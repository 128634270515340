import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {EstruturaLinhaTransmissao} from "../../../../../../../../shared/models/estrutura-linha-transmissao.model";

@Component({
    selector: 'app-atv-ee-lin-trans-estrutura',
    templateUrl: './atv-ee-lin-trans-estrutura.component.html',
    styleUrls: ['./atv-ee-lin-trans-estrutura.component.scss']
})
export class AtvEeLinTransEstruturaComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'descricao', 'acoes'];

    tipos: DominioDto[];

    formEstrutura: FormGroup;

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-estrutura-ee-lin-trans')
            .subscribe(doms => this.tipos = doms);

        this.prepareFormEstrutura();
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.formEstrutura.disable();
        }
    }

    adicionar() {
        if (!this.formEstrutura.valid) return;

        const estruturas = this.form.get('estruturas');

        estruturas.setValue([
            ...(estruturas.value || []),
            EstruturaLinhaTransmissao.fromJson(this.formEstrutura.value)
        ]);

        this.prepareFormEstrutura();
    }

    excluir(item: EstruturaLinhaTransmissao) {
        const estruturas: AbstractControl = this.form.get('estruturas');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: EstruturaLinhaTransmissao) {
        this.formEstrutura.patchValue(item);

        this.excluir(item);
    }

    private prepareFormEstrutura() {
        this.formEstrutura = this.fb.group({
            id: [null],
            idEntidade: [null],
            idDominio: [null, Validators.required,],
        });

        this.formEstrutura.get('idDominio').valueChanges.subscribe(value => {
            if (this.getDescricaoTipo(value) === 'Outro') {
                if (!this.formEstrutura.get('descricao')) {
                    this.formEstrutura.addControl('descricao', this.fb.control(null, [Validators.required]));
                }
            } else if (!!this.formEstrutura.get('descricao')) {
                this.formEstrutura.removeControl('descricao');
            }
        });
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }
}
