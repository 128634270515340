import {ResponsavelTecnico} from './responsavel-tecnico.model';
import {EnumTipoDocumentoRT} from './enum-tipo-documento-rt.model';

/**
 * Model que define o documento do ResponsavelTecnico.
 */
export class DocumentoRT {

    constructor(
        public id?: number,
        public nomeArquivo?: string,
        public tipo?: EnumTipoDocumentoRT,
        public responsavelTecnico?: ResponsavelTecnico,
        public sigilo?: boolean,
        public arquivo?: any, // MultiPart / Blob
        public numeroART?: string
    ) {
    }

    static fromJson(json: any): DocumentoRT {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoRT(
            json.id,
            json.nomeArquivo,
            EnumTipoDocumentoRT.fromJson(json.tipo),
            ResponsavelTecnico.fromJson(json.responsavelTecnico),
            json.sigilo,
            json.arquivo,
            json.numeroART
        );
    }

    static fromJsons(json: any): DocumentoRT[] {
        const documentoRTS: DocumentoRT[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((documento) => {
                documentoRTS.push(DocumentoRT.fromJson(documento));
            });
            return documentoRTS;
        }
    }
}
