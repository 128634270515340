import {NgModule} from '@angular/core';
import {CoreModule} from 'app/main/core/core.module';
import {AbaFinalidadeAbastecimentoPublicoComponent} from './aba-finalidade-abastecimento-publico/aba-finalidade-abastecimento-publico.component';
import {AbaFinalidadeEsgotamentoSanitarioComponent} from './aba-finalidade-esgotamento-sanitario/aba-finalidade-esgotamento-sanitario.component';
import {AbaFinalidadeDessedentacaoComponent} from './aba-finalidade-dessedentacao/aba-finalidade-dessedentacao.component';
import {AbaFinalidadeIndustriaComponent} from './aba-finalidade-industria/aba-finalidade-industria.component';
import {AbaFinalidadeTanqueEscavadoComponent} from './aba-finalidade-tanque-escavado/aba-finalidade-tanque-escavado.component';
import {AbaFinalidadeTanqueRedeComponent} from './aba-finalidade-tanque-rede/aba-finalidade-tanque-rede.component';
import {AbaFinalidadeMineracaoComponent} from './aba-finalidade-mineracao/aba-finalidade-mineracao.component';
import {AbaFinalidadeOutrasComponent} from './aba-finalidade-outras/aba-finalidade-outras.component';
import {AbaObjetivoCaptacaoSuperficialComponent} from './aba-objetivo-captacao-superficial/aba-objetivo-captacao-superficial.component';
import {AbaObjetivoObrasHidraulicasComponent} from './aba-objetivo-obras-hidraulicas/aba-objetivo-obras-hidraulicas.component';
import {AbaTitulacaoCaptacaoInsignificanteComponent} from './aba-titulacao-captacao-insignificante/aba-titulacao-captacao-insignificante.component';
import {AbaObjetivoLancamentosEfluentesComponent} from './aba-objetivo-lancamentos-efluentes/aba-objetivo-lancamentos-efluentes.component';
import {AbaObjetivoTamponamentoPocoTubularComponent} from './aba-objetivo-tamponamento-poco-tubular/aba-objetivo-tamponamento-poco-tubular.component';
import {AbaObjetivoPerfuracaoPocoTubularComponent} from './aba-objetivo-perfuracao-poco-tubular/aba-objetivo-perfuracao-poco-tubular.component';
import {AbaObjetivoBarragemExistenteComponent} from './aba-objetivo-barragem-existente/aba-objetivo-barragem-existente.component';
import {AbaAtividadesComponent} from './aba-atividades.component';
import {SharedModule} from '../../shared.module';
import {AbaObjetivoPocoIrrigacaoComponent} from './aba-objetivo-poco-irrigacao/aba-objetivo-poco-irrigacao.component';
import {AbaObjetivoAguaSubterraneaComponent} from './aba-objetivo-agua-subterranea/aba-objetivo-agua-subterranea.component';
import {AbaObjetivoClassificacaoBarragemComponent} from './aba-objetivo-classificacao-barragem/aba-objetivo-classificacao-barragem.component';
import {DecimalPipe} from '@angular/common';
import {AbaObjetivoAguaSubIrrigacaoComponent} from './aba-objetivo-agua-sub-irrigacao/aba-objetivo-agua-sub-irrigacao.component';
import {AbaFinalidadeGeracaoEnergiaComponent} from './aba-finalidade-geracao-energia/aba-finalidade-geracao-energia.component';
import {AbaObjetivoDrdhComponent} from './aba-objetivo-drdh/aba-objetivo-drdh.component';
import {AbaFinalidadeIrrigacaoComponent} from './aba-finalidade-irrigacao/aba-finalidade-irrigacao.component';
import {MatRadioModule} from "@angular/material/radio";
import {SelectManyCheckboxModule} from "../select-many-checkbox/select-many-checkbox.module";
import {SelectRadioModule} from "../select-radio/select-radio.module";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
    declarations: [
        AbaAtividadesComponent,
        AbaFinalidadeAbastecimentoPublicoComponent,
        AbaFinalidadeEsgotamentoSanitarioComponent,
        AbaObjetivoBarragemExistenteComponent,
        AbaFinalidadeDessedentacaoComponent,
        AbaFinalidadeIndustriaComponent,
        AbaFinalidadeTanqueEscavadoComponent,
        AbaFinalidadeTanqueRedeComponent,
        AbaFinalidadeMineracaoComponent,
        AbaFinalidadeOutrasComponent,
        AbaObjetivoObrasHidraulicasComponent,
        AbaObjetivoCaptacaoSuperficialComponent,
        AbaObjetivoLancamentosEfluentesComponent,
        AbaTitulacaoCaptacaoInsignificanteComponent,
        AbaObjetivoTamponamentoPocoTubularComponent,
        AbaObjetivoPerfuracaoPocoTubularComponent,
        AbaObjetivoDrdhComponent,
        AbaObjetivoClassificacaoBarragemComponent,
        AbaObjetivoPocoIrrigacaoComponent,
        AbaObjetivoAguaSubIrrigacaoComponent,
        AbaObjetivoAguaSubterraneaComponent,
        AbaFinalidadeGeracaoEnergiaComponent,
        AbaFinalidadeIrrigacaoComponent,
        AbaFinalidadeIrrigacaoComponent
    ],

    imports: [
        CoreModule,
        SharedModule,
        MatRadioModule,
        MatRadioModule,
        SelectManyCheckboxModule,
        SelectRadioModule,
        MatTooltipModule
    ],
    exports: [
        AbaAtividadesComponent,
        AbaFinalidadeAbastecimentoPublicoComponent,
        AbaFinalidadeEsgotamentoSanitarioComponent,
        AbaObjetivoBarragemExistenteComponent,
        AbaFinalidadeDessedentacaoComponent,
        AbaFinalidadeIndustriaComponent,
        AbaFinalidadeTanqueEscavadoComponent,
        AbaFinalidadeTanqueRedeComponent,
        AbaFinalidadeMineracaoComponent,
        AbaFinalidadeOutrasComponent,
        AbaFinalidadeGeracaoEnergiaComponent,
        AbaObjetivoObrasHidraulicasComponent,
        AbaObjetivoCaptacaoSuperficialComponent,
        AbaObjetivoLancamentosEfluentesComponent,
        AbaTitulacaoCaptacaoInsignificanteComponent,
        AbaObjetivoTamponamentoPocoTubularComponent,
        AbaObjetivoPerfuracaoPocoTubularComponent,
        AbaObjetivoDrdhComponent,
        AbaObjetivoClassificacaoBarragemComponent,
        AbaObjetivoPocoIrrigacaoComponent,
        AbaObjetivoAguaSubIrrigacaoComponent,
        AbaObjetivoAguaSubterraneaComponent,
        AbaFinalidadeIrrigacaoComponent,
    ],
    providers: [DecimalPipe],
    entryComponents: [],
})
export class AbaAtividadesModule {
}
