import {BaseEnum} from 'app/main/shared/enums/base.enum';

export class TipoFormulario {

    constructor() {
    }

    static TiposFormulario: BaseEnum[] = [
        {valor: '1', descricao: 'Qualidade água subterrânea'},
        {valor: '2', descricao: 'Qualidade solo'},
        {valor: '3', descricao: 'Qualidade água superficial'},
        {valor: '4', descricao: 'Controle de Nível Estático'}
    ];
}
