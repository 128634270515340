import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EvapoPrecipDto} from "../models/evapo-precip-dto.model";
import {RequerimentoOutorga} from "../../pages/requerimentos/requerimento-outorga/requerimento-outorga.model";

@Injectable({
    providedIn: 'root'
})
export class EvapoPrecipService {

    private urlResource = `${environment.URL_GATEWAY}/api/evapotranspiracao-precipitacao`;

    constructor(private http: HttpClient) {
    }

    findByRequerimentoOutorga(requerimentoOutorga: RequerimentoOutorga): Observable<EvapoPrecipDto> {
        return this.http.get(`${this.urlResource}/requerimento-outorga/${requerimentoOutorga.id}`)
            .pipe(
                map(json => EvapoPrecipDto.fromJson(json)),
                catchError(e => throwError(e))
            );
    }

}
