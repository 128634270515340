import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {FormularioQualidadeAgua} from './formulario-qualidade-agua.model';

@Injectable()
export class CompostoQuimicoListResolver extends ListResolver<FormularioQualidadeAgua> {
    constructor(service: FormularioQualidadeAguaService) {
        super(service);
    }
}

@Injectable()
export class CompostoQuimicoResolver extends DetailResolver<FormularioQualidadeAgua> {
    constructor(
        service: FormularioQualidadeAguaService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FormularioQualidadeAguaService extends BaseService<FormularioQualidadeAgua> {
    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            FormularioQualidadeAgua.prototype,
            FormularioQualidadeAgua.fromJson
        );
    }

    static getFormulariosAguaItems(formulariosItems: any): any {
        return {
            key: 'qualidadesAgua',
            value: formulariosItems
        };
    }

    getItemsByCondicionante(parecerTecnicoId: number, condicionanteId: number): Observable<FormularioQualidadeAgua[]> {
        const url = `${this.urlResource}/api/pareceres-tecnicos/${parecerTecnicoId}/condicionantes/${condicionanteId}/qualidades-agua`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    deleteFormularioAgua(idFormularioAgua: number): Observable<any> {
        const url = `${this.urlResource}/api/pareceres-tecnicos/${idFormularioAgua}/formulario-agua`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
}
