import {Injectable, Injector} from '@angular/core';
import {IndustriaFrigorifico} from "../models/industria-frigorifico.model";
import {BaseIndustriaFrigorifico} from "./base-industria-frigorifico.service";


@Injectable({
    providedIn: "root"
})
export class IndustriaFrigorificoService extends BaseIndustriaFrigorifico<IndustriaFrigorifico> {

    constructor(protected injector: Injector) {
        super(
            "/api/ind-frigorifico",
            injector,
            IndustriaFrigorifico.prototype,
            IndustriaFrigorifico.fromJson
        );
    }
}
