import {BaseModel} from 'app/main/shared/models/base.model';

export class ObraSaneamento extends BaseModel {
    constructor(
        public id?: number,
        public agua?: boolean,
        public esgoto?: boolean,
        public zona?: number,
        public desapropriacao?: boolean,
        public corredorFauna?: boolean,
        public equipamentoAPP?: boolean,
        public distanciaTratamentoResidenciais?: number
    ) {
        super();
    }

    static fromJson(json: any): ObraSaneamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObraSaneamento(
            json.id,
            json.agua,
            json.esgoto,
            json.zona,
            json.desapropriacao,
            json.corredorFauna,
            json.equipamentoAPP,
            json.distanciaTratamentoResidenciais
        );
    }

    static fromJsons(json: any): ObraSaneamento[] {
        const lista: ObraSaneamento[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => lista.push(this.fromJson(item)));
            return lista;
        }
    }

}
