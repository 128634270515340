import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {Barragem} from "../../../../../../../../shared/models/barragem.model";
import {EeHidricaService} from "../ee-hidrica.service";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
    selector: 'app-barragem',
    templateUrl: './barragem.component.html',
    styleUrls: ['./barragem.component.scss']
})
export class BarragemComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly: boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() enviarBarragem = new EventEmitter<any[]>();
    formBarragem: FormGroup;
    tipoBarragem: any[] = [];
    barragemSalvas: any[] = [];
    dataSourceBarragem = new MatTableDataSource<any>([]);
    idBarragem: number = 0;
    modoEdicao: boolean = false;
    mostrarDescricao: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private eeHidricaService: EeHidricaService,
                private dominioService: DominiosService) {
    }

    ngOnInit() {
        this.buildFormBarragem();

        this.dominioService.getAllTipoBarragem().subscribe(resposta => {
            this.tipoBarragem = resposta;
        })

        if (this.idForm) {
            this.eeHidricaService.buscarBarragemPorId(this.idForm).subscribe(response => {
                response.forEach(barragem => {
                    this.barragemSalvas.push({
                        id: barragem.id,
                        idTemp: this.idBarragem,
                        tipo: barragem.tipo,
                        descricao: barragem.descricao,
                        comprimentoCrista: barragem.comprimentoCrista,
                        alturaBarragem: barragem.alturaBarragem,
                        tempoFormacao: barragem.tempoFormacao,
                        tempoResidencia: barragem.tempoResidencia,
                    });
                    this.idBarragem++;
                })
                this.dataSourceBarragem = new MatTableDataSource(this.barragemSalvas);
                this.emitBarragem(this.mapearArray());
            })
        }

        if (this.isNaoPodeEditar) {
            this.formBarragem.disable();
        }
    }

    private buildFormBarragem(): void {
        this.formBarragem = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            tipo: ["", [Validators.required]],
            descricao: ["", [Validators.required]],
            comprimentoCrista: ["", [Validators.required]],
            alturaBarragem: ["", [Validators.required]],
            tempoFormacao: ["", [Validators.required]],
            tempoResidencia: ["", [Validators.required]],
        });
        this.formBarragem.controls.descricao.disable();
    }

    adicionarBarragem() {
        if (this.formBarragem.valid) {
            this.barragemSalvas.push({
                id: null,
                idTemp: this.idBarragem,
                tipo: this.formBarragem.controls.tipo.value,
                descricao: this.formBarragem.controls.descricao.value,
                comprimentoCrista: this.formBarragem.controls.comprimentoCrista.value,
                alturaBarragem: this.formBarragem.controls.alturaBarragem.value,
                tempoFormacao: this.formBarragem.controls.tempoFormacao.value,
                tempoResidencia: this.formBarragem.controls.tempoResidencia.value,
            })
            this.idBarragem++;
            this.dataSourceBarragem = new MatTableDataSource(this.barragemSalvas);
            this.emitBarragem(this.mapearArray());
            this.formBarragem.reset();
            this.formBarragem.controls.descricao.disable();
            this.mostrarDescricao = false;
        } else {
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            this.formBarragem.markAllAsTouched();
        }
    }

    ativarModoEdicao(item) {
        this.formBarragem.controls.id.patchValue(item.id);
        this.formBarragem.controls.idTemp.patchValue(item.idTemp);
        this.formBarragem.controls.tipo.patchValue(item.tipo);
        if (item.descricao) {
            this.formBarragem.controls.descricao.enable();
            this.formBarragem.controls.descricao.patchValue(item.descricao)
            this.mostrarDescricao = true;
        } else {
            this.formBarragem.controls.descricao.disable();
            this.mostrarDescricao = false;
        }
        this.formBarragem.controls.comprimentoCrista.patchValue(item.comprimentoCrista);
        this.formBarragem.controls.alturaBarragem.patchValue(item.alturaBarragem);
        this.formBarragem.controls.tempoFormacao.patchValue(item.tempoFormacao);
        this.formBarragem.controls.tempoResidencia.patchValue(item.tempoResidencia);
        this.modoEdicao = true;
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.formBarragem.reset();
    }

    editarBarragem() {
        if (this.formBarragem.valid) {
            this.deletarBarragem(this.formBarragem.controls.idTemp.value);
            this.barragemSalvas.push({
                id: this.formBarragem.controls.id.value,
                idTemp: this.formBarragem.controls.idTemp.value,
                tipo: this.formBarragem.controls.tipo.value,
                descricao: this.formBarragem.controls.descricao.value,
                comprimentoCrista: this.formBarragem.controls.comprimentoCrista.value,
                alturaBarragem: this.formBarragem.controls.alturaBarragem.value,
                tempoFormacao: this.formBarragem.controls.tempoFormacao.value,
                tempoResidencia: this.formBarragem.controls.tempoResidencia.value,
            })
            this.dataSourceBarragem = new MatTableDataSource(this.barragemSalvas);
            this.emitBarragem(this.mapearArray());
            this.formBarragem.reset();
            this.formBarragem.controls.descricao.disable();
            this.mostrarDescricao = false;
            this.modoEdicao = false;
        } else {
            this.formBarragem.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    changeTipoBarragem(event) {
        if (event == 'Outro') {
            this.formBarragem.controls.descricao.reset();
            this.formBarragem.controls.descricao.enable();
            this.mostrarDescricao = true;
        } else {
            this.formBarragem.controls.descricao.reset();
            this.formBarragem.controls.descricao.disable();
            this.mostrarDescricao = false;
        }
    }

    deletarBarragem(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.eeHidricaService.deletarBarragemPorId(itemId).subscribe(response => {
                this.snackBarService.showSuccess("Barragem excluido com sucesso.")
            })
        }
        let data;
        data = this.barragemSalvas.filter((value, key) => {
            return value.idTemp != item;
        });
        this.barragemSalvas = data;
        this.dataSourceBarragem = new MatTableDataSource(data);
        this.emitBarragem(this.mapearArray());
    }

    emitBarragem(barragem) {
        this.enviarBarragem.emit(barragem);
    }

    mapearArray(): any {
        if (this.barragemSalvas.length == 0) {
            return this.barragemSalvas;
        } else {
            return this.barragemSalvas.map(function (item) {
                return new Barragem(
                    item.id,
                    null,
                    item.tipo,
                    item.descricao,
                    Number(item.comprimentoCrista),
                    Number(item.alturaBarragem),
                    Number(item.tempoFormacao),
                    Number(item.tempoResidencia),
                );
            });
        }
    }
}