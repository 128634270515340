import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog-message-disponibilidade-hidrica',
  templateUrl: './dialog-message-disponibilidade-hidrica.component.html',
  styleUrls: ['./dialog-message-disponibilidade-hidrica.component.scss']
})
export class DialogMessageDisponibilidadeHidricaComponent implements OnInit {
  
  liberarTelaConsultarDisponibilidadeHidrica: boolean = false;
  
  constructor(
    public dialogRef: MatDialogRef<DialogMessageDisponibilidadeHidricaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    ) { }
    
    ngOnInit() {
    this.liberarTelaConsultarDisponibilidadeHidrica;
  }

  close(): void {
    this.dialogRef.close();
}

public liberarSimulador(): void {
  this.liberarTelaConsultarDisponibilidadeHidrica = true;
}

}
