import {BaseModel} from "../base.model";

export class ParametroLicenciamento extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public situacao?: boolean,
    ) {
        super();
    }

    static fromJson(json: any): ParametroLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParametroLicenciamento(
            json.id,
            json.atividade,
            json.situacao,
        );
    }
}