import { ResiduoRccService } from './main/shared/services/residuo-rcc.service';
import { BalancoMassaResiduoRccService } from './main/shared/services/balanco-massa-residuo-rcc.service';
import { TipoAtividadeService } from './main/shared/services/tipo-atividade.service';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import Pt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseProgressBarModule } from '../@fuse/components';
import { FuseModule } from '../@fuse/fuse.module';
import { FuseSharedModule } from '../@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { fuseConfig } from './fuse-config';
import { LayoutModule } from './layout/layout.module';
import { AccountService } from './main/core/services/account.service';
import { AuthServerProvider } from './main/core/services/auth-provider.service';
import { Principal } from './main/core/services/principal.service';
import { PageErrorsModule } from './main/errors/page-errors.module';
import 'hammerjs';
import { CookieService } from 'ngx-cookie-service';
import { NgxLoadingModule } from 'ngx-loading';
import { environment } from '../environments/environment';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { CoreModule } from './main/core/core.module';
import { RoutingStateService } from './main/core/services/routing-state.service';
import { PagesModule } from './main/pages/pages.module';
import { LoadingInterceptor } from './main/shared/components/loading/loading.interceptor';
import { LoadingModule } from './main/shared/components/loading/loading.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { FullscreenOverlayContainer, OverlayContainer } from "@angular/cdk/overlay";
import { MatTooltipModule} from '@angular/material/tooltip'
import { SemaGeoportalModule } from '@sema-geo/sema-geoportal';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: './main/pages/pages.module#PagesModule',
    }
];

registerLocaleData(Pt);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        RouterModule.forRoot(appRoutes, {
            useHash: true
        }),
        NgxLoadingModule.forRoot({}),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseProgressBarModule,

        LoadingModule,

        // App modules
        LayoutModule,
        PageErrorsModule,
        CoreModule.forRoot(),
        PagesModule,
        MatTooltipModule,
        SemaGeoportalModule.forRoot(environment)
    ],
    exports: [PagesModule],
    providers: [
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        RoutingStateService,
        AuthServerProvider,
        AccountService,
        Principal,
        CookieService,
        TipoAtividadeService,
        BalancoMassaResiduoRccService,
        ResiduoRccService,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        console.log('[licenciamento] ambiente de ' + environment.name);
    }
}

function CustomPaginator(): MatPaginatorIntl {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = 'Itens por página:';
    customPaginatorIntl.nextPageLabel = 'Próxima página';
    customPaginatorIntl.previousPageLabel = 'Página anterior';
    customPaginatorIntl.firstPageLabel = 'Primeira página';
    customPaginatorIntl.lastPageLabel = 'Ultima página';
    customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };

    return customPaginatorIntl;
}