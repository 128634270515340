import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';

export interface ConfirmarExclusaoPontoDialogData {
    ponto: any;
    temDocumento: boolean;
}

@Component({
    templateUrl: './confirmar-exclusao-ponto-dialog.component.html'
})
export class ConfirmarExclusaoPontoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarExclusaoPontoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmarExclusaoPontoDialogData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
