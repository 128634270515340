import {BaseModel} from 'app/main/shared/models/base.model';
import {GeracaoEnergia} from "./geracao-energia.model";

export class Turbina extends BaseModel {
    constructor(
        public id?: number,
        public tipoTurbina?: string,
        public vazaoNominalUnitaria?: number,
        public geracaoEnergia?: GeracaoEnergia
    ) {
        super();
    }

    static fromJson(json: any): Turbina {
        if (json === undefined || json === null) {
            return null;
        }
        return new Turbina(
            json.id,
            json.tipoTurbina,
            json.vazaoNominalUnitaria,
            GeracaoEnergia.fromJson(json.geracaoEnergia)
        );
    }

}