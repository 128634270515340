import {NgModule} from '@angular/core';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {RequerimentoResponsaveisComponent} from './requerimento-responsaveis.component';
import {RequerimentoResponsavelTecnicoComponent} from './responsavel-tecnico/requerimento-responsavel-tecnico.component';
import {
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule
} from '@angular/material';
import {RequerimentoInteressadoPfComponent} from './interessado-pf/requerimento-interessado-pf.component';
import {RequerimentoRepresentanteLegalComponent} from './representante-legal/requerimento-representante-legal.component';
import {RequerimentoInteressadoPjComponent} from './interessado-pj/requerimento-interessado-pj.component';
import {ConfirmarAlteracaoInteressadoDialogComponent} from './confirmar-alteracao-interessado/confirmar-alteracao-interessado-dialog.component';
import {OverlayOrientacaoArtComponent} from './responsavel-tecnico/overlay-info-art/overlay-orientacao-art.component';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
    declarations: [
        ConfirmarAlteracaoInteressadoDialogComponent,
        RequerimentoInteressadoPfComponent,
        RequerimentoInteressadoPjComponent,
        RequerimentoResponsaveisComponent,
        RequerimentoResponsavelTecnicoComponent,
        RequerimentoRepresentanteLegalComponent,
        OverlayOrientacaoArtComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSortModule,
        MatRadioModule,
        MatTableModule,
        MatDatepickerModule,
        MatTooltipModule,
        OverlayModule
    ],
    exports: [
        ConfirmarAlteracaoInteressadoDialogComponent,
        RequerimentoInteressadoPfComponent,
        RequerimentoInteressadoPjComponent,
        RequerimentoResponsaveisComponent,
        RequerimentoResponsavelTecnicoComponent,
        RequerimentoRepresentanteLegalComponent
    ],
    entryComponents: [
        ConfirmarAlteracaoInteressadoDialogComponent,
        RequerimentoInteressadoPfComponent,
        RequerimentoInteressadoPjComponent,
        RequerimentoResponsaveisComponent,
        RequerimentoResponsavelTecnicoComponent,
        RequerimentoRepresentanteLegalComponent,
        OverlayOrientacaoArtComponent
    ]
})
export class RequerimentoResponsaveisModule {
}
