import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import {isNullOrUndefined} from "util";
import { DialogConfirmacaoService } from 'app/main/shared/components/dialog-confirmacao/dialog-confirmacao.service';
import { CoordenadaDTO } from 'app/main/shared/models/hidrico-models/coordenada-dto';
import { ProcessamentoCoordenadaDto } from 'app/main/shared/models/processamento-coordenada-dto.model';
import { HidricoService } from 'app/main/shared/services/hidrico-service/hidrico.service';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { AuthenticationService } from 'app/main/core/services/authentication.service';
import { TipoProcessamento } from 'app/main/shared/models/hidrico-models/tipo-processamento.enum';
import { DialogConfirmacaoDevolucaoRecursoComponent } from '../dialog-confirmacao-devolucao-recurso/dialog-confirmacao-devolucao-recurso.component';

@Component({
  selector: 'app-devolver-recurso-hidrico-detail',
  templateUrl: './devolver-recurso-hidrico-detail.component.html',
  styleUrls: ['./devolver-recurso-hidrico-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DevolverRecursoHidricoDetailComponent implements OnInit {
   
    pageTitle: string = "Devolução de Recurso Hídrico";
    coordenadaDTO: CoordenadaDTO;
    coordenadaId: number;
    processamentosCoordenada: ProcessamentoCoordenadaDto[] = [];
    usuarioLogado = null;
    cancelarBalancoHidricoRequest: CancelarBalancoHidricoRequest = null;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private activatedRoute: ActivatedRoute,
        private hidricoService: HidricoService,
        private authenticationService: AuthenticationService,
        private dialogConfirmacaoService: DialogConfirmacaoService,
        private dialog: MatDialog) { 
        this.activatedRoute.params.subscribe(params => this.coordenadaId = params['id']);
    }

    ngOnInit() {
        this.getCoordenadaDTO(this.coordenadaId);
        this.authenticationService.currentUser.subscribe(user => this.usuarioLogado = user);
    }

    form: FormGroup = new FormGroup({
        justificativa: new FormControl(null, [Validators.required, Validators.maxLength(2000)])
    });

    montarObjetoCancelarBalancoHidrico(): CancelarBalancoHidricoRequest {
        return this.cancelarBalancoHidricoRequest = new CancelarBalancoHidricoRequest(
            this.coordenadaDTO.id,
            this.usuarioLogado.sub,
            this.usuarioLogado.nome,
            this.form.get('justificativa').value
        );
    }

    protected getBaseComponetPath(): string {
        return this.activatedRoute.snapshot.parent.url[0].path;
    }

    getCoordenadaDTO(coordenadaId: number) {
        this.hidricoService.findCoordenadaById(coordenadaId).subscribe(result => {
            this.coordenadaDTO = result;
            this.getProcessamentosCoordenda(this.coordenadaDTO.id);
        });
    }

    devolverRecursoHidrico(): void {

        if (!this.isFormDevolucaoValido()) {
            return;
        }

        this.dialogConfirmacaoService.openConfimDialog('Confirmar', 
            'exclusão? O ponto será cancelado e enviado para a fila de processamento para atualizar o balanço hídrico. Esta ação não pode ser desfeita. ', 'Deseja continuar?')
            .afterClosed().subscribe(res => {
                if (res) {
                    this.hidricoService.devolverRecursoHidrico(this.montarObjetoCancelarBalancoHidrico()).subscribe(result => {
                        this.snackBarService.showSuccess('Registro salvo com sucesso')
                    this.getProcessamentosCoordenda(this.coordenadaDTO.id);
                },
                e => this.snackBarService.showError('Erro ao solicitar a devolução do recurso hídrico', e));
            }
        });

    }


    solicitarDevolucaoRecursoHidrico(): void {
        if (!this.isFormDevolucaoValido()) {
            return;
        }
        const dialogRef = this.dialog.open(DialogConfirmacaoDevolucaoRecursoComponent, {
            data: {
                idCoordenada: this.coordenadaDTO.id,
                cpfServidor: this.usuarioLogado.sub,
                nomeServidor: this.usuarioLogado.nome,
                justificativa: this.form.get('justificativa').value,
                numeroProcesso: this.coordenadaDTO.numeroProcesso,
            },
            width: '60%',
            height: '30%',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'update') {
                this.getProcessamentosCoordenda(this.coordenadaDTO.id);
            }
        });
    }

    isFormDevolucaoValido(): boolean {

        console.log("ARRAY =>", this.processamentosCoordenada);

        if (this.processamentosCoordenada.length > 0) {
            this.snackBarService.showAlert('O cancelamento já esta na fila de processamento ou já encontra-se como processado.')
            return false;
        }

        if (isNullOrUndefined(this.form.get('justificativa').value)) {
            this.snackBarService.showAlert('A justificativa para o cancelamento é obrigatória')
            return false;
        }
        return true;
    }

    getProcessamentosCoordenda(id: number) {
        this.hidricoService.listarProcessamentoCoordenada(id).subscribe(result => {
            this.processamentosCoordenada = [];
            if (result !== null) {
                if (result.tipoProcessamento != 'GERAR') {
                    this.processamentosCoordenada.push(result);
                }
            }
        });
    }

    voltar(): void {
        this.router
        .navigateByUrl(this.getBaseComponetPath(), {skipLocationChange: true})
        .then(() => this.router.navigate([this.getBaseComponetPath()], {skipLocationChange: true}));
    }

    fechar(): void {
        this.router
        .navigateByUrl(this.getBaseComponetPath(), {skipLocationChange: true})
        .then(() => this.router.navigate([this.getBaseComponetPath()], {skipLocationChange: true}));
    }

}

export class CancelarBalancoHidricoRequest {
    constructor(
        public coordenadaId?: number,
        public cpfExecutor?: string,
        public nomeExecutor?: string,
        public justificativa?: string,
        public recursoHidricoComTituloEmitido?: boolean
    ) {
    }

    static fromJson(json: any): CancelarBalancoHidricoRequest {
        if (json === undefined || json === null) {
            return null;
        }
        return new CancelarBalancoHidricoRequest(
            json.idCoordenada ? json.idCoordenada : null,
            json.cpfExecutor ? json.cpfExecutor : null,
            json.nomeExecutor ? json.nomeExecutor : null,
            json.justificativa ? json.justificativa : null,
            json.recursoHidricoComTituloEmitido
        );
    }

}
