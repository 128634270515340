import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {takeUntil} from 'rxjs/operators';
import {LancamentoTaxa} from '../../../../shared/models/lancamento-taxa.model';
import {LancamentoTaxaService} from '../../../../shared/services/lancamento-taxa.service';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {ErrosAbaRequerimento} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {RequerimentoLicenciamentoService} from '../requerimento-licenciamento.service';
import {AtividadeSubAtividade} from './atividade-subatividade.dto';
import {AtividadeSubatividadeService} from './atividade-subatividade.service';


@Component({
    selector: 'app-requerimento-taxa-licenciamento',
    templateUrl: 'requerimento-taxa-licenciamento.component.html',
    styleUrls: ['requerimento-taxa-licenciamento.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class RequerimentoTaxaLicenciamentoComponent extends BaseTabRequerimentoComponent
    implements OnInit {
    form: FormGroup;
    lancamentosTaxa: Array<LancamentoTaxa> = [];
    listaAtividades: Array<AtividadeSubAtividade> = [];
    valorUpfTotal = 0;
    objetoReqReaproveitamentoTaxa: any = [];
    objetoReqIsencao: any = [];
    isento: any;

    constructor(
        private injector: Injector,
        private lancamentoTaxaService: LancamentoTaxaService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private atividadeSubatividadeService: AtividadeSubatividadeService,
    ) {
        super(injector);
        RequerimentoSteps.TAXA.component = this;
    }

    ngOnInit(): void {
        this.helper
            .onCurrentStepChange()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((e) => {
                if (e.newTabIndex === RequerimentoSteps.TAXA.index) {
                    this.carregarAtividadeSubatividades();
                    this.carregarLancamentosTaxa();

                }
            });

    }

    private async carregarAtividadeSubatividades(): Promise<void> {
        await this.requerimentoLicenciamentoService
            .getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id)
            .subscribe(async lista => {
                await this.atividadeSubatividadeService.getListaAtividadeCalculoTaxa(lista).subscribe(listaAtividades => {
                    this.listaAtividades.push(listaAtividades);
                });
            });
    }

    private carregarLancamentosTaxa(): void {
        this.lancamentoTaxaService.getByRequerimentoId(this.requerimento.id)
            .subscribe(lancamentosTaxa => {
                this.lancamentosTaxa = lancamentosTaxa;
                this.lancamentosTaxa.forEach(lancamentoTaxa => {
                    this.valorUpfTotal = this.valorUpfTotal + lancamentoTaxa.qtdeTotalUPF;
                });
            });

    }

    recebeEmitterReaproveitamento(event: any): void {
        /// recebe o ultimo objeto do array
        this.objetoReqReaproveitamentoTaxa = event[event.length - 1];
    }

    recebeEmitterReqIsencao(event: any): void {
        /// recebe o ultimo objeto do array
        this.objetoReqIsencao = event[event.length - 1];
        this.isento = this.objetoReqIsencao.isencao !== 0;
    }

    async validarAba(erros: ErrosAbaRequerimento): Promise<void> {

        // valida requerimento - isencao
        if (this.objetoReqIsencao.isencao !== 0 && this.objetoReqIsencao.arquivo === false
            || this.objetoReqIsencao.isencao === undefined && this.objetoReqIsencao.arquivo === false) {
            erros.push('Verificar criterios de isenção  !');
        }

        // valida requerimento - reaproveitamento
        else if (this.objetoReqReaproveitamentoTaxa.length === 0) {
            erros.push('Escolha se deseja ou não utilizar créditos !');
        } else if (this.objetoReqReaproveitamentoTaxa.decisaoReaproveitamento === 'S'
            && this.objetoReqReaproveitamentoTaxa.qtdCredito === 0) {
            erros.push('Utilizar quantidade de upf corretamente !');
        }

        return await Promise.resolve();
    }
}
