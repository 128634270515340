import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReqLicenciamentoDinamicaDesmateService} from './req-licenciamento-dinamica-desmate.service';
import {ReqLicenciamentoDinamicaDesmate} from './req-licenciamento-dinamica-desmate.model';
import {RequerimentoLicenciamento} from "../../../requerimento-licenciamento/requerimento-licenciamento.model";
import {DominiosService} from "../../../../../shared/services/dominios.service";
import {SnackBarService} from "../../../../../shared/snack-bar/snack-bar.service";
import {UtilService} from "../../../../../core/services/util.service";

@Component({
    selector: 'app-form-dinamica-desmate',
    templateUrl: './form-dinamica-desmate.component.html',
    styleUrls: ['./form-dinamica-desmate.component.scss']
})
export class FormDinamicaDesmateComponent implements OnInit {

    @Input() requerimentoLicenciamento: RequerimentoLicenciamento;
    @Input() idFeicao: number;
    @Output() public formularios: EventEmitter<any> = new EventEmitter();
    categorias: any;
    form: FormGroup;
    dinamicas: Array<ReqLicenciamentoDinamicaDesmate> = [];
    anos = []
    display: string[] = ['ano', 'categoria', 'subcategoria', 'acao'];
    statusEventual = false;
    statusExploracao = false;
    statusSubCtg = false;

    constructor(private dominioService: DominiosService,
                private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private reqLicenciamentoDinamicaDesmateService: ReqLicenciamentoDinamicaDesmateService) {
    }

    async ngOnInit() {
        this.createForm()
        this.anos = UtilService.arrayDeAnos()
        this.categorias = await this.dominioService.getDominio('categoria_dinamica_desmate').toPromise()
        await this.listarDinamicaDesmate()
        this.validaForm()
    }

    async listarDinamicaDesmate() {
        await this.reqLicenciamentoDinamicaDesmateService
            .getByListReqLicenciamentoIdAndIdFeicao(this.requerimentoLicenciamento.id, this.idFeicao).toPromise().then(retorno => {
                this.dinamicas = []
                this.dinamicas.push(...retorno)
                let dinamica = this.dinamicas.slice()
                this.dinamicas = dinamica;
            }).catch(() => this.snackBarService.showError('Ocorreu um erro inesperado.'))
    }

    validaForm() {
        if (this.dinamicas.length > 0) {
            this.formularios.emit({idFeicao: this.idFeicao, formulario: 'DESMATE', tipo: 'SAVE'});
        }
    }

    createForm() {
        this.form = this.formBuilder.group({
            id: [null],
            requerimentoLicenciamento: [this.requerimentoLicenciamento],
            ano: [null, [Validators.required]],
            idCategoriaDesmate: [null, [Validators.required]],
            subcategoria: [null, [Validators.required]],
            idFeicao: [this.idFeicao],
        });
    }

    adicionar() {
        if (!this.form.value.ano) {
            this.snackBarService.showAlert('Insira o ano.')
            return
        } else if (!this.form.value.idCategoriaDesmate) {
            this.snackBarService.showAlert('Insira uma categoria')
            return
        } else if (!this.form.value.subcategoria) {
            this.snackBarService.showAlert('Informe a subcategoria')
            return
        }

        if (this.dinamicas.length === 0) {
            this.dinamicas.push(this.form.value)
            this.dinamicas = this.dinamicas.slice();
            this.form.reset()
            this.form.patchValue({requerimentoLicenciamento: this.requerimentoLicenciamento})
            this.form.patchValue({idFeicao: this.idFeicao})
        } else {
            this.snackBarService.showAlert('Dinâmica de desmate ja foi adicionada')
        }
    }

    returnDescCategoriaSubCtg(categoria: any) {
        switch (categoria) {
            case 1:
                return 'Eventual';
            case 2:
                return 'Exploração';
            case 'FOGO':
                return 'Fogo';
            case 'VENTO':
                return 'Vento';
            case 'SELETIVA':
                return 'Seletiva';
            case 'DESMATE':
                return 'Desmate';
            default:
                return '';
        }
    }

    salvar() {
        if (this.dinamicas.length > 0) {
            this.reqLicenciamentoDinamicaDesmateService.salvarLista(this.dinamicas).subscribe(retorno => {
                this.dinamicas = retorno
                this.snackBarService.showSuccess('Lista Dinamica de Desmate Salva com Sucesso')
                if (this.dinamicas.length === 1) {
                    this.formularios.emit({idFeicao: this.idFeicao, formulario: 'DESMATE', tipo: 'SAVE'});
                }
                this.form.reset()
            }, () => this.snackBarService.showError('Ocorreu um erro ao tentar salvar'))
        } else {
            this.snackBarService.showAlert('Adicione um ou mais objetos na tabela antes de salvar.')
        }

    }

    deletar(reqLicenciamentoDinamicaDesmate: any) {
        if (reqLicenciamentoDinamicaDesmate.id) {
            this.reqLicenciamentoDinamicaDesmateService.delete(reqLicenciamentoDinamicaDesmate.id).subscribe(async () => {
                await this.listarDinamicaDesmate()
                this.snackBarService.showSuccess('Removido')
                if (this.dinamicas.length === 0) {
                    this.formularios.emit({idFeicao: this.idFeicao, formulario: 'DESMATE', tipo: 'DELETE'});
                }
            }, () => this.snackBarService.showError('Ocorreu um erro inesperado.'))
        } else {
            let cloned = [];
            this.dinamicas = cloned
            this.snackBarService.showSuccess('Removido')
            if (this.dinamicas.length === 0) {
                this.formularios.emit({idFeicao: this.idFeicao, formulario: 'DESMATE', tipo: 'DELETE'});

            }
        }

    }

    exibirSubCategoria(categoria: any): void {
        if (categoria === 'Eventual') {
            this.statusEventual = true;
            this.statusSubCtg = true;
            this.statusExploracao = false;

        } else if (categoria === 'Exploração') {
            this.statusExploracao = true;
            this.statusSubCtg = true;
            this.statusEventual = false;
        }
    }
}
