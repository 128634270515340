import {ModelUtil} from "../model-util";

export class MicrobaciaInfoResponse {
    constructor(
        public microbaciaId?: number,
        public bacia?: string,
        public upg?: number,
        public pluviosidade?: number,
        public areaDrenagem?: number,
        public regiaoHomogeniaMedia?: string,
        public regiaoHomogeniaMaxima?: string,
        public regiaoHomogeniaCurva?: string,
        public classificacaoRio?: string
    ) {
    }

    static fromJson(json: any): MicrobaciaInfoResponse {
        if (json === undefined || json === null) {
            return null;
        }

        return new MicrobaciaInfoResponse(
            ModelUtil.getOrElseNull(json.microbaciaId),
            ModelUtil.getOrElseNull(json.bacia),
            ModelUtil.getOrElseNull(json.upg),
            ModelUtil.getOrElseNull(json.pluviosidade),
            ModelUtil.getOrElseNull(json.areaDrenagem),
            ModelUtil.getOrElseNull(json.regiaoHomogeniaMedia),
            ModelUtil.getOrElseNull(json.regiaoHomogeniaMaxima),
            ModelUtil.getOrElseNull(json.regiaoHomogeniaCurva),
            ModelUtil.getOrElseNull(json.classificacaoRio)
        );
    }

    static fromJsons(json: any): MicrobaciaInfoResponse[] {
        const array: MicrobaciaInfoResponse[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(MicrobaciaInfoResponse.fromJson(item)));
            return array;
        }
    }
}