import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export function checkboxBooleanRequiredDirective(form: FormGroup, nestedForm: string, ...outraPropriedadeCheckbox: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let formValue: any = nestedForm && nestedForm.length > 0 && form.get(nestedForm) ? form.get(nestedForm) : form;

        let atualizaValidade = () => {
            for (let p of outraPropriedadeCheckbox) {
                if (formValue.controls[p].invalid) {
                    formValue.controls[p].updateValueAndValidity();
                }
            }
        }

        if (!control.value) {
            for (let p of outraPropriedadeCheckbox) {
                if (formValue && formValue.get(p) && formValue.get(p).value) {
                    atualizaValidade();
                    return null;
                }
            }
            return {required: true};
        }
        atualizaValidade();
        return null;
    };
}


