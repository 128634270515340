import {BaseModel} from 'app/main/shared/models/base.model';

export class OrgaoAmbiental extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public ativoOutorga?: boolean
    ) {
        super();
    }

    static fromJson(json: any): OrgaoAmbiental {
        if (json === undefined || json === null) {
            return null;
        }
        return new OrgaoAmbiental(
            json.id,
            json.descricao,
            json.ativoOutorga
        );
    }
}
