import {Component, Injector, Input, OnInit} from '@angular/core';
import {ParecerTecnico} from '../../parecer-tecnico.model';
import {MatDialog} from '@angular/material';
import {ParecerTecnicoCondicionantesService} from 'app/main/pages/parecer-tecnico/parecer-tecnico-condicionates/parecer-tecnico-condicionantes.service';
import {ParecerTecnicoCondicionante} from 'app/main/pages/parecer-tecnico/parecer-tecnico-condicionates/parecer-tecnico-condicionantes.model';
import {ParecerTecnicoCondicionanteDialogComponent} from 'app/main/pages/parecer-tecnico/parecer-tecnico-condicionates/parecer-tecnico-condicionante-dialog/parecer-tecnico-condicionante-dialog.component';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {FormBuilder, Validators} from '@angular/forms';
import {CondicionanteTemplateService} from '../../../condicionantes-template/condicionante-template.service';
import {ConfirmarCopiarCondicionanteDialogComponent} from './confirmar-copiar-condicionante-dialog/confirmar-copiar-condicionante-dialog.component';
import {CondicionanteTemplate} from '../../../condicionantes-template/condicionante-template.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {EnumTipoFormulario} from "../../../../shared/enums/EnumTipoFormulario";
import {BaseEnum} from "../../../../shared/enums/base.enum";
import {TipoFormulario} from "../../tipo-formulario.model";

@Component({
    selector: 'app-parecer-tecnico-condicionantes-list',
    templateUrl: './parecer-tecnico-condicionantes-list.component.html',
    styleUrls: ['./parecer-tecnico-condicionantes-list.component.scss']
})
export class ParecerTecnicoCondicionantesListComponent
    implements OnInit {

    @Input()
    protected parecerTecnico: ParecerTecnico;

    tiposCondicionante: any[] = [];
    condicionantesTemplates: any[] = [];
    condicionantesPareceresTecnicos: any[] = [];
    tipoPrazoNaoAplica = 'Não se aplica';
    arquivoGeo: any;
    comentario = '';

    constructor(
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected formBuilder: FormBuilder,
        protected service: ParecerTecnicoCondicionantesService,
        protected condicionanteTemplateService: CondicionanteTemplateService,
        protected injector: Injector
    ) {

    }

    geoForm = this.formBuilder.group({
        arquivoGeo: [null, [Validators.required]]
    });
    tiposFormulario: BaseEnum[] = TipoFormulario.TiposFormulario;

    ngOnInit(): void {
        this.listarCondicionantesPorParecer(this.parecerTecnico.id);
    }

    onArquivoGeoChange(fileInputEvent: any): void {
        if (fileInputEvent.target.files) {
            this.arquivoGeo = fileInputEvent.target.files[0];
        }
    }

    onNewCondicionante(): void {
        this.newCondicionanteDialog(
            this.parecerTecnico
        );
    }

    onAddGeoFile(parecerTecnicoCondicionante: ParecerTecnicoCondicionante): void {
        if (this.geoForm.valid) {
            this.newGeoFile(parecerTecnicoCondicionante, this.parecerTecnico.id);
        }
    }

    async pesquisaCondicionantes() {
        this.condicionantesTemplates = await this.condicionanteTemplateService.pesquisaCondPorComentario(this.comentario).toPromise();
        this.condicionantesPareceresTecnicos = await this.service.pesquisaCondPorComentario(this.comentario).toPromise();

        if (this.condicionantesTemplates.length > 0) {
            this.tratarNumeroItemCondicionantesTemplate();
            this.trataCondicionantesTemplate();
        }

        if (this.condicionantesPareceresTecnicos.length > 0) {
            this.tratarNumeroItemCondicionantes();
            this.trataCondicionantesDePareceres();
        }

    }

    tratarNumeroItemCondicionantesTemplate(): void {
        let condicionantesGerais = this.condicionantesTemplates.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES GERAIS');
        let condicionantesEspecificas = this.condicionantesTemplates.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES ESPECÍFICAS');
        let condicionantesTecnicas = this.condicionantesTemplates.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES TÉCNICAS');

        condicionantesGerais.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesEspecificas.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesTecnicas.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesGerais = condicionantesGerais.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        condicionantesEspecificas = condicionantesEspecificas.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        condicionantesTecnicas = condicionantesTecnicas.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        this.condicionantesTemplates = [];

        this.condicionantesTemplates.push(...condicionantesGerais);
        this.condicionantesTemplates.push(...condicionantesEspecificas);
        this.condicionantesTemplates.push(...condicionantesTecnicas);
    }

    tratarNumeroItemCondicionantes(): void {
        let condicionantesGerais = this.condicionantesPareceresTecnicos.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES GERAIS');
        let condicionantesEspecificas = this.condicionantesPareceresTecnicos.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES ESPECÍFICAS');
        let condicionantesTecnicas = this.condicionantesPareceresTecnicos.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES TÉCNICAS');

        condicionantesGerais.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesEspecificas.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesTecnicas.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesGerais = condicionantesGerais.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        condicionantesEspecificas = condicionantesEspecificas.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        condicionantesTecnicas = condicionantesTecnicas.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        this.condicionantesPareceresTecnicos = [];

        this.condicionantesPareceresTecnicos.push(...condicionantesGerais);
        this.condicionantesPareceresTecnicos.push(...condicionantesEspecificas);
        this.condicionantesPareceresTecnicos.push(...condicionantesTecnicas);
    }

    tratarNumeroItemCondicionantesParecerAtual(condicionantes): any {
        let condicionantesGerais = condicionantes.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES GERAIS');
        let condicionantesEspecificas = condicionantes.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES ESPECÍFICAS');
        let condicionantesTecnicas = condicionantes.filter(cod => cod.tipoCondicionante.descricao === 'CONDICIONANTES TÉCNICAS');

        condicionantesGerais.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesEspecificas.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesTecnicas.forEach((cond, i) => {
            cond['numeroSeq'] = i + 1;
        });

        condicionantesGerais = condicionantesGerais.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        condicionantesEspecificas = condicionantesEspecificas.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        condicionantesTecnicas = condicionantesTecnicas.sort((a, b) =>
            a.numeroSeq - b.numeroSeq
        );

        condicionantes = [];

        condicionantes.push(...condicionantesGerais);
        condicionantes.push(...condicionantesEspecificas);
        condicionantes.push(...condicionantesTecnicas);

        return condicionantes;
    }

    trataCondicionantesTemplate(): void {
        const groupedObj = this.condicionantesTemplates.reduce((prev, cur) => {
            const descricaoTipo = cur['tipoCondicionante'].descricao;
            if (!prev[descricaoTipo]) {
                prev[descricaoTipo] = [cur];
            } else {
                prev[descricaoTipo].push(cur);
            }
            return prev;
        }, {});
        this.condicionantesTemplates = Object.keys(groupedObj).map(key => ({key, value: groupedObj[key]}));
    }

    trataCondicionantesDePareceres(): void {
        const groupedObj = this.condicionantesPareceresTecnicos.reduce((prev, cur) => {
            const descricaoTipo = cur['tipoCondicionante'].descricao;
            if (!prev[descricaoTipo]) {
                prev[descricaoTipo] = [cur];
            } else {
                prev[descricaoTipo].push(cur);
            }
            return prev;
        }, {});
        this.condicionantesPareceresTecnicos = Object.keys(groupedObj).map(key => ({key, value: groupedObj[key]}));
    }

    limparPesquisaCond(): void {
        this.comentario = '';
        this.condicionantesPareceresTecnicos = [];
        this.condicionantesTemplates = [];
    }

    private newGeoFile(
        parecerTecnicoCondicionante: ParecerTecnicoCondicionante,
        parecerTecnicoId: number): void {
        const file = this.arquivoGeo as File;
        const ext = this.arquivoGeo.name.split('.').pop();
        let metodo = '';
        if (ext === 'shp') {
            metodo = 'SHAPE';
        } else if (ext === 'kml') {
            metodo = 'KML';
        } else {
            this.snackBarService.showAlert(
                'Arquivo com extensão não permitida.');
            return;
        }
        this.service.createMultipartGeo(
            parecerTecnicoId,
            parecerTecnicoCondicionante.id,
            'SHAPE',
            file
        ).subscribe(
            () => {
                this.snackBarService.showSuccess(
                    'Dado geoespacial enviado com sucesso.');
            },
            (e) =>
                this.snackBarService.showError(
                    'Erro ao salvar dado geoespacial.', e)
        );
    }

    private newCondicionanteDialog(parecer?: ParecerTecnico): void {
        const dialogRef = this.dialog.open(ParecerTecnicoCondicionanteDialogComponent, {
            width: '1300px',
            data: {parecer: parecer, tiposCondicionante: this.tiposCondicionante}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.listarCondicionantesPorParecer(this.parecerTecnico.id);
            }
        });
    }

    deleteCondicionanteDialog(parecerTecnicoCondicionante: ParecerTecnicoCondicionante): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {
                label: `${parecerTecnicoCondicionante.id} - Item ${parecerTecnicoCondicionante.numero}`
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.deleteCondicionante(parecerTecnicoCondicionante);
            }
        });
    }

    deleteCondicionante(parecerTecnicoCondicionante: ParecerTecnicoCondicionante): void {
        this.service
            .deleteById(this.parecerTecnico.id, parecerTecnicoCondicionante.id)
            .subscribe(() => {
                    this.listarCondicionantesPorParecer(this.parecerTecnico.id);
                    this.snackBarService.showSuccess('Registro excluido com sucesso.');
                },
                error => {
                    this.snackBarService.showError('Erro ao processar sua solicitação', error);
                });
    }

    editCondicionanteDialog(parecerTecnicoCondicionante: ParecerTecnicoCondicionante): void {

        parecerTecnicoCondicionante.parecerTecnico = this.parecerTecnico;

        const dialogRef = this.dialog.open(ParecerTecnicoCondicionanteDialogComponent, {
            width: '1300px',
            data: {
                parecerTecnicoCondicionante: parecerTecnicoCondicionante,
                tiposCondicionante: this.tiposCondicionante
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.listarCondicionantesPorParecer(this.parecerTecnico.id);
            }
        });
    }

    copiarCondicionanteDialog(parecerTecnicoCondicionante: ParecerTecnicoCondicionante = null, condicionanteTemplate: CondicionanteTemplate = null): void {

        if (parecerTecnicoCondicionante) {
            parecerTecnicoCondicionante.parecerTecnico = this.parecerTecnico;

            const dialogRef = this.dialog.open(ConfirmarCopiarCondicionanteDialogComponent, {
                width: '300px'
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.copiarCondicionante(parecerTecnicoCondicionante);
                }
            });
        } else if (condicionanteTemplate) {

            const newParecerCondicionante = new ParecerTecnicoCondicionante();
            newParecerCondicionante.parecerTecnico = this.parecerTecnico;
            newParecerCondicionante.descricao = condicionanteTemplate.descricao;
            newParecerCondicionante.tipoCondicionante = condicionanteTemplate.tipoCondicionante;
            newParecerCondicionante.situacao = condicionanteTemplate.situacao;
            newParecerCondicionante.numero = 1;

            const dialogRef = this.dialog.open(ConfirmarCopiarCondicionanteDialogComponent, {
                width: '300px'
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.copiarCondicionante(newParecerCondicionante);
                }
            });
        }
    }

    private listarCondicionantesPorParecer(parecerId: number): any {
        this.tiposCondicionante = []; // reset
        this.service.getByParecer(parecerId).subscribe(condicionantes => {
            condicionantes = this.tratarNumeroItemCondicionantesParecerAtual(condicionantes);
            const groupedObj = condicionantes.reduce((prev, cur) => {
                const descricaoTipo = cur['tipoCondicionante'].descricao;
                if (!prev[descricaoTipo]) {
                    prev[descricaoTipo] = [cur];
                } else {
                    prev[descricaoTipo].push(cur);
                }
                return prev;
            }, {});
            this.tiposCondicionante = Object.keys(groupedObj).map(key => ({key, value: groupedObj[key]}));
        });
    }

    private copiarCondicionante(parecerTecnicoCondicionante: ParecerTecnicoCondicionante): void {
        this.service
            .copiarCondicionante(this.parecerTecnico.id, parecerTecnicoCondicionante)
            .subscribe(() => {
                    this.listarCondicionantesPorParecer(this.parecerTecnico.id);
                    this.snackBarService.showSuccess('Condicionante copiada com sucesso.');
                },
                error => {
                    this.snackBarService.showError('Erro ao processar sua solicitação', error);
                });
    }

    getGescricaoFromTipoFormulario(tipoFormulario: string): string {

        switch (tipoFormulario) {
            case 'QUALIDADE_AGUA_SUBTERRANEA':
                return '1';
            case 'QUALIDADE_SOLO':
                return '2';
            case 'QUALIDADE_AGUA_SUPERFICIAL':
                return '3';
            case 'CONTROLE_NIVEL_ESTATICO':
                return '4';
        }
    }
}
