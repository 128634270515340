import {Component, Inject, OnInit} from '@angular/core';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Requerimento} from '../../../requerimento.model';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {RequerimentoOutorgaService} from '../../requerimento-outorga.service';
import {RequerimentoOutorga} from '../../requerimento-outorga.model';
import {PontoService} from "../../../../../shared/services/ponto.service";

@Component({
    selector: 'app-importar-pontos-simlam',
    templateUrl: './importar-pontos-simlam.component.html',
    styleUrls: ['./importar-pontos-simlam.component.scss']
})
export class ImportarPontosSimlamComponent implements OnInit {

    pontosSelecionados: Ponto[];

    constructor(
        private pontoService: PontoService,
        private snackBarService: SnackBarService,
        @Inject(MAT_DIALOG_DATA) public data: ImportarPontosSimlamData,
        private dialogRef: MatDialogRef<ImportarPontosSimlamComponent>) {
    }

    ngOnInit(): void {
    }

    confirm(): void {
        this.pontoService.postPontos(this.data.requerimentoOutorga, this.pontosSelecionados)
            .subscribe(() => this.dialogRef.close(true),
                error => this.snackBarService.showError('Erro ao criar pontos', error));
    }
}

export interface ImportarPontosSimlamData {
    requerimentoOutorga: RequerimentoOutorga;
    requerimento: Requerimento;
    pontos: Ponto[];
}
