import {BaseModel} from 'app/main/shared/models/base.model';

export class CaptacaoInsignificante extends BaseModel {
    constructor(
        public id?: number,
        public dbo?: number,
        public fosforoTotal?: number,
        public nitrogenioTotal?: number,
        public caracterizacaoAtividade?: string
    ) {
        super();
    }

    static fromJson(json: any): CaptacaoInsignificante {
        if (json === undefined || json === null) {
            return null;
        }
        return new CaptacaoInsignificante(
            json.id,
            json.dbo,
            json.fosforoTotal,
            json.nitrogenioTotal,
            json.caracterizacaoAtividade
        );
    }
}
