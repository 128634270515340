import {MesVazao} from '../dialog-disponibilidade-hidrica.component';

export class VazaoDto {
    constructor(
        public janeiro?: number,
        public fevereiro?: number,
        public marco?: number,
        public abril?: number,
        public maio?: number,
        public junho?: number,
        public julho?: number,
        public agosto?: number,
        public setembro?: number,
        public outubro?: number,
        public novembro?: number,
        public dezembro?: number,
    ) {
    }

    static fromJson(json: any): VazaoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new VazaoDto(
            json.janeiro,
            json.fevereiro,
            json.marco,
            json.abril,
            json.maio,
            json.junho,
            json.julho,
            json.agosto,
            json.setembro,
            json.outubro,
            json.novembro,
            json.dezembro,
        );
    }

    static fromMesesTodos(mes: MesVazao): VazaoDto {
        return new VazaoDto(mes.vazao, mes.vazao, mes.vazao, mes.vazao, mes.vazao, mes.vazao,
            mes.vazao, mes.vazao, mes.vazao, mes.vazao, mes.vazao, mes.vazao);
    }

    static fromMeses(mes: Array<MesVazao>): VazaoDto {
        const obj = new VazaoDto(0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0);
        mes.forEach(item => {
            obj[item.mes.toLowerCase()] = item.vazao;
        });
        return obj;
    }

    static fromJsons(json: any): VazaoDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(VazaoDto.fromJson(j)));
            return resources;
        }
    }
}
