import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {SubstanciaQuimica} from '../substancia-quimica/substancia-quimica.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class CompostoQuimicoListResolver extends ListResolver<SubstanciaQuimica> {
    constructor(service: SubstanciaQuimicaService) {
        super(service);
    }
}

@Injectable()
export class CompostoQuimicoResolver extends DetailResolver<SubstanciaQuimica> {
    constructor(
        service: SubstanciaQuimicaService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class SubstanciaQuimicaService extends BaseService<SubstanciaQuimica> {
    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            SubstanciaQuimica.prototype,
            SubstanciaQuimica.fromJson
        );
    }

    getSubstanciasQuimicasByComposto(idCompostoQuimico: number): Observable<SubstanciaQuimica[]> {
        const url = `${this.urlResource}/api/compostos-quimicos/${idCompostoQuimico}/substancias-quimicas`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }
}
