import {BaseModel} from 'app/main/shared/models/base.model';

export class EmpreendimentoEstruturaSuporte extends BaseModel  {
    constructor(
        public id?: number,
        public empreendimentoSubEstacao?: number,
        public estruturaSuporte?: number,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoEstruturaSuporte {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoEstruturaSuporte(
            json.id,
            json.empreendimentoSubEstacao,
            json.estruturaSuporte,
            json.descricao,
        );
    }

    static fromJsons(json: any): EmpreendimentoEstruturaSuporte[] {
        const empreendimentos: EmpreendimentoEstruturaSuporte[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(empreendimento => empreendimentos.push(this.fromJson(empreendimento)));
            return empreendimentos;
        }
    }

}
