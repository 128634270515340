import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {BaseModel} from 'app/main/shared/models/base.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {
    ErrosAbaRequerimento
} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {RequerimentoLicenciamentoService} from "../../requerimento-licenciamento/requerimento-licenciamento.service";
import {
    AtividadeParametroEntradaArrecadacaoService
} from "../../../../shared/services/arrecadacao-service/atividade-parametro-entrada-arrecadacao.service";
import {
    AtividadeCalculoArrecadacaoService
} from "../../../../shared/services/arrecadacao-service/atividade-calculo-arrecadacao.service";
import {
    AtividadeArrecadacaoService
} from "../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service";
import {ObjetivoLicenciamentoService} from "../../../../shared/services/objetivo-licenciamento.service";
import {
    ObjetivoLicenciamentoSubatividadeService
} from "../../../../shared/services/objetivo-licenciamento-subatividade.service";
import {
    ConfiguracaoLicenciamentoService
} from "../../../../shared/services/licenciamento-config-service/configuracao-licenciamento.service";
import {
    AtividadeConfiguracaoLicenciamentoService
} from "../../../../shared/services/licenciamento-config-service/atividade-configuracao-licenciamento.service";
import {
    ObjetivoConfiguracaoLicenciamentoService
} from "../../../../shared/services/licenciamento-config-service/objetivo-configuracao-licenciamento.service";
import {
    ObjetivoLicenciamentoAmbientalService
} from "../../../../shared/services/licenciamento-config-service/objetivo-licenciamento-ambiental.service";
import {
    AtividadeProjectionArrecadacao
} from "../../../../shared/models/arrecadacao-models/atividade-projection-arrecadacao.model";
import {ObjetivoLicenciamento} from "../../../../shared/models/objetivo-licenciamento.model";
import {RequerimentoTermoReferenciaEspecificoService} from "../requerimento-termo-referencia-especifico.service";
import {ProcessoService} from "../../../../shared/services/processo.service";
import {Processo} from "../../../../shared/models/processo.model";
import {RequerimentoService} from "../../requerimento.service";
import {take} from "rxjs/operators";
import {ObjetivoParametrizacaoObjetivoService} from "../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service";
import {DominiosService} from "../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../shared/models/dominio-dto.model";
import {TitulacaoService} from "../../../titulacoes/titulacao.service";
import {TipoRequerimentoService} from "../../../tipos-requerimento/tipo-requerimento.service";
import {TipoTitulacaoService} from "../../../../shared/services/arrecadacao-service/tipo-titulacao.service";
import {TipoProcessoEnum} from "../../../tipos-processo/tipo-processo.enum";
import {AtividadeCalculoArrecadacao} from "../../../../shared/models/arrecadacao-models/atividade-calculo-arrecadacao.model";
import {ConfirmacaoDialogComponent} from "../../../parecer-tecnico/parecer-tecnico-conclusoes/parecer-tecnico-conclusoes-dialog/confirmacao-dialog/confirmacao-dialog.component";
import {FormularioReqLicenciamentoEmp} from "../../../../shared/models/formulario-req-licenciamento-emp.dto";
import {LancamentoTaxaDto} from "../../../../shared/models/lancamento-taxa-dto.model";
import {ParametroEntradaArrecadacao} from "../../../../shared/models/arrecadacao-models/parametro-entrada-arrecadacao.model";

@Component({
    selector: 'app-requerimento-formulario-termo-referencia-especifico',
    templateUrl: 'requerimento-formulario-termo-referencia-especifico.component.html',
    styleUrls: ['requerimento-formulario-termo-referencia-especifico.component.scss'],
    animations: fuseAnimations
})
export class RequerimentoFormularioTermoReferenciaEspecificoComponent extends BaseTabRequerimentoComponent implements OnInit {

    constructor(
        protected dialog: MatDialog,
        protected requerimentoService: RequerimentoService,
        protected requerimentoFormularioLicenciamentoService: RequerimentoTermoReferenciaEspecificoService,
        protected requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private formBuilder: FormBuilder,
        private processoService: ProcessoService,
        private atividadeCalculoParametroEntradaService: AtividadeParametroEntradaArrecadacaoService,
        private atividadeCalculoService: AtividadeCalculoArrecadacaoService,
        private atividadeArrecadacaoService: AtividadeArrecadacaoService,
        private snackBarService: SnackBarService,
        private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private objetivoLicenciamentoSubatividadeService: ObjetivoLicenciamentoSubatividadeService,
        private configuracaoLicenciamentoService: ConfiguracaoLicenciamentoService,
        private atividadeConfiguracaoLicenciamentoService: AtividadeConfiguracaoLicenciamentoService,
        private objetivoConfiguracaoLicenciamentoService: ObjetivoConfiguracaoLicenciamentoService,
        protected objetivoLicenciamentoAmbientalService: ObjetivoLicenciamentoAmbientalService,
        private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
        private dominiosService: DominiosService,
        private licenciamentoTitulacaoService: TitulacaoService,
        private tipoRequerimentoService: TipoRequerimentoService,
        private tipoTitulacaoService: TipoTitulacaoService,
        private atividadeCalculoArrecadacaoService: AtividadeCalculoArrecadacaoService,
        private atividadeParametroEntradaArrecadacaoService: AtividadeParametroEntradaArrecadacaoService,
        private titulacaoService: TitulacaoService,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.FORMULARIO.component = this;

        this.form = this.formBuilder.group({
            id: [null],
            requerimento: [this.requerimento, [Validators.required]],
            idAtividade: [null, [Validators.required]],
            idGrupoAtividade: [null, [Validators.required]],
            descricaoAtividade: [null],
            descricaoGrupoAtividade: [null],
            objetivoLicenciamento: [null, [Validators.required]]
        });
    }

    objetivos = [];
    objetivosListados = []
    objetivo: any;
    objetivosSubAtividade = [];
    objetivosPrincipais: Array<any>;
    objetivosOutros: Array<any>;
    objetivosPrincipaisSelecionados = []
    arrayRegraLI = []
    objetivoSelecionado: any
    objetivoOutroSelecionado: any
    objetivosOutrosSelecionados = []
    objetivosDeVinculo: Array<any> = [];
    objsIsento = false

    form: FormGroup;
    displayedColumns: string[] = ['objetivo', 'acao'];
    displayedAtvColumns: string[] = ['atividade', 'acao'];
    reqProtocoloDisplay: string[] = ['numeroProtocoloVinculado', 'acao'];
    atvProjectionEncontrados = []
    atvProjection: Array<AtividadeProjectionArrecadacao> = [];
    atividadeProjection: any;
    reqsProtocoloVinculadoSave: Array<Processo> = [];
    @ViewChild('parametro', {static: false})
    element: ElementRef;
    numeroProtocolo = this.formBuilder.control('');
    configuracao: any

    tiposObjetivo: Array<any> = [];
    titulos: Array<any> = [];
    tiposTitulacao: Array<any> = [];
    tiposRequerimento: Array<DominioDto> = [];

    atividadeLicenciada = [];
    atividadeLicenciadaEncontrado = [];
    isAtividadeComplementar = true
    atividadeAut: any
    parametroEntradaAut: any = {}
    pisiculturaExotico = false
    regraApp = false
    parametroCalculoListAut: any;
    atividadeSemParametroeCaculoAut = false;
    atividadeCalculo: AtividadeCalculoArrecadacao[];
    atividadeCalculoAut: AtividadeCalculoArrecadacao[];
    atividadeSemParametroeCaculo = false;
    parametroCalculoList: any;
    errorStatusCalculoParametroEntrada: any;

    calcularUpfRetornoDtoList = []
    calcularUpfRetornoDtoListAut = []
    popUp = false;
    erroDeCalculo = false
    parametrosCalculoLista: any = [];
    parametrosCalculoListaAut: any = [];
    mostrarOutraObjetivos = true;
    parametroEntrada: any = {}
    parametroEntradas: any;
    parametroEntradasAut: any;
    habilitaAtividadeLicenciada = true
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoEmp;

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    async ngOnInit() {
        await this.getListaObjetivos()
        // await this.getListaTiposAtuacao();
        await this.getListaTitulos();
        await this.getListaTiposRequerimento();
        await this.getListaTiposObjetivo();
        await this.getListaTiposTitulacao();

        if (this.requerimento.tipoProcesso.id === TipoProcessoEnum.LICENCIAMENTO_TRIFASICO_LAS
            || this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS"
            || this.requerimento.tipoProcesso.instanciaRequerimento === "TERMO_REFERENCIA") {
            await this.getObjetivosApresentados()
            // if (this.requerimento.idTipoRequerimentoTifLas != 2) {
            //     if (this.requerimento.tipoProcesso.id === 103 && this.requerimento.numeroProtocoloVinculado) {
            //         await this.criaOuBuscaReqLicenciamentoEmAndamento()
            //     } else {
            //         await this.criaOuBuscaReqLicenciamento()
            //     }
            // } else {
            //     await this.criaOuBuscaReqLicenciamentoEmAndamento()
            // }
        }

        if (this.requerimento.numeroProtocoloVinculado) {
            this.reqsProtocoloVinculadoSave = [this.requerimento];
        }

        this.requerimentoFormularioLicenciamentoService.getByRequerimentoId(this.requerimento.id).subscribe(requerimentoLicenciamento => {
            if (requerimentoLicenciamento) {
                this.requerimentoLicenciamento = requerimentoLicenciamento;
                this.patchForm();

                this.recuperarObjetivos(this.requerimentoLicenciamento.id);
                // this.recuperarAtividadeSalva();
            }
        });

    }

    recuperarObjetivos(id): void {
        this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(id).pipe(take(1)).subscribe(data => {
            console.log("Objetivo salvo: ", data)
            this.objetivosSubAtividade = [];
            let objetivo = data.map(obj => {
                return {
                    objetivo: this.objetivos.find(o => o.id === obj.idObjetivoLicenciamentoAmbiental),
                    vinculo: obj
                }
            });
            this.objetivosSubAtividade.push(objetivo[0].objetivo);
            this.objetivosSubAtividade = this.objetivosSubAtividade.slice()
            this.form.patchValue({
                objetivoLicenciamento: objetivo[0].objetivo ? objetivo[0].objetivo.id : null
            });
        });
    }

    private async listarObjetivos() {
        await this.configuracaoLicenciamentoService.getAllAtivos().toPromise().then(async configLicen => {
            this.configuracao = configLicen.find(conf => conf.idTipoProcesso === this.requerimento.tipoProcesso.id)
            if (this.configuracao) {
                await this.getObjetivosConfiguracao(this.configuracao.id);
            }
        })

        // await this.objetivoLicenciamentoAmbientalService.getAll().toPromise().then(objs => {
        //     this.objetivosListados = objs
        // }).catch(() => {
        //     this.snackBarService.showError('Ocorreu um erro ao buscar a lista de objetivos');
        // });
    }

    private async getObjetivosConfiguracao(id: number) {
        let objs = await this.objetivoConfiguracaoLicenciamentoService.getByConfiguracao(id).toPromise();
        this.objetivosListados = objs.map(obj => {
            return {
                objetivo: this.objetivos.find(o => o.id === obj.objetivoLicenciamento),
                vinculo: obj
            }
        });
    }

    private patchForm() {
        this.form.patchValue({id: this.requerimentoLicenciamento.id});
        this.form.patchValue({requerimento: this.requerimento});
        this.form.patchValue({idAtividade: this.requerimentoLicenciamento.idAtividade});
        this.form.patchValue({idGrupoAtividade: this.requerimentoLicenciamento.idGrupoAtividade});
        this.form.patchValue({descricaoAtividade: this.requerimentoLicenciamento.descricaoAtividade});
        this.form.patchValue({descricaoGrupoAtividade: this.requerimentoLicenciamento.descricaoGrupoAtividade});
    }

    async selecionarObjetivoNaTable(adicionando) {
        if (!this.objetivoSelecionado) {
            this.snackBarService.showAlert('Selecione um objetivo para adicionar na tabela.')
        } else if (this.objetivosSubAtividade.length == 0) {
            this.objetivosSubAtividade = [];
            this.objetivosSubAtividade.push(this.objetivoSelecionado);
            this.objetivosSubAtividade = this.objetivosSubAtividade.slice()

            let passou = await this.criaObjetivoLicenciamento(this.objetivoSelecionado)
            if (passou) {
                await this.preencheTabelaObjetivo(false, adicionando)
            }
            // await this.getAtividadesConfiguracao()
            await this.buscarAtividadesDosObjetivos()
        } else {
            this.snackBarService.showAlert('Já foi selecionado um objetivo')
            return
        }

    }

    private async getAtividadesConfiguracao() {
        this.atvProjectionEncontrados = []
        //
        // let atividadeCalculo = await this.atividadeCalculoArrecadacaoService
        //     .buscarAtividadeCalculosPorIdTipoTitulacao(this.objetivoSelecionado.objetivo.tipoTitulacao.id).toPromise()

        let atvs = await this.atividadeConfiguracaoLicenciamentoService.getByConfiguracao(this.configuracao.id).toPromise();
        for (let atv of atvs) {
            this.atvProjectionEncontrados.push({
                atividade: await this.atividadeArrecadacaoService.getById(atv.atividade).toPromise(),
                vinculo: atv
            });
        }
    }

    selecionarAtividade(atividade) {
        this.atividadeProjection = atividade
    }

    async selecionarAtividadeNaTable() {
        if (!this.atividadeProjection) {
            this.snackBarService.showAlert('Selecione uma atividade para adicionar na tabela.')
        } else if (this.atvProjection.length == 0) {
            this.form.patchValue({idAtividade: this.atividadeProjection.id});
            this.form.patchValue({idGrupoAtividade: this.atividadeProjection.atividadePai ? this.atividadeProjection.atividadePai.id : null});
            this.form.patchValue({descricaoAtividade: this.atividadeProjection.descricao});
            this.form.patchValue({descricaoGrupoAtividade: this.atividadeProjection.atividadePai ? this.atividadeProjection.atividadePai.descricao : null});
            //TODO verificar se está sendo criado o requerimentoLicenciamento ao abrir o requerimento.
            await this.salvaAtividadeLicenciada()
            await this.obterAtividadeCalculoParametroEntrada()
        } else {
            this.snackBarService.showAlert('Já foi selecionado uma atividade')
        }
    }

    private async criaObjetivoLicenciamento(objetivo) {
        // let objetivoLicenciamento = new ObjetivoLicenciamento()
        // objetivoLicenciamento.requerimentoLicenciamento = this.requerimentoLicenciamento
        // objetivoLicenciamento.descricaoObjetivo = this.objetivosSubAtividade[0].descricao
        // objetivoLicenciamento.idObjetivoLicenciamentoAmbiental = this.objetivosSubAtividade[0].id
        // objetivoLicenciamento.idTipoObjetivo = this.objetivosSubAtividade[0].idTipoProcesso
        // await this.objetivoLicenciamentoService.create(objetivoLicenciamento).toPromise()

        let passou = false;
        let objetivoLicenciamento = new ObjetivoLicenciamento()
        objetivoLicenciamento.requerimentoLicenciamento = this.requerimentoLicenciamento
        objetivoLicenciamento.descricaoObjetivo = objetivo.objetivo.objetivo.descricao
        objetivoLicenciamento.idObjetivoLicenciamentoAmbiental = objetivo.objetivo.objetivo.id
        objetivoLicenciamento.idTipoObjetivo = objetivo.objetivo.tipoObjetivo.id

        await this.objetivoLicenciamentoService.create(objetivoLicenciamento).toPromise().then(() => passou = true);
        return passou;
    }

    // async salvarRequerimentoLicenciamento() {
    //     await this.requerimentoLicenciamentoService.create(this.form.value).toPromise().then(async requerimentoLicenciamento => {
    //         this.requerimentoLicenciamento = requerimentoLicenciamento;
    //         this.form.patchValue({id: requerimentoLicenciamento.id});
    //         this.form.patchValue({idAtividade: requerimentoLicenciamento.idAtividade});
    //         this.form.patchValue({idGrupoAtividade: requerimentoLicenciamento.idGrupoAtividade});
    //         this.form.patchValue({idGrupoAtividade: requerimentoLicenciamento.idGrupoAtividade});
    //         this.form.patchValue({descricaoAtividade: requerimentoLicenciamento.descricaoAtividade});
    //         this.form.patchValue({descricaoGrupoAtividade: requerimentoLicenciamento.descricaoGrupoAtividade});
    //         // await this.criaObjetivoLicenciamento()
    //         await this.recuperarAtividadeSalva()
    //     }).catch(() => this.snackBarService.showError('Erro ao tentar salvar a atividade'));
    // }

    private async salvaAtividadeLicenciada() {
        console.log("Atividade selecionada", this.atividadeProjection)
        this.requerimentoLicenciamento.idAtividade = this.atividadeProjection.atividade.id
        if (this.atividadeProjection.poluicao) {
            this.requerimentoLicenciamento.ppd = this.atividadeProjection.poluicao.descricao
        }
        this.requerimentoLicenciamento.descricaoAtividade = this.atividadeProjection.atividade.descricao
        this.requerimentoLicenciamento.idGrupoAtividade = this.atividadeProjection.atividade.atividadePai.id
        this.requerimentoLicenciamento.descricaoGrupoAtividade = this.atividadeProjection.atividade.atividadePai.descricao
        await this.requerimentoLicenciamentoService.update(this.requerimentoLicenciamento).toPromise().then( () => {
            this.atvProjection.push(this.atividadeProjection.atividade);
        })
    }

    recuperarAtividadeSalva() {
        this.atividadeArrecadacaoService.getAtividadeId(this.requerimentoLicenciamento.idAtividade).pipe(
            take(1)
        ).subscribe(atividade => {
            this.atvProjection = []
            this.atvProjection.push(atividade)
            this.atvProjection = this.atvProjection.slice()
        })
    }

    deletarRequerimentoLicenciamentoObjetivo(idObjetivoLicenciamento) {
        if (this.requerimentoLicenciamento.id) {
            this.objetivoLicenciamentoService.deletePorIds(this.requerimentoLicenciamento.id, idObjetivoLicenciamento).pipe(
                take(1)
            ).subscribe(() => {
                this.recuperarObjetivos(this.requerimentoLicenciamento.id);
                this.snackBarService.showSuccess('Deletado com sucesso');
            }, error => this.snackBarService.showError('Ocorreu um erro', error));
        } else {
            this.objetivosSubAtividade.splice(0, 1);
            this.objetivosSubAtividade = this.objetivosSubAtividade.slice()
        }
    }

    deletarRequerimentoLicenciamentoAtividade() {
        this.atvProjection.splice(0, 1);
        this.atvProjection = this.atvProjection.slice()
    }

    private resetAll() {
        this.atvProjection = []
        this.objetivosSubAtividade = []
        this.requerimentoLicenciamento = {};
        this.form.reset();
        this.form.patchValue({requerimento: this.requerimento});
    }

    async selecionarObjetivo(obs: any) {
        this.objetivoSelecionado = obs
    }

    async validarAba(erros: ErrosAbaRequerimento) {
        this.verificaObjsInseridos()

        if (this.objetivosSubAtividade.length == 0) {
            erros.push('É necessário selecionar um objetivo');
        }
        if (this.atvProjection.length == 0) {
            erros.push('É necessário selecionar uma atividade');
        }

        if (this.requerimento.tipoProcesso.instanciaRequerimento !== "AUTORIZACAO_OUTROS_DOCUMENTOS"
            || (this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" && this.requerimento.numeroProtocoloVinculado)) {
            if (this.atividadeCalculo && this.atividadeCalculo.length > 0 && !this.objsIsento) {
                await this.iniciarCalculo()
                if (this.erroDeCalculo && !this.popUp) {
                    erros.push('Sua atividade não é passível de licenciamento');
                } else if (this.popUp) {
                    erros.push('PopUp')
                }
            } else {
                if (this.requerimento.tipoProcesso.instanciaRequerimento === "TERMO_REFERENCIA" && !this.requerimento.numeroProtocoloVinculado) {
                    await this.iniciarCalculo()
                    if (this.erroDeCalculo && !this.popUp) {
                        erros.push('Sua atividade não é passível de licenciamento');
                    } else if (this.popUp) {
                        erros.push('PopUp')
                    }
                } else {
                    if (!this.objsIsento) {
                        erros.push('Não existe licenças para a ser licenciada, verifica os campos do formulário')
                    }
                }

            }
        } else {
            if (this.atividadeCalculoAut && this.atividadeCalculoAut.length > 0 && !this.objsIsento) {
                await this.iniciarCalculo()
                if (this.erroDeCalculo && !this.popUp) {
                    erros.push('Sua atividade não é passível de licenciamento');
                } else if (this.popUp) {
                    erros.push('PopUp')
                }
            } else {
                if (!this.objsIsento) {
                    erros.push('Não existe licenças para a ser licenciada, verifica os campos do formulário')
                }
            }
        }

        if (erros.length === 0) {
            if (!await this.salvar()) {
                erros.push('Ocorreu um erro ao processar os dados do formulário. Tente novamente mais tarde.')
            }

            this.objetivoLicenciamento = this.objetivosPrincipaisSelecionados
            if (this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" &&
                !this.requerimento.numeroProtocoloVinculado) {
                this.objetivoLicenciamento = this.objetivosOutrosSelecionados
            }
            if (erros.length === 0) {
                return await Promise.all([]).then(() => Promise.resolve());
            }
        }
        return Promise.resolve();
    }

    async salvar() {
        let validado = false
        await this.requerimentoFormularioLicenciamentoService.salvarFormularioCalculoDeLicencaEmp(await this.montarFormularioReqLicenDTO()).toPromise().then(formularioReqLicenciamentoDto => {
            this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
            validado = true
        }).catch(error => {
            this.snackBarService.showError('Ocorreu um erro', error);
        });

        return validado
    }

    private async montarFormularioReqLicenDTO() {
        this.formularioReqLicenciamentoDto = new FormularioReqLicenciamentoEmp();
        if (this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" && !this.requerimento.numeroProtocoloVinculado) {
            await this.objetivoAtividadeDeObjetivo()
        }
        this.formularioReqLicenciamentoDto.requerimentoLicenciamento = this.requerimentoLicenciamento
        this.formularioReqLicenciamentoDto.lancamentoTaxa = await this.retornaLancamentosTaxa();
        const lancamentoTaxaParamCalculoList = []
        for (let lancamentoTaxa of this.formularioReqLicenciamentoDto.lancamentoTaxa) {
            lancamentoTaxaParamCalculoList.push(...this.retornaLancTaxaParams(lancamentoTaxa));
        }
        this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList = lancamentoTaxaParamCalculoList
        if (this.atividadeSemParametroeCaculo) {
            this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = true;
        } else {
            this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = false;
        }

        return this.formularioReqLicenciamentoDto;
    }

    private retornaLancTaxaParams(lancamentoTaxa) {

        const lancamentoTaxaParamCalculoDto = [];

        if (!lancamentoTaxa.autorizacao) {
            if (this.parametroEntrada.valor) {
                lancamentoTaxaParamCalculoDto
                    .push(ParametroEntradaArrecadacao
                        .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(this.parametroEntrada, lancamentoTaxa, 'ENTRADA'));
            }

            if (this.objetivosPrincipais.length > 1) {
                this.parametrosCalculoLista.filter(param => limpaObjUnd(param.atividadeCalculo).find(atv => atv.id).id === lancamentoTaxa.idAtividadeCalculo)
                if (this.parametrosCalculoLista.length > 1) {
                    let parametrosCalculoLista = []
                    parametrosCalculoLista.push(this.parametrosCalculoLista.slice(-1)[0])
                    this.parametrosCalculoLista = parametrosCalculoLista
                }
            } else {
                this.parametrosCalculoLista.filter(param => limpaObjUnd(param.atividadeCalculo).find(atv => atv.id).id === lancamentoTaxa.idAtividadeCalculo)
            }

            this.parametrosCalculoLista.forEach(paramCalc => {
                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO'));
            });
        } else {
            if (this.parametroEntradaAut.valor && this.parametroEntrada.idAtividadeCalculo === lancamentoTaxa.idAtividadeCalculo) {
                lancamentoTaxaParamCalculoDto
                    .push(ParametroEntradaArrecadacao
                        .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(this.parametroEntradaAut, lancamentoTaxa, 'ENTRADA', null, true));

            }

            this.parametrosCalculoListaAut.filter(param => limpaObjUnd(param.atividadeCalculo).find(atv => atv.id).id === lancamentoTaxa.idAtividadeCalculo)

            this.parametrosCalculoListaAut.forEach(paramCalc => {
                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO', null, true));
            });
        }

        function limpaObjUnd(obj) {
            obj = obj.filter(function (i) {
                return i
            });
            return obj
        }

        return lancamentoTaxaParamCalculoDto;
    }

    private async retornaLancamentosTaxa() {
        await this.requerimentoFormularioLicenciamentoService.deleteEmp(this.requerimentoLicenciamento.id).toPromise()

        const lancamentosTaxa = []

        for (let licenca of this.calcularUpfRetornoDtoList) {
            const lancamentoTaxa = new LancamentoTaxaDto()
            lancamentoTaxa.requerimento = this.requerimento;
            lancamentoTaxa.qtdePonto = 1;
            lancamentoTaxa.qtdeTotalUPF = licenca.valorUpf;
            lancamentoTaxa.qtdeUnitarioUPF = licenca.valorUpf;
            lancamentoTaxa.codigoReceitaNatureza = licenca.atividadeCalculo.receitaNatureza.id.toString();
            lancamentoTaxa.codigoReceitaGrupo = licenca.atividadeCalculo.receitaNatureza.receitaGrupo.id.toString();
            lancamentoTaxa.idAtividadeCalculo = licenca.atividadeCalculo.id;

            // mudar para titulacão de acordo com o objetivo
            const titulacoes = await this.titulacaoService.getAll().toPromise()
            lancamentoTaxa.titulacao = titulacoes.find(titulacao => titulacao.descricao.toLowerCase().includes('Estudo Impacto Ambiental (EIA/RIMA)'.toLowerCase()));

            if (licenca['isenta']) {
                lancamentoTaxa.taxaIsenta = true;
            }

            lancamentosTaxa.push(lancamentoTaxa);
        }
        return lancamentosTaxa;
    }

    private async objetivoAtividadeDeObjetivo() {
        await this.atividadeArrecadacaoService
            .getByAtividadeNivelPorDesc(this.objetivosOutrosSelecionados[0].objetivo.objetivo.descricao)
            .toPromise().then(async data => {
                this.requerimentoLicenciamento.idAtividade = data[0].id
                this.requerimentoLicenciamento.descricaoAtividade = data[0].descricao
                this.requerimentoLicenciamento.idGrupoAtividade = data[0].idAtvPai
                await this.atividadeArrecadacaoService
                    .getAtividadeId(data[0].idAtvPai)
                    .toPromise().then(async dataAtvPai => {
                        this.requerimentoLicenciamento.descricaoGrupoAtividade = dataAtvPai.descricao
                    });
            });
    }

    private verificaObjsInseridos() {
        let todosObjs = []
        todosObjs.push(...this.objetivosPrincipaisSelecionados)
        todosObjs.push(...this.objetivosOutrosSelecionados)

        if (todosObjs.filter(obj => obj.objetivo.tipoTitulacao.id === 16).length === todosObjs.length) {
            this.objsIsento = true
        } else {
            this.objsIsento = false
        }
    }

    findByProtocolo() {
        if (this.reqsProtocoloVinculadoSave.length > 0) {
            this.snackBarService.showAlert('Protocolo já adicionado');
        } else if (this.numeroProtocolo.value.trim().length > 0) {
            this.processoService.findByNumero(this.numeroProtocolo.value.trim().replace('/', '')).subscribe(() => {
                this.requerimento.numeroProtocoloVinculado = this.numeroProtocolo.value.trim();
                this.requerimentoService.update(this.requerimento).subscribe(() => {
                    this.numeroProtocolo.patchValue('');
                    this.reqsProtocoloVinculadoSave = [...this.reqsProtocoloVinculadoSave, this.requerimento];
                }, e => {
                    this.requerimento.numeroProtocoloVinculado = null;
                    this.snackBarService.showError('Falha ao associar o protocolo', e);
                });
            }, e => this.snackBarService.showError('Não foi possível encontrar o protocolo solicitado', e));
        } else {
            this.snackBarService.showAlert('Insira o número do protocolo para fazer a consulta');
        }
    }

    deletarProtocolo() {
        const protocolo = this.requerimento.numeroProtocoloVinculado;
        this.requerimento.numeroProtocoloVinculado = null;
        this.requerimentoService.update(this.requerimento).subscribe(() => {
            this.reqsProtocoloVinculadoSave = [];
        }, e => {
            this.requerimento.numeroProtocoloVinculado = protocolo;
            this.snackBarService.showError('Falha ao associar o protocolo', e);
        });
    }

    numeroProcessoMask = (text: string): void => {
        text = text.replace(/\D/g, "");
        if (text && text.length > 4) {
            const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
            const year = text.slice(-4);
            this.numeroProtocolo.patchValue( `${initial}/${year}`);
        } else {
            this.numeroProtocolo .patchValue( text);
        }
    }

    private async getListaObjetivos() {
        this.objetivos = await this.objetivoLicenciamentoAmbientalService.getAll().toPromise();
    }

    async getObjetivosApresentados(){
        let objs;
        await this.objetivoParametrizacaoObjetivoService.getByParametrizacaoObjetivo(this.requerimento.idParametroObjetivo).toPromise().then(paramObjs => {
            objs = paramObjs
        });

        this.objetivos = objs.map(obj => ({
            objetivo: {
                objetivo: this.objetivos.find(o => o.id === obj.idObjetivoParametrizacao),
                tipoObjetivo: this.tiposObjetivo.find(o => o.id === obj.idTipoObjetivo),
                titulo: this.titulos.find(o => o.id === obj.idTituloEmitidoAlterado),
                tipoTitulacao: this.tiposTitulacao.find(o => o.id === obj.idArrecadacao),
                tipoRequerimento: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento),
                ordemSelecao: obj.ordemSelecao,
                isSelecaoExclusiva: obj.isSelecaoExclusiva,
                desabilitado: false
            },
            vinculo: obj
        }));

        this.objetivosPrincipais = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 1)
        console.log("Principal", this.objetivosPrincipais)
        this.objetivosOutros = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 2)
        // this.regraDeDesabilitarHabilitarObjetivos()
        if (this.requerimento.idTipoRequerimentoTifLas != 2) {
            this.iniciaRegrasLI()
        }
    }

    private iniciaRegrasLI() {
        if (this.requerimento.tipoProcesso.instanciaRequerimento !== "AUTORIZACAO_OUTROS_DOCUMENTOS") {
            let autorizacaoDesmate = this.objetivosOutros.find(outros => outros.objetivo.objetivo.descricao === 'Autorização de Desmate')
            if (autorizacaoDesmate) {
                let posicaoAutorizaDesmate = this.objetivosOutros.indexOf(autorizacaoDesmate)
                this.objetivosOutros.splice(posicaoAutorizaDesmate, 1)
                this.arrayRegraLI.push(autorizacaoDesmate)
            }

            let autorizacaoQueima = this.objetivosOutros.find(outros => outros.objetivo.objetivo.descricao === 'Autorização Queima Controlada')
            if (autorizacaoQueima) {
                let posicaoautorizacaoQueima = this.objetivosOutros.indexOf(autorizacaoQueima)
                this.objetivosOutros.splice(posicaoautorizacaoQueima, 1)
                this.arrayRegraLI.push(autorizacaoQueima)
            }

            this.objetivosOutros = this.objetivosOutros.slice()
        }
    }

    private async getListaTiposObjetivo() {
        await this.dominiosService.getDominio("TIPO_OBJETIVO").toPromise().then(tiposObjetivos => {
            this.tiposObjetivo = tiposObjetivos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista tipos objetivos');
        });
    }

    private async getListaTitulos() {
        await this.licenciamentoTitulacaoService.getAll().toPromise().then(titulos => {
            this.titulos = titulos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar os títulos');
        });
    }

    private async getListaTiposRequerimento() {
        await this.tipoRequerimentoService.getAll().toPromise().then(tiposReq => {
            this.tiposRequerimento = tiposReq
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar os títulos');
        });
    }

    private async getListaTiposTitulacao() {
        await this.tipoTitulacaoService.getAll().toPromise().then(tipoTitulacao => {
            this.tiposTitulacao = tipoTitulacao
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista de tipos titulação');
        });
    }

    async buscarAtividadesDosObjetivos() {
        this.atividadeLicenciada = []
        for (let objetivosPrincipais of this.objetivosSubAtividade) {
            let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculosPorIdTipoTitulacao(objetivosPrincipais.objetivo.tipoTitulacao.id).toPromise()
            this.atividadeLicenciada.push(...atividadeCalculo)
        }

        let licenciaLOVin = this.objetivosSubAtividade.find(obj => obj.objetivo.objetivo && obj.objetivo.objetivo.descricao === 'LO - Licença de Operação - Vinculada')
        let las = this.objetivosSubAtividade.find(obj => obj.objetivo.objetivo && obj.objetivo.objetivo.descricao === 'LAS - Licença Ambiental Simplificada')
        if (licenciaLOVin || las) {
            this.isAtividadeComplementar = false
        }

        const key = 'id';
        this.atividadeLicenciada = [...new Map(this.atividadeLicenciada.map(item =>
            [item[key], item])).values()];
        this.atividadeLicenciadaEncontrado = this.atividadeLicenciada
    }

    selecionarOutroObjetivo(obs: any) {
        this.objetivoOutroSelecionado = obs
    }

    async selecionarOutroObjetivoNaTable() {
        if (this.objetivosOutrosSelecionados.find(obj => obj.objetivo.objetivo.id === this.objetivoOutroSelecionado.objetivo.objetivo.id)) {
            this.snackBarService.showAlert('Objetivo já selecionado.');
        } else if (this.objetivosOutrosSelecionados.length > 0 && this.objetivoOutroSelecionado.objetivo.isSelecaoExclusiva) {
            this.snackBarService.showAlert('Existe um objetivo exclusivo já selecionado ou objetivo escolhido é exclusivo.');
        } else if (this.objetivosOutrosSelecionados.length == 1 && this.objetivosOutrosSelecionados.filter(obj => obj.objetivo.isSelecaoExclusiva).length == 1) {
            this.snackBarService.showAlert('Objetivo exclusivo já selecionado.');
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" && !this.requerimento.numeroProtocoloVinculado && this.objetivosOutrosSelecionados.length > 0) {
            this.snackBarService.showAlert('Só é permitido apenas um objetivo por requerimento.');
        } else {
            let passou = await this.criaObjetivoLicenciamento(this.objetivoOutroSelecionado)
            if (passou) {
                await this.preencheTabelaObjetivo()
                await this.buscarAtividadesDosObjetivos()
            }
        }
    }

    async deletaOutroObjetivoDaTabela(idObjetivoLicenciamento: number) {
        await this.objetivoLicenciamentoService.deletePorIds(this.requerimentoLicenciamento.id, idObjetivoLicenciamento).toPromise()
        await this.preencheTabelaObjetivo()
    }

    private async preencheTabelaObjetivo(inicio = false, adicionando = false) {
        await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise().then(async lista => {
            this.objetivosPrincipaisSelecionados = []
            this.objetivosOutrosSelecionados = []
            for (let obj of lista) {
                if (obj.idTipoObjetivo == 1) {
                    let objetivoPrincipal;
                    if (this.requerimentoLicenciamentoVinculado.id) {
                        objetivoPrincipal = this.objetivosDeVinculo.find(objs => objs.objetivo && objs.objetivo.objetivo.id === obj.idObjetivoLicenciamentoAmbiental)
                        if (objetivoPrincipal) {
                            this.objetivosPrincipaisSelecionados.push(objetivoPrincipal)
                        }
                    }
                    objetivoPrincipal = this.objetivos.find(objs => objs.objetivo.objetivo.id === obj.idObjetivoLicenciamentoAmbiental)
                    if (objetivoPrincipal) {
                        this.objetivosPrincipaisSelecionados.push(objetivoPrincipal)
                        this.objetivosPrincipaisSelecionados = this.objetivosPrincipaisSelecionados.slice();
                        this.iniciaRegrasLI()
                    }
                } else if (obj.idTipoObjetivo == 2) {

                    this.objetivosOutrosSelecionados.push(this.objetivos.find(objs => objs.objetivo.objetivo.id === obj.idObjetivoLicenciamentoAmbiental))
                    this.objetivosOutrosSelecionados = this.objetivosOutrosSelecionados.slice();
                }
            }

            if (this.objetivosPrincipaisSelecionados.length > 0) {
                await this.buscarAtividadesDosObjetivos()
            }
            if (this.objetivosOutrosSelecionados.length > 0) {
                if (adicionando) {
                    await this.preencherParametrosAutorizacao(true)
                } else {
                    await this.preencherParametrosAutorizacao(inicio)
                }
            }
        }).catch(error => {

            if (error.status != 404) {
                this.snackBarService.showError('Ocorreu um erro ao buscar preencher tabela de objetivo');
            }
            throw error;
        })
    }

    private async preencherParametrosAutorizacao(inicio) {
        const autorizacoes = []
        let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculosPorIdTipoTitulacao(this.objetivosOutrosSelecionados[0].objetivo.tipoTitulacao.id).toPromise()
        autorizacoes.push(...atividadeCalculo)
        let autorizacao = autorizacoes.find(aut => aut.atividade.descricao.includes(this.objetivosOutrosSelecionados[0].objetivo.objetivo.descricao))
        if (autorizacao) {
            this.atividadeAut = autorizacao.atividade
            await this.obterAtividadeCalculoParametroEntradaAut(inicio)
        } else {
            this.snackBarService.showAlert('Houve um problema nas configurações, entre em contato com a equipe da SEMA.')
            return
        }
    }

    async obterAtividadeCalculoParametroEntradaAut(inicio = false) {
        if (this.atividadeAut)
            await this.atividadeParametroEntradaArrecadacaoService
                .buscarParametrosEntradaPIdAtividade(this.atividadeAut.id, this.objetivosOutrosSelecionados[0].objetivo.tipoTitulacao.id)
                .toPromise().then(atividadeParametroCalculoEntrada => {
                    this.parametroEntradasAut = atividadeParametroCalculoEntrada;
                }).catch(async error => {
                    error.status = 404;
                    this.errorStatusCalculoParametroEntrada = error.status;
                    if (this.errorStatusCalculoParametroEntrada === 404) {
                        await this.obterAtividadeCalculoSemParametroEntradaeCalculoAut(inicio);
                    }
                })
        else
            throw new Error('Atividade Autorização não definida')
    }

    obterAtividadeCalculoSemParametroEntradaeCalculoAut(inicio) {
        this.atividadeCalculoArrecadacaoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicencaAut())
            .toPromise().then(async atividadeCalculo => {
            this.atividadeCalculoAut = atividadeCalculo;
            if (!inicio) {
                this.parametroCalculoListAut = [...this.atividadeCalculoAut].shift().parametroCalculoList;
                if (this.parametroCalculoListAut && this.parametroCalculoListAut.length === 0) {
                    this.atividadeSemParametroeCaculoAut = true;
                    await this.iniciarCalculo(false)
                }
            }

        }).catch(error => {
            this.snackBarService.showAlert("Sua atividade não é passível de licenciamento");
        })
    }

    montarObjetoParametroSemLicencaAut() {

        return {
            idAtividade: this.atividadeAut.id,
            parametroEntrada: this.parametroEntradaAut,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp
        }
    }

    async iniciarCalculo(calculaTudo = true) {
        this.calcularUpfRetornoDtoList = []
        this.calcularUpfRetornoDtoListAut = []
        this.popUp = false

        if (calculaTudo) {
            let objetivosAll = []

            objetivosAll.push(...this.objetivosPrincipaisSelecionados)
            if (this.objetivosOutrosSelecionados.length > 0) {
                objetivosAll.push(...this.objetivosOutrosSelecionados)
            }


            let objetivoACalcular = []
            let objetivosDeCalculo = []
            if (this.requerimento.tipoProcesso.instanciaRequerimento !== "AUTORIZACAO_OUTROS_DOCUMENTOS") {
                objetivosDeCalculo.push(...this.atividadeCalculo)
            }
            if (this.objetivosOutrosSelecionados.length > 0) {
                objetivosDeCalculo.push(...this.atividadeCalculoAut)
            }

            objetivosAll = objetivosAll.filter(obj => obj.objetivo.tipoTitulacao.id !== 16)
            for (let objetivo of objetivosAll) {
                for (let calculo of objetivosDeCalculo) {
                    if (objetivo.objetivo.tipoTitulacao.id === calculo.tipoTitulacao.id) {
                        objetivoACalcular.push(calculo)
                    }
                }
            }

            if (objetivoACalcular.length == objetivosAll.length) {
                let atividadesCalculoPrin = []
                for (let objetivosPrin of this.objetivosPrincipaisSelecionados) {
                    atividadesCalculoPrin.push(this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id === objetivosPrin.objetivo.tipoTitulacao.id))
                }
                this.atividadeCalculo = atividadesCalculoPrin

            } else {
                this.snackBarService.showAlert('Sua atividade não é passível de licenciamento')
                this.erroDeCalculo = true
                return;
            }

            for (let param of this.parametrosCalculoLista) {
                param['atividadeCalculo'] = []
            }

            for (let atvCalc of this.atividadeCalculo) {
                for (let param of this.parametrosCalculoLista) {
                    param['atividadeCalculo'].push(atvCalc)
                }
            }

            for (let param of this.parametrosCalculoListaAut) {
                param['atividadeCalculo'] = []
            }

            if (this.atividadeCalculoAut) {
                for (let atvCalc of this.atividadeCalculoAut) {
                    for (let param of this.parametrosCalculoListaAut) {
                        param['atividadeCalculo'].push(atvCalc)
                    }
                }
            }

            if (this.requerimento.tipoProcesso.instanciaRequerimento !== "AUTORIZACAO_OUTROS_DOCUMENTOS") {
                await this.calcularLicenca()
                if (this.erroDeCalculo) {
                    this.erroDeCalculo = true
                    return;
                }
            }

            if (this.mostrarOutraObjetivos && this.objetivosOutrosSelecionados.length > 0) {
                await this.calcularLicencaAut()
            }
        } else {
            if (this.mostrarOutraObjetivos && this.objetivosOutrosSelecionados.length > 0) {
                await this.calcularLicencaAut()
            }
        }

        if (this.calcularUpfRetornoDtoList.length > 0 && this.calcularUpfRetornoDtoListAut.length > 0) {
            this.calcularUpfRetornoDtoList.push(...this.calcularUpfRetornoDtoListAut)
        } else if (this.calcularUpfRetornoDtoList.length > 0
            && this.mostrarOutraObjetivos && this.calcularUpfRetornoDtoListAut.length === 0) {
            this.erroDeCalculo = false
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" && this.calcularUpfRetornoDtoListAut.length > 0
            && this.mostrarOutraObjetivos) {
            this.calcularUpfRetornoDtoList.push(...this.calcularUpfRetornoDtoListAut)
            this.erroDeCalculo = false
        } else {
            this.erroDeCalculo = true
        }
    }

    async calcularLicencaAut() {
        this.erroDeCalculo = false
        const ultimoIndex = this.atividadeCalculoAut.indexOf(this.atividadeCalculoAut.slice(-1)[0]);
        let titulacaoIsenta = true

        for (let i = 0; i < this.atividadeCalculoAut.length; i++) {
            await this.atividadeCalculoArrecadacaoService
                .calcularUpf(this.montarObjetoCalculoUpfAut(this.atividadeCalculoAut[i].id))
                .toPromise().then(calcularUpfRetornoDto => {
                    titulacaoIsenta = this.validacaoAtividadeIsentaAut(calcularUpfRetornoDto)
                    this.parametroEntradaAut['idAtividadeCalculo'] = calcularUpfRetornoDto.atividadeCalculo.id
                    let msg = 'O Upf foi Calculado com sucesso, estará disponível na aba de Cálculo de Taxa';
                    if (!titulacaoIsenta) {
                        calcularUpfRetornoDto['isenta'] = true
                        msg = 'Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020'
                    }
                    this.calcularUpfRetornoDtoListAut.push(calcularUpfRetornoDto)

                    if (ultimoIndex == i) {
                        this.calcularUpfRetornoDtoListAut = this.calcularUpfRetornoDtoListAut.sort((a, b) =>
                            (a.atividadeCalculoAut.tipoTitulacao.ordenacao != null ? a.atividadeCalculoAut.tipoTitulacao.ordenacao : Infinity) - (b.atividadeCalculo.tipoTitulacao.ordenacao != null ? b.atividadeCalculo.tipoTitulacao.ordenacao : Infinity)
                        );

                        if (this.calcularUpfRetornoDtoListAut.length > 0) {
                            this.snackBarService.showSuccess(msg);
                        }
                    }

                }).catch(error => {
                    this.erroDeCalculo = true
                    this.snackBarService.showError('Sua atividade não é passível de licenciamento')
                })
        }
    }

    async calcularLicenca() {
        this.erroDeCalculo = false
        const ultimoIndex = this.atividadeCalculo.indexOf(this.atividadeCalculo.slice(-1)[0]);
        let titulacaoIsenta = true

        this.atividadeCalculo = this.atividadeCalculo.filter(function (i) {
            return i
        });

        for (let i = 0; i < this.atividadeCalculo.length; i++) {
            await this.atividadeCalculoArrecadacaoService
                .calcularUpf(this.montarObjetoCalculoUpf(this.atividadeCalculo[i].id))
                .toPromise().then(calcularUpfRetornoDto => {
                    this.parametroEntrada['idAtividadeCalculo'] = calcularUpfRetornoDto.atividadeCalculo.id
                    this.calcularUpfRetornoDtoList.push(calcularUpfRetornoDto)

                    if (ultimoIndex == i) {
                        this.calcularUpfRetornoDtoList = this.calcularUpfRetornoDtoList.sort((a, b) =>
                            (a.atividadeCalculo.tipoTitulacao.ordenacao != null ? a.atividadeCalculo.tipoTitulacao.ordenacao : Infinity) - (b.atividadeCalculo.tipoTitulacao.ordenacao != null ? b.atividadeCalculo.tipoTitulacao.ordenacao : Infinity)
                        );

                        titulacaoIsenta = this.validacaoAtividadeIsenta()

                        if (this.calcularUpfRetornoDtoList.length > 0) {
                            if (!titulacaoIsenta) {
                                this.snackBarService.showAlert('Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020');
                            } else {
                                this.snackBarService.showSuccess("O Upf foi Calculado com sucesso, estará disponível na aba de Cálculo de Taxa");
                            }
                        }
                    }

                }).catch(error => {
                    this.erroDeCalculo = true
                    this.snackBarService.showError('Sua atividade não é passível de licenciamento')
                })
        }
    }

    montarObjetoCalculoUpfAut(idAtividadeCalculo) {

        this.parametrosCalculoListaAut.forEach(param => {
            delete param.id
        })

        return {
            idAtividadeCalculo: idAtividadeCalculo,
            parametrosCalculo: this.parametrosCalculoListaAut,
            parametroEntrada: this.parametroEntradaAut
        }
    }

    validacaoAtividadeIsentaAut(calculoUpf) {
        let titulacaoIsenta = true
        if (calculoUpf.valorUpf === 0) {
            titulacaoIsenta = false
        }
        return titulacaoIsenta;
    }

    montarObjetoCalculoUpf(idAtividadeCalculo) {

        this.parametrosCalculoLista.forEach(param => {
            delete param.id
        })

        return {
            idAtividadeCalculo: idAtividadeCalculo,
            parametrosCalculo: this.parametrosCalculoLista,
            parametroEntrada: this.parametroEntrada
        }
    }

    validacaoAtividadeIsenta() {
        let titulacaoIsenta = true
        for (let i = 0; i < this.calcularUpfRetornoDtoList.length; i++) {
            if (this.calcularUpfRetornoDtoList[i].valorUpf === 0 && this.calcularUpfRetornoDtoList.length === 1) {
                titulacaoIsenta = false
                this.snackBarService.showAlert('Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020');
            }
        }
        return titulacaoIsenta;
    }

    async obterAtividadeCalculoParametroEntrada(inicio = false) {
        await this.atividadeParametroEntradaArrecadacaoService
            .buscarParametrosEntradaPIdAtividade(this.atividadeProjection.atividade.id, this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id)
            .toPromise().then(atividadeParametroCalculoEntrada => {
                this.parametroEntradas = atividadeParametroCalculoEntrada;
            }).catch(async error => {
                error.status = 404;
                this.errorStatusCalculoParametroEntrada = error.status;
                if (this.errorStatusCalculoParametroEntrada === 404) {
                    await this.obterAtividadeCalculoSemParametroEntradaeCalculo(inicio);
                }
            })
    }

    async obterAtividadeCalculoSemParametroEntradaeCalculo(inicio = false) {
        this.atividadeCalculoArrecadacaoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicenca())
            .toPromise().then(async atividadeCalculo => {
            this.atividadeCalculo = atividadeCalculo;
            if (!inicio) {
                this.parametroCalculoList = [...this.atividadeCalculo].shift().parametroCalculoList;
                if (this.parametroCalculoList && this.parametroCalculoList.length === 0) {
                    this.atividadeSemParametroeCaculo = true;
                    await this.iniciarCalculo()
                }
            }
        }).catch(error => {
            this.snackBarService.showAlert("Ocorreu um erro: " + error);
        })
    }

    montarObjetoParametroSemLicenca() {

        return {
            idAtividade: this.atividadeProjection.atividade.id,
            parametroEntrada: this.parametroEntrada,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp
        }
    }

    async onKey(event, atividadeCalculoparamEntrada, timeOut = true, recuperado = false) {
        this.parametroEntrada.valor = event.target.value;
        this.parametroEntrada.descricao = atividadeCalculoparamEntrada.descricao;
        this.parametroEntrada.labelFormulario = atividadeCalculoparamEntrada.labelFormulario;
        if (event.target.value.trim().toString().length > 0 && event.target.value.trim().toString() !== '') {
            if (timeOut) {
                setTimeout(async () => {
                    if (this.element) {
                        this.element.nativeElement.disabled = true;
                    }
                    await this.obterAtividadeCalculoDoParametroDeEntrada();
                    if (this.element) {
                        this.element.nativeElement.disabled = false;
                    }
                }, 1500);
            } else {
                await this.obterAtividadeCalculoDoParametroDeEntrada(recuperado);
            }
        } else if (event.target.value.toString().length === 0) {
            this.parametroCalculoList = null;
            this.atividadeCalculo = [];
            if (this.element) {
                this.element.nativeElement.disabled = false;
            }
        }
    }

    async obterAtividadeCalculoDoParametroDeEntrada(recuperado = false) {
        if (!recuperado) {
            this.parametroCalculoList = null;
        }
        await this.atividadeCalculoArrecadacaoService.buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoLicenca()).toPromise().then(atividadeCalculo => {
            this.atividadeCalculo = atividadeCalculo
            if (!recuperado) {
                this.parametroCalculoList = [...this.atividadeCalculo].shift().parametroCalculoList;
            }
            if (!this.parametroCalculoList) {
                this.snackBarService.showAlert("Não há licença disponível");
            } else {
                for (let i = 0; i < this.parametroCalculoList.length; i++) {
                    if (this.parametroCalculoList[i].labelFormulario === this.parametroEntradas[0].labelFormulario) {
                        let event = {
                            target: {
                                value: this.parametroEntrada.valor
                            }
                        }
                        this.pegarValorDeParamCalc(event, this.parametroCalculoList[i], this.atividadeCalculo)
                        this.parametroCalculoList.splice(i, 1)
                        let cloned = this.parametroCalculoList.slice()
                        this.parametroCalculoList = cloned
                    }
                }
            }
        }).catch(() => {
            this.snackBarService.showAlert("Sua atividade não é passível de licenciamento");
        })
    }

    pegarValorDeParamCalc(event, parametroEntradas: any, licencas = []) {
        this.montarObjetoParamEntrada(parametroEntradas, event.target.value)
        if (this.atividadeCalculo && this.atividadeCalculo.length === 0) {
            this.calcularUpfRetornoDtoList = this.atividadeCalculo
        } else {
            this.calcularUpfRetornoDtoList = licencas
        }
        // this.eventEmitter.emit(this.returnoObjetoEmit(this.validacao))
    }

    montarObjetoLicenca() {
        return {
            idAtividade: this.atividadeProjection.atividade.id,
            parametroEntrada: this.parametroEntrada,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp
        }
    }

    montarObjetoParamEntrada(parametroEntradas, valor) {
        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (this.parametrosCalculoLista.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoLista.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoLista.splice(this.parametrosCalculoLista.indexOf(paramCalc), 1);
                    this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
                }
            })
        } else {
            this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
        }
    }

    async onKeyAut(event, atividadeCalculoparamEntrada, timeOut = true, recuperado = false) {
        this.parametroEntradaAut.valor = event.target.value;
        this.parametroEntradaAut.descricao = atividadeCalculoparamEntrada.descricao;
        this.parametroEntradaAut.labelFormulario = atividadeCalculoparamEntrada.labelFormulario;
        if (event.target.value.trim().toString().length > 0 && event.target.value.trim().toString() !== '') {
            if (timeOut) {
                setTimeout(async () => {
                    if (this.element) {
                        this.element.nativeElement.disabled = true;
                    }
                    await this.obterAtividadeCalculoDoParametroDeEntradaAut();
                    if (this.element) {
                        this.element.nativeElement.disabled = false;
                    }
                }, 1500);
            } else {
                await this.obterAtividadeCalculoDoParametroDeEntradaAut(recuperado);
            }
        } else if (event.target.value.toString().length === 0) {
            this.parametroCalculoListAut = null;
            this.atividadeCalculoAut = [];
            if (this.element) {
                this.element.nativeElement.disabled = false;
            }
        }
    }

    async obterAtividadeCalculoDoParametroDeEntradaAut(recuperado = false) {
        if (!recuperado) {
            this.parametroCalculoListAut = null;
        }
        await this.atividadeCalculoArrecadacaoService.buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoLicencaAut()).toPromise().then(atividadeCalculo => {
            this.atividadeCalculoAut = atividadeCalculo
            if (!recuperado) {
                this.parametroCalculoListAut = [...this.atividadeCalculoAut].shift().parametroCalculoList;
            }
            if (!this.parametroCalculoListAut) {
                this.snackBarService.showAlert("Não há licença disponível");
            } else {
                for (let i = 0; i < this.parametroCalculoListAut.length; i++) {
                    if (this.parametroCalculoListAut[i].labelFormulario === this.parametroCalculoListAut[0].labelFormulario) {
                        let event = {
                            target: {
                                value: this.parametroEntradaAut.valor
                            }
                        }
                        this.pegarValorDeParamCalc(event, this.parametroCalculoListAut[i], this.atividadeCalculo)
                        this.parametroCalculoListAut.splice(i, 1)
                        let cloned = this.parametroCalculoListAut.slice()
                        this.parametroCalculoListAut = cloned
                    }
                }
            }
        }).catch(() => {
            this.snackBarService.showAlert("Ocorreu um erro ao buscar parametros de entrada");
        })
    }

    montarObjetoLicencaAut() {
        return {
            idAtividade: this.atividadeAut.id,
            parametroEntrada: this.parametroEntradaAut,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp
        }
    }

    pegarValorDeParamCalcAut(event, parametroEntradas: any, licencas = []) {
        this.montarObjetoParamEntradaAut(parametroEntradas, event.target.value)
        if (this.atividadeCalculoAut && this.atividadeCalculoAut.length === 0) {
            this.calcularUpfRetornoDtoListAut = this.atividadeCalculoAut
        } else {
            this.calcularUpfRetornoDtoListAut = licencas
        }
        // this.eventEmitter.emit(this.returnoObjetoEmit(this.validacao))
    }

    montarObjetoParamEntradaAut(parametroEntradas, valor) {
        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (this.parametrosCalculoListaAut.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoListaAut.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoListaAut.splice(this.parametrosCalculoListaAut.indexOf(paramCalc), 1);
                    this.parametrosCalculoListaAut.push(objetoParametrosCalculoParaLista);
                }
            })
        } else {
            this.parametrosCalculoListaAut.push(objetoParametrosCalculoParaLista);
        }
    }
}
