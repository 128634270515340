import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DocumentoRequerimento} from '../documento-requerimento.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-substituir-documento-dialog',
    templateUrl: './substituir-documento-dialog.component.html',
})
export class SubstiruirDocumentoDialogComponent implements OnInit {
    
    constructor(
        public dialogRef: MatDialogRef<SubstiruirDocumentoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
        
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
