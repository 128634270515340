import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DadosGeraisTransporteResiduosPerigosos} from "../../../../../../../../shared/models/dados-gerais-transporte-residuos-perigosos.model";
import {TipoObrasEmpObrasViarias} from "../../../../../../../../shared/models/tipo-obras-emp-obras-viarias.model";
import {InfraestruturaObrasViariasService} from "../infraestrutura-obras-viarias.service";

@Component({
    selector: 'app-tipo-obra',
    templateUrl: './tipo-obra.component.html',
    styleUrls: ['./tipo-obra.component.scss']
})
export class TipoObraComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly : boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() emitirTipoObra = new EventEmitter<any[]>();
    formTipoObra: FormGroup;
    tipoObrasSalvas: any[] = [];
    tipos: any[] = [];
    idTipo: number = 0;
    dataSourceTipoObra = new MatTableDataSource<any>([]);

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private dominioService: DominiosService,
                private infraObrasViariasService: InfraestruturaObrasViariasService) {
    }

    ngOnInit() {
        this.buildFormTipoObra();

        //Buscando tipos de obras
        this.dominioService.getAllTipoObra().subscribe( retorno => {
            this.tipos = retorno;
        })

        if(this.idForm){
            this.infraObrasViariasService.buscarTipoObraPorId(this.idForm).subscribe( response => {
                response.forEach( tipoObra => {
                    this.tipoObrasSalvas.push({
                        id: tipoObra.id,
                        idTemp: this.idTipo,
                        tipoObra: tipoObra.tipo,
                    });
                    this.idTipo++;
                })
                this.dataSourceTipoObra = new MatTableDataSource(this.tipoObrasSalvas);
                this.enviarTipoObra(this.mapearArray());
            })
        }
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.formTipoObra.disable();
        }
    }

    mapearArray(): any{
        if(this.tipoObrasSalvas.length == 0){
            return this.tipoObrasSalvas;
        }else{
            return this.tipoObrasSalvas.map(function(item){
                return new TipoObrasEmpObrasViarias(
                    item.id,
                    null,
                    item.tipoObra
                );
            });
        }
    }

    private buildFormTipoObra(): void {
        this.formTipoObra = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            tipoObra: ["", [Validators.required]],
        })
    }

    adicionarTipoObra() {
        if(this.formTipoObra.valid){
            this.tipoObrasSalvas.push({
                id: null,
                idTemp: this.idTipo,
                tipoObra: this.formTipoObra.controls.tipoObra.value
            })
            this.idTipo++;
            this.dataSourceTipoObra = new MatTableDataSource(this.tipoObrasSalvas);
            this.enviarTipoObra(this.mapearArray());
            this.formTipoObra.reset();
        }else{
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            this.formTipoObra.markAllAsTouched();
        }
    }

    deletarTipoObra(item, acao?: string, itemId?: number){
        if(acao == 'DELETAR' && itemId != null){
            this.infraObrasViariasService.deletarTipoObraPorId(itemId).subscribe( response => {
                this.snackBarService.showSuccess("Tipo obra excluido com sucesso.")
            })
        }
        let data;
        data = this.tipoObrasSalvas.filter((value, key) => {
            return value.idTemp != item;
        });
        this.tipoObrasSalvas = data;
        this.dataSourceTipoObra = new MatTableDataSource(data);
        this.enviarTipoObra(this.mapearArray())
    }

    enviarTipoObra(tipos: any[]) {
        this.emitirTipoObra.emit(tipos);
    }
}