import {GeometriaLinha} from './geometria-linha.model';
import {GeometriaPoligono} from './geometria-poligono.model';
import {GeometriaPonto} from './geometria-ponto.model';
import {BaseModel} from "../base.model";
export interface AnaliseFeicao{
    idGeometria: number;
    wkt: string;
    valor: number;
    ativou: boolean;
}

export class Feicao extends BaseModel {

    public estilo?: {
        corPreenchimento: string,
        espessuraBorda: number,
        cor: string,
        tipoTracejado: number,
        icone?:string
    };

    constructor(
        public id?: number,
        public descricao?: string,
        public tipoGeometria?: number,
        public formulario?: number,
        public geometriaPonto?: GeometriaPonto,
        public geometriaLinha?: GeometriaLinha,
        public geometriaPoligono?: GeometriaPoligono,
        public excluido?: boolean,
        public wkt?: string,
        public idGeometria?: number,
        public idModeloFeicao?: number,
        public objetivoLicenciamento?: number,
        public feicoesUtilizadasNaAnalise?: AnaliseFeicao[]
    ) {
        super();
    }

    static fromJson(json: any): Feicao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Feicao(
            json.id,
            json.descricao,
            json.tipoGeometria,
            json.formulario,
            GeometriaPonto.fromJson(json.geometriaPonto),
            GeometriaLinha.fromJson(json.geometriaLinha),
            GeometriaPoligono.fromJson(json.geometriaPoligono),
            json.excluido,
            json.wkt,
            json.idGeometria,
            json.idModeloFeicao,
            json.objetivoLicenciamento,
            json.feicoesUtilizadasNaAnalise
        );
    }
}
