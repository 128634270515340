import {Injectable, Injector} from '@angular/core';
import {BaseService} from "../base.service";
import {environment} from "../../../../../environments/environment";
import {ObjetivoLicenciamentoAmbiental} from "../../models/licenciamento-config-models/objetivo-licenciamento-ambiental.model";

@Injectable({
    providedIn: 'root'
})
export class ObjetivoLicenciamentoAmbientalService extends BaseService<ObjetivoLicenciamentoAmbiental> {

    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            ObjetivoLicenciamentoAmbiental.prototype,
            ObjetivoLicenciamentoAmbiental.fromJson
        );
        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/objetivolicenciamentoambiental'
    }

}