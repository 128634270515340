import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material';
import {DocumentoImovel} from '../../models/documento-imovel.model';
import {DocumentoImovelService} from '../../services/documento-imovel.service';
import {Imovel} from '../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model';
import {FileValidationUtil} from '../../util/file-validation-util';
import {SnackBarService} from '../../snack-bar/snack-bar.service';
import { map, switchMap } from 'rxjs/operators';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { of } from 'rxjs';

@Component({
    selector: 'app-documento-imovel-urbano',
    templateUrl: './documento-imovel-urbano.component.html',
    styleUrls: ['./documento-imovel-urbano.component.scss'],
})
export class DocumentoImovelUrbanoComponent implements OnInit {

    @Input() requerimento: Requerimento;
    @Input() imovelUrbano: Imovel;
    @Input() somenteVisualizacao: boolean;
    @Output() public updateDocumentosEmitter: EventEmitter<DocumentoImovel[]> = new EventEmitter();

    documentos: DocumentoImovel[];

    constructor(
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        private documentoImovelService: DocumentoImovelService
    ) {
    }

    ngOnInit(): void {
        this.listarDocumentos();
    }

    public listarDocumentos(): void {
        if (!this.imovelUrbano) {
            return;
        }
        this.documentoImovelService
            .getByImovelUrbano(this.imovelUrbano)
            .pipe(switchMap((documentos) => {
                if (this.requerimento && this.requerimento.numeroProtocoloVinculado)
                    return this.documentoImovelService.getByRequerimentoVinculadoNumeroERequerimentoId(this.requerimento.numeroProtocoloVinculado, this.requerimento.id)
                        .pipe(map((documentosProcVinculado) => documentos.concat(documentosProcVinculado)));
                return of(documentos);
            }))
            .subscribe((documentos) => {
                documentos = documentos || [];
                documentos = [...new Map(documentos.map(v => [v.id, v])).values()]; // remove dupl
                this.documentos = documentos.sort((a, b) => {
                    if (a.pessoa.id < b.pessoa.id) {
                        return -1;
                    }
                    return 0;
                });
                this.updateDocumentosEmitter.emit(documentos);
            });
    }

    selecionarAnexo(file, index: number): void {
        if (FileValidationUtil.invalidSize(file, FileValidationUtil.size('5MB'))) {
            this.snackBarService.showAlert('Arquivo com tamanho inválido, o tamanho limite é 5MB.');
            document.getElementById(`input-arquivo-${index}`)['value'] = null;
            return;
        }
        const anexo = this.documentos[index];
        anexo.arquivo = file;
        anexo.tempName = file.name;
        anexo.modified = true;
    }

    removerAnexo(index: number): void {
        const anexo = this.documentos[index];
        anexo.arquivo = null;
        anexo.tempName = null;
        anexo.modified = false;
        document.getElementById(`input-arquivo-${index}`)['value'] = null;
    }

    salvarArquivos(): void {
        let totalFilesModified = 0;
        let totalFinished = 0;
        this.documentos.forEach(doc => {
            if (doc.modified) {
                totalFilesModified++;
                ((doc.arquivo) ? this.documentoImovelService.updateMultipart(doc, doc.arquivo) : this.documentoImovelService.updateMultipart(doc, null))
                    .subscribe(() => {
                        totalFinished++;
                        if (totalFilesModified === totalFinished) {
                            this.listarDocumentos();
                        }
                    }, e => {
                        this.snackBarService.showError('Erro ao fazer upload de um arquivo', e);
                        totalFinished++;
                        if (totalFilesModified === totalFinished) {
                            this.listarDocumentos();
                        }
                    });
            }
        });
    }

    public visualizarDocumento(documento: DocumentoImovel): void {
        window.open(
            this.documentoImovelService.urlResource +
            '/' +
            documento.id +
            '/visualizar'
        );
    }

    public downloadDocumento(documento: DocumentoImovel): void {
        window.open(
            this.documentoImovelService.urlResource +
            '/' +
            documento.id +
            '/download'
        );
    }

    canSave(): boolean {
        return this.documentos ? this.documentos.every(doc => !!doc.nomeArquivo || !!doc.tempName) && this.documentos.some(doc => doc.modified) : false;
    }

    get possuiDocumentos(): boolean {
        return this.documentos && this.documentos.length > 0;
    }

    isDesabilitaConsultaResponsaveisAssociados(): boolean {
        return this.requerimento.tipoRequerimento.isFluxoCancelar 
            || this.requerimento.tipoRequerimento.isFluxoReabilitar; 
    }
}