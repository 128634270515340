import {Component, OnInit} from '@angular/core';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {PerfuracaoPoco} from '../../../../../shared/models/perfuracao-poco.model';
import {BasePontoComponent} from '../base-ponto.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {ResponsaveisCorporativoService} from "../../../../../shared/services/responsaveis-corporativo.service";
import {DialogSelecionarRtPjComponent} from "../../../../../shared/components/dialog-selecionar-rt-pj/dialog-selecionar-rt-pj.component";
import {MatDialog} from "@angular/material";
import {ResponsavelTecnico} from "../../../../../shared/models/responsavel-tecnico.model";

@Component({
    selector: 'app-objetivo-perfuracao-poco-tubular',
    templateUrl: './objetivo-perfuracao-poco-tubular.component.html'
})
export class ObjetivoPerfuracaoPocoTubularComponent extends BasePontoComponent implements OnInit {
    form: FormGroup;

    tiposPorosidade = PerfuracaoPoco.tiposPorosidade;
    tiposPoco = PerfuracaoPoco.tiposPoco;
    tiposCaracteristicasHidraulicas = PerfuracaoPoco.tiposCaracteristicasHidraulicas;
    tiposFontesAbastecimento = PerfuracaoPoco.tiposFontesAbastecimento;
    tiposBombas = PerfuracaoPoco.tiposBombas;
    empresaSelecionada: ResponsavelTecnico;

    constructor(
        private formBuilder: FormBuilder,
        private pontoService: PontoService,
        private responsaveisCorporativoService: ResponsaveisCorporativoService,
        private snackBarService: SnackBarService,
        private matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildForms();
        this.patchForms();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    consultarRtCNPJ(): void {
        this.matDialog.open(DialogSelecionarRtPjComponent).afterClosed().subscribe(result => {
            if (result) {
                this.empresaSelecionada = result;
                this.form.get('idResponsavelTecnico').setValue(this.empresaSelecionada.id);
            }
        });
    }

    buildForms(): void {
        this.form = this.formBuilder.group({
            id: this.formBuilder.control(null),
            idResponsavelTecnico: this.formBuilder.control(null, [Validators.required]),
            dataConclusao: this.formBuilder.control(null, [Validators.required]),
            tipoAquiferoPorosidade: this.formBuilder.control(null, [Validators.required]),
            caracteristicaHidraulica: this.formBuilder.control(null, [Validators.required]),
            fonteAbastecimento: this.formBuilder.control(null, [Validators.required]),
            especificarFonteAbastecimento: this.formBuilder.control({value: null, disabled: true}),
            sistemaIndependente: this.formBuilder.control(null, [Validators.required]),
            profundidadePoco: this.formBuilder.control(null, [Validators.required, Validators.min(0.01)]),
            diametroPerfuracao: this.formBuilder.control(null, [Validators.required, Validators.min(0.01)]),
            diametroRevestimento: this.formBuilder.control(null, [Validators.required, Validators.min(0.01)]),
            unidadeGeologica: this.formBuilder.control(null, [Validators.required, Validators.maxLength(1000)]),
            aquiferoExplorado: this.formBuilder.control(null, [Validators.required, Validators.maxLength(255)]),
            topoAquifero: this.formBuilder.control(null, [Validators.maxLength(255)]),
            baseAquifero: this.formBuilder.control(null, [Validators.maxLength(255)]),
            tipoPoco: this.formBuilder.control(null, [Validators.required])
        });

    }

    private patchForms(): void {
        if (this.ponto.perfuracaoPoco) {
            this.form.patchValue(this.ponto.perfuracaoPoco);
            if (this.ponto.perfuracaoPoco.idResponsavelTecnico) {
                this.empresaSelecionada = this.ponto.perfuracaoPoco.empresaResponsavel;
            }
        }
    }

    salvarPonto(): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            const ponto = Ponto.fromJson(this.ponto);
            ponto.perfuracaoPoco = PerfuracaoPoco.fromJson(this.form.value);
            ponto.formularioConcluido = true;
            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    onFonteChanged(event): void {
        if (event) {
            const field = this.form.get('especificarFonteAbastecimento');
            if (this.form.get('fonteAbastecimento').value === 'OUTROS') {
                field.enable();
                field.setValidators([Validators.required,
                    Validators.maxLength(100)]);
                field.updateValueAndValidity();
                field.markAsUntouched();
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
                field.updateValueAndValidity();
                field.markAsUntouched();
            }
        }
    }
}
