import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {Requerimento} from '../../requerimentos/requerimento.model';

export class Complementacao extends BaseModel {
    constructor(
        public id?: number,
        public numero?: string,
        public situacao?: string,
        public requerimento?: Requerimento,
        public itens?: ComplementacaoItem[],
        public dataEntrega?: Date,
        public dataInicio?: Date,
        public dataFim?: Date,
    ) {
        super();
    }

    static situacoes: BaseEnum[] = [
        {valor: 'EM_ELABORACAO', descricao: 'Em elaboração'},
        {valor: 'CANCELADA', descricao: 'Cancelada'},
        {valor: 'AGUARDANDO_COMPLEMENTACAO', descricao: 'Aguardando complementação'},
        {valor: 'AGUARDANDO_VALIDAR_OFICIO', descricao: 'Aguardando validar oficio'},
        {valor: 'AGUARDANDO_ANALISE', descricao: 'Aguardando análise'},
        {valor: 'CONCLUIDA_SEM_MANIFESTACAO', descricao: 'Concluida Sem manifestação'},
        {valor: 'CONCLUIDA_ATENDIDA', descricao: 'Concluida atendida'},
        {valor: 'CONCLUIDA_NAO_ATENDIDA', descricao: 'Concluida não atendida'}
    ];

    static fromJson(json: any): Complementacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Complementacao(
            json.id,
            json.numero,
            json.situacao,
            json.requerimento,
            json.itens ? json.itens.map(o => ComplementacaoItem.fromJson(o)) : [],
            json.dataEntrega,
            json.dataInicio,
            json.dataFim,
        );
    }

    static tiposPrazo: BaseEnum[] = [
        {valor: 'INFORMAR_UMA_DATA', descricao:'Informar uma data'},
        {valor: 'INFORMAR_NUMERO_DE_DIAS', descricao: 'Informar número de dias'}
    ]

}

export class ComplementacaoItem extends BaseModel {
    constructor(
        public id?: number,
        public numero?: number,
        public situacaoAnalise?: string,
        public nomeDocumentoSolicitado?: string,
        public tipoDocumentoSolicitado?: string,
        public justificativa?: string,
        public arquivos?: ComplementacaoItemArquivo[],
        public resposta?: string,
        public justificativaAnalise?: string,
        public idItemTermoReferenciaRequerimento?: number
    ) {
        super();
    }

    static situacoesAnalise: BaseEnum[] = [
        {valor: 'REPROVADO', descricao: 'Reprovado'},
        {valor: 'PARCIALMENTE_APROVADO', descricao: 'Parcialmente aprovado'},
        {valor: 'APROVADO', descricao: 'Aprovado'}
    ];

    static tiposDocumentoSolicitado: BaseEnum[] = [
        {valor: 'TECNICO', descricao: 'Técnico'},
        {valor: 'CORPORATIVO', descricao: 'Corporativo'},
        {valor: 'SOCIOS', descricao: 'Sócios'},
        {valor: 'RESPONSAVEL_TECNICO', descricao: 'Responsável Técnico'}
    ];

    static fromJson(json: any): ComplementacaoItem {
        if (json === undefined || json === null) {
            return null;
        }
        return new ComplementacaoItem(
            json.id,
            json.numero,
            json.situacaoAnalise,
            json.nomeDocumentoSolicitado,
            json.tipoDocumentoSolicitado,
            json.justificativa,
            json.arquivos ? json.arquivos.map(o => ComplementacaoItemArquivo.fromJson(o)) : null,
            json.resposta ? json.resposta : null,
            json.justificativaAnalise ? json.justificativaAnalise : null,
            json.idItemTermoReferenciaRequerimento,
        );
    }

    getIconAnalise(): string {
        let icon = '';
        if (this.situacaoAnalise) {
            if (this.situacaoAnalise === 'APROVADO') {
                icon = 'check_circle';
            } else {
                icon = 'cancel';
            }
        }
        return icon;
    }

    getIconColorAnalise(): string {
        let color = '';
        if (this.situacaoAnalise) {
            if (this.situacaoAnalise === 'APROVADO') {
                color = 'green';
            } else {
                color = 'red';
            }
        }
        return color;
    }

    _toString(): string | number {
        return (this.nomeDocumentoSolicitado != null ? this.nomeDocumentoSolicitado : this.id);
    }
}

export class ComplementacaoItemArquivo extends BaseModel {
    constructor(
        public id?: number,
        public titulo?: string,
        public nomeArquivo?: string,
        public sigiloso?: boolean,
        public excluido?: boolean,
        public resposta: boolean = true,
        public blob?: Blob,
    ) {
        super();
    }

    public textResposta: string;

    static fromJson(json: any): ComplementacaoItemArquivo {
        if (json === undefined || json === null) {
            return null;
        }
        return new ComplementacaoItemArquivo(
            json.id,
            json.titulo,
            json.nomeArquivo,
            json.sigiloso,
            json.excluido,
            json.resposta,
            json.blob
        );
    }
}



