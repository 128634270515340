import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HistoricoPrazoProcesso} from '../models/historico-prazo-processo.model';
import {HistoricoRequerimentoEnum} from '../../pages/requerimentos/shared/historico-requerimento/model/historico-requerimento.model';

@Injectable({
    providedIn: "root"
})
export class HistoricoPrazoProcessoService extends BaseService<HistoricoPrazoProcesso>{
    constructor(protected  injector: Injector) {
        super(
            '/api/historico-prazo-processo',
            injector,
            HistoricoPrazoProcesso.prototype,
            HistoricoPrazoProcesso.fromJson);
    }

    buscaListaHistoricoPrazoProcesso(idRequerimento: number, evento: HistoricoRequerimentoEnum): Observable<any> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/${evento}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    addHistoricoPrazoProcesso(idRequerimento: number, evento: any, tipoEvento: any): Observable<any> {
        const url = `${this.urlResource}/atualizar-historico/${idRequerimento}/${evento}/${tipoEvento}`;
        return this.http.put(url, null)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    atualizarHistoricoAnaliseCasoNaoExistaPrazo(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/atualizar-historico-req-analise/${idRequerimento}`;
        return this.http.put(url, null)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }
}