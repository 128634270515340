import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { AnaliseRequerimentoEquipe } from 'app/main/pages/tarefas/shared/model/analise-requerimento-equipe.model';
import { TermoReferencia } from 'app/main/pages/termos-referencia/termo-referencia.model';
import { DocumentoProcesso } from 'app/main/shared/models/documento-processo.model';
import { Solicitacao } from 'app/main/shared/models/solicitacao.model';
import { DetailResolver } from 'app/main/shared/resolvers/detail-resolver';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TermoReferenciaEspecificoRequerente } from "../model/termo-referencia-especifico-requerente.model";
import { TermoReferenciaValidacaoSignatario } from '../model/termo-referencia-validacao-signatario.model';





@Injectable()
export class TermoReferenciaEspecificoRequerenteResolver extends DetailResolver<TermoReferenciaEspecificoRequerente> {
    constructor(service: TermoReferenciaEspecificoService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TermoReferenciaEspecificoService extends BaseService<TermoReferenciaEspecificoRequerente> {

    constructor(injector: Injector) {
        super('/api/termo-referencia-especifico', injector, DocumentoProcesso.prototype, DocumentoProcesso.fromJson);
    }

    readByRequerimentoId(idRequerimento: number): Observable<TermoReferenciaEspecificoRequerente> {
        const url = `${this.urlResource}/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    readEquipeByRequerimentoId(requerimentoId: number): Observable<AnaliseRequerimentoEquipe> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}/equipe`;
        return this.http.get(url).pipe(
            map(AnaliseRequerimentoEquipe.fromJson.bind(this)),
            catchError(this.handleError)
        );
    }

    readPdf(idTermoReferenciaEspecifico: number, idRequerimento: number, forceRefresh: boolean = false): Observable<any> {
        return this.http.get(`${this.urlResource}/${idTermoReferenciaEspecifico}/requerimento/${idRequerimento}/pdf`, {
            responseType: 'blob',
            params: new HttpParams().set('forceRefresh', String(forceRefresh))
        }).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ),
            catchError(this.handleError));
    }

    finalizarTarefaCamundaValidarTermoReferencia(idTarefa: string, termoReferenciaValidacaoSignatario: Solicitacao<TermoReferenciaValidacaoSignatario>) {
        const url = `${this.urlResource}/concluir-tarefa/${idTarefa}/`;
        return this.http.put(url, termoReferenciaValidacaoSignatario).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarHtmlTermoReferenciaEspecifico(requerimento: Requerimento) {
        const url = `${this.urlResource}/gerar-termo-referencia-especifico/${requerimento.id}/recuperar-html`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    salvarHtmlDocumento(html: string, idTermoReferencia: number, idRequerimento: number): Observable<TermoReferencia> {
        const url = `${this.urlResource}/${idTermoReferencia}/salvar-html-documento/${idRequerimento}`;
        return this.http.post(url, html).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    salvar(idRequerimento: number, termo: TermoReferencia): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/`;
        return this.http.post(url, termo).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    readByTermoReferenciaId(idTermoReferencia: number): Observable<any> {
        const url = `${this.urlResource}/termo-referencia/${idTermoReferencia}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

}
