import {BaseModel} from "./base.model";

export class AtividadeMineracao extends BaseModel {
    constructor(
        public id?: number,
        public idRequerimento?: number,
        public idTipoObjetoLicenciamento?: number,
        public descricao?: string,
        public possuiBaciaRejeito?: boolean,
        public possuiPilhaEsteril?: boolean,
        public possuiPilhaRejeito?: boolean,
        public aquisicaoFornecedora?: boolean,
        public geracaoPropria?: boolean,
        public consumoAquisicaoFornecedora?: number,
    ) {
        super();
    }

    static fromJson(json: any): AtividadeMineracao {
        if (json === undefined || json === null) {
            return null;
        }
        return new AtividadeMineracao(
            json.id,
            json.idRequerimento,
            json.idTipoObjetoLicenciamento,
            json.descricao,
            json.possuiBaciaRejeito,
            json.possuiPilhaEsteril,
            json.possuiPilhaRejeito,
            json.aquisicaoFornecedora,
            json.geracaoPropria,
            json.consumoAquisicaoFornecedora,
        );
    }

}
