import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class InsumoUtilizado extends BaseModel {
    constructor(
        public id: number,
        public idTipoInsumoMineral: number,
        public descricao: string,
        public quantidadeMensal: number,
        public idUnidadeMedidaInsumoMineral: number,
        public idUnidadeTempo: number,
        public mineracao: AtividadeMineracao,
    ) {
        super();
    }

    static fromJson(json: any): InsumoUtilizado {
        if (json === undefined || json === null) {
            return null;
        }
        return new InsumoUtilizado(
            json.id,
            json.idTipoInsumoMineral,
            json.descricao,
            json.quantidadeMensal,
            json.idUnidadeMedidaInsumoMineral,
            json.idUnidadeTempo,
            json.mineracao,
        );
    }

}
