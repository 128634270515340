import {BaseModel} from 'app/main/shared/models/base.model';
import {Pessoa} from 'app/main/shared/models/pessoa.model';
import {RepresentanteLegalDocumentoCadastro} from 'app/main/shared/models/representante-legal-documento-cadastro.model';
import {Requerimento} from '../../pages/requerimentos/requerimento.model';

export class RepresentanteLegal extends BaseModel {
    constructor(
        public id?: number,
        public tipoPessoa?: string,
        public pessoa?: Pessoa,
        public requerimento?: Requerimento,
        public anexadoProcuracaoComFirma?: boolean,
        public anexadoProcuracaoSimples?: boolean,
        public anexadoCopiaOAB?: boolean,
        public documentos?: Array<RepresentanteLegalDocumentoCadastro>,
    ) {
        super();
    }

    static fromJson(json: any): RepresentanteLegal {
        if (json === undefined || json === null) {
            return null;
        }
        return new RepresentanteLegal(
            json.id,
            json.tipoPessoa,
            Pessoa.fromJson(json.pessoa),
            Requerimento.fromJson(json.requerimento),
            json.anexadoProcuracaoComFirma,
            json.anexadoProcuracaoSimples,
            json.anexadoCopiaOAB,
            RepresentanteLegalDocumentoCadastro.fromJsons(json.documentos)
        );
    }

    static fromJsons(json: any): RepresentanteLegal[] {
        const lista: RepresentanteLegal[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(rt => {
                lista.push(this.fromJson(rt));
            });
            return lista;
        }
    }

    _toString() {
        return (this.pessoa != null ? ((this.pessoa.nome) + (this.pessoa.cpfCnpj ? ' - ' + this.pessoa.cpfCnpj : '')) : '');
    }
}
