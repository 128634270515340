import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from "../../core/services/routing-state.service";
import {Madeira} from '../models/madeira';
import {DetailResolver} from "../resolvers/detail-resolver";
import {ListResolver} from '../resolvers/list-resolver';
import {BaseGeralMadeira} from "./base-geral-madeira.service";
import {Observable} from "rxjs";
import {ServicoCombustivel} from "../models/servico-combustivel.model";
import {catchError, map} from "rxjs/operators";


@Injectable()
export class MadeiraListResolver extends ListResolver<Madeira> {
    constructor(service: MadeiraService) {
        super(service);
    }
}

@Injectable()
export class MadeiraResolver extends DetailResolver<Madeira> {
    constructor(
        service: MadeiraService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class MadeiraService extends BaseGeralMadeira<Madeira> {

    constructor(protected injector: Injector) {
        super(
            "/api/serv-geral-madeira",
            injector,
            Madeira.prototype,
            Madeira.fromJson
        );
    }

    getByRequerimento(idRequerimento: number): Observable<Madeira> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
