import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {MunicipioService} from '../../../../../shared/services/geoportal-base-referencia/municipio.service';
import {Municipio} from '../../../../../shared/models/geoportal-base-referencia/municipio.model';
import {RequerimentoOutorgaFinalidadeService} from '../../../../../shared/services/requerimento-outorga-finalidade.service';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {environment} from '../../../../../../../environments/environment';
import {FormUtil} from "../../../../../shared/util/form-util";

@Component({
    selector: 'app-finalidade-abastecimento-publico',
    templateUrl: './finalidade-abastecimento-publico.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})

export class FinalidadeAbastecimentoPublicoComponent extends BaseFinalidadeComponent implements OnInit {

    percentageErrorMessages = FormUtil.percentageErrorMessages
    form: FormGroup;
    formAbastecimento: FormGroup;
    municipios: Municipio[];
    compareWithMunicipio = Municipio.compareWith;

    constructor(
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private municipioService: MunicipioService,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildResourceForm();
        this.patchForm();
        this.loadMunicipios(environment.UF_PADRAO);
        if (this.isModoVisualizacao) {
            this.form.disable();
            this.formAbastecimento.disable();
        }
    }

    private patchForm(): void {
        this.form.patchValue({
            id: this.requerimentoOutorgaFinalidade.id,
            finalidade: this.requerimentoOutorgaFinalidade.finalidade,
            requerimentoOutorga: this.requerimentoOutorgaFinalidade.requerimentoOutorga
        });
        if (this.requerimentoOutorgaFinalidade.abastecimentoEsgotamento) {
            this.form.patchValue({
                abastecimentoEsgotamento: this.requerimentoOutorgaFinalidade.abastecimentoEsgotamento
            });
        }
    }

    protected buildResourceForm(): void {
        this.formAbastecimento = this.formBuilder.group({
            id: [null],
            municipio: [null, [Validators.required]],
            distrito: [null, [Validators.maxLength(80)]],
            quantitativoPopulacao: [null, [Validators.required, Validators.min(1)]],
            quantitativoPopulacaoMunicipio: [null, [Validators.required, Validators.min(1)]],
            consumoDiario: [null, [Validators.required]],
            coeficienteDiaMaior: [null, [Validators.required, Validators.max(999.99), Validators.min(-999.99)]],
            coeficientePerdas: [null, FormUtil.validatorsSetPercentage],
            taxaCrescimento: [null, [Validators.required, Validators.max(100), Validators.min(-100)]],
            coeficienteHoraMaior: [null, [Validators.required, Validators.max(999.99), Validators.min(-999.99)]],
            resumoPlanoPerdas: [null, FormUtil.validatorsSetPercentage],
            anoFinal: [null, [Validators.required, Validators.maxLength(4), Validators.min(1900)]],
            populacaoFinal: [null, [Validators.required, Validators.min(1)]],
            vazaoFinalProjeto: [null, [Validators.required, Validators.min(0.000001)]]
        });


        this.form = this.formBuilder.group({
            id: [null],
            finalidade: [null, [Validators.required]],
            requerimentoOutorga: [null, [Validators.required]],
            abastecimentoEsgotamento: this.formAbastecimento,
            observacao: [null, Validators.maxLength(3000)],
            cadastroConcluido: [null]
        });
    }

    public salvar(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.form.patchValue({cadastroConcluido: true});
            this.requerimentoOutorgaFinalidadeService.update(this.form.value).subscribe(
                requerimentoOutorgaFinalidade => {
                    Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                    this.form.patchValue(requerimentoOutorgaFinalidade);
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Abastecimento público salvo com sucesso.');
                },
                e => this.snackBarService.showError('Erro ao atualizar o abastecimento público.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    public loadMunicipios(uf: string): void {
        this.municipioService
            .getAllByUf(uf)
            .subscribe(municipios => {
                this.municipios = municipios;
            });
    }

}


