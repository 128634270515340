import {Component, Input} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';

@Component({
    selector: 'app-aba-objetivo-tamponamento-poco-tubular',
    templateUrl: './aba-objetivo-tamponamento-poco-tubular.component.html'
})
export class AbaObjetivoTamponamentoPocoTubularComponent {

    @Input() ponto: Ponto;

    constructor() {
    }
}
