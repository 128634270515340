export enum EnumRegraApresentacao {
    RESPONSAVEIS_IMOVEIS = 'RESPONSAVEIS_IMOVEIS',
    TITULOS_SOLICITADOS = 'TITULOS_SOLICITADOS',
    ATIVIDADES_OUTORGA = 'ATIVIDADES_OUTORGA',
    ANALISTAS_PROCESSO = 'ANALISTAS_PROCESSO',
    RESPONSAVEIS_TECNICOS = 'RESPONSAVEIS_TECNICOS',
    FINALIDADES_OUTORGA = 'FINALIDADES_OUTORGA',
    CONFERENTES_REQUERIMENTO = 'CONFERENTES_REQUERIMENTO',
    PROCESSOS_VINCULADOS = 'PROCESSOS_VINCULADOS'
}
