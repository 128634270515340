import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FlexModule} from "@angular/flex-layout";
import {SemaGeoportalModule} from "@sema-geo/sema-geoportal";
import {SharedModule} from "app/main/shared/shared.module";
import {
    LicAmbAtoAdministrativoSignatarioComponent
} from "../emissao-titulo/ato-administrativo-signatario/ato-administrativo-signatario.component";
import {
    LicAmbEmissaoTituloDocumentosTecnicosComponent
} from "../emissao-titulo/documentos-tecnicos/documentos-tecnicos.component";
import {
    LicAmbValidacaoDocumentosTecnicosComponent
} from "../validacao-titulo/documentos-tecnicos/documentos-tecnicos.component";
import {LicAmbAbaAtividadesLicEmpSharedComponent} from "./aba-atividades-lic-emp/aba-atividades-lic-emp.component";
import {LicAmbAbaDadosGeograficosComponent} from "./aba-dados-geograficos/aba-dados-geograficos.component";
import {LicAmbientalAtividadesObjetivosComponent} from "./atividades-objetivos/atividades-objetivos.component";
import {
    LicAmbModalHistoricoRequerimentoComponent
} from "./modal-historico-requerimento/modal-historico-requerimento.component";
import {LicAmbModalTarefasComponent} from "./modal-tarefas/modal-tarefas.component";
import {
    RequerimentoSolicitacoesDialogLicAmbSharedComponent
} from "./requerimento-solicitacoes-dialog/requerimento-solicitacoes-dialog.component";
import {
    RequerimentoSolicitacoesLicAmbSharedComponent
} from "./requerimento-solicitacoes/requerimento-solicitacoes.component";
import {
    HistoricoAtribuicoesDialogComponent
} from "../atribuir-requerimento-analista/historico-atribuicoes-dialog/historico-atribuicoes-dialog.component";


@NgModule({
    declarations: [
        RequerimentoSolicitacoesLicAmbSharedComponent, 
        RequerimentoSolicitacoesDialogLicAmbSharedComponent,
        LicAmbAbaDadosGeograficosComponent,
        LicAmbientalAtividadesObjetivosComponent,
        LicAmbAbaAtividadesLicEmpSharedComponent,
        LicAmbModalTarefasComponent,
        LicAmbModalHistoricoRequerimentoComponent,
        LicAmbEmissaoTituloDocumentosTecnicosComponent,
        LicAmbAtoAdministrativoSignatarioComponent,
        LicAmbValidacaoDocumentosTecnicosComponent,
        HistoricoAtribuicoesDialogComponent
    ],
    entryComponents: [
        LicAmbAbaDadosGeograficosComponent,
        LicAmbModalTarefasComponent,
        LicAmbModalHistoricoRequerimentoComponent, 
        LicAmbAtoAdministrativoSignatarioComponent,
        RequerimentoSolicitacoesLicAmbSharedComponent,
        RequerimentoSolicitacoesDialogLicAmbSharedComponent,
        LicAmbEmissaoTituloDocumentosTecnicosComponent,
        LicAmbValidacaoDocumentosTecnicosComponent,
        HistoricoAtribuicoesDialogComponent
    ],
    exports: [
        CommonModule,
        SharedModule,
        FlexModule,
        SemaGeoportalModule,
        RequerimentoSolicitacoesLicAmbSharedComponent,
        RequerimentoSolicitacoesDialogLicAmbSharedComponent,
        LicAmbAbaDadosGeograficosComponent,
        LicAmbientalAtividadesObjetivosComponent,
        LicAmbAbaAtividadesLicEmpSharedComponent,       
        LicAmbModalTarefasComponent,
        LicAmbModalHistoricoRequerimentoComponent,
        LicAmbEmissaoTituloDocumentosTecnicosComponent,
        LicAmbAtoAdministrativoSignatarioComponent,
        LicAmbValidacaoDocumentosTecnicosComponent,
        HistoricoAtribuicoesDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FlexModule,
        SemaGeoportalModule,        
    ]
})
export class LicenciamentoAmbientalSharedModule { 

}