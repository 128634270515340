import {Injectable, Injector} from '@angular/core';
import {EmpreendimentoObrasViarias} from "../../../../../../../shared/models/empreendimento-obras-viarias.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {InfraestruturaObrasViariasDto} from "../../../../../../../shared/models/infraestrutura-obras-viarias-dto.model";
import {BaseEmpreendimentoInfraObrasViariasService} from "../../../../../../../shared/services/base-empreendimento-infra-obras-viarias.service";
import {environment} from "../../../../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class InfraestruturaObrasViariasService extends BaseEmpreendimentoInfraObrasViariasService<EmpreendimentoObrasViarias>{
    constructor(protected injector: Injector) {
        super(
            '/api/obras-viarias',
            injector,
            EmpreendimentoObrasViarias.prototype,
            EmpreendimentoObrasViarias.fromJson
        );
    }

    salvarObraViaria(obraViariaDto: InfraestruturaObrasViariasDto): Observable<any> {
        const url = this.urlResourceEmpreendimentoInfraObraViaria;
        return this.http.post(url, obraViariaDto)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    buscarPorId(idEmpreendimento: number): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoInfraObraViaria}/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    buscarTipoObraPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/tipo-obra/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarTipoObraPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/tipo-obra/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarObrasEspeciaisPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/obra-especial/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarObraEspecialPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/obra-especial/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarRodoviasPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/rodovias/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarRodoviaPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/rodovias/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarTipoPavimentacaoPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/tipo-pavimentacao/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarTipoPavimentacaoPorId(idPavimentacao: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/tipo-pavimentacao/${idPavimentacao}`;
        return this.http.delete(url);
    }

    buscarCategoriaPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/categoria/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarCategoriaPorId(idCategoria: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS}/api/categoria/${idCategoria}`;
        return this.http.delete(url);
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoInfraObraViaria}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );;
    }
}
