import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {PontoVazaoMensal} from '../../ponto-vazao-mensal/models/ponto-vazao-mensal.model';
import {TipoCorpoHidrico} from '../../../models/tipo-corpo-hidrico.model';
import {TipoLancamento} from '../../../../pages/tipos-lancamento/tipo-lancamento.model';
import {PontoVazoesMensaisService} from '../../ponto-vazao-mensal/services/ponto-vazoes-mensais.service';
import {TipoCorpoHidricoService} from '../../../services/tipo-corpo-hidrico.service';
import {TipoLancamentoService} from '../../../../pages/tipos-lancamento/tipo-lancamento.service';
import {EnumMes} from '../../../enums/mes.enum';
import {PontoParametroDiluicaoService} from "../../../services/ponto-parametro-diluicao.service";
import {PontoParametroDiluicao} from "../../../models/hidrico-models/ponto-parametro-diluicao.model";
import {DecimalPipe} from "@angular/common";

@Component({
    selector: 'app-aba-objetivo-lancamentos-efluentes',
    templateUrl: './aba-objetivo-lancamentos-efluentes.component.html',
    styleUrls: ['aba-objetivo-lancamentos-efluentes.component.scss']
})

export class AbaObjetivoLancamentosEfluentesComponent implements OnInit {
    @Input() ponto: Ponto;
    lancamentosLenght: EventEmitter<number> = new EventEmitter<number>();

    lancamentos: Array<PontoVazaoMensal>;
    tipoCorposHidricos: Array<TipoCorpoHidrico>;
    tiposLancamento: TipoLancamento[] = [];

    pontoParametrosDiluicao: PontoParametroDiluicao[] = [];
    displayColumnsParametros: any[] = [];
    displayDataParametros: any[] = [];

    constructor(private lancamentoService: PontoVazoesMensaisService,
                private tipoCorpoHidricoService: TipoCorpoHidricoService,
                private tipoLancamentoService: TipoLancamentoService,
                private pontoParametroDiluicaoService: PontoParametroDiluicaoService,
                private decimalPipe: DecimalPipe) {
    }

    ngOnInit(): void {
        this.carregarLancamentos();
        this.listarParametrosDiluicao();
        this.tipoLancamentoService.getAll().subscribe(lancamentos => {
            this.tiposLancamento = lancamentos;
        });
    }

    private carregarLancamentos(): void {
        this.lancamentoService
            .getAllByPonto(this.ponto)
            .subscribe(lancamentos => {
                this.lancamentos = lancamentos;
                this.lancamentosLenght.emit(
                    this.lancamentos.length
                );
            });
    }

    private carregarTipoCorpoHidrico(): void {
        this.tipoCorpoHidricoService
            .getAll()
            .subscribe(tipoCorpoHidricoService => (this.tipoCorposHidricos = tipoCorpoHidricoService));
    }

    getMesTexto(tipo: string): string {
        return EnumMes[tipo];
    }

    private listarParametrosDiluicao() {
        this.pontoParametroDiluicaoService.findAllByPonto(this.ponto).subscribe(result => {
            this.pontoParametrosDiluicao = result;
            this.prepararTabelaParametros();
        });
    }

    private prepararTabelaParametros() {
        const configPipe = '1.0-6';

        this.displayColumnsParametros = ['0'].concat(this.pontoParametrosDiluicao.map((p, index) => (index + 1).toString()))
        this.displayDataParametros = [];
        this.displayDataParametros.push(['Finalidade'].concat(this.pontoParametrosDiluicao.map((p) => p.finalidade.descricao)));
        this.displayDataParametros.push(['Parâmetro'].concat(this.pontoParametrosDiluicao.map((p) => p.parametro.nome)));
        this.displayDataParametros.push(['Concentração'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.concentracao, configPipe))));
        this.displayDataParametros.push(['Concentração bruta'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.concentracaoBruta, configPipe))));
        this.displayDataParametros.push(['Mes / Vazão'].concat(this.pontoParametrosDiluicao.map(() => "Vazão de diluição (m³/s)")));
        this.displayDataParametros.push(['Janeiro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.janeiro, configPipe))));
        this.displayDataParametros.push(['Fevereiro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.fevereiro, configPipe))));
        this.displayDataParametros.push(['Março'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.marco, configPipe))));
        this.displayDataParametros.push(['Abril'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.abril, configPipe))));
        this.displayDataParametros.push(['Maio'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.maio, configPipe))));
        this.displayDataParametros.push(['Junho'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.junho, configPipe))));
        this.displayDataParametros.push(['Julho'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.julho, configPipe))));
        this.displayDataParametros.push(['Agosto'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.agosto, configPipe))));
        this.displayDataParametros.push(['Setembro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.setembro, configPipe))));
        this.displayDataParametros.push(['Outubro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.outubro, configPipe))));
        this.displayDataParametros.push(['Novembro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.novembro, configPipe))));
        this.displayDataParametros.push(['Dezembro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.dezembro, configPipe))));
    }
}


