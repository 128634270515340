import {environment} from '../../../../environments/environment';

export class RedirectUtil {

    public static redirecionaDashboardCamunda(): void {
        window.location.href = `${environment.URL_BPMS}?urlPortal=${environment.URL_PORTAL}`;
    }

    public static redirecionaDashboardCamundaComDelay(): void {
        setTimeout(() => RedirectUtil.redirecionaDashboardCamunda(), 3000);
    }

}
