import {BaseModel} from './base.model';

export class SignatarioDTO extends BaseModel {
    constructor(
        public cpf?: string,
        public nome?: string,
        public cargo?: string
    ) {
        super();
    }

    static fromJson(json: any): SignatarioDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new SignatarioDTO(
            json.cpf,
            json.nome,
            json.cargo);
    }

    static fromJsons(jsonArray: any): SignatarioDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
