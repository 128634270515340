import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {DominiosService} from '../../../../shared/services/dominios.service';
import {TanquePisciculturaService} from '../../../../shared/services/tanque-piscicultura.service';
import {TanquePiscicultura} from '../../../../shared/models/tanque-piscicultura.model';

@Component({
    selector: 'app-tanque-piscicultura',
    templateUrl: './tanque-piscicultura.component.html',
    styleUrls: ['./tanque-piscicultura.component.scss']
})
export class TanquePisciculturaComponent implements OnInit {
    tanquePsciculturaForm: FormGroup;
    tipoTanque: any;
    volumeUtil: any;
    tipoEspecieHibrido: any;
    tipoEspecieEBacia: any;
    especiePisciculturaTodos: any;
    espiciePiscicultura: any;
    tipoEspecieEBaciaTodos: any;
    especiePisciculturaSelecionada = '';
    statusTanqueRede = false;

    // : TODO Esses Parametros devem chegar atraves do component Pai
    idGeometria = 10;
    idAtividade = 414;

    // : TODO Remover apos realizar teste necessarios
    atividade = [{id: 3985, descricao: 'Laboratório de Alevinagem'},
        {id: 4135, descricao: 'Piscicultura Convencional em tanques escavados'},
        {id: 414, descricao: 'Piscicultura / Tanques-rede'},
        {id: 4435, descricao: 'Piscicultura / Tanques-suspenso'}];


    constructor(private formBuilder: FormBuilder,
                private dominiosService: DominiosService,
                private tanquePisciculturaService: TanquePisciculturaService,
                private snackBar: SnackBarService) {
    }

    async ngOnInit() {
        this.tanquePsiculturaForm();
        // await this.retornaTipoConformeIdDaAtividade(this.idAtividade);
        await this.carregarTipoTanque();
        await this.carregarEspeciePiscicultura();
        await this.carregarEspecieEBacia();
    }

    // : TODO Remover apos realizar teste necessarios
    preencherIdAtividade(value: any): void {
        this.retornaTipoConformeIdDaAtividade(value.id);
    }

    async carregarTipoTanque(): Promise<void> {
        this.tipoTanque = await this.dominiosService.getDominioTipoTanques().toPromise();
        this.tipoEspecieHibrido = await this.dominiosService.getDominioTipoEspecie().toPromise();
    }

    async carregarEspecieEBacia(): Promise<void> {
        this.tipoEspecieEBaciaTodos = await this.dominiosService.getDominioTipoEspecieBacia().toPromise();
    }

    async carregarEspeciePiscicultura(): Promise<void> {
        this.especiePisciculturaTodos = await this.dominiosService.getDominioEspeciePiscicultura().toPromise();

    }

    selecionarEspeciePiscicultura(espiciePiscicultura): void {
        this.especiePisciculturaSelecionada = espiciePiscicultura.descricao;
    }

    private tanquePsiculturaForm(): void {
        this.tanquePsciculturaForm = this.formBuilder.group({
            id: [null],
            tipoTanque: [null, Validators.required],
            tipoEspecieHibrido: [null, Validators.required],
            especiePiscicultura: [null, Validators.required],
            volumeUtil: [null, Validators.required],
            tipoEspecieEBacia: [null],
        });
    }

    valoresEspeciePiscicultura(idTipoEspeciePiscicultura): void {
        this.espiciePiscicultura = this.especiePisciculturaTodos.filter(ep => ep.tipoEspecie.id === idTipoEspeciePiscicultura);
    }

    async retornaTipoConformeIdDaAtividade(idAtividade: number): Promise<void> {

        // = “Laboratório de Alevinagem”
        if (idAtividade === 3985) {
            this.statusTanqueRede = false;
            this.tanquePsciculturaForm.get('volumeUtil').disable();
            this.tipoTanque = await this.tanquePisciculturaService.retornaTipoTanquePisciculturaAtvEspecifica(idAtividade).toPromise();

            // = “Piscicultura” / Convencional em tanques escavados
        } else if (idAtividade === 4135) {
            this.statusTanqueRede = false;
            this.tanquePsciculturaForm.get('volumeUtil').disable();
            this.tipoTanque = await this.tanquePisciculturaService.retornaTipoTanquePisciculturaAtvEspecifica(idAtividade).toPromise();

            // = “Piscicultura” / Tanques-rede
        } else if (idAtividade === 414) {
            this.statusTanqueRede = true;
            this.tanquePsciculturaForm.get('volumeUtil').enable();
            this.tipoTanque = await this.tanquePisciculturaService.retornaTipoTanquePisciculturaAtvEspecifica(idAtividade).toPromise();

            // = "Piscicultura" / Tanques-suspenso
        } else if (idAtividade === 4435) {
            this.statusTanqueRede = true;
            this.tanquePsciculturaForm.get('volumeUtil').enable();
            this.tipoTanque = await this.tanquePisciculturaService.retornaTipoTanquePisciculturaAtvEspecifica(idAtividade).toPromise();
        }
    }


    salvar(): void {
        const tanquePiscicultura = new TanquePiscicultura();
        if (this.tanquePsciculturaForm.valid) {
            tanquePiscicultura.idGeometria = this.idGeometria;
            tanquePiscicultura.tipoTanque = this.tanquePsciculturaForm.value.tipoTanque;
            tanquePiscicultura.tipoEspecieHibrido = this.tanquePsciculturaForm.value.tipoEspecieHibrido;
            tanquePiscicultura.especiePiscicultura = this.tanquePsciculturaForm.value.especiePiscicultura;
            tanquePiscicultura.volumeUtil = Number(this.tanquePsciculturaForm.value.volumeUtil);
            tanquePiscicultura.tipoEspecieEBacia = this.tanquePsciculturaForm.value.tipoEspecieEBacia;
            this.tanquePisciculturaService.salvarTanquePiscicultura(tanquePiscicultura).subscribe(() => {
                this.snackBar.showSuccess('Tanque piscicultura salva com sucesso.');
                this.statusTanqueRede = false;
                this.tanquePsciculturaForm.get('volumeUtil').disable();
                this.tanquePsciculturaForm.reset();
            });
        } else {
            this.snackBar.showError('Campo(s) obrigatório(s) não' +
                ' preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');

            this.tanquePsciculturaForm.markAllAsTouched();
        }

    }

}
