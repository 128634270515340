import {Injector, OnInit, Type} from '@angular/core';
import {PredicateResolver} from '../../requerimento-outorga/requerimento-atividade-outorga/predicate.resolver';
import {BaseRequerimentoLicenciamentoFormulario} from './base-requerimento-licenciamento.component';
import {LicenciamentoLacComponent} from './licenciamento-lac/licenciamento-lac.component';
import {Requerimento} from '../../requerimento.model';
import {LicenciamentoFlorestalComponent} from "./licenciamento-florestal/licenciamento-florestal.component";
import {LicenciamentoEmpreendimentosComponent} from "./licenciamento-empreendimentos/licenciamento-empreendimentos.component";
import { TipoProcessoService } from 'app/main/pages/tipos-processo/tipo-processo.service';
import { EnumInstanciaRequerimento } from '../../menu-novo-requerimento/instancia-requerimento.enum';

function byFormularioRequerimentoLicenciamento(instanciaRequerimento: string, type: Type<BaseRequerimentoLicenciamentoFormulario>):
    PredicateResolver<Requerimento, Type<BaseRequerimentoLicenciamentoFormulario>> {
    return {predicate: rl => rl.tipoProcesso.instanciaRequerimento === instanciaRequerimento, resolved: type};
}

export class RequerimentoLicenciamentoFormulario {

    // LicenciamentoLAC = 42
    // LicenciamentoFlorestal = 03
    // LicenciamentoEmpreencimento = 63
    // LicenciamentoEmpreencimentoAutorizacaoDocumentos = 104
    private static predicates: PredicateResolver<Requerimento, Type<BaseRequerimentoLicenciamentoFormulario>>[] = [
        byFormularioRequerimentoLicenciamento(EnumInstanciaRequerimento.RECURSO_FLORESTAL, LicenciamentoFlorestalComponent),
        byFormularioRequerimentoLicenciamento(EnumInstanciaRequerimento.LICENCIAMENTO_ADESAO, LicenciamentoLacComponent),
        byFormularioRequerimentoLicenciamento(EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO, LicenciamentoEmpreendimentosComponent),
        byFormularioRequerimentoLicenciamento(EnumInstanciaRequerimento.TERMO_REFERENCIA, LicenciamentoEmpreendimentosComponent),
        byFormularioRequerimentoLicenciamento(EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS, LicenciamentoEmpreendimentosComponent)
    ];

    static get(requerimento: Requerimento): PredicateResolver<Requerimento, Type<BaseRequerimentoLicenciamentoFormulario>> {
        return this.predicates.find(r => r.predicate(requerimento));
    }

}
