import {Injectable, Injector} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {BaseService} from "../base.service";
import {TipoTitulacaoArrecadacao} from "../../models/arrecadacao-models/tipo-titulacao-arrecadacao.model";

@Injectable({
    providedIn: 'root'
})
export class TipoTitulacaoService extends BaseService<TipoTitulacaoArrecadacao> {

    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            TipoTitulacaoArrecadacao.prototype,
            TipoTitulacaoArrecadacao.fromJson
        );
        this.urlResource = environment.URL_ARRECADACAO_API + '/api/tipo-titulacao/listar-titulacao';
    }

}