import {BaseEnum} from "../../enums/base.enum";
import {Ponto} from "./ponto.model";
import {SearchableModel} from "../../components/select-search/searchable-model";

export class DadoMeteorologico implements SearchableModel{
    itemLabel(): string {
        return `${this.codigo} - ${this.estacao}`;
    }

    searchValue(): string {
        return "";
    }

    constructor(
        public id?: number,
        public tipo?: string,
        public estacao?: string,
        public codigo?: string,
        public rio?: string,
        public areaDrenagem?: number,
        public ponto?: Ponto,
    ) {
    }

    static tiposEstacao: BaseEnum[] = [
        {valor: 'FLUVIOMETRICA', descricao: 'Fluviométrica '},
        {valor: 'PLUVIOMETRICA', descricao: 'Pluviométrica'},
    ]

    static fromJson(json: any): DadoMeteorologico {
        if (json === undefined || json === null) {
            return null;
        }
        return new DadoMeteorologico(
            json.id ? json.id : null,
            json.tipo ? json.tipo : null,
            json.estacao ? json.estacao : null,
            json.codigo ? json.codigo : null,
            json.rio ? json.rio : null,
            json.areaDrenagem ? json.areaDrenagem : null,
            json.ponto ? Ponto.fromJson(json.ponto) : null,
        );
    }

    static fromJsons(json: any): DadoMeteorologico[] {
        const itens: DadoMeteorologico[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => {
                itens.push(DadoMeteorologico.fromJson(item));
            });
            return itens;
        }
    }
}