import {BaseModel} from 'app/main/shared/models/base.model';
import {LancamentoTaxaDto} from './lancamento-taxa-dto.model';


export class LancamentoTaxaParamCalculoDto extends BaseModel {

    constructor(
        public id: number,
        public lancamentoTaxa?: LancamentoTaxaDto,
        public labelFormulario?: string,
        public valor?: string,
        public tipoParametro?: string,
        public descricao?: string,
        public autorizacao?: boolean,
    ) {
        super();
    }


    static fromJson(json: any): LancamentoTaxaParamCalculoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new LancamentoTaxaParamCalculoDto(
            json.id,
            LancamentoTaxaDto.fromJson(json.lancamentoTaxa),
            json.labelFormulario,
            json.valor,
            json.tipoParametro,
            json.descricao,
            json.autorizacao
        );
    }

    static fromJsons(json: any): LancamentoTaxaParamCalculoDto[] {
        const array: LancamentoTaxaParamCalculoDto[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(LancamentoTaxaParamCalculoDto.fromJson(item)));
            return array;
        }
    }

}
