export enum EnumMes {
    TODOS = 'TODOS',
    JANEIRO = 'JANEIRO',
    FEVEREIRO = 'FEVEREIRO',
    MARCO = 'MARÇO',
    ABRIL = 'ABRIL',
    MAIO = 'MAIO',
    JUNHO = 'JUNHO',
    JULHO = 'JULHO',
    AGOSTO = 'AGOSTO',
    SETEMBRO = 'SETEMBRO',
    OUTUBRO = 'OUTUBRO',
    NOVEMBRO = 'NOVEMBRO',
    DEZEMBRO = 'DEZEMBRO'
}

export const ENUM_MES_VALUES: any[] = Object.keys(EnumMes);
export const COMPARATOR_MES = (a, b) => (ENUM_MES_VALUES.indexOf(a) - ENUM_MES_VALUES.indexOf(b));
export const ENUM_MES_VALUES_ONLY: any[] = Object.keys(EnumMes).filter(mes => mes !== 'TODOS');
