import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import { CepDTO } from './cep-dto';


@Injectable({
  providedIn: 'root'
})
export class ViacepService {

  private  http: HttpClient;
  private  urlResource: string = environment.URL_SCORP_API;

  constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/cep';
  }

  getCep(cep: string): Observable<CepDTO> {
    const cepStr = cep.replace('.', '').replace('-', '');
    const url = `${this.urlResource}/${cepStr}`;
    return this.http.get(url).pipe(
      map(response => Object.assign(response)),
      catchError(this.handleError.bind(this))
    );
  }

  protected handleError(error: any): Observable<any> {
    return throwError(error);
  }

}

