import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Benfeitoria} from '../models/benfeitoria.model';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BenfeitoriaService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_LIC_FEICAO_BENFEITORIA;

    createBenfeitoria(benfeitoria: Benfeitoria): Observable<Benfeitoria> {
        const url = `${this.urlResource}/api/benfeitoria`;
        return this.http.post(url, benfeitoria)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscaTipoBenfeitoriaIdAtividade(idAtividade: number): Observable<any> {
        const url = `${this.urlResource}/api/tipo-benfeitoria-atv/busca-tipo-benfeitoria/${idAtividade}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    retornaTipoBenfeitoriaFiltrada(idAtividade: number): Observable<any> {
        const url = `${this.urlResource}/api/tipo-benfeitoria-atv/retorna-tipo-benfeitoria/${idAtividade}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }


}
