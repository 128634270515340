import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {TermoReferenciaRequerimentoDto} from "../models/termo-referencia-requerimento-dto.model";
import {TermoReferenciaRequerimento} from "../models/termo-referencia-requerimento.model";

@Injectable({
    providedIn: 'root'
})
export class TermoReferenciaRequerimentoService extends BaseService<TermoReferenciaRequerimentoDto> {
    constructor(protected injector: Injector) {
        super(
            '/api/termos-referencia-requerimento',
            injector,
            TermoReferenciaRequerimentoDto.prototype,
            TermoReferenciaRequerimentoDto.fromJson
        );
    }

    createTermosConfEmp(termos: any, idRequerimentoLicenciamento: number): Observable<TermoReferenciaRequerimento[]> {
        return this.http
            .post(this.urlResource +
                `/criar-termo-ref-req-emp/requerimento-licenciamento/${idRequerimentoLicenciamento}`,
                termos)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}
