import { BaseModel } from 'app/main/shared/models/base.model';
import { EmpreendimentoEnergTermica } from './empreendimento-energ-termica';

export class GeradorEletricoUsinaTerm extends BaseModel {
    constructor(

        public id?: number,
        public empreendimentoEnergTermica?: EmpreendimentoEnergTermica,
        public identificacao?: string,
        public valorPotenciaAparente?: number,
        public valorFatorPotencia?: number,
        public valorPotenciaAtiva?: number,
        public valorTensao?: number,
        public valorFrequencia?: number,
        public classeIsolamento?: string,
        public valorRotacao?: number,
        public nomeFabricante?: string,
        public dataPrevista?: Date,

    ) {
        super();
    }
    static fromJson(json: any): GeradorEletricoUsinaTerm {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeradorEletricoUsinaTerm(
            json.id,
            EmpreendimentoEnergTermica.fromJson(json.empreendimentoEnergTermica),
            json.identificacao,
            json.valorPotenciaAparente,
            json.valorFatorPotencia,
            json.valorPotenciaAtiva,
            json.valorTensao,
            json.valorFrequencia,
            json.classeIsolamento,
            json.valorRotacao,
            json.nomeFabricante,
            json.dataPrevista
        );
    }

    static fromJsons(json: any): GeradorEletricoUsinaTerm[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(GeradorEletricoUsinaTerm.fromJson(j)));
            return resources;
        }
    }
}
