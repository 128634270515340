import {Pipe, PipeTransform} from "@angular/core";
@Pipe({
    name: 'borderStyle',
})
export class BorderStylePipe implements PipeTransform {
    transform(estilo: number | string): string {
        if (estilo == 1) {
            return 'dashed';
        } else if (estilo == 2) {
            return 'dotted';
        }
        return 'solid';
    }
}
