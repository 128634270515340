import {BaseModel} from 'app/main/shared/models/base.model';

export class ObraPluvialFluvial extends BaseModel {
    constructor(
        public id?: number,
        public rampa?: boolean,
        public pier?: boolean,
        public marina?: boolean,
        public porto?: boolean,
        public tablado?: boolean,
        public outro?: boolean,
        public descricaoTipoObra?: string,
        public zona?: number,
        public anuenciaMarinha?: boolean,
        public desapropriacao?: boolean,
        public areaBensTombadosAcautelados?: boolean
    ) {
        super();
    }

    static fromJson(json: any): ObraPluvialFluvial {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObraPluvialFluvial(
            json.id,
            json.rampa,
            json.pier,
            json.marina,
            json.porto,
            json.tablado,
            json.outro,
            json.descricaoTipoObra,
            json.zona,
            json.anuenciaMarinha,
            json.desapropriacao,
            json.areaBensTombadosAcautelados
        );
    }

    static fromJsons(json: any): ObraPluvialFluvial[] {
        const lista: ObraPluvialFluvial[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => lista.push(this.fromJson(item)));
            return lista;
        }
    }

}
