import {BaseModel} from "./base.model";
import {PrioridadeProcesso} from "./prioridade-processo.model";

export class PrioridadeProcessoCriterio extends BaseModel {

    constructor(
        public id?: number,
        public tipoPrioridade?: number,
        public chave?: string,
        public nomeArquivo?: string,
        public prioridadeProcesso?: PrioridadeProcesso,
        public sigiloso?: boolean
    ) {
        super();
    }

    static fromJson(json: any): PrioridadeProcessoCriterio {
        if (json === undefined || json === null) {
            return null;
        }
        return new PrioridadeProcessoCriterio(
            json.id,
            json.tipoPrioridade,
            json.chave,
            json.nomeArquivo,
            json.prioridadeProcesso,
            json.sigiloso
        );
    }

    static fromJsons(json: any): PrioridadeProcessoCriterio[] {
        const lista: PrioridadeProcessoCriterio[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => lista.push(this.fromJson(item)));
            return lista;
        }
    }
}