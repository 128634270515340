import {BaseModel} from 'app/main/shared/models/base.model';

export class ArquivoSimcarDTO extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public tipo?: string,
        public tamanho?: number,
        public url?: string,
        public temporario?: number,
        public situacao?: string,
        public motivoFalha?: string,
        public dataExecucao?: Date,
    ) {
        super();
    }

    static fromJson(json: any): ArquivoSimcarDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new ArquivoSimcarDTO(
            json.id,
            json.nome,
            json.tipo,
            json.tamanho,
            json.url,
            json.temporario,
            json.situacao,
            json.motivoFalha,
            json.dataExecucao ? new Date(json.dataExecucao) : null
        );
    }
}
