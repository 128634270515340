import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {Titulacao} from './titulacao.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class TitulacaoListResolver extends ListResolver<Titulacao> {
    constructor(service: TitulacaoService) {
        super(service);
    }
}

@Injectable()
export class TitulacaoResolver extends DetailResolver<Titulacao> {
    constructor(
        service: TitulacaoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TitulacaoService extends BaseService<Titulacao> {
    constructor(protected injector: Injector) {
        super(
            '/api/titulacoes',
            injector,
            Titulacao.prototype,
            Titulacao.fromJson
        );
    }

    getAllByTipo(tipo: any): Observable<Titulacao[]> {
        const url = `${this.urlResource}/tipos/${tipo}`;
        return this.http.get(url).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getAllByTipoRequerimento(tipoRequerimento: any): Observable<Titulacao[]> {
        const url = `${this.urlResource}/tipo-requerimento/${tipoRequerimento}`;
        return this.http.get(url).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }
}
