import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    OnDestroy,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {MglTimelineEntryContentComponent} from '../timeline-entry-content/timeline-entry-content.component';
import {MglTimelineEntryHeaderComponent} from '../timeline-entry-header/timeline-entry-header.component';
import {Subscription} from 'rxjs';
import {MglTimelineEntryDotComponent} from "../timeline-entry-dot/timeline-entry-dot.component";
import {MglTimelineEntrySideComponent} from "../timeline-entry-side/timeline-entry-side.component";

@Component({
    selector: 'mgl-timeline-entry',
    templateUrl: './timeline-entry.component.html',
    styleUrls: ['./timeline-entry.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MglTimelineEntryComponent implements AfterViewInit, OnDestroy {

    private subscriptions: Subscription[] = [];

    focusOnOpen = false;

    set expanded(expanded) {
        if (this.dot && expanded) {
            this.dot.expanded = expanded;
        } else {
            this.content.expanded = expanded;
        }
        this.changed.emit(expanded);
    }

    @HostBinding('class.expanded')
    get expanded(): boolean {
         return this.dot ? (this.dot.expanded && this.content.expanded) : this.content.expanded;
    }

    private _mobile = false;

    set mobile(value) {
        this.elementRef.nativeElement.classList.toggle('mobile', value);
        if (this.dot) {
            this.dot.mobile = value;
        }
        if (this.side) {
            this.side.mobile = value;
        }
    }

    // tslint:disable-next-line:no-output-rename
    @Output('expand')
    changed = new EventEmitter<boolean>();

    @Output()
    animationDone = new EventEmitter<string>();

    @ContentChild(MglTimelineEntryContentComponent, {static: false}) content: MglTimelineEntryContentComponent;
    @ContentChild(MglTimelineEntryHeaderComponent, {static: false}) header: MglTimelineEntryHeaderComponent;
    @ContentChild(MglTimelineEntryDotComponent, {static: false}) dot: MglTimelineEntryDotComponent;
    @ContentChild(MglTimelineEntrySideComponent, {static: false}) side: MglTimelineEntrySideComponent;

    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit(): void  {
        if (this.dot) {
            this.subscriptions.push(
                this.dot.animationDone.subscribe(event => {
                    if (event.toState === 'expanded') {
                        this.content.expanded = true;
                    } else {
                        this.animationDone.emit(event);
                    }
                }));
        }
        if (this.content) {
            this.subscriptions.push(this.content.animationDone.subscribe(event => {
                if (this.dot && event.toState === 'collapsed') {
                    this.dot.expanded = false;
                } else {
                    if (this.focusOnOpen) {
                        this.elementRef.nativeElement.scrollIntoView({behavior: 'smooth'});
                    }
                    this.animationDone.emit(event);
                }
            }));
        }
    }

    set alternate(value: boolean) {
        this.elementRef.nativeElement.classList.toggle('alternate', value);
        if (this.dot) {
            this.dot.alternate = value;
        }
        if (this.side) {
            this.side.alternate = value;
        }
    }

    ngOnDestroy(): void  {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    collapse(): void {
        this.expanded = false;
    }

    expand(): void {
        this.expanded = true;
    }


    toggle(event: MouseEvent): void  {
        const headerFound = this.containsInPath(event, 'mgl-timeline-entry-header');
        const contentFound = this.containsInPath(event, 'mgl-timeline-entry-content');
        if (headerFound && contentFound) {
            this.expanded = !this.expanded;
        }
    }

    private containsInPath(mouseEvent: MouseEvent, name: string): boolean {
        let currentElem: Element = mouseEvent.target as Element;
        while (currentElem) {
            if (currentElem.localName === name) {
                return true;
            }
            currentElem = currentElem.parentElement;
        }
        return false;
    }
}
