import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {SubstanciaMineral} from "../../../../../../../../shared/models/substancia-mineral.model";

@Component({
    selector: 'app-substancia-mineral',
    templateUrl: './substancia-mineral.component.html',
    styleUrls: ['./substancia-mineral.component.scss']
})
export class SubstanciaMineralComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'quantidade', 'acoes'];

    tipos: DominioDto[];
    unidades: DominioDto[];
    unidadesTempo: DominioDto[];

    formSubstanciaMineral = this.fb.group({
        id: [null],
        mineracao: [null],
        idSubstacaoMineracao: [null, Validators.required,],
        quantidadeMensal: [null, Validators.required,],
        idUnidadeMedida: [null, Validators.required,],
        idUnidadeTempo: [null, Validators.required,]
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-substancia-mineral')
            .subscribe(doms => this.tipos = doms);
        this.dominiosService.getDominioByUrl('tipo-unidade-medida-substancia-mineral')
            .subscribe(doms => this.unidades = doms);
        this.dominiosService.getDominioByUrl('unidade-tempo')
            .subscribe(doms => this.unidadesTempo = doms);

        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formSubstanciaMineral.disable();
        }
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    getDescricaoUnidadeMedida(id: number) {
        const dom = this.unidades && this.unidades.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formSubstanciaMineral.valid) return;

        const substanciasMineral = this.form.get('substanciasMineral');

        substanciasMineral.setValue([
            ...(substanciasMineral.value || []),
            SubstanciaMineral.fromJson(this.formSubstanciaMineral.value)
        ]);

        this.formSubstanciaMineral.reset();
    }

    excluir(item: SubstanciaMineral) {
        const estruturas: AbstractControl = this.form.get('substanciasMineral');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: SubstanciaMineral) {
        this.formSubstanciaMineral.patchValue(item);

        this.excluir(item);
    }

    getDescricaoUnidadeTempo(id: number) {
        const dom = this.unidadesTempo && this.unidadesTempo.find(d => d.id === id);
        return dom && dom.descricao;
    }
}
