import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material';
import {fuseAnimations} from '@fuse/animations';
import {Observable} from 'rxjs';
import {ConfirmarExclusaoDialogComponent} from '../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {AssuntoNota} from '../../../shared/models/assunto-nota.model';
import {AssuntoNotaService} from '../../../shared/services/assunto-nota.service';
import {RequerimentoNota} from './requerimento-notas.model';
import {RequerimentoService} from '../requerimento.service';
import {RequerimentoNotasNewComponent} from './requerimento-notas-new/requerimento-notas-new.component';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-requerimento-notas',
    templateUrl: './requerimento-notas.component.html',
    styleUrls: ['./requerimento-notas.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoNotasComponent implements OnInit {
    assuntos: Array<AssuntoNota>;
    notas: Array<RequerimentoNota>;
    pesquisaAssuntos: Array<number>;
    pesquisaAnalista: string;
    pesquisaComentario: string;
    requerimentoId: number;
    @Input() titleUpdateEmitter;
    @Output() addButtonsEmitter = new EventEmitter();
    @Output() updateModalComponentEmitter = new EventEmitter();

    constructor(
        private assuntoNotaService: AssuntoNotaService,
        private requerimentoService: RequerimentoService,
        private dialog: MatDialog,
        private snackBarService: SnackBarService
    ) {

    }

    ngOnInit(): void {
        this.titleUpdateEmitter.emit('Notas do requerimento: ' + this.requerimentoId);

        this.loadAssuntos();

        this.loadNotas();

        // Adicionar botão específico da tela na modal
        const buttonAddNota = document.createElement('btnAddNota') as HTMLButtonElement;
        buttonAddNota.innerText = 'Adicionar nova nota';
        buttonAddNota.click = () => {
            this.updateModalComponentEmitter.emit(RequerimentoNotasNewComponent);
        };
        this.addButtonsEmitter.emit([buttonAddNota]);
    }

    excluirNotaDialog(nota: RequerimentoNota): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {
                label: nota.id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirNota(nota);
            }
        });
    }

    private excluirNota(nota: RequerimentoNota): void {
        const onSuccess = () => {
            if (this.excluirNotaItem(nota)) {
                this.snackBarService.showSuccess('Nota excluída com sucesso.');
            }
        };

        this.requerimentoService
            .deleteNota(this.requerimentoId, nota.id)
            .subscribe(
                onSuccess.bind(this),
                this.onError.bind(this)
            );
    }

    private excluirNotaItem(nota: RequerimentoNota): boolean {
        if (this.notas && this.notas.length && nota && nota.id) {
            const index = this.notas.findIndex(rn => rn.id === nota.id);

            if (index > -1) {
                this.notas.splice(index, 1);

                return true;
            }
        }

        return false;
    }

    private fetchNotas(httpParams?: HttpParams): Observable<Array<RequerimentoNota>> {
        return this.requerimentoService.getNotas(this.requerimentoId, httpParams);
    }

    limparPesquisa(): void {
        this.pesquisaAnalista = null;
        this.pesquisaAssuntos = null;
        this.pesquisaComentario = null;

        this.loadNotas();
    }

    private loadAssuntos(): void {
        this.assuntoNotaService.getAll().subscribe(
            assuntos => this.assuntos = assuntos,
            this.onError.bind(this)
        );
    }

    private loadNotas(): void {
        this.fetchNotas().subscribe(
            notas => this.notas = notas,
            this.onError.bind(this)
        );
    }

    private onError(error): void {
        this.snackBarService.showError(error || 'Oops! ocorreu um problema inesperado.');
    }

    pesquisarNotas(): void {
        const onNotas = notas => {
            this.notas = notas;

            if (!this.notas.length) {
                this.snackBarService.showAlert('Não há resultados para essa pesquisa.');
            }
        };

        let httpParams: HttpParams = new HttpParams();
        if (this.pesquisaAssuntos && this.pesquisaAssuntos.length) {
            httpParams = httpParams.set('assuntos', this.pesquisaAssuntos.join(','));
        }
        if (this.pesquisaAnalista && this.pesquisaAnalista.length) {
            httpParams = httpParams.set('analista', this.pesquisaAnalista);
        }
        if (this.pesquisaComentario && this.pesquisaComentario.length) {
            httpParams = httpParams.set('comentario', this.pesquisaComentario);
        }

        this.fetchNotas(httpParams).subscribe(
            onNotas,
            this.onError.bind(this)
        );
    }

}
