import {Injectable, Injector} from '@angular/core';
import {BaseRelacionamentoService} from "./base-relacionamento.service";
import {DominiosService} from "./dominios.service";
import {ProdutoProcessadoProprioIndFrigorifico} from "../models/produto-processado-proprio-ind-frigorifico.model";
import {environment} from "../../../../environments/environment";


@Injectable({
    providedIn: "root"
})
export class ProdutoProcessadoProprioIndFrigorificoService extends BaseRelacionamentoService<ProdutoProcessadoProprioIndFrigorifico> {

    constructor(protected injector: Injector, protected dominiosService: DominiosService) {
        super(
            "/api/prod-proc-proprio-ind-frig",
            injector,
            ProdutoProcessadoProprioIndFrigorifico.prototype,
            ProdutoProcessadoProprioIndFrigorifico.fromJson,
            dominiosService,
            false,
            environment.URL_LIC_IND_FRIGORIFICO
        );
    }

    protected get pathGetByEntidadePai(): string {
        return "industria-frigorifico";
    }

    protected get pathGetDominio(): string {
        return "produto-processado-proprio";
    }


}
