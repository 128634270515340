import {Injectable, Injector} from '@angular/core';
import {ListResolver} from '../resolvers/list-resolver';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {EmpreendimentoInfraParcelSoloResCom} from "../models/empreendimento-infra-parcel-solo-res-com.model";
import {BaseInfraParcelSoloResCom} from "./base-infra-parcel-solo-res-com.service";


@Injectable()
export class EmpreendimentoInfraParcelSoloResComListResolver extends ListResolver<EmpreendimentoInfraParcelSoloResCom> {
    constructor(service: EmpreendimentoInfraParcelSoloResComService) {
        super(service);
    }
}

@Injectable()
export class EmpreendimentoInfraParcelSoloResComResolver extends DetailResolver<EmpreendimentoInfraParcelSoloResCom> {
    constructor(
        service: EmpreendimentoInfraParcelSoloResComService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class EmpreendimentoInfraParcelSoloResComService extends BaseInfraParcelSoloResCom<EmpreendimentoInfraParcelSoloResCom> {

    constructor(protected injector: Injector) {
        super(
            "/api/empreendimentos-infra-parcel-solo-res-com",
            injector,
            EmpreendimentoInfraParcelSoloResCom.prototype,
            EmpreendimentoInfraParcelSoloResCom.fromJson
        );
    }

    getByRequerimento(idRequerimento: number): Observable<EmpreendimentoInfraParcelSoloResCom> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
