import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
    selector: 'app-agua-passivo-supressao-uso-agua',
    templateUrl: './agua-passivo-supressao-uso-agua.component.html',
    styleUrls: ['./agua-passivo-supressao-uso-agua.component.scss']
})
export class AguaPassivoSupressaoUsoAguaComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;
    @Input() requerimento: Requerimento;

    constructor(
        private fb: FormBuilder,
        private snackBarService: SnackBarService
    ) {
    }

    ngOnInit() {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    mudouTipoCaptacao(value) {
        if (!!value && !!value.length) {
            if (value.indexOf('concessionaria') >= 0) {
                if (!!this.form.get('usoAgua').get('captacaoSuperficial')) {
                    (this.form.get('usoAgua') as FormGroup).removeControl('captacaoSuperficial');
                }

                if (!!this.form.get('usoAgua').get('captacaoSubterranea')) {
                    (this.form.get('usoAgua') as FormGroup).removeControl('captacaoSubterranea');
                }
            } else {
                if (value.indexOf('superficial') >= 0) {
                    if (!this.form.get('usoAgua').get('captacaoSuperficial')) {
                        (this.form.get('usoAgua') as FormGroup).addControl('captacaoSuperficial', this.fb.control(null, [Validators.required]))
                    }
                } else if (!!this.form.get('usoAgua').get('captacaoSuperficial')) {
                    (this.form.get('usoAgua') as FormGroup).removeControl('captacaoSuperficial');
                }

                if (value.indexOf('subterranea') >= 0) {
                    if (!this.form.get('usoAgua').get('captacaoSubterranea')) {
                        (this.form.get('usoAgua') as FormGroup).addControl('captacaoSubterranea', this.fb.control(null, [Validators.required]))
                    }
                } else if (!!this.form.get('usoAgua').get('captacaoSubterranea')) {
                    (this.form.get('usoAgua') as FormGroup).removeControl('captacaoSubterranea');
                }
            }
        } else {
            (this.form.get('usoAgua') as FormGroup).removeControl('captacaoSuperficial');
            (this.form.get('usoAgua') as FormGroup).removeControl('captacaoSubterranea');
        }
    }

    mudouCaptacaoSuperficial(event) {
        this.validaCamposSelecionadosJuntos(this.form.get('usoAgua').get('captacaoSuperficial') as FormControl, event.value, 'outorgaCaptacaoSuperficial', 'usoInsignificanteCaptacaoSuperficial');
        if(event.value == 'aguaChuva')
            this.snackBarService.showAlert("Para Tipo de Captação “Água de chuva” é necessário informar na aba 4 a feição para a captação.")
    }

    mudouCaptacaoSubterranea(event) {
        if (event.value === 'pocosRasos') {
            this.validaCamposSelecionadosJuntos(this.form.get('usoAgua').get('captacaoSubterranea') as FormControl, event.value, 'outorgaCaptacaoSubterranea', 'pocosRasos');
        } else if (event.value === 'usoInsignificanteCaptacaoSubterranea') {
            this.validaCamposSelecionadosJuntos(this.form.get('usoAgua').get('captacaoSubterranea') as FormControl, event.value, 'outorgaCaptacaoSubterranea', 'usoInsignificanteCaptacaoSubterranea');
        } else {
            if(event.value == 'outorgaCaptacaoSubterranea')
                this.snackBarService.showAlert("Para Tipo de Captação “Outorga” é necessário informar na aba 4 o ponto de captação de outorga.")
            this.validaCamposSelecionadosJuntos(this.form.get('usoAgua').get('captacaoSubterranea') as FormControl, event.value, 'outorgaCaptacaoSubterranea', 'pocosRasos', 'usoInsignificanteCaptacaoSubterranea');
        }
    }

    validaCamposSelecionadosJuntos(formControl: FormControl, selecionado: string, ...campos) {
        if (formControl.value && campos.includes(selecionado)) {
            let camposFiltrados = campos.filter(c => c !== selecionado);
            formControl.setValue(formControl.value.filter(v => !camposFiltrados.includes(v)));
        }
    }

}
