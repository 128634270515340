import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GeometriaMapa} from '@sema-geo/sema-geoportal';
import {FeicaoAnaliseRequerimentoService} from "../../../../pages/feicoes-analise-requerimentos/feicao-analise-requerimento.service";
import {ModeloFeicaoDTO} from "../../../models/modelo-feicao-dto.model";
import {UtilService} from "../../../../core/services/util.service";
import {DatePipe} from "@angular/common";

export interface DialogEditarFeicaoDESMATEData {
    denomicacao: string;
    idModeloFeicao: number;
    modeloFeicaoDTO: ModeloFeicaoDTO;
    tamanho: number;
    geometria: GeometriaMapa;
    visualizacao: boolean;
    onRemoveFeature: (geometria: GeometriaMapa) => void;
}

@Component({
    selector: 'app-dialog-editar-feicao',
    templateUrl: './dialog-edicao-feicao-desmate.component.html',
    styleUrls: ['./dialog-edicao-feicao-desmate.component.scss'],
})
export class DialogEdicaoFeicaoDesmateComponent {

    form: FormGroup;

    geometria: GeometriaMapa;

    tamanho: number;

    onRemoveFeature: (geometria: GeometriaMapa) => void;

    label: string;

    constructor(
        private formBuilder: FormBuilder,
        private feicaoAnaliseRequerimentoService: FeicaoAnaliseRequerimentoService,
        private snackBarService: SnackBarService,
        private utilService: UtilService,
        private datePipe: DatePipe,
        private dialogRef: MatDialogRef<DialogEditarFeicaoDESMATEData>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEditarFeicaoDESMATEData
    ) {
        this.data.visualizacao = this.data.visualizacao ? this.data.visualizacao : false;
        this.onRemoveFeature = data.onRemoveFeature;
        this.tamanho = data.tamanho;
        this.geometria = data.geometria;


        this.form = this.formBuilder.group({
            denomicacao: [null, [Validators.required, Validators.maxLength(this.tamanho)]],
            dataEvento: [null, [Validators.required]],
            possuiAutorizacao: [null, [Validators.required]],
            numeroAutorizacao: [null, [Validators.required]],
        });
        this.form.get('numeroAutorizacao').disable();

        this.form.get('denomicacao').setValue(data.denomicacao);
        if (this.data.hasOwnProperty('modeloFeicaoDTO') && this.data.modeloFeicaoDTO && this.data.modeloFeicaoDTO.modeloFeicaoDesmate) {
            this.form.get('dataEvento').setValue(this.utilService.zonedDateTimeFormat(data.modeloFeicaoDTO.modeloFeicaoDesmate.dataEvento, 'YYYY-MM-DD'))
            this.form.get('possuiAutorizacao').setValue(data.modeloFeicaoDTO.modeloFeicaoDesmate.possuiAutorizacao === true ? 'true' : 'false')
            this.form.get('numeroAutorizacao').setValue(data.modeloFeicaoDTO.modeloFeicaoDesmate.numeroAutorizacao)
        }
        if(this.data.visualizacao){
            this.form.disable()
        }
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onRemover(): void {
        this.onRemoveFeature(this.geometria);
        this.dialogRef.close({
            success: false
        });
    }

    exibirNumeroAutorizacao(evt: any): void {
        if (evt.value === 'true') {
            this.label = 'Número da Autorização';
            this.form.get('numeroAutorizacao').enable();
        } else {
            this.label = 'Sem autorização';
            this.form.get('numeroAutorizacao').disable();
        }
    }

    numeroAnoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.form.get('numeroAutorizacao').setValue(`${initial}/${year}`);
            }
        }
    }

    salvar(): void {
        this.dialogRef.close({
            success: true,
            geometria: this.geometria,
            feicao: {
                denominacao: this.form.get('denomicacao').value,
                modeloFeicaoDTO: {
                    idModeloFeicao: this.data.idModeloFeicao,
                    modeloFeicaoDesmate: {
                        dataEvento: new Date(this.form.get('dataEvento').value),
                        possuiAutorizacao: this.form.get('possuiAutorizacao').value,
                        numeroAutorizacao: this.form.get('numeroAutorizacao').value
                    }
                }
            }
        });
    }
}
