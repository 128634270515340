import {HttpParams} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from "../base.service";
import {Feicao} from "../../models/geoadmin/feicao.model";
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class FeicaoService extends BaseService<Feicao> {
    constructor(protected injector: Injector) {
        super("/api/feicao", injector, Feicao.prototype, Feicao.fromJson);

        this.urlResource = environment.URL_GEOADMIN + this.apiPath;
    }

    getAll(httpParams?: HttpParams): Observable<Feicao[]> {
        return this.http.get(`${this.urlResource}/list-all`, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }
    getFeicao(id: Number): Observable<Feicao> {
        return this.http.get(`${this.urlResource}/` + id, ).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}
