import {BaseModel} from 'app/main/shared/models/base.model';

export class FormaOcupacao extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public outro?: boolean,
        public idGrupoDocumento?: number
    ) {
        super();
    }

    static fromJson(json: any): FormaOcupacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new FormaOcupacao(
            json.id,
            json.descricao,
            json.outro,
            json.idGrupoDocumento
        );
    }

}
