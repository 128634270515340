import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EmissaoTitulo} from '../../../../shared/models/emissao-titulo.model';
import {HttpParams} from '@angular/common/http';
import {Requerimento} from '../../../requerimentos/requerimento.model';

@Injectable({
    providedIn: 'root'
})
export class EmissaoTituloService extends BaseService<EmissaoTitulo> {

    constructor(protected injector: Injector) {
        super('/api/emissao-titulo', injector, EmissaoTitulo.prototype, EmissaoTitulo.fromJson);
    }

    finalizarTarefaCamunda(idRequerimento: number, idTarefa: string, atoAdministrativo: any): Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa/${idRequerimento}/${idTarefa}/`;
        return this.http.post(url, atoAdministrativo).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    recuperarListaDeEmissoesAguardandoDocumentoEPublicacao(requerimentoId): Observable<EmissaoTitulo[]> {
        const url = `${this.urlResource}/aguardando-documento-publicacao/requerimento/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(EmissaoTitulo.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    readPdf(emissaoTitulo: EmissaoTitulo, forceRefresh: boolean = false): Observable<any> {
        return this.http.get(`${this.urlResource}/${emissaoTitulo.id}/pdf`, {
            responseType: 'blob',
            params: new HttpParams().set('forceRefresh', String(forceRefresh))
        }).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ),
            catchError(this.handleError));
    }

    buscaPorIdRequerimentoDocumentoProcesso(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/emissao-titulo/documento-processo/requerimento-id/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(EmissaoTitulo.fromJson.bind(this)),
            catchError(this.handleError)
        );
    }

    buscaEmissoesPorIdRequerimentoDocumentoProcesso(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/emissoes-titulo/requerimento-id/${idRequerimento}/natu-digital`;
        return this.http.get(url).pipe(
            map(EmissaoTitulo.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    getEmissoesPorImovel(idRequerimento: number) {
        const url = `${this.urlResource}/${idRequerimento}/recuperar-emissoes-imovel`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    alterarSituacaoTituloDecisao(emissao: EmissaoTitulo) {
        const url = `${this.urlResource}/alterar-situacao-titulo-decisao`;

        return this.http.put(url, emissao).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    downloadDocumentoTermoAdessao(idRequerimento: number): Observable<Blob> {
        // tslint:disable-next-line:max-line-length
        const url = `${this.urlResource}/${idRequerimento}/termo-adessao-compromisso-download`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    anexarTitulacaoRequerimentoLegado(form: any, requerimento: Requerimento): Observable<EmissaoTitulo> {
        const url = `${this.urlResource}/requerimento-legado/${requerimento.id}`;
        return this.http.post(url, form).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    excluirTitulacaoRequerimentoLegado(idEmissao: number): Observable<any> {
        const url = `${this.urlResource}/requerimento-legado/${idEmissao}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    readByEmissaoTituloNumeroEmissaoAndReqTitulacaoId(numeroEmissao: any, requerimentoTitulacaoId = null): Observable<EmissaoTitulo[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('numeroEmissao', numeroEmissao)
        if (requerimentoTitulacaoId) {
            httpParams = httpParams.append('requerimentoTitulacaoId', requerimentoTitulacaoId.toString())
        }
        const url = `${this.urlResource}/listar-titulos-por-numero-emissao`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(EmissaoTitulo.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    readByEmissaoTituloByNumeroProcesso(numeroProcesso: any): Observable<EmissaoTitulo[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('numeroProcesso', numeroProcesso)
        const url = `${this.urlResource}/listar-titulos-por-numero/processo`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(EmissaoTitulo.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    readByEmissaoTituloNumeroEmissaoAndReqTitulacaoIdSugef(numeroEmissao: any, requerimentoTitulacaoId = null): Observable<EmissaoTitulo[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('numeroEmissao', numeroEmissao)
        if (requerimentoTitulacaoId) {
            httpParams = httpParams.append('requerimentoTitulacaoId', requerimentoTitulacaoId.toString())
        }
        const url = `${this.urlResource}/listar-titulos-por-numero-emissao-sugef`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(EmissaoTitulo.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    public recuperarHtmlSalvo(emissao: EmissaoTitulo): Observable<string> {
        const url = `${this.urlResource}/${emissao.id}/recuperar-html`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    public gerarHtmlTemplate(emissao: EmissaoTitulo): Observable<string> {
        const url = `${this.urlResource}/${emissao.id}/gerar-html`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    salvarHtmlDocumento(html: string, idEmissao: number): Observable<EmissaoTitulo> {
        const url = `${this.urlResource}/${idEmissao}/salvar-html-documento`;
        return this.http.post(url, html).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    salvarDadosEmissaoAtosInternos(emissaoTituloAtosDTO: any): Observable<EmissaoTitulo> {
        return this.http.post(
            this.urlResource + '/salvar-dados-emissao-titulo-atos-internos', emissaoTituloAtosDTO).pipe(
            map(EmissaoTitulo.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    buscaEmissaoTituloPorNumeroEmissao(numeroEmissao: string): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('numeroEmissao', numeroEmissao);
        const url = `${this.urlResource}/buscar-titulo/numero-emissao`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}