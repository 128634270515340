import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
    selector: 'app-hotel-pousada',
    templateUrl: './hotel-pousada.component.html',
    styleUrls: ['./hotel-pousada.component.scss']
})
export class HotelPousadaComponent implements OnInit {

    @Input() form: FormGroup;
    tiposComercio: DominioDto[];
    @Input() requerimento: Requerimento;
    @Input() isNaoPodeEditar: boolean;

    constructor(private dominiosService: DominiosService) {

    }

    async ngOnInit() {
        this.tiposComercio = await this.dominiosService.getDominioByUrl('tipos-comercio').toPromise();
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }
}
