import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {TitulacaoDocumentosDTO} from './titulacao-documentos-dto.model';

@Injectable({
    providedIn: 'root'
})
export class TitulacaoDocumentosService {
    protected http: HttpClient;
    urlResource = `${environment.URL_GATEWAY}/api/titulacoes-documentos`;

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    getByRequerimentoId(requerimentoId: number): Observable<TitulacaoDocumentosDTO[]> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(response => TitulacaoDocumentosDTO.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getByIdRequerimentoLegado(requerimentoId: number): Observable<TitulacaoDocumentosDTO[]> {
        const url = `${this.urlResource}/titulacoes-requerimento-legado/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(response => TitulacaoDocumentosDTO.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getByRequerimentoLegadoId(requerimentoId: number): Observable<TitulacaoDocumentosDTO[]> {
        const url = `${this.urlResource}/requerimento-legado/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(response => TitulacaoDocumentosDTO.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getVisualizarDocumentoPath(doc: TitulacaoDocumentosDTO): string {
        return `${this.urlResource}/${doc.grupoDocumento}/${doc.chave}/visualizar`;
    }

    getBaixarDocumentoPath(doc: TitulacaoDocumentosDTO): string {
        return `${this.urlResource}/${doc.grupoDocumento}/${doc.chave}/baixar`;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

    reabilitarCancelamentoTitulo(doc: TitulacaoDocumentosDTO): Observable<any> {
        const url = `${this.urlResource}/reabilitar-cancelamento/titulo/${doc.idEmissaoTitulo}`;
        return this.http.put(url, null).pipe(map(response => response),
            catchError(e => throwError(e))
        );
    }

}
