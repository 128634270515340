import {BaseModel} from 'app/main/shared/models/base.model';
import {Pessoa} from 'app/main/shared/models/pessoa.model';
import { DocumentoRTDto } from './documento-rt-dto.model';
import { Email } from './email.model';
import { EnderecoDto } from './endereco-dto.model';
import { Telefone } from './telefone.model';

export class ResponsavelTecnicoCompletoDto extends BaseModel {

    constructor(
        public id?: number,
        public cadastroTecFederal?: string,
        public registroConselho?: string,
        public conselho?: string,
        public conselhoId?: number,
        public ativo?: boolean,
        public pessoa?: Pessoa,

        public enderecos?: Array<EnderecoDto>,
        public telefones?: Array<Telefone>,
        public emails?: Array<Email>,
        public documentos?: Array<DocumentoRTDto>,
    ) {
        super();
    }

    static fromJson(json: any): ResponsavelTecnicoCompletoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new ResponsavelTecnicoCompletoDto(
            json.id,
            json.cadastroTecFederal,
            json.registroConselho,
            json.conselho,
            json.conselhoId,
            json.ativo,
            Pessoa.fromJson(json.pessoa),
            EnderecoDto.fromJsons(json.enderecos),
            Telefone.fromJsons(json.telefones),
            Email.fromJsons(json.emails),
            DocumentoRTDto.fromJsons(json.documentos)
        );
    }

    static fromJsons(json: any): ResponsavelTecnicoCompletoDto[] {
        const lista: ResponsavelTecnicoCompletoDto[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((rt) => {
                lista.push(this.fromJson(rt));
            });
            return lista;
        }
    }

}
