import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {UnidadeContingencia} from 'app/main/shared/models/unidade-contingencia.model';
import {Dominio} from "../../../../../../../../shared/models/dominio-model";
import {DominioService} from "../../../../../../../../shared/services/dominio.service";
import {TIPO_UNID_CONTINGENCIA_BIOMASSA} from "../ee-eolica";
import {FormGroup} from "@angular/forms";


@Component({
    selector: 'app-unidades-contingencia',
    templateUrl: './unidades-contingencia.component.html',
    styleUrls: ['./unidades-contingencia.component.scss']
})
export class UnidadesContingenciaComponent implements OnInit {

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

    @Input() unidadesContingenciaIncluidas: Array<{ tipoUnidadeContingencia: Dominio, tipoCombustivel: Dominio, subtipoCombustivel: Dominio, vinculo: UnidadeContingencia }>;

    @Input() tiposUnidadeContingencia: Array<Dominio> = [];
    @Input() tiposFossil: Array<Dominio> = [];
    @Input() tiposBiomassa: Array<Dominio> = [];
    @Input() unidadesContingenciaExcluidas: Array<number> = [];
    @Input() isNaoPodeEditar: boolean;

    @Input() form: FormGroup;

    tiposCombustivel: Dominio[] = [];
    subtiposCombustivel: Dominio[] = [];

    tipoUnidadeContingencia: Dominio;
    tipoCombustivel: Dominio;
    subtipoCombustivel: Dominio;
    unidadeContingencia: UnidadeContingencia = {};

    descricaoTipo: string;
    descricaoSubTipo: string;

    displayedColumns: string[] = ['identificacao', 'potenciaInstalada', 'tipoCombustivel', 'subtipo', 'descricao', 'acoes'];

    constructor(
        private dominioService: DominioService
    ) {
    }

    ngOnInit() {
        this.dataSource.data = this.unidadesContingenciaIncluidas;
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    excluirUnidadeContingencia(index: number) {
        if (index > -1) {
            let termo = this.unidadesContingenciaIncluidas[index];
            if (termo && termo.vinculo.id !== undefined) {
                this.unidadesContingenciaExcluidas.push(termo.vinculo.id);
            }
            this.unidadesContingenciaIncluidas.splice(index, 1);
            this.dataSource.data = this.unidadesContingenciaIncluidas;
        }
    }

    incluiUnidadeContingencia() {
        let vinculo = this.unidadeContingencia;

        vinculo.tipoUnidadeContingencia = this.tipoUnidadeContingencia.id;
        vinculo.tipoCombustivel = this.tipoCombustivel.id;
        vinculo.subTipoCombustivel = this.subtipoCombustivel && this.subtipoCombustivel.id;
        vinculo.descricaoTipoCombustivel = this.descricaoTipo;
        vinculo.descricaoSubTipoCombustivel = this.descricaoSubTipo;

        if (this.tipoCombustivel) {
            this.unidadesContingenciaIncluidas.push({
                tipoUnidadeContingencia: this.tipoUnidadeContingencia,
                tipoCombustivel: this.tipoCombustivel,
                subtipoCombustivel: this.subtipoCombustivel,
                vinculo
            });

            this.dataSource.data = this.unidadesContingenciaIncluidas;
        }

        this.tipoUnidadeContingencia = undefined;
        this.tipoCombustivel = undefined;
        this.subtipoCombustivel = undefined;
        this.unidadeContingencia = new UnidadeContingencia();
        this.descricaoTipo = '';
        this.descricaoSubTipo = '';
    }

    async recuperaSubtipos() {
        this.descricaoTipo = '';
        this.descricaoSubTipo = '';
        if (this.tipoCombustivel) {
            if (this.tipoUnidadeContingencia.id === TIPO_UNID_CONTINGENCIA_BIOMASSA) {
                this.subtiposCombustivel = await this.dominioService.buscarSubTipoBiomassa(this.tipoCombustivel.id).toPromise();
            } else {
                this.subtiposCombustivel = await this.dominioService.buscarSubTipoCombustivel(this.tipoCombustivel.id).toPromise();
            }
        }

    }

    recuperaTipos() {
        this.descricaoTipo = '';
        this.descricaoSubTipo = '';
        this.tiposCombustivel = this.tipoUnidadeContingencia.id === TIPO_UNID_CONTINGENCIA_BIOMASSA ? this.tiposBiomassa : this.tiposFossil;
    }

    disableIncluirUnidadeContingencia() {
        return !this.tipoUnidadeContingencia || !this.tipoCombustivel ||
            this.unidadeContingencia.identificacao === undefined ||
            this.unidadeContingencia.potenciaInstalada === undefined ||
            (this.tipoCombustivel.descricao === 'Outro' && !this.descricaoTipo) ||
            (this.tipoCombustivel.descricao !== 'Outro' && !this.subtipoCombustivel) ||
            (this.tipoCombustivel.descricao !== 'Outro' && this.subtipoCombustivel && this.subtipoCombustivel.descricao === 'Outro' && !this.descricaoSubTipo)
            ;
    }
}
