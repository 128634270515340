import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'app-emissao-titulo',
    templateUrl: './emissao-titulo.component.html',
})
export class EmissaoTituloComponent implements OnInit, AfterViewInit {

    @Output() callback = new EventEmitter();
    @ViewChild('conteudo', {static: false}) conteudo: ElementRef;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.callback.emit(this.conteudo.nativeElement.innerHTML);
    }


}
