import {Component, OnInit} from '@angular/core';
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {RequerimentoLicenciamentoService} from "../../../../requerimento-licenciamento.service";
import {MatTableDataSource} from "@angular/material/table";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoLicenciamento} from "../../../../requerimento-licenciamento.model";
import {FormControl, Validators} from "@angular/forms";
import {PessoaCorporativoService} from "../../../../../../../shared/services/pessoa-corporativo.service";
import {catchError, map, take} from "rxjs/operators";
import {Pessoa} from "../../../../../../../shared/models/pessoa.model";
import {ReflorestamentoSeminventarioService} from "./reflorestamento-seminventario.service";
import {Reflorestamento} from "../../../../../../../shared/models/reflorestamento.model";
import { DominiosService } from 'app/main/shared/services/dominios.service';
import { LancamentoCreditoReposicaoFlorestalService } from './lancamento-credito-reposicao-florestal.service';
import { LancamentoCreditoReposicaoFlorestal } from 'app/main/shared/models/lancamento-credito-reposicao-florestal.model';
import { DominioFinalidadePlantio, EnumTipoFinalidadePlantio } from '../../../licenciamento-florestal/dominios-model/dominio-finalidade-plantio.model';
import { DominioLimiteCreditoFlorestal } from '../../../licenciamento-florestal/dominios-model/dominio-limite-credito-florestal.model';
import { DominioConfigReflorestamento } from '../../../licenciamento-florestal/dominios-model/dominio-config-reflorestamento.model';
import { FormularioReflorestamentoSemInventario } from 'app/main/shared/models/formulario-reflorestamento-sem-inventario.model';
import { FormularioReflorestamentoSemInventarioDTO } from 'app/main/shared/models/formulario-reflorestamento-sem-inventario-dto.model';
import * as moment from 'moment';
import { Dominio } from 'app/main/shared/models/dominio-model';
import { DominioDto } from 'app/main/shared/models/dominio-dto.model';

@Component({
    selector: 'app-reflorestamento-seminventario',
    templateUrl: './reflorestamento-seminventario.component.html',
    styleUrls: ['./reflorestamento-seminventario.component.scss']
})
export class ReflorestamentoSeminventarioComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    isNaoPodeEditar: boolean = false;
    requerimentoLicenciamento: RequerimentoLicenciamento;
    cpfCnpjDetentorControl = new FormControl(null, [Validators.required]);
    creditoSolicitadoM3 = new FormControl(null, [Validators.required]);
    maskInputConsulta = '000.000.000-009';
    dataSourceAreasFeicao = new MatTableDataSource<any>([]);
    dataSourceAreasAFP = new MatTableDataSource<any>([]);
    dataSourceAreasTALHAO = new MatTableDataSource<any>([]);
    detentor: Pessoa;
    reflorestamento: FormularioReflorestamentoSemInventario;
    lancamentoCreditoReposicaoFlorestal: LancamentoCreditoReposicaoFlorestal;
    finalidadesPlantio: Array<DominioFinalidadePlantio> = [];
    limitesCreditoFlorestal: Array<DominioLimiteCreditoFlorestal> = [];
    configuracoesReflorestamento: Array<DominioConfigReflorestamento> = [];
    finalidadePlantio: DominioFinalidadePlantio;
    limiteCreditoFlorestal: DominioLimiteCreditoFlorestal;
    idObjetivoRequerimentoLicenciamento: any;
    //RN41
    areaTalhaoMaiorQue150: boolean;
    msgVolumeMaiorQueLimite: string = "A Área do talhão informada produziu de reflorestamento mais de 150m³/ha. Em caso de deferimento, o Levantamento Circunstanciado apresentado poderá requerer, durante o projeto, até dois ajustes como Aditivo de Crédito de Reposição Florestal – ADCRF.";
    msgVolumeMenorQueLimite: string = "A Área do talhão informada produziu de reflorestamento igual ou menor que 150m³/ha. O Levantamento Circunstanciado apresentado não poderá requerer um Aditivo de Crédito de Reposição Florestal – ACRF.";
    msgExibida: string;

    dataPlantio = new FormControl(null, [Validators.required]);
    volumeTotalM3: number = 0;
    totalVolumeM3PorHectare: number = 0;
    volumeTotalSt: number = 0;
    totalVolumeSTPorHectare: number = 0;
    areaTotalM3: any = 0;
    areaTotalST: number = 0;
    creditoDisponivel: number = 0;
    totalVolumeTalhao: number; //(m³/ha + (st/ha * 0,6667))
    volumeTotal: number; //(m³ + (total_st * 0,6667))
    creditoConcedido: number = 0;
    modelosFeicao: Dominio[] = [];
    modeloFeicaoTalhao: Dominio;
    modeloFeicaoAFP: Dominio;

    constructor(
        private snackBarService: SnackBarService,
        private pessoaCorporativoservice: PessoaCorporativoService,
        private reflorestamentoSeminventarioService: ReflorestamentoSeminventarioService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private lancamentoCreditoReposicaoFlorestalService: LancamentoCreditoReposicaoFlorestalService,
        private dominiosService: DominiosService,
    ) {
        super();
    }

    async ngOnInit() {
        await this.getModeloFeicao();
        await this.carregarQuadroDeAreas();
        await this.getListaFinalidadesPlantio();
        await this.getListaConfigReflorestamento();
        await this.getListaLimiteCreditoFlorestal();
        await this.carregarReflorestamento();
        this.consultarCreditoReposicaoFlorestal();

        /**
         * VOLUME_TOTAL_HA =>> totalVolumeTalhao = (this.totalVolumeSTPorHectare * 0.6667) + this.totalVolumeM3PorHectare
         */
        this.totalVolumeTalhao = (this.totalVolumeSTPorHectare * 0.6667) + this.totalVolumeM3PorHectare;

        this.volumeTotal = (this.volumeTotalSt * 0.6667) + this.volumeTotalM3;

        if (this.totalVolumeTalhao <= this.configuracoesReflorestamento[0].volumeMaximoCreditoFlorestal 
                && this.requerimentoLicenciamento.requerimento.tipoRequerimento.tipoAtuacao !== 'ANDAMENTO') {
            this.areaTalhaoMaiorQue150 = false;
            this.msgExibida = this.msgVolumeMenorQueLimite;
        } else {
            this.areaTalhaoMaiorQue150 = true;
            this.msgExibida = this.msgVolumeMaiorQueLimite;
        }

        this.cpfCnpjDetentorControl.valueChanges.subscribe(() => {
            this.maskInputConsulta = (this.cpfCnpjDetentorControl.value && this.cpfCnpjDetentorControl.value.length > 11)
                ? '00.000.000/0000-00' : '000.000.000-009';
        });

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
            this.cpfCnpjDetentorControl.disable();
            this.creditoSolicitadoM3.disable();
            this.dataPlantio.disable();
        }
    }

    pesquisarDetentor(): void {
        this.cpfCnpjDetentorControl.markAllAsTouched();
        if (this.cpfCnpjDetentorControl.valid) {
            this.pessoaCorporativoservice.getByCpfCnpj(this.cpfCnpjDetentorControl.value).pipe(
                take(1)
            ).subscribe(async pessoa => {
                this.detentor = pessoa;
            }, error => this.snackBarService.showError('Pessoa não localizada no cadastro de pessoas do SIGA. Verifique o CPF/CNPJ informado ou faça o seu cadastro no SIGA.', error));
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }
    
    consultarCreditoReposicaoFlorestal() {
        return this.lancamentoCreditoReposicaoFlorestalService.getLastByCpfDetentor(this.cpfCnpjDetentorControl.value).pipe(
            take(1)
        ).subscribe(async lancamentoCredito => {
            this.lancamentoCreditoReposicaoFlorestal = lancamentoCredito;
            this.creditoConcedido = this.lancamentoCreditoReposicaoFlorestal && this.lancamentoCreditoReposicaoFlorestal.creditoConcedidoEmM3PorHA 
                ? this.lancamentoCreditoReposicaoFlorestal.creditoConcedidoEmM3PorHA : 0.0000;
        }, error => this.snackBarService.showError('Erro ao consultar o lancamento do crédito de reposição florestal para o CPF: ' + this.cpfCnpjDetentorControl.value , error));
    }

    calcularCreditoDisponivel() {
        if (!this.cpfCnpjDetentorControl.valid) {
            this.snackBarService.showAlert('Selecione um detentor.');
            return;
        }
        if (this.dataPlantio == null) {
            this.snackBarService.showAlert('Selecione a data do plantio.');
            return;
        }
        if (this.finalidadePlantio == null) {
            this.snackBarService.showAlert('Informe a finalidade.');
            return;
        }
        if (this.lancamentoCreditoReposicaoFlorestal && this.lancamentoCreditoReposicaoFlorestal.totalCreditado == null && this.totalVolumeM3PorHectare > 150) {
            this.creditoDisponivel = 150;
        } else {
            this.calculaCreditoDisponivelRegraVolumeMenor150(this.dataPlantio.value);
        }
    }


    async calculaCreditoDisponivelRegraVolumeMenor150(dataPlantio: any) {
        let tempoPlantioEmMeses = this.retornaDiferenceEntreDatasEmMeses(dataPlantio, this.formatDateYYYYMMDD(new Date()));
        
        await this.getLimiteCreditoFlorestal(this.idObjetivoRequerimentoLicenciamento, this.finalidadePlantio.id, tempoPlantioEmMeses)
            .toPromise();

        //RN160
        if (this.limiteCreditoFlorestal && tempoPlantioEmMeses < this.limiteCreditoFlorestal.limiteInferior) {
            this.creditoDisponivel = 0;
        //RN39 O crédito disponível deve considerar o limite legal deduzindo o volume já concedido no imóvel em outros requerimentos.           
        } else if (this.limiteCreditoFlorestal && tempoPlantioEmMeses >= this.limiteCreditoFlorestal.limiteInferior && tempoPlantioEmMeses < this.limiteCreditoFlorestal.limitePosterior) {
            this.creditoDisponivel = ((this.totalVolumeSTPorHectare * 0.6667) + this.totalVolumeM3PorHectare) * this.limiteCreditoFlorestal.percentualMaximo; //0.5
            this.creditoDisponivel = this.formatNumberRoundUp(this.creditoDisponivel, 4);
        //RN40    
        } else if (this.limiteCreditoFlorestal && tempoPlantioEmMeses >= this.limiteCreditoFlorestal.limiteInferior && tempoPlantioEmMeses < this.limiteCreditoFlorestal.limitePosterior && this.finalidadePlantio.tipo === EnumTipoFinalidadePlantio.BIOMASSA_ENERGETICA) {
            this.creditoDisponivel = ((this.totalVolumeSTPorHectare * 0.6667) + this.totalVolumeM3PorHectare) * this.limiteCreditoFlorestal.percentualMaximo; //0.75
            this.creditoDisponivel = this.formatNumberRoundUp(this.creditoDisponivel, 4);    
        //RN161            
        } else if (this.limiteCreditoFlorestal && tempoPlantioEmMeses > this.limiteCreditoFlorestal.limiteInferior && this.finalidadePlantio.tipo === EnumTipoFinalidadePlantio.BIOMASSA_ENERGETICA) {
            this.creditoDisponivel = ((this.totalVolumeSTPorHectare * 0.6667) + this.totalVolumeM3PorHectare) * this.limiteCreditoFlorestal.percentualMaximo; //1
            this.creditoDisponivel = this.formatNumberRoundUp(this.creditoDisponivel, 4);
        //RN162
        } else if (this.limiteCreditoFlorestal && tempoPlantioEmMeses >= this.limiteCreditoFlorestal.limiteInferior && tempoPlantioEmMeses < this.limiteCreditoFlorestal.limitePosterior && this.finalidadePlantio.tipo === EnumTipoFinalidadePlantio.INDUSTRIA_MADEIREIRA) {
            this.creditoDisponivel = ((this.totalVolumeSTPorHectare * 0.6667) + this.totalVolumeM3PorHectare) * this.limiteCreditoFlorestal.percentualMaximo; //0.75
            this.creditoDisponivel = this.formatNumberRoundUp(this.creditoDisponivel, 4);
        ///RN163
        } else if (this.limiteCreditoFlorestal && tempoPlantioEmMeses >= this.limiteCreditoFlorestal.limiteInferior && this.finalidadePlantio.tipo === EnumTipoFinalidadePlantio.INDUSTRIA_MADEIREIRA) {
            this.creditoDisponivel = ((this.totalVolumeSTPorHectare * 0.6667) + this.totalVolumeM3PorHectare) * this.limiteCreditoFlorestal.percentualMaximo;
            this.creditoDisponivel = this.formatNumberRoundUp(this.creditoDisponivel, 4);
        }

    }

    getLimiteCreditoFlorestal(idObjetivo: number, idFinalidade: number, tempoEmMesesDesdeOPlantio: number) {
        return this.dominiosService
            .getDominioLimiteCreditoFlorestalByParametros("LIMITE_CREDITO_FLORESTAL", idObjetivo, idFinalidade, tempoEmMesesDesdeOPlantio)
            .pipe(map(limiteCredito => {
                this.limiteCreditoFlorestal = limiteCredito;
            }), catchError((e) => {
                this.snackBarService.showError('Erro ao consultar o crédito de reposição florestal. Não foi encontrada nenhuma configuração de limite legal para o crédito disponível. Contate um administrador do sistema.', e);
                return null;
            }))
    }

    getModeloFeicao() {
        this.dominiosService
        .getDominio("MODELO_FEICAO")
        .subscribe((response) => {
            this.modelosFeicao = response;
        });
    }
    
    async carregarQuadroDeAreas(): Promise<void> {
        this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
        let result = await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();
        if (result.length > 0) {
            this.modeloFeicaoTalhao = await this.modelosFeicao.filter(mf => mf.descricao === 'TALHAO')[0];
            this.modeloFeicaoAFP = await this.modelosFeicao.filter(mf => mf.descricao === 'AFP')[0];
            if (this.modeloFeicaoTalhao == null && this.modeloFeicaoAFP == null) {
                this.snackBarService.showError('Erro ao consultar modelos de feição.');
                return;
            }

            //TODO - Rever esta situçaõ pois não temos cenário para mais de um objetivo, aguardando analista
            this.idObjetivoRequerimentoLicenciamento = result.filter(feicao => feicao.feicaoDTO.idModeloFeicao === this.modeloFeicaoTalhao.id)[0].objetivoLicenciamento;
            
            this.dataSourceAreasFeicao = new MatTableDataSource(result);
            this.dataSourceAreasAFP = new MatTableDataSource(result.filter(feicao => feicao.feicaoDTO.idModeloFeicao === this.modeloFeicaoAFP.id));
            this.dataSourceAreasTALHAO = new MatTableDataSource(result.filter(feicao => feicao.feicaoDTO.idModeloFeicao === this.modeloFeicaoTalhao.id));

            result.filter(feicao => feicao.feicaoDTO.idModeloFeicao === this.modeloFeicaoTalhao.id).forEach(f => {
                if (f.modeloFeicaoDTO && f.modeloFeicaoDTO.modeloFeicaoTalhao && f.modeloFeicaoDTO.modeloFeicaoTalhao.unidadeMedida == 'M3') {
                    this.volumeTotalM3 = this.volumeTotalM3 + f.modeloFeicaoDTO.modeloFeicaoTalhao.volume;
                    this.areaTotalM3 = this.areaTotalM3 + f.area;
                }

                if (f.modeloFeicaoDTO && f.modeloFeicaoDTO.modeloFeicaoTalhao && f.modeloFeicaoDTO.modeloFeicaoTalhao.unidadeMedida == 'ESTEREO') {
                    this.volumeTotalSt = this.volumeTotalSt + f.modeloFeicaoDTO.modeloFeicaoTalhao.volume;
                    this.areaTotalST = this.areaTotalST + f.area;
                }
               
            });

            if (this.volumeTotalM3 > 0) {
                this.volumeTotalM3 = this.formatNumberRoundUp(this.volumeTotalM3, 4);
            }

            if (this.volumeTotalSt > 0) {
                this.volumeTotalSt = this.formatNumberRoundUp(this.volumeTotalSt, 4);
            }

            //RN166
            if (this.areaTotalM3 > 0) {
                this.totalVolumeM3PorHectare = this.volumeTotalM3 / this.formatNumberRoundUp(this.areaTotalM3, 4);
                this.totalVolumeM3PorHectare = this.formatNumberRoundUp(this.totalVolumeM3PorHectare, 4);
            }

            //RN166
            if (this.areaTotalST > 0) {
                this.totalVolumeSTPorHectare = this.volumeTotalSt / this.formatNumberRoundUp(this.areaTotalST, 4);
                this.totalVolumeSTPorHectare = this.formatNumberRoundUp(this.totalVolumeSTPorHectare, 4);
            }

        }
    }

    salvar(): void {

        if(this.creditoDisponivel < 0) {
            this.snackBarService.showAlert('Crédito disponível não calculado.');
            return;
        }

        if (!this.creditoSolicitadoM3.valid) {
            this.snackBarService.showAlert('Informe o crédito solicitado.');
            return;
        }

        if (this.creditoSolicitadoM3.value > this.creditoDisponivel) {
            this.snackBarService.showAlert('O crédito solicitado maior do que o saldo disponível.');
            return;
        }
        if (!this.cpfCnpjDetentorControl.valid) {
            this.snackBarService.showAlert('Selecione um detentor.');
            return;
        }
        if (!this.dataPlantio.valid) {
            this.snackBarService.showAlert('Selecione a data do plantio.');
            return;
        }
        if (this.finalidadePlantio == null) {
            this.snackBarService.showAlert('Informe a finalidade.');
            return;
        }
        this.calcularCreditoDisponivel();

        this.reflorestamentoSeminventarioService.postFormularioReflorestamentoSemInventario(this.montaObjetoFormularioReflorestamentoSemInventarioDTO())
            .subscribe(() => {
            this.snackBarService.showSuccess("Formulário salvo com sucesso.");
            this.carregarReflorestamento();
            this.enviarFormConcluido.emit();
        }, () => this.snackBarService.showError("Ocorreu um erro no servidor."));
    }

    montaObjetoFormularioReflorestamentoSemInventarioDTO(): FormularioReflorestamentoSemInventarioDTO {
        const reflorestamentoSemInventarioDTO = new FormularioReflorestamentoSemInventarioDTO();
        reflorestamentoSemInventarioDTO.id = this.reflorestamento == null ? null : this.reflorestamento.id;
        reflorestamentoSemInventarioDTO.dataPlantio = new Date(this.dataPlantio.value);
        reflorestamentoSemInventarioDTO.cpfDetentor = this.cpfCnpjDetentorControl.value;
        reflorestamentoSemInventarioDTO.creditoSolicitadoM3 = this.creditoSolicitadoM3.value;
        reflorestamentoSemInventarioDTO.idRequerimento = this.requerimento.id;
        reflorestamentoSemInventarioDTO.idLancamentoCreditoReposicaoFlorestal = this.lancamentoCreditoReposicaoFlorestal == null ?  null : this.lancamentoCreditoReposicaoFlorestal.id;
        reflorestamentoSemInventarioDTO.idPessoa = this.detentor.id;
        reflorestamentoSemInventarioDTO.idFinalidadePlantio = this.finalidadePlantio.id;
        reflorestamentoSemInventarioDTO.lancamentoCreditoCpfDetentor = this.cpfCnpjDetentorControl.value;
        reflorestamentoSemInventarioDTO.lancamentoCreditoVolumeTotal = this.volumeTotal;
        reflorestamentoSemInventarioDTO.lancamentoCreditoVolumeTotalPorHectare = this.totalVolumeTalhao;
        reflorestamentoSemInventarioDTO.lancamentoCreditoCreditoSolicitado = this.creditoSolicitadoM3.value;
        //reflorestamentoSemInventarioDTO.lancamentoCreditoTotalCreditado = this.lancamentoCreditoReposicaoFlorestal == null ?  null : this.lancamentoCreditoReposicaoFlorestal.id;
        //reflorestamentoSemInventarioDTO.lancamentoCreditoTotalAditivado = null;
        reflorestamentoSemInventarioDTO.lancamentoCreditoCreditoDisponivelEmM3PorHA = this.creditoDisponivel;
        //reflorestamentoSemInventarioDTO.lancamentoCreditoCreditoConcedidoEmM3PorHA = null;
        reflorestamentoSemInventarioDTO.lancamentoCreditoSituacaoCredito = 'CADASTRADO';

        return reflorestamentoSemInventarioDTO;
    }

    private async getListaFinalidadesPlantio() {
        this.finalidadesPlantio = await this.dominiosService
            .getDominioFinalidadePlantio("FINALIDADE_PLANTIO")
            .toPromise();
    }

    private async getListaConfigReflorestamento() {
        this.configuracoesReflorestamento = await this.dominiosService
            .getDominioConfigReflorestamento("CONFIG_REFLORESTAMENTO")
            .toPromise();
    }

    private async getListaLimiteCreditoFlorestal() {
        this.limitesCreditoFlorestal = await this.dominiosService
            .getDominioLimiteCreditoFlorestal("LIMITE_CREDITO_FLORESTAL")
            .toPromise();
    }

    async carregarReflorestamento(): Promise<void> {
        this.reflorestamento = await this.reflorestamentoSeminventarioService.buscarPorIdRequerimento(this.requerimento.id).toPromise().catch(() => new Reflorestamento());
        if (this.reflorestamento && this.reflorestamento.id) {
            this.cpfCnpjDetentorControl.patchValue(this.reflorestamento.cpfDetentor);
            this.creditoSolicitadoM3.patchValue(this.reflorestamento.creditoSolicitadoM3);
            this.dataPlantio.patchValue(moment(this.reflorestamento.dataPlantio, moment.ISO_8601).toDate());

            this.finalidadePlantio = await this.dominiosService
                .getDominioFinalidadePlantioById("FINALIDADE_PLANTIO", this.reflorestamento.idFinalidadePlantio)
                .toPromise();
            this.pesquisarDetentor();

            this.calculaCreditoDisponivelRegraVolumeMenor150(this.reflorestamento.dataPlantio);
        }
    }

    atualizaCreditoDisponivel() {
        this.creditoDisponivel = 0.000;
        this.dataPlantio.patchValue('');
    }

    formatNumberRoundUp(number: number, decimalPlaces: number, roundDown = false): number {
        const factor = Math.pow(10, decimalPlaces);
        const rounded = roundDown ? Math.floor(number * factor) / factor : Math.ceil(number * factor) / factor;
        return rounded;
    }

    retornaDiferenceEntreDatasEmMeses(startDate: any, endDate: any) {
        //caso seja necessário pagar tambem a diferença em dias por conta do arredondamento para o cálculo em meses
        //fazer diff de dias e verificar formas de arredondamento
        return moment(endDate, moment.ISO_8601).diff(moment(startDate, moment.ISO_8601),'months');
        //return diffMonths;
    }

    formatDateYYYYMMDD(date: Date): string {
        const isoString = date.toISOString();
        const formattedDate = isoString.slice(0, 10); // Extract YYYY-MM-DD part
        return formattedDate;
    }

}