import {Ponto} from '../../../../shared/models/hidrico-models/ponto.model';
import {
    Component,
    ComponentFactoryResolver,
    Injector,
    OnInit,
    QueryList,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {RequerimentoOutorgaFinalidadeService} from 'app/main/shared/services/requerimento-outorga-finalidade.service';
import {RequerimentoOutorgaFinalidade} from 'app/main/shared/models/requerimento-outorga-finalidade.model';
import {RequerimentoOutorgaService} from '../requerimento-outorga.service';
import {forkJoin, Observable} from 'rxjs';
import {PontoFormulario} from './ponto-formulario';
import {RequerimentoOutorgaFinalidadeFormulario} from './requerimento-outorga-finalidade-formulario';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {ErrosAbaRequerimento} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {PontoService} from "../../../../shared/services/ponto.service";
import {Finalidade} from "../../../finalidades/finalidade.model";

@Component({
    selector: 'app-requerimento-atividade-outorga',
    templateUrl: 'requerimento-atividade-outorga.component.html',
    animations: fuseAnimations
})
export class RequerimentoAtividadeOutorgaComponent extends BaseTabRequerimentoComponent
    implements OnInit {
    requerimentoOutorgaFinalidades: RequerimentoOutorgaFinalidade[] = [];
    pontos: Ponto[] = [];
    finalidades: Finalidade[] = [];

    @ViewChildren('finalidadeContainer', {read: ViewContainerRef}) finalidadeContainers: QueryList<ViewContainerRef>;
    @ViewChildren('pontoContainer', {read: ViewContainerRef}) pontoContainers: QueryList<ViewContainerRef>;

    constructor(
        injector: Injector,
        private pontoService: PontoService,
        private requerimentoOutorgaService: RequerimentoOutorgaService,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private snackBarService: SnackBarService,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
        super(injector);
        RequerimentoSteps.ATIVIDADE.component = this;
    }

    ngOnInit(): void {
        this.helper
            .onCurrentStepChange()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(e => {
                if (e.newTabIndex === RequerimentoSteps.ATIVIDADE.index) {
                    forkJoin([this.carregarFinalidades(), this.carregarPontos()]).toPromise();
                }
            });
    }

    loadFinalidadeContainer(requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade, index: number): void {
        const finalidadeContainer = this.finalidadeContainers.find((e, i, a) => i === index);
        finalidadeContainer.clear();
        const type = RequerimentoOutorgaFinalidadeFormulario.get(requerimentoOutorgaFinalidade).resolved;
        const factory = this.componentFactoryResolver.resolveComponentFactory(type);
        const ref = finalidadeContainer.createComponent(factory);
        ref.instance.requerimentoOutorgaFinalidade = requerimentoOutorgaFinalidade;
        ref.instance.isModoVisualizacao = this.isSomenteVisualizacao;
        ref.instance.pontos = this.pontos;
    }

    loadPontoContainer(ponto: Ponto, index: number): void {
        const pontoContainer = this.pontoContainers.find((e, i, a) => i === index);
        pontoContainer.clear();
        const type = PontoFormulario.get(ponto).resolved;
        const factory = this.componentFactoryResolver.resolveComponentFactory(type);
        const ref = pontoContainer.createComponent(factory);
        ref.instance.ponto = ponto;
        ref.instance.isModoVisualizacao = this.isSomenteVisualizacao;
        ref.instance.finalidades = this.finalidades
    }

    private carregarFinalidades(): Observable<RequerimentoOutorgaFinalidade[]> {
        return this.requerimentoOutorgaFinalidadeService
            .getByRequerimentoOutorgaId(this.requerimentoOutorga.id)
            .pipe(map(requerimentoOutorgaFinalidades => {
                    requerimentoOutorgaFinalidades = requerimentoOutorgaFinalidades.filter(requerimentoOutorgaFinalidade =>
                        RequerimentoOutorgaFinalidadeFormulario.get(requerimentoOutorgaFinalidade));
                    this.requerimentoOutorgaFinalidades = requerimentoOutorgaFinalidades;

                    this.requerimentoOutorgaFinalidades.forEach(finalidade => {
                        if (!this.finalidades.some(fin => fin.id == finalidade.finalidade.id)) {
                            this.finalidades.push(finalidade.finalidade);
                        }
                    });
                    return requerimentoOutorgaFinalidades;
                }),
                catchError(e => {
                    this.snackBarService.showError('Erro ao carregar as finalidades do requerimento.', e);
                    throw e;
                }));
    }

    private carregarPontos(): Observable<Ponto[]> {
        return this.pontoService.getPontos(this.requerimentoOutorga)
            .pipe(map(pontos => {
                    pontos = pontos.filter(ponto => PontoFormulario.get(ponto));
                    pontos = pontos.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0);
                    this.pontos = pontos;
                    return pontos;
                }),
                catchError(e => {
                    this.snackBarService.showError('Erro ao listar os pontos do requerimento', e);
                    throw e;
                }));
    }

    public validarFormObjetivoCaptacao(ponto: Ponto): boolean {
        if (ponto.objetivoRequerimento) {
            return ponto.objetivoRequerimento.objetivo.formulario === 'CAPTACAO_SUPERFICIAL' && !ponto.titulacao.formulario;
        }
    }

    public validarFormTitulacaoCaptacao(ponto: Ponto): boolean {
        if (ponto.objetivoRequerimento) {
            return ponto.objetivoRequerimento.objetivo.formulario === 'CAPTACAO_SUPERFICIAL' && !!ponto.titulacao.formulario;
        }
    }

    async validarAba(erros: ErrosAbaRequerimento): Promise<void> {
        await forkJoin([this.carregarFinalidades(), this.carregarPontos()]).toPromise();
        if (this.requerimentoOutorgaFinalidades) {
            this.requerimentoOutorgaFinalidades.forEach(requerimentoOutorgaFinalidade => {
                if (!requerimentoOutorgaFinalidade.cadastroConcluido) {
                    let msg = `O formulário da Finalidade: ${requerimentoOutorgaFinalidade.finalidade.descricao}`;
                    msg += ', possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).';
                    erros.push(msg, false);
                }
            });
        }
        if (this.pontos) {
            this.pontos.forEach((ponto, index) => {
                if (!ponto.formularioConcluido) {
                    let msg = `O formulário do Ponto: ${index + 1}`;
                    msg += ', possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).';
                    erros.push(msg, false);
                }
            });
        }
        return Promise.resolve();
    }

    get possuiAtividades(): boolean {
        return this.requerimentoOutorgaFinalidades && this.requerimentoOutorgaFinalidades.length > 0;
    }

}
