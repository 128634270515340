import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';

@Component({
    selector: 'app-add-new-button',
    templateUrl: './add-new-button.component.html',
    styleUrls: ['./add-new-button.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AddNewButtonComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
