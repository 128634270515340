import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material";
import {
    ResiduosGeraisResiduosSolidos
} from "../../../../../../../../shared/models/residuos-gerais-residuos-solidos.model";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {ResiduoGeralService} from "../residuo-geral.service";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
    selector: 'app-residuos-solidos-processados-manipulados',
    templateUrl: './residuos-solidos-processados-manipulados.component.html',
    styleUrls: ['./residuos-solidos-processados-manipulados.component.scss']
})
export class ResiduosSolidosProcessadosManipuladosComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly: boolean = false;
    @Output() enviarResiduosSolidos = new EventEmitter<ResiduosGeraisResiduosSolidos[]>();
    @Input() mostrarObrigatorioResiduosSolidos;
    @Input() isNaoPodeEditar: boolean;

    formResiduosSolidos: FormGroup;
    residuosSolidos: any[] = [];
    dataSourceResiduosSolidos = new MatTableDataSource<any>([]);
    ocorreProcessamento: boolean = false;
    mostrarDensidadeRecebimento: boolean = false;
    mostrarDensidadeResiduoBruto: boolean = false;
    mostrarDensidadeProcessamento: boolean = false;
    mostrarDensidadeArmazenProcessado: boolean = false;
    modoEdicao: boolean = false;
    idTabela: number = 0;
    tipoClassificacao: any[] = [];
    tipoEstadoFisico: any[] = [];
    tipoUnidadeMedida: any[] = [];
    mostrarInfoTanques: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private residuoGeralService: ResiduoGeralService,
                private dominioService: DominiosService) {

    }

    async ngOnInit() {
        this.buildFormResiduoSolidoProcessadoManipulado();

        if (this.mostrarObrigatorioResiduosSolidos) {
            this.formResiduosSolidos.markAsDirty();
            this.formResiduosSolidos.markAllAsTouched();
        }

        //Buscando unidade de medida filtrada
        this.tipoUnidadeMedida = await this.residuoGeralService.buscarDominiosFiltrados().toPromise();

        //Buscando tipo classificacao
        this.dominioService.getDominio('TIPO_CLASS_RESIDUOS').subscribe(retorno => {
            this.tipoClassificacao = retorno;
        })

        //Buscando estado fisico
        this.dominioService.getDominio('TIPO_ESTADO_FISICO').subscribe(retorno => {
            this.tipoEstadoFisico = retorno;
        })

        if (this.idForm) {
            this.residuoGeralService.buscarResiduosSolidosPorId(this.idForm).subscribe(response => {
                response.forEach(residuos => {
                    this.residuosSolidos.push({
                        id: residuos.id,
                        idTemp: this.idTabela,
                        nomeResiduo: residuos.nomeResiduo,
                        estadoFisico: residuos.estadoFisico,
                        classificacao: residuos.classificacao,
                        possuiProcessamentoResiduos: residuos.possuiProcessamentoResiduos == true ? "sim" : "nao",
                        possuiReaproveitamento: residuos.possuiReaproveitamento == true ? "sim" : "nao",
                        recebimentoQuantidade: residuos.recebimentoQuantidade,
                        recebimentoUnidade: residuos.recebimentoUnidade,
                        recebimentoDensidade: residuos.recebimentoDensidade,
                        armazenamentoQuantidade: residuos.armazenamentoQuantidade,
                        armazenamentoUnidade: residuos.armazenamentoUnidade,
                        armazenamentoDensidade: residuos.armazenamentoDensidade,
                        processamentoQuantidade: residuos.processamentoQuantidade,
                        processamentoUnidade: residuos.processamentoUnidade,
                        processamentoDensidade: residuos.processamentoDensidade,
                        armazenamentoResiduoProcessadoQuantidade: residuos.armazenamentoResiduoProcessadoQuantidade,
                        armazenamentoResiduoProcessadoUnidade: residuos.armazenamentoResiduoProcessadoUnidade,
                        armazenamentoResiduoProcessadoDensidade: residuos.armazenamentoResiduoProcessadoDensidade,
                        recebimentoPossuiAreaTancagem: residuos.recebimentoPossuiAreaTancagem == true ? "sim" : "nao",
                        recebimentoNumeroTanques: residuos.recebimentoNumeroTanques,
                        recebimentoUnidadeAreaTancagem: residuos.recebimentoNumeroTanques,
                    });
                    this.idTabela++;
                })
                this.dataSourceResiduosSolidos = new MatTableDataSource(this.residuosSolidos);
                this.enviarResiduos(this.mapearArray());
            })
        }
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.formResiduosSolidos.disable();
        }
    }

    private buildFormResiduoSolidoProcessadoManipulado(): void {
        this.formResiduosSolidos = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            empreendimentoResiduosGerais: [null],
            nomeResiduo: ["", [Validators.required]],
            estadoFisico: ["", [Validators.required]],
            classificacao: ["", [Validators.required]],
            possuiProcessamentoResiduos: ["", [Validators.required]],
            possuiReaproveitamento: ["", [Validators.required]],
            recebimentoQuantidade: ["", [Validators.required]],
            recebimentoUnidade: ["", [Validators.required]],
            recebimentoDensidade: [""],
            recebimentoPossuiAreaTancagem: ["", [Validators.required]],
            recebimentoNumeroTanques: [null],
            recebimentoUnidadeAreaTancagem: [null],
            armazenamentoQuantidade: ["", [Validators.required]],
            armazenamentoUnidade: ["", [Validators.required]],
            armazenamentoDensidade: [""],
            processamentoQuantidade: [""],
            processamentoUnidade: [""],
            processamentoDensidade: [""],
            armazenamentoResiduoProcessadoQuantidade: [""],
            armazenamentoResiduoProcessadoUnidade: [""],
            armazenamentoResiduoProcessadoDensidade: [""],
        })
    }

    adicionaResiduoSolido() {
        const validacao = this.validarFormulario();
        if (validacao) {
            this.residuosSolidos.push({
                id: this.formResiduosSolidos.controls.id.value,
                idTemp: this.retornarIdTabela(),
                nomeResiduo: this.formResiduosSolidos.controls.nomeResiduo.value,
                estadoFisico: this.formResiduosSolidos.controls.estadoFisico.value,
                classificacao: this.formResiduosSolidos.controls.classificacao.value,
                possuiProcessamentoResiduos: this.formResiduosSolidos.controls.possuiProcessamentoResiduos.value,
                possuiReaproveitamento: this.formResiduosSolidos.controls.possuiReaproveitamento.value,
                recebimentoQuantidade: this.formResiduosSolidos.controls.recebimentoQuantidade.value,
                recebimentoUnidade: this.formResiduosSolidos.controls.recebimentoUnidade.value,
                recebimentoDensidade: this.formResiduosSolidos.controls.recebimentoDensidade.value,
                armazenamentoQuantidade: this.formResiduosSolidos.controls.armazenamentoQuantidade.value,
                armazenamentoUnidade: this.formResiduosSolidos.controls.armazenamentoUnidade.value,
                armazenamentoDensidade: this.formResiduosSolidos.controls.armazenamentoDensidade.value,
                processamentoQuantidade: this.formResiduosSolidos.controls.processamentoQuantidade.value,
                processamentoUnidade: this.formResiduosSolidos.controls.processamentoUnidade.value,
                processamentoDensidade: this.formResiduosSolidos.controls.processamentoDensidade.value,
                armazenamentoResiduoProcessadoQuantidade: this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoQuantidade.value,
                armazenamentoResiduoProcessadoUnidade: this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoUnidade.value,
                armazenamentoResiduoProcessadoDensidade: this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoDensidade.value,
                recebimentoPossuiAreaTancagem: this.formResiduosSolidos.controls.recebimentoPossuiAreaTancagem.value,
                recebimentoNumeroTanques: this.formResiduosSolidos.controls.recebimentoNumeroTanques.value,
                recebimentoUnidadeAreaTancagem: this.formResiduosSolidos.controls.recebimentoUnidadeAreaTancagem.value,
            });
            this.dataSourceResiduosSolidos = new MatTableDataSource(this.residuosSolidos);
            this.enviarResiduos(this.mapearArray());
            this.ocorreProcessamento = false;
            this.mostrarDensidadeRecebimento = false;
            this.mostrarDensidadeResiduoBruto = false;
            this.mostrarInfoTanques = false;
            this.formResiduosSolidos.reset();
        }
    }

    mapearArray(): any {
        if (this.residuosSolidos.length == 0) {
            return this.residuosSolidos;
        } else {
            return this.residuosSolidos.map(function (item) {
                return new ResiduosGeraisResiduosSolidos(
                    item.id,
                    null,
                    item.nomeResiduo,
                    item.estadoFisico,
                    item.classificacao,
                    item.possuiProcessamentoResiduos == "sim",
                    item.possuiReaproveitamento == "sim",
                    Number(item.recebimentoQuantidade),
                    item.recebimentoUnidade,
                    Number(item.recebimentoDensidade),
                    Number(item.armazenamentoQuantidade),
                    item.armazenamentoUnidade,
                    Number(item.armazenamentoDensidade),
                    Number(item.processamentoQuantidade),
                    item.processamentoUnidade,
                    Number(item.processamentoDensidade),
                    Number(item.armazenamentoResiduoProcessadoQuantidade),
                    item.armazenamentoResiduoProcessadoUnidade,
                    Number(item.armazenamentoResiduoProcessadoDensidade),
                    item.recebimentoPossuiAreaTancagem == "sim",
                    Number(item.recebimentoNumeroTanques),
                    Number(item.recebimentoUnidadeAreaTancagem),
                );
            });
        }
    }

    retornarIdTabela(): number {
        this.idTabela++;
        return this.idTabela;
    }

    enviarResiduos(residuos: ResiduosGeraisResiduosSolidos[]) {
        this.enviarResiduosSolidos.emit(residuos);
    }

    deletarItem(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.residuoGeralService.deletarResiduoSolidoPorId(itemId).subscribe(response => {
                this.snackBar.showSuccess("Residuo deletado excluido com sucesso.")
            });
        }
        let data;
        data = this.residuosSolidos.filter((value, key) => {
            return value.idTemp != item;
        });
        this.residuosSolidos = data;
        this.dataSourceResiduosSolidos = data;
        this.enviarResiduos(this.mapearArray());
    }

    changeTancagem(tancagem) {
        if (tancagem == "sim") {
            this.mostrarInfoTanques = true;
        } else {
            this.mostrarInfoTanques = false;
            this.formResiduosSolidos.controls.recebimentoNumeroTanques.patchValue(null);
            this.formResiduosSolidos.controls.recebimentoUnidadeAreaTancagem.patchValue(null);
        }
    }

    recebimentoUnidade(unidadeSelecionada) {
        if (unidadeSelecionada == "m³" || unidadeSelecionada == "Litro") {
            this.mostrarDensidadeRecebimento = true;
        } else {
            this.formResiduosSolidos.controls.recebimentoDensidade.reset();
            this.mostrarDensidadeRecebimento = false;
        }
    }

    armazenamentoUnidade(unidadeSelecionada) {
        if (unidadeSelecionada == "m³" || unidadeSelecionada == "Litro") {
            this.mostrarDensidadeResiduoBruto = true;
        } else {
            this.formResiduosSolidos.controls.armazenamentoDensidade.reset();
            this.mostrarDensidadeResiduoBruto = false;
        }
    }

    processamentoUnidade(unidadeSelecionada) {
        if (unidadeSelecionada == "m³" || unidadeSelecionada == "Litro") {
            this.mostrarDensidadeProcessamento = true;
        } else {
            this.formResiduosSolidos.controls.processamentoDensidade.reset();
            this.mostrarDensidadeProcessamento = false;
        }
    }

    armazenamentoProcessadoUnidade(unidadeSelecionada) {
        if (unidadeSelecionada == "m³" || unidadeSelecionada == "Litro") {
            this.mostrarDensidadeArmazenProcessado = true;
        } else {
            this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoDensidade.reset();
            this.mostrarDensidadeArmazenProcessado = false;
        }
    }

    changeProcessamento(processamento) {
        if (processamento == "sim") {
            this.ocorreProcessamento = true;
        } else {
            this.formResiduosSolidos.controls.processamentoQuantidade.reset();
            this.formResiduosSolidos.controls.processamentoUnidade.reset();
            this.formResiduosSolidos.controls.processamentoDensidade.reset();
            this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoQuantidade.reset();
            this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoUnidade.reset();
            this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoDensidade.reset();
            this.ocorreProcessamento = false;
        }
    }

    ativarModoEdicaoResiduo(residuo) {
        this.formResiduosSolidos.controls.id.patchValue(residuo.id);
        this.formResiduosSolidos.controls.idTemp.patchValue(residuo.idTemp);
        this.formResiduosSolidos.controls.nomeResiduo.patchValue(residuo.nomeResiduo);
        this.formResiduosSolidos.controls.estadoFisico.patchValue(residuo.estadoFisico);
        this.formResiduosSolidos.controls.classificacao.patchValue(residuo.classificacao);
        this.formResiduosSolidos.controls.possuiProcessamentoResiduos.patchValue(residuo.possuiProcessamentoResiduos);
        this.changeProcessamento(residuo.possuiProcessamentoResiduos);
        this.formResiduosSolidos.controls.possuiReaproveitamento.patchValue(residuo.possuiReaproveitamento);
        this.formResiduosSolidos.controls.recebimentoQuantidade.patchValue(residuo.recebimentoQuantidade);
        this.formResiduosSolidos.controls.recebimentoUnidade.patchValue(residuo.recebimentoUnidade);
        this.recebimentoUnidade(residuo.recebimentoUnidade);
        this.formResiduosSolidos.controls.recebimentoDensidade.patchValue(residuo.recebimentoDensidade);
        this.formResiduosSolidos.controls.armazenamentoQuantidade.patchValue(residuo.armazenamentoQuantidade);
        this.formResiduosSolidos.controls.armazenamentoUnidade.patchValue(residuo.armazenamentoUnidade);
        this.armazenamentoUnidade(residuo.armazenamentoUnidade);
        this.formResiduosSolidos.controls.armazenamentoDensidade.patchValue(residuo.armazenamentoDensidade);
        this.formResiduosSolidos.controls.processamentoQuantidade.patchValue(residuo.processamentoQuantidade);
        this.formResiduosSolidos.controls.processamentoUnidade.patchValue(residuo.processamentoUnidade);
        this.processamentoUnidade(residuo.processamentoUnidade);
        this.formResiduosSolidos.controls.processamentoDensidade.patchValue(residuo.processamentoDensidade);
        this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoQuantidade.patchValue(residuo.armazenamentoResiduoProcessadoQuantidade);
        this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoUnidade.patchValue(residuo.armazenamentoResiduoProcessadoUnidade);
        this.armazenamentoProcessadoUnidade(residuo.armazenamentoResiduoProcessadoUnidade);
        this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoDensidade.patchValue(residuo.armazenamentoResiduoProcessadoDensidade);
        this.formResiduosSolidos.controls.recebimentoPossuiAreaTancagem.patchValue(residuo.recebimentoPossuiAreaTancagem == true ? "sim" : "nao");
        this.changeTancagem(this.formResiduosSolidos.controls.recebimentoPossuiAreaTancagem.value);
        this.formResiduosSolidos.controls.recebimentoNumeroTanques.patchValue(residuo.recebimentoNumeroTanques);
        this.formResiduosSolidos.controls.recebimentoUnidadeAreaTancagem.patchValue(residuo.recebimentoUnidadeAreaTancagem);
        this.modoEdicao = true;
    }

    editarResiduo() {
        const validacao = this.validarFormulario();
        if (validacao) {
            this.deletarItem(this.formResiduosSolidos.controls.idTemp.value);
            this.adicionaResiduoSolido();
            this.modoEdicao = false;
        }
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.ocorreProcessamento = false;
        this.mostrarDensidadeRecebimento = false;
        this.mostrarDensidadeResiduoBruto = false;
        this.mostrarInfoTanques = false;
        this.formResiduosSolidos.reset();
        this.formResiduosSolidos.markAsUntouched();
        this.formResiduosSolidos.updateValueAndValidity()
    }

    validarFormulario(): boolean {
        if (this.formResiduosSolidos.valid) {
            if ((this.formResiduosSolidos.controls.recebimentoUnidade.value == "m³"
                || this.formResiduosSolidos.controls.recebimentoUnidade.value == "litro")) {
                if (this.verificarVazio(this.formResiduosSolidos.controls.recebimentoDensidade.value)) {
                    this.snackBar.showAlert(
                        "O campo de Densidade do agrupamento " +
                        "de \"Capacidade Recebimento\" é obrigatório para unidades \"m³\" e \"litro\". " +
                        "Favor realize o preenchimento do mesmo.");
                    this.formResiduosSolidos.markAllAsTouched();
                    return false;
                }
            }
            if ((this.formResiduosSolidos.controls.armazenamentoUnidade.value == "m³"
                || this.formResiduosSolidos.controls.armazenamentoUnidade.value == "litro")) {
                if (this.verificarVazio(this.formResiduosSolidos.controls.armazenamentoDensidade.value)) {
                    this.snackBar.showAlert(
                        "O campo de Densidade do agrupamento " +
                        "de \"Capacidade de armazenamento do residuo bruto\" é obrigatório para unidades \"m³\" e \"litro\". " +
                        "Favor realize o preenchimento do mesmo.");
                    this.formResiduosSolidos.markAllAsTouched();
                    return false;
                }
            }
            if (this.formResiduosSolidos.controls.possuiProcessamentoResiduos.value == "sim") {
                if (this.verificarVazio(this.formResiduosSolidos.controls.processamentoQuantidade.value) ||
                    this.verificarVazio(this.formResiduosSolidos.controls.processamentoUnidade.value) ||
                    this.verificarVazio(this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoQuantidade.value) ||
                    this.verificarVazio(this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoUnidade.value)) {
                    this.snackBar.showAlert(
                        "Os campos dos agrupamentos \"Capacidade Processamento\" " +
                        "e \"Capacidade de armazenamento do residuo processado\" são obrigatório. " +
                        "Favor realize o preenchimento do(s) mesmo(s).");
                    this.formResiduosSolidos.markAllAsTouched();
                    return false;
                } else {
                    if ((this.formResiduosSolidos.controls.processamentoUnidade.value == "m³"
                        || this.formResiduosSolidos.controls.processamentoUnidade.value == "litro")) {
                        if (this.verificarVazio(this.formResiduosSolidos.controls.processamentoDensidade.value)) {
                            this.snackBar.showAlert(
                                "O campo de Densidade do agrupamento " +
                                "de \"Capacidade Processamento\" é obrigatório para unidades \"m³\" e \"litro\". " +
                                "Favor realize o preenchimento do mesmo.");
                            this.formResiduosSolidos.markAllAsTouched();
                            return false;
                        }
                    }
                    if ((this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoUnidade.value == "m³"
                        || this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoUnidade.value == "litro")) {
                        if (this.verificarVazio(this.formResiduosSolidos.controls.armazenamentoResiduoProcessadoDensidade.value)) {
                            this.snackBar.showAlert(
                                "O campo de Densidade do agrupamento de \"Capacidade de armazenamento do residuo processado\" " +
                                "é obrigatório para unidades \"m³\" e \"litro\". Favor realize o preenchimento do mesmo.");
                            this.formResiduosSolidos.markAllAsTouched();
                            return false;
                        }
                    }
                }
            }
            if (this.formResiduosSolidos.controls.recebimentoPossuiAreaTancagem.value == "sim") {
                if (this.verificarVazio(this.formResiduosSolidos.controls.recebimentoNumeroTanques.value) ||
                    this.verificarVazio(this.formResiduosSolidos.controls.recebimentoUnidadeAreaTancagem.value)) {
                    this.snackBar.showAlert(
                        "Os campos \"Número de tanques\" e \"Unidade da Área de Tancagem\" são obrigatórios. Favor realize o preenchimento do(s) mesmo(s).")
                    this.formResiduosSolidos.markAllAsTouched();
                    return false;
                }
            }
            return true;
        } else {
            this.snackBar.showAlert(
                "Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).");
            this.formResiduosSolidos.markAllAsTouched();
            return false;
        }
    }

    verificarVazio(elemento): boolean {
        if (elemento == "" || elemento == null) {
            return true;
        } else {
            return false;
        }
    }
}