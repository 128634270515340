import {Directive, OnDestroy} from '@angular/core';
import {CdkPortalOutlet} from '@angular/cdk/portal';
import {Subscription} from 'rxjs';
import {MatExpansionPanel} from '@angular/material/expansion';
import {skip} from 'rxjs/operators';

@Directive({
    selector: '[expansionRemover]'
})
export class ExpansionRemoverDirective implements OnDestroy {
    portalOutlet: CdkPortalOutlet;
    subscription = new Subscription();

    constructor(private host: MatExpansionPanel) {
        this.subscription.add(
            this.host.opened.pipe(skip(1)).subscribe(() => this.handlePortal(true))
        );
        this.subscription.add(
            this.host.afterCollapse.subscribe(() => this.handlePortal(false))
        );
    }

    handlePortal(expanded: boolean): void {
        if (expanded) {
            this.host['_portal'].attach(this.portalOutlet);
        } else {
            if (!this.portalOutlet) {
                this.portalOutlet = this.host['_portal']['_attachedHost'];
            }
            this.host['_portal'].detach();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
