import {Pessoa} from '../../shared/models/pessoa.model';
import {DocumentoProcesso} from '../../shared/models/documento-processo.model';

export class AssinanteRequerimentoDto {
    constructor(
        public assinante?: Pessoa,
        public documentoProcesso?: DocumentoProcesso,
        public versao?: number,
        public dataAssinatura?: Date,
    ) {
    }

    static fromJson(json: any): AssinanteRequerimentoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new AssinanteRequerimentoDto(
            json.assinante,
            json.documentoProcesso,
            json.versao,
            json.dataAssinatura
        );
    }

    static fromJsons(json: any): AssinanteRequerimentoDto[] {
        const resources: AssinanteRequerimentoDto[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: AssinanteRequerimentoDto) => resources.push(this.fromJson(e)));
        }
        return resources;
    }
}
