import {AnaliseRequerimentoAnalista} from '../shared/model/analise-requerimento-analista.model';

export class AnaliseJustificativaPausa {
    constructor(
        public id?: number,
        public descricao?: string,
        public analiseRequerimentoEquipe?: AnaliseRequerimentoAnalista,
        public dataInicio?: Date,
    ) {
    }

    static fromJson(json: any): AnaliseJustificativaPausa {
        if (json === undefined || json === null) {
            return null;
        }

        return new AnaliseJustificativaPausa(json.id, json.descricao, json.analiseRequerimentoEquipe, json.dataInicio);
    }
}
