import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material';
import {ReplaySubject, Subject} from 'rxjs';
import {AbstractControl, FormControl} from '@angular/forms';
import {delay, take, takeUntil} from 'rxjs/operators';
import {SearchableModel} from './searchable-model';
import {FiltroSemAcento} from '../../util/filtro-sem-acento';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-select-search',
    templateUrl: './select-search.component.html'
})
export class SelectSearchComponent implements OnInit, AfterViewInit {
    @Input() set formCtrl(formControl: AbstractControl) {
        if (!(formControl instanceof FormControl)) {
            throw new Error('O parametro formControl deve ser do tipo "FormControl"');
        } else {
            this._formControl = formControl as FormControl;
            this.required = this._formControl.hasError('required');
        }
    }
    @Input() set data(data: SearchableModel[]) {
        this._data = data || [];
        // load the initial entity list
        this.filteredEntities.next(this.data.slice());
    }

    get data(): SearchableModel[] {
        return this._data;
    }

    @Input() set entity(entity: string) {
        this.entityCtrl.patchValue(entity);
    }

    constructor() {
    }

    _formControl: FormControl;

    private _data: SearchableModel[] = [];

    /** control for the selected entity */
    public entityCtrl: FormControl = new FormControl();

    /** control for the MatSelect filter keyword */
    public filterCtrl: FormControl = new FormControl();

    @Input() public debilitaHabilitaMun = true

    /** list of entities filtered by search keyword */
    public filteredEntities: ReplaySubject<SearchableModel[]> = new ReplaySubject<SearchableModel[]>(1);

    @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    private _onDestroy = new Subject<void>();

    @Input() titulo = 'Título não definido';
    @Input() searchDelay = 0;
    @Input() modoConsulta: SelectSearchMode = SelectSearchMode.LOCAL;
    @Output() eventConsultar = new EventEmitter<string>();
    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter<any>();
    required: boolean;
    clearSearchInput = true;
    @Input() compareWith: (a: any, b: any) => boolean = (a: any, b: any): boolean => a && b && a.id === b.id;

    ngOnInit(): void {
        if (this.modoConsulta == SelectSearchMode.SERVER_SIDE) {
            if (this.searchDelay == 0) {
                this.searchDelay = 1000;
            }
            this.clearSearchInput = false;
        }

        if (this.debilitaHabilitaMun) {
            this._formControl.disable()
        }

        // listen for search field value changes
        this.filterCtrl.valueChanges
            .pipe(delay(this.searchDelay), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterEntities();
            });
    }

    ngAfterViewInit(): void {
        this.setInitialValue();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onChange($event) {
        this.onSelectionChange.emit($event);
    }

    private setInitialValue() {
        this.filteredEntities
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                // setting the compareWith property to a comparison function
                // triggers initializing the selection according to the initial value of
                // the form control (i.e. _initializeSelection())
                // this needs to be done after the filteredEntities are loaded initially
                // and after the mat-option elements are available
                if (this.singleSelect) {
                    this.singleSelect.compareWith = this.compareWith;
                }
            });
    }

    private filterEntities() {
        if (!this.data) {
            return;
        }
        // get the search keyword
        let search = this.filterCtrl.value;
        if (!search) {
            if (this.modoConsulta == SelectSearchMode.SERVER_SIDE) {
                this._data = [];
            }
            this.filteredEntities.next(this.data.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the entitiess
        if (this.modoConsulta == SelectSearchMode.LOCAL) {
            this.filteredEntities.next(
                this.data.filter(entity => FiltroSemAcento.filtrarSemAcento(entity.searchValue(), search))
            );
        } else if (this.modoConsulta == SelectSearchMode.SERVER_SIDE) {
            if (isNullOrUndefined(this.eventConsultar)) {
                throw new Error('Não foi fornecido um método para consulta dos dados no componente select-search');
            } else {
                this.eventConsultar.next(search);
            }
        }
    }

}

export enum SelectSearchMode {
    LOCAL = 'LOCAL',
    SERVER_SIDE = 'SERVER_SIDE'
}