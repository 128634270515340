
import { BaseModel } from './base.model';
import { ParametrosControleAssinaturaSignatarioDTO } from './parametros-controle-assinatura-signatario-dto.model';
import {BaseEnum} from '../enums/base.enum';

export class ParametrosControleAssinaturaDTO extends BaseModel {
    constructor(
        public qtdSignatarios?: number,
        public tpAssinatura?: string,
        public qtdAssinaturasRealizadas?: number,
        public qtdAssinaturasNaoRealizadas?: number,
        public signatario?: ParametrosControleAssinaturaSignatarioDTO,
        public signatarios?: ParametrosControleAssinaturaSignatarioDTO[],
    ) {
        super();
    }

    static tpAssinatura: BaseEnum[] = [
        { valor: "ASSINATURA_ELETRONICA", descricao: ("Assinatura eletrônica") },
        { valor: "ASSINATURA_DIGITAL", descricao: ("Assinatura digital") }
    ];

    static fromJson(json: any): ParametrosControleAssinaturaDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParametrosControleAssinaturaDTO(
            json.qtdSignatarios,
            json.tpAssinatura,
            json.qtdAssinaturasRealizadas,
            json.qtdAssinaturasNaoRealizadas,
            ParametrosControleAssinaturaSignatarioDTO.fromJson(json.signatario),
            ParametrosControleAssinaturaSignatarioDTO.fromJsons(json.signatarios));
    }

    static fromJsons(jsonArray: any): ParametrosControleAssinaturaDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
