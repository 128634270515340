import {BaseModel} from 'app/main/shared/models/base.model';
import {TipoCondicionante} from '../tipos-condicionante/tipo-condicionante.model';

export class CondicionanteTemplate extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public situacao?: number,
        public tipoCondicionante?: TipoCondicionante
    ) {
        super();
    }

    static fromJson(json: any): CondicionanteTemplate {
        if (json === undefined || json === null) {
            return null;
        }
        return new CondicionanteTemplate(
            json.id,
            json.descricao,
            json.situacao,
            TipoCondicionante.fromJson(json.tipoCondicionante)
        );
    }

}

