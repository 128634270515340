import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../../../../../shared/snack-bar/snack-bar.service';
import {EmpreendimentoEnergiaFotovoltaicaService} from '../../../../../../../../shared/services/empreendimento-energia-fotovoltaica.service';
import {EnergiaFotovoltaicaPainel} from '../../../../../../../../shared/models/energia-fotovoltaica-painel.model';

@Component({
    selector: 'app-painel',
    templateUrl: './painel.component.html',
    styleUrls: ['./painel.component.scss']
})
export class PainelComponent implements OnInit, OnChanges {

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Input() id: number;
    @Input() readonly = false;
    @Input() mostrarObrigatorioPainel;
    @Input() isNaoPodeEditar: boolean;

    form: FormGroup;
    dataSource: EnergiaFotovoltaicaPainel[] = [];
    displayedColumns: string[] = [ 'identificacao', 'inclinacao', 'concentracaoSolar', 'especificacao' ];
    mapInclinacao = new Map();

    constructor(private fb: FormBuilder,
                private snackBarService: SnackBarService,
                private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService) {
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            id: [''],
            identificacao: ['', [Validators.required]],
            inclinacao: ['', Validators.required],
            concentracaoSolar: ['', Validators.required],
            especificacao: ['']
        });

        this.mapInclinacao.set( 1, 'Fixo' );
        this.mapInclinacao.set( 2, 'Rastreado (1 eixo)' );
        this.mapInclinacao.set( 3, 'Rastreado (2 eixo)' );
        this.mapInclinacao.set( 4, 'Outro' );

        if(this.mostrarObrigatorioPainel){
            this.form.markAsDirty();
            this.form.markAllAsTouched();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if ( changes.id ) {
            this.carregar( changes.id.currentValue );
        }

        if ( changes.readonly && !changes.readonly.currentValue ) {
            this.displayedColumns.push( 'acao' );
        }

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }

    }

    excluir( objeto: EnergiaFotovoltaicaPainel ): void {

        this.empreendimentoEnergiaFotovoltaicaService.deletarPainel( objeto.id )
            .subscribe( _ => {
                this.snackBarService.showSuccess( 'Painel excluído com sucesso!' );
                this.carregar(+this.id);
            }, error => {
                this.snackBarService.showError( 'Falha ao excluir painel!' );
                console.error( error );
            });
    }

    editar( objeto: EnergiaFotovoltaicaPainel ): void {

        this.form.patchValue({
            id: objeto.id,
            identificacao: objeto.identificacao,
            inclinacao: objeto.idTipoInclinPainelFotovolt,
            concentracaoSolar: objeto.indConcentracaoSolar,
            especificacao: objeto.concentracaoSolarEspecificacao
        });
    }

    onSaveForm(): void {

        if ( !this.form.invalid ) {
            const form = this.form.getRawValue();
            const entidade: EnergiaFotovoltaicaPainel = {
                id: form.id,
                empreendimentoEnergFotovoltaica: { id: +this.id },
                identificacao: form.identificacao,
                idTipoInclinPainelFotovolt: form.inclinacao,
                indConcentracaoSolar: form.concentracaoSolar,
                concentracaoSolarEspecificacao: form.especificacao
            };

            this.empreendimentoEnergiaFotovoltaicaService.salvarPainel( entidade )
                .subscribe( retorno => {
                    this.save.emit(retorno);
                    this.snackBarService.showSuccess( 'Informações salvas com sucesso!' );
                    this.carregar( +this.id );
                    this.form.reset();
                    this.form.markAsPristine();
                    this.form.markAsUntouched();
                    this.form.updateValueAndValidity();
                }, error => {

                    this.snackBarService.showError( 'Falha ao salvar informações!' );
                    console.error( error );
                } );

        } else {
            this.form.markAllAsTouched();
            this.snackBarService.showError( 'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)' );
        }


    }

    private carregar( id: number ): void {

        this.empreendimentoEnergiaFotovoltaicaService.listarPainel( id )
            .subscribe( lista => {
                this.dataSource = lista;
            }, error => {

                this.snackBarService.showError( 'Falha ao consultar paineis' );
                console.error( error );
            } );
    }

    

}
