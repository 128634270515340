import {Component, Inject, OnInit, Optional} from '@angular/core';
import {ValidacaoGeometria} from "../../../models/geoadmin/validacao-geometria.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ValidacaoData} from "../../../models/geoadmin/validacao-data";
import {Validacao} from "../../../models/geoadmin/validacao.model";

@Component({
    selector: 'mapa-validacao-feicao-entrada',
    templateUrl: './validacao-feicao-entrada-dialog.component.html',
    styleUrls: ['./validacao-feicao-entrada-dialog.component.scss']
})
export class ValidacaoFeicaoEntradaDialogComponent implements OnInit {
    tipoGeometria: string;

    gerais: Validacao[];

    especificas: Validacao[];

    validacoes: Validacao[];

    constructor(
        public dialogRef: MatDialogRef<ValidacaoFeicaoEntradaDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public validacaoData: ValidacaoData,
    ) {
      this.tipoGeometria = '';
      this.gerais = []
      this.especificas = []
      this.validacoes = []
    }

    ngOnInit() {
        if (this.validacaoData) {
          this.gerais = this.validacaoData.gerais;
          this.especificas = this.validacaoData.especificas;
          this.validacoes = this.validacaoData.validacoes;
          this.tipoGeometria = this.validacaoData.tipo;
        }
    }

    erroProjecao(validacao: Validacao): boolean {
        return !validacao.valido && validacao.texto.indexOf('SIRGAS 2000') > 0;
    }

  close() {
    this.dialogRef.close();
  }

  private verificaAlteracao(validacao: ValidacaoGeometria): void {
        if (validacao.wkt) {
            // this.wkt = validacao.wkt;
            // this.wktChange.emit(this.wkt);
        }
    }
}
