import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class MedidaControle extends BaseModel {
    constructor(
        public id: number,
        public idTipoMedidaControle: number,
        public descricao: string,
        public mineracao: AtividadeMineracao,
    ) {
        super();
    }

    static fromJson(json: any): MedidaControle {
        if (json === undefined || json === null) {
            return null;
        }
        return new MedidaControle(
            json.id,
            json.idTipoMedidaControle,
            json.descricao,
            json.mineracao,
        );
    }

}

