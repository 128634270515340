import {RequerimentoOutorgaFinalidadeService} from 'app/main/shared/services/requerimento-outorga-finalidade.service';
import {RebanhoService} from '../../../../../shared/services/rebanho.service';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {TipoRebanhoService} from '../../../../../shared/services/tipo-rebanho.service';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {TipoRebanho} from 'app/main/shared/models/tipo-rebanho.model';
import {Rebanho} from 'app/main/shared/models/rebanho.model';
import {BaseModel} from '../../../../../shared/models/base.model';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {isNullOrUndefined} from 'util';
import {DecimalPipe} from '@angular/common';

@Component({
    selector: 'app-finalidade-dessedentacao',
    templateUrl: './finalidade-dessedentacao.component.html',
    styleUrls: ['./finalidade-dessedentacao.component.scss'],
    animations: fuseAnimations
})
export class FinalidadeDessedentacaoComponent extends BaseFinalidadeComponent implements OnInit {

    constructor(
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private tipoRebanhoService: TipoRebanhoService,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private rebanhoService: RebanhoService,
        private decimalPipe: DecimalPipe,
        private snackBarService: SnackBarService
    ) {
        super();
    }

    form: FormGroup;
    formNovoRebanho: FormGroup;
    columnsToDisplay = ['tipo', 'confinadoQuantidade', 'confinadoConsumo', 'confinadoDemanda', 'livreQuantidade', 'livreConsumo', 'livreDemanda', 'acoes'];
    displayedDemandaColumns = ['demandaDiariaTitulo', 'emptyFooter', 'emptyFooter', 'demandaDiariaConfinado', 'emptyFooter', 'emptyFooter', 'demandaDiariaLivre', 'emptyFooter'];
    displayedDemandaTotalColumns = ['demandaDiariaTotalTitulo', 'demandaDiariaTotal', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter'];

    tiposRebanho: Array<TipoRebanho> = [];
    rebanhos: Array<Rebanho> = [];
    areaEmpreendimentoEmM2: string;

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.buildResourceForm();
        this.patchForm();
        this.carregarTipoRebanho();
        this.carregarRebanhoDoRequerimento();
        if (this.isModoVisualizacao) {
            this.form.disable();
            this.formNovoRebanho.disable();
            this.columnsToDisplay = ['tipo', 'confinadoQuantidade', 'confinadoConsumo', 'livreQuantidade', 'livreConsumo'];
        }
    }

    private patchForm(): void {
        this.form.patchValue({
            id: this.requerimentoOutorgaFinalidade.id,
            finalidade: this.requerimentoOutorgaFinalidade.finalidade,
            requerimentoOutorga: this.requerimentoOutorgaFinalidade.requerimentoOutorga,
            observacao: this.requerimentoOutorgaFinalidade.observacao
        });

        if (this.requerimentoOutorgaFinalidade.criacaoAnimal) {
            this.form.patchValue({
                criacaoAnimal: this.requerimentoOutorgaFinalidade.criacaoAnimal
            });
            this.formNovoRebanho.patchValue({
                criacaoAnimal: this.requerimentoOutorgaFinalidade.criacaoAnimal
            });
        }
    }

    private buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            finalidade: [null, [Validators.required]],
            requerimentoOutorga: [null, [Validators.required]],
            criacaoAnimal: this.formBuilder.group({
                id: [null],
                areaPropriedade: [null, [Validators.min(0)]],
                areaEmpreendimento: [null, [Validators.min(0)]],
                areaEmpreendimentoMedida: ['HA']
            }),
            observacao: [null],
            cadastroConcluido: [null]
        });

        this.form.controls.criacaoAnimal.get('areaEmpreendimento').valueChanges
            .subscribe(value => !isNullOrUndefined(value) ? this.areaEmpreendimentoEmM2 = this.decimalPipe.transform(value * 10000).toString() : null);

        this.formNovoRebanho = this.formBuilder.group({
            tipo: [null, [Validators.required]],
            quantidadeConfinado: [null, [Validators.required, Validators.min(0), Validators.max(2147483647)]],
            consumoConfinado: [null, [Validators.required, Validators.min(0)]],
            quantidadeLivre: [null, [Validators.required, Validators.min(0), Validators.max(2147483647)]],
            consumoLivre: [null, [Validators.required, Validators.min(0)]],
            outroRebanho: [{value: null, disabled: true}, [Validators.maxLength(255)]],
            criacaoAnimal: [null]
        });
    }

    removeRebanho(rebanho: Rebanho): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: rebanho.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.rebanhoService.delete(rebanho).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Rebanho excluído com sucesso!');
                        this.carregarRebanhoDoRequerimento();
                    },
                    (e) => this.snackBarService.showError('Erro ao excluir rebanho.', e)
                );
            }
        });
    }

    validateAddRebanho(): void {
        this.formNovoRebanho.markAllAsTouched();
        if (!this.formNovoRebanho.invalid) {
            this.formNovoRebanho.patchValue({
                criacaoAnimal: this.requerimentoOutorgaFinalidade.criacaoAnimal
            });
            if (
                this.rebanhos.some(rebanho => {
                    return (!this.formNovoRebanho.controls.tipo.value.outro &&
                        rebanho.tipo.id ===
                        this.formNovoRebanho.controls.tipo.value.id
                    );
                })
            ) {
                this.snackBarService.showAlert('Tipo de rebanho já adicionado.');
                return;
            }

            this.addRebanho();
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    private async addRebanho(): Promise<void> {
        if (isNullOrUndefined(this.requerimentoOutorgaFinalidade.criacaoAnimal)) {
            await this.salvar(false);
        }


        this.formNovoRebanho.patchValue({criacaoAnimal: this.form.get('criacaoAnimal').value});
        if (this.formNovoRebanho.valid) {
            this.rebanhoService
                .create(
                    Object.assign(new Rebanho(), this.formNovoRebanho.value)
                )
                .subscribe(
                    () => {
                        this.snackBarService.showSuccess('Rebanho adicionado com sucesso!');
                        this.carregarRebanhoDoRequerimento();
                        this.formNovoRebanho.reset();
                        this.formNovoRebanho.markAsUntouched();
                    },
                    (e) => this.snackBarService.showError('Erro ao adicionar rebanho.', e)
                );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    private carregarTipoRebanho(): void {
        this.tipoRebanhoService
            .getAll()
            .subscribe(tiposRebanho => (this.tiposRebanho = tiposRebanho));
    }

    private carregarRebanhoDoRequerimento(): void {
        if (this.requerimentoOutorgaFinalidade.criacaoAnimal) {
            this.rebanhoService
                .getAllByCriacaoAnimal(
                    this.requerimentoOutorgaFinalidade.criacaoAnimal
                )
                .subscribe(rebanhos => {
                    this.rebanhos = rebanhos;
                    if ((this.requerimentoOutorgaFinalidade.cadastroConcluido !== this.rebanhos.length > 0)) {
                        this.salvar(false);
                    }
                });
        }
    }

    async salvar(showMessages: boolean = true): Promise<void> {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.form.patchValue({cadastroConcluido: (this.rebanhos.length > 0)});

            try {
                const requerimentoOutorgaFinalidade = await this.requerimentoOutorgaFinalidadeService.update(this.form.value).toPromise();
                Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                this.form.patchValue(requerimentoOutorgaFinalidade);
                this.form.markAsPristine();
                if (showMessages) {
                    this.snackBarService.showSuccess('Registro salvo com sucesso!');
                }
            } catch (e) {
                if (showMessages) {
                    this.snackBarService.showError('Erro ao salvar dados.', e);
                }
            }
        } else {
            if (showMessages) {
                this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            }
        }
    }

    public getTotalConsumoConfinado(): number {
        if (this.rebanhos) {
            return this.rebanhos.reduce(
                (total, current) => total + current.consumoConfinado,
                0
            );
        }
        return 0;
    }

    public getDemandaConfinado(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaConfinado,
                0
            );
            return `${this.decimalPipe.transform(total)}`;
        }
        return '0';
    }

    public getTotalDemandaConfinado(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaConfinado,
                0
            );
            return `${this.decimalPipe.transform(total)} (L) ou ${this.decimalPipe.transform(total / 1000, '1.2-6')}(m³)`;
        }
        return '0';
    }

    public getTotalConsumoLivre(): number {
        if (this.rebanhos) {
            return this.rebanhos.reduce(
                (total, current) => total + current.consumoLivre,
                0
            );
        }
        return 0;
    }

    public getTotalQuantidadeConfinado(): number {
        if (this.rebanhos) {
            return this.rebanhos.reduce(
                (total, current) => total + current.quantidadeConfinado,
                0
            );
        }
        return 0;
    }

    public getTotalQuantidadeLivre(): number {
        if (this.rebanhos) {
            return this.rebanhos.reduce(
                (total, current) => total + current.quantidadeLivre,
                0
            );
        }
        return 0;
    }

    public getDemandaLivre(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaLivre,
                0
            );

            return `${this.decimalPipe.transform(total)}`;
        }
        return '0';
    }

    public getTotalDemandalivre(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaLivre,
                0
            );
            return `${this.decimalPipe.transform(total)} (L) ou ${this.decimalPipe.transform(total / 1000, '1.2-6')}(m³)`;
        }
        return '0';
    }

    public getDemandaDiariaTotal(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaLivre + current.demandaConfinado,
                0
            );
            return `${this.decimalPipe.transform(total)} (L) ou ${this.decimalPipe.transform(total / 1000, '1.2-6')}(m³)`;
        }
        return '0';
    }

    onChangeTipoRebanho(event): void {
        if (event) {
            const tipoRebanho: TipoRebanho = Object.assign(
                new TipoRebanho(),
                this.formNovoRebanho.controls.tipo.value
            );
            const field = this.formNovoRebanho.controls.outroRebanho;
            if (tipoRebanho.outro) {
                field.enable();
                field.setValidators([
                    Validators.required,
                    Validators.maxLength(255)
                ]);
                this.formNovoRebanho.updateValueAndValidity();
            } else {
                field.disable();
                field.setValue('');
                field.setValidators([Validators.maxLength(255)]);
                this.formNovoRebanho.updateValueAndValidity();
            }
        }
    }
}
