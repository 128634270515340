import {AnaliseConsema} from './analise-consema.model';


export class AnaliseConsemaAnexo {

    constructor(
        public id?: number,
        public analiseConsema?: AnaliseConsema,
        public tituloDocumento?: string,
        public sigiloso?: boolean,
        public chave?: string,
    ) {
    }

    static fromJson(json: any): AnaliseConsemaAnexo {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnaliseConsemaAnexo(
            json.id,
            AnaliseConsema.fromJson(json.analiseConsema),
            json.tituloDocumento,
            json.chave,
        );
    }

    static fromJsons(jsonArray: any): AnaliseConsemaAnexo[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}