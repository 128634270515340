import {BaseModel} from '../../../../../shared/models/base.model';
import {Pessoa} from '../../../../../shared/models/pessoa.model';
import {FormaOcupacao} from '../../../../formas-ocupacao/forma-ocupacao.model';
import {Imovel} from "./imovel.model";

export class ImovelPessoa extends BaseModel {
    constructor(
        public id?: number,
        public pessoa?: Pessoa,
        public formaOcupacao?: FormaOcupacao,
        public especificaoOcupacao?: string,
        public situacao?: string,
        public imovel?: Imovel,
    ) {
        super();
    }

    static fromJson(json: any): ImovelPessoa {
        if (json === undefined || json === null) {
            return null;
        }
        return new ImovelPessoa(
            json.id,
            Pessoa.fromJson(json.pessoa),
            FormaOcupacao.fromJson(json.formaOcupacao),
            json.especificaoOcupacao,
            json.situacao,
            Imovel.fromJson(json.imovel)
        );
    }

    static fromJsons(jsonArray: any): ImovelPessoa[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }

}
