import { Requerimento } from "app/main/pages/requerimentos/requerimento.model";
import {BaseModel} from "./base.model";
import { LancamentoCreditoReposicaoFlorestal } from "./lancamento-credito-reposicao-florestal.model";

export class FormularioReflorestamentoSemInventarioDTO extends BaseModel {

    constructor(
        public id?: number,
        public dataPlantio?: Date,
        public cpfDetentor?: string,
        public creditoSolicitadoM3?: number,
        public idRequerimento?: Number,
        public idLancamentoCreditoReposicaoFlorestal?: number,
        public idPessoa?: number,
        public idFinalidadePlantio?: number,
        public lancamentoCreditoCpfDetentor?: String,
        public lancamentoCreditoVolumeTotal?: number,
        public lancamentoCreditoVolumeTotalPorHectare?: number,
        public lancamentoCreditoCreditoSolicitado?: number,
        public lancamentoCreditoTotalCreditado?: number,
        public lancamentoCreditoTotalAditivado?: number,
        public lancamentoCreditoCreditoDisponivelEmM3PorHA?: number,
        public lancamentoCreditoCreditoConcedidoEmM3PorHA?: number,
        public lancamentoCreditoSituacaoCredito?: string,
    ) {
        super();
    }

    static fromJson(json: any): FormularioReflorestamentoSemInventarioDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new FormularioReflorestamentoSemInventarioDTO(
            json.id,
            json.dataPlantio,
            json.cpfDetentor,
            json.creditoSolicitadoM3,
            json.idRequerimento != null ? json.idRequerimento : null,
            json.idLancamentoCreditoReposicaoFlorestal != null ? json.idLancamentoCreditoReposicaoFlorestal : null,
            json.idPessoa != null ? json.idPessoa : null,
            json.idFinalidadePlantio != null ? json.idFinalidadePlantio : null,
            json.lancamentoCreditoCpfDetentor,
            json.lancamentoCreditoVolumeTotal,
            json.lancamentoCreditoVolumeTotalPorHectare,
            json.lancamentoCreditoCreditoSolicitado !== null ? json.lancamentoCreditoCreditoSolicitado : null,
            json.lancamentoCreditoTotalCreditado !== null ? json.lancamentoCreditoTotalCreditado : null,
            json.lancamentoCreditoCreditoDisponivelEmM3PorHA,
            json.lancamentoCreditoCreditoConcedidoEmM3PorHA !== null ? json.lancamentoCreditoCreditoConcedidoEmM3PorHA : null,
            json.situacaoCredito
        );
    }

}