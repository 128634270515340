import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {TipoMateriaPrimaMadeira} from 'app/main/shared/models/tipo-madeira-materia-prima';
import {TipoVolumeMateriaPrimaMadeira} from 'app/main/shared/models/tipo-volume-materia-prima-madeira';
import {DominioService} from 'app/main/shared/services/dominio.service';
import {MateriaPrimaMadeira} from "../../../../../../../../shared/models/materia-prima-madeira";

@Component({
    selector: 'app-materia-prima',
    templateUrl: './materia-prima.component.html',
    styleUrls: ['./materia-prima.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: MateriaPrimaComponent,
        multi: true,
    }]
})
export class MateriaPrimaComponent implements OnInit, ControlValueAccessor {
    @Input() isNaoPodeEditar: boolean;

    private onChange = (_) => {
    };
    private onTouched = () => {
    };

    isSomenteVisualizacao = false;

    tiposMateriaPrima: Array<TipoMateriaPrimaMadeira> = [];
    volumes: Array<TipoVolumeMateriaPrimaMadeira> = [];

    form: FormGroup;

    materiasPrimas: Array<MateriaPrimaMadeira>;
    tableColumns = ['tipoMateriaPrima', 'descricao', 'volumeUndMedida', 'acao'];

    constructor(private formBuilder: FormBuilder,
                private dominioService: DominioService,) {

    }

    ngOnInit() {
        this.buildForm();

        this.recuperaTiposVolume();
        this.recuperaTipoMateriaPrima();

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    private async recuperaTipoMateriaPrima() {
        this.dominioService.getMadeiraMateriaPrima()
            .subscribe(doms => this.tiposMateriaPrima = doms);
    }

    private async recuperaTiposVolume() {
        this.dominioService.getTiposUnidadeMedidaMadeira()
            .subscribe(doms => this.volumes = doms);
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            idServGeralMadeira: [null],
            tipoMateria: [{value: null, disabled: this.isSomenteVisualizacao}, [Validators.required]],
            volumeMateria: [{
                value: null,
                disabled: this.isSomenteVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            unidadeMateria: [{value: null, disabled: this.isSomenteVisualizacao}, [Validators.required]],
        });

        this.form.get('tipoMateria').valueChanges.subscribe((tipoMateria) => {
            const isOutros = tipoMateria && this.getDescricaoMateriaPrima(tipoMateria) === 'Outro';
            if (isOutros) {
                if (!this.form.get('descricaoMateria')) {
                    this.form.addControl('descricaoMateria',
                        this.formBuilder.control(null, [Validators.required, Validators.maxLength(100)]));
                }
            } else if (!!this.form.get('descricaoMateria')) {
                this.form.removeControl('descricaoMateria');
            }
        });
    }

    adicionaMateriaPrima() {
        if (this.form.valid) {
            this.materiasPrimas = [
                ...(this.materiasPrimas || []),
                MateriaPrimaMadeira.fromJson({
                    id: this.form.controls.id.value,
                    idMadeira: this.form.controls.tipoMateria.value,
                    descricao: !!this.form.get('descricaoMateria') ? this.form.controls.descricaoMateria.value : undefined,
                    volume: this.form.controls.volumeMateria.value,
                    idUnidadeMedida: this.form.controls.unidadeMateria.value,
                    idServGeralMadeira: this.form.controls.idServGeralMadeira.value,
                })
            ];

            this.onTouched();
            this.onChange(this.materiasPrimas);

            this.buildForm()
        }
    }

    editarItem(item: MateriaPrimaMadeira) {
        this.form.setValue({
            id: item.id || null,
            tipoMateria: item.idMadeira,
            descricaoMateria: item.descricao || null,
            volumeMateria: item.volume,
            unidadeMateria: item.idUnidadeMedida,
            idServGeralMadeira: item.idServGeralMadeira,
        });

        this.deletarItem(item);
    }

    deletarItem(item: MateriaPrimaMadeira) {
        this.materiasPrimas = [
            ...this.materiasPrimas.filter((mp) => mp !== item)
        ];

        if (!this.materiasPrimas) {
            this.materiasPrimas = undefined;
        }

        this.onTouched();
        this.onChange(this.materiasPrimas);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isSomenteVisualizacao = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj && obj.length) {
            this.materiasPrimas = obj;
        } else {
            this.materiasPrimas = undefined;
        }
    }

    getDescricaoMateriaPrima(idMadeira: any) {
        const found = this.tiposMateriaPrima.find(mp => mp.id === idMadeira);

        return !!found ? found.descricao : undefined;
    }

    getDescricaoUnidadeMedida(idUnidadeMedida: any) {
        const found = this.volumes.find(mp => mp.id === idUnidadeMedida);

        return !!found ? found.descricao : undefined;
    }
}
