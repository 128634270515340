import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirmacao-licenciamento-dialog',
    templateUrl: './confirmacao-licenciamento-dialog.component.html',
})
export class ConfirmacaoLicenciamentoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmacaoLicenciamentoDialogComponent>) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
