import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AutoClaveDto } from '../../../../../../../shared/models/empreendimentos-models/auto-clave-dto.model';
import { ResiduosTratamentoTermicoDto } from '../../../../../../../shared/models/empreendimentos-models/residuos-tratamento-termico-dto.model';
import { SituacaoIntertravamentoDto } from '../../../../../../../shared/models/empreendimentos-models/situacao-intertravamento-dto.model';
import { ResiduoTratamentoTermicoService } from '../../../../../../../shared/services/residuo-tratamento-termico.service';
import { SnackBarService } from '../../../../../../../shared/snack-bar/snack-bar.service';
import { RequerimentoService } from "../../../../../requerimento.service";
import { RequerimentoFormularioLicenciamentoService } from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import { BaseAtividadeEmpreendimentoComponent } from '../base-atividade-empreendimento.component';

@Component({
    selector: 'app-residuos-tratamento-termico',
    templateUrl: './residuos-tratamento-termico.component.html',
    styleUrls: ['./residuos-tratamento-termico.component.scss']
})
export class ResiduosTratamentoTermicoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    @Output() emitterValidacaoSave = new EventEmitter();

    statusFormAutoClave: boolean = false;

    statusError = false;
    formTratamentoTermico: FormGroup;
    autoClaveDto: AutoClaveDto[] = [];
    situacaoIntertravamentoDto: SituacaoIntertravamentoDto[] = [];
    idTramentoTermico: any;

    isCapacidadeNominal = false;
    tituloCampoCapacidadeNominal = 'Capacidade Nominal - Kg/ciclo'
    isCampoNumDeCarregamento = false;
    isCamposAutoClave = false;
    isCamposTratamentoTermico = false;
    isNaoPodeEditar: boolean = false;

    // Variável para binding do limite de dígitos no input
    _maxLength: string = '20';

    updateId: boolean = false;
    idRequerimentoVinculado;

    constructor(private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
                private residuoTratamentoTermicoService: ResiduoTratamentoTermicoService) {
        super();
    }

    async ngOnInit() {
        this.buildFormTratamentoTermico();
        if (this.requerimento) {
            this.verificaSePodeEditar();

            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                this.idRequerimentoVinculado = requerimentoLicenciamentoVinculado.requerimento.id
                await this.carregaTratamentoTermicoPorId(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregaTratamentoTermicoPorId(this.requerimento.id)
            }
        }
    }

   private verificaSePodeEditar() {
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.formTratamentoTermico.disable();
        }
    }

    private buildFormTratamentoTermico(): void {
        this.formTratamentoTermico = this.formBuilder.group({
            id: [null],
            atividadeAutoClave: ['', [Validators.required]],
            atividadeTratamentoTermico: ['', [Validators.required]],
            formaDeAlimentacao: ['', [Validators.required]],
            numeroDeCarregamentos: ['', [Validators.required]],
            possuiUnidadeRecuperacao: ['', [Validators.required]],
            capacidadeNominal: ['', [Validators.required]],
            possuiCamaraPosCombustao: ['', [Validators.required]],
            possuiSistemaIntertravamento: ['', [Validators.required]],
        });
    }

    salvar() {
        const validate = this.validarFormulario();
        if (validate) {
            const tratamentoTermicoDto = this.preencherTratamentoTermicoDto();
            this.residuoTratamentoTermicoService.createResiduoTratamentoTermico(tratamentoTermicoDto).subscribe(() => {
                this.snackBar.showSuccess('Resíduos Tratamento Térmico salvo com sucesso.')
                this.enviarFormConcluido.emit();
            }, err => {
                this.snackBar.showError('Erro ao inserir este formulário', err)
            });
            this.formTratamentoTermico.reset();
            this.isCamposAutoClave = false;
            this.isCamposTratamentoTermico = false;
            this.autoClaveDto = [];
            this.statusFormAutoClave = false;
            this.situacaoIntertravamentoDto = [];
            this.isAutoClaveChecked = false;
            this.isTratamTermicoChecked = false;
            this.ValidarCamposTratamTermico();
        }
    }

    receberAutoClaveDto(autoClave: any = []) {
        this.autoClaveDto = autoClave;
    }

    receberStatusFormAutoClaveEmitter(status: boolean) {
        this.statusFormAutoClave = status;
        if (!this.isTratamTermicoChecked) {
            if (this.statusFormAutoClave) {
                this.LimparValidacaoCamposTratamTermico();
            }
            this.statusFormAutoClave = false;
        } else {
            this.ValidarCamposTratamTermico();
        }
    }

    receberSituacoesInterTravamentoDto(situacoeIntertravamento: any) {
        this.situacaoIntertravamentoDto = situacoeIntertravamento;
    }

    validarFormulario(): boolean {

        // Selecionando apenas o fildset Autoclave para preenchimento e salvar
        if (this.isAutoClaveChecked.valueOf() === true && this.isTratamTermicoChecked.valueOf() === false) {

            if (this.autoClaveDto.length < 1 || this.formTratamentoTermico.invalid) {
                this.snackBar.showAlert('O formulário AutoClave possui campo(s) obrigatório(s) não preenchido(s).'
                    + 'Favor realize o preenchimento do(s) mesmo(s).');
                return false;
            }
            this.LimparValidacaoCamposTratamTermico();
            this.formTratamentoTermico.markAsUntouched();
            return true;

            // Selecionando o fildset Autoclave e também o fildset Tratamento-Térmico para preenchimento e salvar
        } else if (this.isAutoClaveChecked.valueOf() === true && this.isTratamTermicoChecked.valueOf() === true) {

            if (this.autoClaveDto.length < 1) {
                this.snackBar.showAlert('O formulário AutoClave possui campo(s) obrigatório(s) não preenchido(s).'
                    + 'Favor realize o preenchimento do(s) mesmo(s).');
                return false;
            }

            if (this.formTratamentoTermico.value.possuiSistemaIntertravamento === 'sim'
                && this.situacaoIntertravamentoDto.length < 1) {
                this.snackBar.showAlert(
                    'Favor informar a identificação do sistema de intertravamento.');
                return false;
            }

            return true;

            // Selecionando apenas o fildeset tratamento-termico para preenchimento e salvar 
        } else if (this.isAutoClaveChecked.valueOf() === false && this.isTratamTermicoChecked.valueOf() === true) {

            this.autoClaveDto = [];

            if (this.formTratamentoTermico.value.possuiSistemaIntertravamento === 'sim'
                && this.situacaoIntertravamentoDto.length < 1) {
                this.snackBar.showAlert(
                    'Favor informar a identificação do sistema de intertravamento.');
                return false;
            }

            return true;

        } else {
            this.snackBar.showAlert(
                'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            this.formTratamentoTermico.markAllAsTouched();
            return false;
        }
    }

    preencherTratamentoTermicoDto() {
        const residuosTratamentoTermicoDto = new ResiduosTratamentoTermicoDto()
        residuosTratamentoTermicoDto.idRequerimento = this.idRequerimentoVinculado ? this.idRequerimentoVinculado : this.requerimento.id;
        residuosTratamentoTermicoDto.id = this.idTramentoTermico ? this.idTramentoTermico : null
        residuosTratamentoTermicoDto.autoClaveDto = this.autoClaveDto
        residuosTratamentoTermicoDto.atividadeAutoClave = this.formTratamentoTermico.value.atividadeAutoClave === true ? 1 : 0
        residuosTratamentoTermicoDto.atividadeTratamentoTermico = this.formTratamentoTermico.value.atividadeTratamentoTermico === true ? 1 : 0
        residuosTratamentoTermicoDto.formaDeAlimentacao = this.formTratamentoTermico.value.formaDeAlimentacao
        residuosTratamentoTermicoDto.numeroDeCarregamentos = this.formTratamentoTermico.value.numeroDeCarregamentos
        residuosTratamentoTermicoDto.possuiUnidadeRecuperacao = this.formTratamentoTermico.value.possuiUnidadeRecuperacao === 'sim' ? 1 : 0
        residuosTratamentoTermicoDto.capacidadeNominal = this.formTratamentoTermico.value.capacidadeNominal
        residuosTratamentoTermicoDto.possuiCamaraPosCombustao = this.formTratamentoTermico.value.possuiCamaraPosCombustao === 'sim' ? 1 : 0
        residuosTratamentoTermicoDto.possuiSistemaIntertravamento = this.formTratamentoTermico.value.possuiSistemaIntertravamento === 'sim' ? 1 : 0
        residuosTratamentoTermicoDto.situacaoIntertravamentoDto = this.situacaoIntertravamentoDto ? this.situacaoIntertravamentoDto : null

        return residuosTratamentoTermicoDto;
    }

    async carregaTratamentoTermicoPorId(idRequerimento: number) {
        await this.residuoTratamentoTermicoService.buscarPorIdRequerimento(idRequerimento).toPromise().then(tratamentoTermico => {
            if (tratamentoTermico) {
                this.idTramentoTermico = tratamentoTermico.id;
                this.formTratamentoTermico.controls.atividadeAutoClave.patchValue(tratamentoTermico.autoClave === 1);
                this.formTratamentoTermico.controls.atividadeTratamentoTermico.patchValue(tratamentoTermico.tratamentoTermico === 1);
                this.formTratamentoTermico.controls.formaDeAlimentacao.patchValue(tratamentoTermico.formaAlimentacao);
                this.formTratamentoTermico.controls.numeroDeCarregamentos.patchValue(tratamentoTermico.numeroCarregamentoUnidade);
                this.formTratamentoTermico.controls.possuiUnidadeRecuperacao.patchValue(tratamentoTermico.possuiUnidadeRecuperacaoCalor === 1 ? 'sim' : 'nao');
                this.formTratamentoTermico.controls.capacidadeNominal.patchValue(tratamentoTermico.capacidadeNominal);
                this.formTratamentoTermico.controls.possuiCamaraPosCombustao.patchValue(tratamentoTermico.possuiCamaraPosCombustao === 1 ? 'sim' : 'nao');
                this.formTratamentoTermico.controls.possuiSistemaIntertravamento.patchValue(tratamentoTermico.possuiSistemaIntertravamento === 1 ? 'sim' : 'nao');

                if (tratamentoTermico.autoClave === 1) {
                    this.updateId = true;
                    this.isCamposAutoClave = true;
                } else if (tratamentoTermico.tratamentoTermico === 1) {
                    this.updateId = true;
                    this.isCamposTratamentoTermico = true;
                }

            }
        });
    }

    // Obtendo o valor atual do campo "numeroDeCarregamentos"
    get numeroDeCarregamentos() {
        return this.formTratamentoTermico.get('numeroDeCarregamentos');
    }

    // Alterando o título do campo "Capacidade Nominal"
    // Apresentando ou não o campo "numeroDeCarregamentos"
    campoCapacidadeNominal(): void {
        this.formTratamentoTermico.get('formaDeAlimentacao').valueChanges
            .subscribe(selectedValue => {
                const numeroDeCarregamentos = this.formTratamentoTermico.get('numeroDeCarregamentos');
                if (selectedValue === 'continua') {
                    this.tituloCampoCapacidadeNominal = 'Capacidade Nominal - Kg/h'
                    this.isCampoNumDeCarregamento = false;
                    numeroDeCarregamentos.reset();
                    this.formTratamentoTermico.get('numeroDeCarregamentos').clearValidators();
                    this.formTratamentoTermico.get('numeroDeCarregamentos').markAsUntouched();
                    this.formTratamentoTermico.get('numeroDeCarregamentos').updateValueAndValidity();

                } else if (selectedValue === 'descontinua') {
                    this.isCampoNumDeCarregamento = true;
                    this.tituloCampoCapacidadeNominal = 'Capacidade Nominal - Kg/ciclo'
                    this.formTratamentoTermico.get('numeroDeCarregamentos').setValidators([Validators.required]);
                    this.formTratamentoTermico.get('numeroDeCarregamentos').markAllAsTouched();
                    this.formTratamentoTermico.get('numeroDeCarregamentos').updateValueAndValidity();
                } else {
                    this.formTratamentoTermico.get('numeroDeCarregamentos').clearValidators();
                    this.formTratamentoTermico.get('numeroDeCarregamentos').markAsUntouched();
                    this.formTratamentoTermico.get('numeroDeCarregamentos').updateValueAndValidity();
                }
            })
    }

    //  Apresentando ou não o fildSet "AutoClave"
    isAutoClaveChecked: boolean = false;

    apresentarFieldSetAutoClave(checkBox: MatCheckboxChange) {
        if (checkBox.checked) {
            this.isAutoClaveChecked = true;
            this.isCamposAutoClave = true;
            this.updateId = true;

        } else {
            this.isAutoClaveChecked = false;
            this.isCamposAutoClave = false;
            this.autoClaveDto = [];
        }
    }

    // Apresentando ou não o fildSet "Tratamento Térmico"
    isTratamTermicoChecked: boolean = false;

    apresentarFieldSetTratamTermico(checkBox: MatCheckboxChange) {
        if (checkBox.checked) {
            this.isTratamTermicoChecked = true;
            this.isCamposTratamentoTermico = true;
            this.updateId = true;
            if (!this.isAutoClaveChecked) {
                this.formTratamentoTermico.get('atividadeAutoClave').clearValidators();
                this.formTratamentoTermico.get('atividadeAutoClave').updateValueAndValidity();
            }
        } else {
            this.isTratamTermicoChecked = false;
            this.isCamposTratamentoTermico = false;
            this.resetCamposFieldsetTratamentoTermico();
            if (this.isAutoClaveChecked && this.autoClaveDto.length > 0) {
                this.LimparValidacaoCamposTratamTermico();
            }
        }
    }

    // Método para limpar validação conforme regras
    LimparValidacaoCamposTratamTermico(): void {
        this.formTratamentoTermico.get('atividadeTratamentoTermico').clearValidators();
        this.formTratamentoTermico.get('formaDeAlimentacao').clearValidators();
        this.formTratamentoTermico.get('numeroDeCarregamentos').clearValidators();
        this.formTratamentoTermico.get('possuiUnidadeRecuperacao').clearValidators();
        this.formTratamentoTermico.get('capacidadeNominal').clearValidators();
        this.formTratamentoTermico.get('possuiCamaraPosCombustao').clearValidators();
        this.formTratamentoTermico.get('possuiSistemaIntertravamento').clearValidators();

        this.formTratamentoTermico.get('atividadeTratamentoTermico').updateValueAndValidity();
        this.formTratamentoTermico.get('formaDeAlimentacao').updateValueAndValidity();
        this.formTratamentoTermico.get('numeroDeCarregamentos').updateValueAndValidity();
        this.formTratamentoTermico.get('possuiUnidadeRecuperacao').updateValueAndValidity();
        this.formTratamentoTermico.get('capacidadeNominal').updateValueAndValidity();
        this.formTratamentoTermico.get('possuiCamaraPosCombustao').updateValueAndValidity();
        this.formTratamentoTermico.get('possuiSistemaIntertravamento').updateValueAndValidity();
    }

    // Validar novamente os campos do fildset tratamento térmico
    ValidarCamposTratamTermico(): void {
        this.formTratamentoTermico.get('atividadeTratamentoTermico').setValidators([Validators.required]);
        this.formTratamentoTermico.get('formaDeAlimentacao').setValidators([Validators.required]);
        this.formTratamentoTermico.get('numeroDeCarregamentos').setValidators([Validators.required]);
        this.formTratamentoTermico.get('possuiUnidadeRecuperacao').setValidators([Validators.required]);
        this.formTratamentoTermico.get('capacidadeNominal').setValidators([Validators.required]);
        this.formTratamentoTermico.get('possuiCamaraPosCombustao').setValidators([Validators.required]);
        this.formTratamentoTermico.get('possuiSistemaIntertravamento').setValidators([Validators.required]);
        this.formTratamentoTermico.get('atividadeTratamentoTermico').updateValueAndValidity();
        this.formTratamentoTermico.get('formaDeAlimentacao').updateValueAndValidity();
        this.formTratamentoTermico.get('numeroDeCarregamentos').updateValueAndValidity();
        this.formTratamentoTermico.get('possuiUnidadeRecuperacao').updateValueAndValidity();
        this.formTratamentoTermico.get('capacidadeNominal').updateValueAndValidity();
        this.formTratamentoTermico.get('possuiCamaraPosCombustao').updateValueAndValidity();
        this.formTratamentoTermico.get('possuiSistemaIntertravamento').updateValueAndValidity();
    }

    // Resetando apenas campos do fieldset tratamento térmico
    resetCamposFieldsetTratamentoTermico(): void {
        this.formTratamentoTermico.get('formaDeAlimentacao').reset();
        this.formTratamentoTermico.get('numeroDeCarregamentos').reset();
        this.formTratamentoTermico.get('possuiUnidadeRecuperacao').reset();
        this.formTratamentoTermico.get('capacidadeNominal').reset();
        this.formTratamentoTermico.get('possuiCamaraPosCombustao').reset();
        this.formTratamentoTermico.get('possuiSistemaIntertravamento').reset();
    }
}
        