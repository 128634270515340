import {TimelineModule} from '../../../../shared/components/timeline/timeline.module';
import {HistoricoRequerimentoComponent} from './historico-requerimento.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../../../../shared/shared.module";
import {FlexModule} from "@angular/flex-layout";

@NgModule({
    declarations: [
        HistoricoRequerimentoComponent
    ],
    imports: [
        CommonModule,
        TimelineModule,
        SharedModule,
        FlexModule
    ],
    exports: [
        HistoricoRequerimentoComponent
    ],
    entryComponents: [
        HistoricoRequerimentoComponent
    ]
})
export class HistoricoRequerimentoModule {
}
