import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DominiosService} from '../../../../shared/services/dominios.service';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {AreaIrrigacao} from '../../../../shared/models/area-irrigacao.model';
import {AreaIrrigacaoService} from '../../../../shared/services/area-irrigacao.service';

@Component({
    selector: 'app-area-irrigacao',
    templateUrl: './area-irrigacao.component.html',
    styleUrls: ['./area-irrigacao.component.scss']
})
export class AreaIrrigacaoComponent implements OnInit {
    areaIrrigacaoForm: FormGroup;
    mostraDescricao = false;
    tipoIrrigacao: any;
    culturasDesenvolvida: any;
    volumeEfluentes = 20;

    // : TODO Esses Parametros devem chegar atraves do component Pai
    idGeometria = 10;

    constructor(private dominiosService: DominiosService,
                private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private areaIrrigacaoService: AreaIrrigacaoService) {
    }

    ngOnInit() {
        this.areaIrrigacaoBuilder();
        this.carregarTiposIrrigacao().then();
    }

    private areaIrrigacaoBuilder(): void {
        this.areaIrrigacaoForm = this.formBuilder.group({
            id: [null],
            tipoIrrigacao: [null, Validators.required],
            culturasDesenvolvida: [null, Validators.required],
            volumeEfluentes: [null, Validators.required],
            descricao: [null, Validators.required]
        });
        this.areaIrrigacaoForm.get('descricao').disable();
    }

    exibirInputDescrever(tipo: any): void {
        if (tipo === 'Culturas anuais' || tipo === 'Outras') {
            this.mostraDescricao = true;
            this.areaIrrigacaoForm.get('descricao').enable();
        } else {
            this.mostraDescricao = false;
            this.areaIrrigacaoForm.get('descricao').disable();
            this.areaIrrigacaoForm.get('descricao').reset();
        }
    }

    validarInputs(): boolean {
        if (this.areaIrrigacaoForm.valid) {
            if (String(this.areaIrrigacaoForm.value.volumeEfluentes).includes('-')) {
                return false;
            }
            return true;
        }
        this.areaIrrigacaoForm.markAllAsTouched();
        return false;
    }

    async carregarTiposIrrigacao(): Promise<void> {
        this.tipoIrrigacao = await this.dominiosService.getDominio('TIPO_DE_IRRIGACAO').toPromise();
        this.culturasDesenvolvida = await this.dominiosService.getDominio('TIPO_CULTURAS_DESENVOLVIDA').toPromise();
    }

    apenasNumeros(event): boolean {
        if (event.key === 'e' || event.key === '.' || event.key === ',' || event.key.includes('-')) {
            return false;
        }
    }

    salvar(): void {
        const validate = this.validarInputs();
        const areaDeIrrigacao = new AreaIrrigacao();

        if (validate) {
            areaDeIrrigacao.idGeometria = this.idGeometria;
            areaDeIrrigacao.tipoIrrigacao = this.areaIrrigacaoForm.value.tipoIrrigacao;
            areaDeIrrigacao.culturasDesenvolvida = this.areaIrrigacaoForm.value.culturasDesenvolvida;
            areaDeIrrigacao.volumeEfluentes = this.areaIrrigacaoForm.value.volumeEfluentes;
            areaDeIrrigacao.descricao = this.areaIrrigacaoForm.value.descricao;
            this.areaIrrigacaoService.salvarTipoDeIrrigacao(areaDeIrrigacao).subscribe(() => {
                this.snackBar.showSuccess('Área de irrigação salva com sucesso.');
                this.areaIrrigacaoForm.reset();
                this.areaIrrigacaoForm.get('descricao').disable();
                this.mostraDescricao = false;
            });
        } else {
            this.snackBar.showError('Campo(s) obrigatório(s) não' +
                'preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');

            if (String(this.areaIrrigacaoForm.value.volumeEfluentes).includes('-')) {
                this.snackBar.showError('O campo não aceita números negativos.');
            }

        }
    }

}
