import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import {
    RequerimentoTitulacao
} from 'app/main/pages/requerimentos/shared/requerimento-titulacao/shared/requerimento-titulacao.model';
import {
    RequerimentoTitulacaoService
} from 'app/main/pages/requerimentos/shared/requerimento-titulacao/shared/requerimento-titulacao.service';
import { LicencaFiscalizacao } from 'app/main/pages/simlam/licenca-fiscalizacao.model';
import { EnumOrigemTitulacao } from 'app/main/shared/enums/EnumOrigemTitulacao';
import { Processo } from 'app/main/shared/models/processo.model';
import { environment } from "../../../../../../../../environments/environment";
import {
    DialogConfirmacaoComponent
} from "../../../../../../shared/components/dialog-confirmacao/dialog-confirmacao.component";
import {
    MessageDialog,
    MessageDialogResult
} from "../../../../../../shared/components/message-dialog/message-dialog.model";
import {
    AtividadeCalculoArrecadacao
} from "../../../../../../shared/models/arrecadacao-models/atividade-calculo-arrecadacao.model";
import {
    ParametroEntradaArrecadacao
} from "../../../../../../shared/models/arrecadacao-models/parametro-entrada-arrecadacao.model";
import { DominioDto } from "../../../../../../shared/models/dominio-dto.model";
import { FormularioReqLicenciamentoEmp } from "../../../../../../shared/models/formulario-req-licenciamento-emp.dto";
import { LancamentoTaxaDto } from "../../../../../../shared/models/lancamento-taxa-dto.model";
import {
    ConfiguracaoLicenciamento
} from "../../../../../../shared/models/licenciamento-config-models/configuracao-licenciamento.model";
import {
    ObjetivoLicenciamentoSubatividade
} from "../../../../../../shared/models/objetivo-licenciamento-subatividade.model";
import { ObjetivoLicenciamento } from "../../../../../../shared/models/objetivo-licenciamento.model";
import { RequerimentoTituloVinculado } from "../../../../../../shared/models/requerimento-titulo-vinculado.model";
import {
    AtividadeArrecadacaoService
} from "../../../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service";
import {
    AtividadeCalculoArrecadacaoService
} from "../../../../../../shared/services/arrecadacao-service/atividade-calculo-arrecadacao.service";
import {
    AtividadeParametroEntradaArrecadacaoService
} from "../../../../../../shared/services/arrecadacao-service/atividade-parametro-entrada-arrecadacao.service";
import { TipoTitulacaoService } from "../../../../../../shared/services/arrecadacao-service/tipo-titulacao.service";
import { DominiosService } from "../../../../../../shared/services/dominios.service";
import { LancTaxaParamCalculoService } from "../../../../../../shared/services/lanc-taxa-param-calculo.service";
import { LancamentoTaxaService } from "../../../../../../shared/services/lancamento-taxa.service";
import {
    ObjetivoLicenciamentoAmbientalService
} from "../../../../../../shared/services/licenciamento-config-service/objetivo-licenciamento-ambiental.service";
import {
    ObjetivoParametrizacaoObjetivoService
} from "../../../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service";
import {
    ParametroObjetivoService
} from "../../../../../../shared/services/licenciamento-config-service/parametro-objetivo.service";
import {
    ObjetivoLicenciamentoSubatividadeService
} from "../../../../../../shared/services/objetivo-licenciamento-subatividade.service";
import { ObjetivoLicenciamentoService } from "../../../../../../shared/services/objetivo-licenciamento.service";
import { SnackBarService } from "../../../../../../shared/snack-bar/snack-bar.service";
import {
    ConfirmacaoDialogComponent
} from "../../../../../parecer-tecnico/parecer-tecnico-conclusoes/parecer-tecnico-conclusoes-dialog/confirmacao-dialog/confirmacao-dialog.component";
import { SimlamService } from "../../../../../simlam/simlam.service";
import { EmissaoTituloService } from "../../../../../tarefas/shared/service/emissao-titulo.service";
import { ReqTituloVinculadoService } from "../../../../../tarefas/shared/service/req-titulo-vinculado.service";
import { TermoReferenciaService } from "../../../../../termos-referencia/termo-referencia.service";
import { TipoProcessoEnum } from "../../../../../tipos-processo/tipo-processo.enum";
import { TipoRequerimentoService } from "../../../../../tipos-requerimento/tipo-requerimento.service";
import { TitulacaoService } from "../../../../../titulacoes/titulacao.service";
import { EnumInstanciaRequerimento } from "../../../../menu-novo-requerimento/instancia-requerimento.enum";
import { Requerimento } from "../../../../requerimento.model";
import { RequerimentoService } from "../../../../requerimento.service";
import { BaseTabRequerimentoComponent } from "../../../../shared/base-requerimento.component";
import {
    ErrosAbaRequerimento
} from "../../../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component";
import { RequerimentoSteps } from "../../../../shared/requerimento.steps";
import {
    RequerimentoFormularioLicenciamentoService
} from "../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import { RequerimentoLicenciamento } from "../../../requerimento-licenciamento.model";
import { RequerimentoLicenciamentoService } from "../../../requerimento-licenciamento.service";

@Component({
    selector: 'app-aba-requerimento-lic-emp',
    templateUrl: './aba-requerimento-lic-emp.component.html',
    styleUrls: ['./aba-requerimento-lic-emp.component.scss']
})
export class AbaRequerimentoLicEmpComponent extends BaseTabRequerimentoComponent implements OnInit {

    @ViewChild("valorParametro", {static: false}) valorParametro: ElementRef;

    lpTramitacaoProcesso = '';
    processo: Processo;
    configuracaoLicenciamento: ConfiguracaoLicenciamento
    tiposAtuacao: Array<DominioDto> = [];
    tiposRequerimento: Array<DominioDto> = [];
    tiposObjetivo: Array<any> = [];
    titulos: Array<any> = [];
    tiposTitulacao: Array<any> = [];

    licencasFiscalizacao: Array<LicencaFiscalizacao> = [];

    objetivosPrincipais: Array<any>;
    objetivosOutros: Array<any>;
    objetivos: Array<any> = [];
    todosObjetivos: Array<any> = [];
    objetivosDeVinculo: Array<any> = [];
    objetivosPrincipaisSelecionados = []
    objetivosOutrosSelecionados = []
    objetivoSelecionado: any
    objetivoOutroSelecionado: any
    displayedColumns: string[] = ['objetivo', 'acao'];

    atividadeLicenSelecionado: any
    atividade: any
    atividadeAut: any
    isAtividadeComplementar = true
    atividadesGrp = [];
    subGrupo = [];
    grupoSubGrupoSelecionadaTable = [];
    atividadeLicenciada = [];
    atividadeLicenciadaEncontrado = [];
    atividadeLicenciadaSubGrupoEncontrado = [];
    atividadeCompLicenciadaEncontrado = [];
    atividadeSelecionadaTable = []
    atividadeComplementar: any
    displayedAtividadeLicenColumns: string[] = ['atividade', 'ppd', 'acao'];
    displayedGrpSubGrpAtividadeLicenColumns: string[] = ['subGrupo', 'atividade', 'ppd', 'acao'];

    reqsTituloVinculadoSave: Array<RequerimentoTituloVinculado>;
    reqsTermoRefSave = [];
    reqsNumeroLOSave = [];
    reqTituloDisplay: string[] = ['numeroProcesso', 'numeroEmissao', 'tipoTitulo', 'tipoVinculo', 'situacao', 'legado', 'acao'];
    reqTermoRefisplay: string[] = ['numeroTermo', 'titulo', 'acao'];
    reqLODisplay: string[] = ['numeroLO', 'interessado', 'acao'];
    numeroProcessoDisplay: string[] = ['numeroProcesso', 'situacaoProcesso', 'tipoProcesso', 'acao'];
    lpTramitacaoVin = [];
    reqVinculado: Requerimento[] = [];

    parametroEntradas: any;
    parametroEntradasAut: any;
    errorStatusCalculoParametroEntrada: any;
    atividadeCalculo: AtividadeCalculoArrecadacao[];
    atividadeCalculoAut: AtividadeCalculoArrecadacao[];
    parametroCalculoList: any;
    parametroCalculoListAut: any;
    parametrosCalculoLista: any = [];
    parametrosCalculoListaAut: any = [];
    atividadeSemParametroeCaculo = false;
    atividadeSemParametroeCaculoAut = false;
    parametroEntrada: any = {}
    parametroEntradaAut: any = {}
    pisiculturaExotico = false
    regraApp = false
    calcularUpfRetornoDtoList = []
    calcularUpfRetornoDtoListAut = []
    habilitaAtividadeLicenciada = true;
    isNaoPodeEditar: boolean = false;
    isDesabilitaParametrosAtividade: boolean = false;
    termoReferenciaEiaRima = false
    licencaOperacaoVinculada = false
    lpTramitacao = false
    referenciaTitulo = true;
    mostrarOutraObjetivos = true;
    emissaoRefTitLegado = false;
    loVinLegado = false;
    erroDeCalculo = false
    edicaoAtvLicenciada = true
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoEmp;
    formularioReqLicenciamentoVinculadoDto: FormularioReqLicenciamentoEmp;
    element: ElementRef;
    arrayRegraLI = []
    numeroTituloReferente: any;
    numeroTermoReferente: any;
    numeroLO: any;
    objetivoValidado = false
    objsIsento = false
    atividadeDesc: string;
    atividadeSubGrupoDesc: string;
    atividadeAtvCompDesc: string;
    popUp = false;
    urlArquivoSimlam: string;

    renovarAutorizacoes: boolean = true;

    constructor(
        injector: Injector,
        private simlamService: SimlamService,
        private requerimentoService: RequerimentoService,
        private lancamentoTaxaService: LancamentoTaxaService,
        private lancTaxaParamCalculoService: LancTaxaParamCalculoService,
        private atividadeArrecadacaoService: AtividadeArrecadacaoService,
        private titulacaoService: TitulacaoService,
        private dominiosService: DominiosService,
        private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
        private objetivoLicenciamentoAmbientalService: ObjetivoLicenciamentoAmbientalService,
        private parametroObjetivoService: ParametroObjetivoService,
        private tipoRequerimentoService: TipoRequerimentoService,
        private tipoTitulacaoService: TipoTitulacaoService,
        private licenciamentoTitulacaoService: TitulacaoService,
        private snackBarService: SnackBarService,
        private atividadeCalculoArrecadacaoService: AtividadeCalculoArrecadacaoService,
        private atividadeParametroEntradaArrecadacaoService: AtividadeParametroEntradaArrecadacaoService,
        private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private objetivoLicenciamentoSubatividadeService: ObjetivoLicenciamentoSubatividadeService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        protected dialog: MatDialog,
        private emissaoTituloService: EmissaoTituloService,
        private reqTituloVinculadoService: ReqTituloVinculadoService,
        private termoReferenciaService: TermoReferenciaService,
        private requerimentoTitulacaoService: RequerimentoTitulacaoService,
        private activatedRoute: ActivatedRoute,
        private messageDialog: MessageDialog
    ) {
        super(injector);
        RequerimentoSteps.FORMULARIO.component = this;
    }

    async ngOnInit() {

        this.requerimento = this.requerimento || Requerimento.fromJson({});
        
        await this.getListaObjetivos();
        await this.getListaTiposAtuacao();
        await this.getListaTitulos();
        await this.getListaTiposRequerimento();
        await this.getListaTiposObjetivo();
        await this.getListaTiposTitulacao();

        if (this.requerimento.tipoProcesso) {
            if (this.requerimento.tipoProcesso.id === TipoProcessoEnum.LICENCIAMENTO_TRIFASICO_LAS ||
                this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS ||
                this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA) {
                await this.getObjetivosApresentados()
                if (this.requerimento.idTipoRequerimentoTifLas != 2) {
                    if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS
                        && this.requerimento.numeroProtocoloVinculado ||
                        this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA
                        && this.requerimento.numeroProtocoloVinculado) {
                        await this.criaOuBuscaReqLicenciamentoEmAndamento()
                    } else {
                        await this.criaOuBuscaReqLicenciamento();
                    }
                } else {
                    await this.criaOuBuscaReqLicenciamentoEmAndamento()
                }
            }
            if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                await this.preencheDadosFormReqLicenVinculo();
                console.log("OnInit - AUTORIZACAO_OUTROS_DOCUMENTOS");
            }
        } else {
            this.messageDialog.showInformation('Não foi possivel definir o tipo de processo');
        }

        if (this.isNatoDigital) {
            this.reqVinculado = [this.requerimento];
        }
        if (this.requerimento && this.requerimento.situacaoProcesso === "AGUARDANDO_CORRECAO") {
            this.requerimentoService.atualizaStatusRequerimento(this.requerimento.id, 'EM_CORRECAO')
                .subscribe(req => this.requerimento = req || this.requerimento);
        }
    }

    filtroRenovacaoComAutorizacoes(autorizacoesEncontradas) {
        this.messageDialog.showQuestion("Foi identificado que existem autorizações/documentos vinculados ao título(s), " +
            "deseja renovar também essas autorizações?", "").subscribe(async result => {
                let titulacoesDoRequerimento = await this.requerimentoService.getTitulacoes(this.requerimento).toPromise();
                for (const autorizacao of autorizacoesEncontradas) {
                    let isTitulacaoCadastrada =
                        titulacoesDoRequerimento.find(titulo => titulo.titulacao.id == autorizacao.objetivo.titulo.id);

                if(!isTitulacaoCadastrada){
                    if(result === MessageDialogResult.Yes){
                            await this.requerimentoTitulacaoService.create(RequerimentoTitulacao.fromJson({
                                titulacao: autorizacao.objetivo.titulo,
                                requerimento: this.requerimento,
                            enumOrigemTitulacao: EnumOrigemTitulacao.REQUERIMENTO}
                            )).toPromise().catch(() =>
                                this.snackBarService.showAlert('Entre em contato com a administração para configurar o Título Emitido/Alterado desse objetivo configurado.'))
                        }
                }else if(isTitulacaoCadastrada){
                    if(result === MessageDialogResult.No){
                            let paramParaDelete = []

                            if (this.parametroEntradasAut && this.parametroEntradasAut.length > 0) {
                                let lancamentoTaxa = this.parametroEntradasAut.filter(lanc => lanc.lancamentoTaxa)[0]
                                if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                                    this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                                    return
                                }
                                for (let param of this.parametroEntradasAut) {
                                    if (param.id && lancamentoTaxa) {
                                        paramParaDelete.push(param)
                                    }
                                }
                            }

                            if (this.parametroCalculoListAut && this.parametroCalculoListAut.length > 0) {
                                let lancamentoTaxa = this.parametroCalculoListAut.filter(lanc => lanc.lancamentoTaxa)[0]
                                if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                                    this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                                    return
                                }
                                for (let param of this.parametroCalculoListAut) {
                                    if (param.id && lancamentoTaxa) {
                                        paramParaDelete.push(param)
                                    }
                                }
                            }

                            if (paramParaDelete.length > 0) {
                                await this.lancamentoTaxaService.deletaTodos(paramParaDelete).toPromise()
                            }
                            this.parametroEntradasAut = []
                            this.parametroCalculoListAut = []
                            this.parametrosCalculoListaAut = []

                            await this.requerimentoTitulacaoService.delete(isTitulacaoCadastrada.id).toPromise();
                        }
                    }
                }

            if(result === MessageDialogResult.Yes){
                    this.renovarAutorizacoes = true;
            }else if(result === MessageDialogResult.No){
                    this.renovarAutorizacoes = false;
                }
            })
    }

    get tituloFormulario(): string {
        let ret: string;
        switch (this.requerimento.tipoProcesso.instanciaRequerimento) {
            case EnumInstanciaRequerimento.TERMO_REFERENCIA:
                ret = 'Formulário de Requerimento de Termo de Referência';
                break;
            case EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS:
                ret = 'Autorização/Outros Documentos';
                break;
            default:
                ret = 'Formulário de Requerimento de Licenciamento';
                break;
        }

        return ret;
    }

    get isNatoDigital(): boolean {
        return this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO
            && !!this.requerimento.numeroProtocoloVinculado;
    }

    isSomenteVisualizacaoEspecifico(): boolean {
        return this.requerimento &&
            (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO
                && (this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoCancelar
                    || this.requerimento.tipoRequerimento.isFluxoReabilitar) || this.requerimento.situacaoProcesso === 'EM_CORRECAO');
    }

    get isEmCorrecao(): boolean {
        return this.requerimento && this.requerimento.situacaoProcesso === 'EM_CORRECAO';
    }

    private resetaRegras() {
        this.termoReferenciaEiaRima = false
        this.licencaOperacaoVinculada = false
        this.lpTramitacao = false
        this.referenciaTitulo = false;
        this.mostrarOutraObjetivos = false;
        this.emissaoRefTitLegado = false;
        this.loVinLegado = false;
        this.erroDeCalculo = false
        this.edicaoAtvLicenciada = false;
        this.objetivoValidado = false
        this.isAtividadeComplementar = false

        this.verificaSeDesabilitaParametrosAtividade();
        this.habilitarEdicaoAtividadeComplementar();
    }

    private verificaObjsInseridos() {
        let todosObjs = []
        todosObjs.push(...this.objetivosPrincipaisSelecionados)
        //comentado após solicitação da analista ANA para remoção do componente [Outros objetivos] da aba 1 
        //todosObjs.push(...this.objetivosOutrosSelecionados)

        this.objsIsento = todosObjs.filter(obj => obj.objetivo.tipoTitulacao.id === 16).length === todosObjs.length;
    }

    private async aplicaRegraDeObjetivosAutorizacao() {
        if (!this.objetivoValidado) {
            this.mostrarOutraObjetivos = !this.requerimento.numeroProtocoloVinculado;
            this.habilitaAtividadeLicenciada = true
            this.isAtividadeComplementar = false
            this.termoReferenciaEiaRima = false
            this.loVinLegado = false;
            this.lpTramitacao = false
            this.referenciaTitulo = false;
            this.objetivoValidado = false;
            this.erroDeCalculo = false;
        }
    }

    private async aplicaRegraDeObjetivos() {
        this.desabilitaEdicaoAba5 = false
        await this.aplicaRegrasDeObjetivosEspecificos()
        if (!this.objetivoValidado) {
            if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de LP - Licença Prévia'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de LI - Licença de Instalação'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de LO - Licença de Operação'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização Queima Controlada'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização de Desmate'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização de Picada'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização de Coleta e Captura de Fauna Silvestre'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização diversa'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Solicitação de para unidade volante'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Estudo preliminar/Laudos'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização de licença de pesca especial'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização Queima Controlada'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização de Desmate'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização Picada'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização de Coleta e Captura de Fauna Silvestre'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização diversa'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização de teste'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Solicitação de para unidade volante'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Estudo preliminar/Laudos'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização de licença de pesca especial'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Pedido de dispensa de EIA/RIMA'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Arquivamento de processo'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Suspensão do processo'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Suspensão da atividade'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Desativação da atividade'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Desmobilização da atividade'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Encerramento total da atividade total'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Alteração de documentos e responsáveis'.toLowerCase()))
                || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('LP - Licença Prévia de ampliação'.toLowerCase()))) {
                this.habilitaAtividadeLicenciada = false //RN001
                if (!this.objsIsento) {
                    await this.preencherParametrosDeEntradaECalculoVinculo()
                }
                this.isAtividadeComplementar = false //RN002
                this.referenciaTitulo = true //RN003
                await this.buscaReferenciaTituloVinculado()
                this.mostrarOutraObjetivos = true //RN032
                if(this.objetivosPrincipaisSelecionados.find(obj =>
                    obj.objetivo.objetivo.descricao.toLowerCase().includes('LP - Licença Prévia de ampliação'.toLowerCase()))){
                    this.desabilitaEdicaoAba5 = false
                }else{
                    this.desabilitaEdicaoAba5 = true
                }
                if (this.requerimento.tipoRequerimento.isFluxoAmpliacao){
                    this.termoReferenciaEiaRima = true
                    this.isAtividadeComplementar = true
                    this.habilitaAtividadeLicenciada = true
                }
            }
        }
    }

    private async aplicaRegrasDeObjetivosEspecificos() {
        if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 3)
            || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 2)) {
            if (!this.objsIsento) {
                await this.preencherParametrosDeEntradaECalculoVinculo()
            }
            this.habilitaAtividadeLicenciada = false //RN001
            this.isAtividadeComplementar = false //RN002
            this.referenciaTitulo = true //RN003
            await this.buscaReferenciaTituloVinculado()
            this.termoReferenciaEiaRima = false //RN024
            this.mostrarOutraObjetivos = true //RN032
            this.isAtividadeComplementar = true //RN031
            this.objetivoValidado = true
        } else if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 8) &&
            this.objetivosPrincipaisSelecionados.length === 1  && !this.requerimento.tipoRequerimento.isFluxoAmpliacao ||
            this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 8) &&
            this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 6)) {
            this.referenciaTitulo = true //RN003
            await this.buscaReferenciaTituloVinculado()
            if (!this.objsIsento) {
                await this.preencherParametrosDeEntradaECalculoVinculo()
            }
            this.habilitaAtividadeLicenciada = true
            this.edicaoAtvLicenciada = false //RN015
            this.isAtividadeComplementar = true //RN031
            this.mostrarOutraObjetivos = true //RN032
            this.objetivoValidado = true
        } else if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 6) &&
            this.objetivosPrincipaisSelecionados.length === 1 ||
            this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 6) &&
            this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 7)) {
            this.edicaoAtvLicenciada = false //RN015
            this.habilitaAtividadeLicenciada = true
            if (!this.objsIsento) {
                await this.preencherParametrosDeEntradaECalculoVinculo()
            }
            this.referenciaTitulo = true //RN003
            await this.buscaReferenciaTituloVinculado()
            this.isAtividadeComplementar = true //RN031
            this.mostrarOutraObjetivos = true //RN032
            this.objetivoValidado = true
        } else if (this.objetivosPrincipaisSelecionados.length === 1
            && this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 7)) {
            this.habilitaAtividadeLicenciada = false //RN001
            this.isAtividadeComplementar = false //RN002
            this.referenciaTitulo = true //RN003
            this.mostrarOutraObjetivos = true //RN032
            this.objetivoValidado = true
        } else if (this.objetivosPrincipaisSelecionados.length === 1
            && this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Alteração de documentos e responsáveis'.toLowerCase()))) {
            this.habilitaAtividadeLicenciada = false
            this.isAtividadeComplementar = false //RN002
            this.referenciaTitulo = false //RN022
            this.mostrarOutraObjetivos = false //RN023
            this.objetivoValidado = true
            if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA && this.requerimento.numeroProtocoloVinculado) {
                this.desabilitaEdicaoAba5 = true
            }
        } else if (this.objetivosPrincipaisSelecionados.length === 1 &&
            this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('LOPM - Licença de Operação de Pesquisa Mineral de ampliação'.toLowerCase()))) {
            this.edicaoAtvLicenciada = false //RN015
            this.referenciaTitulo = true //RN003
            await this.buscaReferenciaTituloVinculado()
            if (!this.objsIsento) {
                await this.preencherParametrosDeEntradaECalculoVinculo()
            }
            this.isAtividadeComplementar = true //RN031
            this.mostrarOutraObjetivos = true //RN032
            this.objetivoValidado = true
            this.habilitaAtividadeLicenciada = true //RN015
        } else if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação LOPM - Licença de Operação de Pesquisa Mineral'.toLowerCase()))) {
            this.edicaoAtvLicenciada = false //RN001
            this.habilitaAtividadeLicenciada = false
            if (!this.objsIsento) {
                await this.preencherParametrosDeEntradaECalculoVinculo()
            }
            this.isAtividadeComplementar = false //RN002
            this.referenciaTitulo = true //RN003
            await this.buscaReferenciaTituloVinculado()
            this.objetivoValidado = true
        }
    }

    async buscaReferenciaTituloVinculado() {
        if (this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento) {
            await this.reqTituloVinculadoService
                .readByReqTituloVinculado(this.requerimentoLicenciamentoVinculado.requerimento.id, '')
                .toPromise().then(async result => {
                    if (result.length && result.length > 0) {
                        let tE = result.filter(rt => rt.tipoVinculoRequerimento === 'TITULO_EXISTENTE');
                        for (let rt of tE) {
                            rt.vinculado = true;
                        }

                        if (!this.reqsTituloVinculadoSave.find(reT => reT.id === tE[0].id)) {
                            this.reqsTituloVinculadoSave.push(tE[0]);
                        }
                        this.reqsTituloVinculadoSave = [...this.reqsTituloVinculadoSave];
                    }
                })

        }
    }

    private async criaOuBuscaReqLicenciamentoEmAndamento() {
        this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).subscribe(async resultado => {
            if (!resultado) {
                let reqLicenciamento = new RequerimentoLicenciamento()
                reqLicenciamento.requerimento = this.requerimento
                this.requerimentoLicenciamentoService.create(reqLicenciamento).subscribe(async reqLicen => {
                    await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                        this.formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                        this.requerimentoLicenciamentoVinculado = this.formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                        this.requerimentoLicenciamento = reqLicen
                        await this.passaDadosDoVinculoReqLic();
                    })
                })
            } else {
                await this.passaDadosDoVinculoReqLic();
                await this.preencheDadosFormReqLicenVinculo();
            }

        })
    }

    private async preencheDadosFormReqLicenVinculo() {
        if (this.formularioReqLicenciamentoDto && this.formularioReqLicenciamentoDto.requerimentoLicenciamento !== null) {

            this.formularioReqLicenciamentoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise();
            this.requerimentoLicenciamento = this.formularioReqLicenciamentoDto.requerimentoLicenciamento;
            if (this.requerimento.numeroProtocoloVinculado) {
                let requerimentoVinculado = await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise()
                this.formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(requerimentoVinculado[0].id).toPromise()
                this.requerimentoLicenciamentoVinculado = this.formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
            }
            await this.preencheTabelaObjetivo(true)
            if (this.objetivosPrincipaisSelecionados.length === 0) {
                this.resetaRegras()
            }

            if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA
                && this.requerimento.numeroProtocoloVinculado) {
                this.habilitaAtividadeLicenciada = false;
                this.desabilitaEdicaoAba5 = true;
            }

            if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                this.habilitaAtividadeLicenciada = true;
            }

            if (this.isNatoDigital) {
                this.aplicaRegrasSuimisNatoDigital();
            }

            if (this.requerimentoLicenciamento.idAtividade) {
                if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA &&
                    this.objetivosPrincipaisSelecionados.length === 0) {
                    await this.atividadeSelecionadaVinculadaSemObjetivo()
                } else {
                    this.preencheTabelaAtividadeLicenciada()
                }
                await this.obterAtividadeCalculoParametroEntrada(true)
                await this.preencherAtividadeComplementar()
                if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                    await this.preencherParametrosDeEntradaECalculo()
                }
            }
        }
    }

    async aplicaRegrasSuimisNatoDigital() {
        if (this.requerimento.tipoRequerimento.isFluxoRenovar) {
            //Aplica regras nato digital - RENOVACAO, no trifasico las sem objetivo selecionado.
            this.habilitaAtividadeLicenciada = false //RN001
            this.isAtividadeComplementar = false //RN002
            this.referenciaTitulo = true //RN003
            await this.buscaReferenciaTituloVinculado()
            this.mostrarOutraObjetivos = true //RN032
            this.desabilitaEdicaoAba5 = true
        } else if (this.requerimento.tipoRequerimento.isFluxoAmpliacao) {
            //Aplica regras nato digital - AMPLIACAO, no trifasico las sem objetivo selecionado.
            this.isAtividadeComplementar = false
            this.referenciaTitulo = true
            await this.buscaReferenciaTituloVinculado()
            this.mostrarOutraObjetivos = true
            this.termoReferenciaEiaRima = true
        }
    }

    private async passaDadosDoVinculoReqLic() {
        //await this.requerimentoLicenciamentoService.passaDadosReqVinculado(this.requerimento.id, this.activatedRoute.snapshot.url[3].path).toPromise()
        this.formularioReqLicenciamentoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise();
        this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
        //this.requerimentoLicenciamento = this.formularioReqLicenciamentoDto.requerimentoLicenciamento
        this.resetaRegras()
        await this.atividadeSelecionadaVinculadaSemObjetivo()
        await this.atividadeComplementarVinculada()
    }

    // private async atividadeSelecionadaVinculadaSemObjetivo() {
    //     let atvCalc = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(this.requerimentoLicenciamento.idAtividade).toPromise()
    //     this.atividadeSelecionadaTable.push(atvCalc[0])
    //     this.atividadeSelecionadaTable = this.atividadeSelecionadaTable.slice()
    //     this.atividade = this.atividadeSelecionadaTable[0].atividade
    //     await this.obterAtividadeCalculoParametroEntrada()
    // }

    private async atividadeSelecionadaVinculadaSemObjetivo() {
        const atvCalc = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(
            this.requerimentoLicenciamento.idAtividade).toPromise();

        // Check if the object already exists in the array
        const existingObj = this.atividadeSelecionadaTable.some(obj => obj.atividade.id === atvCalc[0].atividade.id);

        if (!existingObj) {
            this.atividadeSelecionadaTable.push(atvCalc[0]);
        }

        this.atividadeSelecionadaTable = this.atividadeSelecionadaTable.slice();
        this.atividade = this.atividadeSelecionadaTable[0].atividade;
        await this.obterAtividadeCalculoParametroEntrada();
    }

    private async atividadeComplementarVinculada() {
        this.objetivoLicenciamentoSubatividadeService
            .getRequerimentoLicenciamentoPorId(this.requerimentoLicenciamento.id).toPromise()
            .then(async result => {
                if (result) {
                    let atvCalc = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(result.idAtividadeComplementar).toPromise()
                    this.grupoSubGrupoSelecionadaTable.push(atvCalc[0])
                    this.grupoSubGrupoSelecionadaTable = this.grupoSubGrupoSelecionadaTable.slice()
                }
            })
    }

    private async criaOuBuscaReqLicenciamento() {
        this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).subscribe(async resultado => {
            if (!resultado) {
                let reqLicenciamento = new RequerimentoLicenciamento()
                reqLicenciamento.requerimento = this.requerimento
                this.requerimentoLicenciamentoService.create(reqLicenciamento).subscribe(async reqLic => {
                    this.requerimentoLicenciamento = reqLic
                    await this.preencheTabelaObjetivo()
                    await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise().then(formulReq => {
                        this.formularioReqLicenciamentoDto = formulReq
                        this.requerimentoLicenciamento = this.formularioReqLicenciamentoDto.requerimentoLicenciamento
                    }).catch(() => {
                        this.snackBarService.showError('Ocorreu um erro ao buscar o Formulário do Requerimento.');
                    })
                }, () => {
                    this.snackBarService.showError('Ocorreu um erro ao criar o requerimento.');
                })
            } else {
                await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise().then(async formulReq => {
                    this.formularioReqLicenciamentoDto = formulReq
                }).catch(() => {
                    this.snackBarService.showError('Ocorreu um erro ao processar dados do requerimento.');
                })
                this.requerimentoLicenciamento = this.formularioReqLicenciamentoDto.requerimentoLicenciamento
                await this.preencheTabelaObjetivo(true)
                if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                    if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                        await this.preencherParametrosDeEntradaECalculo()
                    }
                }
                if (this.requerimentoLicenciamento.idAtividade) {
                    this.preencheTabelaAtividadeLicenciada()
                    await this.obterAtividadeCalculoParametroEntrada(true)
                    await this.preencherAtividadeComplementar()
                    if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                        await this.preencherParametrosDeEntradaECalculo()
                    }
                }
            }
        })
    }

    async preencherParametrosDeEntradaECalculo() {
        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
            this.parametroEntradas = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramEntrada =>
                paramEntrada.tipoParametro === 'ENTRADA' && !paramEntrada.autorizacao);

            this.parametroCalculoList = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramCalc => paramCalc.tipoParametro === 'CALCULO'
                && !paramCalc.autorizacao);

            this.parametroEntradasAut = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramEntrada =>
                paramEntrada.tipoParametro === 'ENTRADA' && paramEntrada.autorizacao);

            this.parametroCalculoListAut = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramCalc => paramCalc.tipoParametro === 'CALCULO'
                && paramCalc.autorizacao);

            const key = 'labelFormulario';

            this.parametroEntradas = [...new Map(this.parametroEntradas.map(item =>
                [item[key], item])).values()];

            this.parametroCalculoList = [...new Map(this.parametroCalculoList.map(item =>
                [item[key], item])).values()];

            if (this.parametroEntradas.length > 0) {
                this.parametroEntradas.forEach(async paramEntrada => {
                    const event = {
                        target: {
                            value: paramEntrada.valor
                        }
                    };
                    await this.onKey(event, paramEntrada, false, true);
                });
            }


            if (this.parametroCalculoList.length > 0) {
                this.parametroCalculoList.forEach(paramCalc => {
                    const event = {
                        target: {
                            value: paramCalc.valor
                        }
                    };
                    this.pegarValorDeParamCalc(event, paramCalc);
                });
            }

            if (this.parametroEntradasAut.length > 0) {
                this.parametroEntradasAut.forEach(async paramEntrada => {
                    const event = {
                        target: {
                            value: paramEntrada.valor
                        }
                    };
                    await this.onKey(event, paramEntrada, false, true);
                });
            }


            if (this.parametroCalculoListAut.length > 0) {
                this.parametroCalculoListAut.forEach(paramCalc => {
                    const event = {
                        target: {
                            value: paramCalc.valor
                        }
                    };
                    this.pegarValorDeParamCalcAut(event, paramCalc);
                });
            }

            if (this.parametroEntradasAut.length === 0 && this.parametroCalculoListAut.length === 0) {
                await this.obterAtividadeCalculoParametroEntradaAut()
            }
        }
    }

    async pesquisaAtividade() {
        if (this.atividadeDesc.trim().length === 0) {
            this.atividadeLicenciadaEncontrado = this.atividadeLicenciada;
            return;
        } else if (this.atividadeDesc.trim().length > 0) {
            this.atividadeLicenciadaEncontrado = this.atividadeLicenciada.filter(atv => atv.atividade.descricao.normalize("NFD").toLowerCase().includes(this.atividadeDesc.normalize("NFD").toLowerCase()))
        }
    }

    async preencherParametrosDeEntradaECalculoVinculo() {
        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length === 0) {
            this.parametroEntradas = this.formularioReqLicenciamentoVinculadoDto.lancamentoTaxaParamCalculoList.filter(paramEntrada =>
                paramEntrada.tipoParametro === 'ENTRADA' && !paramEntrada.autorizacao);

            this.parametroCalculoList = this.formularioReqLicenciamentoVinculadoDto.lancamentoTaxaParamCalculoList.filter(paramCalc => paramCalc.tipoParametro === 'CALCULO'
                && !paramCalc.autorizacao);

            const key = 'labelFormulario';

            this.parametroEntradas = [...new Map(this.parametroEntradas.map(item =>
                [item[key], item])).values()];

            this.parametroCalculoList = [...new Map(this.parametroCalculoList.map(item =>
                [item[key], item])).values()];

            if (this.parametroEntradas.length > 0) {
                this.parametroEntradas.forEach(async paramEntrada => {
                    const event = {
                        target: {
                            value: paramEntrada.valor
                        }
                    };
                    await this.onKey(event, paramEntrada, false, true);
                });
            }


            if (this.parametroCalculoList.length > 0) {
                this.parametroCalculoList.forEach(paramCalc => {
                    const event = {
                        target: {
                            value: paramCalc.valor
                        }
                    };
                    this.pegarValorDeParamCalc(event, paramCalc);
                });
            }
        }
    }

    private async getObjetivosApresentados() {
        let objs;
        if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO ||
            this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA ||
            this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) { // Licenciamento Empreendimentos
            await this.objetivoParametrizacaoObjetivoService.getByParametrizacaoObjetivo(this.requerimento.idParametroObjetivo).toPromise().then(paramObjs => {
                objs = paramObjs
            });
        }

        this.objetivos = objs.map(obj => ({
            objetivo: {
                objetivo: this.objetivos.find(o => o.id === obj.idObjetivoParametrizacao),
                tipoObjetivo: this.tiposObjetivo.find(o => o.id === obj.idTipoObjetivo),
                titulo: this.titulos.find(o => o.id === obj.idTituloEmitidoAlterado),
                tipoTitulacao: this.tiposTitulacao.find(o => o.id === obj.idArrecadacao),
                tipoRequerimento: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento),
                ordemSelecao: obj.ordemSelecao,
                isSelecaoExclusiva: obj.isSelecaoExclusiva,
                desabilitado: false
            },
            vinculo: obj
        }));

        //TODO deverá ser feito refactor nos comparativos abaixo, esta pegando valor fixo
        if (!this.requerimento.numeroProtocoloVinculado ||
            this.requerimento.tipoProcesso.instanciaRequerimento != EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO) {
            this.objetivos = this.objetivos.filter(obj => obj.objetivo.tipoRequerimento.id == this.requerimento.tipoRequerimento.id);
        }

        if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
            if (!this.requerimento.numeroProtocoloVinculado) {
                this.objetivosOutros = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id == 1)
            } else {
                this.objetivosPrincipais = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id == 1)
            }
        } else {
            this.objetivosPrincipais = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id == 1)
            this.objetivosOutros = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id == 2)

            //Aplicando filtros de objetivos nato digital
            if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO && this.isNatoDigital) {
                this.objetivosPrincipais =
                    this.objetivosPrincipais.filter(item => item.objetivo.tipoRequerimento.id == this.requerimento.tipoRequerimento.id)

                this.objetivosOutros =
                    this.objetivosOutros.filter(item => item.objetivo.tipoRequerimento.id == this.requerimento.tipoRequerimento.id)
            }

            this.regraDeDesabilitarHabilitarObjetivos()
            if (this.requerimento.idTipoRequerimentoTifLas != 2) {
                this.iniciaRegrasLI()
            }
        }
    }

    private regraDeDesabilitarHabilitarObjetivos() {
        if (this.objetivosPrincipaisSelecionados.length === 0) {
            let objetivosDesabilitdos = this.objetivosPrincipais.filter(ob => ob.objetivo.ordemSelecao > 1)

            for (let obj of objetivosDesabilitdos) {
                let posicaoArray = this.objetivosPrincipais.indexOf(obj)
                this.objetivosPrincipais.splice(posicaoArray, 1)
                obj.objetivo.desabilitado = true
                this.objetivosPrincipais.push(obj)
            }
        } else {
            let objetivosDesabilitdos = this.objetivosPrincipais.filter(ob => ob.objetivo.ordemSelecao > 1)

            for (let obj of objetivosDesabilitdos) {
                let posicaoArray = this.objetivosPrincipais.indexOf(obj)
                this.objetivosPrincipais.splice(posicaoArray, 1)
                obj.objetivo.desabilitado = false
                this.objetivosPrincipais.push(obj)
            }
        }

        this.objetivosPrincipais = this.objetivosPrincipais.sort((a, b) =>
            (a.objetivo.ordemSelecao != null ? a.objetivo.ordemSelecao : Infinity) - (b.objetivo.ordemSelecao != null ? b.objetivo.ordemSelecao : Infinity));

        this.objetivosPrincipais = this.objetivosPrincipais.slice()
    }

    private iniciaRegrasLI() {
        if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
            /// Trecho abaixo comentado pois foi validado com analista que para tipos de processos diferente de AUTORIZAÇÕES/OUTROS DOCUMENTOS
            // os objetivos não será filtrado por algum objetivo com

            // let autorizacaoDesmate = this.objetivosOutros.find(outros => outros.objetivo.objetivo.descricao === 'Autorização de Desmate')
            // if (autorizacaoDesmate) {
            //     let posicaoAutorizaDesmate = this.objetivosOutros.indexOf(autorizacaoDesmate)
            //     this.objetivosOutros.splice(posicaoAutorizaDesmate, 1)
            //     this.arrayRegraLI.push(autorizacaoDesmate)
            // }

            // let autorizacaoQueima = this.objetivosOutros.find(outros => outros.objetivo.objetivo.descricao === 'Autorização Queima Controlada')
            // if (autorizacaoQueima) {
            //     let posicaoautorizacaoQueima = this.objetivosOutros.indexOf(autorizacaoQueima)
            //     this.objetivosOutros.splice(posicaoautorizacaoQueima, 1)
            //     this.arrayRegraLI.push(autorizacaoQueima)
            // }

            this.objetivosOutros = this.objetivosOutros.slice()
        }

    }

    private async getListaTiposAtuacao() {
        await this.dominiosService.getDominio("TIPO_ATUACAO").toPromise().then(tiposAtuacao => {
            this.tiposAtuacao = tiposAtuacao
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista tipo Atuação');
        });
    }

    private async getListaTitulos() {
        await this.licenciamentoTitulacaoService.getAll().toPromise().then(titulos => {
            this.titulos = titulos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar os títulos');
        });
    }

    private async getListaTiposRequerimento() {
        await this.tipoRequerimentoService.getAll().toPromise().then(tiposReq => {
            this.tiposRequerimento = tiposReq
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar os títulos');
        });
    }

    private async getListaObjetivos() {
        await this.objetivoLicenciamentoAmbientalService.getAll().toPromise().then(objs => {
            this.objetivos = objs
            this.todosObjetivos = this.objetivos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista de objetivos');
        });
    }

    private async getListaTiposObjetivo() {
        await this.dominiosService.getDominio("TIPO_OBJETIVO").toPromise().then(tiposObjetivos => {
            this.tiposObjetivo = tiposObjetivos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista tipos objetivos');
        });
    }

    private async getListaTiposTitulacao() {
        await this.tipoTitulacaoService.getAll().toPromise().then(tipoTitulacao => {
            this.tiposTitulacao = tipoTitulacao
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista de tipos titulação');
        });
    }

    validarRegrasNovo(erros: ErrosAbaRequerimento) {

        if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.includes('Prévia - EIA (RIMA)'))
            && this.reqsTermoRefSave.length === 0) {
            erros.push('Referencie um Termo EIA/RIMA ao Requerimento')
        }

        if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.includes('LO - Licença de Operação - Vinculada'))
            && this.reqsNumeroLOSave.length === 0) {
            erros.push('Vincule uma LO ao Requerimento.')
        }

        return erros
    }

    async validarAba(erros: ErrosAbaRequerimento) {
        this.verificaObjsInseridos()

        if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS ||
            (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS &&
                this.requerimento.numeroProtocoloVinculado !== null)) {
            if (this.atividadeCalculo && this.atividadeCalculo.length > 0 && !this.objsIsento) {
                await this.iniciarCalculo()
                if (this.erroDeCalculo && !this.popUp) {
                    erros.push('Sua atividade não é passível de licenciamento');
                } else if (this.popUp) {
                    erros.push('PopUp')
                }
            } else {
                if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA && !this.requerimento.numeroProtocoloVinculado) {
                    await this.iniciarCalculo()
                    if (this.erroDeCalculo && !this.popUp) {
                        erros.push('Sua atividade não é passível de licenciamento');
                    } else if (this.popUp) {
                        erros.push('PopUp')
                    }
                } else {
                    if (this.objetivosPrincipaisSelecionados.length > 0) {
                        let atividadesCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(this.atividadeSelecionadaTable[0].atividade.id).toPromise();
                        this.atividadeCalculo = atividadesCalculo.filter(atvCalc => atvCalc.tipoTitulacao.id === this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id)
                        if (this.atividadeCalculo && this.atividadeCalculo.length > 0 && !this.objsIsento) {
                            await this.iniciarCalculo()
                            if (this.erroDeCalculo && !this.popUp) {
                                erros.push('Sua atividade não é passível de licenciamento');
                            } else if (this.popUp) {
                                erros.push('PopUp')
                            }
                        } else {
                            if (!this.objsIsento) {
                                erros.push('Não existe licenças para a ser licenciada, verifica os campos do formulário')
                            }
                        }
                    } else {
                        erros.push('Verifique o objetivo selecionado.')
                    }
                }

            }
        } else {
            if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                const autorizacoes = [];
                this.atividadeCalculoAut = [];
                let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(this.atividadeSelecionadaTable[0].atividade.id).toPromise();
                autorizacoes.push(...atividadeCalculo);
                /**
                 * Para autorização vamos pegar a posição [0] pois terá somente uma atividade selecionada na tabel
                 */
                let autorizacao = autorizacoes.find(aut => aut.atividade.id === this.atividadeSelecionadaTable[0].atividade.id);
                if (autorizacao) {
                    this.atividadeCalculoAut.push(autorizacao);
                } else {
                    this.snackBarService.showAlert('Houve um problema nas configurações, entre em contato com a equipe da SEMA.')
                    return
                }
            }
            if (this.atividadeCalculoAut && this.atividadeCalculoAut.length > 0 && !this.objsIsento) {
                await this.iniciarCalculo();
                if (this.erroDeCalculo && !this.popUp) {
                    erros.push('Sua atividade não é passível de licenciamento');
                } else if (this.popUp) {
                    erros.push('PopUp')
                }
            } else {
                if (!this.objsIsento) {
                    erros.push('Não existe licenças para a ser licenciada, verifica os campos do formulário')
                }
            }
        }

        erros = this.validaRegrasDeErrosAba(erros)

        // Validar Regras de Aba Tifasico LAS Novo tipo 1 e 3
        if (this.requerimento.idTipoRequerimentoTifLas == 1 ||
            this.requerimento.idTipoRequerimentoTifLas == 3) {
            erros = this.validarRegrasNovo(erros)
        }

        if (erros.length === 0) {
            if (!await this.salvar()) {
                erros.push('Ocorreu um erro ao processar os dados do formulário. Tente novamente mais tarde.')
            }

            this.objetivoLicenciamento = this.objetivosPrincipaisSelecionados
            if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS &&
                !this.requerimento.numeroProtocoloVinculado) {
                this.objetivoLicenciamento = this.objetivosPrincipaisSelecionados
            }
            if (erros.length === 0) {
                return await Promise.all([]).then(() => Promise.resolve());
            }
        }
        return Promise.resolve();
    }

    validaRegrasDeErrosAba(erros) {
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA ||
            this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS &&
            this.requerimento.numeroProtocoloVinculado) {
            if (this.objetivosPrincipaisSelecionados.length === 0) {
                erros.push('Selecione o(s) objetivo(s) ao requerimento')
            }
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS &&
            !this.requerimento.numeroProtocoloVinculado) {
            if (this.objetivosPrincipaisSelecionados.length === 0) {
                erros.push('Selecione o(s) objetivo(s) ao requerimento')
            }
        } else {
            if (this.objetivosPrincipaisSelecionados.length === 0) {
                erros.push('Selecione o(s) objetivo(s) ao requerimento')
            }
            if (this.atividadeSelecionadaTable.length === 0) {
                erros.push('Selecione uma atividade a ser licenciada')
            }

            if (this.habilitaAtividadeLicenciada && this.calcularUpfRetornoDtoList.length === 0) {
                erros.push('Não existe licença a ser calculada')
            }

            if (this.termoReferenciaEiaRima && this.reqsTermoRefSave.length === 0 &&
                !(this.isNatoDigital && this.requerimento.tipoRequerimento.isFluxoAmpliacao)) {
                erros.push('Preencha o Termo de Referência de (EIA/RIMA)')
            }

            if (this.licencaOperacaoVinculada && this.reqsNumeroLOSave.length === 0) {
                erros.push('Preencha o LO – Licença de Operação Vinculada')
            }

            // if (this.termoReferenciaEiaRima) {
            //     if (this.lpTramitacao && this.lpTramitacaoVin.length === 0) {
            //         erros.push('Preencha o LP - Licença Prévia em tramitação')
            //     }
            // }
        }

        return erros
    }

    selecionarObjetivo(obs: any) {
        this.objetivoSelecionado = obs
    }

    selecionarOutroObjetivo(obs: any) {
        this.objetivoOutroSelecionado = obs
    }

    async selecionarObjetivoNaTable(adicionando) {
        let regra1 = this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.ordemSelecao === this.objetivoSelecionado.objetivo.ordemSelecao - 1);

        if ((this.objetivosPrincipaisSelecionados.some(obj => obj.objetivo.objetivo.id === this.objetivoSelecionado.objetivo.objetivo.id)
            && (this.reqsTituloVinculadoSave && this.reqsTituloVinculadoSave.length > 0))
            || this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.id === this.objetivoSelecionado.objetivo.objetivo.id)) {
            this.snackBarService.showAlert('Objetivo já adicionado.');
        } else if (this.objetivosPrincipaisSelecionados.length > 0 && this.objetivoSelecionado.objetivo.isSelecaoExclusiva) {
            this.snackBarService.showAlert('Existe um objetivo exclusivo já selecionado ou objetivo escolhido é exclusivo.');
        } else if (this.objetivosPrincipaisSelecionados.length == 1 && this.objetivosPrincipaisSelecionados.filter(obj => obj.objetivo.isSelecaoExclusiva).length == 1) {
            this.snackBarService.showAlert('Objetivo exclusivo já selecionado.');
        } else if (this.objetivoSelecionado.objetivo.ordemSelecao > 1 && !regra1) {
            this.snackBarService.showAlert('Selecione o objetivo por ordem de seleção.');
        } else {
            let passou = await this.criaObjetivoLicenciamento(this.objetivoSelecionado)
            if (passou) {
                await this.preencheTabelaObjetivo(false, adicionando)
            }
        }
        this.preencheComboAtividadeLicenciadaSubGrupo();
    }

    async selecionarOutroObjetivoNaTable() {
        if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.id === this.objetivoOutroSelecionado.objetivo.objetivo.id)) {
            this.snackBarService.showAlert('Objetivo já selecionado.');
        } else if (this.objetivosPrincipaisSelecionados.length > 0 && this.objetivoOutroSelecionado.objetivo.isSelecaoExclusiva) {
            this.snackBarService.showAlert('Existe um objetivo exclusivo já selecionado ou objetivo escolhido é exclusivo.');
        } else if (this.objetivosPrincipaisSelecionados.length == 1 && this.objetivosPrincipaisSelecionados.filter(obj => obj.objetivo.isSelecaoExclusiva).length == 1) {
            this.snackBarService.showAlert('Objetivo exclusivo já selecionado.');
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS &&
            !this.requerimento.numeroProtocoloVinculado && this.objetivosPrincipaisSelecionados.length > 0) {
            this.snackBarService.showAlert('Só é permitido apenas um objetivo por requerimento.');
        } else {
            let passou = await this.criaObjetivoLicenciamento(this.objetivoOutroSelecionado)
            if (passou) {
                await this.preencheTabelaObjetivo()
                await this.buscarAtividadesDosObjetivos()
            }
        }
    }

    private async preencherParametrosAutorizacao(inicio) {
        const autorizacoes = []
        if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
            let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculosPorIdTipoTitulacao(this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id).toPromise();
            //let objetivosOutros = this.objetivosPrincipaisSelecionados.filter(x => x.objetivo && x.objetivo.tipoTitulacao && x.objetivo.tipoTitulacao.id)
            //let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(this.atividade.id).toPromise();
            autorizacoes.push(...atividadeCalculo);
            let autorizacao = autorizacoes.find(aut => aut.tipoTitulacao.id === this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id);
            if (autorizacao) {
                this.atividadeAut = autorizacao.atividade
                this.atividadeCalculo = autorizacao;
                if (this.atividadeAut)
                    await this.obterAtividadeCalculoParametroEntradaAut(inicio);
                else
                    await this.obterAtividadeCalculoParametroEntrada();
            } else {
                this.snackBarService.showAlert('Houve um problema nas configurações, entre em contato com a equipe da SEMA.')
                return
            }
        }
    }

    private async preencherParametrosAtividadeAutorizacao() {
        const autorizacoes = [];
        let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(this.atividadeSelecionadaTable[0].atividade.id).toPromise()
        autorizacoes.push(...atividadeCalculo);
        //let autorizacao = autorizacoes.find(aut => aut.atividade.descricao.includes(this.objetivosPrincipaisSelecionados[0].objetivo.objetivo.descricao))
        let autorizacao = autorizacoes.find(aut => aut.tipoTitulacao.id === this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id);
        if (autorizacao) {
            this.atividadeAut = autorizacao.atividade;
            this.atividadeCalculo = autorizacao;
            if (this.atividadeAut)
                await this.obterAtividadeCalculoParametroEntradaAut(false);
            else
                await this.obterAtividadeCalculoParametroEntrada();
        } else {
            this.snackBarService.showAlert('Houve um problema nas configurações, entre em contato com a equipe da SEMA.')
            return
        }
    }

    private verificaTitulos() {
        // Verifica se objetivo é EIA/RIMA
        if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo.descricao.includes('Prévia - EIA (RIMA)'))) {
            this.termoReferenciaEiaRima = true
            this.carregarTermoRef()
        } else {
            this.termoReferenciaEiaRima = false
        }

        if (!this.isFluxoEmitir() || this.requerimento.tipoProcesso.instanciaRequerimento === 'AUTORIZACAO_OUTROS_DOCUMENTOS') {
            this.referenciaTitulo = true
            this.carregarReqTituloVinculado('TITULO_EXISTENTE')
        } else {
            this.referenciaTitulo = false;
        }
    }

    public isFluxoEmitir(): boolean {
        return this.requerimento.tipoRequerimento.isFluxoEmitir;
        //return !this.referenciaTitulo && (this.lpTramitacao || this.isNatoDigital)
    }

    private async criaObjetivoLicenciamento(objetivo) {
        let passou = false;
        let objetivoLicenciamento = new ObjetivoLicenciamento()
        objetivoLicenciamento.requerimentoLicenciamento = this.requerimentoLicenciamento
        objetivoLicenciamento.descricaoObjetivo = objetivo.objetivo.objetivo.descricao
        objetivoLicenciamento.idObjetivoLicenciamentoAmbiental = objetivo.objetivo.objetivo.id
        objetivoLicenciamento.idTipoObjetivo = objetivo.objetivo.tipoObjetivo.id

        await this.requerimentoTitulacaoService.create(RequerimentoTitulacao.fromJson(
            {
                titulacao: objetivo.objetivo.titulo,
                requerimento: this.requerimento,
                enumOrigemTitulacao: EnumOrigemTitulacao.REQUERIMENTO
            }
        )).toPromise().then(async () => await this.objetivoLicenciamentoService.create(objetivoLicenciamento).toPromise().then(() => passou = true))
            .catch(() => this.snackBarService.showAlert('Entre em contato com a administração para configurar o Título Emitido/Alterado desse objetivo configurado.'))

        return passou;

    }

    private async preencheTabelaObjetivo(inicio = false, adicionando = false) {
        await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id)
            .toPromise()
            .then(async lista => {
                this.objetivosPrincipaisSelecionados = []
                this.objetivosPrincipaisSelecionados = []
                for (let obj of lista) {
                    if (this.requerimento && this.requerimento.tipoProcesso) {
                        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS &&
                            !this.requerimento.numeroProtocoloVinculado) {
                            this.objetivosPrincipaisSelecionados.push(this.objetivos.find(objs => objs && objs.objetivo&& objs.objetivo.objetivo && objs.objetivo.objetivo.id === obj.idObjetivoLicenciamentoAmbiental))
                            this.objetivosPrincipaisSelecionados = this.objetivosPrincipaisSelecionados.slice();
                            await this.buscarAtividadesDosObjetivos();
                        } else {
                            if (obj.idTipoObjetivo == 1) {
                                let objetivoPrincipal;
                                if (this.requerimentoLicenciamentoVinculado.id) {
                                    objetivoPrincipal = this.objetivosDeVinculo.find(objs => objs.objetivo&& objs.objetivo.objetivo && objs.objetivo.objetivo.id === obj.idObjetivoLicenciamentoAmbiental)
                                    if (objetivoPrincipal) {
                                        this.objetivosPrincipaisSelecionados.push(objetivoPrincipal)
                                    }
                                }
                                objetivoPrincipal = this.objetivos.find(objs => objs.objetivo&& objs.objetivo.objetivo &&
                                    objs.objetivo.objetivo.id === obj.idObjetivoLicenciamentoAmbiental && objs.objetivo.tipoRequerimento.id == this.requerimento.tipoRequerimento.id)
                                if (objetivoPrincipal) {
                                    this.objetivosPrincipaisSelecionados.push(objetivoPrincipal)
                                    this.objetivosPrincipaisSelecionados = this.objetivosPrincipaisSelecionados.slice();
                                    this.validaRegras(objetivoPrincipal)

                                }
                            } else if (obj.idTipoObjetivo == 2) {
                                this.objetivosPrincipaisSelecionados.push(this.objetivos.find(objs => objs.objetivo && objs.objetivo.objetivo && objs.objetivo.objetivo.id === obj.idObjetivoLicenciamentoAmbiental))
                                this.objetivosPrincipaisSelecionados = this.objetivosPrincipaisSelecionados.slice();
                            }
                        }
                    }
                }
                //Bug 9754
            if(this.objetivosOutrosSelecionados.length != 0 && this.requerimento.tipoRequerimento.isFluxoRenovar && inicio && !this.isSomenteVisualizacao){
                    let autorizacaoEncontrada = this.objetivosOutrosSelecionados.filter(titulo => titulo.objetivo.objetivo.descricao.toLowerCase().includes('autorização'))
                if(autorizacaoEncontrada)
                        this.filtroRenovacaoComAutorizacoes(autorizacaoEncontrada);
                }

                this.verificaTitulos()
                if (this.objetivosPrincipaisSelecionados.length > 0 && this.edicaoAtvLicenciada) {
                    await this.buscarAtividadesDosObjetivos()
                }

                //comentado após solicitação da analista para remoção do componente [Outros objetivos] da aba 1 

                // if (this.objetivosOutrosSelecionados.length > 0 && this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                //     if (adicionando) {
                //         await this.preencherParametrosAutorizacao(true)
                //     } else {
                //         await this.preencherParametrosAutorizacao(inicio)
                //     }
                // }

                if ((this.requerimento && this.requerimento.idTipoRequerimentoTifLas === 2) ||
                    (this.requerimento && this.requerimento.idTipoRequerimentoTifLas === 6)) {
                    if (this.objetivosPrincipaisSelecionados.length === 0 &&
                        this.requerimento.idTipoRequerimentoTifLas === 2) {
                        await this.atividadeSelecionadaVinculadaSemObjetivo()
                    }
                    this.resetaRegras()
                    this.verificaObjsInseridos()
                    await this.aplicaRegraDeObjetivos()
                }
                if (this.requerimento && this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                    this.regraDeDesabilitarHabilitarObjetivos()
                }
            }).catch(error => {

                if (error.status != 404) {
                    this.snackBarService.showError('Ocorreu um erro ao buscar preencher tabela de objetivo');
                }
                throw error;
            })

        if (this.requerimento && this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
            await this.aplicaRegraDeObjetivosAutorizacao()
        }
    }

    private validaRegras(objetivoPrincipal) {
        this.mostrarOutraObjetivos = objetivoPrincipal.objetivo.objetivo.descricao !== 'LO - Licença de Operação - Vinculada';

        if (objetivoPrincipal.objetivo.objetivo.descricao === 'LI - Licença de Instalação') {
            let desmate = this.objetivosOutros.find(obj => obj.objetivo.objetivo.descricao === 'Autorização de Desmate')
            let queimada = this.objetivosOutros.find(obj => obj.objetivo.objetivo.descricao === 'Autorização Queima Controlada')

            if (!desmate) {
                let findDesmate = this.arrayRegraLI.find(obj => obj.objetivo.objetivo.descricao === 'Autorização de Desmate')
                if (findDesmate) {
                    this.objetivosOutros.push(findDesmate)
                }
            }
            if (!queimada) {
                let findQueimada = this.arrayRegraLI.find(obj => obj.objetivo.objetivo.descricao === 'Autorização Queima Controlada')
                if (findQueimada) {
                    this.objetivosOutros.push(findQueimada)
                }
            }
        } else {
            this.iniciaRegrasLI()
        }
    }


    /**
     *
     * Remove relações
     * Remove REQUERIMENTO_TITULACAO
     * @param objetivoLicenciamento
     * @returns
     */
    async deletaObjetivoDaTabela(objetivoLicenciamento: any) {
        if (this.objetivosPrincipaisSelecionados.find(obj =>
            obj.objetivo.objetivo.descricao.includes('Prévia - EIA (RIMA)'))
            && this.reqsTermoRefSave.length > 0) {
            this.snackBarService.showAlert('Tem um Termo de Referência de (EIA/RIMA) vinculado a esse objetivo.')
            return
        } else if (this.objetivosPrincipaisSelecionados.find(obj =>
            obj.objetivo.objetivo.descricao.includes('LO - Licença de Operação - Vinculada'))
            && this.reqsNumeroLOSave.length > 0) {
            this.snackBarService.showAlert('Tem LO vinculada a esse objetivo.')
            return
        } else if (objetivoLicenciamento.objetivo.ordemSelecao === 1
            && this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.ordemSelecao === 2)) {
            this.snackBarService.showAlert('Não pode deletar um objetivo que é relacionamento em ordem de seleção.');
            return
        } else if (this.requerimento.idTipoRequerimentoTifLas === 2) {
            let naoDeletavel = false
            await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamentoVinculado.id)
                .toPromise()
                .then(async lista => {
                    for (let obj of lista) {
                        if (obj.idObjetivoLicenciamentoAmbiental === objetivoLicenciamento.objetivo.objetivo.id) {
                            naoDeletavel = true
                        }
                    }
                });

            if (naoDeletavel) {
                this.snackBarService.showAlert('Não pode deletar um objetivo que foi vinculado do protocolo - ' + this.requerimento.numeroProtocoloVinculado);
                return
            }
        }


        if (this.requerimento.idTipoRequerimentoTifLas === 1 || this.requerimento.idTipoRequerimentoTifLas === 3) {
            if (this.objetivosOutrosSelecionados.length > 0) {
                await this.deletaOutroObjetivoDaTabela(this.objetivosOutrosSelecionados[0].objetivo.objetivo.id)
            }
            if (this.objetivosPrincipaisSelecionados.length === 1) {
                await this.deletarTudoRelacionado()
            }
        }

        await this.objetivoLicenciamentoService.deletePorIds(this.requerimentoLicenciamento.id, objetivoLicenciamento.objetivo.objetivo.id).toPromise()

        // Remove REQUERIMENTO_TITULACOES (fisico)
        await this.requerimentoTitulacaoService.deleteAllByRequerimentoId(this.requerimento.id).toPromise();

        await this.preencheTabelaObjetivo()

        //Condição para corrigir o bug 5338
        if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
            this.isAtividadeComplementar = true
        }
        this.verificaAtividadesDeObjetivos(objetivoLicenciamento.objetivo.tipoTitulacao.id)
        this.regraDeDesabilitarHabilitarObjetivos()

    }

    async deletaOutroObjetivoDaTabela(idObjetivoLicenciamento: number) {
        let paramParaDelete = []
        if (this.parametroEntradasAut && this.parametroEntradasAut.length > 0) {
            let lancamentoTaxa = this.parametroEntradasAut.filter(lanc => lanc.lancamentoTaxa)[0]
            if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                return
            }
            for (let param of this.parametroEntradasAut) {
                if (param.id && lancamentoTaxa) {
                    paramParaDelete.push(param)
                }
            }
        }

        if (this.parametroCalculoListAut && this.parametroCalculoListAut.length > 0) {
            let lancamentoTaxa = this.parametroCalculoListAut.filter(lanc => lanc.lancamentoTaxa)[0]
            if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                return
            }
            for (let param of this.parametroCalculoListAut) {
                if (param.id && lancamentoTaxa) {
                    paramParaDelete.push(param)
                }
            }
        }

        if (paramParaDelete.length > 0) {
            await this.lancamentoTaxaService.deletaTodos(paramParaDelete).toPromise()
        }
        this.parametroEntradasAut = []
        this.parametroCalculoListAut = []
        this.parametrosCalculoListaAut = []

        await this.objetivoLicenciamentoService.deletePorIds(this.requerimentoLicenciamento.id, idObjetivoLicenciamento).toPromise()
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
            await this.deletarAtividadeLicenciada();
        }
        await this.preencheTabelaObjetivo()
    }

    verificaAtividadesDeObjetivos(idTipoTitulacao) {
        let listaAtvsRemove = this.atividadeLicenciada.filter(atv => atv.tipoTitulacao.id === idTipoTitulacao)
        for (let atvRemove of listaAtvsRemove) {
            this.atividadeLicenciada.splice(atvRemove, 1)
        }
    }


    async buscarAtividadesDosObjetivos() {
        this.atividadeLicenciada = []
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
            for (let objetivosOutros of this.objetivosPrincipaisSelecionados.filter(x => x.objetivo && x.objetivo.tipoTitulacao && x.objetivo.tipoTitulacao.id)) {
                let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculosPorIdTipoTitulacao(objetivosOutros.objetivo.tipoTitulacao.id).toPromise()
                this.atividadeLicenciada.push(...atividadeCalculo)
            }

        } else {
            for (let objetivosPrincipais of this.objetivosPrincipaisSelecionados.filter(x => x.objetivo && x.objetivo.tipoTitulacao && x.objetivo.tipoTitulacao.id)) {
                let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculosPorIdTipoTitulacao(objetivosPrincipais.objetivo.tipoTitulacao.id).toPromise()
                this.atividadeLicenciada.push(...atividadeCalculo)
            }

            let licenciaLOVin = this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo && obj.objetivo.objetivo.descricao === 'LO - Licença de Operação - Vinculada')
            let las = this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.objetivo && obj.objetivo.objetivo.descricao === 'LAS - Licença Ambiental Simplificada')
            if (licenciaLOVin || las) {
                this.isAtividadeComplementar = false
            }
        }

        const key = 'id';
        this.atividadeLicenciada = [...new Map(this.atividadeLicenciada.map(item =>
            [item[key], item])).values()];
        this.atividadeLicenciadaEncontrado = this.atividadeLicenciada;
    }


    selecionarAtividadeLicenciada(atividade: any) {
        this.atividadeLicenSelecionado = atividade
    }

    async selecionarAtividadeLicenNaTable() {
        if (!this.atividadeDesc || this.atividadeDesc == "") {
            this.snackBarService.showAlert('É necessário informar a atividade.');
            return
        }
        if (this.atividadeSelecionadaTable.length === 0) {
            await this.salvaAtividadeLicenciada()
            this.preencheTabelaAtividadeLicenciada()
            await this.obterAtividadeCalculoParametroEntrada()
            if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                if (this.objetivosPrincipaisSelecionados.length > 0 ) {
                    await this.preencherParametrosAtividadeAutorizacao();
                }
            }
        } else {
            this.snackBarService.showAlert('Já existe uma atividade selecionada.');
        }
    }

    private async salvaAtividadeLicenciada() {
        this.requerimentoLicenciamento.idAtividade = this.atividadeLicenSelecionado.atividade.id
        if (this.atividadeLicenSelecionado.poluicao) {
            this.requerimentoLicenciamento.ppd = this.atividadeLicenSelecionado.poluicao.descricao
        }
        this.requerimentoLicenciamento.descricaoAtividade = this.atividadeLicenSelecionado.atividade.descricao
        this.requerimentoLicenciamento.idGrupoAtividade = this.atividadeLicenSelecionado.atividade.atividadePai.id
        this.requerimentoLicenciamento.descricaoGrupoAtividade = this.atividadeLicenSelecionado.atividade.atividadePai.descricao
        await this.requerimentoLicenciamentoService.update(this.requerimentoLicenciamento).toPromise()
    }

    private preencheTabelaAtividadeLicenciada() {
        if (this.atividadeLicenciada && this.atividadeLicenciada.length > 0) {
            this.atividadeSelecionadaTable.push(this.atividadeLicenciada.find(atvLic => atvLic.atividade.id === this.requerimentoLicenciamento.idAtividade))
            this.atividadeSelecionadaTable = this.atividadeSelecionadaTable.slice()
        }
        this.atividade = this.atividadeSelecionadaTable[0].atividade
        this.subGrupo = this.atividadeLicenciada.filter(atv => atv.atividade.atividadePai)
        this.atividadeLicenciadaSubGrupoEncontrado = this.subGrupo
    }

    async preencheComboAtividadeLicenciadaSubGrupo() {
        this.atividadeLicenciada = [];
        for (let objetivosPrincipais of this.objetivosPrincipaisSelecionados) {
            let atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculosPorIdTipoTitulacao(objetivosPrincipais.objetivo.tipoTitulacao.id).toPromise()
            if (this.atividadeLicenciada.length <= 0) {
                this.atividadeLicenciada.push(...atividadeCalculo)
            }
        }
        this.subGrupo = this.atividadeLicenciada.filter(atv => atv.atividade.atividadePai)
        this.atividadeLicenciadaSubGrupoEncontrado = this.subGrupo
    }

    async obterAtividadeCalculoParametroEntrada(inicio = false) {
        if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS
            && this.objetivosPrincipaisSelecionados.length > 0) {
            await this.atividadeParametroEntradaArrecadacaoService
                .buscarParametrosEntradaPIdAtividade(this.atividade.id, this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id)
                .toPromise().then(atividadeParametroCalculoEntrada => {
                    this.parametroEntradas = atividadeParametroCalculoEntrada;
                }).catch(async error => {
                    error.status = 404;
                    this.errorStatusCalculoParametroEntrada = error.status;
                    if (this.errorStatusCalculoParametroEntrada === 404) {
                        await this.obterAtividadeCalculoSemParametroEntradaeCalculo(inicio);
                    }
                });

        }

    }

    async obterAtividadeCalculoParametroEntradaAut(inicio = false) {
        if (this.atividadeAut) {
            await this.atividadeParametroEntradaArrecadacaoService
            .buscarParametrosEntradaPIdAtividade((this.atividadeSelecionadaTable[0] && this.atividadeSelecionadaTable[0].atividade.id ? this.atividadeSelecionadaTable[0].atividade.id  : this.atividadeAut.id ), this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id)
                .toPromise().then(atividadeParametroCalculoEntrada => {
                    this.parametroEntradasAut = atividadeParametroCalculoEntrada;
                }).catch(async error => {
                    error.status = 404;
                    this.errorStatusCalculoParametroEntrada = error.status;
                    if (this.errorStatusCalculoParametroEntrada === 404) {
                        await this.obterAtividadeCalculoSemParametroEntradaeCalculoAut(inicio);
                    }
                })

        }
        else
            throw new Error('Atividade Autorização não definida')
    }

    async obterAtividadeCalculoSemParametroEntradaeCalculo(inicio = false) {
        this.atividadeCalculoArrecadacaoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicenca())
            .toPromise().then(async atividadeCalculo => {
                this.atividadeCalculo = atividadeCalculo;
                if (!inicio) {
                    this.parametroCalculoList = [...this.atividadeCalculo].shift().parametroCalculoList;
                    if (this.parametroCalculoList && this.parametroCalculoList.length === 0) {
                        this.atividadeSemParametroeCaculo = true;
                        await this.iniciarCalculo()
                    }
                }
            }).catch(error => {
                this.snackBarService.showAlert("Ocorreu um erro: " + error);
            })
    }

    obterAtividadeCalculoSemParametroEntradaeCalculoAut(inicio) {
        this.atividadeCalculoArrecadacaoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicencaAut())
            .toPromise().then(async atividadeCalculo => {
                this.atividadeCalculoAut = atividadeCalculo;
                if (!inicio) {
                    this.parametroCalculoListAut = [...this.atividadeCalculoAut].shift().parametroCalculoList;
                    if (this.parametroCalculoListAut && this.parametroCalculoListAut.length === 0) {
                        this.atividadeSemParametroeCaculoAut = true;
                        await this.iniciarCalculo(false)
                    }
                }

            }).catch(() => this.snackBarService.showAlert("Sua atividade não é passível de licenciamento"));
    }

    montarObjetoParametroSemLicenca() {
        return {
            idAtividade: this.atividade.id,
            parametroEntrada: this.parametroEntrada,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp,
            idTipoTitulacao: this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id
        }
    }

    montarObjetoParametroSemLicencaAut() {

        return {
            idAtividade: this.atividadeAut.id,
            parametroEntrada: this.parametroEntradaAut,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp,
            idTipoTitulacao: this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id
        }
    }

    private async verificaLac() {
        let retorno = 0;
        const atvCalcLAC = this.atividadeCalculo.find(atvCalc => atvCalc.tipoTitulacao.id === 1)

        if (atvCalcLAC) {
            const dialogRef = this.dialog.open(ConfirmacaoDialogComponent,
                {
                    data: {
                        message: 'A atividade pretendida é classificada como LAC.\n' +
                            'Essa atividade encontra-se em Zona de amortecimento de Terra indígena, Unidade de Conservação, ou ocorrerá desmate?\n'
                    },
                    width: '600px'
                });

            await dialogRef.afterClosed().toPromise().then(async result => {
                if (!!result) {
                    retorno = 0;
                } else {
                    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent,
                        {
                            data: {
                                message: `Sua atividade se enquadra no Licenciamento por Adesão e Compromisso, por favor solicitar o seu licenciamento no menu de novos requerimentos no nosso <a href="${environment.URL_LICENCIAMENTO}/#/menu-novo-requerimento" target="_blank">portal.</a>`,
                                apenasDescricao: true
                            },
                            width: '600px'
                        });

                    await dialogRef.afterClosed().toPromise().then(() => {
                        retorno = 1
                    });
                }
            });
        }

        return retorno
    }

    async iniciarCalculo(calculaTudo = true) {
        this.calcularUpfRetornoDtoList = []
        this.calcularUpfRetornoDtoListAut = []
        this.popUp = false

        if (calculaTudo) {

            if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                let validacaoLAC = await this.verificaLac()

                const atvCalcLAC = this.atividadeCalculo.find(atvCalc => atvCalc.tipoTitulacao.id === 1)

                if (atvCalcLAC
                    && validacaoLAC === 0) {
                    if (!this.atividadeCalculo.find(atvCalc => atvCalc.tipoTitulacao.id === 5)) {
                        const atividadesCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(this.atividade.id).toPromise()
                        const atividadeCalcLAS = atividadesCalculo.find(atvCalc => atvCalc.tipoTitulacao.id === 5)
                        const atividadeCalculoParamEntrada = await this.atividadeParametroEntradaArrecadacaoService
                            .buscaAtividadeParametroEntradaPIdAtividadeCalculo(atividadeCalcLAS.id).toPromise()
                        for (let paramEntrada of this.parametroEntradas) {
                            const event = {
                                target: {
                                    value: atividadeCalculoParamEntrada.valorMaximo.toString()
                                }
                            };
                            await this.onKey(event, paramEntrada, false);
                            this.valorParametro.nativeElement.value = atividadeCalculoParamEntrada.valorMaximo.toString()
                            if (this.parametroCalculoList
                                && this.parametroCalculoList.length > 0) {
                                this.snackBarService.showAlert('Preencha o parâmetro pra cálculo para concluir o cálculo')
                                this.erroDeCalculo = true
                                this.popUp = true
                                return
                            }
                        }
                    } else {
                        this.atividadeCalculo = this.atividadeCalculo.filter(atvCalc => atvCalc.tipoTitulacao.id === 5)
                    }
                } else if (validacaoLAC === 1) {
                    this.erroDeCalculo = true
                    return
                }

                if (!this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 5)) {
                    if (this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id == 5)) {
                        this.erroDeCalculo = true
                        this.popUp = true
                        return
                    }
                } else if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 5)) {
                    if (!this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id == 5)) {
                        this.erroDeCalculo = true;
                        this.popUp = true;
                        return;
                    }
                }
            } else {
                await this.obterAtividadeCalculoDoParametroDeEntradaAut();
            }

            let objetivosAll = [];

            objetivosAll.push(...this.objetivosPrincipaisSelecionados);
            //comentado após solicitação da analista ANA para remoção do componente [Outros objetivos] da aba 1
            // if (this.objetivosOutrosSelecionados.length > 0) {
            //     objetivosAll.push(...this.objetivosOutrosSelecionados);
            // }


            let objetivoACalcular = [];
            let objetivosDeCalculo = [];
            if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                objetivosDeCalculo.push(...this.atividadeCalculo);
            }

            if (this.objetivosPrincipaisSelecionados && this.objetivosPrincipaisSelecionados.length > 0
                && this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                objetivosDeCalculo.push(...this.atividadeCalculoAut);
                // if (!this.atividadeCalculo) {
                //     objetivosDeCalculo.push(...this.atividadeCalculoAut);
                // } else {
                //     objetivosDeCalculo.push(...this.atividadeCalculo);
                // }                        
            }
            // if (this.objetivosOutrosSelecionados.length > 0 && this.atividadeCalculoAut) {
            //     objetivosDeCalculo.push(...this.atividadeCalculoAut);
            // }

            objetivosAll = objetivosAll.filter(obj => obj.objetivo.tipoTitulacao.id !== 16)
            for (let objetivo of objetivosAll) {
                for (let calculo of objetivosDeCalculo) {
                    if (objetivo.objetivo.tipoTitulacao.id === calculo.tipoTitulacao.id) {
                        objetivoACalcular.push(calculo)
                    }
                }
            }

            if (objetivoACalcular.length == objetivosAll.length) {
                let atividadesCalculoPrin = []
                for (let objetivosPrin of this.objetivosPrincipaisSelecionados) {
                    atividadesCalculoPrin.push(objetivosDeCalculo.find(calculo => calculo.tipoTitulacao.id === objetivosPrin.objetivo.tipoTitulacao.id))
                }
                this.atividadeCalculo = atividadesCalculoPrin

            } else {
                this.snackBarService.showAlert('Sua atividade não é passível de licenciamento')
                this.erroDeCalculo = true
                this.atividadeCalculoAut = [];
                return;
            }

            for (let param of this.parametrosCalculoLista) {
                param['atividadeCalculo'] = []
            }

            for (let atvCalc of this.atividadeCalculo) {
                for (let param of this.parametrosCalculoLista) {
                    param['atividadeCalculo'].push(atvCalc)
                }
            }

            for (let param of this.parametrosCalculoListaAut) {
                param['atividadeCalculo'] = []
            }

            if (this.atividadeCalculoAut) {
                for (let atvCalc of this.atividadeCalculoAut) {
                    for (let param of this.parametrosCalculoListaAut) {
                        param['atividadeCalculo'].push(atvCalc)
                    }
                }
            }

            if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                await this.calcularLicenca()
                if (this.erroDeCalculo) {
                    this.erroDeCalculo = true
                    return;
                }
            }

            // if (this.mostrarOutraObjetivos && this.objetivosOutrosSelecionados.length > 0) {
            //     await this.calcularLicencaAut()
            // }
            // if (this.mostrarOutraObjetivos && this.objetivosPrincipaisSelecionados.length > 0) {
            //     await this.calcularLicencaAut()
            // }
            if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS && this.objetivosPrincipaisSelecionados.length > 0) {
                await this.calcularLicencaAut()
            }


        }

        // else {
        //     if (this.mostrarOutraObjetivos && this.objetivosOutrosSelecionados.length > 0) {
        //         await this.calcularLicencaAut()
        //     }
        // }

        if ((this.mostrarOutraObjetivos || this.regrasSuimis == "suimisFluxoAlteracaoComProtocoloLp" ||
            this.regrasSuimis == "suimisFluxoAlteracaoComProtocoloLopm" || this.isFluxoEmAndamento())
            && this.calcularUpfRetornoDtoList.length > 0 && this.calcularUpfRetornoDtoListAut.length === 0) {

            this.erroDeCalculo = false;
        } else if(this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS 
            && this.calcularUpfRetornoDtoListAut.length > 0) {

            this.calcularUpfRetornoDtoList = [];
            this.calcularUpfRetornoDtoList.push(...this.calcularUpfRetornoDtoListAut)
            this.erroDeCalculo = false
        } else {
            this.erroDeCalculo = true
        }
    }

    isFluxoEmAndamento(): boolean {
        return !this.requerimento.tipoRequerimento.isFluxoEmitir;
    }

    async calcularLicencaAut() {
        this.erroDeCalculo = false
        const ultimoIndex = this.atividadeSelecionadaTable.indexOf(this.atividadeSelecionadaTable.slice(-1)[0]);
        let titulacaoIsenta = true;

        for (let i = 0; i < this.atividadeSelecionadaTable.length; i++) {
            await this.atividadeCalculoArrecadacaoService
                .calcularUpf(this.montarObjetoCalculoUpf(this.atividadeSelecionadaTable[i]))
                .toPromise().then(calcularUpfRetornoDto => {
                    titulacaoIsenta = this.validacaoAtividadeIsentaAut(calcularUpfRetornoDto)
                    this.parametroEntradaAut['idAtividadeCalculo'] = calcularUpfRetornoDto.atividadeCalculo.id
                    let msg = 'O Upf foi Calculado com sucesso, estará disponível na aba de Cálculo de Taxa';
                    if (!titulacaoIsenta) {
                        calcularUpfRetornoDto['isenta'] = true
                        msg = 'Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020'
                    }
                    this.calcularUpfRetornoDtoListAut.push(calcularUpfRetornoDto)

                    if (ultimoIndex == i) {
                        this.calcularUpfRetornoDtoListAut = this.calcularUpfRetornoDtoListAut.sort((a, b) =>
                            (a.atividadeSelecionadaTable.tipoTitulacao.ordenacao != null ? a.atividadeSelecionadaTable.tipoTitulacao.ordenacao : Infinity) - (b.atividadeCalculo.tipoTitulacao.ordenacao != null ? b.atividadeCalculo.tipoTitulacao.ordenacao : Infinity)
                        );

                        if (this.calcularUpfRetornoDtoListAut.length > 0) {
                            this.snackBarService.showSuccess(msg);
                            this.erroDeCalculo = false;
                        }
                    }

                }).catch(() => {
                    this.erroDeCalculo = true
                    this.snackBarService.showError('Sua atividade não é passível de licenciamento')
                })
        }
    }

    async calcularLicenca() {
        this.erroDeCalculo = false
        const ultimoIndex = this.atividadeCalculo.indexOf(this.atividadeCalculo.slice(-1)[0]);
        let titulacaoIsenta = true

        this.atividadeCalculo = this.atividadeCalculo.filter(function (i) {
            return i
        });

        for (let i = 0; i < this.atividadeCalculo.length; i++) {
            await this.atividadeCalculoArrecadacaoService
                .calcularUpf(this.montarObjetoCalculoUpf(this.atividadeCalculo[i]))
                .toPromise().then(calcularUpfRetornoDto => {
                    this.parametroEntrada['idAtividadeCalculo'] = calcularUpfRetornoDto.atividadeCalculo.id
                    this.calcularUpfRetornoDtoList.push(calcularUpfRetornoDto)

                    if (ultimoIndex == i) {
                        this.calcularUpfRetornoDtoList = this.calcularUpfRetornoDtoList.sort((a, b) =>
                            (a.atividadeCalculo.tipoTitulacao.ordenacao != null ? a.atividadeCalculo.tipoTitulacao.ordenacao : Infinity) - (b.atividadeCalculo.tipoTitulacao.ordenacao != null ? b.atividadeCalculo.tipoTitulacao.ordenacao : Infinity)
                        );

                        titulacaoIsenta = this.validacaoAtividadeIsenta()

                        if (this.calcularUpfRetornoDtoList.length > 0) {
                            if (!titulacaoIsenta) {
                                this.snackBarService.showAlert('Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020');
                            } else {
                                this.snackBarService.showSuccess("O Upf foi Calculado com sucesso, estará disponível na aba de Cálculo de Taxa");
                            }
                        }
                    }

                }).catch(() => {
                    this.erroDeCalculo = true
                    this.snackBarService.showError('Sua atividade não é passível de licenciamento')
                })
        }
    }

    montarObjetoCalculoUpf(atividadeCalculo: AtividadeCalculoArrecadacao) {

        this.parametrosCalculoLista.forEach(param => {
            delete param.id
        })

        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA
                || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS ) {
            return {
                idAtividadeCalculo: atividadeCalculo.id,
                parametrosCalculo: this.parametrosCalculoListaAut,
                parametroEntrada: this.parametroEntradaAut,
                //identificador do tipoTitulacao na configuração de objetivos
                idTipoTitulacao: this.objetivosPrincipaisSelecionados[0].vinculo.idArrecadacao,
                idAtividade: atividadeCalculo.atividade.id,
                instanciaRequerimento: this.requerimento.tipoProcesso.instanciaRequerimento
            }
        } else {
            return {
                atividadeCalculo: atividadeCalculo,
                idAtividadeCalculo: atividadeCalculo.id,
                parametrosCalculo: this.parametrosCalculoLista,
                parametroEntrada: this.parametroEntrada,
                instanciaRequerimento: this.requerimento.tipoProcesso.instanciaRequerimento
            }
        }
    }

    async onKey(event, atividadeCalculoparamEntrada, timeOut = true, recuperado = false) {
        this.parametroEntrada.valor = event.target.value;
        this.parametroEntrada.descricao = atividadeCalculoparamEntrada.descricao;
        this.parametroEntrada.labelFormulario = atividadeCalculoparamEntrada.labelFormulario;
        if (event.target.value.trim().toString().length > 0 && event.target.value.trim().toString() !== '') {
            if (timeOut) {
                setTimeout(async () => {
                    if (this.element) {
                        this.element.nativeElement.disabled = true;
                    }
                    await this.obterAtividadeCalculoDoParametroDeEntrada();
                    if (this.element) {
                        this.element.nativeElement.disabled = false;
                    }
                }, 1500);
            } else {
                await this.obterAtividadeCalculoDoParametroDeEntrada(recuperado);
            }
        } else if (event.target.value.toString().length === 0) {
            this.parametroCalculoList = null;
            this.atividadeCalculo = [];
            if (this.element) {
                this.element.nativeElement.disabled = false;
            }
        }
    }

    async onKeyAut(event, atividadeCalculoparamEntrada, timeOut = true, recuperado = false) {
        this.parametroEntradaAut.valor = event.target.value;
        this.parametroEntradaAut.descricao = atividadeCalculoparamEntrada.descricao;
        this.parametroEntradaAut.labelFormulario = atividadeCalculoparamEntrada.labelFormulario;
        if (event.target.value.trim().toString().length > 0 && event.target.value.trim().toString() !== '') {
            if (timeOut) {
                setTimeout(async () => {
                    if (this.element) {
                        this.element.nativeElement.disabled = true;
                    }
                    await this.obterAtividadeCalculoDoParametroDeEntradaAut();
                    if (this.element) {
                        this.element.nativeElement.disabled = false;
                    }
                }, 1500);
            } else {
                await this.obterAtividadeCalculoDoParametroDeEntradaAut(recuperado);
            }
        } else if (event.target.value.toString().length === 0) {
            this.parametroCalculoListAut = null;
            this.atividadeCalculoAut = [];
            if (this.element) {
                this.element.nativeElement.disabled = false;
            }
        }
    }

    private async retornaLancamentosTaxa() {
        await this.requerimentoFormularioLicenciamentoService.deleteEmp(this.requerimentoLicenciamento.id).toPromise()

        const lancamentosTaxa = []

        for (let licenca of this.calcularUpfRetornoDtoList) {
            const lancamentoTaxa = new LancamentoTaxaDto()
            lancamentoTaxa.requerimento = this.requerimento;
            lancamentoTaxa.qtdePonto = 1;
            lancamentoTaxa.qtdeTotalUPF = licenca.valorUpf;
            lancamentoTaxa.qtdeUnitarioUPF = licenca.valorUpf;

            let atividadeCalculo;
            if (licenca.atividadeCalculo) {
                atividadeCalculo = licenca.atividadeCalculo;
            } else {
                atividadeCalculo = licenca;
            }

            lancamentoTaxa.codigoReceitaNatureza = atividadeCalculo.receitaNatureza ? atividadeCalculo.receitaNatureza.id.toString() : null;
            lancamentoTaxa.codigoReceitaGrupo = atividadeCalculo.receitaNatureza ? atividadeCalculo.receitaNatureza.receitaGrupo.id.toString() : null;
            lancamentoTaxa.idAtividadeCalculo = atividadeCalculo.id;

            // mudar para titulação conforme o objetivo
            const titulacoes = await this.titulacaoService.getAll().toPromise();

            if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                if (atividadeCalculo.tipoTitulacao.id === this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id) {
                    lancamentoTaxa.titulacao = titulacoes.find(titulacao => titulacao.id == this.objetivosPrincipaisSelecionados[0].objetivo.titulo.id);
                    lancamentoTaxa['autorizacao'] = true;
                } else {
                    this.snackBarService.showAlert('Erro ao criar o lancamento de taxa para o requerimento');
                    return;
                }

            }

            lancamentoTaxa.titulacao = titulacoes.find(titulacao => titulacao.id == this.objetivosPrincipaisSelecionados[0].objetivo.titulo.id);

            if (licenca['isenta']) {
                lancamentoTaxa.taxaIsenta = true;
            }

            if (licenca['isenta']) {
                lancamentoTaxa.taxaIsenta = true;
            }

            lancamentosTaxa.push(lancamentoTaxa);
        }
        return lancamentosTaxa;
    }

    private retornaLancTaxaParams(lancamentoTaxa) {

        const lancamentoTaxaParamCalculoDto = [];

        if (!lancamentoTaxa.autorizacao) {
            if (this.parametroEntrada.valor) {
                lancamentoTaxaParamCalculoDto
                    .push(ParametroEntradaArrecadacao
                        .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(this.parametroEntrada, lancamentoTaxa, 'ENTRADA'));
            }

            if (this.objetivosPrincipais && this.objetivosPrincipais.length > 1) {
                this.parametrosCalculoLista.filter(param => limpaObjUnd(param.atividadeCalculo).find(atv => atv.id).id === lancamentoTaxa.idAtividadeCalculo)
                if (this.parametrosCalculoLista.length > 1) {
                    let parametrosCalculoLista = []
                    parametrosCalculoLista.push(this.parametrosCalculoLista.slice(-1)[0])
                    this.parametrosCalculoLista = parametrosCalculoLista
                }
            } else {
                this.parametrosCalculoLista.filter(param => limpaObjUnd(param.atividadeCalculo).find(atv => atv.id).id === lancamentoTaxa.idAtividadeCalculo)
            }

            this.parametrosCalculoLista.forEach(paramCalc => {
                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO'));
            });
        } else {
            if (this.parametroEntradaAut.valor && this.parametroEntrada.idAtividadeCalculo === lancamentoTaxa.idAtividadeCalculo) {
                lancamentoTaxaParamCalculoDto
                    .push(ParametroEntradaArrecadacao
                        .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(this.parametroEntradaAut, lancamentoTaxa, 'ENTRADA', null, true));

            }

            this.parametrosCalculoListaAut.filter(param => limpaObjUnd(param.atividadeCalculo).find(atv => atv.id).id === lancamentoTaxa.idAtividadeCalculo)

            this.parametrosCalculoListaAut.forEach(paramCalc => {
                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO', null, true));
            });
        }

        function limpaObjUnd(obj) {
            obj = obj.filter(function (i) {
                return i
            });
            return obj
        }

        return lancamentoTaxaParamCalculoDto;
    }

    private async montarFormularioReqLicenDTO() {
        this.formularioReqLicenciamentoDto = new FormularioReqLicenciamentoEmp();
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS &&
            !this.requerimento.numeroProtocoloVinculado) {
            await this.objetivoAtividadeDeObjetivo()
        }
        this.formularioReqLicenciamentoDto.requerimentoLicenciamento = this.requerimentoLicenciamento
        this.formularioReqLicenciamentoDto.lancamentoTaxa = await this.retornaLancamentosTaxa();
        const lancamentoTaxaParamCalculoList = []
        try {
            for (let lancamentoTaxa of this.formularioReqLicenciamentoDto.lancamentoTaxa) {
                lancamentoTaxaParamCalculoList.push(...this.retornaLancTaxaParams(lancamentoTaxa));
            }
        } catch(e) {
            console.log("this.formularioReqLicenciamentoDto.lancamentoTaxa", this.formularioReqLicenciamentoDto.lancamentoTaxa);
        }
        this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList = lancamentoTaxaParamCalculoList
        this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = this.atividadeSemParametroeCaculo;

        return this.formularioReqLicenciamentoDto;
    }

    private async objetivoAtividadeDeObjetivo() {
        if (this.atividadeSelecionadaTable && this.atividadeSelecionadaTable.length > 0) {
            await this.atividadeArrecadacaoService
                .getByAtividadeNivelPorDesc(this.atividadeSelecionadaTable[0].atividade.descricao)
                .toPromise().then(async data => {
                    this.requerimentoLicenciamento.idAtividade = data[0].id
                    this.requerimentoLicenciamento.descricaoAtividade = data[0].descricao
                    this.requerimentoLicenciamento.idGrupoAtividade = data[0].idAtvPai
                    await this.atividadeArrecadacaoService
                        .getAtividadeId(data[0].idAtvPai)
                        .toPromise().then(async dataAtvPai => {
                            this.requerimentoLicenciamento.descricaoGrupoAtividade = dataAtvPai.descricao
                        });
                });
        }
    }

    montarObjetoParamEntrada(parametroEntradas, valor) {
        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (this.parametrosCalculoLista.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoLista.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoLista.splice(this.parametrosCalculoLista.indexOf(paramCalc), 1);
                    this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
                }
            })
        } else {
            this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
        }
    }

    montarObjetoParamEntradaAut(parametroEntradas, valor) {
        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (this.parametrosCalculoListaAut.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoListaAut.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoListaAut.splice(this.parametrosCalculoListaAut.indexOf(paramCalc), 1);
                    this.parametrosCalculoListaAut.push(objetoParametrosCalculoParaLista);
                }
            })
        } else {
            this.parametrosCalculoListaAut.push(objetoParametrosCalculoParaLista);
        }
    }

    pegarValorDeParamCalc(event, parametroEntradas: any, licencas = []) {
        this.montarObjetoParamEntrada(parametroEntradas, event.target.value)
        if (this.atividadeCalculo && this.atividadeCalculo.length === 0) {
            this.calcularUpfRetornoDtoList = this.atividadeCalculo
        } else {
            this.calcularUpfRetornoDtoList = licencas
        }
        // this.eventEmitter.emit(this.returnoObjetoEmit(this.validacao))
    }

    pegarValorDeParamCalcAut(event, parametroEntradas: any, licencas = []) {
        this.montarObjetoParamEntradaAut(parametroEntradas, event.target.value)
        if (this.atividadeCalculoAut && this.atividadeCalculoAut.length === 0) {
            this.calcularUpfRetornoDtoListAut = this.atividadeCalculoAut
        } else {
            this.calcularUpfRetornoDtoListAut = licencas
        }
        // this.eventEmitter.emit(this.returnoObjetoEmit(this.validacao))
    }

    async obterAtividadeCalculoDoParametroDeEntrada(recuperado = false) {
        if (!recuperado){
            this.parametroCalculoList = null;
        }
        await this.atividadeCalculoArrecadacaoService.buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoLicenca()).toPromise().then(atividadeCalculo => {
            //Filtrando atividades de calculo pelo tipo titulação do objetivo
            //OBS: Será analisado como deverá funcionar para casos onde o requerimento possuem mais de um objetivo principal.
            this.atividadeCalculo = atividadeCalculo.filter(atvCalc =>
                atvCalc.tipoTitulacao.id == this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id)
            if (!recuperado) {
                this.parametroCalculoList = [...this.atividadeCalculo].shift().parametroCalculoList;
            }
            if (!this.parametroCalculoList) {
                this.snackBarService.showAlert("Não há licença disponível");
            } else {
                for (let i = 0; i < this.parametroCalculoList.length; i++) {
                    if (this.parametroCalculoList[i].labelFormulario === this.parametroEntradas[0].labelFormulario) {
                        let event = {
                            target: {
                                value: this.parametroEntrada.valor
                            }
                        }
                        this.pegarValorDeParamCalc(event, this.parametroCalculoList[i], this.atividadeCalculo)
                        this.parametroCalculoList.splice(i, 1)
                        this.parametroCalculoList = this.parametroCalculoList.slice()
                    }
                }
            }
        }).catch(() => {
            this.snackBarService.showAlert("Sua atividade não é passível de licenciamento");
        })
    }

    async obterAtividadeCalculoDoParametroDeEntradaAut(recuperado = false) {
        if (!recuperado) {
            this.parametroCalculoListAut = null;
        }
        await this.atividadeCalculoArrecadacaoService.buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoLicencaAut()).toPromise().then(atividadeCalculo => {
            this.atividadeCalculoAut = atividadeCalculo
            if (!recuperado) {
                this.parametroCalculoListAut = [...this.atividadeCalculoAut].shift().parametroCalculoList;
            }
            if (!this.parametroCalculoListAut) {
                this.snackBarService.showAlert("Não há licença disponível");
            } else {
                for (let i = 0; i < this.parametroCalculoListAut.length; i++) {
                    if (this.parametroCalculoListAut[i].labelFormulario === this.parametroCalculoListAut[0].labelFormulario) {
                        let event = {
                            target: {
                                value: this.parametroEntradaAut.valor
                            }
                        }
                        this.pegarValorDeParamCalc(event, this.parametroCalculoListAut[i], this.atividadeCalculo)
                        this.parametroCalculoListAut.splice(i, 1)
                        this.parametroCalculoListAut = this.parametroCalculoListAut.slice()
                    }
                }
            }
        }).catch(() => {
            this.snackBarService.showAlert("Ocorreu um erro ao buscar parametros de entrada");
        })
    }

    validacaoAtividadeIsenta() {
        let titulacaoIsenta = true
        for (let i = 0; i < this.calcularUpfRetornoDtoList.length; i++) {
            if (this.calcularUpfRetornoDtoList[i].valorUpf === 0 && this.calcularUpfRetornoDtoList.length === 1) {
                titulacaoIsenta = false
                this.snackBarService.showAlert('Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020');
            }
        }
        return titulacaoIsenta;
    }

    validacaoAtividadeIsentaAut(calculoUpf) {
        let titulacaoIsenta = true
        if (calculoUpf.valorUpf === 0) {
            titulacaoIsenta = false
        }
        return titulacaoIsenta;
    }

    montarObjetoLicenca() {

        return {
            idAtividade: this.atividade.id,
            parametroEntrada: this.parametroEntrada,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp,
            idTipoTitulacao: this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id,
        }
    }

    montarObjetoLicencaAut() {

        return {
            idAtividade: this.atividadeAut ? this.atividadeAut.id : this.atividade.id,
            parametroEntrada: this.parametroEntradaAut,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp,
            idTipoTitulacao: this.objetivosPrincipaisSelecionados[0].objetivo.tipoTitulacao.id,
        }
    }

    selecionarSubGrupoAtividade(atividade: any) {
        this.atividadesGrp = this.atividadeLicenciada.filter(atv => atv.atividade.atividadePai.id === atividade.atividade.atividadePai.id)
        this.atividadeCompLicenciadaEncontrado = this.atividadesGrp
    }

    async deletarAtividadeLicenciada() {
        await this.deletarTudoRelacionado()
    }

    async deletarAtividadeNaBase(atividade) {
        this.requerimentoLicenciamento.idAtividade = null
        this.requerimentoLicenciamento.idGrupoAtividade = null
        this.requerimentoLicenciamento.descricaoAtividade = ''
        this.requerimentoLicenciamento.ppd = ''
        this.requerimentoLicenciamento.descricaoGrupoAtividade = ''
        await this.requerimentoLicenciamentoService.update(this.requerimentoLicenciamento).toPromise()
        this.atividadeSelecionadaTable.splice(atividade, 1)
        this.atividadeSelecionadaTable = this.atividadeSelecionadaTable.slice()
        this.subGrupo = []
        this.atividadesGrp = []
    }

    selecionarAtividadeComplementar(atividade: any) {
        this.atividadeComplementar = atividade
    }

    async criaAtividadeComplementar() {
        let objetivoLicenciamentoSubatividade = new ObjetivoLicenciamentoSubatividade()
        objetivoLicenciamentoSubatividade.requerimentoLicenciamento = this.requerimentoLicenciamento
        objetivoLicenciamentoSubatividade.idAtividadeComplementar = this.atividadeComplementar.atividade.id
        if (this.atividadeComplementar.poluicao) {
            objetivoLicenciamentoSubatividade.ppdAtividadeComplementar = this.atividadeComplementar.poluicao.descricao
        }
        await this.objetivoLicenciamentoSubatividadeService.create(objetivoLicenciamentoSubatividade).toPromise()
    }

    async selecionarAtividadeComplementarNaTable() {
        if (this.atividadeSelecionadaTable.length > 0) {
            if (this.atividadeComplementar.atividade.id === this.atividadeSelecionadaTable[0].atividade.id) {
                this.snackBarService.showAlert('A atividade complementar informada não pode ser igual a atividade informada como licenciada')
            } else {
                if (this.grupoSubGrupoSelecionadaTable.length === 0) {
                    await this.criaAtividadeComplementar()
                    await this.preencherAtividadeComplementar()
                } else {
                    this.snackBarService.showAlert('Já existe uma atividade selecionada')
                }
            }
        } else {
            this.snackBarService.showAlert('Adicione uma atividade licenciada.')
        }
    }

    async preencherAtividadeComplementar() {
        this.objetivoLicenciamentoSubatividadeService
            .getRequerimentoLicenciamentoPorId(this.requerimentoLicenciamento.id).toPromise()
            .then(result => {
                if (result) {
                    let atividade = this.atividadeLicenciada.find(atv => atv.atividade.id === result.idAtividadeComplementar);
                    if (atividade) {
                        this.grupoSubGrupoSelecionadaTable.push(atividade);
                    }
                    this.grupoSubGrupoSelecionadaTable = this.grupoSubGrupoSelecionadaTable.slice()
                }
            })
    }

    deletarAtividadeComplementar(atividadeComplementar: any) {
        this.objetivoLicenciamentoSubatividadeService
            .deletePorIdsLicenAndAtividade(this.requerimentoLicenciamento.id, atividadeComplementar.atividade.id)
            .toPromise().then(() => {
                this.grupoSubGrupoSelecionadaTable.splice(atividadeComplementar, 1)
                this.grupoSubGrupoSelecionadaTable = this.grupoSubGrupoSelecionadaTable.slice()
            })
    }

    async deletarTudoRelacionado() {
        if (this.grupoSubGrupoSelecionadaTable.length > 0) {
            await this.deletarAtividadeComplementar(this.atividadeLicenciada.find(atv => atv.atividade.id === this.grupoSubGrupoSelecionadaTable[0].atividade.id))
        }

        let paramParaDelete = []
        if (this.parametroEntradas && this.parametroEntradas.length > 0) {
            let lancamentoTaxa = this.parametroEntradas.filter(lanc => lanc.lancamentoTaxa)[0]
            if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                return
            }
            for (let param of this.parametroEntradas) {
                if (param.id && lancamentoTaxa) {
                    paramParaDelete.push(param)
                }
            }
        }

        if (this.parametroCalculoList && this.parametroCalculoList.length > 0) {
            let lancamentoTaxa = this.parametroCalculoList.filter(lanc => lanc.lancamentoTaxa)[0]
            if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                return
            }
            for (let param of this.parametroCalculoList) {
                if (param.id && lancamentoTaxa) {
                    paramParaDelete.push(param)
                }
            }
        }

        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS
            && this.parametroCalculoListAut && this.parametroCalculoListAut.length > 0) {
            let lancamentoTaxa = this.parametroCalculoListAut.filter(lanc => lanc.lancamentoTaxa)[0]
            if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                return
            }
            for (let param of this.parametroCalculoListAut) {
                if (param.id && lancamentoTaxa) {
                    paramParaDelete.push(param)
                }
            }
        }

        if (paramParaDelete.length > 0) {
            await this.lancamentoTaxaService.deletaTodos(paramParaDelete).toPromise()
        }
        this.parametroCalculoList = []
        this.parametrosCalculoLista = []
        this.parametroEntradas = []
        this.parametroEntrada = {}
        this.parametroCalculoListAut = []

        if (this.atividadeSelecionadaTable.length > 0) {
            await this.deletarAtividadeNaBase(this.atividadeLicenciada.find(atv => atv.atividade.id === this.requerimentoLicenciamento.idAtividade))
        }
    }

    async salvar() {
        let validado = false
        await this.requerimentoFormularioLicenciamentoService.salvarFormularioCalculoDeLicencaEmp(await this.montarFormularioReqLicenDTO()).toPromise().then(formularioReqLicenciamentoDto => {
            this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
            validado = true
        }).catch(error => {
            let message = 'Ocorreu um erro';
            if (error && error.error && error.error.errors && error.error.errors.length > 0) {
                message = error.error.errors.join('<br>');
            }
            this.snackBarService.showError(message);
        });

        return validado
    }

    async findByNumeroTitulo() {
        if (this.reqsTituloVinculadoSave.length === 0 ||
            this.reqsTituloVinculadoSave.some(reT => reT.numeroProtocoloVinculado !== this.numeroTituloReferente || reT.numeroEmissao !== this.numeroTituloReferente)) {
            if (this.numeroTituloReferente.trim().length > 0) {
                if (!this.emissaoRefTitLegado) {
                    await this.emissaoTituloService
                        .readByEmissaoTituloByNumeroProcesso(this.numeroTituloReferente)
                        .toPromise().then(result => {
                            for (let emissao of result) {
                                //this.reqsTituloVinculadoSave.push(this.criaReqTituloVin('TITULO_EXISTENTE', false, emissao, null, this.numeroTituloReferente));
                                const emissaoFilter = this.reqsTituloVinculadoSave.find(reqTitulo => reqTitulo.emissaoTitulo && reqTitulo.emissaoTitulo.id === emissao.id)
                                if (!emissaoFilter) {
                                    this.reqsTituloVinculadoSave.push(this.criaReqTituloVin('TITULO_EXISTENTE', false, emissao, null, this.numeroTituloReferente));
                                } else {
                                    this.snackBarService.showAlert('Título já adicionado');
                                }
                            }

                            if (this.reqsTituloVinculadoSave.length > 0) {
                                this.reqTituloVinculadoService.saveAll(this.reqsTituloVinculadoSave).subscribe(() => {
                                    this.carregarReqTituloVinculado('TITULO_EXISTENTE')
                                })
                            }

                        }).catch(() => this.snackBarService.showAlert('Não foi possível encontrar o título solicitado'));
                } else if (this.requerimento.tipoProcesso.instanciaRequerimento === "LICENCIAMENTO_TRIFASICO") {
                    this.licencasFiscalizacao = await this.simlamService.pesquisarLicencas(this.numeroTituloReferente).toPromise().catch(() => {
                        this.snackBarService.showError('Os serviços do SIMLAM encontram-se indisponíveis, favor tente mais tarde');
                        return null;
                    });
                    // let processos = await this.simlamService.pesquisarLicencas(this.numeroTituloReferente).toPromise().catch(() => {
                    //     this.snackBarService.showError('Os serviços do SIMLAM encontram-se indisponíveis, favor tente mais tarde');
                    //     return null;
                    // });
                    if (!this.licencasFiscalizacao || this.licencasFiscalizacao.length == 0) {
                        this.adicionarTituloLegado(null, this.requerimento);
                    } else {
                        this.adicionarTituloLegado(this.licencasFiscalizacao, this.requerimento);
                    }

                } else {
                    this.adicionarTituloLegado(null, this.requerimento);
                }
            } else {
                this.snackBarService.showAlert('Insira o número do título para fazer a consulta');
            }
        } else {
            this.snackBarService.showAlert('Existe um título vinculado a esse requerimento.');
        }
    }

    private adicionarTituloLegado(licencas: Array<LicencaFiscalizacao>, requerimento: Requerimento): void {
        if (licencas !== null) {
            licencas.forEach(licenca => {
                if (this.licencasFiscalizacao.some(l => l.numeroProcesso !== licenca.numeroProcesso)) {
                    this.licencasFiscalizacao.push(licenca);
                }
            });
        } else {
            const licencaFiscalizacao = new LicencaFiscalizacao;
            licencaFiscalizacao.numeroProcesso = this.numeroTituloReferente;
            this.licencasFiscalizacao.push(licencaFiscalizacao);
        }

        this.reqTituloVinculadoService.saveAllRequerimentoTitulosVinculados(this.licencasFiscalizacao, this.requerimento).subscribe(() => {
            this.carregarReqTituloVinculado('TITULO_EXISTENTE')
        })
    }

    carregarReqTituloVinculado(tipoVinculo: string): void {
        if (this.requerimento) {
            this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, tipoVinculo).subscribe(async result => {
                let vinculado = await this.adicionaProcessoVinculado(tipoVinculo);
                if (vinculado != null && vinculado.length > 0) {
                    result.push(...vinculado);
                }

                // for (let v of result) {
                //     if (v.requerimentoVinculado && v.requerimentoVinculado.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO) {
                //         let processos = await this.simlamService.pesquisarLicencas(v.numeroEmissao).toPromise().catch(() => {
                //             this.snackBarService.showError('Os serviços do SIMLAM encontram-se indisponíveis, favor tente mais tarde');
                //             return null;
                //         });
                //         if (processos && processos.length > 0) {
                //             v.processoSimlam = processos.pop();
                //         }
                //     }
                // }

                switch (tipoVinculo) {
                    case 'LO_VINCULADA':
                        this.reqsNumeroLOSave = result;
                        break;
                    case 'TITULO_EXISTENTE':
                        this.regrasTitulosVinculados(result)
                        this.reqsTituloVinculadoSave = result;
                        break;
                    case 'LP_TRAMITACAO':
                        this.lpTramitacaoVin = result;
                        break;
                }

                await this.adicionaProcessoVinculado(tipoVinculo);
                await this.buscaReferenciaTituloVinculado();
            })
        }
    }

    async regrasTitulosVinculados(titulosVinculados) {
        titulosVinculados = await titulosVinculados.filter(titulo => !titulo.legado)

        if (titulosVinculados.find(titulo => !titulo.legado &&
            titulo.emissaoTitulo.requerimentoTitulacao.titulacao.descricao.toLowerCase().includes("LP - Licença Prévia".toLowerCase()))) {
            //Regras para titulos vinculados com LP
            if ((this.requerimento.tipoRequerimento.isFluxoAlterar && !this.requerimento.tipoRequerimento.isFluxoAmpliacao) &&
                this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO) {
                this.regrasSuimis = "suimisFluxoAlteracaoComProtocoloLp"
                this.desabilitaEdicaoAba5 = true
            } else if(this.requerimento.tipoRequerimento.isFluxoRenovar &&
                this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO){
                this.regrasSuimis = "suimisFluxoRenovacaoComProtocoloLp"
            }
        }else if(titulosVinculados.find(titulo => !titulo.legado &&
            titulo.emissaoTitulo.requerimentoTitulacao.titulacao.descricao.toLowerCase().includes("LOPM - Licença de Operação de Pesquisa Mineral".toLowerCase()))){
            //Regras para titulos vinculados com LOPM
            if ((this.requerimento.tipoRequerimento.isFluxoAlterar && !this.requerimento.tipoRequerimento.isFluxoAmpliacao) &&
                this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO) {
                this.regrasSuimis = "suimisFluxoAlteracaoComProtocoloLopm"
            }
        }
    }

    private async adicionaProcessoVinculado(_: string): Promise<RequerimentoTituloVinculado[]> {
        if (this.requerimento.numeroProtocoloVinculado) {
            let resposta = await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise();
            if (resposta && resposta.length > 0) {
                let titulo = await this.emissaoTituloService.buscaEmissoesPorIdRequerimentoDocumentoProcesso(resposta[0].id).toPromise().catch(() => null);
                if (titulo.length > 0) {
                    return titulo.map(item => <RequerimentoTituloVinculado>{
                        id: item.id,
                        requerimento: this.requerimento,
                        emissaoTitulo: item,
                        numeroEmissao: item.numeroEmissao,
                        requerimentoVinculado: item.requerimentoTitulacao.requerimento,
                        tipoVinculoRequerimento: 'TITULO_EXISTENTE',
                        legado: false,
                        titulacao: item.requerimentoTitulacao.titulacao,
                        vinculado: true,
                        situacao: item.situacao,
                        tipo: item.requerimentoTitulacao.titulacao.tipo,
                        numeroProtocoloVinculado: item.requerimentoTitulacao.requerimento.numeroProcesso
                    });
                }
            }
        }
        return null;
    }

    setRefTituloVin(value: boolean) {
        this.emissaoRefTitLegado = value
    }

    setLOTituloVin(value: boolean) {
        this.loVinLegado = value
    }

    criaReqTituloVin(tipoVinculo, isLegado, emissaoTitulo = null, requerimentoVinculado = null, numeroFiltro = null) {
        const reqTituloVinculado = new RequerimentoTituloVinculado();
        reqTituloVinculado.requerimento = this.requerimento;
        console.log("tipoVinculo >> ", tipoVinculo);
        if (tipoVinculo === 'TITULO_EXISTENTE') {
            reqTituloVinculado.tipoVinculoRequerimento = 'TITULO_EXISTENTE';
            reqTituloVinculado.numeroProtocoloVinculado = emissaoTitulo !== null ? emissaoTitulo.requerimentoTitulacao.requerimento.numeroProcesso : this.numeroTituloReferente;
            reqTituloVinculado.numeroEmissao = emissaoTitulo !== null ? emissaoTitulo.numeroEmissao : null;
            reqTituloVinculado.situacao = emissaoTitulo !== null ? emissaoTitulo.situacao : null;
            reqTituloVinculado.tipo = emissaoTitulo !== null ? emissaoTitulo.requerimentoTitulacao.titulacao.descricao : null;
            console.log("tipoVinculo === 'TITULO_EXISTENTE'", reqTituloVinculado);
        } else if (tipoVinculo === 'LO_VINCULADA') {
            reqTituloVinculado.tipoVinculoRequerimento = 'LO_VINCULADA'
            reqTituloVinculado.numeroEmissao = this.numeroLO
        } else if (tipoVinculo === 'LP_TRAMITACAO') {
            reqTituloVinculado.tipoVinculoRequerimento = 'LP_TRAMITACAO'
            reqTituloVinculado.requerimentoVinculado = requerimentoVinculado
            reqTituloVinculado.numeroEmissao = requerimentoVinculado.numeroProcesso
        }

        if (isLegado) {
            reqTituloVinculado.legado = isLegado
        } else {
            reqTituloVinculado.legado = isLegado
            reqTituloVinculado.emissaoTitulo = emissaoTitulo
        }
        return reqTituloVinculado
    }

    deletarReqTituloVinculado(id: any, tipoVinculo) {
        this.reqTituloVinculadoService.delete(id).subscribe(() => {
            this.carregarReqTituloVinculado(tipoVinculo)
            this.snackBarService.showSuccess('Título Vinculado deletado com Sucesso');
        }, () => this.snackBarService.showError('Ocorreu um erro ao tentar deletar o título.'))
    }

    findByTermoReferencia() {
        this.termoReferenciaService
            .buscarPorTermoReferenciaPorNumeroEDataValidade(this.numeroTermoReferente).subscribe(termoReferencia => {
                if (termoReferencia.find(tr => tr.numero === this.numeroTermoReferente)) {
                    this.requerimento.termoReferencia = termoReferencia.find(tr => tr.numero === this.numeroTermoReferente)
                    this.requerimentoService.update(this.requerimento).subscribe(() => {
                        this.carregarTermoRef()
                    })
                } else {
                    this.snackBarService.showAlert('Não foi possível encontrar o termo de referência solicitado')
                }
            })
    }

    carregarTermoRef() {
        this.requerimentoService.getById(this.requerimento.id).subscribe(requerimento => {
            this.reqsTermoRefSave = []
            this.requerimento = requerimento || this.requerimento;
            this.requerimentoLicenciamento.requerimento = this.requerimento
            if (this.requerimento.termoReferencia) {
                this.reqsTermoRefSave.push(this.requerimento.termoReferencia)
                this.reqsTermoRefSave = this.reqsTermoRefSave.slice()
            }
        })

    }

    deletarReqTermoVinculado() {
        this.requerimento.termoReferencia = null
        this.requerimentoService.update(this.requerimento).subscribe(() => {
            this.carregarTermoRef()
        })
    }

    async findOperaLO() {
        if (this.reqsNumeroLOSave.length === 0) {
            if (this.numeroLO.trim().length > 0) {
                if (!this.loVinLegado) {
                    await this.emissaoTituloService
                        .readByEmissaoTituloNumeroEmissaoAndReqTitulacaoId(this.numeroLO)
                        .toPromise().then(async result => {
                            for (let emissao of result) {
                                if (emissao.requerimentoTitulacao.titulacao.descricao.includes('LO - Licença de Operação')) {
                                    const reqLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(emissao.requerimentoTitulacao.requerimento.id).toPromise()
                                    if (!reqLicenciamento) {
                                        this.snackBarService.showAlert('Esse título encontrado não é um Licenciamento')
                                        return
                                    }
                                    if (this.requerimentoLicenciamento.idAtividade) {
                                        if (reqLicenciamento.idAtividade !== this.requerimentoLicenciamento.idAtividade) {
                                            this.snackBarService.showAlert('A atividade informada no requerimento deve ser igual a atividade da LO - Licença de Operação')
                                            return
                                        }
                                    } else {
                                        this.snackBarService.showAlert('Precisa associar uma atividade a ser licenciada para vincular a uma LO')
                                        return
                                    }

                                    const emissaoFilter = this.reqsNumeroLOSave.find(reqTitulo => reqTitulo.emissaoTitulo.id === emissao.id)
                                    if (!emissaoFilter) {
                                        this.reqsNumeroLOSave.push(this.criaReqTituloVin('LO_VINCULADA', false, emissao));
                                    } else {
                                        this.snackBarService.showAlert('LO já adicionado');
                                    }
                                } else {
                                    this.snackBarService.showAlert('O Título informado deve ser uma LO - Licença de Operação');
                                    return
                                }
                            }

                            if (this.reqsNumeroLOSave.length > 0) {
                                this.reqTituloVinculadoService.saveAll(this.reqsNumeroLOSave).subscribe(() => {
                                    this.carregarReqTituloVinculado('LO_VINCULADA')
                                })
                            }
                        }).catch(() => this.snackBarService.showAlert('Não foi possível encontrar o título solicitado'));
                } else {
                    this.reqsNumeroLOSave.push(this.criaReqTituloVin('LO_VINCULADA', true));

                    if (this.reqsNumeroLOSave.length > 0) {
                        this.reqTituloVinculadoService.saveAll(this.reqsNumeroLOSave).subscribe(() => {
                            this.carregarReqTituloVinculado('LO_VINCULADA')
                        })
                    }
                }
            } else {
                this.snackBarService.showAlert('Insira o número da LO para vincular');
            }
        } else {
            this.snackBarService.showAlert('Existe uma LO vinculado a esse requerimento.');
        }
    }

    pesquisaSubGrupoAtividade() {
        if (this.atividadeSubGrupoDesc.trim().length === 0) {
            this.atividadeLicenciadaSubGrupoEncontrado = this.subGrupo;
            return;
        } else if (this.atividadeSubGrupoDesc.trim().length > 0) {
            this.atividadeLicenciadaSubGrupoEncontrado = this.subGrupo.filter(atv => atv.atividade.descricao.normalize("NFD").toLowerCase().includes(this.atividadeSubGrupoDesc.normalize("NFD").toLowerCase()))
        }
    }

    pesquisaAtividadeComp() {
        if (this.atividadeAtvCompDesc.trim().length === 0) {
            this.atividadeCompLicenciadaEncontrado = this.atividadesGrp;
            return;
        } else if (this.atividadeAtvCompDesc.trim().length > 0) {
            this.atividadeCompLicenciadaEncontrado = this.atividadesGrp.filter(atv => atv.atividade.descricao.normalize("NFD").toLowerCase().includes(this.atividadeAtvCompDesc.normalize("NFD").toLowerCase()))
        }
    }

    async findByReqTramitacao() {
        const dialogRef = await this.dialog.open(DialogConfirmacaoComponent,
            {
                width: '500px',
                data: {
                    message: 'Existe um requerimento de ' +
                        'licença prévia ou ampliação de licença prévia em trâmite ?'
                }
            });

        await dialogRef.afterClosed().toPromise().then(async result => {
            if (!!result) {
                if (this.lpTramitacaoVin.length === 0 ||
                    this.lpTramitacaoVin.filter(reT => reT.vinculado).length === 1 && this.lpTramitacaoVin.length === 1) {
                    if (this.lpTramitacaoProcesso.trim().length > 0) {

                        await this.requerimentoService.getRequerimento(this.lpTramitacaoProcesso).toPromise()
                            .then(async result => {
                                if (result && result.length > 0) {
                                    for (let req of result) {
                                        let reqLicen = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(req.id).toPromise()
                                        let objetivos = await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(reqLicen.id).toPromise()
                                        req = reqLicen.requerimento

                                        objetivos = objetivos.filter(obj => obj.descricaoObjetivo.toLowerCase() === 'LP - Licença Prévia'.toLowerCase()
                                            || obj.descricaoObjetivo.toLowerCase().includes('LP - Licença Prévia de ampl'.toLowerCase()))

                                        if (objetivos && objetivos.length > 0) {
                                            const reqFilter = this.lpTramitacaoVin.find(rq => rq.requerimentoVinculado.id === req.id)
                                            if (!reqFilter) {
                                                let arrayDesc = []
                                                for (let objs of objetivos) {
                                                    arrayDesc.push(objs.descricaoObjetivo)
                                                }
                                                const dialog2 = await this.dialog.open(DialogConfirmacaoComponent,
                                                    {
                                                        width: '500px',
                                                        data: {
                                                            message: `Após o envio da solicitação, a análise do processo de requerimento de ${arrayDesc.join(' ,')} - ${reqLicen.requerimento.numeroProcesso}} será suspensa, por ser atividade passível (EIA/RIMA). Deseja continuar?`
                                                        }
                                                    });

                                                await dialog2.afterClosed().toPromise().then(async result => {
                                                    if (!!result) {
                                                        this.lpTramitacaoVin.push(this.criaReqTituloVin('LP_TRAMITACAO', false, null, req));
                                                        localStorage.setItem("requerimentoVinculadoLpTramitacao", `${arrayDesc.join(' ,')} - ${reqLicen.requerimento.numeroProcesso}`);
                                                    }
                                                })
                                            } else {
                                                this.snackBarService.showAlert('LP já adicionada!');
                                            }
                                        } else {
                                            this.snackBarService.showAlert('Não é permitido vincular esse processo ao requerimento.');
                                        }
                                    }

                                    if (this.lpTramitacaoVin.length > 0) {
                                        this.reqTituloVinculadoService.saveAll(this.lpTramitacaoVin).subscribe(() => {
                                            this.carregarReqTituloVinculado('LP_TRAMITACAO')
                                        })
                                    }
                                } else {
                                    this.snackBarService.showAlert('Não encontrado nenhum processo -> ' + this.lpTramitacaoProcesso);

                                }
                            }).catch(() => this.snackBarService.showAlert('Não foi possível encontrar a LP'));
                    } else {
                        this.snackBarService.showAlert('Insira o número da LP para fazer a consulta');
                    }
                } else {
                    this.snackBarService.showAlert('Existe uma LP vinculado a esse requerimento.');
                }
            }
        });

    }

    numeroRequerimentoMask(text, origem): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);

                switch (origem) {
                    case 'referenciaProcessoExistente': {
                        this.numeroTituloReferente = `${initial}/${year}`;
                        break;
                    }
                    case 'lpPreviaTramitacao': {
                        this.lpTramitacaoProcesso = `${initial}/${year}`;
                        break;
                    }
                }
            }
        }

    }

    openFileArquivoSimlam(idOrigemSimlam: number) {
        var urlArquivo = `${environment.URL_LIC_SIMLAM_ARQUIVO}?idTitulo=${idOrigemSimlam}`;
        window.open(urlArquivo, '_blank');
    }

    private verificaSeDesabilitaParametrosAtividade() {
        this.isDesabilitaParametrosAtividade = (this.requerimento.situacaoProcesso !== 'EM_CORRECAO' && this.requerimento.situacaoProcesso !== 'EM_CORRECAO_CADASTRO'
            && this.requerimento.situacaoProcesso !== 'EM_CADASTRO')
            || this.currentAction === 'detail'
            || (!this.isFluxoPermiteAlteraParametroAtividade());
    }

    isFluxoPermiteAlteraParametroAtividade(): boolean {
        return this.requerimento.tipoRequerimento.isFluxoAlterar
            || this.requerimento.tipoRequerimento.isFluxoAmpliacao
            || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoEmitir;

    }

    private habilitarEdicaoAtividadeComplementar() {
        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.habilitaAtividadeLicenciada = true;
        } else {
            this.habilitaAtividadeLicenciada = false;
        }
    }

}
