import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export function numeroIgualSuperiorDirective(form: FormGroup, nestedForm: string, propriedadeOutroValor: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let formValue: any = nestedForm && nestedForm.length > 0 && form.get(nestedForm) ? form.get(nestedForm) : form;

        if (control.value && control.value.length) {
            if (formValue && formValue.get(propriedadeOutroValor) && formValue.get(propriedadeOutroValor).value) {
                let valor = Number(formValue.get(propriedadeOutroValor).value);
                let numero = Number(control.value);
                if (numero >= valor) {
                    return {numeroMaiorValor: true}
                }
            }

        }
        return null;
    }
}
