export class TitulacaoDocumentosDTO {
    constructor(
        public id?: number,
        public modeloDocumento?: string,
        public dataCriacao?: string,
        public tipoTitulacaoDocumentoDTO?: string,
        public setorResponsavel?: string,
        public numeroEmissao?: string,
        public tipoEmissao?: string,
        public validadeTitulacao?: string,
        public temDocumento?: boolean,
        public chave?: string,
        public idRequerimento?: number,
        public idEmissaoTitulo?: number,
        public legado?: boolean,
        public grupoDocumento?: string
    ) {
    }

    static fromJson(json: any): TitulacaoDocumentosDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new TitulacaoDocumentosDTO(
            json.id,
            json.modeloDocumento,
            json.dataCriacao,
            json.tipoTitulacaoDocumentoDTO,
            json.setorResponsavel,
            json.numeroEmissao,
            json.tipoEmissao,
            json.validadeTitulacao,
            json.temDocumento,
            json.chave,
            json.idRequerimento,
            json.idEmissaoTitulo,
            json.legado,
            json.grupoDocumento
        );
    }

    static fromJsons(json: any): TitulacaoDocumentosDTO[] {
        const TitulacaoDocumentosDTOs: TitulacaoDocumentosDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => TitulacaoDocumentosDTOs.push(this.fromJson(item)));
            return TitulacaoDocumentosDTOs;
        }
    }
}