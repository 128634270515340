import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {LancamentoTaxa} from '../../../shared/models/lancamento-taxa.model';
import {BaseEnum} from '../../../shared/enums/base.enum';


@Component({
    templateUrl: 'detalhe-da-guia-dialog.component.html',
    styleUrls: ['./detalhe-da-guia-dialog.component.scss'],
})

export class DetalhesDaGuiaDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<DetalhesDaGuiaDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public lancamentoTaxa: LancamentoTaxa
    ) {
    }

    get statusLancamento(): BaseEnum[] {
        return LancamentoTaxa.enumStatus;
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }
}
