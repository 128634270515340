import {ModelUtil} from "../../../../../../shared/models/model-util";

export class EvapoPrecipMensal {
    constructor(
        public codigoIbgeMunicipio?: number,
        public janeiro?: number,
        public fevereiro?: number,
        public marco?: number,
        public abril?: number,
        public maio?: number,
        public junho?: number,
        public julho?: number,
        public agosto?: number,
        public setembro?: number,
        public outubro?: number,
        public novembro?: number,
        public dezembro?: number,
    ) {
    }

    static fromJson(json: any): EvapoPrecipMensal {
        if (json === undefined || json === null) {
            return null;
        }
        return new EvapoPrecipMensal(
            ModelUtil.getOrElseZero(json.codIbge),
            ModelUtil.getOrElseZero(json.janeiro),
            ModelUtil.getOrElseZero(json.fevereiro),
            ModelUtil.getOrElseZero(json.marco),
            ModelUtil.getOrElseZero(json.abril),
            ModelUtil.getOrElseZero(json.maio),
            ModelUtil.getOrElseZero(json.junho),
            ModelUtil.getOrElseZero(json.julho),
            ModelUtil.getOrElseZero(json.agosto),
            ModelUtil.getOrElseZero(json.setembro),
            ModelUtil.getOrElseZero(json.outubro),
            ModelUtil.getOrElseZero(json.novembro),
            ModelUtil.getOrElseZero(json.dezembro),
        );
    }

}