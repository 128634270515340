import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Camada, GeometriaMapa} from '@sema-geo/sema-geoportal';
import {
    GeoportalBaseReferenciaService
} from "../../../../../shared/services/geoportal-base-referencia/geoportal-base-referencia.service";
import {StatusGeometria, statusGeometriaLabel} from "../../../../../shared/enums/status-geometria.enum";
import {
    AnaliseFeicaoRequerimentoLicenciamentoService
} from "../../../../../shared/services/analise-feicao-requerimento-licenciamento.service";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import Map from 'ol';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Style, Stroke, Fill, Text, Circle } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {minArrayLength} from "../../../../../shared/validators/array.validator";
import {
    InconformidadeFeicaoRequerimentoLicenciamento
} from "../../../../../shared/models/inconformidade-feicao-requerimento-licenciamento.model";

export interface  DialogReprovarGeometrias {
    idRequerimentoLicenciamento: number;
    camada: Camada;
    idFeicaoEntrada: number;
}

@Component({
    selector: 'app-bpm-dialog-reprovar-geometrias',
    templateUrl: './dialog-reprovar-geometrias.component.html',
    styleUrls: ['./dialog-reprovar-geometrias.component.scss'],
})
export class DialogReprovarGeometriasComponent {
    map: Map;
    vectorSource: VectorSource;
    reprovarGeometriaForm: FormGroup;
    camada: Camada;
    areas: any;
    geometrias: GeometriaMapa[];
    wkts: string
    protected readonly statusGeometriaLabel = statusGeometriaLabel;
    constructor(
        private readonly fb: FormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private snackBarService: SnackBarService,
        private dialogRef: MatDialogRef<DialogReprovarGeometriasComponent>,
        private readonly baseReferenciaService: GeoportalBaseReferenciaService,
        private readonly analiseFeicaoRequerimentoLicenciamentoService: AnaliseFeicaoRequerimentoLicenciamentoService,
        @Inject(MAT_DIALOG_DATA) private data: DialogReprovarGeometrias
    ) {
        this.camada = data.camada;
        this.geometrias = [];
        this.areas = {};
        this.reprovarGeometriaForm = this.fb.group({
            inconformidades: this.fb.array([], { validators: [minArrayLength(1)] })
        })
        setTimeout(() => {
            if(data.camada.camadas.length > 0 ){
                this.data.camada.camadas.forEach((geometria: GeometriaMapa) => {
                    if(geometria.extra.statusGeometria === StatusGeometria.EM_ANALISE){
                        this.geometrias.push(geometria);
                        this.areas[geometria.extra.id] = this.baseReferenciaService.getArea(geometria.extra.wkt);
                    }
                })
                this.wkts =this.geometrias.map(geo => geo.extra.wkt).join(',');
            }
        }, 200)

    }
    get inconformidades(): FormArray {
        return this.reprovarGeometriaForm.get('inconformidades') as FormArray;
    }


    onMapReady(map: Map){
        this.vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            source: this.vectorSource
        });
        this.map = map;
        this.map.addLayer(vectorLayer);
        this.map.on('singleclick', (event) => {
            const coordinate = event.coordinate;
            const nextIndex = this.inconformidades.length + 1;
            this.addPoint(nextIndex, coordinate, `Inconformidade ${nextIndex}`);
        });
    }
    addPoint(index: number, coord: any, label: string) {
        const pointFeature = new Feature({
            geometry: new Point(coord),
            name: label
        });

        const pointStyle = new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: 'red'
                }),
                stroke: new Stroke({
                    color: 'white',
                    width: 2
                }),
            }),
            text: new Text({
                text: label,
                offsetY: 15,
                scale: 1.2,
                fill: new Fill({
                    color: 'red'
                }),
                stroke: new Stroke({
                    color: '#fff',
                    width: 3
                })
            })
        });

        pointFeature.setStyle(pointStyle);
        this.vectorSource.addFeature(pointFeature);
        this.addInconformidade(index, coord, pointFeature);
    }
    addInconformidade(index: number, coord: any, feature: Feature){
        this.inconformidades.push(this.fb.group({
            index: new FormControl(index, { validators: [Validators.required] }),
            latitude: new FormControl(coord[1], { validators: [Validators.required] }),
            longitude: new FormControl(coord[0], { validators: [Validators.required] }),
            justificativa: new FormControl(null, { validators: [Validators.required, Validators.maxLength(4000)] }),
            feature: new FormControl(feature, { validators: [Validators.required] }),
        }))
    }
    clear(): void {
        this.inconformidades.clear();
    }

    removeInconformidade(index: number) {
        const form: AbstractControl = this.inconformidades.at(index);
        this.vectorSource.removeFeature(form.value.feature);
        this.inconformidades.removeAt(index);
        this.cdr.detectChanges();
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onReprovar(){
        if(this.reprovarGeometriaForm.valid){
            const inconformidades: InconformidadeFeicaoRequerimentoLicenciamento[] = this.inconformidades.value;
            this.analiseFeicaoRequerimentoLicenciamentoService.reprovarGeometrias(
                this.data.idRequerimentoLicenciamento,
                this.data.idFeicaoEntrada,
                inconformidades
            ).subscribe({
                next: () => {
                    this.dialogRef.close({
                        success: true,
                    });
                },
                error: (error) => this.snackBarService.showError(error.message || "Error ao reprovar a geometria.")
            })
        }

    }
}
