import {GeradorVaporUsinaTerm} from './energia-termica-gerador-vapor.model';
import {EquipamentoMotrizUsinaTerm} from './energia-termica-equipamento-motriz.model';
import {UnidadeContingenciaUsinaTerm} from './energia-termica-unidade-contingencia.model';
import {GeradorEletricoUsinaTerm} from './energia-termica-gerador-eletrico.model';
import {UsinaTipoFossil} from './energia-termica-tipo-fossil.model';
import {UsinaTipoBiomassa} from './energia-termica-tipo-biomassa.model';
import {EmpreendimentoEnergTermica} from './empreendimento-energ-termica';
import {BaseModel} from './base.model';

export class UsinaTermoeletricaDto extends BaseModel {
    constructor(
        public empreendimentoEnergeticoTermica?: EmpreendimentoEnergTermica,
        public listaUsinasBiomassa?: UsinaTipoBiomassa[],
        public listaUsinasFossil?: UsinaTipoFossil[],
        public listaGeradoresEletrico?: GeradorEletricoUsinaTerm[],
        public listaUnidadesContingencia?: UnidadeContingenciaUsinaTerm[],
        public listaEquipamentosMotriz?: EquipamentoMotrizUsinaTerm[],
        public listaGeradoresVapor?: GeradorVaporUsinaTerm[]
    ) {
        super();
    }

    static fromJson(json: any): UsinaTermoeletricaDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new UsinaTermoeletricaDto(
            EmpreendimentoEnergTermica.fromJson(json.empreendimentoEnergeticoTermico),
            UsinaTipoBiomassa.fromJsons(json.listaUsinasBiomassa),
            UsinaTipoFossil.fromJsons(json.listaUsinasFossil),
            GeradorEletricoUsinaTerm.fromJsons(json.listaGeradoresEletrico),
            UnidadeContingenciaUsinaTerm.fromJsons(json.listaUnidadesContingencia),
            EquipamentoMotrizUsinaTerm.fromJsons(json.listaEquipamentosMotriz),
            GeradorVaporUsinaTerm.fromJsons(json.listaGeradorVapor)
        );
    }

    static fromJsons(json: any): UsinaTermoeletricaDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(UsinaTermoeletricaDto.fromJson(j)));
            return resources;
        }
    }
}