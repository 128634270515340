import {BaseModel} from "../base.model";

export class SituacaoIntertravamentoDto extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(json: any): SituacaoIntertravamentoDto {
        json = json || {};

        return new SituacaoIntertravamentoDto(
            json.id,
            json.descricao,
        );
    }
}