import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {EmpreendimentoEnergFotovoltaica} from '../models/empreendimento-energ-fotovoltaica';
import {EnergiaFotovoltaicaModulo} from '../models/energia-fotovoltaica-modulo.model';
import {EnergiaFotovoltaicaPainel} from '../models/energia-fotovoltaica-painel.model';
import {EnergiaFotovoltaicaUnidContigencia} from '../models/energia-fotovoltaica-unid-contigencia.model';
import {EnergiaFotovoltaicaTransformadores} from '../models/energia-fotovoltaica-transformadores.model';
import {EnergiaFotovoltaicaUnidGeradoras} from '../models/energia-fotovoltaica-unid-geradoras.model';

@Injectable({
  providedIn: 'root'
})
export class EmpreendimentoEnergiaFotovoltaicaService {

    private readonly URL_RESOURCE: string = `${environment.URL_EMPREENDIMENTO_EE_FOTOVOLTAICA}/api/empreendimento-fotovoltaica`;

    constructor(private http: HttpClient) { }

    public consultarPorId(idEmpreendimentoFotovoltaico: number): Observable<EmpreendimentoEnergFotovoltaica> {

        if (idEmpreendimentoFotovoltaico) {
            return this.http.get<EmpreendimentoEnergFotovoltaica>( `${this.URL_RESOURCE}${idEmpreendimentoFotovoltaico}` );
        } else {
            return EMPTY;
        }
    }

    public consultarPorIdRequerimento(idRequerimento: number): Observable<EmpreendimentoEnergFotovoltaica> {

        if (idRequerimento) {
            return this.http.get<EmpreendimentoEnergFotovoltaica>( `${this.URL_RESOURCE}/buscar-por-id-requerimento/${idRequerimento}` );
        } else {
            return EMPTY;
        }
    }

    public salvar(empreendimento: EmpreendimentoEnergFotovoltaica): Observable<EmpreendimentoEnergFotovoltaica> {

        return this.http.post<EmpreendimentoEnergFotovoltaica>( this.URL_RESOURCE, empreendimento );
    }

    public salvarModulo(empreendimento: EnergiaFotovoltaicaModulo): Observable<EnergiaFotovoltaicaModulo> {

        return this.http.post<EnergiaFotovoltaicaModulo>( `${this.URL_RESOURCE}/modulo/`, empreendimento );
    }

    public listarModulo(idEmpreendimentoFotovoltaico: number): Observable<EnergiaFotovoltaicaModulo[]> {

        if (idEmpreendimentoFotovoltaico) {
            return this.http.get<EnergiaFotovoltaicaModulo[]>( `${this.URL_RESOURCE}/modulo/${idEmpreendimentoFotovoltaico}` );
        } else {
            return EMPTY;
        }
    }

    public deletarModulo(idEmpreendimentoFotovoltaico: number): Observable<void> {

        return this.http.delete<void>( `${this.URL_RESOURCE}/modulo/${idEmpreendimentoFotovoltaico}` );
    }

    public salvarPainel(empreendimento: EnergiaFotovoltaicaPainel): Observable<EnergiaFotovoltaicaPainel> {

        return this.http.post<EnergiaFotovoltaicaModulo>( `${this.URL_RESOURCE}/painel/`, empreendimento );
    }

    public listarPainel(idEmpreendimentoFotovoltaico: number): Observable<EnergiaFotovoltaicaPainel[]> {

        if (idEmpreendimentoFotovoltaico) {
            return this.http.get<EnergiaFotovoltaicaModulo[]>( `${this.URL_RESOURCE}/painel/${idEmpreendimentoFotovoltaico}` );
        } else {
            return EMPTY;
        }
    }

    public deletarPainel(idEmpreendimentoFotovoltaico: number): Observable<void> {

        return this.http.delete<void>( `${this.URL_RESOURCE}/painel/${idEmpreendimentoFotovoltaico}` );
    }

    public salvarUnidContigencia(empreendimento: EnergiaFotovoltaicaUnidContigencia): Observable<EnergiaFotovoltaicaUnidContigencia> {

        return this.http.post<EnergiaFotovoltaicaUnidContigencia>( `${this.URL_RESOURCE}/unid-contigencia/`, empreendimento );
    }

    public listarUnidContigencia(idEmpreendimentoFotovoltaico: number): Observable<EnergiaFotovoltaicaUnidContigencia[]> {

        if (idEmpreendimentoFotovoltaico) {
            return this.http.get<EnergiaFotovoltaicaModulo[]>( `${this.URL_RESOURCE}/unid-contigencia/${idEmpreendimentoFotovoltaico}` );
        } else {
            return EMPTY;
        }
    }

    public deletarUnidContigencia(idEmpreendimentoFotovoltaico: number): Observable<void> {

        return this.http.delete<void>( `${this.URL_RESOURCE}/unid-contigencia/${idEmpreendimentoFotovoltaico}` );
    }

    public salvarTransformadores(empreendimento: EnergiaFotovoltaicaTransformadores): Observable<EnergiaFotovoltaicaTransformadores> {

        return this.http.post<EnergiaFotovoltaicaTransformadores>( `${this.URL_RESOURCE}/transformadores/`, empreendimento );
    }

    public listarTransformadores(idEmpreendimentoFotovoltaico: number): Observable<EnergiaFotovoltaicaTransformadores[]> {

        if (idEmpreendimentoFotovoltaico) {
            return this.http.get<EnergiaFotovoltaicaTransformadores[]>( `${this.URL_RESOURCE}/transformadores/${idEmpreendimentoFotovoltaico}` );
        } else {
            return EMPTY;
        }
    }

    public deletarTransformadores(idEmpreendimentoFotovoltaico: number): Observable<void> {

        return this.http.delete<void>( `${this.URL_RESOURCE}/transformadores/${idEmpreendimentoFotovoltaico}` );
    }

    public salvarUnidGeradoras(empreendimento: EnergiaFotovoltaicaUnidGeradoras): Observable<EnergiaFotovoltaicaUnidGeradoras> {

        return this.http.post<EnergiaFotovoltaicaUnidGeradoras>( `${this.URL_RESOURCE}/unid-geradoras/`, empreendimento );
    }

    public listarUnidGeradoras(idEmpreendimentoFotovoltaico: number): Observable<EnergiaFotovoltaicaUnidGeradoras[]> {

        if (idEmpreendimentoFotovoltaico) {
            return this.http.get<EnergiaFotovoltaicaUnidGeradoras[]>(`${this.URL_RESOURCE}/unid-geradoras/${idEmpreendimentoFotovoltaico}`);
        } else {
            return EMPTY;
        }
    }

    public deletarUnidGeradoras(idEmpreendimentoFotovoltaico: number): Observable<void> {

        return this.http.delete<void>( `${this.URL_RESOURCE}/unid-geradoras/${idEmpreendimentoFotovoltaico}` );
    }

    public consultarDocumento( nuCpfCnpj: string ): Observable<any> {

        return this.http.get( `${environment.URL_SCORP_API}/api/pessoas/pessoa-dto-sem-validacao-de-cadastro/cpfcnpj/${nuCpfCnpj}` );
    }

}
