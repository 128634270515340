import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Pessoa} from '../../../models/pessoa.model';

@Component({
    templateUrl: 'empreendedor-dialog.component.html'
})

export class EmpreendedorDialogComponent implements OnInit {

    pessoa: Pessoa;

    constructor(private dialogRef: MatDialogRef<EmpreendedorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    ngOnInit(): void {
        this.pessoa = this.data;

    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

}
