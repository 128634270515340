import {BaseEnum} from '../../../../../shared/enums/base.enum';
import {DocumentoRequerimento} from "../../../../documentos-requerimentos/documento-requerimento.model";

export class AguaSubterranea {
    constructor(
        public id?: number,
        public bombeamentoHoras?: number,
        public bombeamentoDias?: number,
        public bombeamentoVazao?: number,
        public possuiPerfuracaoPoco?: boolean,
        public nrAutorizacaoPerfuracao?: string,
        public dataPerfuracao?: Date,
        public declaracaoPerfuracao?: DocumentoRequerimento,
        public tipoAquiferoPorosidade?: string,
        public outrasFontes?: string,
        public especificarOutrasFontes?: string,
        public sistemaIndependente?: boolean,
        public materialRevestimento?: string,
        public materialFiltro?: string,
        public materialPreFiltro?: string,
        public diametroPerfuracao?: number,
        public diametroRevestimento?: number,
        public diametroFiltro?: number,
        public numeroHidrometro?: string,
        public alturaBocaPoco?: number,
        public topoAquifero?: number,
        public baseAquifero?: number,
        public cotaTerreno?: number,
        public tipoBomba?: string,
        public potenciaMotor?: number,
        public crivo?: number,
        public nomeMotor?: string,
        public modeloMotor?: string,
        public marcaMotor?: string,
        public observacoesMotor?: string,
        public tempoBombeamento?: number,
        public metodoMedicaoVazao?: string,
        public horaTesteInicio?: Date,
        public horaTesteFim?: Date,
        public profundidadePoco?: number,
        public empresaExecutoraTeste?: string,
        public vazaoTeste?: number,
        public nivelEstBombeamento?: number,
        public nivelDinBombeamento?: number,
        public vazaoEspecifica?: number,
        public testeBombeamento?: DocumentoRequerimento,
        public testeRecuperacao?: DocumentoRequerimento,
    ) {
    }

    static tiposPorosidade: BaseEnum[] = [
        {valor: 'FISSURAL', descricao: ('Fissural')},
        {valor: 'CARSTIGO', descricao: ('Carstigo')},
        {valor: 'GRANULAR', descricao: ('Granular')}
    ];

    static tiposFontesAbastecimento: BaseEnum[] = [
        {valor: 'CURSO_DAGUA', descricao: ('Curso D’Água')},
        {valor: 'ABASTECIMENTO_PUBLICO', descricao: ('Abastecimento Público')},
        {valor: 'BARRAMENTO', descricao: ('Barramento')},
        {valor: 'OUTROS', descricao: ('Outros')}
    ];

    static tiposBombas: BaseEnum[] = [
        {valor: 'SUBMERSA', descricao: ('Submersa')},
        {valor: 'SUPERFICIE', descricao: ('Superfície')}
    ];

    static fromJson(json: any): AguaSubterranea {
        if (json === undefined || json === null) {
            return null;
        }
        return new AguaSubterranea(
            json.id ? json.id : null,
            json.bombeamentoHoras ? json.bombeamentoHoras : null,
            json.bombeamentoDias ? json.bombeamentoDias : null,
            json.bombeamentoVazao ? json.bombeamentoVazao : null,
            json.possuiPerfuracaoPoco ? json.possuiPerfuracaoPoco : null,
            json.nrAutorizacaoPerfuracao ? json.nrAutorizacaoPerfuracao : null,
            json.dataPerfuracao ? json.dataPerfuracao : null,
            json.declaracaoPerfuracao ? DocumentoRequerimento.fromJson(json.declaracaoPerfuracao) : null,
            json.tipoAquiferoPorosidade ? json.tipoAquiferoPorosidade : null,
            json.outrasFontes ? json.outrasFontes : null,
            json.especificarOutrasFontes ? json.especificarOutrasFontes : null,
            json.sistemaIndependente ? json.sistemaIndependente : null,
            json.materialRevestimento ? json.materialRevestimento : null,
            json.materialFiltro ? json.materialFiltro : null,
            json.materialPreFiltro ? json.materialPreFiltro : null,
            json.diametroPerfuracao ? json.diametroPerfuracao : null,
            json.diametroRevestimento ? json.diametroRevestimento : null,
            json.diametroFiltro ? json.diametroFiltro : null,
            json.numeroHidrometro ? json.numeroHidrometro : null,
            json.alturaBocaPoco ? json.alturaBocaPoco : null,
            json.topoAquifero ? json.topoAquifero : null,
            json.baseAquifero ? json.baseAquifero : null,
            json.cotaTerreno ? json.cotaTerreno : null,
            json.tipoBomba ? json.tipoBomba : null,
            json.potenciaMotor ? json.potenciaMotor : null,
            json.crivo ? json.crivo : null,
            json.nomeMotor ? json.nomeMotor : null,
            json.modeloMotor ? json.modeloMotor : null,
            json.marcaMotor ? json.marcaMotor : null,
            json.observacoesMotor ? json.observacoesMotor : null,
            json.tempoBombeamento ? json.tempoBombeamento : null,
            json.metodoMedicaoVazao ? json.metodoMedicaoVazao : null,
            json.horaTesteInicio ? json.horaTesteInicio : null,
            json.horaTesteFim ? json.horaTesteFim : null,
            json.profundidadePoco ? json.profundidadePoco : null,
            json.empresaExecutoraTeste ? json.empresaExecutoraTeste : null,
            json.vazaoTeste ? json.vazaoTeste : null,
            json.nivelEstBombeamento ? json.nivelEstBombeamento : null,
            json.nivelDinBombeamento ? json.nivelDinBombeamento : null,
            json.vazaoEspecifica ? json.vazaoEspecifica : null,
            json.testeBombeamento ? DocumentoRequerimento.fromJson(json.testeBombeamento) : null,
            json.testeRecuperacao ? DocumentoRequerimento.fromJson(json.testeRecuperacao) : null,
        );
    }
}
