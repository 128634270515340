import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectRadioComponent} from './select-radio.component';
import {FormsModule} from '@angular/forms';
import {SelectItemModule} from '../select-item/select-item.module';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [SelectRadioComponent],
  exports: [SelectRadioComponent, SelectItemModule],
    imports: [
        CommonModule,
        FormsModule,
        SelectItemModule,
        MatRadioModule,
        MatTooltipModule
    ],
})
export class SelectRadioModule {

}
