import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AreaIrrigacao} from "../models/area-irrigacao.model";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class AreaIrrigacaoService {
    constructor(private http: HttpClient) {
    }
    urlResource: string = environment.URL_LIC_FEICAO_IRRIGACAO;

    salvarTipoDeIrrigacao(areaIrrigacao: AreaIrrigacao): Observable<AreaIrrigacao> {
        const url = `${this.urlResource}/api/area-irrigacao`;
        return this.http.post(url, areaIrrigacao)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }
}