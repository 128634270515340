import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-validacao-piscicultura-dialog',
    templateUrl: './validacao-regra-app-dialog.component.html',
    styleUrls: ['./validacao-regra-app-dialog.component.scss']
})
export class ValidacaoRegraAppDialogComponent {

    input: any;

    constructor(
        public dialogRef: MatDialogRef<ValidacaoRegraAppDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.input = data;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
