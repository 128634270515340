export class DocumentoTecnicoDTO {
    constructor(
        public documento?: string,
        public numero?: string,
        public analistas?: string,
        public departamentos?: string,
        public chaveDocumento?: string,
        public chaveParecerConsolidado?: string,
    ) {
    }

    static fromJson(json: any): DocumentoTecnicoDTO | DocumentoTecnicoDTO[] {
        if (json === undefined || json === null) {
            return null;
        }
        if (json instanceof Array) {
            return DocumentoTecnicoDTO.fromJsons(json);
        }
        return new DocumentoTecnicoDTO(
            json.documento,
            json.numero,
            json.analistas,
            json.departamentos,
            json.chaveDocumento,
            json.chaveParecerConsolidado)
    }

    static fromJsons(json: any): DocumentoTecnicoDTO[] {
        const lista: DocumentoTecnicoDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(DocumentoTecnicoDTO.fromJson(item) as DocumentoTecnicoDTO) ;
            });
            return lista;
        }
    }
}
