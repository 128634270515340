import {Component, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ObjetivoRequerimentoOutorgaDTO} from '../../../../../shared/models/objetivo-requerimento-outorga-dto.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {ObjetivoOutorga} from '../../../../../shared/models/objetivo-outorga.model';

export interface ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogData {
    objetivoRequerimentoOutorgaDTO: ObjetivoRequerimentoOutorgaDTO;
}

@Component({
    templateUrl: './confirmar-exclusao-objetivo-requerimento-outorga-dialog.component.html'
})
export class ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

    get tiposOutorga(): BaseEnum[] {
        return ObjetivoOutorga.tiposOutorga;
    }

}
