import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EmpreendimentoService} from "../../../../../../../../../shared/services/empreendimento.service";

@Component({
    selector: 'app-empresa-derivacao',
    templateUrl: './empresa-derivacao.component.html',
    styleUrls: ['./empresa-derivacao.component.scss']
})
export class EmpresaDerivacaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;

    cnpjCpf: FormControl;
    nomeRazaoSocial: FormControl;
    licencaAmbientalEmpresa: FormControl;

    showLicencaAmbiental: boolean = false;

    constructor(
        private empreendimentoService: EmpreendimentoService
    ) {
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.cnpjCpf.value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    ngOnInit(): void {
        this.cnpjCpf = this.form.get('cnpjCpf') as FormControl;
        this.nomeRazaoSocial = this.form.get('nomeRazaoSocial') as FormControl;
        this.licencaAmbientalEmpresa = this.form.get('licencaAmbiental') as FormControl;
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    consultarCpfCnpj(evt: any): void {

        const {value} = evt.target;

        if (!!value) {
            const cpfCnpj = value.replace(/\D+/g, '');

            if (cpfCnpj.length === 11 || cpfCnpj.length === 14) {
                this.empreendimentoService.consultarDocumento(cpfCnpj)
                    .subscribe((retorno) => {
                        if (retorno) {
                            this.showLicencaAmbiental = true;
                            this.form.get('licencaAmbiental').setValidators([Validators.required]);
                            this.form.get('nomeRazaoSocial').setValue(retorno.razaoSocial ? retorno.razaoSocial : retorno.nome);
                        } else {
                            this.showLicencaAmbiental = false;
                            this.form.get('licencaAmbiental').setValidators([]);
                            this.form.get('licencaAmbiental').setValue(undefined);
                            this.form.get('licencaAmbiental').updateValueAndValidity();
                        }
                    });
            }
        }
    }

}
