import {Component, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {RequerimentoOutorgaFinalidadeAtividadeDTO} from '../../../models/requerimento-finalidade-atividade-dto.model';

export interface ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogData {
    requerimentoOutorgaFinalidadeAtividadeDTO: RequerimentoOutorgaFinalidadeAtividadeDTO;
}

@Component({
    templateUrl: './confirmar-exclusao-requerimento-finalidade-atividade-dialog.component.html'
})
export class ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
