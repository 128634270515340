import {Injectable, Injector} from '@angular/core';
import {BaseService} from '../base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {AtividadeCalculoArrecadacao} from '../../models/arrecadacao-models/atividade-calculo-arrecadacao.model';
import {CalcularUpfRetornoDtoArrecadacao} from '../../models/arrecadacao-models/calcular-upf-retorno-dto-arrecadacao.model';

@Injectable({
    providedIn: 'root'
})
export class AtividadeCalculoArrecadacaoService extends BaseService<AtividadeCalculoArrecadacao> {
    private urlResourceArrecadacao = environment.URL_ARRECADACAO_API;

    constructor(protected injector: Injector) {
        super('/api/atividade-calculo', injector, AtividadeCalculoArrecadacao.prototype, AtividadeCalculoArrecadacao.fromJson);
    }

    calcularUpf(calculoUPFDto: any): Observable<CalcularUpfRetornoDtoArrecadacao> {
        return this.http
            .post(`${this.urlResourceArrecadacao}/api/atividade-calculo/calcular-upf`, calculoUPFDto)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarListaAtividadeCalculoPAtividadeParametroEntrada(atividadeDto: any): Observable<AtividadeCalculoArrecadacao[]> {
        return this.http
            .post(`${this.urlResourceArrecadacao}/api/atividade-calculo/licenca`, atividadeDto)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarAtividadeCalculoPAtividade(idAtividade: number): Observable<AtividadeCalculoArrecadacao[]> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/api/atividade-calculo/atividade/${idAtividade}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarDarsEmitidos(search: any): Observable<any[]> {
        const url = `${this.urlResourceArrecadacao}/api/dar/buscar-dars-emitidos`;
        return this.http.post(url, search).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    obterDemonstrativoTaxa(idDar: number): Observable<any> {
        const url = `${this.urlResourceArrecadacao}/api/dar/download-dar/${idDar}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map(res => res),
            catchError(this.handleError.bind(this))
        );
    }

    buscarAtividadeCalculosPorIdTipoTitulacao(idTipoTitulacao: number): Observable<any> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/api/atividade-calculo/tipo-titulacao/${idTipoTitulacao}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

}
