import {BaseModel} from './base.model';
import {Requerimento} from '../../pages/requerimentos/requerimento.model';
import {Despacho} from '../components/despacho-cabecalho/despacho.model';
import {Servidor} from './servidor.model';
import {BaseEnum} from '../enums/base.enum';


export class ApreciarParecerTecnico extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public despacho?: Despacho,
        public servidor?: Servidor,
        public decisao?: string,
        public comentario?: string,
        public dataConclusao?: Date,
    ) {
        super();
    }

    static decisaoEnum: BaseEnum[] = [
        {valor: 'SIM', descricao: 'Sim, concluir parecer conforme decisão assinada'},
        {valor: 'NAO_ENCAMINHAR_AJUSTE_PARECER_TECNICO', descricao: 'Não, encaminhar para ajustes no Parecer Tecnico'},
        {valor: 'NAO_ENCAMINHAR_REANALISE', descricao: 'Não, encaminhar para reanálise'},
    ];

    static fromJson(json: any): ApreciarParecerTecnico {
        if (json === undefined || json === null) {
            return null;
        }
        return new ApreciarParecerTecnico(
            json.id,
            Requerimento.fromJson(json.requerimento),
            Despacho.fromJson(json.despacho),
            Servidor.fromJson(json.servidor),
            json.decisao,
            json.comentario,
            json.dataConclusao,
        );
    }
}