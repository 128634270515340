import {BaseModel} from 'app/main/shared/models/base.model';

export class Email extends BaseModel {

    constructor(
        public id?: number,
        public email?: string,
        public tipo?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public contato?: any
    ) {
        super();
    }

    static fromJson(json: any): Email {
        if (json === undefined || json === null) {
            return null;
        }
        return new Email(
            json.id,
            json.email,
            json.tipo,
            json.dataInicio,
            json.dataFim,
            json.contato
        );
    }

    static fromJsons(json: any): Email[] {
        const lista: Email[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((rt) => {
                lista.push(this.fromJson(rt));
            });
            return lista;
        }
    }
}
