import {Component, Injector, OnInit} from '@angular/core';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RequerimentoOutorgaFinalidadeService} from '../../../../../shared/services/requerimento-outorga-finalidade.service';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {MatDialog} from '@angular/material/dialog';
import {Turbina} from '../../../../../shared/models/turbina.model';
import {TurbinaService} from '../../../../../shared/services/turbina.service';
import {ConfirmarExclusaoDialogComponent} from '../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {GeracaoEnergia, TiposUsinasValue} from '../../../../../shared/models/geracao-energia.model';
import {RequerimentoOutorgaFinalidade} from '../../../../../shared/models/requerimento-outorga-finalidade.model';
import {isNullOrUndefined} from 'util';
import {FormUtil} from '../../../../../shared/util/form-util';
import {MessageDialog, MessageDialogResult} from '../../../../../shared/components/message-dialog/message-dialog.model';
import {pairwise, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-finalidade-geracao-energia',
    templateUrl: './finalidade-geracao-energia.component.html',
    styleUrls: ['./finalidade-geracao-energia.component.scss']
})
export class FinalidadeGeracaoEnergiaComponent extends BaseFinalidadeComponent implements OnInit {
    form: FormGroup;
    formTurbina: FormGroup;
    turbinas: Turbina[] = [];

    totalVazaoNominalTurbinas = 0;
    tiposUsinas = GeracaoEnergia.tiposUsinas;
    mostrarTurbinas: boolean;

    condicaoMostrarTurbinas = (valorCombo: string) => valorCombo && valorCombo !== TiposUsinasValue.TERMOELETRICA;

    condicaoCadastroConcluido = (reqFinalidade?: RequerimentoOutorgaFinalidade) => {
        const geracao = (reqFinalidade ? reqFinalidade : this.requerimentoOutorgaFinalidade).geracaoEnergia;
        if (geracao.tipoUsina != TiposUsinasValue.TERMOELETRICA) {
            const qtTurbinasAdicionadas = this.turbinas.length;
            return !isNullOrUndefined(geracao) && qtTurbinasAdicionadas > 0 && geracao.quantidadeTurbinas == qtTurbinasAdicionadas;
        }
        return !isNullOrUndefined(geracao);
    }

    constructor(
        private formBuilder: FormBuilder,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private turbinaService: TurbinaService,
        protected injector: Injector,
        private snackBarService: SnackBarService,
        private messageDialog: MessageDialog,
        public dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            tipoUsina: [null, Validators.required],
            aproveitamentoHidreletrico: [null, Validators.required],
            potenciaUsina: [null, Validators.required],
            quantidadeTurbinas: [null]
        });

        this.form.get('tipoUsina').valueChanges.pipe(startWith(""), pairwise()).subscribe(([oldValue, newValue]) => {
            if (!this.condicaoMostrarTurbinas(newValue) && this.turbinas.length > 0) {
                this.messageDialog.showQuestion('Tem certeza que deseja alterar o tipo da usina?\nCaso altere, as turbinas cadastradas serão removidas.',
                    'Atenção').subscribe( result => {
                        if (result === MessageDialogResult.Yes) {
                            this.turbinaService.deleteAllByGeracaoEnergia(
                                this.requerimentoOutorgaFinalidade.geracaoEnergia.id).subscribe(result => {
                                this.turbinas = [];
                                this.totalVazaoNominalTurbinas = 0;
                                this.mostrarTurbinas = this.condicaoMostrarTurbinas(newValue);
                                FormUtil.baseOnChange(false, this.form.get('quantidadeTurbinas'));
                                this.form.get('tipoUsina').setValue(newValue);
                                this.form.get('quantidadeTurbinas').setValue(null);
                                this.submit();
                            }, e => {
                                    this.snackBarService.showError('Erro ao remover turbinas', e);
                                    this.form.get('tipoUsina').setValue(oldValue);
                            });
                        } else {
                            this.form.get('tipoUsina').setValue(oldValue);
                        }
                    }
                );
            } else {
                this.mostrarTurbinas = this.condicaoMostrarTurbinas(newValue);
                FormUtil.baseOnChange(this.mostrarTurbinas, this.form.get('quantidadeTurbinas'));
            }
        });

        this.formTurbina = this.formBuilder.group({
            tipoTurbina: [null, Validators.required],
            vazaoNominalUnitaria: [null, Validators.required],
        });

        if (this.requerimentoOutorgaFinalidade.geracaoEnergia) {
            this.form.patchValue(this.requerimentoOutorgaFinalidade.geracaoEnergia);
            this.mostrarTurbinas = this.condicaoMostrarTurbinas(this.requerimentoOutorgaFinalidade.geracaoEnergia.tipoUsina);
            this.listarTurbinas();
        }
    }

    listarTurbinas() {
        if (this.requerimentoOutorgaFinalidade.geracaoEnergia && this.requerimentoOutorgaFinalidade.geracaoEnergia.id) {
            this.turbinaService.getAllByGeracaoEnergia(this.requerimentoOutorgaFinalidade.geracaoEnergia)
                .subscribe(list => {
                    this.turbinas = list;
                    this.totalVazaoNominalTurbinas = this.turbinas.reduce((total, turbina) => total + turbina.vazaoNominalUnitaria, 0);

                    if (this.requerimentoOutorgaFinalidade.cadastroConcluido != this.condicaoCadastroConcluido()) {
                        this.submit(false, false);
                    }
                });
        }
    }

    adicionarTurbina(): void {
        this.formTurbina.markAllAsTouched();
        if (!this.requerimentoOutorgaFinalidade.geracaoEnergia || !this.requerimentoOutorgaFinalidade.geracaoEnergia.id) {
            this.submit();
            return;
        }
        if (this.formTurbina.invalid) {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            return;
        }
        const qtTurbinas = this.form.get('quantidadeTurbinas').value;
        if (qtTurbinas <= this.turbinas.length) {
            this.snackBarService.showAlert('Quantidade total de turbinas inválida, aumente a quantidade de turbinas ou remova registros de turbina.');
            return;
        }
        if (qtTurbinas != this.requerimentoOutorgaFinalidade.geracaoEnergia.quantidadeTurbinas) {
            this.submit(true, false);
        }

        if (this.requerimentoOutorgaFinalidade.geracaoEnergia.id) {
            const atividadeServico = Turbina.fromJson(this.formTurbina.value);
            atividadeServico.geracaoEnergia = {id: this.requerimentoOutorgaFinalidade.geracaoEnergia.id};
            this.turbinaService.create(atividadeServico)
                .subscribe(() => {
                    this.snackBarService.showSuccess('Turbina adicionando com sucesso!');
                    this.formTurbina.reset();
                    this.listarTurbinas();
                }, e => this.snackBarService.showError('Erro ao adicionar turbina', e));
        }
    }

    submit(fromAddTurbina = false, showMessages = true): void {
        if (this.form.get('quantidadeTurbinas').value < this.turbinas.length) {
            this.snackBarService.showAlert('Quantidade total de turbinas inválida, aumente a quantidade de turbinas ou remova registros de turbina.');
            return;
        }
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const copyFinalidade: RequerimentoOutorgaFinalidade = Object.assign(this.requerimentoOutorgaFinalidade);
            if (isNullOrUndefined(copyFinalidade.geracaoEnergia)) {
                copyFinalidade.geracaoEnergia = new GeracaoEnergia();
            }
            Object.assign(copyFinalidade.geracaoEnergia, this.form.getRawValue());
            copyFinalidade.cadastroConcluido = this.condicaoCadastroConcluido(copyFinalidade);
            this.requerimentoOutorgaFinalidadeService.update(copyFinalidade).subscribe(
                (requerimentoOutorgaFinalidade) => {
                    Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                    this.form.patchValue(requerimentoOutorgaFinalidade.geracaoEnergia);
                    this.form.markAsPristine();

                    if (fromAddTurbina && this.formTurbina.valid && this.formTurbina.touched) {
                        this.adicionarTurbina();
                    }

                    if (showMessages) {
                        if (!fromAddTurbina && this.requerimentoOutorgaFinalidade.geracaoEnergia.tipoUsina != TiposUsinasValue.TERMOELETRICA
                            && (isNullOrUndefined(this.turbinas) ||
                                this.turbinas.length != this.requerimentoOutorgaFinalidade.geracaoEnergia.quantidadeTurbinas)) {
                            this.snackBarService.showAlert('A quantidade de turbinas adicionadas deve ser a mesma informada no campo numero de turbinas!')
                        } else {
                            this.snackBarService.showSuccess('Registro salvo com sucesso!');
                        }
                    }
                },
                (e) => {
                    if (showMessages) {
                        this.snackBarService.showError('Erro ao salvar o registro.', e);
                    }
                }
            );
        } else {
            if (showMessages) {
                this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            }

        }
    }

    excluirTurbina(turbina: Turbina): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: turbina.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.turbinaService.delete(turbina).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Turbina excluída com sucesso!');
                        this.listarTurbinas();
                    },
                    (e) => this.snackBarService.showError('Erro ao excluir a Turbina.', e)
                );
            }
        });
    }
}
