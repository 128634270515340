import {Injectable, Injector} from '@angular/core';
import {BaseService} from "../base.service";
import {ConfiguracaoLicenciamento} from "../../models/licenciamento-config-models/configuracao-licenciamento.model";
import {environment} from "../../../../../environments/environment";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ConfiguracaoLicenciamentoService extends BaseService<ConfiguracaoLicenciamento> {

    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            ConfiguracaoLicenciamento.prototype,
            ConfiguracaoLicenciamento.fromJson
        );
        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/configuracaolicenciamento'
    }

    getAllAtivos(): Observable<any> {
        return this.http.get(`${this.urlResource}/ativos`).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

}
