import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ContaCorrenteService} from '../../../../shared/services/arrecadacao-service/conta-corrente.service';
import {DecisaoEnum} from '../../../../shared/enums/decisao.enum';
import {RequerimentoReaproveitamentoService} from '../../../../shared/services/requerimento-reaproveitamento.service';
import {RequerimentoReaproveitamento} from '../../../../shared/models/requerimento-reaproveitamento.model';
import {formatNumber} from '@angular/common';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {RequerimentoIsencaoService} from '../../../../shared/services/requerimento-isencao.service';

@Component({
    selector: 'app-requerimento-reaproveitamento-taxa',
    templateUrl: './requerimento-reaproveitamento-taxa.component.html',
    styleUrls: ['./requerimento-reaproveitamento-taxa.component.scss']
})
export class RequerimentoReaproveitamentoTaxaComponent implements OnInit {
    @Input() requerimento: any;
    @Input() isSomenteVisualizacao: boolean;
    @Output() eventEmitter = new EventEmitter();

    decisaoCredito = 'N';
    creditoUsuario: any;
    isento: any;
    decisaoEnum = DecisaoEnum;
    formCredito: FormGroup;
    reaproveitamentoEmitter: any = [];


    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private contaCorrenteService: ContaCorrenteService,
                private reaproveitamentoService: RequerimentoReaproveitamentoService,
                private requerimentoIsencaoService: RequerimentoIsencaoService,
    ) {
    }

    async ngOnInit() {
        await this.obterSaldoUpf();
        await this.buildReaproveitamentoForm();
        await this.recuperaIsencaoExistente();

        if (this.requerimento) {
            this.recuperaReaproveitamentoCasoExista(this.requerimento.id).then(r => r);
        }
    }

    private buildReaproveitamentoForm(): void {
        this.formCredito = this.formBuilder.group({
            decisaoCredito: [null, Validators.required],
            qtdCredito: [{value: this.creditoUsuario, disabled: true}, [Validators.max(this.creditoUsuario)]],
            qtdUpfAproveitamento: ['', Validators.max(this.creditoUsuario)],
        });
    }

    utilizarCredito(decisao: string): void {
        this.decisaoCredito = decisao;

        if (decisao === 'S') {
            this.formCredito.patchValue({qtdCredito: this.creditoUsuario});
            this.enviarEmitter(decisao, 0);
        } else if (decisao === 'N') {
            this.reaproveitamentoService.deletarReaproveitamentoExistente(this.requerimento.id).toPromise().then(r => r);
            this.formCredito.patchValue({qtdUpfAproveitamento: ''});
            this.enviarEmitter(decisao, 0);
        }
    }

    async usarCredito(): Promise<void> {
        const validate = this.validate();
        if (validate) {
            const reaproveitamento = await this.reaproveitamentoService.retornaReaproveitamentoExistente(this.requerimento.id).toPromise();
            // Retorna qtdeUpf desejada
            const qtdeUpf = this.formCredito.value.qtdUpfAproveitamento;

            if (reaproveitamento) {
                const reqReaproveitamento = new RequerimentoReaproveitamento();
                reqReaproveitamento.requerimento = this.requerimento;
                reqReaproveitamento.qtdUpf = qtdeUpf;
                await this.reaproveitamentoService.atualizarReaproveitamento(reqReaproveitamento).subscribe(() => {
                    this.snackBarService.showSuccess('Reaproveitamento confirmado');
                });
            } else {
                this.salvaReaproveitamento(qtdeUpf);
            }
        }
    }

    validate(): boolean {
        if (this.formCredito.valid && this.decisaoCredito === DecisaoEnum.SIM) {
            const qtdUpfAproveitamento = this.formCredito.value.qtdUpfAproveitamento <= this.creditoUsuario ? this.formCredito.value.qtdUpfAproveitamento : 0;
            if (this.decisaoCredito === DecisaoEnum.SIM && qtdUpfAproveitamento === '' ||
                this.decisaoCredito === DecisaoEnum.SIM && qtdUpfAproveitamento === 0) {
                this.snackBarService.showError('Coloque a quantidade de upf que irá utilizar !');
                return false;
            } else if (this.decisaoCredito === DecisaoEnum.SIM && qtdUpfAproveitamento >= 1) {
                return true;
            }
        } else {
            this.snackBarService.showAlert('O valor máximo para reaproveitar é ' + formatNumber(this.creditoUsuario, 'pt-BR', '2.2-2'));
            return false;
        }
    }

    salvaReaproveitamento(qtdUpf: any): void {
        const reqReaproveitamento = new RequerimentoReaproveitamento();
        reqReaproveitamento.requerimento = this.requerimento;
        reqReaproveitamento.qtdUpf = qtdUpf;
        this.enviarEmitter(this.decisaoCredito, qtdUpf);

        this.reaproveitamentoService.salvaReaproveitamento(reqReaproveitamento).subscribe(() => {
            this.snackBarService.showSuccess('Reaproveitamento confirmado');
        });

    }

    enviarEmitter(decisao: string, qtdCredito: any): void {
        this.reaproveitamentoEmitter.push({decisaoReaproveitamento: decisao, qtdCredito: qtdCredito});
        this.eventEmitter.emit(this.reaproveitamentoEmitter);
    }

    async obterSaldoUpf(): Promise<void> {
        const value = await this.contaCorrenteService.buscarSaldoUpfPorIdPessoa(this.requerimento.pessoa.idCorporativo).toPromise();
        if (value > 0) {
            this.creditoUsuario = value;
        }
    }

    async recuperaReaproveitamentoCasoExista(idRequerimento: number): Promise<void> {
        const reaproveitamento = await this.reaproveitamentoService.retornaReaproveitamentoExistente(idRequerimento).toPromise();
        if (reaproveitamento) {
            this.decisaoCredito = 'S';
            this.formCredito.patchValue({decisaoCredito: 'SIM'});
            this.formCredito.patchValue({qtdCredito: this.creditoUsuario});
            this.formCredito.patchValue({qtdUpfAproveitamento: reaproveitamento.qtdUpf});
            this.enviarEmitter(this.decisaoCredito, reaproveitamento.qtdUpf);
        } else {
            this.decisaoCredito = 'N';
            this.formCredito.patchValue({decisaoCredito: 'NAO'});
            this.enviarEmitter(this.decisaoCredito, 0);
        }
    }

    recuperaIsencaoExistente(): void {
        this.requerimentoIsencaoService.buscaIsencao(this.requerimento.id).subscribe(isencao => {
            this.isento = isencao;
        });
    }

}
