import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {EeLinhaTransmissao} from 'app/main/shared/models/ee-linha-transmissao.model';
import {EeLinhaTransmissaoService} from 'app/main/shared/services/ee-linha-transmissao.service';
import {CaracteristicaEletricaSistemaService} from 'app/main/shared/services/caracteristica-eletrica-sistema.service';
import {EstruturaLinhaTransmissaoService} from 'app/main/shared/services/estrutura-linha-transmissao.service';
import {CircuitoLinhaTransmissaoService} from 'app/main/shared/services/circuito-linha-transmissao.service';
import {BitolaTipoCondutorService} from 'app/main/shared/services/bitola-tipo-condutor-linha-transmissao.service';
import {CaracteristicaEletricaSistema} from 'app/main/shared/models/caracteristica-eletrica-sistema.model';
import {EstruturaLinhaTransmissao} from 'app/main/shared/models/estrutura-linha-transmissao.model';
import {BitolaTipoCondutor} from 'app/main/shared/models/bitola-tipo-condutor-linha-transmissao.model';
import {CircuitoLinhaTransmissao} from 'app/main/shared/models/circuito-linha-transmissao.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'aba-atividade-ee-linha-transmissao',
    templateUrl: './ee-linha-transmissao.component.html',
    styleUrls: ['./ee-linha-transmissao.component.scss']
})
export class EeLinhaTransmissaoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    isNaoPodeEditar: boolean = false;

    form: FormGroup = new FormGroup({});

    constructor(
        protected formBuilder: FormBuilder,
        protected dominiosService: DominiosService,
        protected service: EeLinhaTransmissaoService,
        private caracteristicaService: CaracteristicaEletricaSistemaService,
        private estruturaService: EstruturaLinhaTransmissaoService,
        private circuitoService: CircuitoLinhaTransmissaoService,
        private bitolaCondutorService: BitolaTipoCondutorService,
        private snackBarService: SnackBarService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
        this.initFormControl();
    }

    async ngOnInit() {
        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarEeLinhaTransmissao(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarEeLinhaTransmissao(this.requerimento.id)
            }
        }
        
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
                this.isNaoPodeEditar = true;
                this.form.disable();
        }
    }

    async carregarEeLinhaTransmissao(idRequerimento){
        let res = await this.service.getByRequerimento(idRequerimento).toPromise();

        this.form.patchValue({
            id: res ? res.id : null,
            caracteristica: {},
            estruturas: null,
            circuitos: null,
            bitolaCondutores: null
        });

        if (res && res.id) {
            await this.consulta(res.id);
        }
    }

    async salva() {
        this.form.markAllAsTouched();

        this.valida()

        if (this.form.valid) {

            let req = EeLinhaTransmissao.fromJson(this.form.value);
            req.requerimento = this.requerimento.id;

            if (req.id) {
                this.atualizar(req)
            } else {
                this.criar(req);
            }
        }
    }

    private criar(req: any) {
        this.service.create(req).subscribe(async servico => {
            this.enviarFormConcluido.emit();
            this.form.get('id').setValue(servico.id);

            await Promise.all([
                this.salvaCaracteristica(),
                this.salvaEstruturas(),
                this.salvaCircuitos(),
                this.salvaBitolaTipoCondutores()
            ]);
        })

    }

    private atualizar(req: EeLinhaTransmissao) {
        this.service.update(req).subscribe(async servico => {
            this.enviarFormConcluido.emit();
            this.form.get('id').setValue(servico.id);

            await Promise.all([
                this.salvaCaracteristica(),
                this.salvaEstruturas(),
                this.salvaCircuitos(),
                this.salvaBitolaTipoCondutores()
            ]);
        });
    }

    private initFormControl() {
        this.form = this.formBuilder.group({
            id: [null],
            caracteristica: this.formBuilder.group({
                id: [null,],
                tipoTensaoNominalSistema: [null, [Validators.required]],
                extensaoFaixaServidao: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999)]],
                tipoPotenciaCurToCircuitoTipico: [null,],
                tipoTensaoMaximaOperacao: [null,],
                tipoTensaoSuportavelImpulsoAtmosferico: [null,],
                tipoTensaoSuportavelFrequenciaIndustrial: [null,],
            }),
            estruturas: [null, [Validators.required]],
            circuitos: [null, [Validators.required]],
            bitolas: [null, [Validators.required]],
        });
    }

    private valida() {
        if (!this.form.valid) {
            this.snackBarService.showError("O formulário Empreendimentos Energéticos - Linha de transmissão possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            return;
        }
    }

    private async consulta(id: number) {
        this.caracteristicaService.getByLinhaTransmissao(id)
            .subscribe(caracteristica => this.form.get('caracteristica').patchValue(caracteristica));

        this.estruturaService.getByLinhaTransmissao(id)
            .subscribe(estruturas => this.form.get('estruturas').patchValue(estruturas));

        this.circuitoService.getByLinhaTransmissao(id)
            .subscribe(circuitos => this.form.get('circuitos').patchValue(circuitos));

        this.bitolaCondutorService.getByLinhaTransmissao(id)
            .subscribe(bitolas => this.form.get('bitolas').patchValue(bitolas));
    }


    private async salvaCaracteristica() {
        const caracteristica = CaracteristicaEletricaSistema.fromJson({
            ...this.form.get('caracteristica').value,
            idEntidade: this.form.get('id').value,
        });

        if (caracteristica.id) {
            await this.caracteristicaService.update(caracteristica).toPromise();
        } else {
            await this.caracteristicaService.create(caracteristica).toPromise();
        }

    }

    private async salvaEstruturas() {
        const estruturas = this.form.get('estruturas').value;

        const existentes = await this.estruturaService.getByLinhaTransmissao(this.form.get('id').value).toPromise();

        for (const estrutura of existentes) {
            if (!estruturas.find(e => estrutura.id === e.id)) {
                await this.estruturaService.delete(estrutura.id).toPromise();
            }
        }

        for (let estrutura of estruturas) {
            estrutura = EstruturaLinhaTransmissao.fromJson({
                ...estrutura,
                idEntidade: this.form.get('id').value,
            });

            if (estrutura.id) {
                await this.estruturaService.update(estrutura).toPromise();
            } else {
                await this.estruturaService.create(estrutura).toPromise();
            }
        }

    }

    private async salvaCircuitos() {
        const circuitos = this.form.get('circuitos').value;

        const existentes = await this.circuitoService.getByLinhaTransmissao(this.form.get('id').value).toPromise();

        for (const circuito of existentes) {
            if (!circuitos.find(c => circuito.id === c.id)) {
                await this.circuitoService.delete(circuito.id).toPromise();
            }
        }

        for (let circuito of circuitos) {
            circuito = CircuitoLinhaTransmissao.fromJson({
                ...circuito,
                idEntidade: this.form.get('id').value,
            });

            if (circuito.id) {
                await this.circuitoService.update(circuito).toPromise();
            } else {
                await this.circuitoService.create(circuito).toPromise();
            }
        }

    }

    private async salvaBitolaTipoCondutores() {
        const bitolas = this.form.get('bitolas').value;

        const existentes = await this.bitolaCondutorService.getByLinhaTransmissao(this.form.get('id').value).toPromise();

        for (const bitola of existentes) {
            if (!bitolas.find(b => bitola.id === b.id)) {
                await this.bitolaCondutorService.delete(bitola.id).toPromise();
            }
        }

        for (let bitola of bitolas) {
            bitola = BitolaTipoCondutor.fromJson({
                ...bitola,
                idEntidade: this.form.get('id').value,
            });

            if (bitola.id) {
                await this.bitolaCondutorService.update(bitola).toPromise();
            } else {
                await this.bitolaCondutorService.create(bitola).toPromise();
            }
        }

    }
}
