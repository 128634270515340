import {Component, Input, OnInit} from '@angular/core';
import {
    EmpreendimentoComponenteSubestacao
} from "../../../../../../../../shared/models/empreendimento-componente-subestacao.model";
import {FormControl, FormGroup} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {
    EmpreendimentoComposicaoSubEstacaoService
} from "../../../../../../../../shared/services/empreendimento-componente-subestacao.service";

@Component({
    selector: 'app-componentes-subestacao',
    templateUrl: './componentes-subestacao.component.html',
    styleUrls: ['./componentes-subestacao.component.scss']
})
export class ComponentesSubestacaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
    private $idEmpreendimento: number;

    compoentesSubestacao: FormControl

    displayedColumnsComponenteSubestacao: string[] = ['tipo', 'descricao', 'acoes'];
    tipos: DominioDto[] = [];
    tipoSelecionado: DominioDto;
    descricao: string;
    quantidade: number;

    constructor(private dominiosService: DominiosService,
                private componentesSubestacaoService: EmpreendimentoComposicaoSubEstacaoService) {
    }

    async ngOnInit() {
        this.compoentesSubestacao = this.form.get('componentesSubestacao') as FormControl;
        this.dominiosService.getDominio("TIPO_COMPONENTE_SUBESTACAO")
            .subscribe(tipos => this.tipos = tipos);
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    @Input() set idEmpreendimento(idEmpreendimento: number) {
        if (idEmpreendimento !== this.$idEmpreendimento) {
            this.$idEmpreendimento = idEmpreendimento;

            if (this.$idEmpreendimento) {
                this.componentesSubestacaoService.getByEmpreendimento(this.$idEmpreendimento)
                    .subscribe(itens => this.compoentesSubestacao.setValue(itens));
            }
        }
    }

    excluirComponenteSubestacao(index: number) {
        if (index > -1) {
            this.compoentesSubestacao.value.splice(index, 1);
            this.compoentesSubestacao.setValue([...this.compoentesSubestacao.value]);
        }
    }

    addComponenteSubestacao() {
        if (this.tipoSelecionado && !this.compoentesSubestacao.value.some(f => f.componenteSubestacao === this.tipoSelecionado.id && f.descricao === this.descricao)) {
            this.compoentesSubestacao.value.push(EmpreendimentoComponenteSubestacao.fromJson({
                componenteSubEstacao: this.tipoSelecionado.id,
                descricao: this.tipoSelecionado.descricao === 'Outro' ? this.descricao : undefined,
                quantidade: this.quantidade,
            }));

            this.compoentesSubestacao.setValue([...this.compoentesSubestacao.value]);

            this.tipoSelecionado = undefined;
            this.descricao = undefined;
            this.quantidade = undefined;
        }
    }

    verificaBotaoAdicionarDesabilitado() {
        return !this.tipoSelecionado || !this.quantidade ||
            (this.tipoSelecionado && this.tipoSelecionado.descricao === 'Outro' && !this.descricao);
    }

    getDescricao(id) {
        return this.tipos.find(tp => tp.id === id).descricao;
    }

}
