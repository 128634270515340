import {ObjetivoOutorga} from './objetivo-outorga.model';
import {RequerimentoOutorga} from './../../pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import {BaseModel} from 'app/main/shared/models/base.model';

export class ObjetivoRequerimentoOutorga extends BaseModel {
    constructor(
        public id?: number,
        public objetivo?: ObjetivoOutorga,
        public requerimentoOutorga?: RequerimentoOutorga,
    ) {
        super();
    }

    static fromJson(json: any): ObjetivoRequerimentoOutorga {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObjetivoRequerimentoOutorga(
            json.id,
            ObjetivoOutorga.fromJson(json.objetivo),
            RequerimentoOutorga.fromJson(json.requerimentoOutorga),
        );
    }

    static fromJsons(json: any): ObjetivoRequerimentoOutorga[] {
        const objetivosRequerimento: ObjetivoRequerimentoOutorga[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(objetivoRequerimento => {
                objetivosRequerimento.push(this.fromJson(objetivoRequerimento));
            });
            return objetivosRequerimento;
        }
    }
}
