import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule, MatInputModule, MatListModule, MatRadioModule, MatStepperModule, MatTooltipModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SemaAssinaturaEletronicaModule } from '@sema-assinatura/sema-assinatura-eletronico';
import { SemaGeoportalModule } from '@sema-geo/sema-geoportal';
import { CoreModule } from 'app/main/core/core.module';
import { SharedModule } from 'app/main/shared/shared.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { environment } from "../../../../../environments/environment";
import { AbaAtividadesLicComponent } from '../shared/aba-atividades-lic/aba-atividades-lic.component';
import { FormDinamicaDesmateComponent } from '../shared/aba-atividades-lic/form-dinamica-desmate/form-dinamica-desmate.component';
import { FormUpaComponent } from '../shared/aba-atividades-lic/form-upa/form-upa.component';
import { RequerimentoSharedModule } from '../shared/shared.module';
import { AssinarTermoAdessaoCompromissoComponent } from './assinar-termo-adessao-compromisso/assinar-termo-adessao-compromisso';
import { RequerimentoAtividadeLicenciamentoComponent } from './requerimento-atividade-licenciamento/requerimento-atividade-licenciamento.component';
import { RequerimentoFormularioLicenciamentoComponent } from './requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.component';
import { ValidacaoRegraAppDialogComponent } from './requerimento-formulario-licenciamento/validacao-regra-app-dialog/validacao-regra-app-dialog.component';
import { ConfirmarExclusaoPontoLicenciamentoDialogComponent } from './requerimento-geo-licenciamento/confirmar-exclusao-ponto-dialog/confirmar-exclusao-ponto-licenciamento-dialog.component';
import { RequerimentoGeoLicenciamentoComponent } from './requerimento-geo-licenciamento/requerimento-geo-licenciamento.component';
import { RequerimentoLicenciamentoComponent } from './requerimento-licenciamento.component';
import { RequerimentoTaxaLicenciamentoComponent } from './requerimento-taxa-licenciamento/requerimento-taxa-licenciamento.component';
import { RequerimentoTermoResponsabilidadeLicenciamentoComponent } from './requerimento-termo-responsabilidade-licenciamento/requerimento-termo-responsabilidade-licenciamento.component';
import { AbaCalculoTaxaLicEmpComponent } from './tipos-licenciamento/licenciamento-empreendimentos/aba-calculo-taxa-lic-emp/aba-calculo-taxa-lic-emp.component';
import { AbaGeoLicEmpComponent } from './tipos-licenciamento/licenciamento-empreendimentos/aba-geo-lic-emp/aba-geo-lic-emp.component';
import { AbaRequerimentoLicEmpComponent } from "./tipos-licenciamento/licenciamento-empreendimentos/aba-requerimento-lic-emp/aba-requerimento-lic-emp.component";
import { AbaTermoResponsabilidadeLicEmpComponent } from './tipos-licenciamento/licenciamento-empreendimentos/aba-termo-responsabilidade-lic-emp/aba-termo-responsabilidade-lic-emp.component';
import { AtividadesModule } from "./tipos-licenciamento/licenciamento-empreendimentos/atividades/atividades.module";
import { LicenciamentoEmpreendimentosComponent } from './tipos-licenciamento/licenciamento-empreendimentos/licenciamento-empreendimentos.component';
import { AbaCalculoTaxaComponent } from './tipos-licenciamento/licenciamento-florestal/aba-calculo-taxa/aba-calculo-taxa.component';
import { RequerimentoGeoLicenciamentoFlorestalComponent } from './tipos-licenciamento/licenciamento-florestal/aba-geo/aba-geo.component';
import { AbaReqFlorestalComponent } from './tipos-licenciamento/licenciamento-florestal/aba-requerimento/aba-req-florestal.component';
import { AbaTermoResponsabilidadeComponent } from './tipos-licenciamento/licenciamento-florestal/aba-termo-responsabilidade/aba-termo-responsabilidade.component';
import { LicenciamentoFlorestalComponent } from './tipos-licenciamento/licenciamento-florestal/licenciamento-florestal.component';
import { LicenciamentoLacComponent } from './tipos-licenciamento/licenciamento-lac/licenciamento-lac.component';


@NgModule({
    declarations: [
        RequerimentoLicenciamentoComponent,
        RequerimentoFormularioLicenciamentoComponent,
        AbaReqFlorestalComponent,
        RequerimentoGeoLicenciamentoComponent,
        RequerimentoAtividadeLicenciamentoComponent,
        RequerimentoTaxaLicenciamentoComponent,
        LicenciamentoLacComponent,
        ConfirmarExclusaoPontoLicenciamentoDialogComponent,
        AssinarTermoAdessaoCompromissoComponent,
        RequerimentoTermoResponsabilidadeLicenciamentoComponent,
        ValidacaoRegraAppDialogComponent,
        LicenciamentoFlorestalComponent,
        AbaCalculoTaxaComponent,
        RequerimentoGeoLicenciamentoFlorestalComponent,
        AbaTermoResponsabilidadeComponent,
        ValidacaoRegraAppDialogComponent,
        FormUpaComponent,
        FormDinamicaDesmateComponent,
        LicenciamentoEmpreendimentosComponent,
        AbaRequerimentoLicEmpComponent,
        AbaGeoLicEmpComponent,
        AbaAtividadesLicComponent,
        AbaCalculoTaxaLicEmpComponent,
        AbaTermoResponsabilidadeLicEmpComponent
    ],
    imports: [
        CommonModule,
        LeafletModule,
        CoreModule,
        SharedModule,
        RequerimentoSharedModule,
        MatStepperModule,
        MatListModule,
        MatRadioModule,
        MatInputModule,
        MatDatepickerModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        NgxCurrencyModule,
        MatButtonToggleModule,
        SemaGeoportalModule.forRoot(environment),
        MatTooltipModule,
        AtividadesModule,
        SemaAssinaturaEletronicaModule,
    ],
    exports: [
        RequerimentoLicenciamentoComponent,
        ConfirmarExclusaoPontoLicenciamentoDialogComponent,
        AssinarTermoAdessaoCompromissoComponent,
        AbaAtividadesLicComponent,
        AbaGeoLicEmpComponent,
    ],
    entryComponents: [
        RequerimentoLicenciamentoComponent,
        LicenciamentoLacComponent,
        LicenciamentoFlorestalComponent,
        ConfirmarExclusaoPontoLicenciamentoDialogComponent,
        ValidacaoRegraAppDialogComponent,
        LicenciamentoEmpreendimentosComponent,
        AbaGeoLicEmpComponent
    ],
    providers: [DatePipe]
})
export class RequerimentoLicenciamentoModule {
}
