import {ModelUtil} from "../../../../../shared/models/model-util";
import {NecessidadeIrrigacao} from "./necessidades-irrigacao.model";

export class ResumoNecessidadesIrrigacao {
    constructor(
        public consumoMin?: number,
        public consumoMax?: number,
        public consumoMedioAnual?: number,
        public areaTotalIrrigada?: number,
        public eficienciaMedia?: number,
        public volumeTotalAnual?: number,
        public necessidades?: NecessidadeIrrigacao[],
        public todasValidacoes?: string[],
    ) {
    }

    static fromJson(json: any): ResumoNecessidadesIrrigacao {
        if (json === undefined || json === null) {
            return null;
        }
        let resumo = new ResumoNecessidadesIrrigacao(
            ModelUtil.getOrElseZero(json.consumoMin),
            ModelUtil.getOrElseZero(json.consumoMax),
            ModelUtil.getOrElseZero(json.consumoMedioAnual),
            ModelUtil.getOrElseZero(json.areaTotalIrrigada),
            ModelUtil.getOrElseZero(json.eficienciaMedia),
            ModelUtil.getOrElseZero(json.volumeTotalAnual),
            ModelUtil.getOrElseEmptyArray(NecessidadeIrrigacao.fromJsons(json.necessidades)),
        );
        resumo.todasValidacoes = resumo.necessidades.reduce((n1, val) => n1.concat(val.validacoes), [])
        return resumo;
    }
}
