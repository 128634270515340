import {BaseModel} from '../base.model';

export class ParametroEntradaArrecadacao extends BaseModel {

    constructor(
        public id?: number,
        public descricao?: string,
        public labelFormulario?: string,
    ) {
        super();
    }

    static fromJson(json: any): ParametroEntradaArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParametroEntradaArrecadacao(
            json.id,
            json.descricao,
            json.labelFormulario,
        );
    }

    static criarObjetoLancamentoTaxaParamCalcDeParamEntrada(data: any, lancamentoTaxa, tipoParametro, idLancTaxaParamCalc = null, autorizacao = false) {
        return {
            id: idLancTaxaParamCalc,
            lancamentoTaxa: lancamentoTaxa,
            labelFormulario: data.labelFormulario,
            valor: data.valor,
            tipoParametro: tipoParametro,
            descricao: data.descricao,
            autorizacao: autorizacao
        };
    }
}
