import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AutorizacaoManejoFlorestal } from "../../../../../../../shared/models/autorizacao-manejo-florestal.model";
import { BaseAutorizacaoManejoFlorestalService } from "../../../../../../../shared/services/base-autorizacao-manejo-florestal.service";

@Injectable({
    providedIn: 'root'
})

export class ManejoFlorestalService extends BaseAutorizacaoManejoFlorestalService<AutorizacaoManejoFlorestal>{
    constructor(protected injector: Injector) {
        super(
            '/api/formulario-manejo-florestal',
            injector,
            AutorizacaoManejoFlorestal.prototype,
            AutorizacaoManejoFlorestal.fromJson
        );
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResourceLicAutorizacaoManejoFlorestal}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url, { observe: 'response'}).pipe(
            catchError(this.handleError.bind(this)),
            map(response => response.body)
        );
    }

    buscarPessoaPorId(idPessoa: number): Observable<any> {
        const url = `${this.urlResourceLicAutorizacaoManejoFlorestal}/${idPessoa}/pessoa`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    salvarAutorizacaoManejo(autorizacaoManejo: AutorizacaoManejoFlorestal): Observable<AutorizacaoManejoFlorestal> {
        const url = `${this.urlResourceLicAutorizacaoManejoFlorestal}`;
        return this.http.post(url, autorizacaoManejo)
            .pipe(map(response => response),
                catchError(this.handleError.bind(this))
            );
    }
}