import {Component, Input, OnInit} from '@angular/core';
import {AbstractComponenteRelacionamentoSubdominio} from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento-subdominio";
import {SistemaTratamentoSecundario} from "../../../../../../../../shared/models/sistema-tratamento-secundario.model";
import {SistemaTratamentoSecundarioService} from "../../../../../../../../shared/services/sistema-tratamento-secundario.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-atv-sist-tratamento-complementar',
  templateUrl: './atv-sist-tratamento-complementar.component.html',
  styleUrls: ['./atv-sist-tratamento-complementar.component.scss']
})
export class AtvSistTratamentoComplementarComponent extends AbstractComponenteRelacionamentoSubdominio<SistemaTratamentoSecundario, SistemaTratamentoSecundarioService> {

    @Input() isNaoPodeEditar: boolean;

    @Input() form: FormGroup;

    getDisplayedColumns(): string[] {
        return ['tipo', 'subtipo', 'descricao', 'acoes'];
    }

    apresentaSubtipo() {
        if (this.novoDado.dominio) {
            switch (this.novoDado.dominio.descricao) {
                case "Desidratação de lodo":
                    return true;
                default:
                    return false;
            }
        }
    }
}
