import {Component, Inject, OnInit} from '@angular/core';
import {
    AnaliseRequerimentoAnalistaService
} from "../../../../tarefas/shared/service/analise-requerimento-analista.service";
import {AnaliseRequerimentoAnalista} from "../../../../tarefas/shared/model/analise-requerimento-analista.model";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ComentarioRecusaDialogComponent} from "./comentario-recusa-dialog/comentario-recusa-dialog.component";
import {AnaliseRequerimentoEquipe} from "../../../../tarefas/shared/model/analise-requerimento-equipe.model";

@Component({
    selector: 'app-historico-atribuicoes-dialog',
    templateUrl: './historico-atribuicoes-dialog.component.html',
    styleUrls: ['./historico-atribuicoes-dialog.component.scss']
})
export class HistoricoAtribuicoesDialogComponent implements OnInit {

    analiseEquipe: AnaliseRequerimentoEquipe;
    todasAtribuicoes: AnaliseRequerimentoAnalista[];
    situacoesAnalista = AnaliseRequerimentoAnalista.situacoes;
    motivosDeRecusa = AnaliseRequerimentoAnalista.motivosRecusa;

    constructor(@Inject(MAT_DIALOG_DATA) public idRequerimento: number,
                private analiseRequerimentoAnalistaService: AnaliseRequerimentoAnalistaService,
                public dialog: MatDialog,) {
    }

    async ngOnInit() {
        await this.buscarTodasAtribuicoes();
    }

    abrirDialogComentarioRecusa(element: AnaliseRequerimentoAnalista) {
        this.dialog.open(ComentarioRecusaDialogComponent, {
            width: '50%', maxHeight: '300px',
            data: element.comentarioRecusa
        });
    }

    async buscarTodasAtribuicoes() {
        this.todasAtribuicoes =
            await this.analiseRequerimentoAnalistaService.buscarTodasAtribuicoesPorRequerimento(this.idRequerimento).toPromise();
    }
}