import {ModelUtil} from "../../../../../shared/models/model-util";

export class NecessidadeIrrigacao {
    constructor(
        public mes?: string,
        public vazao?: number,
        public volumeEstimado?: number,
        public areaIrrigada?: number,
        public eficienciaMedia?: number,
        public periodo?: number,
        public horasPorDia?: number,
        public volumeMes?: number,
        public horasPorMes?: number,
        public volumeDia?: number,
        public consumo?: number,
        public validacoes?: string[],
    ) {
    }

    static fromJson(json: any): NecessidadeIrrigacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new NecessidadeIrrigacao(
            json.mes,
            ModelUtil.getOrElseZero(json.vazao),
            ModelUtil.getOrElseNull(json.volumeEstimado),
            ModelUtil.getOrElseNull(json.areaIrrigada),
            ModelUtil.getOrElseNull(json.eficiencia),
            ModelUtil.getOrElseNull(json.periodo),
            ModelUtil.getOrElseNull(json.horasPorDia),
            ModelUtil.getOrElseNull(json.volumeMes),
            ModelUtil.getOrElseNull(json.horasPorMes),
            ModelUtil.getOrElseNull(json.volumeDia),
            ModelUtil.getOrElseNull(json.consumo),
            ModelUtil.getOrElseEmptyArray(json.validacoes),
        );
    }

    static fromJsons(json: any): NecessidadeIrrigacao[] {
        const array: NecessidadeIrrigacao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(NecessidadeIrrigacao.fromJson(item)));
            return array;
        }
    }
}
