import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from '../requerimento.model';
import {ObjetivoLicenciamento} from "../../../shared/models/objetivo-licenciamento.model";

export class RequerimentoLicenciamento extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public ppd?: string,
        public idAtividade?: number,
        public idGrupoAtividade?: number,
        public descricaoAtividade?: string,
        public descricaoGrupoAtividade?: string,
        public idParametroObjetivo?: number,
        public idTipoRequerimentoTifLas?: number,
        public idMotivoAutorizacaoQueima?: number) {
        super();
    }

    static fromJson(json: any): RequerimentoLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoLicenciamento(
            json.id,
            Requerimento.fromJson(json.requerimento),
            json.ppd,
            json.idAtividade,
            json.idGrupoAtividade,
            json.descricaoAtividade,
            json.descricaoGrupoAtividade,
            json.idParametroObjetivo,
            json.idTipoRequerimentoTifLas,
            json.idMotivoAutorizacaoQueima);
    }
}
