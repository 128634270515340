import {BaseModel} from "./base.model";
import {EmpreendimentoEeHidrica} from "./empreendimento-ee-hidrica.model";

export class UnidadesGeradoras extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoEeHidrica?: EmpreendimentoEeHidrica,
        public unidadeGeradora?: string,
        public turbinaTipo?: string,
        public turbinaPotencia?: number,
        public turbinaRendimento?: number,
        public turbinaVazaoNominal?: number,
        public turbinaVazaoMaxima?: number,
        public turbinaVazaoMinima?: number,
        public geradorPotencia?: number,
        public geradorRendimento?: number,
        public geradorTensao?: number,
        public geradorFatorPotencia?: number,
    ) {
        super();
    }

    static fromJson(json: any): UnidadesGeradoras {
        if (json === undefined || json === null) {
            return null;
        }
        return new UnidadesGeradoras(
            json.id,
            EmpreendimentoEeHidrica.fromJson(json.empreendimentoEeHidrica),
            json.unidadeGeradora,
            json.turbinaTipo,
            json.turbinaPotencia,
            json.turbinaRendimento,
            json.turbinaVazaoNominal,
            json.turbinaVazaoMaxima,
            json.turbinaVazaoMinima,
            json.geradorPotencia,
            json.geradorRendimento,
            json.geradorTensao,
            json.geradorFatorPotencia,
        );
    }

    static fromJsons(json: any): UnidadesGeradoras[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(UnidadesGeradoras.fromJson(j)));
            return resources;
        }
    }
}