import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { GerenciarRequerimentoProcessoService } from '../../gerenciar-requerimento-processo.service';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RequerimentoService } from '../../requerimento.service';
import { RequerimentoTaskDTO } from '../model/requerimento-task.dto';
import { ServidorService } from 'app/main/shared/services/servidor.service';
import { AuthenticationService } from 'app/main/core/services/authentication.service';
import { MessageDialog, MessageDialogResult } from 'app/main/shared/components/message-dialog/message-dialog.model';
import { NovaAtribuicaoProcessosDTO } from '../model/nova-atribuicao-processos.dto';
import {PessoaCorporativoService} from "../../../../shared/services/pessoa-corporativo.service";

@Component({
  selector: 'app-dialog-troca-assignee-user-task',
  templateUrl: './dialog-troca-assignee-user-task.component.html',
  styleUrls: ['./dialog-troca-assignee-user-task.component.scss']
})
export class DialogTrocaAssigneeUserTaskComponent implements OnInit {


    //requerimentosTaskDTO: MatTableDataSource<RequerimentoTaskDTO>;
    requerimentosTaskDTO: RequerimentoTaskDTO[] = [];
    form: FormGroup;
    tipoSelecao: string = 'PESSOAS_SETOR';
    servidores: any[] = [];
    servidorSelecionado: any;
    usuariosEncontrados: any[] = [];
    cpfUsuarioLogado: string;

    displayedColumns: string[] = ['numero', 'numeroProcesso', 'task', 'usuarioTask', 'acao'];

    constructor(private dialogRef: MatDialogRef<DialogTrocaAssigneeUserTaskComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBarService: SnackBarService,
        private servidorService: ServidorService,
        private pessoaService: PessoaCorporativoService,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        public gerenciarRequerimentoProcessoService: GerenciarRequerimentoProcessoService,
        protected requerimentoService: RequerimentoService,
        private messageDialog: MessageDialog,
    ) {
    }    
    
    ngOnInit(): void {
        this.authenticationService.currentUser.subscribe(user => this.cpfUsuarioLogado = user.sub);
        this.requerimentosTaskDTO = this.data.requerimentosTaskDTO;
        this.gerenciarRequerimentoProcessoService.findTasksCamundaByIdsRequerimento(this.data.redistribuicaoProcessoDTO)
            .subscribe (result => {
                this.requerimentosTaskDTO = result;
            },
            e => this.snackBarService.showError('Erro: ', e));
            this.form = this.fb.group(this.returnObjectForm());
        this.getPessoaSetorUsuarioLogado();
    }
    
    private returnObjectForm(): any {
        return {
            cpfUsuario: [],
            servidorDepartamento: [],
            usuarioOutroSetor: [],
            observacao: [],
            tipoSelecao: ['PESSOAS_SETOR'],
            tasksDTO: [this.requerimentosTaskDTO]
        };
    }

    async getPessoaSetorUsuarioLogado() {
        this.usuariosEncontrados = [];
        this.usuariosEncontrados = await this.servidorService
            .getServidoresScorpPeloDepartamentoDoServidorLogado(this.cpfUsuarioLogado)
            .toPromise();
    }
    
    selecionarNovaAtribuicao(event) {
        this.tipoSelecao = event.value;
        this.usuariosEncontrados = [];
        this.form.get('cpfUsuario').reset;
        this.form.reset();
        if (this.tipoSelecao && this.tipoSelecao == 'PESSOAS_SETOR') {
            this.getPessoaSetorUsuarioLogado();
        }
    }

    async buscarResponsavel() {
        let cpfUsuarioResponsavel = this.form.get('cpfUsuario') ? this.form.get('cpfUsuario').value : null;
        if (cpfUsuarioResponsavel !== null) {
            this.usuariosEncontrados = [];
            let listaIds = this.requerimentosTaskDTO.map(task => task.requerimentoId);
            this.requerimentoService.buscarUsuarioDoProcesso(cpfUsuarioResponsavel, listaIds).toPromise().then(pessoaEncontrada => {
                this.usuariosEncontrados.push({nome: pessoaEncontrada.nome, cpf: pessoaEncontrada.cpfCnpj});
            }, () => this.snackBarService.showError("O usuário responsável: " + this.formatarCpf(cpfUsuarioResponsavel) +
                " não pode ser vinculado à tarefa pois não possui vínculo com o processo selecionado."));
        } else {
            this.snackBarService.showError("Infome o CPF do usuário responsável.");
            return;
        }
    }

    atribuirAnalistaAosProcessos(){
        if(this.validarDados()){
            this.gerenciarRequerimentoProcessoService.realizarNovaAtribuicaoAnalistaDoProcesso(this.construirObjetoNovaAtribuicao())
                .toPromise().then( () => {
                this.messageDialog.showInformation("Registro atualizado com sucesso.!")
                    .subscribe( result => {
                        if(result === MessageDialogResult.OK){
                            this.dialogRef.close('update');
                        }
                    })
            }, error => { 
                console.error(error); 
                this.snackBarService.showError(error.errors[0] || "Erro ao realizar nova atribuição.")
            });
        }
    }

    validarDados(){
        let servidor = this.form.get('servidorDepartamento').value ? this.form.get('servidorDepartamento').value : this.form.get('usuarioOutroSetor').value;
        let passou = true;
        if (!this.requerimentosTaskDTO || this.requerimentosTaskDTO.length == 0) {
            this.snackBarService.showAlert("Nenhum processo foi selecionado para a substituição de analistas.")
            this.form.markAllAsTouched();
            passou = false;
        }
        if (!servidor) {
            this.snackBarService.showAlert("Nenhum servidor foi selecionado para receber a nova atribuição.")
            this.form.markAllAsTouched();
            passou = false;
        }
        if(!this.form.get('observacao').value){
            this.snackBarService.showAlert("Insira uma observação para a substituição dos analistas.")
            this.form.markAllAsTouched();
            passou = false;
        }

        return passou;
    }

    construirObjetoNovaAtribuicao(){
        let usuario = this.form.get('servidorDepartamento').value ? this.form.get('servidorDepartamento').value : this.form.get('usuarioOutroSetor').value;
        if (usuario) {
            return new NovaAtribuicaoProcessosDTO(
                usuario.cpf,
                this.form.get('observacao').value,
                this.requerimentosTaskDTO);
        } else {
            this.snackBarService.showAlert("Erro ao selecionar o servidor.");
            return;
        }
    }

    formatarCpf(cpf: any){
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    fecharDialog(): void {
        this.dialogRef.close();
    }

    removerProcesso(requerimentoTaskDTO: RequerimentoTaskDTO) {
        if (requerimentoTaskDTO) {
            this.requerimentosTaskDTO = [...this.requerimentosTaskDTO.splice(this.requerimentosTaskDTO.indexOf(requerimentoTaskDTO), 1)];
        }
    }

}
