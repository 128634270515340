import {Component, OnInit} from '@angular/core';
import {BasePontoComponent} from "../base-ponto.component";
import {PontoService} from "../../../../../shared/services/ponto.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackBarService} from "../../../../../shared/snack-bar/snack-bar.service";
import {MatDialog} from "@angular/material/dialog";
import {DadoMeteorologico} from "../../../../../shared/models/hidrico-models/dado-meteorologico.model";
import {ENUM_MES_VALUES, EnumMes} from "../../../../../shared/enums/mes.enum";
import {BaseModel} from "../../../../../shared/models/base.model";
import {PontoVazaoMensalDRDH} from "../../../../../shared/models/hidrico-models/ponto-vazao-mensal-drdh.model";
import {DialogAdicionarDadoHidrometeorologicoComponent} from "./dialog-adicionar-dado-hidrometeorologico/dialog-adicionar-dado-hidrometeorologico.component";
import {Ponto} from "../../../../../shared/models/hidrico-models/ponto.model";
import {DRDH} from "../../../../../shared/models/hidrico-models/drdh.model";
import {isNullOrUndefined} from "util";
import {ConfirmarExclusaoDialogComponent} from "../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component";
import {DadosHidrometeorologicosService} from "../../../../../shared/services/dados-hidrometeorologicos.service";
import {PontoVazoesMensaisDrdhService} from "../../../../../shared/services/ponto-vazoes-mensais-drdh.service";

@Component({
    selector: 'app-objetivo-drdh',
    templateUrl: './objetivo-drdh.component.html',
    styleUrls: ['./objetivo-drdh.component.scss']
})
export class ObjetivoDrdhComponent extends BasePontoComponent implements OnInit {

    dadosMeteorologicos: DadoMeteorologico[] = [];
    vazoesMensais: PontoVazaoMensalDRDH[] = [];

    formDRDH: FormGroup;
    formVazaoMensal: FormGroup;
    mesValues = ENUM_MES_VALUES;
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b

    condicaoFormularioConcluido = (ponto?: Ponto): boolean => this.vazoesMensais.length > 0 && this.dadosMeteorologicos.length > 0 &&
        !isNullOrUndefined((ponto ? ponto : this.ponto).drdh)

    mediaMLT = 0

    constructor(
        private pontoService: PontoService,
        private dadosHidrometeorologicosService: DadosHidrometeorologicosService,
        private vazoesMensaisService: PontoVazoesMensaisDrdhService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        public dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.formDRDH = this.formBuilder.group({
            corpoHidrico: [null, Validators.required],
            areaDrenagem: [null, [Validators.required, Validators.min(0.01)]],
            vazaoFirme: [null, [Validators.required, Validators.min(0.000001)]],
            comprimentoTrechoVazao: [null, [Validators.required, Validators.min(0.000001)]],
            descricaoEstruturas: [null, [Validators.required]],
            latFinal: [null],
            longFinal: [null],
            latRestituicao: [null],
            longRestituicao: [null],
        });
        this.formVazaoMensal = this.formBuilder.group({
            mes: [null, Validators.required],
            vazao: [null, [Validators.required, Validators.min(0.000001)]],
        });
        if (this.isModoVisualizacao) {
            this.formDRDH.disable();
        }
        if (this.ponto.drdh) {
            this.formDRDH.patchValue(this.ponto.drdh)
        }
        this.listarDadosMeteorologicos();
        this.listarVazaoMensal();
    }

    excluirDadoHidrometeorologico(dado: DadoMeteorologico) {
        this.dialog.open(ConfirmarExclusaoDialogComponent, {data: {label: dado.id}}).afterClosed().subscribe(result => {
            if (result) {
                this.dadosHidrometeorologicosService.excluirDadoHidrometeorologico(dado).subscribe(() => this.listarDadosMeteorologicos(),
                    e => this.snackBarService.showError('Erro ao excluir dado meteorológicos', e))
            }
        });
    }

    private listarDadosMeteorologicos() {
        this.dadosHidrometeorologicosService.listarDadosHidrometeorologicos(this.ponto).subscribe(result => {
            this.dadosMeteorologicos = result;
            this.verificarFormularioConcluido()

        }, e => this.snackBarService.showError('Erro ao carregar dados meteorológicos', e))
    }

    private listarVazaoMensal() {
        this.vazoesMensaisService.listarVazaoMensal(this.ponto).subscribe(result => {
            this.vazoesMensais = result;
            if (this.vazoesMensais.length > 0) {
                let soma = 0
                this.vazoesMensais.forEach(vazao => {
                    soma += vazao.vazao
                })
                this.mediaMLT = soma / this.vazoesMensais.length
            } else {
                this.mediaMLT = 0;
            }
            this.verificarFormularioConcluido()

        }, e => this.snackBarService.showError('Erro ao carregar vazões mensais', e))
    }

    verificarFormularioConcluido() {
        if (!isNullOrUndefined(this.dadosMeteorologicos) && !isNullOrUndefined(this.vazoesMensais) && this.ponto.formularioConcluido != this.condicaoFormularioConcluido()) {
            this.ponto.formularioConcluido = this.condicaoFormularioConcluido()
            this.pontoService.update(this.ponto).subscribe(result => Object.assign(this.ponto, result))
        }
    }

    adicionarDadoMeteorologico() {
        this.dialog.open(DialogAdicionarDadoHidrometeorologicoComponent, {
            data: this.ponto
        }).afterClosed().subscribe(result => {
            if (result) {
                this.listarDadosMeteorologicos();
            }
        })
    }

    adicionarVazaoMensal() {
        this.formVazaoMensal.markAllAsTouched()
        if (this.formVazaoMensal.valid) {
            const vazao = PontoVazaoMensalDRDH.fromJson(this.formVazaoMensal.value)
            if (!this.vazoesMensais.some(v => v.mes == vazao.mes)) {
                vazao.ponto = this.ponto
                this.vazoesMensaisService.adicionarVazaoMensal(vazao).subscribe(() => {
                        this.formVazaoMensal.reset();
                        this.listarVazaoMensal()
                    },
                    e => this.snackBarService.showError('Erro ao adicionar vazão mensal', e))
            } else {
                this.snackBarService.showAlert('Mês já adicionado!')
            }
        } else {
            this.snackBarService.showAlert('Preencha todos os campos corretamente')
        }
    }

    excluirVazaoMensal(dado: PontoVazaoMensalDRDH) {
        this.dialog.open(ConfirmarExclusaoDialogComponent, {data: {label: dado.id}}).afterClosed().subscribe(result => {
            if (result) {
                this.vazoesMensaisService.excluirVazaoMensal(dado).subscribe(() => this.listarVazaoMensal(),
                    e => this.snackBarService.showError('Erro ao excluir vazão mensal', e))
            }
        })
    }

    salvarPonto(): void {
        this.formDRDH.markAllAsTouched();
        if (!this.formDRDH.invalid) {
            const dtoPonto = Ponto.fromJson(this.ponto);
            dtoPonto.drdh = DRDH.fromJson(this.formDRDH.value);
            dtoPonto.formularioConcluido = this.condicaoFormularioConcluido(dtoPonto);
            this.pontoService.update(dtoPonto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.formDRDH.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    getMesTexto(tipo: string): string {
        return EnumMes[tipo];
    }

}
