import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackBarService, SnackBarType} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {AguaPassivoSupressaoService} from "../../../../../../../shared/services/agua-passivo-supressao.service";
import {AguaPassivoSupressao} from "../../../../../../../shared/models/agua-passiva-supressao.model";
import {AguaPassivoSupressaoTipoLancamentoService} from "../../../../../../../shared/services/agua-passivo-supressao-tipo-lancamento.service";
import {AguaPassivoSupressaoTipoLancamento} from "../../../../../../../shared/models/agua-passivo-supressao-tipo-lancamento.model";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoService} from "../../../../../requerimento.service";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
    selector: 'app-aba-atividade-agua-passivo-supressao',
    templateUrl: './agua-passivo-supressao.component.html',
    styleUrls: ['./agua-passivo-supressao.component.scss']
})
export class AguaPassivoSupressaoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    @Input() requerimento: Requerimento;

    isNaoPodeEditar: boolean = false;

    form = this.fb.group({
        id: [null],
        requerimento: [null],
        usoAgua: this.fb.group({
            tipoCaptacao: [null, [Validators.required]],
        }),
        tiposLancamento: [null, [Validators.required]],
        geral: this.fb.group({
            supressaoVegetal: [null, [Validators.required]],
            passivoAmbiental: [null, [Validators.required]]
        })
    });

    constructor(
        private fb: FormBuilder,
        private snackBarService: SnackBarService,
        private aguaPassivoSupressaoService: AguaPassivoSupressaoService,
        private aguaPassivoSupressaoTipoLancamentoService: AguaPassivoSupressaoTipoLancamentoService,
        private requerimentoService: RequerimentoService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
    ) {
        super();
    }

    async ngOnInit() {
        if (this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                this.carregarAguaPassivoSupressao(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                this.carregarAguaPassivoSupressao(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
                this.isNaoPodeEditar = true;
                this.form.disable();
        }
    }

    carregarAguaPassivoSupressao(idRequerimento) {
        this.aguaPassivoSupressaoService.getByRequerimento(idRequerimento)
            .subscribe(aguaPassivo => {
                if (aguaPassivo) {
                    this.form.patchValue({
                        id: aguaPassivo.id,
                        requerimento: aguaPassivo.requerimento,
                        usoAgua: {
                            tipoCaptacao: ['superficial', 'subterranea', 'concessionaria']
                                .filter(tc => !!aguaPassivo[tc]),
                        },
                        tiposLancamento: null,
                        geral: {
                            supressaoVegetal: aguaPassivo.supressaoVegetal,
                            passivoAmbiental: aguaPassivo.passivoAmbiental
                        }
                    });

                    if (this.form.get('usoAgua').get('tipoCaptacao').value.indexOf('concessionaria') < 0) {
                        if (this.form.get('usoAgua').get('tipoCaptacao').value.indexOf('superficial') > 0) {
                            (this.form.get('usoAgua') as FormGroup).addControl('captacaoSuperficial', this.fb.control(
                                ['outorgaCaptacaoSuperficial', 'usoInsignificanteCaptacaoSuperficial', 'aguaChuva', 'cessaoUsoCaptacaoSuperficial']
                                    .filter(tc => !!aguaPassivo[tc]), [Validators.required]
                            ));
                        }

                        if (this.form.get('usoAgua').get('tipoCaptacao').value.indexOf('subterranea') > 0) {
                            (this.form.get('usoAgua') as FormGroup).addControl('captacaoSubterranea', this.fb.control(
                                ['outorgaCaptacaoSubterranea', 'pocosRasos', 'cessaoUsoCaptacaoSubterranea', 'usoInsignificanteCaptacaoSubterranea']
                                    .filter(tc => !!aguaPassivo[tc]), [Validators.required]
                            ));
                        }
                    }

                    this.aguaPassivoSupressaoTipoLancamentoService.getByAguaPassivoSupressao(aguaPassivo.id)
                        .subscribe(tiposLancamento => this.form.get('tiposLancamento').patchValue(tiposLancamento));
                }
            });
    }

    salva() {
        this.form.markAllAsTouched();

        if (!this.form.valid) {
            this.snackBarService.show('O formulário de Atividades: possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).', SnackBarType.ERROR);
            return;
        }

        const aguaPassivo = AguaPassivoSupressao.fromJson({
            id: this.form.get('id').value,
            requerimento: this.form.get('requerimento').value || this.requerimento.id,
            // Tipo de Captação
            superficial: this.form.get('usoAgua').get('tipoCaptacao').value.indexOf('superficial') >= 0,
            subterranea: this.form.get('usoAgua').get('tipoCaptacao').value.indexOf('subterranea') >= 0,
            concessionaria: this.form.get('usoAgua').get('tipoCaptacao').value.indexOf('concessionaria') >= 0,
            // Captação Superficial
            outorgaCaptacaoSuperficial: this.form.get('usoAgua').get('captacaoSuperficial') && this.form.get('usoAgua').get('captacaoSuperficial').value.indexOf('outorgaCaptacaoSuperficial') >= 0,
            usoInsignificanteCaptacaoSuperficial: this.form.get('usoAgua').get('captacaoSuperficial') && this.form.get('usoAgua').get('captacaoSuperficial').value.indexOf('usoInsignificanteCaptacaoSuperficial') >= 0,
            aguaChuva: this.form.get('usoAgua').get('captacaoSuperficial') && this.form.get('usoAgua').get('captacaoSuperficial').value.indexOf('aguaChuva') >= 0,
            cessaoUsoCaptacaoSuperficial: this.form.get('usoAgua').get('captacaoSuperficial') && this.form.get('usoAgua').get('captacaoSuperficial').value.indexOf('cessaoUsoCaptacaoSuperficial') >= 0,
            // Captação Subterrânea
            outorgaCaptacaoSubterranea: this.form.get('usoAgua').get('captacaoSubterranea') && this.form.get('usoAgua').get('captacaoSubterranea').value.indexOf('outorgaCaptacaoSubterranea') >= 0,
            pocosRasos: this.form.get('usoAgua').get('captacaoSubterranea') && this.form.get('usoAgua').get('captacaoSubterranea').value.indexOf('pocosRasos') >= 0,
            cessaoUsoCaptacaoSubterranea: this.form.get('usoAgua').get('captacaoSubterranea') && this.form.get('usoAgua').get('captacaoSubterranea').value.indexOf('cessaoUsoCaptacaoSubterranea') >= 0,
            usoInsignificanteCaptacaoSubterranea: this.form.get('usoAgua').get('captacaoSubterranea') && this.form.get('usoAgua').get('captacaoSubterranea').value.indexOf('usoInsignificanteCaptacaoSubterranea') >= 0,
            // Geral
            supressaoVegetal: this.form.get('geral').get('supressaoVegetal').value,
            passivoAmbiental: this.form.get('geral').get('passivoAmbiental').value
        });

        if (!aguaPassivo.id) {
            this.aguaPassivoSupressaoService.create(aguaPassivo)
                .subscribe(aguaPassivo => {
                    this.form.get('id').setValue(aguaPassivo.id);
                    this.enviarFormConcluido.emit();
                    this.salvaTiposLancamento(aguaPassivo);
                });
        } else {
            this.aguaPassivoSupressaoService.create(aguaPassivo)
                .subscribe(async aguaPassivo => {
                    this.enviarFormConcluido.emit();
                    await this.salvaTiposLancamento(aguaPassivo);
                });
        }
    }

    private async salvaTiposLancamento(aguaPassivo: AguaPassivoSupressao) {
        const tiposLancamento = this.form.get('tiposLancamento').value;

        const existentes = await this.aguaPassivoSupressaoTipoLancamentoService.getByAguaPassivoSupressao(this.form.get('id').value).toPromise();

        for (const circuito of existentes) {
            if (!tiposLancamento.find(c => circuito.id === c.id)) {
                await this.aguaPassivoSupressaoTipoLancamentoService.delete(circuito.id).toPromise();
            }
        }

        for (let tipoLancamento of tiposLancamento) {
            tipoLancamento = (AguaPassivoSupressaoTipoLancamento.fromJson({
                ...tipoLancamento,
                aguaPassivoSupressao: aguaPassivo,
            })) as any;

            if (tipoLancamento.id) {
                await this.aguaPassivoSupressaoTipoLancamentoService.update(tipoLancamento).toPromise();
            } else {
                await this.aguaPassivoSupressaoTipoLancamentoService.create(tipoLancamento).toPromise();
            }
        }
    }
}
