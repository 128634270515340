import {Ponto} from 'app/main/shared/models/hidrico-models/ponto.model';
import {PontoService} from 'app/main/shared/services/ponto.service';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BasePontoComponent} from '../base-ponto.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {isNullOrUndefined} from "util";

@Component({
    selector: 'app-titulacao-captacao-insignificante',
    templateUrl: './titulacao-captacao-insignificante.component.html'
})
export class TitulacaoCaptacaoInsignificanteComponent extends BasePontoComponent implements OnInit {
    formCaptacaoInsignificante: FormGroup;

    lancamentosLength: number;
    condicaoFormularioConcluido = (ponto?: Ponto): boolean => this.lancamentosLength > 0 && this.lancamentosLength > 0 &&
        !isNullOrUndefined((ponto ? ponto : this.ponto).captacaoInsignificante)

    constructor(
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private pontoService: PontoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildResourcesForm();
        this.patchPonto();
        if (this.isModoVisualizacao) {
            this.formCaptacaoInsignificante.disable();
        }
    }

    private patchPonto(): void {
        this.formCaptacaoInsignificante.patchValue({
            id: this.ponto.id,
            requerimentoOutorga: this.ponto.requerimentoOutorga
        });
        if (this.ponto.captacaoInsignificante) {
            this.formCaptacaoInsignificante.patchValue({captacaoInsignificante: this.ponto.captacaoInsignificante});
        }
    }

    buildResourcesForm(): void {
        this.formCaptacaoInsignificante = this.formBuilder.group({
            id: [null],
            requerimentoOutorga: [null, Validators.required],
            captacaoInsignificante: this.formBuilder.group({
                id: [null],
                caracterizacaoAtividade: [null]
            })
        });
    }

    salvarPonto(): void {
        this.formCaptacaoInsignificante.markAllAsTouched();
        if (!this.formCaptacaoInsignificante.invalid) {
            const ponto = Ponto.fromJson(this.ponto);
            ponto.captacaoInsignificante = this.formCaptacaoInsignificante.controls.captacaoInsignificante.value;
            ponto.formularioConcluido = this.condicaoFormularioConcluido(ponto);
            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.formCaptacaoInsignificante.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        }
    }

    onChangeLancamentos(length: number) {
        this.lancamentosLength = length

        if (this.ponto.formularioConcluido != this.condicaoFormularioConcluido()) {
            this.ponto.formularioConcluido = this.condicaoFormularioConcluido()
            this.pontoService.update(this.ponto).subscribe(result => Object.assign(this.ponto, result))
        }
    }
}
