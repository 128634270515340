import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {MotivoJuntada} from '../models/motivo-juntada.model';
import {MotivoJuntadaDto} from '../models/motivo-juntada-dto.model';
import {FeicaoAnaliseGeometriaDto} from '../models/feicao-analise-geometria-dto.model';

@Injectable({
    providedIn: 'root'
})
export class MotivoJuntadaService extends BaseService<MotivoJuntada> {

    constructor(protected injector: Injector) {
        super(
            '/api/motivo-juntada',
            injector,
            MotivoJuntada.prototype,
            MotivoJuntada.fromJson
        );
    }

    findAllMotivosJuntada(): Observable<any> {
        const url = `${this.urlResource}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    buscaListaConfigAvisosAutomaticos(): Observable<any> {
        const url = `${this.urlResource}/busca-config-avisos`;
        return this.http.get(url)
            .pipe(
                catchError(this.handleError.bind(this)));
    }

    buscaMottivoJuntadaPorId(idMotivoJuntada: number): Observable<MotivoJuntada> {
        return this.http
            .get(`${this.urlResource}/retorna-motivo-juntada/${idMotivoJuntada}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscaConfigAvisosPorDesc(descricao: string): Observable<any[]> {
        return this.http
            .get(`${this.urlResource}/busca-config-avisos-por-desc/${descricao}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscaMotivoJuntadaPorDesc(descricao: string): Observable<any[]> {
        return this.http
            .get(`${this.urlResource}/busca-motivo-juntada-desc/${descricao}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    atualizarMotivoJuntada(motivoJuntada: MotivoJuntada): Observable<any> {
        const url = `${this.urlResource}`;
        return this.http.put(url, motivoJuntada).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    salvaListMotivoJuntada(motivoJuntadaDTO: MotivoJuntadaDto[]): Observable<any> {
        const url = `${this.urlResource}/save-list`;
        return this.http.post(url, motivoJuntadaDTO).pipe(catchError(this.handleError.bind(this)));
    }

    deletarLogicamenteMotivoJuntada(idMotivoJuntada: number): Observable<any> {
        const url = `${this.urlResource}/delete/${idMotivoJuntada}`;
        return this.http.delete(url).pipe(catchError(this.handleError.bind(this)));
    }

}
