import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ItemTermoReferenciaRequerimentoChecklist} from '../models/item-termo-referencia-requerimento-checklist.model';

@Injectable({
    providedIn: 'root'
})
export class ItemTermoReferenciaRequerimentoChecklistService extends BaseService<ItemTermoReferenciaRequerimentoChecklist> {
    constructor(protected injector: Injector) {
        super(
            '/api/itens-termos-referencias-requerimento-checklists',
            injector,
            ItemTermoReferenciaRequerimentoChecklist.prototype,
            ItemTermoReferenciaRequerimentoChecklist.fromJson
        );
    }

    getAllByRequerimento(requerimentoId: number): Observable<ItemTermoReferenciaRequerimentoChecklist[]> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError)
        );
    }

    createList(list: ItemTermoReferenciaRequerimentoChecklist[]): Observable<ItemTermoReferenciaRequerimentoChecklist[]> {
        return this.http.post(this.urlResource, list)
            .pipe(map(resources => ItemTermoReferenciaRequerimentoChecklist.fromJsons(resources as [])),
                catchError(this.handleError));
    }

    updateList(list: ItemTermoReferenciaRequerimentoChecklist[]): Observable<ItemTermoReferenciaRequerimentoChecklist[]> {
        return this.http.put(this.urlResource, list)
            .pipe(map(resources => ItemTermoReferenciaRequerimentoChecklist.fromJsons(resources as [])),
                catchError(this.handleError));
    }

    getHistoricoItemRequerimentoChecklist(idItem: number): Observable<ItemTermoReferenciaRequerimentoChecklist[]> {
        const url = `${this.urlResource}/termoReferenciaChecklist/${idItem}`;
        return this.http.get(url).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError)
        );
    }

    createNew(itemChecklist: ItemTermoReferenciaRequerimentoChecklist): Observable<ItemTermoReferenciaRequerimentoChecklist> {
        const url = `${this.urlResource}/registra-correcao-item-checklist`;
        return this.http.post(url, itemChecklist).pipe(
            map((response) => response),
            catchError(this.handleError.bind(this)));
    }

}
