import {Type} from '@angular/core';
import {AbaAtividadesDto} from "../../../../shared/models/licenciamento-config-models/aba-atividades-dto.model";
import {PredicateResolver} from "../../requerimento-outorga/requerimento-atividade-outorga/predicate.resolver";
import {ResiduosAterroIndustrialUrbanoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-aterro-industrial-urbano/residuos-aterro-industrial-urbano.component";
import {ResiduosCemiterioCrematorioComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-cemiterio-crematorio/residuos-cemiterio-crematorio.component";
import {ServicoCombustivelComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/servico-combustivel/servico-combustivel.component";
import {ResiduosRccComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-rcc/residuos-rcc.component";
import {IndustriaBiocombustivelComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/industria-biocombustivel/industria-biocombustivel.component";
import {IndustriaFrigorificoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/industria-frigorifico/industria-frigorifico.component";
import {ServicoGeralComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/servico-geral/servico-geral.component";
import {SistemaTratamentoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/sistema-tratamento/sistema-tratamento.component";
import {ResiduosTratamentoTermicoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-tratamento-termico/residuos-tratamento-termico.component";
import {ResiduosTransporteProdutosPerigososComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-transporte-produtos-perigosos/residuos-transporte-produtos-perigosos.component";
import {ResiduosGeralComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-geral/residuos-geral.component";
import {AguaPassivoSupressaoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/agua-passivo-supressao/agua-passivo-supressao.component";
import {MadeiraComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/madeira/madeira.component";
import {InfraParcelSoloResComComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infra-parcel-solo-res-com/infra-parcel-solo-res-com.component";
import {InfraestruturaObrasViariasComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infraestrutura-obras-viarias/infraestrutura-obras-viarias.component";
import {EeHidricaComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-hidrica/ee-hidrica.component";
import {EeTermicaComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-termica/ee-termica.component";
import {EeFotovoltaicaComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-fotovoltaica/ee-fotovoltaica.component";
import {GerenciamentoResiduosComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/gerenciamento-residuos/gerenciamento-residuos.component";
import {MineracaoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/mineracao/mineracao.component";
import {EeLinhaTransmissaoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-linha-transmissao/ee-linha-transmissao.component";
import {EeSubestacaoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-subestacao/ee-subestacao.component";
import {EeEolicaComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-eolica/ee-eolica";
import {InfraObraFluviaisSaneamentoComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infra-obra-fluviais-saneamentos/infra-obra-fluviais-saneamento.component";
import { Requerimento } from '../../requerimento.model';
import {QueimaControladaComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/queima-controlada/queima-controlada.component";
import {
    SupressaoVegetalComponent
} from '../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/supressao-vegetal/supressao-vegetal.component';
import {
    AcaiComponent
} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/acai/acai.component";
import {ManejoFlorestalComponent} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/manejo-florestal/manejo-florestal.component";
import {
    ReflorestamentoSeminventarioComponent
} from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/reflorestamento-seminventario/reflorestamento-seminventario.component";

class BaseAtividadeEmpreendimentoComponent {
    requerimento: Requerimento;
    isModoVisualizacao: boolean;
    enviarFormConcluido: any;
}

function byFormularioAtividade(formularioAtividade: string, type: Type<BaseAtividadeEmpreendimentoComponent>):
    PredicateResolver<AbaAtividadesDto, Type<BaseAtividadeEmpreendimentoComponent>> {
    return {predicate: f => f.constante == formularioAtividade, resolved: type};
}

export class RequerimentoLicenciamentoAtividadeFormulario {
    private static predicates: PredicateResolver<AbaAtividadesDto, Type<BaseAtividadeEmpreendimentoComponent>>[] = [
        byFormularioAtividade('residuos-aterro-industrial-urbano', ResiduosAterroIndustrialUrbanoComponent),
        byFormularioAtividade('residuos-cemiterio-crematorio', ResiduosCemiterioCrematorioComponent),
        byFormularioAtividade('servico-combustivel', ServicoCombustivelComponent),
        byFormularioAtividade('residuos-rcc', ResiduosRccComponent),
        byFormularioAtividade('industria-biocombustivel', IndustriaBiocombustivelComponent),
        byFormularioAtividade('industria-frigorifico', IndustriaFrigorificoComponent),
        byFormularioAtividade('servico-geral', ServicoGeralComponent),
        byFormularioAtividade('sistema-tratamento', SistemaTratamentoComponent),
        byFormularioAtividade('residuos-tratamento-termico', ResiduosTratamentoTermicoComponent),
        byFormularioAtividade('residuos-transporte-produtos-perigosos', ResiduosTransporteProdutosPerigososComponent),
        byFormularioAtividade('residuos-geral', ResiduosGeralComponent),
        byFormularioAtividade('agua-passivo-supressao', AguaPassivoSupressaoComponent),
        byFormularioAtividade('madeira', MadeiraComponent),
        byFormularioAtividade('infra-parcel-solo-res-com', InfraParcelSoloResComComponent),
        byFormularioAtividade('infraestrutura-obras-viarias', InfraestruturaObrasViariasComponent),
        byFormularioAtividade('ee-hidrica', EeHidricaComponent),
        byFormularioAtividade('ee-termica', EeTermicaComponent),
        byFormularioAtividade('ee-fotovoltaica', EeFotovoltaicaComponent),
        byFormularioAtividade('gerenciamento-residuos', GerenciamentoResiduosComponent),
        byFormularioAtividade('mineracao', MineracaoComponent),
        byFormularioAtividade('ee-linha-transmissao', EeLinhaTransmissaoComponent),
        byFormularioAtividade('ee-subestacao', EeSubestacaoComponent),
        byFormularioAtividade('ee-eolica', EeEolicaComponent),
        byFormularioAtividade('infra-obra-fluviais-saneamento', InfraObraFluviaisSaneamentoComponent),
        byFormularioAtividade('queima-controlada', QueimaControladaComponent),
        byFormularioAtividade('supressao-de-vegetacao', SupressaoVegetalComponent),
        byFormularioAtividade('acai', AcaiComponent),
        byFormularioAtividade('manejo-florestal', ManejoFlorestalComponent),
        byFormularioAtividade('reflorestamento-seminventario', ReflorestamentoSeminventarioComponent)
    ];

    static get(requerimentoEmpreendimentoAtividade: AbaAtividadesDto): PredicateResolver<AbaAtividadesDto, Type<BaseAtividadeEmpreendimentoComponent>> {
        return this.predicates.find(r => r.predicate(requerimentoEmpreendimentoAtividade));
    }

}