import {NgModule} from '@angular/core';
import {CoreModule} from '@angular/flex-layout';
import {FuseSharedModule} from '@fuse/shared.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {DocumentosRequerimentosModule} from '../../documentos-requerimentos/documentos-requerimentos.module';
import {ItensTermosReferenciaModule} from '../../itens-termos-referencia/itens-termos-referencia.module';
import {RequerimentoDocumentacaoComplementarModule} from './requerimento-documentacao-complementar/requerimento-documentacao-complementar.module';
import {RequerimentoLocalizacaoModule} from './requerimento-localizacao/requerimento-localizacao.module';
import {RequerimentoResponsaveisModule} from './requerimento-responsaveis/requerimento-responsaveis.module';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {DialogDisponibilidadeHidricaComponent} from './dialog-disponibilidade-hidrica/dialog-disponibilidade-hidrica.component';
import {OverlayInfoCaptacaoComponent} from './overlay-info-captacao/overlay-info-captacao.component';
import {RequerimentoAlertDialogComponent} from './requerimento-alert-dialog/requerimento-alert-dialog.component';
import {AbaTitulacoesPareceresComponent} from './aba-titulacoes-pareceres/aba-titulacoes-pareceres.component';
import {ConsultaRecolhimentoTaxaModule} from '../../consulta-recolhimento-taxa/consulta-recolhimento-taxa.module';
import {DialogAlterarRtComponent} from './alteracao-responsavel-tecnico/dialog-alterar-rt/dialog-alterar-rt.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {AbaDocumentoProcessoRequerimentoComponent} from './aba-documento-processo-requerimento/aba-documento-processo-requerimento.component';
import {RequerimentoIsencaoComponent} from './requerimento-isencao/requerimento-isencao.component';
import {RequerimentoReaproveitamentoTaxaComponent} from './requerimento-reaproveitamento-taxa/requerimento-reaproveitamento-taxa.component';
import {NgxCurrencyModule} from 'ngx-currency';
import {VinculacaoProcessoComponent} from './vinculacao-processos/vinculacao-processo.component';
import { OutrosAtosInternosComponent } from './aba-documento-processo-requerimento/outros-atos-internos/outros-atos-internos.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatTabsModule} from "@angular/material/tabs";

@NgModule({
    imports: [
        RequerimentoLocalizacaoModule,
        RequerimentoDocumentacaoComplementarModule,
        RequerimentoResponsaveisModule,
        DocumentosRequerimentosModule,
        ConsultaRecolhimentoTaxaModule,
        ItensTermosReferenciaModule,
        LeafletModule,
        CoreModule,
        SharedModule,
        FuseSharedModule,

        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatRadioModule,
        MatTableModule,
        NgxCurrencyModule,
        MatAutocompleteModule,
        MatTabsModule,
    ],
    exports: [
        RequerimentoLocalizacaoModule,
        RequerimentoDocumentacaoComplementarModule,
        RequerimentoResponsaveisModule,
        DocumentosRequerimentosModule,
        ConsultaRecolhimentoTaxaModule,
        ItensTermosReferenciaModule,
        LeafletModule,
        AbaTitulacoesPareceresComponent,
        AbaDocumentoProcessoRequerimentoComponent,
        RequerimentoIsencaoComponent,
        RequerimentoReaproveitamentoTaxaComponent,
        VinculacaoProcessoComponent,
    ],
    declarations: [DialogDisponibilidadeHidricaComponent, OverlayInfoCaptacaoComponent, RequerimentoAlertDialogComponent,
        AbaTitulacoesPareceresComponent, DialogAlterarRtComponent, AbaDocumentoProcessoRequerimentoComponent, RequerimentoIsencaoComponent,
        RequerimentoReaproveitamentoTaxaComponent, VinculacaoProcessoComponent, OutrosAtosInternosComponent
    ],
    entryComponents: [DialogDisponibilidadeHidricaComponent, OverlayInfoCaptacaoComponent, DialogAlterarRtComponent]
})
export class RequerimentoSharedModule {
}
