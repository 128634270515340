import {BaseModel} from 'app/main/shared/models/base.model';
import {Finalidade} from '../finalidades/finalidade.model';
import {AtividadeArrecadacao} from '../../shared/models/arrecadacao-models/atividade-arrecadacao.model';

export class FinalidadeAtividade extends BaseModel {

    constructor(
        public id?: number,
        public atividade?: AtividadeArrecadacao,
        public finalidade?: Finalidade,
        public idAtividadeArrecadacao?: number
    ) {
        super();
    }

    static fromJson(json: any): FinalidadeAtividade {
        if (json === undefined || json === null) {
            return null;
        }
        return new FinalidadeAtividade(
            json.id,
            AtividadeArrecadacao.fromJson(json.atividade),
            Finalidade.fromJson(json.finalidade),
            json.idAtividadeArrecadacao
        );
    }
}
