import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {Finalidade} from '../finalidade.model';
import {FormControl, Validators} from '@angular/forms';
import {FinalidadeService} from '../finalidade.service';
import {fuseAnimations} from '@fuse/animations';
import {BaseEnum} from '../../../shared/enums/base.enum';

@Component({
    selector: 'app-finalidade-detail',
    templateUrl: './finalidade-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class FinalidadeDetailComponent extends BaseDetailComponent<Finalidade> {

    constructor(
        protected service: FinalidadeService,
        protected injector: Injector
    ) {
        super(injector, new Finalidade(), service, Finalidade.fromJson);
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            descricao: [null, [Validators.required, Validators.maxLength(2048)]],
            formulario: [null, [Validators.required, Validators.maxLength(255)]],
            exigeVistoriaTecnica: new FormControl(false)
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova finalidade';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando a finalidade: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando a finalidade: ' + resourceName;
    }

    get formularios(): BaseEnum[] {
        return Finalidade.formularios;
    }
}

