import {Component, Inject, OnInit} from '@angular/core';
import {BaseModel} from "../../../../../shared/models/base.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SnackBarService} from "../../../../../shared/snack-bar/snack-bar.service";
import {RequerimentoOutorgaFinalidadeService} from "../../../../../shared/services/requerimento-outorga-finalidade.service";
import {RequerimentoOutorgaFinalidade} from "../../../../../shared/models/requerimento-outorga-finalidade.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {
    AdicionarIrrigacaoCulturaComponent,
    AdicionarIrrigacaoCulturaData
} from "./adicionar-irrigacao-cultura/adicionar-irrigacao-cultura.component";
import {MetodoSistemaIrrigacao} from "./metodo-sistema-irrigacao.model";
import {MetodoSistemaIrrigacaoService} from "../../../../../shared/services/metodo-sistema-irrigacao.service";
import {EvapoPrecipService} from "../../../../../shared/services/evapo-precip.service";
import {EvapoPrecipDto} from "../../../../../shared/models/evapo-precip-dto.model";
import {Ponto} from "../../../../../shared/models/hidrico-models/ponto.model";
import {FormUtil} from "../../../../../shared/util/form-util";
import {isNullOrUndefined} from "util";
import {IrrigacaoService} from "../../../../../shared/services/irrigacao.service";
import {IrrigacaoDTO} from "./irrigacao-dto.model";
import {IrrigacaoCultura} from "./irrigacao-cultura.model";
import {Util} from "leaflet";
import {forkJoin, of} from "rxjs";
import {catchError} from "rxjs/internal/operators";
import {ConfirmarExclusaoDialogComponent} from "../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component";
import {ENUM_MES_VALUES_ONLY} from "../../../../../shared/enums/mes.enum";
import indexOf = Util.indexOf;

@Component({
    templateUrl: './adicionar-irrigacao.component.html',
    styleUrls: ['./adicionar-irrigacao.component.scss']
})
export class AdicionarIrrigacaoComponent implements OnInit {
    requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade
    irrigacao: IrrigacaoDTO
    meses = ENUM_MES_VALUES_ONLY;

    form: FormGroup;
    pontoSelecionado: FormControl = new FormControl();
    pontos: Ponto[] = [];

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b
    private metodosSistemasDeIrrigacoes: MetodoSistemaIrrigacao[] = null;
    private evapoPrecip: EvapoPrecipDto = null;
    private houveAlteracaoPersistida: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<AdicionarIrrigacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AdicionarIrrigacaoData,
        private snackBarService: SnackBarService,
        private matDialog: MatDialog,
        private formBuilder: FormBuilder,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private metodoSistemaIrrigacaoService: MetodoSistemaIrrigacaoService,
        private evapoPrecipService: EvapoPrecipService,
        private irrigacaoService: IrrigacaoService,
    ) {
        dialogRef.disableClose = true;
        this.requerimentoOutorgaFinalidade = this.data.requerimentoOutorgaFinalidade;
        this.pontos = this.data.pontos;
        if (isNullOrUndefined(this.data.irrigacao)) {
            this.irrigacao = new IrrigacaoDTO();
            this.irrigacao.culturas = [];
            this.irrigacao.pontos = [];
        } else {
            this.irrigacao = this.data.irrigacao;
        }
        this.irrigacao.idRequerimentoOutorgaFinalidade = this.requerimentoOutorgaFinalidade.id;
    }

    ngOnInit() {
        this.buildFormAndPatch();
        this.calcularNecessidades();
    }

    salvar(showMessages = true) {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            if (this.irrigacao.pontos.length == 0){
                if (showMessages) {
                    this.snackBarService.showAlert('É necessário adicionar um ponto!')
                }
                return;
            }
            if (this.irrigacao.culturas.length == 0){
                if (showMessages) {
                    this.snackBarService.showAlert('É necessário adicionar uma cultura!')
                }
                return;
            }

            Object.assign(this.irrigacao, this.form.getRawValue());
            this.irrigacaoService.criar(this.irrigacao).subscribe(result => {
                Object.assign(this.irrigacao, result);
                this.houveAlteracaoPersistida = true;
                if (showMessages) {
                    this.snackBarService.showSuccess('Finalidade Irrigação salva com sucesso')
                }
            }, e => {
                if (showMessages) {
                    this.snackBarService.showError('Erro ao salvar finalidade', e)
                }
            });
        } else {
            if (showMessages) {
                this.snackBarService.showAlert('Preencha todos os campos do formulário')
            }
        }
    }

    private buildFormAndPatch() {
        this.form = this.formBuilder.group({
            identificacao: [null, Validators.required],
            possuiReservatorioArtificial: [null, Validators.required],
            volumeReservatorio: [null],
            existeOutorga: [null, Validators.required],
            orgaoCedenteOutorga: [null],
            numeroOutorga: [null],
            dataOutorga: [null],
            validadeOutorga: [null],
        });
        if (this.irrigacao)
            this.form.patchValue(this.irrigacao);

        this.form.controls.possuiReservatorioArtificial.valueChanges.subscribe(value => {
            FormUtil.baseOnChange(value && value == true, this.form.controls.volumeReservatorio)
        });
        this.form.controls.existeOutorga.valueChanges.subscribe(value => {
            FormUtil.baseOnChange(value && value == true, this.form.controls.orgaoCedenteOutorga)
            FormUtil.baseOnChange(value && value == true, this.form.controls.numeroOutorga)
            FormUtil.baseOnChange(value && value == true, this.form.controls.dataOutorga)
            FormUtil.baseOnChange(value && value == true, this.form.controls.validadeOutorga)
        })
    }

    async adicionarCultura() {
        try {
            if (this.metodosSistemasDeIrrigacoes == null || this.evapoPrecip == null) {
                const result = await forkJoin({
                    metodosSistemasDeIrrigacoes: this.metodoSistemaIrrigacaoService.getAll(),
                    evapoPrecip: this.evapoPrecipService.findByRequerimentoOutorga(this.requerimentoOutorgaFinalidade.requerimentoOutorga)
                }).pipe(catchError(e => of(e))).toPromise()
                this.metodosSistemasDeIrrigacoes = result.metodosSistemasDeIrrigacoes;
                this.evapoPrecip = result.evapoPrecip;
            }
            this.matDialog.open<AdicionarIrrigacaoCulturaComponent, AdicionarIrrigacaoCulturaData>(AdicionarIrrigacaoCulturaComponent,
                {
                    maxHeight: '90vh',
                    maxWidth: '90vw',
                    data: {
                        irrigacao: this.irrigacao,
                        metodosSistemasDeIrrigacoes: this.metodosSistemasDeIrrigacoes,
                        evapotranspiracao: this.evapoPrecip.evapotranspiracao,
                        precipitacao: this.evapoPrecip.precipitacao
                    }
                }).afterClosed().subscribe(result => {
                if (result) {
                    this.irrigacao.culturas.push(result);
                    this.salvar(false)
                }
            });
        } catch (e) {
            this.snackBarService.showError('Erro ao Buscar dados auxiliares das culturas', e);
        }
    }

    adicionarPonto() {
        if (this.pontoSelecionado.value) {
            const ponto = this.pontoSelecionado.value;
            this.pontoSelecionado.setValue(null);

            this.irrigacao.pontos.push(ponto);
            this.irrigacao.pontos = [...this.irrigacao.pontos];

            this.pontos.splice(this.pontos.indexOf(ponto), 1);
            this.pontos = [...this.pontos];
            this.salvar(false)
        }
    }

    removerPonto(item: Ponto) {
        this.matDialog.open(ConfirmarExclusaoDialogComponent, {data: item.id}).afterClosed().subscribe(result => {
            if (result) {
                this.pontos = [...this.pontos, ...this.irrigacao.pontos.splice(this.irrigacao.pontos.indexOf(item), 1)];
                this.irrigacao.pontos = [...this.irrigacao.pontos];
                this.salvar(false)
            }
        })
    }

    excluirCultura(cultura: IrrigacaoCultura) {
        this.matDialog.open(ConfirmarExclusaoDialogComponent, {data: cultura.culturas}).afterClosed().subscribe(result => {
            if (result) {
                this.irrigacao.culturas.splice(indexOf(this.irrigacao.culturas, cultura), 1)
                this.irrigacao.culturas = [...this.irrigacao.culturas];
                this.salvar(false)
            }
        })
    }

    fechar() {
        this.dialogRef.close(this.houveAlteracaoPersistida)
    }

    private calcularNecessidades() {
        if (!isNullOrUndefined(this.irrigacao) && !isNullOrUndefined(this.irrigacao.id)) {
            this.irrigacaoService.calculoNecessidades(this.irrigacao).subscribe(result => {
                this.irrigacao.resumoNecessidades = result;
            }, e => this.snackBarService.showError('Erro ao calcular necessidades', e));
        }
    }
}

export interface AdicionarIrrigacaoData {
    requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;
    irrigacao: IrrigacaoDTO;
    pontos: Ponto[];
}
