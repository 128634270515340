import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {geoJSON, latLng, TileLayer, tileLayer} from 'leaflet';
import {HidricoService, TipoGeoPortal} from '../../services/hidrico-service/hidrico.service';
import {PontoService} from "../../services/ponto.service";
import {SnackBarService} from "../../snack-bar/snack-bar.service";

export interface VisualizarGeometriaDialogData {
    id: number;
}

@Component({
    selector: 'app-visualizar-geometria-dialog',
    templateUrl: './visualizar-geometria-dialog.component.html',
})
export class VisualizarGeometriaDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<VisualizarGeometriaDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: VisualizarGeometriaDialogData,
        private pontoService: PontoService,
        private snackBarService: SnackBarService
    ) {
    }

    map: L.Map;
    jsonLayer: L.GeoJSON;

    layerOpenStreetMap = tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
            maxZoom: 19,
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }
    );

    worldImagery = tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
            attribution:
                'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        }
    );

    ibgeGeoServer =
        'https://geoservicos.ibge.gov.br/geoserver/wms?SRS=urn:ogc:def:crs:EPSG:4674&';

    layerEstado = new TileLayer.WMS(
        `${this.ibgeGeoServer}CQL_FILTER=nm_estado='MATO GROSSO'`,
        {
            layers: 'CGEO:CGEO_Estados',
            format: 'image/png',
            transparent: true,
            opacity: 0.2
        }
    );

    layerMunicipios = new TileLayer.WMS(
        `${this.ibgeGeoServer}CQL_FILTER=geocodigo>=5100102 and geocodigo<=5108600&`,
        {
            layers: 'CCAR:BC250_2019_Municipio_A',
            format: 'image/png',
            transparent: true,
            opacity: 0.5
        }
    );

    layers = [
        this.worldImagery,
        this.layerEstado,
        this.layerMunicipios
    ];

    controlLayers = {
        baseLayers: {
            'World Imagery': this.worldImagery,
            'Open Street Map': this.layerOpenStreetMap
        },
        overlays: {
            Estado: this.layerEstado,
            Municípios: this.layerMunicipios
        }
    };

    onMapReady(map: L.Map): void {
        this.map = map;
        this.map.setMaxZoom(17);
        this.jsonLayer = geoJSON().addTo(map);
        this.atualizarMapa();
        this.map.invalidateSize();
    }

    atualizarMapa(): void {
        this.jsonLayer.clearLayers();
        if (this.data && this.data.id) {
            this.pontoService.getFeatureFromPonto(this.data.id).subscribe(geojson => {
                this.jsonLayer.addData(geojson);
                this.map.fitBounds(this.jsonLayer.getBounds());
            }, () => this.snackBarService.showError('Erro ao carregar dados do mapa.'));
        } else {
            this.map.setView(latLng(-12.9436372, -56.0444165), 6);
        }
    }

    close(): void {
        this.dialogRef.close(true);
    }
}
