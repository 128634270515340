import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'environments/environment';
import {ObjetivoOutorga} from "../models/objetivo-outorga.model";
import {FiltroObjetivoFinalidadeDTO} from "../models/filtro-objetivo-finalidade-dto.model";

@Injectable({
    providedIn: 'root'
})
export class ObjetivoFinalidadeService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/objetivo-finalidade';
    }

    getAllByObjetivos(objetivos: ObjetivoOutorga[]): Observable<FiltroObjetivoFinalidadeDTO[]> {
        let httpParams = new HttpParams();
        objetivos.forEach(obj => httpParams = httpParams.append('objetivo', obj.id.toString()))
        const url = `${this.urlResource}/dto`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    private jsonDataToResourceList(jsonData: any[]): FiltroObjetivoFinalidadeDTO[] {
        const entries: FiltroObjetivoFinalidadeDTO[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new FiltroObjetivoFinalidadeDTO(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

    private jsonDataToResource(jsonData: any): FiltroObjetivoFinalidadeDTO {
        return Object.assign(new FiltroObjetivoFinalidadeDTO(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
