import {ResponsavelTecnico} from '../../../../../shared/models/responsavel-tecnico.model';
import {EnumTipoDocumentoRL} from './enum-tipo-documento-rl.model';

/**
 * Model que define o documento do RepresentanteLegal.
 */
export class DocumentoRL {

    constructor(
        public id?: number,
        public nomeArquivo?: string,
        public tipo?: EnumTipoDocumentoRL,
        public representanteLegal?: ResponsavelTecnico,//mesmos dados, refatorar nome
        public sigilo?: boolean,
        public arquivo?: any // MultiPart / Blob
    ) {
    }

    static fromJson(json: any): DocumentoRL {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoRL(
            json.id,
            json.nomeArquivo,
            EnumTipoDocumentoRL.fromJson(json.tipo),
            ResponsavelTecnico.fromJson(json.representanteLegal),
            json.sigilo,
            json.arquivo
        );
    }

    static fromJsons(json: any): DocumentoRL[] {
        const DocumentoRLS: DocumentoRL[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((documento) => {
                DocumentoRLS.push(DocumentoRL.fromJson(documento));
            });
            return DocumentoRLS;
        }
    }

    get obrigatorio(): boolean{
        return this.tipo === EnumTipoDocumentoRL.PROCURACAO;
    }
}
