import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {Requerimento} from '../../../pages/requerimentos/requerimento.model';
import {EspelhoOutorgaDto} from '../../../pages/requerimentos/requerimento-outorga/espelho-outorga-dto.model';
import {RequerimentoOutorgaService} from '../../../pages/requerimentos/requerimento-outorga/requerimento-outorga.service';
import {TipoProcesso} from '../../../pages/tipos-processo/tipo-processo.model';
import {FormularioReqLicenciamentoDto} from '../../models/formulario-req-licenciamento.dto';
import {RequerimentoFormularioLicenciamentoService} from '../../../pages/requerimentos/requerimento-licenciamento/requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service';
import {AtividadeArrecadacaoService} from '../../services/arrecadacao-service/atividade-arrecadacao.service';
import {ObjetivoLicenciamentoService} from '../../services/objetivo-licenciamento.service';
import {RequerimentoLicenciamento} from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model';
import {RequerimentoLicenciamentoService} from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.service';
import {ObjetivoLicenciamento} from '../../models/objetivo-licenciamento.model';
import {AtividadeParametroLicenciamentoService} from '../../services/licenciamento-config-service/atividade-parametro-licenciamento.service';
import {TitulacaoService} from 'app/main/pages/titulacoes/titulacao.service';
import {TituloParametrizacaoObjetivoService} from '../../services/licenciamento-config-service/titulo-parametrizacao-objetivos.service';
import {ObjetivoParametrizacaoObjetivoService} from '../../services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service';
import { EnumInstanciaRequerimento } from 'app/main/pages/requerimentos/menu-novo-requerimento/instancia-requerimento.enum';

@Component({
    selector: 'app-aba-requerimento',
    templateUrl: './requerimento-aba.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoAbaComponent implements OnInit {

    @Input() requerimento = new Requerimento();

    titulos: any[] = [];
    objetivos: any[] = [];
    requerimentoLicenciamento: RequerimentoLicenciamento;
    requerimentoLicenciamentoList: RequerimentoLicenciamento[] = [];
    objetivosLicenciamento: ObjetivoLicenciamento[];
    objetivosTitulacaoLicenciamento: any[];

    requerimentoOutorga: EspelhoOutorgaDto;
    tipoProcessos: TipoProcesso[] = [];
    displayedColumns: string[] = ['finalidade', 'atividade', 'ppd'];
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoDto;
    consultaAtividadeServico: any = [];

    constructor(
        private requerimentoOutorgaService: RequerimentoOutorgaService,
        private atividadeArrecadacaoService: AtividadeArrecadacaoService,
        protected requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private objetivosLicenciamentoService: ObjetivoLicenciamentoService,
        private parametroAtividadeLicenciamentoService: AtividadeParametroLicenciamentoService,
        private objetivoParametroObjetivoService: ObjetivoParametrizacaoObjetivoService,
        private titulacaoService: TitulacaoService,
        private tituloParametrizacaoObjetivoService: TituloParametrizacaoObjetivoService,
        private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.tipoProcessos.push(this.requerimento.tipoProcesso);
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.RECURSO_HIDRICO.toString()) {
            this.recuperarRequerimentoOutorga();
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO.toString()
            || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.RECURSO_FLORESTAL.toString()
            || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA.toString()
            || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS.toString()) {
            await this.carregarListaDeAtividades();
        } 
        await this.getObjetivosApresentados();
    }

    private recuperarRequerimentoOutorga(): void {
        if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
            this.requerimentoOutorgaService.getEspelhoOutorga(this.requerimento).subscribe(requerimentoOutoroga => {
                this.requerimentoOutorga = requerimentoOutoroga;
            });
        }
    }

    private async carregarListaDeAtividades(): Promise<void> {
        if (this.requerimento) {
            this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
            this.requerimentoLicenciamentoList.push(this.requerimentoLicenciamento);
            if (this.requerimentoLicenciamento) {
                this.objetivosLicenciamento = await this.objetivosLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();
            }

            this.objetivosLicenciamento.forEach(async o => {
                o.titulacao = await this.titulacaoService.getById(await this.objetivoParametrizacaoObjetivoService.getIdTitulacaoByIdObjetivoLic(o.idObjetivoLicenciamentoAmbiental).toPromise()).toPromise();
            });

            // this.objetivoParametroObjetivoService.getIdTitulacaoByIdObjetivoLic(o.id)
            //     .pipe(switchMap(idTitulacao => {
            //             return idTitulacao;
            //             // return this.titulacaoService.getById((idTitulacao))
            //             //     .pipe(map((res) => {
            //             //         this.objetivosTitulacaoLicenciamento[index].titulacao = o;
            //             // }))
            // }))
            this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId(this.requerimento.id).subscribe(formularioReqLicenciamentoDto => {
                this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                this.atividadeArrecadacaoService.getAtividadeDto(this.formularioReqLicenciamentoDto.requerimentoLicenciamento.idAtividade,
                    this.formularioReqLicenciamentoDto.requerimentoLicenciamento.idGrupoAtividade)
                    .toPromise().then(async atividade => {
                    atividade['ppd'] = this.formularioReqLicenciamentoDto.requerimentoLicenciamento.ppd;
                    this.consultaAtividadeServico = [];
                    this.consultaAtividadeServico.push(atividade);
                });
            });
        }
    }


    private async getObjetivosApresentados() {
        let objs;
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO.toString() || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA.toString() ||
            this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS.toString()) { // Licenciamento Empreendimentos
            await this.objetivoParametroObjetivoService.getByParametrizacaoObjetivo(this.requerimento.idParametroObjetivo).toPromise().then(paramObjs => {
                objs = paramObjs
            });
        }

        this.objetivos = (objs || []).map(async obj => {
            this.titulos = await this.tituloParametrizacaoObjetivoService.getByParametrizacaoObjetivo(obj.idObjetivoParametrizacao).toPromise()
            return {
                objetivo: {
                    objetivo: this.objetivos.find(o => o.id === obj.idObjetivoParametrizacao),
                    titulo: this.titulos.find(o => o.id === obj.idTituloEmitidoAlterado),
                },
                vinculo: obj
            }
        });

        this.objetivos = this.objetivos.filter(obj => obj.objetivo && obj.objetivo.tipoRequerimento.id === this.requerimento.tipoRequerimento.id)

        // if (this.requerimento.tipoProcesso.id == 104) {
        //     if (!this.requerimento.numeroProtocoloVinculado) {
        //         this.objetivosOutros = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 1)
        //     } else {
        //         this.objetivosPrincipais = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 1)
        //     }
        // } else {
        //     this.objetivosPrincipais = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 1)
        //     this.objetivosOutros = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 2)
        //     this.regraDeDesabilitarHabilitarObjetivos()
        //     if (this.requerimento.idTipoRequerimentoTifLas != 2) {
        //         this.iniciaRegrasLI()
        //     }
        // }

    }


}

