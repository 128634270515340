import {Ponto} from "./ponto.model";

export class PontoVazaoMensalDRDH {
    constructor(
        public id?: number,
        public mes?: string,
        public vazao?: number,
        public ponto?: Ponto,
    ) {
    }

    static fromJson(json: any): PontoVazaoMensalDRDH {
        if (json === undefined || json === null) {
            return null;
        }
        return new PontoVazaoMensalDRDH(
            json.id ? json.id : null,
            json.mes ? json.mes : null,
            json.vazao ? json.vazao : null,
            json.ponto ? Ponto.fromJson(json.ponto) : null,
        );
    }

    static fromJsons(json: any): PontoVazaoMensalDRDH[] {
        const itens: PontoVazaoMensalDRDH[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => {
                itens.push(PontoVazaoMensalDRDH.fromJson(item));
            });
            return itens;
        }
    }
}