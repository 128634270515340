import {Component, Input, OnInit} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {PostoFluviometricoService} from '../../../services/posto-fluviometrico.service';
import {ObraPostoFluviometrico} from '../../../models/obra-posto-fluviometrico.model';
import {ObraBarragem} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-obra-barragem/obra-barragem.model';

@Component({
    selector: 'app-aba-objetivo-classificacao-barragem',
    templateUrl: './aba-objetivo-classificacao-barragem.component.html'
})
export class AbaObjetivoClassificacaoBarragemComponent implements OnInit {

    @Input() ponto: Ponto;
    tiposObras = ObraBarragem.tiposObras;
    tiposVertedouros = ObraBarragem.tiposVertedouros;
    postosFluviometricosAdicionados: ObraPostoFluviometrico[];

    constructor(private postoFluviometricoService: PostoFluviometricoService) {
    }

    ngOnInit(): void {
        this.carregarObraPostoFluviometricos();
    }

    private carregarObraPostoFluviometricos(): void {
        if (this.ponto.obraBarragem) {
            this.postoFluviometricoService.getAllByObraBarragem(this.ponto.obraBarragem).subscribe(postos => {
                this.postosFluviometricosAdicionados = postos;
            });
        }
    }
}
