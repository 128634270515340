/**
 * Chave utilizada para coparar a instanciaRequerimento que veio no tipoProcesso
 */
export enum EnumInstanciaRequerimento {
    AUTORIZACAO_OUTROS_DOCUMENTOS = 'AUTORIZACAO_OUTROS_DOCUMENTOS',
    LICENCIAMENTO_ADESAO = 'LICENCIAMENTO_ADESAO',
    LICENCIAMENTO_TRIFASICO = 'LICENCIAMENTO_TRIFASICO',
    RECURSO_HIDRICO = 'RECURSO_HIDRICO',
    RECURSO_FLORESTAL = 'RECURSO_FLORESTAL',
    TERMO_REFERENCIA = 'TERMO_REFERENCIA',
    FLUXO_NAO_DEFINIDO = 'FLUXO_NAO_DEFINIDO'
}