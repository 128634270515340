import {Injectable, Injector} from '@angular/core';
import {ValidacaoGeometria} from "../../models/geoadmin/validacao-geometria.model";
import {catchError, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {BaseService} from "../base.service";
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ValidacaoGeometriaService extends BaseService<ValidacaoGeometria> {

    constructor(injector: Injector) {
        super('/api/valicacoes-geometrias', injector, ValidacaoGeometria.prototype, ValidacaoGeometria.fromJson)
        this.urlResource = environment.URL_GEOADMIN + this.apiPath;
    }

    validarWkt(wkt: string, srid: number ): Observable<ValidacaoGeometria> {
        return this.http.post<ValidacaoGeometria>(`${this.urlResource}/wkt`, {wkt: wkt, srid: srid}).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    validarGeoJson(geoJson: string): Observable<ValidacaoGeometria> {
        return this.http.post<ValidacaoGeometria>(`${this.urlResource}/json`, geoJson).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }
}



