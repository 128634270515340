import {BaseModel} from 'app/main/shared/models/base.model';

export class CompostoQuimico extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string
    ) {
        super();
    }

    static fromJson(json: any): CompostoQuimico {
        if (json === undefined || json === null) {
            return null;
        }
        return new CompostoQuimico(json.id, json.nome);
    }
}