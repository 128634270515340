import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {RequerimentoService} from '../../requerimento.service';
import {MatDialog} from '@angular/material';
import {TermoReferenciaService} from 'app/main/pages/termos-referencia/termo-referencia.service';
import {TermoReferenciaRequerimento} from 'app/main/shared/models/termo-referencia-requerimento.model';
import {ItemTermoReferencia} from 'app/main/pages/itens-termos-referencia/item-termo-referencia.model';
import {
    DocumentoRequerimento,
    TipoDocumentoRequerimento
} from 'app/main/pages/documentos-requerimentos/documento-requerimento.model';
import {
    DocumentoRequerimentoDialogComponent
} from 'app/main/pages/documentos-requerimentos/documento-requerimento-dialog/documento-requerimento-dialog.component';
import {DocumentoRequerimentoService} from 'app/main/pages/documentos-requerimentos/documento-requerimento.service';
import {
    ConfirmarExclusaoDialogComponent
} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {
    ItemTermoReferenciaInformacoesAdicionaisDialogComponent
} from 'app/main/pages/itens-termos-referencia/item-termo-referencia-informacoes-adicionais-dialog/item-termo-referencia-informacoes-adicionais-dialog.component';
import {BaseTabRequerimentoComponent} from '../base-requerimento.component';
import {takeUntil} from 'rxjs/operators';
import {ItemTermoReferenciaRequerimento} from 'app/main/shared/models/item-termo-referencia-requerimento.model';
import {RequerimentoSteps} from '../requerimento.steps';
import {
    ItemTermoReferenciaRequerimentoChecklistService
} from 'app/main/shared/services/item-termo-referencia-requerimento-checklist.service';
import {
    ItemTermoReferenciaRequerimentoChecklist
} from 'app/main/shared/models/item-termo-referencia-requerimento-checklist.model';
import {ErrosAbaRequerimento} from '../requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {
    SubstiruirDocumentoDialogComponent
} from 'app/main/pages/documentos-requerimentos/substituir-documento-dialog/substituir-documento-dialog.component';
import {
    ObjetivoLicenciamentoSubatividadeService
} from "../../../../shared/services/objetivo-licenciamento-subatividade.service";
import {
    ConfiguracaoLicenciamentoService
} from "../../../../shared/services/licenciamento-config-service/configuracao-licenciamento.service";
import {
    TermoReferenciaConfiguracaoLicenciamentoService
} from "../../../../shared/services/licenciamento-config-service/termo-referencia-configuracao-licenciamento.service";
import {
    AtividadeConfiguracaoLicenciamentoService
} from "../../../../shared/services/licenciamento-config-service/atividade-configuracao-licenciamento.service";
import {ObjetivoLicenciamentoService} from "../../../../shared/services/objetivo-licenciamento.service";
import {
    ObjetivoConfiguracaoLicenciamentoService
} from "../../../../shared/services/licenciamento-config-service/objetivo-configuracao-licenciamento.service";
import {EnumInstanciaRequerimento} from "../../menu-novo-requerimento/instancia-requerimento.enum";
import { it } from 'date-fns/locale';

@Component({
    selector: 'app-requerimento-documentacao-complementar',
    templateUrl: 'requerimento-documentacao-complementar.component.html',
    styleUrls: ['requerimento-documentacao-complementar.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoDocumentacaoComplementarComponent extends BaseTabRequerimentoComponent implements OnInit {
    termosReferencia: TermoReferenciaRequerimento[] = [];
    itensObrigatorios: ItemTermoReferencia[] = [];
    documentos: DocumentoRequerimento[] = [];
    itensTermoChecklist: ItemTermoReferenciaRequerimentoChecklist[] = [];
    statusAnalises = ItemTermoReferenciaRequerimentoChecklist.statusAnalises;
    erroConfiguracaoOutorga: string;
    erroConfiguracaoLicenciamento: string;
    isEdicaoDesabilitada: boolean = false;
    itemChecklist: ItemTermoReferenciaRequerimentoChecklist;

    constructor(
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected service: RequerimentoService,
        protected termoReferenciaService: TermoReferenciaService,
        protected documentoRequerimentoService: DocumentoRequerimentoService,
        protected itemTermoReferenciaRequerimentoChecklistService: ItemTermoReferenciaRequerimentoChecklistService,
        private configuracaoLicenciamentoService: ConfiguracaoLicenciamentoService,
        private termoReferenciaConfiguracaoLicenciamentoService: TermoReferenciaConfiguracaoLicenciamentoService,
        private atividadeConfiguracaoLicenciamentoService: AtividadeConfiguracaoLicenciamentoService,
        private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private objetivoConfiguracaoLicenciamentoService: ObjetivoConfiguracaoLicenciamentoService,
        private objetivoLicenciamentoSubatividadeService: ObjetivoLicenciamentoSubatividadeService,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.DOCUMENTOS.component = this;
    }

    ngOnInit() {
        this.inicializaDados();
    }

    inicializaDados() {
        this.helper.onCurrentStepChange().pipe(takeUntil(this._unsubscribeAll)).subscribe(async e => {
            if (e.newTabIndex === RequerimentoSteps.DOCUMENTOS.index) {
                if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
                    this.termoReferenciaService.getByRequerimentoOutorgaId(this.requerimentoOutorga.id).subscribe(termosReferencia => {
                        this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
                        this.listarItensTermoReferenciaObrigatorios();
                    }, error => {
                        this.erroConfiguracaoOutorga = error.errors[0];
                        this.snackBarService.showError('Erro ao listar termos', error);
                    });
                } else if (this.requerimento.tipoProcesso.tipo === 'LICENCIAMENTO' ||
                    this.requerimento.tipoProcesso.tipo === 'AUTORIZACAO' ||
                    this.requerimento.tipoProcesso.tipo === 'ESTUDO_IMPACTO_AMBIENTAL_EIA_RIMA') {
                    if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO
                        || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA
                        || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS
                        || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.RECURSO_FLORESTAL) {
                        if(this.requerimento.termoReferencia != null){
                            await this.recuperarTermosDeReferencia();
                        }else{
                            await this.recuperarTermosDeReferenciaDeConfiguracao();
                            await this.recuperarTermosDeReferencia();
                        }
                    } else {
                        await this.recuperarTermosDeReferencia();
                    }
                }
            }
        });
    }

    private async recuperarTermosDeReferenciaDeConfiguracao() {
        await this.configuracaoLicenciamentoService.getAllAtivos().toPromise().then(async configLicen => {
            const config = configLicen.filter(conf => conf.idTipoProcesso === this.requerimento.tipoProcesso.id)
            for (let conf of config) {
                conf['atividades'] = await this.atividadeConfiguracaoLicenciamentoService.getByConfiguracao(conf.id).toPromise()
                conf['objetivos'] = await this.objetivoConfiguracaoLicenciamentoService.getByConfiguracao(conf.id).toPromise()
            }
            let objsSalvo = await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise()
            let atvComplementar = await this.objetivoLicenciamentoSubatividadeService.getRequerimentoLicenciamentoPorId(this.requerimentoLicenciamento.id).toPromise()

            let filtroAtv = []
            for (let filtro of config) {
                for (let atv of filtro['atividades']) {
                    if (atv.atividade === this.requerimentoLicenciamento.idAtividade) {
                        filtroAtv.push(filtro)
                    }
                }
            }

            if (filtroAtv.length === 0) {
                for (let filtro of config) {
                    for (let atv of filtro['atividades']) {
                        if (atvComplementar) {
                            if (atv.atividade === atvComplementar.idAtividadeComplementar) {
                                filtroAtv.push(filtro)
                            }
                        }
                    }
                }
                if (filtroAtv.length === 0) {
                    this.snackBarService.showAlert('O(s) objetivos(s) ou Atividade(s) selecionadas no formulário não foram configuradas. Entre em contato com a administração da SEMA')
                    return
                }
            }

            let objsFiltro = false
            let filtroFinal = []

            for (let filtro of filtroAtv) {
                for (let objF of filtro['objetivos']) {
                    for (let objS of objsSalvo) {
                        if (objF.objetivoLicenciamento === objS.idObjetivoLicenciamentoAmbiental) {
                            objsFiltro = true
                            filtroFinal.push(filtro)
                        }
                    }
                }
            }

            this.idDepartamentoResponsavel = filtroFinal.find(config => config.idSetorResponsavel != null).idSetorResponsavel;

            if (!objsFiltro) {
                this.snackBarService.showAlert('O(s) objetivos(s) ou atividade(s) selecionadas no formulário não foram configuradas. Entre em contato com a administração da SEMA')
                return
            }

            for (let conf of filtroFinal) {
                let trms = await this.termoReferenciaConfiguracaoLicenciamentoService.getByConfiguracao(conf.id).toPromise();
                await this.recuperarTermosDeReferenciaConfEmp(trms);
            }

            await this.recuperarTermosDeReferencia();

        })
    }

    private async recuperarTermosDeReferencia() {
        await this.termoReferenciaService.getByRequerimentoLicenciamentoId(this.requerimentoLicenciamento.id)
        .toPromise().then(termosReferencia => {
            this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
            this.listarItensTermoReferenciaObrigatorios();
        }).catch(error => {
            this.erroConfiguracaoLicenciamento = error.errors[0];
            this.snackBarService.showError('Erro ao listar termos', error);
        })
    }

    private async recuperarTermosDeReferenciaConfEmp(trms): Promise<void> {
        await this.termoReferenciaService.getByRequerimentoLicenciamentoIdConfEmp(this.requerimentoLicenciamento.id, trms)
        .toPromise().then(termosReferencia => {
            this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
            this.listarItensTermoReferenciaObrigatorios();
        }).catch(error => {
            this.erroConfiguracaoLicenciamento = error.errors[0];
            this.snackBarService.showError('Erro ao listar termos', error);
        });
    }

    descricaoFromTipoItemTermoReferencia(tipoItemTermoReferencia: string): string {
        let item = ItemTermoReferencia.tiposEspecificos.find(t => t.valor === tipoItemTermoReferencia);
        if(!item){
            item = ItemTermoReferencia.tipos.find(t => t.valor === tipoItemTermoReferencia);
        }
        return item.descricao;
    }

    documentosByItem(item: ItemTermoReferenciaRequerimento): DocumentoRequerimento[] {
        return this.documentos.filter(d => d.tipo === 'ITEM_TERMO_REFERENCIA' && d.itemTermoReferenciaRequerimento.id === item.id);
    }

    novoDocumentoDialog(itemTermoReferenciaRequerimento: ItemTermoReferenciaRequerimento, tipo: string): void {
        this.documentoDialog(new DocumentoRequerimento(null, null, null, this.requerimento, itemTermoReferenciaRequerimento, false, tipo));
    }

    informacoesAdicionais(itemTermoReferenciaRequerimento: ItemTermoReferenciaRequerimento): void {
        this.dialog.open(
            ItemTermoReferenciaInformacoesAdicionaisDialogComponent,
            {width: '600px', data: itemTermoReferenciaRequerimento.itemTermoReferencia}
        );
    }

    documentoDialog(documentoRequerimento: DocumentoRequerimento): void {
        if (!this.isSomenteVisualizacao) {
            const dialogRef = this.dialog.open(
                DocumentoRequerimentoDialogComponent,
                {
                    width: '600px',
                    data: documentoRequerimento
                }
            );

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    if (this.itensTermoChecklist.length > 0) {
                        this.alteraStatusAnaliseChecklist(documentoRequerimento);
                        // let itenTermoRefRequerimentoCheckList = this.itensTermoChecklist.filter(i => i.itemTermoReferenciaRequerimento.id = documentoRequerimento.itemTermoReferenciaRequerimento.id);
                        // if (!itenTermoRefRequerimentoCheckList) {
                        //     this.snackBarService.showError('Erro ao consultar o item termo de referência para este documento.');
                        //     return;
                        // }
                        // this.criarItemAnaliseCheckList(itenTermoRefRequerimentoCheckList[0]);
                    }
                    this.listarDocumentos();
                }
            });
        }
    }

    listarDocumentos(): void {
        this.documentoRequerimentoService
        .getByRequerimento(this.requerimento, [TipoDocumentoRequerimento.ITEM_TERMO_REFERENCIA])
        .subscribe(documentos => {
            this.documentos = documentos;
        });
    }

    listarItensTermoReferenciaObrigatorios(): void {
        const itens = this.termosReferencia.map(termoReferencia => termoReferencia.itens
        .map(itensTermoReferencia => itensTermoReferencia.itemTermoReferencia)
        .filter(item => item.obrigatorio));
        this.itensObrigatorios = [].concat.apply([], itens);
        this.listarDocumentos();
        this.listarChecklists();
    }

    checklistByItem(item: ItemTermoReferenciaRequerimento): ItemTermoReferenciaRequerimentoChecklist {
        return this.itensTermoChecklist.filter(e => e.itemTermoReferenciaRequerimento.id === item.id)
        .sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
        .pop();
    }

    checklistByItemComentario(item: ItemTermoReferenciaRequerimento): string {
        const lastChecklistItemRascunho = this.checklistByItem(item);
        if (!lastChecklistItemRascunho) {
            return null;
        }
        return lastChecklistItemRascunho.comentario;
    }

    checklistByItemData(item: ItemTermoReferenciaRequerimento): Date {
        const lastChecklistItemRascunho = this.checklistByItem(item);
        if (!lastChecklistItemRascunho) {
            return null;
        }
        return lastChecklistItemRascunho.dataInicio;
    }

    checklistByItemSituacao(item: ItemTermoReferenciaRequerimento): string {
        const lastChecklistItemRascunho = this.checklistByItem(item);
        if (!lastChecklistItemRascunho) {
            return null;
        }
        if (this.statusAnalises && lastChecklistItemRascunho.statusAnalise) {
            return this.statusAnalises.find(e => e.valor === lastChecklistItemRascunho.statusAnalise).descricao;
        }
        return null;
    }

    checklistByItemSituacaoEnum(item: ItemTermoReferenciaRequerimento): string {
        const lastChecklistItemRascunho = this.checklistByItem(item);
        if (!lastChecklistItemRascunho) {
            return null;
        }
        if (this.statusAnalises && lastChecklistItemRascunho.statusAnalise) {
            return this.statusAnalises.find(e => e.valor === lastChecklistItemRascunho.statusAnalise).valor;
        }
        return null;
    }

    private listarChecklists(): void {
        this.itemTermoReferenciaRequerimentoChecklistService
        .getAllByRequerimento(this.requerimento.id)
        .subscribe(items => {
            this.itensTermoChecklist = items;
        });
    }

    excluirDocumentoDialog(documentoRequerimento: DocumentoRequerimento): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: documentoRequerimento.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirDocumento(documentoRequerimento);
            }
        });
    }

    private excluirDocumento(documentoRequerimento: DocumentoRequerimento): void {
        this.documentoRequerimentoService
        .delete(documentoRequerimento.id)
        .subscribe(() => {
            this.snackBarService.showSuccess('Registro excluido com sucesso.');
            if (this.itensTermoChecklist.length > 0) {
               
                this.alteraStatusAnaliseChecklist(documentoRequerimento);
            }
            this.listarDocumentos();
        });
    }

    visualizarDocumento(documentoRequerimento: DocumentoRequerimento): void {
        window.open(`${this.documentoRequerimentoService.urlResource}/${documentoRequerimento.id}/visualizar`);
    }

    downloadDocumento(documentoRequerimento: DocumentoRequerimento): void {
        window.open(`${this.documentoRequerimentoService.urlResource}/${documentoRequerimento.id}/download`);
    }

    validarAba(erros: ErrosAbaRequerimento): void {
        if (!this.termosReferencia.length) {
            erros.push('O Requerimento precisa de pelo menos um Termo de Referência associado.', false);
        }
        if (this.itensTermoChecklist &&
            this.itensTermoChecklist.find(item => item.statusAnalise === 'NAO_ATENDE')) {
            erros.push('Verifique se há algum item do Termo de Referência com a situação "NÃO ATENDE".', false);
        }
        this.itensObrigatorios.forEach(item => {
            const possuiDoc = this.documentos.some(documento => documento.itemTermoReferenciaRequerimento.itemTermoReferencia.id === item.id);
            if (!possuiDoc) {
                erros.push(`O Item: ${item.numero} do Termo de Referência: ${item.termoReferencia.numero}, precisa de pelo menos um arquivo associado.`, false);
            }
        });
    }

    bloquearItemAposConferencia(item: ItemTermoReferenciaRequerimento): boolean {
        const lastChecklistItemRascunho = this.checklistByItem(item);
        if (!lastChecklistItemRascunho) {
            return false;
        }
        if (this.statusAnalises && lastChecklistItemRascunho.statusAnalise) {
            return this.isEdicaoDesabilitada = this.statusAnalises.find(
                    e => e.valor === lastChecklistItemRascunho.statusAnalise).valor !== 'NAO_ATENDE'
                && this.statusAnalises.find(
                    e => e.valor === lastChecklistItemRascunho.statusAnalise).valor !== 'NAO_AVALIADO';
        }
        return false;
    }

    alteraStatusAnaliseChecklist(documentoRequerimento: DocumentoRequerimento) {
        let itemChecklist: ItemTermoReferenciaRequerimentoChecklist;
        itemChecklist = this.checklistByItem(documentoRequerimento.itemTermoReferenciaRequerimento);
        itemChecklist.statusAnalise = 'NAO_AVALIADO';

        this.itensTermoChecklist.forEach((item, index) => {
            if (item.id === itemChecklist.id) {
                this.itensTermoChecklist[index] = itemChecklist;
            }
        });
        this.updateChecklists();
    }

    criarItemAnaliseCheckList(item: ItemTermoReferenciaRequerimentoChecklist) {
        const itemChecklist = new ItemTermoReferenciaRequerimentoChecklist(null, 'NAO_AVALIADO', null, null, null, item.itemTermoReferenciaRequerimento, item.servidor);
        this.saveChecklist(itemChecklist);
        this.listarChecklists();
    }

    private updateChecklists(): void {
        this.itemTermoReferenciaRequerimentoChecklistService
        .updateList(this.itensTermoChecklist)
        .subscribe(items => {
            this.itensTermoChecklist = items;
        });
    }

    private saveChecklist(itemArray: ItemTermoReferenciaRequerimentoChecklist): void {
        this.itemTermoReferenciaRequerimentoChecklistService
        .createNew(itemArray)
        .subscribe(item => {
            this.itemChecklist = item;
        });
    }

    corItemStatus(item: ItemTermoReferenciaRequerimento): string {
        const lastChecklistItemRascunho = this.checklistByItem(item);
        if (this.statusAnalises && item && lastChecklistItemRascunho.statusAnalise) {
            return this.statusAnalises.find(e => e.valor === lastChecklistItemRascunho
                .statusAnalise).valor == 'NAO_ATENDE' ? 'red' : 'white';
        }
        return 'white';
    }

    confirmaSubstituirDocumentoDialog(documentoRequerimento: DocumentoRequerimento): void {
        let itemChecklist: ItemTermoReferenciaRequerimentoChecklist;
        itemChecklist = this.checklistByItem(documentoRequerimento.itemTermoReferenciaRequerimento);
        if (!this.isSomenteVisualizacao) {
            const dialogRef = this.dialog.open(
                SubstiruirDocumentoDialogComponent,
                {
                    width: '600px',
                    data: itemChecklist.statusAnalise
                }
            );

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.documentoDialog(documentoRequerimento)
                }
            });
        }
    }

    openDocumentoDialog(docReq: DocumentoRequerimento): void {
        if (this.bloquearItemAposConferencia(docReq.itemTermoReferenciaRequerimento)) {
            return;
        }
        this.documentoDialog(docReq)
    }

    get isEmCorrecaoSuimis(): boolean {
        return this.requerimento && this.requerimento.situacaoProcesso === 'EM_CORRECAO' &&
            (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO ||
                this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL ||
                this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS);
    }
    
}