import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {Finalidade} from 'app/main/pages/finalidades/finalidade.model';
import {FinalidadeAtividade} from 'app/main/pages/finalidades-atividade/finalidade-atividade.model';
import {BaseModel} from '../../models/base.model';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {FinalidadeService} from 'app/main/pages/finalidades/finalidade.service';
import {FinalidadeAtividadeService} from 'app/main/pages/finalidades-atividade/finalidade-atividade.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RequerimentoFinalidadeAtividadeService} from '../../services/requerimento-finalidade-atividade.service';
import {MatDialog} from '@angular/material';
import {RequerimentoOutorgaFinalidadeAtividadeDTO} from '../../models/requerimento-finalidade-atividade-dto.model';
import {ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogComponent} from './confirmar-exclusao-requerimento-finalidade-atividade-dialog/confirmar-exclusao-requerimento-finalidade-atividade-dialog.component';
import {ConfirmarAdicaoRequerimentoFinalidadeAtividadeDialogComponent} from './confirmar-adicao-requerimento-finalidade-atividade-dialog/confirmar-adicao-requerimento-finalidade-atividade-dialog.component';
import {SnackBarService} from '../../snack-bar/snack-bar.service';
import {RequerimentoFinalidadeAtividadeDTO} from '../../models/req-finalidade-atividade-dto.model';
import {RequerimentoOutorga} from '../../../pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import {ObjetivoOutorga} from "../../models/objetivo-outorga.model";
import { debounceTime, filter, map, tap, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-requerimento-finalidade-atividade',
    templateUrl: './requerimento-finalidade-atividade.component.html'
})
export class RequerimentoFinalidadeAtividadeComponent implements OnInit, OnChanges {
    @Input() requerimento: Requerimento;
    @Input() requerimentoOutorga: RequerimentoOutorga;
    @Input() objetivosOutorga: ObjetivoOutorga[];
    @Output() finalidadesAtividade = new EventEmitter<any>();
    @Input() isSomenteVisualizacao = false;
    @Input() temDocumento = true;
    form: FormGroup;
    finalidades: Array<Finalidade>;
    finalidadeAtividadeList: Array<FinalidadeAtividade> = [];
    requerimentoFinalidadesAtividadesDTOs: Array<RequerimentoOutorgaFinalidadeAtividadeDTO> = [];

    atividadesEncontradas: Array<FinalidadeAtividade> = [];
    atividadePesquisada: FinalidadeAtividade;

    // Solicitado a inserção da dica conforme tarefa http://sgd.memora.com.br/issues/5688
    dicaFinalidade: String = 'Inserir a finalidade principal, a de maior uso no ponto.';

    constructor(
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected formBuilder: FormBuilder,
        protected finalidadeService: FinalidadeService,
        protected finalidadeAtividadeService: FinalidadeAtividadeService,
        protected requerimentoFinalidadeAtividadeService: RequerimentoFinalidadeAtividadeService
    ) {
    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.carregarFinalidades();
        this.carregarRequerimentoFinalidadeAtividade();
        this.buildRequerimentoFinalidadeAtividadeForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const change = changes['objetivosOutorga'] as SimpleChange;
        if (change && change.previousValue && change.currentValue && change.previousValue.length != change.currentValue.length) {
            this.carregarFinalidades()
            this.carregarRequerimentoFinalidadeAtividade();
        }
    }

    public buildRequerimentoFinalidadeAtividadeForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            finalidade: [null, [Validators.required]],
            finalidadeAtividade: [null, [Validators.required]],
            idRequerimento: [null],
            ppd: [null],
            atividadePesquisada:[null]
        });
    }

    private carregarFinalidades(): void {
        this.finalidades = [];
        if (this.objetivosOutorga && this.objetivosOutorga.length > 0) {
            this.finalidadeService.findAllByObjetivos(this.objetivosOutorga).subscribe(finalidades => {
                    this.finalidades = finalidades;
                }, () => this.snackBarService.showError('Erro ao carregar as finalidades.'));
        }
    }

    private carregarRequerimentoFinalidadeAtividade(): void {
        this.requerimentoFinalidadeAtividadeService
            .getOutorgaDTOByRequerimento(this.requerimento)
            .subscribe(resources => {
                this.requerimentoFinalidadesAtividadesDTOs = resources;
                this.finalidadesAtividade.emit({
                        requerimentoFinalidadesAtividadesLenght: this.requerimentoFinalidadesAtividadesDTOs.length
                    }
                );
            }, () => this.snackBarService.showError('Erro ao carregar as finalidades e atividades do requerimento.'));
    }

    public containFinalidadeAtividade(finalidadeAtividade: FinalidadeAtividade): boolean {
        if (this.requerimentoFinalidadesAtividadesDTOs.some(requerimentoFinalidadeAtividadeDTO =>
            requerimentoFinalidadeAtividadeDTO.finalidadeAtividade.atividade.id === finalidadeAtividade.atividade.id &&
            requerimentoFinalidadeAtividadeDTO.finalidadeAtividade.finalidade.id === finalidadeAtividade.finalidade.id)) {
            this.snackBarService.showAlert('Atividade já adicionada.');
            return true;
        }
        return false;
    }

    public containsFinalidade(finalidadeAtividade: FinalidadeAtividade): boolean {
        return this.requerimentoFinalidadesAtividadesDTOs.some(requerimentoFinalidadeAtividadeDTO =>
            requerimentoFinalidadeAtividadeDTO.finalidadeAtividade.finalidade.id === finalidadeAtividade.finalidade.id);
    }

    public atualizarFinalidadeAtividadeList(fa): void {
        this.form.get('finalidadeAtividade').reset();
        this.finalidadeAtividadeService
            .getAllByFinalidade(fa.value.id)
            .subscribe(finalidadeAtividadeList => {
                this.finalidadeAtividadeList = finalidadeAtividadeList;
                this.atividadesEncontradas = finalidadeAtividadeList;
            }, () => this.snackBarService.showError('Erro ao atualizar as finalidades e atividades.'));
    }

    addFinalidadeAtividade(): void {
        if (this.form.valid) {
            const finalidadeAtividade = this.form.get('finalidadeAtividade').value;
            if (this.containFinalidadeAtividade(finalidadeAtividade)) {
                this.form.reset();
                return;
            }

            const adicionar = () => {
                this.requerimentoFinalidadeAtividadeService
                    .create(new RequerimentoFinalidadeAtividadeDTO(finalidadeAtividade.id, this.requerimento.id))
                    .subscribe(() => {
                            this.snackBarService.showSuccess('Atividade adicionada com sucesso!');
                            this.carregarRequerimentoFinalidadeAtividade();
                            this.form.reset();
                        }, e => this.snackBarService.showError('Erro ao adicionar atividade.', e)
                    );
            };
            if (!this.containsFinalidade(finalidadeAtividade) && this.temDocumento) {
                const dialogRef = this.dialog.open(ConfirmarAdicaoRequerimentoFinalidadeAtividadeDialogComponent, {width: '450px'});

                dialogRef.afterClosed().subscribe(result => {
                    if (!!result) {
                        adicionar();
                    }
                });
            } else {
                adicionar();
            }
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    excluirFinalidadeAtividadeDialog(requerimentoOutorgaFinalidadeAtividadeDTO: RequerimentoOutorgaFinalidadeAtividadeDTO): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogComponent, {
            width: '450px',
            data: {requerimentoOutorgaFinalidadeAtividadeDTO: requerimentoOutorgaFinalidadeAtividadeDTO}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirFinalidadeAtividade(requerimentoOutorgaFinalidadeAtividadeDTO);
            }
        });
    }

    private excluirFinalidadeAtividade(requerimentoOutorgaFinalidadeAtividadeDTO: RequerimentoOutorgaFinalidadeAtividadeDTO): void {
        this.requerimentoFinalidadeAtividadeService
            .delete(requerimentoOutorgaFinalidadeAtividadeDTO)
            .subscribe(
                () => {
                    this.snackBarService.showSuccess('Atividade excluída com sucesso!');
                    this.carregarRequerimentoFinalidadeAtividade();
                },
                e => this.snackBarService.showError('Erro ao excluir atividade.', e)
            );
        this.form.reset();
    }

    pesquisaAtividadeDesc(event) {
        const atividadeDescricao = event.target.value;  
        if (atividadeDescricao.trim().length === 0) {
            this.atividadesEncontradas = this.finalidadeAtividadeList;
            return;
        } else if (atividadeDescricao.toLowerCase().trim().length > 2) {

            this.atividadesEncontradas = this.finalidadeAtividadeList.filter(
                (atv) => atv.atividade.descricao.toLowerCase().includes(atividadeDescricao.toLowerCase())
            );
        }
    } 
    
    atividadeSelecionada(atividade: FinalidadeAtividade){
        this.form.get('finalidadeAtividade').setValue(atividade);
    }

}
