import {BaseModel} from 'app/main/shared/models/base.model';

export class DoencaGrave extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public outro?: boolean
    ) {
        super();
    }

    static fromJson(json: any): DoencaGrave {
        if (json === undefined || json === null) {
            return null;
        }
        return new DoencaGrave(
            json.id,
            json.descricao,
            json.dataInicio,
            json.dataFim,
            json.outro
        );
    }
}
