import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {AquiculturaTanqueEscavado} from '../models/aquicultura-tanque-escavado.model';
import {CultivoTanqueEscavado} from '../models/cultivo-tanque-escavado.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CultivoTanqueEscavadoService extends BaseService<CultivoTanqueEscavado> {
    constructor(protected injector: Injector) {
        super(
            '/api/cultivo-tanque-escavado',
            injector,
            CultivoTanqueEscavado.prototype,
            CultivoTanqueEscavado.fromJson
        );
    }

    getAllByCultivoTanqueEscavado(aquiculturaTanqueEscavado: AquiculturaTanqueEscavado): Observable<CultivoTanqueEscavado[]> {
        const url = `${this.urlResource}/aquitetura-tanque-escavado/${aquiculturaTanqueEscavado.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    deleteCultivoTanqueEscavado(cultivoTanqueEscavadoID: number): Observable<any> {
        const url = `${this.urlResource}/${cultivoTanqueEscavadoID}`;

        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonDataToResourceList(jsonData: any[]): CultivoTanqueEscavado[] {
        const entries: CultivoTanqueEscavado[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new CultivoTanqueEscavado(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

}
