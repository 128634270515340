import {BaseModel} from "./base.model";
import {EmpreendimentoObrasViarias} from "./empreendimento-obras-viarias.model";

export class TipoPavimentacaoEmpObrasViarias extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoObrasViarias?: EmpreendimentoObrasViarias,
        public tipo?: string
    ) {
        super();
    }

    static fromJson(json: any): TipoPavimentacaoEmpObrasViarias {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoPavimentacaoEmpObrasViarias(
            json.id,
            EmpreendimentoObrasViarias.fromJson(json.empreendimentoObrasViarias),
            json.tipo,
        );
    }

    static fromJsons(json: any): TipoPavimentacaoEmpObrasViarias[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(TipoPavimentacaoEmpObrasViarias.fromJson(j)));
            return resources;
        }
    }
}