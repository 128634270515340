import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {CoreMessageService} from './core-message.service';
import {empty} from 'rxjs';


@Injectable({providedIn: 'root'})
export class AuthServerProvider implements OnDestroy {

    public tokenValue: any;  // deve ser guardado num storage local
    refreshTokenValue: any;
    authEventEmitter: EventEmitter<string>;

    constructor(
        private http: HttpClient,
        private coreMessageService: CoreMessageService,
    ) {
        this.authEventEmitter = new EventEmitter();
    }

    public getAuthEventEmitter() {
        return this.authEventEmitter;
    }

    ngOnDestroy() {
        this.authEventEmitter.unsubscribe();
    }

    storeAuthenticationToken(jwt, rememberMe) {
        //this.tokenValue = jwt;
        //console.log('storeAuthenticationToken: ', this.tokenValue);
        //if (rememberMe) {
        //    // this.$localStorage.store('authenticationToken', jwt);
        //} else {
        //    // this.$localStorage.store('authenticationToken', jwt);
        //}
    }

    logout(redirect?: boolean): Observable<any> {
        //console.log('################# doLogout');
        //return new Observable((observer) => {
        //    this.tokenValue = null;
        //    // this.$localStorage.clear('authenticationToken');
        //    this.tokenValue = null;
        //    this.authEventEmitter.emit('auth:logout');
        //    this.coreMessageService.getGlobalEventEmmiter().emit('auth:logout');
        //    console.log('logout!');
        //    // console.log('token: ', this.$localStorage.retrieve('authenticationToken'));
        //    // observer.complete();
        //    observer.complete();
        //});
        return empty();
    }

}
