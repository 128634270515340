import {Injectable, Injector} from '@angular/core';
import {BaseService} from '../base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {AtividadeCalculoParametroEntradaArrecadacao} from '../../models/arrecadacao-models/atividade-calculo-parametro-entrada-arrecadacao.model';
import {ParametroEntradaArrecadacao} from '../../models/arrecadacao-models/parametro-entrada-arrecadacao.model';

@Injectable({
    providedIn: 'root'
})
export class AtividadeParametroEntradaArrecadacaoService extends BaseService<AtividadeCalculoParametroEntradaArrecadacao> {
    private urlResourceArrecadacao = environment.URL_ARRECADACAO_API;

    constructor(protected injector: Injector) {
        super('/api/arrecadacao', injector, AtividadeCalculoParametroEntradaArrecadacao.prototype, AtividadeCalculoParametroEntradaArrecadacao.fromJson);
    }

    buscarParametrosAtividadeCalculoPorIdAtividadeCalculo(idAtividadeCalculo: number): Observable<AtividadeCalculoParametroEntradaArrecadacao> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/atividade-calculo/${idAtividadeCalculo}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    // buscarParametrosEntradaPIdAtividade(idAtividade: number): Observable<ParametroEntradaArrecadacao[]> {
    //     return this.http
    //         .get(`${this.urlResourceArrecadacao}/api/atividade-parametro-entrada/parametro-entrada/${idAtividade}`)
    //         .pipe(
    //             map(response => response),
    //             catchError(this.handleError.bind(this))
    //         );
    // }

    buscarParametrosEntradaPIdAtividade(idAtividade: number, idTitulacao): Observable<ParametroEntradaArrecadacao[]> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/api/atividade-parametro-entrada/parametro-entrada/${idAtividade}/${idTitulacao}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarParametrosEntradaPIdAtividadeAndIdTipoTitulacao(idAtividade: number, idTipoTitulacao: number): Observable<ParametroEntradaArrecadacao[]> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/api/atividade-parametro-entrada/parametro-entrada/${idAtividade}/${idTipoTitulacao}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscaAtividaeParametroCalculoPorIdAtividade(idAtividade: number): Observable<any> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/api/atividade-parametro-entrada/${idAtividade}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscaAtividadeParametroEntradaPIdAtividadeCalculo(idAtividadeCalculo: number): Observable<any> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/api/atividade-parametro-entrada/atividade-calculo/${idAtividadeCalculo}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarTipoTitulacao(): Observable<any[]> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/api/tipo-titulacao/listar-titulacao`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }
}
