import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    Injector,
    OnInit,
    QueryList,
    ViewChildren,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {Requerimento} from '../requerimento.model';
import {RequerimentoLicenciamentoFormulario} from './tipos-licenciamento/requerimento-licenciamento-formulario';
import {BaseRequerimentoLicenciamentoFormulario} from './tipos-licenciamento/base-requerimento-licenciamento.component';
import {ActivatedRoute} from '@angular/router';
import {RequerimentoService} from '../requerimento.service';

@Component({
    selector: 'app-requerimento-licenciamento',
    templateUrl: 'requerimento-licenciamento.component.html',
    styleUrls: ['requerimento-licenciamento.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoLicenciamentoComponent extends BaseRequerimentoLicenciamentoFormulario implements OnInit {

    @ViewChildren('requerimentoLicencaContainer', {read: ViewContainerRef}) requerimentoLicenciamentoContainers: QueryList<ViewContainerRef>;

    requerimentoId: any;

    constructor(injector: Injector,
                private componentFactoryResolver: ComponentFactoryResolver,
                private route: ActivatedRoute,
                private requerimentoService: RequerimentoService,
                
    ) {
        super(injector);
        this.route.params.subscribe(params => {
            this.requerimentoId = params['id'];
        });
    }

    ngOnInit(): void {
        this.requerimentoService.getById(this.requerimentoId).subscribe(async requerimento => {
            await this.loadFinalidadeContainer(requerimento, 0);
        });
    }

    async loadFinalidadeContainer(requerimento: Requerimento, index: number): Promise<void> {
        const requerimentoLicenciamentoContainers = this.requerimentoLicenciamentoContainers.find((e, i, a) => i === index);
        requerimentoLicenciamentoContainers.clear();
        // const requerimentoLicenca = await this.requerimentoLicencaService.getByRequerimentoLicencaRequerimentoId(requerimento.id).toPromise();
        const type = RequerimentoLicenciamentoFormulario.get(requerimento).resolved;
        const factory = this.componentFactoryResolver.resolveComponentFactory(type);
        const ref = requerimentoLicenciamentoContainers.createComponent(factory);
        ref.instance.requerimento = requerimento;
        ref.instance.isModoVisualizacao = this.isSomenteVisualizacao;
    }
}
