import {BaseModel} from 'app/main/shared/models/base.model';

export class HotelPousada extends BaseModel {
    constructor(
        public id?: number,
        public hotel?: boolean,
        public pousada?: boolean,
        public clubeCampo?: boolean,
        public parqueTematico?: boolean,
        public qtdeQuartos?: number,
        public capacidadeHospedes?: number,
        public grupoGeradorProprio?: boolean,
        public baciaContencaoGerador?: boolean,
        public volumeCombustivel?: number,
        public qtdeSanitarios?: number,
        public numeroPopulacaoFixa?: number,
        public numeroPopulacaoFlutuante?: number
    ) {
        super();
    }

    static fromJson(json: any): HotelPousada {
        if (json === undefined || json === null) {
            return null;
        }
        return new HotelPousada(
            json.id,
            json.hotel,
            json.pousada,
            json.clubeCampo,
            json.parqueTematico,
            json.qtdeQuartos,
            json.capacidadeHospedes,
            json.grupoGeradorProprio,
            json.baciaContencaoGerador,
            json.volumeCombustivel,
            json.qtdeSanitarios,
            json.numeroPopulacaoFixa,
            json.numeroPopulacaoFlutuante
        );
    }

}
