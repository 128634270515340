import {PocoObservacao} from './models/poco-observacao-model';
import {PocoObservacaoService} from './service/poco-observacao.service';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseModel} from '../../models/base.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {Ponto} from '../../models/hidrico-models/ponto.model';
import {SnackBarService} from '../../snack-bar/snack-bar.service';

@Component({
    selector: 'app-poco-observacao',
    templateUrl: './poco-observacao.component.html',
})
export class PocoObservacaoComponent implements OnInit {
    @Input() ponto: Ponto;
    @Input() isSomenteVisualizacao = false;
    @Input() ativarDistanciaPoco = true;
    @Output() pocosLength: EventEmitter<number> = new EventEmitter();

    form: FormGroup;
    displayedColumns: string[] = this.ativarDistanciaPoco ? ['latitude', 'longitude', 'diametro_perfuracao', 'diametro_revestimento', 'distancia', 'acoes'] :
        ['latitude', 'longitude', 'diametro_perfuracao', 'diametro_revestimento', 'acoes'];
    pocosObservacao: PocoObservacao[] = [];
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    constructor(
        private dialog: MatDialog,
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private pocosObservacaoService: PocoObservacaoService
    ) {
    }

    ngOnInit(): void {
        this.buildForm();
        this.carregarPocosObservacao();
        if (this.isSomenteVisualizacao) {
            this.displayedColumns = this.ativarDistanciaPoco ? ['latitude', 'longitude', 'diametro_perfuracao', 'diametro_revestimento', 'distancia'] :
                ['latitude', 'longitude', 'diametro_perfuracao', 'diametro_revestimento'];
        }
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            latitude: [null, [Validators.required]],
            longitude: [null, [Validators.required]],
            diametroPerfuracao: [null, [Validators.required, Validators.min(0.00001)]],
            diametroRevestimento: [null, [Validators.required, Validators.min(0.00001)]],
            distanciaPocoCaptacao: [null, this.ativarDistanciaPoco ? [Validators.required, Validators.min(0.00001)] : []]
        });
    }

    private carregarPocosObservacao(): void {
        this.pocosObservacaoService.getAllByPonto(this.ponto).subscribe(pocosObservacao => {
            this.pocosObservacao = pocosObservacao;
            this.pocosLength.emit(this.pocosObservacao.length);
        });
    }

    buttonAddPocoObservacaoClick(): void {
        if (this.containsPocoObservacao()) {
            this.snackBarService.showAlert('Poço de observação já adicionado.');
        } else {
            this.addPocoObservacao();
        }
    }

    private addPocoObservacao(): void {
        const poco = PocoObservacao.fromJson(this.form.value);
        poco.ponto = this.ponto;

        this.pocosObservacaoService.create(poco).subscribe(
            () => {
                this.snackBarService.showSuccess('Poço de observação adicionado com sucesso!');
                this.form.reset();
                this.form.markAsUntouched();
                this.carregarPocosObservacao();

            },
            (e) => this.snackBarService.showError('Erro ao adicionar poço de observação.', e)
        );
    }

    public containsPocoObservacao(): boolean {
        return this.pocosObservacao.some(pocoObservacao => {
            return pocoObservacao.latitude === this.form.get('latitude').value &&
                pocoObservacao.longitude === this.form.get('longitude').value;
        });
    }

    buttonExcluirPocoObservacaoClick(pocoObservacao: PocoObservacao): void {
        const dialogRef = this.createExcluirPocoObservacaoDialog(pocoObservacao);

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirPocoObservacao(pocoObservacao);
            }
        });
    }

    private createExcluirPocoObservacaoDialog(pocoObservacao: PocoObservacao) {
        return this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: 'Latitude: ' + pocoObservacao.latitude + ', longitude: ' + pocoObservacao.longitude}
        });
    }

    private excluirPocoObservacao(pocoObservacao: PocoObservacao): void {
        this.pocosObservacaoService.delete(pocoObservacao).subscribe(
            () => {
                this.snackBarService.showSuccess('Poço de observação excluído com sucesso!');
                this.carregarPocosObservacao();
            },
            (e) => this.snackBarService.showError('Erro ao excluir lançamento.', e)
        );
    }

}