import {BaseModel} from 'app/main/shared/models/base.model';
import { EmpreendimentoEnergiaEolica } from './empreendimento-energia-eolica.model';

export class UnidadeContingencia extends BaseModel {
    constructor(
        public id?: number,
        public identificacao?: string,
        public potenciaInstalada?: number,
        public tipoUnidadeContingencia?: number,
        public tipoCombustivel?: number,
        public subTipoCombustivel?: number,
        public descricaoTipoCombustivel?: string,
        public descricaoSubTipoCombustivel?: string,
        public empreendimentoEnergiaEolica?: number
    ) {
        super();
    }

    static fromJson(json: any): UnidadeContingencia {
        if (json === undefined || json === null) {
            return null;
        }
        return new UnidadeContingencia(
            json.id,
            json.identificacao,
            json.potenciaInstalada,
            json.tipoUnidadeContingencia,
            json.tipoCombustivel,
            json.subTipoCombustivel,
            json.descricaoTipoCombustivel,
            json.descricaoSubTipoCombustivel,
            json.empreendimentoEnergiaEolica
        );
    }

    static fromJsons(json: any): UnidadeContingencia[] {
        const UnidadeContingencias: UnidadeContingencia[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(UnidadeContingencia => UnidadeContingencias.push(this.fromJson(UnidadeContingencia)));
            return UnidadeContingencias;
        }
    }

}
