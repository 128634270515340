import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class PrevisaoVolumesProducao extends BaseModel {
    constructor(
        public id: number,
        public produto: string,
        public quantidade: number,
        public mineracao: AtividadeMineracao,
    ) {
        super();
    }

    static fromJson(json: any): PrevisaoVolumesProducao {
        if (json === undefined || json === null) {
            return null;
        }
        return new PrevisaoVolumesProducao(
            json.id,
            json.produto,
            json.quantidade,
            json.mineracao,
        );
    }

}
