import { environment } from 'environments/environment';

export class FiltroSemAcento {

    public static filtrarSemAcento(itemLabel: string, filterValue: string): boolean {
        itemLabel = itemLabel.normalize('NFD');
        itemLabel = itemLabel.replace(/[\u0300-\u036f]/g, '');
        itemLabel = itemLabel.toUpperCase();

        filterValue = filterValue.normalize('NFD');
        filterValue = filterValue.replace(/[\u0300-\u036f]/g, '');
        filterValue = filterValue.toUpperCase();
        return itemLabel.includes(filterValue);
    }
}
