import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from '../enums/base.enum';


export class Tamponamento extends BaseModel {
    constructor(
        public id?: number,
        public tipoPoco?: string,
        public profundidadePoco?: number,
        public profundidadePreenchimento?: number,
        public justificativa?: string,
    ) {
        super();
    }

    static tiposPoco: BaseEnum[] = [
        {valor: 'TUBULAR', descricao: 'Poço tubular'},
        {valor: 'POCO_OBSERVACAO', descricao: 'Poço de Observação'},
    ];

    static fromJson(json: any): Tamponamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new Tamponamento(
            json.id,
            json.tipoPoco,
            json.profundidadePoco,
            json.profundidadePreenchimento,
            json.justificativa,
        );
    }
}
