import {Injectable, Injector} from '@angular/core';
import {Ponto} from '../models/hidrico-models/ponto.model';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {RequerimentoOutorga} from '../../pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import {DadoMeteorologico} from '../models/hidrico-models/dado-meteorologico.model';
import {PontoVazaoMensalDRDH} from '../models/hidrico-models/ponto-vazao-mensal-drdh.model';

@Injectable({
    providedIn: 'root'
})
export class PontoService extends BaseService<Ponto> {

    constructor(injector: Injector) {
        super('/api/pontos', injector, Ponto.prototype, Ponto.fromJson);
    }

    /**
     * Adiciona pontos declarados em um Shapefile ao requerimento.
     * @param requerimentoOutorga Requerimento á receber o ponto.
     * @param file Shapefile a ser enviado.
     * @param idObjetivoRequerimento O ID do objetivo do requerimento caso exista.
     */
    postPontoShapeFile(requerimentoOutorga: RequerimentoOutorga, file: File, idObjetivoRequerimento: number): Observable<any> {
        let url = `${this.urlResource}/shape?idRequerimentoOutorga=${requerimentoOutorga.id}`;

        if (idObjetivoRequerimento) {
            url += `&idObjetivoRequerimento=${idObjetivoRequerimento}`;
        }
        return this.http.post(url, file).pipe(
            map(Ponto.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Adiciona um registro de ponto ao requerimento.
     * @param requerimentoOutorga Requerimento á receber o ponto.
     * @param ponto Dados do ponto a ser tratado.
     */
    postPonto(requerimentoOutorga: RequerimentoOutorga, ponto: Ponto): Observable<Ponto> {
        const url = `${this.urlResource}?idRequerimentoOutorga=${requerimentoOutorga.id}`;
        return this.http.post(url, ponto).pipe(
            map(Ponto.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Adiciona um registro de ponto ao requerimento.
     * @param requerimentoOutorga - Requerimento á receber o ponto.
     * @param pontos - Lista de pontos
     */
    postPontos(requerimentoOutorga: RequerimentoOutorga, pontos: Ponto[]): Observable<Ponto> {
        const url = `${this.urlResource}/batch?idRequerimentoOutorga=${requerimentoOutorga.id}`;
        return this.http.post(url, pontos).pipe(
            map(Ponto.fromJsons.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Lista os pontos adicionados ao requerimento.
     * @param requerimentoOutorga - Requerimento que se deseja listar os pontos,
     */
    getPontos(requerimentoOutorga: RequerimentoOutorga): Observable<Ponto[]> {
        const url = `${this.urlResource}?idRequerimentoOutorga=${requerimentoOutorga.id}`;
        return this.http.get(url).pipe(
            map(jsonArray => {
                return Ponto.fromJsons(jsonArray);
            }), catchError(this.handleError.bind(this))
        );
    }

    /**
     * Lista os pontos atualizados com titulação adicionados ao requerimento.
     * @param requerimentoOutorga - Requerimento que se deseja listar os pontos,
     */
    getPontosAtualizados(requerimentoOutorga: RequerimentoOutorga): Observable<Ponto[]> {
        const url = `${this.urlResource}/atualizados?idRequerimentoOutorga=${requerimentoOutorga.id}`;
        return this.http.get(url).pipe(
            map(jsonArray => {
                return Ponto.fromJsons(jsonArray);
            }), catchError(this.handleError.bind(this))
        );
    }

    /**
     * Deleta um registro de Ponto.
     * @param requerimentoOutorga - Requerimento outorga no qual o ponto está vinculado.
     * @param ponto - Ponto á ser excluído o registro.
     */
    deletePonto(requerimentoOutorga: RequerimentoOutorga, ponto: Ponto): Observable<any> {
        const url = `${this.urlResource}/${ponto.id}?idRequerimentoOutorga=${requerimentoOutorga.id}`;
        return this.http.delete(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getFeatureCollectionFromRequerimentoOutorga(idRequerimentoOutorga: number): Observable<any> {
        return this.http.get(`${this.urlResource}/features?idRequerimentoOutorga=${idRequerimentoOutorga}`);
    }

    getPointsFromImovelPrincipal(idRequerimentoOutorga: number): Observable<any> {
        return this.http.get(`${this.urlResource}/pontos-imovel-principal?idRequerimentoOutorga=${idRequerimentoOutorga}`);
    }

    confirmarInterseccaoDeCamadas(requerimentoId: number): Observable<string[]> {
        const url = `${environment.URL_GATEWAY}/api/pontos/${requerimentoId}/confirmarInterseccaoDeCamadas`;
        return this.http.get(url).pipe(
            map(jsonArray => jsonArray),
            catchError(this.handleError.bind(this))
        );
    }

    getPontosLegado(requerimentoId: number): Observable<Ponto[]> {
        const url = `${environment.URL_GATEWAY}/api/pontos/legado?idRequerimento=${requerimentoId}`;
        return this.http.get(url).pipe(map(jsonArray => {
            return Ponto.fromJsons(jsonArray);
        }), catchError(this.handleError.bind(this)));
    }

    update(resource: Ponto): Observable<Ponto> {
        const url = `${this.urlResource}/${resource.id}`;
        return this.http.put(url, resource).pipe(
            map(json => Ponto.fromJson(json)),
            catchError(this.handleError)
        );
    }

    updateFormData(resource: FormData, id: number): Observable<Ponto> {
        const url = `${this.urlResource}/${id}`;
        return this.http.put(url, resource).pipe(
            map(json => Ponto.fromJson(json)),
            catchError(this.handleError)
        );
    }

    getFeatureFromPonto(id: number): Observable<any> {
        return this.http.get(`${this.urlResource}/${id}/feature`);
    }
}
