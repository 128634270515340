import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {RequerimentoIsencaoDto} from '../models/requerimento-isencao-dto.model';
import {RequerimentoIsencao} from '../models/requerimento-isencao.model';

@Injectable({
    providedIn: 'root'
})
export class RequerimentoIsencaoService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_GATEWAY;

    salvaIsencaoComArquivo(file: FormData): Observable<RequerimentoIsencaoDto> {
        const url = `${this.urlResource}/api/requerimento-isencao`;
        return this.http.post(url, file)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletaIsencaoCasoExistaAlgumaSalva(idRequermineto: number): Observable<RequerimentoIsencao> {
        const url = `${this.urlResource}/api/requerimento-isencao/deleta-isencao/${idRequermineto}`;
        return this.http.delete(url)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }


    buscaIsencao(idRequermineto: number): Observable<any> {
        const url = `${this.urlResource}/api/requerimento-isencao/busca-isencao/${idRequermineto}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }


}
