import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'sortObject'
})
@Injectable()
export class ObjectSortener implements PipeTransform {
    transform(objectArray: any, key: string, direction: number): any[] {
        if (key && objectArray !== null && objectArray.length > 0) {
            objectArray.sort(
                (a: any, b: any) => {
                    const propertyA: number | string = this.getProperty(a, key);
                    const propertyB: number | string = this.getProperty(b, key);

                    if (propertyA < propertyB) {
                        return -1 * direction;
                    } else if (propertyA > propertyB) {
                        return 1 * direction;
                    } else {
                        return 0;
                    }
                }
            );
        }
        return objectArray;
    }

    sort(objectArray: any, key: string, direction: number) {
        if (objectArray != null) {
            objectArray.sort(
                (a: any, b: any) => {
                    const propertyA: number | string = this.getProperty(a, key);
                    const propertyB: number | string = this.getProperty(b, key);

                    if (propertyA < propertyB) {
                        return -1 * direction;
                    } else if (propertyA > propertyB) {
                        return 1 * direction;
                    } else {
                        return 0;
                    }
                }
            );
        }
    }


    private getProperty(value: { [key: string]: any }, key: string): number | string {
        if (value == null || typeof value !== 'object') {
            return undefined;
        }

        const keys: string[] = key.split('.');
        let result: any = value[keys.shift()];

        for (const key of keys) {
            if (result == null) { // check null or undefined
                return undefined;
            }

            result = result[key];
        }

        return result;
    }


} 
