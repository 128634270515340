import {PontoVazoesMensaisService} from './services/ponto-vazoes-mensais.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ENUM_MES_VALUES, EnumMes} from '../../enums/mes.enum';
import {MatDialog} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PontoVazaoMensal} from './models/ponto-vazao-mensal.model';
import {BaseModel} from '../../models/base.model';
import {Ponto} from '../../models/hidrico-models/ponto.model';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {SnackBarService} from '../../snack-bar/snack-bar.service';

@Component({
    selector: 'app-ponto-vazao-mensal',
    templateUrl: './ponto-vazao-mensal.component.html',
    styleUrls: ['ponto-vazao-mensal.component.scss']
})

export class PontoVazaoMensalComponent implements OnInit {

    constructor(
        private dialog: MatDialog,
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private pontoVazoesMensaisService: PontoVazoesMensaisService
    ) {
    }

    @Input() titulo: string;
    @Input() ponto: Ponto;
    @Input() isSomenteVisualizacao = false;
    @Output() lancamentosLength: EventEmitter<number> = new EventEmitter();
    mesValues = ENUM_MES_VALUES;
    formLancamento: FormGroup;
    displayedColumns: string[] = ['mes', 'vazao', 'tempo', 'periodo', 'volumeDiario', 'volumeMensal', 'acoes'];
    pontoVazoesMensais: PontoVazaoMensal[] = [];
    vazaoMensalASerEditado: PontoVazaoMensal = null;

    MESES = [
        {mes: 'JANEIRO'},
        {mes: 'FEVEREIRO'},
        {mes: 'MARCO'},
        {mes: 'ABRIL'},
        {mes: 'MAIO'},
        {mes: 'JUNHO'},
        {mes: 'JULHO'},
        {mes: 'AGOSTO'},
        {mes: 'SETEMBRO'},
        {mes: 'OUTUBRO'},
        {mes: 'NOVEMBRO'},
        {mes: 'DEZEMBRO'},
    ];
    SEGUNDOS_DIA = 3600;
    volumeAnual = 0;

    private ordenarMesesLancamento(lancamento: PontoVazaoMensal[]): PontoVazaoMensal[] {
        const allMonths = ['JANEIRO', 'FEVEREIRO', 'MARCO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO'];

        lancamento.sort((a, b) => {
            if (allMonths.indexOf(a.mes) < allMonths.indexOf(b.mes)) {
                return -1;
            }
            return 0;
        });

        return lancamento;
    }

    ngOnInit(): void {
        this.buildForm();
        this.carregarLancamentos();
        if (this.isSomenteVisualizacao) {
            this.displayedColumns = ['mes', 'vazao', 'tempo', 'periodo'];
        }
    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    public buildForm(): void {
        this.formLancamento = this.formBuilder.group({
            id: [null],
            mes: [null, Validators.required],
            vazao: [null, [Validators.required, Validators.min(0.000001)]],
            tempo: [null, [Validators.max(24), Validators.min(1)]],
            periodo: [null, [Validators.max(31), Validators.min(1)]],
            ponto: [null, Validators.required]
        });
        this.formLancamento.patchValue({ponto: this.ponto});
    }

    private carregarLancamentos(): void {
        this.pontoVazoesMensaisService
            .getAllByPonto(this.ponto)
            .subscribe(pontoVazoesMensais => {
                this.pontoVazoesMensais = pontoVazoesMensais;
                this.ordenarMesesLancamento(this.pontoVazoesMensais);
                this.lancamentosLength.emit(this.pontoVazoesMensais.length);
                this.calcularVolumeAnual(pontoVazoesMensais);
            });
    }

    getMesTexto(tipo: string): string {
        return EnumMes[tipo];
    }

    public containLancamento(): boolean {
        if (
            this.pontoVazoesMensais.some(pvm => {
                return pvm.mes === this.formLancamento.get('mes').value;
            })
        ) {
            this.snackBarService.showSuccess('Lançamento já adicionado.');
            return true;
        }
        return false;
    }

    editarVazao(vazao: PontoVazaoMensal) {
        this.vazaoMensalASerEditado = vazao;
        this.formLancamento.patchValue(vazao);
    }

    addLancamento(): void {
        if (this.vazaoMensalASerEditado == null && this.containLancamento()) {
            return;
        } else {
            if (this.formLancamento.value.mes === 'TODOS') {
                this.addLancamentoTodosMeses();
            } else {
                this.pontoVazoesMensaisService.create(this.formLancamento.value).subscribe(
                    () => {
                        this.vazaoMensalASerEditado = null;
                        this.snackBarService.showSuccess('Lançamento adicionado com sucesso!');
                        this.formLancamento.reset();
                        this.formLancamento.patchValue({ponto: this.ponto});
                        this.formLancamento.markAsUntouched();
                        this.carregarLancamentos();
                    },
                    (e) => this.snackBarService.showError('Erro ao adicionar lançamento.', e)
                );
            }
        }
    }

    private addLancamentoTodosMeses(): void {
        this.MESES.map(mes => {
            this.formLancamento.patchValue({mes: mes.mes});
            if (this.containLancamento()) {
                return;
            }
            this.pontoVazoesMensaisService.create(this.formLancamento.value).subscribe(
                () => {
                    this.snackBarService.showSuccess('Lançamento adicionado com sucesso!');
                    this.formLancamento.reset();
                    this.formLancamento.patchValue({ponto: this.ponto});
                    this.formLancamento.markAsUntouched();
                    this.carregarLancamentos();
                },
                (e) => this.snackBarService.showError('Erro ao adicionar lançamento.', e)
            );
        });
    }

    excluirLancamentoDialog(pontoVazaoMensal: PontoVazaoMensal): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: this.getMesTexto(pontoVazaoMensal.mes)}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirLancamento(pontoVazaoMensal);
            }
        });
    }

    private excluirLancamento(pontoVazaoMensal: PontoVazaoMensal): void {
        this.pontoVazoesMensaisService.delete(pontoVazaoMensal).subscribe(
            () => {
                this.snackBarService.showSuccess('Lançamento excluído com sucesso!');
                this.carregarLancamentos();
            },
            (e) => this.snackBarService.showError('Erro ao excluir lançamento.', e)
        );
    }

    volumeDiario(pontoVazaoMensal: PontoVazaoMensal): number {
        return pontoVazaoMensal.vazao * pontoVazaoMensal.tempo * this.SEGUNDOS_DIA;
    }

    volumeMensal(pontoVazaoMensal: PontoVazaoMensal): number{
        return this.volumeDiario(pontoVazaoMensal) * pontoVazaoMensal.periodo;
    }

    calcularVolumeAnual(pontoVazoesMensais: PontoVazaoMensal[]): void{
        this.volumeAnual = 0;
        pontoVazoesMensais.forEach(pvm => {
            this.volumeAnual += this.volumeMensal(pvm);
        });
    }

    cancelarEdicao() {
        this.vazaoMensalASerEditado = null;
        this.formLancamento.reset()
        this.formLancamento.patchValue({ponto: this.ponto});
    }
}
