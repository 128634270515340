import {BaseModel} from 'app/main/shared/models/base.model';
import {Pessoa} from 'app/main/shared/models/pessoa.model';
import {Requerimento} from '../../pages/requerimentos/requerimento.model';
import {SearchableModel} from "../components/select-search/searchable-model";

export class ResponsavelTecnico extends BaseModel implements SearchableModel {

    constructor(
        public id?: number,
        public tipoPessoa?: string,
        public nome?: string,
        public email?: string,
        public telefone?: string,
        public cpfCnpj?: string,
        public profissoes?: string,
        public conselho?: string,
        public cadastroTecFederal?: string,
        public registroConselho?: string,
        public pessoa?: Pessoa,
        public requerimento?: Requerimento,
        public representanteLegal?: boolean,
        public dataVinculo?: Date,
        public situacao?: string,
        public nomeRazaoSocial?: any,
    ) {
        super();
    }

    searchValue(): string {
        return this.cpfCnpj;
    }

    itemLabel(): string {
        return this.nomeRazaoSocial;
    }

    static fromJson(json: any): ResponsavelTecnico {
        if (json === undefined || json === null) {
            return null;
        }
        return new ResponsavelTecnico(
            json.id,
            json.tipoPessoa,
            json.nome,
            json.email,
            json.telefone,
            json.cpfCnpj,
            json.profissoes,
            json.conselho,
            json.cadastroTecFederal,
            json.registroConselho,
            Pessoa.fromJson(json.pessoa),
            Requerimento.fromJson(json.requerimento),
            json.representanteLegal,
            json.dataVinculo,
            json.situacao,
            json.nomeRazaoSocial
        );
    }

    static fromJsons(json: any): ResponsavelTecnico[] {
        const lista: ResponsavelTecnico[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((rt) => {
                lista.push(this.fromJson(rt));
            });
            return lista;
        }
    }

    _toString(): string {
        return (
            (this.nome != null ? this.nome : this.id) +
            (this.cpfCnpj ? ' - ' + this.cpfCnpj : '')
        );
    }
}
