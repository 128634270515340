import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {GeracaoEnergia} from "../models/geracao-energia.model";
import {Turbina} from "../models/turbina.model";

@Injectable({
    providedIn: 'root'
})
export class TurbinaService {
    private urlResource = `${environment.URL_GATEWAY}/api/turbinas`;

    constructor(private http: HttpClient) {}

    getAllByGeracaoEnergia(geracaoEnergia: GeracaoEnergia): Observable<Turbina[]> {
        const url = `${this.urlResource}/geracao-energia/${geracaoEnergia.id}`;
        return this.http.get(url).pipe(
            map(this.jsonToResources),
            catchError(this.handleError)
        );
    }

    create(turbina: Turbina): Observable<Turbina> {
        return this.http
            .post(this.urlResource, turbina)
            .pipe(
                map(Turbina.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    deleteAllByGeracaoEnergia(geracaoEnergiaId: number): Observable<void> {
        const url = `${this.urlResource}/geracao-energia/${geracaoEnergiaId}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    delete(turbina: Turbina): Observable<any> {
        const url = `${this.urlResource}/${turbina.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonToResources(json: any): Turbina[] {
        const resources = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: any) => resources.push(Turbina.fromJson(e)));
        }
        return resources;
    }

    private handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
