import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {Ponto} from 'app/main/shared/models/hidrico-models/ponto.model';
import {TipoCorpoHidrico} from 'app/main/shared/models/tipo-corpo-hidrico.model';
import {TipoCorpoHidricoService} from 'app/main/shared/services/tipo-corpo-hidrico.service';
import {TipoLancamento} from 'app/main/pages/tipos-lancamento/tipo-lancamento.model';
import {TipoLancamentoService} from 'app/main/pages/tipos-lancamento/tipo-lancamento.service';
import {PontoService} from 'app/main/shared/services/ponto.service';
import {BaseModel} from 'app/main/shared/models/base.model';
import {LancamentoEfluente} from './models/lancamento-efluente.model';
import {BasePontoComponent} from '../base-ponto.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {isNullOrUndefined} from "util";
import {FormUtil} from "../../../../../shared/util/form-util";
import {PontoParametroDiluicao} from "../../../../../shared/models/hidrico-models/ponto-parametro-diluicao.model";
import {PontoParametroDiluicaoService} from "../../../../../shared/services/ponto-parametro-diluicao.service";
import {ParametroDiluicaoService} from "../../../../../shared/services/parametro-diluicao.service";
import {ParametroDiluicao} from "../../../../../shared/models/hidrico-models/parametro-diluicao.model";
import {ConfirmarExclusaoDialogComponent} from "../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DecimalPipe} from "@angular/common";

@Component({
    selector: 'app-objetivo-lancamentos-efluentes',
    templateUrl: './objetivo-lancamentos-efluentes.component.html',
    styleUrls: ['objetivo-lancamentos-efluentes.component.scss'],
    animations: fuseAnimations
})
export class ObjetivoLancamentosEfluentesComponent extends BasePontoComponent implements OnInit {

    formLancamentosEfluente: FormGroup;
    formParametroDiluicao: FormGroup;
    tipoCorposHidricos: TipoCorpoHidrico[] = [];
    tiposLancamento: TipoLancamento[] = [];

    lancamentosLength: number  = null;
    condicaoFormularioConcluido = (ponto?: Ponto): boolean => this.lancamentosLength > 0 &&
        !isNullOrUndefined((ponto ? ponto : this.ponto).lancamentoEfluente) &&
            this.parametrosObrigatorios.every(param => this.pontoParametrosDiluicao.some(pontoParam => pontoParam.idParametro == param.parametroId))
            && this.parametroPreponderanteValido == true && this.parametrosObrigatorios.length == 0;

    percentageErrorMessages = FormUtil.percentageErrorMessages

    parametros: ParametroDiluicao[] = [];
    parametrosObrigatorios: ParametroDiluicao[] = [];
    parametrosFiltrados: ParametroDiluicao[] = [];
    pontoParametrosDiluicao: PontoParametroDiluicao[] = [];
    parametroPreponderanteValido: boolean = null;
    parametroPreponderanteTabela: PontoParametroDiluicao;

    constructor(
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private tipoCorpoHidricoService: TipoCorpoHidricoService,
        private tipoLancamentoService: TipoLancamentoService,
        private pontoService: PontoService,
        private pontoParametroDiluicaoService: PontoParametroDiluicaoService,
        private finalidadeParametroDiluicaoService: ParametroDiluicaoService,
        private matDialog: MatDialog,
        private decimalPipe: DecimalPipe
    ) {
        super();
    }

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;
    displayColumnsParametros: any[] = [];
    displayDataParametros: any[] = [];

    ngOnInit(): void {

        this.tipoCorpoHidricoService.getAll().subscribe(corposHidricos => {
            this.tipoCorposHidricos = corposHidricos;
        });

        this.tipoLancamentoService.getAll().subscribe(lancamentos => {
            this.tiposLancamento = lancamentos;
        });
        this.buildResourcesForm();
        if (this.isModoVisualizacao) {
            this.formLancamentosEfluente.disable();
        }

        this.listarParametrosDiluicao();

        this.finalidadeParametroDiluicaoService.findAllByFinalidade(this.finalidades, this.ponto.idMicrobacia).subscribe(result => {
            this.parametros = result;
            this.parametrosObrigatorios = this.parametros.filter(param => param.obrigatorio && !this.parametros.some(p => p.parametroId == param.parametroId))
        }, e => this.snackBarService.showError('Erro ao consultar configuração de parametros', e));
    }

    buildResourcesForm(): void {
        this.formLancamentosEfluente = this.formBuilder.group({
            id: [null],
            lancamentoEfluente: this.formBuilder.group({
                id: [null],
                tipoTratamento: [null, Validators.required],
                eficienciaProjeto: [null, FormUtil.validatorsSetPercentage],
                eficienciaReal: [null, FormUtil.validatorsSetPercentage],
                tipoLancamento: [null, Validators.required],
            }),
            tipoCorpoHidrico: [null, Validators.required],
            outroTipoCorpoHidrico: [{value: null, disabled: true}],
            corpoHidrico: [null]
        });
        this.formLancamentosEfluente.patchValue({
            id: this.ponto.id,
            tipoCorpoHidrico: this.ponto.tipoCorpoHidrico,
            outroTipoCorpoHidrico: this.ponto.outroTipoCorpoHidrico,
            corpoHidrico: this.ponto.corpoHidrico
        });

        this.formParametroDiluicao = this.formBuilder.group({
            finalidade: [null, Validators.required],
            parametro: [null, Validators.required],
            concentracao: [null, Validators.required],
            concentracaoBruta: [null, Validators.required],
            concentracaoNatural: [null, Validators.required],
            concentracaoLitro: [null, Validators.required]
        });

        this.formParametroDiluicao.get('parametro').valueChanges.pipe().subscribe(parametro => {
            if (!isNullOrUndefined(parametro)) {
                this.formParametroDiluicao.patchValue({
                    concentracaoBruta: parametro.concentracaoBruta,
                    concentracaoNatural: parametro.concentracaoNatural,
                    concentracaoLitro: parametro.concentracaoLitro
                });
            }
        });

        this.formParametroDiluicao.get('finalidade').valueChanges.pipe().subscribe(async finalidade => {
            this.formParametroDiluicao.get('parametro').reset();
            this.formParametroDiluicao.get('concentracao').reset();
            this.formParametroDiluicao.get('concentracaoBruta').reset();
            this.parametrosFiltrados = this.parametros.filter(parametro => parametro.finalidade.id)

            if (this.parametrosFiltrados.length == 0) {
                this.snackBarService.showAlert('Não há parâmetros configurados para a finalidade de ' + finalidade.descricao)
            } else if (this.parametrosFiltrados.length == 1) {
                this.formParametroDiluicao.patchValue({parametro: this.parametrosFiltrados[0]})
            }
        })

        if (this.ponto.lancamentoEfluente) {
            this.formLancamentosEfluente.controls.lancamentoEfluente.patchValue(this.ponto.lancamentoEfluente);
        }

    }

    salvarPonto(): void {
        this.formLancamentosEfluente.markAllAsTouched();
        if (!this.formLancamentosEfluente.invalid) {
            const dtoPonto = Ponto.fromJson(this.ponto);
            dtoPonto.tipoCorpoHidrico = this.formLancamentosEfluente.controls.tipoCorpoHidrico.value;
            dtoPonto.outroTipoCorpoHidrico = this.formLancamentosEfluente.controls.outroTipoCorpoHidrico.value;
            dtoPonto.corpoHidrico = this.formLancamentosEfluente.controls.corpoHidrico.value;
            dtoPonto.lancamentoEfluente = LancamentoEfluente.fromJson(this.formLancamentosEfluente.controls.lancamentoEfluente.value);
            dtoPonto.formularioConcluido = this.condicaoFormularioConcluido(dtoPonto);
            this.pontoService.update(dtoPonto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.formLancamentosEfluente.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    onTipoCorpoHidricoChanged(event): void {
        if (event) {
            const field = this.formLancamentosEfluente.controls.outroTipoCorpoHidrico;
            if (TipoCorpoHidrico.fromJson(this.formLancamentosEfluente.controls.tipoCorpoHidrico.value).outro) {
                field.enable();
                field.setValidators([Validators.required,
                    Validators.maxLength(255)]);
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue(null);
            }
        }
    }

    onChangeLancamentos(length: number) {
        if (!isNullOrUndefined(this.lancamentosLength)) {
            this.listarParametrosDiluicao();
        }
        this.lancamentosLength = length

        this.atualizarFormularioConcluido();
    }

    adicionarParametroDiluicao() {
        this.formParametroDiluicao.markAllAsTouched();
        if (this.pontoParametrosDiluicao.length >= 5) {
            this.snackBarService.showAlert('Deverá informar no máximo 5 parâmetros')
            return;
        }
        if (this.formParametroDiluicao.valid) {
            const pontoParametro = PontoParametroDiluicao.fromJson(this.formParametroDiluicao.getRawValue());
            if (this.pontoParametrosDiluicao.some(p => p.parametro.parametroId == pontoParametro.parametro.parametroId &&
                p.finalidade.id == pontoParametro.finalidade.id)) {
                this.snackBarService.showAlert('Parâmetro já adicionado para esta finalidade')
                return;
            }
            pontoParametro.ponto = this.ponto;
            this.pontoParametroDiluicaoService.create(pontoParametro).subscribe(() => {
                this.formParametroDiluicao.get('parametro').reset();
                this.formParametroDiluicao.get('concentracao').reset();
                this.formParametroDiluicao.get('concentracaoBruta').reset();
                this.snackBarService.showSuccess('Sucesso ao adicionar parâmetro de diluição')
                this.listarParametrosDiluicao();
            }, e => this.snackBarService.showError('Erro ao adicionar parâmetro de diluição', e));
        }
    }

    private listarParametrosDiluicao() {
        this.pontoParametroDiluicaoService.findAllByPonto(this.ponto).subscribe(result => {
                this.pontoParametrosDiluicao = result;
                this.prepararTabelaParametros();

                let parametroPreponderante: PontoParametroDiluicao = null;
                this.pontoParametrosDiluicao.forEach(pontoParametro => {
                    if (parametroPreponderante == null || Object.values(pontoParametro.vazao).some(p1 => Object.values(parametroPreponderante.vazao).every(pLocal => p1 > pLocal))) {
                        parametroPreponderante = pontoParametro;
                    }
                })
                this.parametroPreponderanteTabela = parametroPreponderante;
                this.parametroPreponderanteValido = this.ponto.parametro.id == parametroPreponderante.parametro.parametroId

                this.parametrosObrigatorios = this.parametros.filter(param => param.obrigatorio && !this.pontoParametrosDiluicao.some(p => p.parametro.parametroId == param.parametroId))

                this.atualizarFormularioConcluido();
            },
            e => this.snackBarService.showError('Erro ao consultar parâmetros de diluição do ponto', e))
    }

    private prepararTabelaParametros() {
        const configPipe = '1.0-6';

        this.displayColumnsParametros = ['0'].concat(this.pontoParametrosDiluicao.map((p, index) => (index + 1).toString()))
        this.displayDataParametros = [];
        this.displayDataParametros.push(['Finalidade'].concat(this.pontoParametrosDiluicao.map((p) => p.finalidade.descricao)));
        this.displayDataParametros.push(['Parâmetro'].concat(this.pontoParametrosDiluicao.map((p) => p.parametro.nome)));
        this.displayDataParametros.push(['Concentração'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.concentracao, configPipe))));
        this.displayDataParametros.push(['Concentração bruta'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.concentracaoBruta, configPipe))));
        this.displayDataParametros.push(['Mes / Vazão'].concat(this.pontoParametrosDiluicao.map(() => "Vazão de diluição (m³/s)")));
        this.displayDataParametros.push(['Janeiro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.janeiro, configPipe))));
        this.displayDataParametros.push(['Fevereiro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.fevereiro, configPipe))));
        this.displayDataParametros.push(['Março'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.marco, configPipe))));
        this.displayDataParametros.push(['Abril'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.abril, configPipe))));
        this.displayDataParametros.push(['Maio'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.maio, configPipe))));
        this.displayDataParametros.push(['Junho'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.junho, configPipe))));
        this.displayDataParametros.push(['Julho'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.julho, configPipe))));
        this.displayDataParametros.push(['Agosto'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.agosto, configPipe))));
        this.displayDataParametros.push(['Setembro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.setembro, configPipe))));
        this.displayDataParametros.push(['Outubro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.outubro, configPipe))));
        this.displayDataParametros.push(['Novembro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.novembro, configPipe))));
        this.displayDataParametros.push(['Dezembro'].concat(this.pontoParametrosDiluicao.map((p) => this.decimalPipe.transform(p.vazao.dezembro, configPipe))));
        if (!this.isModoVisualizacao)
            this.displayDataParametros.push([''].concat(this.pontoParametrosDiluicao.map((p, index) => index.toString())));

    }

    excluirParametroPonto(index: number) {
        const pontoParametro = this.pontoParametrosDiluicao[index];

        this.matDialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {
                label: pontoParametro.parametro.nome
            }
        }).afterClosed().subscribe(result => {
            if (!!result) {
                this.pontoParametroDiluicaoService.delete(pontoParametro.id).subscribe(() => {
                    this.snackBarService.showSuccess('Registro removido com sucesso');
                    this.listarParametrosDiluicao();
                }, e => this.snackBarService.showError('Erro ao remover registro', e));
            }
        });
    }

    private atualizarFormularioConcluido() {
        if (this.parametroPreponderanteValido != null && this.lancamentosLength != null && this.ponto.formularioConcluido != this.condicaoFormularioConcluido()) {
            this.ponto.formularioConcluido = this.condicaoFormularioConcluido()
            this.pontoService.update(this.ponto).subscribe(result => Object.assign(this.ponto, result))
        }
    }
}
