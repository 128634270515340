import {Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {AbaDocumentoProcessoDTO} from "./aba-documento-processo-dto.model";
import {Observable, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TermoAdesaoECompromissoService {

    protected http: HttpClient;
    urlResource = `${environment.URL_GATEWAY}/api/termos-adesao-e-compromisso`;

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    regerarPdfLac(idRequerimento: number) {
        const url = `${this.urlResource}/regerar-pdf-licenca-lac/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
