import {BaseModel} from './base.model';
import {Pessoa} from './pessoa.model';
import {DocumentoTemplate} from 'app/main/shared/models/documento-template.model';

export class Documento extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public pessoa?: Pessoa,
        public template?: DocumentoTemplate,
        public chave?: string
    ) {
        super();
    }

    static fromJson(json: any): Documento {
        if (json === undefined || json === null) {
            return null;
        }
        return new Documento(
            json.id,
            json.nome,
            Pessoa.fromJson(json.pessoa),
            DocumentoTemplate.fromJson(json.template),
            json.chave
        );
    }

    static fromJsons(json: any): Documento[] {
        const array: Documento[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(Documento.fromJson(item)));
            return array;
        }
    }
}
