import {
    ChangeDetectionStrategy,
    Component,
    Injector,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {takeUntil} from 'rxjs/operators';
import {ObjetivoRequerimentoOutorgaService} from '../../../../shared/services/objetivo-requerimento-outorga.service';
import {ObjetivoRequerimentoOutorga} from '../../../../shared/models/objetivo-requerimento-outorga.model';
import {RequerimentoFinalidadeAtividadeService} from 'app/main/shared/services/requerimento-finalidade-atividade.service';
import {LancamentoTaxa} from '../../../../shared/models/lancamento-taxa.model';
import {LancamentoTaxaService} from '../../../../shared/services/lancamento-taxa.service';
import {ComponentPortal} from '@angular/cdk/portal';
import {CdkOverlayOrigin, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {ErrosAbaRequerimento} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {OverlayInfoCaptacaoComponent} from '../../shared/overlay-info-captacao/overlay-info-captacao.component';
import {RequerimentoOutorgaFinalidadeAtividadeDTO} from '../../../../shared/models/requerimento-finalidade-atividade-dto.model';

@Component({
    selector: 'app-requerimento-taxa-outorga',
    templateUrl: 'requerimento-taxa-outorga.component.html',
    styleUrls: ['requerimento-taxa-outorga.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class RequerimentoTaxaOutorgaComponent extends BaseTabRequerimentoComponent
    implements OnInit {
    form: FormGroup;
    displayedColumns: string[] = ['finalidade', 'atividade', 'ppd'];
    lancamentosTaxa: Array<LancamentoTaxa> = [];
    lancamentosTaxasRequerente: Array<LancamentoTaxa> = [];
    objetivosRequerimentoOutorga: Array<ObjetivoRequerimentoOutorga> = [];
    finalidadeAtividadesRequerimento: Array<RequerimentoOutorgaFinalidadeAtividadeDTO> = [];
    valorUpfTotal = 0;
    objetoReqReaproveitamentoTaxa: any = [];
    objetoReqIsencao: any = [];

    overlayInfoCaptacao: OverlayRef;
    @ViewChild(CdkOverlayOrigin, {static: false}) _overlayOrigin: CdkOverlayOrigin;

    constructor(
        private injector: Injector,
        private objetivoRequerimentoOutorgaService: ObjetivoRequerimentoOutorgaService,
        private requerimentoFinalidadeAtividadeService: RequerimentoFinalidadeAtividadeService,
        private lancamentoTaxaService: LancamentoTaxaService,
        private overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {
        super(injector);
        RequerimentoSteps.TAXA.component = this;
    }

    ngOnInit(): void {
        this.helper
            .onCurrentStepChange()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((e) => {
                if (e.newTabIndex === RequerimentoSteps.TAXA.index) {
                    this.carregarLancamentosTaxa();
                    this.carregarObjetivoRequerimentoOutorga();
                    this.carregarFinalidadeAtividadesRequerimento();
                    this.listarLancamentosTaxasRequerente();
                }
            });
    }

    async listarLancamentosTaxasRequerente(): Promise<void> {
        await this.lancamentoTaxaService.getByLacamentoTaxaStatus(status)
            .subscribe(lancamentoTaxa => {
                this.lancamentosTaxasRequerente = lancamentoTaxa.lancamentosTaxas;
            });
    }

    private carregarLancamentosTaxa(): void {
        this.lancamentoTaxaService
            .getByRequerimentoId(this.requerimento.id)
            .subscribe(lancamentosTaxa => {
                this.lancamentosTaxa = lancamentosTaxa;
                this.lancamentosTaxa.forEach(lancamentoTaxa => {
                    this.valorUpfTotal = this.valorUpfTotal + lancamentoTaxa.qtdeTotalUPF;
                });
            });
    }

    private carregarFinalidadeAtividadesRequerimento(): void {
        this.requerimentoFinalidadeAtividadeService
            .getOutorgaDTOByRequerimento(this.requerimento)
            .subscribe((atividades) => {
                this.finalidadeAtividadesRequerimento = atividades;
            });
    }

    private carregarObjetivoRequerimentoOutorga(): void {
        this.objetivoRequerimentoOutorgaService
            .getAllByRequerimentoOutorga(this.requerimentoOutorga)
            .subscribe((objetivosRequerimentoOutorga) => {
                this.objetivosRequerimentoOutorga = objetivosRequerimentoOutorga;
            });
    }

    async validarAba(erros: ErrosAbaRequerimento): Promise<void> {
        if(erros.requerimentoStep.component.requerimento.pessoa) {
            // valida requerimento - isencao
            if (this.objetoReqIsencao.isencao !== 0 && this.objetoReqIsencao.arquivo === false
                || this.objetoReqIsencao.isencao === undefined && this.objetoReqIsencao.arquivo === false) {
                erros.push('Verificar criterios de isenção  !', false);
            }
            // valida requerimento - reaproveitamento
            else if (this.objetoReqReaproveitamentoTaxa.length === 0 && !this.isRequerimentoAguardandoRevisaoOuEmRevisao) {
                erros.push('Escolha se deseja ou não utilizar créditos !', false);
            } else if (this.objetoReqReaproveitamentoTaxa.decisaoReaproveitamento === 'S'
                && this.objetoReqReaproveitamentoTaxa.qtdCredito === 0) {
                erros.push('Utilizar quantidade de upf corretamente !', false);
            }
        } 
        return await Promise.resolve();
    }

    openOverlayInfo(): void {
        const strategy = this.overlay.position().connectedTo(
            this._overlayOrigin.elementRef,
            {originX: 'start', originY: 'top'},
            {overlayX: 'start', overlayY: 'bottom'});
        this.overlayInfoCaptacao = this.overlay.create({positionStrategy: strategy});
        this.overlayInfoCaptacao.attach(new ComponentPortal(OverlayInfoCaptacaoComponent, this.viewContainerRef));
    }

    closeOverlayInfo(): void {
        this.overlayInfoCaptacao.dispose();
    }

    recebeEmitterReaproveitamento(event: any): void {
        /// recebe o ultimo objeto do array Reaproveitamento
        this.objetoReqReaproveitamentoTaxa = event[event.length - 1];
    }

    recebeEmitterReqIsencao(event: any): void {
        /// recebe o ultimo objeto do array Isencao
        this.objetoReqIsencao = event[event.length - 1];
    }
}

