import {PostoFluviometrico} from './posto-fluviometrico.model';
import {ObraBarragem} from '../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-obra-barragem/obra-barragem.model';

export class ObraPostoFluviometrico {

    constructor(
        public obraBarragem?: ObraBarragem,
        public postoFluviometrico?: PostoFluviometrico,
        public areaDrenagem?: number,
        public areaDrenagemBarramento?: number,
        public precipitacaoMediaAnual?: number,
        public evaporacaoMediaAnual?: number,
        public evaporacaoMediaMensal?: number,
        public periodoVazaoMltInicio?: number,
        public periodoVazaoMltFim?: number,
        public vazaoMediaLongoTermo?: number,
        public vazaoMaximaRegistrada?: number,
        public vazaoMinimaRegistrada?: number,
        public vazaoFirme?: number,
        public vazaoMinMedMensal?: number,
        public vazaoProjeto?: number,
        public tempoRecorrenciaVazao?: number,
        public vazaoObrasDesvio?: number,
        public tempoRecorrenciaVazaoObras?: number
    ) {
    }

    static fromJson(json: any): ObraPostoFluviometrico {
        if (json === undefined || json === null) {
            return null;
        }

        return new ObraPostoFluviometrico(
            json.obraBarragem ? json.obraBarragem : null,
            json.postoFluviometrico ? json.postoFluviometrico : null,
            json.areaDrenagem ? json.areaDrenagem : null,
            json.areaDrenagemBarramento ? json.areaDrenagemBarramento : null,
            json.precipitacaoMediaAnual ? json.precipitacaoMediaAnual : null,
            json.evaporacaoMediaAnual ? json.evaporacaoMediaAnual : null,
            json.evaporacaoMediaMensal ? json.evaporacaoMediaMensal : null,
            json.periodoVazaoMltInicio ? json.periodoVazaoMltInicio : null,
            json.periodoVazaoMltFim ? json.periodoVazaoMltFim : null,
            json.vazaoMediaLongoTermo ? json.vazaoMediaLongoTermo : null,
            json.vazaoMaximaRegistrada ? json.vazaoMaximaRegistrada : null,
            json.vazaoMinimaRegistrada ? json.vazaoMinimaRegistrada : null,
            json.vazaoFirme ? json.vazaoFirme : null,
            json.vazaoMinMedMensal ? json.vazaoMinMedMensal : null,
            json.vazaoProjeto ? json.vazaoProjeto : null,
            json.tempoRecorrenciaVazao ? json.tempoRecorrenciaVazao : null,
            json.vazaoObrasDesvio ? json.vazaoObrasDesvio : null,
            json.tempoRecorrenciaVazaoObras ? json.tempoRecorrenciaVazaoObras : null
        );
    }

    static fromJsons(json: any): ObraPostoFluviometrico[] {
        const array: ObraPostoFluviometrico[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(ObraPostoFluviometrico.fromJson(item)));
            return array;
        }
    }
}
