import {Injectable, Injector} from '@angular/core';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {CombustivelUtilizado} from '../models/combustivel-utilizado.model';
import {BaseMineracao} from "./base-mineracao.service";


@Injectable()
export class CombustivelUtilizadoResolver extends DetailResolver<CombustivelUtilizado> {
    constructor(
        service: CombustivelUtilizadoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class CombustivelUtilizadoService extends BaseMineracao<CombustivelUtilizado> {

    constructor(protected injector: Injector) {
        super(
            "/api/combustivel-utilizado",
            injector,
            CombustivelUtilizado.prototype,
            CombustivelUtilizado.fromJson
        );
    }

    getByMineracao(idMineracao: number): Observable<Array<CombustivelUtilizado>> {
        const url = `${this.urlResource}/mineracao/${idMineracao}`;

        return this.http
            .get(url)
            .pipe(
                map(e => this.jsonToResources(e)),
                catchError(this.handleError.bind(this))
            );
    }
}
