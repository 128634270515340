import {ObjetivoRequerimentoOutorga} from '../models/objetivo-requerimento-outorga.model';
import {RequerimentoOutorga} from '../../pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {ObjetivoRequerimentoOutorgaDTO} from '../models/objetivo-requerimento-outorga-dto.model';

@Injectable({
    providedIn: 'root'
})
export class ObjetivoRequerimentoOutorgaService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/objetivos-requerimento-outorga';
    }

    getAllByRequerimentoOutorga(requerimentoOutorga: RequerimentoOutorga): Observable<ObjetivoRequerimentoOutorga[]> {
        const url = `${this.urlResource}/requerimento-outorga/${requerimentoOutorga.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    getDTOByRequerimentoOutorga(requerimentoOutorga: RequerimentoOutorga): Observable<ObjetivoRequerimentoOutorgaDTO[]> {
        const url = `${this.urlResource}/requerimento-outorga/${requerimentoOutorga.id}/dto`;
        return this.http.get(url).pipe(
            map(resources => ObjetivoRequerimentoOutorgaDTO.fromJsons(resources)),
            catchError(this.handleError)
        );
    }

    create(objetivoRequerimentoOutorga: FormData): Observable<ObjetivoRequerimentoOutorga> {
        return this.http
            .post(this.urlResource, objetivoRequerimentoOutorga)
            .pipe(
                map(this.jsonDataToResource),
                catchError(this.handleError.bind(this))
            );
    }

    update(objetivoRequerimentoOutorga: ObjetivoRequerimentoOutorga): Observable<ObjetivoRequerimentoOutorga> {
        const url = `${this.urlResource}/${objetivoRequerimentoOutorga.id}`;
        return this.http.put(url, objetivoRequerimentoOutorga).pipe(
            map(() => objetivoRequerimentoOutorga),
            catchError(this.handleError)
        );
    }

    delete(objetivoRequerimentoOutorga: ObjetivoRequerimentoOutorga): Observable<any> {
        const url = `${this.urlResource}/${objetivoRequerimentoOutorga.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    deleteById(id: number): Observable<any> {
        const url = `${this.urlResource}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonDataToResourceList(jsonData: any[]): ObjetivoRequerimentoOutorga[] {
        const entries: ObjetivoRequerimentoOutorga[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new ObjetivoRequerimentoOutorga(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

    private jsonDataToResource(jsonData: any): ObjetivoRequerimentoOutorga {
        return Object.assign(new ObjetivoRequerimentoOutorga(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
