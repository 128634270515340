export class AbaDocumentoProcessoDTO {
    constructor(
        public id?: number,
        public modeloDocumento?: string,
        public dataCriacao?: string,
        public tipoTitulacaoDocumentoDTO?: string,
        public setorResponsavel?: string,
        public numeroEmissao?: string,
        public tipoEmissao?: string,
        public validadeTitulacao?: string,
        public temDocumento?: boolean,
        public grupoDocumento?: string,
        public chave?: string,
        public idRequerimento?: number,
        public idEmissaoTitulo?: number
    ) {
    }

    static fromJson(json: any): AbaDocumentoProcessoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new AbaDocumentoProcessoDTO(
            json.id ? json.id : null,
            json.modeloDocumento ? json.modeloDocumento : null,
            json.dataCriacao ? json.dataCriacao : null,
            json.tipoTitulacaoDocumentoDTO ? json.tipoTitulacaoDocumentoDTO : null,
            json.setorResponsavel ? json.setorResponsavel : null,
            json.numeroEmissao ? json.numeroEmissao : null,
            json.tipoEmissao ? json.tipoEmissao : null,
            json.validadeTitulacao ? json.validadeTitulacao : null,
            json.temDocumento ? json.temDocumento : null,
            json.grupoDocumento? json.grupoDocumento : null,
            json.chave? json.chave : null,
            json.idRequerimento ? json.idRequerimento : null,
            json.idEmissaoTitulo ? json.idEmissaoTitulo : null,
        );
    }

    static fromJsons(json: any): AbaDocumentoProcessoDTO[] {
        const AbaDocumentoProcessoDTO: AbaDocumentoProcessoDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => AbaDocumentoProcessoDTO.push(this.fromJson(item)));
            return AbaDocumentoProcessoDTO;
        }
    }
}
