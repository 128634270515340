import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DominioDto } from 'app/main/shared/models/dominio-dto.model';
import { DominiosService } from 'app/main/shared/services/dominios.service';
import {CaracteristicaEletricaSistema} from "../../../../../../../../shared/models/caracteristica-eletrica-sistema.model";

@Component({
  selector: 'app-atv-ee-lin-trans-caracteristicas-eletric-sist',
  templateUrl: './atv-ee-lin-trans-caracteristicas-eletric-sist.component.html',
  styleUrls: ['./atv-ee-lin-trans-caracteristicas-eletric-sist.component.scss']
})
export class AtvEeLinTransCaracteristicasEletricSistComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isSomenteVisualizacao = false;
  @Input() isNaoPodeEditar: boolean;

  tiposTensaoNominalSistema: DominioDto[] = [];
  tiposPotenciaCurtoCircuitoTipico: DominioDto[] = [];
  tiposTensaoMaximaOperacao: DominioDto[] = [];
  tiposTensaoSuportavelImpulsoAtmosferico: DominioDto[] = [];
  tiposTensaoSuportavelFrequenciaIndustrial: DominioDto[] = [];

  constructor(private dominiosService: DominiosService) {
  }

  ngOnInit() {
      this.dominiosService.getDominioByUrl('tipo-tensao-nom-sis-ee-lin-trans')
          .subscribe(doms => this.tiposTensaoNominalSistema = doms);
      this.dominiosService.getDominioByUrl('tipo-potencia-curto-circ-ee-lin-trans')
          .subscribe(doms => this.tiposPotenciaCurtoCircuitoTipico = doms);
      this.dominiosService.getDominioByUrl('tipo-tensao-max-opera-ee-lin-trans')
          .subscribe(doms => this.tiposTensaoMaximaOperacao = doms);
      this.dominiosService.getDominioByUrl('tipo-tensao-impul-atm-ind-ee-lin-trans')
          .subscribe(doms => this.tiposTensaoSuportavelImpulsoAtmosferico = doms);
      this.dominiosService.getDominioByUrl('tipo-tensao-supo-freq-ind-ee-lin-trans')
          .subscribe(doms => this.tiposTensaoSuportavelFrequenciaIndustrial = doms);
  }

  ngOnChanges(): void {
    if (this.isNaoPodeEditar) {
        this.form.disable();
    }
}

}
