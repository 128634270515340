import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { RequerimentoService } from 'app/main/pages/requerimentos/requerimento.service';
import { BaseTabRequerimentoComponent } from 'app/main/pages/requerimentos/shared/base-requerimento.component';
import { ErrosAbaRequerimento } from 'app/main/pages/requerimentos/shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { RequerimentoSteps } from 'app/main/pages/requerimentos/shared/requerimento.steps';
import { DocumentoProcessoSignatarioService } from 'app/main/pages/tarefas/shared/service/documento-processo-signatario.service';
import { DocumentoProcessoSignatario } from 'app/main/shared/models/documento-processo-signatario.model';
import { takeUntil } from 'rxjs/operators';
import { RequerimentoLicenciamentoService } from '../../../requerimento-licenciamento.service';
import { AssinanteRequerimentoDto } from 'app/main/pages/requerimentos/assinante-requerimento.dto';
import { DocumentoProcessoService } from 'app/main/pages/gerar-documento-publicacao/documento-processo.service';
import { PdfViewerDialog } from 'app/main/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';

@Component({
  selector: 'app-aba-termo-responsabilidade-lic-emp',
  templateUrl: './aba-termo-responsabilidade-lic-emp.component.html',
  styleUrls: ['./aba-termo-responsabilidade-lic-emp.component.scss']
})
export class AbaTermoResponsabilidadeLicEmpComponent extends BaseTabRequerimentoComponent implements OnInit {

    checkControl: FormControl;
    display: string[] = ['assinante', 'versao', 'dataAssinatura'];
    documentosSignatario: DocumentoProcessoSignatario[] = [];
    assinantes: AssinanteRequerimentoDto[] = [];

    constructor(
        protected service: RequerimentoService,
        private formBuilder: FormBuilder,
        private documentoProcessoSignatarioService: DocumentoProcessoSignatarioService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private documentoProcessoService: DocumentoProcessoService,
        private pdfViewerDialog: PdfViewerDialog,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.TERMO.component = this;
        
    }

    ngOnInit(): void {

        console.log('AbaTermoResponsabilidadeComponent.situacaoProcesso', this.requerimento.situacaoProcesso)
        console.log('AbaTermoResponsabilidadeComponent.concordouTermosResponsabilidade', this.requerimento.concordouTermosResponsabilidade)
        console.log('AbaTermoResponsabilidadeComponent.currentAction', this.currentAction)

        console.log('AbaTermoResponsabilidadeComponent.isSomenteVisualizacao:', this.currentAction === 'detail' || (this.requerimento.situacaoProcesso !== 'EM_CADASTRO'
        && this.requerimento.situacaoProcesso !== 'CHECKLIST_REPROVADO'
        && this.requerimento.situacaoProcesso !== 'EM_CORRECAO_CADASTRO'));


        this.checkControl = this.formBuilder.control({
            value: this.requerimento.concordouTermosResponsabilidade,
            disabled: this.isSomenteVisualizacao
        });


        this.helper.onCurrentStepChange().pipe(takeUntil(this._unsubscribeAll)).subscribe(e => {
            if (e.newTabIndex === RequerimentoSteps.TERMO.index) {
                this.checkControl = this.formBuilder.control({
                    value: this.requerimento.concordouTermosResponsabilidade,
                    disabled: this.isSomenteVisualizacao
                });
            }
        });

        this.service.buscarAssinantesPorRequerimento(this.requerimento).subscribe(result => {
            this.assinantes = result;
        });

        this.documentoProcessoSignatarioService.listaDeDocumentosAssinados(this.requerimento.id).subscribe(docs => {
            console.log('AbaTermoResponsabilidadeComponent.listaDeDocumentosAssinados.documentoProcessoSignatarioService:', docs);
            this.documentosSignatario = docs;
        },(e) => {
            console.log('AbaTermoResponsabilidadeComponent.listaDeDocumentosAssinados.documentoProcessoSignatarioService.ERROR', e);
        })
    }

    validarAba(erros: ErrosAbaRequerimento): void {
        if (!this.checkControl.value) {
            erros.push('Para enviar o requerimento é necessário concordar com os termos e condições..');
        }
    }

    baixarPdf() {
        this.requerimentoLicenciamentoService.downloadRelatorioDocumentoFlorestal(this.requerimento.id).subscribe(response => {
            const blobGenerate = new Blob([response], {type: response.type});
            const downloadURL = window.URL.createObjectURL(blobGenerate);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = 'Documento Requerimento' + this.requerimento.numero + '.pdf';
            link.click();
        })
    }

    statusTermo(): void {
        const check = this.checkControl.value;
        this.service.statusTermo(this.requerimento.id, check)
            .subscribe(() => {
                this.requerimento.concordouTermosResponsabilidade = check;
                return;
            });
    }

    visualizarAssinatura(item: AssinanteRequerimentoDto): void {
        this.documentoProcessoService.downloadDocumentoProcesso(item.documentoProcesso).subscribe(blob => {
            this.pdfViewerDialog.show(blob, {
                height: '80%', width: '60%', showDownloadButton: true
            });
        });
    }
}
