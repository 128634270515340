import {FinalidadeAtividade} from '../../pages/finalidades-atividade/finalidade-atividade.model';


export class RequerimentoOutorgaFinalidadeAtividadeDTO {
    constructor(
        public id?: number,
        public finalidadeAtividade?: FinalidadeAtividade,
        public ppd?: string,
        public temFormulario?: boolean,
        public temPonto?: boolean
    ) {
    }

    static fromJson(json: any): RequerimentoOutorgaFinalidadeAtividadeDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoOutorgaFinalidadeAtividadeDTO(
            json.id,
            FinalidadeAtividade.fromJson(json.finalidadeAtividade),
            json.ppd,
            json.temFormulario,
            json.temPonto
        );
    }

    static fromJsons(jsonArray: any): RequerimentoOutorgaFinalidadeAtividadeDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }

}
