import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../../../../../../shared/snack-bar/snack-bar.service';
import {EmpreendimentoEnergiaFotovoltaicaService} from '../../../../../../../../../shared/services/empreendimento-energia-fotovoltaica.service';
import {EnergiaFotovoltaicaTransformadores} from '../../../../../../../../../shared/models/energia-fotovoltaica-transformadores.model';

@Component({
    selector: 'app-painel-transformadores',
    templateUrl: './painel-transformadores.component.html',
    styleUrls: ['./painel-transformadores.component.scss']
})
export class PainelTransformadoresComponent implements OnInit, OnChanges {

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Input() id: string;
    @Input() readonly = false;
    @Input() isNaoPodeEditar: boolean;

    form: FormGroup;
    dataSource: EnergiaFotovoltaicaTransformadores[] = [];
    displayedColumns = [ 'entradaTransformador', 'saidaTransformador' ];

    constructor(private fb: FormBuilder,
                private snackBarService: SnackBarService,
                private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            id: [''],
            entradaTransformador: ['', [Validators.required]],
            saidaTransformador: ['', [Validators.required]],
        });
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    filtroRegex(formName){
        var input = this.form.get(formName).value;
        input = input.replace(/[^0-9]/g, "");
        this.form.get(formName).patchValue(input);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.id) {
            this.carregar( changes.id.currentValue );
        }

        if (changes.readonly && !changes.readonly.currentValue) {
            this.displayedColumns.push( 'acao' );
        }
    }

    editar( objeto: EnergiaFotovoltaicaTransformadores ): void {

        this.form.patchValue( {
            id: objeto.id,
            entradaTransformador: objeto.valorEntradaMva,
            saidaTransformador: objeto.valorSaidaMva
        } );
    }

    excluir( objeto: EnergiaFotovoltaicaTransformadores ): void {

        this.empreendimentoEnergiaFotovoltaicaService.deletarTransformadores( objeto.id )
            .subscribe( () => {
                this.snackBarService.showSuccess( 'Transformador excluído com sucesso' );
                this.carregar( +this.id );
            }, error => {
                this.snackBarService.showError( 'Falha ao excluir informação' );
                console.error( error );
            } );
    }

    saveForm(): void {

        if ( !this.form.invalid ) {

            const form = this.form.getRawValue();
            const entidade: EnergiaFotovoltaicaTransformadores = {
                id: form.id,
                empreendimentoEnergFotovoltaica: { id: +this.id },
                valorEntradaMva: form.entradaTransformador,
                valorSaidaMva: form.saidaTransformador
            };
            this.empreendimentoEnergiaFotovoltaicaService.salvarTransformadores(entidade)
                .subscribe( retorno => {
                    this.save.emit(retorno);
                    this.snackBarService.showSuccess( 'Informações salvas com sucesso!' );
                    this.carregar( +this.id );
                    this.form.reset();
                    this.form.markAsPristine();
                    this.form.markAsUntouched();
                    this.form.updateValueAndValidity();
                }, error => {
                    this.snackBarService.showError( 'Falha ao salvar informação!' );
                    console.error( error );
                } );
        } else {
            this.form.markAllAsTouched();
            this.snackBarService.showError( 'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)' );
        }

    }

    private carregar( id: number ): void {

        this.empreendimentoEnergiaFotovoltaicaService.listarTransformadores( id )
            .subscribe( lista => {
                this.dataSource = lista;

            }, error => {
                this.snackBarService.showError( 'Falha ao carregar Transformadores' );
                console.error( error );
            } );
    }
}
