import {Pipe, PipeTransform} from '@angular/core';
import {UtilService} from "../../core/services/util.service";
@Pipe({ name: 'dataBR' })
export class DataPipe implements PipeTransform {
    constructor(private utilService: UtilService) {}
    transform(value: string): string {
        try {
            return this.utilService.zonedDateTimeFormat(value, 'DD/MM/YYYY HH:mm:ss')
        } catch (e) {
            return value
        }
    }
}
