import {NgModule} from '@angular/core';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from '../../shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule, Routes} from '@angular/router';
import {MatTableModule} from '@angular/material/table';
import {ReferendarParecerTecnicoConsemaComponent} from './referendar-parecer-tecnico-consema/referendar-parecer-tecnico-consema.component';
import {HttpClientModule} from '@angular/common/http';
import {ApreciarParecerTecnicoComponent} from './apreciar-parecer-tecnico/apreciar-parecer-tecnico.component';
import {VisualizarComentarioDialogComponent} from './apreciar-parecer-tecnico/visualizar-comentario-dialog/visualizar-comentario-dialog.component';
import {ConfirmarCancelarApreciarParecerDialogComponent} from './apreciar-parecer-tecnico/confirmar-cancelar-apreciar-parecer-dialog/confirmar-cancelar-apreciar-parecer-dialog.component';
import {ReferendarLicencaPreviaComponent} from './referendar-liceca-consema/referendar-licenca-previa.component';

export const routes: Routes = [
    {
        path: 'referendar-parecer-tecnico',
        component: ReferendarParecerTecnicoConsemaComponent
    },

    {
        path: 'referendar-licenca-previa',
        component: ReferendarLicencaPreviaComponent
    },
    {
        path: 'apreciar-parecer-tecnico',
        component: ApreciarParecerTecnicoComponent
    }
];

@NgModule({
    declarations: [
        ReferendarParecerTecnicoConsemaComponent,
        ReferendarLicencaPreviaComponent,
        ReferendarParecerTecnicoConsemaComponent,
        ApreciarParecerTecnicoComponent,
        VisualizarComentarioDialogComponent,
        ConfirmarCancelarApreciarParecerDialogComponent
        ],
    imports: [
        CoreModule,
        SharedModule,
        MatDatepickerModule,
        MatTooltipModule,
        RouterModule.forChild(routes),
        MatTableModule,
        HttpClientModule
    ],
    exports: [
        ReferendarParecerTecnicoConsemaComponent,
        ApreciarParecerTecnicoComponent,
        ReferendarLicencaPreviaComponent
    ],
    entryComponents: [VisualizarComentarioDialogComponent, ConfirmarCancelarApreciarParecerDialogComponent]
})

export class ReferendarParecerTecnicoConsemaModule {

}