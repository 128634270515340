import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AnaliseRequerimentoAnalistaService } from 'app/main/pages/tarefas/shared/service/analise-requerimento-analista.service';
import {AnaliseJustificativaPausa} from '../analise-justificativa.pausa.model';

@Component({
    selector: 'app-bpm-modal-impedimentos',
    templateUrl: './modal-impedimentos.component.html',
})
export class ModalImpedimentosComponent implements OnInit {

    impedimentosAnalista: AnaliseJustificativaPausa[] = [];
    quantidadeTarefa = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private analiseRequerimentoEquipeService: AnaliseRequerimentoAnalistaService,
                private analiseRequerimentoAnalistaService: AnaliseRequerimentoAnalistaService) {
    }

    ngOnInit(): void {
        this.getImpedimentosAnalista();
        this.getQuantidadeDeAnalista();
    }

    getImpedimentosAnalista(): void {
        this.analiseRequerimentoEquipeService.getImpedimentosAnalista(this.data.analista.id)
            .subscribe(response => {
                this.impedimentosAnalista = response;
            });
    }

    getQuantidadeDeAnalista(): void {
        this.analiseRequerimentoAnalistaService.getQuantidadeTarefasAnalista(this.data.analista.id)
            .subscribe(response => {
                this.quantidadeTarefa = response.length;
            });
    }
}
