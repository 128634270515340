import {BaseModel} from "./base.model";
import {EmpreendimentoTransporteResiduosPerigosos} from "./empreendimento-transporte-residuos-perigosos.model";

export class VeiculosEquipamentosResiduosPerigosos extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoTransporteResiduosPerigosos?: EmpreendimentoTransporteResiduosPerigosos,
        public marca?: string,
        public tipo?: string,
        public ano?: string,
        public placa?: string,
        public carga?: number
    ) {
        super();
    }

    static fromJson(json: any): VeiculosEquipamentosResiduosPerigosos {
        if (json === undefined || json === null) {
            return null;
        }
        return new VeiculosEquipamentosResiduosPerigosos(
            json.id,
            EmpreendimentoTransporteResiduosPerigosos.fromJson(json.empreendimentoTransporteResiduosPerigosos),
            json.marca,
            json.tipo,
            json.ano,
            json.placa,
            json.carga
        );
    }

    static fromJsons(json: any): VeiculosEquipamentosResiduosPerigosos[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(VeiculosEquipamentosResiduosPerigosos.fromJson(j)));
            return resources;
        }
    }
}