import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Madeira} from 'app/main/shared/models/madeira';
import {MadeiraService} from 'app/main/shared/services/madeira.service';
import {MateriaPrimaMadeiraService} from 'app/main/shared/services/materia-prima.service';
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {MateriaPrimaMadeira} from "../../../../../../../shared/models/materia-prima-madeira";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-madeira',
    templateUrl: './madeira.component.html',
    styleUrls: ['./madeira.component.scss']
})
export class MadeiraComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    form: FormGroup;

    isNaoPodeEditar: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private madeiraService: MadeiraService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
                private materiaPrimaMadeiraService: MateriaPrimaMadeiraService) {
        super();

    }

    async ngOnInit() {
        this.buildForm();

        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                     let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                 this.carregarMadeira(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                 this.carregarMadeira(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
                this.isNaoPodeEditar = true;
                this.form.disable();
        }
    }

    carregarMadeira(idRequerimento){
        this.madeiraService.getByRequerimento(idRequerimento)
            .subscribe(servico => {
                if (!!servico) {
                    this.form.setValue({
                        id: servico.id,
                        requerimento: servico.requerimento,
                        utilizaMadeira: servico.isUtilizaMadeira,
                        tipoMadeira: [
                            ...(servico.isMadeiraNativa ? ['nativa'] : []),
                            ...(servico.isMadeiraReflorestamento ? ['reflorestamento'] : []),
                        ],
                        existePicadorMaterialLenhoso: servico.isUtilizaPicador,
                        depositoDeMadeira: servico.isExisteDeposito,
                        materiasPrimas: null,
                    });

                    this.consultaMateriasPrimas(servico.id)
                }
            })
    }

    private consultaMateriasPrimas(id: number) {
        this.materiaPrimaMadeiraService.getByServicoGeralMadeira(id)
            .subscribe(materiasPrimas => this.form.get('materiasPrimas').setValue(materiasPrimas));
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            requerimento: [null],
            utilizaMadeira: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            tipoMadeira: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            existePicadorMaterialLenhoso: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            depositoDeMadeira: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            materiasPrimas: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
        })
    }

    private createMadeira(): Madeira {
        return new Madeira(
            this.form.controls.id.value,
            this.form.controls.requerimento.value || this.requerimento.id,
            this.form.controls.utilizaMadeira.value,
            this.form.controls.tipoMadeira.value.indexOf('nativa') >= 0,
            this.form.controls.tipoMadeira.value.indexOf('reflorestamento') >= 0,
            this.form.controls.existePicadorMaterialLenhoso.value,
            this.form.controls.depositoDeMadeira.value
        )
    }

    public async salva() {
        this.form.markAllAsTouched();
        Object.values(this.form.controls).forEach(control => control.markAsDirty());

        if (this.form.valid) {
            let madeira = this.createMadeira()

            if (!!this.form.get('id').value) {
                await this.madeiraService.update(madeira).toPromise().then((response) => {
                    madeira = response;
                    this.enviarFormConcluido.emit();
                });
            } else {
                await this.madeiraService.create(madeira).toPromise().then((response) => {
                    madeira = response;
                    this.enviarFormConcluido.emit();
                });
            }

            this.form.get('id').setValue(madeira.id);

            await this.salvaMateriasPrimas();
        } else {
            this.snackBarService.showError("O formulário Madeira possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).");
        }
    }

    private async salvaMateriasPrimas() {
        const registros = this.form.get('materiasPrimas').value;

        const existentes = await this.materiaPrimaMadeiraService.getByServicoGeralMadeira(this.form.get('id').value).toPromise();

        for (const registro of existentes) {
            if (!registros.find(c => registro.id === c.id)) {
                await this.materiaPrimaMadeiraService.delete(registro.id).toPromise();
            }
        }

        for (let registro of registros) {
            registro = MateriaPrimaMadeira.fromJson({
                ...registro,
                madeira: this.form.get('id').value,
                idServGeralMadeira: this.form.get('id').value,
            });

            if (registro.id) {
                await this.materiaPrimaMadeiraService.update(registro).toPromise();
            } else {
                await this.materiaPrimaMadeiraService.create(registro).toPromise();
            }
        }
    }
}
