import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ParecerRequerimentoConclusao } from 'app/main/pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.model';
import { ParecerRequerimentoConclusaoService } from 'app/main/pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.service';
import { ParecerTecnico } from 'app/main/pages/parecer-tecnico/parecer-tecnico.model';
import { RequerimentoTitulacao } from 'app/main/pages/requerimentos/shared/requerimento-titulacao/shared/requerimento-titulacao.model';
import { Template } from 'app/main/pages/templates/template.model';
import { TemplateService } from 'app/main/pages/templates/template.service';
import { BaseEnum } from 'app/main/shared/enums/base.enum';
import { Servidor } from 'app/main/shared/models/servidor.model';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import {MessageDialog, MessageDialogResult} from "../../../../../shared/components/message-dialog/message-dialog.model";

@Component({
    selector: 'app-lic-amb-shared-requerimento-solicitacoes-dialog',
    templateUrl: './requerimento-solicitacoes-dialog.component.html',
    styleUrls: ['./requerimento-solicitacoes-dialog.component.scss']
})
export class RequerimentoSolicitacoesDialogLicAmbSharedComponent implements OnInit {

    minDate: Date = new Date(); 
    form: FormGroup;
    deferido: boolean;
    requerimentoTitulacao: RequerimentoTitulacao;
    parecerTecnicoId: number;
    templates: Array<Template>;
    templatesTitulo: Array<Template>;
    templatesExtratoTitulo: Array<Template>;
    templatesAtoAdministrativo: Array<Template>;
    templatesExtratoAtosAdministrativos: Array<Template>;

    constructor(
        public dialogRef: MatDialogRef<RequerimentoSolicitacoesDialogLicAmbSharedComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any,
        private parecerRequerimentoConlusaoService: ParecerRequerimentoConclusaoService,
        private formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        private templateService: TemplateService,
        private messageDialog: MessageDialog,
    ) {
    }

    ngOnInit(): void {
        this.deferido = this.data['deferido'] ? true : false;
        this.requerimentoTitulacao = this.data['requerimentoTitulacao'] as RequerimentoTitulacao;
        this.parecerTecnicoId = this.data['parecerTecnicoId'] as number;

        this.form = this.formBuilder.group({
            tipoData: [null, this.deferido ? [Validators.required] : null],
            prazoTipo: [null],
            prazoNumero: [null],
            prazoVencimento: [null],
            motivo: [null, !this.deferido ? [Validators.required] : null],
            template: [null, this.deferido ? [Validators.required] : null],
            templatePublicacao: [null, this.deferido ? [Validators.required] : null]
        });
        this.minDate.setHours(24); // Setando uma data minima para selecionar nos inputs de data.
        this.listarTemplates();
    }

    onNoClick(): void {
        this.messageDialog.showQuestion(this.deferido ? 'Deseja fechar, "Registrar detalhes para Deferimento"' :
            'Deseja fechar, "Registrar detalhes para Indeferimento"').subscribe(result => {
            if (result === MessageDialogResult.Yes) {
                this.dialogRef.close(false);
            }
        })
    }

    onChangeData(): void {
        
        const prazoTipo = this.form.controls.prazoTipo;
        const prazoNumero = this.form.controls.prazoNumero;
        const prazoVencimento = this.form.controls.prazoVencimento;
        const template = this.form.controls.templateTitulo;
        const templateTitulo = this.form.controls.templateExtrato;

        prazoTipo.setValidators(this.isTipoDataPrazo ? [Validators.required] : []);
        prazoTipo.updateValueAndValidity();
        prazoTipo.reset();

        prazoNumero.setValidators(this.isTipoDataPrazo ? [Validators.required, Validators.min(1)] : []);
        prazoNumero.updateValueAndValidity();
        prazoNumero.reset();

        prazoVencimento.setValidators(this.isTipoDataPrazo || this.form.controls.tipoData.value.valor === 'SEM_PRAZO' ||
            this.form.controls.tipoData.value.valor === 'DEFINIR_PRAZO_NA_EMISSAO' ? [] : [Validators.required]);
        prazoVencimento.updateValueAndValidity();
        prazoVencimento.reset();
    }

    salvarConclusao(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const parecerConclusao = new ParecerRequerimentoConclusao();
            parecerConclusao.conclusao = this.deferido ? 'DEFERIDO' : 'INDEFERIDO';

            if (this.deferido) {
                parecerConclusao.tipoData = this.form.controls.tipoData.value.valor;
                parecerConclusao.template = this.form.controls.template.value;
                parecerConclusao.templatePublicacao = this.form.controls.templatePublicacao.value;
                if (this.form.controls.tipoData.value.valor === 'PRAZO') {
                    // PRAZO
                    parecerConclusao.prazoNumero = this.form.controls.prazoNumero.value;
                    parecerConclusao.prazoTipo = this.form.controls.prazoTipo.value.valor;
                    const numero = parecerConclusao.prazoNumero;
                    const dias = parecerConclusao.prazoTipo === 'DIAS' ? numero :
                        parecerConclusao.prazoTipo === 'SEMANAS' ? numero * 7 :
                            parecerConclusao.prazoTipo === 'MESES' ? numero * 30 : numero * 365;
                    const hoje = new Date();
                    hoje.setDate(new Date().getDate() + dias);
                    parecerConclusao.prazoVencimento = hoje;
                } else {
                    // VENCIMENTO
                    parecerConclusao.prazoVencimento = this.form.controls.prazoVencimento.value;
                }
            } else {
                parecerConclusao.motivo = this.form.controls.motivo.value.valor;
                parecerConclusao.template = this.form.controls.template.value;
                parecerConclusao.templatePublicacao = this.form.controls.templatePublicacao.value;
            }

            parecerConclusao.parecerTecnico = new ParecerTecnico(this.parecerTecnicoId);
            parecerConclusao.requerimentoTitulacao = this.requerimentoTitulacao;
            parecerConclusao.servidor = new Servidor();
            this.parecerRequerimentoConlusaoService
                .create(parecerConclusao)
                .subscribe(persisted => {
                    this.dialogRef.close(true);
                });
        } else {
            this.snackBarService.showAlert('Revise os campos do formulário da conclusão.');
        }

    }

    listarTemplates() {
        this.templateService.listAllTemplates().subscribe(result => {
            this.templates = result;
            this.carregaComboTemplates();
        })

    }

    carregaComboTemplates() {
        this.inicializarListasTemplates();

        this.templates.forEach(template => {
            if (template.fluxoProcesso === 'LICENCIAMENTO') {
                if (template.tipoTemplate === 'TITULO' && template.tipoTitulo !== 'OUTROS_ATOS') {
                    this.templatesTitulo.push(template);
                }
                if (template.tipoTemplate === 'EXTRATO' && template.tipoTitulo !== 'OUTROS_ATOS') {
                    this.templatesExtratoTitulo.push(template);
                }
                if (template.tipoTemplate === 'TITULO' && template.tipoTitulo === 'OUTROS_ATOS') {
                    this.templatesAtoAdministrativo.push(template);
                }
                if (template.tipoTemplate === 'EXTRATO' && template.tipoTitulo === 'OUTROS_ATOS') {
                    this.templatesExtratoAtosAdministrativos.push(template);
                }
            }
        });
        this.atribuirTemplateConfiguracaoTitulacao();
    }

    atribuirTemplateConfiguracaoTitulacao(){
        if(this.requerimentoTitulacao.titulacao.template)
            this.form.patchValue({template:
                    this.templatesTitulo.find(template => template.id == this.requerimentoTitulacao.titulacao.template.id)});
        if(this.requerimentoTitulacao.titulacao.templatePublicacao)
            this.form.patchValue({templatePublicacao:
                    this.templatesExtratoTitulo.find(templatePub => templatePub.id == this.requerimentoTitulacao.titulacao.templatePublicacao.id)})
        if(this.requerimentoTitulacao.titulacao.validadePadrao){
            this.form.patchValue({tipoData: ParecerRequerimentoConclusao.tiposData.find(tipoData => tipoData.valor == 'PRAZO'),
                prazoTipo: ParecerRequerimentoConclusao.prazosTipo.find(prazoTipo => prazoTipo.valor == 'ANOS'),
                prazoNumero: this.requerimentoTitulacao.titulacao.validadePadrao})
        }
    }

    inicializarListasTemplates() {
        this.templatesTitulo = new Array<Template>();
        this.templatesExtratoTitulo = new Array<Template>();
        this.templatesAtoAdministrativo = new Array<Template>();
        this.templatesExtratoAtosAdministrativos = new Array<Template>();
    }

    get tiposData(): BaseEnum[] {
        return ParecerRequerimentoConclusao.tiposData;
    }

    get prazosTipo(): BaseEnum[] {
        return ParecerRequerimentoConclusao.prazosTipo;
    }

    get motivosIndeferimento(): BaseEnum[] {
        return ParecerRequerimentoConclusao.motivosIndeferimento;
    }

    get isTipoDataPrazo(): boolean {
        return this.form.controls.tipoData &&
            this.form.controls.tipoData.value &&
            this.form.controls.tipoData.value.valor === 'PRAZO';
    }

    get isTipoDataVencimento(): boolean {
        return this.form.controls.tipoData &&
            this.form.controls.tipoData.value &&
            this.form.controls.tipoData.value.valor === 'VENCIMENTO';
    }

}
