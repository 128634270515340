import {BaseModel} from "./base.model";
import {EmpreendimentoObrasViarias} from "./empreendimento-obras-viarias.model";

export class TipoObrasEmpObrasViarias extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoObrasViarias?: EmpreendimentoObrasViarias,
        public tipo?: string
    ) {
        super();
    }

    static fromJson(json: any): TipoObrasEmpObrasViarias {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoObrasEmpObrasViarias(
            json.id,
            EmpreendimentoObrasViarias.fromJson(json.empreendimentoTransporteResiduosPerigosos),
            json.tipo,
        );
    }

    static fromJsons(json: any): TipoObrasEmpObrasViarias[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(TipoObrasEmpObrasViarias.fromJson(j)));
            return resources;
        }
    }
}