import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Titulacao} from '../../../../titulacoes/titulacao.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TitulacaoService} from '../../../../titulacoes/titulacao.service';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {EmissaoTituloService} from '../../../../tarefas/shared/service/emissao-titulo.service';
import {Requerimento} from '../../../requerimento.model';
import {Moment} from 'moment';
import { TituloLegadoAnexoService } from 'app/main/pages/tarefas/shared/service/titulo-legado-anexo.service';

@Component({
    selector: 'app-adicionar-copia-titulo',
    templateUrl: './adicionar-copia-titulo.component.html'
})
export class AdicionarCopiaTituloComponent implements OnInit {

    
    form: FormGroup;
    tiposTitulacoes = Titulacao.tipos;
    titulacoes: Titulacao[] = [];
    nomeArquivo = '';


    constructor(
        private dialogRef: MatDialogRef<AdicionarCopiaTituloComponent>,
        @Inject(MAT_DIALOG_DATA) private requerimento: Requerimento,
        private formBuilder: FormBuilder,
        private titulacaoService: TitulacaoService,
        private emissaoTituloService: EmissaoTituloService,
        private tituloLegadoAnexoService: TituloLegadoAnexoService,
        private snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            tipoTitulo: [null, [Validators.required, Validators.maxLength(255)]],
            nomeTitulo: [null, [Validators.required, Validators.maxLength(255)]],
            numero: [null, [Validators.required, Validators.maxLength(255)]],
            validade: [null, [Validators.required]],
            arquivo: [null]
        });
        this.form.get('arquivo').valueChanges.subscribe(value => {
            this.nomeArquivo = value != null ? value.name : '';
        });
    }

    salvar(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {

            const formData = new FormData();
            formData.append('tipoTitulo', this.form.get('tipoTitulo').value);
            formData.append('idTitulacao', this.form.get('nomeTitulo').value.id);
            formData.append('numero', this.form.get('numero').value);
            formData.append('validade', (this.form.get('validade').value as Moment).toDate().getTime().toString());
            if (this.form.get('arquivo').value) {
                formData.append('multipartFile', this.form.get('arquivo').value);
            }

            this.emissaoTituloService.anexarTitulacaoRequerimentoLegado(formData, this.requerimento).subscribe(result => {
                this.snackBarService.showSuccess('Titulação anexada com sucesso!');
                this.dialogRef.close(true);
            }, e => this.snackBarService.showError('Erro ao adicionar titulação', e));
        } else {
            this.snackBarService.showAlert('Preencha todos os campos.');
        }
    }

    salvarTituloLegado(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {

            const formData = new FormData();
            formData.append('tipoTitulo', this.form.get('tipoTitulo').value);
            formData.append('idTitulacao', this.form.get('nomeTitulo').value.id);
            formData.append('numero', this.form.get('numero').value);
            formData.append('validade', (this.form.get('validade').value as Moment).toDate().getTime().toString());
            if (this.form.get('arquivo').value) {
                formData.append('multipartFile', this.form.get('arquivo').value);
            }

            this.tituloLegadoAnexoService.anexarTituloRequerimentoLegado(formData, this.requerimento).subscribe(result => {
                this.snackBarService.showSuccess('Titulação anexada com sucesso!');
                this.dialogRef.close(true);
            }, e => this.snackBarService.showError('Erro ao adicionar titulação', e));
        } else {
            this.snackBarService.showAlert('Preencha todos os campos.');
        }
    }

    onChangeTipoTitulacao(): void {
        const tipoTituloSelecionado = this.form.get('tipoTitulo').value;
        this.form.get('nomeTitulo').setValue(null);
        this.titulacaoService.getAllByTipo(tipoTituloSelecionado).subscribe(result => {
            this.titulacoes = result;
        }, error => this.snackBarService.showError(`Erro ao consultar titulações com o tipo ${tipoTituloSelecionado}`, error));
    }
}
