import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {notEmptyDirective} from "../../../../../../../../shared/directives/not-empty.directive";
import {checkboxBooleanRequiredDirective} from "../../../../../../../../shared/directives/checkbox-boolean-required.directive";

@Component({
    selector: 'app-tipo-subestacao',
    templateUrl: './tipo-subestacao.component.html',
    styleUrls: ['./tipo-subestacao.component.scss']
})
export class TipoSubestacaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() idEmpreendimento: number;
    @Input() isNaoPodeEditar: boolean;

    possuiSubestacaoExterna: FormControl;
    possuiSubestacaoInterna: FormControl;

    constructor() {

    }

    async ngOnInit() {
        this.possuiSubestacaoExterna = this.form.get('possuiSubestacaoExterna') as FormControl;
        this.possuiSubestacaoInterna = this.form.get('possuiSubestacaoInterna') as FormControl;
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    selecionouInterna(event: MatCheckboxChange) {
        if (event.checked) {
            this.form.get('subestacoesInternas').setValidators([Validators.required, notEmptyDirective]);
        } else {
            this.form.get('subestacoesInternas').setValidators([]);
        }
        this.form.get('subestacoesInternas').updateValueAndValidity();
    }

    selecionouExterna(event: MatCheckboxChange) {
        if (event.checked) {
            this.form.get('possuiSubestacaoAerea').setValidators([Validators.required,
                checkboxBooleanRequiredDirective(this.form, '', 'possuiSubestacaoTerrea')]);
            this.form.get('possuiSubestacaoTerrea').setValidators([Validators.required,
                checkboxBooleanRequiredDirective(this.form, '', 'possuiSubestacaoAerea')]);
        } else {
            this.form.get('possuiSubestacaoAerea').setValue(false);
            this.form.get('possuiSubestacaoTerrea').setValue(false);
            this.form.get('possuiSubestacaoAerea').setValidators([Validators.required]);
            this.form.get('possuiSubestacaoTerrea').setValidators([Validators.required]);
        }
        this.form.get('possuiSubestacaoAerea').updateValueAndValidity();
        this.form.get('possuiSubestacaoTerrea').updateValueAndValidity();
    }
}
