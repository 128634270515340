import {Injectable, Injector} from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TipoProcesso } from './tipo-processo-novo.model';

@Injectable({
  providedIn: 'root'
})
export class TipoProcessoService extends BaseService<TipoProcesso> {

    constructor(protected injector: Injector) {
        super(
            '/api/tipos-processo',
            injector,
            TipoProcesso.prototype,
            TipoProcesso.fromJson
        );
    }

    getListaTiposProcessoCriacaoNovoRequerimento(): Observable<TipoProcesso[]> {
        const url = `${this.urlResource}/ativos-criacao-requerimento`;
        return this.http
            .get(url)
            .pipe(map(array => TipoProcesso.fromJsons(array)), catchError(this.handleError.bind(this)));
    }

    getAllTiposProcesso(): Observable<TipoProcesso[]> {
        const url = `${this.urlResource}/list-all`;
        return this.http
            .get(url)
            .pipe(map(array => TipoProcesso.fromJsons(array)), catchError(this.handleError.bind(this)));
    }
}
