import { BaseModel } from "app/main/shared/models/base.model";
import { TemplateDTO } from "./template-dto.model";

export class ParecerRequerimentoConclusaoUpdateTemplateDTO extends BaseModel {
    constructor(
        public idParecerRequeriemntoConclusao?: number,
        public idTemplate?: number,
        public idTemplatePublicacao?: number,
        public tipoTemplate?: string
    ) {
        super();
    }

    static fromJson(json: any): ParecerRequerimentoConclusaoUpdateTemplateDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParecerRequerimentoConclusaoUpdateTemplateDTO(
            json.idParecerRequeriemntoConclusao,
            json.idTemplate,
            json.idTemplatePublicacao,
            json.tipoTemplate, 
        );
    }
}
