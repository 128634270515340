import {ObjetivoOutorga} from './objetivo-outorga.model';

export class ObjetivoRequerimentoOutorgaDTO {
    constructor(
        public id?: number,
        public objetivoOutorga?: ObjetivoOutorga,
        public temPonto?: boolean
    ) {
    }

    static fromJson(json: any): ObjetivoRequerimentoOutorgaDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObjetivoRequerimentoOutorgaDTO(
            json.id,
            ObjetivoOutorga.fromJson(json.objetivoOutorga),
            json.temPonto,
        );
    }

    static fromJsons(jsonArray: any): ObjetivoRequerimentoOutorgaDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }

}
