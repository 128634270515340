import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Requerimento} from '../../pages/requerimentos/requerimento.model';
import {ActivatedRoute} from '@angular/router';
import {EspelhoOutorgaDto} from '../../pages/requerimentos/requerimento-outorga/espelho-outorga-dto.model';
import {RequerimentoOutorgaService} from '../../pages/requerimentos/requerimento-outorga/requerimento-outorga.service';
import {RequerimentoLicenciamentoService} from '../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.service';
import {AtividadeSubAtividade} from '../../pages/requerimentos/requerimento-licenciamento/requerimento-taxa-licenciamento/atividade-subatividade.dto';
import {AtividadeSubatividadeService} from '../../pages/requerimentos/requerimento-licenciamento/requerimento-taxa-licenciamento/atividade-subatividade.service';
import {RequerimentoLicenciamento} from "../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model";
import {ParametroObjetivoService} from "../services/licenciamento-config-service/parametro-objetivo.service";
import {ParametroObjetivo} from "../models/licenciamento-config-models/parametro-objetivo.model";
import { TipoProcesso } from 'app/main/pages/tipos-processo/tipo-processo.model';
import { RequerimentoOutorga } from 'app/main/pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import { ObjetivoLicenciamentoService } from '../services/objetivo-licenciamento.service';
import { ObjetivoLicenciamento } from '../models/objetivo-licenciamento.model';
import { TitulacaoService } from 'app/main/pages/titulacoes/titulacao.service';
import { ObjetivoParametrizacaoObjetivoService } from '../services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service';

@Component({
    selector: 'app-cabecalho-requerimento',
    templateUrl: './cabecalho-requerimento.component.html',
    styleUrls: ['./cabecalho-requerimento.component.scss']
})
export class CabecalhoRequerimentoComponent implements OnInit, OnChanges {

    situacoesProcessos = Requerimento.situacoesProcesso;
    @Input()
    requerimento: Requerimento;

    requerimentoOutorga: EspelhoOutorgaDto;
    requerimentoLicenciamento: RequerimentoLicenciamento;
    requerimentoLicenciamentoList: RequerimentoLicenciamento[] = [];

    nomeTarefa: any;
    atividade: any;
    espelhoOutorgaDto: EspelhoOutorgaDto;
    listaAtividades: Array<AtividadeSubAtividade> = [];
    
    parametroObjetivoLicenciamento: ParametroObjetivo[] = [];

    tipoProcessos: Array<TipoProcesso> = [];
    objetivosLicenciamento: ObjetivoLicenciamento[];
    
    constructor(
        private route: ActivatedRoute,
        private requerimentoOutorgaService: RequerimentoOutorgaService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private objetivosLicenciamentoService: ObjetivoLicenciamentoService,
        private atividadeService: AtividadeSubatividadeService,
        private parametroObjetivoService: ParametroObjetivoService,
        private titulacoesService: TitulacaoService,
        private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService
    ) {
        this.route.params.subscribe(params => this.nomeTarefa = params['nome_tarefa']);
    }

    ngOnInit(): void {
        if (this.requerimento && this.requerimento.tipoProcesso.tipo === 'OUTORGA'){
            this.recuperarRequerimentoOutorga();
        } else if (this.requerimento.tipoProcesso.tipo === 'LICENCIAMENTO') {
            // this.recuperarRequerimentoLicenciamento();
            this.buscarDadosAtividadeRequerimentoLicenciamento();
        }
        if (this.requerimento)
        this.tipoProcessos.push(this.requerimento.tipoProcesso);


        

    }

    private recuperarRequerimentoOutorga(): void {
        if (this.requerimento) {
            this.requerimentoOutorgaService.getEspelhoOutorga(this.requerimento).subscribe(
                requerimentoOutoroga => {
                    this.requerimentoOutorga = requerimentoOutoroga;
                    this.espelhoOutorgaDto = requerimentoOutoroga  
                } 
                ,
                _ => this.espelhoOutorgaDto = null
            );
        }
    }

    private  recuperarRequerimentoLicenciamento(): void {
        if (this.requerimento) {
            this.requerimentoLicenciamentoService
                .getListaRequerimentoLicenciamento(this.requerimento.id)
                .subscribe( lista => {
                    this.atividadeService.getListaAtividade(lista).subscribe(listaAtividades => {
                        listaAtividades.forEach(ati => {
                            if (ati instanceof AtividadeSubAtividade) {
                                this.listaAtividades.push(ati);
                            }

                        });

                    });
                });
        }
    }


    isEllipsisActive(e): boolean {
        if (e.value) {
            return (e.offsetWidth < e.scrollWidth);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
            this.recuperarRequerimentoOutorga();
        }
    }

    async buscarDadosAtividadeRequerimentoLicenciamento(){
        this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
        this.requerimentoLicenciamentoList.push(this.requerimentoLicenciamento);
        if (this.requerimentoLicenciamento)
            this.objetivosLicenciamento = await this.objetivosLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();
        if(this.requerimento.idParametroObjetivo)
            this.parametroObjetivoLicenciamento.push(await this.parametroObjetivoService.getById(this.requerimento.idParametroObjetivo).toPromise());
        this.objetivosLicenciamento.forEach(async o => {
            o.titulacao = await this.titulacoesService.getById(await this.objetivoParametrizacaoObjetivoService.getIdTitulacaoByIdObjetivoLic(o.idObjetivoLicenciamentoAmbiental).toPromise()).toPromise();
        });

    }
}
