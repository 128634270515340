import {ModelUtil} from "../model-util";
import {Finalidade} from "../../../pages/finalidades/finalidade.model";

export class ParametroDiluicao {

    constructor(
        public parametroId?: number,
        public finalidade?: Finalidade,
        public nome?: string,
        public sigla?: string,
        public unidade?: string,
        public concentracaoBruta?: number,
        public concentracaoNatural?: number,
        public concentracaoLitro?: number,
        public obrigatorio?: boolean,
    ) {
    }

    static fromJson(json: any): ParametroDiluicao {
        if (json === undefined || json === null) {
            return null;
        }

        return new ParametroDiluicao(
            json.id? json.id : json.parametroId ? json.parametroId : null,
            json.finalidade? Finalidade.fromJson(json.finalidade) : null,
            json.texto? json.texto : json.nome ? json.nome : null,
            json.sigla ? json.sigla : null,
            json.unidade ? json.unidade : null,
            ModelUtil.getOrElseZero(json.concentracaoBruta),
            ModelUtil.getOrElseZero(json.concentracaoNatural),
            ModelUtil.getOrElseZero(json.concentracaoLitro),
            json.obrigatorio? json.obrigatorio : false
        );
    }

    static fromJsons(json: any): ParametroDiluicao[] {
        const array: ParametroDiluicao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(ParametroDiluicao.fromJson(item)));
            return array;
        }
    }
}
