import {Component, Input, ViewChild} from '@angular/core';
import {AbstractComponenteRelacionamento} from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento";
import {FormGroup, NgForm} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {ProdutoProcessadoTerceiroIndFrigorifico} from "../../../../../../../../shared/models/produto-processado-terceiro-ind-frigorifico.model";
import {ProdutoProcessadoTerceiroIndFrigorificoService} from "../../../../../../../../shared/services/produto-processado-terceiro-ind-frigorifico.service";
import {SubprodutoProcessadoTerceiroIndFrigorificoService} from "../../../../../../../../shared/services/subproduto-processado-terceiro-ind-frigorifico.service";
import {PessoaCorporativoService} from "../../../../../../../../shared/services/pessoa-corporativo.service";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {SubprodutoProcessadoTerceiroComponent} from "./subproduto-processado-terceiro/subproduto-processado-terceiro.component";

@Component({
    selector: 'app-produto-processado-terceiro',
    templateUrl: './produto-processado-terceiro.component.html',
    styleUrls: ['./produto-processado-terceiro.component.scss']
})
export class ProdutoProcessadoTerceiroComponent extends AbstractComponenteRelacionamento<ProdutoProcessadoTerceiroIndFrigorifico, ProdutoProcessadoTerceiroIndFrigorificoService> {

    protected TIPO_OUTRO = 'Outros';
    private readonly TAMANHO_CNPJ = 14;
    empresaExiste: boolean = false;

    @Input() form: FormGroup;
    @Input() serviceSubproduto: SubprodutoProcessadoTerceiroIndFrigorificoService;
    @Input() isNaoPodeEditar: boolean;
    @ViewChild('insideForm', {static: false}) insideForm: NgForm;
    @ViewChild('subproduto', {static: false}) subprodutoProcessadoTerceiroComponent: SubprodutoProcessadoTerceiroComponent;
    unidadesMedida: Array<DominioDto> = [];

    constructor(
        private dominiosService: DominiosService,
        private pessoaCorporativoService: PessoaCorporativoService,
        private snackBarService: SnackBarService,
    ) {
        super();
    }

    async ngOnInit() {
        super.ngOnInit();

        this.unidadesMedida = await this.dominiosService.getDominioByUrl('unidade-medida-prod-proc').toPromise();

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    getDisplayedColumns(): string[] {
        return ['produto', 'cnpj', 'empresa', 'numeroLO', 'volume', 'acoes'];
    }

    getEmpresaCNPJ() {
        this.empresaExiste = false;
        if (this.novoDado.vinculo.cnpjEmpresa && this.novoDado.vinculo.cnpjEmpresa.length === this.TAMANHO_CNPJ) {
            this.pessoaCorporativoService.consultarCnpjSemValidacaoCadastro(this.novoDado.vinculo.cnpjEmpresa).subscribe((pessoa) => {
                if (pessoa) {
                    this.novoDado.vinculo.razaoSocialEmpresa = pessoa.razaoSocial;
                    this.empresaExiste = true;
                } else {
                   this.erroConsultaCNPJ();
                }
            }, (err) => this.erroConsultaCNPJ());

        }
    }

    private erroConsultaCNPJ() {
        this.novoDado.vinculo.razaoSocialEmpresa = undefined;
        this.snackBarService.showAlert("Por favor, apresente as documentações da empresa de destinação de acordo com o termo de Referência. Aba 6 – Documentação complementar.");
    }

    validaNumeroLOEmpresa() {
        //TODO chamar endpoint para validar o número de licença (RN006)
    }

    isBotaoAdicionarDisabled() {
        return super.isBotaoAdicionarDisabled() || !this.novoDado.vinculo.volume || !this.novoDado.vinculo.idUnidadeMedida ||
            !this.novoDado.vinculo.cnpjEmpresa || !this.novoDado.vinculo.razaoSocialEmpresa || !this.novoDado.vinculo.numeroLicencaOperacaoEmpresa ;
    }

    getUnidadeMedida(idUnidadeMedida: any) {
        let um = this.unidadesMedida.find(um => um.id === idUnidadeMedida);
        return um ? um.descricao : '';
    }
}
