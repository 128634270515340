import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {RequerimentoService} from '../../requerimento.service';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {DatePipe} from '@angular/common';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {PdfViewerDialog} from 'app/main/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import {RequerimentoOutorgaComponent} from '../requerimento-outorga.component';
import {MessageDialog} from '../../../../shared/components/message-dialog/message-dialog.model';
import {takeUntil} from 'rxjs/operators';
import {ObjetivoRequerimentoOutorga} from '../../../../shared/models/objetivo-requerimento-outorga.model';
import {ErrosAbaRequerimento} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {ObjetivoRequerimentoOutorgaService} from '../../../../shared/services/objetivo-requerimento-outorga.service';
import {DocumentoProcessoService} from '../../../gerar-documento-publicacao/documento-processo.service';
import {AssinanteRequerimentoDto} from '../../assinante-requerimento.dto';
import { TermoReferenciaService } from 'app/main/pages/termos-referencia/termo-referencia.service';
import { DocumentoRequerimentoService } from 'app/main/pages/documentos-requerimentos/documento-requerimento.service';
import { ItemTermoReferenciaRequerimentoChecklistService } from 'app/main/shared/services/item-termo-referencia-requerimento-checklist.service';
import { ConfiguracaoLicenciamentoService } from 'app/main/shared/services/licenciamento-config-service/configuracao-licenciamento.service';
import { TermoReferenciaConfiguracaoLicenciamentoService } from 'app/main/shared/services/licenciamento-config-service/termo-referencia-configuracao-licenciamento.service';
import { TermoReferenciaRequerimento } from 'app/main/shared/models/termo-referencia-requerimento.model';
import { ItemTermoReferencia } from 'app/main/pages/itens-termos-referencia/item-termo-referencia.model';
import { DocumentoRequerimento, TipoDocumentoRequerimento } from 'app/main/pages/documentos-requerimentos/documento-requerimento.model';
import { ItemTermoReferenciaRequerimentoChecklist } from 'app/main/shared/models/item-termo-referencia-requerimento-checklist.model';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { result } from 'lodash';
import { TermoReferenciaRequerimentoService } from 'app/main/shared/services/termo-referencia-requerimento.service';

@Component({
    selector: 'app-requerimento-termo-responsabilidade-outorga',
    templateUrl: 'requerimento-termo-responsabilidade-outorga.component.html',
    styleUrls: ['requerimento-termo-responsabilidade-outorga.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoTermoResponsabilidadeOutorgaComponent extends BaseTabRequerimentoComponent implements OnInit {

    pdf: any;
    checkControl: FormControl;
    private objetivosRequerimentoOutorga: ObjetivoRequerimentoOutorga[] = [];
    assinantes: AssinanteRequerimentoDto[] = [];

    termosReferencia: TermoReferenciaRequerimento[] = [];
    itensObrigatorios: ItemTermoReferencia[] = [];
    documentos: DocumentoRequerimento[] = [];
    itensTermoChecklist: ItemTermoReferenciaRequerimentoChecklist[] = [];
    statusAnalises = ItemTermoReferenciaRequerimentoChecklist.statusAnalises;
    erroConfiguracaoOutorga: string;
    erroConfiguracaoLicenciamento: string;

    constructor(
        protected service: RequerimentoService,
        protected snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        public datepipe: DatePipe,
        private pdfViewerDialog: PdfViewerDialog,
        private documentoProcessoService: DocumentoProcessoService,
        private requerimentoOutorgaComponent: RequerimentoOutorgaComponent,
        private messageDialog: MessageDialog,
        private objetivoRequerimentoOutorgaService: ObjetivoRequerimentoOutorgaService,
        protected termoReferenciaService: TermoReferenciaService,
        protected termoReferenciaRequerimentoService: TermoReferenciaRequerimentoService,
        protected documentoRequerimentoService: DocumentoRequerimentoService,
        protected itemTermoReferenciaRequerimentoChecklistService: ItemTermoReferenciaRequerimentoChecklistService,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.TERMO.component = this;
    }

    ngOnInit(): void {
        this.helper.onCurrentStepChange().pipe(takeUntil(this._unsubscribeAll)).subscribe(e => {
            if (e.newTabIndex === RequerimentoSteps.TERMO.index) {

                this.objetivoRequerimentoOutorgaService.getAllByRequerimentoOutorga(this.requerimentoOutorga)
                    .subscribe(result => this.objetivosRequerimentoOutorga = result);

                this.checkControl = this.formBuilder.control({
                    value: this.requerimento.concordouTermosResponsabilidade,
                    disabled: this.isSomenteVisualizacao
                });

                this.service.buscarAssinantesPorRequerimento(this.requerimento).subscribe(result => {
                    this.assinantes = result;
                });

                this.termoReferenciaService.getByRequerimentoOutorgaId(this.requerimentoOutorga.id).subscribe(termosReferencia => {
                    this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
                }, error => {
                    this.erroConfiguracaoOutorga = error.errors[0];
                    this.snackBarService.showError('Erro ao listar termos', error);
                });

                this.listarDocumentos();
                this.listarChecklists();
            }
        });
    }
    
    async visualizarRequerimento(): Promise<void> {
        const abasParaValidar = RequerimentoSteps.values;
        abasParaValidar.pop();
        let errosAbasRequerimento: ErrosAbaRequerimento[] = [];
        await this.requerimentoOutorgaComponent.validarAbas(abasParaValidar).then(errosAbas => errosAbasRequerimento = errosAbas);
        if (errosAbasRequerimento.length) {
            this.messageDialog.showWarning('Por favor, preencha as informações do requerimento para visualizar o relatório.', 'Visualizar relatório');
            return Promise.resolve();
        }

        this.service.readPdf(this.requerimento).subscribe(blob => {
            this.pdfViewerDialog.show(blob, {
                height: '80%', width: '60%', showDownloadButton: true
            });
        });
    }

    statusTermo(): void {
        const check = this.checkControl.value;
        this.service.statusTermo(this.requerimento.id, check)
            .subscribe(() => {
                this.requerimento.concordouTermosResponsabilidade = check;
                return;
            });
    }

    get temAvisoReclassificacaoTitulacao(): boolean {
        return this.objetivosRequerimentoOutorga.some(o => o.objetivo.formulario === 'LANCAMENTO_EFLUENTE'
            || o.objetivo.formulario === 'CAPTACAO_SUPERFICIAL');
    }

    validarAba(erros: ErrosAbaRequerimento): void {
        if (!this.checkControl.value) {
            erros.push('É obrigatório concordar com os Termos de Responsabilidade.');
        }
        if (!this.termosReferencia.length) {
            erros.push('O Requerimento precisa de pelo menos um Termo de Referência associado.', false);
        }
        if (this.itensTermoChecklist &&
            this.itensTermoChecklist.find(item => item.statusAnalise === 'NAO_ATENDE')) {
            erros.push('Verifique se há algum item do Termo de Referência com a situação "NÃO ATENDE".', false);
        }
        this.itensObrigatorios.forEach(item => {
            const possuiDoc = this.documentos.some(documento => documento.itemTermoReferenciaRequerimento.itemTermoReferencia.id === item.id);
            if (!possuiDoc) {
                erros.push(`O Item: ${item.numero} do Termo de Referência: ${item.termoReferencia.numero}, precisa de pelo menos um arquivo associado.`, false);
            }
        });
    }

    private listarChecklists(): void {
        this.itemTermoReferenciaRequerimentoChecklistService
            .getAllByRequerimento(this.requerimento.id)
            .subscribe(items => {
                this.itensTermoChecklist = items;
            });
    }

    listarDocumentos(): void {
        this.documentoRequerimentoService
            .getByRequerimento(this.requerimento, [TipoDocumentoRequerimento.ITEM_TERMO_REFERENCIA])
            .subscribe(documentos => {
                this.documentos = documentos;
            });
    }

    visualizarAssinatura(item: AssinanteRequerimentoDto): void {
        this.documentoProcessoService.downloadDocumentoProcesso(item.documentoProcesso).subscribe(blob => {
            this.pdfViewerDialog.show(blob, {
                height: '80%', width: '60%', showDownloadButton: true
            });
        });
    }
}
