import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {MatPaginator, MatSort} from '@angular/material';
import {HttpParams} from '@angular/common/http';
import {catchError, finalize} from 'rxjs/operators';
import {BaseService} from '../services/base.service';
import {Page} from './page.model';
import {BaseModel} from './base.model';

export class BaseDataSource<T extends BaseModel> extends DataSource<T> {

    constructor(
        private service: BaseService<T>,
        private paginator: MatPaginator,
        private sorter: MatSort,
        initialPage?: Page<T>
    ) {
        super();
        if (initialPage !== undefined) {
            this.extractPage(initialPage);
        }
    }

    private dataSubject = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    static compare(a: number | string, b: number | string, isAsc: boolean): any {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    list(httpParams: HttpParams): void {
        this.loadingSubject.next(true);
        this.service.getPage(httpParams).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(this.extractPage.bind(this));
    }

    sort(): void {
        if (this.sorter == null) {
            return;
        }
        if (!this.sorter.active || this.sorter.direction === '') {
            return;
        }
        this.dataSubject.next(this.dataSubject.getValue().sort((a, b) =>
            BaseDataSource.compare(a[this.sorter.active], b[this.sorter.active], this.sorter.direction === 'asc')
        ));
    }

    extractPage(page: Page<T>): void {
        this.paginator.length = page.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.dataSubject.next(page.content);
        this.sort();
    }

    connect(): Observable<T[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

}