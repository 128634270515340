import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm} from "@angular/forms";

@Component({
    selector: 'app-cemiterio',
    templateUrl: './cemiterio.component.html',
    styleUrls: ['./cemiterio.component.scss']
})
export class CemiterioComponent {
    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
}
