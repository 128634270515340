import {Injectable, Injector} from '@angular/core';
import {TipoProcesso} from './tipo-processo.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';

@Injectable()
export class TipoProcessoListResolver extends ListResolver<TipoProcesso> {
    constructor(service: TipoProcessoService) {
        super(service);
    }
}

@Injectable()
export class TipoProcessoResolver extends DetailResolver<TipoProcesso> {
    constructor(service: TipoProcessoService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TipoProcessoService extends BaseService<TipoProcesso> {
    constructor(protected injector: Injector) {
        super('/api/tipos-processo', injector, TipoProcesso.prototype, TipoProcesso.fromJson);
    }
}
