import { BaseEnum } from 'app/main/shared/enums/base.enum';
import {BaseModel} from 'app/main/shared/models/base.model';

export class TipoProcesso extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipo?: string,
        public custoDiariaVistoria?: number,
        public custoKmRodadoVistoria?: number,
        public instanciaRequerimento?: string,
        public dataFim?: Date,
        public ativo?: boolean
    ) {
        super();
    }

    public isOutorga() : boolean {
        return this.tipo === 'OUTORGA';
    }

    static tiposObjetivoProcesso: BaseEnum[] = [
        {valor: 'AUTORIZACAO', descricao: 'AUTORIZACAO'},
        {valor: 'LICENCIAMENTO', descricao: 'LICENCIAMENTO'},
        {valor: 'OUTORGA', descricao: 'OUTORGA'},
        {valor: 'ESTUDO_IMPACTO_AMBIENTAL_EIA_RIMA', descricao: 'ESTUDO DO IMPACTO AMBIENTAL/EIA-RIMA'},
    ];

    static instanciasRequerimento: BaseEnum[] = [
        {valor: 'AUTORIZACAO_OUTROS_DOCUMENTOS', descricao: "AUTORIZAÇÃO/OUTROS DOCUMENTOS"},
        {valor: 'LICENCIAMENTO_ADESAO', descricao: 'LAC'},
        {valor: 'LICENCIAMENTO_TRIFASICO', descricao: 'SUIMIS'},
        {valor: 'RECURSO_HIDRICO', descricao: 'SURH'},
        {valor: 'RECURSO_FLORESTAL', descricao: 'SUGF'},
        {valor: 'TERMO_REFERENCIA', descricao: 'EIA/RIMA'},
        {valor: 'FLUXO_NAO_DEFINIDO', descricao: 'FLUXO NÃO DEFINIDO'},
    ];

    static fromJson(json: any): TipoProcesso {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoProcesso(
            json.id,
            json.descricao,
            json.tipo,
            json.custoDiariaVistoria,
            json.custoKmRodadoVistoria,
            json.instanciaRequerimento,
            json.dataFim,
            json.ativo
        );
    }
}