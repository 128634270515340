import {BaseModel} from "../base.model";
import {SolicitacaoManifestacao} from "./solicitacao-manifestacao.model";

export class SolicitacaoAnexo extends BaseModel {

    constructor(
        public id?: number,
        public solicitacao?: SolicitacaoManifestacao,
        public chave?: string,
        public indSigiloso?: number,
        public titulo?: string,
    ) {
        super();
    }

    static fromJson(json: any): SolicitacaoAnexo {
        if (json === undefined || json === null) {
            return null;
        }
        return new SolicitacaoAnexo(
            json.id,
            SolicitacaoManifestacao.fromJson(json.solicitacao),
            json.chave,
            json.indSigiloso,
            json.titulo,
        );
    }
}