import {EmpreendimentoEnergeticoTermicaService} from './empreendimento-energetico-termica.service';
import {EmpreendimentoEnergTermica} from './../../../../../../../shared/models/empreendimento-energ-termica';
import {UsinaTermoeletricaDto} from 'app/main/shared/models/usina-termoeletrica-dto.model';
import {SnackBarService} from './../../../../../../../shared/snack-bar/snack-bar.service';
import {UnidadeContingenciaUsinaTerm} from './../../../../../../../shared/models/energia-termica-unidade-contingencia.model';
import {EquipamentoMotrizUsinaTerm} from './../../../../../../../shared/models/energia-termica-equipamento-motriz.model';
import {GeradorVaporUsinaTerm} from './../../../../../../../shared/models/energia-termica-gerador-vapor.model';
import {GeradorEletricoUsinaTerm} from './../../../../../../../shared/models/energia-termica-gerador-eletrico.model';
import {UsinaTipoFossil} from './../../../../../../../shared/models/energia-termica-tipo-fossil.model';
import {UsinaTipoBiomassa} from './../../../../../../../shared/models/energia-termica-tipo-biomassa.model';
import {Dominio} from './../../../../../../../shared/models/dominio-model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit, Output} from '@angular/core';
import {DominiosService} from '../../../../../../../shared/services/dominios.service';
import {BaseAtividadeEmpreendimentoComponent} from '../base-atividade-empreendimento.component';
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-ee-termica',
    templateUrl: './ee-termica.component.html',
    styleUrls: ['./ee-termica.component.scss']
})
export class EeTermicaComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    @Output() idTipoUsina: any;

    updateId: boolean = false;
    formEnergTermica: FormGroup;
    dominioTipoEmp: Dominio[] = [];
    tipoSistemaResfriamento: any[] = [];
    tipoSistemaConexo: any[] = [];
    tipoCombustiveisPrevisto: any[] = [];
    tipoReposicaoPerdasAgua: any[] = [];

    usinasBiomassaSalvas: UsinaTipoBiomassa[] = [];
    usinasFossilSalvas: UsinaTipoFossil[] = [];
    geradoresEletricoSalvos: GeradorEletricoUsinaTerm[] = [];
    geradoresVaporSalvos: GeradorVaporUsinaTerm[] = [];
    equipamentosMotrizSalvos: EquipamentoMotrizUsinaTerm[] = [];
    unidadesContingenciaSalvos: UnidadeContingenciaUsinaTerm[] = [];
    unidadeMedida: any[] = [];
    usinaBiomassa: any;
    usinaFossil: any;
    tipoCombustiveisPrevistos: any;
    tipoReposicoesPerdasEmAgua: any;
    validateErrorInput: string;
    errorTipos: boolean = false;
    errorCicloSimples: boolean = false;
    errorCicloCombinado: boolean = false;

    idEEtermica: number;
    mostrarTipoBiomassa = false;
    mostrarTipoFossil = false;

    isNaoPodeEditar: boolean = false;

    constructor(
        private fb: FormBuilder,
        private snackBarService: SnackBarService,
        private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService,
        private dominioService: DominiosService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
    }

    async ngOnInit() {
        this.buildFormUsinaTermo();
        this.listarTipoUsina();
        this.listarTipoSistemaResfriamento();
        this.listarTipoSistemaConexao();
        this.listarTipoCombustiveisPrevistos();
        this.listaTipoReposicoesPerdasAgua();
        this.buscarUnidadeDeMedida();

        if (this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                 this.carregaEEtermicoPorIdRequerimento(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                 this.carregaEEtermicoPorIdRequerimento(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
                this.isNaoPodeEditar = true;
                this.formEnergTermica.disable();
        }

    }

    buscarUnidadeDeMedida(): void {
        this.empreendimentoEnergService.buscarDominiosFiltrados().subscribe(unidadeMedida => {
            this.unidadeMedida = unidadeMedida.filter(unidade => unidade.id <= 2);
        });
    }

    private buildFormUsinaTermo(): void {
        this.formEnergTermica = this.fb.group({
            id: [null],
            tipoUsinaBiomassa: [''],
            tipoUsinaFossil: [''],
            idSistemaConexao: ['', Validators.required],
            numUnidadeGeradora: ['', Validators.required],
            potenciaInstaladaPlaca: ['', [Validators.required]],
            potenciaLiquidaDeclarada: ['', [Validators.required]],
            potenciaInstaladaDeclarada: ['', [Validators.required]],
            cpDenominacao: ['', [Validators.required]],
            cpConsumo: ['', [Validators.required]],
            cpDensidadeMetroCubico: ['', [Validators.required]],
            cpPoderCalorificoInferior: ['', [Validators.required]],
            cpTipoUnidadeMedida: ['', [Validators.required]],
            caDenominacao: [''],
            caConsumo: [''],
            caDensidadeMetroCubico: [''],
            caPoderCalorificoInferior: [''],
            caTipoUnidadeMedida: [''],
            balEletMaxGeracaoBruta: ['', [Validators.required]],
            balEletConsServicoAuxiliar: [''],
            balEletMaxGeracaoLiquida: ['', [Validators.required]],
            balEletConsProcessoConexo: [''],
            balEletPerdasAteConexao: [''],
            balEletIntercambioRede: [''],
            dcpTipo: ['', [Validators.required]],
            dcpConfiguracaoGp: [''],
            dcpConfiguracaoCg: [''],
            dcpGeracaoCs: [''],
            dcpGeracaoCb: [''],
            tipoCsCtv: [''],
            tipoCsTcg: [''],
            tipoCbCtgr: [''],
            cgTipoCs: [''],
            cgTipoCb: [''],
            cgTipoCsCtv: [''],
            cgTipoCbCtgr: [''],
            cgtipoCbCtgvr: [''],
            tipoSistemaResfriamento: ['', [Validators.required]],
            tipoReposicoesPerdasEmAgua: [''],
            codigoReposicaoPerdasTipoAgua: ['', [Validators.required]],
            rpaGvQtdeAgua: ['', [Validators.required]],
            rpaMrVazaoCirculacao: [''],
            rpaMrResposicaoPerdas: [''],
            rpaCdVazaoCirculacao: [''],
            rpaCdResposicaoPerdas: [''],

        });
    }

    public exibirFormUsina(idBiomassa: number, idFossil: number): void {
        if (idBiomassa == 1) {
            this.mostrarTipoBiomassa = true;
            this.idTipoUsina = 1;
        }

        if (idFossil == 1) {
            this.mostrarTipoFossil = true;
            this.idTipoUsina = 2;
        }
    }

    salvar(): void {
        const validate = this.validarFormulario();
        if (validate) {
            const usinaTermoeletricaDto = new UsinaTermoeletricaDto();
            usinaTermoeletricaDto.listaUsinasBiomassa = this.usinasBiomassaSalvas;
            usinaTermoeletricaDto.listaUsinasFossil = this.usinasFossilSalvas;
            usinaTermoeletricaDto.listaGeradoresEletrico = this.geradoresEletricoSalvos;
            usinaTermoeletricaDto.listaGeradoresVapor = this.geradoresVaporSalvos;
            usinaTermoeletricaDto.listaEquipamentosMotriz = this.equipamentosMotrizSalvos;
            usinaTermoeletricaDto.listaUnidadesContingencia = this.unidadesContingenciaSalvos;
            usinaTermoeletricaDto.empreendimentoEnergeticoTermica = this.montarEmpreendimento();

            this.empreendimentoEnergService.salvarEmpreendimentoEnergetico(usinaTermoeletricaDto).subscribe(() => {
                this.snackBarService.showSuccess('Salvo com sucesso');
                this.enviarFormConcluido.emit();
            });
        }
    }

    validarFormulario(): boolean {
        const validate = this.validarAgrupamento();
        if (this.formEnergTermica.valid && validate) {
            if (!this.formEnergTermica.value.tipoReposicoesPerdasEmAgua) {
                this.validateErrorInput = 'errorTipoReposicoesPerdasEmAgua';
                this.snackBarService.showAlert(
                    'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
                return false;
            }
            if (!this.formEnergTermica.value.tipoUsinaBiomassa && !this.formEnergTermica.value.tipoUsinaFossil) {
                this.validateErrorInput = 'errorTipoUsinaTermo';
                return false;
            }
            if (this.formEnergTermica.value.codigoReposicaoPerdasTipoAgua === 1) {
                if (!this.formEnergTermica.value.rpaMrVazaoCirculacao &&
                    !this.formEnergTermica.value.rpaMrResposicaoPerdas &&
                    !this.formEnergTermica.value.rpaCdVazaoCirculacao &&
                    !this.formEnergTermica.value.rpaCdResposicaoPerdas) {
                    this.validateErrorInput = 'erroRepoPerdas';
                    return false;
                }
            }
            if (!this.requerimento) {
                this.snackBarService.showError('idRequerimento não encontrado.');
                return false;
            }
            if (this.usinasBiomassaSalvas.length > 0 || this.usinasFossilSalvas.length > 0) {
                if (this.geradoresEletricoSalvos.length > 0 && this.geradoresVaporSalvos.length > 0 &&
                    this.equipamentosMotrizSalvos.length > 0 && this.unidadesContingenciaSalvos.length > 0) {
                    return true;
                } else {
                    this.snackBarService.showAlert(
                        'Algum dos formulário possui campo(s) obrigatório(s)' +
                        ' não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
                    return false;
                }
            } else {
                this.snackBarService.showAlert(
                    'O formulário Biomassa ou Fóssil possui campo(s) obrigatório(s)' +
                    ' não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
                return false;
            }
            return true;
        } else {
            this.snackBarService.showAlert(
                'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            this.formEnergTermica.markAllAsTouched();
            return false;
        }
    }

    verificarVazio(elemento): boolean {
        return elemento === '' || elemento == null;
    }

    public listarTipoUsina(): void {
        this.dominioService.getDominio('TIPO_USINA_TERMOELETRICA').subscribe(retorno => {
            this.dominioTipoEmp = retorno;
            this.usinaBiomassa = retorno[0];
            this.usinaFossil = retorno[1];
        });
    }

    public listarTipoSistemaResfriamento(): void {
        this.dominioService.getDominio('SISTEMA_RESFRIAMENTO').subscribe(retorno => {
            this.tipoSistemaResfriamento = retorno;
        });
    }

    public listarTipoSistemaConexao(): void {
        this.dominioService.getDominio('TIPO_SISTEMA_CONEXAO').subscribe(retorno => {
            this.tipoSistemaConexo = retorno;
        });
    }

    public listarTipoCombustiveisPrevistos(): void {
        this.dominioService.getDominio('TIPO_COMBUSTIVEIS_PREVISTOS').subscribe(retorno => {
            this.tipoCombustiveisPrevisto = retorno;
        });
    }

    public listaTipoReposicoesPerdasAgua(): void {
        this.dominioService.getDominio('TIPO_REPO_PERDAS_AGUA').subscribe(retorno => {
            this.tipoReposicaoPerdasAgua = retorno;
        });
    }

    receberUsinasBiomassa(usinasAtualizadas: UsinaTipoBiomassa[]): void {
        this.usinasBiomassaSalvas = usinasAtualizadas;
    }

    receberUsinasFossil(usinasAtualizadas: UsinaTipoFossil[]): void {
        this.usinasFossilSalvas = usinasAtualizadas;
    }

    receberGeradoresEletricos(geradoresAtualizados: GeradorEletricoUsinaTerm[]): void {
        this.geradoresEletricoSalvos = geradoresAtualizados;
    }

    receberGeradoresVapor(geradoresAtualizados: GeradorVaporUsinaTerm[]): void {
        this.geradoresVaporSalvos = geradoresAtualizados;
    }

    receberUnidadeContingencia(unidadesContingenciaAtualizados: UnidadeContingenciaUsinaTerm[]): void {
        this.unidadesContingenciaSalvos = unidadesContingenciaAtualizados;
    }

    receberEquipamentoMotriz(equipamentosMotrizAtualizados: EquipamentoMotrizUsinaTerm[]): void {
        this.equipamentosMotrizSalvos = equipamentosMotrizAtualizados;
    }

    validarTipoUsinaTermoeletrica(item: Dominio, event: any): void {
        if (item.id === 1 && event.checked === true) {
            this.mostrarTipoBiomassa = true;
            this.idTipoUsina = item.id;
        } else if (item.id === 1 && event.checked === false) {
            this.mostrarTipoBiomassa = false;
        } else if (item.id === 2 && event.checked === true) {
            this.mostrarTipoFossil = true;
            this.idTipoUsina = item.id;
        } else if (item.id === 2 && event.checked === false) {
            this.mostrarTipoFossil = false;
        }

        // Caso ambos selecionados enviar ambos ids
        if (this.formEnergTermica.controls.tipoUsinaBiomassa.value === true &&
            this.formEnergTermica.controls.tipoUsinaFossil.value === true) {
            this.idTipoUsina = {idBiomassa: this.usinaBiomassa.id, idFossil: this.usinaFossil.id};
        }
    }

    validaTipoCombustiveisPrevistos(item: any): void {
        this.tipoCombustiveisPrevistos = item.id;
    }

    validaTipoReposicoesPerdasEmAgua(item: any): void {
        this.tipoReposicoesPerdasEmAgua = item.id;
    }

    montarEmpreendimento(): EmpreendimentoEnergTermica {
        return new EmpreendimentoEnergTermica(
            null,
            this.requerimento.id,
            this.formEnergTermica.controls.tipoUsinaBiomassa.value === true ? 1 : 0,
            this.formEnergTermica.controls.tipoUsinaFossil.value === true ? 1 : 0,
            this.formEnergTermica.controls.idSistemaConexao.value,
            Number(this.formEnergTermica.controls.numUnidadeGeradora.value),
            Number(this.formEnergTermica.controls.potenciaInstaladaPlaca.value),
            Number(this.formEnergTermica.controls.potenciaLiquidaDeclarada.value),
            Number(this.formEnergTermica.controls.potenciaInstaladaDeclarada.value),
            this.formEnergTermica.controls.cpDenominacao.value,
            Number(this.formEnergTermica.controls.cpConsumo.value),
            Number(this.formEnergTermica.controls.cpDensidadeMetroCubico.value),
            Number(this.formEnergTermica.controls.cpPoderCalorificoInferior.value),
            this.formEnergTermica.controls.cpTipoUnidadeMedida.value.id,
            this.formEnergTermica.controls.caDenominacao.value,
            Number(this.formEnergTermica.controls.caConsumo.value),
            Number(this.formEnergTermica.controls.caDensidadeMetroCubico.value),
            Number(this.formEnergTermica.controls.caPoderCalorificoInferior.value),
            this.formEnergTermica.controls.caTipoUnidadeMedida.value.id,
            Number(this.formEnergTermica.controls.balEletMaxGeracaoBruta.value),
            Number(this.formEnergTermica.controls.balEletConsServicoAuxiliar.value),
            Number(this.formEnergTermica.controls.balEletMaxGeracaoLiquida.value),
            Number(this.formEnergTermica.controls.balEletConsProcessoConexo.value),
            Number(this.formEnergTermica.controls.balEletPerdasAteConexao.value),
            Number(this.formEnergTermica.controls.balEletIntercambioRede.value),
            this.tipoCombustiveisPrevistos,
            this.formEnergTermica.controls.dcpConfiguracaoGp.value,
            this.formEnergTermica.controls.dcpConfiguracaoCg.value,
            this.formEnergTermica.controls.dcpGeracaoCs.value,
            this.formEnergTermica.controls.dcpGeracaoCb.value,
            this.formEnergTermica.controls.tipoCsCtv.value,
            this.formEnergTermica.controls.tipoCsTcg.value,
            this.formEnergTermica.controls.tipoCbCtgr.value,
            this.formEnergTermica.controls.cgTipoCs.value,
            this.formEnergTermica.controls.cgTipoCb.value,
            this.formEnergTermica.controls.cgTipoCsCtv.value,
            this.formEnergTermica.controls.cgTipoCbCtgr.value,
            this.formEnergTermica.controls.cgtipoCbCtgvr.value,
            this.formEnergTermica.controls.tipoSistemaResfriamento.value,
            this.tipoReposicoesPerdasEmAgua,
            this.formEnergTermica.controls.codigoReposicaoPerdasTipoAgua.value,
            Number(this.formEnergTermica.controls.rpaGvQtdeAgua.value),
            Number(this.formEnergTermica.controls.rpaMrVazaoCirculacao.value),
            Number(this.formEnergTermica.controls.rpaMrResposicaoPerdas.value),
            Number(this.formEnergTermica.controls.rpaCdVazaoCirculacao.value),
            Number(this.formEnergTermica.controls.rpaCdResposicaoPerdas.value),
        );
    }

    async carregaEEtermicoPorIdRequerimento(idRequerimento: number): Promise<void> {
        await this.empreendimentoEnergService.buscaEEtermicaPorIdRequerimento(idRequerimento).toPromise().then(eeTermica => {
            if (eeTermica) {
                this.idEEtermica = eeTermica.id;
                this.exibirFormUsina(eeTermica.idTipoUsinaBiomassa, eeTermica.idTipoUsinaFossil);
                this.formEnergTermica.controls.tipoUsinaBiomassa.patchValue(eeTermica.idTipoUsinaBiomassa === 1);
                this.formEnergTermica.controls.tipoUsinaFossil.patchValue(eeTermica.idTipoUsinaFossil === 1);
                this.formEnergTermica.controls.idSistemaConexao.patchValue(eeTermica.idSistemaConexao),
                this.formEnergTermica.controls.numUnidadeGeradora.patchValue(eeTermica.numUnidadeGeradora),
                this.formEnergTermica.controls.potenciaInstaladaPlaca.patchValue(eeTermica.potenciaInstaladaPlaca),
                this.formEnergTermica.controls.potenciaLiquidaDeclarada.patchValue(eeTermica.potenciaLiquidaDeclarada),
                this.formEnergTermica.controls.potenciaInstaladaDeclarada.patchValue(eeTermica.potenciaInstaladaDeclarada),
                this.formEnergTermica.controls.cpDenominacao.patchValue(eeTermica.cpDenominacao),
                this.formEnergTermica.controls.cpConsumo.patchValue(eeTermica.cpConsumo),
                this.formEnergTermica.controls.cpDensidadeMetroCubico.patchValue(eeTermica.cpDensidadeMetroCubico),
                this.formEnergTermica.controls.cpPoderCalorificoInferior.patchValue(eeTermica.cpPoderCalorificoInferior),
                this.formEnergTermica.controls.cpTipoUnidadeMedida.patchValue(eeTermica.cpTipoUnidadeMedida),
                this.formEnergTermica.controls.caDenominacao.patchValue(eeTermica.caDenominacao),
                this.formEnergTermica.controls.caConsumo.patchValue(eeTermica.caConsumo),
                this.formEnergTermica.controls.caDensidadeMetroCubico.patchValue(eeTermica.caDensidadeMetroCubico),
                this.formEnergTermica.controls.caPoderCalorificoInferior.patchValue(eeTermica.caPoderCalorificoInferior),
                this.formEnergTermica.controls.caTipoUnidadeMedida.patchValue(eeTermica.caTipoUnidadeMedida),
                this.formEnergTermica.controls.balEletMaxGeracaoBruta.patchValue(eeTermica.balEletMaxGeracaoBruta),
                this.formEnergTermica.controls.balEletConsServicoAuxiliar.patchValue(eeTermica.balEletConsServicoAuxiliar),
                this.formEnergTermica.controls.balEletMaxGeracaoLiquida.patchValue(eeTermica.balEletMaxGeracaoLiquida),
                this.formEnergTermica.controls.balEletConsProcessoConexo.patchValue(eeTermica.balEletConsProcessoConexo),
                this.formEnergTermica.controls.balEletPerdasAteConexao.patchValue(eeTermica.balEletPerdasAteConexao),
                this.formEnergTermica.controls.balEletIntercambioRede.patchValue(eeTermica.balEletIntercambioRede),
                this.formEnergTermica.controls.dcpTipo.patchValue(eeTermica.dcpTipo),
                this.formEnergTermica.controls.dcpConfiguracaoGp.patchValue(eeTermica.dcpConfiguracaoGp),
                this.formEnergTermica.controls.dcpConfiguracaoCg.patchValue(eeTermica.dcpConfiguracaoCg),
                this.formEnergTermica.controls.dcpGeracaoCs.patchValue(eeTermica.dcpGeracaoCs),
                this.formEnergTermica.controls.dcpGeracaoCb.patchValue(eeTermica.dcpGeracaoCb),
                this.formEnergTermica.controls.tipoCsCtv.patchValue(eeTermica.tipoCsCtv),
                this.formEnergTermica.controls.tipoCsTcg.patchValue(eeTermica.tipoCsTcg),
                this.formEnergTermica.controls.tipoCbCtgr.patchValue(eeTermica.tipoCbCtgr),
                this.formEnergTermica.controls.cgTipoCs.patchValue(eeTermica.cgTipoCs),
                this.formEnergTermica.controls.cgTipoCb.patchValue(eeTermica.cgTipoCb),
                this.formEnergTermica.controls.cgTipoCsCtv.patchValue(eeTermica.cgTipoCsCtv),
                this.formEnergTermica.controls.cgTipoCbCtgr.patchValue(eeTermica.cgTipoCbCtgr),
                this.formEnergTermica.controls.cgtipoCbCtgvr.patchValue(eeTermica.cgtipoCbCtgvr),
                this.formEnergTermica.controls.tipoSistemaResfriamento.patchValue(eeTermica.tipoSistemaResfriamento),
                this.formEnergTermica.controls.tipoReposicoesPerdasEmAgua.patchValue(eeTermica.tipoReposicoesPerdasEmAgua),
                this.formEnergTermica.controls.codigoReposicaoPerdasTipoAgua.patchValue(eeTermica.codigoReposicaoPerdasTipoAgua),
                this.formEnergTermica.controls.rpaGvQtdeAgua.patchValue(eeTermica.rpaGvQtdeAgua),
                this.formEnergTermica.controls.rpaMrVazaoCirculacao.patchValue(eeTermica.rpaMrVazaoCirculacao),
                this.formEnergTermica.controls.rpaMrResposicaoPerdas.patchValue(eeTermica.rpaMrResposicaoPerdas),
                this.formEnergTermica.controls.rpaCdVazaoCirculacao.patchValue(eeTermica.rpaCdVazaoCirculacao),
                this.formEnergTermica.controls.rpaCdResposicaoPerdas.patchValue(eeTermica.rpaCdResposicaoPerdas);
            }
        });
        this.updateId = true;
    }

    validarAgrupamento(): boolean {
        if (this.formEnergTermica.value.dcpConfiguracaoGp || this.formEnergTermica.value.dcpConfiguracaoCg) {
            let tipoDcpConfig: string;
            if (this.formEnergTermica.value.dcpConfiguracaoGp === true) {
                tipoDcpConfig = 'Geracao Pura';
            } else if (this.formEnergTermica.value.dcpConfiguracaoCg === true) {
                tipoDcpConfig = 'Cogeracao';
            }

            if (tipoDcpConfig === 'Geracao Pura') {
                if (!this.formEnergTermica.value.dcpGeracaoCs && !this.formEnergTermica.value.dcpGeracaoCb) {
                    this.validateErrorInput = 'errorTipos';
                    return false;
                } else {
                    if (this.formEnergTermica.value.dcpGeracaoCs === true) {
                        if (!this.formEnergTermica.value.tipoCsCtv && !this.formEnergTermica.value.tipoCsTcg) {
                            this.validateErrorInput = 'errorCicloSimples';
                            return false;
                        } else {
                            this.validateErrorInput = '';
                            return true;
                        }
                    } else if (this.formEnergTermica.value.dcpGeracaoCb === true) {
                        if (!this.formEnergTermica.value.tipoCbCtgr) {
                            this.validateErrorInput = 'errorCicloCombinado';
                            return false;
                        }
                        this.validateErrorInput = '';
                        return true;
                    }
                }
            } else if (tipoDcpConfig === 'Cogeracao') {
                if (!this.formEnergTermica.value.cgTipoCs && !this.formEnergTermica.value.cgTipoCb) {
                    this.validateErrorInput = 'errorTipos';
                    return false;
                } else {
                    if (this.formEnergTermica.value.cgTipoCs === true) {
                        if (!this.formEnergTermica.value.cgTipoCsCtv) {
                            this.validateErrorInput = 'errorCicloSimples';
                            return false;
                        } else {
                            this.validateErrorInput = '';
                            return true;
                        }
                    } else if (this.formEnergTermica.value.cgTipoCb === true) {
                        if (!this.formEnergTermica.value.cgTipoCbCtgr && !this.formEnergTermica.value.cgtipoCbCtgvr) {
                            this.validateErrorInput = 'errorCicloCombinado';
                            return false;
                        }
                        this.validateErrorInput = '';
                        return true;
                    }
                }
            }
        } else {
            this.validateErrorInput = 'errorConfigBlocos';
            return false;
        }
    }
}
