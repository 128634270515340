import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatStepper} from '@angular/material';
import {Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {Pessoa} from 'app/main/shared/models/pessoa.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {Subject} from 'rxjs';
import {PessoaCorporativoService} from 'app/main/shared/services/pessoa-corporativo.service';
import {Documento} from 'app/main/shared/models/documento.model';
import {RequerimentoService} from '../../../../pages/requerimentos/requerimento.service';
import {Requerimento} from '../../../../pages/requerimentos/requerimento.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

/**
 * Documento de Requisitos
 * SEMA_SIGA_02_EspecificacaoFuncional_CadastroRequerimento
 * Pag.63
 */

@Component({
    selector: 'app-aba-requerimento-interessado-pj',
    templateUrl: './aba-requerimento-interessado-pj.component.html',
    styleUrls: ['./aba-requerimento-interessado-pj.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AbaRequerimentoInteressadoPjComponent implements OnInit {

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private pessoaCorporativoService: PessoaCorporativoService,
        protected requerimentoService: RequerimentoService,
    ) {
    }

    @Input()
    requerimento: Requerimento;
    @Input()
    salvarSubject: Subject<any>;
    @Input()
    dirtySubject: Subject<any> = null;
    @Input()
    public stepper: MatStepper;

    documentosEmpresa: Documento[];

    pessoasEmpresa: Pessoa[] = [];
    pessoaEmpresaSelecionada: Pessoa;
    pessoaEmpresaDocumentos: Documento[] = [];

    // forms
    form: FormGroup;

    // ui
    pessoasFisicasRepresentantesColumns: string[] = ['selection', 'cpf', 'nome', 'email', 'telefone'];
    mostraMensagemSubterraneaPJEUrbano: boolean;

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.buildResourceForm();
        this.loadData();
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            cpfCnpj: [null],
            nome: [null],
            nomeFantasia: [null],
            naturezaJuridica: [null],
            inscricaoMunicipal: [null],
            inscricaoEstadual: [null],
            telefone: [null],
            dataNascimento: [null],
            porteEmpresa: [null],
            endereco: [null],
        });
        this.form.disable();
    }

    private loadData() {
        this.form.patchValue(this.requerimento.pessoa);
        this.pessoaCorporativoService.getRepresentantesByCnpj(this.requerimento.pessoa.cpfCnpj).subscribe(result => {
            this.pessoasEmpresa = result;
        }, (error) => {
            if (error.status !== 404) {
                this.snackBarService.showError('Houve um erro ao listar os representantes.');
            }
        });
        this.pessoaCorporativoService.getDocumentosByPessoaIdCorporativo(this.requerimento.pessoa.idCorporativo).subscribe(result => {
            this.documentosEmpresa = result.filter(doc => doc.nome != null);
        });
        if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
            this.requerimentoService.ehRequerimentoSubterraneoPJEUrbano(this.requerimento.id).subscribe(result => this.mostraMensagemSubterraneaPJEUrbano = result);
        }
    }

    visualizarRepresentante(representante: Pessoa): void {
        this.pessoaEmpresaSelecionada = representante;
        this.pessoaCorporativoService.getDocumentosRepresentante(this.requerimento.pessoa.cpfCnpj, representante).subscribe(result => {
            this.pessoaEmpresaDocumentos = result;
        }, (e) => this.snackBarService.showError('Houve um erro ao listar os documentos.', e));
    }
}

