import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ReqLicenciamentoDinamicaDesmate} from "./req-licenciamento-dinamica-desmate.model";

@Injectable({
    providedIn: 'root'
})
export class ReqLicenciamentoDinamicaDesmateService extends BaseService<ReqLicenciamentoDinamicaDesmate> {
    constructor(protected injector: Injector) {
        super(
            '/api/req-licenciamento-dinamica-desmate',
            injector,
            ReqLicenciamentoDinamicaDesmate.prototype,
            ReqLicenciamentoDinamicaDesmate.fromJson
        );
    }

    getByListReqLicenciamentoIdAndIdFeicao(idRequerimentoLicenciamento: number, idFeicao: number): Observable<ReqLicenciamentoDinamicaDesmate[]> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/id-feicao/${idFeicao}`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    salvarLista(lista: any) {
        const url = `${this.urlResource}/lista`;
        return this.http.post(url, lista).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}
