import {DisponibilidadeHidricaMes} from './disponibilidade-hidrica-mes.model';
import {ModelUtil} from "../../../../../shared/models/model-util";

export class DisponibilidadeHidricaInfo {
    constructor(
        public disponibilidadeMeses?: DisponibilidadeHidricaMes[],
        public disponibilidadeMesesOriginal?: DisponibilidadeHidricaMes[],
        public concentracaoNatural?: string,
        public concentracaoLimite?: string,
    ) {
    }

    static fromJson(json: any): DisponibilidadeHidricaInfo {
        if (json === undefined || json === null) {
            return null;
        }
        return new DisponibilidadeHidricaInfo(
            ModelUtil.getOrElseNull(DisponibilidadeHidricaMes.fromJsons(json.disponibilidadeMeses)),
            ModelUtil.getOrElseNull(DisponibilidadeHidricaMes.fromJsons(json.disponibilidadeMesesOriginal)),
            ModelUtil.getOrElseNull(json.concentracaoNatural),
            ModelUtil.getOrElseNull(json.concentracaoLimite),
        );
    }
}
