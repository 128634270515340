import {EvapoPrecipMensal} from "../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-irrigacao/adicionar-irrigacao-cultura/evaporacao-precipitacao-mensal.model";
import {ModelUtil} from "./model-util";

export class EvapoPrecipDto {
    constructor(
        public evapotranspiracao?: EvapoPrecipMensal,
        public precipitacao?: EvapoPrecipMensal,
    ) {
    }

    static fromJson(json: any): EvapoPrecipDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new EvapoPrecipDto(
            ModelUtil.getOrElseNull(EvapoPrecipMensal.fromJson(json.evapotranspiracao)),
            ModelUtil.getOrElseNull(EvapoPrecipMensal.fromJson(json.precipitacao)),
        );
    }
}