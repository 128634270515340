import {Component, OnInit} from '@angular/core';
import {DominiosService} from '../../../../shared/services/dominios.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {Benfeitoria} from '../../../../shared/models/benfeitoria.model';
import {BenfeitoriaService} from '../../../../shared/services/benfeitoria.service';


@Component({
    selector: 'app-benfeitoria',
    templateUrl: './benfeitoria.component.html',
    styleUrls: ['./benfeitoria.component.scss']
})
export class BenfeitoriaComponent implements OnInit {

    tipoBenfeitoria: any;
    benfeitoriaForm: FormGroup;
    mostrarCapAlojamento = true;
    mostrarCapProducao = false;
    mostraDescricao = false;
    outraAtv = 1;
    // : TODO Esses Parametros devem chegar atraves do component Pai
    idGeometria = 10;
    idAtividae: any;

    // : TODO Remover apos realizar teste necessarios
    atividade = [{id: 404, descricao: 'Piscicultura'},
        {id: 414, descricao: 'Piscicultura / Distinta'},
        {id: 3768, descricao: 'Bovinocultura / Criação de Bovinos, Búfalos e caprinos'},
        {id: 3882, descricao: 'Suinocultura (ciclo completo)'},
        {id: 3985, descricao: 'Laboratório de Alevinagem'},
        {id: 4024, descricao: 'Avicultura / Produção de pintos de um dia (Incubatório)'},
        {id: 4087, descricao: 'Avicultura / Avicultura de corte'},
        {id: 4135, descricao: 'Piscicultura Convencional em tanques escavados'},
        {id: 4189, descricao: 'Suinocultura (crescimento e terminação)'},
        {id: 4191, descricao: 'Avicultura / Produção de ovos (Postura)'},
        {id: 4219, descricao: 'Irrigação / Obras de irrigação'},
        {id: 4237, descricao: 'Suinocultura (unidade de produção de leitões)'},
        {id: 4239, descricao: 'Piscicultura Tanques-rede'},
        {id: 4434, descricao: 'Produção de ovos férteis'},
        {id: 1, descricao: 'Outras Atividades'},
        {id: 4, descricao: 'Atividades Complementar'}];


    constructor(private dominiosService: DominiosService,
                private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private benfeitoriaService: BenfeitoriaService) {
    }

    ngOnInit(): void {
        this.benfeitoriaBuilder();

        // if (this.idAtividae) {
        //     this.retornaTipoConformeIdDaAtividade(this.idAtividae);
        // } else {
        //     this.snackBar.showError('idAtividade não encontrado para prosseguir consulta');
        // }

    }

    // : TODO Remover apos realizar teste necessarios
    preencherIdAtividade(value: any): void {
        this.idAtividae = value.id;
        this.retornaTipoConformeIdDaAtividade(this.idAtividae);
    }

    private benfeitoriaBuilder(): void {
        this.benfeitoriaForm = this.formBuilder.group({
            id: [null],
            tipoBenfeitoria: [null, Validators.required],
            capAlojamento: [null, Validators.required],
            capProducao: [null, Validators.required],
            descricao: [null, Validators.required],
        });
    }

    salvar(): void {
        const validate = this.validarInputs();
        const benfeitoria = new Benfeitoria();

        if (validate) {
            benfeitoria.idGeometria = this.idGeometria;
            benfeitoria.idTipoBenfeitoria = this.benfeitoriaForm.value.tipoBenfeitoria.id;
            benfeitoria.capAlojamento = this.benfeitoriaForm.value.capAlojamento ? Number(this.benfeitoriaForm.value.capAlojamento) : null;
            benfeitoria.capProducao = this.benfeitoriaForm.value.capProducao ? Number(this.benfeitoriaForm.value.capProducao) : null;
            benfeitoria.descricao = this.benfeitoriaForm.value.descricao;
            this.benfeitoriaService.createBenfeitoria(benfeitoria).subscribe(() => {
                this.snackBar.showSuccess('Benfeitoria salvo com sucesso.');
                this.benfeitoriaForm.reset();
                this.mostraDescricao = false;
                this.benfeitoriaForm.get('descricao').disable();
            });
        } else {
            this.snackBar.showError('Campo(s) obrigatório(s) não' +
                ' preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }


    exibirInputs(tipo: any): void {
        this.exibirCapAlojamento(tipo);
        this.exibirCapProducao(tipo);

        if (tipo.descricao === 'Outros') {
            this.benfeitoriaForm.get('descricao').enable();
            this.mostraDescricao = true;
        } else {
            this.benfeitoriaForm.get('descricao').disable();
            this.mostraDescricao = false;
        }
    }


    exibirCapAlojamento(tipo: any): void {
        if (tipo.descricao === 'Fábrica de ração' || tipo.descricao === 'Depósito de ração' ||
            tipo.descricao === 'Galpão de insumos' || tipo.descricao === 'Esterqueira' ||
            tipo.descricao === 'Silo' ||
            tipo.descricao === 'Deposito de embalagens vazias de defensivo agrícola' ||
            tipo.descricao === 'Reservatório de água' || tipo.descricao === 'Laboratório' ||
            tipo.descricao === 'Fábrica de Gelo' || tipo.descricao === 'Frigorífico') {
            this.benfeitoriaForm.get('capAlojamento').disable();
            this.mostrarCapAlojamento = false;
        } else {
            this.benfeitoriaForm.get('capAlojamento').enable();
            this.mostrarCapAlojamento = true;
        }
    }

    exibirCapProducao(tipo: any): void {
        if (tipo.descricao === 'Prédio Incubatório' ||
            tipo.descricao === 'Fábrica de ração' ||
            tipo.descricao === 'Silo' ||
            tipo.descricao === 'Fábrica de Gelo' ||
            tipo.descricao === 'Frigorífico') {
            this.benfeitoriaForm.get('capProducao').enable();
            this.mostrarCapProducao = true;
        } else {
            this.benfeitoriaForm.get('capProducao').disable();
            this.mostrarCapProducao = false;
        }
    }

    validarInputs(): boolean {
        if (this.benfeitoriaForm.valid) {
            return true;
        }
        this.benfeitoriaForm.markAllAsTouched();
        return false;
    }

    retornaTipoConformeIdDaAtividade(idAtividade: number): void {

        // = “Piscicultura”
        if (idAtividade === 404) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Piscicultura” / Distinta
        } else if (idAtividade === 414) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // =  “Bovinocultura / Criação de Bovinos, Búfalos e caprinos”
        } else if (idAtividade === 3768) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // =  “Suinocultura” / Suinocultura (ciclo completo)
        } else if (idAtividade === 3882) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Laboratório de Alevinagem”
        } else if (idAtividade === 3985) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Avicultura” / Produção de pintos de um dia (Incubatório)
        } else if (idAtividade === 4024) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Avicultura” / Avicultura de corte
        } else if (idAtividade === 4087) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Piscicultura” / Convencional em tanques escavados
        } else if (idAtividade === 4135) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Suinocultura” / Suinocultura (crescimento e terminação)
        } else if (idAtividade === 4189) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // =  “Avicultura” / Produção de ovos (Postura)
        } else if (idAtividade === 4191) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Irrigação” / Obras de irrigação
        } else if (idAtividade === 4219) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // =  “Suinocultura” / Suinocultura (unidade de produção de leitões)
        } else if (idAtividade === 4237) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Piscicultura” / Tanques-rede
        } else if (idAtividade === 4239) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Produção de ovos férteis”
        } else if (idAtividade === 4434) {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(idAtividade).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
            // = “Outras Atividades”
        } else {
            this.benfeitoriaService.retornaTipoBenfeitoriaFiltrada(this.outraAtv).subscribe(tipoBenfeitoria => {
                this.tipoBenfeitoria = tipoBenfeitoria;
            });
        }
    }
}
