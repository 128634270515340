import {BaseModel} from '../base.model';
import {AtividadeArrecadacao} from './atividade-arrecadacao.model';
import {TipoCobrancaArrecadacao} from './tipo-cobranca-arrecadacao.model';
import {ReceitaNaturezaArrecadacao} from './receita-natureza-arrecadacao.model';
import {PoluicaoArrecadacao} from './poluicao-arrecadacao.model';
import {TipoPorteArrecadacao} from './tipo-porte-arrecadacao.model';
import {PrevisaoLegalArrecadacao} from './previsao-legal-arrecadacao.model';
import {ParametroCalculoArrecadacao} from './parametro-calculo-arrecadacao.model';
import {TipoTitulacaoArrecadacao} from './tipo-titulacao-arrecadacao.model';

export class AtividadeCalculoArrecadacao extends BaseModel {

    constructor(
        public id?: number,
        public atividade?: AtividadeArrecadacao,
        public poluicao?: PoluicaoArrecadacao,
        public tipoCobranca?: TipoCobrancaArrecadacao,
        public tipoTitulacao?: TipoTitulacaoArrecadacao,
        public tipoPorte?: TipoPorteArrecadacao,
        public previsaoLegal?: PrevisaoLegalArrecadacao,
        public receitaNatureza?: ReceitaNaturezaArrecadacao,
        public limiteUpf?: number,
        public calculo?: string,
        public parametroCalculoList?: ParametroCalculoArrecadacao[],
    ) {
        super();
    }

    static fromJson(json: any): AtividadeCalculoArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new AtividadeCalculoArrecadacao(
            json.id,
            AtividadeArrecadacao.fromJson(json.atividade),
            PoluicaoArrecadacao.fromJson(json.poluicao),
            TipoCobrancaArrecadacao.fromJson(json.tipoCobranca),
            TipoTitulacaoArrecadacao.fromJson(json.tipoTitulacao),
            TipoPorteArrecadacao.fromJson(json.tipoPorte),
            PrevisaoLegalArrecadacao.fromJson(json.previsaoLegal),
            ReceitaNaturezaArrecadacao.fromJson(json.receitaNatureza),
            json.limiteUpf,
            json.calculo,
            ParametroCalculoArrecadacao.fromJsons(json.parametroCalculoList),
        );
    }
}
