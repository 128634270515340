import {Injectable, Injector} from "@angular/core";
import {EmpreendimentoResiduosGerais} from "../../../../../../../shared/models/empreendimento-residuos-gerais.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ResiduosGeraisDto} from "../../../../../../../shared/models/residuos-gerais-dto.model";
import {BaseEmpreendimentoResiduosGeralService} from "../../../../../../../shared/services/base-empreendimento-residuos-geral.service";
import {environment} from "../../../../../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ResiduoGeralService extends BaseEmpreendimentoResiduosGeralService<EmpreendimentoResiduosGerais>{
    constructor(protected injector: Injector) {
        super(
            '/api/residuo-geral',
            injector,
            EmpreendimentoResiduosGerais.prototype,
            EmpreendimentoResiduosGerais.fromJson
        );
    }

    salvarResiduoGeral(residuosGeraisDto: ResiduosGeraisDto): Observable<ResiduosGeraisDto> {
        const url = this.urlResourceEmpreendimentoResiduosGeral;
        return this.http.post(url, residuosGeraisDto)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    buscarDominiosFiltrados(): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoResiduosGeral}/dominios-filtrados`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarPorId(idEmpreendimento: number): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoResiduosGeral}/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    buscarResiduosSolidosPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_RESIDUOS_GERAL}/api/residuos-solidos-residuos-geral/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarResiduoSolidoPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_RESIDUOS_GERAL}/api/residuos-solidos-residuos-geral/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoResiduosGeral}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );;
    }
}