import {BaseModel} from "../../../../shared/models/base.model";

export class HistoricoPrioridadeProcessoDTO extends BaseModel {

    constructor(
        public id?: number,
        public data?: Date,
        public cpf?: string,
        public nome?: string,
        public prioridades?: number[],
        public justificativa?: string
    ) {
        super();
    }

    static fromJson(json: any): HistoricoPrioridadeProcessoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new HistoricoPrioridadeProcessoDTO(
            json.id,
            json.data,
            json.cpf,
            json.nome,
            json.prioridades,
            json.justificativa
        );
    }
}