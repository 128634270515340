import {Injectable, Injector} from "@angular/core";
import {AutorizacaoQueimaControlada} from "../../../../../../../shared/models/autorizacao-queima-controlada.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseService} from "../../../../../../../shared/services/base.service";
import {Acai} from "../../../../../../../shared/models/acai.model";

@Injectable({
    providedIn: 'root'
})

export class AcaiService extends BaseService<Acai> {

    constructor(protected injector: Injector) {
        super(
            '/api/formulario-acai',
            injector,
            AutorizacaoQueimaControlada.prototype,
            AutorizacaoQueimaControlada.fromJson
        );
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}