import {Injectable, Injector} from '@angular/core';
import {SistemaTratamento} from "../models/sistema-tratamento.model";
import {BaseSistTratamento} from "./base-sist-tratamento.service";


@Injectable({
    providedIn: "root"
})
export class SistemaTratamentoService extends BaseSistTratamento<SistemaTratamento> {

    constructor(protected injector: Injector) {
        super(
            "/api/sist-tratamento",
            injector,
            SistemaTratamento.prototype,
            SistemaTratamento.fromJson
        );
    }
}
