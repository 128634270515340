import {TipoProcesso} from '../tipos-processo/tipo-processo.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {Pessoa} from '../../shared/models/pessoa.model';
import {TipoRequerimento} from '../tipos-requerimento/tipo-requerimento.model';
import {Processo} from '../../shared/models/processo.model';
import {TermoReferencia} from "../termos-referencia/termo-referencia.model";

export class Requerimento extends BaseModel {

    constructor(
        public id?: number,
        public chaveGeoPortal?: string,
        public numero?: string,
        public dataAbertura?: Date,
        public tipoProcesso?: TipoProcesso,
        public pessoa?: Pessoa,
        public concordouTermosResponsabilidade?: boolean,
        public situacaoProcesso?: string,
        public cpfCadastrante?: string,
        public dataAlteracao?: Date,
        public dataProtocoloProcesso?: Date,
        public expiracao?: number,
        public usuarioLogadoInteressado?: boolean,
        public numeroProcesso?: string,
        public tipoRequerimento?: TipoRequerimento,
        public processo?: Processo,
        public idTipoRequerimentoTifLas?: number,
        public termoReferencia?: TermoReferencia,
        public numeroProtocoloVinculado?: string,
        public idParametroObjetivo?: number,
        public desabilitarAtividades?: boolean,
        public dataExpiracaoRequerimento?: Date
    ) {
        super();
    }

    static situacoesRequerimento: BaseEnum[] = [
        {valor: 'EM_CADASTRO', descricao: 'Em cadastro'},
        {valor: 'AGUARDANDO_CONFERENCIA', descricao: 'Aguardando Conferência'},
        {valor: 'EM_CONFERENCIA', descricao: 'Em Conferência'},
        {valor: 'ENCAMINHADO_PARA_ARRECADACAO', descricao: 'Encaminhado para Arrecadaçao'},
        {valor: 'CHECKLIST_REPROVADO', descricao: 'Checklist Reprovado'},
        {valor: 'EM_CORRECAO_CADASTRO', descricao: 'Em Correção de Cadastro'},
        {valor: 'AGUARDANDO_EMISSAO_DE_TAXAS', descricao: 'Aguardando Emissão de Taxas'},
        {valor: 'CANCELADO_POR_SOLICITACAO_DO_CADASTRANTE', descricao: 'Cancelado por Solicitação do Cadastrante'},
        {
            valor: 'CANCELADO_POR_EXPIRACAO_DE_PRAZO_DE_CADASTRO',
            descricao: 'Cancelado por Expiração de Prazo de Cadastro'
        },
        {valor: 'CANCELADO_POR_FALTA_DE_PAGAMENTO', descricao: 'Cancelado por falta de Pagamento'},
        {valor: 'AGUARDANDO_PAGAMENTO_DE_TAXAS', descricao: 'Aguardando Pagamento de Taxas'},
        {valor: 'AGUARDANDO_CHECKLIST_DOCUMENTOS', descricao: 'Aguardando checklist dos Documentos'},
        {valor: 'ARQUIVADO_DEFINITIVAMENTE', descricao: 'Arquivado definitivamente'},
        {valor: 'ARQUIVADO_TEMPORARIAMENTE', descricao: 'Arquivado temporariamente'},
        {valor: 'CONCLUIDO', descricao: 'Concluído'},
        {valor: 'CANCELADO', descricao: 'Cancelado'},
    ];

    static situacoesProcesso: BaseEnum[] = [
        {valor: 'EM_CADASTRO', descricao: 'Em cadastro'},
        {valor: 'AGUARDANDO_CONFERENCIA', descricao: 'Aguardando Conferência'},
        {valor: 'EM_CONFERENCIA', descricao: 'Em Conferência'},
        {valor: 'ENCAMINHADO_PARA_ARRECADACAO', descricao: 'Encaminhado para Arrecadaçao'},
        {valor: 'CHECKLIST_REPROVADO', descricao: 'Checklist Reprovado'},
        {valor: 'EM_CORRECAO_CADASTRO', descricao: 'Em Correção de Cadastro'},
        {valor: 'AGUARDANDO_EMISSAO_DE_TAXAS', descricao: 'Aguardando Emissão de Taxas'},
        {valor: 'AGUARDANDO_ATRIBUICAO', descricao: 'Aguardando atribuição'},
        {valor: 'AGUARDANDO_ANALISE', descricao: 'Aguardando análise'},
        {valor: 'EM_ANALISE', descricao: 'Em análise'},
        {valor: 'AGUARDANDO_ACEITE_ATRIBUICAO', descricao: 'Aguardando o aceite da Atribuição pelos analistas'},
        {valor: 'CANCELADO_POR_SOLICITACAO_DO_CADASTRANTE', descricao: 'Cancelado por Solicitação do Cadastrante'},
        {
            valor: 'CANCELADO_POR_EXPIRACAO_DE_PRAZO_DE_CADASTRO',
            descricao: 'Cancelado por Expiração de Prazo de Cadastro'
        },
        {valor: 'CANCELADO_POR_FALTA_DE_PAGAMENTO', descricao: 'Cancelado por falta de Pagamento'},
        {valor: 'AGUARDANDO_PAGAMENTO_DE_TAXAS', descricao: 'Aguardando Pagamento de Taxas'},
        {valor: 'AGUARDANDO_CHECKLIST_DOCUMENTOS', descricao: 'Aguardando checklist dos Documentos'},
        {valor: 'ARQUIVADO_DEFINITIVAMENTE', descricao: 'Arquivado definitivamente'},
        {valor: 'ARQUIVADO_TEMPORARIAMENTE', descricao: 'Arquivado temporariamente'},
        {valor: 'CONCLUIDO', descricao: 'Concluído'},
        {valor: 'CANCELADO', descricao: 'Cancelado'},
        {valor: 'EM_REVISAO', descricao: 'Em revisão'},
        {valor: 'EM_CORRECAO', descricao: 'Em correção'},
        {valor: 'AGUARDANDO_REVISAO', descricao: 'Aguardando revisão'},
        {valor: 'AGUARDANDO_CORRECAO', descricao: 'Aguardando correção'},
    ];

    static fromJson(json: any): Requerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new Requerimento(
            json.id,
            json.chaveGeoPortal,
            json.numero,
            new Date(json.dataAbertura),
            TipoProcesso.fromJson(json.tipoProcesso),
            json.pessoa ? json.pessoa : null,
            json.concordouTermosResponsabilidade,
            json.situacaoProcesso,
            json.cpfCadastrante,
            new Date(json.dataAlteracao),
            new Date(json.dataProtocoloProcesso),
            json.expiracao,
            json.usuarioLogadoInteressado,
            json.numeroProcesso,
            TipoRequerimento.fromJson(json.tipoRequerimento),
            Processo.fromJson(json.processo),
            json.idTipoRequerimentoTifLas,
            TermoReferencia.fromJson(json.termoReferencia),
            json.numeroProtocoloVinculado,
            json.idParametroObjetivo,
            json.dataExpiracaoRequerimento
        );
    }

    static fromJsons(json: any): Requerimento[] {
        const lista: Requerimento[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}