import { Injectable, Injector } from '@angular/core';
import { RoutingStateService } from "../../core/services/routing-state.service";
import { MateriaPrimaMadeira } from '../models/materia-prima-madeira';
import { TipoMateriaPrimaMadeira } from '../models/tipo-madeira-materia-prima';
import { DetailResolver } from "../resolvers/detail-resolver";
import { ListResolver } from '../resolvers/list-resolver';
import { BaseGeralMadeira } from './base-geral-madeira.service';
import { BaseService } from "./base.service";
import {Observable} from "rxjs";
import {Madeira} from "../models/madeira";
import {catchError, map} from "rxjs/operators";


@Injectable()
export class MateriaPrimaMadeiraListResolver extends ListResolver<MateriaPrimaMadeira> {
    constructor(service: MateriaPrimaMadeiraService) {
        super(service);
    }
}

@Injectable()
export class MateriaPrimaMadeiraResolver extends DetailResolver<MateriaPrimaMadeira> {
    constructor(
        service: MateriaPrimaMadeiraService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class MateriaPrimaMadeiraService extends BaseGeralMadeira<MateriaPrimaMadeira> {

    constructor(protected injector: Injector) {
        super(
            "/api/serv-geral-materia-prima-madeira",
            injector,
            MateriaPrimaMadeira.prototype,
            MateriaPrimaMadeira.fromJson
        );
    }

    getByServicoGeralMadeira(idServGeralMadeira: number): Observable<Array<MateriaPrimaMadeira>> {
        const url = `${this.urlResource}/servico-geral-madeira/${idServGeralMadeira}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
