import {Component, Injector, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';
import {RequerimentoOutorgaFinalidadeService} from '../../../services/requerimento-outorga-finalidade.service';
import {BaseModel} from '../../../models/base.model';
import {CultivoTanqueRede} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-rede/models/cultivo-tanque-rede.model';
import {CultivoTanqueRedeService} from 'app/main/pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-rede/services/cultivo-tanque-rede.service';
import {AquiculturaTanqueRede} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-rede/models/aquicultura-tanque-rede.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-aba-finalidade-tanque-rede',
    templateUrl: 'aba-finalidade-tanque-rede.component.html'
})
export class AbaFinalidadeTanqueRedeComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;

    cultivoRedeArray: Array<CultivoTanqueRede>;
    displayedColumns: string[] = ['areaCultivo', 'producao', 'conversaoAlimentar', 'ciclos'];
    aquiculturaTanqueRede: AquiculturaTanqueRede;

    constructor(injector: Injector,
                private dialog: MatDialog,
                private cultivoRedeService: CultivoTanqueRedeService,
                private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
                private snackBarService: SnackBarService,
    ) {
    }

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    ngOnInit(): void {
        this.aquiculturaTanqueRede = this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede;
        this.carregarAquiculturaRededoDoRequerimento();
    }

    private carregarAquiculturaRededoDoRequerimento(): void {
        this.cultivoRedeService
            .getAllByCultivoTanqueRede(
                this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede
            )
            .subscribe(cultivoRede => {
                this.cultivoRedeArray = cultivoRede;
            }, (e) => this.snackBarService.showError('Erro ao carregar lista cultivo tanque rede.', e));
    }

    public getTotalAreaCultivo(): number {
        if (this.cultivoRedeArray) {
            const soma: number = this.cultivoRedeArray.reduce(
                (s, current) => s + current.areaCultivo, 0);
            return soma;
        }
        return 0;
    }

    public getTotalProducao(): number {
        if (this.cultivoRedeArray) {
            const soma = this.cultivoRedeArray.reduce(
                (total, current) => total + current.producao,
                0
            );
            return soma;
        }
        return 0;
    }

    public getTotalConservacaoAlimentar(): number {
        if (this.cultivoRedeArray) {
            const soma = this.cultivoRedeArray.reduce(
                (total, current) => total + current.conversaoAlimentar,
                0
            );
            return soma;
        }
        return 0;
    }

    public getTotalCiclos(): number {
        if (this.cultivoRedeArray) {
            const soma = this.cultivoRedeArray.reduce(
                (total, current) => total + current.ciclos,
                0
            );
            return soma;
        }
        return 0;
    }

}
