import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AquiculturaTanqueRede} from '../models/aquicultura-tanque-rede.model';
import {CultivoTanqueRede} from '../models/cultivo-tanque-rede.model';

@Injectable({
    providedIn: 'root'
})
export class CultivoTanqueRedeService extends BaseService<CultivoTanqueRede> {
    constructor(protected injector: Injector) {
        super(
            '/api/cultivo-tanque-rede',
            injector,
            CultivoTanqueRede.prototype,
            CultivoTanqueRede.fromJson
        );
    }

    getAllByCultivoTanqueRede(aquiculturaTanqueRede: AquiculturaTanqueRede): Observable<CultivoTanqueRede[]> {
        const url = `${this.urlResource}/aquitetura-tanque-rede/${aquiculturaTanqueRede.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    private jsonDataToResourceList(jsonData: any[]): CultivoTanqueRede[] {
        const entries: CultivoTanqueRede[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new CultivoTanqueRede(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

}
