import {BaseModel} from 'app/main/shared/models/base.model';
import {EmpreendimentoComponenteSubestacao} from "./empreendimento-componente-subestacao.model";
import {EmpreendimentoFuncao} from "./empreendimento-funcao.model";
import {EmpreendimentoEstruturaSuporte} from "./empreendimento-estrutura-suporte.model";
import {EmpreendimentoMeioIsolacao} from "./empreendimento-meio-isolacao.model";
import {EmpreendimentoMeioIsolamento} from "./empreendimento-meio-isolamento.model";
import {EmpreendimentoSubestacaoInterna} from "./empreendimento-subestacao-interna.model";

export class EmpreendimentoSubEstacao extends BaseModel {
    constructor(
        public id?: number,
        public derivaEnergiaLocal?: boolean,
        public nomeRazaoSocial?: string,
        public cnpjCpf?: string,
        public licencaAmbiental?: string,
        public tipoConstrutivo?: number,
        public possuiSubestacaoOperacaoPresencial?: boolean,
        public possuiSubEstacaoSupervisionada?: boolean,
        public possuiSubestacaoExterna?: boolean,
        public possuiSubestacaoInterna?: boolean,
        public possuiSubestacaoTerrea?: boolean,
        public possuiSubestacaoAerea?: boolean,
        public componentesSubestacao?: Array<EmpreendimentoComponenteSubestacao>,
        public estruturasSuporte?: Array<EmpreendimentoEstruturaSuporte>,
        public funcoes?: Array<EmpreendimentoFuncao>,
        public meiosIsolacao?: Array<EmpreendimentoMeioIsolacao>,
        public meiosIsolamento?: Array<EmpreendimentoMeioIsolamento>,
        public subestacoesInternas?: Array<EmpreendimentoSubestacaoInterna>,
        public cadastro?: Date,
        public requerimento?: number,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoSubEstacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoSubEstacao(
            json.id,
            json.derivaEnergiaLocal,
            json.nomeRazaoSocial,
            json.cnpjCpf,
            json.licencaAmbiental,
            json.tipoConstrutivo,
            json.possuiSubestacaoOperacaoPresencial,
            json.possuiSubEstacaoSupervisionada,
            json.possuiSubestacaoExterna,
            json.possuiSubestacaoInterna,
            json.possuiSubestacaoTerrea,
            json.possuiSubestacaoAerea,
            EmpreendimentoComponenteSubestacao.fromJsons(json.componentesSubestacao),
            EmpreendimentoEstruturaSuporte.fromJsons(json.estruturasSuporte),
            EmpreendimentoFuncao.fromJsons(json.funcoes),
            EmpreendimentoMeioIsolacao.fromJsons(json.meiosIsolacao),
            EmpreendimentoMeioIsolamento.fromJsons(json.meiosIsolamento),
            EmpreendimentoSubestacaoInterna.fromJsons(json.subestacoesInternas),
            json.cadastro,
            json.requerimento
        );
    }

}
