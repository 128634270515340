import {BaseRelacionamentoModel} from "./base-relacionamento.model";

export class SistemaTratamentoSecundario extends BaseRelacionamentoModel {
    constructor(
        public id: number,
        public idDominio: number,
        public idEntidade: number,
        public idSubDominio: number,
        public descricao: string
    ) {
        super(id, idDominio, idEntidade, descricao, idSubDominio);
    }

    static fromJson(json: any): SistemaTratamentoSecundario {
        if (json === undefined || json === null) {
            return null;
        }
        return new SistemaTratamentoSecundario(
            json.id,
            json.idDominio,
            json.idEntidade,
            json.idSubDominio,
            json.descricao
        );
    }

}
