import {Component, Inject} from '@angular/core';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GeometriaMapa} from '@sema-geo/sema-geoportal';
import {
    GeoportalBaseReferenciaService
} from "../../../../../shared/services/geoportal-base-referencia/geoportal-base-referencia.service";
import {Observable} from "rxjs";
import {statusGeometriaLabel} from "../../../../../shared/enums/status-geometria.enum";
import {
    AnaliseFeicaoRequerimentoLicenciamentoService
} from "../../../../../shared/services/analise-feicao-requerimento-licenciamento.service";

export interface  DialogAprovarGeometria {
    idRequerimentoLicenciamento: number;
    geometria: GeometriaMapa;
}

@Component({
    selector: 'app-bpm-dialog-aprovar-geometria',
    templateUrl: './dialog-aprovar-geometria.component.html',
    styleUrls: ['./dialog-aprovar-geometria.component.scss'],
})
export class DialogAprovarGeometriaComponent {
    geometria: GeometriaMapa;
    area$: Observable<string>
    protected readonly statusGeometriaLabel = statusGeometriaLabel;
    constructor(
        private snackBarService: SnackBarService,
        private dialogRef: MatDialogRef<DialogAprovarGeometriaComponent>,
        private readonly baseReferenciaService: GeoportalBaseReferenciaService,
        private readonly analiseFeicaoRequerimentoLicenciamentoService: AnaliseFeicaoRequerimentoLicenciamentoService,
        @Inject(MAT_DIALOG_DATA) private data: DialogAprovarGeometria
    ) {
        this.geometria = data.geometria;
        this.area$ = this.baseReferenciaService.getArea(this.geometria.wkt);
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onAprovar(){
        this.analiseFeicaoRequerimentoLicenciamentoService.aprovarGeometria(
            this.data.idRequerimentoLicenciamento,
            this.geometria.extra.id
        ).subscribe({
            next: () => {
                this.dialogRef.close({
                    success: true,
                });
            }
        })
    }
}
