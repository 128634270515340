import {Predicate, Type} from '@angular/core';
import {Ponto} from '../../../../shared/models/hidrico-models/ponto.model';
import {TitulacaoCaptacaoInsignificanteComponent} from './titulacao-captacao-insignificante/titulacao-captacao-insignificante.component';
import {ObjetivoObrasHidraulicasComponent} from './objetivo-obras-hidraulicas/objetivo-obras-hidraulicas.component';
import {ObjetivoLancamentosEfluentesComponent} from './objetivo-lancamentos-efluentes/objetivo-lancamentos-efluentes.component';
import {ObjetivoCaptacaoSuperficialComponent} from './objetivo-captacao-superficial/objetivo-captacao-superficial.component';
import {ObjetivoTamponamentoPocoTubularComponent} from './objetivo-tamponamento-poco-tubular/objetivo-tamponamento-poco-tubular.component';
import {ObjetivoPerfuracaoPocoTubularComponent} from './objetivo-perfuracao-poco-tubular/objetivo-perfuracao-poco-tubular.component';
import {BasePontoComponent} from './base-ponto.component';
import {PredicateResolver} from './predicate.resolver';
import {ObjetivoObraBarragemComponent} from './objetivo-obra-barragem/objetivo-obra-barragem.component';
import {ObjetivoAguaSubterraneaComponent} from './objetivo-agua-subterranea/objetivo-agua-subterranea.component';
import {ObjetivoAguaSubterraneaIrrigacaoComponent} from './objetivo-agua-subterranea-irrigacao/objetivo-agua-subterranea-irrigacao.component';
import {ObjetivoBarragemExistenteComponent} from './objetivo-barragem-existente/objetivo-barragem-existente.component';
import {ObjetivoPocoIrrigacaoComponent} from './objetivo-poco-irrigacao/objetivo-poco-irrigacao.component';
import {ObjetivoDrdhComponent} from "./objetivo-drdh/objetivo-drdh.component";

function byFormularioObjetivo(formularioObjetivo: string, type: Type<BasePontoComponent>): PredicateResolver<Ponto, Type<BasePontoComponent>> {
    return {predicate: p => p.objetivoRequerimento.objetivo.formulario === formularioObjetivo, resolved: type};
}

function byFormularioObjetivoAnd(formularioObjetivo: string, and: Predicate<Ponto>, type: Type<BasePontoComponent>): PredicateResolver<Ponto, Type<BasePontoComponent>> {
    const aux = byFormularioObjetivo(formularioObjetivo, type);
    return {predicate: p => aux.predicate(p) && and(p), resolved: type};
}

export class PontoFormulario {
    private static predicates: PredicateResolver<Ponto, Type<BasePontoComponent>>[] = [
        byFormularioObjetivoAnd('CAPTACAO_SUPERFICIAL', p => !!p.titulacao.formulario, TitulacaoCaptacaoInsignificanteComponent),
        byFormularioObjetivo('OBRAS_HIDRAULICAS', ObjetivoObrasHidraulicasComponent),
        byFormularioObjetivo('LANCAMENTO_EFLUENTE', ObjetivoLancamentosEfluentesComponent),
        byFormularioObjetivoAnd('CAPTACAO_SUPERFICIAL', p => !p.titulacao.formulario, ObjetivoCaptacaoSuperficialComponent),
        byFormularioObjetivo('TAMPONAMENTO', ObjetivoTamponamentoPocoTubularComponent),
        byFormularioObjetivo('PERFURACAO', ObjetivoPerfuracaoPocoTubularComponent),
        byFormularioObjetivo('BARRAGEM_EXISTENTE', ObjetivoBarragemExistenteComponent),
        byFormularioObjetivo('PERF_POCO_IRRIGACAO', ObjetivoPocoIrrigacaoComponent),
        byFormularioObjetivo('CLASSIFICACAO_BARRAGEM', ObjetivoObraBarragemComponent),
        byFormularioObjetivo('DRDH', ObjetivoDrdhComponent),
        byFormularioObjetivo('CAP_SUB_IRRIGACAO', ObjetivoAguaSubterraneaIrrigacaoComponent),
        byFormularioObjetivo('AGUA_SUBTERRANEA', ObjetivoAguaSubterraneaComponent)
    ];

    static get(ponto: Ponto): PredicateResolver<Ponto, Type<BasePontoComponent>> {
        return this.predicates.find(r => r.predicate(ponto));
    }

}
