import {Injectable, Injector} from '@angular/core';
import {AguaPassivoSupressao} from "../models/agua-passiva-supressao.model";
import {BaseAguaPassivoSupressao} from "./base-agua-passivo-supressao.service";


@Injectable({
    providedIn: "root"
})
export class AguaPassivoSupressaoService extends BaseAguaPassivoSupressao<AguaPassivoSupressao> {

    constructor(protected injector: Injector) {
        super(
            "/api/agua-passivo-supressao",
            injector,
            AguaPassivoSupressao.prototype,
            AguaPassivoSupressao.fromJson
        );
    }
}
