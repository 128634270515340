import {Finalidade} from "../../pages/finalidades/finalidade.model";

export class FiltroObjetivoFinalidadeDTO {

    constructor(
        public idObjetivo?: number,
        public idsFinalidade?: number[]
    ) {
    }

    public permiteFinalidade(finalidade: Finalidade): boolean {
        return this.idsFinalidade.some(id => id == finalidade.id);
    }

    static fromJson(json: any): FiltroObjetivoFinalidadeDTO {
        if (json === undefined || json === null) {
            return null;
        }

        return new FiltroObjetivoFinalidadeDTO(
            json.idObjetivo ? json.idObjetivo : null,
            json.idsFinalidade ? json.idsFinalidade : null
        );
    }

    static fromJsons(json: any): FiltroObjetivoFinalidadeDTO[] {
        const array: FiltroObjetivoFinalidadeDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(FiltroObjetivoFinalidadeDTO.fromJson(item)));
            return array;
        }
    }
}
