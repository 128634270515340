import {Component, Input} from '@angular/core';
import { DocumentoRequerimentoService } from 'app/main/pages/documentos-requerimentos/documento-requerimento.service';
import { DocumentoProcessoService } from 'app/main/pages/gerar-documento-publicacao/documento-processo.service';
import {Ponto} from '../../../models/hidrico-models/ponto.model';

@Component({
    selector: 'aba-objetivo-agua-sub-irrigacao',
    templateUrl: './aba-objetivo-agua-sub-irrigacao.component.html'
})

export class AbaObjetivoAguaSubIrrigacaoComponent {

    @Input() ponto: Ponto;

    constructor(
        private documentoRequerimentoService: DocumentoRequerimentoService,
    ) {
    }

    public downloadDocumento(id: any): void {
        window.open(`${this.documentoRequerimentoService.urlResource}/${id}/download`);
    }

}


