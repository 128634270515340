import {BaseModel} from 'app/main/shared/models/base.model';

export class Crematorio extends BaseModel {
    constructor(
        public id: number,
        public equipamentoRefrigeracao: boolean,
        public areaDesmembramentoCarcaca: boolean,
        public sistemaLavagem: boolean,
        public tempoCremacao: number,
        // public formaAlimentacaoCombustivel: string,
        public tipoCombustivel: string,
        public descricaoTipoCombustivel: string,
        public tipoEquipamentoTratamentoTermico: string,
        public capacidadeNominal: number,
        public numeroCarregamentos: number,
        public camaraPosCombustao: boolean,
        public monitoramentoConinuo: boolean,
        public humano: boolean,
        public animal: boolean
    ) {
        super();
    }

    static fromJson(json: any): Crematorio {
        if (json === undefined || json === null) {
            return null;
        }
        return new Crematorio(
            json.id,
            json.equipamentoRefrigeracao,
            json.areaDesmembramentoCarcaca,
            json.sistemaLavagem,
            json.tempoCremacao,
            // json.formaAlimentacaoCombustivel,
            json.tipoCombustivel,
            json.descricaoTipoCombustivel,
            json.tipoEquipamentoTratamentoTermico,
            json.capacidadeNominal,
            json.numeroCarregamentos,
            json.camaraPosCombustao,
            json.monitoramentoConinuo,
            json.humano,
            json.animal
        );
    }

    static fromJsons(json: any): Crematorio[] {
        const crematorios: Crematorio[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(crematorio => crematorios.push(this.fromJson(crematorio)));
            return crematorios;
        }
    }

}
