import {Component, Injector, Input, OnInit} from '@angular/core';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {AuthenticationService} from 'app/main/core/services/authentication.service';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {DialogPesquisaProcessoComponent} from 'app/main/shared/components/dialog-pesquisa-processo/dialog-pesquisa-processo.component';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {Requerimento} from '../../requerimento.model';
import {VinculacaoProcessoDto} from './vinculacao-processo.dto';
import {VinculacaoProcessoService} from './vinculacao-processo.service';
import {VinculacaoProcesso} from './model/vinculacao-processo.model';

@Component({
    selector: 'app-vinculacao-processo',
    templateUrl: './vinculacao-processo.component.html',
})
export class VinculacaoProcessoComponent implements OnInit {

    @Input() requerimento: Requerimento;
    @Input() isSomenteVisualizacao: boolean;

    juntadaProcessos = new MatTableDataSource<VinculacaoProcesso>();

    vinculacaoProcessoDto: VinculacaoProcessoDto = null;

    constructor(
        protected injector: Injector,
        protected juntadaProcessoService: VinculacaoProcessoService,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.loadJuntadaProcessos();
    }

    loadJuntadaProcessos(): void {
        this.juntadaProcessoService.findByRequerimentoPrincipal(this.requerimento.id).subscribe((result) => {
            this.juntadaProcessos.data = result;
        }, error => {
            this.snackBarService.showError('Erro ao carregar processos vinculados.', error);
        });
    }

    desvincularProcessoDialog(id: number): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.desvincularProcesso(id);
            }
        });
    }

    desvincularProcesso(id: number): void {

        this.juntadaProcessoService.delete(id).subscribe(() => {
                this.snackBarService.showSuccess('Registro excluido com sucesso!');
                this.loadJuntadaProcessos();
            },
            (e) => this.snackBarService.showError('Não foi possível excluir o registro.', e)
        );
    }

    associarRequerimento(requerimentoJuntada: Requerimento): void {
        if (requerimentoJuntada != null && !this.isProcessoVinculado(requerimentoJuntada.numeroProcesso)) {

            if (this.requerimento.numeroProcesso !== requerimentoJuntada.numeroProcesso) {
                this.vinculacaoProcessoDto = new VinculacaoProcessoDto(this.requerimento.id, requerimentoJuntada.id);
                this.juntadaProcessoService.associarRequerimento(this.vinculacaoProcessoDto).subscribe(() => {
                    this.loadJuntadaProcessos();
                    this.snackBarService.showSuccess('Registro salvo com sucesso.');
                }, error => {
                    this.snackBarService.showError('Erro ao vincular processos.', error);
                });
            } else {
                this.snackBarService.showError('Erro ao vincular processo! Verifique o número digitado');
            }
        } else {
            this.snackBarService.showError('Este requerimento já foi associado ao processo.');
        }
    }

    abrirDialogPesquisaProcesso(): void {
        const dialogRef = this.dialog.open(DialogPesquisaProcessoComponent, {
            width: '900px',
            maxHeight: '450px',
            data: this.requerimento
        });
        dialogRef.afterClosed().subscribe(requerimentoResult => {
            if (requerimentoResult) {
                this.associarRequerimento(requerimentoResult);
            }
        });
    }

    isProcessoVinculado(numeroProcesso: string): boolean {
        return this.juntadaProcessos.data.some(juntadaProcesso => juntadaProcesso.numeroProcesso === numeroProcesso);
    }

    get servidor(): boolean {
        return this.authenticationService.currentUserDecode.servidor;
    }

}
