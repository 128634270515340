import {BaseModel} from 'app/main/shared/models/base.model';

export class MateriaPrimaMadeira extends BaseModel {
    constructor(
        public id?: number,
        public idMadeira?: number,
        public descricao?: string,
        public volume?: number,
        public idUnidadeMedida?: number,
        public idServGeralMadeira?: number
    ) {
        super();
    }

    static fromJson(json: any): MateriaPrimaMadeira {
        if (json === undefined || json === null) {
            return null;
        }
        return new MateriaPrimaMadeira(
            json.id,
            json.idMadeira,
            json.descricao,
            json.volume,
            json.idUnidadeMedida,
            json.idServGeralMadeira
        );
    }

    static fromJsons(json: any): MateriaPrimaMadeira[] {
        const MateriaPrimaMadeiras: MateriaPrimaMadeira[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(MateriaPrimaMadeira => MateriaPrimaMadeiras.push(this.fromJson(MateriaPrimaMadeira)));
            return MateriaPrimaMadeiras;
        }
    }

}
