import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {PontoService} from "../../../../../../shared/services/ponto.service";
import {DadoMeteorologico} from "../../../../../../shared/models/hidrico-models/dado-meteorologico.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA} from "@angular/material";
import {Ponto} from "../../../../../../shared/models/hidrico-models/ponto.model";
import {SnackBarService} from "../../../../../../shared/snack-bar/snack-bar.service";
import {DadosHidrometeorologicosService} from "../../../../../../shared/services/dados-hidrometeorologicos.service";
import {SelectSearchMode} from "../../../../../../shared/components/select-search/select-search.component";
import {isNullOrUndefined} from "util";

@Component({
    selector: 'app-dialog-adicionar-dado-hidrometeorologico',
    templateUrl: './dialog-adicionar-dado-hidrometeorologico.component.html',
    styleUrls: ['./dialog-adicionar-dado-hidrometeorologico.component.scss']
})
export class DialogAdicionarDadoHidrometeorologicoComponent implements OnInit {
    SERVER_SIDE = SelectSearchMode.SERVER_SIDE;

    tiposEstacao = DadoMeteorologico.tiposEstacao;

    formPesquisa = new FormGroup({
        tipo: new FormControl(null, Validators.required),
        dadoSelecionado: new FormControl(null)
    });

    form = new FormGroup({
        tipo: new FormControl({value: null, disabled: true}, Validators.required),
        estacao: new FormControl(null, Validators.required),
        codigo: new FormControl({value: null, disabled: true}, Validators.required),
        rio: new FormControl(null),
        areaDrenagem: new FormControl(null, [Validators.min(0.000001)]),
    });

    dadosPesquisa: DadoMeteorologico[] = [];

    constructor(
        public dialogRef: MatDialogRef<DialogAdicionarDadoHidrometeorologicoComponent>,
        @Inject(MAT_DIALOG_DATA) public ponto: Ponto,
        public pontoService: PontoService,
        public dadosHidrometeorologicosService: DadosHidrometeorologicosService,
        public snackBarService: SnackBarService
    ) {
    }

    ngOnInit() {
        this.formPesquisa.get('dadoSelecionado').valueChanges.subscribe(value => this.selecionarDado(value));
        this.formPesquisa.get('tipo').valueChanges.subscribe(value => {
            this.dadosPesquisa = [];
            this.selecionarDado(null)
        });
    }

    adicionar() {
        this.form.markAllAsTouched()
        if (!this.form.invalid) {
            const dado = DadoMeteorologico.fromJson(this.form.getRawValue());
            dado.ponto = this.ponto;
            this.dadosHidrometeorologicosService.adicionarDadoHidrometeorologico(dado).subscribe(result => {
                    this.dialogRef.close(result)
                },
                e => this.snackBarService.showError('Erro ao adicionar dado meteorológico', e))
        } else {
            this.snackBarService.showAlert('Preencha todos os campos corretamente')
        }
    }

    pesquisar(textoPesquisa: string) {
        this.formPesquisa.markAllAsTouched()
        if (this.formPesquisa.valid) {
            const formValue = this.formPesquisa.value;
            this.dadosHidrometeorologicosService.pesquisarHidrometeorologico(formValue.tipo, textoPesquisa).subscribe(result => {
                    this.dadosPesquisa = result;
                    if (this.dadosPesquisa.length == 0)
                        this.snackBarService.showAlert('Não foi encontrado nenhuma fonte de dados')
                },
                e => this.snackBarService.showError('Erro ao pesquisar dado', e))
        } else {
            this.snackBarService.showAlert('Selecione o tipo')
        }
    }

    selecionarDado(item: DadoMeteorologico) {
        if (isNullOrUndefined(item)) {
            this.form.patchValue({
                estacao: null,
                rio: null,
                areaDrenagem: null
            })
            this.form.controls.estacao.enable();
            this.form.controls.rio.enable();
            this.form.controls.areaDrenagem.enable();
        } else {
            this.form.patchValue(item);
            if (item.estacao) {
                this.form.controls.estacao.disable();
            } else {
                this.form.controls.estacao.enable();
            }
            if (item.rio) {
                this.form.controls.rio.disable();
            } else {
                this.form.controls.rio.enable();
            }
            if (item.areaDrenagem) {
                this.form.controls.areaDrenagem.disable();
            } else {
                this.form.controls.areaDrenagem.enable();
            }
        }
    }

}
