import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ResponsavelDialogComponent} from './dialog-responsavel/responsavel-dialog-component';
import {RepresentateDialogComponent} from './dialog-representate/representate-dialog-component';
import {Requerimento} from '../../../pages/requerimentos/requerimento.model';
import {RequerimentoService} from '../../../pages/requerimentos/requerimento.service';
import {ResponsavelTecnico} from '../../models/responsavel-tecnico.model';
import {DialogHistoricoRtComponent} from '../../../pages/requerimentos/shared/alteracao-responsavel-tecnico/historico-rt/dialog-historico-rt.component';
import {fuseAnimations} from '../../../../../@fuse/animations';

@Component({
    selector: 'app-aba-responsaveis',
    templateUrl: 'aba-responsaveis.component.html',
    styleUrls: ['aba-responsaveis.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AbaResponsaveisComponent implements OnInit {
    @Input() requerimento = new Requerimento();

    responsaveisTecnicos: ResponsavelTecnico[] = [];
    representantesLegal: ResponsavelTecnico[] = [];


    constructor(
        protected dialog: MatDialog,
        protected requerimentoService: RequerimentoService,
    ) {
    }

    ngOnInit(): void {
        if (this.requerimento) {
            this.listarResponsavelLegal();
            this.listarResponsaveisTecnicos();
        }
    }

    listarResponsavelLegal(): void {
        this.requerimentoService
            .listarRepresentantesLegais(this.requerimento.id).subscribe((content) => {
            this.representantesLegal = content;
        });
    }

    listarResponsaveisTecnicos(): void {
        this.requerimentoService
            .listarResponsaveisTecnicos(this.requerimento.id).subscribe((content) => {
            this.responsaveisTecnicos = content;
        });
    }

    openDialogReseponsavel(responsavelTecnico: any): void {
        this.dialog.open(ResponsavelDialogComponent, {
                width: '1800px',
                maxHeight: '535px',
                data: {requerimento: this.requerimento, responsavelTecnico: responsavelTecnico}
            }
        );
    }

    openDialogRepresentante(representanteLegal: any): void {
        this.dialog.open(RepresentateDialogComponent, {
                width: '1800px',
                maxHeight: '535px',
                data: {requerimento: this.requerimento, representanteLegal: representanteLegal}
            }
        );
    }

    openHistoricoDialog(): void {
        this.requerimentoService.listHistoricoAlteracaoRt(this.requerimento.id).subscribe(result => {
                this.dialog.open(DialogHistoricoRtComponent, {
                    width: '1800px',
                    maxHeight: '600px',
                    data: {historico: result}
                });
            }
        );

    }

}
