import {Component, OnInit} from '@angular/core';
import {DominiosService} from "../../../../../shared/services/dominios.service";
import {EmissaoTitulo} from "../../../../../shared/models/emissao-titulo.model";
import {EmissaoTituloService} from "../../../../tarefas/shared/service/emissao-titulo.service";
import {Requerimento} from "../../../requerimento.model";
import {RequerimentoService} from "../../../requerimento.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DocumentoProcesso} from "../../../../../shared/models/documento-processo.model";
import {SnackBarService} from "../../../../../shared/snack-bar/snack-bar.service";
import {ConfiguracaoAvisosAutomaticosService} from "../../../../../shared/services/configuracao-avisos-automaticos.service";
import {EnumSituacaoTitulo} from "../../../../../shared/enums/EnumSituacaoTitulo";
import {OutrosAtosInternosService} from "./outros-atos-internos.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogConfirmacaoComponent} from "../../../../../shared/components/dialog-confirmacao/dialog-confirmacao.component";
import {RedirectUtil} from "../../../../../shared/util/RedirectUtil";
import {TemplateService} from "../../../../templates/template.service";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {SemaAssinadorEletronico} from "@sema-assinatura/sema-assinatura-eletronico";
import {AssinadorEletronicoService} from "../../../../../shared/services/assinador-eletronico.service";
import {DocumentoProcessoService} from "../../../../tarefas/shared/service/documento-processo.service";
import {RegistrarDecisaoDocumentoDto} from "./registrar-decisao-documento-dto.model";

@Component({
    selector: 'app-outros-atos-internos',
    templateUrl: './outros-atos-internos.component.html',
    styleUrls: ['./outros-atos-internos.component.scss']
})
export class OutrosAtosInternosComponent implements OnInit {
    tiposSolicitacao: any
    templates: any
    requerimento: Requerimento
    emissaoTitulo: EmissaoTitulo
    form: FormGroup
    formEmissao: FormGroup
    avisosAutomaticos: any
    avisoAutomatico = false;
    camposPassiveisEmissao = false
    habalitaAvancar = false
    idEmissaoTitulo: any
    idRequerimento: any
    modelo = false
    botaoGerar = true;
    botaoLimpar = true;
    htmlDocumentoTexto: any;

    constructor(private dominioService: DominiosService,
                private emissaoTituloService: EmissaoTituloService,
                private requerimentoService: RequerimentoService,
                private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private configuracaoAvisosAutomaticosService: ConfiguracaoAvisosAutomaticosService,
                private outrosAtosInternosService: OutrosAtosInternosService,
                private dialog: MatDialog,
                private templateService: TemplateService,
                private route: ActivatedRoute,
                private assinadorEletronicoService: AssinadorEletronicoService,
                private semaAssinadorEletronico: SemaAssinadorEletronico,
                private documentoProcessoService: DocumentoProcessoService) {
    }

    async ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.idRequerimento = +params.get('idRequerimento')
            this.idEmissaoTitulo = +params.get('idEmissaoTitulo')
        })
        this.construirForm()
        this.construirFormEmissao()
        await this.buscaReq()
        await this.carregarEmissao()
        this.carregarTiposSolicitacao()
        this.carregarTemplates()
    }

    carregarTiposSolicitacao() {
        this.dominioService.getDominio('TIPO_SOLICITACAO_ATOS_INTERNOS').subscribe(tp => {
            this.tiposSolicitacao = tp;

            for (let tipo of this.tiposSolicitacao) {
                if (this.emissaoTitulo.situacaoTitulo === 'ATIVO' && tipo.id == 1 ||
                    this.emissaoTitulo.situacaoTitulo === 'SUSPENSO' && tipo.id == 1) {
                    tipo['habilitar'] = true
                }
                if (this.emissaoTitulo.situacaoTitulo === 'ATIVO' && tipo.id == 2) {
                    tipo['habilitar'] = true
                }
                if (this.emissaoTitulo.situacaoTitulo === 'SUSPENSO' && tipo.id == 3) {
                    tipo['habilitar'] = true
                }
                if (tipo.id === 4 && this.emissaoTitulo.situacao === 'VIGENTE' && this.emissaoTitulo.situacaoTitulo === 'ATIVO') {
                    tipo['habilitar'] = true
                }
                if (tipo.id === 3 && this.emissaoTitulo.situacao === 'CANCELADO' && this.emissaoTitulo.situacaoTitulo === 'CANCELADO') {
                    tipo['habilitar'] = true
                }
            }
        })
    }

    private carregarTemplates() {
        this.templateService.getAll().subscribe(t => {
            this.templates = t
        })
    }

    async carregarEmissao() {
        await this.emissaoTituloService.getById(this.idEmissaoTitulo).toPromise().then(async e => {
            this.emissaoTitulo = e
            await this.preencherFormComDadosEmissao()
        })
    }

    async buscaReq() {
        await this.requerimentoService.getById(this.idRequerimento).toPromise().then(r => {
            this.requerimento = r
        })
    }

    private construirForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            idTipoSolicitacao: [null, Validators.required],
            justificativaSolicitacao: [null],
            avisoSolicitacao: [null, Validators.required],
            idAvisoAutomatico: [null],
            situacaoTitulo: [null],
            nomeArquivo: [null],
            anexoDocumento: [null],
            documentoProcessoNovo: [null],
            idEmissaoTitulo: [null],
        });
    }

    construirFormEmissao() {
        this.formEmissao = this.formBuilder.group({
            id: [null],
            dataValidade: [null, Validators.required],
            numeroPortaria: [null],
            numeroEmissao: [null],
            template: [null, Validators.required],
            arquivo: [null, Validators.required],
            documentoProcesso: [null, Validators.required],
            htmlDocumentoTexto: [null]
        });
    }

    async preencherFormComDadosEmissao() {
        this.formEmissao.patchValue({id: this.emissaoTitulo.id})
        this.formEmissao.patchValue({dataValidade: this.emissaoTitulo.dataValidade})
        this.formEmissao.patchValue({numeroPortaria: this.emissaoTitulo.numeroPortaria})
        this.formEmissao.patchValue({numeroEmissao: this.emissaoTitulo.numeroEmissao})
        if (this.emissaoTitulo.chaveHtmlDocumento) {
            let html = await this.assinadorEletronicoService.readHtmlByChave(this.emissaoTitulo.chaveHtmlDocumento).toPromise()
            this.htmlDocumentoTexto = this.blobToString(html)
            this.formEmissao.patchValue({htmlDocumentoTexto: this.htmlDocumentoTexto})
        } else {
            this.htmlDocumentoTexto = 'Documento título não emitido';
            this.formEmissao.patchValue({htmlDocumentoTexto: this.htmlDocumentoTexto})
        }
    }

    blobToString(b) {
        let u, x;
        u = URL.createObjectURL(b);
        x = new XMLHttpRequest();
        x.open('GET', u, false);
        x.send();
        URL.revokeObjectURL(u);
        return x.responseText;
    }

    criarNovoDocumentoProcesso() {
        let documentoProcesso = new DocumentoProcesso()
        documentoProcesso.requerimento = this.requerimento
        documentoProcesso.documentoAssinado = false
        documentoProcesso.grupoDocumento = this.emissaoTitulo.documentoProcesso.grupoDocumento
        documentoProcesso.tipoEmissaoPublicacao = this.emissaoTitulo.documentoProcesso.tipoEmissaoPublicacao
        this.formEmissao.patchValue({documentoProcesso: documentoProcesso})
    }

    habilitarCampo(item: any) {
        if (item.id === 4) {
            this.habalitaAvancar = true
        } else {
            this.habalitaAvancar = false
            this.modelo = false
            this.camposPassiveisEmissao = false
        }
    }

    acaoBotao(tag: any) {
        if (tag === 'modelo') {
            this.modelo = true
            this.habalitaAvancar = false
            this.botaoGerar = true
        } else if (tag === 'campos') {
            if (this.formEmissao.get('template').value) {
                this.botaoGerar = false
                this.camposPassiveisEmissao = true
                this.formEmissao.enable()
                this.botaoLimpar = true
            } else {
                this.snackBarService.showAlert('Selecione o Modelo do Título')
                this.formEmissao.markAllAsTouched()
                return
            }
        } else if (tag === 'visualizar') {
            this.camposPassiveisEmissao = true
            this.formEmissao.disable()
            this.botaoGerar = false
            this.botaoLimpar = false
        } else if (tag === 'fechar') {
            this.botaoGerar = true
            this.camposPassiveisEmissao = false
            this.formEmissao.enable()
        }
    }

    uploadDocumento(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (fileList) {
            let documentoProcesso = new DocumentoProcesso()
            documentoProcesso.nomeArquivo = fileList[0].name
            documentoProcesso.grupoDocumento = 'DOCUMENTO_ATOS_INTERNOS'
            documentoProcesso.requerimento = this.requerimento
            this.form.patchValue({documentoProcessoNovo: documentoProcesso})
            this.form.patchValue({nomeArquivo: fileList[0].name})
            this.form.patchValue({anexoDocumento: fileList[0]})
        }
    }

    async assinarAlteracaoEmissao() {
        const dialogRef = this.dialog.open(DialogConfirmacaoComponent,
            {
                width: '500px',
                data: {message: "<p style='font-style: italic; color: red'>Confirmar dados alterados? Tem certeza de que deseja efetuar a alteração de informações do título? Essa ação é irreversível.</p>"}
            });

        let stop = false
        await dialogRef.afterClosed().toPromise().then(result => {
            if (!result) {
                stop = true
            }
        });

        if (stop) {
            return
        }

        let blobPdf = await this.documentoProcessoService.stringHtmlParaPdf(this.formEmissao.value.htmlDocumentoTexto).toPromise()
        let arquivo = await this.assinadorEletronicoService.salvarDocumentoPandora(blobPdf).toPromise()
        let chaveDocumentoAssinado = await this.semaAssinadorEletronico.assinadorEletronico(arquivo.chave)
        this.criarNovoDocumentoProcesso()
        arquivo.chave = chaveDocumentoAssinado.chave
        this.formEmissao.patchValue({arquivo: arquivo})
        if (chaveDocumentoAssinado) {
            await this.emissaoTituloService.salvarHtmlDocumento(this.formEmissao.value.htmlDocumentoTexto, this.emissaoTitulo.id).toPromise()
            this.emissaoTituloService.salvarDadosEmissaoAtosInternos(this.formEmissao.value).subscribe(e => {
                this.snackBarService.showSuccess('Emissão salva com sucesso!')
                this.emissaoTitulo = e;
                this.acaoBotao('fechar')
                this.htmlDocumentoTexto = this.formEmissao.value.htmlDocumentoTexto
            }, error => {
                this.snackBarService.showError('Ocorreu um erro ao processar os dados da emissão.')
            })
        }
    }

    async pesquisarAvisosAutomaticos(event) {
        if (event.target.value.trim().length > 1) {
            await this.configuracaoAvisosAutomaticosService.getAvisosAutomaticosPorDescricao(event.target.value).toPromise().then(avisos => {
                this.avisosAutomaticos = avisos;
            })
        } else {
            await this.configuracaoAvisosAutomaticosService.getAvisosAutomaticosPorDescricao('').toPromise().then(avisos => {
                this.avisosAutomaticos = avisos;
            })
        }
    }

    selecionarAviso(aviso: any) {
        this.form.patchValue({idAvisoAutomatico: aviso.id})
    }

    async salvarAtosInternos() {
        if (this.form.valid) {
            if (this.form.value.idTipoSolicitacao === 1) {
                this.emissaoTitulo.situacaoTitulo = EnumSituacaoTitulo.CANCELADO
                this.form.patchValue({situacaoTitulo: EnumSituacaoTitulo.CANCELADO})

                const dialogRef = this.dialog.open(DialogConfirmacaoComponent,
                    {
                        width: '500px',
                        data: {message: 'Confirmar Solicitação para Cancelamento. Tem certeza de que deseja confirmar o cancelamento?'}
                    });

                let stop = false
                await dialogRef.afterClosed().toPromise().then(result => {
                    if (!result) {
                        stop = true
                    }
                });

                if (stop) {
                    return
                }
            } else if (this.form.value.idTipoSolicitacao === 2) {
                this.emissaoTitulo.situacaoTitulo = EnumSituacaoTitulo.SUSPENSO
                this.form.patchValue({situacaoTitulo: EnumSituacaoTitulo.SUSPENSO})
            } else if (this.form.value.idTipoSolicitacao === 3) {
                this.emissaoTitulo.situacaoTitulo = EnumSituacaoTitulo.ATIVO
                this.form.patchValue({situacaoTitulo: EnumSituacaoTitulo.ATIVO})
            } else {
                this.form.patchValue({situacaoTitulo: this.emissaoTitulo.situacaoTitulo})
            }

            this.form.patchValue({idEmissaoTitulo: this.emissaoTitulo.id})

            if (this.form.value.idTipoSolicitacao !== 4) {
                let idDocProcessoTituloACancelar = this.emissaoTitulo.documentoProcesso.id;
                let chaveDocProcessoTituloACancelar = this.emissaoTitulo.documentoProcesso.chave;
                let chaveDocumentoComDecisaoRegistrada =
                    await this.outrosAtosInternosService.registrarDecisaoNoDocumento(this.montarRegistroDeDecisao()).toPromise();
                let chaveDocumentoAssinado =
                    await this.semaAssinadorEletronico.assinadorEletronicoCodigoVerificador(chaveDocumentoComDecisaoRegistrada, true, this.emissaoTitulo.documentoProcesso.id)
                if (chaveDocumentoAssinado) {
                    this.emissaoTitulo.documentoProcesso.chave = chaveDocumentoAssinado.chave;
                    /**
                     * Se o tipo de solicitação for cancelamento atualiza documentoProcesso com dados do título cancelado 
                     * e cria um histórico do arquivo do título anterior.
                     */
                    if (this.form.value.idTipoSolicitacao === 1) {
                        await this.documentoProcessoService.atualizaComDadosDoTituloCancelado(this.emissaoTitulo.documentoProcesso, idDocProcessoTituloACancelar, chaveDocProcessoTituloACancelar).toPromise()
                    } else {
                        await this.documentoProcessoService.update(this.emissaoTitulo.documentoProcesso).toPromise()
                    }
                    this.salvar();
                }
            } else {
                this.salvar();
            }
        } else {
            this.form.markAsTouched();
            this.snackBarService.showAlert('Preenche todos os campos necessários.');
        }
    }

    montarRegistroDeDecisao(){
        return new RegistrarDecisaoDocumentoDto(this.emissaoTitulo.documentoProcesso.chave, this.form.get('justificativaSolicitacao').value,
            this.emissaoTitulo.requerimentoTitulacao.titulacao.descricao, this.emissaoTitulo.requerimentoTitulacao.requerimento.numeroProcesso,
            this.form.get('idTipoSolicitacao').value)
    }

    salvar() {
        this.outrosAtosInternosService
            .salvarAtosInternos(this.form.value, this.form.get('anexoDocumento').value).subscribe(s => {
            this.snackBarService.showSuccess('Salvo com sucesso')
            this.fechar();
        }, error => {
            this.snackBarService.showError('Ocorreu um erro ao processar os dados de solicitação.')
        })
    }

    mostrarAvisos(b: boolean) {
        this.avisoAutomatico = b
    }

    limparCampoEmissao() {
        this.formEmissao.reset()
    }

    limparForm() {
        this.form.reset()
    }

    fechar() {
        RedirectUtil.redirecionaDashboardCamunda()
    }

    async selecionarModeloTitulo(item: any) {
        this.htmlDocumentoTexto = await this.templateService.getHtmlPorTemplate(item.id).toPromise()
    }
}
