import {BaseEnum} from './../../../../shared/enums/base.enum';
import {TimelineItem} from './../../../../shared/components/timeline/models/timelime-item.model';
import {HistoricoRequerimento} from './model/historico-requerimento.model';
import {Component, Input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {RequerimentoService} from 'app/main/pages/requerimentos/requerimento.service';
import {fuseAnimations} from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-historico-requerimento',
    templateUrl: './historico-requerimento.component.html',
    animations: fuseAnimations,
})
export class HistoricoRequerimentoComponent implements OnInit, OnChanges {

    @Input() requerimento: Requerimento;

    historicos: HistoricoRequerimento[] = [];
    historicosRequerimento = HistoricoRequerimento.historicosRequerimento;

    constructor(protected requerimentoService: RequerimentoService,
        private activatedRoute: ActivatedRoute,) {
        this.requerimento = this.requerimento || this.activatedRoute.snapshot.data.routeData ? this.activatedRoute.snapshot.data.routeData.requerimento : null;
    }

    ngOnInit(): void {
        if (this.requerimento && this.requerimento.id)
        this.requerimentoService.listarHistorico(this.requerimento.id).subscribe(historicos => {
            this.historicos = historicos;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            this.requerimentoService.listarHistorico(this.requerimento.id).subscribe(historicos => {
                this.historicos = historicos;
            });
        }
    }

}
