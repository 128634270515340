import {ModelUtil} from "../../../../../shared/models/model-util";

export class DisponibilidadeHidricaMes {
    constructor(
        public mes?: string,
        public mesOrdem?: number,
        public vazaoQ95?: number,
        public vazaoQ95Atual?: number,
        public vazaoQInc?: number,
        public vazaoDisponivel?: number,
        public vazaoSolicitada?: number,
        public concentracao?: number,
        public vazaoEfluente?: number,
        public vazaoOutorgada?: number,
        public vazaoUsoIns?: number,
        public vazaoDiluicao?: number,
        public vazaoDevolvida?: number,
        public vazaoDisponivelUsoLocal?: boolean,
        public vazaoDisponivelUsoJusante?: boolean,
        public percQ95Solic?: number,
        public vazaoDisponivelUsoQ95?: boolean,
        public percQ95UsoMontante?: number,
        public percQ95UsoConsuntivo?: number,
        public vazaoQ95Original?: number,
        public vazaoEfluenteOriginal?: number,
        public vazaoOutorgadaOriginal?: number,
        public vazaoUsoInsOriginal?: number,
        public vazaoDiluicaoOriginal?: number,
    ) {
    }

    static fromJson(json: any): DisponibilidadeHidricaMes {
        if (json === undefined || json === null) {
            return null;
        }
        return new DisponibilidadeHidricaMes(
            json.mes,
            json.mesOrdem,
            json.vazaoQ95,
            json.vazaoQ95Atual,
            json.vazaoQInc,
            json.vazaoDisponivel,
            json.vazaoSolicitada,
            json.concentracao,
            json.vazaoEfluente,
            json.vazaoOutorgada,
            json.vazaoUsoIns,
            json.vazaoDiluicao,
            json.vazaoDevolvida,
            json.vazaoDisponivelUsoLocal,
            json.vazaoDisponivelUsoJusante,
            json.percQ95Solic,
            json.vazaoDisponivelUsoQ95,
            json.percQ95UsoMontante,
            json.percQ95UsoConsuntivo,
            ModelUtil.getOrElseZero(json.vazaoQ95Original),
            ModelUtil.getOrElseZero(json.vazaoEfluenteOriginal),
            json.vazaoOutorgadaOriginal,
            json.vazaoUsoInsOriginal,
            json.vazaoDiluicaoOriginal
        );
    }

    static fromJsons(json: any): DisponibilidadeHidricaMes[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(DisponibilidadeHidricaMes.fromJson(j)));
            return resources;
        }
    }
}
