import {HttpParams} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Complementacao, ComplementacaoItem, ComplementacaoItemArquivo} from './complementacao.model';
import {OficioPendencia} from "../../../shared/models/oficio-pendencia.model";

@Injectable({providedIn: 'root'})
export class ComplementacaoService extends BaseService<Complementacao> {
    constructor(
        protected injector: Injector
    ) {
        super('/api/complementacoes', injector, Complementacao.prototype, Complementacao.fromJson);
    }

    getAllComFiltros(requerimentoId: number, situacoes: string[] = null): Observable<Complementacao[]> {
        let params: HttpParams = new HttpParams().set('requerimentoId', String(requerimentoId));
        if (situacoes && situacoes.length > 0) {
            params = params.set('situacaoList', situacoes.toString());
        }
        return this.http.get(this.urlResource, {params: params}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    createComplementacao(requerimentoId: number): Observable<Complementacao> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}`;

        return this.http
            .post(url, null)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createComplementacaoItem(complementacaoId: number, item: ComplementacaoItem): Observable<ComplementacaoItem> {
        const url = `${this.urlResource}/${complementacaoId}/itens`;

        return this.http
            .post(url, item)
            .pipe(
                map(ComplementacaoItem.fromJson),
                catchError(this.handleError.bind(this))
            );
    }

    createComplementacaoItemArquivo(complementacaoId: number, complementacaoItemId: number, arquivo: ComplementacaoItemArquivo): Observable<ComplementacaoItemArquivo> {
        const url = `${this.urlResource}/${complementacaoId}/itens/${complementacaoItemId}/arquivos`;

        const formData = new FormData();

        formData.append('arquivo', arquivo.blob);
        formData.append('titulo', arquivo.titulo);
        formData.append('sigiloso', arquivo.sigiloso.toString());
        formData.append('resposta', arquivo.resposta.toString());
        formData.append('textResposta', arquivo.textResposta);

        return this.http
            .post(url, formData)
            .pipe(
                map(ComplementacaoItemArquivo.fromJson),
                catchError(this.handleError.bind(this))
            );
    }

    deleteComplementacaoItem(complementacaoId: number, complementacaoItemId: number): Observable<any> {
        const url = `${this.urlResource}/${complementacaoId}/itens/${complementacaoItemId}`;

        return this.http
            .delete(url)
            .pipe(
                catchError(this.handleError.bind(this))
            );
    }

    deleteComplementacaoItemArquivo(complementacaoId: number, complementacaoItemId: number, complementacaoItemArquivoId: number): Observable<any> {
        const url = `${this.urlResource}/${complementacaoId}/itens/${complementacaoItemId}/arquivos/${complementacaoItemArquivoId}`;

        return this.http
            .delete(url)
            .pipe(
                catchError(this.handleError.bind(this))
            );
    }

    findComplementacaoPorRequerimento(requerimentoId: number, comItens: boolean = false): Observable<Complementacao> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}`;

        let params: HttpParams = new HttpParams();

        if (comItens) {
            params = params.set('comItens', comItens.toString());
        }

        return this.http
            .get(url, {params})
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateComplementacao(complementacao: Complementacao, concluir: boolean = false): Observable<any> {
        const url = `${this.urlResource}/${complementacao.id}`;

        const params: HttpParams = new HttpParams({fromObject: {concluir: concluir.toString()}});

        return this.http
            .put(url, complementacao, {params})
            .pipe(
                catchError(this.handleError.bind(this))
            );
    }

    validarOficio(idRequerimento: number, idTarefa: string, despacho: string, aceite: boolean, nomeTarefa: string, finalizar: boolean = false): Observable<string> {
        const url = `${this.urlResource}/validar-oficio/${idRequerimento}/${idTarefa}`;

        const formData = new FormData();
        formData.append('despacho', despacho);
        formData.append('aceite', aceite ? 'true' : 'false');
        formData.append('finalizar', finalizar ? 'true' : 'false');
        formData.append('nomeTarefa', nomeTarefa);

        return this.http.post(url, formData).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    respostaComplementacao(idRequerimento: number, idTarefa: string, complementacao: Complementacao, finalizar: boolean): Observable<string> {
        const formData = new FormData();
        formData.append('finalizar', finalizar ? 'true' : 'false');
        formData.append('complementacao', new Blob([JSON.stringify(complementacao)],
            {type: 'application/json'}));

        const url = `${this.urlResource}/resposta/tarefa/${idTarefa}`;
        return this.http.post(url, formData).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    analiseComplementacao(idRequerimento: number, idTarefa: string, complementacao: Complementacao, finalizar: boolean): Observable<string> {
        const formData = new FormData();
        formData.append('finalizar', finalizar ? 'true' : 'false');
        formData.append('complementacao', new Blob([JSON.stringify(complementacao)],
            {type: 'application/json'}));

        const url = `${this.urlResource}/analise/tarefa/${idTarefa}`;
        return this.http.post(url, formData).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    salvarValidacaoDePrazoParaComplementacao(file: FormData, idTarefa: string): Observable<string> {
        const url = `${this.urlResource}/concluir-validacao-prazo/tarefa/${idTarefa}`;
        return this.http.post(url, file).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    
    salvarValidacaoEncerramentoPrazoConsemaAnexo(file: FormData, idComplementacao: number): Observable<any> {
        const url = `${this.urlResource}/salvar-anexo-validacao-encerramento/${idComplementacao}/complementacao`;
        return this.http.post(url, file)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    getVisualizarDocumentoPath(doc: ComplementacaoItemArquivo): string {
        return `${this.urlResource}/arquivo/${doc.id}/visualizar`;
    }

    getBaixarDocumentoPath(doc: ComplementacaoItemArquivo): string {
        return `${this.urlResource}/arquivo/${doc.id}/baixar`;
    }

    getComplementacaoByIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscarOficioPendenciaPorComplementacao(complementacaoId: number): Observable<OficioPendencia> {
        const url = `${this.urlResource}/buscar-oficio-pendencia/${complementacaoId}`;
        return this.http.get(url).pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    //Bugfix 8545 - Somente gera o documento para visualização, NÃO SALVA.
    visualizarOficioParaValidacao(idComplementacao: number, type = 'application/pdf'): Observable<Blob> {
        const url = `${this.urlResource}/visulizar-oficio-para-validacao/${idComplementacao}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: type});
                }
            ));
    }

    getInconcormidadesTR(idRequerimento: number, idComplementacao: number): Observable<any> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/termos-referencia-itens-inconformidades/complementacao/${idComplementacao}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    getUltimaComplementacaoByIdRequerimento(idRequerimento: number, comItens: boolean = false): Observable<Complementacao> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/atual`;

        let params: HttpParams = new HttpParams();

        if (comItens) {
            params = params.set('comItens', comItens.toString());
        }

        return this.http
            .get(url, {params})
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
