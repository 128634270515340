import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from '../requerimentos/requerimento.model';

export class FeicaoAnaliseRequerimento extends BaseModel {
    constructor(
        public id?: number,
        public titulo?: string,
        public obrigatorio?: boolean,
        public requerimento?: Requerimento,
        public idGeometria?: number,
    ) {
        super();
    }

    static fromJson(json: FeicaoAnaliseRequerimento): FeicaoAnaliseRequerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new FeicaoAnaliseRequerimento(
            json.id,
            json.titulo,
            json.obrigatorio,
            Requerimento.fromJson(json.requerimento),
            json.idGeometria
        );
    }
}
