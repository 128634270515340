import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {DominioService} from "../../../../../../../../shared/services/dominio.service";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {Dominio} from "../../../../../../../../shared/models/dominio-model";
import {ResiduoGeradoModel} from "../../../../../../../../shared/models/residuo-gerado.model";
import {ResiduoGeradoMensalModel} from "../../../../../../../../shared/models/residuo-gerado-mensal.model";
import {ResiduoGeradoEstocadoModel} from "../../../../../../../../shared/models/residuo-gerado-estocado.model";
import {EmpreendimentoGerenciamentoResiduosService} from "../../../../../../../../shared/services/empreendimento-gerenciamento-residuos.service";
import {Requerimento} from "../../../../../../requerimento.model";
import {RequerimentoFormularioLicenciamentoService} from "../../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../../requerimento.service";

@Component({
    selector: 'app-residuos-gerados',
    templateUrl: './residuos-gerados.component.html',
    styleUrls: ['./residuos-gerados.component.scss']
})
export class ResiduosGeradosComponent implements OnInit {

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Input() id: number;
    @Input() requerimento: Requerimento;
    @Input() readonly = false;
    @Input() isNaoPodeEditar: boolean;

    @ViewChild('formDirective', {static: true})
    formDirective: NgForm;

    form: FormGroup;
    tiposClassificacao: Dominio[] = [];
    tiposUnidadeMedida: Dominio[] = [];
    mapTiposClassificacao = new Map();
    mapTiposUnidadeMedida = new Map();

    dataSource: ResiduoGeradoModel[] = [];

    displayedColumns: string[] = [
        'identificacao',
        'classificacao',
        'origem',
        'pontosSegracao',
        'freqGeracao',
        'qtdMensal',
        'qtdResiduoEstocado'
    ];

    idRequerimento

    constructor(private fb: FormBuilder,
                private dominioService: DominioService,
                private empreendimentoGerenciamentoResiduosService: EmpreendimentoGerenciamentoResiduosService,
                private snackBarService: SnackBarService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService) {
        this.form = this.fb.group({
            id: [''],
            identificacao: ['', [Validators.required, Validators.maxLength(255)]],
            tipoClassificacao: ['', [Validators.required]],
            origem: ['', [Validators.required, Validators.maxLength(255)]],
            identPontosSegregacao: ['', [Validators.required, Validators.maxLength(255)]],
            freqGeracao: ['', [Validators.required, Validators.maxLength(255)]],
            residuoMensal: this.fb.group({
                qtd: ['', [Validators.required, Validators.maxLength(20)]],
                tipoUnidadeMedida: ['', [Validators.required]],
                densidade: ['', [Validators.maxLength(20)]],
            }),
            residuoEstocado:this.fb.group({
                qtd: ['', [Validators.required, Validators.maxLength(20)]],
                tipoUnidadeMedida: ['', [Validators.required]],
                densidade: ['', [Validators.maxLength(20)]],
            })
        });
    }

    async ngOnInit() {
        if(this.id) {
            this.empreendimentoGerenciamentoResiduosService.consultarResiduosGeradosPorId(this.id).subscribe(res => {
                this.form.patchValue(res);
            }, error => {
                this.snackBarService.showError('Falha ao carregar informações!');
                console.error(error);
            });
        }

        this.dominioService.getTipoClassificacaoResiduosGerados().subscribe(res => {
            this.tiposClassificacao = res;
            this.tiposClassificacao.forEach(tipo => {
                this.mapTiposClassificacao.set(tipo.id, tipo.descricao);
            })
        },err => {
            console.error( err );
        });
        this.empreendimentoGerenciamentoResiduosService.buscarUnidadeMedidaFiltrada().subscribe(res => {
            this.tiposUnidadeMedida = res;
            this.tiposUnidadeMedida.forEach(tipo => {
                this.mapTiposUnidadeMedida.set(tipo.id, tipo.descricao);
            })
        },err => {
            console.error( err );
        });
        this.listenersInit();

        let requerimentoLicenciamentoVinculado;
        if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
            await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
            })
            this.idRequerimento = requerimentoLicenciamentoVinculado.requerimento.id
            this.carregar(this.idRequerimento);

        } else {
            this.carregar(this.requerimento.id);
        }

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }

    }

    private listenersInit(): void {
        this.form.get('residuoMensal.tipoUnidadeMedida').valueChanges.subscribe(type => {
            if(type == 6){
                this.form.get('residuoMensal.densidade').reset();
                this.form.get('residuoMensal.densidade').setErrors(null);
            }
        });
        this.form.get('residuoEstocado.tipoUnidadeMedida').valueChanges.subscribe(type => {
            if(type == 6){
                this.form.get('residuoEstocado.densidade').reset();
                this.form.get('residuoEstocado.densidade').setErrors(null);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ( changes.requerimento ) {
            this.carregar(this.requerimento.id);
        }

        if (changes.readonly && !changes.readonly.currentValue) {
            this.displayedColumns = this.displayedColumns.concat('acao');
        }
    }

    saveForm() :void {
        if(!this.form.invalid) {
            const form = this.form.getRawValue();
            const residuoMensal: ResiduoGeradoMensalModel = {
                qtd: form.residuoMensal.qtd,
                tipoUnidadeMedida: form.residuoMensal.tipoUnidadeMedida,
                densidade: form.residuoMensal.densidade
            }
            const residuoEstocado: ResiduoGeradoEstocadoModel = {
                qtd: form.residuoEstocado.qtd,
                tipoUnidadeMedida: form.residuoEstocado.tipoUnidadeMedida,
                densidade: form.residuoEstocado.densidade
            }
            const entidade: ResiduoGeradoModel = {
                id: form.id,
                idRequerimento: this.requerimento.id,
                freqGeracao: form.freqGeracao,
                identPontosSegregacao: form.identPontosSegregacao,
                identificacao: form.identificacao,
                origem: form.origem,
                residuoMensal: residuoMensal,
                residuoEstocado: residuoEstocado,
                tipoClassificacao: form.tipoClassificacao
            };
            this.empreendimentoGerenciamentoResiduosService.salvarResiduoGerado(entidade).subscribe(response => {
                this.carregar(entidade.idRequerimento);
                this.snackBarService.showSuccess( 'Informações salvas com sucesso!' );
                this.save.emit(response);
                this.formDirective.resetForm();
            }, error => {
                this.snackBarService.showError( 'Falha ao salvar informações!' );
                console.error( error );
            });
        }
    }

    editar(residuo: ResiduoGeradoModel){
        this.form.patchValue( {
            id: residuo.id,
            idRequerimento: residuo.idRequerimento,
            freqGeracao: residuo.freqGeracao,
            identPontosSegregacao: residuo.identPontosSegregacao,
            identificacao: residuo.identificacao,
            origem: residuo.origem,
            residuoEstocado: residuo.residuoEstocado,
            residuoMensal: residuo.residuoMensal,
            tipoClassificacao: residuo.tipoClassificacao
        } );
    }

    excluir(residuo: ResiduoGeradoModel): void {

        this.empreendimentoGerenciamentoResiduosService.deletarResiduoGerado(residuo.id)
            .subscribe( _ => {
                this.snackBarService.showSuccess( 'Resíduo gerado excluído com sucesso!' );
                this.carregar(this.requerimento.id);
            }, error => {
                if(error.status == 400){
                    this.snackBarService.showError( 'Existe pelo menos um registro de gerenciamento de resíduos utilizando o resíduo selecionado!' );
                } else {
                    this.snackBarService.showError('Falha ao excluir Resíduo gerado');
                }
                console.error( error );
            });
    }

    keyPressOnlyNumeric(event) {
        var inp = String.fromCharCode(event.keyCode);
        if (/\d/.test(inp)) {
            return true;
        }
        event.preventDefault();
        return false;
    }

    private carregar(idRequerimento): void {
        this.empreendimentoGerenciamentoResiduosService.listarResiduosGeradosPorRequerimento(idRequerimento)
            .subscribe( lista => {
                this.dataSource = lista;
            }, error => {
                this.snackBarService.showError( 'Falha ao listar os Resíduos Gerados!' );
                console.error( error );
            } );
    }

}
