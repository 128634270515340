import {BaseModel} from "./base.model";
import {Requerimento} from "../../pages/requerimentos/requerimento.model";
import {PrioridadeProcessoCriterio} from "./prioridade-processo-criterio.model";

export class PrioridadeProcesso extends BaseModel {

    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public justificativa?: string,
        public dataCriacao?: Date,
        public cpfCadastrante?: string,
        public criterios?: PrioridadeProcessoCriterio[]
    ) {
        super();
    }

    static fromJson(json: any): PrioridadeProcesso {
        if (json === undefined || json === null) {
            return null;
        }
        return new PrioridadeProcesso(
            json.id,
            json.requerimento,
            json.justificativa,
            json.dataCriacao,
            json.cpfCadastrante,
            json.criterios
        );
    }

    static fromJsons(json: any): PrioridadeProcesso[] {
        const lista: PrioridadeProcesso[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => lista.push(this.fromJson(item)));
            return lista;
        }
    }
}