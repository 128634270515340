import { Pipe, PipeTransform } from '@angular/core';
import {CenarioRegraGeo} from "../enums/cenario-regra-geo.enum";
import {RegraGeoFeicao} from "../models/geoadmin/regra-geo-feicao.model";
import {TipoRegraGeo} from "../enums/tipo-regra-geo.enum";
import {APROVADO_SVG, ATENCAO_SVG, REPROVADO_SVG} from "../util/geo-report-icones.util";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
@Pipe({
    name: 'regraGeoIcone'
})
export class RegraGeoIconePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(regra: RegraGeoFeicao, cenarioId: number): SafeHtml | boolean {
        const icone = getRegraGeoIcone(regra, cenarioId)
        return icone ? this.sanitizer.bypassSecurityTrustHtml(<string>icone) : icone;
    }
}
export function getRegraGeoIcone(regra: RegraGeoFeicao, cenarioId: number): string | boolean {
    const encontrouErros: boolean = verificaAtivacaoRegra(regra, cenarioId);
    if (!encontrouErros) {
        return APROVADO_SVG;
    } else if (encontrouErros && regra.tipoRegra === TipoRegraGeo.IMPEDITIVA) {
        return REPROVADO_SVG;
    } else if (encontrouErros && regra.tipoRegra === TipoRegraGeo.ALERTA) {
        return ATENCAO_SVG;
    }
    return false;
}

export function verificaAtivacaoRegra(regra: RegraGeoFeicao, cenarioId: number): boolean {
    let ativou: boolean = null;

    switch (cenarioId) {
        case CenarioRegraGeo.PROIBITIVA:
            ativou = regra.ativouRegra;
            break;
        case CenarioRegraGeo.GARANTIA_HISTORICO_FEICOES:
        case CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO:
        case CenarioRegraGeo.GARANTIA_APLICACOES_BATCH:
            ativou = !regra.ativouRegra;
            break;
    }

    return ativou;
}