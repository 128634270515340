import {Injectable, Injector} from '@angular/core';
import {EmpreendimentoFuncao} from "../models/empreendimento-funcao.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseEmpreendimentoEnergeticosSubestacaoService} from "./base-empreendimento-energeticos-subestacao.service";

@Injectable({
    providedIn: 'root'
})
export class EmpreendimentoFuncaoService extends BaseEmpreendimentoEnergeticosSubestacaoService<EmpreendimentoFuncao> {

    constructor(injector: Injector) {
        super(  '/api/empreendimento/funcao',
                injector,
            EmpreendimentoFuncao.prototype,
            EmpreendimentoFuncao.fromJson);
    }

    getByEmpreendimento(idEmpreendimento: number): Observable<Array<EmpreendimentoFuncao>> {
        const url = `${this.urlResourceEmpreendimentoEnergeticosSubestacao}/empreendimento/${idEmpreendimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}
