import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ParecerRequerimentoAvaliacaoService} from '../../../pages/parecer-tecnico/parecer-requerimento-avaliacao/parecer-requerimento-avaliacao.service';

@Component({
    selector: 'app-dialog-emissao-titulo',
    templateUrl: './dialog-emissao-titulo.component.html',
    styleUrls: ['./dialog-emissao-titulo.component.scss']
})
export class DialogEmissaoTituloComponent implements OnInit {

    form: FormGroup;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder,
                private parecerRequerimentoAvaliacaoService: ParecerRequerimentoAvaliacaoService,
                private matDialogRef: MatDialogRef<DialogEmissaoTituloComponent>) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            documento: [null]
        });
        this.form.patchValue({documento: this.data});
    }

    fecharDialog(): void {
        this.matDialogRef.close(this.form.value.documento);
    }

    async salvarDocumento() {
        // await this.parecerRequerimentoAvaliacaoService.exportPdf(this.form.value.documento).toPromise()
        console.log('salvo');
    }
}
