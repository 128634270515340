import {BaseModel} from 'app/main/shared/models/base.model';

export class AreaIrrigacao extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public tipoIrrigacao?: string,
        public culturasDesenvolvida?: string,
        public volumeEfluentes?: number,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(json: any): AreaIrrigacao {
        json = json || {};

        return new AreaIrrigacao(
            json.id,
            json.idGeometria,
            json.tipoIrrigacao,
            json.culturasDesenvolvida,
            json.volumeEfluentes,
            json.descricao,
        );

    }
}