import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {InformacaoLo} from "../../../../../../../../shared/models/informacao-lo.model";
import {PessoaCorporativoService} from "../../../../../../../../shared/services/pessoa-corporativo.service";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";

@Component({
    selector: 'app-informacao-lo',
    templateUrl: './informacao-lo.component.html',
    styleUrls: ['./informacao-lo.component.scss']
})
export class InformacaoLoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;
    pessoaExiste: boolean = false;
    private readonly TAMANHO_CNPJ = 14;
    private readonly TAMANHO_CPF = 11;
    private readonly TIPO_PF = "PF";

    displayedColumns = ['tipo', 'cnpj', 'empresa', 'numeroLo', 'dataValidadeLicenca', 'acoes'];

    tipos: DominioDto[];

    formInformacaoLo = this.fb.group({
        id: [null],
        mineracao: [null],
        idSubstancia: [null, Validators.required,],
        cnpjCpf: [null, Validators.required,],
        nomeRazaoSocial: [null, Validators.required,],
        numeroLicenca: [null,],
        dataValidadeLicenca: [null]
    });

    constructor(
        private dominiosService: DominiosService,
        private pessoaCorporativoService: PessoaCorporativoService,
        private fb: FormBuilder,
        private snackBarService: SnackBarService) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-substancia-mineral')
            .subscribe(doms => this.tipos = doms);

        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formInformacaoLo.disable();
        }
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formInformacaoLo.valid){
            this.snackBarService.showError(this.formInformacaoLo.get('numeroLicenca').valid ?  "Preencha todos os " +
                "campo(s) obrigatório(s)." : "O sistema verificou que o informado para Licença de Operação é inválido.");
            this.formInformacaoLo.markAllAsTouched();
        }else{
            const informacaoLO = this.form.get('informacaoLO');

            informacaoLO.setValue([
                ...(informacaoLO.value || []),
                InformacaoLo.fromJson(this.formInformacaoLo.value)
            ]);

            this.formInformacaoLo.reset();
        }
    }

    excluir(item: InformacaoLo) {
        const estruturas: AbstractControl = this.form.get('informacaoLO');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: InformacaoLo) {
        this.formInformacaoLo.patchValue(item);

        this.excluir(item);
    }

    getPessoaByCNPJCPF() {
        this.pessoaExiste = false;
        let cnpjCpf = this.formInformacaoLo.get('cnpjCpf').value
        if (cnpjCpf && (cnpjCpf.length === this.TAMANHO_CNPJ || cnpjCpf.length === this.TAMANHO_CPF)) {
            this.pessoaCorporativoService.consultarCnpjSemValidacaoCadastro(cnpjCpf).subscribe((pessoa) => {
                if (pessoa) {
                    this.formInformacaoLo.get('nomeRazaoSocial').patchValue(pessoa.tipo === this.TIPO_PF ? pessoa.nome : pessoa.razaoSocial);
                    this.pessoaExiste = true;
                }
            }, (err) => this.formInformacaoLo.get('nomeRazaoSocial').patchValue(null));
        }
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.formInformacaoLo.get('cnpjCpf').value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

}
