import {OrgaoAmbiental} from '../../../../shared/models/orgao-ambiental.model';
import {OrgaoAmbientalService} from '../../../../shared/services/orgao-ambiental.service';
import {RequerimentoOutorgaService} from '../requerimento-outorga.service';
import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {Titulacao} from 'app/main/pages/titulacoes/titulacao.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RequerimentoService} from '../../requerimento.service';
import {MatDialog} from '@angular/material/dialog';
import {
    ConfirmarCancelamentoDialogComponent
} from '../../shared/confirmar-cancelamento-dialog/confirmar-cancelamento-dialog.component';
import {RedirectUtil} from '../../../../shared/util/RedirectUtil';
import {takeUntil} from 'rxjs/operators';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {
    ErrosAbaRequerimento
} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {RequerimentoOutorga} from '../requerimento-outorga.model';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {AdicionarCopiaTituloComponent} from './adicionar-copia-titulo/adicionar-copia-titulo.component';
import {EmissaoTituloService} from '../../../tarefas/shared/service/emissao-titulo.service';
import {TitulacaoDocumentosService} from '../../shared/aba-titulacoes-pareceres/titulacao-documentos.service';
import {TitulacaoDocumentosDTO} from '../../shared/aba-titulacoes-pareceres/titulacao-documentos-dto.model';
import {
    ConfirmarExclusaoDialogComponent
} from '../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {PdfViewerDialog} from '../../../../shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import {DocumentoRequerimentoService} from "../../../documentos-requerimentos/documento-requerimento.service";
import {ObjetivoOutorga} from "../../../../shared/models/objetivo-outorga.model";
import {TituloLegadoAnexoService} from 'app/main/pages/tarefas/shared/service/titulo-legado-anexo.service';
import {ProcessoService} from "../../../../shared/services/processo.service";

@Component({
    selector: 'app-requerimento-formulario-outorga',
    templateUrl: 'requerimento-formulario-outorga.component.html',
    animations: fuseAnimations
})
export class RequerimentoFormularioOutorgaComponent
    extends BaseTabRequerimentoComponent
    implements OnInit {
    formRequerimentoOutorga: FormGroup;
    titulacoes: Titulacao[];
    requerimentoFinalidadesAtividadesLenght = 0;
    requerimentoObjetivoLenght = 0;
    objetivosAdicionados: ObjetivoOutorga[] = [];
    orgaosAmbientais: OrgaoAmbiental[] = [];
    titulacaoDocumentos: TitulacaoDocumentosDTO[];
    temDocumento: boolean;
    isRenderizaComponenteParaNatuDigital: boolean = false;

    constructor(
        private injector: Injector,
        private router: Router,
        private dialog: MatDialog,
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private requerimentoService: RequerimentoService,
        private requerimentoOutorgaService: RequerimentoOutorgaService,
        private orgaoAmbientalService: OrgaoAmbientalService,
        private changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private titulacaoDocumentosService: TitulacaoDocumentosService,
        private emissaoTituloService: EmissaoTituloService,
        private tituloLegadoAnexoService: TituloLegadoAnexoService,
        private pdfViewerDialog: PdfViewerDialog,
        private documentoRequerimentoService: DocumentoRequerimentoService,
        private processoService: ProcessoService
    ) {
        super(injector);
        RequerimentoSteps.FORMULARIO.component = this;
    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.helper.onCurrentStepChange().pipe(takeUntil(this._unsubscribeAll)).subscribe(e => {
            if (e.newTabIndex === RequerimentoSteps.FORMULARIO.index) {
                this.buildRequerimentoOutorgaForm();
                if (this.requerimentoOutorga.possuiRequerimentoEmAndamento) {
                    this.listarOrgaosAmbientais();
                }
                if (this.isSomenteVisualizacao) {
                    this.formRequerimentoOutorga.disable();
                }
                this.consultarSeTemDocumentoInformado();
                if (!this.requerimento.tipoRequerimento.isFluxoEmitir) {
                    this.carregarTitulacoesLegado();
                }
                if (this.isFluxoNatuDigital) {
                    this.isRenderizaComponenteParaNatuDigital = true
                }
            }
        });
    }

    private buildRequerimentoOutorgaForm(): void {
        this.formRequerimentoOutorga = this.formBuilder.group({
            id: [null],
            requerimento: [null, [Validators.required]],
            possuiRequerimentoEmAndamento: [null],
            orgaoAmbientalRequerimentoEmAndamento: [{
                value: null,
                disabled: !this.requerimentoOutorga.possuiRequerimentoEmAndamento
            },
                [Validators.required],
            ],
            protocoloRequerimentoEmAndamento: [
                {
                    value: null,
                    disabled: !this.requerimentoOutorga.possuiRequerimentoEmAndamento,
                },
                [Validators.required, Validators.maxLength(50)],
            ],
        });
        this.formRequerimentoOutorga.patchValue(this.requerimentoOutorga);
        this.formRequerimentoOutorga.markAsPristine();
        this.formRequerimentoOutorga.markAsUntouched();
        this.formRequerimentoOutorga.valueChanges.subscribe(() => {
            this.changeDetectorRef.detectChanges();
        });
    }


    get possuiRequerimentoEmAndamento(): boolean {
        if (this.formRequerimentoOutorga) {
            return this.formRequerimentoOutorga.get(
                'possuiRequerimentoEmAndamento'
            ).value;
        }
        return false;
    }

    consultarSeTemDocumentoInformado(): void {
        this.documentoRequerimentoService.existsByRequerimento(this.requerimento)
        .subscribe(exists => this.temDocumento = exists);
    }

    checkFinalidadesAtividade(event): void {
        this.requerimentoFinalidadesAtividadesLenght = event.requerimentoFinalidadesAtividadesLenght;
        this.changeDetectorRef.detectChanges();
    }

    checkObjetivos(objetivos: ObjetivoOutorga[]): void {
        this.requerimentoObjetivoLenght = objetivos.length;
        this.objetivosAdicionados = objetivos;
        this.changeDetectorRef.detectChanges();
    }

    private listarOrgaosAmbientais(): void {
        this.orgaoAmbientalService
        .getAll()
        .subscribe((orgaosAmbientais) => {
            this.orgaosAmbientais = orgaosAmbientais.filter((o) => o.ativoOutorga);
        }, () => this.snackBarService.showError('Erro ao listar orgãos ambientais.'));
    }

    public limparValoresOrgaoProtocolo(): void {
        this.formRequerimentoOutorga.patchValue({
            orgaoAmbientalRequerimentoEmAndamento: null,
        });
        this.formRequerimentoOutorga.patchValue({
            protocoloRequerimentoEmAndamento: null,
        });
        if (this.possuiRequerimentoEmAndamento) {
            if (this.orgaosAmbientais.length === 0) {
                this.listarOrgaosAmbientais();
            }
            this.formRequerimentoOutorga
            .get('orgaoAmbientalRequerimentoEmAndamento')
            .enable();
            this.formRequerimentoOutorga
            .get('protocoloRequerimentoEmAndamento')
            .enable();
        } else {
            this.formRequerimentoOutorga
            .get('orgaoAmbientalRequerimentoEmAndamento')
            .disable();
            this.formRequerimentoOutorga
            .get('protocoloRequerimentoEmAndamento')
            .disable();
        }
    }

    private async salvarRequerimentoOutorga(): Promise<void> {
        if (this.formRequerimentoOutorga.dirty) {
            const requerimentoOutorga = RequerimentoOutorga.fromJson(this.formRequerimentoOutorga.getRawValue());
            return await this.requerimentoOutorgaService.update(requerimentoOutorga).toPromise().then(ro => {
                this.requerimentoOutorga = ro;
                this.formRequerimentoOutorga.patchValue(ro);
                this.formRequerimentoOutorga.markAsPristine();
                this.formRequerimentoOutorga.markAsUntouched();
                return Promise.resolve();
            }, (e) => {
                this.snackBarService.showError('Erro ao atualizar o requerimento outorga.', e);
                return Promise.reject(e);
            });
        }
        return Promise.resolve();
    }

    public cancelar(): void {
        const idTarefa = this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path;

        const dialogRef = this.dialog.open(ConfirmarCancelamentoDialogComponent, {
            width: '300px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.requerimentoService.cancelarRequerimento(idTarefa, this.requerimento.id).subscribe(resp => {
                    RedirectUtil.redirecionaDashboardCamunda();
                }, () => {
                });
            }
        });
    }

    fechar(): void {
        RedirectUtil.redirecionaDashboardCamunda();
    }

    async validarAba(erros: ErrosAbaRequerimento): Promise<void> {
        let requerimento = this.formRequerimentoOutorga.get("requerimento").value;
        if (this.formRequerimentoOutorga.invalid) {
            if (this.formRequerimentoOutorga.invalid) {
                this.formRequerimentoOutorga.markAllAsTouched();
                this.formRequerimentoOutorga.updateValueAndValidity();
            }
            erros.push('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
        if (!this.requerimentoFinalidadesAtividadesLenght) {
            erros.push('O Requerimento precisa de pelo menos uma Finalidade/Atividade associada.');
        }
        if (!this.requerimentoObjetivoLenght) {
            erros.push('O Requerimento precisa de pelo menos um Objetivo associado.');
        }
        if (!this.requerimento.tipoRequerimento.isFluxoEmitir && !this.titulacaoDocumentos.length) {
            erros.push('O Requerimento precisa de pelo menos um titulo á ser renovado associado.');
        }
        if (this.requerimento.tipoRequerimento.isFluxoRenovar) {

        }
        if (erros.length === 0) {
            return await Promise.all([
                this.salvarRequerimentoOutorga(),
            ]).then(() => Promise.resolve());
        }
        return Promise.resolve();
    }

    public excluirTitulo(item: TitulacaoDocumentosDTO): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            data: {label: item.id}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tituloLegadoAnexoService.excluirTitulacaoRequerimentoLegado(item.id).subscribe(() => {
                    this.snackBarService.showSuccess('Sucesso ao excluir titulo');
                    this.carregarTitulacoesLegado();
                }, error => this.snackBarService.showError('Erro ao excluir titulo', error));
            }
        });
    }

    visualizarDocumento(doc: TitulacaoDocumentosDTO): void {
        this.pdfViewerDialog.show(this.titulacaoDocumentosService.getVisualizarDocumentoPath(doc), {showDownloadButton: true});
    }

    adicionarTitulo(): void {
        const dialogRef = this.dialog.open(AdicionarCopiaTituloComponent, {
            height: '450px',
            width: '700px',
            data: this.requerimento
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.carregarTitulacoesLegado();
            }
        });
    }

    private async carregarTitulacoesLegado(): Promise<void> {
        this.titulacaoDocumentos = await this.titulacaoDocumentosService.getByIdRequerimentoLegado(this.requerimento.id).toPromise() || [];
        this.titulacaoDocumentos.forEach(titulo => titulo.legado = true);

        if (this.requerimento.processo && this.requerimento.processo.numero) {
            this.requerimentoService.getRequerimento(this.requerimento.processo.numero).toPromise().then(async r => {
                let titulos = await this.emissaoTituloService.buscaEmissoesPorIdRequerimentoDocumentoProcesso(r[0].id).toPromise()
                if (titulos) {
                    titulos.forEach(titulo => {
                        titulo.legado = false;
                        titulo.temDocumento = true;
                        titulo.validadeTitulacao = titulo.dataValidade;
                        titulo.grupoDocumento = titulo.documentoProcesso.grupoDocumento;
                        titulo.tipoTitulacaoDocumentoDTO = titulo.documentoProcesso.grupoDocumento;
                        titulo.chave = titulo.documentoProcesso.chave;
                        this.titulacaoDocumentos = [...this.titulacaoDocumentos, titulo];
                    });
                }
            });
        }
    }
}