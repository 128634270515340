import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TipoSistemaCultivo} from '../../../../../../shared/models/tipo-sistema-cultivo.model';

@Injectable({
    providedIn: 'root'
})
export class TipoSistemaCultivoService extends BaseService<TipoSistemaCultivo> {
    constructor(protected injector: Injector) {
        super(
            '/api/tipos-sistema-cultivo',
            injector,
            TipoSistemaCultivo.prototype,
            TipoSistemaCultivo.fromJson
        );
    }

    getAll(): Observable<TipoSistemaCultivo[]> {
        const url = `${this.urlResource}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    private jsonDataToResourceList(jsonData: any[]): TipoSistemaCultivo[] {
        const entries: TipoSistemaCultivo[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new TipoSistemaCultivo(), element);
                entries.push(rt);
            });
        }
        return entries;
    }


}
