import {Injectable, Injector} from '@angular/core';
import {ListResolver} from '../resolvers/list-resolver';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {Cemiterio} from "../models/cemiterio.model";
import { BaseLicenciamentoResiduosCemitCrematorio } from './base-licenciamento-residuos-cemit-crematorio.service';


@Injectable()
export class CemiterioListResolver extends ListResolver<Cemiterio> {
    constructor(service: CemiterioService) {
        super(service);
    }
}

@Injectable()
export class CemiterioResolver extends DetailResolver<Cemiterio> {
    constructor(
        service: CemiterioService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class CemiterioService extends BaseLicenciamentoResiduosCemitCrematorio<Cemiterio> {

    constructor(protected injector: Injector) {
        super(
            "/api/cemiterios",
            injector,
            Cemiterio.prototype,
            Cemiterio.fromJson
        );
    }
}
