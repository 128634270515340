import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MatTableDataSource} from "@angular/material/table";
@Component({
    templateUrl: './dialog-confirma-analise-feicao-batch.component.html',
    styleUrls: ['./dialog-confirma-analise-feicao-batch.component.scss']
})
export class DialogConfirmaAnaliseFeicaoBatchComponent {
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[];
    constructor(
        public dialogRef: MatDialogRef<DialogConfirmaAnaliseFeicaoBatchComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.displayedColumns =  [
            'wkt',
            'descricao',
            'acoes'
        ];
       this.parse();
    }
    parse(){
        const data: any[] = [];
        if(Array.isArray(this.data.geoLicEmp.feicoesAplicacoesBatch)){
            this.data.geoLicEmp.feicoesAplicacoesBatch.forEach(fc => {
                data.push({
                    descricao: fc.camada.title,
                    wkt: fc.feicao.wkt || '',
                    fc: fc
                });
            });
            this.dataSource = new MatTableDataSource(data);
        }
    }
    copyWKT(wkt: string){
        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (wkt));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    }
    removeFeicao(element){
        console.log(element)
        this.data.geoLicEmp.feicoesAplicacoesBatch = this.data.geoLicEmp.feicoesAplicacoesBatch.filter(obj => obj !== element.fc);
        this.data.geoLicEmp.componenteMapa.deleteFeature(element.fc.geometria.feature);
        this.parse();
    }
    onNoClick(): void {
        this.dialogRef.close(false);
    }
    clear(): void {
        this.data.geoLicEmp.feicoesAplicacoesBatch.forEach(fc => {
            this.data.geoLicEmp.componenteMapa.deleteFeature(fc.geometria.feature);
        });
        this.data.geoLicEmp.feicoesAplicacoesBatch = [];
        this.dialogRef.close(false);
    }
    confirm(): void {
        this.dialogRef.close(true);
    }
}
