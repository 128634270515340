import {Injectable, Injector} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BaseCorporativoService} from './base-corporativo.service';
import {ResponsavelTecnico} from '../models/responsavel-tecnico.model';
import {Pessoa} from '../models/pessoa.model';
import { ResponsavelTecnicoCompletoDto } from '../models/responsavel-tecnico-completo-dto.model';
import { PessoaCompletoDto } from '../models/pessoa-completo-dto.model';
import {HttpParams} from "@angular/common/http";
import {any} from "codelyzer/util/function";

@Injectable({
    providedIn: 'root',
})
export class ConfiguracaoAvisosAutomaticosService extends BaseCorporativoService<any> {
    constructor(protected injector: Injector) {
        super(
            '/api',
            injector,
            {},
            any
        );
    }

    getAvisosAutomaticosPorDescricao(descAvisoAutomatico: string): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('assunto', descAvisoAutomatico);

        const url = `${this.urlResourceCorporativo}/config-avisos-automatico/filter-assunto`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}
