import {Injectable, Injector} from '@angular/core';
import {NavigationError, Router, RoutesRecognized} from '@angular/router';
import {filter} from 'rxjs/operators';

const ErrorCategories = {
    UNAUTHORIZED: [401],
    NOT_FOUND: [404]
};

@Injectable()
export class RoutingStateService {
    private history = [];
    private historyQueryParams = [];

    constructor(
        private injector: Injector,
        private router?: Router
    ) {
    }

    public loadRouting(): void {
        if (this.router == null) {
            this.router = this.injector.get(Router);
        }
        this.router.events.pipe(filter(event => event instanceof RoutesRecognized))
            .subscribe((route: RoutesRecognized) => {
                this.history = [...this.history, route.urlAfterRedirects];
            });
        this.router.events.pipe(filter(event => event instanceof NavigationError))
            .subscribe((navError: NavigationError) => {
                console.log('route.state error:', navError);

                if (navError.error && ErrorCategories.UNAUTHORIZED.includes(navError.error.status)) {
                    return this.router.navigateByUrl('/error/error-401', {replaceUrl: true});
                }

                if (navError.error && ErrorCategories.NOT_FOUND.includes(navError.error.status)) {
                    return this.router.navigateByUrl('/error/error-404', {replaceUrl: true});
                }

                this.router.navigateByUrl('/error/error-500', {replaceUrl: true});
            });
    }

    public getHistory(): string[] {
        return this.history;
    }

    public getPreviousUrl(): string {
        return this.history[this.history.length - 2] || '/index';
    }

    public addQueryParamsHistory(queryParams: any) {
        this.historyQueryParams.push(queryParams);
    }

    public getQueryParamsLast() {
        return this.historyQueryParams[this.historyQueryParams.length - 1] || {};
    }


}

