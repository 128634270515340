import {BaseModel} from 'app/main/shared/models/base.model';

export class DominioDto extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(json: any): DominioDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new DominioDto(
            json.id ? json.id : null,
            json.especificacao ? json.especificacao : null
        );
    }
}
