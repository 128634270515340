import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GestaoManifestacaoService {

    protected http: HttpClient;
    urlResource: string = environment.URL_GESTAO_MANIFESTACAO_API;

    constructor(
        protected injector: Injector
    ) {
        this.http = injector.get(HttpClient);
    }

    getBySolicitacaoId(id: number): Observable<any> {
        const url = `${this.urlResource}/api/solicitacao/${id}`;

        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarAnexosSolicitacaoPorRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/api/solicitacao-anexo/buscar-anexos-por-requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarAnexosRespostaPorRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/api/resposta-anexo/buscar-anexos-por-requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }
    private handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
