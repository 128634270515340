import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ResponsavelTecnico} from '../../../models/responsavel-tecnico.model';
import {RequerimentoService} from '../../../../pages/requerimentos/requerimento.service';
import {DocumentoRL} from '../../../../pages/requerimentos/shared/requerimento-responsaveis/representante-legal/documento-rl.model';
import {EnumTipoDocumentoRL} from '../../../../pages/requerimentos/shared/requerimento-responsaveis/representante-legal/enum-tipo-documento-rl.model';
import {RepresentanteLegal} from '../../../models/representante-legal.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import { ResponsaveisCorporativoService } from 'app/main/shared/services/responsaveis-corporativo.service';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { PessoaCompletoDto } from 'app/main/shared/models/pessoa-completo-dto.model';
import { Documento } from 'app/main/shared/models/documento.model';
import {environment} from '../../../../../../environments/environment';

@Component({
    templateUrl: 'representante-dialog.component.html',
    styleUrls: ['representante-dialog.component.scss'],
})

export class RepresentateDialogComponent implements OnInit {
    requerimento = new Requerimento();
    representanteLegal: ResponsavelTecnico;
    anexos: DocumentoRL[] = [];
    pessoaCorporativoCompleto: PessoaCompletoDto;
    cadastroAtualizadoAposRequerimento: boolean = false;

    constructor(private dialogRef: MatDialogRef<RepresentateDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private snackBarService: SnackBarService,
                private responsaveisCorporativoService: ResponsaveisCorporativoService,
                protected requerimentoService: RequerimentoService) {

    }

    ngOnInit(): void {
        this.requerimento = this.data.requerimento;
        this.representanteLegal = this.data.representanteLegal;
        this.pesquisarRepresentanteLegal(this.representanteLegal.pessoa.cpfCnpj);
        this.visualizarRepresentanteLegal(this.representanteLegal);
    }

    visualizarRepresentanteLegal(responsavelTecnico: RepresentanteLegal): void {
        this.requerimentoService.listarDocumentosDoRepresentanteLegal(
            this.representanteLegal.requerimento.id,
            responsavelTecnico.pessoa.cpfCnpj).subscribe((result) => {
            // TODO: Verificar se é mesmo para mostrar apenas os não sigilisos
            this.anexos = result.filter(resultado => !resultado.sigilo);
        }, error => {
            this.snackBarService.showError('Erro ao carregar documentos do Representante legal.', error);
        });

    }

    pesquisarRepresentanteLegal(cpfCnpj): void {
        this.responsaveisCorporativoService.getPessoaCompletobyCpfCnpj(cpfCnpj)
            .subscribe((pessoaCompleto) => {
                    this.pessoaCorporativoCompleto = pessoaCompleto;

                    let dataAberturaRequerimento = new Date(this.requerimento.dataAbertura);
                    let dataAtualizacaoPessoa = new Date(this.pessoaCorporativoCompleto.dataAtualizacao);

                    this.cadastroAtualizadoAposRequerimento = dataAtualizacaoPessoa > dataAberturaRequerimento;

                }, error => {
                    this.snackBarService
                        .showError('Erro ao pesquisar o responsável pelo imóvel no corporativo', error);
                }
            );
    }

    public visualizarDocumento(documento: DocumentoRL): void {
        this.requerimentoService.downloadDocumentoRepresentanteLegal(documento.representanteLegal.requerimento.id, documento).subscribe((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    public downloadDocumento(documento: DocumentoRL): void {
        window.open(
            `${this.requerimentoService.urlResource}/${documento.representanteLegal.requerimento.id}`
            + `/representantes-legais/${documento.representanteLegal.pessoa.cpfCnpj}`
            + `/documentos/${EnumTipoDocumentoRL.toJson(documento.tipo)}`
        );
    }

    enderecoCompleto(endereco: any): string {
        return `${endereco.tipo.toLowerCase()} - ${endereco.tipoLogradouro.descricao} ${endereco.logradouro}, ${endereco.numero},
            ${endereco.bairro}, ${endereco.municipio.nome} - ${endereco.municipio.uf}`
    }

    telefonesToString(telefones: Array<any>): string {
        let telefonesString: string = '';
        telefones.forEach(telefone => {
            telefonesString += `${telefone.tipo.toLowerCase()} - (${telefone.ddd}) ${telefone.numero}, `;
        });
        return telefonesString;
    }

    emailsToString(emails: Array<any>): string {
        let emailsString: string = '';
        emails.forEach(email => {
            emailsString += `${email.tipo.toLowerCase()} - ${email.email}, `;
        });
        return emailsString;
    }

    isPessoaJuridica(): boolean {
        return this.pessoaCorporativoCompleto.tipo && this.pessoaCorporativoCompleto.tipo == 'PJ';
    }

    public visualizarDocumentoP(documento: Documento): void {
        window.open(
            environment.URL_SCORP_API +
            `/api/documentos/${documento.id}/visualizar`
        );
    }

    public downloadDocumentoP(documento: Documento): void {
        window.open(
            environment.URL_SCORP_API + `/api/documentos/${documento.id}/download`
        );
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }
}
