import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
    selector: 'app-tipos-construtivos',
    templateUrl: './tipos-construtivos.component.html',
    styleUrls: ['./tipos-construtivos.component.scss']
})
export class TiposConstrutivosComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;

    tipoConstrutivo: FormControl;

    tipos: Array<DominioDto> = [];

    constructor(private dominiosService: DominiosService) {
    }

    async ngOnInit() {
        this.tipoConstrutivo = this.form.get('tipoConstrutivo') as FormControl;
        this.tipos = await this.dominiosService.getDominio("TIPO_CONSTRUTIVOS").toPromise();
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }
}
