import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {RequerimentoOutorgaFinalidadeService} from '../../../../../shared/services/requerimento-outorga-finalidade.service';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {Municipio} from '../../../../../shared/models/geoportal-base-referencia/municipio.model';
import {MunicipioService} from '../../../../../shared/services/geoportal-base-referencia/municipio.service';

@Component({
    selector: 'app-finalidade-esgotamento-sanitario',
    templateUrl: './finalidade-esgotamatendo-sanitario.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})

export class FinalidadeEsgotamentoSanitarioComponent extends BaseFinalidadeComponent implements OnInit {
    municipios: Array<Municipio>;
    form: FormGroup;
    formEsgotamento: FormGroup;
    compareWithMunicipio = Municipio.compareWith;

    constructor(
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private municipioService: MunicipioService,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildResourceForm();
        this.patchForm();
        this.recuperarTodosMunicipiosMt();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    private recuperarTodosMunicipiosMt(): void {
        this.municipioService.getAllByUf('MT').subscribe(municipios => {
            this.municipios = municipios;
        });
    }

    private patchForm(): void {
        this.form.patchValue({
            id: this.requerimentoOutorgaFinalidade.id,
            finalidade: this.requerimentoOutorgaFinalidade.finalidade,
            requerimentoOutorga: this.requerimentoOutorgaFinalidade.requerimentoOutorga
        });
        if (this.requerimentoOutorgaFinalidade.abastecimentoEsgotamento) {
            this.form.patchValue({
                abastecimentoEsgotamento: this.requerimentoOutorgaFinalidade.abastecimentoEsgotamento
            });
        }
    }

    protected buildResourceForm(): void {
        this.formEsgotamento = this.formBuilder.group({
            id: [null],
            municipio: [null, [Validators.required]],
            distrito: [null, [Validators.maxLength(255)]],
            quantitativoPopulacaoMunicipio: [null, [Validators.required, Validators.min(1)]],
            quantitativoPopulacao: [null, [Validators.required, Validators.min(1)]],
            consumoDiario: [null, [Validators.required]],
            coeficienteDiaMaior: [null, [Validators.required]],
            taxaCrescimento: [null, [Validators.required, Validators.max(100), Validators.min(-100)]],
            coeficienteHoraMaior: [null, [Validators.required]],
            anoFinal: [null, [Validators.required, Validators.maxLength(4), Validators.min(1900)]],
            populacaoFinal: [null, [Validators.required, Validators.max(2147483647)]],
            vazaoFinalProjeto: [null, [Validators.required, Validators.min(0.0001)]],
            coeficienteRetorno: [null, [Validators.required]],
        });

        this.form = this.formBuilder.group({
            id: [null],
            finalidade: [null, [Validators.required]],
            requerimentoOutorga: [null, [Validators.required]],
            abastecimentoEsgotamento: this.formEsgotamento,
            observacao: [null, Validators.maxLength(3000)],
            cadastroConcluido: [null]
        });
    }

    salvar(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.form.patchValue({cadastroConcluido: true});
            this.requerimentoOutorgaFinalidadeService.update(this.form.value).subscribe(
                requerimentoOutorgaFinalidade => {
                    Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                    this.form.patchValue(requerimentoOutorgaFinalidade);
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Esgotamento sanitário salvo com sucesso.');
                },
                (e) => this.snackBarService.showError('Erro ao atualizar esgotamento sanitário.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }
}


