import {BaseModel} from 'app/main/shared/models/base.model';


export class PessoaJuridicaCorporativo extends BaseModel {
    constructor(
        public id?: number,
        public cnpj?: string,
        public razaoSocial?: string,
        public nomeFantasia?: string,
        public inscricaoMunicipal?: string,
        public inscricaoMunicipalUF?: string,
        public inscricaoEstadual?: string,
        public inscricaoEstadualUF?: string,
        public naturezaJuridica?: string,
        public porteEmpresa?: string,
        public dataConstituicao?: Date,
        public pessoa?: any,
    ) {
        super();
    }


    static fromJson(json: any): PessoaJuridicaCorporativo {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaJuridicaCorporativo(
            json.id,
            json.cnpj,
            json.razaoSocial,
            json.nomeFantasia,
            json.inscricaoMunicipal,
            json.inscricaoMunicipalUF,
            json.inscricaoEstadual,
            json.inscricaoEstadualUF,
            json.naturezaJuridica,
            json.porteEmpresa,
            new Date(json.dataConstituicao),

            json.pessoa,
        );
    }

    static fromJsons(json: any): PessoaJuridicaCorporativo[] {
        const pessoas: PessoaJuridicaCorporativo[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(pessoa => {
                pessoas.push(this.fromJson(pessoa));
            });
            return pessoas;
        }
    }

    _toString() {
        return (this.razaoSocial != null ? this.razaoSocial : this.nomeFantasia) + (this.cnpj ? ' - ' + this.cnpj : '');
    }
}
