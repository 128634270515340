import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {UsinaTermoeletricaDto} from 'app/main/shared/models/usina-termoeletrica-dto.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpreendimentoEnergeticoTermicaService {

    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_LIC_EE_TERMICA;


    salvarEmpreendimentoEnergetico(empreendimentosEnergeticoDto: UsinaTermoeletricaDto): Observable<UsinaTermoeletricaDto> {
        const url = `${this.urlResource}/api/empreendimento-termica`;
        return this.http.post(url, empreendimentosEnergeticoDto)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscaEEtermicaPorId(idEEtermico: number): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/find-eeTermica-id/${idEEtermico}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscarDominiosFiltrados(): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/busca-unidades-medidades`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscaListUsinaFossil(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/usinas-fossil/${idEEtermica}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarUsinaFosilPorId(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/ee-usina-fossil/delete/${idEEtermica}`;
        return this.http.delete(url);
    }

    buscaListUsinaBiomassa(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/usinas-biomassa/${idEEtermica}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarUsinaBiomassaPorId(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/ee-usina-biomassa/delete/${idEEtermica}`;
        return this.http.delete(url);
    }

    buscaListUnidadeContingente(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/unidades-contingencia/${idEEtermica}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarUnidContingentePorId(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/ee-unid-contigencia/delete/${idEEtermica}`;
        return this.http.delete(url);
    }

    buscaListEquipamentoMotriz(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/equipamentos-motriz/${idEEtermica}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarEquipamentoMotrizPorId(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/ee-equipe-motriz/delete/${idEEtermica}`;
        return this.http.delete(url);
    }

    buscaListGeradorVapor(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/geradores-vapor/${idEEtermica}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarGeradorVaporPorId(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/ee-gerador-vapor/delete/${idEEtermica}`;
        return this.http.delete(url);
    }

    buscaListGeradorEletrico(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/geradores-eletrico/${idEEtermica}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarGeradorEletricoPorId(idEEtermica: number): Observable<any> {
        const url = `${this.urlResource}/api/ee-gerador-eletrico/delete/${idEEtermica}`;
        return this.http.delete(url);
    }

    buscaEEtermicaPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/api/empreendimento-termica/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }
}