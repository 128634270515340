import {BaseModel} from 'app/main/shared/models/base.model';
import {TermoReferencia} from "../../pages/termos-referencia/termo-referencia.model";
import {RequerimentoLicenciamento} from "../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model";

export class TermoReferenciaRequerimentoDto extends BaseModel {
    constructor(
        public requerimentoLicenciamento?: RequerimentoLicenciamento,
        public termosReferencia?: TermoReferencia[],
    ) {
        super();
    }


    static fromJson(json: any): TermoReferenciaRequerimentoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new TermoReferenciaRequerimentoDto(
            RequerimentoLicenciamento.fromJson(json.requerimentoLicenciamento),
            TermoReferencia.fromJsons(json.termosReferencia)
        );
    }

    static fromJsons(json: any): TermoReferenciaRequerimentoDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((resource: any) =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }
}
