import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Ponto} from 'app/main/shared/models/hidrico-models/ponto.model';
import {VisualizarGeometriaDialogComponent} from 'app/main/shared/components/visualizar-geometria-dialog/visualizar-geometria-dialog.component';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {MicrobaciaInfoResponse} from "../../models/hidrico-models/microbacia-info-response.model";
import {HidricoService} from "../../services/hidrico-service/hidrico.service";
import {GeoportalBaseReferenciaService} from "../../services/geoportal-base-referencia/geoportal-base-referencia.service";
import {GeometriaDto} from "../../models/geoportal-base-referencia/geometria-dto.model";
import {FormUtil} from "../../util/form-util";

@Component({
    selector: 'app-informacoes-geograficas',
    templateUrl: './informacoes-geograficas.component.html'
})
export class InformacoesGeograficasComponent implements OnInit {


    private _ponto: Ponto;
    @Input() set ponto(ponto: Ponto) {
        this._ponto = ponto;
    }

    get ponto(): Ponto {
        return this._ponto;
    }

    form: FormGroup;
    microbaciaInfoResponse: MicrobaciaInfoResponse;
    areasRestritas: GeometriaDto[];
    provinciasHidrologicas: GeometriaDto[];

    constructor(
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        protected snackBarService: SnackBarService,
        private hidricoService: HidricoService,
        private geoportalBaseReferenciaService: GeoportalBaseReferenciaService
    ) {
    }

    ngOnInit(): void {
        this.hidricoService.consultarInfoMicrobacia(this.ponto.idMicrobacia).subscribe(result => {
            this.microbaciaInfoResponse = result;
        });
        this.geoportalBaseReferenciaService.listarAreasRestritas(this.ponto).subscribe(result => {
            this.areasRestritas = result;
        });
        this.geoportalBaseReferenciaService.listarProvinciasHidrologicas(this.ponto).subscribe(result => {
            this.provinciasHidrologicas = result;
        });
    }

    get areasRestritasList(): string {
        return this.areasRestritas && this.areasRestritas.length > 0
            ? 'O ponto está dentro ou nos limites do(a) ' + this.areasRestritas.map(a => a.descricao).join(', ')
            : 'O ponto não está dentro ou nos limites de nenhuma área especial.';
    }

    get provinciasHidrologicasList(): string {
        return this.provinciasHidrologicas && this.provinciasHidrologicas.length > 0
            ? this.provinciasHidrologicas.map(a => a.descricao).join(', ')
            : 'O ponto não está dentro ou nos limites de nenhuma província hidrológica.';
    }

    get isObjetivoSubterraneo(): boolean {
        return this._ponto.objetivoRequerimento.objetivo.tipoOutorga === 'SUBTERRANEA';
    }

    verMapa(): void {
        this.dialog.open(VisualizarGeometriaDialogComponent, {
            data: {
                id: this.ponto.id
            }
        });
    }

    convertDEGToDMS(deg: number, lat: boolean): string {
        return FormUtil.convertDEGToDMS(deg, lat);
    }

}
