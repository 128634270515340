import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {HistoricoAlteracaoRt} from '../historico-alteracao.rt';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-dialog-historico-rt',
    templateUrl: './dialog-historico-rt.component.html',
    styleUrls: ['./dialog-historico-rt.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])]
})
export class DialogHistoricoRtComponent implements OnInit {
    expandedElement: HistoricoAlteracaoRt | null;

    dataSource: MatTableDataSource<HistoricoAlteracaoRt>;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dataSource = new MatTableDataSource<HistoricoAlteracaoRt>();
    }

    ngOnInit(): void {
        this.dataSource.data = this.data.historico;
    }

}
