import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Dominio} from '../../../../../../../../shared/models/dominio-model';
import {DominioService} from '../../../../../../../../shared/services/dominio.service';
import {DominioProperties} from '../../../../../../../../shared/models/dominio-properties';
import {SnackBarService} from '../../../../../../../../shared/snack-bar/snack-bar.service';
import {EmpreendimentoEnergiaFotovoltaicaService} from '../../../../../../../../shared/services/empreendimento-energia-fotovoltaica.service';
import {EnergiaFotovoltaicaUnidContigencia} from '../../../../../../../../shared/models/energia-fotovoltaica-unid-contigencia.model';

@Component({
    selector: 'app-painel-unidade-contigencia',
    templateUrl: './painel-unidade-contigencia.component.html',
    styleUrls: ['./painel-unidade-contigencia.component.scss']
})
export class PainelUnidadeContigenciaComponent implements OnInit, OnChanges {

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Input() id: number;
    @Input() readonly = false;
    @Input() mostrarObrigatorioUnidadeContig;
    @Input() isNaoPodeEditar: boolean;

    form: FormGroup;
    tipos: Dominio[] = [];
    mapTipos = new Map();
    tiposBiomassa: Dominio[] = [];
    tiposFossil: Dominio[] = [];
    mapTipoCombustivel = new Map();
    mapSubTipoCombustivel = new Map();

    tipoCombustiveis: Dominio[] = [];
    subtiposCombustivel: Dominio[] = [];
    subtiposBiomassa: Dominio[] = [];
    listaSubtipos: Dominio[] = [];
    tipoCombustivel: string;
    outroTipoCombustivel = false;
    outroSubTipoCombustivel = false;
    dataSource: EnergiaFotovoltaicaUnidContigencia[] = [];
    displayedColumns: string[] = [
        'identificacao',
        'potenciaInstalada',
        'tipoCombustivel',
        'subtipo',
        'descricao',
    ];

    constructor(private fb: FormBuilder,
                private dominioService: DominioService,
                private snackBarService: SnackBarService,
                private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService) {
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            id: [''],
            identificaoUnidadeGestora: ['', [Validators.required]],
            potenciaInstalada: ['', [Validators.required]],
            tipo: ['', Validators.required],
            tipoCombustivel: ['', Validators.required],
            tipoOutroDescricao: ['', Validators.required],
            subTipo: ['', Validators.required],
            subTipoOutroDescricao: [ '', Validators.required],
        });

        this.form.get('tipoOutroDescricao').disable();
        this.form.get('subTipoOutroDescricao').disable();

        if(this.mostrarObrigatorioUnidadeContig){
            this.form.markAsDirty();
            this.form.markAllAsTouched();
        }

        this.dominioService.buscarTipoUnidadeContigencia()
            .subscribe( retorno => {
                this.tipos = retorno;
                this.tipos.forEach( tipo => {
                    this.mapTipos.set( tipo.id, tipo.descricao );
                } );
            }, err => {

                console.error( err );
            } );

        this.dominioService.buscarSubTipoCombustivel()
            .subscribe( retorno => {
                this.subtiposCombustivel = retorno;
                this.subtiposCombustivel.forEach( item => {
                    this.mapSubTipoCombustivel.set( `2-${item.id}`, item.descricao );
                } );

            }, err => {

                console.error( err );
            } );

        this.dominioService.buscarSubTipoBiomassa()
            .subscribe( retorno => {
                this. subtiposBiomassa = retorno;
                this.subtiposBiomassa.forEach( item => {
                    this.mapSubTipoCombustivel.set( `1-${item.id}`, item.descricao );
                } );
            }, error => {
                console.error( error );
            } );


        this.dominioService.buscarTipoBiomassa()
            .subscribe( retorno => {

                this.tiposBiomassa = retorno;
                this.tiposBiomassa.forEach( item => {
                    this.mapTipoCombustivel.set( `1-${item.id}`, item.descricao );
                } );
            }, error => {

                console.error( error );
            } );

        this.dominioService.buscarTipoCombustivel()
            .subscribe( retorno => {
                this.tiposFossil = retorno;
                this.tiposFossil.forEach( item => {
                    this.mapTipoCombustivel.set( `2-${item.id}`, item.descricao );
                } );
            }, error => {
                console.error( error );
            } );

    }

    filtroRegex(formName){
        var input = this.form.get(formName).value;
        input = input.replace(/[^0-9]/g, "");
        this.form.get(formName).patchValue(input);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if ( changes.id ) {
            this.carregar(changes.id.currentValue);
        }

        if ( changes.readonly && !changes.readonly.currentValue ) {
            this.displayedColumns.push( 'acao' );
        }

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    excluir( objeto: EnergiaFotovoltaicaUnidContigencia ): void {

        this.empreendimentoEnergiaFotovoltaicaService.deletarUnidContigencia( objeto.id )
            .subscribe( () => {

                this.snackBarService.showSuccess( 'Unidade de contigencia excluída com sucesso!' );
                this.carregar( +this.id );
            }, error => {

                this.snackBarService.showError( 'Falha ao excluir Unidade de contigencia.' );
                console.error( error );
            } );
    }

    editar( objeto: EnergiaFotovoltaicaUnidContigencia ): void {

        if (objeto.idTipoUnidadeContigencia === 1) {
            this.tipoCombustivel = 'biomassa';
        } else if (objeto.idTipoUnidadeContigencia === 2) {
            this.tipoCombustivel = 'fossil';
        }

        this.onChangeTipo({ value: objeto.idTipoUnidadeContigencia } );
        this.onChangeTipoCombustivel( { value: objeto.idTipoCombustivel }, false );
        this.onChangeSubtipoCombustivel( { value: objeto.idSubtipoCombustivel } );

        this.form.patchValue({
            id: objeto.id,
            identificaoUnidadeGestora: objeto.identificacao,
            potenciaInstalada: objeto.potenciaInstaladaKw,
            tipo: objeto.idTipoUnidadeContigencia,
            tipoCombustivel: objeto.idTipoCombustivel,
            subTipo: objeto.idSubtipoCombustivel,

            tipoOutroDescricao: objeto.descricaoOutroTipo,
            subTipoOutroDescricao: objeto.descricaoOutroSubtipo,
        });

        //Id do tipo "Outro"
        if(objeto.idTipoCombustivel == 6){
            this.form.controls.subTipo.disable();
        }else{
            this.form.controls.subTipo.enable();
        }
    }

    onChangeTipo( evt: any ): void {

        if (evt.value === 1) {
            this.tipoCombustivel = 'biomassa';
            this.tipoCombustiveis = this.tiposBiomassa;
        } else if (evt.value === 2) {
            this.tipoCombustivel = 'fossil';
            this.tipoCombustiveis = this.tiposFossil;
        }
    }

    onChangeTipoCombustivel( evt: any, carregar = true ): void {
        const {value} = evt;

        if ( this.tipoCombustivel === 'fossil' ) {
            this.listaSubtipos = this.subtiposCombustivel.filter(item => +item.idTipoCombustivel === value);
        } else if ( this.tipoCombustivel === 'biomassa' ) {
            this.listaSubtipos = this.subtiposBiomassa.filter(item => +item.idTipoBiomassa === value);
        }

        this.outroSubTipoCombustivel = false;
        if (carregar) {
            this.form.patchValue( { subTipo: null, subTipoOutroDescricao: null } );
        }

        const itemSelecionado = this.tipoCombustiveis.find( item => +item.id === +value );

        this.outroTipoCombustivel = itemSelecionado && itemSelecionado.descricao.toUpperCase() == 'OUTRO';

        if(itemSelecionado.descricao == 'Outro'){
            this.form.get('tipoOutroDescricao').enable();
            this.form.controls.subTipo.disable();
        }else{
            this.form.get('tipoOutroDescricao').disable();
            this.form.controls.subTipo.enable();
        }
        this.form.get('tipoOutroDescricao').patchValue('');
    }

    onChangeSubtipoCombustivel( evt: any ): void {
        const {value} = evt;

        const itemSelecionado: Dominio = this.listaSubtipos.find(item => +item.id === +value );
        this.outroSubTipoCombustivel = itemSelecionado && itemSelecionado.descricao.toUpperCase() == 'OUTRO';

        this.form.get('subTipoOutroDescricao').patchValue('');
        if(this.outroSubTipoCombustivel){
            this.form.get('subTipoOutroDescricao').enable();
        }else{
            this.form.get('subTipoOutroDescricao').disable();
        }
    }

    saveForm(): void {

        if (!this.form.invalid) {
            const form = this.form.getRawValue();

            const entidade: EnergiaFotovoltaicaUnidContigencia = {
                id: form.id,
                identificacao: form.identificaoUnidadeGestora,
                potenciaInstaladaKw: form.potenciaInstalada,
                idTipoUnidadeContigencia: form.tipo,
                idTipoCombustivel: form.tipoCombustivel,
                idSubtipoCombustivel: form.subTipo,
                descricaoOutroTipo: form.tipoOutroDescricao,
                descricaoOutroSubtipo: form.subTipoOutroDescricao,
                empreendimentoEnergFotovoltaica: { id: +this.id }
            };

            this.empreendimentoEnergiaFotovoltaicaService.salvarUnidContigencia(entidade)
                .subscribe( retorno => {

                    this.carregar(+this.id);
                    this.snackBarService.showSuccess( 'Informações salvas com sucesso!' );
                    this.save.emit(retorno);
                    this.form.reset();
                    this.form.markAsPristine();
                    this.form.markAsUntouched();
                    this.form.updateValueAndValidity();
                }, error => {

                    this.snackBarService.showError( 'Falha ao salvar informações!' );
                    console.error( error );
                } );
            this.form.controls.subTipo.enable();
        } else {
            this.form.markAllAsTouched();
            this.snackBarService.showError( 'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)' );
        }
    }

    private carregar( id: number ): void {

        this.empreendimentoEnergiaFotovoltaicaService.listarUnidContigencia( id )
            .subscribe( lista => {
                this.dataSource = lista;
            }, error => {

                this.snackBarService.showError( 'Falha ao listar as unidades de contigências' );
                console.error( error );
            } );
    }
}
