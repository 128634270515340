import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {MatDialog} from '@angular/material';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {ImovelService} from '../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.service';
import {Requerimento} from '../../../pages/requerimentos/requerimento.model';
import {Camada, CamadaFlatNode} from '@sema-geo/sema-geoportal';
import {MapaPadraoComponent} from '@sema-geo/sema-geoportal';
import {RequerimentoOutorgaService} from "../../../pages/requerimentos/requerimento-outorga/requerimento-outorga.service";
import {RequerimentoOutorga} from "../../../pages/requerimentos/requerimento-outorga/requerimento-outorga.model";
import {PontoService} from "../../services/ponto.service";
import {Imovel} from "../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model";
import {DecimalPipe} from "@angular/common";
import {CommonsUtil} from "../../util/commons-util";

@Component({
    selector: 'app-aba-dados-geograficos',
    templateUrl: 'aba-dados-geograficos.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AbaDadosGeograficosComponent {

    @Input()
    requerimento = new Requerimento();
    configPipe = '1.0-6';
    localizacao: Camada = {
        title: 'Localização',
        name: 'localizacao',
        geometryGroup: true,
        expandGroup:true,
        camadas: []
    };

    feicoesRequerimento: Camada = {
        title: 'Feições do Requerimento',
        name: 'requerimento',
        geometryGroup: true,
        expandGroup:true,
        camadas: []
    };
    camadaTrabalho: Camada = {
        title: 'Camada de trabalho',
        name: 'trabalho',
        geometryGroup: true,
        expandGroup:true,
        permissao: {
            ponto: true,
            linha: true,
            poligono: true,
            buffer: true,
            exportar: true,
            remover: true,
            upload: true
        },
        camadas: []
    };
 
    camadas: Camada[] = [this.localizacao, this.feicoesRequerimento];
    camadaSelecionada: Camada = this.camadaTrabalho;

    @ViewChild('mapaPadraoComponent', {static: false})
    componenteMapa: MapaPadraoComponent;

    requerimentoOutorga: RequerimentoOutorga;

    constructor(
        protected requerimentoOutorgaService: RequerimentoOutorgaService,
        protected pontoService: PontoService,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected imovelService: ImovelService,
        protected decimalPipe: DecimalPipe
    ) {
    }

    carregamentoCompleto(): void {
        this.buscarRequerimentoOutorga();
    }

    buscarRequerimentoOutorga(): void {
        this.requerimentoOutorgaService.getByRequerimentoId(this.requerimento.id).subscribe(async requerimentoOutorga => {
            this.requerimentoOutorga = requerimentoOutorga;
            await this.atualizarMapa();
            this.componenteMapa.fit();
        });
    }

    private async atualizarMapa(): Promise<void> {
        this.componenteMapa.deleteAllFeatures();

        await this.carregarLocalizacaoImovel();
        await this.carregarPontosRequerimento();

        this.camadas = [...this.camadas];
    }

    private async carregarLocalizacaoImovel(): Promise<void> {
        const imovel: Imovel = await this.imovelService.getByRequerimento(this.requerimento).toPromise();
        // Feições do imóvel.
        await this.imovelService.buscarTodosPorRequerimentoComGeometrias(this.requerimento.id).toPromise().then(imoveis => {
            this.localizacao.camadas = [];
            imoveis.forEach(imovel => {
                imovel.geometrias.forEach(geometria => {
                    const geometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt);
                    geometriaMapa.id = geometria.id;
                    geometriaMapa.permissao = {
                        remover: true
                    };
                    geometriaMapa.extra = geometria;

                    const tipo = imovel.tipo;
                    if (tipo === 'RURAL') {
                        const imovelPrincipal = imovel.imovelPrincipal ? '*' : '';
                        geometriaMapa.title = `${imovel.numeroCAR} ${imovelPrincipal}`;
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            Nome: geometriaMapa.title
                        };
                    } else if (tipo === 'URBANO') {
                        geometriaMapa.title = 'Área do imóvel urbano';
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            Nome: geometriaMapa.title
                        };

                        // Só permitir um único polígono para o imóvel urbano.
                        this.localizacao.permissao.poligono = false;
                    } else if (tipo === 'OBRA') {
                        geometriaMapa.title = geometriaMapa.tipoGeometria;
                        geometriaMapa.propriedades = {
                            id: geometria.id
                        };
                    }
                    geometriaMapa.visualizacao = {
                        showCheckbox: true,
                        showFeature: true
                    }
                    geometriaMapa['expandGroup'] = true;
                    geometriaMapa.feature.setStyle(this.componenteMapa.defaultStyle)
                    this.localizacao.camadas.push(geometriaMapa);
                });
            })
        });
    }

    private async carregarPontosRequerimento(): Promise<void> {
        this.feicoesRequerimento.camadas.sort((a, b) => CommonsUtil.comparaStrings(a['title'], b['title']));

        await this.pontoService.getPontos(this.requerimentoOutorga).toPromise().then(pontosOutorga => {
            this.feicoesRequerimento.camadas.forEach(camada => camada.camadas = []);
            pontosOutorga.forEach(ponto => {
                const p = this.componenteMapa.criarPonto(ponto.id, ponto.latitude, ponto.longitude);
                p.title = `[${ponto.id}] ${ponto.nomePonto || p.tipoGeometria}`;
                p.propriedades = {
                    ID: ponto.id,
                    Nome: ponto.nomePonto,
                    Objetivo: ponto.objetivoRequerimento ? ponto.objetivoRequerimento.objetivo.descricao : null,
                    Finalidade: ponto.finalidade? ponto.finalidade.descricao : null,
                    'Vazão': ponto.vazao ? this.decimalPipe.transform(ponto.vazao.toFixed(6), this.configPipe) : null,
                    'Concentração': ponto.concentracaoParametro ? this.decimalPipe.transform(ponto.concentracaoParametro.toFixed(6), this.configPipe) + " " + ponto.parametro.sigla : null,
                    'Parâmetro': ponto.parametro ? ponto.parametro.texto : null
                };

                if (!ponto.objetivoRequerimento) {
                    this.feicoesRequerimento.camadas.push(p);
                } else {
                    const camadaArvore = this.feicoesRequerimento.camadas.find(c => c.id === ponto.objetivoRequerimento.id);
                    if (camadaArvore) {
                        if (camadaArvore.camadas) {
                            camadaArvore.camadas.push(p);
                        } else {
                            camadaArvore.camadas = [p];
                        }
                    } else {
                        const novaCamada = {
                            id: ponto.objetivoRequerimento.id,
                            title: ponto.objetivoRequerimento.objetivo.descricao,
                            name: ponto.objetivoRequerimento.id.toString(10),
                            geometryGroup: true,
                            expandGroup: true,
                            camadas: [p]
                        } as Camada;
                        this.feicoesRequerimento.camadas.push(novaCamada);
                    }
                }
            });
            this.feicoesRequerimento.camadas.forEach((camada) => {
                const node: CamadaFlatNode = new CamadaFlatNode();
                node.item = camada.title.toUpperCase()
                node.camada = camada;
                node.camada.title = camada.title.toUpperCase()
                this.componenteMapa.camadasSelecionadas.unshift(node);
                camada.camadas.sort((a, b) => CommonsUtil.comparaStrings(a['title'], b['title']));
            })
            this.camadas = [...this.camadas];
        });
    }
}
