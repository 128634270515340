import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {DoencaGrave} from './doenca-grave.model';
import {Cemiterio} from "./cemiterio.model";
import {Crematorio} from "./crematorio.model";
import {ParcelamentoSolo} from "./parcelamento-solo.model";
import {ResidenciaisComerciais} from "./residenciais-comerciais.model";

export class SistemaTratamento extends BaseModel {
    constructor(
        public id?: number,
        public domestico?: boolean,
        public industrial?: boolean,
        public primario?: boolean,
        public secundario?: boolean,
        public terciario?: boolean,
        public complementar?: boolean,
        public individual?: boolean,
        public coletivo?: boolean,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): SistemaTratamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new SistemaTratamento(
            json.id,
            json.domestico,
            json.industrial,
            json.primario,
            json.secundario,
            json.terciario,
            json.complementar,
            json.individual,
            json.coletivo,
            json.requerimento
        );
    }

}
