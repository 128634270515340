import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DocumentoProcesso} from 'app/main/shared/models/documento-processo.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentoProcessoService extends BaseService<DocumentoProcesso> {

    constructor(protected injector: Injector) {
        super('/api/documento-processo', injector, DocumentoProcesso.prototype, DocumentoProcesso.fromJson);
    }

    listaDocumentosOficio(idRequerimento?: number): Observable<any> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/oficio-pendencia`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    stringHtmlParaPdf(html: string): Observable<Blob> {
        const url = `${this.urlResource}/html-para-pdf`;
        return this.http.post(url, html, {responseType: 'blob'}).pipe(
            map((res) => {
                return new Blob([res], {type: 'application/pdf'});
            }), catchError(() => this.handleError(this)));
    }

    atualizaComDadosDoTituloCancelado(documentoProcesso: DocumentoProcesso, 
        idDocProcessoACancelar: number, chaveDocProcessoTituloACancelar: string): Observable<any> {
        const url = `${this.urlResource}/atualiza-com-dados-titulo-cancelado/documento/${idDocProcessoACancelar}/chave/${chaveDocProcessoTituloACancelar}`;
        return this.http.put(url, documentoProcesso).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}
