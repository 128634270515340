import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
    templateUrl: './dialog-confirma-exclusao.component.html',
    styleUrls: ['./dialog-confirma-exclusao.component.scss']
})
export class DialogConfirmaExclusaoComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmaExclusaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
