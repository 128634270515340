import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ObjetivoLicenciamentoSubatividade} from "../models/objetivo-licenciamento-subatividade.model";
import {BaseService} from "./base.service";
import {ObjetivoLicenciamento} from "../models/objetivo-licenciamento.model";

@Injectable({
    providedIn: 'root'
})
export class ObjetivoLicenciamentoSubatividadeService extends BaseService<ObjetivoLicenciamentoSubatividade> {

    constructor(protected injector: Injector) {
        super(
            '/api/objetivos-licenciamento-subatividade',
            injector,
            ObjetivoLicenciamento.prototype,
            ObjetivoLicenciamento.fromJson
        );
    }

    getRequerimentoLicenciamentoPorId(idRequerimentoLicenciamento: number): Observable<any> {
        const url = `${this.urlResource}/requerimento-licenciamento/${idRequerimentoLicenciamento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    deletePorIdsLicenAndAtividade(requerimentoLicenciamentoId: number, idAtividade: number): Observable<any> {
        return this.http.delete(`${this.urlResource}/requerimento-licenciamento/
        ${requerimentoLicenciamentoId}/atividade/${idAtividade}`).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
}
