import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {AssuntoNota} from '../../../../shared/models/assunto-nota.model';
import {AssuntoNotaService} from '../../../../shared/services/assunto-nota.service';
import {CurrentUser} from '../../../../core/models/current-user.model';
import {Requerimento} from '../../requerimento.model';
import {RequerimentoNota} from '../requerimento-notas.model';
import {RequerimentoService} from '../../requerimento.service';
import * as moment from 'moment';
import {RequerimentoNotasComponent} from '../requerimento-notas.component';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-requerimento-notas-new',
    templateUrl: './requerimento-notas-new.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoNotasNewComponent implements OnInit {
    assuntos: Array<AssuntoNota>;
    form: FormGroup;
    requerimentoId = 0;
    @Input() titleUpdateEmitter;
    @Output() addButtonsEmitter = new EventEmitter();
    @Output() voltarEmitter = new EventEmitter();
    private requerimento: Requerimento;

    constructor(
        private authenticationService: AuthenticationService,
        private assuntoNotaService: AssuntoNotaService,
        private requerimentoService: RequerimentoService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackBarService: SnackBarService
    ) {

    }

    ngOnInit(): void {
        this.titleUpdateEmitter.emit('Adicionar Nota no Requerimento ' + this.requerimentoId);

        this.buildForm();

        this.loadRequerimento();

        this.loadAssuntos();

        // Adicionar botão específico da tela na modal
        const buttonSalvarNota = document.createElement('btnAddNota') as HTMLButtonElement;
        buttonSalvarNota.innerText = 'Salvar';
        buttonSalvarNota.click = () => {
            this.salvar();
        };

        const buttonVoltar = document.createElement('btnVoltar') as HTMLButtonElement;
        buttonVoltar.innerText = 'Voltar';
        buttonVoltar.click = () => {
            this.voltarEmitter.emit(RequerimentoNotasComponent);
        };

        this.addButtonsEmitter.emit([buttonVoltar, buttonSalvarNota]);
    }

    private get assunto(): AssuntoNota {
        const value = this.form.get('assuntoId').value;

        return value && value > 0 ? new AssuntoNota(value) : null;
    }

    private get analista(): string {
        const value = this.form.get('analista').value;

        return (value && value.length > 0 ? value.trim() : '').substring(0, 255);
    }

    private get analistaAutenticado(): string {
        let value = '';

        try {
            const usuario: CurrentUser = this.authenticationService.currentUserDecode;

            value = usuario && usuario.nome && usuario.sub ? `${usuario.nome} (${usuario.sub})` : '';
        } catch (error) {
            this.onError('O analista deve estar autenticado.');
        }

        return value;
    }

    private get comentario(): string {
        const value = this.form.get('comentario').value;

        return value && value.length > 0 ? value.trim() : '';
    }

    get hoje(): string {
        return moment().format('DD/MM/YYYY');
    }

    get salvarDisabled(): boolean {
        return this.form.invalid || this.form.pristine;
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            assuntoId: [null, [Validators.required]],
            analista: [this.analistaAutenticado, [Validators.required]],
            comentario: [null, [Validators.required, Validators.maxLength(500)]],
            dataCadastro: [this.hoje]
        });
    }

    private loadAssuntos(): void {
        this.assuntoNotaService.getAll().subscribe(
            assuntos => this.assuntos = assuntos,
            this.onError.bind(this)
        );
    }

    private loadRequerimento(): void {
        this.activatedRoute.params.subscribe(r => this.requerimento = new Requerimento(this.requerimentoId));
    }

    private onError(error): void {
        this.snackBarService.showError(error || 'Oops! ocorreu um problema inesperado.');
    }

    salvar(): void {
        if (this.assunto && this.analista && this.comentario) {
            const nota = new RequerimentoNota();

            nota.analista = this.analista;
            nota.assunto = this.assunto;
            nota.comentario = this.comentario;

            this.requerimentoService
                .createNota(this.requerimento.id, nota)
                .subscribe(
                    () => {
                        this.snackBarService.showSuccess('Nota adicionada com sucesso.');
                        this.voltarEmitter.emit(RequerimentoNotasComponent);
                    },
                    this.onError.bind(this)
                );
        } else {
            this.snackBarService.showError('Verifique os campos do formulário.');
        }
    }
}
