import {Component, Input, OnInit} from '@angular/core';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';

@Component({
    selector: 'app-aba-finalidade-abastecimento-publico',
    templateUrl: './aba-finalidade-abastecimento-publico.component.html'
})

export class AbaFinalidadeAbastecimentoPublicoComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;

    constructor() {
    }

    ngOnInit(): void {
    }

}


