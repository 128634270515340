import {Titulacao} from '../../pages/titulacoes/titulacao.model';
import {BaseEnum} from '../enums/base.enum';
import {BaseModel} from 'app/main/shared/models/base.model';

export class ObjetivoOutorga extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipoOutorga?: string,
        public geraVazao?: boolean,
        public exigeParametro?: boolean,
        public titulacao?: Titulacao,
        public formulario?: string,
        public tipoSolicitacao?: string,
        public balancoHidrico?: boolean,
        public diluicao?: boolean,
        public vazaoContinua?: boolean
    ) {
        super();
    }

    get isDRDH(): boolean {
        return this.formulario == 'DRDH';
    }

    get isSubterraneo(): boolean {
        return this.tipoOutorga == 'SUBTERRANEA';
    }

    static tiposOutorga: BaseEnum[] = [
        {
            valor: 'SUBTERRANEA',
            descricao: 'Subterrânea'
        },
        {
            valor: 'SUPERFICIAL',
            descricao: 'Superficial'
        },
        {
            valor: 'BARRAMENTO',
            descricao: 'Barramento'
        }
    ];

    static fromJson(json: any): ObjetivoOutorga {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObjetivoOutorga(
            json.id,
            json.descricao,
            json.tipoOutorga,
            json.geraVazao,
            json.exigeParametro,
            json.titulacao,
            json.formulario,
            json.tipoSolicitacao,
            json.balancoHidrico,
            json.diluicao,
            json.vazaoContinua
        );
    }
}
