import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {VinculacaoProcesso} from './model/vinculacao-processo.model';

@Injectable({
    providedIn: 'root'
})
export class VinculacaoProcessoService extends BaseService<VinculacaoProcesso> {

    constructor(protected injector: Injector) {
        super(
            '/api/vinculacao-processos',
            injector,
            VinculacaoProcesso.prototype,
            VinculacaoProcesso.fromJson
        );
    }

    findByRequerimentoPrincipal(idRequerimento: number): Observable<VinculacaoProcesso[]> {
        const url = `${this.urlResource}/requerimento-principal/${idRequerimento}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    associarRequerimento(juntadaProcessoDto: any): any {
        const url = this.urlResource;
        return this.http.post(url, juntadaProcessoDto).pipe(
            catchError(this.handleError.bind(this))
        );
    }


}
