import {ResponsavelTecnico} from './responsavel-tecnico.model';
import {EnumTipoDocumentoRT} from './enum-tipo-documento-rt.model';

/**
 * Model que define o documento do ResponsavelTecnico.
 */
export class DocumentoRTDto {

    constructor(
        public id?: number,
        public idResponsavelTecnico?: number,
        public chave?: string,
        public nome?: string,
        public nomeTemplate?: string
    ) {
    }

    static fromJson(json: any): DocumentoRTDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoRTDto(
            json.id,
            json.idResponsavelTecnico,
            json.chave,
            json.nome,
            json.nomeTemplate
        );
    }

    static fromJsons(json: any): DocumentoRTDto[] {
        const documentoRTS: DocumentoRTDto[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((documento) => {
                documentoRTS.push(DocumentoRTDto.fromJson(documento));
            });
            return documentoRTS;
        }
    }
}
