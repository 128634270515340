import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class QuadroColaboradores extends BaseModel {
    constructor(
        public id: number,
        public idCategoriaColaborador: number,
        public descricao: string,
        public numero: number,
        public mineracao: AtividadeMineracao,
    ) {
        super();
    }

    static fromJson(json: any): QuadroColaboradores {
        if (json === undefined || json === null) {
            return null;
        }
        return new QuadroColaboradores(
            json.id,
            json.idCategoriaColaborador,
            json.descricao,
            json.numero,
            json.mineracao,
        );
    }

}

