import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Finalidade} from "../../pages/finalidades/finalidade.model";
import {ParametroDiluicao} from "../models/hidrico-models/parametro-diluicao.model";

@Injectable({
    providedIn: 'root'
})
export class ParametroDiluicaoService {
    private urlResource = `${environment.URL_GATEWAY}/api/finalidade-parametro-diluicao`;

    constructor(private http: HttpClient) {
    }

    findAllByFinalidade(finalidades: Finalidade[], idMicrobacia: number): Observable<ParametroDiluicao[]> {
        let params = new HttpParams()
        finalidades.forEach(finalidade => params = params.append('idFinalidade',  finalidade.id.toString()))
        params = params.append('idMicrobacia', idMicrobacia.toString())

        return this.http.get(`${this.urlResource}/list-concentracao`, {params: params})
            .pipe(
                map(json => ParametroDiluicao.fromJsons(json)),
                catchError(e => throwError(e))
            );
    }

    findAllByMicrobacia(idMicrobacia: number): Observable<ParametroDiluicao[]> {
        let params = new HttpParams()
        params = params.append('idMicrobacia', idMicrobacia.toString())

        return this.http.get(`${this.urlResource}/list-all-concentracao`, {params: params})
            .pipe(
                map(json => ParametroDiluicao.fromJsons(json)),
                catchError(e => throwError(e))
            );
    }

    //Busca todos parametros de concentração pelo id microbacia diretamente no hidrico
    findAllByMicrobaciaHidrico(idMicrobacia: number): Observable<ParametroDiluicao[]> {
        let params = new HttpParams()
        params = params.append('microbaciaId', idMicrobacia.toString())

        return this.http.get(`${environment.URL_HIDRICO}/api/parametros/list-info`, {params: params})
            .pipe(
                map(json => ParametroDiluicao.fromJsons(json)),
                catchError(e => throwError(e))
            );
    }
}
