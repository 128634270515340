import {BaseModel} from 'app/main/shared/models/base.model';


export class ParqueBomba extends BaseModel {
    constructor(
        public id?: number,
        public quantidadeIlhas?: number,
        public quantidadeUnidadeAbastecimento?: number,
        public quantidadeBicos?: number
    ) {
        super();
    }

    static fromJson(json: any): ParqueBomba {
        if (json === undefined || json === null) {
            return null;
        }

        return new ParqueBomba(
            json.id,
            json.quantidadeIlhas,
            json.quantidadeUnidadeAbastecimento,
            json.quantidadeBicos,
        );
    }
}
