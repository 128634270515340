import {ModelUtil} from "../model-util";
import {MicrobaciaDisponibilidade} from "./microbacia-disponibilidade.model";

export class RedefinirDisponibilidadeResponse {
    constructor(
        public microbaciaId?: number,
        public bacia?: string,
        public codigoBacia?: string,
        public unidadeBacia?: string,
        public microbaciaDisponibilidadesDTO?: MicrobaciaDisponibilidade[],
        public historicoDisponibilidadeRedefinida?: MicrobaciaVazaoRedefinida[]
    ) {
    }

    static fromJson(json: any): RedefinirDisponibilidadeResponse {
        if (json === undefined || json === null) {
            return null;
        }

        return new RedefinirDisponibilidadeResponse(
            ModelUtil.getOrElseNull(json.microbaciaId),
            ModelUtil.getOrElseNull(json.bacia),
            ModelUtil.getOrElseNull(json.codigoBacia),
            ModelUtil.getOrElseNull(json.unidadeBacia),
            ModelUtil.getOrElseNull(MicrobaciaDisponibilidade.fromJsons(json.microbaciaDisponibilidadesDTO))
        );
    }

    static fromJsons(json: any): RedefinirDisponibilidadeResponse[] {
        const array: RedefinirDisponibilidadeResponse[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(RedefinirDisponibilidadeResponse.fromJson(item)));
            return array;
        }
    }
}

export class MicrobaciaVazaoRedefinida {
    constructor(
        public id?: number,
        public cpfExecutor?: string,
        public nomeExecutor?: string,
        public justificativa?: string,
        public dataCadastro?: Date
    ) {
    }

    static fromJson(json: any): MicrobaciaVazaoRedefinida {
        if (json === undefined || json === null) {
            return null;
        }

        return new MicrobaciaVazaoRedefinida(
            ModelUtil.getOrElseNull(json.id),
            ModelUtil.getOrElseNull(json.cpfExecutor),
            ModelUtil.getOrElseNull(json.nomeExecutor),
            ModelUtil.getOrElseNull(json.justificativa),
            ModelUtil.getOrElseNull(json.dataCadastro)
        );
    }

    static fromJsons(json: any): MicrobaciaVazaoRedefinida[] {
        const array: MicrobaciaVazaoRedefinida[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(MicrobaciaVazaoRedefinida.fromJson(item)));
            return array;
        }
    }
}
