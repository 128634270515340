import {BaseModel} from 'app/main/shared/models/base.model';
import {PessoaCorporativo} from './pessoa-corporativo.model';


export class PessoaFisicaCorporativo extends BaseModel {
    constructor(
        public id?: number,  // id no scorp
        public nome?: string,
        public email?: string,
        public telefone?: string,
        public sexo?: string,
        public cpfCnpj?: string,
        public dataNascimento?: Date,
        // pessoa
        public pessoa?: PessoaCorporativo,
    ) {
        super();
    }


    static fromJson(json: any): PessoaFisicaCorporativo {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaFisicaCorporativo(
            json.id,
            json.nome,
            json.email,
            json.telefone,
            json.sexo,
            json.cpfCnpj,
            new Date(json.dataNascimento),
            json.pessoa,
        );
    }

    static fromJsons(json: any): PessoaFisicaCorporativo[] {
        const pessoas: PessoaFisicaCorporativo[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(pessoa => {
                pessoas.push(this.fromJson(pessoa));
            });
            return pessoas;
        }
    }

    _toString() {
        return (this.nome != null ? this.nome : this.id) + (this.cpfCnpj ? ' - ' + this.cpfCnpj : '');
    }
}
