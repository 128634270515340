import { LagoaBaciaContencao } from "./../../../../shared/models/lagoa-bacia-contencao";
import { LagoaBaciaContencaoService } from "./../../../../shared/services/lagoa-bacia-contencao.service";
import { Dominio } from "./../../../../shared/models/dominio-model";
import { DominiosService } from "./../../../../shared/services/dominios.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SnackBarService } from "./../../../../shared/snack-bar/snack-bar.service";
import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-lagoa-bacia-contencao",
    templateUrl: "./lagoa-bacia-contencao.component.html",
    styleUrls: ["./lagoa-bacia-contencao.component.scss"],
})
export class LagoaBaciaContencaoComponent implements OnInit {

    // Id da geometria provisório para efeito de teste
    // receberá o id vindo de fora.
    @Input() idGeometria: number = 10;

    formLagoaBaciaContencao: FormGroup;
    tipoContencao: Dominio[] = [];
    tipoImpermeabilizacao: Dominio[] = [];

    constructor(
        private dominiosService: DominiosService,
        private formBuilder: FormBuilder,
        private lagoaBaciaContencaoService: LagoaBaciaContencaoService,
        private snackBar: SnackBarService
    ) {}

    ngOnInit() {
        this.lagoaBaciaContencaoBuild();
        this.buscarTipoImpermeabilizacao();
        this.buscarTipoContencao();
    }

    private lagoaBaciaContencaoBuild(): void {
        this.formLagoaBaciaContencao = this.formBuilder.group({
            id: [null],
            idTipoBaciaContencao: [null, Validators.required],
            volume: [null, Validators.required],
            idTipoImpermeabilizacao: [null, Validators.required],
        });
    }

    buscarTipoContencao(): void {
        this.dominiosService
            .getDominio("TIPO_BACIA_CONTENCAO")
            .subscribe((response) => {
                this.tipoContencao = response;
            });
    }

    buscarTipoImpermeabilizacao(): void {
        this.dominiosService
            .getDominio("TIPO_IMPERMEABILIZACAO")
            .subscribe((response) => {
                this.tipoImpermeabilizacao = response;
            });
    }

    salvar(): void {
        const lagoaBaciaContencao = new LagoaBaciaContencao();
        if (this.formLagoaBaciaContencao.valid) {
            lagoaBaciaContencao.idGeometria = this.idGeometria;
            lagoaBaciaContencao.idTipoBaciaContencao =
                this.formLagoaBaciaContencao.value.idTipoBaciaContencao;
            lagoaBaciaContencao.volume =
                this.formLagoaBaciaContencao.value.volume;
            lagoaBaciaContencao.idTipoImpermeabilizacao =
                this.formLagoaBaciaContencao.value.idTipoImpermeabilizacao;

            this.lagoaBaciaContencaoService
                .createLagoaBaciaContencao(lagoaBaciaContencao)
                .subscribe(
                    () => {
                        this.snackBar.showSuccess(
                            "Registro de lagoa-bacia-contenção salvo com sucesso."
                        );
                    },
                    (err) => {
                        this.snackBar.showError(
                            "Erro ao salvar este registro."
                        );
                    }
                );
                this.formLagoaBaciaContencao.reset();
        } else {
            this.formLagoaBaciaContencao.markAllAsTouched();
            this.snackBar.showAlert(
                "Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)"
            );
        }
    }
}
