import {Injectable, Injector} from '@angular/core';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {RelacaoMaquinasEquipamentos} from '../models/relacao-maquinas-equipamentos.model';
import {BaseMineracao} from "./base-mineracao.service";


@Injectable()
export class RelacaoMaquinasEquipamentosResolver extends DetailResolver<RelacaoMaquinasEquipamentos> {
    constructor(
        service: RelacaoMaquinasEquipamentosService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class RelacaoMaquinasEquipamentosService extends BaseMineracao<RelacaoMaquinasEquipamentos> {

    constructor(protected injector: Injector) {
        super(
            "/api/relacao-maquina-equipamento",
            injector,
            RelacaoMaquinasEquipamentos.prototype,
            RelacaoMaquinasEquipamentos.fromJson
        );
    }

    getByMineracao(idMineracao: number): Observable<Array<RelacaoMaquinasEquipamentos>> {
        const url = `${this.urlResource}/mineracao/${idMineracao}`;

        return this.http
            .get(url)
            .pipe(
                map(e => this.jsonToResources(e)),
                catchError(this.handleError.bind(this))
            );
    }
}
