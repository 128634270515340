import {Type} from '@angular/core';
import {RequerimentoOutorgaFinalidade} from '../../../../shared/models/requerimento-outorga-finalidade.model';
import {BaseFinalidadeComponent} from './base-finalidade.component';
import {PredicateResolver} from './predicate.resolver';
import {FinalidadeAbastecimentoPublicoComponent} from './finalidade-abastecimento-publico/finalidade-abastecimento-publico.component';
import {FinalidadeEsgotamentoSanitarioComponent} from './finalidade-esgotamento-sanitario/finalidade-esgotamento-sanitario.component';
import {FinalidadeDessedentacaoComponent} from './finalidade-dessedentacao/finalidade-dessedentacao.component';
import {FinalidadeIndustriaComponent} from './finalidade-industria/finalidade-industria.component';
import {FinalidadeMineracaoComponent} from './finalidade-mineracao/finalidade-mineracao.component';
import {FinalidadeTanqueEscavadoComponent} from './finalidade-tanque-escavado/finalidade-tanque-escavado.component';
import {FinalidadeTanqueRedeComponent} from './finalidade-tanque-rede/finalidade-tanque-rede.component';
import {FinalidadesOutrasComponent} from './finalidades-outras/finalidades-outras.component';
import {FinalidadeGeracaoEnergiaComponent} from "./finalidade-geracao-energia/finalidade-geracao-energia.component";
import {ListaIrrigacaoComponent} from "./finalidade-irrigacao/lista-irrigacao/lista-irrigacao.component";

function byFormularioFinalidade(formularioFinalidade: string, type: Type<BaseFinalidadeComponent>):
    PredicateResolver<RequerimentoOutorgaFinalidade, Type<BaseFinalidadeComponent>> {
    return {predicate: f => f.finalidade.formulario === formularioFinalidade, resolved: type};
}

export class RequerimentoOutorgaFinalidadeFormulario {
    private static predicates: PredicateResolver<RequerimentoOutorgaFinalidade, Type<BaseFinalidadeComponent>>[] = [
        byFormularioFinalidade('ABASTECIMENTO_PUBLICO', FinalidadeAbastecimentoPublicoComponent),
        byFormularioFinalidade('ESGOTAMENTO_SANITARIO', FinalidadeEsgotamentoSanitarioComponent),
        byFormularioFinalidade('DESSEDENTACAO_CRIACAO_ANIMAL', FinalidadeDessedentacaoComponent),
        byFormularioFinalidade('INDUSTRIA', FinalidadeIndustriaComponent),
        byFormularioFinalidade('IRRIGACAO', ListaIrrigacaoComponent),
        byFormularioFinalidade('MINERACAO', FinalidadeMineracaoComponent),
        byFormularioFinalidade('AQUICULTURA_TANQUE_ESCAVADO', FinalidadeTanqueEscavadoComponent),
        byFormularioFinalidade('AQUICULTURA_TANQUE_REDE', FinalidadeTanqueRedeComponent),
        byFormularioFinalidade('OUTROS', FinalidadesOutrasComponent),
        byFormularioFinalidade('GERACAO_ENERGIA', FinalidadeGeracaoEnergiaComponent),
        byFormularioFinalidade('SEM_FORMULARIO', null)
    ];

    static get(requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade): PredicateResolver<RequerimentoOutorgaFinalidade, Type<BaseFinalidadeComponent>> {
        return this.predicates.find(r => r.predicate(requerimentoOutorgaFinalidade));
    }

}
