import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DominioService} from '../../../../../../../../shared/services/dominio.service';
import {Dominio} from '../../../../../../../../shared/models/dominio-model';
import {EmpreendimentoEnergiaFotovoltaicaService} from '../../../../../../../../shared/services/empreendimento-energia-fotovoltaica.service';
import {EnergiaFotovoltaicaModulo} from '../../../../../../../../shared/models/energia-fotovoltaica-modulo.model';
import {SnackBarService} from '../../../../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-painel-modulos-usina-fotovoltaica',
    templateUrl: './painel-modulos-usina-fotovoltaica.component.html',
    styleUrls: ['./painel-modulos-usina-fotovoltaica.component.scss']
})
export class PainelModulosUsinaFotovoltaicaComponent implements OnInit, OnChanges {

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Input() id: number;
    @Input() readonly = false;
    @Input() mostrarObrigatorioUsina;
    @Input() isNaoPodeEditar: boolean;

    form: FormGroup;
    dataSource: EnergiaFotovoltaicaModulo[] = [];
    displayedColumns: string[] = [ 'fabricante', 'modelo', 'tipoCelula', 'descricao' ];
    tipoCelulas: Dominio[] = [];
    mapTipoCelulas = new Map();
    mostrarDescricaoCelula: boolean = false;

    constructor(private fb: FormBuilder,
                private dominioService: DominioService,
                private snackBarService: SnackBarService,
                private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService) {
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            id: [''],
            fabricante: ['', [Validators.required]],
            modelo: ['', [Validators.required]],
            tipoCelula: ['', Validators.required],
            descricao: ['', [Validators.required]]
        });

        this.form.controls.descricao.disable();

        this.dominioService.buscarTipoCelulas()
            .subscribe( (dominios: Dominio[]) => {
                this.tipoCelulas = dominios;
                this.tipoCelulas.forEach( t => this.mapTipoCelulas.set( t.id, t.descricao ) );
            }, err => {
                this.snackBarService.showError( 'Falha ao listar tipos de celulas' );
                console.error( err );
            } );

        if(this.mostrarObrigatorioUsina){
            this.form.markAsDirty();
            this.form.markAllAsTouched();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if ( changes.id ) {

            this.carregar( changes.id.currentValue );
        }

        if ( changes.readonly && !changes.readonly.currentValue ) {
            this.displayedColumns.push( 'acao' );
        }

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    changeTipoCelula( idCelula ){
        if(idCelula == 99){
            this.mostrarDescricaoCelula = true;
            this.form.controls.descricao.enable();
            this.form.controls.descricao.reset();
        }else {
            this.mostrarDescricaoCelula = false;
            this.form.controls.descricao.disable();
            this.form.controls.descricao.reset();
        }
    }

    private carregar( id: number ): void {
        this.empreendimentoEnergiaFotovoltaicaService.listarModulo( id )
        .subscribe( lista => {
            this.dataSource = lista;
        }, error => {
            this.snackBarService.showError( 'Falha ao listar modulos' );
            console.error( error );
            });
    }

    editar( objeto: EnergiaFotovoltaicaModulo ): void {
        this.form.patchValue({
            id: objeto.id,
            fabricante: objeto.nomeFabricante,
            modelo: objeto.modelo,
            descricao: objeto.descricao,
            tipoCelula: objeto.idTipoCelulaFotovoltaica
        });
        this.changeTipoCelula(objeto.idTipoCelulaFotovoltaica)
    }

    excluir( objeto: EnergiaFotovoltaicaModulo ): void {

        this.empreendimentoEnergiaFotovoltaicaService.deletarModulo( objeto.id )
            .subscribe( _ => {
                this.snackBarService.showSuccess( 'Modulo excluído com sucesso!' );
                this.carregar(+this.id);
            }, error => {
                this.snackBarService.showError( 'Falha ao excluir modulo' );
                console.error( error );
            });
    }

    saveForm(): void {

        if ( !this.form.invalid ) {
            const form = this.form.getRawValue();
            const entidade: EnergiaFotovoltaicaModulo = {
                id: form.id,
                descricao: form.descricao,
                empreendimentoEnergFotovoltaica: { id: +this.id },
                idTipoCelulaFotovoltaica: form.tipoCelula,
                modelo: form.modelo,
                nomeFabricante: form.fabricante
            };
            
            this.empreendimentoEnergiaFotovoltaicaService.salvarModulo( entidade )
                .subscribe( retorno => {
                    this.save.emit( retorno );
                    this.snackBarService.showSuccess( 'Modulo salvo com sucesso!' );
                    this.carregar( +this.id );
                    this.form.reset();
                }, error => {

                    console.error( error );
                    this.snackBarService.showError( 'Falha ao salvar modulo!' );
                });
        } else {
            this.form.markAllAsTouched();
            this.snackBarService.showError( 'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)' );
        }
    }

}
