import {BaseModel} from "./base.model";
import {Requerimento} from '../../pages/requerimentos/requerimento.model';

export class AutorizacaoManejoFlorestal extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public intervencaoArea?: boolean,
        public idPessoaDetentor?: number,
    ) {
        super();
    }

    static fromJson(json: any): AutorizacaoManejoFlorestal {
        if (json === undefined || json === null) {
            return null;
        }
        return new AutorizacaoManejoFlorestal(
            json.id,
            Requerimento.fromJson(json.requerimento),
            json.intervencaoArea,
            json.idPessoaDetentor,
        );
    }

    static fromJsons(json: any): AutorizacaoManejoFlorestal[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(AutorizacaoManejoFlorestal.fromJson(j)));
            return resources;
        }
    }
}