import { BaseModel } from "./base.model";

export class ProcessamentoCoordenadaDto extends BaseModel {
    constructor(
        public aguardandoProcessamentoMT?: number,
        public aguardandoProcessamentoMicrobacia?: number,
        public dataCadastro?: Date,
        public dataFim?: Date,
        public idCoordenada?: number,
        public tipoProcessamento?: string,
        public numeroProcesso?: string,
        public cpfExecutor?: string,
        public nomeExecutor?: string,
        public justificativa?: string
    ) {
        super();
    }

    static fromJson(json: any): ProcessamentoCoordenadaDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new ProcessamentoCoordenadaDto(
            json.aguardandoProcessamentoMT ? json.aguardandoProcessamentoMT : null,
            json.aguardandoProcessamentoMicrobacia ? json.aguardandoProcessamentoMicrobacia : null,
            json.dataCadastro ? json.dataCadastro : null,
            json.dataFim ? json.dataFim : null,
            json.idCoordenada ? json.idCoordenada : null,
            json.tipoProcessamento ? json.tipoProcessamento : null,
            json.numeroProcesso ? json.numeroProcesso : null,
            json.cpfExecutor ? json.cpfExecutor : null,
            json.nomeExecutor ? json.nomeExecutor : null,
            json.justificativa ? json.justificativa : null,
        );
    }

    static fromJsons(json: any): ProcessamentoCoordenadaDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(ProcessamentoCoordenadaDto.fromJson(j)));
            return resources;
        }
    }
}