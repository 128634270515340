import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
    selector: 'app-lic-amb-modal-historico-requerimento',
    templateUrl: './modal-historico-requerimento.component.html',
})
export class LicAmbModalHistoricoRequerimentoComponent implements OnInit, OnDestroy {

    @ViewChild('container', {read: ViewContainerRef, static: true})
    container;
    componentRef: ComponentRef<any>;
    buttons: HTMLButtonElement[] = [];
    titleUpdateEmitter = new EventEmitter();
    voltarEmitter = new EventEmitter();
    addButtonsEmitter = new EventEmitter();
    requerimento: Requerimento = new Requerimento();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<LicAmbModalHistoricoRequerimentoComponent>,
        private resolver: ComponentFactoryResolver,
        private activatedRoute: ActivatedRoute,
    ) {
        this.requerimento =
        this.activatedRoute.snapshot.data.routeData ? this.activatedRoute.snapshot.data.routeData.requerimento : null;
    }

    ngOnInit(): void {
        this.createComponent(null);

        this.titleUpdateEmitter.subscribe(title => {
            this.dialogRef.componentInstance.data['title'] = title;
        });
    }

    createComponent(component): void {
        this.buttons = [];
        this.container.clear();
        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component || this.data.component);
        this.componentRef = this.container.createComponent(factory);
        const hostedComponent = this.componentRef.instance;

        // pass Inputs to component
        if (this.data.inputs) {
            Object.keys(this.data.inputs).forEach(inputName => {
                hostedComponent[inputName] = this.data.inputs[inputName];
            });
        }

        // PassThrough outputs from hosted component
        Object.keys(hostedComponent)
            .filter(prop => hostedComponent[prop] instanceof EventEmitter)
            .forEach(output => {
                this[output] = new EventEmitter();
                this[`${output}_sub`] = hostedComponent[output].subscribe(data => {
                    this[output].emit(data);
                });
            });

        // Buttons event
        const buttonsEmitter = hostedComponent['addButtonsEmitter'];
        if (buttonsEmitter) {
            hostedComponent['addButtonsEmitter'].subscribe(data => {
                this.buttons.push(...data);
            });
        }

        // Title update
        const titlePageComponent = hostedComponent['tituloModalTarefas'];
        if (titlePageComponent) {
            this.data['title'] = titlePageComponent;
        }
        // Pass Title Update Emitter.
        hostedComponent['titleUpdateEmitter'] = this.titleUpdateEmitter;

        // UpdateComponent event
        const updateEmitter = hostedComponent['updateModalComponentEmitter']
        if (updateEmitter) {
            updateEmitter.subscribe(data => {
                this.createComponent(data);
            });
        }

        // voltar event
        const voltarEmitter = hostedComponent['voltarEmitter'];
        if (voltarEmitter) {
            voltarEmitter.subscribe(componentVoltar => {
                this.createComponent(componentVoltar);
            });
        }

    }

    closeModal(): void {
        this.dialogRef.close();
    }

    onClick(event, b: HTMLButtonElement): void {
        b.click();
    }

    ngOnDestroy(): void {
        this.componentRef.destroy();
    }
}
