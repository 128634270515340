import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {AutoClaveDto} from "../../../../../../../../shared/models/empreendimentos-models/auto-clave-dto.model";
import {ResiduoTratamentoTermicoService} from "../../../../../../../../shared/services/residuo-tratamento-termico.service";

@Component({
    selector: 'app-autoclave',
    templateUrl: './autoclave.component.html',
    styleUrls: ['./autoclave.component.scss']
})
export class AutoclaveComponent implements OnInit {

    @Output() statusFormAutoClaveEmitter= new EventEmitter();
    @Output() autoClaveEmitter = new EventEmitter();
    @Input() idTratamentoTermico: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;

    formAutoclave: FormGroup;
    idAutoClaveTemp: number = 0;
    modoEdicao: boolean = false;
    autoclaveAdicionado: any[] = [];
    unidadeMedida: any[] = [];
    dataSourceAutoclave = new MatTableDataSource<any>([]);
    
    // Variável para binding do limite de dígitos no input
    _maxLength: string = "20";

    constructor(private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private residuoTratamentoTermicoService: ResiduoTratamentoTermicoService) {
    }

    async ngOnInit() {
        await this.buildFormAutoclave()
        this.unidadeMedida = await this.residuoTratamentoTermicoService.buscarDominiosFiltrados().toPromise();
        if (this.idTratamentoTermico) {
            this.carregarAutoClaveComTratamentoExistente(this.idTratamentoTermico);
        }
        if (this.isNaoPodeEditar) {
            this.formAutoclave.disable();
        }
    }

    private buildFormAutoclave(): void {
        this.formAutoclave = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            modelo: ["", [Validators.required]],
            pressaoOperacao: ["", [Validators.required]],
            unidadeMedida: ["", [Validators.required]],
            numeroCiclosPorDias: ["", [Validators.required]],
            formaDeAlimentacao: ["", [Validators.required]],
            capacidadeDaAutoclave: ["", [Validators.required]],
            temperaturaDeEsterelizacao: ["", [Validators.required]],
            duracaoTotalCiclos: ["", [Validators.required]],
            capacidadeDeProcessamento: ["", [Validators.required]],
            possuiCamara: ["", [Validators.required]],
        });
    }

    adicionaAutoclave() {
        if (this.formAutoclave.valid) {
            this.autoclaveAdicionado.push({
                id: null,
                idTemp: this.idAutoClaveTemp,
                modelo: this.formAutoclave.controls.modelo.value,
                pressaoOperacao: this.formAutoclave.controls.pressaoOperacao.value,
                unidadeMedida: this.formAutoclave.controls.unidadeMedida.value,
                numeroCiclosPorDias: this.formAutoclave.controls.numeroCiclosPorDias.value,
                formaDeAlimentacao: this.formAutoclave.controls.formaDeAlimentacao.value,
                capacidadeDaAutoclave: this.formAutoclave.controls.capacidadeDaAutoclave.value,
                temperaturaDeEsterelizacao: this.formAutoclave.controls.temperaturaDeEsterelizacao.value,
                duracaoTotalCiclos: this.formAutoclave.controls.duracaoTotalCiclos.value,
                capacidadeDeProcessamento: this.formAutoclave.controls.capacidadeDeProcessamento.value,
                possuiCamara: this.formAutoclave.controls.possuiCamara.value === 'sim' ? 1 : 0,
            });
            this.idAutoClaveTemp++
            this.enviarAutoClaveDto(this.mapearArray())
            this.enviarStatusFormAutoClave(true);
            this.dataSourceAutoclave = new MatTableDataSource(this.autoclaveAdicionado);
            this.formAutoclave.reset();
        } else {
            this.enviarStatusFormAutoClave(false);
            this.snackBar.showAlert(
                "O formulário AutoClave possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).");
            this.formAutoclave.markAllAsTouched();
        }
    }

    deletarAutoclaveItem(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.residuoTratamentoTermicoService.deletarAutoClavePorId(itemId).subscribe( response => {
                this.snackBar.showSuccess("Autoclave excluido com sucesso.")
            })
        }
        let data;
        data = this.autoclaveAdicionado.filter((value, key) => {
            return value.idTemp != item;
        });
        this.autoclaveAdicionado = data;
        this.dataSourceAutoclave = new MatTableDataSource(data);
        this.enviarAutoClaveDto(this.mapearArray());
        this.enviarStatusFormAutoClave(false);
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.formAutoclave.reset();
        this.formAutoclave.markAsUntouched();
        this.formAutoclave.updateValueAndValidity()
        this.enviarStatusFormAutoClave(true);
    }

    mapearArray(): any {
        if (this.autoclaveAdicionado.length == 0) {
            return this.autoclaveAdicionado;
        } else {
            return this.autoclaveAdicionado.map(function (item) {
                return new AutoClaveDto(
                    item.id,
                    item.modelo,
                    item.pressaoOperacao,
                    item.unidadeMedida,
                    item.numeroCiclosPorDias,
                    item.formaDeAlimentacao,
                    item.capacidadeDaAutoclave,
                    item.temperaturaDeEsterelizacao,
                    item.duracaoTotalCiclos,
                    item.capacidadeDeProcessamento,
                    item.possuiCamara,
                );
            });
        }
    }

    enviarAutoClaveDto(autoClaveDto: AutoClaveDto[]) {
        this.autoClaveEmitter.emit(autoClaveDto)
    }

    enviarStatusFormAutoClave (status: boolean){
        this.statusFormAutoClaveEmitter.emit(status);
    }

    editarAutoclave() {
        if (this.formAutoclave.valid) {
            this.deletarAutoclaveItem(this.formAutoclave.controls.idTemp.value);
            this.autoclaveAdicionado.push({
                id: null,
                idTemp: this.idAutoClaveTemp,
                modelo: this.formAutoclave.controls.modelo.value,
                pressaoOperacao: this.formAutoclave.controls.pressaoOperacao.value,
                unidadeMedida: this.formAutoclave.controls.unidadeMedida.value,
                numeroCiclosPorDias: this.formAutoclave.controls.numeroCiclosPorDias.value,
                formaDeAlimentacao: this.formAutoclave.controls.formaDeAlimentacao.value,
                capacidadeDaAutoclave: this.formAutoclave.controls.capacidadeDaAutoclave.value,
                temperaturaDeEsterelizacao: this.formAutoclave.controls.temperaturaDeEsterelizacao.value,
                duracaoTotalCiclos: this.formAutoclave.controls.duracaoTotalCiclos.value,
                capacidadeDeProcessamento: this.formAutoclave.controls.capacidadeDeProcessamento.value,
                possuiCamara: this.formAutoclave.controls.possuiCamara.value === 'sim' ? 1 : 0,
            });
            this.enviarStatusFormAutoClave(true);
            this.dataSourceAutoclave = new MatTableDataSource(this.autoclaveAdicionado);
            this.enviarAutoClaveDto(this.mapearArray())
            this.formAutoclave.reset();
            this.modoEdicao = false;
        }
    }

    ativarModoEdicaoAutoClave(autoclave) {
        this.formAutoclave.controls.id.patchValue(autoclave.id);
        this.formAutoclave.controls.idTemp.patchValue(autoclave.idTemp);
        this.formAutoclave.controls.modelo.patchValue(autoclave.modelo);
        this.formAutoclave.controls.capacidadeDaAutoclave.patchValue(autoclave.capacidadeDaAutoclave);
        this.formAutoclave.controls.pressaoOperacao.patchValue(autoclave.pressaoOperacao);
        this.formAutoclave.controls.unidadeMedida.patchValue(autoclave.unidadeMedida);
        this.formAutoclave.controls.numeroCiclosPorDias.patchValue(autoclave.numeroCiclosPorDias);
        this.formAutoclave.controls.temperaturaDeEsterelizacao.patchValue(autoclave.temperaturaDeEsterelizacao);
        this.formAutoclave.controls.duracaoTotalCiclos.patchValue(autoclave.duracaoTotalCiclos);
        this.formAutoclave.controls.capacidadeDeProcessamento.patchValue(autoclave.capacidadeDeProcessamento);
        this.formAutoclave.controls.formaDeAlimentacao.patchValue(autoclave.formaDeAlimentacao);
        this.formAutoclave.controls.possuiCamara.patchValue(autoclave.possuiCamara === 1 ? 'sim' : 'nao');
        this.modoEdicao = true;
        this.enviarStatusFormAutoClave(false);
    }

    carregarAutoClaveComTratamentoExistente(idTratamentoTermico: any) {
        this.residuoTratamentoTermicoService.buscaListaAutoClavePorIdTratamento(idTratamentoTermico).subscribe(response => {
            response.forEach(autoclave => {
                this.autoclaveAdicionado.push({
                    id: autoclave.id,
                    idTemp: this.idAutoClaveTemp,
                    modelo: autoclave.modelo,
                    pressaoOperacao: autoclave.pressaoOperacao,
                    unidadeMedida: autoclave.unidadeMedida,
                    numeroCiclosPorDias: autoclave.numeroCiclosPorDias,
                    formaDeAlimentacao: autoclave.formaDeAlimentacao,
                    capacidadeDaAutoclave: autoclave.capacidadeDaAutoclave,
                    temperaturaDeEsterelizacao: autoclave.temperaturaDeEsterelizacao,
                    duracaoTotalCiclos: autoclave.duracaoTotalCiclos,
                    capacidadeDeProcessamento: autoclave.capacidadeDeProcessamento,
                    possuiCamara: autoclave.possuiCamara === 'sim' ? 1 : 0,
                });
                this.idAutoClaveTemp++
            });
            this.dataSourceAutoclave = new MatTableDataSource(this.autoclaveAdicionado);
        })
    }

}
