import {BaseModel} from "../base.model";

export class TermoReferenciaConfiguracaoLicenciamento extends BaseModel {
    constructor(
        public id?: number,
        public termoReferencia?: number,
        public configuracaoLicenciamento?: number,
        public cadastro?: Date
    ) {
        super();
    }

    static fromJson(json: any): TermoReferenciaConfiguracaoLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new TermoReferenciaConfiguracaoLicenciamento(
            json.id,
            json.termoReferencia,
            json.configuracaoLicenciamento,
            json.cadastro
        );
    }
}
