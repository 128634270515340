import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingStateService } from "../../core/services/routing-state.service";
import { CircuitoLinhaTransmissao } from '../models/circuito-linha-transmissao.model';
import { DetailResolver } from "../resolvers/detail-resolver";
import { BaseEeLinhaTransmissao } from './base-ee-linha-transmissao.service';
import { DominiosService } from "./dominios.service";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class CaracteristicaEletricaSistemaResolver extends DetailResolver<CircuitoLinhaTransmissao> {
    constructor(
        service: CircuitoLinhaTransmissaoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class CircuitoLinhaTransmissaoService extends BaseEeLinhaTransmissao<CircuitoLinhaTransmissao> {

    constructor(protected injector: Injector, protected dominiosService: DominiosService) {
        super(
            "/api/circuito-linha-transmissao",
            injector,
            CircuitoLinhaTransmissao.prototype,
            CircuitoLinhaTransmissao.fromJson,
        );
    }

    protected get pathGetByEntidadePai(): string {
        return "ee-linha-transmissao";
    }

    protected get pathGetDominio(): string {
        return "tipo-circuito-ee-lin-trans";
    }

    getByLinhaTransmissao(idLinhaTransmissao: number): Observable<CircuitoLinhaTransmissao[]> {
        const url = `${this.urlResource}/ee-linha-transmissao/${idLinhaTransmissao}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }


}
