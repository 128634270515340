export enum ModeloFeicao {
    AMF = 1,
    UPA = 2,
    TALHAO = 3,
    AEP = 4,
    AQC = 5,
    ASVT = 6,
    ASVS = 7,
    DESMATE = 8,
    EVENTUAL = 9,
    SELETIVA = 10
}
