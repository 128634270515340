import {Feicao} from "./feicao.model";
import {RegraGeoFeicao} from "./regra-geo-feicao.model";
import {BaseModel} from "../base.model";
import {GeometriaPonto} from "./geometria-ponto.model";
import {FeicaoGeometriaDto} from "../feicao-geometria-dto.model";

export class RegraGeo extends BaseModel {

    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public observacao?: string,
        public identificacao?: string,
        public feicaoEntrada?: Feicao,
        public ativa?: boolean,
        public regrasFeicao?: RegraGeoFeicao[]
    ) {
        super();
    }

    static fromJson(json: any): RegraGeo {
        if (json === undefined || json === null) {
            return null;
        }
        return new RegraGeo(
            json.id,
            json.nome,
            json.descricao,
            json.observacao,
            json.identificacao,
            Feicao.fromJson(json.feicaoEntrada),
            json.ativa,
            json.regrasFeicao
        );
    }

    static fromJsons(jsonArray: any): RegraGeo[] {
        const resources = [];
        if (!jsonArray) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(RegraGeo.fromJson(resource)));
            return resources;
        }
    }
}
