import {BaseModel} from 'app/main/shared/models/base.model';
import {ParecerTecnicoCondicionante} from '../parecer-tecnico/parecer-tecnico-condicionates/parecer-tecnico-condicionantes.model';
import {SubstanciaQuimica} from '../substancia-quimica/substancia-quimica.model';
import {UnidadeAgua} from '../../shared/models/unidade-agua.model';

export class FormularioQualidadeAgua extends BaseModel {
    constructor(
        public id?: number,
        public substanciaQuimica?: SubstanciaQuimica,
        public unidadeAgua?: UnidadeAgua,
        public tipoFormulario?: any,
        public parecerTecnicoCondicionante?: ParecerTecnicoCondicionante,
    ) {
        super();
    }

    static fromJson(json: any): FormularioQualidadeAgua {
        if (json === undefined || json === null) {
            return null;
        }
        return new FormularioQualidadeAgua(
            json.id,
            SubstanciaQuimica.fromJson(json.substanciaQuimica),
            UnidadeAgua.fromJson(json.unidadeAgua),
            json.tipoFormulario,
            ParecerTecnicoCondicionante.fromJson(json.parecerTecnicoCondicionante));
    }
}
