import {NgModule} from '@angular/core';
import {CoreModule} from '../../core/core.module';
import {FinalidadeListComponent} from './finalidade-list/finalidade-list.component';
import {FinalidadeDetailComponent} from './finalidade-detail/finalidade-detail.component';
import {RouterModule, Routes} from '@angular/router';
import {FinalidadeListResolver, FinalidadeResolver} from './finalidade.service';
import {SharedModule} from 'app/main/shared/shared.module';

export const routes: Routes = [
    {
        path: 'new',
        component: FinalidadeDetailComponent
    },
    {
        path: 'finalidades',
        component: FinalidadeListComponent,
        resolve: {
            data: FinalidadeListResolver
        }
    },
    {
        path: ':id/edit',
        component: FinalidadeDetailComponent,
        resolve: {
            data: FinalidadeResolver
        }
    },
    {
        path: ':id/detail',
        component: FinalidadeDetailComponent,
        resolve: {
            data: FinalidadeResolver
        }
    }
];

@NgModule({
    declarations: [
        FinalidadeDetailComponent,
        FinalidadeListComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes),
    ],
    entryComponents: [
        FinalidadeDetailComponent,
        FinalidadeListComponent
    ],
    providers: [
        FinalidadeResolver,
        FinalidadeListResolver
    ]
})
export class FinalidadesModule {
}
