import { Component, OnInit } from '@angular/core';
import { AutorizacaoQueimaControlada } from "../../../../../../../shared/models/autorizacao-queima-controlada.model";
import { BaseModel } from "../../../../../../../shared/models/base.model";
import {
    FeicaoRequerimentoLicenciamentoDto
} from '../../../../../../../shared/models/feicao-requerimento-licenciamento-dto.model';
import { ModeloFeicaoDTO } from '../../../../../../../shared/models/modelo-feicao-dto.model';
import { QueimaControladaDto } from '../../../../../../../shared/models/queima-controlada-dto.model';
import { QueimaControladaFeicoes } from '../../../../../../../shared/models/queima-controlada-feicoes.model';
import { DominiosService } from "../../../../../../../shared/services/dominios.service";
import { SnackBarService } from "../../../../../../../shared/snack-bar/snack-bar.service";
import { EmissaoTituloService } from "../../../../../../tarefas/shared/service/emissao-titulo.service";
import { ReqTituloVinculadoService } from "../../../../../../tarefas/shared/service/req-titulo-vinculado.service";
import { RequerimentoLicenciamento } from "../../../../requerimento-licenciamento.model";
import { RequerimentoLicenciamentoService } from "../../../../requerimento-licenciamento.service";
import { BaseAtividadeEmpreendimentoComponent } from "../base-atividade-empreendimento.component";
import { QueimaControladaService } from "./queima-controlada.service";

import { FormUtil } from '../../../../../../../shared/util/form-util';

@Component({
    selector: 'app-queima-controlada',
    templateUrl: './queima-controlada.component.html',
    styleUrls: ['./queima-controlada.component.scss']
})
export class QueimaControladaComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    motivoSelect: any
    areaQueima: any
    errosDeRegras = []
    requerimentoLicenciamento: RequerimentoLicenciamento;
    queimaControladaDto: QueimaControladaDto = new QueimaControladaDto();

    quadroDeAreas: FeicaoRequerimentoLicenciamentoDto[] = [];

    isNaoPodeEditar: boolean = false;

    constructor(private dominioService: DominiosService,
                private snackBarService: SnackBarService,
                private reqTituloVinculadoService: ReqTituloVinculadoService,
                private emissaoTituloService: EmissaoTituloService,
                private queimaControladaService: QueimaControladaService,
                private requerimentoLicenciamentoService: RequerimentoLicenciamentoService) {
        super();
    }

    async ngOnInit() {
        if (this.requerimento.id) {
            this.requerimentoLicenciamento =
                await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();

            this.queimaControladaService.buscarPorIdRequerimento(this.requerimento.id).toPromise()
                .then( response => {
                    if(response){
                        this.queimaControladaDto = response;

                        this.requerimentoLicenciamentoService.getFeicoesPorIds(this.queimaControladaDto.quadroDeFeicoes.map(
                            feicao => feicao.idFeicaoRequerimentoLicenciamento), this.requerimentoLicenciamento.id)
                            .toPromise().then( feicoes => {
                            this.quadroDeAreas = feicoes
                        })
                    }
            })
        }

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
        }
    }

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    async buscarQuadroDeAreas() {
        this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).subscribe(feicoes => {
            this.quadroDeAreas = feicoes;
        })
    }

    private async verificarRegrasDeMotivo() {
        let titulosInformado =
            await this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, null).toPromise();

        let feicoes: FeicaoRequerimentoLicenciamentoDto[] =
            await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();

        let feicaoAqc: FeicaoRequerimentoLicenciamentoDto =
            feicoes.find(f => f.modeloFeicaoDTO && f.modeloFeicaoDTO.idModeloFeicao == 5)

        if (feicaoAqc.modeloFeicaoDTO.modeloFeicaoAQC.motivo == "RESTO_EXPLORACAO_FLORESTAL") {
            if (titulosInformado.length == 0) {
                this.snackBarService.showAlert('Informe o número da autorização de exploração na primeira aba do requerimento. [MSG001]');
            }
        } else {
            if (titulosInformado.length == 0) {
                this.snackBarService.showAlert('Informe o número da autorização ou licença da atividade na primeira aba do requerimento [MSG002].');
            }
        }
    }

    salvar(){
        this.queimaControladaService.salvarFormEFeicoes(this.montarQueimaDto()).toPromise().then( resposta => {
            this.queimaControladaDto = resposta;
            this.snackBarService.showSuccess("Formulário salvo com sucesso.")
            this.enviarFormConcluido.emit();
            this.verificarRegrasDeMotivo();
        }, () => this.snackBarService.showError("Ocorreu um erro no servidor."));
    }

    montarQueimaDto(){
        let queimaControlada = this.montarQueimaControlada();
        let listaFeicoes: QueimaControladaFeicoes[] = [];
        this.quadroDeAreas.forEach(feicao => {
            listaFeicoes.push(this.montarQuadroDeFeicoes(feicao.id, queimaControlada));
        })
        return new QueimaControladaDto(queimaControlada, listaFeicoes)
    }

    montarQueimaControlada(){
        return new AutorizacaoQueimaControlada(this.queimaControladaDto.queimaControlada ? this.queimaControladaDto.queimaControlada.id : null, this.requerimento.id)
    }

    montarQuadroDeFeicoes(idFeicaoRequerimentoLicenciamento: number, queimaControlada: AutorizacaoQueimaControlada){
        return new QueimaControladaFeicoes(null, queimaControlada, idFeicaoRequerimentoLicenciamento)
    }

    buscarMotivoQueima(motivo){
        return ModeloFeicaoDTO.motivosAqc.find(x => x.valor == motivo).descricao;
    }

    formatarCoordenadaPonto(wktString: string): any {
        const regex = /POINT \(([^)]+)\)/;
        const matches = wktString.match(regex);

        if (matches) {
            const coordinates = matches[1].split(' ');
            const longitude = parseFloat(coordinates[0]);
            const latitude = parseFloat(coordinates[1]);
            return FormUtil.convertDEGToDMS(longitude.toString(), false).toString() + "  " + FormUtil.convertDEGToDMS(latitude.toString(), true).toString()
        } else {
            return null;
        }
    }
}
