import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TipoSistemaCultivo} from '../../../../../shared/models/tipo-sistema-cultivo.model';
import {CultivoTanqueEscavadoService} from './services/cultivo-tanque-escavado.service';
import {CultivoTanqueEscavado} from './models/cultivo-tanque-escavado.model';
import {MatDialog} from '@angular/material';
import {RequerimentoOutorgaFinalidadeService} from '../../../../../shared/services/requerimento-outorga-finalidade.service';
import {ConfirmarExclusaoDialogComponent} from '../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {BaseModel} from '../../../../../shared/models/base.model';
import {TipoSistemaCultivoService} from './services/tipo-sistema-cultivo.service';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {RedirectUtil} from "../../../../../shared/util/RedirectUtil";

@Component({
    selector: 'app-finalidade-tanque-escavado',
    templateUrl: 'finalidade-tanque-escavado.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class FinalidadeTanqueEscavadoComponent extends BaseFinalidadeComponent implements OnInit {
    form: FormGroup;
    formCultivoTanqueEscavado: FormGroup;
    cultivoTanqueEscavadoArray: Array<CultivoTanqueEscavado> = [];
    tipoSistemaCultivo: Array<TipoSistemaCultivo> = [];
    displayedColumns: string[] = ['numeroTanques', 'areaCultivo', 'producao', 'conversaoAlimentar', 'ciclos', 'acoes'];
    observacao: any;

    constructor(
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private tipoSistemaCultivoService: TipoSistemaCultivoService,
        private cultivoTanqueEscavadoService: CultivoTanqueEscavadoService,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private snackBarService: SnackBarService
    ) {
        super();
    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.buildResourceForm();
        this.patchForm();
        this.carregarTipoSistemaCultivo();
        this.carregarAquiculturaTanqueEscavadoDoRequerimento();
        if (this.isModoVisualizacao) {
            this.form.disable();
            this.formCultivoTanqueEscavado.disable();
            this.displayedColumns = ['numeroTanques', 'areaCultivo', 'producao', 'conversaoAlimentar', 'ciclos'];
        }
    }

    private patchForm(): void {
        this.form.patchValue({
            id: this.requerimentoOutorgaFinalidade.id,
            finalidade: this.requerimentoOutorgaFinalidade.finalidade,
            requerimentoOutorga: this.requerimentoOutorgaFinalidade.requerimentoOutorga
        });
        if (this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado) {
            this.form.patchValue({
                aquiculturaTanqueEscavado: this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado
            });
            this.formCultivoTanqueEscavado.patchValue({
                aquiculturaTanqueEscavado: this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado
            });
            this.observacao = this.requerimentoOutorgaFinalidade.observacao;
        }
    }

    protected buildResourceForm(): void {

        this.form = this.formBuilder.group({
            id: [null],
            finalidade: [null, [Validators.required]],
            requerimentoOutorga: [null, [Validators.required]],
            aquiculturaTanqueEscavado: this.formBuilder.group({
                id: [null],
                tipoSistemaCultivo: [null, [Validators.required]],
                outroSistemaCultivo: [{value: null, disabled: true}],
                areaBarragem: [null],
                volumeBarragem: [null],
                areaTanques: [null],
                areaTanqueEspecifico: [null],
                volumeTanques: [null],
                volumeTanqueEspecifico: [null],
                outrosEspelho: [null, [Validators.maxLength(255)]],
                especieAserCultivada: [null, [Validators.required, Validators.maxLength(512)]],
                cria: [false],
                recria: [false],
                engorda: [false],
                recriaEngorda: [false],
                profundidadeMedia: [null, [Validators.required]],
                perdasEvaporacao: [null, [Validators.required]],
                quantidadeRacao: [null, [Validators.required]],
                volumeUtil: [null, [Validators.required]],
                perdasInfiltracao: [null, [Validators.required]]
            }),
            observacao: [null, Validators.maxLength(3000)],
            cadastroConcluido: [null]
        });

        this.formCultivoTanqueEscavado = this.formBuilder.group({
            numeroTanques: [null, [Validators.required, Validators.min(1), Validators.max(2147483647)]],
            areaCultivo: [null, [Validators.required]],
            producao: [null, [Validators.required]],
            conversaoAlimentar: [null, [Validators.required]],
            ciclos: [null, [Validators.required, Validators.min(1), Validators.max(2147483647)]],
            aquiculturaTanqueEscavado: [null],
        });

    }

    private carregarTipoSistemaCultivo(): void {
        this.tipoSistemaCultivoService
            .getAll()
            .subscribe(tipoSistemaCultivo => (this.tipoSistemaCultivo = tipoSistemaCultivo));
    }

    addCultivoTanqueEscavado(): void {
        if (!this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado) {
            this.salvar(false);
            setTimeout(() => {
                if (this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado && this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado.id) {
                    this.salvarCultivo();
                }
            }, 3000);
        }else{
            this.salvarCultivo();
        }
    }

    private salvarCultivo(): void {
        this.formCultivoTanqueEscavado.markAllAsTouched();
        this.formCultivoTanqueEscavado.patchValue({aquiculturaTanqueEscavado: this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado});
        if (this.formCultivoTanqueEscavado.valid) {
            this.cultivoTanqueEscavadoService.create(this.formCultivoTanqueEscavado.value).subscribe(() => {
                    this.snackBarService.showSuccess('Dados do Cultivo adicionado com sucesso!');
                    this.carregarAquiculturaTanqueEscavadoDoRequerimento();
                    this.formCultivoTanqueEscavado.reset();
                    this.formCultivoTanqueEscavado.markAsUntouched();
                },
                (e) => this.snackBarService.showError('Erro ao adicionar Cultivo do Tanque Escavado.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    private carregarAquiculturaTanqueEscavadoDoRequerimento(): void {
        if (this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado) {
            this.cultivoTanqueEscavadoService
                .getAllByCultivoTanqueEscavado(this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado)
                .subscribe(cultivoTanqueEscavados => {
                    this.cultivoTanqueEscavadoArray = cultivoTanqueEscavados;
                    if (this.requerimentoOutorgaFinalidade.cadastroConcluido !== this.cultivoTanqueEscavadoArray.length > 0) {
                        this.salvar(false);
                    }
                }, (e) => this.snackBarService.showError('Erro ao carregar lista cultivo tanque escavado.', e));
        }
    }

    salvar(showMessages: boolean = true): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            this.form.patchValue({cadastroConcluido: this.cultivoTanqueEscavadoArray.length > 0});
            this.requerimentoOutorgaFinalidadeService.update(this.form.value).subscribe(
                requerimentoOutorgaFinalidade => {
                    Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                    this.patchForm();
                    this.form.markAsPristine();
                    if (showMessages) {
                        this.snackBarService.showSuccess('Aquicultura Tanque Escavado Salvo com sucesso!');
                    }
                },
                (e) => {
                    this.snackBarService.showError('Erro ao atualizar os dados.', e);
                }
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    deleteCultivoTanqueEscavado(cultivoTanqueEscavado: CultivoTanqueEscavado): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: cultivoTanqueEscavado.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.cultivoTanqueEscavadoService.delete(cultivoTanqueEscavado.id
                ).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Dados do Cultivo excluído com sucesso!');
                        this.carregarAquiculturaTanqueEscavadoDoRequerimento();
                    },
                    (e) => this.snackBarService.showError('Erro ao excluir Dados do Cultivo.', e)
                );
            }
        });
    }

    public onChangeTipoSitemaCultivo(event): void {
        if (event) {
            const field = this.form.controls.aquiculturaTanqueEscavado.get('outroSistemaCultivo');
            const tipo = TipoSistemaCultivo.fromJson(this.form.controls.aquiculturaTanqueEscavado.get('tipoSistemaCultivo').value);
            if (tipo.outro) {
                field.enable();
                field.setValidators([
                    Validators.required
                ]);
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValue('');
                field.setValidators([]);
                field.updateValueAndValidity();
            }
        }
    }

    public getTotalNTanqueEscavado(): number {
        if (this.cultivoTanqueEscavadoArray) {
            return this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.numeroTanques,
                0
            );
        }
        return 0;
    }

    public getTotalAreaCultivo(): number {
        if (this.cultivoTanqueEscavadoArray) {
            return this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.areaCultivo, 0);
        }
        return 0;
    }

    public getTotalProducao(): number {
        if (this.cultivoTanqueEscavadoArray) {
            return this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.producao,
                0
            );
        }
        return 0;
    }

    public getTotalConservacaoAlimentar(): number {
        if (this.cultivoTanqueEscavadoArray) {
            return this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.conversaoAlimentar,
                0
            );
        }
        return 0;
    }

    public getTotalCiclos(): number {
        if (this.cultivoTanqueEscavadoArray) {
            return this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.ciclos,
                0
            );
        }
        return 0;
    }
}
