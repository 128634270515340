import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {MatTableDataSource} from "@angular/material/table";
import {CasaDeForca} from "../../../../../../../../shared/models/casa-de-forca.model";
import {Reservatorio} from "../../../../../../../../shared/models/reservatorio.model";
import {EeHidricaService} from "../ee-hidrica.service";

@Component({
    selector: 'app-reservatorio',
    templateUrl: './reservatorio.component.html',
    styleUrls: ['./reservatorio.component.scss']
})
export class ReservatorioComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly : boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() enviarReservatorios = new EventEmitter<any[]>();
    formReservatorios: FormGroup;
    reservatoriosSalvos: any[] = [];
    idReservatorio: number = 0;
    dataSourceReservatorio = new MatTableDataSource<any>([]);
    modoEdicao: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private eeHidricaService: EeHidricaService,) {
    }

    ngOnInit() {
        this.buildFormReservatorios();
        if(this.idForm){
            this.eeHidricaService.buscarReservatoriosPorId(this.idForm).subscribe( response => {
                response.forEach( reservatorio => {
                    this.reservatoriosSalvos.push({
                        id: reservatorio.id,
                        idTemp: this.idReservatorio,
                        identificacao: reservatorio.identificacao,
                        volumeMax: reservatorio.volumeMax,
                        volumeMin: reservatorio.volumeMin,
                        volumeUtil: reservatorio.volumeUtil,
                        areaMax: reservatorio.areaMax,
                        areaMaximorum: reservatorio.areaMaximorum,
                        areaMin: reservatorio.areaMin,
                        reservatorioVidaMaxOperativo: reservatorio.reservatorioVidaMaxOperativo,
                        reservatorioVidaUtil: reservatorio.reservatorioVidaUtil,
                    });
                    this.idReservatorio++;
                })
                this.dataSourceReservatorio = new MatTableDataSource(this.reservatoriosSalvos);
                this.enviarReservatorio(this.mapearArray());
            })
        }

        if (this.isNaoPodeEditar) {
            this.formReservatorios.disable();
        }
    }

    private buildFormReservatorios(): void {
        this.formReservatorios = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            identificacao: ["", [Validators.required]],
            volumeMax: ["", [Validators.required]],
            volumeMin: ["", [Validators.required]],
            volumeUtil: ["", [Validators.required]],
            areaMax: ["", [Validators.required]],
            areaMaximorum: ["", [Validators.required]],
            areaMin: ["", [Validators.required]],
            reservatorioVidaMaxOperativo: ["", [Validators.required]],
            reservatorioVidaUtil: ["", [Validators.required]],
        });
    }

    adicionarReservatorio() {
        if(this.formReservatorios.valid){
            this.reservatoriosSalvos.push({
                id: null,
                idTemp: this.idReservatorio,
                identificacao: this.formReservatorios.controls.identificacao.value,
                volumeMax: this.formReservatorios.controls.volumeMax.value,
                volumeMin: this.formReservatorios.controls.volumeMin.value,
                volumeUtil: this.formReservatorios.controls.volumeUtil.value,
                areaMax: this.formReservatorios.controls.areaMax.value,
                areaMaximorum: this.formReservatorios.controls.areaMaximorum.value,
                areaMin: this.formReservatorios.controls.areaMin.value,
                reservatorioVidaMaxOperativo: this.formReservatorios.controls.reservatorioVidaMaxOperativo.value,
                reservatorioVidaUtil: this.formReservatorios.controls.reservatorioVidaUtil.value,
            })
            this.idReservatorio++;
            this.dataSourceReservatorio = new MatTableDataSource(this.reservatoriosSalvos);
            this.enviarReservatorio(this.mapearArray());
            this.formReservatorios.reset();
        }else{
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            this.formReservatorios.markAllAsTouched();
        }
    }

    enviarReservatorio(reservatorio){
        this.enviarReservatorios.emit(reservatorio);
    }

    mapearArray(): any{
        if(this.reservatoriosSalvos.length == 0){
            return this.reservatoriosSalvos;
        }else{
            return this.reservatoriosSalvos.map(function(item){
                return new Reservatorio(
                    item.id,
                    null,
                    item.identificacao,
                    Number(item.volumeMax),
                    Number(item.volumeMin),
                    Number(item.volumeUtil),
                    Number(item.areaMax),
                    Number(item.areaMaximorum),
                    Number(item.areaMin),
                    Number(item.reservatorioVidaMaxOperativo),
                    Number(item.reservatorioVidaUtil),
                );
            });
        }
    }

    ativarModoEdicao(item){
        this.formReservatorios.controls.id.patchValue(item.id);
        this.formReservatorios.controls.idTemp.patchValue(item.idTemp);
        this.formReservatorios.controls.identificacao.patchValue(item.identificacao);
        this.formReservatorios.controls.volumeMax.patchValue(item.volumeMax);
        this.formReservatorios.controls.volumeMin.patchValue(item.volumeMin);
        this.formReservatorios.controls.volumeUtil.patchValue(item.volumeUtil);
        this.formReservatorios.controls.areaMax.patchValue(item.areaMax);
        this.formReservatorios.controls.areaMaximorum.patchValue(item.areaMaximorum);
        this.formReservatorios.controls.areaMin.patchValue(item.areaMin);
        this.formReservatorios.controls.reservatorioVidaMaxOperativo.patchValue(item.reservatorioVidaMaxOperativo);
        this.formReservatorios.controls.reservatorioVidaUtil.patchValue(item.reservatorioVidaUtil);
        this.modoEdicao = true;
    }

    editarReservatorio(){
        if(this.formReservatorios.valid){
            this.deletarReservatorio(this.formReservatorios.controls.idTemp.value);

            this.reservatoriosSalvos.push({
                id: this.formReservatorios.controls.id.value,
                idTemp: this.formReservatorios.controls.idTemp.value,
                identificacao: this.formReservatorios.controls.identificacao.value,
                volumeMax: this.formReservatorios.controls.volumeMax.value,
                volumeMin: this.formReservatorios.controls.volumeMin.value,
                volumeUtil: this.formReservatorios.controls.volumeUtil.value,
                areaMax: this.formReservatorios.controls.areaMax.value,
                areaMaximorum: this.formReservatorios.controls.areaMaximorum.value,
                areaMin: this.formReservatorios.controls.areaMin.value,
                reservatorioVidaMaxOperativo: this.formReservatorios.controls.reservatorioVidaMaxOperativo.value,
                reservatorioVidaUtil: this.formReservatorios.controls.reservatorioVidaUtil.value,
            })
            this.dataSourceReservatorio = new MatTableDataSource(this.reservatoriosSalvos);
            this.enviarReservatorio(this.mapearArray());
            this.formReservatorios.reset();
            this.modoEdicao = false;
        }else {
            this.formReservatorios.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    fecharEdicao(){
        this.modoEdicao = false;
        this.formReservatorios.reset();
    }

    deletarReservatorio(item, acao?: string, itemId?: number){
        if(acao == 'DELETAR' && itemId != null){
            this.eeHidricaService.deletarReservatoriosPorId(itemId).subscribe( response => {
                this.snackBarService.showSuccess("Reservatorio excluido com sucesso.")
            })
        }
        let data;
        data = this.reservatoriosSalvos.filter((value, key) => {
            return value.idTemp != item;
        });
        this.reservatoriosSalvos = data;
        this.dataSourceReservatorio = new MatTableDataSource(data);
        this.enviarReservatorio(this.mapearArray());
    }

}