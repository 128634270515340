import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseService} from "./base.service";
import {PessoaFisicaCorporativo} from "../models/pessoa-fisica-corporativo.model";
import {environment} from "../../../../environments/environment";
import {PessoaJuridicaCorporativo} from "../models/pessoa-juridica-corporativo.model";

@Injectable({
    providedIn: 'root'
})
export class PortalApiService extends BaseService<any> {
    private urlResourcePortal = environment.URL_PORTAL_API;

    constructor(protected injector: Injector) {
        super('portal', injector, PessoaFisicaCorporativo.prototype, PessoaFisicaCorporativo.fromJson);
    }

    buscarPerfilUsuario(cpf: string): Observable<any> {
        const url = `${this.urlResourcePortal}/api/perfis-usuario/usuarios-by-cpf/${cpf}`;
        return this.http.get(url);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}