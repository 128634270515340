import {Pipe, PipeTransform} from '@angular/core';
import {ObjetivoOutorga} from '../models/objetivo-outorga.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';

@Pipe({
    name: 'filterTiposObjetivo'
})
export class FilterTiposObjetivoPipe implements PipeTransform {

    transform(list: ObjetivoOutorga[], tipo: BaseEnum): ObjetivoOutorga[] {
        if (!tipo) {
            return list;
        }
        if (!list) {
            return [];
        }
        return list.filter(item => item.tipoOutorga === tipo.valor);
    }

}
