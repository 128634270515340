import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Requerimento} from '../../../requerimento.model';
import {ObjetivoRequerimentoOutorga} from '../../../../../shared/models/objetivo-requerimento-outorga.model';
import {RequerimentoTitulacao} from '../../../shared/requerimento-titulacao/shared/requerimento-titulacao.model';
import {Finalidade} from '../../../../finalidades/finalidade.model';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {FormUtil} from '../../../../../shared/util/form-util';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {PontoMapa} from '@sema-geo/sema-geoportal';
import {FiltroObjetivoFinalidadeDTO} from '../../../../../shared/models/filtro-objetivo-finalidade-dto.model';
import {RequerimentoOutorga} from '../../requerimento-outorga.model';
import {GeometriaMapa} from '@sema-geo/sema-geoportal';
import {Observable} from 'rxjs';
import {ParametroDiluicao} from "../../../../../shared/models/hidrico-models/parametro-diluicao.model";
import {ParametroDiluicaoService} from "../../../../../shared/services/parametro-diluicao.service";
import {MessageDialogComponent} from "../../../../../shared/components/message-dialog/message-dialog.component";

export interface EditarPontoDialogData {
    requerimento: Requerimento;
    titulacoesRequerimento: RequerimentoTitulacao[];
    objetivosAdicionados: ObjetivoRequerimentoOutorga[];
    configuracaoFiltro: FiltroObjetivoFinalidadeDTO[];
    finalidadesAdicionadas: Finalidade[];
    requerimentoOutorga: RequerimentoOutorga;
    ponto: PontoMapa;
    objetivoRequerimento: ObjetivoRequerimentoOutorga;

    onRemoveFeature: (geometria: GeometriaMapa) => void;
}

@Component({
    selector: 'app-editar-ponto-dialog',
    templateUrl: './editar-ponto-dialog.component.html',
    styleUrls: ['./editar-ponto-dialog.component.scss']
})
export class EditarPontoDialogComponent implements OnInit {

    maskInputLatLng = '';

    ponto: PontoMapa;

    parametroDiluicao: ParametroDiluicao;

    requerimento: Requerimento;

    requerimentoOutorga: RequerimentoOutorga;

    objetivosAdicionados: ObjetivoRequerimentoOutorga[];

    titulacoesRequerimento: RequerimentoTitulacao[];

    parametrosDiluicao: ParametroDiluicao[];
    parametrosDiluicaoFiltrados: ParametroDiluicao[] = [];

    configuracaoFiltro: FiltroObjetivoFinalidadeDTO[];

    finalidadesAdicionadas: Finalidade[];

    finalidadesFiltradas: Finalidade[] = [];

    formatoCoordenadas = new FormControl('latLng', Validators.required);

    onRemoveFeature: (geometria: GeometriaMapa) => void;

    pontoForm: FormGroup;

    dicaConcentracao: string;
    dicaFinalidade: string;
    dicaVazao: string;

    constructor(
        private formBuilder: FormBuilder,
        private pontoService: PontoService,
        private parametroDiluicaoService: ParametroDiluicaoService,
        private snackBarService: SnackBarService,
        private dialogRef: MatDialogRef<EditarPontoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: EditarPontoDialogData,
        private readonly dialog: MatDialog
    ) {
        this.requerimento = data.requerimento;
        this.objetivosAdicionados = data.objetivosAdicionados;
        this.titulacoesRequerimento = data.titulacoesRequerimento;
        this.configuracaoFiltro = data.configuracaoFiltro;
        this.requerimentoOutorga = data.requerimentoOutorga;
        this.finalidadesAdicionadas = data.finalidadesAdicionadas;
        this.onRemoveFeature = data.onRemoveFeature;
        this.ponto = data.ponto;

        this.formatoCoordenadas.patchValue('latLng');
        this.formatoCoordenadas.disable();

        const ponto = data.ponto;
        this.pontoForm = this.formBuilder.group({
            id: [null],
            nomePonto: [null, [Validators.required, Validators.maxLength(100)]],
            latitude: [null, [Validators.required]],
            longitude: [null, [Validators.required]],
            objetivoRequerimento: [data.objetivoRequerimento, [Validators.required]],
            finalidade: [null, [Validators.required]],
            vazao: [null],
            point: [null],
            concentracaoParametro: [null],
            idParametro: [null],
            titulacao: [null, this.requerimento.tipoRequerimento.isFluxoRenovar ? Validators.required : []],
            codigoIbgeMunicipio: null
        });

        this.pontoForm.patchValue({
            id: ponto.id,
            latitude: ponto.latitude,
            longitude: ponto.longitude,
            codigoIbgeMunicipio: ponto.extra.codigoIbgeMunicipio
        });

        this.pontoForm.controls.latitude.disable();
        this.pontoForm.controls.longitude.disable();

        if (!this.requerimento.tipoRequerimento.isFluxoEmitir && this.titulacoesRequerimento.length === 1) {
            this.pontoForm.controls.titulacao.setValue(this.titulacoesRequerimento[0].titulacao);
        }
        this.pontoForm.markAsUntouched();
        this.pontoForm.updateValueAndValidity();
        this.dicaFinalidade = 'Inserir a finalidade principal, a de maior uso no ponto.'
        this.dicaVazao = 'Inserir a vazão total de uso deste ponto, considerando a finalidade principal e outras atividades secundárias se houver.'
    }

    async ngOnInit(): Promise<void> {
        this.parametrosDiluicao = await this.parametroDiluicaoService
            .findAllByFinalidade(this.finalidadesAdicionadas, this.ponto.extra.idMicrobacia).toPromise()
            .catch(e => {
                this.snackBarService.showError('Erro ao consultar configuração de parametros', e);
                return [];
            });

        if (this.objetivosAdicionados.length === 1) {
            this.pontoForm.controls.objetivoRequerimento.setValue(this.objetivosAdicionados[0]);
        }
        this.onChangeObjetivo();
    }

    onChangeFormato(): void {
        this.maskInputLatLng = this.formatoCoordenadas.value === 'grau' ? '00° 00\' 00.0"' : 'separator.6';
        this.formatoCoordenadas.markAsUntouched();
        this.pontoForm.controls.latitude.setValue(null);
        this.pontoForm.controls.longitude.setValue(null);
        if (this.objetivosAdicionados.length === 1) {
            this.pontoForm.controls.objetivoRequerimento.setValue(this.objetivosAdicionados[0]);
        }
        this.pontoForm.markAsUntouched();
        this.pontoForm.updateValueAndValidity();
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    onRemover(): void {
        this.onRemoveFeature(this.ponto);
        this.dialogRef.close(false);
    }

    get tituloVazao(): string {
        const objetivo = this.pontoForm.controls.objetivoRequerimento.value.objetivo;
        return objetivo.tipoOutorga === 'SUPERFICIAL' ? 'Vazão (m³/s)' : 'Vazão (m³/dia)';
    }

    onChangeObjetivo(): void {
        const objetivoRequerimento = this.pontoForm.get('objetivoRequerimento').value;
        const vazaoControl = this.pontoForm.get('vazao');
        const parametroControl = this.pontoForm.get('idParametro');
        const concentracaoParametroControl = this.pontoForm.get('concentracaoParametro');
        vazaoControl.setValue('');
        vazaoControl.markAsUntouched();
        parametroControl.setValue('');
        parametroControl.markAsUntouched();
        concentracaoParametroControl.setValue('');
        concentracaoParametroControl.markAsUntouched();

        if (objetivoRequerimento && objetivoRequerimento.objetivo.geraVazao) {
            vazaoControl.setValidators([Validators.required, Validators.min(0.000001)]);
        } else {
            vazaoControl.clearValidators();
        }

        if (objetivoRequerimento && objetivoRequerimento.objetivo.exigeParametro) {
            parametroControl.setValidators([Validators.required]);
            concentracaoParametroControl.setValidators([Validators.required, Validators.min(0.000001)]);
        } else {
            parametroControl.clearValidators();
            concentracaoParametroControl.clearValidators();
        }

        vazaoControl.updateValueAndValidity();
        parametroControl.updateValueAndValidity();
        concentracaoParametroControl.updateValueAndValidity();
        this.pontoForm.updateValueAndValidity();

        const filtro = this.configuracaoFiltro.find(item => item.idObjetivo === objetivoRequerimento.objetivo.id);
        //this.finalidadesFiltradas = this.finalidadesAdicionadas.filter(fin => filtro.permiteFinalidade(fin));

        this.finalidadesAdicionadas.forEach((item) => {
            var duplicated  = this.finalidadesFiltradas.findIndex(redItem => {
                return item.id == redItem.id;
            }) > -1;

            if(!duplicated) {
                this.finalidadesFiltradas.push(item);
            }
        });
        
        if (this.finalidadesFiltradas.length === 1) {
            this.pontoForm.controls.finalidade.setValue(this.finalidadesFiltradas[0]);
            this.changeFinalidade(this.finalidadesFiltradas[0])
        } else {
            this.pontoForm.controls.finalidade.setValue(null);
        }
    }

    changeParametro(idParametro: any): void {
        this.parametroDiluicao = this.parametrosDiluicao.find(value => value.parametroId === idParametro);
    }

    changeFinalidade(finalidade: any): void {

        this.pontoForm.patchValue({parametro: null});
        this.parametrosDiluicaoFiltrados = this.parametrosDiluicao.filter(parametro => parametro.finalidade.id == finalidade.id);
        if (this.parametrosDiluicaoFiltrados.length === 0) {
            this.snackBarService.showAlert('Não há parâmetros configurados para a finalidade de ' + finalidade.descricao);
        } else if (this.parametrosDiluicaoFiltrados.length === 1) {
            this.pontoForm.patchValue({parametro: this.parametrosDiluicaoFiltrados[0].parametroId});
        }
    }

    get unidadeParametroHidrico(): string {
        return this.parametroDiluicao ? this.parametroDiluicao.unidade : '';
    }

    salvarPonto(): void {
        const ponto = Ponto.fromJson(this.pontoForm.getRawValue());
        if (this.formatoCoordenadas.value === 'grau') {
            ponto.latitude = FormUtil.parseDMSToDecimal(this.pontoForm.controls.latitude.value);
            ponto.longitude = FormUtil.parseDMSToDecimal(this.pontoForm.controls.longitude.value);
        }
        const objetivo = this.pontoForm.controls.objetivoRequerimento.value.objetivo;
        if(objetivo.tipoSolicitacao == 'CONCENTRACAO_EFLUENTE'){
            ponto.vazaoEfluente = Number(ponto.vazao)
        }
        let service: Observable<Ponto>;
        if (ponto.id) {
            service = this.pontoService.update(ponto);
        } else {
            service = this.pontoService.postPonto(this.requerimentoOutorga, ponto);
        }

        service.subscribe(() => {
            this.snackBarService.showSuccess('Ponto de captação salvo com sucesso!');
            this.dialogRef.close(true);
        }, e => this.snackBarService.showError('Erro ao salvar ponto.', e));
    }

    exibirInfoConcentracao(evt){
        this.dialog.open(MessageDialogComponent, {
            data: {
                icon: 'info',
                title: 'Concentração',
                message: 'Informe a concentração do parâmetro de diluição selecionado.',
                buttons: true
            }
        })

        evt.stopPropagation();
        evt.preventDefault();
    }

    exibirInfoFinalidade(evt){
        this.dialog.open(MessageDialogComponent, {
            data: {
                icon: 'info',
                title: 'Finalidade',
                message: 'Informe a finalidade principal do objetivo selecionado. Apenas UMA finalidade deve ser selecionada para cada objetivo.' +
                    ' Outras finalidades devem ser explicadas nas observações da finalidade principal na aba "6-Dados da Atividade" ' +
                    'e/ou nos anexos inseridos na aba "7-Documentos Complementares".',
                buttons: true

            }
        })

        evt.stopPropagation();
        evt.preventDefault();
    }

    exibirInfoVazao(evt){
        this.dialog.open(MessageDialogComponent, {
            data: {
                icon: 'info',
                title: 'Vazão',
                message: 'Informe a vazão máxima e na próxima aba do requerimento deverá detalhar a informação fazendo a distribuição mensal até atingir o valor máximo informado aqui.',
                buttons: true
            }
        })

        evt.stopPropagation();
        evt.preventDefault();
    }
}
