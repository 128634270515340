import {FeicaoRequerimento} from '../../pages/feicoes-requerimentos/feicao-requerimento.model';
import {GeometriaDTO} from './geometria-dto.model';

export class FeicaoGeometriaDto {
    constructor(
        public feicaoRequerimento?: FeicaoRequerimento,
        public geometriaDTO?: GeometriaDTO
    ) {
    }

    static fromJson(json: any): FeicaoGeometriaDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new FeicaoGeometriaDto(
            FeicaoRequerimento.fromJson(json.feicaoRequerimento),
            GeometriaDTO.fromJson(json.geometriaDTO),
        );
    }

    static fromJsons(jsonArray: any): FeicaoGeometriaDto[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
