import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ItemTermoReferencia} from '../item-termo-referencia.model';
import {ItemTermoReferenciaService} from '../item-termo-referencia.service';

@Component({
    selector: 'app-item-termo-referencia-informacoes-adicionais-dialog',
    templateUrl: './item-termo-referencia-informacoes-adicionais-dialog.component.html',
})
export class ItemTermoReferenciaInformacoesAdicionaisDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<ItemTermoReferenciaInformacoesAdicionaisDialogComponent>,
        private itemTermoReferenciaService: ItemTermoReferenciaService,
        @Inject(MAT_DIALOG_DATA) public data: ItemTermoReferencia
    ) {
    }

    onClose(): void {
        this.dialogRef.close(false);
    }

    visualizarDocumento(): void {
        window.open(`${this.itemTermoReferenciaService.urlResource}/${this.data.id}/visualizar`);
    }
}
