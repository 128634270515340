import { Component, ComponentFactoryResolver, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren, ViewContainerRef } from '@angular/core';
import { RequerimentoLicenciamento } from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model';
import { EeHidricaService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-hidrica/ee-hidrica.service';
import { EmpreendimentoEnergeticoTermicaService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-termica/empreendimento-energetico-termica.service';
import { InfraestruturaObrasViariasService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infraestrutura-obras-viarias/infraestrutura-obras-viarias.service';
import { ResiduoGeralService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-geral/residuo-geral.service';
import { ResiduosTransporteProdutosPerigososService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-transporte-produtos-perigosos/residuos-transporte-produtos-perigosos.service';
import { RequerimentoOutorgaFinalidade } from 'app/main/shared/models/requerimento-outorga-finalidade.model';
import { RequerimentoOutorgaFinalidadeService } from 'app/main/shared/services/requerimento-outorga-finalidade.service';
import { RequerimentoFormularioLicenciamentoService } from '../../../pages/requerimentos/requerimento-licenciamento/requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service';
import { RequerimentoLicenciamentoService } from '../../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.service';
import { AtividadeSubAtividade } from '../../../pages/requerimentos/requerimento-licenciamento/requerimento-taxa-licenciamento/atividade-subatividade.dto';
import { AtividadeSubatividadeService } from '../../../pages/requerimentos/requerimento-licenciamento/requerimento-taxa-licenciamento/atividade-subatividade.service';
import { EspelhoOutorgaDto } from '../../../pages/requerimentos/requerimento-outorga/espelho-outorga-dto.model';
import { RequerimentoOutorgaService } from '../../../pages/requerimentos/requerimento-outorga/requerimento-outorga.service';
import { Requerimento } from '../../../pages/requerimentos/requerimento.model';
import { FormularioReqLicenciamentoDto } from '../../models/formulario-req-licenciamento.dto';
import { Ponto } from '../../models/hidrico-models/ponto.model';
import { ObjetivoRequerimentoOutorga } from '../../models/objetivo-requerimento-outorga.model';
import { AguaPassivoSupressaoService } from '../../services/agua-passivo-supressao.service';
import { AtividadeMineracaoService } from '../../services/atividade-mineracao.service';
import { EeLinhaTransmissaoService } from '../../services/ee-linha-transmissao.service';
import { EmpreendimentoCemiterioCrematorioService } from '../../services/empreendimento-cemiterio-crematorio.service';
import { EmpreendimentoEnergeticoService } from '../../services/empreendimento-energetico.service';
import { EmpreendimentoEnergiaFotovoltaicaService } from '../../services/empreendimento-energia-fotovoltaica.service';
import { EmpreendimentoGerenciamentoResiduosService } from '../../services/empreendimento-gerenciamento-residuos.service';
import { EmpreendimentoInfraParcelSoloResComService } from '../../services/empreendimento-infra-parcel-solo-res-com.service';
import { EmpreendimentoService } from '../../services/empreendimento.service';
import { IndustriaBiocombustivelService } from '../../services/industria-biocombustivel.service';
import { IndustriaFrigorificoService } from '../../services/industria-frigorifico.service';
import { InfraObraFluvSaneamService } from '../../services/infra-obra-fluv-saneam.service';
import { AtividadeParametroLicenciamentoService } from '../../services/licenciamento-config-service/atividade-parametro-licenciamento.service';
import { MadeiraService } from '../../services/madeira.service';
import { ObjetivoRequerimentoOutorgaService } from '../../services/objetivo-requerimento-outorga.service';
import { PontoService } from '../../services/ponto.service';
import { ResiduoAterroIndUrbService } from '../../services/residuo-aterro-ind-urb.service';
import { ResiduoRccService } from '../../services/residuo-rcc.service';
import { ResiduoTratamentoTermicoService } from '../../services/residuo-tratamento-termico.service';
import { ServicoCombustivelService } from '../../services/servico-combustivel.service';
import { ServicoGeralService } from '../../services/servico-geral.service';
import { SistemaTratamentoService } from '../../services/sistema-tratamento.service';
import {RequerimentoEmpreendimentoAtividadeFormulario} from "../../../pages/tarefas-bpms/lic-ambiental/@shared/aba-atividades-lic-emp/requerimento-empreendimento-atividade-formulario";
import { EnumInstanciaRequerimento } from 'app/main/pages/requerimentos/menu-novo-requerimento/instancia-requerimento.enum';
import {AtividadeArrecadacaoService} from '../../services/arrecadacao-service/atividade-arrecadacao.service';

@Component({
    selector: 'app-aba-requerimento-atividade',
    templateUrl: 'aba-atividades.component.html'
})
export class AbaAtividadesComponent implements OnInit, OnChanges {

    @Input() requerimento = new Requerimento();
    espelhoOutorgaDto: EspelhoOutorgaDto;
    requerimentoOutorgaFinalidades: RequerimentoOutorgaFinalidade[] = [];
    objetivoRequementoOutorga: ObjetivoRequerimentoOutorga;
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoDto;
    pontos: Ponto[] = [];
    listaAtividades: Array<AtividadeSubAtividade> = [];
    formularioPonto: string;
    parametroEntrada: any = [];

    possuiFinalidadesNaoConcluidas = false;
    possuiPontosNaoConcluidos = false;

    requerimentoEmpreendimentoAtividade: any[] = [];
    @ViewChildren('formularioContainer', { read: ViewContainerRef }) formularioContainer: QueryList<ViewContainerRef>;
    formsConcluidos: any[] = [];


    @Input()
    requerimentoLicenciamentoVinculado: RequerimentoLicenciamento;
    
    requerimentoLicenciamento: RequerimentoLicenciamento;

    subAtividade: any;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private atividadeService: AtividadeSubatividadeService,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private objetivoRequerimentoOutorgaService: ObjetivoRequerimentoOutorgaService,
        private pontoService: PontoService,
        private requerimentoOutorgaService: RequerimentoOutorgaService,
        protected requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private mineracaoService: AtividadeMineracaoService,
        private atividadeParamLicenciamentoService: AtividadeParametroLicenciamentoService,
        private residuoAterroIndUrbService: ResiduoAterroIndUrbService,
        private industriaFrigorificoService: IndustriaFrigorificoService,
        private empreendimentoCemiterioCrematorioService: EmpreendimentoCemiterioCrematorioService,
        private servicoCombustivelService: ServicoCombustivelService,
        private industriaBiocombustivelService: IndustriaBiocombustivelService,
        private residuosRccService: ResiduoRccService,
        private empreendimentoGerenciamentoResiduosService: EmpreendimentoGerenciamentoResiduosService,
        private residuoTratamentoTermicoService: ResiduoTratamentoTermicoService,
        private transporteProdutosPerigososService: ResiduosTransporteProdutosPerigososService,
        private residuoGeralService: ResiduoGeralService,
        private aguaPassivoSupressaoService: AguaPassivoSupressaoService,
        private sistemaTratamentoService: SistemaTratamentoService,
        private madeiraService: MadeiraService,
        private empreendimentoInfraParcelSoloResComService: EmpreendimentoInfraParcelSoloResComService,
        private infraObrasViariasService: InfraestruturaObrasViariasService,
        private eeHidricaService: EeHidricaService,
        private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService,
        private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService,
        private eeLinhaTransmissaoService: EeLinhaTransmissaoService,
        private empreendimentoSubestacaoService: EmpreendimentoService,
        private empreendimentoEnergiaEolicaService: EmpreendimentoEnergeticoService,
        private infraObraFluvSaneamService: InfraObraFluvSaneamService,
        private requerimentoFormLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private servicoGeralService: ServicoGeralService,
        private arrecadacaoService: AtividadeArrecadacaoService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        if (this.requerimento  && this.requerimento.id) {
            this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
            this.carregarFormulariosAtividades();
            this.carregarAtividade();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            this.fetchRequerimentoEspelho();
        }
    }


    private carregarFormulariosAtividades() {
        this.atividadeParamLicenciamentoService.buscarFormulariosPorIdAtividade(this.requerimentoLicenciamento.idAtividade,
            this.requerimentoLicenciamento.id).toPromise()
            .then(response => {
                response = response.filter(requerimentoFormularioAtividade =>
                    RequerimentoEmpreendimentoAtividadeFormulario.get(requerimentoFormularioAtividade));
                this.requerimentoEmpreendimentoAtividade = response;
                this.requerimentoEmpreendimentoAtividade.forEach(async form => {
                    await this.verificarPreenchimentoFormularios(form.constante);
                });
            }
            )
    }

    private async carregarAtividade(){
        await this.arrecadacaoService.getAtividadeId(this.requerimentoLicenciamento.idAtividade).toPromise()
            .then(subAtividade => {
                this.subAtividade = subAtividade;
            })
    }

    loadFinalidadeContainer(requerimentoEmpreendimentoAtividade: any, index: number): void {
        const formularioContainer = this.formularioContainer.find((e, i, a) => i === index);
        formularioContainer.clear();
        const type = RequerimentoEmpreendimentoAtividadeFormulario.get(requerimentoEmpreendimentoAtividade).resolved;
        const factory = this.componentFactoryResolver.resolveComponentFactory(type);
        const ref = formularioContainer.createComponent(factory);
        ref.instance.requerimento = this.requerimento;
        ref.instance.isModoVisualizacao = true;
        ref.instance.requerimento.desabilitarAtividades = true
        ref.instance.enviarFormConcluido.subscribe((value) => {
            this.recarregarForms();
        })
    }
    recarregarForms() {
        this.formsConcluidos.forEach(async form => {
            await this.verificarPreenchimentoFormularios(form.formularioDesc);
        });
    }
    
    get possuiAtividades(): boolean {
        return this.requerimentoEmpreendimentoAtividade && this.requerimentoEmpreendimentoAtividade.length > 0;
    }

    public validarFormTitulacaoCaptacao(ponto: Ponto): boolean {
        if (ponto.objetivoRequerimento) {
            return ponto.titulacao.formulario === 'CAPTACAO_INSIGNIFICANTE' && !!ponto.titulacao.formulario;
        }
    }

    public validarFormObjetivoCaptacao(ponto: Ponto): boolean {
        if (ponto.objetivoRequerimento) {
            return ponto.objetivoRequerimento.objetivo.formulario === 'CAPTACAO_SUPERFICIAL' && !ponto.titulacao.formulario;
        }
    }

    private validarStep(): void {
        if (this.requerimentoOutorgaFinalidades) {
            this.possuiFinalidadesNaoConcluidas = this.requerimentoOutorgaFinalidades
                .some(requerimentoOutorgaFinalidade => !requerimentoOutorgaFinalidade.cadastroConcluido);
        }
        if (this.pontos) {
            this.possuiPontosNaoConcluidos = this.pontos.some(ponto => !ponto.formularioConcluido);
        }
    }

    private async carregarAtividadeSubatividades(): Promise<void> {
        await this.requerimentoLicenciamentoService
            .getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id)
            .subscribe(async lista => {
                await this.atividadeService.getListaAtividadeCalculoTaxa(lista).subscribe(listaAtividades => {
                    this.listaAtividades.push(listaAtividades);
                });
            }, e => this.listaAtividades = []);
    }

    private carregarRequerimentosOutorga(): void {
        this.requerimentoOutorgaFinalidadeService
            .getByRequerimentoOutorgaId(this.espelhoOutorgaDto.requerimentoOutorga.id)
            .subscribe(
                requerimentoOutorgaFinalidades => {
                    this.requerimentoOutorgaFinalidades = requerimentoOutorgaFinalidades;
                }, e => this.requerimentoOutorgaFinalidades = null
            );
    }

    private carregarPontos(): void {
        this.pontoService
            .getPontos(this.espelhoOutorgaDto.requerimentoOutorga)
            .subscribe(pontos => {
                this.pontos = pontos;
                this.validarStep();
            }, e => this.pontos = null);
    }

    private carregarObjetivoRequerimentoOutorga(): void {
        this.objetivoRequerimentoOutorgaService
            .getAllByRequerimentoOutorga(this.espelhoOutorgaDto.requerimentoOutorga)
            .subscribe(objetivosRequerimentoOutorga => {
                if (objetivosRequerimentoOutorga.length > 0) {
                    this.objetivoRequementoOutorga = objetivosRequerimentoOutorga[0];
                    this.formularioPonto = this.objetivoRequementoOutorga.objetivo.formulario;
                }
            }, e => this.objetivoRequementoOutorga = null);
    }

    private fetchRequerimentoEspelho(): void {
        if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
            this.requerimentoOutorgaService.getEspelhoOutorga(this.requerimento).subscribe(requerimentoOutoroga => {
                this.espelhoOutorgaDto = requerimentoOutoroga;
                this.carregarRequerimentosOutorga();
                this.carregarPontos();
                if (!this.formularioPonto) {
                    this.carregarObjetivoRequerimentoOutorga();
                }
            }, e => this.espelhoOutorgaDto = null);
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO) {
            this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId
            (this.requerimento.id).subscribe(formularioReqLicenciamentoDto => {
                this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                this.parametroEntrada = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList;
                this.carregarAtividadeSubatividades();
            }, e => this.formularioReqLicenciamentoDto = null);
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA) {
            this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId
            (this.requerimento.id).subscribe(formularioReqLicenciamentoDto => {
                this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                this.parametroEntrada = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList;
                this.carregarAtividadeSubatividades();
            }, e => this.formularioReqLicenciamentoDto = null);
        }
    }

    get possuiAtividadesOutorga(): boolean {
        return this.requerimento && this.requerimento.tipoProcesso.tipo === 'OUTORGA'
            && this.requerimentoOutorgaFinalidades && this.requerimentoOutorgaFinalidades.length > 0
            && this.requerimentoOutorgaFinalidades.some(req => req.finalidade.formulario !== 'SEM_FORMULARIO');
    }

    get atividadesOutorgaVazia(): boolean {
        return this.requerimento && this.requerimento.tipoProcesso.tipo === 'OUTORGA'
            && (!this.requerimentoOutorgaFinalidades
                || this.requerimentoOutorgaFinalidades.length === 0
                || this.requerimentoOutorgaFinalidades.some(req => req.finalidade.formulario === 'SEM_FORMULARIO'));
    }



    receberFormConcluido(detalheForm: any) {
        const objIndex = this.formsConcluidos.findIndex((obj => obj.formularioDesc == detalheForm.formularioDesc));

        if (objIndex >= 0) {
            this.formsConcluidos[objIndex] = detalheForm;
        } else {
            this.formsConcluidos.push(detalheForm);
        }
    }

    verificarPreenchimentoFormularios(formDesc) {
        switch (formDesc) {
            case 'mineracao':
                this.mineracaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(mineracao => {
                    if (mineracao) {
                        this.receberFormConcluido({
                            idForm: mineracao.id,
                            formConcluido: true,
                            formularioDesc: 'mineracao',
                            nomeForm: 'Mineração'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'mineracao',
                            nomeForm: 'Mineração'
                        })
                    }
                });
                break;
            case 'residuos-aterro-industrial-urbano':
                this.residuoAterroIndUrbService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuoAterro => {
                    if (residuoAterro) {
                        this.receberFormConcluido({
                            idForm: residuoAterro.id,
                            formConcluido: true,
                            formularioDesc: 'residuos-aterro-industrial-urbano',
                            nomeForm: 'Resíduos - Aterro Industrial/Urbano'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false, formularioDesc: 'residuos-aterro-industrial-urbano',
                            nomeForm: 'Resíduos - Aterro Industrial/Urbano'
                        })
                    }
                });
                break;
            case 'industria-frigorifico':
                this.industriaFrigorificoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(industriaFrigorifico => {
                    if (industriaFrigorifico) {
                        this.receberFormConcluido({
                            idForm: industriaFrigorifico.id,
                            formConcluido: true,
                            formularioDesc: 'industria-frigorifico',
                            nomeForm: 'Indústria - Abate/Frigorífico de animais'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false, formularioDesc: 'industria-frigorifico',
                            nomeForm: 'Indústria - Abate/Frigorífico de animais'
                        })
                    }
                });
                break;
            case 'residuos-cemiterio-crematorio':
                this.empreendimentoCemiterioCrematorioService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(cemiterioCrematorio => {
                    if (cemiterioCrematorio) {
                        this.receberFormConcluido({
                            idForm: cemiterioCrematorio.id, formConcluido: true,
                            formularioDesc: 'residuos-cemiterio-crematorio', nomeForm: 'Resíduos - Cemitério/Crematório'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-cemiterio-crematorio', nomeForm: 'Resíduos - Cemitério/Crematório'
                        })
                    }
                });
                break;
            case 'servico-combustivel':
                this.servicoCombustivelService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(servicoCombustivel => {
                    if (servicoCombustivel) {
                        this.receberFormConcluido({
                            idForm: servicoCombustivel.id, formConcluido: true,
                            formularioDesc: 'servico-combustivel', nomeForm: 'Serviços - Combustível'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'servico-combustivel', nomeForm: 'Serviços - Combustível'
                        })
                    }
                });
                break;
            case 'industria-biocombustivel':
                this.industriaBiocombustivelService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(industriaBiocombustivel => {
                    if (industriaBiocombustivel) {
                        this.receberFormConcluido({
                            idForm: industriaBiocombustivel.id, formConcluido: true,
                            formularioDesc: 'industria-biocombustivel', nomeForm: 'Indústria - Biocombustível'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'industria-biocombustivel', nomeForm: 'Indústria - Biocombustível'
                        })
                    }
                });
                break;
            case 'residuos-rcc':
                this.residuosRccService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuosRcc => {
                    if (residuosRcc) {
                        this.receberFormConcluido({
                            idForm: residuosRcc.id, formConcluido: true,
                            formularioDesc: 'residuos-rcc', nomeForm: 'Resíduos - Construção Civil'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-rcc', nomeForm: 'Resíduos - Construção Civil'
                        })
                    }
                });
                break;
            case 'gerenciamento-residuos':
                this.empreendimentoGerenciamentoResiduosService.listarResiduosGeradosPorRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(gerenciamentoResiduos => {
                    if (gerenciamentoResiduos) {
                        this.receberFormConcluido({
                            idForm: gerenciamentoResiduos[0].id,
                            formConcluido: true,
                            formularioDesc: 'gerenciamento-residuos',
                            nomeForm: 'Resíduos - Plano de Gerenciamento de Resíduos Sólidos'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'gerenciamento-residuos',
                            nomeForm: 'Resíduos - Plano de Gerenciamento de Resíduos Sólidos'
                        })
                    }
                });
                break;
            case 'residuos-tratamento-termico':
                this.residuoTratamentoTermicoService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(tratamentoTermico => {
                    if (tratamentoTermico) {
                        this.receberFormConcluido({
                            idForm: tratamentoTermico.id, formConcluido: true,
                            formularioDesc: 'residuos-tratamento-termico', nomeForm: 'Resíduos - Tratamento Térmico'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-tratamento-termico', nomeForm: 'Resíduos - Tratamento Térmico'
                        })
                    }
                });
                break;
            case 'residuos-transporte-produtos-perigosos':
                this.transporteProdutosPerigososService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(produtosPerigosos => {
                    if (produtosPerigosos) {
                        this.receberFormConcluido({
                            idForm: produtosPerigosos.id,
                            formConcluido: true,
                            formularioDesc: 'residuos-transporte-produtos-perigosos',
                            nomeForm: 'Resíduos - Transporte de resíduos e produtos perigosos'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'residuos-transporte-produtos-perigosos',
                            nomeForm: 'Resíduos - Transporte de resíduos e produtos perigosos'
                        })
                    }
                });
                break;
            case 'residuos-geral':
                this.residuoGeralService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuoGeral => {
                    if (residuoGeral) {
                        this.receberFormConcluido({
                            idForm: residuoGeral.id, formConcluido: true,
                            formularioDesc: 'residuos-geral', nomeForm: 'Resíduos - Geral'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-geral', nomeForm: 'Resíduos - Geral'
                        })
                    }
                });
                break;
            case 'agua-passivo-supressao':
                this.aguaPassivoSupressaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(aguaPassivoSupressao => {
                    if (aguaPassivoSupressao) {
                        this.receberFormConcluido({
                            idForm: aguaPassivoSupressao.id, formConcluido: true,
                            formularioDesc: 'agua-passivo-supressao', nomeForm: 'Água Passivo Supressão'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'agua-passivo-supressao', nomeForm: 'Água Passivo Supressão'
                        })
                    }
                });
                break;
            case 'sistema-tratamento':
                this.sistemaTratamentoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(sistemaTratamento => {
                    if (sistemaTratamento) {
                        this.receberFormConcluido({
                            idForm: sistemaTratamento.id,
                            formConcluido: true,
                            formularioDesc: 'sistema-tratamento',
                            nomeForm: 'Sistema de tratamento de efluentes doméstico e industrial'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'sistema-tratamento',
                            nomeForm: 'Sistema de tratamento de efluentes doméstico e industrial'
                        })
                    }
                });
                break;
            case 'madeira':
                this.madeiraService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(madeira => {
                    if (madeira) {
                        this.receberFormConcluido({
                            idForm: madeira.id, formConcluido: true,
                            formularioDesc: 'madeira', nomeForm: 'Madeira'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'madeira', nomeForm: 'Madeira'
                        })
                    }
                });
                break;
            case 'infra-parcel-solo-res-com':
                this.empreendimentoInfraParcelSoloResComService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(infraParcelSolo => {
                    if (infraParcelSolo) {
                        this.receberFormConcluido({
                            idForm: infraParcelSolo.id,
                            formConcluido: true,
                            formularioDesc: 'infra-parcel-solo-res-com',
                            nomeForm: 'Infraestrutura - Obras Residenciais/Comerciais / Parcelamento do solo'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'infra-parcel-solo-res-com',
                            nomeForm: 'Infraestrutura - Obras Residenciais/Comerciais / Parcelamento do solo'
                        })
                    }
                });
                break;
            case 'infraestrutura-obras-viarias':
                this.infraObrasViariasService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(infraObraViaria => {
                    if (infraObraViaria) {
                        this.receberFormConcluido({
                            idForm: infraObraViaria.id, formConcluido: true,
                            formularioDesc: 'infraestrutura-obras-viarias', nomeForm: 'Infraestrutura - Obras Viárias'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'infraestrutura-obras-viarias', nomeForm: 'Infraestrutura - Obras Viárias'
                        })
                    }
                });
                break;
            case 'ee-hidrica':
                this.eeHidricaService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeHidrica => {
                    if (eeHidrica) {
                        this.receberFormConcluido({
                            idForm: eeHidrica.id, formConcluido: true,
                            formularioDesc: 'ee-hidrica', nomeForm: 'Empreendimentos Energéticos - Hídrica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-hidrica', nomeForm: 'Empreendimentos Energéticos - Hídrica'
                        })
                    }
                });
                break;
            case 'ee-termica':
                this.empreendimentoEnergService.buscaEEtermicaPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeTermica => {
                    if (eeTermica) {
                        this.receberFormConcluido({
                            idForm: eeTermica.id, formConcluido: true,
                            formularioDesc: 'ee-termica', nomeForm: 'Empreendimentos Energéticos - Térmica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-termica', nomeForm: 'Empreendimentos Energéticos - Térmica'
                        })
                    }
                });
                break;
            case 'ee-fotovoltaica':
                this.empreendimentoEnergiaFotovoltaicaService.consultarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeFotovoltaica => {
                    if (eeFotovoltaica) {
                        if (eeFotovoltaica.formConcluido) {
                            this.receberFormConcluido({
                                idForm: eeFotovoltaica.id,
                                formConcluido: true,
                                formularioDesc: 'ee-fotovoltaica',
                                nomeForm: 'Empreendimentos Energéticos - Fotovoltaica'
                            })
                        } else {
                            this.receberFormConcluido({
                                idForm: 0,
                                formConcluido: false,
                                formularioDesc: 'ee-fotovoltaica',
                                nomeForm: 'Empreendimentos Energéticos - Fotovoltaica'
                            })
                        }
                    }
                });
                break;
            case 'ee-linha-transmissao':
                this.eeLinhaTransmissaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeLinha => {
                    if (eeLinha) {
                        this.receberFormConcluido({
                            idForm: eeLinha.id,
                            formConcluido: true,
                            formularioDesc: 'ee-linha-transmissao',
                            nomeForm: 'Empreendimentos Energéticos - Linha de Transmissão'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'ee-linha-transmissao',
                            nomeForm: 'Empreendimentos Energéticos - Linha de Transmissão'
                        })
                    }
                });
                break;
            case 'ee-subestacao':
                this.empreendimentoSubestacaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeSubestacao => {
                    if (eeSubestacao) {
                        this.receberFormConcluido({
                            idForm: eeSubestacao.id, formConcluido: true,
                            formularioDesc: 'ee-subestacao', nomeForm: 'Empreendimentos Energéticos - Subestação'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-subestacao', nomeForm: 'Empreendimentos Energéticos - Subestação'
                        })
                    }
                });
                break;
            case 'ee-eolica':
                this.empreendimentoEnergiaEolicaService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeEolica => {
                    if (eeEolica) {
                        this.receberFormConcluido({
                            idForm: eeEolica.id, formConcluido: true,
                            formularioDesc: 'ee-eolica', nomeForm: 'Empreendimentos Energéticos - Eólica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-eolica', nomeForm: 'Empreendimentos Energéticos - Eólica'
                        })
                    }
                });
                break;
            case 'infra-obra-fluviais-saneamento':
                this.infraObraFluvSaneamService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(obraFluv => {
                    if (obraFluv) {
                        this.receberFormConcluido({
                            idForm: obraFluv.id,
                            formConcluido: true,
                            formularioDesc: 'infra-obra-fluviais-saneamento',
                            nomeForm: 'Infraestrutura - Obras Fluviais Saneamento'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'infra-obra-fluviais-saneamento',
                            nomeForm: 'Infraestrutura - Obras Fluviais Saneamento'
                        })
                    }
                });
                break;
            case 'servico-geral':
                this.servicoGeralService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(obraFluv => {
                    if (obraFluv) {
                        this.receberFormConcluido({
                            idForm: obraFluv.id, formConcluido: true,
                            formularioDesc: 'servico-geral', nomeForm: 'Serviços Gerais'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'servico-geral', nomeForm: 'Serviços Gerais'
                        })
                    }
                });
                break;
        }
    }
}
