import {Observable, throwError} from 'rxjs';
import {Fertirrigacao} from '../models/fertirrigacao.model';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FertirrigacaoService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_LIC_FEICAO_IRRIGACAO;

    createFertirrigacao(fertirrigacao: Fertirrigacao): Observable<Fertirrigacao> {
        const url = `${this.urlResource}/api/fertirrigacao`;
        return this.http.post(url, fertirrigacao)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }


}
