import {Component, Input, OnInit} from '@angular/core';
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../shared/models/dominio-dto.model";
import {FormBuilder, Validators} from "@angular/forms";
import {SnackBarService, SnackBarType} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {AtividadeMineracaoService} from "../../../../../../../shared/services/atividade-mineracao.service";
import {SubstanciaMineralService} from "../../../../../../../shared/services/substancia-mineral.service";
import {InformacaoAnmService} from "../../../../../../../shared/services/informacao-anm.service";
import {InformacaoLoService} from "../../../../../../../shared/services/informacao-lo.service";
import {PrevisaoVolumesProducaoService} from "../../../../../../../shared/services/previsao-volumes-producao.service";
import {
    RelacaoMaquinasEquipamentosService
} from "../../../../../../../shared/services/relacao-maquinas-equipamentos.service";
import {CombustivelUtilizadoService} from "../../../../../../../shared/services/combustivel-utilizado.service";
import {InsumoUtilizadoService} from "../../../../../../../shared/services/insumo-utilizado.service";
import {QuadroColaboradoresService} from "../../../../../../../shared/services/quadro-colaboradores.service";
import {ImpactoAmbientalService} from "../../../../../../../shared/services/impacto-ambiental.service";
import {MedidaControleService} from "../../../../../../../shared/services/medida-controle.service";
import {Mineracao} from "../../../../../../../shared/models/mineracao.model";
import {SubstanciaMineral} from "../../../../../../../shared/models/substancia-mineral.model";
import {InformacaoAnm} from "../../../../../../../shared/models/informacao-anm.model";
import {InformacaoLo} from "../../../../../../../shared/models/informacao-lo.model";
import {PrevisaoVolumesProducao} from "../../../../../../../shared/models/previsao-volumes-producao.model";
import {RelacaoMaquinasEquipamentos} from "../../../../../../../shared/models/relacao-maquinas-equipamentos.model";
import {CombustivelUtilizado} from "../../../../../../../shared/models/combustivel-utilizado.model";
import {InsumoUtilizado} from "../../../../../../../shared/models/insumo-utilizado.model";
import {QuadroColaboradores} from "../../../../../../../shared/models/quadro-colaboradores.model";
import {ImpactoAmbiental} from "../../../../../../../shared/models/impacto-ambiental.model";
import {MedidaControle} from "../../../../../../../shared/models/medida-controle.model";
import {AtividadeMineracao} from "../../../../../../../shared/models/atividade-mineracao.model";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {GeracaoPropria} from "../../../../../../../shared/models/geracao-propria.model";
import {GeracaoPropriaService} from "../../../../../../../shared/services/geracao-propria.service";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-aba-atividade-mineracao',
    templateUrl: './mineracao.component.html',
    styleUrls: ['./mineracao.component.scss']
})
export class MineracaoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    private TIPO_OUTRO = 9;

    objetosLicenciamento: DominioDto[] = [];

    isNaoPodeEditar: boolean = false;

    form = this.fb.group({
        id: [null],
        idRequerimento: [null],
        idTipoObjetoLicenciamento: [null, [Validators.required]],
        descricao: [null],
        aquisicaoFornecedora: [null, [Validators.required]],
        geracaoPropria: [null, [Validators.required]],
        consumoAquisicaoFornecedora: [null],
        substanciasMineral: [null, [Validators.required]],
        informacaoANM: [null, [Validators.required]],
        relacaoMaquinaEquipamento: [null, [Validators.required]],
        combustiveisUtilizados: [null, [Validators.required]],
        insumosUtilizados: [null, [Validators.required]],
        quadrosColaboradores: [null, [Validators.required]],
        impactosAmbientais: [null, [Validators.required]],
        medidasControle: [null, [Validators.required]],
        tiposGeracaoPropria: [null, [Validators.required]]
    })


    tooltips = {
        1: '(marque esta opção para licenciar atividade que envolve a lavra/extração e o beneficiamento mineral.)',
        2: '(marque esta opção para licenciar atividade que envolve apenas o beneficiamento.)',
        3: '(marque esta opção para licenciar atividade que envolve apenas a lavra/extração mineral.)',
        4: '(marque esta opção para licenciar atividade que envolve apenas a pesquisa mineral, sem lavra e sem beneficiamento.)',
        5: '(marque esta opção para licenciar atividade que envolve apenas a pesquisa mineral, com lavra.)',
        6: '(selecione este item quando se tratar apenas de beneficiamento, por exemplo, de argila ou outro mineral com a aquisição do bem mineral de uma empresa já licenciada na SEMA.)',
        7: '(selecione este item quando houver pedido de LOP para utilização do bem mineral em obras públicas.)',
        8: '(selecione aqui quando houver apenas o processamento de rejeito minerário)',
    }

    constructor(
        private fb: FormBuilder,
        private snackBarService: SnackBarService,
        private dominiosService: DominiosService,
        private atividadeMineracaoService: AtividadeMineracaoService,
        private substanciaMineralService: SubstanciaMineralService,
        private informacaoAnmService: InformacaoAnmService,
        private informacaoLoService: InformacaoLoService,
        private previsaoVolumeProducaoService: PrevisaoVolumesProducaoService,
        private relacaoMaquinaEquipamentoService: RelacaoMaquinasEquipamentosService,
        private combustivelUtilizadoService: CombustivelUtilizadoService,
        private insumoUtilizadoService: InsumoUtilizadoService,
        private quadroColaboradoresService: QuadroColaboradoresService,
        private impactoAmbientalService: ImpactoAmbientalService,
        private medidaControleService: MedidaControleService,
        private geracaoPropriaService: GeracaoPropriaService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
    }

    async ngOnInit() {
        this.form.get('idTipoObjetoLicenciamento').valueChanges.subscribe(id => {
            if ([1, 2, 6, 8].indexOf(id) >= 0 && !this.form.get('previsaoVolumeProducao')) {
                this.form.addControl('previsaoVolumeProducao', this.fb.control(null, Validators.required));
            } else if ([1, 2, 6, 8].indexOf(id) < 0 && this.form.get('previsaoVolumeProducao')) {
                this.form.removeControl('previsaoVolumeProducao');
            }

            if ([6, 8].indexOf(id) >= 0) {
                this.form.get('informacaoANM').clearValidators();
                this.form.get('informacaoANM').updateValueAndValidity();
            } else if ([1, 2, 3, 4, 5, 7, 9].indexOf(id) >= 0) {
                this.form.get('informacaoANM').setValidators([Validators.required]);
                this.form.get('informacaoANM').updateValueAndValidity();
            }

            if ([6, 8].indexOf(id) >= 0 && !this.form.get('informacaoLO')) {
                this.form.addControl('informacaoLO', this.fb.control(null, Validators.required));
            } else if ([6, 8].indexOf(id) < 0 && this.form.get('informacaoLO')) {
                this.form.removeControl('informacaoLO');
            }
        })

        this.dominiosService.getDominioByUrl('tipo-objeto-licenciamento')
            .subscribe(objetos => this.objetosLicenciamento = objetos);

        if (this.requerimento && this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarMineracao(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarMineracao(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
                this.isNaoPodeEditar = true;
                this.form.disable();
        }
    }

    carregarMineracao(idRequerimento){
        this.atividadeMineracaoService.getByRequerimento(idRequerimento)
            .subscribe(mineracao => {
                this.form.patchValue({
                    id: mineracao.id,
                    idRequerimento: mineracao.idRequerimento,
                    idTipoObjetoLicenciamento: mineracao.idTipoObjetoLicenciamento,
                    descricao: mineracao.descricao,
                    aquisicaoFornecedora: mineracao.aquisicaoFornecedora,
                    geracaoPropria: mineracao.geracaoPropria,
                    consumoAquisicaoFornecedora: mineracao.consumoAquisicaoFornecedora,
                    substanciasMineral: null,
                    informacaoANM: null,
                    relacaoMaquinaEquipamento: null,
                    combustiveisUtilizados: null,
                    insumosUtilizados: null,
                    quadrosColaboradores: null,
                    impactosAmbientais: null,
                    medidasControle: null,
                    tiposGeracaoPropria: null
                });

                if (!!this.form.get('informacaoLO')) {
                    this.informacaoLoService.getByMineracao(mineracao.id)
                        .subscribe(registros => this.form.get('informacaoLO').setValue(registros));
                }

                if (!!this.form.get('previsaoVolumeProducao')) {
                    this.previsaoVolumeProducaoService.getByMineracao(mineracao.id)
                        .subscribe(registros => this.form.get('previsaoVolumeProducao').setValue(registros));
                }

                this.substanciaMineralService.getByMineracao(mineracao.id)
                    .subscribe(registros => this.form.get('substanciasMineral').setValue(registros));
                this.informacaoAnmService.getByMineracao(mineracao.id)
                    .subscribe(registros => this.form.get('informacaoANM').setValue(registros));
                this.relacaoMaquinaEquipamentoService.getByMineracao(mineracao.id)
                    .subscribe(registros => this.form.get('relacaoMaquinaEquipamento').setValue(registros));
                this.combustivelUtilizadoService.getByMineracao(mineracao.id)
                    .subscribe(registros => this.form.get('combustiveisUtilizados').setValue(registros));
                this.insumoUtilizadoService.getByMineracao(mineracao.id)
                    .subscribe(registros => this.form.get('insumosUtilizados').setValue(registros));
                this.quadroColaboradoresService.getByMineracao(mineracao.id)
                    .subscribe(registros => this.form.get('quadrosColaboradores').setValue(registros));
                this.impactoAmbientalService.getByMineracao(mineracao.id)
                    .subscribe(registros => this.form.get('impactosAmbientais').setValue(registros));
                this.medidaControleService.getByMineracao(mineracao.id)
                    .subscribe(registros => this.form.get('medidasControle').setValue(registros));
                if (this.form.get('geracaoPropria').value === true){
                    this.geracaoPropriaService.getByMineracao(mineracao.id)
                        .subscribe(registros => this.form.get('tiposGeracaoProria').setValue(registros));
                }
            }, err => {
                this.form.patchValue({
                    id: null,
                    idRequerimento: this.requerimento.id,
                    idTipoObjetoLicenciamento: null,
                    descricao: null,
                    aquisicaoFornecedora: null,
                    geracaoPropria: null,
                    consumoAquisicaoFornecedora: null,
                    substanciasMineral: null,
                    informacaoANM: null,
                    relacaoMaquinaEquipamento: null,
                    combustiveisUtilizados: null,
                    insumosUtilizados: null,
                    quadrosColaboradores: null,
                    impactosAmbientais: null,
                    medidasControle: null,
                    tiposGeracaoPropria: null
                });
            });
    }

    salva() {
        this.verificarMatrizEnergetica();

        if(this.form.get('informacaoLO')){
            let valid = true;
            this.form.get('informacaoLO').value.forEach(info => {
                let match = this.form.get('substanciasMineral').value.find(subMineral => subMineral.idSubstacaoMineracao == info.idSubstancia)
                if(!match)
                    valid = false;
            })
            if(!valid){
                this.snackBarService.showError('Uma ou mais substância informada em "LO de terceiro" não ' +
                    'está incluída no agrupamento "Substância Mineral"');
                return;
            }
        }

        if (!this.form.valid) {
            this.snackBarService.show('O formulário de Atividades: possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).', SnackBarType.ERROR);
            return;
        }

        const mineracao = AtividadeMineracao.fromJson({
            id: this.form.get('id').value,
            idRequerimento: this.form.get('idRequerimento').value,
            idTipoObjetoLicenciamento: this.form.get('idTipoObjetoLicenciamento').value,
            descricao: this.form.get('descricao').value,
            aquisicaoFornecedora: this.form.get('aquisicaoFornecedora').value,
            geracaoPropria: this.form.get('geracaoPropria').value,
            consumoAquisicaoFornecedora: this.form.get('consumoAquisicaoFornecedora').value,
        });

        if (mineracao.id) {
            this.atividadeMineracaoService.update(mineracao).subscribe(mineracao => {
                this.enviarFormConcluido.emit();
                this.salvaTodosSubRegistros(mineracao);
            });
        } else {
            this.atividadeMineracaoService.create(mineracao).subscribe(mineracao => {
                this.enviarFormConcluido.emit();
                this.form.get('id').setValue(mineracao.id);
                this.salvaTodosSubRegistros(mineracao);
            });
        }
    }

    private salvaTodosSubRegistros(mineracao: AtividadeMineracao) {
        if (!!this.form.get('informacaoLO')) {
            this.salvaSubRegistros(mineracao, 'informacaoLO', this.informacaoLoService, InformacaoLo.fromJson);
        }

        if (!!this.form.get('previsaoVolumeProducao')) {
            this.salvaSubRegistros(mineracao, 'previsaoVolumeProducao', this.previsaoVolumeProducaoService, PrevisaoVolumesProducao.fromJson);
        }

        this.salvaSubRegistros(mineracao, 'substanciasMineral', this.substanciaMineralService, SubstanciaMineral.fromJson);
        this.salvaSubRegistros(mineracao, 'informacaoANM', this.informacaoAnmService, InformacaoAnm.fromJson);
        this.salvaSubRegistros(mineracao, 'relacaoMaquinaEquipamento', this.relacaoMaquinaEquipamentoService, RelacaoMaquinasEquipamentos.fromJson);
        this.salvaSubRegistros(mineracao, 'combustiveisUtilizados', this.combustivelUtilizadoService, CombustivelUtilizado.fromJson);
        this.salvaSubRegistros(mineracao, 'insumosUtilizados', this.insumoUtilizadoService, InsumoUtilizado.fromJson);
        this.salvaSubRegistros(mineracao, 'quadrosColaboradores', this.quadroColaboradoresService, QuadroColaboradores.fromJson);
        this.salvaSubRegistros(mineracao, 'impactosAmbientais', this.impactoAmbientalService, ImpactoAmbiental.fromJson);
        this.salvaSubRegistros(mineracao, 'medidasControle', this.medidaControleService, MedidaControle.fromJson);
        this.salvaSubRegistros(mineracao, 'tiposGeracaoPropria', this.geracaoPropriaService, GeracaoPropria.fromJson);
    }

    private async salvaSubRegistros(mineracao: AtividadeMineracao, formField: string, service: any, fromJsonFunction) {
        const registros = this.form.get(formField).value;

        const existentes = await service.getByMineracao(this.form.get('id').value).toPromise();

        for (const registro of existentes) {
            if (!registros.find(c => registro.id === c.id)) {
                await service.delete(registro.id).toPromise();
            }
        }

        for (let registro of registros) {
            registro = fromJsonFunction({
                ...registro,
                mineracao: mineracao,
            });

            if (registro.id) {
                await service.update(registro).toPromise();
            } else {
                await service.create(registro).toPromise();
            }
        }
    }

    showDescricao() {
        return this.form.get('idTipoObjetoLicenciamento').value && this.form.get('idTipoObjetoLicenciamento').value === this.TIPO_OUTRO;
    }

    verificarMatrizEnergetica(): void{
        if (this.form.get('geracaoPropria').value === false){
            this.form.get('tiposGeracaoPropria').disable();
        }
    }
}
