import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { Camada, ExtraOptions, GeometriaMapa, MapaPadraoComponent } from '@sema-geo/sema-geoportal';
import { Estilo } from "@sema-geo/sema-geoportal/lib/mapa-padrao/models/estilo";
import { removeDuplicatesByNestedKey } from 'app/main/shared/util/array';
import { Feature } from 'ol';
import { WKT } from 'ol/format';
import { Style } from 'ol/style';
import { map, take, takeUntil } from 'rxjs/operators';
import {
    DialogConfirmaAnaliseFeicaoBatchComponent
} from "../../../../../../shared/components/dialog-confirma-analise-feicao-batch/dialog-confirma-analise-feicao-batch.component";
import {
    DialogConfirmaExclusaoFeicaoComponent
} from "../../../../../../shared/components/dialog-confirma-exclusao-feicao/dialog-confirma-exclusao-feicao.component";
import {
    DialogEdicaoFeicaoAqcComponent
} from "../../../../../../shared/components/dialogs-modelos-feicao/dialog-edicao-feicao-aqc/dialog-edicao-feicao-aqc.component";
import { DialogEdicaoFeicaoDesmateComponent } from "../../../../../../shared/components/dialogs-modelos-feicao/dialog-edicao-feicao-desmate/dialog-edicao-feicao-desmate.component";
import { DialogEdicaoFeicaoEventualComponent } from "../../../../../../shared/components/dialogs-modelos-feicao/dialog-edicao-feicao-eventual/dialog-edicao-feicao-eventual.component";
import { DialogEdicaoFeicaoSeletivaComponent } from "../../../../../../shared/components/dialogs-modelos-feicao/dialog-edicao-feicao-seletiva/dialog-edicao-feicao-seletiva.component";
import {
    DialogEdicaoFeicaoTalhaoComponent
} from "../../../../../../shared/components/dialogs-modelos-feicao/dialog-edicao-feicao-talhao/dialog-edicao-feicao-talhao.component";
import { DialogEdicaoFeicaoUpaComponent } from "../../../../../../shared/components/dialogs-modelos-feicao/dialog-edicao-feicao-upa/dialog-edicao-feicao-upa.component";
import {
    RelatorioRegraDialogComponent
} from "../../../../../../shared/components/geoadmin/relatorio-regra/relatorio-regra-dialog/relatorio-regra-dialog.component";
import {
    ValidacaoFeicaoEntradaDialogComponent
} from "../../../../../../shared/components/geoadmin/validacao-feicao-entrada-dialog/validacao-feicao-entrada-dialog.component";
import {
    MessageDialog
} from '../../../../../../shared/components/message-dialog/message-dialog.model';
import { CenarioRegraGeo } from "../../../../../../shared/enums/cenario-regra-geo.enum";
import { ModeloFeicao } from "../../../../../../shared/enums/modelo-feicao.enum";
import { OrigemFeicaoEntrada } from "../../../../../../shared/enums/origem-feicao-entrada.enum";
import { StatusGeometria } from "../../../../../../shared/enums/status-geometria.enum";
import { TipoRegraGeo } from "../../../../../../shared/enums/tipo-regra-geo.enum";
import { BaseModel } from '../../../../../../shared/models/base.model';
import { DocumentoProcesso } from "../../../../../../shared/models/documento-processo.model";
import { DominioDto } from "../../../../../../shared/models/dominio-dto.model";
import {
    FeicaoRequerimentoLicenciamentoDto
} from '../../../../../../shared/models/feicao-requerimento-licenciamento-dto.model';
import {
    AnaliseTopologica, FeicaoComparacao,
    RequisicaoAnalisesTopologicas
} from "../../../../../../shared/models/geoadmin/analise-topologica";
import { CamadaRegraGeo } from "../../../../../../shared/models/geoadmin/camada-regra-geo.model";
import { CenarioRegrasGeo } from "../../../../../../shared/models/geoadmin/cenario-regras-geo";
import { CamadaFeicao, CamadaObjetivo } from "../../../../../../shared/models/geoadmin/feicao-vinculada.interface";
import { Feicao } from "../../../../../../shared/models/geoadmin/feicao.model";
import { RegraGeo } from "../../../../../../shared/models/geoadmin/regra-geo.model";
import { ValidacaoGeometria } from "../../../../../../shared/models/geoadmin/validacao-geometria.model";
import { Ponto } from '../../../../../../shared/models/hidrico-models/ponto.model';
import { ObjetivoLicenciamento } from "../../../../../../shared/models/objetivo-licenciamento.model";
import {
    RegrasGeoRequerimentoFeicao
} from "../../../../../../shared/models/regras-geo-requerimento/regra-geo-requerimento-feicao.model";
import {
    RegrasGeoRequerimentoRegra
} from "../../../../../../shared/models/regras-geo-requerimento/regra-geo-requerimento-regra.model";
import {
    RegrasGeoRequerimento
} from "../../../../../../shared/models/regras-geo-requerimento/regras-geo-requerimento.model";
import { RequerimentoTituloVinculado } from "../../../../../../shared/models/requerimento-titulo-vinculado.model";
import { DominiosService } from "../../../../../../shared/services/dominios.service";
import { FeicaoService } from "../../../../../../shared/services/geoadmin/feicao.service";
import { RegraGeoService } from "../../../../../../shared/services/geoadmin/regra-geo.service";
import { ValidacaoGeometriaService } from "../../../../../../shared/services/geoadmin/validacao-geometria.service";
import {
    RegrasGeoRequerimentoService
} from "../../../../../../shared/services/licenciamento-config-service/regras-geo-requerimento.service";
import { ObjetivoLicenciamentoService } from "../../../../../../shared/services/objetivo-licenciamento.service";
import { SnackBarService, SnackBarType } from '../../../../../../shared/snack-bar/snack-bar.service';
import {
    agruparFeicoesAnalisadasPorObjetivos, cleanCamadas, geometriaUuid, getCamada,
    getCamadaByFeicaoEntrada, getCamadaByGeometria, getCamadasByFeicaoEntrada,
    getEstiloFromFeicao, getFeaturesFromFile, getFeicoesEntrada, getStatusGeometriaClass,
    getStyle, ordenarFeicoes, removerCamada, temFeicaoDeEnvioObrigatorio
} from "../../../../../../shared/util/geo";
import { DocumentoProcessoService } from "../../../../../gerar-documento-publicacao/documento-processo.service";
import {
    DialogVisualizarIncoformidadesGeometriaComponent
} from "../../../../../tarefas-bpms/lic-ambiental/requerimento-analise/dialog-visualizar-incoformidades-geometria/dialog-visualizar-incoformidades-geometria.component";
import {
    DialogEditarFeicaoComponent
} from '../../../../../tarefas/requerimento-analise/dialog-editar-feicao/dialog-editar-feicao.component';
import { EmissaoTituloService } from "../../../../../tarefas/shared/service/emissao-titulo.service";
import { ReqTituloVinculadoService } from "../../../../../tarefas/shared/service/req-titulo-vinculado.service";
import {
    ConfirmarExclusaoPontoDialogComponent
} from '../../../../requerimento-outorga/requerimento-geo-outorga/confirmar-exclusao-ponto-dialog/confirmar-exclusao-ponto-dialog.component';
import { RequerimentoService } from '../../../../requerimento.service';
import { BaseTabRequerimentoComponent } from '../../../../shared/base-requerimento.component';
import { Imovel } from "../../../../shared/requerimento-localizacao/shared/imovel.model";
import { ImovelService } from '../../../../shared/requerimento-localizacao/shared/imovel.service';
import {
    ErrosAbaRequerimento
} from '../../../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { RequerimentoSteps } from '../../../../shared/requerimento.steps';
import { RequerimentoLicenciamento } from "../../../requerimento-licenciamento.model";
import { RequerimentoLicenciamentoService } from '../../../requerimento-licenciamento.service';
import {FeicaoTabelaAtributosService} from "../../../../../../shared/services/geo/feicao-tabela-atributos.service";


@Component({
    selector: 'app-requerimento-geo-licenciamento-florestal',
    templateUrl: 'aba-geo.component.html',
    animations: fuseAnimations
})
export class RequerimentoGeoLicenciamentoFlorestalComponent extends BaseTabRequerimentoComponent implements OnInit {
    selectedIndex: number = 0;
    selectedIndexRelatorio: number = 0;

    referenciasTemporarias: Camada = {
        title: 'Referências temporárias',
        name: 'temporario',
        expandGroup: true,
        permissao: {
            remover: true,
            ponto: true,
            poligono: true,
            linha: true,
            buffer: true,
            upload: true,
            exportar: true
        },
        camadas: []
    };

    localizacao: Camada = {
        title: 'Localização',
        name: 'localizacao',
        geometryGroup: true,
        expandGroup: true,
        camadas: []
    };

    feicoesRequerimento: Camada = {
        title: 'Feições do Requerimento',
        name: 'requerimento',
        geometryGroup: true,
        expandGroup: true,
        camadas: []
    };

    feicoesAnalisadas: Camada = {
        title: 'Feições Analisadas',
        name: 'feicoes-analisadas',
        geometryGroup: true,
        expandGroup: true,
        camadas: [],
    };

    camadaTrabalho: Camada = {
        title: 'Camada de trabalho',
        name: 'trabalho',
        geometryGroup: true,
        expandGroup: true,
        camadas: [this.referenciasTemporarias, this.localizacao, this.feicoesRequerimento]
    };

    camadas: Camada[] = [this.camadaTrabalho];
    camadaSelecionada: Camada;

    extraOptions: ExtraOptions[];

    @ViewChild('mapaPadraoComponent', {static: false})
    componenteMapa: MapaPadraoComponent;

    analisesTopologicasDasGeometriasRequerimento: {
        geometria: FeicaoRequerimentoLicenciamentoDto,
        aplicacoes: CenarioRegrasGeo[]
    }[] = [];
    imoveisDoRequerimento: Imovel[] = [];
    geometrias: FeicaoRequerimentoLicenciamentoDto[] = [];
    regrasFeicoes = new Map<number, RegrasGeoRequerimentoFeicao[]>();
    objetivos: ObjetivoLicenciamento[];
    feicoesAplicacoesBatch: any[] = [];
    cenariosRegrasGeo: Array<DominioDto> = [];

    @ViewChild(CdkOverlayOrigin, {static: false}) _overlayOrigin: CdkOverlayOrigin;

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;
    inventarioImportado: DocumentoProcesso;
    requerimentoLicenciamento: RequerimentoLicenciamento;
    objetivoLicenciamento: ObjetivoLicenciamento[] = [];
    mostrarInventario: boolean = false;

    protocoloVinculadoComLp: boolean;
    protocoloVinculadoComLi: boolean;
    protocoloVinculadoComLo: boolean;

    constructor(
        protected imovelService: ImovelService,
        protected formBuilder: FormBuilder,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private reqTituloVinculadoService: ReqTituloVinculadoService,
        private requerimentoService: RequerimentoService,
        private messageDialog: MessageDialog,
        private documentoProcessoService: DocumentoProcessoService,
        private readonly feicaoService: FeicaoService,
        private readonly regraGeoService: RegraGeoService,
        private readonly regrasGeoRequerimentoService: RegrasGeoRequerimentoService,
        private readonly objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private readonly validacaoGeometriaService: ValidacaoGeometriaService,
        private readonly emissaoTituloService: EmissaoTituloService,
        private readonly dominiosService: DominiosService,
        private readonly feicaoTabelaAtributosService: FeicaoTabelaAtributosService,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.GEO.component = this;
    }

    ngOnInit(): void {
        this.helper.onCurrentStepChange().pipe(takeUntil(this._unsubscribeAll)).subscribe(async e => {
            if (e.newTabIndex === RequerimentoSteps.GEO.index) {
                await this.carregarReqTituloVinculado('LP_TRAMITACAO');
                await this.carregarReqTituloVinculado('LO_VINCULADA');
                await this.carregarReqTituloVinculado('TITULO_EXISTENTE');

                await this.listarGeometrias();

                this.getFeicoesAnalisadas();
                this.getCenariosDeRegrasGeo();
                // await this.buscarObjetivoSalvo();
            }
        });
    }

    private findCamada(idFeicao: number, idObjetivo: number): CamadaRegraGeo {
        const camadasObjetivo: Camada = this.feicoesRequerimento.camadas.find(c => c.name === `objetivo-${idObjetivo}`);
        if(camadasObjetivo){
            return camadasObjetivo.camadas.find((c: CamadaRegraGeo) => c.name === `${idFeicao}-${idObjetivo}`);
        } else {
            return null
        }
    }
    private isAdicionarFeicao(): boolean {
        return (this.protocoloVinculadoComLp || (this.protocoloVinculadoComLp && this.protocoloVinculadoComLi))
            || (this.protocoloVinculadoComLi || (this.protocoloVinculadoComLi && this.protocoloVinculadoComLo));
    }

    private isEditarFeicaoPrincipal(principal: boolean): boolean {
        if (principal) {
            return this.protocoloVinculadoComLp || (this.protocoloVinculadoComLp && this.protocoloVinculadoComLi);
        }

        return false;
    }

    private isNotRemoverFeicaoPrincipal(principal: boolean): boolean {
        if (principal) {
            return (this.protocoloVinculadoComLp || (this.protocoloVinculadoComLp && this.protocoloVinculadoComLi))
                || (this.protocoloVinculadoComLi || (this.protocoloVinculadoComLi && this.protocoloVinculadoComLo));
        }

        return true;
    }

    setRegrasToFeicaoEntrada(regraGeoConfig: RegrasGeoRequerimentoFeicao, camada: Camada){
        const camadasFeicaoEntrada = getCamadaByFeicaoEntrada(camada, regraGeoConfig.idFeicaoEntrada);
        camadasFeicaoEntrada.forEach((cam: CamadaRegraGeo) => {
            regraGeoConfig.regras.forEach((regra) => {
                if (!cam.regraGeoRequerimentoFeicao.regras.some(regraExistente => regraExistente.id === regra.id)) {
                    cam.regraGeoRequerimentoFeicao.regras.push(regra);
                }
            });
        })
    }

    async listarGeometrias(): Promise<void> {
        const feicoesDuplicadas: RegrasGeoRequerimentoFeicao[] = [];
        this.feicoesRequerimento.camadas = [];

        this.objetivos = await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();

        let idsFeicoes = [];
        for (let objetivo of this.objetivos) {

            let regrasGeoRequerimento: RegrasGeoRequerimento[] = await this.regrasGeoRequerimentoService.getByTipoProcessoAtividadeObjetivo(
                this.requerimentoLicenciamento.requerimento.tipoProcesso.id,
                this.requerimentoLicenciamento.idAtividade,
                objetivo.idObjetivoLicenciamentoAmbiental
            ).toPromise();

            let feicoesConfiguracao: RegrasGeoRequerimentoFeicao[] = [];

            for (let f of regrasGeoRequerimento.map(r => r.feicoes)) {
                feicoesConfiguracao = feicoesConfiguracao.concat(f);
            }

            this.regrasFeicoes.set(objetivo.idObjetivoLicenciamentoAmbiental, feicoesConfiguracao);

            const camadaObjetivos: Camada = {
                title: objetivo.descricaoObjetivo,
                name: `objetivo-${objetivo.idObjetivoLicenciamentoAmbiental}`,
                geometryGroup: true,
                expandGroup: true,
                camadas: [],
            };

            for (let feicaoConfiguracao of feicoesConfiguracao) {
                // Evita a inclusão duplicada de feições em uma única camada.
                if (idsFeicoes.includes(feicaoConfiguracao.idFeicaoEntrada)) {
                    feicoesDuplicadas.push(feicaoConfiguracao)
                    continue;
                }

                idsFeicoes.push(feicaoConfiguracao.idFeicaoEntrada);

                let feicaoEntrada: Feicao = await this.feicaoService.getById(feicaoConfiguracao.idFeicaoEntrada).toPromise();

                feicaoEntrada.objetivoLicenciamento = objetivo.idObjetivoLicenciamentoAmbiental;

                const estilo: Estilo = getEstiloFromFeicao(feicaoEntrada);

                const envioObrigatorio = temFeicaoDeEnvioObrigatorio(feicaoConfiguracao.idFeicaoEntrada, feicoesConfiguracao) ? ' * ' : '';

                //Valida se a edição/exclusão das feições serão bloqueadas conforme o tipo de fluxo do requerimento
                let isBloqueiaEdicaoEExclusaoGeometrias = 
                    this.requerimento.tipoRequerimento.isFluxoRenovar 
                    || this.requerimento.tipoRequerimento.isFluxoCancelar
                    || this.requerimento.tipoRequerimento.isFluxoReabilitar
                    || this.requerimento.tipoRequerimento.isFluxoTransferir;

                let camada: CamadaRegraGeo = {
                    title: `${envioObrigatorio} ${feicaoEntrada.descricao}`,
                    name: `${feicaoConfiguracao.idFeicaoEntrada}-${objetivo.idObjetivoLicenciamentoAmbiental}`,
                    camadas: [],
                    geometryGroup: isBloqueiaEdicaoEExclusaoGeometrias,
                    permissao: {
                        ponto: !!feicaoEntrada.geometriaPonto,
                        linha: !!feicaoEntrada.geometriaLinha,
                        poligono: !!feicaoEntrada.geometriaPoligono,
                        editar: this.isEditarFeicaoPrincipal(feicaoConfiguracao.principal) && this.isAdicionarFeicao(),
                        remover: !this.isSomenteVisualizacao && !this.isNotRemoverFeicaoPrincipal(feicaoConfiguracao.principal),
                        upload: true
                    },
                    style: estilo ? getStyle(estilo) : this.componenteMapa.defaultStyle
                };
                camada.regraGeoRequerimentoFeicao = feicaoConfiguracao;
                camada.feicao = feicaoEntrada;

                camada.extraOptions = [
                    {
                        icon: 'arrow_downward',
                        text: 'Download do SHP',
                        hint: 'Download do SHP da Feição',
                        callback: () => this.downloadSHP(feicaoEntrada)
                    },
                    {
                        icon: 'arrow_downward',
                        text: 'Download do KML',
                        hint: 'Download do KML da Feição',
                        callback: () => this.downloadKML(feicaoEntrada)
                    }
                ];

                camadaObjetivos.camadas.push(camada)
            }

            if (feicoesConfiguracao.length > 0) {
                feicoesDuplicadas.forEach(feicao => {
                    this.setRegrasToFeicaoEntrada(feicao, camadaObjetivos);
                })
                setTimeout(() => this.feicoesRequerimento.camadas.push(camadaObjetivos), 100);
            } else {
                this.snackBarService.showAlert(`Não foram encontradas Regras GEO configuradas para o objetivo: ${objetivo.descricaoObjetivo}.`);
            }
        }

        this.geometrias = await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();
        this.imoveisDoRequerimento = await this.imovelService.buscarTodosPorRequerimentoComGeometrias(this.requerimento.id).toPromise();
        this.atualizarMapa();
    }

    downloadSHP(feicao: Feicao){
        this.feicaoTabelaAtributosService.downloadSHP(this.requerimento.id, feicao.id).subscribe(blob => {
            this.feicaoTabelaAtributosService.showFile(blob, `${feicao.descricao.toUpperCase()}_SHP.zip`);
        }, error => {
            this.snackBarService.showError(`Erro ao baixar o SHP: ${error}.`);

        });
    }
    downloadKML(feicao: Feicao){
        this.feicaoTabelaAtributosService.downloadKML(this.requerimento.id, feicao.id).subscribe(blob => {
            this.feicaoTabelaAtributosService.showFile(blob, `${feicao.descricao.toUpperCase()}_KML.zip`);
        }, error => {
            this.snackBarService.showError(`Erro ao baixar o KML: ${error}.`);
        });
    }

    // async listarGeometrias(): Promise<void> {
    //     this.feicoesRequerimento.camadas = [];
    //     let objetivos = await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();
    //     for (let objetivo of objetivos) {
    //         let regrasGeoRequerimento: RegrasGeoRequerimento[] = await this.regrasGeoRequerimentoService.getByTipoProcessoAtividadeObjetivo(
    //             this.requerimentoLicenciamento.requerimento.tipoProcesso.id,
    //             this.requerimentoLicenciamento.idAtividade,
    //             objetivo.idObjetivoLicenciamentoAmbiental
    //         ).toPromise();
    //
    //         let feicoes: RegrasGeoRequerimentoFeicao[] = [];
    //         for (let f of regrasGeoRequerimento.map(r => r.feicoes)) {
    //             feicoes = feicoes.concat(f);
    //         }
    //
    //         this.regrasFeicoes.set(objetivo.idObjetivoLicenciamentoAmbiental, feicoes);
    //
    //         for (let feicao of feicoes) {
    //
    //             const nomeCamada = `${objetivo.idObjetivoLicenciamentoAmbiental}/${feicao.idFeicaoEntrada}`;
    //             const camadaExistente = this.findCamada(feicao.idFeicaoEntrada);
    //
    //             if (camadaExistente) {
    //                 camadaExistente.name += '@' + nomeCamada;
    //                 continue;
    //             }
    //
    //             const feicaoBack: Feicao = await this.feicaoService.getById(feicao.idFeicaoEntrada).toPromise();
    //             let style: Style = null;
    //             let estilo: {
    //                 corPreenchimento: string;
    //                 espessuraBorda: number;
    //                 cor: string;
    //                 tipoTracejado: number;
    //             } = null;
    //
    //             if (feicaoBack.geometriaPonto) {
    //                 estilo = {
    //                     corPreenchimento: feicaoBack.geometriaPonto.corPreenchimento,
    //                     espessuraBorda: feicaoBack.geometriaPonto.espessuraBorda,
    //                     cor: feicaoBack.geometriaPonto.corBorda,
    //                     tipoTracejado: feicaoBack.geometriaPonto.tipoTracejado
    //                 };
    //             } else if (feicaoBack.geometriaPoligono) {
    //                 estilo = {
    //                     corPreenchimento: feicaoBack.geometriaPoligono.corPreenchimento,
    //                     espessuraBorda: feicaoBack.geometriaPoligono.espessuraBorda,
    //                     cor: feicaoBack.geometriaPoligono.corBorda,
    //                     tipoTracejado: feicaoBack.geometriaPoligono.tipoTracejado
    //                 };
    //             } else if (feicaoBack.geometriaLinha) {
    //                 estilo = {
    //                     corPreenchimento: 'white',
    //                     espessuraBorda: feicaoBack.geometriaLinha.espessuraBorda,
    //                     cor: feicaoBack.geometriaLinha.corLinha,
    //                     tipoTracejado: feicaoBack.geometriaLinha.tipoTracejado
    //                 };
    //             }
    //
    //             if (estilo) {
    //                 let lineDash: number[] = null;
    //                 if (estilo.tipoTracejado == 1) {
    //                     lineDash = [10, 20]
    //                 } else if (estilo.tipoTracejado == 2) {
    //                     lineDash = [5, 10];
    //                 }
    //
    //                 let stroke = new Stroke({
    //                     color: estilo.cor,
    //                     width: estilo.espessuraBorda || 1,
    //                     lineCap: 'round',
    //                     lineDash: lineDash
    //                 });
    //
    //                 style = new Style({
    //                     fill: new Fill({
    //                         color: estilo.corPreenchimento
    //                     }),
    //                     stroke: stroke
    //                 });
    //             }
    //
    //             let camada: CamadaRegraGeo = {
    //                 title: feicaoBack.descricao,
    //                 name: nomeCamada,
    //                 camadas: [],
    //                 permissao: {
    //                     ponto: !!feicaoBack.geometriaPonto,
    //                     linha: !!feicaoBack.geometriaLinha,
    //                     poligono: !!feicaoBack.geometriaPoligono,
    //                     editar: !this.isSomenteVisualizacao,
    //                     remover: !this.isSomenteVisualizacao,
    //                     upload: true
    //                 },
    //                 style
    //             };
    //             camada.feicao = feicaoBack;
    //             camada.regraGeoRequerimentoFeicao = feicao;
    //             this.feicoesRequerimento.camadas.push(camada);
    //         }
    //     }
    //     this.geometrias = await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();
    //     this.imoveisDoRequerimento = await this.imovelService.buscarTodosPorRequerimentoComGeometrias(this.requerimento.id).toPromise();
    //     this.atualizarMapa();
    // }

    getRegrasGeo(idFeicao: number, idObjetivos: number[] = null): RegrasGeoRequerimentoRegra[] {
        let regras: RegrasGeoRequerimentoRegra[] = [];
        idObjetivos.forEach(idObjetivo => {
            const regrasFeicoes = this.regrasFeicoes.get(idObjetivo).filter(regra => regra.idFeicaoEntrada === idFeicao);
            for (let feicao of regrasFeicoes) {
                feicao.regras.forEach(r => {
                    if (!regras.some(x => r.id == x.id)) {
                        regras.push(r)
                    }
                });
            }
        });

        return regras;
    }

    // atualizarMapa = (): void => {
    //     this.componenteMapa.getSource().clear();
    //     // Feições do imóvel.
    //     this.localizacao.camadas.forEach(feicao => this.componenteMapa.deleteFeature(feicao));
    //     this.localizacao.camadas = [];
    //     this.imoveisDoRequerimento.forEach((imovel) => {
    //         imovel.geometrias.forEach(geometria => {
    //             const geometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt);
    //             geometriaMapa.id = geometria.id;
    //             geometriaMapa.permissao = {
    //                 remover: true
    //             };
    //             geometriaMapa.extra = geometria;
    //
    //             if (imovel.tipo === 'RURAL') {
    //                 const imovelPrincipal = imovel.imovelPrincipal ? '*' : '';
    //                 geometriaMapa.title = `${imovel.numeroCAR} ${imovelPrincipal}`;
    //                 geometriaMapa.propriedades = {
    //                     id: geometria.id,
    //                     Nome: geometriaMapa.title
    //                 };
    //                 geometriaMapa.extra['imovel'] = imovel;
    //             } else if (imovel.tipo === 'URBANO') {
    //                 geometriaMapa.title = 'Área do imóvel urbano';
    //                 geometriaMapa.propriedades = {
    //                     id: geometria.id,
    //                     Nome: geometriaMapa.title
    //                 };
    //                 geometriaMapa.extra['imovel'] = imovel;
    //                 // Só permitir um único polígono para o imóvel urbano.
    //                 this.localizacao.permissao.poligono = false;
    //             } else if (imovel.tipo === 'OBRA') {
    //                 geometriaMapa.title = geometriaMapa.tipoGeometria;
    //                 geometriaMapa.propriedades = {
    //                     id: geometria.id
    //                 };
    //                 geometriaMapa.extra['imovel'] = imovel;
    //             }
    //             geometriaMapa.visualizacao = {
    //                 showCheckbox: true,
    //                 showFeature: true
    //             }
    //             geometriaMapa['expandGroup'] = true
    //             geometriaMapa.feature.setStyle(this.componenteMapa.defaultStyle)
    //             this.localizacao.camadas.push(geometriaMapa);
    //         });
    //     });
    //
    //     // Feições do requerimento.
    //     this.feicoesRequerimento.camadas.forEach(camada => camada.camadas.forEach(feicao => this.componenteMapa.deleteFeature(feicao)));
    //     this.feicoesRequerimento.camadas.forEach(camada => camada.camadas = []);
    //     for (let i = this.feicoesRequerimento.camadas.length - 1; i > 0; i--) {
    //         if (this.feicoesRequerimento.camadas[i] instanceof GeometriaMapa) {
    //             this.feicoesRequerimento.camadas = this.feicoesRequerimento.camadas.splice(i, 1);
    //         }
    //     }
    //     this.feicoesRequerimento.camadas.sort((a, b) => CommonsUtil.comparaStrings(a['title'], b['title']));
    //
    //     if (this.geometrias) {
    //         this.geometrias.forEach(geometria => {
    //             const camada = this.findCamada(geometria.idFeicaoEntrada);
    //             camada.expandGroup = true;
    //
    //             if (camada) {
    //                 const geometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt, camada.style);
    //                 geometriaMapa.permissao = {
    //                     remover: true,
    //                     editar: true
    //                 };
    //
    //                 geometriaMapa.extra = {
    //                     ...geometria
    //                 };
    //                 geometriaMapa.title = `[${geometria.id}] ${geometria.denominacao || 'Sem denominação'}`;
    //                 geometriaMapa.propriedades = {
    //                     ID: geometria.id,
    //                     'Denominação': geometria.denominacao
    //                 };
    //
    //                 if (!geometria.denominacao) {
    //                     geometriaMapa.warningMessage = 'Cadastro incompleto';
    //                 }
    //                 camada['expandGroup'] = true
    //                 camada.camadas.push(geometriaMapa);
    //             }
    //
    //         });
    //         this.feicoesRequerimento.camadas.forEach((camada) => {
    //             const node: CamadaFlatNode = new CamadaFlatNode();
    //             node.item = camada.title.toUpperCase()
    //             node.camada = camada;
    //             node.camada.title = camada.title.toUpperCase()
    //             this.componenteMapa.camadasSelecionadas.unshift(node);
    //             camada.camadas.sort((a, b) => CommonsUtil.comparaStrings(a['title'], b['title']));
    //         })
    //     }
    //
    //     this.camadas = [...this.camadas];
    //     this.componenteMapa.fit();
    // }
    inserirFeicoesDoRequerimento(): void {
        // Feições do requerimento.
        this.feicoesRequerimento.camadas.forEach((camada: Camada) => {
            camada.camadas.forEach((cam: Camada) => cleanCamadas(cam, this.componenteMapa))
        });

        if (this.geometrias) {
            for (let geometria of this.geometrias) {
                if (!geometria.objetivoLicenciamento) {
                    console.error("ABA 4 - Dados Geográficos: feição sem objetivo definido.")
                    continue;
                }
                const camada: CamadaRegraGeo = this.findCamada(geometria.idFeicaoEntrada, geometria.objetivoLicenciamento);
                if (camada) {
                    if (geometria.wkt) {
                        const geometriaMapa: GeometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt, camada.style);
                        let isBloqueiaEdicaoEExclusaoGeometrias = this.requerimento.tipoRequerimento.isFluxoRenovar 
                            || this.requerimento.tipoRequerimento.isFluxoCancelar
                            || this.requerimento.tipoRequerimento.isFluxoReabilitar
                            || this.requerimento.tipoRequerimento.isFluxoTransferir;
                        geometriaMapa.permissao = {
                            remover: !isBloqueiaEdicaoEExclusaoGeometrias,
                            editar: !isBloqueiaEdicaoEExclusaoGeometrias
                        };
                        if(this.requerimento.situacaoProcesso === "EM_CORRECAO" && geometria.statusGeometria === StatusGeometria.APROVADA){
                            geometriaMapa.permissao = {
                                remover: false,
                                editar: false
                            };
                            geometriaMapa.extraOptions.push( {
                                icon: 'pageview',
                                text: 'Visualizar dados',
                                hint: 'Visualizar dados',
                                callback: () => this.onShowDadosGeometria(geometriaMapa)
                            })
                        }
                        geometriaMapa.extraOptions = [];

                        geometriaMapa.extra = {
                            ...geometria,
                        };

                        geometriaMapa.title = `<strong class="req-geometria ${getStatusGeometriaClass(geometria.statusGeometria)}" >[${geometria.id}] ${geometria.denominacao || 'Sem denominação'}</strong>`;
                        geometriaMapa.name = geometriaUuid(geometria.id);
                        geometriaMapa.propriedades = {
                            ID: geometria.id,
                            'Denominação': geometria.denominacao
                        };

                        if (!geometria.denominacao) {
                            geometriaMapa.warningMessage = 'Cadastro incompleto';
                        }
                        geometriaMapa.visualizacao = {
                            showCheckbox: true,
                            showFeature: true
                        }
                        if(geometria.inconformidades && geometria.inconformidades.length > 0){
                            geometriaMapa.warningMessage = 'Geometria com Inconformidades';
                            geometriaMapa.extraOptions.push( {
                                icon: 'pageview',
                                text: 'Visualizar inconformidades',
                                hint: 'Visualizar inconformidades',
                                iconeColor: 'orange',
                                callback: () => this.visualizarInconformidades(geometriaMapa)
                            })
                        }
                        camada['expandGroup'] = true
                        geometriaMapa.style = camada.style
                        camada.camadas.push(geometriaMapa);
                    }
                }
            }
        }
        this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'requerimento');
        this.camadaTrabalho.camadas.push(this.feicoesRequerimento);
        ordenarFeicoes(this.feicoesRequerimento, this.componenteMapa);
    }
    visualizarInconformidades(geom: GeometriaMapa){
        this.dialog.open(DialogVisualizarIncoformidadesGeometriaComponent, {
            width: '60%',
            data: {
                geometria: geom
            }
        });
    }
    inserirFeicoesImoveisDoRequerimento(): void {
        cleanCamadas(this.localizacao, this.componenteMapa);
        if (this.imoveisDoRequerimento.length > 0) {
            this.imoveisDoRequerimento.forEach((imovel) => {
                imovel.geometrias.forEach((geometria) => {
                    const geometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt);
                    geometriaMapa.id = geometria.id;
                    geometriaMapa.name = geometriaUuid(geometria.id);

                    geometriaMapa.permissao = {
                        remover: false
                    };
                    geometriaMapa.extra = geometria;
                    if (imovel.tipo === 'RURAL') {
                        const imovelPrincipal = imovel.imovelPrincipal ? '*' : '';
                        geometriaMapa.title = `${imovel.numeroCAR} ${imovelPrincipal}`;
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            Nome: geometriaMapa.title
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                    } else if (imovel.tipo === 'URBANO') {
                        geometriaMapa.title = 'Área do imóvel urbano';
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            Nome: geometriaMapa.title,
                            imovel: imovel.denominacao
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                        // Só permitir um único polígono para o imóvel urbano.
                        this.localizacao.permissao.poligono = false;
                    } else if (imovel.tipo === 'OBRA') {
                        geometriaMapa.title = geometriaMapa.tipoGeometria;
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            imovel: imovel.denominacao
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                    }
                    geometriaMapa.visualizacao = {
                        showCheckbox: true,
                        showFeature: true
                    }
                    geometriaMapa['expandGroup'] = true
                    geometriaMapa.style = this.componenteMapa.defaultStyle
                    geometriaMapa.feature.setStyle(this.componenteMapa.defaultStyle)
                    this.localizacao.camadas.push(geometriaMapa);
                });
            });

            if (this.imoveisDoRequerimento.some(imovel => imovel.geometrias.some(geom => geom.wkt !== ''))) {
                this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'localizacao');
                this.camadaTrabalho.camadas.push(this.localizacao);
            }
        }
    }
    atualizarMapa = (): void => {
        this.componenteMapa.getSource().clear();
        this.inserirFeicoesImoveisDoRequerimento();
        this.inserirFeicoesDoRequerimento();

        this.camadas = [...this.camadas];

        this.getFeicoesAnalisadas();
        this.componenteMapa.fit();
    }

    importar = (file: File) => {
        getFeaturesFromFile(file, this.componenteMapa).then((features) => {
            this.componenteMapa.showProgressBar = false;
            if(Array.isArray(features) && features.length > 1){
                const [idFeicaoEntrada, objetivoLicenciamento] = this.camadaSelecionada.name.split('-');
                if (this.ultrapassouQuantidadeMaximaDeFeatures(Number(idFeicaoEntrada), Number(objetivoLicenciamento), features)) {
                    this.snackBarService.showError("O total de geometrias submetidas ultrapassa a quantidade máxima de feições permitidas.");
                    return;
                } else {
                    this.componenteMapa.importarFile(file);
                }
            } else {
                this.componenteMapa.importarFile(file);
            }
        }).catch(e => this.snackBarService.showError('Erro ao importar as geometrias: ', e));
    }

    removerGeometria(geometria: GeometriaMapa): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoPontoDialogComponent, {
            width: '450px',
            data: {
                ponto: {
                    nomePonto: geometria.extra.denominacao
                } as Ponto
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.requerimentoLicenciamentoService.deleteGeometria(this.requerimentoLicenciamento.id, geometria.extra.id).subscribe(async () => {
                    this.componenteMapa.delete(geometria);
                    this.snackBarService.showSuccess('Registro excluído com sucesso.');
                    await this.listarGeometrias();
                }, e => this.snackBarService.showError('Erro ao excluir a geometria.', e));
            }
        });
    }
    excluirGeometriaDialog(geometria: GeometriaMapa): void {
        this.getGeometriasDependentes(geometria).then((feicaoRequerimentoLicenciamentoDto: FeicaoRequerimentoLicenciamentoDto) => {
            if(feicaoRequerimentoLicenciamentoDto.hasOwnProperty('feicoesDependentes')){
                if (feicaoRequerimentoLicenciamentoDto.feicoesDependentes.length > 0){
                    feicaoRequerimentoLicenciamentoDto.feicoesDependentes.forEach(feicao => {
                        // const feicaoEntrada =   this.feicoesRequerimento.camadas.find((feicaoCamada) => 
                        //     feicaoCamada.camadas.some((cam: GeometriaMapa) => cam.extra.idGeometria == feicao.idGeometria));
                        const extraOpts = (geometria as any).extra || (geometria as any).extraOptions || {};
                        const camadaId = extraOpts.idGeometria || geometria.id;
                        if (camadaId) {
                            const feicaoEntrada = this.feicoesRequerimento.camadas.find((c) =>  getCamadaByGeometria(c, camadaId) != null);
                            if (feicaoEntrada) {
                                feicao['nomeFeicaoEntrada'] = feicaoEntrada.title
                            }
                        }
                        
                    })
                    const dialogRef = this.dialog.open(DialogConfirmaExclusaoFeicaoComponent, {
                        width: '700px',
                        data: {
                            feicao: geometria.extra as FeicaoRequerimentoLicenciamentoDto,
                            dependencias: feicaoRequerimentoLicenciamentoDto.feicoesDependentes
                        }
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (!!result) {
                            this.requerimentoLicenciamentoService.deleteGeometria(this.requerimentoLicenciamento.id, geometria.extra.id).subscribe(async () => {
                                this.snackBarService.showSuccess('Registros de geometrias excluídos com sucesso.');
                                await this.listarGeometrias();
                            }, e => this.snackBarService.showError('Erro ao excluir as geometrias.', e));
                        }
                    });
                } else {
                    this.removerGeometria(geometria)
                }
            }else{
                this.removerGeometria(geometria)
            }
        }).catch(e => this.snackBarService.showError('Erro ao recuperar as geometrias dependentes', e));
    }

    public criaGeometria(wkt: string, style: Style = null): GeometriaMapa {
        return this.componenteMapa.criarGeometria(wkt, style)
    }

    private ultrapassouQuantidadeMaximaDeFeicoes(idFeicaoEntrada: number, idObjetivo: number): boolean {
        const feicaoEntrada: CamadaRegraGeo = this.findCamada(idFeicaoEntrada, idObjetivo);
        if (feicaoEntrada) {
            return feicaoEntrada.camadas.length >= feicaoEntrada.regraGeoRequerimentoFeicao.quantidadeMaxima
        } else {
            return false
        }
    }
    private ultrapassouQuantidadeMaximaDeFeatures(idFeicaoEntrada: number, idObjetivo: number, features): boolean {
        const feicaoEntrada: CamadaRegraGeo = this.findCamada(idFeicaoEntrada, idObjetivo);
        const limiteMaximo: number = feicaoEntrada.regraGeoRequerimentoFeicao.quantidadeMaxima
        if (feicaoEntrada) {
            return features.length >= limiteMaximo || feicaoEntrada.camadas.length  >= features.length
        } else {
            return false
        }
    }
    private criaAnalisesTopologicas(regraGeoAdmin: RegraGeo, regrasSemGeometrias: string[]): AnaliseTopologica[] {
        const analisesTopologicas: AnaliseTopologica[] = [];
        regraGeoAdmin.regrasFeicao.forEach(regra => {
            const camadasRequerimento: CamadaRegraGeo[] = getCamadasByFeicaoEntrada(this.feicoesRequerimento, regra.idFeicaoEntradaComoComp);
            if (camadasRequerimento.length > 0) {
                const feicoesComparacao: FeicaoComparacao[] = [];
                camadasRequerimento.forEach((geometria: GeometriaMapa) => {
                    feicoesComparacao.push({
                        id: regra.idFeicaoEntradaComoComp,
                        wkt: geometria.wkt,
                        idGeometria: geometria.extra.idGeometria
                    })
                })
                if (feicoesComparacao.length > 0) {
                    analisesTopologicas.push({
                        id: regra.id,
                        feicoesComparacao: feicoesComparacao
                    })
                }
            } else {
                regrasSemGeometrias.push(regraGeoAdmin.nome)
            }
        })
        return analisesTopologicas;
    }

    // public async processaRequisicoesDeAnalisesTopologicas(regrasGeoConfig: RegrasGeoRequerimentoRegra[], feicao: FeicaoRequerimentoLicenciamentoDto): Promise<CenarioRegrasGeo[]> {
    //     let aplicacoesRegrasObs = [];
    //     const regrasSemGeometrias: string[] = [];
    //     const ocorreuErros: boolean[] = [];
    //     for (const [index, regraGeoConfig] of Object.entries(regrasGeoConfig)) {
    //         let requisicao: RequisicaoAnalisesTopologicas = {
    //             idFeicaoEntrada: feicao.idFeicaoEntrada,
    //             wktFeicaoEntrada: feicao.wkt
    //         }
    //         if (regraGeoConfig.cenario === CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO) {
    //             const regraGeoAdmin: RegraGeo = await this.regraGeoService.getRegraGeoById(regraGeoConfig.idRegraGeo).toPromise()
    //             const analisesTopologicas: AnaliseTopologica[] = [];
    //
    //             regraGeoAdmin.regrasFeicao.forEach(regra => {
    //                 const camadasRequerimento: CamadaRegraGeo[] = this.feicoesRequerimento.camadas.filter((camada: CamadaRegraGeo) => camada.feicao.id === regra.idFeicaoEntradaComoComp);
    //
    //                 if (camadasRequerimento.length > 0) {
    //                     const feicoesComparacao: FeicaoComparacao[] = [];
    //                     camadasRequerimento.forEach((camada) => {
    //                         if (camada.camadas.length > 0) {
    //                             camada.camadas.forEach((geometria: GeometriaMapa) => {
    //                                 feicoesComparacao.push({
    //                                     id: regra.idFeicaoEntradaComoComp,
    //                                     wkt: geometria.wkt,
    //                                     idGeometria: geometria.extra.idGeometria
    //                                 })
    //                             })
    //                         } else {
    //                             regrasSemGeometrias.push(regraGeoAdmin.nome)
    //                         }
    //                     })
    //                     if (feicoesComparacao.length > 0) {
    //                         analisesTopologicas.push({
    //                             id: regra.id,
    //                             feicoesComparacao: feicoesComparacao
    //                         })
    //                     }
    //                 } else {
    //                     regrasSemGeometrias.push(regraGeoAdmin.nome)
    //                 }
    //             })
    //
    //             requisicao.analisesTopologicas = analisesTopologicas;
    //
    //             if (requisicao.analisesTopologicas.length > 0) {
    //                 aplicacoesRegrasObs.push(this.regraGeoService.applyTerceiroCenario(regraGeoConfig.idRegraGeo, requisicao).pipe(map(response => ({
    //                     cenarioId: regraGeoConfig.cenario,
    //                     regra: response
    //                 }))).toPromise());
    //                 ocorreuErros.push(false)
    //             } else {
    //                 this.snackBarService.show('Não há feições cadadastras para validar o cenário de aplicação das regras GEO para garantia com feições do requerimento.<br>Regras: <br>' + regrasSemGeometrias.join('<br>'), SnackBarType.ERROR, 50000);
    //                 ocorreuErros.push(true)
    //             }
    //
    //         } else {
    //             aplicacoesRegrasObs.push(this.regraGeoService.apply(regraGeoConfig.idRegraGeo, requisicao).pipe(map(response => ({
    //                 cenarioId: regraGeoConfig.cenario,
    //                 regra: response
    //             }))).toPromise());
    //             ocorreuErros.push(false)
    //         }
    //     }
    //     if (ocorreuErros.some(i => i == true)) {
    //         return []
    //     } else {
    //         return await Promise.all(aplicacoesRegrasObs)
    //     }
    // }
    public async processaRequisicoesDeAnalisesTopologicas(regrasGeoConfig: RegrasGeoRequerimentoRegra[], feicao: FeicaoRequerimentoLicenciamentoDto, aplicacoes?: any[]): Promise<CenarioRegrasGeo[]> {
        const aplicacoesRegrasObs: any[] = [];
        const regrasSemGeometrias: string[] = [];
        let hasErrors: boolean = false;

        for (const regraGeoConfig of regrasGeoConfig) {
            const requisicao: RequisicaoAnalisesTopologicas = {
                idFeicaoEntrada: feicao.idFeicaoEntrada,
                idRegraGeo: regraGeoConfig.idRegraGeo,
                cenario: regraGeoConfig.cenario,
                wktFeicaoEntrada: feicao.wkt
            };

            if(regraGeoConfig.cenario === CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO) {
                const regraGeoAdmin: RegraGeo = await this.regraGeoService.getRegraGeoById(regraGeoConfig.idRegraGeo).toPromise();
                requisicao.analisesTopologicas = this.criaAnalisesTopologicas(regraGeoAdmin, regrasSemGeometrias);
                if (requisicao.analisesTopologicas.length === 0) {
                    this.snackBarService.show(
                        `<p>Não há feições cadadastras para validar o cenário de aplicação das regras GEO para garantia com feições do requerimento.<p><span class="regras-titulo">Regras</span><br><span class="regras-terceiro-cenario">${regrasSemGeometrias.join('<br>')}</span>`,
                        SnackBarType.ERROR,
                        50000
                    );
                    hasErrors = true;
                    continue;
                }
                aplicacoesRegrasObs.push(this.regraGeoService.applyTerceiroCenario(regraGeoConfig.idRegraGeo, requisicao)
                    .pipe(map(response => ({ cenarioId: regraGeoConfig.cenario, regra: response }))).toPromise());

            } else {
                aplicacoesRegrasObs.push(this.regraGeoService.apply(regraGeoConfig.idRegraGeo, requisicao)
                    .pipe(map(response => ({
                        cenarioId: regraGeoConfig.cenario,
                        regra: response
                    }))).toPromise());
            }
        }
        return hasErrors ? [] : await Promise.all(aplicacoesRegrasObs);
    }
    verificaAplicacoesRegrasGeo(aplicacoes: CenarioRegrasGeo[]): string[] {
        const todasRegrasValidadas: string[] = [];
        aplicacoes.forEach(aplicacao => {
            for (let regra of aplicacao.regra.regrasFeicao) {
                switch (aplicacao.cenarioId) {
                    case 1:
                        if (regra.ativouRegra) {
                            todasRegrasValidadas.push(regra.tipoRegra)
                        }
                        break;
                    case 2:
                        if (!regra.ativouRegra) {
                            todasRegrasValidadas.push(regra.tipoRegra)
                        }
                        break;
                    case 3:
                        if (!regra.ativouRegra) {
                            todasRegrasValidadas.push(regra.tipoRegra)
                        }
                        break;
                }
            }
        })

        return todasRegrasValidadas;
    }
    openDialog(title: string, subtitle: string, aplicacoes, alertMode: boolean) {
        return this.dialog.open(RelatorioRegraDialogComponent, {
            width: '98%',
            height: '95vh',
            data: {
                title,
                subtitle,
                aplicacoes,
                alertMode
            }
        });
    }
    processAplicacoes(aplicacoes, feicao, geometria) {
        feicao.feicoesPrincipais = [];
        aplicacoes.forEach(apl => {
            if (apl.cenarioId !== CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO && apl.cenarioId !== CenarioRegraGeo.GARANTIA_APLICACOES_BATCH) return;
            apl.regra.regrasFeicao
                .filter(regra => regra.origem === OrigemFeicaoEntrada.FEICAO_ENTRADA)
                .forEach(({feicaoComparacao}) => {
                    feicao.feicoesPrincipais.push(...feicaoComparacao.feicoesUtilizadasNaAnalise
                        .filter(geom => geom.ativou)
                        .map(geom => ({idGeometria: geom.idGeometria})));
                });
        });

        const regrasVerificadas = this.verificaAplicacoesRegrasGeo(aplicacoes);

        if (!regrasVerificadas.length) {
            this.snackBarService.showSuccess('Análises Topológicas executadas com sucesso!');
            this.incluirGeometria(geometria, feicao);
            return;
        }

        if (regrasVerificadas.some(item => item == TipoRegraGeo.IMPEDITIVA)) {
            this.openDialog(
                'ATENÇAO - foi submetida uma geometria inválida no Requerimento.',
                'Favor verificar o relatório abaixo para averiguação e posteriormente submeta uma nova geometria válida.',
                aplicacoes,
                false
            ).afterClosed().subscribe(() => {
                this.componenteMapa.deleteFeature(geometria);
            });
        } else if (regrasVerificadas.some(item => item == TipoRegraGeo.ALERTA)) {
            this.openDialog(
                `ATENÇAO - Foram detectadas geometrias que infringiram as regras para feição submetida, porém não são impeditivas para a continuade do preenchimento do requerimento.`,
                '',
                aplicacoes,
                true
            ).afterClosed().subscribe(async (inserirGeometria) => {
                if (inserirGeometria) {
                    this.incluirGeometria(geometria, feicao);
                } else {
                    this.componenteMapa.deleteFeature(geometria);
                }
            });
        }
    }
    private criaAnalisesTopologicasProcessamentEmBatch(regraGeoAdmin: RegraGeo, regrasSemGeometrias: string[]): AnaliseTopologica[] {
        const analisesTopologicas: AnaliseTopologica[] = [];

        regraGeoAdmin.regrasFeicao.forEach((regra) => {
            const feicoesApp = this.feicoesAplicacoesBatch.filter(
                (feicaoApp) => feicaoApp.feicao.idFeicaoEntrada === regra.idFeicaoEntradaComoComp
            );
            const camadasRequerimento = getCamadasByFeicaoEntrada(this.feicoesRequerimento, regra.idFeicaoEntradaComoComp);

            const feicoesComparacao: FeicaoComparacao[] = [];

            feicoesApp.forEach((feicaoApp) => {
                feicoesComparacao.push({
                    id: regra.idFeicaoEntradaComoComp,
                    wkt: feicaoApp.geometria.wkt,
                    idGeometria: null,
                });
            });

            camadasRequerimento.forEach((geometria: GeometriaMapa) => {
                feicoesComparacao.push({
                    id: regra.idFeicaoEntradaComoComp,
                    wkt: geometria.wkt,
                    idGeometria: geometria.extra.idGeometria,
                });
            });

            if (feicoesComparacao.length > 0) {
                analisesTopologicas.push({
                    id: regra.id,
                    feicoesComparacao: feicoesComparacao,
                });
            } else {
                regrasSemGeometrias.push(regraGeoAdmin.nome);
            }
        });

        return analisesTopologicas;
    }
    async montaRequisicoesDeAnalisesTopologicasGarantiaAplicaoesBatch(regrasGeoConfig: RegrasGeoRequerimentoRegra[], feicao: FeicaoRequerimentoLicenciamentoDto){
        const regrasSemGeometrias: string[] = [];
        const aplicacoes: any[] = [];
        for (const regraGeoConfig of regrasGeoConfig) {
            const requisicao: RequisicaoAnalisesTopologicas = {
                idFeicaoEntrada: feicao.idFeicaoEntrada,
                idRegraGeo: regraGeoConfig.idRegraGeo,
                cenario: regraGeoConfig.cenario,
                wktFeicaoEntrada: feicao.wkt
            };

            if(regraGeoConfig.cenario === CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO || regraGeoConfig.cenario === CenarioRegraGeo.GARANTIA_APLICACOES_BATCH) {
                const regraGeoAdmin: RegraGeo = await this.regraGeoService.getRegraGeoById(regraGeoConfig.idRegraGeo).toPromise();
                requisicao.analisesTopologicas = this.criaAnalisesTopologicasProcessamentEmBatch(regraGeoAdmin, regrasSemGeometrias);
                aplicacoes.push({
                    regraGeoConfig: regraGeoConfig,
                    requisicao: requisicao,
                    regrasSemGeometrias: regrasSemGeometrias,
                    regraGeoAdmin: regraGeoAdmin
                })
            } else {
                aplicacoes.push({
                    regraGeoConfig: regraGeoConfig,
                    requisicao: requisicao,
                    regrasSemGeometrias: regrasSemGeometrias,
                    regraGeoAdmin: null
                })
            }
        }

        return aplicacoes;
    }
    async montaFeicoesAplicacoesBatch() {
        const objetivos = this.objetivos.map(ob => ob.idObjetivoLicenciamentoAmbiental);
        for (const [index, feicaoApp] of Object.entries(this.feicoesAplicacoesBatch)) {
            const regrasGeo = this.getRegrasGeo(feicaoApp.feicao.idFeicaoEntrada, objetivos);
            feicaoApp.aplicacoes = await this.montaRequisicoesDeAnalisesTopologicasGarantiaAplicaoesBatch(regrasGeo, feicaoApp.feicao);
        }
    }
    showMessagemAlerta(ap){
        if (ap.requisicao.cenario === CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO) {
            if (ap.requisicao.analisesTopologicas.length === 0) {
                this.snackBarService.show(
                    `<p>Não há feições cadadastras para validar o 
                                            cenário de aplicação das regras GEO para garantia com feições do
                                             requerimento.<p><span class="regras-titulo">Regras</span>
                                             <br><span class="regras-terceiro-cenario">${ap.regrasSemGeometrias.join('<br>')}
                                             </span>`,
                    SnackBarType.ERROR,
                    10000
                );
            }
        } else if (ap.requisicao.cenario === CenarioRegraGeo.GARANTIA_APLICACOES_BATCH) {
            if (ap.requisicao.analisesTopologicas.length === 0) {
                this.snackBarService.show(
                    `<p>Não há feições cadadastras para validar o 
                                            cenário de aplicação das regras GEO para garantia em lote de feições do
                                             requerimento.<p><span class="regras-titulo">Regras</span>
                                             <br><span class="regras-terceiro-cenario">${ap.regrasSemGeometrias.join('<br>')}
                                             </span>`,
                    SnackBarType.ERROR,
                    10000
                );
            }
        }
    }
    criaRaquestsProcessamentoEmBatch(): Promise<any> {
        const requestsPromises: any[] = [];
        const requestsBatch: any[] = [];
        if (Array.isArray(this.feicoesAplicacoesBatch)){
            this.feicoesAplicacoesBatch.forEach((item) => {
                item.aplicacoes.forEach((ap) => {
                    if(ap.requisicao.cenario === CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO){
                        requestsPromises.push(this.regraGeoService.applyTerceiroCenario(ap.requisicao.idRegraGeo, ap.requisicao)
                            .pipe(map(response => ({
                                feicao: item.feicao,
                                geometria: item.geometria,
                                cenarioId: ap.requisicao.cenario,
                                regra: response
                            }))).toPromise());
                    } else if (ap.requisicao.cenario === CenarioRegraGeo.GARANTIA_APLICACOES_BATCH) {
                        requestsBatch.push({
                            feicao: item.feicao,
                            geometria: item.geometria,
                            cenarioId: ap.requisicao.cenario,
                            requisicao: ap.requisicao
                        });
                    } else {
                        requestsPromises.push(this.regraGeoService.apply(ap.requisicao.idRegraGeo, ap.requisicao)
                            .pipe(map(response => ({
                                feicao: item.feicao,
                                geometria: item.geometria,
                                cenarioId: ap.requisicao.cenario,
                                regra: response
                            }))).toPromise());
                    }
                })
            })
        }

        const batchPromise = this.regraGeoService
            .applyBatch(requestsBatch.map(re => re.requisicao))
            .toPromise()
            .then((responses) => {
                return responses.map((response, idx) => {
                    return {
                        feicao: requestsBatch[idx].feicao,
                        geometria: requestsBatch[idx].geometria,
                        cenarioId: requestsBatch[idx].cenarioId,
                        regra: response,
                    };
                });
            });

        return Promise.all([...requestsPromises, batchPromise])
    }
    flattenCenarioRegrasGeo(aplicacoes: CenarioRegrasGeo[]): CenarioRegrasGeo[] {
        const flattened: CenarioRegrasGeo[] = [];
        const flattenRecursive = (arr: CenarioRegrasGeo[]) => {
            for (const item of arr) {
                if (Array.isArray(item)) {
                    flattenRecursive(item);
                } else {
                    flattened.push(item);
                }
            }
        }
        flattenRecursive(aplicacoes);

        return flattened;
    }
    incluirGeometriaBatch(aplicacoes: CenarioRegrasGeo[]): void {
        this.requerimentoLicenciamentoService.postGeometriaBatch(this.requerimentoLicenciamento.id, aplicacoes.map(apl => apl.feicao))
            .subscribe({
                next: async (value) => {
                    this.snackBarService.showSuccess('Geometrias salva com sucesso!');
                    await this.listarGeometrias();
                    this.feicoesAplicacoesBatch = [];
                },
                error: err => {
                    aplicacoes.forEach(apl => {
                        this.componenteMapa.delete(apl.geometria);
                    })
                    this.feicoesAplicacoesBatch = [];
                    this.snackBarService.showError('Erro ao salvar as geometrias.', err);
                }
            })
    }
    async executaAnalisesTopologicasGarantiaAplicoesBatch(){
        await this.montaFeicoesAplicacoesBatch();
        const naoTemAnalisesTopologicas = this.feicoesAplicacoesBatch.some(item => item.aplicacoes.some(ap => ap.requisicao.analisesTopologicas.length === 0))

        if (naoTemAnalisesTopologicas) {
            this.feicoesAplicacoesBatch.forEach((item) => {
                item.aplicacoes.forEach((ap) => {
                    this.showMessagemAlerta(ap)
                    this.componenteMapa.delete(ap.geometria);
                })
            });
            this.feicoesAplicacoesBatch = [];
            return;
        }

        const aplicacoes: CenarioRegrasGeo[] = await this.criaRaquestsProcessamentoEmBatch();
        const aplicacoesFlattened = this.flattenCenarioRegrasGeo(aplicacoes);

        if (Array.isArray(aplicacoesFlattened)){
            aplicacoesFlattened.forEach(apl => {
                apl.feicao.feicoesPrincipais = []
                if (apl.cenarioId !== CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO && apl.cenarioId !== CenarioRegraGeo.GARANTIA_APLICACOES_BATCH) return;
                apl.regra.regrasFeicao
                    .filter(regra => regra.origem === OrigemFeicaoEntrada.FEICAO_ENTRADA)
                    .forEach(({feicaoComparacao}) => {
                        apl.feicao.feicoesPrincipais.push(...feicaoComparacao.feicoesUtilizadasNaAnalise
                            .filter(geom => geom.ativou)
                            .map(geom => ({idGeometria: geom.idGeometria, wkt: geom.wkt})));
                    });
            });
        }

        const regrasVerificadas = this.verificaAplicacoesRegrasGeo(aplicacoesFlattened);
        const aplicacoesNaoDuplicadas = removeDuplicatesByNestedKey(aplicacoesFlattened, 'geometria.feature.ol_uid');

        if (!regrasVerificadas.length) {
            this.snackBarService.showSuccess('Análises Topológicas executadas com sucesso!');
            this.incluirGeometriaBatch(aplicacoesNaoDuplicadas)
            return;
        }

        if (regrasVerificadas.some(item => item == TipoRegraGeo.IMPEDITIVA)) {
            this.openDialog(
                'ATENÇAO - foi submetida uma geometria inválida no Requerimento.',
                'Favor verificar o relatório abaixo para averiguação e posteriormente submeta uma nova geometria válida.',
                aplicacoesFlattened,
                false
            ).afterClosed().subscribe(() => {
                aplicacoes.forEach(apl => {
                    this.componenteMapa.delete(apl.geometria);
                })
            });
        } else if (regrasVerificadas.some(item => item == TipoRegraGeo.ALERTA)) {
            this.openDialog(
                `ATENÇAO - Foram detectadas geometrias que infringiram as regras para feição submetida, porém não são impeditivas para a continuade do preenchimento do requerimento.`,
                '',
                aplicacoesFlattened,
                true
            ).afterClosed().subscribe((inserirGeometria) => {
                if (inserirGeometria) {
                    this.incluirGeometriaBatch(aplicacoesNaoDuplicadas)
                } else {
                    aplicacoes.forEach(apl => {
                        this.componenteMapa.delete(apl.geometria);
                    })

                }
            });
        }

        this.feicoesAplicacoesBatch = [];
    }

    async applyRegrasGeoEAdicionaFeature(feicao: FeicaoRequerimentoLicenciamentoDto, geometria: GeometriaMapa, camada: CamadaRegraGeo) {
        try {
            const objetivos = this.objetivos.map(ob => ob.idObjetivoLicenciamentoAmbiental);
            const regrasGeo = this.getRegrasGeo(feicao.idFeicaoEntrada, objetivos);

            if (!regrasGeo || regrasGeo.length === 0) {
                this.snackBarService.showAlert('Não há configuração de Regras GEO cadastradas para esse tipo de requerimento.');
                return;
            }

            const temRegrasGarantiaAplicacoesBatch: boolean = regrasGeo.some(regra => regra.cenario === CenarioRegraGeo.GARANTIA_APLICACOES_BATCH);

            if (temRegrasGarantiaAplicacoesBatch) {

                const feicaoAplicacaoBatch: any = {
                    feicao: feicao,
                    geometria: geometria,
                    camada: camada,
                    aplicacoes: []
                }

                this.feicoesAplicacoesBatch.push(feicaoAplicacaoBatch);

                const dialogRef = this.dialog.open(DialogConfirmaAnaliseFeicaoBatchComponent, {
                    width: '60%',
                    data: {
                        geoLicEmp: this
                    }
                })

                dialogRef.afterClosed().subscribe(async (processar) => {
                    if (processar) {
                        await this.executaAnalisesTopologicasGarantiaAplicoesBatch()
                    }
                })

            } else {
                const aplicacoes: CenarioRegrasGeo[] = await this.processaRequisicoesDeAnalisesTopologicas(regrasGeo, feicao);
                if (aplicacoes.length > 0) {
                   await this.processAplicacoes(aplicacoes, feicao, geometria);
                } else {
                    this.componenteMapa.deleteFeature(geometria.feature);
                }
            }

        } catch (e) {
            console.error(e);
            this.snackBarService.showError('Não foi possível executar as análises topológicas da feição submetida.');
            this.componenteMapa.deleteFeature(geometria.feature);
        }
    }

    onAddFeature = async (geometria: GeometriaMapa): Promise<void> => {
        this.validacaoTopologicaDaGeometria([geometria.feature], async (dados: ValidacaoGeometria) => {
            /**
             * Em alguns casos o serviço da validação topológica corrige a geometria submetida e devolve o atributo valido como true,
             * nesse caso é preciso inserir o wkt corrigido.
             */
            if (dados.valido) {
                this.componenteMapa.deleteFeature(geometria);
                geometria = this.criaGeometria(dados.wkt, geometria.style);
            }

            if (this.camadaSelecionada === this.referenciasTemporarias) {
                this.referenciasTemporarias.camadas.push(geometria);
                geometria.title = geometria.tipoGeometria;
                geometria.permissao = {
                    remover: true
                };
                this.camadas = [...this.camadas];
            } else {
                const feicao: FeicaoRequerimentoLicenciamentoDto = new FeicaoRequerimentoLicenciamentoDto();
                feicao.wkt = geometria.wkt;
                feicao.requerimentoLicenciamento = this.requerimentoLicenciamento;

                if (!this.camadaSelecionada) {
                    return;
                }

                const [idFeicaoEntrada, objetivoLicenciamento] = this.camadaSelecionada.name.split('-');

                feicao.objetivoLicenciamento = Number(objetivoLicenciamento);
                feicao.idFeicaoEntrada = Number(idFeicaoEntrada);

                if (this.ultrapassouQuantidadeMaximaDeFeicoes(feicao.idFeicaoEntrada, feicao.objetivoLicenciamento)) {
                    this.snackBarService.showError("Quantidade máxima de feições ultrapassada.");
                    this.componenteMapa.deleteFeature(geometria);
                    return;
                }

                await this.applyRegrasGeoEAdicionaFeature(feicao, geometria, this.camadaSelecionada);

            }
        }, erro => {
            this.snackBarService.showError('Erro na validação topológica da feicao submetida', erro);
            this.componenteMapa.deleteFeature(geometria.feature);
        });
    }
    incluirGeometria(geometria: GeometriaMapa, feicao: FeicaoRequerimentoLicenciamentoDto): void {
        this.requerimentoLicenciamentoService.postGeometria(this.requerimentoLicenciamento.id, feicao).toPromise()
            .then(async (feicaoRequerimento: FeicaoRequerimentoLicenciamentoDto) => {
                geometria.id = feicaoRequerimento.id;
                geometria.title = `[${geometria.id}] 'Sem denominação'}`;
                geometria.extra = feicaoRequerimento;
                return geometria
            })
            .then(async (geom) => {
                let feicaoEntrada: Feicao = await this.feicaoService.getById(geometria.extra.idFeicaoEntrada).toPromise();

                const dialogComponent = this.getDialogComponentByModelo(feicaoEntrada.idModeloFeicao);

                const dialogRef = this.dialog.open(dialogComponent, {
                    width: '900px',
                    data: {
                        denomicacao: geometria.extra.denominacao,
                        tamanho: 255,
                        modeloFeicaoDTO: geometria.extra.modeloFeicaoDTO,
                        idModeloFeicao: feicaoEntrada.idModeloFeicao,
                        geometria: geometria,
                        onRemoveFeature: this.onRemoveFeature
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (!!result.success) {
                        this.requerimentoLicenciamentoService.putGeometria(this.requerimentoLicenciamento.id, geometria.extra.id, result.feicao).subscribe(async () => {
                            this.snackBarService.showSuccess('Geometria salva com sucesso!');
                            await this.listarGeometrias();
                        }, e => {
                            this.componenteMapa.delete(geometria);
                            this.snackBarService.showError('Erro ao salvar a geometria.', e);
                        });
                    }
                });
            })
            .catch(e => {
                this.componenteMapa.delete(geometria);
                this.snackBarService.showError('Erro ao salvar a geometria.', e);
            })
    }

    onClearAll = (): void => {
        if (this.camadaSelecionada === this.referenciasTemporarias) {
            this.referenciasTemporarias.camadas = [];
            this.camadas = [...this.camadas];
        } else {
            this.camadaSelecionada.camadas.forEach(this.excluirGeometriaDialog);
        }
    }

    private isCamadaTemporaria(geometria: GeometriaMapa): boolean {
        return this.referenciasTemporarias.camadas.indexOf(geometria) > -1;
    }

    private isCamadaLocalizacao(geometria: GeometriaMapa): boolean {
        return this.localizacao.camadas.indexOf(geometria) > -1;
    }

    async getGeometriasDependentes(geometria: GeometriaMapa): Promise<FeicaoRequerimentoLicenciamentoDto> {
        return this.requerimentoLicenciamentoService.getFeicoesPorIdGeometria(this.requerimentoLicenciamento.id, geometria.extra.idGeometria).toPromise();
    }

    onRemoveFeature = (geometria: GeometriaMapa): void => {
        if (this.isCamadaTemporaria(geometria)) {
            const index = this.referenciasTemporarias.camadas.indexOf(geometria);
            if (index > -1) {
                this.referenciasTemporarias.camadas.splice(index, 1);
                this.componenteMapa.delete(geometria);
            }
            this.camadas = [...this.camadas];
        } else if (this.isCamadaLocalizacao(geometria)) {
            this.imovelService.deleteFeicaoLocalizacao(this.requerimento.id, geometria.id).pipe(take(1)).subscribe(async () => {
                const imovel = this.imoveisDoRequerimento.find(imovel => imovel.id = geometria.extra.imovel.id)
                // Só permitir um único polígono para o imóvel urbano.
                if (imovel.tipo === 'URBANO') {
                    this.localizacao.permissao.poligono = true;
                }
                this.snackBarService.showSuccess('Geometria removida com sucesso.');
                await this.listarGeometrias();
            }, error => {
                this.snackBarService.showError(error.message || 'Erro ao remover a geometria.');
            });
        } else {
            this.excluirGeometriaDialog(geometria);
        }
    }

    validaFeicoesEntrada(erros: ErrosAbaRequerimento): ErrosAbaRequerimento {
        const camadas: CamadaRegraGeo[] = getFeicoesEntrada(this.feicoesRequerimento);
        camadas.forEach((camada) => {
            if (camada.regraGeoRequerimentoFeicao.envioObrigatorio && camada.camadas.length === 0) {
                erros.push(`É obrigatório o envio de mínimo uma feição para Seção de Feições do Requerimento -> ${camada.title.toLocaleUpperCase()}.`)
            }
        })
        return erros
    }

    async validarAba(erros: ErrosAbaRequerimento): Promise<void> {
        erros = this.validaFeicoesEntrada(erros);
        //TODO aguardando desenvolvimento da API de inventario
        //Validação de inventarios importados
        // if(this.mostrarInventario){
        //     if(!this.inventarioImportado){
        //         erros.push('É obrigatório ter um inventário florestal associado, com pelo menos um ponto importado.', false);
        //     }
        // }
        if (erros.length === 0 || !erros.temRestritivo) {
            return Promise.resolve();
        }

        return Promise.resolve();
    }

    onSelectCamada(camadaSelecionada: Camada): void {
        this.camadaSelecionada = camadaSelecionada;
    }

    getDialogComponentByModelo(idModeloFeicao) {
        const componentMap = {
            [ModeloFeicao.AQC]: DialogEdicaoFeicaoAqcComponent,
            [ModeloFeicao.UPA]: DialogEdicaoFeicaoUpaComponent,
            [ModeloFeicao.DESMATE]: DialogEdicaoFeicaoDesmateComponent,
            [ModeloFeicao.EVENTUAL]: DialogEdicaoFeicaoEventualComponent,
            [ModeloFeicao.SELETIVA]: DialogEdicaoFeicaoSeletivaComponent,
            [ModeloFeicao.TALHAO]: DialogEdicaoFeicaoTalhaoComponent,
            default: DialogEditarFeicaoComponent,
        };

        return componentMap[idModeloFeicao] || componentMap.default;
    }


    onShowDadosGeometria = (geometria: GeometriaMapa): void => {
        this.feicaoService.getById(geometria.extra.idFeicaoEntrada).subscribe({
            next: (feicaoEntrada: Feicao) => {
                const dialogComponent = this.getDialogComponentByModelo(feicaoEntrada.idModeloFeicao);
                this.dialog.open(dialogComponent, {
                    width: '900px',
                    data: {
                        denomicacao: geometria.extra.denominacao,
                        tamanho: 255,
                        modeloFeicaoDTO: geometria.extra.modeloFeicaoDTO,
                        idModeloFeicao: feicaoEntrada.idModeloFeicao,
                        geometria: geometria,
                        visualizacao: true
                    }
                })
            }
        });
    }


    onEditarGeometria = (geometria: GeometriaMapa): void => {
        this.validacaoTopologicaDaGeometria([geometria.feature], async (dados: ValidacaoGeometria) => {
            const geom_temp = geometria;
            if (dados.valido) {
                this.componenteMapa.deleteFeature(geometria);
                geometria = this.criaGeometria(dados.wkt, geometria.style);
                geometria.extra = geom_temp.extra
            }

            let feicaoEntrada: Feicao = await this.feicaoService.getById(geometria.extra.idFeicaoEntrada).toPromise();

            const dialogComponent = this.getDialogComponentByModelo(feicaoEntrada.idModeloFeicao);
            const dialogRef = this.dialog.open(dialogComponent, {
                width: '900px',
                data: {
                    denomicacao: geometria.extra.denominacao,
                    tamanho: 255,
                    modeloFeicaoDTO: geometria.extra.modeloFeicaoDTO,
                    idModeloFeicao: feicaoEntrada.idModeloFeicao,
                    geometria: geometria,
                    onRemoveFeature: this.onRemoveFeature
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (!!result.success) {
                    this.requerimentoLicenciamentoService.putGeometria(this.requerimentoLicenciamento.id, geometria.extra.id, result.feicao).subscribe(async () => {
                        this.snackBarService.showSuccess('Geometria alterada com sucesso!');
                        await this.listarGeometrias();
                    }, e => {
                        this.componenteMapa.delete(geometria);
                        this.snackBarService.showError('Erro ao salvar a geometria.', e);
                    });
                }
            });
        }, erro => {
            this.componenteMapa.delete(geometria);
            this.snackBarService.showError('Erro na validação topológica da geometria.', erro);
        })
    }

    async importarInventario() {
        //TODO aguardando desenvolvimento da API de inventario
        this.snackBarService.showAlert("Funcionalidade em desenvolvimento.");

        // if(!this.inventarioImportado){
        //     this.inventarioImportado = await this.documentoProcessoService.getById(2400).toPromise();
        // }else{
        //     this.snackBarService.showAlert("Já existe um inventário associado a este requerimento.")
        // }
    }

    removerInvetario() {
        //TODO aguardando desenvolvimento da API de inventario
        this.snackBarService.showAlert("Funcionalidade em desenvolvimento.");

        // this.messageDialog.showQuestion(`Todos os pontos do inventario serão excluídos deste requerimento, deseja continuar?`, 'Confirmar exclusão de inventário')
        //     .subscribe(resposta => {
        //         if(resposta === MessageDialogResult.Yes){
        //             this.inventarioImportado = null;
        //         }
        //     });
    }

    async baixarInventario() {
        window.open(`${this.documentoProcessoService.urlResource}/${this.inventarioImportado.id}/download`);
    }

    async buscarObjetivoSalvo() {
        this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
        this.objetivoLicenciamento = await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();
        this.requerInventario();
    }

    requerInventario() {
        this.objetivoLicenciamento.forEach(obj => {
            if (obj.descricaoObjetivo === "ACAI – Autorização de Corte de Árvore Adulta Isolada" ||
                obj.descricaoObjetivo === "Reflorestamento" ||
                obj.descricaoObjetivo === "PMFS – Plano de Manejo Florestal" ||
                obj.descricaoObjetivo === "PEF – Plano de Exploração Florestal") {
                this.mostrarInventario = true;
            }
        });
    }
    async getAnalisesTopologicasDaGeometria(geometria: FeicaoRequerimentoLicenciamentoDto): Promise<CenarioRegrasGeo[]> {
        if (this.selectedIndex !== 1) {
            return []
        }
        const camada: CamadaRegraGeo = this.findCamada(geometria.idFeicaoEntrada, geometria.objetivoLicenciamento);
        try {
            if (camada) {
                const objetivos = this.objetivos.map(ob => ob.idObjetivoLicenciamentoAmbiental);
                const regrasGeoConfig = camada ? this.getRegrasGeo(geometria.idFeicaoEntrada, objetivos) : [];
                return regrasGeoConfig.length > 0 ? await this.processaRequisicoesDeAnalisesTopologicas(regrasGeoConfig, geometria) : [];
            } else {
                return []
            }
        } catch (e) {
            return []
        }
    }
    async onChangeTab(evt) {
        if (evt.index == 0) {
            await this.listarGeometrias();
        } else {
            this.analisesTopologicasDasGeometriasRequerimento = [];
            for (const [index, geometria] of Object.entries(this.geometrias)) {
                this.analisesTopologicasDasGeometriasRequerimento.push({
                    geometria: geometria,
                    aplicacoes: await this.getAnalisesTopologicasDaGeometria(geometria)
                })
            }
        }
    }
    validacaoTopologicaDaGeometria(features: Feature[], done, error) {
        const featuresWkt = new WKT().writeFeatures(features, {
            featureProjection: this.componenteMapa.projection
        });
        const srid = this.componenteMapa.projection.getCode().split(":")[1];
        this.validacaoGeometriaService.validarWkt(featuresWkt, parseInt(srid)).pipe(
            take(1)
        ).subscribe(validacao => {
            if (validacao.valido) {
                done(validacao)
            } else {
                const dialog = this.dialog.open(ValidacaoFeicaoEntradaDialogComponent, {
                    id: 'validacao-feicao-entrada',
                    data: validacao,
                });
                dialog.afterClosed().subscribe(result => {
                    this.componenteMapa.deleteFeatures(features)
                });
            }
        }, err => {
            error(err)
        });
    }
    private async adicionaProcessoVinculado(_: string): Promise<RequerimentoTituloVinculado[]> {
        if (this.requerimento.numeroProtocoloVinculado) {
            let resposta = await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise();
            if (resposta && resposta.length > 0) {
                let titulo = await this.emissaoTituloService.buscaEmissoesPorIdRequerimentoDocumentoProcesso(resposta[0].id).toPromise().catch(() => null);
                if (titulo.length > 0) {
                    return titulo.map(item => <RequerimentoTituloVinculado>{
                        id: item.id,
                        requerimentoVinculado: item.requerimentoTitulacao.requerimento,
                        emissaoTitulo: item,
                        numeroEmissao: this.requerimento.numeroProtocoloVinculado
                    });
                }
            }
        }

        return null;
    }
    private async carregarReqTituloVinculado(tipoVinculo: string): Promise<void> {
        let result = await this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, tipoVinculo).toPromise();
        let vinculado = await this.adicionaProcessoVinculado(tipoVinculo);
        if (vinculado != null && vinculado.length > 0) {
            result.push(...vinculado);
        }

        if (result.some(titulo => !titulo.legado && titulo.emissaoTitulo.requerimentoTitulacao.titulacao.descricao.toLowerCase().includes("LP - Licença Prévia".toLowerCase()))) {
            this.protocoloVinculadoComLp = true;
        } else if (result.some(titulo => !titulo.legado && titulo.emissaoTitulo.requerimentoTitulacao.titulacao.descricao.toLowerCase().includes("LI - Licença de Instalação".toLowerCase()))) {
            this.protocoloVinculadoComLi = true;
        } else if (result.some(titulo => !titulo.legado && titulo.emissaoTitulo.requerimentoTitulacao.titulacao.descricao.toLowerCase().includes("LO - Licença de Operação".toLowerCase()))) {
            this.protocoloVinculadoComLo = true;
        }
    }
    private async showFeicoesAnalisadas(camadas: CamadaObjetivo[]) {
        const camadasDeFeicoesAnalisadas: Camada[] = [];
        for (const [index, camadaObjetivo] of Object.entries(camadas)) {
            camadaObjetivo.camada.camadas = [];
            camadaObjetivo.camada.expandGroup = true
            const feicoes: CamadaFeicao[] = await Promise.all(camadaObjetivo.feicoesPromises);
            for (const feicaoAnalisada of feicoes) {
                feicaoAnalisada.camada.expandGroup = true
                feicaoAnalisada.camada.geometryGroup = true;
                feicaoAnalisada.camada.camadas = [];

                for (const feicao of feicaoAnalisada.geometrias) {
                    const geometriaMapa: GeometriaMapa = this.componenteMapa.criarGeometria(feicao.wkt, feicaoAnalisada.camada.style);
                    geometriaMapa.id = feicao.id;
                    geometriaMapa.title = `[${feicao.id}] ${feicao.denominacao || 'Sem denominação'}`;
                    geometriaMapa.name = geometriaUuid(feicao.id);

                    geometriaMapa.propriedades = {
                        ID: feicao.id,
                        'Denominação': feicao.denominacao,
                    };
                    geometriaMapa.permissao = {
                        remover: false,
                        ponto: false,
                        poligono: false,
                        linha: false,
                        buffer: false,
                        upload: false,
                        exportar: true
                    };
                    geometriaMapa.extra = feicao;
                    geometriaMapa.visualizacao = {
                        showCheckbox: true,
                        showFeature: true
                    }
                    geometriaMapa.expandGroup = true;
                    geometriaMapa.style = feicaoAnalisada.camada.style
                    geometriaMapa.feature.setStyle(feicaoAnalisada.camada.style)
                    feicaoAnalisada.camada.camadas.push(geometriaMapa)
                }
                camadaObjetivo.camada.camadas.push(feicaoAnalisada.camada)
            }
            camadasDeFeicoesAnalisadas.push(camadaObjetivo.camada)
        }
        cleanCamadas(this.feicoesAnalisadas, this.componenteMapa)
        this.feicoesAnalisadas.camadas = [...camadasDeFeicoesAnalisadas];

        ordenarFeicoes(this.feicoesAnalisadas, this.componenteMapa);
        this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'feicoes-analisadas');
        this.camadaTrabalho.camadas.push(this.feicoesAnalisadas);
        this.camadas = [...this.camadas];
        this.componenteMapa.fit();
    }
    private async handleFeicoesAnalisadas(feicoesAnalisadas: FeicaoRequerimentoLicenciamentoDto[]) {
        const feicoesAgrupadas: CamadaObjetivo[] = agruparFeicoesAnalisadasPorObjetivos(feicoesAnalisadas);
        if (feicoesAgrupadas.length > 0) {
            feicoesAgrupadas.forEach((objetivo: CamadaObjetivo) => {
                objetivo.camada = {
                    title: objetivo.descricaoObjetivoLicenciamento,
                    name: `objetivo-${objetivo.objetivoLicenciamento}`,
                    geometryGroup: true,
                    expandGroup: true,
                    camadas: [],
                };
                objetivo.feicoesPromises = [];
                objetivo.feicoes.forEach((feicaoEntrada: CamadaFeicao) => {
                    objetivo.feicoesPromises.push(new Promise<CamadaFeicao>((resolve, reject) => {
                        this.feicaoService.getById(Number(feicaoEntrada.idFeicaoEntrada)).subscribe({
                            next: (feicao: Feicao) => {
                                resolve({
                                    camada: getCamada(feicao),
                                    geometrias: feicaoEntrada.geometrias
                                })
                            },
                            error: (e) => {
                                reject(e);
                            }
                        })
                    }))
                })
            })
            await this.showFeicoesAnalisadas(feicoesAgrupadas);
        }
    }
    private getFeicoesAnalisadas(): void {
        if (this.requerimentoLicenciamento.requerimento.numeroProtocoloVinculado) {
            this.requerimentoLicenciamentoService.getFeicoesAnalisadas(this.requerimentoLicenciamento.requerimento.numeroProtocoloVinculado)
                .subscribe({
                    next: (feicoesAnalisadas: FeicaoRequerimentoLicenciamentoDto[]) => {
                        this.handleFeicoesAnalisadas(feicoesAnalisadas).then(r => {});
                    },
                    error: (e) => {
                        this.snackBarService.showError('Erro ao buscar as feições analisadas.', e);
                    }
                })
        }
    }
    private getCenariosDeRegrasGeo() {
        this.dominiosService.getDominio("TIPO_CENARIO_REGRA_GEO").subscribe(cenarios => this.cenariosRegrasGeo = cenarios);
    }
}
