export class ProcessDefinition {

    constructor(
        public id?: string,
        public definitionId?: string,
        public businessKey?: string,
        public name?: string,
        public caseInstanceId?: string,
        public tenantId?: string,
        public ended?: boolean,
        public suspended?: boolean,
        public links?: any,
        public variables?: any,
        public key?: string,
        public version?: number,
    ) {
    }

    static fromJson(json: any): ProcessDefinition {
        if (json === undefined || json === null) {
            return null;
        }
        return new ProcessDefinition(
            json.id,
            json.definitionId,
            json.businessKey,
            json.name,
            json.caseInstanceId,
            json.tenantId,
            json.ended,
            json.suspended,
            json.links,
            json.variables,
            json.key,
            json.version
        );
    }

    static fromJsons(json: any): ProcessDefinition[] {
        const lista: ProcessDefinition[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}