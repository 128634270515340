import {Component, Injector, OnInit} from '@angular/core';
import {RequerimentoService} from '../../../requerimento.service';
import {ConfirmarCancelamentoDialogComponent} from '../../../shared/confirmar-cancelamento-dialog/confirmar-cancelamento-dialog.component';
import {RedirectUtil} from '../../../../../shared/util/RedirectUtil';
import {BaseRequerimentoLicenciamentoFormulario} from '../base-requerimento-licenciamento.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-licenciamento-lac',
    templateUrl: './licenciamento-lac.component.html',
    styleUrls: ['./licenciamento-lac.component.css']
})
export class LicenciamentoLacComponent extends BaseRequerimentoLicenciamentoFormulario implements OnInit {

    constructor(injector: Injector,
                private requerimentoService: RequerimentoService,
                private snackBarService: SnackBarService) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.activatedRoute.snapshot.url[2].path === 'edit') {
            this.requerimentoService.getById(this.requerimento.id).subscribe(req => {
                if (req.situacaoProcesso === 'CHECKLIST_REPROVADO') {
                    this.requerimentoService.atualizaStatusRequerimento(req.id, 'EM_CORRECAO_CADASTRO').subscribe(req => req);
                }
            });
        }
    }

    cancelar(): any {
        const idTarefa = this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path;

        const dialogRef = this.dialog.open(ConfirmarCancelamentoDialogComponent, {
            width: '300px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.requerimentoService.cancelarRequerimento(idTarefa, this.requerimento.id).subscribe(resp => {
                    RedirectUtil.redirecionaDashboardCamunda();
                }, () => {
                });
            }
        });
    }

    finalizar(): void {
        this.validacaoFinal().then(passou => {
            if (passou) {
                const idTarefa = this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path;
                this.requerimentoService.finalizarProcesso(idTarefa, this.requerimento.id).subscribe(() => {
                        this.snackBarService.showSuccess('Requerimento enviado com sucesso!');
                        RedirectUtil.redirecionaDashboardCamundaComDelay();
                    }, e => this.snackBarService.showError((e.status >= 400 && e.status < 500) ? e.message : 'Erro ao enviar o requerimento.')
                );
            }
        });
    }
}
