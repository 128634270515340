import {BaseModel} from "../../../../shared/models/base.model";

export class ConsultaRequerimentoProcessoDTO extends BaseModel {

    constructor(
        public id?: number,
        public numero?: string,
        public numeroProcesso?: string,
        public prioridade?: boolean,
        public nomePessoa?: string,
        public titulacao?: string,
        public finalidade?: string,
        public objetivo?: string,
        public tipoRequerimento?: string
    ) {
        super();
    }

    static fromJson(json: any): ConsultaRequerimentoProcessoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new ConsultaRequerimentoProcessoDTO(
            json.id,
            json.numero,
            json.numeroProcesso,
            json.prioridade,
            json.nomePessoa,
            json.titulacao,
            json.finalidade,
            json.objetivo,
            json.tipoRequerimento
        );
    }

    static fromJsons(json: any): ConsultaRequerimentoProcessoDTO[] {
        const lista: ConsultaRequerimentoProcessoDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => lista.push(this.fromJson(item)));
            return lista;
        }
    }
}