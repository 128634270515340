import {Component, Input} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {AguaSubterranea} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-agua-subterranea/agua-subterranea.model';
import { DocumentoRequerimentoService } from 'app/main/pages/documentos-requerimentos/documento-requerimento.service';
import { DocumentoRequerimento } from 'app/main/pages/documentos-requerimentos/documento-requerimento.model';
import { ArquivoService } from 'app/main/shared/services/arquivo.service';
import { PdfViewerDialog } from '../../pdf-viewer-dialog/pdf-viewer-dialog.model';

@Component({
    selector: 'app-aba-objetivo-agua-subterranea',
    templateUrl: './aba-objetivo-agua-subterranea.component.html'
})

export class AbaObjetivoAguaSubterraneaComponent {

    @Input() ponto: Ponto;
    tipoPorosidade = AguaSubterranea.tiposPorosidade;
    tipoFonteAbastecimento = AguaSubterranea.tiposFontesAbastecimento;
    tipoBomba = AguaSubterranea.tiposBombas;

    constructor(
        private documentoRequerimentoService: DocumentoRequerimentoService,
        private arquivoService: ArquivoService,
        private pdfViewerDialog: PdfViewerDialog
    ) {
    }

    public downloadDocumento(id: any): void {
        window.open(`${this.documentoRequerimentoService.urlResource}/${id}/download`);
    }

    visualizarArquivo(docRequerimento: DocumentoRequerimento) {
        if (docRequerimento.nomeArquivo.endsWith('.pdf')) {
            this.arquivoService.download(docRequerimento.chave).subscribe(result =>
                this.pdfViewerDialog.show(result));
        } else {
            this.arquivoService.download(docRequerimento.chave, 'application/ms-excel').subscribe(result =>
                window.open(window.URL.createObjectURL(result)));
        }
    }

}
