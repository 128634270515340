import {Injectable, Injector} from '@angular/core';
import {TipoSistemaCaptacao} from '../../../../../../shared/models/tipo-sistema-captacao.model';
import {CachedBaseService} from '../../../../../../shared/services/cached-base.service';

@Injectable({
    providedIn: 'root'
})
export class AtividadeAquiculturaService extends CachedBaseService<TipoSistemaCaptacao> {

    constructor(protected injector: Injector) {
        super('/api/atividades-aquicultura', injector, TipoSistemaCaptacao.fromJson);
    }

}
