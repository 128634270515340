
import { Injectable } from '@angular/core';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { UtilService } from 'app/main/core/services/util.service';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { Imovel } from 'app/main/pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model';
import { TipoProcessoEnum } from 'app/main/pages/tipos-processo/tipo-processo.enum';
import { TipoProcesso } from 'app/main/pages/tipos-processo/tipo-processo.model';
import { EnumTarefaTitle, TarefaDefinitionKey } from 'app/main/shared/enums/EnumTarefaTitle';
import { EnumProcessDefinition } from 'app/main/shared/enums/process-definition.enum';
import { ObjetivoLicenciamento } from 'app/main/shared/models/objetivo-licenciamento.model';
import { Tarefa } from 'app/main/shared/models/tarefa.model';
import { LocalStorageService } from 'app/main/shared/services/local-storage.service';
import { TermoReferenciaEspecificoService } from './termo-referencia-especifico.service';


/*
#7545

Camunda - » Processos» SIGA2 - Termo de Referência Específico

Para o Imóvel Rural localizado na Aba 2 do formulário de tipo de requerimento: Estudo Impacto Ambiental (EIA/RIMA) para o objetivo de “Alteração de documentos e responsáveis”, advindo dos dados do ultimo requerimento o qual o termo de referência foi requerido (Requerimento Origem).

    Implementar as seguintes regras
    RN - Sistema não permitirá a edição e exclusão do CAR;
    RN - Sistema permitirá somente para o CAR principal que:
    Para Aba Responsáveis , o sistema deve permitir alterar, incluir e excluir responsáveis;
    Para Aba documentos complementares , o sistema deve permitir alterar documentos complementares.
*/

@Injectable({
    providedIn: 'root'
})
export class TermoReferenciaEspecificoRules {

    imovel: Imovel;
    requerimento: Requerimento;
    tarefa: Tarefa;

    constructor(private service: TermoReferenciaEspecificoService, private routingStateService: RoutingStateService,
        private localStorageService: LocalStorageService,
        private utilService: UtilService) {

    }

    public init(requerimento: Requerimento, imovel?: Imovel) {
        this.requerimento = requerimento;
        this.imovel = imovel;
    }

    /* -- acoes ---*/

    public isPermite_edicao_carPrincipal(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                resolve(this._rn_procTermoReferenciaEspecifico_app_requerimento_localizacao_ac_editar());
            } catch (e) {
                reject(e);
            }
        });
    }
    public isPermite_exclusao_carPrincipal(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                resolve(this._rn_procTermoReferenciaEspecifico_app_requerimento_localizacao_ac_remover());
            } catch (e) {
                reject(e);
            }
        });
    }
    public isPermite_edicao_responsaveis(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }
    public isPermite_exclusao_responsaveis(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }
    public isPermite_edicao_documentacaoComplementar(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }
    public isPermite_exclusao_documentacaoComplementar(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }

    /* -- rn ---*/

    private _rn_procTermoReferenciaEspecifico_app_requerimento_localizacao_ac_remover(): boolean {
        let aceito = true;
        try {
 

            if (this.requerimento.tipoProcesso.tipo == TipoProcessoEnum.ESTUDO_IMPACTO_AMBIENTAL_EIA_RIMA) { // tipo processo
                if (this.tarefa.processDefinitionKey == EnumProcessDefinition.TERMO_REFERENCIA_ESPECIFICO) { // processo camunda
                    if (this.tarefa.taskDefinitionKey == TarefaDefinitionKey.CADASTRAR_REQUERIMENTO) { // cadastro do requerimento
                        if (this.imovel.tipo == 'RURAL' && this.imovel.imovelPrincipal) {

                            if (this.localStorageService.get('requerimento_ojetivos') != null) {
                                aceito = aceito && (this.localStorageService.get('requerimento_ojetivos') as Array<ObjetivoLicenciamento>)
                                    .find((o) => UtilService.strNormalizeNFD('Alteração de documentos e responsáveis') == UtilService.strNormalizeNFD(o.descricaoObjetivo)) == null;
                            }
                            return aceito && this.requerimento.numeroProtocoloVinculado == null;
                        }
                    }
                }
            }
        } catch(e) {
            console.error(e);
        }

        return aceito;
    }
    private _rn_procTermoReferenciaEspecifico_app_requerimento_localizacao_ac_editar(): boolean {
        let aceito = true;
        try {
            if (this.requerimento.tipoProcesso.tipo == TipoProcessoEnum.ESTUDO_IMPACTO_AMBIENTAL_EIA_RIMA) { // tipo processo
                if (this.tarefa.processDefinitionKey == EnumProcessDefinition.TERMO_REFERENCIA_ESPECIFICO) { // processo camunda
                    if (this.tarefa.taskDefinitionKey == TarefaDefinitionKey.CADASTRAR_REQUERIMENTO) { // cadastro do requerimento
                        if (this.imovel.tipo == 'RURAL' && this.imovel.imovelPrincipal) {
                            if (this.localStorageService.get('requerimento_ojetivos') != null) {
                                aceito = aceito && (this.localStorageService.get('requerimento_ojetivos') as Array<ObjetivoLicenciamento>)
                                    .find((o) => UtilService.strNormalizeNFD('Alteração de documentos e responsáveis') == UtilService.strNormalizeNFD(o.descricaoObjetivo)) == null;
                            }
                        }
                    }
                }
            }
        } catch(e) {
            console.error(e);
        }
        return aceito;
    }



}