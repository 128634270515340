import {BaseModel} from 'app/main/shared/models/base.model';
import {TipoSistemaCaptacao} from './tipo-sistema-captacao.model';

export class Captacao extends BaseModel {
    constructor(
        public id?: number,
        public tipoSistema?: TipoSistemaCaptacao,
        public especificacao?: string,
    ) {
        super();
    }

    static fromJson(json: any): Captacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Captacao(
            json.id ? json.id : null,
            json.tipoSistema ? TipoSistemaCaptacao.fromJson(json.tipoSistema) : null,
            json.especificacao ? json.especificacao : null
        );
    }
}
