import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {InformacaoAnm} from "../../../../../../../../shared/models/informacao-anm.model";
import {MaskPipe} from "ngx-mask";
import {numProcessoAnmDirective} from "../../../../../../../../shared/directives/num-processo-anm.directive";

@Component({
    selector: 'app-informacao-anm',
    templateUrl: './informacao-anm.component.html',
    styleUrls: ['./informacao-anm.component.scss']
})
export class InformacaoAnmComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['numeroProcesso', 'numeroTitulo', 'dataValidade', 'areaRequerida', 'areaUtil', 'acoes'];

    tipos: DominioDto[];
    unidades: DominioDto[];

    formInformacaoANM = this.fb.group({
        id: [null],
        mineracao: [null],
        numeroProcesso: [null, [Validators.required, numProcessoAnmDirective()]],
        numeroTituloMinerario: [null, Validators.required,],
        dataValidade: [null],
        areaRequerida: [null, Validators.required,],
        areaUtil: [null, Validators.required,]
    });

    constructor(
        private fb: FormBuilder, private maskPipe: MaskPipe) {
    }

    ngOnInit() {
        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formInformacaoANM.disable();
        }
    }

    adicionar() {
        if (!this.formInformacaoANM.valid) return;

        const informacaoANM = this.form.get('informacaoANM');

        informacaoANM.setValue([
            ...(informacaoANM.value || []),
            InformacaoAnm.fromJson(this.formInformacaoANM.value)
        ]);

        this.formInformacaoANM.reset();
    }

    excluir(item: InformacaoAnm) {
        const estruturas: AbstractControl = this.form.get('informacaoANM');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: InformacaoAnm) {
        this.formInformacaoANM.patchValue(item);

        this.excluir(item);
    }

}
