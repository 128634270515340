import {BaseModel} from 'app/main/shared/models/base.model';

export class AreaDeConfinamento extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public numeroPiquetes?: number,
        public numeroAnimaisConfinados?: number,
        public mesInicio?: string,
        public mesFinal?: string,
    ) {
        super();
    }

    static fromJson(json: any): AreaDeConfinamento {
        json = json || {};

        return new AreaDeConfinamento(
            json.id,
            json.idGeometria,
            json.numeroPiquetes,
            json.numeroAnimaisConfinados,
            json.mesInicio,
            json.mesFinal,
        );
    }
}