import {Component, OnInit} from '@angular/core';
import {RequerimentoService} from "../../../../../requerimento.service";
import {RequerimentoLicenciamentoService} from "../../../../requerimento-licenciamento.service";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";

@Component({
    selector: 'app-tela-teste-documentos',
    templateUrl: './tela-teste-documentos.component.html',
    styleUrls: ['./tela-teste-documentos.component.scss']
})
export class TelaTesteDocumentosComponent implements OnInit {
    //TODO Tela teste para gerar licenças suimis para a equipe de testes

    // idReq: any;
    constructor(private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
                private snackBarService: SnackBarService) {
    }

    ngOnInit() {
    }

    baixarPdf(tipo: any) {
        this.requerimentoLicenciamentoService.downloadLicencaSuimisTeste(506127, tipo).subscribe(response => {
            const blobGenerate = new Blob([response], {type: response.type});
            const downloadURL = window.URL.createObjectURL(blobGenerate);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = 'Licença-' + tipo +'-suimis-teste' + '.pdf';
            link.click();
        }, error => this.snackBarService.showError("Ocorreu um erro."))
    }

}
