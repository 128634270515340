import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {AnaliseRequerimentoEquipe} from '../model/analise-requerimento-equipe.model';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AnaliseRequerimentoAnalista} from '../model/analise-requerimento-analista.model';
import {HttpParams} from '@angular/common/http';
import {Despacho} from '../../../../shared/components/despacho-cabecalho/despacho.model';
import {AtribuicaoAceitarRecusarDto} from '../../analista-aceitar-atribuicao/atribuicao-aceitar-recusar-dto.model';
import {AnalistaTarefasDTO} from '../model/dto/analista-tarefas-dto.model';
import {AnaliseRequerimento} from '../model/analise-requerimento.model';
import {AnaliseRequerimentoComAnalista} from '../../requerimento-atribuir/dialogs/detalhes-analista-dialog/analise-requerimento-com-analista.model';
import {Page} from "../../../../shared/models/page.model";

@Injectable({
    providedIn: 'root',
})
export class DespachoService extends BaseService<Despacho> {
    constructor(protected injector: Injector) {
        super(
            '/api/despachos',
            injector,
            Despacho.prototype,
            Despacho.fromJson
        );
    }

    /**
     * Lista os despachos de um requerimento.
     * @param idRequerimento {number} - ID do requerimento.
     * @param httpParams {HttpParams} - Parametros para a listagem.
     */
    getDespachoByIdRequerimento(idRequerimento: number, httpParams: HttpParams): Observable<Page<Despacho>> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );

    }

    /**
     * Retorna o rascunho do despacho da tarefa.
     * @param idRequerimento {number} - ID do requerimento interessado.
     * @param nomeTarefa {string} - Tarefa interessada.
     */
    getDespachoDaTarefa(idRequerimento: number, nomeTarefa: string): Observable<Despacho> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/tarefa/${nomeTarefa}`;
        return this.http.get(url).pipe(map(Despacho.fromJson.bind(this)), catchError(this.handleError.bind(this)));
    }
}