import {Component} from '@angular/core';

@Component({
    selector: 'app-overlay-info-captacao',
    templateUrl: './overlay-info-captacao.component.html',
    styleUrls: ['./overlay-info-captacao.component.scss']
})
export class OverlayInfoCaptacaoComponent {

    constructor() {
    }

}
