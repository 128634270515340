import {CriacaoAnimal} from './../models/criacao-animal.model';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Rebanho} from '../models/rebanho.model';

@Injectable({
    providedIn: 'root'
})
export class RebanhoService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/rebanhos';
    }

    getAllByCriacaoAnimal(criacaoAnimal: CriacaoAnimal): Observable<Rebanho[]> {
        const url = `${this.urlResource}/criacao-animal/${criacaoAnimal.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    create(rebanho: Rebanho): Observable<Rebanho> {
        return this.http
            .post(this.urlResource, rebanho)
            .pipe(
                map(this.jsonDataToResource),
                catchError(this.handleError.bind(this))
            );
    }

    delete(rebanho: Rebanho): Observable<any> {
        const url = `${this.urlResource}/${rebanho.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonDataToResourceList(jsonData: any[]): Rebanho[] {
        const entries: Rebanho[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new Rebanho(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

    private jsonDataToResource(jsonData: any): Rebanho {
        return Object.assign(new Rebanho(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
