import {BaseModel} from 'app/main/shared/models/base.model';

export class Mineracao extends BaseModel {
    constructor(
        public id?: number,
        public extracaoLeitoRio?: boolean,
        public desmonteHidraulico?: boolean,
        public escavacaoMeioEncosta?: boolean,
        public escavacaoPlanice?: boolean,
        public desmonteExplosivo?: boolean,
        public outros?: boolean,
        public especifiqueTipologia?: string,
        public horas?: number,
        public dias?: number,
        public mes?: number,
        public consumoPerCaptaDiario?: number,
        public numeroFuncionarios?: number,
        public consumoDiarioIndustria?: number,
        public vazaoUsoDomestico?: number,
        public vazaoExploracaoMinerio?: number,
        public vazaoUsoBeneficiamento?: number,
        public tipoEquipamento?: string,
        public aguaReutilizada?: boolean
    ) {
        super();
    }

    static fromJson(json: any): Mineracao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Mineracao(
            json.id,
            json.extracaoLeitoRio,
            json.desmonteHidraulico,
            json.escavacaoMeioEncosta,
            json.escavacaoPlanice,
            json.desmonteExplosivo,
            json.outros,
            json.especifiqueTipologia,
            json.horas,
            json.dias,
            json.mes,
            json.consumoPerCaptaDiario,
            json.numeroFuncionarios,
            json.consumoDiarioIndustria,
            json.vazaoUsoDomestico,
            json.vazaoExploracaoMinerio,
            json.vazaoUsoBeneficiamento,
            json.tipoEquipamento,
            json.aguaReutilizada
        );
    }
}
