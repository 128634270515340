import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {PrevisaoVolumesProducao} from "../../../../../../../../shared/models/previsao-volumes-producao.model";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";

@Component({
    selector: 'app-previsao-volumes-producao',
    templateUrl: './previsao-volumes-producao.component.html',
    styleUrls: ['./previsao-volumes-producao.component.scss']
})
export class PrevisaoVolumesProducaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['produto', 'quantidade', 'acoes'];

    formPrevisaoVolumesProducao = this.fb.group({
        id: [null],
        mineracao: [null],
        produto: [null, Validators.required,],
        quantidade: [null, Validators.required,],
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formPrevisaoVolumesProducao.disable();
        }
    }

    adicionar() {
        if (!this.formPrevisaoVolumesProducao.valid) return;

        const previsaoVolumeProducao = this.form.get('previsaoVolumeProducao');

        previsaoVolumeProducao.setValue([
            ...(previsaoVolumeProducao.value || []),
            PrevisaoVolumesProducao.fromJson(this.formPrevisaoVolumesProducao.value)
        ]);

        this.formPrevisaoVolumesProducao.reset();
    }

    excluir(item: PrevisaoVolumesProducao) {
        const estruturas: AbstractControl = this.form.get('previsaoVolumeProducao');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: PrevisaoVolumesProducao) {
        this.formPrevisaoVolumesProducao.patchValue(item);

        this.excluir(item);
    }

}
