import {BaseModel} from "./base.model";
import {RotasTransporteResiduosPerigosos} from "./rotas-transporte-residuos-perigosos.model";
import {RodoviasTransporteResiduosPerigosos} from "./rodovias-transporte-residuos-perigosos.model";

export class RotasRodoviasDto extends BaseModel {
    constructor(
        public rotasTransporteResiduosPerigosos?: RotasTransporteResiduosPerigosos,
        public listaRodovias?: RodoviasTransporteResiduosPerigosos[]
    ) {
        super();
    }

    static fromJson(json: any): RotasRodoviasDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new RotasRodoviasDto(
            RotasTransporteResiduosPerigosos.fromJson(json.rotasTransporteResiduosPerigosos),
            RodoviasTransporteResiduosPerigosos.fromJsons(json.listaRodovias)
        );
    }

    static fromJsons(json: any): RotasRodoviasDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(RotasRodoviasDto.fromJson(j)));
            return resources;
        }
    }
}