import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
    templateUrl: './confirmar-adicao-requerimento-finalidade-atividade-dialog.component.html'
})
export class ConfirmarAdicaoRequerimentoFinalidadeAtividadeDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarAdicaoRequerimentoFinalidadeAtividadeDialogComponent>
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
