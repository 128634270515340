import {Injectable, Injector} from '@angular/core';
import {TipoCorpoHidrico} from '../models/tipo-corpo-hidrico.model';
import {CachedBaseService} from './cached-base.service';

@Injectable({
    providedIn: 'root'
})
export class TipoCorpoHidricoService extends CachedBaseService<TipoCorpoHidrico> {

    constructor(injector: Injector) {
        super('/api/tipos-corpo-hidrico', injector, TipoCorpoHidrico.fromJson);
    }

}
