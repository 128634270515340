import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {MetodoSistemaIrrigacao} from "../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-irrigacao/metodo-sistema-irrigacao.model";

@Injectable({
    providedIn: 'root'
})
export class MetodoSistemaIrrigacaoService {

    private urlResource = `${environment.URL_GATEWAY}/api/metodo-sistema-irrigacao`;

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<MetodoSistemaIrrigacao[]> {
        return this.http.get(this.urlResource)
            .pipe(
                map((json: []) => this.jsonToResources(json)),
                catchError(e => throwError(e))
            );
    }


    private jsonToResources(json: any): MetodoSistemaIrrigacao[] {
        const resources = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: any) => resources.push(MetodoSistemaIrrigacao.fromJson(e)));
        }
        return resources;
    }

}
