import { BaseModel } from "app/main/shared/models/base.model";

export enum EnumTipoFinalidadePlantio {
    BIOMASSA_ENERGETICA = 'BIOMASSA_ENERGETICA',
    INDUSTRIA_MADEIREIRA = 'INDUSTRIA_MADEIREIRA'
}

export class DominioFinalidadePlantio extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipo?: EnumTipoFinalidadePlantio
    ) {
        super();
    }

    static fromJson(json: any): DominioFinalidadePlantio {
        if (json === undefined || json === null) {
            return null;
        }
        return new DominioFinalidadePlantio(
            json.id ? json.id : null,
            json.descricao ? json.descricao : null,
            json.tipo ? json.tipo : null
        );
    }

    static fromJsons(jsonArray: any): DominioFinalidadePlantio[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(DominioFinalidadePlantio.fromJson(resource)));
            return resources;
        }
    }
}