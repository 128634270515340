import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Requerimento} from "../../requerimento.model";
import {Tarefa} from "../../../../shared/models/tarefa.model";
import {PrioridadeProcessoService} from "../../../../shared/services/prioridade-processo.service";
import {take} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {createRequestOptions} from "../../../../core/util/request-util";
import {PageEvent} from "@angular/material/paginator/typings/paginator";
import {HistoricoPrioridadeProcessoDTO} from "../model/historico-prioridade-processo.dto";
import {DominiosService} from "../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../shared/models/dominio-dto.model";

@Component({
    templateUrl: 'dialog-historico-priorizacao.component.html',
    styleUrls: ['dialog-historico-priorizacao.component.scss'],
})
export class DialogHistoricoPriorizacaoComponent implements OnInit {

    totalElements = 0;
    prioridades: HistoricoPrioridadeProcessoDTO[] = [];
    requerimento: Requerimento;
    listaCriterios: DominioDto[] = [];
    tarefas: Tarefa[] = [];
    displayedColumns: string[] = ['data', 'cpf', 'nome', 'prioridade', 'justificativa', 'acoes'];

    constructor(
        private readonly dominiosService: DominiosService,
        private dialogRef: MatDialogRef<DialogHistoricoPriorizacaoComponent>,
        private readonly prioridadeProcessoService: PrioridadeProcessoService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.requerimento = data.requerimento;
    }

    async ngOnInit(): Promise<void> {
        this.listar({
            pageIndex: 0,
            pageSize: 5,
            length: 0
        });

        this.listaCriterios = await this.dominiosService.getDominio('TIPO_PRIORIDADE_PROCESSO').toPromise();
    }

    listar(page: PageEvent): void {
        let httpParams: HttpParams = createRequestOptions({
            page: String(page.pageIndex),
            size: String(page.pageSize),
        });

        this.prioridadeProcessoService.getByHistoricoRequerimentoId(this.requerimento.id, httpParams).pipe(
            take(1)
        ).subscribe(prioridades => {
            this.totalElements = prioridades.totalElements;
            this.prioridades = prioridades.content;
        });
    }

    public closeDialog(): void {
        this.dialogRef.close(false);
    }

    public closeRedirectDialog(): void {
        this.dialogRef.close(true);
    }

    getPrioridades(prioridade: number[]): string {
        if (prioridade) {
            return prioridade.map(x => this.listaCriterios.find(d => d.id == x)).filter(x => !!x).map(d => d.descricao).join(', ');
        }

        return "";
    }
}