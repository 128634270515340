import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { TemplateDTO } from './shared/template-dto.model';
import { EmissaoTituloService } from './shared/emissao-titulo.service';
import { MatDialog } from '@angular/material';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { SnackBarService } from '../../snack-bar/snack-bar.service';
import { ParecerRequerimentoConclusaoService } from './shared/parecer-requerimento-conclusao.service';
import { EmissaoTitulo } from '../../models/emissao-titulo.model';
import { ParecerRequerimentoConclusao } from 'app/main/pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.model';
import { TemplatesEmissaoTitulosDialogComponent } from './templates-emissao-titulos-dialog/templates-emissao-titulos-dialog.component';

@Component({
  selector: 'app-templates-emissao-titulos',
  templateUrl: './templates-emissao-titulos.component.html',
  styleUrls: ['./templates-emissao-titulos.component.scss']
})
export class TemplatesEmissaoTitulosComponent implements OnInit {

    @Input() emissaoTitulo: EmissaoTitulo;
    @Input() requerimento: Requerimento;
    @Input() deletedEmitter = new EventEmitter();
    @Output() acaoUpdateEmitter = new EventEmitter();
    templatesVinculados: TemplateDTO[] = []
    

    constructor(
        injector: Injector,
        private dialog: MatDialog,
        private parecerRequerimentoConclusaoService: ParecerRequerimentoConclusaoService,
        private emissaoTituloService: EmissaoTituloService,
        private snackBarService: SnackBarService
    ) {

    }

    ngOnInit(): void {
        this.carregarTemplatesVinculados();

        this.acaoUpdateEmitter.subscribe(conclusao => {
            this.carregarTemplatesVinculados();
        });
        
    }

    carregarTemplatesVinculados() {
        this.emissaoTituloService
            .getByEmissaoTituloId(this.emissaoTitulo.id).subscribe(emissaoTitulo => {
                this.templatesVinculados = [];
                this.templatesVinculados.push(emissaoTitulo.parecerRequerimentoConclusao.template);
                this.templatesVinculados.push(emissaoTitulo.parecerRequerimentoConclusao.templatePublicacao);
                // this.templatesVinculados.push(this.addTemplateTitulo(emissaoTitulo.parecerRequerimentoConclusao));
                // this.templatesVinculados.push(this.addTemplatePublicacao(emissaoTitulo.parecerRequerimentoConclusao));
            });
            console.log("this.templatesVinculados: ", this.templatesVinculados);            
    }

    alterarTemplate(template: TemplateDTO): void {
        const dialogRef = this.dialog.open(TemplatesEmissaoTitulosDialogComponent, {
            data: {
                emissaoTitulo: this.emissaoTitulo,
                template: template,
                requerimentoTitulacao: this.emissaoTitulo.requerimentoTitulacao,
            },
            width: '550px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                //this.acaoUpdateEmitter.emit(result);
                // Desabilitar o RequerimentoTitulacao adicionado
                this.carregarTemplatesVinculados();
            }
        });
    }

    // addTemplateTitulo(parecerRequerimentoConclusao: ParecerRequerimentoConclusao) {
    //     let templateTitulo = new TemplateDTO();
    //     templateTitulo.id = parecerRequerimentoConclusao.template.id;
    //     templateTitulo.nomeTemplate = parecerRequerimentoConclusao.template.nomeTemplate;
    //     templateTitulo.descricao = parecerRequerimentoConclusao.template.descricao;
    //     templateTitulo.dataInicio = parecerRequerimentoConclusao.template.dataInicio;
    //     templateTitulo.dataFim = parecerRequerimentoConclusao.template.dataFim;
    //     templateTitulo.tipoTemplate = parecerRequerimentoConclusao.template.tipoTemplate;
    //     templateTitulo.tipoTitulo = parecerRequerimentoConclusao.template.tipoTitulo;
    //     templateTitulo.fluxoProcesso =  parecerRequerimentoConclusao.template.fluxoProcesso;
    //     return templateTitulo;
    // }

    // addTemplatePublicacao(parecerRequerimentoConclusao: ParecerRequerimentoConclusao) {
    //     let templatePublicacao = new TemplateDTO();
    //     templatePublicacao.id = parecerRequerimentoConclusao.templatePublicacao.id;
    //     templatePublicacao.nomeTemplate = parecerRequerimentoConclusao.templatePublicacao.nomeTemplate;
    //     templatePublicacao.descricao = parecerRequerimentoConclusao.templatePublicacao.descricao;
    //     templatePublicacao.dataInicio = parecerRequerimentoConclusao.templatePublicacao.dataInicio;
    //     templatePublicacao.dataFim = parecerRequerimentoConclusao.templatePublicacao.dataFim;
    //     templatePublicacao.tipoTemplate = parecerRequerimentoConclusao.templatePublicacao.tipoTemplate;
    //     templatePublicacao.tipoTitulo = parecerRequerimentoConclusao.templatePublicacao.tipoTitulo;
    //     templatePublicacao.fluxoProcesso =  parecerRequerimentoConclusao.templatePublicacao.fluxoProcesso;
    //     return templatePublicacao;
    // }

}
