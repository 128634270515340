import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {BitolaTipoCondutor} from 'app/main/shared/models/bitola-tipo-condutor-linha-transmissao.model';
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
    selector: 'app-atv-ee-lin-trans-bitola-tipo-condutor',
    templateUrl: './atv-ee-lin-trans-bitola-tipo-condutor.component.html',
    styleUrls: ['./atv-ee-lin-trans-bitola-tipo-condutor.component.scss']
})
export class AtvEeLinTransBitolaTipoCondutorComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'bitola', 'secao', 'acoes'];

    formBitola: FormGroup;

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.prepareFormEstrutura();
        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formBitola.disable();
        }
    }

    adicionar() {
        if (!this.formBitola.valid) return;

        const estruturas = this.form.get('bitolas');

        estruturas.setValue([
            ...(estruturas.value || []),
            BitolaTipoCondutor.fromJson(this.formBitola.value)
        ]);

        this.prepareFormEstrutura();
    }

    excluir(item: BitolaTipoCondutor) {
        const estruturas: AbstractControl = this.form.get('bitolas');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: BitolaTipoCondutor) {
        this.formBitola.patchValue(item);

        this.excluir(item);
    }

    private prepareFormEstrutura() {
        this.formBitola = this.fb.group({
            id: [null],
            idEntidade: [null],
            idDominio: [null],
            tipo: [null, [Validators.required, Validators.maxLength(50)]],
            bitola: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999), Validators.maxLength(20)]],
            secao: [null, [Validators.required, Validators.min(0), Validators.max(99999999999999999999), Validators.maxLength(20)]]
        });
    }

}

