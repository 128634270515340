import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {CircuitoLinhaTransmissao} from "../../../../../../../../shared/models/circuito-linha-transmissao.model";

@Component({
    selector: 'app-atv-ee-lin-trans-circuito',
    templateUrl: './atv-ee-lin-trans-circuito.component.html',
    styleUrls: ['./atv-ee-lin-trans-circuito.component.scss']
})
export class AtvEeLinTransCircuitoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'descricao', 'acoes'];

    tipos: DominioDto[];

    formCircuito: FormGroup;

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-circuito-ee-lin-trans')
            .subscribe(doms => this.tipos = doms);

        this.prepareFormEstrutura();
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.formCircuito.disable();
        }
    }

    getDescricaoTipo(id: number){
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formCircuito.valid) return;

        const estruturas = this.form.get('circuitos');

        estruturas.setValue([
            ...(estruturas.value || []),
            CircuitoLinhaTransmissao.fromJson(this.formCircuito.value)
        ]);

        this.prepareFormEstrutura();
    }

    excluir(item: CircuitoLinhaTransmissao) {
        const estruturas: AbstractControl = this.form.get('circuitos');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: CircuitoLinhaTransmissao) {
        this.formCircuito.patchValue(item);

        this.excluir(item);
    }

    private prepareFormEstrutura() {
        this.formCircuito = this.fb.group({
            id: [null],
            idEntidade: [null],
            idDominio: [null, Validators.required,],
        });
        
        this.formCircuito.get('idDominio').valueChanges.subscribe(value => {
            if (this.getDescricaoTipo(value) === 'Outro') {
                if (!this.formCircuito.get('descricao')) {
                    this.formCircuito.addControl('descricao', this.fb.control(null, [Validators.required]));
                }
            } else if (!!this.formCircuito.get('descricao')) {
                this.formCircuito.removeControl('descricao');
            }
        });
    }

}
