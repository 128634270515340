import {BaseModel} from 'app/main/shared/models/base.model';

export class Industria extends BaseModel {
    constructor(
        public id?: number,
        public horas?: number,
        public dias?: number,
        public meses?: number,
        public consumoPerCaptaDiario?: number,
        public numeroFuncionarios?: number,
        public consumoDiarioIndustria?: number,
        public vazaoUsoDomestico?: number
    ) {
        super();
    }

    static fromJson(json: any): Industria {
        if (json === undefined || json === null) {
            return null;
        }
        return new Industria(
            json.id,
            json.horas,
            json.dias,
            json.meses,
            json.consumoPerCaptaDiario,
            json.numeroFuncionarios,
            json.consumoDiarioIndustria,
            json.vazaoUsoDomestico);
    }
}
