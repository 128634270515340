import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EmissaoTitulo} from '../../../../shared/models/emissao-titulo.model';
import {DocumentoProcessoSignatario} from '../../../../shared/models/documento-processo-signatario.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentoProcessoSignatarioService extends BaseService<DocumentoProcessoSignatario> {

    constructor(protected injector: Injector) {
        super('/api/documento-processo-signatario', injector, DocumentoProcessoSignatario.prototype, DocumentoProcessoSignatario.fromJson);
    }

    finalizarTarefaCamunda(idRequerimento: number, idTareda: string, emissaoTitulo: EmissaoTitulo): Observable<any> {
        const url = `${this.urlResource}/concluir-tarefa-termo-adessao/${idRequerimento}/${idTareda}`;
        return this.http.post(url, emissaoTitulo).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    criarEmissaoLicenca(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/criar-emissao-licenca/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    listaDeDocumentosAssinados(id?: number): Observable<DocumentoProcessoSignatario[]> {
        const url = `${this.urlResource}/lista-documentos/${id}`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }
}
