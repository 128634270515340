import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Injector,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmacaoDialogComponent } from 'app/main/pages/parecer-tecnico/parecer-tecnico-conclusoes/parecer-tecnico-conclusoes-dialog/confirmacao-dialog/confirmacao-dialog.component';

import { RequerimentoFormularioLicenciamentoService } from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service';
import { RequerimentoLicenciamento } from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model';
import { RequerimentoLicenciamentoService } from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.service';
import { EeHidricaService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-hidrica/ee-hidrica.service';
import { EmpreendimentoEnergeticoTermicaService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-termica/empreendimento-energetico-termica.service';
import { InfraestruturaObrasViariasService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infraestrutura-obras-viarias/infraestrutura-obras-viarias.service';
import { ResiduoGeralService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-geral/residuo-geral.service';
import { ResiduosTransporteProdutosPerigososService } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-transporte-produtos-perigosos/residuos-transporte-produtos-perigosos.service';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { RequerimentoService } from 'app/main/pages/requerimentos/requerimento.service';
import { ErrosAbaRequerimento } from 'app/main/pages/requerimentos/shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { AtividadeCalculoArrecadacao } from 'app/main/shared/models/arrecadacao-models/atividade-calculo-arrecadacao.model';
import { FormularioReqLicenciamentoEmp } from 'app/main/shared/models/formulario-req-licenciamento-emp.dto';
import { LancamentoTaxaDto } from 'app/main/shared/models/lancamento-taxa-dto.model';
import { AguaPassivoSupressaoService } from 'app/main/shared/services/agua-passivo-supressao.service';
import { AtividadeArrecadacaoService } from 'app/main/shared/services/arrecadacao-service/atividade-arrecadacao.service';
import { AtividadeCalculoArrecadacaoService } from 'app/main/shared/services/arrecadacao-service/atividade-calculo-arrecadacao.service';
import { AtividadeParametroEntradaArrecadacaoService } from 'app/main/shared/services/arrecadacao-service/atividade-parametro-entrada-arrecadacao.service';
import { AtividadeMineracaoService } from 'app/main/shared/services/atividade-mineracao.service';
import { EeLinhaTransmissaoService } from 'app/main/shared/services/ee-linha-transmissao.service';
import { EmpreendimentoCemiterioCrematorioService } from 'app/main/shared/services/empreendimento-cemiterio-crematorio.service';
import { EmpreendimentoEnergeticoService } from 'app/main/shared/services/empreendimento-energetico.service';
import { EmpreendimentoEnergiaFotovoltaicaService } from 'app/main/shared/services/empreendimento-energia-fotovoltaica.service';
import { EmpreendimentoGerenciamentoResiduosService } from 'app/main/shared/services/empreendimento-gerenciamento-residuos.service';
import { EmpreendimentoInfraParcelSoloResComService } from 'app/main/shared/services/empreendimento-infra-parcel-solo-res-com.service';
import { EmpreendimentoService } from 'app/main/shared/services/empreendimento.service';
import { IndustriaBiocombustivelService } from 'app/main/shared/services/industria-biocombustivel.service';
import { IndustriaFrigorificoService } from 'app/main/shared/services/industria-frigorifico.service';
import { InfraObraFluvSaneamService } from 'app/main/shared/services/infra-obra-fluv-saneam.service';
import { AtividadeParametroLicenciamentoService } from 'app/main/shared/services/licenciamento-config-service/atividade-parametro-licenciamento.service';
import { MadeiraService } from 'app/main/shared/services/madeira.service';
import { ObjetivoLicenciamentoSubatividadeService } from 'app/main/shared/services/objetivo-licenciamento-subatividade.service';
import { ResiduoAterroIndUrbService } from 'app/main/shared/services/residuo-aterro-ind-urb.service';
import { ResiduoRccService } from 'app/main/shared/services/residuo-rcc.service';
import { ResiduoTratamentoTermicoService } from 'app/main/shared/services/residuo-tratamento-termico.service';
import { ServicoCombustivelService } from 'app/main/shared/services/servico-combustivel.service';
import { ServicoGeralService } from 'app/main/shared/services/servico-geral.service';
import { SistemaTratamentoService } from 'app/main/shared/services/sistema-tratamento.service';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { environment } from 'environments/environment';
import { forkJoin } from 'rxjs';
import { RequerimentoEmpreendimentoAtividadeFormulario } from './requerimento-empreendimento-atividade-formulario';
import {ObjetivoLicenciamentoService} from "../../../../../shared/services/objetivo-licenciamento.service";
import {ObjetivoLicenciamento} from "../../../../../shared/models/objetivo-licenciamento.model";
import {ObjetivoParametrizacaoObjetivoService} from "../../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service";


@Component({
    selector: 'app-lic-amb-shared-aba-atividades-lic-emp',
    templateUrl: './aba-atividades-lic-emp.component.html',
    styleUrls: ['./aba-atividades-lic-emp.component.scss']
})
export class LicAmbAbaAtividadesLicEmpSharedComponent implements OnInit {

    @ViewChild("valorParametro", {static: false}) valorParametro: ElementRef;

    
    private _requerimento: Requerimento;

    @Input()
    public get requerimento(): Requerimento {
        return this._requerimento;
    }
    public set requerimento(value: Requerimento) {
        this._requerimento = value;
        this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id)
                .subscribe((rl) => this.requerimentoLicenciamento = rl);
    }
    
    requerimentoLicenciamento: RequerimentoLicenciamento;

    @Input()
    requerimentoLicenciamentoVinculado: RequerimentoLicenciamento;

    @Input()
    isSomenteVisualizacao: boolean = true;

    formsConcluidos: any[] = [];
    requerimentoEmpreendimentoAtividade: any[] = [];

    objetivoSubAtividade: any;
    atividadeComplementar: any;
    subAtividade: any;
    
    
    atividadeLicenSelecionado: any
    atividade: any
    atividadeAut: any
    isAtividadeComplementar = true
    atividadesGrp = [];
    subGrupo = [];
    grupoSubGrupoSelecionadaTable = [];
    atividadeLicenciada = [];
    atividadeLicenciadaEncontrado = [];
    atividadeLicenciadaSubGrupoEncontrado = [];
    atividadeCompLicenciadaEncontrado = [];
    atividadeSelecionadaTable = [];
    displayedAtividadeLicenColumns: string[] = ['atividade', 'ppd', 'acao'];
    displayedGrpSubGrpAtividadeLicenColumns: string[] = ['subGrupo', 'atividade', 'ppd', 'acao'];


    parametroEntradas: any;
    parametroEntradasAut: any;
    errorStatusCalculoParametroEntrada: any;
    atividadeCalculo: AtividadeCalculoArrecadacao[];
    atividadeCalculoAut: AtividadeCalculoArrecadacao[];
    parametroCalculoList: any;
    parametroCalculoListAut: any;
    parametrosCalculoLista: any = [];
    parametrosCalculoListaAut: any = [];
    atividadeSemParametroeCaculo = false;
    atividadeSemParametroeCaculoAut = false;
    parametroEntrada: any = {}
    parametroEntradaAut: any = {}
    pisiculturaExotico = false
    regraApp = false
    calcularUpfRetornoDtoList = []
    calcularUpfRetornoDtoListAut = []
    habilitaAtividadeLicenciada = true
    termoReferenciaEiaRima = false
    licencaOperacaoVinculada = false
    lpTramitacao = false
    referenciaTitulo = true;
    mostrarOutraObjetivos = true;
    emissaoRefTitLegado = false;
    loVinLegado = false;
    erroDeCalculo = false
    edicaoAtvLicenciada = true
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoEmp;
    formularioReqLicenciamentoVinculadoDto: FormularioReqLicenciamentoEmp;
    element: ElementRef;
    arrayRegraLI = []
    numeroTituloReferente: any;
    numeroTermoReferente: any;
    numeroLO: any;
    objetivoValidado = false
    objsIsento = false
    atividadeDesc: string;
    atividadeSubGrupoDesc: string;
    atividadeAtvCompDesc: string;
    popUp = false;

    lancamentoTaxa: LancamentoTaxaDto;
    lancamentoTaxaParam: any[] = [];

    objetivosLicenciamento: ObjetivoLicenciamento[];
    parametrizacaoDoObjetivoPrincipal: any;

    @ViewChildren('formularioContainer', { read: ViewContainerRef }) formularioContainer: QueryList<ViewContainerRef>;

    @Input()
    public isSomenteLeitura: boolean = false;


    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        injector: Injector,
        private snackBarService: SnackBarService,
        protected dialog: MatDialog,
        private requerimentoService: RequerimentoService,
        private atividadeParametroEntradaArrecadacaoService: AtividadeParametroEntradaArrecadacaoService,
        private atividadeCalculoArrecadacaoService: AtividadeCalculoArrecadacaoService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private atividadeParamLicenciamentoService: AtividadeParametroLicenciamentoService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private objetivoLicenciamentoSubatividadeService: ObjetivoLicenciamentoSubatividadeService,
        private arrecadacaoService: AtividadeArrecadacaoService,
        private mineracaoService: AtividadeMineracaoService,
        private residuoAterroIndUrbService: ResiduoAterroIndUrbService,
        private industriaFrigorificoService: IndustriaFrigorificoService,
        private empreendimentoCemiterioCrematorioService: EmpreendimentoCemiterioCrematorioService,
        private servicoCombustivelService: ServicoCombustivelService,
        private industriaBiocombustivelService: IndustriaBiocombustivelService,
        private residuosRccService: ResiduoRccService,
        private empreendimentoGerenciamentoResiduosService: EmpreendimentoGerenciamentoResiduosService,
        private residuoTratamentoTermicoService: ResiduoTratamentoTermicoService,
        private transporteProdutosPerigososService: ResiduosTransporteProdutosPerigososService,
        private residuoGeralService: ResiduoGeralService,
        private aguaPassivoSupressaoService: AguaPassivoSupressaoService,
        private sistemaTratamentoService: SistemaTratamentoService,
        private madeiraService: MadeiraService,
        private empreendimentoInfraParcelSoloResComService: EmpreendimentoInfraParcelSoloResComService,
        private infraObrasViariasService: InfraestruturaObrasViariasService,
        private eeHidricaService: EeHidricaService,
        private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService,
        private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService,
        private eeLinhaTransmissaoService: EeLinhaTransmissaoService,
        private empreendimentoSubestacaoService: EmpreendimentoService,
        private empreendimentoEnergiaEolicaService: EmpreendimentoEnergeticoService,
        private infraObraFluvSaneamService: InfraObraFluvSaneamService,
        private requerimentoFormLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private servicoGeralService: ServicoGeralService,
        private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
    ) {
        
    }

    async ngOnInit() {
        if (this.requerimento.id) {
            this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();

            this.objetivosLicenciamento =
                await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();
            //Filtrando objetivos principais (1 = PRINCIPAL)
            let objetivosPrincipaisDoRequerimento = this.objetivosLicenciamento.filter(obj => obj.idTipoObjetivo == 1)
            let objsParametrizados = await this.objetivoParametrizacaoObjetivoService.getByParametrizacaoObjetivo(this.requerimento.idParametroObjetivo).toPromise();

            //TODO Pegando o primeiro do array enquanto a regra a respeito de qual objetivo principal tem maior prioridade é descutida.
            this.parametrizacaoDoObjetivoPrincipal = objsParametrizados.filter(obj => obj.idTipoRequerimento == this.requerimento.tipoRequerimento.id &&
                obj.idObjetivoParametrizacao == objetivosPrincipaisDoRequerimento[0].idObjetivoLicenciamentoAmbiental)
            await this.carregarFormulariosAtividades();
        }

        await forkJoin([
            this.carregarFormulariosAtividades(),
            this.carregarAtividades(), 
            this.buscarFormularioRequerimentoLicenciamentoEmp()]
        ).toPromise();

        if (this.requerimento.tipoProcesso.instanciaRequerimento === "LICENCIAMENTO_TRIFASICO" ||
            this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" ||
            this.requerimento.tipoProcesso.instanciaRequerimento === "TERMO_REFERENCIA") {
            // await this.getObjetivosApresentados()
            if (this.requerimento.idTipoRequerimentoTifLas != 2) {
                if (this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" && this.requerimento.numeroProtocoloVinculado ||
                    this.requerimento.tipoProcesso.instanciaRequerimento === "TERMO_REFERENCIA" && this.requerimento.numeroProtocoloVinculado) {
                    await this.criaOuBuscaReqLicenciamentoEmAndamento()
                } else {
                    await this.criaOuBuscaReqLicenciamento()
                }
            } else {
                await this.criaOuBuscaReqLicenciamentoEmAndamento()
            }
        }


    }

    private async criaOuBuscaReqLicenciamentoEmAndamento() {
        this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).subscribe(async resultado => {
                if (resultado)
                    await this.preencheDadosFormReqLicenVinculo();
        })
    }

    private async criaOuBuscaReqLicenciamento() {
        this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).subscribe(async resultado => {
                await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise().then(async formulReq => {
                    this.formularioReqLicenciamentoDto = formulReq
                }).catch(() => {
                    this.snackBarService.showError('Ocorreu um erro ao processar dados do requerimento.');
                })
                this.requerimentoLicenciamento = this.formularioReqLicenciamentoDto.requerimentoLicenciamento
                // await this.preencheTabelaObjetivo(true)
                if (this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS") {
                    if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                        await this.preencherParametrosDeEntradaECalculo()
                    }
                }
                if (this.requerimentoLicenciamento.idAtividade) {
                    this.preencheTabelaAtividadeLicenciada()
                    await this.obterAtividadeCalculoParametroEntrada(true)
                    await this.preencherAtividadeComplementar()
                    if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                        await this.preencherParametrosDeEntradaECalculo()
                    }
                }
            
        })
    }



    private async preencheDadosFormReqLicenVinculo() {
        this.formularioReqLicenciamentoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise()
        this.requerimentoLicenciamento = this.formularioReqLicenciamentoDto.requerimentoLicenciamento
        let requerimentoVinculado = await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise()
        this.formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(requerimentoVinculado[0].id).toPromise()
        this.requerimentoLicenciamentoVinculado = this.formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
        
        // await this.preencheTabelaObjetivo(true)
        
        
        if (this.requerimentoLicenciamento.idAtividade) {
            this.preencheTabelaAtividadeLicenciada()
            await this.obterAtividadeCalculoParametroEntrada(true)
            await this.preencherAtividadeComplementar()
            if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                await this.preencherParametrosDeEntradaECalculo()
            }
        }
    }


    private preencheTabelaAtividadeLicenciada() {
        this.atividadeSelecionadaTable.push(this.atividadeLicenciada.find(atvLic => atvLic.atividade.id === this.requerimentoLicenciamento.idAtividade))
        this.atividadeSelecionadaTable = this.atividadeSelecionadaTable.slice()
        this.atividade = this.atividadeSelecionadaTable[0].atividade
        this.subGrupo = this.atividadeLicenciada.filter(atv => atv.atividade.atividadePai)
        this.atividadeLicenciadaSubGrupoEncontrado = this.subGrupo
    }

    async obterAtividadeCalculoParametroEntrada(inicio = false) {
        await this.atividadeParametroEntradaArrecadacaoService
            .buscarParametrosEntradaPIdAtividade(this.atividade.id, this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao)
            .toPromise().then(atividadeParametroCalculoEntrada => {
                this.parametroEntradas = atividadeParametroCalculoEntrada;
            }).catch(async error => {
                error.status = 404;
                this.errorStatusCalculoParametroEntrada = error.status;
                if (this.errorStatusCalculoParametroEntrada === 404) {
                    await this.obterAtividadeCalculoSemParametroEntradaeCalculo(inicio);
                }
            })
    }

    async obterAtividadeCalculoParametroEntradaAut(inicio = false) {
        if (this.atividadeAut)
            await this.atividadeParametroEntradaArrecadacaoService
                .buscarParametrosEntradaPIdAtividade(this.atividadeAut.id, this.parametrizacaoDoObjetivoPrincipal.idArrecadacao)
                .toPromise().then(atividadeParametroCalculoEntrada => {
                    this.parametroEntradasAut = atividadeParametroCalculoEntrada;
                }).catch(async error => {
                    error.status = 404;
                    this.errorStatusCalculoParametroEntrada = error.status;
                    if (this.errorStatusCalculoParametroEntrada === 404) {
                        await this.obterAtividadeCalculoSemParametroEntradaeCalculoAut(inicio);
                    }
                })
        else
         throw new Error('Atividade Autorização não definida')
    }


    async preencherAtividadeComplementar() {
        this.objetivoLicenciamentoSubatividadeService
            .getRequerimentoLicenciamentoPorId(this.requerimentoLicenciamento.id).toPromise()
            .then(result => {
                if (result) {
                    this.grupoSubGrupoSelecionadaTable.push(this.atividadeLicenciada.find(atv => atv.atividade.id === result.idAtividadeComplementar))
                    this.grupoSubGrupoSelecionadaTable = this.grupoSubGrupoSelecionadaTable.slice()
                }
            })
    }

    
    async preencherParametrosDeEntradaECalculoVinculo() {
        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length === 0) {
            this.parametroEntradas = this.formularioReqLicenciamentoVinculadoDto.lancamentoTaxaParamCalculoList.filter(paramEntrada =>
                paramEntrada.tipoParametro === 'ENTRADA' && !paramEntrada.autorizacao);

            this.parametroCalculoList = this.formularioReqLicenciamentoVinculadoDto.lancamentoTaxaParamCalculoList.filter(paramCalc => paramCalc.tipoParametro === 'CALCULO'
                && !paramCalc.autorizacao);

            const key = 'labelFormulario';

            this.parametroEntradas = [...new Map(this.parametroEntradas.map(item =>
                [item[key], item])).values()];

            this.parametroCalculoList = [...new Map(this.parametroCalculoList.map(item =>
                [item[key], item])).values()];

            if (this.parametroEntradas.length > 0) {
                this.parametroEntradas.forEach(async paramEntrada => {
                    const event = {
                        target: {
                            value: paramEntrada.valor
                        }
                    };
                    
                });
            }


            if (this.parametroCalculoList.length > 0) {
                this.parametroCalculoList.forEach(paramCalc => {
                    const event = {
                        target: {
                            value: paramCalc.valor
                        }
                    };
                    this.pegarValorDeParamCalc(event, paramCalc);
                });
            }
        }
    }


    async obterAtividadeCalculoSemParametroEntradaeCalculo(inicio = false) {
        this.atividadeCalculoArrecadacaoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicenca())
            .toPromise().then(async atividadeCalculo => {
            this.atividadeCalculo = atividadeCalculo;
            if (!inicio) {
                this.parametroCalculoList = [...this.atividadeCalculo].shift().parametroCalculoList;
                if (this.parametroCalculoList && this.parametroCalculoList.length === 0) {
                    this.atividadeSemParametroeCaculo = true;
                    await this.iniciarCalculo()
                }
            }
        }).catch(error => {
            this.snackBarService.showAlert("Ocorreu um erro: " + error);
        })
    }
 
    obterAtividadeCalculoSemParametroEntradaeCalculoAut(inicio) {
        this.atividadeCalculoArrecadacaoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicencaAut())
            .toPromise().then(async atividadeCalculo => {
            this.atividadeCalculoAut = atividadeCalculo;
            if (!inicio) {
                this.parametroCalculoListAut = [...this.atividadeCalculoAut].shift().parametroCalculoList;
                if (this.parametroCalculoListAut && this.parametroCalculoListAut.length === 0) {
                    this.atividadeSemParametroeCaculoAut = true;
                    await this.iniciarCalculo(false)
                }
            }

        }).catch(error => {
            this.snackBarService.showAlert("Sua atividade não é passível de licenciamento");
        })
    }



    pegarValorDeParamCalc(event, parametroEntradas: any, licencas = []) {
        this.montarObjetoParamEntrada(parametroEntradas, event.target.value)
        if (this.atividadeCalculo && this.atividadeCalculo.length === 0) {
            this.calcularUpfRetornoDtoList = this.atividadeCalculo
        } else {
            this.calcularUpfRetornoDtoList = licencas
        }
        // this.eventEmitter.emit(this.returnoObjetoEmit(this.validacao))
    }

    pegarValorDeParamCalcAut(event, parametroEntradas: any, licencas = []) {
        this.montarObjetoParamEntradaAut(parametroEntradas, event.target.value)
        if (this.atividadeCalculoAut && this.atividadeCalculoAut.length === 0) {
            this.calcularUpfRetornoDtoListAut = this.atividadeCalculoAut
        } else {
            this.calcularUpfRetornoDtoListAut = licencas
        }
        // this.eventEmitter.emit(this.returnoObjetoEmit(this.validacao))
    }


    montarObjetoParametroSemLicenca() {

        return {
            idAtividade: this.atividade.id,
            parametroEntrada: this.parametroEntrada,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp,
            idTipoTitulacao: this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao
        }
    }

    montarObjetoParametroSemLicencaAut() {

        return {
            idAtividade: this.atividadeAut.id,
            parametroEntrada: this.parametroEntradaAut,
            pisiculturaExotico: this.pisiculturaExotico,
            regraApp: this.regraApp,
            idTipoTitulacao: this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao
        }
    }

    
    async preencherParametrosDeEntradaECalculo() {
        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
            this.parametroEntradas = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramEntrada =>
                paramEntrada.tipoParametro === 'ENTRADA' && !paramEntrada.autorizacao);

            this.parametroCalculoList = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramCalc => paramCalc.tipoParametro === 'CALCULO'
                && !paramCalc.autorizacao);

            this.parametroEntradasAut = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramEntrada =>
                paramEntrada.tipoParametro === 'ENTRADA' && paramEntrada.autorizacao);

            this.parametroCalculoListAut = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramCalc => paramCalc.tipoParametro === 'CALCULO'
                && paramCalc.autorizacao);

            const key = 'labelFormulario';

            this.parametroEntradas = [...new Map(this.parametroEntradas.map(item =>
                [item[key], item])).values()];

            this.parametroCalculoList = [...new Map(this.parametroCalculoList.map(item =>
                [item[key], item])).values()];

            if (this.parametroEntradas.length > 0) {
                this.parametroEntradas.forEach(async paramEntrada => {
                    const event = {
                        target: {
                            value: paramEntrada.valor
                        }
                    };
                    // await this.onKey(event, paramEntrada, false, true);
                });
            }


            if (this.parametroCalculoList.length > 0) {
                this.parametroCalculoList.forEach(paramCalc => {
                    const event = {
                        target: {
                            value: paramCalc.valor
                        }
                    };
                    this.pegarValorDeParamCalc(event, paramCalc);
                });
            }

            if (this.parametroEntradasAut.length > 0) {
                this.parametroEntradasAut.forEach(async paramEntrada => {
                    const event = {
                        target: {
                            value: paramEntrada.valor
                        }
                    };
                    // await this.onKey(event, paramEntrada, false, true);
                });
            }


            if (this.parametroCalculoListAut.length > 0) {
                this.parametroCalculoListAut.forEach(paramCalc => {
                    const event = {
                        target: {
                            value: paramCalc.valor
                        }
                    };
                    this.pegarValorDeParamCalcAut(event, paramCalc);
                });
            }

            if (this.parametroEntradasAut.length === 0 && this.parametroCalculoListAut.length === 0) {
                await this.obterAtividadeCalculoParametroEntradaAut()
            }
        }
    }


    
    private async verificaLac() {
        let retorno = 0;
        const atvCalcLAC = this.atividadeCalculo.find(atvCalc => atvCalc.tipoTitulacao.id === 1)

        if (atvCalcLAC) {
            const dialogRef = this.dialog.open(ConfirmacaoDialogComponent,
                {
                    data: {
                        message: 'A atividade pretendida é classificada como LAC.\n' +
                            'Essa atividade encontra-se em Zona de amortecimento de Terra indígena, Unidade de Conservação, ou ocorrerá desmate?\n'
                    },
                    width: '600px'
                });

            await dialogRef.afterClosed().toPromise().then(async result => {
                if (!!result) {
                    retorno = 0;
                } else {
                    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent,
                        {
                            data: {
                                message: `Sua atividade se enquadra no Licenciamento por Adesão e Compromisso, por favor solicitar o seu licenciamento no menu de novos requerimentos no nosso <a href="${environment.URL_LICENCIAMENTO}/#/menu-novo-requerimento" target="_blank">portal.</a>`,
                                apenasDescricao: true
                            },
                            width: '600px'
                        });

                    await dialogRef.afterClosed().toPromise().then(() => {
                        retorno = 1
                    });
                }
            });
        }

        return retorno
    }


    
    async iniciarCalculo(calculaTudo = true) {
        this.calcularUpfRetornoDtoList = []
        this.calcularUpfRetornoDtoListAut = []
        this.popUp = false

        if (calculaTudo) {

            if (this.requerimento.tipoProcesso.instanciaRequerimento !== "AUTORIZACAO_OUTROS_DOCUMENTOS") {
                let validacaoLAC = await this.verificaLac()

                const atvCalcLAC = this.atividadeCalculo.find(atvCalc => atvCalc.tipoTitulacao.id === 1)

                if (atvCalcLAC
                    && validacaoLAC === 0) {
                    if (!this.atividadeCalculo.find(atvCalc => atvCalc.tipoTitulacao.id === 5)) {
                        const atividadesCalculo = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculoPAtividade(this.atividade.id).toPromise()
                        const atividadeCalcLAS = atividadesCalculo.find(atvCalc => atvCalc.tipoTitulacao.id === 5)
                        const atividadeCalculoParamEntrada = await this.atividadeParametroEntradaArrecadacaoService
                            .buscaAtividadeParametroEntradaPIdAtividadeCalculo(atividadeCalcLAS.id).toPromise()
                        for (let paramEntrada of this.parametroEntradas) {
                            const event = {
                                target: {
                                    value: atividadeCalculoParamEntrada.valorMaximo.toString()
                                }
                            };
                            // await this.onKey(event, paramEntrada, false);
                            this.valorParametro.nativeElement.value = atividadeCalculoParamEntrada.valorMaximo.toString()
                            if (this.parametroCalculoList
                                && this.parametroCalculoList.length > 0) {
                                this.snackBarService.showAlert('Preencha o parâmetro pra cálculo para concluir o cálculo')
                                this.erroDeCalculo = true
                                this.popUp = true
                                return
                            }
                        }
                    } else {
                        this.atividadeCalculo = this.atividadeCalculo.filter(atvCalc => atvCalc.tipoTitulacao.id === 5)
                    }
                } else if (validacaoLAC === 1) {
                    this.erroDeCalculo = true
                    return
                }

                // if (!this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 5)) {
                //     if (this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id == 5)) {
                //         const dialogRef = this.dialog.open(ConfirmacaoDialogComponent,
                //             {
                //                 data: {
                //                     message: 'Sua atividade se enquadra na LAS – Licença Ambiental Simplificada, ' +
                //                         'por favor solicitar o Objetivo “LAS - Licença Ambiental Simplificada“',
                //                     apenasDescricao: true
                //                 },
                //                 width: '600px'
                //             });
                //         this.erroDeCalculo = true
                //         this.popUp = true
                //         return
                //     }
                // } else if (this.objetivosPrincipaisSelecionados.find(obj => obj.objetivo.tipoTitulacao.id === 5)) {
                //     if (!this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id == 5)) {
                //         const dialogRef = this.dialog.open(ConfirmacaoDialogComponent,
                //             {
                //                 data: {
                //                     message: 'Sua atividade não se enquadra na LAS – Licença Ambiental Simplificada, por favor solicitar algum dos objetivos abaixo:<br>' +
                //                         '- “LP - Licença Prévia”;<br>' +
                //                         '- “LP - Licença Prévia - EIA (RIMA)”;<br>' +
                //                         '- “LO - Licença de Operação – Vinculada',
                //                     apenasDescricao: true
                //                 },
                //                 width: '600px'
                //             });
                //         this.erroDeCalculo = true
                //         this.popUp = true
                //         return
                //     }
                // }
            }

            // let objetivosAll = []

            // objetivosAll.push(...this.objetivosPrincipaisSelecionados)
            // if (this.objetivosOutrosSelecionados.length > 0) {
            //     objetivosAll.push(...this.objetivosOutrosSelecionados)
            // }


            // let objetivoACalcular = []
            // let objetivosDeCalculo = []
            // if (this.requerimento.tipoProcesso.id !== 104) {
            //     objetivosDeCalculo.push(...this.atividadeCalculo)
            // }
            // if (this.objetivosOutrosSelecionados.length > 0) {
            //     objetivosDeCalculo.push(...this.atividadeCalculoAut)
            // }

            // objetivosAll = objetivosAll.filter(obj => obj.objetivo.tipoTitulacao.id !== 16)
            // for (let objetivo of objetivosAll) {
            //     for (let calculo of objetivosDeCalculo) {
            //         if (objetivo.objetivo.tipoTitulacao.id === calculo.tipoTitulacao.id) {
            //             objetivoACalcular.push(calculo)
            //         }
            //     }
            // }

            // if (objetivoACalcular.length == objetivosAll.length) {
            //     let atividadesCalculoPrin = []
            //     for (let objetivosPrin of this.objetivosPrincipaisSelecionados) {
            //         atividadesCalculoPrin.push(this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id === objetivosPrin.objetivo.tipoTitulacao.id))
            //     }
            //     this.atividadeCalculo = atividadesCalculoPrin

            // } else {
            //     this.snackBarService.showAlert('Sua atividade não é passível de licenciamento')
            //     this.erroDeCalculo = true
            //     return;
            // }

            for (let param of this.parametrosCalculoLista) {
                param['atividadeCalculo'] = []
            }

            for (let atvCalc of this.atividadeCalculo) {
                for (let param of this.parametrosCalculoLista) {
                    param['atividadeCalculo'].push(atvCalc)
                }
            }

            for (let param of this.parametrosCalculoListaAut) {
                param['atividadeCalculo'] = []
            }

            if (this.atividadeCalculoAut) {
                for (let atvCalc of this.atividadeCalculoAut) {
                    for (let param of this.parametrosCalculoListaAut) {
                        param['atividadeCalculo'].push(atvCalc)
                    }
                }
            }
 

         }
        //  await this.calcularLicencaAut()


        if (this.calcularUpfRetornoDtoList.length > 0 && this.calcularUpfRetornoDtoListAut.length > 0) {
            this.calcularUpfRetornoDtoList.push(...this.calcularUpfRetornoDtoListAut)
        } else if (this.calcularUpfRetornoDtoList.length > 0
            && this.mostrarOutraObjetivos && this.calcularUpfRetornoDtoListAut.length === 0) {
            this.erroDeCalculo = false
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" && this.calcularUpfRetornoDtoListAut.length > 0
            && this.mostrarOutraObjetivos) {
            this.calcularUpfRetornoDtoList.push(...this.calcularUpfRetornoDtoListAut)
            this.erroDeCalculo = false
        } else {
            this.erroDeCalculo = true
        }
    }
  
    
    montarObjetoCalculoUpf(idAtividadeCalculo) {

        this.parametrosCalculoLista.forEach(param => {
            delete param.id
        })

        return {
            idAtividadeCalculo: idAtividadeCalculo,
            parametrosCalculo: this.parametrosCalculoLista,
            parametroEntrada: this.parametroEntrada
        }
    }

    montarObjetoCalculoUpfAut(idAtividadeCalculo) {

        this.parametrosCalculoListaAut.forEach(param => {
            delete param.id
        })

        return {
            idAtividadeCalculo: idAtividadeCalculo,
            parametrosCalculo: this.parametrosCalculoListaAut,
            parametroEntrada: this.parametroEntradaAut
        }
    }


    
    montarObjetoParamEntrada(parametroEntradas, valor) {
        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (this.parametrosCalculoLista.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoLista.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoLista.splice(this.parametrosCalculoLista.indexOf(paramCalc), 1);
                    this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
                }
            })
        } else {
            this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
        }
    }

    montarObjetoParamEntradaAut(parametroEntradas, valor) {
        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (this.parametrosCalculoListaAut.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoListaAut.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoListaAut.splice(this.parametrosCalculoListaAut.indexOf(paramCalc), 1);
                    this.parametrosCalculoListaAut.push(objetoParametrosCalculoParaLista);
                }
            })
        } else {
            this.parametrosCalculoListaAut.push(objetoParametrosCalculoParaLista);
        }
    }


    // private async getObjetivosApresentados() {
    //     let objs;
    //     if (this.requerimento.tipoProcesso.id == 63 || this.requerimento.tipoProcesso.id == 103 ||
    //         this.requerimento.tipoProcesso.id == 104) { // Licenciamento Empreendimentos
    //         await this.objetivoParametrizacaoObjetivoService.getByParametrizacaoObjetivo(this.requerimento.idParametroObjetivo).toPromise().then(paramObjs => {
    //             objs = paramObjs
    //         });
    //     }

    //     this.objetivos = objs.map(obj => ({
    //         objetivo: {
    //             objetivo: this.objetivos.find(o => o.id === obj.idObjetivoParametrizacao),
    //             tipoObjetivo: this.tiposObjetivo.find(o => o.id === obj.idTipoObjetivo),
    //             titulo: this.titulos.find(o => o.id === obj.idTituloEmitidoAlterado),
    //             tipoTitulacao: this.tiposTitulacao.find(o => o.id === obj.idArrecadacao),
    //             tipoRequerimento: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento),
    //             ordemSelecao: obj.ordemSelecao,
    //             isSelecaoExclusiva: obj.isSelecaoExclusiva,
    //             desabilitado: false
    //         },
    //         vinculo: obj
    //     }));

    //     this.objetivos = this.objetivos.filter(obj => obj.objetivo.tipoRequerimento.id === this.requerimento.tipoRequerimento.id)

    //     if (this.requerimento.tipoProcesso.id == 104) {
    //         if (!this.requerimento.numeroProtocoloVinculado) {
    //             this.objetivosOutros = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 1)
    //         } else {
    //             this.objetivosPrincipais = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 1)
    //         }
    //     } else {
    //         this.objetivosPrincipais = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 1)
    //         this.objetivosOutros = this.objetivos.filter(obj => obj.objetivo.tipoObjetivo.id === 2)
    //         this.regraDeDesabilitarHabilitarObjetivos()
    //         if (this.requerimento.idTipoRequerimentoTifLas != 2) {
    //             this.iniciaRegrasLI()
    //         }
    //     }

    // }


    // private async criaOuBuscaReqLicenciamento() {
    //     this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).subscribe(async resultado => {
    //         if (!resultado) {
    //             let reqLicenciamento = new RequerimentoLicenciamento()
    //             reqLicenciamento.requerimento = this.requerimento
    //             this.requerimentoLicenciamentoService.create(reqLicenciamento).subscribe(async reqLic => {
    //                 this.requerimentoLicenciamento = reqLic
    //                 await this.preencheTabelaObjetivo()
    //                 await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise().then(formulReq => {
    //                     this.formularioReqLicenciamentoDto = formulReq
    //                     this.requerimentoLicenciamento = this.formularioReqLicenciamentoDto.requerimentoLicenciamento
    //                 }).catch(() => {
    //                     this.snackBarService.showError('Ocorreu um erro ao buscar o Formulário do Requerimento.');
    //                 })
    //             }, () => {
    //                 this.snackBarService.showError('Ocorreu um erro ao criar o requerimento.');
    //             })
    //         } else {
    //             await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise().then(async formulReq => {
    //                 this.formularioReqLicenciamentoDto = formulReq
    //             }).catch(() => {
    //                 this.snackBarService.showError('Ocorreu um erro ao processar dados do requerimento.');
    //             })
    //             this.requerimentoLicenciamento = this.formularioReqLicenciamentoDto.requerimentoLicenciamento
    //             await this.preencheTabelaObjetivo(true)
    //             if (this.requerimento.tipoProcesso.id === 104) {
    //                 if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
    //                     await this.preencherParametrosDeEntradaECalculo()
    //                 }
    //             }
    //             if (this.requerimentoLicenciamento.idAtividade) {
    //                 this.preencheTabelaAtividadeLicenciada()
    //                 await this.obterAtividadeCalculoParametroEntrada(true)
    //                 await this.preencherAtividadeComplementar()
    //                 if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
    //                     await this.preencherParametrosDeEntradaECalculo()
    //                 }
    //             }
    //         }
    //     })
    // }





    private async buscarFormularioRequerimentoLicenciamentoEmp() {
        await this.requerimentoFormLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise()
            .then(async formularioReqLicenciamentoDto => {
                if (formularioReqLicenciamentoDto) {
                    if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                        const key = 'labelFormulario';
                        this.lancamentoTaxaParam = [...new Map(formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.map(item =>
                            [item[key], item])).values()];
                    }
                }
            });
    }

    stringParametroCalculo(parametros: any) {

        let novoArray = []
        for (let param of parametros) {
            let newObject = `${param.labelFormulario} : ${param.valor}`
            novoArray.push(newObject)
        }
        return novoArray.join(", ")
    }

    async carregarAtividades() {
        await this.objetivoLicenciamentoSubatividadeService.getRequerimentoLicenciamentoPorId(this.requerimento.id).toPromise()
            .then(objetivo => {
                this.objetivoSubAtividade = objetivo;
            })

        if (this.objetivoSubAtividade) {
            await this.arrecadacaoService.getAtividadeId(this.objetivoSubAtividade.idAtividadeComplementar).toPromise()
                .then(atividadeComplementar => {
                    this.atividadeComplementar = atividadeComplementar;
                })
        }

        await this.arrecadacaoService.getAtividadeId(this.requerimentoLicenciamento.idAtividade).toPromise()
            .then(subAtividade => {
                this.subAtividade = subAtividade;
            })
    }

    loadFinalidadeContainer(requerimentoEmpreendimentoAtividade: any, index: number): void {
        const formularioContainer = this.formularioContainer.find((e, i, a) => i === index);
        formularioContainer.clear();
        const type = RequerimentoEmpreendimentoAtividadeFormulario.get(requerimentoEmpreendimentoAtividade).resolved;
        const factory = this.componentFactoryResolver.resolveComponentFactory(type);
        const ref = formularioContainer.createComponent(factory);
        ref.instance.requerimento = this.requerimento;
        ref.instance.isModoVisualizacao = this.isSomenteVisualizacao || this.isSomenteLeitura;
        ref.instance.requerimento.desabilitarAtividades = true
        ref.instance.enviarFormConcluido.subscribe((value) => {
            console.debug('Atualizando formularios: recarregarForms')
            setTimeout(() => this.recarregarForms(), 3000);
        });
        this.recarregarForms();
        
    }

    private async carregarFormulariosAtividades() {
        return await this.atividadeParamLicenciamentoService.buscarFormulariosPorIdAtividade(this.requerimentoLicenciamento.idAtividade,
            this.requerimentoLicenciamento.id).toPromise()
            .then(response => {
                response = response.filter(requerimentoFormularioAtividade =>
                    RequerimentoEmpreendimentoAtividadeFormulario.get(requerimentoFormularioAtividade));
                this.requerimentoEmpreendimentoAtividade = response;
                this.requerimentoEmpreendimentoAtividade.forEach(async form => {
                    await this.verificarPreenchimentoFormularios(form.constante);
                });
            }
            )
    }

    receberFormConcluido(detalheForm: any) {
        const objIndex = this.formsConcluidos.findIndex((obj => obj.formularioDesc == detalheForm.formularioDesc));
        if (detalheForm) detalheForm.id = `${detalheForm.formularioDesc}-${detalheForm.idForm}`;
        if (objIndex >= 0) {
            this.formsConcluidos[objIndex] = detalheForm;
        } else {
            this.formsConcluidos.push(detalheForm);
        }
    }

    checkFormSituacao(id: any, formDesc: string) {
        let form =  this.formsConcluidos.find((f) => f.idForm == id && f.formularioDesc == formDesc);
        return form && form.formConcluido;
    }

    
    validarAba(erros: ErrosAbaRequerimento): void | Promise<void> {
        this.formsConcluidos.forEach(form => {
            if (form.formConcluido == false) {
                erros.push("O formulário " + form.nomeForm + ": possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).");
            }
        });

        if (erros.length === 0) {
            return Promise.resolve();
        }
    }

    get possuiAtividades(): boolean {
        return this.requerimentoEmpreendimentoAtividade && this.requerimentoEmpreendimentoAtividade.length > 0;
    }

    recarregarForms() {
        this.formsConcluidos.forEach(async form => {
            await this.verificarPreenchimentoFormularios(form.formularioDesc);
        });
    }

    verificarPreenchimentoFormularios(formDesc) {
        switch (formDesc) {
            case 'mineracao':
                this.mineracaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(mineracao => {
                    if (mineracao) {
                        this.receberFormConcluido({
                            idForm: mineracao.id,
                            formConcluido: true,
                            formularioDesc: 'mineracao',
                            nomeForm: 'Mineração'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'mineracao',
                            nomeForm: 'Mineração'
                        })
                    }
                });
                break;
            case 'residuos-aterro-industrial-urbano':
                this.residuoAterroIndUrbService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuoAterro => {
                    if (residuoAterro) {
                        this.receberFormConcluido({
                            idForm: residuoAterro.id,
                            formConcluido: true,
                            formularioDesc: 'residuos-aterro-industrial-urbano',
                            nomeForm: 'Resíduos - Aterro Industrial/Urbano'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false, formularioDesc: 'residuos-aterro-industrial-urbano',
                            nomeForm: 'Resíduos - Aterro Industrial/Urbano'
                        })
                    }
                });
                break;
            case 'industria-frigorifico':
                this.industriaFrigorificoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(industriaFrigorifico => {
                    if (industriaFrigorifico) {
                        this.receberFormConcluido({
                            idForm: industriaFrigorifico.id,
                            formConcluido: true,
                            formularioDesc: 'industria-frigorifico',
                            nomeForm: 'Indústria - Abate/Frigorífico de animais'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false, formularioDesc: 'industria-frigorifico',
                            nomeForm: 'Indústria - Abate/Frigorífico de animais'
                        })
                    }
                });
                break;
            case 'residuos-cemiterio-crematorio':
                this.empreendimentoCemiterioCrematorioService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(cemiterioCrematorio => {
                    if (cemiterioCrematorio) {
                        this.receberFormConcluido({
                            idForm: cemiterioCrematorio.id, formConcluido: true,
                            formularioDesc: 'residuos-cemiterio-crematorio', nomeForm: 'Resíduos - Cemitério/Crematório'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-cemiterio-crematorio', nomeForm: 'Resíduos - Cemitério/Crematório'
                        })
                    }
                });
                break;
            case 'servico-combustivel':
                this.servicoCombustivelService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(servicoCombustivel => {
                    if (servicoCombustivel) {
                        this.receberFormConcluido({
                            idForm: servicoCombustivel.id, formConcluido: true,
                            formularioDesc: 'servico-combustivel', nomeForm: 'Serviços - Combustível'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'servico-combustivel', nomeForm: 'Serviços - Combustível'
                        })
                    }
                });
                break;
            case 'industria-biocombustivel':
                this.industriaBiocombustivelService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(industriaBiocombustivel => {
                    if (industriaBiocombustivel) {
                        this.receberFormConcluido({
                            idForm: industriaBiocombustivel.id, formConcluido: true,
                            formularioDesc: 'industria-biocombustivel', nomeForm: 'Indústria - Biocombustível'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'industria-biocombustivel', nomeForm: 'Indústria - Biocombustível'
                        })
                    }
                });
                break;
            case 'residuos-rcc':
                this.residuosRccService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuosRcc => {
                    if (residuosRcc) {
                        this.receberFormConcluido({
                            idForm: residuosRcc.id, formConcluido: true,
                            formularioDesc: 'residuos-rcc', nomeForm: 'Resíduos - Construção Civil'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-rcc', nomeForm: 'Resíduos - Construção Civil'
                        })
                    }
                });
                break;
            case 'gerenciamento-residuos':
                this.empreendimentoGerenciamentoResiduosService.listarResiduosGeradosPorRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(gerenciamentoResiduos => {
                    if (gerenciamentoResiduos) {
                        this.receberFormConcluido({
                            idForm: gerenciamentoResiduos[0].id,
                            formConcluido: true,
                            formularioDesc: 'gerenciamento-residuos',
                            nomeForm: 'Resíduos - Plano de Gerenciamento de Resíduos Sólidos'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'gerenciamento-residuos',
                            nomeForm: 'Resíduos - Plano de Gerenciamento de Resíduos Sólidos'
                        })
                    }
                });
                break;
            case 'residuos-tratamento-termico':
                this.residuoTratamentoTermicoService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(tratamentoTermico => {
                    if (tratamentoTermico) {
                        this.receberFormConcluido({
                            idForm: tratamentoTermico.id, formConcluido: true,
                            formularioDesc: 'residuos-tratamento-termico', nomeForm: 'Resíduos - Tratamento Térmico'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-tratamento-termico', nomeForm: 'Resíduos - Tratamento Térmico'
                        })
                    }
                });
                break;
            case 'residuos-transporte-produtos-perigosos':
                this.transporteProdutosPerigososService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(produtosPerigosos => {
                    if (produtosPerigosos) {
                        this.receberFormConcluido({
                            idForm: produtosPerigosos.id,
                            formConcluido: true,
                            formularioDesc: 'residuos-transporte-produtos-perigosos',
                            nomeForm: 'Resíduos - Transporte de resíduos e produtos perigosos'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'residuos-transporte-produtos-perigosos',
                            nomeForm: 'Resíduos - Transporte de resíduos e produtos perigosos'
                        })
                    }
                });
                break;
            case 'residuos-geral':
                this.residuoGeralService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuoGeral => {
                    if (residuoGeral) {
                        this.receberFormConcluido({
                            idForm: residuoGeral.id, formConcluido: true,
                            formularioDesc: 'residuos-geral', nomeForm: 'Resíduos - Geral'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-geral', nomeForm: 'Resíduos - Geral'
                        })
                    }
                });
                break;
            case 'agua-passivo-supressao':
                this.aguaPassivoSupressaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(aguaPassivoSupressao => {
                    if (aguaPassivoSupressao) {
                        this.receberFormConcluido({
                            idForm: aguaPassivoSupressao.id, formConcluido: true,
                            formularioDesc: 'agua-passivo-supressao', nomeForm: 'Água Passivo Supressão'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'agua-passivo-supressao', nomeForm: 'Água Passivo Supressão'
                        })
                    }
                });
                break;
            case 'sistema-tratamento':
                this.sistemaTratamentoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(sistemaTratamento => {
                    if (sistemaTratamento) {
                        this.receberFormConcluido({
                            idForm: sistemaTratamento.id,
                            formConcluido: true,
                            formularioDesc: 'sistema-tratamento',
                            nomeForm: 'Sistema de tratamento de efluentes doméstico e industrial'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'sistema-tratamento',
                            nomeForm: 'Sistema de tratamento de efluentes doméstico e industrial'
                        })
                    }
                });
                break;
            case 'madeira':
                this.madeiraService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(madeira => {
                    if (madeira) {
                        this.receberFormConcluido({
                            idForm: madeira.id, formConcluido: true,
                            formularioDesc: 'madeira', nomeForm: 'Madeira'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'madeira', nomeForm: 'Madeira'
                        })
                    }
                });
                break;
            case 'infra-parcel-solo-res-com':
                this.empreendimentoInfraParcelSoloResComService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(infraParcelSolo => {
                    if (infraParcelSolo) {
                        this.receberFormConcluido({
                            idForm: infraParcelSolo.id,
                            formConcluido: true,
                            formularioDesc: 'infra-parcel-solo-res-com',
                            nomeForm: 'Infraestrutura - Obras Residenciais/Comerciais / Parcelamento do solo'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'infra-parcel-solo-res-com',
                            nomeForm: 'Infraestrutura - Obras Residenciais/Comerciais / Parcelamento do solo'
                        })
                    }
                });
                break;
            case 'infraestrutura-obras-viarias':
                this.infraObrasViariasService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(infraObraViaria => {
                    if (infraObraViaria) {
                        this.receberFormConcluido({
                            idForm: infraObraViaria.id, formConcluido: true,
                            formularioDesc: 'infraestrutura-obras-viarias', nomeForm: 'Infraestrutura - Obras Viárias'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'infraestrutura-obras-viarias', nomeForm: 'Infraestrutura - Obras Viárias'
                        })
                    }
                });
                break;
            case 'ee-hidrica':
                this.eeHidricaService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeHidrica => {
                    if (eeHidrica) {
                        this.receberFormConcluido({
                            idForm: eeHidrica.id, formConcluido: true,
                            formularioDesc: 'ee-hidrica', nomeForm: 'Empreendimentos Energéticos - Hídrica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-hidrica', nomeForm: 'Empreendimentos Energéticos - Hídrica'
                        })
                    }
                });
                break;
            case 'ee-termica':
                this.empreendimentoEnergService.buscaEEtermicaPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeTermica => {
                    if (eeTermica) {
                        this.receberFormConcluido({
                            idForm: eeTermica.id, formConcluido: true,
                            formularioDesc: 'ee-termica', nomeForm: 'Empreendimentos Energéticos - Térmica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-termica', nomeForm: 'Empreendimentos Energéticos - Térmica'
                        })
                    }
                });
                break;
            case 'ee-fotovoltaica':
                this.empreendimentoEnergiaFotovoltaicaService.consultarPorIdRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeFotovoltaica => {
                    if (eeFotovoltaica) {
                        if (eeFotovoltaica.formConcluido) {
                            this.receberFormConcluido({
                                idForm: eeFotovoltaica.id,
                                formConcluido: true,
                                formularioDesc: 'ee-fotovoltaica',
                                nomeForm: 'Empreendimentos Energéticos - Fotovoltaica'
                            })
                        } else {
                            this.receberFormConcluido({
                                idForm: 0,
                                formConcluido: false,
                                formularioDesc: 'ee-fotovoltaica',
                                nomeForm: 'Empreendimentos Energéticos - Fotovoltaica'
                            })
                        }
                    }
                });
                break;
            case 'ee-linha-transmissao':
                this.eeLinhaTransmissaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeLinha => {
                    if (eeLinha) {
                        this.receberFormConcluido({
                            idForm: eeLinha.id,
                            formConcluido: true,
                            formularioDesc: 'ee-linha-transmissao',
                            nomeForm: 'Empreendimentos Energéticos - Linha de Transmissão'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'ee-linha-transmissao',
                            nomeForm: 'Empreendimentos Energéticos - Linha de Transmissão'
                        })
                    }
                });
                break;
            case 'ee-subestacao':
                this.empreendimentoSubestacaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeSubestacao => {
                    if (eeSubestacao) {
                        this.receberFormConcluido({
                            idForm: eeSubestacao.id, formConcluido: true,
                            formularioDesc: 'ee-subestacao', nomeForm: 'Empreendimentos Energéticos - Subestação'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-subestacao', nomeForm: 'Empreendimentos Energéticos - Subestação'
                        })
                    }
                });
                break;
            case 'ee-eolica':
                this.empreendimentoEnergiaEolicaService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeEolica => {
                    if (eeEolica) {
                        this.receberFormConcluido({
                            idForm: eeEolica.id, formConcluido: true,
                            formularioDesc: 'ee-eolica', nomeForm: 'Empreendimentos Energéticos - Eólica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-eolica', nomeForm: 'Empreendimentos Energéticos - Eólica'
                        })
                    }
                });
                break;
            case 'infra-obra-fluviais-saneamento':
                this.infraObraFluvSaneamService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(obraFluv => {
                    if (obraFluv) {
                        this.receberFormConcluido({
                            idForm: obraFluv.id,
                            formConcluido: true,
                            formularioDesc: 'infra-obra-fluviais-saneamento',
                            nomeForm: 'Infraestrutura - Obras Fluviais Saneamento'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'infra-obra-fluviais-saneamento',
                            nomeForm: 'Infraestrutura - Obras Fluviais Saneamento'
                        })
                    }
                });
                break;
            case 'servico-geral':
                this.servicoGeralService.getByRequerimento(this.requerimentoLicenciamentoVinculado ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(obraFluv => {
                    if (obraFluv) {
                        this.receberFormConcluido({
                            idForm: obraFluv.id, formConcluido: true,
                            formularioDesc: 'servico-geral', nomeForm: 'Serviços Gerais'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'servico-geral', nomeForm: 'Serviços Gerais'
                        })
                    }
                });
                break;
        }
    }
}
