import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnumInstanciaRequerimento } from 'app/main/pages/requerimentos/menu-novo-requerimento/instancia-requerimento.enum';
import { EmissaoTituloService } from 'app/main/pages/tarefas/shared/service/emissao-titulo.service';
import { TipoProcesso } from 'app/main/pages/tipos-processo/tipo-processo.model';
import { Titulacao } from 'app/main/pages/titulacoes/titulacao.model';
import { EmissaoTitulo } from 'app/main/shared/models/emissao-titulo.model';
import { LicenciamentoConfiguracaoUtil } from 'app/main/shared/util/licenciamento-configuracao-util';
import { take } from 'rxjs/operators';
import { DominioDto } from '../../../../shared/models/dominio-dto.model';
import { DominiosService } from '../../../../shared/services/dominios.service';
import {
    ObjetivoParametrizacaoObjetivoService
} from '../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service';
import {
    ParametroObjetivoService
} from '../../../../shared/services/licenciamento-config-service/parametro-objetivo.service';
import {
    TituloParametrizacaoObjetivoService
} from '../../../../shared/services/licenciamento-config-service/titulo-parametrizacao-objetivos.service';
import { ObjetivoLicenciamentoService } from '../../../../shared/services/objetivo-licenciamento.service';
import { ProcessoService } from '../../../../shared/services/processo.service';
import { SnackBarService } from '../../../../shared/snack-bar/snack-bar.service';
import { TipoRequerimentoService } from '../../../tipos-requerimento/tipo-requerimento.service';
import { TitulacaoService } from '../../../titulacoes/titulacao.service';
import { RequerimentoLicenciamentoService } from '../../requerimento-licenciamento/requerimento-licenciamento.service';
import { RequerimentoService } from '../../requerimento.service';
import { TipoProcessoService } from '../tipo-processo.service';

@Component({
    selector: 'app-dialog-licenciamento-trifasico-nato',
    templateUrl: './dialog-licenciamento-trifasico.component.html',
    styleUrls: ['./dialog-licenciamento-trifasico.component.scss']
})
export class DialogLicenciamentoTrifasicoComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogLicenciamentoTrifasicoComponent>,
        public requerimentoService: RequerimentoService,
        public processoService: ProcessoService,
        private dominioService: DominiosService,
        private tipoRequerimentoService: TipoRequerimentoService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private tipoProcessoService: TipoProcessoService,
        private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private parametroObjetivoService: ParametroObjetivoService,
        private tituloParametrizacaoObjetivoService: TituloParametrizacaoObjetivoService,
        private licenciamentoTitulacaoService: TitulacaoService,
        private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
        private emissaoTituloService: EmissaoTituloService,
        private snackBarService: SnackBarService,
        private titulacaoService: TitulacaoService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    opcaoSelecionada: any;
    numeroProtocolo: any;

    requerimento: any;
    objetivos: any;

    mostrarEncontradoSiga: boolean = false;
    mostrarEncontradoLegado: boolean = false;
    mostrarNaoEncontrado: boolean = false;

    tipoRequerimentoTrifLas: Array<DominioDto>
    tiposRequerimento: Array<any> = [];
    emissoesTitulo: Array<EmissaoTitulo> = [];
    titulacoes: Array<Titulacao> = [];
    tipoRequerimento: any;
    validado = false
    titulos: Array<any> = [];
    parametros: Array<any> = [];
    idParametroObjetivo: number
    falhou = false

    async ngOnInit() {
        await this.getListaTiposRequerimento();
        await this.getTipoRequerimento();
        await this.getListaTitulos();
        await this.buscaParametroComTiposRequerimento();
        await this.getTitulacoes();
    }

    private async getTipoRequerimento() {
        this.tipoRequerimentoTrifLas = await this.dominioService.getDominio('TIPO_REQUERIMENTO_TRIF_LAS').toPromise();
    }

    private async getListaTitulos() {
        this.titulos = await this.licenciamentoTitulacaoService.getAll().toPromise();
    }

    private async getTitulacoes() {
        this.titulacoes = await this.titulacaoService.getAll().toPromise();
    }

    retornarResultado(aut = false) {
        if (this.data && this.data.aut && !aut) {
            if (this.validado && this.tipoRequerimento) {
                this.dialogRef.close({
                    tipoRequerimento: this.tipoRequerimento,
                    numeroProtocoloVinculado: this.numeroProtocolo,
                    idTipoRequerimentoAtuacao: this.opcaoSelecionada,
                    idParametroObjetivo: this.idParametroObjetivo,
                    //emissoesTitulo: this.emissoesTitulo
                });
            } else {
                this.snackBarService.showAlert('Preencha os campos para avançar e criar o requerimento.')
                return
            }
        } else if (aut) {
            this.dialogRef.close({});
        } else {
            //O identificadores da opção selecionada é carregado pela tabela DOMINIOS.REQUERIMENTO_TRIFASICO_LAS
            if (this.opcaoSelecionada == 1 || this.opcaoSelecionada == 3) {
                let parametro = this.parametros.find(param => param.idTipoProcesso === this.data.tpProcesso.id && param.idTipoAtuacao == 1);
                if (!parametro) {
                    this.snackBarService.showError('Erro ao criar o requerimento. Não foi possível consultar o [parametroObjetivo] para o tipo de processo TRIFÁSICO/LAS')
                    return
                }
        
                this.idParametroObjetivo = parametro ? parametro.id : null;
                this.dialogRef.close({
                    idTipoRequerimentoAtuacao: this.opcaoSelecionada,
                    idParametroObjetivo: this.idParametroObjetivo
                });
            } else if (this.opcaoSelecionada == 2) {
                if (this.validado && this.tipoRequerimento) {
                    this.dialogRef.close({
                        tipoRequerimento: this.tipoRequerimento,
                        idTipoRequerimentoAtuacao: this.opcaoSelecionada,
                        numeroProtocoloVinculado: this.numeroProtocolo,
                        idParametroObjetivo: this.idParametroObjetivo
                    });
                } else {
                    this.snackBarService.showAlert('Preencha os campos para avançar e criar o requerimento.')
                    return
                }
            }
        }
    }

    async buscarRequerimento(numeroProtocolo, aut = false) {
        // if (!this.requerimento) {
        this.mostrarEncontradoLegado = false
        this.validado = false
        this.mostrarNaoEncontrado = false

        await this.processoService.findByNumero(numeroProtocolo.replace('/', '')).toPromise().then(async response => {
            if (response) {
                await this.requerimentoService.getRequerimento(numeroProtocolo).toPromise().then(async resposta => {
                    if (response) {
                        response.requerimentos.push(resposta[0])
                    }
                })

                let validacao = response.requerimentos.find(req => req.situacaoProcesso !== 'EM_CADASTRO')
                if (validacao) {
                    this.requerimento = response.requerimentos[0]
                    if (!aut) {
                        await this.buscaReqLicenciamento()
                    } else {
                        //Bug 8794 - Voltando validação, uma vez que a projection só retorna a descrição do tipo de processo
                        if (this.requerimento.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS) {
                            const tipoProcesso = await this.tipoProcessoService.getById(this.requerimento.idTipoProcesso).toPromise();
                            //this.emissoesTitulo = await this.emissaoTituloService.readByEmissaoTituloByNumeroProcesso(this.requerimento.numeroProcesso).toPromise();
                            this.populaCompoTiposRequerimentoAut(tipoProcesso);
                        } else if (this.requerimento.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA) {
                            await this.buscaReqLicenciamentoEstudoAmbiental()
                            if (!this.idParametroObjetivo) {
                                this.snackBarService.showAlert('Não foi possível encontrar a Estudo Impacto Ambiental EIA/RIMA emitida.')
                                this.mostrarNaoEncontrado = true;
                                this.validado = false
                                return
                            }
                            this.tiposRequerimento = this.tiposRequerimento.filter(tp => tp.descricao.includes('Estudo Impacto Ambiental'))
                            this.opcaoSelecionada = 6
                        } else {
                            this.snackBarService.showAlert('Não foi possível encontrar a autorização/documento informado.')
                            this.mostrarNaoEncontrado = true;
                            this.validado = false
                            return
                        }
                    }
                    if (!this.falhou) {
                        this.mostrarEncontradoLegado = true;
                        this.validado = true
                    }
                } else {
                    this.snackBarService.showAlert('Este Requerimento ainda está em cadastro.')
                    this.mostrarNaoEncontrado = true;
                }
            }
        }).catch((e) => {
            console.debug('Erro inesperado', e);
            this.snackBarService.showAlert('Erro ao buscar o requerimento.')
            this.mostrarNaoEncontrado = true;
        })
        // }
    }

    async populaCompoTiposRequerimentoAut(tipoProcesso: TipoProcesso) {
        if (tipoProcesso) {
            let parametroNaoEmitido = this.parametros.find(p => p.idTipoAtuacao === 1 && p.situacao 
                && p.idTipoProcesso === tipoProcesso.id);
            let parametroEmitido = this.parametros.find(p => p.idTipoAtuacao === 2 && p.situacao 
                && p.idTipoProcesso === tipoProcesso.id);
    
            if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                this.idParametroObjetivo = parametroNaoEmitido.id;
                this.tiposRequerimento = this.tiposRequerimento.filter(tp => 
                    tp.tipoProcesso.id === tipoProcesso.id && tp.tipoAtuacao === 'NOVO');
            } else {
                this.idParametroObjetivo = parametroEmitido.id;
                this.tiposRequerimento = this.tiposRequerimento.filter(tp => 
                    tp.tipoProcesso.id === tipoProcesso.id && tp.tipoAtuacao === 'ANDAMENTO');
            }
        }
    }

    async buscaReqLicenciamentoEstudoAmbiental() {
        // let parametroNaoEmitido = this.parametros.find(p => p.descricao.includes('Estudo Impacto Ambiental (EIA/RIMA) não emitidas') && p.situacao && p.idTipoAtuacao === 2)
        let parametroEmitido = this.parametros.find(p => p.descricao.includes('Estudo Impacto Ambiental') && p.situacao && p.idTipoAtuacao === 2)
        if (this.requerimento.situacaoProcesso == 'CONCLUIDO') {
            this.idParametroObjetivo = parametroEmitido.id
        }
    }

    async buscaReqLicenciamento() {
        await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise().then(async reqLic => {
            await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(reqLic.id)
            .toPromise().then(async listaObjetivoLicenciamento => {
                this.falhou = false

                // TODO: Adicionar Enumerador/Constante para os tipos definidos em lei, não há forma de comparação lógica sem constantes que envolvem regras de negócio
                // let idsObjetivosLicenciamentoAmbiental = listaObjetivoLicenciamento.map((ol) => ol.idObjetivoLicenciamentoAmbiental);
                // let idsObjetivosLicenciamento = listaObjetivoLicenciamento.map((ol) => ol.id);

                // let tiposProcessosTrifasico = listaObjetivoLicenciamento.filter((ol) => ol.requerimentoLicenciamento.requerimento.tipoProcesso.instanciaRequerimento == 'LICENCIAMENTO_TRIFASICO')
                //     .map((ol) => ol.requerimentoLicenciamento.requerimento.tipoProcesso );
                // let tiposRequerimentosTrifasico = listaObjetivoLicenciamento.filter((ol) => ol.requerimentoLicenciamento.requerimento.tipoProcesso.instanciaRequerimento == 'LICENCIAMENTO_TRIFASICO')
                //     .map((ol) => ol.requerimentoLicenciamento.requerimento.tipoRequerimento );
                
                let LP = listaObjetivoLicenciamento.find(obj => LicenciamentoConfiguracaoUtil.isLicencaPrevia(obj.descricaoObjetivo))
                let LI = listaObjetivoLicenciamento.find(obj => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(obj.descricaoObjetivo));
                let LO = listaObjetivoLicenciamento.find(obj => LicenciamentoConfiguracaoUtil.isLicencaOperacao(obj.descricaoObjetivo))
                let LOP = listaObjetivoLicenciamento.find(obj => LicenciamentoConfiguracaoUtil.isLicencaOperacaoProvisoria(obj.descricaoObjetivo))
                let LOPM = listaObjetivoLicenciamento.find(obj => LicenciamentoConfiguracaoUtil.isLicencaOperacaoPesquisaMineral(obj.descricaoObjetivo))
                let LAS = listaObjetivoLicenciamento.find(obj => LicenciamentoConfiguracaoUtil.isLicencaAmbientalSimplificada(obj.descricaoObjetivo))
                
                let titulosParametros = []
                let arrayObjetivos;
                let tpBuscaParam;

                let mensagemDetalhe: string = null;

                for (let param of this.parametros) {
                    titulosParametros.push(...await this.pegaParametroObjetivo(param))
                }

                if (LP) {
                    if (LP && LI && LO) {
                        for (let titulo of titulosParametros) {
                            if (titulo.length === 3) {
                                if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                    let tituloLP = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLI = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLO = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacao(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    if (tituloLI && tituloLP && tituloLO) {
                                        tpBuscaParam = tituloLP
                                    }
                                } else {
                                    let tituloLP = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLI = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLO = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacao(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    if (tituloLI && tituloLP && tituloLO) {
                                        tpBuscaParam = tituloLP
                                    }
                                }

                                if (tpBuscaParam) {
                                    arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                    break;
                                }
                            }
                        }
                    } else if (LP && LI) {
                        for (let titulo of titulosParametros) {
                            if (titulo.length === 2) {
                                if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                    let tituloLP = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLI = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    if (tituloLI && tituloLP) {
                                        tpBuscaParam = tituloLP
                                    }
                                } else {
                                    let tituloLP = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLI = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    if (tituloLI && tituloLP) {
                                        tpBuscaParam = tituloLP
                                    }
                                }

                                if (tpBuscaParam) {
                                    arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                    break;
                                }
                            }
                        }

                    } else if (LP && LO) {
                        for (let titulo of titulosParametros) {
                            if (titulo.length === 2) {
                                if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                    let tituloLP = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLO = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacao(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    if (tituloLO && tituloLP) {
                                        tpBuscaParam = tituloLP
                                    }
                                } else {
                                    let tituloLP = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLO = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacao(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    if (tituloLO && tituloLP) {
                                        tpBuscaParam = tituloLP
                                    }
                                }

                                if (tpBuscaParam) {
                                    arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                    break;
                                }
                            }
                        }
                    } else {
                        for (let titulo of titulosParametros) {
                            if (titulo.length === 1) {
                                if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                    tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                } else {
                                    tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                }

                                if (tpBuscaParam) {
                                    arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                    break;
                                }
                                // else {
                                //     mensagemDetalhe = `LO sem conifgurações válidas (Parametrização)`;
                                // }
                            }
                        }
                    }
                } else if (LI) {
                    if (LI && LO) {
                        for (let titulo of titulosParametros) {

                            if (titulo.length === 2) {
                                // console.debug('LI/LO TITULO 2', titulo, this.requerimento, this.requerimento.situacaoProcesso);
                                if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                    let tituloLI = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLO = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacao(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)

                                    if (tituloLI && tituloLO) {
                                        tpBuscaParam = tituloLI
                                    }
                                } else {
                                    let tituloLI = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLO = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacao(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    if (tituloLI && tituloLO) {
                                        tpBuscaParam = tituloLO
                                    }
                                }

                                if (tpBuscaParam) {
                                    arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                    break;
                                }
                            }
                        }
                    } else {
                        for (let titulo of titulosParametros) {

                            if (titulo.length === 1) {

                                if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                    tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                } else {
                                    tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                }

                                if (tpBuscaParam) {
                                    arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                    break;
                                }

                            }

                            //TODO: FOI ACRESCENTADO ESTE TRECHO DE CÓDIGO PARA CONTEMPLAR A CONFIGURAÇÃO.
                            //LP. LI EMITIDAS PARA EMISSÃO DE UMA LO
                            if (titulo.length === 2) {
                                if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                    let tituloLP = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLI = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)

                                    if (tituloLI && tituloLP) {
                                        tpBuscaParam = tituloLP
                                    }
                                } else {
                                    let tituloLP = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaPrevia(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    let tituloLI = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaInstalacao(tit.titulo.descricao)  && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                                    if (tituloLI && tituloLP) {
                                        tpBuscaParam = tituloLI
                                    }
                                }

                                if (tpBuscaParam) {
                                    arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                    break;
                                }
                            }
                        }
                    }
                } else if (LO) {
                    for (let titulo of titulosParametros) {
                        if (titulo.length === 1) {
                            if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacao(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                            } else {
                                tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacao(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                            }

                            if (tpBuscaParam) {
                                arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                break;
                            }
                        }
                    }
                } else if (LOP) {
                    for (let titulo of titulosParametros) {
                        if (titulo.length === 1) {
                            if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacaoProvisoria(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                            } else {
                                tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacaoProvisoria(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                            }

                            if (tpBuscaParam) {
                                arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                break;
                            } 
                            // else {
                            //     mensagemDetalhe = `LOP sem conifgurações válidas (Parametrização)`;
                            // }
                        }
                    }
                    if (tpBuscaParam == null) {
                        mensagemDetalhe = `LOP sem conifgurações válidas (Parametrização)`;
                    }
                    
                } else if (LOPM) {
                    for (let titulo of titulosParametros) {
                        if (titulo.length === 1) {
                            if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacaoPesquisaMineral(tit.titulo.descricao) && !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                            } else {
                                tpBuscaParam = titulo.find(tit => LicenciamentoConfiguracaoUtil.isLicencaOperacaoPesquisaMineral(tit.titulo.descricao) && tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                            }

                            if (tpBuscaParam) {
                                arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                break;
                            }
                            // else {
                            //     mensagemDetalhe = `LOPM sem conifgurações válidas (Parametrização)`;
                            // }
                        }
                    }
                } else if (LAS) {
                    for (let titulo of titulosParametros) {
                        if (titulo.some(t => t.titulo.id === 102)) {
                            if (this.requerimento.situacaoProcesso != 'CONCLUIDO') {
                                tpBuscaParam = titulo.find(tit => !tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                            } else {
                                tpBuscaParam = titulo.find(tit =>tit.vinculo.isEmitido && tit.parametro.idTipoAtuacao === 2)
                            }

                            if (tpBuscaParam) {
                                arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam)
                                break;
                            } else {
                                mensagemDetalhe = `LAS sem conifgurações válidas (Parametrização)`;
                            }
                        }
                    }
                }

                console.debug(`LP=${JSON.stringify(LP)}\r\nLI=${JSON.stringify(LI)}\r\nLO=${JSON.stringify(LO)}\r\nLOP=${JSON.stringify(LOP)}\r\nLOPM=${JSON.stringify(LOPM)}\r\nLAS=${JSON.stringify(LAS)}`);

                if (!tpBuscaParam) {
                    if (mensagemDetalhe) {
                        this.snackBarService.showError(mensagemDetalhe)
                    } else {
                        this.snackBarService.showError('Ocorreu um erro ao buscar as configurações. Entre em contato com a equipe administrativa.');
                    }
                    this.falhou = true
                } else {
                    this.idParametroObjetivo = tpBuscaParam.parametro.id
                }
                if (arrayObjetivos) {
                    this.tiposRequerimento = [...new Map(arrayObjetivos.map((item) => [item['id'], item])).values()];
                }
            })
        })
    
    }
    

    async buscaParametroComTiposRequerimento() {
        this.parametros = await this.parametroObjetivoService.getAll().toPromise();
    }

    private async getListaTiposRequerimento() {
        await this.tipoRequerimentoService.getAll().pipe(
            take(1)
        ).subscribe(x => {
            this.tiposRequerimento = x
        });
    }

    // Bugfix 8559
    // public async filtrarTiposRequerimentoPorOpcaoSelecionada(opcaoSelecionada: any): Promise<void> {
    //     if (opcaoSelecionada.id === 2) {
    //         this.tiposRequerimento = this.tiposRequerimento.filter(item => item.id !== 35);
    //     }
    // }

    private async pegaParametroObjetivo(param) {
        let titulosParametros = []
        let titulos = await this.tituloParametrizacaoObjetivoService.getByParametrizacaoObjetivo(param.id).toPromise()

        // console.log("param", param, "titulos--",titulos )
        titulosParametros.push(titulos.map(titulo => ({
            titulo: this.titulos.find(t => t.id === titulo.idTituloRequerido),
            vinculo: titulo,
            parametro: param,
        })))

        return titulosParametros;
    }

    private async pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam) {
        let objs = await this.objetivoParametrizacaoObjetivoService.getByParametrizacaoObjetivo(tpBuscaParam.parametro.id).toPromise();
        return objs.map(obj => ({
            id: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento).id,
            descricao: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento).descricao,
            tipoAutuacao: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento).tipoAutuacao,
            tipoFluxo: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento).tipoFluxo,
        }))
    }

    autProtocolo() {
        this.opcaoSelecionada = 5
    }

    numeroRequerimentoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.numeroProtocolo = `${initial}/${year}`;
            }
        }
    }
    
    
    
    // isLicencaPrevia(descr: string) {
    //     if (descr == null) return false;

    //     let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
    //     return (
    //         ( normalizeUpperdDescrWords.find((w)=>w == 'LP') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'PREVIA')) )
    //     ) != null;
    // }

    // isLicencaInstalacao(descr: string) {
    //     if (descr == null) return false;
    //     let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
    //     return (
    //         ( normalizeUpperdDescrWords.find((w)=>w == 'LI') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'INSTALACAO')) )
    //     ) != null;
    // }

    // isLicencaOperacao(descr: string) {
    //     if (descr == null) return false;
    //     let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
    //     return (
    //         ( normalizeUpperdDescrWords.find((w)=>w == 'LO') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'OPERACAO') && !normalizeUpperdDescrWords.find((w)=>w == 'PROVISORIA')) )
    //     ) != null;
    // }

    // isLicencaOperacaoProvisoria(descr: string) {
    //     if (descr == null) return false;
    //     let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
    //     return (
    //         ( normalizeUpperdDescrWords.find((w)=>w == 'LOP') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'OPERACAO') && normalizeUpperdDescrWords.find((w)=>w == 'PROVISORIA')) )
    //     ) != null;
    // }

    // isLicencaOperacaoPesquisaMineral(descr: string) {
    //     if (descr == null) return false;
    //     let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
    //     return (
    //         ( normalizeUpperdDescrWords.find((w)=>w == 'LOPM') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'OPERACAO') && normalizeUpperdDescrWords.find((w)=>w == 'MINERAL')) )
    //     ) != null;
    // }


}




