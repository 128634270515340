import {CommonsUtil} from './../../../../../../../../shared/util/commons-util';
import {UsinaTipoBiomassa} from './../../../../../../../../shared/models/energia-termica-tipo-biomassa.model';
import {MatTableDataSource} from '@angular/material/table';
import {Dominio} from './../../../../../../../../shared/models/dominio-model';
import {SnackBarService} from './../../../../../../../../shared/snack-bar/snack-bar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SubDominio} from 'app/main/shared/models/subdominio-model';
import {DominioService} from '../../../../../../../../shared/services/dominio.service';
import {BaseModel} from '../../../../../../../../shared/models/base.model';
import {EmpreendimentoEnergeticoTermicaService} from '../empreendimento-energetico-termica.service';


@Component({
    selector: 'app-usina-biomassa',
    templateUrl: './usina-biomassa.component.html',
    styleUrls: ['./usina-biomassa.component.scss']
})
export class UsinaBiomassaComponent implements OnInit {

    @Input() idEEtermica: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;
    @Input() idTipoUsina: number;
    modoEdicao = false;

    @Output() enviarUsinasBiomassa = new EventEmitter<UsinaTipoBiomassa[]>();
    formUsinaBiomassa: FormGroup;

    usinasBiomassa: any[] = [];
    dataSourceUsinasBiomassa = new MatTableDataSource<any>([]);

    @Output() save: EventEmitter<any> = new EventEmitter<any>();

    displayedColumns: string[] = ['tipo', 'subtipo', 'descricao'];

    tiposBiomassa: Dominio[] = [];
    subTiposBiomassa: SubDominio[] = [];

    mapTipoBiomassa = new Map();

    mostrarCampoOutroTipo = false;
    mostrarCampoOutroSubTipo = false;

    idUsinaBiomassaTemp = 0;
    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    constructor(
        private fb: FormBuilder,
        private dominioService: DominioService,
        private snackBarService: SnackBarService,
        private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService,
    ) {
    }

    ngOnInit() {
        this.dominioService.buscarTipoBiomassa()
            .subscribe(retorno => {

                this.tiposBiomassa = retorno;
                this.tiposBiomassa.forEach(item => {
                    this.mapTipoBiomassa.set(`1-${item.id}`, item.descricao);
                });
            }, error => {

                console.error(error);
            });

        if (this.idEEtermica) {
            this.carregaUsinaBiomassaCasoExistaIdEEtermica(this.idEEtermica);
        }

        this.formUsinaBiomassa = this.fb.group({
            id: [''],
            idTemp: [null],
            empreendimentoEnergeticoTermico: [null],
            tipoBiomassa: ['', [Validators.required]],
            subTipoBiomassa: ['', [Validators.required]],
            descricaoTipo: [''],
            descricaoSubTipo: [''],
        });

        if (this.isNaoPodeEditar) {
            this.formUsinaBiomassa.disable();
        }
    }

    changeTipoBiomassa(tipo: any): void {
        this.getSubTipoBiomassa(tipo.value.id);
        this.selecaoTipo(tipo.value);
    }

    changeSubTipoBiomassa(subtipo: any): void {
        this.selecaoSubTipo(subtipo.value);
    }

    getSubTipoBiomassa(id: number): void {
        this.dominioService.buscarSubTipoBiomassa()
            .subscribe(subTipoBiomassa => {
                this.subTiposBiomassa = subTipoBiomassa.filter(subTipo => subTipo.idTipoBiomassa === id);
            }, error => {
                console.error(error);
            });
    }

    adicionaUsinaBiomassa(): void {
        const validacao = this.validarFormulario();
        if (validacao) {
            let descricao;
            if (this.formUsinaBiomassa.controls.descricaoTipo.value) {
                descricao = this.formUsinaBiomassa.controls.descricaoTipo.value;
            } else if (this.formUsinaBiomassa.controls.descricaoSubTipo.value) {
                descricao = this.formUsinaBiomassa.controls.descricaoSubTipo.value;
            }

            this.usinasBiomassa.push({
                id: null,
                idTemp: this.idUsinaBiomassaTemp,
                tipoBiomassa: this.formUsinaBiomassa.controls.tipoBiomassa.value,
                subTipoBiomassa: this.formUsinaBiomassa.controls.subTipoBiomassa.value,
                descricao: descricao ? descricao : null,
            });

            this.dataSourceUsinasBiomassa = new MatTableDataSource(this.usinasBiomassa);
            this.enviarUsinas(this.mapearArray());
            this.mostrarCampoOutroTipo = false;
            this.mostrarCampoOutroSubTipo = false;
            this.idUsinaBiomassaTemp++;
            this.formUsinaBiomassa.reset();
        }

    }

    enviarUsinas(usinas: UsinaTipoBiomassa[]): void {
        this.enviarUsinasBiomassa.emit(usinas);
    }

    deletarItem(item, acao?: string, itemId?: number): void {
        if (acao === 'DELETAR' && itemId != null) {
            this.empreendimentoEnergService.deletarUsinaBiomassaPorId(itemId).subscribe(() => {
                this.snackBarService.showSuccess('Usina Biomassa excluido com sucesso.');
            });
        }
        let data;
        data = this.usinasBiomassa.filter((value, key) => {
            return value.idTemp !== item;
        });
        this.usinasBiomassa = data;
        this.dataSourceUsinasBiomassa = new MatTableDataSource(data);
    }

    selecaoTipo(unidadeSelecionada): void {
        if (unidadeSelecionada.descricao == 'Outro') {
            this.mostrarCampoOutroTipo = true;
            this.mostrarCampoOutroSubTipo = false;
            this.formUsinaBiomassa.controls.subTipoBiomassa.disable();
            this.formUsinaBiomassa.controls.subTipoBiomassa.reset();
        } else {
            this.formUsinaBiomassa.controls.descricaoTipo.reset();
            this.formUsinaBiomassa.controls.subTipoBiomassa.enable();
            this.mostrarCampoOutroTipo = false;
        }
    }

    selecaoSubTipo(unidadeSelecionada): void {
        if (unidadeSelecionada.descricao == 'Outro') {
            this.mostrarCampoOutroSubTipo = true;
            this.mostrarCampoOutroTipo = false;
        } else {
            this.formUsinaBiomassa.controls.descricaoSubTipo.reset();
            this.mostrarCampoOutroSubTipo = false;
        }
    }

    mapearArray(): any {
        if (this.usinasBiomassa.length == 0) {
            return this.usinasBiomassa;
        } else {
            return this.usinasBiomassa.map(function (item) {
                return new UsinaTipoBiomassa(
                    null,
                    null,
                    item.tipoBiomassa.id,
                    item.subTipoBiomassa ? item.subTipoBiomassa.id : null,
                    item.descricao
                );
            });
        }
    }

    async ativarModoEdicaoUsina(usina: any): Promise<void> {
        await this.dominioService.buscarSubTipoBiomassa().toPromise().then(subTipoBioMassa => {
            this.subTiposBiomassa = subTipoBioMassa.filter(subTipo => subTipo.idTipoBiomassa === usina.subTipoBiomassa.idTipoBiomassa);
        });

        this.formUsinaBiomassa.controls.id.patchValue(usina.id);
        this.formUsinaBiomassa.controls.idTemp.patchValue(usina.idTemp);
        this.formUsinaBiomassa.controls.tipoBiomassa.patchValue(usina.tipoBiomassa);
        this.selecaoTipo(usina.tipoBiomassa);
        this.formUsinaBiomassa.controls.subTipoBiomassa.patchValue(usina.subTipoBiomassa);
        if (usina.subTipoBiomassa) {
            this.selecaoSubTipo(usina.subTipoBiomassa);
        }
        if (usina.tipoBiomassa.descricao === 'Outro') {
            this.formUsinaBiomassa.controls.descricaoTipo.patchValue(usina.descricao);
        } else if (usina.subTipoBiomassa.descricao === 'Outro') {
            this.formUsinaBiomassa.controls.descricaoSubTipo.patchValue(usina.descricao);
        }
        this.modoEdicao = true;

    }

    editarUsina(): void {
        const validacao = this.validarFormulario();
        if (validacao) {
            this.deletarItem(this.formUsinaBiomassa.controls.idTemp.value);
            this.adicionaUsinaBiomassa();
            this.modoEdicao = false;

        }

    }

    fecharEdicao(): void {
        this.modoEdicao = false;
        this.mostrarCampoOutroTipo = false;
        this.mostrarCampoOutroSubTipo = false;
        this.formUsinaBiomassa.reset();
        this.formUsinaBiomassa.markAsUntouched();
        this.formUsinaBiomassa.updateValueAndValidity();
    }

    validarFormulario(): boolean {
        if (this.formUsinaBiomassa.valid) {
            if (this.formUsinaBiomassa.value.tipoBiomassa.descricao === 'Outro' &&
                CommonsUtil.isEmpty(this.formUsinaBiomassa.controls.descricaoTipo.value)) {
                this.formUsinaBiomassa.markAllAsTouched();
                return false;
            } else if (this.formUsinaBiomassa.value.subTipoBiomassa) {
                if (this.formUsinaBiomassa.value.subTipoBiomassa.descricao === 'Outro' &&
                    CommonsUtil.isEmpty(this.formUsinaBiomassa.controls.descricaoSubTipo.value)) {
                    this.formUsinaBiomassa.markAllAsTouched();
                    return false;
                }
            }
            return true;
        } else {
            this.snackBarService.showAlert(
                'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            this.formUsinaBiomassa.markAllAsTouched();
            return false;
        }
    }

    verificarVazio(elemento): boolean {
        return elemento === '' || elemento == null;
    }

    carregaUsinaBiomassaCasoExistaIdEEtermica(idEEtermica: number): void {
        this.empreendimentoEnergService.buscaListUsinaBiomassa(idEEtermica).subscribe(async result => {
            for (const usinaBiomassa of result) {
                const tipoBiomassa = await this.tiposBiomassa.filter(tipo => tipo.id === usinaBiomassa.idTipoBiomassa);
                const listSubTipo = await this.dominioService.buscarSubTipoBiomassa().toPromise();
                const SubTipo = listSubTipo.filter(subTipo => subTipo.id === usinaBiomassa.idSubTipoBiomassa);

                this.usinasBiomassa.push({
                    id: usinaBiomassa.id,
                    idTemp: this.idUsinaBiomassaTemp,
                    tipoBiomassa: tipoBiomassa[0],
                    subTipoBiomassa: SubTipo[0],
                    descricao: usinaBiomassa.descricao ? usinaBiomassa.descricao : '',
                });
                this.dataSourceUsinasBiomassa = new MatTableDataSource(this.usinasBiomassa);
                this.idUsinaBiomassaTemp++;
            }
        });
    }
}

    
