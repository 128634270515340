import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {AnaliseConsema} from "../models/analise-consema.model";

@Injectable({
    providedIn: "root"
})
export class AnaliseConsemaService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_GATEWAY;

    salvarPareceTecnicoConsema(analiseConsema: AnaliseConsema, idTarefa): Observable<AnaliseConsema> {
        const url = `${this.urlResource}/api/parecer-tecnico-consema/${idTarefa}`;
        return this.http.post(url, analiseConsema)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

}
