import {BaseModel} from 'app/main/shared/models/base.model';

export class Madeira extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: number,
        public isUtilizaMadeira?: boolean,
        public isMadeiraNativa?: boolean,
        public isMadeiraReflorestamento?: boolean,
        public isUtilizaPicador?: boolean,
        public isExisteDeposito?: boolean,
    ) {
        super();
    }

    static fromJson(json: any): Madeira {
        if (json === undefined || json === null) {
            return null;
        }
        return new Madeira(
            json.id,
            json.requerimento,
            json.isUtilizaMadeira,
            json.isMadeiraNativa,
            json.isMadeiraReflorestamento,
            json.isUtilizaPicador,
            json.isExisteDeposito
        );
    }

    static fromJsons(json: any): Madeira[] {
        const Madeiras: Madeira[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(Madeira => Madeiras.push(this.fromJson(Madeira)));
            return Madeiras;
        }
    }

}
