import {Component, OnInit} from '@angular/core';
import {BasePontoComponent} from '../base-ponto.component';
import {BaseModel} from '../../../../../shared/models/base.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {AguaSubterranea} from './agua-subterranea.model';
import {CommonsUtil} from '../../../../../shared/util/commons-util';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {FormUtil} from "../../../../../shared/util/form-util";
import {DatePipe} from "@angular/common";
import {DocumentoRequerimentoService} from "../../../../documentos-requerimentos/documento-requerimento.service";
import {DocumentoRequerimento} from "../../../../documentos-requerimentos/documento-requerimento.model";
import {ArquivoService} from "../../../../../shared/services/arquivo.service";
import {PdfViewerDialog} from "../../../../../shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model";

function nrAutorizacaoPerfuracaoValidator(control: FormControl): any {

    if (parseInt(control.value, 10) <= 0) {
        return {
            nrAutorizacaoPerfuracaoValidator: true
        };
    }
    return null;
}

@Component({
    selector: 'app-objetivo-agua-subterranea',
    templateUrl: './objetivo-agua-subterranea.component.html',
})
export class ObjetivoAguaSubterraneaComponent extends BasePontoComponent implements OnInit {
    tipoPorosidade = AguaSubterranea.tiposPorosidade;
    tipoFonteAbastecimento = AguaSubterranea.tiposFontesAbastecimento;
    tipoBomba = AguaSubterranea.tiposBombas;

    form: FormGroup;
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;
    requerirDeclaracao: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private pontoService: PontoService,
        private snackBarService: SnackBarService,
        private documentoRequerimentoService: DocumentoRequerimentoService,
        private arquivoService: ArquivoService,
        private pdfViewerDialog: PdfViewerDialog,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit(): void {
        this.construirForm();
        this.patchForms();
        this.onChangeTipoDeObra();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    private patchForms(): void {
        if (this.ponto.aguaSubterranea) {
            if (this.ponto.aguaSubterranea.nrAutorizacaoPerfuracao) {
                this.ponto.aguaSubterranea.nrAutorizacaoPerfuracao = this.ponto.aguaSubterranea.nrAutorizacaoPerfuracao.toString().padStart(10, '0');
            }
            this.form.patchValue(this.ponto.aguaSubterranea);
            this.form.patchValue({
                horaTesteInicio: this.datePipe.transform(this.ponto.aguaSubterranea.horaTesteInicio, 'dd/MM/yyyy HH:mm:ss'),
                horaTesteFim: this.datePipe.transform(this.ponto.aguaSubterranea.horaTesteFim, 'dd/MM/yyyy HH:mm:ss')
            });
        }
    }

    private construirForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            bombeamentoHoras: [null, [Validators.required, Validators.min(1), Validators.max(24)]],
            bombeamentoDias: [null, [Validators.required, Validators.min(1), Validators.max(7)]],
            bombeamentoVazao: [null, [Validators.required, Validators.min(0.00001)]],
            possuiPerfuracaoPoco: [null, [Validators.required]],
            nrAutorizacaoPerfuracao: [null, [Validators.required, nrAutorizacaoPerfuracaoValidator]],
            dataPerfuracao: [null, [Validators.required]],
            declaracaoPerfuracao: [null],
            tipoAquiferoPorosidade: [null, [Validators.required, Validators.maxLength(255)]],
            outrasFontes: [null, [Validators.required]],
            especificarOutrasFontes: [null],
            sistemaIndependente: [null, [Validators.required]],
            materialRevestimento: [null, [Validators.required, Validators.maxLength(255)]],
            materialFiltro: [null, [Validators.required, Validators.maxLength(255)]],
            materialPreFiltro: [null, [Validators.required, Validators.maxLength(255)]],
            diametroPerfuracao: [null, [Validators.required, Validators.min(0.00001)]],
            diametroRevestimento: [null, [Validators.required, Validators.min(0.00001)]],
            diametroFiltro: [null, [Validators.required, Validators.min(0.00001)]],
            numeroHidrometro: [null, [Validators.required, Validators.maxLength(15)]],
            alturaBocaPoco: [null, [Validators.required, Validators.min(0.00001)]],
            topoAquifero: [null, [Validators.required, Validators.min(0.00001)]],
            baseAquifero: [null, [Validators.required, Validators.min(0.00001)]],
            cotaTerreno: [null, [Validators.required, Validators.min(0.00001)]],
            tipoBomba: [null, [Validators.required]],
            potenciaMotor: [null, [Validators.required, Validators.min(0.00001)]],
            crivo: [null, [Validators.required, Validators.min(0.00001)]],
            nomeMotor: [null, [Validators.required, Validators.maxLength(255)]],
            modeloMotor: [null, [Validators.required, Validators.maxLength(255)]],
            marcaMotor: [null, [Validators.required, Validators.maxLength(255)]],
            observacoesMotor: [null, [Validators.maxLength(255)]],
            tempoBombeamento: [null, [Validators.required, Validators.min(1)]],
            metodoMedicaoVazao: [null, [Validators.maxLength(255)]],
            horaTesteInicio: [null, [Validators.required]],
            horaTesteFim: [null, [Validators.required]],
            profundidadePoco: [null, [Validators.required, Validators.min(0.00001)]],
            empresaExecutoraTeste: [null, [Validators.maxLength(255)]],
            vazaoTeste: [null, [Validators.required, Validators.min(0.00001)]],
            nivelEstBombeamento: [null, [Validators.required, Validators.min(0.00001)]],
            nivelDinBombeamento: [null, [Validators.required, Validators.min(0.00001)]],
            vazaoEspecifica: [null, [Validators.required, Validators.min(0.00001)]],
            testeBombeamento: [null, Validators.required],
            testeRecuperacao: [null, Validators.required],
        });

        this.form.get('possuiPerfuracaoPoco').valueChanges
            .subscribe(value => {
                const ctrlNrAutorizacao = this.form.get('nrAutorizacaoPerfuracao');
                const ctrlAnexoAutorizacao = this.form.get('declaracaoPerfuracao');
                if (value) {
                    ctrlNrAutorizacao.setValidators([Validators.required, nrAutorizacaoPerfuracaoValidator]);
                    ctrlNrAutorizacao.updateValueAndValidity();

                    FormUtil.clearControl(ctrlAnexoAutorizacao);
                    this.requerirDeclaracao = false;
                } else {
                    FormUtil.clearControl(ctrlNrAutorizacao);

                    ctrlAnexoAutorizacao.setValidators(Validators.required);
                    ctrlAnexoAutorizacao.updateValueAndValidity();
                    this.requerirDeclaracao = true;
                }
            });

        this.form.get('testeBombeamento').valueChanges.subscribe(file => {
            if (file instanceof File) {
                const docRequerimento: DocumentoRequerimento = new DocumentoRequerimento();
                docRequerimento.titulo = 'Teste de bombeamento.'
                docRequerimento.requerimento = this.ponto.requerimentoOutorga.requerimento;
                docRequerimento.tipo = 'TESTE_BOMBEAMENTO';
                this.documentoRequerimentoService.createMultipart(docRequerimento, file, true).subscribe(result =>
                    this.form.get('testeBombeamento').setValue(result), e => {
                    this.snackBarService.showError("Erro ao fazer upload do arquivo", e);
                    this.form.get('testeBombeamento').reset();
                });
            }
        })

        this.form.get('testeRecuperacao').valueChanges.subscribe(file => {
            if (file instanceof File) {
                const docRequerimento: DocumentoRequerimento = new DocumentoRequerimento();
                docRequerimento.titulo = 'Teste de recuperação.'
                docRequerimento.requerimento = this.ponto.requerimentoOutorga.requerimento;
                docRequerimento.tipo = 'TESTE_RECUPERACAO';
                this.documentoRequerimentoService.createMultipart(docRequerimento, file, true).subscribe(result =>
                    this.form.get('testeRecuperacao').setValue(result), e => {
                    this.snackBarService.showError("Erro ao fazer upload do arquivo", e);
                    this.form.get('testeRecuperacao').reset();
                });
            }
        })

        this.form.get('declaracaoPerfuracao').valueChanges.subscribe(file => {
            if (file instanceof File) {
                const docRequerimento: DocumentoRequerimento = new DocumentoRequerimento();
                docRequerimento.titulo = 'Declaração da data de perfuração do poço autorizado.'
                docRequerimento.requerimento = this.ponto.requerimentoOutorga.requerimento;
                docRequerimento.tipo = 'DECLARACAO_PERFURACAO_POCO';
                this.documentoRequerimentoService.createMultipart(docRequerimento, file, true).subscribe(result =>
                    this.form.get('declaracaoPerfuracao').setValue(result));
            }
        })
    }

    salvarPonto(): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            const ponto = Ponto.fromJson(this.ponto);
            ponto.aguaSubterranea = AguaSubterranea.fromJson(this.form.value);
            ponto.aguaSubterranea.horaTesteInicio = CommonsUtil.stringToDate(this.form.get('horaTesteInicio').value)
            ponto.aguaSubterranea.horaTesteFim = CommonsUtil.stringToDate(this.form.get('horaTesteFim').value)
            ponto.formularioConcluido = true;

            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). ' +
                'Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    onChangeTipoDeObra(): void {
        const select = this.form.controls.outrasFontes;
        const field = this.form.controls.especificarOutrasFontes;

        CommonsUtil.baseOnChange(select.value === 'OUTROS', select, field,
            [Validators.required, Validators.maxLength(255)]);
    }

    visualizarArquivo(docRequerimento: DocumentoRequerimento) {
        if (docRequerimento.nomeArquivo.endsWith('.pdf')) {
            this.arquivoService.download(docRequerimento.chave, 'application/pdf').subscribe(result =>
                this.pdfViewerDialog.show(result));
        } else {
            this.arquivoService.download(docRequerimento.chave, 'application/ms-excel').subscribe(result =>
                window.open(window.URL.createObjectURL(result)));
        }
    }
}
