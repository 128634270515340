import {BaseModel} from "./base.model";

export class ResiduoAterroIndUrbMunicipiosDto extends BaseModel {
    constructor(
        public id?: number,
        public codigoIbgeMunicipio?: number,
        public possuiColetaSeletiva?: number,
        public possuiOutrasFormasResiduo?: number,
        public idFormaDisponibilizacaoResiduos?: number,
        public outros?: string,
    ) {
        super();
    }

    static fromJson(json: any): ResiduoAterroIndUrbMunicipiosDto {
        json = json || {};

        return new ResiduoAterroIndUrbMunicipiosDto(
            json.codigoIbgeMunicipio,
            json.possuiColetaSeletiva,
            json.possuiOutrasFormasResiduo,
            json.idFormaDisponibilizacaoResiduos,
            json.outros,
        );
    }
}