import {Injectable, Injector} from '@angular/core';
import {ListResolver} from '../resolvers/list-resolver';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {EmpreendimentoEnergiaEolica} from '../models/empreendimento-energia-eolica.model';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import { EmpreendimentoEnergiaEolicaTecnologia } from '../models/empreendimento-energia-eolica-tecnologia.model';


@Injectable({
    providedIn: "root"
})
export class EmpreendimentoEnergiaEolicaTecnologiaService extends BaseService<EmpreendimentoEnergiaEolicaTecnologia> {

    constructor(protected injector: Injector) {
        super(
            "/api/empreedimento-energia-eolica-tipo-tecnologia",
            injector,
            EmpreendimentoEnergiaEolicaTecnologia.prototype,
            EmpreendimentoEnergiaEolicaTecnologia.fromJson
        );
    }

    getByEmpreendimento(idEmpreendimento: number): Observable<Array<EmpreendimentoEnergiaEolicaTecnologia>> {
        const url = `${this.urlEeEolica}/empreendimento/${idEmpreendimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
