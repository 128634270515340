import {Injectable, Injector} from '@angular/core';
import {CompostoQuimico} from './composto-quimico.model';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Page} from 'app/main/shared/models/page.model';

@Injectable()
export class CompostoQuimicoListResolver extends ListResolver<CompostoQuimico> {
    constructor(service: CompostoQuimicoService) {
        super(service);
    }
}

@Injectable()
export class CompostoQuimicoResolver extends DetailResolver<CompostoQuimico> {
    constructor(
        service: CompostoQuimicoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CompostoQuimicoService extends BaseService<CompostoQuimico> {
    constructor(protected injector: Injector) {
        super(
            '/api/compostos-quimicos/',
            injector,
            CompostoQuimico.prototype,
            CompostoQuimico.fromJson
        );
    }

    getAllToPage(): Observable<Page<CompostoQuimico>> {
        const url = `${this.urlResource}`;
        return this.http.get(url).pipe(
            map(response => this.jsonToPage(response)),
            catchError(this.handleError.bind(this))
        );
    }
}
