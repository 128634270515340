import {
    ChangeDetectionStrategy,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {TipoProcesso} from '../../tipos-processo/tipo-processo.model';
import {TipoProcessoService} from '../../tipos-processo/tipo-processo.service';
import {Titulacao} from '../../titulacoes/titulacao.model';
import {TitulacaoService} from '../../titulacoes/titulacao.service';
import {Requerimento} from '../requerimento.model';
import {RequerimentoService} from '../requerimento.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BaseEnum} from '../../../shared/enums/base.enum';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Processo} from '../../../shared/models/processo.model';
import {FormUtil} from "../../../shared/util/form-util";
import { ServidorService } from 'app/main/shared/services/servidor.service';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { Servidor } from 'app/main/shared/models/servidor.model';
import { ResponsavelTecnico } from 'app/main/shared/models/responsavel-tecnico.model';
import { RequerimentoResponsavelTecnicoComponent } from '../shared/requerimento-responsaveis/responsavel-tecnico/requerimento-responsavel-tecnico.component';
import { AuthenticationService } from 'app/main/core/services/authentication.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-requerimento-consultar-processos',
    templateUrl: './requerimento-consultar-processos.component.html',
    styleUrls: ['./requerimento-consultar-processos.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoConsultarProcessosComponent
    implements OnInit, OnChanges {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    form: FormGroup;
    tiposProcesso: Array<TipoProcesso>;
    titulacoes: Array<Titulacao>;
    dataSource: MatTableDataSource<Requerimento>;
    columnAtributes: string[] = ['id', 'numeroProcesso', 'numero', 'cpfCnpj', 'tipoProcesso', 'situacaoProcesso', 'dataAlteracao', 'buttons'];
    totalElements: number;
    isUsuarioLogadoServidor: boolean;
    cpfUsuarioLogado: string;
    isUsuarioResponsavelTecnicoProcesso: boolean = false;
    usuarioLogado;

    comparador = (a: Requerimento, b: Requerimento) =>
        a && b ? a.id === b.id : a === b

    constructor(
        injector: Injector,
        protected tipoProcessoService: TipoProcessoService,
        protected titulacaoService: TitulacaoService,
        public service: RequerimentoService,
        public servidorService: ServidorService,
        private snackBarService: SnackBarService,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private router: Router,
    ) {

    }

    ngOnInit(): void {
        this.isUsuarioServidor();
        this.dataSource = new MatTableDataSource<Requerimento>();
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'numero':
                    const numeroSplit = item.numero.split('/');
                    return +numeroSplit[1] + (+numeroSplit[0] * 10000);
                default:
                    return item[property];
            }
        };
        this.getTipoProcesso();
        this.getTitulacoes();
        this.form = this.fb.group({
            numeroRequerimento: [],
            numeroProcesso: [],
            nomeInteressado: [],
            nomeEmpreendimento: [],
            cpfCnpjInteressado: [],
            dataInicio: [],
            dataFim: [],
            situacaoProcesso: [],
            tipoProcessoId: [],
            titulacaoId: []
        });
    }

    getTipoProcesso(): void {
        this.tipoProcessoService
            .getAll()
            .subscribe(
                tiposProcesso => (this.tiposProcesso = tiposProcesso)
            );
    }

    get situacoesProcesso(): BaseEnum[] {
        return Requerimento.situacoesProcesso;
    }

    getTitulacoes(): void {
        this.titulacaoService
            .getAll()
            .subscribe(titulacoes => (this.titulacoes = titulacoes));
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.form.get('cpfCnpjInteressado').value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    numeroProcessoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.form.get('numeroProcesso').patchValue(`${initial}/${year}`);
            }
        }
    }

    numeroRequerimentoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.form.get('numeroRequerimento').patchValue(`${initial}/${year}`);
            }
        }
    }

    consultar(): void {
        FormUtil.resetPaginator(this.paginator);
        this.listarProcessos();
    }

    listarProcessos(): void {
        this.service.listarProcessosRequerimentos(this.form, this.paginator).subscribe(response => {
            this.dataSource.data = response.content;
            this.totalElements = response.totalElements;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    public visualizarPaginaProcesso(item: Requerimento): void {  
        this.isInteresados(item).then(res => {
            if (!res) {
                this.snackBarService.showError('Acesso não autorizado à visualização do processo.');
                return;
            }
            this.router.navigateByUrl(`/requerimentos/${item.id}/pagina-processos/1`);
        });      
    }

    isEllipsisActive(e): boolean {
        return (e.offsetWidth < e.scrollWidth);
    }

    limpar(): void {
        this.form.reset();
        this.dataSource.data = [];
        FormUtil.resetPaginator(this.paginator);
    }

    isUsuarioServidor(): void {
        this.servidorService.isUsuarioLogadoServidor().subscribe(response => {
            this.isUsuarioLogadoServidor = response.servidor;
            this.cpfUsuarioLogado = response.cpf;
        });
    }


    isServidor():boolean {
        return this.isUsuarioLogadoServidor;
    }

    async isInteresados(requerimento:Requerimento): Promise<boolean> {
        if(this.cpfUsuarioLogado) {
            return await this.isUsuarioLogadoResponsavelTecnicoProcesso(requerimento.id, this.cpfUsuarioLogado).toPromise()
                || this.cpfUsuarioLogado === requerimento.cpfCadastrante
                || this.cpfUsuarioLogado === requerimento.pessoa.cpfCnpj 
                || this.isUsuarioResponsavelTecnicoProcesso
                || this.isUsuarioLogadoServidor;
        }  
        return false;
    }

    isUsuarioLogadoResponsavelTecnicoProcesso(idRequerimento: number, cpfUsuarioLogado: string): Observable<boolean> {
        return this.service.isUsuarioLogadoResponsavelTecnicoProcesso(idRequerimento, cpfUsuarioLogado).pipe(map(response => {
            this.isUsuarioResponsavelTecnicoProcesso = response;
            return this.isUsuarioResponsavelTecnicoProcesso;
        }, e => this.snackBarService.showError('Erro ao verificar se o usuário logado é responsável técnico no processo.', e)));
    }

    /**
     * Funcionalidade utilizada somente para testes utilizando os profiles do ambiente de DESENVOLVIMENTO para testes 
     */
    pagarDar(requerimento: Requerimento): void {
        this.service.pagarDar(requerimento).subscribe(() => {
            this.snackBarService.showSuccess('A data de arrecadação foi definida com sucesso!\n\nAguarde até 3 minutos para que a rotina faça a baixa dos lancamentos!');
        }, e => this.snackBarService.showError('Ocorreu um erro.', e));
    }

    isHomologacao(): boolean {
        return environment.name !== 'production' && environment.name !== 'prod';
    }

    isLessOrEquals31122023(): boolean {
        return new Date() <= new Date(2023, 12, 31, 23, 59, 59, 999);
    }

}
