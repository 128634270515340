import {BaseModel} from "./base.model";
import {AguaPassivoSupressao} from "./agua-passiva-supressao.model";

export class AguaPassivoSupressaoTipoLancamento extends BaseModel {
    constructor(
        public id: number,

        public idTipoLancamento: number,
        public descricao: string,
        public aguaPassivoSupressao: AguaPassivoSupressao,
    ) {
        super();
    }

    static fromJson(json: any): AguaPassivoSupressaoTipoLancamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new AguaPassivoSupressaoTipoLancamento(
            json.id,
            json.idTipoLancamento,
            json.descricao,
            json.aguaPassivoSupressao
        );
    }

}
