import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../../../../../../shared/shared.module";
import {NgxLoadingModule} from "ngx-loading";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTableModule} from "@angular/material/table";
import {MatSelectModule} from "@angular/material/select";
import {MatRadioModule} from "@angular/material/radio";
import {MatFormFieldModule} from "@angular/material/form-field";
import {RouterModule, Routes} from "@angular/router";
import {CapiaComponent} from "./capia/capia.component";
import {EeFotovoltaicaComponent} from "./ee-fotovoltaica/ee-fotovoltaica.component";
import {EeHidricaComponent} from "./ee-hidrica/ee-hidrica.component";
import {EeLinhaTransmissaoComponent} from "./ee-linha-transmissao/ee-linha-transmissao.component";
import {EeSubestacaoComponent} from "./ee-subestacao/ee-subestacao.component";
import {ClassificacaoFuncaoComponent} from './ee-subestacao/classificacao-funcao/classificacao-funcao.component';
import {FormasOperacaoComponent} from './ee-subestacao/formas-operacao/formas-operacao.component';
import {TiposConstrutivosComponent} from './ee-subestacao/tipos-construtivos/tipos-construtivos.component';
import {MeioIsolamentoComponent} from './ee-subestacao/meio-isolamento/meio-isolamento.component';
import {TipoSubestacaoComponent} from './ee-subestacao/tipo-subestacao/tipo-subestacao.component';
import {ProjetoSubestacaoComponent} from './ee-subestacao/projeto-subestacao/projeto-subestacao.component';
import {MaterialEstruturasSuporteComponent} from './ee-subestacao/material-estruturas-suporte/material-estruturas-suporte.component';
import {MeioIsolacaoComponent} from './ee-subestacao/meio-isolacao/meio-isolacao.component';
import {ComponentesSubestacaoComponent} from './ee-subestacao/componentes-subestacao/componentes-subestacao.component';
import {SubestacaoExternaComponent} from './ee-subestacao/tipo-subestacao/subestacao-externa/subestacao-externa.component';
import {SubestacaoInternaComponent} from './ee-subestacao/tipo-subestacao/subestacao-interna/subestacao-interna.component';
import {EmpresaDerivacaoComponent} from './ee-subestacao/projeto-subestacao/empresa-derivacao/empresa-derivacao.component';
import {EeTermicaComponent} from "./ee-termica/ee-termica.component";
import {GerenciamentoResiduosComponent} from "./gerenciamento-residuos/gerenciamento-residuos.component";
import {IndustriaBiocombustivelComponent} from "./industria-biocombustivel/industria-biocombustivel.component";
import {AguaPassivoSupressaoComponent} from "./agua-passivo-supressao/agua-passivo-supressao.component";
import {EeEolicaComponent} from "./ee-eolica/ee-eolica";
import {IndustriaFrigorificoComponent} from './industria-frigorifico/industria-frigorifico.component';
import {InfraObraFluviaisSaneamentoComponent} from "./infra-obra-fluviais-saneamentos/infra-obra-fluviais-saneamento.component";
import {InfraestruturaObrasViariasComponent} from "./infraestrutura-obras-viarias/infraestrutura-obras-viarias.component";
import {InfraParcelSoloResComComponent} from "./infra-parcel-solo-res-com/infra-parcel-solo-res-com.component";
import {MadeiraComponent} from "./madeira/madeira.component";
import {MineracaoComponent} from "./mineracao/mineracao.component";
import {ResiduosAterroIndustrialUrbanoComponent} from "./residuos-aterro-industrial-urbano/residuos-aterro-industrial-urbano.component";
import {ResiduosCemiterioCrematorioComponent} from './residuos-cemiterio-crematorio/residuos-cemiterio-crematorio.component';
import {ResiduosGeralComponent} from "./residuos-geral/residuos-geral.component";
import {ResiduosRccComponent} from "./residuos-rcc/residuos-rcc.component";
import {ResiduosTransporteProdutosPerigososComponent} from "./residuos-transporte-produtos-perigosos/residuos-transporte-produtos-perigosos.component";
import {ResiduosTratamentoTermicoComponent} from "./residuos-tratamento-termico/residuos-tratamento-termico.component";
import {ServicoCombustivelComponent} from "./servico-combustivel/servico-combustivel.component";
import {ServicoGeralComponent} from "./servico-geral/servico-geral.component";
import {SistemaTratamentoComponent} from "./sistema-tratamento/sistema-tratamento.component";
import {SuimisAtividadesComponent} from "./suimis-atividades/suimis-atividades.component";
import {GeradorEnergiaComponent} from './ee-eolica/geradores-energia/geradores-energia.component';
import {TecnologiaEmpreendimentoEnergeticoComponent} from './ee-eolica/tecnologia-empreendimento-energetico/tecnologia-empreendimento-energetico.component';
import {TanqueComponent} from "./servico-combustivel/tanque/tanque.component";
import {ParqueBombaComponent} from "./servico-combustivel/area-abastecimento/parque-bomba/parque-bomba.component";
import {MatButtonModule, MatCheckboxModule, MatInputModule} from '@angular/material';
import {FlexModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AbaAtividadesModule} from 'app/main/shared/components/aba-atividades/aba-atividades.module';
import {FormUtil} from 'app/main/shared/util/form-util';
import {MateriaPrimaComponent} from './madeira/materia-prima/materia-prima.component';
import {ResiduosSolidosProcessadosManipuladosComponent} from "./residuos-geral/residuos-solidos-processados-manipulados/residuos-solidos-processados-manipulados.component";
import {PainelModulosUsinaFotovoltaicaComponent} from './ee-fotovoltaica/painel-modulos-usina-fotovoltaica/painel-modulos-usina-fotovoltaica.component';
import {ResiduosUrbanoMunicipiosComponent} from './residuos-aterro-industrial-urbano/residuos-urbano-municipios/residuos-urbano-municipios.component';
import {ResiduosGeradosComponent} from './gerenciamento-residuos/residuos-gerados/residuos-gerados.component';
import {TransporteResiduosRodoviasComponent} from './residuos-transporte-produtos-perigosos/transporte-residuos-rodovias/transporte-residuos-rodovias.component';
import {TransporteResiduosProdutosComponent} from './residuos-transporte-produtos-perigosos/transporte-residuos-produtos/transporte-residuos-produtos.component';
import {SituacoesIntertravamentoComponent} from './residuos-tratamento-termico/situacoes-intertravamento/situacoes-intertravamento.component';
import {AutoclaveComponent} from './residuos-tratamento-termico/autoclave/autoclave.component';
import {TipoObraComponent} from './infraestrutura-obras-viarias/tipo-obra/tipo-obra.component';
import {CasaDeForcaComponent} from './ee-hidrica/casa-de-forca/casa-de-forca.component';
import {ReservatorioComponent} from './ee-hidrica/reservatorio/reservatorio.component';
import {UnidadesGeradorasComponent} from './ee-hidrica/unidades-geradoras/unidades-geradoras.component';
import {MonitoramentoContinuoRegistradoresComponent} from './residuos-cemiterio-crematorio/crematorio/monitoramento-continuo-registradores/monitoramento-continuo-registradores.component';
import {ParcelamentoSoloComponent} from './infra-parcel-solo-res-com/parcelamento-solo/parcelamento-solo.component';
import {ObraSaneamentoComponent} from './infra-obra-fluviais-saneamentos/obra-saneamento/obra-saneamento.component';
import {FormasTransbordoResiduosComponent} from './residuos-aterro-industrial-urbano/formas-transbordo-residuos/formas-transbordo-residuos.component';
import {TransporteResiduosVeiculosEquipamentosComponent} from './residuos-transporte-produtos-perigosos/transporte-residuos-veiculos-equipamentos/transporte-residuos-veiculos-equipamentos.component';
import {PainelGerenciamentoResiduosComponent} from './gerenciamento-residuos/painel-gerenciamento-residuos/painel-gerenciamento-residuos.component';
import {TransporteResiduosDadosGeraisComponent} from './residuos-transporte-produtos-perigosos/transporte-residuos-dados-gerais/transporte-residuos-dados-gerais.component';
import {RodoviasComponent} from './infraestrutura-obras-viarias/rodovias/rodovias.component';
import {ObrasDeArteEspeciaisComponent} from './infraestrutura-obras-viarias/obras-de-arte-especiais/obras-de-arte-especiais.component';
import {BarragemComponent} from './ee-hidrica/barragem/barragem.component';
import {CemiterioComponent} from './residuos-cemiterio-crematorio/cemiterio/cemiterio.component';
import {CrematorioComponent} from './residuos-cemiterio-crematorio/crematorio/crematorio.component';
import {ResidenciaisComerciaisComponent} from './infra-parcel-solo-res-com/residenciais-comerciais/residenciais-comerciais.component';
import {ObraPluvialFluvialComponent} from './infra-obra-fluviais-saneamentos/obra-pluvial-fluvial/obra-pluvial-fluvial.component';
import {HotelPousadaComponent} from './servico-geral/hotel-pousada/hotel-pousada.component';
import {HospitalComponent} from "./servico-geral/hospital/hospital.component";
import {ShoppingCenterComponent} from "./servico-geral/shopping-center/shopping-center.component";
import {OficinaManutencaoComponent} from "./servico-geral/oficina-manutencao/oficina-manutencao.component";
import {DadosGeraisComponent} from "./servico-combustivel/dados-gerais/dados-gerais.component";
import {AreaAbastecimentoComponent} from './servico-combustivel/area-abastecimento/area-abastecimento.component';
import {AtvSistTratamentoPrimarioComponent} from './sistema-tratamento/atv-sist-tratamento-primario/atv-sist-tratamento-primario.component';
import {AtvSistTratamentoSecundarioComponent} from "./sistema-tratamento/atv-sist-tratamento-secundario/atv-sist-tratamento-secundario.component";
import {AtvSistTratamentoTerciarioComponent} from "./sistema-tratamento/atv-sist-tratamento-terciario/atv-sist-tratamento-terciario.component";
import {AtvSistTratamentoComplementarComponent} from "./sistema-tratamento/atv-sist-tratamento-complementar/atv-sist-tratamento-complementar.component";
import {MateriaPrimaIndFrigorificoComponent} from "./industria-frigorifico/materia-prima-ind-frigorifico/materia-prima-ind-frigorifico.component";
import {ProdutoProcessadoProprioComponent} from "./industria-frigorifico/produto-processado-proprio/produto-processado-proprio.component";
import {SubprodutoProcessadoProprioComponent} from "./industria-frigorifico/produto-processado-proprio/subproduto-processado-proprio/subproduto-processado-proprio.component";
import {ProdutoProcessadoTerceiroComponent} from './industria-frigorifico/produto-processado-terceiro/produto-processado-terceiro.component';
import {SubprodutoProcessadoTerceiroComponent} from "./industria-frigorifico/produto-processado-terceiro/subproduto-processado-terceiro/subproduto-processado-terceiro.component";
import {AtvEeLinTransBitolaTipoCondutorComponent} from "./ee-linha-transmissao/atv-ee-lin-trans-bitola-tipo-condutor/atv-ee-lin-trans-bitola-tipo-condutor.component";
import {AtvEeLinTransCaracteristicasEletricSistComponent} from './ee-linha-transmissao/atv-ee-lin-trans-caracteristicas-eletric-sist/atv-ee-lin-trans-caracteristicas-eletric-sist.component';
import {AtvEeLinTransCircuitoComponent} from './ee-linha-transmissao/atv-ee-lin-trans-circuito/atv-ee-lin-trans-circuito.component';
import {AtvEeLinTransEstruturaComponent} from './ee-linha-transmissao/atv-ee-lin-trans-estrutura/atv-ee-lin-trans-estrutura.component';
import {AguaPassivoSupressaoUsoAguaComponent} from "./agua-passivo-supressao/agua-passivo-supressao-uso-agua/agua-passivo-supressao-uso-agua.component";
import {AguaPassivoSupressaoGeralComponent} from './agua-passivo-supressao/agua-passivo-supressao-geral/agua-passivo-supressao-geral.component';
import {AguaPassivoSupressaoTipoLancamentoComponent} from "./agua-passivo-supressao/agua-passivo-supressao-tipo-lancamento/agua-passivo-supressao-tipo-lancamento.component";
import {SubstanciaMineralComponent} from "./mineracao/substancia-mineral/substancia-mineral.component";
import {InformacaoAnmComponent} from "./mineracao/informacao-anm/informacao-anm.component";
import {InformacaoLoComponent} from "./mineracao/informacao-lo/informacao-lo.component";
import {PrevisaoVolumesProducaoComponent} from "./mineracao/previsao-volumes-producao/previsao-volumes-producao.component";
import {RelacaoMaquinasEquipamentosComponent} from "./mineracao/relacao-maquinas-equipamentos/relacao-maquinas-equipamentos.component";
import {TiposCombustiveisUtilizadosComponent} from "./mineracao/tipos-combustiveis-utilizados/tipos-combustiveis-utilizados.component";
import {QuadroColaboradoresComponent} from './mineracao/quadro-colaboradores/quadro-colaboradores.component';
import {ImpactosAmbientaisComponent} from "./mineracao/impactos-ambientais/impactos-ambientais.component";
import {MedidasControleComponent} from "./mineracao/medidas-controle/medidas-controle.component";
import {MatrizEnergeticaComponent} from './mineracao/matriz-energetica/matriz-energetica.component';
import {DadosGeraisMineracaoComponent} from './mineracao/dados-gerais-mineracao/dados-gerais-mineracao.component';
import {InsumosUtilizadosComponent} from "./mineracao/insumos-utilizados/insumos-utilizados.component";
import {UnidadesContingenciaComponent} from "./ee-eolica/unidades-contingencia/unidades-contingencia.component";
import {UsinaBiomassaComponent} from './ee-termica/usina-biomassa/usina-biomassa.component';
import {UsinaFossilComponent} from './ee-termica/usina-fossil/usina-fossil.component';
import {UnidadeContingenciaComponent} from './ee-termica/unidade-contingencia/unidade-contingencia.component';
import {GeradorVaporComponent} from './ee-termica/gerador-vapor/gerador-vapor.component';
import {GeradorEletricoComponent} from './ee-termica/gerador-eletrico/gerador-eletrico.component';
import {EquipamentoMotrizComponent} from './ee-termica/equipamento-motriz/equipamento-motriz.component';
import {MateriaPrimaIndBiocombustivelComponent} from "./industria-biocombustivel/materia-prima-ind-biocombustivel/materia-prima-ind-biocombustivel.component";
import {ProdutoBiodieselComponent} from "./industria-biocombustivel/produto-biodiesel/produto-biodiesel.component";
import {ProdutoEtanolComponent} from "./industria-biocombustivel/produto-etanol/produto-etanol.component";
import {PainelComponent} from "./ee-fotovoltaica/painel/painel.component";
import {PainelUnidadesGeradorasComponent} from "./ee-fotovoltaica/painel-unidades-geradoras/painel-unidades-geradoras.component";
import {PainelUnidadeContigenciaComponent} from "./ee-fotovoltaica/painel-unidade-contigencia/painel-unidade-contigencia.component";
import {PainelCaracterizacaoComponent} from "./ee-fotovoltaica/painel-caracterizacao/painel-caracterizacao.component";
import {PainelTransformadoresComponent} from "./ee-fotovoltaica/painel-caracterizacao/painel-transformadores/painel-transformadores.component";
import {ObraDrenagemComponent} from "./infra-obra-fluviais-saneamentos/obra-drenagem/obra-drenagem.component";
import {TelaTesteDocumentosComponent} from "./tela-teste-documentos/tela-teste-documentos.component";
import {QueimaControladaComponent} from './queima-controlada/queima-controlada.component';
import {SupressaoVegetalComponent} from './supressao-vegetal/supressao-vegetal.component';
import {AcaiComponent} from "./acai/acai.component";
import {ManejoFlorestalComponent} from "./manejo-florestal/manejo-florestal.component";
import {DialogTermoCompromissoComponent} from "./manejo-florestal/dialog-termo-compromisso/dialog-termo-compromisso.component";
import {
    ReflorestamentoSeminventarioComponent
} from "./reflorestamento-seminventario/reflorestamento-seminventario.component";

export const routes: Routes = [
    {
        path: 'agua-passivo-supressao',
        component: AguaPassivoSupressaoComponent,
        pathMatch: 'full',
    },
    {
        path: 'capia',
        component: CapiaComponent,
        pathMatch: 'full',
    },
    {
        path: 'ee-eolica',
        component: EeEolicaComponent
    },
    {
        path: 'ee-fotovoltaica',
        component: EeFotovoltaicaComponent
    },
    {
        path: 'ee-hidrica',
        component: EeHidricaComponent
    },
    {
        path: 'ee-linha-transmissao',
        component: EeLinhaTransmissaoComponent
    },
    {
        path: 'ee-subestacao',
        component: EeSubestacaoComponent
    },
    {
        path: 'ee-termica',
        component: EeTermicaComponent
    },
    {
        path: 'gerenciamento-residuos',
        component: GerenciamentoResiduosComponent
    },
    {
        path: 'industria-biocombustivel',
        component: IndustriaBiocombustivelComponent
    },
    {
        path: 'industria-frigorifico',
        component: IndustriaFrigorificoComponent
    },
    {
        path: 'infraestrutura-obras-fluviais-saneamento',
        component: InfraObraFluviaisSaneamentoComponent
    },
    {
        path: 'infraestrutura-obras-viarias',
        component: InfraestruturaObrasViariasComponent
    },
    {
        path: 'infraestrutura-parcelamento-solo-res-com',
        component: InfraParcelSoloResComComponent
    },
    {
        path: 'madeira',
        component: MadeiraComponent
    },
    {
        path: 'mineracao',
        component: MineracaoComponent
    },
    {
        path: 'residuos-aterro-industrial-urbano',
        component: ResiduosAterroIndustrialUrbanoComponent
    },
    {
        path: 'residuos-cemiterio-crematorio',
        component: ResiduosCemiterioCrematorioComponent
    },
    {
        path: 'residuos-geral',
        component: ResiduosGeralComponent
    },
    {
        path: 'residuos-rcc',
        component: ResiduosRccComponent
    },
    {
        path: 'residuos-transporte-produtos-perigosos',
        component: ResiduosTransporteProdutosPerigososComponent
    },
    {
        path: 'residuos-tratamento-termico',
        component: ResiduosTratamentoTermicoComponent
    },
    {
        path: 'servico-combustivel',
        component: ServicoCombustivelComponent
    },
    {
        path: 'servico-geral',
        component: ServicoGeralComponent
    },
    {
        path: 'sistema-tratamento',
        component: SistemaTratamentoComponent
    },
    {
        path: 'supressao-vegetal',
        component: SupressaoVegetalComponent
    },
    {
        path: 'suimis-atividades',
        component: SuimisAtividadesComponent
    },
    {
        path: 'teste-documento',
        component: TelaTesteDocumentosComponent
    }
];

@NgModule({
    declarations: [
        AguaPassivoSupressaoComponent,
        TecnologiaEmpreendimentoEnergeticoComponent,
        GeradorEnergiaComponent,
        UnidadesContingenciaComponent,
        CapiaComponent,
        EeFotovoltaicaComponent,
        EeHidricaComponent,
        EeLinhaTransmissaoComponent,
        EeSubestacaoComponent,
        ClassificacaoFuncaoComponent,
        FormasOperacaoComponent,
        TiposConstrutivosComponent,
        MeioIsolamentoComponent,
        TipoSubestacaoComponent,
        ProjetoSubestacaoComponent,
        MaterialEstruturasSuporteComponent,
        MeioIsolacaoComponent,
        ComponentesSubestacaoComponent,
        SubestacaoExternaComponent,
        SubestacaoInternaComponent,
        EmpresaDerivacaoComponent,
        EeEolicaComponent,
        EeTermicaComponent,
        GerenciamentoResiduosComponent,
        IndustriaBiocombustivelComponent,
        IndustriaFrigorificoComponent,
        InfraObraFluviaisSaneamentoComponent,
        InfraestruturaObrasViariasComponent,
        InfraParcelSoloResComComponent,
        MadeiraComponent,
        MineracaoComponent,
        ResiduosAterroIndustrialUrbanoComponent,
        ResiduosCemiterioCrematorioComponent,
        ResiduosGeralComponent,
        ResiduosRccComponent,
        ResiduosTransporteProdutosPerigososComponent,
        ResiduosTratamentoTermicoComponent,
        ServicoCombustivelComponent,
        ServicoGeralComponent,
        SistemaTratamentoComponent,
        TanqueComponent,
        ParqueBombaComponent,
        SistemaTratamentoComponent,
        AtvSistTratamentoPrimarioComponent,
        AtvSistTratamentoSecundarioComponent,
        AtvSistTratamentoTerciarioComponent,
        AtvSistTratamentoComplementarComponent,
        MateriaPrimaComponent,
        ResiduosSolidosProcessadosManipuladosComponent,
        SuimisAtividadesComponent,
        PainelModulosUsinaFotovoltaicaComponent,
        SistemaTratamentoComponent,
        ResiduosUrbanoMunicipiosComponent,
        FormasTransbordoResiduosComponent,
        ResiduosGeradosComponent,
        PainelGerenciamentoResiduosComponent,
        TransporteResiduosRodoviasComponent,
        TransporteResiduosVeiculosEquipamentosComponent,
        TransporteResiduosProdutosComponent,
        TransporteResiduosDadosGeraisComponent,
        AutoclaveComponent,
        SituacoesIntertravamentoComponent,
        RodoviasComponent,
        TipoObraComponent,
        ObrasDeArteEspeciaisComponent,
        CasaDeForcaComponent,
        BarragemComponent,
        UnidadesGeradorasComponent,
        ReservatorioComponent,
        CemiterioComponent,
        CrematorioComponent,
        MonitoramentoContinuoRegistradoresComponent,
        InfraParcelSoloResComComponent,
        ResidenciaisComerciaisComponent,
        ParcelamentoSoloComponent,
        InfraObraFluviaisSaneamentoComponent,
        ObraPluvialFluvialComponent,
        ObraSaneamentoComponent,
        ServicoGeralComponent,
        HotelPousadaComponent,
        HospitalComponent,
        ShoppingCenterComponent,
        OficinaManutencaoComponent,
        ObraDrenagemComponent,
        ServicoCombustivelComponent,
        DadosGeraisComponent,
        TanqueComponent,
        AreaAbastecimentoComponent,
        ParqueBombaComponent,
        OficinaManutencaoComponent,
        SistemaTratamentoComponent,
        AtvSistTratamentoPrimarioComponent,
        AtvSistTratamentoSecundarioComponent,
        AtvSistTratamentoTerciarioComponent,
        AtvSistTratamentoComplementarComponent,
        IndustriaFrigorificoComponent,
        MateriaPrimaIndFrigorificoComponent,
        ProdutoProcessadoProprioComponent,
        SubprodutoProcessadoProprioComponent,
        ProdutoProcessadoTerceiroComponent,
        SubprodutoProcessadoTerceiroComponent,
        AtvEeLinTransBitolaTipoCondutorComponent,
        AtvEeLinTransCaracteristicasEletricSistComponent,
        AtvEeLinTransCircuitoComponent,
        AtvEeLinTransEstruturaComponent,
        EeLinhaTransmissaoComponent,
        AguaPassivoSupressaoComponent,
        AguaPassivoSupressaoUsoAguaComponent,
        AguaPassivoSupressaoGeralComponent,
        AguaPassivoSupressaoTipoLancamentoComponent,
        SubstanciaMineralComponent,
        InformacaoAnmComponent,
        InformacaoLoComponent,
        PrevisaoVolumesProducaoComponent,
        RelacaoMaquinasEquipamentosComponent,
        TiposCombustiveisUtilizadosComponent,
        QuadroColaboradoresComponent,
        ImpactosAmbientaisComponent,
        MedidasControleComponent,
        MatrizEnergeticaComponent,
        DadosGeraisMineracaoComponent,
        InsumosUtilizadosComponent,
        UsinaBiomassaComponent,
        UsinaFossilComponent,
        UnidadeContingenciaComponent,
        GeradorVaporComponent,
        GeradorEletricoComponent,
        EquipamentoMotrizComponent,
        MateriaPrimaIndBiocombustivelComponent,
        ProdutoBiodieselComponent,
        ProdutoEtanolComponent,
        PainelComponent,
        PainelUnidadesGeradorasComponent,
        PainelUnidadeContigenciaComponent,
        PainelCaracterizacaoComponent,
        PainelTransformadoresComponent,
        TelaTesteDocumentosComponent,
        QueimaControladaComponent,
        DialogTermoCompromissoComponent,
        ManejoFlorestalComponent,
        SupressaoVegetalComponent,
        AcaiComponent,
        ReflorestamentoSeminventarioComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
        MatFormFieldModule,
        MatSelectModule,
        MatRadioModule,
        MatTableModule,
        NgxLoadingModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        FlexModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        AbaAtividadesModule
    ],
    entryComponents: [
        QueimaControladaComponent,
        AcaiComponent,
        ReflorestamentoSeminventarioComponent,
        ManejoFlorestalComponent,
        DialogTermoCompromissoComponent
    ],
    providers: [
        FormUtil
    ],
    exports: [
        ManejoFlorestalComponent
    ]
})
export class AtividadesModule {
}