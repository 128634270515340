import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RequerimentoService} from "../../requerimento.service";
import {ProcessoService} from "../../../../shared/services/processo.service";
import {TipoRequerimentoService} from "../../../tipos-requerimento/tipo-requerimento.service";
import {
    TituloParametrizacaoObjetivoService
} from "../../../../shared/services/licenciamento-config-service/titulo-parametrizacao-objetivos.service";
import {TitulacaoService} from "../../../titulacoes/titulacao.service";
import {SnackBarService} from "../../../../shared/snack-bar/snack-bar.service";
import {EmissaoTituloService} from "../../../tarefas/shared/service/emissao-titulo.service";
import {EnumSituacaoTitulo} from "../../../../shared/enums/EnumSituacaoTitulo";
import {EmissaoTitulo} from "../../../../shared/models/emissao-titulo.model";
import {Processo} from "../../../../shared/models/processo.model";

@Component({
    selector: 'app-dialog-lac-trifasico-nato',
    templateUrl: './dialog-licenciamento-trifasico-nato.component.html',
    styleUrls: ['./dialog-licenciamento-trifasico-nato.component.scss']
})
export class DialogLicenciamentoTrifasicoNatoComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DialogLicenciamentoTrifasicoNatoComponent>,
        public requerimentoService: RequerimentoService,
        public processoService: ProcessoService,
        private tipoRequerimentoService: TipoRequerimentoService,
        private tituloParametrizacaoObjetivoService: TituloParametrizacaoObjetivoService,
        private licenciamentoTitulacaoService: TitulacaoService,
        private snackBarService: SnackBarService,
        private emissaoTituloService: EmissaoTituloService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    numeroProtocolo: any;

    requerimento: any;
    objetivos: any;

    processo: Processo;
    mostrarNaoEncontrado: boolean = false;

    tiposRequerimento: Array<any> = [];
    tipoRequerimento: any;
    validado = false;
    parametros: Array<any> = [];
    idParametroObjetivo: number
    falhou = false
    emissaoTitulo: EmissaoTitulo;

    async ngOnInit() {
        await this.getListaTiposRequerimento()
    }

    async retornarResultado() {
        if (this.validado && this.tipoRequerimento) {
            this.emissaoTitulo = await this.emissaoTituloService.buscaPorIdRequerimentoDocumentoProcesso(this.requerimento.id).toPromise()
            if (this.tipoRequerimento.isFluxoReabilitar && this.emissaoTitulo.situacaoTitulo !== EnumSituacaoTitulo.SUSPENSO) {
                this.snackBarService.showAlert('A solicitação de reabilitação de título pode ser realizada apenas para os títulos na situação “Suspenso”')
            } else if (this.tipoRequerimento.isFluxoCancelar && this.emissaoTitulo.situacaoTitulo === EnumSituacaoTitulo.CANCELADO) {
                this.snackBarService.showAlert('A solicitação de cancelamento de título não pode ser realizada para os títulos na situação “Cancelado”')
            } else {
                this.dialogRef.close({
                    tipoRequerimento: this.tipoRequerimento,
                    numeroProtocoloVinculado: this.numeroProtocolo
                });
            }
        } else {
            this.snackBarService.showAlert('Preencha os campos para avançar e criar o requerimento.')
        }
    }

    async buscarRequerimento(numeroProtocolo) {
        this.processo = null;
        this.validado = false;
        this.mostrarNaoEncontrado = false;

        await this.processoService.findByNumero(numeroProtocolo.replace('/', '')).toPromise().then(async processo => {
            if (processo) {
                await this.requerimentoService.getRequerimento(numeroProtocolo).toPromise().then(async resposta => {
                    if (processo) {
                        processo.requerimentos.push(resposta[0])
                    }
                })

                let validacao = processo.requerimentos.find(req => req.situacaoProcesso === 'CONCLUIDO' && req.tipoProcesso.toLowerCase().includes('Licenciamento trifásico/LAS'.toLowerCase()))
                if (validacao) {
                    this.requerimento = processo.requerimentos[0]
                    if (!this.falhou) {
                        this.processo = processo;
                        this.validado = true
                    }
                } else {
                    this.snackBarService.showAlert('Não foi possível encontrar a titulação informada.')
                    this.mostrarNaoEncontrado = true;
                }
            }
        }).catch(() => {
            this.snackBarService.showAlert('Erro ao buscar o requerimento.')
            this.mostrarNaoEncontrado = true;
        });
    }

    private async getListaTiposRequerimento() {
        const situacao = this.emissaoTitulo ? this.emissaoTitulo.situacaoTitulo : null;
        this.tiposRequerimento = await this.tipoRequerimentoService.getAll().toPromise();
        this.tiposRequerimento = this.tiposRequerimento.filter(tr => {
            if (!tr.tipoAtuacao.toUpperCase().includes('ANDAMENTO')) {
                return false;
            } else if (situacao == EnumSituacaoTitulo.SUSPENSO) {
                return tr.tipoRequerimento.isFluxoReabilitar || tr.tipoRequerimento.isFluxoCancelar;
            } else if (tr.isFluxoReabilitar) {
                return false;
            }

            return true;
        });
    }

    numeroRequerimentoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.numeroProtocolo = `${initial}/${year}`;
            }
        }
    }
}