import {BaseModel} from 'app/main/shared/models/base.model';
import {HotelPousada} from "./hotel-pousada.model";
import {Hospital} from "./hospital.model";
import {ShoppingCenter} from "./shopping-center.model";
import {OficinaManutencao} from "./oficina-manutencao.model";

export class ServicoGeral extends BaseModel {
    constructor(
        public id?: number,
        public tipoAtividade?: number,
        public hotelPousada?: HotelPousada,
        public hospital?: Hospital,
        public shoppingCenter?: ShoppingCenter,
        public oficinaManutencao?: OficinaManutencao,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): ServicoGeral {
        if (json === undefined || json === null) {
            return null;
        }
        return new ServicoGeral(
            json.id,
            json.tipoAtividade,
            HotelPousada.fromJson(json.hotelPousada),
            Hospital.fromJson(json.hospital),
            ShoppingCenter.fromJson(json.shoppingCenter),
            OficinaManutencao.fromJson(json.oficinaManutencao),
            json.requerimento
        );
    }

}
