import {BaseModel} from "./base.model";
import {Requerimento} from "../../pages/requerimentos/requerimento.model";

export class HistoricoTrocaAnalistas extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public cpfAntigoAnalista?: string,
        public cpfNovoAnalista?: string,
        public justificativa?: string,
        public dataTroca?: Date,
    ) {
        super();
    }

    static fromJson(json: any): HistoricoTrocaAnalistas {
        if (json === undefined || json === null) {
            return null;
        }
        return new HistoricoTrocaAnalistas(
            json.id,
            Requerimento.fromJson(json.numeroLeitosEnfermaria),
            json.cpfAntigoAnalista,
            json.cpfNovoAnalista,
            json.justificativa,
            json.dataTroca,
        );
    }
}