import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../../../../../../../environments/environment';
import {Municipio} from '../../../../../../../../shared/models/geoportal-base-referencia/municipio.model';
import {MunicipioService} from '../../../../../../../../shared/services/geoportal-base-referencia/municipio.service';
import {DominioDto} from '../../../../../../../../shared/models/dominio-dto.model';
import {DominiosService} from '../../../../../../../../shared/services/dominios.service';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../../../../../shared/snack-bar/snack-bar.service';
import {ResiduoAterroIndUrbMunicipiosDto} from '../../../../../../../../shared/models/residuo-aterro-ind-urb-municipios-dto.model';
import {BaseModel} from '../../../../../../../../shared/models/base.model';
import {ResiduoAterroIndUrbService} from '../../../../../../../../shared/services/residuo-aterro-ind-urb.service';

@Component({
    selector: 'app-residuos-urbano-municipios',
    templateUrl: './residuos-urbano-municipios.component.html',
    styleUrls: ['./residuos-urbano-municipios.component.scss']
})
export class ResiduosUrbanoMunicipiosComponent implements OnInit {

    @Output() residuosUrbanoMunicipios = new EventEmitter();
    @Input() idResAterroIndUrbano: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;

    municipios: Municipio[];
    residuosDisponiveis: DominioDto[];
    residuoEscolhido: any;
    idResiduoTemp: number = 0;
    modoEdicao: boolean = false;
    dadosDoResiduoMunicipio: any[] = [];
    dataSourceResiduoMunicipio = new MatTableDataSource<any>([]);
    formResiduosUrbanoMunicipios: FormGroup;
    errorInputOutros: string;

    constructor(private municipioService: MunicipioService,
                private dominiosService: DominiosService,
                private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private residuoAterroIndUrbService: ResiduoAterroIndUrbService
    ) {
    }

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    ngOnInit() {
        this.carregarSelects();
        this.residuosUrbanoMunicipiosBuilder();
        if (this.idResAterroIndUrbano) {
            this.carregaResUrbanoMunicipioComTratamentoExistente(this.idResAterroIndUrbano)
        }
        if (this.isNaoPodeEditar) {
            this.formResiduosUrbanoMunicipios.disable();
        }
    }

    carregarSelects() {
        this.listarMunicipios().then();
        this.buscarDisponibilizacaoResiduos().then();
    }

    private residuosUrbanoMunicipiosBuilder() {
        this.formResiduosUrbanoMunicipios = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            municipio: [null, Validators.required],
            possuiColetaSeletiva: [null, Validators.required],
            possuiOutrasFormasResiduo: [null, Validators.required],
            formaDisponibilizacaoResiduos: [null],
            outros: [null],
        });
    }

    adicionarResiduo(): void {
        if (this.formResiduosUrbanoMunicipios.valid) {
            if (this.formResiduosUrbanoMunicipios.value.possuiOutrasFormasResiduo === 'sim'
                && !this.formResiduosUrbanoMunicipios.value.formaDisponibilizacaoResiduos) {
                this.formResiduosUrbanoMunicipios.markAllAsTouched();
                this.errorInputOutros = 'erroInputQuais';
                this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)');
            } else {
                const formaDisponivel = this.formResiduosUrbanoMunicipios.value.formaDisponibilizacaoResiduos && this.formResiduosUrbanoMunicipios.value.formaDisponibilizacaoResiduos.descricao === 'Outro';
                if (formaDisponivel && this.formResiduosUrbanoMunicipios.value.outros === null) {
                    this.errorInputOutros = 'erroInputOutros';
                    this.formResiduosUrbanoMunicipios.markAllAsTouched();
                    this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)');
                } else {
                    this.dadosDoResiduoMunicipio.push({
                        id: null,
                        idTemp: this.idResiduoTemp,
                        municipio: this.formResiduosUrbanoMunicipios.value.municipio,
                        possuiColetaSeletiva: this.formResiduosUrbanoMunicipios.value.possuiColetaSeletiva === 'sim' ? 1 : 0,
                        possuiOutrasFormasResiduo: this.formResiduosUrbanoMunicipios.value.possuiOutrasFormasResiduo === 'sim' ? 1 : 0,
                        formaDisponibilizacaoResiduos: this.formResiduosUrbanoMunicipios.value.formaDisponibilizacaoResiduos,
                        outros: this.formResiduosUrbanoMunicipios.value.outros
                    });
                    this.idResiduoTemp++;

                    this.dataSourceResiduoMunicipio = new MatTableDataSource(this.dadosDoResiduoMunicipio);
                    this.enviarResiduoUrbanoMunicipioDto(this.mapearArray());
                    this.formResiduosUrbanoMunicipios.reset();
                    this.residuoEscolhido = null;
                }
            }
        } else {
            this.formResiduosUrbanoMunicipios.markAllAsTouched();
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)')
        }
    }

    mapearArray(): any {
        if (this.dadosDoResiduoMunicipio.length == 0) {
            return this.dadosDoResiduoMunicipio;
        } else {
            return this.dadosDoResiduoMunicipio.map(function (item) {
                return new ResiduoAterroIndUrbMunicipiosDto(
                    item.id,
                    item.municipio.codigoIbge,
                    item.possuiColetaSeletiva,
                    item.possuiOutrasFormasResiduo,
                    item.formaDisponibilizacaoResiduos ? item.formaDisponibilizacaoResiduos.id : null,
                    item.outros,
                );
            });
        }
    }

    deletarItem(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.residuoAterroIndUrbService.deletarResAterroIndUrbanoMunicipioPorId(itemId).subscribe(() => {
                this.snackBarService.showSuccess('Residuo Aterro Municipio excluido com sucesso.')
            })
        }
        let data;
        data = this.dadosDoResiduoMunicipio.filter((value, key) => {
            return value.idTemp != item;
        });
        this.dadosDoResiduoMunicipio = data;
        this.dataSourceResiduoMunicipio = new MatTableDataSource(data);
        this.enviarResiduoUrbanoMunicipioDto(this.mapearArray());
    }

    editarResiudoMunicipio() {
        if (this.formResiduosUrbanoMunicipios.valid) {
            this.deletarItem(this.formResiduosUrbanoMunicipios.controls.idTemp.value);
            this.dadosDoResiduoMunicipio.push({
                id: null,
                idTemp: this.idResiduoTemp,
                municipio: this.formResiduosUrbanoMunicipios.controls.municipio.value,
                possuiColetaSeletiva: this.formResiduosUrbanoMunicipios.controls.possuiColetaSeletiva.value === 'sim' ? 1 : 0,
                possuiOutrasFormasResiduo: this.formResiduosUrbanoMunicipios.controls.possuiOutrasFormasResiduo.value === 'sim' ? 1 : 0,
                formaDisponibilizacaoResiduos: this.formResiduosUrbanoMunicipios.controls.formaDisponibilizacaoResiduos.value,
                outros: this.formResiduosUrbanoMunicipios.controls.outros.value,
            });
            this.dataSourceResiduoMunicipio = new MatTableDataSource(this.dadosDoResiduoMunicipio);
            this.enviarResiduoUrbanoMunicipioDto(this.mapearArray())
            this.formResiduosUrbanoMunicipios.reset();
            this.modoEdicao = false;
            this.residuoEscolhido = null;
        }
    }

    async ativarModoEdicaoResiduo(residuo) {
        this.municipios = await this.municipios.filter((muni) => muni.nome === residuo.municipio.nome);
        this.residuosDisponiveis = await this.residuosDisponiveis.filter((residuoDisponivel) => residuoDisponivel.descricao === residuo.formaDisponibilizacaoResiduos.descricao);
        this.residuoEscolhido = await residuo.formaDisponibilizacaoResiduos.descricao

        this.formResiduosUrbanoMunicipios.controls.id.patchValue(residuo.id);
        this.formResiduosUrbanoMunicipios.controls.idTemp.patchValue(residuo.idTemp);
        this.formResiduosUrbanoMunicipios.controls.municipio.patchValue(this.municipios);
        this.formResiduosUrbanoMunicipios.controls.possuiColetaSeletiva.patchValue(residuo.possuiColetaSeletiva === 1 ? 'sim' : 'nao');
        this.formResiduosUrbanoMunicipios.controls.possuiOutrasFormasResiduo.patchValue(residuo.possuiOutrasFormasResiduo === 1 ? 'sim' : 'nao');
        this.formResiduosUrbanoMunicipios.controls.formaDisponibilizacaoResiduos.patchValue(this.residuosDisponiveis[0]);
        this.formResiduosUrbanoMunicipios.controls.outros.patchValue(residuo.outros);
        this.modoEdicao = true;
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.formResiduosUrbanoMunicipios.reset();
        this.formResiduosUrbanoMunicipios.markAsUntouched();
        this.formResiduosUrbanoMunicipios.updateValueAndValidity()
    }

    verificarVazio(elemento): boolean {
        if (elemento == '' || elemento == null) {
            return true;
        } else {
            return false;
        }
    }

    enviarResiduoUrbanoMunicipioDto(residuoMunicipioDto: ResiduoAterroIndUrbMunicipiosDto[]) {
        this.residuosUrbanoMunicipios.emit(residuoMunicipioDto)
    }

    async listarMunicipios() {
        this.municipios = await this.municipioService.getAllByUf(environment.UF_PADRAO).toPromise();
    }

    async buscarDisponibilizacaoResiduos() {
        this.residuosDisponiveis = await this.dominiosService.getDominio('FORMA_DISPONIBILIZACAO_RESIDUOS').toPromise();
    }

    receberResiduoEscolhido(residuo: any) {
        this.residuoEscolhido = residuo.descricao;
    }

    carregarMunicipios() {
        this.listarMunicipios().then();
    }

    carregarFormasDisponiveis() {
        this.buscarDisponibilizacaoResiduos().then();
    }

    async carregaResUrbanoMunicipioComTratamentoExistente(idResAterroIndUrbano: number) {
        this.residuoAterroIndUrbService.buscaListaResAterroIndUrbanoMunicipioPorIdResAterro(idResAterroIndUrbano).subscribe(async resAterroUrbanoMuni => {
            for (const resAterroMuni of resAterroUrbanoMuni) {
                let municipios = await this.municipios.filter((muni) => muni.codigoIbge === resAterroMuni.codigoIbgeMunicipio);
                let formaResiduos = await this.residuosDisponiveis.filter((residuoDisponivel) => residuoDisponivel.id === resAterroMuni.formaDisponibilizacaoResiduos);

                this.dadosDoResiduoMunicipio.push({
                    id: resAterroMuni.id,
                    idTemp: this.idResiduoTemp,
                    municipio: municipios[0],
                    possuiColetaSeletiva: resAterroMuni.possuiColetaSeletiva,
                    possuiOutrasFormasResiduo: resAterroMuni.possuiOutrasFormasResiduo,
                    formaDisponibilizacaoResiduos: formaResiduos[0],
                    outros: resAterroMuni.outros
                });
                this.idResiduoTemp++
            }
            this.dataSourceResiduoMunicipio = new MatTableDataSource(this.dadosDoResiduoMunicipio);
        });
    }
}
