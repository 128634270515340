import {Component, Input, OnInit} from '@angular/core';
import {RequerimentoOutorgaFinalidade} from "../../../models/requerimento-outorga-finalidade.model";
import {IrrigacaoService} from "../../../services/irrigacao.service";
import {IrrigacaoDTO} from "../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-irrigacao/irrigacao-dto.model";
import {SnackBarService} from "../../../snack-bar/snack-bar.service";
import {ENUM_MES_VALUES_ONLY} from "../../../enums/mes.enum";

@Component({
    selector: 'app-aba-finalidade-irrigacao',
    templateUrl: './aba-finalidade-irrigacao.component.html',
    styleUrls: ['./aba-finalidade-irrigacao.component.scss']
})
export class AbaFinalidadeIrrigacaoComponent implements OnInit {

    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;
    irrigacoes: IrrigacaoDTO[];
    meses = ENUM_MES_VALUES_ONLY;

    constructor(
        private irrigacaoService: IrrigacaoService,
        private snackBarService: SnackBarService,
    ) {
    }

    ngOnInit() {
        this.irrigacaoService.listarIrrigacoes(this.requerimentoOutorgaFinalidade, true).subscribe(result => this.irrigacoes = result,
            e => this.snackBarService.showError('Erro ao carregar formulários de irrigação', e))
    }

}
