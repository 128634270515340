import {BaseModel} from 'app/main/shared/models/base.model';

export class ParametroHidrico extends BaseModel {
    constructor(
        public id?: number,
        public texto?: string,
        public sigla?: string,
        public unidade?: string,
        public conservativo?: boolean,
        public atividades?: number
    ) {
        super();
    }

    static fromJson(json: any): ParametroHidrico {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParametroHidrico(
            json.id ? json.id : null,
            json.texto ? json.texto : null,
            json.sigla ? json.sigla : null,
            json.unidade ? json.unidade : null,
            json.conservativo ? json.conservativo : null,
            json.atividades ? json.atividades : null
        );
    }

    static fromJsons(json: any): ParametroHidrico[] {
        const parametros: ParametroHidrico[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(parametro => parametros.push(ParametroHidrico.fromJson(parametro)));
            return parametros;
        }
    }

    get siglaUnidade(): string{
        return `${this.sigla}(${this.unidade})`;
    }
}
