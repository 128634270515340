import {Injector} from '@angular/core';
import {environment} from 'environments/environment';
import {BaseModel} from '../models/base.model';
import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Page} from '../models/page.model';

export abstract class BaseCorporativoService<T extends BaseModel> {

    protected http: HttpClient;
    urlResourceCorporativo: string = environment.URL_SCORP_API;

    constructor(
        protected apiPath: string,
        protected injector: Injector,
        protected type: T,
        protected jsonToResourceFn: (json: any) => T
    ) {
        this.http = injector.get(HttpClient);
        this.urlResourceCorporativo += apiPath;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

    protected jsonToResources(json: any): T[] {
        const resources: T[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: T) => resources.push(this.jsonToResourceFn(e)));
        }
        return resources;
    }

    protected jsonToPage(json: any): Page<T> {
        return Page.fromJson(
            this.jsonToResources(json.content),
            json,
            this.type
        );
    }

}
