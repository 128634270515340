import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from './requerimento.model';

export class RequerimentoWrapper extends BaseModel {
    constructor(
        public requerimento?: Requerimento,
        public tarefaId?: string
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoWrapper {
        json = json || {};

        return new RequerimentoWrapper(
            Requerimento.fromJson(json.requerimento),
            json.tarefaId
        );
    }
}