export class DocumentoTecnicoDTO {
    constructor(
        public documento?: string,
        public numero?: string,
        public analistas?: string,
        public departamentos?: string,
        public chaveDocumento?: string,
        public chaveParecerConsolidado?: string,
    ) {
    }

    static fromJson(json: any): DocumentoTecnicoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoTecnicoDTO(
            json.documento,
            json.numero,
            json.analistas,
            json.departamentos,
            json.chaveDocumento,
            json.chaveParecerConsolidado)
    }
}
