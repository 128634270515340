import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';

import {FormaOcupacao} from './forma-ocupacao.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';


@Injectable()
export class FormaOcupacaoListResolver extends ListResolver<FormaOcupacao> {
    constructor(service: FormaOcupacaoService) {
        super(service);
    }
}

@Injectable()
export class FormaOcupacaoResolver extends DetailResolver<FormaOcupacao> {
    constructor(service: FormaOcupacaoService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FormaOcupacaoService extends BaseService<FormaOcupacao> {

    constructor(protected injector: Injector) {
        super('/api/formas-ocupacoes', injector, FormaOcupacao.prototype, FormaOcupacao.fromJson);
    }

}
