import {BaseModel} from "./base.model";
import {EmpreendimentoTransporteResiduosPerigosos} from "./empreendimento-transporte-residuos-perigosos.model";

export class TransporteResiduosPerigosos extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoTransporteResiduosPerigosos?: EmpreendimentoTransporteResiduosPerigosos,
        public classeRisco?: string,
        public residuoProduto?: string,
        public qtdAnual?: number,
        public unidadeMedida?: string,
        public acondicionamento?: string,
        public descricao?: string
    ) {
        super();
    }

    static fromJson(json: any): TransporteResiduosPerigosos {
        if (json === undefined || json === null) {
            return null;
        }
        return new TransporteResiduosPerigosos(
            json.id,
            EmpreendimentoTransporteResiduosPerigosos.fromJson(json.empreendimentoTransporteResiduosPerigosos),
            json.classeRisco,
            json.residuoProduto,
            json.qtdAnual,
            json.unidadeMedida,
            json.acondicionamento,
            json.descricao
        );
    }

    static fromJsons(json: any): TransporteResiduosPerigosos[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(TransporteResiduosPerigosos.fromJson(j)));
            return resources;
        }
    }
}