import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {TanquePiscicultura} from "../models/tanque-piscicultura.model";

@Injectable({
    providedIn: 'root'
})

export class TanquePisciculturaService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_LIC_FEICAO_AQUICULTURA;

    salvarTanquePiscicultura(tanquePiscicultura: TanquePiscicultura): Observable<TanquePiscicultura> {
        const url = `${this.urlResource}/api/tanque-piscicultura`;
        return this.http.post(url, tanquePiscicultura)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscarAtividadeID(idAtividade: number): Observable<any> {
        const url = `${this.urlResource}/api/tipo-tanque-piscicultura-atv/busca-atv-tipo-tanque-piscicultura/${idAtividade}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    retornaTipoTanquePisciculturaAtvEspecifica(idAtividade: number): Observable<any> {
        const url = `${this.urlResource}/api/tipo-tanque-piscicultura-atv/retorna-tipo-tanque-piscicultura-atv/${idAtividade}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }
}