import {AnaliseRequerimentoEquipe} from './analise-requerimento-equipe.model';
import {RequerimentoOutorga} from '../../../requerimentos/requerimento-outorga/requerimento-outorga.model';

export class AnaliseRequerimento {
    constructor(
        public id: number = null,
        public dataInicio: Date = null,
        public dataFim: Date = null,
        public situacao: Date = null,
        public requerimento: RequerimentoOutorga = null,
        public equipe: AnaliseRequerimentoEquipe = null
    ) {
    }

    static fromJson(json: any): AnaliseRequerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnaliseRequerimento(
            json.id,
            json.dataInicio,
            json.dataFim,
            json.situacao,
            json.requerimento,
            json.equipe
        );
    }

    static fromJsons(json: any): AnaliseRequerimento[] {
        const array: AnaliseRequerimento[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(AnaliseRequerimento.fromJson(item)));
            return array;
        }
    }
}
