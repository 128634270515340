import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Pessoa} from '../../models/pessoa.model';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-visualizar-pessoa',
    templateUrl: './visualizar-pessoa.component.html',
    styleUrls: ['./visualizar-pessoa.component.scss']
})
export class VisualizarPessoaComponent implements OnInit, OnChanges {
    @Input()
    pessoa: Pessoa;
    @Input()
    titulo: string = null;
    @Input()
    borderless = false;

    form: FormGroup;

    constructor(
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            id: [{value: null, disabled: true}],
            tipo: [{value: null, disabled: true}],
            nome: [{value: null, disabled: true}],
            dataNascimento: [{value: null, disabled: true}],
            cpfCnpj: [{value: null, disabled: true}],
            rg: [{value: null, disabled: true}],
            telefone: [{value: null, disabled: true}],
            email: [{value: null, disabled: true}],
            sexo: [{value: null, disabled: true}],
            endereco: [{value: null, disabled: true}],
            cadastroTecnicoFederal: [{value: null, disabled: true}],
        });

        if (this.pessoa) {
            this.form.patchValue(this.pessoa);
            this.form.get("cadastroTecnicoFederal").patchValue(this.pessoa.cadastroTecnicoFederal || "Nenhum");
        }
    }

    ngOnChanges(): void {
        if (this.form && this.pessoa) {
            this.form.patchValue(this.pessoa);
        }
    }
}