import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmpreendimentoEnergiaFotovoltaicaService} from '../../../../../../../shared/services/empreendimento-energia-fotovoltaica.service';
import {SnackBarService} from '../../../../../../../shared/snack-bar/snack-bar.service';
import {EmpreendimentoEnergFotovoltaica} from '../../../../../../../shared/models/empreendimento-energ-fotovoltaica';
import {forkJoin} from 'rxjs';
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoService} from "../../../../../requerimento.service";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";

@Component({
    selector: 'app-formulario-empreendimento-fotovoltaica',
    templateUrl: './ee-fotovoltaica.component.html',
    styleUrls: ['./ee-fotovoltaica.component.scss']
})
export class EeFotovoltaicaComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit, OnChanges {

    id: number;
    form: FormGroup;
    mostrarLicencaAmbiental: boolean = false;

    atualizarComponenteUsina: boolean = true;
    mostrarObrigatorioUsina: boolean = false;

    atualizarComponentePainel: boolean = true;
    mostrarObrigatorioPainel: boolean = false;

    atualizarComponenteUnidadeGeradora: boolean = true;
    mostrarObrigatorioUnidadesGeradoras: boolean = false;

    atualizarComponenteUnidadeContig: boolean = true;
    mostrarObrigatorioUnidadeContig: boolean = false;
    isNaoPodeEditar: boolean = false;

    updateId: boolean = false;

    constructor(private fb: FormBuilder,
                private snackBarService: SnackBarService,
                private requerimentoService: RequerimentoService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService) {
        super();

        this.form = fb.group({
            idTipoEeFotovoltaica: ['', [Validators.required]],
            areaTotalUsinaM2: ['', [Validators.required]],
            numUnidadesGeradoras: ['', [Validators.required]],
            potenciaInstaladaDeclaradaKw: ['', [Validators.required]],
            potenciaLiquidaDeclaradaKw: ['', [Validators.required]],
            altitudeM: ['', [Validators.required]],
            tempAmbienteMediaAnualC: ['', [Validators.required]],

            substacaoElevadoraEntradaKv: [''],
            substacaoElevadoraSaidaKv: [''],
            quantidadeTransformadores: [''],
            linhaTransmissaoKv: [''],
            extensaoLinhaKm: [''],
            idTipoCircuito: [''],

            numLicencaAmbiental: [''],
            razaoSocial: [{value: '', disabled: true}, Validators.maxLength(150)],
            numCpfCnpj: [''],

            idTipoSistemaConexaoFotovoltaica: ['', [Validators.required]],
            sisConexParalelismoPermanente: ['', [Validators.required]],
            //TODO Codigo provisorio para funcionar o formulario para a equipe de teste
            idRequerimento: [0],
            // idRequerimento: [this.requerimento.id],
            id: ['']
        });
    }

    async ngOnInit(){
        let requerimentoLicenciamentoVinculado;
        if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
            await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
            })
            await this.carregarEeFotovoltalica(requerimentoLicenciamentoVinculado.requerimento.id)
        } else {
            await this.carregarEeFotovoltalica(this.requerimento.id)
        }
        if(this.isModoVisualizacao){
            this.form.disable()
        }

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
                this.isNaoPodeEditar = true;
                this.form.disable();
        }
    }

    async carregarEeFotovoltalica(idRequerimento){
        await this.empreendimentoEnergiaFotovoltaicaService.consultarPorIdRequerimento(idRequerimento)
            .toPromise().then(retorno => {
                if (retorno) {
                    this.id = retorno.id;
                    this.form.patchValue(retorno);
                    this.mostrarLicencaAmbiental = true;
                    this.updateId = true;
                } else {
                    const entidade = {
                        idRequerimento: this.requerimento.id,
                        //TODO Codigo provisorio para funcionar o formulario para a equipe de teste
                        // idRequerimento: 0,
                        idTipoEeFotovoltaica: 0,
                        formConcluido: false,
                    } as EmpreendimentoEnergFotovoltaica;
                    this.empreendimentoEnergiaFotovoltaicaService.salvar(entidade)
                        .toPromise().then(retorno => {
                        this.id = retorno.id;
                        //TODO Codigo provisorio para funcionar o formulario para a equipe de teste
                        // this.form.patchValue({id: retorno.id, idRequerimento: 0});
                        this.form.patchValue({id: retorno.id, idRequerimento: this.requerimento.id});
                        this.updateId = true;
                    }, error => {
                        this.snackBarService.showError('Falha ao salvar informações!');
                        console.error(error);
                    });
                }
            }, error => {
                this.snackBarService.showError('Falha ao salvar informações!');
                console.error(error);
            });
    }

    filtroRegex(formName){
        var input = this.form.get(formName).value;
        input = input.replace(/[^0-9]/g, "");
        this.form.get(formName).patchValue(input);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.idRequerimento) {

        }
    }

    onSaveModulosUsinaFotovoltaica(value: any): void {

        console.log(value);
    }

    onSavePainel(value: any): void {

        console.log(value);
    }

    onSaveUnidadeGestora(value: any): void {

        console.log(value);
    }

    onSaveUnidadeContigencia(value: any): void {

        console.log(value);
    }

    async onSalvar() {
        const obs = [
            this.empreendimentoEnergiaFotovoltaicaService.listarModulo(this.id),
            this.empreendimentoEnergiaFotovoltaicaService.listarPainel(this.id),
            this.empreendimentoEnergiaFotovoltaicaService.listarUnidGeradoras(this.id),
            this.empreendimentoEnergiaFotovoltaicaService.listarUnidContigencia(this.id)
        ];

        if (!this.form.invalid) {
            const form = this.form.getRawValue();

            forkJoin(obs).subscribe(listas => {

                //Atualizando componentes obrigatorios para que os campos mostrem a mensagem de obrigatorio.
                let status = true;
                if(listas[0].length === 0){
                    this.atualizarComponenteUsina = false;
                    setTimeout(() => {
                        this.atualizarComponenteUsina = true;
                    }, 1000)
                    this.mostrarObrigatorioUsina = true;
                    status = false;
                }
                if(listas[1].length === 0){
                    this.atualizarComponentePainel = false;
                    setTimeout(() => {
                        this.atualizarComponentePainel = true;
                    }, 1000)
                    this.mostrarObrigatorioPainel = true;
                    status = false;
                }
                if(listas[2].length === 0){
                    this.atualizarComponenteUnidadeGeradora = false;
                    setTimeout(() => {
                        this.atualizarComponenteUnidadeGeradora = true;
                    }, 1000)
                    this.mostrarObrigatorioUnidadesGeradoras = true;
                    status = false;
                }
                if(listas[3].length === 0){
                    this.atualizarComponenteUnidadeContig = false;
                    setTimeout(() => {
                        this.atualizarComponenteUnidadeContig = true;
                    }, 1000)
                    this.mostrarObrigatorioUnidadeContig = true;
                    status = false;
                }

                if (!status) {
                    this.form.markAllAsTouched();
                    this.snackBarService.showError('Pelo menos um agrupamento deve ser preenchido [Módulos da Usina Fotovoltaica, Painel e Unidades geradoras]!');
                } else {

                    form.formConcluido = true;
                    this.empreendimentoEnergiaFotovoltaicaService.salvar(form)
                        .subscribe(retorno => {
                            this.id = retorno.id;
                            this.enviarFormConcluido.emit();
                            this.snackBarService.showSuccess('Informações salvas com sucesso!');
                            this.form.reset();
                        }, error => {
                            this.snackBarService.showError('Falha ao salvar informações!');
                            console.error(error);
                        });
                }
            }, error => {
                this.snackBarService.showError('Falha ao verificar se dados obrigatórios foram preenchidos!');
                console.error(error);
            });

        } else {
            this.form.markAllAsTouched();
            this.snackBarService.showError('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)');
        }
    }

    consultarCpfCnpj(evt: any): void {
        const {value} = evt.target;
        this.empreendimentoEnergiaFotovoltaicaService.consultarDocumento(value)
            .subscribe((retorno) => {
                this.form.patchValue({razaoSocial: retorno.razaoSocial ? retorno.razaoSocial : retorno.nome});
                this.form.get('numLicencaAmbiental').patchValue('')
                this.mostrarLicencaAmbiental = true;
            }, error => {
                this.form.controls.razaoSocial.patchValue('');
                this.form.get('numLicencaAmbiental').patchValue('')
                this.mostrarLicencaAmbiental = false;
            });
    }
}
