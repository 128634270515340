import { BaseModel } from 'app/main/shared/models/base.model';
import { EmpreendimentoEnergTermica } from './empreendimento-energ-termica';

export class EquipamentoMotrizUsinaTerm extends BaseModel {
    constructor(

        public id?: number,
        public empreendimentoEnergTermica?: EmpreendimentoEnergTermica,
        public identificacaoEquipMotriz?: string,
        public descricaoTipo?: string,
        public idUnidadeMedida?: number,
        public valorPotenciaInstalada?: number,
        public valorRotacao?: number,

    ) {
        super();
    }
    static fromJson(json: any): EquipamentoMotrizUsinaTerm {
        if (json === undefined || json === null) {
            return null;
        }
        return new EquipamentoMotrizUsinaTerm(
            json.id,
            EmpreendimentoEnergTermica.fromJson(json.empreendimentoEnergTermica),
            json.identificacaoEquipMotriz,
            json.descricaoTipo,
            json.idUnidadeMedida.id,
            json.valorPotenciaInstalada,
            json.valorRotacao
        );
    }

    static fromJsons(json: any): EquipamentoMotrizUsinaTerm[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(EquipamentoMotrizUsinaTerm.fromJson(j)));
            return resources;
        }
    }
}
