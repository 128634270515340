import {BaseModel} from 'app/main/shared/models/base.model';
import { Email } from './email.model';
import { EnderecoDto } from './endereco-dto.model';
import { Telefone } from './telefone.model';


export class DadosContatoPessoaDTO extends BaseModel {
    constructor(
        public enderecoDTO?: EnderecoDto,
        public telefoneDTO?: Telefone,
        public emailPessoaDTO?: Email
    ) {
        super();
    }

    static fromJson(json: any): DadosContatoPessoaDTO {
        if (json === undefined || json === null) {
            return null;
        }

        return new DadosContatoPessoaDTO(
            EnderecoDto.fromJson(json.enderecoDTO),
            Telefone.fromJson(json.telefoneDTO),
            Email.fromJson(json.emailPessoaDTO)
        );
    }
}
