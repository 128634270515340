import {Component, Input, OnInit} from '@angular/core';
import {AtividadeServicoService} from 'app/main/shared/services/atividade-servico.service';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';
import {AtividadeServico} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidades-outras/atividade-servico/models/atividade-servico.model';

@Component({
    selector: 'app-aba-finalidade-outras',
    templateUrl: './aba-finalidade-outras.component.html'
})

export class AbaFinalidadeOutrasComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;
    atividadesServico: Array<AtividadeServico>;

    constructor(private atividadeServicoService: AtividadeServicoService) {
    }

    ngOnInit(): void {
        this.carregarAtividadeServicos();
    }

    carregarAtividadeServicos(): void {
        if (this.requerimentoOutorgaFinalidade.outraFinalidade) {
            this.atividadeServicoService
                .getAllByOutraFinalidade(
                    this.requerimentoOutorgaFinalidade.outraFinalidade
                )
                .subscribe(atividadesServico => {
                    this.atividadesServico = atividadesServico;
                });
        }
    }

    getVazaoConsumoTotal(): number {
        if (this.atividadesServico) {
            return this.atividadesServico.reduce(
                (total, current) => total + current.vazaoConsumo,
                0
            );
        }
        return 0;
    }

}


