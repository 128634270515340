import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AreaDeConfinamento} from "../models/area-de-confinamento.model";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AreaDeConfinamentoService {
    constructor(private http: HttpClient) {
    }
    urlResource: string = environment.URL_LIC_FEICAO_PECUARIA_INTENSIVA;

    salvarAreaDeConfinamento(areaDeConfinamento: AreaDeConfinamento): Observable<AreaDeConfinamento> {
        const url = `${this.urlResource}/api/area-de-confinamento`;
        return this.http.post(url, areaDeConfinamento)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }
}