import {BaseModel} from './base.model';
import {BaseEnum} from '../enums/base.enum';
import {ParecerRequerimentoConclusao} from '../../pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.model';
import {EnumSituacaoTitulo} from '../enums/EnumSituacaoTitulo';
import {DocumentoProcesso} from './documento-processo.model';
import {EnumSituacaoTituloDecisao} from '../enums/EnumSituacaoTituloDecisao';
import {RequerimentoTitulacao} from "../../pages/requerimentos/shared/requerimento-titulacao/shared/requerimento-titulacao.model";

export class EmissaoTitulo extends BaseModel {
    constructor(
        public id?: number,
        public parecerRequerimentoConclusao?: ParecerRequerimentoConclusao,
        public descricao?: string,
        public tipoEmissao?: string,
        public chave?: string,
        public chaveDocumentoAssinado?: string,
        public chaveHtmlDocumento?: string,
        public dataValidade?: Date,
        public numeroPortaria?: string,
        public dataPublicacao?: Date,
        public situacao?: string,
        public documentoProcesso?: DocumentoProcesso,
        public situacaoPublicacao?: string,
        public dataInicio?: Date,
        public situacaoTitulo?: EnumSituacaoTitulo,
        public situacaoTituloDecisao?: EnumSituacaoTituloDecisao,
        public justificativaAto?: string,
        public numeroEmissao?: string,
        public tipoAssinatura?: string,
        public requerimentoTitulacao?: RequerimentoTitulacao
    ) {
        super();
    }

    static situacaoTituloDecisao: BaseEnum[] = [
        {valor: 'AGUARDANDO_SUSPENSAO', descricao: 'Aguardando suspensao'},
        {valor: 'AGUARDANDO_CANCELAMENTO', descricao: 'Aguardando cancelamento'},
        {valor: 'AGUARDANDO_REABILITACAO', descricao: 'Aguardando reabilitação'},
        {valor: 'SEM_DECISOES_PENDENTES', descricao: 'Sem decisões pendentes'}
    ];

    static situacao: BaseEnum[] = [
        {valor: 'EM_CADASTRO', descricao: 'Em cadastro'},
        {valor: 'SUSPENSO', descricao: 'Suspenso'},
        {valor: 'CANCELADO', descricao: 'Cancelado'},
        {valor: 'VENCIDO', descricao: 'Vencido'},
        {valor: 'VIGENTE', descricao: 'Vigente'},
        {valor: 'VIGENTE_EM_RENOVACAO', descricao: 'Vigente em renovação'},
        {valor: 'VIGENTE_TRANSFERIDO', descricao: 'Vigente transferido'},
        {valor: 'VIGENTE_RENOVADO', descricao: 'Vigente renovado'}
    ];

    static situacaoPublicacao: BaseEnum[] = [
        {valor: 'AGUARDANDO_SOLICITACAO', descricao: 'Aguardando solicitação'},
        {valor: 'AGUARDANDO_GERAR_DOCUMENTACAO', descricao: 'Aguardando gerar documentação'},
        {valor: 'AGUARDANDO_PUBLICACAO', descricao: 'Aguardando publicação'},
        {valor: 'PUBLICADO', descricao: 'Publicado'}
    ];

    static fromJson(json: any): EmissaoTitulo {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmissaoTitulo(
            json.id,
            ParecerRequerimentoConclusao.fromJson(json.parecerRequerimentoConclusao),
            json.descricao,
            json.tipoEmissao,
            json.chave,
            json.chaveDocumentoAssinado,
            json.chaveHtmlDocumento,
            json.dataValidade,
            json.numeroPortaria,
            json.dataPublicacao,
            json.situacao,
            DocumentoProcesso.fromJson(json.documentoProcesso),
            json.situacaoPublicacao,
            json.dataInicio,
            json.situacaoTitulo,
            json.situacaoTituloDecisao,
            json.justificativaAto,
            json.numeroEmissao,
            json.tipoAssinatura,
            RequerimentoTitulacao.fromJson(json.requerimentoTitulacao)
        );
    }

    static fromJsons(json: any): EmissaoTitulo[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(EmissaoTitulo.fromJson(j)));
            return resources;
        }
    }

}
