import {BaseModel} from 'app/main/shared/models/base.model';
import {EmpreendimentoEnergTermica} from './empreendimento-energ-termica';

export class UsinaTipoFossil extends BaseModel {
    constructor(

        public id?: number,
        public empreendimentoEnergTermica?: EmpreendimentoEnergTermica,
        public idTipoCombustivel?: number,
        public idSubTipoCombustivel?: number,
        public descricao?: string

    ) {
        super();
    }
    static fromJson(json: any): UsinaTipoFossil {
        if (json === undefined || json === null) {
            return null;
        }
        return new UsinaTipoFossil(
            json.id,
            EmpreendimentoEnergTermica.fromJson(json.empreendimentoEnergTermica),
            json.idTipoCombustivel,
            json.idSubTipoCombustivel,
            json.descricao
        );
    }

    static fromJsons(json: any): UsinaTipoFossil[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(UsinaTipoFossil.fromJson(j)));
            return resources;
        }
    }
}
