import {Component, OnInit} from '@angular/core';
import {BasePontoComponent} from '../base-ponto.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {CommonsUtil} from '../../../../../shared/util/commons-util';
import {BarragemExistente} from './barragem-existente.model';
import {BaseModel} from '../../../../../shared/models/base.model';
import {TipoCorpoHidrico} from '../../../../../shared/models/tipo-corpo-hidrico.model';
import {TipoCorpoHidricoService} from '../../../../../shared/services/tipo-corpo-hidrico.service';
import {AlocacaoVazao} from '../../../../../shared/models/alocacao-vazao.model';
import {AlocacaoVazaoService} from '../../../../../shared/services/alocacao-vazao.service';
import {ConfirmarExclusaoDialogComponent} from '../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {MatDialog} from '@angular/material';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-objetivo-barragem-existente',
    templateUrl: './objetivo-barragem-existente.component.html',
})
export class ObjetivoBarragemExistenteComponent extends BasePontoComponent implements OnInit {
    form: FormGroup;
    formVazao: FormGroup;

    finalidadesVazao = AlocacaoVazao.finalidades;
    finalidadesBarragemExistente = BarragemExistente.finalidades;
    tiposVertedor = BarragemExistente.tiposVertedouros;
    materialConstrutivo = BarragemExistente.materialConstrutivo;
    posicoesBarragem = BarragemExistente.posicaoBarragem;
    tipoCorposHidricos: TipoCorpoHidrico[] = [];

    alocacaoVazoesAdicionadas: AlocacaoVazao[];
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    constructor(
        private formBuilder: FormBuilder,
        private pontoService: PontoService,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private tipoCorpoHidricoService: TipoCorpoHidricoService,
        private alocacaoVazaoService: AlocacaoVazaoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildForms();
        this.patchForms();
        this.tipoCorpoHidricoService.getAll().subscribe(resp => {
            this.tipoCorposHidricos = resp;
        });
        this.carregarVazoes();
        this.onTipoCorpoHidricoChanged();
        this.onPosicaoBarragemChanged();
        this.onTipoMaterialConstrutivoChanged();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    buildForms(): void {
        this.formVazao = this.formBuilder.group({
            finalidade: [null, [Validators.required]],
            percentualVazao: [null, [Validators.required, Validators.max(100), Validators.min(1)]],
        });

        this.form = this.formBuilder.group({
            tipoCorpoHidrico: [null, Validators.required],
            outroTipoCorpoHidrico: [{value: null, disabled: true}],
            corpoHidrico: [null],
            barragemExistente: this.formBuilder.group({
                id: [null],
                finalidadeBarragem: [null, [Validators.required, Validators.maxLength(255)]],
                anoInicioConstrucao: [null, [Validators.required, Validators.min(1500)]],
                anoFimConstrucao: [null, [Validators.required, Validators.min(1500)]],
                areaDrenagem: [null, [Validators.required, Validators.min(0.00001)]],
                areaMaximaReservatorio: [null, [Validators.required, Validators.min(0.00001)]],
                volumeMaximoAcumulado: [null, [Validators.required, Validators.min(0.00001)]],
                usoPreponderante: [null, [Validators.required, Validators.maxLength(255)]],
                tipoMaterialConstrutivo: [null, [Validators.required]],
                tipoMaterialEspecifique: [null],
                alturaMaxima: [null, [Validators.required, Validators.min(0.00001)]],
                comprimento: [null, [Validators.required, Validators.min(0.00001)]],
                cotaCrista: [null, [Validators.required, Validators.min(0.00001)]],
                tipoVertedor: [null, [Validators.required, Validators.min(0.00001)]],
                cotaSoleiraVertedouro: [null, [Validators.required, Validators.min(0.00001)]],
                cotaCristaVertedouro: [null, [Validators.required, Validators.min(0.00001)]],
                vazaoMaxima: [null, [Validators.required, Validators.min(0.000001)]],
                tempoRetorno: [null, [Validators.required, Validators.max(999), Validators.min(0.00001)]],
                tipoExtravasador: [null, [Validators.required, Validators.maxLength(255)]],
                extravasadorVazao: [null, [Validators.required, Validators.min(0.00001)]],
                extravasadorPosicao: [null, [Validators.required, Validators.maxLength(255)]],
                extravasadorPosEspecifique: [null, [Validators.required, Validators.maxLength(255)]],
            })
        });
    }

    private patchForms(): void {
        this.form.patchValue({
            id: this.ponto.id,
            tipoCorpoHidrico: this.ponto.tipoCorpoHidrico,
            outroTipoCorpoHidrico: this.ponto.outroTipoCorpoHidrico,
            corpoHidrico: this.ponto.corpoHidrico,
        });

        if (this.ponto.barragemExistente) {
            this.form.controls.barragemExistente.patchValue(this.ponto.barragemExistente);
        }
    }

    salvarPonto(): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            const ponto = Ponto.fromJson(this.ponto);
            ponto.barragemExistente = BarragemExistente.fromJson(this.form.get('barragemExistente').value);
            ponto.tipoCorpoHidrico = TipoCorpoHidrico.fromJson(this.form.controls.tipoCorpoHidrico.value);
            ponto.outroTipoCorpoHidrico = this.form.controls.outroTipoCorpoHidrico.value;
            ponto.corpoHidrico = this.form.controls.corpoHidrico.value;
            ponto.formularioConcluido = true;
            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.patchForms();
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s).' +
                ' Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    onTipoCorpoHidricoChanged(): void {
        const select = this.form.controls.tipoCorpoHidrico;
        const field = this.form.controls.outroTipoCorpoHidrico;
        CommonsUtil.baseOnChange(select.value && select.value.outro, select, field,
            [Validators.required, Validators.maxLength(255)]);
    }

    onTipoMaterialConstrutivoChanged(): void {
        const select = this.form.controls.barragemExistente.get('tipoMaterialConstrutivo');
        const field = this.form.controls.barragemExistente.get('tipoMaterialEspecifique');
        CommonsUtil.baseOnChange(select.value && select.value.valor === 'OUTRO', select, field,
            [Validators.required, Validators.maxLength(255)]);
    }

    onPosicaoBarragemChanged(): void {
        const select = this.form.controls.barragemExistente.get('extravasadorPosicao');
        const field = this.form.controls.barragemExistente.get('extravasadorPosEspecifique');
        CommonsUtil.baseOnChange(select.value && select.value.valor === 'OUTRO', select, field,
            [Validators.required, Validators.maxLength(255)]);
    }

    adicionarVazao(): void {
        this.formVazao.markAllAsTouched();
        if (this.formVazao.valid) {
            if (this.alocacaoVazoesAdicionadas && this.alocacaoVazoesAdicionadas.some(item => item.finalidade === this.formVazao.controls.finalidade.value)) {
                this.snackBarService.showAlert('Finalidade já adicionada');
            } else {
                const vazao = AlocacaoVazao.fromJson(this.formVazao.value);
                vazao.barragemExistente = this.ponto.barragemExistente;
                this.alocacaoVazaoService.create(vazao).subscribe(() => {
                    this.carregarVazoes();
                    this.snackBarService.showSuccess('Vazão adicionada com sucesso');
                }, (e) => this.snackBarService.showError('Erro ao adicionar a vazão', e));
            }
        } else {
            this.snackBarService.showAlert('Preencha todos os campos corretamente');
        }
    }

    private carregarVazoes(): void {
        if (this.ponto.barragemExistente) {
            this.alocacaoVazaoService.getAllByBarragemExistente(this.ponto.barragemExistente).subscribe(result => {
                this.alocacaoVazoesAdicionadas = result;
            });
        }
    }

    excluirVazao(item: AlocacaoVazao): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: item.id}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.alocacaoVazaoService.deleteByBarragemExistenteAndAlocacaoVazao(this.ponto.barragemExistente, item).subscribe(() => {
                    this.carregarVazoes();
                    this.snackBarService.showSuccess('Alocação vazão removida com sucesso');
                }, (e) => this.snackBarService.showError('Erro ao remover Alocação vazão', e));
            }
        });

    }
}
