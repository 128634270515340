import {BaseModel} from 'app/main/shared/models/base.model';
import {AquiculturaTanqueEscavado} from './aquicultura-tanque-escavado.model';

export class CultivoTanqueEscavado extends BaseModel {
    constructor(
        public id?: number,
        public aquiculturaTanqueEscavado?: AquiculturaTanqueEscavado,
        public numeroTanques?: number,
        public areaCultivo?: number,
        public producao?: number,
        public conversaoAlimentar?: number,
        public ciclos?: number,
    ) {
        super();
    }

    static fromJson(json: any): CultivoTanqueEscavado {
        if (json === undefined || json === null) {
            return null;
        }
        return new CultivoTanqueEscavado(
            json.id,
            AquiculturaTanqueEscavado.fromJson(json.aquiculturaTanqueEscavado),
            json.numeroTanques,
            json.areaCultivo,
            json.producao,
            json.conversaoAlimentar,
            json.ciclos
        );
    }

}
