import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Documento} from '../../models/documento.model';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-documentos-pessoa',
    templateUrl: './documentos.component.html',
    styleUrls: ['./documentos.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentosComponent implements OnInit {
    @Input()
    public documentos: Documento[] = [];
    @Input()
    public titulo: string = null;

    public displayedColumns: string[] = ['descricao', 'acoes'];

    constructor(
        protected dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    public visualizarDocumento(documento: Documento): void {
        window.open(
            environment.URL_SCORP_API +
            '/api/documentos/' +
            documento.id +
            '/visualizar'
        );
    }

    public downloadDocumento(documento: Documento): void {
        window.open(
            environment.URL_SCORP_API + '/api/documentos/' + documento.id + '/download'
        );
    }

}
