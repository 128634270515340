import {Component, ViewEncapsulation} from '@angular/core';
import { LoadChildren, Route, Router } from '@angular/router';

@Component({
    selector: 'error-404',
    templateUrl: './404/error-404.component.html',
    styleUrls: ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error404Component {
    /**
     * Constructor
     */
    constructor(private router: Router) {

    } 
    

}

