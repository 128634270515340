import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injector} from '@angular/core';
import {BaseModel} from '../models/base.model';
import {environment} from 'environments/environment';
import {Page} from '../models/page.model';

export abstract class BaseService<T extends BaseModel> {
    protected http: HttpClient;
    urlResource: string = environment.URL_GATEWAY;
    urlEeEolica: string = environment.URL_EMPREENDIMENTO_EE_EOLICA;
    urlScorp: string = environment.URL_SCORP_API;

    constructor(
        protected apiPath: string,
        protected injector: Injector,
        protected type: T,
        protected jsonToResourceFn: (json: any) => T,
        protected urlRes?: string
    ) {
        this.http = injector.get(HttpClient);
        if (this.urlRes !== undefined) {
            this.urlResource = this.urlRes;
            this.urlEeEolica = this.urlRes;
        }
        this.urlResource += apiPath;
        this.urlEeEolica += apiPath;
    }

    getAll(httpParams?: HttpParams): Observable<T[]> {
        return this.http.get(this.urlResource, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getPage(httpParams?: HttpParams): Observable<Page<T>> {
        return this.http.get(`${this.urlResource}/page`, {params: httpParams}).pipe(
            map(response => this.jsonToPage(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getById(id: number): Observable<T> {
        const url = `${this.urlResource}/${id}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    create(resource: T): Observable<T> {
        return this.http
            .post(this.urlResource, resource)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    update(resource: T): Observable<T> {
        const url = `${this.urlResource}/${resource.id}`;
        return this.http.put(url, resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<any> {
        const url = `${this.urlResource}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    protected jsonToPage(json: any): Page<T> {
        return Page.fromJson(
            this.jsonToResources(json.content),
            json,
            this.type
        );
    }

    protected jsonToResources(json: any): T[] {
        const resources: T[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: T) => resources.push(this.jsonToResourceFn(e)));
        }
        return resources;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
    
    getAllEolica(httpParams?: HttpParams): Observable<T[]> {
        return this.http.get(this.urlEeEolica, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getPageEolica(httpParams?: HttpParams): Observable<Page<T>> {
        return this.http.get(`${this.urlEeEolica}/page`, {params: httpParams}).pipe(
            map(response => this.jsonToPage(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getByIdEolica(id: number): Observable<T> {
        const url = `${this.urlEeEolica}/${id}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createEolica(resource: T): Observable<T> {
        return this.http
            .post(this.urlEeEolica, resource)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateEolica(resource: T): Observable<T> {
        const url = `${this.urlEeEolica}/${resource.id}`;
        return this.http.put(url, resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    deleteEolica(id: number): Observable<any> {
        const url = `${this.urlEeEolica}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    createTecnologia(resource: T): Observable<T> {
        return this.http
            .post(this.urlEeEolica, resource)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
    
    createGerador(resource: T): Observable<T> {
        return this.http
            .post(this.urlEeEolica, resource)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createContigencia(resource: T): Observable<T> {
        return this.http
            .post(this.urlEeEolica, resource)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    deleteTecnologia(id: number): Observable<any> {
        const url = `${this.urlEeEolica}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    deleteGerador(id: number): Observable<any> {
        const url = `${this.urlEeEolica}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    deleteContingencia(id: number): Observable<any> {
        const url = `${this.urlEeEolica}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    getByIdEmissaoTitulo(idEmissaoTitulo: number): Observable<T> {
        const url = `${this.urlResource}/by-emissao-titulo/${idEmissaoTitulo}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
