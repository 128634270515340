import {CommonsUtil} from './../../../../../../../../shared/util/commons-util';
import {MatTableDataSource} from '@angular/material';
import {UnidadeContingenciaUsinaTerm} from './../../../../../../../../shared/models/energia-termica-unidade-contingencia.model';
import {Dominio} from './../../../../../../../../shared/models/dominio-model';
import {SnackBarService} from './../../../../../../../../shared/snack-bar/snack-bar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DominioService} from '../../../../../../../../shared/services/dominio.service';
import {EmpreendimentoEnergeticoTermicaService} from '../empreendimento-energetico-termica.service';

@Component({
    selector: 'app-unidade-contingencia',
    templateUrl: './unidade-contingencia.component.html',
    styleUrls: ['./unidade-contingencia.component.scss']
})
export class UnidadeContingenciaComponent implements OnInit {

    @Input() idEEtermica: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;
    @Input() tipoUsina: any;
    modoEdicao: boolean = false;

    @Output() enviarUnidadeContingencia = new EventEmitter<UnidadeContingenciaUsinaTerm[]>();
    formUnidadeContingencia: FormGroup;

    unidadesContingencia: any[] = [];
    dataSourceUnidadesContingencia = new MatTableDataSource<any>([]);

    @Output() save: EventEmitter<any> = new EventEmitter<any>();

    displayedColumns: string[] = ['identificacao', 'potenciaInstalada', 'tipo', 'subtipo', 'descricao', 'acao'];

    tiposCombustivel: any;
    tipoBiomassa: Dominio[] = [];
    subTipoCombustivel: any;
    subTiposCombustivel: any;
    mostrarCampoOutroTipo = false;
    mostrarCampoOutroSubTipo = false;
    idTabela: number = 0;

    constructor(
        private fb: FormBuilder,
        private dominioService: DominioService,
        private snackBarService: SnackBarService,
        private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService,
    ) {
    }

    ngOnInit() {
        this.formUnidadeContingencia = this.fb.group({
            id: [''],
            idTemp: [null],
            identificacao: ['', [Validators.required]],
            potenciaInstalada: ['', [Validators.required]],
            tipoCombustivel: ['', [Validators.required]],
            subTipoCombustivel: ['', [Validators.required]],
            descricaoTipo: [''],
            descricaoSubTipo: [''],
            descricao: ['']
        });

        if (this.idEEtermica) {
            this.empreendimentoEnergService.buscaEEtermicaPorId(this.idEEtermica).subscribe(result => {
                this.carregaUnidadeContingCasoExistaIdEEtermica(result);
            });
        }

        if (this.isNaoPodeEditar) {
            this.formUnidadeContingencia.disable();
        }
    }

    async getTipoCombustivel(): Promise<boolean> {
        if (this.tipoUsina) {
            if (this.tipoUsina === 1) {
                this.tiposCombustivel = [];
                this.tiposCombustivel = await this.dominioService.buscarTipoBiomassa().toPromise();

            } else if (this.tipoUsina === 2) {
                this.tiposCombustivel = [];
                this.tiposCombustivel = await this.dominioService.buscarTipoCombustivel().toPromise();

            } else if (this.tipoUsina.idBiomassa && this.tipoUsina.idFossil) {
                this.tiposCombustivel = [];
                const usinaBio = await this.dominioService.buscarTipoBiomassa().toPromise();
                const biomassaList = [];
                usinaBio.forEach(result => {
                    biomassaList.push({
                        id: result.id,
                        descricao: result.descricao,
                        tipoUsina: 1
                    });
                });
                const usinaFossil = await this.dominioService.buscarTipoCombustivel().toPromise();
                const fossilList = [];
                usinaFossil.forEach(result => {
                    fossilList.push({
                        id: result.id,
                        descricao: result.descricao,
                        tipoUsina: 2,
                    });
                });
                this.tiposCombustivel = biomassaList.concat(fossilList);
            }
        } else {
            this.snackBarService.showAlert(
                'Selecione o Tipo de Usina.');
            this.formUnidadeContingencia.markAllAsTouched();
            return false;
        }
    }

    carregaSubTipoCombustivel(tipo: any): void {
        this.exibirOutrosTipo(tipo.value);
        if (this.tipoUsina === 1) {
            this.dominioService.buscarSubTipoBiomassa()
                .subscribe(subTipoBiomassa => {
                    this.subTiposCombustivel = subTipoBiomassa.filter(subTipo => subTipo.idTipoBiomassa === tipo.value.id);
                });
        } else if (this.tipoUsina === 2) {
            this.dominioService.buscarSubTipoCombustivel().subscribe(subTipoCombustivel => {
                this.subTiposCombustivel = subTipoCombustivel.filter(subTipo => subTipo.idTipoCombustivel === tipo.value.id);
            });
        } else if (this.tipoUsina.idBiomassa && this.tipoUsina.idFossil) {
            if (tipo.value.tipoUsina === 1) {
                this.dominioService.buscarSubTipoBiomassa()
                    .subscribe(subTipoBiomassa => {
                        this.subTiposCombustivel = subTipoBiomassa.filter(subTipo => subTipo.idTipoBiomassa === tipo.value.id);
                    });
            } else if (tipo.value.tipoUsina === 2) {
                this.dominioService.buscarSubTipoCombustivel().subscribe(subTipoCombustivel => {
                    this.subTiposCombustivel = subTipoCombustivel.filter(subTipo => subTipo.idTipoCombustivel === tipo.value.id);
                });
            }
        }
    }

    exibiOutrosSubTipo(unidadeSelecionada): void {
        const unidade = unidadeSelecionada.value ? unidadeSelecionada.value : unidadeSelecionada;
        if (unidade.descricao === 'Outro') {
            this.mostrarCampoOutroSubTipo = true;
            this.mostrarCampoOutroTipo = false;
        } else {
            this.formUnidadeContingencia.controls.descricaoSubTipo.reset();
            this.mostrarCampoOutroSubTipo = false;
        }
    }

    adicionaUnidadeContingencia(): void {
        const validacao = this.validarFormulario();
        if (validacao) {
            let descricaoGenerica;
            if (!CommonsUtil.isEmpty(this.formUnidadeContingencia.controls.descricaoTipo.value)) {
                descricaoGenerica = this.formUnidadeContingencia.controls.descricaoTipo.value;
            } else {
                descricaoGenerica = this.formUnidadeContingencia.controls.descricaoSubTipo.value;
            }
            this.unidadesContingencia.push({
                id: null,
                idTemp: this.retornarIdTabela(),
                identificacao: this.formUnidadeContingencia.controls.identificacao.value,
                potenciaInstalada: Number(this.formUnidadeContingencia.controls.potenciaInstalada.value),
                tipoCombustivel: this.formUnidadeContingencia.controls.tipoCombustivel.value,
                subTipoCombustivel: this.formUnidadeContingencia.controls.subTipoCombustivel.value,
                descricao: descricaoGenerica
            });

            this.dataSourceUnidadesContingencia = new MatTableDataSource(this.unidadesContingencia);
            this.formUnidadeContingencia.reset();
            this.enviarUnidadesContingencia(this.mapearArray());
            this.mostrarCampoOutroTipo = false;
            this.mostrarCampoOutroSubTipo = false;
        }
    }

    retornarIdTabela(): number {
        this.idTabela++;
        return this.idTabela;
    }

    enviarUnidadesContingencia(unidades: UnidadeContingenciaUsinaTerm[]) {
        this.enviarUnidadeContingencia.emit(unidades);
    }

    deletarItem(item, acao?: string, itemId?: number): void {
        if (acao === 'DELETAR' && itemId != null) {
            this.empreendimentoEnergService.deletarUnidContingentePorId(itemId).subscribe(() => {
                this.snackBarService.showSuccess('Unidade Contingente excluido com sucesso.');
            });
        }
        let data;
        data = this.unidadesContingencia.filter((value, key) => {
            return value.idTemp !== item;
        });
        this.unidadesContingencia = data;
        this.dataSourceUnidadesContingencia = data;
        this.enviarUnidadesContingencia(this.mapearArray());
    }

    exibirOutrosTipo(unidadeSelecionada): void {
        if (unidadeSelecionada.descricao === 'Outro') {
            this.mostrarCampoOutroTipo = true;
            this.mostrarCampoOutroSubTipo = false;
            this.formUnidadeContingencia.controls.subTipoCombustivel.disable();
            this.formUnidadeContingencia.controls.subTipoCombustivel.reset();
        } else {
            this.formUnidadeContingencia.controls.descricaoTipo.reset();
            this.formUnidadeContingencia.controls.subTipoCombustivel.enable();
            this.mostrarCampoOutroTipo = false;
        }
    }

    mapearArray(): any {
        if (this.unidadesContingencia.length == 0) {
            return this.unidadesContingencia;
        } else {
            return this.unidadesContingencia.map(function (item) {
                return new UnidadeContingenciaUsinaTerm(
                    null,
                    null,
                    item.identificacao,
                    item.potenciaInstalada,
                    item.tipoCombustivel.id,
                    item.subTipoCombustivel ? item.subTipoCombustivel.id : null,
                    item.descricao,
                );
            });
        }
    }

    ativarModoEdicaoUnidadeCont(unidade) {
        this.formUnidadeContingencia.controls.id.patchValue(unidade.id);
        this.formUnidadeContingencia.controls.idTemp.patchValue(unidade.idTemp);
        this.formUnidadeContingencia.controls.identificacao.patchValue(unidade.identificacao);
        this.formUnidadeContingencia.controls.potenciaInstalada.patchValue(unidade.potenciaInstalada);
        this.formUnidadeContingencia.controls.tipoCombustivel.patchValue(unidade.tipoCombustivel);
        this.exibirOutrosTipo(unidade.tipoCombustivel);

        if (unidade.subTipoCombustivel == null) {
            unidade.subTipoCombustivel = {};
        }

        this.formUnidadeContingencia.controls.subTipoCombustivel.patchValue(unidade.subTipoCombustivel);
        this.exibiOutrosSubTipo(unidade.subTipoCombustivel);
        this.formUnidadeContingencia.controls.descricaoTipo.patchValue(unidade.descricao ? unidade.descricao : '');
        this.formUnidadeContingencia.controls.descricaoSubTipo.patchValue(unidade.descricao ? unidade.descricao : '');
        this.formUnidadeContingencia.controls.descricao.patchValue(unidade.descricao ? unidade.descricao : '');
        this.modoEdicao = true;

    }

    editarUnidadeCont() {
        const validacao = this.validarFormulario();
        if (validacao) {
            this.deletarItem(this.formUnidadeContingencia.controls.idTemp.value);
            this.adicionaUnidadeContingencia();
            this.modoEdicao = false;
        }
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.formUnidadeContingencia.reset();
        this.formUnidadeContingencia.markAsUntouched();
        this.formUnidadeContingencia.updateValueAndValidity()
    }


    validarFormulario(): boolean {
        if (this.formUnidadeContingencia.valid) {
            if ((this.mostrarCampoOutroTipo)) {

                if (CommonsUtil.isEmpty(this.formUnidadeContingencia.controls.tipoCombustivel.value)) {
                    this.snackBarService.showAlert(
                        'O campo Tipo Combustível ' +
                        ' é obrigatório' +
                        'Favor realize o preenchimento do mesmo.');
                    this.formUnidadeContingencia.markAllAsTouched();
                    return false;
                }

                if (CommonsUtil.isEmpty(this.formUnidadeContingencia.controls.descricaoTipo.value)) {
                    this.snackBarService.showAlert(
                        'O campo Descrição de Outro tipo ' +
                        ' é obrigatório' +
                        'Favor realize o preenchimento do mesmo.');
                    this.formUnidadeContingencia.markAllAsTouched();
                    return false;
                }

            }

            if ((this.mostrarCampoOutroSubTipo)) {
                if (CommonsUtil.isEmpty(this.formUnidadeContingencia.controls.descricaoSubTipo.value)) {
                    this.snackBarService.showAlert(
                        'O campo Descrição de Outro Subtipo ' +
                        ' é obrigatório' +
                        'Favor realize o preenchimento do mesmo.');
                    this.formUnidadeContingencia.markAllAsTouched();
                    return false;
                }

                if (CommonsUtil.isEmpty(this.formUnidadeContingencia.controls.subTipoCombustivel.value)) {
                    this.snackBarService.showAlert(
                        'O campo Sub Tipo Combustível ' +
                        ' é obrigatório' +
                        'Favor realize o preenchimento do mesmo.');
                    this.formUnidadeContingencia.markAllAsTouched();
                    return false;
                }
            }
            return true;
        } else {
            this.snackBarService.showAlert(
                'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            this.formUnidadeContingencia.markAllAsTouched();
            return false;
        }
    }


    carregaUnidadeContingCasoExistaIdEEtermica(eeTermica: any): void {
        this.empreendimentoEnergService.buscaListUnidadeContingente(eeTermica.id).subscribe(async result => {
            if (eeTermica.idTipoUsinaBiomassa === 1) {
                this.tiposCombustivel = await this.dominioService.buscarTipoBiomassa().toPromise();
            }
            if (eeTermica.idTipoUsinaFossil === 1) {
                this.tiposCombustivel = await this.dominioService.buscarTipoCombustivel().toPromise();
            }

            for (const unidadeConting of result) {
                const tipo = await this.tiposCombustivel.filter(tipoComb => tipoComb.id === unidadeConting.idTipoCombustivel);
                const listSubTipo = await this.dominioService.buscarSubTipoBiomassa().toPromise();
                const SubTipo = listSubTipo.filter(subTipo => subTipo.id === unidadeConting.idSubTipoCombustivel);

                this.unidadesContingencia.push({
                    id: unidadeConting.id,
                    idTemp: this.retornarIdTabela(),
                    identificacao: unidadeConting.identificacaoUG,
                    potenciaInstalada: unidadeConting.valorPotenciaInstalada,
                    tipoCombustivel: tipo[0],
                    subTipoCombustivel: SubTipo[0],
                    descricao: unidadeConting.descricao ? unidadeConting.descricao : ''
                });

                this.dataSourceUnidadesContingencia = new MatTableDataSource(this.unidadesContingencia);
            }
        });
    }
}

