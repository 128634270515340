import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {OrgaoAmbiental} from '../models/orgao-ambiental.model';

@Injectable({
    providedIn: 'root'
})
export class OrgaoAmbientalService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/orgaos-ambientais';
    }

    getAll(): Observable<OrgaoAmbiental[]> {
        const url = `${this.urlResource}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    private jsonDataToResourceList(jsonData: any[]): OrgaoAmbiental[] {
        const entries: OrgaoAmbiental[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new OrgaoAmbiental(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
