import {BaseModel} from './base.model';

export class CrematorioMonitoramentoDTO extends BaseModel {
    constructor(
        public id?: number,
        public idCrematorio?: number,
        public idTipoMonitoramentoContinuo?: number,
        public descricao?: string
    ) {
        super();
    }

    static fromJson(json: any): CrematorioMonitoramentoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new CrematorioMonitoramentoDTO(
            json.id,
            json.idCrematorio,
            json.idTipoMonitoramentoContinuo,
            json.descricao);
    }

    static fromJsons(jsonArray: any): CrematorioMonitoramentoDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
