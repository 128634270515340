import {BaseModel} from 'app/main/shared/models/base.model';
import {PessoaJuridicaCorporativo} from './pessoa-juridica-corporativo.model';
import {PessoaFisicaCorporativo} from './pessoa-fisica-corporativo.model';

export class PessoaCorporativo extends BaseModel {
    constructor(
        public id?: number, // id no scorp
        public tipo?: string,
        public cadastroCompleto?: boolean,
        public pessoaFisica?: PessoaFisicaCorporativo,
        public pessoaJuridica?: PessoaJuridicaCorporativo,
        public contato?: any,
        public endereco?: any
    ) {
        super();
    }

    static fromJson(json: any): PessoaCorporativo {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaCorporativo(
            json.id,
            json.tipo
                ? json.tipo
                : json.tipoPessoa
                ? json.tipoPessoa
                : json.pessoaFisica
                    ? 'PF'
                    : json.pessoaJuridica
                        ? 'PJ'
                        : null,
            json.cadastroCompleto,
            json.pessoaFisica,
            json.pessoaJuridica,
            json.contato,
            json.endereco
        );
    }

    static fromJsons(json: any): PessoaCorporativo[] {
        const pessoas: PessoaCorporativo[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((pessoa) => {
                pessoas.push(this.fromJson(pessoa));
            });
            return pessoas;
        }
    }

    _toString(): string {
        return (
            (this.pessoaFisica != null
                ? this.pessoaFisica.nome
                : this.pessoaJuridica
                    ? this.pessoaJuridica.razaoSocial ||
                    this.pessoaJuridica.nomeFantasia
                    : this.id) +
            (this.pessoaFisica != null
                ? ' - ' + this.pessoaFisica.cpfCnpj
                : this.pessoaJuridica
                    ? ' - ' + this.pessoaJuridica.cnpj
                    : '')
        );
    }
}
