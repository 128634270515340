import { Component, Input } from '@angular/core';
import { AbstractComponenteRelacionamento } from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento";
import { SistemaTratamentoPrimario } from "../../../../../../../../shared/models/sistema-tratamento-primario.model";
import { SistemaTratamentoPrimarioService } from "../../../../../../../../shared/services/sistema-tratamento-primario.service";

@Component({
  selector: 'app-atv-sist-tratamento-primario',
  templateUrl: './atv-sist-tratamento-primario.component.html',
  styleUrls: ['./atv-sist-tratamento-primario.component.scss']
})
export class AtvSistTratamentoPrimarioComponent extends AbstractComponenteRelacionamento<SistemaTratamentoPrimario, SistemaTratamentoPrimarioService> {

  @Input() isNaoPodeEditar: boolean;

  getDisplayedColumns(): string[] {
    return ['tipo', 'descricao', 'acoes'];
  }


}
