import {ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatStepper} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {BaseModel} from 'app/main/shared/models/base.model';
import {Subject} from 'rxjs';
import {RequerimentoService} from '../../../requerimento.service';
import {DoencaGrave} from '../../../../../shared/models/doenca-grave.model';
import {DoencaGraveService} from '../../../../../shared/services/doenca-grave.service';
import {Requerimento} from '../../../requerimento.model';
import {EnumTipoDocumentoDoencaPessoa} from '../enum-tipo-documento-doenca.model';
import {DocumentoDoencaPessoa} from '../documento-doenca-pessoa.model';
import {RequerimentoHelperAcessors} from '../../requerimento.helper';
import {FileValidationUtil} from '../../../../../shared/util/file-validation-util';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {ConfirmarAlteracaoInteressadoDialogComponent} from '../confirmar-alteracao-interessado/confirmar-alteracao-interessado-dialog.component';
import {PessoaCorporativoService} from '../../../../../shared/services/pessoa-corporativo.service';
import {Pessoa} from '../../../../../shared/models/pessoa.model';
import { EnumInstanciaRequerimento } from '../../../menu-novo-requerimento/instancia-requerimento.enum';

/**
 * Documento de Requisitos
 * SEMA_SIGA_02_EspecificacaoFuncional_CadastroRequerimento
 * Pag.63
 */

@Component({
    selector: 'app-requerimento-interessado-pf',
    templateUrl: 'requerimento-interessado-pf.component.html',
    styleUrls: ['./requerimento-interessado-pf.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoInteressadoPfComponent extends RequerimentoHelperAcessors implements OnInit {
    protected activatedRoute: ActivatedRoute;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        protected requerimentoService: RequerimentoService,
        private doencaGraveService: DoencaGraveService,
        private pessoaCorporativoService: PessoaCorporativoService,
        injector: Injector
    ) {
        super(injector);
        this.activatedRoute = injector.get(ActivatedRoute);
    }

    @Input()
    requerimento: Requerimento;
    @Input()
    salvarSubject: Subject<any>;
    @Input()
    dirtySubject: Subject<any> = null;
    @Input()
    stepper: MatStepper;
    @Input() somenteVisualizacao: boolean;

    @Input() altDocResp: boolean;

    tiposDoencasGraves: DoencaGrave[] = null;
    documentosResponsavelDoencaGrave: DocumentoDoencaPessoa[] = EnumTipoDocumentoDoencaPessoa.listarDocumentosRestantes();

    // forms
    form: FormGroup;
    pessoa: Pessoa;

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.buildResourceForm();
        this.loadData();

        if (!this.altDocResp) {
            this.somenteVisualizacao = true;
        } else {
            this.somenteVisualizacao = false;
        }

        if (this.somenteVisualizacao) {
            this.form.disable();
        }
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            idadeSuperior60Anos: [null, [Validators.required]],
            possuiDeficienciaFisicaMental: [null, [Validators.required]],
            possuiDoencaGrave: [null, [Validators.required]],
            doencaGrave: [null],
            doenca: [{value: null, disabled: true}, [Validators.maxLength(150)]]
        });
    }

    private async loadData() {
        //Busca pessoa com os dados atualizados no SCORP para exibir no componente app-visualizar-pessoa
        this.pessoa = await this.pessoaCorporativoService.getByCpfCnpj(this.requerimento.pessoa.cpfCnpj).toPromise().then();

        this.form.patchValue(this.requerimento.pessoa);

        this.doencaGraveService.getAll().subscribe((result) => {
            this.tiposDoencasGraves = result;
        });
        this.listarDocumentos();
    }

    listarDocumentos(): void {
        this.requerimentoService.listarDocumentosDoencaPessoa(this.requerimento.id).subscribe(result => {
            this.documentosResponsavelDoencaGrave = EnumTipoDocumentoDoencaPessoa.listarDocumentosRestantes(result);
        }, error => {
            console.log(error);
        });
    }

    onTipoDoencaChanged(event): void {
        if (event) {
            const field = this.form.controls.doenca;
            if (this.form.controls.doencaGrave.value.outro) {
                field.enable();
                field.setValidators([Validators.required]);
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
            }
        }
    }

    salvarDadosInteressado(): void {
        this.form.markAllAsTouched();
        if (this.isFormValid()) {
            const formData = new FormData();

            if (this.form.controls.possuiDoencaGrave.value) {
                this.documentosResponsavelDoencaGrave.forEach(anexo => {
                    if (anexo.tipo === EnumTipoDocumentoDoencaPessoa.CID) {
                        if (anexo.arquivo) {
                            formData.append('fileCID', anexo.arquivo);
                        }
                        formData.append('sigiloCID', anexo.sigilo ? 'true' : 'false');

                    } else if (anexo.tipo === EnumTipoDocumentoDoencaPessoa.LAUDO) {
                        if (anexo.arquivo) {
                            formData.append('fileLaudoMedico', anexo.arquivo);
                        }
                        formData.append('sigiloLaudoMedico', anexo.sigilo ? 'true' : 'false');

                    }
                });
            } else {
                this.form.controls.doencaGrave.setValue(null);
            }

            formData.append('pessoa', new Blob([JSON.stringify(this.form.value)], {type: 'application/json'}));
            const idTarefa = this.activatedRoute.snapshot.url[3].path;
            this.requerimentoService.putInteressado(this.requerimento.id, formData, idTarefa).subscribe((result) => {
                this.requerimento.pessoa = result.pessoa;
                this.form.patchValue(result.pessoa);
                this.form.markAsPristine();
                this.form.markAsUntouched();
                this.form.updateValueAndValidity();
                this.snackBarService.showSuccess('Interessado atualizado com sucesso!');
                this.listarDocumentos();
            }, e => {
                this.snackBarService.showError('Erro ao atualizar o interessado.', e);
            });
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    isFormValid(): boolean {
        if (this.form.invalid) {
            return false;
        }
        if (this.form.controls.possuiDoencaGrave.value) {
            if (this.form.controls.doencaGrave.value == null) {
                return false;
            }
            if (this.form.controls.doencaGrave.value.outro && !this.form.controls.doenca.value) {
                return false;
            }
            if (this.quantidadeDeAnexoAdicionado() < 1) {
                return false;
            }
        }
        return true;
    }

    removerAnexo(index: number): void {
        const anexo = this.documentosResponsavelDoencaGrave[index];
        anexo.arquivo = null;
        anexo.nomeArquivo = null;
        document.getElementById(`input-arquivo-${index}`)['value'] = null;
    }

    selecionarAnexo(file: File, index: number): void {
        if (FileValidationUtil.invalidSize(file, FileValidationUtil.size('5MB'))) {
            this.snackBarService.showAlert('Arquivo com tamanho inválido, o tamanho limite é 5MB.');
            document.getElementById(`input-arquivo-${index}`)['value'] = null;
            return;
        }
        const anexo = this.documentosResponsavelDoencaGrave[index];
        anexo.arquivo = file;
        anexo.nomeArquivo = file.name;
        this.form.markAsTouched();
        this.form.markAsDirty();
        this.form.updateValueAndValidity();
    }

    public visualizarDocumento(documento: DocumentoDoencaPessoa): void {
        this.requerimentoService.downloadDocumentoDoencaPessoa(this.requerimento.id, documento).subscribe((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    public downloadDocumento(documento: DocumentoDoencaPessoa): void {
        window.open(
            `${this.requerimentoService.urlResource}/${this.requerimento.id}/interessado/documentos-doenca/${EnumTipoDocumentoDoencaPessoa.toJson(documento.tipo)}`
        );
    }

    /**
     * Quantidade de anexo em memória,
     * para validar o minímo de UM arquivo necessário.
     */
    quantidadeDeAnexoAdicionado(): number {
        let count = 0;
        this.documentosResponsavelDoencaGrave.forEach(anexo => {
            if (anexo.arquivo || anexo.id != null) {
                count++;
            }
        });
        return count;
    }

    confirmarAlteracaoInteressadoDialog(): void {
        const matDialogRef = this.dialog.open(ConfirmarAlteracaoInteressadoDialogComponent, {width: '400px'});
        matDialogRef.afterClosed().subscribe(confirmou => {
            if (!!confirmou) {
                this.removerInteressado();
            }
        });
    }

    removerInteressado(): void {
        const idTarefa = this.activatedRoute.snapshot.url[3].path;
        this.requerimentoService.deleteInteressado(this.requerimento.id, idTarefa).subscribe((result) => {
            this.requerimento = result;
            this.form.patchValue({});
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.form.updateValueAndValidity();
            this.snackBarService.showSuccess('Interessado atual removido. Selecione um novo Interessado!');
            this.listarDocumentos();
        }, e => {
            this.snackBarService.showError('Erro ao remover o interessado.', e);
        });
    }

    get pessoaAdicionada(): boolean {
        return this.requerimento.pessoa !== null;
    }

    // isAcaoDeAlteracaoPermitida(): boolean {
    //     console.log("isAcaoDeAlteracaoPermitida() >> ", !this.somenteVisualizacao);
    //     return this.requerimento && this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO ||
    //     (this.requerimento && this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO 
    //         && this.requerimento.idTipoRequerimentoTifLas != 2);
    // }

    // isAcaoDeAlteracaoPermitidaFluxoTrifasicoAndamento(): boolean {
    //     console.log("isAcaoDeAlteracaoPermitidaFluxoTrifasicoAndamento() >> ", !this.somenteVisualizacao);
    //     return this.requerimento && this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO 
    //         && this.requerimento.idTipoRequerimentoTifLas == 2; // && altDocResp
    // }

    isAcaoDeAlteracaoPermitida(): boolean {
        return this.requerimento && this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO 
            ||
            (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO  && this.requerimento.idTipoRequerimentoTifLas != 2) 
            ||
            (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO  && this.requerimento.idTipoRequerimentoTifLas === 2 && this.altDocResp) 
            || this.altDocResp;
    }
    
}

