import {BaseModel} from "./base.model";
import {DadosGeraisTransporteResiduosPerigosos} from "./dados-gerais-transporte-residuos-perigosos.model";
import {VeiculosEquipamentosResiduosPerigosos} from "./veiculos-equipamentos-residuos-perigosos.model";
import {TransporteResiduosPerigosos} from "./transporte-residuos-perigosos.model";
import {RotasRodoviasDto} from "./rotas-rodovias-dto.model";

export class TransporteResiduosPerigososDto extends BaseModel {
    constructor(
        public idEmpreendimento?: number,
        public idRequerimento?: number,
        public listaDadosGerais?: DadosGeraisTransporteResiduosPerigosos[],
        public listaRotasRodoviasDTO?: RotasRodoviasDto[],
        public listaVeiculos?: VeiculosEquipamentosResiduosPerigosos[],
        public listaResiduosPerigosos?: TransporteResiduosPerigosos[],
    ) {
        super();
    }

    static fromJson(json: any): TransporteResiduosPerigososDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new TransporteResiduosPerigososDto(
            json.idEmpreendimento,
            json.idRequerimento,
            DadosGeraisTransporteResiduosPerigosos.fromJsons(json.listaDadosGerais),
            RotasRodoviasDto.fromJsons(json.listaRotasRodoviasDTO),
            VeiculosEquipamentosResiduosPerigosos.fromJsons(json.listaVeiculos),
            TransporteResiduosPerigosos.fromJsons(json.listaResiduosPerigosos),
        );
    }

    static fromJsons(json: any): TransporteResiduosPerigososDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(TransporteResiduosPerigososDto.fromJson(j)));
            return resources;
        }
    }
}