import {BaseModel} from "../base.model";

export class ObjetivoConfiguracaoLicenciamento extends BaseModel {
    constructor(
        public id?: number,
        public objetivoLicenciamento?: number,
        public configuracaoLicenciamento?: number,
        public cadastro?: Date
    ) {
        super();
    }

    static fromJson(json: any): ObjetivoConfiguracaoLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObjetivoConfiguracaoLicenciamento(
            json.id,
            json.objetivoLicenciamento,
            json.configuracaoLicenciamento,
            json.cadastro
        );
    }
}
