import {BaseRelacionamentoModel} from "./base-relacionamento.model";

export class MateriaPrimaIndBiocombustivel extends BaseRelacionamentoModel {
    constructor(
        public id: number,
        public idDominio: number,
        public idEntidade: number,
        public descricao: string,
        public idMateriaPrima: number,
        public descricaoMateriaPrima: string,
        public cogeracaoEnergia: boolean
    ) {
        super(id, idDominio, idEntidade, descricao);
    }

    static fromJson(json: any): MateriaPrimaIndBiocombustivel {
        if (json === undefined || json === null) {
            return null;
        }
        return new MateriaPrimaIndBiocombustivel(
            json.id,
            json.idDominio,
            json.idEntidade,
            json.descricao,
            json.idMateriaPrima,
            json.descricaoMateriaPrima,
            json.cogeracaoEnergia,
        );
    }

}
