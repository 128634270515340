import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ParecerRequerimentoAvaliacao} from './parecer-requerimento-avaliacao.model';
import {ServiceAssinador} from "../../../shared/services/service-assinador.interface";
import {Solicitacao} from "../../../shared/models/solicitacao.model";

@Injectable({
    providedIn: 'root',
})
export class ParecerRequerimentoAvaliacaoService extends BaseService<ParecerRequerimentoAvaliacao> implements ServiceAssinador{
    constructor(protected injector: Injector) {
        super(
            '/api/parecer-requerimento-avaliacao',
            injector,
            ParecerRequerimentoAvaliacao.prototype,
            ParecerRequerimentoAvaliacao.fromJson
        );
    }

    finalizarTarefaCamundaValidarParecer(idTarefa: string, parecerRequerimentoAvaliacao: Solicitacao<ParecerRequerimentoAvaliacao>): Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa/${idTarefa}/`;
        return this.http.put(url, parecerRequerimentoAvaliacao).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    recuperarParecerRequerimentoAvaliacaoCpfLogado(idRequerimento: number): Observable<ParecerRequerimentoAvaliacao> {
        const url = `${this.urlResource}/parecer-requerimento-avaliacao/usuario/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    recuperarParecerRequerimentoAvaliacaoServidor(servidorId: number, idRequerimento: number): Observable<ParecerRequerimentoAvaliacao> {
        const url = `${this.urlResource}/parecer-requerimento-avaliacao/${idRequerimento}/servidor/${servidorId}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    assinarDocumento(alvoAssinador: any, senha: string, idTarefa?: string): Observable<any> {
        const url = `${this.urlResource}/assinar-parecer-tecnico`;
        return this.http.post(url, alvoAssinador, {params: {idTarefa: idTarefa, senha: btoa(senha)}}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    assinarDocumentoCodigoVerificador(alvoAssinador: any, senha: string, idTarefa?: string){
        return this.http.post('', {});
    }
}
