import {BaseModel} from 'app/main/shared/models/base.model';
import {AquiculturaTanqueRede} from './aquicultura-tanque-rede.model';

export class CultivoTanqueRede extends BaseModel {
    constructor(
        public id?: number,
        public aquiculturaTanqueRede?: AquiculturaTanqueRede,
        public areaCultivo?: number,
        public producao?: number,
        public conversaoAlimentar?: number,
        public ciclos?: number,
    ) {
        super();
    }

    static fromJson(json: any): CultivoTanqueRede {
        if (json === undefined || json === null) {
            return null;
        }
        return new CultivoTanqueRede(
            json.id,
            AquiculturaTanqueRede.fromJson(json.aquiculturaTanqueRede),
            json.areaCultivo,
            json.producao,
            json.conversaoAlimentar,
            json.ciclos
        );
    }

}
