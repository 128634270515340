import {Injectable, Injector} from '@angular/core';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseCorporativoService} from './base-corporativo.service';
import {Pessoa} from 'app/main/shared/models/pessoa.model';
import {PessoaCorporativo} from '../models/pessoa-corporativo.model';
import {Documento} from '../models/documento.model';
import {PessoaJuridicaCorporativo} from '../models/pessoa-juridica-corporativo.model';
import { DadosContatoPessoaDTO } from '../models/dados-contato-pessoa-dto.model';

@Injectable({
    providedIn: 'root'
})
export class PessoaCorporativoService extends BaseCorporativoService<any> {

    constructor(protected injector: Injector) {
        super('/api/pessoas', injector, PessoaCorporativo.prototype, PessoaCorporativo.fromJson);
    }

    /**
     * Obtem cadastro completo, pessoaFisica, pessoaJuridica, contato, endereco
     * @param idPessoa
     */
    getCadastroCompleto(idPessoa: number): any {
        return this.http.get(`${this.urlResourceCorporativo}/${idPessoa}`)
        .pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getByCpfCnpj(cpf: string): Observable<Pessoa> {
        const url = `${this.urlResourceCorporativo}/cpf-cnpj/${cpf}`;

        return this.http
        .get(url)
        .pipe(
            map(Pessoa.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getById(idPessoa: number): Observable<Pessoa> {
        const url = `${this.urlResourceCorporativo}/pessoa-licenciamento/${idPessoa}/id`;

        return this.http
        .get(url)
        .pipe(
            map(Pessoa.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getByPessoaJuridicaCorpCpfCnpj(cnpj: string): Observable<PessoaJuridicaCorporativo> {
        const url = `${this.urlResourceCorporativo}/cnpj/${cnpj}`;

        return this.http
        .get(url)
        .pipe(
            map(PessoaJuridicaCorporativo.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Lista os representantes associados á empresa.
     * @param {string} cnpj - CNPJ do Empresa
     */
    getRepresentantesByCnpj(cnpj: string): Observable<Pessoa[]> {
        const url = `${this.urlResourceCorporativo}/${cnpj}/representantes`;
        return this.http.get(url).pipe(
            map(json => Pessoa.fromJsons(json)),
            catchError(this.handleError.bind(this)
            ));
    }

    /**
     * Lista os documentos de um representante.
     * @param {string} cnpjEmpresa - CNPJ da Empresa.
     * @param {Representante} representante - Representante á ser listado os documentos.
     */
    getDocumentosRepresentante(cnpjEmpresa: string, representante: Pessoa): Observable<Documento[]> {
        const url = `${this.urlResourceCorporativo}/${cnpjEmpresa}/representantes/${representante.cpfCnpj}/documentos`;
        return this.http.get(url).pipe(
            map(json => Documento.fromJsons(json)),
            catchError(this.handleError.bind(this)));
    }

    getDocumentosByPessoaIdCorporativo(idCorporativo: number): Observable<Documento[]> {
        const url = `${environment.URL_SCORP_API}/api/documentos/pessoa/${idCorporativo}`;

        return this.http
        .get(url)
        .pipe(
            map(Documento.fromJsons.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    public consultarCnpjSemValidacaoCadastro( nuCpfCnpj: string ): Observable<any> {
        const url = `${this.urlResourceCorporativo}/pessoa-dto-sem-validacao-de-cadastro/cpfcnpj/${nuCpfCnpj}`;
        return this.http.get(url);
    }

    getDadosContatoPessoa(idPessoa: number): Observable<DadosContatoPessoaDTO> {
        return this.http.get(`${this.urlResourceCorporativo}/dados-contato-pessoa/${idPessoa}`)
        .pipe(
            map(DadosContatoPessoaDTO.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }
}
