import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {RequerimentoTituloVinculado} from "../../../../shared/models/requerimento-titulo-vinculado.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { LicencaFiscalizacao } from 'app/main/pages/simlam/licenca-fiscalizacao.model';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReqTituloVinculadoService extends BaseService<RequerimentoTituloVinculado> {

    constructor(protected injector: Injector) {
        super('/api/req-titulo-vinculado', injector,
            RequerimentoTituloVinculado.prototype,
            RequerimentoTituloVinculado.fromJson);
    }

    saveAll(reqTituloVinculado: Array<RequerimentoTituloVinculado>): Observable<RequerimentoTituloVinculado[]> {
        const url = `${this.urlResource}/salvar-lista`;
        return this.http.post(url, reqTituloVinculado).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    readByReqTituloVinculado(requerimentoId: number, tipoVinculoRequerimento): Observable<RequerimentoTituloVinculado[]> {
        let httpParams = new HttpParams()
        if (tipoVinculoRequerimento) {
            httpParams = httpParams.set('tipoVinculoRequerimento', tipoVinculoRequerimento)
        }
        const url = `${this.urlResource}/requerimento/${requerimentoId}/tipo-vinculo`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(RequerimentoTituloVinculado.fromJsons.bind(this)),
            catchError(this.handleError)
        );
    }

    saveAllRequerimentoTitulosVinculados(licencas: Array<LicencaFiscalizacao>, requerimento: Requerimento): Observable<LicencaFiscalizacao[]> {
        const url = `${this.urlResource}/salvar-lista/requerimento-id/${requerimento.id}`;
        return this.http.post(url, licencas).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    // downloadPDF(idOrigemSimlam?: number) {
    //     return `${environment.URL_LIC_SIMLAM_ARQUIVO}?idTitulo=${idOrigemSimlam}`;
    // }

    // downloadPDF(idOrigemSimlam?: number): string {
    //     return this.http.get(`${environment.URL_LIC_SIMLAM_ARQUIVO}?idTitulo=${idOrigemSimlam}`, {responseType: 'blob'});
    // }

}
