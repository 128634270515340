import {BaseModel} from 'app/main/shared/models/base.model';
import {ObraPluvialFluvial} from "./obra-pluvial-fluvial.model";
import {ObraSaneamento} from "./obra-saneamento.model";
import {ObraDrenagem} from "./obra-drenagem.model";

export class InfraObraFluvSaneam extends BaseModel {
    constructor(
        public id?: number,
        public obraPluvialFluvial?: ObraPluvialFluvial,
        public obraSaneamento?: ObraSaneamento,
        public obraDrenagem?: ObraDrenagem,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): InfraObraFluvSaneam {
        if (json === undefined || json === null) {
            return null;
        }
        return new InfraObraFluvSaneam(
            json.id,
            ObraPluvialFluvial.fromJson(json.obraPluvialFluvial),
            ObraSaneamento.fromJson(json.obraSaneamento),
            ObraDrenagem.fromJson(json.obraDrenagem),
            json.requerimento
        );
    }

    static fromJsons(json: any): InfraObraFluvSaneam[] {
        const empreendimentos: InfraObraFluvSaneam[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(empreendimento => empreendimentos.push(this.fromJson(empreendimento)));
            return empreendimentos;
        }
    }

}
