import {BaseModel} from 'app/main/shared/models/base.model';

export class Composteira extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public volumeUtilCompostagem?: number,
        public numeroCelulas?: number,
        public possuiTanqueAcumulo?: number,
        public volumeTanque?: number,

    ) {
        super();
    }

    static fromJson(json: any): Composteira {
        json = json || {};

        return new Composteira(
            json.id,
            json.idGeometria,
            json.volumeUtilCompostagem,
            json.numeroCelulas,
            json.possuiTanqueAcumulo,
            json.volumeTanque,
        );
    }
}