import {Municipio} from 'app/main/shared/models/geoportal-base-referencia/municipio.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {BaseModel} from 'app/main/shared/models/base.model';
import {DocumentoRequerimento} from "../../../../documentos-requerimentos/documento-requerimento.model";
import {GeometriaDTO} from "../../../../../shared/models/geometria-dto.model";

export class Imovel extends BaseModel {

    constructor(
        public id?: number,
        public idRequerimento?: number,
        public tipo?: string,
        public iptu?: string,
        public numeroCadastroSiga?: string,
        public matricula?: string,
        public denominacao?: string,
        public utilidadePublicaOuInteresseSocial?: boolean,
        public area?: string,
        public municipio?: Municipio,
        public endereco?: string,
        public bairro?: string,
        public complemento?: string,
        public roteiroLocalizacao?: string,
        public cep?: string,
        public unidadeMedida?: string,
        public numeroCAR?: string,
        public cadastroCompleto?: boolean,
        public atividade?: string,
        public anexo?: DocumentoRequerimento,
        public abastecimentoPublico?: boolean,
        public numeroMatriculaAbastecimento?: string,
        public imovelPrincipal?: boolean,
        public idSimcar?: number,
        public situacao?: string,
        public geometrias?: GeometriaDTO[],
        public codigoIbgeMunicipio?: any,
        public latitude?: string,
        public longitude?: string,
    ) {
        super();
    }

    static unidades: BaseEnum[] = [
        {valor: 'M2', descricao: 'm² - Metros quadrados'},
        {valor: 'HA', descricao: 'Ha - Hectares'},
        {valor: 'KM2', descricao: 'Km² - Quilômetros quadrados'}
    ];

    static tiposPessoa: BaseEnum[] = [
        {valor: 'FISICA', descricao: 'Física'},
        {valor: 'JURIDICA', descricao: 'Jurídica'},
    ];

    static tiposImovel: BaseEnum[] = [
        {valor: 'URBANO', descricao: 'Imóvel urbano'},
        {valor: 'RURAL', descricao: 'Imóvel rural'},
    ];

    static fromJson(json: any): Imovel {
        if (json === undefined || json === null) {
            return null;
        }
        return new Imovel(
            json.id,
            json.idRequerimento,
            json.tipo,
            json.iptu,
            json.numeroCadastroSiga,
            json.matricula ? json.matricula : null,
            json.denominacao ? json.denominacao : null,
            json.utilidadePublicaOuInteresseSocial,
            json.area,
            json.municipio,
            json.endereco,
            json.bairro,
            json.complemento ? json.complemento : null,
            json.roteiroLocalizacao,
            json.cep,
            json.unidadeMedida,
            json.numeroCAR ? json.numeroCAR : '',
            json.cadastroCompleto,
            json.atividade,
            DocumentoRequerimento.fromJson(json.anexo),
            json.abastecimentoPublico,
            json.numeroMatriculaAbastecimento,
            json.imovelPrincipal,
            json.idSimcar,
            json.situacao,
            json.geometrias ? json.geometrias : null,
            json.codigoIbgeMunicipio,
            json.latitude,
            json.longitude,
        );
    }
}
