import {BaseModel} from 'app/main/shared/models/base.model';
import {Mineracao} from './mineracao.model';
import {Industria} from './industria.model';

export class CaracterizacaoConsumoAgua extends BaseModel {
    constructor(
        public id?: number,
        public produto?: string,
        public unidade?: number,
        public consumoAgua?: number,
        public producao?: number,
        public mineracao?: Mineracao,
        public industria?: Industria
    ) {
        super();
    }

    static fromJson(json: any): CaracterizacaoConsumoAgua {
        if (json === undefined || json === null) {
            return null;
        }
        return new CaracterizacaoConsumoAgua(
            json.id,
            json.produto,
            json.unidade,
            json.consumoAgua,
            json.producao,
            Mineracao.fromJson(json.mineracao),
            Industria.fromJson(json.industria)
        );
    }
}
