export abstract class BaseModel {
    id?: number;
    acao?: string;

    static fromJson(json: any): BaseModel {
        if (json === undefined || json === null) {
            return null;
        }
        return Object.assign({}, json);
    }
}
