import { Injectable, Injector } from '@angular/core';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { EstruturaLinhaTransmissao } from '../models/estrutura-linha-transmissao.model';
import { DetailResolver } from '../resolvers/detail-resolver';
import { BaseEeLinhaTransmissao } from './base-ee-linha-transmissao.service';
import { DominiosService } from "./dominios.service";


@Injectable()
export class CaracteristicaEletricaSistemaResolver extends DetailResolver<EstruturaLinhaTransmissao> {
    constructor(
        service: EstruturaLinhaTransmissaoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}


@Injectable({
    providedIn: "root"
})
export class EstruturaLinhaTransmissaoService extends BaseEeLinhaTransmissao<EstruturaLinhaTransmissao> {

    constructor(protected injector: Injector, protected dominiosService: DominiosService) {
        super(
            "/api/estrutura-linha-transmissao",
            injector,
            EstruturaLinhaTransmissao.prototype,
            EstruturaLinhaTransmissao.fromJson,
        );
    }

    protected get pathGetByEntidadePai(): string {
        return "ee-linha-transmissao";
    }

    protected get pathGetDominio(): string {
        return "tipo-estrutura-ee-lin-trans";
    }

    getByLinhaTransmissao(idLinhaTransmissao: number): Observable<EstruturaLinhaTransmissao[]> {
        const url = `${this.urlResource}/ee-linha-transmissao/${idLinhaTransmissao}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }


}
