import {Injectable, Injector} from '@angular/core';
import {UnidadeQuimica} from './unidade-quimica.model';
import {CachedBaseService} from '../../shared/services/cached-base.service';

@Injectable({
    providedIn: 'root'
})
export class UnidadeQuimicaService extends CachedBaseService<UnidadeQuimica> {

    constructor(protected injector: Injector) {
        super('/api/unidades-quimicas/', injector, UnidadeQuimica.fromJson);
    }

}
