import {BaseModel} from "../base.model";

export class GeometriaPonto extends BaseModel {
    constructor(
        public id?: number,
        public espessuraBorda?: number,
        public corBorda?: string,
        public simbolo?: string,
        public tipoTracejado?: number,
        public corPreenchimento?: string,
        public icone?: string
    ) {
        super();
    }

    static fromJson(json: any): GeometriaPonto {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeometriaPonto(
            json.id,
            json.espessuraBorda,
            json.corBorda,
            json.simbolo,
            json.tipoTracejado,
            json.corPreenchimento,
            json.icone
        );
    }
}
