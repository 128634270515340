import {BaseModel} from '../base.model';

export class ParametroCalculoArrecadacao extends BaseModel {

    constructor(
        public id?: number,
        public descricao?: string,
        public labelFormulario?: string,
        public tipoDado?: string,
        // public tipoParametroCalculo?: TipoParametroCalculo,
    ) {
        super();
    }

    static fromJson(json: any): ParametroCalculoArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParametroCalculoArrecadacao(
            json.id,
            json.descricao,
            json.labelFormulario,
            json.tipoDado
            // TipoParametroCalculo.fromJson(json.tipoParametroCalculo),
        );
    }

    static fromJsons(jsonArray: any): ParametroCalculoArrecadacao[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
