import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {RegrasGeoRequerimento} from "../../models/regras-geo-requerimento/regras-geo-requerimento.model";
import {environment} from "../../../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RegrasGeoRequerimentoService extends BaseService<RegrasGeoRequerimento> {
    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            RegrasGeoRequerimento.prototype,
            RegrasGeoRequerimento.fromJson
        );

        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/regras-geo-requerimento';
    }

    public getByTipoProcessoAtividadeObjetivo(idTipoProcesso, idAtividade, idObjetivoLicenciamento): Observable<RegrasGeoRequerimento[]> {
        const url = `${this.urlResource}/${idTipoProcesso}/tipo-processo/${idAtividade}/atividade/${idObjetivoLicenciamento}/objetivo`;
        return this.http.get<RegrasGeoRequerimento[]>(url).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }
}
