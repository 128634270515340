import { environment } from 'environments/environment';
import { TipoAtividade, TipoAtividadeDto } from '../../core/models/tipo-atividade';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TipoAtividadeService {

  baseUrl = `${environment.URL_LIC_RES_CONSTR_CIVIL}/api/residuo-rcc-tipo-atividade`;

  constructor(private httpClient: HttpClient) { }

  create(tipoAtividade: TipoAtividade): Observable<TipoAtividadeDto> {
    return this.httpClient.post<TipoAtividadeDto>(this.baseUrl, tipoAtividade).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }

  readById(id: string): Observable<TipoAtividadeDto> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpClient.get<TipoAtividadeDto>(url).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }

  update(tipoAtividade: TipoAtividadeDto): Observable<TipoAtividadeDto> {
    const url = `${this.baseUrl}/${tipoAtividade.id}`;
    return this.httpClient.put<TipoAtividadeDto>(url, tipoAtividade).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }

  delete(id: string): Observable<TipoAtividadeDto> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpClient.delete<TipoAtividadeDto>(url).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }

  read(): Observable<TipoAtividadeDto[]> {
    return this.httpClient.get<TipoAtividadeDto[]>(`${this.baseUrl}/lista`).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }
}
