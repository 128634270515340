import {BaseModel} from './base.model';

export class GeradorEletrico extends BaseModel {
    constructor(
        public id?: number,
        public identificacao?: string,
        public fatorPotencia?: number,
        public classeIsolamento?: string,
        public potenciaNominalAparente?: string,
        public rotacoesOperacao?: string,
        public rotacaoPotencia?: number,
        public tensao?: number,
        public dataEntradaOperacao?: number,
        public empreendimentoEnergiaEolica?: number
    ) {
        super();
    }

    static fromJson(json: any): GeradorEletrico {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeradorEletrico(
            json.id,
            json.identificacao,
            json.fatorPotencia,
            json.classeIsolamento,
            json.potenciaNominalAparente,
            json.rotacoesOperacao,
            json.rotacaoPotencia,
            json.tensao,
            json.dataEntradaOperacao,
            json.empreendimentoEnergiaEolica
        );
    }

    static fromJsons(jsonArray: any): GeradorEletrico[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
