import {ChangeDetectionStrategy, Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {RequerimentoService} from 'app/main/pages/requerimentos/requerimento.service';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {ResponsaveisCorporativoService} from '../../../../shared/services/responsaveis-corporativo.service';
import {Pessoa} from '../../../../shared/models/pessoa.model';
import {DocumentoRL} from '../requerimento-responsaveis/representante-legal/documento-rl.model';
import {TipoProcesso} from 'app/main/pages/tipos-processo/tipo-processo.model';
import {EnumTipoDocumentoRL} from '../requerimento-responsaveis/representante-legal/enum-tipo-documento-rl.model';
import {PessoaCorporativoService} from 'app/main/shared/services/pessoa-corporativo.service';
import {EmpreendedorDialogComponent} from 'app/main/shared/components/aba-responsaveis/dialog-empreendedor/empreendedor-dialog-component';
import {fuseAnimations} from '@fuse/animations';

@Component({
    selector: 'app-interessado-associado',
    templateUrl: './interessado-associado.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class InteressadoAssociadoComponent implements OnInit, OnChanges {

    @Input() requerimento: Requerimento;
    showForm: FormControl = new FormControl(false);

    inputConsulta = new FormControl('', [Validators.required, Validators.minLength(1)]);
    anexos: DocumentoRL[] = EnumTipoDocumentoRL.listarDocumentosRestantes();
    fluxoAnexo: TipoProcesso | null = null;

    empreendedores: Pessoa[] = [];

    constructor(
        protected injector: Injector,
        protected requerimentoService: RequerimentoService,
        protected pessoaCorporativoService: PessoaCorporativoService,
        private dialog: MatDialog,
        public responsaveisCorporativoService: ResponsaveisCorporativoService
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            this.empreendedores = this.requerimento.pessoa ? [this.requerimento.pessoa] : [];
        }
    }

    openDialogRepresentante(pessoa: any): void {
        this.dialog.open(EmpreendedorDialogComponent, {
                width: '1800px',
                maxHeight: '450px',
                data: pessoa
            }
        );
    }

}

