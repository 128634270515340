import {Injectable, Injector} from '@angular/core';
import { EmpreendimentoSubEstacao } from '../models/empreendimento-subestacao.model';
import {Observable} from "rxjs";
import {EmpreendimentoCemiterioCrematorio} from "../models/empreendimento-cemiterio-crematorio.model";
import {catchError, map} from "rxjs/operators";
import {BaseEmpreendimentoEnergeticosSubestacaoService} from "./base-empreendimento-energeticos-subestacao.service";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EmpreendimentoService extends BaseEmpreendimentoEnergeticosSubestacaoService<EmpreendimentoSubEstacao> {

    constructor(injector: Injector) {
        super(  '/api/empreendimento/subestacao', 
                injector, 
                EmpreendimentoSubEstacao.prototype,
                EmpreendimentoSubEstacao.fromJson);
    }

    getByRequerimento(idRequerimento: number): Observable<EmpreendimentoSubEstacao> {
        const url = `${this.urlResourceEmpreendimentoEnergeticosSubestacao}/requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }


    public consultarDocumento( nuCpfCnpj: string ): Observable<any> {
        return this.http.get( `${environment.URL_SCORP_API}/api/pessoas/pessoa-dto-sem-validacao-de-cadastro/cpfcnpj/${nuCpfCnpj}` );
    }

}
