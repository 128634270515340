import {BaseTabRequerimentoComponent} from './base-requerimento.component';

export class RequerimentoStep {
    component: BaseTabRequerimentoComponent;

    constructor(public index: number) {
    }

    get last(): boolean {
        return this.index === RequerimentoSteps.values.length - 1;
    }
}

export const RequerimentoSteps = {
    FORMULARIO: new RequerimentoStep(0),
    LOCALIZACAO: new RequerimentoStep(1),
    RESPONSAVEIS: new RequerimentoStep(2),
    GEO: new RequerimentoStep(3),
    ATIVIDADE: new RequerimentoStep(4),
    DOCUMENTOS: new RequerimentoStep(5),
    TAXA: new RequerimentoStep(6),
    TERMO: new RequerimentoStep(7),

    get values(): RequerimentoStep[] {
        return [this.FORMULARIO, this.LOCALIZACAO, this.RESPONSAVEIS, this.GEO, this.ATIVIDADE, this.DOCUMENTOS, this.TAXA, this.TERMO];
    },

    getByIndex(index: number): RequerimentoStep {
        return this.values.find(step => step.index === index);
    }
};
