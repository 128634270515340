import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {Ponto} from 'app/main/shared/models/hidrico-models/ponto.model';
import {PontoService} from 'app/main/shared/services/ponto.service';
import {Tamponamento} from '../../../../../shared/models/tamponamento.model';
import {BasePontoComponent} from '../base-ponto.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-objetivo-tamponamento-poco-tubular',
    templateUrl: './objetivo-tamponamento-poco-tubular.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})

export class ObjetivoTamponamentoPocoTubularComponent extends BasePontoComponent implements OnInit {

    formTamponamento: FormGroup;
    tiposPoco = Tamponamento.tiposPoco;

    constructor(
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private pontoService: PontoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildResourceForm();
        this.patchPonto();
        if (this.isModoVisualizacao) {
            this.formTamponamento.disable();
        }
    }

    private buildResourceForm(): void {
        this.formTamponamento = this.formBuilder.group({
            id: [null],
            tipoPoco: [null, [Validators.required]],
            profundidadePoco: [null, Validators.required],
            profundidadePreenchimento: [null, Validators.required],
            justificativa: [null, [Validators.required, Validators.maxLength(1000)]],
        });
    }

    private patchPonto(): void {
        if (this.ponto.tamponamento) {
            this.formTamponamento.patchValue(this.ponto.tamponamento);
        }
    }

    salvarPonto(): void {
        this.formTamponamento.markAllAsTouched();
        if (!this.formTamponamento.invalid) {
            const ponto = Ponto.fromJson(this.ponto);
            ponto.tamponamento = Tamponamento.fromJson(this.formTamponamento.value);
            ponto.formularioConcluido = true;
            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.formTamponamento.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

}


