import { Injectable, Injector, Input } from '@angular/core';
import { CrudAction } from 'app/main/shared/components/base-detail/base-detail.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnumInstanciaRequerimento } from '../menu-novo-requerimento/instancia-requerimento.enum';
import { RequerimentoLicenciamento } from '../requerimento-licenciamento/requerimento-licenciamento.model';
import { RequerimentoOutorga } from '../requerimento-outorga/requerimento-outorga.model';
import { Requerimento } from '../requerimento.model';
import { ChangeStepEvent } from './base-requerimento.component';

@Injectable({providedIn: 'root'})
export class RequerimentoHelper {
    private _requerimento = new BehaviorSubject<Requerimento>(
        new Requerimento()
    );

    private _taskId = new BehaviorSubject<string>(null);

    private _requerimentoLicenciamento = new BehaviorSubject<RequerimentoLicenciamento>(
        new RequerimentoLicenciamento()
    );

    private _requerimentoLicenciamentoVinculado = new BehaviorSubject<RequerimentoLicenciamento>(
        new RequerimentoLicenciamento()
    );

    private _edicaoAba5 = new BehaviorSubject<boolean>(null
    );

    private _requerimentoOutorga = new BehaviorSubject<RequerimentoOutorga>(
        new RequerimentoOutorga()
    );

    private _altDocResp = new BehaviorSubject<boolean>(null
    );

    private _objetivoLicenciamento = new BehaviorSubject<any>(
        null);

    private _regrasSuimis = new BehaviorSubject<string>(null
    );

    private _currentStep = new BehaviorSubject<ChangeStepEvent>({
        newTabIndex: 0,
    });

    private _currentAction = new BehaviorSubject<CrudAction>('new');

    private _idDepartamentoResponsavel = new BehaviorSubject<number>(null
    );

    get requerimento(): Requerimento {
        return this._requerimento.value;
    }

    set requerimento(requerimento: Requerimento) {
        this._requerimento.next(requerimento);
    }

    set taskId(taskId: string) {
        this._taskId.next(taskId);
    }

    onRequerimentoChange(): Observable<Requerimento> {
        return this._requerimento.asObservable();
    }

    set requerimentoLicenciamento(requerimentoLicenciamento: RequerimentoLicenciamento) {
        this._requerimentoLicenciamento.next(requerimentoLicenciamento);
    }

    get requerimentoLicenciamento(): RequerimentoLicenciamento {
        return this._requerimentoLicenciamento.value;
    }

    set requerimentoLicenciamentoVinculado(requerimentoLicenciamento: RequerimentoLicenciamento) {
        this._requerimentoLicenciamentoVinculado.next(requerimentoLicenciamento);
    }

    get requerimentoLicenciamentoVinculado(): RequerimentoLicenciamento {
        return this._requerimentoLicenciamentoVinculado.value;
    }

    get requerimentoOutorga(): RequerimentoOutorga {
        return this._requerimentoOutorga.value;
    }

    set requerimentoOutorga(requerimentoOutorga: RequerimentoOutorga) {
        this._requerimentoOutorga.next(requerimentoOutorga);
    }

    get objetivoLicenciamento(): any {
        return this._objetivoLicenciamento.value;
    }

    set objetivoLicenciamento(objetivoLicenciamento: any) {
        this._objetivoLicenciamento.next(objetivoLicenciamento);
    }

    get altDocResp(): boolean {
        return this._altDocResp.value;
    }

    set altDocResp(altDocResp: boolean) {
        this._altDocResp.next(altDocResp);
    }

    get idDepartamentoResponsavel(): number {
        return this._idDepartamentoResponsavel.value;
    }

    set idDepartamentoResponsavel(id: number) {
        this._idDepartamentoResponsavel.next(id);
    }

    set regrasSuimis(tipoRegra: string) {
        this._regrasSuimis.next(tipoRegra);
    }

    get regrasSuimis(): string {
        return this._regrasSuimis.value;
    }

    get edicaoAba5(): boolean {
        return this._edicaoAba5.value;
    }

    set edicaoAba5(edicaoAba5: boolean) {
        this._edicaoAba5.next(edicaoAba5);
    }

    onRequerimentoOutorgaChange(): Observable<RequerimentoOutorga> {
        return this._requerimentoOutorga.asObservable();
    }

    get currentStepIndex(): number {
        return this._currentStep.value.newTabIndex;
    }

    set currentStep(currentStep: ChangeStepEvent) {
        this._currentStep.next(currentStep);
    }

    onCurrentStepChange(): Observable<ChangeStepEvent> {
        return this._currentStep.asObservable();
    }

    get currentAction(): CrudAction {
        return this._currentAction.value;
    }

    set currentAction(action: CrudAction) {
        this._currentAction.next(action);
    }

    onCurrentActionChange(): Observable<CrudAction> {
        return this._currentAction.asObservable();
    }

    onRequerimentoLicenciamentoVinculadoChange(): Observable<RequerimentoLicenciamento> {
        return this._requerimentoLicenciamentoVinculado.asObservable();
    }
}

export class RequerimentoHelperAcessors {
    helper: RequerimentoHelper;

    constructor(injector: Injector) {
        this.helper = injector.get(RequerimentoHelper);
    }

    @Input()
    get requerimento(): Requerimento {
        return this.helper.requerimento;
    }

    set requerimento(requerimento: Requerimento) {
        this.helper.requerimento = requerimento;
    }

    set requerimentoTaskId(taskId: string) {
        this.helper.taskId = taskId;
    }

    get requerimentoLicenciamento(): RequerimentoLicenciamento {
        return this.helper.requerimentoLicenciamento;
    }

    set requerimentoLicenciamento(requerimentoLicenciamento: RequerimentoLicenciamento) {
        this.helper.requerimentoLicenciamento = requerimentoLicenciamento;
    }

    get requerimentoLicenciamentoVinculado(): RequerimentoLicenciamento {
        return this.helper.requerimentoLicenciamentoVinculado;
    }

    set requerimentoLicenciamentoVinculado(requerimentoLicenciamento: RequerimentoLicenciamento) {
        this.helper.requerimentoLicenciamentoVinculado = requerimentoLicenciamento;
    }

    get desabilitaEdicaoAba5(): boolean {
        return this.helper.edicaoAba5;
    }

    set desabilitaEdicaoAba5(edicaoAba5: boolean) {
        this.helper.edicaoAba5 = edicaoAba5;
    }

    get requerimentoOutorga(): RequerimentoOutorga {
        return this.helper.requerimentoOutorga;
    }

    set requerimentoOutorga(requerimentoOutorga: RequerimentoOutorga) {
        this.helper.requerimentoOutorga = requerimentoOutorga;
    }

    get altDocResp(): boolean {
       
        let isBloqueiaResponsavelImovel = this.requerimento.tipoRequerimento.isFluxoCancelar
            || this.requerimento.tipoRequerimento.isFluxoReabilitar
            || this.requerimento.tipoRequerimento.isFluxoAlterar;
        this.altDocResp = true;
        //Se a ação no cadastro dos requerimentos for igual a 'detail' (Visualização) automaticamente nenhuma aba pode ser
        //alterada.
        if(this.currentAction == 'detail'){
            this.altDocResp = false;
        } else if (this.requerimento.idTipoRequerimentoTifLas === 2) {
            if ((this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS
                    || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO
                    || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA)
                    && this.requerimento.numeroProtocoloVinculado && !isBloqueiaResponsavelImovel) {
                        this.altDocResp = true;
            } else {
                this.altDocResp = false;
            }

        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO
            && !this.requerimento.numeroProtocoloVinculado) {
            this.altDocResp = true;
        } else if((this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS
                || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO
                || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA)
            && this.requerimento.numeroProtocoloVinculado &&  isBloqueiaResponsavelImovel){
            this.altDocResp = false;
        } else if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_ADESAO && this.requerimento.numeroProtocoloVinculado) {
            this.lacAtoDigital()
        }
        return this.helper.altDocResp;
    }

    get regrasSuimis(): string {
        return this.helper.regrasSuimis;
    }

    set regrasSuimis(tipoRegra: string) {
        this.helper.regrasSuimis = tipoRegra;
    }

    private lacAtoDigital() {
        if (this.requerimento.tipoRequerimento.id === 73 ||
            this.requerimento.tipoRequerimento.id === 4 ||
            this.requerimento.tipoRequerimento.id === 32 ||
            this.requerimento.tipoRequerimento.id === 6) {
            this.altDocResp = false
        }
    }

    set altDocResp(altDocResp: boolean) {
        this.helper.altDocResp = altDocResp;
    }

    get idDepartamentoResponsavel(): number {
        return this.helper.idDepartamentoResponsavel;
    }

    set idDepartamentoResponsavel(id: number) {
        this.helper.idDepartamentoResponsavel = id;
    }

    get objetivoLicenciamento(): any {
        return this.helper.objetivoLicenciamento;
    }

    set objetivoLicenciamento(objetivoLicenciamento: any) {
        this.helper.objetivoLicenciamento = objetivoLicenciamento;
    }

    get currentAction(): CrudAction {
        return this.helper.currentAction;
    }

    get isSomenteVisualizacao(): boolean {
        return this.currentAction === 'detail' ||  (this.requerimento && this.requerimento.situacaoProcesso !== 'CHECKLIST_REPROVADO')
            && (this.requerimento && this.requerimento.situacaoProcesso !== 'EM_CORRECAO_CADASTRO')
            && this.requerimento.situacaoProcesso !== 'EM_CORRECAO'
            && (this.requerimento && this.requerimento.situacaoProcesso !== 'EM_CADASTRO');
    }

    /***
     * Bloqueia a edição dos campos conforme regras na aba específica.
     */
    isSomenteVisualizacaoEspecifico(): boolean {
        return false;
    }

    get currentStepIndex(): number {
        return this.helper.currentStepIndex;
    }

    get isRequerimentoRevisao(): boolean {
        return this.requerimento.situacaoProcesso === 'EM_REVISAO' || this.requerimento.situacaoProcesso === 'EM_CORRECAO';
    }

    get isRequerimentoNatuLegado(): boolean {
        return this.requerimento.processo.origem === 'LEGADO';
    }

    get isFluxoNatuDigital(): boolean {
        return this.requerimento.processo.origem !== 'LEGADO'
            && (this.requerimento.tipoRequerimento.tipoFluxo === 'ALTERAR'
                || this.requerimento.tipoRequerimento.tipoFluxo === 'RENOVAR'
                || this.requerimento.tipoRequerimento.tipoFluxo === 'TRANSFERIR'
                || this.requerimento.tipoRequerimento.tipoFluxo === 'CANCELAR'
                || this.requerimento.tipoRequerimento.tipoFluxo === 'REABILITAR');
    }

    get isRequerimentoAguardandoRevisaoOuEmRevisao(): boolean {
        return this.requerimento.situacaoProcesso === 'AGUARDANDO_REVOSAO'
            || this.requerimento.situacaoProcesso === 'EM_REVISAO'
            || this.requerimento.situacaoProcesso === 'AGUARDANDO_CORRECAO'
            || this.requerimento.situacaoProcesso === 'EM_CORRECAO';
    }

}
