import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmacaoDialogComponent} from './confirmacao-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmacaoDialogService {

    constructor(private dialog: MatDialog) {
    }

    openConfimDialog(acao: string, tipo: string, nome: string): any {
        return this.dialog.open(ConfirmacaoDialogComponent, {
            width: '390px',
            panelClass: 'confirm-dialog-container',
            disableClose: true,
            data: {
                message: 'Tem certeza que deseja ' + acao + ' o(a) ' + tipo + ' ' + nome + '?'
            }
        });
    }
}
