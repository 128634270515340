import {Component, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CKEditorComponent} from 'ckeditor4-angular';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-document-template-dialog',
    templateUrl: './document-template-dialog.component.html',
    styleUrls: ['./document-template-dialog.component.scss']
})
export class DocumentTemplateDialogComponent implements OnInit {

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private matDialogRef: MatDialogRef<DocumentTemplateDialogComponent, DocumentTemplateDialogResult>) {
    }

    @ViewChild(CKEditorComponent, {static: true})
    editor: CKEditorComponent;
    content = '';
    title = null;

    public static openDocumentDialog(matDialog: MatDialog, content: string, title: string,
                                     readOnly: boolean = false): MatDialogRef<DocumentTemplateDialogComponent, DocumentTemplateDialogResult> {
        return matDialog.open(DocumentTemplateDialogComponent, {
            data: {
                content: content,
                readOnly: readOnly,
                title: title
            },
            width: '80%',
            height: '80%'
        });
    }

    ngOnInit(): void {
        this.matDialogRef.updateSize('80%', '80%');

        if (this.editor) {

            this.editor.ready.subscribe(e => {
                e.editor.setReadOnly(this.data.readOnly);
            });
        }

        if (this.data && this.data.content) {
            this.content = this.data.content;
        }

        if (this.data && this.data.title) {
            this.title = this.data.title;
        }
    }

    fecharDialog(): void {
        this.matDialogRef.close({save: false});
    }

    getContentAndClose(): void {
        // merge header with ckeditor content
        // const content = templateHeader + this.editor.data;
        this.matDialogRef.close({save: true, content: this.editor.data});
    }

}

export interface DocumentTemplateDialogResult {
    save: boolean;
    content?: any;
}
