import { Injectable, Injector } from '@angular/core';
import { TemplateDTO } from './template-dto.model';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseEnum } from 'app/main/shared/enums/base.enum';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends BaseService<TemplateDTO> {
    constructor(protected injector: Injector) {
        super('/api/templates', injector, TemplateDTO.prototype, TemplateDTO.fromJson);
    }

    getNomesTemplates(): Observable<BaseEnum[]> {
        return this.http.get(`${this.urlResource}/nomes-template`).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    listAllTemplates(): Observable<any[]> {
        return this.http.get(`${this.urlResource}`).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

}
