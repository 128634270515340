import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Processo} from '../../../../shared/models/processo.model';
import {ProcessoService} from '../../../../shared/services/processo.service';
import {TipoRequerimentoService} from '../../../tipos-requerimento/tipo-requerimento.service';
import {TipoRequerimento} from '../../../tipos-requerimento/tipo-requerimento.model';
import {DialogAjudaRequerimentoOutorgaComponent} from '../../requerimento-outorga/dialog-ajuda-requerimento-outorga/dialog-ajuda-requerimento-outorga.component';
import {RequerimentoService} from '../../requerimento.service';
import {EmissaoTituloService} from '../../../tarefas/shared/service/emissao-titulo.service';
import {TitulacaoService} from '../../../titulacoes/titulacao.service';
import {ParametroObjetivoService} from '../../../../shared/services/licenciamento-config-service/parametro-objetivo.service';
import {TituloParametrizacaoObjetivoService} from '../../../../shared/services/licenciamento-config-service/titulo-parametrizacao-objetivos.service';
import {ObjetivoParametrizacaoObjetivoService} from '../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service';
import { ObjetivoParametrizacaoObjetivo } from 'app/main/shared/models/licenciamento-config-models/objetivo-parametrizacao-objetivo.model';
import { result } from 'lodash';

@Component({
    selector: 'app-dialog-requerimento-existente',
    templateUrl: './dialog-requerimento-existente.component.html',
    styleUrls: ['dialog-requerimento-existente.component.scss'],
})
export class DialogRequerimentoExistenteComponent implements OnInit {

    possuiProcotocolo?: boolean | null = null;
    avisoPossuiProcotocolo?: boolean | null = null;
    formProtocolo: FormControl;
    formTipoRequerimento: FormControl;
    tiposRequerimento: any;
    processo: Processo;
    mouseOverTooltipTitulo: string = null;
    emissao: any;
    titulos: Array<any> = [];
    parametros: Array<any> = [];
    processoFlorestalFluxoAndamento: boolean = false;
    parametroObjetivoNovo: any;


    constructor(
        private dialogRef: MatDialogRef<DialogRequerimentoExistenteComponent, DialogRequerimentoExistenteResult>,
        private snackBarService: SnackBarService,
        private processoService: ProcessoService,
        private tipoRequerimentoService: TipoRequerimentoService,
        private emissaoTituloService: EmissaoTituloService,
        public requerimentoService: RequerimentoService,
        private licenciamentoTitulacaoService: TitulacaoService,
        private parametroObjetivoService: ParametroObjetivoService,
        private tituloParametrizacaoObjetivoService: TituloParametrizacaoObjetivoService,
        private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.formProtocolo = new FormControl(null, Validators.required);
        this.formTipoRequerimento = new FormControl(null, Validators.required);
    }

    ngOnInit(): void {
        this.getTiposDeRequeriemnto();
    }

    getTiposDeRequeriemnto() {
        this.tipoRequerimentoService.getAllByTipoAtuacao('ANDAMENTO').subscribe(result => this.tiposRequerimento = result,
            e => this.snackBarService.showError('Erro ao listar os tipos requerimento', e));
    }

    async concluir(): Promise<void> {
        if (this.possuiProcotocolo != null) {
            if (this.possuiProcotocolo) {
                this.formTipoRequerimento.markAllAsTouched();
                if (!this.processo) {
                    this.snackBarService.showAlert('Busque o processo pelo número de protocolo');
                } else if (!this.formTipoRequerimento.value) {
                    this.snackBarService.showAlert('Selecione o tipo requerimento desejado');
                } else {
                    if (this.data && this.data.tipo === 'FLORESTAL') {

                        let objetivosParametrizacaoObjetivo;
                        await this.objetivoParametrizacaoObjetivoService.getByIdTipoRequerimento(this.formTipoRequerimento.value.id).toPromise().then(paramObjs => {
                            objetivosParametrizacaoObjetivo = paramObjs
                        });

                        if (objetivosParametrizacaoObjetivo && objetivosParametrizacaoObjetivo.length > 1) {
                            this.snackBarService.showAlert('Existe mais de uma configuração para mesmo tipo de requerimento selecionado.');
                        }
                        this.parametroObjetivoNovo = this.parametros.find(param => param.idTipoProcesso === this.data.tpProcesso.id && param.idTipoAtuacao == 1);
                        //this.parametroObjetivoAndamento = this.parametros.find(param => param.idTipoProcesso === this.data.tpProcesso.id && param.idTipoAtuacao == 2);
                        this.dialogRef.close({
                            possuiProtocoloExistente: true,
                            numeroProtocolo: this.formProtocolo.value,
                            tipoRequerimento: this.formTipoRequerimento.value,
                            idParametroObjetivo: !this.processoFlorestalFluxoAndamento ? this.parametroObjetivoNovo.id : objetivosParametrizacaoObjetivo[0].idParametroObjetivo
                        });
                    } else {
                        this.dialogRef.close({
                            possuiProtocoloExistente: true,
                            numeroProtocolo: this.formProtocolo.value.replace('/', ''),
                            tipoRequerimento: this.formTipoRequerimento.value,

                        });
                    }
                }
            } else {
                this.dialogRef.close({
                    possuiProtocoloExistente: false
                });
            }
        } else {
            this.snackBarService.showAlert('Selecione se possui um protocolo de outorga existente');
        }
    }

    pesquisarProcesso(): void {
        this.formProtocolo.markAllAsTouched();
        
        if (this.formProtocolo.valid) {
            this.processoService.findByNumero(this.formProtocolo.value.replace('/', '')).subscribe(async result => {
                if (result.origem === 'SIGA') {
                    if (this.data.tipo === 'OUTORGA') {
                        await this.requerimentoService.getRequerimento(result.numero).toPromise().then(async resposta => {
                            let processoOutorga = !this.data || this.data.tipo !== 'OUTORGA' || resposta.some(req => req.codigoTipoProcesso == 'OUTORGA');
                            if (!processoOutorga) {
                                this.snackBarService.showError('O processo informado não é de Outorga de Recursos Hídricos')
                            } else if (resposta.some(req => req.codigoTipoProcesso == 'OUTORGA') && !resposta.some(req => req.situacaoProcesso === 'CONCLUIDO' && req.codigoTipoProcesso == 'OUTORGA')) {
                                this.snackBarService.showError('O processo não encontra-se concluído')
                            } else if (resposta) {
                                let emissoes = await this.emissaoTituloService.buscaEmissoesPorIdRequerimentoDocumentoProcesso(resposta[0].id).toPromise();
                                const tipos = this.tiposRequerimento.filter((element) => element.tipoProcesso.tipo === 'OUTORGA');
                                this.tiposRequerimento  = [];
                                this.tiposRequerimento.push(...tipos);
                                if (emissoes) {
                                    emissoes.forEach(emissao => {
                                        //Validar este trecho para situação do título CANCELADO implementado em outro momento
                                        if (emissao.situacaoTitulo === 'CANCELADO') {
                                            this.tiposRequerimento.splice(this.tiposRequerimento.indexOf(this.tiposRequerimento.find(tr => tr.id === 32)), 1)
                                            this.tiposRequerimento = this.tiposRequerimento.slice()
                                        }
                                    });
                                    this.processo = result;
                                } else {
                                    this.snackBarService.showError('Não foi possível encontrar a titulação informada')
                                }
                            }
                        });
                    } else {
                        await this.requerimentoService.getRequerimento(result.numero).toPromise().then(async resposta => {
                            let processoFlorestal = !this.data || this.data.tipo !== 'FLORESTAL' || resposta.some(req => req.codigoTipoProcesso === 'LICENCIAMENTO');
                            if (!processoFlorestal) {
                                this.snackBarService.showError('O processo informado não é de Licenciamento de Recursos Florestais');
                            } else {
                                await this.validarParametrizacaoLicenciamento(resposta, result);
                            }
                        });
                    }
                } else {
                    // Para processos legados..
                    const tipos = this.tiposRequerimento.filter((element) => element.tipoProcesso.tipo === 'OUTORGA');
                    this.tiposRequerimento  = [];
                    this.tiposRequerimento.push(...tipos);
                    this.processo = result;
                }
            }, e => this.snackBarService.showError('Erro ao pesquisar o processo', e));
        }
    }


    // Funcionalidade Evolutiva #9797
    async validarParametrizacaoLicenciamento(requerimento: any, processo: any): Promise<any> {
        await this.getListaTitulos();
        await this.buscaParametroComTiposRequerimento();

        /**
         * Pegando os titulares do requerimento, porém, apenas os titulos referentes a origem da titulação 'REQUERIMENTO', pois é descartada a origem 
         * emissão para carregamento dos objetivos das configurações de andamento
         */
        const titulacoesReq: any[] = await this.requerimentoService.getTitulacoes(requerimento[0]).toPromise()
            .then(result => {
                const found = result.find(tit => tit.enumOrigemTitulacao === 'REQUERIMENTO');
                return found ? [found] : []; // Retorna um array com o elemento encontrado ou um array vazio
            });

        if (titulacoesReq) {
            let titulosParametros = [];
            let arrayObjetivos;
            let tpBuscaParam;

            if (titulacoesReq) {
                for (let param of this.parametros) {
                    titulosParametros.push(...await this.pegaParametroObjetivo(param));
                }

                for (const titulo of titulosParametros) {
                    if (titulo) {
                        let tituloRecursosFlorestais = titulo.find(tit => tit.titulo.id === titulacoesReq[0].titulacao.id && tit.vinculo.isEmitido
                            && tit.parametro.idTipoAtuacao === 2);

                        if (tituloRecursosFlorestais) {
                            tpBuscaParam = tituloRecursosFlorestais;
                            this.processoFlorestalFluxoAndamento = true;
                        }
                    }

                    if (tpBuscaParam) {
                        arrayObjetivos = await this.pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam);
                        this.tiposRequerimento = arrayObjetivos;
                        this.processo = processo;
                        break;
                    }
                }
                if (!arrayObjetivos) {
                    this.snackBarService.showError('Recursos Florestais sem conifgurações válidas (Parametrização)');
                    this.processo = undefined;
                }
            }
            
        } else {
            this.snackBarService.showError('Não foi possível encontrar a titulação vinculada ao Requerimento');
            this.processo = undefined;
        }
    }


    private async pegaParametroObjetivo(param): Promise<any> {
        let titulosParametros = [];

        let titulos = await this.tituloParametrizacaoObjetivoService.getByParametrizacaoObjetivo(param.id).toPromise()

        titulosParametros.push(titulos.map(titulo => ({
            titulo: this.titulos.find(t => t.id === titulo.idTituloRequerido),
            vinculo: titulo,
            parametro: param,
        })));

        return titulosParametros;
    }

    private async pegaOsTiposDeRequerimentoPeloParametro(tpBuscaParam): Promise<any> {
        let objs = await this.objetivoParametrizacaoObjetivoService.getByParametrizacaoObjetivo(tpBuscaParam.parametro.id).toPromise();
        return objs.map(obj => ({
            id: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento).id,
            descricao: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento).descricao,
            tipoAutuacao: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento).tipoAutuacao,
            tipoFluxo: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento).tipoFluxo,
        }));
    }

    private async getListaTitulos(): Promise<void> {
        this.titulos = await this.licenciamentoTitulacaoService.getAll().toPromise();
    }

    async buscaParametroComTiposRequerimento(): Promise<void> {
        this.parametros = await this.parametroObjetivoService.getAll().toPromise();
    }

    continuar(): void {
        this.avisoPossuiProcotocolo = false;
    }

    radioChange(event: any) {
        this.avisoPossuiProcotocolo = event.value;
    }

    numeroProcessoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.formProtocolo.patchValue(`${initial}/${year}`);
            }
        }
    }

    mostrarTooltipPossuiTitulacao(param: string) {
        this.mouseOverTooltipTitulo = param;
    }

    openDialog(): void {
        this.dialog.open(DialogAjudaRequerimentoOutorgaComponent, {
            data: this.data.tipo,
            width: '90%',
        });
    }

}

export interface DialogRequerimentoExistenteResult {
    possuiProtocoloExistente: boolean;
    numeroProtocolo?: number | null;
    tipoRequerimento?: TipoRequerimento | null;
    idParametroObjetivo?: number | null;
    
}