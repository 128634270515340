import {BaseModel} from './base.model';

export class Signatario extends BaseModel {
    constructor(
        public id?: number,
        public dataAtualizacao?: Date,
        public nome?: string,
        public setores?: string,
        public profissoes?: string,
        public cpf?: string,
        public cargo?: string,
        public matricula?: string
    ) {
        super();
    }

    public get cargoESetor(): string {
        return `${this.cargo} ${this.setores}`;
    }

    static fromJson(json: any): Signatario {
        if (json === undefined || json === null) {
            return null;
        }
        return new Signatario(
            json.id,
            json.dataAtualizacao,
            json.nome,
            json.setores,
            json.profissoes,
            json.cpf,
            json.cargo,
            json.matricula);
    }
}
