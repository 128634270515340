import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import { CrematorioMonitoramentoDTO } from '../models/crematorio-monitoramento-dto.model';
import { BaseLicenciamentoResiduosCemitCrematorio } from './base-licenciamento-residuos-cemit-crematorio.service';


@Injectable({
    providedIn: "root"
})
export class CrematorioMonitoramentoService extends BaseLicenciamentoResiduosCemitCrematorio<CrematorioMonitoramentoDTO> {

    constructor(protected injector: Injector) {
        super(
            "/api/crematorio-monitoramento",
            injector,
            CrematorioMonitoramentoDTO.prototype,
            CrematorioMonitoramentoDTO.fromJson
        );
    }

    getByIdCrematorio(idCrematorio: number): Observable<Array<CrematorioMonitoramentoDTO>> {
        const url = `${this.urlResource}/crematorio/${idCrematorio}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
