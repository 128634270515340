import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Requerimento} from '../requerimento.model';
import {SnackBarService} from '../../../shared/snack-bar/snack-bar.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import { MatDialog } from '@angular/material';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ItemTermoReferenciaRequerimentoChecklistService } from 'app/main/shared/services/item-termo-referencia-requerimento-checklist.service';
import { TermoReferenciaService } from '../../termos-referencia/termo-referencia.service';
import { DocumentoRequerimentoService } from '../../documentos-requerimentos/documento-requerimento.service';
import { RequerimentoOutorgaService } from '../requerimento-outorga/requerimento-outorga.service';
import { RequerimentoLicenciamentoService } from '../requerimento-licenciamento/requerimento-licenciamento.service';
import { RequerimentoService } from '../requerimento.service';
import { LoadingService } from 'app/main/shared/components/loading/loading.service';
import { TermoReferenciaRequerimento } from 'app/main/shared/models/termo-referencia-requerimento.model';
import { DocumentoRequerimento, TipoDocumentoRequerimento } from '../../documentos-requerimentos/documento-requerimento.model';
import { HistoricoConferenciaChecklist, ItemTermoReferenciaRequerimentoChecklist, ItemTermoReferenciaRequerimentoChecklistFormGroup } from 'app/main/shared/models/item-termo-referencia-requerimento-checklist.model';
import { RequerimentoOutorga } from '../requerimento-outorga/requerimento-outorga.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { GerenciarRequerimentoProcessoService } from '../gerenciar-requerimento-processo.service';
import { ItemTermoReferencia } from '../../itens-termos-referencia/item-termo-referencia.model';
import { ItemTermoReferenciaRequerimento } from 'app/main/shared/models/item-termo-referencia-requerimento.model';
import { ItemTermoReferenciaInformacoesAdicionaisDialogComponent } from '../../itens-termos-referencia/item-termo-referencia-informacoes-adicionais-dialog/item-termo-referencia-informacoes-adicionais-dialog.component';
import { DocumentoRequerimentoDialogComponent } from '../../documentos-requerimentos/documento-requerimento-dialog/documento-requerimento-dialog.component';
import { catchError, map } from 'rxjs/operators';
import { BaseEnum } from 'app/main/shared/enums/base.enum';
import { ItemTermoReferenciaHistoricoChecklistDialogComponent } from '../../itens-termos-referencia/item-termo-referencia-historico-checklist-dialog/item-termo-referencia-historico-checklist-dialog.component';

@Component({
    selector: 'app-requerimento-historico-termo-referencia',
    templateUrl: './requerimento-historico-termo-referencia.component.html',
    styleUrls: ['./requerimento-historico-termo-referencia.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoHistoricoTermoReferenciaComponent implements OnInit {

    requerimento: Requerimento;

    form: FormGroup;
    termosReferencia: TermoReferenciaRequerimento[];
    documentos: DocumentoRequerimento[] = [];
    checklists: ItemTermoReferenciaRequerimentoChecklist[] = null;
    requerimentoOutorga: RequerimentoOutorga = null;
    resource: any = null;
    requerimentoId = 0;
    formCreated = false;
    data: Subject<any[]> = new Subject<any[]>();
    checklist: any = {
        DATA_ATIVIDADE: '',
        SITUACAO: '',
        NAO_AVALIADO: 0,
        ATENDIDO: 0,
        NAO_ATENDE: 0,
        DISPENSADO: 0
    };
    totals;

    constructor(
        public formBuilder: FormBuilder,
        public gerenciarRequerimentoProcessoService: GerenciarRequerimentoProcessoService,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected service: ItemTermoReferenciaRequerimentoChecklistService,
        protected termoReferenciaService: TermoReferenciaService,
        protected documentoRequerimentoService: DocumentoRequerimentoService,
        protected itemTermoReferenciaRequerimentoChecklistService: ItemTermoReferenciaRequerimentoChecklistService,
        protected requerimentoOutorgaService: RequerimentoOutorgaService,
        protected requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        protected requerimentoService: RequerimentoService,
        protected injector: Injector,
        private loadingService: LoadingService,
        private activatedRoute: ActivatedRoute,
    ) {

    }

    ngOnInit(): void {
        this.loadRequerimento();
    }

    async loadRequerimento(): Promise<void> {
        this.activatedRoute.params.subscribe(p => {
            this.requerimentoService.getById(p['id']).subscribe(req => {
                this.requerimento = req;
                console.log(this.requerimento)
                if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
                    this.requerimentoOutorgaService.getByRequerimentoId(this.requerimento.id).subscribe(outorga => {
                        this.termoReferenciaService.getByRequerimentoOutorgaId(outorga.id).subscribe(termosReferencia => {
                            this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
                            this.listarItens();
                        });
                    });
                } else if (this.requerimento.tipoProcesso.tipo === 'LICENCIAMENTO') {
                    this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).subscribe(licenciamento => {
                        this.termoReferenciaService.getByRequerimentoLicenciamentoId(licenciamento.id).subscribe(termosReferencia => {
                            this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
                            this.listarItens();
                        });
                    });
                }
                this.buildResourcesForm();
            });
        });
    }

    buildResourcesForm(): void {
        this.form = this.formBuilder.group({
            termos: this.formBuilder.array([
                this.formBuilder.group({
                    tipos: this.formBuilder.array([
                        this.formBuilder.group({
                            avaliacoes: this.formBuilder.array([])
                        })
                    ])
                })
            ])
        });
    }

    corFromTermoReferencia(indexTermoReferencia: number): string {
        const itens = this.form.value['termos'][indexTermoReferencia]['tipos'].flatMap(tipo => tipo['avaliacoes']);
        for (const item of itens) {
            if (item.status === 'NAO_ATENDE' || item.status === 'NAO_AVALIADO') {
                return 'red';
            }
        }
        return 'green';
    }

    corFromTipoItemTermoReferencia(indexTermoReferencia: number, indexTipoItemTermoReferencia: number): string {
        const itens = this.form.value['termos'][indexTermoReferencia]['tipos'][indexTipoItemTermoReferencia]['avaliacoes'];
        for (const item of itens) {
            if (item.status === 'NAO_ATENDE' || item.status === 'NAO_AVALIADO') {
                return 'lightcoral';
            }
        }
        return '#7cb342';
    }

    descricaoFromTipoItemTermoReferencia(tipoItemTermoReferencia: string): string {
        return ItemTermoReferencia.tipos.find(t => t.valor === tipoItemTermoReferencia).descricao;
    }

    documentosByItem(item: ItemTermoReferenciaRequerimento): DocumentoRequerimento[] {
        return this.documentos.filter(d => d.tipo === 'ITEM_TERMO_REFERENCIA' && d.itemTermoReferenciaRequerimento.id === item.id);
    }

    listarItens(): void {
        combineLatest(this.listarDocumentos(), this.listarChecklists()).pipe(
            map(([documentos, checklists]) => ({documentos, checklists}))
        ).subscribe(pair => {
            this.documentos = pair.documentos;
            this.checklists = pair.checklists;
            this.createFormsAvaliacoes();
            this.setarUltimaDataChecklist();
            this.setarUltimaSituacaoChecklist();
        });
    }

    listarDocumentos(): Observable<DocumentoRequerimento[]> {
        return this.documentoRequerimentoService
            .getByRequerimento(this.requerimento, [TipoDocumentoRequerimento.ITEM_TERMO_REFERENCIA]);
    }

    listarChecklists(): Observable<ItemTermoReferenciaRequerimentoChecklist[]> {
        return this.service
            .getAllByRequerimento(this.requerimento.id);
    }

    setarUltimaSituacaoChecklist(): void {
        if (!this.checklists || this.checklists.length === 0 || this.checklists.find(e => e.statusAnalise === 'NAO_AVALIADO')) {
            this.checklist['SITUACAO'] = 'Aguardando checklist';
        } else if (this.checklists.find(e => e.statusAnalise === 'NAO_ATENDE')) {
            this.checklist['SITUACAO'] = 'Checklist reprovado';
        } else {
            this.checklist['SITUACAO'] = 'Checklist validado';
        }
    }

    setarUltimaDataChecklist(): void {
        if (!this.checklists || this.checklists.length === 0) {
            return;
        }
        let checklistMaisRecente: ItemTermoReferenciaRequerimentoChecklist = null;
        this.termosReferencia.forEach(tr => {
            tr.itens.forEach(itr => {
                const aux = this.checklists.filter(e => e.itemTermoReferenciaRequerimento.id === itr.id)
                    .sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
                    .pop();
                if (aux && (!checklistMaisRecente || (checklistMaisRecente &&
                    Date.parse(aux.dataInicio as unknown as string) > Date.parse(checklistMaisRecente.dataInicio as unknown as string)))) {
                    checklistMaisRecente = aux;
                }
            });
        });
        this.checklist['DATA_ATIVIDADE'] = checklistMaisRecente.dataInicio;
    }

    limparResumo(): void {
        this.checklist['NAO_AVALIADO'] = 0;
        this.checklist['ATENDIDO'] = 0;
        this.checklist['NAO_ATENDE'] = 0;
        this.checklist['DISPENSADO'] = 0;
    }

    createFormsAvaliacoes(): void {
        this.limparResumo();
        const termosForm = new FormArray([]);
        const totals = new Array(this.termosReferencia.length);
        // termos de referencia
        this.termosReferencia.forEach((termo, iz) => {
            const fgTermo = new FormGroup({
                tipos: new FormArray([])
            });
            totals[iz] = new Array(termo.itensPorTipo.length);
            termosForm.push(fgTermo);
            if (termo.itensPorTipo) {
                // tipos
                termo.itensPorTipo.forEach((tipo, ix) => {
                    totals[iz][ix] = 0;
                    const fgTipo = new FormGroup({
                        avaliacoes: new FormArray([])
                    });
                    // tiposForm.push(fgTipo);
                    (fgTermo.controls.tipos as FormArray).push(fgTipo);
                    if (tipo.itens) {
                        const avaliacoes = new FormArray([]);
                        // itens
                        tipo.itens.forEach((item, iy) => {
                            const lastChecklistItem = this.checklists
                                .filter(e =>
                                    e.itemTermoReferenciaRequerimento.id === item.id
                                    && e.rascunho === true)
                                .slice(-1)
                                .pop();
                            const fgItem = new FormGroup({
                                id: new FormControl(null),
                                itemTermoReferenciaRequerimento: new FormControl(item),
                                status: new FormControl(null),
                                comentario: new FormControl(null),
                                modificado: new FormControl(false)
                            });
                            fgItem.controls.id.patchValue(lastChecklistItem ? lastChecklistItem.id : null);
                            fgItem.controls.status.patchValue(lastChecklistItem && lastChecklistItem.statusAnalise ? lastChecklistItem.statusAnalise : 'NAO_AVALIADO');
                            fgItem.controls.comentario.patchValue(lastChecklistItem && lastChecklistItem.comentario ? lastChecklistItem.comentario : null);


                            if ((lastChecklistItem && lastChecklistItem.statusAnalise ? lastChecklistItem.statusAnalise : 'NAO_AVALIADO') === 'NAO_ATENDE') {
                                // Caso não tenha valor no comentário, mostrar que é obrigatório.
                                if (!fgItem.controls.comentario.value) {
                                    fgItem.controls.comentario.setErrors({required: true});
                                    fgItem.controls.comentario.markAsTouched();
                                }
                                fgItem.controls.comentario.setValidators([Validators.required]);
                            } else {
                                fgItem.controls.comentario.setValidators(null);
                                fgItem.controls.comentario.setErrors(null);
                            }

                            if (lastChecklistItem) {
                                switch (lastChecklistItem.statusAnalise) {
                                    case 'NAO_AVALIADO':
                                        item['color'] = ColorStatusAvaliacao.NAO_AVALIADO.toString();
                                        item['isValid'] = false;
                                        break;
                                    case 'ATENDIDO':
                                        item['color'] = ColorStatusAvaliacao.ATENDIDO.toString();
                                        item['isValid'] = true;
                                        totals[iz][ix] += 1;
                                        break;
                                    case 'NAO_ATENDE':
                                        item['color'] = ColorStatusAvaliacao.NAO_ATENDE.toString();
                                        item['isValid'] = !!(lastChecklistItem.comentario);
                                        break;
                                    case 'DISPENSADO':
                                        item['color'] = ColorStatusAvaliacao.DISPENSADO.toString();
                                        item['isValid'] = true;
                                        break;
                                }
                            } else {
                                item['color'] = ColorStatusAvaliacao.NAO_AVALIADO.toString();
                                item['isValid'] = false;
                            }

                            avaliacoes.push(fgItem);
                            this.addResumeItem(lastChecklistItem);
                        });
                        const fgAvaliacoes = fgTipo as FormGroup;
                        fgAvaliacoes.setControl('avaliacoes', avaliacoes);
                    }
                });
            }
        });

        this.form.setControl('termos', termosForm);
        this.formCreated = true;
        this.totals = totals;
    }

    addResumeItem(itemChecklist: ItemTermoReferenciaRequerimentoChecklist): void {
        if (itemChecklist && itemChecklist.statusAnalise) {
            const currentValue = this.checklist[itemChecklist.statusAnalise];
            this.checklist[itemChecklist.statusAnalise] = currentValue + 1;
        } else {
            // Padrão é não avaliado quando não existir.
            const currentValue = this.checklist['NAO_AVALIADO'];
            this.checklist['NAO_AVALIADO'] = currentValue + 1;
        }
    }

    informacoesAdicionais(itemTermoReferenciaRequerimento: ItemTermoReferenciaRequerimento): void {
        this.dialog.open(
            ItemTermoReferenciaInformacoesAdicionaisDialogComponent,
            {width: '600px', data: itemTermoReferenciaRequerimento.itemTermoReferencia}
        );
    }

    documentoDialog(documentoRequerimento: DocumentoRequerimento): void {
        const dialogRef = this.dialog.open(
            DocumentoRequerimentoDialogComponent,
            {
                width: '600px',
                data: documentoRequerimento
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.listarDocumentos().subscribe(documentos => (this.documentos = documentos));
            }
        });
    }

    visualizarDocumento(documentoRequerimento: DocumentoRequerimento): void {
        window.open(`${this.documentoRequerimentoService.urlResource}/${documentoRequerimento.id}/visualizar`);
    }

    downloadDocumento(documentoRequerimento: DocumentoRequerimento): void {
        window.open(`${this.documentoRequerimentoService.urlResource}/${documentoRequerimento.id}/download`);
    }

    private temStatus(status: string): boolean {
        let temOStatus = false;
        Object.keys(this.form.controls).forEach(keyTermos => {
            // termos
            const termos = this.form.controls[keyTermos].value as FormArray;
            Object.keys(termos).forEach(keyTipos => {
                // tipos
                const tipos = termos[keyTipos].tipos as FormArray;
                Object.keys(tipos).forEach(keyChecklists => {
                    // checklists
                    const checklists = tipos[keyChecklists].avaliacoes as FormArray;
                    Object.keys(checklists).forEach((keyChecklist, index) => {
                        const fgChecklist = checklists[keyChecklist] as ItemTermoReferenciaRequerimentoChecklistFormGroup;
                        if (fgChecklist && fgChecklist.status === status) {
                            temOStatus = true;
                        }
                    });
                });
            });
        });
        return temOStatus;
    }

    private allValid(): boolean {
        let valid = true;
        Object.keys(this.form.controls).forEach(keyTermos => {
            // termos
            const termos = this.form.controls[keyTermos].value as FormArray;
            Object.keys(termos).forEach(keyTipos => {
                // tipos
                const tipos = termos[keyTipos].tipos as FormArray;
                Object.keys(tipos).forEach(keyChecklists => {
                    // checklists
                    const checklists = tipos[keyChecklists].avaliacoes as FormArray;
                    Object.keys(checklists).forEach((keyChecklist, index) => {
                        const fgChecklist = checklists[keyChecklist] as ItemTermoReferenciaRequerimentoChecklistFormGroup;
                        if (fgChecklist && fgChecklist.status === 'NAO_AVALIADO' && !fgChecklist.comentario) {
                            valid = false;
                        }
                    });
                });
            });
        });
        return valid;
    }

    /**
     * Valida e constroi o array de retorno para ser utilizado nas requisições, em caso de rascunho = true, não há validação,
     * somente é construído o array, em caso de rascunho = false, as validações são aplicadas, e caso alguma não seja respeitada,
     * mostra mensagem tratada e retorna nulo como indicativo de que algo não está válido.
     * @param rascunho: boolean
     */
     validarEConstruirArray(rascunho = true): ItemTermoReferenciaRequerimentoChecklist[] {
        const arrPost: ItemTermoReferenciaRequerimentoChecklist[] = [];
        const formControls = this.form.controls;
        const snack = this.snackBarService;
        const allValidated = Object.keys(formControls).every(function (keyTermos): boolean {
            // termos
            const termos = formControls[keyTermos].value as FormArray;
            return Object.keys(termos).every(function (keyTipos): boolean {
                // tipos
                const tipos = termos[keyTipos].tipos as FormArray;
                return Object.keys(tipos).every(function (keyChecklists): boolean {
                    // checklists
                    const checklists = tipos[keyChecklists].avaliacoes as FormArray;
                    return Object.keys(checklists).every(function (keyChecklist, index): boolean {
                        const fgChecklist = checklists[keyChecklist] as ItemTermoReferenciaRequerimentoChecklistFormGroup;
                        const checklist = new ItemTermoReferenciaRequerimentoChecklist(
                            fgChecklist.id,
                            fgChecklist.status,
                            fgChecklist.comentario,
                            rascunho,
                            null,
                            new ItemTermoReferenciaRequerimento(fgChecklist.itemTermoReferenciaRequerimento.id)
                        );
                        if (!rascunho && checklist.statusAnalise === 'NAO_AVALIADO') {
                            snack.showAlert('O item ' + fgChecklist.itemTermoReferenciaRequerimento.itemTermoReferencia.numero +
                                ' do termo ' + fgChecklist.itemTermoReferenciaRequerimento.itemTermoReferencia.termoReferencia.titulo +
                                ' precisa ser avaliado.');
                            return false;
                        } else if (!rascunho && checklist.statusAnalise === 'NAO_ATENDE' && !checklist.comentario) {
                            snack.showAlert('O item ' + fgChecklist.itemTermoReferenciaRequerimento.itemTermoReferencia.numero +
                                ' do termo ' + fgChecklist.itemTermoReferenciaRequerimento.itemTermoReferencia.termoReferencia.titulo +
                                ' precisa de um comentário.');
                            return false;
                        } else {
                            if (checklists[keyChecklist].modificado || !rascunho) {
                                arrPost.push(checklist);
                            }
                            return true;
                        }
                    });
                });
            });
        });
        return allValidated ? arrPost : null;
    }

    get statusAvaliacoes(): BaseEnum[] {
        return ItemTermoReferenciaRequerimentoChecklist.statusAnalises;
    }

    onChangeComentario(comentario: string, iz: number, ix: number, iy: number): void {
        this.termosReferencia[iz].itensPorTipo[ix].itens[iy]['isValid'] = comentario && comentario.trim().length > 0;
        const conferencia = this.getConferenciaControl(iz, ix, iy);
        conferencia.modificado.patchValue(true);

        // Totals
        this.totals[iz][ix] = this.termosReferencia[iz].itensPorTipo[ix].itens.filter(e => e['isValid']).length;
    }

    getConferenciaControl(iz: number, ix: number, iy: number): { [p: string]: AbstractControl } {
        const termos = this.form.controls.termos as FormArray;
        const tipos = (termos.controls[iz] as FormGroup).controls.tipos as FormArray;
        const conferencias = (tipos.controls[ix] as FormGroup).controls.avaliacoes as FormArray;
        return (conferencias.controls[iy] as FormGroup).controls;
    }

    onChangeStatus(status: BaseEnum, iz: number, ix: number, iy: number): void {
        const conferencia = this.getConferenciaControl(iz, ix, iy);
        conferencia.modificado.patchValue(true);

        // Em caso de inconformidade, obrigar o comentário
        if (status.valor === 'NAO_ATENDE') {
            // Caso não tenha valor no comentário, mostrar que é obrigatório.
            if (!conferencia.comentario.value) {
                conferencia.comentario.setErrors({required: true});
                conferencia.comentario.markAsTouched();
            }
            conferencia.comentario.setValidators([Validators.required]);
        } else {
            conferencia.comentario.setValidators(null);
            conferencia.comentario.setErrors(null);
        }

        const item = this.termosReferencia[iz].itensPorTipo[ix].itens[iy];
        switch (status.valor) {
            case 'NAO_AVALIADO':
                item['color'] = ColorStatusAvaliacao.NAO_AVALIADO.toString();
                item['isValid'] = false;
                break;
            case 'ATENDIDO':
                item['color'] = ColorStatusAvaliacao.ATENDIDO.toString();
                item['isValid'] = true;
                break;
            case 'NAO_ATENDE':
                item['color'] = ColorStatusAvaliacao.NAO_ATENDE.toString();
                item['isValid'] = !(conferencia.comentario && !conferencia.comentario.value);
                break;
            case 'DISPENSADO':
                item['color'] = ColorStatusAvaliacao.DISPENSADO.toString();
                item['isValid'] = true;
                break;
        }

        // Totals
        this.totals[iz][ix] = this.termosReferencia[iz].itensPorTipo[ix].itens.filter(e => e['isValid']).length;
    }

    atualizarResumo(): void {
        this.limparResumo();

        Object.keys(this.form.controls).forEach(keyTermos => {
            // termos
            const termos = this.form.controls[keyTermos].value as FormArray;
            Object.keys(termos).forEach(keyTipos => {
                // tipos
                const tipos = termos[keyTipos].tipos as FormArray;
                Object.keys(tipos).forEach(keyChecklists => {
                    // checklists
                    const checklists = tipos[keyChecklists].avaliacoes as FormArray;
                    Object.keys(checklists).forEach((keyChecklist, index) => {
                        const item = checklists[keyChecklist] as ItemTermoReferenciaRequerimentoChecklistFormGroup;
                        switch (item.status) {
                            case 'NAO_AVALIADO':
                                this.checklist['NAO_AVALIADO']++;
                                break;
                            case 'ATENDIDO':
                                this.checklist['ATENDIDO']++;
                                break;
                            case 'NAO_ATENDE':
                                this.checklist['NAO_ATENDE']++;
                                break;
                            case 'DISPENSADO':
                                this.checklist['DISPENSADO']++;
                                break;
                        }
                    });
                });
            });
        });
    }

    historico(itemTermoReferenciaRequerimento: ItemTermoReferenciaRequerimento): void {
        this.service.getHistoricoItemRequerimentoChecklist(itemTermoReferenciaRequerimento.id).subscribe(item => {
            const historico = new HistoricoConferenciaChecklist(item, itemTermoReferenciaRequerimento);
            this.dialog.open(ItemTermoReferenciaHistoricoChecklistDialogComponent, {width: '700px', data: historico});
        });
    }
}

enum ColorStatusAvaliacao {
    NAO_AVALIADO = '#ffe287',
    ATENDIDO = '#7cb342',
    NAO_ATENDE = '#f08080',
    DISPENSADO = '#7cb342'
}
