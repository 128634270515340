import {BaseModel} from 'app/main/shared/models/base.model';

export class IndustriaFrigorifico extends BaseModel {
    constructor(
        public id?: number,
        public abateFrigorificoAnimais?: boolean,
        public graxaria?: boolean,
        public curtume?: boolean,
        public numeroFuncionarios?: number,
        public destinacaoProdutosTerceiros?: number,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): IndustriaFrigorifico {
        if (json === undefined || json === null) {
            return null;
        }
        return new IndustriaFrigorifico(
            json.id,
            json.abateFrigorificoAnimais,
            json.graxaria,
            json.curtume,
            json.numeroFuncionarios,
            json.destinacaoProdutosTerceiros,
            json.requerimento
        );
    }

}
