import {ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatStepper} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {BaseModel} from 'app/main/shared/models/base.model';
import {Subject} from 'rxjs';
import {DoencaGrave} from '../../../models/doenca-grave.model';
import {DocumentoDoencaPessoa} from '../../../../pages/requerimentos/shared/requerimento-responsaveis/documento-doenca-pessoa.model';
import {EnumTipoDocumentoDoencaPessoa} from '../../../../pages/requerimentos/shared/requerimento-responsaveis/enum-tipo-documento-doenca.model';
import {RequerimentoService} from '../../../../pages/requerimentos/requerimento.service';
import {DoencaGraveService} from '../../../services/doenca-grave.service';
import {Requerimento} from '../../../../pages/requerimentos/requerimento.model';
import {RequerimentoHelperAcessors} from '../../../../pages/requerimentos/shared/requerimento.helper';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

/**
 * Documento de Requisitos
 * SEMA_SIGA_02_EspecificacaoFuncional_CadastroRequerimento
 * Pag.63
 */

@Component({
    selector: 'app-aba-requerimento-interessado-pf',
    templateUrl: 'aba-requerimento-interessado-pf.component.html',
    styleUrls: ['./aba-requerimento-interessado-pf.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AbaRequerimentoInteressadoPfComponent extends RequerimentoHelperAcessors implements OnInit {

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        protected requerimentoService: RequerimentoService,
        private doencaGraveService: DoencaGraveService,
        injector: Injector
    ) {
        super(injector);
        this.activatedRoute = injector.get(ActivatedRoute);
    }

    @Input()
    requerimento: Requerimento;
    @Input()
    salvarSubject: Subject<any>;
    @Input()
    dirtySubject: Subject<any> = null;
    @Input()
    stepper: MatStepper;
    protected activatedRoute: ActivatedRoute;

    tiposDoencasGraves: DoencaGrave[] = null;
    documentosResponsavelDoencaGrave: DocumentoDoencaPessoa[] = EnumTipoDocumentoDoencaPessoa.listarDocumentosRestantes();

    // forms
    form: FormGroup;

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.buildResourceForm();
        this.loadData();
        if (!this.altDocResp) {
            this.form.disable();
        }
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            idadeSuperior60Anos: [{value: null, disabled: true}, [Validators.required]],
            possuiDeficienciaFisicaMental: [{value: null, disabled: true}, [Validators.required]],
            possuiDoencaGrave: [{value: null, disabled: true}, [Validators.required]],
            doencaGrave: [{value: null, disabled: true}],
            doenca: [{value: null, disabled: true}, [Validators.maxLength(150)]]
        });
        this.form.disable();
    }

    private loadData() {
        this.form.patchValue(this.requerimento.pessoa);

        this.doencaGraveService.getAll().subscribe((result) => {
            this.tiposDoencasGraves = result;
        });
        this.listarDocumentos();
    }

    listarDocumentos() {
        this.requerimentoService.listarDocumentosDoencaPessoa(this.requerimento.id).subscribe(result => {
            this.documentosResponsavelDoencaGrave = EnumTipoDocumentoDoencaPessoa.listarDocumentosRestantes(result);
        }, error => {
            console.log(error);
        });
    }


    onTipoDoencaChanged(event): void {
        if (event) {
            const field = this.form.controls.doenca;
            if (this.form.controls.doencaGrave.value.outro) {
                field.enable();
                field.setValidators([Validators.required]);
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
            }
        }
    }

    salvarDadosInteressado(): void {
        if (this.isFormValid()) {
            const formData = new FormData();

            if (this.form.controls.possuiDoencaGrave.value) {
                this.documentosResponsavelDoencaGrave.forEach(anexo => {
                    if (anexo.tipo == EnumTipoDocumentoDoencaPessoa.CID) {
                        if (anexo.arquivo) {
                            formData.append('fileCID', anexo.arquivo);
                        }
                        formData.append('sigiloCID', anexo.sigilo ? 'true' : 'false');

                    } else if (anexo.tipo == EnumTipoDocumentoDoencaPessoa.LAUDO) {
                        if (anexo.arquivo) {
                            formData.append('fileLaudoMedico', anexo.arquivo);
                        }
                        formData.append('sigiloLaudoMedico', anexo.sigilo ? 'true' : 'false');

                    }
                });
            } else {
                this.form.controls.doencaGrave.setValue(null);
            }
            const idTarefa = this.activatedRoute.snapshot.url[3].path;
            formData.append('pessoa', new Blob([JSON.stringify(this.form.value)], {type: 'application/json'}));
            this.requerimentoService.putInteressado(this.requerimento.id, formData, idTarefa).subscribe((result) => {
                this.requerimento.pessoa = result.pessoa;
                this.form.patchValue(result.pessoa);
                this.form.markAsPristine();
                this.form.markAsUntouched();
                this.form.updateValueAndValidity();
                this.snackBarService.showSuccess('Interessado atualizado com sucesso!');
                this.listarDocumentos();
            }, (e) => {
                this.snackBarService.showError('Erro ao atualizar o interessado.', e);
            });
        } else {
            this.snackBarService.showAlert('Preencha todos os campos obrigatórios.');
        }
    }

    isFormValid(): boolean {
        if (this.form.invalid) {
            return false;
        }
        if (this.form.controls.possuiDoencaGrave.value) {
            if (this.form.controls.doencaGrave.value == null) {
                return false;
            }
            if (this.form.controls.doencaGrave.value.outro && !this.form.controls.doenca.value) {
                return false;
            }
            if (this.quantidadeDeAnexoAdicionado() < 1) {
                return false;
            }
        }
        return true;
    }

    removerAnexo(index: number) {
        const anexo = this.documentosResponsavelDoencaGrave[index];
        anexo.arquivo = null;
        anexo.nomeArquivo = null;
    }

    selecionarAnexo(file: File, index: number): void {
        const anexo = this.documentosResponsavelDoencaGrave[index];
        anexo.arquivo = file;
        anexo.nomeArquivo = file.name;
        this.form.markAsTouched();
        this.form.markAsDirty();
        this.form.updateValueAndValidity();
    }

    public visualizarDocumento(documento: DocumentoDoencaPessoa): void {
        this.requerimentoService.downloadDocumentoDoencaPessoa(this.requerimento.id, documento).subscribe((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    public downloadDocumento(documento: DocumentoDoencaPessoa): void {
        window.open(
            `${this.requerimentoService.urlResource}/${this.requerimento.id}/interessado/documentos-doenca/${EnumTipoDocumentoDoencaPessoa.toJson(documento.tipo)}`
        );
    }

    /**
     * Quantidade de anexo em memória,
     * para validar o minímo de UM arquivo necessário.
     */
    quantidadeDeAnexoAdicionado(): number {
        let count = 0;
        this.documentosResponsavelDoencaGrave.forEach(anexo => {
            if (anexo.arquivo || anexo.id != null) {
                count++;
            }
        });
        return count;
    }

}

