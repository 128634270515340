import {Injectable, Injector} from "@angular/core";
import {BaseService} from "./base.service";
import {AnaliseLicencaPreviaConsemaAnexo} from "../models/analise-licenca-previa-consema-anexo.model";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})

export class AnaliseLicencaPreviaConsemaAnexoService extends BaseService<AnaliseLicencaPreviaConsemaAnexo>{
    constructor(protected  injector: Injector) {
        super(
            '/api/analise-licenca-previa-consema-anexo',
            injector,
            AnaliseLicencaPreviaConsemaAnexo.prototype,
            AnaliseLicencaPreviaConsemaAnexo.fromJson)
    }

    salvarAnaliseLicencaPreviaConsemaAnexo(file: FormData): Observable<any> {
        const url = `${this.urlResource}`;
        return this.http.post(url, file)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }
}