import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Requerimento} from '../../requerimento.model';
import {AbaDocumentoProcessoDTO} from './aba-documento-processo-dto.model';
import {AbaDocumentoProcessoRequerimentoService} from './aba-documento-processo-requerimento.service';
import {OrganizacaoAdministrativaCorporativoService} from "../../../../shared/services/organizacao-administrativa-corporativo.service";
import {AuthenticationService} from "../../../../core/services/authentication.service";
import {CurrentUser} from "../../../../core/models/current-user.model";
import * as moment from "moment";
import {UtilService} from "../../../../core/services/util.service";
import {EmissaoTituloService} from "../../../tarefas/shared/service/emissao-titulo.service";
import { TermoAdesaoECompromissoService } from './termo-adesao-e-compromisso.service';
import { error } from 'console';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'aba-documento-processo-requerimento',
    templateUrl: './aba-documento-processo-requerimento.component.html',
    styleUrls: ['./aba-documento-processo-requerimento.component.scss']
})
export class AbaDocumentoProcessoRequerimentoComponent implements OnInit, OnChanges {

    @Input() requerimento = new Requerimento();

    titulacaoDocumentosDTOS: Array<AbaDocumentoProcessoDTO> = [];
    isPerfilPermitido: any

    constructor(
        private abaDocumentoProcessoRequerimentoService: AbaDocumentoProcessoRequerimentoService,
        private organizacaoAdministrativaCorporativoService: OrganizacaoAdministrativaCorporativoService,
        private authenticationService: AuthenticationService,
        private emissaoTituloService: EmissaoTituloService,
        private utilService: UtilService,
        private snackBarService: SnackBarService,
        private termoAdesaoECompromissoService: TermoAdesaoECompromissoService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const usuario: CurrentUser = this.authenticationService.currentUserDecode;
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            this.carregarVerificacaoDePerfil(usuario.sub)
            this.carregarDocumentos();
        }
    }

    carregarVerificacaoDePerfil(cpf) {
        this.organizacaoAdministrativaCorporativoService
            .isPerfilSuperintendenciaOrGabinete(cpf).subscribe(isPerfil => {
            this.isPerfilPermitido = isPerfil
        })
    }

    private carregarDocumentos(): void {
        this.abaDocumentoProcessoRequerimentoService
            .buscarTitulosDocumentosEmitidos(this.requerimento.id)
            .subscribe(async result => {
                this.titulacaoDocumentosDTOS = result;

                let datas = []
                for (let r of result) {
                    if (r.idEmissaoTitulo) {
                        r.dataCriacao = this.utilService.zonedDateTimeFormat(r.dataCriacao, 'DD/MM/YYYY HH:mm:ss');
                        let emissao = await this.emissaoTituloService.getById(r.idEmissaoTitulo).toPromise()
                        r['situacaoTitulo'] = emissao.situacaoTitulo
                        datas.push(r)
                    }
                }
                let maiorData = datas.reduce(function(prev, current) {
                    return (moment(prev.dataCriacao).isAfter(current.dataCriacao)) ? prev : current
                })

                for (let r of this.titulacaoDocumentosDTOS) {
                    if (r.id === maiorData.id) {
                        r['outroAtoInterno'] = true
                    }
                }
            });

    }

    visualizarDocumento(doc: AbaDocumentoProcessoDTO): void {
        window.open(this.abaDocumentoProcessoRequerimentoService.getVisualizarDocumentoPath(doc));
    }

    baixarDocumento(doc: AbaDocumentoProcessoDTO): void {
        window.open(this.abaDocumentoProcessoRequerimentoService.getBaixarDocumentoPath(doc));
    }

    regerarLac(doc: AbaDocumentoProcessoDTO): void {
        this.termoAdesaoECompromissoService.regerarPdfLac(doc.idRequerimento).subscribe(()=> {
            this.snackBarService.showSuccess('Licença por adesão e compromisso gerada com sucesso!')
        }, error => {
            this.snackBarService.showError(error.errors[0] || "Erro ao regerar a licença por adesão e compromisso!");
        });
    }

    isDocumentoLAC(doc: AbaDocumentoProcessoDTO): boolean {
        return doc.grupoDocumento === 'LICENCA_POR_ADESAO_E_COMPROMISSO';
    }
}
