import {Component, OnInit, ViewChild} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {TipoProcesso} from '../../tipos-processo/tipo-processo.model';
import {TipoProcessoService} from '../../tipos-processo/tipo-processo.service';
import {Titulacao} from '../../titulacoes/titulacao.model';
import {TitulacaoService} from '../../titulacoes/titulacao.service';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {environment} from '../../../../../environments/environment';
import {SnackBarService} from '../../../shared/snack-bar/snack-bar.service';
import {FormUtil} from "../../../shared/util/form-util";
import {Municipio} from 'app/main/shared/models/geoportal-base-referencia/municipio.model';
import {MunicipioService} from 'app/main/shared/services/geoportal-base-referencia/municipio.service';
import {Finalidade} from '../../finalidades/finalidade.model';
import {FinalidadeService} from '../../finalidades/finalidade.service';
import {ServidorService} from 'app/main/shared/services/servidor.service';
import {Servidor} from 'app/main/shared/models/servidor.model';
import {SelectionModel} from '@angular/cdk/collections';
import {ObjetivoOutorga} from 'app/main/shared/models/objetivo-outorga.model';
import {TipoRequerimentoService} from "../../tipos-requerimento/tipo-requerimento.service";
import {TipoRequerimento} from "../../tipos-requerimento/tipo-requerimento.model";
import {
    ObjetivoLicenciamentoAmbientalService
} from "../../../shared/services/licenciamento-config-service/objetivo-licenciamento-ambiental.service";
import {ObjetivoOutorgaService} from "../../../shared/services/objetivo-outorga.service";
import {TarefaService} from "../../../shared/services/tarefa.service";
import {Tarefa} from "../../../shared/models/tarefa.model";
import {ProcessoService} from "../../../shared/services/processo.service";
import {ProcessDefinition} from "../../../shared/models/bpms/process-definition";
import {take} from "rxjs/operators";
import {ConsultaRequerimentoProcessoDTO} from "./model/consulta-requerimento-processo.dto";
import {
    ObjetivoLicenciamentoAmbiental
} from "../../../shared/models/licenciamento-config-models/objetivo-licenciamento-ambiental.model";
import {ConsultaRequerimentoProcessoService} from "../consulta-requerimento-processo.service";
import {MatDialog} from "@angular/material/dialog";
import {
    DataDetalheRequerimento,
    DialogDetalheRequerimentoComponent
} from "./dialog-detalhe-requerimento/dialog-detalhe-requerimento.component";

@Component({
    selector: 'app-consulta-requerimentos-processos',
    templateUrl: './consulta-requerimentos-processos.component.html',
    styleUrls: ['./consulta-requerimentos-processos.component.scss'],
    animations: fuseAnimations
})
export class ConsultaRequerimentosProcessosComponent implements OnInit {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    form: FormGroup;
    tiposProcesso: Array<TipoProcesso>;
    titulacoes: Array<Titulacao>;
    dataSourceRequerimentos: MatTableDataSource<ConsultaRequerimentoProcessoDTO>;
    municipios: Municipio[];
    conferentes: Servidor[];
    finalidadesOutorga: Array<Finalidade>;
    tiposRequerimento: Array<TipoRequerimento>;
    tarefas: Tarefa[] = [];
    definicoesProcesso: ProcessDefinition[] = [];
    objLicenciamento: ObjetivoLicenciamentoAmbiental[];
    pesquisado = false;
    servidores: Servidor[];
    totalElements: number;

    isServidorLogadoPerfilCoordenador: boolean = false;
    isServidorLogadoPerfilGestor: boolean = false;

    selection = new SelectionModel<ConsultaRequerimentoProcessoDTO>(true, []);
    isProfileDesenvolvimentoOuHomologacao: boolean = false;

    displayedColumns: string[] = [
        'prioridade', 'numRequerimento', 'numProcesso', 'interessado',
        'objetivo', 'finalidade', 'titulacao', 'acoes'
    ];

    objetivosOutorga: ObjetivoOutorga[] = [];

    comparador = (a: ConsultaRequerimentoProcessoDTO, b: ConsultaRequerimentoProcessoDTO) =>
        a && b ? a.id === b.id : a === b

    constructor(
        private readonly tipoProcessoService: TipoProcessoService,
        private readonly titulacaoService: TitulacaoService,
        private readonly municipioService: MunicipioService,
        private readonly tipoRequerimentoService: TipoRequerimentoService,
        private readonly servidorService: ServidorService,
        private readonly finalidadeService: FinalidadeService,
        private readonly consultaRequerimentoProcessoService: ConsultaRequerimentoProcessoService,
        private readonly objetivoLicenciamentoAmbientalService: ObjetivoLicenciamentoAmbientalService,
        private readonly fb: FormBuilder,
        private readonly dialog: MatDialog,
        private readonly snackBarService: SnackBarService,
        private readonly objetivoOutorgaService: ObjetivoOutorgaService,
        private readonly tarefaService: TarefaService,
        private readonly processoService: ProcessoService
    ) {
    }

    ngOnInit(): void {
        this.dataSourceRequerimentos = new MatTableDataSource<ConsultaRequerimentoProcessoDTO>();
        this.dataSourceRequerimentos.sort = this.sort;
        this.dataSourceRequerimentos.sortingDataAccessor = this.dataSourceSorter;
        this.getTipoProcesso();
        this.getTitulacoes();
        this.listarMunicipios();
        this.carregarFinalidades();
        this.listarServidores();
        this.getConferentes();
        this.form = this.fb.group(this.returnObjectForm());
        this.getTipoPerfilServidorLogado();
        this.getListaTiposRequerimento();
        this.getListaObjetivos();
        this.listarObjetivos();
        this.carregaListaProcessos();
        this.isServidorLogadoGestor();
        this.validarProfileEmExecucao();
    }

    private dataSourceSorter(item, property) {
        switch (property) {
            case 'numRequerimento':
                const numeroSplit = item.numero.split('/');
                return +numeroSplit[1] + (+numeroSplit[0] * 10000);
            case 'interessado':
                return item.nomePessoa;
            default:
                return item[property];
        }
    }

    private returnObjectForm(): any {
        return {
            numeroRequerimento: [],
            numeroProcesso: [],
            numeroProtocolo: [],
            nomeInteressado: [],
            cpfCnpjInteressado: [],
            dataInicio: [],
            dataFim: [],
            situacaoRequerimentoProcesso: [],
            municipioEmpreendimento: [],
            tipoProcesso: [],
            tiposOutorga: [],
            titulacaoId: [],
            prioridade: ['T'],
            finalidadeIds: [],
            tipoRequerimentoIds: [],
            processoTarefa: [],
            responsavelTarefa: [],
            nomeTarefa: [],
            conferenteResponsavel: [],
            analistaResponsavel: []
        };
    }

    private getListaObjetivos(): void {
        this.objetivoLicenciamentoAmbientalService.getAll().pipe(
            take(1)
        ).subscribe(objs => {
            this.objLicenciamento = objs.filter(p => p.situacao)
            this.objLicenciamento = this.objLicenciamento.filter(obj => obj.idTipoProcesso !== 2 && obj.idTipoProcesso !== 42)
        }, () => this.snackBarService.showError('Ocorreu um erro ao buscar a lista de objetivos'));
    }

    carregaListaProcessos(): void {
        this.processoService.getDefinitions().pipe(
            take(1)
        ).subscribe(
            definicoes => this.definicoesProcesso = definicoes,
            error => this.snackBarService.showError('Ocorreu um erro ao consultar a atividade.', error)
        );
    }

    carregaTarefas(): void {
        this.tarefas = [];
        if (this.form.get('processoTarefa').value) {
            this.tarefaService.getByProcessId(this.form.get('processoTarefa').value).pipe(
                take(1)
            ).subscribe(
                tarefas => this.tarefas = tarefas,
                error => this.snackBarService.showError('Ocorreu um erro ao consultar a atividade.', error)
            );
        }
    }

    private getTipoProcesso(): void {
        this.tipoProcessoService.getAll().pipe(
            take(1)
        ).subscribe(
            tiposProcesso => this.tiposProcesso = tiposProcesso,
            () => this.snackBarService.showError('Erro ao carregar as tipos de processo.')
        );
    }

    get tiposOutorga(): BaseEnum[] {
        return ObjetivoOutorga.tiposOutorga;
    }

    private listarObjetivos() {
        this.objetivoOutorgaService.getAll().pipe(
            take(1)
        ).subscribe(
            objetivosOutorga => this.objetivosOutorga = objetivosOutorga,
            () => this.snackBarService.showError('Erro ao listar objetivos da outorga.')
        );
    }

    private getListaTiposRequerimento() {
        this.tipoRequerimentoService.getAll().pipe(
            take(1)
        ).subscribe(x => this.tiposRequerimento = x);
    }

    private getTitulacoes(): void {
        this.titulacaoService.getAll().pipe(
            take(1)
        ).subscribe(
            titulacoes => this.titulacoes = titulacoes,
            () => this.snackBarService.showError('Erro ao carregar as titulações.')
        );
    }

    private listarMunicipios(): void {
        this.municipioService.getAllByUf(environment.UF_PADRAO).pipe(
            take(1)
        ).subscribe(
            municipios => this.municipios = municipios,
            () => this.snackBarService.showError('Erro ao carregar os municípios.')
        );
    }

    private carregarFinalidades(): void {
        this.finalidadeService.getAll().pipe(
            take(1)
        ).subscribe(
            finalidadesOutorga => this.finalidadesOutorga = finalidadesOutorga,
            () => this.snackBarService.showError('Erro ao carregar as finalidades.')
        );
    }

    private getTipoPerfilServidorLogado(): void {
        this.consultaRequerimentoProcessoService.getIsPerfilCoordenador().pipe(
            take(1)
        ).subscribe(
            response => this.isServidorLogadoPerfilCoordenador = response,
            () => this.snackBarService.showError('Erro ao verificar se o perfil servidor logado é do tipo coordenador.')
        );
    }

    private listarServidores(): void {
        this.servidorService.getListaServidoresAtivos().pipe(
            take(1)
        ).subscribe(
            servidores => this.servidores = servidores,
            () => this.snackBarService.showError('Erro ao carregar a lista de servidores.')
        );
    }

    private getConferentes(): void {
        this.consultaRequerimentoProcessoService.getServidoresCoordenadores().pipe(
            take(1)
        ).subscribe(
            response => this.conferentes = response,
            () => this.snackBarService.showError('Erro ao verificar se o perfil servidor logado é do tipo coordenador.')
        );
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.form.get('cpfCnpjInteressado').value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    numeroProtocoloMask(text): void {
        if (text && text.length > 4) {
            if (text.indexOf('/') > -1) {
                text = text.replace('/', '');
            }

            const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
            const year = text.slice(-4);
            this.form.get('numeroProcesso').patchValue(`${initial}/${year}`);
        }
    }

    numeroRequerimentoProcessoMask(text): void {
        if (text && text.length > 4) {
            if (text.indexOf('/') > -1) {
                text = text.replace('/', '');
            }

            const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
            const year = text.slice(-4);
            this.form.get('numeroRequerimento').patchValue(`${initial}/${year}`);
        }
    }

    consultar(): void {
        FormUtil.resetPaginator(this.paginator);
        this.listarRequerimentos();
    }

    limpar(): void {
        this.form.reset();
        this.form = this.fb.group(this.returnObjectForm());
        this.dataSourceRequerimentos.data = [];
        FormUtil.resetPaginator(this.paginator);
    }

    listarRequerimentos(): void {
        this.selection.clear();
        this.dataSourceRequerimentos.data = [];
        this.totalElements = 0;
        this.consultaRequerimentoProcessoService.consultaRequerimentosProcessos(this.form, this.paginator).pipe(
            take(1)
        ).subscribe(response => {
            this.pesquisado = true;
            this.dataSourceRequerimentos.data = response.content;
            this.totalElements = response.totalElements;
        }, () => this.pesquisado = true);
    }

    exportarToExcel(): void {
        this.consultaRequerimentoProcessoService.exportToExcel(this.form).pipe(
            take(1)
        ).subscribe(response => this.downloadFile(response, 'requerimentos.xlsx'));
    }

    downloadFile(blob: any, filename: string) {
        let binaryData = [];
        binaryData.push(blob);

        const url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));

        // create hidden dom element (so it works in all browsers)
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);

        // create file, attach to hidden element and open hidden element
        a.href = url;
        a.download = filename;

        a.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        }))

        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            a.remove();
        }, 100);
    }

    openDialogDetalheRequerimento(requerimentoId: number): void {
        this.dialog.open(DialogDetalheRequerimentoComponent, {
                width: '1800px',
                maxHeight: '535px',
                data: {
                    id: requerimentoId
                } as DataDetalheRequerimento
            }
        ).afterClosed().subscribe(result => {
            this.selection.clear();
            if (result) {
                this.listarRequerimentos();
            }
        });
    }


    isDataSourceEmpty(): boolean {
        return !this.dataSourceRequerimentos || this.dataSourceRequerimentos.data.length === 0;
    }

    //Verifica se o servidor logado é um gestor: superintendente/coordenador/gerente e seus substitutos
    private isServidorLogadoGestor(): void {
        this.consultaRequerimentoProcessoService.getIsPerfilGestor().pipe(
            take(1)
        ).subscribe(
            response => this.isServidorLogadoPerfilGestor = response,
            () => this.snackBarService.showError('Erro ao verificar se o perfil servidor logado é um gestor: superintendente/coordenador/gerente e seus substitutos.'));
    }

    validarProfileEmExecucao() {
        if (environment.name === 'dev' || environment.name === 'homolog' || environment.name === 'desenv' || environment.name === 'local') {
            this.isProfileDesenvolvimentoOuHomologacao = true;
        }

        return this.isProfileDesenvolvimentoOuHomologacao;
    }

}