import {Component, OnInit} from '@angular/core';
import {Complementacao} from '../../tarefas/complementacao/complementacao.model';
import {ComplementacaoService} from '../../tarefas/complementacao/complementacao.service';

@Component({
    selector: 'app-acompanhamento-complementacao-processo',
    templateUrl: './acompanhamento-complementacao-processo.component.html',
})
export class AcompanhamentoComplementacaoProcessoComponent implements OnInit {

    listaComplementacoesPrazo: Complementacao[] = [];
    displayedColumns: string[] = ['numeroProcesso', 'tipoRequerimento', 'finalidade', 'dataLimite', 'expiraEm', 'situacao', 'acao'];

    constructor(private complementacaoService: ComplementacaoService) {
    }

    ngOnInit(): void {
        this.complementacaoService.getPage().subscribe(result => {
            this.listaComplementacoesPrazo = result.content;
        });
    }

    visualizarCertidaoDeNaoAtendimento(): void {
    }

    resolverComplementacao(element: Complementacao): void {
    }

    solicitarProrrogacaoDePrazo(element: Complementacao): void {
    }
}
