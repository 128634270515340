import {Ponto} from "./ponto.model";
import {Finalidade} from "../../../pages/finalidades/finalidade.model";
import {ParametroDiluicao} from "./parametro-diluicao.model";
import {VazaoDto} from "../../../pages/requerimentos/shared/dialog-disponibilidade-hidrica/model/vazao-dto.model";
import {ModelUtil} from "../model-util";

export class PontoParametroDiluicao {

    constructor(
        public id?: number,
        public ponto?: Ponto,
        public idParametro?: number,
        public parametro?: ParametroDiluicao,
        public finalidade?: Finalidade,
        public concentracao?: number,
        public concentracaoBruta?: number,
        public concentracaoNatural?: number,
        public concentracaoLitro?: number,
        public vazao?: VazaoDto,
    ) {
    }

    static fromJson(json: any): PontoParametroDiluicao {
        if (json === undefined || json === null) {
            return null;
        }

        return new PontoParametroDiluicao(
            json.id ? json.id : null,
            json.ponto ? Ponto.fromJson(json.ponto) : null,
            json.parametro? json.parametro.parametroId : json.idParametro ? json.idParametro : null,
            json.parametro? ParametroDiluicao.fromJson(json.parametro) : null,
            json.finalidade ? Finalidade.fromJson(json.finalidade) : null,
            ModelUtil.getOrElseZero(json.concentracao),
            ModelUtil.getOrElseZero(json.concentracaoBruta),
            ModelUtil.getOrElseZero(json.concentracaoNatural),
                ModelUtil.getOrElseZero(json.concentracaoLitro),
            json.vazao? VazaoDto.fromJson(json.vazao) : null,
        );
    }

    static fromJsons(json: any): PontoParametroDiluicao[] {
        const array: PontoParametroDiluicao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(PontoParametroDiluicao.fromJson(item)));
            return array;
        }
    }
}
