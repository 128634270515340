import {Injectable, Injector} from '@angular/core';
import {AtividadeMineracao} from "../models/atividade-mineracao.model";
import {BaseMineracao} from "./base-mineracao.service";


@Injectable({
    providedIn: "root"
})
export class AtividadeMineracaoService extends BaseMineracao<AtividadeMineracao> {

    constructor(protected injector: Injector) {
        super(
            "/api/mineracao",
            injector,
            AtividadeMineracao.prototype,
            AtividadeMineracao.fromJson
        );
    }
}
