import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxLoadingModule} from 'ngx-loading';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterModule, Routes} from '@angular/router';
import {MatButtonModule, MatCheckboxModule, MatInputModule} from '@angular/material';
import {FlexModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AbaAtividadesModule} from 'app/main/shared/components/aba-atividades/aba-atividades.module';
import {FormUtil} from 'app/main/shared/util/form-util';
import {BenfeitoriaComponent} from './benfeitoria/benfeitoria.component';
import {AreaDeConfinamentoComponent} from './area-de-confinamento/area-de-confinamento.component';
import {AreaIrrigacaoComponent} from './area-irrigacao/area-irrigacao.component';
import {ComposteiraComponent} from './composteira/composteira.component';
import {FertilizacaoComponent} from './fertilizacao/fertilizacao.component';
import {FertirrigacaoComponent} from './fertirrigacao/fertirrigacao.component';
import {LagoaBaciaContencaoComponent} from './lagoa-bacia-contencao/lagoa-bacia-contencao.component';
import {TanquePisciculturaComponent} from './tanque-piscicultura/tanque-piscicultura.component';
import {TratamentoEfluentesComponent} from './tratamento-efluentes/tratamento-efluentes.component';
import {MatIconModule} from '@angular/material/icon';

export const routes: Routes = [
    {
        path: 'benfeitoria',
        component: BenfeitoriaComponent,
        pathMatch: 'full',
    },
    {
        path: 'area-de-confinamento',
        component: AreaDeConfinamentoComponent,
        pathMatch: 'full',
    },
    {
        path: 'area-irrigacao',
        component: AreaIrrigacaoComponent,
        pathMatch: 'full',
    },
    {
        path: 'composteira',
        component: ComposteiraComponent,
        pathMatch: 'full',
    },
    {
        path: 'fertilizacao',
        component: FertilizacaoComponent,
        pathMatch: 'full',
    },
    {
        path: 'fertirrigacao',
        component: FertirrigacaoComponent,
        pathMatch: 'full',
    },
    {
        path: 'lagoa-bacia-contencao',
        component: LagoaBaciaContencaoComponent,
        pathMatch: 'full',
    },
    {
        path: 'tanque-piscicultura',
        component: TanquePisciculturaComponent,
        pathMatch: 'full',
    },
    {
        path: 'tratamento-efluentes',
        component: TratamentoEfluentesComponent,
        pathMatch: 'full',
    }
];

@NgModule({
    declarations: [
        BenfeitoriaComponent,
        AreaDeConfinamentoComponent,
        AreaIrrigacaoComponent,
        ComposteiraComponent,
        FertilizacaoComponent,
        FertirrigacaoComponent,
        LagoaBaciaContencaoComponent,
        TanquePisciculturaComponent,
        TratamentoEfluentesComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatSelectModule,
        MatRadioModule,
        MatTableModule,
        NgxLoadingModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatInputModule,
        FlexModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        AbaAtividadesModule,
        MatIconModule
    ],
    providers: [
        FormUtil
    ]
})
export class FormulariosModule {
}
