import {BaseModel} from 'app/main/shared/models/base.model';
import {RequerimentoLicenciamento} from "../../../requerimento-licenciamento/requerimento-licenciamento.model";

export class RequerimentoLicenciamentoUpa extends BaseModel {
    constructor(
        public id?: number,
        public requerimentoLicenciamento?: RequerimentoLicenciamento,
        public anoExploracao?: number,
        public cicloCorteManejo?: number,
        public idOrigemUpa?: number,
        public cpfCnpjDetentor?: string,
        public idFeicao?: number,
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoLicenciamentoUpa {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoLicenciamentoUpa(
            json.id,
            RequerimentoLicenciamento.fromJson(json.requerimentoLicenciamento),
            json.anoExploracao,
            json.cicloCorteManejo,
            json.idOrigemUpa,
            json.cpfCnpjDetentor,
            json.idFeicao,
        );
    }
}
