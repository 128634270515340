import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {RedirectUtil} from "../../../shared/util/RedirectUtil";
import {Requerimento} from "../requerimento.model";
import {MatTableDataSource} from "@angular/material/table";
import {DocumentoProcesso} from "../../../shared/models/documento-processo.model";
import {DocumentoProcessoService} from "../../gerar-documento-publicacao/documento-processo.service";
import {SelectionModel} from "@angular/cdk/collections";
import {RequerimentoService} from "../requerimento.service";
import {EnumGrupoDocumento} from "../../../shared/enums/EnumGrupoDocumento";
import {SnackBarService} from "../../../shared/snack-bar/snack-bar.service";
import {MatPaginator} from "@angular/material/paginator";
import {ServidorService} from "../../../shared/services/servidor.service";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {GestaoManifestacaoService} from "../../../shared/services/gestao-manifestacao-service/gestao-manifestacao.service";
import {TodosDocumentosDto} from "../../../shared/models/todos-documentos-dto.model";
import {ArquivoService} from "../../../shared/services/arquivo.service";
import {PortalApiService} from "../../../shared/services/portal-api.service";
import {ResponsavelTecnico} from "../../../shared/models/responsavel-tecnico.model";
import {MatSort} from "@angular/material/sort";
import {Feicao} from "../../../shared/models/geoadmin/feicao.model";
import {FeicaoTabelaAtributosService} from "../../../shared/services/geo/feicao-tabela-atributos.service";

@Component({
    selector: 'app-vistas-ao-processo',
    templateUrl: './vistas-do-processo.component.html',
    styleUrls: ['./vistas-do-processo.component.scss']
})
export class VistasDoProcessoComponent implements OnInit {

    numero: string;
    cpfInteressado: string;
    nomeInteressado: string;
    tipo: string;

    mostrarConsulta: boolean = true;
    mostrarSelecaoProcessosInteressado: boolean = false;

    requerimento: Requerimento;
    listaDeRequerimentosInteressado: Requerimento[] = [];
    responsaveisTecnicosRequerimento: any[] = [];

    isUsuarioLogadoServidor: boolean;
    cpfUsuarioLogado: string;
    perfisUsuarioLogado: any[];
    perfilAutorizado: any;

    @ViewChild('documentosPaginator', {static: false}) documentosPaginator: MatPaginator;
    @ViewChild('processosPaginator', {static: false}) processosPaginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatSort, {static: false}) sortProcessos: MatSort;
    dataSourceDocumentos = new MatTableDataSource<any>([]);
    dataSourceProcessos = new MatTableDataSource<any>([]);
    selection = new SelectionModel<any>(true, []);

    constructor(private documentoProcessoService: DocumentoProcessoService,
                private requerimentoService: RequerimentoService,
                private snackBarService: SnackBarService,
                private servidorService: ServidorService,
                private authenticationService: AuthenticationService,
                private arquivoService: ArquivoService,
                private portalService: PortalApiService,
                private feicaoTabelaAtributosService: FeicaoTabelaAtributosService
    ) {
    }

    ngOnInit() {
        this.authenticationService.currentUser.subscribe(user => this.cpfUsuarioLogado = user.sub);
        this.isUsuarioServidor();
        this.buscarPerfisUsuarioLogado();
    }

    preConsulta(){
        if(this.numero){
            this.consultarNumeroProcessoRequerimento();
        }else if(this.cpfInteressado || this.nomeInteressado){
            this.consultarRequerimentoPorInteressado()
        }
    }

    async consultarRequerimentoPorInteressado(){
        this.mostrarSelecaoProcessosInteressado = true;
        this.listaDeRequerimentosInteressado =
            await this.requerimentoService.buscarRequerimentoPorInteressado(this.cpfInteressado, this.nomeInteressado).toPromise();
        if(this.listaDeRequerimentosInteressado.length > 0){
            this.dataSourceProcessos = new MatTableDataSource(this.listaDeRequerimentosInteressado);
            this.dataSourceProcessos.paginator = this.processosPaginator
            this.dataSourceProcessos.sort = this.sortProcessos;
        }else{
            this.dataSourceProcessos = new MatTableDataSource([]);
            this.dataSourceProcessos.paginator = this.processosPaginator
            this.dataSourceProcessos.sort = this.sortProcessos;
        }
    }

    async consultarNumeroProcessoRequerimento(){
        if(!this.numero || !this.tipo){
            this.snackBarService.showAlert("É obrigatório a seleção de um tipo e a inserção de um número de processo/requerimento.")
            return;
        }
        this.requerimento = null
        this.requerimento =  await this.requerimentoService.buscarRequerimentoPorNumero(this.numero, this.tipo).toPromise()

        if(this.requerimento){
            if(await this.validarAutorizacaoUsuarioLogado())
                await this.buscarTodosDocumentos();
        }else{
            this.snackBarService.showAlert("Número de Requerimento/Processo não localizado na base do SIGA");
        }
    }

    async avancarSelecaoProcessoInteressado(){
        if(await this.validarAutorizacaoUsuarioLogado()){
            await this.buscarTodosDocumentos();
            this.mostrarConsulta = !this.requerimento;
            this.mostrarSelecaoProcessosInteressado = false;
        }
    }

    voltar(){
        this.requerimento = null;
        this.mostrarConsulta = true;
        this.mostrarSelecaoProcessosInteressado = false;
        this.listaDeRequerimentosInteressado = []
        this.responsaveisTecnicosRequerimento = [];
        this.perfilAutorizado = null;
        this.limparCampos();
    }

    async buscarResponsaveisTecnicos(){
        this.responsaveisTecnicosRequerimento =
            await this.requerimentoService.listarResponsaveisTecnicosSemValidacao(this.requerimento.id).toPromise();
    }

    async buscarTodosDocumentos() {
        this.mostrarConsulta = !this.requerimento;

        const todosDocumentos = await this.documentoProcessoService.buscarTodosDocumentosDoProcesso(this.requerimento.id).toPromise();
        this.dataSourceDocumentos = new MatTableDataSource(todosDocumentos);
        this.dataSourceDocumentos.paginator = this.documentosPaginator
        this.dataSourceDocumentos.sort = this.sort;
        // const documentosAnexoSolicitacaoManifestacao =
        //     await this.gestaoManifestacaoService.buscarAnexosSolicitacaoPorRequerimento(this.requerimento.id).toPromise();
        // const documentosAnexoRespostaManifestacao =
        //     await this.gestaoManifestacaoService.buscarAnexosRespostaPorRequerimento(this.requerimento.id).toPromise();

        // this.dataSourceDocumentos = new MatTableDataSource(todosDocumentos.concat(documentosAnexoRespostaManifestacao,
        //     documentosAnexoSolicitacaoManifestacao));
    }

    async validarAutorizacaoUsuarioLogado(){
        await this.buscarResponsaveisTecnicos();
        if(this.cpfUsuarioLogado == this.requerimento.pessoa.cpfCnpj){
            this.perfilAutorizado = "INTERESSADO"
            return true;
        } else if(this.responsaveisTecnicosRequerimento.find(responsavel => responsavel.pessoa.cpfCnpj == this.cpfUsuarioLogado &&
            responsavel.habilitado)){
            this.perfilAutorizado = "RESPONSAVEL_TECNICO"
            return true;
        } else if(this.isServidor()){
            this.perfilAutorizado = "SERVIDOR"
            return true;
        } else if(this.perfisUsuarioLogado.find(perfilUsuario => perfilUsuario.perfil.chaveSegPerfil == 'VISTAS_ADVOGADOS_AUTORIZADOS')){
            this.perfilAutorizado = "ADVOGADO"
            return true;
        } else if(this.perfisUsuarioLogado.find(perfilUsuario => perfilUsuario.perfil.chaveSegPerfil == 'Ministério Publico - Parceiro')){
            this.perfilAutorizado = "MINISTERIO_PUBLICO"
            return true;
        } else{
            this.snackBarService.showAlert("Usuário não autorizado a acessar informações deste requerimento/processo. Entre em contato com a SEMA")
            this.requerimento = null
            return false;
        }
    }

    visualizarDocumentoProcesso(item: TodosDocumentosDto): void {
        if(this.isVisualizacaoArquivoValida(item.sigiloso)){
            window.open(`${this.arquivoService.urlResource}/${item.chave}`);
        }else{
            this.snackBarService.showError("Usuário não autorizado a visualizar os documentos do processo.");
        }
    }

    // downloadDocumentoProcesso(item: TodosDocumentosDto): void {
    //     if(this.isVisualizacaoArquivoValida(item.sigiloso)){
    //         window.open(`${this.arquivoService.urlResource}/${item.chave}/download`);
    //     }else{
    //         this.snackBarService.showError("Usuário não autorizado a visualizar os documentos do processo.");
    //     }
    // }

    isVisualizacaoArquivoValida(sigiloso: boolean){
        if(this.perfilAutorizado != "INTERESSADO" && this.perfilAutorizado != "RESPONSAVEL_TECNICO"){
            if(sigiloso){
                return this.perfisUsuarioLogado.find(perfilUsuario => perfilUsuario.perfil.chaveSegPerfil == 'VISTAS_DOCUMENTOS_SIGILOSOS');
            } else {
                return this.perfisUsuarioLogado.find(perfilUsuario => perfilUsuario.perfil.chaveSegPerfil == 'VISTAS_DOCUMENTOS_GERAIS'
                    || perfilUsuario.perfil.chaveSegPerfil == 'VISTAS_ADVOGADOS_AUTORIZADOS');
            }
        }else{
            return true;
        }
    }

    verificaSeTodosForamSelecionados() {
        const numSelecionados = this.selection.selected.length;
        const numLinhas = this.dataSourceDocumentos.data.length;
        return numSelecionados === numLinhas;
    }

    selecionarTodos() {
        if (this.verificaSeTodosForamSelecionados()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSourceDocumentos.data);
    }

    checkboxLabel(linha?: any): string {
        if (!linha) {
            return `${this.verificaSeTodosForamSelecionados() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(linha) ? 'deselect' : 'select'} row ${linha.position + 1}`;
    }

    downloadGeometrias(){
        const [numeroProcesso, ano] = this.requerimento.numeroProcesso.split("/");
        this.feicaoTabelaAtributosService.downloadSHPTodasGeometriasPorRequerimento(this.requerimento.id).subscribe(blob => {
            this.feicaoTabelaAtributosService.showFile(blob, `geometrias-do-processo-${numeroProcesso}-${ano}-shp.zip`);
        }, error => {
            this.snackBarService.showError(`Erro ao baixar o SHP: ${error}.`);

        });
    }

    fazerCargaProcesso(){
        if(this.selection.selected.length == 0){
            this.snackBarService.showAlert("Selecione os documentos desejados.")
            return;
        }

        //Verificando se o usuario está fazendo carga de documentos sigilosos sem ter permissão para isto.
        let documentosSigilosos = this.selection.selected.find(item => item.sigiloso);
        if(documentosSigilosos && !this.isVisualizacaoArquivoValida(true)){
            this.snackBarService.showError("Usuário não autorizado a visualizar os documentos do processo.");
            return;
        }

        /**
         * Permite baixar a carga de processos se:
         * 1º - tiver documentos sigilosos e o método 'isVisualizacaoArquivoValida(true)' retornar true;
         * 2º - Não tiver documentos sigilosos e o método 'isVisualizacaoArquivoValida(false)' retornar true;
         */
        if ((documentosSigilosos && this.isVisualizacaoArquivoValida(true)) || !documentosSigilosos && this.isVisualizacaoArquivoValida(false)) {
            let documentosMapeados = this.selection.selected.map(item => {return item.chave});
            this.documentoProcessoService.fazerCargaProcesso(this.requerimento.id, documentosMapeados).toPromise().then(response => {
                setTimeout(() => {
                    this.downloadZipArquivos(response);
                }, 2000);
                this.snackBarService.showSuccess('Baixando documentos...');
            }).catch(error => this.snackBarService.showError('Ocorreu um erro ao fazendo download dos documentos', error));
        } else {
            this.snackBarService.showError("Usuário não autorizado a fazer carga de processo.");
            return;
        }
    }

    downloadZipArquivos(blob: Blob): void {
        const blobGenerate = new Blob([blob], {type: blob.type});
        const downloadURL = window.URL.createObjectURL(blobGenerate);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'Vistas-do-requerimento-' +
            (this.tipo == "REQUERIMENTO" ? 'numero-' + this.requerimento.numero.toString().replace('/', '-') :
            'numero-processo-' + this.requerimento.numeroProcesso.toString().replace('/', '-')) + '.zip';
        link.click();
    }

    isUsuarioServidor(): void {
        this.servidorService.isUsuarioLogadoServidor().subscribe(response => {
            this.isUsuarioLogadoServidor = response.servidor;
        });
    }

    buscarPerfisUsuarioLogado() {
        this.portalService.buscarPerfilUsuario(this.cpfUsuarioLogado).subscribe(result => {
            this.perfisUsuarioLogado = result;
        })
    }

    isServidor():boolean {
        return this.isUsuarioLogadoServidor;
    }

    selecionarProcesso(requerimentoInteressadoSelecionado){
        this.requerimento = requerimentoInteressadoSelecionado;
    }

    numeroRequerimentoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.numero = `${initial}/${year}`;
            }
        }
    }

    limparCampos(){
        this.numero = "";
        this.tipo = "";
        this.cpfInteressado = "";
        this.nomeInteressado = "";
        this.mostrarSelecaoProcessosInteressado = false;
        this.listaDeRequerimentosInteressado = []
        this.dataSourceProcessos = new MatTableDataSource([]);
        this.dataSourceProcessos.paginator = this.processosPaginator
        this.dataSourceProcessos.sort = this.sortProcessos;
        this.selection.clear();
    }

    fechar(){
        RedirectUtil.redirecionaDashboardCamunda();
    }
}