import {Component, Input, OnInit} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';

@Component({
    selector: 'app-aba-objetivo-poco-irrigacao',
    templateUrl: './aba-objetivo-poco-irrigacao.component.html'
})

export class AbaObjetivoPocoIrrigacaoComponent implements OnInit {

    @Input() ponto: Ponto;

    constructor() {
    }

    ngOnInit(): void {
    }

}


