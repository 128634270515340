import {Injectable, Injector} from '@angular/core';
import {EeLinhaTransmissao} from '../models/ee-linha-transmissao.model';
import {BaseEeLinhaTransmissao} from "./base-ee-linha-transmissao.service";


@Injectable({
    providedIn: "root"
})
export class EeLinhaTransmissaoService extends BaseEeLinhaTransmissao<EeLinhaTransmissao> {

    constructor(protected injector: Injector) {
        super(
            "/api/ee-linha-transmissao",
            injector,
            EeLinhaTransmissao.prototype,
            EeLinhaTransmissao.fromJson
        );
    }
}
