import {BaseModel} from 'app/main/shared/models/base.model';

export class RequerimentoIsencaoDto extends BaseModel {
    constructor(
        public idRequerimento?: number,
        public idIsencaoTaxa?: number,
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoIsencaoDto {
        json = json || {};

        return new RequerimentoIsencaoDto(
            json.idRequerimento,
            json.idIsencaoTaxa,
        );
    }
}