import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ResiduoGeradoModel} from "../models/residuo-gerado.model";
import {GerenciamentoResiduoModel} from "../models/gerenciamento-residuo.model";
import {Dominio} from "../models/dominio-model";

@Injectable({
    providedIn: 'root'
})
export class EmpreendimentoGerenciamentoResiduosService {

    private readonly URL_RESOURCE_RESIDUOS_GERADOS: string = `${environment.URL_LIC_RES_GERENCIAMENTO}/api/empreendimento-residuos-gerados`;
    private readonly URL_RESOURCE_GERENCIAMENTO_RESIDUOS: string = `${environment.URL_LIC_RES_GERENCIAMENTO}/api/empreendimento-gerenciamento-residuos`;

    constructor(private http: HttpClient) {
    }

    public salvarResiduoGerado(residuo: ResiduoGeradoModel): Observable<ResiduoGeradoModel> {
        return this.http.post<ResiduoGeradoModel>(`${this.URL_RESOURCE_RESIDUOS_GERADOS}/`, residuo);
    }

    public deletarResiduoGerado(idResiduo: number): Observable<void> {
        return this.http.delete<void>(`${this.URL_RESOURCE_RESIDUOS_GERADOS}/${idResiduo}`);
    }

    public consultarResiduosGeradosPorId(idResiduo: number): Observable<ResiduoGeradoModel> {
        return this.http.get<ResiduoGeradoModel>(`${this.URL_RESOURCE_RESIDUOS_GERADOS}/${idResiduo}`);
    }

    public listarResiduosGeradosPorRequerimento(idRequerimento: number): Observable<ResiduoGeradoModel[]> {
        return this.http.get<ResiduoGeradoModel[]>(`${this.URL_RESOURCE_RESIDUOS_GERADOS}/requerimento/${idRequerimento}`);
    }

    public buscarUnidadeMedidaFiltrada(): Observable<Dominio[]> {
        return this.http.get<Dominio[]>(`${this.URL_RESOURCE_RESIDUOS_GERADOS}/dominios-unidade-medida-filtrada/`);
    }

    public salvarGerenciamentoResiduos(ger: GerenciamentoResiduoModel): Observable<GerenciamentoResiduoModel> {
        return this.http.post<GerenciamentoResiduoModel>(`${this.URL_RESOURCE_GERENCIAMENTO_RESIDUOS}/`, ger);
    }

    public consultarGerenciamentoResiduosPorId(idGer: number): Observable<GerenciamentoResiduoModel> {
        return this.http.get<GerenciamentoResiduoModel>(`${this.URL_RESOURCE_GERENCIAMENTO_RESIDUOS}/${idGer}`);
    }

    public listarGerenciamentoResiduosPorRequerimento(idRequerimento: number): Observable<GerenciamentoResiduoModel[]> {
        return this.http.get<GerenciamentoResiduoModel[]>(`${this.URL_RESOURCE_GERENCIAMENTO_RESIDUOS}/requerimento/${idRequerimento}`);
    }

    public deletarGerenciamentoResiduos(idGer: number): Observable<void> {
        return this.http.delete<void>(`${this.URL_RESOURCE_GERENCIAMENTO_RESIDUOS}/${idGer}`);
    }

    public consultarPessoa(nuCpfCnpj: string): Observable<any> {
        return this.http.get(`${environment.URL_SCORP_API}/api/pessoas/pessoa-dto-sem-validacao-de-cadastro/cpfcnpj/${nuCpfCnpj}`);
    }

}
