import { Component, ElementRef, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UtilService } from '../../../../../../core/services/util.service';
import { AtividadeArrecadacao } from '../../../../../../shared/models/arrecadacao-models/atividade-arrecadacao.model';
import { AtividadeProjectionArrecadacao } from '../../../../../../shared/models/arrecadacao-models/atividade-projection-arrecadacao.model';
import { ParametroEntradaArrecadacao } from '../../../../../../shared/models/arrecadacao-models/parametro-entrada-arrecadacao.model';
import { FormularioReqLicenciamentoEmp } from "../../../../../../shared/models/formulario-req-licenciamento-emp.dto";
import { FormularioReqLicenciamentoDto } from '../../../../../../shared/models/formulario-req-licenciamento.dto';
import { LancamentoTaxaDto } from '../../../../../../shared/models/lancamento-taxa-dto.model';
import { ObjetivoLicenciamento } from "../../../../../../shared/models/objetivo-licenciamento.model";
import { AtividadeArrecadacaoService } from '../../../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service';
import { AtividadeCalculoArrecadacaoService } from '../../../../../../shared/services/arrecadacao-service/atividade-calculo-arrecadacao.service';
import { AtividadeParametroEntradaArrecadacaoService } from '../../../../../../shared/services/arrecadacao-service/atividade-parametro-entrada-arrecadacao.service';
import { ContaCorrenteService } from '../../../../../../shared/services/arrecadacao-service/conta-corrente.service';
import { LancamentoTaxaService } from "../../../../../../shared/services/lancamento-taxa.service";
import { ObjetivoParametrizacaoObjetivoService } from "../../../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service";
import { ObjetivoLicenciamentoService } from "../../../../../../shared/services/objetivo-licenciamento.service";
import { SnackBarService } from '../../../../../../shared/snack-bar/snack-bar.service';
import { Titulacao } from '../../../../../titulacoes/titulacao.model';
import { BaseTabRequerimentoComponent } from '../../../../shared/base-requerimento.component';
import { ErrosAbaRequerimento } from '../../../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { RequerimentoSteps } from '../../../../shared/requerimento.steps';
import { RequerimentoFormularioLicenciamentoService } from "../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import { RequerimentoLicenciamentoService } from '../../../requerimento-licenciamento.service';

@Component({
    selector: 'app-aba-calculo-taxa-lic-emp',
    templateUrl: './aba-calculo-taxa-lic-emp.component.html',
    styleUrls: ['./aba-calculo-taxa-lic-emp.component.scss']
  })
  export class AbaCalculoTaxaLicEmpComponent  extends BaseTabRequerimentoComponent implements OnInit {
   
    camposCredito = 'N';
    atividadeSemParametroeCaculo = false;
    parametroEntradas: any[] = [];
    parametroEntrada: any = {};
    creditoUsuario: any
    idPessoa: number
    atividadeProjection: Array<AtividadeProjectionArrecadacao>
    atividadePai: AtividadeArrecadacao
    displayedAtvColumns: string[] = ['atividade', 'subatividade'];
    element: ElementRef;
    parametroCalculoList: any;
    atividadeCalculo: any[];
    parametrosCalculoLista: any = [];
    msgCalculo = '';
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoDto;
    formularioReqLicenciamentoEmp: FormularioReqLicenciamentoEmp;
    errorStatusCalculoParametroEntrada = 200;
    calcularUpfRetornoDtoList: any[] = [];
    titulacaoIsenta = true;
    display: string[] = ['naturezaReceita', 'parametros', 'qtdUpf', 'qtdReal'];
    displayLT: string[] = ['naturezaReceita', 'qtdUpf'];
    licencas: any = [];
    isento: boolean;
    timer
    lancamentoTaxa: LancamentoTaxaDto;
    lancamentosTaxa: LancamentoTaxaDto[];
    taxaIsenta: boolean;
    guiasAssociadas: any[] = [];
    guiasAssociadasDisplay = ['numDar', 'situacao', 'vencimento', 'valor', 'acao'];
    objetoReqIsencao: any = [];
    objetoReqReaproveitamentoTaxa: any = [];

    objetivosLicenciamento: ObjetivoLicenciamento[];
    parametrizacaoDoObjetivoPrincipal: any;

    constructor(private formBuilder: FormBuilder,
                private contaCorrenteService: ContaCorrenteService,
                private atividadeArrecadacaoService: AtividadeArrecadacaoService,
                private atividadeCalculoService: AtividadeCalculoArrecadacaoService, 
                private injector: Injector,
                private atividadeCalculoParametroEntradaService: AtividadeParametroEntradaArrecadacaoService,
                private snackBarService: SnackBarService,
                protected requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
                private atividadeCalculoArrecadacaoService: AtividadeCalculoArrecadacaoService,
                private lancamentoTaxaService: LancamentoTaxaService,
                protected requerimentoFormLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
                private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
                private utils: UtilService) {
        super(injector);
        RequerimentoSteps.TAXA.component = this;
    }

    async ngOnInit() {
        this.idPessoa = this.requerimento.pessoa.idCorporativo
        await this.recuperarAtividadeProjection()
        if (this.requerimento.tipoProcesso.instanciaRequerimento === "LICENCIAMENTO_TRIFASICO" ||
            this.requerimento.tipoProcesso.instanciaRequerimento === "TERMO_REFERENCIA" ||
            this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS") {
            await this.buscarFormularioRequerimentoLicenciamentoEmp()
        } 
        else {
           // await this.buscarFormularioRequerimentoLicenciamento()
           this.snackBarService.showAlert("Requerimento não é do tipo Empreendimentos");
        }

        // this.obterSaldoUpf()
        if (this.taxaIsenta === false) {
            this.patchForm();
        }
 
    }

    private async buscarFormularioRequerimentoLicenciamentoEmp() {
        await this.requerimentoFormLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise().then(async formularioReqLicenciamentoDto => {
            if (formularioReqLicenciamentoDto) {
                if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                    this.formularioReqLicenciamentoEmp = formularioReqLicenciamentoDto
                    this.lancamentosTaxa = formularioReqLicenciamentoDto.lancamentoTaxa;
                    if (this.lancamentoTaxa) {
                        this.taxaIsenta = this.lancamentoTaxa.taxaIsenta
                        this.taxaIsenta = this.lancamentoTaxa.taxaIsenta;
                        if (this.lancamentoTaxa.taxaIsenta === false) {
                            this.taxaIsenta = false;
                        }
                        this.taxaIsenta = this.lancamentoTaxa.taxaIsenta;

                        if (this.taxaIsenta === false) {
                            this.patchForm();
                        }
                        // this.pesquisarGuiasAssociadas();
                    }
                }
            }
        });
    }
  

    async recuperarAtividadeProjection() {
        await this.atividadeArrecadacaoService
            .getByAtividadeNivelPorDesc(null, this.requerimentoLicenciamento.idAtividade)
            .toPromise().then(async data => {
                this.atividadeProjection = data
                if (this.atividadeProjection[0].nivel === 3) {
                    await this.atividadeArrecadacaoService.getAtividadeId(this.atividadeProjection[0].id).toPromise().then(atv => {
                        this.atividadePai = atv
                    })
                } else if (this.atividadeProjection[0].nivel === 4) {
                    await this.atividadeArrecadacaoService.getAtividadeId(this.atividadeProjection[0].idAtvPai).toPromise().then(atv => {
                        this.atividadePai = atv
                    })
                }

            });
    }

    private objetivoAtividadeDeObjetivo() {
        let novaArrayAtividade = [];
        for (let obj of this.objetivoLicenciamento) {
            novaArrayAtividade.push(this.atividadeProjection.find(d => d.descricao.includes(obj.objetivo.objetivo.descricao)))
        }
        this.atividadeProjection = novaArrayAtividade
    }

    async obterAtividadeCalculoSemParametroEntradaeCalculo() {
        let msg = true;
        await this.atividadeCalculoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicenca())
            .toPromise().then(async atividadeCalculo => {
                this.atividadeCalculo = atividadeCalculo;
                this.parametroCalculoList = [...this.atividadeCalculo].shift();
                if (this.parametroCalculoList && this.parametroCalculoList.parametroCalculoList.length === 0) {
                    this.atividadeSemParametroeCaculo = true;
                    await this.calcularLicenca()
                }
            }).catch(() => {
                msg = false;
            });
    }

    async obterAtividadeCalculoParametroEntrada() {
        this.objetivosLicenciamento =
            await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();
        //Filtrando objetivos principais (1 = PRINCIPAL)
        let objetivosPrincipaisDoRequerimento = this.objetivosLicenciamento.filter(obj => obj.idTipoObjetivo == 1)
        let objsParametrizados = await this.objetivoParametrizacaoObjetivoService.getByParametrizacaoObjetivo(this.requerimento.idParametroObjetivo).toPromise();

        //TODO Pegando o primeiro do array enquanto a regra a respeito de qual objetivo principal tem maior prioridade é descutida.
        this.parametrizacaoDoObjetivoPrincipal = objsParametrizados.filter(obj => obj.idTipoRequerimento == this.requerimento.tipoRequerimento.id &&
            obj.idObjetivoParametrizacao == objetivosPrincipaisDoRequerimento[0].idObjetivoLicenciamentoAmbiental)

        await this.atividadeCalculoParametroEntradaService
            .buscarParametrosEntradaPIdAtividade(this.atividadeProjection[0].id, this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao)
            .toPromise().then(atividadeParametroCalculoEntrada => {
                this.parametroEntradas = atividadeParametroCalculoEntrada;
            }).catch(async error => {
                error.status = 404;
                this.errorStatusCalculoParametroEntrada = error.status;
                if (this.errorStatusCalculoParametroEntrada === 404) {
                    await this.obterAtividadeCalculoSemParametroEntradaeCalculo()
                }
            })
    }

    montarObjetoCalculoUpf(idAtividadeCalculo) {

        this.parametrosCalculoLista.forEach(param => {
            delete param.id;
        });

        return {
            idAtividadeCalculo: idAtividadeCalculo,
            parametrosCalculo: this.parametrosCalculoLista,
            parametroEntrada: this.parametroEntrada
        };
    }


    async calcularLicenca() {
        this.calcularUpfRetornoDtoList = [];
        for (let i = 0; i < this.atividadeCalculo.length; i++) {
            await this.atividadeCalculoService
                .calcularUpf(this.montarObjetoCalculoUpf(this.atividadeCalculo[i].id)).toPromise().then(calcularUpfRetornoDto => {
                    if (calcularUpfRetornoDto.atividadeCalculo.tipoTitulacao.id === 14 || calcularUpfRetornoDto.atividadeCalculo.tipoTitulacao.id === 28) { // AUTORIZAÇÃO
                        if (this.calcularUpfRetornoDtoList.length === 0) {
                            if (calcularUpfRetornoDto.valorUpf === 0) {
                                this.titulacaoIsenta = false;
                            } else {
                                this.titulacaoIsenta = true;
                                calcularUpfRetornoDto['parametrosDeCalculo'] = this.parametrosCalculoLista
                                this.calcularUpfRetornoDtoList.push(calcularUpfRetornoDto);
                                this.licencas = []
                                this.licencas = this.calcularUpfRetornoDtoList.slice()
                            }
                        }
                    } else {
                        this.titulacaoIsenta = true;
                    }
                }).catch(() => {
                });
        }
    }

    private async patchForm() {
        await this.obterAtividadeCalculoParametroEntrada();
        const valorArea = await this.requerimentoLicenciamentoService.getUnidadeProducaoAnual(this.requerimentoLicenciamento.id).toPromise()
        const paramList:any = (this.parametroCalculoList && this.parametroCalculoList.parametroCalculoList ? this.parametroCalculoList.parametroCalculoList : this.parametroCalculoList) || {};
        if (paramList['forEach'])
            paramList.forEach(paramCalc => {
                this.pegarValorDeParamCalc(valorArea, paramCalc);
            })
    }

    montarObjetoParametroSemLicenca() {

        if (this.atividadeProjection[0].nivel === 3) {
            return {
                idAtividade: this.atividadeProjection[0].id,
                pisiculturaExotico: false,
                regraApp: false,
                idTipoTitulacao: this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao
            };
        } else if (this.atividadeProjection[0].nivel === 4) {
            return {
                idAtividade: this.atividadeProjection[0].idAtvPai,
                pisiculturaExotico: false,
                regraApp: false,
                idTipoTitulacao: this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao
            };
        }


    }

    async pegarValorDeParamCalc(valor, parametroEntradas: ParametroEntradaArrecadacao, licencas = []) {
        this.msgCalculo = 'Aguarde 5 segundos para o calculo'
        this.montarObjetoParamEntrada(parametroEntradas, valor)
        if (this.atividadeCalculo && this.atividadeCalculo.length === 0) {
            this.calcularUpfRetornoDtoList = this.atividadeCalculo
        } else {
            this.calcularUpfRetornoDtoList = licencas
        }
        await this.calcularLicenca()
    }

    montarObjetoParamEntrada(parametroEntradas, valor) {

        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (!this.parametroCalculoList.length) {
            if (this.parametroCalculoList.parametroCalculoList.length <= 1) {
                this.parametrosCalculoLista = []
            }
        }

        if (this.parametrosCalculoLista.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoLista.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoLista.splice(this.parametrosCalculoLista.indexOf(paramCalc), 1);
                    this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
                }
            });
        } else {
            this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
        }
    }

    refatorandoLicencasQNaoTemSigla(licenca: any): any {
        if (!licenca.atividadeCalculo.tipoTitulacao.sigla || licenca.atividadeCalculo.tipoTitulacao.sigla === '') {
            return `${licenca.atividadeCalculo.tipoTitulacao.descricao}`
        } else {
            return `${licenca.atividadeCalculo.tipoTitulacao.descricao} - (${licenca.atividadeCalculo.tipoTitulacao.sigla})`;
        }
    }

    refatorandoLancamentoQNaoTemSigla(licenca: LancamentoTaxaDto): any {
        return `${licenca.titulacao.tipo} - (${licenca.titulacao.descricao})`;
    }

    // createFormIsento() {
    //     this.formIsento = this.formBuilder.group({
    //         status: [null]
    //     });
    //     this.formIsento.controls.status.patchValue('9');
    // }

    // setIsento(isento: string) {
    //     this.isento = true
    //     // this.taxaIsenta = isento
    // }

    // async uncheckRadio(isento: string) {
    //     await this.obterSaldoUpf()
    //     this.isento = false
    //     // this.taxaIsenta = isento
    // }

    private montarFormularioReqLicenDTO() {
        const lancamentoTaxa = new LancamentoTaxaDto();
        if (this.formularioReqLicenciamentoDto) {
            if (this.formularioReqLicenciamentoDto.lancamentoTaxa) {
                lancamentoTaxa.id = this.formularioReqLicenciamentoDto.lancamentoTaxa.id;
            }
        }
        lancamentoTaxa.requerimento = this.requerimento;
        lancamentoTaxa.qtdePonto = 1;

        if (this.calcularUpfRetornoDtoList.length > 0) {
            lancamentoTaxa.qtdeTotalUPF = this.calcularUpfRetornoDtoList[0].valorUpf;
            lancamentoTaxa.qtdeUnitarioUPF = this.calcularUpfRetornoDtoList[0].valorUpf;
            lancamentoTaxa.valorReal = this.calcularUpfRetornoDtoList[0].valorReal
            lancamentoTaxa.codigoReceitaNatureza = this.calcularUpfRetornoDtoList[0].atividadeCalculo.receitaNatureza.id.toString();
            lancamentoTaxa.codigoReceitaGrupo = this.calcularUpfRetornoDtoList[0].atividadeCalculo.receitaNatureza.receitaGrupo.id.toString();
            lancamentoTaxa.idAtividadeCalculo = this.calcularUpfRetornoDtoList[0].atividadeCalculo.id;
        }

        lancamentoTaxa.utilizarCredito = this.camposCredito
        lancamentoTaxa.taxaIsenta = this.taxaIsenta

        const titulacao = new Titulacao();
        titulacao.id = 17;
        lancamentoTaxa.titulacao = titulacao;

        const lancamentoTaxaParamCalculoDto = [];
        this.parametrosCalculoLista.forEach(paramCalc => {
            if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                const paramCalcFind = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.find(param => param.labelFormulario === paramCalc.labelFormulario);
                if (paramCalcFind) {
                    lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                        .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO', paramCalcFind.id));
                }
            } else {
                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO'));
            }
        });

        this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList = lancamentoTaxaParamCalculoDto;
        this.formularioReqLicenciamentoDto.lancamentoTaxa = lancamentoTaxa;
        if (this.atividadeSemParametroeCaculo) {
            this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = true;
        } else {
            this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = false;
        }

        return this.formularioReqLicenciamentoDto;
    }

    async salvar() {
        if (this.requerimento.tipoProcesso.instanciaRequerimento !== "TERMO_REFERENCIA") {
            this.requerimentoFormLicenciamentoService.salvarFormularioCalculoDeLicenca(this.montarFormularioReqLicenDTO()).toPromise().then(formularioReqLicenciamentoDto => {
                this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
            }).catch(error => {
                this.snackBarService.showError('Ocorreu um erro', error);
            });
        }
    }

    async validarAba(erros: ErrosAbaRequerimento) {

        // valida requerimento - isencao
        if (this.objetoReqIsencao.isencao !== 0 && this.objetoReqIsencao.arquivo === false
            || this.objetoReqIsencao.isencao === undefined && this.objetoReqIsencao.arquivo === false) {
            erros.push('Verificar criterios de isenção  !');
        }

        // valida requerimento - reaproveitamento
        else if (this.objetoReqReaproveitamentoTaxa.length === 0) {
            erros.push('Escolha se deseja ou não utilizar créditos !');
        } else if (this.objetoReqReaproveitamentoTaxa.decisaoReaproveitamento === 'S'
            && this.objetoReqReaproveitamentoTaxa.qtdCredito === 0) {
            erros.push('Utilizar quantidade de upf corretamente !');
        }

        if (erros.length === 0) {
            if (this.requerimento.tipoProcesso.instanciaRequerimento === "LICENCIAMENTO_TRIFASICO" || this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS") {
                return await Promise.resolve();
            } else {
                return await Promise.all([
                    this.salvar(),
                ]).then(() => Promise.resolve());
            }
        }
        return Promise.resolve();
    }

    stringParametroCalculo(parametros: any) {

        let novoArray = []
        for (let param of parametros) {
            let newObject = `${param.labelFormulario} : ${param.valor}`
            novoArray.push(newObject)
        }
        return novoArray.join(', ')
    }

    pesquisarGuiasAssociadas() {
        const dadosSearch = {
            numDar: '',
            cpfcnpj: this.requerimento.pessoa.cpfCnpj,
            nome: '',
            dataVencimento: '',
            situacaoId: 1,
            naturezaId: ''
        };

        this.atividadeCalculoArrecadacaoService.buscarDarsEmitidos(dadosSearch).subscribe(dars => {
            if (dars && dars.length > 0)
                this.guiasAssociadas.push(...dars);
            else  this.guiasAssociadas = [];
        })
    }

    emitirDemonstrativo(numDar: any, idDar: number) {
        this.atividadeCalculoArrecadacaoService.obterDemonstrativoTaxa(idDar).toPromise().then((result) => {
            const blob = new Blob([result], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = numDar + '_demonstrativo.pdf';
            link.click();
        }).catch(error => {
            this.snackBarService.showError('Falhar ao baixar documento DAR.')
        });
    }

    recebeEmitterReqIsencao(event: any): void {
        /// recebe o ultimo objeto do array
        this.objetoReqIsencao = event[event.length - 1];
        this.taxaIsenta = this.objetoReqIsencao.isencao !== 0;
    }

    recebeEmitterReaproveitamento(event: any): void {
        /// recebe o ultimo objeto do array
        this.objetoReqReaproveitamentoTaxa = event[event.length - 1];
        this.camposCredito = this.objetoReqReaproveitamentoTaxa.decisaoReaproveitamento;
    }
}