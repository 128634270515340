import { BaseModel } from "../base.model";

export class CoordenadaDTO extends BaseModel {
    constructor(
        public id?: number,
        public microbaciaId?: number,
        public codigoBacia?: string,
        public unidadeBacia?: string,
        public empreendimento?: string,
        public cpfCnpjInteressado?: string,
        public interessado?: string,
        public dataCadastro?: Date,
        public tipoSolicitacao?: string,
        public finalidade?: string,
        public vazao?: string,
        public numeroProcesso?: string,
        public idPontoOutorga?: number



    ) {
        super();
    }

    static fromJson(json: any): CoordenadaDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new CoordenadaDTO(
            json.id ? json.id : null,
            json.microbaciaId ? json.microbaciaId : null,
            json.codigoBacia ? json.codigoBacia : null,
            json.unidadeBacia ? json.unidadeBacia : null,
            json.empreendimento ? json.empreendimento : null,
            json.cpfCnpjInteressado ? json.cpfCnpjInteressado : null,
            json.interessado ? json.interessado : null,
            json.dataCadastro ? json.dataCadastro : null,
            json.tipoSolicitacao ? json.tipoSolicitacao : null,
            json.finalidade ? json.finalidade : null,
            json.vazao ? json.vazao : null,
            json.numeroProcesso ? json.numeroProcesso : null,
            json.idPontoOutorga ? json.idPontoOutorga : null,
            
        );
    }

    static fromJsons(json: any): CoordenadaDTO[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(CoordenadaDTO.fromJson(j)));
            return resources;
        }
    }
}