import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DocumentoRequerimentoService} from '../documento-requerimento.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DocumentoRequerimento} from '../documento-requerimento.model';
import {FileValidationUtil} from '../../../shared/util/file-validation-util';
import {ItemTermoReferencia} from '../../itens-termos-referencia/item-termo-referencia.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {MotivoJuntadaService} from '../../../shared/services/motivo-juntada.service';
import {JuntadaProcessoService} from '../../../shared/services/juntada-processo.service';
import {JuntadaProcesso} from '../../../shared/models/juntada-processo.model';

@Component({
    selector: 'app-documento-requerimento-dialog',
    templateUrl: './documento-requerimento-dialog.component.html',
})
export class DocumentoRequerimentoDialogComponent implements OnInit {

    form: FormGroup;
    arquivo: File;
    motivosJuntada: any;
    extensoes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel', 'application/vnd.oasis.opendocument.spreadsheet', 'zip',
        'application/zip', 'application/x-zip', 'application/x-zip-compressed'];

    constructor(
        public dialogRef: MatDialogRef<DocumentoRequerimentoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DocumentoRequerimento,
        private documentoRequerimentoService: DocumentoRequerimentoService,
        private formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        private juntadaProcessoService: JuntadaProcessoService,
        private motivoJuntadaService: MotivoJuntadaService
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            titulo: [null, [Validators.required, Validators.maxLength(200)]],
            sigiloso: [null],
            motivoJuntada: [null, [Validators.required, Validators.maxLength(100)]],
            justificativa: [null, [Validators.required, Validators.maxLength(2000)]]
        });
        this.buscaMotivosJuntada();
    }

    onFileSelect(event): void {
        const files = event.target.files;

        if (this.data.itemTermoReferenciaRequerimento) {

            if (!this.validarExtensao(files[0].type)) {
                this.snackBarService.showAlert(`Formato de arquivo inválido.\nExtensões de arquivos válidos: PDF, XLS, XLSX, ODS, ZIP`);
                return;
            }
            const descricaoTamanhoMB = this.descricaoFromTamanhoMB(this.data.itemTermoReferenciaRequerimento.itemTermoReferencia.limiteMB);
            if (FileValidationUtil.invalidSize(files[0], FileValidationUtil.size(descricaoTamanhoMB))) {
                this.snackBarService.showAlert(`Arquivo com tamanho inválido, o tamanho limite é de ${descricaoTamanhoMB}.`);
                document.getElementById('input-arquivo')['value'] = null;
                return;
            }
        }

        if (files.length === 1) {
            this.arquivo = files[0];
            this.data.dataUpload = new Date();
        } else {
            this.arquivo = null;
        }
    }

    private validarExtensao(type: string): boolean {
        return this.extensoes.some(
            tipoArquivo => tipoArquivo === type
        );
    }

    onNoClick(): void {
        this.dialogRef.close(null);
    }

    async confirm(): Promise<void> {

        if (this.data.tipo === 'ITEM_TERMO_REFERENCIA') {

            this.data.titulo = this.form.get('titulo').value;
            this.data.sigiloso = this.form.get('sigiloso').value;
            (this.data.id === null
                    ? this.documentoRequerimentoService.createMultipart(
                        this.data,
                        this.arquivo
                    )
                    : this.documentoRequerimentoService.updateMultipart(
                        this.data,
                        this.arquivo
                    )
            ).subscribe(
                () => {
                    this.dialogRef.close(true);
                },
                (e) => {
                    this.snackBarService.showError(
                        'Não foi possível salvar o registro.', e);
                }
            );
        } else if (this.data.tipo === 'JUNTADA_PROCESSO') {

            if (this.form.valid) {
                if (this.arquivo) {
                    const juntadaProcesso = new JuntadaProcesso();
                    const dataHj = new Date();

                    juntadaProcesso.motivoJuntada = this.form.value.motivoJuntada;
                    juntadaProcesso.titulo = this.form.value.titulo;
                    juntadaProcesso.justificativa = this.form.value.justificativa;
                    juntadaProcesso.requerimentoPrincipal = this.data.requerimento;
                    juntadaProcesso.sigiloso = this.form.value.sigiloso === true ? 'S' : 'N';
                    juntadaProcesso.dataCadastro = dataHj;
                    juntadaProcesso.arquivo = this.arquivo;

                    const formData = new FormData();
                    formData.append('file', this.arquivo);
                    const juntadaProcessoDTO = new Blob([JSON.stringify(juntadaProcesso)], {type: 'application/json'});
                    formData.append('juntadaProcessoDTO', juntadaProcessoDTO);

                    this.juntadaProcessoService.emitirJuntadaProcesso(formData).subscribe(result => {
                        juntadaProcesso.id = result.id;
                        this.dialogRef.close(juntadaProcesso);
                        this.snackBarService.showSuccess('Juntada Processo salva com sucesso');
                    });
                } else {
                    this.snackBarService.showAlert('Adicionar arquivo comprovatorio');
                }
            } else {
                this.form.markAllAsTouched();
                this.snackBarService.showError('Preencha os campos corretamente');
            }
        }
    }

    buscaMotivosJuntada(): void {
        this.motivoJuntadaService.findAllMotivosJuntada().subscribe(result => {
            this.motivosJuntada = result;
        });
    }

    descricaoFromTamanhoMB(tamanhoMB: string): string {
        return ItemTermoReferencia.limitesMB.find(t => t.valor === tamanhoMB).descricao;
    }

}
