import {Injectable, Injector} from '@angular/core';
import {ListResolver} from '../resolvers/list-resolver';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {InfraObraFluvSaneam} from "../models/infra-obra-fluv-saneam.model";
import {BaseInfraObraFluvSaneam} from "./base-infra-obra-fluv-saneam.service";


@Injectable()
export class InfraObraFluvSaneamListResolver extends ListResolver<InfraObraFluvSaneam> {
    constructor(service: InfraObraFluvSaneamService) {
        super(service);
    }
}

@Injectable()
export class InfraObraFluvSaneamResolver extends DetailResolver<InfraObraFluvSaneam> {
    constructor(
        service: InfraObraFluvSaneamService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class InfraObraFluvSaneamService extends BaseInfraObraFluvSaneam<InfraObraFluvSaneam> {

    constructor(protected injector: Injector) {
        super(
            "/api/serv-infr-obra-fluv-saneamento",
            injector,
            InfraObraFluvSaneam.prototype,
            InfraObraFluvSaneam.fromJson
        );
    }

    getByRequerimento(idRequerimento: number): Observable<InfraObraFluvSaneam> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
