import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import {
    TermoReferenciaEspecificoRules
} from 'app/main/pages/tarefas-bpms/termo-referencia-especifico/shared/termo-referencia-especifico-rules.service';
import { BaseModel } from 'app/main/shared/models/base.model';
import { RequerimentoArquivoSimcarDTO } from 'app/main/shared/models/requerimento-arquivo-simcar-dto.model';
import { Tarefa } from 'app/main/shared/models/tarefa.model';
import { LocalStorageService } from 'app/main/shared/services/local-storage.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import {
    ConfirmarExclusaoDialogComponent,
    ExcludeData
} from '../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import { PdfViewerDialog } from '../../../../shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import { DocumentoImovel } from '../../../../shared/models/documento-imovel.model';
import { DocumentoTemplate } from '../../../../shared/models/documento-template.model';
import { Municipio } from '../../../../shared/models/geoportal-base-referencia/municipio.model';
import { Pessoa } from '../../../../shared/models/pessoa.model';
import { ArquivoService } from '../../../../shared/services/arquivo.service';
import { DocumentoImovelService } from '../../../../shared/services/documento-imovel.service';
import { MunicipioService } from '../../../../shared/services/geoportal-base-referencia/municipio.service';
import { PessoaCorporativoService } from '../../../../shared/services/pessoa-corporativo.service';
import { SnackBarService } from '../../../../shared/snack-bar/snack-bar.service';
import { FormUtil } from '../../../../shared/util/form-util';
import { DocumentoRequerimento } from '../../../documentos-requerimentos/documento-requerimento.model';
import { DocumentoRequerimentoService } from '../../../documentos-requerimentos/documento-requerimento.service';
import { FormaOcupacao } from '../../../formas-ocupacao/forma-ocupacao.model';
import { FormaOcupacaoService } from '../../../formas-ocupacao/forma-ocupacao.service';
import { TipoProcessoEnum } from '../../../tipos-processo/tipo-processo.enum';
import { EnumInstanciaRequerimento } from '../../menu-novo-requerimento/instancia-requerimento.enum';
import { RequerimentoService } from '../../requerimento.service';
import { BaseTabRequerimentoComponent } from '../base-requerimento.component';
import {
    AlertAbaRequerimento,
    RequerimentoAlertDialogComponent
} from '../requerimento-alert-dialog/requerimento-alert-dialog.component';
import { ErrosAbaRequerimento } from '../requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { RequerimentoSteps } from '../requerimento.steps';
import { RelacaoImoveisEncontradosDialogComponent } from './shared/dialog/relacao-imoveis-encontrados-dialog.component';
import { ImovelPessoa } from './shared/imovel-pessoa.model';
import { ImovelPessoaService } from './shared/imovel-pessoa.service';
import { Imovel } from './shared/imovel.model';
import { ImovelService } from './shared/imovel.service';
import { ViacepService } from './shared/viacep.service';
import {
    DialogAjudaRequerimentoOutorgaComponent
} from '../../requerimento-outorga/dialog-ajuda-requerimento-outorga/dialog-ajuda-requerimento-outorga.component';
import {
    DialogAjudaFormaOcupacaoComponent
} from './shared/dialog-ajuda-forma-ocupacao/dialog-ajuda-forma-ocupacao.component';


@Component({
    selector: 'app-requerimento-localizacao',
    templateUrl: 'requerimento-localizacao.component.html',
    styleUrls: ['requerimento-localizacao.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class RequerimentoLocalizacaoComponent extends BaseTabRequerimentoComponent implements OnInit {
    protected activatedRoute: ActivatedRoute;

    /* Consulta geral */
    municipios: Municipio[];
    formasOcupacao: Array<FormaOcupacao>;
    municipioConsulta = new FormControl(null, [Validators.required]);
    iptuConsulta = new FormControl(null, [Validators.required]);
    numeroCadastroSigaConsulta = new FormControl(null, [Validators.required]);
    numeroCar = new FormControl(null, [Validators.required, Validators.pattern('^MT[0-9]{1,}\\/([2-9]0[1-9][2-9]|[2-9]0[2-9][0-9]|[2-9][1-9][0-9]{2}|[3-9][0-9]{3})$')]);
    documentos: DocumentoImovel[];
    compareWithMunicipio = Municipio.compareWith;

    /* Aba imóvel */
    imovel: Imovel;
    imovelList: any[] = [];
    form: FormGroup;
    resultadoConsultaTodosImoveis: Imovel[] = [];
    imovelConsultadoSimcar: any;
    formImovelPrincipal: FormGroup;
    mostrarTabelaMultCars: boolean = true;
    mostrarBotaoAdicionarImovel: boolean = false;
    isVisualizarImovelCadastrado: boolean = true;
    mostrarFormImovelPrincipal: boolean = false;
    isCriacaoImovel: boolean = true;
    habilitaSelecaoMunicipio: boolean = true;
    habilitarConsultaResponsavel: boolean = true;
    habilitarDivConsultaCar: boolean = true;
    habilitarBotaoSalvarImovelUrbanoObra: boolean = true;
    readonly: boolean = true;
    isNaoPodeEditar: boolean = false;

    //Variavel de controle para objetivo "Alteração de responsáveis e documentos"
    habilitarAlteracaoResponsaveisDocumentos: boolean = false;

    /* Aba responsáveis */
    formaOcupacao: FormaOcupacao = null;
    especificaoOcupacao = '';
    cpfCnpjResponsavelControl = new FormControl('', [Validators.required]);
    maskInputConsulta = '000.000.000-009';
    resultadoConsultaResponsaveis: Pessoa[] = [];
    responsaveisAdicionados: ImovelPessoa[] = [];
    responsaveisAdicionadosOriginal: ImovelPessoa[] = null;
    arquivos: RequerimentoArquivoSimcarDTO[];

    documentosTemplate: DocumentoTemplate[] = [];
    protected formBuilder: FormBuilder = new FormBuilder();
    selectedIndex = 0;
    private isRequerimentoSubterraneo: boolean;
    textoObra = '';
    textoLabelObra = '';

    private tipoImovelPrev: any = null;
    private tipoImovelAtual: any = null;

    isCepValido: boolean;
    isValidate: boolean;

    formatoCoordenadasImovelPrincipal = new FormControl('latLng');
    maskInputLatLngImovelPrincipal = '';

    formatoCoordenadasInfoGeograficas = new FormControl('latLng');
    maskInputLatLngInfoGeograficas = '';

    coordenadasFormadoGMS = new FormControl(null);
    coordenadasImovelPrincipalFormadoGMS = new FormControl(null);

    constructor(
        protected imovelService: ImovelService,
        protected municipioService: MunicipioService,
        protected pessoaCorporativoservice: PessoaCorporativoService,
        protected documentoImovelService: DocumentoImovelService,
        protected imovelPessoaService: ImovelPessoaService,
        protected formaOcupacaoService: FormaOcupacaoService,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected injector: Injector,
        protected requerimentoService: RequerimentoService,
        protected arquivoService: ArquivoService,
        protected pdfViewerDialog: PdfViewerDialog,
        protected documentoRequerimentoService: DocumentoRequerimentoService,
        protected cdr: ChangeDetectorRef,
        protected cepService: ViacepService,
        private localStorageService: LocalStorageService,
        private termoReferenciaEspecificoRules: TermoReferenciaEspecificoRules
    ) {
        super(injector);
        RequerimentoSteps.LOCALIZACAO.component = this;
        this.activatedRoute = injector.get(ActivatedRoute);
        this.buildResourceForm();

        if (this.localStorageService.get('currentTask') && this.localStorageService.get('currentTask').id) {
            this.termoReferenciaEspecificoRules.tarefa = this.localStorageService.get('currentTask') as Tarefa;
            this.termoReferenciaEspecificoRules.requerimento = this.requerimento || this.localStorageService.get('requerimento');
        }
    }

    comparadorPorId = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b;

    ngOnInit(): void {
        this.helper
        .onCurrentStepChange()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(async (e) => {
            if (e.newTabIndex === RequerimentoSteps.LOCALIZACAO.index) {
                await this.listarMunicipios();
                await this.carregarImovel();
                this.listarFormaOcupacoes();
                this.setFormState();
                this.cpfCnpjResponsavelControl.valueChanges.subscribe(() => {
                    this.maskInputConsulta = (this.cpfCnpjResponsavelControl.value && this.cpfCnpjResponsavelControl.value.length > 11)
                        ? '00.000.000/0000-00' : '000.000.000-009';
                });

                if (this.requerimento.tipoProcesso.isOutorga()) {
                    this.requerimentoService.ehRequerimentoSubterraneo(this.requerimento.id).subscribe(result => this.isRequerimentoSubterraneo = result);
                }

                this.form.get('anexo').valueChanges.subscribe(file => {
                    if (file instanceof File) {
                        const docRequerimento: DocumentoRequerimento = new DocumentoRequerimento();
                        docRequerimento.requerimento = this.requerimentoOutorga.requerimento;
                        docRequerimento.tipo = this.isRedeDeAbastecimentoPublico() ? 'DECLARACAO_ABASTECIMENTO' : 'DECLARACAO_NAO_ABASTECIMENTO';
                        this.documentoRequerimentoService.createMultipart(docRequerimento, file).subscribe(result =>
                            this.form.get('anexo').setValue(result), e => {
                            this.snackBarService.showError('Erro ao fazer upload do arquivo', e);
                            this.form.get('anexo').reset();
                        });
                    }
                });
            }
            this.controleCrudImovel();
        });
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO) {
            this.regrasDeRefinamento();
        } else {
            this.textoLabelObra = 'Onde está localizado sua atividade/empreendimento?';
            this.textoObra = 'Atividade/Estudo/Obra de infraestrutura (com interferência em vários imóveis)';
        }

        this.isCepValido = true;

        this.verificaSePodeEditar();
    }

    get isEmCorrecao(): boolean {
        return this.requerimento && this.requerimento.situacaoProcesso === 'EM_CORRECAO';
    }

    get isPermiteEdicaoImovel(): boolean {
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.RECURSO_HIDRICO
            || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_ADESAO) {
            return this.requerimento && !this.isSomenteVisualizacao;
        } else {
            return !this.requerimento.tipoRequerimento.isFluxoAlterar 
                                && !this.requerimento.tipoRequerimento.isFluxoReabilitar
                                && !this.requerimento.tipoRequerimento.isFluxoCancelar
                                && !this.requerimento.tipoRequerimento.isFluxoTransferir;
        }
    }

    isPermiteRemocaoImovel(item: any): boolean {
        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.RECURSO_HIDRICO
            || this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.LICENCIAMENTO_ADESAO) {
            return  ((this.requerimento && !this.isSomenteVisualizacao) || (this.habilitarDivConsultaCar && this.currentAction !== 'detail'))
                && !(this.requerimento.situacaoProcesso === 'EM_CORRECAO' && item.imovelPrincipal == true);
        } else {
            return !this.requerimento.tipoRequerimento.isFluxoAlterar 
                                && !this.requerimento.tipoRequerimento.isFluxoReabilitar
                                && !this.requerimento.tipoRequerimento.isFluxoCancelar
                                && !this.requerimento.tipoRequerimento.isFluxoTransferir;
        }
    }

    //(!isSomenteVisualizacao || (habilitarDivConsultaCar && this.currentAction !== 'detail')) && !(isEmCorrecao && this.item.imovelPrincipal == true)

    isPreExistente(imovel: ImovelPessoa): boolean {
        return this.responsaveisAdicionadosOriginal.some(x => x.id === imovel.id);
    }

    isSomenteVisualizacaoEspecifico(): boolean {
        return this.requerimento && this.requerimento.tipoProcesso.id == TipoProcessoEnum.LICENCIAMENTO_TRIFASICO_LAS
            && (this.requerimento.tipoRequerimento.isFluxoRenovar || this.requerimento.tipoRequerimento.isFluxoTransferir
                || this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoReabilitar
                || this.requerimento.tipoRequerimento.isFluxoAmpliacao || (this.requerimento.tipoRequerimento.isFluxoAlterar &&
                    this.imovel.tipo == 'RURAL' && this.imovel.situacao != 'CANCELADO'));
    }

    private regrasDeRefinamento() {
        if (this.requerimento.idTipoRequerimentoTifLas == 1 ||
            this.requerimento.idTipoRequerimentoTifLas == 3) {
            this.textoLabelObra = 'obra de infraestrutura (com interferência em imóveis)';
            this.textoObra = 'Atividade/Autorização/Estudo com interferência em vários imóveis';
        } else {
            this.textoLabelObra = 'Onde está localizado sua atividade/empreendimento?';
            this.textoObra = 'Atividade/Estudo/Obra de infraestrutura (com interferência em vários imóveis)';
        }
    }

    private carregarImovel() {
        if (this.requerimento && this.requerimento.numeroProtocoloVinculado) {
            this.imovelService.getByRequerimentoVinculadoNumeroERequerimentoId(this.requerimento.numeroProtocoloVinculado, this.requerimento.id)
            .subscribe(async (imovelVinculado) => {
                if (imovelVinculado) {
                    this.habilitaSelecaoMunicipio = !this.requerimento.tipoRequerimento.isFluxoRenovar;
                    this.imovel = imovelVinculado.find(() => true);
                    this.imovel.municipio = this.imovel.municipio || (await this.municipioService.getByCodigoIbge(this.imovel.codigoIbgeMunicipio).toPromise());
                    this.patchImovel(this.imovel);
                    this.controleImovelPrincipal();
                    this.regrasSuimisProtocoloLp();
                } else {
                    this.imovel = null;
                }

                this.cdr.markForCheck();
            });
        } else {
            this.imovelService.getByRequerimento(this.requerimento).subscribe(imovel => {
                this.imovel = imovel;
                if (this.imovel) {
                    this.patchImovel(this.imovel);
                    this.convertCoordenadasParaGMSImovelPrincipal();
                    this.convertCoordenadasParaGMSImovel();
                }
                this.cdr.markForCheck();
            });
        }
    }

    regrasSuimisProtocoloLp() {
        if (this.regrasSuimis == 'suimisFluxoAlteracaoComProtocoloLp') {
            if (this.imovel.tipo == 'URBANO') {
                this.habilitaSelecaoMunicipio = false;
            }
        } else if (this.regrasSuimis == 'suimisFluxoRenovacaoComProtocoloLp') {
            this.habilitarDivConsultaCar = false;
        }
    }

    private patchImovel(imovel: Imovel) {
        if (imovel) {
            this.form.patchValue(imovel);
            this.setFormState();
            this.validarFormTipoImovel(imovel.tipo);
            this.listarResponsaveisAdicionados();
            if (imovel.tipo === 'URBANO') {
                this.listarDocumentos();
            } else if (imovel.tipo === 'RURAL') {
                this.listarTodosImoveis().then(r => r);
                this.controleImovelPrincipal();
            }
            this.controleCrudImovel();
        }
    }

    private disableTipo(): void {
        const tipoControl = this.form.get('tipo');
        tipoControl.disable({emitEvent: false});
    }

    private enableTipo(): void {
        this.form.get('tipo').enable({emitEvent: false});
    }

    private async listarMunicipios(): Promise<void> {

        await this.municipioService
        .getAllByUf(environment.UF_PADRAO)
        .toPromise().then((municipios) => {
            this.municipios = municipios;
        });

    }

    listarFormaOcupacoes(): void {
        this.formaOcupacaoService.getAll().subscribe((formasOcupacao) => {
            this.formasOcupacao = formasOcupacao;
        });
    }

    private async listarTodosImoveis() {
        this.resultadoConsultaTodosImoveis = await this.imovelService.buscarTodosPorRequerimento(this.requerimento.id).toPromise();
        if (this.resultadoConsultaTodosImoveis.length == 0) {
            //this.onChangeTipoImovel();
            this.imovelPrincipalChange('NAO');
        }
    }

    private listarDocumentos(): void {
        if (this.imovel) {
            this.documentoImovelService
            .getByImovelUrbano(this.imovel)
            .subscribe((documentos) => {
                this.documentos = documentos;
            });
        } else {
            this.snackBarService.showAlert('Não existe nenhum imóvel adicionado ao requerimento.');
        }
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            idRequerimento: [null],
            matricula: [null],
            iptu: [null],
            numeroCadastroSiga: [null],
            tipo: [null, [Validators.required]],
            denominacao: [
                null,
                [Validators.required, Validators.maxLength(200)],
            ],
            utilidadePublicaOuInteresseSocial: [null],
            area: [null, [Validators.maxLength(50)]],
            municipio: [null, [Validators.required]],
            endereco: [null, [Validators.required, Validators.maxLength(255)]],
            bairro: [null, [Validators.required, Validators.maxLength(255)]],
            complemento: [null, [Validators.maxLength(255)]],
            roteiroLocalizacao: [null],
            cep: [null, [Validators.required, Validators.maxLength(10)]],
            enderecoEmpreendimento: [null, [Validators.maxLength(255)]],
            unidadeMedida: ['M2', [Validators.maxLength(3)]],
            numeroCAR: [null],
            cadastroCompleto: [null],
            atividade: [null],
            imovelPrincipal: [null],
            abastecimentoPublico: [null],
            numeroMatriculaAbastecimento: [''],
            anexo: [null],
            situacao: [null],
            latitude: [null],
            longitude: [null],
        });
        this.form.get('latitude').disable();
        this.form.get('longitude').disable();
        this.form.patchValue({idRequerimento: this.requerimento.id});

        this.formImovelPrincipal = this.formBuilder.group({
            imovelPrincipal: [null, [Validators.required]],
            municipio: [null, [Validators.required]],
            endereco: [null, [Validators.required, Validators.maxLength(255)]],
            bairro: [null, [Validators.required, Validators.maxLength(255)]],
            complemento: [null, [Validators.required, Validators.maxLength(255)]],
            cep: [null, [Validators.required, Validators.maxLength(10)]],
            latitude: [null],
            longitude: [null],
            latitudeGMS: [null],
            longitudeGMS: [null],
        });
        this.formImovelPrincipal.get('latitude').disable();
        this.formImovelPrincipal.get('longitude').disable();
        this.formImovelPrincipal.get('latitudeGMS').disable();
        this.formImovelPrincipal.get('longitudeGMS').disable();

        this.form.valueChanges.subscribe((v) => {
            if (this.tipoImovelPrev != this.tipoImovelAtual) {
                this.tipoImovelPrev = this.tipoImovelAtual;
            }
            this.tipoImovelAtual = this.form.get('tipo').value;
        });
    }

    setImovelValidators(): void {
        const iptuControl = this.form.get('iptu');
        const numeroCAR = this.form.get('numeroCAR');
        const roteiroLocalizacao = this.form.get('roteiroLocalizacao');
        const matriculaControl = this.form.get('matricula');
        const utilidadePublicaOuInteresseSocialControl = this.form.get('utilidadePublicaOuInteresseSocial');
        this.form.get('tipo').valueChanges.subscribe(
            tipo => {
                if (tipo === 'URBANO') {
                    iptuControl.setValidators([Validators.required, Validators.maxLength(50)]);
                    matriculaControl.setValidators([Validators.maxLength(50)]);
                    utilidadePublicaOuInteresseSocialControl.setValidators(null);
                    numeroCAR.setValidators(null);
                    roteiroLocalizacao.setValidators(null);
                    this.setFormState();
                }

                if (tipo === 'OBRA') {
                    iptuControl.setValidators(null);
                    matriculaControl.setValidators(null);
                    utilidadePublicaOuInteresseSocialControl.setValidators(Validators.required);
                    numeroCAR.setValidators(null);
                    roteiroLocalizacao.setValidators(null);
                    this.setFormState();
                }

                if (tipo === 'RURAL') {
                    utilidadePublicaOuInteresseSocialControl.setValidators(null);
                    numeroCAR.setValidators(Validators.required);
                    matriculaControl.setValidators(null);
                    roteiroLocalizacao.setValidators(Validators.required);
                    this.setFormState();
                }

                iptuControl.updateValueAndValidity();
                matriculaControl.updateValueAndValidity();
                utilidadePublicaOuInteresseSocialControl.updateValueAndValidity();
                this.form.updateValueAndValidity();
            }
        );
    }

    private setFormState(): void {
        if ((this.imovel && this.imovel.tipo === 'RURAL') || this.isSomenteVisualizacao || this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS" && this.requerimento.numeroProtocoloVinculado) {
            this.form.disable({emitEvent: false});
        } else if (this.requerimento.tipoProcesso.id === 42 &&
            this.requerimento.numeroProtocoloVinculado) {
            this.regrasNatoDigital();
        } else {
            this.form.enable({emitEvent: false});
        }

        if (this.tipoImovel == 'RURAL' && this.isImovelPrincipal) {
            this.formImovelPrincipal.disable({emitEvent: false});
        }

        if (this.imovel != null && this.imovel.id != null) {
            this.disableTipo();
        } else {
            if (this.tipoImovel != 'RURAL') {
                this.enableTipo();
            }
        }

        if (this.tipoImovel == 'OBRA' && this.requerimento.tipoRequerimento.isFluxoRenovar && this.currentAction !== 'detail') {
            this.form.get('denominacao').enable();
            this.form.get('utilidadePublicaOuInteresseSocial').enable();
            this.form.get('area').enable();
            this.form.get('unidadeMedida').enable();
            this.form.get('cep').enable();
            this.form.get('endereco').enable();
            this.form.get('bairro').enable();
            this.form.get('complemento').enable();
            this.form.get('municipio').enable();
            this.habilitaSelecaoMunicipio = true;
        }

        if (this.requerimento.tipoProcesso.instanciaRequerimento === EnumInstanciaRequerimento.TERMO_REFERENCIA
            && this.requerimento.numeroProtocoloVinculado) {
            this.form.disable();
        }

        if (this.currentAction !== 'detail') {
            this.aplicaRegrasDeObjetivo();
        }

        this.verificaSePodeEditar();
    }

    private verificaSePodeEditar() {
        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoReabilitar || this.requerimento.tipoRequerimento.isFluxoRenovar) {
            if (!this.isRequerimentoProtocoloLegado()) {
                this.isNaoPodeEditar = true;
                this.form.disable();
                this.formImovelPrincipal.disable();
            }
        }
    }

    private regrasNatoDigital() {
        if (this.requerimento.tipoRequerimento.id === 4 ||
            this.requerimento.tipoRequerimento.id === 5 ||
            this.requerimento.tipoRequerimento.id === 32 ||
            this.requerimento.tipoRequerimento.id === 6) {
            this.form.disable({emitEvent: false});
        }
    }

    private aplicaRegrasDeObjetivo() {
        if (this.requerimento.idTipoRequerimentoTifLas === 2) {
            if (this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 3)
                || this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 2)) {
                this.enableTipo();
                this.regraDesabilitaMunicipio();
            } else if (this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('LP - Licença Prévia de ampliação'.toLowerCase()))) {
                if (this.imovel.tipo == 'OBRA' || this.imovel.tipo == 'URBANO') {
                    this.habilitarBotaoSalvarImovelUrbanoObra = true; //RN027
                    this.form.enable(); //RN027
                    this.habilitaSelecaoMunicipio = this.imovel.tipo == 'OBRA'; //RN027 - desabilita alteração municipio no URBANO e habilita no tipo OBRA
                } else {
                    if (this.imovel.situacao != 'CANCELADO') {
                        this.habilitarDivConsultaCar = false;
                        this.habilitaSelecaoMunicipio = false;
                    }
                }
            } else if (this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 8) &&
                this.objetivoLicenciamento.length === 1 ||
                this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 8) &&
                this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 6)) {
                this.enableTipo();
                this.regraDesabilitaMunicipio();
            } else if (this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 6) &&
                this.objetivoLicenciamento.length === 1 ||
                this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 6) &&
                this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 7)) {
                this.enableTipo();
                this.regraDesabilitaMunicipio();
            } else if (this.objetivoLicenciamento.length === 1
                && this.objetivoLicenciamento.find(obj => obj.objetivo.tipoTitulacao.id === 7)) {
                this.regraDesabilitaMunicipio();
            } else if (this.objetivoLicenciamento.length === 1 &&
                this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('LOPM - Licença de Operação de Pesquisa Mineral de ampliação'.toLowerCase()))) {
                this.enableTipo();
                this.form.enable({emitEvent: false}); //RN028
                this.habilitarBotaoSalvarImovelUrbanoObra = true; //RN028
                if (this.imovel.tipo == 'URBANO') {
                    this.habilitaSelecaoMunicipio = false;
                } //RN027
            } else if (this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação LOPM - Licença de Operação de Pesquisa Mineral'.toLowerCase()))) {
                if (this.imovel.tipo == 'URBANO' || this.imovel.tipo == 'OBRA') {
                    this.habilitarBotaoSalvarImovelUrbanoObra = false; //RN030
                    this.form.disable(); //RN030
                    this.habilitaSelecaoMunicipio = false; //RN030
                }
            }

            //Validando o Objetivo "Alteração de documentos e responsáveis" separadamente pois o mesmo pode ser selecionado
            //juntamente a outros objetivos.
            if (this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Alteração de documentos e responsáveis'.toLowerCase()))) {
                this.habilitarAlteracaoResponsaveisDocumentos = true;
                if (this.requerimento.tipoRequerimento.isFluxoAlterar && this.imovel.tipo == 'RURAL' && this.imovel.situacao != 'CANCELADO') {
                    this.habilitarDivConsultaCar = false;
                    this.habilitaSelecaoMunicipio = false;
                }
            }
            this.aplicaRegrasOjetivos();
        }
    }

    isDesabilitaInsercaoOrExclusao(): boolean {
        return this.isSomenteVisualizacao 
            || this.requerimento.tipoRequerimento.isFluxoCancelar 
            || this.requerimento.tipoRequerimento.isFluxoTransferir
            || this.requerimento.tipoRequerimento.isFluxoReabilitar; 
    }

    isDesabilitaConsultaResponsaveisAssociados(): boolean {
        //validação remoida do frontEnd -- (!this.habilitarConsultaResponsavel && !this.habilitarAlteracaoResponsaveisDocumentos) || this.currentAction !== 'detail'
        return this.isSomenteVisualizacao 
            || this.requerimento.tipoRequerimento.isFluxoCancelar 
            || this.requerimento.tipoRequerimento.isFluxoReabilitar; 
    }

    private aplicaRegrasOjetivos() {
        if (this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de LP - Licença Prévia'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de LI - Licença de Instalação'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de LO - Licença de Operação'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização Queima Controlada'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização de Desmate'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização de Picada'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização de Coleta e Captura de Fauna Silvestre'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização diversa'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Solicitação de para unidade volante'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Estudo preliminar/Laudos'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de Autorização de licença de pesca especial'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização Queima Controlada'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização de Desmate'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização Picada'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização de Coleta e Captura de Fauna Silvestre'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização diversa'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização de teste'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Solicitação de para unidade volante'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Estudo preliminar/Laudos'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Autorização de licença de pesca especial'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Pedido de dispensa de EIA/RIMA'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Alteração de documentos e responsáveis'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Arquivamento de processo'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Suspensão do processo'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Suspensão da atividade'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Desativação da atividade'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Desmobilização da atividade'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Encerramento total da atividade total'.toLowerCase()))
            || this.objetivoLicenciamento.find(obj => obj.objetivo.objetivo.descricao.toLowerCase().includes('Alteração de documentos e responsáveis'.toLowerCase()))) {
            this.regraDesabilitaMunicipio();
        }
    }

    private regraDesabilitaMunicipio() {
        // this.carregaMunicipio = false
        if (this.form.get('tipo').value === 'URBANO') {
            this.form.get('municipio').disable();
            // this.carregaMunicipio = false
        } else {
            // this.carregaMunicipio = true
            this.form.get('municipio').enable();
        }

        // TEM REQUERIMENTO VINCULADO ATIVA COMBO MUNICIPIO
        if (this.requerimento && this.requerimento.numeroProtocoloVinculado) {
            this.form.get('municipio').enable();
        }
    }

    async buscarImovel(): Promise<void> {
        //Validação para inserção de imoveis rurais para LAC - tarefa 6970
        if (this.requerimento.tipoProcesso.id === 42 && this.resultadoConsultaTodosImoveis.length > 0) {
            this.snackBarService.showAlert('Para o tipo de solicitação Licenciamento por Adesão e Compromisso - LAC, não é permitida ' +
                'a inserção de mais de um CAR.');
            return;
        }

        if (this.tipoImovel === 'URBANO') {
            await this.buscarImovelUrbano();
        }
        if (this.tipoImovel === 'RURAL') {
            //Bugfix#8165
            if (!this.numeroCar.value) {
                this.snackBarService.showAlert('O Número de registro do CAR não informado.');
                return;
            } else {
                this.buscarImovelRural();
            }
        }
    }

    private async buscarImovelUrbano(): Promise<void> {
        this.iptuConsulta.markAllAsTouched();
        this.municipioConsulta.markAllAsTouched();
        if (this.iptuConsulta.valid || this.numeroCadastroSigaConsulta.valid) {
            if (!this.municipioConsulta.valid) {
                this.snackBarService.showError('Municipio não encontrado.');
            } else {
                this.imovelList = await this.imovelService.getByIptuAndCodigoIbgeMunicipio(this.iptuConsulta.value, this.numeroCadastroSigaConsulta.value, this.municipioConsulta.value.codigoIbge).toPromise();
                if (this.imovelList.length > 0) {
                    let imovel: Imovel;
                    if (this.imovelList.length === 1) {
                        imovel = this.imovelList[0];

                        if (this.imovel) {
                            imovel['id'] = this.imovel.id;
                        } else {
                            imovel['id'] = null;
                        }

                        this.form.patchValue(imovel);
                        this.form.patchValue({idRequerimento: this.requerimento.id});

                        await this.tratarDoc(imovel.anexo);

                        this.isCepValido = this.form.get('cep').value === imovel['cep'] &&
                            this.form.get('municipio').value.codigoIbge === imovel['municipio'].codigoIbge;

                        this.snackBarService.showSuccess('Imóvel urbano encontrado.');
                    } else {
                        // Evolutiva #8432
                        await this.verificarRelacaoImoveisEncontrados(this.imovelList);
                    }
                } else {
                    this.snackBarService.showError('Imóvel urbano não encontrado.');
                }
            }
        } else {
            this.numeroCadastroSigaConsulta.markAllAsTouched();
            this.snackBarService.showError('Imóvel urbano não encontrado. ');
        }
        this.iptuConsulta.reset();
        this.municipioConsulta.reset();
        this.numeroCadastroSigaConsulta.reset();
    }

    async verificarRelacaoImoveisEncontrados(imoveis: any[]): Promise<any> {
        const matDialogRef = await this.dialog.open(RelacaoImoveisEncontradosDialogComponent,
            {width: '700px', data: imoveis});
        matDialogRef.afterClosed().subscribe(async imovel => {
            if (imovel) {
                if (this.imovel) {
                    imovel['id'] = this.imovel.id;
                } else {
                    imovel['id'] = null;
                }
                this.form.patchValue(imovel);
                this.form.patchValue({idRequerimento: this.requerimento.id});

                await this.tratarDoc(imovel.anexo);

                this.isCepValido = this.form.get('cep').value === imovel['cep'] &&
                    this.form.get('municipio').value.codigoIbge === imovel['municipio'].codigoIbge;

                this.snackBarService.showSuccess('Imóvel urbano encontrado.');
            }
        });
    }

    private async buscarImovelRural(): Promise<void> {
        if (this.numeroCar.valid) {
            try {
                this.mostrarTabelaMultCars = false;
                this.imovelConsultadoSimcar = await this.imovelService.buscarImovelRuralSimcar(this.numeroCar.value.replace('/', '-')).toPromise();
                this.imovel = this.imovelConsultadoSimcar.imovel;
                this.atribuirImovelForm(this.imovelConsultadoSimcar.imovel, 'buscaImoveis');
                this.formImovelPrincipal.enable({emitEvent: false});
                this.isVisualizarImovelCadastrado = false;
                this.isCriacaoImovel = true;
                this.mostrarBotaoAdicionarImovel = true;
                this.form.disable({emitEvent: false});
                //this.enableTipo();
                this.listarResponsaveisAdicionados(this.imovelConsultadoSimcar.interessadosImovel);
                this.convertCoordenadasParaGMSImovelPrincipal();
                this.convertCoordenadasParaGMSImovel();
            } catch (e) {
                this.snackBarService.showError('Erro ao buscar o imóvel rural', e);
            }
        } else if (this.numeroCar.hasError('required')) {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        } else if (this.numeroCar.hasError('pattern')) {
            this.snackBarService.showAlert('Número do registro no CAR inválido, exemplo: MT123456/2020.');
        }
    }

    atribuirImovelForm(imovel, origem?: string) {
        this.form.get('id').patchValue(imovel.id);
        this.form.get('idRequerimento').patchValue(imovel.idRequerimento);
        this.form.get('denominacao').patchValue(imovel.denominacao);
        this.form.get('utilidadePublicaOuInteresseSocial').patchValue(imovel.utilidadePublicaOuInteresseSocial);
        this.form.get('area').patchValue(imovel.area);
        this.form.get('municipio').patchValue(imovel.municipio);
        this.form.get('endereco').patchValue(imovel.endereco);
        this.form.get('bairro').patchValue(imovel.bairro);
        this.form.get('complemento').patchValue(imovel.complemento);
        this.form.get('roteiroLocalizacao').patchValue(imovel.roteiroLocalizacao);
        this.form.get('cep').patchValue(imovel.cep);
        this.form.get('enderecoEmpreendimento').patchValue(imovel.enderecoEmpreendimento);
        this.form.get('unidadeMedida').patchValue(imovel.unidadeMedida);
        this.form.get('numeroCAR').patchValue(imovel.numeroCAR);
        this.form.get('cadastroCompleto').patchValue(imovel.cadastroCompleto);
        this.form.get('atividade').patchValue(imovel.atividade);
        this.form.get('imovelPrincipal').patchValue(imovel.imovelPrincipal);
        this.form.get('abastecimentoPublico').patchValue(imovel.abastecimentoPublico);
        this.form.get('numeroMatriculaAbastecimento').patchValue(imovel.numeroMatriculaAbastecimento);
        this.form.get('anexo').patchValue(imovel.anexo);
        this.form.get('situacao').patchValue(imovel.situacao);

        if (origem != 'buscaImoveis') {
            this.formImovelPrincipal.get('imovelPrincipal').patchValue(imovel.imovelPrincipal);
        }
        this.formImovelPrincipal.get('municipio').patchValue(imovel.municipio);
        this.formImovelPrincipal.get('endereco').patchValue(imovel.endereco);
        this.formImovelPrincipal.get('bairro').patchValue(imovel.bairro);
        this.formImovelPrincipal.get('complemento').patchValue(imovel.complemento);
        this.formImovelPrincipal.get('cep').patchValue(imovel.cep);
        this.formImovelPrincipal.get('latitude').patchValue(imovel.latitude);
        this.formImovelPrincipal.get('longitude').patchValue(imovel.longitude);
    }

    formatarCep(): string {
        const cep: string = this.formImovelPrincipal.get('cep').value.replace(/\D/g, '');
        return cep.slice(0, 2) + '.' + cep.slice(2, 5) + '-' + cep.slice(5, 8);
    }

    validarFormNovoImovel(): boolean {
        if (this.formImovelPrincipal.get('imovelPrincipal').valid) {
            const validate = this.resultadoConsultaTodosImoveis.filter(result => result.numeroCAR === this.form.get('numeroCAR').value);
            if (validate.length === 0 || !this.isCriacaoImovel) {
                if (this.formImovelPrincipal.get('imovelPrincipal').value == 'SIM') {
                    console.log("this.formImovelPrincipal >> ", this.formImovelPrincipal);
                    if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL
                        && this.formatoCoordenadasImovelPrincipal.value === 'grau') {
                        this.formImovelPrincipal.get("latitude").patchValue(FormUtil.parseDMSToDecimal(this.formImovelPrincipal.get('latitudeGMS').value));
                        this.formImovelPrincipal.get("longitude").patchValue(FormUtil.parseDMSToDecimal(this.formImovelPrincipal.get('longitudeGMS').value));
                    }
                    if (this.formImovelPrincipal.valid) {
                        return true;
                    } else {
                        this.snackBarService.showAlert('É preciso informar todos os campos do endereço do imóvel rural principal do Requerimento.');
                        return false;
                    }
                } else {
                    return true;
                }
            } else if (this.isCriacaoImovel) {
                this.snackBarService.showAlert('Imóvel informado já pertence a imóveis rurais.');
                return false;
            } else {
                //editando imovel; bugfix - 7908
                return true;
            }
        } else {
            this.snackBarService.showAlert('É preciso informar se o CAR incluído no Requerimento é o principal ou não.');
            return false;
        }
    }

    async adicionarImovelAoRequerimento() {
        await this.validarCep(this.formImovelPrincipal.get('cep').value,
            this.formImovelPrincipal.get('imovelPrincipal').value == 'SIM' ? 'imovelPrincipal' : 'imovelSecundario');
        if (this.validarFormNovoImovel()) {
            if (!this.isCepValido && this.formImovelPrincipal.get('imovelPrincipal').value == 'SIM') {
                this.snackBarService.showAlert('O CEP digitado é inválido ou esta fora do município selecionado.');
                return;
            }
            //BugFix#8168
            let idImovelPrincipalAnterior;
            if (this.resultadoConsultaTodosImoveis.length > 0) {
                //idImovelPrincipalAnterior = this.resultadoConsultaTodosImoveis.filter(imovel => imovel.imovelPrincipal)[0].id;
                this.resultadoConsultaTodosImoveis.filter(imovel => {
                    if (imovel.imovelPrincipal) {
                        idImovelPrincipalAnterior = this.resultadoConsultaTodosImoveis[0].id;
                    }
                });
            }

            //Pegando dados do imovel
            const imovel = this.form.getRawValue();
            //Atribuindo novos valores inseridos no formulario de endereço do imovel principal.
            imovel.imovelPrincipal = this.formImovelPrincipal.get('imovelPrincipal').value == 'SIM';
            imovel.idSimcar = this.imovel.idSimcar;
            imovel.idRequerimento = this.requerimento.id;
            if (this.formImovelPrincipal.get('imovelPrincipal').value == 'SIM') {
                if (this.formImovelPrincipal.valid) {
                    imovel.municipio = this.formImovelPrincipal.get('municipio').value;
                    imovel.endereco = this.formImovelPrincipal.get('endereco').value;
                    imovel.bairro = this.formImovelPrincipal.get('bairro').value;
                    imovel.complemento = this.formImovelPrincipal.get('complemento').value;
                    if (this.formatoCoordenadasImovelPrincipal.value === 'grau') {
                        imovel.latitude = FormUtil.parseDMSToDecimal(this.formImovelPrincipal.get('latitudeGMS').value);
                        imovel.longitude = FormUtil.parseDMSToDecimal(this.formImovelPrincipal.get('longitudeGMS').value);
                    } else {
                        imovel.latitude = this.formImovelPrincipal.get('latitude').value;
                        imovel.longitude = this.formImovelPrincipal.get('longitude').value;
                    }
                    imovel.cep = this.formatarCep();
                }
                await this.convertCoordenadasParaGMSImovel();
            }

            if (!this.isCriacaoImovel) {
                // //Editar imovel
                this.imovelService.update(imovel).toPromise().then(async (data) => {
                    this.formImovelPrincipal.disable({emitEvent: true});
                    await this.listarTodosImoveis();
                    await this.visualizarImovel(data, 'editar');
                    this.snackBarService.showSuccess('Imóvel Rural atualizado com sucesso!');

                    //BugFix#8168
                    if (idImovelPrincipalAnterior) {
                        if (idImovelPrincipalAnterior != this.resultadoConsultaTodosImoveis.filter(imovel => imovel.imovelPrincipal)[0].id) {
                            this.snackBarService.showSuccess('Este imóvel foi definido como sendo o principal em substituição ao anteriormente definido.');
                        }
                    }
                }, e => {
                    this.snackBarService.showError('Erro ao atualizar imóvel rural. ' + e);
                });
            } else {
                //Adicionando novo imovel
                imovel.id = null;
                const imovelRuraSimcarDTO = {interessadosImovel: this.responsaveisAdicionados, imovel: imovel};

                this.imovelService.adicionarImovelAoRequerimento(imovelRuraSimcarDTO, this.requerimento.id).toPromise().then(data => {
                    this.listarTodosImoveis();
                    this.visualizarImovel(data, imovel.imovelPrincipal ? 'editar' : 'somenteVisualizacao');
                    this.snackBarService.showSuccess('Imóvel Rural cadastrado com sucesso!');

                    //BugFix#8168
                    if (idImovelPrincipalAnterior) {
                        if (idImovelPrincipalAnterior != this.resultadoConsultaTodosImoveis.filter(imovel => imovel.imovelPrincipal)[0].id) {
                            this.snackBarService.showSuccess('Este imóvel foi definido como sendo o principal em substituição ao anteriormente definido.');
                        }
                    }
                    this.numeroCar.reset();
                }, e => {
                    this.snackBarService.showError('Erro ao salvar imóvel rural. ' + e);
                });
            }
        }
    }

    // desativarTipoParaImovelRural() {
    //     if (!this.form.get('tipo').value) {
    //         return false;
    //     } else {
    //         if (this.tipoImovel !== 'RURAL') {
    //             return !!this.imovel;
    //         } else {
    //             return this.resultadoConsultaTodosImoveis.length > 0;
    //         }
    //     }
    // }

    controleImovelPrincipal() {
        if (this.isImovelPrincipal) {
            this.mostrarFormImovelPrincipal = true;
            this.formImovelPrincipal.patchValue(this.imovel);
            this.termoReferenciaEspecificoRules.imovel = this.imovel;
        } else {
            this.mostrarFormImovelPrincipal = false;
            this.termoReferenciaEspecificoRules.imovel = this.imovel;
        }
        this.formImovelPrincipal.get('imovelPrincipal').patchValue(this.imovel.imovelPrincipal == true ? 'SIM' : 'NAO');
    }

    async visualizarImovel(imovel: Imovel, acao: string) {
        this.imovel = imovel;
        this.atribuirImovelForm(imovel);
        this.listarResponsaveisAdicionados();
        this.controleImovelPrincipal();
        this.convertCoordenadasParaGMSImovelPrincipal();
        this.convertCoordenadasParaGMSImovel();

        if (acao == 'editar') {

            if (!await this.termoReferenciaEspecificoRules.isPermite_edicao_carPrincipal()) {
                this.snackBarService.showSuccess('Não é possivel alterar imovel do requerimento');
                return;
            }

            this.isCriacaoImovel = false;
            this.isVisualizarImovelCadastrado = false;
            this.mostrarBotaoAdicionarImovel = true;
            this.formImovelPrincipal.enable({emitEvent: false});
            this.form.patchValue(this.imovel);
            this.controleCrudImovel();
        } else {
            this.isCriacaoImovel = true;
            this.isVisualizarImovelCadastrado = true;
            this.mostrarBotaoAdicionarImovel = false;
            this.formImovelPrincipal.disable({emitEvent: false});
            this.controleCrudImovel();
        }
    }

    async removerImovelRural(imovel) {
        if (!await this.termoReferenciaEspecificoRules.isPermite_exclusao_carPrincipal()) {
            this.snackBarService.showSuccess('Não é possivel remover imovel do requerimento');
            return;
        }

        if (this.resultadoConsultaTodosImoveis.length == 1 && (this.requerimento.situacaoProcesso === 'EM_REVISAO' || this.requerimento.situacaoProcesso === 'EM_CORRECAO')) {
            this.snackBarService.showAlert('Requerimento não pode ficar sem um CAR associado. Inserir um  novo CAR antes de excluir o atual.');
            return;
        }


        const alertsAbaRequerimento: AlertAbaRequerimento[] = [];
        alertsAbaRequerimento.push(new AlertAbaRequerimento('Deseja realmente remover este CAR do Requerimento?'));
        const matDialogRef = this.dialog.open(RequerimentoAlertDialogComponent, {
            width: '500px',
            data: {alertsRequerimento: alertsAbaRequerimento}
        });

        matDialogRef.afterClosed().subscribe(confirmou => {
            if (!!confirmou) {
                this.imovelService.delete(imovel.id).toPromise().then(() => {
                    let tipoImovel = this.form.get('tipo').value;
                    this.snackBarService.showSuccess('CAR removido com sucesso.');
                    this.imovel = null;
                    this.formImovelPrincipal.reset();
                    this.form.reset();
                    this.form.get('tipo').setValue(tipoImovel);
                    this.listarTodosImoveis();
                    this.responsaveisAdicionados = [];
                    this.removerInteressadoImovel(imovel);
                    this.controleCrudImovel();


                });
            } else {
                return;
            }

        });
    }

    removerInteressadoImovel(imovel: any): void {
        if (imovel.imovelPrincipal === true) {
            const idTarefa = this.activatedRoute.snapshot.url[3].path;
            this.requerimentoService.deleteInteressado(this.requerimento.id, idTarefa).subscribe();
        }
    }

    imovelPrincipalChange(event) {
        if (event == 'SIM') {
            this.mostrarFormImovelPrincipal = true;
        } else {
            this.mostrarFormImovelPrincipal = false;
        }
        this.controleCrudImovel();
    }

    async salvar(): Promise<void> {
        this.form.patchValue({idRequerimento: this.requerimento.id});
        this.form.markAllAsTouched();
        await this.validarFormTipoImovel(this.form.value.tipo);
        //await this.validarCep(this.form.get('cep').value);
        if (this.form.valid) {
            if (!this.isCepValido) {
                this.snackBarService.showAlert('O CEP digitado é inválido ou esta fora do município selecionado.');
                return;
            }
            if (this.form.get('id').value != null) {
                if (this.imovel !== null && this.imovel.id && !await this.termoReferenciaEspecificoRules.isPermite_edicao_carPrincipal()) {
                    this.snackBarService.showSuccess('Não é possivel alterar imovel do requerimento');
                    return;
                }

                if (this.habilitaRemocaoMunicipio() && this.form.get('municipio').value.codigoIbge !== this.imovel.municipio.codigoIbge) {

                    const alertsAbaRequerimento: AlertAbaRequerimento[] = [];
                    alertsAbaRequerimento.push(new AlertAbaRequerimento('Ao alterar o município todos os dados geográficos, '
                        + 'termos de referência e outras informações vinculadas seram redefinidas. Deseja continuar?'));

                    const matDialogRef = this.dialog.open(RequerimentoAlertDialogComponent, {
                        width: '500px',
                        data: {alertsRequerimento: alertsAbaRequerimento}
                    });
                    matDialogRef.afterClosed().subscribe(confirmou => {
                        if (!!confirmou) {
                            this.atualizarImovel();
                        } else {
                            return;
                        }
                    });
                } else {
                    this.atualizarImovel();
                }
            } else {
                this.form.get('imovelPrincipal').patchValue(true);

                let imovel: Imovel = this.form.getRawValue();

                if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL
                    && this.formatoCoordenadasInfoGeograficas.value === 'grau') {
                    imovel.latitude = FormUtil.parseDMSToDecimal(imovel.latitude).toString();
                    imovel.longitude = FormUtil.parseDMSToDecimal(imovel.longitude).toString();
                }

                this.imovelService.create(imovel).subscribe((data) => {
                    this.snackBarService.showSuccess('Registro salvo com sucesso.');
                    this.form.patchValue(data);
                    this.form.markAsPristine();
                    this.imovel = data;
                    this.disableTipo();
                    // Redirecionar para a aba de responsáveis
                    this.selectedIndex = 1; // aba responsáveis
                }, e => {
                    this.snackBarService.showError(e.error.errors[0] === '' ? 'Erro ao salvar o imóvel.' : e.error.errors[0]);
                });
            }
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    private async atualizarImovel(): Promise<void> {
        if (!await this.termoReferenciaEspecificoRules.isPermite_edicao_carPrincipal()) {
            this.snackBarService.showSuccess('Não é possivel atualizar o imóvel do requerimento');
            return;
        }

        if (this.isCepValido) {
            let imovel: Imovel = this.form.getRawValue();

            if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL
                && this.formatoCoordenadasInfoGeograficas.value === 'grau') {
                imovel.latitude = FormUtil.parseDMSToDecimal(imovel.latitude).toString();
                imovel.longitude = FormUtil.parseDMSToDecimal(imovel.longitude).toString();
            }

            this.imovelService.update(imovel).subscribe((data) => {
                this.form.patchValue(data);
                this.form.markAsPristine();
                this.snackBarService.showSuccess('Registro salvo com sucesso.');
            });
        }
    }

    private habilitaRemocaoMunicipio(): boolean {
        return this.imovel && this.imovel.id && this.imovel.municipio && this.tipoImovel && this.tipoImovel !== 'RURAL';
    }

    listarResponsaveisAdicionados(listaResponsaveis?: any): void {
        if (listaResponsaveis) {
            this.responsaveisAdicionados = listaResponsaveis;
            if (this.responsaveisAdicionadosOriginal == null) {
                this.responsaveisAdicionadosOriginal = [...this.responsaveisAdicionados];
            }
        } else {
            if (this.imovel != null && this.imovel.id !== null) {
                this.imovelPessoaService.getAllByImovelUrbano(this.imovel).subscribe(
                    (result) => {
                        this.responsaveisAdicionados = result;
                        if (this.responsaveisAdicionadosOriginal == null) {
                            this.responsaveisAdicionadosOriginal = [...this.responsaveisAdicionados];
                        }
                    }, () => this.snackBarService.showError('Erro ao listar responsáveis pelo imóvel.')
                );
            }
        }
    }

    visualizarDocumento(documentoImovel: DocumentoImovel): void {
        window.open(
            this.documentoImovelService.urlResource +
            '/' +
            documentoImovel.id +
            '/visualizar'
        );
    }

    downloadDocumento(documentoImovel: DocumentoImovel): void {
        window.open(
            this.documentoImovelService.urlResource +
            '/' +
            documentoImovel.id +
            '/download'
        );
    }

    // consulta na tabela do coorporativo
    pesquisarResponsavel(): void {
        this.cpfCnpjResponsavelControl.markAllAsTouched();
        if (this.cpfCnpjResponsavelControl.valid) {
            if (this.possuiPessoaAdicionada(this.cpfCnpjResponsavelControl.value)) {
                this.snackBarService.showAlert('Este responsável já foi associado ao imóvel.');
            } else {
                this.pessoaCorporativoservice.getByCpfCnpj(this.cpfCnpjResponsavelControl.value).subscribe(
                    (pessoa) => {
                        this.resultadoConsultaResponsaveis = [pessoa];
                        this.especificaoOcupacao = '';
                    }, error => {
                        this.snackBarService.showError('Ero ao pesquisar o responsável', error);
                    }
                );
            }
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    possuiPessoaAdicionada(cpfCnpj: string): boolean {
        return this.responsaveisAdicionados.some(imovel => imovel.pessoa.cpfCnpj === cpfCnpj);
    }

    adicionarResponsavel(responsavel: Pessoa): void {
        if (this.imovel.id == null) {
            this.responsaveisAdicionados.push(new ImovelPessoa(null, responsavel, this.formaOcupacao, this.especificaoOcupacao));
        } else {
            if (this.requerimento.tipoProcesso.tipo == 'LICENCIAMENTO') {
                this.adicionarResp(responsavel);
                return;
            } else if (this.requerimento.idTipoRequerimentoTifLas === 2) {
                if (this.altDocResp) {
                    this.adicionarResp(responsavel);
                    return;
                } else {
                    this.snackBarService.showAlert('Não é possivel adicionar responsáveis nesse requerimento.');
                    return;
                }
            }
            this.adicionarResp(responsavel);
        }
    }

    adicionarResp(responsavel: Pessoa) {
        if (!this.isFormularioRtInvalido()) {
            if (this.tipoImovel !== 'OBRA' && this.formaOcupacao == null) {
                this.snackBarService.showAlert('Nenhuma forma de ocupação foi selecionada.');
                return;
            }
            if (!this.possuiPessoaAdicionada(responsavel.cpfCnpj)) {
                this.imovelPessoaService
                .addToImovel(
                    responsavel,
                    this.form.get('id').value,
                    this.formaOcupacao,
                    this.especificaoOcupacao
                )
                .subscribe(
                    () => {
                        this.snackBarService.showSuccess('Responsável adicionado com sucesso!');
                        this.listarResponsaveisAdicionados();
                        this.listarDocumentos();
                        this.limparCampos();
                    },
                    e =>
                        this.snackBarService.showError('Erro ao adicionar responsável.', e)
                );
            } else {
                this.resultadoConsultaResponsaveis = null;
                this.cpfCnpjResponsavelControl.reset();
                this.cpfCnpjResponsavelControl.markAsUntouched();
                this.cpfCnpjResponsavelControl.updateValueAndValidity();
                this.snackBarService.showAlert('Este responsável já foi associado ao imóvel.');
            }
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    excluirResponsavelDialog(imovelPessoa: ImovelPessoa): void {
        if (imovelPessoa.id == null) {
            this.responsaveisAdicionados = this.responsaveisAdicionados.filter(iPessoa => iPessoa.pessoa.cpfCnpj != imovelPessoa.pessoa.cpfCnpj);
        } else {
            if (this.requerimento.tipoProcesso.tipo == 'LICENCIAMENTO') {
                this.deletarResp(imovelPessoa);
                return;
            } else if (this.requerimento.idTipoRequerimentoTifLas === 2) {
                if (this.altDocResp) {
                    this.deletarResp(imovelPessoa);
                    return;
                } else if ((this.requerimento.tipoProcesso.instanciaRequerimento === "LICENCIAMENTO_TRIFASICO" && !this.altDocResp) || this.requerimento.tipoProcesso) {
                    this.snackBarService.showAlert('Não é possivel deletar responsaveis nesse requerimento.');
                    return;
                }
                this.deletarResp(imovelPessoa);

            } else if (this.requerimento.tipoProcesso.id === 42 && this.requerimento.numeroProtocoloVinculado) {
                this.snackBarService.showAlert('Não é possivel deletar responsaveis nesse requerimento.');
                return;
            }
            this.deletarResp(imovelPessoa);
        }
    }

    deletarResp(imovelPessoa: ImovelPessoa) {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: imovelPessoa.pessoa.nome + (this.requerimento.pessoa && imovelPessoa.pessoa.id === this.requerimento.pessoa.id ? ' (Interessado)' : '')},
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (!!result) {
                this.excluirResponsavel(imovelPessoa.pessoa);
            }
        });
    }

    atualizarListagemDocumentos(documentos: DocumentoImovel[]): void {
        this.documentos = documentos;
    }

    atualizarListagemArquivos(arquivos: RequerimentoArquivoSimcarDTO[]): void {
        this.arquivos = arquivos;
    }

    private excluirResponsavel(pessoa: Pessoa): void {
        const idTarefa = this.activatedRoute.snapshot.url[3].path;
        this.imovelPessoaService
        .removeFromImovel(pessoa, this.imovel, idTarefa)
        .subscribe(
            () => {
                if (this.requerimento.pessoa && this.requerimento.pessoa.id === pessoa.id) {
                    this.requerimento.pessoa = null;
                }
                this.snackBarService.showSuccess('Responsável excluído com sucesso!');
                this.form.markAsDirty();
                this.listarResponsaveisAdicionados();
                this.listarDocumentos();
            },
            e =>
                this.snackBarService.showError('Erro ao excluir responsável.', e)
        );
    }

    isFormularioRtInvalido(): boolean {
        if (this.tipoImovel === 'URBANO') {
            return this.formaOcupacao === null || this.formaOcupacao.id === null ||
                (this.formaOcupacao.outro && (this.especificaoOcupacao === null || this.especificaoOcupacao.length === 0))
                || this.resultadoConsultaResponsaveis.length !== 1;
        } else {
            return this.resultadoConsultaResponsaveis.length !== 1;
        }
    }

    public limparCampos(): void {
        this.cpfCnpjResponsavelControl.reset();
        this.resultadoConsultaResponsaveis = [];
        this.formaOcupacao = null;
        this.form.markAsDirty();
    }

    get possuiImovel(): boolean {
        return (this.imovel == null || this.imovel.id == null);
    }

    get ativarBuscaImovelUrbano(): boolean {
        return this.possuiImovel && this.tipoImovel === 'URBANO';
    }

    get ativarBuscaImovelRural(): boolean {
        return this.tipoImovel === 'RURAL';
    }

    get getToolTipAjuda(): string {
        return 'Para este Requerimento, é necessário que seja informado um imóvel principal. ' +
            'Caso haja outro imóvel informado como principal, este será substituído pelo último marcado como principal. ' +
            'O endereço deve ser informado para o imóvel principal.';
    }

    get tituloCadastro(): string {
        if (this.tipoImovel === 'RURAL') {
            return 'Detalhes do Imóvel Rural';
        }
        if (this.tipoImovel === 'URBANO') {
            return 'Detalhes do Imóvel Urbano';
        }
        if (this.tipoImovel === 'OBRA') {
            return 'Detalhes de Obra de Infraestrutura';
        }
    }

    get denominacao(): string {
        if (this.tipoImovel === 'OBRA') {
            return 'Denominação da Obra';
        } else {
            return 'Denominação do imóvel';
        }
    }

    get unidades(): any {
        return Imovel.unidades;
    }

    get tipoImovel(): string {
        return this.form ? this.form.get('tipo').value : '';
    }

    get isImovelPrincipal(): boolean {
        return this.imovel ? this.imovel.imovelPrincipal : false;
    }

    async validarAba(erros: ErrosAbaRequerimento): Promise<void> {

        this.controleCrudImovel();

        if (!this.tipoImovel || this.tipoImovel === '') {
            erros.push('É obrigatório selecionar o imóvel/obra na tabela.');
        }
        if (this.tipoImovel === 'RURAL') {
            if (this.formatoCoordenadasImovelPrincipal && this.formatoCoordenadasImovelPrincipal.value === 'grau') {
                this.formImovelPrincipal.get('latitudeGMS').setValidators([Validators.required]);
                this.formImovelPrincipal.get('longitudeGMS').setValidators([Validators.required]);
            } else {
                this.formImovelPrincipal.get('latitude').setValidators([Validators.required]);
                this.formImovelPrincipal.get('longitude').setValidators([Validators.required]);
            }
            if (this.resultadoConsultaTodosImoveis.length == 0) {
                erros.push('Este tipo de Requerimento requer a informação de pelo menos um imóvel rural.');
            } else {
                const possuiImovelPrincipal = this.resultadoConsultaTodosImoveis.find(x => x.imovelPrincipal == true);
                if (!possuiImovelPrincipal) {
                    erros.push('É preciso informar um CAR como sendo o principal do Requerimento para prosseguir.');
                }
            }
        }


        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.updateValueAndValidity();
            erros.push('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
        if (!this.responsaveisAdicionados.length) {
            erros.push('O Imóvel precisa de pelo menos um Responsável associado.', false);
        }
        if (this.documentos) {
            this.documentos.forEach(documento => {
                if (!documento.nomeArquivo) {
                    erros.push(`O Documento: ${documento.titulo} do Responsável: ${documento.pessoa.nome}, precisa ser preenchido.`, false);
                }
            });
        }



        // Validacoes por tipo requerimento / rns especificas
        if (this.requerimento && this.requerimento.tipoProcesso) {
            switch (this.requerimento.tipoProcesso.instanciaRequerimento) {
                case EnumInstanciaRequerimento.RECURSO_FLORESTAL: {
                    await this.validaRecursoFlorestal(erros);
                    break;
                }
                default: console.info("Validar Requerimento Localização: Tipo de Processo sem validação.")
            }
        }

        console.debug('Aba Localização validada');

    }


    private async validaRecursoFlorestal(erros: ErrosAbaRequerimento) {
        if (this.requerimento.tipoRequerimento) {
            switch (this.requerimento.tipoRequerimento.tipoAtuacao) {
                case 'NOVO': {
                    await this.validaRecursoFlorestal_novo(erros);
                    break;
                }
                default: await this.validaRecursoFlorestal_novo(erros);
            }
        }
    }

    private async validaRecursoFlorestal_novo(erros: ErrosAbaRequerimento)  {
       
        //  _RN0033: O sistema deve validar se a coordenada informada na segunda aba do requerimento para localização, 
       //           está dentro da ATP do CAR principal, quando informada uma coordenada e o tipo de localização for Imóvel Rural.
       //           Caso esteja fora do perímetro do imóvel no requerimento, não deve aceitar a conclusão do requerimento.
        
       if (this.imovel && this.imovel.tipo == 'RURAL') {
            if (this.imovel.numeroCAR) {   // situacao? CAR_VALIDADO
                //
                // let objetivoAcai = this.objetivoLicenciamento && this.objetivoLicenciamento.objetivo && this.objetivoLicenciamento.objetivo.descricao ? this.objetivoLicenciamento.objetivo.descricao.toLowerCase().indexOf("acai") != -1 : false;
                let coordenadasValidas = await this.imovelService.validarCoordendasImovelSugef(this.requerimento.id).toPromise();

                if(!coordenadasValidas){
                    erros.push("Aba 2: A coordenada não está dentro do perímetro do imóvel.", false);
                }
            }
       }

    }



    onChangeTipoImovel(tipo: any): boolean {
        this.form.get('tipo').setValue(tipo);

        //Habilitando obrigatoriedade latitude e longitude quando requerimento do tipo recursos florestais.
        if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL) {
            this.form.get('latitude').enable();
            this.form.get('longitude').enable();
            if (this.formatoCoordenadasImovelPrincipal.value === 'grau') {
                this.formImovelPrincipal.get('latitudeGMS').setValidators([Validators.required]);
                this.formImovelPrincipal.get('longitudeGMS').setValidators([Validators.required]);
                this.formImovelPrincipal.get('latitudeGMS').enable();
                this.formImovelPrincipal.get('longitudeGMS').enable();
            } else {
                this.formImovelPrincipal.get('latitude').setValidators([Validators.required]);
                this.formImovelPrincipal.get('longitude').setValidators([Validators.required]);
                this.formImovelPrincipal.get('latitude').enable();
                this.formImovelPrincipal.get('longitude').enable();
            }
        } else {
            this.formImovelPrincipal.get('latitude').clearValidators();
            this.formImovelPrincipal.get('longitude').clearValidators();
            this.formImovelPrincipal.get('latitudeGMS').clearValidators();
            this.formImovelPrincipal.get('longitudeGMS').clearValidators();
        }

        if (this.form.get('id').value != null) {
            let dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent,
                {
                    data: <ExcludeData>{
                        label: 'Atenção',
                        message: 'Ao alterar o imóvel serão removidos os documentos vinculados ao imóvel, deseja prosseguir?',
                    }
                });
            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    // this.documentoImovelService.deleteDocumentos().subscribe((res) => this.limparForm())
                    this.limparForm();
                } else {
                    this.form.patchValue({'tipo': this.tipoImovelPrev});
                    return false;
                }
            });
        } else {
            this.limparForm();
        }

        return false;
        

    }

    private limparForm() {
        this.form.get('id').reset();
        this.form.get('matricula').reset();
        this.form.get('iptu').reset();
        this.form.get('numeroCadastroSiga').reset();
        this.form.get('denominacao').reset();
        this.form.get('utilidadePublicaOuInteresseSocial').reset();
        this.form.get('area').reset();
        this.form.get('municipio').reset();
        this.form.get('endereco').reset();
        this.form.get('bairro').reset();
        this.form.get('complemento').reset();
        this.form.get('roteiroLocalizacao').reset();
        this.form.get('cep').reset();
        this.form.get('unidadeMedida').reset();
        this.form.get('numeroCAR').reset();
        this.form.get('cadastroCompleto').reset();
        this.form.get('atividade').reset();
        this.form.get('enderecoEmpreendimento').reset();
        this.form.get('denominacao').reset();

        this.formImovelPrincipal.get('imovelPrincipal').reset();
        this.formImovelPrincipal.get('municipio').reset();
        this.formImovelPrincipal.get('endereco').reset();
        this.formImovelPrincipal.get('bairro').reset();
        this.formImovelPrincipal.get('complemento').reset();
        this.formImovelPrincipal.get('cep').reset();
        this.numeroCar = new FormControl('');
    }

    isExigeDeclaracao(): boolean {
        return this.requerimento.tipoProcesso.isOutorga() && this.isRequerimentoSubterraneo && this.tipoImovel !== 'OBRA';
    }

    isRedeDeAbastecimentoPublico(): boolean {
        const isAbastecimento: boolean | null = this.form.get('abastecimentoPublico').value;
        return isAbastecimento != null && isAbastecimento;
    }

    onChangeAbastecimentoPublico() {
        const abastecimentoPublico: boolean = this.form.get('abastecimentoPublico').value;
        this.form.patchValue({
            numeroMatriculaAbastecimento: '',
            anexo: null
        });
        const anexoControl = this.form.get('anexo');
        anexoControl.setValidators(this.tipoImovel == 'RURAL' ? null : Validators.required);
        FormUtil.clearControlState(anexoControl);

        const numeroMatriculaControl = this.form.get('numeroMatriculaAbastecimento');
        if (abastecimentoPublico) {
            numeroMatriculaControl.setValidators(Validators.required);
            numeroMatriculaControl.updateValueAndValidity();
        } else {
            numeroMatriculaControl.setValidators(null);
            numeroMatriculaControl.updateValueAndValidity();
        }
    }

    async trocarTipoImovel(tipo) {
        this.imovel = null;
        this.onChangeTipoImovel(tipo);

        this.form.patchValue({
            abastecimentoPublico: null,
            numeroMatriculaAbastecimento: '',
            anexo: null
        });

        this.validarFormTipoImovel(tipo);

        this.form.get('abastecimentoPublico').setValidators(this.isExigeDeclaracao() ? Validators.required : null);
        this.form.get('abastecimentoPublico').updateValueAndValidity();
        this.form.get('anexo').setValidators(this.isExigeDeclaracao() ? Validators.required : null);
        this.form.get('anexo').updateValueAndValidity();
        this.aplicaRegrasDeObjetivo();
    }

    validarFormTipoImovel(tipo) {
        const iptuControl = this.form.get('iptu');
        const numeroCAR = this.form.get('numeroCAR');
        const roteiroLocalizacao = this.form.get('roteiroLocalizacao');
        const matriculaControl = this.form.get('matricula');
        const utilidadePublicaOuInteresseSocialControl = this.form.get('utilidadePublicaOuInteresseSocial');

        switch (tipo) {
            case 'URBANO':
                if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL) {
                    this.form.get('latitude').setValidators([Validators.required]);
                    this.form.get('longitude').setValidators([Validators.required]);
                }
                matriculaControl.setValidators([Validators.maxLength(50)]);
                utilidadePublicaOuInteresseSocialControl.setValidators(null);
                numeroCAR.setValidators(null);
                roteiroLocalizacao.setValidators(null);
                this.setFormState();
                break;
            case 'RURAL': {
                if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL) {
                    if (this.formatoCoordenadasImovelPrincipal.value === 'grau') {
                        this.formImovelPrincipal.get('latitudeGMS').setValidators([Validators.required]);
                        this.formImovelPrincipal.get('longitudeGMS').setValidators([Validators.required]);
                    } else {
                        this.formImovelPrincipal.get('latitude').setValidators([Validators.required]);
                        this.formImovelPrincipal.get('longitude').setValidators([Validators.required]);
                    }
                    
                }
                utilidadePublicaOuInteresseSocialControl.setValidators(null);
                numeroCAR.setValidators(Validators.required);
                matriculaControl.setValidators(null);
                roteiroLocalizacao.setValidators(Validators.required);
                this.setFormState();
                break;
            }
            case 'OBRA':
                if (this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL) {
                    this.form.get('latitude').setValidators([Validators.required]);
                    this.form.get('longitude').setValidators([Validators.required]);
                }
                iptuControl.setValidators(null);
                matriculaControl.setValidators(null);
                utilidadePublicaOuInteresseSocialControl.setValidators(Validators.required);
                numeroCAR.setValidators(null);
                roteiroLocalizacao.setValidators(null);
                this.setFormState();
                break;
            default:
                break;
        }

        iptuControl.updateValueAndValidity();
        matriculaControl.updateValueAndValidity();
        utilidadePublicaOuInteresseSocialControl.updateValueAndValidity();
        this.form.updateValueAndValidity();
    }

    visualizarDocRequerimento(docRequerimento: DocumentoRequerimento) {
        this.arquivoService.download(docRequerimento.chave).subscribe(result => this.pdfViewerDialog.show(result));
    }

    deletarDocumentoAbastecimentoPublico(docRequerimento: DocumentoRequerimento): void {
        try {
            this.imovelService.deletarDocumentoAbastecimentoPublico(this.requerimento.id)
            .subscribe(() => {
                this.snackBarService.showSuccess('Arquivo excluído com sucesso!');
            });
        } catch (e) {
            this.snackBarService.showError('Erro ao excluir o arquivo', e);
        }
    }

    private async tratarDoc(anexo: DocumentoRequerimento) {
        try {
            anexo.id = null;
            anexo.requerimento = this.requerimento;
            const doc: DocumentoRequerimento = await this.documentoRequerimentoService.create(anexo).toPromise();
            this.form.get('anexo').setValue(doc);
        } catch (e) {
            this.snackBarService.showError('Erro ao copiar o documento', e);
        }
    }

    controleCrudImovel() {
        if (this.tipoImovel != null) {
            //Não bloquear imoveis que não são rurais.
            this.habilitarConsultaResponsavel = true;
        } else {
            if (this.isVisualizarImovelCadastrado) {
                //Bloquear caso o imovel esteja em visualização.
                this.habilitarConsultaResponsavel = false;
            } else {
                //antes --> //Bloquear caso imovel não seja principal e não bloquear caso seja.
                this.habilitarConsultaResponsavel = this.isImovelPrincipal;
            }
        }
        if (this.imovel == null && this.tipoImovel == null) {
            this.habilitarConsultaResponsavel = true;  // novo cadastro
        } 

        // SUGEF
        try {
            if(this.requerimento.tipoProcesso.instanciaRequerimento != EnumInstanciaRequerimento.RECURSO_FLORESTAL){
                this.formImovelPrincipal.get('latitude').clearValidators();
                this.formImovelPrincipal.get('longitude').clearValidators();
                this.formImovelPrincipal.get('longitudeGMS').clearValidators();
                this.formImovelPrincipal.get('longitudeGMS').clearValidators();
            }
        } catch (e) {
            console.error(e);
        }

    }

    async validarCep(cep: any, origem?: string) {
        //Caso o CEP seja nulo irá cair na validação do form
        if (cep) {
            const cepFormatado = cep.replace(/\D/g, '');
            let result = await this.cepService.getCep(cepFormatado).toPromise().catch(() => null);
            let codIbge = result !== null ? result.cep : null;
            if (origem == 'imovelPrincipal' && this.imovel.tipo === 'RURAL') {
                codIbge = this.formImovelPrincipal.get('municipio').value.codigoIbge;
                this.isCepValido = !((result == null || result.erro) || (result.municipio !== null && codIbge !== result.municipio.codigoIbge));
            } else {
                if (!codIbge) {
                    this.isCepValido = false;
                    this.snackBarService.showAlert('O CEP digitado é inválido ou esta fora do município selecionado.');
                    return;
                }
                this.isCepValido = !((result == null || result.erro) || (result.municipio == null && this.form.get('municipio').value.codigoIbge !== result.municipio.codigoIbge));
                this.populaDadosFormMunicipio(result);
            }
        }
    }

    onKey(event: any) {
        this.validarCep(event.target.value);
    }

    populaDadosFormMunicipio(dadosCep: any) {
        this.form.get('municipio').patchValue(dadosCep.municipio);
        this.form.get('endereco').patchValue(dadosCep.logradouro);
        this.form.get('bairro').patchValue(dadosCep.bairro);
        this.form.get('complemento').patchValue(dadosCep.complemento);
        this.form.get('cep').patchValue(dadosCep.cep);
    }

    onChangeFormatoCoordenadasImovelPrincipal(): void {
        this.maskInputLatLngImovelPrincipal = this.formatoCoordenadasImovelPrincipal.value === 'grau' ? '00° 00\' 00.0"' : 'separator.6';
        this.formatoCoordenadasImovelPrincipal.markAsUntouched();

        this.formImovelPrincipal.controls.latitude.setValue(null);
        this.formImovelPrincipal.controls.longitude.setValue(null);
        this.formImovelPrincipal.controls.longitudeGMS.setValue(null);
        this.formImovelPrincipal.controls.longitudeGMS.setValue(null);
        this.formImovelPrincipal.markAsUntouched();
        this.formImovelPrincipal.updateValueAndValidity();
        this.coordenadasImovelPrincipalFormadoGMS.setValue(null);
        this.coordenadasFormadoGMS.setValue(null);
    }

    onChangeFormatoCoordenadasInfoGeograficas(): void {
        this.maskInputLatLngInfoGeograficas = this.formatoCoordenadasInfoGeograficas.value === 'grau' ? '00° 00\' 00.0"' : 'separator.6';
        this.formatoCoordenadasInfoGeograficas.markAsUntouched();

        this.form.controls.latitude.setValue(null);
        this.form.controls.longitude.setValue(null);
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
        this.coordenadasImovelPrincipalFormadoGMS.setValue(null);
        this.coordenadasFormadoGMS.setValue(null);
    }

    convertCoordenadasParaGMSImovelPrincipal() {
        if (this.tipoImovelAtual== 'RURAL' &&  this.formImovelPrincipal.get('latitude').value != null && this.formImovelPrincipal.get('longitude').value != null) {
            this.coordenadasImovelPrincipalFormadoGMS.patchValue(FormUtil.convertDEGToDMS(this.formImovelPrincipal.get('latitude').value, true) + ' ' + FormUtil.convertDEGToDMS(this.formImovelPrincipal.get('longitude').value, false));
        } else {
            this.coordenadasImovelPrincipalFormadoGMS.patchValue(null);
        }
    }

    convertCoordenadasParaGMSImovel() {
        if (this.tipoImovelAtual== 'RURAL' &&  this.form.get('latitude').value != null && this.form.get('longitude').value != null) {
            this.coordenadasFormadoGMS.patchValue(FormUtil.convertDEGToDMS(this.form.get('latitude').value, true) + ' ' + FormUtil.convertDEGToDMS(this.form.get('longitude').value, false));
        } else {
            this.coordenadasFormadoGMS.patchValue(null);
        }
    }

    abrirDialogAjuda(){
        this.dialog.open(DialogAjudaFormaOcupacaoComponent, {
            width: '40%',
        });
    }

    isRequerimentoProtocoloLegado(): boolean {
        return this.requerimento.processo.origem === "LEGADO";
    }
}