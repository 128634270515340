import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {BaseRequerimentoComponent} from '../shared/base-requerimento.component';
import {RequerimentoOutorgaService} from './requerimento-outorga.service';
import {RequerimentoService} from '../requerimento.service';
import {RedirectUtil} from '../../../shared/util/RedirectUtil';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {PdfViewerDialog} from '../../../shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import {DocumentoProcessoService} from '../../gerar-documento-publicacao/documento-processo.service';
import {ConfirmarCancelamentoDialogComponent} from '../shared/confirmar-cancelamento-dialog/confirmar-cancelamento-dialog.component';
import {HistoricoPrazoProcessoService} from '../../../shared/services/historico-prazo-processo.service';
import {UtilService} from '../../../core/services/util.service';
import {HistoricoRequerimentoEnum} from '../shared/historico-requerimento/model/historico-requerimento.model';

@Component({
    selector: 'app-requerimento-outorga',
    templateUrl: 'requerimento-outorga.component.html',
    styleUrls: ['requerimento-outorga.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})

export class RequerimentoOutorgaComponent extends BaseRequerimentoComponent implements OnInit {

    constructor(
        private service: RequerimentoOutorgaService,
        private requerimentoService: RequerimentoService,
        private documentoProcessoService: DocumentoProcessoService,
        private pdfViewerDialog: PdfViewerDialog,
        private snackBarService: SnackBarService,
        private historicoPrazoProcessoService: HistoricoPrazoProcessoService,
        private utilService: UtilService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.currentAction === 'edit') {
            if (this.requerimento.situacaoProcesso === 'CHECKLIST_REPROVADO') {
                this.requerimentoService.atualizaStatusRequerimento(this.requerimento.id, 'EM_CORRECAO_CADASTRO')
                    .subscribe(req => this.requerimento = req);
            }
            if (this.requerimento.situacaoProcesso === 'AGUARDANDO_REVISAO') {
                this.requerimentoService.atualizaStatusRequerimento(this.requerimento.id, 'EM_REVISAO')
                    .subscribe(req => this.requerimento = req);
            }
            if (this.requerimento.situacaoProcesso === 'AGUARDANDO_CORRECAO') {
                this.requerimentoService.atualizaStatusRequerimento(this.requerimento.id, 'EM_CORRECAO')
                    .subscribe(req => this.requerimento = req);
            }
        }
    }

    cancelar(): any {
        const idTarefa = this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path;

        const dialogRef = this.dialog.open(ConfirmarCancelamentoDialogComponent, {
            width: '300px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.requerimentoService.cancelarRequerimento(idTarefa, this.requerimento.id).subscribe(resp => {
                    RedirectUtil.redirecionaDashboardCamunda();
                }, () => {
                });
            }
        });
    }

    async finalizar(): Promise<void> {
        this.validacaoFinal().then(async passou => {
            if (passou) {
                this.requerimentoService.readPdf(this.requerimento).subscribe(blob => {
                    this.pdfViewerDialog.show(blob, {
                        height: '80%', width: '60%', showDownloadButton: true,
                        assinatura: {
                            service: this.requerimentoService,
                            alvoAssinatura: this.requerimento,
                            idTarefa: this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path,
                            aviso: 'Essa ação não poderá ser desfeita.\n Confira atentamente todas as informações ' +
                                'do requerimento antes de Assinar e Assinar e enviar o Requerimento para o checklist.',
                            textoBotaoEnviar: 'Assinar e Enviar Requerimento'
                        }
                    }).subscribe(assinou => {
                        if (assinou) {
                            // atualiza HistoricoPrazoProcesso para consulta de prazos
                            if (this.requerimento.situacaoProcesso === 'EM_REVISAO' || this.requerimento.situacaoProcesso === 'EM_CORRECAO') {
                                this.atualizarHistoricoPrazoProcesso();
                            }
                            RedirectUtil.redirecionaDashboardCamunda();
                        }
                    });
                });
            }
        });

    }

    atualizarHistoricoPrazoProcesso(): void {
        this.historicoPrazoProcessoService.addHistoricoPrazoProcesso(this.requerimento.id,
            HistoricoRequerimentoEnum.EM_ANALISE, 'INTERNO').subscribe(() => {
        });
    }

}
