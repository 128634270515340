import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class SubstanciaMineral extends BaseModel {
    constructor(
        public id: number,
        public idSubstacaoMineracao: number,
        public idUnidadeMedida: number,
        public idUnidadeTempo: number,
        public quantidadeMensal: number,
        public descricao: string,
        public mineracao: AtividadeMineracao,
    ) {
        super();
    }

    static fromJson(json: any): SubstanciaMineral {
        if (json === undefined || json === null) {
            return null;
        }
        return new SubstanciaMineral(
            json.id,
            json.idSubstacaoMineracao,
            json.idUnidadeMedida,
            json.idUnidadeTempo,
            json.quantidadeMensal,
            json.descricao,
            json.mineracao
        );
    }

}
