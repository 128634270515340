import { BaseModel } from 'app/main/shared/models/base.model';
import { EmpreendimentoEnergTermica } from './empreendimento-energ-termica';

export class GeradorVaporUsinaTerm extends BaseModel {
    constructor(

        public id?: number,
        public empreendimentoEnergTermica?: EmpreendimentoEnergTermica,
        public identificacaoGeradorVapor?: string,
        public descricaoTipo?: string,
        public capacidade?: number,
        public pressaoInstrumento?: number,
        public temperatura?: number,
        public eficiencia?: number,
        public nomeFabricante?: string

    ) {
        super();
    }
    static fromJson(json: any): GeradorVaporUsinaTerm {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeradorVaporUsinaTerm(
            json.id,
            EmpreendimentoEnergTermica.fromJson(json.empreendimentoEnergTermica),
            json.identificacaoGeradorVapor,
            json.descricaoTipo,
            json.capacidade,
            json.pressaoInstrumento,
            json.temperatura,
            json.eficiencia,
            json.nomeFabricante,
        );
    }

    static fromJsons(json: any): GeradorVaporUsinaTerm[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(GeradorVaporUsinaTerm.fromJson(j)));
            return resources;
        }
    }
}
