import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ResiduoRccService } from "app/main/shared/services/residuo-rcc.service";
import { SnackBarService } from "app/main/shared/snack-bar/snack-bar.service";
import { TipoAtividadeService } from "../../../../../../../shared/services/tipo-atividade.service";
import { RequerimentoService } from "../../../../../requerimento.service";
import { RequerimentoFormularioLicenciamentoService } from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import { BaseAtividadeEmpreendimentoComponent } from "../base-atividade-empreendimento.component";
import { BalancoMassaResiduoRcc } from "./../../../../../../../core/models/balanco-massa-residuo-rcc";
import {
    ResiduoRcc,
    ResiduoRccDto,
} from "./../../../../../../../core/models/residuo-rcc";
import { TipoAtividade } from "./../../../../../../../core/models/tipo-atividade";
import { BalancoMassaResiduoRccService } from "./../../../../../../../shared/services/balanco-massa-residuo-rcc.service";

@Component({
    selector: "app-residuos-rcc",
    templateUrl: "./residuos-rcc.component.html",
    styleUrls: ["./residuos-rcc.component.scss"],
})
export class ResiduosRccComponent
    extends BaseAtividadeEmpreendimentoComponent
    implements OnInit
{
    idFormulario: number;
    idFormBalanco: number;
    formularioSalvo: ResiduoRcc;
    formResiduos: FormGroup;
    formBalanco: FormGroup;

    // Tipo selecionado no mat-select
    tipoAtividades: TipoAtividade[] = [];

    tipoAtividadeSelecionado: any;

    // Array para tabela de tipos selecionados
    tipoAtividadeFiltrada: TipoAtividade[] = [];

    // Gerando um array com tipos escolhidos pelo usuário
    tiposAtividadeEscolhidos: TipoAtividade[] = [];

    // Exibindo no dataSource tipos escolhidos
    dataSourceTipo = new MatTableDataSource<TipoAtividade>([]);

    // Array para dados do objeto balanço
    balancosSalvos: any[] = [];
    dataSourceBalanco = new MatTableDataSource<any>([]);
    
    isNaoPodeEditar: boolean = false;

    colunasBalancoMassa: string[] = [
        "residuosRecebidos",
        "residuosBeneficiado1",
        "rejeitos1",
        "residuosBeneficiado2",
        "rejeitos2",
        "classe",
        "acao",
    ];

    // Array com as unidades de medidas disponiveis
    arrayUnidadeMedida = [
        "M3",
        "TON",
        "M3_MES",
        "TON_MES",
        "TON_M3",
        "TON_DIA",
    ];

    // Tooltip para o texto fixo de balanco da massa
    apresentarInformacaoBalancodaMassa =
        "“Destacamos que o local deve possuir capacidade e estrutura para processar o material conforme a demanda de entrada, se valendo de estoques somente para achatar os picos de recebimentos diários e logística operacional de seu acondicionamento, seja do material triado ou dos rejeitos deste processo, caso contrário ocorrerá a formação de um passivo. Caso haja interesse em armazenamento de resíduos classe A para uso futuro deverá ser requerido licenciamento para aterro.”";

    // parte da estrutura de regras dos campos
    get capacidadeNominal() {
        return this.formResiduos.get("capacidadeNominal");
    }

    get armazenamentoTempo() {
        return this.formResiduos.get("armazenamentoTempo");
    }

    get aterroCapacidade() {
        return this.formResiduos.get("aterroCapacidade");
    }

    get aterroCapacidadeOperacional() {
        return this.formResiduos.get("aterroCapacidadeOperacional");
    }

    get aterroVidaUtil() {
        return this.formResiduos.get("aterroVidaUtil");
    }

    get entradaDensidade() {
        return this.formBalanco.get("entradaDensidade");
    }

    get armazenamentoResiduoDensidade() {
        return this.formBalanco.get("armazenamentoResiduoDensidade");
    }

    get armazenamentoRejeitosDensidade() {
        return this.formBalanco.get("armazenamentoRejeitosDensidade");
    }

    get saidaResiduoDensidade() {
        return this.formBalanco.get("saidaResiduoDensidade");
    }

    get saidaRejeitosDensidade() {
        return this.formBalanco.get("saidaRejeitosDensidade");
    }

    constructor(
        private formBuilder: FormBuilder,
        private tipoAtividadeService: TipoAtividadeService,
        private residuoRccService: ResiduoRccService,
        private balancoService: BalancoMassaResiduoRccService,
        private snackBarService: SnackBarService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
    }

    async ngOnInit() {
        this.buildForms();
        this.getAllTipoAtividade();
        this.campoCapacidadeNominal();
        this.campoTempoMedio();
        this.camposAterro();
        this.unidadeMedidaArmazenamentoRejeitoDensidade();
        this.unidadeMedidaArmazenamentoSelecionada();
        this.unidadeMedidaEntradaSelecionada();
        this.unidadeMedidaSaidaRejeitosDensidade();
        this.unidadeMedidaSaidaResiduoDensidade();

        await this.listarRcc();

        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.formBalanco.disable()
            this.formResiduos.disable()
        }
    }

    async listarRcc() {
        if (this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarResiduoRcc(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarResiduoRcc(this.requerimento.id)
            }
        }
    }

    // habilitando campos conforme regras
    isCapacidadeNominal = false;
    campoCapacidadeNominal() {
        this.formResiduos
            .get("balanca")
            .valueChanges.subscribe((selectedValue) => {
                const capacidadeNominal =
                    this.formResiduos.get("capacidadeNominal");
                if (selectedValue == "true") {
                    this.isCapacidadeNominal = true;
                    capacidadeNominal.setValidators(Validators.required);
                } else {
                    this.isCapacidadeNominal = false;
                    capacidadeNominal.clearValidators();
                }
                capacidadeNominal.updateValueAndValidity();
            });
    }

    isCamposAterroHabilitado = false;
    camposAterro() {
        const aterroCapacidade = this.formResiduos.get("aterroCapacidade");
        const aterroCapacidadeOperacional = this.formResiduos.get(
            "aterroCapacidadeOperacional"
        );
        const aterroVidaUtil = this.formResiduos.get("aterroVidaUtil");

        if (
            this.tiposAtividadeEscolhidos.includes(
                this.tipoAtividadeSelecionado
            ) &&
            this.tipoAtividadeSelecionado.id == "1"
        ) {
            this.isCamposAterroHabilitado = true;
            aterroCapacidade.setValidators(Validators.required);
            aterroCapacidadeOperacional.setValidators(Validators.required);
            aterroVidaUtil.setValidators(Validators.required);
        } else {
            aterroCapacidade.clearValidators();
            aterroCapacidadeOperacional.clearValidators();
            aterroVidaUtil.clearValidators();
        }
        aterroCapacidade.updateValueAndValidity();
        aterroCapacidadeOperacional.updateValueAndValidity();
        aterroVidaUtil.updateValueAndValidity();
    }

    isCampoTempoMedio = false;
    campoTempoMedio() {
        this.formResiduos
            .get("armazenamentoTemporario")
            .valueChanges.subscribe((selectedValue) => {
                const armazenamentoTempo =
                    this.formResiduos.get("armazenamentoTempo");
                if (selectedValue == "true") {
                    this.isCampoTempoMedio = true;
                    armazenamentoTempo.setValidators(Validators.required);
                } else {
                    this.isCampoTempoMedio = false;
                    armazenamentoTempo.clearValidators();
                }
                armazenamentoTempo.updateValueAndValidity();
            });
    }

    isCampoEntradaDensidade = false;
    unidadeMedidaEntradaSelecionada() {
        this.formBalanco
            .get("entradaUnidadeMedida")
            .valueChanges.subscribe((unidadeSelecionada) => {
                const entradaDensidade =
                    this.formBalanco.get("entradaDensidade");
                if (unidadeSelecionada === "M3_MES") {
                    this.isCampoEntradaDensidade = true;
                    entradaDensidade.setValidators(Validators.required);
                } else {
                    this.isCampoEntradaDensidade = false;
                    entradaDensidade.clearValidators();
                }
                entradaDensidade.updateValueAndValidity();
            });
    }

    isArmazenamentoResiduoDensidade = false;
    unidadeMedidaArmazenamentoSelecionada() {
        this.formBalanco
            .get("armazenamentoResiduoUnidadeMedida")
            .valueChanges.subscribe((unidadeSelecionada) => {
                const armazenamentoResiduoDensidade = this.formBalanco.get(
                    "armazenamentoResiduoDensidade"
                );
                if (unidadeSelecionada === "M3") {
                    this.isArmazenamentoResiduoDensidade = true;
                    armazenamentoResiduoDensidade.setValidators(
                        Validators.required
                    );
                } else {
                    this.isArmazenamentoResiduoDensidade = false;
                    armazenamentoResiduoDensidade.clearValidators();
                }
                armazenamentoResiduoDensidade.updateValueAndValidity();
            });
    }

    isArmazenamentoRejeitosDensidade = false;
    unidadeMedidaArmazenamentoRejeitoDensidade() {
        this.formBalanco
            .get("armazenamentoRejeitosUnidadeMedida")
            .valueChanges.subscribe((unidadeSelecionada) => {
                const armazenamentoRejeitosDensidade = this.formBalanco.get(
                    "armazenamentoRejeitosDensidade"
                );
                if (unidadeSelecionada === "M3") {
                    this.isArmazenamentoRejeitosDensidade = true;
                    armazenamentoRejeitosDensidade.setValidators(
                        Validators.required
                    );
                } else {
                    this.isArmazenamentoRejeitosDensidade = false;
                    armazenamentoRejeitosDensidade.clearValidators();
                }
                armazenamentoRejeitosDensidade.updateValueAndValidity();
            });
    }

    isSaidaResiduoDensidade = false;
    unidadeMedidaSaidaResiduoDensidade() {
        this.formBalanco
            .get("saidaResiduoUnidadeMedida")
            .valueChanges.subscribe((unidadeSelecionada) => {
                const saidaResiduoDensidade = this.formBalanco.get(
                    "saidaResiduoDensidade"
                );
                if (unidadeSelecionada === "M3_MES") {
                    this.isSaidaResiduoDensidade = true;
                    saidaResiduoDensidade.setValidators(Validators.required);
                } else {
                    this.isSaidaResiduoDensidade = false;
                    saidaResiduoDensidade.clearValidators();
                }
                saidaResiduoDensidade.updateValueAndValidity();
            });
    }

    isSaidaRejeitosDensidade = false;
    unidadeMedidaSaidaRejeitosDensidade() {
        this.formBalanco
            .get("saidaRejeitosUnidadeMedida")
            .valueChanges.subscribe((unidadeSelecionada) => {
                const saidaRejeitosDensidade = this.formBalanco.get(
                    "saidaRejeitosDensidade"
                );
                if (unidadeSelecionada === "M3_MES") {
                    this.isSaidaRejeitosDensidade = true;
                    saidaRejeitosDensidade.setValidators(Validators.required);
                } else {
                    this.isSaidaRejeitosDensidade = false;
                    saidaRejeitosDensidade.clearValidators();
                }
                saidaRejeitosDensidade.updateValueAndValidity();
            });
    }

    apresentarFieldsetBalancoMassa: boolean = true;
    apresentarAgrupamentoBalancoMassa(item: any) {

        if (item.id == "1") {
            if (this.tiposAtividadeEscolhidos.some((tipo) => tipo.id == "3")) {
                this.apresentarFieldsetBalancoMassa = false;
            } else {
                this.apresentarFieldsetBalancoMassa = true;
            }
        } else if (item.id == "3") {
            if (this.tiposAtividadeEscolhidos.some((tipo) => tipo.id == "1")) {
                this.apresentarFieldsetBalancoMassa = false;
            } else {
                this.apresentarFieldsetBalancoMassa = true;
            }
        }
    }

    buildForms() {
        this.formResiduos = this.formBuilder.group({
            id: [null],
            balanca: [null, [Validators.required]],
            capacidadeNominal: [
                null,
                [Validators.required, Validators.maxLength(20)],
            ],
            recebimentoResiduos: [
                null,
                [Validators.required, Validators.maxLength(20)],
            ],
            quantidadeRecebimento: [null],
            armazenamentoTemporario: [null, [Validators.required]],
            armazenamentoTempo: [
                null,
                [Validators.required, Validators.maxLength(20)],
            ],
            triagem: [null, [Validators.required]],
            aterroCapacidade: [null, Validators.maxLength(20)],
            aterroCapacidadeOperacional: [null, Validators.maxLength(20)],
            aterroVidaUtil: [null, Validators.maxLength(20)],
        });

        this.formBalanco = this.formBuilder.group({
            id: [null],
            classe: [null, [Validators.required]],
            entradaResiduosRecebidos: [
                null,
                [Validators.required, Validators.maxLength(20)],
            ],
            entradaUnidadeMedida: [null, [Validators.required]],
            entradaDensidade: [null],
            armazenamentoResiduo: [
                null,
                [Validators.required, Validators.maxLength(20)],
            ],
            armazenamentoResiduoUnidadeMedida: [null, [Validators.required]],
            armazenamentoResiduoDensidade: [null],
            armazenamentoRejeitos: [
                null,
                [Validators.required, Validators.maxLength(20)],
            ],
            armazenamentoRejeitosUnidadeMedida: [null, [Validators.required]],
            armazenamentoRejeitosDensidade: [null],
            saidaResiduo: [
                null,
                [Validators.required, Validators.maxLength(20)],
            ],
            saidaResiduoUnidadeMedida: [null, [Validators.required]],
            saidaResiduoDensidade: [null],
            saidaRejeitos: [
                null,
                [Validators.required, Validators.maxLength(20)],
            ],
            saidaRejeitosUnidadeMedida: [null, [Validators.required]],
            saidaRejeitosDensidade: [null, [Validators.required]],
        });
    }

    // Buscando lista de tipos de atividades
    getAllTipoAtividade(): void {
        this.tipoAtividadeService.read().subscribe(
            (resposta) => {
                this.tipoAtividades = resposta;
            },
            (err) => {
                this.snackBarService.showError(
                    "Erro ao carregar tipos de atividades",
                    err
                );
            }
        );
    }

    // adiciona tipos de atividade na grid
    adicionarTipoAtividade(t?:any) {

        if (
            this.tipoAtividadeSelecionado !== undefined &&
            this.tipoAtividadeSelecionado !== "Tipo"
        ) {
            // inserindo os tipos de atividades selecionadas
            this.tiposAtividadeEscolhidos.push(this.tipoAtividadeSelecionado);
            // Filtrando os tipos de atividades duplicados
            this.tipoAtividadeFiltrada = this.tiposAtividadeEscolhidos.filter(
                (elem, index, arr) => arr.indexOf(elem) === index
            );
            // Retornando a lista filtrada
            this.tiposAtividadeEscolhidos = this.tipoAtividadeFiltrada;
            // Renderizando na MatTableDataSource
            this.dataSourceTipo = new MatTableDataSource(
                this.tiposAtividadeEscolhidos
            );
            // Apresentando ou não fieldset Aterro com a escolha de "Aterro de RCC" conforme regra
            this.camposAterro();
            // Apresentando ou não fieldset Balando de massa
            if (
                (this.tiposAtividadeEscolhidos.includes(
                    this.tipoAtividadeSelecionado
                ) &&
                    this.tipoAtividadeSelecionado.id == "1") ||
                this.tipoAtividadeSelecionado.id == "3"
            ) {
                this.apresentarFieldsetBalancoMassa = false;
            }
        }

        this.tipoAtividadeSelecionado = "Tipo";
    }

    // excluindo um tipo de atividade da lista de selecionados
    excluirTipoSelecionado(item: TipoAtividade) {
        let tipoAtividadeList: TipoAtividade[] = this.dataSourceTipo.data;
        tipoAtividadeList.splice(tipoAtividadeList.indexOf(item), 1);
        this.dataSourceTipo.data = tipoAtividadeList;
        this.tiposAtividadeEscolhidos = tipoAtividadeList;
        // apresentar ou não o fieldset Aterro
        if (item.id == "1") {
            this.isCamposAterroHabilitado = false;
        }
        this.apresentarAgrupamentoBalancoMassa(item);
    }

    // adicionando um balanco no array
    adicionarBalanco() {
        let totalResiduoConvertidoEmTonelada = 0;
        const densidadeEntrada = this.formBalanco.get("entradaDensidade").value;
        const residuoRecebidoM3 = this.formBalanco.get(
            "entradaResiduosRecebidos"
        ).value;

        if (densidadeEntrada > 0) {
            totalResiduoConvertidoEmTonelada =
                residuoRecebidoM3 * densidadeEntrada;
        } else {
            totalResiduoConvertidoEmTonelada = residuoRecebidoM3 * 1;
        }
        this.balancosSalvos.push({
            id: this.idFormBalanco ? this.idFormBalanco : null,
            classe: this.formBalanco.controls.classe.value,
            entradaResiduosRecebidos: totalResiduoConvertidoEmTonelada,
            entradaUnidadeMedida:
                this.formBalanco.controls.entradaUnidadeMedida.value,
            entradaDensidade: densidadeEntrada,
            armazenamentoResiduo:
                this.formBalanco.controls.armazenamentoResiduo.value,
            armazenamentoResiduoUnidadeMedida:
                this.formBalanco.controls.armazenamentoResiduoUnidadeMedida
                    .value,
            armazenamentoResiduoDensidade:
                this.formBalanco.controls.armazenamentoResiduoDensidade.value,
            armazenamentoRejeitos:
                this.formBalanco.controls.armazenamentoRejeitos.value,
            armazenamentoRejeitosUnidadeMedida:
                this.formBalanco.controls.armazenamentoRejeitosUnidadeMedida
                    .value,
            armazenamentoRejeitosDensidade:
                this.formBalanco.controls.armazenamentoRejeitosDensidade.value,
            saidaResiduo: this.formBalanco.controls.saidaResiduo.value,
            saidaResiduoUnidadeMedida:
                this.formBalanco.controls.saidaResiduoUnidadeMedida.value,
            saidaResiduoDensidade:
                this.formBalanco.controls.saidaResiduoDensidade.value,
            saidaRejeitos: this.formBalanco.controls.saidaRejeitos.value,
            saidaRejeitosUnidadeMedida:
                this.formBalanco.controls.saidaRejeitosUnidadeMedida.value,
            saidaRejeitosDensidade:
                this.formBalanco.controls.saidaRejeitosDensidade.value,
        });
        this.dataSourceBalanco = new MatTableDataSource(this.balancosSalvos);
        this.formBalanco.reset();
    }

    // excluindo um item da table de balanco massa
    excluirBalancoSelecionado(item: any) {
        let balancoList: any[] = this.dataSourceBalanco.data;
        balancoList.splice(balancoList.indexOf(item), 1);
        this.dataSourceBalanco.data = balancoList;
        this.balancoService.delete(item.id).subscribe(
            () => {
                this.snackBarService.showSuccess(
                    "Registro excluído com sucesso."
                );
            },
            (err) => {
                this.snackBarService.showError(
                    "Erro ao excluir este registro",
                    err
                );
            }
        );
    }

    // editar item de balanço na mat-table
    editarBalancoMassa(item: BalancoMassaResiduoRcc) {
        this.formBalanco.patchValue(item);
        this.idFormBalanco = item.id;
        let balancoList: any[] = this.dataSourceBalanco.data;
        balancoList.splice(balancoList.indexOf(item), 1);
        this.dataSourceBalanco.data = balancoList;
    }

    // inserindo um formulário de resíduo rcc com uma lista de atividades e uma lista de balanço da massa
    async createResiduoRcc() {
        if (this.formResiduos.invalid) {
            this.snackBarService.showAlert(
                "O formulário Resíduos de construção civil possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)."
            );
        }
        if (this.tipoAtividadeSelecionado == undefined) {
            this.snackBarService.showAlert(
                "A seleção de um tipo de atividade é obrigatório. Favor selecionar pelo menos um (01) tipo."
            );
        } else {
            const residuoRccDto =
                this.formResiduos.getRawValue() as ResiduoRccDto;
            residuoRccDto.residuoRccTipoAtividade = this.tipoAtividadeFiltrada;
            residuoRccDto.residuoRccBalancoMassa = this.balancosSalvos;
            residuoRccDto.idRequerimento = this.requerimento.id;
            await this.residuoRccService
                .create(residuoRccDto)
                .toPromise()
                .then(
                    async () => {
                        this.snackBarService.showSuccess(
                            "ResiduoRcc salvo com sucesso."
                        );
                        this.enviarFormConcluido.emit();
                        this.limpaCampos();
                        this.apresentarFieldsetBalancoMassa = true;

                        await this.listarRcc();
                    },
                    (err) => {
                        this.snackBarService.showError(
                            "Erro ao inserir este formulário",
                            err
                        );
                    }
                );
        }
    }

    limpaCampos() {
        this.formResiduos.reset();
        this.formBalanco.reset();
        this.tiposAtividadeEscolhidos = [];
        this.balancosSalvos = [];
        this.dataSourceBalanco = new MatTableDataSource();
        this.dataSourceTipo = new MatTableDataSource();
    }

    async carregarResiduoRcc(idRequerimento: number): Promise<void> {
        await this.residuoRccService.buscarPorIdRequerimento(idRequerimento).toPromise().then((response) => {
                    if (response) {
                        this.formularioSalvo = response;
                        this.formResiduos.patchValue(this.formularioSalvo);
                        this.formResiduos.controls.balanca.patchValue(
                            this.formularioSalvo.balanca == true
                                ? "true"
                                : "false"
                        );
                        this.formResiduos.controls.recebimentoResiduos.patchValue(
                            this.formularioSalvo.recebimentoResiduos == true
                                ? "true"
                                : "false"
                        );
                        this.formResiduos.controls.armazenamentoTemporario.patchValue(
                            this.formularioSalvo.armazenamentoTemporario == true
                                ? "true"
                                : "false"
                        );
                        this.formResiduos.controls.triagem.patchValue(
                            this.formularioSalvo.triagem == true
                                ? "true"
                                : "false"
                        );
                        this.balancosSalvos =
                            this.formularioSalvo.residuoRccBalancoMassa;
                        this.dataSourceBalanco = new MatTableDataSource(
                            this.balancosSalvos
                        );

                        this.tiposAtividadeEscolhidos =
                            this.formularioSalvo.residuoRccTipoAtividade;

                        this.tiposAtividadeEscolhidos[0].id == "1" ||
                        this.tiposAtividadeEscolhidos[0].id == "3"
                            ? (this.apresentarFieldsetBalancoMassa = false)
                            : true;
                        this.tiposAtividadeEscolhidos[0].id == "1"
                            ? (this.isCamposAterroHabilitado = true)
                            : false;
                        this.dataSourceTipo = new MatTableDataSource(
                            this.tiposAtividadeEscolhidos
                        );
                        this.formBalanco.patchValue({
                            id: this.formularioSalvo.residuoRccBalancoMassa[0]
                                .id,
                        });
                    }
                },
                (error) => {
                    console.error(error);
                }
            );
    }
}
