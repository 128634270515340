import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";
import {DominioDto} from "../../../../../../../../../shared/models/dominio-dto.model";
import {ParqueBombaCombustivel} from "../../../../../../../../../shared/models/parque-bomba-combustivel.model";
import {DominiosService} from "../../../../../../../../../shared/services/dominios.service";

@Component({
    selector: 'app-parque-bomba',
    templateUrl: './parque-bomba.component.html',
    styleUrls: ['./parque-bomba.component.scss']
})
export class ParqueBombaComponent implements OnInit {

    @Input() form: FormGroup;

    @Input() combustiveisControl: AbstractControl;

    @Input()
    combustiveisExcluidos: number[];

    @Input() isSomenteVisualizacao: boolean;

    @Input() isNaoPodeEditar: boolean;

    tiposCombustivel: DominioDto[];

    tipo: number;

    displayedColumns: string[] = ['combustiveis', 'acoes'];


    constructor(private dominiosService: DominiosService) {
    }

    async ngOnInit() {
        this.tiposCombustivel = await this.dominiosService.getDominioByUrl('tipo-combustivel-tanque').toPromise();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.combustiveisControl.disable();
        }
    }

    adiciona() {
        if (this.tipo) {
            this.combustiveisControl.setValue([
                ...this.combustiveisControl.value,
                ParqueBombaCombustivel.fromJson({
                    tipoCombustivel: this.tipo,
                })
            ]);

            this.combustiveisControl.markAsTouched();
            this.combustiveisControl.markAsDirty();
        }

        this.tipo = undefined;
    }

    async excluir(index: number) {
        if (index > -1) {
            const combustivel = this.combustiveisControl.value[index];

            if (combustivel && combustivel.id !== undefined && combustivel.id !== null) {
                this.combustiveisExcluidos.push(combustivel.id);
            }

            this.combustiveisControl.setValue([
                ...this.combustiveisControl.value.filter(c => c !== combustivel),
            ]);
            this.combustiveisControl.markAsTouched();
            this.combustiveisControl.markAsDirty();
        }
    }

    getTipoCombustivel(tipoCombustivel) {
        return this.tiposCombustivel.find(tipo => tipo.id == tipoCombustivel);
    }
}
