import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class InformacaoLo extends BaseModel {
    constructor(
        public id: number,
        public idSubstancia: number,
        public cnpjCpf: string,
        public nomeRazaoSocial: string,
        public numeroLicenca: number,
        public dataValidadeLicenca: Date,
        public mineracao: AtividadeMineracao,
    ) {
        super();
    }

    static fromJson(json: any): InformacaoLo {
        if (json === undefined || json === null) {
            return null;
        }
        return new InformacaoLo(
            json.id,
            json.idSubstancia,
            json.cnpjCpf,
            json.nomeRazaoSocial,
            json.numeroLicenca,
            json.dataValidadeLicenca,
            json.mineracao,
        );
    }

}
