import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpRequest} from '@angular/common/http';

export enum RequestMethod {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    DELETE = 'DELETE'
}

export interface RequestPath {
    method: RequestMethod;
    pathPattern: string;
}

@Injectable({
    providedIn: 'root',
})
export class LoadingService {

    get value(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    static EXCLUDED_REQUESTS: RequestPath[] = [{
        pathPattern: '\/api\/itens-termos-referencias-requerimento-analises\/[0-9]{1,}\/[0-9]{1,}\/[0-9]{1,}$',
        method: RequestMethod.PUT
    }];

    private loadingSubject = new BehaviorSubject(false);

    static isExcludedRequest(req: HttpRequest<any>): boolean {
        return LoadingService.EXCLUDED_REQUESTS.some(excludedRequest => {
            const test1 = req.method === excludedRequest.method.toString();
            const test2 = new RegExp(excludedRequest.pathPattern).test(req.url);
            return (test1 && test2 || req.params.get('ignoreLoading') === 'true');
        });
    }

    public setValue(value: boolean): void {
        this.loadingSubject.next(value);
    }
}