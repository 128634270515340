import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ArquivoService {
    urlResource = `${environment.URL_GATEWAY}/api/arquivo`;

    constructor(private http: HttpClient) {}

    download(chave: string, type = 'application/pdf'): Observable<Blob> {
        const url = `${this.urlResource}/${chave}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: type});
                }
            ));
    }

}
