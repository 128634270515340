import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TipoObraService} from '../../../../../shared/services/tipo-obra.service';
import {TipoObra} from '../../../../../shared/models/tipo-obra.model';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {TipoCorpoHidrico} from 'app/main/shared/models/tipo-corpo-hidrico.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {TipoCorpoHidricoService} from 'app/main/shared/services/tipo-corpo-hidrico.service';
import {BasePontoComponent} from '../base-ponto.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-objetivo-obras-hidraulicas',
    templateUrl: './objetivo-obras-hidraulicas.component.html'
})
export class ObjetivoObrasHidraulicasComponent extends BasePontoComponent implements OnInit {
    form: FormGroup;
    tipoObras: TipoObra[] = [];
    tipoCorposHidricos: TipoCorpoHidrico[] = [];

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    constructor(
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private pontoService: PontoService,
        private tipoObraService: TipoObraService,
        private tipoCorpoHidricoService: TipoCorpoHidricoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildResourceForm();

        this.tipoObraService.getAll().subscribe(resp => {
            this.tipoObras = resp;
        });

        this.tipoCorpoHidricoService.getAll().subscribe(resp => {
            this.tipoCorposHidricos = resp;
        });

        this.patchPonto();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    private buildResourceForm(): void {

        this.form = this.formBuilder.group({
            tipoCorpoHidrico: [null, Validators.required],
            outroTipoCorpoHidrico: [{value: null, disabled: true}],
            corpoHidrico: [null],
            cursoAguaObra: [null, Validators.required],
            obra: this.formBuilder.group({
                id: [null],
                tipoReservacao: [null, Validators.required],
                especificacaoReservacao: [{value: null, disabled: true}],
                tipoDesvio: [null, Validators.required],
                especificacaoDesvio: [{value: null, disabled: true}],
                tipoTravessia: [null, Validators.required],
                especificacaoTravessia: [{value: null, disabled: true}]
            })
        });
    }

    private patchPonto(): void {
        this.form.patchValue({
            id: this.ponto.id,
            requerimentoOutorga: this.ponto.requerimentoOutorga,
            municipio: this.ponto.municipio,
            latitude: this.ponto.latitude,
            longitude: this.ponto.longitude,
            tipoCorpoHidrico: this.ponto.tipoCorpoHidrico,
            outroTipoCorpoHidrico: this.ponto.outroTipoCorpoHidrico,
            cursoAguaObra: this.ponto.cursoAguaObra,
            corpoHidrico: this.ponto.corpoHidrico,
        });

        if (this.ponto.obra) {
            this.form.controls.obra.patchValue(this.ponto.obra);
        }
    }

    retornaTipoObrasReservacao(): TipoObra[] {
        return this.tipoObras.filter(obra => obra.tipo === 'RESERVACAO');
    }

    retornaTipoObrasDesvio(): TipoObra[] {
        return this.tipoObras.filter(obra => obra.tipo === 'DESVIO');
    }

    retornaTipoObrasTravessia(): TipoObra[] {
        return this.tipoObras.filter(obra => obra.tipo === 'TRAVESSIA');
    }

    salvarPonto(): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            const ponto = Ponto.fromJson(this.ponto);
            ponto.tipoCorpoHidrico = TipoCorpoHidrico.fromJson(this.form.controls.tipoCorpoHidrico.value);
            ponto.outroTipoCorpoHidrico = this.form.controls.outroTipoCorpoHidrico.value;
            ponto.corpoHidrico = this.form.controls.corpoHidrico.value;
            ponto.cursoAguaObra = this.form.controls.cursoAguaObra.value;
            ponto.obra = this.form.controls.obra.value;
            ponto.formularioConcluido = true;
            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        }
    }

    onTipoCorpoHidricoChanged(event): void {
        if (event) {
            const field = this.form.controls.outroTipoCorpoHidrico;
            if (this.form.controls.tipoCorpoHidrico.value.outro) {
                field.enable();
                field.setValidators([Validators.required,
                    Validators.maxLength(255)]);
                field.markAsUntouched();
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
                field.markAsUntouched();
                field.updateValueAndValidity();
            }
        }
    }

    onReservacaoChanged(event): void {
        if (event) {
            const field = this.form.controls.obra.get('especificacaoReservacao');
            if (this.form.controls.obra.get('tipoReservacao').value.outro) {
                field.enable();
                field.setValidators([Validators.required,
                    Validators.maxLength(255)]);
                field.markAsUntouched();
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
                field.markAsUntouched();
                field.updateValueAndValidity();
            }
        }
    }

    onDesvioChanged(event): void {
        if (event) {
            const field = this.form.controls.obra.get('especificacaoDesvio');
            if (this.form.controls.obra.get('tipoDesvio').value.outro) {
                field.enable();
                field.setValidators([Validators.required,
                    Validators.maxLength(255)]);
                field.markAsUntouched();
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
                field.markAsUntouched();
                field.updateValueAndValidity();
            }
        }
    }


    onTravessiaChanged(event): void {
        if (event) {
            const field = this.form.controls.obra.get('especificacaoTravessia');
            if (this.form.controls.obra.get('tipoTravessia').value.outro) {
                field.enable();
                field.setValidators([Validators.required,
                    Validators.maxLength(255)]);
                field.updateValueAndValidity();
                field.markAsUntouched();
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
                field.markAsUntouched();
                field.updateValueAndValidity();
            }
        }
    }

}
