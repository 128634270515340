import {Component, Input} from '@angular/core';
import {AbstractComponenteRelacionamentoSubdominio} from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento-subdominio";
import {SistemaTratamentoSecundario} from "../../../../../../../../shared/models/sistema-tratamento-secundario.model";
import {SistemaTratamentoSecundarioService} from "../../../../../../../../shared/services/sistema-tratamento-secundario.service";

@Component({
  selector: 'app-atv-sist-tratamento-terciario',
  templateUrl: './atv-sist-tratamento-terciario.component.html',
  styleUrls: ['./atv-sist-tratamento-terciario.component.scss']
})
export class AtvSistTratamentoTerciarioComponent extends AbstractComponenteRelacionamentoSubdominio<SistemaTratamentoSecundario, SistemaTratamentoSecundarioService> {

    @Input() isNaoPodeEditar: boolean;

    getDisplayedColumns(): string[] {
        return ['tipo', 'subtipo', 'descricao', 'acoes'];
    }

    apresentaSubtipo() {
        if (this.novoDado.dominio) {
            switch (this.novoDado.dominio.descricao) {
                case "Filtro":
                    return true;
                default:
                    return false;
            }
        }
    }
}
