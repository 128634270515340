import { BaseModel } from "app/main/shared/models/base.model";

export class LagoaBaciaContencao extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public idTipoBaciaContencao?: number,
        public volume?: number,
        public idTipoImpermeabilizacao?: number
    ) {
        super();
    }

    static fromJson(json: any): LagoaBaciaContencao {
        json = json || {};

        return new LagoaBaciaContencao(
            json.id,
            json.idGeometria,
            json.idTipoBaciaContencao,
            json.volume,
            json.idTipoImpermeabilizacao
        );
    }
}
