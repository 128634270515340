import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {fuseAnimations} from '@fuse/animations';
import {BaseModel} from 'app/main/shared/models/base.model';
import {RequerimentoService} from '../../requerimento.service';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {RequerimentoFormularioLicenciamentoValidatorsService} from './requerimento-formulario-licenciamento.validators.service';
import {ErrosAbaRequerimento} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {AtividadeParametroEntradaArrecadacaoService} from '../../../../shared/services/arrecadacao-service/atividade-parametro-entrada-arrecadacao.service';
import {AtividadeCalculoArrecadacaoService} from '../../../../shared/services/arrecadacao-service/atividade-calculo-arrecadacao.service';
import {AtividadeArrecadacaoService} from '../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {AtividadeArrecadacao} from '../../../../shared/models/arrecadacao-models/atividade-arrecadacao.model';
import {RequerimentoLicenciamentoService} from '../requerimento-licenciamento.service';
import {FormularioReqLicenciamentoDto} from '../../../../shared/models/formulario-req-licenciamento.dto';
import {LancamentoTaxaDto} from '../../../../shared/models/lancamento-taxa-dto.model';
import {Titulacao} from '../../../titulacoes/titulacao.model';
import {RequerimentoFormularioLicenciamentoService} from './requerimento-formulario-licenciamento.service';
import {ValidacaoRegraAppDialogComponent} from './validacao-regra-app-dialog/validacao-regra-app-dialog.component';
import {ParametroEntradaArrecadacao} from "../../../../shared/models/arrecadacao-models/parametro-entrada-arrecadacao.model";
import {ActivatedRoute} from "@angular/router";
import add from 'date-fns/add/index.js';


@Component({
    selector: 'app-requerimento-formulario-licenciamento',
    templateUrl: 'requerimento-formulario-licenciamento.component.html',
    styleUrls: ['requerimento-formulario-licenciamento.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [RequerimentoFormularioLicenciamentoValidatorsService],
})
export class RequerimentoFormularioLicenciamentoComponent extends BaseTabRequerimentoComponent implements OnInit {

    constructor(
        protected dialog: MatDialog,
        protected requerimentoService: RequerimentoService,
        protected requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        protected requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private requerimentoLicencaEditValidatorsService: RequerimentoFormularioLicenciamentoValidatorsService,
        private formBuilder: FormBuilder,
        private atividadeCalculoParametroEntradaService: AtividadeParametroEntradaArrecadacaoService,
        private atividadeCalculoService: AtividadeCalculoArrecadacaoService,
        private atividadeArrecadacaoService: AtividadeArrecadacaoService,
        private snackBarService: SnackBarService,
        private activatedRoute: ActivatedRoute,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.FORMULARIO.component = this;
    }

    atividades: Array<AtividadeArrecadacao>;
    form: FormGroup;
    displayedColumns: string[] = ['atividade', 'subgrupo', 'ppd', 'acoes'];
    consultaAtividadeServico: any = [];
    parametroEntrada: any = {};
    parametroEntradas: any[] = [];
    atividadeCalculo: any[];
    parametroCalculoList: any;
    parametrosCalculoLista: any = [];
    calcularUpfRetornoDtoList: any[] = [];
    atividadeSemParametroeCaculo = false;
    errorStatusCalculoParametroEntrada = 200;
    atividadeGrupoDescricao: AtividadeArrecadacao[] = [];
    subGruposEncontrados: AtividadeArrecadacao[] = [];
    atividadePressionada: AtividadeArrecadacao;
    atividadeDescricaoSelect: AtividadeArrecadacao;
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoDto;
    tipoTitulacaoLac: any;
    titulacaoIsenta = true;
    pisiculturaExotico = false;
    regraApp = false;
    @ViewChild('parametro', {static: false})
    element: ElementRef;
    msg = '';
    desabilitaForm = false

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    async ngOnInit() {
        this.buildFormLicenciamento();
        await this.buscarTipoTitulacaoLac();
        this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId(this.requerimento.id).toPromise().then(async formularioReqLicenciamentoDto => {
            if (formularioReqLicenciamentoDto) {
                this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                    this.requerimentoLicenciamento = formularioReqLicenciamentoDto.requerimentoLicenciamento;
                    await this.patchForm();
                }
            }
        }).catch(async () => {
            if (this.requerimento.numeroProtocoloVinculado) {
                let formularioReqLicenciamentoDto = await this.requerimentoLicenciamentoService.passaDadosReqVinculadoLac(this.requerimento.id, this.activatedRoute.snapshot.url[3].path).toPromise()
                this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                    this.requerimentoLicenciamento = formularioReqLicenciamentoDto.requerimentoLicenciamento;
                    await this.patchForm();
                    this.validandoRegrasDigitalNato()
                }
            }
        });
    }
    

    private validandoRegrasDigitalNato() {
        if (this.requerimento.tipoRequerimento.id === 4 ||
        this.requerimento.tipoRequerimento.id === 32 ||
        this.requerimento.tipoRequerimento.id === 5 ||
        this.requerimento.tipoRequerimento.id === 6) {
            this.form.disable()
            this.desabilitaForm = true
        }
    }


    private async patchForm() {
        this.form.patchValue({id: this.requerimentoLicenciamento.id});
        this.form.patchValue({requerimento: this.requerimento});
        this.form.patchValue({idAtividade: this.requerimentoLicenciamento.idAtividade});
        this.form.patchValue({idGrupoAtividade: this.requerimentoLicenciamento.idGrupoAtividade});

        await this.carregarAtividade();
        await this.obterAtividadeCalculoParametroEntrada();
        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
            this.parametroEntradas = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramEntrada =>
                paramEntrada.tipoParametro === 'ENTRADA');

            if (this.parametroEntradas.length > 0) {
                this.parametroEntradas.forEach(paramEntrada => {
                    const event = {
                        target: {
                            value: paramEntrada.valor
                        }
                    };
                    this.onKey(event, paramEntrada, false);
                });
            }

            this.parametroCalculoList = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramCalc => paramCalc.tipoParametro === 'CALCULO');

            if (this.parametroCalculoList.length > 0) {
                this.parametroCalculoList.forEach(paramCalc => {
                    const event = {
                        target: {
                            value: paramCalc.valor
                        }
                    };
                    this.pegarValorDeParamCalc(event, paramCalc);
                });
            }
        }
    }

    protected buildFormLicenciamento(): void {
        this.form = this.formBuilder.group(
            {
                id: [null],
                requerimento: [this.requerimento, [Validators.required]],
                idAtividade: [null, [Validators.required]],
                idGrupoAtividade: [null, [Validators.required]],
                descricaoAtividade: [null],
                descricaoGrupoAtividade: [null],
                ppd: [null]
            });

        if (this.requerimento.numeroProtocoloVinculado && !this.isSomenteVisualizacao) {
            this.validandoRegrasDigitalNato()
        }
    }

    async pesquisaSubGrupoDesc(event) {
        this.atividadeGrupoDescricao = [];
        this.atividadeDescricaoSelect = null;
        const subGrupoDesc = event.target.value;
        if (subGrupoDesc.trim().length === 0) {
            this.atividadeGrupoDescricao = [];
            return;
        } else if (subGrupoDesc.trim().length > 2) {
            this.atividadeGrupoDescricao = [];
            this.subGruposEncontrados = await this.atividadeArrecadacaoService
                .getBySubGrupoDesc(subGrupoDesc)
                .toPromise();
        }
    }

    async buscarAtividadePorSubGrupo(atividade: AtividadeArrecadacao, consulta = false) {
        if (this.consultaAtividadeServico.length > 0) {
            this.snackBarService.showAlert('Já existe uma atividade cadastrada.');
            return;
        }
        this.parametroEntradas = [];
        this.parametroCalculoList = [];
        if (atividade != null) {
            this.atividadePressionada = atividade;
        }
        if (!consulta) {
            await this.atividadesPorId(this.atividadePressionada.id);
        } else {
            this.form.patchValue({idAtividade: this.atividadeDescricaoSelect.id});
            this.form.patchValue({idGrupoAtividade: this.atividadeDescricaoSelect.atividadePai.id});
            this.form.patchValue({descricaoAtividade: this.atividadeDescricaoSelect.descricao});
            this.form.patchValue({descricaoGrupoAtividade: this.atividadeDescricaoSelect.atividadePai.descricao});
            await this.atividadePorId();
            await this.salvarRequerimentoLicenciamento();
            this.atividadeDescricaoSelect = null;
        }
    }

    async carregarAtividade() {
        const atividade = await this.atividadeArrecadacaoService
            .getAtividadeDto(
                this.requerimentoLicenciamento.idAtividade,
                this.requerimentoLicenciamento.idGrupoAtividade
            )
            .toPromise();
        atividade['ppd'] = this.requerimentoLicenciamento.ppd;
        this.consultaAtividadeServico = [];
        this.consultaAtividadeServico.push(atividade);
    }

    async buscarTipoTitulacaoLac() {
        this.atividadeCalculoParametroEntradaService.buscarTipoTitulacao().toPromise().then( titulacoes => {
            this.tipoTitulacaoLac = titulacoes.find(titulacao => titulacao.descricao == 'LICENÇA POR ADESÃO E COMPROMISSO')
        }, () => this.snackBarService.showError("Ocorreu um erro ao buscar "))
    }

    async atividadesPorId(idAtividade) {
        await this.atividadeArrecadacaoService.getFilhasArrecadacaoParaId(idAtividade)
            .toPromise()
            .then(async atividadesParaDecricao => {
                this.atividadeGrupoDescricao = atividadesParaDecricao;
            }).catch(error => {
                this.snackBarService.showError('Ocorreu um erro ao consultar a atividade.', error);
            });
    }

    async atividadePorId() {
        await this.atividadeArrecadacaoService.getById(this.atividadeDescricaoSelect.id)
            .toPromise()
            .then(atividade => {
                this.atividadeDescricaoSelect['atividadePai'] = atividade;
            }).catch(error => {
                this.snackBarService.showError('Ocorreu um erro ao consultar a atividade.', error);
            });
    }

    async salvarRequerimentoLicenciamento() {
        const requerimentoLicenciamento = await this.requerimentoLicenciamentoService.create(this.form.value).toPromise();

        this.requerimentoLicenciamento = requerimentoLicenciamento;
        await this.carregarAtividade();
        this.form.patchValue({id: requerimentoLicenciamento.id});
        this.form.patchValue({idAtividade: requerimentoLicenciamento.idAtividade});
        this.form.patchValue({idGrupoAtividade: requerimentoLicenciamento.idGrupoAtividade});
        this.carregarFormularioLicenciamento();
        await this.obterAtividadeCalculoParametroEntrada();
    }

    private carregarFormularioLicenciamento() {
        this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId(this.requerimento.id).subscribe(formularioReqLicenciamentoDto => {
            this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
        });
    }

    async obterAtividadeCalculoParametroEntrada() {
        await this.atividadeCalculoParametroEntradaService
            .buscarParametrosEntradaPIdAtividade(this.consultaAtividadeServico[0].id, this.tipoTitulacaoLac.id)
            .toPromise().then(atividadeParametroCalculoEntrada => {
                this.atividadeSemParametroeCaculo = false;
                if (!atividadeParametroCalculoEntrada) {
                    this.obterAtividadeCalculoSemParametroEntradaeCalculo();
                } else {
                    this.parametroEntradas = atividadeParametroCalculoEntrada;
                }
            }).catch(async error => {
                error.status = 404;
                this.errorStatusCalculoParametroEntrada = error.status;
                if (this.errorStatusCalculoParametroEntrada === 404) {
                    await this.obterAtividadeCalculoSemParametroEntradaeCalculo();
                }
            });
    }

    obterAtividadeCalculoSemParametroEntradaeCalculo() {
        let msg = true;
        this.atividadeCalculoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicenca())
            .toPromise().then(async atividadeCalculo => {
            this.atividadeCalculo = atividadeCalculo;
            this.parametroCalculoList = [...this.atividadeCalculo].shift();
            if (this.parametroCalculoList.parametroCalculoList.length === 0) {
                this.atividadeSemParametroeCaculo = false;
                await this.calcularLicenca();
            } else {
                this.snackBarService.showAlert('Para os parâmetros apresentados sua atividade não é passível de licenciamento.');
            }
        }).catch(() => {
            msg = false;
        });

        msg ? this.snackBarService.showSuccess('Licenciamento processado com sucesso') :
            this.snackBarService.showError('Ocorreu um erro na busca do licenciamento, tente novamente.');
    }

    montarObjetoCalculoUpf(idAtividadeCalculo) {

        this.parametrosCalculoLista.forEach(param => {
            delete param.id;
        });

        return {
            idAtividadeCalculo: idAtividadeCalculo,
            parametrosCalculo: this.parametrosCalculoLista,
            parametroEntrada: this.parametroEntrada
        };
    }

    async calcularLicenca() {
        this.calcularUpfRetornoDtoList = [];
        for (let i = 0; i < this.atividadeCalculo.length; i++) {
            await this.atividadeCalculoService
                .calcularUpf(this.montarObjetoCalculoUpf(this.atividadeCalculo[i].id)).toPromise().then(calcularUpfRetornoDto => {
                    if (calcularUpfRetornoDto.atividadeCalculo.tipoTitulacao.id === 1) {
                        if (this.calcularUpfRetornoDtoList.length === 0) {
                            if (calcularUpfRetornoDto.valorUpf === 0) {
                                this.titulacaoIsenta = false;
                            } else {
                                this.titulacaoIsenta = true;
                                this.form.patchValue({ppd: calcularUpfRetornoDto.atividadeCalculo.poluicao.descricao});
                            }
                            this.calcularUpfRetornoDtoList.push(calcularUpfRetornoDto);
                        }
                    } else {
                        this.titulacaoIsenta = true;
                    }
                }).catch(() => {
                });
        }
    }

    onKey(event, atividadeCalculoparamEntrada, timeOut = true) {
        if (event.target.value !== null && atividadeCalculoparamEntrada) {
            this.parametroEntrada.valor = event.target.value;
            this.parametroEntrada.descricao = atividadeCalculoparamEntrada.descricao;
            this.parametroEntrada.labelFormulario = atividadeCalculoparamEntrada.labelFormulario;
            if (event.target.value.trim().toString().length > 0 && event.target.value.trim().toString() !== '') {
                if (timeOut) {
                    setTimeout(async () => {
                        if (this.element) {
                            this.element.nativeElement.disabled = true;
                        }
                        await this.obterAtividadeCalculoDoParametroDeEntrada();
                        if (this.element) {
                            this.element.nativeElement.disabled = false;
                        }
                    }, 1500);
                } else {
                    setTimeout(async () => {
                        await this.obterAtividadeCalculoDoParametroDeEntrada();
                    }, 0);
                }
            } else if (event.target.value.toString().length === 0) {
                this.parametroCalculoList = null;
                this.atividadeCalculo = [];
                if (this.element) {
                    this.element.nativeElement.disabled = false;
                }
            }
        }
    }

    pegarValorDeParamCalc(event, parametroEntradas: any) {
        this.montarObjetoParamEntrada(parametroEntradas, event.target.value);
    }

    montarObjetoParamEntrada(parametroEntradas, valor) {

        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (this.parametrosCalculoLista.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoLista.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoLista.splice(this.parametrosCalculoLista.indexOf(paramCalc), 1);
                    this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
                }
            });
        } else {
            this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
        }
    }

    async obterAtividadeCalculoDoParametroDeEntrada() {
        this.parametroCalculoList = null;
        let msg = true;
        await this.atividadeCalculoService.buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoLicenca()).toPromise().then(atividadeCalculo => {
            this.atividadeCalculo = atividadeCalculo;
            this.parametroCalculoList = [...this.atividadeCalculo].shift();
            if (!this.parametroCalculoList) {
                this.snackBarService.showAlert('Para os parâmetros apresentados sua atividade não é passível de licenciamento.');
            } else {
                for (let i = 0; i < this.parametroCalculoList.parametroCalculoList.length; i++) {
                    if (this.parametroCalculoList.parametroCalculoList[i].labelFormulario === this.parametroEntradas[0].labelFormulario) {
                        const event = {
                            target: {
                                value: 1
                            }
                        };
                        this.pegarValorDeParamCalc(event, this.parametroCalculoList.parametroCalculoList[i]);
                        this.parametroCalculoList.parametroCalculoList.splice(i, 1);
                        this.parametroCalculoList.parametroCalculoList = this.parametroCalculoList.parametroCalculoList.slice();
                    }
                }
            }
        }).catch(() => {
            msg = false;
            this.snackBarService.showAlert('Para os parâmetros apresentados sua atividade não é passível de licenciamento.');
        });

        if (!msg) {
            this.atividadeCalculoParametroEntradaService.buscaAtividaeParametroCalculoPorIdAtividade(this.consultaAtividadeServico[0].idAtividadeArrecadacao).subscribe(data => {
                if (data && data.length > 0) {
                    data = data.filter(atv => atv.atividadeCalculo.tipoTitulacao.id === 1);
                    if (data.length > 0) {
                        const atv = data[0];
                        this.msg = `Para essa atividade, os parâmetros dever ser informados em mínima: ${atv.valorMinimo} e máxima: ${atv.valorMaximo}`;
                        this.snackBarService.showAlert(this.msg);
                    }
                }
            }, () => this.snackBarService.showAlert('Para os parâmetros apresentados sua atividade não é passível de licenciamento.'));
        } else {
            this.msg = '';
        }

    }

    montarObjetoLicenca() {

        return {
            idAtividade: this.consultaAtividadeServico[0].id,
            parametroEntrada: this.parametroEntrada,
            pisiculturaExotico: false,
            regraApp: false,
            idTipoTitulacao: this.tipoTitulacaoLac.id,
        };
    }

    montarObjetoParametroSemLicenca() {

        return {
            idAtividade: this.consultaAtividadeServico[0].id,
            pisiculturaExotico: false,
            regraApp: false,
            idTipoTitulacao: this.tipoTitulacaoLac.id,
        };
    }

    async salvar() {
        this.requerimentoFormularioLicenciamentoService.salvarFormularioCalculoDeLicenca(this.montarFormularioReqLicenDTO()).toPromise().then(formularioReqLicenciamentoDto => {
            this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
        }).catch(error => {
            this.snackBarService.showError('Ocorreu um erro', error);
        });
    }

    private montarFormularioReqLicenDTO() {
        const lancamentoTaxa = new LancamentoTaxaDto();
        if (this.formularioReqLicenciamentoDto) {
            if (this.formularioReqLicenciamentoDto.lancamentoTaxa) {
                lancamentoTaxa.id = this.formularioReqLicenciamentoDto.lancamentoTaxa.id;
            }
        }
        lancamentoTaxa.requerimento = this.requerimento;
        lancamentoTaxa.qtdePonto = 1;
        lancamentoTaxa.qtdeTotalUPF = this.calcularUpfRetornoDtoList[0].valorUpf;
        lancamentoTaxa.qtdeUnitarioUPF = this.calcularUpfRetornoDtoList[0].valorUpf;
        lancamentoTaxa.idAtividadeCalculo = this.calcularUpfRetornoDtoList[0].atividadeCalculo.id;

        // mudar para titulacão lac
        const titulacao = new Titulacao();
        titulacao.id = 100;
        lancamentoTaxa.titulacao = titulacao;

        const lancamentoTaxaParamCalculoDto = [];
        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
            const paramCalcFind = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.find(param => param.labelFormulario === this.parametroEntrada.labelFormulario);
            lancamentoTaxaParamCalculoDto
                .push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(this.parametroEntrada, lancamentoTaxa, 'ENTRADA', paramCalcFind.id));
        } else {
            lancamentoTaxaParamCalculoDto
                .push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(this.parametroEntrada, lancamentoTaxa, 'ENTRADA'));
        }

        this.parametrosCalculoLista.forEach(paramCalc => {
            if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                const paramCalcFind = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.find(param => param.labelFormulario === paramCalc.labelFormulario);
                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO', paramCalcFind.id));
            } else {
                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO'));
            }
        });

        this.formularioReqLicenciamentoDto.requerimentoLicenciamento = this.form.value;
        this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList = lancamentoTaxaParamCalculoDto;
        this.formularioReqLicenciamentoDto.lancamentoTaxa = lancamentoTaxa;
        if (this.atividadeSemParametroeCaculo) {
            this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = true;
        } else {
            this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = false;
        }

        return this.formularioReqLicenciamentoDto;
    }

    deletarRequerimentoLicenciamentoAtividade() {
        this.requerimentoFormularioLicenciamentoService.delete(this.requerimentoLicenciamento.id).subscribe(() => {
            this.resetAll();
            this.snackBarService.showSuccess('Deletado com sucesso');
        }, error => this.snackBarService.showError('Ocorreu um erro', error));
    }

    private resetAll() {
        this.consultaAtividadeServico = [];
        this.parametroCalculoList = [];
        this.parametroEntradas = [];
        this.formularioReqLicenciamentoDto = null;
        this.requerimentoLicenciamento = {};
        this.form.reset();
        this.form.patchValue({requerimento: this.requerimento});
    }

    async selecionarAtividadeDescricao(atividadeG: AtividadeArrecadacao) {
        this.atividadeDescricaoSelect = atividadeG;
        const atividadeArrecadacao = await this.atividadeArrecadacaoService
            .getAtividadeId(atividadeG.id)
            .toPromise();
        let tanqueExotico = false;
        if (atividadeArrecadacao.descricao === 'Piscicultura Tanques-rede') {
            const dialogRef = this.dialog.open(ValidacaoRegraAppDialogComponent, {
                width: '500px',
                data: {texto: 'A atividade é para criação de peixes exóticos?'},
                disableClose: true
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    tanqueExotico = true;
                    this.pisiculturaExotico = true;
                }
            });
        }

        if (atividadeArrecadacao.regraApp && !tanqueExotico) {
            const dialogRef = this.dialog.open(ValidacaoRegraAppDialogComponent, {
                width: '500px',
                data: {texto: 'A atividade selecionada será desempenhada em Área de Preservação Permanente?'},
                disableClose: true
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.regraApp = true;
                }
            });
        }
    }
    
    async validarAba(erros: ErrosAbaRequerimento): Promise<any> {
        
        if (this.msg != '') {
            erros.push(this.msg);
        }
        await this.calcularLicenca();
        
        if (this.form.invalid) {
            erros.push('Selecione uma atividade.');
        }
        
        if (!this.titulacaoIsenta) {
            this.snackBarService.showAlert('Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020');
        }

        if (this.calcularUpfRetornoDtoList.length === 0) {
            erros.push('Sua atividade não se enquadra no Licenciamento por Adesão e Compromisso, por favor solicitar o seu licenciamento diretamente a SEMA-MT.');
        }
        if (erros.length === 0) {
            return await Promise.all([
                this.salvar()
            ]).then(() => Promise.resolve());
        }
        return Promise.resolve();
       
    }

}
