import {BaseModel} from "../base.model";

export class ConfiguracaoLicenciamento extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public idTipoProcesso?: number,
        public setorResponsavel?: number,
        public situacao?: boolean,
        public cadastro?: Date,
        public atualizacao?: Date,
        public idTemplateParecerTecnico?: number,
        public idSetorResponsavel?: number,
    ) {
        super();
    }

    static fromJson(json: any): ConfiguracaoLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new ConfiguracaoLicenciamento(
            json.id, 
            json.descricao,
            json.idTipoProcesso,
            json.setorResponsavel,
            json.situacao,
            json.cadastro,
            json.atualizacao,
            json.idTemplateParecerTecnico,
            json.idSetorResponsavel,
        );
    }
}
