import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {ParecerTecnicoCondicionante} from './parecer-tecnico-condicionantes.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ParecerTecnicoCondicionantesService extends BaseService<ParecerTecnicoCondicionante> {
    constructor(protected injector: Injector) {
        super('/api/pareceres-tecnicos', injector, ParecerTecnicoCondicionante.prototype, ParecerTecnicoCondicionante.fromJson);
    }

    getByParecer(
        idParecer: number
    ): Observable<ParecerTecnicoCondicionante[]> {
        const url = `${this.urlResource}/${idParecer}/condicionantes`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    addOrUpdateCondicionante(
        resource: ParecerTecnicoCondicionante,
        itemsResource: any = null
    ): Observable<ParecerTecnicoCondicionante> {
        const dataCondicionante = {
            descricao: resource.descricao,
            numero: parseInt(resource.numero.toString(), 0),
            // situacao: resource.situacao, // Criado por padrão
            tipoPrazo: parseInt(resource.tipoPrazo.valor, 0),
            tipoResposta: parseInt(resource.tipoResposta.valor, 0),
            parecerTecnico: {id: resource.parecerTecnico.id},
            tipoCondicionante: {id: resource.tipoCondicionante.id}
        };
        if (dataCondicionante.tipoResposta === 1) { // Arquivo
            dataCondicionante['tipoArquivo'] = parseInt(resource.tipoArquivo.valor, 0);
        }
        if (dataCondicionante.tipoResposta === 2) { // Formulário
            dataCondicionante['tipoFormulario'] = resource.tipoFormulario;
            // Recuperar itens do formulário da qualidade de água
            if (dataCondicionante['tipoFormulario']) { // Qualidade de Água
                dataCondicionante[itemsResource.key] = itemsResource.value;
            }
        }
        if (resource.tipoPrazo.valor === '1') { // Periodicidade
            dataCondicionante['tipoPeriodo'] = parseInt(resource.tipoPeriodo.valor, 0);
            dataCondicionante['periodo'] = resource.periodo;
            dataCondicionante['dataVencimento'] = resource.dataVencimento;
        }
        if (resource.tipoPrazo.valor === '2') { // Vencimento
            dataCondicionante['dataVencimento'] = resource.dataVencimento;
        }
        if (resource.id) { // Edit
            const url = `${this.urlResource}/${resource.parecerTecnico.id}/condicionantes/${resource.id}`;
            return this.http.put(url, dataCondicionante).pipe(
                map(e => true),
                catchError(this.handleError.bind(this))
            );
        } else { // Create
            const url = `${this.urlResource}/${resource.parecerTecnico.id}/condicionantes`;
            return this.http.post(url, dataCondicionante).pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
        }
    }

    deleteById(
        idParecer: number,
        id: number
    ): Observable<ParecerTecnicoCondicionante> {
        const url = `${this.urlResource}/${idParecer}/condicionantes/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    createMultipartGeo(
        parecerId: number,
        parecerCondicionanteId: number,
        metodo: string,
        file: Blob
    ): Observable<any> {
        const url = `${this.urlResource}/${parecerId}/condicionantes/${parecerCondicionanteId}/dado-geoespacial`;
        return this.http
            .put(
                url,
                this.montarFormData(
                    metodo,
                    file
                )
            )
            .pipe(
                map(() => null),
                catchError(this.handleError.bind(this))
            );
    }

    private montarFormData(
        metodo: string,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('file', multipartFile);
        formData.append('metodo', metodo);
        return formData;
    }

    pesquisaCondPorComentario(comentario: string): Observable<ParecerTecnicoCondicionante[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('descricao', comentario);
        const url = `${this.urlResource}/condicionantes/pesquisar-descricao`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    copiarCondicionante(idParecerTecnico: number, parecerTecnicoCondicionante: ParecerTecnicoCondicionante): any {
        const url = `${this.urlResource}/${idParecerTecnico}/copiar-condicionante`;
        return this.http.post(url, parecerTecnicoCondicionante).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }
}
