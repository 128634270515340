import {BaseModel} from "../../../../../shared/models/base.model";
import {ModelUtil} from "../../../../../shared/models/model-util";

export class MetodoSistemaIrrigacao extends BaseModel {
    constructor(
        public id?: number,
        public valor?: string,
        public descricao?: string,
        public minimaEficiencia?: number
    ) {
        super();
    }

    static fromJson(json: any): MetodoSistemaIrrigacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new MetodoSistemaIrrigacao(
            json.id,
            ModelUtil.getOrElseNull(json.valor),
            ModelUtil.getOrElseNull(json.descricao),
            ModelUtil.getOrElseNull(json.minimaEficiencia)
        );
    }
}
