import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Processo} from '../models/processo.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {ProcessDefinition} from "../models/bpms/process-definition";

@Injectable({
    providedIn: 'root'
})
export class ProcessoService extends BaseService<Processo> {
    constructor(protected injector: Injector) {
        super('/api/processo', injector, Processo.prototype, Processo.fromJson);
    }

    findByNumero(numero: string): Observable<Processo> {
        return this.http.get(`${this.urlResource}/numero/${numero}`).pipe(
            map((response) => this.jsonToResourceFn(response)),
            catchError(this.handleError.bind(this)
            )
        );
    }

    findByRequerimentoId(requerimentoId: string): Observable<Processo> {
        return this.http.get(`${this.urlResource}/requerimento/${requerimentoId}`).pipe(
            map((response) => this.jsonToResourceFn(response)),
            catchError(this.handleError.bind(this)
            )
        );
    }

    findProcessosVinculados(requerimentoId: string): Observable<Processo> {
        return this.http.get(`${this.urlResource}/requerimento/${requerimentoId}/processos-vinculados`).pipe(
            map((response) => this.jsonToResourceFn(response)),
            catchError(this.handleError.bind(this)
            )
        );
    }

    getDefinitions(): Observable<ProcessDefinition[]> {
        return this.http.get(`${this.urlResource}/definitions`).pipe(
            map((response) => ProcessDefinition.fromJsons(response)),
            catchError(this.handleError.bind(this)
            )
        );
    }
}
