import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {JuntadaProcesso} from '../models/juntada-processo.model';

@Injectable({
    providedIn: 'root'
})
export class JuntadaProcessoService extends BaseService<JuntadaProcesso> {

    constructor(protected injector: Injector) {
        super(
            '/api/juntadas-processo',
            injector,
            JuntadaProcesso.prototype,
            JuntadaProcesso.fromJson
        );
    }

    buscaListaJuntadaPorIdRequerimento(idRequerimento: number): Observable<any[]> {
        const url = `${this.urlResource}/requerimento-principal/${idRequerimento}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    emitirJuntadaProcesso(file: FormData): Observable<any> {
        const url = `${this.urlResource}`;
        return this.http.post(url, file)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

}