import { RequerimentoTaskDTO } from "./requerimento-task.dto";

export class NovaAtribuicaoProcessosDTO {
    constructor(
        public cpfNovoAnalista?: string,
        public justificativa?: string,
        public requerimentosTaskDTO?: RequerimentoTaskDTO[]
    ) {
    }

    static fromJson(json: any): NovaAtribuicaoProcessosDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new NovaAtribuicaoProcessosDTO(
            json.cpfNovoAnalista,
            json.justificativa,
            json.requerimentosTaskDTO
        );
    }
}