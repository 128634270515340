import {Component, Input, OnInit} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {BaseModel} from '../../../models/base.model';

@Component({
    selector: 'app-aba-objetivo-obras-hidraulicas',
    templateUrl: 'aba-objetivo-obras-hidraulicas.component.html'
})
export class AbaObjetivoObrasHidraulicasComponent implements OnInit {
    @Input() ponto: Ponto;

    constructor() {
    }

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    ngOnInit(): void {
    }

}
