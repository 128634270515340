import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmpreendimentoSubEstacao} from 'app/main/shared/models/empreendimento-subestacao.model';
import {EmpreendimentoService} from 'app/main/shared/services/empreendimento.service';
import {
    checkboxBooleanRequiredDirective
} from "../../../../../../../shared/directives/checkbox-boolean-required.directive";
import {notEmptyDirective} from "../../../../../../../shared/directives/not-empty.directive";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-ee-subestacao',
    templateUrl: './ee-subestacao.component.html'
})

export class EeSubestacaoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    isNaoPodeEditar: boolean = false;

    form: FormGroup = this.fb.group({
        id: [null],
        requerimento: [null],
        derivaEnergiaLocal: [{
            value: null,
            disabled: this.isModoVisualizacao
        }, [Validators.required]],
        nomeRazaoSocial: [{
            value: null,
            disabled: this.isModoVisualizacao
        }],
        cnpjCpf: [{value: null, disabled: this.isModoVisualizacao}],
        licencaAmbiental: [{value: null, disabled: this.isModoVisualizacao}],
        tipoConstrutivo: [{
            value: null,
            disabled: this.isModoVisualizacao
        }, [Validators.required]],
        possuiSubestacaoOperacaoPresencial: [{
            value: null,
            disabled: this.isModoVisualizacao
        }],
        possuiSubEstacaoSupervisionada: [{
            value: null,
            disabled: this.isModoVisualizacao
        }],
        possuiSubestacaoExterna: [{
            value: false,
            disabled: this.isModoVisualizacao
        }],
        possuiSubestacaoInterna: [{
            value: false,
            disabled: this.isModoVisualizacao
        }],
        possuiSubestacaoTerrea: [{
            value: false,
            disabled: this.isModoVisualizacao
        }],
        possuiSubestacaoAerea: [{
            value: false,
            disabled: this.isModoVisualizacao
        }],
        componentesSubestacao: [{
            value: [],
            disabled: this.isModoVisualizacao
        }, [Validators.required]],
        estruturasSuporte: [{
            value: [],
            disabled: this.isModoVisualizacao
        }, [Validators.required, notEmptyDirective]],
        funcoes: [{
            value: [],
            disabled: this.isModoVisualizacao
        }, [Validators.required, notEmptyDirective]],
        meiosIsolacao: [{
            value: [],
            disabled: this.isModoVisualizacao
        }, [Validators.required, notEmptyDirective]],
        meiosIsolamento: [{
            value: [],
            disabled: this.isModoVisualizacao
        }, [Validators.required, notEmptyDirective]],
        subestacoesInternas: [{value: [], disabled: this.isModoVisualizacao}],
    });

    nomeForm: string = 'Empreendimentos Energéticos - Subestação';

    constructor(injector: Injector,
                private fb: FormBuilder,
                private empreendimentoService: EmpreendimentoService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
                private snackBarService: SnackBarService) {
        super();
    }


    async ngOnInit() {
        this.form.get('possuiSubestacaoOperacaoPresencial').setValidators([Validators.required, checkboxBooleanRequiredDirective(this.form, '', 'possuiSubEstacaoSupervisionada')]);
        this.form.get('possuiSubEstacaoSupervisionada').setValidators([Validators.required, checkboxBooleanRequiredDirective(this.form, '', 'possuiSubestacaoOperacaoPresencial')]);
        this.form.get('possuiSubestacaoExterna').setValidators([Validators.required, checkboxBooleanRequiredDirective(this.form, '', 'possuiSubestacaoInterna')]);
        this.form.get('possuiSubestacaoInterna').setValidators([Validators.required, checkboxBooleanRequiredDirective(this.form, '', 'possuiSubestacaoExterna')]);
        this.form.get('possuiSubestacaoTerrea').setValidators([Validators.required, checkboxBooleanRequiredDirective(this.form, '', 'possuiSubestacaoAerea')]);
        this.form.get('possuiSubestacaoAerea').setValidators([Validators.required, checkboxBooleanRequiredDirective(this.form, '', 'possuiSubestacaoTerrea')]);

        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarEeSubestacao(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarEeSubestacao(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.form.disable();
        }
    }

    async carregarEeSubestacao(idRequerimento){
        let res = await this.empreendimentoService.getByRequerimento(idRequerimento).toPromise();

        if (res) {
            this.form.patchValue(res || {requerimento: this.requerimento.id});
        }
    }

    async salvarFormulario() {
        this.form.markAllAsTouched();

        if (this.form.valid) {
            let res = EmpreendimentoSubEstacao.fromJson(this.form.value);
            res.requerimento = this.requerimento.id;
            if (res.id === null) {
                this.empreendimentoService.create(res).subscribe(
                    resource => {
                        this.enviarFormConcluido.emit();
                    }
                );
            } else {
                this.empreendimentoService.update(res).subscribe(
                    resource => {
                        this.enviarFormConcluido.emit();
                    }
                );
            }
        } else {
            this.snackBarService.showError("O formulário " + this.nomeForm + " possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

}
