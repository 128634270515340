import {BaseModel} from 'app/main/shared/models/base.model';
import {ItemTermoReferenciaRequerimento} from './item-termo-referencia-requerimento.model';
import {BaseEnum} from '../enums/base.enum';
import {ServidorLicenciamento} from '../../pages/tarefas/shared/model/servidor-licenciamento.model';

export class ItemTermoReferenciaRequerimentoChecklist extends BaseModel {
    constructor(
        public id?: number,
        public statusAnalise?: string,
        public comentario?: string,
        public rascunho?: boolean,
        public dataInicio?: Date,
        public itemTermoReferenciaRequerimento?: ItemTermoReferenciaRequerimento,
        public servidor?: ServidorLicenciamento
    ) {
        super();
    }

    static statusAnalises: BaseEnum[] = [
        {valor: 'NAO_AVALIADO', descricao: 'Não avaliado'},
        {valor: 'ATENDIDO', descricao: 'Atendido'},
        {valor: 'NAO_ATENDE', descricao: 'Não atende'},
        {valor: 'DISPENSADO', descricao: 'Dispensado'},
    ];

    static fromJson(json: any): ItemTermoReferenciaRequerimentoChecklist {
        if (json === undefined || json === null) {
            return null;
        }
        return new ItemTermoReferenciaRequerimentoChecklist(
            json.id,
            json.statusAnalise,
            json.comentario,
            json.rascunho,
            json.dataInicio,
            ItemTermoReferenciaRequerimento.fromJson(json.itemTermoReferenciaRequerimento),
            ServidorLicenciamento.fromJson(json.servidor)
        );
    }

    static fromJsons(json: any[]): ItemTermoReferenciaRequerimentoChecklist[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(resource =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }

}

export interface ItemTermoReferenciaRequerimentoChecklistFormGroup {
    id: number;
    itemTermoReferenciaRequerimento: ItemTermoReferenciaRequerimento;
    status: string;
    comentario: string;
}

export class HistoricoConferenciaChecklist extends BaseModel {
    constructor(
        public itemTermoReferenciaRequerimentoChecklist?: ItemTermoReferenciaRequerimentoChecklist[],
        public itemTermoReferencia?: ItemTermoReferenciaRequerimento
    ) {
        super();
    }
}
