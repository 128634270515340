import {BaseModel} from 'app/main/shared/models/base.model';
import {Municipio} from 'app/main/shared/models/geoportal-base-referencia/municipio.model';
import {RequerimentoOutorga} from 'app/main/pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import {TipoCorpoHidrico} from '../tipo-corpo-hidrico.model';
import {Captacao} from '../captacao.model';
import {ObjetivoRequerimentoOutorga} from '../objetivo-requerimento-outorga.model';
import {Tamponamento} from '../tamponamento.model';
import {PerfuracaoPoco} from '../perfuracao-poco.model';
import {Titulacao} from '../../../pages/titulacoes/titulacao.model';
import {CaptacaoInsignificante} from '../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/titulacao-captacao-insignificante/captacao-insignificante.model';
import {LancamentoEfluente} from '../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-lancamentos-efluentes/models/lancamento-efluente.model';
import {ObraBarragem} from '../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-obra-barragem/obra-barragem.model';
import {AguaSubterranea} from '../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-agua-subterranea/agua-subterranea.model';
import {BarragemExistente} from '../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-barragem-existente/barragem-existente.model';
import {AguaSubterraneaIrrigacao} from '../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-agua-subterranea-irrigacao/model/agua-subterranea-irrigacao.model';
import {ParametroHidrico} from './parametro-hidrico.model';
import {Finalidade} from '../../../pages/finalidades/finalidade.model';
import {DRDH} from './drdh.model';
import {PocoTubularIrrigacaoModel} from '../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-poco-irrigacao/poco-tubular-irrigacao.model';

export class Ponto extends BaseModel {
    constructor(
        public id?: number,
        public nomePonto?: string,
        public requerimentoOutorga?: RequerimentoOutorga,
        public idCalculoHidrico?: number,
        public idCalculoHidricoLegado?: number,
        public codigoIbgeMunicipio?: number,
        public municipio?: Municipio,
        public latitude?: number,
        public longitude?: number,
        public vazao?: number,
        public vazaoEfluente?: number,
        public tipoCorpoHidrico?: TipoCorpoHidrico,
        public outroTipoCorpoHidrico?: string,
        public corpoHidrico?: string,
        public corpoHidricoGeo?: string,
        public cursoAguaObra?: string,
        public captacaoInsignificante?: CaptacaoInsignificante,
        public lancamentoEfluente?: LancamentoEfluente,
        public captacao?: Captacao,
        public perfuracaoPoco?: PerfuracaoPoco,
        public obra?: any,
        public cadastroConcluido?: boolean,
        public tamponamento?: Tamponamento,
        public objetivoRequerimento?: ObjetivoRequerimentoOutorga,
        public idParametro?: number,
        public concentracaoParametro?: number,
        public formularioConcluido?: boolean,
        public titulacao?: Titulacao,
        public codigoUPG?: string,
        public unidadeUPG?: string,
        public geologia?: string,
        public idMicrobacia?: number,
        public obraBarragem?: ObraBarragem,
        public aguaSubterranea?: AguaSubterranea,
        public barragemExistente?: BarragemExistente,
        public aguaSubterraneaIrrigacao?: AguaSubterraneaIrrigacao,
        public pocoTubularIrrigacao?: PocoTubularIrrigacaoModel,
        public parametro?: ParametroHidrico,
        public finalidade?: Finalidade,
        public drdh?: DRDH,
        public dataProcessamento?: Date,
        public pontoBalancoHidrico?: boolean,
        public permiteEnvioProcessamentoCoordenada?: boolean
    ) {
        super();
    }

    static fromJson(json: any): Ponto {
        if (json === undefined || json === null) {
            return null;
        }
        return new Ponto(
            json.id ? json.id : null,
            json.nomePonto ? json.nomePonto : null,
            json.requerimentoOutorga ? RequerimentoOutorga.fromJson(json.requerimentoOutorga) : null,
            json.idCalculoHidrico ? json.idCalculoHidrico : null,
            json.idCalculoHidricoLegado ? json.idCalculoHidricoLegado : null,
            json.codigoIbgeMunicipio ? json.codigoIbgeMunicipio : null,
            json.municipio ? Municipio.fromJson(json.municipio) : null,
            parseFloat(json.latitude) ? parseFloat(json.latitude) : null,
            parseFloat(json.longitude) ? parseFloat(json.longitude) : null,
            json.vazao ? json.vazao : null,
            json.vazaoEfluente ? json.vazaoEfluente : null,
            json.tipoCorpoHidrico ? TipoCorpoHidrico.fromJson(json.tipoCorpoHidrico) : null,
            json.outroTipoCorpoHidrico ? json.outroTipoCorpoHidrico : null,
            json.corpoHidrico ? json.corpoHidrico : null,
            json.corpoHidricoGeo ? json.corpoHidricoGeo : null,
            json.cursoAguaObra ? json.cursoAguaObra : null,
            CaptacaoInsignificante.fromJson(json.captacaoInsignificante) ? CaptacaoInsignificante.fromJson(json.captacaoInsignificante) : null,
            LancamentoEfluente.fromJson(json.lancamentoEfluente) ? LancamentoEfluente.fromJson(json.lancamentoEfluente) : null,
            json.captacao ? Captacao.fromJson(json.captacao) : null,
            json.perfuracaoPoco ? PerfuracaoPoco.fromJson(json.perfuracaoPoco) : null,
            json.obra ? json.obra : null,
            json.cadastroConcluido ? json.cadastroConcluido : false,
            json.tamponamento ? Tamponamento.fromJson(json.tamponamento) : null,
            json.objetivoRequerimento ? ObjetivoRequerimentoOutorga.fromJson(json.objetivoRequerimento) : null,
            json.idParametro ? json.idParametro : null,
            json.concentracaoParametro ? json.concentracaoParametro : null,
            json.formularioConcluido ? json.formularioConcluido : false,
            json.titulacao ? json.titulacao : null,
            json.codigoUPG ? json.codigoUPG : null,
            json.unidadeUPG ? json.unidadeUPG : null,
            json.geologia ? json.geologia : null,
            json.idMicrobacia ? json.idMicrobacia : null,
            json.obraBarragem ? json.obraBarragem : null,
            json.aguaSubterranea ? json.aguaSubterranea : null,
            json.barragemExistente ? json.barragemExistente : null,
            json.aguaSubterraneaIrrigacao ? AguaSubterraneaIrrigacao.fromJson(json.aguaSubterraneaIrrigacao) : null,
            json.pocoTubularIrrigacao ? PocoTubularIrrigacaoModel.fromJson(json.pocoTubularIrrigacao) : null,
            json.parametro ? ParametroHidrico.fromJson(json.parametro) : null,
            json.finalidade ? Finalidade.fromJson(json.finalidade) : null,
            json.drdh ? DRDH.fromJson(json.drdh) : null,
            json.dataProcessamento ? json.dataProcessamento : null,
            json.pontoBalancoHidrico ? json.pontoBalancoHidrico : false,
            json.permiteEnvioProcessamentoCoordenada ? json.permiteEnvioProcessamentoCoordenada : false
        );
    }

    static fromJsons(json: any): Ponto[] {
        const pontos: Ponto[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(ponto => pontos.push(Ponto.fromJson(ponto)));
            return pontos;
        }
    }

    temFormulario(): boolean {
        return this.captacaoInsignificante ||
            this.lancamentoEfluente ||
            this.captacao ||
            this.obra ||
            this.obraBarragem ||
            this.barragemExistente ||
            this.tamponamento ||
            this.pocoTubularIrrigacao ||
            this.aguaSubterranea ||
            this.aguaSubterraneaIrrigacao ||
            this.perfuracaoPoco;
    }

}
