import {RequerimentoOutorga} from '../../../requerimentos/requerimento-outorga/requerimento-outorga.model';
import {Requerimento} from '../../../requerimentos/requerimento.model';
import {AnaliseRequerimentoAnalista} from './analise-requerimento-analista.model';

export class AnaliseRequerimentoEquipe {
    constructor(
        public id: number = null,
        public requerimento: RequerimentoOutorga = null,
        public complexidade: string = null,
        public analistas: AnaliseRequerimentoAnalista[] = [],
        public publicarComposicaoEquipe: boolean = false,
        public situacao: string = null,
        public prazo: number = null,
        public dataDesativacao: Date = null,
        public idOrganizacaoAdministrativa: number = null,
    ) {
    }

    static fromJson(json: any): AnaliseRequerimentoEquipe {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnaliseRequerimentoEquipe(
            json.id,
            Requerimento.fromJson(json.requerimento),
            json.complexidade,
            AnaliseRequerimentoAnalista.fromJsons(json.analistas),
            json.publicarComposicaoEquipe,
            json.situacao,
            json.prazo,
            json.dataDesativacao,
            json.idOrganizacaoAdministrativa,
        );
    }
}
