import {FeicaoAnaliseRequerimento} from '../../pages/feicoes-analise-requerimentos/feicao-analise-requerimento.model';
import {FeicaoGeometriaDto} from './feicao-geometria-dto.model';

export class FeicaoAnaliseGeometriaDto {
    constructor(
        public feicaoAnaliseRequerimento?: FeicaoAnaliseRequerimento,
        public wkt?: string
    ) {
    }

    static fromJson(json: any): FeicaoAnaliseGeometriaDto {
        if (!json) {
            return null;
        }
        return new FeicaoAnaliseGeometriaDto(
            FeicaoAnaliseRequerimento.fromJson(json.feicaoAnaliseRequerimento),
            json.wkt
        );
    }

    static fromJsons(jsonArray: any): FeicaoGeometriaDto[] {
        const resources = [];
        if (!jsonArray) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(FeicaoAnaliseGeometriaDto.fromJson(resource)));
            return resources;
        }
    }
}
