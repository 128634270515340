import {DetailResolver} from './../../shared/resolvers/detail-resolver';
import {ListResolver} from './../../shared/resolvers/list-resolver';
import {Injectable, Injector} from '@angular/core';
import {ItemTermoReferencia} from './item-termo-referencia.model';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {TermoReferencia} from '../termos-referencia/termo-referencia.model';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class ItemTermoReferenciaListResolver extends ListResolver<ItemTermoReferencia> {
    constructor(service: ItemTermoReferenciaService) {
        super(service);
    }
}

@Injectable()
export class ItemTermoReferenciaResolver extends DetailResolver<ItemTermoReferencia> {
    constructor(service: ItemTermoReferenciaService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ItemTermoReferenciaService extends BaseService<ItemTermoReferencia> {

    constructor(protected injector: Injector) {
        super('/api/itens-termo-referencia', injector, ItemTermoReferencia.prototype, ItemTermoReferencia.fromJson);
    }

    create(itemTermoReferencia: ItemTermoReferencia, file?: File): Observable<ItemTermoReferencia> {
        const url = this.urlResource + (file ? '/file' : '');
        return this.http.post(url, file ? this.createFormData(itemTermoReferencia, file) : itemTermoReferencia).pipe(
            map(ItemTermoReferencia.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    update(itemTermoReferencia: ItemTermoReferencia, file?: File): Observable<ItemTermoReferencia> {
        const url = `${this.urlResource}/${itemTermoReferencia.id}` + (file ? '/file' : '');
        return this.http.put(url, file ? this.createFormData(itemTermoReferencia, file) : itemTermoReferencia).pipe(
            map(() => itemTermoReferencia),
            catchError(this.handleError.bind(this))
        );
    }

    private createFormData(itemTermoReferencia: ItemTermoReferencia, file: File): FormData {
        const formData = new FormData();
        formData.append('file', file);
        const itemTermoReferenciaParam = new Blob([JSON.stringify(itemTermoReferencia)], {type: 'application/json'});
        formData.append('itemTermoReferencia', itemTermoReferenciaParam);
        return formData;
    }


    getByTermoReferencia(termoReferencia: TermoReferencia): Observable<ItemTermoReferencia[]> {
        return this.http.get(`${this.urlResource}/termo-referencia/${termoReferencia.id}`).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

}
