import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {MatTableDataSource} from '@angular/material/table';
import {RequerimentoService} from 'app/main/pages/requerimentos/requerimento.service';
import {SnackBarService} from '../../snack-bar/snack-bar.service';

@Component({
    selector: 'app-dialog-pesquisa-processo',
    templateUrl: './dialog-pesquisa-processo.component.html'
})
export class DialogPesquisaProcessoComponent implements OnInit {

    numeroProcessoControl: FormControl;
    requerimento: Requerimento;
    dataSource: MatTableDataSource<Requerimento>;
    requerimentos: Requerimento[] = [];

    constructor(private dialogRef: MatDialogRef<DialogPesquisaProcessoComponent>,
                private requerimentoService: RequerimentoService,
                private snackBarService: SnackBarService) {
    }

    ngOnInit(): void {
        this.numeroProcessoControl = new FormControl(null, Validators.required);
        this.dataSource = new MatTableDataSource<Requerimento>();
    }

    confirm(requerimento: Requerimento): void {
        if (requerimento) {
            this.dialogRef.close(requerimento);
        } else {
            this.snackBarService.showError('Requerimento não associado ao processo.');
        }
    }

    pesquisar(): void {
        this.requerimento = null;
        this.requerimentos = [];
        this.numeroProcessoControl.markAllAsTouched();
        if (this.numeroProcessoControl.valid) {
            this.requerimentoService.getRequerimento(this.numeroProcessoControl.value).subscribe(result => {
                this.requerimentos = result;
                this.numeroProcessoControl = new FormControl(null, Validators.required);
            }, e => this.snackBarService.showError('Erro ao consultar requerimento', e));
        }
    }

    fecharDialog(): void {
        this.dialogRef.close();
    }
}
