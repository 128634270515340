import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {
    IErrorMessageObject,
    IMessageObject,
    MessageDialog,
    MessageDialogResult
} from '../message-dialog/message-dialog.model';
import {FormControl, Validators} from "@angular/forms";
import {SnackBarService} from "../../snack-bar/snack-bar.service";
import {AssinaturaRequest} from "./pdf-viewer-dialog.model";
import {AuthenticatedUserService} from "../../../core/services/authenticated-user.service";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {CurrentUser} from "../../../core/models/current-user.model";
import { EnumObjetoAssinatura } from '../../enums/EnumObjetoAssinatura';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
    selector: 'app-pdf-viewer-dialog',
    templateUrl: './pdf-viewer-dialog.component.html',
    styleUrls: ['./pdf-viewer-dialog.component.scss'],
})
export class PdfViewerDialogComponent implements OnInit {
    source: Blob;
    formSenha: FormControl;
    usuarioLogado: CurrentUser;

    constructor(
        public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient,
        private messageDialog: MessageDialog,
        private snackbarService: SnackBarService,
        private authenticationService: AuthenticationService
    ) {
        pdfDefaultOptions.workerSrc = () => '/assets/pdf.worker-2.7.673.min.js';
    }

    private fetch(): void {
        this.http
            .get(this.data.source, {params: this.data.httpParams, responseType: 'blob'})
            .pipe(catchError(this.showErrorDialog.bind(this)))
            .subscribe(blob => this.source = blob);
    }

    private loadPdf(): void {
        if (typeof this.data.source === 'string') {
            this.fetch();
        } else {
            this.source = this.data.source;
        }
    }

    private showErrorDialog(message: string | IMessageObject | IErrorMessageObject): Observable<MessageDialogResult> {
        return this.messageDialog.showError(message);
    }

    ngOnInit(): void {
        this.usuarioLogado = this.authenticationService.currentUserDecode;
        this.formSenha = new FormControl(null, [Validators.required, Validators.minLength(6)]);
        this.loadPdf();
    }


    assinarDoc(): void {
        this.formSenha.markAllAsTouched();
        if (this.formSenha.valid) {
            const assinatura: AssinaturaRequest = this.data.assinatura;

            console.debug('assinatura', assinatura);
            if (assinatura.objetoAssinatura === EnumObjetoAssinatura.TITULO) {
                assinatura.service.assinarDocumento(assinatura.alvoAssinatura, this.formSenha.value, assinatura.idTarefa, assinatura.dadosSolicitacao, assinatura.bLobPdf, assinatura.tarefa).subscribe(result => {
                    this.snackbarService.showSuccess('Título assinado com sucesso');
                    this.dialogRef.close(true);
                }, e => {
                    this.snackbarService.showError(e.error && e.error.errors ? e.error.errors[0] : 'Erro ao assinar o documento!')
                });

            } else if(assinatura.objetoAssinatura === EnumObjetoAssinatura.ATO_ADMINISTRATIVO) {
                assinatura.service.assinarDocumento(assinatura.alvoAssinatura, this.formSenha.value, assinatura.idTarefa, assinatura.dadosSolicitacao, assinatura.bLobPdf, assinatura.tarefa).subscribe(result => {
                    this.snackbarService.showSuccess('Ato administrativo assinado com sucesso!');
                    this.dialogRef.close(true);
                }, e => {
                    this.snackbarService.showError(e.error && e.error.errors ? e.error.errors[0] : 'Erro ao assinar o ato administrativo!')
                });
                
            } else if(assinatura.objetoAssinatura === EnumObjetoAssinatura.COPIA_REQUERIMENTO_SUIMIS 
                || assinatura.objetoAssinatura === EnumObjetoAssinatura.COPIA_REQUERIMENTO_SUGF){
                assinatura.service.assinarDocumento(assinatura.alvoAssinatura, this.formSenha.value, assinatura.idTarefa,
                    null, null, null, assinatura.idDepartamentoResponsavel).subscribe(result => {
                    this.snackbarService.showSuccess('Documento assinado com sucesso!');
                    this.dialogRef.close(true);
                }, e => {
                    this.snackbarService.showError(e.error && e.error.errors ? e.error.errors[0] : 'Erro ao assinar o documento!')
                });
            } else {
                assinatura.service.assinarDocumento(assinatura.alvoAssinatura, this.formSenha.value, assinatura.idTarefa).subscribe(result => {
                    this.snackbarService.showSuccess('Documento assinado com sucesso!');
                    this.dialogRef.close(true);
                }, e => {
                    this.snackbarService.showError(e.error && e.error.errors ? e.error.errors[0] :'Erro ao assinar o documento!')
                });
            }

            
        }
    }
}
