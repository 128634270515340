import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {DominiosService} from '../../../../shared/services/dominios.service';
import {RequerimentoIsencaoDto} from '../../../../shared/models/requerimento-isencao-dto.model';
import {RequerimentoIsencaoService} from '../../../../shared/services/requerimento-isencao.service';

@Component({
    selector: 'app-requerimento-isencao',
    templateUrl: './requerimento-isencao.component.html',
    styleUrls: ['./requerimento-isencao.component.scss']
})
export class RequerimentoIsencaoComponent implements OnInit {
    @Input() requerimento: any;
    @Input() isSomenteVisualizacao: boolean;
    @Output() eventEmitter = new EventEmitter();

    formIsencao: FormGroup;
    exibiComprovacao = false;
    arquivoIsensao: any = [];
    nomeArquivo: any;
    isencaoTaxaList: any;
    idTemp = 0;
    statusButton = false;
    arquivo: File;
    arquivoSelecionado: any;
    isencao: any = 0;
    reqIsencaoDto: any = [];
    dataSourceIsensao = new MatTableDataSource<any>([]);


    constructor(private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private dominiosService: DominiosService,
                private requerimentoIsencaoService: RequerimentoIsencaoService) {
    }

    async ngOnInit() {
        await this.isencaoBuilder();
        await this.buscaIsencaoTaxaList();
        this.statusButton = false;
        await this.enviarEmitter(false, this.isencao);
        if (this.requerimento.id) {
            this.carregaIsencaoCasoExista().then(r => r);
        }
    }

    private isencaoBuilder(): void {
        this.formIsencao = this.formBuilder.group({
            id: [null],
            tipoIsencaoTaxa: [null, Validators.required],
        });
        this.formIsencao.controls.tipoIsencaoTaxa.patchValue('naoAtendoNenhumCriterio');
    }

    buscaIsencaoTaxaList(): void {
        this.dominiosService.getAllIsencaoTaxa().subscribe(isencaoTaxa => {
            this.isencaoTaxaList = isencaoTaxa;
        });
    }

    exibirComprovacao(event: any): void {
        this.exibiComprovacao = event.value !== 'naoAtendoNenhumCriterio';
        this.isencao = event.value === 'naoAtendoNenhumCriterio' ? 0 : event.value;
        this.enviarEmitter(false, this.isencao);
    }

    deletarIsencaoCasoExistaAlgumaAoSelecionar(idRequerimento: number): void {
        this.requerimentoIsencaoService.deletaIsencaoCasoExistaAlgumaSalva(idRequerimento).subscribe(() => {
        });
    }

    uploadFile(event: any): void {
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (fileList) {
            this.arquivoSelecionado = fileList;
        }

    }

    visualizarDocumento(idRequerimento: number): void {
        window.open(`${this.requerimentoIsencaoService.urlResource}/api/requerimento-isencao/${idRequerimento}/download`);
    }

    enviaArquivoEsalvaIsencao(arquivoSelecionado: any, isencaoValue: any): void {

        const requerimentoIsancaoDto = new RequerimentoIsencaoDto();
        requerimentoIsancaoDto.idRequerimento = this.requerimento.id;
        requerimentoIsancaoDto.idIsencaoTaxa = isencaoValue;

        arquivoSelecionado.forEach(async arquivo => {
            const formData = new FormData();
            formData.append('file', arquivo.arquivo);
            const requerimentoIsencaoDTO = new Blob([JSON.stringify(requerimentoIsancaoDto)], {type: 'application/json'});
            formData.append('requerimentoIsencaoDTO', requerimentoIsencaoDTO);

            await this.requerimentoIsencaoService.salvaIsencaoComArquivo(formData).toPromise();
            await this.enviarEmitter(formData, this.isencao);

        });
    }

    enviarEmitter(arquivo: any, isencao: number): any {
        this.reqIsencaoDto.push({
            arquivo: arquivo ? true : false,
            isencao: isencao
        });
        this.eventEmitter.emit(this.reqIsencaoDto);
    }

    adicionaMatTable(): void {
        if (this.idTemp === 1) {
            this.deletarArquivo(this.idTemp, null);
            this.formIsencao.controls.tipoIsencaoTaxa.patchValue('naoAtendoNenhumCriterio');
            this.idTemp = 0;
        } else {
            if (this.arquivoSelecionado) {
                this.arquivoIsensao.push({
                    id: null,
                    idTemp: this.idTemp,
                    idRequerimento: this.requerimento.id,
                    nomeArquivo: this.arquivoSelecionado[0].name,
                    arquivo: this.arquivoSelecionado[0]
                });
                this.idTemp++;
                this.dataSourceIsensao = new MatTableDataSource(this.arquivoIsensao);
                this.enviaArquivoEsalvaIsencao(this.arquivoIsensao, this.formIsencao.value.tipoIsencaoTaxa);
                this.statusButton = true;
            } else {
                this.snackBar.showAlert('Selecione algum arquivo antes de adicionar');
            }
        }

    }

    async carregaIsencaoCasoExista(): Promise<void> {
        const reqIsencao = await this.requerimentoIsencaoService.buscaIsencao(this.requerimento.id).toPromise();
        if (reqIsencao) {
            this.formIsencao.patchValue({tipoIsencaoTaxa: reqIsencao.idIsencaoTaxa});
            this.exibiComprovacao = true;
            this.statusButton = true;
            this.idTemp++;
            this.arquivoIsensao.push({
                id: null,
                idTemp: this.idTemp,
                idRequerimento: reqIsencao.idRequerimento,
                nomeArquivo: reqIsencao.nomeArquivo,
            });
            this.dataSourceIsensao = new MatTableDataSource(this.arquivoIsensao);
        }
    }

    deletarArquivo(item: number, idRequerimento: number): void {
        if (idRequerimento) {
            this.deletarIsencaoCasoExistaAlgumaAoSelecionar(this.requerimento.id);
        }

        let data;
        data = this.arquivoIsensao.filter((value, key) => {
            return value.idTemp !== item;
        });
        this.enviarEmitter(false, undefined);
        this.statusButton = false;
        this.exibiComprovacao = false;
        this.formIsencao.reset();
        this.arquivoIsensao = data;
        this.dataSourceIsensao = new MatTableDataSource(data);
        this.idTemp = 0;
    }
}
