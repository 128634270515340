import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-dialog-termo-compromisso',
    styleUrls: ['dialog-termo-compromisso.component.scss'],
    templateUrl: './dialog-termo-compromisso.component.html'
})
export class DialogTermoCompromissoComponent implements OnInit {
    @Input() isNaoPodeEditar: boolean;

    statusPossuiTermoCompromisso = false;
    anexosSalvos: any[] = [];
    nomeArquivoSelecionado = '';
    formAnexarArquivo: FormGroup;
    formTermoCompromisso: FormGroup;
    idAnexo = 0;
    dataSourceAnexo = new MatTableDataSource<any>([]);

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<DialogTermoCompromissoComponent>,
                private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.buildForm();
        if (this.isNaoPodeEditar) {
            this.formAnexarArquivo.disable();
            this.formTermoCompromisso.disable();
        }
    }

    buildForm(): void {
        this.formAnexarArquivo = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            arquivo: ['', [Validators.required]],
        });

        this.formTermoCompromisso = this.formBuilder.group({
            id: [null],
            possuiTermoResponsabilidadeCompromisso: [null],
            cancelamentoDesaverbacaoTermo: [null],
            solicitarRetificacaoTermo: [null],
        });
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

    uploadFile(event: Event): void {
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (fileList) {
            this.nomeArquivoSelecionado = fileList[0].name;
            this.formAnexarArquivo.get('arquivo').patchValue(fileList[0]);
        }
    }

    anexarArquivo(): void {
        if (this.formAnexarArquivo.valid) {
            this.anexosSalvos.push({
                id: null,
                idTemp: this.idAnexo,
                arquivo: this.formAnexarArquivo.controls.arquivo.value.name,
            });
            this.idAnexo++;
            this.dataSourceAnexo = new MatTableDataSource<any>(this.anexosSalvos);
            this.formAnexarArquivo.reset();
            this.nomeArquivoSelecionado = '';
        }
    }

    exibirMaisInformacoes(possuiTermo: any): void {
        this.statusPossuiTermoCompromisso = possuiTermo.value === 'sim';
    }

    limparTabelaArquivos(): void {
        this.anexosSalvos = [];
        this.dataSourceAnexo = new MatTableDataSource<any>([]);
    }
}
