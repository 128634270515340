import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { RequerimentoNewComponent } from 'app/main/pages/requerimentos/requerimento-new/requerimento-new.component';
import { RequerimentoSharedModule } from 'app/main/pages/requerimentos/shared/shared.module';
import { SharedModule } from 'app/main/shared/shared.module';
import { CoreModule } from '../../core/core.module';
import { AbaAtividadesModule } from '../../shared/components/aba-atividades/aba-atividades.module';
import { ParecerTecnicoDetailModule } from '../parecer-tecnico/parecer-tecnico-detail/parecer-tecnico-detail.module';
import { LicenciamentoAmbientalSharedModule } from "../tarefas-bpms/lic-ambiental/@shared/lic-ambiental-shared.module";
import {
    ModalImpedimentosComponent
} from "../tarefas-bpms/lic-ambiental/requerimento-analise/modal-impedimentos/modal-impedimentos.component";
import {
    AcompanhamentoComplementacaoProcessoComponent
} from './acompanhamento-complementacao-processo/acompanhamento-complementacao-processo.component';
import {
    ConsultaRequerimentosProcessosComponent
} from "./consulta-requerimentos-processos/consulta-requerimentos-processos.component";
import {
    DialogDetalheRequerimentoComponent
} from "./consulta-requerimentos-processos/dialog-detalhe-requerimento/dialog-detalhe-requerimento.component";
import {
    DialogHistoricoPriorizacaoComponent
} from "./consulta-requerimentos-processos/dialog-historico-priorizacao/dialog-historico-priorizacao.component";
import { DialogNotasComponent } from "./consulta-requerimentos-processos/dialog-notas/dialog-notas.component";
import { DefinirPrioridadeProcessoComponent } from "./definir-prioridade-processo/definir-prioridade-processo.component";
import { RequerimentoProcessoResolver } from './gerenciar-requerimento-processo.service';
import {
    DialogDistribuirRequerimentoComponent
} from './gerenciar-requerimentos-processos/dialog-distribuir-requerimento/dialog-distribuir-requerimento-component';
import {
    DialogObservacaoRequerimentoDistribuidoComponent
} from './gerenciar-requerimentos-processos/dialog-observacao-requerimento-distribuido/dialog-observacao-requerimento-distribuido-component';
import {
    DialogRedistribuirRequerimentoComponent
} from './gerenciar-requerimentos-processos/dialog-redistribuir-requerimento/dialog-redistribuir-requerimento.component';
import {
    DialogTrocaAssigneeUserTaskComponent
} from './gerenciar-requerimentos-processos/dialog-troca-assignee-user-task/dialog-troca-assignee-user-task.component';
import {
    GerenciarRequerimentosProcessosComponent
} from './gerenciar-requerimentos-processos/gerenciar-requerimentos-processos.component';
import { DialogLacProcessoComponent } from "./menu-novo-requerimento/dialog-lac-processo/dialog-lac-processo.component";
import {
    DialogLicenciamentoTrifasicoNatoComponent
} from "./menu-novo-requerimento/dialog-licenciamento-trifasico-nato/dialog-licenciamento-trifasico-nato.component";
import {
    DialogLicenciamentoTrifasicoComponent
} from './menu-novo-requerimento/dialog-licenciamento-trifasico/dialog-licenciamento-trifasico.component';
import { MenuNovoRequerimentoComponent } from './menu-novo-requerimento/menu-novo-requerimento.component';
import {
    RequerimentoConsultarProcessosComponent
} from './requerimento-consultar-processos/requerimento-consultar-processos.component';
import {
    RequerimentoHistoricoTermoReferenciaComponent
} from './requerimento-historico-termo-referencia/requerimento-historico-termo-referencia.component';
import { RequerimentoLicenciamentoComponent } from './requerimento-licenciamento/requerimento-licenciamento.component';
import { RequerimentoLicenciamentoModule } from './requerimento-licenciamento/requerimento-licenciamento.module';
import { RequerimentoListComponent } from './requerimento-list/requerimento-list.component';
import {
    ConfirmacaoLicenciamentoDialogComponent
} from './requerimento-new/confirmacao-licenciamento-dialog/confirmacao-licenciamento-dialog.component';
import {
    DialogProtocoloExistenteComponent
} from './requerimento-new/dialog-protocolo-existente/dialog-protocolo-existente.component';
import {
    DialogRequerimentoExistenteComponent
} from './requerimento-new/dialog-requerimento-existente/dialog-requerimento-existente.component';
import {
    RequerimentoNotasNewComponent
} from './requerimento-notas/requerimento-notas-new/requerimento-notas-new.component';
import { RequerimentoNotasComponent } from './requerimento-notas/requerimento-notas.component';
import { RequerimentoOutorgaComponent } from './requerimento-outorga/requerimento-outorga.component';
import { RequerimentoOutorgaModule } from './requerimento-outorga/requerimento-outorga.module';
import {
    RequerimentoPaginaProcessosComponent
} from './requerimento-pagina-processos/requerimento-pagina-processos.component';
import {
    RequerimentoTermoReferenciaEspecificoComponent
} from './requerimento-termo-referencia-especifico/requerimento-termo-referencia-especifico.component';
import {
    RequerimentoTermoReferenciaEspecificoModule
} from './requerimento-termo-referencia-especifico/requerimento-termo-referencia-especifico.module';
import { RequerimentoListResolver, RequerimentoResolver } from './requerimento.service';
import {
    OutrosAtosInternosComponent
} from "./shared/aba-documento-processo-requerimento/outros-atos-internos/outros-atos-internos.component";
import { AbaAlteracaoRtComponent } from './shared/alteracao-responsavel-tecnico/aba-alteracao-rt.component';
import {
    ConfirmarCancelamentoDialogComponent
} from './shared/confirmar-cancelamento-dialog/confirmar-cancelamento-dialog.component';
import { HistoricoRequerimentoModule } from './shared/historico-requerimento/historico-requerimento.module';
import { InteressadoAssociadoComponent } from './shared/interessado-associado/interessado-associado.component';
import { RepresentanteAssociadoComponent } from './shared/representante-associado/representante-associado.component';
import { RequerimentoAlertDialogComponent } from './shared/requerimento-alert-dialog/requerimento-alert-dialog.component';
import {
    RequerimentoValidacaoAbaDialogComponent
} from './shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {
    RequerimentoValidacaoDialogComponent
} from './shared/requerimento-validacao-dialog/requerimento-validacao-dialog.component';
import { VistasDoProcessoComponent } from './vistas-do-processo/vistas-do-processo.component';

export const routes: Routes = [
    {
        path: 'requerimentos',
        component: RequerimentoListComponent,
        resolve: {
            data: RequerimentoListResolver,
        },
    },
    {
        path: 'new',
        component: RequerimentoNewComponent,
        pathMatch: 'full',
    },
    {
        path: 'menu-novo-requerimento',
        component: MenuNovoRequerimentoComponent,
        pathMatch: 'full',
    },
    {
        path: 'licenciamento/:id/edit/:idTarefa',
        component: RequerimentoLicenciamentoComponent,
        resolve: {
            resource: RequerimentoResolver,
        },
    },
    {
        path: 'licenciamento/:id/detail',
        component: RequerimentoLicenciamentoComponent,
        resolve: {
            resource: RequerimentoResolver,
        },
    },
    {
        path: 'outorga/:id/edit/:idTarefa',
        component: RequerimentoOutorgaComponent,
        resolve: {
            resource: RequerimentoResolver,
        },
    },
    {
        path: 'outorga/:id/detail',
        component: RequerimentoOutorgaComponent,
        resolve: {
            resource: RequerimentoResolver,
        },
    },
    {
        path: 'definir-prioridade-processo/:id',
        component: DefinirPrioridadeProcessoComponent
    },
    {
        path: 'definir-prioridade-processo/:id/:idPrioridade/detalhe',
        component: DefinirPrioridadeProcessoComponent
    },
    {
        path: 'processos',
        component: RequerimentoConsultarProcessosComponent,
        resolve: {
            data: RequerimentoListResolver,
        },
    },
    {
        path: ':id/notas',
        component: RequerimentoNotasComponent,
        pathMatch: 'full'
    },
    {
        path: ':id/notas/:notaId',
        component: RequerimentoNotasNewComponent,
        pathMatch: 'full'
    },
    {
        path: ':id/pagina-processos',
        component: RequerimentoPaginaProcessosComponent,
        pathMatch: 'full'
    },
    {
        path: ':id/pagina-processos/:tab',
        component: RequerimentoPaginaProcessosComponent,
        pathMatch: 'full'
    },
    {
        path: 'gerenciar-requerimentos-processos',
        component: GerenciarRequerimentosProcessosComponent
    },
    {
        path: 'consulta-requerimentos-processos',
        component: ConsultaRequerimentosProcessosComponent
    },
    {
        path: 'gerenciar-requerimentos-processos/licenciamento/:id/detail',
        component: RequerimentoLicenciamentoComponent,
        resolve: {
            resource: RequerimentoProcessoResolver,
        },
    },
    {
        path: 'gerenciar-requerimentos-processos/outorga/:id/detail',
        component: RequerimentoOutorgaComponent,
        resolve: {
            resource: RequerimentoResolver,
        },
    },
    {
        path: 'gerenciar-requerimentos-processos/:id/pagina-processos',
        component: RequerimentoPaginaProcessosComponent,
        pathMatch: 'full'
    },
    {
        path: 'gerenciar-requerimentos-processos/:id/historico-conferencia',
        component: RequerimentoHistoricoTermoReferenciaComponent,
        pathMatch: 'full'
    },
    {
        path: 'termo-referencia-especifico/:id/edit/:idTarefa',
        component: RequerimentoTermoReferenciaEspecificoComponent,
        resolve: {
            resource: RequerimentoResolver,
        },
    },
    {
        path: 'outros-atos/:idRequerimento/emissao/:idEmissaoTitulo',
        component: OutrosAtosInternosComponent,
        pathMatch: 'full'
    },
    {
        path: 'vistas-do-processo',
        component: VistasDoProcessoComponent,
        pathMatch: 'full'
    },
];

@NgModule({
    declarations: [
        RequerimentoNewComponent,
        RequerimentoListComponent,
        RequerimentoConsultarProcessosComponent,
        RequerimentoNotasComponent,
        RequerimentoNotasNewComponent,
        AcompanhamentoComplementacaoProcessoComponent,
        AbaAlteracaoRtComponent,
        RepresentanteAssociadoComponent,
        InteressadoAssociadoComponent,
        ConfirmarCancelamentoDialogComponent,
        RequerimentoPaginaProcessosComponent,
        RequerimentoValidacaoDialogComponent,
        RequerimentoValidacaoAbaDialogComponent,
        ConfirmacaoLicenciamentoDialogComponent,
        DialogRequerimentoExistenteComponent,
        DialogProtocoloExistenteComponent,
        GerenciarRequerimentosProcessosComponent,
        ConsultaRequerimentosProcessosComponent,
        DialogDistribuirRequerimentoComponent,
        DialogDetalheRequerimentoComponent,
        DialogHistoricoPriorizacaoComponent,
        DialogNotasComponent,
        DialogObservacaoRequerimentoDistribuidoComponent,
        RequerimentoHistoricoTermoReferenciaComponent,
        MenuNovoRequerimentoComponent,
        DialogLicenciamentoTrifasicoComponent,
        DialogLicenciamentoTrifasicoNatoComponent,
        DialogLacProcessoComponent,
        DialogRedistribuirRequerimentoComponent,
        VistasDoProcessoComponent,
        DialogTrocaAssigneeUserTaskComponent,
        DefinirPrioridadeProcessoComponent,
        ModalImpedimentosComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        AbaAtividadesModule,
        RequerimentoLicenciamentoModule,
        RequerimentoOutorgaModule,
        RequerimentoTermoReferenciaEspecificoModule,
        RequerimentoSharedModule,
        ParecerTecnicoDetailModule,
        RouterModule.forChild(routes),
        HistoricoRequerimentoModule,
        MatGridListModule,
        MatDatepickerModule,
        MatIconModule,
        MatTooltipModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatAutocompleteModule,
        LicenciamentoAmbientalSharedModule
    ],
    providers: [
        RequerimentoResolver,
        RequerimentoListResolver,
        RequerimentoProcessoResolver
    ],
    exports: [
        ModalImpedimentosComponent
    ],
    entryComponents: [
        ConfirmarCancelamentoDialogComponent,
        RequerimentoValidacaoDialogComponent,
        RequerimentoAlertDialogComponent,
        RequerimentoValidacaoAbaDialogComponent,
        ConfirmacaoLicenciamentoDialogComponent,
        DialogRequerimentoExistenteComponent,
        DialogProtocoloExistenteComponent,
        DialogDistribuirRequerimentoComponent,
        DialogDetalheRequerimentoComponent,
        DialogHistoricoPriorizacaoComponent,
        DialogNotasComponent,
        DialogObservacaoRequerimentoDistribuidoComponent,
        DialogLicenciamentoTrifasicoComponent,
        DialogLicenciamentoTrifasicoNatoComponent,
        DialogLacProcessoComponent,
        DialogRedistribuirRequerimentoComponent,
        DialogTrocaAssigneeUserTaskComponent,
        ModalImpedimentosComponent
    ]
})
export class RequerimentosModule {
}