import {DocumentoRT} from './documento-rt.model';
import {EnumTipoDocumentoRL} from "../../pages/requerimentos/shared/requerimento-responsaveis/representante-legal/enum-tipo-documento-rl.model";

export enum EnumTipoDocumentoRT {
    ART = ('ART (CREA) do Projeto'),
    PROCURACAO = ('Procuração com firma reconhecida'), //Item depreciado
    CTE = ('Cópia da Declaração do Cadastro Técnico Estadual') //Item depreciado
}

export namespace EnumTipoDocumentoRT {

    export function listarDocumentos(): DocumentoRT[] {
        const list: DocumentoRT[] = [];

        list.push(new DocumentoRT(null, null, EnumTipoDocumentoRT.ART,
            null, false, null, null));

        return list;
    }

    export function fromJson(json: any): EnumTipoDocumentoRT {
        if (json === 'ART') {
            return EnumTipoDocumentoRT.ART;
        }
        if (json === 'CTE') {
            return EnumTipoDocumentoRT.CTE;
        }
        if (json === 'PROCURACAO') {
            return EnumTipoDocumentoRT.PROCURACAO;
        }
        return null;
    }

    export function toJson(tipo: EnumTipoDocumentoRT): string {
        if (tipo === EnumTipoDocumentoRT.ART) {
            return 'ART';
        }
        if (tipo === EnumTipoDocumentoRT.CTE) {
            return 'CTE';
        }
        if (tipo === EnumTipoDocumentoRT.PROCURACAO) {
            return 'PROCURACAO';
        }
        return null;
    }

}
