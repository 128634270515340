import {Component, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {RequerimentoOutorgaFinalidadeService} from '../../../../../shared/services/requerimento-outorga-finalidade.service';
import {CultivoTanqueRede} from './models/cultivo-tanque-rede.model';
import {CultivoTanqueRedeService} from './services/cultivo-tanque-rede.service';
import {ConfirmarExclusaoDialogComponent} from '../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {TipoCorpoHidricoService} from 'app/main/shared/services/tipo-corpo-hidrico.service';
import {TipoCorpoHidrico} from 'app/main/shared/models/tipo-corpo-hidrico.model';
import {BaseModel} from '../../../../../shared/models/base.model';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {TipoSistemaCultivo} from '../../../../../shared/models/tipo-sistema-cultivo.model';
import {TipoSistemaCultivoService} from '../finalidade-tanque-escavado/services/tipo-sistema-cultivo.service';
import {AtividadeAquicultura} from '../../../../../shared/models/atividade-aquicultura.model';
import {AtividadeAquiculturaService} from './services/atividade-aquicultura.service';

@Component({
    selector: 'app-finalidade-tanque-rede',
    templateUrl: 'finalidade-tanque-rede.component.html',
    styleUrls: ['finalidade-tanque-rede.component.scss'],
    animations: fuseAnimations
})
export class FinalidadeTanqueRedeComponent extends BaseFinalidadeComponent implements OnInit {
    form: FormGroup;
    formCultivoTanqueRede: FormGroup;
    cultivoRedeArray: Array<CultivoTanqueRede> = [];
    tiposSistemaCultivo: Array<TipoSistemaCultivo> = [];
    atividadesAquicultura: Array<AtividadeAquicultura> = [];
    tiposCorpoHidrico: Array<TipoCorpoHidrico> = [];
    displayedColumns: string[] = ['areaCultivo', 'producao', 'conversaoAlimentar', 'ciclos', 'acoes'];

    constructor(private dialog: MatDialog,
                private formBuilder: FormBuilder,
                private tipoCorpoHidricoService: TipoCorpoHidricoService,
                private tiposSistemaCultivoService: TipoSistemaCultivoService,
                private atividadeAquiculturaService: AtividadeAquiculturaService,
                private cultivoRedeService: CultivoTanqueRedeService,
                private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
                private snackBarService: SnackBarService
    ) {
        super();
    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.buildResourceForm();
        this.patchForm();
        this.carregarTipoCorpoHidrico();
        this.carregarTipoSistemaCaptacao();
        this.carregarAtividadesAquicultura();
        this.carregarAquiculturaRededoDoRequerimento();
        if (this.isModoVisualizacao) {
            this.form.disable();
            this.formCultivoTanqueRede.disable();
            this.displayedColumns = ['areaCultivo', 'producao', 'conversaoAlimentar', 'ciclos'];
        }
    }

    private patchForm(): void {
        this.form.patchValue({
            id: this.requerimentoOutorgaFinalidade.id,
            finalidade: this.requerimentoOutorgaFinalidade.finalidade,
            requerimentoOutorga: this.requerimentoOutorgaFinalidade.requerimentoOutorga,
            observacao: this.requerimentoOutorgaFinalidade.observacao
        });
        if (this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede) {
            this.form.patchValue({
                aquiculturaTanqueRede: this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede
            });
            this.formCultivoTanqueRede.patchValue({
                aquiculturaTanqueRede: this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede
            });
        }
    }

    protected buildResourceForm(): void {

        this.form = this.formBuilder.group({
            id: [null],
            finalidade: [null, [Validators.required]],
            requerimentoOutorga: [null, [Validators.required]],
            aquiculturaTanqueRede: this.formBuilder.group({
                id: [null],
                tipoCorpoHidrico: [null, [Validators.required]],
                outroCorpoHidrico: [{value: null, disabled: true}, [Validators.maxLength(255)]],
                areaPoligonal: [null, [Validators.required]],
                profundidadeMedia: [null, [Validators.required]],
                tipoSistemaCultivo: [null, [Validators.required]],
                atividadeAquicultura: [null, [Validators.required]],
                outroSistema: [{value: null, disabled: true}, [Validators.maxLength(255)]],
                outraAtividadeAquicultura: [{value: null, disabled: true}, [Validators.maxLength(255)]],
                especies: [null, [Validators.required, Validators.maxLength(512)]],
                quantidadeTanque: [null, [Validators.required, Validators.max(2147483647)]],
                quantidadeFosforo: [null, [Validators.required]],
            }),
            observacao: [null, [Validators.maxLength(3000)]],
            cadastroConcluido: [null]
        });

        this.formCultivoTanqueRede = this.formBuilder.group({
            areaCultivo: [null, [Validators.required]],
            producao: [null, [Validators.required]],
            conversaoAlimentar: [null, [Validators.required]],
            ciclos: [null, [Validators.required]],
            aquiculturaTanqueRede: [null],
        });
    }

    salvar(showMessage = true): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            this.form.patchValue({cadastroConcluido: (this.cultivoRedeArray.length > 0)});

            this.requerimentoOutorgaFinalidadeService.update(this.form.value).subscribe(
                requerimentoOutorgaFinalidade => {
                    Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                    this.patchForm();
                    this.form.markAsPristine();
                    if (showMessage) {
                        this.snackBarService.showSuccess('Aquicultura Tanque Rede Salvo com sucesso!');
                    }
                },
                (e) => {
                    if (showMessage) {
                        this.snackBarService.showError('Erro ao atualizar os dados.', e);
                    }
                }
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
        this.isFormSaved();
    }

    isFormSaved(): boolean {
        return this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede ? false : true;
    }

    addCultivoTanqueRede(): void {
        this.formCultivoTanqueRede.markAllAsTouched();
        this.formCultivoTanqueRede.patchValue({aquiculturaTanqueRede: this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede});
        if (this.formCultivoTanqueRede.valid) {
            this.cultivoRedeService
                .create(this.formCultivoTanqueRede.value
                )
                .subscribe(
                    () => {
                        this.snackBarService.showSuccess('Dados do Cultivo adicionado com sucesso!');
                        this.carregarAquiculturaRededoDoRequerimento();
                        this.formCultivoTanqueRede.reset();
                    },
                    (e) => this.snackBarService.showError('Erro ao adicionar Cultivo do Tanque Rede.', e)
                );
        }
    }

    private carregarAtividadesAquicultura(): void {
        this.atividadeAquiculturaService
            .getAll()
            .subscribe(atividadesAquicultura => (this.atividadesAquicultura = atividadesAquicultura));
    }

    private carregarTipoSistemaCaptacao(): void {
        this.tiposSistemaCultivoService
            .getAll()
            .subscribe(tiposSistemaCultivo => (this.tiposSistemaCultivo = tiposSistemaCultivo));
    }

    private carregarTipoCorpoHidrico(): void {
        this.tipoCorpoHidricoService
            .getAll()
            .subscribe(tipoCorpoHidricoService => {
                (this.tiposCorpoHidrico = tipoCorpoHidricoService);
            });
    }

    private carregarAquiculturaRededoDoRequerimento(): void {
        if (this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede) {
            this.cultivoRedeService
                .getAllByCultivoTanqueRede(this.requerimentoOutorgaFinalidade.aquiculturaTanqueRede)
                .subscribe(cultivoRede => {
                    this.cultivoRedeArray = cultivoRede;
                    if (this.requerimentoOutorgaFinalidade.cadastroConcluido !== this.cultivoRedeArray.length > 0) {
                        this.salvar(false);
                    }
                }, (e) => this.snackBarService.showError('Erro ao carregar lista cultivo tanque rede.', e));
        }
    }

    public deleteCultivoTanqueRede(cultivoTanqueRede: CultivoTanqueRede): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: cultivoTanqueRede.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.cultivoRedeService.delete(cultivoTanqueRede.id
                ).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Dados do Cultivo Tanque Rede excluído com sucesso!');
                        this.carregarAquiculturaRededoDoRequerimento();
                    },
                    () => this.snackBarService.showError('Dados do Cultivo.')
                );
            }
        });
    }

    onChangeTipoCorpoHidrico(event): void {
        if (event) {
            const field = this.form.controls.aquiculturaTanqueRede.get('outroCorpoHidrico');
            const tipo = TipoCorpoHidrico.fromJson(this.form.controls.aquiculturaTanqueRede.get('tipoCorpoHidrico').value);
            if (tipo.outro) {
                field.enable();
                field.setValidators([Validators.required]);
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValue('');
                field.setValidators([]);
                field.updateValueAndValidity();
            }
        }
    }

    onChangeTipoSitemaCultivo(event): void {
        if (event) {
            const field = this.form.controls.aquiculturaTanqueRede.get('outroSistema');
            if (this.form.controls.aquiculturaTanqueRede.get('tipoSistemaCultivo').value.outro) {
                field.enable();
                field.setValidators([Validators.required]);
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValue('');
                field.setValidators([]);
                field.updateValueAndValidity();
            }
        }
    }

    onChangeAtividadeAquicultura(event): void {
        if (event) {
            const field = this.form.controls.aquiculturaTanqueRede.get('outraAtividadeAquicultura');
            if (this.form.controls.aquiculturaTanqueRede.get('atividadeAquicultura').value.outro) {
                field.enable();
                field.setValidators([Validators.required]);
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValue('');
                field.setValidators([]);
                field.updateValueAndValidity();
            }
        }
    }

    public getTotalAreaCultivo(): number {
        if (this.cultivoRedeArray) {
            return this.cultivoRedeArray.reduce(
                (soma, current) => soma + current.areaCultivo, 0);
        }
        return 0;
    }

    public getTotalProducao(): number {
        if (this.cultivoRedeArray) {
            return this.cultivoRedeArray.reduce(
                (total, current) => total + current.producao,
                0
            );
        }
        return 0;
    }

    public getTotalConservacaoAlimentar(): number {
        if (this.cultivoRedeArray) {
            return this.cultivoRedeArray.reduce(
                (total, current) => total + current.conversaoAlimentar,
                0
            );
        }
        return 0;
    }

    public getTotalCiclos(): number {
        if (this.cultivoRedeArray) {
            return this.cultivoRedeArray.reduce(
                (total, current) => total + current.ciclos,
                0
            );
        }
        return 0;
    }
}
