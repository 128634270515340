import {BaseModel} from "./base.model";

export class EmpreendimentoObrasViarias extends BaseModel {
    constructor(
        public id?: number,
        public idRequerimento?: number,
        public denominacaoObra?: string,
        public extensaoKm?: number,
        public numeroPistas?: number,
        public numeroFaixasRolamento?: number,
        public extensaoPavimentacaoExistente?: number,
        public extensaoPavimentacaoNovosTrechos?: number,
        public larguraFaixaDominio?: number,
        public haveraDesapropriacao?: boolean,
        public possuiCemiterioFaixaDominio?: boolean,
        public interfereAreaUrbana?: boolean,
        public possuiAnelViario?: boolean,
        public interfereCorredorFauna?: boolean,
        public transposicaoCorposHidricos?: boolean,
        public qtdCorposHidricos?: number,
        // public categoria?: string,
        public interfereBensTombados?: boolean,
        public areaAntropizada?: boolean,
        public haveraObrasArteEspeciais?: boolean,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoObrasViarias {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoObrasViarias(
            json.id,
            json.idRequerimento,
            json.denominacaoObra,
            json.extensaoKm,
            json.numeroPistas,
            json.numeroFaixasRolamento,
            json.extensaoPavimentacaoExistente,
            json.extensaoPavimentacaoNovosTrechos,
            json.larguraFaixaDominio,
            json.haveraDesapropriacao,
            json.possuiCemiterioFaixaDominio,
            json.interfereAreaUrbana,
            json.possuiAnelViario,
            json.interfereCorredorFauna,
            json.transposicaoCorposHidricos,
            json.qtdCorposHidricos,
            // json.categoria,
            json.interfereBensTombados,
            json.areaAntropizada,
            json.haveraObrasArteEspeciais
        );
    }

    static fromJsons(json: any): EmpreendimentoObrasViarias[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(EmpreendimentoObrasViarias.fromJson(j)));
            return resources;
        }
    }
}