import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SemaGeoportalModule } from '@sema-geo/sema-geoportal';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DiaSemanaPipe } from 'app/main/shared/pipe/dia-semana.pipe';
import { ObjectSortener } from 'app/main/shared/pipe/sort.pipe';
import { CKEditorModule } from 'ckeditor4-angular';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseDirectivesModule } from '../../../@fuse/directives/directives';
import { environment } from "../../../environments/environment";
import { PageErrorsModule } from '../errors/page-errors.module';
import {
    ConfirmarCopiarCondicionanteDialogComponent
} from '../pages/parecer-tecnico/parecer-tecnico-condicionates/parecer-tecnico-condicionantes-list/confirmar-copiar-condicionante-dialog/confirmar-copiar-condicionante-dialog.component';
import {
    DialogHistoricoRtComponent
} from '../pages/requerimentos/shared/alteracao-responsavel-tecnico/historico-rt/dialog-historico-rt.component';
import { DialogVisualizarIncoformidadesGeometriaComponent } from '../pages/tarefas-bpms/lic-ambiental/requerimento-analise/dialog-visualizar-incoformidades-geometria/dialog-visualizar-incoformidades-geometria.component';
import { AnexarArquivosComponent } from "./anexar-arquivos/anexar-arquivos.component";
import { CabecalhoRequerimentoComponent } from './cabecalho-requerimento/cabecalho-requerimento.component';
import { AbaDadosGeograficosComponent } from './components/aba-dados-geograficos/aba-dados-geograficos.component';
import {
    AbaDocumentacaoComplementarComponent
} from './components/aba-documentacao-complementar/aba-documentacao-complementar.component';
import { AbaImoveisComponent } from './components/aba-imoveis/aba-imoveis.component';
import {
    ResponsavelImovelDialogComponent
} from './components/aba-imoveis/dialog-responsavel-imovel/responsavel-imovel-dialog-component';
import {
    AbaLocalizacaoAtividadesRequerimentoComponent
} from './components/aba-localizacao-atividades-requerimento/aba-localizacao-atividades-requerimento.component';
import { RequerimentoAbaComponent } from './components/aba-requerimento/requerimento-aba.component';
import {
    AbaRequerimentoInteressadoPfComponent
} from './components/aba-responsaveis/aba-interessado-pf/aba-requerimento-interessado-pf.component';
import {
    AbaRequerimentoInteressadoPjComponent
} from './components/aba-responsaveis/aba-interessado-pj/aba-requerimento-interessado-pj.component';
import { AbaResponsaveisComponent } from './components/aba-responsaveis/aba-responsaveis.component';
import {
    EmpreendedorDialogComponent
} from './components/aba-responsaveis/dialog-empreendedor/empreendedor-dialog-component';
import {
    RepresentateDialogComponent
} from './components/aba-responsaveis/dialog-representate/representate-dialog-component';
import {
    ResponsavelDialogComponent
} from './components/aba-responsaveis/dialog-responsavel/responsavel-dialog-component';
import { AddNewButtonComponent } from './components/add-new-button/add-new-button.component';
import {
    AssinarDocumentoDialogComponent
} from './components/assinar-documento-dialog/assinar-documento-dialog.component';
import {
    ConfirmarExclusaoDialogComponent
} from './components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import { DespachoComponent } from './components/despacho-cabecalho/despacho.component';
import {
    DialogConfirmaAnaliseFeicaoBatchComponent
} from './components/dialog-confirma-analise-feicao-batch/dialog-confirma-analise-feicao-batch.component';
import {
    DialogConfirmaExclusaoFeicaoComponent
} from './components/dialog-confirma-exclusao-feicao/dialog-confirma-exclusao-feicao.component';
import { DialogConfirmacaoComponent } from './components/dialog-confirmacao/dialog-confirmacao.component';
import { DialogMessageDisponibilidadeHidricaComponent } from './components/dialog-message-disponibilidade-hidrica/dialog-message-disponibilidade-hidrica.component';
import {
    DialogPesquisaProcessoComponent
} from './components/dialog-pesquisa-processo/dialog-pesquisa-processo.component';
import { DialogSelecionarRtPjComponent } from './components/dialog-selecionar-rt-pj/dialog-selecionar-rt-pj.component';
import {
    DialogEdicaoFeicaoAqcComponent
} from "./components/dialogs-modelos-feicao/dialog-edicao-feicao-aqc/dialog-edicao-feicao-aqc.component";
import { DialogEdicaoFeicaoDesmateComponent } from "./components/dialogs-modelos-feicao/dialog-edicao-feicao-desmate/dialog-edicao-feicao-desmate.component";
import { DialogEdicaoFeicaoEventualComponent } from "./components/dialogs-modelos-feicao/dialog-edicao-feicao-eventual/dialog-edicao-feicao-eventual.component";
import { DialogEdicaoFeicaoSeletivaComponent } from "./components/dialogs-modelos-feicao/dialog-edicao-feicao-seletiva/dialog-edicao-feicao-seletiva.component";
import { DialogEdicaoFeicaoTalhaoComponent } from "./components/dialogs-modelos-feicao/dialog-edicao-feicao-talhao/dialog-edicao-feicao-talhao.component";
import { DialogEdicaoFeicaoUpaComponent } from "./components/dialogs-modelos-feicao/dialog-edicao-feicao-upa/dialog-edicao-feicao-upa.component";
import { DocumentoImovelRuralComponent } from './components/documento-imovel-rural/documento-imovel-rural.component';
import { DocumentoImovelUrbanoComponent } from './components/documento-imovel-urbano/documento-imovel-urbano.component';
import {
    RequerimentoResponsavelLegalArquivosDialogComponent
} from './components/documento-representante-legal/documento-representante-legal-arquivo-dialog.component';
import {
    RepresentanteLegalDocumentoCadastroComponent
} from './components/documento-representante-legal/documento-representante-legal.component';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { EditorTextoComponent } from './components/editor-texto/editor-texto.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { IconeLegendaComponent } from "./components/geoadmin/relatorio-regra/icone-legenda/icone-legenda.component";
import {
    RelatorioRegraDialogComponent
} from "./components/geoadmin/relatorio-regra/relatorio-regra-dialog/relatorio-regra-dialog.component";
import { RelatorioRegraComponent } from "./components/geoadmin/relatorio-regra/relatorio-regra.component";
import { TemplateParecerComponent } from "./components/geoadmin/template-parecer/template-parecer.component";
import {
    ValidacaoFeicaoEntradaDialogComponent
} from './components/geoadmin/validacao-feicao-entrada-dialog/validacao-feicao-entrada-dialog.component';
import { InformacoesGeograficasComponent } from './components/informacoes-geograficas/informacoes-geograficas.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { PdfViewerDialogComponent } from './components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { PocoObservacaoComponent } from './components/poco-observacao/poco-observacao.component';
import { PontoVazaoMensalComponent } from './components/ponto-vazao-mensal/ponto-vazao-mensal.component';
import { RequerimentoObjetivoComponent } from './components/requerimento-objetivo/requerimento-objetivo.component';
import { SelectItemModule } from './components/select-item/select-item.module';
import { SelectManyCheckboxModule } from './components/select-many-checkbox/select-many-checkbox.module';
import { SelectRadioModule } from './components/select-radio/select-radio.module';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { TemplatesEmissaoTitulosDialogComponent } from './components/templates-emissao-titulos/templates-emissao-titulos-dialog/templates-emissao-titulos-dialog.component';
import { TemplatesEmissaoTitulosComponent } from './components/templates-emissao-titulos/templates-emissao-titulos.component';
import { TitulosSolicitadosComponent } from './components/titulos-solicitados/titulos-solicitados.component';
import {
    VisualizarGeometriaDialogModule
} from './components/visualizar-geometria-dialog/visualizar-geometria-dialog.module';
import { VisualizarPessoaComponent } from './components/visualizar-pessoa/visualizar-pessoa.component';
import { ExpansionRemoverDirective } from './directives/expansion-remover.directive';
import { FocusDirective } from './directives/focus.directive';
import { NumbersOnlyDirective } from "./directives/numbers-only.directive";
import { DocumentTemplateDialogComponent } from './document-templates/dialog/document-template-dialog.component';
import { DialogEmissaoTituloComponent } from './emissao-titulo/dialog-emissao-titulo/dialog-emissao-titulo.component';
import { EmissaoTituloComponent } from './emissao-titulo/emissao-titulo.component';
import { BaseEnumDescricaoPipe } from './pipe/base-enum-descricao.pipe';
import { BorderStylePipe } from "./pipe/border-style.pipe";
import { CoordenadasFormatPipe } from './pipe/coordenadas-format.pipe';
import { CpfCnpjMaskPipe } from './pipe/cpf-cnpj-mask.pipe';
import { DataPipe } from "./pipe/data.pipe";
import { FilterTiposObjetivoPipe } from './pipe/filter-tipos-objetivo.pipe';
import { RegraGeoClassesPipe } from './pipe/regra-geo-classes.pipe';
import { RegraGeoIconePipe } from './pipe/regra-geo-icone.pipe';
import { SafeSvgPipe } from "./pipe/safe-svg.pipe";
import { UnidadeMedidaPipe } from './pipe/unidade-medida.pipe';
import { CustomSnackBarComponent } from './snack-bar/custom-snack-bar.component';
import { DialogConfirmaExclusaoComponent } from '../pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-florestal/dialog-confirma-exclusao/dialog-confirma-exclusao.component';
import {
    TemplateParecerSemaGeoportalComponent
} from "./components/geoadmin/template-parecer-sema-geoportal/template-parecer-sema-geoportal.component";
@NgModule({
    declarations: [
        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        DespachoComponent,
        EditorTextoComponent,
        DocumentoImovelUrbanoComponent,
        DocumentoImovelRuralComponent,
        DocumentosComponent,
        RepresentanteLegalDocumentoCadastroComponent,
        RequerimentoResponsavelLegalArquivosDialogComponent,
        ObjectSortener,
        DiaSemanaPipe,
        VisualizarPessoaComponent,
        CabecalhoRequerimentoComponent,
        FilterTiposObjetivoPipe,
        SafeSvgPipe,
        BorderStylePipe,
        BaseEnumDescricaoPipe,
        CpfCnpjMaskPipe,
        EmissaoTituloComponent,
        DialogEmissaoTituloComponent,
        DialogSelecionarRtPjComponent,
        DialogHistoricoRtComponent,
        DialogEdicaoFeicaoAqcComponent,
        DialogConfirmaExclusaoFeicaoComponent,
        DialogConfirmaAnaliseFeicaoBatchComponent,
        DocumentTemplateDialogComponent,
        AbaDocumentacaoComplementarComponent,
        AbaImoveisComponent,
        AbaDadosGeograficosComponent,
        AbaRequerimentoInteressadoPfComponent,
        AbaRequerimentoInteressadoPjComponent,
        InformacoesGeograficasComponent,
        RequerimentoAbaComponent,
        AbaResponsaveisComponent,
        RelatorioRegraComponent,
        TemplateParecerComponent,
        TemplateParecerSemaGeoportalComponent,
        IconeLegendaComponent,
        RelatorioRegraDialogComponent,
        PocoObservacaoComponent,
        PontoVazaoMensalComponent,
        RepresentateDialogComponent,
        EmpreendedorDialogComponent,
        ResponsavelDialogComponent,
        ExpansionRemoverDirective,
        MessageDialogComponent,
        PdfViewerDialogComponent,
        FocusDirective,
        CustomSnackBarComponent,
        DialogPesquisaProcessoComponent,
        CpfCnpjMaskPipe,
        AssinarDocumentoDialogComponent,
        FileSelectorComponent,
        ResponsavelImovelDialogComponent,
        RequerimentoObjetivoComponent,
        NumbersOnlyDirective,
        UnidadeMedidaPipe,
        DialogConfirmacaoComponent,
        CoordenadasFormatPipe,
        RegraGeoIconePipe,
        RegraGeoClassesPipe,
        DataPipe,
        ConfirmarCopiarCondicionanteDialogComponent,
        ValidacaoFeicaoEntradaDialogComponent,
        AbaLocalizacaoAtividadesRequerimentoComponent,
        AnexarArquivosComponent,
        TitulosSolicitadosComponent,
        TemplatesEmissaoTitulosComponent,
        TemplatesEmissaoTitulosDialogComponent,
        DialogMessageDisponibilidadeHidricaComponent,
        DialogEdicaoFeicaoUpaComponent,
        DialogEdicaoFeicaoDesmateComponent,
        DialogEdicaoFeicaoEventualComponent,
        DialogEdicaoFeicaoSeletivaComponent,
        DialogEdicaoFeicaoTalhaoComponent,
        DialogVisualizarIncoformidadesGeometriaComponent,
        DialogConfirmaExclusaoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        PageErrorsModule,
        // Material Modules
        MatStepperModule,
        MatDialogModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSelectInfiniteScrollModule,
        NgxMatSelectSearchModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatListModule,
        MatTabsModule,
        NgxChartsModule,
        MatRadioModule,
        MatAutocompleteModule,
        MatGridListModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        NgxMaskModule.forRoot({
            validation: true,
        }),
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        VisualizarGeometriaDialogModule,
        FuseDirectivesModule,
        NgxExtendedPdfViewerModule,
        SemaGeoportalModule.forRoot(environment),

        SelectItemModule,
        SelectManyCheckboxModule,
        SelectRadioModule,
    ],
    exports: [
        RouterModule,
        PageErrorsModule,
        SelectItemModule,
        SelectManyCheckboxModule,
        SelectRadioModule,
        MatStepperModule,
        MatSelectModule,
        MatSelectInfiniteScrollModule,
        NgxMatSelectSearchModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        NgxMaskModule,
        MatRadioModule,
        MatTableModule,
        MatDialogModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatTabsModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatInputModule,
        AnexarArquivosComponent,
        VisualizarGeometriaDialogModule,
        DespachoComponent,
        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        EditorTextoComponent,
        DocumentoImovelUrbanoComponent,
        DocumentoImovelRuralComponent,
        DocumentosComponent,
        PocoObservacaoComponent,
        PontoVazaoMensalComponent,
        AbaDocumentacaoComplementarComponent,
        AbaImoveisComponent,
        AbaLocalizacaoAtividadesRequerimentoComponent,
        AbaDadosGeograficosComponent,
        AbaRequerimentoInteressadoPfComponent,
        AbaRequerimentoInteressadoPjComponent,
        InformacoesGeograficasComponent,
        RequerimentoAbaComponent,
        AbaResponsaveisComponent,
        RelatorioRegraComponent,
        TemplateParecerComponent,
        TemplateParecerSemaGeoportalComponent,
        IconeLegendaComponent,
        RelatorioRegraDialogComponent,
        RepresentateDialogComponent,
        EmpreendedorDialogComponent,
        RepresentanteLegalDocumentoCadastroComponent,
        RequerimentoResponsavelLegalArquivosDialogComponent,
        VisualizarPessoaComponent,
        CabecalhoRequerimentoComponent,
        FilterTiposObjetivoPipe,
        SafeSvgPipe,
        BorderStylePipe,
        EmissaoTituloComponent,
        BaseEnumDescricaoPipe,
        CpfCnpjMaskPipe,
        ExpansionRemoverDirective,
        FocusDirective,
        AssinarDocumentoDialogComponent,
        FileSelectorComponent,
        RequerimentoObjetivoComponent,
        NumbersOnlyDirective,
        UnidadeMedidaPipe,
        CoordenadasFormatPipe,
        RegraGeoIconePipe,
        RegraGeoClassesPipe,
        DataPipe,
        ConfirmarCopiarCondicionanteDialogComponent,
        ValidacaoFeicaoEntradaDialogComponent,
        TitulosSolicitadosComponent,
        TemplatesEmissaoTitulosComponent,
        TemplatesEmissaoTitulosDialogComponent,
        DialogMessageDisponibilidadeHidricaComponent,
        DialogVisualizarIncoformidadesGeometriaComponent
    ],
    entryComponents: [
        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        DespachoComponent,
        DocumentoImovelUrbanoComponent,
        DocumentoImovelRuralComponent,
        DocumentosComponent,
        DocumentTemplateDialogComponent,
        RepresentanteLegalDocumentoCadastroComponent,
        ResponsavelDialogComponent,
        RequerimentoResponsavelLegalArquivosDialogComponent,
        DialogEmissaoTituloComponent,
        DialogSelecionarRtPjComponent,
        DialogHistoricoRtComponent,
        DialogEdicaoFeicaoAqcComponent,
        DialogConfirmaExclusaoFeicaoComponent,
        DialogConfirmaAnaliseFeicaoBatchComponent,
        RepresentateDialogComponent,
        EmpreendedorDialogComponent,
        MessageDialogComponent,
        PdfViewerDialogComponent,
        CustomSnackBarComponent,
        DialogPesquisaProcessoComponent,
        AssinarDocumentoDialogComponent,
        ResponsavelImovelDialogComponent,
        DialogConfirmacaoComponent,
        RelatorioRegraDialogComponent,
        ConfirmarCopiarCondicionanteDialogComponent,
        ValidacaoFeicaoEntradaDialogComponent,
        TitulosSolicitadosComponent,
        TemplatesEmissaoTitulosComponent,
        TemplatesEmissaoTitulosDialogComponent,
        DialogMessageDisponibilidadeHidricaComponent,
        DialogEdicaoFeicaoUpaComponent,
        DialogEdicaoFeicaoDesmateComponent,
        DialogEdicaoFeicaoEventualComponent,
        DialogEdicaoFeicaoSeletivaComponent,
        DialogEdicaoFeicaoTalhaoComponent,
        DialogVisualizarIncoformidadesGeometriaComponent,
        DialogConfirmaExclusaoComponent
    ],
    providers: [
        ObjectSortener,
        DiaSemanaPipe,
        UnidadeMedidaPipe,
        DataPipe,
        RegraGeoIconePipe,
        RegraGeoClassesPipe,
        {provide: MaskPipe, useClass: MaskPipe}
    ],
})
export class SharedModule {
}
