import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {GeradorEletrico} from "../../../../../../../../shared/models/gerador-eletrico.model";
import {FormGroup, NgForm} from "@angular/forms";

@Component({
    selector: 'app-geradores-energia',
    templateUrl: './geradores-energia.component.html',
    styleUrls: ['./geradores-energia.component.scss']
})
export class GeradorEnergiaComponent implements OnInit {

    @Input() form: FormGroup;

    @Input() geradoresEletricosIncluidos: Array<GeradorEletrico>;
    @Input() geradoresEletricosExcluidos: Array<number> = [];
    @Input() isNaoPodeEditar: boolean;

    gerador: GeradorEletrico = {};

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

    displayedColumns: string[] = ['identificacao', 'potenciaNominalAparente', 'rotacoesOperacao', 'fatorPotencia', 'rotacaoPotencia', 'tensao', 'classeIsolamento', 'dataEntradaOperacao', 'acoes'];

    constructor() {
    }

    ngOnInit() {
        this.dataSource.data = this.geradoresEletricosIncluidos;
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    excluirGeradorEnergia(index: number) {
        if (index > -1) {
            let termo = this.geradoresEletricosIncluidos[index];
            if (termo && termo.id !== undefined) {
                this.geradoresEletricosExcluidos.push(termo.id);
            }
            this.geradoresEletricosIncluidos.splice(index, 1);
            this.dataSource.data = this.geradoresEletricosIncluidos;
        }
    }

    incluirGeradorEnergia(form: NgForm) {
        if (this.gerador) {
            this.geradoresEletricosIncluidos.push(this.gerador);
            this.dataSource.data = this.geradoresEletricosIncluidos;

            this.gerador = {};
            form.reset({});
            form.control.markAsPristine({onlySelf: false});
            form.control.markAsUntouched({onlySelf: false});
        }

    }

}
