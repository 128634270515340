import {Component, Input, OnInit} from '@angular/core';
import {RebanhoService} from '../../../services/rebanho.service';
import {Rebanho} from '../../../models/rebanho.model';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';
import {DecimalPipe} from "@angular/common";

@Component({
    selector: 'app-aba-finalidade-dessedentacao',
    templateUrl: './aba-finalidade-dessedentacao.component.html',
    styleUrls: ['./aba-finalidade-dessedentacao.component.scss']
})

export class AbaFinalidadeDessedentacaoComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;

    columnsToDisplay = ['tipo', 'confinadoQuantidade', 'confinadoConsumo', 'confinadoDemanda', 'livreQuantidade', 'livreConsumo', 'livreDemanda'];
    displayedDemandaColumns = ['demandaDiariaTitulo', 'emptyFooter', 'emptyFooter', 'demandaDiariaConfinado', 'emptyFooter', 'emptyFooter', 'demandaDiariaLivre'];
    displayedDemandaTotalColumns = ['demandaDiariaTotalTitulo', 'demandaDiariaTotal', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'emptyFooter'];
    rebanhos: Array<Rebanho> = [];

    constructor(private rebanhoService: RebanhoService,
                private decimalPipe: DecimalPipe) {
    }

    ngOnInit(): void {
        this.carregarRebanhoDoRequerimento();
    }

    private carregarRebanhoDoRequerimento(): void {
        if (this.requerimentoOutorgaFinalidade.criacaoAnimal) {
            this.rebanhoService
                .getAllByCriacaoAnimal(
                    this.requerimentoOutorgaFinalidade.criacaoAnimal
                )
                .subscribe(rebanhos => {
                    this.rebanhos = rebanhos;
                });
        }
    }

    public getTotalConsumoConfinado(): number {
        if (this.rebanhos) {
            return this.rebanhos.reduce(
                (total, current) => total + current.consumoConfinado,
                0
            );
        }
        return 0;
    }

    public getDemandaConfinado(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaConfinado,
                0
            );
            return `${this.decimalPipe.transform(total)}`;
        }
        return '0';
    }

    public getTotalDemandaConfinado(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaConfinado,
                0
            );
            return `${this.decimalPipe.transform(total)} (L) ou ${this.decimalPipe.transform(total / 1000, '1.2-6')}(m³)`;
        }
        return '0';
    }

    public getTotalConsumoLivre(): number {
        if (this.rebanhos) {
            return this.rebanhos.reduce(
                (total, current) => total + current.consumoLivre,
                0
            );
        }
        return 0;
    }

    public getTotalQuantidadeConfinado(): number {
        if (this.rebanhos) {
            return this.rebanhos.reduce(
                (total, current) => total + current.quantidadeConfinado,
                0
            );
        }
        return 0;
    }

    public getTotalQuantidadeLivre(): number {
        if (this.rebanhos) {
            return this.rebanhos.reduce(
                (total, current) => total + current.quantidadeLivre,
                0
            );
        }
        return 0;
    }

    public getDemandaLivre(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaLivre,
                0
            );

            return `${this.decimalPipe.transform(total)}`;
        }
        return '0';
    }

    public getTotalDemandalivre(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaLivre,
                0
            );
            return `${this.decimalPipe.transform(total)} (L) ou ${this.decimalPipe.transform(total / 1000, '1.2-6')}(m³)`;
        }
        return '0';
    }

    public getDemandaDiariaTotal(): string {
        if (this.rebanhos) {
            const total = this.rebanhos.reduce(
                (t, current) => t + current.demandaLivre + current.demandaConfinado,
                0
            );
            return `${this.decimalPipe.transform(total)} (L) ou ${this.decimalPipe.transform(total / 1000, '1.2-6')}(m³)`;
        }
        return '0';
    }

}


