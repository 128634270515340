import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {ComposteiraService} from '../../../../shared/services/composteira.service';
import {Composteira} from '../../../../shared/models/composteira.model';

@Component({
    selector: 'app-composteira',
    templateUrl: './composteira.component.html',
    styleUrls: ['./composteira.component.scss']
})
export class ComposteiraComponent implements OnInit {

    composteiraForm: FormGroup;

    // : TODO Esses Parametros devem chegar atraves do component Pai
    idGeometria = 10;


    constructor(private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private composteiraService: ComposteiraService) {
    }

    ngOnInit() {
        this.composteiraBuilder();
    }

    private composteiraBuilder(): void {
        this.composteiraForm = this.formBuilder.group({
            id: [null],
            volumeUtilCompostagem: [null, Validators.required],
            numeroCelulas: [null, Validators.required],
            possuiTanqueAcumulo: [null, Validators.required],
            volumeTanque: [null, Validators.required],
        });
    }

    salvar(): void {
        const composteira = new Composteira();

        if (this.composteiraForm.valid) {
            composteira.idGeometria = this.idGeometria;
            composteira.volumeUtilCompostagem = this.composteiraForm.value.volumeUtilCompostagem ? Number(this.composteiraForm.value.volumeUtilCompostagem) : null;
            composteira.numeroCelulas = this.composteiraForm.value.numeroCelulas ? Number(this.composteiraForm.value.numeroCelulas) : null;
            composteira.possuiTanqueAcumulo = this.composteiraForm.value.possuiTanqueAcumulo === 'sim' ? 1 : 0;
            composteira.volumeTanque = this.composteiraForm.value.volumeTanque ? Number(this.composteiraForm.value.volumeTanque) : null;

            this.composteiraService.createComposteira(composteira).subscribe(() => {
                this.snackBar.showSuccess('Composteira salvo com sucesso.');
                this.composteiraForm.reset();
                this.composteiraForm.get('volumeTanque').disable();
            });
        } else {
            this.composteiraForm.markAllAsTouched();
            this.snackBar.showError('Campo(s) obrigatório(s) não' +
                ' preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    exibirVolumeTanque(tanque: any): void {
        if (tanque.value === 'sim') {
            this.composteiraForm.get('volumeTanque').enable();
        } else {
            this.composteiraForm.get('volumeTanque').disable();
        }
    }
}
