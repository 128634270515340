import {Injectable, Injector} from '@angular/core';
import {TermoReferencia} from './termo-referencia.model';
import {TermoReferenciaRequerimento} from 'app/main/shared/models/termo-referencia-requerimento.model';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from '../../shared/services/base.service';
import {ListResolver} from '../../shared/resolvers/list-resolver';
import {DetailResolver} from '../../shared/resolvers/detail-resolver';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {MotivoJuntada} from '../../shared/models/motivo-juntada.model';
import {Requerimento} from '../requerimentos/requerimento.model';
import {HttpParams} from "@angular/common/http";
import {TermoReferenciaComItensDto} from "../../shared/models/termo-referencia-com-itens-dto.model";
import {TermoReferenciaEspecificoRequerente} from "../tarefas-bpms/termo-referencia-especifico/model/termo-referencia-especifico-requerente.model";

@Injectable()
export class TermoReferenciaListResolver extends ListResolver<TermoReferencia> {
    constructor(service: TermoReferenciaService) {
        super(service);
    }
}

@Injectable()
export class TermoReferenciaResolver extends DetailResolver<TermoReferencia> {
    constructor(
        service: TermoReferenciaService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TermoReferenciaService extends BaseService<TermoReferencia> {
    
    constructor(protected injector: Injector) {
        super(
            '/api/termos-referencia',
            injector,
            TermoReferencia.prototype,
            TermoReferencia.fromJson
        );
    }

    getByRequerimentoOutorgaId(requerimentoOutorgaId: number): Observable<TermoReferenciaRequerimento[]> {
        const url = `${environment.URL_GATEWAY}/api/requerimentos-outorga/${requerimentoOutorgaId}/termos-referencia`;
        return this.http.get(url).pipe(
            map(jsons => TermoReferenciaRequerimento.fromJsons(jsons).sort((t1, t2) => t1 && t2 && t1.numero > t2.numero ? 1 : -1)),
            catchError(this.handleError.bind(this)));
    }

    getByRequerimentoLicenciamentoId(requerimentoLicenciamentoId: number): Observable<TermoReferenciaRequerimento[]> {
        const url = `${environment.URL_GATEWAY}/api/requerimento-licenciamento/${requerimentoLicenciamentoId}/termos-referencia`;
        return this.http.get(url).pipe(
            map(jsons => TermoReferenciaRequerimento.fromJsons(jsons).sort((t1, t2) => t1 && t2 && t1.numero > t2.numero ? 1 : -1)),
            catchError(this.handleError.bind(this)));
    }

    getByRequerimentoLicenciamentoIdConfEmp(requerimentoLicenciamentoId: number, termosEmp): Observable<TermoReferenciaRequerimento[]> {
        const url = `${environment.URL_GATEWAY}/api/requerimento-licenciamento/${requerimentoLicenciamentoId}/termos-referencia-config-emp`;
        return this.http.post(url, termosEmp).pipe(
            map(jsons => TermoReferenciaRequerimento.fromJsons(jsons).sort((t1, t2) => t1 && t2 && t1.numero > t2.numero ? 1 : -1)),
            catchError(this.handleError.bind(this)));
    }

    getByTermoReferenciaPorNumero(numeroTermoReferencia: string): Observable<TermoReferencia[]> {
        const url = `${this.urlResource}/numero-termo/${numeroTermoReferencia}`;
        return this.http.get(url).pipe(
            map(jsons => TermoReferencia.fromJsons(jsons).sort((t1, t2) => t1 && t2 && t1.numero > t2.numero ? 1 : -1)),
            catchError(this.handleError.bind(this)));
    }

    buscaTermoReferenciaPorRequerimento(idRequerimento?: number): Observable<TermoReferencia[]> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(jsons => TermoReferencia.fromJsons(jsons).sort((t1, t2) => t1 && t2 && t1.numero > t2.numero ? 1 : -1)),
            catchError(this.handleError.bind(this)));
    }

    criaEVinculaAoRequerimento(idRequerimento?: number, termoReferencia?: TermoReferencia): Observable<TermoReferencia> {
        const url = `${this.urlResource}/cria-e-vincula-termo-ao-requerimento/${idRequerimento}`;
        return this.http.post(url, termoReferencia).pipe(
            map(jsons => jsons),
            catchError(this.handleError.bind(this)));
    }

    buscaTermoReferenciaItensComFiltro(idTermo?, descFiltro?): Observable<TermoReferenciaComItensDto[]> {
        const url = `${this.urlResource}/busca-termo-e-itens`;
        let params: HttpParams = new HttpParams();
        if (idTermo) {
            params = params.set('idTermo', idTermo.toString());
        }

        if(descFiltro){
            params = params.set('desc', descFiltro);
        }

        return this.http.get(url, {params: params}).pipe(
            map(jsons => TermoReferenciaComItensDto.fromJsons(jsons)),
            catchError(this.handleError.bind(this)));
    }

    buscarPorTermoReferenciaPorNumeroEDataValidade(numeroTermoReferencia: string): Observable<TermoReferencia[]> {
        let params: HttpParams = new HttpParams();
        if (numeroTermoReferencia) {
            params = params.set('numeroTermo', numeroTermoReferencia);
        }

        const url = `${this.urlResource}/numero-termo-especifico`;
        return this.http.get(url, {params: params}).pipe(
            map(jsons => TermoReferencia.fromJsons(jsons).sort((t1, t2) => t1 && t2 && t1.numero > t2.numero ? 1 : -1)),
            catchError(this.handleError.bind(this)));
    }

    buscarTermoReferenciaRequerente(idTermoReferencia: number): Observable<TermoReferenciaEspecificoRequerente> {
        const url = `${this.urlResource}/buscar-termo-referencia-requerente/${idTermoReferencia}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this)));
    }
}