import {ModuleWithProviders, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';

// material
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatRippleModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MatDialogModule} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FuseSharedModule} from '@fuse/shared.module';
import {UtilService} from './services/util.service';
import {LanguageHelper} from './services/language-helper.service';
import {AuthenticatedUserService} from './services/authenticated-user.service';
import { SemaAssinaturaEletronicaModule } from '@sema-assinatura/sema-assinatura-eletronico';
import { environment } from 'environments/environment';

@NgModule({
    imports: [
        HttpClientModule,

        // Angular Core
        TranslateModule,

        // Material
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDialogModule,
        NgxChartsModule,

        // Fuse
        FuseSharedModule,

        //
        SemaAssinaturaEletronicaModule.forRoot(environment)
       
        
    ],
    exports: [
        // Material
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDialogModule,
        NgxChartsModule,

        // Fuse
        FuseSharedModule,
    ],
    providers: [
        UtilService,
        LanguageHelper,
        AuthenticatedUserService,
        // {
        //     provide: ENVIRONMENTER,
        //     useFactory: () => {
        //         return new Environmenter(environment)
        //     }
        // }
    ],
    declarations: [],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule,
        };
    }
}
