import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AnaliseRequerimentoService} from "../../../tarefas/shared/service/analise-requerimento.service";
import {AnaliseRequerimentoEquipe} from "../../../tarefas/shared/model/analise-requerimento-equipe.model";
import {ServidorService} from "../../../../shared/services/servidor.service";
import {SnackBarService} from "../../../../shared/snack-bar/snack-bar.service";
import {MessageDialog, MessageDialogResult} from "../../../../shared/components/message-dialog/message-dialog.model";
import {HistoricoTrocaAnalistas} from "../../../../shared/models/historico-troca-analistas.model";
import {GerenciarRequerimentoProcessoService} from "../../gerenciar-requerimento-processo.service";
import {Requerimento} from "../../requerimento.model";
import {RequerimentoService} from "../../requerimento.service";
import {OrganizacaoAdministrativaCorporativoService} from "../../../../shared/services/organizacao-administrativa-corporativo.service";

@Component({
    selector: 'app-dialog-redistribuir-requerimento',
    templateUrl: './dialog-redistribuir-requerimento.component.html',
    styleUrls: ['./dialog-redistribuir-requerimento.component.scss']
})
export class DialogRedistribuirRequerimentoComponent implements OnInit {

    analiseRequerimentoEquipe: AnaliseRequerimentoEquipe;
    analistasAtuais: any[] = [];
    analistaPesquisado: any;
    form: FormGroup;
    requerimento: Requerimento;
    servidores: any[] = [];
    servidorSelecionado: any;
    servidoresEncontrados: any[] = [];

    organizacoesFiltradas: any = [];
    analistasDoSetor: any = [];

    constructor(private dialogRef: MatDialogRef<DialogRedistribuirRequerimentoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: FormBuilder,
                private analiseRequerimentoService: AnaliseRequerimentoService,
                private servidorService: ServidorService,
                private snackBarService: SnackBarService,
                private messageDialog: MessageDialog,
                private gerenciarRequerimentoProcessoService: GerenciarRequerimentoProcessoService,
                private requerimentoService: RequerimentoService,
                private organizacaoAdministrativaService: OrganizacaoAdministrativaCorporativoService) {
    }

    async ngOnInit() {
        this.construirForm();
        this.requerimento = await this.requerimentoService.findById(this.data.requerimentoId).toPromise();
        this.organizacoesFiltradas = await this.organizacaoAdministrativaService.buscarTodasAtivas().toPromise();
        await this.analiseRequerimentoService.readEquipeByRequerimentoId(this.data.requerimentoId).toPromise().then( equipeAnalistas =>{
            this.analiseRequerimentoEquipe = equipeAnalistas;
            this.analistasAtuais = equipeAnalistas.analistas;
        });
    }

    construirForm(){
        this.form = this.formBuilder.group({
            analistaTrocado: [null],
            cpfMatriculaBusca: [""],
            nomeCpfNovoAnalista: [null],
            justificativa: ["", [Validators.required, Validators.maxLength(4000)]],
            setorSelecionado: [null],
            analistaSetorSelecionado: [null],
            analistaBuscadoPorCpfMatricula: [null],
        });

        this.form.get("nomeCpfNovoAnalista").disable();
    }

    limpar(){
        this.form.get('cpfMatriculaBusca').patchValue(null);
        this.form.get('analistaSetorSelecionado').patchValue(null);
        this.form.get('analistaBuscadoPorCpfMatricula').patchValue(null);
        this.form.get("nomeCpfNovoAnalista").patchValue(null);
        this.form.get('justificativa').patchValue("");
        this.servidoresEncontrados = [];
        this.analistaPesquisado = null;
    }

    async adicionarAnalistaPorNome(){
        if(!this.form.get('analistaSetorSelecionado').value && !this.form.get('analistaBuscadoPorCpfMatricula').value){
            this.snackBarService.showAlert("Os campos “Informar Analista por CPF ou matrícula” e ou “Analistas do Setor”, " +
                "precisam ser preenchidos")
        }else{
            this.analistaPesquisado = this.form.get('analistaBuscadoPorCpfMatricula').value ?
                this.form.get('analistaBuscadoPorCpfMatricula').value : this.form.get('analistaSetorSelecionado').value;
            this.form.get('nomeCpfNovoAnalista').patchValue(this.analistaPesquisado.nome + " " +
                this.formatarCpf(this.analistaPesquisado.cpf))
        }
    }

    alterarAnalistaDoProcesso(){
        if(this.validarDados()){
            this.gerenciarRequerimentoProcessoService.substituirAnalistaDoProcesso(this.construirObjetoHistoricoTrocaAnalista())
                .toPromise().then( () => {
                this.messageDialog.showInformation("O analista do processo foi substituído com sucesso no processo!")
                    .subscribe( result => {
                        if(result === MessageDialogResult.OK){
                            this.dialogRef.close();
                        }
                    })
            }, error => { console.error(error); this.snackBarService.showError("Somente é possível Alterar Analista da Equipe, " +
                "para processos que estão na etapa de análise.")} );
        }
    }

    validarDados(){
        let passou = true;

        if(!this.form.get('analistaTrocado').value){
            this.snackBarService.showAlert("Selecione um analista do processo para realizar a substituição.")
            passou = false;
        }else if(!this.analistaPesquisado){
            this.snackBarService.showAlert("Os campos “Informar Analista por CPF ou matrícula” e ou “Analistas do Setor”, " +
                "precisam ser preenchidos")
            passou = false;
        }else if(!this.form.get('justificativa').value){
            this.snackBarService.showAlert("Insira uma justificativa para a substituição dos analistas.")
            this.form.markAllAsTouched();
            passou = false;
        }

        return passou;
    }

    construirObjetoHistoricoTrocaAnalista(){
        return new HistoricoTrocaAnalistas(
            null,
            this.requerimento,
            this.form.get('analistaTrocado').value,
            this.analistaPesquisado.cpf,
            this.form.get('justificativa').value,
            null);
    }

    formatarCpf(cpf: any){
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    async pesquisaAnalistaPorCpfMatricula(event) {
        const cpfMatricula = event.target.value;

        if(cpfMatricula.trim().length == 0){
            this.servidores = [];
            return;
        }else{
            this.servidores = [];
            this.servidoresEncontrados = await this.servidorService.getServidorCpfOuMatricula(cpfMatricula).toPromise();
        }
    }

    async selecionarSetor(setor: any) {
        this.analistasDoSetor = await this.servidorService.getServidoresScorpPeloDepartamento(setor.id).toPromise();
    }

    selecionarAnalista(origem: string, analistaCpfMatricula?:any){
        if(origem == 'SETOR'){
            this.form.get('analistaBuscadoPorCpfMatricula').patchValue(null);
            this.form.get('cpfMatriculaBusca').patchValue(null);
            this.form.get("nomeCpfNovoAnalista").patchValue(null);
            this.servidoresEncontrados = [];
            this.analistaPesquisado = null;
        }else {
            this.form.get('analistaSetorSelecionado').patchValue(null);
            this.form.get("nomeCpfNovoAnalista").patchValue(null);
            this.analistaPesquisado = null;
            this.form.get('analistaBuscadoPorCpfMatricula').patchValue(analistaCpfMatricula);
        }
    }
}
