import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {ResiduoAterroIndUrbFormasTransbordoDto} from "../../../../../../../../shared/models/residuo-aterro-ind-urb-formas-transbordo-dto.model";
import {ResiduoAterroIndUrbService} from "../../../../../../../../shared/services/residuo-aterro-ind-urb.service";

@Component({
    selector: 'app-formas-transbordo-residuos',
    templateUrl: './formas-transbordo-residuos.component.html',
    styleUrls: ['./formas-transbordo-residuos.component.scss']
})
export class FormasTransbordoResiduosComponent implements OnInit {

    @Output() formasTransbordoResiduos = new EventEmitter();
    @Input() idResAterroIndUrbano: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;

    formasTransbordos: DominioDto[]
    dadosFormasTransbordo: any[] = [];
    dataSourceFormasTransbordo = new MatTableDataSource<any>([]);
    formFormasTransbordo: FormGroup;
    idFormasTransbordoTemp: number = 0;
    modoEdicao: boolean = false;

    constructor(private dominiosService: DominiosService,
                private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private residuoAterroIndUrbService: ResiduoAterroIndUrbService) {
    }

    ngOnInit() {
        this.buscarFormasTransbordo();
        this.formasTransbordoResiduoBuilder();
        if (this.idResAterroIndUrbano) {
            this.carregaFormasTransbordoComTratamentoExistente(this.idResAterroIndUrbano).then()
        }
        if (this.isNaoPodeEditar) {
            this.formFormasTransbordo.disable();
        }
    }

    async buscarFormasTransbordo() {
        this.formasTransbordos = await this.dominiosService.getDominio('FORMA_TRANSBORDO').toPromise();
    }

    private formasTransbordoResiduoBuilder() {
        this.formFormasTransbordo = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            formas: [null, Validators.required],
            capacidade: [null, Validators.required],
        });
    }

    adicionarResiduo() {
        if (!this.formFormasTransbordo.invalid) {
            this.dadosFormasTransbordo.push({
                id: null,
                idTemp: this.idFormasTransbordoTemp,
                formas: this.formFormasTransbordo.controls.formas.value,
                capacidade: this.formFormasTransbordo.controls.capacidade.value,
            });
            this.idFormasTransbordoTemp++
            this.dataSourceFormasTransbordo = new MatTableDataSource(this.dadosFormasTransbordo);
            this.enviarFormasTransbordoDto(this.mapearArray())

            this.formFormasTransbordo.reset();
        } else {
            this.formFormasTransbordo.markAllAsTouched()
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)')
        }

    }

    mapearArray(): any {
        if (this.dadosFormasTransbordo.length == 0) {
            return this.dadosFormasTransbordo;
        } else {
            return this.dadosFormasTransbordo.map(function (item) {
                return new ResiduoAterroIndUrbFormasTransbordoDto(
                    item.id,
                    item.formas.id,
                    Number(item.capacidade),
                );
            });
        }
    }

    enviarFormasTransbordoDto(residuoMunicipioDto: ResiduoAterroIndUrbFormasTransbordoDto[]) {
        this.formasTransbordoResiduos.emit(residuoMunicipioDto)
    }

    deletarItem(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.residuoAterroIndUrbService.deletarResAterroIndUrbanoFormasTransbordoPorId(itemId).subscribe( () => {
                this.snackBarService.showSuccess("Residuo Formas Transbordo excluido com sucesso.")
            })
        }
        let data;
        data = this.dadosFormasTransbordo.filter((value, key) => {
            return value.idTemp != item;
        });
        this.dadosFormasTransbordo = data;
        this.dataSourceFormasTransbordo = new MatTableDataSource(data);
        this.enviarFormasTransbordoDto(this.mapearArray());
    }

    editarFormaTransbordo() {
        if (this.formFormasTransbordo.valid) {
            this.deletarItem(this.formFormasTransbordo.controls.idTemp.value);
            this.dadosFormasTransbordo.push({
                id: null,
                idTemp: this.idFormasTransbordoTemp,
                formas: this.formFormasTransbordo.controls.formas.value,
                capacidade: this.formFormasTransbordo.controls.capacidade.value,
            });
            this.dataSourceFormasTransbordo = new MatTableDataSource(this.dadosFormasTransbordo);
            this.enviarFormasTransbordoDto(this.mapearArray());
            this.formFormasTransbordo.reset();
            this.modoEdicao = false;
        }
    }

    ativarModoEdicaoFormasTransbordo(formasTransbordo) {
        this.formasTransbordos = this.formasTransbordos.filter((residuoDisponivel) => residuoDisponivel.descricao === formasTransbordo.formas.descricao);
        this.formFormasTransbordo.controls.id.patchValue(formasTransbordo.id);
        this.formFormasTransbordo.controls.idTemp.patchValue(formasTransbordo.idTemp);
        this.formFormasTransbordo.controls.formas.patchValue(this.formasTransbordos[0]);
        this.formFormasTransbordo.controls.capacidade.patchValue(formasTransbordo.capacidade);
        this.modoEdicao = true;
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.formFormasTransbordo.reset();
        this.formFormasTransbordo.markAsUntouched();
        this.formFormasTransbordo.updateValueAndValidity()
    }

    async carregaFormasTransbordoComTratamentoExistente(idResAterroIndUrbano: number) {
        this.residuoAterroIndUrbService.buscaListaResAterroIndUrbanoFormasTransbordoPorIdResAterro(idResAterroIndUrbano)
            .subscribe(async formasTransbordo => {
                for (const formas of formasTransbordo) {
                    let formasTransbordos = await this.formasTransbordos.filter((residuoDisponivel) => residuoDisponivel.id === formas.idFormaTransbordo);
                    this.dadosFormasTransbordo.push({
                        id: formas.id,
                        idTemp: this.idFormasTransbordoTemp,
                        formas: formasTransbordos[0],
                        capacidade: formas.capacidade,
                    });
                    this.idFormasTransbordoTemp++
                }
                this.dataSourceFormasTransbordo = new MatTableDataSource(this.dadosFormasTransbordo);
            });
    }

}
