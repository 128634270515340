import {PocoObservacao} from './../models/poco-observacao-model';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Ponto} from "../../../models/hidrico-models/ponto.model";


@Injectable({
    providedIn: 'root'
})
export class PocoObservacaoService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/pocos-observacao';
    }

    getAllByPonto(ponto: Ponto): Observable<PocoObservacao[]> {
        const url = `${this.urlResource}/ponto/${ponto.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    create(pocoObservacao: PocoObservacao): Observable<PocoObservacao> {
        return this.http
            .post(this.urlResource, pocoObservacao)
            .pipe(
                map(this.jsonDataToResource),
                catchError(this.handleError.bind(this))
            );
    }

    delete(pocoObservacao: PocoObservacao): Observable<any> {
        const url = `${this.urlResource}/${pocoObservacao.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonDataToResourceList(jsonData: any[]): PocoObservacao[] {
        const entries: PocoObservacao[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new PocoObservacao(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

    private jsonDataToResource(jsonData: any): PocoObservacao {
        return Object.assign(new PocoObservacao(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
