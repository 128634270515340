import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {CasaDeForca} from "../../../../../../../../shared/models/casa-de-forca.model";
import {UnidadesGeradoras} from "../../../../../../../../shared/models/unidades-geradoras.model";
import {EeHidricaService} from "../ee-hidrica.service";

@Component({
    selector: 'app-unidades-geradoras',
    templateUrl: './unidades-geradoras.component.html',
    styleUrls: ['./unidades-geradoras.component.scss']
})
export class UnidadesGeradorasComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly : boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() enviarUnidadesGeradoras = new EventEmitter<any[]>();
    formUnidadesGeradoras: FormGroup;
    unidadesGeradorasSalvas: any[] = [];
    idUnidadeGeradora: number = 0;
    dataSourceUnidadeGeradora = new MatTableDataSource<any>([]);
    modoEdicao: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private eeHidricaService: EeHidricaService,) {
    }

    ngOnInit() {
        this.buildFormUnidadeGeradora();
        if(this.idForm){
            this.eeHidricaService.buscarUnidadesGeradorasPorId(this.idForm).subscribe( response => {
                response.forEach( unidadeGeradora => {
                    this.unidadesGeradorasSalvas.push({
                        id: unidadeGeradora.id,
                        idTemp: this.idUnidadeGeradora,
                        unidadeGeradora: unidadeGeradora.unidadeGeradora,
                        turbinaTipo: unidadeGeradora.turbinaTipo,
                        turbinaPotencia: unidadeGeradora.turbinaPotencia,
                        turbinaRendimento: unidadeGeradora.turbinaRendimento,
                        turbinaVazaoNominal: unidadeGeradora.turbinaVazaoNominal,
                        turbinaVazaoMaxima: unidadeGeradora.turbinaVazaoMaxima,
                        turbinaVazaoMinima: unidadeGeradora.turbinaVazaoMinima,
                        geradorPotencia: unidadeGeradora.geradorPotencia,
                        geradorRendimento: unidadeGeradora.geradorRendimento,
                        geradorTensao: unidadeGeradora.geradorTensao,
                        geradorFatorPotencia: unidadeGeradora.geradorFatorPotencia,
                    });
                    this.idUnidadeGeradora++;
                })
                this.dataSourceUnidadeGeradora = new MatTableDataSource(this.unidadesGeradorasSalvas);
                this.enviarUnidGeradora(this.mapearArray());
            })
        }

        if (this.isNaoPodeEditar) {
            this.formUnidadesGeradoras.disable();
        }
    }

    private buildFormUnidadeGeradora(): void {
        this.formUnidadesGeradoras = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            unidadeGeradora: ["", [Validators.required]],
            turbinaTipo: ["", [Validators.required]],
            turbinaPotencia: ["", [Validators.required]],
            turbinaRendimento: ["", [Validators.required]],
            turbinaVazaoNominal: ["", [Validators.required]],
            turbinaVazaoMaxima: ["", [Validators.required]],
            turbinaVazaoMinima: ["", [Validators.required]],
            geradorPotencia: ["", [Validators.required]],
            geradorRendimento: ["", [Validators.required]],
            geradorTensao: ["", [Validators.required]],
            geradorFatorPotencia: ["", [Validators.required]],
        });
    }

    adicionarUnidadeGeradora() {
        if(this.formUnidadesGeradoras.valid){
            this.unidadesGeradorasSalvas.push({
                id: null,
                idTemp: this.idUnidadeGeradora,
                unidadeGeradora: this.formUnidadesGeradoras.controls.unidadeGeradora.value,
                turbinaTipo: this.formUnidadesGeradoras.controls.turbinaTipo.value,
                turbinaPotencia: this.formUnidadesGeradoras.controls.turbinaPotencia.value,
                turbinaRendimento: this.formUnidadesGeradoras.controls.turbinaRendimento.value,
                turbinaVazaoNominal: this.formUnidadesGeradoras.controls.turbinaVazaoNominal.value,
                turbinaVazaoMaxima: this.formUnidadesGeradoras.controls.turbinaVazaoMaxima.value,
                turbinaVazaoMinima: this.formUnidadesGeradoras.controls.turbinaVazaoMinima.value,
                geradorPotencia: this.formUnidadesGeradoras.controls.geradorPotencia.value,
                geradorRendimento: this.formUnidadesGeradoras.controls.geradorRendimento.value,
                geradorTensao: this.formUnidadesGeradoras.controls.geradorTensao.value,
                geradorFatorPotencia: this.formUnidadesGeradoras.controls.geradorFatorPotencia.value,
            })
            this.idUnidadeGeradora++;
            this.dataSourceUnidadeGeradora = new MatTableDataSource(this.unidadesGeradorasSalvas);
            this.enviarUnidGeradora(this.mapearArray());
            this.formUnidadesGeradoras.reset();
        }else{
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            this.formUnidadesGeradoras.markAllAsTouched();
        }
    }

    ativarModoEdicao(item){
        this.formUnidadesGeradoras.controls.id.patchValue(item.id);
        this.formUnidadesGeradoras.controls.idTemp.patchValue(item.idTemp);
        this.formUnidadesGeradoras.controls.unidadeGeradora.patchValue(item.unidadeGeradora);
        this.formUnidadesGeradoras.controls.turbinaTipo.patchValue(item.turbinaTipo);
        this.formUnidadesGeradoras.controls.turbinaPotencia.patchValue(item.turbinaPotencia);
        this.formUnidadesGeradoras.controls.turbinaRendimento.patchValue(item.turbinaRendimento);
        this.formUnidadesGeradoras.controls.turbinaVazaoNominal.patchValue(item.turbinaVazaoNominal);
        this.formUnidadesGeradoras.controls.turbinaVazaoMaxima.patchValue(item.turbinaVazaoMaxima);
        this.formUnidadesGeradoras.controls.turbinaVazaoMinima.patchValue(item.turbinaVazaoMinima);
        this.formUnidadesGeradoras.controls.geradorPotencia.patchValue(item.geradorPotencia);
        this.formUnidadesGeradoras.controls.geradorRendimento.patchValue(item.geradorRendimento);
        this.formUnidadesGeradoras.controls.geradorTensao.patchValue(item.geradorTensao);
        this.formUnidadesGeradoras.controls.geradorFatorPotencia.patchValue(item.geradorFatorPotencia);
        this.modoEdicao = true;
    }

    fecharEdicao(){
        this.modoEdicao = false;
        this.formUnidadesGeradoras.reset();
    }

    editarUnidadeGeradora(){
        if(this.formUnidadesGeradoras.valid){
            this.deletarUnidadeGeradora(this.formUnidadesGeradoras.controls.idTemp.value);

            this.unidadesGeradorasSalvas.push({
                id: this.formUnidadesGeradoras.controls.id.value,
                idTemp: this.formUnidadesGeradoras.controls.idTemp.value,
                unidadeGeradora: this.formUnidadesGeradoras.controls.unidadeGeradora.value,
                turbinaTipo: this.formUnidadesGeradoras.controls.turbinaTipo.value,
                turbinaPotencia: this.formUnidadesGeradoras.controls.turbinaPotencia.value,
                turbinaRendimento: this.formUnidadesGeradoras.controls.turbinaRendimento.value,
                turbinaVazaoNominal: this.formUnidadesGeradoras.controls.turbinaVazaoNominal.value,
                turbinaVazaoMaxima: this.formUnidadesGeradoras.controls.turbinaVazaoMaxima.value,
                turbinaVazaoMinima: this.formUnidadesGeradoras.controls.turbinaVazaoMinima.value,
                geradorPotencia: this.formUnidadesGeradoras.controls.geradorPotencia.value,
                geradorRendimento: this.formUnidadesGeradoras.controls.geradorRendimento.value,
                geradorTensao: this.formUnidadesGeradoras.controls.geradorTensao.value,
                geradorFatorPotencia: this.formUnidadesGeradoras.controls.geradorFatorPotencia.value,
            })
            this.dataSourceUnidadeGeradora = new MatTableDataSource(this.unidadesGeradorasSalvas);
            this.enviarUnidGeradora(this.mapearArray());
            this.formUnidadesGeradoras.reset();
            this.modoEdicao = false;
        }else {
            this.formUnidadesGeradoras.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    deletarUnidadeGeradora(item, acao?: string, itemId?: number){
        if(acao == 'DELETAR' && itemId != null){
            this.eeHidricaService.deletarUnidadesGeradorasPorId(itemId).subscribe( response => {
                this.snackBarService.showSuccess("Unidade geradora excluido com sucesso.")
            })
        }
        let data;
        data = this.unidadesGeradorasSalvas.filter((value, key) => {
            return value.idTemp != item;
        });
        this.unidadesGeradorasSalvas = data;
        this.dataSourceUnidadeGeradora = new MatTableDataSource(data);
        this.enviarUnidGeradora(this.mapearArray());
    }

    enviarUnidGeradora(unidade){
        this.enviarUnidadesGeradoras.emit(unidade);
    }

    mapearArray(): any{
        if(this.unidadesGeradorasSalvas.length == 0){
            return this.unidadesGeradorasSalvas;
        }else{
            return this.unidadesGeradorasSalvas.map(function(item){
                return new UnidadesGeradoras(
                    item.id,
                    null,
                    item.unidadeGeradora,
                    item.turbinaTipo,
                    Number(item.turbinaPotencia),
                    Number(item.turbinaRendimento),
                    Number(item.turbinaVazaoNominal),
                    Number(item.turbinaVazaoMaxima),
                    Number(item.turbinaVazaoMinima),
                    Number(item.geradorPotencia),
                    Number(item.geradorRendimento),
                    Number(item.geradorTensao),
                    Number(item.geradorFatorPotencia),
                );
            });
        }
    }
}