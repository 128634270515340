import {Component, OnInit} from '@angular/core';
import {
    ConfirmacaoLicenciamentoDialogComponent
} from "../requerimento-new/confirmacao-licenciamento-dialog/confirmacao-licenciamento-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Requerimento} from "../requerimento.model";
import {RequerimentoWrapper} from "../requerimento-wrapper.model";
import {RequerimentoService} from "../requerimento.service";
import {Router} from "@angular/router";
import {
    DialogRequerimentoExistenteComponent,
    DialogRequerimentoExistenteResult
} from "../requerimento-new/dialog-requerimento-existente/dialog-requerimento-existente.component";
import {SnackBarService} from "../../../shared/snack-bar/snack-bar.service";
import {
    DialogLicenciamentoTrifasicoComponent
} from "./dialog-licenciamento-trifasico/dialog-licenciamento-trifasico.component";
import {TipoRequerimento} from "../../tipos-requerimento/tipo-requerimento.model";
import {
    DialogProtocoloExistenteComponent,
    DialogProtocoloExistenteResult
} from "../requerimento-new/dialog-protocolo-existente/dialog-protocolo-existente.component";
import {ParametroObjetivoService} from "../../../shared/services/licenciamento-config-service/parametro-objetivo.service";
import {DialogConfirmacaoComponent} from "../../../shared/components/dialog-confirmacao/dialog-confirmacao.component";
import {DialogLacProcessoComponent} from "./dialog-lac-processo/dialog-lac-processo.component";
import {
    DialogLicenciamentoTrifasicoNatoComponent
} from "./dialog-licenciamento-trifasico-nato/dialog-licenciamento-trifasico-nato.component";
import {ProcessoService} from "../../../shared/services/processo.service";
import {fuseAnimations} from "../../../../../@fuse/animations";
import { TipoProcessoService } from './tipo-processo.service';
import { TipoProcesso } from './tipo-processo-novo.model';
import { EnumInstanciaRequerimento } from './instancia-requerimento.enum';
import { TipoFluxo } from 'app/main/shared/enums/tipo-fluxo.enum';
import { TipoAtuacao } from 'app/main/shared/enums/tipo-autuacao.enum';
import { TipoRequerimentoService } from '../../tipos-requerimento/tipo-requerimento.service';

@Component({
    selector: 'app-menu-novo-requerimento',
    templateUrl: './menu-novo-requerimento.component.html',
    styleUrls: ['./menu-novo-requerimento.component.scss'],
    animations: fuseAnimations
})
export class MenuNovoRequerimentoComponent implements OnInit{

    requerimento: Requerimento = new Requerimento();
    parametros: Array<any> = [];
    tiposProcesso: TipoProcesso[] = [];
    fieldSetAtividadesPoluidoras: boolean = false;
    fieldSetHidrico: boolean = false;
    fieldSetRecursosFlorestais: boolean = false;
    fieldSetTermoReferencia: boolean = false;
    fieldSetFluxoNaoDefinido: boolean = false;
    fieldSetAutorizacaoOutrosDocumentos: boolean = false;

    isMobile = false;

    constructor(
        protected dialog: MatDialog,
        protected service: RequerimentoService,
        private router: Router,
        protected snackBarService: SnackBarService,
        private parametroObjetivoService: ParametroObjetivoService,
        public requerimentoService: RequerimentoService,
        public processoService: ProcessoService,
        public tipoProcessoService: TipoProcessoService,
        public tipoRequerimentoService: TipoRequerimentoService
    ) {
    }

    onResize(event: any) {
        this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(): void {
        this.listarTiposProcessosAtivos();
        this.isMobile = window.innerWidth < 768;
    }

    listarTiposProcessosAtivos() {
        this.tipoProcessoService.getListaTiposProcessoCriacaoNovoRequerimento()
            .subscribe(result => {
                this.tiposProcesso = result;
                this.renderizaFieldSets();
            });
    }

    tratarTipoProcessoClick(tipoProcesso: TipoProcesso): void {

        if (tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_HIDRICO.toString()) {
            this.iniciarProcessoOutorga(tipoProcesso);
        }
        if (tipoProcesso.tipo == 'LICENCIAMENTO' && tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO.toString()) {
            this.iniciarProcessoTrifasicoLAS(tipoProcesso);
        }
        if (tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL.toString()) {
            this.iniciarProcessoFlorestal(tipoProcesso);
        }
        if (tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA.toString()) {
            this.iniciarEiaRima(tipoProcesso);
        }
        if (tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_ADESAO.toString()) {
            this.iniciarProcessoLAC(tipoProcesso);
        }
        if (tipoProcesso.tipo === 'AUTORIZACAO' && tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.AUTORIZACAO_OUTROS_DOCUMENTOS.toString()) {
            this.iniciarAutorizacaoDocumento(tipoProcesso);
        }
        if (tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.FLUXO_NAO_DEFINIDO.toString()) {
            this.renderizarMsgFluxoNaoImplementado(tipoProcesso);
        }

    }

    iniciarProcessoLAC(tipoProcessoParam: TipoProcesso) {
        const dialogRef = this.dialog.open(ConfirmacaoLicenciamentoDialogComponent, {width: '500px'});

                dialogRef.afterClosed().subscribe(result => {
                    if (!!result) {
                        const tipoProcesso = tipoProcessoParam
                        this.criarRequerimento(tipoProcesso);
                    }
                });
    }

    iniciarProcessoOutorga(tipoProcessoParam: TipoProcesso) {
        const dialogRef = this.dialog.open<DialogRequerimentoExistenteComponent, any
            , DialogRequerimentoExistenteResult | null>(DialogRequerimentoExistenteComponent, {data: {tipo: 'OUTORGA'}});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const tipoProcesso = tipoProcessoParam

                if (!result.possuiProtocoloExistente) {
                    this.criarRequerimento(tipoProcesso);
                } else {
                    this.service.createComTipoRequerimento(result.tipoRequerimento,
                        tipoProcesso, result.numeroProtocolo).subscribe((res: RequerimentoWrapper) => {
                        this.navigateToRequerimento(res);
                    }, error => this.snackBarService.showError(null, error));
                }
            }
        });
    }

    iniciarProcessoFlorestal(tipoProcessoParam: TipoProcesso) {
        const dialogRef = this.dialog.open<DialogRequerimentoExistenteComponent, any
            , DialogRequerimentoExistenteResult | null>(DialogRequerimentoExistenteComponent, {
                data: {
                    tipo: 'FLORESTAL',
                    tpProcesso : tipoProcessoParam
                }
            });
            dialogRef.afterClosed().subscribe(async result => {
                await this.buscaParametroComTiposRequerimento();
                if (result) {
                    const tipoProcesso = tipoProcessoParam;
                    let idParametroObjetivo = null;
            
                    if (result.possuiProtocoloExistente) {
                        idParametroObjetivo = result.idParametroObjetivo;
                    }
            
                    if (!result.possuiProtocoloExistente) {
                        // Busca o tipo de requerimento, filtra os parametros e cria o requerimento
                        await this.criaRequerimentoFlorestalFluxoEmissao(tipoProcesso);
                    } else {
                        // Chama o método criarRequerimento com os parâmetros do protocolo existente
                        this.criarRequerimento(tipoProcesso, null, result.tipoRequerimento, result.numeroProtocolo, idParametroObjetivo);
                    }
                }
            });
    }

    criaRequerimentoFlorestalFluxoEmissao(tipoProcesso: TipoProcesso) {
        // Inicializa a variável corretamente fora do escopo do if
        let parametroObjetivo = null;
        this.tipoRequerimentoService.findByTipoProcessoIdAndTipoFluxoAndTipoAutoacao(tipoProcesso.id, 'EMITIR', 'NOVO')
            .subscribe((res: TipoRequerimento) => {
                // Encontra o parâmetro com base no tipo de requerimento e outros filtros
                parametroObjetivo = this.parametros.find(p => 
                    p.idTipoProcesso === tipoProcesso.id && 
                    p.situacao && 
                    p.idTipoAtuacao === 1
                );
                // Chama o método criarRequerimento com os parâmetros corretos
                this.criarRequerimento(tipoProcesso, null, res, null, parametroObjetivo.id);
            });
    }

    async buscaParametroComTiposRequerimento() {
        this.parametros = await this.parametroObjetivoService.getAll().toPromise()
    }

    iniciarProcessoTrifasicoLAS(tipoProcessoParam: TipoProcesso) {
        const dialogRef = this.dialog.open(DialogLicenciamentoTrifasicoComponent, {
            width: '650px',
            data: {
                tpProcesso : tipoProcessoParam
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                if (result.idTipoRequerimentoAtuacao == 1 || result.idTipoRequerimentoAtuacao == 3) {
                    const tipoProcesso = tipoProcessoParam
                    this.criarRequerimento(tipoProcesso, result.idTipoRequerimentoAtuacao, result.tipoRequerimento, null, result.idParametroObjetivo);
                } else if (result.idTipoRequerimentoAtuacao == 2) {
                    const tipoProcesso = tipoProcessoParam
                    this.criarRequerimento(tipoProcesso, result.idTipoRequerimentoAtuacao, result.tipoRequerimento, result.numeroProtocoloVinculado, result.idParametroObjetivo);
                }
            }
        });
    }

    iniciarAutorizacaoDocumento(tipoProcessoParam: TipoProcesso) {
        const dialogRef = this.dialog.open(DialogLicenciamentoTrifasicoComponent, {
            width: '600px',
            data: {
                message: 'Possui um protocolo de Autorização/Outros documentos e deseja fazer outros requerimentos?',
                aut: true
            }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (!!result) {
                //const emissoesTitulos = result.emissoesTitulo;
                // if (emissoesTitulos && emissoesTitulos.length > 0) {
                //     if(result.tipoRequerimento.tipoFluxo == 'REABILITAR' && 
                //         emissoesTitulos.some(e => e.situacaoTitulo !== 'CANCELADO' || e.situacaoTitulo !== 'SUSPENSO')) {
                //         this.snackBarService.showAlert("O protocolo informado não possui título com a situação CANCELADO ou SUSPENSO.");
                //         return;
                //     }
                // }
                if (result.idTipoRequerimentoAtuacao == 5) {
                    const tipoProcesso = tipoProcessoParam
                    this.criarRequerimento(tipoProcesso, result.idTipoRequerimentoAtuacao, result.tipoRequerimento, result.numeroProtocoloVinculado, result.idParametroObjetivo);
                } else {
                    let listaParametros = await this.parametroObjetivoService.getAll().toPromise();
                    let tipoRequerimento = await this.tipoRequerimentoService.findByTipoProcessoIdAndTipoFluxoAndTipoAutoacao(tipoProcessoParam.id, TipoFluxo.EMITIR, TipoAtuacao.NOVO).toPromise();
                    let parametro = listaParametros.find(param => param.idTipoProcesso === tipoProcessoParam.id && param.situacao && param.idTipoAtuacao == 1);
                    //let idParametroObjetivo = listaParametros.find(p => p.descricao.includes('Autorização/Outros documentos') && p.situacao && p.idTipoAtuacao == 1);
                    const tipoProcesso = tipoProcessoParam;
                    this.criarRequerimento(tipoProcesso, 4, tipoRequerimento, null, parametro.id);
                }

            }
        });
    }

    iniciarEiaRima(tipoProcessoParam: TipoProcesso) {
        const dialogRef = this.dialog.open(DialogLicenciamentoTrifasicoComponent, {
            width: '600px',
            data: {
                message: 'Possui um Termo de Referência (EIA/RIMA) e deseja solicitar alteração?',
                aut: true
            }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (!!result) {
                if (result.idTipoRequerimentoAtuacao == 6) {
                    const tipoProcesso = tipoProcessoParam
                    this.criarRequerimento(tipoProcesso, result.idTipoRequerimentoAtuacao, result.tipoRequerimento, result.numeroProtocoloVinculado, result.idParametroObjetivo);
                } else {
                    let tipoRequerimento = await this.tipoRequerimentoService.findByTipoProcessoIdAndTipoFluxoAndTipoAutoacao(tipoProcessoParam.id, TipoFluxo.EMITIR, TipoAtuacao.NOVO).toPromise();
                    let listaParametros = await this.parametroObjetivoService.getAll().toPromise()
                    //let idParametroObjetivo = listaParametros.find(p => p.descricao.includes('Estudo Impacto Ambiental') && p.situacao && p.idTipoAtuacao == 1)
                    let parametro = listaParametros.find(param => param.idTipoProcesso === tipoProcessoParam.id && param.situacao && param.idTipoAtuacao == 1);
                    const tipoProcesso = tipoProcessoParam
                    this.criarRequerimento(tipoProcesso, 5, tipoRequerimento, null, parametro.id);
                }

            }
        });
    }

    renderizarMsgFluxoNaoImplementado(tipoProcessoParam: TipoProcesso) {
        return this.snackBarService.showError("Fluxo não implementado para o tipo de processo: " + tipoProcessoParam.descricao);
    }

    criarRequerimento(tipoProcesso: TipoProcesso, idTipoRequerimentoAtuacao = null, tipoRequerimento = null, numeroProtocoloVinculado = null, idParametroObjetivo = null): void {
        this.requerimento = new Requerimento();

        if (idTipoRequerimentoAtuacao) {
            this.requerimento.idTipoRequerimentoTifLas = idTipoRequerimentoAtuacao
        }

        if (tipoRequerimento) {
            this.requerimento.tipoRequerimento = tipoRequerimento
        }
        if (numeroProtocoloVinculado) {
            this.requerimento.numeroProtocoloVinculado = numeroProtocoloVinculado
        }

        if (idParametroObjetivo) {
            this.requerimento.idParametroObjetivo = idParametroObjetivo
        }

        this.requerimento.tipoProcesso = tipoProcesso;

        this.service.createWithTaskReturn(this.requerimento).subscribe((res: RequerimentoWrapper) => {
            this.navigateToRequerimento(res);
        }, () => {
            this.snackBarService.showError('Ocorreu um erro ao criar o requerimento');
        });
    }

    navigateToRequerimento(requerimento: RequerimentoWrapper): void {
        this.requerimento = requerimento.requerimento;
        if (this.requerimento.tipoProcesso.tipo === 'AUTORIZACAO' || this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA.toString()) {
            this.router.navigateByUrl(`/requerimentos/licenciamento/${this.requerimento.id}/edit/${requerimento.tarefaId}`);
        } else {
            let url = `/requerimentos/${this.requerimento.tipoProcesso.tipo.toLowerCase()}/${this.requerimento.id}/edit/${requerimento.tarefaId}`;
            this.router.navigateByUrl(url);
        }
    }

    renderizaFieldSets() {
        this.tiposProcesso.forEach(tp => {
            if (tp.tipo == 'AUTORIZACAO' && tp.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO.toString()) {
                this.fieldSetAutorizacaoOutrosDocumentos = true;
            }
            if (tp.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_HIDRICO.toString()) {
                this.fieldSetHidrico = true;
            }
            if (tp.tipo == 'LICENCIAMENTO' && tp.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_TRIFASICO.toString() || tp.instanciaRequerimento == EnumInstanciaRequerimento.LICENCIAMENTO_ADESAO.toString()) {
                this.fieldSetAtividadesPoluidoras = true;
            }
            if (tp.instanciaRequerimento == EnumInstanciaRequerimento.RECURSO_FLORESTAL.toString()) {
                this.fieldSetRecursosFlorestais = true;
            }
            if (tp.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA.toString()) {
                this.fieldSetTermoReferencia = true;
            }
            if (tp.instanciaRequerimento == EnumInstanciaRequerimento.FLUXO_NAO_DEFINIDO.toString()) {
                this.fieldSetFluxoNaoDefinido = true;
            }
        });

    }

}