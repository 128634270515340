import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {ResponsaveisCorporativoService} from "../../services/responsaveis-corporativo.service";
import {ResponsavelTecnico} from "../../models/responsavel-tecnico.model";
import {SnackBarService} from "../../snack-bar/snack-bar.service";

@Component({
    selector: 'app-dialog-selecionar-rt-pj',
    templateUrl: './dialog-selecionar-rt-pj.component.html',
})
export class DialogSelecionarRtPjComponent implements OnInit {
    cnpjControl: FormControl;
    selecionado: ResponsavelTecnico;
    dataSource: MatTableDataSource<ResponsavelTecnico>;

    constructor(private dialogRef: MatDialogRef<DialogSelecionarRtPjComponent>,
                private responsaveisCorporativoService: ResponsaveisCorporativoService,
                private snackBarService: SnackBarService) {
    }

    ngOnInit(): void {
        this.cnpjControl = new FormControl(null, Validators.required);
        this.dataSource = new MatTableDataSource<ResponsavelTecnico>();
    }

    confirm(): void {
        if (this.selecionado) {
            this.dialogRef.close(this.selecionado);
        } else {
            this.snackBarService.showAlert('Pesquise uma empresa');
        }
    }

    pesquisar(): void {
        this.selecionado = null;
        this.dataSource.data = [];
        this.cnpjControl.markAllAsTouched();
        if (this.cnpjControl.valid) {
            this.responsaveisCorporativoService.getRTbyCpfCnpj(this.cnpjControl.value).subscribe(result => {
                this.selecionado = result;
                this.dataSource.data = [this.selecionado];
            }, e => this.snackBarService.showError('Erro ao consultar empresa', e));
        }
    }
}
