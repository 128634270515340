import {Injectable, Injector} from '@angular/core';
import {IndustriaBiocombustivel} from "../models/industria-biocombustivel.model";
import {BaseEmpreendimentoIndustriaBiocombustivel} from "./base-empreendimento-industria-biocombustivel.service";


@Injectable({
    providedIn: "root"
})
export class IndustriaBiocombustivelService extends BaseEmpreendimentoIndustriaBiocombustivel<IndustriaBiocombustivel> {

    constructor(protected injector: Injector) {
        super(
            "/api/ind-biocombustivel",
            injector,
            IndustriaBiocombustivel.prototype,
            IndustriaBiocombustivel.fromJson
        );
    }
}
