import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EmissaoTituloSignatario} from '../../../../shared/models/emissao-titulo-signatario.model';
import {Signatario} from '../../../../shared/models/signatario.model';
import {ParametrosControleAssinaturaDTO} from 'app/main/shared/models/parametros-controle-assinatura-dto.model';
import {Solicitacao} from "../../../../shared/models/solicitacao.model";
import { ServiceAssinador } from 'app/main/shared/services/service-assinador.interface';

@Injectable({
    providedIn: 'root'
})
export class EmissaoTituloSignatarioService extends BaseService<EmissaoTituloSignatario>  implements ServiceAssinador {

    constructor(protected injector: Injector) {
        super('/api/emissao-titulo-signatario', injector, EmissaoTituloSignatario.prototype, EmissaoTituloSignatario.fromJson);
    }

    getBySignatariosPorIdEmissao(idEmissaoTitulo: number): Observable<Signatario[]> {
        const url = `${this.urlResource}/emissao-titulo/${idEmissaoTitulo}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getSignatarioPorIdEmissao(idEmissaoTitulo: number): Observable<EmissaoTituloSignatario> {
        const url = `${this.urlResource}/emissao-titulo/${idEmissaoTitulo}/signatario`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    atualizarSituacaoSignatario(emissaoTituloSignatario: Solicitacao<EmissaoTituloSignatario>, telaEmissao: string): Observable<EmissaoTituloSignatario> {
        const url = `${this.urlResource}/atualizar-situacao-signatario`;
        return this.http
            .put(url, emissaoTituloSignatario, {params: {telaEmissao: telaEmissao}})
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    finalizarTarefaCamunda(idRequerimento: number, idTarefa: string, emissaoTituloignatario: any): Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa/${idRequerimento}/${idTarefa}/`;
        return this.http.post(url, emissaoTituloignatario).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    finalizarTarefaCamundaValidacao(idTarefa: string, emissaoTituloignatario: any, telaEmissao: string): Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa-validacao/${idTarefa}`;
        return this.http.post(url, emissaoTituloignatario, {params: {telaEmissao: telaEmissao}}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    finalizarTarefaApreciacaoCamundaValidacao(idTarefa: string, emissaoTituloignatario: any, telaEmissao: string): Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa-apreciacao/${idTarefa}`;
        return this.http.post(url, emissaoTituloignatario, {params: {telaEmissao: telaEmissao}}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    finalizarTarefaCamundaComDocumento(tarefaId: string, emissaoTituloignatario: any, signedDocument: Blob, telaEmissao: string): Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa-validacao/${tarefaId}/documento-assinado`;

        const formData = new FormData();

        const solicitacao = new Blob(
            [JSON.stringify(emissaoTituloignatario)],
            {type: 'application/json'}
        );
        formData.append('solicitacao', solicitacao);
        formData.append('multipartFile', signedDocument);

        return this.http.post(url, formData, {params: {telaEmissao: telaEmissao}}).pipe(
                map(response => response),
                catchError(this.handleError)
        );
    }

    getByParametrosControleAssinatura(idEmissaoTitulo: number): Observable<ParametrosControleAssinaturaDTO> {
        const url = `${this.urlResource}/emissao-titulo/${idEmissaoTitulo}/controle-assinatura`;
        return this.http
            .get(url)
            .pipe(
                map(resources => ParametrosControleAssinaturaDTO.fromJson(resources)),
                catchError(this.handleError.bind(this))
            );
    }

    assinarDocumento(alvoAssinador: any, senha: string, idTarefa?: string, emissaoTituloSignatario?: any, signedDocument?: Blob, telaEmissao?: string): Observable<any> {
        const url = `${this.urlResource}/concluir-tarefa-validacao/${idTarefa}/documento-assinado`;

        const formData = new FormData();

        const solicitacao = new Blob(
            [JSON.stringify(emissaoTituloSignatario)],
            {type: 'application/json'}
        );
        var senhaBase64 = btoa(senha);
        formData.append('solicitacao', solicitacao);
        formData.append('multipartFile', signedDocument);
        formData.append('senha', senhaBase64);

        return this.http.post(url, formData, {params: {telaEmissao: telaEmissao}}).pipe(
                map(response => response),
                catchError(this.handleError)
        );
    }

    assinarDocumentoCodigoVerificador(alvoAssinador: any, senha: string, idTarefa?: string){
        return this.http.post('', {});
    }
}
