import {ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatStepper} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {Pessoa} from 'app/main/shared/models/pessoa.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {Subject} from 'rxjs';
import {RequerimentoService} from '../../../requerimento.service';
import {PessoaCorporativoService} from 'app/main/shared/services/pessoa-corporativo.service';
import {Documento} from 'app/main/shared/models/documento.model';
import {Requerimento} from '../../../requerimento.model';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {ConfirmarAlteracaoInteressadoDialogComponent} from '../confirmar-alteracao-interessado/confirmar-alteracao-interessado-dialog.component';
import {RequerimentoHelperAcessors} from '../../requerimento.helper';

/**
 * Documento de Requisitos
 * SEMA_SIGA_02_EspecificacaoFuncional_CadastroRequerimento
 * Pag.63
 */

@Component({
    selector: 'app-requerimento-interessado-pj',
    templateUrl: './requerimento-interessado-pj.component.html',
    styleUrls: ['./requerimento-interessado-pj.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoInteressadoPjComponent extends RequerimentoHelperAcessors implements OnInit {


    constructor(
        private dialog: MatDialog,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private pessoaCorporativoService: PessoaCorporativoService,
        protected requerimentoService: RequerimentoService,
        injector: Injector
    ) {
        super(injector);
        this.activatedRoute = injector.get(ActivatedRoute);
    }

    get pessoaAdicionada(): boolean {
        return this.requerimento.pessoa !== null;
    }

    protected activatedRoute: ActivatedRoute;

    @Input()
    requerimento: Requerimento;

    @Input()
    salvarSubject: Subject<any>;

    @Input()
    dirtySubject: Subject<any> = null;

    @Input()
    public stepper: MatStepper;

    @Input()
    somenteVisualizacao: boolean;

    @Input() altDocResp: boolean;

    documentosEmpresa: Documento[];

    pessoasEmpresa: Pessoa[] = [];
    pessoaEmpresaSelecionada: Pessoa;
    pessoaEmpresaDocumentos: Documento[] = [];

    // forms
    form: FormGroup;

    // ui
    pessoasFisicasRepresentantesColumns: string[] = ['selection', 'cpf', 'nome', 'email', 'telefone'];
    mostraMensagemSubterraneaPJEUrbano = false;

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.buildResourceForm();
        this.loadData();
        if (!this.altDocResp) {
            this.somenteVisualizacao = true
        } else {
            this.somenteVisualizacao = false;
        }
        if (this.somenteVisualizacao) {
            this.form.disable();
        }
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            cpfCnpj: [null],
            nome: [null],
            nomeFantasia: [null],
            naturezaJuridica: [null],
            inscricaoMunicipal: [null],
            inscricaoEstadual: [null],
            telefone: [null],
            dataNascimento: [null],
            porteEmpresa: [null],
            endereco: [null],
            cadastroTecnicoFederal: [null],
        });
        this.dirtySubject.next(false);
    }

    private async loadData() {
        //Busca pessoa com os dados atualizados no SCORP para exibir no componente app-visualizar-pessoa
        this.form.patchValue(await this.pessoaCorporativoService.getByCpfCnpj(this.requerimento.pessoa.cpfCnpj).toPromise().then());

        this.pessoaCorporativoService.getRepresentantesByCnpj(this.requerimento.pessoa.cpfCnpj).subscribe(result => {
            this.pessoasEmpresa = result;
        }, (error) => {
            if (error.status !== 404) {
                this.snackBarService.showError('Houve um erro ao listar os representantes.');
            }
        });
        this.pessoaCorporativoService.getDocumentosByPessoaIdCorporativo(this.requerimento.pessoa.idCorporativo).subscribe(result => {
            this.documentosEmpresa = result.filter(doc => doc.nome != null);
        });
        if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
            this.requerimentoService.ehRequerimentoSubterraneoPJEUrbano(this.requerimento.id).subscribe(result => this.mostraMensagemSubterraneaPJEUrbano = result);
        }
    }

    visualizarRepresentante(representante: Pessoa): void {
        this.pessoaEmpresaSelecionada = representante;
        this.pessoaCorporativoService.getDocumentosRepresentante(this.requerimento.pessoa.cpfCnpj, representante).subscribe(result => {
            this.pessoaEmpresaDocumentos = result;
        }, () => this.snackBarService.showError('Houve um erro ao listar os documentos.'));
    }

    confirmarAlteracaoInteressadoDialog(): void {
        const matDialogRef = this.dialog.open(ConfirmarAlteracaoInteressadoDialogComponent, {width: '400px'});
        matDialogRef.afterClosed().subscribe(confirmou => {
            if (!!confirmou) {
                this.removerInteressado();
            }
        });
    }

    removerInteressado(): void {
        const idTarefa = this.activatedRoute.snapshot.url[3].path;

        console.log('idTarefa ' + idTarefa + ' -- ' + 'requerimentoTaskId ' + this.requerimentoTaskId);

        this.requerimentoService.deleteInteressado(this.requerimento.id, idTarefa).subscribe((result) => {
            this.requerimento = result;
            this.form.patchValue({});
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.form.updateValueAndValidity();
            this.snackBarService.showSuccess('Interessado atual removido. Selecione um novo Interessado!');
        }, e => {
            this.snackBarService.showError('Erro ao remover o interessado.', e);
        });
    }
}

