import {BaseModel} from 'app/main/shared/models/base.model';
import {ItemTermoReferenciaRequerimento} from './item-termo-referencia-requerimento.model';
import {BaseEnum} from '../enums/base.enum';

export class ItemTermoReferenciaRequerimentoAnalise extends BaseModel {
    constructor(
        public id?: number,
        public statusAnalise?: string,
        public comentario?: string,
        public rascunho?: boolean,
        public dataInicio?: Date,
        public itemTermoReferenciaRequerimento?: ItemTermoReferenciaRequerimento,
        public servidor?: ServidoTermoReferencia
    ) {
        super();
    }

    static statusAnalises: BaseEnum[] = [
        {valor: 'NAO_AVALIADO', descricao: 'Não avaliado'},
        {valor: 'ATENDIDO', descricao: 'Atendido'},
        {valor: 'NAO_ATENDE', descricao: 'Não atende'},
        {valor: 'DISPENSADO', descricao: 'Dispensado'},
    ];

    static statusConferencia: BaseEnum[] = [
        {valor: 'NAO_CONFERIDO', descricao: 'Não analisado'},
        {valor: 'CONFERIDO', descricao: 'Aprovado'},
        {valor: 'INCONFORMIDADE', descricao: 'Reprovado'}
    ];

    static fromJson(json: any): ItemTermoReferenciaRequerimentoAnalise {
        if (json === undefined || json === null) {
            return null;
        }
        return new ItemTermoReferenciaRequerimentoAnalise(
            json.id,
            json.statusAnalise,
            json.comentario,
            json.rascunho,
            json.dataInicio,
            ItemTermoReferenciaRequerimento.fromJson(json.itemTermoReferenciaRequerimento),
            ServidoTermoReferencia.fromJson(json.servidor)
        );
    }

    static fromJsons(json: any[]): ItemTermoReferenciaRequerimentoAnalise[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(resource =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }

}

export interface ItemTermoReferenciaRequerimentoAnaliseFormGroup {
    id: number;
    itemTermoReferenciaRequerimento: ItemTermoReferenciaRequerimento;
    status: string;
    comentario: string;
}


export class HistoricoConferencia extends BaseModel {
    constructor(
        public itemTermoReferenciaRequerimentoAnalise?: ItemTermoReferenciaRequerimentoAnalise[],
        public itemTermoReferencia?: ItemTermoReferenciaRequerimento
    ) {
        super();
    }

}


export class ServidoTermoReferencia extends BaseModel {
    constructor(
        public nome?: string,
        public formacao?: string,
        public setor?: string,
        public cpf?: string
    ) {
        super();
    }

    static fromJson(json: any): ServidoTermoReferencia {
        if (json === undefined || json === null) {
            return null;
        }
        return new ServidoTermoReferencia(
            json.nome,
            json.formacao,
            json.setor,
            json.cpf,
        );
    }

}

