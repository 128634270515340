import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AnaliseLicencaPreviaConsema} from "../models/analise-licenca-previa-consema.model";

@Injectable({
    providedIn: "root"
})

export class AnaliseLicencaPreviaConsemaService {
    constructor(private http: HttpClient) {
    }


    urlResource: string = environment.URL_GATEWAY;

    salvarPareceTecnicoLicencaPreviaConsema(analiseLicencaPreviaConsema: AnaliseLicencaPreviaConsema, idTarefa, completar = true): Observable<AnaliseLicencaPreviaConsema> {
        const url = `${this.urlResource}/api/parecer-tecnico-licenca-previa/${idTarefa}/${completar}`;
        return this.http.post(url, analiseLicencaPreviaConsema)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

}