import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FinalidadeAtividadeService} from '../finalidade-atividade.service';
import {HttpParams} from '@angular/common/http';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';
import {FinalidadeAtividade} from '../finalidade-atividade.model';

@Component({
    selector: 'app-finalidade-atividade-list',
    templateUrl: './finalidade-atividade-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class FinalidadeAtividadeListComponent extends BaseListComponent<FinalidadeAtividade> {

    descricao: string;

    constructor(
        injector: Injector,
        service: FinalidadeAtividadeService,
    ) {
        super(injector, ['id', 'finalidade', 'atividade', 'buttons'], service);
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        if (typeof this.descricao !== 'undefined' && this.descricao.length > 0) {
            httpParams = httpParams.set('descricao', this.descricao);
        }
        return httpParams;
    }

}
