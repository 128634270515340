import {Injectable, Injector} from '@angular/core';
import {Imovel} from './imovel.model';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Pessoa} from '../../../../../shared/models/pessoa.model';
import {PessoaDto} from '../../../../../shared/models/pessoa.dto';
import {FormaOcupacao} from '../../../../formas-ocupacao/forma-ocupacao.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../../../environments/environment';
import {ImovelPessoa} from './imovel-pessoa.model';

@Injectable({
    providedIn: 'root',
})
export class ImovelPessoaService {
    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/pessoas-imoveis';
    }

    getAllByImovelUrbano(imovel: Imovel): Observable<ImovelPessoa[]> {
        const url = `${this.urlResource}/imovel/${imovel.id}/pessoas`;
        return this.http.get(url).pipe(
            map(json => ImovelPessoa.fromJsons(json)),
            catchError(this.handleError.bind(this))
        );
    }

    addToImovel(pessoa: PessoaDto, idImovel: number, formaOcupacao: FormaOcupacao, especificaoOcupacao: string): Observable<any> {
        const url = `${this.urlResource}/${idImovel}`;
        let urlParams: HttpParams = new HttpParams();
        urlParams = urlParams.append('cpfCnpj', pessoa.cpfCnpj);
        if (formaOcupacao) {
            urlParams = urlParams.append('idFormaOcupacao', String(formaOcupacao.id));
        }
        if (especificaoOcupacao) {
            urlParams = urlParams.append('especificaoOcupacao', especificaoOcupacao);
        }
        return this.http.post(url, null, {params: urlParams}).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    removeFromImovel(pessoa: Pessoa, imovel: Imovel, idTarefa: string): Observable<any> {
        const url = `${this.urlResource}/pessoa/${pessoa.id}/imovel/${imovel.id}/${idTarefa}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}