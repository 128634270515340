import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GeometriaMapa} from '@sema-geo/sema-geoportal';
import {
    FeicaoAnaliseRequerimentoService
} from "../../../../pages/feicoes-analise-requerimentos/feicao-analise-requerimento.service";
import {ModeloFeicaoDTO} from "../../../models/modelo-feicao-dto.model";

export interface DialogEditarFeicaoAQCData {
    denomicacao: string;
    idModeloFeicao: number;
    modeloFeicaoDTO: ModeloFeicaoDTO;
    tamanho: number;
    geometria: GeometriaMapa;
    visualizacao: boolean;
    onRemoveFeature: (geometria: GeometriaMapa) => void;
}

@Component({
    selector: 'app-dialog-editar-feicao',
    templateUrl: './dialog-edicao-feicao-aqc.component.html',
    styleUrls: ['./dialog-edicao-feicao-aqc.component.scss'],
})
export class DialogEdicaoFeicaoAqcComponent {

    form: FormGroup;

    geometria: GeometriaMapa;

    tamanho: number;

    motivos: {label: string, value: string}[] = [
        {label: "Resto de exploração Florestal", value: "RESTO_EXPLORACAO_FLORESTAL"},
        {label: "Resto de Cultura", value: "RESTO_CULTURA"},
        {label: "Limpeza de Pastagem", value: "LIMPEZA_PASTAGEM"},
        {label: "Cana de Açúcar", value: "CANA_ACUCAR"},
        {label: "Controle Fitossanitário", value: "CONTROLE_FITOSSANITARIO"},
        {label: "Pesquisa Cientifica Experimental", value: "PESQUISA_CIENTIFICA_EXPERIMENTAL"},
        {label: "Pesquisa Tecnológica", value: "PESQUISA_TECNOLOGICA"},
        {label: "Outros", value: "OUTROS"}

    ];

    onRemoveFeature: (geometria: GeometriaMapa) => void;

    denomicacao = new FormControl(null, [Validators.required, Validators.maxLength(this.tamanho)]);
    motivo = new FormControl(null, [Validators.required]);

    constructor(
        private formBuilder: FormBuilder,
        private feicaoAnaliseRequerimentoService: FeicaoAnaliseRequerimentoService,
        private snackBarService: SnackBarService,
        private dialogRef: MatDialogRef<DialogEditarFeicaoAQCData>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEditarFeicaoAQCData
    ) {
        this.data.visualizacao = this.data.visualizacao ? this.data.visualizacao : false;
        this.onRemoveFeature = data.onRemoveFeature;
        this.tamanho = data.tamanho;
        this.geometria = data.geometria;

        this.form = this.formBuilder.group({
            denomicacao: this.denomicacao,
            motivo: this.motivo
        });

        this.denomicacao.setValue(data.denomicacao);

        if (this.data.hasOwnProperty('modeloFeicaoDTO') && this.data.modeloFeicaoDTO && this.data.modeloFeicaoDTO.modeloFeicaoAQC){
            this.motivo.setValue(data.modeloFeicaoDTO.modeloFeicaoAQC.motivo)
        }
        if(this.data.visualizacao){
            this.form.disable()
        }
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onRemover(): void {
        this.onRemoveFeature(this.geometria);
        this.dialogRef.close({
            success: false
        });
    }

    salvar(): void {
        this.dialogRef.close({
            success: true,
            geometria: this.geometria,
            feicao: {
                denominacao: this.denomicacao.value,
                modeloFeicaoDTO: {
                    idModeloFeicao: this.data.idModeloFeicao,
                    modeloFeicaoAQC: {
                        motivo: this.motivo.value
                    }
                }
            }
        });
    }
}
