import {Injectable, Injector} from "@angular/core";
import {BaseService} from "../../shared/services/base.service";
import {LicencaFiscalizacao} from "./licenca-fiscalizacao.model";
import {Observable} from "rxjs";
import {catchError} from "rxjs/internal/operators";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class SimlamService extends BaseService<LicencaFiscalizacao> {

    constructor(protected injector: Injector) {
        super(
            '/api/simlam',
            injector, LicencaFiscalizacao.prototype,
            LicencaFiscalizacao.fromJson
        );
    }

    pesquisarLicencas(numeroProcesso: string): Observable<LicencaFiscalizacao[]> {
        let params = new HttpParams();
        params = params.set('numeroProcesso', numeroProcesso)
        const url = `${this.urlResource}/licencas`;
        return this.http.get(url, {
            params
        }).pipe(catchError(this.handleError.bind(this)));
    }
}