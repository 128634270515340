import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AlocacaoVazao} from '../models/alocacao-vazao.model';
import {BarragemExistente} from '../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-barragem-existente/barragem-existente.model';

@Injectable({
    providedIn: 'root'
})
export class AlocacaoVazaoService extends BaseService<AlocacaoVazao> {
    constructor(protected injector: Injector) {
        super('/api/alocacao-vazao', injector, AlocacaoVazao.prototype, AlocacaoVazao.fromJson);
    }

    create(alocacaoVazao: AlocacaoVazao): Observable<AlocacaoVazao> {
        return this.http.post(this.urlResource, alocacaoVazao)
            .pipe(
                map(value => AlocacaoVazao.fromJson(value)),
                catchError(this.handleError.bind(this))
            );
    }

    getAllByBarragemExistente(barragemExistente: BarragemExistente): Observable<AlocacaoVazao[]> {
        const url = `${this.urlResource}/barragem-existente/${barragemExistente.id}`;
        return this.http.get(url).pipe(
            map(value => AlocacaoVazao.fromJsons(value)),
            catchError(this.handleError.bind(this))
        );
    }

    deleteByBarragemExistenteAndAlocacaoVazao(barragemExistente: BarragemExistente, alocacaoVazao: AlocacaoVazao): Observable<void> {
        const url = `${this.urlResource}/barragem-existente/${barragemExistente.id}/${alocacaoVazao.id}`;
        return this.http.delete(url).pipe(catchError(this.handleError.bind(this)));
    }

}
