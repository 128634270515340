import {ModelUtil} from "../../../../../shared/models/model-util";
import {IrrigacaoCultura} from "./irrigacao-cultura.model";
import {isNullOrUndefined} from "util";
import {Ponto} from "../../../../../shared/models/hidrico-models/ponto.model";
import {ResumoNecessidadesIrrigacao} from "./resumo-necessidades-irrigacao.model";

export class IrrigacaoDTO {
    constructor(
        public id?: number,
        public idRequerimentoOutorgaFinalidade?: number,
        public identificacao?: string,
        public possuiReservatorioArtificial?: boolean,
        public volumeReservatorio?: number,
        public existeOutorga?: boolean,
        public orgaoCedenteOutorga?: string,
        public numeroOutorga?: string,
        public dataOutorga?: Date,
        public validadeOutorga?: Date,
        public culturas?: IrrigacaoCultura[],
        public pontos?: Ponto[],
        public formularioValido?: boolean,
        public resumoNecessidades?: ResumoNecessidadesIrrigacao,
    ) {

    }

    get pontosFormatados(): string {
        if (isNullOrUndefined(this.pontos)) {
            return "";
        }
        return this.pontos.map(ponto => ponto.nomePonto).join(", ");
    }

    get metodoSistemaIrrigacao(): string {
        if (isNullOrUndefined(this.culturas)) {
            return "";
        }
        return this.culturas.map(cult => cult.metodoSistemaIrrigacao.descricao).join(", ");
    }

    static fromJson(json: any): IrrigacaoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new IrrigacaoDTO(
            json.id,
            ModelUtil.getOrElseNull(json.idRequerimentoOutorgaFinalidade),
            ModelUtil.getOrElseNull(json.identificacao),
            ModelUtil.getOrElseNull(json.possuiReservatorioArtificial),
            ModelUtil.getOrElseNull(json.volumeReservatorio),
            ModelUtil.getOrElseNull(json.existeOutorga),
            ModelUtil.getOrElseNull(json.orgaoCedenteOutorga),
            ModelUtil.getOrElseNull(json.numeroOutorga),
            ModelUtil.getOrElseNull(json.dataOutorga),
            ModelUtil.getOrElseNull(json.validadeOutorga),
            ModelUtil.getOrElseEmptyArray(IrrigacaoCultura.fromJsons(json.culturas)),
            ModelUtil.getOrElseEmptyArray(json.pontos),
            ModelUtil.getOrElseNull(json.formularioValido),
            ModelUtil.getOrElseNull(ResumoNecessidadesIrrigacao.fromJson(json.resumoNecessidades))
        );
    }

    static fromJsons(json: any): IrrigacaoDTO[] {
        const array: IrrigacaoDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(IrrigacaoDTO.fromJson(item)));
            return array;
        }
    }
}
