import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-painel-caracterizacao',
    templateUrl: './painel-caracterizacao.component.html',
    styleUrls: ['./painel-caracterizacao.component.scss']
})
export class PainelCaracterizacaoComponent implements OnInit, OnChanges {

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Input() id: number;
    @Input() readonly = false;
    @Input() isNaoPodeEditar: boolean;

    form: FormGroup;
    dataSource: any = [];

    constructor( private fb: FormBuilder ) {

        this.form = this.fb.group({
        });
    }

    ngOnInit(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    saveTransformadores( objeto: any ): void {

    }

    saveSubestacao( objeto: any ): void {

    }
}
