import {BaseModel} from "./base.model";
import { ProcessDefinition } from "./bpms/process-definition";

export class Tarefa extends BaseModel {
    name?: string;
    assignee?: string;
    usuarioProcesso?: string;
    created?: Date;
    due?: Date;
    followUp?: Date;
    delegationState?: string;
    description?: string;
    executionId?: string;
    owner?: string;
    parentTaskId?: string;
    priority?: number;
    processDefinitionId?: string;
    processInstanceId?: string;
    taskDefinitionKey?: string;
    caseExecutionId?: string;
    caseInstanceId?: string;
    caseDefinitionId?: string;
    suspended?: boolean;
    formKey?: string;
    tenantId?: string;
    variables?: any;
    existCandidateGroup?: boolean;
    nomeProcesso?: string;
    numeroRequerimento?: string;
    porcentagem?: number;
    tarefaGrupo?: boolean;
    numeroAvisos?: number;
    process?: ProcessDefinition;
    processDefinitionKey?: string;
    execucao?: any;
}