import {BaseModel} from "./base.model";
import {EmpreendimentoEeHidrica} from "./empreendimento-ee-hidrica.model";

export class Derivacao extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoEeHidrica?: EmpreendimentoEeHidrica,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(json: any): Derivacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Derivacao(
            json.id,
            EmpreendimentoEeHidrica.fromJson(json.empreendimentoEeHidrica),
            json.descricao,
        );
    }

    static fromJsons(json: any): Derivacao[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(Derivacao.fromJson(j)));
            return resources;
        }
    }
}