import { Requerimento } from "app/main/pages/requerimentos/requerimento.model";
import {BaseModel} from "./base.model";
import { LancamentoCreditoReposicaoFlorestal } from "./lancamento-credito-reposicao-florestal.model";

export class FormularioReflorestamentoSemInventario extends BaseModel {

    constructor(
        public id?: number,
        public dataPlantio?: Date,
        public cpfDetentor?: string,
        public creditoSolicitadoM3?: number,
        public requerimento?: Requerimento,
        public lancamentoCreditoReposicaoFlrestal?: LancamentoCreditoReposicaoFlorestal,
        public idPessoa?: number,
        public idFinalidadePlantio?: number,
    ) {
        super();
    }

    static fromJson(json: any): FormularioReflorestamentoSemInventario {
        if (json === undefined || json === null) {
            return null;
        }
        return new FormularioReflorestamentoSemInventario(
            json.id,
            json.dataPlantio,
            json.cpfDetentor,
            json.creditoSolicitadoM3,
            Requerimento.fromJson(json.requerimento),
            LancamentoCreditoReposicaoFlorestal.fromJson(json.lancamentoCreditoReposicaoFlrestal),
            json.idPessoa,
            json.idFinalidadePlantio,
        );
    }

}