import {EnumTipoDocumentoDoencaPessoa} from './enum-tipo-documento-doenca.model';
import {Pessoa} from '../../../../shared/models/pessoa.model';

/**
 * Model que define o documento de doença da pessoa.
 */
export class DocumentoDoencaPessoa {
    arquivo: any;

    constructor(
        public id?: number,
        public nomeArquivo?: string,
        public tipo?: EnumTipoDocumentoDoencaPessoa,
        public pessoa?: Pessoa,
        public sigilo?: boolean
    ) {
    }

    static fromJson(json: any): DocumentoDoencaPessoa {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoDoencaPessoa(
            json.id,
            json.nomeArquivo,
            EnumTipoDocumentoDoencaPessoa.fromJson(json.tipo),
            Pessoa.fromJson(json.pessoa),
            json.sigilo
        );
    }

    static fromJsons(jsonArray: any): DocumentoDoencaPessoa[] {
        const documentos = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(json => {
                documentos.push(DocumentoDoencaPessoa.fromJson(json));
            });
            return documentos;
        }
    }
}
