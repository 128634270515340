import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {EmpreendimentoMeioIsolamento} from "../../../../../../../../shared/models/empreendimento-meio-isolamento.model";
import {
    EmpreendimentoMeioIsolamentoService
} from "../../../../../../../../shared/services/empreendimento-meio-isolamento.service";

@Component({
    selector: 'app-meio-isolamento',
    templateUrl: './meio-isolamento.component.html',
    styleUrls: ['./meio-isolamento.component.scss']
})
export class MeioIsolamentoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
    private $idEmpreendimento: number;

    meiosIsolamento: FormControl;
    tipos: Array<any> = [];

    constructor(private dominiosService: DominiosService,
                private meioIsolamentoService: EmpreendimentoMeioIsolamentoService) {
    }

    @Input() set idEmpreendimento(idEmpreendimento: number) {
        if (idEmpreendimento !== this.$idEmpreendimento) {
            this.$idEmpreendimento = idEmpreendimento;

            if (this.$idEmpreendimento) {
                this.meioIsolamentoService.getByEmpreendimento(this.$idEmpreendimento)
                    .subscribe(itens => {
                        this.tipos.forEach(t => t.checked = itens.some(i => i.meioIsolamento === t.id));
                        this.meiosIsolamento.setValue(itens);
                    });
            }
        }
    }

    async ngOnInit() {
        this.meiosIsolamento = this.form.get('meiosIsolamento') as FormControl;
        this.tipos = await this.dominiosService.getDominio("TIPO_MEIO_ISOLAMENTO").toPromise()
        this.tipos = this.tipos.map(t => {
            t.checked = false;
            return t;
        });
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    incrementarListaMeiosIsolamentos(meioisolamento: DominioDto, event): void {
        if (event.checked) {
            if (!this.meiosIsolamento.value.includes(meioisolamento.id)) {
                this.meiosIsolamento.value.push(EmpreendimentoMeioIsolamento.fromJson({
                    meioIsolamento: meioisolamento.id
                }));
            }
        } else {
            let index = this.meiosIsolamento.value.findIndex(m => m.meioIsolamento === meioisolamento.id);
            if (index > -1) {
                this.meiosIsolamento.value.splice(index, 1);
            }
        }
        this.form.get('meiosIsolamento').updateValueAndValidity();
    }
}
