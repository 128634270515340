import {Injectable, Injector} from '@angular/core';
import {EmpreendimentoMeioIsolamento} from '../models/empreendimento-meio-isolamento.model';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseEmpreendimentoEnergeticosSubestacaoService} from "./base-empreendimento-energeticos-subestacao.service";

@Injectable({
    providedIn: 'root'
})
export class EmpreendimentoMeioIsolamentoService extends BaseEmpreendimentoEnergeticosSubestacaoService<EmpreendimentoMeioIsolamento> {

    constructor(injector: Injector) {
        super(  '/api/empreendimento/meioIsolamento',
                injector,
                EmpreendimentoMeioIsolamento.prototype,
                EmpreendimentoMeioIsolamento.fromJson);
    }

    getByEmpreendimento(idEmpreendimento: number): Observable<Array<EmpreendimentoMeioIsolamento>> {
        const url = `${this.urlResourceEmpreendimentoEnergeticosSubestacao}/empreendimento/${idEmpreendimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}
