import {Injectable, Injector} from '@angular/core';
import {BaseService} from "../base.service";
import {environment} from "../../../../../environments/environment";
import {ParametroObjetivo} from "../../models/licenciamento-config-models/parametro-objetivo.model";

@Injectable({
    providedIn: 'root'
})
export class ParametroObjetivoService extends BaseService<ParametroObjetivo> {

    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            ParametroObjetivo.prototype,
            ParametroObjetivo.fromJson
        );
        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/parametro-objetivo'
    }
}