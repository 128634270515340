import { BaseModel } from "app/main/shared/models/base.model";
import { EmissaoTitulo } from "app/main/shared/models/emissao-titulo.model";
import { ParecerTecnico } from "../parecer-tecnico.model";

export class ParecerRequerimentoConclusaoDTO extends BaseModel {
    constructor(
        public emissaoTitulo?: EmissaoTitulo,
        public parecerTecnico?: ParecerTecnico,
        public enumParecerConclusao?: string
    ) {
        super();
    }

    static fromJson(json: any): ParecerRequerimentoConclusaoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParecerRequerimentoConclusaoDTO(
            ParecerTecnico.fromJson(json.parecerTecnico),
            EmissaoTitulo.fromJson(json.emissaoTitulo),
            json.enumParecerConclusao
        );
    }

    static fromJsons(json: any): ParecerRequerimentoConclusaoDTO[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(ParecerRequerimentoConclusaoDTO.fromJson(j)));
            return resources;
        }
    }
}