import { BaseEnum } from "../../enums/base.enum";
import { EnumStatusSolicitacaoManifestacao } from "../../enums/enum-status-solicitacao-manifestacao.enum";
import {BaseModel} from "../base.model";

export class SolicitacaoManifestacao extends BaseModel {

    constructor(
        public id?: number,
        public dataSolicitacao?: Date,
        public comentario?: string,
        public numeroProcesso?: string,
        public cpfCnpjDestinatario?: string,
        public nomeDestinatarioAtendimento?: string,
        public prazoRespostaDias?: number,
        public statusSolicitacao?: EnumStatusSolicitacaoManifestacao,
        public dataAvaliacao?: Date,
        public comentarioAvaliacao?: string,
        public setorRemetente?: string,
        public setorRemetenteResponsavel?: string,
        public setorRemetenteRespCpf?: string,
        public setorRemetenteSolicitante?: string,
        public emailDestinatario?: string,
        public tipoManifestacao?: string,
        public idOrganizacaoAdm?: string,
        public nomeGrupo?: string,
        public numeroSolicitacao?: string,
        public vencimento?: Date,
        public idRequerimentoSiga?: number,
        public tipoConclusaoManifestacao?: string,
        public orgaoExternoDestinatario?: string,
        public numeroProtocoloExtexno?: string
    ) {
        super();
    }

    static statusDasSolicitacoes: BaseEnum[] = [
        {valor: 'AGUARDANDO_APROVACAO', descricao: 'Aguardando aprovação'},
        {valor: 'AGUARDANDO_RESPOSTA', descricao: 'Aguardando resposta'},
        {valor: 'APROVADA', descricao: 'Aprovada'},
        {valor: 'REPROVADA', descricao: 'Reprovada'},
        {valor: 'RESPONDIDA', descricao: 'Respondida'},
    ];

    static fromJson(json: any): SolicitacaoManifestacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new SolicitacaoManifestacao(
            json.id,
            json.dataSolicitacao,
            json.comentario,
            json.numeroProcesso,
            json.cpfCnpjDestinatario,
            json.nomeDestinatarioAtendimento,
            json.prazoRespostaDias,
            json.statusSolicitacao,
            json.dataAvaliacao,
            json.comentarioAvaliacao,
            json.setorRemetente,
            json.setorRemetenteResponsavel,
            json.setorRemetenteRespCpf,
            json.setorRemetenteSolicitante,
            json.emailDestinatario,
            json.tipoManifestacao,
            json.idOrganizacaoAdm,
            json.nomeGrupo,
            json.numeroSolicitacao,
            json.vencimento,
            json.idRequerimentoSiga,
            json.tipoConclusaoManifestacao,
            json.orgaoExternoDestinatario,
            json.numeroProtocoloExtexno
        );
    }

    static fromJsons(jsonArray: any): SolicitacaoManifestacao[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}