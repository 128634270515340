import {BaseModel} from "../../shared/models/base.model";

export class LicencaFiscalizacao extends BaseModel {

    constructor(
        public idOrigem?: number,
        public arquivo?: string,
        public origem?: string,
        public cnpj?: string,
        public nome?: string,
        public numeroCar?: string,
        public tipo?: string,
        public municipio?: string,
        public latitude?: string,
        public longitude?: string,
        public numero?: string,
        public numeroProcesso?: string,
        public dataEmissao?: Date,
        public dataValidade?: Date,
        public situacao?: string,
        public dataAtualizaca?: Date
    ) {
        super();
    }

    static fromJson(json: any): LicencaFiscalizacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new LicencaFiscalizacao(
            json.idOrigem,
            json.arquivo,
            json.origem,
            json.cnpj,
            json.nome,
            json.numeroCar,
            json.tipo,
            json.municipio,
            json.latitude,
            json.longitude,
            json.numero,
            json.numeroProcesso,
            json.dataEmissao,
            json.dataValidade,
            json.situacao,
            json.dataAtualizaca
        );
    }

    static fromJsons(json: any): LicencaFiscalizacao[] {
        const lista: LicencaFiscalizacao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}