import {Injectable, Injector} from "@angular/core";
import {BaseService} from "../base.service";
import {RegraGeo} from "../../models/geoadmin/regra-geo.model";
import {RegraGeoFeicao} from "../../models/geoadmin/regra-geo-feicao.model";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {RequisicaoAnalisesTopologicas} from "../../models/geoadmin/analise-topologica";

@Injectable({
    providedIn: "root",
})
export class RegraGeoService extends BaseService<RegraGeo> {
    constructor(protected injector: Injector) {
        super("/api/regras-geo", injector, RegraGeo.prototype, RegraGeo.fromJson);
        this.urlResource = environment.URL_GEOADMIN + this.apiPath;
    }
    apply(idRegra: number, requisicao: RequisicaoAnalisesTopologicas): Observable<RegraGeo> {
        return this.http.post<RegraGeo>(`${this.urlResource}/apply/${idRegra}`, requisicao).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
    applyTerceiroCenario(idRegraGeoConfig: number, requisicao: RequisicaoAnalisesTopologicas): Observable<RegraGeo> {
        return this.http.post<RegraGeo>(`${this.urlResource}/apply/requerimento/${idRegraGeoConfig}`, requisicao).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
    applyBatch(requisicoes: RequisicaoAnalisesTopologicas[]): Observable<RegraGeo[]> {
        return this.http.post<RegraGeo[]>(`${this.urlResource}/apply/requerimento/batch`, requisicoes).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
    getByFeicao(idFeicao: number): Observable<RegraGeo[]> {
        return this.http.get(`${this.urlResource}/${idFeicao}/feicao`).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }
    getRegraGeoById(idRegraGeo: number): Observable<RegraGeo> {
        return this.http.get(`${this.urlResource}/${idRegraGeo}`).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
    getFeature(url: string): Observable<any> {
        return this.http.post<any>(`${this.urlResource}/get-feature`, url).pipe(
            map(x => x),
            catchError(this.handleError.bind(this))
        );
    }
    getFeatureFromDB(url: string): Observable<any> {
        const urlRequest = `${environment.URL_GEOADMIN}${url}`
        return this.http.get<any>(urlRequest).pipe(
            map(x => x),
            catchError(this.handleError.bind(this))
        );
    }
}

@Injectable({
    providedIn: "root",
})
export class RegraGeoFeicaoService extends BaseService<RegraGeoFeicao> {
    constructor(protected injector: Injector) {
        super("/api/regras-geo/feicao", injector, RegraGeoFeicao.prototype, RegraGeoFeicao.fromJson);
    }
}
