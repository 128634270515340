export enum EnumTarefaTitle {
    // Colocar as tarefas que forem rederizadas com String correta do título da Página

    EMITIR_ATO_ADMINISTRATIVO = 'Emitir Ato Administrativo',
    EMITIR_AUTORIZACAO_AMBIENTAL = 'Emitir Autorização Ambiental',
    EMITIR_PORTARIA_OUTORGA = 'Emitir Portaria de Outorga',
    REFERENDAR_CONSEMA = 'Referendar Parecer Técnico no CONSEMA',

    VALIDAR_ATO_ADMINISTRATIVO = 'Assinar Ato Adminisrativo',
    VALIDAR_PORTARIA_OUTORGA = 'Assinar Portaria Outorga',
    VALIDAR_PARECER_TECNICO = 'Assinar Parecer Técnico',
    APRECIAR_PARECER_TECNICO = 'Apreciar Parecer Técnico',

    APRECIAR_PORTARIA_OUTORGA = 'Apreciar Portaria de Outorga',
    ATRIBUIR_ANALISTA_REQUERIMENTO = 'Atribuir Requerimento',
    ATRIBUIR_REQUERIMENTO_MULTIDISCIPLINAR = 'Atribuir Requerimento Multidisciplinar',

    ACEITAR_ATRIBUICAO_REQUERIMENTO = 'Aceitar Atribuição do Processo',
    SOLICITAR_COMPLEMENTACAO = 'Solicitar Complementação',
    VALIDAR_ENCERRAMENTO_PRAZO = 'Validar Encerramento Prazo da Complementação',
    ANALISE_REQUERIMENTO = 'Analisar Processo',
    ARQUIVOS_VISTORIA = 'Anexar Arquivos de Vistorias',
    ELABORAR_PARECER_TECNICO = 'Revisar Parecer Técnico',

    CONFERIR_DOCUMENTOS = 'Conferir Documentos',
    REGISTRAR_COMPROVANTE_PUBLICACAO = 'Registrar Comprovante Publicação',
    VERIFICAR_REVISAO_PROCESSO = 'Verificar Revisão do Processo',

    VALIDAR_AUTORIZACAO_AMBIENTAL = 'Assinar Autorização Ambiental',
    VALIDAR_CERTIFICADO_CADASTRO = 'Assinar Certificado de Cadastro',
    ANALISAR_COMPLEMENTACAO = 'Analisar Complementação',
    VALIDAR_OFICIO_PENDENCIA = 'Validar Oficio de Pendencia',
    ENVIAR_COMPLEMENTACAO_SOLICITADA = 'Enviar Complementação Solicitada',
    ASSINAR_TERMO_ADESSAO_COMPROMISSO = 'Assinar Termo de Adesão e Compromisso',

    DIGITALIZAR_PROCESSO_LEGADO = 'Digitalizar Processo Legado',

    // MODULO SUGEF 
    SUGEF_ELABORAR_PARECER_TECNICO = 'Elaborar Parecer Técnico',
    VISTORIA_ARQUIVOS = 'Vistoria de Arquivos',
    AUDIENCIA_ARQUIVOS = 'Audiencia Reuniao publica ',
    REVISAR_TERMO_ASSINADO = 'Revisar Termo Assinado / Registrado',

    // MODULO SUIMIS
    SUIMIS_ELABORAR_PARECER_TECNICO = 'Elaborar Parecer Técnico',
    SUIMIS_VALIDAR_LICENCA_AMBIENTAL = 'Assinar Licença Ambiental',

    //MODULO TERMO REFERENCIA ESPECIFICO
    ELABORAR_TERMO_REFERENCIA = 'Elaborar Termo de Referência',
    VALIDAR_TERMO_REFERENCIA = 'Validar Termo de Referência',

    VALIDAR_SOLICITACAO = 'Validar Solicitação de Manifestação',
    RESPONDER_SOLICITACAO = 'Atender Solicitação de Manifestação',
    REGISTRAR_RESPOSTA_MANIFESTACAO = 'Registrar Resposta Externa'
}


export enum TarefaDefinitionKey {

    CADASTRAR_REQUERIMENTO = 'taskCadastrarRequerimento'

}