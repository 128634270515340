import {Component} from '@angular/core';

@Component({
    selector: 'app-overlay-orientacao-art',
    templateUrl: './overlay-orientacao-art.component.html',
    styleUrls: ['./overlay-orientacao-art.component.scss']
})
export class OverlayOrientacaoArtComponent {

    constructor() {
    }

}
