import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export class AlertAbaRequerimento {
    private _alerts: AlertAbaRequerimento[] = [];

    constructor(public mensagem: string) {
    }

    public push(mensagem: string): void {
        this._alerts.push(new AlertAbaRequerimento(mensagem));
    }

    get alerts(): AlertAbaRequerimento[] {
        return [...this._alerts].sort((a, b) => a.mensagem === b.mensagem ? 0 : 1);
    }

    get length(): number {
        return this._alerts.length;
    }

}

@Component({
    selector: 'app-requerimento-alert-dialog',
    templateUrl: './requerimento-alert-dialog.component.html',
})
export class RequerimentoAlertDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<RequerimentoAlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { alertsRequerimento: AlertAbaRequerimento[] }
    ) {
    }

    cancelar(): void {
        this.dialogRef.close(false);
    }

    continuar(): void {
        this.dialogRef.close(true);
    }

    get alertsRequerimento(): AlertAbaRequerimento[] {
        return this.data.alertsRequerimento;
    }

}
