import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {RequerimentoLicenciamentoUpa} from "./requerimento-licenciamento-upa.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class RequerimentoLicenciamentoUpaService extends BaseService<RequerimentoLicenciamentoUpa> {
    constructor(protected injector: Injector) {
        super(
            '/api/req-licenciamento-upa',
            injector,
            RequerimentoLicenciamentoUpa.prototype,
            RequerimentoLicenciamentoUpa.fromJson
        );
    }

    getByRequerimentoLicenciamentoIdAndIdFeicao(idRequerimentoLicenciamento: number, idFeicao: number): Observable<RequerimentoLicenciamentoUpa> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/id-feicao/${idFeicao}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }


    getModulosFiscais(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/modulos-fiscais/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}
