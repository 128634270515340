import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PostoFluviometrico} from '../../../../../../shared/models/posto-fluviometrico.model';
import {PostoFluviometricoService} from '../../../../../../shared/services/posto-fluviometrico.service';
import {ObraPostoFluviometrico} from '../../../../../../shared/models/obra-posto-fluviometrico.model';
import {BaseModel} from '../../../../../../shared/models/base.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-dialog-obra-posto-fluviometrico',
    templateUrl: './dialog-obra-posto-fluviometrico.component.html',
    styleUrls: ['./dialog-obra-posto-fluviometrico.component.scss']
})
export class DialogObraPostoFluviometricoComponent implements OnInit {

    form: FormGroup;
    postosFluviometricos: PostoFluviometrico[];
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    constructor(
        public dialogRef: MatDialogRef<DialogObraPostoFluviometricoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private postoFluviometricoService: PostoFluviometricoService,
        private snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
        this.construirFormulario();
        this.postosFluviometricos = this.data.postosFluviometricos;
        if (this.data.form) {
            this.form.patchValue(this.data.form);
        }
    }

    private construirFormulario(): void {
        this.form = this.formBuilder.group({
            postoFluviometrico: [null, Validators.required],
            obraBarragem: [null, Validators.required],
            areaDrenagem: [null, [Validators.required, Validators.min(1)]],
            areaDrenagemBarramento: [null, [Validators.required, Validators.min(1)]],
            precipitacaoMediaAnual: [null, [Validators.required, Validators.min(0.0001)]],
            evaporacaoMediaAnual: [null, [Validators.required, Validators.min(0.0001)]],
            evaporacaoMediaMensal: [null, [Validators.required, Validators.min(0.0001)]],
            periodoVazaoMltInicio: [null, [Validators.required, Validators.min(1)]],
            periodoVazaoMltFim: [null, [Validators.required, Validators.min(1)]],
            vazaoMediaLongoTermo: [null, [Validators.required, Validators.min(0.0001)]],
            vazaoMaximaRegistrada: [null, [Validators.required, Validators.min(0.0001)]],
            vazaoMinimaRegistrada: [null, [Validators.required, Validators.min(0.0001)]],
            vazaoFirme: [null, [Validators.required, Validators.min(0.0001)]],
            vazaoMinMedMensal: [null, [Validators.required, Validators.min(0.0001)]],
            vazaoProjeto: [null, [Validators.required, Validators.min(0.0001)]],
            tempoRecorrenciaVazao: [null, [Validators.required, Validators.min(1)]],
            vazaoObrasDesvio: [null, [Validators.required, Validators.min(0.0001)]],
            tempoRecorrenciaVazaoObras: [null, [Validators.required, Validators.min(1)]]
        });
    }

    salvar(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const obraPosto = ObraPostoFluviometrico.fromJson(this.form.value);

            (this.data.form ?
                this.postoFluviometricoService.updateObraPostoFluviometrico(obraPosto) :
                this.postoFluviometricoService.createObraPostoFluviometrico(obraPosto)).subscribe(result => {
                this.snackBarService.showSuccess('Posto fluviométrico salvo com sucesso');
                this.dialogRef.close(true);
            }, (e) => this.snackBarService.showError('Erro ao adicionar Posto fluviométrico', e));
        } else {
            this.snackBarService.showAlert('Preencha todos os campos');
        }

    }

}
