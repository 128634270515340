import {BaseModel} from "./base.model";
import {EmpreendimentoObrasViarias} from "./empreendimento-obras-viarias.model";

export class CategoriaEmpObrasViarias extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoObrasViarias?: EmpreendimentoObrasViarias,
        public categoria?: string
    ) {
        super();
    }

    static fromJson(json: any): CategoriaEmpObrasViarias {
        if (json === undefined || json === null) {
            return null;
        }
        return new CategoriaEmpObrasViarias(
            json.id,
            EmpreendimentoObrasViarias.fromJson(json.empreendimentoObrasViarias),
            json.categoria,
        );
    }

    static fromJsons(json: any): CategoriaEmpObrasViarias[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(CategoriaEmpObrasViarias.fromJson(j)));
            return resources;
        }
    }
}