import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";

@Component({
    selector: 'app-gerenciamento-residuos',
    templateUrl: './gerenciamento-residuos.component.html',
    styleUrls: ['./gerenciamento-residuos.component.scss']
})
export class GerenciamentoResiduosComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    @Input() idGerenciamentoResiduo: number;
    @Input() idResiduoGerado: number;
    
    isNaoPodeEditar: boolean = false;

    public reloadRegistrosGerados: BehaviorSubject<any>;

    constructor(
    ) {
        super();
        this.reloadRegistrosGerados = new BehaviorSubject(null);
    }

    async ngOnInit() {
        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
        }
    }

    onSaveResiduosGerados(event): void {
        this.reloadRegistrosGerados.next(event);
        this.enviarFormConcluido.emit();
        console.log(event);
    }

    onSaveGerenciamentoResiduo(event): void {
        console.log(event);
    }

}
