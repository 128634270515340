import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {RotasTransporteResiduosPerigosos} from '../../../../../../../../shared/models/rotas-transporte-residuos-perigosos.model';
import {RotasRodoviasDto} from '../../../../../../../../shared/models/rotas-rodovias-dto.model';
import {RodoviasTransporteResiduosPerigosos} from '../../../../../../../../shared/models/rodovias-transporte-residuos-perigosos.model';
import {PessoaCorporativoService} from '../../../../../../../../shared/services/pessoa-corporativo.service';
import {SnackBarService} from '../../../../../../../../shared/snack-bar/snack-bar.service';
import {FormUtil} from '../../../../../../../../shared/util/form-util';
import {ResiduosTransporteProdutosPerigososService} from '../residuos-transporte-produtos-perigosos.service';
import {EmissaoTituloService} from '../../../../../../../tarefas/shared/service/emissao-titulo.service';

@Component({
    selector: 'app-transporte-residuos-rodovias',
    templateUrl: './transporte-residuos-rodovias.component.html',
    styleUrls: ['./transporte-residuos-rodovias.component.scss']
})
export class TransporteResiduosRodoviasComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() enviarTrasporteRodovias = new EventEmitter<any[]>();
    formProcedencia: FormGroup;
    formRodovias: FormGroup;
    mostrarProcedenciaNumeroLicenca = false;
    mostrarDestinoNumeroLicenca = false;
    rodoviasSalvas: any = [];
    listaRodoviasTemp: any = [];
    dataSourceRodovias = new MatTableDataSource<any>([]);
    procedenciaSalvas: any = [];
    dataSourceProcedencia = new MatTableDataSource<any>([]);
    idProcedencia = 0;
    idRodovias = 0;
    modoEdicao = false;

    constructor(private formBuilder: FormBuilder,
                private scorpService: PessoaCorporativoService,
                private snackBarService: SnackBarService,
                public utils: FormUtil,
                private serviceTransporte: ResiduosTransporteProdutosPerigososService,
                public emissaoTituloService: EmissaoTituloService) {
    }

    ngOnInit() {
        this.buildForm();
        this.buildFormRodovias();

        if (this.idForm) {
            this.serviceTransporte.buscarRodoviasPorId(this.idForm).subscribe(response => {
                response.forEach(procedencia => {
                    this.procedenciaSalvas.push({
                        id: procedencia.rotasTransporteResiduosPerigosos.id,
                        idTemp: this.idProcedencia,
                        procedenciaCnpj: procedencia.rotasTransporteResiduosPerigosos.procedenciaCnpj,
                        procedenciaRazaoSocial: procedencia.rotasTransporteResiduosPerigosos.procedenciaRazaoSocial,
                        procedenciaNumeroLicenca: procedencia.rotasTransporteResiduosPerigosos.procedenciaNumeroLicenca,
                        destinoCnpj: procedencia.rotasTransporteResiduosPerigosos.destinoCnpj,
                        destinoRazaoSocial: procedencia.rotasTransporteResiduosPerigosos.destinoRazaoSocial,
                        destinoNumeroLicenca: procedencia.rotasTransporteResiduosPerigosos.destinoNumeroLicenca,
                        listaRodovias: this.mapListaRodovias(procedencia.listaRodovias),
                    });
                    this.idProcedencia++;
                });
                this.dataSourceProcedencia = new MatTableDataSource(this.procedenciaSalvas);
                this.enviarRodovias(this.mapearDto());
            }, error => {
                this.snackBarService.showError('Ocorreu um erro ao buscar as rodovias salvas. Cod. Erro: ' + error.status);
            });
        }
        if (this.isNaoPodeEditar) {
            this.formRodovias.disable();
            this.formProcedencia.disable();
        }
    }

    mapListaRodovias(listaRodovias): any {
        listaRodovias.forEach(rodovia => {
            this.listaRodoviasTemp.push({
                id: rodovia.id,
                idTemp: this.idRodovias,
                rotasTransporteResiduosPerigosos: null,
                rodoviaBr: rodovia.rodoviaBr,
                rodoviaMt: rodovia.rodoviaMt,
            });
            this.idRodovias++;
        });
        return this.listaRodoviasTemp;
    }

    private buildForm(): void {
        this.formProcedencia = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            procedenciaCnpj: ['', [Validators.required]],
            procedenciaRazaoSocial: ['', [Validators.required]],
            procedenciaNumeroLicenca: ['', [Validators.required]],
            destinoCnpj: ['', [Validators.required]],
            destinoRazaoSocial: ['', [Validators.required]],
            destinoNumeroLicenca: ['', [Validators.required]],
            listaRodovias: ['']
        });
        this.formProcedencia.controls.procedenciaNumeroLicenca.disable();
        this.formProcedencia.controls.destinoNumeroLicenca.disable();
    }

    private buildFormRodovias(): void {
        this.formRodovias = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            idProcedencia: [''],
            rodoviaBr: ['', [Validators.required]],
            rodoviaMt: ['', [Validators.required]],
        });
    }

    validarNumeroLicenca(evt: any): void {
        // Validacao da licença tarefa #8537 RN005 - Número da licença de operação
        const value = evt.target;
        const numeroEmissao = this.numeroEmissaoMask(value.value);
        if (numeroEmissao !== null) {
            this.emissaoTituloService.buscaEmissaoTituloPorNumeroEmissao(numeroEmissao).subscribe(result => {
                if (!result) {
                    this.snackBarService.showError('O sistema verificou que o número informado para licença de Operação é inválido.');
                } else if (result.parecerRequerimentoConclusao.requerimentoTitulacao.titulacao.descricao !== 'LO - Licença de Operação'){
                    this.snackBarService.showError('O sistema verificou que o número informado para licença de Operação é inválido.');
                }
            });
        }
    }

    changeCnpjProcedencia(evt: any): void {
        const {value} = evt.target;
        if (this.validarCnpj(value)) {
            this.scorpService.consultarCnpjSemValidacaoCadastro(FormUtil.obterApenaNumeros(value)).subscribe((retorno) => {
                this.formProcedencia.controls.procedenciaRazaoSocial
                    .patchValue(retorno.razaoSocial ? retorno.razaoSocial : retorno.nome);
                this.changeProcedenciaNumeroLicenca('Ativar');
            }, error => {
                this.formProcedencia.controls.procedenciaCnpj.reset();
                this.changeProcedenciaNumeroLicenca('Desativar');
                this.snackBarService.showAlert('Por favor, apresente as documentações da empresa ' +
                    'de procedência/destino, de acordo com o termo de Referência. Aba 6 – Documentação complementar.');
            });
        }
    }

    changeCnpjDestino(evt: any) {
        const {value} = evt.target;
        if (this.validarCnpj(value)) {
            this.scorpService.consultarCnpjSemValidacaoCadastro(FormUtil.obterApenaNumeros(value)).subscribe((retorno) => {
                this.formProcedencia.controls.destinoRazaoSocial
                    .patchValue(retorno.razaoSocial ? retorno.razaoSocial : retorno.nome);
                this.changeDestinoNumeroLicenca('Ativar');
            }, error => {
                this.formProcedencia.controls.destinoCnpj.reset();
                this.changeDestinoNumeroLicenca('Desativar');
                this.snackBarService.showAlert('Por favor, apresente as documentações da empresa ' +
                    'de procedência/destino, de acordo com o termo de Referência. Aba 6 – Documentação complementar.');
            });
        }
    }

    validarCnpj(cnpj): boolean {
        if (!cnpj || cnpj === '') {
            this.snackBarService.showAlert('Favor inserir um CNPJ.');
            return false;
        } else {
            return true;
        }
    }

    numeroRequerimentoProcessoMask(text, campo): void {
        if (text && text.length > 4) {
            if (text.indexOf('/') > -1) {
                text = text.replace('/', '');
            }

            const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
            const year = text.slice(-4);
            campo.patchValue(`${initial}/${year}`);
        }
    }

    mapearDto(): any {
        if (this.procedenciaSalvas.length == 0) {
            return this.procedenciaSalvas;
        } else {
            return this.procedenciaSalvas.map(function (item) {
                return new RotasRodoviasDto(
                    (new RotasTransporteResiduosPerigosos(
                        item.id,
                        null,
                        FormUtil.obterApenaNumeros(item.procedenciaCnpj),
                        item.procedenciaRazaoSocial,
                        item.procedenciaNumeroLicenca ? FormUtil.removeCaracteresEspeciaisRetornaNumero(item.procedenciaNumeroLicenca) : 0,
                        FormUtil.obterApenaNumeros(item.destinoCnpj),
                        item.destinoRazaoSocial,
                        item.destinoNumeroLicenca ? FormUtil.removeCaracteresEspeciaisRetornaNumero(item.destinoNumeroLicenca) : 0,
                    )),
                    (item.listaRodovias.length == 0 ? item.listaRodovias : item.listaRodovias.map(function (item) {
                        return new RodoviasTransporteResiduosPerigosos(item.id, null, Number(item.rodoviaBr), Number(item.rodoviaMt));
                    }))
                );
            });
        }
    }

    adicionarRodovia() {
        if (this.formRodovias.valid) {
            this.rodoviasSalvas.push({
                id: null,
                idTemp: this.idRodovias,
                idProcedencia: this.modoEdicao ? this.formProcedencia.controls.id.value : this.idProcedencia,
                rodoviaBr: this.formRodovias.controls.rodoviaBr.value,
                rodoviaMt: this.formRodovias.controls.rodoviaMt.value
            });
            this.idRodovias++;
            this.dataSourceRodovias = new MatTableDataSource(this.rodoviasSalvas);
            this.formRodovias.reset();
        } else {
            this.formRodovias.markAllAsTouched();
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    adicionarTransporteRodovia() {
        if (this.formProcedencia.valid) {
            if (this.rodoviasSalvas.length == 0) {
                this.formRodovias.markAllAsTouched();
                this.snackBarService.showAlert('Adicione uma Rodovia.');
            } else {
                this.procedenciaSalvas.push({
                    id: null,
                    idTemp: this.idProcedencia,
                    procedenciaCnpj: this.formProcedencia.controls.procedenciaCnpj.value,
                    procedenciaRazaoSocial: this.formProcedencia.controls.procedenciaRazaoSocial.value,
                    procedenciaNumeroLicenca: this.formProcedencia.controls.procedenciaNumeroLicenca.value,
                    destinoCnpj: this.formProcedencia.controls.destinoCnpj.value,
                    destinoRazaoSocial: this.formProcedencia.controls.destinoRazaoSocial.value,
                    destinoNumeroLicenca: this.formProcedencia.controls.destinoNumeroLicenca.value,
                    listaRodovias: this.rodoviasSalvas
                });
                this.dataSourceRodovias = new MatTableDataSource([]);
                this.rodoviasSalvas = [];
                this.dataSourceProcedencia = new MatTableDataSource(this.procedenciaSalvas);
                this.enviarRodovias(this.mapearDto());
                this.formProcedencia.reset();
                this.idProcedencia++;
            }
        } else {
            this.formProcedencia.markAllAsTouched();
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    changeProcedenciaNumeroLicenca(acao: string) {
        if (acao == 'Ativar') {
            this.mostrarProcedenciaNumeroLicenca = true;
            this.formProcedencia.controls.procedenciaNumeroLicenca.enable();
        } else {
            this.mostrarProcedenciaNumeroLicenca = false;
            this.formProcedencia.controls.procedenciaNumeroLicenca.disable();
        }
    }

    changeDestinoNumeroLicenca(acao: string) {
        if (acao == 'Ativar') {
            this.mostrarDestinoNumeroLicenca = true;
            this.formProcedencia.controls.destinoNumeroLicenca.enable();
        } else {
            this.mostrarDestinoNumeroLicenca = false;
            this.formProcedencia.controls.destinoNumeroLicenca.disable();
        }
    }

    ativarModoEdicao(item) {
        this.formProcedencia.controls.id.patchValue(item.id);
        this.formProcedencia.controls.idTemp.patchValue(item.idTemp);
        this.formProcedencia.controls.procedenciaCnpj.patchValue(item.procedenciaCnpj);
        this.formProcedencia.controls.procedenciaRazaoSocial.patchValue(item.procedenciaRazaoSocial);
        this.formProcedencia.controls.procedenciaNumeroLicenca.patchValue(item.procedenciaNumeroLicenca);
        if (!this.verificarVazio(item.procedenciaNumeroLicenca)) {
            this.changeProcedenciaNumeroLicenca('Ativar');
        } else {
            this.changeProcedenciaNumeroLicenca('Desativar');
        }

        this.formProcedencia.controls.destinoCnpj.patchValue(item.destinoCnpj);
        this.formProcedencia.controls.destinoRazaoSocial.patchValue(item.destinoRazaoSocial);
        this.formProcedencia.controls.destinoNumeroLicenca.patchValue(item.destinoNumeroLicenca);
        if (!this.verificarVazio(item.destinoNumeroLicenca)) {
            this.changeDestinoNumeroLicenca('Ativar');
        } else {
            this.changeDestinoNumeroLicenca('Desativar');
        }
        this.formProcedencia.controls.listaRodovias.patchValue(item.listaRodovias);
        this.rodoviasSalvas = item.listaRodovias;
        this.dataSourceRodovias = new MatTableDataSource(this.rodoviasSalvas);
        this.modoEdicao = true;
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.rodoviasSalvas = [];
        this.dataSourceRodovias = new MatTableDataSource([]);
        this.formProcedencia.reset();
        this.formRodovias.reset();
    }

    verificarVazio(item): boolean {
        return item == null || item == '';
    }

    editarTransporteRodovia() {
        if (this.formProcedencia.valid) {
            this.deletarProcedencia(this.formProcedencia.controls.idTemp.value);
            this.procedenciaSalvas.push({
                id: this.formProcedencia.controls.id.value,
                idTemp: this.formProcedencia.controls.idTemp.value,
                procedenciaCnpj: this.formProcedencia.controls.procedenciaCnpj.value,
                procedenciaRazaoSocial: this.formProcedencia.controls.procedenciaRazaoSocial.value,
                procedenciaNumeroLicenca: this.formProcedencia.controls.procedenciaNumeroLicenca.value,
                destinoCnpj: this.formProcedencia.controls.destinoCnpj.value,
                destinoRazaoSocial: this.formProcedencia.controls.destinoRazaoSocial.value,
                destinoNumeroLicenca: this.formProcedencia.controls.destinoNumeroLicenca.value,
                listaRodovias: this.rodoviasSalvas
            });
            this.dataSourceRodovias = new MatTableDataSource([]);
            this.rodoviasSalvas = [];
            this.dataSourceProcedencia = new MatTableDataSource(this.procedenciaSalvas);
            this.enviarRodovias(this.mapearDto());
            this.formProcedencia.reset();
            this.modoEdicao = false;
        }
    }

    deletarProcedencia(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.serviceTransporte.deletarProcedenciaPorId(itemId).subscribe(response => {
                this.snackBarService.showSuccess('Procedencia excluida com sucesso.');
            }, error => {
                this.snackBarService.showError('Ocorreu um erro ao deletar a procedencia. Cod. Erro: ' + error.status);
            });
        }
        let data;
        data = this.procedenciaSalvas.filter((value, key) => {
            return value.idTemp != item;
        });
        this.procedenciaSalvas = data;
        this.dataSourceProcedencia = new MatTableDataSource(data);
        this.enviarRodovias(this.mapearDto());
    }

    deletarRodovia(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.serviceTransporte.deletarRodoviaPorId(itemId).subscribe(response => {
                this.snackBarService.showSuccess('Rodovia excluida com sucesso.');
            }, error => {
                this.snackBarService.showError('Ocorreu um erro ao deletar a rodovia. Cod. Erro: ' + error.status);
            });
        }
        let data;
        data = this.rodoviasSalvas.filter((value, key) => {
            return value.idTemp != item;
        });
        this.rodoviasSalvas = data;
        this.dataSourceRodovias = new MatTableDataSource(data);
    }

    enviarRodovias(rodovias: any[]) {
        this.enviarTrasporteRodovias.emit(rodovias);
    }

    removeCaracteresEspeciaisRetornaNumero(value: string): number {
        const result = value.replace(/[^\w\s]/gi, '');
        return Number(result);
    }

    numeroEmissaoMask(text): any {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                return `${initial}/${year}`;
            }
        }
    }
}