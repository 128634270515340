import {AnaliseLicencaPreviaConsema} from "./analise-licenca-previa-consema.model";

export class AnaliseLicencaPreviaConsemaAnexo {
    constructor(
        public id?: number,
        public analiseConsema?: AnaliseLicencaPreviaConsema,
        public tituloDocumento?: string,
        public chave?: string,
    ) {
    }

    static fromJson(json: any): AnaliseLicencaPreviaConsemaAnexo {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnaliseLicencaPreviaConsemaAnexo(
            json.id,
            AnaliseLicencaPreviaConsema.fromJson(json.analiseConsema),
            json.tituloDocumento,
            json.chave,
        );
    }

    static fromJsons(jsonArray: any): AnaliseLicencaPreviaConsemaAnexo[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }

}