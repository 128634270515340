import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {TratamentoEfluentes} from '../models/tratamento-efluentes.model';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TratamentoEfluentesService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_LIC_FEICAO_BENFEITORIA;

    createTratamentoEfluentes(tratamentoEfluentes: TratamentoEfluentes): Observable<TratamentoEfluentes> {
        const url = `${this.urlResource}/api/tratamento-efluente`;
        return this.http.post(url, tratamentoEfluentes)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }


}
