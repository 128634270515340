import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from 'environments/environment';
import {VazaoDto} from '../../../pages/requerimentos/shared/dialog-disponibilidade-hidrica/model/vazao-dto.model';
import {LancamentoDto} from '../../../pages/requerimentos/shared/dialog-disponibilidade-hidrica/model/lancamento-dto.model';
import {DisponibilidadeHidricaMesManual} from '../../../pages/requerimentos/shared/dialog-disponibilidade-hidrica/model/disponibilidade-hidrica-mes-manual.model';
import {VazaoContinuaDto} from '../../../pages/tarefas/requerimento-analise/requerimento-analise-pontos/vazao-continua-dto.model';
import {catchError, map} from 'rxjs/operators';
import {DisponibilidadeHidricaInfo} from '../../../pages/requerimentos/shared/dialog-disponibilidade-hidrica/model/disponibilidade-hidrica-info.model';
import {RedefinirDisponibilidadeResponse} from "../../models/hidrico-models/redefinir-disponibilidade-response.model";
import {PontoVazaoMensal} from "../../components/ponto-vazao-mensal/models/ponto-vazao-mensal.model";
import {MicrobaciaInfoResponse} from "../../models/hidrico-models/microbacia-info-response.model";
import { ProcessamentoCoordenadaDto } from '../../models/processamento-coordenada-dto.model';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material';
import { CoordenadaDTO } from '../../models/hidrico-models/coordenada-dto';
import { Page } from '../../models/page.model';
import { SnackBarService } from '../../snack-bar/snack-bar.service';
import { BaseService } from '../base.service';
import { truncateSync } from 'fs';
import { CancelarBalancoHidricoRequest } from 'app/main/pages/devolucao-recurso-hidrico/devolver-recurso-hidrico-detail/devolver-recurso-hidrico-detail.component';
import { createRequestOptions } from 'app/main/core/util/request-util';

@Injectable({providedIn: 'root'})
export class HidricoService {
    
    url = `${environment.URL_HIDRICO}/api`;
    protected http: HttpClient;

    constructor(injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    consultarVazaoContinuaDTO(idMicrobacia: number, vazaoDto: VazaoDto): Observable<VazaoContinuaDto> {
        return this.http.post(`${this.url}/microbacias/${idMicrobacia}/vazao-continua-dto`, vazaoDto)
            .pipe(map(response => VazaoContinuaDto.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    consultarVazaoDisponivel(idMicrobacia: number, tipoSolicitacao: EnumTipoSolicitacao): Observable<any> {
        return this.http.get(`${this.url}/microbacias/${idMicrobacia}/vazao-disponivel/${tipoSolicitacao}`);
    }

    consultarCaptacao(idMicrobacia: number, meses: VazaoDto, tipoSolicitacao: EnumTipoSolicitacao): Observable<any> {
        return this.http.post(`${this.url}/microbacias/${idMicrobacia}/vazao-disponivel-solicitacao-captacao/${tipoSolicitacao}`, meses);
    }

    consultarLancamento(idMicrobacia: number, lancamentoDto: LancamentoDto): Observable<any> {
        return this.http.post(`${this.url}/microbacias/${idMicrobacia}/vazao-disponivel-solicitacao-diluicao`, lancamentoDto);
    }

    consultarCaptacaoManual(idMicrobacia: number, disponibilidadesMesManual: DisponibilidadeHidricaMesManual[], tipoSolicitacao: EnumTipoSolicitacao): Observable<DisponibilidadeHidricaInfo> {
        return this.http.post(`${this.url}/microbacias/${idMicrobacia}/vazao-disponivel-manual-captacao/${tipoSolicitacao}`, disponibilidadesMesManual)
            .pipe(map(response => DisponibilidadeHidricaInfo.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    consultarParametros(): Observable<any> {
        return this.http.get(`${this.url}/parametros/list`);
    }

    consultarInfoRedefinirDisponibilidade(latitude: number, longitude: number): Observable<any> {
        return this.http.get(`${this.url}/redefinir-disponibilidades?latitude=${latitude}&longitude=${longitude}`);
    }

    redefinirDisponibilidade(dto: any): Observable<any> {
        return this.http.post(`${this.url}/redefinir-disponibilidades`, dto)
            .pipe(map(response => RedefinirDisponibilidadeResponse.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    consultarInfoMicrobacia(idMicrobacia: number): Observable<MicrobaciaInfoResponse> {
        return this.http.get(`${this.url}/microbacias/${idMicrobacia}/info`)
            .pipe(map(response => MicrobaciaInfoResponse.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    listarProcessamentoCoordenada(idCoordenada: number): Observable<any>  {
        return this.http.get(`${this.url}/processamento-coordenadas/dto-coordenada?idCoordenada=${idCoordenada}`)
            .pipe(map(response => ProcessamentoCoordenadaDto.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    listarProcessamentoCoordenadaPontoAnalise(idPontoOutorga: number): Observable<any>  {
        return this.http.get(`${this.url}/processamento-coordenadas?idPontoOutorga=${idPontoOutorga}`)
            .pipe(map(response => ProcessamentoCoordenadaDto.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    listarProcessamentoCoordenadaDevolucaoRecurso(tipoProcessamento: string, pageIndex: number, 
        pageSize: number, descricao: string): Observable<Page<ProcessamentoCoordenadaDto>>  {
        const url = `${this.url}/processamento-coordenadas/${tipoProcessamento}/page`;
        const params: any = {
            page: pageIndex,
            size: pageSize,
            descricao: descricao
        };

        const httpParams: HttpParams = Object.getOwnPropertyNames(params)
            .filter(item => params[item])
            .reduce(
                (p, key) => p.set(key, params[key])
                , new HttpParams()
        );

        return this.http.get(url, {params: httpParams, withCredentials: true}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );

    }

    getCoordenadasBalancoHidrico(form: FormGroup, pageIndex: number, pageSize: number): Observable<Page<CoordenadaDTO>> {
        const url = `${this.url}/coordenadas/filtro`;

        const params: any = {
            page: pageIndex,
            size: pageSize,
            id: form.get('idVazao').value,
            numeroProcesso: form.get('numeroProcesso').value,
            cpfCnpjInteressado: form.get('cpfCnpjInteressado').value,
            empreendimento: form.get('empreendimento').value,
            
        };

        const httpParams: HttpParams = Object.getOwnPropertyNames(params)
            .filter(item => params[item])
            .reduce(
                (p, key) => p.set(key, params[key])
                , new HttpParams()
        );

        if(httpParams.keys().length <= 1) {
            return throwError("Obrigatório informar ao menos um filtro para pesquisa.");
        } 

        return this.http.get(url, {params: httpParams, withCredentials: true}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    findCoordenadaById(coordenadaId: number) {
        return this.http.get(`${this.url}/coordenadas/${coordenadaId}/coordenada`)
            .pipe(map(response => CoordenadaDTO.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    findProcessamentoCoordenadaByIdPontoOutorga(idPontoOutorga: number, idCoordenada: number) {
        let httpParams = new HttpParams()
        httpParams = createRequestOptions(idPontoOutorga != null ? {
            idPontoOutorga: (idPontoOutorga)
        } : {
            idCoordenada: (idCoordenada)
        });
    
        httpParams = httpParams.set('tipoProcessamento', 'CANCELAR');

        const url = `${this.url}/processamento-coordenadas/visualizar-devolucao-recurso`;
        return this.http.get(url, {params: httpParams, withCredentials: true})
            .pipe(map(response => ProcessamentoCoordenadaDto.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    devolverRecursoHidrico(cancelarBalancoHidricoRequest: CancelarBalancoHidricoRequest) {
        console.log("cancelarBalancoHidricoRequest ==>> ", cancelarBalancoHidricoRequest);
        return this.http.post(`${this.url}/coordenadas/cancelar-outorga-ponto`, cancelarBalancoHidricoRequest)
            .pipe(map(response => CancelarBalancoHidricoRequest.fromJson(response)),
                catchError(this.handleError.bind(this)));
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}

export declare type TipoGeoPortal = 'ponto';

export enum EnumTipoSolicitacao {
    CAPTACAO_SUPERFICIAL_OUTORGA = 'OUTORGA',
}
