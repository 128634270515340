import {Injectable, Injector} from '@angular/core';
import {ListResolver} from '../resolvers/list-resolver';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {EmpreendimentoEnergiaEolica} from '../models/empreendimento-energia-eolica.model';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";


@Injectable()
export class EmpreendimentoEnergeticoListResolver extends ListResolver<EmpreendimentoEnergiaEolica> {
    constructor(service: EmpreendimentoEnergeticoService) {
        super(service);
    }
}

@Injectable()
export class EmpreendimentoEnergeticoResolver extends DetailResolver<EmpreendimentoEnergiaEolica> {
    constructor(
        service: EmpreendimentoEnergeticoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class EmpreendimentoEnergeticoService extends BaseService<EmpreendimentoEnergiaEolica> {

    constructor(protected injector: Injector) {
        super(
            "/api/empreedimento-energia-eolica",
            injector,
            EmpreendimentoEnergiaEolica.prototype,
            EmpreendimentoEnergiaEolica.fromJson
        );
    }

    getByRequerimento(idRequerimento: number): Observable<EmpreendimentoEnergiaEolica> {
        const url = `${this.urlEeEolica}/requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
