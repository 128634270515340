import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AssinadorEletronicoService {
  private http: HttpClient;
  private urlResource = environment.URL_GATEWAY + '/api/assinador-eletronico'

  constructor(private injector: Injector) {
    this.http = injector.get(HttpClient);
  }

    salvarDocumentoPandora(multipartFile: Blob): Observable<any> {
        return this.http.post(
            this.urlResource + '/salvar-documento',
            this.montarFormData(multipartFile)).pipe(
            map(t => t),
            catchError(this.handleError.bind(this))
        );
    }

  readPDFByChave(chave: string): Observable<Blob> {
    const url = `${this.urlResource}/${chave}/pdf`;
    return this.http.get(url, {responseType: 'blob'}).pipe(
      map((res) => {
        return new Blob([res], {type: 'application/pdf'});
      }), catchError(() => this.handleError(this)));
  }

    readHtmlByChave(chave: string): Observable<Blob> {
        const url = `${this.urlResource}/${chave}/pdf`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                return new Blob([res], {type: 'text/html'});
            }), catchError(() => this.handleError(this)));
    }

    private montarFormData(
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        formData.append('validar', new Blob(
            ['Pdf Validado'],
            {type: 'text/plain'}
        ));
        return formData;
    }

  protected handleError(error: any): Observable<any> {
    return throwError(error);
  }

}
