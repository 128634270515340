// var xmlParseString = require('xml2js').parseString;
// import * as xml2js from 'xml2js';

import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {LanguageHelper} from './language-helper.service';

@Injectable()
export class UtilService {

    constructor(
        private languageHelper: LanguageHelper
    ) {
    }

    public static getGuid(): string {
        return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // ex de data: 2020-10-15T20:58:45.577-03:00
    zonedDateTimeStringFormatter(zonedDateTimeString: string): string {
        if (!zonedDateTimeString || zonedDateTimeString.length < 8) {
            return '';
        }
        return zonedDateTimeString.substr(8, 2) + '/' +
            zonedDateTimeString.substr(5, 2) + '/' +
            zonedDateTimeString.substr(0, 4) + ' ' +
            zonedDateTimeString.substr(11, 5)
    }

    public static strNormalizeNFD(value: string) {
        const str = 'ÁÉÍÓÚáéíóúâêîôûàèìòùÇç/.,~!@#$%&_-12345';
        return value.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
    }

    public static arquivoNomeNormalizado(fileName) {
        let extensao = UtilService.arquivoExtensao(fileName);
        let arquivoNome = UtilService.strNormalizeNFD(UtilService.arquivoNomeSemExtensao(fileName));
        return '' + arquivoNome + '.' + extensao;

    }

    public static arquivoExtensao(fileName) {
        if (fileName != null)
            return fileName.split('.').pop();
        return "";
    }

    public static arquivoNomeSemExtensao(fileName) {
        if (fileName != null)
            return fileName.split('.').slice(0, -1).join('.');
        return "";
    }


    verificaLimiteAdicionar(total, addQuantidade, limite) {
        return total + addQuantidade > limite;
    }

    dataURItoBlob(dataURI) {
        // code adapted from: http://stackoverflow.com/questions/33486352/cant-upload-image-to-aws-s3-from-ionic-camera
        const binary = atob(dataURI.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    }

    toDataUrl(url, callback) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    /**
     * Calcula a diferença das datas em dias, date1 - date2.
     * @param date1 : Date - primeira data.
     * @param date2 : Date - segunda data.
     */
    dateDiff(date1: Date, date2: Date): number {
        const diff = Math.abs(date1.getTime() - date2.getTime());
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    strToZonedDateTime(date: string, fromFormat: string, lang?: any) {
        if (date === undefined || date === null)
            return null;

        if (!lang)
            lang = this.languageHelper.getService().getDefaultLang();
        const data = moment(date, fromFormat, true);
        return data.format('YYYY-MM-DDTHH:mm:ss.[001][Z]');
    }

    zonedDateTimeFormat(date: string, format: string, lang?: any) {
        if (date === undefined || date === null)
            return null;
        if (!lang)
            lang = this.languageHelper.getService().getDefaultLang();
        const data = moment.parseZone(date);
        return data.format(format);
    }

    dateTimeFormat(date: string, fromFormat: string, toFormat: string, lang?: any) {
        if (date === undefined || date === null)
            return null;
        if (!lang)
            lang = this.languageHelper.getService().getDefaultLang();
        const data = moment(date, fromFormat, true);  // moment.parseZone(date);
        return data.format(toFormat);
    }

    nowZonedDateTime() {
        return moment().format('YYYY-MM-DDTHH:mm:ss.[001][Z]');
    }

    /**
     * Get a random floating point number between `min` and `max`.
     *
     * @param {number} min - min number
     * @param {number} max - max number
     * @return {number} a random floating point number
     */
    getRandomFloat(min, max) {
        return Math.random() * (max - min) + min;
    }

    /**
     * Get a random integer between `min` and `max`.
     *
     * @param {number} min - min number
     * @param {number} max - max number
     * @return {number} a random integer
     */
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    /**
     * Get a random boolean value.
     *
     * @return {boolean} a random true/false
     */
    getRandomBool() {
        return Math.random() >= 0.5;
    }


    /**
     * ARAYS
     */
    findObjectByKey(array, key, value) {
        if (array !== null)
            for (let i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return array[i];
                }
            }
        return null;
    }


    formataCodigoVenda(venda, codigo?: string) {
        let str: string = '';
        if (codigo && !venda) {
            str = codigo;
            // VENDA PRESENCIAL / COMISSARIO / BILHETERIA
            str = str.replace(/(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,2})/g, function (a, b, c, d, e) {
                let ret = "";
                if (b != "")
                    ret = b;
                if (c != "")
                    ret = ret + "." + c;
                if (d != "")
                    ret = ret + "." + d;
                if (e != "")
                    ret = ret + "-" + e;
                return ret;
            });
            // if (str != null && str.startsWith('V'))
            return str;


        }

        if (venda != null) {
            if (venda.sessionId != null) {
                str = venda.sessionId;
                if ((<string>venda.sessionId).startsWith('V')) {
                    //let str:string = venda.sessionId;
                    // VENDA PRESENCIAL / COMISSARIO / BILHETERIA
                    str = str.replace(/(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,2})/g, function (a, b, c, d, e) {
                        let ret = "";
                        if (b != "")
                            ret = b;
                        if (c != "")
                            ret = ret + "." + c;
                        if (d != "")
                            ret = ret + "." + d;
                        if (e != "")
                            ret = ret + "-" + e;
                        return ret;
                    });
                    venda.sessionIdFormatted = str;

                }
            }
        }

        return str;
    }

    // remove  . , - e qualquer caractere especial

    formataCodigoVendaOriginal(codigoVenda: string) {
        let codVenda: string = codigoVenda;
        return codVenda.split(/[a-z,A-Z,0-9]/gi).join();
    }

    formataUsuarioPapel(valor?: string) {

        if (valor !== null) {
            let r: string = valor.toUpperCase();
            if (r.indexOf('ROLE_USER') > -1) return ''; // desconsidera papel usuario (nao necessario)
            if (r.indexOf('ROLE_') > -1) {
                return (r.substr(r.indexOf('ROLE_') + 'ROLE_'.length, r.length)).replace(/\_/gi, '-');
            }
        }
        return valor;
    }

    static arrayDeAnos() {
        const anoAtual = new Date().getFullYear();
        let arrayAnos = []
        for (let i = 2000; i < anoAtual + 30; i++) {
            arrayAnos.push(i)
        }
        return arrayAnos;
    }

    formataValor(preco: number) {
        return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(preco).replace('R$', '');
    }
}