import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractComponenteRelacionamento} from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento";
import {FormGroup, NgForm} from "@angular/forms";
import {ProdutoProcessadoProprioIndFrigorifico} from "../../../../../../../../shared/models/produto-processado-proprio-ind-frigorifico.model";
import {ProdutoProcessadoProprioIndFrigorificoService} from "../../../../../../../../shared/services/produto-processado-proprio-ind-frigorifico.service";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {SubProdutoProcessadoProprioIndFrigorificoService} from "../../../../../../../../shared/services/sub-produto-processado-proprio-ind-frigorifico.service";
import {SubprodutoProcessadoProprioComponent} from "./subproduto-processado-proprio/subproduto-processado-proprio.component";

@Component({
    selector: 'app-produto-processado-proprio',
    templateUrl: './produto-processado-proprio.component.html',
    styleUrls: ['./produto-processado-proprio.component.scss']
})
export class ProdutoProcessadoProprioComponent extends AbstractComponenteRelacionamento<ProdutoProcessadoProprioIndFrigorifico, ProdutoProcessadoProprioIndFrigorificoService> implements OnInit {

    protected TIPO_OUTRO = 'Outros';

    @Input() form: FormGroup;
    @Input() serviceSubproduto: SubProdutoProcessadoProprioIndFrigorificoService;
    @Input() isNaoPodeEditar: boolean;
    @ViewChild('insideForm', {static: false}) insideForm: NgForm;
    @ViewChild('subproduto', {static: false}) subprodutoProcessadoProprioComponent: SubprodutoProcessadoProprioComponent;
    unidadesMedida: Array<DominioDto> = [];

    constructor(
        private dominiosService: DominiosService
    ) {
        super();
    }

    async ngOnInit() {
        super.ngOnInit();

        this.unidadesMedida = await this.dominiosService.getDominioByUrl('unidade-medida-prod-proc').toPromise();

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    getDisplayedColumns(): string[] {
        return ['produto', 'descricao', 'volume', 'acoes'];
    }

    isBotaoAdicionarDisabled() {
        return super.isBotaoAdicionarDisabled() || !this.novoDado.vinculo.volume || !this.novoDado.vinculo.idUnidadeMedida;
    }

    getUnidadeMedida(idUnidadeMedida: any) {
            let um = this.unidadesMedida.find(um => um.id === idUnidadeMedida);
        return um ? um.descricao : '';
    }
}
