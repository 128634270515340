export const locale = {
    lang: 'pt',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARDS': 'Dashboards',
            'CADASTROS': 'Cadastros',
            'SAMPLE': {
                'TITLE': 'Sample',
                'BADGE': '2222'
            }
        }
    }
};
