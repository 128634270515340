import {BaseModel} from 'app/main/shared/models/base.model';

export class TipoCorpoHidrico extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public outro?: boolean
    ) {
        super();
    }

    static fromJson(json: any): TipoCorpoHidrico {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoCorpoHidrico(
            json.id ? json.id : null,
            json.descricao ? json.descricao : null,
            json.dataInicio ? json.dataInicio : null,
            json.dataFim ? json.dataFim : null,
            json.outro
        );
    }
}
