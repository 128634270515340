import {Component, Input} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {PerfuracaoPoco} from '../../../models/perfuracao-poco.model';

@Component({
    selector: 'app-aba-objetivo-perfuracao-poco-tubular',
    templateUrl: './aba-objetivo-perfuracao-poco-tubular.component.html'
})
export class AbaObjetivoPerfuracaoPocoTubularComponent {

    @Input() ponto: Ponto;

    tiposPorosidade = PerfuracaoPoco.tiposPorosidade;
    tiposPoco = PerfuracaoPoco.tiposPoco;
    tiposCaracteristicasHidraulicas = PerfuracaoPoco.tiposCaracteristicasHidraulicas;
    tiposFontesAbastecimento = PerfuracaoPoco.tiposFontesAbastecimento;
    tiposBombas = PerfuracaoPoco.tiposBombas;

    constructor() {
    }

    tipoPoco(valor: any): string {
        return this.tiposPoco.find(p => p.valor === valor).descricao;
    }

    tipoPorosidade(valor: any): string {
        return this.tiposPorosidade.find(p => p.valor === valor).descricao;
    }

    tipoCaracteristicasHidraulicas(valor: any): string {
        return this.tiposCaracteristicasHidraulicas.find(p => p.valor === valor).descricao;
    }

    tipoFontesAbastecimento(valor: any): string {
        return this.tiposFontesAbastecimento.find(p => p.valor === valor).descricao;
    }

    tipoBombas(valor: any): string {
        return this.tiposBombas.find(p => p.valor === valor).descricao;
    }


}
