import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {EmpreendimentoEnergiaEolicaTecnologia} from 'app/main/shared/models/empreendimento-energia-eolica-tecnologia.model';
import {MatTableDataSource} from "@angular/material/table";
import {Dominio} from "../../../../../../../../shared/models/dominio-model";

@Component({
    selector: 'app-tecnologia-empreendimento-energetico',
    templateUrl: './tecnologia-empreendimento-energetico.component.html',
    styleUrls: ['./tecnologia-empreendimento-energetico.component.scss']
})
export class TecnologiaEmpreendimentoEnergeticoComponent implements OnInit {

    @Input() form: FormGroup;

    @Input() tiposTecnologiaSelecionados: Array<{ dominio: Dominio, vinculo: EmpreendimentoEnergiaEolicaTecnologia }>;
    @Input() tiposTecnologia: Array<Dominio> = [];
    @Input() tiposTecnologiaExcluidos: Array<number> = [];
    @Input() isNaoPodeEditar: boolean;

    tipo: Dominio;

    displayedColumns: string[] = ['descricao', 'acoes'];

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

    constructor() {
    }

    ngOnInit() {
        this.dataSource.data = this.tiposTecnologiaSelecionados;

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    excluirTipoTecnologiaEolica(index: number) {
        if (index > -1) {
            let termo = this.tiposTecnologiaSelecionados[index];
            if (termo && termo.vinculo.id !== undefined) {
                this.tiposTecnologiaExcluidos.push(termo.vinculo.id);
            }
            this.tiposTecnologiaSelecionados.splice(index, 1);
            this.dataSource.data = this.tiposTecnologiaSelecionados;
        }
    }

    incluiTipoTecnologiaEolica() {
        let vinculo = new EmpreendimentoEnergiaEolicaTecnologia();

        vinculo.tipoTecnologiaEolica = this.tipo.id;

        if (this.tipo && (this.tiposTecnologia.some(atv => atv.id === vinculo.tipoTecnologiaEolica))) {
            this.tiposTecnologiaSelecionados.push({
                dominio: this.tipo,
                vinculo: vinculo
            });
            this.tipo = undefined;
            this.dataSource.data = this.tiposTecnologiaSelecionados;

        }
    }
}
