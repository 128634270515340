import {BaseModel} from 'app/main/shared/models/base.model';

export class Cemiterio extends BaseModel {
    constructor(
        public id: number,
        public numeroSepultamentosMes: number,
        public numeroJazidosUnidade: number,
        public grauVulnerabilidadeAquifero: number,
        public distanciaSuperior: boolean,
        public horizontal: boolean,
        public vertical: boolean,
        public jusante: boolean,
        public montante: boolean,
        public permeabilidadeInferior10: boolean,
        public permeabilidadeSuperior10: boolean
    ) {
        super();
    }

    static fromJson(json: any): Cemiterio {
        if (json === undefined || json === null) {
            return null;
        }
        return new Cemiterio(
            json.id,
            json.numeroSepultamentosMes,
            json.numeroJazidosUnidade,
            json.grauVulnerabilidadeAquifero,
            json.distanciaSuperior,
            json.horizontal,
            json.vertical,
            json.jusante,
            json.montante,
            json.permeabilidadeInferior10,
            json.permeabilidadeSuperior10,
        );
    }

    static fromJsons(json: any): Cemiterio[] {
        const cemiterios: Cemiterio[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(cemiterio => cemiterios.push(this.fromJson(cemiterio)));
            return cemiterios;
        }
    }

}
