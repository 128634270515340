import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-dados-gerais',
    templateUrl: './dados-gerais.component.html',
    styleUrls: ['./dados-gerais.component.scss']
})
export class DadosGeraisComponent {

    @Input() form: FormGroup;

    @Input() isNaoPodeEditar: boolean;

}
