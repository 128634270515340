import {ResponsavelTecnico} from '../../../../../shared/models/responsavel-tecnico.model';

export class PocoTubularIrrigacaoModel {
    constructor(
        public id?: number,
        public idResponsavelTecnico?: number,
        public diametroPerfuracao?: number,
        public diametroRevestimento?: number,
        public vazao?: number,
        public profundidade?: number,
        public empresaResponsavel?: ResponsavelTecnico,
        public dataConclusao?: Date,
    ) {
    }

    static fromJson(json: any): PocoTubularIrrigacaoModel {
        if (json === undefined || json === null) {
            return null;
        }
        return new PocoTubularIrrigacaoModel(
            json.id ? json.id : null,
            json.idResponsavelTecnico ? json.idResponsavelTecnico : null,
            json.diametroPerfuracao ? json.diametroPerfuracao : null,
            json.diametroRevestimento ? json.diametroRevestimento : null,
            json.vazao ? json.vazao : null,
            json.profundidade ? json.profundidade : null,
            json.empresaResponsavel ? ResponsavelTecnico.fromJson(json.empresaResponsavel) : null,
            json.dataConclusao ? json.dataConclusao : null
        );
    }
}
