import {BaseModel} from 'app/main/shared/models/base.model';

export class EmpreendimentoEnergiaEolicaTecnologia extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoEnergiaEolica?: number,
        public tipoTecnologiaEolica?: number,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoEnergiaEolicaTecnologia {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoEnergiaEolicaTecnologia(
            json.id,
            json.empreendimentoEnergiaEolica,
            json.tipoTecnologiaEolica,
        );
    }

}
