export class MotivoJuntada {
    constructor(
        public id?: number,
        public descricao?: string,
        public idTermoReferencia?: number,
        public indConfigAviso?: string,
        public idConfigAvisos?: number,
        public dataInicio?: Date,
        public dataFim?: Date,
        public idTipoProcesso?: number,
        public indTipoObjetivo?: string,
        public idObjetivo?: number,
        public idAtividade?: number,
    ) {
    }

    static fromJson(json: any): MotivoJuntada {
        if (json === undefined || json === null) {
            return null;
        }
        return new MotivoJuntada(
            json.id,
            json.descricao,
            json.idTermoReferencia,
            json.indConfigAviso,
            json.idConfigAvisos,
            json.dataInicio,
            json.dataFim,
            json.idTipoProcesso,
            json.indTipoObjetivo,
            json.idObjetivo,
            json.idAtividade,

        );
    }
}
