import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
  selector: 'app-obra-pluvial-fluvial',
  templateUrl: './obra-pluvial-fluvial.component.html',
  styleUrls: ['./obra-pluvial-fluvial.component.scss']
})
export class ObraPluvialFluvialComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
    zonas: Array<DominioDto> = [];

    constructor(private dominiosService: DominiosService) {
    }

    async ngOnInit() {
        this.zonas = await this.dominiosService.getDominioByUrl('tipos-zona-obra-fluv-saneam').toPromise();

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    showDescricao() {
        return this.form.get('obraPluvialFluvial').get('outro').value;
    }
}
