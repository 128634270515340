import {BaseModel} from 'app/main/shared/models/base.model';

export class EeLinhaTransmissao extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): EeLinhaTransmissao {
        if (json === undefined || json === null) {
            return null;
        }
        return new EeLinhaTransmissao(
            json.id,
            json.requerimento
        );
    }

}
