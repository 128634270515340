import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {DominiosService} from '../../../../shared/services/dominios.service';
import {FertilizacaoService} from '../../../../shared/services/fertilizacao.service';
import {Fertilizacao} from '../../../../shared/models/fertilizacao.model';

@Component({
    selector: 'app-fertilizacao',
    templateUrl: './fertilizacao.component.html',
    styleUrls: ['./fertilizacao.component.scss']
})
export class FertilizacaoComponent implements OnInit {

    formFertilizacao: FormGroup;
    culturasDesenvolvidas: any;
    tipoSistema: any;

    // : TODO Esses Parametros devem chegar atraves do component Pai
    idGeometria = 10;

    constructor(private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private dominiosService: DominiosService,
                private fertilizacaoService: FertilizacaoService) {
    }

    ngOnInit() {
        this.fertilizacaoBuilder();
        this.buscaCulturasdesenvolvidas();
        this.buscaTipoSistema();
    }

    private fertilizacaoBuilder(): void {
        this.formFertilizacao = this.formBuilder.group({
            id: [null],
            idTipoSistema: [null, Validators.required],
            idTipoCulturasDesenv: [null, Validators.required],
            volumeEfluente: [null, Validators.required],
        });
    }

    buscaTipoSistema(): void {
        this.dominiosService.getDominio('TIPO_SISTEMA_FERTILIZACAO').subscribe(result => {
            this.tipoSistema = result;
        });
    }

    buscaCulturasdesenvolvidas(): void {
        this.dominiosService.getDominio('TIPO_CULTURAS_DESENVOLVIDA').subscribe(result => {
            this.culturasDesenvolvidas = result.filter(tipo => tipo.descricao !== 'Outras');
        });
    }

    salvar(): void {
        if (this.formFertilizacao.valid) {
            const fertilizacao = new Fertilizacao();
            fertilizacao.idGeometria = this.idGeometria;
            fertilizacao.idTipoSistema = this.formFertilizacao.value.idTipoSistema;
            fertilizacao.idTipoCulturasDesenv = this.formFertilizacao.value.idTipoCulturasDesenv;
            fertilizacao.volumeEfluente = Number(this.formFertilizacao.value.volumeEfluente);

            this.fertilizacaoService.createFertilizacao(fertilizacao).subscribe(() => {
                this.snackBar.showSuccess('Fertilização salvo com sucesso.');
                this.formFertilizacao.reset();
            });

        } else {
            this.formFertilizacao.markAllAsTouched();
            this.snackBar.showError('Campo(s) obrigatório(s) não' +
                ' preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }
}
