import {Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import Map from 'ol/Map';
import {OSM, XYZ} from "ol/source";
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import VectorSource from "ol/source/Vector";
import {Feature} from "ol";
import {LinearRing, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon} from 'ol/geom';
import {WKT} from "ol/format";
import {fromLonLat, get as ol_get, toLonLat} from 'ol/proj';
import {defaults as defaultControls, FullScreen, ScaleLine} from 'ol/control';
import LayerSwitcherImage from 'ol-ext/control/LayerSwitcherImage';
import Projection from "ol/proj/Projection";
import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import {Extent} from "ol/extent";
import domtoimage from 'dom-to-image-more';
import {Fill, Stroke, Style, Text} from "ol/style";
import {SnackBarService} from "../../../snack-bar/snack-bar.service";
import {Feicao} from "../../../models/geoadmin/feicao.model";
import {
    GeoportalBaseReferenciaService
} from "../../../services/geoportal-base-referencia/geoportal-base-referencia.service";
import {RegrasGeoRequerimentoRegra} from "../../../models/regras-geo-requerimento/regra-geo-requerimento-regra.model";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {CenarioRegrasGeo} from "../../../models/geoadmin/cenario-regras-geo";
import {getStyle} from "../../../util/geo";
import {toStringHDMS} from 'ol/coordinate';
import {FeicaoRequerimentoLicenciamentoDto} from "../../../models/feicao-requerimento-licenciamento-dto.model";
import {RegrasGeoRequerimentoFeicao} from "../../../models/regras-geo-requerimento/regra-geo-requerimento-feicao.model";
import {LoadingService} from "../../loading/loading.service";
import {FormUtil} from "../../../util/form-util";
import {logoSemaTemplateV1} from "../template-parecer-sema-geoportal/template-parecer-sema-imagens";
import {COMPASS} from "../../../util/geo-report-icones.util";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-template-parecer',
    templateUrl: './template-parecer.component.html',
    styleUrls: ['./template-parecer.component.scss']
})
export class TemplateParecerComponent {

    validacao = false;
    falha = false;
    aplicacoes: {
        geometria: FeicaoRequerimentoLicenciamentoDto,
        aplicacoes: CenarioRegrasGeo[],
        feicaoEntrada?: Feicao
    }[] = [];

    private readonly projection: Projection;
    private readonly extent: Extent = [-62.2981182998491860, -18.4908757503537053, -48.3634794314139285, -6.5772913742869203];

    @Input()
    regra: RegrasGeoRequerimentoRegra;

    @Input()
    feicaoesPrincipais: RegrasGeoRequerimentoFeicao[] = [];

    @Output()
    arquivoGerado = new EventEmitter<{ blob: Blob, nome: string }>();

    @ViewChild("map", {static: false})
    map: ElementRef;

    mapaGeo: Map;
    source: VectorSource;

    @Input("analises")
    set setAnalises(aplicacoes: {
        geometria: FeicaoRequerimentoLicenciamentoDto,
        aplicacoes: CenarioRegrasGeo[]
    }[]) {
        if(aplicacoes.length > 0 && this.feicaoesPrincipais.length > 0){
            // Filtrar as aplicações com as feições principais
            this.aplicacoes = aplicacoes.filter(aplicacao =>
                this.feicaoesPrincipais.some(feicaoPrincipal =>
                    feicaoPrincipal.idFeicaoEntrada === aplicacao.geometria.idFeicaoEntrada
                )
            );
            console.log(this.aplicacoes)
            if (this.mapaGeo) {
                this.mapaGeo.setTarget(undefined);
                this.mapaGeo = null;
                this.source = null;
            }
            if (this.map) {
                this.map.nativeElement.clearAll();
            }
            if (aplicacoes && aplicacoes.length > 0) {
                setTimeout(() => this.zone.runOutsideAngular(() => this.initMap()), 1000);
            }
        }

    }

    constructor(
        private readonly snackBarService: SnackBarService,
        private readonly zone: NgZone,
        private readonly geoportalBaseReferenciaService: GeoportalBaseReferenciaService,
        private readonly loadingService: LoadingService,
    ) {
        // Configurações da projeção utilizada.
        proj4.defs('EPSG:4674', '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
        proj4.defs('EPSG:100005', 'PROJCS["SIRGAS_2000_Lambert_Conformal_Conic_MT",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Standard_Parallel_1",-10.5],PARAMETER["Standard_Parallel_2",-15.5],PARAMETER["Latitude_Of_Origin",-13.0],UNIT["Meter",1.0]], AUTHORITY["EPSG","4674"]]');
        proj4.defs('EPSG:100006', 'PROJCS["South_America_Lambert_Conformal_Conic_MT",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Standard_Parallel_1",-10.5],PARAMETER["Standard_Parallel_2",-15.5],PARAMETER["Latitude_Of_Origin",-13.0],UNIT["Meter",1.0]]');
        register(proj4);

        const wordExtent: Extent = [-122.19, -59.87, -25.28, 32.72];
        ol_get('EPSG:4674').setExtent(this.extent);
        ol_get('EPSG:4674').setWorldExtent(wordExtent);

        this.projection = new Projection({
            code: 'EPSG:4674',
            units: 'm',
            extent: this.extent,
            worldExtent: wordExtent
        });
    }

    getEstilo(feicaoEntrada: Feicao, opacity: number = null) {
        let estilo = null;
        if (feicaoEntrada.geometriaPonto) {
            estilo = {
                corPreenchimento: opacity ? TemplateParecerComponent.convertHexToRGBA(feicaoEntrada.geometriaPonto.corPreenchimento, opacity) : feicaoEntrada.geometriaPonto.corPreenchimento,
                espessuraBorda: feicaoEntrada.geometriaPonto.espessuraBorda,
                cor: feicaoEntrada.geometriaPonto.corBorda,
                tipoTracejado: feicaoEntrada.geometriaPonto.tipoTracejado,
                icone: feicaoEntrada.geometriaPonto.icone,
            };
        } else if (feicaoEntrada.geometriaPoligono) {
            estilo = {
                corPreenchimento: opacity ? TemplateParecerComponent.convertHexToRGBA(feicaoEntrada.geometriaPoligono.corPreenchimento, opacity) : feicaoEntrada.geometriaPoligono.corPreenchimento,
                espessuraBorda: feicaoEntrada.geometriaPoligono.espessuraBorda,
                cor: feicaoEntrada.geometriaPoligono.corBorda,
                tipoTracejado: feicaoEntrada.geometriaPoligono.tipoTracejado
            };
        } else if (feicaoEntrada.geometriaLinha) {
            estilo = {
                corPreenchimento: 'white',
                espessuraBorda: feicaoEntrada.geometriaLinha.espessuraBorda,
                cor: feicaoEntrada.geometriaLinha.corLinha,
                tipoTracejado: feicaoEntrada.geometriaLinha.tipoTracejado
            };
        }
        return estilo
    }

    async initMap(): Promise<void> {
        const view = new View({
            center: fromLonLat([-55.97115771, -13.22944778], this.projection.getCode()),
            zoom: 1,
            minZoom: 2,
            maxZoom: 20,
            extent: this.extent,
            projection: this.projection
        });
        this.source = new VectorSource({wrapX: false});
        const vectorLayer = new VectorLayer({
            visible: true,
            title: 'Feições',
            source: this.source
        });

        vectorLayer.setZIndex(10000);

        for (const aplicacao of this.aplicacoes) {
            if (aplicacao.geometria && aplicacao.geometria.wkt) {
                const feature = await new WKT().readFeature(aplicacao.geometria.wkt, {
                    featureProjection: this.projection
                });

                aplicacao.feicaoEntrada = aplicacao.aplicacoes.map(x => x.regra.feicaoEntrada).find(x => x.id == aplicacao.geometria.idFeicaoEntrada);
                if (aplicacao.feicaoEntrada) {
                    aplicacao.feicaoEntrada.estilo = this.getEstilo(aplicacao.feicaoEntrada, 70);
                    if (aplicacao.feicaoEntrada.estilo) {
                        const styleOpenlayers = getStyle(aplicacao.feicaoEntrada.estilo);
                        feature.setStyle(styleOpenlayers);
                    }
                }

                this.source.addFeature(feature);
                // this.addLabels(feature, this.source, this.projection);
            }
        }

        this.mapaGeo = new Map({
            target: this.map.nativeElement,
            controls: defaultControls().extend([
                new FullScreen(),
                new LayerSwitcherImage(),
                new ScaleLine({
                    units: 'metric',
                    bar: true,
                    steps: 4,
                    text: true,
                    minWidth: 140,
                })
            ]),
            layers: [
                new TileLayer({
                    source: new XYZ({
                        crossOrigin: 'Anonymous',
                        attributions: 'Tiles &copy; Esri Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye,' +
                            ' Getmapping, Aerogrid, IGN, IGP, UPR-EGP',
                        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    }),
                    baseLayer: true,
                    title: 'Esri'
                }),
                new TileLayer({
                    visible: false,
                    baseLayer: true,
                    title: 'OpenStreetMap',
                    source: new OSM({
                        crossOrigin: 'Anonymous'
                    })
                }),
                vectorLayer
            ],
            view: view
        });
        const features = this.source.getFeatures();
        if (features.length > 0) {
            this.mapaGeo.getView().fit(this.source.getExtent(), {
                maxZoom: 16,
                padding: [20, 20, 20, 20]
            });
        } else {
            this.snackBarService.showError(`Falha ao carregar as geometrias no mapa da regra`)
        }
    }

    private addLabels(feature: Feature, source: VectorSource, projection: Projection): void {
        let geometry = feature.getGeometry();

        if (geometry instanceof Point) {
            this.addText(feature.getGeometry().getCoordinates(), source, projection);
        } else if (geometry instanceof LineString || geometry instanceof MultiPoint || geometry instanceof LinearRing) {
            let coordinates = feature.getGeometry().getCoordinates();
            for (let coord of coordinates) {
                this.addText(coord, source, projection);
            }
        } else if (geometry instanceof Polygon || geometry instanceof MultiLineString) {
            let coordinates = feature.getGeometry().getCoordinates();
            for (let coord of coordinates) {
                for (let c of coord) {
                    this.addText(c, source, projection);
                }
            }
        } else if (geometry instanceof MultiPolygon) {
            let coordinates = feature.getGeometry().getCoordinates();
            for (let coord of coordinates) {
                for (let c of coord) {
                    for (let a of c) {
                        this.addText(a, source, projection);
                    }
                }
            }
        }
    }

    private addText(coordinate: Coordinates, source: VectorSource, projection: Projection): void {
        let text = new Feature({
            geometry: new Point(coordinate)
        });

        const coord = toLonLat(coordinate, projection);
        text.setStyle(this.getLabelStyle(0, 30, toStringHDMS(coord, 4)));
        source.addFeature(text);
    }

    private getLabelStyle(offsetx: number, offsety: number, label: string): Style {
        return new Style({
            text: new Text({
                font: '12px Calibri,sans-serif',
                textAlign: 'center',
                fill: new Fill({
                    color: [0, 0, 0, 1],
                }),
                stroke: new Stroke({
                    color: '#fff', width: 2
                }),
                text: label,
                offsetY: offsety,
                offsetX: offsetx
            })
        });
    }

    private gerarImagemMapa(map: Map, callback: (resultado: string) => void) {
        const width = 1000;
        const height = 1000;
        const viewResolution = map.getView().getResolution();
        const targetElement = map.getTargetElement();

        const exportOptions = {
            filter: element => {
                const className = element.className || '';
                return (
                    className.indexOf('ol-control') === -1 ||
                    className.indexOf('ol-scale') > -1 ||
                    (className.indexOf('ol-attribution') > -1 && className.indexOf('ol-uncollapsible'))
                );
            },
            width: width,
            height: height
        };

        // Set print size
        const originalWidth = targetElement.style.width;
        const originalHeight = targetElement.style.height;
        targetElement.style.width = `${width}px`;
        targetElement.style.height = `${height}px`;

        // Adjust view to fit the extent
        const extent = this.source.getExtent();
        map.getView().fit(extent, { maxZoom: 16, padding: [20, 20, 20, 20] });

        // Render map and generate image
        map.once('rendercomplete', () => {
            domtoimage.toPng(map.getViewport(), exportOptions).then(dataUrl => {
                callback(dataUrl);

                // Restore original size and resolution
                targetElement.style.width = originalWidth;
                targetElement.style.height = originalHeight;
                map.updateSize();
                map.getView().setResolution(viewResolution);
            });
        });

        // Update map size to trigger rendering
        map.updateSize();
    }

    geraPDFRelatorio(): void {
        this.geraPDF(arquivo => arquivo.download("Parecer Técnico - MAPA.pdf"));
    }

    salvarPDFRelatorio(): void {
        this.geraPDF(arquivo => {
            arquivo.getBlob(blob => this.arquivoGerado.emit({blob, nome: "Parecer Técnico - MAPA.pdf"}));
        });
    }

    private static rgba2hex(orig: string): string {
        let rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
        if (rgb) {
            return '#' + (Number(rgb[1]) | 1 << 8).toString(16).slice(1) +
                (Number(rgb[2]) | 1 << 8).toString(16).slice(1) +
                (Number(rgb[3]) | 1 << 8).toString(16).slice(1);
        }

        return orig;
    }

    public static desenhaRetanguloObjeto(estilo: {
        corPreenchimento: string | null | undefined,
        espessuraBorda: number | null | undefined,
        cor: string | null | undefined,
        tipoTracejado: number
    }, width: number = 15, height: number = 15): string {
        const corPreenchimento = estilo.corPreenchimento || 'transparent';
        let style = `fill:${corPreenchimento}`;
        let extra = '';

        if (estilo.tipoTracejado === 1) {
            extra = "stroke-dasharray='1'";
        } else if (estilo.tipoTracejado === 2) {
            extra = "stroke-dasharray='2'";
        }

        const espessuraBorda = estilo.espessuraBorda || 0;

        if (espessuraBorda !== 0) {
            const cor = estilo.cor || 'transparent';
            style += `;stroke:${cor}`;
            style += `;stroke-width:${espessuraBorda}`;
        }
        return `<svg xmlns="http://www.w3.org/2000/svg"><rect width="${width}" height="${height}" x="0" y="0" style="${style}" ${extra}/></svg>`;
    }
    geraPDF(callback: (arquivo: any) => void) {
        this.loadingService.setValue(true);
        const content: any[] = [];
        let document = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            // [left, top, right, bottom]
            pageMargins: [10, 100, 10, 20],

            header: {
                margin: [24, 15, 24, 30],
                columns: [{
                    image: logoSemaTemplateV1,
                    width: 250
                }]
            },
            content: [],
            styles: {
                titleReport: {
                    fontSize: 8,
                    bold: true,
                    color: 'gray'
                },
                textFooter: {
                    fontSize: 9
                },
                msg: {
                    fontSize: 10,
                    color: 'red'
                },
                title: {
                    fontSize: 13,
                    bold: true
                },
                table: {
                    fontSize: 10,
                },
                value: {
                    fontSize: 10,
                },
                info: {
                    fontSize: 10,
                },
                regraTitulo: {
                    fontSize: 10,
                    bold: true
                },
                valueMsg: {
                    color: 'red',
                    fontSize: 9,
                },
                legendaCentroid: {
                    fontSize: 10,
                }
            }
        }
        let titleTable: any[] = [];

        if (this.aplicacoes.length > 0) {

            titleTable.push({
                text: "Fone: (65) 3613-7200\nRua C, esquina com a Rua F - Centro Político Administrativo CPA\nCEP: 78.050-970\n\nMATO GROSSO - ESTADO DE TRANSFORMAÇÃO\nWWW.MT.GOV.BR",
                style: 'titleReport',
                alignment: 'right',
                margin: [0, 10, 0, 0]
            });

            if (titleTable.length > 0) {
                // @ts-ignore
                document.header.columns.push(titleTable)
            }

            if (this.falha) {
                content.push({
                    text: 'Nenhum processo encontrado com este identificador',
                    style: 'msg',
                    alignment: 'center',
                    margin: [0, 100, 0, 0]
                });
            } else {
                this.gerarImagemMapa(this.mapaGeo, imagem => {
                    content.push({
                        image: imagem,
                        width: 550,
                        alignment: 'center',
                    });

                    content.push({
                        text: 'SRC: SIGARS 2000',
                        style: 'regraTitulo',
                        alignment: 'right',
                        margin: [0, 10, 20, 10]
                    });

                    let info = {
                        style: 'table',
                        margin: [20, 15, 20, 15],
                        table: {
                            alignment: 'center',
                            headerRows: 1,
                            widths: [15, 'auto', 'auto'],
                            body: [],
                        },
                        layout: {
                            hLineColor: (i, node) => {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'white';
                            },
                            vLineColor: (i, node) => {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'white';
                            }
                        }
                    };

                    info.table.body.push([
                        {
                            colSpan: 3,
                            text: 'Legenda',
                            alignment: 'center'
                        },
                        {
                            text: ''
                        },
                        {
                            text: ''
                        }
                    ])
                    let iconeFeicaoEntrada = {};

                    for (let aplicacao of this.aplicacoes) {
                        if (aplicacao.feicaoEntrada) {                        
                            if (aplicacao.feicaoEntrada.estilo && aplicacao.feicaoEntrada.estilo.icone) {
                                iconeFeicaoEntrada = {
                                    svg: aplicacao.feicaoEntrada.estilo.icone,
                                    alignment: 'center'
                                };
                            } else {
                                iconeFeicaoEntrada = {
                                    svg: TemplateParecerComponent.desenhaRetanguloObjeto(aplicacao.feicaoEntrada.estilo),
                                    width: 15,
                                    height: 15
                                };
                            }
                        }
                        info.table.body.push([
                            iconeFeicaoEntrada,
                            {
                                text: aplicacao.geometria.denominacao || (aplicacao.feicaoEntrada ? aplicacao.feicaoEntrada.descricao : 'FE'),
                                style: 'regraTitulo',
                                alignment: 'left',
                                margin: [0, 0, 0, 0]
                            },
                            {
                                text: TemplateParecerComponent.getLabelCentroid(aplicacao.geometria),
                                style: 'legendaCentroid',
                                alignment: 'left',
                                margin: [0, 0, 0, 0]
                            }
                        ]);
                    }
                    content.push({
                        columns: [
                            info,
                            {
                                image: COMPASS,
                                width: 100
                            }
                        ]
                    });
                    document.content.push(...content);
                    this.loadingService.setValue(false);
                    callback(pdfMake.createPdf(document));
                });
            }
        }
    }

    public static getLabelCentroid(geometria: FeicaoRequerimentoLicenciamentoDto): string {
        let label = '';

        const isValidCentroid = (latitude: number | null | undefined, longitude: number | null | undefined): boolean => {
            return latitude !== null && latitude !== undefined && longitude !== null && longitude !== undefined;
        };

        if (geometria.tipoGeometria === 'Ponto') {
            label = `Centroide: Latitude ${FormUtil.convertDEGToDMS(geometria.latitude, true)}, Longitude: ${FormUtil.convertDEGToDMS(geometria.longitude, false)}`;
        } else if (isValidCentroid(geometria.centroidLatidude, geometria.centroidLongitude) &&
            (geometria.tipoGeometria === 'Polígono' || geometria.tipoGeometria === 'Não identificada')) {
            label = `Centroide: Latitude ${FormUtil.convertDEGToDMS(geometria.centroidLatidude, true)}, Longitude: ${FormUtil.convertDEGToDMS(geometria.centroidLongitude, false)}`;
        }

        return label;
    }

    public static convertHexToRGBA(hex, opacity): string {
        if (hex.includes('rgba') || hex.includes('rgb')) return hex;

        const tempHex = hex.replace('#', '');
        let r, g, b = null;

        if (tempHex.length === 3) {
            r = parseInt(tempHex.substring(0, 1), 16)
            g = parseInt(tempHex.substring(1, 2), 16)
            b = parseInt(tempHex.substring(2, 3), 16)
        } else {
            r = parseInt(tempHex.substring(0, 2), 16)
            g = parseInt(tempHex.substring(2, 4), 16)
            b = parseInt(tempHex.substring(4, 6), 16)
        }
        return `rgba(${r},${g},${b},${opacity / 100})`;
    };
}