import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.scss']
})
export class HospitalComponent implements OnInit{

    @Input() form: FormGroup;
    @Input() requerimento: Requerimento;
    @Input() isNaoPodeEditar: boolean;

    ngOnInit(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

}
