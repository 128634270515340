export class RequerimentoTaskDTO {

    constructor(
        public requerimentoId?: number,
        public businesskey?: number,
        public numero?: string,
        public numeroProcesso?: string,
        public tipoProcesso?: string,
        public task?: string,
        public assignee?: string,
        public taskId?: string,
        public usuarioTask?: string,
        public taskDefinitionKey?: string
    ) {
    }

    static fromJson(json: any): RequerimentoTaskDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoTaskDTO(
            json.requerimentoId,
            json.businesskey,
            json.numero,
            json.numeroProcesso,
            json.tipoProcesso,
            json.task,
            json.assignee,
            json.taskId,
            json.usuarioTask,
            json.taskDefinitionKey
        );
    }

    static fromJsons(json: any): RequerimentoTaskDTO[] {
        const resources: RequerimentoTaskDTO[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: RequerimentoTaskDTO) => resources.push(this.fromJson(e)));
        }
        return resources;
    }

}