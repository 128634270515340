import {Injectable, Injector} from '@angular/core';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import { InformacaoAnm } from '../models/informacao-anm.model';
import {BaseMineracao} from "./base-mineracao.service";


@Injectable()
export class InformacaoAnmResolver extends DetailResolver<InformacaoAnm> {
    constructor(
        service: InformacaoAnmService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
    export class InformacaoAnmService extends BaseMineracao<InformacaoAnm> {

    constructor(protected injector: Injector) {
        super(
            "/api/informacao-anm",
            injector,
            InformacaoAnm.prototype,
            InformacaoAnm.fromJson
        );
    }

    getByMineracao(idMineracao: number): Observable<Array<InformacaoAnm>> {
        const url = `${this.urlResource}/mineracao/${idMineracao}`;

        return this.http
            .get(url)
            .pipe(
                map(e => this.jsonToResources(e)),
                catchError(this.handleError.bind(this))
            );
    }

    getDadosAnm(numeroProcesso: number, ano: number): Observable<Object> {
        const url = `https://geo.anm.gov.br/arcgis/rest/services/SIGMINE/dados_anm/MapServer/0/query?where=NUMERO=${numeroProcesso}%20and%20ANO=${ano}&outFields=*&f=geojson`;
        return this.http.get<Object>(url);
    }
}