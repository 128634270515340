import {BaseModel} from 'app/main/shared/models/base.model';

export class OficinaManutencao extends BaseModel {
    constructor(
        public id?: number,
        public mecanica?: boolean,
        public industrial?: boolean,
        public tornearia?: boolean,
        public numeroFuncionarios?: number,
        public qtdeSanitarios?: number,
        public numeroPopulacaoFixa?: number,
        public numeroPopulacaoFlutuante?: number
    ) {
        super();
    }

    static fromJson(json: any): OficinaManutencao {
        if (json === undefined || json === null) {
            return null;
        }
        return new OficinaManutencao(
            json.id,
            json.mecanica,
            json.industrial,
            json.tornearia,
            json.numeroFuncionarios,
            json.qtdeSanitarios,
            json.numeroPopulacaoFixa,
            json.numeroPopulacaoFlutuante
        );
    }

}
