import {Component, ContentChildren, ElementRef, HostBinding, Input, OnDestroy, Optional, QueryList, Self, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ErrorStateMatcher} from '@angular/material/core';
import {ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatFormFieldControl} from '@angular/material/form-field';
import {SelectItemComponent} from '../select-item/select-item.component';

@Component({
  selector: 'app-select-radio',
  templateUrl: './select-radio.component.html',
  styleUrls: ['./select-radio.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectRadioComponent
    }
  ]
})
export class SelectRadioComponent implements ControlValueAccessor, MatFormFieldControl<any>, OnDestroy {

  static nextId = 0;

  private onChange = (val) => {
  };
  private onTouched = () => {
  };

  private $value: any;
  private $placeholder: string;
  private $disabled = false;
  private $required = false;

  @Input() layout: 'vertical' | 'horizontal' | 'grid' = 'vertical';

  @ViewChild('input', {static: true}) input: ElementRef;

  stateChanges = new Subject<void>();

  @HostBinding() id = `select-radio-${SelectRadioComponent.nextId++}`;

  @HostBinding('attr.aria-describedby') describedBy = '';

  @ContentChildren(SelectItemComponent) opcoes: QueryList<SelectItemComponent>;


  constructor(
    private errorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private parentForm: NgForm,
    @Optional() private parentFormGroup: FormGroupDirective) {

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get value() {
    return this.$value;
  }

  set value(value: Array<string>) {
    this.$value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  get focused() {
    return false;
  }

  get empty() {
    return !this.value;
  }

  onContainerClick(event: MouseEvent) {
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return true;
  }

  @Input()
  get placeholder() {
    return this.$placeholder;
  }

  set placeholder(plh) {
    this.$placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this.$required;
  }

  set required(value) {
    this.$required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.$disabled;
  }

  set disabled(value: boolean) {
    this.$disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get errorState() {
    return this.errorStateMatcher.isErrorState(this.ngControl.control as FormControl, this.parentFormGroup || this.parentForm);
  }

  get controlType() {
    return 'select-chips';
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

}

