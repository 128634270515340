import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Requerimento} from '../../requerimentos/requerimento.model';
import {RequerimentoService} from '../../requerimentos/requerimento.service';
import {DocumentoProcessoService} from '../../gerar-documento-publicacao/documento-processo.service';
import {SnackBarService} from '../../../shared/snack-bar/snack-bar.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {DocumentoProcesso} from "../../../shared/models/documento-processo.model";
import {AnaliseConsemaAnexo} from "../../../shared/models/analise-consema-anexo.model";
import {AnaliseConsemaAnexoService} from "../../../shared/services/analise-consema-anexo.service";
import {DespachoService} from "../../tarefas/shared/service/despacho.service";
import {AnaliseConsema} from "../../../shared/models/analise-consema.model";
import {AnaliseConsemaService} from "../../../shared/services/analise-consema.service";
import {Despacho} from "../../../shared/components/despacho-cabecalho/despacho.model";
import {RedirectUtil} from "../../../shared/util/RedirectUtil";
import {take} from "rxjs/operators";
import {DocumentoProcessoSignatarioService} from "../../tarefas/shared/service/documento-processo-signatario.service";
import {PessoaCorporativoService} from "../../../shared/services/pessoa-corporativo.service";
import {EmissaoTituloSignatarioService} from "../../tarefas/shared/service/emissao-titulo-signatario.service";
import {EmissaoTituloService} from "../../tarefas/shared/service/emissao-titulo.service";

@Component({
    selector: 'app-referendar-parecer-tecnico-consema',
    templateUrl: './referendar-parecer-tecnico-consema.component.html',
    styleUrls: ['./referendar-parecer-tecnico-consema.component.scss'],
})
export class ReferendarParecerTecnicoConsemaComponent implements OnInit {

    @Input() requerimento = new Requerimento();
    @Input() idTarefa: string;
    formReferendarParecerTecnico: FormGroup;
    formAnexarDocumento: FormGroup;
    formDespacho: FormGroup;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @Output() enviarListaArquivos = new EventEmitter<any[]>();
    displayedColumns: string[] = ['documento', 'numero', 'analista', 'acao'];

    nomeArquivoSelecionado: string = '';
    dataSourceAnexarDocumento = new MatTableDataSource<any>([]);
    displayedColumnsAnexo: string[] = ['tituloDocumento', 'acao'];
    documentoTecnico: DocumentoProcesso[] = [];
    tableDocumentoTecnico: MatTableDataSource<DocumentoProcesso>;
    idAnexo: number = 0;
    anexosSalvos: any[] = [];
    parecerTecnicoConsema: AnaliseConsema;
    despacho: Despacho;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly requerimentoService: RequerimentoService,
        private readonly documentosProcessoService: DocumentoProcessoService,
        private readonly documentoProcessoSignatarioService: DocumentoProcessoSignatarioService,
        private readonly analiseConsemaService: AnaliseConsemaService,
        private readonly analiseConsemaAnexoService: AnaliseConsemaAnexoService,
        private readonly despachoService: DespachoService,
        private readonly snackBar: SnackBarService,
        private readonly pessoaCorporativoService: PessoaCorporativoService,
        private readonly emissaoTituloService: EmissaoTituloService,
        private readonly emissaoTituloSignatarioService: EmissaoTituloSignatarioService
    ) {
    }

    async ngOnInit(): Promise<void> {
        await this.buildResourceForm();
        this.carregarTabelaDocumentosTecnicos();
    }

    protected buildResourceForm(): void {
        this.formReferendarParecerTecnico = this.formBuilder.group({
            id: [null],
            decisao: [null, [Validators.required]],
        });
        this.formDespacho = this.formBuilder.group({
            tarefaId: [null, [Validators.required]],
            requerimento: [null, [Validators.required]],
            nomeTarefa: [null, [Validators.required]],
            textoDespacho: ['', [Validators.required, Validators.min(0), Validators.max(4000)]],
        });

        this.formAnexarDocumento = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            arquivo: ["", [Validators.required]],
            tituloDocumento: ["", [Validators.required]],
        })
    }

    async carregarTabelaDocumentosTecnicos(): Promise<void> {
        const titulos = await this.emissaoTituloService.buscaPorIdRequerimentoDocumentoProcesso(this.requerimento.id).toPromise();
        this.documentoTecnico = await this.documentosProcessoService.buscarTodosPorIdRequerimento(this.requerimento.id).toPromise();

        let d = [];

        for (let i = 0; i < this.documentoTecnico.length; i++) {
            const documento = this.documentoTecnico[i];
            let titulo = titulos.find(x => x.documentoProcesso.id === documento.id);
            let ret = {
                titulo: documento.titulo,
                numero: documento.numero,
                analista: '',
                chave: documento.chave
            };

            if (titulo) {
                const signatarios = await this.emissaoTituloSignatarioService.getSignatarioPorIdEmissao(titulo.id).toPromise();
                if (signatarios && signatarios.signatario) {
                    ret.analista = signatarios.signatario.nome;
                    if (signatarios.signatario.setores) {
                        ret.numero = '/' + signatarios.signatario.setores
                    }
                }
            }

            d.push(ret);
        }

        this.tableDocumentoTecnico = new MatTableDataSource(d);
        this.tableDocumentoTecnico.paginator = this.paginator;
        this.tableDocumentoTecnico.sort = this.sort;
    }

    uploadFile(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (fileList) {
            this.nomeArquivoSelecionado = fileList[0].name;
            this.formAnexarDocumento.controls.arquivo.patchValue(fileList[0])
        }
    }

    mapearArray(): any {
        if (this.anexosSalvos.length == 0) {
            return this.anexosSalvos;
        } else {
            return this.anexosSalvos.map(function (item) {
                if (item.id == null) {
                    return new AnaliseConsemaAnexo(
                        item.id,
                        item.arquivo,
                        item.tituloDocumento,
                    );
                }
            });
        }
    }

    anexarArquivo() {
        if (this.formAnexarDocumento.valid) {
            this.anexosSalvos.push({
                id: null,
                idTemp: this.idAnexo,
                arquivo: this.formAnexarDocumento.controls.arquivo.value,
                tituloDocumento: this.formAnexarDocumento.controls.tituloDocumento.value,
            })
            this.idAnexo++;
            this.dataSourceAnexarDocumento = new MatTableDataSource<any>(this.anexosSalvos);
            this.formAnexarDocumento.reset();
            this.nomeArquivoSelecionado = '';
        }
    }

    abrirPdf(idAnexo?: number, file?: any, tipoAnexo?: any) {
        if (tipoAnexo) {
            window.open(`${this.analiseConsemaAnexoService.urlResource}/visualizar-doc/${idAnexo}`);
        } else {
            window.open(URL.createObjectURL(new Blob([file], {type: 'application/pdf'})));
        }

    }

    abrirPdfDocumento(chave: string): void {
        this.documentosProcessoService.readPDFByChave(chave).pipe(
            take<Blob>(1)
        ).subscribe(arquivo => {
            window.open(URL.createObjectURL(new Blob([arquivo], {type: 'application/pdf'})));
        })
    }

    removerArquivo(item, itemId?: number, tipoAnexo?: string) {
        if (itemId != null) {
            if (tipoAnexo) {
                this.analiseConsemaAnexoService.delete(itemId).subscribe(() => {
                });
            }
        } else {
            let data;
            data = this.anexosSalvos.filter((value, key) => {
                return value.idTemp != item;
            });
            this.anexosSalvos = data;
            this.dataSourceAnexarDocumento = new MatTableDataSource(data);
        }
    }

    retornaAnaliseConsema() {
        const analiseConsema = new AnaliseConsema();
        analiseConsema.requerimento = this.requerimento;
        analiseConsema.despacho = this.retornaDespacho();
        analiseConsema.decisao = this.formReferendarParecerTecnico.get('decisao').value;

        return analiseConsema;
    }

    retornaDespacho() {
        const despacho = new Despacho();
        despacho.descricao = this.formDespacho.get('textoDespacho').value;
        return despacho;
    }

    concluirTarefaAnexoAnaliseConsema() {
        if (this.formReferendarParecerTecnico.valid) {
            this.analiseConsemaService.salvarPareceTecnicoConsema(this.retornaAnaliseConsema(), this.idTarefa).toPromise().then(result => {
                this.anexosSalvos.forEach(async a => {
                    a.parecerTecnicoConsema = result;

                    const anexoConsema = await this.mapearAnexoConsema(result, a.sigiloso);
                    const formData = new FormData();
                    formData.append('file', a.arquivo);
                    const consemaAnexoDTO = new Blob([JSON.stringify(anexoConsema)], {type: 'application/json'});
                    formData.append('consemaAnexoDTO', consemaAnexoDTO);

                    this.analiseConsemaAnexoService.salvarAnaliseConsemaAnexo(formData).subscribe(result => {
                        a.id = result.id;
                        this.snackBar.showSuccess('Parecer técnico salvo com sucesso');
                        this.formReferendarParecerTecnico.reset()
                        RedirectUtil.redirecionaDashboardCamunda();
                    });
                })
            });
        } else {
            this.snackBar.showAlert('Adicionar arquivo')
            this.formReferendarParecerTecnico.markAllAsTouched();
        }
    }

    cancelarTarefa(): void {
        RedirectUtil.redirecionaDashboardCamunda();
    }

    mapearAnexoConsema(analiseConsema: AnaliseConsema, sigiloso: boolean): any {
        return {analiseConsema: analiseConsema, sigiloso: sigiloso};
    }

}
