import {BaseModel} from 'app/main/shared/models/base.model';

export abstract class BaseRelacionamentoModel extends BaseModel {

    constructor(
        public id: number,
        public idDominio: number,
        public idEntidade: number,
        public descricao?: string,
        public idSubDominio?: number
    ) {
        super();
    }

}
