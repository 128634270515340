import {isNullOrUndefined} from "util";

export class ModelUtil {
    static getOrElseNull(prop: any) {
        return !isNullOrUndefined(prop) ? prop : null
    }

    static getOrElseEmptyArray(prop: any) {
        return !isNullOrUndefined(prop) ? prop : []
    }

    static getOrElseZero(prop: any) {
        return !isNullOrUndefined(prop) ? prop : 0
    }
}