import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Imovel} from './imovel.model';
import {catchError, map} from 'rxjs/operators';
import {Requerimento} from '../../../requerimento.model';
import {Observable, throwError} from 'rxjs';
import {RequerimentoArquivoSimcarDTO} from '../../../../../shared/models/requerimento-arquivo-simcar-dto.model';
import {HttpParams} from '@angular/common/http';
import {GeometriaDTO} from "../../../../../shared/models/geometria-dto.model";
import {TemplateImovelMapa} from "./template-imovel-mapa.model";

@Injectable({
    providedIn: 'root',
})
export class ImovelService extends BaseService<Imovel> {

    constructor(protected injector: Injector) {
        super(
            '/api/imoveis',
            injector,
            Imovel.prototype,
            Imovel.fromJson
        );
    }

    create(imovel: Imovel): Observable<Imovel> {
        return this.http
            .post(this.urlResource, imovel)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getByIptuAndCodigoIbgeMunicipio(iptu: string, numeroCadastroSigaConsulta: string, codigoIbge: number): Observable<Imovel[]> {
        const urlParams: HttpParams = new HttpParams()
            .set('iptu', iptu)
            .set('numeroCadastroSiga', numeroCadastroSigaConsulta)
            .set('codigoIbge', String(codigoIbge));
        return this.http.get(this.urlResource, {params: urlParams}).pipe(catchError(this.handleError.bind(this)));
    }

    getByRequerimento(requerimento: Requerimento): Observable<Imovel> {
        const url = `${this.urlResource}/requerimento/${requerimento.id}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(error => {
                    if (error.status !== 400) {
                        return throwError(error);
                    }
                })
            );
    }

    getByRequerimentoVinculadoNumeroERequerimentoId(
        numeroProtocoloVinculado: string, idRequerimento: number 
    ): Observable<Imovel[]> {
        const url = `${this.urlResource}/requerimento-vinculado`;
        return this.http
            .get(url, { 
                params: new HttpParams()
                    .append('numeroProtocoloVinculado',numeroProtocoloVinculado)
                    .append('idRequerimento',idRequerimento.toString())
            })
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }


    getByRequerimentoNotThrow(requerimento: Requerimento): Observable<Imovel> {
        const url = `${this.urlResource}/requerimento/${requerimento.id}`;
        return this.http
            .get(url)
            .pipe(catchError(this.handleError.bind(this)));
    }

    createToRequerimentoByNumeroCar(requerimento: Requerimento, numeroCar: string): Observable<Imovel> {
        return this.http.get(`${this.urlResource}/numero-car/${numeroCar}/requerimento/${requerimento.id}`)
            .pipe(map(this.jsonToResourceFn.bind(this)), catchError(this.handleError.bind(this)));
    }

    getArquivosSimcarByImovel(imovel: Imovel): Observable<RequerimentoArquivoSimcarDTO[]> {
        return this.http.get(`${this.urlResource}/${imovel.id}/arquivos-simcar`)
            .pipe(map(array => RequerimentoArquivoSimcarDTO.fromJsons(array)), catchError(this.handleError.bind(this)));
    }

    getArquivosSimcarByIdSimcar(idSimcar: number): Observable<RequerimentoArquivoSimcarDTO[]> {
        return this.http.get(`${this.urlResource}/arquivos-simcar/id-simcar/${idSimcar}`)
            .pipe(map(array => RequerimentoArquivoSimcarDTO.fromJsons(array)), catchError(this.handleError.bind(this)));
    }

    createFeicaoLocalizacao(requerimentoId: number, wkt: string): Observable<any> {
        const url = `${this.urlResource}/${requerimentoId}/localizacao`;
        return this.http.post(url, wkt).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    deleteFeicaoLocalizacao(requerimentoId: number, idGeometria: number): Observable<any> {
        const url = `${this.urlResource}/${requerimentoId}/localizacao/${idGeometria}`;
        return this.http.delete(url).pipe(catchError(this.handleError.bind(this)));
    }

    deletarDocumentoAbastecimentoPublico(requerimentoId: number): Observable<any> {
        const url = `${this.urlResource}/${requerimentoId}/deletar-documento-abastecimento-publico`;
        return this.http.delete(url).pipe(catchError(this.handleError.bind(this)));
    }

    getFeicaoLocalizacao(requerimentoId: number): Observable<GeometriaDTO[]> {
        const url = `${this.urlResource}/${requerimentoId}/localizacao`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    buscarImovelRuralSimcar(numeroCar: string): Observable<any> {
        const url = `${this.urlResource}/buscar-imovel-rural-simcar/${numeroCar}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    buscarTodosPorRequerimento(idRequerimento: number): Observable<Imovel[]> {
        const url = `${this.urlResource}/buscar-lista-imoveis/${idRequerimento}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    buscarTodosPorRequerimentoComGeometrias(idRequerimento: number): Observable<Imovel[]> {
        const url = `${this.urlResource}/buscar-lista-imoveis-com-wkt/${idRequerimento}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    adicionarImovelAoRequerimento(imovelRuraSimcarDTO, idRequerimento: number): Observable<Imovel> {
        const url = `${this.urlResource}/adicionar-imovel-rural/${idRequerimento}`;
        return this.http.post(url, imovelRuraSimcarDTO).pipe(catchError(this.handleError.bind(this)));
    }

    validarCoordendasImovelSugef(idRequerimento: number): Observable<boolean> {
        const url = `${this.urlResource}/validar-coordenadas-imovel/${idRequerimento}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }
    getTemplateMapa(idRequerimento: number): Observable<TemplateImovelMapa[]> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/template-mapa`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }
}
