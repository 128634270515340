import {BaseModel} from 'app/main/shared/models/base.model';
import {AnaliseRequerimentoAnalista} from '../analise-requerimento-analista.model';

export class AnalistaTarefasDTO extends BaseModel {
    constructor(
        public analistaDTO: AnaliseRequerimentoAnalista = null,
        public percentualConcluido?: number,
        public quantidadeTarefas?: number,
    ) {
        super();
    }

    static fromJson(json: any): AnalistaTarefasDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnalistaTarefasDTO(
            json.analistaDTO,
            json.percentualConcluido,
            json.quantidadeTarefas
        );
    }

    static fromJsons(json: any): AnalistaTarefasDTO[] {
        const resources: AnalistaTarefasDTO[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: AnalistaTarefasDTO) => resources.push(AnalistaTarefasDTO.fromJson(e)));
        }
        return resources;
    }
}
