import {Component, OnInit} from '@angular/core';
import {BasePontoComponent} from '../base-ponto.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {AguaSubterraneaIrrigacao} from './model/agua-subterranea-irrigacao.model';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {isNullOrUndefined} from "util";
import {FormUtil} from "../../../../../shared/util/form-util";
import {DocumentoRequerimento} from "../../../../documentos-requerimentos/documento-requerimento.model";
import {DocumentoRequerimentoService} from "../../../../documentos-requerimentos/documento-requerimento.service";
import {ArquivoService} from "../../../../../shared/services/arquivo.service";
import {PdfViewerDialog} from "../../../../../shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model";
import {CommonsUtil} from "../../../../../shared/util/commons-util";
import {DatePipe} from "@angular/common";

function nrAutorizacaoPerfuracaoValidator(control: FormControl): any {

    if (parseInt(control.value, 10) <= 0) {
        return {
            nrAutorizacaoPerfuracaoValidator: true
        };
    }
    return null;
}
@Component({
    selector: 'app-objetivo-agua-subterranea-irrigacao',
    templateUrl: './objetivo-agua-subterranea-irrigacao.component.html',
})
export class ObjetivoAguaSubterraneaIrrigacaoComponent extends BasePontoComponent implements OnInit {

    form: FormGroup;
    pocosLength: number;
    lancamentosLength: number;
    condicaoFormularioConcluido = (ponto?: Ponto): boolean => this.pocosLength > 0 && this.lancamentosLength > 0 &&
        !isNullOrUndefined((ponto ? ponto : this.ponto).aguaSubterraneaIrrigacao)
    requerirDeclaracao: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private pontoService: PontoService,
        private documentoRequerimentoService: DocumentoRequerimentoService,
        private snackBarService: SnackBarService,
        private arquivoService: ArquivoService,
        private pdfViewerDialog: PdfViewerDialog,
        private datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit(): void {
        this.construirForm();
        this.patchForms();
    }

    private patchForms(): void {
        if (this.ponto.aguaSubterraneaIrrigacao) {
            this.form.patchValue(this.ponto.aguaSubterraneaIrrigacao);
            if (this.ponto.aguaSubterraneaIrrigacao.nrAutorizacaoPerfuracao) {
                this.ponto.aguaSubterraneaIrrigacao.nrAutorizacaoPerfuracao = this.ponto.aguaSubterraneaIrrigacao.nrAutorizacaoPerfuracao.toString().padStart(10, '0');
            }
        }
        this.form.patchValue({
            horaTesteInicio: this.datePipe.transform(this.ponto.aguaSubterraneaIrrigacao.horaTesteInicio, 'dd/MM/yyyy HH:mm:ss'),
            horaTesteFim: this.datePipe.transform(this.ponto.aguaSubterraneaIrrigacao.horaTesteFim, 'dd/MM/yyyy HH:mm:ss')
        });
    }

    private construirForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            diametroPerfuracao: [null, [Validators.required, Validators.min(0.00001)]],
            diametroRevestimento: [null, [Validators.required, Validators.min(0.00001)]],
            vazao: [null, [Validators.required, Validators.min(0.000001)]],
            profundidade: [null, [Validators.required, Validators.min(0.00001)]],
            possuiPerfuracaoPoco: [null, [Validators.required]],
            nrAutorizacaoPerfuracao: [null, [Validators.required, nrAutorizacaoPerfuracaoValidator]],
            dataPerfuracao: [null, [Validators.required]],
            declaracaoPerfuracao: [null],
            alturaBocaPoco: [null, [Validators.required, Validators.min(0.00001)]],
            metodoMedicaoVazao: [null, [Validators.maxLength(255)]],
            horaTesteInicio: [null, [Validators.required]],
            horaTesteFim: [null, [Validators.required]],
            cotaTopografica: [null, [Validators.required, Validators.min(0.00001)]],
            empresaExecutoraTeste: [null, [Validators.maxLength(255)]],
            vazaoTeste: [null, [Validators.required, Validators.min(0.000001)]],
            nivelEstBombeamento: [null, [Validators.required, Validators.min(0.00001)]],
            nivelDinBombeamento: [null, [Validators.required, Validators.min(0.00001)]],
            vazaoEspecifica: [null, [Validators.required, Validators.min(0.000001)]],
            testeBombeamento: [null, Validators.required],
            testeRecuperacao: [null, Validators.required],
        });


        this.form.get('possuiPerfuracaoPoco').valueChanges
            .subscribe(value => {
                const ctrlNrAutorizacao = this.form.get('nrAutorizacaoPerfuracao');
                const ctrlAnexoAutorizacao = this.form.get('declaracaoPerfuracao');
                if (value) {
                    ctrlNrAutorizacao.setValidators([Validators.required, nrAutorizacaoPerfuracaoValidator]);
                    ctrlNrAutorizacao.updateValueAndValidity();

                    FormUtil.clearControl(ctrlAnexoAutorizacao);
                    this.requerirDeclaracao = false;
                } else {
                    FormUtil.clearControl(ctrlNrAutorizacao);

                    ctrlAnexoAutorizacao.setValidators(Validators.required);
                    ctrlAnexoAutorizacao.updateValueAndValidity();
                    this.requerirDeclaracao = true;
                }
            });

        this.form.get('testeBombeamento').valueChanges.subscribe(file => {
            if (file instanceof File) {
                const docRequerimento: DocumentoRequerimento = new DocumentoRequerimento();
                docRequerimento.titulo = 'Teste de bombeamento.'
                docRequerimento.requerimento = this.ponto.requerimentoOutorga.requerimento;
                docRequerimento.tipo = 'TESTE_BOMBEAMENTO';
                this.documentoRequerimentoService.createMultipart(docRequerimento, file, true).subscribe(result =>
                    this.form.get('testeBombeamento').setValue(result), e => {
                    this.snackBarService.showError("Erro ao fazer upload do arquivo", e);
                    this.form.get('testeBombeamento').reset();
                });
            }
        })

        this.form.get('testeRecuperacao').valueChanges.subscribe(file => {
            if (file instanceof File) {
                const docRequerimento: DocumentoRequerimento = new DocumentoRequerimento();
                docRequerimento.titulo = 'Teste de recuperação.'
                docRequerimento.requerimento = this.ponto.requerimentoOutorga.requerimento;
                docRequerimento.tipo = 'TESTE_RECUPERACAO';
                this.documentoRequerimentoService.createMultipart(docRequerimento, file, true).subscribe(result =>
                    this.form.get('testeRecuperacao').setValue(result), e => {
                    this.snackBarService.showError("Erro ao fazer upload do arquivo", e);
                    this.form.get('testeRecuperacao').reset();
                });
            }
        })

        this.form.get('declaracaoPerfuracao').valueChanges.subscribe(file => {
            if (file instanceof File) {
                const docRequerimento: DocumentoRequerimento = new DocumentoRequerimento();
                docRequerimento.titulo = 'Declaração da data de perfuração do poço autorizado.'
                docRequerimento.requerimento = this.ponto.requerimentoOutorga.requerimento;
                docRequerimento.tipo = 'DECLARACAO_PERFURACAO_POCO';
                this.documentoRequerimentoService.createMultipart(docRequerimento, file, true).subscribe(result =>
                    this.form.get('declaracaoPerfuracao').setValue(result));
            }
        })
    }


    salvarPonto(): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            const ponto = Ponto.fromJson(this.ponto);

            ponto.aguaSubterraneaIrrigacao = AguaSubterraneaIrrigacao.fromJson(this.form.value);
            ponto.aguaSubterraneaIrrigacao.horaTesteInicio = CommonsUtil.stringToDate(this.form.get('horaTesteInicio').value)
            ponto.aguaSubterraneaIrrigacao.horaTesteFim = CommonsUtil.stringToDate(this.form.get('horaTesteFim').value)
            ponto.formularioConcluido = this.condicaoFormularioConcluido(ponto);
            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). ' +
                'Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    onChangePocos(length: number) {
        this.pocosLength = length

        if (!isNullOrUndefined(this.lancamentosLength) && this.ponto.formularioConcluido != this.condicaoFormularioConcluido()) {
            this.ponto.formularioConcluido = this.condicaoFormularioConcluido()
            this.pontoService.update(this.ponto).subscribe(result => Object.assign(this.ponto, result))
        }
    }

    onChangeLancamentos(length: number) {
        this.lancamentosLength = length

        if (!isNullOrUndefined(this.pocosLength) && this.ponto.formularioConcluido != this.condicaoFormularioConcluido()) {
            this.ponto.formularioConcluido = this.condicaoFormularioConcluido()
            this.pontoService.update(this.ponto).subscribe(result => Object.assign(this.ponto, result))
        }
    }

    visualizarArquivo(docRequerimento: DocumentoRequerimento) {
        if (docRequerimento.nomeArquivo.endsWith('.pdf')) {
            this.arquivoService.download(docRequerimento.chave).subscribe(result =>
                this.pdfViewerDialog.show(result));
        } else {
            this.arquivoService.download(docRequerimento.chave, 'application/ms-excel').subscribe(result =>
                window.open(window.URL.createObjectURL(result)));
        }
    }
}
