import {Injectable, Injector} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {AtividadeSubAtividade} from './atividade-subatividade.dto';
import {BaseArrecadacaoService} from '../../../../shared/services/base-arrecadacao.service';
import {RequerimentoLicenciamento} from '../requerimento-licenciamento.model';


@Injectable({
    providedIn: 'root'
})
export class AtividadeSubatividadeService extends BaseArrecadacaoService<AtividadeSubAtividade> {

    constructor(protected injector: Injector) {
        super('/api/atividade', injector, AtividadeSubAtividade.prototype, AtividadeSubAtividade.fromJson);
    }

    getListaAtividade(requerimento: RequerimentoLicenciamento[]): Observable<any> {

        const requisicao: Array<any> = [];

        requerimento.forEach(rec => {
            const url = `${this.urlResourceArrecadacao}/atividade-subatividade/${rec.idAtividade}/${rec.idGrupoAtividade} `;

            requisicao.push(this.http.get(url).pipe(
                    map(AtividadeSubAtividade.fromJson.bind(this)),
                    catchError(this.handleError.bind(this))
                ));
        });

        return forkJoin(requisicao);
    }

    getListaAtividadeCalculoTaxa(requerimento: RequerimentoLicenciamento): Observable<AtividadeSubAtividade> {
        const url = `${this.urlResourceArrecadacao}/atividade-subatividade/${requerimento.idAtividade}/${requerimento.idGrupoAtividade} `;
        return this.http.get(url).pipe(
            map(AtividadeSubAtividade.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

}
