import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {LancamentoTaxaParamCalculoDto} from "../models/lancamento-taxa-param-calculo.dto";

@Injectable({
    providedIn: 'root'
})
export class LancTaxaParamCalculoService extends BaseService<LancamentoTaxaParamCalculoDto> {

    constructor(protected injector: Injector) {
        super(
            '/api/lanc-taxa-param-calc',
            injector,
            LancamentoTaxaParamCalculoDto.prototype,
            LancamentoTaxaParamCalculoDto.fromJson
        );
    }
}
