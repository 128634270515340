import {BaseModel} from 'app/main/shared/models/base.model';
import {RequerimentoLicenciamento} from "../../../requerimento-licenciamento/requerimento-licenciamento.model";

export class ReqLicenciamentoDinamicaDesmate extends BaseModel {
    constructor(
        public id?: number,
        public requerimentoLicenciamento?: RequerimentoLicenciamento,
        public ano?: number,
        public idCategoriaDesmate?: number,
        public subcategoria?: number,
        public idFeicao?: number,
    ) {
        super();
    }

    static fromJson(json: any): ReqLicenciamentoDinamicaDesmate {
        if (json === undefined || json === null) {
            return null;
        }
        return new ReqLicenciamentoDinamicaDesmate(
            json.id,
            RequerimentoLicenciamento.fromJson(json.requerimentoLicenciamento),
            json.ano,
            json.idCategoriaDesmate,
            json.subcategoria,
            json.idFeicao,
        );
    }
}
