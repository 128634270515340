import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'error-500',
    templateUrl: './500/error-500.component.html',
    styleUrls: ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error500Component {
    /**
     * Constructor
     */
    constructor() {
    }
}
