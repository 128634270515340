import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../snack-bar/snack-bar.service";
import {SolicitacaoAnexoService} from "../services/manifestacao/solicitacao-anexo.service";
import {AnexoArquivoService} from "../services/manifestacao/anexo-arquivo.service";
import {AnexoArquivo} from "../models/manifestacao/anexo-arquivo.model";

@Component({
    selector: 'app-anexar-arquivos',
    templateUrl: './anexar-arquivos.component.html',
    styleUrls: ['./anexar-arquivos.component.scss']
})
export class AnexarArquivosComponent implements OnInit {

    @Input() idSolicitacao?: number;
    @Input() tipoAnexo: any;
    @Output() enviarListaArquivos = new EventEmitter<any[]>();
    formAnexarArquivo: FormGroup;
    anexosSalvos: any[] = [];
    idAnexo: number = 0;
    dataSourceAnexo = new MatTableDataSource<any>([]);
    displayedColumns: string[] = ['titulo-do-documento', 'sigiloso', 'acao'];
    nomeArquivoSelecionado: string = '';

    constructor(
        private formBuilder: FormBuilder,
        private solicitacaoAnexoService: SolicitacaoAnexoService,
        private snackBarService: SnackBarService,
        private respostaAnexoService: AnexoArquivoService,
    ) {
    }

    async ngOnInit() {
        this.buildFormAnexarArquivo();

        if (this.idSolicitacao) {
            await this.solicitacaoAnexoService.getSolicitcaoAnexoPorIdSolicitacao(this.idSolicitacao).toPromise().then(arquivos => {
                arquivos.forEach(arquivo => {
                    this.anexosSalvos.push({
                        id: arquivo.id,
                        idTemp: this.idAnexo,
                        arquivo: arquivo.chave,
                        tituloArquivo: arquivo.titulo,
                        sigiloso: arquivo.indSigiloso == 1,
                    });
                    this.idAnexo++;
                });
                this.dataSourceAnexo = new MatTableDataSource<any>(this.anexosSalvos);
            });
        }
    }

    private buildFormAnexarArquivo() {
        this.formAnexarArquivo = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            arquivo: ["", [Validators.required]],
            tituloArquivo: ["", [Validators.required]],
            sigiloso: [false],
        })
    }

    setValorCheckBox(event): void {
        this.formAnexarArquivo.controls.sigiloso.patchValue(event);
    }

    anexarArquivo() {
        if (this.formAnexarArquivo.valid) {
            this.anexosSalvos.push({
                id: null,
                idTemp: this.idAnexo,
                arquivo: this.formAnexarArquivo.controls.arquivo.value,
                tituloArquivo: this.formAnexarArquivo.controls.tituloArquivo.value,
                sigiloso: this.formAnexarArquivo.controls.sigiloso.value
            })
            this.idAnexo++;
            this.dataSourceAnexo = new MatTableDataSource<any>(this.anexosSalvos);
            this.emitArquivos(this.mapearArray());
            this.formAnexarArquivo.reset();
            this.nomeArquivoSelecionado = '';
            this.setValorCheckBox(false);
        }
    }

    abrirPdf(idAnexo?: number, file?: any, tipoAnexo?: any) {
        if (idAnexo) {
            if (tipoAnexo == 'SOLICITACAO') {
                window.open(`${this.solicitacaoAnexoService.urlResource}/visualizar-doc/${idAnexo}`);
            } else if (tipoAnexo == 'RESPOSTA') {
                window.open(`${this.respostaAnexoService.urlResource}/visualizar-doc/${idAnexo}`);
            }
        } else {
            window.open(URL.createObjectURL(new Blob([file], {type: 'application/pdf'})));
        }
    }

    removerArquivo(item, itemId?: number, tipoAnexo?: string) {
        if (itemId != null) {
            if (tipoAnexo == 'SOLICITACAO') {
                this.solicitacaoAnexoService.delete(itemId).subscribe(() => {
                    this.snackBarService.showSuccess("Anexo removido com sucesso.")
                });
            } else if (tipoAnexo == 'RESPOSTA') {
                this.respostaAnexoService.delete(itemId).subscribe(() => {
                    this.snackBarService.showSuccess("Anexo removido com sucesso.")
                });
            }
        } else {
            let data;
            data = this.anexosSalvos.filter(value => {
                return value.idTemp != item;
            });
            this.anexosSalvos = data;
            this.dataSourceAnexo = new MatTableDataSource(data);
            this.emitArquivos(this.mapearArray())
        }
    }

    mapearArray(): any {
        if (this.anexosSalvos.length == 0) {
            return this.anexosSalvos;
        } else {
            return this.anexosSalvos.map(function (item) {
                if (item.id == null) {
                    return new AnexoArquivo(
                        item.id,
                        item.arquivo,
                        item.tituloArquivo,
                        item.sigiloso == true ? 1 : 0,
                    );
                }
            });
        }
    }

    emitArquivos(arquivos) {
        this.enviarListaArquivos.emit(arquivos);
    }

    uploadFile(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (fileList) {
            this.nomeArquivoSelecionado = fileList[0].name;
            this.formAnexarArquivo.controls.arquivo.patchValue(fileList[0])
        }
    }
}