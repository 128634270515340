import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from '../../pages/requerimentos/requerimento.model';
import {BaseEnum} from '../enums/base.enum';
import {Pessoa} from "./pessoa.model";

export class DocumentoProcesso extends BaseModel {
    constructor(
        public id?: number,
        public titulo?: string,
        public nomeArquivo?: string,
        public chave?: string,
        public tipoEmissaoPublicacao?: string,
        public requerimento?: Requerimento,
        public sigiloso?: boolean,
        public grupoDocumento?: string,
        public dataCriacao?: Date,
        public dataFim?: Date,
        public numero?: string,
        public documentoAssinado?: boolean,
        public codigoVerificador?: string,
        public codigoCrc32?: string,
    ) {
        super();
    }

    static gruposDocumento: BaseEnum[] = [
        {valor: 'COPIA_PROCESSO', descricao: 'Cópia processo'},
        {valor: 'TERMO_REFERENCIA', descricao: 'Termo de Referencia'},
        {valor: 'RESPONSAVEL_IMOVEL', descricao: 'Responsável do imóvel'},
        {valor: 'DOENCA_REPRESENTANTE', descricao: 'Doença representante'},
        {valor: 'RESPONSAVEL_TECNICO', descricao: 'Responsável Técnico'},
        {valor: 'REPRESENTANTE_LEGAL', descricao: 'Representante Legal'},
        {valor: 'EMISSAO_TITULO_PORTARIA', descricao: 'Emissão Portaria Outorga'},
        {valor: 'EMISSAO_TITULO_AUTORIZACAO', descricao: 'Emissão Autorização'},
        {valor: 'EMISSAO_TITULO_CADASTRO', descricao: 'Emissão Cadastro de Certificado'},
        {valor: 'EMISSAO_TITULO_ATO', descricao: 'COPIA_PROCESSO'},
        {valor: 'TERMO_RESPONSABILIDADE', descricao: 'Termo de responsabilidade'},
        {valor: 'COMPLEMENTACAO', descricao: 'Complementação'},
        {valor: 'GESTAO_MANIFESTACAO', descricao: 'Solicitar Manifestação'},
        {valor: 'PARECER_TECNICO', descricao: 'Parecer Técnico'},
        {valor: 'DOCUMENTO_PUBLICACAO', descricao: 'Documento publicação'},
        {valor: 'DOCUMENTO_REQUERIMENTO_GERADO', descricao: 'DOCUMENTO_REQUERIMENTO_GERADO'},
        {valor: 'DOCUMENTO_REQUERIMENTO_PUBLICADO', descricao: 'DOCUMENTO_REQUERIMENTO_PUBLICADO'},
        {valor: 'EMISSAO_LICENCA_AMBIENTAL', descricao: 'Emissão Licença Ambiental'},
        {valor: 'TERMO_DE_ADESAO_E_COMPROMISSO', descricao: 'Termo de Adesão e Compromisso'},
        {valor: 'DOCUMENTO_ATOS_INTERNOS', descricao: 'Documento de Atos Internos'},
        {valor: 'DOCUMENTO_DE_VISTORIA', descricao: 'Documento de Vistoria'},
        {valor: 'TERMO_REFERENCIA_ESPECIFICO', descricao: 'Termo de referência específico'},
        {valor: 'TERMO_CONCORDANCIA_REQUERIMENTO', descricao: 'Termo de Concordância do Requerimento'},
        {valor: 'OFICIO_DE_PENDENCIA', descricao: 'Ofício de pendência'},
        {valor: 'OFICIO_DE_PRORROGACAO_PRAZO_COMPLEMENTACAO', descricao: 'Ofício de prorrogacao de prazo para a complementação'},
        {valor: 'DOCUMENTO_PRORROGACAO_PRAZO_ANEXO', descricao: 'Documento anexo á prorrogação de prazo'},
        {valor: 'CERTIDAO_NAO_MANIFESTACAO', descricao: 'Certidão de não manifestação'},
        {valor: 'CERTIDAO_NAO_ATENDIMENTO', descricao: 'Certidão de não manifestação'},
        {valor: 'JUNTADA_DE_PROCESSO', descricao: 'Juntada de Processo'},
        {valor: 'COMPROVANTE_DE_PROTOCOLO', descricao: 'Comprovante de Protocolo'},
        {valor: 'SOLICITACAO_REVISAO_REQUERIMENTO', descricao: 'Solicitação para revisão do requerimento'},
        {valor: 'SOLICITACAO_ARQUIVAMENTO_PROCESSO', descricao: 'Solicitação para arquivamento do processo'},
        {valor: 'DOCUMENTO_INVENTARIO_IMPORTADO', descricao: 'Documento utilizado para importação de um inventário'},
        {valor: 'DOCUMENTO_REUNIOES_PUBLICAS', descricao: 'Documento anexado Audiencias e Reunioes'},
        {valor: 'LICENCA_POR_ADESAO_E_COMPROMISSO', descricao: 'Licença por adesão e compromisso LAC'},
        {valor: 'TITULO_EXISTENTE_PROCESSO_FISICO', descricao: 'Referência título existente - Processo Físico'},
        {valor: 'DOCUMENTO_PARECER_TEMPLATE_GEO', descricao: 'Documento com o mapa e os CARs com o parecer Geo da análise'}
    ];

    static fromJson(json: any): DocumentoProcesso {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoProcesso(
            json.id,
            json.titulo,
            json.nomeArquivo,
            json.chave,
            json.tipoEmissaoPublicacao,
            Requerimento.fromJson(json.requerimento),
            json.sigiloso,
            json.grupoDocumento,
            new Date(json.dataCriacao),
            new Date (json.dataFim),
            json.numero,
            json.documentoAssinado,
            json.codigoVerificador,
            json.codigoCrc32,
        );
    }

    static fromJsons(json: any): DocumentoProcesso[] {
        const documentoProcesso: DocumentoProcesso[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(documentosProcesso => {
                documentoProcesso.push(DocumentoProcesso.fromJson(documentosProcesso));
            });
            return documentoProcesso;
        }
    }
}
