import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'diaSemana'
})
export class DiaSemanaPipe implements PipeTransform {

    transform(date: Date, complete?: boolean): any {
        return this.getDiaSemana(date, complete);
    }

    private getDiaSemana(data, complete): any {

        if (data !== undefined && data != null && data.getDay) {
            const d = data.getDay();
            switch (d) {
                case  0:
                    return 'Domingo';
                case  1:
                    return complete ? 'Segunda-Feira' : 'Segunda';
                case  2:
                    return complete ? 'Terça-Feira' : 'Terça';
                case  3:
                    return complete ? 'Quarta-Feira' : 'Quarta';
                case  4:
                    return complete ? 'Quinta-Feira' : 'Quinta';
                case  5:
                    return complete ? 'Sexta-Feira' : 'Sexta';
                case  6:
                    return complete ? 'Sábado' : 'Sábado';
                default:
                    return '';
            }

        }

    }

}
