import {environment} from 'environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {IrrigacaoCultura} from "../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-irrigacao/irrigacao-cultura.model";
import {RequerimentoOutorgaFinalidade} from "../models/requerimento-outorga-finalidade.model";
import {IrrigacaoDTO} from "../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-irrigacao/irrigacao-dto.model";
import {ResumoNecessidadesIrrigacao} from "../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-irrigacao/resumo-necessidades-irrigacao.model";

@Injectable({
    providedIn: 'root'
})
export class IrrigacaoService {

    private urlResource = `${environment.URL_GATEWAY}/api/irrigacao`;
    protected http: HttpClient;

    constructor(injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    criar(irrigacao: IrrigacaoDTO): Observable<IrrigacaoDTO> {
        return this.http.post(`${this.urlResource}`, irrigacao)
            .pipe(map(json => IrrigacaoDTO.fromJson(json)),
                catchError(e => throwError(e)));
    }

    listarIrrigacoes(requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade, calculo = false) : Observable<IrrigacaoDTO[]> {
        let params = new HttpParams();
        if (calculo){
            params = params.set('calculo', String(calculo))
        }
        return this.http.get(`${this.urlResource}/req-outorga-finalidade/${requerimentoOutorgaFinalidade.id}`, {params: params})
            .pipe(
                map(json => IrrigacaoDTO.fromJsons(json)),
                catchError(e => throwError(e))
            );
    }

    calculoNecessidades(irrigacao: IrrigacaoDTO) : Observable<ResumoNecessidadesIrrigacao> {
        return this.http.get(`${this.urlResource}/${irrigacao.id}/calculo-necessidades`)
            .pipe(
                map(json => ResumoNecessidadesIrrigacao.fromJson(json)),
                catchError(e => throwError(e))
            );
    }

    excluirIrrigacao(irrigacao: IrrigacaoDTO): Observable<IrrigacaoCultura> {
        return this.http.delete(`${this.urlResource}/${irrigacao.id}`)
            .pipe(
                map(json => IrrigacaoCultura.fromJson(json)),
                catchError(e => throwError(e))
            );
    }
}
