import {BaseModel} from "../base.model";

export class AnexoArquivo extends BaseModel {

    constructor(
        public id?: number,
        public arquivo?: any,
        public tituloArquivo?: string,
        public sigiloso?: number,
    ) {
        super();
    }

    static fromJson(json: any): AnexoArquivo {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnexoArquivo(
            json.id,
            json.arquivo,
            json.tituloArquivo,
            json.sigiloso,
        );
    }

    static fromJsons(jsonArray: any): AnexoArquivo[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}