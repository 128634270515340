import {Component, Injector, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';
import {RequerimentoOutorgaFinalidadeService} from '../../../services/requerimento-outorga-finalidade.service';
import {BaseModel} from '../../../models/base.model';
import {CultivoTanqueEscavado} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-escavado/models/cultivo-tanque-escavado.model';
import {AquiculturaTanqueEscavado} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-escavado/models/aquicultura-tanque-escavado.model';
import {CultivoTanqueEscavadoService} from 'app/main/pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-escavado/services/cultivo-tanque-escavado.service';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-aba-finalidade-tanque-escavado',
    templateUrl: 'aba-finalidade-tanque-escavado.component.html'
})
export class AbaFinalidadeTanqueEscavadoComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;

    cultivoTanqueEscavadoArray: Array<CultivoTanqueEscavado>;
    displayedColumns: string[] = ['numeroTanques', 'areaCultivo', 'producao', 'conversaoAlimentar', 'ciclos'];
    aquiculturaTanqueEscavado: AquiculturaTanqueEscavado;

    constructor(injector: Injector,
                private dialog: MatDialog,
                private cultivoTanqueEscavadoService: CultivoTanqueEscavadoService,
                private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
                private snackBarService: SnackBarService,
    ) {
    }

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    ngOnInit(): void {
        this.aquiculturaTanqueEscavado = this.requerimentoOutorgaFinalidade.aquiculturaTanqueEscavado;
        this.carregarAquiculturaTanqueEscavadoDoRequerimento();
    }

    private carregarAquiculturaTanqueEscavadoDoRequerimento(): void {
        this.cultivoTanqueEscavadoService
            .getAllByCultivoTanqueEscavado(
                this.aquiculturaTanqueEscavado
            )
            .subscribe(cultivoTanqueEscavados => {
                this.cultivoTanqueEscavadoArray = cultivoTanqueEscavados;
            }, (e) => this.snackBarService.showError('Erro ao carregar lista cultivo tanque escavado.', e));
    }

    public getTotalNTanqueEscavado(): number {
        if (this.cultivoTanqueEscavadoArray) {
            const soma = this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.numeroTanques,
                0
            );
            return soma;
        }
        return 0;
    }

    public getTotalAreaCultivo(): number {
        if (this.cultivoTanqueEscavadoArray) {
            const soma = this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.areaCultivo, 0);
            return soma;
        }
        return 0;
    }

    public getTotalProducao(): number {
        if (this.cultivoTanqueEscavadoArray) {
            const soma = this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.producao,
                0
            );
            return soma;
        }
        return 0;
    }

    public getTotalConservacaoAlimentar(): number {
        if (this.cultivoTanqueEscavadoArray) {
            const soma = this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.conversaoAlimentar,
                0
            );
            return soma;
        }
        return 0;
    }

    public getTotalCiclos(): number {
        if (this.cultivoTanqueEscavadoArray) {
            const soma = this.cultivoTanqueEscavadoArray.reduce(
                (total, current) => total + current.ciclos,
                0
            );
            return soma;
        }
        return 0;
    }

}
