import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
    templateUrl: './confirmar-adicao-objetivo-requerimento-outorga-dialog.component.html'
})
export class ConfirmarAdicaoObjetivoRequerimentoOutorgaDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarAdicaoObjetivoRequerimentoOutorgaDialogComponent>
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
