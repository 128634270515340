import {BaseModel} from '../../models/base.model';
import {AnaliseRequerimentoEquipe} from '../../../pages/tarefas/shared/model/analise-requerimento-equipe.model';
import {BaseEnum} from '../../enums/base.enum';
import {ServidorLicenciamento} from '../../../pages/tarefas/shared/model/servidor-licenciamento.model';

export class Despacho extends BaseModel {
    constructor(
        public id?: number,
        public analiseRequerimento?: AnaliseRequerimentoEquipe,
        public servidor?: ServidorLicenciamento,
        public nomeTarefa?: string,
        public descricao?: string,
        public dataInicio?: string,
        public dataEnvio?: string,
        public tipoDespacho?: string
    ) {
        super();
    }

    public static tipoDespacho: BaseEnum[] = [
        {valor: 'P', descricao: 'Público'},
        {valor: 'I', descricao: 'Interno'},
    ];

    static fromJson(json: any): Despacho {
        if (json === undefined || json === null) {
            return null;
        }
        return new Despacho(
            json.id,
            AnaliseRequerimentoEquipe.fromJson(json.analiseRequerimento),
            ServidorLicenciamento.fromJson(json.servidor),
            json.nomeTarefa,
            json.descricao,
            json.dataInicio,
            json.dataEnvio,
            json.tipoDespacho
        );
    }
}
