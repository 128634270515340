import {NgModule} from '@angular/core';
import {
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule, MatTooltipModule
} from '@angular/material';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {ParecerTecnicoCondicionanteDialogComponent} from '../parecer-tecnico-condicionante-dialog/parecer-tecnico-condicionante-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { ConfirmarCopiarCondicionanteDialogComponent } from './confirmar-copiar-condicionante-dialog/confirmar-copiar-condicionante-dialog.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@NgModule({
    declarations: [ParecerTecnicoCondicionanteDialogComponent],
    imports: [
        CoreModule,
        SharedModule,
        MatFormFieldModule,
        MatSelectModule,
        MatRadioModule,
        MatTableModule,
        MatDatepickerModule,
        MatTooltipModule
    ],
    exports: [ParecerTecnicoCondicionanteDialogComponent],
    entryComponents: [ParecerTecnicoCondicionanteDialogComponent, ConfirmarCopiarCondicionanteDialogComponent],
    providers: [{provide: MAT_DATE_LOCALE, useValue: 'pt'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}]
})
export class ParecerTecnicoCondicionantesListModule {
}
