import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {HttpParams} from '@angular/common/http';
import {FeicaoTemplateService} from '../feicao-template.service';
import {FeicaoTemplate} from '../feicao-template.model';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';

@Component({
    selector: 'app-feicao-template-list',
    templateUrl: './feicao-template-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class FeicaoTemplateListComponent extends BaseListComponent<FeicaoTemplate> {

    descricao: string;

    constructor(
        injector: Injector,
        service: FeicaoTemplateService,
    ) {
        super(injector, ['id', 'descricao', 'tipoGeometria', 'buttons'], service);
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        if (typeof this.descricao !== 'undefined' && this.descricao.length > 0)
            httpParams = httpParams.set('descricao', this.descricao);
        return httpParams;
    }

}
