import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'unidadeMedida'
})
export class UnidadeMedidaPipe implements PipeTransform{
    
    transform(value: string) {
        
        const regExp = /(M3)|(_)|(TON)|(MES)|(DIA)/i;

        if(!value){
            return null;
        }
        const unidadeMedida = value;

        if (unidadeMedida.match(regExp)) {      
            const unidadeFormatada = unidadeMedida
            .replace(/M3/i,' m³')
            .replace(/_/,' /')
            .replace(/TON/i,' toneladas')
            .replace(/MES/i,' mês')
            .replace(/DIA/i,' dia');
            return unidadeFormatada;
        }

    }

}