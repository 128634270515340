import { Requerimento } from "app/main/pages/requerimentos/requerimento.model";
import { BaseEnum } from "../enums/base.enum";
import { BaseModel } from "./base.model";
import { DocumentoProcesso } from "./documento-processo.model";

export class TituloLegadoAnexo extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipoEmissao?: string,
        public chave?: string,
        public dataValidade?: Date,
        public numero?: string,
        public documentoProcesso?: DocumentoProcesso,
        public requerimento?: Requerimento,
        public dataInicio?: Date,
    ) {
        super();
    }

    static fromJson(json: any): TituloLegadoAnexo {
        if (json === undefined || json === null) {
            return null;
        }
        return new TituloLegadoAnexo(
            json.id,
            json.descricao,
            json.tipoEmissao,
            json.chave,
            json.dataValidade,
            json.numero,
            DocumentoProcesso.fromJson(json.documentoProcesso),
            Requerimento.fromJson(json.requerimento),
        );
    }

    static fromJsons(json: any): TituloLegadoAnexo[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(TituloLegadoAnexo.fromJson(j)));
            return resources;
        }
    }

}
