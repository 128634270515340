import {AnaliseRequerimentoAnalista} from '../../../shared/model/analise-requerimento-analista.model';
import {RequerimentoOutorga} from '../../../../requerimentos/requerimento-outorga/requerimento-outorga.model';
import {AnaliseRequerimentoEquipe} from '../../../shared/model/analise-requerimento-equipe.model';
import {DateUtils} from "../../../../../shared/util/date-utils";

export class AnaliseRequerimentoComAnalista {
    constructor(
        public id: number = null,
        public dataInicio: Date = null,
        public dataFim: Date = null,
        public situacao: Date = null,
        public requerimento: RequerimentoOutorga = null,
        public equipe: AnaliseRequerimentoEquipe = null,
        public analista: AnaliseRequerimentoAnalista = null
    ) {
    }

    static fromJson(json: any): AnaliseRequerimentoComAnalista {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnaliseRequerimentoComAnalista(
            json.id,
            DateUtils.getDate(json.dataInicio),
            DateUtils.getDate(json.dataFim),
            json.situacao,
            json.requerimento,
            json.equipe,
            json.analista
        );
    }

    static fromJsons(json: any): AnaliseRequerimentoComAnalista[] {
        const array: AnaliseRequerimentoComAnalista[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(AnaliseRequerimentoComAnalista.fromJson(item)));
            return array;
        }
    }
}