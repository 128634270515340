import {BaseRelacionamentoService} from "../../services/base-relacionamento.service";
import {BaseRelacionamentoModel} from "../../models/base-relacionamento.model";
import {AbstractComponenteRelacionamento} from "./abstract-componente-relacionamento";
import {OnInit} from "@angular/core";

export abstract class AbstractComponenteRelacionamentoSubdominio<T extends BaseRelacionamentoModel, S extends BaseRelacionamentoService<T>> extends AbstractComponenteRelacionamento<T, S> implements OnInit {

    constructor() {
        super();
    }

    public abstract apresentaSubtipo(): boolean;

    async ngOnInit() {
        super.ngOnInit();
        for (let s of this.info.selecionados) {
            this.service.getSubDominio(s.dominio.id).then(() => {
                s.subdominio = this.info.subdominio.find(o => o.id === s.vinculo.idSubDominio)
            });
        }
    }

    protected atualizaVinculo() {
        super.atualizaVinculo();
        if (this.apresentaSubtipo()) {
            this.novoDado.vinculo.idSubDominio = this.novoDado.subdominio.id;
        }
    }

    protected validaLinha(): boolean {
        let valida = true;

        if (this.apresentaSubtipo()) {
            valida = this.novoDado.vinculo.idSubDominio && this.info.subdominio.some(d => d.id === this.novoDado.vinculo.idSubDominio)
        }
        return super.validaLinha() && valida;
    }

    public async getSubDominio() {
        this.info.subdominio = [];
        await this.service.getSubDominio(this.novoDado.dominio.id);
    }

    isBotaoAdicionarDisabled() {
        return super.isBotaoAdicionarDisabled() ||
            (this.apresentaSubtipo() && !this.novoDado.subdominio) ||
            (this.novoDado.subdominio && this.novoDado.subdominio.descricao === 'Outro' && !this.novoDado.vinculo.descricao)
    }

    showDescricao(): boolean {
        return super.showDescricao() || (this.novoDado.subdominio && this.novoDado.subdominio.descricao === 'Outro');
    }


}