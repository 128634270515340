import {BaseModel} from "./base.model";
import {AutorizacaoQueimaControlada} from './autorizacao-queima-controlada.model';

export class QueimaControladaFeicoes extends BaseModel {
    constructor(
        public id?: number,
        public queimaControlada?: AutorizacaoQueimaControlada,
        public idFeicaoRequerimentoLicenciamento?: number,
    ) {
        super();
    }

    static fromJson(json: any): QueimaControladaFeicoes {
        if (json === undefined || json === null) {
            return null;
        }
        return new QueimaControladaFeicoes(
            json.id,
            AutorizacaoQueimaControlada.fromJson(json.queimaControlada),
            json.idFeicaoRequerimentoLicenciamento,
        );
    }

    static fromJsons(json: any): QueimaControladaFeicoes[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(QueimaControladaFeicoes.fromJson(j)));
            return resources;
        }
    }
}