import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {empty, Observable} from 'rxjs';
import {PdfViewerDialogComponent} from './pdf-viewer-dialog.component';
import {ServiceAssinador} from "../../services/service-assinador.interface";
import { EnumObjetoAssinatura } from '../../enums/EnumObjetoAssinatura';

function getValueOrDefault<T>(value: T, defaultValue: T): T {
    return value === undefined ? defaultValue : value;
}

function applyDefaultConfig(config?: PdfViewerDialogConfig): PdfViewerDialogConfig {
    if (!config) {
        config = {};
    }

    config.showBorders = getValueOrDefault(config.showBorders, false);
    config.showRotateButton = getValueOrDefault(config.showRotateButton, true);
    config.showPagingButtons = getValueOrDefault(config.showPagingButtons, true);
    config.showPropertiesButton = getValueOrDefault(config.showPropertiesButton, true);
    config.showSecondaryToolbarButton = getValueOrDefault(config.showSecondaryToolbarButton, true);
    config.showZoomButtons = getValueOrDefault(config.showZoomButtons, true);
    config.showDownloadButton = getValueOrDefault(config.showDownloadButton, true);
    config.showPrintButton = getValueOrDefault(config.showPrintButton, true);

    return config;
}

export interface AssinaturaRequest {
    service: ServiceAssinador;
    alvoAssinatura: any;
    idTarefa?: string;
    aviso?: string;
    textoBotaoEnviar?: string;
    objetoAssinatura?: EnumObjetoAssinatura;
    dadosSolicitacao?: any;
    bLobPdf?: any;
    tarefa?: string;
    idDepartamentoResponsavel?: number;
}

export interface PdfViewerDialogConfig {
    height?: string;
    width?: string;
    httpParams?: HttpParams;
    filenameForDownload?: string;
    showBookmarkButton?: boolean;
    showBorders?: boolean;
    showDownloadButton?: boolean;
    showFindButton?: boolean;
    showHandToolButton?: boolean;
    showOpenFileButton?: boolean;
    showPagingButtons?: boolean;
    showPresentationModeButton?: boolean;
    showPrintButton?: boolean;
    showPropertiesButton?: boolean;
    showRotateButton?: boolean;
    showScrollingButton?: boolean;
    showSecondaryToolbarButton?: boolean;
    showSidebarButton?: boolean;
    showSpreadButton?: boolean;
    showZoomButtons?: boolean;
    assinatura?: AssinaturaRequest;
}

@Injectable({providedIn: 'root'})
export class PdfViewerDialog {
    constructor(private dialog: MatDialog) {

    }

    show(source: string | Blob | Uint8Array | ArrayBuffer, config?: PdfViewerDialogConfig): Observable<any> {
        if (source) {
            config = applyDefaultConfig(config);

            const dialogConfig = new MatDialogConfig();

            dialogConfig.height = getValueOrDefault(config.height, '90%');
            dialogConfig.width = getValueOrDefault(config.width, '960px');
            dialogConfig.panelClass = 'pdf-viewer-dialog';
            dialogConfig.data = {source, ...config};

            return this.dialog.open(PdfViewerDialogComponent, dialogConfig).afterClosed();
        }

        return empty();
    }
}
