import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EmpreendimentoEnergiaEolica} from "app/main/shared/models/empreendimento-energia-eolica.model";
import {EmpreendimentoEnergeticoService} from "app/main/shared/services/empreendimento-energetico.service";
import {Dominio} from "../../../../../../../shared/models/dominio-model";
import {UnidadeContingenciaService} from "../../../../../../../shared/services/unidade-contingencia.service";
import {DominioService} from "../../../../../../../shared/services/dominio.service";
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {
    EmpreendimentoEnergiaEolicaTecnologia
} from "../../../../../../../shared/models/empreendimento-energia-eolica-tecnologia.model";
import {UnidadeContingencia} from "../../../../../../../shared/models/unidade-contingencia.model";
import {GeradorEletricoService} from "../../../../../../../shared/services/gerador-eletrico.service";
import {GeradorEletrico} from "../../../../../../../shared/models/gerador-eletrico.model";
import {
    EmpreendimentoEnergiaEolicaTecnologiaService
} from "../../../../../../../shared/services/empreendimento-energia-eolica-tecnlogia.service";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";
import {error} from "util";

export const TIPO_UNID_CONTINGENCIA_BIOMASSA = 1;
export const TIPO_UNID_CONTINGENCIA_FOSSIL = 2;

@Component({
    selector: "app-aba-empreendimento-energetico",
    templateUrl: "./ee-eolica.html",
    styleUrls: ["./ee-eolica.scss"],
})
export class EeEolicaComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    form: FormGroup;
    nomeForm = 'Empreendimentos Energéticos - Éolica'
    private empreendimento: EmpreendimentoEnergiaEolica;

    isNaoPodeEditar: boolean = false;

    tiposEmpreendimento: Array<Dominio> = [];
    tiposSistemaConexao: Array<Dominio> = [];
    tiposControlePotencia: Array<Dominio> = [];

    tiposTecnologiaSelecionados: Array<{
        dominio: Dominio;
        vinculo: EmpreendimentoEnergiaEolicaTecnologia;
    }>;
    tiposTecnologia: Array<Dominio> = [];
    tiposTecnologiaExcluidos: Array<number> = [];

    geradoresEletricosIncluidos: Array<GeradorEletrico>;
    geradoresEletricosExcluidos: Array<number> = [];

    unidadesContingenciaIncluidas: Array<{
        tipoUnidadeContingencia: Dominio;
        tipoCombustivel: Dominio;
        subtipoCombustivel: Dominio;
        vinculo: UnidadeContingencia;
    }>;
    tiposUnidadeContingencia: Array<Dominio> = [];
    tiposFossil: Array<Dominio> = [];
    tiposBiomassa: Array<Dominio> = [];
    unidadesContingenciaExcluidas: Array<number> = [];

    constructor(
        private formBuilder: FormBuilder,
        private empreendimentoEnergiaEolicaService: EmpreendimentoEnergeticoService,
        private empreendimentoEnergiaEolicaTecnologiaService: EmpreendimentoEnergiaEolicaTecnologiaService,
        private empreendimentoEnergiaEolicaUnidadeContigenciaService: UnidadeContingenciaService,
        private empreendimentoEnergiaEolicaGeradorEletricoService: GeradorEletricoService,
        private dominiosService: DominiosService,
        private dominioService: DominioService,
        private snackBarService: SnackBarService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
    }

    async ngOnInit() {
        this.initFormGroup();

        this.getListaTiposEmpreendimento();
        this.getListaTiposSistemaConexao();
        this.getListaTiposControlePotencia();
        this.getListaTiposTecnologia();
        this.getListaTipoUnidadeContingencia();
        this.getListaTipoFossil();
        this.getListaTipoBiomassa();

        if (this.requerimento) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarEeEolica(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarEeEolica(this.requerimento.id)
            }

            if (this.empreendimento) {
                this.form.patchValue(this.empreendimento);
                this.getTiposTecnologia();
                this.getGeradoresEletricos();
                this.getUnidadesContigencia();
            } else {
                this.tiposTecnologiaSelecionados = [];
                this.unidadesContingenciaIncluidas = [];
                this.geradoresEletricosIncluidos = [];
            }
        } else {
            this.tiposTecnologiaSelecionados = [];
            this.unidadesContingenciaIncluidas = [];
            this.geradoresEletricosIncluidos = [];
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
                this.isNaoPodeEditar = true;
                this.form.disable();
        }
    }

    async carregarEeEolica(idRequerimento) {
        let res = await this.empreendimentoEnergiaEolicaService
            .getByRequerimento(idRequerimento)
            .toPromise();
        this.empreendimento = res ? res : null;
    }

    initFormGroup() {
        this.form = this.formBuilder.group({
            // tipo: [{value: null, disabled: this.isModoVisualizacao}, Validators.required],
            tipoSistemaConexao: [{value: null, disabled: this.isModoVisualizacao}, Validators.required],
            paralelismoPermanente: [{value: null, disabled: this.isModoVisualizacao}, Validators.required],
            fabricanteTurbinas: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(50)]],
            modelo: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(50)]],
            classeVentoIEC: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(50)],],
            potenciaInstaladaDeclarada: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            capacidadeInstaladaUnidadeGeradora: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            velocidadeVentoNominal: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            velocidadeVentoCorte: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            numeroUnidadesGeradoras: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            fatorCapacidade: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            velocidadeVentoPartida: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            possuiMultiplicadorVelocidade: [{value: null, disabled: this.isModoVisualizacao}, Validators.required],
            razao: [{value: null, disabled: this.isModoVisualizacao}, Validators.maxLength(50)],
            tipoControlePotencia: [{value: null, disabled: this.isModoVisualizacao}, Validators.required],
            quantidadeTurbinas: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            potenciaNominal: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            potenciaReferencia: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            maxPotenciaGerada: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            diametroRotor: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            areaVarridaRotor: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            faixaRotadaRotor: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            numeroPasRotor: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            alturaEixoRotor: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            tipoTorres: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            materialTorres: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            nivelRuidoBaseTorre: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
            referenciaVelocidadeVento: [{
                value: null,
                disabled: this.isModoVisualizacao
            }, [Validators.required, Validators.maxLength(20)]],
        });
    }

    private async getListaTiposEmpreendimento() {
        this.tiposEmpreendimento = await this.dominiosService
            .getDominio("TIPO_EMPREEND_ENERGIA_EOLICA")
            .toPromise();
    }

    private async getListaTiposSistemaConexao() {
        this.tiposSistemaConexao = await this.dominiosService
            .getDominio("TIPO_SISTEMA_CONEXAO")
            .toPromise();
    }

    private async getListaTiposControlePotencia() {
        this.tiposControlePotencia = await this.dominiosService
            .getDominio("TIPO_CONTROLE_POTENCIA")
            .toPromise();
    }

    private async getListaTiposTecnologia() {
        this.tiposTecnologia = await this.dominiosService
            .getDominio("TIPO_TECNOLOGIA_EOLICA")
            .toPromise();
    }

    private async getListaTipoUnidadeContingencia() {
        this.tiposUnidadeContingencia = await this.dominioService
            .buscarTipoUnidadeContigencia()
            .toPromise();
    }

    private async getListaTipoFossil() {
        this.tiposFossil = await this.dominioService
            .buscarTipoCombustivel()
            .toPromise();
    }

    private async getListaSubTipoFossil(idTipo: number) {
        return await this.dominioService
            .buscarSubTipoCombustivel(idTipo)
            .toPromise();
    }

    private async getListaTipoBiomassa() {
        this.tiposBiomassa = await this.dominioService
            .buscarTipoBiomassa()
            .toPromise();
    }

    private async getListaSubtiposBiomassa(idTipo: number) {
        return await this.dominioService
            .buscarSubTipoBiomassa(idTipo)
            .toPromise();
    }

    private async getTiposTecnologia() {
        let vinculos = await this.empreendimentoEnergiaEolicaTecnologiaService
            .getByEmpreendimento(this.empreendimento.id)
            .toPromise();
        this.tiposTecnologiaSelecionados = vinculos.map((vinculo) => {
            return {
                dominio: this.tiposTecnologia.find(
                    (o) => o.id === vinculo.tipoTecnologiaEolica
                ),
                vinculo: vinculo,
            };
        });

        if (!vinculos || vinculos.length < 1) {
            this.tiposTecnologiaSelecionados = [];
        }
    }

    private async getGeradoresEletricos() {
        this.geradoresEletricosIncluidos =
            await this.empreendimentoEnergiaEolicaGeradorEletricoService
                .getByEmpreendimento(this.empreendimento.id)
                .toPromise();

        if (
            !this.geradoresEletricosIncluidos ||
            this.geradoresEletricosIncluidos.length < 1
        ) {
            this.geradoresEletricosIncluidos = [];
        }
    }

    private async getUnidadesContigencia() {
        let vinculos =
            await this.empreendimentoEnergiaEolicaUnidadeContigenciaService
                .getByEmpreendimento(this.empreendimento.id)
                .toPromise();
        this.unidadesContingenciaIncluidas = await Promise.all(vinculos.map(async (vinculo) => {
            let tipoUnidadeContingencia: Dominio = this.tiposUnidadeContingencia.find(
                (o) => o.id === vinculo.tipoUnidadeContingencia
            );
            let tipoCombustivel: Dominio = this.getListaTipoCombustivel(tipoUnidadeContingencia).find(
                (o) => o.id === vinculo.tipoCombustivel
            )
            let listaSubTipoCombustivel: Dominio[] = await this.getListaSubTipoCombustivel(tipoUnidadeContingencia, tipoCombustivel)
            return {
                tipoUnidadeContingencia: tipoUnidadeContingencia,
                tipoCombustivel: tipoCombustivel,
                subtipoCombustivel: listaSubTipoCombustivel.find(
                    (o) => o.id === vinculo.subTipoCombustivel
                ),
                vinculo: vinculo,
            };
        }));

        if (!vinculos || vinculos.length < 1) {
            this.tiposTecnologiaSelecionados = [];
        }
    }

    private getListaTipoCombustivel(tipoUnidadeContigencia): Dominio[] {
        let lista;
        if (tipoUnidadeContigencia.id === TIPO_UNID_CONTINGENCIA_BIOMASSA) {
            lista = this.tiposBiomassa;
        } else {
            lista = this.tiposFossil;
        }
        return lista;
    }

    private async getListaSubTipoCombustivel(tipoUnidadeContigencia, tipoCombustivel): Promise<Dominio[]> {
        let lista;
        if (tipoUnidadeContigencia.id === TIPO_UNID_CONTINGENCIA_BIOMASSA) {
            lista = await this.getListaSubtiposBiomassa(tipoCombustivel.id);
        } else {
            lista = await this.getListaSubTipoFossil(tipoCombustivel.id)
        }
        return lista;
    }

    private async deleteTiposTecnologia() {
        for (let id of this.tiposTecnologiaExcluidos) {
            await this.empreendimentoEnergiaEolicaTecnologiaService
                .deleteTecnologia(id)
                .toPromise();
        }
    }

    private async deleteGeradoresEletricos() {
        for (let id of this.geradoresEletricosExcluidos) {
            await this.empreendimentoEnergiaEolicaGeradorEletricoService
                .deleteGerador(id)
                .toPromise();
        }
    }

    private async deleteUnidadesContingencia() {
        for (let id of this.unidadesContingenciaExcluidas) {
            await this.empreendimentoEnergiaEolicaUnidadeContigenciaService
                .deleteContingencia(id)
                .toPromise();
        }
    }

    private async createTiposTecnologia(resource: EmpreendimentoEnergiaEolica) {
        await this.deleteTiposTecnologia();
        for (let tipos of this.tiposTecnologiaSelecionados) {
            let t = tipos.vinculo;
            t.empreendimentoEnergiaEolica = resource.id;
            if (t.id === undefined) {
                const salvo = await this.empreendimentoEnergiaEolicaTecnologiaService
                    .createTecnologia(t)
                    .toPromise();
                t.id = salvo.id;
            }
        }
    }

    private async createGeradoresEletricos(
        resource: EmpreendimentoEnergiaEolica
    ) {
        await this.deleteGeradoresEletricos();
        for (let t of this.geradoresEletricosIncluidos) {
            t.empreendimentoEnergiaEolica = resource.id;
            if (t.id === undefined) {
                const salvo = await this.empreendimentoEnergiaEolicaGeradorEletricoService
                    .createGerador(t)
                    .toPromise();
                t.id = salvo.id;
            }
        }
    }

    limitInput(input: HTMLInputElement, limite: number) {
        if (String(input.value).length > limite) {
            input.value = String(input.value).substring(0, limite);
        }
    }

    private async createUnidadesContingencia(
        resource: EmpreendimentoEnergiaEolica
    ) {
        await this.deleteUnidadesContingencia();
        for (let tipos of this.unidadesContingenciaIncluidas) {
            let t = tipos.vinculo;
            t.empreendimentoEnergiaEolica = resource.id;
            if (t.id === undefined) {
                const salvo = await this.empreendimentoEnergiaEolicaUnidadeContigenciaService
                    .createContigencia(t)
                    .toPromise();
                t.id = salvo.id;
            }
        }
    }

    async salva() {
        this.form.markAllAsTouched();

        if (this.form.valid) {
            let res = EmpreendimentoEnergiaEolica.fromJson(this.form.value);
            res.requerimento = this.requerimento.id
            res.id = this.empreendimento ? this.empreendimento.id : null;
            let resource;
            if (!res.id) {
                await this.empreendimentoEnergiaEolicaService.createEolica(res).toPromise().then(res => {
                    resource = res;
                    this.enviarFormConcluido.emit();
                }, () => this.snackBarService.showError("Ocorreu um erro no servidor."));
            } else {
                await this.empreendimentoEnergiaEolicaService.updateEolica(res).toPromise().then(() => {
                    resource = res;
                    this.enviarFormConcluido.emit();
                }, () => this.snackBarService.showError("Ocorreu um erro no servidor."));
            }

            await Promise.all([
                this.createTiposTecnologia(resource),
                this.createGeradoresEletricos(resource),
                this.createUnidadesContingencia(resource)
            ])
        } else {
            this.snackBarService.showError("O formulário " + this.nomeForm + " possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }
}
