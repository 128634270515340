import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
    templateUrl: 'relacao-imoveis-encontrados-dialog.component.html',
    styleUrls: ['./relacao-imoveis-encontrados-dialog.component.scss'],
})

export class RelacaoImoveisEncontradosDialogComponent {


    constructor(
        private dialogRef: MatDialogRef<RelacaoImoveisEncontradosDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any[],
    ) {
    }


    public closeDialog(imovel: any): void {
        this.dialogRef.close(imovel);
    }

    onSelectImovel(imovel: any): void {
        console.log('Imovel Dialog', imovel);
        this.closeDialog(imovel);
    }
}
