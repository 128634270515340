import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ParecerRequerimentoConclusao} from './parecer-requerimento-conclusao.model';
import {EmissaoTitulo} from '../../../shared/models/emissao-titulo.model';
import {EnumSituacaoTitulo} from '../../../shared/enums/EnumSituacaoTitulo';
import { ParecerRequerimentoConclusaoDTO } from './parecer-requerimento-conclusao-dto.model';
import { ParecerTecnico } from '../parecer-tecnico.model';

@Injectable({
    providedIn: 'root',
})
export class ParecerRequerimentoConclusaoService extends BaseService<ParecerRequerimentoConclusao> {
    constructor(protected injector: Injector) {
        super(
            '/api/parecer-requerimento-conclusao',
            injector,
            ParecerRequerimentoConclusao.prototype,
            ParecerRequerimentoConclusao.fromJson
        );
    }

    getByParecerTecnico(parecerTecnicoId: number): Observable<ParecerRequerimentoConclusao[]> {
        const url = `${this.urlResource}/parecer-tecnico/${parecerTecnicoId}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createParecerEmissaoTitulo(emissaoTitulo: EmissaoTitulo, enumSituacaoTitulo: EnumSituacaoTitulo, parecerTecnico: ParecerTecnico): Observable<EmissaoTitulo> {
        let parecerRequerimentoConclusaoDTO = new ParecerRequerimentoConclusaoDTO();
        parecerRequerimentoConclusaoDTO.emissaoTitulo = emissaoTitulo;
        parecerRequerimentoConclusaoDTO.enumParecerConclusao = enumSituacaoTitulo;
        parecerRequerimentoConclusaoDTO.parecerTecnico = parecerTecnico;
        const url = `${this.urlResource}/parecer-emissao-titulo`;
        return this.http
            .post(url, parecerRequerimentoConclusaoDTO)
            .pipe(
                map(response => response),
                catchError(this.handleError)
            );
    }

    readEmissaoTituloDTOPorIdParecerReqConclusao(idParecerRequerimentoConclusao: number): Observable<EmissaoTitulo> {
        const url = `${this.urlResource}/emissao-titulo/${idParecerRequerimentoConclusao}`;
        return this.http
            .get(url)
            .pipe(
                map(EmissaoTitulo.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
