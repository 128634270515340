import {Component, Input, OnInit} from '@angular/core';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';
import {GeracaoEnergia} from '../../../models/geracao-energia.model';
import {TurbinaService} from '../../../services/turbina.service';
import {Turbina} from '../../../models/turbina.model';
import {SnackBarService} from '../../../snack-bar/snack-bar.service';

@Component({
    selector: 'app-aba-finalidade-geracao-energia',
    templateUrl: './aba-finalidade-geracao-energia.component.html'
})
export class AbaFinalidadeGeracaoEnergiaComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;
    geracaoEnergia: GeracaoEnergia;
    turbinas: Turbina[] = [];

    totalVazaoNominalTurbinas = 0;

    tiposUsinas = GeracaoEnergia.tiposUsinas;

    constructor(
        private turbinaService: TurbinaService,
        private snackBarService: SnackBarService,
    ) {
    }

    ngOnInit(): void {
        this.geracaoEnergia = this.requerimentoOutorgaFinalidade.geracaoEnergia;
        this.turbinaService.getAllByGeracaoEnergia(this.geracaoEnergia).subscribe(turbinas => {
                this.turbinas = turbinas;
                this.totalVazaoNominalTurbinas = this.turbinas.reduce((total, turbina) => total + turbina.vazaoNominalUnitaria, 0);
            },
            e => this.snackBarService.showError('Erro ao buscar Turbinas', e));
    }

}
