import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Derivacao } from "../../../../../../../shared/models/derivacao.model";
import { EmpreendimentoEeHidricaDTO } from "../../../../../../../shared/models/empreendimento-ee-hidrica-dto.model";
import { EmpreendimentoEeHidrica } from "../../../../../../../shared/models/empreendimento-ee-hidrica.model";
import { SnackBarService } from "../../../../../../../shared/snack-bar/snack-bar.service";
import { RequerimentoService } from "../../../../../requerimento.service";
import { RequerimentoFormularioLicenciamentoService } from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import { BaseAtividadeEmpreendimentoComponent } from "../base-atividade-empreendimento.component";
import { EeHidricaService } from "./ee-hidrica.service";

@Component({
    selector: 'app-ee-hidrica',
    templateUrl: './ee-hidrica.component.html',
    styleUrls: ['./ee-hidrica.component.scss']
})
export class EeHidricaComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    idForm: number;
    updateId: boolean = false;
    formEeHidrica: FormGroup;
    mostrarTipoCanalDerivacao: boolean = false;
    tipoCanalDerivacao: any = ['Canal/Túnel de adução', 'Conduto/Túnel Forçado/Galeria'];
    mostrarFieldCanalAducao: boolean = false;
    mostrarFieldCondutoForcado: boolean = false;
    isNaoPodeEditar: boolean = false;
    listaTipoCanalDerivacao: any = [];

    casasDeForca: any[] = [];
    barragem: any[] = [];
    unidadesGeradoras: any[] = [];
    reservatorios: any[] = [];

    formularioSalvo: EmpreendimentoEeHidrica;
    formularioSalvoCanalDerivacao: any[] = [];


    constructor(private formBuilder: FormBuilder,
                private eeHidricaService: EeHidricaService,
                private requerimentoService: RequerimentoService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private snackBarService: SnackBarService,) {
        super();
    }

    async ngOnInit() {
        this.buildFormEeHidrica();
        try {
            if (this.requerimento.id) {
                
                await this.carregarEeHidrica(this.requerimento.id)
                
                if (this.idForm) {
                    await this.eeHidricaService.buscarDerivacaoPorId(this.idForm).toPromise().then(response => {
                        this.formularioSalvoCanalDerivacao = response;
                        if (this.formularioSalvoCanalDerivacao)
                            this.formularioSalvoCanalDerivacao.forEach(canal => {
                                if (canal) {
                                    if (canal.descricao == 'Canal/Túnel de adução') {
                                        this.formEeHidrica.controls.tipoAducao.patchValue(true);
                                        this.alterarTipoDerivacao(true, canal.descricao, canal)
                                    }
                                    if (canal.descricao == 'Conduto/Túnel Forçado/Galeria') {
                                        this.formEeHidrica.controls.tipoForcadoGaleria.patchValue(true);
                                        this.alterarTipoDerivacao(true, canal.descricao, canal)
                                    }
                                }
                            })
                    })
                }
            }

            if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
                || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
                this.isNaoPodeEditar = true;
                this.formEeHidrica.disable();
            }

            //carregando componentes filhos após id do formulario ser atualizado
            this.updateId = true;
        } catch (error) {
            //carregando componentes filhos após id do formulario ser atualizado
            this.updateId = true;
        }

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
            this.formEeHidrica.disable();
        }
    }

    async carregarEeHidrica(idRequerimento){
        await this.eeHidricaService.buscarPorIdRequerimento(idRequerimento).toPromise().then( response => {
            if(response){
                this.formularioSalvo = response;
                this.idForm = response.id;
                this.formEeHidrica.controls.id.patchValue(this.formularioSalvo.id);
                this.formEeHidrica.controls.tipoEmpreendimentoEnergetico.patchValue(this.formularioSalvo.tipoEmpreendimentoEnergetico);
                this.formEeHidrica.controls.naMontante.patchValue(this.formularioSalvo.naMontante);
                this.formEeHidrica.controls.naJusante.patchValue(this.formularioSalvo.naJusante);
                this.formEeHidrica.controls.fatorCapacidade.patchValue(this.formularioSalvo.fatorCapacidade);
                this.formEeHidrica.controls.quedaBruta.patchValue(this.formularioSalvo.quedaBruta);
                this.formEeHidrica.controls.areaReservatorio.patchValue(this.formularioSalvo.areaReservatorio);
                this.formEeHidrica.controls.vazaoQmlt.patchValue(this.formularioSalvo.vazaoQmlt);
                this.formEeHidrica.controls.potenciaTotal.patchValue(this.formularioSalvo.potenciaTotal);
                this.formEeHidrica.controls.energiaMedia.patchValue(this.formularioSalvo.energiaMedia);
                this.formEeHidrica.controls.possuiBarragem.patchValue(this.formularioSalvo.possuiBarragem == true ? "sim" : "nao");
                this.formEeHidrica.controls.possuiReservatorio.patchValue(this.formularioSalvo.possuiReservatorio == true ? 'sim' : 'nao');
                this.formEeHidrica.controls.possuiCanalDerivacao.patchValue(this.formularioSalvo.possuiCanalDerivacao == true ? 'sim' : 'nao');
                if(this.formularioSalvo.possuiCanalDerivacao == true){
                    this.mostrarTipoCanalDerivacao = true;
                }else{
                    this.mostrarTipoCanalDerivacao = false;
                }
                this.formEeHidrica.controls.tipoCircuito.patchValue(this.formularioSalvo.tipoCircuito);
                this.formEeHidrica.controls.tipoCabo.patchValue(this.formularioSalvo.tipoCabo);
                this.formEeHidrica.controls.extensaoTransmissaoInteresse.patchValue(this.formularioSalvo.extensaoTransmissaoInteresse);
                this.formEeHidrica.controls.tensaoTransmissao.patchValue(this.formularioSalvo.tensaoTransmissao);
                this.formEeHidrica.controls.resistividadeTransmissao.patchValue(this.formularioSalvo.resistividadeTransmissao);
                this.formEeHidrica.controls.comprimentoCanalAducao.patchValue(this.formularioSalvo.comprimentoCanalAducao);
                this.formEeHidrica.controls.larguraMediaCanalAducao.patchValue(this.formularioSalvo.larguraMediaCanalAducao);
                this.formEeHidrica.controls.qtdeCondutoTunel.patchValue(this.formularioSalvo.qtdeCondutoTunel);
                this.formEeHidrica.controls.diametroInternoTunel.patchValue(this.formularioSalvo.diametroInternoTunel);
                this.formEeHidrica.controls.comprimentoMedioTunel.patchValue(this.formularioSalvo.comprimentoMedioTunel);
            }
        })
    }

    private buildFormEeHidrica(): void {
        this.formEeHidrica = this.formBuilder.group({
            id: [null],
            tipoEmpreendimentoEnergetico: ["", [Validators.required]],
            naMontante: ["", [Validators.required]],
            naJusante: ["", [Validators.required]],
            fatorCapacidade: ["", [Validators.required]],
            quedaBruta: ["", [Validators.required]],
            areaReservatorio: ["", [Validators.required]],
            vazaoQmlt: ["", [Validators.required]],
            potenciaTotal: ["", [Validators.required]],
            energiaMedia: ["", [Validators.required]],
            possuiBarragem: ["", [Validators.required]],
            possuiReservatorio: ["", [Validators.required]],
            possuiCanalDerivacao: ["", [Validators.required]],
            tipoCircuito: ["", [Validators.required]],
            tipoCabo: ["", [Validators.required]],
            extensaoTransmissaoInteresse: ["", [Validators.required]],
            tensaoTransmissao: ["", [Validators.required]],
            resistividadeTransmissao: ["", [Validators.required]],
            comprimentoCanalAducao: ["", [Validators.required]],
            larguraMediaCanalAducao: ["", [Validators.required]],
            qtdeCondutoTunel: ["", [Validators.required]],
            diametroInternoTunel: ["", [Validators.required]],
            comprimentoMedioTunel: ["", [Validators.required]],
            tipoAducao: [false],
            tipoForcadoGaleria: [false],
        })
        this.formEeHidrica.controls.comprimentoCanalAducao.disable();
        this.formEeHidrica.controls.larguraMediaCanalAducao.disable();
        this.formEeHidrica.controls.qtdeCondutoTunel.disable();
        this.formEeHidrica.controls.diametroInternoTunel.disable();
        this.formEeHidrica.controls.comprimentoMedioTunel.disable();
    }

    changeCanalDerivacao(event){
        if(event == 'sim'){
            this.mostrarTipoCanalDerivacao = true;
        }else {
            this.mostrarTipoCanalDerivacao = false;
        }
    }

    alterarTipoDerivacao(event: any, tipoCanalDerivacao: string, object?: any) {
        if(event){
            if(object){
                this.listaTipoCanalDerivacao.push(object);
            }else{
                this.listaTipoCanalDerivacao.push(new Derivacao(
                    null,
                    null,
                    tipoCanalDerivacao,
                ))
            }
        }else{
            let data;
            data = this.listaTipoCanalDerivacao.filter((value, key) => {
                if(value.descricao == tipoCanalDerivacao){
                    if(value.id != null){
                        this.eeHidricaService.deletarDerivacaoPorId(value.id).subscribe( response => {
                            this.snackBarService.showSuccess("deletado");
                        })
                    }
                }
                return value.descricao != tipoCanalDerivacao;
            });
            this.listaTipoCanalDerivacao = data;
        }

        if (tipoCanalDerivacao == 'Canal/Túnel de adução') {
            this.mostrarFieldCanalAducao = event;
            if (event) {
                this.formEeHidrica.controls.comprimentoCanalAducao.enable();
                this.formEeHidrica.controls.larguraMediaCanalAducao.enable();
            } else {
                this.formEeHidrica.controls.comprimentoCanalAducao.disable();
                this.formEeHidrica.controls.comprimentoCanalAducao.setErrors(null);

                this.formEeHidrica.controls.larguraMediaCanalAducao.disable();
                this.formEeHidrica.controls.larguraMediaCanalAducao.setErrors(null);

                this.formEeHidrica.controls.comprimentoCanalAducao.reset();
                this.formEeHidrica.controls.larguraMediaCanalAducao.reset();
            }
        } else if (tipoCanalDerivacao == 'Conduto/Túnel Forçado/Galeria') {
            this.mostrarFieldCondutoForcado = event;
            if (event) {
                this.formEeHidrica.controls.qtdeCondutoTunel.enable();
                this.formEeHidrica.controls.diametroInternoTunel.enable();
                this.formEeHidrica.controls.comprimentoMedioTunel.enable();
            } else {
                this.formEeHidrica.controls.qtdeCondutoTunel.disable();
                this.formEeHidrica.controls.qtdeCondutoTunel.setErrors(null);

                this.formEeHidrica.controls.diametroInternoTunel.disable();
                this.formEeHidrica.controls.diametroInternoTunel.setErrors(null);

                this.formEeHidrica.controls.comprimentoMedioTunel.disable();
                this.formEeHidrica.controls.comprimentoMedioTunel.setErrors(null);

                this.formEeHidrica.controls.qtdeCondutoTunel.reset();
                this.formEeHidrica.controls.diametroInternoTunel.reset();
                this.formEeHidrica.controls.comprimentoMedioTunel.reset();
            }
        }
    }

    receberCasasDeForca(casasDeForca: any[]) {
        this.casasDeForca = casasDeForca;
    }

    receberBarragem(barragem: any[]) {
        this.barragem = barragem;
    }

    receberUnidadesGeradoras(uniGeradora: any[]) {
        this.unidadesGeradoras = uniGeradora;
    }

    receberReservatorios(reservatorio: any[]) {
        this.reservatorios = reservatorio;
    }

    salvar() {
        if(this.validarForm()){
            let empreendimentoEeHidrica = new EmpreendimentoEeHidrica(
                this.formEeHidrica.controls.id.value,
                this.requerimento.id,
                this.formEeHidrica.controls.tipoEmpreendimentoEnergetico.value,
                Number(this.formEeHidrica.controls.naMontante.value),
                Number(this.formEeHidrica.controls.naJusante.value),
                Number(this.formEeHidrica.controls.fatorCapacidade.value),
                Number(this.formEeHidrica.controls.quedaBruta.value),
                Number(this.formEeHidrica.controls.areaReservatorio.value),
                Number(this.formEeHidrica.controls.vazaoQmlt.value),
                Number(this.formEeHidrica.controls.potenciaTotal.value),
                Number(this.formEeHidrica.controls.energiaMedia.value),
                this.formEeHidrica.controls.possuiBarragem.value == 'sim',
                this.formEeHidrica.controls.possuiReservatorio.value == 'sim',
                this.formEeHidrica.controls.possuiCanalDerivacao.value == 'sim',
                this.formEeHidrica.controls.tipoCircuito.value,
                this.formEeHidrica.controls.tipoCabo.value,
                Number(this.formEeHidrica.controls.extensaoTransmissaoInteresse.value),
                Number(this.formEeHidrica.controls.tensaoTransmissao.value),
                Number(this.formEeHidrica.controls.resistividadeTransmissao.value),
                Number(this.formEeHidrica.controls.comprimentoCanalAducao.value),
                Number(this.formEeHidrica.controls.larguraMediaCanalAducao.value),
                Number(this.formEeHidrica.controls.qtdeCondutoTunel.value),
                Number(this.formEeHidrica.controls.diametroInternoTunel.value),
                Number(this.formEeHidrica.controls.comprimentoMedioTunel.value),
            );

            let eeHidricaDto = new EmpreendimentoEeHidricaDTO(
                empreendimentoEeHidrica,
                this.listaTipoCanalDerivacao,
                this.barragem,
                this.casasDeForca,
                this.reservatorios,
                this.unidadesGeradoras,
            );

            this.eeHidricaService.salvarEeHidrica(eeHidricaDto).subscribe( response => {
                this.snackBarService.showSuccess("Salvou")
                this.enviarFormConcluido.emit();
            });
        }
    }

    validarForm(): boolean {
        if(this.formEeHidrica.controls.tipoEmpreendimentoEnergetico.invalid){
            this.formEeHidrica.markAllAsTouched();
            this.snackBarService.showAlert("O sistema verificou que não foi selecionado nenhum tipo de Empreendimento Energético.");
            return false;
        }else if(this.formEeHidrica.invalid){
            this.formEeHidrica.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            return false;
        }else if(this.formEeHidrica.controls.possuiCanalDerivacao.value == 'sim' && this.listaTipoCanalDerivacao.length < 1){
            this.snackBarService.showAlert("O sistema verificou que não foi informado nenhum tipo de canal de derivação.");
            return false;
        }else if(this.casasDeForca.length < 1){
            this.snackBarService.showAlert("O sistema verificou que não foi informada nenhuma casa de força.");
            return false;
        }else if(this.unidadesGeradoras.length < 1){
            this.snackBarService.showAlert("O sistema verificou que não foi informada nenhuma unidade geradora.");
            return false;
        }else if(this.formEeHidrica.controls.possuiBarragem.value == 'sim' && this.barragem.length < 1 ){
            this.snackBarService.showAlert("O sistema verificou que não foi informado nenhum tipo de barragem.");
            return false;
        }else if(this.formEeHidrica.controls.possuiReservatorio.value == 'sim' && this.reservatorios.length < 1 ){
            this.snackBarService.showAlert("O sistema verificou que não foi informado nenhum tipo de reservatório.");
            return false;
        }
        return true;
    }
}
