import {BaseModel} from "./base.model";
import {EmpreendimentoObrasViarias} from "./empreendimento-obras-viarias.model";
import {RodoviasEmpObrasViarias} from "./rodovias-emp-obras-viarias.model";
import {TipoObrasEmpObrasViarias} from "./tipo-obras-emp-obras-viarias.model";
import {ObrasEspeciaisEmpObrasViarias} from "./obras-especiais-emp-obras-viarias.model";
import {TipoPavimentacaoEmpObrasViarias} from "./tipo-pavimentacao-emp-obras-viarias.model";
import {CategoriaEmpObrasViarias} from "./categoria-emp-obras-viarias.model";

export class InfraestruturaObrasViariasDto extends BaseModel {
    constructor(
        public empreendimentoObrasViarias?: EmpreendimentoObrasViarias,
        public listaRodovias?: RodoviasEmpObrasViarias[],
        public listaTipoObras?: TipoObrasEmpObrasViarias[],
        public listaObrasEspeciais?: ObrasEspeciaisEmpObrasViarias[],
        public listaPavimentacao?: TipoPavimentacaoEmpObrasViarias[],
        public listaCategorias?: CategoriaEmpObrasViarias[],
    ) {
        super();
    }

    static fromJson(json: any): InfraestruturaObrasViariasDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new InfraestruturaObrasViariasDto(
            EmpreendimentoObrasViarias.fromJson(json.empreendimentoTransporteResiduosPerigosos),
            RodoviasEmpObrasViarias.fromJsons(json.listaRodovias),
            TipoObrasEmpObrasViarias.fromJsons(json.listaTipoObras),
            ObrasEspeciaisEmpObrasViarias.fromJsons(json.listaObrasEspeciais),
            TipoPavimentacaoEmpObrasViarias.fromJsons(json.listaPavimentacao),
            CategoriaEmpObrasViarias.fromJsons(json.listaCategorias),
        );
    }

    static fromJsons(json: any): InfraestruturaObrasViariasDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(InfraestruturaObrasViariasDto.fromJson(j)));
            return resources;
        }
    }
}