import {BaseModel} from 'app/main/shared/models/base.model';
import { Documento } from './documento.model';
import { Email } from './email.model';
import { EnderecoDto } from './endereco-dto.model';
import { Telefone } from './telefone.model';

export class PessoaCompletoDto extends BaseModel {

    constructor(
        public id: number,
        public dataAtualizacao: Date,
        public cpfCnpj: string,
        public nome: string,
        public tipo: string,
        public rg: string,
        public mae: string,
        public pai: string,
        public dataNascimento: Date,
        public sexo: string,
        public profissoes: string,
        public cadastroCompleto: boolean,

        // ------------------ DADOS PESSOA JURIDICA BEGIN
        public nomeFantasia: string,
        public inscricaoMunicipal: string,
        public inscricaoEstadual: string,
        public naturezaJuridica: string,
        public porteEmpresa: string,
        public razaoSocial: string,

        public enderecos?: Array<EnderecoDto>,
        public telefones?: Array<Telefone>,
        public emails?: Array<Email>,
        public documentos?: Array<Documento>,
    ) {
        super();
    }

    static fromJson(json: any): PessoaCompletoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaCompletoDto(
            json.id,
            json.dataAtualizacao,
            json.cpfCnpj,
            json.nome,
            json.tipo,
            json.rg,
            json.mae,
            json.pai,
            json.dataNascimento,
            json.sexo,
            json.profissoes,
            json.cadastroCompleto,
            json.nomeFantasia,
            json.inscricaoMunicipal,
            json.inscricaoEstadual,
            json.naturezaJuridica,
            json.porteEmpresa,
            json.razaoSocial,
            EnderecoDto.fromJsons(json.enderecos),
            Telefone.fromJsons(json.telefones),
            Email.fromJsons(json.emails),
            Documento.fromJsons(json.documentos)
        );
    }

    static fromJsons(json: any): PessoaCompletoDto[] {
        const lista: PessoaCompletoDto[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((rt) => {
                lista.push(this.fromJson(rt));
            });
            return lista;
        }
    }

}
