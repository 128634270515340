import {Injectable, Injector} from '@angular/core';
import {ListResolver} from '../resolvers/list-resolver';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import { BitolaTipoCondutor } from '../models/bitola-tipo-condutor-linha-transmissao.model';
import { BaseEeLinhaTransmissao } from './base-ee-linha-transmissao.service';


@Injectable()
export class BitolaTipoCondutorListResolver extends ListResolver<BitolaTipoCondutor> {
    constructor(service: BitolaTipoCondutorService) {
        super(service);
    }
}

@Injectable()
export class TanqueResolver extends DetailResolver<BitolaTipoCondutor> {
    constructor(
        service: BitolaTipoCondutorService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class BitolaTipoCondutorService extends BaseEeLinhaTransmissao<BitolaTipoCondutor> {

    constructor(protected injector: Injector) {
        super(
            "/api/bitola-tip-cond-linha-transmissao",
            injector,
            BitolaTipoCondutor.prototype,
            BitolaTipoCondutor.fromJson
        );
    }

    getByLinhaTransmissao(idLinhaTransmissao: number): Observable<BitolaTipoCondutor[]> {
        const url = `${this.urlResource}/ee-linha-transmissao/${idLinhaTransmissao}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
