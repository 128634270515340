export const environment = {
    production: true,
    name: 'production',
    hmr: false,
    URL_GATEWAY: 'https://licenciamento-api.sema.mt.gov.br',
    URL_PORTAL: 'https://portal.sema.mt.gov.br',
    URL_PORTAL_API: 'https://portal-api.sema.mt.gov.br',
    URL_PANDORA: 'https://api.pandora.apps.ocp.sema.mt.gov.br',
    URL_LICENCIAMENTO: 'https://licenciamento.sema.mt.gov.br',
    URL_LICENCIAMENTO_API: 'https://licenciamento-api.sema.mt.gov.br',
    URL_LICENCIAMENTO_CONFIG_API: 'https://licenciamento-configuracao-api.sema.mt.gov.br',
    URL_ARRECADACAO_API: 'https://arrecadacao-api.sema.mt.gov.br',
    URL_GEOPORTAL: 'https://geoportal-api.sema.mt.gov.br',
    URL_GEOPORTAL_BASE_REFERENCIA: 'https://basegeo-api.sema.mt.gov.br',
    URL_HIDRICO: 'https://hidrico-api.sema.mt.gov.br',
    URL_SCORP: 'https://scorp.sema.mt.gov.br',
    URL_SCORP_API: 'https://scorp-api.sema.mt.gov.br',
    URL_BPMS: 'https://bpms.sema.mt.gov.br',
    URL_REFERENCIA: 'https://basegeo-api.sema.mt.gov.br',
    URL_GEO: 'https://geoportal-api.sema.mt.gov.br',
    URL_DOMINIO: 'https://dominios-api.sema.mt.gov.br',
    URL_DOMINIOS_API: 'https://dominios-api.sema.mt.gov.br',
    URL_LICENCIAMENTO_EMPREENDIMENTO: 'http://naoexiste.sema.mt.gov.br',
    URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO: 'https://lic-res-transp-prod-perig-api.sema.mt.gov.br',
    URL_EMPREENDIMENTO_INFRA_OBRAS_VIARIAS: 'https://lic-infra-obra-viaria-api.sema.mt.gov.br',
    URL_EMPREENDIMENTO_EE_FOTOVOLTAICA: 'https://lic-ee-fotovoltaica-api.sema.mt.gov.br',
    URL_EMPREENDIMENTO_EE_HIDRICA: 'https://lic-ee-hidrica-api.sema.mt.gov.br',
    URL_EMPREENDIMENTO_EE_EOLICA: 'https://lic-ee-eolica-api.sema.mt.gov.br',
    URL_LIC_EE_SUBESTACAO: 'https://lic-ee-subestacao-api.sema.mt.gov.br',
    URL_LICENCIAMENTO_RESIDUOS_CEMITERIO_CREMATORIO: 'https://lic-res-cemit-crematorio-api.sema.mt.gov.br',
    URL_EMPREENDIMENTO_RESIDUOS_GERAL: 'https://lic-res-geral-api.sema.mt.gov.br',
    URL_LIC_RES_CONSTR_CIVIL: 'https://lic-res-constr-civil-api.sema.mt.gov.br',
    URL_LIC_RES_GERENCIAMENTO:  'https://lic-res-gerenciamento-api.sema.mt.gov.br',
    URL_LIC_RES_TRATAM_TERM: 'https://lic-res-tratam-term-api.sema.mt.gov.br',
    URL_LIC_RES_ATERRO_IND_URBANO: 'https://lic-res-aterro-ind-urbano-api.sema.mt.gov.br',
    URL_LIC_IND_BIOCOMBUSTIVEL: 'https://lic-ind-biocombustivel-api.sema.mt.gov.br',
    URL_LIC_IND_FRIGORIFICO: 'https://lic-ind-frigorifico-api.sema.mt.gov.br',
    URL_LIC_INFRA_PARCEL_SOLO_RES_COM: 'https://lic-infra-parcel-solo-res-com-api.sema.mt.gov.br',
    URL_LIC_INFRA_OBRA_FLUV_SANEAM: 'https://lic-infra-obra-fluv-saneam-api.sema.mt.gov.br',
    URL_LIC_SERV_GERAL: 'https://lic-serv-geral-api.sema.mt.gov.br',
    URL_LIC_SERV_COMBUSTIVEL: 'https://lic-serv-combustivel-api.sema.mt.gov.br',
    URL_LIC_MINERACAO: 'https://lic-mineracao-api.sema.mt.gov.br',
    URL_LIC_AGUA_PASSIVO_SUPRESSAO: 'https://lic-agua-passivo-supressao-api.sema.mt.gov.br',
    URL_LIC_GERAL_MADEIRA: 'https://lic-geral-madeira-api.sema.mt.gov.br',
    URL_LIC_SISTEMA_TRATAMENTO: 'https://lic-sist-tratamento-api.sema.mt.gov.br',
    URL_LIC_EE_LINHA_TRANSMISSAO: 'https://lic-ee-linha-transmissao-api.sema.mt.gov.br',
    URL_GESTAO_MANIFESTACAO: 'https://gestao-manifestacao-api.sema.mt.gov.br',
    URL_LIC_FEICAO_BENFEITORIA: 'https://lic-benfeitoria-api.sema.mt.gov.br',
    URL_LIC_FEICAO_IRRIGACAO: 'https://lic-irrigacao-api.sema.mt.gov.br',
    URL_LIC_FEICAO_PECUARIA_INTENSIVA: 'https://lic-pecuaria-intensiva-api.sema.mt.gov.br',
    URL_LIC_FEICAO_AQUICULTURA: 'https://lic-aquicultura-api.sema.mt.gov.br',
    URL_LIC_EE_TERMICA: 'https://lic-ee-termica-api.sema.mt.gov.br',
    URL_LIC_AUTORIZACAO_QUEIMA_CONTROLADA: 'https://queima-controlada-api.sema.mt.gov.br',
    URL_LIC_SIMLAM_ARQUIVO: 'https://monitoramento.sema.mt.gov.br/simlam/Relatorios/PDFTitulo.aspx',
    URL_BPMS_API: 'https://bpms-api.sema.mt.gov.br',
    URL_GESTAO_MANIFESTACAO_API: 'https://gestao-manifestacao-api.sema.mt.gov.br',
    URL_GEOADMIN: 'https://geoadmin-api.sema.mt.gov.br',
    URL_WSO2_AUTH: '',
    URL_WSO2_USER_INFO: '',
    URL_CLOUD_SERVER_PORTAL: '',
    URL_CLOUD_SERVER_EUREKA: '',
    URL_CLOUD_SERVER_AUTH: '',
    UF_PADRAO: 'MT',
    OAUTH_CLIENT_KEY: 'M4xK_8Jjhf0XVdB7cpBiP0JoZ00a',
    OAUTH_CLIENT_SECRET: 'aqEtaGeG0Yc5gh4SW0g2_OeEQYYa',
    OAUTH_SCOPES: 'email openid profile address photourl picture',
    IDENTITY_COOKIE_NAME: "br.gov.mt.sema.identity", 
};
