import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[focus]'
})
export class FocusDirective {

    @Input() focus: string;

    constructor(private elementRef: ElementRef) {

    }

    private focusCore(): void {
        if (this.focus && this.focus.length) {
            const element = this.elementRef.nativeElement.ownerDocument.querySelector(this.focus);

            if (element && typeof element.focus === 'function') {
                element.focus();
            }
        }
    }

    @HostListener('click')
    onClick(): void {
        this.focusCore();            
    }
}