import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {LancamentoTaxa} from '../models/lancamento-taxa.model';
import {GuiaLancamentoTaxaDto} from '../models/guia-lancamento-taxa.dto';
import {HttpParams} from '@angular/common/http';
import {LancamentoTaxaParamCalculoDto} from "../models/lancamento-taxa-param-calculo.dto";

@Injectable({
    providedIn: 'root'
})
export class LancamentoTaxaService extends BaseService<LancamentoTaxa> {

    constructor(protected injector: Injector) {
        super(
            '/api/lancamentos-taxa',
            injector,
            LancamentoTaxa.prototype,
            LancamentoTaxa.fromJson
        );
    }

    getByRequerimentoId(requerimentoId: number): Observable<LancamentoTaxa[]> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getByLacamentoTaxaStatus(status: string): Observable<GuiaLancamentoTaxaDto> {
        const url = `${this.urlResource}`;
        if (status) {

            return this.http
                .get(url, {params: new HttpParams().append('status', status)})
                .pipe(
                    map(response => response),
                    catchError(this.handleError.bind(this))
                );
        }
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    downloadDocumentoPdfDaTaxa(
        lancamentoTaxa: LancamentoTaxa): Observable<Blob> {
        const url = `${this.urlResource}/documento-lancamento-taxa/${lancamentoTaxa.id}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    deletaTodos(lancamentoTaxaParamCalculoDto: LancamentoTaxaParamCalculoDto[]): Observable<any> {
        const url = `${this.urlResource}/deletar-todos-lac-taxa-param-calc`;
        return this.http
            .put(url, lancamentoTaxaParamCalculoDto)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    updateDTO(resource: any): Observable<any> {
        const url = `${this.urlResource}/${resource.id}`;
        return this.http.put(url, resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

}
