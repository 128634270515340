import {BaseModel} from 'app/main/shared/models/base.model';
import {ServicoCombustivel} from "./servico-combustivel.model";


export class Tanque extends BaseModel {
    constructor(
        public id?: number,
        public identificador?: string,
        public tipoTanque?: number,
        public capacidade?: number,
        public tipoIdade?: number,
        public horizontal?: boolean,
        public vertical?: boolean,
        public aereo?: boolean,
        public enterrado?: boolean,
        public servico?: ServicoCombustivel
    ) {
        super();
    }

    static fromJson(json: any): Tanque {
        if (json === undefined || json === null) {
            return null;
        }

        return new Tanque(
            json.id,
            json.identificador,
            json.tipoTanque,
            json.capacidade,
            json.tipoIdade,
            json.horizontal || !!json.posicionamento,
            json.vertical || !json.posicionamento,
            json.aereo || !!json.localizacao,
            json.enterrado || !json.localizacao,
            json.servico
        );
    }
}
