import {BaseModel} from "../base.model";

export class AutoClaveDto extends BaseModel {
    constructor(
        public id?: number,
        public modelo?: string,
        public pressaoOperacao?: number,
        public unidadeMedida?: string,
        public numeroCiclosPorDias?: number,
        public formaDeAlimentacao?: string,
        public capacidadeDaAutoclave?: number,
        public temperaturaDeEsterelizacao?: number,
        public duracaoTotalCiclos?: number,
        public capacidadeDeProcessamento?: number,
        public possuiCamara?: string,
    ) {
        super();
    }

    static fromJson(json: any): AutoClaveDto {
        json = json || {};

        return new AutoClaveDto(
            json.id,
            json.modelo,
            json.pressaoOperacao,
            json.unidadeMedida,
            json.numeroCiclosPorDias,
            json.formaDeAlimentacao,
            json.capacidadeDaAutoclave,
            json.temperaturaDeEsterelizacao,
            json.duracaoTotalCiclos,
            json.capacidadeDeProcessamento,
            json.possuiCamara,
        );
    }
}