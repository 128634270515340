import {BaseModel} from "../base.model";

export class ObjetivoParametrizacaoObjetivo extends BaseModel {
    constructor(
        public id?: number,
        public idParametroObjetivo?: number,
        public idTipoRequerimento?: number,
        public idObjetivoParametrizacao?: number,
        public isSelecaoExclusiva?: boolean,
        public ordemSelecao?: number,
        public idTituloEmitidoAlterado?: number,
        public idArrecadacao?: number,
        public idTipoObjetivo?: number,

    ) {
        super();
    }

    static fromJson(json: any): ObjetivoParametrizacaoObjetivo {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObjetivoParametrizacaoObjetivo(
            json.id,
            json.idParametroObjetivo,
            json.idTipoRequerimento,
            json.idObjetivoParametrizacao,
            json.isSelecaoExclusiva,
            json.ordemSelecao,
            json.idTituloEmitidoAlterado,
            json.idArrecadacao,
            json.idTipoObjetivo,
        );
    }

    static fromJsons(json: any): ObjetivoParametrizacaoObjetivo[] {
        const objetivos: ObjetivoParametrizacaoObjetivo[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(objetivo => objetivos.push(this.fromJson(objetivo)));
            return objetivos;
        }
    }

}
