import {BaseModel} from 'app/main/shared/models/base.model';

export class EmpreendimentoEnergTermica extends BaseModel {
    constructor(
        public id?: number,
        public idRequerimento?: number,
        public idTipoUsinaBiomassa?: number,
        public idTipoUsinaFossil?: number,
        public idSistemaConexao?: number,
        public numUnidadeGeradora?: number,
        public potenciaInstaladaPlaca?: number,
        public potenciaLiquidaDeclarada?: number,
        public potenciaInstaladaDeclarada?: number,
        public cpDenominacao?: string,
        public cpConsumo?: number,
        public cpDensidadeMetroCubico?: number,
        public cpPoderCalorificoInferior?: number,
        public cpTipoUnidadeMedida?: number,
        public caDenominacao?: string,
        public caConsumo?: number,
        public caDensidadeMetroCubico?: number,
        public caPoderCalorificoInferior?: number,
        public caTipoUnidadeMedida?: number,
        public balEletMaxGeracaoBruta?: number,
        public balEletConsServicoAuxiliar?: number,
        public balEletMaxGeracaoLiquida?: number,
        public balEletConsProcessoConexo?: number,
        public balEletPerdasAteConexao?: number,
        public balEletIntercambioRede?: number,
        public dcpTipo?: number,
        public dcpConfiguracaoGp?: boolean,
        public dcpConfiguracaoCg?: boolean,
        public dcpGeracaoCs?: boolean,
        public dcpGeracaoCb?: boolean,
        public tipoCsCtv?: boolean,
        public tipoCsTcg?: boolean,
        public tipoCbCtgr?: boolean,
        public cgTipoCs?: boolean,
        public cgTipoCb?: boolean,
        public cgTipoCsCtv?: boolean,
        public cgTipoCbCtgr?: boolean,
        public cgtipoCbCtgvr?: boolean,
        public tipoSistemaResfriamento?: number,
        public tipoReposicoesPerdasEmAgua?: number,
        public codigoReposicaoPerdasTipoAgua?: number,
        public rpaGvQtdeAgua?: number,
        public rpaMrVazaoCirculacao?: number,
        public rpaMrResposicaoPerdas?: number,
        public rpaCdVazaoCirculacao?: number,
        public rpaCdResposicaoPerdas?: number,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoEnergTermica {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoEnergTermica(
            json.id,
            json.idRequerimento,
            json.idTipoUsinaBiomassa,
            json.idTipoUsinaFossil,
            json.idSistemaConexao,
            json.numUnidadeGeradora,
            json.potenciaInstaladaPlaca,
            json.potenciaLiquidaDeclarada,
            json.potenciaInstaladaDeclarada,
            json.cpDenominacao,
            json.cpConsumo,
            json.cpDensidadeMetroCubico,
            json.cpPoderCalorificoInferior,
            json.cpTipoUnidadeMedida,
            json.caDenominacao,
            json.caConsumo,
            json.caDensidadeMetroCubico,
            json.caPoderCalorificoInferior,
            json.caTipoUnidadeMedida,
            json.balEletMaxGeracaoBruta,
            json.balEletConsServicoAuxiliar,
            json.balEletMaxGeracaoLiquida,
            json.balEletConsProcessoConexo,
            json.balEletPerdasAteConexao,
            json.balEletIntercambioRede,
            json.dcpTipo,
            json.dcpConfiguracaoGp,
            json.dcpConfiguracaoCg,
            json.dcpGeracaoCs,
            json.dcpGeracaoCb,
            json.tipoCsCtv,
            json.tipoCsTcg,
            json.tipoCbCtgr,
            json.cgTipoCs,
            json.cgTipoCb,
            json.cgTipoCsCtv,
            json.cgTipoCbCtgr,
            json.cgtipoCbCtgvr,
            json.tipoSistemaResfriamento,
            json.tipoReposicoesPerdasEmAgua,
            json.codigoReposicaoPerdasTipoAgua,
            json.rpaGvQtdeAgua,
            json.rpaMrVazaoCirculacao,
            json.rpaMrResposicaoPerdas,
            json.rpaCdVazaoCirculacao,
            json.rpaCdResposicaoPerdas,
        );
    }

    static fromJsons(json: any): EmpreendimentoEnergTermica[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(EmpreendimentoEnergTermica.fromJson(j)));
            return resources;
        }
    }
}
