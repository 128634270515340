import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Tanque} from "../models/tanque.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class TanqueService extends BaseService<Tanque> {

    constructor(protected injector: Injector) {
        super(
            "/api/tanque",
            injector,
            Tanque.prototype,
            Tanque.fromJson,
            environment.URL_LIC_SERV_COMBUSTIVEL
        );
    }

    getByServicoCombustivel(idServicoCombustivel: number): Observable<Tanque[]> {
        const url = `${this.urlResource}/servico-combustivel/${idServicoCombustivel}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
