import {BaseModel} from "./base.model";
import {EmpreendimentoEeHidrica} from "./empreendimento-ee-hidrica.model";

export class Barragem extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoEeHidrica?: EmpreendimentoEeHidrica,
        public tipo?: string,
        public descricao?: string,
        public comprimentoCrista?: number,
        public alturaBarragem?: number,
        public tempoFormacao?: number,
        public tempoResidencia?: number,
    ) {
        super();
    }

    static fromJson(json: any): Barragem {
        if (json === undefined || json === null) {
            return null;
        }
        return new Barragem(
            json.id,
            EmpreendimentoEeHidrica.fromJson(json.empreendimentoEeHidrica),
            json.tipo,
            json.descricao,
            json.comprimentoCrista,
            json.alturaBarragem,
            json.tempoFormacao,
            json.tempoResidencia,
        );
    }

    static fromJsons(json: any): Barragem[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(Barragem.fromJson(j)));
            return resources;
        }
    }
}