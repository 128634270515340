import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-dados-gerais-mineracao',
    templateUrl: './dados-gerais-mineracao.component.html',
    styleUrls: ['./dados-gerais-mineracao.component.scss']
})
export class DadosGeraisMineracaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao: boolean;
    @Input() isNaoPodeEditar: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
