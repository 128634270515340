import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {RequerimentoOutorgaFinalidade} from '../models/requerimento-outorga-finalidade.model';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RequerimentoOutorgaFinalidadeService {

    private urlResource = `${environment.URL_GATEWAY}/api/requerimento-outorga-finalidades`;

    constructor(private http: HttpClient) {
    }

    getByRequerimentoOutorgaId(requerimentoOutorgaId: number): Observable<RequerimentoOutorgaFinalidade[]> {
        const url = `${environment.URL_GATEWAY}/api/requerimentos-outorga/${requerimentoOutorgaId}/finalidades`;
        return this.http.get(url)
            .pipe(
                map((json: []) => this.jsonToResources(json)),
                catchError(e => throwError(e))
            );
    }

    update(requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade): Observable<RequerimentoOutorgaFinalidade> {
        const url = `${this.urlResource}/${requerimentoOutorgaFinalidade.id}`;
        return this.http.put(url, requerimentoOutorgaFinalidade)
            .pipe(
                map((json: any) => RequerimentoOutorgaFinalidade.fromJson(json)),
                catchError(this.handleError.bind(this))
            );
    }

    private jsonToResources(json: any): RequerimentoOutorgaFinalidade[] {
        const resources = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: any) => resources.push(RequerimentoOutorgaFinalidade.fromJson(e)));
        }
        return resources;
    }

    private handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
