import {BaseModel} from "./base.model";
import {RotasTransporteResiduosPerigosos} from "./rotas-transporte-residuos-perigosos.model";

export class RodoviasTransporteResiduosPerigosos extends BaseModel {
    constructor(
        public id?: number,
        public rotasTransporteResiduosPerigosos?: RotasTransporteResiduosPerigosos,
        public rodoviaBr?: number,
        public rodoviaMt?: number
    ) {
        super();
    }

    static fromJson(json: any): RodoviasTransporteResiduosPerigosos {
        if (json === undefined || json === null) {
            return null;
        }
        return new RodoviasTransporteResiduosPerigosos(
            json.id,
            RotasTransporteResiduosPerigosos.fromJson(json.rotasTransporteResiduosPerigosos),
            json.rodoviaBr,
            json.rodoviaMt
        );
    }

    static fromJsons(json: any): RodoviasTransporteResiduosPerigosos[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(RodoviasTransporteResiduosPerigosos.fromJson(j)));
            return resources;
        }
    }
}