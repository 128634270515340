import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Dominio} from '../models/dominio-model';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {DominioProperties} from '../models/dominio-properties';

@Injectable({
    providedIn: 'root'
})
export class DominioService {

    private readonly URL_RESOURCE: string = `${environment.URL_DOMINIOS_API}/api/dominio/tabela`;

    constructor( private http: HttpClient ) {
    }

    private getDominio(tabela: string ): Observable<Dominio[]> {

        return this.http.get<Dominio[]>( `${this.URL_RESOURCE}?tabela=${tabela}` );
    }

    private getDominioProperties(tabela: string ): Observable<DominioProperties[]> {

        return this.http.get<DominioProperties[]>( `${this.URL_RESOURCE}/properties?tabela=${tabela}` );
    }


    private getTipoCelulas(): Observable<Dominio[]> {
        return this.http.get<Dominio[]>( `${environment.URL_DOMINIOS_API}/api/tipo-celula` );
    }

    private getTipoUnidadeContigencia(): Observable<Dominio[]> {
        return this.http.get<Dominio[]>( `${environment.URL_DOMINIOS_API}/api/tipo-unidade-contigencia` );
    }

    private getTipoCombustivel(): Observable<Dominio[]> {
        return this.http.get<Dominio[]>( `${environment.URL_DOMINIOS_API}/api/tipo-combustivel` );
    }

    private getTipoBiomassa(): Observable<Dominio[]> {
        return this.http.get<Dominio[]>( `${environment.URL_DOMINIOS_API}/api/tipo-biomassa` );
    }

    private getSubTipoCombustivel(idTipo?: number): Observable<Dominio[]> {
        let tipo = idTipo !== undefined ? ('/' + idTipo) : '';
        return this.http.get<Dominio[]>( `${environment.URL_DOMINIOS_API}/api/subtipo-combustivel${tipo}`);
    }

    private getSubTipoBiomassa(idTipo?: number): Observable<Dominio[]> {
        let tipo = idTipo !== undefined ? ('/' + idTipo) : '';
        return this.http.get<Dominio[]>( `${environment.URL_DOMINIOS_API}/api/subtipo-biomassa${tipo}` );
    }

    private getUnidadeMedidaMadeira(): Observable<Dominio[]> {
        return this.http.get<Dominio[]>( `${environment.URL_DOMINIOS_API}/api/tipos-unidade-medida-materia-prima` );
    }

    private getMadeirasMateriaPrima(): Observable<Dominio[]> {
        return this.http.get<Dominio[]>( `${environment.URL_DOMINIOS_API}/api/tipos-madeira-materia-prima` );
    }

    public getTipoTransporteGerenciamentoResiduos(): Observable<Dominio[]> {

        return this.getDominio('TIPO_TRANSPORTE_GER_RESIDUOS');
    }

    public getTipoDestinacaoFinalGerenciamentoResiduos(): Observable<Dominio[]> {

        return this.getDominio('TIPO_DEST_FINAL_GER_RESIDUOS');
    }

    public getTipoReutilizacaoReciclagemRecuperacaoGerenciamentoResiduos(): Observable<Dominio[]> {

        return this.getDominio('TIPO_REU_RECI_RECU_GER_RESIDUOS');
    }

    public getTipoTratamentoGerenciamentoResiduos(): Observable<Dominio[]> {

        return this.getDominio('TIPO_TRATAMENTO');
    }

    public getTipoAcondicionamentoGerenciamentoResiduos(): Observable<Dominio[]> {

        return this.getDominio('TIPO_ACONDICIONAMENTO');
    }

    public getTipoArmazenamentoGerenciamentoResiduos(): Observable<Dominio[]> {

        return this.getDominio('TIPO_ARMAZENAMENTO');
    }

    public getTipoUnidadeMedidaResiduosGerados(): Observable<Dominio[]> {

        return this.getDominio( 'UNIDADE_MEDIDA' );
    }

    public getTipoClassificacaoResiduosGerados(): Observable<Dominio[]> {

        return this.getDominio( 'TIPO_CLASS_RESIDUOS' );
    }

    public buscarTipoCelulas(): Observable<Dominio[]> {

        return this.getTipoCelulas();
    }

    public buscarTipoUnidadeContigencia(): Observable<Dominio[]> {

        return this.getTipoUnidadeContigencia();
    }

    public buscarTipoCombustivel(): Observable<Dominio[]> {

        return this.getTipoCombustivel();
    }

    public buscarTipoBiomassa(): Observable<Dominio[]> {

        return this.getTipoBiomassa();
    }

    public buscarSubTipoCombustivel(idTipo?: number): Observable<Dominio[]> {

        return this.getSubTipoCombustivel(idTipo);
    }

    public buscarSubTipoBiomassa(idTipo?: number): Observable<Dominio[]> {

        return this.getSubTipoBiomassa(idTipo);
    }

    public getTiposMadeiraMateriaPrima(): Observable<Dominio[]> {

        return this.getDominio( 'TIPOS_MATERIA_PRIMA_MADEIRA' );
    }

    public getTiposUnidadeMedidaMadeira(): Observable<Dominio[]> {

        return this.getUnidadeMedidaMadeira();
    }

    public getMadeiraMateriaPrima(): Observable<Dominio[]> {

        return this.getMadeirasMateriaPrima();
    }

}
