import { Injectable, Injector } from '@angular/core';
import { GeradorEletrico } from '../models/gerador-eletrico.model';
import { BaseService } from "./base.service";
import {Observable} from "rxjs";
import {EmpreendimentoEnergiaEolicaTecnologia} from "../models/empreendimento-energia-eolica-tecnologia.model";
import {catchError, map} from "rxjs/operators";


@Injectable({
    providedIn: "root"
})
export class GeradorEletricoService extends BaseService<GeradorEletrico> {

    constructor(protected injector: Injector) {
        super(
            "/api/gerador-eletrico",
            injector,
            GeradorEletrico.prototype,
            GeradorEletrico.fromJson
        );
    }

    getByEmpreendimento(idEmpreendimento: number): Observable<Array<GeradorEletrico>> {
        const url = `${this.urlEeEolica}/empreendimento/${idEmpreendimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}
