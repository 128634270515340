import {NgModule} from '@angular/core';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {DocumentoRequerimentoDialogComponent} from './documento-requerimento-dialog/documento-requerimento-dialog.component';
import { SubstiruirDocumentoDialogComponent } from './substituir-documento-dialog/substituir-documento-dialog.component';

@NgModule({
    declarations: [
        DocumentoRequerimentoDialogComponent,
        SubstiruirDocumentoDialogComponent
    ],
    imports: [CoreModule, SharedModule],
    entryComponents: [
        DocumentoRequerimentoDialogComponent,
        SubstiruirDocumentoDialogComponent
    ]
})
export class DocumentosRequerimentosModule {
}
