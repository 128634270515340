import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMESSAGE, ISEVERITY } from 'app/main/core/services/core-message.service';
import { MessageDialog } from 'app/main/shared/components/message-dialog/message-dialog.model';
import { ModeloFeicao } from 'app/main/shared/enums/modelo-feicao.enum';
import { AutorizacaoSupressaoVegetacao } from '../../../../../../../shared/models/autorizacao-supressao-vegetacao.model';
import {
    FeicaoRequerimentoLicenciamentoDto
} from '../../../../../../../shared/models/feicao-requerimento-licenciamento-dto.model';
import { PessoaCorporativo } from '../../../../../../../shared/models/pessoa-corporativo.model';
import { ScorpApiService } from '../../../../../../../shared/services/scorp-api.service';
import { SnackBarService } from '../../../../../../../shared/snack-bar/snack-bar.service';
import { RequerimentoLicenciamento } from '../../../../requerimento-licenciamento.model';
import { RequerimentoLicenciamentoService } from '../../../../requerimento-licenciamento.service';
import { BaseAtividadeEmpreendimentoComponent } from '../base-atividade-empreendimento.component';
import { SupressaoVegetalService } from './supressao-vegetal.service';

@Component({
    selector: 'app-supressao-vegetal',
    templateUrl: './supressao-vegetal.component.html',
    styleUrls: ['./supressao-vegetal.component.scss']
})
export class SupressaoVegetalComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit{

    form: FormGroup;
    quadroDeAreas: FeicaoRequerimentoLicenciamentoDto[] = [];
    supressaoVegetalSalva: AutorizacaoSupressaoVegetacao = new AutorizacaoSupressaoVegetacao();
    requerimentoLicenciamento: RequerimentoLicenciamento;
    nomeDetentor: string = '';
    pessoa: PessoaCorporativo;

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private messageDialog: MessageDialog,
                private supressaoVegetalService: SupressaoVegetalService,
                private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
                private scorpService: ScorpApiService,) {
        super();
    }

    async ngOnInit() {
        this.buildForm();
        if (this.requerimento.id) {
            this.requerimentoLicenciamento =
                await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();

            this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).subscribe(feicoes => {
                this.quadroDeAreas = feicoes;
            })

            this.supressaoVegetalService.buscarPorIdRequerimento(this.requerimento.id).toPromise()
                .then(response => {
                    if (response) {
                        this.atribuirValorForm(response);
                        this.supressaoVegetalSalva = response;

                        this.buscarPessoaScorp();
                    }
                })
        }
    }

    buildForm() {
        this.form = this.formBuilder.group({
            intervencaoArea: [null, Validators.required],
            cpfCnpjDetentor: [null, Validators.required],
        });
    }

    atribuirValorForm(supressaoVegetal: AutorizacaoSupressaoVegetacao){
        this.form.get('intervencaoArea').patchValue(supressaoVegetal.intervencaoArea);
        this.form.get('cpfCnpjDetentor').patchValue(supressaoVegetal.cpfCnpjDetentor);
    }

    buscarInventarioFlorestal(){
        this.snackBarService.showAlert("Funcionalidade em desenvolvimento.")
    }

    async salvar(){
        let msgsValidacao:IMESSAGE[] = await this.validar(false);
        if(msgsValidacao.filter((m)=>m.restritiva).length == 0){
            
            if (msgsValidacao.filter((m)=>!m.restritiva).length > 0) {
                this.messageDialog.showWarning({ errors: msgsValidacao.filter((m)=>!m.restritiva).map((m)=>m.message), message: 'Atenção' }, 'SIGA');
            }
                


            this.supressaoVegetalSalva.id ? this.supressaoVegetalService.update(this.montarObjetoSupressaoDeVegetacao()).toPromise().then( () => {
                    this.snackBarService.showSuccess("Formulário salvo com sucesso.")
                    this.enviarFormConcluido.emit();
                }, () => this.snackBarService.showError("Ocorreu um erro no servidor.")) :
                this.supressaoVegetalService.create(this.montarObjetoSupressaoDeVegetacao()).toPromise().then( () => {
                    this.snackBarService.showSuccess("Formulário salvo com sucesso.")
                    this.enviarFormConcluido.emit();
                }, () => this.snackBarService.showError("Ocorreu um erro no servidor."));
        }else{
            this.form.markAllAsTouched();
            this.snackBarService.showError(msgsValidacao.map((m)=>m.message).join('\n'));
        }
    }
    
    async validar(restrict: boolean = false): Promise<IMESSAGE[]> {
        let isValid  = false;
        let msgs: IMESSAGE[] = [];



        if (!this.form.valid) {
            msgs.push({ message: "Preencha todos os campos obrigatórios.", restritiva: true, severity: ISEVERITY.ERROR });
            isValid = false;
        }
        // Houve intervencao
        // Validar RI0013  -- Feicoes quadroArea
        if (!!this.form.get('intervencaoArea').value) {
            if (this.quadroDeAreas != null && this.quadroDeAreas.length > 0 )
            {
                //Tarefa 11228 - Remover validação de dinamica de desmate e titulo referenciado.
                // RI0013
                // if (this.quadroDeAreas.find( f => f.modeloFeicaoDTO != null && f.modeloFeicaoDTO.idModeloFeicao == ModeloFeicao.DESMATE) == null) {
                //     isValid = false;
                //     msgs.push({ message: 'Se houve anteriormente uma intervenção na área é obrigatório o envio da Dinâmica de Desmate nos dados geográficos da quarta aba do Requerimento.',
                //         restritiva: restrict, severity: ISEVERITY.WARN });
                // }

            } else {
                isValid = false;
                msgs.push({ message: 'Quadro de Áreas não preenchido [Feições]', restritiva: restrict, severity: ISEVERITY.ERROR });
                
            }
        }

        return Promise.resolve(msgs);
    }

    montarObjetoSupressaoDeVegetacao(){
        return new AutorizacaoSupressaoVegetacao(this.supressaoVegetalSalva.id ? this.supressaoVegetalSalva.id : null,
            this.requerimento, this.form.get('intervencaoArea').value, this.formatarCpfCnpj(), this.pessoa.id)
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.form.get('cpfCnpjDetentor').value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    buscarPessoaScorp() {
        if(this.form.get('cpfCnpjDetentor').value){
            if(this.isCPF()){
                this.scorpService.getByPessoaFisica(this.formatarCpfCnpj()).toPromise().then(pessoaFisica => {
                    this.nomeDetentor = pessoaFisica.nome;
                    this.pessoa = pessoaFisica.pessoa;
                })
            }else{
                this.scorpService.getByPessoaJuridica(this.formatarCpfCnpj()).toPromise().then(pessoaJuridica => {
                    this.nomeDetentor = pessoaJuridica.nomeFantasia;
                    this.pessoa = pessoaJuridica.pessoa;
                })
            }
        }else {
            this.form.get('cpfCnpjDetentor').markAsTouched();
        }
    }

    formatarCpfCnpj(){
        return this.form.get('cpfCnpjDetentor').value.replace('.', '').replace('-', '').replace('/', '');
    }
}
