import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {RequerimentoOutorgaFinalidadeService} from 'app/main/shared/services/requerimento-outorga-finalidade.service';
import {CaracterizacaoConsumoAguaService} from 'app/main/shared/services/caracterizacao-consumo-agua.service';
import {CaracterizacaoConsumoAgua} from 'app/main/shared/models/caracterizacao-consumo-agua.model';
import {Mineracao} from 'app/main/shared/models/mineracao.model';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-finalidade-mineracao',
    templateUrl: './finalidade-mineracao.component.html'
})
export class FinalidadeMineracaoComponent extends BaseFinalidadeComponent implements OnInit {
    form: FormGroup;
    observacao: FormControl;
    formNovoConsumo: FormGroup;
    consumos: Array<CaracterizacaoConsumoAgua> = [];

    constructor(
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private caracterizacaoConsumoAguaService: CaracterizacaoConsumoAguaService,
        private snackBarService: SnackBarService) {
        super();
    }

    ngOnInit(): void {
        this.buildForms();
        this.carregarConsumos();
        this.patchForm();
        if (this.isModoVisualizacao) {
            this.form.disable();
            this.formNovoConsumo.disable();
            this.observacao.disable();
        }
    }

    buildForms(): void {
        this.form = this.formBuilder.group({
            id: [null],
            extracaoLeitoRio: [false],
            desmonteHidraulico: [false],
            escavacaoMeioEncosta: [false],
            escavacaoPlanice: [false],
            desmonteExplosivo: [false],
            outros: [false],
            especifiqueTipologia: [{value: null, disabled: true}, Validators.maxLength(255)],
            horas: [null, [Validators.required, Validators.max(24), Validators.min(1)]],
            consumoPerCaptaDiario: [null, Validators.required],
            consumoDiarioIndustria: [null, Validators.required],
            dias: [null, [Validators.required, Validators.max(31), Validators.min(1)]],
            numeroFuncionarios: [null, Validators.required],
            vazaoUsoDomestico: [null, [Validators.required, Validators.min(0.000001)]],
            mes: [null, [Validators.required, Validators.max(12), Validators.min(1)]],
            vazaoExploracaoMinerio: [null, [Validators.required, Validators.min(0.000001)]],
            vazaoUsoBeneficiamento: [null, [Validators.required, Validators.min(0.000001)]],
            tipoEquipamento: [null],
            aguaReutilizada: [null, Validators.required],
        });

        this.observacao = this.formBuilder.control(null, [Validators.maxLength(3000)]);

        this.formNovoConsumo = this.formBuilder.group({
            produto: [null, Validators.required],
            unidade: [null, Validators.required],
            consumoAgua: [null, Validators.required],
            producao: [null, Validators.required],
        });
    }

    private patchForm(): void {
        this.form.patchValue({
            id: this.requerimentoOutorgaFinalidade.id,
            finalidade: this.requerimentoOutorgaFinalidade.finalidade,
            requerimentoOutorga: this.requerimentoOutorgaFinalidade.requerimentoOutorga
        });

        if (this.requerimentoOutorgaFinalidade.mineracao) {
            this.form.patchValue(this.requerimentoOutorgaFinalidade.mineracao);

            if (this.requerimentoOutorgaFinalidade.mineracao.outros) {
                const field = this.form.controls.especifiqueTipologia;
                if (this.form.controls.outros.value) {
                    field.enable();
                    field.setValidators([Validators.required]);
                }

            }
        }
    }

    addConsumo(): void {
        if (!this.requerimentoOutorgaFinalidade.mineracao) {
            this.salvar(false);
            setTimeout(() => {
                if (this.requerimentoOutorgaFinalidade.mineracao && this.requerimentoOutorgaFinalidade.mineracao.id) {
                    this.createConsumo();
                }
            }, 3000);
        }else{
            this.createConsumo();
        }
    }

    /**
     * Cria um registro de consumo.
     */
    createConsumo(): void {
        this.formNovoConsumo.markAllAsTouched();
        if (this.formNovoConsumo.valid) {
            const consumo: CaracterizacaoConsumoAgua = Object.assign(new CaracterizacaoConsumoAgua(), this.formNovoConsumo.value);
            consumo.mineracao = this.requerimentoOutorgaFinalidade.mineracao;
            this.caracterizacaoConsumoAguaService
                .create(consumo)
                .subscribe(
                    () => {
                        this.snackBarService.showSuccess('Consumo adicionado com sucesso!');
                        this.carregarConsumos();
                        this.formNovoConsumo.reset();
                    },
                    (e) => this.snackBarService.showError('Erro ao adicionar consumo.', e)
                );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    removeConsumo(consumo: CaracterizacaoConsumoAgua): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: consumo.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.caracterizacaoConsumoAguaService.delete(consumo).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Consumo excluído com sucesso!');
                        this.carregarConsumos();
                    },
                    (e) => this.snackBarService.showError('Erro ao excluir consumo.', e)
                );
            }
        });
    }

    carregarConsumos(): void {
        if (this.requerimentoOutorgaFinalidade.mineracao) {
            this.caracterizacaoConsumoAguaService
                .getAllByMineracao(
                    this.requerimentoOutorgaFinalidade.mineracao
                )
                .subscribe(consumos => {
                    this.consumos = consumos;
                    if (this.requerimentoOutorgaFinalidade.cadastroConcluido !== (consumos.length > 0)) {
                        this.salvar(false);
                    }
                });
        }
    }

    salvar(showMessage: boolean = true): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.requerimentoOutorgaFinalidade.mineracao = Mineracao.fromJson(this.form.value);
            this.requerimentoOutorgaFinalidade.observacao = this.observacao.value;
            this.requerimentoOutorgaFinalidade.cadastroConcluido = (this.consumos.length > 0);

            this.requerimentoOutorgaFinalidadeService.update(this.requerimentoOutorgaFinalidade)
                .subscribe((requerimentoOutorgaFinalidade) => {
                        Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                        this.form.patchValue(requerimentoOutorgaFinalidade.mineracao);
                        this.form.markAsPristine();
                        if (showMessage) {
                            this.snackBarService.showSuccess('Registro salvo com sucesso!');
                        }
                    },
                    (e) => {
                        this.snackBarService.showError('Erro ao salvar dados.', e);
                    }
                );
        } else {
            this.snackBarService.showAlert('Preencha todos os campos obrigatórios.');
        }
    }

    outrosChanged(event): void {
        if (event) {
            const field = this.form.controls.especifiqueTipologia;
            if (this.form.controls.outros.value) {
                field.enable();
                field.setValidators([Validators.required]);
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
            }
        }
    }

    getDemandaTotal(): number {
        if (this.consumos) {
            return this.consumos.reduce(
                (total, current) => total + (current.producao * current.consumoAgua),
                0
            );
        }
        return 0;
    }

}
