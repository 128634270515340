import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Requerimento} from '../../requerimentos/requerimento.model';
import {RequerimentoService} from '../../requerimentos/requerimento.service';
import {DocumentoProcessoService} from '../../gerar-documento-publicacao/documento-processo.service';
import {SnackBarService} from '../../../shared/snack-bar/snack-bar.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {DocumentoProcesso} from "../../../shared/models/documento-processo.model";
import {DespachoService} from "../../tarefas/shared/service/despacho.service";
import {Despacho} from "../../../shared/components/despacho-cabecalho/despacho.model";
import {RedirectUtil} from "../../../shared/util/RedirectUtil";
import {AnaliseLicencaPreviaConsemaService} from "../../../shared/services/analise-licenca-previa-consema.service";
import {AnaliseLicencaPreviaConsemaAnexo} from "../../../shared/models/analise-licenca-previa-consema-anexo.model";
import {AnaliseLicencaPreviaConsemaAnexoService} from "../../../shared/services/analise-licenca-previa-consema-anexo.service";
import {AnaliseLicencaPreviaConsema} from "../../../shared/models/analise-licenca-previa-consema.model";
import {Complementacao} from "../../tarefas/complementacao/complementacao.model";

@Component({
    selector: 'app-referendar-licenca-previa',
    templateUrl: './referendar-licenca-previa.component.html',
    styleUrls: ['./referendar-licenca-previa.component.scss'],
})
export class ReferendarLicencaPreviaComponent implements OnInit {

    @Input() requerimento = new Requerimento();
    @Input() idTarefa: string;
    formLicencaPreviaConsema: FormGroup;
    formAnexarDocumento: FormGroup;
    formDespacho: FormGroup;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @Output() enviarListaArquivos = new EventEmitter<any[]>();
    displayedColumns: string[] = ['documento', 'numero', 'analista', 'acao'];
    arquivo: File;
    nomeArquivoSelecionado: string = '';
    dataSourceAnexarDocumento = new MatTableDataSource<any>([]);
    displayedColumnsAnexo: string[] = ['tituloDocumento', 'acao'];
    documentoTecnico: DocumentoProcesso[] = [];
    tableDocumentoTecnico: MatTableDataSource<DocumentoProcesso>;
    idAnexo: number = 0;
    anexosSalvos: any[] = [];
    parecerTecnicoConsema: AnaliseLicencaPreviaConsema;
    despacho: Despacho;
    complementacao: Complementacao;

    constructor(
        private formBuilder: FormBuilder,
        protected requerimentoService: RequerimentoService,
        private documentosProcessoService: DocumentoProcessoService,
        private analiseLicencaPreviaConsemaService: AnaliseLicencaPreviaConsemaService,
        private analiseLicencaPreviaConsemaAnexoService: AnaliseLicencaPreviaConsemaAnexoService,
        private despachoService: DespachoService,
        private snackBar: SnackBarService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        await this.buildResourceForm();
        this.carregarTabelaDocumentosTecnicos();
    }

    protected buildResourceForm(): void {
        this.formLicencaPreviaConsema = this.formBuilder.group({
            id: [null],
            decisao: [null, [Validators.required]],
        });
        this.formDespacho = this.formBuilder.group({
            tarefaId: [null],
            requerimento: [null],
            nomeTarefa: [null],
            textoDespacho: ['', [Validators.required, Validators.min(0), Validators.max(4000)]],
        });

        this.formAnexarDocumento = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            arquivo: [null],
            tituloDocumento: [null, [Validators.required]],
        })
    }

    carregarTabelaDocumentosTecnicos(): void {
        this.documentosProcessoService.buscarTodosPorIdRequerimento(this.requerimento.id).toPromise().then(result => {
            this.documentoTecnico = result;
            this.tableDocumentoTecnico = new MatTableDataSource(this.documentoTecnico);
            this.tableDocumentoTecnico.paginator = this.paginator;
            this.tableDocumentoTecnico.sort = this.sort;
        })
    }

    uploadFile(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        const fileList: FileList | null = element.files;
        if (fileList) {
            this.nomeArquivoSelecionado = fileList[0].name;
            this.formAnexarDocumento.controls.arquivo.patchValue(fileList[0])
        }
    }

    mapearArray(): any {
        if (this.anexosSalvos.length == 0) {
            return this.anexosSalvos;
        } else {
            return this.anexosSalvos.map(function (item) {
                if (item.id == null) {
                    return new AnaliseLicencaPreviaConsemaAnexo(
                        item.id,
                        item.arquivo,
                        item.tituloDocumento,
                    );
                }
            });
        }
    }

    anexarArquivo() {
        if (this.formAnexarDocumento.valid) {
            this.anexosSalvos.push({
                id: null,
                idTemp: this.idAnexo,
                arquivo: this.formAnexarDocumento.controls.arquivo.value,
                tituloDocumento: this.formAnexarDocumento.controls.tituloDocumento.value,
            })
            this.idAnexo++;
            this.dataSourceAnexarDocumento = new MatTableDataSource<any>(this.anexosSalvos);
            this.formAnexarDocumento.reset();
            this.nomeArquivoSelecionado = '';
        }
    }

    abrirPdf(idAnexo?: number, file?: any, tipoAnexo?: any) {
        if (tipoAnexo) {
            window.open(`${this.analiseLicencaPreviaConsemaAnexoService.urlResource}/visualizar-doc/${idAnexo}`);
        } else {
            window.open(URL.createObjectURL(new Blob([file], {type: 'application/pdf'})));
        }

    }

    removerArquivo(item, itemId?: number, tipoAnexo?: string) {
        if (itemId != null) {
            if (tipoAnexo) {
                this.analiseLicencaPreviaConsemaAnexoService.delete(itemId).subscribe(() => {
                });
            }
        } else {
            let data;
            data = this.anexosSalvos.filter((value, key) => {
                return value.idTemp != item;
            });
            this.anexosSalvos = data;
            this.dataSourceAnexarDocumento = new MatTableDataSource(data);
        }
    }

    retornaAnaliseConsema() {
        const analiseLicencaPreviaConsema = new AnaliseLicencaPreviaConsema();
        analiseLicencaPreviaConsema.requerimento = this.requerimento;
        analiseLicencaPreviaConsema.despacho = this.retornaDespacho();
        analiseLicencaPreviaConsema.decisao = this.formLicencaPreviaConsema.get('decisao').value;

        return analiseLicencaPreviaConsema;
    }

    retornaDespacho() {
        const despacho = new Despacho();
        despacho.descricao = this.formDespacho.get('textoDespacho').value;
        return despacho;
    }

    concluirTarefaAnexoAnaliseConsema(completar = true) {
        if (this.formLicencaPreviaConsema.valid && this.anexosSalvos.length > 0 && this.formDespacho.valid) {
            this.analiseLicencaPreviaConsemaService.salvarPareceTecnicoLicencaPreviaConsema(this.retornaAnaliseConsema(), this.idTarefa, completar).toPromise().then(result => {
                this.anexosSalvos.forEach(async arquivo => {
                    arquivo.parecerTecnicoConsema = result;

                    const formData = new FormData();
                    formData.append('file', arquivo.arquivo);
                    const analiseLicencaPreviaConsema = new Blob([JSON.stringify(result)], {type: 'application/json'});
                    formData.append('analiseLicencaPreviaConsema', analiseLicencaPreviaConsema);

                    this.analiseLicencaPreviaConsemaAnexoService.salvarAnaliseLicencaPreviaConsemaAnexo(formData).subscribe(result => {
                        arquivo.id = result.id;
                        this.snackBar.showSuccess('Parecer técnico com licença prévia salvo com sucesso');
                        this.formLicencaPreviaConsema.reset()
                        RedirectUtil.redirecionaDashboardCamunda();
                    });
                })
            });
        } else {
            this.snackBar.showAlert('Preencha os campos obrigatórios!')
            this.formLicencaPreviaConsema.markAllAsTouched();
            this.formAnexarDocumento.markAllAsTouched();
            this.formDespacho.markAllAsTouched();
        }
    }

    cancelarTarefa(): void {
        RedirectUtil.redirecionaDashboardCamunda();
    }

}
