import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {BehaviorSubject, Observable} from 'rxjs';
import {TipoProcesso} from '../../tipos-processo/tipo-processo.model';
import {TipoProcessoService} from '../../tipos-processo/tipo-processo.service';
import {Requerimento} from '../requerimento.model';
import {RequerimentoService} from '../requerimento.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {RequerimentoWrapper} from '../requerimento-wrapper.model';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmacaoLicenciamentoDialogComponent} from './confirmacao-licenciamento-dialog/confirmacao-licenciamento-dialog.component';
import {
    DialogRequerimentoExistenteComponent,
    DialogRequerimentoExistenteResult
} from './dialog-requerimento-existente/dialog-requerimento-existente.component';
import {SnackBarService} from '../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-requerimento-new',
    templateUrl: 'requerimento-new.component.html',
    styleUrls: ['requerimento-new.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoNewComponent implements OnInit {
    requerimento: Requerimento = new Requerimento();
    tiposDeProcesso: Array<TipoProcesso>;
    isMobile;

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading = this.loadingSubject.asObservable();

    constructor(
        protected service: RequerimentoService,
        private tipoProcessoService: TipoProcessoService,
        private router: Router,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
    ) {
        iconRegistry.addSvgIcon(
            'tree',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/tree.svg'));
        iconRegistry.addSvgIcon(
            'water',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/water.svg'));
    }

    ngOnInit(): void {
        this.isMobile = (window.innerWidth <= 900);
        this.carregarTiposDeProcesso().subscribe(tiposDeProcesso => {
            this.tiposDeProcesso = tiposDeProcesso;
        });
    }

    onResize(event): void {
        this.isMobile = (event.target.innerWidth <= 900);
    }

    tratarTipoProcessoClick(tipoProcesso: TipoProcesso): void {

        if (tipoProcesso.tipo === 'LICENCIAMENTO' && tipoProcesso.id != 3) {
            const dialogRef = this.dialog.open(ConfirmacaoLicenciamentoDialogComponent, {width: '500px'});

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.criarRequerimento(tipoProcesso);
                }
            });
        } else if (tipoProcesso.tipo === 'OUTORGA') {
            const dialogRef = this.dialog.open<DialogRequerimentoExistenteComponent, any,
                DialogRequerimentoExistenteResult | null>(DialogRequerimentoExistenteComponent, {});
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (!result.possuiProtocoloExistente) {
                        this.criarRequerimento(tipoProcesso);
                    } else {
                        this.service.createComTipoRequerimento(result.tipoRequerimento,
                            tipoProcesso, result.numeroProtocolo).subscribe((res: RequerimentoWrapper) => {
                            this.navigateToRequerimento(res);
                        }, error => this.snackBarService.showError(null, error));
                    }
                }
            });
        } else {
            this.criarRequerimento(tipoProcesso);
        }

    }

    criarRequerimento(tipoProcesso: TipoProcesso): void {
        this.requerimento = new Requerimento();
        this.requerimento.tipoProcesso = tipoProcesso;

        this.service.createWithTaskReturn(this.requerimento).subscribe((res: RequerimentoWrapper) => {
            this.navigateToRequerimento(res);
        });
    }

    private carregarTiposDeProcesso(): Observable<any> {
        return this.tipoProcessoService.getAll();
    }

    navigateToRequerimento(requerimento: RequerimentoWrapper): void {
        this.requerimento = requerimento.requerimento;
        this.router.navigateByUrl(`/requerimentos/${this.requerimento.tipoProcesso.tipo.toLowerCase()}/${this.requerimento.id}/edit/${requerimento.tarefaId}`);
    }

    voltar(): void {
        this.router.navigateByUrl('/requerimentos');
    }
}