import {Ponto} from "../../../models/hidrico-models/ponto.model";

export class PocoObservacao {
    constructor(
        public id?: number,
        public latitude?: string,
        public longitude?: string,
        public datum?: string,
        public diametroPerfuracao?: number,
        public diametroRevestimento?: number,
        public distanciaPocoCaptacao?: number,
        public ponto?: Ponto,
    ) {
    }

    static fromJson(json: any): PocoObservacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new PocoObservacao(
            json.id,
            json.latitude,
            json.longitude,
            json.datum,
            json.diametroPerfuracao,
            json.diametroRevestimento,
            json.distanciaPocoCaptacao,
            Ponto.fromJson(json.ponto),
        );
    }
}
