import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {AbstractComponenteRelacionamento} from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {MateriaPrimaIndBiocombustivel} from "../../../../../../../../shared/models/materia-prima-ind-biocombustivel.model";
import {MateriaPrimaIndBiocombustivelService} from "../../../../../../../../shared/services/materia-prima-ind-biocombustivel.service";

@Component({
    selector: 'app-materia-prima-ind-biocombustivel',
    templateUrl: './materia-prima-ind-biocombustivel.component.html',
    styleUrls: ['./materia-prima-ind-biocombustivel.component.scss']
})
export class MateriaPrimaIndBiocombustivelComponent extends AbstractComponenteRelacionamento<MateriaPrimaIndBiocombustivel, MateriaPrimaIndBiocombustivelService> implements OnInit {

    protected TIPO_OUTRO = 'Outros';

    @Input() materiasPrima: Array<DominioDto> = [];
    @Input() isNaoPodeEditar: boolean;
    @ViewChild('insideForm', {static: false}) insideForm: NgForm;

    novaMateriaPrima: DominioDto;

    constructor() {
        super();
    }

    async ngOnInit() {
        super.ngOnInit();
    }

    getDisplayedColumns(): string[] {
        return ['produto', 'descricao', 'cogeracaoEnergia', 'materiaPrima', "descricaoMP", 'acoes'];
    }

    getMateriaPrima(id) {
        return this.materiasPrima.find(mp => mp.id === id);
    }

    refresh() {
        super.refresh();
        this.novaMateriaPrima = undefined;
    }

    async editaLinha(item: any, index: number): Promise<void> {
        await super.editaLinha(item, index);
        this.novaMateriaPrima = this.getMateriaPrima(item.vinculo.idMateriaPrima);
    }

    protected validaLinha() {
        return super.validaLinha() && this.novaMateriaPrima && this.materiasPrima.some(d => d.id === this.novoDado.vinculo.idMateriaPrima);
    }

    protected atualizaVinculo() {
        super.atualizaVinculo();
        this.novoDado.vinculo.idMateriaPrima = this.novaMateriaPrima.id;
    }

    isBotaoAdicionarDisabled() {
        return super.isBotaoAdicionarDisabled() || !this.novaMateriaPrima ||
            (this.novaMateriaPrima && this.novaMateriaPrima.descricao === this.TIPO_OUTRO && !this.novoDado.vinculo.descricaoMateriaPrima)
    }

    showDescricaoMateriaPrima() {
        return this.novaMateriaPrima && this.novaMateriaPrima.descricao === this.TIPO_OUTRO;
    }

}
