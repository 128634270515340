import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-ajuda-forma-ocupacao',
  templateUrl: './dialog-ajuda-forma-ocupacao.component.html',
  styleUrls: ['./dialog-ajuda-forma-ocupacao.component.scss']
})
export class DialogAjudaFormaOcupacaoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogAjudaFormaOcupacaoComponent>) { }

  ngOnInit() {
  }

}
