import {NgModule} from '@angular/core';
import {MatFormFieldModule, MatRadioModule, MatSelectModule, MatTableModule} from '@angular/material';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {RequerimentoLocalizacaoComponent} from './requerimento-localizacao.component';
import {RelacaoImoveisEncontradosDialogComponent} from './shared/dialog/relacao-imoveis-encontrados-dialog.component';
import { DialogAjudaFormaOcupacaoComponent } from './shared/dialog-ajuda-forma-ocupacao/dialog-ajuda-forma-ocupacao.component';

@NgModule({
    declarations: [RequerimentoLocalizacaoComponent, RelacaoImoveisEncontradosDialogComponent, DialogAjudaFormaOcupacaoComponent],
    imports: [
        CoreModule,
        SharedModule,
        MatFormFieldModule,
        MatSelectModule,
        MatRadioModule,
        MatTableModule
    ],
    exports: [RequerimentoLocalizacaoComponent],
    entryComponents: [RequerimentoLocalizacaoComponent, RelacaoImoveisEncontradosDialogComponent, DialogAjudaFormaOcupacaoComponent]
})
export class RequerimentoLocalizacaoModule {
}
