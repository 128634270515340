import {Component, Input, OnInit} from '@angular/core';
import {
    EmpreendimentoEstruturaSuporte
} from "../../../../../../../../shared/models/empreendimento-estrutura-suporte.model";
import {FormControl, FormGroup} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {
    EmpreendimentoEstruturaSuporteService
} from "../../../../../../../../shared/services/empreendimento-estrutura-suporte.service";

@Component({
    selector: 'app-material-estruturas-suporte',
    templateUrl: './material-estruturas-suporte.component.html',
    styleUrls: ['./material-estruturas-suporte.component.scss']
})
export class MaterialEstruturasSuporteComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
    private $idEmpreendimento: number;

    materiaisEstruturasSuporte: FormControl;

    displayedColumnsEstruturaSuporte: string[] = ['tipo', 'descricao', 'acoes'];

    tipos: DominioDto[] = [];
    tipoSelecionado: DominioDto;
    descricao: string;

    constructor(private dominiosService: DominiosService,
                private estruturasSuporteService: EmpreendimentoEstruturaSuporteService) {
    }

    async ngOnInit() {
        this.materiaisEstruturasSuporte = this.form.get('estruturasSuporte') as FormControl;
        this.dominiosService.getDominio("TIPO_ESTRUTURA_SUPORTE")
            .subscribe(tipos => this.tipos = tipos);
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    @Input() set idEmpreendimento(idEmpreendimento: number) {
        if (idEmpreendimento !== this.$idEmpreendimento) {
            this.$idEmpreendimento = idEmpreendimento;

            if (this.$idEmpreendimento) {
                this.estruturasSuporteService.getByEmpreendimento(this.$idEmpreendimento)
                    .subscribe(itens => this.materiaisEstruturasSuporte.setValue(itens));
            }
        }
    }

    excluirMaterialEstruturaSuporte(index: number) {
        if (index > -1) {
            this.materiaisEstruturasSuporte.value.splice(index, 1);
            this.materiaisEstruturasSuporte.setValue([...this.materiaisEstruturasSuporte.value]);
        }
    }

    addMaterialEstruturaSuporte() {

        if (this.tipoSelecionado && !this.materiaisEstruturasSuporte.value.some(f => f.estruturaSuporte === this.tipoSelecionado.id && f.descricao === this.descricao)) {
            this.materiaisEstruturasSuporte.value.push(EmpreendimentoEstruturaSuporte.fromJson({
                estruturaSuporte: this.tipoSelecionado.id,
                descricao: this.tipoSelecionado.descricao === 'Outro' ? this.descricao : undefined,
            }));

            this.materiaisEstruturasSuporte.setValue([...this.materiaisEstruturasSuporte.value]);

            this.tipoSelecionado = undefined;
            this.descricao = undefined;
        }
    }

    verificaBotaoAdicionarDesabilitado() {
        return !this.tipoSelecionado ||
            (this.tipoSelecionado && this.tipoSelecionado.descricao === 'Outro' && !this.descricao);
    }

    getDescricao(id) {
        return this.tipos.find(tp => tp.id === id).descricao;
    }
}
