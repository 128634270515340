import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {MatDialog} from '@angular/material';
import {TermoReferenciaService} from 'app/main/pages/termos-referencia/termo-referencia.service';
import {TermoReferenciaRequerimento} from 'app/main/shared/models/termo-referencia-requerimento.model';
import {ItemTermoReferencia} from 'app/main/pages/itens-termos-referencia/item-termo-referencia.model';
import {
    DocumentoRequerimento,
    TipoDocumentoRequerimento
} from 'app/main/pages/documentos-requerimentos/documento-requerimento.model';
import {DocumentoRequerimentoService} from 'app/main/pages/documentos-requerimentos/documento-requerimento.service';
import {ItemTermoReferenciaInformacoesAdicionaisDialogComponent} from 'app/main/pages/itens-termos-referencia/item-termo-referencia-informacoes-adicionais-dialog/item-termo-referencia-informacoes-adicionais-dialog.component';
import {ItemTermoReferenciaRequerimento} from 'app/main/shared/models/item-termo-referencia-requerimento.model';
import {Requerimento} from '../../../pages/requerimentos/requerimento.model';
import {EspelhoOutorgaDto} from '../../../pages/requerimentos/requerimento-outorga/espelho-outorga-dto.model';
import {RequerimentoOutorga} from '../../../pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import {RequerimentoService} from '../../../pages/requerimentos/requerimento.service';
import {RequerimentoOutorgaService} from '../../../pages/requerimentos/requerimento-outorga/requerimento-outorga.service';
import {DocumentoRequerimentoDialogComponent} from '../../../pages/documentos-requerimentos/documento-requerimento-dialog/documento-requerimento-dialog.component';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {SnackBarService} from '../../snack-bar/snack-bar.service';
import {FormularioReqLicenciamentoDto} from '../../models/formulario-req-licenciamento.dto';
import {RequerimentoFormularioLicenciamentoService} from '../../../pages/requerimentos/requerimento-licenciamento/requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {MatTableDataSource} from '@angular/material/table';
import {JuntadaProcessoService} from '../../services/juntada-processo.service';
import {JuntadaProcesso} from '../../models/juntada-processo.model';
import { EnumSituacaoProcesso } from '../../enums/situacao-processo.enum';
import {DocumentoProcessoService} from "../../../pages/gerar-documento-publicacao/documento-processo.service";
import {DocumentoProcesso} from "../../models/documento-processo.model";
import {AuthenticationService} from "../../../core/services/authentication.service";
import {ArquivoService} from "../../services/arquivo.service";
import {TodosDocumentosDto} from "../../models/todos-documentos-dto.model";
import {GestaoManifestacaoService} from "../../services/gestao-manifestacao-service/gestao-manifestacao.service";

@Component({
    selector: 'app-aba-documentacao-complementar',
    templateUrl: 'aba-documentacao-complementar.component.html',
    styleUrls: ['aba-documentacao-complementar.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AbaDocumentacaoComplementarComponent implements OnInit, OnChanges {

    @Input() requerimento = new Requerimento();
    @Input() idTarefa: string;

    termosReferencia: TermoReferenciaRequerimento[] = [];
    termosReferenciaFiltered: TermoReferenciaRequerimento[] = [];
    documentos: DocumentoRequerimento[] = [];
    filterString = '';
    espelhoOutorgaDto: EspelhoOutorgaDto;
    requerimentoOutorga: RequerimentoOutorga;
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoDto;
    dataSourceJuntadaProcesso = new MatTableDataSource<any>([]);
    dataSourceTodosDocumentos = new MatTableDataSource<any>([]);
    data: any[] = [];
    idTemp = 0;
    isResponsavel = false
    isServidor = false;
    usuarioLogado: any

    constructor(
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected service: RequerimentoService,
        protected termoReferenciaService: TermoReferenciaService,
        protected documentoRequerimentoService: DocumentoRequerimentoService,
        private requerimentoOutorgaService: RequerimentoOutorgaService,
        protected requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private juntadaProcessoService: JuntadaProcessoService,
        private documentoProcessoService: DocumentoProcessoService,
        private requerimentoService: RequerimentoService,
        private authenticationService: AuthenticationService,
        private arquivoService: ArquivoService,
        private gestaoManifestacaoService: GestaoManifestacaoService
    ) {
    }

    ngOnInit(): void {
        if (this.requerimento) {
            this.carregaJuntadaProcessoSalva(this.requerimento);
            this.listarResponsaveisTecnicos()
        }
    }

    listarResponsaveisTecnicos(): void {
        this.authenticationService.currentUser.subscribe(user => {
            this.usuarioLogado = user;
            this.isServidor = user.servidor;
        });

        this.requerimentoService
            .listarResponsaveisTecnicos(this.requerimento.id).subscribe((content) => {
            if (content.find(r => r.cpfCnpj === this.usuarioLogado.sub)) {
                this.isResponsavel = true
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
                this.requerimentoOutorgaService.getEspelhoOutorga(this.requerimento).subscribe(espelhoOutorgaDto => {
                    this.espelhoOutorgaDto = espelhoOutorgaDto;
                    this.requerimentoOutorga = this.espelhoOutorgaDto.requerimentoOutorga;
                    this.recuperarTermosDeReferencia();
                });
            } else if (this.requerimento.tipoProcesso.tipo === 'LICENCIAMENTO') {
                this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId
                (this.requerimento.id).subscribe(formularioReqLicenciamentoDto => {
                    this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                    this.recuperarTermosDeReferencia();
                });
            } else if (this.requerimento.tipoProcesso.tipo === 'ESTUDO_IMPACTO_AMBIENTAL_EIA_RIMA') {
                this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId
                (this.requerimento.id).subscribe(formularioReqLicenciamentoDto => {
                    this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                    this.recuperarTermosDeReferencia();
                });
            }

            this.buscarTodosDocumentos();
        }
    }

    private recuperarTermosDeReferencia(): void {
        if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
            if (this.requerimentoOutorga) {
                this.termoReferenciaService.getByRequerimentoOutorgaId(this.requerimentoOutorga.id).subscribe(termosReferencia => {
                    this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
                    this.termosReferenciaFiltered = termosReferencia;
                    this.listarDocumentos();
                });
            }
        } else if (this.requerimento.tipoProcesso.tipo === 'LICENCIAMENTO') {
            this.termoReferenciaService.getByRequerimentoLicenciamentoId(this.formularioReqLicenciamentoDto.requerimentoLicenciamento.id).subscribe(termosReferencia => {
                this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
                this.termosReferenciaFiltered = termosReferencia;
                this.listarDocumentos();
            });
        } else if (this.requerimento.tipoProcesso.tipo === 'ESTUDO_IMPACTO_AMBIENTAL_EIA_RIMA') {
            this.termoReferenciaService.getByRequerimentoLicenciamentoId(this.formularioReqLicenciamentoDto.requerimentoLicenciamento.id).subscribe(termosReferencia => {
                this.termosReferencia = termosReferencia.filter(termo => termo.itens && termo.itens.length > 0);
                this.termosReferenciaFiltered = termosReferencia;
                this.listarDocumentos();
            });
        }
    }

    descricaoFromTipoItemTermoReferencia(tipoItemTermoReferencia: string): string {
        return ItemTermoReferencia.tipos.find(t => t.valor === tipoItemTermoReferencia).descricao;
    }

    documentosByItem(item: ItemTermoReferenciaRequerimento): DocumentoRequerimento[] {
        return this.documentos.filter(d => d.tipo === 'ITEM_TERMO_REFERENCIA' && d.itemTermoReferenciaRequerimento.id === item.id);
    }

    documentosByTipo(tipo: string): DocumentoRequerimento[] {
        return this.documentos.filter(d => d.tipo === tipo);
    }

    novoDocumentoDialog(itemTermoReferenciaRequerimento: ItemTermoReferenciaRequerimento, tipo: string): void {
        this.documentoDialog(new DocumentoRequerimento(null, null, null, this.requerimento, itemTermoReferenciaRequerimento, false, tipo));
    }

    documentoDialog(documentoRequerimento: DocumentoRequerimento): void {
        const dialogRef = this.dialog.open(
            DocumentoRequerimentoDialogComponent,
            {
                width: '600px',
                data: documentoRequerimento
            }
        );
        dialogRef.afterClosed().subscribe(async result => {
            if (!!result) {
                await this.listarDocumentos();
                if (this.requerimento) {
                    this.carregaJuntadaProcessoSalva(this.requerimento);
                }
            }
        });
    }

    excluirDocumentoDialog(juntadaProcesso: any): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: juntadaProcesso.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirDocumento(juntadaProcesso);
            }
        });
    }

    private excluirDocumento(juntadaProcesso: any): void {
        if (juntadaProcesso.id) {
            this.juntadaProcessoService
                .delete(juntadaProcesso.id)
                .subscribe(() => {
                    this.snackBarService.showSuccess('Registro excluido com sucesso.');
                    this.listarDocumentos();
                    if (this.requerimento) {
                        this.carregaJuntadaProcessoSalva(this.requerimento);
                    }
                });
        }

        let data;
        data = this.data.filter((value) => {
            return value.idTemp !== juntadaProcesso.idTemp;
        });
        this.data = data;
        this.dataSourceJuntadaProcesso = new MatTableDataSource(this.data);
    }

    informacoesAdicionais(itemTermoReferenciaRequerimento: ItemTermoReferenciaRequerimento): void {
        this.dialog.open(
            ItemTermoReferenciaInformacoesAdicionaisDialogComponent,
            {width: '600px', data: itemTermoReferenciaRequerimento.itemTermoReferencia}
        );
    }

    buscarDocumentos(): void {
        if (this.filterString === '') {
            this.limparFiltro();
        } else {
            this.termosReferenciaFiltered = [];

            this.termosReferencia.forEach(termo => {
                const tempTermo = TermoReferenciaRequerimento.fromJson(termo);

                tempTermo.itensPorTipo = tempTermo.itensPorTipo.filter(itemPorTipo => {
                    itemPorTipo.itens = itemPorTipo.itens.filter(item => {
                            return item.itemTermoReferencia.descricao.toLowerCase().includes(this.filterString.toLowerCase());
                        }
                    );
                    return itemPorTipo.itens != null && itemPorTipo.itens.length > 0;
                });

                if (tempTermo.itensPorTipo != null && tempTermo.itensPorTipo.length > 0) {
                    this.termosReferenciaFiltered.push(tempTermo);
                }
            });
        }
    }

    limparFiltro(): void {
        this.filterString = '';
        this.termosReferenciaFiltered = this.termosReferencia;
    }

    listarDocumentos(): void {
        this.documentoRequerimentoService
            .getByRequerimento(this.requerimento, [TipoDocumentoRequerimento.ITEM_TERMO_REFERENCIA, TipoDocumentoRequerimento.JUNTADA_PROCESSO])
            .subscribe(documentos => (this.documentos = documentos));
    }

    async buscarTodosDocumentos() {
        const todosDocumentosProcessoLicenciamento = await this.documentoProcessoService.buscarTodosDocumentosDoProcesso(this.requerimento.id).toPromise();
        // const documentosAnexoSolicitacaoManifestacao = await this.gestaoManifestacaoService.buscarAnexosSolicitacaoPorRequerimento(this.requerimento.id).toPromise();
        // const documentosAnexoRespostaManifestacao = await this.gestaoManifestacaoService.buscarAnexosRespostaPorRequerimento(this.requerimento.id).toPromise();

        this.dataSourceTodosDocumentos = new MatTableDataSource(todosDocumentosProcessoLicenciamento);
    }

    visualizarDocumento(documentoRequerimento: DocumentoRequerimento): void {
        window.open(`${this.documentoRequerimentoService.urlResource}/${documentoRequerimento.id}/visualizar`);
    }

    downloadDocumento(documentoRequerimento: DocumentoRequerimento): void {
        window.open(`${this.documentoRequerimentoService.urlResource}/${documentoRequerimento.id}/download`);
    }

    downloadDocumentoJuntada(juntadaProcesso: any): void {
        window.open(`${this.juntadaProcessoService.urlResource}/${juntadaProcesso.id}/download`);
    }

    visualizarDocumentoJuntada(juntadaProcesso: any): void {
        window.open(`${this.juntadaProcessoService.urlResource}/${juntadaProcesso.id}/visualizar`);
    }

    carregaJuntadaProcessoSalva(requerimento: any): void {
        this.juntadaProcessoService.buscaListaJuntadaPorIdRequerimento(requerimento.id).subscribe(result => {
            this.dataSourceJuntadaProcesso = new MatTableDataSource(result);
        });
    }

    isVisualizaJuntadaDocumentos() {
        return this.requerimento.situacaoProcesso === 'AGUARDANDO_ANALISE'
            || this.requerimento.situacaoProcesso === 'EM_ANALISE'
            || this.requerimento.situacaoProcesso === 'AGUARDANDO_ACEITE_ATRIBUICAO'
            || this.requerimento.situacaoProcesso === 'AGUARDANDO_ATRIBUICAO'
            || this.requerimento.situacaoProcesso === 'CONCLUIDO'
    }

    visualizarDocumentoProcesso(item: TodosDocumentosDto): void {
        window.open(`${this.arquivoService.urlResource}/${item.chave}`);
    }

    downloadDocumentoProcesso(item: TodosDocumentosDto): void {
        window.open(`${this.arquivoService.urlResource}/${item.chave}/download`);
    }
}