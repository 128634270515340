import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Requerimento} from '../../requerimento.model';
import {TitulacaoDocumentosDTO} from './titulacao-documentos-dto.model';
import {TitulacaoDocumentosService} from './titulacao-documentos.service';
import {CurrentUser} from "../../../../core/models/current-user.model";
import {OrganizacaoAdministrativaCorporativoService} from "../../../../shared/services/organizacao-administrativa-corporativo.service";
import {AuthenticationService} from "../../../../core/services/authentication.service";
import {UtilService} from "../../../../core/services/util.service";
import * as moment from 'moment';
import {EmissaoTituloService} from "../../../tarefas/shared/service/emissao-titulo.service";
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-aba-titulacoes-pareceres',
    templateUrl: './aba-titulacoes-pareceres.component.html',
    styleUrls: ['./aba-titulacoes-pareceres.component.scss']
})
export class AbaTitulacoesPareceresComponent implements OnInit, OnChanges {

    @Input() requerimento = new Requerimento();

    titulacaoDocumentosDTOS: Array<TitulacaoDocumentosDTO> = [];
    isPerfilPermitido: any

    constructor(
        private titulacaoDocumentosService: TitulacaoDocumentosService,
        private organizacaoAdministrativaCorporativoService: OrganizacaoAdministrativaCorporativoService,
        private authenticationService: AuthenticationService,
        private utilService: UtilService,
        private emissaoTituloService: EmissaoTituloService,
        private snackBarService: SnackBarService) {
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        const usuario: CurrentUser = this.authenticationService.currentUserDecode;
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            this.carregarVerificacaoDePerfil(usuario.sub)
            this.carregarDocumentos();
        }
    }

    carregarVerificacaoDePerfil(cpf) {
        this.organizacaoAdministrativaCorporativoService
            .isPerfilSuperintendenciaOrGabinete(cpf).subscribe(isPerfil => {
            this.isPerfilPermitido = isPerfil
        })
    }

    private carregarDocumentos(): void {
        this.titulacaoDocumentosService
            .getByRequerimentoId(this.requerimento.id)
            .subscribe(async result => {
                this.titulacaoDocumentosDTOS = result;

                let datas = []
                for (let r of result) {
                    if (r.idEmissaoTitulo) {
                        let emissao = await this.emissaoTituloService.getById(r.idEmissaoTitulo).toPromise()
                        r['situacaoTitulo'] = emissao.situacaoTitulo
                        datas.push(r)
                    }
                }
                let maiorData = datas.reduce(function(prev, current) {
                    return (moment(prev.dataCriacao).isAfter(current.dataCriacao)) ? prev : current
                })

                for (let r of this.titulacaoDocumentosDTOS) {
                    if (r.id === maiorData.id) {
                        r['outroAtoInterno'] = true
                    }
                }
            });

    }

    visualizarDocumento(doc: TitulacaoDocumentosDTO): void {
        window.open(this.titulacaoDocumentosService.getVisualizarDocumentoPath(doc));
    }

    baixarDocumento(doc: TitulacaoDocumentosDTO): void {
        window.open(this.titulacaoDocumentosService.getBaixarDocumentoPath(doc));
    }

    reabilitarCancelamento(doc: TitulacaoDocumentosDTO): void {
        if (doc && doc.chave !== null || doc.chave !== "") {
            this.titulacaoDocumentosService.reabilitarCancelamentoTitulo(doc)
                .subscribe(() => {
                    this.snackBarService.showSuccess('Registro salvo com sucesso.');
                    this.carregarDocumentos();
                });
        } else {
            this.snackBarService.showError('Erro ao reabilitar o cancelamento do título.');
        }
    }
}
