import {Component, Input, OnInit} from '@angular/core';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';
import {CaracterizacaoConsumoAgua} from '../../../models/caracterizacao-consumo-agua.model';
import {CaracterizacaoConsumoAguaService} from '../../../services/caracterizacao-consumo-agua.service';

@Component({
    selector: 'app-aba-finalidade-mineracao',
    templateUrl: './aba-finalidade-mineracao.component.html'
})

export class AbaFinalidadeMineracaoComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;

    consumos: Array<CaracterizacaoConsumoAgua>;

    constructor(private mineracaoService: CaracterizacaoConsumoAguaService) {
    }

    ngOnInit(): void {
        this.carregarConsumos();
    }

    carregarConsumos(): void {
        if (this.requerimentoOutorgaFinalidade.mineracao) {
            this.mineracaoService
                .getAllByMineracao(
                    this.requerimentoOutorgaFinalidade.mineracao
                )
                .subscribe(consumos => {
                    this.consumos = consumos;
                });
        }
    }

    getDemandaTotal(): number {
        if (this.consumos) {
            return this.consumos.reduce(
                (total, current) => total + (current.producao * current.consumoAgua),
                0
            );
        }
        return 0;
    }

}


