import {BaseModel} from 'app/main/shared/models/base.model';

export class EmpreendimentoComponenteSubestacao extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoSubEstacao?: number,
        public componenteSubEstacao?: number,
        public descricao?: string,
        public quantidade?: number
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoComponenteSubestacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoComponenteSubestacao(
            json.id,
            json.empreendimentoSubEstacao,
            json.componenteSubEstacao,
            json.descricao,
            json.quantidade
        );
    }

    static fromJsons(json: any): EmpreendimentoComponenteSubestacao[] {
        const empreendimentos: EmpreendimentoComponenteSubestacao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(empreendimento => empreendimentos.push(this.fromJson(empreendimento)));
            return empreendimentos;
        }
    }

}
