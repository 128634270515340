import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Servidor} from '../models/servidor.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import { UsuarioServidorDTO } from 'app/main/pages/requerimentos/requerimento-consultar-processos/usuario-servidor-dto.model';

@Injectable({
    providedIn: 'root'
})
export class ServidorService extends BaseService<Servidor> {

    urlScorpApi: string = environment.URL_SCORP_API;

    constructor(protected injector: Injector) {
        super('/api/servidores', injector, Servidor.prototype, Servidor.fromJson);
    }

    getByServidoresScorp(nome: string): Observable<any[]> {
        const url = `${this.urlScorpApi}/api/servidores/nome/${nome}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getServidorCpfScorp(cpf: string): Observable<any> {
        const url = `${this.urlScorpApi}/api/servidores/cpf/${cpf}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getChefeParecerAvaliacaoServidor(requerimentoId: number): Observable<any[]> {
        const url = `${this.urlResource}/chefe/${requerimentoId}/complete`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getServidorCpf(cpf: string): Observable<Servidor> {
        const url = `${this.urlResource}/${cpf}/cpfServidor`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getServidorLogadoCpf(): Observable<Servidor> {
        const url = `${this.urlResource}/servidor-logado`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getListaServidores(): Observable<Servidor[]> {
        const url = `${this.urlResource}/lista-todos`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getListaServidoresAtivos(): Observable<Servidor[]> {
        const url = `${this.urlScorpApi}/api/servidores/lista-ativos`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    isUsuarioLogadoServidor(): Observable<UsuarioServidorDTO> {
        const url = `${this.urlResource}/isUsuario-logado-servidor`;
        return this.http.get(url).pipe(
            map(json => json),
            catchError(this.handleError)
        );
    }

    ///by-lotacao-servidor/cpf/logado
    getServidoresScorpPeloDepartamentoDoServidorLogado(cpf: string): Observable<any[]> {
        const url = `${this.urlScorpApi}/api/servidores/by-lotacao-servidor/${cpf}/logado`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getServidorCpfOuMatricula(cpfMatricula: string): Observable<any> {
        const url = `${this.urlScorpApi}/api/servidores/cpf-matricula/${cpfMatricula}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    //Busca servidores de uma organização administrativa
    getServidoresScorpPeloDepartamento(idOrganizacaoAdm: number): Observable<any[]> {
        const url = `${this.urlScorpApi}/api/servidores/buscar-por-departamento/${idOrganizacaoAdm}`;
        return this.http
            .get(url).pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }
}
