import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GeometriaMapa} from '@sema-geo/sema-geoportal';
import {FeicaoAnaliseRequerimentoService} from "../../../../pages/feicoes-analise-requerimentos/feicao-analise-requerimento.service";
import {ModeloFeicaoDTO} from "../../../models/modelo-feicao-dto.model";
import {UtilService} from "../../../../core/services/util.service";
import {DominiosService} from "../../../services/dominios.service";

export interface DialogEditarFeicaoEVENTUALData {
    denomicacao: string;
    idModeloFeicao: number;
    modeloFeicaoDTO: ModeloFeicaoDTO;
    tamanho: number;
    geometria: GeometriaMapa;
    visualizacao: boolean;
    onRemoveFeature: (geometria: GeometriaMapa) => void;
}

@Component({
    selector: 'app-dialog-editar-feicao',
    templateUrl: './dialog-edicao-feicao-eventual.component.html',
    styleUrls: ['./dialog-edicao-feicao-eventual.component.scss'],
})
export class DialogEdicaoFeicaoEventualComponent implements OnInit {

    form: FormGroup;
    geometria: GeometriaMapa;
    tamanho: number;
    onRemoveFeature: (geometria: GeometriaMapa) => void;
    tipoCausaEvento: any[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private feicaoAnaliseRequerimentoService: FeicaoAnaliseRequerimentoService,
        private snackBarService: SnackBarService,
        private utilService: UtilService,
        private dominiosService: DominiosService,
        private dialogRef: MatDialogRef<DialogEditarFeicaoEVENTUALData>,
        @Inject(MAT_DIALOG_DATA) public data: DialogEditarFeicaoEVENTUALData
    ) {
        this.data.visualizacao = this.data.visualizacao ? this.data.visualizacao : false;
        this.onRemoveFeature = data.onRemoveFeature;
        this.tamanho = data.tamanho;
        this.geometria = data.geometria;
    }

    async ngOnInit(): Promise<void> {
        this.form = this.formBuilder.group({
            denomicacao: [null, [Validators.required, Validators.maxLength(this.tamanho)]],
            dataEvento: [null, [Validators.required]],
            causaEvento: [null, [Validators.required]],
        });

        this.tipoCausaEvento = await this.dominiosService.getDominio('TIPO_CAUSA_EVENTO').toPromise();

        this.form.get('denomicacao').setValue(this.data.denomicacao);
        if (this.data.hasOwnProperty('modeloFeicaoDTO') && this.data.modeloFeicaoDTO && this.data.modeloFeicaoDTO.modeloFeicaoEventual) {
            this.form.get('dataEvento').setValue(this.utilService.zonedDateTimeFormat(this.data.modeloFeicaoDTO.modeloFeicaoEventual.dataEvento, 'YYYY-MM-DD'))
            const causaEvento = this.tipoCausaEvento.filter(tipo => tipo.id === this.data.modeloFeicaoDTO.modeloFeicaoEventual.idTipoCausaEvento)
            this.form.get('causaEvento').setValue(causaEvento[0])
        }
        if(this.data.visualizacao){
            this.form.disable()
        }
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onRemover(): void {
        this.onRemoveFeature(this.geometria);
        this.dialogRef.close({
            success: false
        });
    }

    salvar(): void {
        this.dialogRef.close({
            success: true,
            geometria: this.geometria,
            feicao: {
                denominacao: this.form.get('denomicacao').value,
                modeloFeicaoDTO: {
                    idModeloFeicao: this.data.idModeloFeicao,
                    modeloFeicaoEventual: {
                        dataEvento: new Date(this.form.get('dataEvento').value),
                        idTipoCausaEvento: this.form.get('causaEvento').value.id
                    }
                }
            }
        });
    }
}
