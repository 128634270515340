import {BaseModel} from './base.model';
import {EmissaoTitulo} from './emissao-titulo.model';
import {Signatario} from './signatario.model';
import {BaseEnum} from '../enums/base.enum';

export class EmissaoTituloSignatario extends BaseModel {
    constructor(
        public id?: number,
        public emissaoTitulo?: EmissaoTitulo,
        public signatario?: Signatario,
        public situacao?: string,
        public chave?: string,
        public decisao?: string,
        public descricao?: string,
        public telaPortaria?: string,
        public tarefaFinalizada?: boolean
    ) {
        super();
    }

    static decisao: BaseEnum[] = [
        {valor: 'AGUARDANDO', descricao: 'Aguardando'},
        {valor: 'ASSINADO', descricao: 'Assinado'},
        {valor: 'REPROVADO', descricao: 'Reprovado'},
    ];

    static fromJson(json: any): EmissaoTituloSignatario {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmissaoTituloSignatario(
            json.id,
            EmissaoTitulo.fromJson(json.emissaoTitulo),
            Signatario.fromJson(json.signatario),
            json.situacao,
            json.chave,
            json.decisao,
            json.descricao,
            json.telaPortaria,
            json.tarefaFinalizada);
    }
}
