import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RedefinirDisponibilidadeHidricaComponent} from "./redefinir-disponibilidade-hidrica.component";
import {SharedModule} from "../../shared/shared.module";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule, Routes} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {MatCheckboxModule} from "@angular/material/checkbox";

export const routes: Routes = [{
    path: 'redefinir-disponibilidade-hidrica',
    component: RedefinirDisponibilidadeHidricaComponent
}];

@NgModule({
    declarations: [RedefinirDisponibilidadeHidricaComponent],
    imports: [
        CommonModule,
        SharedModule,
        FlexModule,
        MatButtonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        MatInputModule,
        MatTableModule,
        MatCheckboxModule,
    ]
})
export class RedefinirDisponibilidadeHidricaModule {
}
