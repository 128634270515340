import {Requerimento} from '../requerimento.model';
import {RequerimentoOutorga} from './requerimento-outorga.model';
import {ObjetivoRequerimentoOutorga} from 'app/main/shared/models/objetivo-requerimento-outorga.model';
import {RequerimentoOutorgaFinalidadeAtividadeDTO} from '../../../shared/models/requerimento-finalidade-atividade-dto.model';

export class EspelhoOutorgaDto {
    constructor(
        public requerimento?: Requerimento,
        public requerimentoOutorga?: RequerimentoOutorga,
        public objetivoRequerimentoOutorga?: Array<ObjetivoRequerimentoOutorga>,
        public requerimentoFinalidadeAtividades?: Array<RequerimentoOutorgaFinalidadeAtividadeDTO>
    ) {
    }

    static fromJson(json: any): EspelhoOutorgaDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new EspelhoOutorgaDto(
            Requerimento.fromJson(json.requerimento),
            RequerimentoOutorga.fromJson(json.requerimentoOutorga),
            ObjetivoRequerimentoOutorga.fromJsons(json.objetivoRequerimentoOutorga),
            RequerimentoOutorgaFinalidadeAtividadeDTO.fromJsons(json.requerimentoFinalidadeAtividades)
        );
    }
}
