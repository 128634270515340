import { UtilService } from "app/main/core/services/util.service";


export class LicenciamentoConfiguracaoUtil {


    public static isLicencaPrevia(descr: string) {
        if (descr == null) return false;

        let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
        return (
            ( normalizeUpperdDescrWords.find((w)=>w == 'LP') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'PREVIA')) )
        ) != null;
    }

    public static isLicencaInstalacao(descr: string) {
        if (descr == null) return false;
        let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
        return (
            ( normalizeUpperdDescrWords.find((w)=>w == 'LI') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'INSTALACAO')) )
        ) != null;
    }

    public static isLicencaOperacao(descr: string) {
        if (descr == null) return false;
        let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
        return (
            ( normalizeUpperdDescrWords.find((w)=>w == 'LO') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'OPERACAO') && !normalizeUpperdDescrWords.find((w)=>w == 'PROVISORIA')) )
        ) != null;
    }

    public static isLicencaOperacaoProvisoria(descr: string) {
        if (descr == null) return false;
        let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
        return (
            ( normalizeUpperdDescrWords.find((w)=>w == 'LOP') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'OPERACAO') && normalizeUpperdDescrWords.find((w)=>w == 'PROVISORIA')) )
        ) != null;
    }

    public static isLicencaOperacaoPesquisaMineral(descr: string) {
        if (descr == null) return false;
        let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
        return (
            ( normalizeUpperdDescrWords.find((w)=>w == 'LOPM') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'OPERACAO') && normalizeUpperdDescrWords.find((w)=>w == 'MINERAL')) )
        ) != null;
    }

    public static isLicencaAmbientalSimplificada(descr: string) {
        if (descr == null) return false;
        let normalizeUpperdDescrWords = UtilService.strNormalizeNFD(descr.toUpperCase()).split(/(?:,| )+/);
        return (
            ( normalizeUpperdDescrWords.find((w)=>w == 'LAS') || ( normalizeUpperdDescrWords.find((w)=>w == 'LICENCA') && normalizeUpperdDescrWords.find((w)=>w == 'AMBIENTAL') && normalizeUpperdDescrWords.find((w)=>w == 'SIMPLIFICADA')) )
        ) != null;
    }

}