import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RequerimentoService} from "../../requerimento.service";
import {ProcessoService} from "../../../../shared/services/processo.service";
import {TipoRequerimentoService} from "../../../tipos-requerimento/tipo-requerimento.service";
import {TituloParametrizacaoObjetivoService} from "../../../../shared/services/licenciamento-config-service/titulo-parametrizacao-objetivos.service";
import {TitulacaoService} from "../../../titulacoes/titulacao.service";
import {SnackBarService} from "../../../../shared/snack-bar/snack-bar.service";
import {EmissaoTituloService} from "../../../tarefas/shared/service/emissao-titulo.service";
import {DocumentoProcessoService} from "../../../tarefas/shared/service/documento-processo.service";
import {EnumSituacaoTitulo} from "../../../../shared/enums/EnumSituacaoTitulo";

@Component({
    selector: 'app-dialog-lac-processo',
    templateUrl: './dialog-lac-processo.component.html',
    styleUrls: ['./dialog-lac-processo.component.scss']
})
export class DialogLacProcessoComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<DialogLacProcessoComponent>,
                public requerimentoService: RequerimentoService,
                public processoService: ProcessoService,
                private tipoRequerimentoService: TipoRequerimentoService,
                private tituloParametrizacaoObjetivoService: TituloParametrizacaoObjetivoService,
                private licenciamentoTitulacaoService: TitulacaoService,
                private snackBarService: SnackBarService,
                private emissaoTituloService: EmissaoTituloService,
                private documentoProcessoService: DocumentoProcessoService,
                @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    numeroProtocolo: any;

    requerimento: any;
    objetivos: any;

    mostrarEncontradoSiga: boolean = false;
    mostrarEncontradoLegado: boolean = false;
    mostrarNaoEncontrado: boolean = false;

    tiposRequerimento: Array<any> = [];
    tipoRequerimento: any;
    validado = false
    titulos: Array<any> = [];
    parametros: Array<any> = [];
    idParametroObjetivo: number
    falhou = false

    async ngOnInit() {
        await this.getListaTitulos()
        await this.getListaTiposRequerimento()
    }

    private async getListaTitulos() {
        this.titulos = await this.licenciamentoTitulacaoService.getAll().toPromise();
    }

    async retornarResultado() {
        if (this.validado && this.tipoRequerimento) {
            let emissaoTitulo = await this.emissaoTituloService.buscaPorIdRequerimentoDocumentoProcesso(this.requerimento.id).toPromise()
            if (this.tipoRequerimento.id === 32
                && emissaoTitulo.situacaoTitulo !== EnumSituacaoTitulo.SUSPENSO) {
                this.snackBarService.showAlert('A solicitação de reabilitação de título pode ser realizada apenas para os títulos na situação “Suspenso”')
                return
            }
            this.dialogRef.close({
                tipoRequerimento: this.tipoRequerimento,
                numeroProtocoloVinculado: this.numeroProtocolo
            });
        } else {
            this.snackBarService.showAlert('Preencha os campos para avançar e criar o requerimento.')
            return
        }
    }

    async buscarRequerimento(numeroProtocolo) {
        this.mostrarEncontradoLegado = false
        this.validado = false
        this.mostrarNaoEncontrado = false

        await this.processoService.findByNumero(numeroProtocolo.replace('/', '')).toPromise().then(async response => {
            if (response) {
                await this.requerimentoService.getRequerimento(numeroProtocolo).toPromise().then(async resposta => {
                    if (response) {
                        response.requerimentos.push(resposta[0])
                    }
                })

                let validacao = response.requerimentos.find(req => req.situacaoProcesso === 'CONCLUIDO' && req.tipoProcesso == 'LAC - Licenciamento por Adesão e Compromisso')
                if (validacao) {
                    this.requerimento = response.requerimentos[0]
                    if (!this.falhou) {
                        this.mostrarEncontradoLegado = true;
                        this.validado = true
                    }
                } else {
                    this.snackBarService.showAlert('Não foi possível encontrar a titulação informada.')
                    this.mostrarNaoEncontrado = true;
                }
            }
        }).catch(() => {
            this.snackBarService.showAlert('Erro ao buscar o requerimento.')
            this.mostrarNaoEncontrado = true;
        })
        // }
    }

    private async getListaTiposRequerimento() {
        this.tiposRequerimento = await this.tipoRequerimentoService.getAll().toPromise();
        this.tiposRequerimento = this.tiposRequerimento.filter(tr => tr.tipoAtuacao.toUpperCase().includes('ANDAMENTO'))
    }

    numeroRequerimentoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.numeroProtocolo = `${initial}/${year}`;
            }
        }
    }
}
