import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {PontoVazaoMensal} from '../models/ponto-vazao-mensal.model';

@Injectable({
    providedIn: 'root'
})
export class PontoVazoesMensaisService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/ponto-vazoes-mensais';
    }

    getAllByPonto(ponto: Ponto): Observable<PontoVazaoMensal[]> {
        return this.http.get(`${this.urlResource}`, {params: {idPonto: ponto.id.toString()}}).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    create(lancamento: PontoVazaoMensal): Observable<PontoVazaoMensal> {
        return this.http
            .post(this.urlResource, lancamento)
            .pipe(
                map(this.jsonDataToResource),
                catchError(this.handleError.bind(this))
            );
    }

    delete(lancamento: PontoVazaoMensal): Observable<any> {
        const url = `${this.urlResource}/${lancamento.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonDataToResourceList(jsonData: any[]): PontoVazaoMensal[] {
        const entries: PontoVazaoMensal[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new PontoVazaoMensal(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

    private jsonDataToResource(jsonData: any): PontoVazaoMensal {
        return Object.assign(new PontoVazaoMensal(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
