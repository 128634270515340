import {NgModule} from '@angular/core';
import {
    MatDatepickerModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule
} from '@angular/material';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {RequerimentoSharedModule} from '../shared/shared.module';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {CommonModule, DatePipe} from '@angular/common';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {NgxCurrencyModule} from 'ngx-currency';
import {SemaGeoportalModule} from '@sema-geo/sema-geoportal';
import {environment} from "../../../../../environments/environment";
import {RequerimentoTermoReferenciaEspecificoComponent} from "./requerimento-termo-referencia-especifico.component";
import {
    RequerimentoFormularioTermoReferenciaEspecificoComponent
} from "./requerimento-formulario-termo-referencia-especifico/requerimento-formulario-termo-referencia-especifico.component";

@NgModule({
    declarations: [
        RequerimentoTermoReferenciaEspecificoComponent,
        RequerimentoFormularioTermoReferenciaEspecificoComponent
    ],
    imports: [
        CommonModule,
        LeafletModule,
        CoreModule,
        SharedModule,
        RequerimentoSharedModule,
        MatStepperModule,
        MatListModule,
        MatRadioModule,
        MatInputModule,
        MatDatepickerModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        NgxCurrencyModule,
        MatButtonToggleModule,
        SemaGeoportalModule.forRoot(environment),
        MatTooltipModule
    ],
    exports: [
        RequerimentoTermoReferenciaEspecificoComponent,
        RequerimentoFormularioTermoReferenciaEspecificoComponent
    ],
    entryComponents: [
        RequerimentoTermoReferenciaEspecificoComponent,
        RequerimentoFormularioTermoReferenciaEspecificoComponent
    ],
    providers: [DatePipe]
})
export class RequerimentoTermoReferenciaEspecificoModule {
}
