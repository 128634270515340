import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {DoencaGrave} from './doenca-grave.model';

export class Pessoa extends BaseModel {
    constructor(
        public id: number,
        public idCorporativo: number,
        public dataAtualizacao: Date,
        public nome: string,
        public tipo: string,
        public email: string,
        public telefone: string,
        public endereco: string,
        public complemento: string,
        public logradouro: string,
        public bairro: string,
        public numero: string,
        public cpfCnpj: string,
        public rg: string,
        public dataNascimento: Date,
        public sexo: string,
        public profissoes: string,
        public idadeSuperior60Anos: boolean,
        public possuiDeficienciaFisicaMental: boolean,
        public possuiDoencaGrave: boolean,
        public doencaGrave: DoencaGrave,
        public outraDoencaGrave: string,
        // ------------------ DADOS PESSOA JURIDICA BEGIN
        public nomeFantasia: string,
        public inscricaoMunicipal: string,
        public inscricaoEstadual: string,
        public naturezaJuridica: string,
        public porteEmpresa: string,
        public codigoIbgeMunicipio: string,
        public cadastroTecnicoFederal: string,
    ) {
        super();
    }

    getNomeFormatado(): string {
        if (this.tipo === 'PF') {
            return `${this.cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')} - ${this.nome}`
        } else {
            return `${this.cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')} - ${this.nomeFantasia}`
        }
    }

    static tiposPessoa: BaseEnum[] = [
        {valor: 'PF', descricao: 'Pessoa Física'},
        {valor: 'PJ', descricao: 'Pessoa Jurídica'},
    ];

    static sexo: BaseEnum[] = [
        {valor: 'M', descricao: 'Masculino'},
        {valor: 'F', descricao: 'Feminino'},
    ];

    static fromJson(json: any): Pessoa {
        if (json === undefined || json === null) {
            return null;
        }
        return new Pessoa(
            json.id,
            json.idCorporativo,
            json.dataAtualizacao,
            json.nome,
            json.tipo,
            json.email,
            json.telefone,
            json.endereco,
            json.complemento,
            json.logradouro,
            json.bairro,
            json.numero,
            json.cpfCnpj,
            json.rg,
            json.dataNascimento,
            json.sexo,
            json.profissoes,
            json.idadeSuperior60Anos,
            json.possuiDeficienciaFisicaMental,
            json.possuipossuiDoencaGrave,
            DoencaGrave.fromJson(json.doencaGrave),
            json.outraDoencaGrave,
            json.nomeFantasia,
            json.inscricaoMunicipal,
            json.inscricaoEstadual,
            json.naturezaJuridica,
            json.porteEmpresa,
            json.codigoIbgeMunicipio,
            json.cadastroTecnicoFederal,
        );
    }

    static fromJsons(json: any): Pessoa[] {
        const pessoas: Pessoa[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(pessoa => pessoas.push(this.fromJson(pessoa)));
            return pessoas;
        }
    }

}
