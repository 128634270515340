import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import { EmissaoTitulo } from 'app/main/shared/models/emissao-titulo.model';
import { ServidorService } from 'app/main/shared/services/servidor.service';
import { DocumentoProcessoService } from '../documento-processo/documento-processo.service';

@Component({
    selector: 'app-lic-amb-documentos-tecnicos',
    templateUrl: './documentos-tecnicos.component.html', 
    styleUrls: ['./documentos-tecnicos.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LicAmbEmissaoTituloDocumentosTecnicosComponent implements OnInit {

    @Input() emissaoTitulo: EmissaoTitulo;

    constructor(private servidorService: ServidorService,
                private documentoProcessoService: DocumentoProcessoService) {
    }

    ngOnInit(): void {
    }


    public downloadDocumento(id: any, isPdfComAnexo: boolean = false): void {
        if (isPdfComAnexo) {
            window.open(
                `${this.documentoProcessoService.urlResource}/${id}/download-parecer-com-anexos`
            );
        } else {
            window.open(
                `${this.documentoProcessoService.urlResource}/${id}/download`
            );
        }
    }

}
