import {DocumentoRequerimento} from "../../../../../documentos-requerimentos/documento-requerimento.model";

export class AguaSubterraneaIrrigacao {
    constructor(
        public id?: number,
        public diametroPerfuracao?: number,
        public diametroRevestimento?: number,
        public vazao?: number,
        public profundidade?: number,
        public empresaResponsavel?: string,
        public empresaResponsavelCnpj?: string,
        public possuiPerfuracaoPoco?: boolean,
        public nrAutorizacaoPerfuracao?: string,
        public dataPerfuracao?: Date,
        public declaracaoPerfuracao?: DocumentoRequerimento,
        public alturaBocaPoco?: number,
        public metodoMedicaoVazao?: string,
        public horaTesteInicio?: Date,
        public horaTesteFim?: Date,
        public cotaTopografica?: number,
        public empresaExecutoraTeste?: string,
        public vazaoTeste?: number,
        public nivelEstBombeamento?: number,
        public nivelDinBombeamento?: number,
        public vazaoEspecifica?: number,
        public testeBombeamento?: DocumentoRequerimento,
        public testeRecuperacao?: DocumentoRequerimento,
    ) {
    }

    static fromJson(json: any): AguaSubterraneaIrrigacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new AguaSubterraneaIrrigacao(
            json.id ? json.id : null,
            json.diametroPerfuracao ? json.diametroPerfuracao : null,
            json.diametroRevestimento ? json.diametroRevestimento : null,
            json.vazao ? json.vazao : null,
            json.profundidade ? json.profundidade : null,
            json.empresaResponsavel ? json.empresaResponsavel : null,
            json.empresaResponsavelCnpj ? json.empresaResponsavelCnpj : null,
            json.possuiPerfuracaoPoco ? json.possuiPerfuracaoPoco : null,
            json.nrAutorizacaoPerfuracao ? json.nrAutorizacaoPerfuracao : null,
            json.dataPerfuracao ? json.dataPerfuracao : null,
            json.declaracaoPerfuracao ? DocumentoRequerimento.fromJson(json.declaracaoPerfuracao) : null,
            json.alturaBocaPoco ? json.alturaBocaPoco : null,
            json.metodoMedicaoVazao ? json.metodoMedicaoVazao : null,
            json.horaTesteInicio ? json.horaTesteInicio : null,
            json.horaTesteFim ? json.horaTesteFim : null,
            json.cotaTopografica ? json.cotaTopografica : null,
            json.empresaExecutoraTeste ? json.empresaExecutoraTeste : null,
            json.vazaoTeste ? json.vazaoTeste : null,
            json.nivelEstBombeamento ? json.nivelEstBombeamento : null,
            json.nivelDinBombeamento ? json.nivelDinBombeamento : null,
            json.vazaoEspecifica ? json.vazaoEspecifica : null,
            json.testeBombeamento ? DocumentoRequerimento.fromJson(json.testeBombeamento) : null,
            json.testeRecuperacao ? DocumentoRequerimento.fromJson(json.testeRecuperacao) : null,
        );
    }
}
