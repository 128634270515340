import {NgModule} from '@angular/core';
import {CoreModule} from '../../core/core.module'
import {FeicaoTemplateListComponent} from './feicao-template-list/feicao-template-list.component';
import {FeicaoTemplateDetailComponent} from './feicao-template-detail/feicao-template-detail.component';
import {RouterModule, Routes} from '@angular/router';
import {FeicaoTemplateListResolver, FeicaoTemplateResolver} from './feicao-template.service';
import {SharedModule} from 'app/main/shared/shared.module';

export const routes: Routes = [
    {
        path: 'new',
        component: FeicaoTemplateDetailComponent
    },
    {
        path: 'feicoes-template',
        component: FeicaoTemplateListComponent,
        resolve: {
            data: FeicaoTemplateListResolver
        }
    },
    {
        path: ':id/edit',
        component: FeicaoTemplateDetailComponent,
        resolve: {
            data: FeicaoTemplateResolver
        }
    },
    {
        path: ':id/detail',
        component: FeicaoTemplateDetailComponent,
        resolve: {
            data: FeicaoTemplateResolver
        }
    }
];

@NgModule({
    declarations: [
        FeicaoTemplateListComponent,
        FeicaoTemplateDetailComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    entryComponents: [
        FeicaoTemplateDetailComponent,
        FeicaoTemplateListComponent
    ],
    providers: [
        FeicaoTemplateResolver,
        FeicaoTemplateListResolver
    ]
})
export class FeicoesTemplateModule {
}
