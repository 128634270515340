import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import {DominioDto} from "../models/dominio-dto.model";
import {environment} from "../../../../environments/environment";
import {SubDominioDto} from "../models/sub-dominio-dto.model";
import { DominioFinalidadePlantio } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-florestal/dominios-model/dominio-finalidade-plantio.model';
import { DominioLimiteCreditoFlorestal } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-florestal/dominios-model/dominio-limite-credito-florestal.model';
import { DominioConfigReflorestamento } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-florestal/dominios-model/dominio-config-reflorestamento.model';

@Injectable({
    providedIn: 'root'
})
export class DominiosService extends BaseService<DominioDto> {
    urlResource: string = environment.URL_DOMINIOS_API;


    constructor(protected injector: Injector) {
        super('/', injector, DominioDto.prototype, DominioDto.fromJson);
    }

    getDominio(tabela: string): Observable<DominioDto[]> {
        const url = this.urlResource + `/api/dominio/tabela`;
        return this.http
            .get(url, {
                params: {tabela: tabela}
            })
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getDominioFinalidadePlantio(tabela: string): Observable<DominioFinalidadePlantio[]> {
        const url = this.urlResource + `/api/finalidades-plantio`;
        return this.http
            .get(url, {
                params: {tabela: tabela}
            })
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getDominioFinalidadePlantioById(tabela: string, idFinalidadePlantio: number): Observable<DominioFinalidadePlantio> {
        const url = this.urlResource + `/api/finalidades-plantio/${idFinalidadePlantio}/id`;
        return this.http
            .get(url, {
                params: {tabela: tabela}
            })
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }


    getDominioLimiteCreditoFlorestal(tabela: string): Observable<DominioLimiteCreditoFlorestal[]> {
        const url = this.urlResource + `/api/limites-credito-florestal`;
        return this.http
            .get(url, {
                params: {tabela: tabela}
            })
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getDominioLimiteCreditoFlorestalByParametros(tabela: string, idObjetivo: number, idFinalidade: number, 
            tempoEmMesesDesdeOPlantio: number): Observable<DominioLimiteCreditoFlorestal> {
        const url = this.urlResource + `/api/limites-credito-florestal/find-by-parametros/${idObjetivo}/objetivo/${idFinalidade}/finalidade/${tempoEmMesesDesdeOPlantio}/numero-meses`;
        return this.http
            .get(url, {
                params: {tabela: tabela}
            })
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getDominioConfigReflorestamento(tabela: string): Observable<DominioConfigReflorestamento[]> {
        const url = this.urlResource + `/api/configuracoes-reflorestamento`;
        return this.http
            .get(url, {
                params: {tabela: tabela}
            })
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getDominioTipoTanques(): Observable<DominioDto[]> {
        const url = this.urlResource + `/api/tanque-piscicultura`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getDominioTipoEspecie(): Observable<DominioDto[]> {
        const url = this.urlResource + `/api/tipo-especie-piscicultura`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getDominioTipoEspecieBacia(): Observable<DominioDto[]> {
        const url = this.urlResource + `/api/especie-piscicultura-bacia`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getDominioEspeciePiscicultura(): Observable<DominioDto[]> {
        const url = this.urlResource + `/api/especie-piscicultura`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }


    getDominioComFiltro(tabela: string, listaId: number[]): Observable<DominioDto[]> {
        const url = this.urlResource + `/api/dominio/tabela`;
        return this.http
            .get(url, {
                params: {tabela: tabela, listaId: listaId.toString()}
            })
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getAllTipoResiduoTransportado(): Observable<any>{
        const url = this.urlResource + `/api/tipo-residuo-transportado`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getAllTipoClasseRisco(): Observable<any>{
        const url = this.urlResource + `/api/tipo-classe-risco`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getAllTipoAcondicionamento(): Observable<any>{
        const url = this.urlResource + `/api/tipo-acondicionamento`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getAllTipoObra(): Observable<any>{
        const url = this.urlResource + `/api/tipo-obra`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getAllTipoPavimentacao(): Observable<any>{
        const url = this.urlResource + `/api/tipo-pavimentacao`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getAllTipoObrasEspeciais(): Observable<any>{
        const url = this.urlResource + `/api/tipo-obra-especial`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getAllTipoBarragem(): Observable<any>{
        const url = this.urlResource + `/api/tipo-barragem`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getAllTipoCasaForca(): Observable<any>{
        const url = this.urlResource + `/api/tipo-casa-forca`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getDominioByUrl(path: string): Observable<DominioDto[]> {
        const url = this.urlResource + `/api/` + path;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getSubDominioByUrl(path: string, idDominio: number): Observable<SubDominioDto[]> {
        const url = this.urlResource + `/api/` + path + `/tipo/` + idDominio;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getAllIsencaoTaxa(): Observable<any>{
        const url = this.urlResource + `/api/isencao-taxa`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getByIdIsencao(idIsencao: number): Observable<any>{
        const url = this.urlResource + `/api/isencao-taxa/${idIsencao}`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getAllTipoDuracaoCiclo(): Observable<any>{
        const url = this.urlResource + `/api/tipo-duracao-ciclo`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    getAllTipoCicloAtual(): Observable<any>{
        const url = this.urlResource + `/api/tipo-ciclo-atual`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }

    buscarVariaveisCamundaPorOrganizacao(idOrg: number): Observable<any>{
        const url = this.urlResource + `/api/variavel-grupo-camunda/${idOrg}`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        )
    }
}
