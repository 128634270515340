import {Component, Input, ViewChild} from '@angular/core';
import {AbstractComponenteRelacionamento} from "../../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento";
import {FormGroup, NgForm} from "@angular/forms";
import {DominioDto} from "../../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../../shared/services/dominios.service";
import {SubProdutoProcessadoTerceiroIndFrigorifico} from "../../../../../../../../../shared/models/subproduto-processado-terceiro-ind-frigorifico.model";
import {SubprodutoProcessadoTerceiroIndFrigorificoService} from "../../../../../../../../../shared/services/subproduto-processado-terceiro-ind-frigorifico.service";

@Component({
  selector: 'app-subproduto-processado-terceiro',
  templateUrl: './subproduto-processado-terceiro.component.html',
  styleUrls: ['./subproduto-processado-terceiro.component.scss']
})
export class SubprodutoProcessadoTerceiroComponent extends AbstractComponenteRelacionamento<SubProdutoProcessadoTerceiroIndFrigorifico, SubprodutoProcessadoTerceiroIndFrigorificoService> {

    protected TIPO_OUTRO = 'Outros';

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
    @ViewChild('insideForm', {static: false}) insideForm: NgForm;
    unidadesMedida: Array<DominioDto> = [];

    constructor(
        private dominiosService: DominiosService
    ) {
        super();
    }

    async ngOnInit() {
        super.ngOnInit();

        this.unidadesMedida = await this.dominiosService.getDominioByUrl('unidade-medida-prod-proc').toPromise();

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    getDisplayedColumns(): string[] {
        return ['subproduto', 'descricao', 'volume', 'acoes'];
    }

    isBotaoAdicionarDisabled() {
        return super.isBotaoAdicionarDisabled() || !this.novoDado.vinculo.volume || !this.novoDado.vinculo.idUnidadeMedida;
    }

    getUnidadeMedida(idUnidadeMedida: any) {
        let um = this.unidadesMedida.find(um => um.id === idUnidadeMedida);
        return um ? um.descricao : '';
    }
}
