import {BaseModel} from 'app/main/shared/models/base.model';

export class ObraDrenagem extends BaseModel {
    constructor(
        public id?: number,
        public zona?: number,
        public desapropriacao?: boolean,
        public corredorFauna?: boolean,
        public equipamentoAPP?: boolean,
        public extensao?: number
    ) {
        super();
    }

    static fromJson(json: any): ObraDrenagem {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObraDrenagem(
            json.id,
            json.zona,
            json.desapropriacao,
            json.corredorFauna,
            json.equipamentoAPP,
            json.extensao
        );
    }

    static fromJsons(json: any): ObraDrenagem[] {
        const lista: ObraDrenagem[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => lista.push(this.fromJson(item)));
            return lista;
        }
    }

}
