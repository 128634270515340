import { Injectable, Injector } from '@angular/core';
import { LancamentoCreditoReposicaoFlorestal } from 'app/main/shared/models/lancamento-credito-reposicao-florestal.model';
import { BaseService } from 'app/main/shared/services/base.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LancamentoCreditoReposicaoFlorestalService extends BaseService<LancamentoCreditoReposicaoFlorestal>{
    
    constructor(protected injector: Injector) {
        super(
            '/api/lancamentos-credito-reposicao-florestal',
            injector,
            LancamentoCreditoReposicaoFlorestal.prototype,
            LancamentoCreditoReposicaoFlorestal.fromJson
        );
    }

    getLastByCpfDetentor(cpfDetentor: string) {
        const url = `${this.urlResource}/buscar-por-cpf-detentor/${cpfDetentor}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

}
