import {Component, Input, OnInit} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {AlocacaoVazao} from '../../../models/alocacao-vazao.model';
import {BarragemExistente} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-barragem-existente/barragem-existente.model';
import {AlocacaoVazaoService} from '../../../services/alocacao-vazao.service';

@Component({
    selector: 'app-aba-objetivo-barragem-existente',
    templateUrl: './aba-objetivo-barragem-existente.component.html'
})

export class AbaObjetivoBarragemExistenteComponent implements OnInit {

    @Input() ponto: Ponto;
    finalidadesVazao = AlocacaoVazao.finalidades;
    finalidades = BarragemExistente.finalidades;
    tiposVertedor = BarragemExistente.tiposVertedouros;
    materialConstrutivo = BarragemExistente.materialConstrutivo;
    posicoesBarragem = BarragemExistente.posicaoBarragem;
    alocacaoVazoesAdicionadas: AlocacaoVazao[];

    constructor(
        private alocacaoVazaoService: AlocacaoVazaoService
    ) {
    }

    ngOnInit(): void {
        this.carregarVazoes();
    }

    private carregarVazoes(): void {
        if (this.ponto.barragemExistente) {
            this.alocacaoVazaoService.getAllByBarragemExistente(this.ponto.barragemExistente).subscribe(result => {
                this.alocacaoVazoesAdicionadas = result;
            });
        }
    }

}


