import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { ParecerRequerimentoConclusao } from 'app/main/pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.model';
import { ParecerRequerimentoConclusaoService } from 'app/main/pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.service';
import { ParecerTecnico } from 'app/main/pages/parecer-tecnico/parecer-tecnico.model';
import { EnumOrigemTitulacao } from '../../../../../shared/enums/EnumOrigemTitulacao';
import { Requerimento } from '../../../requerimento.model';
import { RequerimentoService } from '../../../requerimento.service';
import { RequerimentoSolicitacoesDialogComponent } from '../requerimento-solicitacoes-dialog/requerimento-solicitacoes-dialog.component';
import { RequerimentoTitulacao } from '../shared/requerimento-titulacao.model';


@Component({
    selector: 'app-requerimento-solicitacoes',
    templateUrl: 'requerimento-solicitacoes.component.html',
    styleUrls: ['requerimento-solicitacoes.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class RequerimentoSolicitacoesComponent implements OnInit {

    @Input() requerimento: Requerimento;
    @Input() parecerTecnico: ParecerTecnico;
    @Input() deletedEmitter = new EventEmitter();
    @Output() acaoUpdateEmitter = new EventEmitter();
    solicitacoes: RequerimentoTitulacao[] = [];
    conclusoes: ParecerRequerimentoConclusao[] = [];

    constructor(
        injector: Injector,
        private dialog: MatDialog,
        private requerimentoService: RequerimentoService,
        private parecerRequerimentoConclusaoService: ParecerRequerimentoConclusaoService
    ) {

    }

    ngOnInit(): void {
        this.carregarSolicitacoes();

        this.acaoUpdateEmitter.subscribe(conclusao => {
            this.carregarSolicitacoes();
        });
        this.deletedEmitter.subscribe(conclusao => {
            this.solicitacoes.forEach(s => {
                if (s.titulacao.id === (conclusao as ParecerRequerimentoConclusao).requerimentoTitulacao.titulacao.id) {
                    s['disabled'] = false;
                }
            });
        });
    }

    carregarSolicitacoes(): void {
        this.requerimentoService
            .getTitulacoes(this.requerimento)
            .subscribe(solicitacoes => {
                this.parecerRequerimentoConclusaoService
                    .getByParecerTecnico(this.parecerTecnico.id)
                    .subscribe(async conclusoes => {
                        this.conclusoes = conclusoes;
                        for (let i = 0; i < solicitacoes.length; i++) {
                            const idTitulacao = solicitacoes[i].titulacao.id;
                            const exists = conclusoes.some(c => c.requerimentoTitulacao.titulacao.id === idTitulacao);
                            solicitacoes[i]['disabled'] = exists;
                        }

                        solicitacoes = await solicitacoes.filter(solicitacao => solicitacao.enumOrigemTitulacao === EnumOrigemTitulacao.REQUERIMENTO);
                        // filtrar solicitacoes repetidas
                        const filtradas = [];
                        solicitacoes.forEach(solicitacao => {
                            let repetido = null;
                            if (filtradas.length > 0) {
                                repetido = filtradas.find(item => item.titulacao.id === solicitacao.titulacao.id);
                            }
                            if (!repetido || filtradas.length === 0) {
                                filtradas.push(solicitacao);
                            }
                        });
                        this.solicitacoes = filtradas;
                    });
            });
    }

    acaoSolicitacao(requerimentoTitulacao: RequerimentoTitulacao, deferido: boolean): void {
        const dialogRef = this.dialog.open(RequerimentoSolicitacoesDialogComponent, {
            data: {
                requerimentoTitulacao: requerimentoTitulacao,
                deferido: deferido,
                parecerTecnicoId: this.parecerTecnico.id
            },
            width: '550px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.acaoUpdateEmitter.emit(result);
                // Desabilitar o RequerimentoTitulacao adicionado
                this.solicitacoes.forEach(s => {
                    if (s.titulacao.id === requerimentoTitulacao.titulacao.id) {
                        s['disabled'] = true;
                    }
                });
            }
        });
    }

}
