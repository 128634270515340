import {BaseModel} from "../../../../../shared/models/base.model";

export class RegistrarDecisaoDocumentoDto extends BaseModel {
    constructor(
        public chaveDocumento?: string,
        public textoJustificativa?: string,
        public tituloDoc?: string,
        public numProcesso?: string,
        public idTipoSolicitacao?: number,
    ) {
        super();
    }

    static fromJson(json: any): RegistrarDecisaoDocumentoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new RegistrarDecisaoDocumentoDto(
            json.chaveDocumento,
            json.textoJustificativa,
            json.tituloDoc,
            json.numProcesso,
            json.idTipoSolicitacao,
        );
    }

    static fromJsons(json: any): RegistrarDecisaoDocumentoDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(RegistrarDecisaoDocumentoDto.fromJson(j)));
            return resources;
        }
    }

}