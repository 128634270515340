import {Requerimento} from '../../pages/requerimentos/requerimento.model';
import {BaseEnum} from '../enums/base.enum';
import {Pessoa} from './pessoa.model';

export class Processo {
    constructor(
        public id?: number,
        public numero?: string,
        public origem?: string,
        public requerimentos?: any,
        public dataAbertura?: Date,
        public numeroPortaria?: string,
        public numeroProtocolo?: string,
        public dataAlteracao?: Date,
        public cpfCadastrante?: string,
    ) {
    }

    static origem: BaseEnum[] = [
        {
            valor: 'SIGA', descricao: 'SIGA"'
        },
        {
            valor: 'LEGADO', descricao: 'Legado"'
        },
    ];

    static fromJson(json: any): Processo {
        if (json === undefined || json === null) {
            return null;
        }
        return new Processo(
            json.id,
            json.numero,
            json.origem,
            Requerimento.fromJsons(json.requerimentos),
            json.dataAbertura,
            json.numeroPortaria,
            json.numeroProtocolo,
            json.dataAlteracao,
            json.cpfCadastrante,
        );
    }

    static fromJsons(json: any): Processo[] {
        const lista: Processo[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}
