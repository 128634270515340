import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Requerimento} from '../requerimento.model';
import {Processo} from '../../../shared/models/processo.model';
import {ProcessoService} from '../../../shared/services/processo.service';

@Component({
    selector: 'app-requerimento-pagina-processos',
    templateUrl: './requerimento-pagina-processos.component.html',
    styleUrls: ['./requerimento-pagina-processos.component.scss']
})
export class RequerimentoPaginaProcessosComponent implements OnInit {

    processo: Processo;
    requerimentoSelecionado: Requerimento;
    selectedTabIndex = 0;
    situacoesProcessos = Requerimento.situacoesProcesso;

    isUsuarioLogadoServidor: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private processoService: ProcessoService
    ) {
    }

    ngOnInit(): void {
        this.loadRequerimento();
    }

    async loadRequerimento(): Promise<void> {
        this.activatedRoute.params.subscribe(p => {
            this.processoService.findProcessosVinculados(p['id']).subscribe(result => {
                this.processo = result;
                this.requerimentoSelecionado = this.processo.requerimentos.find(req => req.id == p['id']);
            });

            const tabNumber = p['tab'];
            if (tabNumber) {
                this.selectedTabIndex = tabNumber - 1;
            }
        });
    }

    abrirRequerimento(item: Requerimento): void {
        this.requerimentoSelecionado = item;
    }
}