import {BaseModel} from 'app/main/shared/models/base.model';
import {TipoRebanho} from './tipo-rebanho.model';
import {CriacaoAnimal} from './criacao-animal.model';

export class Rebanho extends BaseModel {
    constructor(
        public id?: number,
        public tipo?: TipoRebanho,
        public outroRebanho?: string,
        public quantidadeConfinado?: number,
        public consumoConfinado?: number,
        public quantidadeLivre?: number,
        public consumoLivre?: number,
        public criacaoAnimal?: CriacaoAnimal
    ) {
        super();
    }

    get demandaConfinado(): number {
        return this.quantidadeConfinado * this.consumoConfinado;
    }

    get demandaLivre(): number {
        return this.quantidadeLivre * this.consumoLivre;
    }

    static fromJson(json: any): Rebanho {
        if (json === undefined || json === null) {
            return null;
        }
        return new Rebanho(
            json.id,
            json.tipo,
            json.outroRebanho,
            json.quantidadeConfinado,
            json.consumoConfinado,
            json.quantidadeLivre,
            json.consumoLivre,
            json.criacaoAnimal
        );
    }
}
