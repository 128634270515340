import {NgModule} from '@angular/core';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {ParecerTecnicoCondicionantesListComponent} from '../parecer-tecnico-condicionates/parecer-tecnico-condicionantes-list/parecer-tecnico-condicionantes-list.component';
import {ParecerTecnicoCondicionantesListModule} from '../parecer-tecnico-condicionates/parecer-tecnico-condicionantes-list/parecer-tecnico-condicionantes-list.module';
import {ParecerTecnicoConclusoesComponent} from '../parecer-tecnico-conclusoes/parecer-tecnico-conclusoes.component';
import {RequerimentoSolicitacoesComponent} from '../../requerimentos/shared/requerimento-titulacao/requerimento-solicitacoes/requerimento-solicitacoes.component';
import {RequerimentoSolicitacoesDialogComponent} from '../../requerimentos/shared/requerimento-titulacao/requerimento-solicitacoes-dialog/requerimento-solicitacoes-dialog.component';
import {ParecerRequerimentoConclusaoListComponent} from '../parecer-requerimento-conclusao/parecer-requerimento-conclusao-list/parecer-requerimento-conclusao-list.component';
import {DespachoComponent} from 'app/main/shared/components/despacho-cabecalho/despacho.component';
import {ParecerTecnicoConclusoesDialogComponent} from '../parecer-tecnico-conclusoes/parecer-tecnico-conclusoes-dialog/parecer-tecnico-conclusoes-dialog.component';
import {ConfirmacaoDialogComponent} from '../parecer-tecnico-conclusoes/parecer-tecnico-conclusoes-dialog/confirmacao-dialog/confirmacao-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        ParecerTecnicoCondicionantesListComponent,
        ParecerTecnicoConclusoesComponent,
        RequerimentoSolicitacoesComponent,
        RequerimentoSolicitacoesDialogComponent,
        ParecerRequerimentoConclusaoListComponent,
        ParecerTecnicoConclusoesDialogComponent,
        ConfirmacaoDialogComponent,

    ],
    imports: [
        CoreModule,
        SharedModule,
        ParecerTecnicoCondicionantesListModule,
        MatDatepickerModule,
        MatTooltipModule
    ],
    exports: [ParecerTecnicoCondicionantesListComponent,
        ParecerTecnicoConclusoesComponent,
        RequerimentoSolicitacoesComponent,
        RequerimentoSolicitacoesDialogComponent,
        ParecerTecnicoConclusoesDialogComponent,
        ParecerRequerimentoConclusaoListComponent,
        ConfirmacaoDialogComponent],
    entryComponents: [ParecerTecnicoCondicionantesListComponent,
        ParecerTecnicoConclusoesComponent,
        RequerimentoSolicitacoesComponent,
        RequerimentoSolicitacoesDialogComponent,
        ParecerTecnicoConclusoesDialogComponent,
        ParecerRequerimentoConclusaoListComponent,
        ConfirmacaoDialogComponent,
        DespachoComponent]
})
export class ParecerTecnicoDetailModule {
}
