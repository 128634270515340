import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {FeicaoRequerimento} from './feicao-requerimento.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Requerimento} from '../requerimentos/requerimento.model';
import {FeicaoGeometriaDto} from '../../shared/models/feicao-geometria-dto.model';

@Injectable({
    providedIn: 'root'
})
export class FeicaoRequerimentoService extends BaseService<FeicaoRequerimento> {
    constructor(protected injector: Injector) {
        super(
            '/api/feicoes-requerimentos',
            injector,
            FeicaoRequerimento.prototype,
            FeicaoRequerimento.fromJson
        );
    }

    getByRequerimento(
        requerimento: Requerimento
    ): Observable<FeicaoRequerimento[]> {
        const url = `${this.urlResource}/requerimento/${requerimento.id}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createMultipart(
        feicaoRequerimento: FeicaoRequerimento,
        metodoIncluirGeometria: string,
        multipartFile: Blob
    ): Observable<FeicaoRequerimento> {
        return this.http
            .post(
                this.urlResource,
                this.montarFormData(
                    {
                        requerimentoId: feicaoRequerimento.requerimento.id,
                        itemGrupoFeicaoTemplateId:
                        feicaoRequerimento.itemGrupoFeicaoTemplate.id
                    },
                    metodoIncluirGeometria,
                    multipartFile
                )
            )
            .pipe(
                map(FeicaoRequerimento.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateMultipart(
        feicaoRequerimento: FeicaoRequerimento,
        metodoIncluirGeometria: string,
        multipartFile: Blob
    ): Observable<FeicaoRequerimento> {
        const url = `${this.urlResource}/${feicaoRequerimento.id}`;
        return this.http
            .put(
                url,
                this.montarFormData(
                    {
                        id: feicaoRequerimento.id,
                        requerimentoId: feicaoRequerimento.requerimento.id,
                        itemGrupoFeicaoTemplateId:
                        feicaoRequerimento.itemGrupoFeicaoTemplate.id
                    },
                    metodoIncluirGeometria,
                    multipartFile
                )
            )
            .pipe(
                map(() => feicaoRequerimento),
                catchError(this.handleError.bind(this))
            );
    }

    private montarFormData(
        feicaoRequerimentoDTO: any,
        metodoIncluirGeometria: string,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        formData.append('metodo', metodoIncluirGeometria);
        const feicaoRequerimentoParam = new Blob(
            [JSON.stringify(feicaoRequerimentoDTO)],
            {type: 'application/json'}
        );
        formData.append('feicaoRequerimentoDTO', feicaoRequerimentoParam);
        return formData;
    }

    adicionarPontoLicenciamento(feicaoGeometriaDto: FeicaoGeometriaDto): any {
        const url = `${this.urlResource}/ponto-licenciamento`;
        return this.http.post(url, feicaoGeometriaDto).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    atualizarPonto(feicaoRequerimento: FeicaoRequerimento): any {
        const url = `${this.urlResource}/${feicaoRequerimento.id}`;
        return this.http.put(url, feicaoRequerimento).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    excluirPonto(idFeicao: number): any {
        const url = `${this.urlResource}/deletar-ponto-licenciamento/${idFeicao}`;
        return this.http.delete(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getByRequerimentoLac(requerimento: Requerimento): Observable<FeicaoGeometriaDto> {
        const url = `${this.urlResource}/requerimento-licenciamento/${requerimento.id}`;
        return this.http
            .get(url).pipe(
                map(response => response),
                catchError(this.handleError)
            );
    }
}
