import {ChangeDetectionStrategy, Component, Injector, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog, MatStepper} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {fuseAnimations} from '@fuse/animations';
import {Pessoa} from 'app/main/shared/models/pessoa.model';
import {Subject} from 'rxjs';
import {RequerimentoService} from '../../requerimento.service';
import {BaseTabRequerimentoComponent} from '../base-requerimento.component';
import {ResponsavelTecnico} from '../../../../shared/models/responsavel-tecnico.model';
import {RepresentanteLegal} from '../../../../shared/models/representante-legal.model';
import {RedirectUtil} from '../../../../shared/util/RedirectUtil';
import {RequerimentoSteps} from '../requerimento.steps';
import {ErrosAbaRequerimento} from '../requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import { PessoaCorporativoService } from 'app/main/shared/services/pessoa-corporativo.service';
import { PessoaCorporativo } from 'app/main/shared/models/pessoa-corporativo.model';
import { DadosContatoPessoaDTO } from 'app/main/shared/models/dados-contato-pessoa-dto.model';
import { EnumTipoEndereco } from 'app/main/shared/enums/tipo-endereco.enum';
import { CommonsUtil } from 'app/main/shared/util/commons-util';
import {TipoProcessoEnum} from "../../../tipos-processo/tipo-processo.enum";
import {ReqTituloVinculadoService} from "../../../tarefas/shared/service/req-titulo-vinculado.service";
import {RequerimentoTituloVinculado} from "../../../../shared/models/requerimento-titulo-vinculado.model";
import {TermoReferenciaService} from "../../../termos-referencia/termo-referencia.service";

/**
 * Documento de Requisitos
 * SEMA_SIGA_02_EspecificacaoFuncional_CadastroRequerimento
 * Pag.63
 */

@Component({
    selector: 'app-requerimento-responsaveis',
    templateUrl: 'requerimento-responsaveis.component.html',
    styleUrls: ['requerimento-responsaveis.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class RequerimentoResponsaveisComponent extends BaseTabRequerimentoComponent implements OnInit {

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        protected requerimentoService: RequerimentoService,
        private pessoaCorpporativoService: PessoaCorporativoService,
        private activatedRoute: ActivatedRoute,
        private reqTituloVinculadoService: ReqTituloVinculadoService,
        private termoReferenciaService: TermoReferenciaService,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.RESPONSAVEIS.component = this;
    }

    @Input()
    public stepper: MatStepper;
    @Output()
    salvarSubject: Subject<any> = new Subject();
    @Output()
    dirtySubject: Subject<boolean> = new Subject();

    pessoasImovel: any[] = [];

    responsaveisTecnicosAnexados: ResponsavelTecnico[] = [];

    representantesLegaisAnexados: RepresentanteLegal[];

    interessadoSelecionado: Pessoa = null;
    representanteLegal: Pessoa = null;
    reqVinculadoTramitacao: RequerimentoTituloVinculado[] = [];

    habilitarAlteracaoResponsavelRepresentante: boolean = false;

    // tabs
    tabIndex = 0;
    comparadorInteressadoPessoaImovel = (a: any, b: any) => a && b ? a.id === b.id : a === b;

    async ngOnInit() {
        this.requerimento = await this.requerimentoService.getById(this.requerimento.id).toPromise()
        this.requerimentoService.getProprietariosImovel(this.requerimento).subscribe(
            pessoas => this.pessoasImovel = pessoas,
            () => this.snackBarService.showError('Erro ao obter os responsáveis do imóvel do requerimento.')
        );
        this.listarResponsaveisTecnicos();
        this.listarRepresentantesLegais();
        this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, "LP_TRAMITACAO").subscribe( req => {
            this.reqVinculadoTramitacao = req;
        })

        this.aplicarRegrasObjetivosEspecificos();
    }

    //Metodo criado para aplicar regras baseadas em objetivos especificos, para os bugs 9963, 9952, 10114 e
    // demais solicitações que possam vir a acontecer.
    aplicarRegrasObjetivosEspecificos() {
        if (this.objetivoLicenciamento.find(obj =>
            obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação LOPM - Licença de Operação de Pesquisa Mineral'.toLowerCase()))) {
            this.habilitarAlteracaoResponsavelRepresentante = true;
        }else if (this.objetivoLicenciamento.find(obj =>
            obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de LP - Licença Prévia'.toLowerCase()))) {
            this.habilitarAlteracaoResponsavelRepresentante = true;
        }else if (this.objetivoLicenciamento.find(obj =>
            obj.objetivo.objetivo.descricao.toLowerCase().includes('Renovação de LI - Licença de Instalação'.toLowerCase()))) {
            this.habilitarAlteracaoResponsavelRepresentante = true;
        }else if (this.objetivoLicenciamento.find(obj =>
            obj.objetivo.objetivo.descricao.toLowerCase().includes('LOPM - Licença de Operação de Pesquisa Mineral de ampliação'.toLowerCase()))) {
            this.habilitarAlteracaoResponsavelRepresentante = true;
        }else if (this.objetivoLicenciamento.find(obj =>
            obj.objetivo.objetivo.descricao.toLowerCase().includes('LP - Licença Prévia de ampliação'.toLowerCase()))) {
            this.habilitarAlteracaoResponsavelRepresentante = true;
        }else if (this.objetivoLicenciamento.find(obj =>
            obj.objetivo.objetivo.descricao.toLowerCase().includes('LI - Licença de Instalação de ampliação'.toLowerCase()))) {
            this.habilitarAlteracaoResponsavelRepresentante = true;
        }
    }

    isSomenteVisualizacaoEspecifico(): boolean {
        return this.requerimento && this.requerimento.tipoProcesso.id == TipoProcessoEnum.LICENCIAMENTO_TRIFASICO_LAS &&
            ((this.requerimento.tipoRequerimento.isFluxoAlterar && this.regrasSuimis != "suimisFluxoAlteracaoComProtocoloLp") ||
            (this.requerimento.tipoRequerimento.isFluxoRenovar && !this.altDocResp) ||
            this.requerimento.tipoRequerimento.isFluxoCancelar ||
            this.requerimento.tipoRequerimento.isFluxoReabilitar);
    }

    listarResponsaveisTecnicos(): void {
        this.requerimentoService
            .listarResponsaveisTecnicos(this.requerimento.id).subscribe((content) => {
            this.responsaveisTecnicosAnexados = content;
        });
    }

    listarRepresentantesLegais(): void {
        this.requerimentoService
            .listarRepresentantesLegais(this.requerimento.id).subscribe((content) => {
            this.representantesLegaisAnexados = content;
        });
    }

    private async validaDadosInteressado(){
        let isDadosValidos: boolean = true;
        this.pessoaCorpporativoService
            .getDadosContatoPessoa(this.interessadoSelecionado.idCorporativo).subscribe((resp) => {
            resp.emailPessoaDTO && resp.emailPessoaDTO.tipo == 'PRINCIPAL' ? isDadosValidos = true : (
                isDadosValidos = false,
                this.snackBarService.showError(`O usuário ${CommonsUtil.cpfCnpjMask(this.interessadoSelecionado.cpfCnpj)} não possui um e-mail principal válido.`)
            ); 
            resp.enderecoDTO && resp.enderecoDTO.tipo.toString() == 'PRINCIPAL' ? isDadosValidos = true : (
                isDadosValidos = false,
                this.snackBarService.showError(`O usuário ${CommonsUtil.cpfCnpjMask(this.interessadoSelecionado.cpfCnpj)} não possui um endereço principal válido.`)
            );
            resp.telefoneDTO !== null ? isDadosValidos = true : (
                isDadosValidos = false,
                this.snackBarService.showError(`O usuário ${CommonsUtil.cpfCnpjMask(this.interessadoSelecionado.cpfCnpj)} não possui um contato principal válido.`)
            )
        });

        if(this.requerimento.termoReferencia){
            let termoReferenciaRequerente = await this.termoReferenciaService.buscarTermoReferenciaRequerente(this.requerimento.termoReferencia.id).toPromise();

            if(termoReferenciaRequerente){
                termoReferenciaRequerente.requerente.cpfCnpj == this.interessadoSelecionado.cpfCnpj ? isDadosValidos = true :
                    (isDadosValidos = false, this.snackBarService.showError("O usuário interessado selecionado diverge do usuário interessado do Termo de Referência (EIA/RIMA) informado na Aba 1 - Formulário de Requerimento."));
            }else{
                this.snackBarService.showError("Não foi encontrado o Termo de Referência Específico do Requerente.")
                isDadosValidos = false
            }
        }

        if(this.reqVinculadoTramitacao.length > 0){
            if(this.interessadoSelecionado.cpfCnpj !== this.reqVinculadoTramitacao[0].requerimentoVinculado.pessoa.cpfCnpj){
                isDadosValidos = false;
                this.snackBarService.showError('Foi identificado que o interessado associado ao requerimento diverge do informado ' +
                    'no processo da LP - Licença Prévia que encontra se em tramitação.')
            }
        }
        return isDadosValidos;
    }

    async postInteressado() {
        if(!await this.validaDadosInteressado()) {
            return;
        }else{
            if (this.interessadoSelecionado instanceof Pessoa) {
                const idTarefa = this.activatedRoute.snapshot.url[3].path;
                this.requerimentoService.postInteressado(this.requerimento.id, this.interessadoSelecionado, idTarefa).subscribe(result => {
                    this.requerimento = result;
                    if(this.requerimentoLicenciamento){
                        this.requerimentoLicenciamento.requerimento = this.requerimento;
                    }
                }, (e) => this.snackBarService.showError('Ocorreu um erro ao definir o interessado.', e));
            } else {
                this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            }
        }
    }

    fechar(): void {
        RedirectUtil.redirecionaDashboardCamunda();
    }

    async validarAba(erros: ErrosAbaRequerimento): Promise<any> {

        // Validações do Interessado
        if (!this.requerimento.pessoa) {
            erros.push('É obrigatório selecionar o Interessado.', false);
        }

        // Validações do Responsável Técnico
        if (!this.responsaveisTecnicosAnexados.length) {
            erros.push('O Requerimento precisa de pelo menos um Responsável Técnico associado.', false);
        }
        
        if (this.responsaveisTecnicosAnexados.length && this.responsaveisTecnicosAnexados.length > 0) {
            for (const rt of this.responsaveisTecnicosAnexados) {
                const anexos = await this.requerimentoService.listarDocumentosDoResponsavelTecnico(
                    this.requerimento.id,
                    rt.pessoa.cpfCnpj).toPromise();
                if (!anexos.length) {
                    erros.push('É necessário anexar os documentos do Responsável Técnico associado. ' + rt.pessoa.nome, false);
                }
            }
        }

        // Validações do Responsável Legal
        if (this.requerimento.pessoa && this.requerimento.pessoa.tipo === 'PJ' && !this.representantesLegaisAnexados.length) {
            erros.push('O Requerimento precisa de um Representante Legal associado.', false);
        }
        if (this.representantesLegaisAnexados.length && this.representantesLegaisAnexados.length > 0) {
            for (const rl of this.representantesLegaisAnexados) {
                const anexos = await this.requerimentoService.listarDocumentosDoRepresentanteLegal(
                    this.requerimento.id,
                    rl.pessoa.cpfCnpj).toPromise();
                if (!anexos.length) {
                    erros.push('É necessário anexar os documentos do Responsável Legal associado. ' + rl.pessoa.nome, false);
                }
            }
        }
        this.requerimento = this.requerimento
    }
}
