import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {GrupoFeicaoTemplateService} from 'app/main/pages/feicoes-template/feicao-template.service';
import {FeicaoRequerimento} from 'app/main/pages/feicoes-requerimentos/feicao-requerimento.model';
import {FeicaoRequerimentoService} from 'app/main/pages/feicoes-requerimentos/feicao-requerimento.service';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {
    ErrosAbaRequerimento
} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {FeicaoGeometriaDto} from '../../../../shared/models/feicao-geometria-dto.model';
import {GeometriaDTO} from 'app/main/shared/models/geometria-dto.model';
import {
    ConfirmarExclusaoPontoLicenciamentoDialogComponent
} from './confirmar-exclusao-ponto-dialog/confirmar-exclusao-ponto-licenciamento-dialog.component';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {ImovelService} from '../../shared/requerimento-localizacao/shared/imovel.service';
import {Camada, GeometriaMapa, MapaPadraoComponent, PontoMapa} from '@sema-geo/sema-geoportal';
import {
    DialogEditarFeicaoComponent,
    DialogEditarFeicaoData
} from '../../../tarefas/requerimento-analise/dialog-editar-feicao/dialog-editar-feicao.component';
import {take} from 'rxjs/operators';
import {Imovel} from "../../shared/requerimento-localizacao/shared/imovel.model";
import {CommonsUtil} from "../../../../shared/util/commons-util";

@Component({
    selector: 'app-requerimento-geo-licenciamento',
    templateUrl: 'requerimento-geo-licenciamento.component.html',
    styleUrls: ['requerimento-geo-licenciamento.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoGeoLicenciamentoComponent extends BaseTabRequerimentoComponent implements OnInit {

    referenciasTemporarias: Camada = {
        title: 'Referências temporárias',
        name: 'temporario',
        expandGroup: true,
        permissao: {
            remover: true,
            ponto: true,
            poligono: true,
            linha: true,
            buffer: true,
            upload: true,
            exportar: true
        },
        camadas: []
    };

    localizacao: Camada = {
        title: 'Localização',
        name: 'localizacao',
        geometryGroup: true,
        expandGroup: true,
        permissao: {
            exportar: true
        }
    };

    feicoesRequerimento: Camada = {
        title: 'Feições do Requerimento',
        name: 'requerimento',
        camadas: [],
        expandGroup: true,
        permissao: {
            upload: true,
            ponto: true,
            remover: true,
            exportar: true
        }
    };

    camadaTrabalho: Camada = {
        title: 'Camada de trabalho',
        name: 'trabalho',
        geometryGroup: true,
        expandGroup: true,
        camadas: [this.referenciasTemporarias, this.feicoesRequerimento, this.localizacao]
    };

    @ViewChild('mapaPadraoComponent', {static: false})
    componenteMapa: MapaPadraoComponent;

    camadas: Camada[] = [this.camadaTrabalho];
    camadaSelecionada: Camada;
    imoveisDoRequerimento: Imovel[] = [];

    feicaoGeometriaDto: FeicaoGeometriaDto[] = [];
    temDocumento = true;

    constructor(
        protected feicaoRequerimentoService: FeicaoRequerimentoService,
        protected grupoFeicaoTemplateService: GrupoFeicaoTemplateService,
        protected formBuilder: FormBuilder,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected imovelService: ImovelService,
        injector: Injector,
    ) {
        super(injector);
        RequerimentoSteps.GEO.component = this;
    }

    ngOnInit(): void {
        this.listarFeicoesRequerimento().then();
    }

    adicionarPonto(geometria: GeometriaMapa): void {
        if (this.camadaSelecionada === this.referenciasTemporarias) {
            this.referenciasTemporarias.camadas.push(geometria);
            geometria.title = geometria.tipoGeometria;
            geometria.permissao = {
                remover: true
            };
            this.camadas = [...this.camadas];
        } else {
            const dialogRef = this.dialog.open(DialogEditarFeicaoComponent, {
                width: '900px',
                data: {
                    tamanho: 255,
                    geometria: geometria,
                    onRemoveFeature: this.excluirPontoDialog
                } as DialogEditarFeicaoData
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result.success) {
                    const ponto = geometria as PontoMapa;
                    const novoPonto = new FeicaoGeometriaDto();
                    novoPonto.feicaoRequerimento = new FeicaoRequerimento();
                    novoPonto.geometriaDTO = new GeometriaDTO();
                    novoPonto.feicaoRequerimento.titulo = result.titulo;
                    novoPonto.feicaoRequerimento.requerimento = this.requerimento;
                    novoPonto.geometriaDTO.latitude = ponto.latitude;
                    novoPonto.geometriaDTO.longitude = ponto.longitude;

                    this.feicaoRequerimentoService.adicionarPontoLicenciamento(novoPonto).pipe(
                        take(1)
                    ).subscribe(() => {
                        this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                        this.listarFeicoesRequerimento();
                    }, error => this.snackBarService.showError(error.errors[0]));
                } else {
                    this.componenteMapa.deleteFeature(geometria);
                }
            });
        }
    }

    excluirPontoDialog = (geometria: GeometriaMapa): void => {
        const dialogRef = this.dialog.open(ConfirmarExclusaoPontoLicenciamentoDialogComponent, {
            width: '450px',
            data: {
                denominacao: geometria.extra.feicaoRequerimento.titulo,
                pontoGeoDto: geometria.extra.geometriaDTO,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.feicaoRequerimentoService.excluirPonto(geometria.extra.feicaoRequerimento.id).pipe(
                    take(1)
                ).subscribe(() => {
                    this.snackBarService.showSuccess('Ponto excluído com sucesso.');
                    this.feicaoGeometriaDto = [];
                    this.listarFeicoesRequerimento();
                }, (error) => {
                    this.snackBarService.showError(error);
                });
            }
        });
    }

    onEditarFeicao(geometria: GeometriaMapa): void {
        const dialogRef = this.dialog.open(DialogEditarFeicaoComponent, {
            width: '900px',
            data: {
                denomicacao: geometria.extra.feicaoRequerimento.titulo,
                tamanho: 255,
                geometria: geometria,
                onRemoveFeature: this.excluirPontoDialog
            } as DialogEditarFeicaoData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result.success) {
                geometria.extra.feicaoRequerimento.titulo = result.titulo;

                this.feicaoRequerimentoService.atualizarPonto(geometria.extra.feicaoRequerimento).subscribe(() => {
                    this.snackBarService.showSuccess('Ponto atualizado com sucesso!');
                    this.listarFeicoesRequerimento();
                }, e => this.snackBarService.showError('Erro ao alterar a geometria.', e));

            }
        });
    }

    validarAba(erros: ErrosAbaRequerimento): void {
        if (this.feicaoGeometriaDto.length === 0) {
            erros.push('É obrigatório salvar um ponto antes de continuar.', true);
        }
    }

    importar = (file: File): void => {
        this.componenteMapa.importarFile(file);
    }

    onSelectCamada(camadaSelecionada: Camada): void {
        this.camadaSelecionada = camadaSelecionada;
    }

    async listarFeicoesRequerimento(): Promise<void> {
        const feicoes = await this.feicaoRequerimentoService.getByRequerimentoLac(this.requerimento).toPromise();
        if (feicoes) {
            this.feicaoGeometriaDto = [];
            this.feicaoGeometriaDto.push(feicoes);
        }

        this.imoveisDoRequerimento = await this.imovelService.buscarTodosPorRequerimentoComGeometrias(this.requerimento.id).toPromise();
        this.atualizarMapa();
    }

    atualizarMapa(): void {
        this.componenteMapa.getSource().clear();
        // Feições do imóvel.

        this.localizacao.camadas = [];
        this.imoveisDoRequerimento.forEach((imovel) => {
            imovel.geometrias.forEach(geometria => {
                const geometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt);
                geometriaMapa.id = geometria.id;
                geometriaMapa.permissao = {
                    remover: true
                };
                geometriaMapa.extra = geometria;
                geometriaMapa.extra['imovel'] = imovel;
                if (imovel.tipo === 'RURAL') {
                    const imovelPrincipal = imovel.imovelPrincipal ? '*' : '';
                    geometriaMapa.title = `${imovel.numeroCAR} ${imovelPrincipal}`;
                    geometriaMapa.propriedades = {
                        id: geometria.id,
                        Nome: geometriaMapa.title,
                        'Imóvel': imovel.denominacao
                    };
                } else if (imovel.tipo === 'URBANO') {
                    geometriaMapa.title = 'Área do imóvel urbano';
                    geometriaMapa.propriedades = {
                        id: geometria.id,
                        Nome: geometriaMapa.title,
                        'Imóvel': imovel.denominacao
                    };

                    // Só permitir um único polígono para o imóvel urbano.
                    this.localizacao.permissao.poligono = false;
                } else if (imovel.tipo === 'OBRA') {
                    geometriaMapa.title = geometriaMapa.tipoGeometria;
                    geometriaMapa.propriedades = {
                        id: geometria.id,
                        'Imóvel': imovel.denominacao
                    };
                    this.localizacao.permissao.ponto = true;
                    this.localizacao.permissao.linha = true;
                    this.localizacao.permissao.poligono = true;
                }
                geometriaMapa.visualizacao = {
                    showCheckbox: true,
                    showFeature: true
                }
                geometriaMapa['expandGroup'] = true;
                geometriaMapa.feature.setStyle(this.componenteMapa.defaultStyle)
                this.localizacao.camadas.push(geometriaMapa);
            });
        });

        // Feições do requerimento.
        this.feicoesRequerimento.camadas.forEach(this.componenteMapa.deleteFeature);
        this.feicoesRequerimento.camadas = [];
        for (let i = this.feicoesRequerimento.camadas.length - 1; i > 0; i--) {
            if (this.feicoesRequerimento.camadas[i] instanceof GeometriaMapa) {
                this.feicoesRequerimento.camadas = this.feicoesRequerimento.camadas.splice(i, 1);
            }
        }
        this.feicoesRequerimento.camadas.sort((a, b) => CommonsUtil.comparaStrings(a['title'], b['title']));
        // Reinicia as permissões do componente.
        this.feicoesRequerimento.permissao.ponto = true;
        this.feicoesRequerimento.permissao.upload = true;

        this.feicaoGeometriaDto.forEach(ponto => {
            // Só permite a adição de um único ponto.
            this.feicoesRequerimento.permissao.ponto = false;
            this.feicoesRequerimento.permissao.upload = false;

            const p = this.componenteMapa.criarPonto(ponto.feicaoRequerimento.id, ponto.geometriaDTO.latitude, ponto.geometriaDTO.longitude);
            p.permissao.remover = true;
            p.permissao.editar = true;
            p.extra = ponto;
            p.title = ponto.feicaoRequerimento.titulo || p.tipoGeometria;
            p.propriedades = {
                ID: ponto.feicaoRequerimento.id,
                Deniminação: ponto.feicaoRequerimento.titulo,
            };

            this.feicoesRequerimento.camadas.push(p);
        });

        this.feicoesRequerimento.camadas.forEach((camada) => {
            camada.camadas.sort((a, b) =>  a.id - b.id);
        })

        this.camadas = [...this.camadas];
        this.componenteMapa.fit();
    }

    onClearAll = (): void => {
        if (this.camadaSelecionada === this.referenciasTemporarias) {
            this.referenciasTemporarias.camadas = [];
            this.camadas = [...this.camadas];
        } else {
            this.feicoesRequerimento.camadas.forEach(this.excluirPontoDialog);
        }
    }
}
