import {BaseModel} from 'app/main/shared/models/base.model';
import {FormaOcupacao} from 'app/main/pages/formas-ocupacao/forma-ocupacao.model';
import {Pessoa} from 'app/main/shared/models/pessoa.model';

export class DocumentoImovel extends BaseModel {
    public arquivo;
    public modified: boolean;
    public tempName: string = null;

    constructor(
        public id?: number,
        public titulo?: string,
        public nomeArquivo?: string,
        public imovel?: any,
        public chave?: string,
        public sigiloso?: boolean,
        public formaOcupacao?: FormaOcupacao,
        public pessoa?: Pessoa
    ) {
        super();
    }

    static fromJson(json: any): DocumentoImovel {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoImovel(
            json.id,
            json.titulo,
            json.nomeArquivo,
            json.imovel,
            json.chave,
            json.sigiloso,
            FormaOcupacao.fromJson(json.formaOcupacao),
            Pessoa.fromJson(json.pessoa)
        );
    }

}
