import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {RequerimentoFormularioLicenciamentoService} from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service';
import {EspelhoOutorgaDto} from 'app/main/pages/requerimentos/requerimento-outorga/espelho-outorga-dto.model';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {TipoProcesso} from 'app/main/pages/tipos-processo/tipo-processo.model';
import {FormularioReqLicenciamentoDto} from 'app/main/shared/models/formulario-req-licenciamento.dto';
import {AtividadeArrecadacaoService} from 'app/main/shared/services/arrecadacao-service/atividade-arrecadacao.service';
import {RequerimentoLicenciamento} from "../../../../requerimentos/requerimento-licenciamento/requerimento-licenciamento.model";
import {RequerimentoLicenciamentoService} from "../../../../requerimentos/requerimento-licenciamento/requerimento-licenciamento.service";
import {ObjetivoLicenciamento} from "../../../../../shared/models/objetivo-licenciamento.model";
import {ObjetivoLicenciamentoService} from "../../../../../shared/services/objetivo-licenciamento.service";
import {ParametroObjetivo} from "../../../../../shared/models/licenciamento-config-models/parametro-objetivo.model";
import {ParametroObjetivoService} from "../../../../../shared/services/licenciamento-config-service/parametro-objetivo.service";
import {ObjetivoParametrizacaoObjetivoService} from "../../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service";
import {TitulacaoService} from "../../../../titulacoes/titulacao.service";


@Component({
    selector: 'app-lic-ambiental-atividades-objetivos',
    templateUrl: './atividades-objetivos.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LicAmbientalAtividadesObjetivosComponent implements OnInit {

    @Input() requerimento = new Requerimento();

    requerimentoOutorga: EspelhoOutorgaDto;
    tipoProcessos: Array<TipoProcesso> = [];
    displayedColumns: string[] = ['finalidade', 'atividade', 'ppd'];
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoDto;
    consultaAtividadeServico: any = [];
    validate = false;

    requerimentoLicenciamento: RequerimentoLicenciamento;
    requerimentoLicenciamentoList: RequerimentoLicenciamento[] = [];
    objetivosLicenciamento: ObjetivoLicenciamento[];
    parametroObjetivoLicenciamento: ParametroObjetivo[] = [];

    constructor(private atividadeArrecadacaoService: AtividadeArrecadacaoService,
                protected requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
                private objetivosLicenciamentoService: ObjetivoLicenciamentoService,
                private parametroObjetivoService: ParametroObjetivoService,
                private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
                private titulacoesService: TitulacaoService,) {
    }

    ngOnInit(): void {
        this.carregarListaDeAtividades();
        this.tipoProcessos.push(this.requerimento.tipoProcesso);
        this.validate = this.requerimento.tipoProcesso.tipo === 'Autorização/Outros documentos' ? false : true;

        if (this.requerimento.tipoProcesso.tipo === 'LICENCIAMENTO') {
            this.buscarDadosAtividadeRequerimentoLicenciamento();
        }
    }


    private carregarListaDeAtividades(): void {
        if (this.requerimento) {
            this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId(this.requerimento.id).subscribe(formularioReqLicenciamentoDto => {
                this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                this.atividadeArrecadacaoService.getAtividadeDto(this.formularioReqLicenciamentoDto.requerimentoLicenciamento.idAtividade,
                    this.formularioReqLicenciamentoDto.requerimentoLicenciamento.idGrupoAtividade)
                    .toPromise().then(async atividade => {
                    atividade['ppd'] = this.formularioReqLicenciamentoDto.requerimentoLicenciamento.ppd;
                    this.consultaAtividadeServico = [];
                    this.consultaAtividadeServico.push(atividade);
                });
            });
        }
    }

    async buscarDadosAtividadeRequerimentoLicenciamento() {
        this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
        this.requerimentoLicenciamentoList.push(this.requerimentoLicenciamento);
        if (this.requerimentoLicenciamento)
            this.objetivosLicenciamento = await this.objetivosLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();
        if (this.requerimento.idParametroObjetivo)
            this.parametroObjetivoLicenciamento.push(await this.parametroObjetivoService.getById(this.requerimento.idParametroObjetivo).toPromise());
        this.objetivosLicenciamento.forEach(async o => {
            o.titulacao = await this.titulacoesService.getById(await this.objetivoParametrizacaoObjetivoService.getIdTitulacaoByIdObjetivoLic(o.idObjetivoLicenciamentoAmbiental).toPromise()).toPromise();
        });
    }
}

