import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {OutraFinalidade} from '../models/outra-finalidade.model';
import {AtividadeServico} from '../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidades-outras/atividade-servico/models/atividade-servico.model';

@Injectable({
    providedIn: 'root'
})
export class AtividadeServicoService {

    private urlResource = `${environment.URL_GATEWAY}/api/atividades-servicos`;

    constructor(private http: HttpClient) {
    }

    getAllByOutraFinalidade(outraFinalidade: OutraFinalidade): Observable<AtividadeServico[]> {
        const url = `${this.urlResource}/outra-finalidade/${outraFinalidade.id}`;
        return this.http.get(url).pipe(
            map(this.jsonToResources),
            catchError(this.handleError)
        );
    }


    create(atividadeServico: AtividadeServico): Observable<AtividadeServico> {
        return this.http
            .post(this.urlResource, atividadeServico)
            .pipe(
                map(AtividadeServico.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    delete(atividadeServico: AtividadeServico): Observable<any> {
        const url = `${this.urlResource}/${atividadeServico.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonToResources(json: any): AtividadeServico[] {
        const resources = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: any) => resources.push(AtividadeServico.fromJson(e)));
        }
        return resources;
    }

    private handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
