import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {DocumentoRequerimento} from '../../../pages/documentos-requerimentos/documento-requerimento.model';
import {DocumentoRequerimentoService} from '../../../pages/documentos-requerimentos/documento-requerimento.service';
import {Observable} from 'rxjs/internal/Observable';
import {SnackBarService} from '../../snack-bar/snack-bar.service';

@Component({
    selector: 'file-selector',
    templateUrl: './file-selector.component.html',
    styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent implements OnInit, AfterViewInit {

    _formControl: FormControl;
    _nomeArquivo = '';
    _required: boolean;
    _isDocumentoAnexado: boolean;

    @Output() visualizarArquivo = new EventEmitter<any>();
    @Output() excluirArquivoEvent = new EventEmitter<any>();

    @Input() accept = '.pdf';
    @Input() labelField = 'Arquivo';
    @Input() isSomenteVisualizacao = false;

    public excluirArquivo(documento: any): void {
        this.excluirArquivoEvent.emit(documento);
        this._formControl.setValue(null);
    }

    @Input() set formCtrl(formControl: AbstractControl) {
        if (!(formControl instanceof FormControl)) {
            throw new Error('O parametro formControl deve ser do tipo "FormControl"');
        } else {
            this._formControl = formControl as FormControl;
            this._required = this._formControl.hasError('required');
        }
    }

    @Input() set value(documentoRequerimento: DocumentoRequerimento) {
        if (!this.isDoc(documentoRequerimento)) {
            throw new Error('O parametro value deve ser do tipo "DocumentoRequerimento"');
        } else {
            this._formControl = new FormControl(documentoRequerimento);
        }
    }

    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

    constructor(
        private documentoRequerimentoService: DocumentoRequerimentoService,
        private snackBarService: SnackBarService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        if (isNullOrUndefined(this._formControl)) {
            throw new Error('O parametro formControl não foi fornecido');
        }
        this._formControl.valueChanges.subscribe(value => this.onFormControlValuechange(value));
    }

    ngAfterViewInit(): void {
        if (this._formControl.value) {
            this.onFormControlValuechange(this._formControl.value);
        }
    }

    private onFormControlValuechange(value: any): void {
        if (value == null || !(value instanceof File)) {
            this.fileInput.nativeElement.value = '';
            this._nomeArquivo = '';
        }
        this._isDocumentoAnexado = !isNullOrUndefined(value) && this.isDoc(value);
        if (value instanceof File) {
            this._nomeArquivo = value.name;
        } else if (value && this.isDoc(value)) {
            this._nomeArquivo = value.nomeArquivo;
        }

        this.changeDetectorRef.detectChanges();
    }

    onFileSelected(event): void {
        if (event.target.files.length === 1) {
            const file = event.target.files[0];
            if (this._isDocumentoAnexado) {
                this.editarArquivo(this._formControl.value, file).subscribe(value => {
                    this._formControl.setValue(value);
                }, e => this.snackBarService.showError('Erro ao fazer upload do arquivo', e));
            } else {
                this._formControl.setValue(file);
            }
        } else {
            this._formControl.reset();
        }
        this.fileInput.nativeElement.value = '';
    }

    isDoc(arg: any): arg is DocumentoRequerimento {
        return arg.chave !== undefined && arg.id !== undefined && arg.nomeArquivo !== undefined;
    }

    clearFileInput(fileInput: HTMLInputElement): void {
        fileInput.value = '';
        this._formControl.setValue(null);
    }

    editarArquivo(value: any, mf: File): Observable<any> {
        if (this.isDoc(value)) {
            return this.documentoRequerimentoService.updateMultipart(value, mf);
        }
    }

    formHasError(): boolean {
        return this._formControl.hasError('required');
    }
}
