import {BaseModel} from 'app/main/shared/models/base.model';

export class EmpreendimentoEnergiaEolica extends BaseModel {
    constructor(
        public id?: number,
        // public tipo?: number,
        public tipoSistemaConexao?: number,
        public paralelismoPermanente?: boolean,
        public fabricanteTurbinas?: string,
        public modelo?: string,
        public classeVentoIEC?: string,
        public potenciaInstaladaDeclarada?: number,
        public capacidadeInstaladaUnidadeGeradora?: number,
        public velocidadeVentoNominal?: number,
        public velocidadeVentoCorte?: number,
        public numeroUnidadesGeradoras?: number,
        public fatorCapacidade?: number,
        public velocidadeVentoPartida?: number,
        public possuiMultiplicadorVelocidade?: boolean,
        public razao?: string,
        public tipoControlePotencia?: number,
        public quantidadeTurbinas?: number,
        public potenciaNominal?: number,
        public potenciaReferencia?: number,
        public maxPotenciaGerada?: number,
        public diametroRotor?: number,
        public areaVarridaRotor?: number,
        public faixaRotadaRotor?: number,
        public numeroPasRotor?: number,
        public alturaEixoRotor?: number,
        public tipoTorres?: string,
        public materialTorres?: string,
        public nivelRuidoBaseTorre?: string,
        public referenciaVelocidadeVento?: string,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoEnergiaEolica {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoEnergiaEolica(
            json.id,
            // json.tipo,
            json.tipoSistemaConexao,
            json.paralelismoPermanente,
            json.fabricanteTurbinas,
            json.modelo,
            json.classeVentoIEC,
            json.potenciaInstaladaDeclarada,
            json.capacidadeInstaladaUnidadeGeradora,
            json.velocidadeVentoNominal,
            json.velocidadeVentoCorte,
            json.numeroUnidadesGeradoras,
            json.fatorCapacidade,
            json.velocidadeVentoPartida,
            json.possuiMultiplicadorVelocidade,
            json.razao,
            json.tipoControlePotencia,
            json.quantidadeTurbinas,
            json.potenciaNominal,
            json.potenciaReferencia,
            json.maxPotenciaGerada,
            json.diametroRotor,
            json.areaVarridaRotor,
            json.faixaRotadaRotor,
            json.numeroPasRotor,
            json.alturaEixoRotor,
            json.tipoTorres,
            json.materialTorres,
            json.nivelRuidoBaseTorre,
            json.referenciaVelocidadeVento,
            json.requerimento
        );
    }

    static fromJsons(json: any): EmpreendimentoEnergiaEolica[] {
        const EmpreendimentoEnergiaEolicas: EmpreendimentoEnergiaEolica[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(EmpreendimentoEnergiaEolica => EmpreendimentoEnergiaEolicas.push(this.fromJson(EmpreendimentoEnergiaEolica)));
            return EmpreendimentoEnergiaEolicas;
        }
    }

}
