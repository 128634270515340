export class JuntadaProcesso {
    constructor(
        public id?: number,
        public motivoJuntada?: any,
        public titulo?: string,
        public justificativa?: string,
        public requerimentoPrincipal?: any,
        public sigiloso?: string,
        public dataCadastro?: Date,
        public arquivo?: any,
    ) {
    }

    static fromJson(json: any): JuntadaProcesso {
        if (json === undefined || json === null) {
            return null;
        }
        return new JuntadaProcesso(
            json.id ,
            json.idMotivoJuntada,
            json.titulo,
            json.justificativa,
            json.requerimentoPrincipal,
            json.sigiloso,
            json.dataCadastro,
        );
    }
}
