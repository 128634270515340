import {Type} from '@angular/core';
import { AguaPassivoSupressaoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/agua-passivo-supressao/agua-passivo-supressao.component';
import { BaseAtividadeEmpreendimentoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/base-atividade-empreendimento.component';
import { EeEolicaComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-eolica/ee-eolica';
import { EeFotovoltaicaComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-fotovoltaica/ee-fotovoltaica.component';
import { EeHidricaComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-hidrica/ee-hidrica.component';
import { EeLinhaTransmissaoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-linha-transmissao/ee-linha-transmissao.component';
import { EeSubestacaoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-subestacao/ee-subestacao.component';
import { EeTermicaComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-termica/ee-termica.component';
import { GerenciamentoResiduosComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/gerenciamento-residuos/gerenciamento-residuos.component';
import { IndustriaBiocombustivelComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/industria-biocombustivel/industria-biocombustivel.component';
import { IndustriaFrigorificoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/industria-frigorifico/industria-frigorifico.component';
import { InfraObraFluviaisSaneamentoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infra-obra-fluviais-saneamentos/infra-obra-fluviais-saneamento.component';
import { InfraParcelSoloResComComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infra-parcel-solo-res-com/infra-parcel-solo-res-com.component';
import { InfraestruturaObrasViariasComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infraestrutura-obras-viarias/infraestrutura-obras-viarias.component';
import { MadeiraComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/madeira/madeira.component';
import { MineracaoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/mineracao/mineracao.component';
import { ResiduosAterroIndustrialUrbanoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-aterro-industrial-urbano/residuos-aterro-industrial-urbano.component';
import { ResiduosCemiterioCrematorioComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-cemiterio-crematorio/residuos-cemiterio-crematorio.component';
import { ResiduosGeralComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-geral/residuos-geral.component';
import { ResiduosRccComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-rcc/residuos-rcc.component';
import { ResiduosTransporteProdutosPerigososComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-transporte-produtos-perigosos/residuos-transporte-produtos-perigosos.component';
import { ResiduosTratamentoTermicoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-tratamento-termico/residuos-tratamento-termico.component';
import { ServicoCombustivelComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/servico-combustivel/servico-combustivel.component';
import { ServicoGeralComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/servico-geral/servico-geral.component';
import { SistemaTratamentoComponent } from 'app/main/pages/requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/sistema-tratamento/sistema-tratamento.component';
import { PredicateResolver } from 'app/main/pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/predicate.resolver';
import { AbaAtividadesDto } from 'app/main/shared/models/licenciamento-config-models/aba-atividades-dto.model';


function byFormularioAtividade(formularioAtividade: string, type: Type<BaseAtividadeEmpreendimentoComponent>):
    PredicateResolver<AbaAtividadesDto, Type<BaseAtividadeEmpreendimentoComponent>> {
    return {predicate: f => f.constante == formularioAtividade, resolved: type};
}

export class RequerimentoEmpreendimentoAtividadeFormulario {
    private static predicates: PredicateResolver<AbaAtividadesDto, Type<BaseAtividadeEmpreendimentoComponent>>[] = [
        byFormularioAtividade('residuos-aterro-industrial-urbano', ResiduosAterroIndustrialUrbanoComponent),
        byFormularioAtividade('residuos-cemiterio-crematorio', ResiduosCemiterioCrematorioComponent),
        byFormularioAtividade('servico-combustivel', ServicoCombustivelComponent),
        byFormularioAtividade('residuos-rcc', ResiduosRccComponent),
        byFormularioAtividade('industria-biocombustivel', IndustriaBiocombustivelComponent),
        byFormularioAtividade('industria-frigorifico', IndustriaFrigorificoComponent),
        byFormularioAtividade('servico-geral', ServicoGeralComponent),
        byFormularioAtividade('sistema-tratamento', SistemaTratamentoComponent),
        byFormularioAtividade('residuos-tratamento-termico', ResiduosTratamentoTermicoComponent),
        byFormularioAtividade('residuos-transporte-produtos-perigosos', ResiduosTransporteProdutosPerigososComponent),
        byFormularioAtividade('residuos-geral', ResiduosGeralComponent),
        byFormularioAtividade('agua-passivo-supressao', AguaPassivoSupressaoComponent),
        byFormularioAtividade('madeira', MadeiraComponent),
        byFormularioAtividade('infra-parcel-solo-res-com', InfraParcelSoloResComComponent),
        byFormularioAtividade('infraestrutura-obras-viarias', InfraestruturaObrasViariasComponent),
        byFormularioAtividade('ee-hidrica', EeHidricaComponent),
        byFormularioAtividade('ee-termica', EeTermicaComponent),
        byFormularioAtividade('ee-fotovoltaica', EeFotovoltaicaComponent),
        byFormularioAtividade('gerenciamento-residuos', GerenciamentoResiduosComponent),
        byFormularioAtividade('mineracao', MineracaoComponent),
        byFormularioAtividade('ee-linha-transmissao', EeLinhaTransmissaoComponent),
        byFormularioAtividade('ee-subestacao', EeSubestacaoComponent),
        byFormularioAtividade('ee-eolica', EeEolicaComponent),
        byFormularioAtividade('infra-obra-fluviais-saneamento', InfraObraFluviaisSaneamentoComponent),
    ];

    static get(requerimentoEmpreendimentoAtividade: AbaAtividadesDto): PredicateResolver<AbaAtividadesDto, Type<BaseAtividadeEmpreendimentoComponent>> {
        return this.predicates.find(r => r.predicate(requerimentoEmpreendimentoAtividade));
    }

}
