import {BaseModel} from 'app/main/shared/models/base.model';

export class TipoObra extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipo?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public outro?: boolean
    ) {
        super();
    }

    static fromJson(json: any): TipoObra {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoObra(
            json.id,
            json.descricao,
            json.tipo,
            json.dataInicio,
            json.dataFim,
            json.outro
        );
    }
}
