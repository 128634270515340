import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function numProcessoAnmDirective(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && control.value.length >= 3) {
            let tresPrimeirosDigitos = Number(control.value.substring(0, 3));
            if (tresPrimeirosDigitos != 966 &&
                tresPrimeirosDigitos != 967 &&
                tresPrimeirosDigitos != 866 &&
                tresPrimeirosDigitos != 867 &&
                tresPrimeirosDigitos != 868) {
                return {primeirosDigitos: true}
            }
        }
        return null;
    };
}
