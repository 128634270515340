import {NgModule} from '@angular/core';
import {MatDatepickerModule, MatInputModule, MatListModule, MatRadioModule, MatStepperModule} from '@angular/material';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {RequerimentoSharedModule} from '../shared/shared.module';
import {RequerimentoOutorgaComponent} from './requerimento-outorga.component';
import {RequerimentoFormularioOutorgaComponent} from './requerimento-formulario-outorga/requerimento-formulario-outorga.component';
import {RequerimentoGeoOutorgaComponent} from './requerimento-geo-outorga/requerimento-geo-outorga.component';
import {DatePipe} from '@angular/common';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ConfirmarExclusaoPontoDialogComponent} from './requerimento-geo-outorga/confirmar-exclusao-ponto-dialog/confirmar-exclusao-ponto-dialog.component';
import {ConfirmarAdicaoPontoDialogComponent} from './requerimento-geo-outorga/confirmar-adicao-ponto-dialog/confirmar-adicao-ponto-dialog.component';
import {ConfirmarAdicaoObjetivoRequerimentoOutorgaDialogComponent} from './requerimento-formulario-outorga/confirmar-adicao-objetivo-requerimento-outorga-dialog/confirmar-adicao-objetivo-requerimento-outorga-dialog.component';
import {ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogComponent} from './requerimento-formulario-outorga/confirmar-exclusao-objetivo-requerimento-outorga-dialog/confirmar-exclusao-objetivo-requerimento-outorga-dialog.component';
import {RequerimentoFinalidadeAtividadeComponent} from '../../../shared/components/requerimento-finalidade-atividade/requerimento-finalidade-atividade.component';
import {ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogComponent} from '../../../shared/components/requerimento-finalidade-atividade/confirmar-exclusao-requerimento-finalidade-atividade-dialog/confirmar-exclusao-requerimento-finalidade-atividade-dialog.component';
import {ConfirmarAdicaoRequerimentoFinalidadeAtividadeDialogComponent} from 'app/main/shared/components/requerimento-finalidade-atividade/confirmar-adicao-requerimento-finalidade-atividade-dialog/confirmar-adicao-requerimento-finalidade-atividade-dialog.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {RequerimentoTaxaOutorgaComponent} from './requerimento-taxa-outorga/requerimento-taxa-outorga.component';
import {RequerimentoTermoResponsabilidadeOutorgaComponent} from './requerimento-termo-responsabilidade-outorga/requerimento-termo-responsabilidade-outorga.component';
import {RequerimentoAtividadeOutorgaModule} from './requerimento-atividade-outorga/requerimento-atividade-outorga.module';
import {SemaGeoportalModule} from '@sema-geo/sema-geoportal';
import {AdicionarCopiaTituloComponent} from './requerimento-formulario-outorga/adicionar-copia-titulo/adicionar-copia-titulo.component';
import {ImportarPontosSimlamComponent} from './requerimento-geo-outorga/importar-pontos-simlam/importar-pontos-simlam.component';
import { EditarPontoDialogComponent } from './requerimento-geo-outorga/editar-ponto-dialog/editar-ponto-dialog.component';
import {DialogEditarFeicaoComponent} from '../../tarefas/requerimento-analise/dialog-editar-feicao/dialog-editar-feicao.component';
import {environment} from "../../../../../environments/environment";
import { DialogAjudaRequerimentoOutorgaComponent } from './dialog-ajuda-requerimento-outorga/dialog-ajuda-requerimento-outorga.component';

@NgModule({
    declarations: [
        RequerimentoOutorgaComponent,
        RequerimentoFormularioOutorgaComponent,
        RequerimentoFinalidadeAtividadeComponent,
        RequerimentoGeoOutorgaComponent,
        RequerimentoTaxaOutorgaComponent,
        RequerimentoTermoResponsabilidadeOutorgaComponent,
        ConfirmarAdicaoObjetivoRequerimentoOutorgaDialogComponent,
        ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogComponent,
        ConfirmarAdicaoRequerimentoFinalidadeAtividadeDialogComponent,
        ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogComponent,
        ConfirmarAdicaoPontoDialogComponent,
        ConfirmarExclusaoPontoDialogComponent,
        AdicionarCopiaTituloComponent,
        ImportarPontosSimlamComponent,
        EditarPontoDialogComponent,
        DialogEditarFeicaoComponent,
        DialogAjudaRequerimentoOutorgaComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        RequerimentoSharedModule,
        RequerimentoAtividadeOutorgaModule,
        MatStepperModule,
        MatListModule,
        MatRadioModule,
        MatInputModule,
        MatDatepickerModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        MatTooltipModule,
        OverlayModule,
        SemaGeoportalModule.forRoot(environment)
    ],
    providers: [DatePipe],
    exports: [
        RequerimentoOutorgaComponent,
        RequerimentoFormularioOutorgaComponent,
        RequerimentoFinalidadeAtividadeComponent,
        RequerimentoTaxaOutorgaComponent,
        RequerimentoTermoResponsabilidadeOutorgaComponent,
        ConfirmarAdicaoObjetivoRequerimentoOutorgaDialogComponent,
        ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogComponent,
        ConfirmarAdicaoRequerimentoFinalidadeAtividadeDialogComponent,
        ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogComponent,
        ConfirmarAdicaoPontoDialogComponent,
        ConfirmarExclusaoPontoDialogComponent,
        EditarPontoDialogComponent,
        DialogEditarFeicaoComponent,
    ],
    entryComponents: [
        RequerimentoOutorgaComponent,
        ConfirmarAdicaoObjetivoRequerimentoOutorgaDialogComponent,
        ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogComponent,
        ConfirmarAdicaoRequerimentoFinalidadeAtividadeDialogComponent,
        ConfirmarExclusaoRequerimentoFinalidadeAtividadeDialogComponent,
        ConfirmarAdicaoPontoDialogComponent,
        ConfirmarExclusaoPontoDialogComponent,
        AdicionarCopiaTituloComponent,
        ImportarPontosSimlamComponent,
        EditarPontoDialogComponent,
        DialogEditarFeicaoComponent,
        DialogAjudaRequerimentoOutorgaComponent
    ]
})
export class RequerimentoOutorgaModule {
}
