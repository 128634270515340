import {BaseModel} from "./base.model";

export class EmpreendimentoResiduosGerais extends BaseModel {
    constructor(
        public id?: number,
        public idRequerimento?: number,
        public recebimentoPossuiBalanco?: boolean,
        public recebimentoPossuiLaboratorioAnalise?: boolean,
        public recebimentoPossuiCobertura?: boolean,
        public recebimentoPossuiPisoImpermeabilizado?: boolean,
        public recebimentoPossuiAreaEsperaResiduos?: boolean,
        public recebimentoPossuiSistemaColeta?: boolean,
        public armazenamentoPossuiArmazenamentoResiduos?: boolean,
        public armazenamentoPossuiSistemaColeta?: boolean,
        public armazenamentoPossuiPisoImpermeabilizado?: boolean,
        public armazenamentoPossuiCobertura?: boolean,
        public armazenamentoPossuiBaciaContencao?: boolean,
        public armazenamentoVolumeBacia?: number,
        public manipulacaoPossuiAreaManipulacaoResiduos?: boolean,
        public manipulacaoPossuiSistemaVentilacao?: boolean,
        public manipulacaoPossuiPisoImpermeabilizado?: boolean,
        public manipulacaoPossuiControlePoluentes?: boolean,
        public manipulacaoPossuiCobertura?: boolean,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoResiduosGerais {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoResiduosGerais(
            json.id,
            json.idRequerimento,
            json.recebimentoPossuiBalanco,
            json.recebimentoPossuiLaboratorioAnalise,
            json.recebimentoPossuiCobertura,
            json.recebimentoPossuiPisoImpermeabilizado,
            json.recebimentoPossuiAreaEsperaResiduos,
            json.recebimentoPossuiSistemaColeta,
            json.armazenamentoPossuiArmazenamentoResiduos,
            json.armazenamentoPossuiSistemaColeta,
            json.armazenamentoPossuiPisoImpermeabilizado,
            json.armazenamentoPossuiCobertura,
            json.armazenamentoPossuiBaciaContencao,
            json.armazenamentoVolumeBacia,
            json.manipulacaoPossuiAreaManipulacaoResiduos,
            json.manipulacaoPossuiSistemaVentilacao,
            json.manipulacaoPossuiPisoImpermeabilizado,
            json.manipulacaoPossuiControlePoluentes,
            json.manipulacaoPossuiCobertura
        );
    }

    static fromJsons(json: any): EmpreendimentoResiduosGerais[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(EmpreendimentoResiduosGerais.fromJson(j)));
            return resources;
        }
    }
}