import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { take } from "rxjs/operators";
import { environment } from '../../../../../../../../environments/environment';
import { EnumOrigemTitulacao } from "../../../../../../shared/enums/EnumOrigemTitulacao";
import {
    AtividadeCalculoArrecadacao
} from "../../../../../../shared/models/arrecadacao-models/atividade-calculo-arrecadacao.model";
import {
    AtividadeProjectionArrecadacao
} from '../../../../../../shared/models/arrecadacao-models/atividade-projection-arrecadacao.model';
import {
    ParametroEntradaArrecadacao
} from "../../../../../../shared/models/arrecadacao-models/parametro-entrada-arrecadacao.model";
import { DocumentoProcesso } from '../../../../../../shared/models/documento-processo.model';
import { DominioDto } from "../../../../../../shared/models/dominio-dto.model";
import { EmissaoTitulo } from '../../../../../../shared/models/emissao-titulo.model';
import { FormularioReqLicenciamentoEmp } from "../../../../../../shared/models/formulario-req-licenciamento-emp.dto";
import { LancamentoTaxaDto } from '../../../../../../shared/models/lancamento-taxa-dto.model';
import { ObjetivoLicenciamento } from '../../../../../../shared/models/objetivo-licenciamento.model';
import { RequerimentoTituloVinculado } from '../../../../../../shared/models/requerimento-titulo-vinculado.model';
import { ArquivoService } from '../../../../../../shared/services/arquivo.service';
import {
    AtividadeArrecadacaoService
} from '../../../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service';
import {
    AtividadeCalculoArrecadacaoService
} from '../../../../../../shared/services/arrecadacao-service/atividade-calculo-arrecadacao.service';
import {
    AtividadeParametroEntradaArrecadacaoService
} from '../../../../../../shared/services/arrecadacao-service/atividade-parametro-entrada-arrecadacao.service';
import { TipoTitulacaoService } from "../../../../../../shared/services/arrecadacao-service/tipo-titulacao.service";
import { DominiosService } from "../../../../../../shared/services/dominios.service";
import { LancamentoTaxaService } from '../../../../../../shared/services/lancamento-taxa.service';
import {
    ObjetivoLicenciamentoAmbientalService
} from "../../../../../../shared/services/licenciamento-config-service/objetivo-licenciamento-ambiental.service";
import {
    ObjetivoParametrizacaoObjetivoService
} from "../../../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service";
import { ObjetivoLicenciamentoService } from '../../../../../../shared/services/objetivo-licenciamento.service';
import { SnackBarService } from '../../../../../../shared/snack-bar/snack-bar.service';
import { DocumentoProcessoService } from '../../../../../gerar-documento-publicacao/documento-processo.service';
import { LicencaFiscalizacao } from '../../../../../simlam/licenca-fiscalizacao.model';
import { SimlamService } from '../../../../../simlam/simlam.service';
import { EmissaoTituloService } from '../../../../../tarefas/shared/service/emissao-titulo.service';
import { ReqTituloVinculadoService } from '../../../../../tarefas/shared/service/req-titulo-vinculado.service';
import { TipoRequerimentoService } from "../../../../../tipos-requerimento/tipo-requerimento.service";
import { Titulacao } from "../../../../../titulacoes/titulacao.model";
import { TitulacaoService } from "../../../../../titulacoes/titulacao.service";
import { Requerimento } from '../../../../requerimento.model';
import { BaseTabRequerimentoComponent } from '../../../../shared/base-requerimento.component';
import { RequerimentoTitulacao } from "../../../../shared/requerimento-titulacao/shared/requerimento-titulacao.model";
import {
    RequerimentoTitulacaoService
} from "../../../../shared/requerimento-titulacao/shared/requerimento-titulacao.service";
import {
    ErrosAbaRequerimento
} from '../../../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { RequerimentoSteps } from '../../../../shared/requerimento.steps';
import { RequerimentoLicenciamento } from "../../../requerimento-licenciamento.model";
import { RequerimentoLicenciamentoService } from '../../../requerimento-licenciamento.service';
import { AbaReqFlorestalService } from './aba-req-florestal.service';
import { AbaReqFlorestalValidatorsService } from './aba-req-florestal.validators.service';
import {CommonsUtil} from '../../../../../../shared/util/commons-util';
import { DialogConfirmaExclusaoComponent } from '../dialog-confirma-exclusao/dialog-confirma-exclusao.component';


@Component({
    selector: 'app-requerimento-formulario-licenciamento-florestal',
    templateUrl: 'aba-req-florestal.component.html',
    styleUrls: ['aba-req-florestal.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [AbaReqFlorestalValidatorsService],
})
export class AbaReqFlorestalComponent extends BaseTabRequerimentoComponent implements OnInit {

    constructor(
        protected dialog: MatDialog,
        protected requerimentoService: AbaReqFlorestalService,
        protected requerimentoFormularioLicenciamentoService: AbaReqFlorestalService,
        protected requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private formBuilder: FormBuilder,
        private atividadeArrecadacaoService: AtividadeArrecadacaoService,
        private snackBarService: SnackBarService,
        private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private lancamentoTaxaService: LancamentoTaxaService,
        private emissaoTituloService: EmissaoTituloService,
        private reqTituloVinculadoService: ReqTituloVinculadoService,
        protected objetivoLicenciamentoAmbientalService: ObjetivoLicenciamentoAmbientalService,
        protected objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
        private requerimentoTitulacaoService: RequerimentoTitulacaoService,
        private licenciamentoTitulacaoService: TitulacaoService,
        private dominiosService: DominiosService,
        private tipoRequerimentoService: TipoRequerimentoService,
        private tipoTitulacaoService: TipoTitulacaoService,
        private atividadeCalculoArrecadacaoService: AtividadeCalculoArrecadacaoService,
        private atividadeParametroEntradaArrecadacaoService: AtividadeParametroEntradaArrecadacaoService,
        private titulacaoService: TitulacaoService,
        private simlamService: SimlamService,
        private documentoProcessoService: DocumentoProcessoService,
        private arquivoService: ArquivoService,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.FORMULARIO.component = this;
    }

    objetivos = [];
    objetivo: any;
    objetivosSubAtividade = [];
    form: FormGroup;
    displayedColumns: string[] = ['objetivo', 'acao'];
    displayedAtvColumns: string[] = ['atividade', 'acao'];
    reqTituloDisplay: string[] = ['numeroTitulo', 'titulo', 'situacao', 'origemTitulo', 'acao'];
    atvProjectionEncontrados = []
    atvProjection: Array<AtividadeProjectionArrecadacao> = [];
    atividadeProjection: any;
    emissaoTitulos: Array<EmissaoTitulo>;
    reqsTituloVinculadoSave: Array<RequerimentoTituloVinculado> = [];
    @ViewChild('parametro', {static: false})
    element: ElementRef;
    atvNivelDesc = ''
    numeroEmissao = '';
    origemTitulo = '';
    nomeTitulo: number;
    configuracao: any
    titulos: Array<any> = [];
    tiposObjetivo: Array<any> = [];
    tiposTitulacao: Array<any> = [];
    tiposRequerimento: Array<DominioDto> = [];
    todosObjetivos: Array<any> = [];
    tiposAtuacao: Array<DominioDto> = [];
    titulacaoFlorestal: Titulacao[];
    isDesabilitaParametrosAtividade: boolean = false;

    parametroEntradas: any;
    errorStatusCalculoParametroEntrada: any;
    atividadeCalculo: AtividadeCalculoArrecadacao[];
    parametroCalculoList: any;
    atividadeSemParametroeCaculo = false;
    parametroEntrada: any = {};
    pisiculturaExotico = false;
    regraApp = false;
    calcularUpfRetornoDtoList: any[] = [];
    popUp = false;
    parametrosCalculoLista: any = [];
    @ViewChild("valorParametro", {static: false}) valorParametro: ElementRef;
    erroDeCalculo = false;
    objsIsento = false;
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoEmp = new FormularioReqLicenciamentoEmp();

    licencasSimLam: Array<LicencaFiscalizacao> = [];
    arquivo: any;
    arquivoDocProcessoFisico: DocumentoProcesso;
    atividadeDesc = new FormControl(null);
    atividadeLicenciadaEncontrado = [];

    atividadeLicenciada = [];
    formAtividade: FormGroup;

    readonly OBJETIVO_PRINCIPAL: number = 1;

    msgExclusaoRegistroAtividade: string = 'Caso existam feições, pontos, formulários e documentos anexados a esta atividade/serviço nas abas Dados Geográficos, Dados da Atividade e Documentação Complementar, os dados serão excluídos.' + 
    'Esta ação não pode ser desfeita.';

    msgExclusaoRegistroObjetivo: string = 'Caso existam feições, pontos, formulários e documentos anexados a este objetivo nas abas Dados Geográficos, Dados da Atividade e Documentação Complementar, os dados serão excluídos.' + 
    'Esta ação não pode ser desfeita.';

    async ngOnInit() {
        this.buildFormLicenciamento();

        await this.getListaObjetivos()
        await this.getListaTiposAtuacao();
        await this.getListaTitulos();
        await this.getListaTiposRequerimento();
        await this.getListaTiposObjetivo();
        await this.getListaTiposTitulacao();
        await this.getObjetivosApresentados()
        this.carregarReqTituloVinculado()
        this.carregarTitulacoes();
        await this.criaOuBuscaReqLicenciamento();
        this.origemTitulo = 'SIGA';
    }

    private async criaOuBuscaReqLicenciamento() {
        this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).subscribe(async resultado => {
            if (!resultado) {
                let reqLicenciamento = new RequerimentoLicenciamento()
                reqLicenciamento.requerimento = this.requerimento
                this.requerimentoLicenciamentoService.create(reqLicenciamento).subscribe(async reqLic => {
                    this.requerimentoLicenciamento = reqLic
                }, () => {
                    this.snackBarService.showError('Ocorreu um erro ao criar o requerimento.');
                })
            } else {
                this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoFlorestalId(this.requerimento.id).subscribe(async formularioReqLicenciamentoDto => {
                    if (formularioReqLicenciamentoDto) {
                        this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                        if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                            this.requerimentoLicenciamento = formularioReqLicenciamentoDto.requerimentoLicenciamento;
                            await this.patchForm();
                            await this.recuperarObjetivoSubAtividadePorIDObjetivo(this.requerimentoLicenciamento.id)

                            if (this.requerimentoLicenciamento.idAtividade) {
                                await this.recuperarAtividadeSalva()
                                await this.obterAtividadeCalculoParametroEntrada(true)
                                if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                                    await this.preencherParametrosDeEntradaECalculo()
                                }
                            }
                        }
                    }
                    this.verificaSeDesabilitaParametrosAtividade();
                });
                
            }
        })
    }
    limparParametrosCalculo() {
        this.parametroCalculoList = [];
        this.parametroEntradas = [];
    }

    async preencherParametrosDeEntradaECalculo() {
        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
            this.parametroEntradas = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramEntrada =>
                paramEntrada.tipoParametro === 'ENTRADA');

            this.parametroCalculoList = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.filter(paramCalc => paramCalc.tipoParametro === 'CALCULO');

            const key = 'labelFormulario';

            this.parametroEntradas = [...new Map(this.parametroEntradas.map(item =>
                [item[key], item])).values()];

            this.parametroCalculoList = [...new Map(this.parametroCalculoList.map(item =>
                [item[key], item])).values()];

            if (this.parametroEntradas.length > 0) {
                this.parametroEntradas.forEach(async paramEntrada => {
                    const event = {
                        target: {
                            value: paramEntrada.valor
                        }
                    };
                    await this.onKey(event, paramEntrada, false, true);
                });
            }

            if (this.parametroCalculoList.length > 0) {
                this.parametroCalculoList.forEach(paramCalc => {
                    const event = {
                        target: {
                            value: paramCalc.valor
                        }
                    };
                    this.pegarValorDeParamCalc(event, paramCalc);
                });
            }
            // if ((this.parametroEntradas && this.parametroEntradas.length === 0) || (this.parametroCalculoList && this.parametroCalculoList.length === 0)) {
            //     await this.obterAtividadeCalculoDoParametroDeEntrada()
            // }
        }
    }

    async recuperarObjetivoSubAtividadePorIDObjetivo(idReqLicen) {
        await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(idReqLicen).toPromise().then(data => {
            this.objetivosSubAtividade = [];
            let objetivo = data.map(obj => {
                return {
                    objetivo: this.objetivos.find(o => o.objetivo.objetivo.id === obj.idObjetivoLicenciamentoAmbiental),
                    vinculo: obj
                }
            });
            if (objetivo && objetivo.length > 0) {
                this.objetivosSubAtividade.push(objetivo[0].objetivo.objetivo);
                this.objetivosSubAtividade = this.objetivosSubAtividade.slice();
                this.form.patchValue({
                    objetivoLicenciamento: objetivo[0].objetivo.objetivo.objetivo ? objetivo[0].objetivo.objetivo.objetivo.id : null
                });
                this.objetivo = objetivo[0].objetivo.objetivo;
            } else {
                console.log("recuperarObjetivoSubAtividadePorIDObjetivo: ", this.objetivo);
                if (this.objetivo) {
                    this.form.patchValue({
                        objetivoLicenciamento: this.objetivo.id
                    });
                    this.objetivosSubAtividade.push(objetivo);
                }
                // if (this.objetivos && this.objetivos.length > 0) {
                //     let objetivo = this.objetivos[0].objetivo;
                //     this.form.patchValue({
                //         objetivoLicenciamento: objetivo ? objetivo.id : null
                //     });
                //     this.objetivosSubAtividade.push(objetivo);
                // }
                this.objetivosSubAtividade = this.objetivosSubAtividade.slice();
            }
        });
        this.getAtividadesConfiguracao();
    }

    private async getListaTiposRequerimento() {
        await this.tipoRequerimentoService.getAll().toPromise().then(tiposReq => {
            this.tiposRequerimento = tiposReq
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar os títulos');
        });
    }

    private async getListaTitulos() {
        await this.licenciamentoTitulacaoService.getAll().toPromise().then(titulos => {
            this.titulos = titulos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar os títulos');
        });
    }

    private async getListaTiposObjetivo() {
        await this.dominiosService.getDominio("TIPO_OBJETIVO").toPromise().then(tiposObjetivos => {
            this.tiposObjetivo = tiposObjetivos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista tipos objetivos');
        });
    }

    private async getListaTiposTitulacao() {
        await this.tipoTitulacaoService.getAll().toPromise().then(tipoTitulacao => {
            this.tiposTitulacao = tipoTitulacao
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista de tipos titulação');
        });
    }

    private async getObjetivosApresentados() {
        let objs;
        await this.objetivoParametrizacaoObjetivoService.getByParametrizacaoObjetivoByIdParamObjetivoAndIdTipoRequerimento(this.requerimento.idParametroObjetivo, this.requerimento.tipoRequerimento.id).toPromise().then(paramObjs => {
            objs = paramObjs
        });

        this.objetivos = objs.map(obj => ({
            objetivo: {
                objetivo: this.objetivos.find(o => o.id === obj.idObjetivoParametrizacao),
                tipoObjetivo: this.tiposObjetivo.find(o => o.id === obj.tipoObjetivo),
                titulo: this.titulos.find(o => o.id === obj.idTituloEmitidoAlterado),
                tipoTitulacao: this.tiposTitulacao.find(o => o.id === obj.idArrecadacao),
                tipoRequerimento: this.tiposRequerimento.find(o => o.id === obj.idTipoRequerimento),
                ordemSelecao: obj.ordemSelecao,
                isSelecaoExclusiva: obj.isSelecaoExclusiva,
                desabilitado: false
            },
            vinculo: obj
        }));
    }

    private async getListaTiposAtuacao() {
        await this.dominiosService.getDominio("TIPO_ATUACAO").toPromise().then(tiposAtuacao => {
            this.tiposAtuacao = tiposAtuacao
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista tipo Atuação');
        });
    }

    private async getListaObjetivos() {
        await this.objetivoLicenciamentoAmbientalService.getAll().toPromise().then(objs => {
            this.objetivos = objs
            this.todosObjetivos = this.objetivos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar a lista de objetivos');
        });
    }

    private async patchForm() {
        this.form.patchValue({id: this.requerimentoLicenciamento.id});
        this.form.patchValue({requerimento: this.requerimento});
        this.form.patchValue({idAtividade: this.requerimentoLicenciamento.idAtividade});
        this.form.patchValue({idGrupoAtividade: this.requerimentoLicenciamento.idGrupoAtividade});
        this.form.patchValue({descricaoAtividade: this.requerimentoLicenciamento.descricaoAtividade});
        this.form.patchValue({descricaoGrupoAtividade: this.requerimentoLicenciamento.descricaoGrupoAtividade});
    }

    protected buildFormLicenciamento(): void {
        this.form = this.formBuilder.group(
            {
                id: [null],
                requerimento: [this.requerimento, [Validators.required]],
                idAtividade: [null, [Validators.required]],
                idGrupoAtividade: [null, [Validators.required]],
                descricaoAtividade: [null],
                descricaoGrupoAtividade: [null],
                ppd: [null],
                objetivoLicenciamento: [null, [Validators.required]]
            });

        this.formAtividade = this.formBuilder.group(
            {
                descAtividade: ['']
            });
    }

    async selecionarObjetivoNaTable() {
        if (this.objetivosSubAtividade.length == 0) {
            this.objetivosSubAtividade = [];
            this.objetivosSubAtividade.push(this.objetivo);
            this.objetivosSubAtividade = this.objetivosSubAtividade.slice()
            let passou = await this.criaObjetivoLicenciamento(this.objetivo)
            this.getAtividadesConfiguracao();
        } else {
            this.snackBarService.showAlert('Ja foi selecionado um objetivo')
            return
        }
    }

    private async getAtividadesConfiguracao() {
        this.atvProjectionEncontrados = []
        let objetivoLicenciamentoAmbiental = null;
        console.log("Objetivo Selecionado na table: ", this.objetivo);
        if (this.objetivo && this.objetivo.tipoObjetivo.id === this.OBJETIVO_PRINCIPAL) {
            objetivoLicenciamentoAmbiental = this.objetivo;
            let atvs = await this.atividadeCalculoArrecadacaoService.buscarAtividadeCalculosPorIdTipoTitulacao(objetivoLicenciamentoAmbiental.tipoTitulacao.id).toPromise()
            atvs = atvs.sort((a, b) => CommonsUtil.comparaStrings(a['atividade']['descricao'], b['atividade']['descricao']))
    
            for (let atv of atvs) {
                let atividade = {atividade: atv.atividade, vinculo: atv}
                this.atividadeLicenciada.push(atividade);
                this.atvProjectionEncontrados.push(atividade);
            }
            this.atividadeLicenciadaEncontrado = this.atvProjectionEncontrados;
        }
    }

    async pesquisaAtividade() {
        if (this.atividadeDesc.value.trim().length === 0) {
            this.atvProjectionEncontrados = this.atividadeLicenciada;
            console.log("atividadeDesc if: ", this.atividadeDesc.value);
            return;
        } else if (this.atividadeDesc.value.trim().length > 0) {
            this.atvProjectionEncontrados =
                this.atividadeLicenciada.filter(atv => atv.atividade.descricao.normalize("NFD").toLowerCase().includes(this.atividadeDesc.value.normalize("NFD").toLowerCase()))
        }
    }

    selecionarAtividade(atividade) {
        this.atividadeProjection = atividade
    }

    async selecionarAtividadeNaTable() {
        if (this.atvProjection.length === 0) {
            await this.salvaAtividadeLicenciada()
            await this.recuperarAtividadeSalva()
            await this.obterAtividadeCalculoParametroEntrada()
        } else {
            this.snackBarService.showAlert('Já existe uma atividade selecionada.');
            return
        }
    }

    async obterAtividadeCalculoParametroEntrada(inicio = false) {
        console.log("obterAtividadeCalculoParametroEntrada: ", this.objetivo);
        if (this.objetivo && this.objetivo.tipoObjetivo.id === this.OBJETIVO_PRINCIPAL) {
            let objetivoLicenciamentoAmbiental = this.objetivo;
            await this.atividadeParametroEntradaArrecadacaoService
            .buscarParametrosEntradaPIdAtividade(this.atividadeProjection.id, objetivoLicenciamentoAmbiental.tipoTitulacao.id)
            .toPromise().then( async atividadeParametroCalculoEntrada => {
                this.parametroEntradas = atividadeParametroCalculoEntrada;
            }).catch(async error => {
                error.status = 404;
                this.errorStatusCalculoParametroEntrada = error.status;
                if (this.errorStatusCalculoParametroEntrada === 404) {
                    await this.obterAtividadeCalculoSemParametroEntradaeCalculo(inicio);
                }
            })
        }

    }

    async obterAtividadeCalculoSemParametroEntradaeCalculo(inicio = false) {
        this.atividadeCalculoArrecadacaoService
        .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicenca())
        .toPromise().then(async atividadeCalculo => {
            this.atividadeCalculo = atividadeCalculo;
            if (!inicio) {
                this.parametroCalculoList = [...this.atividadeCalculo].shift().parametroCalculoList;
                if (this.parametroCalculoList && this.parametroCalculoList.length === 0) {
                    this.atividadeSemParametroeCaculo = true;
                    await this.iniciarCalculo()
                }
            }
        }).catch(error => {
            this.snackBarService.showAlert("Ocorreu um erro: " + error);
        })
    }

    montarObjetoParametroSemLicenca() {
        console.log("montarObjetoParametroSemLicenca: ", this.objetivo);
        if (this.objetivo && this.objetivo.tipoObjetivo.id === this.OBJETIVO_PRINCIPAL) {
            let objetivoLicenciamentoAmbiental = this.objetivo;
            return {
                idAtividade: this.atividadeProjection.id,
                parametroEntrada: this.parametroEntrada,
                pisiculturaExotico: this.pisiculturaExotico,
                regraApp: this.regraApp,
                idTipoTitulacao: objetivoLicenciamentoAmbiental.tipoTitulacao.id
            }
        } else {
            return null;
        }
    }

    async onKey(event, atividadeCalculoparamEntrada, timeOut = true, recuperado = false) {
        this.parametroEntrada.valor = event.target.value;
        this.parametroEntrada.descricao = atividadeCalculoparamEntrada.descricao;
        this.parametroEntrada.labelFormulario = atividadeCalculoparamEntrada.labelFormulario;
        if (event.target.value.trim().toString().length > 0 && event.target.value.trim().toString() !== '') {
            if (timeOut) {
                setTimeout(async () => {
                    if (this.element) {
                        this.element.nativeElement.disabled = true;
                    }
                    await this.obterAtividadeCalculoDoParametroDeEntrada();
                    if (this.element) {
                        this.element.nativeElement.disabled = false;
                    }
                }, 1500);
            } else {
                await this.obterAtividadeCalculoDoParametroDeEntrada(recuperado);
            }
        } else if (event.target.value.toString().length === 0) {
            this.parametroCalculoList = null;
            this.atividadeCalculo = [];
            if (this.element) {
                this.element.nativeElement.disabled = false;
            }
        }
    }

    async obterAtividadeCalculoDoParametroDeEntrada(recuperado = false) {
        if (!recuperado) {
            this.parametroCalculoList = null;
        }
        await this.atividadeCalculoArrecadacaoService.buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoLicenca()).toPromise().then(atividadeCalculo => {
            this.atividadeCalculo = atividadeCalculo.filter(atvCalc =>
                atvCalc.tipoTitulacao.id == this.objetivo.tipoTitulacao.id)
            if (!recuperado) {
                this.parametroCalculoList = [...this.atividadeCalculo].shift().parametroCalculoList;
            }
            if (!this.parametroCalculoList) {
                this.snackBarService.showAlert("Não há licença disponível");
            } else {
                for (let i = 0; i < this.parametroCalculoList.length; i++) {
                    if (this.parametroCalculoList[i].labelFormulario === this.parametroCalculoList[0].labelFormulario) {
                        let event = {
                            target: {
                                value: this.parametroEntrada.valor
                            }
                        }
                        this.pegarValorDeParamCalc(event, this.parametroCalculoList[i], this.atividadeCalculo)
                        this.parametroCalculoList.splice(i, 1)
                        this.parametroCalculoList = this.parametroCalculoList.slice()
                    }
                }
            }
        }).catch(() => {
            this.snackBarService.showAlert("Sua atividade não é passível de licenciamento");
        })
    }

    montarObjetoLicenca() {
        console.log("montarObjetoLicenca: ", this.objetivo);
        if (this.objetivo && this.objetivo.tipoObjetivo.id === this.OBJETIVO_PRINCIPAL
            && this.atividadeCalculo.some(atv => atv.tipoTitulacao.id === this.objetivo.tipoTitulacao.id)
        ) {
            let objetivoLicenciamentoAmbiental = this.objetivo;
            return {
                idAtividade: this.atividadeProjection.id,
                parametroEntrada: this.parametroEntrada,
                pisiculturaExotico: this.pisiculturaExotico,
                regraApp: this.regraApp,
                idTipoTitulacao: objetivoLicenciamentoAmbiental.tipoTitulacao.id,
            }
        } else {
            return null;
        }
    }

    async pegarValorDeParamCalc(event, parametroEntradas: any, licencas = []) {
        this.montarObjetoParamEntrada(parametroEntradas, event.target.value);
        if (this.atividadeCalculo && this.atividadeCalculo.length === 0) {
            this.calcularUpfRetornoDtoList = this.atividadeCalculo
        } else {
            this.calcularUpfRetornoDtoList = licencas
        }
    }

    montarObjetoParamEntrada(parametroEntradas, valor) {
        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (this.parametrosCalculoLista.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoLista.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoLista.splice(this.parametrosCalculoLista.indexOf(paramCalc), 1);
                    this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
                }
            })
        } else {
            this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
        }
    }

    async iniciarCalculo(calculaTudo = true) {
        this.calcularUpfRetornoDtoList = []
        this.popUp = false
        console.log("iniciarCalculo: ", this.objetivo);
        const objetivoLicenciamento = this.objetivo && this.objetivo.tipoObjetivo.id === this.OBJETIVO_PRINCIPAL ?  this.objetivo : null; 

        if (calculaTudo && objetivoLicenciamento != null) {
            if (objetivoLicenciamento.tipoTitulacao.id !== 5) {
                if (this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id == 5)) {
                    this.erroDeCalculo = true
                    this.popUp = true
                    return
                }
            } else if (objetivoLicenciamento.tipoTitulacao.id == 5) {
                if (!this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id == 5)) {
                    this.erroDeCalculo = true;
                    this.popUp = true;
                    return;
                }
            }

            let objetivosAll = [];

            objetivosAll.push(objetivoLicenciamento);

            let objetivoACalcular = [];
            let objetivosDeCalculo = [];
            objetivosDeCalculo.push(...this.atividadeCalculo);

            objetivosAll = objetivosAll.filter(obj => obj.tipoTitulacao.id !== 16)
            for (let objetivo of objetivosAll) {
                for (let calculo of objetivosDeCalculo) {
                    if (objetivo.tipoTitulacao.id === calculo.tipoTitulacao.id) {
                        objetivoACalcular.push(calculo)
                    }
                }
            }

            if (objetivoACalcular.length == objetivosAll.length) {
                let atividadesCalculoPrin = []
                atividadesCalculoPrin.push(this.atividadeCalculo.find(calculo => calculo.tipoTitulacao.id === objetivoLicenciamento.tipoTitulacao.id))
                this.atividadeCalculo = atividadesCalculoPrin
            } else {
                this.snackBarService.showAlert('Sua atividade não é passível de licenciamento')
                this.erroDeCalculo = true
                return;
            }

            for (let param of this.parametrosCalculoLista) {
                param['atividadeCalculo'] = []
            }

            for (let atvCalc of this.atividadeCalculo) {
                for (let param of this.parametrosCalculoLista) {
                    param['atividadeCalculo'].push(atvCalc)
                }
            }

            await this.calcularLicenca()
            if (this.erroDeCalculo) {
                this.erroDeCalculo = true
                return;
            }

        }

        if (this.calcularUpfRetornoDtoList.length > 0) {
            this.erroDeCalculo = false
        } else {
            this.erroDeCalculo = true
        }
    }

    async calcularLicenca() {
        this.erroDeCalculo = false
        const ultimoIndex = this.atividadeCalculo.indexOf(this.atividadeCalculo.slice(-1)[0]);
        let titulacaoIsenta = true

        this.atividadeCalculo = this.atividadeCalculo.filter(function (i) {
            return i
        });

        for (let i = 0; i < this.atividadeCalculo.length; i++) {
            await this.atividadeCalculoArrecadacaoService
            .calcularUpf(this.montarObjetoCalculoUpf(this.atividadeCalculo[i].id))
            .toPromise().then(calcularUpfRetornoDto => {
                this.parametroEntrada['idAtividadeCalculo'] = calcularUpfRetornoDto.atividadeCalculo.id
                this.calcularUpfRetornoDtoList.push(calcularUpfRetornoDto)

                if (ultimoIndex == i) {
                    this.calcularUpfRetornoDtoList = this.calcularUpfRetornoDtoList.sort((a, b) =>
                        (a.atividadeCalculo.tipoTitulacao.ordenacao != null ? a.atividadeCalculo.tipoTitulacao.ordenacao : Infinity) - (b.atividadeCalculo.tipoTitulacao.ordenacao != null ? b.atividadeCalculo.tipoTitulacao.ordenacao : Infinity)
                    );

                    titulacaoIsenta = this.validacaoAtividadeIsenta()

                    if (this.calcularUpfRetornoDtoList.length > 0) {
                        if (!titulacaoIsenta) {
                            this.snackBarService.showAlert('Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020');
                        } else {
                            this.snackBarService.showSuccess("O Upf foi Calculado com sucesso, estará disponível na aba de Cálculo de Taxa");
                        }
                    }
                }

            }).catch(() => {
                this.erroDeCalculo = true
                this.snackBarService.showError('Sua atividade não é passível de licenciamento')
            })
        }
    }

    montarObjetoCalculoUpf(idAtividadeCalculo) {
        this.parametrosCalculoLista.forEach(param => {
            delete param.id
        })

        return {
            idAtividadeCalculo: idAtividadeCalculo,
            parametrosCalculo: this.parametrosCalculoLista,
            parametroEntrada: this.parametroEntrada
        }
    }

    validacaoAtividadeIsenta() {
        let titulacaoIsenta = true
        for (let i = 0; i < this.calcularUpfRetornoDtoList.length; i++) {
            if (this.calcularUpfRetornoDtoList[i].valorUpf === 0 && this.calcularUpfRetornoDtoList.length === 1) {
                titulacaoIsenta = false
                this.snackBarService.showAlert('Esta atividade é isenta de pagamento de taxa conforme Art. 6º item II, da Lei 11.179/2020');
            }
        }
        return titulacaoIsenta;
    }

    private async salvaAtividadeLicenciada() {
        this.requerimentoLicenciamento.idAtividade = this.atividadeProjection.id
        this.requerimentoLicenciamento.descricaoAtividade = this.atividadeProjection.descricao
        this.requerimentoLicenciamento.idGrupoAtividade = this.atividadeProjection.atividadePai.id
        this.requerimentoLicenciamento.descricaoGrupoAtividade = this.atividadeProjection.atividadePai.descricao
        await this.requerimentoLicenciamentoService.update(this.requerimentoLicenciamento).toPromise()
    }

    private async criaObjetivoLicenciamento(objetivo) {
        let passou = false;
        let objetivoLicenciamento = new ObjetivoLicenciamento()
        objetivoLicenciamento.requerimentoLicenciamento = this.requerimentoLicenciamento
        objetivoLicenciamento.descricaoObjetivo = objetivo.objetivo.descricao
        objetivoLicenciamento.idObjetivoLicenciamentoAmbiental = objetivo.objetivo.id
        objetivoLicenciamento.idTipoObjetivo = objetivo.tipoObjetivo.id

        await this.requerimentoTitulacaoService.create(RequerimentoTitulacao.fromJson(
            {
                titulacao: objetivo.titulo,
                requerimento: this.requerimento,
                enumOrigemTitulacao: EnumOrigemTitulacao.REQUERIMENTO
            }
        )).toPromise().then(async () => await this.objetivoLicenciamentoService.create(objetivoLicenciamento).toPromise().then(() => {
        }))
        .catch(() => this.snackBarService.showAlert('Entre em contato com a administração para configurar o Título Emitido/Alterado desse objetivo configurado.'))

        return passou;
    }

    async salvarRequerimentoLicenciamento() {
        await this.requerimentoLicenciamentoService.create(this.form.value).toPromise().then(async requerimentoLicenciamento => {
            this.requerimentoLicenciamento = requerimentoLicenciamento;
            this.form.patchValue({id: requerimentoLicenciamento.id});
            this.form.patchValue({idAtividade: requerimentoLicenciamento.idAtividade});
            this.form.patchValue({idGrupoAtividade: requerimentoLicenciamento.idGrupoAtividade});
            this.form.patchValue({idGrupoAtividade: requerimentoLicenciamento.idGrupoAtividade});
            this.form.patchValue({descricaoAtividade: requerimentoLicenciamento.descricaoAtividade});
            this.form.patchValue({descricaoGrupoAtividade: requerimentoLicenciamento.descricaoGrupoAtividade});
            await this.recuperarAtividadeSalva()
        }).catch(() => this.snackBarService.showError('Erro ao tentar salvar a atividade'));
    }

    async recuperarAtividadeSalva() {
        await this.atividadeArrecadacaoService.getAtividadeId(this.requerimentoLicenciamento.idAtividade).toPromise().then(atividade => {
            this.atvProjection = []
            this.atvProjection.push(atividade)
            this.atvProjection = this.atvProjection.slice()
            this.atividadeProjection = atividade;
        })
    }

    async criarLancamentoTaxaInicial() {
        let lancamentoTaxa;
        lancamentoTaxa = await this.lancamentoTaxaService.getByRequerimentoId(this.requerimento.id).toPromise()
        if (lancamentoTaxa.length === 0) {
            lancamentoTaxa = new LancamentoTaxaDto();
            lancamentoTaxa.requerimento = this.requerimento;
            lancamentoTaxa.qtdePonto = 1;

            lancamentoTaxa.qtdeTotalUPF = 0;
            lancamentoTaxa.qtdeUnitarioUPF = 0;
            lancamentoTaxa.valorReal = 0
            lancamentoTaxa.taxaIsenta = true;
            let idTitulacao = await this.objetivoParametrizacaoObjetivoService.getByIdTitulacao(this.objetivosSubAtividade[0].objetivo.id).toPromise()
            lancamentoTaxa.titulacao = this.titulos.find(t => t.id === idTitulacao);
            await this.lancamentoTaxaService.create(lancamentoTaxa).toPromise();
        }
    }

    async deletarRequerimentoLicenciamentoObjetivo(objetivoLicenciamento: any) {
        if (this.objetivosSubAtividade.length === 1) {
            await this.deletarRequerimentoLicenciamentoAtividade()
            await this.objetivoLicenciamentoService.deletePorIds(this.requerimentoLicenciamento.id, objetivoLicenciamento.objetivo.id).toPromise()
            await this.requerimentoTitulacaoService.deleteAllByRequerimentoId(this.requerimento.id).toPromise();
            this.objetivosSubAtividade.splice(0, 1);
            this.objetivosSubAtividade = this.objetivosSubAtividade.slice()
        }
    }

    async deletarRequerimentoLicenciamentoAtividade() {
        if (this.atvProjection.length > 0) {
            let paramParaDelete = []
            if (this.parametroEntradas && this.parametroEntradas.length > 0) {
                let lancamentoTaxa = this.parametroEntradas.filter(lanc => lanc.lancamentoTaxa)[0]
                if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                    this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                    return
                }
                for (let param of this.parametroEntradas) {
                    if (param.id && lancamentoTaxa) {
                        paramParaDelete.push(param)
                    }
                }
            }

            if (this.parametroCalculoList && this.parametroCalculoList.length > 0) {
                let lancamentoTaxa = this.parametroCalculoList.filter(lanc => lanc.lancamentoTaxa)[0]
                if (lancamentoTaxa && lancamentoTaxa.numeroDar) {
                    this.snackBarService.showAlert('Essa licença ja foi processada e não pode ser deletada');
                    return
                }
                for (let param of this.parametroCalculoList) {
                    if (param.id && lancamentoTaxa) {
                        paramParaDelete.push(param)
                    }
                }
            }
            if (paramParaDelete.length > 0) {
                await this.lancamentoTaxaService.deletaTodos(paramParaDelete).toPromise()
            }
            this.parametroCalculoList = []
            this.parametrosCalculoLista = []
            this.parametroEntradas = []
            this.parametroEntrada = {}

            await this.deletarAtividadeNaBase()
        }
    }

    async deletarAtividadeNaBase() {
        this.requerimentoLicenciamento.idAtividade = null
        this.requerimentoLicenciamento.idGrupoAtividade = null
        this.requerimentoLicenciamento.descricaoAtividade = ''
        this.requerimentoLicenciamento.ppd = ''
        this.requerimentoLicenciamento.descricaoGrupoAtividade = ''
        await this.requerimentoLicenciamentoService.update(this.requerimentoLicenciamento).toPromise()
        this.atvProjection.splice(0, 1);
        this.atvProjection = this.atvProjection.slice()
    }

    private resetAll() {
        this.atvProjection = []
        this.objetivosSubAtividade = []
        this.requerimentoLicenciamento = {};
        this.form.reset();
        this.form.patchValue({requerimento: this.requerimento});
    }

    async selecionarObjetivo(objetivoLicen: any) {
        this.objetivo = objetivoLicen;
    }

    selecionarTitulacaoFlorestal(titulacao: number): void {
        this.nomeTitulo = titulacao;
    }

    private verificaIsencao() {
        console.log("verificaIsencao: ", this.objetivo);
        let objetivo = this.objetivo ? this.objetivo : null;
        return this.objsIsento = objetivo && objetivo.tipoTitulacao && objetivo.tipoTitulacao.id  === 16 ? true : false;
    }

    async validarAba(erros: ErrosAbaRequerimento) {
        this.verificaIsencao();

        try {
            const atividadeCalculo = await this.atividadeCalculoArrecadacaoService.buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoLicenca()).toPromise();
            this.atividadeCalculo = atividadeCalculo.filter(atv => 
                atv.atividade.id == this.atvProjection[0].id && 
                atv.tipoTitulacao.id === this.objetivo.tipoTitulacao.id
            );
        } catch (error) {
            console.error('Failed to fetch atividadeCalculo:', error);
            erros.push('A atividade selecionada não corresponde ao objetivo de ' + this.objetivo.tipoTitulacao.descricao + ' e não pode ser licenciada. Escolha atividade correta. Caso não encontre a atividade correta, contate o administrador do sistema!');
        }

        if (this.atividadeCalculo && this.atividadeCalculo.length > 0 && !this.objsIsento) {
            await this.iniciarCalculo()
            if (this.erroDeCalculo && !this.popUp) {
                erros.push('Sua atividade não é passível de licenciamento');
            } else if (this.popUp) {
                erros.push('PopUp')
            }
        } else {
            if (!this.objsIsento) {
                if (this.parametrosCalculoLista == null || this.parametrosCalculoLista.length == 0)
                    erros.push('Não existe licenças para a ser licenciada, verifica os campos do formulário')
            }
        }

        if (this.objetivosSubAtividade.length == 0) {
            erros.push('É necessário selecionar um objetivo');
        }
        if (this.atvProjection.length == 0) {
            erros.push('É necessário selecionar uma atividade');
        }

        if (erros.length === 0) {
            if (!await this.salvar()) {
                erros.push('Ocorreu um erro ao processar os dados do formulário. Tente novamente mais tarde.')
            }

            this.objetivoLicenciamento = this.objetivo
            return await Promise.all([]).then(() => Promise.resolve());
        }
        return Promise.resolve();
    }

    async salvar() {
        let validado = false
        await this.requerimentoFormularioLicenciamentoService.salvarFormularioCalculoDeLicencaFlorestal(await this.montarFormularioReqLicenDTO()).toPromise().then(formularioReqLicenciamentoDto => {
            this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
            validado = true
        }).catch(error => {
            this.snackBarService.showError('Ocorreu um erro', error);
        });

        return validado
    }

    private async montarFormularioReqLicenDTO() {
        if (this.calcularUpfRetornoDtoList && this.calcularUpfRetornoDtoList.length == 0) {
            await this.obterAtividadeCalculoDoParametroDeEntrada();
        }
        const lancamentoTaxa = new LancamentoTaxaDto();
        this.formularioReqLicenciamentoDto = new FormularioReqLicenciamentoEmp();

        await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise()
            .then(result => {
                this.requerimentoLicenciamento = result;
        });

        this.formularioReqLicenciamentoDto.requerimentoLicenciamento = this.requerimentoLicenciamento;
        this.formularioReqLicenciamentoDto.lancamentoTaxa = [];

        const lancamentoTaxaParamCalculoDto = [];

        if (this.formularioReqLicenciamentoDto) {
            if (this.calcularUpfRetornoDtoList.length > 0) {
                for (let licenca of this.calcularUpfRetornoDtoList) {
                    const lancamentoTaxa = new LancamentoTaxaDto()
                    lancamentoTaxa.requerimento = this.requerimento;
                    lancamentoTaxa.qtdePonto = 1;
                    lancamentoTaxa.qtdeTotalUPF = licenca.valorUpf;
                    lancamentoTaxa.qtdeUnitarioUPF = licenca.valorUpf;
                    lancamentoTaxa.valorReal = licenca.valorReal;
                    lancamentoTaxa.codigoReceitaNatureza = licenca.atividadeCalculo.receitaNatureza.id.toString();
                    lancamentoTaxa.codigoReceitaGrupo = licenca.atividadeCalculo.receitaNatureza.receitaGrupo.id.toString();
                    lancamentoTaxa.idAtividadeCalculo = licenca.atividadeCalculo.id;
                    lancamentoTaxa.requerimento = this.requerimento;

                    if (licenca.atividadeCalculo.tipoTitulacao.id === 14 || licenca.atividadeCalculo.tipoTitulacao.id === 28) {
                        console.log("montarFormularioReqLicenDTO: ", this.objetivo);
                        lancamentoTaxa.titulacao = this.titulos.find(titulacao => titulacao.id == this.objetivo.titulo.id);
                        lancamentoTaxa['autorizacao'] = true
                    } else {
                        lancamentoTaxa.titulacao = this.titulos.find(titulacao => titulacao.id == this.objetivo.titulo.id);
                    }

                    if (licenca['isenta']) {
                        lancamentoTaxa.taxaIsenta = true;
                    }

                    this.parametrosCalculoLista.forEach(paramCalc => {
                        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList && this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                            const paramCalcFind = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.find(param => param.labelFormulario === paramCalc.labelFormulario);
                            if (paramCalcFind) {
                                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO', paramCalcFind.id));
                            }
                        } else {
                            lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                                .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO'));
                        }
                    });

                    this.formularioReqLicenciamentoDto.lancamentoTaxa.push(lancamentoTaxa);

        
                }
            }


            
        
            this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList = lancamentoTaxaParamCalculoDto;
            if (this.atividadeSemParametroeCaculo) {
                this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = true;
            } else {
                this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = false;
            }

            return this.formularioReqLicenciamentoDto;
        }
    }

    // private async montarFormularioReqLicenDTO() {
    //     this.formularioReqLicenciamentoDto = new FormularioReqLicenciamentoEmp();

    //     this.formularioReqLicenciamentoDto.requerimentoLicenciamento = this.requerimentoLicenciamento
    //     this.formularioReqLicenciamentoDto.lancamentoTaxa = await this.retornaLancamentosTaxa();
    //     const lancamentoTaxaParamCalculoList = []
    //     for (let lancamentoTaxa of this.formularioReqLicenciamentoDto.lancamentoTaxa) {
    //         let lancamentoTaxaParams = this.retornaLancTaxaParams(lancamentoTaxa);
    //         //     id: idLancTaxaParamCalc,
    //         //     lancamentoTaxa: lancamentoTaxa,
    //         //     labelFormulario: data.labelFormulario,
    //         //     valor: data.valor,
    //         //     tipoParametro: tipoParametro,
    //         //     descricao: data.descricao,
    //         //     autorizacao: autorizacao
    //         for (let ltp of lancamentoTaxaParams) {
    //             if (lancamentoTaxaParamCalculoList.find((ltpc) => ltp.descricao.toLowerCase() == ltpc.descricao.toLowerCase()) == null) {
    //                 lancamentoTaxaParamCalculoList.push(ltp);
    //             }
    //         }
        
    //     }
    //     this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList = lancamentoTaxaParamCalculoList
    //     this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = this.atividadeSemParametroeCaculo;

    //     return this.formularioReqLicenciamentoDto;
    // }

    private async retornaLancamentosTaxa() {
        //await this.requerimentoFormularioLicenciamentoService.deleteEmp(this.requerimentoLicenciamento.id).toPromise()

        const lancamentosTaxa = []

        for (let licenca of this.calcularUpfRetornoDtoList) {
            const lancamentoTaxa = new LancamentoTaxaDto()
            lancamentoTaxa.requerimento = this.requerimento;
            lancamentoTaxa.qtdePonto = 1;
            lancamentoTaxa.qtdeTotalUPF = licenca.valorUpf;
            lancamentoTaxa.qtdeUnitarioUPF = licenca.valorUpf;
            lancamentoTaxa.codigoReceitaNatureza = licenca.atividadeCalculo.receitaNatureza.id.toString();
            lancamentoTaxa.codigoReceitaGrupo = licenca.atividadeCalculo.receitaNatureza.receitaGrupo.id.toString();
            lancamentoTaxa.idAtividadeCalculo = licenca.atividadeCalculo.id;

            if (licenca.atividadeCalculo.tipoTitulacao.id === 14 || licenca.atividadeCalculo.tipoTitulacao.id === 28) {
                lancamentoTaxa.titulacao = this.titulos.find(titulacao => titulacao.id == this.objetivo.titulo.id);
                lancamentoTaxa['autorizacao'] = true
            } else {
                lancamentoTaxa.titulacao = this.titulos.find(titulacao => titulacao.descricao.toLowerCase().includes(licenca.atividadeCalculo.receitaNatureza.receitaGrupo.descricao.toLowerCase()));
            }

            if (licenca['isenta']) {
                lancamentoTaxa.taxaIsenta = true;
            }

            lancamentosTaxa.push(lancamentoTaxa);
        }
        return lancamentosTaxa;
    }

    private retornaLancTaxaParams(lancamentoTaxa) {
        const lancamentoTaxaParamCalculoDto = [];

        if (this.parametroEntrada.valor) {
            lancamentoTaxaParamCalculoDto
            .push(ParametroEntradaArrecadacao
            .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(this.parametroEntrada, lancamentoTaxa, 'ENTRADA'));
        }

        if (this.objetivo) {
            this.parametrosCalculoLista.filter(param => limpaObjUnd(param.atividadeCalculo).find(atv => atv.id).id === lancamentoTaxa.idAtividadeCalculo)
        }

        this.parametrosCalculoLista.forEach(paramCalc => {
            lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
            .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO', null, lancamentoTaxa.autorizacao));
        });

        function limpaObjUnd(obj) {
            obj = obj.filter(function (i) {
                return i
            });
            return obj
        }

        return lancamentoTaxaParamCalculoDto;
    }

    async getAllAtividade() {
        this.atvProjectionEncontrados = []
        if (this.atvNivelDesc.length === 0) {
            await this.atividadeArrecadacaoService
            .getByAtividadeNivelPorDesc(this.atvNivelDesc)
            .toPromise().then(data => {
                for (let obj of this.objetivosSubAtividade) {
                    const objetos = data.filter(objetivos => objetivos.id === obj.idAtividade && objetivos.nivel == 3 || objetivos.idAtvPai === obj.idAtividade && objetivos.nivel == 4)
                    this.atvProjectionEncontrados.push(...objetos)
                }
            });
            this.atividadeLicenciadaEncontrado = this.atvProjectionEncontrados;
        }
    }

    arquivoSelecionado(event): void {
        if (event.target.files.length === 1) {
            this.arquivo = event.target.files[0];
        } else {
            this.arquivo = null;
        }
        this.arquivoDocProcessoFisico = null;
    }

    // Adiciona vinculo por processo físico.
    async addByNumeroEmissao() {
        if (!this.nomeTitulo) {
            this.snackBarService.showAlert('Insira o nome do título para adicioná-lo');
        } else if (this.numeroEmissao.trim().length == 0) {
            this.snackBarService.showAlert('Insira o número do título para adicioná-lo');
        }

        const docProcesso = new DocumentoProcesso();
        docProcesso.titulo = 'Referência título existente - Processo Físico';
        docProcesso.nomeArquivo = this.arquivo.name;
        docProcesso.requerimento = this.requerimento;
        docProcesso.sigiloso = false;
        docProcesso.grupoDocumento = DocumentoProcesso.gruposDocumento.find(x => x.valor == 'TITULO_EXISTENTE_PROCESSO_FISICO').valor;
        docProcesso.dataCriacao = new Date();

        const formData = new FormData();
        formData.append('file', this.arquivo);
        const documentoProcesso = new Blob([JSON.stringify(docProcesso)], {type: 'application/json'});
        formData.append('documentoProcesso', documentoProcesso);

        this.documentoProcessoService.createDocProcesso(formData).toPromise().then(result => {
            this.arquivoDocProcessoFisico = result;
            if(this.arquivoDocProcessoFisico){
                const reqTituloVinculado = new RequerimentoTituloVinculado();
                reqTituloVinculado.requerimento = this.requerimento;
                reqTituloVinculado.emissaoTitulo = null;
                reqTituloVinculado.numeroEmissao = this.numeroEmissao;
                reqTituloVinculado.tipoVinculoRequerimento = 'PROCESSO_FISICO';
                reqTituloVinculado.legado = true;
                reqTituloVinculado.documentoProcesso = this.arquivoDocProcessoFisico;
                reqTituloVinculado.titulacao = {
                    id: this.nomeTitulo
                }
                this.reqsTituloVinculadoSave.push(reqTituloVinculado);
                if (this.reqsTituloVinculadoSave.length > 0) {
                    this.reqTituloVinculadoService.saveAll(this.reqsTituloVinculadoSave).pipe(
                        take(1)
                    ).subscribe(
                        () => {
                            this.carregarReqTituloVinculado();
                            this.numeroEmissao = '';
                        },
                        () => this.snackBarService.showAlert('Ocorreu uma falha ao vincular a titulação.')
                    );
                }
            }
        }, error => {
            this.snackBarService.showAlert('Erro ao Salvar Documento Processo Físico!');
        });
    }

    async findByNumeroEmissao() {
        if (this.numeroEmissao.trim().length > 0) {
            if (this.origemTitulo === 'SIGA') {
                await this.emissaoTituloService
                .readByEmissaoTituloByNumeroProcesso(this.numeroEmissao)
                .toPromise().then(result => {
                    for (let emissao of result) {
                        const emissoesVinculadas = this.reqsTituloVinculadoSave.filter(titulosVinculados => titulosVinculados.legado === false);
                        const emissaoFilter = emissoesVinculadas.filter(reqTitulo => reqTitulo.emissaoTitulo.id === emissao.id);
                        if (emissaoFilter.length === 0) {
                            const reqTituloVinculado = new RequerimentoTituloVinculado();
                            reqTituloVinculado.requerimento = this.requerimento;
                            reqTituloVinculado.emissaoTitulo = emissao;
                            reqTituloVinculado.numeroEmissao = this.numeroEmissao;
                            reqTituloVinculado.tipoVinculoRequerimento = 'TITULO_EXISTENTE';
                            reqTituloVinculado.legado = false;
                            this.reqsTituloVinculadoSave.push(reqTituloVinculado);

                            if (this.reqsTituloVinculadoSave.length > 0) {
                                this.reqTituloVinculadoService.saveAll(this.reqsTituloVinculadoSave).subscribe(() => {
                                    this.carregarReqTituloVinculado();
                                    this.numeroEmissao = '';
                                });
                            }
                        } else {
                            this.snackBarService.showAlert('Título já adicionado');
                        }
                    }
                }).catch(() => this.snackBarService.showAlert('Não foi encontrado um título emitido pelo SIGA. ' +
                    'Verifique se o número e nome estão corretos e tente novamente'));
            } else {
                this.licencasSimLam = await this.simlamService.pesquisarLicencas(this.numeroEmissao).toPromise().catch(error => {
                    if(error.status == 500){
                        this.snackBarService.showError('Os serviços do SIMLAM encontram-se indisponíveis, favor tente mais tarde');
                    }else if(error.status == 401){
                        this.snackBarService.showError('Não foi encontrado um título emitido pelo SIMLAM. ' +
                            'Verifique se o número e nome estão corretos e tente novamente');
                    }
                    return null;
                });

                if (!this.licencasSimLam || this.licencasSimLam.length == 0) {
                    this.adicionarTituloLegado(null, this.requerimento);
                } else {
                    this.adicionarTituloLegado(this.licencasSimLam, this.requerimento);
                }
            }
        } else {
            this.snackBarService.showAlert('Insira o número do título para fazer a consulta');
        }
    }

    private adicionarTituloLegado(licencas: Array<LicencaFiscalizacao>, requerimento: Requerimento): void {
        if (licencas !== null) {
            licencas.forEach(licenca => {
                if (this.licencasSimLam.some(l => l.numeroProcesso !== licenca.numeroProcesso)) {
                    this.licencasSimLam.push(licenca);
                }
            });
        } else {
            const licencaFiscalizacao = new LicencaFiscalizacao;
            licencaFiscalizacao.numeroProcesso = this.numeroEmissao;
            this.licencasSimLam.push(licencaFiscalizacao);
        }

        this.reqTituloVinculadoService.saveAllRequerimentoTitulosVinculados(this.licencasSimLam, this.requerimento).subscribe(() => {
            this.carregarReqTituloVinculado()
        })
    }

    carregarReqTituloVinculado() {
        this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, null).pipe(
            take(1)
        ).subscribe(result => {
            this.reqsTituloVinculadoSave = result
        })
    }

    carregarTitulacoes() {
        this.titulacaoService.getAll().pipe(
            take(1)
        ).subscribe(result => this.titulacaoFlorestal = result
        .filter(t => t.tipoRequerimento.toLowerCase() == 'todos' || t.tipoRequerimento.toLowerCase() == 'florestal'));
    }

    deletarReqTituloVinculado(id: any) {
        this.reqTituloVinculadoService.delete(id).subscribe(() => {
            this.snackBarService.showSuccess('Título deletado com Sucesso');
            this.carregarReqTituloVinculado()
        }, () => this.snackBarService.showError('Ocorreu um erro ao tentar deletar o título.'))
    }

    numeroRequerimentoMask(text, origem): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);

                switch (origem) {
                    case 'referenciaProcessoExistente': {
                        this.numeroEmissao = `${initial}/${year}`;
                        break;
                    }
                }
            }
        }
    }

    openFileArquivoSimlam(itemSelecionado: RequerimentoTituloVinculado) {
        if(itemSelecionado.tipoVinculoRequerimento == 'PROCESSO_FISICO'){
            this.documentoProcessoService.readPDFByChave(itemSelecionado.documentoProcesso.chave).pipe(take<Blob>(1)).subscribe(arquivo => {
                window.open(URL.createObjectURL(new Blob([arquivo], {type: 'application/pdf'})));
            })
        }else{
            var urlArquivo = `${environment.URL_LIC_SIMLAM_ARQUIVO}?idTitulo=${itemSelecionado.idOrigem}`;
            window.open(urlArquivo, '_blank');
        }
    }

    placeholderCampoProcesso(): string {
        return this.origemTitulo == 'FISICO' ? "Título" : "Processo";
    }

    async excluirLicenciamentoObjetivo(objetivoLicenciamento: any) {
        const dialogRef = await this.dialog.open(DialogConfirmaExclusaoComponent,
            {
                width: '500px',
                data: {
                    message: this.msgExclusaoRegistroObjetivo
                }
            });

        await dialogRef.afterClosed().toPromise().then(async result => {
            if (!!result) {
                await this.deletarRequerimentoLicenciamentoObjetivo(objetivoLicenciamento);
            }
        });

    }

    async excluirRequerimentoLicenciamentoAtividade() {
        const dialogRef = await this.dialog.open(DialogConfirmaExclusaoComponent,
            {
                width: '500px',
                data: {
                    message: this.msgExclusaoRegistroAtividade
                }
            });

        await dialogRef.afterClosed().toPromise().then(async result => {
            if (!!result) {
                await this.deletarRequerimentoLicenciamentoAtividade();
            }
        });

    }

    private verificaSeDesabilitaParametrosAtividade() {
        this.isDesabilitaParametrosAtividade = (this.requerimento.situacaoProcesso !== 'EM_CORRECAO' && this.requerimento.situacaoProcesso !== 'EM_CORRECAO_CADASTRO'
            && this.requerimento.situacaoProcesso !== 'EM_CADASTRO')
            || this.currentAction === 'detail'
            || (!this.isFluxoPermiteAlteraParametroAtividade());
    }

    isFluxoPermiteAlteraParametroAtividade(): boolean {
        return this.requerimento.tipoRequerimento.isFluxoAlterar
            || this.requerimento.tipoRequerimento.isFluxoAmpliacao
            || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoEmitir;

    }

}
