import {Pipe, PipeTransform} from '@angular/core';
import {BaseEnum} from 'app/main/shared/enums/base.enum';

@Pipe({
    name: 'baseEnumPipe'
})
export class BaseEnumDescricaoPipe implements PipeTransform {

    transform(value: string, baseEnumArray: BaseEnum[]): string {
        if (value == null) {
            return '';
        }

        const result = baseEnumArray.find(item => item.valor === value);

        return result ? result.descricao : value;
    }

}
