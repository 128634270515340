import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '../shared/shared.module';
import { CondicionanteTemplateModule } from './condicionantes-template/condicionante-template.module';
import { FormulariosModule } from './feicoes-requerimentos/formularios/formularios.module';
import { FeicoesTemplateModule } from './feicoes-template/feicoes-template.module';
import { FinalidadesAtividadeModule } from './finalidades-atividade/finalidades-atividade.module';
import { FinalidadesModule } from './finalidades/finalidades.module';


import { ReferendarParecerTecnicoConsemaModule } from "./parecer-tecnico/referendar-parecer-tecnico-consema.module";
import { RedefinirDisponibilidadeHidricaModule } from './redefinir-disponibilidade-hidrica/redefinir-disponibilidade-hidrica.module';
import { RequerimentosModule } from './requerimentos/requerimentos.module';

const routes: Routes = [
    {
        path: 'requerimentos',
        loadChildren: './requerimentos/requerimentos.module#RequerimentosModule',
    },
    {
        path: 'condicionantes-template',
        loadChildren:
            './condicionantes-template/condicionante-template.module#CondicionanteTemplateModule',
    },
    {
        path: 'feicoes-template',
        loadChildren:
            './feicoes-template/feicoes-template.module#FeicoesTemplateModule',
    },
    {
        path: 'finalidades',
        loadChildren: './finalidades/finalidades.module#FinalidadesModule',
    },
    {
        path: 'finalidades-atividade',
        loadChildren:
            './finalidades-atividade/finalidades-atividade.module#FinalidadesAtividadeModule',
    },
    {
        path: 'gerar-documento-publicacao',
        loadChildren:
            './gerar-documento-publicacao/gerar-documento-publicacao.module#GerarDocumentoPublicacaoModule',
    },
    {
        path: 'licenciamento/:id/edit/:idTarefa',
        redirectTo: 'requerimentos/licenciamento/:id/edit/:idTarefa',
        
    },
    {
        path: 'tipos-solicitacao',
        loadChildren:
            './tipos-solicitacao/tipos-solicitacao.module#TiposSolicitacaoModule',
    },
    {
        path: 'tipos-processo',
        loadChildren:
            './tipos-processo/tipos-processo.module#TiposProcessoModule',
    },
    {
        path: 'tipos-requerimento',
        loadChildren:
            './tipos-requerimento/tipos-requerimento.module#TiposRequerimentoModule',
    },
    {
        path: 'titulacoes',
        loadChildren: './titulacoes/titulacoes.module#TitulacoesModule',
    },
    {
        path: 'termos-referencia',
        loadChildren:
            './termos-referencia/termos-referencia.module#TermosReferenciaModule',
    },
    {
        path: 'tarefas',
        loadChildren:
            './tarefas/tarefas.module#TarefasModule',
    },
    {
        path: 'tarefas-bpms', 
        loadChildren:
            './tarefas-bpms/tarefas-bpms.module#TarefasBpmsModule',
    },
    {
        path: 'assinar-cadastro',
        loadChildren:
            './validar-certificado-cadastro/assinar-cadastro.module#AssinarCadastroModule',
    },
    {
        path: 'configuracao-outorga',
        loadChildren:
            './configuracao-outorga/configuracao-outorga.module#ConfiguracaoOutorgaModule',
    },
    {
        path: 'consulta-recolhimento-taxa',
        loadChildren:
            './consulta-recolhimento-taxa/consulta-recolhimento-taxa.module#ConsultaRecolhimentoTaxaModule',
    },
    {
        path: 'envio-email-test',
        loadChildren:
            './envio-email-test/envio-email-test.module#EnvioEmailTestModule',
    },
    {
        path: 'templates',
        loadChildren: './templates/template.module#TemplateModule',
    },
    {
        path: 'redefinir-disponibilidade-hidrica',
        loadChildren: './redefinir-disponibilidade-hidrica/redefinir-disponibilidade-hidrica.module#RedefinirDisponibilidadeHidricaModule',
    },
    {
        path: 'atividades/licenciamento-empreendimento',
        loadChildren: './requerimentos/requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/atividades.module#AtividadesModule',
    },
    {
        path: 'feicoes-requerimentos/formularios',
        loadChildren: './feicoes-requerimentos/formularios/formularios.module#FormulariosModule',
    },
    {
        path: 'disponibilidade-hidrica',
        loadChildren:
            './consulta-disponibilidade-hidrica/consulta-disponibilidade-hidrica.module#ConsultaDisponibilidadeHidricaModule',
    },
    {
        path: 'devolucao-recurso-hidrico',
        loadChildren: './devolucao-recurso-hidrico/devolver-recurso-hidrico.module#DevolverRecursoHidricoModule',
    },
    {
        path: 'motivo-juntada',
        loadChildren: './manter-motivo-juntada/manter-motivo-juntada.module#ManterMotivoJuntadaModule'
    },

    {
        path: 'parecer-tecnico',
        loadChildren: './parecer-tecnico/referendar-parecer-tecnico-consema.module#ReferendarParecerTecnicoConsemaModule'
    },

    {
        path: 'assinar-documento',
        loadChildren: './assinador-por-chave/assinador-chave.module#AssinadorChaveModule'
    },
    {
        path: 'configurar-periodo-proibitivo',
        loadChildren: './configurar-periodo-proibitivo/configurar-periodo-proibitivo.module#ConfigurarPeriodoProibitivoModule'
    },
    {
        path: 'configurar-autorizacao-florestal',
        loadChildren: './configurar-autorizacao-florestal/configurar-autorizacao-florestal.module#ConfigurarAutorizacaoFlorestalModule'
    },
    {
        path: 'variaveis-grupo-camunda',
        loadChildren: './variavel-grupo-camunda/variavel-grupo-camunda.module#VariavelGrupoCamundaModule'
    }
];

@NgModule({
    imports: [
        // Angular Core
        CommonModule,
        RouterModule.forChild(routes),

        // Fuse
        FuseSharedModule,

        // shared
        SharedModule,

        // pages
        RequerimentosModule,
        CondicionanteTemplateModule,
        FeicoesTemplateModule,
        FinalidadesModule,
        FinalidadesAtividadeModule,        
        RedefinirDisponibilidadeHidricaModule,
        MatTooltipModule,
        FormulariosModule, 
        ReferendarParecerTecnicoConsemaModule
    ],
    declarations: []
})
export class PagesModule {
}
