import { Style, Stroke, Fill, Icon, Circle } from 'ol/style';

export interface FeicaoCarStyleMapper {
    feicaoSimcar: string;
    espessuraBorda: number;
    corRgb: string;
    corCodigo: string;
    tipoTracejado: string;
    iconUrl?: string;
}

export const stylesMapper: FeicaoCarStyleMapper[] = [
    { feicaoSimcar: 'CAR_ATP', espessuraBorda: 4, corRgb: '0 0 0', corCodigo: '#000000', tipoTracejado: 'CONTINUO'},
    { feicaoSimcar: 'CAR_AIR', espessuraBorda: 3, corRgb: '130 130 130', corCodigo: '#828282', tipoTracejado: 'CONTINUO'},
    { feicaoSimcar: 'CAR_APP', espessuraBorda: 1, corRgb: '0 0 0', corCodigo: '#000000', tipoTracejado: 'TRACEJADO'},
    { feicaoSimcar: 'CAR_AVN', espessuraBorda: 1, corRgb: '85 255 0', corCodigo: '#55ff00', tipoTracejado: 'CONTINUO'},
    { feicaoSimcar: 'CAR_AUAS', espessuraBorda: 1, corRgb: '255 85 0', corCodigo: '#ff5500', tipoTracejado: 'CONTINUO'},
    { feicaoSimcar: 'CAR_ARL', espessuraBorda: 2, corRgb: '255 255 255', corCodigo: '#ffffff', tipoTracejado: 'CONTINUO'},
    { feicaoSimcar: 'CAR_AREA_CONSOLIDADA', espessuraBorda: 2, corRgb: '0 112 255', corCodigo: '#0070ff', tipoTracejado: 'CONTINUO'}
];

export function getFeicaoCarStyle(feicaoSimcar: string): Style | null {
    const styleData = stylesMapper.find(style => style.feicaoSimcar === feicaoSimcar);

    if (!styleData) {
        return null;
    }

    let lineDash: number[] = null;
    if (styleData.tipoTracejado === 'TRACEJADO') {
        lineDash = [10, 20];
    } else if (styleData.tipoTracejado === 'PONTILHADO') {
        lineDash = [5, 10];
    }

    const stroke = new Stroke({
        color: styleData.corCodigo,
        width: styleData.espessuraBorda || 1,
        lineCap: 'round',
        lineDash: lineDash
    });

    let icon = null;
    if (styleData.iconUrl) {
        icon = new Icon({
            crossOrigin: 'anonymous',
            src: `data:image/svg+xml;utf8,${encodeURIComponent(styleData.iconUrl)}`,
        });
    } else {
        icon = new Circle({
            radius: 6,
            fill: new Fill({
                color: styleData.corCodigo || 'transparent',
            }),
            stroke: stroke,
        });
    }
    return new Style({
        fill: new Fill({
            color: 'transparent'
        }),
        stroke: stroke,
        image: icon
    });
}
