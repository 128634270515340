import {FeicaoGeometriaDto} from './feicao-geometria-dto.model';
import {RequerimentoLicenciamento} from '../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model';
import {StatusGeometria} from "../enums/status-geometria.enum";
import {InconformidadeFeicaoRequerimentoLicenciamento} from "./inconformidade-feicao-requerimento-licenciamento.model";
import {ModeloFeicaoDTO} from "./modelo-feicao-dto.model";
import { FeicaoDTO } from './feicao-dto.model';

export class FeicaoRequerimentoLicenciamentoDto {
    constructor(
        public id?: number,
        public requerimentoLicenciamento?: RequerimentoLicenciamento,
        public denominacao?: string,
        public descricaoObjetivo?: string,
        public tipoGeometria?: string,
        public objetivo?: number,
        public area?: number,
        public wkt?: string,
        public idFeicaoEntrada?: number,
        public idGeometria?: number,
        public objetivoLicenciamento?: number,
        public descricaoObjetivoLicenciamento?: string,
        public statusGeometria?: StatusGeometria,
        public feicaoDTO?: FeicaoDTO,
        public modeloFeicaoDTO?: ModeloFeicaoDTO,
        public feicoesPrincipais?: FeicaoRequerimentoLicenciamentoDto[],
        public feicoesDependentes?: FeicaoRequerimentoLicenciamentoDto[],
        public feicoesVinculadas?: FeicaoRequerimentoLicenciamentoDto[],
        public inconformidades?: InconformidadeFeicaoRequerimentoLicenciamento[],
        public excluido?: boolean,
        public latitude?: number,
        public longitude?: number,
        public centroidLatidude?: number,
        public centroidLongitude?: number,
        public latitudeLongitudeGMS?: string
) {
    }
    static fromJson(json: any): FeicaoRequerimentoLicenciamentoDto {
        if (!json) {
            return null;
        }
        return new FeicaoRequerimentoLicenciamentoDto(
            json.id,
            RequerimentoLicenciamento.fromJson(json.requerimentoLicenciamento),
            json.denominacao,
            json.descricaoObjetivo,
            json.tipoGeometria,
            json.objetivo,
            json.area,
            json.wkt,
            json.idFeicaoEntrada,
            json.idGeometria,
            json.objetivoLicenciamento,
            json.descricaoObjetivoLicenciamento,
            json.statusGeometria,
            FeicaoDTO.fromJson(json.feicaoDTO),
            json.modeloFeicaoDTO,
            json.feicoesPrincipais,
            json.feicoesDependentes,
            json.feicoesVinculadas,
            json.inconformidades,
            json.excluido,
            json.latitude,
            json.longitude,
            json.centroidLatidude,
            json.centroidLongitude,
            json.latitudeLongitudeGMS
        );
    }

    static fromJsons(jsonArray: any): FeicaoGeometriaDto[] {
        const resources = [];
        if (!jsonArray) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(FeicaoRequerimentoLicenciamentoDto.fromJson(resource)));
            return resources;
        }
    }
}

