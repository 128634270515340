import {BaseModel} from 'app/main/shared/models/base.model';

export class Fertilizacao extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public idTipoSistema?: number,
        public idTipoCulturasDesenv?: number,
        public volumeEfluente?: number,
    ) {
        super();
    }

    static fromJson(json: any): Fertilizacao {
        json = json || {};

        return new Fertilizacao(
            json.id,
            json.idGeometria,
            json.idTipoSistema,
            json.idTipoCulturasDesenv,
            json.volumeEfluente,
        );
    }
}