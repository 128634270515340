import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from '../../shared/enums/base.enum';

export class Finalidade extends BaseModel {

    constructor(
        public id?: number,
        public descricao?: string,
        public formulario?: string,
        public exigeVistoriaTecnica?: boolean
    ) {
        super();
    }

    static formularios: BaseEnum[] = [
        {valor: 'ABASTECIMENTO_PUBLICO', descricao: 'Abastecimento Publico'},
        {valor: 'ESGOTAMENTO_SANITARIO', descricao: 'Egostamento Sanitario'},
        {valor: 'DESSEDENTACAO_CRIACAO_ANIMAL', descricao: 'Dessedentação e Criação Animal'},
        {valor: 'INDUSTRIA', descricao: 'Indústria'},
        {valor: 'MINERACAO', descricao: 'Mineração'},
        {valor: 'AQUICULTURA_TANQUE_ESCAVADO', descricao: 'Aquicultura Tanque Escavado'},
        {valor: 'AQUICULTURA_TANQUE_REDE', descricao: 'Aquicultura Tanque Rede'},
        {valor: 'OUTROS', descricao: 'Outros'},
        {valor: 'GERACAO_ENERGIA', descricao: 'Geração de energia'},
        {valor: 'SEM_FORMULARIO', descricao: 'Sem formulário'},
    ];

    static fromJson(json: any): Finalidade {
        if (json === undefined || json === null) {
            return null;
        }
        return new Finalidade(
            json.id,
            json.descricao,
            json.formulario,
            json.exigeVistoriaTecnica
        );
    }

}

