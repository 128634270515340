import {CommonsUtil} from './../../../../../../../../shared/util/commons-util';
import {MatTableDataSource} from '@angular/material';
import {EquipamentoMotrizUsinaTerm} from './../../../../../../../../shared/models/energia-termica-equipamento-motriz.model';
import {SnackBarService} from './../../../../../../../../shared/snack-bar/snack-bar.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {EmpreendimentoEnergeticoTermicaService} from '../empreendimento-energetico-termica.service';
import {BaseModel} from '../../../../../../../../shared/models/base.model';

@Component({
    selector: 'app-equipamento-motriz',
    templateUrl: './equipamento-motriz.component.html',
    styleUrls: ['./equipamento-motriz.component.scss']
})
export class EquipamentoMotrizComponent implements OnInit {

    @Input() idEEtermica: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Output() enviarEquipamentoMotriz = new EventEmitter<EquipamentoMotrizUsinaTerm[]>();
    formEquipamentoMotriz: FormGroup;

    equipamentosMotriz: any[] = [];
    dataSourceEquipamentosMotriz = new MatTableDataSource<any>([]);
    unidadeMedidaEquipeMotriz: any[] = [];
    modoEdicao: boolean = false;

    idTabela: number = 0;
    displayedColumns: string[] = [
        'identificacaoEquipMotriz',
        'descricaoTipo',
        'valorPotenciaInstalada',
        'valorRotacao',
        'idUnidadeMedida',
        'acao'
    ];

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    constructor(private fb: FormBuilder,
                private snackBarService: SnackBarService,
                private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService,) {
    }

    ngOnInit(): void {

        this.formEquipamentoMotriz = this.fb.group({
            id: [''],
            idTemp: [null],
            empreendimentoEnergTermica: [''],
            identificacaoEquipMotriz: ['', [Validators.required]],
            descricaoTipo: ['', [Validators.required]],
            unidadeMedida: ['', [Validators.required]],
            valorPotenciaInstalada: ['', [Validators.required]],
            valorRotacao: ['', [Validators.required]],
        });

        this.buscarUnidadeDeMedida();

        if (this.idEEtermica) {
            this.carregaEquipaMotrizCasoExistaIdEEtermica(this.idEEtermica);
        }

        if (this.isNaoPodeEditar) {
            this.formEquipamentoMotriz.disable();
        }

    }

    buscarUnidadeDeMedida(): void {
        this.empreendimentoEnergService.buscarDominiosFiltrados().subscribe(unidadeMedida => {
            this.unidadeMedidaEquipeMotriz = unidadeMedida.filter(unidade => unidade.id >= 16);
        });
    }

    adicionaEquipamentoMotriz(): void {
        const validacao = this.validarFormulario();
        if (validacao) {

            this.equipamentosMotriz.push({
                id: null,
                idTemp: this.retornarIdTabela(),
                identificacaoEquipMotriz: this.formEquipamentoMotriz.controls.identificacaoEquipMotriz.value,
                descricaoTipo: this.formEquipamentoMotriz.controls.descricaoTipo.value,
                valorPotenciaInstalada: Number(this.formEquipamentoMotriz.controls.valorPotenciaInstalada.value),
                valorRotacao: Number(this.formEquipamentoMotriz.controls.valorRotacao.value),
                descricaoMedicao: this.formEquipamentoMotriz.controls.unidadeMedida.value
            });

            this.dataSourceEquipamentosMotriz = new MatTableDataSource(this.equipamentosMotriz);
            this.formEquipamentoMotriz.reset();
            this.enviarEquipamentosMotriz(this.mapearArray());
        }

    }

    retornarIdTabela(): number {
        this.idTabela++;
        return this.idTabela;
    }

    enviarEquipamentosMotriz(equipamentos: EquipamentoMotrizUsinaTerm[]): void {
        this.enviarEquipamentoMotriz.emit(equipamentos);
    }

    deletarItem(item, acao?: string, itemId?: number): void {
        if (acao === 'DELETAR' && itemId != null) {
            this.empreendimentoEnergService.deletarEquipamentoMotrizPorId(itemId).subscribe(() => {
                this.snackBarService.showSuccess('Equipamento Motriz excluido com sucesso.');
            });
        }
        let data;
        data = this.equipamentosMotriz.filter(value => {
            return value.idTemp !== item;
        });
        this.equipamentosMotriz = data;
        this.dataSourceEquipamentosMotriz = new MatTableDataSource(this.equipamentosMotriz);
        this.enviarEquipamentosMotriz(this.mapearArray());
    }


    mapearArray(): any {
        if (this.equipamentosMotriz.length == 0) {
            return this.equipamentosMotriz;
        } else {
            return this.equipamentosMotriz.map(function (item) {
                return new EquipamentoMotrizUsinaTerm(
                    null,
                    null,
                    item.identificacaoEquipMotriz,
                    item.descricaoTipo,
                    item.descricaoMedicao.id,
                    item.valorPotenciaInstalada,
                    item.valorRotacao
                );
            });
        }
    }

    ativarModoEdicaoEquipMotriz(equipamentos): void {
        this.formEquipamentoMotriz.controls.id.patchValue(equipamentos.id);
        this.formEquipamentoMotriz.controls.idTemp.patchValue(equipamentos.idTemp);
        this.formEquipamentoMotriz.controls.identificacaoEquipMotriz.patchValue(equipamentos.identificacaoEquipMotriz);
        this.formEquipamentoMotriz.controls.descricaoTipo.patchValue(equipamentos.descricaoTipo);
        this.formEquipamentoMotriz.controls.valorPotenciaInstalada.patchValue(equipamentos.valorPotenciaInstalada);
        this.formEquipamentoMotriz.controls.valorRotacao.patchValue(equipamentos.valorRotacao);
        this.formEquipamentoMotriz.controls.unidadeMedida.patchValue(equipamentos.descricaoMedicao);
        this.modoEdicao = true;
    }

    editarEquipamento(): void {
        const validacao = this.validarFormulario();
        if (validacao) {
            this.deletarItem(this.formEquipamentoMotriz.controls.idTemp.value);
            this.adicionaEquipamentoMotriz();
            this.modoEdicao = false;
        }

    }

    fecharEdicao(): void {
        this.modoEdicao = false;
        this.formEquipamentoMotriz.reset();
        this.formEquipamentoMotriz.markAsUntouched();
        this.formEquipamentoMotriz.updateValueAndValidity()
    }


    validarFormulario(): boolean {
        if (this.formEquipamentoMotriz.valid) {
            if (CommonsUtil.isEmpty(this.formEquipamentoMotriz.controls.identificacaoEquipMotriz.value)) {
                this.snackBarService.showAlert(
                    'O campo Identificação ' +
                    ' é obrigatório' +
                    'Favor realize o preenchimento do mesmo.');
                this.formEquipamentoMotriz.markAllAsTouched();
                return false;
            }

            return true;
        } else {
            this.snackBarService.showAlert(
                'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            this.formEquipamentoMotriz.markAllAsTouched();
            return false;
        }
    }


    carregaEquipaMotrizCasoExistaIdEEtermica(idEEtermica: any): void {
        this.empreendimentoEnergService.buscaListEquipamentoMotriz(idEEtermica).subscribe(async result => {
            const unidadeMedida = await this.empreendimentoEnergService.buscarDominiosFiltrados().toPromise();

            for (const equipamentoMotriz of result) {
                const unidadeFiltrada = unidadeMedida.filter(uni => uni.id === equipamentoMotriz.idUnidadeMedida);

                this.equipamentosMotriz.push({
                    id: equipamentoMotriz.id,
                    idTemp: this.retornarIdTabela(),
                    identificacaoEquipMotriz: equipamentoMotriz.identificacaoEquipMotriz,
                    descricaoTipo: equipamentoMotriz.descricaoTipo,
                    valorPotenciaInstalada: equipamentoMotriz.valorPotenciaInstalada,
                    valorRotacao: equipamentoMotriz.valorRotacao,
                    descricaoMedicao: unidadeFiltrada[0]
                });
                this.dataSourceEquipamentosMotriz = new MatTableDataSource(this.equipamentosMotriz);
            }
        });
    }
}
