import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from '../requerimento.model';

export class RequerimentoTermoReferenciaEspecificoModel extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public possuiRequerimentoEmAndamento?: boolean,
        public protocoloRequerimentoEmAndamento?: string
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoTermoReferenciaEspecificoModel {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoTermoReferenciaEspecificoModel(
            json.id,
            Requerimento.fromJson(json.requerimento),
            json.possuiRequerimentoEmAndamento,
            json.protocoloRequerimentoEmAndamento
        );
    }
}
