import { Titulacao } from 'app/main/pages/titulacoes/titulacao.model';
import { BaseModel } from 'app/main/shared/models/base.model';
import { RequerimentoLicenciamento } from "../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model";
import { ObjetivoLicenciamentoAmbiental } from './licenciamento-config-models/objetivo-licenciamento-ambiental.model';

export class ObjetivoLicenciamento extends BaseModel {

    private _titulacao: Titulacao;

    constructor(
        public id?: number,
        public requerimentoLicenciamento?: RequerimentoLicenciamento,
        public idObjetivoLicenciamentoAmbiental?: number,
        public objetivoLicenciamentoAmbiental?: ObjetivoLicenciamentoAmbiental,
        public idTipoObjetivo?: number,
        public descricaoObjetivo?: string,
    ) {
        super();
    }

    set titulacao(value: Titulacao) {
        this._titulacao = value;
    }
    get titulacao() {
        return this._titulacao;
    }
    static fromJson(json: any): ObjetivoLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObjetivoLicenciamento(
            json.id,
            RequerimentoLicenciamento.fromJson(json.requerimentoLicenciamento),
            json.idObjetivoLicenciamentoAmbiental,
            ObjetivoLicenciamentoAmbiental.fromJson( json.objetivoLicenciamentoAmbiental ),
            json.idTipoObjetivo,
            json.descricaoObjetivo
        );
    }
}
