import {Component, ElementRef, OnInit} from '@angular/core';
import {BasePontoComponent} from '../base-ponto.component';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {ObraBarragem} from './obra-barragem.model';
import {MunicipioService} from '../../../../../shared/services/geoportal-base-referencia/municipio.service';
import {Municipio} from '../../../../../shared/models/geoportal-base-referencia/municipio.model';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {PostoFluviometrico} from '../../../../../shared/models/posto-fluviometrico.model';
import {PostoFluviometricoService} from '../../../../../shared/services/posto-fluviometrico.service';
import {ObraPostoFluviometrico} from '../../../../../shared/models/obra-posto-fluviometrico.model';
import {ConfirmarExclusaoDialogComponent} from '../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {MatDialog} from '@angular/material';
import {DialogObraPostoFluviometricoComponent} from './dialog-obra-posto-fluviometrico/dialog-obra-posto-fluviometrico.component';
import {CommonsUtil} from '../../../../../shared/util/commons-util';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-objetivo-obra-barragem',
    templateUrl: './objetivo-obra-barragem.component.html',
})
export class ObjetivoObraBarragemComponent extends BasePontoComponent implements OnInit {

    tiposObras = ObraBarragem.tiposObras;
    tiposVertedouros = ObraBarragem.tiposVertedouros;
    municipios: Municipio[];
    compareWithMunicipio = Municipio.compareWith;

    form: FormGroup;
    postosFluviometricos: PostoFluviometrico[];
    postosFluviometricosAdicionados: ObraPostoFluviometrico[];

    constructor(
        private formBuilder: FormBuilder,
        private pontoService: PontoService,
        private snackBarService: SnackBarService,
        private municipioService: MunicipioService,
        private postoFluviometricoService: PostoFluviometricoService,
        private el: ElementRef,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.construirForm();
        this.patchForms();
        this.municipioService.getAllByUf('MT').subscribe(municipios => {
            this.municipios = municipios;
        });
        this.postoFluviometricoService.getAll().subscribe(postos => {
            this.postosFluviometricos = postos;
        });
        this.carregarObraPostoFluviometricos();
        this.onChangeRelocacaoEstrada();
        this.onChangeRelocacaoPontes();
        this.onChangeTipoDeObra();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    private construirForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            tipoObra: [null, [Validators.required]],
            especifiqueTipoObra: [{disabled: true, value: null}],
            nomeCorpoHidrico: [null, [Validators.required, Validators.maxLength(255)]],
            baciaHidrografica: [null, [Validators.required, Validators.maxLength(255)]],
            subBaciaHidrografica: [null, [Validators.required, Validators.maxLength(255)]],
            municipioDireita: [null, [Validators.required]],
            municipioEsquerda: [null, [Validators.required]],
            distanciaFoz: [null, [Validators.required, Validators.min(0.00001)]],
            montanteMinimoNormal: [null, [Validators.required, Validators.min(0.00001)]],
            montanteMaximoNormal: [null, [Validators.required, Validators.min(0.00001)]],
            montanteMaximorum: [null, [Validators.required, Validators.min(0.00001)]],
            jusanteNormal: [null, [Validators.required, Validators.min(0.00001)]],
            jusanteMinima: [null, [Validators.required, Validators.min(0.00001)]],
            jusanteMaximoNormal: [null, [Validators.required, Validators.min(0.00001)]],
            nivelAguaMaximorum: [null, [Validators.required, Validators.min(0.00001)]],
            nivelAguaMaximoNormal: [null, [Validators.required, Validators.min(0.00001)]],
            nivelAguaMinimoNormal: [null, [Validators.required, Validators.min(0.00001)]],
            dispVazaoRemanescente: [null, [Validators.required]],
            descargaDeFundo: [null, [Validators.required, Validators.min(0.00001)]],
            volumeNivelAguaNormal: [null, [Validators.required, Validators.min(0.00001)]],
            volumeUtil: [null, [Validators.required, Validators.min(0.00001)]],
            abaixoSoleiraVertedouro: [null, [Validators.required, Validators.min(0.00001)]],
            vidaUtilAnos: [null, [Validators.required, Validators.min(1)]],
            vazaoRegularizada: [null, [Validators.required, Validators.min(0.00001)]],
            periodoCriticoInicio: [null, [Validators.required, Validators.min(1)]],
            periodoCriticoFim: [null, [Validators.required, Validators.min(1)]],
            perimetroReservatorio: [null, [Validators.required, Validators.min(0.00001)]],
            profundidadeMedia: [null, [Validators.required, Validators.min(0.00001)]],
            profundidadeMaxima: [null, [Validators.required, Validators.min(0.00001)]],
            reservTempoFormacao: [null, [Validators.required, Validators.min(1)]],
            reservTempoResidencia: [null, [Validators.required, Validators.min(1)]],
            reservDeplecaoMaxima: [null, [Validators.required, Validators.min(0.00001)]],
            reservVazaoDescarregada: [null, [Validators.required, Validators.min(0.00001)]],
            tipoMacicoBarragem: [null, [Validators.required, Validators.maxLength(255)]],
            barragemCotaCrista: [null, [Validators.required, Validators.min(0.00001)]],
            barragemComprimentoCrista: [null, [Validators.required, Validators.min(0.00001)]],
            barragemAlturaMaxima: [null, [Validators.required, Validators.min(0.00001)]],
            tipoVertedor: [null, [Validators.required]],
            comprimentoSoleira: [null, [Validators.required, Validators.min(0.00001)]],
            vertedouroCotaCrista: [null, [Validators.required, Validators.min(0.00001)]],
            numeroVaosVertedouro: [null, [Validators.required, Validators.min(1)]],
            vazaoVertedouro: [null, [Validators.required, Validators.min(0.00001)]],
            periodoRetorno: [null, [Validators.required, Validators.min(0.00001)]],
            laminaDaguaMaximaVertedouro: [null, [Validators.required, Validators.min(0.00001)]],
            tomadaDaguaLatitudeSul: [null, [Validators.required]],
            tomadaDaguaLongitudeOeste: [null, [Validators.required]],
            vazaoReduzidaLatitudeSul: [null],
            vazaoReduzidaLongitudeOeste: [null],
            extensaoTVR: [null, [Validators.required, Validators.min(0.00001)]],
            tipoTurbina: [null, [Validators.required, Validators.maxLength(255)]],
            turbinaVazaoUnitaria: [null, [Validators.required, Validators.min(0.00001)]],
            turbinaPotenciaUnitaria: [null, [Validators.required, Validators.min(0.00001)]],
            turbinaRendimentoMaximo: [null, [Validators.required, Validators.max(100), Validators.min(-100)]],
            turbinaRendimentoMinimo: [null, [Validators.required, Validators.max(100), Validators.min(-100)]],
            turbinaUnidades: [null, [Validators.required, Validators.min(0.00001)]],
            turbinaQuedaBrutaMaxima: [null, [Validators.required, Validators.min(0.00001)]],
            turbinaNivelAguaMaximorum: [null, [Validators.required, Validators.min(0.00001)]],
            cghQuedaMax: [null, [Validators.required, Validators.min(0.00001)]],
            cghQuedaReferencia: [null, [Validators.required, Validators.min(0.00001)]],
            cghPotenciaUsina: [null, [Validators.required, Validators.min(0.00001)]],
            cghEnergiaFirme: [null, [Validators.required, Validators.min(0.00001)]],
            cghEnergiaAssegurada: [null, [Validators.required, Validators.min(0.00001)]],
            cghPotenciaMedia: [null, [Validators.required, Validators.min(0.00001)]],
            populacaoAtingidaUrbana: [null, [Validators.required, Validators.min(1)]],
            populacaoAtingidaRural: [null, [Validators.required, Validators.min(1)]],
            familiasAtingidasUrbana: [null, [Validators.required, Validators.min(1)]],
            familiasAtingidasRural: [null, [Validators.required, Validators.min(1)]],
            nucleosUrbanosAtingidos: [null, [Validators.required, Validators.min(1)]],
            interferenciaAreaProtegida: [null, [Validators.required]],
            interferenciaAreaIndigena: [null, [Validators.required]],
            relocacaoEstrada: [null, [Validators.required]],
            extensaoRelocacaoEstrada: [{disabled: true, value: null}],
            relocacaoPontes: [null, [Validators.required]],
            extensaoRelocacaoPontes: [{disabled: true, value: null}],
            aspectosAmbientais: [null, [Validators.required]],
            outrosRecursosHidricos: [null, [Validators.required]],
            observacoes: [null, [Validators.required]],
        });
    }

    private patchForms(): void {
        if (this.ponto.obraBarragem) {
            this.form.patchValue(this.ponto.obraBarragem);
        }
    }

    salvarPonto(): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            const ponto = Ponto.fromJson(this.ponto);
            ponto.obraBarragem = ObraBarragem.fromJson(this.form.value);
            ponto.formularioConcluido = true;
            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.scrollToFirstInvalid(this.form.controls);
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    adicionarPosto(): void {
        const dialogRef = this.dialog.open(DialogObraPostoFluviometricoComponent, {
            data: {
                postosFluviometricos: this.postosFluviometricos.filter(item =>
                    !this.postosFluviometricosAdicionados.some(adicionado => item.id === adicionado.postoFluviometrico.id)
                ),
                form: {
                    obraBarragem: this.ponto.obraBarragem
                }
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.carregarObraPostoFluviometricos();
            }
        });

    }

    excluirPosto(posto: PostoFluviometrico): void {

        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: posto.nome}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.postoFluviometricoService.deleteObraPostoFluviometrico(this.ponto.obraBarragem, posto).subscribe(() => {
                    this.carregarObraPostoFluviometricos();
                    this.snackBarService.showSuccess('Posto fluviométrico removido com sucesso');
                }, (e) => this.snackBarService.showError('Erro ao remover Posto fluviométrico', e));
            }
        });

    }

    editarPosto(obraPostoFluviometrico: ObraPostoFluviometrico): void {
        const dialogRef = this.dialog.open(DialogObraPostoFluviometricoComponent, {
            data: {
                postosFluviometricos: this.postosFluviometricos.filter(item =>
                    !this.postosFluviometricosAdicionados.some(adicionado => item.id === adicionado.postoFluviometrico.id &&
                        item.id !== obraPostoFluviometrico.postoFluviometrico.id)
                ),
                form: obraPostoFluviometrico
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.carregarObraPostoFluviometricos();
            }

        });
    }

    private scrollToFirstInvalid(controls: { [p: string]: AbstractControl }): void {
        for (const key of Object.keys(controls)) {
            if (controls[key].invalid) {
                const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');

                break;
            }
        }
    }

    onChangeTipoDeObra(): void {
        const select = this.form.controls.tipoObra;
        const field = this.form.controls.especifiqueTipoObra;

        CommonsUtil.baseOnChange(select.value === 'OUTROS', select, field, [Validators.required, Validators.maxLength(255)]);
    }

    onChangeRelocacaoPontes(): void {
        const select = this.form.controls.relocacaoPontes;
        const field = this.form.controls.extensaoRelocacaoPontes;

        CommonsUtil.baseOnChange(select.value === true, select, field, [Validators.required, Validators.min(1)]);
    }

    onChangeRelocacaoEstrada(): void {
        const select = this.form.controls.relocacaoEstrada;
        const field = this.form.controls.extensaoRelocacaoEstrada;

        CommonsUtil.baseOnChange(select.value === true, select, field, [Validators.required, Validators.min(1)]);
    }

    private carregarObraPostoFluviometricos(): void {
        if (this.ponto.obraBarragem) {
            this.postoFluviometricoService.getAllByObraBarragem(this.ponto.obraBarragem).subscribe(postos => {
                this.postosFluviometricosAdicionados = postos;
            });
        }
    }
}
