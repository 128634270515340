import {NgModule} from '@angular/core';
import {CoreModule} from '../../core/core.module';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'app/main/shared/shared.module';
import {FinalidadeAtividadeDetailComponent} from './finalidade-atividade-detail/finalidade-atividade-detail.component';
import {FinalidadeAtividadeListComponent} from './finalidade-atividade-list/finalidade-atividade-list.component';
import {FinalidadeAtividadeListResolver, FinalidadeAtividadeResolver} from './finalidade-atividade.service';


export const routes: Routes = [
    {
        path: 'new',
        component: FinalidadeAtividadeDetailComponent
    },
    {
        path: 'finalidades-atividade',
        component: FinalidadeAtividadeListComponent,
        resolve: {
            data: FinalidadeAtividadeListResolver
        }
    },
    {
        path: ':id/edit',
        component: FinalidadeAtividadeDetailComponent,
        resolve: {
            data: FinalidadeAtividadeResolver
        }
    },
    {
        path: ':id/detail',
        component: FinalidadeAtividadeDetailComponent,
        resolve: {
            data: FinalidadeAtividadeResolver
        }
    }
];

@NgModule({
    declarations: [
        FinalidadeAtividadeListComponent,
        FinalidadeAtividadeDetailComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    entryComponents: [
        FinalidadeAtividadeDetailComponent,
        FinalidadeAtividadeListComponent
    ],
    providers: [
        FinalidadeAtividadeResolver,
        FinalidadeAtividadeListResolver
    ]
})
export class FinalidadesAtividadeModule {
}
