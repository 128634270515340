import {BaseEnum} from '../enums/base.enum';
import {BarragemExistente} from '../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-barragem-existente/barragem-existente.model';

export class AlocacaoVazao {

    constructor(
        public id?: number,
        public finalidade?: string,
        public percentualVazao?: number,
        public barragemExistente?: BarragemExistente,
    ) {
    }

    static finalidades: BaseEnum[] = [
        {
            valor: 'ABASTECIMENTO',
            descricao: 'Abastecimento'
        },
        {
            valor: 'IRRIGACAO',
            descricao: 'Irrigação'
        },
        {
            valor: 'INDUSTRIA',
            descricao: 'Industria'
        },
        {
            valor: 'ENERGIA',
            descricao: 'Energia'
        },
        {
            valor: 'OUTROS',
            descricao: 'Outros'
        }
    ];

    static fromJson(json: any): AlocacaoVazao {
        if (json === undefined || json === null) {
            return null;
        }

        return new AlocacaoVazao(
            json.id ? json.id : null,
            json.finalidade ? json.finalidade : null,
            json.percentualVazao ? json.percentualVazao : null,
            json.barragemExistente ? json.barragemExistente : null,
        );
    }

    static fromJsons(json: any): AlocacaoVazao[] {
        const array: AlocacaoVazao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(AlocacaoVazao.fromJson(item)));
            return array;
        }
    }
}
