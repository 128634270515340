import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {VeiculosEquipamentosResiduosPerigosos} from "../../../../../../../../shared/models/veiculos-equipamentos-residuos-perigosos.model";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {ResiduosTransporteProdutosPerigososService} from "../residuos-transporte-produtos-perigosos.service";

@Component({
    selector: 'app-transporte-residuos-veiculos-equipamentos',
    templateUrl: './transporte-residuos-veiculos-equipamentos.component.html',
    styleUrls: ['./transporte-residuos-veiculos-equipamentos.component.scss']
})
export class TransporteResiduosVeiculosEquipamentosComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly : boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() enviarVeiculosEquipamentos = new EventEmitter<any[]>();
    formVeiculosEquipamentos: FormGroup;
    idVeiculosEquipamentos: number = 0;
    veiculosEquipamentosSalvos: any[] = [];
    dataSourceVeiculos = new MatTableDataSource<any>([]);

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private serviceTransporte: ResiduosTransporteProdutosPerigososService) {
    }

    ngOnInit() {
        this.buildForm();

        if(this.idForm){
            this.serviceTransporte.buscarVeiculosPorId(this.idForm).subscribe( response => {
                response.forEach( veiculos => {
                    this.veiculosEquipamentosSalvos.push({
                        id: veiculos.id,
                        idTemp: this.idVeiculosEquipamentos,
                        placa: veiculos.placa,
                        marca: veiculos.marca,
                        tipo: veiculos.tipo,
                        ano: veiculos.ano,
                        carga: veiculos.carga,
                    });
                    this.idVeiculosEquipamentos++;
                })
                this.dataSourceVeiculos = new MatTableDataSource(this.veiculosEquipamentosSalvos);
                this.enviarVeiculos(this.mapearArray());
            }, error => {
                this.snackBarService.showError("Ocorreu um erro ao buscar a lista de veiculos e equipamentos. Cod. Erro: " + error.status);
            })
        }

        if (this.isNaoPodeEditar) {
            this.formVeiculosEquipamentos.disable();
        }
    }

    private buildForm(): void {
        this.formVeiculosEquipamentos = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            placa: ["", [Validators.required]],
            marca: ["", [Validators.required]],
            tipo: ["", [Validators.required]],
            ano: ["", [Validators.required]],
            carga: ["", [Validators.required]],
        })
    }

    mapearArray(): any{
        if(this.veiculosEquipamentosSalvos.length == 0){
            return this.veiculosEquipamentosSalvos;
        }else{
            return this.veiculosEquipamentosSalvos.map(function(item){
                return new VeiculosEquipamentosResiduosPerigosos(
                    item.id,
                    null,
                    item.placa,
                    item.marca,
                    item.tipo,
                    item.ano,
                    Number(item.carga),
                );
            });
        }
    }

    validarPlaca(){
        //TODO Validar placa do veiculo na API do DETRAN de acordo com a US008 RN003 - Consultar API DETRAN
    }

    adicionarVeiculo(){
        if(this.formVeiculosEquipamentos.valid){
            this.veiculosEquipamentosSalvos.push({
                id: null,
                idTemp: this.idVeiculosEquipamentos,
                placa: this.formVeiculosEquipamentos.controls.placa.value,
                marca: this.formVeiculosEquipamentos.controls.marca.value,
                tipo: this.formVeiculosEquipamentos.controls.tipo.value,
                ano: this.formVeiculosEquipamentos.controls.ano.value,
                carga: this.formVeiculosEquipamentos.controls.carga.value
            })
            this.idVeiculosEquipamentos++;
            this.dataSourceVeiculos = new MatTableDataSource(this.veiculosEquipamentosSalvos);
            this.enviarVeiculos(this.mapearArray());
            this.formVeiculosEquipamentos.reset();
        }else {
            this.formVeiculosEquipamentos.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    enviarVeiculos(veiculos: any[]) {
        this.enviarVeiculosEquipamentos.emit(veiculos);
    }

    deletarVeiculo(item, acao?: string, itemId?: number){
        if(acao == 'DELETAR' && itemId != null){
            this.serviceTransporte.deletarVeiculoPorId(itemId).subscribe( response => {
                this.snackBarService.showSuccess("Veiculo/Equipamento excluido com sucesso.")
            })
        }
        let data;
        data = this.veiculosEquipamentosSalvos.filter((value, key) => {
            return value.idTemp != item;
        });
        this.veiculosEquipamentosSalvos = data;
        this.dataSourceVeiculos = new MatTableDataSource(data);
        this.enviarVeiculos(this.mapearArray());
    }
}
