import {HttpParams} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material';
import {ActivatedRouteSnapshot} from '@angular/router';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {Page} from 'app/main/shared/models/page.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {RequerimentoOutorgaService} from './requerimento-outorga/requerimento-outorga.service';
import {
    DelegarListaTarefaDTO
} from './gerenciar-requerimentos-processos/dialog-distribuir-requerimento/delegar-lista-tarefa-dto.model';
import {
    ObservacaoDistribuicao
} from './gerenciar-requerimentos-processos/dialog-observacao-requerimento-distribuido/observacao-distribuicao.model';
import {FormUtil} from "../../shared/util/form-util";
import {HistoricoTrocaAnalistas} from "../../shared/models/historico-troca-analistas.model";
import {RedistribuicaoProcessoDTO} from './gerenciar-requerimentos-processos/model/redistribuicao-processo-dto';
import {GerenciarRequerimentosDTO} from "./gerenciar-requerimentos-processos/model/gerenciar-requerimentos.dto";
import {RequerimentoTaskDTO} from './gerenciar-requerimentos-processos/model/requerimento-task.dto';
import {NovaAtribuicaoProcessosDTO} from './gerenciar-requerimentos-processos/model/nova-atribuicao-processos.dto';

@Injectable()
export class RequerimentoProcessoResolver {

    constructor(
        private service: GerenciarRequerimentoProcessoService,
        private routingStateService: RoutingStateService,
        private requerimentoOutorgaService: RequerimentoOutorgaService
    ) {
    }

    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        this.routingStateService.addQueryParamsHistory(route.queryParams);
        if (route.params['id'] != null) {
            const requerimento = await this.service.getById(route.params['id']).toPromise();
            const retorno = {requerimento: requerimento};
            if (requerimento.tipoProcesso.toUpperCase() === 'OUTORGA') {
                retorno['requerimentoOutorga'] = await this.requerimentoOutorgaService.getByRequerimentoId(requerimento.id).toPromise();
            }

            return retorno;
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class GerenciarRequerimentoProcessoService extends BaseService<GerenciarRequerimentosDTO> {
    constructor(protected injector: Injector) {
        super(
            '/api/requerimentos',
            injector, GerenciarRequerimentosDTO.prototype,
            GerenciarRequerimentosDTO.fromJson
        );
    }

    public listarRequerimentosProcessos(
        form: FormGroup,
        tipoCoordenada: string,
        paginator: MatPaginator,
        isPage = true
    ): Observable<Page<GerenciarRequerimentosDTO>> {
        const url = `${this.urlResource}/requerimentos-processos`;

        const params: any = {
            page: String(paginator.pageIndex),
            size: String(paginator.pageSize),
            tipoConsulta: form.get('tipoConsulta').value,
            numeroRequerimento: form.get('numeroRequerimento').value,
            numeroProcesso: form.get('numeroProcesso').value,
            numeroProtocolo: form.get('numeroProtocolo').value,
            nomeInteressado: form.get('nomeInteressado').value,
            cpfCnpjInteressado: form.get('cpfCnpjInteressado').value,
            conferenteResponsavelCpf: form.get('conferenteResponsavelCpf').value,
            analistaResponsavelCpf: form.get('analistaResponsavelCpf').value,
            atividadeId: form.get('atividadeId').value,
            situacaoRequerimentoProcessoPeriodo: form.get('situacaoRequerimentoProcessoPeriodo').value,
            dataInicio: new Date(form.get('dataInicio').value).getTime(),
            dataFim: new Date(form.get('dataFim').value).getTime(),
            situacaoRequerimentoProcesso: form.get('situacaoRequerimentoProcesso').value,
            municipioEmpreendimento: form.get('municipioEmpreendimento').value,
            nomeEmpreendimento: form.get('nomeEmpreendimento').value,
            tiposOutorga: form.get('tiposOutorga').value && form.get('tiposOutorga').value.length > 0 ? form.get('tiposOutorga').value : null,
            titulacaoId: form.get('titulacaoId').value,
            finalidadeIds: form.get('finalidadeIds').value,
            objLicenciamentoIds: form.get('objLicenciamentoIds').value && form.get('objLicenciamentoIds').value.length > 0 ? form.get('objLicenciamentoIds').value : null,
            tipoRequerimentoIds: form.get('tipoRequerimentoIds').value,
            responsavelImovel: form.get('responsavelImovel').value,
            responsavelTecnico: form.get('responsavelTecnico').value,
            representanteLegal: form.get('representanteLegal').value,
            latitude: tipoCoordenada === 'grau' ? FormUtil.parseDMSToDecimal(form.get('latitude').value) : form.get('latitude').value,
            longitude: tipoCoordenada === 'grau' ? FormUtil.parseDMSToDecimal(form.get('longitude').value) : form.get('longitude').value,
            tipoAplicacao: form.get('tipoAplicacao').value,
            regraApresentacao: form.get('regraApresentacao').value,
            valorBuffer: form.get('tipoAplicacao').value === 'BUFFER' ? form.get('valorBuffer').value : null,
            nomeTarefa: form.get('nomeTarefa').value,
            processoTarefa: form.get('processoTarefa').value,
            responsavelTarefa: form.get('responsavelTarefa').value,
            prioridade: form.get('prioridade').value,
            tipoProcesso: form.get('tipoProcesso').value && form.get('tipoProcesso').value.length > 0 ? form.get('tipoProcesso').value.map(x => x.id) : null,
            isPage: isPage
        };

        const httpParams = Object.getOwnPropertyNames(params).filter(item => params[item]).reduce(
            (p, key) => p.set(key, params[key]), new HttpParams()
        );

        return this.http.get(url, {params: httpParams}).pipe(
            map((response) => this.jsonToPage(response)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Consulta o tipo do perfil do servidor logado
     */
    getIsPerfilCoordenador(): Observable<any> {
        const url = `${this.urlResource}/is-perfil-coordenador`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Lista servidores com perfil de coordenadores
     */
    getServidoresCoordenadores(): Observable<any> {
        const url = `${this.urlResource}/servidores-perfil-coordenador`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getObservacaoRequerimentoDistribuido(requerimentoId: number): Observable<ObservacaoDistribuicao[]> {
        const url = `${this.urlResource}/observacao-requerimento-distribuido/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(response => ObservacaoDistribuicao.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    delegarListaTarefas(form: FormGroup): Observable<any> {
        const delegarTarefaDTO: DelegarListaTarefaDTO = new DelegarListaTarefaDTO();
        delegarTarefaDTO.cpfServidor = form.get('cpfServidor').value;
        delegarTarefaDTO.listaRequerimentos = form.get('requerimentosAtribuidos').value;
        delegarTarefaDTO.observacao = form.get('observacao').value;

        const url = `${this.urlResource}/delegar-tarefa-lista`;

        return this.http
        .post(url, delegarTarefaDTO)
        .pipe(
            map(DelegarListaTarefaDTO.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Exporta lista de requerimentos para xlsx.
     */
    exportToExcel(form: FormGroup, tipoCoordenada: string): Observable<any> {
        const url = `${this.urlResource}/requerimentos-export-xlsx`;

        const params: any = {
            tipoConsulta: form.get('tipoConsulta').value,
            numeroRequerimento: form.get('numeroRequerimento').value,
            numeroProcesso: form.get('numeroProcesso').value,
            numeroProtocolo: form.get('numeroProtocolo').value,
            nomeInteressado: form.get('nomeInteressado').value,
            cpfCnpjInteressado: form.get('cpfCnpjInteressado').value,
            conferenteResponsavelCpf: form.get('conferenteResponsavelCpf').value,
            analistaResponsavelCpf: form.get('analistaResponsavelCpf').value,
            atividadeId: form.get('atividadeId').value,
            situacaoRequerimentoProcessoPeriodo: form.get('situacaoRequerimentoProcessoPeriodo').value,
            dataInicio: new Date(form.get('dataInicio').value).getTime(),
            dataFim: new Date(form.get('dataFim').value).getTime(),
            situacaoRequerimentoProcesso: form.get('situacaoRequerimentoProcesso').value,
            municipioEmpreendimento: form.get('municipioEmpreendimento').value,
            nomeEmpreendimento: form.get('nomeEmpreendimento').value,
            tiposOutorga: form.get('tiposOutorga').value && form.get('tiposOutorga').value.length > 0 ? form.get('tiposOutorga').value : null,
            titulacaoId: form.get('titulacaoId').value,
            finalidadeIds: form.get('finalidadeIds').value,
            objLicenciamentoIds: form.get('objLicenciamentoIds').value && form.get('objLicenciamentoIds').value.length > 0 ? form.get('objLicenciamentoIds').value : null,
            tipoRequerimentoIds: form.get('tipoRequerimentoIds').value,
            responsavelImovel: form.get('responsavelImovel').value,
            responsavelTecnico: form.get('responsavelTecnico').value,
            representanteLegal: form.get('representanteLegal').value,
            latitude: tipoCoordenada === 'grau' ? FormUtil.parseDMSToDecimal(form.get('latitude').value) : form.get('latitude').value,
            longitude: tipoCoordenada === 'grau' ? FormUtil.parseDMSToDecimal(form.get('longitude').value) : form.get('longitude').value,
            tipoAplicacao: form.get('tipoAplicacao').value,
            regraApresentacao: form.get('regraApresentacao').value,
            nomeTarefa: form.get('nomeTarefa').value,
            processoTarefa: form.get('processoTarefa').value,
            responsavelTarefa: form.get('responsavelTarefa').value,
            prioridade: form.get('prioridade').value,
            tipoProcesso: form.get('tipoProcesso').value && form.get('tipoProcesso').value.length > 0 ? form.get('tipoProcesso').value.map(x => x.id) : null
        };

        const httpParams = Object.getOwnPropertyNames(params).filter(item => params[item]).reduce(
            (p, key) => p.set(key, params[key]), new HttpParams()
        );

        return this.http.get(url, {
            params: httpParams,
            responseType: 'blob'
        }).pipe(
            map(res => res),
            catchError(this.handleError.bind(this))
        );
    }

    public substituirAnalistaDoProcesso(histTrocaAnalista: HistoricoTrocaAnalistas): Observable<any> {
        const url = `${this.urlResource}/substituir-analista-processo`;

        return this.http.post(url, histTrocaAnalista).pipe(
            map((response) => response),
            catchError(this.handleError.bind(this)));
    }

    //Verifica se o servidor logado é um gestor: superintendente/coordenador/gerente e seus substitutos
    getIsPerfilGestor(): Observable<any> {
        const url = `${this.urlResource}/is-perfil-gestor`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    enviarParaRedistribuicao(redistribuicaoProcessoDTO: RedistribuicaoProcessoDTO): Observable<any> {
        const url = `${this.urlResource}/tasks/redistribuir-processo/list`;
        return this.http.post(url, redistribuicaoProcessoDTO).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    findTasksCamundaByIdsRequerimento(redistribuicaoProcessoDTO: RedistribuicaoProcessoDTO): Observable<any> {
        const url = `${this.urlResource}/instancias-task-camunda`;
        return this.http.put(url, redistribuicaoProcessoDTO).pipe(
            map(response => RequerimentoTaskDTO.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    public realizarNovaAtribuicaoAnalistaDoProcesso(novaAtribuicaoProcessosDTO: NovaAtribuicaoProcessosDTO): Observable<any> {
        const url = `${this.urlResource}/realizar-nova-atribuicao/requerimentos`;
        return this.http.post(url, novaAtribuicaoProcessosDTO).pipe(
            map((response) => response),
            catchError(this.handleError.bind(this)));
    }

}