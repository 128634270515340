import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {DadosGeraisTransporteResiduosPerigosos} from "../../../../../../../../shared/models/dados-gerais-transporte-residuos-perigosos.model";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {ResiduosTransporteProdutosPerigososService} from "../residuos-transporte-produtos-perigosos.service";

@Component({
    selector: 'app-transporte-residuos-dados-gerais',
    templateUrl: './transporte-residuos-dados-gerais.component.html',
    styleUrls: ['./transporte-residuos-dados-gerais.component.scss']
})
export class TransporteResiduosDadosGeraisComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly : boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() enviarDadosGerais = new EventEmitter<any[]>();
    formTransporteDadosGerais: FormGroup;
    residuosTransportados: any = [];
    dataSourceTransportados = new MatTableDataSource<any>([]);
    idDadosGerais: number = 0;
    modoEdicao: boolean = false;
    unidadeMedidaFiltrada: any[] = [];
    tipoResiduoTransportado: any[] = [];

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private dominioService: DominiosService,
                private serviceTransporte: ResiduosTransporteProdutosPerigososService) {
    }

    async ngOnInit() {
        this.buildFormResiduoGeral();

        //Buscando unidade de medida filtrada
        this.unidadeMedidaFiltrada = await this.serviceTransporte.buscarDominiosFiltrados().toPromise();

        //Buscando tipos de residuo a ser transportado
        this.dominioService.getAllTipoResiduoTransportado().subscribe( retorno => {
            this.tipoResiduoTransportado = retorno;
        }, error => {
            this.snackBarService.showError("Ocorreu um erro ao buscar a lista de tipo residuo transportado. Cod. Erro: " + error.status);
        })

        if(this.idForm){
            this.serviceTransporte.buscarResiduosGeraisPorId(this.idForm).subscribe( response => {
                response.forEach( residuosGerais => {
                    this.residuosTransportados.push({
                        id: residuosGerais.id,
                        idTemp: this.idDadosGerais,
                        residuoTransportado: residuosGerais.residuoTransportado,
                        quantidadeTotalResiduo: residuosGerais.qtdTotalResiduo,
                        unidadeMedidaResiduo: residuosGerais.unidadeResiduoTransportado,
                        quantidadeMinimaResiduo: residuosGerais.qtdMinimaViagem,
                        quantidadeMaximaResiduo: residuosGerais.qtdMaximaViagem,
                        quantidadeViagensPorAno: residuosGerais.qtdViagensPorAno,
                    });
                    this.idDadosGerais++;
                })
                this.dataSourceTransportados = new MatTableDataSource(this.residuosTransportados);
                this.enviarDados(this.mapearArray());
            }, error => {
                this.snackBarService.showError("Ocorreu um erro ao buscar os dados gerais. Cod. Erro: " + error.status);
            })
        }

        if (this.isNaoPodeEditar) {
            this.formTransporteDadosGerais.disable();
        }
    }

    private buildFormResiduoGeral(): void {
        this.formTransporteDadosGerais = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            residuoTransportado: ["", [Validators.required]],
            quantidadeTotalResiduo: ["", [Validators.required]],
            unidadeMedidaResiduo: ["", [Validators.required]],
            quantidadeMinimaResiduo: ["", [Validators.required]],
            quantidadeMaximaResiduo: ["", [Validators.required]],
            quantidadeViagensPorAno: ["", [Validators.required]]
        })
    }

    mapearArray(): any{
        if(this.residuosTransportados.length == 0){
            return this.residuosTransportados;
        }else{
            return this.residuosTransportados.map(function(item){
                return new DadosGeraisTransporteResiduosPerigosos(
                    item.id,
                    null,
                    item.residuoTransportado,
                    Number(item.quantidadeTotalResiduo),
                    item.unidadeMedidaResiduo,
                    Number(item.quantidadeMinimaResiduo),
                    Number(item.quantidadeMaximaResiduo),
                    Number(item.quantidadeViagensPorAno),
                );
            });
        }
    }

    changeResiduoTransportado() {
        this.formTransporteDadosGerais.controls.unidadeMedidaResiduo.reset();
    }

    enviarDados(transporteResiduos: any[]){
        this.enviarDadosGerais.emit(transporteResiduos);
    }

    adicionarTransporteResiduo() {
        if(this.formTransporteDadosGerais.valid){
            this.residuosTransportados.push({
                id: null,
                idTemp: this.idDadosGerais,
                residuoTransportado: this.formTransporteDadosGerais.controls.residuoTransportado.value,
                quantidadeTotalResiduo: this.formTransporteDadosGerais.controls.quantidadeTotalResiduo.value,
                unidadeMedidaResiduo: this.formTransporteDadosGerais.controls.unidadeMedidaResiduo.value,
                quantidadeMinimaResiduo: this.formTransporteDadosGerais.controls.quantidadeMinimaResiduo.value,
                quantidadeMaximaResiduo: this.formTransporteDadosGerais.controls.quantidadeMaximaResiduo.value,
                quantidadeViagensPorAno: this.formTransporteDadosGerais.controls.quantidadeViagensPorAno.value,
            })
            this.dataSourceTransportados = new MatTableDataSource(this.residuosTransportados);
            this.idDadosGerais++;
            this.enviarDados(this.mapearArray());
            this.formTransporteDadosGerais.reset();
        }else {
            this.formTransporteDadosGerais.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    ativarModoEdicao(item){
        this.formTransporteDadosGerais.controls.id.patchValue(item.id);
        this.formTransporteDadosGerais.controls.idTemp.patchValue(item.idTemp);
        this.formTransporteDadosGerais.controls.residuoTransportado.patchValue(item.residuoTransportado);
        this.formTransporteDadosGerais.controls.quantidadeTotalResiduo.patchValue(item.quantidadeTotalResiduo);
        this.formTransporteDadosGerais.controls.unidadeMedidaResiduo.patchValue(item.unidadeMedidaResiduo);
        this.formTransporteDadosGerais.controls.quantidadeMinimaResiduo.patchValue(item.quantidadeMinimaResiduo);
        this.formTransporteDadosGerais.controls.quantidadeMaximaResiduo.patchValue(item.quantidadeMaximaResiduo);
        this.formTransporteDadosGerais.controls.quantidadeViagensPorAno.patchValue(item.quantidadeViagensPorAno);
        this.modoEdicao = true;
    }

    fecharEdicao(){
        this.modoEdicao = false;
        this.formTransporteDadosGerais.reset();
    }

    editarTransporteResiduo(){
        if(this.formTransporteDadosGerais.valid){
            this.deletarTransporteResiduo(this.formTransporteDadosGerais.controls.idTemp.value);

            this.residuosTransportados.push({
                id: this.formTransporteDadosGerais.controls.id.value,
                idTemp: this.formTransporteDadosGerais.controls.idTemp.value,
                residuoTransportado: this.formTransporteDadosGerais.controls.residuoTransportado.value,
                quantidadeTotalResiduo: this.formTransporteDadosGerais.controls.quantidadeTotalResiduo.value,
                unidadeMedidaResiduo: this.formTransporteDadosGerais.controls.unidadeMedidaResiduo.value,
                quantidadeMinimaResiduo: this.formTransporteDadosGerais.controls.quantidadeMinimaResiduo.value,
                quantidadeMaximaResiduo: this.formTransporteDadosGerais.controls.quantidadeMaximaResiduo.value,
                quantidadeViagensPorAno: this.formTransporteDadosGerais.controls.quantidadeViagensPorAno.value,
            })
            this.dataSourceTransportados = new MatTableDataSource(this.residuosTransportados);
            this.enviarDados(this.mapearArray());
            this.formTransporteDadosGerais.reset();
            this.modoEdicao = false;
        }else{
            this.formTransporteDadosGerais.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    deletarTransporteResiduo(item, acao?: string, itemId?: number){
        if(acao == 'DELETAR' && itemId != null){
            this.serviceTransporte.deletarTranposrteResiduoPorId(itemId).subscribe( response => {
                this.snackBarService.showSuccess("Residuo dados gerais excluido com sucesso.")
            }, error => {
                this.snackBarService.showError("Ocorreu um erro ao deletar o transporte de residuo. Cod. Erro: " + error.status);
            })
        }
        let data;
        data = this.residuosTransportados.filter((value, key) => {
            return value.idTemp != item;
        });
        this.residuosTransportados = data;
        this.dataSourceTransportados= new MatTableDataSource(data);
        this.enviarDados(this.mapearArray());
    }
}
