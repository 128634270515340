import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';

import {Finalidade} from './finalidade.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ObjetivoOutorga} from "../../shared/models/objetivo-outorga.model";
import {HttpParams} from "@angular/common/http";


@Injectable()
export class FinalidadeListResolver extends ListResolver<Finalidade> {
    constructor(service: FinalidadeService) {
        super(service);
    }
}

@Injectable()
export class FinalidadeResolver extends DetailResolver<Finalidade> {
    constructor(service: FinalidadeService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FinalidadeService extends BaseService<Finalidade> {

    constructor(protected injector: Injector) {
        super('/api/finalidades', injector, Finalidade.prototype, Finalidade.fromJson);
    }

    findAllByObjetivos(objetivos: ObjetivoOutorga[]): Observable<Finalidade[]> {
        const url = `${this.urlResource}/objetivos`;
        let params = new HttpParams()
        objetivos.forEach(obj => params = params.append('objetivo', obj.id.toString()))
        return this.http.get(url, {params: params}).pipe(
            map(jsonArray => this.jsonToResources(jsonArray)),
            catchError(this.handleError)
        );
    }
}
