import {BaseModel} from "./base.model";

export class EmpreendimentoTransporteResiduosPerigosos extends BaseModel {
    constructor(
        public id?: number,
        public idRequerimento?: boolean,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoTransporteResiduosPerigosos {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoTransporteResiduosPerigosos(
            json.id,
            json.idRequerimento
        );
    }

    static fromJsons(json: any): EmpreendimentoTransporteResiduosPerigosos[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(EmpreendimentoTransporteResiduosPerigosos.fromJson(j)));
            return resources;
        }
    }
}