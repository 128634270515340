import {BaseModel} from 'app/main/shared/models/base.model';

export class DRDH extends BaseModel {
    constructor(
        public id?: number,
        public corpoHidrico?: string,
        public areaDrenagem?: number,
        public vazaoFirme?: number,
        public comprimentoTrechoVazao?: number,
        public descricaoEstruturas?: string,
        public latFinal?: string,
        public longFinal?: string,
        public latRestituicao?: string,
        public longRestituicao?: string,
    ) {
        super();
    }

    static fromJson(json: any): DRDH {
        if (json === undefined || json === null) {
            return null;
        }
        return new DRDH(
            json.id,
            json.corpoHidrico,
            json.areaDrenagem,
            json.vazaoFirme,
            json.comprimentoTrechoVazao,
            json.descricaoEstruturas,
            json.latFinal,
            json.longFinal,
            json.latRestituicao,
            json.longRestituicao,
        );
    }
}
