import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {fuseAnimations} from '@fuse/animations';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CaracterizacaoConsumoAgua} from 'app/main/shared/models/caracterizacao-consumo-agua.model';
import {RequerimentoOutorgaFinalidadeService} from 'app/main/shared/services/requerimento-outorga-finalidade.service';
import {CaracterizacaoConsumoAguaService} from 'app/main/shared/services/caracterizacao-consumo-agua.service';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-finalidade-industria',
    templateUrl: 'finalidade-industria.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class FinalidadeIndustriaComponent extends BaseFinalidadeComponent implements OnInit {
    form: FormGroup;
    formCaracterizacaoConsumoAgua: FormGroup;
    caracterizacoesConsumoAgua: CaracterizacaoConsumoAgua[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private caracterizacaoConsumoAguaService: CaracterizacaoConsumoAguaService,
        private snackBarService: SnackBarService) {
        super();
    }

    ngOnInit(): void {
        this.buildForms();
        this.patchForm();
        this.listarCaracterizacoesConsumoAgua();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    private patchForm(): void {
        this.form.patchValue({
            id: this.requerimentoOutorgaFinalidade.id,
            finalidade: this.requerimentoOutorgaFinalidade.finalidade,
            requerimentoOutorga: this.requerimentoOutorgaFinalidade.requerimentoOutorga
        });
        if (this.requerimentoOutorgaFinalidade.industria) {
            this.form.patchValue({
                industria: this.requerimentoOutorgaFinalidade.industria
            });
        }
    }

    private buildForms(): void {
        this.form = this.formBuilder.group({
            id: [null],
            finalidade: [null, [Validators.required]],
            requerimentoOutorga: [null, [Validators.required]],
            industria: this.formBuilder.group({
                id: [null],
                horas: [null, [Validators.required, Validators.max(24), Validators.min(1)]],
                dias: [null, [Validators.required, Validators.max(31), Validators.min(1)]],
                meses: [null, [Validators.required, Validators.max(12), Validators.min(1)]],
                consumoPerCaptaDiario: [null, [Validators.required, Validators.min(0)]],
                numeroFuncionarios: [null, [Validators.required, Validators.min(0), Validators.max(2147483647)]],
                consumoDiarioIndustria: [null, [Validators.required, Validators.min(0)]],
                vazaoUsoDomestico: [null, [Validators.required, Validators.min(0.0001)]]
            }),
            observacao: [null, [Validators.maxLength(3000)]],
            cadastroConcluido: [null]
        });

        this.formCaracterizacaoConsumoAgua = this.formBuilder.group({
            produto: [null, [Validators.required, Validators.maxLength(255)]],
            unidade: [null, Validators.required],
            consumoAgua: [null, Validators.required],
            producao: [null, Validators.required]
        });
    }

    private listarCaracterizacoesConsumoAgua(): void {
        if (this.requerimentoOutorgaFinalidade.industria && this.requerimentoOutorgaFinalidade.industria.id) {
            this.caracterizacaoConsumoAguaService.getAllByIndustria(this.requerimentoOutorgaFinalidade.industria)
                .subscribe(caracterizacoesConsumoAgua => {
                    this.caracterizacoesConsumoAgua = caracterizacoesConsumoAgua;
                    if (this.requerimentoOutorgaFinalidade.cadastroConcluido !== caracterizacoesConsumoAgua.length > 0) {
                        this.salvarIndustria(false);
                    }
                });
        }
    }

    salvarIndustria(showMessage: boolean = true): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.form.patchValue({cadastroConcluido: this.caracterizacoesConsumoAgua.length > 0});
            this.requerimentoOutorgaFinalidadeService.update(this.form.value)
                .subscribe(
                    (requerimentoOutorgaFinalidade) => {
                        Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                        this.form.patchValue(requerimentoOutorgaFinalidade);
                        this.form.markAsPristine();
                        if (showMessage) {
                            this.snackBarService.showSuccess('Registro salvo com sucesso!');
                        }
                    },
                    (e) => {
                            this.snackBarService.showError('Erro ao salvar dados da industria.', e);
                    }
                );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    adicionarCaracterizacaoConsumoAgua(): void {
        if (!this.requerimentoOutorgaFinalidade.industria) {
            this.salvarIndustria(false);
            setTimeout(() => {
                if (this.requerimentoOutorgaFinalidade.industria && this.requerimentoOutorgaFinalidade.industria.id) {
                    this.salvarConsumo();
                }
            }, 3000);
        }else{
            this.salvarConsumo();
        }
    }

    private salvarConsumo(): void {
        this.formCaracterizacaoConsumoAgua.markAllAsTouched();
        if (this.formCaracterizacaoConsumoAgua.invalid) {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            return;
        }
        const caracterizacaoConsumoAgua = CaracterizacaoConsumoAgua.fromJson(this.formCaracterizacaoConsumoAgua.value);
        caracterizacaoConsumoAgua.industria = {id: this.requerimentoOutorgaFinalidade.industria.id};
        this.caracterizacaoConsumoAguaService.create(caracterizacaoConsumoAgua)
            .subscribe(() => {
                this.snackBarService.showSuccess('Consumo de água adicionado com sucesso!');
                this.formCaracterizacaoConsumoAgua.reset();
                this.listarCaracterizacoesConsumoAgua();
            });
    }

    excluirCaracterizacaoConsumoAgua(caracterizacaoConsumoAgua: CaracterizacaoConsumoAgua): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: caracterizacaoConsumoAgua.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.caracterizacaoConsumoAguaService.delete(caracterizacaoConsumoAgua).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Consumo de água excluído com sucesso!');
                        this.listarCaracterizacoesConsumoAgua();
                    },
                    (e) => this.snackBarService.showError('Erro ao excluir consumo de água.', e)
                );
            }
        });
    }

}
