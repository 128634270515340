import {Component, OnInit} from '@angular/core';
import {AbstractAbaAtividadeRequerimento} from "../../../../../../../shared/components/aba-atividades/abstract-aba-atividade-requerimento";
import {ServicoGeral} from "../../../../../../../shared/models/servico-geral.model";
import {ServicoGeralService} from "../../../../../../../shared/services/servico-geral.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../shared/models/dominio-dto.model";
import {checkboxBooleanRequiredDirective} from "../../../../../../../shared/directives/checkbox-boolean-required.directive";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";

@Component({
    selector: 'app-aba-atividade-serv-geral',
    templateUrl: './servico-geral.component.html',
    styleUrls: ['./servico-geral.component.scss']
})
export class ServicoGeralComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {
    private readonly TIPO_HOTEL_POUSADA: number = 1;
    private readonly TIPO_HOSPITAL: number = 2;
    private readonly TIPO_SHOPPING: number = 3;
    private readonly TIPO_OFICINA: number = 4;

    form: FormGroup;
    entidade: ServicoGeral = {} as any;
    consultasIniciaisFinalizadas: boolean = false;
    isNaoPodeEditar: boolean;

    tiposAtividade: DominioDto[] = [];

    constructor(
        protected formBuilder: FormBuilder,
        protected dominiosService: DominiosService,
        protected service: ServicoGeralService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
        private snackBarService: SnackBarService,
    ) {
        super();
    }

    async ngOnInit() {
        this.consultasIniciaisFinalizadas = false;
        this.form = this.formBuilder.group({});
        this.addControlsForm();
        await this.getDominios();
        await this.getEntidadeByRequerimento();

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
            this.form.disable();
        }
    }

    async carregarServicoGeral(idRequerimento){
        let res = await this.service.getByRequerimento(idRequerimento).toPromise();
        this.entidade = res ? res : {} as any;

        if (res) {
            await this.atualizaFormEntidade();
            this.consultasIniciaisFinalizadas = true;
        } else {
            this.consultasIniciaisFinalizadas = true;
        }
    }

    protected addControlsForm() {
        this.form = this.formBuilder.group({
            tipoAtividade: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
        });
    }

    protected async getEntidadeByRequerimento() {
        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarServicoGeral(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarServicoGeral(this.requerimento.id)
            }
        }
        if(this.isModoVisualizacao){
            this.form.disable()
        }
    }

    private async atualizaFormEntidade() {
        await this.posGetEntidade();
        this.form.patchValue(this.entidade);
    }

    protected async getDominios() {
        this.tiposAtividade = await this.dominiosService.getDominioByUrl("tipos-atv-serv-geral").toPromise();
    }

    isTipoHotelPousada() {
        return this.getValueFromEntidade('tipoAtividade') === this.TIPO_HOTEL_POUSADA;
    }

    isTipoHospital() {
        return this.getValueFromEntidade('tipoAtividade') === this.TIPO_HOSPITAL;
    }

    isTipoShopping() {
        return this.getValueFromEntidade('tipoAtividade') === this.TIPO_SHOPPING;
    }

    isTipoOficina() {
        return this.getValueFromEntidade('tipoAtividade') === this.TIPO_OFICINA;
    }

    protected getValueFromEntidade(parametro: string) {
        let tipo;
        if (this.form) {
            tipo = this.form.get(parametro).value;
        }

        if (!tipo) {
            if (this.entidade) {
                tipo = this.entidade[parametro]
            }
        }

        return tipo;
    }

    protected async posGetEntidade(): Promise<void> {
        this.atualizaTipoAtividade();
    }

    atualizaTipoAtividade() {
        this.atualizaHotelPousada();
        this.atualizaHospital()
        this.atualizaShoppingCenter();
        this.atualizaOficina();
    }

    atualizaHotelPousada() {
        if (this.isTipoHotelPousada()) {
            this.form.addControl('hotelPousada', this.formBuilder.group({
                id: [null],
                hotel: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'hotelPousada', 'pousada', 'clubeCampo', 'parqueTematico')]],
                pousada: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'hotelPousada', 'hotel', 'clubeCampo', 'parqueTematico')]],
                clubeCampo: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'hotelPousada', 'pousada', 'hotel', 'parqueTematico')]],
                parqueTematico: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'hotelPousada', 'pousada', 'clubeCampo', 'hotel')]],
                qtdeQuartos: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required, Validators.maxLength(20)]],
                capacidadeHospedes: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required, Validators.maxLength(20)]],
                grupoGeradorProprio: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                baciaContencaoGerador: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                volumeCombustivel: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required, Validators.maxLength(20)]],
                qtdeSanitarios: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroPopulacaoFixa: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroPopulacaoFlutuante: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]]
            }));
        } else {
            this.form.removeControl('hotelPousada');
        }
    }

    atualizaHospital() {
        if (this.isTipoHospital()) {
            this.form.addControl('hospital', this.formBuilder.group({
                id: [null],
                numeroLeitosEnfermaria: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroLeitosApartamento: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroLeitosUTI: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                prontoAtendimento: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                procedimentoCirurgico: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                examesLaboratoriais: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                qtdeSalasCirurgia: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                qtdeConsultorios: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                qtdeSanitarios: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                realizaExamesImagem: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroPopulacaoFixa: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroPopulacaoFlutuante: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]]
            }));
        } else {
            this.form.removeControl('hospital');
        }
    }

    atualizaShoppingCenter() {
        if (this.isTipoShopping()) {
            this.form.addControl('shoppingCenter', this.formBuilder.group({
                id: [null],
                qtdeLojasAncora: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                qtdeLojasSemiAncora: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                qtdeLojasComuns: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroVagasEstacionamento: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                grupoGeradorProprio: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                baciaContencaoGerador: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                volumeCombustivel: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                qtdeSanitarios: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroPopulacaoFixa: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroPopulacaoFlutuante: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]]
            }));
        } else {
            this.form.removeControl('shoppingCenter');
        }
    }

    atualizaOficina() {
        if (this.isTipoOficina()) {
            this.form.addControl('oficinaManutencao', this.formBuilder.group({
                id: [null],
                mecanica: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'oficinaManutencao', 'industrial')]],
                industrial: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'oficinaManutencao', 'mecanica')]],
                tornearia: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroFuncionarios: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                qtdeSanitarios: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroPopulacaoFixa: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroPopulacaoFlutuante: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]]
            }));
        } else {
            this.form.removeControl('oficinaManutencao');
        }
    }

    public isFormValido() {
        return this.form.valid;
    }

    public async salva() {
        this.form.markAllAsTouched();

        if (this.isFormValido()) {
            let res = ServicoGeral.fromJson(this.form.value);
            res['requerimento'] = this.requerimento.id;
            res.id = this.entidade.id;


            if (this.entidade.id === undefined) {
                this.service.create(res).subscribe(result => {
                    this.entidade = result;
                    this.enviarFormConcluido.emit();
                    this.snackBarService.showSuccess("Formulário salvo com sucesso.")
                    // this.posSalva(result);
                });
            } else {
                this.service.update(res).subscribe(result => {
                    this.entidade = result;
                    this.enviarFormConcluido.emit();
                    this.snackBarService.showSuccess("Formulário salvo com sucesso.")
                    // this.posSalva(result);
                });
            }
        }

    }

}
