import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared.module';
import {MglTimelineEntryComponent} from './timeline-entry/timeline-entry.component';
import {MglTimelineEntryContentComponent} from './timeline-entry-content/timeline-entry-content.component';
import {MglTimelineEntryHeaderComponent} from './timeline-entry-header/timeline-entry-header.component';
import {MglTimelineComponent} from './timeline.component';
import {MglTimelineEntryDotComponent} from './timeline-entry-dot/timeline-entry-dot.component';
import {MglTimelineEntrySideComponent} from './timeline-entry-side/timeline-entry-side.component';

@NgModule({
    declarations: [
        MglTimelineComponent,
        MglTimelineEntryComponent,
        MglTimelineEntryContentComponent,
        MglTimelineEntryHeaderComponent,
        MglTimelineEntryDotComponent,
        MglTimelineEntrySideComponent,
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        MglTimelineComponent,
        MglTimelineEntryComponent,
        MglTimelineEntryContentComponent,
        MglTimelineEntryHeaderComponent,
        MglTimelineEntryDotComponent,
        MglTimelineEntrySideComponent,
    ],
    entryComponents: [
        MglTimelineComponent,
        MglTimelineEntryComponent,
        MglTimelineEntryContentComponent,
        MglTimelineEntryHeaderComponent,
        MglTimelineEntryDotComponent,
        MglTimelineEntrySideComponent,
    ]
})
export class TimelineModule {
}
