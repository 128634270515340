import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class GeracaoPropria extends BaseModel {
    constructor(
        public id: number,
        public idTipoGeracaoPropria: number,
        public mineracao: AtividadeMineracao,
        public consumoGeracaoPropria: number,
    ) {
        super();
    }

    static fromJson(json: any): GeracaoPropria {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeracaoPropria(
            json.id,
            json.idTipoGeracaoPropria,
            json.mineracao,
            json.consumoGeracaoPropria
        );
    }

}

