import {BaseModel} from "./base.model";
import {Requerimento} from '../../pages/requerimentos/requerimento.model';

export class AutorizacaoSupressaoVegetacao extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public intervencaoArea?: boolean,
        public cpfCnpjDetentor?: string,
        public idPessoa?: number,
    ) {
        super();
    }

    static fromJson(json: any): AutorizacaoSupressaoVegetacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new AutorizacaoSupressaoVegetacao(
            json.id,
            Requerimento.fromJson(json.requerimento),
            json.intervencaoArea,
            json.cpfCnpjDetentor,
            json.idPessoa,
        );
    }

    static fromJsons(json: any): AutorizacaoSupressaoVegetacao[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(AutorizacaoSupressaoVegetacao.fromJson(j)));
            return resources;
        }
    }
}