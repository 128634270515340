import { BaseModel } from "app/main/shared/models/base.model";

export class OrganizacaoAdministrativaDTO extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public sigla?: string,
        public codigo?: string,
        public ativo?: boolean,
        public organizacaoSupervisora?: OrganizacaoAdministrativaDTO
    ) {
        super();
    }
    static fromJson(json: any): OrganizacaoAdministrativaDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new OrganizacaoAdministrativaDTO(
            json.id,
            json.nome,
            json.sigla,
            json.codigo,
            json.ativo,
            OrganizacaoAdministrativaDTO.fromJson(json.organizacaoSupervisora),
        );
    }

    static fromJsons(json: any): OrganizacaoAdministrativaDTO[] {
        const pessoas: OrganizacaoAdministrativaDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(pessoa => pessoas.push(this.fromJson(pessoa)));
            return pessoas;
        }
    }
}