import {VazaoDto} from './vazao-dto.model';

export class LancamentoDto {
    constructor(
        public parametro?: number,
        public concentracao?: number,
        public vazaoSolicitada?: VazaoDto
    ) {
    }

    static fromJson(json: any): LancamentoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new LancamentoDto(
            json.parametro,
            json.concentracao,
            json.vazaoSolicitada
        );
    }


    static fromJsons(json: any): LancamentoDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(LancamentoDto.fromJson(j)));
            return resources;
        }
    }
}
