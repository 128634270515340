import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {
    AguaPassivoSupressaoTipoLancamento
} from "../../../../../../../../shared/models/agua-passivo-supressao-tipo-lancamento.model";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
    selector: 'app-agua-passivo-supressao-tipo-lancamento',
    templateUrl: './agua-passivo-supressao-tipo-lancamento.component.html',
    styleUrls: ['./agua-passivo-supressao-tipo-lancamento.component.scss']
})
export class AguaPassivoSupressaoTipoLancamentoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    @Input() requerimento: Requerimento;

    displayedColumns = ['tipo', 'descricao', 'acoes'];

    tipos: DominioDto[];

    formTipoLancamento = this.fb.group({
        id: [null],
        aguaPassivoSupressao: [null],
        idTipoLancamento: [null, [Validators.required]]
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-lancamento-efluente').subscribe(doms => this.tipos = doms);
        
        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formTipoLancamento.disable();
        }
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    tipoAlterado(tipo: any) {
        if (this.getDescricaoTipo(tipo) === 'Outro') {
            if (!this.formTipoLancamento.get('descricao')) {
                this.formTipoLancamento.addControl('descricao', this.fb.control(null, [Validators.required, Validators.maxLength(50)]));
            }
        } else if (!!this.formTipoLancamento.get('descricao')) {
            this.formTipoLancamento.removeControl('descricao');
        }
    }

    adicionar() {
        const tiposLancamento = this.form.get('tiposLancamento');

        tiposLancamento.setValue([
            ...(tiposLancamento.value || []),
            AguaPassivoSupressaoTipoLancamento.fromJson(this.formTipoLancamento.value)
        ]);

        this.formTipoLancamento.reset();
    }

    excluir(item: AguaPassivoSupressaoTipoLancamento) {
        const estruturas: AbstractControl = this.form.get('tiposLancamento');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: AguaPassivoSupressaoTipoLancamento) {
        this.formTipoLancamento.patchValue(item);

        this.excluir(item);
    }

}
