import {BaseModel} from './base.model';
import { SignatarioDTO } from './signatario-dto.model';
import {BaseEnum} from "../enums/base.enum";

export class CertificadoDigitalDTO extends BaseModel {
    constructor(
        public aliasKey?: string,
        public serialNumber?: string,
        public type?: string,
        public version?: string,
        public valid?: boolean,
        public signaturePosition?: number,
        public signatario?: SignatarioDTO,
    ) {
        super();
    }

    static tiposCertificadoDigital: BaseEnum[] = [
        {
            valor: 'TODOS',
            descricao: 'Todos'
        },
        {
            valor: 'A1',
            descricao: 'A1'
        },
        {
            valor: 'A3',
            descricao: 'A3'
        }
    ];

    static fromJson(json: any): CertificadoDigitalDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new CertificadoDigitalDTO(
            json.aliasKey,
            json.serialNumber,
            json.type,
            json.version,
            json.valid,
            json.signaturePosition,
            SignatarioDTO.fromJson(json.signatario));
    }

    static fromJsons(jsonArray: any): CertificadoDigitalDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
