import {Component, Input, OnInit} from '@angular/core';
import {EmpreendimentoMeioIsolacao} from "../../../../../../../../shared/models/empreendimento-meio-isolacao.model";
import {FormControl, FormGroup} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {
    EmpreendimentoMeioIsolacaoService
} from "../../../../../../../../shared/services/empreendimento-meio-isolacao.service";

@Component({
    selector: 'app-meio-isolacao',
    templateUrl: './meio-isolacao.component.html',
    styleUrls: ['./meio-isolacao.component.scss']
})
export class MeioIsolacaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
    private $idEmpreendimento: number;

    meiosIsolacao: FormControl;

    displayedColumnsMeioIsolacao: string[] = ['tipo', 'descricao', 'acoes'];
    tipos: DominioDto[] = [];
    tipoSelecionado: DominioDto;
    descricao: string;

    constructor(private dominiosService: DominiosService,
                private meioIsolacaoService: EmpreendimentoMeioIsolacaoService) {
    }

    async ngOnInit() {
        this.meiosIsolacao = this.form.get('meiosIsolacao') as FormControl;
        this.tipos = await this.dominiosService.getDominio("TIPO_MEIO_ISOLACAO").toPromise();
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    @Input() set idEmpreendimento(idEmpreendimento: number) {
        if (idEmpreendimento !== this.$idEmpreendimento) {
            this.$idEmpreendimento = idEmpreendimento;

            if (this.$idEmpreendimento) {
                this.meioIsolacaoService.getByEmpreendimento(this.$idEmpreendimento)
                    .subscribe(itens => this.meiosIsolacao.setValue(itens));
            }
        }
    }

    excluirMeioIsolacao(index: number) {
        if (index > -1) {
            this.meiosIsolacao.value.splice(index, 1);
            this.meiosIsolacao.setValue([...this.meiosIsolacao.value]);
        }
    }

    addMeioIsolacao() {
        if (this.tipoSelecionado && !this.meiosIsolacao.value.some(f => f.meioIsolacao === this.tipoSelecionado.id && f.descricao === this.descricao)) {
            this.meiosIsolacao.value.push(EmpreendimentoMeioIsolacao.fromJson({
                meioIsolacao: this.tipoSelecionado.id,
                descricao: this.tipoSelecionado.descricao === 'Outro' ? this.descricao : undefined,
            }));

            this.meiosIsolacao.setValue([...this.meiosIsolacao.value]);

            this.tipoSelecionado = undefined;
            this.descricao = undefined;
        }
    }

    verificaBotaoAdicionarDesabilitado() {
        return !this.tipoSelecionado ||
            (this.tipoSelecionado && this.tipoSelecionado.descricao === 'Outro' && !this.descricao);
    }

    getDescricao(id) {
        return this.tipos.find(tp => tp.id === id).descricao;
    }

}
