import {BaseModel} from './base.model';

export class PostoFluviometrico extends BaseModel {

    constructor(
        public id: number,
        public codigo?: string,
        public nome?: string,
        public rio?: string,
    ) {
        super();
    }

    static fromJson(json: any): PostoFluviometrico {
        if (json === undefined || json === null) {
            return null;
        }

        return new PostoFluviometrico(
            json.id ? json.id : null,
            json.codigo ? json.codigo : null,
            json.nome ? json.nome : null,
            json.rio ? json.rio : null
        );
    }

    static fromJsons(json: any): PostoFluviometrico[] {
        const array: PostoFluviometrico[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(PostoFluviometrico.fromJson(item)));
            return array;
        }
    }

    toString(): string {
        return `${this.codigo} - Nome: ${this.nome} - Rio: ${this.rio}`;
    }
}
