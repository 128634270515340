import {Injectable, Injector} from '@angular/core';
import {TipoRequerimento} from './tipo-requerimento.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class TipoRequerimentoListResolver extends ListResolver<TipoRequerimento> {
    constructor(service: TipoRequerimentoService) {
        super(service);
    }
}

@Injectable()
export class TipoRequerimentoResolver extends DetailResolver<TipoRequerimento> {
    constructor(service: TipoRequerimentoService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TipoRequerimentoService extends BaseService<TipoRequerimento> {
    
    constructor(protected injector: Injector) {
        super('/api/tipos-requerimento', injector, TipoRequerimento.prototype, TipoRequerimento.fromJson);
    }


    getAllByTipoAtuacao(tipoAtuacao: string): Observable<TipoRequerimento[]> {
        const url = `${this.urlResource}/tipo-atuacao/${tipoAtuacao}`;
        return this.http.get(url).pipe(
            map(value => this.jsonToResources(value)),
            catchError(this.handleError.bind(this))
        );
    }

    findByTipoProcessoIdAndTipoFluxoAndTipoAutoacao(idTipoProcesso: number, tipoFluxo: any, tipoAtuacao: any): Observable<TipoRequerimento> {
        const url = `${this.urlResource}/by-tipo-processo/${idTipoProcesso}/tipo-fluxo/${tipoFluxo}/tipo-atuacao/${tipoAtuacao}`;
        return this.http.get(url).pipe(
            map(TipoRequerimento.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

}
