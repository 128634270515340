import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {
    checkboxBooleanRequiredDirective
} from "../../../../../../../shared/directives/checkbox-boolean-required.directive";
import {BaseRelacionamentoService} from "../../../../../../../shared/services/base-relacionamento.service";
import {IndustriaFrigorifico} from "../../../../../../../shared/models/industria-frigorifico.model";
import {IndustriaFrigorificoService} from "../../../../../../../shared/services/industria-frigorifico.service";
import {
    MateriaPrimaIndFrigorificoService
} from "../../../../../../../shared/services/materia-prima-ind-frigorifico.service";
import {
    ProdutoProcessadoProprioIndFrigorificoService
} from "../../../../../../../shared/services/produto-processado-proprio-ind-frigorifico.service";
import {
    SubProdutoProcessadoProprioIndFrigorificoService
} from "../../../../../../../shared/services/sub-produto-processado-proprio-ind-frigorifico.service";
import {
    ProdutoProcessadoTerceiroIndFrigorificoService
} from "../../../../../../../shared/services/produto-processado-terceiro-ind-frigorifico.service";
import {
    SubprodutoProcessadoTerceiroIndFrigorificoService
} from "../../../../../../../shared/services/subproduto-processado-terceiro-ind-frigorifico.service";
import {ProdutoProcessadoProprioComponent} from "./produto-processado-proprio/produto-processado-proprio.component";
import {ProdutoProcessadoTerceiroComponent} from "./produto-processado-terceiro/produto-processado-terceiro.component";
import {
    MateriaPrimaIndFrigorificoComponent
} from "./materia-prima-ind-frigorifico/materia-prima-ind-frigorifico.component";
import {SnackBarService, SnackBarType} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-aba-atividade-industria-frigorifico',
    templateUrl: './industria-frigorifico.component.html',
    styleUrls: ['./industria-frigorifico.component.scss']
})
export class IndustriaFrigorificoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit{
    @ViewChild('MP', {static: false}) materiaPrimaComponent: MateriaPrimaIndFrigorificoComponent;
    @ViewChild('PPP', {static: false}) produtoProcessadoProprioComponent: ProdutoProcessadoProprioComponent;
    @ViewChild('PPT', {static: false}) produtoProcessadoTerceiroComponent: ProdutoProcessadoTerceiroComponent;

    form: FormGroup;
    entidade: IndustriaFrigorifico = {} as any;
    consultasIniciaisFinalizadas: boolean = false;
    isNaoPodeEditar: boolean = false;

    constructor(
        protected formBuilder: FormBuilder,
        protected dominiosService: DominiosService,
        protected service: IndustriaFrigorificoService,
        public materiaPrimaIndFrigorificoService: MateriaPrimaIndFrigorificoService,
        public produtoProcessadoProprioIndFrigorificoService: ProdutoProcessadoProprioIndFrigorificoService,
        public subProdutoProcessadoProprioIndFrigorificoService: SubProdutoProcessadoProprioIndFrigorificoService,
        public produtoProcessadoTerceiroIndFrigorificoService: ProdutoProcessadoTerceiroIndFrigorificoService,
        public subprodutoProcessadoTerceiroIndFrigorificoService: SubprodutoProcessadoTerceiroIndFrigorificoService,
        private snackBarService: SnackBarService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
    }

    async ngOnInit() {
        this.consultasIniciaisFinalizadas = false;
        this.form = this.formBuilder.group({});
        this.addControlsForm();
        await this.getDominios();
        await this.getEntidadeByRequerimento();

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
            this.form.disable();
        }
    }

    async carregarIndustriaFrigorifico(idRequerimento){
        let res = await this.service.getByRequerimento(idRequerimento).toPromise();
        this.entidade = res ? res : {} as any;

        if (res) {
            await this.atualizaFormEntidade();
            await this.getSelecionadosSalvos();
        } else {
            this.consultasIniciaisFinalizadas = true;
        }
    }

    protected addControlsForm() {
        this.form.addControl('abateFrigorificoAnimais', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'graxaria', 'curtume')]));
        this.form.addControl('graxaria', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'curtume', 'abateFrigorificoAnimais')]));
        this.form.addControl('curtume', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'abateFrigorificoAnimais', 'graxaria')]));
        this.form.addControl('numeroFuncionarios', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [Validators.required]));

        this.form.get('abateFrigorificoAnimais').valueChanges.subscribe(value => {

            if (value) {
                if (!this.form.get('destinacaoProdutosTerceiros')) {
                    this.form.addControl('destinacaoProdutosTerceiros', this.formBuilder.control({
                        value: null,
                        disabled: this.isModoVisualizacao
                    }, [Validators.required]));
                }
            } else if (!!this.form.get('destinacaoProdutosTerceiros')) {
                this.form.removeControl('destinacaoProdutosTerceiros');
            }

        });
    }

    hasTerceiro() {
        return !!this.form.get('abateFrigorificoAnimais').value &&
            !!this.form.get('destinacaoProdutosTerceiros') &&
            !!this.form.get('destinacaoProdutosTerceiros').value;
    }

    protected async getSelecionadosSalvos(): Promise<void> {
        for (let serv of this.servicesRelacionamentosAtivos) {
            await serv.getSelecionadosSalvos(this.entidade.id);
        }
        this.consultasIniciaisFinalizadas = true;
    }

    protected get todasServicesRelacionamentos(): Array<BaseRelacionamentoService<any>> {
        return [
            this.materiaPrimaIndFrigorificoService,
            this.produtoProcessadoProprioIndFrigorificoService,
            this.subProdutoProcessadoProprioIndFrigorificoService,
            this.produtoProcessadoTerceiroIndFrigorificoService,
            this.subprodutoProcessadoTerceiroIndFrigorificoService
        ];
    }

    protected get servicesRelacionamentosAtivos(): Array<BaseRelacionamentoService<any>> {
        let lista: Array<BaseRelacionamentoService<any>> = [
            this.materiaPrimaIndFrigorificoService,
            this.produtoProcessadoProprioIndFrigorificoService,
            this.subProdutoProcessadoProprioIndFrigorificoService,
        ];

        if (this.hasTerceiro()) {
            lista.push(this.produtoProcessadoTerceiroIndFrigorificoService);
            lista.push(this.subprodutoProcessadoTerceiroIndFrigorificoService);
        }

        return lista;
    }

    limitInput(input: HTMLInputElement, limite: number) {
        if (String(input.value).length > limite) {
            input.value = String(input.value).substring(0, limite).replace(/[^0-9]/g, '');
        } else {
            input.value = input.value.replace(/[^0-9]/g, '');
        }
    }

    protected preSalva() {
        let valid = true;
        if (this.materiaPrimaIndFrigorificoService.info.selecionados.length < 1) {
            this.materiaPrimaComponent.insideForm.form.markAllAsTouched();
            valid = false;
        }

        if (this.produtoProcessadoProprioIndFrigorificoService.info.selecionados.length < 1) {
            this.produtoProcessadoProprioComponent.insideForm.form.markAllAsTouched();
            valid = false;
        }
        if (this.subProdutoProcessadoProprioIndFrigorificoService.info.selecionados.length < 1) {
            this.produtoProcessadoProprioComponent.subprodutoProcessadoProprioComponent.insideForm.form.markAllAsTouched();
            valid = false;
        }

        if (this.hasTerceiro()) {
            if (this.produtoProcessadoTerceiroIndFrigorificoService.info.selecionados.length < 1) {
                this.produtoProcessadoTerceiroComponent.insideForm.form.markAllAsTouched();
                valid = false;
            }
            if (this.subprodutoProcessadoTerceiroIndFrigorificoService.info.selecionados.length < 1) {
                this.produtoProcessadoTerceiroComponent.subprodutoProcessadoTerceiroComponent.insideForm.form.markAllAsTouched();
                valid = false;
            }
        }

        if (!valid) {
            this.snackBarService.show('O formulário de Atividades: possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).', SnackBarType.ERROR);
        }

    }

    protected async getDominios(): Promise<void> {
        for (let serv of this.todasServicesRelacionamentos) {
            await serv.getDominio();
        }
    }

    protected async getEntidadeByRequerimento() {
        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarIndustriaFrigorifico(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarIndustriaFrigorifico(this.requerimento.id)
            }
        }
        if(this.isModoVisualizacao){
            this.form.disable()
        }

    }

    private async atualizaFormEntidade() {
        this.form.patchValue(this.entidade);
    }

    public isFormValido() {
        return this.form.valid;
    }

    public async salva() {
        this.preSalva();

        this.form.markAllAsTouched();

        if (this.isFormValido()) {
            let res = new IndustriaFrigorifico(this.entidade.id, this.form.get('abateFrigorificoAnimais').value, this.form.get('graxaria').value,
                this.form.get('curtume').value, this.form.get('numeroFuncionarios').value,
                this.form.get('abateFrigorificoAnimais').value ? this.form.get('destinacaoProdutosTerceiros').value : null, this.requerimento.id);

            if (this.entidade.id === undefined) {
                this.service.create(res).subscribe(result => {
                    this.entidade = result;
                    this.posSalva();
                }, error => this.snackBarService.showError("Ocorreu um erro no servidor. " + error));
            } else {
                this.service.update(res).subscribe(result => {
                    this.entidade = result;
                    this.posSalva();
                }, error => this.snackBarService.showError("Ocorreu um erro no servidor. " + error));
            }
        }

    }

    protected async posSalva() {
        await this.salvaRelacionamentos();
        this.snackBarService.showSuccess("Formulário salvo com sucesso.")
    }

    protected async salvaRelacionamentos(): Promise<void> {
        for (let serv of this.servicesRelacionamentosAtivos) {
            await serv.createSelecionados(this.entidade.id);
        }
        this.enviarFormConcluido.emit();
    };

    protected getValueFromEntidade(parametro: string) {
        let tipo;
        if (this.form) {
            tipo = this.form.get(parametro).value;
        }

        if (!tipo) {
            if (this.entidade) {
                tipo = this.entidade[parametro]
            }
        }

        return tipo;
    }
}

