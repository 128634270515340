import {BaseModel} from './base.model';

export class Servidor extends BaseModel {
    constructor(
        public id?: number,
        public cpf?: string,
        public nome?: string,
        public matricula?: string,
        public cargo?: string,
        public profissoes?: string,
        public setores?: string,
    ) {
        super();
    }


    static fromJson(json: any): Servidor {
        if (json === undefined || json === null) {
            return null;
        }
        return new Servidor(
            json.id,
            json.cpf,
            json.nome,
            json.matricula,
            json.cargo,
            json.profissoes,
            json.setores)
    }

    static fromJsons(json: any): Servidor[] {
        const servidores: Servidor[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(analista => {
                servidores.push(this.fromJson(analista));
            });
            return servidores;
        }
    }

    _toString(): string | number {
        return (this.nome != null ? this.nome : this.id) + (this.cpf ? ' - ' + this.cpf : '');
    }
}

export class OrganogramaDTO extends BaseModel {
    constructor(
        public id?: number,
        public sigla?: string,
        public nome?: string,
        public dataInicio?: Date,
        public organizacaoAdministrativaPai?: OrganogramaDTO
    ) {
        super();
    }


    static fromJson(json: any): Servidor {
        if (json === undefined || json === null) {
            return null;
        }
        return new Servidor(
            json.id,
            json.sigla,
            json.nome,
            json.dataInicio,
            json.organizacaoAdministrativaPai);
    }

    _toString(): string | number {
        return (this.nome != null ? this.nome : this.id);
    }
}
