import {BaseModel} from 'app/main/shared/models/base.model';
import {ItemTermoReferencia} from 'app/main/pages/itens-termos-referencia/item-termo-referencia.model';

export class ItemTermoReferenciaRequerimento extends BaseModel {
    constructor(
        public id?: number,
        public itemTermoReferencia?: ItemTermoReferencia,
        public isValid?: boolean,
    ) {
        super();
    }

    static fromJson(json: any): ItemTermoReferenciaRequerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new ItemTermoReferenciaRequerimento(
            json.id,
            ItemTermoReferencia.fromJson(json.itemTermoReferencia),
            json.valid || json.isValid
        );
    }

    static fromJsons(json: any[]): ItemTermoReferenciaRequerimento[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(resource =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }

}
