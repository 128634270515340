import {BaseRelacionamentoModel} from "./base-relacionamento.model";

export class ProdutoBiocombustivel extends BaseRelacionamentoModel {
    constructor(
        public id: number,
        public idDominio: number,
        public idEntidade: number,
        public descricao: string,
        public volume: BigInteger,
        public idUnidadeMedida: number,
        public vinculoMateriaPrima: any,
    ) {
        super(id, idDominio, idEntidade, descricao);
    }

    static fromJson(json: any): ProdutoBiocombustivel {
        if (json === undefined || json === null) {
            return null;
        }
        return new ProdutoBiocombustivel(
            json.id,
            json.idDominio,
            json.idEntidade,
            json.descricao,
            json.volume,
            json.idUnidadeMedida,
            json.vinculoMateriaPrima,
        );
    }

}
