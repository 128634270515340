import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SelectItemModule} from '../select-item/select-item.module';
import {SelectManyCheckboxComponent} from './select-many-checkbox.component';
import {MatCheckboxModule} from '@angular/material/checkbox';


@NgModule({
  declarations: [SelectManyCheckboxComponent],
  exports: [SelectManyCheckboxComponent, SelectItemModule],
  imports: [
    CommonModule,
    FormsModule,
    SelectItemModule,
    MatCheckboxModule
  ],
})
export class SelectManyCheckboxModule {

}
