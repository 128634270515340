import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {MedidaControle} from "../../../../../../../../shared/models/medida-controle.model";

@Component({
    selector: 'app-medidas-controle',
    templateUrl: './medidas-controle.component.html',
    styleUrls: ['./medidas-controle.component.scss']
})
export class MedidasControleComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'acoes'];

    tipos: DominioDto[];

    formMedidaControle = this.fb.group({
        id: [null],
        mineracao: [null],
        idTipoMedidaControle: [null, Validators.required,],
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-medida-controle')
            .subscribe(doms => this.tipos = doms);

        this.formMedidaControle.get('idTipoMedidaControle').valueChanges
            .subscribe(value => {
                const isOutro = value &&
                    this.tipos && !!this.tipos.find(d => d.id === value && d.descricao === 'Outro');

                if (isOutro) {
                    if (!this.formMedidaControle.get('descricao')) {
                        this.formMedidaControle.addControl('descricao',
                            this.fb.control(null, [Validators.required, Validators.maxLength(100)]))
                    }
                } else if (!!this.formMedidaControle.get('descricao')) {
                    this.formMedidaControle.removeControl('descricao');
                }

            });

        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formMedidaControle.disable();
        }
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formMedidaControle.valid) return;

        const medidasControle = this.form.get('medidasControle');

        medidasControle.setValue([
            ...(medidasControle.value || []),
            MedidaControle.fromJson(this.formMedidaControle.value)
        ]);

        this.formMedidaControle.reset();
    }

    excluir(item: MedidaControle) {
        const estruturas: AbstractControl = this.form.get('medidasControle');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: MedidaControle) {
        this.formMedidaControle.patchValue(item);

        this.excluir(item);
    }

}
