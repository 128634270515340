import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TransporteResiduosPerigososDto} from "../../../../../../../shared/models/transporte-residuos-perigosos-dto.model";
import {ResiduosTransporteProdutosPerigososService} from "./residuos-transporte-produtos-perigosos.service";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-residuos-transporte-produtos-perigosos',
    templateUrl: './residuos-transporte-produtos-perigosos.component.html',
    styleUrls: ['./residuos-transporte-produtos-perigosos.component.scss']
})
export class ResiduosTransporteProdutosPerigososComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    updateId: boolean = false;
    idForm: number = null;
    formTransporteResiduos: FormGroup;
    rodovias: any[] = [];
    veiculos: any[] = [];
    produtos: any[] = [];
    dadosGerais: any[] = [];
    mostrarProdutosResiduos: boolean = false;
    isNaoPodeEditar: boolean = false;

    constructor(private serviceTransporte: ResiduosTransporteProdutosPerigososService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
                private snackBarService: SnackBarService) {
        super();
    }

    async ngOnInit() {
        if(this.requerimento.id){
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarResiduosTransporteProdutosPerigosos(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarResiduosTransporteProdutosPerigosos(this.requerimento.id)
            }
        }
        this.updateId = true;

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
            this.formTransporteResiduos.disable();
        }
    }

    async carregarResiduosTransporteProdutosPerigosos(idRequerimento){
        await this.serviceTransporte.buscarPorIdRequerimento(idRequerimento).toPromise().then((response) => {
            if(response){
                this.idForm = response.id;
            }
        })
    }

    receberRodovias(rodovias: any[]) {
        this.rodovias = rodovias;
    }

    receberVeiculos(veiculos: any[]) {
        this.veiculos = veiculos;
    }

    receberProdutos(produtos: any[]) {
        this.produtos = produtos;
    }

    receberDadosGerais(dadosGerais: any[]) {
        this.dadosGerais = dadosGerais;
        this.mostrarProdutosResiduos = this.validarDadosGerais();
    }

    validarDadosGerais(): boolean{
        let retorno = false;
        if(this.dadosGerais.length == 0){
            retorno = false;
        }
        this.dadosGerais.forEach( dadosGerais => {
            if((dadosGerais.residuoTransportado == 'Transporte de resíduos não perigosos') ||
                (dadosGerais.residuoTransportado =='Transporte de produtos ou resíduos perigosos')){
                retorno = true;
            }
        })
        return retorno;
    }

    montarTransporteResiduosPerigososDTO(): TransporteResiduosPerigososDto{
        return new TransporteResiduosPerigososDto(
            //TODO id estatico para testar o salvar do formulario
            this.idForm == 0 ? null : this.idForm,
            this.requerimento.id,
            this.dadosGerais,
            this.rodovias,
            this.veiculos,
            this.produtos
        );
    }

    salvar(){
        if(this.validarFormularios()){
            //Limpando produtos caso seja somente transporte de efluentes de limpa fossa
            if(!this.validarDadosGerais()){
                this.produtos = [];
            }
            this.serviceTransporte.salvarFormulario(this.montarTransporteResiduosPerigososDTO()).subscribe( () => {
                this.enviarFormConcluido.emit();
                this.snackBarService.showSuccess("Formulário salvo com sucesso.")
            }, error => {
                this.snackBarService.showError("Ocorreu um erro durante a solicitacao. Cod. Erro: " + error.status);
            })
        }
    }

    validarFormularios(): boolean{
        if(this.dadosGerais.length == 0){
            this.snackBarService.showAlert("O formulário Dados Gerais: " +
                "possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            return false;
        } else if(this.rodovias.length == 0){
            this.snackBarService.showAlert("O formulário Principais rotas utilizadas: " +
                "possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            return false;
        } else if(this.veiculos.length == 0){
            this.snackBarService.showAlert("O formulário Veículos e equipamentos: " +
                "possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            return false;
        }
        return true;
    }
}
