import {BaseEnum} from '../../../../../shared/enums/base.enum';

export class BarragemExistente {

    constructor(
        public id?: number,
        public finalidadeBarragem?: string,
        public anoInicioConstrucao?: number,
        public anoFimConstrucao?: number,
        public areaDrenagem?: number,
        public areaMaximaReservatorio?: number,
        public volumeMaximoAcumulado?: number,
        public usoPreponderante?: string,
        public tipoMaterialConstrutivo?: string,
        public tipoMaterialEspecifique?: string,
        public alturaMaxima?: number,
        public comprimento?: number,
        public cotaCrista?: number,
        public tipoVertedor?: string,
        public cotaSoleiraVertedouro?: number,
        public cotaCristaVertedouro?: number,
        public vazaoMaxima?: number,
        public tempoRetorno?: number,
        public tipoExtravasador?: string,
        public extravasadorVazao?: number,
        public extravasadorPosicao?: string,
        public extravasadorPosEspecifique?: string,
    ) {
    }

    static finalidades: BaseEnum[] = [
        {
            valor: 'PARTICULAR',
            descricao: 'Particular'
        },
        {
            valor: 'PUBLICO',
            descricao: 'Público'
        }
    ];

    static materialConstrutivo: BaseEnum[] = [
        {
            valor: 'TIPO_DE_MATERIAL_BARRAGEM',
            descricao: 'Tipo de material barragem'
        },
        {
            valor: 'ENROCAMENTO',
            descricao: 'Enrocamento'
        },
        {
            valor: 'TERRA',
            descricao: 'Terra'
        },
        {
            valor: 'CONCRETO',
            descricao: 'Concreto'
        },
        {
            valor: 'MISTO',
            descricao: 'Misto'
        },
        {
            valor: 'OUTRO',
            descricao: 'Outro'
        },
    ];

    static tiposVertedouros: BaseEnum[] = [
        {
            valor: 'COM_COMPORTA',
            descricao: 'Com comporta'
        },
        {
            valor: 'SEM_COMPORTA',
            descricao: 'Sem comporta'
        }
    ];

    static posicaoBarragem: BaseEnum[] = [
        {
            valor: 'OMBREIRA_DIREITA',
            descricao: 'Ombreira direita'
        },
        {
            valor: 'OMBREIRA_ESQUERDA',
            descricao: 'Ombreira esquerda'
        },
        {
            valor: 'OUTRO',
            descricao: 'Outro'
        }
    ];

    static fromJson(json: any): BarragemExistente {
        if (json === undefined || json === null) {
            return null;
        }
        return new BarragemExistente(
            json.id ? json.id : null,
            json.finalidadeBarragem ? json.finalidadeBarragem : null,
            json.anoInicioConstrucao ? json.anoInicioConstrucao : null,
            json.anoFimConstrucao ? json.anoFimConstrucao : null,
            json.areaDrenagem ? json.areaDrenagem : null,
            json.areaMaximaReservatorio ? json.areaMaximaReservatorio : null,
            json.volumeMaximoAcumulado ? json.volumeMaximoAcumulado : null,
            json.usoPreponderante ? json.usoPreponderante : null,
            json.tipoMaterialConstrutivo ? json.tipoMaterialConstrutivo : null,
            json.tipoMaterialEspecifique ? json.tipoMaterialEspecifique : null,
            json.alturaMaxima ? json.alturaMaxima : null,
            json.comprimento ? json.comprimento : null,
            json.cotaCrista ? json.cotaCrista : null,
            json.tipoVertedor ? json.tipoVertedor : null,
            json.cotaSoleiraVertedouro ? json.cotaSoleiraVertedouro : null,
            json.cotaCristaVertedouro ? json.cotaCristaVertedouro : null,
            json.vazaoMaxima ? json.vazaoMaxima : null,
            json.tempoRetorno ? json.tempoRetorno : null,
            json.tipoExtravasador ? json.tipoExtravasador : null,
            json.extravasadorVazao ? json.extravasadorVazao : null,
            json.extravasadorPosicao ? json.extravasadorPosicao : null,
            json.extravasadorPosEspecifique ? json.extravasadorPosEspecifique : null,
        );
    }

    static fromJsons(json: any): BarragemExistente[] {
        const lista: BarragemExistente[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}
