import {AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {MatPaginator} from "@angular/material";

export class FormUtil {

    static validatorsSetPercentage = [Validators.required, Validators.max(100), Validators.min(0)]
    static percentageErrorMessages: (control: AbstractControl) => string = (control: AbstractControl) => {
        if (control.hasError('min')) {
            return 'O valor mínimo é de 0'
        }
        if (control.hasError('max')) {
            return 'O valor máximo é de 100'
        }
        return 'Este campo é obrigatório'
    }

    static markAllDirty(control: AbstractControl): void {
        if (control) {
            if (control.hasOwnProperty('controls')) {
                control.markAsDirty({onlySelf: true}); // mark group
                const ctrl = control as any;

                _.forEach(ctrl.controls, c => {
                    this.markAllDirty(c as AbstractControl);
                });
            } else {
                ((control) as FormControl).markAsDirty({onlySelf: true});
            }
        }
    }

    static getAllErrors(ctl: AbstractControl, name: string = 'root'): any {
        let errors = [];

        if (ctl instanceof FormGroup) {
            _.forEach((ctl as FormGroup).controls, (child: AbstractControl, key: string) => {
                errors = _.concat(errors, FormUtil.getAllErrors(child, key));
            });
        } else if (ctl instanceof FormArray) {
            _.forEach((ctl as FormArray).controls, (child: AbstractControl, idx: number) => {
                errors = _.concat(errors, FormUtil.getAllErrors(child, '' + idx));
            });
        } else if (ctl instanceof FormControl) {
            errors = _.concat(errors, [{name: name, errors: ctl.errors}]);
        }

        return errors;
    }

    static valueChangesOf(frm: AbstractControl, originalObj: any, fn: (val: any) => void) {
        const keys = _.keys(originalObj);
        FormUtil.normalizeObservable(frm.valueChanges, originalObj).subscribe(fn);
    }

    static normalizeObservable(obs: Observable<any>, originalObj: any): any {
        const keys = _.keys(originalObj);
        return obs.pipe(map(val => {
            return _.mergeWith(_.clone(originalObj), _.pick(val, keys), (o1, o2) => _.isNil(o2) ? o1 : o2);
        }), distinctUntilChanged(_.isEqual));
    }

    /**
     * Reseta os atributos do paginator sem ativar o event page.
     * @param paginator
     */
    public static resetPaginator(paginator: MatPaginator): void {
        paginator.pageIndex = 0;
        paginator.pageSize = 0;
        paginator.length = 0;
    }

    static clearControlState(control: AbstractControl) {
        control.markAsUntouched();
        control.markAsPristine();
        control.updateValueAndValidity();
    }

    static clearControl(control: AbstractControl) {
        control.clearValidators()
        control.setErrors(null);
        control.setValue(null);
        this.clearControlState(control)
    }

    static parseDMSToDecimal(dms: string): number {
        if (dms) {
            const parts = dms.split(/[^\d.]+/);
            return this.convertDMSToDD(parseFloat(parts[0]), parseFloat(parts[1]), parseFloat(parts[2]));
        }

        return null;
    }

    static convertDMSToDD(degrees: number, minutes: number, seconds: number): number {
        return (degrees + minutes / 60 + seconds / (60 * 60)) * -1;
    }

    static convertDEGToDMS(deg, lat): string {
        let direction;
        const absolute = Math.abs(deg);

        const degrees = Math.floor(absolute);
        const minutesNotTruncated = (absolute - degrees) * 60;
        const minutes = Math.floor(minutesNotTruncated);
        const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(4);

        if (lat) {
            direction = deg >= 0 ? "N" : "S";
        } else {
            direction = deg >= 0 ? "E" : "W";
        }

        return degrees + "°" + minutes + "'" + seconds + "\"" + direction;
    }

    static copy(obj: object): object {
        return Object.assign(Object.create(obj), obj)
    }

    /**
     * Base on change
     * @param condicaoParaAtivar - Condições para ativar o 'field'
     * @param field - Field á set manipulado em função do valor do Select
     * @param validations - Validações á serem ativadas quando o 'field' for ativado
     */
    static baseOnChange(condicaoParaAtivar: boolean, field: AbstractControl, validations: ValidatorFn | ValidatorFn[] = Validators.required): void {
        if (condicaoParaAtivar) {
            field.enable();
            field.setValidators(validations);
        } else {
            field.setValidators(null);
            field.setValue(null);
            field.markAsUntouched();
            field.disable();
        }
        field.updateValueAndValidity();
    }

    isCpf(cpf_cnpj: string): boolean {
        return cpf_cnpj == null ? true : cpf_cnpj.length < 12 ? true : false;
    }

    static isCpfValido(cpf: string): boolean {
        const cpfLength = 11;
        const weights = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        return this.isValido(cpf, cpfLength, weights);
    }

    static isValido(digits: string, correctDigitsLength: number, weights: number[]): boolean {
        const cleanDigits = this.obterApenaNumeros(digits);
        if (cleanDigits.length !== correctDigitsLength || this.isMesmoDigitos(cleanDigits)) {
            return false;
        }
        const digitsWithoutChecker = cleanDigits.substring(0, correctDigitsLength - 2);
        const digitsChecker = cleanDigits.substring(correctDigitsLength - 2, correctDigitsLength);
        const calculetedChecker = this.calcular(digitsWithoutChecker, weights);
        return digitsChecker === calculetedChecker;
    }

    static obterApenaNumeros(digits: string): string {
        return digits.replace(/\D/g, '');
    }

    static isMesmoDigitos(digits: string): boolean {
        return !digits.split('').some((digit) => digit !== digits[0]);
    }

    static calcular(digits: string, weights: number[]): string {
        const digitsLength = digits.length;
        const digitsLengthWithoutChecker = weights.length - 1;

        const sum = digits.split('').reduce((acc, digit, idx) => {
            return acc + +digit * weights[digitsLength - 1 - idx];
        }, 0);
        const sumDivisionRemainder = sum % 11;
        const checker = sumDivisionRemainder < 2 ? 0 : 11 - sumDivisionRemainder;

        if (digitsLength === digitsLengthWithoutChecker) {
            return this.calcular(`${digits}${checker}`, weights);
        }

        return `${digits[digitsLength - 1]}${checker}`;
    }

    static isCnpjValido(cnpj: string): boolean {
        const cpfLength = 14;
        const weights = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6];
        return this.isValido(cnpj, cpfLength, weights);
    }

    static removeCaracteresEspeciaisRetornaNumero(value: string): number {
        if (value) {
            const result = value.replace(/[^\w\s]/gi, '');
            return Number(result);
        }

        return null;
    }
}