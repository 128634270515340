import {Component, Inject, OnInit} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatOptionSelectionChange,
    MatRadioChange,
    MatTableDataSource
} from '@angular/material';
import {ParecerTecnicoCondicionantesService} from '../parecer-tecnico-condicionantes.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ParecerTecnicoCondicionante} from '../parecer-tecnico-condicionantes.model';
import {TipoCondicionanteService} from '../../../tipos-condicionante/tipo-condicionante.service';
import {TipoCondicionante} from '../../../tipos-condicionante/tipo-condicionante.model';
import {ParecerTecnico} from '../../../parecer-tecnico/parecer-tecnico.model';
import {CompostoQuimicoService} from '../../../compostos-quimicos/composto-quimico.service';
import {CompostoQuimico} from '../../../compostos-quimicos/composto-quimico.model';
import {TipoFormulario} from '../../tipo-formulario.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import * as moment from 'moment';
import {SubstanciaQuimica} from 'app/main/pages/substancia-quimica/substancia-quimica.model';
import {SubstanciaQuimicaService} from 'app/main/pages/substancia-quimica/substancia-quimica.service';
import {UnidadeQuimica} from 'app/main/pages/unidades-quimicas/unidade-quimica.model';
import {UnidadeQuimicaService} from 'app/main/pages/unidades-quimicas/unidade-quimica.service';
import {FormularioQualidadeAgua} from 'app/main/pages/formulario-qualidade-agua/formulario-qualidade-agua.model';
import {FormularioQualidadeAguaService} from 'app/main/pages/formulario-qualidade-agua/formulario-qualidade-agua.service';
import {CondicionanteTemplate} from 'app/main/pages/condicionantes-template/condicionante-template.model';
import {CondicionanteTemplateService} from 'app/main/pages/condicionantes-template/condicionante-template.service';
import {ParametroCondicionante} from '../../../../shared/models/parametro-condicionante.model';
import {ParametroCondicionanteService} from './service/parametro-condicionante.service';
import {UnidadeAguaService} from './service/unidade-agua.service';
import {EnumTipoFormulario} from '../../../../shared/enums/EnumTipoFormulario';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-parecer-tecnico-condicionante-dialog',
    templateUrl: './parecer-tecnico-condicionante-dialog.component.html',
    styleUrls: ['./parecer-tecnico-condicionante-dialog.component.scss']
})
export class ParecerTecnicoCondicionanteDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ParecerTecnicoCondicionanteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any,
        private parecerTecnicoCondicionanteService: ParecerTecnicoCondicionantesService,
        private tipoCondicionanteService: TipoCondicionanteService,
        private compostoQuimicoService: CompostoQuimicoService,
        private substanciaQuimicaService: SubstanciaQuimicaService,
        private unidadeQuimicaService: UnidadeQuimicaService,
        private formularioQualidadeAguaService: FormularioQualidadeAguaService,
        private condicionanteTemplateService: CondicionanteTemplateService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private parametroCondicionanteService: ParametroCondicionanteService,
        private unidadeAguaService: UnidadeAguaService
    ) {
    }

    minDate: Date = new Date();

    form: FormGroup;
    valorTipoFormulario = '';
    tiposCondicionante: TipoCondicionante[];
    compostosQuimicos: CompostoQuimico[];
    substanciasQuimicas: SubstanciaQuimica[];
    unidadesQuimicas: UnidadeQuimica[];
    formulariosQualidadeAgua: FormularioQualidadeAgua[] = [];
    prazos: BaseEnum[] = ParecerTecnicoCondicionante.prazoEntregaParecerTecnicoCondicionante;
    tiposResposta: BaseEnum[];
    tiposPeriodo: BaseEnum[] = ParecerTecnicoCondicionante.periodoParecerTecnicoCondicionante;
    tiposArquivo: BaseEnum[] = ParecerTecnicoCondicionante.arquivoParecerTecnicoCondicionante;
    tiposFormulario: BaseEnum[] = TipoFormulario.TiposFormulario;
    condicionantesTemplate: CondicionanteTemplate[];
    showVencimento = false;
    showPeriodo = false;
    isTipoRespostaArquivo = false;
    isTipoRespostaFormulario = false;
    isEdit = false;
    parecerTecnico: ParecerTecnico;
    parecerTecnicoCondicionante: ParecerTecnicoCondicionante;
    numeroLoadedEdit = false;
    condicionantesGrouped: any;
    displayedColumnsFormularioAgua: string[] = ['id', 'compostoQuimico', 'substanciaQuimica', 'unidadeQuimica', 'cas', 'acao'];
    displayedColumnsFormularioSolo: string[] = ['id', 'compostoQuimico', 'substanciaQuimica', 'cas', 'acao'];
    displayedColumnsFormularioAguaSuperficial: string[] = ['id', 'parametroCondicionante', 'unidadeAgua', 'valorIntervencao', 'observacao', 'acao'];
    dataSourceFormularioAgua: MatTableDataSource<FormularioQualidadeAgua>;
    dataSourceFormularioSolo: MatTableDataSource<FormularioQualidadeAgua>;
    dataSourceFormularioAguaSuperficial: MatTableDataSource<FormularioQualidadeAgua>;
    parametrosCondicionantes: ParametroCondicionante[];
    terminouRender = false;
    comparador = (a: TipoCondicionante, b: TipoCondicionante) => a && b ? a.id === b.id : a === b;
    comparadorTipoFormulario = (a: BaseEnum, b: BaseEnum) => a && b ? a.valor === b.valor : a === b;


    ngOnInit(): void {
        this.tiposResposta = ParecerTecnicoCondicionante.respostaParecerTecnicoCondicionante.filter(t => t.valor !== '4');
        this.form = this.formBuilder.group({
            numero: [null],
            descricao: [null],
            periodo: [null],
            tipoCondicionante: [null],
            tipoPrazo: [null],
            tipoResposta: null,
            tipoPeriodo: [null],
            tipoArquivo: null,
            dataVencimento: [null],
            tipoFormulario: [null],
            substanciaQuimica: null,
            unidadeQuimica: [null],
            compostoQuimico: null,
            casCondicionante: [null],
            parametroCondicionante: [null],
            unidadeAgua: [null],
            unidadeAguaDescricao: [null],
            observacao: [null],
            valorIntervencao: [null],
            nivelEstaticoEstiagem: [null],
            dataMedicaoEstiagem: [null],
            nivelEstaticoChuva: [null],
            dataMedicaoChuva: [null],

        });

        // Validar se é uma edição ou um novo cadastro de condicionante parecer tecnico
        this.isEdit = this.data['parecerTecnicoCondicionante'] ? true : false;
        if (this.isEdit) { // Edit
            this.parecerTecnicoCondicionante = this.data['parecerTecnicoCondicionante'];
            this.parecerTecnico = this.parecerTecnicoCondicionante.parecerTecnico;
            this.setFormValues();
        } else { // Create
            this.parecerTecnico = this.data['parecer'];
        }

        // Recuperar os TiposCondicionantes agrupado com itens para apresentar no numero a soma do total de itens + 1
        this.condicionantesGrouped = this.data['tiposCondicionante'];

        this.listarTiposCondicionante();
        this.listarCondicionantesTemplate();
        this.minDate.setHours(24); // Setando uma data minima para selecionar nos inputs de data.
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    private listarCondicionantesTemplate(): void {
        this.condicionanteTemplateService.getAll().subscribe(templates => {
            this.condicionantesTemplate = templates;
        });
    }

    private listarTiposCondicionante(): void {
        this.tiposCondicionante = [];
        this.tipoCondicionanteService
            .getAll()
            .subscribe((tiposCondicionante) => {
                if (tiposCondicionante) {
                    this.tiposCondicionante = tiposCondicionante;
                }
            });
    }

    private setFormValues(): void {
        this.form.controls.descricao.setValue(this.parecerTecnicoCondicionante.descricao);
        this.form.controls.tipoCondicionante.setValue(this.parecerTecnicoCondicionante.tipoCondicionante);
        this.form.controls.numero.setValue(this.parecerTecnicoCondicionante.numero);
        const prazo: BaseEnum = ParecerTecnicoCondicionante.prazoEntregaParecerTecnicoCondicionante
            .find(e => e.descricao === this.parecerTecnicoCondicionante.tipoPrazo.descricao);
        this.form.controls.tipoPrazo.setValue(prazo);
        this.onChangePrazo(null, prazo);

        if (prazo.valor === '2') { // Vencimento
            this.form.controls.dataVencimento.setValue(this.parecerTecnicoCondicionante.dataVencimento);
        } else if (prazo.valor === '1') { // Periodo
            this.form.controls.dataVencimento.setValue(this.parecerTecnicoCondicionante.dataVencimento);
            this.form.controls.periodo.setValue(this.parecerTecnicoCondicionante.periodo);
            const periodo: BaseEnum = ParecerTecnicoCondicionante.periodoParecerTecnicoCondicionante
                .find(e => e.descricao === this.parecerTecnicoCondicionante.tipoPeriodo.descricao);
            this.form.controls.tipoPeriodo.setValue(periodo);
        }

        const resposta: BaseEnum = ParecerTecnicoCondicionante.respostaParecerTecnicoCondicionante
            .find(e => e.descricao === this.parecerTecnicoCondicionante.tipoResposta.descricao);
        this.form.controls.tipoResposta.patchValue(resposta);

        if (resposta.valor === '1') { // Arquivo
            const arquivo: BaseEnum = ParecerTecnicoCondicionante.arquivoParecerTecnicoCondicionante
                .find(e => e.descricao === this.parecerTecnicoCondicionante.tipoArquivo.descricao);
            this.form.controls.tipoArquivo.setValue(arquivo);
        }

        if (resposta.valor === '2') { // Formulário

            const tipoFormulario: BaseEnum = TipoFormulario.TiposFormulario
                .find(e => e.valor === this.getTipoFormularioOrdinal(this.parecerTecnicoCondicionante.tipoFormulario));

            this.form.controls.tipoFormulario.patchValue(tipoFormulario);
            this.recebeTipoFormulario(tipoFormulario);

            this.formularioQualidadeAguaService.getItemsByCondicionante(
                this.parecerTecnicoCondicionante.parecerTecnico.id,
                this.parecerTecnicoCondicionante.id
            ).subscribe(items => {
                this.formulariosQualidadeAgua.push(...items);
                const qualidadeAguaSub = this.formulariosQualidadeAgua.filter(e => e.tipoFormulario === EnumTipoFormulario.QUALIDADE_AGUA_SUBTERRANEA);
                const qualidadeAguaSolo = this.formulariosQualidadeAgua.filter(e => e.tipoFormulario === EnumTipoFormulario.QUALIDADE_SOLO);
                const qualidadeAguaSuper = this.formulariosQualidadeAgua.filter(e => e.tipoFormulario === EnumTipoFormulario.QUALIDADE_AGUA_SUPERFICIAL);
                this.dataSourceFormularioAgua = new MatTableDataSource(qualidadeAguaSub);
                this.dataSourceFormularioSolo = new MatTableDataSource(qualidadeAguaSolo);
                this.dataSourceFormularioAguaSuperficial = new MatTableDataSource(qualidadeAguaSuper);
            });
        }
    }

    private getTipoFormularioOrdinal(tipoFormulario: string): string {
        switch (tipoFormulario) {
            case 'QUALIDADE_AGUA_SUBTERRANEA':
                return '1';
            case 'QUALIDADE_SOLO':
                return '2';
            case 'QUALIDADE_AGUA_SUPERFICIAL':
                return '3';
            case 'CONTROLE_NIVEL_ESTATICO':
                return '4';
        }
    }

    private listarCompostosQuimicos(): void {
        this.compostosQuimicos = [];
        this.compostoQuimicoService
            .getAll()
            .subscribe((compostosQuimicos) => {
                if (compostosQuimicos) {
                    this.compostosQuimicos = compostosQuimicos;
                }
            });
    }

    private listarSubstanciasQuimicas(compostoId: number): void {
        this.substanciasQuimicas = [];
        this.substanciaQuimicaService
            .getSubstanciasQuimicasByComposto(compostoId)
            .subscribe((substanciasQuimicas) => {
                if (substanciasQuimicas) {
                    this.substanciasQuimicas = substanciasQuimicas;
                }
            });
    }

    private listarUnidadesQuimicas(): void {
        this.unidadesQuimicas = [];
        this.unidadeQuimicaService
            .getAll()
            .subscribe((unidadesQuimicas) => {
                if (unidadesQuimicas) {
                    this.unidadesQuimicas = unidadesQuimicas;
                }
            });
    }

    private listarParametrosCondicionantes(): void {
        this.parametrosCondicionantes = [];
        this.parametroCondicionanteService
            .getAllParamCond()
            .subscribe((parametroCondicionante) => {
                if (parametroCondicionante) {
                    this.parametrosCondicionantes = parametroCondicionante;
                }
            });
    }

    private setarUltimoIndiceTipoCondicionante(tipoCondicionante: TipoCondicionante): void {
        let numero = 0;

        this.condicionantesGrouped.forEach(condicionante => {
            if (condicionante.key.toLowerCase() === tipoCondicionante.descricao.toLowerCase()) {
                numero = condicionante.value.length + 1;
            }
        });

        if (numero === 0) {
            numero = 1;
        }

        this.form.controls.numero.setValue(numero);
    }

    onChangeTemplate(event: MatOptionSelectionChange, condicionante: CondicionanteTemplate): void {
        if (event && event.isUserInput) {
            this.form.controls.descricao.setValue(condicionante.descricao);

            // Se não houver nenhum tipo Condicionante selecionado, seleciona do próprio template.
            if (!this.form.controls.tipoCondicionante.value || this.form.controls.tipoCondicionante.value === null) {
                const tipoCondicionante: TipoCondicionante = this.tiposCondicionante
                    .find(tc => {
                        return tc.descricao === condicionante.tipoCondicionante.descricao;
                    });
                this.form.controls.tipoCondicionante.setValue(tipoCondicionante);
            }
        }
    }

    onChangePrazo(event: MatRadioChange, prazo: BaseEnum): void {
        // TODO: Implementar compare do BaseEnum prazoEntregaParecerTecnicoCondicionante
        if (prazo.valor === '1' || prazo.valor === '2') {
            if (prazo.valor === '2') {
                this.showVencimento = true;
                this.showPeriodo = false;
            } else if (prazo.valor === '1') {
                this.showVencimento = true;
                this.showPeriodo = true;
            }
        } else {
            this.showVencimento = false;
            this.showPeriodo = false;
        }
        // Para não zerar os valores na construção do form
        if (event) {
            this.form.controls.dataVencimento.patchValue([]);
            this.form.controls.tipoPeriodo.patchValue([]);
            this.form.controls.periodo.patchValue([]);

            this.form.controls.tipoResposta.patchValue([]);
            this.onChangeResposta(null, null);
        }
    }

    onChangeResposta(event: MatOptionSelectionChange, resposta: BaseEnum): void {
        // TODO: Implementar compare do BaseEnum respostaEntregaParecerTecnicoCondicionante

        // isUserInput é importante pois o evento é disparado duas vezes pela interface
        if ((event && event.isUserInput) || resposta) {

            // Quando vem da tela, o objeto resposta vem null e é preenchido pelo event.source.value.
            if (!resposta) {
                resposta = event.source.value;
            }
            if (resposta.valor === '1') { // Tipo Arquivo
                this.isTipoRespostaArquivo = true;
            } else {
                this.isTipoRespostaArquivo = false;
            }
            if (resposta.valor === '2') { // Tipo Formulário
                this.isTipoRespostaFormulario = true;
                this.listarCompostosQuimicos();
                this.listarUnidadesQuimicas();
                this.listarParametrosCondicionantes();
            } else {
                this.isTipoRespostaFormulario = false;
            }
            if (!this.terminouRender) {
                this.terminouRender = true;
            } else {
                this.form.patchValue({
                    tipoArquivo: null,
                    substanciaQuimica: null,
                    unidadeQuimica: [null],
                    compostoQuimico: null,
                    casCondicionante: [null],
                    parametroCondicionante: [null],
                    unidadeAgua: [null],
                    unidadeAguaDescricao: [null],
                    observacao: [null],
                    valorIntervencao: [null]
                });
            }
        } else if (!event && !resposta) {
            this.isTipoRespostaFormulario = false;
            this.isTipoRespostaArquivo = false;
            this.form.patchValue({
                tipoResposta: null,
                tipoArquivo: null,
                tipoFormulario: [null],
                substanciaQuimica: null,
                unidadeQuimica: [null],
                compostoQuimico: null,
                casCondicionante: [null],
                parametroCondicionante: [null],
                unidadeAgua: [null],
                unidadeAguaDescricao: [null],
                observacao: [null],
                valorIntervencao: [null]
            });

        }
    }

    onChangeCompostoQuimico(event: MatOptionSelectionChange, compostoQuimico: CompostoQuimico): void {
        if (event.isUserInput) {
            this.listarSubstanciasQuimicas(compostoQuimico.id);
            this.form.patchValue({casCondicionante: ''});
        }
    }

    onChangeTipoCondicionante(event: MatOptionSelectionChange, tipoCondicionante: TipoCondicionante): void {
        if (event.isUserInput) {
            // Ao editar, não pode atualizar o número na primeira vez.
            // TODO: Validar se o Tipo Condicionante selecionado é igual ao do objeto editado. Se sim, manter o valor do objeto editado.
            if (this.isEdit && !this.numeroLoadedEdit) {
                this.numeroLoadedEdit = true;
            } else {
                this.setarUltimoIndiceTipoCondicionante(tipoCondicionante);
            }
        }
    }

    onConfirmFormulario(): void {
        if (this.valorTipoFormulario === 'Qualidade água subterrânea') {
            if (!this.form.controls.compostoQuimico.value || !this.form.controls.substanciaQuimica.value) {
                this.snackBarService.showAlert('Preencha os campos para adicionar');
                return;
            } else {
                const f = new FormularioQualidadeAgua(null,
                    this.form.controls.substanciaQuimica.value,
                    null,
                    EnumTipoFormulario.QUALIDADE_AGUA_SUBTERRANEA,
                    null);
                this.formulariosQualidadeAgua.push(f);
                const qualidadeAguaSub = this.formulariosQualidadeAgua.filter(e => e.tipoFormulario === EnumTipoFormulario.QUALIDADE_AGUA_SUBTERRANEA);
                this.dataSourceFormularioAgua = new MatTableDataSource(qualidadeAguaSub);
                this.dataSourceFormularioAgua._updateChangeSubscription();
            }
        }

        if (this.valorTipoFormulario === 'Qualidade solo') {
            if (!this.form.controls.compostoQuimico.value || !this.form.controls.substanciaQuimica.value) {
                this.snackBarService.showAlert('Preencha os campos para adicionar');
                return;
            } else {
                const f = new FormularioQualidadeAgua(null,
                    this.form.controls.substanciaQuimica.value,
                    null,
                    EnumTipoFormulario.QUALIDADE_SOLO,
                    null);
                this.formulariosQualidadeAgua.push(f);
                const qualidadeAguaSolo = this.formulariosQualidadeAgua.filter(e => e.tipoFormulario === EnumTipoFormulario.QUALIDADE_SOLO);
                this.dataSourceFormularioSolo = new MatTableDataSource(qualidadeAguaSolo);
                this.dataSourceFormularioSolo._updateChangeSubscription();
            }
        }

        if (this.valorTipoFormulario === 'Qualidade água superficial') {
            if (!this.form.controls.parametroCondicionante.value) {
                this.snackBarService.showAlert('Preencha os campos para adicionar');
                return;
            } else {
                const f = new FormularioQualidadeAgua(null,
                    null,
                    this.form.controls.unidadeAgua.value,
                    EnumTipoFormulario.QUALIDADE_AGUA_SUPERFICIAL,
                    null);
                this.formulariosQualidadeAgua.push(f);
                const qualidadeAguaSuper = this.formulariosQualidadeAgua.filter(e => e.tipoFormulario === EnumTipoFormulario.QUALIDADE_AGUA_SUPERFICIAL);
                this.dataSourceFormularioAguaSuperficial = new MatTableDataSource(qualidadeAguaSuper);
                this.dataSourceFormularioAguaSuperficial._updateChangeSubscription();
            }
        }


        this.form.patchValue({unidadeAgua: null});
        this.form.patchValue({unidadeAguaDescricao: ''});
        this.form.patchValue({observacao: ''});
        this.form.patchValue({valorIntervencao: ''});
        this.form.patchValue({substanciaQuimica: null});
        this.form.patchValue({unidadeQuimica: null});
        this.form.patchValue({compostoQuimico: null});
        this.form.patchValue({casCondicionante: null});
        this.form.patchValue({parametroCondicionante: null});
    }

    onConfirm(): void {
        const now = moment();
        this.form.markAllAsTouched();
        // refatorar submit
        if (this.form.controls.tipoPrazo.value.valor !== '0') {
            if (this.form.controls.tipoResposta.value === null) {
                this.snackBarService.showAlert('Preencha o tipo de Resposta');
                return;
            }
            if (this.form.controls.tipoResposta.value.valor === '1' && this.form.controls.tipoArquivo.value === null) {
                this.snackBarService.showAlert('Preencha o tipo de arquivo');
                return;
            }
        }
        if (!this.form.controls.tipoPrazo.value) {
            this.snackBarService.showAlert('Preencher prazo de entrega');
            return;
        }
        if (this.form.controls.tipoPrazo.value.valor === '1'
            && (this.form.controls.dataVencimento.invalid || this.form.controls.tipoPeriodo.invalid || this.form.controls.periodo.invalid)) {
            this.snackBarService.showAlert('Preencha os dados De Período');
            return;
        }
        if (this.form.controls.tipoPrazo.value.valor === '2'
            && (this.form.controls.dataVencimento.invalid)) {
            this.snackBarService.showAlert('Preencha a data de vencimento');
            return;
        }

        // Se resposta igual arquivo
        const tipoArquivo: BaseEnum = this.showVencimento && this.form.controls.tipoResposta.value.valor === '1' ? this.form.controls.tipoArquivo.value : null;
        // Se resposta igual formulário
        const tipoFormulario: string = this.showVencimento && this.form.controls.tipoResposta.value.valor === '2' ? this.getTipoFormularioEnum() : null;
        // Se formulário qualidade de água, recuperar itens
        let formularioItems = null;
        if (tipoFormulario) {
            formularioItems = FormularioQualidadeAguaService.getFormulariosAguaItems(this.formulariosQualidadeAgua);
        }

        const parecerCondicioante = new ParecerTecnicoCondicionante(
            (this.parecerTecnicoCondicionante && this.parecerTecnicoCondicionante.id) ? this.parecerTecnicoCondicionante.id : null, // ID
            null, // Data Criacao
            null, // Data Exclusao
            this.form.controls.dataVencimento.value ? this.form.controls.dataVencimento.value : null, // Data Vencimento
            this.form.controls.descricao.value, // Descricao
            this.form.controls.numero.value, // Numero
            this.form.controls.periodo.value, // Periodo
            0, // Situacao Criado
            tipoArquivo, // Tipo Arquivo
            tipoFormulario, // Tipo Formulario
            this.form.controls.tipoPeriodo.value ? this.form.controls.tipoPeriodo.value : null, // Tipo Periodo
            this.form.controls.tipoPrazo.value, // Tipo Prazo
            this.showVencimento ? this.form.controls.tipoResposta.value : {valor: 4, descricao: 'Não se Aplica'}, // Tipo Resposta
            this.parecerTecnico, // Parecer Tecnico
            this.form.controls.tipoCondicionante.value // Tipo Condicionante
        );

        this.parecerTecnicoCondicionanteService
            .addOrUpdateCondicionante(parecerCondicioante, formularioItems)
            .subscribe(
                parecerCondicionante => {
                    this.snackBarService.showSuccess('Solicitação processada com sucesso!');
                    this.dialogRef.close(parecerCondicionante);
                }, e => {
                    this.snackBarService.showError(e.error.errors[0] === '' ? 'Erro ao salvar o registro.' : e.error.errors[0]);
                }
            );
    }

    private getTipoFormularioEnum(): string {
        const tipoFormulario: BaseEnum = this.form.controls.tipoFormulario.value;

        switch (tipoFormulario.valor) {
            case '1':
                return EnumTipoFormulario.QUALIDADE_AGUA_SUBTERRANEA;
            case '2':
                return EnumTipoFormulario.QUALIDADE_SOLO;
            case '3':
                return EnumTipoFormulario.QUALIDADE_AGUA_SUPERFICIAL;
            case '4':
                return EnumTipoFormulario.CONTROLE_NIVEL_ESTATICO;
        }
    }

    delete(qualidadeAgua: FormularioQualidadeAgua, index): void {

        if (qualidadeAgua.id) {
            this.formularioQualidadeAguaService.deleteFormularioAgua(qualidadeAgua.id).subscribe(() => {
                if (qualidadeAgua.tipoFormulario === EnumTipoFormulario.QUALIDADE_AGUA_SUBTERRANEA) {
                    this.dataSourceFormularioAgua.data.splice(index, 1);
                    this.formulariosQualidadeAgua.splice(this.formulariosQualidadeAgua.indexOf(qualidadeAgua), 1);
                    this.dataSourceFormularioAgua._updateChangeSubscription();
                } else if (qualidadeAgua.tipoFormulario === EnumTipoFormulario.QUALIDADE_SOLO) {
                    this.dataSourceFormularioSolo.data.splice(index, 1);
                    this.formulariosQualidadeAgua.splice(this.formulariosQualidadeAgua.indexOf(qualidadeAgua), 1);
                    this.dataSourceFormularioSolo._updateChangeSubscription();
                } else if (qualidadeAgua.tipoFormulario === EnumTipoFormulario.QUALIDADE_AGUA_SUPERFICIAL) {
                    this.dataSourceFormularioAguaSuperficial.data.splice(index, 1);
                    this.formulariosQualidadeAgua.splice(this.formulariosQualidadeAgua.indexOf(qualidadeAgua), 1);
                    this.dataSourceFormularioAguaSuperficial._updateChangeSubscription();
                }
                this.snackBarService.showSuccess('Informação removida com Sucesso !');
            }, (e) => this.snackBarService.showError('Erro ao remover Informação !', e));

        } else {
            if (qualidadeAgua.tipoFormulario === EnumTipoFormulario.QUALIDADE_AGUA_SUBTERRANEA) {
                this.dataSourceFormularioAgua.data.splice(index, 1);
                this.formulariosQualidadeAgua.splice(this.formulariosQualidadeAgua.indexOf(qualidadeAgua), 1);
                this.dataSourceFormularioAgua._updateChangeSubscription();
            } else if (qualidadeAgua.tipoFormulario === EnumTipoFormulario.QUALIDADE_SOLO) {
                this.dataSourceFormularioSolo.data.splice(index, 1);
                this.formulariosQualidadeAgua.splice(this.formulariosQualidadeAgua.indexOf(qualidadeAgua), 1);
                this.dataSourceFormularioSolo._updateChangeSubscription();
            } else if (qualidadeAgua.tipoFormulario === EnumTipoFormulario.QUALIDADE_AGUA_SUPERFICIAL) {
                this.dataSourceFormularioAguaSuperficial.data.splice(index, 1);
                this.formulariosQualidadeAgua.splice(this.formulariosQualidadeAgua.indexOf(qualidadeAgua), 1);
                this.dataSourceFormularioAguaSuperficial._updateChangeSubscription();
            }
        }
    }

    recebeTipoFormulario(tipoFormulario: BaseEnum) {
        this.valorTipoFormulario = tipoFormulario.descricao;
    }

    carregarSubstanciaQumica(substanciaQuimica: SubstanciaQuimica) {
        this.form.patchValue({casCondicionante: substanciaQuimica.casRegistryNumber});
        this.form.patchValue({unidadeQuimica: substanciaQuimica.unidadeQuimica.descricao});

    }

    async carregarUnidadePorParametroCond(parametroCondicionanteId: number) {

        const unidade = await this.unidadeAguaService.getByParametroId(parametroCondicionanteId).toPromise();

        this.form.patchValue({unidadeAgua: unidade});
        this.form.patchValue({unidadeAguaDescricao: unidade.descricao});
        this.form.patchValue({valorIntervencao: unidade.valorIntervencao});
        this.form.patchValue({observacao: unidade.observacao});
    }
}
