import {Injectable, Injector} from '@angular/core';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import { CaracteristicaEletricaSistema } from '../models/caracteristica-eletrica-sistema.model';
import { BaseEeLinhaTransmissao } from './base-ee-linha-transmissao.service';


@Injectable()
export class CaracteristicaEletricaSistemaResolver extends DetailResolver<CaracteristicaEletricaSistema> {
    constructor(
        service: CaracteristicaEletricaSistemaService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class CaracteristicaEletricaSistemaService extends BaseEeLinhaTransmissao<CaracteristicaEletricaSistema> {

    constructor(protected injector: Injector) {
        super(
            "/api/carac-eletrica-sistema",
            injector,
            CaracteristicaEletricaSistema.prototype,
            CaracteristicaEletricaSistema.fromJson
        );
    }

    getByLinhaTransmissao(idLinhaTransmissao: number): Observable<CaracteristicaEletricaSistema> {
        const url = `${this.urlResource}/ee-linha-transmissao/${idLinhaTransmissao}`;

        return this.http
            .get(url)
            .pipe(
                map(e => this.jsonToResourceFn(e)),
                catchError(this.handleError.bind(this))
            );
    }
}
