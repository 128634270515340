import {NgModule} from '@angular/core';
import {MatFormFieldModule, MatRadioModule, MatSelectModule, MatTableModule} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {NgxLoadingModule} from 'ngx-loading';
import {FinalidadeAbastecimentoPublicoComponent} from './finalidade-abastecimento-publico/finalidade-abastecimento-publico.component';
import {FinalidadeDessedentacaoComponent} from './finalidade-dessedentacao/finalidade-dessedentacao.component';
import {FinalidadeEsgotamentoSanitarioComponent} from './finalidade-esgotamento-sanitario/finalidade-esgotamento-sanitario.component';
import {FinalidadeIndustriaComponent} from './finalidade-industria/finalidade-industria.component';
import {FinalidadeMineracaoComponent} from './finalidade-mineracao/finalidade-mineracao.component';
import {FinalidadeTanqueEscavadoComponent} from './finalidade-tanque-escavado/finalidade-tanque-escavado.component';
import {FinalidadeTanqueRedeComponent} from './finalidade-tanque-rede/finalidade-tanque-rede.component';
import {FinalidadesOutrasComponent} from './finalidades-outras/finalidades-outras.component';
import {ObjetivoCaptacaoSuperficialComponent} from './objetivo-captacao-superficial/objetivo-captacao-superficial.component';
import {ObjetivoLancamentosEfluentesComponent} from './objetivo-lancamentos-efluentes/objetivo-lancamentos-efluentes.component';
import {ObjetivoObrasHidraulicasComponent} from './objetivo-obras-hidraulicas/objetivo-obras-hidraulicas.component';
import {RequerimentoAtividadeOutorgaComponent} from './requerimento-atividade-outorga.component';
import {ObjetivoTamponamentoPocoTubularComponent} from './objetivo-tamponamento-poco-tubular/objetivo-tamponamento-poco-tubular.component';
import {TitulacaoCaptacaoInsignificanteComponent} from './titulacao-captacao-insignificante/titulacao-captacao-insignificante.component';
import {ObjetivoPerfuracaoPocoTubularComponent} from './objetivo-perfuracao-poco-tubular/objetivo-perfuracao-poco-tubular.component';
import {ObjetivoObraBarragemComponent} from './objetivo-obra-barragem/objetivo-obra-barragem.component';
import {DialogObraPostoFluviometricoComponent} from './objetivo-obra-barragem/dialog-obra-posto-fluviometrico/dialog-obra-posto-fluviometrico.component';
import {ObjetivoAguaSubterraneaComponent} from './objetivo-agua-subterranea/objetivo-agua-subterranea.component';
import {ObjetivoBarragemExistenteComponent} from './objetivo-barragem-existente/objetivo-barragem-existente.component';
import {ObjetivoAguaSubterraneaIrrigacaoComponent} from './objetivo-agua-subterranea-irrigacao/objetivo-agua-subterranea-irrigacao.component';
import {ObjetivoPocoIrrigacaoComponent} from './objetivo-poco-irrigacao/objetivo-poco-irrigacao.component';
import {DecimalPipe} from '@angular/common';
import {FinalidadeGeracaoEnergiaComponent} from './finalidade-geracao-energia/finalidade-geracao-energia.component';
import {AdicionarIrrigacaoComponent} from './finalidade-irrigacao/adicionar-irrigacao.component';
import {AdicionarIrrigacaoCulturaComponent} from './finalidade-irrigacao/adicionar-irrigacao-cultura/adicionar-irrigacao-cultura.component';
import {ObjetivoDrdhComponent} from './objetivo-drdh/objetivo-drdh.component';
import {DialogAdicionarDadoHidrometeorologicoComponent} from './objetivo-drdh/dialog-adicionar-dado-hidrometeorologico/dialog-adicionar-dado-hidrometeorologico.component';
import {ListaIrrigacaoComponent} from './finalidade-irrigacao/lista-irrigacao/lista-irrigacao.component';

@NgModule({
    declarations: [
        RequerimentoAtividadeOutorgaComponent,
        FinalidadeAbastecimentoPublicoComponent,
        FinalidadeEsgotamentoSanitarioComponent,
        FinalidadeIndustriaComponent,
        FinalidadeDessedentacaoComponent,
        FinalidadeMineracaoComponent,
        FinalidadeTanqueEscavadoComponent,
        FinalidadeTanqueRedeComponent,
        FinalidadesOutrasComponent,
        FinalidadeGeracaoEnergiaComponent,
        AdicionarIrrigacaoComponent,
        AdicionarIrrigacaoCulturaComponent,

        ObjetivoCaptacaoSuperficialComponent,
        ObjetivoLancamentosEfluentesComponent,
        ObjetivoObrasHidraulicasComponent,
        ObjetivoPerfuracaoPocoTubularComponent,
        ObjetivoTamponamentoPocoTubularComponent,
        TitulacaoCaptacaoInsignificanteComponent,
        ObjetivoObraBarragemComponent,
        DialogObraPostoFluviometricoComponent,
        ObjetivoAguaSubterraneaComponent,
        ObjetivoAguaSubterraneaIrrigacaoComponent,
        ObjetivoBarragemExistenteComponent,
        ObjetivoPocoIrrigacaoComponent,
        ObjetivoDrdhComponent,
        DialogAdicionarDadoHidrometeorologicoComponent,
        ListaIrrigacaoComponent,
    ],

    imports: [
        CoreModule,
        SharedModule,
        MatFormFieldModule,
        MatSelectModule,
        MatRadioModule,
        MatTableModule,
        NgxLoadingModule,
        MatDatepickerModule,
        MatTooltipModule,
    ],
    exports: [
        RequerimentoAtividadeOutorgaComponent,
        FinalidadeAbastecimentoPublicoComponent,
        FinalidadeEsgotamentoSanitarioComponent,
        FinalidadeIndustriaComponent,
        FinalidadeDessedentacaoComponent,
        FinalidadeMineracaoComponent,
        FinalidadeTanqueEscavadoComponent,
        FinalidadeTanqueRedeComponent,
        FinalidadesOutrasComponent,
        AdicionarIrrigacaoComponent,

        AdicionarIrrigacaoCulturaComponent,

        ObjetivoCaptacaoSuperficialComponent,
        ObjetivoLancamentosEfluentesComponent,
        ObjetivoObrasHidraulicasComponent,
        ObjetivoPerfuracaoPocoTubularComponent,
        ObjetivoTamponamentoPocoTubularComponent,
        TitulacaoCaptacaoInsignificanteComponent,
        ObjetivoDrdhComponent
    ],
    entryComponents: [
        RequerimentoAtividadeOutorgaComponent,
        FinalidadeAbastecimentoPublicoComponent,
        FinalidadeEsgotamentoSanitarioComponent,
        FinalidadeIndustriaComponent,
        FinalidadeDessedentacaoComponent,
        FinalidadeMineracaoComponent,
        FinalidadeTanqueEscavadoComponent,
        FinalidadeTanqueRedeComponent,
        FinalidadesOutrasComponent,
        AdicionarIrrigacaoComponent,

        AdicionarIrrigacaoCulturaComponent,
        FinalidadeGeracaoEnergiaComponent,

        ObjetivoCaptacaoSuperficialComponent,
        ObjetivoLancamentosEfluentesComponent,
        ObjetivoObrasHidraulicasComponent,
        ObjetivoPerfuracaoPocoTubularComponent,
        ObjetivoTamponamentoPocoTubularComponent,
        ObjetivoObraBarragemComponent,
        TitulacaoCaptacaoInsignificanteComponent,
        DialogObraPostoFluviometricoComponent,
        ObjetivoAguaSubterraneaComponent,
        ObjetivoAguaSubterraneaIrrigacaoComponent,
        ObjetivoBarragemExistenteComponent,
        ObjetivoPocoIrrigacaoComponent,
        ObjetivoPocoIrrigacaoComponent,
        ObjetivoDrdhComponent,
        DialogAdicionarDadoHidrometeorologicoComponent,
        ListaIrrigacaoComponent
    ],
    providers: [DecimalPipe],

})
export class RequerimentoAtividadeOutorgaModule {
}
