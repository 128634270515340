import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface ExcludeData {
    id: any;
    label: string;
    title?: string;
    message?: string;
}

@Component({
    selector: 'app-confirmar-exclusao-dialog',
    templateUrl: './confirmar-exclusao-dialog.component.html'
})
export class ConfirmarExclusaoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarExclusaoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ExcludeData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
