import {RequerimentoFinalidadeAtividade} from './../models/requerimento-finalidade-atividade.model';
import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {RequerimentoOutorgaFinalidadeAtividadeDTO} from '../models/requerimento-finalidade-atividade-dto.model';
import {RequerimentoFinalidadeAtividadeDTO} from "../models/req-finalidade-atividade-dto.model";

@Injectable({
    providedIn: 'root'
})
export class RequerimentoFinalidadeAtividadeService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/requerimento-finalidades-atividades';
    }

    getAllByRequerimento(requerimento: Requerimento): Observable<RequerimentoFinalidadeAtividade[]> {
        const url = `${this.urlResource}/requerimento/${requerimento.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceList),
            catchError(this.handleError)
        );
    }

    getOutorgaDTOByRequerimento(requerimento: Requerimento): Observable<RequerimentoOutorgaFinalidadeAtividadeDTO[]> {
        const url = `${this.urlResource}/requerimento/${requerimento.id}/outorga-dto`;
        return this.http.get(url).pipe(
            map(jsonArray => RequerimentoOutorgaFinalidadeAtividadeDTO.fromJsons(jsonArray)),
            catchError(this.handleError)
        );
    }

    create(requerimentoFinalidadeAtividade: RequerimentoFinalidadeAtividadeDTO): Observable<RequerimentoFinalidadeAtividade> {
        return this.http
            .post(this.urlResource, requerimentoFinalidadeAtividade)
            .pipe(
                map(this.jsonDataToResource),
                catchError(this.handleError.bind(this))
            );
    }

    delete(requerimentoFinalidadeAtividade: RequerimentoFinalidadeAtividade): Observable<any> {
        const url = `${this.urlResource}/${requerimentoFinalidadeAtividade.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonDataToResourceList(jsonData: any[]): RequerimentoFinalidadeAtividade[] {
        const entries: RequerimentoFinalidadeAtividade[] = [];
        if (jsonData !== undefined && jsonData !== null) {
            jsonData.forEach(element => {
                const rt = Object.assign(new RequerimentoFinalidadeAtividade(), element);
                entries.push(rt);
            });
        }
        return entries;
    }

    private jsonDataToResource(jsonData: any): RequerimentoFinalidadeAtividade {
        return Object.assign(new RequerimentoFinalidadeAtividade(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
