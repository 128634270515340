import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
  selector: 'app-oficina-manutencao',
  templateUrl: './oficina-manutencao.component.html',
  styleUrls: ['./oficina-manutencao.component.scss']
})
export class OficinaManutencaoComponent implements OnInit{
    
    @Input() form: FormGroup;
    @Input() requerimento: Requerimento;
    @Input() isNaoPodeEditar: boolean;


    ngOnInit(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }
}
