import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {GeometriaDto} from '../../models/geoportal-base-referencia/geometria-dto.model';
import {environment} from '../../../../../environments/environment';
import { Ponto } from '../../models/hidrico-models/ponto.model';
import {formatGeodesicArea} from "../../util/area";

export interface AreaGeometria {
    id?: number
    wkt: string
    areaM2?: number
    areaHa?: number
    areaKm2?: number
}

@Injectable({
    providedIn: 'root'
})
export class GeoportalBaseReferenciaService {

    private http: HttpClient;
    urlGeoPortalBaseReferencia: string = environment.URL_GEOPORTAL_BASE_REFERENCIA;

    constructor(private injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    listarAreasRestritas(ponto: Ponto): Observable<GeometriaDto[]> {
        const url = this.urlGeoPortalBaseReferencia + `/api/interseccao-camadas/areas-restritas/${ponto.latitude}/${ponto.longitude}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonDataToList),
                catchError(this.handleError.bind(this))
            );
    }

    listarProvinciasHidrologicas(ponto: Ponto): Observable<GeometriaDto[]> {
        const url = this.urlGeoPortalBaseReferencia + `/api/interseccao-camadas/provincias-hidrologicas/${ponto.latitude}/${ponto.longitude}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonDataToList),
                catchError(this.handleError.bind(this))
            );
    }

    carregarDadosGeoserver(urlGeoserver: string): Observable<any>{
        const url = this.urlGeoPortalBaseReferencia + `/api/camadas/getrows`;
        const urlParams: string[] = urlGeoserver.split('&CQL_FILTER=');
        const params = {
            params: {
                url: urlParams[0],
                cql_filter: unescape('&CQL_FILTER=' + urlParams[1]),
            }
        }
        return this.http
            .get(url, params)
            .pipe(catchError(this.handleError.bind(this)));
    }
    getArea(wkt: any): Observable<string> {
        return this.http.post<AreaGeometria>(`${this.urlGeoPortalBaseReferencia}/api/geometria/area-geodesica`,{wkt: wkt}).pipe(
            map(area => formatGeodesicArea(area.areaM2)),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonDataToList(jsonData: any[]): GeometriaDto[] {
        const entries: GeometriaDto[] = [];

        jsonData.forEach(element => {
            const rt = Object.assign(new GeometriaDto(), element);
            entries.push(rt);
        });
        return entries;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}

