import { Injectable, Injector } from '@angular/core';
import { ParecerRequerimentoConclusao } from 'app/main/pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.model';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ParecerRequerimentoConclusaoUpdateTemplateDTO } from './parecer-req-conclusao-update-template-dto.mode';

@Injectable({
  providedIn: 'root'
})
export class ParecerRequerimentoConclusaoService extends BaseService<ParecerRequerimentoConclusao> {
    
    constructor(protected injector: Injector) {
        super(
            '/api/parecer-requerimento-conclusao',
            injector,
            ParecerRequerimentoConclusao.prototype,
            ParecerRequerimentoConclusao.fromJson
        );
    }

    getByEmissaoTituloId(emissaoTituloId: number): Observable<ParecerRequerimentoConclusao> {
        const url = `${this.urlResource}/find-by/${emissaoTituloId}/emissao-titulo`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateTemplateParecerRequerimentoConclusao(parecerConclusaoDTO: ParecerRequerimentoConclusaoUpdateTemplateDTO): Observable<any> {
        const url = `${this.urlResource}/${parecerConclusaoDTO.idParecerRequeriemntoConclusao}/update-template-emissao`;
        return this.http.put(url, parecerConclusaoDTO).
        pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
    
}
