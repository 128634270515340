import {OrgaoAmbiental} from '../../../shared/models/orgao-ambiental.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from '../requerimento.model';
import {DocumentoRequerimento} from "../../documentos-requerimentos/documento-requerimento.model";

export class RequerimentoOutorga extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public possuiRequerimentoEmAndamento?: boolean,
        public orgaoAmbientalRequerimentoEmAndamento?: OrgaoAmbiental,
        public protocoloRequerimentoEmAndamento?: string,
        public declaracaoNaoAbastecimento?: DocumentoRequerimento
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoOutorga {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoOutorga(
            json.id,
            Requerimento.fromJson(json.requerimento),
            json.possuiRequerimentoEmAndamento,
            json.orgaoAmbientalRequerimentoEmAndamento,
            json.protocoloRequerimentoEmAndamento,
            DocumentoRequerimento.fromJson(json.declaracaoNaoAbastecimento)
        );
    }
}
