import {BaseModel} from 'app/main/shared/models/base.model';

export class Hospital extends BaseModel {
    constructor(
        public id?: number,
        public numeroLeitosEnfermaria?: number,
        public numeroLeitosApartamento?: number,
        public numeroLeitosUTI?: number,
        public prontoAtendimento?: boolean,
        public procedimentoCirurgico?: boolean,
        public examesLaboratoriais?: boolean,
        public qtdeSalasCirurgia?: number,
        public qtdeConsultorios?: number,
        public qtdeSanitarios?: number,
        public realizaExamesImagem?: boolean,
        public numeroPopulacaoFixa?: number,
        public numeroPopulacaoFlutuante?: number
    ) {
        super();
    }

    static fromJson(json: any): Hospital {
        if (json === undefined || json === null) {
            return null;
        }
        return new Hospital(
            json.id,
            json.numeroLeitosEnfermaria,
            json.numeroLeitosApartamento,
            json.numeroLeitosUTI,
            json.prontoAtendimento,
            json.procedimentoCirurgico,
            json.examesLaboratoriais,
            json.qtdeSalasCirurgia,
            json.qtdeConsultorios,
            json.qtdeSanitarios,
            json.realizaExamesImagem,
            json.numeroPopulacaoFixa,
            json.numeroPopulacaoFlutuante,
        );
    }

}
