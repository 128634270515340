import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import { catchError } from 'rxjs/operators';
import { RequerimentoTitulacao } from './requerimento-titulacao.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RequerimentoTitulacaoService extends BaseService<RequerimentoTitulacao> {
    constructor(protected injector: Injector) {
        super(
            '/api/requerimento-titulacoes',
            injector,
            RequerimentoTitulacao.prototype,
            RequerimentoTitulacao.fromJson
            );
    }
        

    deleteById(idRequerimentoTitulacao: number): Observable<any> {
        return this.http.delete(`${this.urlResource}/${idRequerimentoTitulacao}`)
         .pipe(catchError(this.handleError.bind(this)));
    }
    
}
