import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {AnaliseRequerimentoEquipe} from '../model/analise-requerimento-equipe.model';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AnaliseRequerimentoAnalista} from '../model/analise-requerimento-analista.model';
import {HttpParams} from '@angular/common/http';
import {Despacho} from '../../../../shared/components/despacho-cabecalho/despacho.model';
import {AtribuicaoAceitarRecusarDto} from '../../analista-aceitar-atribuicao/atribuicao-aceitar-recusar-dto.model';
import {AnalistaTarefasDTO} from '../model/dto/analista-tarefas-dto.model';
import {AnaliseRequerimento} from '../model/analise-requerimento.model';
import {AnaliseRequerimentoComAnalista} from '../../requerimento-atribuir/dialogs/detalhes-analista-dialog/analise-requerimento-com-analista.model';

@Injectable({
    providedIn: 'root',
})
export class AnaliseRequerimentoService extends BaseService<AnaliseRequerimentoEquipe> {
    constructor(protected injector: Injector) {
        super(
            '/api/analises-requerimentos',
            injector,
            AnaliseRequerimentoEquipe.prototype,
            AnaliseRequerimentoEquipe.fromJson
        );
    }

    readByRequerimentoId(requerimentoId: number): Observable<AnaliseRequerimento> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(AnaliseRequerimento.fromJson.bind(this)),
            catchError(this.handleError)
        );
    }

    readEquipeByRequerimentoId(requerimentoId: number): Observable<AnaliseRequerimentoEquipe> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}/equipe`;
        return this.http.get(url).pipe(
            map(AnaliseRequerimentoEquipe.fromJson.bind(this)),
            catchError(this.handleError)
        );
    }

    getAnalistasPorSetorDoUsuarioLogado(): Observable<AnalistaTarefasDTO[]> {
        return this.http.get(`${this.urlResource}/analistas-por-setor`).pipe(
            map(response => AnalistaTarefasDTO.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    buscaTodasAnalisesPorAnalista(cpf: string): Observable<AnaliseRequerimentoComAnalista[]> {
        return this.http.get(`${this.urlResource}/analista/${cpf}`).pipe(
            map(data => AnaliseRequerimentoComAnalista.fromJsons(data)), catchError(this.handleError)
        );
    }

    buscarAnalistaAtribuicao(requerimentoId: number): Observable<AnaliseRequerimentoAnalista> {
        return this.http.get(`${this.urlResource}/requerimento/${requerimentoId}/analista/`)
            .pipe(map(response => AnaliseRequerimentoAnalista.fromJson(response)),
                catchError(this.handleError));
    }

    /**
     * Cria e finaliza Atribuição
     * @param {number} idRequerimento - ID do requerimento.
     * @param {number} idTarefa - ID da Tarefa.
     * @param analiseRequerimento
     */
    createEfinalizar(idRequerimento: number, idTarefa: string, analiseRequerimento: FormData): Observable<string> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/tarefa/${idTarefa}`;
        return this.http.post(url, analiseRequerimento).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    /**
     * Atualiza e finaliza Atribuição
     * @param {number} idRequerimento - ID do requerimento.
     * @param {number} idTarefa - ID da Tarefa.
     * @param analiseRequerimento
     */
    updateEfinalizar(idRequerimento: number, idTarefa: string, analiseRequerimento: FormData): Observable<string> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/tarefa/${idTarefa}`;
        return this.http.put(url, analiseRequerimento).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    /**
     * Criar Analise requerimento para continuar depois
     * @param resource
     * @param requerimentoId
     */
    createSalvarEContinuarDepois(resource: FormData, requerimentoId: number): Observable<AnaliseRequerimentoEquipe> {
        return this.http.post(`${this.urlResource}/requerimento/${requerimentoId}`, resource).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Atualiza Analise requerimento para continuar depois
     * @param resource
     * @param requerimentoId
     */
    updateSalvarEContinuarDepois(resource: FormData, requerimentoId: number): Observable<AnaliseRequerimentoEquipe> {
        return this.http.put(`${this.urlResource}/requerimento/${requerimentoId}`, resource).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Aceita ou recusa atribuição do analista á análise do requerimento.
     * @param requerimentoId {number} - ID do requerimento.
     * @param tarefaId {string} - ID da tarefa Camunda.
     * @param aceito {boolean} - Booleano que indica se foi aceito a atribuição.
     * @param aceitarRecusarDto {AtribuicaoAceitarRecusarDto} - Justificativa da recusa.
     */
    aceitarRecusarAtribuicao(requerimentoId: number, tarefaId: string,
                             aceito: boolean, aceitarRecusarDto: AtribuicaoAceitarRecusarDto): Observable<any> {
        return this.http.post(`${this.urlResource}/atribuicao/${requerimentoId}/${tarefaId}/${aceito}`,
            aceitarRecusarDto).pipe(map(Despacho.fromJson.bind(this)), catchError(this.handleError.bind(this)));
    }

    buscarUltimaEquipe(requerimentoId: number): Observable<AnaliseRequerimentoEquipe> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}/ultima-equipe`;
        return this.http.get(url).pipe(
            map(AnaliseRequerimentoEquipe.fromJson.bind(this)),
            catchError(this.handleError)
        );
    }

    criarEFinalizarAtribuirAOutroSetor(idRequerimento: number, idTarefa: string, analiseRequerimento: FormData): Observable<string> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/tarefa/${idTarefa}/criar-atribuir-outro-setor`;
        return this.http.post(url, analiseRequerimento).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    criarAtribuirAOutroSetorEContinuarDepois(analiseRequerimento: FormData, idRequerimento: number): Observable<AnaliseRequerimentoEquipe> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/salvar-atribuir-outro-setor`;
        return this.http.post(url, analiseRequerimento).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    atualizarEFinalizarAtribuirAOutroSetor(idRequerimento: number, idTarefa: string, analiseRequerimento: FormData): Observable<string> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/tarefa/${idTarefa}/salvar-atribuir-outro-setor`;
        return this.http.put(url, analiseRequerimento).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    atualizarAtribuirAOutroSetorEContinuarDepois(analiseRequerimento: FormData, idRequerimento: number): Observable<AnaliseRequerimentoEquipe> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/salvar-atribuir-outro-setor`;
        return this.http.put(url, analiseRequerimento).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}
