import { Pipe, PipeTransform } from '@angular/core';
import { toStringHDMS } from 'ol/coordinate';

@Pipe({
    name: 'coordenadasMask'
})
export class CoordenadasFormatPipe implements PipeTransform {

    latitudeFormat: string = '';

    constructor() {
    }

    transform(value: any): any {

       const latitude = [value, 0];

       value != null ? value = toStringHDMS(latitude, 4) : '';

       return  this.latitudeFormat = value.substring(10);
    }

}
