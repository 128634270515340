import { BaseModel } from "app/main/shared/models/base.model";

export class RedistribuicaoProcessoDTO extends BaseModel {

    constructor(
        public idsRequerimento: number[] = []
    ) {
        super();
    }

    static fromJson(json: any): RedistribuicaoProcessoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new RedistribuicaoProcessoDTO(
            json.idsRequerimento
        );
    }
}