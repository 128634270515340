import {Component, Input, OnInit} from '@angular/core';
import {EmpreendimentoCemiterioCrematorioService} from "app/main/shared/services/empreendimento-cemiterio-crematorio.service";
import {EmpreendimentoCemiterioCrematorio} from "app/main/shared/models/empreendimento-cemiterio-crematorio.model";
import {checkboxBooleanRequiredDirective} from "app/main/shared/directives/checkbox-boolean-required.directive";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CrematorioMonitoramentoDTO} from "app/main/shared/models/crematorio-monitoramento-dto.model";
import {DominioDto} from "app/main/shared/models/dominio-dto.model";
import {CrematorioMonitoramentoService} from "app/main/shared/services/crematorio-monitoramento.service";
import {DominiosService} from "app/main/shared/services/dominios.service";
import {CemiterioService} from "app/main/shared/services/cemiterio.service";
import {CrematorioService} from 'app/main/shared/services/crematorio.service';
import {SnackBarService, SnackBarType} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-residuos-cemiterio-crematorio',
    templateUrl: './residuos-cemiterio-crematorio.component.html',
    styleUrls: ['./residuos-cemiterio-crematorio.component.scss']
})
export class ResiduosCemiterioCrematorioComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    form: FormGroup = new FormGroup({});
    private empreendimento: EmpreendimentoCemiterioCrematorio;
    hasCemiterio: boolean;
    hasCrematorio: boolean;
    isNaoPodeEditar: boolean = false;

    tipoAtividade: string = "cemiterio"; // cemiterio | crematorio

    tiposMonitoramentoSelecionados: Array<{ dominio: DominioDto, vinculo: CrematorioMonitoramentoDTO }>;
    tiposMonitoramento: Array<DominioDto> = [];
    tiposExcluidos: Array<number> = [];

    constructor(
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private empreendimentoCemiterioCrematorioService: EmpreendimentoCemiterioCrematorioService,
        private cemiterioService: CemiterioService,
        private crematorioService: CrematorioService,
        private crematorioMonitoramentoService: CrematorioMonitoramentoService,
        private dominiosService: DominiosService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
    }


    async ngOnInit() {
        await this.getListaTiposMonitoramento();

        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarResiduosCemiterioCrematorio(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarResiduosCemiterioCrematorio(this.requerimento.id)
            }

        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.form.disable();
        }

    }

    async carregarResiduosCemiterioCrematorio(idRequerimento){
        let res = await this.empreendimentoCemiterioCrematorioService.getByRequerimento(idRequerimento).toPromise();
        this.empreendimento = res ? res : {} as any;

        if (res) {
            this.hasCemiterio = res.cemiterio ? true : false;
            this.hasCrematorio = res.crematorio ? true : false;

            this.changeAtividade(this.hasCrematorio ? 'crematorio' : 'cemiterio');
            // this.selecionouCemiterio(this.hasCemiterio);
            // this.selecionouCrematorio(this.hasCrematorio);
            this.form.patchValue(this.empreendimento);
            await this.getTiposMonitoramentoCrematorio();
        } else {
            this.tiposMonitoramentoSelecionados = [];
            this.changeAtividade('cemiterio');
        }
    }



    async salva() {
        this.form.markAllAsTouched();

        if (this.form.valid) {
            let res = EmpreendimentoCemiterioCrematorio.fromJson(this.form.value);
            res.requerimento = this.requerimento.id;
             res.id = this.empreendimento.id;
             if (this.hasCemiterio) {
                 res.cemiterio.id = this.empreendimento.cemiterio ? this.empreendimento.cemiterio.id : null;
             }
             if (this.hasCrematorio) {
                 res.crematorio.id = this.empreendimento.crematorio ? this.empreendimento.crematorio.id : null;
             }
            if (this.empreendimento.id === undefined) {
                this.empreendimentoCemiterioCrematorioService.create(res).subscribe(
                    resource => {
                        this.createTiposMonitoramento(resource)
                        this.enviarFormConcluido.emit();
                    }
                );
            } else {
                this.empreendimentoCemiterioCrematorioService.update(res).subscribe(
                    resource => {
                        this.createTiposMonitoramento(resource)
                        this.enviarFormConcluido.emit();
                    }
                );
            }
        } else {
            this.snackBarService.show('O formulário de Atividades: possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).', SnackBarType.ERROR);
        }

    }

    private async getListaTiposMonitoramento() {
        this.tiposMonitoramento = await this.dominiosService.getDominioByUrl('tipos-monitoramento-continuo').toPromise();
    }

    private async getTiposMonitoramentoCrematorio() {
        if (this.hasCrematorio) {
            let trms = await this.crematorioMonitoramentoService.getByIdCrematorio(this.empreendimento.crematorio.id).toPromise();
            this.tiposMonitoramentoSelecionados = trms.map(trm => {
                return {
                    dominio: this.tiposMonitoramento.find(o => o.id === trm.idTipoMonitoramentoContinuo),
                    vinculo: trm
                }
            });

            if (!trms || trms.length < 1) {
                this.tiposMonitoramentoSelecionados = [];
            }
        }
    }

    private async deleteTiposMonitoramento() {
        for (let id of this.tiposExcluidos) {
            await this.crematorioMonitoramentoService.delete(id).toPromise();
        }
    }

    private async createTiposMonitoramento(resource: EmpreendimentoCemiterioCrematorio) {
        if (this.hasCrematorio) {
            await this.deleteTiposMonitoramento();
            for (let trmS of this.tiposMonitoramentoSelecionados) {
                let t = trmS.vinculo;
                t.idCrematorio = resource.crematorio.id;
                if (t.id === undefined) {
                    await this.crematorioMonitoramentoService.create(t).toPromise();
                }
            }
        }
    }

    changeAtividade(tipo: string) {
        switch(tipo) {
            case 'cemiterio': this.selecionouCemiterio(true); break;
            case 'crematorio': this.selecionouCrematorio(true); break;
            default: this.snackBarService.showAlert(`Tipo atividade selecionada inválida [${tipo}]`);
        }
    }

    selecionouCemiterio(checked: boolean) {
        this.hasCrematorio = !checked;
        this.hasCemiterio = checked;
        if (checked) {
            this.form.removeControl('crematorio');
            this.form.addControl('cemiterio', this.formBuilder.group({
                horizontal: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'cemiterio', 'vertical')]],
                vertical: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'cemiterio', 'horizontal')]],
                numeroSepultamentosMes: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroJazidosUnidade: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                grauVulnerabilidadeAquifero: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                distanciaSuperior: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                jusante: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'cemiterio', 'montante')]],
                montante: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'cemiterio', 'jusante')]],
                permeabilidadeSuperior10: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]]
            }));
        } else {
            this.form.removeControl('cemiterio');
        }
    }

    selecionouCrematorio(checked: boolean) {
        this.hasCrematorio = checked;
        this.hasCemiterio = !this.hasCrematorio;
        if (checked) {
            this.form.removeControl('cemiterio');
            this.form.addControl('crematorio', this.formBuilder.group({
                humano: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'crematorio', 'animal')]],
                animal: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'crematorio', 'humano')]],
                equipamentoRefrigeracao: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                areaDesmembramentoCarcaca: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }],
                sistemaLavagem: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                // formaAlimentacaoCombustivel: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                tempoCremacao: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                tipoCombustivel: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                descricaoTipoCombustivel: [{value: null, disabled: this.isModoVisualizacao}],
                tipoEquipamentoTratamentoTermico: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                capacidadeNominal: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                numeroCarregamentos: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                camaraPosCombustao: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                monitoramentoConinuo: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]]
            }));
        } else {
            this.form.removeControl('crematorio');
        }
    }

}