import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {BaseModel} from 'app/main/shared/models/base.model';
import {ParecerTecnico} from '../parecer-tecnico.model';
import {Servidor} from '../../../shared/models/servidor.model';

export class ParecerRequerimentoAvaliacao extends BaseModel {
    constructor(
        public id?: number,
        public parecerTecnico?: ParecerTecnico,
        public servidor?: Servidor,
        public avaliacao?: string,
        public comentario?: string,
        public rascunhoDespacho?: string,
        public tarefaFinalizada?: boolean,
    ) {
        super();
    }

    static avaliacao: BaseEnum[] = [
        {valor: 'SIM', descricao: 'Deferido'},
        {valor: 'NAO_SUGIRO_AJUSTES_PARECER_TECNICO', descricao: 'Não, sugiro que sejam realizados ajustes no parecer técnico'},
        {valor: 'NAO_SUGIRO_REANALISE', descricao: 'Não, sugiro reanálise'}
    ];

    static fromJson(json: any): ParecerRequerimentoAvaliacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParecerRequerimentoAvaliacao(
            json.id,
            ParecerTecnico.fromJson(json.parecerTecnico),
            json.servidor,
            json.avaliacao,
            json.comentario,
            json.rascunhoDespacho,
            json.tarefaFinalizada
        );
    }
}
