import {BaseModel} from 'app/main/shared/models/base.model';

export class Fertirrigacao extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public idTipoSistema?: number,
        public idTipoCulturasDesenv?: number,
        public mesInicial?: string,
        public mesFinal?: string,
        public volumeEfluente?: number,
    ) {
        super();
    }

    static fromJson(json: any): Fertirrigacao {
        json = json || {};

        return new Fertirrigacao(
            json.id,
            json.idGeometria,
            json.idTipoSistema,
            json.idTipoCulturasDesenv,
            json.mesInicial,
            json.mesFinal,
            json.volumeEfluente,
        );
    }
}