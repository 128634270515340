import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {DocumentoRequerimento} from './documento-requerimento.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Requerimento} from '../requerimentos/requerimento.model';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DocumentoRequerimentoService extends BaseService<DocumentoRequerimento> {
    constructor(protected injector: Injector) {
        super(
            '/api/documentos-requerimentos',
            injector,
            DocumentoRequerimento.prototype,
            DocumentoRequerimento.fromJson
        );
    }

    getByRequerimento(
        requerimento: Requerimento,
        tipos?: string[]
    ): Observable<DocumentoRequerimento[]> {
        const url = `${this.urlResource}/requerimento/${requerimento.id}`;
        let params: HttpParams = new HttpParams();

        if (tipos) {
            params = params.set('tipos', tipos.toString());
        }

        return this.http
            .get(url, {params: params})
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    existsByRequerimento(requerimento: Requerimento): Observable<boolean> {
        const url = `${this.urlResource}/requerimento/${requerimento.id}/exists`;
        return this.http.get(url)
            .pipe(map(exists => exists),
                catchError(this.handleError.bind(this)));
    }

    createMultipart(
        documentoRequerimento: DocumentoRequerimento,
        multipartFile: Blob,
        ignoreLoading: boolean = false
    ): Observable<DocumentoRequerimento> {
        return this.http
            .post(
                this.urlResource,
                this.montarFormData(documentoRequerimento, multipartFile),
                ignoreLoading? {params: {ignoreLoading: String(ignoreLoading)}} : {}
            )
            .pipe(
                map(DocumentoRequerimento.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateMultipart(
        documentoRequerimento: DocumentoRequerimento,
        multipartFile: Blob,
        ignoreLoading: boolean = false
    ): Observable<DocumentoRequerimento> {
        const url = `${this.urlResource}/${documentoRequerimento.id}`;
        return this.http
            .put(url, this.montarFormData(documentoRequerimento, multipartFile,),
                ignoreLoading? {params: {ignoreLoading: String(ignoreLoading)}} : {}
            )
            .pipe(
                map(DocumentoRequerimento.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    private montarFormData(
        documentoRequerimento: DocumentoRequerimento,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const documentoRequerimentoParam = new Blob(
            [JSON.stringify(documentoRequerimento)],
            {type: 'application/json'}
        );
        formData.append('documentoRequerimento', documentoRequerimentoParam);
        return formData;
    }

}
