import {BaseModel} from 'app/main/shared/models/base.model';
import {EmpreendimentoSubEstacao} from './empreendimento-subestacao.model';

export class EmpreendimentoFuncao extends BaseModel {
    constructor(
        public id?: number,
        public funcao?: number,
        public empreendimentoSubEstacao?: EmpreendimentoSubEstacao,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoFuncao {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoFuncao(
            json.id,
            json.funcao,
            json.empreendimentoSubEstacao,
        );
    }

    static fromJsons(json: any): EmpreendimentoFuncao[] {
        const empreendimentos: EmpreendimentoFuncao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(empreendimento => empreendimentos.push(this.fromJson(empreendimento)));
            return empreendimentos;
        }
    }

}
