import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {TermoReferencia} from '../termos-referencia/termo-referencia.model';

export class ItemTermoReferencia extends BaseModel {
    constructor(
        public id?: number,
        public numero?: number,
        public descricao?: string,
        public tipo?: string,
        public obrigatorio?: boolean,
        public informacoesAdicionais?: string,
        public nomeArquivo?: string,
        public chave?: string,
        public termoReferencia?: TermoReferencia,
        public limiteMB?: string
    ) {
        super();
    }

    get descricaoTruncada(): string {
        return this.descricao.length > 50
            ? this.descricao.substring(0, 50) + '...'
            : this.descricao;
    }

    static tipos: BaseEnum[] = [
        {valor: 'DOCUMENTACAO_TECNICA', descricao: 'Documentação técnica'},
        {valor: 'DOCUMENTOS_GERAIS', descricao: 'Documentos Gerais'},
        {valor: 'DADO_GEOESPACIAL', descricao: 'Dado geoespacial'}
    ];

    static tiposEspecificos: BaseEnum[] = [
        {valor: 'DOCUMENTOS_ESPECIFICOS', descricao: 'Documentos Específicos'},
        {valor: 'DOCUMENTOS_GERAIS', descricao: 'Documentos Gerais'},
    ];

    static limitesMB: BaseEnum[] = [
        {valor: 'UM_MB', descricao: '1MB'},
        {valor: 'CINCO_MB', descricao: '5MB'},
        {valor: 'DEZ_MB', descricao: '10MB'},
        {valor: 'VINTE_MB', descricao: '20MB'},
        {valor: 'TRINTA_MB', descricao: '30MB'},
    ];

    static fromJson(json: any): ItemTermoReferencia {
        if (json === undefined || json === null) {
            return null;
        }
        return new ItemTermoReferencia(
            json.id,
            json.numero,
            json.descricao,
            json.tipo,
            json.obrigatorio,
            json.informacoesAdicionais,
            json.nomeArquivo,
            json.chave,
            TermoReferencia.fromJson(json.termoReferencia),
            json.limiteMB
        );
    }

    static fromJsons(json: any): ItemTermoReferencia[] {
        const itensTermoReferencia: ItemTermoReferencia[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(itemTermoReferencia =>
                itensTermoReferencia.push(this.fromJson(itemTermoReferencia))
            );
            return itensTermoReferencia;
        }
    }
}
