import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ResiduoAterroIndUrbDto} from "../models/residuo-aterro-ind-urb-dto.model";

@Injectable({
    providedIn: 'root'
})
export class ResiduoAterroIndUrbService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_LIC_RES_ATERRO_IND_URBANO;

    createResiduoAterro(residuoAterroIndUrbDto: ResiduoAterroIndUrbDto): Observable<ResiduoAterroIndUrbDto> {
        const url = `${this.urlResource}/api/residuo-aterro-urbano`;
        return this.http.post(url, residuoAterroIndUrbDto)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscaResiduoAterroIndUrbano(idResAterroIndUrbano: number): Observable<any> {
        const url = `${this.urlResource}/api/residuo-aterro-urbano/id/${idResAterroIndUrbano}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscaListaResAterroIndUrbanoMunicipioPorIdResAterro(idResAterroIndUrbano: number): Observable<any> {
        const url = `${this.urlResource}/api/res-aterro-urbano-municipio/id/${idResAterroIndUrbano}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }
    deletarResAterroIndUrbanoMunicipioPorId(idResAterroMunicipio: number): Observable<any> {
        const url = `${this.urlResource}/api/res-aterro-urbano-municipio/delete/${idResAterroMunicipio}`;
        return this.http.delete(url);
    }


    buscaListaResAterroIndUrbanoFormasTransbordoPorIdResAterro(idResAterroIndUrbano: number): Observable<any> {
        const url = `${this.urlResource}/api/res-aterro-urbano-formas-transbordo/id/${idResAterroIndUrbano}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarResAterroIndUrbanoFormasTransbordoPorId(idResAterroFormasTransbordo: number): Observable<any> {
        const url = `${this.urlResource}/api/res-aterro-urbano-formas-transbordo/delete/${idResAterroFormasTransbordo}`;
        return this.http.delete(url);
    }


    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/api/residuo-aterro-urbano/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(e => throwError(e))
        );;
    }
}
