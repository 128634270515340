import {Injectable, Injector} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class FeicaoTabelaAtributosService {
    private http: HttpClient;
    private urlResource: string

    constructor(private injector: Injector) {
        this.http = injector.get(HttpClient);
        this.urlResource = environment.URL_GATEWAY + '/api/feicoes-tabela-atributos'
    }

    downloadSHP(idRequerimento: number, idFeicaoEntrada: number): Observable<Blob> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');
        return this.http.get(
            `${this.urlResource}/${idRequerimento}/requerimento/${idFeicaoEntrada}/feicao-entrada/download-shp`,
            { headers: headers, responseType: 'blob' }
        ).pipe(
            catchError(this.handleError.bind(this))
        );
    }
    downloadKML(idRequerimento: number, idFeicaoEntrada: number): Observable<Blob> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');
        return this.http.get(
            `${this.urlResource}/${idRequerimento}/requerimento/${idFeicaoEntrada}/feicao-entrada/download-kml`,
        { headers: headers, responseType: 'blob' }
        ).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    downloadSHPTodasGeometriasPorRequerimento(idRequerimento: number): Observable<Blob> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/octet-stream');
        return this.http.get(
            `${this.urlResource}/${idRequerimento}/requerimento/download-shp?excluidas=true`,
            { headers: headers, responseType: 'blob' }
        ).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    showFile(blob: Blob, fileName: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }
    protected handleError(error: HttpErrorResponse): Observable<any> {
        if (error.error instanceof Blob && error.error.type === 'text/html') {
            return new Observable(observer => {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(event.target.result, 'text/html');
                    const messageElement = Array.from(doc.getElementsByTagName('p')).find(p => p.textContent.includes('Message'));
                    let errorMessage = 'Um erro desconhecido aconteceu!';
                    if (messageElement) {
                        const fullMessage = messageElement.textContent.split('Message')[1].trim();
                        const match = fullMessage.match(/Exception: (.*)/);
                        if (match) {
                            errorMessage = match[1].trim();
                        }
                    }
                    observer.error(errorMessage);
                    observer.complete();
                };
                reader.readAsText(error.error);
            });
        } else {
            let errorMessage = 'Um erro desconhecido aconteceu!';
            if (error.error instanceof ErrorEvent) {
                // Erros do lado do cliente
                errorMessage = `Erro: ${error.error.message}`;
            } else {
                // Erros do lado do servidor
                errorMessage = `Código do erro: ${error.status}\nMensagem: ${error.message}`;
            }
            return throwError(errorMessage);
        }
    }


}
