import {Component, OnInit} from '@angular/core';
import {DominiosService} from '../../../../../../../shared/services/dominios.service';
import {DominioDto} from '../../../../../../../shared/models/dominio-dto.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../../../../shared/snack-bar/snack-bar.service';
import {ResiduoAterroIndUrbService} from '../../../../../../../shared/services/residuo-aterro-ind-urb.service';
import {ResiduoAterroIndUrbDto} from '../../../../../../../shared/models/residuo-aterro-ind-urb-dto.model';
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-residuos-aterro-industrial-urbano',
    templateUrl: './residuos-aterro-industrial-urbano.component.html',
    styleUrls: ['./residuos-aterro-industrial-urbano.component.scss']
})
export class ResiduosAterroIndustrialUrbanoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    tipoAtividade: DominioDto[]
    tipoResiduoIndustrial: any
    tipoResiduoUrbano: any
    residuosUrbanoMunicipios: any;
    formasTransbordoResiduos: any;
    resAterroIndUrbano: any;
    residuosAterroIndustrial: FormGroup;
    validacaoAterro: boolean;
    validacaoTransbordoEstacao: boolean;
    erroAterro: string;
    exibiErroInput: string;
    isNaoPodeEditar: boolean = false;

    idResAterroIndUrbano = null;

    constructor(private dominiosService: DominiosService,
                private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
                private residuoAterroIndUrbService: ResiduoAterroIndUrbService) {
        super();
    }

    async ngOnInit() {
        this.builderTipos()

        if (this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                 this.carregaResAterroIndUrbanoPorIdExistente(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                 this.carregaResAterroIndUrbanoPorIdExistente(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.residuosAterroIndustrial.disable();
        }
    }


    builderTipos() {
        this.residuosAterroIndustrialBuilder();
        this.buscarTipoAtividade();
        this.buscarTipoResiduo();
    }

    private residuosAterroIndustrialBuilder() {
        this.residuosAterroIndustrial = this.formBuilder.group({
            id: [null],
            tipoAtividade: [null, Validators.required],
            tipoResiduoIndustrial: [null],
            tipoResiduoUrbano: [null],
            possuiBalanca: [null, Validators.required],
            capacidadeTonelada: [null],
            capacidadeVolumetrica: [null],
            capacidadeOperacional: [null],
            pesoEspecificoLixo: [null],
            populacaoAtendida: [null],
            vidaUtilAnos: [null],
            transbordoCapacidadeOperacional: [null],
            transbordoPopulacaoAtendida: [null],
        });
    }

    receberFormasTransbordoResiduos(formasTransbordo: any) {
        this.formasTransbordoResiduos = formasTransbordo.length > 0 ? formasTransbordo : null;
    }

    receberResiduosUrbanoMunicipios(residuos: any) {
        this.residuosUrbanoMunicipios = residuos.length > 0 ? residuos : null;
    }

    buscarTipoAtividade() {
        const consultaTabela = 'TIPO_ATIVIDADE_RESIDUO';
        this.dominiosService.getDominio(consultaTabela).subscribe(tipoAtividade => {
            this.tipoAtividade = tipoAtividade;
        })
    }

    buscarTipoResiduo() {
        const consultaTabela = 'TIPO_RESIDUO';
        this.dominiosService.getDominio(consultaTabela).subscribe(tipoResiduo => {
            this.tipoResiduoIndustrial = tipoResiduo[0].descricao;
            this.tipoResiduoUrbano = tipoResiduo[1].descricao;
        })
    }

    validarTipoAtividade(tipoAtividade) {
        if (tipoAtividade.descricao === 'Aterro') {
            this.validacaoAterro = true;
            this.validacaoTransbordoEstacao = false;
        } else {
            this.validacaoAterro = false;
            this.validacaoTransbordoEstacao = true;
        }
    }

    verificarVazio(elemento): boolean {
        if (elemento == '' || elemento == null) {
            return true;
        } else {
            return false;
        }
    }

    validarFormulario(): boolean {
        if (this.residuosAterroIndustrial.valid) {
            return true;
        } else {
            this.residuosAterroIndustrial.markAllAsTouched()
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)')
        }
    }

    salvarAterroTransbordo(): void {
        const validacao = this.validarFormulario();
        if (validacao) {
            if (this.residuosAterroIndustrial.value.tipoAtividade.descricao === 'Aterro'
                && this.residuosAterroIndustrial.value.tipoResiduoUrbano) {

                const residuoAterroDto = this.validaObjetoResiduosAterroIndustrial();
                if (residuoAterroDto && this.residuosUrbanoMunicipios) {

                    residuoAterroDto.residuoAterroIndUrbMunicipios = this.residuosUrbanoMunicipios;
                    this.residuoAterroIndUrbService.createResiduoAterro(residuoAterroDto)
                        .subscribe(() => {
                            this.snackBarService.showSuccess('Residuo Aterro Industrial Urbano salvo com sucesso.');
                            this.enviarFormConcluido.emit();
                            this.residuosAterroIndustrial.reset();
                        });
                } else {
                    this.snackBarService.showError('Formulario não foi preenchindo corretamente.');
                }

            } else if (this.residuosAterroIndustrial.value.tipoAtividade.descricao === 'Transbordo/Estação de transferência'
                && this.residuosAterroIndustrial.value.tipoResiduoUrbano) {

                const residuoAterroDto = this.validaObjetoResiduosAterroIndustrial();
                if (residuoAterroDto && this.formasTransbordoResiduos && this.residuosUrbanoMunicipios) {
                    residuoAterroDto.residuoAterroIndUrbFormasTransbordo = this.formasTransbordoResiduos;
                    residuoAterroDto.residuoAterroIndUrbMunicipios = this.residuosUrbanoMunicipios;

                    this.residuoAterroIndUrbService.createResiduoAterro(residuoAterroDto)
                        .subscribe(() => {
                            this.snackBarService.showSuccess('Residuo Aterro Industrial Urbano salvo com sucesso.')
                            this.enviarFormConcluido.emit();
                            this.residuosAterroIndustrial.reset();
                        });
                } else {
                    this.snackBarService.showError('Formulario não foi preenchindo corretamente.');
                }
            } else if (this.residuosAterroIndustrial.value.tipoAtividade.descricao === 'Transbordo/Estação de transferência'
                && this.residuosAterroIndustrial.value.tipoResiduoIndustrial) {

                const residuoAterroDto = this.validaObjetoResiduosAterroIndustrial();
                if (this.formasTransbordoResiduos && residuoAterroDto) {
                    residuoAterroDto.residuoAterroIndUrbFormasTransbordo = this.formasTransbordoResiduos;
                    this.residuoAterroIndUrbService.createResiduoAterro(residuoAterroDto)
                        .subscribe(() => {
                            this.snackBarService.showSuccess('Residuo Aterro Industrial Urbano salvo com sucesso.');
                            this.enviarFormConcluido.emit();
                            this.residuosAterroIndustrial.reset();
                        });
                } else {
                    this.snackBarService.showError('Formulario não foi preenchindo corretamente.');
                }
            } else {

                const residuoAterroDto = this.validaObjetoResiduosAterroIndustrial();
                if (residuoAterroDto) {
                    this.residuoAterroIndUrbService.createResiduoAterro(residuoAterroDto)
                        .subscribe(() => {
                            this.snackBarService.showSuccess('Residuo Aterro Industrial Urbano salvo com sucesso.')
                            this.enviarFormConcluido.emit();
                            this.residuosAterroIndustrial.reset();
                        });
                }

            }
        }
    }

    validaObjetoResiduosAterroIndustrial(): ResiduoAterroIndUrbDto {
        if (this.residuosAterroIndustrial.value.tipoResiduoIndustrial != null || this.residuosAterroIndustrial.value.tipoResiduoUrbano != null) {
            if (this.residuosAterroIndustrial.value.tipoAtividade.descricao === 'Aterro') {
                if (this.residuosAterroIndustrial.value.capacidadeVolumetrica === null
                    || this.residuosAterroIndustrial.value.capacidadeOperacional === null
                    || this.residuosAterroIndustrial.value.pesoEspecificoLixo === null
                    || this.residuosAterroIndustrial.value.populacaoAtendida === null
                    || this.residuosAterroIndustrial.value.vidaUtilAnos === null) {
                    this.erroAterro = 'erroAterro';
                    this.residuosAterroIndustrial.markAllAsTouched();
                    this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)');
                } else {
                    return this.montaObjetoResiduoAterroIndUrbDto();
                }
            } else if (this.residuosAterroIndustrial.value.tipoAtividade.descricao === 'Transbordo/Estação de transferência' &&
                this.residuosAterroIndustrial.value.transbordoCapacidadeOperacional === null &&
                this.residuosAterroIndustrial.value.transbordoPopulacaoAtendida === null) {
                this.exibiErroInput = 'erroInputsTransbordo';
                this.residuosAterroIndustrial.markAllAsTouched();
                this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)');
            } else {
                return this.montaObjetoResiduoAterroIndUrbDto();
            }
        } else {
            this.exibiErroInput = 'erroInputTipo';
            this.residuosAterroIndustrial.markAllAsTouched();
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)');
        }
    }

    private carregaResAterroIndUrbanoPorIdExistente(idRequerimento: number) {
        this.residuoAterroIndUrbService.buscarPorIdRequerimento(idRequerimento).subscribe(resAterro => {
            const tipoAtividade = this.tipoAtividade.filter(tipo => tipo.id === resAterro.idTipoAtividade);
            this.validarTipoAtividade(tipoAtividade[0]);
            this.resAterroIndUrbano = resAterro.id;
            this.residuosAterroIndustrial.controls.tipoAtividade.patchValue(tipoAtividade[0]);
            this.residuosAterroIndustrial.controls.tipoResiduoIndustrial.patchValue(resAterro.tipoResiduoIndustrial === 1);
            this.residuosAterroIndustrial.controls.tipoResiduoUrbano.patchValue(resAterro.tipoResiduoUrbano === 1);
            this.residuosAterroIndustrial.controls.possuiBalanca.patchValue(resAterro.possuiBalanca === 1 ? 'sim' : 'nao');
            this.residuosAterroIndustrial.controls.capacidadeTonelada.patchValue(resAterro.capacidadeTonelada);
            this.residuosAterroIndustrial.controls.capacidadeVolumetrica.patchValue(resAterro.capacidadeVolumetrica);
            this.residuosAterroIndustrial.controls.capacidadeOperacional.patchValue(resAterro.capacidadeOperacionalTnlDia);
            this.residuosAterroIndustrial.controls.pesoEspecificoLixo.patchValue(resAterro.pesoEspecificoLixoTnl);
            this.residuosAterroIndustrial.controls.populacaoAtendida.patchValue(resAterro.populacaoAtendidaNroHab);
            this.residuosAterroIndustrial.controls.vidaUtilAnos.patchValue(resAterro.vidaUtilAnos);
            this.residuosAterroIndustrial.controls.transbordoCapacidadeOperacional.patchValue(resAterro.transbordoCapOperacionalTnl);
            this.residuosAterroIndustrial.controls.transbordoPopulacaoAtendida.patchValue(resAterro.transbordoPopulacaoAtendidahab);

        });
    }

    montaObjetoResiduoAterroIndUrbDto(): ResiduoAterroIndUrbDto {
        const residuoAterroDto = new ResiduoAterroIndUrbDto();
        //TODO Verificar se precisa apagar a variavel idResAterroUrbano já que não é mais usada para buscar o form
        residuoAterroDto.id = this.idResAterroIndUrbano;
        residuoAterroDto.idRequerimento = this.requerimento.id;
        residuoAterroDto.idTipoAtividade = this.residuosAterroIndustrial.value.tipoAtividade.id
        residuoAterroDto.tipoResiduoIndustrial = this.residuosAterroIndustrial.value.tipoResiduoIndustrial === true ? 1 : 0;
        residuoAterroDto.tipoResiduoUrbano = this.residuosAterroIndustrial.value.tipoResiduoUrbano === true ? 1 : 0;
        residuoAterroDto.possuiBalanca = this.residuosAterroIndustrial.value.possuiBalanca === 'sim' ? 1 : 0;
        residuoAterroDto.capacidadeTonelada = Number(this.residuosAterroIndustrial.value.capacidadeTonelada);
        residuoAterroDto.capacidadeVolumetrica = Number(this.residuosAterroIndustrial.value.capacidadeVolumetrica);
        residuoAterroDto.capacidadeOperacionalTnlDia = Number(this.residuosAterroIndustrial.value.capacidadeOperacional);
        residuoAterroDto.pesoEspecificoLixoTnl = Number(this.residuosAterroIndustrial.value.pesoEspecificoLixo);
        residuoAterroDto.populacaoAtendidaNroHab = Number(this.residuosAterroIndustrial.value.populacaoAtendida);
        residuoAterroDto.vidaUtilAnos = Number(this.residuosAterroIndustrial.value.vidaUtilAnos);
        residuoAterroDto.transbordoCapOperacionalTnl = Number(this.residuosAterroIndustrial.value.transbordoCapacidadeOperacional);
        residuoAterroDto.transbordoPopulacaoAtendidahab = Number(this.residuosAterroIndustrial.value.transbordoPopulacaoAtendida);

        return residuoAterroDto;
    }
}
