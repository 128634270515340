import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PontoMapa} from '@sema-geo/sema-geoportal';
import {GeometriaMapa} from '@sema-geo/sema-geoportal';
import {FeicaoAnaliseRequerimentoService} from '../../../feicoes-analise-requerimentos/feicao-analise-requerimento.service';

export interface DialogEditarFeicaoData {
    denomicacao: string;
    tamanho: number;
    geometria: GeometriaMapa;
    visualizacao: boolean;
    onRemoveFeature: (geometria: GeometriaMapa) => void;
}

@Component({
    selector: 'app-dialog-editar-feicao',
    templateUrl: './dialog-editar-feicao.component.html',
    styleUrls: ['./dialog-editar-feicao.component.scss'],
})
export class DialogEditarFeicaoComponent  implements OnInit{

    form: FormGroup;

    geometria: GeometriaMapa;

    tamanho: number;

    onRemoveFeature: (geometria: GeometriaMapa) => void;

    denomicacao = new FormControl(null, [Validators.required, Validators.maxLength(255)]);

    constructor(
        private formBuilder: FormBuilder,
        private feicaoAnaliseRequerimentoService: FeicaoAnaliseRequerimentoService,
        private snackBarService: SnackBarService,
        private dialogRef: MatDialogRef<DialogEditarFeicaoData>,
        @Inject(MAT_DIALOG_DATA) protected data: DialogEditarFeicaoData
    ) {
        this.data.visualizacao = this.data.visualizacao ? this.data.visualizacao : false;
        this.onRemoveFeature = data.onRemoveFeature;
        this.tamanho = data.tamanho;
        this.geometria = data.geometria;

        this.form = this.formBuilder.group({
            denomicacao: this.denomicacao
        });

        this.denomicacao.setValue(data.denomicacao);
    }
    ngOnInit(): void {
        if(this.data.visualizacao){
            this.form.disable()
        }
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onRemover(): void {
        this.onRemoveFeature(this.geometria);
        this.dialogRef.close({
            success: false
        });
    }

    salvar(): void {
        this.dialogRef.close({
            success: true,
            geometria: this.geometria,
            titulo: this.denomicacao.value,
            feicao: {
                denominacao: this.denomicacao.value
            }
        });
    }
}
