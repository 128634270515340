import { Component, ElementRef, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UtilService } from '../../../../../../core/services/util.service';
import { AtividadeArrecadacao } from '../../../../../../shared/models/arrecadacao-models/atividade-arrecadacao.model';
import { AtividadeProjectionArrecadacao } from '../../../../../../shared/models/arrecadacao-models/atividade-projection-arrecadacao.model';
import { ParametroEntradaArrecadacao } from '../../../../../../shared/models/arrecadacao-models/parametro-entrada-arrecadacao.model';
import { FormularioReqLicenciamentoEmp } from "../../../../../../shared/models/formulario-req-licenciamento-emp.dto";
import { FormularioReqLicenciamentoDto } from '../../../../../../shared/models/formulario-req-licenciamento.dto';
import { LancamentoTaxaDto } from '../../../../../../shared/models/lancamento-taxa-dto.model';
import { ObjetivoLicenciamento } from "../../../../../../shared/models/objetivo-licenciamento.model";
import { AtividadeArrecadacaoService } from '../../../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service';
import { AtividadeCalculoArrecadacaoService } from '../../../../../../shared/services/arrecadacao-service/atividade-calculo-arrecadacao.service';
import { AtividadeParametroEntradaArrecadacaoService } from '../../../../../../shared/services/arrecadacao-service/atividade-parametro-entrada-arrecadacao.service';
import { ContaCorrenteService } from '../../../../../../shared/services/arrecadacao-service/conta-corrente.service';
import { LancamentoTaxaService } from "../../../../../../shared/services/lancamento-taxa.service";
import { ObjetivoParametrizacaoObjetivoService } from "../../../../../../shared/services/licenciamento-config-service/objetivo-parametrizacao-objetivo.service";
import { ObjetivoLicenciamentoService } from "../../../../../../shared/services/objetivo-licenciamento.service";
import { SnackBarService, SnackBarType } from '../../../../../../shared/snack-bar/snack-bar.service';
import { Titulacao } from '../../../../../titulacoes/titulacao.model';
import { EnumInstanciaRequerimento } from "../../../../menu-novo-requerimento/instancia-requerimento.enum";
import { BaseTabRequerimentoComponent } from '../../../../shared/base-requerimento.component';
import { ErrosAbaRequerimento } from '../../../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { RequerimentoSteps } from '../../../../shared/requerimento.steps';
import { RequerimentoFormularioLicenciamentoService } from "../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import { RequerimentoLicenciamentoService } from '../../../requerimento-licenciamento.service';
import { AbaReqFlorestalService } from '../aba-requerimento/aba-req-florestal.service';
import { TitulacaoService } from 'app/main/pages/titulacoes/titulacao.service';

@Component({
    selector: 'app-requerimento-calculo-taxa-licenciamento-florestal',
    templateUrl: './aba-calculo-taxa.component.html',
    styleUrls: ['./aba-calculo-taxa.component.scss']
})
export class AbaCalculoTaxaComponent extends BaseTabRequerimentoComponent
    implements OnInit {

    camposCredito = 'N';
    atividadeSemParametroeCaculo = false;
    parametroEntradas: any[] = [];
    parametroEntrada: any = {};
    creditoUsuario: any
    idPessoa: number
    atividadeProjection: Array<AtividadeProjectionArrecadacao>
    atividadePai: AtividadeArrecadacao
    displayedAtvColumns: string[] = ['atividade', 'subatividade'];
    element: ElementRef;
    parametroCalculoList: any;
    atividadeCalculo: any[];
    parametrosCalculoLista: any = [];
    msgCalculo = '';
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoEmp = new FormularioReqLicenciamentoEmp();
    errorStatusCalculoParametroEntrada = 200;
    calcularUpfRetornoDtoList: any[] = [];
    titulacaoIsenta = true;
    display: string[] = ['naturezaReceita', 'parametros', 'qtdUpf', 'qtdReal'];
    displayLT: string[] = ['naturezaReceita', 'qtdUpf'];
    licencas: any = [];
    isento: boolean;
    timer
    lancamentoTaxa: LancamentoTaxaDto;
    lancamentosTaxa: LancamentoTaxaDto[];
    taxaIsenta: boolean;
    guiasAssociadas: any[] = [];
    guiasAssociadasDisplay = ['numDar', 'situacao', 'vencimento', 'valor', 'acao'];
    objetoReqIsencao: any = [];
    objetoReqReaproveitamentoTaxa: any = [];
    titulos: Array<any> = [];
    objetivoLicenciamentoTitulacaoDTO: any[] = [];

    objetivosLicenciamento: ObjetivoLicenciamento[];
    parametrizacaoDoObjetivoPrincipal: any;

    constructor(private formBuilder: FormBuilder,
                private contaCorrenteService: ContaCorrenteService,
                private atividadeArrecadacaoService: AtividadeArrecadacaoService,
                private atividadeCalculoService: AtividadeCalculoArrecadacaoService,
                protected requerimentoFormularioLicenciamentoService: AbaReqFlorestalService,
                private injector: Injector,
                private atividadeCalculoParametroEntradaService: AtividadeParametroEntradaArrecadacaoService,
                private snackBarService: SnackBarService,
                protected requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
                private atividadeCalculoArrecadacaoService: AtividadeCalculoArrecadacaoService,
                private lancamentoTaxaService: LancamentoTaxaService,
                protected requerimentoFormLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
                private objetivoParametrizacaoObjetivoService: ObjetivoParametrizacaoObjetivoService,
                private licenciamentoTitulacaoService: TitulacaoService,
                private utils: UtilService) {
        super(injector);
        RequerimentoSteps.TAXA.component = this;
    }

    async ngOnInit() {
        this.idPessoa = this.requerimento.pessoa.idCorporativo
        await this.recuperarAtividadeProjection()
        if (this.requerimento.tipoProcesso.instanciaRequerimento === "LICENCIAMENTO_TRIFASICO" ||
            this.requerimento.tipoProcesso.instanciaRequerimento === "TERMO_REFERENCIA" ||
            this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS") {
            await this.buscarFormularioRequerimentoLicenciamentoEmp()
        } else {
            await this.buscarFormularioRequerimentoLicenciamento()
        }

        this.getListaTitulos();
        this.getObjetivosLicenciamentoRequerimento();
        if (this.taxaIsenta === false) {
            this.patchForm();
        }

        if(this.requerimento.tipoProcesso.instanciaRequerimento != EnumInstanciaRequerimento.RECURSO_FLORESTAL){
            this.pesquisarGuiasAssociadas();
        }
    }

    getObjetivosLicenciamentoRequerimento() {
        this.objetivoLicenciamentoService.findObjetivosLicenciamentoByRequeriementoId(this.requerimento.id)
            .subscribe(result => {
                this.objetivoLicenciamentoTitulacaoDTO = result;
        });
    }

    private async buscarFormularioRequerimentoLicenciamentoEmp() {
        await this.requerimentoFormLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise().then(async formularioReqLicenciamentoDto => {
            if (formularioReqLicenciamentoDto) {
                if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                    this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto
                    this.lancamentosTaxa = formularioReqLicenciamentoDto.lancamentoTaxa;
                }
            }
        });
    }

    private async buscarFormularioRequerimentoLicenciamento() {
        this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoFlorestalId(this.requerimento.id).subscribe(async formularioReqLicenciamentoDto => {
            if (formularioReqLicenciamentoDto) {
                if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                    this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto
                    this.lancamentosTaxa = formularioReqLicenciamentoDto.lancamentoTaxa;
                    this.taxaIsenta = this.lancamentosTaxa.some(lt => lt.taxaIsenta);
                }
            }
        });

        if (this.taxaIsenta === false) {
            this.patchForm();
        }
        this.pesquisarGuiasAssociadas();
    }

    async recuperarAtividadeProjection() {
        await this.atividadeArrecadacaoService
            .getByAtividadeNivelPorDesc(null, this.requerimentoLicenciamento.idAtividade)
            .toPromise().then(async data => {
                this.atividadeProjection = data
                if (this.atividadeProjection[0].nivel === 3) {
                    await this.atividadeArrecadacaoService.getAtividadeId(this.atividadeProjection[0].id).toPromise().then(atv => {
                        this.atividadePai = atv
                    })
                } else if (this.atividadeProjection[0].nivel === 4) {
                    await this.atividadeArrecadacaoService.getAtividadeId(this.atividadeProjection[0].idAtvPai).toPromise().then(atv => {
                        this.atividadePai = atv
                    })
                }

            });
    }

    private objetivoAtividadeDeObjetivo() {
        let novaArrayAtividade = [];
        for (let obj of this.objetivoLicenciamento) {
            novaArrayAtividade.push(this.atividadeProjection.find(d => d.descricao.includes(obj.objetivo.objetivo.descricao)))
        }
        this.atividadeProjection = novaArrayAtividade
    }

    async obterAtividadeCalculoSemParametroEntradaeCalculo() {
        let msg = true;
        await this.atividadeCalculoService
            .buscarListaAtividadeCalculoPAtividadeParametroEntrada(this.montarObjetoParametroSemLicenca())
            .toPromise().then(async atividadeCalculo => {
                this.atividadeCalculo = atividadeCalculo;
                this.parametroCalculoList = [...this.atividadeCalculo].shift();
                if (this.parametroCalculoList && this.parametroCalculoList.parametroCalculoList.length === 0) {
                    this.atividadeSemParametroeCaculo = true;
                    await this.calcularLicenca()
                }
            }).catch(() => {
                msg = false;
            });
    }

    async obterAtividadeCalculoParametroEntrada() {
        try {
            const objetivosLicenciamento = await this.objetivoLicenciamentoService
                .buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id)
                .toPromise();
    
            this.objetivosLicenciamento = objetivosLicenciamento;
    
            // Filtrando objetivos principais (1 = PRINCIPAL)
            const objetivosPrincipaisDoRequerimento = this.objetivosLicenciamento.filter(
                obj => obj.idTipoObjetivo === 1
            );
    
            const objsParametrizados = await this.objetivoParametrizacaoObjetivoService
                .getByParametrizacaoObjetivo(this.requerimento.idParametroObjetivo)
                .toPromise();
    
            //TODO Pegando o primeiro do array enquanto a regra a respeito de qual objetivo principal tem maior prioridade é descutida.
            this.parametrizacaoDoObjetivoPrincipal = objsParametrizados.filter(
                obj =>
                    obj.idTipoRequerimento === this.requerimento.tipoRequerimento.id &&
                    obj.idObjetivoParametrizacao === objetivosPrincipaisDoRequerimento[0].idObjetivoLicenciamentoAmbiental
            );
    
            const atividadeParametroCalculoEntrada = await this.atividadeCalculoParametroEntradaService
                .buscarParametrosEntradaPIdAtividade(
                    this.atividadeProjection[0].id,
                    this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao
                )
                .toPromise()
                .catch(() => null);

            if (atividadeParametroCalculoEntrada) {
                this.parametroEntradas = atividadeParametroCalculoEntrada;
            }                
    
            const atividadeCalculo = await this.atividadeCalculoService
                .buscarListaAtividadeCalculoPAtividadeParametroEntrada(
                    this.montarObjetoParametroSemLicenca()
                )
                .toPromise();
    
            this.atividadeCalculo = atividadeCalculo;
            this.parametroCalculoList = [...this.atividadeCalculo].shift();
    
            this.calcularLicenca();
    
        } catch (error) {
            console.error("Erro:", error);
    
            if (error.status === 404) {
                this.obterAtividadeCalculoSemParametroEntradaeCalculo();
            }
        }
    }

    montarObjetoCalculoUpf(idAtividadeCalculo) {

        // Cria uma nova lista de parâmetros, excluindo a propriedade 'id'
        const parametrosCalculoLimpos = this.parametrosCalculoLista.map(param => {
            const { id, ...resto } = param; // Desestruturação para excluir 'id'
            return resto; // Retorna um novo objeto sem a propriedade 'id'
        });

        return {
            idAtividadeCalculo: idAtividadeCalculo,
            parametrosCalculo: parametrosCalculoLimpos,
            parametroEntrada: this.parametroEntrada
        };
    }


    async calcularLicenca() {
        this.calcularUpfRetornoDtoList = [];
        for (let i = 0; i < this.atividadeCalculo.length; i++) {
            await this.atividadeCalculoService
                .calcularUpf(this.montarObjetoCalculoUpf(this.atividadeCalculo[i].id)).toPromise().then(calcularUpfRetornoDto => {
                    // if (calcularUpfRetornoDto.atividadeCalculo.tipoTitulacao.id === 14 || calcularUpfRetornoDto.atividadeCalculo.tipoTitulacao.id === 28) { // AUTORIZAÇÃO
                    if (this.calcularUpfRetornoDtoList.length === 0) {
                        if (calcularUpfRetornoDto.valorUpf === 0) {
                            this.titulacaoIsenta = false;
                        } else {
                            this.titulacaoIsenta = true;
                            calcularUpfRetornoDto['parametrosDeCalculo'] = this.parametrosCalculoLista
                            this.calcularUpfRetornoDtoList.push(calcularUpfRetornoDto);
                            this.licencas = []
                            this.licencas = this.calcularUpfRetornoDtoList.slice()
                        }
                    }
                    // } else {
                    //     this.titulacaoIsenta = true;
                    // }
                }).catch((e) => {
                    console.error(e);
                });
        }
    }

    private async patchForm() {
        await this.obterAtividadeCalculoParametroEntrada();
        // const valorArea = await this.requerimentoLicenciamentoService.getUnidadeProducaoAnual(this.requerimentoLicenciamento.id).toPromise()
        let lancamentosTaxaCalculosEncontradosEVinculados = [];
        this.parametroCalculoList.parametroCalculoList.forEach(paramCalc => {
            if (this.formularioReqLicenciamentoDto && this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList) {
                let ltcParametro = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.find((ltc) => ltc.descricao.toLowerCase() == paramCalc.descricao.toLowerCase());
                if (ltcParametro) {
                    lancamentosTaxaCalculosEncontradosEVinculados.push(ltcParametro);
                    this.pegarValorDeParamCalc(ltcParametro.valor, paramCalc);
                }
                
            }
            
        });

    }

    private async getListaTitulos() {
        await this.licenciamentoTitulacaoService.getAll().toPromise().then(titulos => {
            this.titulos = titulos
        }).catch(() => {
            this.snackBarService.showError('Ocorreu um erro ao buscar os títulos');
        });
    }

    montarObjetoParametroSemLicenca() {

        if (this.atividadeProjection[0].nivel === 3) {
            return {
                idAtividade: this.atividadeProjection[0].id,
                pisiculturaExotico: false,
                regraApp: false,
                idTipoTitulacao: this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao
            };
        } else if (this.atividadeProjection[0].nivel === 4) {
            return {
                idAtividade: this.atividadeProjection[0].idAtvPai,
                pisiculturaExotico: false,
                regraApp: false,
                idTipoTitulacao: this.parametrizacaoDoObjetivoPrincipal[0].idArrecadacao
            };
        }


    }

    async pegarValorDeParamCalc(valor, parametroEntradas: ParametroEntradaArrecadacao, licencas = []) {
        this.msgCalculo = 'Aguarde 5 segundos para o calculo'
        this.montarObjetoParamEntrada(parametroEntradas, valor)
        if (this.atividadeCalculo && this.atividadeCalculo.length === 0) {
            this.calcularUpfRetornoDtoList = this.atividadeCalculo
        } else {
            this.calcularUpfRetornoDtoList = licencas
        }
        await this.calcularLicenca()
    }

    montarObjetoParamEntrada(parametroEntradas, valor) {

        const objetoParametrosCalculoParaLista = {
            id: parametroEntradas.id,
            descricao: parametroEntradas.descricao,
            labelFormulario: parametroEntradas.labelFormulario,
            valor: valor
        };

        if (!this.parametroCalculoList.length) {
            if (this.parametroCalculoList.parametroCalculoList.length <= 1) {
                this.parametrosCalculoLista = []
            }
        }

        if (this.parametrosCalculoLista.some(param => param.id === objetoParametrosCalculoParaLista.id)) {
            this.parametrosCalculoLista.forEach(paramCalc => {
                if (paramCalc.id === objetoParametrosCalculoParaLista.id && paramCalc.valor != objetoParametrosCalculoParaLista.valor) {
                    this.parametrosCalculoLista.splice(this.parametrosCalculoLista.indexOf(paramCalc), 1);
                    this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
                }
            });
        } else {
            this.parametrosCalculoLista.push(objetoParametrosCalculoParaLista);
        }
    }

    refatorandoLicencasQNaoTemSigla(licenca: any): any {
        if (!licenca.atividadeCalculo.tipoTitulacao.sigla || licenca.atividadeCalculo.tipoTitulacao.sigla === '') {
            return `${licenca.atividadeCalculo.tipoTitulacao.descricao}`
        } else {
            return `${licenca.atividadeCalculo.tipoTitulacao.descricao} - (${licenca.atividadeCalculo.tipoTitulacao.sigla})`;
        }
    }

    refatorandoLancamentoQNaoTemSigla(licenca: LancamentoTaxaDto): any {
        return `${licenca.titulacao.tipo} - (${licenca.titulacao.descricao})`;
    }


    private async montarFormularioReqLicenDTO() {
        const lancamentoTaxa = new LancamentoTaxaDto();
        this.formularioReqLicenciamentoDto = new FormularioReqLicenciamentoEmp();

        await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise()
            .then(result => {
                this.requerimentoLicenciamento = result;
        });

        this.formularioReqLicenciamentoDto.requerimentoLicenciamento = this.requerimentoLicenciamento;
        this.formularioReqLicenciamentoDto.lancamentoTaxa = [];

        const lancamentoTaxaParamCalculoDto = [];

        if (this.formularioReqLicenciamentoDto) {
            if (this.calcularUpfRetornoDtoList.length > 0) {
                for (let licenca of this.calcularUpfRetornoDtoList) {
                    const lancamentoTaxa = new LancamentoTaxaDto()
                    lancamentoTaxa.requerimento = this.requerimento;
                    lancamentoTaxa.qtdePonto = 1;
                    lancamentoTaxa.qtdeTotalUPF = licenca.valorUpf;
                    lancamentoTaxa.qtdeUnitarioUPF = licenca.valorUpf;
                    lancamentoTaxa.valorReal = licenca.valorReal;
                    lancamentoTaxa.codigoReceitaNatureza = licenca.atividadeCalculo.receitaNatureza.id.toString();
                    lancamentoTaxa.codigoReceitaGrupo = licenca.atividadeCalculo.receitaNatureza.receitaGrupo.id.toString();
                    lancamentoTaxa.idAtividadeCalculo = licenca.atividadeCalculo.id;
                    lancamentoTaxa.requerimento = this.requerimento;
                    
                    //lancamentoTaxa.titulacao = this.titulos.find(titulacao => titulacao.descricao.toLowerCase().includes(licenca.atividadeCalculo.receitaNatureza.receitaGrupo.descricao.toLowerCase()));
                    lancamentoTaxa.titulacao = this.titulos.find(titulacao => titulacao.id === this.objetivoLicenciamentoTitulacaoDTO.some(ob => ob.titulacaoId === titulacao.id));

                    this.titulos.forEach(titulacao => {
                        if (this.objetivoLicenciamentoTitulacaoDTO.some(objtLic => objtLic.titulacaoId === titulacao.id)) {
                            lancamentoTaxa.titulacao = titulacao;
                        }
                    });

                    //Como os requerimentos sugef são de unico objetivo, o título é verificado conforme o objetivoLicenciamento.
                    const titulaçãoEncontrada = this.titulos.find(titulacao => 
                        this.objetivoLicenciamentoTitulacaoDTO.some(objtLic => objtLic.titulacaoId === titulacao.id)
                    );
                    
                    if (titulaçãoEncontrada) {
                        lancamentoTaxa.titulacao = titulaçãoEncontrada;
                    }
                   

                    if (licenca['isenta']) {
                        lancamentoTaxa.taxaIsenta = true;
                    }

                    this.parametrosCalculoLista.forEach(paramCalc => {
                        if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList && this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                            const paramCalcFind = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.find(param => param.labelFormulario === paramCalc.labelFormulario);
                            if (paramCalcFind) {
                                lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                                    .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO', paramCalcFind.id));
                            }
                        } else {
                            lancamentoTaxaParamCalculoDto.push(ParametroEntradaArrecadacao
                                .criarObjetoLancamentoTaxaParamCalcDeParamEntrada(paramCalc, lancamentoTaxa, 'CALCULO'));
                        }
                    });

                    this.formularioReqLicenciamentoDto.lancamentoTaxa.push(lancamentoTaxa);

        
                }
            }
        
            this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList = lancamentoTaxaParamCalculoDto;
            if (this.atividadeSemParametroeCaculo) {
                this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = true;
            } else {
                this.formularioReqLicenciamentoDto.lancamentoTaxaSemParam = false;
            }

            return this.formularioReqLicenciamentoDto;
        }
    }

    async salvar() {
        if (this.requerimento.tipoProcesso.instanciaRequerimento !== "TERMO_REFERENCIA") {
            this.requerimentoFormularioLicenciamentoService.salvarFormularioCalculoDeLicencaFlorestal(await this.montarFormularioReqLicenDTO()).toPromise().then(formularioReqLicenciamentoDto => {
                this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
            }).catch(error => {
                this.snackBarService.showError('Ocorreu um erro', error);
            });
        }
    }

    async validarAba(erros: ErrosAbaRequerimento) {

        // valida requerimento - isencao
        if (this.objetoReqIsencao.isencao !== 0 && this.objetoReqIsencao.arquivo === false
            || this.objetoReqIsencao.isencao === undefined && this.objetoReqIsencao.arquivo === false) {
            erros.push('Verificar criterios de isenção  !');
        }

        // valida requerimento - reaproveitamento
        else if (this.objetoReqReaproveitamentoTaxa.length === 0) {
            erros.push('Escolha se deseja ou não utilizar créditos !');
        } else if (this.objetoReqReaproveitamentoTaxa.decisaoReaproveitamento === 'S'
            && this.objetoReqReaproveitamentoTaxa.qtdCredito === 0) {
            erros.push('Utilizar quantidade de upf corretamente !');
        }

        if (erros.length === 0) {
            return await Promise.all([
                this.salvar(),
            ]).then(() => Promise.resolve());
            // if (this.requerimento.tipoProcesso.instanciaRequerimento === "LICENCIAMENTO_TRIFASICO" || this.requerimento.tipoProcesso.instanciaRequerimento === "AUTORIZACAO_OUTROS_DOCUMENTOS") {
            //     return await Promise.resolve();
            // } else {
            //     return await Promise.all([
            //         this.salvar(),
            //     ]).then(() => Promise.resolve());
            // }
        }
        return Promise.resolve();
    }

    stringParametroCalculo(parametros: any) {

        let novoArray = []
        for (let param of parametros) {
            let newObject = `${param.labelFormulario} : ${param.valor}`
            novoArray.push(newObject)
        }
        return novoArray.join(', ')
    }

    pesquisarGuiasAssociadas() {
        const dadosSearch = {
            numDar: '',
            cpfcnpj: this.requerimento.pessoa.cpfCnpj,
            nome: '',
            dataVencimento: '',
            situacaoId: 1,
            naturezaId: ''
        };

        this.atividadeCalculoArrecadacaoService.buscarDarsEmitidos(dadosSearch).subscribe(dars => {
            this.guiasAssociadas.push(...dars);
        })
    }

    emitirDemonstrativo(numDar: any, idDar: number) {
        this.atividadeCalculoArrecadacaoService.obterDemonstrativoTaxa(idDar).toPromise().then((result) => {
            const blob = new Blob([result], {type: 'application/pdf'});
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = numDar + '_demonstrativo.pdf';
            link.click();
        }).catch(error => {
            this.snackBarService.showError('Falhar ao baixar documento DAR.')
        });
    }

    recebeEmitterReqIsencao(event: any): void {
        /// recebe o ultimo objeto do array
        this.objetoReqIsencao = event[event.length - 1];
        this.taxaIsenta = this.objetoReqIsencao.isencao !== 0;
    }

    recebeEmitterReaproveitamento(event: any): void {
        /// recebe o ultimo objeto do array
        this.objetoReqReaproveitamentoTaxa = event[event.length - 1];
        this.camposCredito = this.objetoReqReaproveitamentoTaxa.decisaoReaproveitamento;
    }
}