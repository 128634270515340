import {NgModule} from '@angular/core';
import {CoreModule} from '../../core/core.module';
import {CondicionanteTemplateListComponent} from './condicionante-template-list/condicionante-template-list.component';
import {CondicionanteTemplateDetailComponent} from './condicionante-template-detail/condicionante-template-detail.component';
import {RouterModule, Routes} from '@angular/router';
import {CondicionanteTemplateListResolver, CondicionanteTemplateResolver} from './condicionante-template.service';
import {SharedModule} from 'app/main/shared/shared.module';

export const routes: Routes = [
    {
        path: 'new',
        component: CondicionanteTemplateDetailComponent
    },
    {
        path: 'condicionantes-template',
        component: CondicionanteTemplateListComponent,
        resolve: {
            data: CondicionanteTemplateListResolver
        }
    },
    {
        path: ':id/edit',
        component: CondicionanteTemplateDetailComponent,
        resolve: {
            data: CondicionanteTemplateResolver
        }
    },
    {
        path: ':id/detail',
        component: CondicionanteTemplateDetailComponent,
        resolve: {
            data: CondicionanteTemplateResolver
        }
    }
];

@NgModule({
    declarations: [
        CondicionanteTemplateDetailComponent,
        CondicionanteTemplateListComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes),
    ],
    entryComponents: [
        CondicionanteTemplateDetailComponent,
        CondicionanteTemplateListComponent
    ],
    providers: [
        CondicionanteTemplateResolver,
        CondicionanteTemplateListResolver
    ]
})
export class CondicionanteTemplateModule {
}
