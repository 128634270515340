import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {EmpreendimentoFuncao} from "../../../../../../../../shared/models/empreendimento-funcao.model";
import {EmpreendimentoFuncaoService} from "../../../../../../../../shared/services/empreendimento-funcao.service";

@Component({
    selector: 'app-classificacao-funcao',
    templateUrl: './classificacao-funcao.component.html',
    styleUrls: ['./classificacao-funcao.component.scss']
})
export class ClassificacaoFuncaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
    private $idEmpreendimento: number;

    funcoes: FormControl;
    displayedColumnsFuncao: string[] = ['descricao', 'acoes'];
    tiposFuncao: DominioDto[] = [];
    selectFuncao: DominioDto = null;

    constructor(private dominiosService: DominiosService,
                private funcaoService: EmpreendimentoFuncaoService) {
    }

    async ngOnInit() {
        this.funcoes = this.form.get('funcoes') as FormControl;
        this.tiposFuncao = await this.dominiosService.getDominio("TIPO_FUNCAO").toPromise();
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    @Input() set idEmpreendimento(idEmpreendimento: number) {
        if (idEmpreendimento !== this.$idEmpreendimento) {
            this.$idEmpreendimento = idEmpreendimento;

            if (this.$idEmpreendimento) {
                this.funcaoService.getByEmpreendimento(this.$idEmpreendimento)
                    .subscribe(itens => this.funcoes.setValue(itens));
            }
        }
    }

    excluirFuncao(index: number) {
        if (index > -1) {
            this.funcoes.value.splice(index, 1);
            this.funcoes.setValue([...this.funcoes.value]);
        }
    }

    addFuncao(funcao: DominioDto) {
        if (funcao && !this.funcoes.value.some(f => f.funcao === funcao.id)) {
            this.funcoes.value.push(EmpreendimentoFuncao.fromJson({
                funcao: funcao.id
            }));

            this.funcoes.setValue([...this.funcoes.value]);
            this.selectFuncao = undefined;
        }
    }

    verificaBotaoDisabled() {
        return !this.selectFuncao
    }

    getDescricao(id) {
        return this.tiposFuncao.find(tp => tp.id === id).descricao;
    }
}
