import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {GeracaoPropria} from "../../../../../../../../shared/models/geracao-propria.model";

@Component({
    selector: 'app-matriz-energetica',
    templateUrl: './matriz-energetica.component.html',
    styleUrls: ['./matriz-energetica.component.scss']
})
export class MatrizEnergeticaComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'consumo', 'acoes'];

    tipos: DominioDto[];

    formGeracaoPropria = this.fb.group({
        id: [null],
        mineracao: [null],
        idTipoGeracaoPropria: [null, Validators.required,],
        consumoGeracaoPropria: [null, Validators.required,]
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-geracao-propria')
            .subscribe(doms => this.tipos = doms);

        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formGeracaoPropria.disable();
        }
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formGeracaoPropria.valid) return;

        const tiposGeracaoPropria = this.form.get('tiposGeracaoPropria');

        tiposGeracaoPropria.setValue([
            ...(tiposGeracaoPropria.value || []),
            GeracaoPropria.fromJson(this.formGeracaoPropria.value)
        ]);

        this.formGeracaoPropria.reset();
    }

    excluir(item: GeracaoPropria) {
        const estruturas: AbstractControl = this.form.get('tiposGeracaoPropria');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: GeracaoPropria) {
        this.formGeracaoPropria.patchValue(item);

        this.excluir(item);
    }

}
