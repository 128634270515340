import { BaseModel } from './base.model';
import { Signatario } from './signatario.model';

export class ParametrosControleAssinaturaSignatarioDTO extends BaseModel {
    constructor(
        public assinaturaConcluida?: boolean,
        public signatario?: Signatario,
    ) {
        super();
    }

    static fromJson(json: any): ParametrosControleAssinaturaSignatarioDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParametrosControleAssinaturaSignatarioDTO(
            json.assinaturaConcluida,
            json.signatario);
    }

    static fromJsons(jsonArray: any): ParametrosControleAssinaturaSignatarioDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
