import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {DadosGeraisTransporteResiduosPerigosos} from "../../../../../../../../shared/models/dados-gerais-transporte-residuos-perigosos.model";
import {TransporteResiduosPerigosos} from "../../../../../../../../shared/models/transporte-residuos-perigosos.model";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {ResiduosTransporteProdutosPerigososService} from "../residuos-transporte-produtos-perigosos.service";

@Component({
    selector: 'app-transporte-residuos-produtos',
    templateUrl: './transporte-residuos-produtos.component.html',
    styleUrls: ['./transporte-residuos-produtos.component.scss']
})
export class TransporteResiduosProdutosComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly : boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() enviarProdutos = new EventEmitter<any[]>();
    formProdutos: FormGroup;
    produtosSalvos: any = [];
    dataSourceProdutos = new MatTableDataSource<any>([]);
    idProdutos: number = 0;
    modoEdicao: boolean = false;
    unidadeMedidaFiltrada: any[] = [];
    tipoClasseRisco: any[] = [];
    tipoAcondicionamento: any[] = [];
    mostrarDescricaoProduto: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private dominioService: DominiosService,
                private serviceTransporte: ResiduosTransporteProdutosPerigososService) {
    }

    async ngOnInit() {
        this.buildForm();

        //Buscando unidade de medida filtrada
        this.unidadeMedidaFiltrada = await this.serviceTransporte.buscarDominiosFiltrados().toPromise();

        this.dominioService.getAllTipoClasseRisco().subscribe( retorno => {
            this.tipoClasseRisco = retorno;
        }, error => {
            this.snackBarService.showError("Ocorreu um erro ao buscar a lista tipo classe de risco. Cod. Erro: " + error.status);
        })

        this.dominioService.getAllTipoAcondicionamento().subscribe( retorno => {
            this.tipoAcondicionamento = retorno
        }, error => {
            this.snackBarService.showError("Ocorreu um erro ao buscar a lista tipo acondicionamento. Cod. Erro: " + error.status);
        })

        if(this.idForm){
            this.serviceTransporte.buscarResiduosProdutosPorId(this.idForm).subscribe( response => {
                response.forEach( residuosProdutos => {
                    this.produtosSalvos.push({
                        id: residuosProdutos.id,
                        idTemp: this.idProdutos,
                        classeRisco: residuosProdutos.classeRisco,
                        nomeResiduo: residuosProdutos.residuoProduto,
                        qtdAnual: residuosProdutos.qtdAnual,
                        unidadeMedida: residuosProdutos.unidadeMedida,
                        acondicionamento: residuosProdutos.acondicionamento,
                        descricao: residuosProdutos.descricao,
                    });
                    this.idProdutos++;
                })
                this.dataSourceProdutos = new MatTableDataSource(this.produtosSalvos);
                this.enviarProdutosResiduos(this.mapearArray());
            }, error => {
                this.snackBarService.showError("Ocorreu um erro ao buscar a lista de produtos/residuos. Cod. Erro: " + error.status);
            })
        }
        if (this.isNaoPodeEditar) {
            this.formProdutos.disable();
        }
    }

    private buildForm(): void {
        this.formProdutos = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            classeRisco: ["", [Validators.required]],
            nomeResiduo: ["", [Validators.required]],
            qtdAnual: ["", [Validators.required]],
            unidadeMedida: ["", [Validators.required]],
            acondicionamento: ["", [Validators.required]],
            descricao: ["", [Validators.required]],
        })

        this.formProdutos.controls.descricao.disable();
    }

    mapearArray(): any{
        if(this.produtosSalvos.length == 0){
            return this.produtosSalvos;
        }else{
            return this.produtosSalvos.map(function(item){
                return new TransporteResiduosPerigosos(
                    item.id,
                    null,
                    item.classeRisco,
                    item.nomeResiduo,
                    Number(item.qtdAnual),
                    item.unidadeMedida,
                    item.acondicionamento,
                    item.descricao,
                );
            });
        }
    }

    enviarProdutosResiduos(produtos){
        this.enviarProdutos.emit(produtos);
    }

    adicionarProdutoResiduo(){
        if(this.formProdutos.valid){
            this.produtosSalvos.push({
                id: null,
                idTemp: this.idProdutos,
                classeRisco: this.formProdutos.controls.classeRisco.value,
                nomeResiduo: this.formProdutos.controls.nomeResiduo.value,
                qtdAnual: this.formProdutos.controls.qtdAnual.value,
                unidadeMedida: this.formProdutos.controls.unidadeMedida.value,
                acondicionamento: this.formProdutos.controls.acondicionamento.value,
                descricao: this.formProdutos.controls.descricao.value,
            })
            this.idProdutos++;
            this.dataSourceProdutos = new MatTableDataSource(this.produtosSalvos);
            this.enviarProdutosResiduos(this.mapearArray());
            this.formProdutos.reset();
            //resetando campo descricao
            this.onChangeAcondicionamento('resetar');
        }else {
            this.formProdutos.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    ativarModoEdicao(item){
        this.formProdutos.controls.id.patchValue(item.id);
        this.formProdutos.controls.idTemp.patchValue(item.idTemp);
        this.formProdutos.controls.classeRisco.patchValue(item.classeRisco);
        this.formProdutos.controls.nomeResiduo.patchValue(item.nomeResiduo);
        this.formProdutos.controls.qtdAnual.patchValue(item.qtdAnual);
        this.formProdutos.controls.unidadeMedida.patchValue(item.unidadeMedida);
        this.formProdutos.controls.acondicionamento.patchValue(item.acondicionamento);
        this.onChangeAcondicionamento(item.acondicionamento);
        this.formProdutos.controls.descricao.patchValue(item.descricao);
        this.modoEdicao = true;
    }

    fecharEdicao(){
        this.modoEdicao = false;
        this.formProdutos.reset();
    }

    editarProdutoResiduo(){
        if(this.formProdutos.valid){
            this.deletarProduto(this.formProdutos.controls.idTemp.value);

            this.produtosSalvos.push({
                id: this.formProdutos.controls.id.value,
                idTemp: this.formProdutos.controls.idTemp.value,
                classeRisco: this.formProdutos.controls.classeRisco.value,
                nomeResiduo: this.formProdutos.controls.nomeResiduo.value,
                qtdAnual: this.formProdutos.controls.qtdAnual.value,
                unidadeMedida: this.formProdutos.controls.unidadeMedida.value,
                acondicionamento: this.formProdutos.controls.acondicionamento.value,
                descricao: this.formProdutos.controls.descricao.value,
            })
            this.dataSourceProdutos = new MatTableDataSource(this.produtosSalvos);
            this.enviarProdutosResiduos(this.mapearArray());
            this.formProdutos.reset();
            //resetando campo descricao
            this.onChangeAcondicionamento('resetar');
            this.modoEdicao = false;
        }else {
            this.formProdutos.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    deletarProduto(item, acao?: string, itemId?: number){
        if(acao == 'DELETAR' && itemId != null){
            this.serviceTransporte.deletarResiduosProdutosPorId(itemId).subscribe( response => {
                this.snackBarService.showSuccess("Residuo/Produto excluido com sucesso.")
            })
        }
        let data;
        data = this.produtosSalvos.filter((value, key) => {
            return value.idTemp != item;
        });
        this.produtosSalvos = data;
        this.dataSourceProdutos = new MatTableDataSource(data);
        this.enviarProdutosResiduos(this.mapearArray());
    }

    onChangeAcondicionamento(value){
        if(value == 'Outro'){
            this.mostrarDescricaoProduto = true;
            this.formProdutos.controls.descricao.enable();
        }else{
            this.mostrarDescricaoProduto = false;
            this.formProdutos.controls.descricao.patchValue('');
            this.formProdutos.controls.descricao.disable();
        }
    }
}
