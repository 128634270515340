import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MatRadioChange} from "@angular/material/radio";

@Component({
    selector: 'app-formas-operacao',
    templateUrl: './formas-operacao.component.html',
    styleUrls: ['./formas-operacao.component.scss']
})
export class FormasOperacaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;

    possuiSubestacaoOperacaoPresencial: FormControl;
    possuiSubEstacaoSupervisionada: FormControl;

    constructor() {
    }

    preencherFormaOperacao(event: MatRadioChange) {
            this.possuiSubEstacaoSupervisionada.setValue(!event.value);
    }

    ngOnInit(): void {
        this.possuiSubestacaoOperacaoPresencial = this.form.get('possuiSubestacaoOperacaoPresencial') as FormControl;
        this.possuiSubEstacaoSupervisionada = this.form.get('possuiSubEstacaoSupervisionada') as FormControl;

        if (this.possuiSubestacaoOperacaoPresencial.value !== undefined) {
            this.possuiSubEstacaoSupervisionada.setValue(!this.possuiSubestacaoOperacaoPresencial.value);
        }
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }
}
