import {Component, Input,  OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CKEditor4} from 'ckeditor4-angular';

@Component({
    selector: 'app-editor-texto',
    templateUrl: './editor-texto.component.html',
    styleUrls: ['editor-text.component.scss']
})
export class EditorTextoComponent implements OnInit {

    @Input()
    titulo: string;

    @Input()
    lengthLimit: number;

    @Input()
    form: FormGroup;

    @Input()
    controlName: string;

    @Input()
    config: any;

    @Input()
    readOnly = false;

    @Input()
    data: any;

    editor: FormControl;

    ngOnInit(): void {
        this.editor = this.form.get(this.controlName) as FormControl;
    }

    public onChange( event: CKEditor4.EventInfo ) {
        this.editor.setValue( event.editor.getData() );
        this.editor.markAsDirty();
    }

    public onFocus() {
        this.editor.markAsTouched();
    }

    formControlHasErrorRequired(): boolean {
        const field = this.form.controls[this.controlName];
        return field.hasError('required') && field.touched;
    }
}
