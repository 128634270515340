import {BaseModel} from 'app/main/shared/models/base.model';
import {ArquivoSimcarDTO} from './arquivo-simcar-dto.model';
import {BaseEnum} from '../enums/base.enum';


export class RequerimentoArquivoSimcarDTO extends BaseModel {
    constructor(
        public id?: number,
        public arquivo?: ArquivoSimcarDTO,
        public categoria?: string,
        public descricao?: string,
    ) {
        super();
    }

    static categorias: BaseEnum[] = [
        {valor: 'REQUERIMENTO_JUSTIFICATIVA', descricao: 'Justificativa'},
        {valor: 'REQUERIMENTO_INTERESSADO', descricao: 'Interessado'},
        {valor: 'REQUERIMENTO_RESPONSAVEL_TECNICO', descricao: 'Responsável Técnico'},
        {valor: 'REQUERIMENTO_CARACTERIZACAO', descricao: 'Caracterização'},
        {valor: 'REQUERIMENTO_PROPRIEDADE', descricao: 'Propriedade'},
        {valor: 'REQUERIMENTO_DOMINIALIDADE', descricao: 'Dominialidade'},
        {valor: 'ALTERAR_URGENCIA', descricao: 'Alterar urgência'},
        {valor: 'ARQUIVO_INTERNO', descricao: 'Arquivo interno'},
        {valor: 'CANCELAMENTO', descricao: 'Cancelamento'},
        {valor: 'INDEFERIMENTO', descricao: 'Indeferimento'},
        {valor: 'REVERTER_CANCELAMENTO', descricao: 'Reverter cancelamento'}
    ];

    static descricoes: BaseEnum[] = [
        {valor: 'CPF', descricao: 'CPF'},
        {valor: 'COMPROVANTE_PAGAMENTO', descricao: 'Comprovante de pagamento'},
        {valor: 'TPCR', descricao: 'TPCR'},
        {valor: 'CANU', descricao: 'CANU'},
        {valor: 'LOCP', descricao: 'LOCP'},
        {valor: 'RG', descricao: 'RG'},
        {valor: 'TRDO', descricao: 'TRDO'},
        {valor: 'CCVE', descricao: 'CCVE'},
        {valor: 'DSRU', descricao: 'DSRU'},
        {valor: 'TDRF', descricao: 'TDRF'},
        {valor: 'TRAT', descricao: 'TRAT'},
        {valor: 'CROQUI_PDF_CAR_PARECER', descricao: 'CROQUI PDF CAR Parecer'},
        {valor: 'OUTRO', descricao: 'Outro'},
        {valor: 'CREG', descricao: 'CREG'},
        {valor: 'CTAF', descricao: 'CTAF'},
        {valor: 'ARQUIVO_PROCESSADO', descricao: 'Arquivo processado'},
        {valor: 'CROQUI_PDF_CAR_EMITIDO', descricao: 'CROQUI PDF CAR Emitido'},
        {valor: 'ESCR', descricao: 'ESCR'},
        {valor: 'CAOF', descricao: 'CAOF'},
        {valor: 'AOCP', descricao: 'AOCP'},
        {valor: 'IMPO', descricao: 'IMPO'},
        {valor: 'CROQUI_PDF_CAR_INSCRICAO', descricao: 'CROQUI PDF CAR Inscrição'},
        {valor: 'DOC_EMITIDO_ORGAO_CLASSE', descricao: 'DOC Emitido Orgão Classe'},
        {valor: 'CCTP', descricao: 'CCTP'},
        {valor: 'COPIA_PROC_PUBLICA', descricao: 'Cópia Procuração Pública'},
        {valor: 'COMP_LOCALIZACAO_ESTABELECIMENTO', descricao: 'COMP Localização Estabelecimento'},
        {valor: 'ADEC', descricao: 'ADEC'},
        {valor: 'TDOF', descricao: 'TDOF'},
        {valor: 'TDRR', descricao: 'TDRR'},
        {valor: 'BASE_REFERENCIA', descricao: 'Base de Referência'},
        {valor: 'CRDU', descricao: 'CRDU'},
        {valor: 'DAMU', descricao: 'DAMU'},
        {valor: 'TDOM', descricao: 'TDOM'},
        {valor: 'EREG', descricao: 'EREG'},
        {valor: 'CERT_JUNTA_COMERCIAL', descricao: 'CERT Junta Comercial'},
        {valor: 'TDOA', descricao: 'TDOA'},
        {valor: 'CATP', descricao: 'CATP'},
        {valor: 'CNPJ', descricao: 'CNPJ'},
        {valor: 'ART_PROJETO', descricao: 'ART Projeto'},
        {valor: 'CPCV', descricao: 'CPCV'},
        {valor: 'CCDP', descricao: 'CCDP'},
        {valor: 'DCON', descricao: 'DCON'}
    ];

    static fromJson(json: any): RequerimentoArquivoSimcarDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoArquivoSimcarDTO(
            json.id,
            ArquivoSimcarDTO.fromJson(json.arquivo),
            json.categoria,
            json.descricao
        );
    }

    static fromJsons(json: any): RequerimentoArquivoSimcarDTO[] {
        const array: RequerimentoArquivoSimcarDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(RequerimentoArquivoSimcarDTO.fromJson(item)));
            return array;
        }
    }
}
