import {BaseModel} from '../models/base.model';
import {Resolve} from '@angular/router';
import {Page} from '../models/page.model';
import {Observable} from 'rxjs';
import {BaseService} from '../services/base.service';


export class ListResolver<T extends BaseModel> implements Resolve<Page<T>> {
    constructor(
        private service: BaseService<T>
    ) {
    }

    resolve(): Observable<Page<T>> {
        return this.service.getPage();
    }
}