import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {Template} from "./template.model";
import {Observable} from "rxjs/internal/Observable";
import {BaseEnum} from "../../shared/enums/base.enum";
import {catchError, map} from "rxjs/operators";
import { TiposTemplate } from 'app/main/shared/models/documentos-disponiveis-publicacao-dto.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class TemplateListResolver extends ListResolver<Template> {
    constructor(service: TemplateService) {
        super(service);
    }
}

@Injectable()
export class TemplateResolver extends DetailResolver<Template> {
    constructor(service: TemplateService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class TemplateService extends BaseService<Template> {
    constructor(protected injector: Injector) {
        super('/api/templates', injector, Template.prototype, Template.fromJson);
    }

    getNomesTemplates(): Observable<BaseEnum[]> {
        return this.http.get(`${this.urlResource}/nomes-template`).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    listAllTemplates(): Observable<any[]> {
        return this.http.get(`${this.urlResource}`).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getAllByTitulo(tipoTemplate?: TiposTemplate): Observable<any[]> {
        return this.http.get(`${this.urlResource}/tipo-template/${tipoTemplate}/list`).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getHtmlPorTemplate(idTemplate?: number): Observable<any> {
        return this.http.get(`${this.urlResource}/retorna-html-template/${idTemplate}`).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}