import {BaseModel} from "./base.model";
import {Requerimento} from "../../pages/requerimentos/requerimento.model";

export class Acai extends BaseModel {

    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public cpfCnpjDetentor?: string,
        public intervencaoArea?: boolean,
    ) {
        super();
    }

    static fromJson(json: any): Acai {
        if (json === undefined || json === null) {
            return null;
        }
        return new Acai(
            json.id,
            json.requerimento,
            json.cpfCnpjDetentor,
            json.intervencaoArea,
        );
    }

}