import {Injectable, Injector} from '@angular/core';
import {Tarefa} from "../models/tarefa.model";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/internal/operators";

@Injectable({
    providedIn: 'root'
})
export class TarefaService extends BaseService<Tarefa> {

    constructor(
        protected injector: Injector
    ) {
        super('/api/tarefas', injector, Tarefa.prototype, Tarefa.fromJson);
    }

    getByProcessId(processId: string): Observable<Tarefa[]> {
        return this.http.get(`${this.urlResource}/process/${processId}`).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getByRequerimentoId(requerimentoId: number): Observable<Tarefa[]> {
        return this.http.get(`${this.urlResource}/requerimento/${requerimentoId}`).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }
}