import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { IMESSAGE, ISEVERITY } from 'app/main/core/services/core-message.service';
import { ReqTituloVinculadoService } from 'app/main/pages/tarefas/shared/service/req-titulo-vinculado.service';
import { MessageDialog } from 'app/main/shared/components/message-dialog/message-dialog.model';
import { ModeloFeicao } from 'app/main/shared/enums/modelo-feicao.enum';
import { take } from "rxjs/operators";
import { Acai } from "../../../../../../../shared/models/acai.model";
import { Pessoa } from "../../../../../../../shared/models/pessoa.model";
import { PessoaCorporativoService } from "../../../../../../../shared/services/pessoa-corporativo.service";
import { SnackBarService } from "../../../../../../../shared/snack-bar/snack-bar.service";
import { FormUtil } from '../../../../../../../shared/util/form-util';
import { RequerimentoLicenciamento } from "../../../../requerimento-licenciamento.model";
import { RequerimentoLicenciamentoService } from "../../../../requerimento-licenciamento.service";
import { BaseAtividadeEmpreendimentoComponent } from "../base-atividade-empreendimento.component";
import { AcaiService } from "./acai.service";

@Component({
    selector: 'app-acai',
    templateUrl: './acai.component.html',
    styleUrls: ['./acai.component.scss']
})
export class AcaiComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    requerimentoLicenciamento: RequerimentoLicenciamento;
    cpfCnpjDetentorControl = new FormControl('', [Validators.required]);
    maskInputConsulta = '000.000.000-009';
    dataSourceAreasFeicao = new MatTableDataSource<any>([]);
    detentor: Pessoa;
    acai: Acai;
    inventario: any;
    areaIntervencaoControl = new FormControl('', [Validators.required]);

    constructor(
        private snackBarService: SnackBarService,
        private pessoaCorporativoservice: PessoaCorporativoService,
        private acaiService: AcaiService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private messageDialog: MessageDialog,
        private reqTituloVinculadoService: ReqTituloVinculadoService,
    ) {
        super();
    }

    async ngOnInit() {
        await this.carregarQuadroDeAreas();
        await this.carregarAcai();

        this.cpfCnpjDetentorControl.valueChanges.subscribe(() => {
            this.maskInputConsulta = (this.cpfCnpjDetentorControl.value && this.cpfCnpjDetentorControl.value.length > 11)
                ? '00.000.000/0000-00' : '000.000.000-009';
        });
    }

    pesquisarDetentor(): void {
        this.cpfCnpjDetentorControl.markAllAsTouched();
        if (this.cpfCnpjDetentorControl.valid) {
            this.pessoaCorporativoservice.getByCpfCnpj(this.cpfCnpjDetentorControl.value).pipe(
                take(1)
            ).subscribe(pessoa => this.detentor = pessoa,
                error => this.snackBarService.showError('Pessoa não localizada no cadastro de pessoas do SIGA. ' +
                    'Verifique o CPF/CNPJ informado ou faça o seu cadastro no SIGA.'));
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    async carregarQuadroDeAreas(): Promise<void> {
        this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
        let result = await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();
        if (result.length > 0) {
            this.dataSourceAreasFeicao = new MatTableDataSource(result);
        }
    }

    async carregarAcai(): Promise<void> {
        this.acai = await this.acaiService.buscarPorIdRequerimento(this.requerimento.id).toPromise().catch(() => new Acai());
        if (this.acai && this.acai.id) {
            this.cpfCnpjDetentorControl.patchValue(this.acai.cpfCnpjDetentor);
            this.areaIntervencaoControl.patchValue(this.acai.intervencaoArea);
            this.pesquisarDetentor();
        } else {
            this.acai = new Acai();
        }
    }

    salvar() {

        this.acai.cpfCnpjDetentor = this.cpfCnpjDetentorControl.value;
        this.acai.intervencaoArea = this.areaIntervencaoControl.value;
        this.acai.requerimento = this.requerimento;

        this.validar(false).then((msgsValidacao) => {
            if(msgsValidacao.filter((m)=>m.restritiva).length == 0){
            
                if (msgsValidacao.filter((m)=>!m.restritiva).length > 0) {
                    this.messageDialog.showWarning({ errors: msgsValidacao.filter((m)=>!m.restritiva).map((m)=>m.message), message: 'Atenção' }, 'SIGA');
                }

                if (!this.acai.id) {
                    this.acaiService.create(this.acai).subscribe(() => {
                        this.snackBarService.showSuccess("Formulário salvo com sucesso.");
                        this.enviarFormConcluido.emit();
                    }, () => this.snackBarService.showError("Ocorreu um erro no servidor."));
                } else {
                    this.acaiService.update(this.acai).subscribe(() => {
                        this.snackBarService.showSuccess("Formulário salvo com sucesso.");
                        this.enviarFormConcluido.emit();
                    }, () => this.snackBarService.showError("Ocorreu um erro no servidor."));
                }
                
            } else {
                this.snackBarService.showAlert(msgsValidacao.filter((m)=>m.restritiva).map((m)=>m.message).join('\n'));
            }
        }).catch((r) => this.snackBarService.showError(r));
        
    }

    formatarCoordenadaPonto(wktString: string): any {
        const regex = /POINT \(([^)]+)\)/;
        const matches = wktString.match(regex);

        if (matches) {
            const coordinates = matches[1].split(' ');
            const longitude = parseFloat(coordinates[0]);
            const latitude = parseFloat(coordinates[1]);
            return FormUtil.convertDEGToDMS(longitude.toString(), false).toString() + "  " + FormUtil.convertDEGToDMS(latitude.toString(), true).toString()
        } else {
            return null;
        }
    }

    async validar(restrict: boolean = false): Promise<IMESSAGE[]> {
        let msgs: IMESSAGE[] = [];

        if (!this.acai.cpfCnpjDetentor ) {
            msgs.push({ message: "Preencha todos os campos obrigatórios.", restritiva: true, severity: ISEVERITY.ERROR });
        }
        if (!this.acai.intervencaoArea ) {
            msgs.push({ message: "Preencha todos os campos obrigatórios.", restritiva: true, severity: ISEVERITY.ERROR });
        }
        
        // Houve intervencao
        // Validar RN043  -- Feicoes quadroArea
        if (!!this.acai.intervencaoArea) {
            if (this.dataSourceAreasFeicao != null && this.dataSourceAreasFeicao.data != null && this.dataSourceAreasFeicao.data.length > 0 ) {
                await this.validarCenario03(msgs, this.dataSourceAreasFeicao.data, restrict);
            } else await this.validarCenario03(msgs, null, restrict);
        }

        return Promise.resolve(msgs);
    }


    
    async validarCenario03(msgs: IMESSAGE[], quadroAreas: any[], restrict: boolean = false) {
        // Cenario 3
        let tituloVinculadoList =  await this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, null).toPromise().catch((e) => { return null; })
        if (tituloVinculadoList && tituloVinculadoList.length > 0) {
                //TODO: validar titulos anteriores?
        } else {
            if (quadroAreas == null) {
                msgs.push({ message: 'Quadro de Áreas não preenchido [Feições]', restritiva: restrict, severity: ISEVERITY.ERROR });
            }
            //Tarefa 11227 - remover validação de obrigatoriedade de dinamica de desmate
            // else {
            //     msgs.push({ message: 'Se houve anteriormente uma intervenção na área é obrigatório o envio da Dinâmica de Desmate nos dados geográficos da quarta aba do Requerimento.',
            //     restritiva: restrict, severity: ISEVERITY.WARN });
            // }
            

        }

        return Promise.resolve(msgs);
    }


}