import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {EmpreendimentoObrasViarias} from "../../../../../../../shared/models/empreendimento-obras-viarias.model";
import {InfraestruturaObrasViariasDto} from "../../../../../../../shared/models/infraestrutura-obras-viarias-dto.model";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {InfraestruturaObrasViariasService} from "./infraestrutura-obras-viarias.service";
import {
    TipoPavimentacaoEmpObrasViarias
} from "../../../../../../../shared/models/tipo-pavimentacao-emp-obras-viarias.model";
import {CategoriaEmpObrasViarias} from "../../../../../../../shared/models/categoria-emp-obras-viarias.model";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {
    RequerimentoFormularioLicenciamentoService
} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-infraestrutura-obras-viarias',
    templateUrl: './infraestrutura-obras-viarias.component.html',
    styleUrls: ['./infraestrutura-obras-viarias.component.scss']
})
export class InfraestruturaObrasViariasComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    idForm: number;
    updateId: boolean = false;
    tiposObra: any[] = [];
    rodovias: any[] = [];
    obrasEspeciais: any[] = [];
    listaPavimentacaoEscolhida: any[] = [];
    listaCategoriasEscolhidas: any[] = [];

    formularioSalvo: EmpreendimentoObrasViarias;
    formObrasViarias: FormGroup;
    mostrarQuantidadeCorposHidricos: boolean = false;
    mostrarAgrupamentoObrasEspeciais: boolean = false;

    isNaoPodeEditar: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private dominioService: DominiosService,
                private snackBarService: SnackBarService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
                private infraObrasViarias: InfraestruturaObrasViariasService) {
        super();
    }

    async ngOnInit() {
        this.buildFormObrasViarias();

        if (this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarInfraestruturaObrasViarias(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarInfraestruturaObrasViarias(this.requerimento.id)
            }

            if (this.idForm) {
                await this.infraObrasViarias.buscarTipoPavimentacaoPorId(this.idForm).subscribe(response => {
                    response.forEach(tipoPavimentacao => {
                        if (tipoPavimentacao.tipo == 'Concreto') {
                            this.formObrasViarias.controls.concretoTipoPavimentacao.patchValue(true);
                            this.alteracaoTipoPavimentacao(true, tipoPavimentacao.tipo, tipoPavimentacao)
                        }
                        if (tipoPavimentacao.tipo == 'Asfalto') {
                            this.formObrasViarias.controls.asfaltoTipoPavimentacao.patchValue(true);
                            this.alteracaoTipoPavimentacao(true, tipoPavimentacao.tipo, tipoPavimentacao)
                        }
                        if (tipoPavimentacao.tipo == 'Blocos') {
                            this.formObrasViarias.controls.blocosTipoPavimentacao.patchValue(true);
                            this.alteracaoTipoPavimentacao(true, tipoPavimentacao.tipo, tipoPavimentacao)
                        }
                        if (tipoPavimentacao.tipo == 'Pedras') {
                            this.formObrasViarias.controls.pedrasTipoPavimentacao.patchValue(true);
                            this.alteracaoTipoPavimentacao(true, tipoPavimentacao.tipo, tipoPavimentacao)
                        }
                        if (tipoPavimentacao.tipo == 'Revestimento primário') {
                            this.formObrasViarias.controls.revestimentoTipoPavimentacao.patchValue(true);
                            this.alteracaoTipoPavimentacao(true, tipoPavimentacao.tipo, tipoPavimentacao)
                        }
                    });
                })

                await this.infraObrasViarias.buscarCategoriaPorId(this.idForm).subscribe(response => {
                    response.forEach(item => {
                        if (item.categoria == 'Federal') {
                            this.formObrasViarias.controls.categoriaFederal.patchValue(true);
                            this.alterarCategoria(true, item.categoria, item)
                        }
                        if (item.categoria == 'Estadual') {
                            this.formObrasViarias.controls.categoriaEstadual.patchValue(true);
                            this.alterarCategoria(true, item.categoria, item)
                        }
                        if (item.categoria == 'Municipal') {
                            this.formObrasViarias.controls.categoriaMunicipal.patchValue(true);
                            this.alterarCategoria(true, item.categoria, item)
                        }
                    });
                })
            }
        }

        if (this.isModoVisualizacao) {
            this.formObrasViarias.disable()
        }

        //carregando componentes filhos após id do formulario ser atualizado
        this.updateId = true;

        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.formObrasViarias.disable();
        }
    }

    async carregarInfraestruturaObrasViarias(idRequerimento) {
        await this.infraObrasViarias.buscarPorIdRequerimento(idRequerimento).toPromise().then(response => {
            if (response) {
                this.idForm = response.id;
                this.formularioSalvo = response;
                this.formObrasViarias.controls.id.patchValue(this.formularioSalvo.id);
                this.formObrasViarias.controls.denominacaoObra.patchValue(this.formularioSalvo.denominacaoObra);
                this.formObrasViarias.controls.extensaoKm.patchValue(this.formularioSalvo.extensaoKm);
                this.formObrasViarias.controls.numerosFaixasRolamento.patchValue(this.formularioSalvo.numeroFaixasRolamento);
                this.formObrasViarias.controls.numeroPistas.patchValue(this.formularioSalvo.numeroPistas);
                this.formObrasViarias.controls.pavimentacaoExistente.patchValue(this.formularioSalvo.extensaoPavimentacaoExistente);
                this.formObrasViarias.controls.pavimentacaoNovos.patchValue(this.formularioSalvo.extensaoPavimentacaoNovosTrechos);
                this.formObrasViarias.controls.larguraFaixas.patchValue(this.formularioSalvo.larguraFaixaDominio);
                this.formObrasViarias.controls.haveraDesapropriacao.patchValue(this.formularioSalvo.haveraDesapropriacao == true ? "sim" : "nao");
                this.formObrasViarias.controls.cemiterioDominio.patchValue(this.formularioSalvo.possuiCemiterioFaixaDominio == true ? "sim" : "nao");
                this.formObrasViarias.controls.areaUrbana.patchValue(this.formularioSalvo.interfereAreaUrbana == true ? "sim" : "nao");
                this.formObrasViarias.controls.anelViario.patchValue(this.formularioSalvo.possuiAnelViario == true ? "sim" : "nao");
                this.formObrasViarias.controls.corredorFauna.patchValue(this.formularioSalvo.interfereCorredorFauna == true ? 'sim' : 'nao');
                this.formObrasViarias.controls.corposHidricos.patchValue(this.formularioSalvo.transposicaoCorposHidricos == true ? 'sim' : 'nao');
                this.changeCorposHidricos(this.formObrasViarias.controls.corposHidricos.value);
                this.formObrasViarias.controls.qtdCorposHidricos.patchValue(this.formularioSalvo.qtdCorposHidricos);
                this.formObrasViarias.controls.areaBensTombados.patchValue(this.formularioSalvo.interfereBensTombados == true ? 'sim' : 'nao');
                this.formObrasViarias.controls.areaAntropizada.patchValue(this.formularioSalvo.areaAntropizada == true ? 'sim' : 'nao');
                this.formObrasViarias.controls.haveraObrasEspeciais.patchValue(this.formularioSalvo.haveraObrasArteEspeciais == true ? 'sim' : 'nao');
                this.changeObrasEspeciais(this.formObrasViarias.controls.haveraObrasEspeciais.value);
            }
        })
    }

    private buildFormObrasViarias(): void {
        this.formObrasViarias = this.formBuilder.group({
            id: [null],
            denominacaoObra: ["", [Validators.required]],
            extensaoKm: ["", [Validators.pattern("^[0-9]*$")]],
            numeroPistas: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
            numerosFaixasRolamento: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
            pavimentacaoExistente: ["", [Validators.pattern("^[0-9]*$")]],
            pavimentacaoNovos: ["", [Validators.pattern("^[0-9]*$")]],
            larguraFaixas: ["", [Validators.pattern("^[0-9]*$")]],
            haveraDesapropriacao: ["", [Validators.required]],
            cemiterioDominio: ["", [Validators.required]],
            areaUrbana: ["", [Validators.required]],
            anelViario: ["", [Validators.required]],
            corredorFauna: ["", [Validators.required]],
            corposHidricos: ["", [Validators.required]],
            qtdCorposHidricos: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
            // categoria: ["", [Validators.required]],
            areaBensTombados: ["", [Validators.required]],
            areaAntropizada: ["", [Validators.required]],
            haveraObrasEspeciais: ["", [Validators.required]],
            concretoTipoPavimentacao: [""],
            asfaltoTipoPavimentacao: [""],
            blocosTipoPavimentacao: [""],
            pedrasTipoPavimentacao: [""],
            categoriaFederal: [""],
            categoriaEstadual: [""],
            categoriaMunicipal: [""],
            revestimentoTipoPavimentacao: [""],
        })
        this.formObrasViarias.controls.qtdCorposHidricos.disable();
    }

    mapearObjetoObrasViarias(): any {
        return new EmpreendimentoObrasViarias(
            this.formObrasViarias.controls.id.value,
            this.requerimento.id,
            this.formObrasViarias.controls.denominacaoObra.value,
            Number(this.formObrasViarias.controls.extensaoKm.value),
            Number(this.formObrasViarias.controls.numeroPistas.value),
            Number(this.formObrasViarias.controls.numerosFaixasRolamento.value),
            Number(this.formObrasViarias.controls.pavimentacaoExistente.value),
            Number(this.formObrasViarias.controls.pavimentacaoNovos.value),
            Number(this.formObrasViarias.controls.larguraFaixas.value),
            this.formObrasViarias.controls.haveraDesapropriacao.value == 'sim',
            this.formObrasViarias.controls.cemiterioDominio.value == 'sim',
            this.formObrasViarias.controls.areaUrbana.value == 'sim',
            this.formObrasViarias.controls.anelViario.value == 'sim',
            this.formObrasViarias.controls.corredorFauna.value == 'sim',
            this.formObrasViarias.controls.corposHidricos.value == 'sim',
            Number(this.formObrasViarias.controls.qtdCorposHidricos.value),
            this.formObrasViarias.controls.areaBensTombados.value == 'sim',
            this.formObrasViarias.controls.areaAntropizada.value == 'sim',
            this.formObrasViarias.controls.haveraObrasEspeciais.value == 'sim',
        );
    }

    receberTiposObra(tipos: any[]) {
        this.tiposObra = tipos;
    }

    receberRodovias(rodovias: any[]) {
        this.rodovias = rodovias;
    }

    receberObrasEspeciais(obrasEspeciais: any[]) {
        this.obrasEspeciais = obrasEspeciais;
    }

    alteracaoTipoPavimentacao(event: any, tipoPavimentacao: string, object?: any) {
        if (event) {
            if (object) {
                this.listaPavimentacaoEscolhida.push(object);
            } else {
                this.listaPavimentacaoEscolhida.push(new TipoPavimentacaoEmpObrasViarias(
                    null,
                    null,
                    tipoPavimentacao,
                ))
            }
        } else {
            let data;
            data = this.listaPavimentacaoEscolhida.filter((value, key) => {
                if (value.tipo == tipoPavimentacao) {
                    if (value.id != null) {
                        this.infraObrasViarias.deletarTipoPavimentacaoPorId(value.id).subscribe(response => {
                            this.snackBarService.showSuccess("Tipo pavimentação excluido.");
                        })
                    }
                }
                return value.tipo != tipoPavimentacao;
            });
            this.listaPavimentacaoEscolhida = data;
        }
    }

    alterarCategoria(event: any, categoria: string, object?: any) {
        if (event) {
            if (object) {
                this.listaCategoriasEscolhidas.push(object);
            } else {
                this.listaCategoriasEscolhidas.push(new CategoriaEmpObrasViarias(
                    null,
                    null,
                    categoria,
                ))
            }
        } else {
            let data;
            data = this.listaCategoriasEscolhidas.filter((value, key) => {
                if (value.categoria == categoria) {
                    if (value.id != null) {
                        this.infraObrasViarias.deletarCategoriaPorId(value.id).subscribe(response => {
                            this.snackBarService.showSuccess("Categoria excluida.");
                        })
                    }
                }
                return value.categoria != categoria;
            });
            this.listaCategoriasEscolhidas = data;
        }
    }

    changeObrasEspeciais(obrasEspeciais) {
        if (obrasEspeciais == "sim") {
            this.mostrarAgrupamentoObrasEspeciais = true;
        } else {
            this.mostrarAgrupamentoObrasEspeciais = false;
            this.obrasEspeciais = [];
        }
    }

    changeCorposHidricos(corposHidricos) {
        if (corposHidricos == "sim") {
            this.mostrarQuantidadeCorposHidricos = true;
            this.formObrasViarias.controls.qtdCorposHidricos.enable();
        } else {
            this.mostrarQuantidadeCorposHidricos = false;
            this.formObrasViarias.controls.qtdCorposHidricos.disable();
            this.formObrasViarias.controls.qtdCorposHidricos.reset();
        }
    }

    salvar() {
        if (this.tiposObra.length > 0) {
            if (this.formObrasViarias.valid) {
                const obrasViariasDto = new InfraestruturaObrasViariasDto(
                    this.mapearObjetoObrasViarias(),
                    this.rodovias,
                    this.tiposObra,
                    this.obrasEspeciais,
                    this.listaPavimentacaoEscolhida,
                    this.listaCategoriasEscolhidas
                );
                this.infraObrasViarias.salvarObraViaria(obrasViariasDto).subscribe(() => {
                    this.enviarFormConcluido.emit();
                    this.snackBarService.showSuccess("Formulário salvo.")
                });
            } else {
                this.snackBarService.showAlert(" Campo(s) obrigatório(s) não preenchido(s)." +
                    " Favor realize o preenchimento do(s) mesmo(s).")
                this.formObrasViarias.markAllAsTouched();
            }
        } else {
            this.snackBarService.showAlert("Insira pelo menos um tipo de obra.")
        }
    }
}