import {BaseModel} from "../base.model";

export class TituloParametrizacaoObjetivo extends BaseModel {
    constructor(
        public id?: number,
        public idParametroObjetivo?: number,
        public idTituloRequerido?: number,
        public isEmitido?: boolean
    ) {
        super();
    }

    static fromJson(json: any): TituloParametrizacaoObjetivo {
        if (json === undefined || json === null) {
            return null;
        }
        return new TituloParametrizacaoObjetivo(
            json.id,
            json.idParametroObjetivo,
            json.idTituloRequerido,
            json.isEmitido
        );
    }

    static fromJsons(json: any): TituloParametrizacaoObjetivo[] {
        const titulos: TituloParametrizacaoObjetivo[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(titulo => titulos.push(this.fromJson(titulo)));
            return titulos;
        }
    }

}
