import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GeometriaMapa} from '@sema-geo/sema-geoportal';
import Map from 'ol';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Style, Stroke, Fill, Text, Circle } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

import {
    GeoportalBaseReferenciaService
} from "../../../../../shared/services/geoportal-base-referencia/geoportal-base-referencia.service";
import {Observable} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {minArrayLength} from "../../../../../shared/validators/array.validator";
import {
    AnaliseFeicaoRequerimentoLicenciamentoService
} from "../../../../../shared/services/analise-feicao-requerimento-licenciamento.service";
import {
    InconformidadeFeicaoRequerimentoLicenciamento
} from "../../../../../shared/models/inconformidade-feicao-requerimento-licenciamento.model";

export interface  DialogVisualizarIncoformidadesGeometria {
    geometria: GeometriaMapa;
}

@Component({
    selector: 'app-bpm-dialog-visualizar-incoformidades-geometria',
    templateUrl: './dialog-visualizar-incoformidades-geometria.component.html',
    styleUrls: ['./dialog-visualizar-incoformidades-geometria.component.scss'],
})
export class DialogVisualizarIncoformidadesGeometriaComponent {
    geometria: GeometriaMapa;
    area$: Observable<string>;
    map: Map;
    vectorSource: VectorSource;
    reprovarGeometriaForm: FormGroup;
    constructor(
        private readonly fb: FormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private snackBarService: SnackBarService,
        private dialogRef: MatDialogRef<DialogVisualizarIncoformidadesGeometriaComponent>,
        private readonly baseReferenciaService: GeoportalBaseReferenciaService,
        private readonly analiseFeicaoRequerimentoLicenciamentoService: AnaliseFeicaoRequerimentoLicenciamentoService,
        @Inject(MAT_DIALOG_DATA) private data: DialogVisualizarIncoformidadesGeometria
    ) {
        this.geometria = data.geometria;
        this.area$ = this.baseReferenciaService.getArea(this.geometria.wkt);
        this.reprovarGeometriaForm = this.fb.group({
            inconformidades: this.fb.array([], { validators: [minArrayLength(1)] })
        })
    }
    get inconformidades(): FormArray {
        return this.reprovarGeometriaForm.get('inconformidades') as FormArray;
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onMapReady(map: Map){
        this.vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            source: this.vectorSource
        });
        this.map = map;
        this.map.addLayer(vectorLayer);

        if(this.geometria.extra.inconformidades && this.geometria.extra.inconformidades.length > 0){
            this.geometria.extra.inconformidades.forEach((inconformidade: InconformidadeFeicaoRequerimentoLicenciamento) => {
                this.addPoint(
                    inconformidade.id,
                    [inconformidade.longitude, inconformidade.latitude],
                    'Inconformidade ' + inconformidade.id,
                    inconformidade.justificativa
                );
            })
        }
        this.reprovarGeometriaForm.disable();
    }
    addPoint(index: number, coord: any, label: string, justificativa: string) {
        const pointFeature = new Feature({
            geometry: new Point(coord),
            name: label
        });

        const pointStyle = new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: 'red'
                }),
                stroke: new Stroke({
                    color: 'white',
                    width: 2
                }),
            }),
            text: new Text({
                text: label,
                offsetY: 15,
                scale: 1.2,
                fill: new Fill({
                    color: 'red'
                }),
                stroke: new Stroke({
                    color: '#fff',
                    width: 3
                })
            })
        });

        pointFeature.setStyle(pointStyle);
        this.vectorSource.addFeature(pointFeature);
        this.addInconformidade(index, coord, pointFeature, justificativa);
    }
    addInconformidade(index: number, coord: any, feature: Feature, justificativa:string){
        this.inconformidades.push(this.fb.group({
            index: new FormControl(index, { validators: [Validators.required] }),
            latitude: new FormControl(coord[1], { validators: [Validators.required] }),
            longitude: new FormControl(coord[0], { validators: [Validators.required] }),
            justificativa: new FormControl(justificativa, { validators: [Validators.required, Validators.maxLength(4000)] }),
            feicaoRequerimentoLicenciamento: new FormControl(this.geometria.extra),
            feature: new FormControl(feature, { validators: [Validators.required] }),
        }))
    }
    clear(): void {
        this.inconformidades.clear();
    }
}
