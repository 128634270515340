import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {Tanque} from "../../../../../../../../shared/models/tanque.model";

@Component({
    selector: 'app-tanque',
    templateUrl: './tanque.component.html',
    styleUrls: ['./tanque.component.scss']
})
export class TanqueComponent implements OnInit {

    @Input()
    tanquesExcluidos: number[];

    @Input() isSomenteVisualizacao: boolean;

    @Input() isNaoPodeEditar: boolean;

    @Input() control: AbstractControl;

    form: FormGroup;
    tiposTanque: DominioDto[] = [];
    tiposIdadeTanque: DominioDto[] = [];

    displayedColumns: string[] = ['identificador', 'tipoTanque', 'capacidade', 'tipoIdade', 'posicionamento', 'localizacao', 'acoes'];

    constructor(private dominiosService: DominiosService,
                private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-tanque-combustivel')
            .subscribe(tipos => this.tiposTanque = tipos);
        this.dominiosService.getDominioByUrl('tipo-idade-tanque')
            .subscribe(tipos => this.tiposIdadeTanque = tipos);

        this.initFormControl();

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    private initFormControl() {
        this.form = new FormGroup({});

        this.form.addControl("id", this.formBuilder.control(null));
        this.form.addControl("identificador", this.formBuilder.control({
            value: null,
            disabled: this.isSomenteVisualizacao
        }, [Validators.required]));
        this.form.addControl("tipoTanque", this.formBuilder.control({
            value: null,
            disabled: this.isSomenteVisualizacao
        }, [Validators.required]));
        this.form.addControl("capacidade", this.formBuilder.control({
            value: null,
            disabled: this.isSomenteVisualizacao
        }, [Validators.required, Validators.min(1), Validators.max(99999999999999999999)]));
        this.form.addControl("tipoIdade", this.formBuilder.control({
            value: null,
            disabled: this.isSomenteVisualizacao
        }, [Validators.required]));
        this.form.addControl("posicionamento", this.formBuilder.control({
            value: null,
            disabled: this.isSomenteVisualizacao
        }, [Validators.required]));
        this.form.addControl("localizacao", this.formBuilder.control({
            value: null,
            disabled: this.isSomenteVisualizacao
        }, [Validators.required]));
    }


    async excluir(index: number) {
        if (index > -1) {
            let tanque = this.control.value[index];
            if (tanque && tanque.id !== undefined && tanque.id !== null) {
                this.tanquesExcluidos.push(tanque.id);
            }

            this.control.setValue([
                ...this.control.value.filter(c => c !== tanque),
            ]);
            this.control.markAllAsTouched();
            this.control.markAsDirty();
        }
    }

    adicionar() {
        if (this.form.valid) {

            this.control.setValue([
                ...this.control.value,
                Tanque.fromJson(this.form.value),
            ]);
            this.control.markAllAsTouched();
            this.control.markAsDirty();

            this.initFormControl();
        }
    }

    editar(index) {
        if (index > -1) {
            let tanque = this.control.value[index];
            this.form.patchValue(tanque);
            this.form.get('posicionamento').setValue(tanque.horizontal)
            this.form.get('localizacao').setValue(tanque.aereo)


            this.control.setValue([
                ...this.control.value.filter(c => c !== tanque),
            ]);
            this.control.markAllAsTouched();
            this.control.markAsDirty();
        }
    }

    getTipoTanque(tipoTanque) {
        return this.tiposTanque.find(tipo => tipo.id == tipoTanque);
    }

    isBotaoDisabled() {
        return !this.form.valid || this.isSomenteVisualizacao;
    }
}
