export class FileValidationUtil {

    private static sizes: MBSize[] = [
        // 5254143 bytes é até onde o Windows exibe como 1MB
        {sizeInBytes: 1048576, label: '1MB'},
        // 5254143 bytes é até onde o Windows exibe como 5MB
        {sizeInBytes: 5254143, label: '5MB'},
        // 10591231 bytes é até onde o Windows exibe como 10MB
        {sizeInBytes: 10591231, label: '10MB'},
        // 20971520 bytes é até onde o Windows exibe como 20MB
        {sizeInBytes: 20971520, label: '20MB'},
        // 31457280 bytes é até onde o Windows exibe como 30MB
        {sizeInBytes: 31457280, label: '30MB'}
    ];

    static size(label: string): MBSize {
        return this.sizes.find(s => s.label === label);
    }

    static invalidSize(file: File, limit: MBSize): boolean {
        return !file.size || file.size > limit.sizeInBytes;
    }
}

export interface MBSize {
    label: string;
    sizeInBytes: number;
}
