import { Imovel } from "app/main/pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model";
import {BaseModel} from "./base.model";
import { EmissaoTitulo } from "./emissao-titulo.model";

export class LancamentoCreditoReposicaoFlorestal extends BaseModel {

    constructor(
        public id?: number,
        public dataPlantio?: Date,
        public cpfDetentor?: string,
        public volumeTotal?: number,
        public volumeTotalPorHectare?: number,
        public creditoSolicitado?: number,
        public totalCreditado?: number,
        public totalAditivado?: number,
        public creditoDisponivelEmM3PorHA?: number,
        public creditoConcedidoEmM3PorHA?: number,
        public situacaoCredito?: string,
        public imovel?: Imovel,
        public emissaoTitulo?: EmissaoTitulo,
        public dataCriacao?: Date
    ) {
        super();
    }

    static fromJson(json: any): LancamentoCreditoReposicaoFlorestal {
        if (json === undefined || json === null) {
            return null;
        }
        return new LancamentoCreditoReposicaoFlorestal(
            json.id,
            json.dataPlantio,
            json.cpfDetentor,
            json.volumeTotal,
            json.volumeTotalPorHectare,
            json.creditoSolicitado,
            json.totalCreditado,
            json.totalAditivado,
            json.creditoDisponivelEmM3PorHA,
            json.creditoConcedidoEmM3PorHA,
            json.situacaoCredito,
            Imovel.fromJson(json.imovel),
            EmissaoTitulo.fromJson(json.emissaoTitulo),
            json.dataCriacao
        );
    }

}