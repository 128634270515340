import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Ponto} from '../../../../shared/models/hidrico-models/ponto.model';
import {EnumTipoSolicitacao, HidricoService} from '../../../../shared/services/hidrico-service/hidrico.service';
import {DisponibilidadeHidricaInfo} from './model/disponibilidade-hidrica-info.model';
import {DisponibilidadeHidricaMes} from './model/disponibilidade-hidrica-mes.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseModel} from '../../../../shared/models/base.model';
import {ENUM_MES_VALUES, ENUM_MES_VALUES_ONLY, EnumMes} from '../../../../shared/enums/mes.enum';
import {VazaoDto} from './model/vazao-dto.model';
import {LancamentoDto} from './model/lancamento-dto.model';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {ParametroDiluicaoService} from "../../../../shared/services/parametro-diluicao.service";
import {ParametroDiluicao} from "../../../../shared/models/hidrico-models/parametro-diluicao.model";
import {VazaoContinuaDto} from "../../../tarefas/requerimento-analise/requerimento-analise-pontos/vazao-continua-dto.model";
import {PontoVazoesMensaisService} from "../../../../shared/components/ponto-vazao-mensal/services/ponto-vazoes-mensais.service";
import {MicrobaciaInfoResponse} from "../../../../shared/models/hidrico-models/microbacia-info-response.model";

@Component({
    selector: 'app-dialog-disponibilidade-hidrica',
    styleUrls: ['dialog-disponibilidade-hidrica.component.scss'],
    templateUrl: './dialog-disponibilidade-hidrica.component.html'
})
export class DialogDisponibilidadeHidricaComponent implements OnInit {

    disponibilidadeHidricaInfo: DisponibilidadeHidricaInfo;
    microbaciaInfoResponse: MicrobaciaInfoResponse = null;
    resultadoConsulta: Array<DisponibilidadeHidricaMes> = [];
    mesesVazao: Array<MesVazao> = [];
    parametrosDiluicao: ParametroDiluicao[] = [];
    parametroDiluicao: ParametroDiluicao;
    tiposDeConsulta = TiposDeConsulta;
    tipoConsultaSelecionado: FormControl = this.formBuilder.control(this.tiposDeConsulta.SOMENTE_DISP);
    formGroup: FormGroup;
    dboControl: FormControl = this.formBuilder.control(null, [Validators.required, Validators.min(0.00001)]);
    simuladorLiberado = false;

    mesValues = ENUM_MES_VALUES;
    mesValuesOnly = ENUM_MES_VALUES_ONLY;
    colunasTabelaResultado: string[] = [];

    vazaoJusanteUltrapassadaMeses: string = null;
    vazaoQ95UltrapassadaMeses: string = null;
    vazaoUltrapassadaMeses: string = null;
    vazaoContinuaUltrapassadaEmpreendedores = '';
    qntdVazaoDisponivel: number;
    vazaoContinua: VazaoContinuaDto;
    qntdVazaoContinuaDisponivel: number;
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    constructor(@Inject(MAT_DIALOG_DATA) public ponto: Ponto,
                private hidricoService: HidricoService,
                private lancamentoService: PontoVazoesMensaisService,
                private parametroDiluicaoService: ParametroDiluicaoService,
                private formBuilder: FormBuilder,
                private snackBarService: SnackBarService) {
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            mes: [null, Validators.required],
            vazao: [null, [Validators.required, Validators.min(0.00001)]]
        });
        this.consultarInfoMicrobacia();
        this.hidricoService.consultarVazaoDisponivel(this.ponto.idMicrobacia, EnumTipoSolicitacao.CAPTACAO_SUPERFICIAL_OUTORGA).subscribe(value => {
                this.disponibilidadeHidricaInfo = value;
                this.resultadoConsulta = this.disponibilidadeHidricaInfo.disponibilidadeMeses;
            }
        );
        this.listarParametros();
    }

    onChangeTipoConsulta(): void {
        this.resultadoConsulta = [];
        this.mesesVazao = [];
        if (this.tipoConsultaSelecionado.value === TiposDeConsulta.LANCAMENTO) {
            this.colunasTabelaResultado = ['mes', 'q95', 'qdisp', 'qeflu', 'ceflu', 'vazao', 'q95solic', 'usos', 'disp'];
        } else if (this.tipoConsultaSelecionado.value === TiposDeConsulta.CAPTACAO) {
            this.colunasTabelaResultado = ['mes', 'q95', 'qdisp', 'vazao', 'q95solic', 'usos', 'disp'];
        }
        this.formGroup.reset();
        this.dboControl.reset();
        this.vazaoContinua = null;
        this.vazaoJusanteUltrapassadaMeses = null;
        this.vazaoUltrapassadaMeses = null;
        this.vazaoQ95UltrapassadaMeses = null;
    }

    getMesTexto(tipo: string): string {
        return EnumMes[tipo];
    }

    adicionarMes(): void {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            if (this.mesesVazao.some(item => item.mes === this.formGroup.controls.mes.value)) {
                this.snackBarService.showAlert('Mês já adicionado');
            } else {
                const local: Array<MesVazao> = [];
                if (this.formGroup.controls.mes.value === 'TODOS') {
                    this.mesValuesOnly.forEach(mes => {
                        let mesVazao = {} as MesVazao;
                        mesVazao.mes = mes;
                        mesVazao.vazao = this.formGroup.controls.vazao.value;
                        local.push(mesVazao as MesVazao);
                    });
                } else {
                    for (const entry of this.mesesVazao) {
                        local.push(entry);
                    }
                    local.push(this.formGroup.value as MesVazao);
                }

                this.mesesVazao = local.sort((a, b) =>
                    (ENUM_MES_VALUES.indexOf(a.mes) - ENUM_MES_VALUES.indexOf(b.mes)));
                this.formGroup.reset();
            }
        }
    }

    consultar(): void {
        switch (this.tipoConsultaSelecionado.value) {
            case TiposDeConsulta.SOMENTE_DISP:
                this.hidricoService.consultarVazaoDisponivel(this.ponto.idMicrobacia, EnumTipoSolicitacao.CAPTACAO_SUPERFICIAL_OUTORGA).subscribe(value => {
                        this.disponibilidadeHidricaInfo = value;
                        this.resultadoConsulta = this.disponibilidadeHidricaInfo.disponibilidadeMeses;
                    }
                );
                break;
            case TiposDeConsulta.CAPTACAO:
                let vazaoDto = this.constroiVazaoDto();
                this.hidricoService.consultarCaptacao(this.ponto.idMicrobacia, vazaoDto, EnumTipoSolicitacao.CAPTACAO_SUPERFICIAL_OUTORGA).subscribe(value => {
                    this.constroiResultadoPesquisa(value);
                    this.consultarVazaoContinua(vazaoDto);
                }, () => this.snackBarService.showError('Erro ao consultar simulação'));
                break;
            case TiposDeConsulta.LANCAMENTO:
                if (this.tipoConsultaSelecionado.value === TiposDeConsulta.LANCAMENTO && !this.dboControl.valid) {
                    this.snackBarService.showAlert('Preencha a concentração corretamente');
                    break;
                }
                if (this.tipoConsultaSelecionado.value === TiposDeConsulta.LANCAMENTO && !this.parametroDiluicao) {
                    this.snackBarService.showAlert('Selecione o parâmetro corretamente');
                    break;
                }

                this.hidricoService.consultarLancamento(this.ponto.idMicrobacia, new LancamentoDto(this.parametroDiluicao.parametroId, this.dboControl.value, this.constroiVazaoDto()))
                    .subscribe(value => {
                        this.constroiResultadoPesquisa(value);
                    }, () => this.snackBarService.showError('Erro ao consultar simulação'));
                break;
        }
    }

    private constroiResultadoPesquisa(disponibilidadeHidricaInfo: DisponibilidadeHidricaInfo) {
        this.disponibilidadeHidricaInfo = disponibilidadeHidricaInfo;
        this.resultadoConsulta = this.disponibilidadeHidricaInfo.disponibilidadeMeses;
        this.vazaoJusanteUltrapassadaMeses = this.calculaStringVazaoJusanteUltrapassadaMeses();
        this.vazaoUltrapassadaMeses = this.calculaStringVazaoUltrapassadaMeses();
        this.vazaoQ95UltrapassadaMeses = this.calculaStringVazaoQ95UltrapassadaMeses();
        this.qntdVazaoDisponivel = this.calculaQntdVazaoDisponivel();
    }

    constroiVazaoDto(): VazaoDto {
        return VazaoDto.fromMeses(this.mesesVazao);
    }

    deleteVazao(item: MesVazao): void {
        const local: Array<MesVazao> = [];
        for (const entry of this.mesesVazao) {
            if (entry.mes !== item.mes) {
                local.push(entry);
            }
        }
        this.mesesVazao = local;
    }

    private calculaStringVazaoJusanteUltrapassadaMeses(): string {
        let result = '';
        const array = this.resultadoConsulta.filter(item => !item.vazaoDisponivelUsoJusante);
        array.forEach((item, index) => {
            result += `${item.mes}`;
            if (index !== array.length - 1) {
                result += ', ';
            }
        });
        return result;
    }

    private calculaStringVazaoUltrapassadaMeses(): string {
        let result = '';
        const array = this.resultadoConsulta.filter(item => !item.vazaoDisponivelUsoLocal);
        array.forEach((item, index) => {
            result += `${item.mes}`;
            if (index !== array.length - 1) {
                result += ', ';
            }
        });
        return result;
    }

    private calculaStringVazaoQ95UltrapassadaMeses(): string {
        let result = '';
        const array = this.resultadoConsulta.filter(item => !item.vazaoDisponivelUsoQ95);
        array.forEach((item, index) => {
            result += `${item.mes}`;
            if (index !== array.length - 1) {
                result += ', ';
            }
        });
        return result;
    }

    private calculaQntdVazaoDisponivel(): number {
        let total = 0;
        this.resultadoConsulta.forEach(item => {
            if (item.vazaoDisponivelUsoLocal && item.vazaoDisponivelUsoJusante && item.vazaoDisponivelUsoQ95) {
                total++;
            }
        });
        return total;
    }

    public liberarSimulador(): void {
        this.simuladorLiberado = true;
    }

    listarParametros(): void {
        this.parametroDiluicaoService.findAllByMicrobacia(this.ponto.idMicrobacia).subscribe(parametros => {
            this.parametrosDiluicao = parametros;
        });
    }

    get unidadeParametroHidrico() {
        return this.parametroDiluicao ? this.parametroDiluicao.unidade : '';
    }

    get nomeParametroHidrico() {
        return this.parametroDiluicao ? this.parametroDiluicao.nome : '';
    }

    changeParametro(idParametro: any) {
        this.parametroDiluicao = this.parametrosDiluicao.find(value => value.parametroId == idParametro);
    }

    public consultarVazaoContinua(vazaoDto: VazaoDto): void {
        this.hidricoService.consultarVazaoContinuaDTO(this.ponto.idMicrobacia, vazaoDto).subscribe(result => {
            this.vazaoContinua = result;
            this.qntdVazaoContinuaDisponivel = this.calculaQntdVazaoContinua();
            this.vazaoContinuaUltrapassadaEmpreendedores = this.calculaStringVazaoContinuaUltrapassadaMeses();
        });
    }

    private calculaQntdVazaoContinua(): number {
        let total = 0;
        this.vazaoContinua.coordenadasDRDH.forEach(item => {
            if (item.vazaoDisponivel) {
                total++;
            }
        });
        return total;
    }

    private calculaStringVazaoContinuaUltrapassadaMeses(): string {
        let result = '';
        const array = this.vazaoContinua.coordenadasDRDH.filter(item => !item.vazaoDisponivel);
        array.forEach((item, index) => {
            result += `${item.emprendimento}`;
            if (index !== array.length - 1) {
                result += ', ';
            }
        });
        return result;
    }

    public consultarInfoMicrobacia(): void{
        this.hidricoService.consultarInfoMicrobacia(this.ponto.idMicrobacia).subscribe(result => {
            this.microbaciaInfoResponse = result;
        });
    }
}

export interface MesVazao {
    mes: string;
    vazao: number;
}

export enum TiposDeConsulta {
    SOMENTE_DISP,
    CAPTACAO,
    LANCAMENTO
}
