import {BaseModel} from "../models/base.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Injector} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Page} from "../models/page.model";
import {environment} from "../../../../environments/environment";

export abstract class BaseEmpreendimentoResiduosGeralService<T extends BaseModel> {
    protected http: HttpClient;

    urlResourceEmpreendimentoResiduosGeral: string = environment.URL_EMPREENDIMENTO_RESIDUOS_GERAL;

    constructor(
        protected apiPath: string,
        protected injector: Injector,
        protected type: T,
        protected jsonToResourceFn: (json: any) => T
    ) {
        this.http = injector.get(HttpClient);
        this.urlResourceEmpreendimentoResiduosGeral += apiPath;
    }

    getAll(httpParams?: HttpParams): Observable<T[]> {
        return this.http.get(this.urlResourceEmpreendimentoResiduosGeral, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getById(id: number): Observable<T> {
        const url = `${this.urlResourceEmpreendimentoResiduosGeral}/id/${id}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    create(resource: T): Observable<T> {
        return this.http
            .post(this.urlResourceEmpreendimentoResiduosGeral, resource)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    update(resource: T): Observable<T> {
        const url = `${this.urlResourceEmpreendimentoResiduosGeral}/${resource.id}`;
        return this.http.put(url, resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoResiduosGeral}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    protected jsonToPage(json: any): Page<T> {
        return Page.fromJson(
            this.jsonToResources(json.content),
            json,
            this.type
        );
    }

    protected jsonToResources(json: any): T[] {
        const resources: T[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: T) => resources.push(this.jsonToResourceFn(e)));
        }
        return resources;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}