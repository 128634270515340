import {BaseModel} from 'app/main/shared/models/base.model';
import { EnumTipoEndereco } from '../enums/tipo-endereco.enum';
import { TipoLogradouro } from './tipo-logradouro.model';
import {Municipio} from './geoportal-base-referencia/municipio.model';

export class EnderecoDto extends BaseModel {

    constructor(
        public id?: number,
        public cep?: string,
        public logradouro?: string,
        public numero?: string,
        public complemento?: string,
        public bairro?: string,
        public tipoLogradouro?: TipoLogradouro,
        public tipo?: EnumTipoEndereco,
        public municipio?: Municipio,
        public contato?: any
    ) {
        super();
    }

    static fromJson(json: any): EnderecoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new EnderecoDto(
            json.id,
            json.cep,
            json.logradouro,
            json.numero,
            json.complemento,
            json.bairro,
            json.tipoLogradouro,
            json.tipo,
            json.municipio,
            json.contato
        );
    }

    static fromJsons(json: any): EnderecoDto[] {
        const lista: EnderecoDto[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((rt) => {
                lista.push(this.fromJson(rt));
            });
            return lista;
        }
    }
}
