import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import { CrematorioMonitoramentoDTO } from 'app/main/shared/models/crematorio-monitoramento-dto.model';
import { DominioDto } from 'app/main/shared/models/dominio-dto.model';
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
    selector: 'app-crematorio',
    templateUrl: './crematorio.component.html',
    styleUrls: ['./crematorio.component.scss']
})
export class CrematorioComponent implements OnInit {
    @Input() tiposMonitoramentoSelecionados: Array<{ dominio: DominioDto, vinculo: CrematorioMonitoramentoDTO }> = [];
    @Input() tiposMonitoramento: Array<DominioDto> = [];
    @Input() tiposExcluidos: Array<number> = [];
    @Input() isSomenteVisualizacao: boolean;
    @Input() isNaoPodeEditar: boolean;

    private readonly TIPO_OUTRO = 5;

    @Input() form: FormGroup;
    tiposCombustivel: DominioDto[];

    constructor(private dominiosService: DominiosService) {
    }


    showDescricao() {
        return this.form.get('crematorio').get('tipoCombustivel') && this.form.get('crematorio').get('tipoCombustivel').value === this.TIPO_OUTRO;
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-combustivel-crematorio')
            .subscribe(doms => this.tiposCombustivel = doms);
            
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }
}
