import {BaseModel} from "../base.model";

export class GeometriaLinha extends BaseModel {
    constructor(
        public id?: number,
        public espessuraBorda?: number,
        public corLinha?: string,
        public tipoTracejado?: number
    ) {
        super();
    }

    static fromJson(json: any): GeometriaLinha {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeometriaLinha(
            json.id,
            json.espessuraBorda,
            json.corLinha,
            json.tipoTracejado
        );
    }
}
