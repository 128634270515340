import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {ResiduoTratamentoTermicoService} from "../../../../../../../../shared/services/residuo-tratamento-termico.service";
import {SituacaoIntertravamentoDto} from "../../../../../../../../shared/models/empreendimentos-models/situacao-intertravamento-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";

@Component({
    selector: 'app-situacoes-intertravamento',
    templateUrl: './situacoes-intertravamento.component.html',
    styleUrls: ['./situacoes-intertravamento.component.scss']
})
export class SituacoesIntertravamentoComponent implements OnInit {

    @Output() situacaoIntertravamentoEmitter = new EventEmitter();
    @Input() idTratamentoTermico: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;

    modoEdicao: boolean = false;
    unidadeMedida: any[] = [];
    intertravamentoAdicionado: any[] = [];
    idIntertravamentoTemp: number = 0;
    formIntertravamento: FormGroup;
    dataSourceIntertravamento = new MatTableDataSource<any>([]);
    situacoesIntertravamentoList: DominioDto[];

    constructor(private dominiosService: DominiosService,
                private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private residuoTratamentoTermicoService: ResiduoTratamentoTermicoService) {
    }

    ngOnInit() {
        this.buildFormSitaacoesIntertravamento();
        this.buscarSituacoesIntertravamento();
        if (this.idTratamentoTermico) {
            this.carregarSituacoesComTratamentoExistente(this.idTratamentoTermico)
        }
        if (this.isNaoPodeEditar) {
            this.formIntertravamento.disable();
        }
    }

    private buildFormSitaacoesIntertravamento(): void {
        this.formIntertravamento = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            intertravamento: ["", [Validators.required]],
        });
    }

    enviarSituacaoIntertravamentoDto(situacaoIntertravamentoDto: SituacaoIntertravamentoDto[]) {
        this.situacaoIntertravamentoEmitter.emit(situacaoIntertravamentoDto)
    }

    adicionaIntertravamento() {
        if (this.formIntertravamento.valid) {
            this.intertravamentoAdicionado.push({
                id: null,
                idTemp: this.idIntertravamentoTemp,
                descricao: this.formIntertravamento.controls.intertravamento.value,
            });

            this.idIntertravamentoTemp++
            this.enviarSituacaoIntertravamentoDto(this.mapearArray())
            this.dataSourceIntertravamento = new MatTableDataSource(this.intertravamentoAdicionado);
            this.formIntertravamento.reset();
        } else {
            this.snackBar.showAlert(
                "Favor informar a identificação do sistema de intertravamento.");
            this.formIntertravamento.markAllAsTouched();
        }
    }


    buscarSituacoesIntertravamento() {
        const consultaTabela = 'SITUACOES_INTERTRAVAMENTO';
        this.dominiosService.getDominio(consultaTabela).subscribe(intertravamento => {
            this.situacoesIntertravamentoList = intertravamento;
        })
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.formIntertravamento.reset();
        this.formIntertravamento.markAsUntouched();
        this.formIntertravamento.updateValueAndValidity()
    }

    deletarItemIntertravaento(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.residuoTratamentoTermicoService.deletarIntertravamentoPorId(itemId).subscribe(response => {
                this.snackBar.showSuccess("Situação intertravamento excluido com sucesso.")
            })
        }
        let data;
        data = this.intertravamentoAdicionado.filter((value, key) => {
            return value.idTemp != item;
        });
        this.intertravamentoAdicionado = data;
        this.dataSourceIntertravamento = new MatTableDataSource(data);
        this.enviarSituacaoIntertravamentoDto(this.mapearArray());
    }

    editarIntertravamento() {
        if (this.formIntertravamento.valid) {
            this.deletarItemIntertravaento(this.formIntertravamento.controls.idTemp.value);
            this.intertravamentoAdicionado.push({
                id: null,
                idTemp: this.idIntertravamentoTemp,
                descricao: this.formIntertravamento.controls.intertravamento.value,
            });
            this.idIntertravamentoTemp++
            this.dataSourceIntertravamento = new MatTableDataSource(this.intertravamentoAdicionado);
            this.enviarSituacaoIntertravamentoDto(this.mapearArray())
            this.formIntertravamento.reset();
            this.modoEdicao = false;
        }
    }

    ativarModoEdicaoIntertravamento(situacaoIntertravamento) {
        this.formIntertravamento.controls.id.patchValue(situacaoIntertravamento.id);
        this.formIntertravamento.controls.idTemp.patchValue(situacaoIntertravamento.idTemp);
        this.formIntertravamento.controls.intertravamento.patchValue(situacaoIntertravamento.descricao);
        this.modoEdicao = true;
    }

    mapearArray(): any {
        if (this.intertravamentoAdicionado.length == 0) {
            return this.intertravamentoAdicionado;
        } else {
            return this.intertravamentoAdicionado.map(function (item) {
                return new SituacaoIntertravamentoDto(
                    item.id,
                    item.descricao,
                );
            });
        }
    }

    carregarSituacoesComTratamentoExistente(idTratamentoTermico: any) {
        this.residuoTratamentoTermicoService.buscaListaSituacaoIntertravamentoPorIdTratamento(idTratamentoTermico).subscribe(response => {
            response.forEach(interTravamento => {
                this.intertravamentoAdicionado.push({
                    id: interTravamento.id,
                    idTemp: this.idIntertravamentoTemp,
                    descricao: interTravamento.descricao,
                });
                this.idIntertravamentoTemp++
            });
            this.dataSourceIntertravamento = new MatTableDataSource(this.intertravamentoAdicionado);
        });
    }

}
