import {BaseModel} from "./base.model";
import {EmpreendimentoEeHidrica} from "./empreendimento-ee-hidrica.model";
import {Barragem} from "./barragem.model";
import {CasaDeForca} from "./casa-de-forca.model";
import {Reservatorio} from "./reservatorio.model";
import {UnidadesGeradoras} from "./unidades-geradoras.model";
import {Derivacao} from "./derivacao.model";

export class EmpreendimentoEeHidricaDTO extends BaseModel {
    constructor(
        public empreendimentoEeHidrica?: EmpreendimentoEeHidrica,
        public listaDerivacao?: Derivacao[],
        public listaBarragem?: Barragem[],
        public listaCasaForca?: CasaDeForca[],
        public listaReservatorio?: Reservatorio[],
        public listaUnidadesGeradoras?: UnidadesGeradoras[],
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoEeHidricaDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoEeHidricaDTO(
            json.id,
            json.idRequerimento,
            json.naMontante,
            json.naJusante,
            json.fatorCapacidade,
            json.quedaBruta,
        );
    }

    static fromJsons(json: any): EmpreendimentoEeHidricaDTO[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(EmpreendimentoEeHidricaDTO.fromJson(j)));
            return resources;
        }
    }
}