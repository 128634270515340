import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {ResiduoGeradoModel} from "../../../../../../../../shared/models/residuo-gerado.model";
import {Dominio} from "../../../../../../../../shared/models/dominio-model";
import {GerenciamentoResiduoModel} from "../../../../../../../../shared/models/gerenciamento-residuo.model";
import {DominioService} from "../../../../../../../../shared/services/dominio.service";
import {EmpreendimentoGerenciamentoResiduosService} from "../../../../../../../../shared/services/empreendimento-gerenciamento-residuos.service";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {debounceTime} from "rxjs/operators";
import {Observable} from "rxjs";
import {RequerimentoFormularioLicenciamentoService} from "../../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../../requerimento.service";
import {Requerimento} from "../../../../../../requerimento.model";

@Component({
  selector: 'app-painel-gerenciamento-residuos',
  templateUrl: './painel-gerenciamento-residuos.component.html',
  styleUrls: ['./painel-gerenciamento-residuos.component.scss']
})
export class PainelGerenciamentoResiduosComponent implements OnInit, OnChanges {

    @Input() id: number;
    @Input() requerimento: Requerimento;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;
    @Input() reloadRegistrosGerados: Observable<any>;
    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    form: FormGroup;

    @ViewChild('formDirective', {static: true})
    formDirective: NgForm;

    listaResiduosGerados: ResiduoGeradoModel[] = [];
    mapListaResiduosGerados = new Map;

    tiposArmazenamento: Dominio[] = [];
    mapTiposArmazenamento = new Map;

    tiposAcondicionamento: Dominio[] = [];
    mapTiposAcondicionamento = new Map;

    tiposTratamento: Dominio[] = [];
    mapTiposTratamento = new Map;

    tiposReutilizacaoReciclagemRecuperacao: Dominio[] = [];
    mapTiposReutilizacaoReciclagemRecuperacao = new Map;

    tiposDestinacaoFinal: Dominio[] = [];
    mapTiposDestinacaoFinal = new Map;

    tiposTransporte: Dominio[] = [];
    mapTiposTransporte = new Map;

    private readonly DEBOUNCE_TIME: number = 1000;

    idRequerimento

    dataSource: GerenciamentoResiduoModel[] = [];
    displayedColumns: string[] = [
        'idResiduoGerado',
        'armazenamento',
        'acondicionamento',
        'freqColeta',
        'tratamento',
        'reuReciRecu',
        'transporte',
        'destinacao'
    ];

    constructor(private fb: FormBuilder,
                private dominioService: DominioService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
                private empreendimentoGerenciamentoResiduosService: EmpreendimentoGerenciamentoResiduosService,
                private snackBarService: SnackBarService) {
        //Definindo o formulario
        this.form = this.fb.group({
            id: [''],
            idResiduoGerado: ['', [Validators.required]],
            armazenamento: ['', [Validators.required]],
            armazenamentoOutro: ['', [Validators.maxLength(255)]],
            acondicionamento: ['', [Validators.required]],
            acondicionamentoOutro: ['', [Validators.maxLength(255)]],
            freqColeta: ['', [Validators.required, Validators.maxLength(255)]],
            tratamento: this.fb.group({
                tipo: ['', [Validators.required]],
                tipoOutro: ['', [Validators.maxLength(255)]],
                dentroEmpreendimento: ['', [Validators.required]],
                cnpjEmpresa: ['', [Validators.pattern('^[0-9]{1,20}$')]],
                razaoSocial: ['', [Validators.maxLength(255)]],
                nuLicenca: ['', [Validators.pattern('^[0-9]{1,20}$')]]
            }),
            reuReciRecu: this.fb.group({
                tipo: ['', [Validators.required]],
                tipoOutro: ['', [Validators.maxLength(255)]],
                dentroEmpreendimento: ['', [Validators.required]],
                cnpjEmpresa: ['', [Validators.pattern('^[0-9]{1,20}$')]],
                razaoSocial: ['', [Validators.maxLength(255)]],
                nuLicenca: ['', [Validators.pattern('^[0-9]{1,20}$')]]
            }),
            destinacao: this.fb.group({
                tipo: ['', [Validators.required]],
                tipoOutro: ['', [Validators.maxLength(255)]],
                dentroEmpreendimento: ['', [Validators.required]],
                cnpjEmpresa: ['', [Validators.pattern('^[0-9]{1,20}$')]],
                razaoSocial: ['', [Validators.maxLength(255)]],
                nuLicenca: ['', [Validators.pattern('^[0-9]{1,20}$')]]
            }),
            transporte: this.fb.group({
                tipo: ['', [Validators.required]],
                cnpjEmpresa: ['', [Validators.pattern('^[0-9]{1,20}$')]],
                razaoSocial: ['', [Validators.maxLength(255)]],
                nuLicenca: ['', [Validators.pattern('^[0-9]{1,20}$')]]
            })
        });
    }

    async ngOnInit() {
        if(this.id) {
            this.empreendimentoGerenciamentoResiduosService.consultarGerenciamentoResiduosPorId(this.id).subscribe(res => {
                this.form.patchValue(res);
            }, error => {
                this.snackBarService.showError('Falha ao carregar informações!');
                console.error(error);
            });
        }

        //Carregando todos os selectmenu da tela
        this.dominioService.getTipoArmazenamentoGerenciamentoResiduos().subscribe(res => {
            this.tiposArmazenamento = res;
            this.tiposArmazenamento.forEach(tipo => {
                this.mapTiposArmazenamento.set(tipo.id, tipo.descricao);
            });
        });
        this.dominioService.getTipoAcondicionamentoGerenciamentoResiduos().subscribe(res => {
            this.tiposAcondicionamento = res;
            this.tiposAcondicionamento.forEach(tipo => {
                this.mapTiposAcondicionamento.set(tipo.id, tipo.descricao);
            });
        });
        this.dominioService.getTipoTratamentoGerenciamentoResiduos().subscribe(res => {
            this.tiposTratamento = res;
            this.tiposTratamento.forEach(tipo => {
                this.mapTiposTratamento.set(tipo.id, tipo.descricao);
            });
        });
        this.dominioService.getTipoReutilizacaoReciclagemRecuperacaoGerenciamentoResiduos().subscribe(res => {
            this.tiposReutilizacaoReciclagemRecuperacao = res;
            this.tiposReutilizacaoReciclagemRecuperacao.forEach(tipo => {
                this.mapTiposReutilizacaoReciclagemRecuperacao.set(tipo.id, tipo.descricao);
            });
        });
        this.dominioService.getTipoDestinacaoFinalGerenciamentoResiduos().subscribe(res => {
            this.tiposDestinacaoFinal = res;
            this.tiposDestinacaoFinal.forEach(tipo => {
                this.mapTiposDestinacaoFinal.set(tipo.id, tipo.descricao);
            });
        });
        this.dominioService.getTipoTransporteGerenciamentoResiduos().subscribe(res => {
            this.tiposTransporte = res;
            this.tiposTransporte.forEach(tipo => {
                this.mapTiposTransporte.set(tipo.id, tipo.descricao);
            });
        });

        let requerimentoLicenciamentoVinculado;
        if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
            await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
            })
            this.idRequerimento = requerimentoLicenciamentoVinculado.requerimento.id
            //Esperando sinal da tela de registros gerados pra atualizar a listagem
            this.reloadRegistrosGerados.subscribe(_ => {
                this.carregarRegistrosGerados(this.idRequerimento);
            });

            this.carregarRegistrosGerados(this.idRequerimento);
            this.carregar(this.idRequerimento);

        } else {
            this.reloadRegistrosGerados.subscribe(_ => {
                this.carregarRegistrosGerados(this.requerimento.id);
            });

            this.carregarRegistrosGerados(this.requerimento.id);
            this.carregar(this.requerimento.id);
        }

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ( changes.requerimento ) {
            this.carregar(this.requerimento.id);
        }

        //Listeners de mudança nos campos de CPF/CNPJ
        this.form.get('tratamento.cnpjEmpresa').valueChanges.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe(val => {
            this.consultarCpfCnpj(null, "tratamento");
        });
        this.form.get('reuReciRecu.cnpjEmpresa').valueChanges.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe(val => {
            this.consultarCpfCnpj(null, "reuReciRecu");
        });
        this.form.get('destinacao.cnpjEmpresa').valueChanges.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe(val => {
            this.consultarCpfCnpj(null, "destinacao");
        });
        this.form.get('transporte.cnpjEmpresa').valueChanges.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe(val => {
            this.consultarCpfCnpj(null, "transporte");
        });

        if (changes.readonly && !changes.readonly.currentValue) {
            this.displayedColumns = this.displayedColumns.concat('acao');
        }
    }

    consultarLicenca(evt: any, form: string): void {
        //TODO: Implementar quando houver informações sobre licença!
        console.log('TODO: validar licença');
    }

    cpfCnpjMask(value: string, cnpjCpf?: string): string {
        if (cnpjCpf)
         if (cnpjCpf == 'CNPJ') return '00.000.000/0000-00';
         else '000.000.000-009';
         
        return value.length < 12 ? '000.000.000-009' : '00.000.000/0000-00';
    }

    consultarCpfCnpj(evt: any, form: string): void {
        if (!this.form.controls[form].get("cnpjEmpresa").invalid) {
            const {value} = this.form.get(`${form}.cnpjEmpresa`);
            if(value) {
                this.empreendimentoGerenciamentoResiduosService.consultarPessoa(value)
                    .subscribe((retorno) => {
                        this.form.get(`${form}.razaoSocial`).disable();
                        this.form.controls[form].patchValue({razaoSocial: retorno.razaoSocial ? retorno.razaoSocial : retorno.nome});
                    }, (error) => {
                        this.form.get(`${form}.razaoSocial`).enable();
                        this.form.controls[form].patchValue({nuLicenca: null});
                        this.snackBarService.showAlert("Por favor, apresente as documentações da empresa de procedência/destino, de acordo com o termo de Referência. Aba 6 – Documentação complementar.");
                    });
            }
        }
    }

    saveForm(): void {
        if(this.form.valid){
            const form = this.form.getRawValue();
            const entidade: GerenciamentoResiduoModel = this.buildEntity(form);
            this.empreendimentoGerenciamentoResiduosService.salvarGerenciamentoResiduos(entidade).subscribe(response => {
                this.carregar(this.requerimento.id);
                this.snackBarService.showSuccess( 'Informações salvas com sucesso! Se faz necessário também o envio do documento PGRS – Programa de Gerenciamento de Resíduos Sólidos, é obrigatório seu upload na ABA 6 desse requerimento. ' );
                this.save.emit(response);
                this.resetForm();
            }, error => {
                this.snackBarService.showError( 'Falha ao salvar informações!' );
                console.error( error );
            });
        }else{
            this.form.markAllAsTouched();
        }
    }

    resetForm():void{
        this.formDirective.resetForm();
        this.form.enable({onlySelf: false});
    }

    editar(gerenciamentoResiduo: GerenciamentoResiduoModel){
        this.resetForm();
        this.form.patchValue(this.buildEntity(gerenciamentoResiduo));
    }

    excluir(gerenciamentoResiduo: GerenciamentoResiduoModel){
        this.empreendimentoGerenciamentoResiduosService.deletarGerenciamentoResiduos(gerenciamentoResiduo.id)
            .subscribe( _ => {
                this.snackBarService.showSuccess( 'Gerenciamento do resíduo excluído com sucesso!' );
                this.carregar(this.requerimento.id);
            }, error => {
                this.snackBarService.showError( 'Falha ao excluir Gerenciamento do resíduo' );
                console.error( error );
            });
    }

    keyPressOnlyNumeric(event) {
        var inp = String.fromCharCode(event.keyCode);
        if (/\d/.test(inp)) {
            return true;
        }
        event.preventDefault();
        return false;
    }

    private carregarRegistrosGerados(idRequerimento): void{
        this.empreendimentoGerenciamentoResiduosService.listarResiduosGeradosPorRequerimento(idRequerimento).subscribe(res => {
            this.listaResiduosGerados = res;
            this.listaResiduosGerados.forEach(residuo => {
                this.mapListaResiduosGerados.set(residuo.id, residuo.identificacao);
            });
        }, error => {
            this.snackBarService.showError('Falha ao carregar informações de Resíduos Gerados!');
            console.error(error);
        });
    }

    private carregar(idRequerimento): void {
        this.empreendimentoGerenciamentoResiduosService.listarGerenciamentoResiduosPorRequerimento(idRequerimento)
            .subscribe( lista => {
                this.dataSource = lista;
            }, error => {
                this.snackBarService.showError( 'Falha ao listar Gerenciamentos de resíduos!' );
                console.error( error );
            } );
    }

    private buildEntity(source: any): any {
        return {
            id: source.id,
            idResiduoGerado: source.idResiduoGerado,
            armazenamento: source.armazenamento,
            armazenamentoOutro: source.armazenamentoOutro,
            acondicionamento: source.acondicionamento,
            acondicionamentoOutro: source.armazenamentoOutro,
            freqColeta: source.freqColeta,
            tratamento: {
                tipo: source.tratamento.tipo,
                tipoOutro: source.tratamento.tipoOutro,
                dentroEmpreendimento: source.tratamento.dentroEmpreendimento,
                cnpjEmpresa: source.tratamento.cnpjEmpresa,
                razaoSocial: source.tratamento.razaoSocial,
                nuLicenca: source.tratamento.nuLicenca
            },
            reuReciRecu: {
                tipo: source.reuReciRecu.tipo,
                tipoOutro: source.reuReciRecu.tipoOutro,
                dentroEmpreendimento: source.reuReciRecu.dentroEmpreendimento,
                cnpjEmpresa: source.reuReciRecu.cnpjEmpresa,
                razaoSocial: source.reuReciRecu.razaoSocial,
                nuLicenca: source.reuReciRecu.nuLicenca
            },
            destinacao: {
                tipo: source.destinacao.tipo,
                tipoOutro: source.destinacao.tipoOutro,
                dentroEmpreendimento: source.destinacao.dentroEmpreendimento,
                cnpjEmpresa: source.destinacao.cnpjEmpresa,
                razaoSocial: source.destinacao.razaoSocial,
                nuLicenca: source.destinacao.nuLicenca
            },
            transporte: {
                tipo: source.transporte.tipo,
                cnpjEmpresa: source.transporte.cnpjEmpresa,
                razaoSocial: source.transporte.razaoSocial,
                nuLicenca: source.transporte.nuLicenca
            }
        };
    }

}
