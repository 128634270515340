import {BaseModel} from "../../../../shared/models/base.model";
import {DateUtils} from "../../../../shared/util/date-utils";

export class GerenciarRequerimentosDTO extends BaseModel {

    constructor(
        public id?: number,
        public numero?: string,
        public numeroProcesso?: string,
        public dataProtocoloProcesso?: Date,
        public prioridade?: number,
        public tipoProcesso?: string,
        public descricaoProcesso?: string,
        public situacaoProcesso?: string,
        public nomePessoa?: string,
        public processoTarefa?: string,
        public nomeTarefa?: string,
        public analistaResponsavel?: string,
        public dataAlteracao?: Date
    ) {
        super();
    }

    static fromJson(json: any): GerenciarRequerimentosDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new GerenciarRequerimentosDTO(
            json.id,
            json.numero,
            json.numeroProcesso,
            DateUtils.getDate(json.dataProtocoloProcesso),
            json.prioridade,
            json.tipoProcesso,
            json.descricaoProcesso,
            json.situacaoProcesso,
            json.nomePessoa,
            json.processoTarefa,
            json.nomeTarefa,
            json.analistaResponsavel,
            DateUtils.getDate(json.dataAlteracao)
        );
    }

    static fromJsons(json: any): GerenciarRequerimentosDTO[] {
        const lista: GerenciarRequerimentosDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => lista.push(this.fromJson(item)));
            return lista;
        }
    }
}