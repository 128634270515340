import {BaseModel} from "../base.model";
import {AutoClaveDto} from "./auto-clave-dto.model";
import {SituacaoIntertravamentoDto} from "./situacao-intertravamento-dto.model";

export class ResiduosTratamentoTermicoDto extends BaseModel {
    constructor(
        public id?: number,
        public idRequerimento?: number,
        public autoClaveDto?: AutoClaveDto[],
        public atividadeAutoClave?: number,
        public atividadeTratamentoTermico?: number,
        public formaDeAlimentacao?: string,
        public numeroDeCarregamentos?: number,
        public possuiUnidadeRecuperacao?: number,
        public capacidadeNominal?: number,
        public possuiCamaraPosCombustao?: number,
        public possuiSistemaIntertravamento?: number,
        public situacaoIntertravamentoDto?: SituacaoIntertravamentoDto[],

    ) {
        super();
    }

    static fromJson(json: any): ResiduosTratamentoTermicoDto {
        if (json === undefined || json === null) {
            return null;
        }

        return new ResiduosTratamentoTermicoDto(
            json.id,
            json.idRequerimento,
            json.autoClaveDto,
            json.atividadeAutoClave,
            json.atividadeTratamentoTermico,
            json.formaDeAlimentacao,
            json.numeroDeCarregamentos,
            json.possuiUnidadeRecuperacao,
            json.capacidadeNominal,
            json.possuiCamaraPosCombustao,
            json.possuiSistemaIntertravamento,
            json.descIntertravamento
        );
    }
}