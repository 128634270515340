import {Component, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
    templateUrl: './visualizar-comentario-dialog.component.html'
})
export class VisualizarComentarioDialogComponent {

    comentario: any;
    
    constructor(
        public dialogRef: MatDialogRef<VisualizarComentarioDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VisualizarComentarioDialogComponent
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

}
