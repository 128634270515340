import {Component, ElementRef, Injector, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FormBuilder} from '@angular/forms';
import {BaseModel} from 'app/main/shared/models/base.model';
import {MatDialog} from '@angular/material';
import {catchError, map, take, takeUntil} from 'rxjs/operators';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {Ponto} from 'app/main/shared/models/hidrico-models/ponto.model';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {RequerimentoOutorgaService} from '../requerimento-outorga.service';
import {ObjetivoRequerimentoOutorgaService} from '../../../../shared/services/objetivo-requerimento-outorga.service';
import {ObjetivoRequerimentoOutorga} from '../../../../shared/models/objetivo-requerimento-outorga.model';
import {PontoService} from '../../../../shared/services/ponto.service';
import {
    ConfirmarExclusaoPontoDialogComponent
} from './confirmar-exclusao-ponto-dialog/confirmar-exclusao-ponto-dialog.component';
import {DocumentoRequerimentoService} from '../../../documentos-requerimentos/documento-requerimento.service';
import {
    ConfirmarAdicaoPontoDialogComponent
} from './confirmar-adicao-ponto-dialog/confirmar-adicao-ponto-dialog.component';
import {
    ErrosAbaRequerimento
} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {Observable} from 'rxjs';
import {CdkOverlayOrigin, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {OverlayInfoCaptacaoComponent} from '../../shared/overlay-info-captacao/overlay-info-captacao.component';
import {
    DialogDisponibilidadeHidricaComponent
} from '../../shared/dialog-disponibilidade-hidrica/dialog-disponibilidade-hidrica.component';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {
    Camada,
    CamadaFlatNode,
    ExtraOptions,
    GeometriaMapa,
    MapaPadraoComponent,
    PontoMapa
} from '@sema-geo/sema-geoportal';
import {ImovelService} from '../../shared/requerimento-localizacao/shared/imovel.service';
import {RequerimentoService} from '../../requerimento.service';
import {RequerimentoTitulacao} from '../../shared/requerimento-titulacao/shared/requerimento-titulacao.model';
import {
    ImportarPontosSimlamComponent,
    ImportarPontosSimlamData
} from './importar-pontos-simlam/importar-pontos-simlam.component';
import {
    RequerimentoFinalidadeAtividadeService
} from '../../../../shared/services/requerimento-finalidade-atividade.service';
import {Finalidade} from '../../../finalidades/finalidade.model';
import {ObjetivoFinalidadeService} from '../../../../shared/services/objetivo-finalidade.service';
import {FiltroObjetivoFinalidadeDTO} from '../../../../shared/models/filtro-objetivo-finalidade-dto.model';
import {EditarPontoDialogComponent, EditarPontoDialogData} from './editar-ponto-dialog/editar-ponto-dialog.component';
import {DecimalPipe} from '@angular/common';
import {
    RegrasGeoRequerimentoService
} from "../../../../shared/services/licenciamento-config-service/regras-geo-requerimento.service";
import {RegrasGeoRequerimento} from "../../../../shared/models/regras-geo-requerimento/regras-geo-requerimento.model";
import {Imovel} from "../../shared/requerimento-localizacao/shared/imovel.model";
import { FeicaoRequerimentoService } from 'app/main/pages/feicoes-requerimentos/feicao-requerimento.service';
import {CommonsUtil} from "../../../../shared/util/commons-util";
import { EnumInstanciaRequerimento } from '../../menu-novo-requerimento/instancia-requerimento.enum';


@Component({
    selector: 'app-requerimento-geo-outorga',
    templateUrl: 'requerimento-geo-outorga.component.html',
    styleUrls: ['requerimento-geo-outorga.component.scss'],
    animations: fuseAnimations
})
export class RequerimentoGeoOutorgaComponent extends BaseTabRequerimentoComponent implements OnInit {

    referenciasTemporarias: Camada = {
        title: 'Referências temporárias',
        name: 'temporario',
        expandGroup: true,
        permissao: {
            remover: true,
            ponto: true,
            poligono: true,
            linha: true,
            buffer: true,
            upload: true,
            exportar: true
        },
        camadas: []
    };
    configPipe = '1.0-6';
    localizacao: Camada = {
        title: 'Localização',
        name: 'localizacao',
        expandGroup: true,
        permissao: {
            upload: true,
            exportar: true
        }
    };

    feicoesRequerimento: Camada = {
        title: 'Feições do Requerimento',
        name: 'requerimento',
        geometryGroup: true,
        expandGroup: true,
    };

    camadaTrabalho: Camada = {
        title: 'Camada de trabalho',
        name: 'trabalho',
        geometryGroup: true,
        expandGroup: true,
        camadas: [this.referenciasTemporarias, this.localizacao, this.feicoesRequerimento]
    };

    camadas: Camada[] = [this.camadaTrabalho];
    camadaSelecionada: Camada;

    extraOptions: ExtraOptions[];

    @ViewChild('mapaPadraoComponent', {static: false})
    componenteMapa: MapaPadraoComponent;

    objetivosAdicionados: ObjetivoRequerimentoOutorga[];
    finalidadesAdicionadas: Finalidade[];
    titulacoesRequerimento: RequerimentoTitulacao[];
    pontosAdicionados: Ponto[] = [];
    imoveisDoRequerimento: Imovel[] = [];
    temDocumento = true;
    configuracaoFiltro: FiltroObjetivoFinalidadeDTO[] = [];
    regrasGeo: RegrasGeoRequerimento[] = [];

    overlayInfoCaptacao: OverlayRef;
    @ViewChild(CdkOverlayOrigin, {static: false}) _overlayOrigin: CdkOverlayOrigin;

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    constructor(
        protected readonly imovelService: ImovelService,
        protected readonly formBuilder: FormBuilder,
        protected readonly dialog: MatDialog,
        protected readonly snackBarService: SnackBarService,
        private readonly injector: Injector,
        private readonly requerimentoOutorgaService: RequerimentoOutorgaService,
        private readonly requerimentoService: RequerimentoService,
        private readonly requerimentoFinalidadeAtividadeService: RequerimentoFinalidadeAtividadeService,
        private readonly objetivoFinalidadeService: ObjetivoFinalidadeService,
        private readonly pontoService: PontoService,
        private readonly objetivoRequerimentoOutorgaService: ObjetivoRequerimentoOutorgaService,
        private readonly documentoRequerimentoService: DocumentoRequerimentoService,
        private readonly regrasGeoRequerimentoService: RegrasGeoRequerimentoService,
        private readonly overlay: Overlay,
        public readonly viewContainerRef: ViewContainerRef,
        protected readonly decimalPipe: DecimalPipe,
        private readonly feicaoRequerimentoService: FeicaoRequerimentoService
    ) {
        super(injector);
        RequerimentoSteps.GEO.component = this;
    }

    ngOnInit() {
        this.helper.onCurrentStepChange().pipe(takeUntil(this._unsubscribeAll)).subscribe(e => {
            if (e.newTabIndex === RequerimentoSteps.GEO.index) {
                this.carregarDados();

                this.consultarSeTemDocumentoInformado();
                if (!this.requerimento.tipoRequerimento.isFluxoEmitir) {
                    this.requerimentoService.getTitulacoes(this.requerimento).subscribe(result => {
                        this.titulacoesRequerimento = result;
                    }, error => this.snackBarService.showError('Não foi possivel localizar as titulações', error));
                }
            }
        });
        this.extraOptions = [{
            id: 'overlayInfo',
            icon: 'info',
            onMouseEnter: () => this.openOverlayInfo(),
            onMouseLeave: () => this.closeOverlayInfo()
        }];

        if (!this.requerimento.tipoRequerimento.isFluxoEmitir) {
            this.extraOptions.push({
                icon: 'cloud_download',
                hint: 'Importar SIMLAM',
                callback: () => this.importHidrico()
            });
        }
    }

    private async carregarDados(): Promise<any> {
        await this.listarObjetivosRequerimentoOutorga().toPromise();
        await this.listarFinalidadeAtividadesRequerimento().toPromise();
        await this.listarFiltroObjetivoFinalidade().toPromise();
        await this.listarRegrasRequerimento();
        await this.listarPontos();
    }

    consultarSeTemDocumentoInformado(): void {
        this.documentoRequerimentoService.existsByRequerimento(this.requerimento).subscribe(exists => this.temDocumento = exists);
    }

    temFormulario(): boolean {
        return this.pontosAdicionados.filter(p => p.temFormulario()).length > 0;
    }

    private async listarRegrasRequerimento(): Promise<void> {
        for (let objetivo of this.objetivosAdicionados) {
            for (let finalidade of this.finalidadesAdicionadas) {
                let regras = await this.regrasGeoRequerimentoService.getByTipoProcessoAtividadeObjetivo(this.requerimento.tipoRequerimento.id,
                    finalidade.id, objetivo.id).toPromise();
                this.regrasGeo = this.regrasGeo.concat(regras)
            }
        }

        if (this.requerimento.tipoProcesso.instanciaRequerimento !== EnumInstanciaRequerimento.RECURSO_HIDRICO
                && this.regrasGeo.length == 0) {
            this.snackBarService.showError('Nenhuma regra encontrada para este tipo de requerimento, finalidades e objetivos.');
        }
    }

    private listarObjetivosRequerimentoOutorga(): Observable<ObjetivoRequerimentoOutorga[]> {
        return this.objetivoRequerimentoOutorgaService.getAllByRequerimentoOutorga(this.requerimentoOutorga).pipe(map(objetivosRequerimentoOutorga => {
            this.objetivosAdicionados = objetivosRequerimentoOutorga;
            this.feicoesRequerimento.camadas = objetivosRequerimentoOutorga.map(objetivo => ({
                id: objetivo.id,
                title: objetivo.objetivo.descricao,
                name: objetivo.id.toString(10),
                extra: {objetivo: objetivo.objetivo},
                permissao: {
                    ponto: true,
                    upload: true,
                    exportar: true
                }
            } as Camada));
            //Adiciona Legenda das Feições de Entrada
            this.feicoesRequerimento.camadas.forEach(camada => {
                const node: CamadaFlatNode = new CamadaFlatNode();
                node.item = camada.title.toUpperCase()
                node.camada = camada;
                node.camada.title = camada.title.toUpperCase()
                node.camada.style = this.componenteMapa.defaultStyle
                this.componenteMapa.camadasSelecionadas.unshift(node);
            })
            // força a releitura das camadas.
            this.camadas = [this.camadaTrabalho];
            return objetivosRequerimentoOutorga;
        }), catchError((e) => {
            this.snackBarService.showError('Erro ao carregar objetivos.', e);
            throw e;
        }));
    }

    private listarFinalidadeAtividadesRequerimento(): Observable<Finalidade[]> {
        return this.requerimentoFinalidadeAtividadeService.getAllByRequerimento(this.requerimento).pipe(map(requerimentosFinalidade => {
            this.finalidadesAdicionadas = [...new Set(requerimentosFinalidade.map(value => value.finalidadeAtividade.finalidade))];
            return this.finalidadesAdicionadas;
        }), catchError((e) => {
            this.snackBarService.showError('Erro ao carregar finalidades.', e);
            throw e;
        }));
    }

    private listarFiltroObjetivoFinalidade(): Observable<any> {
        return this.objetivoFinalidadeService.getAllByObjetivos([...new Set(this.objetivosAdicionados.map(obj => obj.objetivo))]).pipe(map(result => {
            this.configuracaoFiltro = result;
        }, catchError((e) => {
            this.snackBarService.showError('Erro ao carregar finalidades.', e);
            throw e;
        })));
    }

    async listarPontos(): Promise<void> {
        this.pontosAdicionados = await this.pontoService.getPontos(this.requerimentoOutorga).toPromise();
        this.imoveisDoRequerimento = await this.imovelService.buscarTodosPorRequerimentoComGeometrias(this.requerimento.id).toPromise();
        this.atualizarMapa();
    }

    atualizarMapa(): void {
        // FIXME corrigir a remoção da camada temporária.
        this.componenteMapa.deleteAllFeatures();
        this.componenteMapa.getSource().clear();
        // Feições do imóvel.
        this.localizacao.camadas = [];
        this.imoveisDoRequerimento.forEach((imovel) => {
            imovel.geometrias.forEach(geometria => {
                const geometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt);
                geometriaMapa.expandGroup = true;
                geometriaMapa.id = geometria.id;
                geometriaMapa.permissao = {
                    remover: true
                };
                geometriaMapa.extra = geometria;

                if (imovel.tipo === 'RURAL') {
                    const imovelPrincipal = imovel.imovelPrincipal ? '*' : '';
                    geometriaMapa.title = `${imovel.numeroCAR} ${imovelPrincipal}`;
                    geometriaMapa.propriedades = {
                        id: geometria.id,
                        Nome: geometriaMapa.title
                    };
                    geometriaMapa.extra.imovel = imovel
                } else if (imovel.tipo === 'URBANO') {
                    geometriaMapa.title = 'Área do imóvel urbano';
                    geometriaMapa.propriedades = {
                        id: geometria.id,
                        Nome: geometriaMapa.title
                    };
                    geometriaMapa.extra.imovel = imovel
                    // Só permitir um único polígono para o imóvel urbano.
                    this.localizacao.permissao.poligono = true;
                } else if (imovel.tipo === 'OBRA') {
                    geometriaMapa.title = geometriaMapa.tipoGeometria;
                    geometriaMapa.propriedades = {
                        id: geometria.id,
                    };
                    geometriaMapa.extra.imovel = imovel
                    this.localizacao.permissao.ponto = true;
                    this.localizacao.permissao.linha = true;
                    this.localizacao.permissao.poligono = true;
                }
                geometriaMapa.visualizacao = {
                    showCheckbox: true,
                    showFeature: true
                }
                geometriaMapa.feature.setStyle(this.componenteMapa.defaultStyle)
                this.localizacao.camadas.push(geometriaMapa);
            });
        });

        // Feições do requerimento.
        this.feicoesRequerimento.camadas.forEach(camada => camada.camadas = []);
        for (let i = this.feicoesRequerimento.camadas.length - 1; i > 0; i--) {
            if (this.feicoesRequerimento.camadas[i] instanceof GeometriaMapa) {
                this.feicoesRequerimento.camadas = this.feicoesRequerimento.camadas.splice(i, 1);
            }
        }

        this.feicoesRequerimento.camadas.sort((a, b) => CommonsUtil.comparaStrings(a['title'], b['title']));

        this.pontosAdicionados.forEach(ponto => {
            const p = this.componenteMapa.criarPonto(ponto.id, ponto.latitude, ponto.longitude);
            p.permissao.remover = true;
            p.permissao.editar = !ponto.cadastroConcluido;
            p.extra = ponto;
            p.title = `[${ponto.id}] ${ponto.nomePonto || p.tipoGeometria}`;
            p.propriedades = {
                ID: ponto.id,
                Nome: ponto.nomePonto,
                Objetivo: ponto.objetivoRequerimento ? ponto.objetivoRequerimento.objetivo.descricao : null,
                Finalidade: ponto.finalidade ? ponto.finalidade.descricao : null,
                'Vazão': ponto.vazao ? this.decimalPipe.transform(ponto.vazao.toFixed(6), this.configPipe) : null,
                'Concentração': ponto.concentracaoParametro ? this.decimalPipe.transform(ponto.concentracaoParametro.toFixed(6), this.configPipe) + " " + ponto.parametro.sigla : null,
                'Parâmetro': ponto.parametro ? ponto.parametro.texto : null
            };

            p.extraOptions = [{
                text: 'Ver disponibilidade',
                icon: 'check',
                callback: () => this.verDisponibilidade(ponto)
            }];

            if (!ponto.cadastroConcluido) {
                p.warningMessage = 'Cadastro incompleto';
            }

            if (!ponto.objetivoRequerimento) {
                this.feicoesRequerimento.camadas.push(p);
            } else {

                const camadaArvore = this.feicoesRequerimento.camadas.find((camada: GeometriaMapa) => {
                    return camada.extra.objetivo.id === ponto.objetivoRequerimento.objetivo.id
                });

                if (camadaArvore) {
                    camadaArvore.expandGroup = true;
                    if (camadaArvore.camadas) {
                        camadaArvore.camadas.push(p);
                    } else {
                        camadaArvore.camadas = [p];
                    }
                } else {
                    const novaCamada = {
                        id: ponto.objetivoRequerimento.id,
                        title: ponto.objetivoRequerimento.objetivo.descricao,
                        name: ponto.objetivoRequerimento.id.toString(10),
                        expandGroup: true,
                        permissao: {
                            ponto: true,
                            upload: true,
                            exportar: true
                        },
                        camadas: [p]
                    } as Camada;
                    this.feicoesRequerimento.camadas.push(novaCamada);
                }
            }
        });
        this.feicoesRequerimento.camadas.forEach((camada) => {
            const node: CamadaFlatNode = new CamadaFlatNode();
            node.item = camada.title.toUpperCase()
            node.camada = camada;
            node.camada.title = camada.title.toUpperCase()
            this.componenteMapa.camadasSelecionadas.unshift(node);
            camada.camadas.sort((a, b) => CommonsUtil.comparaStrings(a['title'], b['title']));
        })
        this.camadas = [...this.camadas];
        this.componenteMapa.fit();
    }

    importar = (file: File): void => {
        if (this.temFormulario() || this.temDocumento) {
            const dialogRef = this.dialog.open(ConfirmarAdicaoPontoDialogComponent, {
                width: '450px',
                data: {
                    temPonto: this.pontosAdicionados.length > 0
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    if (file.name.toLowerCase().endsWith('.kml')) {
                        this.componenteMapa.importarFile(file);
                    } else {
                        this.importarShapeFile(file);
                    }
                }
            });
        } else {
            this.importarShapeFile(file);
        }
    }

    private importarShapeFile(file: File): void {
        if ((this.camadaSelecionada === this.referenciasTemporarias) || (this.camadaSelecionada === this.localizacao)) {
            // Importa no componente em si e deixa que ele gere os devidos eventos de geometrias.
            this.componenteMapa.importarFile(file);
        } else {
            this.pontoService.postPontoShapeFile(this.requerimentoOutorga, file, this.camadaSelecionada ? this.camadaSelecionada.id : null).subscribe(() => {
                this.snackBarService.showSuccess('Shapefile importado com sucesso!');
                this.listarPontos();
            }, e => this.snackBarService.showError('Erro ao importar shapefile.', e));
        }
    }

    excluirPontoDialog(ponto: Ponto): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoPontoDialogComponent, {
            width: '450px',
            data: {
                ponto: ponto,
                temDocumento: this.temDocumento
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirPonto(ponto);
            }
        });
    }

    excluirPonto(ponto: Ponto): void {
        if (this.pontosAdicionados.length == 1 && (this.requerimento.situacaoProcesso === 'EM_REVISAO' || this.requerimento.situacaoProcesso === 'EM_CORRECAO')) {
            this.snackBarService.showAlert('Requerimento não pode ficar sem ao menos um ponto associado. Inserir o(s) novo(s) ponto(s) antes de excluir o atual.');
            return;
        }
        this.pontoService.deletePonto(this.requerimentoOutorga, ponto).subscribe(() => {
            this.snackBarService.showSuccess('Registro excluido com sucesso.');

            this.listarPontos();
            this.consultarSeTemDocumentoInformado();
        }, e => this.snackBarService.showError('Erro ao excluir ponto.', e));
    }

    onEditarGeometria(geometria: GeometriaMapa): void {
        const dialogRef = this.dialog.open(EditarPontoDialogComponent, {
            width: '90%',
            data: {
                requerimento: this.requerimento,
                titulacoesRequerimento: this.titulacoesRequerimento,
                objetivosAdicionados: this.objetivosAdicionados,
                objetivoRequerimento: this.objetivosAdicionados.find(o => geometria.extra && geometria.extra
                    && geometria.extra.objetivoRequerimento && o.id === geometria.extra.objetivoRequerimento.id),
                configuracaoFiltro: this.configuracaoFiltro,
                finalidadesAdicionadas: this.finalidadesAdicionadas,
                requerimentoOutorga: this.requerimentoOutorga,
                ponto: geometria,
                onRemoveFeature: this.onRemoveFeature
            } as EditarPontoDialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.listarPontos();
            }
        });
    }

    onAddFeature = (geometria: GeometriaMapa): void => {
        if (this.camadaSelecionada === this.referenciasTemporarias) {
            this.referenciasTemporarias.camadas.push(geometria);
            geometria.title = geometria.tipoGeometria;
            geometria.permissao = {
                remover: true
            };
            this.camadas = [...this.camadas];
        } else if (this.camadaSelecionada === this.localizacao) {
            this.imovelService.createFeicaoLocalizacao(this.requerimento.id, geometria.wkt).subscribe(() => {
                this.snackBarService.showSuccess('Geometria salva com sucesso!');

                this.listarPontos();
            }, e => {
                this.componenteMapa.delete(geometria);
                this.snackBarService.showError('Erro ao salvar a geometria', e);
            });
        } else {
            const g = geometria as PontoMapa;

            const ponto = new Ponto();
            ponto.id = g.id;
            ponto.latitude = g.latitude;
            ponto.longitude = g.longitude;

            if (this.camadaSelecionada) {
                ponto.objetivoRequerimento = {
                    id: this.camadaSelecionada.id
                };
            }

            this.pontoService.postPonto(this.requerimentoOutorga, ponto).subscribe((ponto) => {
                this.snackBarService.showSuccess('Ponto de captação salvo com sucesso!');

                this.listarPontos().then(() => {
                    g.id = ponto.id
                    g.extra = ponto
                    this.onEditarGeometria(g);
                });
            }, e => {
                this.componenteMapa.delete(geometria);
                this.snackBarService.showError('Erro ao salvar ponto.', e);
            });
        }
    }
    onClearAll = (): void => {
        if (this.camadaSelecionada === this.referenciasTemporarias) {
            this.referenciasTemporarias.camadas = [];
            this.camadas = [...this.camadas];
        } else {
            this.pontosAdicionados.forEach(this.excluirPontoDialog);
        }
    }

    private isCamadaTemporaria(geometria: GeometriaMapa): boolean {
        return this.referenciasTemporarias.camadas.indexOf(geometria) > -1;
    }

    private isCamadaLocalizacao(geometria: GeometriaMapa): boolean {
        return this.localizacao.camadas.indexOf(geometria) > -1;
    }

    onRemoveFeature = (geometria: GeometriaMapa): void => {
        if (this.isCamadaTemporaria(geometria)) {
            const index = this.referenciasTemporarias.camadas.indexOf(geometria);
            if (index > -1) {
                this.referenciasTemporarias.camadas.splice(index, 1);
                this.componenteMapa.delete(geometria);
            }
            this.camadas = [...this.camadas];
        } else if (this.isCamadaLocalizacao(geometria)) {
            this.imovelService.deleteFeicaoLocalizacao(this.requerimento.id, geometria.id).pipe(take(1)).subscribe(() => {
                // Só permitir um único polígono para o imóvel urbano.
                const imovel = this.imoveisDoRequerimento.find(imovel => imovel.id === geometria.extra.imovel.id)
                if (imovel.tipo === 'URBANO') {
                    this.localizacao.permissao.poligono = true;
                }
                this.snackBarService.showSuccess('Geometria removida com sucesso.');
                this.listarPontos();
            }, error => {
                this.snackBarService.showError(error.message || 'Erro ao remover a geometria.');
            });
        } else {
            this.excluirPontoDialog(geometria.extra);
        }
    }

    async validarAba(erros: ErrosAbaRequerimento): Promise<void> {
        this.objetivosAdicionados.forEach(objetivoRequerimento => {
            let regras: RegrasGeoRequerimento[] = this.regrasGeo.filter(regra => regra.objetivos.some(o => o.idObjetivoLicenciamento == objetivoRequerimento.id));
            regras.forEach(regra => {
                regra.feicoes.forEach(feicao => {
                    const geometrias: Ponto[] = this.pontosAdicionados.filter(ponto => ponto.objetivoRequerimento ? ponto.objetivoRequerimento.id === objetivoRequerimento.id : false);
                    // Zero para ponto
                    if (feicao.envioObrigatorio && geometrias.length == 0) {
                        erros.push(`O objetivo '${objetivoRequerimento.objetivo.descricao}' precisa de pelo menos uma geometria associada.`);
                    } else if (feicao.quantidadeMaxima < geometrias.length) {
                        erros.push(`A quantidade de geometrias do objetivo: ${objetivoRequerimento.objetivo.descricao} excede a quantidade máxima permitidade de ${feicao.quantidadeMaxima}.`);
                    }
                });
            });
        });


        
        // Desabilita essa verificação se for um requerimento de obra
        if (this.imoveisDoRequerimento.some(x => x.tipo == 'RURAL')) {
            if (!this.requerimento.tipoProcesso.isOutorga) {
                let imovelPrincipal = this.imoveisDoRequerimento.filter(i => i.imovelPrincipal && i.tipo == 'RURAL');
                if (imovelPrincipal == null) {
                    erros.push(`Não foi encontrado um imóvel principal.`);
                }
                let feicoes = await this.feicaoRequerimentoService.getByRequerimento(this.requerimento).toPromise();
                if (!(feicoes != null && feicoes.length > 0)) {
                    erros.push(`É obrigatório adicionar o ponto para o Imóvel`);
                }
            }
        }

        // SE OUTORGA VALIDA TODOS OS IMOVEIS ESTAO COM PONTOS ADICIONADOS (SE TEM GEOMETRIA VINCULADA)
        //
        // if (this.requerimento.tipoProcesso.isOutorga) {
        //     if (this.imoveisDoRequerimento.some(x => x.tipo == 'RURAL')) {
        //         // SE TEM OUTORGA E FOR RURAL
        //         // await new Promise(()=> {
        //             this.imoveisDoRequerimento.filter(x => x.tipo == 'RURAL').forEach(async (i) => {
        //                 let geometriasImovel = await this.pontoService.getPointsFromImovelPrincipal(this.requerimentoOutorga.id).toPromise();
        //                 if (geometriasImovel == null || geometriasImovel.length == 0) {
        //                     erros.push(`É obrigatório adicionar o ponto para o Imóvel Nr.CAR ${i.numeroCAR}`);
        //                 }
        //             });
        //         //     Promise.resolve(erros);
        //         // });
                
        //     }
        // }


        this.finalidadesAdicionadas.forEach(finalidade => {
            if (!this.pontosAdicionados.some(ponto => ponto.finalidade ? ponto.finalidade.id === finalidade.id : false)) {
                erros.push(`A Finalidade: ${finalidade.descricao}, precisa de pelo menos um Ponto associado.`);
            }
        });
        if (!this.requerimento.tipoRequerimento.isFluxoEmitir) {
            this.titulacoesRequerimento.forEach(requerimentoTitulacao => {
                if (!this.pontosAdicionados.some(ponto => ponto.titulacao ? ponto.titulacao.id === requerimentoTitulacao.titulacao.id : false)) {
                    erros.push(`A titulação: ${requerimentoTitulacao.titulacao.descricao}, precisa de pelo menos um Ponto associado.`);
                }
            });
        }
        this.pontosAdicionados.forEach((ponto, i) => {
            if (!ponto.objetivoRequerimento && (!ponto.nomePonto || !ponto.nomePonto.length)) {
                erros.push(`O Ponto ${ponto.id}, precisa de uma Denominação definida e um Objetivo associado.`);
            } else if (!ponto.objetivoRequerimento && ponto.nomePonto && ponto.nomePonto.length) {
                erros.push(`O Ponto: ${ponto.nomePonto}, precisa de um Objetivo associado.`);
            } else if (ponto.objetivoRequerimento && (!ponto.nomePonto || !ponto.nomePonto.length)) {
                erros.push(`O Ponto ${ponto.id}, precisa de uma Denominação definida.`);
            }
        });

        if (erros.length === 0) {
            return await this.pontoService.getPontosAtualizados(this.requerimentoOutorga).toPromise().then(() => Promise.resolve(), (e) => {
                this.snackBarService.showError('', e);
                return Promise.reject(e);
            });
        } else {
            return Promise.resolve();
        }
    }

    openOverlayInfo(): void {
        const ref = new ElementRef(document.getElementById('overlayInfo'));

        const strategy = this.overlay.position().flexibleConnectedTo(ref).withPositions([{
            originX: 'start',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 10
        }]);
        this.overlayInfoCaptacao = this.overlay.create({positionStrategy: strategy});
        this.overlayInfoCaptacao.attach(new ComponentPortal(OverlayInfoCaptacaoComponent, this.viewContainerRef));
    }

    closeOverlayInfo(): void {
        this.overlayInfoCaptacao.dispose();
    }

    verDisponibilidade(ponto: Ponto): void {
        this.dialog.open(DialogDisponibilidadeHidricaComponent, {
            data: ponto,
            width: '900px'
        });
    }

    async importHidrico(): Promise<void> {
        let pontos: Ponto[] = [];
        await this.pontoService.getPontosLegado(this.requerimento.id).toPromise().then(result =>
            pontos = result, error => this.snackBarService.showError('Erro ao buscar pontos SIMLAM', error));

        if (pontos && pontos.length > 0) {
            const pontosFiltrados = pontos.filter(ponto => !this.pontosAdicionados.some(pontoAdicionado =>
                ponto.idCalculoHidricoLegado === pontoAdicionado.idCalculoHidricoLegado));

            if (pontosFiltrados && pontosFiltrados.length > 0) {
                this.dialog.open<ImportarPontosSimlamComponent, ImportarPontosSimlamData>(ImportarPontosSimlamComponent, {
                    data: {
                        requerimento: this.requerimento,
                        pontos: pontosFiltrados,
                        requerimentoOutorga: this.requerimentoOutorga
                    }
                }).afterClosed().subscribe(result => {
                    if (result) {
                        this.listarPontos();
                    }
                });
            } else {
                this.snackBarService.showAlert('Todos os pontos do SIMLAM já estão importados.');
            }
        }
    }

    onSelectCamada(camadaSelecionada: Camada): void {
        this.camadaSelecionada = camadaSelecionada;
    }
}