import {Injectable, Injector} from '@angular/core';
import {BaseRelacionamentoService} from "./base-relacionamento.service";
import {DominiosService} from "./dominios.service";
import {ProdutoProcessadoTerceiroIndFrigorifico} from '../models/produto-processado-terceiro-ind-frigorifico.model';
import {environment} from "../../../../environments/environment";


@Injectable({
    providedIn: "root"
})
export class ProdutoProcessadoTerceiroIndFrigorificoService extends BaseRelacionamentoService<ProdutoProcessadoTerceiroIndFrigorifico> {

    constructor(protected injector: Injector, protected dominiosService: DominiosService) {
        super(
            "/api/prod-proc-terceiro-ind-frig",
            injector,
            ProdutoProcessadoTerceiroIndFrigorifico.prototype,
            ProdutoProcessadoTerceiroIndFrigorifico.fromJson,
            dominiosService,
            false,
            environment.URL_LIC_IND_FRIGORIFICO
        );
    }

    protected get pathGetByEntidadePai(): string {
        return "industria-frigorifico";
    }

    protected get pathGetDominio(): string {
        return "produto-processado-terceiro";
    }


}
