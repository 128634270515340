import {Injectable, Injector} from '@angular/core';
import {Ponto} from '../models/hidrico-models/ponto.model';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DadoMeteorologico} from "../models/hidrico-models/dado-meteorologico.model";

@Injectable({
    providedIn: 'root'
})
export class DadosHidrometeorologicosService extends BaseService<DadoMeteorologico> {

    constructor(injector: Injector) {
        super('/api/dados-hidrometeorologicos', injector, DadoMeteorologico.prototype, DadoMeteorologico.fromJson);
    }

    listarDadosHidrometeorologicos(ponto: Ponto): Observable<DadoMeteorologico[]> {
        return this.http.get(`${this.urlResource}`,{params: {idPonto: ponto.id.toString()}}).pipe(
            map(value => DadoMeteorologico.fromJsons(value)),
            catchError(this.handleError.bind(this))
        );
    }

    adicionarDadoHidrometeorologico(dado: DadoMeteorologico): Observable<DadoMeteorologico> {
        return this.http.post(`${this.urlResource}`, dado).pipe(
            map(value => DadoMeteorologico.fromJson(value)),
            catchError(this.handleError.bind(this))
        );
    }

    pesquisarHidrometeorologico(tipo: string, nomeCodigo: string): Observable<DadoMeteorologico[]> {
        return this.http.get(`${this.urlResource}/fonte`, {params: {tipoEstacao: tipo, nomeCodigo: nomeCodigo}}).pipe(
            map(value => DadoMeteorologico.fromJsons(value)),
            catchError(this.handleError.bind(this))
        );
    }

    excluirDadoHidrometeorologico(dado: DadoMeteorologico): Observable<void> {
        return this.http.delete(`${this.urlResource}/${dado.id}`).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
}
