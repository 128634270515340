import {BaseModel} from 'app/main/shared/models/base.model';
import {ItemTermoReferenciaRequerimento} from './item-termo-referencia-requerimento.model';

export class TermoReferenciaRequerimento extends BaseModel {
    constructor(
        public id?: number,
        public numero?: string,
        public titulo?: string,
        public itens?: ItemTermoReferenciaRequerimento[]
    ) {
        super();
        if (this.itens !== undefined && this.itens.length > 0) {
            this.itensPorTipo = [];
            this.itens.map(itemTermoReferenciaRequerimento => {
                const itemPorTipo = this.itensPorTipo.find(i => i.tipo === itemTermoReferenciaRequerimento.itemTermoReferencia.tipo);
                if (itemPorTipo !== undefined) {
                    itemPorTipo.itens.push(itemTermoReferenciaRequerimento);
                } else {
                    this.itensPorTipo.push({
                        tipo: itemTermoReferenciaRequerimento.itemTermoReferencia.tipo,
                        itens: [itemTermoReferenciaRequerimento]
                    });
                }
            });
        }
    }

    itensPorTipo: ItensPorTipo[];

    static fromJson(json: any): TermoReferenciaRequerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new TermoReferenciaRequerimento(
            json.id,
            json.numero,
            json.titulo,
            ItemTermoReferenciaRequerimento.fromJsons(json.itens)
                .sort((i1, i2) => i1 && i2 && i1.itemTermoReferencia && i2.itemTermoReferencia && i1.itemTermoReferencia.numero > i2.itemTermoReferencia.numero ? 1 : -1)
        );
    }

    static fromJsons(json: any): TermoReferenciaRequerimento[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((resource: any) =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }
}

export interface ItensPorTipo {
    tipo: string;
    itens: ItemTermoReferenciaRequerimento[];
}
