import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface ConfirmarAdicaoPontoDialogData {
    temPonto: boolean;
}

@Component({
    templateUrl: './confirmar-adicao-ponto-dialog.component.html'
})
export class ConfirmarAdicaoPontoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarAdicaoPontoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmarAdicaoPontoDialogData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
