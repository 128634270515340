import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {CrematorioMonitoramentoDTO} from 'app/main/shared/models/crematorio-monitoramento-dto.model';
import {DominioDto} from 'app/main/shared/models/dominio-dto.model';

@Component({
    selector: 'app-monitoramento-continuo-registradores',
    templateUrl: './monitoramento-continuo-registradores.component.html',
    styleUrls: ['./monitoramento-continuo-registradores.component.scss']
})
export class MonitoramentoContinuoRegistradoresComponent implements OnInit {


    @Input() tiposMonitoramentoSelecionados: Array<{ dominio: DominioDto, vinculo: CrematorioMonitoramentoDTO }> = [];
    @Input() tiposMonitoramento: Array<DominioDto> = [];
    @Input() tiposExcluidos: Array<number> = [];
    @Input() isSomenteVisualizacao: boolean;
    @Input() isNaoPodeEditar: boolean;

    tipo: DominioDto;
    descricao: string;

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

    displayedColumns: string[] = ['identificacao', 'descricao', 'acoes'];

    constructor() {
    }

    async ngOnInit() {
        this.dataSource.data = this.tiposMonitoramentoSelecionados;
    }

    excluirTipoMovimento(index: number) {
        if (index > -1) {
            let termo = this.tiposMonitoramentoSelecionados[index];
            if (termo && termo.vinculo.id !== undefined) {
                this.tiposExcluidos.push(termo.vinculo.id);
            }
            this.tiposMonitoramentoSelecionados.splice(index, 1);
            this.dataSource.data = this.tiposMonitoramentoSelecionados;
        }
    }

    incluiTipoMovimento() {
        let vinculo = new CrematorioMonitoramentoDTO();

        vinculo.idTipoMonitoramentoContinuo = this.tipo.id;
        vinculo.descricao = this.tipo.descricao === 'Outro' ? this.descricao : undefined;

        if (this.tipo && (this.tiposMonitoramento.some(atv => atv.id === vinculo.idTipoMonitoramentoContinuo))) {
            if (!this.tiposMonitoramentoSelecionados) {
                this.tiposMonitoramentoSelecionados = [];
            }
            this.tiposMonitoramentoSelecionados.push({
                dominio: this.tipo,
                vinculo: vinculo
            });
            this.dataSource.data = this.tiposMonitoramentoSelecionados;

            this.tipo = undefined;
            this.descricao = undefined;
        }
    }

}
