import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SnackBarService} from '../../../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-suimis-atividades',
    templateUrl: './suimis-atividades.component.html',
    styleUrls: ['./suimis-atividades.component.scss']
})
export class SuimisAtividadesComponent implements OnInit {

    constructor(private router: Router,
                private snackBar: SnackBarService) {
    }

    ngOnInit() {
    }

    navegarParaFormulario(destino?: string) {
        if (destino) {
            this.router.navigateByUrl(`/atividades/licenciamento-empreendimento/${destino}`);
        } else {
            this.snackBar.showAlert('Formulario em construção!')
        }
    }

    navegarParaFormularioFeicao(destino?: string): void {
        if (destino) {
            this.router.navigateByUrl(`/feicoes-requerimentos/formularios/${destino}`);
        } else {
            this.snackBar.showAlert('Formulario em construção!');
        }
    }
}
