import {BaseModel} from "./base.model";
import {EmpreendimentoObrasViarias} from "./empreendimento-obras-viarias.model";

export class ObrasEspeciaisEmpObrasViarias extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoObrasViarias?: EmpreendimentoObrasViarias,
        public tipo?: string,
        public quantidade?: number,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(json: any): ObrasEspeciaisEmpObrasViarias {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObrasEspeciaisEmpObrasViarias(
            json.id,
            EmpreendimentoObrasViarias.fromJson(json.empreendimentoTransporteResiduosPerigosos),
            json.tipo,
            json.quantidade,
            json.descricao
        );
    }

    static fromJsons(json: any): ObrasEspeciaisEmpObrasViarias[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(ObrasEspeciaisEmpObrasViarias.fromJson(j)));
            return resources;
        }
    }
}