import {BaseModel} from 'app/main/shared/models/base.model';

export class ShoppingCenter extends BaseModel {
    constructor(
        public id?: number,
        public qtdeLojasAncora?: number,
        public qtdeLojasSemiAncora?: number,
        public qtdeLojasComuns?: number,
        public numeroVagasEstacionamento?: number,
        public grupoGeradorProprio?: boolean,
        public baciaContencaoGerador?: boolean,
        public volumeCombustivel?: number,
        public qtdeSanitarios?: number,
        public numeroPopulacaoFixa?: number,
        public numeroPopulacaoFlutuante?: number
    ) {
        super();
    }

    static fromJson(json: any): ShoppingCenter {
        if (json === undefined || json === null) {
            return null;
        }
        return new ShoppingCenter(
            json.id,
            json.qtdeLojasAncora,
            json.qtdeLojasSemiAncora,
            json.qtdeLojasComuns,
            json.numeroVagasEstacionamento,
            json.grupoGeradorProprio,
            json.baciaContencaoGerador,
            json.volumeCombustivel,
            json.qtdeSanitarios,
            json.numeroPopulacaoFixa,
            json.numeroPopulacaoFlutuante
        );
    }

}
