import {BaseModel} from "../base.model";

export class LancamentosCreditosDisponiveisRetornoDtoArrecadacao extends BaseModel {

    constructor(
        public numeroDar?: number, //DAR
        public descricaoReceitaNatureza?: string, // tela: natureza de receita
        public historicoLancamento?: string, // tela: descrição do crédito
        public qtdeUpf?: number, // tela: Valor(UPF) BIGDECIMAL
        public valorMoeda?: number, // tela: Valor(RS) BIGDECIMAL
        public data?: string, // tela: data/hora do pgnto
        public numeroProcesso?: string, //tela: N processo //DAR
        public situacao?: boolean,
        public pagamentoEmDuplicidade?: boolean
    ) {
        super();
    }

    static fromJson(json: any): LancamentosCreditosDisponiveisRetornoDtoArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new LancamentosCreditosDisponiveisRetornoDtoArrecadacao(
            json.numeroDar,
            json.descricaoReceitaNatureza,
            json.historicoLancamento,
            json.qtdeUpf,
            json.valorMoeda,
            json.data,
            json.numeroProcesso,
            json.situacao,
            json.pagamentoEmDuplicidade
        );
    }

    static fromJsons(jsonArray: any): LancamentosCreditosDisponiveisRetornoDtoArrecadacao[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}