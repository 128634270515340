import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ResiduosGeraisResiduosSolidos} from "../../../../../../../shared/models/residuos-gerais-residuos-solidos.model";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {ResiduosGeraisDto} from "../../../../../../../shared/models/residuos-gerais-dto.model";
import {EmpreendimentoResiduosGerais} from "../../../../../../../shared/models/empreendimento-residuos-gerais.model";
import {ResiduoGeralService} from "./residuo-geral.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-residuos-geral',
    templateUrl: './residuos-geral.component.html',
    styleUrls: ['./residuos-geral.component.scss']
})
export class ResiduosGeralComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    idForm: number;
    updateId: boolean = false;
    formGeralDeResiduos: FormGroup;
    residuosSolidosSalvos: ResiduosGeraisResiduosSolidos[] = [];
    mostrarVolumeBacia: boolean = false;
    mostrarInfoTanques: boolean = false;
    formularioSalvo: EmpreendimentoResiduosGerais;

    atualizarComponenteResiduos: boolean = true;
    mostrarObrigatorioResiduos: boolean = false;
    isNaoPodeEditar: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
                private residuoGeralService: ResiduoGeralService) {
        super();

    }

    async ngOnInit() {
        this.buildFormResiduoGeral();

        if(this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarResiduosGeral(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarResiduosGeral(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.formGeralDeResiduos.disable();
        }

        //carregando componentes filhos após id do formulario ser atualizado
        this.updateId = true;
    }

    async carregarResiduosGeral(idRequerimento){
        await this.residuoGeralService.buscarPorIdRequerimento(idRequerimento).toPromise().then(response => {
            if(response){
                this.idForm = response.id;
                this.formularioSalvo = response;
                this.formGeralDeResiduos.controls.id.patchValue(this.formularioSalvo.id);
                this.formGeralDeResiduos.controls.recebimentoPossuiBalanco.patchValue(this.formularioSalvo.recebimentoPossuiBalanco == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.recebimentoPossuiLaboratorioAnalise.patchValue(this.formularioSalvo.recebimentoPossuiLaboratorioAnalise == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.recebimentoPossuiCobertura.patchValue(this.formularioSalvo.recebimentoPossuiCobertura == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.recebimentoPossuiPisoImpermeabilizado.patchValue(this.formularioSalvo.recebimentoPossuiPisoImpermeabilizado == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.recebimentoPossuiAreaEsperaResiduos.patchValue(this.formularioSalvo.recebimentoPossuiAreaEsperaResiduos == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.recebimentoPossuiSistemaColeta.patchValue(this.formularioSalvo.recebimentoPossuiSistemaColeta == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.armazenamentoPossuiArmazenamentoResiduos.patchValue(this.formularioSalvo.armazenamentoPossuiArmazenamentoResiduos == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.armazenamentoPossuiSistemaColeta.patchValue(this.formularioSalvo.armazenamentoPossuiSistemaColeta == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.armazenamentoPossuiPisoImpermeabilizado.patchValue(this.formularioSalvo.armazenamentoPossuiPisoImpermeabilizado == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.armazenamentoPossuiBaciaContencao.patchValue(this.formularioSalvo.armazenamentoPossuiBaciaContencao == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.armazenamentoPossuiCobertura.patchValue(this.formularioSalvo.armazenamentoPossuiCobertura == true ? "sim" : "nao");
                this.changeBaciaContecao(this.formGeralDeResiduos.controls.armazenamentoPossuiBaciaContencao.value);
                this.formGeralDeResiduos.controls.armazenamentoVolumeBacia.patchValue(this.formularioSalvo.armazenamentoVolumeBacia);
                this.formGeralDeResiduos.controls.manipulacaoPossuiAreaManipulacaoResiduos.patchValue(this.formularioSalvo.manipulacaoPossuiAreaManipulacaoResiduos == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.manipulacaoPossuiSistemaVentilacao.patchValue(this.formularioSalvo.manipulacaoPossuiSistemaVentilacao == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.manipulacaoPossuiPisoImpermeabilizado.patchValue(this.formularioSalvo.manipulacaoPossuiPisoImpermeabilizado == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.manipulacaoPossuiControlePoluentes.patchValue(this.formularioSalvo.manipulacaoPossuiControlePoluentes == true ? "sim" : "nao");
                this.formGeralDeResiduos.controls.manipulacaoPossuiCobertura.patchValue(this.formularioSalvo.manipulacaoPossuiCobertura == true ? "sim" : "nao");
            }
        })
    }

    private buildFormResiduoGeral(): void {
        this.formGeralDeResiduos = this.formBuilder.group({
            id: [null],
            recebimentoPossuiBalanco: ["", [Validators.required]],
            recebimentoPossuiLaboratorioAnalise: ["", [Validators.required]],
            recebimentoPossuiCobertura: ["", [Validators.required]],
            recebimentoPossuiPisoImpermeabilizado: ["", [Validators.required]],
            recebimentoPossuiAreaEsperaResiduos: ["", [Validators.required]],
            recebimentoPossuiSistemaColeta: ["", [Validators.required]],
            armazenamentoPossuiArmazenamentoResiduos: ["", [Validators.required]],
            armazenamentoPossuiSistemaColeta: ["", [Validators.required]],
            armazenamentoPossuiPisoImpermeabilizado: ["", [Validators.required]],
            armazenamentoPossuiCobertura: ["", [Validators.required]],
            armazenamentoPossuiBaciaContencao: ["", [Validators.required]],
            armazenamentoVolumeBacia: [null],
            manipulacaoPossuiAreaManipulacaoResiduos: ["", [Validators.required]],
            manipulacaoPossuiSistemaVentilacao: ["", [Validators.required]],
            manipulacaoPossuiPisoImpermeabilizado: ["", [Validators.required]],
            manipulacaoPossuiControlePoluentes: ["", [Validators.required]],
            manipulacaoPossuiCobertura: ["", [Validators.required]],
        })
    }

    changeBaciaContecao(baciaContecao) {
        if(baciaContecao == "sim"){
            this.mostrarVolumeBacia = true;
        }else {
            this.mostrarVolumeBacia = false;
            this.formGeralDeResiduos.controls.armazenamentoVolumeBacia.patchValue(null);
        }
    }

    salvar() {
        const validacao = this.validarFormulario();
        if(validacao){
            const residuosGeraisDto = new ResiduosGeraisDto();
            residuosGeraisDto.listaResiduosSolidos = this.residuosSolidosSalvos;
            residuosGeraisDto.empreendimentoResiduosGerais = this.montarEmpreendimento();
            this.residuoGeralService.salvarResiduoGeral(residuosGeraisDto).subscribe( retorno => {
                this.snackBar.showSuccess("Formulario salvo.")
                this.enviarFormConcluido.emit();
            })
        }
    }

    montarEmpreendimento(): EmpreendimentoResiduosGerais{
        return new EmpreendimentoResiduosGerais(
            this.formGeralDeResiduos.controls.id.value,
            this.requerimento.id,
            this.formGeralDeResiduos.controls.recebimentoPossuiBalanco.value == "sim",
            this.formGeralDeResiduos.controls.recebimentoPossuiLaboratorioAnalise.value == "sim",
            this.formGeralDeResiduos.controls.recebimentoPossuiCobertura.value == "sim",
            this.formGeralDeResiduos.controls.recebimentoPossuiPisoImpermeabilizado.value == "sim",
            this.formGeralDeResiduos.controls.recebimentoPossuiAreaEsperaResiduos.value == "sim",
            this.formGeralDeResiduos.controls.recebimentoPossuiSistemaColeta.value == "sim",
            this.formGeralDeResiduos.controls.armazenamentoPossuiArmazenamentoResiduos.value == "sim",
            this.formGeralDeResiduos.controls.armazenamentoPossuiSistemaColeta.value == "sim",
            this.formGeralDeResiduos.controls.armazenamentoPossuiPisoImpermeabilizado.value == "sim",
            this.formGeralDeResiduos.controls.armazenamentoPossuiCobertura.value == "sim",
            this.formGeralDeResiduos.controls.armazenamentoPossuiBaciaContencao.value == "sim",
            Number(this.formGeralDeResiduos.controls.armazenamentoVolumeBacia.value),
            this.formGeralDeResiduos.controls.manipulacaoPossuiAreaManipulacaoResiduos.value == "sim",
            this.formGeralDeResiduos.controls.manipulacaoPossuiSistemaVentilacao.value == "sim",
            this.formGeralDeResiduos.controls.manipulacaoPossuiPisoImpermeabilizado.value == "sim",
            this.formGeralDeResiduos.controls.manipulacaoPossuiControlePoluentes.value == "sim",
            this.formGeralDeResiduos.controls.manipulacaoPossuiCobertura.value == "sim",
            );
    }

    validarFormulario(): boolean{
        if(this.formGeralDeResiduos.valid){
            if(this.formGeralDeResiduos.controls.armazenamentoPossuiBaciaContencao.value == "sim") {
                if (this.verificarVazio(this.formGeralDeResiduos.controls.armazenamentoVolumeBacia.value)) {
                    this.snackBar.showAlert(
                        "O campo de \"Volume para a bacia de contenção\" é obrigatório. Favor realize o preenchimento do mesmo.")
                    this.formGeralDeResiduos.markAllAsTouched();
                    return false;
                }
            }
            if(this.residuosSolidosSalvos.length == 0){
                this.snackBar.showAlert(
                    "Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).");

                this.atualizarComponenteResiduos = false;
                setTimeout(() => {
                    this.atualizarComponenteResiduos = true;
                }, 10)
                this.mostrarObrigatorioResiduos = true;
                return false;
            }
            return true;
        }else{
            this.snackBar.showAlert(
                "Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).");
            this.formGeralDeResiduos.markAllAsTouched();

            if(this.residuosSolidosSalvos.length == 0){
                this.atualizarComponenteResiduos = false;
                setTimeout(() => {
                    this.atualizarComponenteResiduos = true;
                }, 10)
                this.mostrarObrigatorioResiduos = true;
            }
            return false;
        }
    }

    verificarVazio(elemento): boolean{
        if(elemento == "" || elemento == null){
            return true;
        }else{
            return false;
        }
    }

    receberResiduos(residuosAtualizados: ResiduosGeraisResiduosSolidos[]) {
        this.residuosSolidosSalvos = residuosAtualizados;
    }
}
