import {BaseModel} from "../../../../../shared/models/base.model";
import {ModelUtil} from "../../../../../shared/models/model-util";
import {IrrigacaoCulturaMensal} from "./irrigacao-cultura-mensal.model";
import {MetodoSistemaIrrigacao} from "./metodo-sistema-irrigacao.model";

export class IrrigacaoCultura extends BaseModel {
    constructor(
        public id?: number,
        public culturas?: string,
        public metodoSistemaIrrigacao?: MetodoSistemaIrrigacao,
        public minimaEficienciaIrrigacao?: number,
        public eficienciaIrrigacao?: number,
        public areaIrrigada?: number,
        public justificativa?: string,
        public fonteEvapotranspiracao?: string,
        public irrigacoesCulturasMensais?: IrrigacaoCulturaMensal[],
    ) {
        super();
    }

    static fromJson(json: any): IrrigacaoCultura {
        if (json === undefined || json === null) {
            return null;
        }
        return new IrrigacaoCultura(
            json.id,
            ModelUtil.getOrElseNull(json.culturas),
            ModelUtil.getOrElseNull(MetodoSistemaIrrigacao.fromJson(json.metodoSistemaIrrigacao)),
            ModelUtil.getOrElseNull(json.minimaEficienciaIrrigacao),
            ModelUtil.getOrElseNull(json.eficienciaIrrigacao),
            ModelUtil.getOrElseNull(json.areaIrrigada),
            ModelUtil.getOrElseNull(json.justificativa),
            ModelUtil.getOrElseNull(json.fonteEvapotranspiracao),
            ModelUtil.getOrElseNull(IrrigacaoCulturaMensal.fromJsons(json.irrigacoesCulturasMensais)),
        );
    }

    static fromJsons(json: any): IrrigacaoCultura[] {
        const array: IrrigacaoCultura[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(IrrigacaoCultura.fromJson(item)));
            return array;
        }
    }
}
