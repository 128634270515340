import { Component, Injector, OnInit } from '@angular/core';
import { PdfViewerDialog } from 'app/main/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import { ApiError } from 'app/main/shared/models/api-error';
import { ConfiguracaoLicenciamentoService } from 'app/main/shared/services/licenciamento-config-service/configuracao-licenciamento.service';
import { ModeloFeicao } from '../../../../../shared/enums/modelo-feicao.enum';
import { AutorizacaoSupressaoVegetacao } from '../../../../../shared/models/autorizacao-supressao-vegetacao.model';
import {
    FeicaoRequerimentoLicenciamentoDto
} from '../../../../../shared/models/feicao-requerimento-licenciamento-dto.model';
import { ObjetivoLicenciamentoService } from '../../../../../shared/services/objetivo-licenciamento.service';
import { SnackBarService } from '../../../../../shared/snack-bar/snack-bar.service';
import { RedirectUtil } from '../../../../../shared/util/RedirectUtil';
import { EmissaoTituloService } from '../../../../tarefas/shared/service/emissao-titulo.service';
import { ReqTituloVinculadoService } from '../../../../tarefas/shared/service/req-titulo-vinculado.service';
import { EnumInstanciaRequerimento } from '../../../menu-novo-requerimento/instancia-requerimento.enum';
import { RequerimentoService } from '../../../requerimento.service';
import {
    ConfirmarCancelamentoDialogComponent
} from '../../../shared/confirmar-cancelamento-dialog/confirmar-cancelamento-dialog.component';
import { ImovelService } from '../../../shared/requerimento-localizacao/shared/imovel.service';
import { RequerimentoLicenciamentoService } from '../../requerimento-licenciamento.service';
import { BaseRequerimentoLicenciamentoFormulario } from '../base-requerimento-licenciamento.component';
import {
    ManejoFlorestalService
} from '../licenciamento-empreendimentos/atividades/manejo-florestal/manejo-florestal.service';
import {
    QueimaControladaService
} from '../licenciamento-empreendimentos/atividades/queima-controlada/queima-controlada.service';
import {
    SupressaoVegetalService
} from '../licenciamento-empreendimentos/atividades/supressao-vegetal/supressao-vegetal.service';
import { EnumObjetoAssinatura } from 'app/main/shared/enums/EnumObjetoAssinatura';

@Component({
    selector: 'app-licenciamento-florestal',
    templateUrl: './licenciamento-florestal.component.html',
    styleUrls: ['./licenciamento-florestal.component.scss']
})
export class LicenciamentoFlorestalComponent extends BaseRequerimentoLicenciamentoFormulario implements OnInit {

    constructor(injector: Injector,
                private requerimentoService: RequerimentoService,
                private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
                private snackBarService: SnackBarService,
                private pdfViewerDialog: PdfViewerDialog,
                private queimaControladaService: QueimaControladaService,
                private reqTituloVinculadoService: ReqTituloVinculadoService,
                private emissaoTituloService: EmissaoTituloService,
                private imovelService: ImovelService,
                private supressaoVegetalService: SupressaoVegetalService,
                private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
                private manejoFlorestalService: ManejoFlorestalService,
                private configuracaoLicenciamentoService: ConfiguracaoLicenciamentoService) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.activatedRoute.snapshot.url[2].path === 'edit') {
            this.requerimentoService.getById(this.requerimento.id).subscribe(req => {
                if (req.situacaoProcesso === 'CHECKLIST_REPROVADO') {
                    this.requerimentoService.atualizaStatusRequerimento(req.id, 'EM_CORRECAO_CADASTRO').subscribe(req => req);
                } else if (this.requerimento && this.requerimento.situacaoProcesso === 'AGUARDANDO_CORRECAO') {
                    this.requerimentoService.atualizaStatusRequerimento(this.requerimento.id, 'EM_CORRECAO')
                        .subscribe(req => this.requerimento = req);
                }
            });
        }
    }

    cancelar(): any {
        const idTarefa = this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path;

        const dialogRef = this.dialog.open(ConfirmarCancelamentoDialogComponent, {
            width: '300px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.requerimentoService.cancelarRequerimento(idTarefa, this.requerimento.id).subscribe(resp => {
                    RedirectUtil.redirecionaDashboardCamunda();
                }, () => {
                });
            }
        });
    }

    async finalizar() {
        // TODO: Verificar possibilidade na aplicação licenciamento-configuração (web) manter o campo nome da tabela OBJETIVO_LICENCIAMENTO_AMBIENTAL
        //       que consta o campo Descrição (MANEJO FLORESTAL #SUGF, ASV - .., ACQ... ), e criar constantes únicas (enums) para serem vinculados à implementação

        if (this.requerimento.tipoProcesso.instanciaRequerimento != EnumInstanciaRequerimento.RECURSO_FLORESTAL) {
            this.snackBarService.showError(`Formulário utilizado [RECURSO_FLORESTAL] em um requerimento do tipo ${this.requerimento.tipoProcesso.instanciaRequerimento}`);
                return false;
        }

        //aqui garante que requerimento é RECURSO_FLORESTAL
        // Valida por formulários existentes

        const formManejoFlorestal = await this.manejoFlorestalService.buscarPorIdRequerimento(this.requerimento.id).toPromise()
        .catch((e:ApiError) => {
            if (e.status == 404) {
                return null;
            } else {
                this.snackBarService.showError(`Erro validar formulário [MANEJO FLORESTAL] - ${e.message}`);
                throw new Error(`Erro validar formulário [MANEJO FLORESTAL] - ${e.message}`);
            }
        });
        
        if (formManejoFlorestal)
            if (!await this.validarManejoFlorestal()) return;
            
        const formAqc = await this.queimaControladaService.buscarPorIdRequerimento(this.requerimento.id).toPromise()
        .catch((e:ApiError) => {
            console.error(e);
            if (e.status == 404) {
                return null;
            } else {
                this.snackBarService.showError(`Erro validar formulário [AQC] - ${e.message}`);
                throw new Error(`Erro validar formulário [AQC] - ${e.message}`);
            }
        });
        if (formAqc)
            if (!await this.validarQueimaControlada()) return;

        const formASV: AutorizacaoSupressaoVegetacao = await this.supressaoVegetalService
            .buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado &&
            this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id :
                this.requerimento.id).toPromise().catch((e:ApiError) => {
                    if (e.status == 404) {
                        return null;
                    } else {
                        this.snackBarService.showError(`Erro validar formulário [ASV] - ${e.message}`);
                        throw new Error(`Erro validar formulário [ASV] - ${e.message}`);
                    }
                });
        if (formASV)
            if (!await this.validarASV()) return;

        this.validarEProsseguir();
    }

    validarEProsseguir() {
        this.validacaoFinal().then(async passou => {
            if (passou) {
                this.requerimentoLicenciamentoService.readPdf(this.requerimento).subscribe(blob => {
                    this.pdfViewerDialog.show(blob, {
                        height: '80%', width: '60%', showDownloadButton: true,
                        assinatura: {
                            service: this.requerimentoService,
                            alvoAssinatura: this.requerimento,
                            idTarefa: this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path,
                            aviso: 'Essa ação não poderá ser desfeita.\n Confira atentamente todas as informações ' +
                                'do requerimento antes de Assinar e Assinar e enviar o Requerimento para o checklist.',
                            textoBotaoEnviar: 'Assinar e Enviar Requerimento',
                            idDepartamentoResponsavel: this.idDepartamentoResponsavel ? this.idDepartamentoResponsavel : null,
                            objetoAssinatura: EnumObjetoAssinatura.COPIA_REQUERIMENTO_SUGF,
                        }
                    }).subscribe(assinou => {
                        if (assinou) {
                            RedirectUtil.redirecionaDashboardCamundaComDelay();
                        }
                    });
                });
            }
        });
    }

    async validarASV() {
        let coordenadasValidas = await this.imovelService.validarCoordendasImovelSugef(this.requerimento.id).toPromise();

        if(!coordenadasValidas){
            this.snackBarService.showError("Aba 2: A coordenada não está dentro do perímetro do imóvel/atividade.");
            return false;
        }else{
            return true;
        }
    }

    async validarManejoFlorestal() {
        let coordenadasValidas = await this.imovelService.validarCoordendasImovelSugef(this.requerimento.id).toPromise();

        if(!coordenadasValidas){
            this.snackBarService.showError("Aba 2: A coordenada não está dentro do perímetro do imóvel/atividade.");
            return false;
        }else{
            return true;
        }
    }

    async validarQueimaControlada() {
        let coordenadasValidas = await this.imovelService.validarCoordendasImovelSugef(this.requerimento.id).toPromise();

        if(!coordenadasValidas){
            this.snackBarService.showError("Aba 2: A coordenada não está dentro do perímetro do imóvel/atividade.");
            return false;
        }

        let titulosInformado = await this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, null).toPromise();

        let feicoes: FeicaoRequerimentoLicenciamentoDto[] =
            await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();

        let feicaoAqc: FeicaoRequerimentoLicenciamentoDto = feicoes.find(f => f.modeloFeicaoDTO && f.modeloFeicaoDTO.idModeloFeicao == 5)

        if (feicaoAqc.modeloFeicaoDTO.modeloFeicaoAQC.motivo == "RESTO_EXPLORACAO_FLORESTAL") {
            if (titulosInformado.length == 0) {
                this.snackBarService.showError('Informe o número da autorização de exploração na primeira aba do requerimento. [Campo "Referenciar Título já existente"]');
                return false;
            }
        } else {
            if (titulosInformado.length == 0) {
                this.snackBarService.showError('Informe o número da autorização ou licença da atividade na primeira aba do requerimento. [Campo "Referenciar Título já existente"]');
                return false;
            }
        }

        return true;
    }
}