import {Injectable, Injector} from '@angular/core';
import {BaseRelacionamentoService} from "./base-relacionamento.service";
import {DominiosService} from "./dominios.service";
import {SistemaTratamentoTerciario} from "../models/sistema-tratamento-terciario.model";
import {environment} from 'environments/environment';


@Injectable({
    providedIn: "root"
})
export class SistemaTratamentoTerciarioService extends BaseRelacionamentoService<SistemaTratamentoTerciario> {

    constructor(protected injector: Injector, protected dominiosService: DominiosService) {
        super(
            "/api/sist-tratamento-terciario",
            injector,
            SistemaTratamentoTerciario.prototype,
            SistemaTratamentoTerciario.fromJson,
            dominiosService,
            true,
            environment.URL_LIC_SISTEMA_TRATAMENTO
        );
    }

    protected get pathGetByEntidadePai(): string {
        return "sistema-tratamento";
    }

    protected get pathGetDominio(): string {
        return "tipo-sistema-tratamento-terciario";
    }

    protected get pathGetSubDominio(): string {
        return "subtipo-sistema-tratamento-terciario";
    }


}
