import {BaseModel} from 'app/main/shared/models/base.model';
import {TipoSistemaCultivo} from '../../../../../../shared/models/tipo-sistema-cultivo.model';

export class AquiculturaTanqueEscavado extends BaseModel {
    constructor(
        public id?: number,
        public tipoSistemaCultivo?: TipoSistemaCultivo,
        public outroSistemaCultivo?: string,
        public areaBarragem?: number,
        public volumeBarragem?: number,
        public areaTanques?: number,
        public areaTanqueEspecifico?: number,
        public volumeTanques?: number,
        public volumeTanqueEspecificos?: number,
        public outrosEspelho?: string,
        public cria?: boolean,
        public recria?: boolean,
        public engorda?: boolean,
        public recriaEngorda?: boolean,
        public profundidadeMedia?: number,
        public perdasEvaporacao?: number,
        public quantidadeRacao?: number,
        public volumeUtil?: number,
        public perdasInfiltracao?: number,
        public especieAserCultivada?: string
    ) {
        super();
    }

    static fromJson(json: any): AquiculturaTanqueEscavado {
        if (json === undefined || json === null) {
            return null;
        }
        return new AquiculturaTanqueEscavado(
            json.id,
            TipoSistemaCultivo.fromJson(json.tipoSistemaCultivo),
            json.outroSistemaCultivo,
            json.areaBarragem,
            json.volumeBarragem,
            json.areaTanques,
            json.areaTanqueEspecifico,
            json.volumeTanques,
            json.volumeTanqueEspecifico,
            json.outrosEspelho,
            json.cria,
            json.recria,
            json.engorda,
            json.recriaEngorda,
            json.profundidadeMedia,
            json.perdasEvaporacao,
            json.quantidadeRacao,
            json.volumeUtil,
            json.perdasInfiltracao,
            json.especieAserCultivada
        );
    }

}
