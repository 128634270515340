import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';

export class FeicaoTemplate extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipoGeometria?: string
    ) {
        super();
    }

    static tipos: BaseEnum[] = [
        {valor: 'PONTO', descricao: 'Ponto'},
        {valor: 'LINHA', descricao: 'Linha'},
        {valor: 'POLIGONO', descricao: 'Poligono'},
        {valor: 'MULTIPONTO', descricao: 'Multiponto'},
        {valor: 'MULTILINHA', descricao: 'Multilinha'},
        {valor: 'MULTIPOLIGONO', descricao: 'Multipoligono'},
        {valor: 'COLECAO', descricao: 'Coleção'}
    ];

    static fromJson(json: any): FeicaoTemplate {
        if (json === undefined || json === null) { return null; }
        return new FeicaoTemplate(json.id, json.descricao, json.tipoGeometria);
    }
}

export class GrupoFeicaoTemplate extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public itens?: ItemGrupoFeicaoTemplate[]
    ) {
        super();
    }

    static fromJson(json: any): GrupoFeicaoTemplate {
        if (json === undefined || json === null) { return null; }
        return new GrupoFeicaoTemplate(
            json.id,
            json.nome,
            ItemGrupoFeicaoTemplate.fromJsons(json.itens)
        );
    }
}

export class ItemGrupoFeicaoTemplate extends BaseModel {
    constructor(
        public id?: number,
        public feicaoTemplate?: FeicaoTemplate,
        public obrigatorio?: boolean
    ) {
        super();
    }

    static fromJson(json: any): ItemGrupoFeicaoTemplate {
        if (json === undefined || json === null) { return null; }
        return new ItemGrupoFeicaoTemplate(
            json.id,
            FeicaoTemplate.fromJson(json.feicaoTemplate),
            json.obrigatorio
        );
    }

    static fromJsons(json: any): ItemGrupoFeicaoTemplate[] {
        const array: ItemGrupoFeicaoTemplate[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => {
                array.push(this.fromJson(item));
            });
            return array;
        }
    }
}
