import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material';
import {VisualizarGeometriaDialogComponent} from './visualizar-geometria-dialog.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';

@NgModule({
    declarations: [VisualizarGeometriaDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        LeafletModule
    ],
    exports: [VisualizarGeometriaDialogComponent],
    entryComponents: [VisualizarGeometriaDialogComponent]
})
export class VisualizarGeometriaDialogModule {
}
