import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {InsumoUtilizado} from "../../../../../../../../shared/models/insumo-utilizado.model";

@Component({
    selector: 'app-insumos-utilizados',
    templateUrl: './insumos-utilizados.component.html',
    styleUrls: ['./insumos-utilizados.component.scss']
})
export class InsumosUtilizadosComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'quantidade', 'acoes'];

    tipos: DominioDto[];
    unidades: DominioDto[];
    unidadesTempo: DominioDto[];

    formInsumoUtilizado = this.fb.group({
        id: [null],
        mineracao: [null],
        idTipoInsumoMineral: [null, Validators.required,],
        idUnidadeMedidaInsumoMineral: [null, Validators.required,],
        quantidadeMensal: [null, Validators.required,],
        idUnidadeTempo: [null, Validators.required,]
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-insumo-mineracao')
            .subscribe(doms => this.tipos = doms);
        this.dominiosService.getDominioByUrl('tipo-unidade-medida-insumo-mineral')
            .subscribe(doms => this.unidades = doms);
        this.dominiosService.getDominioByUrl('unidade-tempo')
            .subscribe(doms => this.unidadesTempo = doms);

        this.formInsumoUtilizado.get('idTipoInsumoMineral').valueChanges
            .subscribe(value => {
                const isOutro = value &&
                    this.tipos && !!this.tipos.find(d => d.id === value && d.descricao === 'Outro');

                if (isOutro) {
                    if (!this.formInsumoUtilizado.get('descricao')) {
                        this.formInsumoUtilizado.addControl('descricao',
                            this.fb.control(null, [Validators.required, Validators.maxLength(100)]))
                    }
                } else if (!!this.formInsumoUtilizado.get('descricao')) {
                    this.formInsumoUtilizado.removeControl('descricao');
                }

            });

        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formInsumoUtilizado.disable();
        }
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    getDescricaoUnidadeMedida(id: number) {
        const dom = this.unidades && this.unidades.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formInsumoUtilizado.valid) return;

        const insumosUtilizados = this.form.get('insumosUtilizados');

        insumosUtilizados.setValue([
            ...(insumosUtilizados.value || []),
            InsumoUtilizado.fromJson(this.formInsumoUtilizado.value)
        ]);
        this.formInsumoUtilizado.reset();
    }

    excluir(item: InsumoUtilizado) {
        const estruturas: AbstractControl = this.form.get('insumosUtilizados');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: InsumoUtilizado) {
        this.formInsumoUtilizado.patchValue(item);

        this.excluir(item);
    }

    getDescricaoUnidadeTempo(id: number) {
        const dom = this.unidadesTempo && this.unidadesTempo.find(d => d.id === id);
        return dom && dom.descricao;
    }

}
