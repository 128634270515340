import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {RelacaoMaquinasEquipamentos} from "../../../../../../../../shared/models/relacao-maquinas-equipamentos.model";

@Component({
    selector: 'app-relacao-maquinas-equipamentos',
    templateUrl: './relacao-maquinas-equipamentos.component.html',
    styleUrls: ['./relacao-maquinas-equipamentos.component.scss']
})
export class RelacaoMaquinasEquipamentosComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'quantidade', 'acoes'];

    tipos: DominioDto[];

    formRelacaoMaquinasEquipamentos = this.fb.group({
        id: [null],
        mineracao: [null],
        idEquipamento: [null, Validators.required,],
        quantidade: [null, Validators.required,]
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('equipamento')
            .subscribe(doms => this.tipos = doms);

        this.formRelacaoMaquinasEquipamentos.get('idEquipamento').valueChanges
            .subscribe(value => {
                const isOutro = value &&
                    this.tipos && !!this.tipos.find(d => d.id === value && d.descricao === 'Outro');

                if (isOutro) {
                    if (!this.formRelacaoMaquinasEquipamentos.get('descricao')) {
                        this.formRelacaoMaquinasEquipamentos.addControl('descricao',
                            this.fb.control(null, [Validators.required, Validators.maxLength(100)]))
                    }
                } else if (!!this.formRelacaoMaquinasEquipamentos.get('descricao')) {
                    this.formRelacaoMaquinasEquipamentos.removeControl('descricao');
                }

            });

        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formRelacaoMaquinasEquipamentos.disable();
        }
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formRelacaoMaquinasEquipamentos.valid) return;

        const relacaoMaquinaEquipamento = this.form.get('relacaoMaquinaEquipamento');

        relacaoMaquinaEquipamento.setValue([
            ...(relacaoMaquinaEquipamento.value || []),
            RelacaoMaquinasEquipamentos.fromJson(this.formRelacaoMaquinasEquipamentos.value)
        ]);

        this.formRelacaoMaquinasEquipamentos.reset();
    }

    excluir(item: RelacaoMaquinasEquipamentos) {
        const estruturas: AbstractControl = this.form.get('relacaoMaquinaEquipamento');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: RelacaoMaquinasEquipamentos) {
        this.formRelacaoMaquinasEquipamentos.patchValue(item);

        this.excluir(item);
    }

}
