import {Component, Input, OnInit} from '@angular/core';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';

@Component({
    selector: 'app-aba-finalidade-esgotamento-sanitario',
    templateUrl: './aba-finalidade-esgotamento-sanitario.component.html'
})

export class AbaFinalidadeEsgotamentoSanitarioComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;

    constructor() {
    }

    ngOnInit(): void {
    }

}


