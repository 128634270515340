import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {HistoricoConferencia} from '../../../shared/models/item-termo-referencia-requerimento-analise.model';

@Component({
    selector: 'app-item-termo-referencia-informacoes-adicionais-dialog',
    templateUrl: './item-termo-referencia-historico-dialog.component.html',
})
export class ItemTermoReferenciaHistoricoDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<ItemTermoReferenciaHistoricoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: HistoricoConferencia
    ) {
    }

    onClose(): void {
        this.dialogRef.close(false);
    }


}
