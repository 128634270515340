import {Component, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-ajuda-requerimento-outorga',
  templateUrl: './dialog-ajuda-requerimento-outorga.component.html',
  styleUrls: ['./dialog-ajuda-requerimento-outorga.component.scss']
})
export class DialogAjudaRequerimentoOutorgaComponent {

    constructor(public dialogRef: MatDialogRef<DialogAjudaRequerimentoOutorgaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {}
}

