import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UtilService} from 'app/main/core/services/util.service';
import {RepresentanteLegalDocumentoCadastro} from 'app/main/shared/models/representante-legal-documento-cadastro.model';
import {RepresentanteLegalDocumentoService} from 'app/main/shared/services/representante-legal-documento.service';
import {SnackBarService} from '../../snack-bar/snack-bar.service';

@Component({
    selector: 'app-documento-responsavel-legal-dialog',
    template: `
        <mat-dialog-content>

            <form
                name="form"
                [formGroup]="form"
                class="item w-100-p"
                fxLayout="column"
                fxFlex
            >
                <div fxLayout="column" fxLayoutGap="20px grid">
                    <h2 mat-dialog-title>{{ titulo }}</h2>
                    <mat-form-field
                        appearance="outline"
                        floatLabel="always"
                        class="w-100-p"
                    >
                        <mat-label>*Titulo do documento:</mat-label>
                        <input
                            matInput
                            placeholder="T&iacute;tulo do documento"
                            name="titulo"
                            formControlName="titulo"
                            required
                        />
                    </mat-form-field>

                    <mat-label>*Anexo sigiloso</mat-label>
                    <mat-checkbox
                        name="anexoSigiloso"
                        formControlName="anexoSigiloso"
                    >*Anexo sigiloso
                    </mat-checkbox
                    >
                </div>
                <div appearance="outline" class="w-100-p">
                    <mat-label>Arquivo</mat-label>
                    <input
                        type="file"
                        name="arquivo"
                        formControlName="arquivo"
                        (change)="onFileSelect($event)"
                        accept=".pdf"
                        required
                    />
                </div>
            </form>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button class="mat-raised-button" (click)="onNoClick()">
                Cancelar
            </button>
            <button
                class="mat-raised-button mat-primary"
                [disabled]="form.invalid"
                (click)="confirm()"
            >
                Confirmar
            </button>
        </mat-dialog-actions>
    `
})
export class RequerimentoResponsavelLegalArquivosDialogComponent
    implements OnInit {
    form: FormGroup;

    @Input() documento: RepresentanteLegalDocumentoCadastro;

    @Input() titulo = 'Documentos';
    currentFile: File = null;
    permiteEditarTitulo = false;

    constructor(
        public dialogRef: MatDialogRef<RequerimentoResponsavelLegalArquivosDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private service: RepresentanteLegalDocumentoService,
        private formBuilder: FormBuilder,
        protected snackBarService: SnackBarService
    ) {
        this.documento = this.data.documento;
        this.permiteEditarTitulo = this.data.permiteEditarTitulo;
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            titulo: [null, [Validators.required, Validators.maxLength(150)]],
            anexoSigiloso: [false, [Validators.required]],
            arquivo: [null, [Validators.required]]
        });
        // this.form.get("titulo").setValue(this.data.titulo);
        this.form.patchValue(this.documento);
        if (!this.permiteEditarTitulo) {
            this.form.get('titulo').disable();
        }
        this.currentFile = null;
    }

    onFileSelect(event): void {
        if (event.target.files.length === 1) {
            const file = event.target.files[0];
            if (file !== null) {
                this.currentFile = file;
                // this.form.get('arquivo').setValue(file); // nao pode "setar" o arquivo, ele eh definido automaticamente
            }
        } else {
            this.form.get('arquivo').reset();
        }
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm() {

        this.documento.titulo = this.form.get('titulo').value;
        this.documento.sigilo = this.form.get('anexoSigiloso').value;

        if (this.currentFile) {

            // let ddNovo: RepresentanteLegalDocumentoCadastro = new RepresentanteLegalDocumentoCadastro();
            // ddNovo.titulo = this.documento.titulo;
            // ddNovo.sigilo = this.documento.sigilo;
            // ddNovo.nomeArquivo = UtilService.arquivoNomeNormalizado(
            //     this.currentFile.name
            // );
            // ddNovo.arquivo = this.currentFile;
            this.documento.nomeArquivo = UtilService.arquivoNomeNormalizado(this.currentFile.name);
            this.service.updateMultipart(this.documento, this.currentFile).subscribe((documentoSalvo) => {
                this.dialogRef.close(this.documento);
            }, () => this.dialogRef.close(false));


            return;

        }

        this.dialogRef.close(false);
    }
}
