import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-parcelamento-solo',
  templateUrl: './parcelamento-solo.component.html',
  styleUrls: ['./parcelamento-solo.component.scss']
})
export class ParcelamentoSoloComponent {
  @Input() form: FormGroup;
  @Input() isNaoPodeEditar: boolean;

  limitInput(input: HTMLInputElement, limite: number) {
    if (String(input.value).length > limite) {
      input.value = String(input.value).substring(0, limite);
    }
  }

}
