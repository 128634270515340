export enum EnumGrupoDocumento {
    TERMO_REFERENCIA = 'TERMO_REFERENCIA',
    TERMO_REFERENCIA_ESPECIFICO = 'TERMO_REFERENCIA_ESPECIFICO',
    RESPONSAVEL_IMOVEL = 'Responsável do imóvel',
    DOENCA_REPRESENTANTE = 'Doença representante',
    RESPONSAVEL_TECNICO = 'Responsável Técnico',
    REPRESENTANTE_LEGAL = 'Representante Legal',
    EMISSAO_TITULO_PORTARIA = 'Emissão Portaria Outorga',
    EMISSAO_TITULO_AUTORIZACAO = 'Emissão Autorização',
    EMISSAO_TITULO_CADASTRO = 'Emissão Cadastro de Certificado',
    EMISSAO_TITULO_ATO = 'Emissão Ato Administrativo',
    TERMO_RESPONSABILIDADE = 'Termo de responsabilidade',
    PARECER = 'Parecer',
    COMPLEMENTACAO = 'Complementação',
    ATOS = 'Atos',
    TITULOS = 'Títulos',
    DOCUMENTO_REQUERIMENTO_GERADO = 'DOCUMENTO_REQUERIMENTO_GERADO',
    DOCUMENTO_REQUERIMENTO_PUBLICADO = 'DOCUMENTO_REQUERIMENTO_PUBLICADO',
    TERMO_DE_ADESAO_E_COMPROMISSO = 'TERMO_DE_ADESAO_E_COMPROMISSO',
    EMISSAO_LICENCA_AMBIENTAL = 'EMISSAO_LICENCA_AMBIENTAL',
    DOCUMENTO_INVENTARIO_IMPORTADO = 'DOCUMENTO_INVENTARIO_IMPORTADO',
    DOCUMENTO_DE_VISTORIA = 'DOCUMENTO_DE_VISTORIA',
    DOCUMENTO_REUNIOES_PUBLICAS = 'DOCUMENTO_REUNIOES_PUBLICAS',
    DOCUMENTO_DESPACHO = 'Documento do despacho gerado no processo',
    TITULO_EXISTENTE_PROCESSO_FISICO = 'Referência título existente - Processo Físico'
}
