import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseManifestacaoService} from "./base-manifestacao.service";
import {SolicitacaoAnexo} from "../../models/manifestacao/solicitacao-anexo.model";
import {AnexoArquivo} from "../../models/manifestacao/anexo-arquivo.model";

@Injectable({
    providedIn: 'root'
})
export class SolicitacaoAnexoService extends BaseManifestacaoService<SolicitacaoAnexo> {

    constructor(injector: Injector) {
        super('/api/solicitacao-anexo', injector, SolicitacaoAnexo.prototype, SolicitacaoAnexo.fromJson)

    }

    getSolicitcaoAnexoPorIdSolicitacao(idSolicitacaoAnexo: number): Observable<SolicitacaoAnexo[]> {
        const url = `${this.urlResource}/busca-anexo/${idSolicitacaoAnexo}`;
        return this.http
        .get(url)
        .pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    salvarSolicitacaoAnexo(formData: FormData): Observable<AnexoArquivo> {
        const url = `${this.urlResource}/anexo-arquivo`;
        return this.http
        .post(url, formData)
        .pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}