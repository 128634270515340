import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ImovelPessoa} from '../../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel-pessoa.model';
import {RequerimentoService} from '../../../../pages/requerimentos/requerimento.service';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {Imovel} from 'app/main/pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model';
import {DocumentoImovelService} from '../../../services/documento-imovel.service';
import {DocumentoImovel} from '../../../models/documento-imovel.model';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { PessoaCompletoDto } from 'app/main/shared/models/pessoa-completo-dto.model';
import { Documento } from 'app/main/shared/models/documento.model';
import {environment} from '../../../../../../environments/environment';
import { ResponsaveisCorporativoService } from 'app/main/shared/services/responsaveis-corporativo.service';

@Component({
    templateUrl: 'responsavel-imovel-dialog.component.html',
    styleUrls: ['responsavel-imovel-dialog.component.scss'],
})

export class ResponsavelImovelDialogComponent implements OnInit {
    responsavelImovel: ImovelPessoa;
    pessoaCorporativoCompleto: PessoaCompletoDto;
    requerimento: Requerimento;
    cadastroAtualizadoAposRequerimento: boolean;
    documentos: DocumentoImovel[];
    imovel: Imovel = new Imovel();

    constructor(private dialogRef: MatDialogRef<ResponsavelImovelDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private snackBarService: SnackBarService,
                private documentoImovelService: DocumentoImovelService,
                protected requerimentoService: RequerimentoService,
                private responsaveisCorporativoService: ResponsaveisCorporativoService,
    ) {

    }

    ngOnInit(): void {
        this.responsavelImovel = this.data.responsavel;
        this.imovel = this.data.imovel;
        this.requerimento = this.data.requerimento;
        this.listarDocumentos(this.imovel);
        this.pesquisarResponsavel(this.responsavelImovel.pessoa.cpfCnpj);
    }

    public listarDocumentos(imovel: Imovel) {
        this.documentoImovelService
            .getByImovelUrbano(imovel)
            .subscribe((documentos) => {
                this.documentos = documentos;
            }, error => {
                this.snackBarService.showError('Erro ao carregar documentos do responsável pelo imóvel.', error);
            });
    }

    visualizarDocumento(documentoImovel: DocumentoImovel): void {
        window.open(
            this.documentoImovelService.urlResource + `/${documentoImovel.id}/visualizar`
        );
    }

    downloadDocumento(documentoImovel: DocumentoImovel): void {
        window.open(
            this.documentoImovelService.urlResource + `/${documentoImovel.id}/download`
        );
    }

    pesquisarResponsavel(cpfCnpj): void {
        this.responsaveisCorporativoService.getPessoaCompletobyCpfCnpj(cpfCnpj)
            .subscribe((pessoaCompleto) => {
                    this.pessoaCorporativoCompleto = pessoaCompleto

                    let dataAberturaRequerimento = new Date(this.requerimento.dataAbertura);
                    let dataAtualizacaoPessoa = new Date(this.pessoaCorporativoCompleto.dataAtualizacao);

                    this.cadastroAtualizadoAposRequerimento = dataAtualizacaoPessoa > dataAberturaRequerimento;

                }, error => {
                    this.snackBarService
                        .showError('Erro ao pesquisar o responsável pelo imóvel no corporativo', error);
                }
            );
    }

    enderecoCompleto(endereco: any): string {
        return `${endereco.tipo.toLowerCase()} - ${endereco.tipoLogradouro.descricao} ${endereco.logradouro}, ${endereco.numero},
            ${endereco.bairro}, ${endereco.municipio.nome} - ${endereco.municipio.uf}`
    }

    telefonesToString(telefones: Array<any>): string {
        let telefonesString: string = '';
        telefones.forEach(telefone => {
            telefonesString += `${telefone.tipo.toLowerCase()} - (${telefone.ddd}) ${telefone.numero}, `;
        });
        return telefonesString;
    }

    emailsToString(emails: Array<any>): string {
        let emailsString: string = '';
        emails.forEach(email => {
            emailsString += `${email.tipo.toLowerCase()} - ${email.email}, `;
        });
        return emailsString;
    }

    isPessoaJuridica(): boolean {
        return this.pessoaCorporativoCompleto.tipo && this.pessoaCorporativoCompleto.tipo == 'PJ';
    }

    public visualizarDocumentoP(documento: Documento): void {
        window.open(
            environment.URL_SCORP_API +
            `/api/documentos/${documento.id}/visualizar`
        );
    }

    public downloadDocumentoP(documento: Documento): void {
        window.open(
            environment.URL_SCORP_API + `/api/documentos/${documento.id}/download`
        );
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }
}
