import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Requerimento} from "../../../pages/requerimentos/requerimento.model";
import {RequerimentoOutorga} from "../../../pages/requerimentos/requerimento-outorga/requerimento-outorga.model";
import {ObjetivoOutorga} from "../../models/objetivo-outorga.model";
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {DocumentoRequerimento} from "../../../pages/documentos-requerimentos/documento-requerimento.model";
import {ConfirmarAdicaoObjetivoRequerimentoOutorgaDialogComponent} from "../../../pages/requerimentos/requerimento-outorga/requerimento-formulario-outorga/confirmar-adicao-objetivo-requerimento-outorga-dialog/confirmar-adicao-objetivo-requerimento-outorga-dialog.component";
import {SnackBarService} from "../../snack-bar/snack-bar.service";
import {ObjetivoRequerimentoOutorgaDTO} from "../../models/objetivo-requerimento-outorga-dto.model";
import {ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogComponent} from "../../../pages/requerimentos/requerimento-outorga/requerimento-formulario-outorga/confirmar-exclusao-objetivo-requerimento-outorga-dialog/confirmar-exclusao-objetivo-requerimento-outorga-dialog.component";
import {ObjetivoRequerimentoOutorgaService} from "../../services/objetivo-requerimento-outorga.service";
import {MatDialog} from "@angular/material";
import {DocumentoRequerimentoService} from "../../../pages/documentos-requerimentos/documento-requerimento.service";
import {PdfViewerDialog} from "../pdf-viewer-dialog/pdf-viewer-dialog.model";
import {ArquivoService} from "../../services/arquivo.service";
import {BaseModel} from "../../models/base.model";
import {BaseEnum} from "../../enums/base.enum";
import {ObjetivoOutorgaService} from "../../services/objetivo-outorga.service";

@Component({
    selector: 'app-requerimento-objetivo',
    templateUrl: './requerimento-objetivo.component.html',
    styleUrls: ['./requerimento-objetivo.component.scss']
})
export class RequerimentoObjetivoComponent implements OnInit {
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b

    get tiposOutorga(): BaseEnum[] {
        return ObjetivoOutorga.tiposOutorga;
    }

    @Input() requerimento: Requerimento;
    @Input() requerimentoOutorga: RequerimentoOutorga;
    @Input() isSomenteVisualizacao = false;
    @Input() temDocumento = true;
    @Output() objetivos = new EventEmitter<ObjetivoOutorga[]>();

    objetivosRequerimentoOutorgaDTOs: ObjetivoRequerimentoOutorgaDTO[] = [];
    formObjetivoRequerimento: FormGroup;
    objetivosOutorga: ObjetivoOutorga[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private objetivoRequerimentoOutorgaService: ObjetivoRequerimentoOutorgaService,
        private documentoRequerimentoService: DocumentoRequerimentoService,
        private pdfViewerDialog: PdfViewerDialog,
        private arquivoService: ArquivoService,
        private objetivoOutorgaService: ObjetivoOutorgaService,
    ) {
    }

    ngOnInit() {
        this.formObjetivoRequerimento = this.formBuilder.group({
            objetivo: [null, [Validators.required]],
            requerimentoOutorga: [null],
        });
        this.listarObjetivos();
        this.carregarObjetivoRequerimentoOutorga();
        if (this.isSomenteVisualizacao)
            this.formObjetivoRequerimento.disable();
    }

    private listarObjetivos(): void {
        this.objetivoOutorgaService
            .getAll()
            .subscribe((objetivosOutorga) => {
                this.objetivosOutorga = objetivosOutorga;
            }, () => this.snackBarService.showError('Erro ao listar objetivos da outorga.'));
    }

    excluirObjetivoRequerimentoDialog(objetivoRequerimentoOutorgaDTO: ObjetivoRequerimentoOutorgaDTO): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoObjetivoRequerimentoOutorgaDialogComponent, {
            width: '450px',
            data: {objetivoRequerimentoOutorgaDTO: objetivoRequerimentoOutorgaDTO}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirObjetivoRequerimento(objetivoRequerimentoOutorgaDTO);
            }
        });
    }

    private carregarObjetivoRequerimentoOutorga(): void {
        if (this.requerimentoOutorga.id) {
            this.objetivoRequerimentoOutorgaService
                .getDTOByRequerimentoOutorga(this.requerimentoOutorga)
                .subscribe((resources) => {
                    this.objetivosRequerimentoOutorgaDTOs = resources;
                    this.objetivos.emit([...new Set(this.objetivosRequerimentoOutorgaDTOs.map(item => item.objetivoOutorga))])
                }, () => this.snackBarService.showError('Erro ao carregar os objetivos do requerimento.'));
        }
    }

    private excluirObjetivoRequerimento(objetivoRequerimentoOutorga: ObjetivoRequerimentoOutorgaDTO): void {
        if (this.objetivosRequerimentoOutorgaDTOs.length == 1 && (this.requerimento.situacaoProcesso === 'EM_REVISAO' || this.requerimento.situacaoProcesso === 'EM_CORRECAO')) {
            this.snackBarService.showAlert('É necessário ao menos um objetivo selecionado.');
            return;
        }
        
        this.objetivoRequerimentoOutorgaService
            .deleteById(objetivoRequerimentoOutorga.id)
            .subscribe(
                () => {
                    this.snackBarService.showSuccess('Objetivo excluído com sucesso!');
                    this.carregarObjetivoRequerimentoOutorga();
                },
                e => this.snackBarService.showError('Erro ao remover objetivo.', e)
            );
    }

    confirmarAdicaoObjetivoRequerimentoOutorga(form: FormGroupDirective = null): void {
        if (this.temDocumento) {
            const dialogRef = this.dialog.open(ConfirmarAdicaoObjetivoRequerimentoOutorgaDialogComponent, {width: '450px'});

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.addObjetivoRequerimento(form);
                }
            });
        } else {
            this.addObjetivoRequerimento(form);
        }
    }

    private contemObjetivo(): boolean {
        const objetivo = this.formObjetivoRequerimento.get('objetivo').value;
        return this.objetivosRequerimentoOutorgaDTOs.some(objetivoRequerimentoOutorgaDTO => objetivoRequerimentoOutorgaDTO.objetivoOutorga.id === objetivo.id);
    }

    addObjetivoRequerimento(form: FormGroupDirective = null): void {
        this.formObjetivoRequerimento.markAllAsTouched();
        if (this.formObjetivoRequerimento.valid) {
            if (this.contemObjetivo()) {
                this.snackBarService.showAlert('Objetivo já adicionado ao requerimento!');
            } else {

                const formData = new FormData();
                formData.append('idRequerimentoOutorga', this.requerimentoOutorga.id.toString());
                formData.append('idObjetivo', this.formObjetivoRequerimento.get('objetivo').value.id);

                this.objetivoRequerimentoOutorgaService.create(formData)
                    .subscribe(() => {
                            this.snackBarService.showSuccess('Objetivo adicionado com sucesso!');
                            this.carregarObjetivoRequerimentoOutorga();
                            this.formObjetivoRequerimento.reset();
                            if (form) {
                                form.resetForm();
                            }
                        },
                        e => {
                            this.snackBarService.showError('Erro ao adicionar objetivo', e);
                        }
                    );
            }
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    async visualizarDocRequerimento(doc: DocumentoRequerimento) {
        const arquivo = await this.arquivoService.download(doc.chave).toPromise();
        this.pdfViewerDialog.show(arquivo);
    }

}
