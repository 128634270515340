import {BaseModel} from 'app/main/shared/models/base.model';
import {RequerimentoLicenciamento} from "../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model";

export class ObjetivoLicenciamentoSubatividade extends BaseModel {
    constructor(
        public id?: number,
        public idAtividadeComplementar?: number,
        public requerimentoLicenciamento?: RequerimentoLicenciamento,
        public ppdAtividadeComplementar?: string
    ) {
        super();
    }

    static fromJson(json: any): ObjetivoLicenciamentoSubatividade {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObjetivoLicenciamentoSubatividade(
            json.id,
            json.idAtividadeComplementar,
            RequerimentoLicenciamento.fromJson(json.requerimentoLicenciamento),
            json.ppdAtividadeComplementar
        );
    }
}
