import {Component, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {RequerimentoService} from 'app/main/pages/requerimentos/requerimento.service';
import {DocumentoRT} from '../../../../../shared/models/documento-rt.model';
import {ResponsaveisCorporativoService} from 'app/main/shared/services/responsaveis-corporativo.service';
import {MatDialog} from '@angular/material';
import {ResponsavelTecnico} from '../../../../../shared/models/responsavel-tecnico.model';
import {EnumTipoDocumentoRT} from '../../../../../shared/models/enum-tipo-documento-rt.model';
import {ConfirmarExclusaoDialogComponent} from '../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {Subject} from 'rxjs';
import {FileValidationUtil} from '../../../../../shared/util/file-validation-util';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {OverlayOrientacaoArtComponent} from './overlay-info-art/overlay-orientacao-art.component';
import {Overlay} from '@angular/cdk/overlay';
import {ReqTituloVinculadoService} from "../../../../tarefas/shared/service/req-titulo-vinculado.service";

@Component({
    selector: 'app-requerimento-responsavel-tecnico',
    templateUrl: './requerimento-responsavel-tecnico.component.html',
    styleUrls: ['./requerimento-responsavel-tecnico.component.scss'],
    animations: fuseAnimations
})
export class RequerimentoResponsavelTecnicoComponent implements OnInit {

    @Input() requerimento: Requerimento;
    @Input() responsaveisTecnicosAnexados: ResponsavelTecnico[];
    @Input() isSomenteVisualizacao: boolean;
    @Input() altDocRes: boolean;
    @Output() listarResponsaveisTecnicosEmitter = new Subject();

    columnsResponsaveis: string[] = ['nome', 'cpfcnpj', 'ctf', 'email', 'formacao', 'acoes'];
    columnsConsulta: string[] = ['select', 'nome', 'cpfcnpj', 'ctf', 'email', 'formacao'];
    resultadoPesquisa: ResponsavelTecnico[] = [];
    maskInputConsulta = '000.000.000-009';
    inputConsulta = new FormControl('', [Validators.required, Validators.minLength(1)]);
    anexos: DocumentoRT[] = EnumTipoDocumentoRT.listarDocumentos();
    numeroART = new FormControl(null, Validators.required);
    isResponsavelLegal = new FormControl(false, Validators.required);
    fluxoAnexo: TipoProcesso = TipoProcesso.CRIAR;
    isRequerimentoSubterraneo = false;

    // TODO - Remover após correção dos arquivos
    responsavelComDocumentosPerdidos: boolean;

    // Fim remover

    constructor(
        protected requerimentoService: RequerimentoService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        protected responsavelTecnicoCorporativoService: ResponsaveisCorporativoService,
        private reqTituloVinculadoService: ReqTituloVinculadoService,
    ) {
    }

    ngOnInit(): void {
        this.inputConsulta.valueChanges.subscribe(() =>
            this.maskInputConsulta = (this.inputConsulta.value.length > 11) ? '00.000.000/0000-00' : '000.000.000-009');
        this.listarResponsaveisTecnicos();

        this.isSomenteVisualizacao = !this.altDocRes;

        if (this.isSomenteVisualizacao) {
            this.numeroART.disable();
        }
        this.verificaSeehRequerimentoSubterraneo();
    }

    private verificaSeehRequerimentoSubterraneo(): void {
        if (this.requerimento.tipoProcesso.tipo === 'OUTORGA') {
            this.requerimentoService.ehRequerimentoSubterraneo(this.requerimento.id).subscribe(result => this.isRequerimentoSubterraneo = result);
        }
    }

    /**
     * Consulta listagem de pessoas no SCORP e configura a tabela resultado com os dados.
     */
    consultarPessoas(): void {
        this.inputConsulta.markAllAsTouched();
        if (this.inputConsulta.valid) {
            const inputConsultaValue = this.inputConsulta.value.trim();
            // Valida se o valor consultado é diferente do ultimo consultado
            this.responsavelTecnicoCorporativoService.getRTbyCpfCnpj(inputConsultaValue).subscribe((result) => {
                let jaAdicionado = false;
                if (this.requerimento.tipoProcesso.instanciaRequerimento === "TERMO_REFERENCIA" && !this.requerimento.numeroProtocoloVinculado) {
                    this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, 'LP_TRAMITACAO').subscribe(async lpTramitacao => {
                        if (lpTramitacao && lpTramitacao.length > 0) {
                            this.requerimentoService.listarResponsaveisTecnicos(lpTramitacao[0].requerimentoVinculado.id).subscribe((lpTramitacaoResp) => {
                                this.responsaveisTecnicosAnexados.forEach(rt => {
                                        if (rt.pessoa.cpfCnpj === result.pessoa.cpfCnpj) {
                                            this.snackBarService.showSuccess('Responsável técnico já adicionado ao requerimento.');
                                            jaAdicionado = true;
                                        }
                                    }
                                );

                                if (!jaAdicionado) {
                                    this.resultadoPesquisa = [result];
                                    this.anexos = EnumTipoDocumentoRT.listarDocumentos();
                                }
                            });
                        } else {
                            this.responsaveisTecnicosAnexados.forEach(rt => {
                                    if (rt.pessoa.cpfCnpj === result.pessoa.cpfCnpj) {
                                        this.snackBarService.showSuccess('Responsável técnico já adicionado ao requerimento.');
                                        jaAdicionado = true;
                                    }
                                }
                            );

                            if (!jaAdicionado) {
                                this.resultadoPesquisa = [result];
                                this.anexos = EnumTipoDocumentoRT.listarDocumentos();
                            }
                        }
                    })
                } else {
                    this.responsaveisTecnicosAnexados.forEach(rt => {
                            if (rt.pessoa.cpfCnpj === result.pessoa.cpfCnpj) {
                                this.snackBarService.showSuccess('Responsável técnico já adicionado ao requerimento.');
                                jaAdicionado = true;
                            }
                        }
                    );

                    if (!jaAdicionado) {
                        this.resultadoPesquisa = [result];
                        this.anexos = EnumTipoDocumentoRT.listarDocumentos();
                    }
                }

            }, error => {
                this.snackBarService.showError('Erro ao consultar pessoas', error);
            });
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    private listarResponsaveisTecnicos(): void {
        this.listarResponsaveisTecnicosEmitter.next();
    }

    postResponsavelTecnico(): void {
        if (this.formRtIsValid()) {

            if (this.isResponsavelLegal.value && this.requerimento.pessoa != null && this.requerimento.pessoa.cpfCnpj === this.resultadoPesquisa[0].pessoa.cpfCnpj) {
                this.snackBarService.showAlert('O representante legal não pode ser o interessado');
                return;
            }

            const formData = new FormData();
            formData.append('numeroART', this.numeroART.value.toString());
            formData.append('representanteLegal', this.isResponsavelLegal.value ? 'true' : 'false');
            formData.append('cpfCnpj', this.resultadoPesquisa[0].pessoa.cpfCnpj);
            this.anexos.forEach(anexo => {
                if (anexo.tipo === EnumTipoDocumentoRT.ART) {
                    formData.append('fileART', anexo.arquivo);
                    formData.append('sigiloART', anexo.sigilo ? 'true' : 'false');

                }
            });

            this.requerimentoService.postResponsavelTecnico(this.requerimento.id, formData).subscribe((result) => {
                this.limparFluxoAnexos();
                this.snackBarService.showSuccess('Responsável técnico adicionado ao requerimento');
            }, (error) => {
                this.snackBarService.showError('Ocorreu um erro ao adicionar o RT ao requerimento.', error);
            });
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    putResponsavelTecnico(): void {
        if (this.formRtIsValid()) {

            const formData = new FormData();
            if (this.numeroART.dirty) {
                formData.append('numeroART', this.numeroART.value.toString());
            }

            this.anexos.forEach(anexo => {
                if (anexo.tipo === EnumTipoDocumentoRT.ART) {
                    if (anexo.arquivo) {
                        formData.append('fileART', anexo.arquivo);
                    }
                    formData.append('sigiloART', anexo.sigilo ? 'true' : 'false');

                }
            });

            if (this.isResponsavelComDocumentosPerdidos()) {
                this.requerimentoService.putResponsavelTecnicoSemArquivos(this.requerimento.id, this.resultadoPesquisa[0].pessoa.cpfCnpj, formData).subscribe((result) => {
                    this.limparFluxoAnexos();
                    this.snackBarService.showSuccess('Responsável técnico atualizado');
                }, (error) => {
                    this.snackBarService.showError('Ocorreu um erro ao atualizar o Responsável técnico.', error);
                });
                // TODO - Remover após correção dos arquivos
            } else {
                this.requerimentoService.putResponsavelTecnico(this.requerimento.id, this.resultadoPesquisa[0].pessoa.cpfCnpj, formData).subscribe((result) => {
                    this.limparFluxoAnexos();
                    this.snackBarService.showSuccess('Responsável técnico atualizado');
                }, (error) => {
                    this.snackBarService.showError('Ocorreu um erro ao atualizar o Responsável técnico.', error);
                });
            }
            // Fim remover

        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    removerAnexo(index: number): void {
        const anexo = this.anexos[index];
        anexo.arquivo = null;
        anexo.nomeArquivo = null;
        document.getElementById(`input-arquivo-${index}`)['value'] = null;
    }

    selecionarAnexo(file, index: number): void {
        if (FileValidationUtil.invalidSize(file, FileValidationUtil.size('5MB'))) {
            this.snackBarService.showAlert('Arquivo com tamanho inválido, o tamanho limite é 5MB.');
            document.getElementById(`input-arquivo-${index}`)['value'] = null;
            return;
        }
        const anexo = this.anexos[index];
        anexo.arquivo = file;
        anexo.nomeArquivo = file.name;
    }

    formRtIsValid(): boolean {
        let isValid = true;
        if (this.isTipoProcessoCriar()) {
            this.anexos.forEach(anexo => {
                if (!(anexo.arquivo instanceof File)) {
                    isValid = false;
                }
            });
        }
        if (!(this.resultadoPesquisa[0])) {
            isValid = false;
        }
        if (!this.numeroART.value) {
            isValid = false;
        }
        return isValid;
    }

    /**
     * Limpa todas as variáveis utilizadas no fluxo de adicionar o RT
     */
    limparFluxoAnexos(): void {
        this.fluxoAnexo = TipoProcesso.CRIAR;
        this.inputConsulta.setValue('');
        this.inputConsulta.markAsPristine();
        this.inputConsulta.markAsUntouched();
        this.inputConsulta.updateValueAndValidity();
        this.resultadoPesquisa = [];
        this.numeroART.reset();
        this.anexos = [];
        this.isResponsavelLegal.setValue(false);
        this.responsavelComDocumentosPerdidos = false;
        this.listarResponsaveisTecnicos();
    }

    visualizarResponsavelTecnico(responsavelTecnico: ResponsavelTecnico): void {
        this.requerimentoService.listarDocumentosDoResponsavelTecnico(
            this.requerimento.id,
            responsavelTecnico.pessoa.cpfCnpj).subscribe((result) => {
            this.resultadoPesquisa = [responsavelTecnico];
            this.fluxoAnexo = TipoProcesso.EDITAR;
            this.anexos = result;

            // TODO - Remover após correção dos arquivos
            if (!this.anexos.length) {
                this.fluxoAnexo = TipoProcesso.CRIAR;
                this.anexos = EnumTipoDocumentoRT.listarDocumentos();
                this.responsavelComDocumentosPerdidos = true;
            }
            // Fim remover

            const anexo = result.find(a => a.tipo === EnumTipoDocumentoRT.ART);
            this.numeroART.setValue(anexo.numeroART);
            this.numeroART.markAsPristine();

        }, error => {
            this.snackBarService.showError('Erro ao carregar documentos do responsável técnico.', error);
        });

    }

    // TODO - Remover após correção dos arquivos
    isResponsavelComDocumentosPerdidos(): boolean {
        return this.removeResponsavelTecnico && this.resultadoPesquisa[0].id && this.responsavelComDocumentosPerdidos;
    }

    // Fim remover

    removeResponsavelTecnico(responsavelTecnico: ResponsavelTecnico): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: responsavelTecnico.pessoa.nome}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.requerimentoService.deleteResponsavelTecnico(this.requerimento.id, responsavelTecnico.pessoa.cpfCnpj)
                    .subscribe(() => {
                            this.limparFluxoAnexos();
                            this.snackBarService.showSuccess('Responsável técnico excluído com sucesso!');
                            this.listarResponsaveisTecnicos();
                        },
                        (e) => this.snackBarService.showError('Erro ao excluir responsável técnico.', e)
                    );
            }
        });
    }

    public visualizarDocumento(documento: DocumentoRT): void {
        this.requerimentoService.downloadDocumentoResponsavelTecnico(this.requerimento.id, documento).subscribe((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    public downloadDocumento(documento: DocumentoRT): void {
        window.open(
            `${this.requerimentoService.urlResource}/${this.requerimento.id}/responsaveis-tecnicos/` +
            `${documento.responsavelTecnico.pessoa.cpfCnpj}/documentos/${EnumTipoDocumentoRT.toJson(documento.tipo)}`
        );
    }

    // Verificadores de ENUM para utilizar no HTML.
    isTipoProcessoCriar(): boolean {
        return this.fluxoAnexo === TipoProcesso.CRIAR;
    }

    isReponsavelTecnicoPessoaFisica(): boolean {
        return this.resultadoPesquisa[0] ? this.resultadoPesquisa[0].pessoa.tipo === 'PF' : false;
    }

    isTipoProcessoEditar(): boolean {
        return this.fluxoAnexo === TipoProcesso.EDITAR;
    }

    openDialogOrientacaoART(): void {
        this.dialog.open(OverlayOrientacaoArtComponent)
    }
}

enum TipoProcesso {
    EDITAR,
    CRIAR
}
