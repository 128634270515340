import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AutorizacaoQueimaControlada } from "../../../../../../../shared/models/autorizacao-queima-controlada.model";
import { QueimaControladaDto } from '../../../../../../../shared/models/queima-controlada-dto.model';
import { BaseAutorizacaoQueimaControladaService } from "../../../../../../../shared/services/base-autorizacao-queima-controlada.service";

@Injectable({
    providedIn: 'root'
})

export class QueimaControladaService extends BaseAutorizacaoQueimaControladaService<AutorizacaoQueimaControlada>{
    constructor(protected injector: Injector) {
        super(
            '/api/queima-controlada',
            injector,
            AutorizacaoQueimaControlada.prototype,
            AutorizacaoQueimaControlada.fromJson
        );
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResourceLicAutorizacaoQueimaControlada}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url, { observe: 'response'}).pipe(
            catchError(this.handleError.bind(this)),
            map(response => response.body)
        );
    }

    salvarFormEFeicoes(queimaControladaDto: QueimaControladaDto): Observable<any> {
        const url = `${this.urlResourceLicAutorizacaoQueimaControlada}/salvar-form-e-feicoes`;
        return this.http.post(url, queimaControladaDto).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}