import {BaseModel} from 'app/main/shared/models/base.model';
import {OutraFinalidade} from 'app/main/shared/models/outra-finalidade.model';

export class AtividadeServico extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public vazaoConsumo?: number,
        public outraFinalidade?: OutraFinalidade
    ) {
        super();
    }

    static fromJson(json: any): AtividadeServico {
        if (json === undefined || json === null) {
            return null;
        }
        return new AtividadeServico(
            json.id,
            json.descricao,
            json.vazaoConsumo,
            OutraFinalidade.fromJson(json.outraFinalidade)
        );
    }

}