import {Injectable, Injector} from '@angular/core';
import {Observable, of, throwError} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";
import {BaseService} from "./base.service";
import {environment} from "../../../../environments/environment";
import { Tarefa } from '../models/tarefa.model';
import { ProcessDefinition } from '../models/bpms/process-definition';

@Injectable({
    providedIn: 'root'
})
export class BpmsApiService extends BaseService<any> {
    private urlResourceBpms = environment.URL_BPMS_API;

    constructor(protected injector: Injector) {
        super('bpms', injector, {}, null);
        this.urlResourceBpms = environment.URL_BPMS_API;
        this.urlResource = environment.URL_BPMS_API;
    }

    getBusinessKey(idTarefa: string): Observable<any> {
        const url = `${this.urlResourceBpms}/api/tarefas/buscar-businesskey/${idTarefa}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getTarefaPorBusinessKey(businessKey: string): Observable<any> {
        const url = `${this.urlResourceBpms}/api/tarefas/buscar-tarefa-por-businesskey/${businessKey}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getTarefaPorId(taskId: string, fetchProcessDefinition: boolean = false): Observable<any> {
        const url = `${this.urlResourceBpms}/api/tarefas/${taskId}`;
        return this.http.get<Tarefa>(url).pipe(
            switchMap(response => {
                return fetchProcessDefinition ? this.getProcessoDefinicaoPorId(response.processDefinitionId)
                .pipe(map((processRes) => {
                    if (processRes) {
                        response.process = processRes;
                        response.processDefinitionKey = processRes.key;
                    }
                    return response;
                })) : of(response);
            }),
            catchError(this.handleError.bind(this))
        );
    }

    getProcessoPorId(id: string): Observable<any> {
        const url = `${this.urlResourceBpms}/api/processo/${id}`;
        return this.http.get<ProcessDefinition>(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getProcessoDefinicaoPorId(id: string): Observable<any> {
        const url = `${this.urlResourceBpms}/api/processo/definition/${id}`;
        return this.http.get<ProcessDefinition>(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }



    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}