import {BaseModel} from 'app/main/shared/models/base.model';

export class VinculacaoProcessoDto extends BaseModel {

    constructor(
        public requerimentoPrincipalId?: number,
        public requerimentoJuntadaId?: number
    ) {
        super();
    }

    static fromJson(json: any): VinculacaoProcessoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new VinculacaoProcessoDto(
            json.requerimentoPrincipalId,
            json.requerimentoJuntadaId
        );
    }

}
