import { Injectable, Injector } from '@angular/core';
import { EmissaoTitulo } from 'app/main/shared/models/emissao-titulo.model';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmissaoTituloService  extends BaseService<EmissaoTitulo> {
    constructor(protected injector: Injector) {
        super(
            '/api/emissao-titulo',
            injector,
            EmissaoTitulo.prototype,
            EmissaoTitulo.fromJson
        );
    }

    getByEmissaoTituloId(emissaoTituloId: number): Observable<EmissaoTitulo> {
        const url = `${this.urlResource}/${emissaoTituloId}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }


}
