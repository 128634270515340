import {BaseModel} from "./base.model";
import {ResiduoAterroIndUrbFormasTransbordoDto} from "./residuo-aterro-ind-urb-formas-transbordo-dto.model";
import {ResiduoAterroIndUrbMunicipiosDto} from "./residuo-aterro-ind-urb-municipios-dto.model";

export class ResiduoAterroIndUrbDto extends BaseModel {
    constructor(
        public id?: number,
        public residuoAterroIndUrbMunicipios?: ResiduoAterroIndUrbMunicipiosDto[],
        public residuoAterroIndUrbFormasTransbordo?: ResiduoAterroIndUrbFormasTransbordoDto[],
        public idRequerimento?: number,
        public idTipoAtividade?: number,
        public tipoResiduoIndustrial?: number,
        public tipoResiduoUrbano?: number,
        public possuiBalanca?: number,
        public capacidadeTonelada?: number,
        public capacidadeVolumetrica?: number,
        public capacidadeOperacionalTnlDia?: number,
        public pesoEspecificoLixoTnl?: number,
        public populacaoAtendidaNroHab?: number,
        public vidaUtilAnos?: number,
        public transbordoCapOperacionalTnl?: number,
        public transbordoPopulacaoAtendidahab?: number,
    ) {
        super();
    }

    static fromJson(json: any): ResiduoAterroIndUrbDto {
        json = json || {};

        return new ResiduoAterroIndUrbDto(
            json.id,
            json.residuoAterroIndUrbMunicipios,
            json.residuoAterroIndUrbFormasTransbordo,
            json.idRequerimento,
            json.idTipoAtividade,
            json.tipoResiduoIndustrial,
            json.tipoResiduoUrbano,
            json.possuiBalanca,
            json.capacidadeTonelada,
            json.capacidadeVolumetrica,
            json.capacidadeOperacionalTnlDia,
            json.pesoEspecificoLixoTnl,
            json.populacaoAtendidaNroHab,
            json.vidaUtilAnos,
            json.transbordoCapOperacionalTnl,
            json.transbordoPopulacaoAtendidahab,
        );
    }
}