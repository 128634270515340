import {Injectable, Injector} from '@angular/core';
import { FeicaoRequerimentoLicenciamentoDto } from '../models/feicao-requerimento-licenciamento-dto.model';
import { BaseService } from './base.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FeicaoRequerimentoLicenciamentoService extends BaseService<FeicaoRequerimentoLicenciamentoDto> {

    urlResource: string = environment.URL_GATEWAY;

    constructor(protected injector: Injector) {
        super(
            '/api/feicoes-requerimento-licenciamento',
            injector,
            FeicaoRequerimentoLicenciamentoDto.prototype,
            FeicaoRequerimentoLicenciamentoDto.fromJson
        );
    }

    existsFeicaoRequerimentoLicenciamento(id: number):  Observable<boolean>{
        const url = `${this.urlResource}/api/feicoes-requerimento-licenciamento/exists/${id}`; 

        return this.http.get<boolean>(url).pipe(
            map(response => response), // Supondo que a resposta seja um booleano
            catchError(() => {
                return [false];
            })
        );
    }

    updateIdObjetivoLicenciamento(idRequerimentoLicenciamento: number, idObjetivoLicenciamento: number): Observable<any> {
        const url = `${this.urlResource}/api/feicoes-requerimento-licenciamento/${idRequerimentoLicenciamento}/objetivo-licenciamento/${idObjetivoLicenciamento}`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
        
    }

}