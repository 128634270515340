import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from "../enums/base.enum";

export enum TiposUsinasValue {
    HIDRELETRICA = 'HIDRELETRICA',
    TERMOELETRICA = 'TERMOELETRICA'
}

export class GeracaoEnergia extends BaseModel {
    constructor(
        public id?: number,
        public aproveitamentoHidreletrico?: string,
        public potenciaUsina?: number,
        public quantidadeTurbinas?: number,
        public tipoUsina?: string,
    ) {
        super();
    }

    public static tiposUsinas: BaseEnum[] = [
        {valor: TiposUsinasValue.HIDRELETRICA, descricao: "Hidrelétrica"},
        {valor: TiposUsinasValue.TERMOELETRICA, descricao: "Termoelétrica"}
    ];

    static fromJson(json: any): GeracaoEnergia {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeracaoEnergia(
            json.id,
            json.aproveitamentoHidreletrico,
            json.potenciaUsina,
            json.quantidadeTurbinas,
            json.tipoUsina,
        );
    }
}
