import {BaseModel} from 'app/main/shared/models/base.model';


export class RequerimentoReaproveitamento extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: any,
        public qtdUpf?: any,
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoReaproveitamento {
        if (json === undefined || json === null) {
            return null;
        }

        return new RequerimentoReaproveitamento(
            json.id,
            json.requerimento,
            json.qtdUpf,
        );
    }
}
