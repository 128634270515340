import {CriacaoAnimal} from './criacao-animal.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {RequerimentoOutorga} from 'app/main/pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import {Finalidade} from 'app/main/pages/finalidades/finalidade.model';
import {Mineracao} from './mineracao.model';
import {Industria} from './industria.model';
import {OutraFinalidade} from './outra-finalidade.model';
import {AquiculturaTanqueEscavado} from '../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-escavado/models/aquicultura-tanque-escavado.model';
import {AquiculturaTanqueRede} from '../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-rede/models/aquicultura-tanque-rede.model';
import {GeracaoEnergia} from "./geracao-energia.model";

export class RequerimentoOutorgaFinalidade extends BaseModel {
    constructor(
        public id?: number,
        public finalidade?: Finalidade,
        public requerimentoOutorga?: RequerimentoOutorga,
        public observacao?: string,
        public criacaoAnimal?: CriacaoAnimal,
        public abastecimentoEsgotamento?: any,
        public aquiculturaTanqueEscavado?: AquiculturaTanqueEscavado,
        public aquiculturaTanqueRede?: AquiculturaTanqueRede,
        public mineracao?: Mineracao,
        public industria?: Industria,
        public outraFinalidade?: OutraFinalidade,
        public geracaoEnergia?: GeracaoEnergia,
        public cadastroConcluido?: boolean
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoOutorgaFinalidade {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoOutorgaFinalidade(
            json.id,
            Finalidade.fromJson(json.finalidade),
            RequerimentoOutorga.fromJson(json.requerimentoOutorga),
            json.observacao,
            CriacaoAnimal.fromJson(json.criacaoAnimal),
            json.abastecimentoEsgotamento,
            AquiculturaTanqueEscavado.fromJson(json.aquiculturaTanqueEscavado),
            AquiculturaTanqueRede.fromJson(json.aquiculturaTanqueRede),
            Mineracao.fromJson(json.mineracao),
            Industria.fromJson(json.industria),
            OutraFinalidade.fromJson(json.outraFinalidade),
            GeracaoEnergia.fromJson(json.geracaoEnergia),
            json.cadastroConcluido
        );
    }
}
