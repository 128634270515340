import {BaseModel} from "app/main/shared/models/base.model";
import {Requerimento} from "../requerimentos/requerimento.model";
import {ItemGrupoFeicaoTemplate} from "../feicoes-template/feicao-template.model";

export class FeicaoRequerimento extends BaseModel {
    constructor(
        public id?: number,
        public chaveGrupo?: string,
        public titulo?: string,
        public tipoGeometria?: string,
        public obrigatorio?: boolean,
        public requerimento?: Requerimento,
        public itemGrupoFeicaoTemplate?: ItemGrupoFeicaoTemplate,
        public idGeometria?: number,
    ) {
        super();
    }

    static fromJson(json: any): FeicaoRequerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new FeicaoRequerimento(
            json.id,
            json.chaveGrupo,
            json.titulo,
            json.tipoGeometria,
            json.obrigatorio,
            Requerimento.fromJson(json.requerimento),
            ItemGrupoFeicaoTemplate.fromJson(json.itemGrupoFeicaoTemplate),
            json.idGeometria,
        );
    }
}
