import {Injectable, Injector} from '@angular/core';
import {ServicoCombustivel} from "../models/servico-combustivel.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseServCombustivel} from "./base-serv-combustivel.service";

@Injectable({
    providedIn: "root"
})
export class ServicoCombustivelService extends BaseServCombustivel<ServicoCombustivel> {

    constructor(protected injector: Injector) {
        super(
            "/api/servico-combustivel",
            injector,
            ServicoCombustivel.prototype,
            ServicoCombustivel.fromJson
        );
    }

    getByRequerimento(idRequerimento: number): Observable<ServicoCombustivel> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
