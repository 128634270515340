import {BaseModel} from "./base.model";
import {TermoReferencia} from "../../pages/termos-referencia/termo-referencia.model";
import {ItemTermoReferencia} from "../../pages/itens-termos-referencia/item-termo-referencia.model";

export class TermoReferenciaComItensDto extends BaseModel {
    constructor(
        public termoReferencia?: TermoReferencia,
        public itensTermoReferencia?: ItemTermoReferencia[]
    ) {
        super();
        if (this.itensTermoReferencia.length > 0) {
            this.itensPorTipo = [];
            this.itensTermoReferencia.map(itens => {
                const itemPorTipo = this.itensPorTipo.find(i => i.tipo === itens.tipo);
                if (itemPorTipo !== undefined) {
                    itemPorTipo.itens.push(itens);
                } else {
                    this.itensPorTipo.push({
                        tipo: itens.tipo,
                        itens: [itens]
                    });
                }
            });
        }
    }

    itensPorTipo: ItensTermoPorTipo[];

    static fromJson(json: any): TermoReferenciaComItensDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new TermoReferenciaComItensDto(
            json.termoReferencia,
            ItemTermoReferencia.fromJsons(json.itensTermoReferencia)
        );
    }

    static fromJsons(json: any): TermoReferenciaComItensDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((resource: any) =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }
}

export interface ItensTermoPorTipo {
    tipo: string;
    itens: ItemTermoReferencia[];
}