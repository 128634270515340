import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CenarioRegrasGeo} from "../../../../models/geoadmin/cenario-regras-geo";

export interface RelatorioRegraInputs {
    title?: string;
    subtitle?: string;
    aplicacoes?: CenarioRegrasGeo[];
    alertMode?: boolean
}

@Component({
    selector: 'app-relatorio-regra-dialog',
    templateUrl: './relatorio-regra-dialog.component.html',
    styleUrls: ['./relatorio-regra-dialog.component.scss']
})
export class RelatorioRegraDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<RelatorioRegraDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RelatorioRegraInputs
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    continuar(): void {
        this.dialogRef.close(true);
    }
}
