import {BaseModel} from "./base.model";

export class ResiduoAterroIndUrbFormasTransbordoDto extends BaseModel {
    constructor(
        public id?: number,
        public idFormaTransbordo?: number,
        public capacidade?: number,
    ) {
        super();
    }

    static fromJson(json: any): ResiduoAterroIndUrbFormasTransbordoDto {
        json = json || {};

        return new ResiduoAterroIndUrbFormasTransbordoDto(
            json.idFormaTransbordo,
            json.capacidade,
        );
    }
}