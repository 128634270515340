import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {RequerimentoService} from '../../requerimento.service';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {DatePipe} from '@angular/common';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {MessageDialog} from '../../../../shared/components/message-dialog/message-dialog.model';
import {takeUntil} from 'rxjs/operators';
import {ErrosAbaRequerimento} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';

@Component({
    selector: 'app-requerimento-termo-responsabilidade-licenciamento',
    templateUrl: 'requerimento-termo-responsabilidade-licenciamento.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoTermoResponsabilidadeLicenciamentoComponent extends BaseTabRequerimentoComponent implements OnInit {

    pdf: any;
    checkControl: FormControl;

    constructor(
        protected service: RequerimentoService,
        private formBuilder: FormBuilder,
        public datepipe: DatePipe,
        private messageDialog: MessageDialog,
        injector: Injector
    ) {
        super(injector);
        RequerimentoSteps.TERMO.component = this;
    }

    ngOnInit(): void {
        this.helper.onCurrentStepChange().pipe(takeUntil(this._unsubscribeAll)).subscribe(e => {
            if (e.newTabIndex === RequerimentoSteps.TERMO.index) {
                this.checkControl = this.formBuilder.control({
                    value: this.requerimento.concordouTermosResponsabilidade,
                    disabled: this.isSomenteVisualizacao
                });
            }
        });
    }

    statusTermo(): void {
        const check = this.checkControl.value;
        this.service.statusTermo(this.requerimento.id, check)
            .subscribe(() => {
                this.requerimento.concordouTermosResponsabilidade = check;
                return;
            });
    }

    validarAba(erros: ErrosAbaRequerimento): void {
        if (!this.checkControl.value) {
            erros.push('É obrigatório concordar com os Termos de Responsabilidade.');
        }
    }
}
