import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {DoencaGrave} from './doenca-grave.model';
import {Cemiterio} from "./cemiterio.model";
import {Crematorio} from "./crematorio.model";

export class EmpreendimentoCemiterioCrematorio extends BaseModel {
    constructor(
        public id?: number,
        public cemiterio?: Cemiterio,
        public crematorio?: Crematorio,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoCemiterioCrematorio {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoCemiterioCrematorio(
            json.id,
            Cemiterio.fromJson(json.cemiterio),
            Crematorio.fromJson(json.crematorio),
            json.requerimento
        );
    }

    static fromJsons(json: any): EmpreendimentoCemiterioCrematorio[] {
        const empreendimentos: EmpreendimentoCemiterioCrematorio[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(empreendimento => empreendimentos.push(this.fromJson(empreendimento)));
            return empreendimentos;
        }
    }

}
