import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {ObjetivoParametrizacaoObjetivo} from "../../models/licenciamento-config-models/objetivo-parametrizacao-objetivo.model";
import {environment} from "../../../../../environments/environment";
import {BaseService} from "../base.service";

@Injectable({
    providedIn: 'root'
})
export class ObjetivoParametrizacaoObjetivoService extends BaseService<ObjetivoParametrizacaoObjetivo> {

    constructor(protected injector: Injector) {
        super('', injector,
            ObjetivoParametrizacaoObjetivo.prototype,
            ObjetivoParametrizacaoObjetivo.fromJson);
        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/objetivo-parametro-objetivo'
    }

    getByParametrizacaoObjetivo(idParametrizacaoObjetivo: number, httpParams?: HttpParams): Observable<ObjetivoParametrizacaoObjetivo[]> {
        const url = `${this.urlResource}/parametro-objetivo/${idParametrizacaoObjetivo}`;

        return this.http.get(url, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getByIdTitulacao(idObjetivoLicenc: number, httpParams?: HttpParams): Observable<any> {
        const url = `${this.urlResource}/busca-id-titulacao-por-id-obj/${idObjetivoLicenc}`;

        return this.http.get(url, {params: httpParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getIdTitulacaoByIdObjetivoLic(idObjetivoLicenc: number, httpParams?: HttpParams): Observable<any> {
        const url = `${this.urlResource}/busca-id-titulacao-por-id-obj/${idObjetivoLicenc}`;

        return this.http.get(url, {params: httpParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }


}