import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {Observable, throwError} from 'rxjs';
import {ResiduoRccDto} from '../../core/models/residuo-rcc';
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ResiduoRccService {

    baseUrl = `${environment.URL_LIC_RES_CONSTR_CIVIL}/api/residuo-rcc`;

    constructor(private httpClient: HttpClient) {
    }

    create(residuoRccDto: ResiduoRccDto): Observable<ResiduoRccDto> {
        console.log("Enviando residuoRccDto...", JSON.stringify(residuoRccDto));
        return this.httpClient.post<any>(this.baseUrl, residuoRccDto).pipe(
            map(response => response),
            catchError(e => throwError(e)));
    }

    readById(id: string): Observable<ResiduoRccDto> {
        const url = `${this.baseUrl}/${id}`;
        return this.httpClient.get<any>(url).pipe(
            map(response => response),
            catchError(e => throwError(e)));
    }

    update(residuoRcc: ResiduoRccDto): Observable<ResiduoRccDto> {
        const url = `${this.baseUrl}/${residuoRcc.id}`;
        return this.httpClient.put<any>(url, residuoRcc).pipe(
            map(response => response),
            catchError(e => throwError(e)));
    }

    delete(id: string): Observable<ResiduoRccDto> {
        const url = `${this.baseUrl}/${id}`;
        return this.httpClient.delete<ResiduoRccDto>(url).pipe(
            map(response => response),
            catchError(e => throwError(e)));
    }

    read(): Observable<ResiduoRccDto[]> {
        return this.httpClient.get<ResiduoRccDto[]>(`${this.baseUrl}/lista`).pipe(
            map(response => response),
            catchError(e => throwError(e)));
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.baseUrl}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.httpClient.get(url).pipe(
            map(response => response),
            catchError(e => throwError(e))
        );
        ;
    }
}
