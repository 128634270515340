import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BalancoMassaResiduoRcc, BalancoMassaResiduoRccDto } from '../../core/models/balanco-massa-residuo-rcc';

@Injectable({
  providedIn: 'root'
})
export class BalancoMassaResiduoRccService {

  baseUrl = `${environment.URL_LIC_RES_CONSTR_CIVIL}/api/residuo-rcc-balanco-massa`;

  constructor(private httpClient: HttpClient) { }

  create(balancoMassaResiduo: BalancoMassaResiduoRcc): Observable <BalancoMassaResiduoRccDto> {
    return this.httpClient.post<BalancoMassaResiduoRccDto>(`${this.baseUrl}`, balancoMassaResiduo).
    pipe(map(response => response),
      catchError(e => throwError(e)))
  }

  readById(id: string): Observable<BalancoMassaResiduoRccDto> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpClient.get<BalancoMassaResiduoRccDto>(url).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }

  update(balancoMassaResiduo: BalancoMassaResiduoRccDto): Observable<BalancoMassaResiduoRccDto> {
    const url = `${this.baseUrl}/${balancoMassaResiduo.id}`;
    return this.httpClient.put<BalancoMassaResiduoRccDto>(url, balancoMassaResiduo).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }

  delete(id: string): Observable<BalancoMassaResiduoRccDto> {
    const url = `${this.baseUrl}/${id}`;
    return this.httpClient.delete<BalancoMassaResiduoRccDto>(url).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }

  read(): Observable<BalancoMassaResiduoRccDto[]> {
    return this.httpClient.get<BalancoMassaResiduoRccDto[]>(`${this.baseUrl}/lista`).pipe(
      map(response => response),
      catchError(e => throwError(e)));
  }
}
