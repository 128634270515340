import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AnaliseJustificativaPausa} from '../../requerimento-analise/analise-justificativa.pausa.model';
import {AnaliseRequerimentoAnalista} from '../model/analise-requerimento-analista.model';
import { FormGroup } from '@angular/forms';
import {Requerimento} from "../../../requerimentos/requerimento.model";

@Injectable({
    providedIn: 'root',
})
export class AnaliseRequerimentoAnalistaService extends BaseService<AnaliseRequerimentoAnalista> {
    constructor(protected injector: Injector) {
        super(
            '/api/analista-requerimento',
            injector,
            AnaliseRequerimentoAnalista.prototype,
            AnaliseRequerimentoAnalista.fromJson
        );
    }

    createAtribuicaoAnalista(resource: any): Observable<AnaliseRequerimentoAnalista> {
        return this.http.post(this.urlResource, resource).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    recuperaAnalistaLogado(idRequerimento: number): Observable<AnaliseRequerimentoAnalista> {
        return this.http.get(`${this.urlResource}/${idRequerimento}/logado`).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    finalizarAnaliseCamunda(idTarefa: string, solicitacao: any): Observable<any> {
        const url = `${this.urlResource}/concluir-tarefa-analise/${idTarefa}/`;
        return this.http.post(url, solicitacao).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    finalizarAnaliseTermoReferenciaEspecificoCamunda(idTarefa: string, solicitacao: any): Observable<any> {
        const url = `${this.urlResource}/concluir-tarefa-elaborar-termo-referencia/${idTarefa}/especifico`;
        return this.http.post(url, solicitacao).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }


    pausarIniciarTarefaAnaliseCamunda(formAnalisePausa: any, idTarefa: string, isPausa: boolean, requerimentoId: number): Observable<any> {
        const url = `${this.urlResource}/pausar-tarefa/${idTarefa}/${isPausa}/${requerimentoId}`;
        return this.http.put(url, formAnalisePausa).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getStatusTarefa(idRequerimento: number): Observable<boolean> {
        const url = `${this.urlResource}/analista-analise-pausada/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getMotivoPausa(analiseRequerimentoEquipe: AnaliseRequerimentoAnalista): Observable<AnaliseJustificativaPausa> {
        const url = `${this.urlResource}/justificativa-pausa/${analiseRequerimentoEquipe ? analiseRequerimentoEquipe.id : 0}`;
        return this.http.get(url).pipe(
            map(AnaliseJustificativaPausa.fromJson.bind(this)),
            catchError(this.handleError)
        );
    }

    updateVisualizacaoAnalise(idRequerimento: number): Observable<AnaliseRequerimentoAnalista> {
        const url = `${this.urlResource}/alterar-visualizacao-analise/${idRequerimento}`;
        return this.http.put(url, {}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    alterarPercentualConcluido(percentualConcluido: number, idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/alterar-percentual-concluido/${idRequerimento}`;
        return this.http.put(url, percentualConcluido).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getImpedimentosAnalista(idAnalista: number): Observable<any> {
        const url = `${this.urlResource}/impedimentos-analista/${idAnalista}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getQuantidadeTarefasAnalista(idAnalista: any): Observable<any> {
        const url = `${this.urlResource}/quantidade-tarefas-analise/${idAnalista}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    solicitarRevisaoRequerimento(requerimentoId: number, tarefaId: number, formSolicitacaoRevisao: FormGroup): Observable<any> {
        const url = `${this.urlResource}/${requerimentoId}/solicitacao-revisao/${tarefaId}/tarefa`;
        return this.http.post(url, formSolicitacaoRevisao).pipe(catchError(this.handleError.bind(this)));
    }

    buscarNumeroTarefasSetor(idSetor: number): Observable<any> {
        const url = `${this.urlResource}/numero-tarefas-por-setor/${idSetor}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarAnalistasAtribuidosPorEquipeMultidisciplinar(idEquipeMult: number): Observable<any> {
        const url = `${this.urlResource}/buscar-por-atribuicao-equipe-multidisciplinar/${idEquipeMult}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarTodasAtribuicoesPorRequerimento(idRequerimento: number): Observable<AnaliseRequerimentoAnalista[]> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}/todas-atribuicoes-por-requerimento`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}