import {BaseModel} from 'app/main/shared/models/base.model';
import {AreaAbastecimento} from "./area-abastecimento.model";


export class ServicoCombustivel extends BaseModel {
    constructor(
        public id?: number,
        public comercioVarejista?: number,
        public comercioAtacadista?: number,
        public pontoAbastecimento?: number,
        public transpRevendedorRetalhista?: number,
        public pontoAbastecimentoAereo?: number,
        public area?: AreaAbastecimento,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): ServicoCombustivel {
        if (json === undefined || json === null) {
            return null;
        }

        return new ServicoCombustivel(
            json.id,
            json.comercioVarejista,
            json.comercioAtacadista,
            json.pontoAbastecimento,
            json.transpRevendedorRetalhista,
            json.pontoAbastecimentoAereo,
            AreaAbastecimento.fromJson(json.area),
            json.requerimento,
        );
    }
}
