import {DocumentoEntidade} from 'app/main/shared/models/base-documento-entidade.model';
import {RepresentanteLegal} from 'app/main/shared/models/representante-legal.model';

export class RepresentanteLegalDocumentoCadastro extends DocumentoEntidade {

    constructor(
        public id?: number,
        public titulo?: string,
        public numeroRegistroClasse?: string,
        public nomeArquivo?: string,
        public representanteLegal?: RepresentanteLegal,
        public chave?: string,
        public tipoDocumento?: string,
        public idDocumentoCorporativo?: number,
        public idDocumentoTemplateCorporativo?: number,
        public sigilo?: boolean,
        public excluido?: boolean,
        public arquivo?: any, // MultiPart / Blob
        public acao?: string,
    ) {
        super();
    }

    static fromJson(json: any): RepresentanteLegalDocumentoCadastro {
        if (json === undefined || json === null) {
            return null;
        }
        return new RepresentanteLegalDocumentoCadastro(
            json.id,
            json.titulo,
            json.numeroRegistroClasse,
            json.nomeArquivo,
            RepresentanteLegal.fromJson(json.representanteLegal),
            json.chave,
            json.tipoDocumento,
            json.idDocumentoCorporativo,
            json.idDocumentoTemplateCorporativo,
            json.sigilo,
            json.excluido,
            json.arquivo,
            json.acao,
        );
    }

    static fromJsons(json: any): RepresentanteLegalDocumentoCadastro[] {
        const jsons: RepresentanteLegalDocumentoCadastro[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(e => {
                jsons.push(this.fromJson(e));
            });
            return jsons;
        }
    }

}
