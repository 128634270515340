import {BaseEnum} from "../../../../../shared/enums/base.enum";


export enum HistoricoRequerimentoEnum {
    CRIACAO_REQUERIMENTO = 'CRIACAO_REQUERIMENTO',
    CONCLUSAO_REQUERIMENTO = 'CONCLUSAO_REQUERIMENTO',
    CANCELAMENTO_REQUERIMENTO = 'CANCELAMENTO_REQUERIMENTO',
    ARQUIVADO_DEFINITIVAMENTE = 'ARQUIVADO_DEFINITIVAMENTE',
    FINALIZACAO_REQUERIMENTO = 'FINALIZACAO_REQUERIMENTO',
    CHECKLIST_REPROVADO = 'CHECKLIST_REPROVADO',
    CHECKLIST_APROVADO = 'CHECKLIST_APROVADO',
    AGUARDANDO_PAGAMENTO_TAXA = 'AGUARDANDO_PAGAMENTO_TAXA',
    PAGAMENTO_CONFIRMADO = 'PAGAMENTO_CONFIRMADO',
    PROTOCOLO = 'PROTOCOLO',
    AGUARDANDO_ANALISE = 'AGUARDANDO_ANALISE',
    EM_ANALISE = 'EM_ANALISE',
    NOTIFICAR_COMPLEMENTACAO = 'NOTIFICAR_COMPLEMENTACAO',
    COMPLEMENTACAO_RECUSADA = 'COMPLEMENTACAO_RECUSADA',
    COMPLEMENTACAO_ATENDIDA = 'COMPLEMENTACAO_ATENDIDA',
    CERTIDAO_DE_NAO_MANIFESTACAO = 'CERTIDAO_DE_NAO_MANIFESTACAO',
    TITULOS_EMITIDOS = 'TITULOS_EMITIDOS',
    TITULACOES_DISPONIVEIS = 'TITULACOES_DISPONIVEIS',
    AGUARDANDO_CONFERENCIA = 'AGUARDANDO_CONFERENCIA',
    EM_CONFERENCIA = 'EM_CONFERENCIA',
    EM_REVISAO = 'EM_REVISAO',
    EM_CORRECAO = 'EM_CORRECAO',
    AGUARDANDO_REVISAO = 'AGUARDANDO_REVISAO',
    EM_COMPLEMENTACAO = 'EM_COMPLEMENTACAO',
    REQUERIMENTO_REVISADO = 'REQUERIMENTO_REVISADO',
    AGUARDANDO_CORRECAO_REQUERIMENTO = 'AGUARDANDO_CORRECAO_REQUERIMENTO',
    PRAZO_CORRECAO_EXPIRADO = 'PRAZO_CORRECAO_EXPIRADO',
    REQUERIMENTO_CORRIGIDO = 'REQUERIMENTO_CORRIGIDO'
}

export class HistoricoRequerimento {
    constructor(
        public id?: number,
        public dataCadastro?: Date,
        public evento?: string
    ) {
    }

    static historicosRequerimento: BaseEnum[] = [
        {valor: HistoricoRequerimentoEnum.CRIACAO_REQUERIMENTO, descricao: 'Criação do requerimento'},
        {valor: HistoricoRequerimentoEnum.CONCLUSAO_REQUERIMENTO, descricao: 'Conclusão do requerimento'},
        {valor: HistoricoRequerimentoEnum.CANCELAMENTO_REQUERIMENTO, descricao: 'Cancelamento do requerimento'},
        {valor: HistoricoRequerimentoEnum.ARQUIVADO_DEFINITIVAMENTE, descricao: 'Arquivado definitivamente'},
        {valor: HistoricoRequerimentoEnum.FINALIZACAO_REQUERIMENTO, descricao: 'Finalização do requerimento'},
        {valor: HistoricoRequerimentoEnum.CHECKLIST_REPROVADO, descricao: 'Checklist reprovado'},
        {valor: HistoricoRequerimentoEnum.CHECKLIST_APROVADO, descricao: 'Checklist aprovado'},
        {valor: HistoricoRequerimentoEnum.AGUARDANDO_PAGAMENTO_TAXA, descricao: 'Aguardando pagamento da taxa'},
        {valor: HistoricoRequerimentoEnum.PAGAMENTO_CONFIRMADO, descricao: 'Pagamento confirmado'},
        {valor: HistoricoRequerimentoEnum.PROTOCOLO, descricao: 'Protocolo'},
        {valor: HistoricoRequerimentoEnum.AGUARDANDO_ANALISE, descricao: 'Aguardando análise'},
        {valor: HistoricoRequerimentoEnum.EM_ANALISE, descricao: 'Em análise'},
        {valor: HistoricoRequerimentoEnum.NOTIFICAR_COMPLEMENTACAO, descricao: 'Notificar complementação'},
        {valor: HistoricoRequerimentoEnum.COMPLEMENTACAO_RECUSADA, descricao: 'Complementação recusada'},
        {valor: HistoricoRequerimentoEnum.COMPLEMENTACAO_ATENDIDA, descricao: 'Complementação atendida'},
        {valor: HistoricoRequerimentoEnum.CERTIDAO_DE_NAO_MANIFESTACAO, descricao: 'Certidão de não manifestação'},
        {valor: HistoricoRequerimentoEnum.TITULOS_EMITIDOS, descricao: 'Títulos emitidos'},
        {valor: HistoricoRequerimentoEnum.TITULACOES_DISPONIVEIS, descricao: 'Títulações disponíveis'},
        {valor: HistoricoRequerimentoEnum.AGUARDANDO_CONFERENCIA, descricao: 'Aguardando conferência'},
        {valor: HistoricoRequerimentoEnum.EM_CONFERENCIA, descricao: 'Em conferência'},
        {valor: HistoricoRequerimentoEnum.REQUERIMENTO_REVISADO, descricao: 'Requerimento revisado'},
        {valor: HistoricoRequerimentoEnum.EM_REVISAO, descricao: 'Em revisão'},
        {valor: HistoricoRequerimentoEnum.AGUARDANDO_REVISAO, descricao: 'Solicitação de revisão'},
        {valor: HistoricoRequerimentoEnum.EM_CORRECAO, descricao: 'Em correção'},
        {valor: HistoricoRequerimentoEnum.AGUARDANDO_CORRECAO_REQUERIMENTO, descricao: 'Aguardando correção do requerimento'},
        {valor: HistoricoRequerimentoEnum.PRAZO_CORRECAO_EXPIRADO, descricao: 'Correção do requerimento expirada'},
        {valor: HistoricoRequerimentoEnum.REQUERIMENTO_CORRIGIDO, descricao: 'Correção do requerimento concluída'},
        
    ];

    static fromJson(json: any): HistoricoRequerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new HistoricoRequerimento(
            json.id ? json.id : null,
            json.dataCadastro ? json.dataCadastro : null,
            json.evento ? json.evento : null
        );
    }
}