import {Component, Input} from '@angular/core';
import {SistemaTratamentoSecundario} from "../../../../../../../../shared/models/sistema-tratamento-secundario.model";
import {SistemaTratamentoSecundarioService} from "../../../../../../../../shared/services/sistema-tratamento-secundario.service";
import {AbstractComponenteRelacionamentoSubdominio} from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento-subdominio";

@Component({
    selector: 'app-atv-sist-tratamento-secundario',
    templateUrl: './atv-sist-tratamento-secundario.component.html',
    styleUrls: ['./atv-sist-tratamento-secundario.component.scss']
})
export class AtvSistTratamentoSecundarioComponent extends AbstractComponenteRelacionamentoSubdominio<SistemaTratamentoSecundario, SistemaTratamentoSecundarioService> {

    @Input() isNaoPodeEditar: boolean;

    getDisplayedColumns(): string[] {
        return ['tipo', 'subtipo', 'descricao', 'acoes'];
    }

    apresentaSubtipo() {
        if (this.novoDado.dominio) {
            switch (this.novoDado.dominio.descricao) {
                case "Lagoa":
                case "Reator anaeróbio":
                case "Lodos ativados":
                    return true;
                default:
                    return false;
            }
        }
    }

}
