import {NgModule} from '@angular/core';
import {MatFormFieldModule, MatRadioModule, MatSelectModule, MatTableModule} from '@angular/material';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {RequerimentoDocumentacaoComplementarComponent} from './requerimento-documentacao-complementar.component';

@NgModule({
    declarations: [RequerimentoDocumentacaoComplementarComponent],
    imports: [
        CoreModule,
        SharedModule,
        MatFormFieldModule,
        MatSelectModule,
        MatRadioModule,
        MatTableModule
    ],
    exports: [RequerimentoDocumentacaoComplementarComponent],
    entryComponents: [RequerimentoDocumentacaoComplementarComponent]
})
export class RequerimentoDocumentacaoComplementarModule {
}
