import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseManifestacaoService} from "./base-manifestacao.service";
import {AnexoArquivo} from "../../models/manifestacao/anexo-arquivo.model";
import {RespostaAnexo} from "../../models/manifestacao/resposta-anexo.model";

@Injectable({
    providedIn: 'root'
})
export class AnexoArquivoService extends BaseManifestacaoService<AnexoArquivo> {

    constructor(injector: Injector) {
        super('/api/resposta-anexo', injector, AnexoArquivo.prototype, AnexoArquivo.fromJson)

    }

    salvarRespostaAnexo(formData: FormData): Observable<AnexoArquivo> {
        const url = `${this.urlResource}/anexo-arquivo`;
        return this.http
        .post(url, formData)
        .pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getRespostaAnexoPorIdResposta(idResposta: number): Observable<RespostaAnexo[]> {
        const url = `${this.urlResource}/busca-anexo/${idResposta}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}