import {BaseModel} from "./base.model";
import {EmpreendimentoResiduosGerais} from "./empreendimento-residuos-gerais.model";
import {ResiduosGeraisResiduosSolidos} from "./residuos-gerais-residuos-solidos.model";

export class ResiduosGeraisDto extends BaseModel {
    constructor(
        public empreendimentoResiduosGerais?: EmpreendimentoResiduosGerais,
        public listaResiduosSolidos?: ResiduosGeraisResiduosSolidos[],
    ) {
        super();
    }

    static fromJson(json: any): ResiduosGeraisDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new ResiduosGeraisDto(
            EmpreendimentoResiduosGerais.fromJson(json.empreendimentoResiduosGerais),
            ResiduosGeraisResiduosSolidos.fromJsons(json.listaResiduosSolidos),
        );
    }

    static fromJsons(json: any): ResiduosGeraisDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(ResiduosGeraisDto.fromJson(j)));
            return resources;
        }
    }
}