import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
    selector: 'app-obra-drenagem',
    templateUrl: './obra-drenagem.component.html',
    styleUrls: ['./obra-drenagem.component.scss']
})
export class ObraDrenagemComponent {
    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;

    zonas: Array<DominioDto> = [];

    constructor(private dominiosService: DominiosService) {
    }

    async ngOnInit() {
        this.zonas = await this.dominiosService.getDominioByUrl('tipos-zona-obra-fluv-saneam').toPromise();

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }
}
