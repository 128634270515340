import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {fuseAnimations} from '@fuse/animations';
import {Finalidade} from '../../finalidades/finalidade.model';
import {FinalidadeService} from '../../finalidades/finalidade.service';
import {Validators} from '@angular/forms';
import {AtividadeArrecadacao} from '../../../shared/models/arrecadacao-models/atividade-arrecadacao.model';
import {AtividadeArrecadacaoService} from '../../../shared/services/arrecadacao-service/atividade-arrecadacao.service';
import {FinalidadeAtividade} from '../finalidade-atividade.model';
import {FinalidadeAtividadeService} from '../finalidade-atividade.service';

@Component({
    selector: 'app-finalidade-atividade-detail',
    templateUrl: './finalidade-atividade-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class FinalidadeAtividadeDetailComponent extends BaseDetailComponent<FinalidadeAtividade> implements OnInit {

    atividades: AtividadeArrecadacao[];
    finalidades: Array<Finalidade>;
    compareAtividadeWith = AtividadeArrecadacao.compareWith;

    constructor(
        protected finalidadeAtividadeService: FinalidadeAtividadeService,
        protected atividadeArrecadacaoService: AtividadeArrecadacaoService,
        protected finalidadeService: FinalidadeService,
        protected injector: Injector
    ) {
        super(injector, new FinalidadeAtividade(), finalidadeAtividadeService, FinalidadeAtividade.fromJson);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.carregarAtividades();
        this.carregarFinalidades();
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            atividade: [null, [Validators.required]],
            finalidade: [null, [Validators.required]]
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova finalidade / atividade';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.finalidade.descricao + ' - ' + this.resource.atividade.descricao || '';
        return 'Editando a finalidade / atividade: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.finalidade.descricao + ' - ' + this.resource.atividade.descricao || '';
        return 'Visualizando a finalidade / atividade: ' + resourceName;
    }

    private carregarFinalidades(): void {
        this.finalidadeService.getAll().subscribe(
            finalidades => this.finalidades = finalidades
        );
    }

    private carregarAtividades(): void {
        this.atividadeArrecadacaoService.getAllAtividades().subscribe(
            atividades => this.atividades = atividades
        );
    }

}

