import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {BaseModel} from 'app/main/shared/models/base.model';

export class PontoVazaoMensal extends BaseModel {
    constructor(
        public id?: number,
        public mes?: string,
        public vazao?: number,
        public tempo?: number,
        public periodo?: number,
        public ponto?: Ponto
    ) {
        super();
    }

    static fromJson(json: any): PontoVazaoMensal {
        if (json === undefined || json === null) {
            return null;
        }
        return new PontoVazaoMensal(
            json.id,
            json.mes,
            json.vazao,
            json.tempo,
            json.periodo,
            Ponto.fromJson(json.ponto)
        );
    }
}
