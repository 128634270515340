import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HidricoService } from 'app/main/shared/services/hidrico-service/hidrico.service';
import { CancelarBalancoHidricoRequest } from '../devolver-recurso-hidrico-detail/devolver-recurso-hidrico-detail.component';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { RequerimentoService } from '../../requerimentos/requerimento.service';
import { Requerimento } from '../../requerimentos/requerimento.model';

@Component({
  selector: 'app-dialog-confirmacao-devolucao-recurso',
  templateUrl: './dialog-confirmacao-devolucao-recurso.component.html',
  styleUrls: ['./dialog-confirmacao-devolucao-recurso.component.scss']
})
export class DialogConfirmacaoDevolucaoRecursoComponent implements OnInit {

    coordenadaId?: number;
    cpfExecutor?: string;
    nomeExecutor?: string;
    justificativa?: string;
    numeroProcesso?: string;
    requerimento?: Requerimento;
    recursoHidricoComTituloEmitido: boolean = false;
    cancelarBalancoHidricoRequest: CancelarBalancoHidricoRequest = null;

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmacaoDevolucaoRecursoComponent>,
        private hidricoService: HidricoService,
        private snackBarService: SnackBarService,
        private requerimentoService: RequerimentoService,
        @Inject(MAT_DIALOG_DATA) protected data: any,
    ) { }

    ngOnInit() {
        this.coordenadaId = this.data.idCoordenada;
        this.cpfExecutor = this.data.cpfServidor;
        this.nomeExecutor = this.data.nomeServidor;
        this.justificativa = this.data.justificativa;
        //this.consultarRequerimentoProcesso();
    }

    devolverRecursoHidrico() {
        this.hidricoService.devolverRecursoHidrico(this.montarObjetoCancelarBalancoHidrico()).subscribe(result => {
            this.dialogRef.close('update');
            this.snackBarService.showSuccess("Registro salvo com sucesso.");
        }, e => {
            this.snackBarService.showError(e.error.errors[0] === '' ? e.error.message : e.error.errors[0]);
        });
    }

    montarObjetoCancelarBalancoHidrico(): CancelarBalancoHidricoRequest {
        return this.cancelarBalancoHidricoRequest = new CancelarBalancoHidricoRequest(
            this.coordenadaId,
            this.cpfExecutor,
            this.nomeExecutor,
            this.justificativa,
            this.recursoHidricoComTituloEmitido
        );
    }

    consultarRequerimentoProcesso() {
        this.requerimentoService.getRequerimento(this.numeroProcesso)
            .subscribe(result => {
                this.requerimento = result;
            });
    }

    setRecursoHidricoComTituloEmitido(completed: boolean) {
        this.recursoHidricoComTituloEmitido = completed;
      }

}
