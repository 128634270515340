import {Municipio} from './geoportal-base-referencia/municipio.model';

export class GeometriaDTO {
    constructor(
        public id?: number,
        public latitude?: number,
        public longitude?: number,
        public municipio?: Municipio,
        public wkt?: string
    ) {
    }

    static fromJson(json: any): GeometriaDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeometriaDTO(
            json.id,
            json.latitude,
            json.longitude,
            json.municipio,
            json.wkt
        );
    }

    static fromJsons(jsonArray: any): GeometriaDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
