import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ErrosAbaRequerimento} from '../requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';

@Component({
    selector: 'app-requerimento-validacao-dialog',
    templateUrl: './requerimento-validacao-dialog.component.html'
})
export class RequerimentoValidacaoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<RequerimentoValidacaoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { errosAbasRequerimento: ErrosAbaRequerimento[], final: boolean }
    ) {
    }

    cancelar(): void {
        this.dialogRef.close(false);
    }

    continuar(): void {
        this.dialogRef.close(true);
    }

    temRestritivo(): boolean {
        return this.errosAbasRequerimento.some(errosAbaRequerimento => errosAbaRequerimento.temRestritivo);
    }

    get errosAbasRequerimento(): ErrosAbaRequerimento[] {
        return this.data.errosAbasRequerimento;
    }

    get final(): boolean {
        return this.data.final;
    }
}
