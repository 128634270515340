import {Titulacao} from '../../pages/titulacoes/titulacao.model';
import {BaseEnum} from '../enums/base.enum';
import {Requerimento} from '../../pages/requerimentos/requerimento.model';

export class LancamentoTaxaDto {

    constructor(
        public id?: number,
        public mensagem?: number,
        public dataVencimento?: Date,
        public dataPagamento?: Date,
        public dataEmissao?: Date,
        public statusProcessado?: string,
        public valorDesconto?: number,
        public valorReal?: number,
        public qtdeUnitarioUPF?: number,
        public qtdeTotalUPF?: number,
        public qtdePonto?: number,
        public requerimento?: Requerimento,
        public chavePdfGed?: string,
        public codigoReceitaGrupo?: string,
        public codigoReceitaNatureza?: string,
        public numeroDar?: string,
        public titulacao?: Titulacao,
        public idAtividadeCalculo?: number,
        public utilizarCredito?: string,
        public taxaIsenta?: boolean
    ) {
    }

    public static enumStatus: BaseEnum[] = [
        {valor: 'TODOS', descricao: 'Todos'},
        {valor: 'AGUARDANDO_EMISSAO', descricao: 'Aguardando Emissão'},
        {valor: 'AGUARDANDO_PAGAMENTO', descricao: 'Aguardando pagamento'},
        {valor: 'VENCIDA', descricao: 'Vencida'},
        {valor: 'PAGA', descricao: 'Paga'},
        {valor: 'CANCELADA', descricao: 'Cancelada'},
    ];

    static fromJson(json: any): LancamentoTaxaDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new LancamentoTaxaDto(
            json.id,
            json.mensagem,
            json.dataEmissao ? new Date(json.dataEmissao) : null,
            json.dataPagamento ? new Date(json.dataPagamento) : null,
            json.dataVencimento ? new Date(json.dataVencimento) : null,
            json.statusProcessado,
            json.valorDesconto,
            json.valorReal,
            json.qtdeUnitarioUPF,
            json.qtdeTotalUPF,
            json.qtdePonto,
            Requerimento.fromJson(json.requerimento),
            json.chavePdfGed,
            json.codigoReceitaGrupo,
            json.codigoReceitaNatureza,
            json.numeroDar,
            Titulacao.fromJson(json.titulacao),
            json.idAtividadeCalculo,
            json.utilizarCredito,
            json.taxaIsenta
        );
    }

    static fromJsons(json: any): LancamentoTaxaDto[] {
        const lancamentoTaxas: LancamentoTaxaDto[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(lancamento => lancamentoTaxas.push(this.fromJson(lancamento)));
            return lancamentoTaxas;
        }
    }
}
