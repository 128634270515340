import {BaseModel} from "./base.model";
import {QueimaControladaFeicoes} from './queima-controlada-feicoes.model';
import {AutorizacaoQueimaControlada} from './autorizacao-queima-controlada.model';


export class QueimaControladaDto extends BaseModel {
    constructor(
        public queimaControlada?: AutorizacaoQueimaControlada,
        public quadroDeFeicoes?: QueimaControladaFeicoes[],
    ) {
        super();
    }

    static fromJson(json: any): QueimaControladaDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new QueimaControladaDto(
            AutorizacaoQueimaControlada.fromJson(json.queimaControlada),
            QueimaControladaFeicoes.fromJsons(json.quadroDeFeicoes),
        );
    }

    static fromJsons(json: any): QueimaControladaDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(QueimaControladaDto.fromJson(j)));
            return resources;
        }
    }
}