import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource} from '@angular/material';
import {RequerimentoService} from '../../../../pages/requerimentos/requerimento.service';
import {SnackBarService, SnackBarType} from 'app/main/shared/snack-bar/snack-bar.service';
import { GerenciarRequerimentoProcessoService } from '../../gerenciar-requerimento-processo.service';
import { ObservacaoDistribuicao } from './observacao-distribuicao.model';
import { fuseAnimations } from '@fuse/animations';

@Component({
    templateUrl: 'dialog-observacao-requerimento-distribuido.component.html',
    styleUrls: ['dialog-observacao-requerimento-distribuido.component.scss']
})

export class DialogObservacaoRequerimentoDistribuidoComponent implements OnInit {

    displayedColumns: string[] = ['dataHora', 'observacao'];
    dataSource: MatTableDataSource<ObservacaoDistribuicao>;

    constructor(private dialogRef: MatDialogRef<DialogObservacaoRequerimentoDistribuidoComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private snackBarService: SnackBarService,
                public gerenciarRequerimentoProcessoService: GerenciarRequerimentoProcessoService,
                protected requerimentoService: RequerimentoService,
    ) {

    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<ObservacaoDistribuicao>();
        this.getObservacao();
    }

    private getObservacao() {
        this.gerenciarRequerimentoProcessoService.getObservacaoRequerimentoDistribuido(this.data.requerimentoId).subscribe(response => {
            this.dataSource.data = response;
        });
    }

    public closeDialog(): void {
        this.dialogRef.close(false);
    }
}
