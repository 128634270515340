import {BaseModel} from 'app/main/shared/models/base.model';

export class CriacaoAnimal extends BaseModel {
    constructor(
        public id?: number,
        public areaPropriedade?: number,
        public areaEmpreendimento?: number
    ) {
        super();
    }

    static fromJson(json: any): CriacaoAnimal {
        if (json === undefined || json === null) {
            return null;
        }
        return new CriacaoAnimal(
            json.id,
            json.areaPropriedade? json.areaPropriedade : null,
            json.areaEmpreendimento? json.areaEmpreendimento : null
        );
    }
}
