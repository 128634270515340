import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { RequerimentoTitulacao } from './shared/requerimento-titulacao.model';
import { MatDialog } from '@angular/material';
import { RequerimentoService } from 'app/main/pages/requerimentos/requerimento.service';
import { SnackBarService } from '../../snack-bar/snack-bar.service';
import { DialogConfirmacaoComponent } from '../dialog-confirmacao/dialog-confirmacao.component';
import { RequerimentoTitulacaoService } from './shared/requerimento-titulacao.service';

@Component({
  selector: 'app-titulos-solicitados',
  templateUrl: './titulos-solicitados.component.html',
  styleUrls: ['./titulos-solicitados.component.scss']
})
export class TitulosSolicitadosComponent implements OnInit {

    @Input() requerimento: Requerimento;
    @Input() isSomenteVisualizacao = false;
    @Input() deletedEmitter = new EventEmitter();
    @Output() acaoUpdateEmitter = new EventEmitter();
    solicitacoes: RequerimentoTitulacao[] = [];

    constructor(
        injector: Injector,
        private dialog: MatDialog,
        private requerimentoService: RequerimentoService,
        private requerimentoTitulacaoService: RequerimentoTitulacaoService,
        private snackBarService: SnackBarService
    ) {

    }

    ngOnInit(): void {
        this.carregarSolicitacoes();

        this.acaoUpdateEmitter.subscribe(conclusao => {
            this.carregarSolicitacoes();
        });
        
    }

    carregarSolicitacoes(): void {
        this.requerimentoService
            .getTitulacoes(this.requerimento)
            .subscribe(solicitacoes => {
                this.solicitacoes = solicitacoes;
            });
    }

    excluirTitulo(idRequerimentoTitulacao: number) {
        if (this.solicitacoes.length === 1) {
            this.snackBarService.showAlert("Não é possível realizar a exclusão. É necessário manter pelo menos uma titulação no requerimento.");
            return;
        }
        const dialog = this.dialog.open(DialogConfirmacaoComponent,
            {
                width: '500px',
                data: {
                    message: 'Depois de remover este título selecionado não será possível adicioná-lo novamente neste requerimento. Se posteriormente perceber que removeu por engano, você deverá iniciar outra vez um novo requerimento. Deseja continuar e remover este título?'
                }
            });

        dialog.afterClosed().toPromise().then(async result => {
            if (!!result) {
                this.requerimentoTitulacaoService.deleteById(idRequerimentoTitulacao).subscribe( response => {
                    this.carregarSolicitacoes();
                    this.snackBarService.showSuccess("Titulação excluida com sucesso!")
                })
            }
        })
    }

}
