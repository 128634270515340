import {BaseModel} from 'app/main/shared/models/base.model';

export class ObservacaoDistribuicao extends BaseModel {

    constructor(
        public id?: number,
        public dataHora?: Date,
        public cpfDistribuidor?: string,
        public cpfServidor?: string,
        public observacao?: string
    ) {
        super();
    }

    static fromJson(json: any): ObservacaoDistribuicao {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObservacaoDistribuicao(
            json.id,
            json.dataHora,
            json.cpfDistribuidor,
            json.cpfServidor,
            json.observacao
        );
    }

    static fromJsons(json: any): ObservacaoDistribuicao[] {
        const lista: ObservacaoDistribuicao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}
