import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {fuseAnimations} from '../../../../@fuse/animations';
import {LancamentoTaxa} from '../../shared/models/lancamento-taxa.model';
import {GuiaLancamentoTaxaDto} from '../../shared/models/guia-lancamento-taxa.dto';
import {Observable} from 'rxjs';
import {LancamentoTaxaService} from '../../shared/services/lancamento-taxa.service';


@Component({
    selector: 'app-consulta-recolhimento-taxa',
    templateUrl: './consulta-recolhimento-taxa.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})

export class ConsultaRecolhimentoTaxaComponent implements OnInit {

    lancamentosAsync: Observable<{}>;
    guiaLancamentoTaxaDto: GuiaLancamentoTaxaDto;
    statusLancamento = 'TODOS';
    statusLancamentoEnum = LancamentoTaxa.enumStatus;

    constructor(
        protected service: LancamentoTaxaService
    ) {
    }

    ngOnInit(): void {
        this.listarLancamentosPorStatus();
    }

    async listarLancamentosPorStatus() {
        const status = !this.statusLancamento || this.statusLancamento === 'TODOS' ? null : this.statusLancamento;

        await this.service.getByLacamentoTaxaStatus(status)
            .subscribe(lancamentoTaxa => {
                this.guiaLancamentoTaxaDto = lancamentoTaxa;
            });
    }
}
