import {Injectable, Injector} from '@angular/core';
import {BaseRelacionamentoService} from "./base-relacionamento.service";
import {DominiosService} from "./dominios.service";
import {ProdutoBiocombustivel} from "../models/produto-biocombustivel.model";
import {environment} from "../../../../environments/environment";


@Injectable({
    providedIn: "root"
})
export class ProdutoBiodieselService extends BaseRelacionamentoService<ProdutoBiocombustivel> {

    constructor(protected injector: Injector, protected dominiosService: DominiosService) {
        super(
            "/api/relacao-produto-biocombustivel",
            injector,
            ProdutoBiocombustivel.prototype,
            ProdutoBiocombustivel.fromJson,
            dominiosService,
            false,
            environment.URL_LIC_IND_BIOCOMBUSTIVEL
        );
    }

    protected get pathGetByEntidadePai(): string {
        return "materia-prima";
    }

    protected get pathGetDominio(): string {
        return "produto-biodiesel";
    }


}
