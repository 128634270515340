import {Injectable, Injector} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {BaseService} from 'app/main/shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Page} from 'app/main/shared/models/page.model';
import {RepresentanteLegalDocumentoCadastro} from '../models/representante-legal-documento-cadastro.model';

@Injectable({
    providedIn: 'root'
})
export class RepresentanteLegalDocumentoService extends BaseService<RepresentanteLegalDocumentoCadastro> {
    constructor(protected injector: Injector) {
        super('/api/representanteslegais',
            injector,
            RepresentanteLegalDocumentoCadastro.prototype,
            RepresentanteLegalDocumentoCadastro.fromJson
        );
    }

    create(documento: RepresentanteLegalDocumentoCadastro): Observable<RepresentanteLegalDocumentoCadastro> {
        const url = `${this.urlResource}/${documento.representanteLegal.id}/documentos`;
        return this.http.post(url, documento).pipe(
            map(response => this.jsonToResourceFn(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getDocumentos(representanteLegalId: number): Observable<Page<RepresentanteLegalDocumentoCadastro>> {
        const url = `${this.urlResource}/${representanteLegalId}/documentos`;
        const urlParams: HttpParams = new HttpParams();
        return this.http.get(url, {params: urlParams}).pipe(
            map(response => this.jsonToPage(response)),
            catchError(this.handleError.bind(this))
        );

    }

    public createMultipart(documento: RepresentanteLegalDocumentoCadastro, multipartFile: Blob): Observable<RepresentanteLegalDocumentoCadastro> {
        return this.http.post(`${this.urlResource}/${documento.representanteLegal.id}/documentos`, this.montarFormData(documento, multipartFile)).pipe(
            map(RepresentanteLegalDocumentoCadastro.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    public updateMultipart(documento: RepresentanteLegalDocumentoCadastro, multipartFile: Blob): Observable<RepresentanteLegalDocumentoCadastro> {
        const url = `${this.urlResource}/${documento.representanteLegal.id}/documentos/${documento.id}`;
        return this.http.put(url, this.montarFormData(documento, multipartFile)).pipe(
            map(() => documento),
            catchError(this.handleError.bind(this)));
    }

    private montarFormData(documento: RepresentanteLegalDocumentoCadastro, multipartFile: Blob): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const documentoParam = new Blob([JSON.stringify(documento)], {type: 'application/json'});
        formData.append('documento', documentoParam);
        return formData;
    }
}
