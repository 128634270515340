import {BaseModel} from "../base.model";
import {Feicao} from "./feicao.model";

export class RegraGeoFeicao extends BaseModel {

    public estilo?: {
        corPreenchimento: string,
        espessuraBorda: number,
        cor: string,
        tipoTracejado: number,
        icone?:string
    };

    constructor(
        public id?: number,
        public nome?: string,
        public filtro?: string,
        public excecao?: string,
        public justificativaExcecao?: string,
        public mensagemAviso?: string,
        public tipoMedida?: number,
        public tipoMedidaDescricao?: string,
        public tipoRelacionamentoTopologico?: number,
        public tipoRelacionamentoTopologicoDescricao?: string,
        public realizaAnalise?: boolean,
        public tolerancia?: number,
        public tipoRegra?: string,
        public alterarFeicao?: boolean,
        public dataInicio?: Date,
        public dataFim?: Date,
        public urlAplicacaoRegra?: string,
        public ativouRegra?: boolean,
        public urlSLD?: string,
        public resultadoMedida?: number,
        public totalFeaturesEncontradas?: number,
        public origem?: number,
        public idFeicaoEntradaComoComp?: number,
        public feicaoComparacao?: Feicao
    ) {
        super();
    }

    static fromJson(json: any): RegraGeoFeicao {
        if (json === undefined || json === null) {
            return null;
        }
        return new RegraGeoFeicao(
            json.id,
            json.nome,
            json.filtro,
            json.excecao,
            json.justificativaExcecao,
            json.mensagemAviso,
            json.tipoMedida,
            json.tipoMedidaDescricao,
            json.tipoRelacionamentoTopologico,
            json.tipoRelacionamentoTopologicoDescricao,
            json.realizaAnalise,
            json.tolerancia,
            json.tipoRegra,
            json.alterarFeicao,
            json.dataInicio,
            json.dataFim,
            json.urlAplicacaoRegra,
            json.ativouRegra,
            json.urlSLD,
            json.resultadoMedida,
            json.totalFeaturesEncontradas,
            json.origem,
            json.idFeicaoEntradaComoComp,
            json.feicaoComparacao
        );
    }
}
