import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiError } from '../main/shared/models/api-error';

function errorHandler(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
        return throwError(new ApiError('Não autorizado. A sessão expirou, faça um novo login.', [], 401));
    }

    return throwError(ApiError.fromJson(error));
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor() {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(catchError(errorHandler.bind(this)));
    }
}