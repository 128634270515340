import {Component, Inject} from '@angular/core';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Camada, GeometriaMapa} from '@sema-geo/sema-geoportal';
import {
    GeoportalBaseReferenciaService
} from "../../../../../shared/services/geoportal-base-referencia/geoportal-base-referencia.service";
import {StatusGeometria, statusGeometriaLabel} from "../../../../../shared/enums/status-geometria.enum";
import {
    AnaliseFeicaoRequerimentoLicenciamentoService
} from "../../../../../shared/services/analise-feicao-requerimento-licenciamento.service";

export interface  DialogAprovarGeometrias {
    idRequerimentoLicenciamento: number;
    camada: Camada;
    idFeicaoEntrada: number;
}

@Component({
    selector: 'app-bpm-dialog-aprovar-geometrias',
    templateUrl: './dialog-aprovar-geometrias.component.html',
    styleUrls: ['./dialog-aprovar-geometrias.component.scss'],
})
export class DialogAprovarGeometriasComponent {
    camada: Camada;
    areas: any;
    geometrias: GeometriaMapa[]
    protected readonly statusGeometriaLabel = statusGeometriaLabel;
    constructor(
        private snackBarService: SnackBarService,
        private dialogRef: MatDialogRef<DialogAprovarGeometriasComponent>,
        private readonly baseReferenciaService: GeoportalBaseReferenciaService,
        private readonly analiseFeicaoRequerimentoLicenciamentoService: AnaliseFeicaoRequerimentoLicenciamentoService,
        @Inject(MAT_DIALOG_DATA) private data: DialogAprovarGeometrias
    ) {
        this.camada = data.camada;
        this.geometrias = [];
        this.areas = {};
        if(data.camada.camadas.length > 0 ){
            this.data.camada.camadas.forEach((geometria: GeometriaMapa) => {
               if(geometria.extra.statusGeometria === StatusGeometria.EM_ANALISE){
                   this.geometrias.push(geometria);
                   this.areas[geometria.extra.id] = this.baseReferenciaService.getArea(geometria.wkt);
               }
            })
        }
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onAprovar(){
        this.analiseFeicaoRequerimentoLicenciamentoService.aprovarGeometrias(
            this.data.idRequerimentoLicenciamento,
           this.data.idFeicaoEntrada
        ).subscribe({
            next: () => {
                this.dialogRef.close({
                    success: true,
                });
            }
        })
    }
}
