export const groupBy = (items: any[], key: string) => {
    return items.reduce((result, item) => {
        (result[item[key]] = result[item[key]] || []).push(item);
        return result;
    }, {});
}

export const getValueByNestedKey = (obj, nestedKey) => {
    const keys = nestedKey.split('.');
    let value = obj;
    for (const key of keys) {
        value = value[key];
    }
    return value;
}

export const removeDuplicatesByNestedKey = (arr, nestedKey) => {
    const seen = new Set();
    return arr.filter((item) => {
        const itemValue = getValueByNestedKey(item, nestedKey);
        if (!seen.has(itemValue)) {
            seen.add(itemValue);
            return true;
        }
        return false;
    });
}
