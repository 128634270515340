import {Injectable, Injector} from '@angular/core';
import {ServicoGeral} from "../models/servico-geral.model";
import {BaseServGeral} from "./base-serv-geral.service";


@Injectable({
    providedIn: "root"
})
export class ServicoGeralService extends BaseServGeral<ServicoGeral> {

    constructor(protected injector: Injector) {
        super(
            "/api/serv-geral",
            injector,
            ServicoGeral.prototype,
            ServicoGeral.fromJson
        );
    }
}
