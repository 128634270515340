import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {
    ObrasEspeciaisEmpObrasViarias
} from "../../../../../../../../shared/models/obras-especiais-emp-obras-viarias.model";
import {InfraestruturaObrasViariasService} from "../infraestrutura-obras-viarias.service";

@Component({
    selector: 'app-obras-de-arte-especiais',
    templateUrl: './obras-de-arte-especiais.component.html',
    styleUrls: ['./obras-de-arte-especiais.component.scss']
})
export class ObrasDeArteEspeciaisComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly: boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() emitirObrasEspeciais = new EventEmitter<any[]>();
    formObrasEspeciais: FormGroup;
    obrasEspeciaisSalvas: any[] = [];
    tiposObrasEspeciais: any[] = [];
    idObraEspecial: number = 0;
    mostrarDescricao: boolean = false;
    dataSourceObraEspecial = new MatTableDataSource<any>([]);

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private dominioService: DominiosService,
                private infraObrasViariasService: InfraestruturaObrasViariasService) {
    }

    ngOnInit() {
        this.buildFormObrasEspeciais();

        //Buscando tipo pavimentacao de obras viarias
        this.dominioService.getAllTipoObrasEspeciais().subscribe(retorno => {
            this.tiposObrasEspeciais = retorno;
        });

        if (this.idForm) {
            this.infraObrasViariasService.buscarObrasEspeciaisPorId(this.idForm).subscribe(response => {
                response.forEach(obrasEspeciais => {
                    this.obrasEspeciaisSalvas.push({
                        id: obrasEspeciais.id,
                        idTemp: this.idObraEspecial,
                        tipo: obrasEspeciais.tipo,
                        quantidade: obrasEspeciais.quantidade,
                        descricao: obrasEspeciais.descricao
                    });
                    this.idObraEspecial++;
                })
                this.dataSourceObraEspecial = new MatTableDataSource(this.obrasEspeciaisSalvas);
                this.enviarObraEspecial(this.mapearArray());
            })
        }
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.formObrasEspeciais.disable();
        }
    }

    mapearArray(): any {
        if (this.obrasEspeciaisSalvas.length == 0) {
            return this.obrasEspeciaisSalvas;
        } else {
            return this.obrasEspeciaisSalvas.map(function (item) {
                return new ObrasEspeciaisEmpObrasViarias(
                    item.id,
                    null,
                    item.tipo,
                    Number(item.quantidade),
                    item.descricao
                );
            });
        }
    }

    private buildFormObrasEspeciais(): void {
        this.formObrasEspeciais = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            tipo: ["", [Validators.required]],
            quantidade: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
            descricao: ["", [Validators.required]]
        })

        this.formObrasEspeciais.controls.descricao.disable();
    }

    changeTipoObrasViarias() {
        if (this.formObrasEspeciais.controls.tipo.value == 'Outras') {
            this.mostrarDescricao = true;
            this.formObrasEspeciais.controls.descricao.enable();
        } else {
            this.mostrarDescricao = false;
            this.formObrasEspeciais.controls.descricao.disable();
        }
    }

    adicionarObraEspecial() {
        if (this.formObrasEspeciais.valid) {
            this.obrasEspeciaisSalvas.push({
                id: null,
                idTemp: this.idObraEspecial,
                tipo: this.formObrasEspeciais.controls.tipo.value,
                quantidade: this.formObrasEspeciais.controls.quantidade.value,
                descricao: this.formObrasEspeciais.controls.descricao.value
            })
            this.idObraEspecial++;
            this.dataSourceObraEspecial = new MatTableDataSource(this.obrasEspeciaisSalvas);
            this.enviarObraEspecial(this.mapearArray());
            this.mostrarDescricao = false;
            this.formObrasEspeciais.controls.descricao.disable();
            this.formObrasEspeciais.reset();
        } else {
            this.snackBarService.showAlert("O formulário Obras de artes especiais: possui campo(s) obrigatório(s) não preenchido(s)." +
                " Favor realize o preenchimento do(s) mesmo(s).")
            this.formObrasEspeciais.markAllAsTouched();
        }
    }

    deletarObraEspecial(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.infraObrasViariasService.deletarObraEspecialPorId(itemId).subscribe(response => {
                this.snackBarService.showSuccess("Barragem excluido com sucesso.")
            })
        }
        let data;
        data = this.obrasEspeciaisSalvas.filter((value, key) => {
            return value.idTemp != item;
        });
        this.obrasEspeciaisSalvas = data;
        this.dataSourceObraEspecial = new MatTableDataSource(data);
        this.enviarObraEspecial(this.mapearArray())
    }

    enviarObraEspecial(obrasEspeciais: any[]) {
        this.emitirObrasEspeciais.emit(obrasEspeciais);
    }
}