import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class AudioPlayer {
    constructor() {

    }

    play(audioFileName: string): void {
        const audio = new Audio();

        audio.src = `../../../../assets/audio/${audioFileName}.wav`;

        audio.load();

        audio.play();
    }
}
