import {HttpParams} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material';
import {Page} from 'app/main/shared/models/page.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {
    ConsultaRequerimentoProcessoDTO
} from "./consulta-requerimentos-processos/model/consulta-requerimento-processo.dto";

@Injectable({
    providedIn: 'root',
})
export class ConsultaRequerimentoProcessoService extends BaseService<ConsultaRequerimentoProcessoDTO> {

    constructor(protected injector: Injector) {
        super(
            '/api/requerimentos',
            injector, ConsultaRequerimentoProcessoDTO.prototype,
            ConsultaRequerimentoProcessoDTO.fromJson
        );
    }

    public consultaRequerimentosProcessos(
        form: FormGroup,
        paginator: MatPaginator,
        isPage = true
    ): Observable<Page<ConsultaRequerimentoProcessoDTO>> {
        const url = `${this.urlResource}/consulta-requerimentos-processos`;

        const params: any = {
            page: String(paginator.pageIndex),
            size: String(paginator.pageSize),
            numeroRequerimento: form.get('numeroRequerimento').value,
            numeroProcesso: form.get('numeroProcesso').value,
            numeroProtocolo: form.get('numeroProtocolo').value,
            nomeInteressado: form.get('nomeInteressado').value,
            cpfCnpjInteressado: form.get('cpfCnpjInteressado').value,
            conferenteResponsavel: form.get('conferenteResponsavel').value,
            analistaResponsavel: form.get('analistaResponsavel').value,
            dataInicio: new Date(form.get('dataInicio').value).getTime(),
            dataFim: new Date(form.get('dataFim').value).getTime(),
            situacaoRequerimentoProcesso: form.get('situacaoRequerimentoProcesso').value,
            municipioEmpreendimento: form.get('municipioEmpreendimento').value,
            tiposOutorga: form.get('tiposOutorga').value && form.get('tiposOutorga').value.length > 0 ? form.get('tiposOutorga').value : null,
            titulacaoId: form.get('titulacaoId').value,
            finalidadeIds: form.get('finalidadeIds').value,
            tipoRequerimentoIds: form.get('tipoRequerimentoIds').value,
            nomeTarefa: form.get('nomeTarefa').value,
            processoTarefa: form.get('processoTarefa').value,
            responsavelTarefa: form.get('responsavelTarefa').value,
            prioridade: form.get('prioridade').value,
            tipoProcesso: form.get('tipoProcesso').value && form.get('tipoProcesso').value.length > 0 ? form.get('tipoProcesso').value.map(x => x.id) : null,
            isPage: isPage
        };

        const httpParams = Object.getOwnPropertyNames(params).filter(item => params[item]).reduce(
            (p, key) => p.set(key, params[key]), new HttpParams()
        );

        return this.http.get(url, {params: httpParams}).pipe(
            map((response) => this.jsonToPage(response)),
            catchError(this.handleError.bind(this))
        );
    }


    /**
     * Consulta o tipo do perfil do servidor logado
     */
    getIsPerfilCoordenador(): Observable<any> {
        const url = `${this.urlResource}/is-perfil-coordenador`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Lista servidores com perfil de coordenadores
     */
    getServidoresCoordenadores(): Observable<any> {
        const url = `${this.urlResource}/servidores-perfil-coordenador`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Exporta lista de requerimentos para xlsx.
     */
    exportToExcel(form: FormGroup): Observable<any> {
        const url = `${this.urlResource}/consulta-requerimentos-export-xlsx`;

        const params: any = {
            numeroRequerimento: form.get('numeroRequerimento').value,
            numeroProcesso: form.get('numeroProcesso').value,
            numeroProtocolo: form.get('numeroProtocolo').value,
            nomeInteressado: form.get('nomeInteressado').value,
            cpfCnpjInteressado: form.get('cpfCnpjInteressado').value,
            conferenteResponsavel: form.get('conferenteResponsavel').value,
            analistaResponsavel: form.get('analistaResponsavel').value,
            dataInicio: new Date(form.get('dataInicio').value).getTime(),
            dataFim: new Date(form.get('dataFim').value).getTime(),
            situacaoRequerimentoProcesso: form.get('situacaoRequerimentoProcesso').value,
            municipioEmpreendimento: form.get('municipioEmpreendimento').value,
            tiposOutorga: form.get('tiposOutorga').value && form.get('tiposOutorga').value.length > 0 ? form.get('tiposOutorga').value : null,
            titulacaoId: form.get('titulacaoId').value,
            finalidadeIds: form.get('finalidadeIds').value,
            tipoRequerimentoIds: form.get('tipoRequerimentoIds').value,
            nomeTarefa: form.get('nomeTarefa').value,
            processoTarefa: form.get('processoTarefa').value,
            responsavelTarefa: form.get('responsavelTarefa').value,
            prioridade: form.get('prioridade').value,
            tipoProcesso: form.get('tipoProcesso').value && form.get('tipoProcesso').value.length > 0 ? form.get('tipoProcesso').value.map(x => x.id) : null,
        };

        const httpParams = Object.getOwnPropertyNames(params).filter(item => params[item]).reduce(
            (p, key) => p.set(key, params[key]), new HttpParams()
        );

        return this.http.get(url, {
            params: httpParams,
            responseType: 'blob'
        }).pipe(
            map(res => res),
            catchError(this.handleError.bind(this))
        );
    }

    //Verifica se o servidor logado é um gestor: superintendente/coordenador/gerente e seus substitutos
    getIsPerfilGestor(): Observable<any> {
        const url = `${this.urlResource}/is-perfil-gestor`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}