import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {BaseModel} from 'app/main/shared/models/base.model';
import {OutraFinalidade} from 'app/main/shared/models/outra-finalidade.model';
import {AtividadeServicoService} from 'app/main/shared/services/atividade-servico.service';
import {RequerimentoOutorgaFinalidadeService} from '../../../../../shared/services/requerimento-outorga-finalidade.service';
import {BaseFinalidadeComponent} from '../base-finalidade.component';
import {AtividadeServico} from './atividade-servico/models/atividade-servico.model';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-finalidades-outras',
    templateUrl: './finalidades-outras.component.html'
})
export class FinalidadesOutrasComponent extends BaseFinalidadeComponent implements OnInit {
    form: FormGroup;
    formAtividadeServico: FormGroup;
    atividadesServicos: AtividadeServico[] = [];
    outraFinalidade: OutraFinalidade = new OutraFinalidade();

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    constructor(
        private formBuilder: FormBuilder,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private atividadeServicoSevice: AtividadeServicoService,
        protected injector: Injector,
        private snackBarService: SnackBarService,
        public dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildResourceForm();
        this.patchForm();
        this.listarAtividadesOuServicos();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    private patchForm(): void {
        this.form.patchValue({
            id: this.requerimentoOutorgaFinalidade.id,
            finalidade: this.requerimentoOutorgaFinalidade.finalidade,
            requerimentoOutorga: this.requerimentoOutorgaFinalidade.requerimentoOutorga
        });

        if (this.requerimentoOutorgaFinalidade.outraFinalidade) {
            this.form.patchValue({
                outraFinalidade: this.requerimentoOutorgaFinalidade.outraFinalidade,
                observacao: this.requerimentoOutorgaFinalidade.observacao
            });
        }
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            finalidade: [null, [Validators.required]],
            requerimentoOutorga: [null, [Validators.required]],
            outraFinalidade: this.formBuilder.group({
                id: [null]
            }),
            observacao: [null],
            cadastroConcluido: [null]
        });

        this.formAtividadeServico = new FormGroup({
            id: new FormControl(),
            descricao: new FormControl(),
            vazaoConsumo: new FormControl()
        });
    }

    private listarAtividadesOuServicos(): void {
        if (this.requerimentoOutorgaFinalidade.outraFinalidade && this.requerimentoOutorgaFinalidade.outraFinalidade.id) {
            this.atividadeServicoSevice.getAllByOutraFinalidade(this.requerimentoOutorgaFinalidade.outraFinalidade)
                .subscribe(atividadesServicos => {
                    this.atividadesServicos = atividadesServicos;
                    if (this.requerimentoOutorgaFinalidade.cadastroConcluido != this.atividadesServicos.length > 0) {
                        this.submit(false);
                    }
                });
        }
    }

    submit(showMessages = true): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.form.patchValue({cadastroConcluido: this.atividadesServicos.length > 0});
            this.requerimentoOutorgaFinalidadeService.update(this.form.value)
                .subscribe(
                    (requerimentoOutorgaFinalidade) => {
                        Object.assign(this.requerimentoOutorgaFinalidade, requerimentoOutorgaFinalidade);
                        this.form.patchValue(requerimentoOutorgaFinalidade);
                        this.form.markAsPristine();

                        if (this.formAtividadeServico.valid && this.formAtividadeServico.touched) {
                            this.adicionarAtividadeOuServico();
                        }

                        if (showMessages)
                            this.snackBarService.showSuccess('Registro salvo com sucesso!');

                    },
                    (e) => {
                        if (showMessages)
                            this.snackBarService.showError('Erro ao salvar o registro.', e);

                    }
                );
        } else {
            if (showMessages)
                this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');

        }
    }

    adicionarAtividadeOuServico(): void {
        this.formAtividadeServico.markAllAsTouched();
        if (!this.requerimentoOutorgaFinalidade.outraFinalidade || !this.requerimentoOutorgaFinalidade.outraFinalidade.id) {
            this.submit();
            return;
        }
        if (this.formAtividadeServico.invalid) {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            return;
        }
        if (this.requerimentoOutorgaFinalidade.outraFinalidade.id) {
            const atividadeServico = AtividadeServico.fromJson(this.formAtividadeServico.value);
            atividadeServico.outraFinalidade = {id: this.requerimentoOutorgaFinalidade.outraFinalidade.id};
            this.atividadeServicoSevice.create(atividadeServico)
                .subscribe(() => {
                    this.snackBarService.showSuccess('Atividade/Serviço adicionando com sucesso!');
                    this.formAtividadeServico.reset();
                    this.listarAtividadesOuServicos();
                });
        }
    }

    excluirAtividadeServico(atividadeServico: AtividadeServico): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: atividadeServico.id}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.atividadeServicoSevice.delete(atividadeServico).subscribe(
                    () => {
                        this.snackBarService.showSuccess('Atividade/serviço excluído com sucesso!');
                        this.listarAtividadesOuServicos();
                    },
                    (e) => this.snackBarService.showError('Erro ao excluir a atividade/serviço.', e)
                );
            }
        });
    }

    getVazaoConsumoTotal(): number {
        if (this.atividadesServicos) {
            return this.atividadesServicos.reduce(
                (total, current) => total + current.vazaoConsumo,
                0
            );
        }
        return 0;
    }
}
