import {BaseModel} from 'app/main/shared/models/base.model';
import {ParecerTecnico} from '../parecer-tecnico.model';
import {TipoCondicionante} from '../../tipos-condicionante/tipo-condicionante.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';

export class ParecerTecnicoCondicionante extends BaseModel {
    constructor(
        public id?: number,
        public dataCriacao?: Date,
        public dataExclusao?: Date,
        public dataVencimento?: Date,
        public descricao?: string,
        public numero?: number,
        public periodo?: Date,
        public situacao?: number,
        public tipoArquivo?: BaseEnum,
        public tipoFormulario?: string,
        public tipoPeriodo?: BaseEnum,
        public tipoPrazo?: BaseEnum,
        public tipoResposta?: BaseEnum,
        public parecerTecnico?: ParecerTecnico,
        public tipoCondicionante?: TipoCondicionante
    ) {
        super();
    }

    static situacaoParecerTecnicoCondicionante: BaseEnum[] = [
        {valor: '0', descricao: 'Criado'},
        {valor: '1', descricao: 'Desabilitado'}
    ];

    static prazoEntregaParecerTecnicoCondicionante: BaseEnum[] = [
        {valor: '1', descricao: 'Periodicidade'},
        {valor: '2', descricao: 'Vencimento'},
        {valor: '0', descricao: 'Não se aplica'}
    ];

    static periodoParecerTecnicoCondicionante: BaseEnum[] = [
        {valor: '0', descricao: 'Dias'},
        {valor: '1', descricao: 'Semanas'},
        {valor: '2', descricao: 'Meses'},
        {valor: '3', descricao: 'Anos'}
    ];

    static respostaParecerTecnicoCondicionante: BaseEnum[] = [
        {valor: '0', descricao: 'Protocolo'},
        {valor: '1', descricao: 'Arquivo eletrônico'},
        {valor: '2', descricao: 'Formulário eletrônico'},
        {valor: '3', descricao: 'Dado geoespacial'},
        {valor: '4', descricao: 'Não se aplica'}
    ];

    static arquivoParecerTecnicoCondicionante: BaseEnum[] = [
        {valor: '0', descricao: 'Sem modelo'},
        {valor: '1', descricao: 'Outros'},
        {valor: '2', descricao: 'PRAD – Plano de recuperação de área degradada'},
        {valor: '3', descricao: 'PBA – Plano básico ambiental'},
        {valor: '4', descricao: 'PEF - Plano de exploração florestal'}
    ];

    static fromJson(json: any): ParecerTecnicoCondicionante {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParecerTecnicoCondicionante(
            json.id,
            json.dataCriacao,
            json.dataExclusao,
            json.dataVencimento,
            json.descricao,
            json.numero,
            json.periodo,
            json.situacao,
            json.tipoArquivo,
            json.tipoFormulario,
            json.tipoPeriodo,
            json.tipoPrazo,
            json.tipoResposta,
            ParecerTecnico.fromJson(json.parecerTecnico),
            TipoCondicionante.fromJson(json.tipoCondicionante)
        );
    }
}
