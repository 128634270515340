import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {Validators} from '@angular/forms';
import {FeicaoTemplate} from '../feicao-template.model';
import {fuseAnimations} from '@fuse/animations';
import {FeicaoTemplateService} from '../feicao-template.service';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';

@Component({
    selector: 'app-feicao-template-detail',
    templateUrl: './feicao-template-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class FeicaoTemplateDetailComponent extends BaseDetailComponent<FeicaoTemplate> {

    constructor(
        protected feicaoTemplateService: FeicaoTemplateService,
        protected injector: Injector
    ) {
        super(injector, new FeicaoTemplate(), feicaoTemplateService, FeicaoTemplate.fromJson);
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            descricao: [null, [Validators.required, Validators.maxLength(255)]],
            tipoGeometria: [null, [Validators.required]]
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova feição template';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando a feição template: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando a feição template: ' + resourceName;
    }

    get tiposGeometria() {
        return FeicaoTemplate.tipos;
    }

}

