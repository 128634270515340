import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FeicaoRequerimentoLicenciamentoDto} from "../../models/feicao-requerimento-licenciamento-dto.model";

export class Dependencia extends FeicaoRequerimentoLicenciamentoDto {
    nomeFeicaoEntrada: string
}
export interface DialogConfirmaExclusaoFeicaoComponentData {
    feicao: FeicaoRequerimentoLicenciamentoDto;
    dependencias: Dependencia[];
}

@Component({
    templateUrl: './dialog-confirma-exclusao-feicao.component.html',
    styleUrls: ['./dialog-confirma-exclusao-feicao.component.scss']
})
export class DialogConfirmaExclusaoFeicaoComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmaExclusaoFeicaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogConfirmaExclusaoFeicaoComponentData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
