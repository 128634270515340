import { BaseModel } from "./base.model";

export class CaracteristicaEletricaSistema extends BaseModel {
    constructor(
        public id: number,
        public idDominio: number,
        public idEntidade: number,
        public extensaoFaixaServidao: number,
        public tipoTensaoNominalSistema: number,
        public tipoPotenciaCurToCircuitoTipico: number,
        public tipoTensaoMaximaOperacao: number,
        public tipoTensaoSuportavelImpulsoAtmosferico: number,
        public tipoTensaoSuportavelFrequenciaIndustrial: number
    ) {
        super();
    }

    static fromJson(json: any): CaracteristicaEletricaSistema {
        if (json === undefined || json === null) {
            return null;
        }
        return new CaracteristicaEletricaSistema(
            json.id,
            json.idDominio,
            json.idEntidade,
            json.extensaoFaixaServidao,
            json.tipoTensaoNominalSistema,
            json.tipoPotenciaCurToCircuitoTipico,
            json.tipoTensaoMaximaOperacao,
            json.tipoTensaoSuportavelImpulsoAtmosferico,
            json.tipoTensaoSuportavelFrequenciaIndustrial
        );
    }

}
