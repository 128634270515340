export class ServidorLicenciamento {
    constructor(
        public id: number = null,
        public dataAtualizacao: Date = null,
        public cpf: string = null,
        public nome: string = null,
        public profissoes: string = null,
        public matricula: string = null,
        public setores: string = null,
        public cargo: string = null,
    ) {
    }

    static fromJson(json: any): ServidorLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new ServidorLicenciamento(
            json.id,
            json.dataAtualizacao,
            json.cpf,
            json.nome,
            json.profissoes,
            json.matricula,
            json.setores,
            json.cargo
        );
    }

    static fromJsons(json: any): ServidorLicenciamento[] {
        const resources: ServidorLicenciamento[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: ServidorLicenciamento) => resources.push(ServidorLicenciamento.fromJson(e)));
        }
        return resources;
    }
}