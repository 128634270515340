import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {AnaliseRequerimentoAnalista} from "../../../../../tarefas/shared/model/analise-requerimento-analista.model";

@Component({
  selector: 'app-comentario-recusa-dialog',
  templateUrl: './comentario-recusa-dialog.component.html',
  styleUrls: ['./comentario-recusa-dialog.component.scss']
})
export class ComentarioRecusaDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public comentario: string) { }

  ngOnInit() {
  }

}
