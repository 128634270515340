import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {checkboxBooleanRequiredDirective} from "../../../../../../../shared/directives/checkbox-boolean-required.directive";
import {InfraObraFluvSaneam} from "../../../../../../../shared/models/infra-obra-fluv-saneam.model";
import {InfraObraFluvSaneamService} from "../../../../../../../shared/services/infra-obra-fluv-saneam.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-aba-atividade-infra-obra-fluv-saneam',
    templateUrl: './infra-obra-fluviais-saneamento.component.html',
    styleUrls: ['./infra-obra-fluviais-saneamento.component.scss']
})
export class InfraObraFluviaisSaneamentoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    hasObraPluvialFluvial: boolean = false;
    hasObraSaneamento: boolean = false;
    hasObraDrenagem: boolean = false;

    form: FormGroup;
    private empreendimento: InfraObraFluvSaneam;

    isNaoPodeEditar: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private infraObraFluvSaneamService: InfraObraFluvSaneamService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
        this.form = this.formBuilder.group({});
    }

    async ngOnInit() {

        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarInfraObraFluviaisSaneamento(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarInfraObraFluviaisSaneamento(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.form.disable();
        }
    }

    async carregarInfraObraFluviaisSaneamento(idRequerimento){
        let res = await this.infraObraFluvSaneamService.getByRequerimento(idRequerimento).toPromise();
        this.empreendimento = res ? res : {} as any;

        if (res) {
            if (res.obraSaneamento && res.obraSaneamento.id) {
                this.hasObraSaneamento = true;
            }
            if (res.obraPluvialFluvial && res.obraPluvialFluvial.id) {
                this.hasObraPluvialFluvial = true;
            }
            if (res.obraDrenagem && res.obraDrenagem.id) {
                this.hasObraDrenagem = true;
            }
            this.atualizaObraPluvialFluvial();
            this.atualizaObraSaneamento();
            this.atualizaObraDrenagem();
            this.form.patchValue(this.empreendimento);
        }
    }

    async salva() {
        this.form.markAllAsTouched();

        if (this.form.valid) {
            let res = InfraObraFluvSaneam.fromJson(this.form.value);
            res.requerimento = this.requerimento.id;
            res.id = this.empreendimento.id;
            if (this.hasObraPluvialFluvial) {
                res.obraPluvialFluvial.id = this.empreendimento.obraPluvialFluvial ? this.empreendimento.obraPluvialFluvial.id : null;
            }
            if (this.hasObraSaneamento) {
                res.obraSaneamento.id = this.empreendimento.obraSaneamento ? this.empreendimento.obraSaneamento.id : null;
            }
            if (this.hasObraDrenagem) {
                res.obraDrenagem.id = this.empreendimento.obraDrenagem ? this.empreendimento.obraDrenagem.id : null;
            }

            if (this.empreendimento.id === undefined) {
                this.infraObraFluvSaneamService.create(res).subscribe( response => {
                    this.enviarFormConcluido.emit();
                });
            } else {
                this.infraObraFluvSaneamService.update(res).subscribe(response => {
                    this.enviarFormConcluido.emit();
                });
            }
        }

    }

    atualizaObraPluvialFluvial() {
        if (this.hasObraPluvialFluvial) {
            this.form.addControl('obraPluvialFluvial', this.formBuilder.group({
                rampa: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'obraPluvialFluvial', 'pier', 'marina', 'porto', 'tablado', 'outro')]],
                pier: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'obraPluvialFluvial', 'rampa', 'marina', 'porto', 'tablado', 'outro')]],
                marina: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'obraPluvialFluvial', 'rampa', 'pier', 'porto', 'tablado', 'outro')]],
                porto: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'obraPluvialFluvial', 'rampa', 'pier', 'marina', 'tablado', 'outro')]],
                tablado: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'obraPluvialFluvial', 'rampa', 'pier', 'marina', 'porto', 'outro')]],
                outro: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'obraPluvialFluvial', 'rampa', 'pier', 'marina', 'porto', 'tablado')]],
                descricaoTipoObra: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                zona: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                anuenciaMarinha: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                desapropriacao: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                areaBensTombadosAcautelados: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]]
            }));
        } else {
            this.form.removeControl('obraPluvialFluvial');
        }
    }

    atualizaObraSaneamento() {
        if (this.hasObraSaneamento) {
            this.form.addControl('obraSaneamento', this.formBuilder.group({
                agua: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'obraSaneamento', 'esgoto')]],
                esgoto: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'obraSaneamento', 'agua')]],
                zona: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                desapropriacao: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                corredorFauna: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                equipamentoAPP: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                distanciaTratamentoResidenciais: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]]
            }));
        } else {
            this.form.removeControl('obraSaneamento');
        }
    }

    atualizaObraDrenagem() {
        if (this.hasObraDrenagem) {
            this.form.addControl('obraDrenagem', this.formBuilder.group({
                zona: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                desapropriacao: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                corredorFauna: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                equipamentoAPP: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                extensao: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]]
            }));
        } else {
            this.form.removeControl('obraDrenagem');
        }
    }

}
