import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequerimentoLicenciamentoUpaService} from './requerimento-licenciamento-upa.service';
import {RequerimentoLicenciamentoUpa} from './requerimento-licenciamento-upa.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {DominiosService} from 'app/main/shared/services/dominios.service';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {RequerimentoLicenciamento} from "../../../requerimento-licenciamento/requerimento-licenciamento.model";
import {RequerimentoLicenciamentoService} from "../../../requerimento-licenciamento/requerimento-licenciamento.service";
import {UtilService} from "../../../../../core/services/util.service";

@Component({
    selector: 'app-form-upa',
    templateUrl: './form-upa.component.html',
    styleUrls: ['./form-upa.component.scss']
})
export class FormUpaComponent implements OnInit {

    origensUpa: any;
    cicloDeManejo: any;
    formUpa: FormGroup;
    anos: any;
    cpfCnpjDetentor: string = '';
    @Input() requerimentoLicenciamento: RequerimentoLicenciamento;
    @Input() idFeicao: number;
    requerimentoLicenciamentoUPA: RequerimentoLicenciamentoUpa;
    @Output() public formularios: EventEmitter<any> = new EventEmitter();

    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b

    constructor(
        private requerimentoLicenciamentoUpaService: RequerimentoLicenciamentoUpaService,
        private dominioService: DominiosService,
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        protected requerimentoLicenciamentoService: RequerimentoLicenciamentoService
    ) {
    }

    async ngOnInit() {
        this.requerimentoLicenciamentoUPA = await this.requerimentoLicenciamentoUpaService
        .getByRequerimentoLicenciamentoIdAndIdFeicao(this.requerimentoLicenciamento.id, this.idFeicao).toPromise()
        if (this.requerimentoLicenciamentoUPA) {
            this.cpfCnpjDetentor = this.requerimentoLicenciamentoUPA.cpfCnpjDetentor;
        }
        this.createForm();
        this.anos = UtilService.arrayDeAnos();
        this.origensUpa = await this.dominioService.getDominio('origem_upa').toPromise();
        await this.preencherForm();
        this.validaCiclosDeCorteManejo();
    }

    async preencherForm() {
        if (this.requerimentoLicenciamentoUPA) {
            this.formularios.emit({idFeicao: this.idFeicao, formulario: 'UPA', tipo: 'SAVE'});
            this.formUpa.patchValue({id: this.requerimentoLicenciamentoUPA.id})
            this.formUpa.patchValue({requerimentoLicenciamento: this.requerimentoLicenciamentoUPA.requerimentoLicenciamento})
            this.formUpa.patchValue({anoExploracao: this.requerimentoLicenciamentoUPA.anoExploracao})
            this.formUpa.patchValue({idOrigemUpa: this.requerimentoLicenciamentoUPA.idOrigemUpa})
            this.formUpa.patchValue({cicloCorteManejo: this.requerimentoLicenciamentoUPA.cicloCorteManejo})
            this.formUpa.patchValue({idFeicao: this.requerimentoLicenciamentoUPA.idFeicao})
            this.formUpa.patchValue({cpfCnpjDetentor: this.requerimentoLicenciamentoUPA.cpfCnpjDetentor})
        } else {
            this.formularios.emit({idFeicao: this.idFeicao, formulario: 'UPA', tipo: 'DELETE'});
        }
    }

    salvar() {
        if (this.formUpa.valid) {
            this.requerimentoLicenciamentoUpaService.create(this.formUpa.value).subscribe(retorno => {
                this.formUpa.controls['cpfCnpjDetentor'].disable();
                this.formUpa.patchValue({id: retorno.id})
                this.snackBarService.showSuccess('Salvo com Sucesso')
                this.formularios.emit({idFeicao: this.idFeicao, formulario: 'UPA', tipo: 'SAVE'});
            }, () => {
                this.snackBarService.showError('Ocorreu um erro ao tentar salvar')
                this.formUpa.controls['cpfCnpjDetentor'].disable();
            })
        } else {
            this.formUpa.markAllAsTouched();
            this.snackBarService.showError('Preencher os campos corretamente')
        }

    }

    createForm() {
        this.formUpa = this.formBuilder.group({
            id: [null],
            requerimentoLicenciamento: [this.requerimentoLicenciamento],
            anoExploracao: [null, [Validators.required]],
            idOrigemUpa: [null, [Validators.required]],
            cicloCorteManejo: [null, [Validators.required]],
            cpfCnpjDetentor: [this.cpfCnpjDetentor, [Validators.required]],
            idFeicao: [this.idFeicao]
        });
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.formUpa.get('cpfCnpjDetentor').value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    validaCiclosDeCorteManejo(): void {
        this.requerimentoLicenciamentoUpaService.getModulosFiscais(this.requerimentoLicenciamento.requerimento.id).subscribe(modulos => {
            if (modulos > 4) {
                this.cicloDeManejo = [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35];
            } else {
                this.cicloDeManejo = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
            }
        });
    }

}
