import {CommonsUtil} from './../../../../../../../../shared/util/commons-util';
import {MatTableDataSource} from '@angular/material';
import {GeradorEletricoUsinaTerm} from './../../../../../../../../shared/models/energia-termica-gerador-eletrico.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmpreendimentoEnergeticoTermicaService} from '../empreendimento-energetico-termica.service';

@Component({
    selector: 'app-gerador-eletrico',
    templateUrl: './gerador-eletrico.component.html',
    styleUrls: ['./gerador-eletrico.component.scss']
})
export class GeradorEletricoComponent implements OnInit {

    @Input() idEEtermica: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;

    modoEdicao = false;

    @Output() enviarGeradorEletrico = new EventEmitter<GeradorEletricoUsinaTerm[]>();
    formGeradorEletrico: FormGroup;

    geradoresEletrico: any[] = [];
    dataSourceGeradoresEletricos = new MatTableDataSource<any>([]);

    @Output() save: EventEmitter<any> = new EventEmitter<any>();

    displayedColumns: string[] = [
        'identificacao',
        'valorPotenciaAparente',
        'valorFatorPotencia',
        'valorPotenciaAtiva',
        'valorTensao',
        'valorFrequencia',
        'classeIsolamento',
        'valorRotacao',
        'dataPrevista',
        'nomeFabricante',
        'acao'
    ];

    idTabela = 0;

    constructor(
        private fb: FormBuilder,
        private snackBarService: SnackBarService,
        private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService
    ) {
    }

    ngOnInit() {
        this.formGeradorEletrico = this.fb.group({
            id: [''],
            idTemp: [null],
            empreendimentoEnergTermica: [''],
            identificacao: ['', [Validators.required]],
            valorPotenciaAparente: ['', [Validators.required]],
            valorFatorPotencia: ['', [Validators.required]],
            valorPotenciaAtiva: ['', [Validators.required]],
            valorTensao: ['', [Validators.required]],
            valorFrequencia: ['', [Validators.required]],
            classeIsolamento: ['', [Validators.required]],
            valorRotacao: ['', [Validators.required]],
            nomeFabricante: ['', [Validators.required]],
            dataPrevista: ['']
        });

        if (this.idEEtermica) {
            this.carregaGeradorEletricoCasoExistaIdEEtermica(this.idEEtermica);
        }

        if (this.isNaoPodeEditar) {
            this.formGeradorEletrico.disable();
        }

    }

    carregaGeradorEletricoCasoExistaIdEEtermica(idEEtermica: number): void{
        this.empreendimentoEnergService.buscaListGeradorEletrico(idEEtermica).subscribe(async result => {
            for (const geradorEletrico of result) {
                this.geradoresEletrico.push({
                    id: geradorEletrico.id,
                    idTemp: this.retornarIdTabela(),
                    identificacao: geradorEletrico.identificacao,
                    valorPotenciaAparente: geradorEletrico.valorPotenciaAparente,
                    valorFatorPotencia: geradorEletrico.valorFatorPotencia,
                    valorPotenciaAtiva: geradorEletrico.valorPotenciaAtiva,
                    valorTensao: geradorEletrico.valorTensao,
                    valorFrequencia: geradorEletrico.valorFrequencia,
                    classeIsolamento: geradorEletrico.classeIsolamento,
                    valorRotacao: geradorEletrico.valorRotacao,
                    nomeFabricante: geradorEletrico.nomeFabricante,
                    dataPrevista: geradorEletrico.dataPrevista ? geradorEletrico.dataPrevista : ''
                });
                this.dataSourceGeradoresEletricos = new MatTableDataSource(this.geradoresEletrico);
                this.idTabela++;
            }
        });
    }

    adicionaGeradorEletrico(): void {

        const validacao = this.validarFormulario();
        if (validacao) {

            this.geradoresEletrico.push({
                id: null,
                idTemp: this.retornarIdTabela(),
                identificacao: this.formGeradorEletrico.controls.identificacao.value,
                valorPotenciaAparente: Number(this.formGeradorEletrico.controls.valorPotenciaAparente.value),
                valorFatorPotencia: Number(this.formGeradorEletrico.controls.valorFatorPotencia.value),
                valorPotenciaAtiva: Number(this.formGeradorEletrico.controls.valorPotenciaAtiva.value),
                valorTensao: Number(this.formGeradorEletrico.controls.valorTensao.value),
                valorFrequencia: Number(this.formGeradorEletrico.controls.valorFrequencia.value),
                classeIsolamento: this.formGeradorEletrico.controls.classeIsolamento.value,
                valorRotacao: Number(this.formGeradorEletrico.controls.valorRotacao.value),
                nomeFabricante: this.formGeradorEletrico.controls.nomeFabricante.value,
                dataPrevista: this.formGeradorEletrico.controls.dataPrevista.value
            });

            this.dataSourceGeradoresEletricos = new MatTableDataSource(this.geradoresEletrico);
            this.enviarGeradoresEletrico(this.mapearArray());
            this.formGeradorEletrico.reset();
        }

    }

    retornarIdTabela(): number {
        this.idTabela++;
        return this.idTabela;
    }

    enviarGeradoresEletrico(geradores: GeradorEletricoUsinaTerm[]): void {
        this.enviarGeradorEletrico.emit(geradores);
    }

    deletarItem(item, acao?: string, itemId?: number): void {
        if (acao === 'DELETAR' && itemId != null) {
            this.empreendimentoEnergService.deletarGeradorEletricoPorId(itemId).subscribe(() => {
                this.snackBarService.showSuccess('Gerador Eletrico excluido com sucesso.');
            });
        }
        let data;
        data = this.geradoresEletrico.filter((value, key) => {
            return value.idTemp !== item;
        });
        this.geradoresEletrico = data;
        this.dataSourceGeradoresEletricos = data;
        this.enviarGeradoresEletrico(this.mapearArray());
    }


    mapearArray(): any {
        if (this.geradoresEletrico.length == 0) {
            return this.geradoresEletrico;
        } else {
            return this.geradoresEletrico.map(function (item) {
                return new GeradorEletricoUsinaTerm(
                    null,
                    null,
                    item.identificacao,
                    item.valorPotenciaAparente,
                    item.valorFatorPotencia,
                    item.valorPotenciaAtiva,
                    item.valorTensao,
                    item.valorFrequencia,
                    item.classeIsolamento,
                    item.valorRotacao,
                    item.nomeFabricante,
                    item.dataPrevista,
                );
            });
        }
    }

    ativarModoEdicaoUsina(geradores): void {
        this.formGeradorEletrico.controls.id.patchValue(geradores.id);
        this.formGeradorEletrico.controls.idTemp.patchValue(geradores.idTemp);
        this.formGeradorEletrico.controls.identificacao.patchValue(geradores.identificacao);
        this.formGeradorEletrico.controls.valorPotenciaAparente.patchValue(geradores.valorPotenciaAparente);
        this.formGeradorEletrico.controls.valorFatorPotencia.patchValue(geradores.valorFatorPotencia);
        this.formGeradorEletrico.controls.valorPotenciaAtiva.patchValue(geradores.valorPotenciaAtiva);
        this.formGeradorEletrico.controls.valorTensao.patchValue(geradores.valorTensao);
        this.formGeradorEletrico.controls.valorFrequencia.patchValue(geradores.valorFrequencia);
        this.formGeradorEletrico.controls.classeIsolamento.patchValue(geradores.classeIsolamento);
        this.formGeradorEletrico.controls.valorRotacao.patchValue(geradores.valorRotacao);
        this.formGeradorEletrico.controls.nomeFabricante.patchValue(geradores.nomeFabricante);
        this.formGeradorEletrico.controls.dataPrevista.patchValue(geradores.dataPrevista);
        this.modoEdicao = true;

    }

    editarGerador(): void {
        const validacao = this.validarFormulario();
        if (validacao) {
            this.deletarItem(this.formGeradorEletrico.controls.idTemp.value);
            this.adicionaGeradorEletrico();
            this.modoEdicao = false;
        }
    }

    fecharEdicao(): void {
        this.modoEdicao = false;
        this.formGeradorEletrico.reset();
        this.formGeradorEletrico.markAsUntouched();
        this.formGeradorEletrico.updateValueAndValidity()
    }


    validarFormulario(): boolean {
        if (this.formGeradorEletrico.valid) {

            if (CommonsUtil.isEmpty(this.formGeradorEletrico.controls.identificacao.value)) {
                this.snackBarService.showAlert(
                    'O campo Identificação ' +
                    ' é obrigatório' +
                    'Favor realize o preenchimento do mesmo.');
                this.formGeradorEletrico.markAllAsTouched();
                return false;
            }

            return true;
        } else {
            this.snackBarService.showAlert(
                'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            this.formGeradorEletrico.markAllAsTouched();
            return false;
        }
    }

}
