import {Component, Injector, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {TipoProcesso} from '../../tipos-processo/tipo-processo.model';
import {TipoProcessoService} from '../../tipos-processo/tipo-processo.service';
import {Titulacao} from '../../titulacoes/titulacao.model';
import {TitulacaoService} from '../../titulacoes/titulacao.service';
import {Requerimento} from '../requerimento.model';
import {RequerimentoService} from '../requerimento.service';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {environment} from '../../../../../environments/environment';
import {SnackBarService} from '../../../shared/snack-bar/snack-bar.service';
import {FormUtil} from "../../../shared/util/form-util";

@Component({
    selector: 'app-requerimento-list',
    templateUrl: './requerimento-list.component.html',
    styleUrls: ['./requerimento-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class RequerimentoListComponent implements OnInit, OnChanges {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    form: FormGroup;
    tiposProcesso: Array<TipoProcesso>;
    titulacoes: Array<Titulacao>;
    dataSource: MatTableDataSource<Requerimento>;
    displayedColumns: string[] = ['numero', 'tipoProcesso', 'cpfCadastrante', 'dataAbertura', 'dataAlteracao', 'situacao', 'buttons'];
    totalElements: number;

    comparador = (a: Requerimento, b: Requerimento) =>
        a && b ? a.id === b.id : a === b

    constructor(
        injector: Injector,
        protected tipoProcessoService: TipoProcessoService,
        protected titulacaoService: TitulacaoService,
        public service: RequerimentoService,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        private snackBarService: SnackBarService
    ) {

    }

    ngOnInit(): void {
        this.paginator.firstPage();
        this.dataSource = new MatTableDataSource<Requerimento>();
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'numero':
                    const numeroSplit = item.numero.split('/');
                    return +numeroSplit[1] + (+numeroSplit[0] * 10000);
                case 'cpfCadastrante':
                    return item.pessoa.nome;
                default:
                    return item[property];
            }
        };
        this.getTipoProcesso();
        this.getTitulacoes();
        this.form = this.fb.group(this.returnObjectForm());
    }

    private returnObjectForm(): any {
        return {
            numeroRequerimento: [],
            nomeInteressado: [],
            nomeEmpreendimento: [],
            cpfCnpjInteressado: [],
            dataInicio: [],
            dataFim: [],
            situacaoProcesso: [],
            tipoProcessoId: [],
            titulacaoId: []
        };
    }

    getTipoProcesso(): void {
        this.tipoProcessoService
            .getAll()
            .subscribe(
                tiposProcesso => (this.tiposProcesso = tiposProcesso)
            );
    }

    get situacoesRequerimento(): BaseEnum[] {
        return Requerimento.situacoesRequerimento;
    }

    getTitulacoes(): void {
        this.titulacaoService
            .getAll()
            .subscribe(titulacoes => (this.titulacoes = titulacoes));
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.form.get('cpfCnpjInteressado').value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    numeroProcessoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.form.get('numeroProcesso').patchValue(`${initial}/${year}`);
            }
        }
    }

    numeroRequerimentoMask(text): void {
        if (text) {
            if (text.length > 4) {
                if (text.indexOf('/') > -1) {
                    text = text.replace('/', '');
                }
                const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
                const year = text.slice(-4);
                this.form.get('numeroRequerimento').patchValue(`${initial}/${year}`);
            }
        }
    }

    consultar(): void {
        FormUtil.resetPaginator(this.paginator);
        this.listarRequerimentos();
    }

    limpar(): void {
        this.form.reset();
        this.dataSource.data = [];
        FormUtil.resetPaginator(this.paginator);
    }

    listarRequerimentos(): void {
        this.service.listarRequerimentos(this.form, this.paginator).subscribe(response => {
            this.dataSource.data = response.content;
            this.totalElements = response.totalElements;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    isEllipsisActive(e): boolean {
        return (e.offsetWidth < e.scrollWidth);
    }

    /**
     * Funcionalidade utilizada somente para testes utilizando os profiles do ambiente de DESENVOLVIMENTO para testes 
     */
    pagarDar(requerimento: Requerimento): void {
        this.service.pagarDar(requerimento).subscribe(() => {
            this.snackBarService.showSuccess('A data de arrecadação foi definida com sucesso!\n\nAguarde até 3 minutos para que a rotina faça a baixa dos lancamentos!');
        }, e => this.snackBarService.showError('Ocorreu um erro.', e));
    }

    isHomologacao(): boolean {
        return environment.name !== 'production' && environment.name !== 'prod';
    }

    isLessOrEquals31122023(): boolean {
        return new Date() <= new Date(2024, 12, 31, 23, 59, 59, 999);
    }
}
