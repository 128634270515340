import {BaseModel} from "../base.model";

export class TipoTitulacaoArrecadacao extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public sigla?: string,
        public ordenacao?: number,
        public indAcessoPerfilExterno?: boolean
    ) {
        super();
    }

    static fromJson(json: any): TipoTitulacaoArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoTitulacaoArrecadacao(
            json.id,
            json.descricao,
            json.sigla,
            json.ordenacao,
            json.indAcessoPerfilExterno
        );
    }

    static fromJsons(json: any): TipoTitulacaoArrecadacao[] {
        const tipos: TipoTitulacaoArrecadacao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(tipo => tipos.push(this.fromJson(tipo)));
            return tipos;
        }
    }

}
