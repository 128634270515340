import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-subestacao-externa',
    templateUrl: './subestacao-externa.component.html',
    styleUrls: ['./subestacao-externa.component.scss']
})
export class SubestacaoExternaComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;

    possuiSubestacaoTerrea: FormControl;
    possuiSubestacaoAerea: FormControl;

    constructor() {
    }

    ngOnInit(): void {
        this.possuiSubestacaoTerrea = this.form.get('possuiSubestacaoTerrea') as FormControl;
        this.possuiSubestacaoAerea = this.form.get('possuiSubestacaoAerea') as FormControl;

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    ngOnChanges(): void {
    }

}
