import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ParecerTecnicoService} from '../../parecer-tecnico/parecer-tecnico.service';
import {Requerimento} from '../../requerimentos/requerimento.model';
import {ParecerTecnico} from '../parecer-tecnico.model';
import {MatDialog} from '@angular/material/dialog';
import {ParecerTecnicoConclusoesDialogComponent} from './parecer-tecnico-conclusoes-dialog/parecer-tecnico-conclusoes-dialog.component';


@Component({
    selector: 'app-parecer-tecnico-conclusoes',
    templateUrl: './parecer-tecnico-conclusoes.component.html',
    styleUrls: ['./parecer-tecnico-conclusoes.component.scss']
})
export class ParecerTecnicoConclusoesComponent implements OnInit {

    @Input() requerimento: Requerimento;
    @Input() parecerTecnico: ParecerTecnico;
    @Output() acaoUpdateEmitter = new EventEmitter<any>();
    @Output() deletedEmitter = new EventEmitter<any>();

    constructor(
        protected service: ParecerTecnicoService,
        protected injector: Injector,
        public dialog: MatDialog
    ) {

    }

    ngOnInit(): void {

    }

    acaoUpdate($event: any): void {
        this.acaoUpdateEmitter.emit($event);
    }

    acaoDeleted(conclusao: any): void {
        this.deletedEmitter.emit(conclusao);
    }

    abrirDialogParecerConclusoes(): void {
        const dialogRef = this.dialog.open(ParecerTecnicoConclusoesDialogComponent, {
            data: {requerimento: this.requerimento, parecerTecnico: this.parecerTecnico}
        });
        dialogRef.afterClosed().subscribe(() => {
            this.acaoUpdate(true);
        });
    }
}
