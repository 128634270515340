import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {CasaDeForca} from "../../../../../../../../shared/models/casa-de-forca.model";
import {EeHidricaService} from "../ee-hidrica.service";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
    selector: 'app-casa-de-forca',
    templateUrl: './casa-de-forca.component.html',
    styleUrls: ['./casa-de-forca.component.scss']
})
export class CasaDeForcaComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly: boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() enviarCasaDeForca = new EventEmitter<any[]>();
    formCasaDeForca: FormGroup;
    listaTipoCasaDeForca: any[] = [];
    mostrarDescricao: boolean = false;
    casaDeForcaSalvas: any[] = [];
    idCasaDeForca: number = 0;
    dataSourceCasaDeForca = new MatTableDataSource<any>([]);
    modoEdicao: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private eeHidricaService: EeHidricaService,
                private dominioService: DominiosService) {
    }

    ngOnInit() {
        this.buildFormCasaDeForca();

        this.dominioService.getAllTipoCasaForca().subscribe(resposta => {
            this.listaTipoCasaDeForca = resposta;
        })

        if (this.idForm) {
            this.eeHidricaService.buscarCasaForcaPorId(this.idForm).subscribe(response => {
                response.forEach(casaForca => {
                    this.casaDeForcaSalvas.push({
                        id: casaForca.id,
                        idTemp: this.idCasaDeForca,
                        tipo: casaForca.tipo,
                        descricao: casaForca.descricao,
                        quantidade: casaForca.quantidade,
                        aoPeDaBarragem: casaForca.aoPeDaBarragem == true ? 'sim' : 'nao'
                    });
                    this.idCasaDeForca++;
                })
                this.dataSourceCasaDeForca = new MatTableDataSource(this.casaDeForcaSalvas);
                this.enviarCasa(this.mapearArray());
            })
        }

        if (this.isNaoPodeEditar) {
            this.formCasaDeForca.disable();
        }
    }

    private buildFormCasaDeForca(): void {
        this.formCasaDeForca = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            tipo: ["", [Validators.required]],
            descricao: ["", [Validators.required]],
            quantidade: ["", [Validators.required]],
            aoPeDaBarragem: ["", [Validators.required]],
        });
        this.formCasaDeForca.controls.descricao.disable();
    }

    changeTipoCasaDeForca(event) {
        if (event == 'Outro') {
            this.formCasaDeForca.controls.descricao.reset();
            this.formCasaDeForca.controls.descricao.enable();
            this.mostrarDescricao = true;
        } else {
            this.formCasaDeForca.controls.descricao.reset();
            this.formCasaDeForca.controls.descricao.disable();
            this.mostrarDescricao = false;
        }
    }

    adicionarCasaDeForca() {
        if (this.formCasaDeForca.valid) {
            this.casaDeForcaSalvas.push({
                id: null,
                idTemp: this.idCasaDeForca,
                tipo: this.formCasaDeForca.controls.tipo.value,
                descricao: this.formCasaDeForca.controls.descricao.value,
                quantidade: this.formCasaDeForca.controls.quantidade.value,
                aoPeDaBarragem: this.formCasaDeForca.controls.aoPeDaBarragem.value,
            })
            this.idCasaDeForca++;
            this.dataSourceCasaDeForca = new MatTableDataSource(this.casaDeForcaSalvas);
            this.enviarCasa(this.mapearArray());
            this.formCasaDeForca.controls.descricao.disable();
            this.mostrarDescricao = false;
            this.formCasaDeForca.reset();
        } else {
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            this.formCasaDeForca.markAllAsTouched();
        }
    }

    ativarModoEdicao(item) {
        this.formCasaDeForca.controls.id.patchValue(item.id);
        this.formCasaDeForca.controls.idTemp.patchValue(item.idTemp);
        this.formCasaDeForca.controls.tipo.patchValue(item.tipo);
        if (item.descricao) {
            this.formCasaDeForca.controls.descricao.enable();
            this.formCasaDeForca.controls.descricao.patchValue(item.descricao);
            this.mostrarDescricao = true;
        } else {
            this.formCasaDeForca.controls.descricao.disable();
            this.mostrarDescricao = false;
        }
        this.formCasaDeForca.controls.quantidade.patchValue(item.quantidade);
        this.formCasaDeForca.controls.aoPeDaBarragem.patchValue(item.aoPeDaBarragem);
        this.modoEdicao = true;
    }

    fecharEdicao() {
        this.modoEdicao = false;
        this.formCasaDeForca.reset();
    }

    editarCasaDeForca() {
        if (this.formCasaDeForca.valid) {
            this.deletarCasaDeForca(this.formCasaDeForca.controls.idTemp.value);

            this.casaDeForcaSalvas.push({
                id: this.formCasaDeForca.controls.id.value,
                idTemp: this.formCasaDeForca.controls.idTemp.value,
                tipo: this.formCasaDeForca.controls.tipo.value,
                descricao: this.formCasaDeForca.controls.descricao.value ? this.formCasaDeForca.controls.descricao.value : "",
                quantidade: this.formCasaDeForca.controls.quantidade.value,
                aoPeDaBarragem: this.formCasaDeForca.controls.aoPeDaBarragem.value,
            })
            this.dataSourceCasaDeForca = new MatTableDataSource(this.casaDeForcaSalvas);
            this.enviarCasa(this.mapearArray());
            this.formCasaDeForca.reset();
            this.formCasaDeForca.controls.descricao.disable();
            this.mostrarDescricao = false;
            this.modoEdicao = false;
        } else {
            this.formCasaDeForca.markAllAsTouched();
            this.snackBarService.showAlert("Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    deletarCasaDeForca(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.eeHidricaService.deletarCasaForcaPorId(itemId).subscribe(response => {
                this.snackBarService.showSuccess("Casa de força excluido com sucesso.")
            });
        }
        let data;
        data = this.casaDeForcaSalvas.filter((value, key) => {
            return value.idTemp != item;
        });
        this.casaDeForcaSalvas = data;
        this.dataSourceCasaDeForca = new MatTableDataSource(data);
        this.enviarCasa(this.mapearArray());
    }

    enviarCasa(casaDeForca) {
        this.enviarCasaDeForca.emit(casaDeForca);
    }

    mapearArray(): any {
        if (this.casaDeForcaSalvas.length == 0) {
            return this.casaDeForcaSalvas;
        } else {
            return this.casaDeForcaSalvas.map(function (item) {
                return new CasaDeForca(
                    item.id,
                    null,
                    item.tipo,
                    item.descricao,
                    Number(item.quantidade),
                    item.aoPeDaBarragem == 'sim',
                );
            });
        }
    }
}