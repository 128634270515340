import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {QuadroColaboradores} from "../../../../../../../../shared/models/quadro-colaboradores.model";

@Component({
    selector: 'app-quadro-colaboradores',
    templateUrl: './quadro-colaboradores.component.html',
    styleUrls: ['./quadro-colaboradores.component.scss']
})
export class QuadroColaboradoresComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'numero', 'acoes'];

    tipos: DominioDto[];

    formQuadroColaboradores = this.fb.group({
        id: [null],
        mineracao: [null],
        idCategoriaColaborador: [null, Validators.required,],
        numero: [null, Validators.required,]
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('categoria-colaborador')
            .subscribe(doms => this.tipos = doms);

        this.formQuadroColaboradores.get('idCategoriaColaborador').valueChanges
            .subscribe(value => {
               const isOutro = value &&
                   this.tipos && !!this.tipos.find(d => d.id === value && d.descricao === 'Outro');

               if (isOutro) {
                   if (!this.formQuadroColaboradores.get('descricao')) {
                       this.formQuadroColaboradores.addControl('descricao',
                           this.fb.control(null, [Validators.required, Validators.maxLength(100)]))
                   }
               } else if (!!this.formQuadroColaboradores.get('descricao')) {
                   this.formQuadroColaboradores.removeControl('descricao');
               }

            });

        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formQuadroColaboradores.disable();
        }
    }

    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formQuadroColaboradores.valid) return;

        const quadrosColaboradores = this.form.get('quadrosColaboradores');

        quadrosColaboradores.setValue([
            ...(quadrosColaboradores.value || []),
            QuadroColaboradores.fromJson(this.formQuadroColaboradores.value)
        ]);

        this.formQuadroColaboradores.reset();
    }

    excluir(item: QuadroColaboradores) {
        const estruturas: AbstractControl = this.form.get('quadrosColaboradores');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: QuadroColaboradores) {
        this.formQuadroColaboradores.patchValue(item);

        this.excluir(item);
    }

}
