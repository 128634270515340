import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {SistemaTratamento} from "../../../../../../../shared/models/sistema-tratamento.model";
import {SistemaTratamentoService} from "../../../../../../../shared/services/sistema-tratamento.service";
import {AbstractAbaAtividadeRequerimentoRelacionamento} from "../../../../../../../shared/components/aba-atividades/abstract-aba-atividade-requerimento-relacionamento";
import {SistemaTratamentoPrimarioService} from "../../../../../../../shared/services/sistema-tratamento-primario.service";
import {SistemaTratamentoTerciarioService} from "../../../../../../../shared/services/sistema-tratamento-terciario.service";
import {SistemaTratamentoSecundarioService} from "../../../../../../../shared/services/sistema-tratamento-secundario.service";
import {SistemaTratamentoComplementarService} from "../../../../../../../shared/services/sistema-tratamento-complementar.service";
import {BaseRelacionamentoService} from "../../../../../../../shared/services/base-relacionamento.service";
import {checkboxBooleanRequiredDirective} from "../../../../../../../shared/directives/checkbox-boolean-required.directive";
import {SnackBarService, SnackBarType} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-aba-atividade-sistema-tratamento',
    templateUrl: './sistema-tratamento.component.html',
    styleUrls: ['./sistema-tratamento.component.scss']
})
export class SistemaTratamentoComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit{

    form: FormGroup;
    entidade: SistemaTratamento = {} as any;

    consultasIniciaisFinalizadas: boolean = false;
    isNaoPodeEditar: boolean;

    constructor(
        protected formBuilder: FormBuilder,
        protected dominiosService: DominiosService,
        protected service: SistemaTratamentoService,
        public primarioService: SistemaTratamentoPrimarioService,
        public secundarioService: SistemaTratamentoSecundarioService,
        public terciarioService: SistemaTratamentoTerciarioService,
        public complementarService: SistemaTratamentoComplementarService,
        private snackBarService: SnackBarService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
    ) {
        super();
    }

    async ngOnInit() {
        this.consultasIniciaisFinalizadas = false;
        this.form = this.formBuilder.group({});
        this.addControlsForm();
        await this.getDominios();
        await this.getEntidadeByRequerimento();

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
            this.form.disable()
        }
    }

    async carregarSistemaTratamento(idRequerimento){
        let res = await this.service.getByRequerimento(idRequerimento).toPromise();
        this.entidade = res ? res : {} as any;

        if (res) {
            await this.atualizaFormEntidade();
            this.consultasIniciaisFinalizadas = true;
        } else {
            this.consultasIniciaisFinalizadas = true;
        }
    }

    protected async getDominios(): Promise<void> {
        for (let serv of this.todasServicesRelacionamentos) {
            await serv.getDominio();
        }
    }

    protected async getEntidadeByRequerimento() {

        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarSistemaTratamento(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarSistemaTratamento(this.requerimento.id)
            }
        }
        if(this.isModoVisualizacao){
            this.form.disable()
        }
    }

    private async atualizaFormEntidade() {
        this.form.patchValue(this.entidade);
    }

    protected addControlsForm() {
        this.form.addControl('domestico', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'industrial')]));
        this.form.addControl('industrial', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'domestico')]));
        this.form.addControl('primario', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'secundario', 'terciario', 'complementar')]));
        this.form.addControl('secundario', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'primario', 'terciario', 'complementar')]));
        this.form.addControl('terciario', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'secundario', 'primario', 'complementar')]));
        this.form.addControl('complementar', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'secundario', 'terciario', 'primario')]));
        this.form.addControl('individual', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'coletivo')]));
        this.form.addControl('coletivo', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'individual')]));
    }


    hasPrimario() {
        return this.getValueFromEntidade('primario');
    }

    hasSecundario() {
        return this.getValueFromEntidade('secundario');
    }

    hasTerciario() {
        return this.getValueFromEntidade('terciario');
    }

    hasComplementar() {
        return this.getValueFromEntidade('complementar');
    }

    protected getValueFromEntidade(parametro: string) {
        let tipo;
        if (this.form) {
            tipo = this.form.get(parametro).value;
        }

        if (!tipo) {
            if (this.entidade) {
                tipo = this.entidade[parametro]
            }
        }

        return tipo;
    }

    protected get servicesRelacionamentosAtivos(): Array<BaseRelacionamentoService<any>> {
        let services = [];

        if (this.hasPrimario()) {
            services.push(this.primarioService);
        }
        if (this.hasSecundario()) {
            services.push(this.secundarioService);
        }
        if (this.hasTerciario()) {
            services.push(this.terciarioService);
        }
        if (this.hasComplementar()) {
            services.push(this.complementarService);
        }

        return services;
    }

    protected async salvaRelacionamentos() {
        if (this.hasPrimario()) {
            await this.primarioService.createSelecionados(this.entidade.id);
        }
        if (this.hasSecundario()) {
            await this.secundarioService.createSelecionados(this.entidade.id);
        }
        if (this.hasTerciario()) {
            await this.terciarioService.createSelecionados(this.entidade.id);
        }
        if (this.hasComplementar()) {
            await this.complementarService.createSelecionados(this.entidade.id);
        }
    }

    protected get todasServicesRelacionamentos(): Array<BaseRelacionamentoService<any>> {
        return [
            this.primarioService,
            this.secundarioService,
            this.terciarioService,
            this.complementarService
        ];
    }

    protected preSalva() {
        let valid = true;
        if (this.primarioService.info.selecionados.length < 1 ||
            this.secundarioService.info.selecionados.length < 1 ||
            this.terciarioService.info.selecionados.length < 1 ||
            this.complementarService.info.selecionados.length < 1
        ) {
            valid = false;
        }

        if (!valid) {
            this.snackBarService.show('O formulário de Atividades: possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).', SnackBarType.ERROR);
        }
    }

    public async salva() {
        this.preSalva();

        this.form.markAllAsTouched();

        if (this.isFormValido()) {
            let res = SistemaTratamento.fromJson(this.form.value);
            res['requerimento'] = this.requerimento.id;
            res.id = this.entidade.id;


            if (this.entidade.id === undefined) {
                this.service.create(res).subscribe(result => {
                    this.entidade = result;
                    // this.salvaRelacionamentos();
                    this.enviarFormConcluido.emit();
                });
            } else {
                this.service.update(res).subscribe(result => {
                    this.entidade = result;
                    // this.salvaRelacionamentos();
                    this.enviarFormConcluido.emit();
                });
            }
        }

    }

    public isFormValido() {
        return this.form.valid;
    }
}
