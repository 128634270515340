import {EnumSituacaoProcesso} from 'app/main/shared/enums/situacao-processo.enum';

export class VinculacaoProcesso {
    constructor(
        public id?: number,
        public numeroProcesso?: string,
        public tipoProcesso?: string,
        public situacaoProcesso?: EnumSituacaoProcesso,
        public situacaoProcessoDescricao?: string
    ) {
    }

    static fromJson(json: any): VinculacaoProcesso {
        if (json === undefined || json === null) {
            return null;
        }
        return new VinculacaoProcesso(
            json.id ? json.id : null,
            json.numeroProcesso ? json.numeroProcesso : null,
            json.tipoProcesso ? json.tipoProcesso : null,
            json.situacaoProcesso ? json.situacaoProcesso : null,
            json.situacaoProcessoDescricao ? json.situacaoProcessoDescricao : null,
        );
    }

    static fromJsons(json: any): VinculacaoProcesso[] {
        const vinculacaoProcessos: VinculacaoProcesso[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(vinculacaoProcesso => vinculacaoProcessos.push(this.fromJson(vinculacaoProcesso)));
            return vinculacaoProcessos;
        }
    }
}
