import {BaseModel} from 'app/main/shared/models/base.model';
import {ParqueBomba} from "./parque-bomba.model";


export class AreaAbastecimento extends BaseModel {
    constructor(
        public id?: number,
        public coberto?: boolean,
        public pisoImpermeavel?: boolean,
        public canaletas?: boolean,
        public plataformaCarga?: boolean,
        public parqueBomba?: ParqueBomba
    ) {
        super();
    }

    static fromJson(json: any): AreaAbastecimento {
        if (json === undefined || json === null) {
            return null;
        }

        return new AreaAbastecimento(
            json.id,
            json.coberto,
            json.pisoImpermeavel,
            json.canaletas,
            json.plataformaCarga,
            ParqueBomba.fromJson(json.parqueBomba)
        );
    }
}
