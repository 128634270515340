import {ReceitaGrupoArrecadacao} from './receita-grupo-arrecadacao.model';
import {BaseModel} from '../base.model';

export class ReceitaNaturezaArrecadacao extends BaseModel {

    constructor(
        public id?: number,
        public descricao?: string,
        public receitaGrupo?: ReceitaGrupoArrecadacao,
    ) {
        super();
    }

    static fromJson(json: any): ReceitaNaturezaArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new ReceitaNaturezaArrecadacao(
            json.id,
            json.descricao,
            ReceitaGrupoArrecadacao.fromJson(json.receitaGrupo),
        );
    }
}
