import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from '../requerimentos/requerimento.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {DocumentoProcesso} from '../../shared/models/documento-processo.model';

export class ParecerTecnico extends BaseModel {
    constructor(
        public id?: number,
        public dataCriacao?: string,
        public descricao?: string,
        public situacao?: string,
        public chaveHtmlDocumento?: string,
        public requerimento?: Requerimento,
        public documentoProcesso?: DocumentoProcesso,
        public numero?: string,
        public exigeEIARIMA?: string,
        public chaveDocumentoComAnexos?: string,
    ) {
        super();
        this.exigeEIARIMA = this.exigeEIARIMA || 'NAO';
    }

    static situacaoParecerTecnico: BaseEnum[] = [
        {valor: '0', descricao: 'Criado'},
        {valor: '1', descricao: 'Desabilitado'}
    ];

    static exigeEIARIMA: BaseEnum[] = [
        {valor: 'NAO', descricao: 'Não'},
        {valor: 'DEFERIDO', descricao: 'Sim, deferido com EIA/RIMA'},
        {valor: 'DISPENSA', descricao: 'Sim, deferido com dispensa de EIA/RIMA'},
        
    ];


    static fromJson(json: any): ParecerTecnico {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParecerTecnico(
            json.id,
            json.dataCriacao,
            json.descricao,
            json.situacao,
            json.chaveHtmlDocumento,
            Requerimento.fromJson(json.requerimento),
            DocumentoProcesso.fromJson(json.documentoProcesso),
            json.numero,
            json.exigeEIARIMA,
            json.chaveDocumentoComAnexos,
        );
    }
}
