import {BaseModel} from "./base.model";

export class EmpreendimentoEeHidrica extends BaseModel {
    constructor(
        public id?: number,
        public idRequerimento?: number,
        public tipoEmpreendimentoEnergetico?: string,
        public naMontante?: number,
        public naJusante?: number,
        public fatorCapacidade?: number,
        public quedaBruta?: number,
        public areaReservatorio?: number,
        public vazaoQmlt?: number,
        public potenciaTotal?: number,
        public energiaMedia?: number,
        public possuiBarragem?: boolean,
        public possuiReservatorio?: boolean,
        public possuiCanalDerivacao?: boolean,
        public tipoCircuito?: string,
        public tipoCabo?: string,
        public extensaoTransmissaoInteresse?: number,
        public tensaoTransmissao?: number,
        public resistividadeTransmissao?: number,
        public comprimentoCanalAducao?: number,
        public larguraMediaCanalAducao?: number,
        public qtdeCondutoTunel?: number,
        public diametroInternoTunel?: number,
        public comprimentoMedioTunel?: number,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoEeHidrica {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoEeHidrica(
            json.id,
            json.idRequerimento,
            json.tipoEmpreendimentoEnergetico,
            json.naMontante,
            json.naJusante,
            json.fatorCapacidade,
            json.quedaBruta,
            json.areaReservatorio,
            json.vazaoQmlt,
            json.potenciaTotal,
            json.energiaMedia,
            json.possuiBarragem,
            json.possuiReservatorio,
            json.possuiCanalDerivacao,
            json.tipoCircuito,
            json.tipoCabo,
            json.extensaoTransmissaoInteresse,
            json.tensaoTransmissao,
            json.resistividadeTransmissao,
            json.comprimentoCanalAducao,
            json.larguraMediaCanalAducao,
            json.qtdeCondutoTunel,
            json.diametroInternoTunel,
            json.comprimentoMedioTunel,
        );
    }

    static fromJsons(json: any): EmpreendimentoEeHidrica[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(EmpreendimentoEeHidrica.fromJson(j)));
            return resources;
        }
    }
}