import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface ExcludeData {
    id: any;
    label: string;
}

@Component({
    selector: 'app-confirmar-cancelamento-dialog',
    templateUrl: './confirmar-cancelamento-dialog.component.html',
})
export class ConfirmarCancelamentoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarCancelamentoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ExcludeData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
