import {BaseModel} from './base.model';
import {DocumentoProcesso} from './documento-processo.model';
import {Pessoa} from './pessoa.model';

export class DocumentoProcessoSignatario extends BaseModel {
    constructor(
        public id?: number,
        public dataAssinatura?: Date,
        public pessoa?: Pessoa,
        public hashDocumento?: string,
        public documentoProcesso?: DocumentoProcesso
    ) {
        super();
    }

    static fromJson(json: any): DocumentoProcessoSignatario {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoProcessoSignatario(
            json.id,
            json.dataAssinatura,
            json.pessoa,
            json.hashDocumento,
            json.documentoProcesso,
        );
    }

    static fromJsons(json: any): DocumentoProcessoSignatario[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(DocumentoProcessoSignatario.fromJson(j)));
            return resources;
        }
    }

}
