import {Component, Input, ViewChild} from '@angular/core';
import {AbstractComponenteRelacionamento} from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento";
import {FormGroup, NgForm} from "@angular/forms";
import {MateriaPrimaIndFrigorifico} from "../../../../../../../../shared/models/materia-prima-ind-frigorifico.model";
import {MateriaPrimaIndFrigorificoService} from "../../../../../../../../shared/services/materia-prima-ind-frigorifico.service";

@Component({
  selector: 'app-materia-prima-ind-frigorifico',
  templateUrl: './materia-prima-ind-frigorifico.component.html',
  styleUrls: ['./materia-prima-ind-frigorifico.component.scss']
})
export class MateriaPrimaIndFrigorificoComponent extends AbstractComponenteRelacionamento<MateriaPrimaIndFrigorifico, MateriaPrimaIndFrigorificoService>{

    TIPO_OUTRO = "Outros";
    @Input() form: FormGroup;
    @ViewChild('insideForm', {static: false}) insideForm: NgForm;
    @Input() isNaoPodeEditar: boolean;

    getDisplayedColumns(): string[] {
        return ['materiaPrima', 'descricao', 'acoes'];
    }


}

