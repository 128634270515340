import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import { BaseService } from 'app/main/shared/services/base.service';
import { DocumentoProcesso } from './documento-processo.model';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { DocumentoTecnicoDTO } from './documento-tecnico-dto.model';


@Injectable({
    providedIn: 'root'
})
export class DocumentoProcessoService extends BaseService<DocumentoProcesso> {

    constructor(injector: Injector) {
        super('/api/documento-processo', injector, DocumentoProcesso.prototype, DocumentoProcesso.fromJson);
    }

    downloadDocumentoProcesso(
        documentoProcesso: DocumentoProcesso): Observable<Blob> {
        const url = `${this.urlResource}/visualizar/${documentoProcesso.id}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    readPDFByChave(chave: string): Observable<Blob> {
        const url = `${this.urlResource}/${chave}/pdf`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    public findDocumentoTecnicoByRequerimento(requerimento: Requerimento) {
        const url = `${this.urlResource}/documentos-tecnicos/requerimento/${requerimento.id}`;
        return this.http
            .get(url)
            .pipe(
                map(DocumentoTecnicoDTO.fromJsons.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    buscarTodosPorIdRequerimento(idRequerimento: number): Observable<DocumentoProcesso[]> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

}
