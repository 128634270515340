import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {MatDialog} from '@angular/material';
import {RepresentanteLegal} from 'app/main/shared/models/representante-legal.model';
import {RepresentanteLegalDocumentoCadastro} from 'app/main/shared/models/representante-legal-documento-cadastro.model';
import {RepresentanteLegalDocumentoService} from 'app/main/shared/services/representante-legal-documento.service';
import {RequerimentoResponsavelLegalArquivosDialogComponent} from 'app/main/shared/components/documento-representante-legal/documento-representante-legal-arquivo-dialog.component';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {Observable} from 'rxjs';
import {SnackBarService} from '../../snack-bar/snack-bar.service';

@Component({
    selector: 'app-documento-representante-legal',
    templateUrl: './documento-representante-legal.component.html',
})
export class RepresentanteLegalDocumentoCadastroComponent implements OnInit {

    @Input('representanteLegal') representanteLegal: RepresentanteLegal;
    @Input('requerimento') requerimento: Requerimento;

    @Output('onAnexar') onAnexar: EventEmitter<any> = new EventEmitter();

    documentos: RepresentanteLegalDocumentoCadastro[];
    isSomenteVisualizacao: boolean = false;

    constructor(
        protected service: RepresentanteLegalDocumentoService,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
        this.listarDocumentos();
    }

    public listarDocumentos(): void {
        this.service
            .getDocumentos(this.representanteLegal.id)
            .subscribe(documentosPage => {
                this.documentos = documentosPage.content;
            });
    }

    private listarDocumentosSub(): Observable<any> {
        return this.service
            .getDocumentos(this.representanteLegal.id);

    }

    public documentoDialog(documento?: RepresentanteLegalDocumentoCadastro): void {
        const dialogRef = this.dialog.open(
            RequerimentoResponsavelLegalArquivosDialogComponent,
            {
                width: '600px',
                data: {
                    documento: documento == null
                        ? new RepresentanteLegalDocumentoCadastro(
                            null,
                            null,
                            null,
                            null,
                            this.representanteLegal,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                        ) : documento,
                    permiteEditarTitulo: false
                }

            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.listarDocumentosSub().subscribe(documentosPage => {
                    this.documentos = documentosPage.content;
                    this.onAnexar.emit({representanteLegal: this.representanteLegal, documentos: this.documentos});
                });


            }
        });
    }

    public visualizarDocumento(documento: RepresentanteLegalDocumentoCadastro): void {
        window.open(this.service.urlResource + '/' + documento.representanteLegal.id + '/documentos/' + documento.id + '/visualizar');
    }

    public downloadDocumento(documento: RepresentanteLegalDocumentoCadastro): void {
        window.open(this.service.urlResource + '/' + documento.representanteLegal.id + '/documentos/' + documento.id + '/download');
    }

}
