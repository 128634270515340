import {BaseModel} from "../base.model";

export class GeometriaPoligono extends BaseModel {
    constructor(
        public id?: number,
        public espessuraBorda?: number,
        public corBorda?: string,
        public corPreenchimento?: string,
        public tipoTracejado?: number
    ) {
        super();
    }

    static fromJson(json: any): GeometriaPoligono {
        if (json === undefined || json === null) {
            return null;
        }
        return new GeometriaPoligono(
            json.id,
            json.espessuraBorda,
            json.corBorda,
            json.corPreenchimento,
            json.tipoTracejado
        );
    }
}
