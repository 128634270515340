import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {RequerimentoLicenciamento} from './requerimento-licenciamento.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {FeicaoRequerimentoLicenciamentoDto} from '../../../shared/models/feicao-requerimento-licenciamento-dto.model';
import {ResultadoRestricaoPontoGeo} from '../../../shared/models/resultado-restricao-ponto-geo.model';
import { Requerimento } from '../requerimento.model';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RequerimentoLicenciamentoService extends BaseService<RequerimentoLicenciamento> {
    constructor(protected injector: Injector) {
        super(
            '/api/requerimento-licenciamento',
            injector,
            RequerimentoLicenciamento.prototype,
            RequerimentoLicenciamento.fromJson
        );
    }

    getListaRequerimentoLicenciamento(id?: number): Observable<RequerimentoLicenciamento[]> {
        const url = `${this.urlResource}/requerimento-taxa-licenciamento/${id}`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getRequerimentoLicenciamentoByRequerimentoId(idRequerimento: number): Observable<RequerimentoLicenciamento> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;
        return this.http
        .get(url)
        .pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    
    downloadRelatorioDocumentoFlorestal(idRed: number): Observable<Blob> {
        return this.http
        .get(`${this.urlResource}/gerar-relatorio/${idRed}`, {
            responseType: 'blob'
        }).pipe(map((res) => {
            return new Blob([res], {type: res.type});
        }));
    }

    finalizarProcesso(idTarefa: string, idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/${idTarefa}/finalizar-processo`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
    }

    getFeicoes(idRequerimentoLicenciamento: number): Observable<FeicaoRequerimentoLicenciamentoDto[]> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    getFeicoesPorIds(listaIds: number[], idRequerimentoLicenciamento: number): Observable<FeicaoRequerimentoLicenciamentoDto[]> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes-por-id`;
        let urlParams: HttpParams = new HttpParams();
        urlParams = urlParams.append('listaIds', listaIds.toString());
        return this.http.get(url, {params: urlParams}).pipe(catchError(this.handleError.bind(this)));
    }

    getFeicoesPorIdGeometria(idRequerimentoLicenciamento: number,  idGeometria: number): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes/${idGeometria}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    deleteGeometria(idRequerimentoLicenciamento: number, idGeometria: number): Observable<any> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes/${idGeometria}`;
        return this.http.delete(url).pipe(catchError(this.handleError.bind(this)));
    }

    deleteGeometrias(idRequerimentoLicenciamento: number, feicoes: FeicaoRequerimentoLicenciamentoDto[]): Observable<any> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes`;
        return this.http.request('delete', url, { body: feicoes }).pipe(catchError(this.handleError.bind(this)));
    }

    postGeometria(idRequerimentoLicenciamento: number, feicao: FeicaoRequerimentoLicenciamentoDto): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes`;
        return this.http.post(url, feicao).pipe(catchError(this.handleError.bind(this)));
    }

    postGeometriaBatch(idRequerimentoLicenciamento: number, feicoes: FeicaoRequerimentoLicenciamentoDto[]): Observable<FeicaoRequerimentoLicenciamentoDto[]> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes-batch`;
        return this.http.post(url, feicoes).pipe(catchError(this.handleError.bind(this)));
    }

    putGeometria(idRequerimentoLicenciamento: number, idFeicaoRequerimento: number, feicao: FeicaoRequerimentoLicenciamentoDto): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes/${idFeicaoRequerimento}`;
        return this.http.put(url, feicao).pipe(catchError(this.handleError.bind(this)));
    }
    postValidacoes(feicao: FeicaoRequerimentoLicenciamentoDto): Observable<ResultadoRestricaoPontoGeo[]> {
        const url = `${this.urlResource}/feicoes/validacao`;
        return this.http.post(url, feicao).pipe(catchError(this.handleError.bind(this)));
    }

    getUnidadeProducaoAnual(idRequerimentoLicenciamento: number): Observable<number> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes/area/unidade-producao-anual`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    readPdf(requerimento: Requerimento): Observable<any> {
        return this.http.get(`${this.urlResource}/${requerimento.id}/pdf`, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    passaDadosReqVinculado(idRequerimento: number, idTarefa: string): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/tarefa/${idTarefa}/passa-dados-vinculados`;
        return this.http.put(url, {}).pipe(catchError(this.handleError.bind(this)));
    }

    passaDadosReqVinculadoLac(idRequerimento: number, idTarefa: string): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/tarefa/${idTarefa}/passa-dados-vinculados-lac`;
        return this.http.put(url, {}).pipe(catchError(this.handleError.bind(this)));
    }

    //TODO serviço para gerar licenças suimis para a equipe de teste
    downloadLicencaSuimisTeste(idRed: number, tipo: string): Observable<Blob> {
        return this.http
            .get(`${this.urlResource}/gerar-licencas-teste/${idRed}/${tipo}`, {
                responseType: 'blob'
            }).pipe(map((res) => {
                return new Blob([res], {type: res.type});
            }));
    }

    getFeicoesAnalisadas(numeroProcesso: string): Observable<FeicaoRequerimentoLicenciamentoDto[]> {
        const url: string = `${this.urlResource}/numero-processo/feicoes`;
        return this.http.post(url, numeroProcesso).pipe(catchError(this.handleError.bind(this)));
    }
}
