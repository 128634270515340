import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {MatDialog} from '@angular/material';
import {Imovel} from 'app/main/pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model';
import {RequerimentoService} from '../../../pages/requerimentos/requerimento.service';
import {Requerimento} from '../../../pages/requerimentos/requerimento.model';
import {ImovelService} from '../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.service';
import {ImovelPessoaService} from '../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel-pessoa.service';
import {DocumentoImovel} from '../../models/documento-imovel.model';
import {DocumentoImovelService} from '../../services/documento-imovel.service';
import {ImovelPessoa} from '../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel-pessoa.model';
import {SnackBarService} from '../../snack-bar/snack-bar.service';
import {ResponsavelImovelDialogComponent} from './dialog-responsavel-imovel/responsavel-imovel-dialog-component';
import {DocumentoRequerimento} from "../../../pages/documentos-requerimentos/documento-requerimento.model";
import {ArquivoService} from "../../services/arquivo.service";
import {PdfViewerDialog} from "../pdf-viewer-dialog/pdf-viewer-dialog.model";
import {isNullOrUndefined} from "util";


@Component({
    selector: 'app-aba-imoveis',
    templateUrl: 'aba-imoveis.component.html',
    styleUrls: ['aba-imoveis.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class AbaImoveisComponent implements OnInit, OnChanges {
    unidadesDeMedidas = Imovel.unidades;
    @Input() requerimento = new Requerimento();
    imovel: Imovel = new Imovel();
    responsaveisAdicionados: ImovelPessoa[];
    documentos: DocumentoImovel[];
    possuiInformacaoAbastecimento: boolean;
    resultadoConsultaTodosImoveis: Imovel[] = [];

    constructor(
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        protected service: RequerimentoService,
        protected imovelPessoaService: ImovelPessoaService,
        private imovelService: ImovelService,
        private documentoImovelService: DocumentoImovelService,
        private arquivoService: ArquivoService,
        private pdfViewerDialog: PdfViewerDialog
    ) {
    }

    ngOnInit(): void {
    }

    private fetchImovel(): void {
        this.imovelService
            .getByRequerimento(this.requerimento)
            .subscribe(imovel => {
                this.imovel = imovel;
                this.possuiInformacaoAbastecimento = !isNullOrUndefined(this.imovel.numeroMatriculaAbastecimento) ||
                    !isNullOrUndefined(this.imovel.anexo);
                this.getResponsaveis(imovel);
                if(imovel.tipo == 'RURAL'){
                    this.listarTodosImoveis();
                }
            }, e => this.imovel = null);
    }

    async listarTodosImoveis(){
        this.resultadoConsultaTodosImoveis = await this.imovelService.buscarTodosPorRequerimento(this.requerimento.id).toPromise();
    }

    visualizarImovel(imovel){
        this.imovel = imovel;
        this.getResponsaveis(imovel)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            this.fetchImovel();
        }
    }

    getResponsaveis(imovel: Imovel) {
        this.imovelPessoaService.getAllByImovelUrbano(imovel).subscribe(
            (pessoas) => {
                this.responsaveisAdicionados = pessoas;
                if (pessoas.length > 0) {
                    this.listarDocumentos(imovel);
                }
            },
            () =>
                this.snackBarService.showError('Erro ao listar responsáveis pelo imóvel.')
        );
    }

    public listarDocumentos(imovel: Imovel) {
        this.documentoImovelService
            .getByImovelUrbano(imovel)
            .subscribe((documentos) => {
                this.documentos = documentos;
            });
    }

    visualizarDocumento(documentoImovel: DocumentoImovel): void {
        window.open(
            this.documentoImovelService.urlResource +
            '/' +
            documentoImovel.id +
            '/visualizar'
        );
    }

    downloadDocumento(documentoImovel: DocumentoImovel): void {
        window.open(
            this.documentoImovelService.urlResource +
            '/' +
            documentoImovel.id +
            '/download'
        );
    }

    get denominacao(): string {
        if (this.imovel) {
            if (this.imovel.tipo === 'OBRA') {
                return 'Denominação da Obra';
            } else {
                return 'Denominação do imóvel';
            }
        }
    }

    openDialogResponsavel(responsavel: any, imovel: any, requerimento: any): void {
        this.dialog.open(ResponsavelImovelDialogComponent, {
                width: '1800px',
                maxHeight: '535px',
                data: {responsavel: responsavel, imovel: imovel, requerimento: requerimento}
            }
        );
    }

    visualizarDocRequerimento(doc: DocumentoRequerimento) {
        this.arquivoService.download(doc.chave).subscribe(result => this.pdfViewerDialog.show(result))
    }
}
