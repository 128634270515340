import {HistoricoRequerimento, HistoricoRequerimentoEnum} from './shared/historico-requerimento/model/historico-requerimento.model';
import {HttpParams} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material';
import {ActivatedRouteSnapshot} from '@angular/router';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {Page} from 'app/main/shared/models/page.model';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {RequerimentoOutorgaService} from './requerimento-outorga/requerimento-outorga.service';
import {Requerimento} from './requerimento.model';
import {RequerimentoNota} from './requerimento-notas/requerimento-notas.model';
import {ResponsavelTecnico} from '../../shared/models/responsavel-tecnico.model';
import {DocumentoRT} from '../../shared/models/documento-rt.model';
import {EnumTipoDocumentoRT} from '../../shared/models/enum-tipo-documento-rt.model';
import {DocumentoRL} from './shared/requerimento-responsaveis/representante-legal/documento-rl.model';
import {EnumTipoDocumentoRL} from './shared/requerimento-responsaveis/representante-legal/enum-tipo-documento-rl.model';
import {Pessoa} from '../../shared/models/pessoa.model';
import {EnumTipoDocumentoDoencaPessoa} from './shared/requerimento-responsaveis/enum-tipo-documento-doenca.model';
import {DocumentoDoencaPessoa} from './shared/requerimento-responsaveis/documento-doenca-pessoa.model';
import {RequerimentoTitulacao} from './shared/requerimento-titulacao/shared/requerimento-titulacao.model';
import {ParecerTecnico} from '../parecer-tecnico/parecer-tecnico.model';
import {RequerimentoWrapper} from './requerimento-wrapper.model';
import {HistoricoAlteracaoRt} from './shared/alteracao-responsavel-tecnico/historico-alteracao.rt';
import {DespachoDto} from '../../shared/models/despacho-dto.model';
import {ItemTermoReferenciaRequerimentoChecklist} from '../../shared/models/item-termo-referencia-requerimento-checklist.model';
import {TipoRequerimento} from '../tipos-requerimento/tipo-requerimento.model';
import {TipoProcesso} from '../tipos-processo/tipo-processo.model';
import {ServiceAssinador} from '../../shared/services/service-assinador.interface';
import {AssinanteRequerimentoDto} from "./assinante-requerimento.dto";
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { environment } from 'environments/environment';
import { TarefaService } from 'app/main/shared/services/tarefa.service';
import { EnumProcessDefinition } from 'app/main/shared/enums/process-definition.enum';
import { BpmsApiService } from 'app/main/shared/services/bpms-api.service';
import { LocalStorageService } from 'app/main/shared/services/local-storage.service';
import { VerificaRevisaoProcessoDTO } from '../tarefas/requerimento-verificar-revisao-processo/verifica-revisao-processo.dto.model';

@Injectable()
export class RequerimentoListResolver extends ListResolver<Requerimento> {
    constructor(service: RequerimentoService) {
        super(service);
    }
}

@Injectable()
export class RequerimentoResolver {
    constructor(
        private service: RequerimentoService,
        private routingStateService: RoutingStateService,
        private requerimentoOutorgaService: RequerimentoOutorgaService,
        private taskService: BpmsApiService,
        private localStorageService: LocalStorageService
    ) {
    }

    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        this.routingStateService.addQueryParamsHistory(route.queryParams);
        if (route.params['id'] != null) {
            const requerimento = await this.service
                .getById(route.params['id'])
                .toPromise();
            const retorno = {requerimento: requerimento};
            if (requerimento.tipoProcesso.tipo.toUpperCase() === 'OUTORGA') {
                retorno['requerimentoOutorga'] = await this.requerimentoOutorgaService
                    .getByRequerimentoId(requerimento.id)
                    .toPromise();
            } else if (
                requerimento.tipoProcesso.tipo.toUpperCase() === 'LICENCIAMENTO'
            ) {
                // TODO Obter requerimentoLicenca
            }

            if (route.params['idTarefa']) 
                await this.taskService.getTarefaPorId(route.params['idTarefa'], true).toPromise().then((res) =>  {
                    this.localStorageService.set('currentTask', res);
                    retorno['currentTask'] = res;
                }).catch(() => null);
            return retorno;
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class RequerimentoService extends BaseService<Requerimento> implements ServiceAssinador {
    public static URL_RESOURCE = `${environment.URL_GATEWAY}/api/requerimentos`;

    constructor(protected injector: Injector, private snackBarService: SnackBarService) {
        super(
            '/api/requerimentos',
            injector, Requerimento.prototype,
            Requerimento.fromJson
        );
    }

    public listarProcessosRequerimentos(
        form: FormGroup,
        paginator: MatPaginator
    ): Observable<Page<Requerimento>> {
        const url = `${this.urlResource}/listar-requerimentos-processos`;

        const params: any = {
            page: String(paginator.pageIndex),
            size: String(paginator.pageSize),
            numeroRequerimento: form.get('numeroRequerimento').value,
            numeroProcesso: form.get('numeroProcesso').value,
            cpfCnpjInteressado: form.get('cpfCnpjInteressado').value,
            nomeInteressado: form.get('nomeInteressado').value,
            nomeEmpreendimento: form.get('nomeEmpreendimento').value,
            dataInicio: new Date(form.get('dataInicio').value).getTime(),
            dataFim: new Date(form.get('dataFim').value).getTime(),
            tipoProcessoId: form.get('tipoProcessoId').value,
            situacaoProcesso: form.get('situacaoProcesso').value,
            titulacaoId: form.get('titulacaoId').value,
        };

        const httpParams: HttpParams = Object.getOwnPropertyNames(params)
            .filter(item => params[item])
            .reduce(
                (p, key) => p.set(key, params[key])
                , new HttpParams()
            );
        
        if(httpParams.keys().length <= 2) {
            this.snackBarService.showError('Obrigatório informar ao menos um filtro para pesquisa.');
            return;
        } 

        return this.http
            .get(url, {params: httpParams})
            .pipe(
                map((response) => this.jsonToPage(response)),
                catchError(this.handleError.bind(this)
                )
            );
    }

    public listarRequerimentos(
        form: FormGroup,
        paginator: MatPaginator
    ): Observable<Page<Requerimento>> {
        const url = `${this.urlResource}/page`;

        const params: any = {
            page: String(paginator.pageIndex),
            size: String(paginator.pageSize),
            numeroRequerimento: form.get('numeroRequerimento').value,
            cpfCnpjInteressado: form.get('cpfCnpjInteressado').value,
            nomeInteressado: form.get('nomeInteressado').value,
            nomeEmpreendimento: form.get('nomeEmpreendimento').value,
            dataInicio: new Date(form.get('dataInicio').value).getTime(),
            dataFim: new Date(form.get('dataFim').value).getTime(),
            tipoProcessoId: form.get('tipoProcessoId').value,
            situacaoProcesso: form.get('situacaoProcesso').value,
            titulacaoId: form.get('titulacaoId').value,
        };

        const httpParams = Object.getOwnPropertyNames(params)
            .filter(item => params[item])
            .reduce(
                (p, key) => p.set(key, params[key])
                , new HttpParams()
            );

        return this.http
            .get(url, {params: httpParams})
            .pipe(
                map((response) => this.jsonToPage(response)),
                catchError(this.handleError.bind(this)
                )
            );
    }

    /**
     *
     * @param idRequerimento
     * @param historicoRt
     */
    postHistoricoAlteracaoRt(idRequerimento: number, historicoRt: HistoricoAlteracaoRt): Observable<HistoricoAlteracaoRt> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/associacao`;
        return this.http.post(url, historicoRt).pipe(catchError(this.handleError.bind(this)));
    }


    /**
     *
     * @param idRequerimento
     */
    listHistoricoAlteracaoRt(idRequerimento: number): Observable<HistoricoAlteracaoRt[]> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/associacao`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Associa um ResponsavelTecnico ao requerimento.
     * @param {number} idRequerimento - ID do requerimento.
     * @param {FormData} form - Dados necessários para criação do RT: fileART, fileProcuracao,
     * fileART, sigiloART, numeroART, cpfCnpj.
     */
    postResponsavelTecnico(idRequerimento: number, form: FormData): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos`;
        return this.http.post(url, form).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Atualiza um ResponsavelTecnico associado ao requerimento.
     * @param {number} idRequerimento - ID do requerimento.
     * @param {FormData} form - Dados necessários para criação do RT: fileART, fileProcuracao,
     * fileART, sigiloART, numeroART, cpfCnpj.
     * @param {string} cpfCnpjRT - Cpf/Cnpj do responsável técnico.
     */
    putResponsavelTecnico(idRequerimento: number, cpfCnpjRT: string, form: FormData): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/${cpfCnpjRT}`;
        return this.http.put(url, form).pipe(catchError(this.handleError.bind(this)));
    }

    // TODO - Remover após correção dos arquivos
    putResponsavelTecnicoSemArquivos(idRequerimento: number, cpfCnpjRT: string, form: FormData): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/${cpfCnpjRT}/sem-arquivo`;
        return this.http.put(url, form).pipe(catchError(this.handleError.bind(this)));
    }
    // Fim remover

    putRepresentanteLegalSemArquivos(idRequerimento: number, cpfCnpjRT: string, form: FormData): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/representantes-legais/${cpfCnpjRT}/sem-arquivo`;
        return this.http.put(url, form).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     *  Desassocia um ResponsavelTecnico vinculado ao requerimento
     * @param {number} idRequerimento - ID do requerimento.
     * @param {string} cpfCnpjRT - Cpf/Cnpj do responsável técnico.
     */
    deleteResponsavelTecnico(idRequerimento: number, cpfCnpjRT: string): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/${cpfCnpjRT}`;
        return this.http.delete(url).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Listar responsáveis técnicos associados ao requerimento.
     * @param {number} idRequerimento - ID do requerimento.
     */
    listarResponsaveisTecnicos(
        idRequerimento: number
    ): Observable<ResponsavelTecnico[]> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Lista os documentos associados á um responsável técnico.
     * @param {number} idRequerimento - ID do requerimento.
     * @param {string} cpfCnpjRT - Cpf/Cnpj do responsável técnico.
     */
    listarDocumentosDoResponsavelTecnico(
        idRequerimento: number,
        cpfCnpjRT: string
    ): Observable<DocumentoRT[]> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/${cpfCnpjRT}/documentos`;
        return this.http.get(url).pipe(map(DocumentoRT.fromJsons.bind(this)), catchError(this.handleError.bind(this)));
    }

    downloadDocumentoResponsavelTecnico(
        idRequerimento: number,
        documento: DocumentoRT): Observable<Blob> {
        // tslint:disable-next-line:max-line-length
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/${documento.responsavelTecnico.pessoa.cpfCnpj}/documentos/${EnumTipoDocumentoRT.toJson(documento.tipo)}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    /**
     * Retorna true se o usuário logado for responsável técnico do processo.
     * @param {number} idRequerimento - ID do requerimento.
     * @param {cpf} cpf do usuário logado.
     */
    public isUsuarioLogadoResponsavelTecnicoProcesso(idRequerimento: number, cpf: string): Observable<boolean> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/${cpf}/is-responsavel-tecnico-processo`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Associa um Representante legal ao requerimento.
     * @param {number} idRequerimento - ID do requerimento.
     * @param {FormData} form - Dados necessários para criação do RL: sigiloProcuracao, sigiloOAB, fileOAB, fileProcuracao.
     */
    postRepresentanteLegal(idRequerimento: number, form: FormData): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/representantes-legais`;
        return this.http.post(url, form).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Atualiza um Representante legal associado ao requerimento.
     * @param {number} idRequerimento - ID do requerimento.
     * @param {FormData} form - Dados necessários para criação do RL: sigiloProcuracao, sigiloOAB, fileOAB, fileProcuracao.
     * @param {string} cpfCnpjRT - Cpf/Cnpj do responsável técnico.
     */
    putRepresentanteLegal(idRequerimento: number, cpfCnpjRT: string, form: FormData): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/representantes-legais/${cpfCnpjRT}`;
        return this.http.put(url, form).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     *  Desassocia um Representante legal vinculado ao requerimento
     * @param {number} idRequerimento - ID do requerimento.
     * @param {string} cpfCnpjRT - Cpf/Cnpj do responsável técnico.
     */
    deleteRepresentanteLegal(idRequerimento: number, cpfCnpjRT: string): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/representantes-legais/${cpfCnpjRT}`;
        return this.http.delete(url).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Listar Representante legal associados ao requerimento.
     * @param {number} idRequerimento - ID do requerimento.
     */
    listarRepresentantesLegais(
        idRequerimento: number
    ): Observable<ResponsavelTecnico[]> {
        const url = `${this.urlResource}/${idRequerimento}/representantes-legais`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Lista os documentos associados á um RepresentanteLegal.
     * @param {number} idRequerimento - ID do requerimento.
     * @param {string} cpfCnpjRL - Cpf/Cnpj do representante legal.
     */
    listarDocumentosDoRepresentanteLegal(
        idRequerimento: number,
        cpfCnpjRL: string
    ): Observable<DocumentoRL[]> {
        const url = `${this.urlResource}/${idRequerimento}/representantes-legais/${cpfCnpjRL}/documentos`;
        return this.http.get(url).pipe(map(DocumentoRL.fromJsons.bind(this)), catchError(this.handleError.bind(this)));
    }

    downloadDocumentoRepresentanteLegal(
        idRequerimento: number,
        documento: DocumentoRL): Observable<Blob> {
        // tslint:disable-next-line:max-line-length
        const url = `${this.urlResource}/${idRequerimento}/representantes-legais/${documento.representanteLegal.pessoa.cpfCnpj}/documentos/${EnumTipoDocumentoRL.toJson(documento.tipo)}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    getProprietariosImovel(requerimento: Requerimento): Observable<Pessoa[]> {
        const url = `${this.urlResource}/${requerimento.id}/imoveis/proprietarios`;
        return this.http.get(url).pipe(
            map(json => Pessoa.fromJsons(json)),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Declara o interessado do requerimento
     * @param idRequerimento
     * @param pessoa
     */
    postInteressado(
        idRequerimento: number,
        pessoa: Pessoa,
        idTarefa: string
    ): any {
        const url = `${this.urlResource}/${idRequerimento}/${idTarefa}/interessado`;
        return this.http.post(url, pessoa).pipe(
            map(Requerimento.fromJson.bind(this)),
            catchError(this.handleError.bind(this)));
    }

    /**
     * Atualiza os dados do interessado do requerimento
     * @param idRequerimento
     * @param formData
     */
    putInteressado(
        idRequerimento: number,
        formData: FormData,
        idTarefa: string
    ): Observable<Requerimento> {
        const url = `${this.urlResource}/${idRequerimento}/${idTarefa}/interessado`;
        return this.http.put(url, formData).pipe(map(Requerimento.fromJson.bind(this)), catchError(this.handleError.bind(this)));
    }

    /**
     * Remove o interessado do requerimento
     * @param idRequerimento
     * @param idTarefa
     */
    deleteInteressado(
        idRequerimento: number,
        idTarefa: string
    ): any {
        const url = `${this.urlResource}/${idRequerimento}/${idTarefa}/interessado`;
        return this.http.delete(url).pipe(map(Requerimento.fromJson.bind(this)), catchError(this.handleError.bind(this)));
    }

    /**
     * Lista os documentos associados ao interessado.
     * @param {number} idRequerimento - ID do requerimento.
     */
    listarDocumentosDoencaPessoa(
        idRequerimento: number
    ): Observable<DocumentoDoencaPessoa[]> {
        const url = `${this.urlResource}/${idRequerimento}/interessado/documentos-doenca`;
        return this.http.get(url).pipe(map(DocumentoDoencaPessoa.fromJsons.bind(this)), catchError(this.handleError.bind(this)));
    }

    downloadDocumentoDoencaPessoa(
        idRequerimento: number,
        documento: DocumentoDoencaPessoa): Observable<Blob> {
        const url = `${this.urlResource}/${idRequerimento}/interessado/documentos-doenca/${EnumTipoDocumentoDoencaPessoa.toJson(documento.tipo)}`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    /**
     * Finaliza a tarefa de análise de documentos no Camunda
     * @param {number} idRequerimento - ID do requerimento.
     * @param idTarefa
     * @param hasReprovado
     */
    finalizarConferirDocumentos(idRequerimento: number, idTarefa: string, hasReprovado: boolean, list: ItemTermoReferenciaRequerimentoChecklist[]):
        Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa-analise-documentos/${idRequerimento}`;
        return this.http.post(url, {hasReprovado: hasReprovado, idTarefa: idTarefa, listTermo: list}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    /**
     * Recupera os Requerimentos Titulações
     * @param {Requerimento} requerimento - Requerimento com ID.
     */
    getTitulacoes(requerimento: Requerimento): Observable<RequerimentoTitulacao[]> {
        const url = `${this.urlResource}/${requerimento.id}/titulacoes`;
        return this.http
            .get(url)
            .pipe(
                map(RequerimentoTitulacao.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    /**
     * Recupera o Parecer Técnico do Requerimento
     * @param {Requerimento} requerimento - Requerimento com ID.
     */
    getParecerTecnico(requerimento: Requerimento): Observable<ParecerTecnico> {
        const url = `${this.urlResource}/${requerimento.id}/parecer-tecnico`;
        return this.http
            .get(url)
            .pipe(
                map(ParecerTecnico.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getNotas(requerimentoId: number, httpParams?: HttpParams): Observable<Array<RequerimentoNota>> {
        const url = `${this.urlResource}/${requerimentoId}/notas`;

        return this.http
            .get(url, {params: httpParams})
            .pipe(
                map(RequerimentoNota.fromIterable.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createNota(requerimentoId: number, nota: RequerimentoNota): Observable<RequerimentoNota> {
        const url = `${this.urlResource}/${requerimentoId}/notas`;

        return this.http
            .post(url, nota)
            .pipe(
                map(RequerimentoNota.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    deleteNota(requerimentoId: number, notaId: number): Observable<any> {
        const url = `${this.urlResource}/${requerimentoId}/notas/${notaId}`;

        return this.http
            .delete(url)
            .pipe(
                map(() => null),
                catchError(this.handleError.bind(this))
            );
    }

    ehRequerimentoSubterraneoPJEUrbano(requerimentoId: number): Observable<boolean> {
        const url = `${this.urlResource}/${requerimentoId}/is-requerimento-subterraneo-pj-urbano`;
        return this.http.get(url).pipe(
                map(json => json),
                catchError(this.handleError.bind(this))
            );
    }

    ehRequerimentoSubterraneo(requerimentoId: number): Observable<boolean> {
        const url = `${this.urlResource}/${requerimentoId}/is-requerimento-subterraneo`;
        return this.http.get(url).pipe(
            map(json => json),
            catchError(this.handleError.bind(this))
        );
    }

    cancelarRequerimento(idTarefa: string, idRequerimento: number): Observable<any> {
        if (idTarefa === undefined) {
            idTarefa = 'empty';
        }
        const url = `${this.urlResource}/${idRequerimento}/${idTarefa}/cancelar-processo`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
    }

    finalizarProcesso(idTarefa: string, idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/${idRequerimento}/${idTarefa}/finalizar-processo`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
    }

    atualizaStatusRequerimento(idRequerimento: number, statu: string): Observable<any> {
        const url = `${this.urlResource}/atualizar-requerimento/${idRequerimento}/${statu}`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
    }

    createWithTaskReturn(data: any): Observable<RequerimentoWrapper> {
        const url = `${this.urlResource}/create`;

        return this.http
            .post(url, data)
            .pipe(map(RequerimentoWrapper.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createComTipoRequerimento(tipoRequerimento: TipoRequerimento, tipoProcesso: TipoProcesso, numeroProtocolo: number): Observable<RequerimentoWrapper> {
        const url = `${this.urlResource}/${tipoRequerimento.id}/${tipoProcesso.tipo}/processo/${numeroProtocolo}`;

        return this.http.post(url, null)
            .pipe(map(RequerimentoWrapper.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    statusTermo(idRequerimento: number, status: boolean): any {
        const url = `${this.urlResource}/${status}/status-termo/${idRequerimento}/requerimento`;
        return this.http.put(url, status).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    /**
     * Concluir tarefa de Registrar comprovante de Publicação Oficial
     * @param despachoDto
     */
    concluirTarefaRegistrarPublicacao(despachoDto: DespachoDto):
        Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa-registrar-publicacao`;
        return this.http.post(url, despachoDto).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    /**
     * Concluir tarefa de Digitalizar processo legado
     * @param requerimentoId
     * @param tarefaId
     * @param comentario
     */
    concluirTarefaDigitalizarProcesso(requerimentoId: number, tarefaId: string, comentario: string):
        Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa-digitalizar-processo-legado`;
        return this.http.post(url, {requerimentoId: requerimentoId, tarefaId: tarefaId, comentario: comentario}).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    /**
     * Listar o histórico de eventos do requerimento.
     * @param {number} idRequerimento - ID do requerimento.
     */
    listarHistorico(
        idRequerimento: number
    ): Observable<HistoricoRequerimento[]> {
        const url = `${this.urlResource}/${idRequerimento}/historicos`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    buscaHistoricoPorEventoIdReq(idRequerimento: number, status: HistoricoRequerimentoEnum): Observable<HistoricoRequerimento> {
        const url = `${this.urlResource}/historico-req/${idRequerimento}/${status}`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    pagarDar(requerimento: Requerimento): Observable<any> {
        const url = `${this.urlResource}/${requerimento.id}/pagar-dar`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    downloadDocumentosRequerimentoLicenciamento(idRequerimento: number): Observable<Blob> {
        return this.http
            .post(`${this.urlResource}/download-documentos-processo-req-licenca`, idRequerimento, {responseType: 'blob'}).pipe(map((res) => {
                return new Blob([res], {type: res.type});
            }));
    }

    /**
     * Consulta um requerimento pelo número do processo
     * @param numeroProcesso
     */
    getRequerimento(numeroProcesso: string): any {
        const url = `${this.urlResource}/numero-processo`;
        let urlParams: HttpParams = new HttpParams();
        if (numeroProcesso) {
            urlParams = urlParams.append('numeroProcesso', numeroProcesso);
        }
        return this.http.get(url, {params: urlParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    readPdf(requerimento: Requerimento): Observable<any> {
        return this.http.get(`${this.urlResource}/${requerimento.id}/pdf`, {responseType: 'blob'}).pipe(
            map((res) => {
                    return new Blob([res], {type: 'application/pdf'});
                }
            ));
    }

    testDeEnvioEmail(envioEmailTest: any):
        Observable<string> {
        const url = `${this.urlResource}/enviar-email-test`;
        return this.http.post(url, envioEmailTest).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    assinarDocumento(requerimento: Requerimento, senha: string, idTarefa: string, emissaoTituloignatario?: any, signedDocument?: Blob, telaEmissao?: string, idDepartamentoResponsavel?: number): Observable<any> {
        const url = `${this.urlResource}/${requerimento.id}/assinar`;
        return this.http.post(url, btoa(JSON.stringify({idTarefa: idTarefa, senha: senha, idDepartamentoResponsavel: idDepartamentoResponsavel}))).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscarAssinantesPorRequerimento(requerimento: Requerimento): Observable<AssinanteRequerimentoDto[]> {
        const url = `${this.urlResource}/${requerimento.id}/assinantes`;
        return this.http.get(url).pipe(
            map(json => AssinanteRequerimentoDto.fromJsons(json)),
            catchError(this.handleError)
        );
    }

    /**
     * Recupera o Requerimento pelo seu identificador
     * @param {idRequerimento} idRequerimento.
     */
     findById(idRequerimento: number): Observable<Requerimento> {
        const url = `${this.urlResource}/${idRequerimento}/requerimento`;
        return this.http
            .get(url).pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    /**
     * Consulta um requerimento pelo número
     */
    buscarRequerimentoPorNumero(numero: string, tipo: string): any {
        const url = `${this.urlResource}/buscar-requerimento-por-numero`;
        let urlParams: HttpParams = new HttpParams();
        if (numero) {
            urlParams = urlParams.append('numero', numero);
            urlParams = urlParams.append('tipo', tipo);
        }
        return this.http.get(url, {params: urlParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Consulta um requerimento pelo seu interessado
     */
    buscarRequerimentoPorInteressado(cpfCnpj: string, nomeInteressado: string): Observable<Requerimento[]> {
        const url = `${this.urlResource}/buscar-requerimento-por-interessado`;
        let urlParams: HttpParams = new HttpParams();

        urlParams = urlParams.append('cpfCnpj', cpfCnpj ? cpfCnpj : "");
        urlParams = urlParams.append('nomeInteressado', nomeInteressado ? nomeInteressado : "");
        return this.http.get(url, {params: urlParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Listar responsáveis técnicos associados ao requerimento, sem validacao.
     * @param {number} idRequerimento - ID do requerimento.
     */
    listarResponsaveisTecnicosSemValidacao(idRequerimento: number): Observable<ResponsavelTecnico[]> {
        const url = `${this.urlResource}/${idRequerimento}/responsaveis-tecnicos/sem-validate`;
        return this.http.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     * Concluir tarefa verificação de revisão do processo #10064
     * @param despachoDto
     */
    concluirTarefaVerificarRevisaoProcesso(verificaRevisaoProcessoDTO: VerificaRevisaoProcessoDTO, finalizarTarefa: Boolean):
        Observable<string> {
        const url = `${this.urlResource}/concluir-tarefa-verificar-revisao/processo/finalizar-tarefa/${finalizarTarefa}`;
        return this.http.post(url, verificaRevisaoProcessoDTO).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    /**
     * Consulta se um usuario faz parte do processo.
     */
    buscarUsuarioDoProcesso(cpf: string, listaIds: number[]): any {
        let urlParams: HttpParams = new HttpParams();
        urlParams = urlParams.append('listaIds', listaIds.toString());

        const url = `${this.urlResource}/verificar-usuario-processo/${cpf}`;
        return this.http.get(url, {params: urlParams}).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    assinarDocumentoCodigoVerificador(alvoAssinador: any, senha: string, idTarefa?: string){
        return this.http.post('', {});
    }
}