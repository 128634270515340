import {EmissaoTitulo} from './emissao-titulo.model';
import {Requerimento} from '../../pages/requerimentos/requerimento.model';
import {LicencaFiscalizacao} from "../../pages/simlam/licenca-fiscalizacao.model";
import {Titulacao} from "../../pages/titulacoes/titulacao.model";
import {DocumentoProcesso} from './documento-processo.model';

export class RequerimentoTituloVinculado {

    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public emissaoTitulo?: EmissaoTitulo,
        public numeroEmissao?: string,
        public requerimentoVinculado?: Requerimento,
        public tipoVinculoRequerimento?: string,
        public legado?: boolean,
        public titulacao?: Titulacao,
        public vinculado?: boolean,
        public processoSimlam?: LicencaFiscalizacao,
        public situacao?: string,
        public tipo?: string,
        public numeroProtocoloVinculado?: string,
        public idOrigem?: number,
        public documentoProcesso?: DocumentoProcesso,
    ) {
    }

    static fromJson(json: any): RequerimentoTituloVinculado {
        json = json || {};

        return new RequerimentoTituloVinculado(
            json.id,
            Requerimento.fromJson(json.requerimento),
            EmissaoTitulo.fromJson(json.emissaoTitulo),
            json.numeroEmissao,
            Requerimento.fromJson(json.requerimentoVinculado),
            json.tipoVinculoRequerimento,
            json.legado,
            Titulacao.fromJson(json.titulacao),
            json.vinculado,
            LicencaFiscalizacao.fromJson(json.processoSimlam),
            json.situacao,
            json.tipo,
            json.numeroProtocoloVinculado,
            json.idOrigem,
            DocumentoProcesso.fromJson(json.documentoProcesso)
        );
    }

    static fromJsons(json: any): RequerimentoTituloVinculado[] {
        const array: RequerimentoTituloVinculado[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(RequerimentoTituloVinculado.fromJson(item)));
            return array;
        }
    }
}