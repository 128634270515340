import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AreaDeConfinamento} from '../../../../shared/models/area-de-confinamento.model';
import {AreaDeConfinamentoService} from '../../../../shared/services/area-de-confinamento.service';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {
    BUSCAR_MES_ENUM,
    ENUM_MESES_FEICAO_VALUES,
    EnumMesesFeicaoEnum
} from "../../../../shared/enums/EnumMesesFeicao.enum";

@Component({
  selector: 'app-area-de-confinamento',
  templateUrl: './area-de-confinamento.component.html',
  styleUrls: ['./area-de-confinamento.component.scss']
})
export class AreaDeConfinamentoComponent implements OnInit {
    areaConfinamentoForm: FormGroup;
    numeroPiquetes: 20;
    numeroAnimaisConfinados: 20;

    mesInicial: any[] = [
        {value: 'JANEIRO', descricao: 'Janeiro'},
        {value: 'FEVEREIRO', descricao: 'Fevereiro'},
        {value: 'MARCO', descricao: 'Março'},
        {value: 'ABRIL', descricao: 'Abril'},
        {value: 'MAIO', descricao: 'Maio'},
        {value: 'JUNHO', descricao: 'Junho'},
        {value: 'JULHO', descricao: 'Julho'},
        {value: 'AGOSTO', descricao: 'Agosto'},
        {value: 'SETEMBRO', descricao: 'Setembro'},
        {value: 'OUTUBRO', descricao: 'Outubro'},
        {value: 'NOVEMBRO', descricao: 'Novembro'},
        {value: 'DEZEMBRO', descricao: 'Dezembro'},
    ];

    mesFinal: any[] = [
        {value: 'JANEIRO', descricao: 'Janeiro'},
        {value: 'FEVEREIRO', descricao: 'Fevereiro'},
        {value: 'MARCO', descricao: 'Março'},
        {value: 'ABRIL', descricao: 'Abril'},
        {value: 'MAIO', descricao: 'Maio'},
        {value: 'JUNHO', descricao: 'Junho'},
        {value: 'JULHO', descricao: 'Julho'},
        {value: 'AGOSTO', descricao: 'Agosto'},
        {value: 'SETEMBRO', descricao: 'Setembro'},
        {value: 'OUTUBRO', descricao: 'Outubro'},
        {value: 'NOVEMBRO', descricao: 'Novembro'},
        {value: 'DEZEMBRO', descricao: 'Dezembro'},
    ];

    // : TODO Esse Parametro deve chegar atraves do component Pai
    idGeometria = 10;

  constructor(private formBuilder: FormBuilder,
              private areaDeConfinamentoService: AreaDeConfinamentoService,
              private snackBar: SnackBarService) {

  }

  ngOnInit() {
      this.areaConfinamentoBuilder();

  }

  private areaConfinamentoBuilder(): void {
      this.areaConfinamentoForm = this.formBuilder.group({
          id: [null],
          numeroPiquetes: [null, Validators.required],
          numeroAnimaisConfinados: [null, Validators.required],
          mesInicio: [null, Validators.required],
          mesFinal: [null, Validators.required]
      });
  }

   validarInputs(): boolean {
      if (this.areaConfinamentoForm.valid) {
          if (String(this.areaConfinamentoForm.value.numeroPiquetes).includes('-')) {
              return false;
          }

          if (String(this.areaConfinamentoForm.value.numeroAnimaisConfinados).includes('-')) {
              return false;
          }
          return true;
      }
      this.areaConfinamentoForm.markAllAsTouched();
      return false;
   }

   apenasNumeros(event): boolean {
      if (event.key === 'e' || event.key === '.' || event.key === ',' || event.key.includes('-')) {
          return false;
      }
   }

  salvar(): void {
      const validate = this.validarInputs();
      const areaConfinamento = new AreaDeConfinamento();
      if (validate) {
          areaConfinamento.numeroPiquetes = this.areaConfinamentoForm.value.numeroPiquetes;
          areaConfinamento.numeroAnimaisConfinados = this.areaConfinamentoForm.value.numeroAnimaisConfinados;
          areaConfinamento.mesInicio = this.areaConfinamentoForm.value.mesInicio;
          areaConfinamento.mesFinal = this.areaConfinamentoForm.value.mesFinal;
          this.areaDeConfinamentoService.salvarAreaDeConfinamento(areaConfinamento).subscribe(() => {
              this.snackBar.showSuccess('Área de confinamento salva com sucesso.');
              this.areaConfinamentoForm.reset();
          });

      } else {
          this.snackBar.showError('Campo(s) obrigatório(s) não' +
              ' preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
          if (String(this.areaConfinamentoForm.value.numeroPiquetes).includes('-')) {
              this.snackBar.showError('O campo não aceita números negativos.');
          }

          if (String(this.areaConfinamentoForm.value.numeroAnimaisConfinados).includes('-')) {
              this.snackBar.showError('O campo não aceita números negativos.');
          }
      }

  }

}
