import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {IrrigacaoCultura} from "../irrigacao-cultura.model";
import {COMPARATOR_MES, ENUM_MES_VALUES, ENUM_MES_VALUES_ONLY} from "../../../../../../shared/enums/mes.enum";
import {RequerimentoOutorgaFinalidadeService} from "../../../../../../shared/services/requerimento-outorga-finalidade.service";
import {SnackBarService} from "../../../../../../shared/snack-bar/snack-bar.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {MetodoSistemaIrrigacao} from "../metodo-sistema-irrigacao.model";
import {EvapoPrecipMensal} from "./evaporacao-precipitacao-mensal.model";
import {debounceTime, takeUntil} from "rxjs/operators";
import {IrrigacaoCulturaMensal} from "../irrigacao-cultura-mensal.model";
import {Subject} from "rxjs/internal/Subject";
import {FormUtil} from "../../../../../../shared/util/form-util";
import {IrrigacaoDTO} from "../irrigacao-dto.model";

@Component({
    selector: 'app-adicionar-irrigacao-cultura',
    templateUrl: './adicionar-irrigacao-cultura.component.html',
    styleUrls: ['./adicionar-irrigacao-cultura.component.scss']
})
export class AdicionarIrrigacaoCulturaComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;

    form: FormGroup
    formMensal: FormGroup
    meses = ENUM_MES_VALUES;
    arrayFormCulturaMensais: FormGroup[] = [];
    mostrarFieldJustificativa: boolean;
    mostrarFieldFonteDados: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AdicionarIrrigacaoCulturaData,
        private dialogRef: MatDialogRef<AdicionarIrrigacaoCulturaComponent>,
        private formBuilder: FormBuilder,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
        private snackBarService: SnackBarService,
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this._unsubscribeAll = new Subject();
        this.form = this.formBuilder.group({
            culturas: [null, Validators.required],
            metodoSistemaIrrigacao: [null, Validators.required],
            minimaEficienciaIrrigacao: [null],
            eficienciaIrrigacao: [null, Validators.required],
            areaIrrigada: [null, Validators.required],
            justificativa: [null],
            fonteEvapotranspiracao: [null],
        });

        this.formMensal = this.formBuilder.group({
            mes: [null, Validators.required],
            coeficienteCultura: [null, Validators.required],
            coeficienteAjuste: [null, Validators.required],
        });

        this.form.controls.metodoSistemaIrrigacao.valueChanges.subscribe((value: MetodoSistemaIrrigacao) => {
            this.form.controls.minimaEficienciaIrrigacao.setValue(value.minimaEficiencia);
            this.validarEficiencia();
        });

        this.form.controls.eficienciaIrrigacao.valueChanges.pipe(debounceTime(500)).subscribe(() => this.validarEficiencia());
    }

    adicionar() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            if (this.arrayFormCulturaMensais.length > 0) {
                const irrigacaoCultura = IrrigacaoCultura.fromJson(this.form.value);
                irrigacaoCultura.irrigacoesCulturasMensais = this.arrayFormCulturaMensais.map(form => IrrigacaoCulturaMensal.fromJson(form.value));

                this.dialogRef.close(irrigacaoCultura);
            } else {
                this.snackBarService.showAlert('Adicione ao menos uma irrigação mensal')
            }
        }
    }

    /**
     * Se a eficiência informada for menor que a mínima eficiência do método/sistema informado, então obrigatório justificativa.
     */
    validarEficiencia() {
        const eficienciaIrrigacao = this.form.controls.eficienciaIrrigacao.value;
        if (eficienciaIrrigacao) {
            const minimaEficienciaMetodoIrrigacao = this.form.controls.minimaEficienciaIrrigacao.value;
            const control = this.form.controls.justificativa;

            if (eficienciaIrrigacao < minimaEficienciaMetodoIrrigacao) {
                control.setValidators([Validators.required])
                this.mostrarFieldJustificativa = true;
            } else {
                control.setValidators(null)
                control.setValue(null);
                this.mostrarFieldJustificativa = false;
            }
            control.markAsUntouched();
            control.updateValueAndValidity();
        }
    }

    adicionarCulturaMes() {
        this.formMensal.markAllAsTouched();
        if (this.formMensal.valid) {
            const mensal = IrrigacaoCulturaMensal.fromJson(this.formMensal.value)
            if (this.arrayFormCulturaMensais.some(form => form.value.mes == mensal.mes)) {
                this.snackBarService.showAlert(`O mês ${mensal.mes} já está adicionado`);
                return;
            } else if (mensal.mes == 'TODOS') {
                ENUM_MES_VALUES_ONLY.filter(mes => !this.arrayFormCulturaMensais.some(form => mes == form.value.mes)).forEach(mes => {
                    this.arrayFormCulturaMensais.push(this.formBuilder.group({
                        mes: [mes.toString(), Validators.required],
                        coeficienteCultura: [+mensal.coeficienteCultura, [Validators.required]],
                        coeficienteAjuste: [+mensal.coeficienteAjuste, [Validators.required]],
                        evapotranspiracao: [this.data.evapotranspiracao[mes.toLowerCase()], Validators.required],
                        precipitacao: [this.data.precipitacao[mes.toLowerCase()], Validators.required],
                    }));
                })
            } else {
                const formGroup = new FormGroup({
                    mes: new FormControl(mensal.mes, Validators.required),
                    coeficienteCultura: new FormControl(+mensal.coeficienteCultura, [Validators.required]),
                    coeficienteAjuste: new FormControl(+mensal.coeficienteAjuste, [Validators.required]),
                    evapotranspiracao: new FormControl(this.data.evapotranspiracao[mensal.mes.toLowerCase()], Validators.required),
                    precipitacao: new FormControl(this.data.precipitacao[mensal.mes.toLowerCase()], Validators.required),
                });
                formGroup.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => this.validarFontesEvapoPrecip())
                this.arrayFormCulturaMensais.push(formGroup);

            }
            this.arrayFormCulturaMensais = [...this.arrayFormCulturaMensais.sort((a, b) =>
                COMPARATOR_MES(a.get('mes').value, b.get('mes').value))];
            this.formMensal.reset();
        }
    }

    removerCulturaMes(irrigacaoCulturaMensal: FormGroup) {
        this.arrayFormCulturaMensais.splice(this.arrayFormCulturaMensais.indexOf(irrigacaoCulturaMensal), 1);
        this.arrayFormCulturaMensais = [...this.arrayFormCulturaMensais];
    }

    private validarFontesEvapoPrecip() {
        this.mostrarFieldFonteDados = this.arrayFormCulturaMensais.some(form => {
            const mes: string = form.value.mes.toLowerCase();
            const retorno = form.value.precipitacao !== this.data.precipitacao[mes] || form.value.evapotranspiracao !== this.data.evapotranspiracao[mes];
            //FormUtil.baseOnChange(retorno, this.form.controls.fonteEvapotranspiracao, [Validators.required]);
            FormUtil.baseOnChange(retorno, this.form.controls.fonteEvapotranspiracao);
            return retorno;
        })
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

export class AdicionarIrrigacaoCulturaData {
    irrigacao: IrrigacaoDTO;
    metodosSistemasDeIrrigacoes: MetodoSistemaIrrigacao[];
    evapotranspiracao: EvapoPrecipMensal;
    precipitacao: EvapoPrecipMensal;
}
