import {Input, OnInit} from "@angular/core";
import {BaseRelacionamentoService} from "../../services/base-relacionamento.service";
import {BaseRelacionamentoModel} from "../../models/base-relacionamento.model";
import {DominioDto} from "../../models/dominio-dto.model";
import {MatTableDataSource} from "@angular/material/table";
import {SubDominioDto} from "../../models/sub-dominio-dto.model";
import {NgForm} from "@angular/forms";

export abstract class AbstractComponenteRelacionamento<T extends BaseRelacionamentoModel, S extends BaseRelacionamentoService<T>> implements OnInit {

    protected TIPO_OUTRO: string = 'Outro';

    @Input() service: S;
    @Input() isSomenteVisualizacao: boolean;

    novoDado: { dominio: DominioDto, subdominio?: SubDominioDto, vinculo: T };
    linhaEdicao: number;

    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

    displayedColumns: string[];

    constructor() {
        this.refresh();
        this.displayedColumns = this.getDisplayedColumns();
    }

    refresh() {
        this.novoDado = {
            dominio: undefined,
            subdominio: undefined,
            vinculo: {} as any
        }
    }

    get info() {
        return this.service.info;
    }

    abstract getDisplayedColumns(): string[];

    ngOnInit() {
        this.dataSource.data = this.getSelecionadosApresentacaoDataSource();
    }

    excluiLinha(index: number) {
        if (index > -1) {
            let sel = this.info.selecionados[index];
            if (sel && sel.vinculo.id !== undefined) {
                this.info.excluidos.push(sel.vinculo.id);
            }
            this.info.selecionados.splice(index, 1);
            this.dataSource.data = this.getSelecionadosApresentacaoDataSource();
        }
    }

    incluiLinha(insideForm?: NgForm) {
        this.atualizaVinculo();

        if (this.validaLinha()) {
            if (this.linhaEdicao !== undefined) {
                this.info.selecionados.splice(this.linhaEdicao, 1);
                this.linhaEdicao = undefined;
            }
            this.info.selecionados.push(this.novoDado);
            this.refresh();
            this.dataSource.data = this.getSelecionadosApresentacaoDataSource();

            if (insideForm) {
                insideForm.resetForm();
            }
        }
    }

    async editaLinha(item: any, index: number) {
        this.linhaEdicao = index;
        this.novoDado = {...item};
        if (this.service.hasSubDominio) {
            await this.service.getSubDominio(this.novoDado.dominio.id);
        }
    }

    protected validaLinha() {
        return this.novoDado.dominio && this.info.dominio.some(d => d.id === this.novoDado.vinculo.idDominio);
    }

    protected atualizaVinculo() {
        this.novoDado.vinculo.idDominio = this.novoDado.dominio.id;
    }

    isBotaoAdicionarDisabled() {
        return this.isSomenteVisualizacao || !this.novoDado.dominio ||
            (this.novoDado.dominio && this.novoDado.dominio.descricao === this.TIPO_OUTRO && !this.novoDado.vinculo.descricao)
    }

    showDescricao() {
        return this.novoDado.dominio && this.novoDado.dominio.descricao === this.TIPO_OUTRO;
    }

    compareDominio(obj1: DominioDto | SubDominioDto, obj2: DominioDto | SubDominioDto) {
        return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
    }

    protected getSelecionadosApresentacaoDataSource() {
        return this.info.selecionados;
    }

}