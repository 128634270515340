import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import {FeicaoRequerimentoLicenciamentoDto} from "../models/feicao-requerimento-licenciamento-dto.model";
import {
    InconformidadeFeicaoRequerimentoLicenciamento
} from "../models/inconformidade-feicao-requerimento-licenciamento.model";

@Injectable({
    providedIn: 'root'
})
export class AnaliseFeicaoRequerimentoLicenciamentoService extends BaseService<FeicaoRequerimentoLicenciamentoDto> {
    constructor(protected injector: Injector) {
        super(
            '/api/analise-geo-feicoes-requerimento-licenciamento',
            injector,
            FeicaoRequerimentoLicenciamentoDto.prototype,
            FeicaoRequerimentoLicenciamentoDto.fromJson
        );
    }

    aprovarGeometria(idRequerimentoLicenciamento: number, idFeicaoRequerimento: number): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes/${idFeicaoRequerimento}/aprovar`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
    }

    aprovarGeometrias(idRequerimentoLicenciamento: number, idFeicaoEntrada: number): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicao-entrada/${idFeicaoEntrada}/aprovar-todas`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
    }
    reprovarGeometria(idRequerimentoLicenciamento: number, idFeicaoRequerimento: number, inconformidades: InconformidadeFeicaoRequerimentoLicenciamento[]): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes/${idFeicaoRequerimento}/reprovar`;
        return this.http.put(url, inconformidades).pipe(catchError(this.handleError.bind(this)));
    }
    reprovarGeometrias(idRequerimentoLicenciamento: number, idFeicaoEntrada: number, inconformidades: InconformidadeFeicaoRequerimentoLicenciamento[]): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicao-entrada/${idFeicaoEntrada}/reprovar-todas`;
        return this.http.put(url, inconformidades).pipe(catchError(this.handleError.bind(this)));
    }
    reanalisarGeometria(idRequerimentoLicenciamento: number, idFeicaoRequerimento: number): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicoes/${idFeicaoRequerimento}/reanalisar`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
    }
    reanalisarGeometrias(idRequerimentoLicenciamento: number, idFeicaoEntrada: number): Observable<FeicaoRequerimentoLicenciamentoDto> {
        const url = `${this.urlResource}/${idRequerimentoLicenciamento}/feicao-entrada/${idFeicaoEntrada}/reanalisar-todas`;
        return this.http.put(url, null).pipe(catchError(this.handleError.bind(this)));
    }
}
