import {Injectable, Injector} from '@angular/core';
import {BaseService} from "../base.service";
import {ObjetivoConfiguracaoLicenciamento} from "../../models/licenciamento-config-models/objetivo-configuracao-licenciamento.model";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ObjetivoConfiguracaoLicenciamentoService extends BaseService<ObjetivoConfiguracaoLicenciamento> {

    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            ObjetivoConfiguracaoLicenciamento.prototype,
            ObjetivoConfiguracaoLicenciamento.fromJson
        );
        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/objetivoLicenciamento'
    }

    getByConfiguracao(idConfiguracao: number, httpParams?: HttpParams): Observable<ObjetivoConfiguracaoLicenciamento[]> {
        const url = `${this.urlResource}/byConfiguracao/${idConfiguracao}`;

        return this.http.get(url, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }
}
