import {BaseModel} from 'app/main/shared/models/base.model';

export class RequerimentoFinalidadeAtividadeDTO extends BaseModel {
    constructor(
        public idFinalidadeAtividade?: number,
        public idRequerimento?: number,
        public ppd?: string,
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoFinalidadeAtividadeDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoFinalidadeAtividadeDTO(
            json.idFinalidadeAtividade,
            json.idRequerimento,
            json.ppd
        );
    }

    static fromJsons(json: any): RequerimentoFinalidadeAtividadeDTO[] {
        const requerimentoFinalidadesAtividades: RequerimentoFinalidadeAtividadeDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(requerimentoFinalidadeAtividade => {
                requerimentoFinalidadesAtividades.push(this.fromJson(requerimentoFinalidadeAtividade));
            });
            return requerimentoFinalidadesAtividades;
        }
    }
}
