import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';


@Component({
    templateUrl: './confirmar-cancelar-apreciar-parecer-dialog.component.html'
})
export class ConfirmarCancelarApreciarParecerDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarCancelarApreciarParecerDialogComponent>,
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
