import {Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseModel} from "../models/base.model";


export abstract class BaseRequerimentoService<T extends BaseModel> extends BaseService<T> {

    constructor(protected apiPath: string,
                protected injector: Injector,
                protected type: T,
                protected jsonToResourceFn: (json: any) => T) {
        super(
            apiPath,
            injector,
            type,
            jsonToResourceFn
        );
    }

    getByRequerimento(idRequerimento: number): Observable<T> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
