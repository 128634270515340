import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ResponsavelTecnico} from '../../../models/responsavel-tecnico.model';
import {RequerimentoService} from '../../../../pages/requerimentos/requerimento.service';
import {Requerimento} from '../../../../pages/requerimentos/requerimento.model';
import {DocumentoRT} from '../../../models/documento-rt.model';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {EnumTipoDocumentoRT} from '../../../../shared/models/enum-tipo-documento-rt.model';
import {ResponsaveisCorporativoService} from '../../../services/responsaveis-corporativo.service';
import { ResponsavelTecnicoCompletoDto } from 'app/main/shared/models/responsavel-tecnico-completo-dto.model';
import { DocumentoRTDto } from 'app/main/shared/models/documento-rt-dto.model';
import {environment} from '../../../../../../environments/environment';

@Component({
    templateUrl: 'responsavel-dialog.component.html',
    styleUrls: ['responsavel-dialog.component.scss'],
})

export class ResponsavelDialogComponent implements OnInit {
    requerimento = new Requerimento();
    cadastroAtualizadoAposRequerimento: boolean = false;
    responsavelTecnico: ResponsavelTecnico;
    pessoaCorporativoCompleto: ResponsavelTecnicoCompletoDto;
    anexos: DocumentoRT[] = EnumTipoDocumentoRT.listarDocumentos();

    constructor(private dialogRef: MatDialogRef<ResponsavelDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                protected requerimentoService: RequerimentoService,
                private responsaveisCorporativoService: ResponsaveisCorporativoService,
                private snackBarService: SnackBarService) {
    }

    ngOnInit(): void {
        this.anexos = [];
        this.requerimento = this.data.requerimento;
        this.responsavelTecnico = this.data.responsavelTecnico;
        this.pesquisarResponsavelTecnico(this.responsavelTecnico.pessoa.cpfCnpj);
        this.listarDocumentos(this.responsavelTecnico);
    }

    listarDocumentos(responsavelTecnico: ResponsavelTecnico): void {
        this.requerimentoService.listarDocumentosDoResponsavelTecnico(
            this.requerimento.id,
            responsavelTecnico.pessoa.cpfCnpj).subscribe((result) => {
            this.anexos = result;
        }, error => {
            this.snackBarService.showError('Erro ao carregar documentos do responsável técnico.', error);
        });
    }

    pesquisarResponsavelTecnico(cpfCnpj): void {
        this.responsaveisCorporativoService.getRTCompletobyCpfCnpj(cpfCnpj)
            .subscribe((responsavelTecnico) => {
                    this.pessoaCorporativoCompleto = responsavelTecnico

                    let dataAberturaRequerimento = new Date(this.requerimento.dataAbertura);
                    let dataAtualizacaoPessoa = new Date(this.pessoaCorporativoCompleto.pessoa.dataAtualizacao);

                    this.cadastroAtualizadoAposRequerimento = dataAtualizacaoPessoa > dataAberturaRequerimento;

                }, error => {
                    this.snackBarService
                        .showError('Erro ao pesquisar o responsável técnico no corporativo', error);
                }
            );
    }

    public visualizarDocumento(documento: DocumentoRT): void {
        this.requerimentoService.downloadDocumentoResponsavelTecnico(
            this.requerimento.id, documento
        ).subscribe((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    public downloadDocumento(documento: DocumentoRT): void {
        window.open(
            `${this.requerimentoService.urlResource}/${this.requerimento.id}/responsaveis-tecnicos/` +
            `${documento.responsavelTecnico.pessoa.cpfCnpj}/documentos/` +
            `${EnumTipoDocumentoRT.toJson(documento.tipo)}`
        );
    }

    enderecoCompleto(endereco: any): string {
        return `${endereco.tipo.toLowerCase()} - ${endereco.tipoLogradouro.descricao} ${endereco.logradouro}, ${endereco.numero},
            ${endereco.bairro}, ${endereco.municipio.nome} - ${endereco.municipio.uf}`
    }

    telefonesToString(telefones: Array<any>): string {
        let telefonesString: string = '';
        telefones.forEach(telefone => {
            telefonesString += `${telefone.tipo.toLowerCase()} - (${telefone.ddd}) ${telefone.numero}, `;
        });
        return telefonesString;
    }

    emailsToString(emails: Array<any>): string {
        let emailsString: string = '';
        emails.forEach(email => {
            emailsString += `${email.tipo.toLowerCase()} - ${email.email}, `;
        });
        return emailsString;
    }

    isPessoaJuridica(): boolean {
        return this.pessoaCorporativoCompleto.pessoa.tipo && this.pessoaCorporativoCompleto.pessoa.tipo == 'PJ';
    }

    public visualizarDocumentoRt(documento: DocumentoRTDto): void {
        window.open(
            environment.URL_SCORP_API +
            `/api/documentos-responsaveis-tecnicos/${documento.id}/visualizar`
        );
    }

    public downloadDocumentoRt(documento: DocumentoRTDto): void {
        window.open(
            environment.URL_SCORP_API + `/api/documentos-responsaveis-tecnicos/${documento.id}/download`
        );
    }
}
