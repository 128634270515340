import {BaseModel} from 'app/main/shared/models/base.model';

export class TanquePiscicultura extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public tipoTanque?: number,
        public tipoEspecieHibrido?: number,
        public volumeUtil?: number,
        public especiePiscicultura?: number,
        public tipoEspecieEBacia?: number,
    ) {
        super();
    }

    static fromJson(json: any): TanquePiscicultura {
        json = json || {};

        return new TanquePiscicultura(
            json.id,
            json.idGeometria,
            json.tipoTanque,
            json.tipoEspecieHibrido,
            json.volumeUtil,
            json.especiePiscicultura,
            json.tipoEspecieEBacia,
        );

    }
}