import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {DoencaGrave} from '../models/doenca-grave.model';

@Injectable({
    providedIn: 'root'
})
export class DoencaGraveService extends BaseService<DoencaGrave> {

    constructor(injector: Injector) {
        super('/api/doencas-graves', injector, DoencaGrave.prototype, DoencaGrave.fromJson);
    }
}
