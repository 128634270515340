import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {FeicaoTemplate, GrupoFeicaoTemplate} from './feicao-template.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';

@Injectable()
export class FeicaoTemplateListResolver extends ListResolver<FeicaoTemplate> {
    constructor(service: FeicaoTemplateService) {
        super(service);
    }
}

@Injectable()
export class FeicaoTemplateResolver extends DetailResolver<FeicaoTemplate> {
    constructor(
        service: FeicaoTemplateService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FeicaoTemplateService extends BaseService<FeicaoTemplate> {
    constructor(protected injector: Injector) {
        super(
            '/api/feicoes-template',
            injector,
            FeicaoTemplate.prototype,
            FeicaoTemplate.fromJson
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class GrupoFeicaoTemplateService extends BaseService<GrupoFeicaoTemplate> {
    constructor(protected injector: Injector) {
        super(
            '/api/grupos-feicoes-template',
            injector,
            GrupoFeicaoTemplate.prototype,
            GrupoFeicaoTemplate.fromJson
        );
    }
}
