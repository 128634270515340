import {RequerimentoLicenciamento} from '../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model';
import {LancamentoTaxaParamCalculoDto} from './lancamento-taxa-param-calculo.dto';
import {LancamentoTaxaDto} from './lancamento-taxa-dto.model';


export class FormularioReqLicenciamentoEmp {

    constructor(
        public requerimentoLicenciamento?: RequerimentoLicenciamento,
        public lancamentoTaxa?: LancamentoTaxaDto[],
        public lancamentoTaxaParamCalculoList?: LancamentoTaxaParamCalculoDto[],
        public lancamentoTaxaSemParam?: boolean
    ) {
    }

    static fromJson(json: any): FormularioReqLicenciamentoEmp {
        if (json === undefined || json === null) {
            return null;
        }
        return new FormularioReqLicenciamentoEmp(
            RequerimentoLicenciamento.fromJson(json.requerimentoLicenciamento),
            LancamentoTaxaDto.fromJsons(json.lancamentoTaxa),
            LancamentoTaxaParamCalculoDto.fromJsons(json.lancamentoTaxaParamCalculoList),
        );
    }


}
