import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {debounceTime} from 'rxjs/operators';
import { EmissaoTitulo } from 'app/main/shared/models/emissao-titulo.model';
import { EmissaoTituloSignatario } from 'app/main/shared/models/emissao-titulo-signatario.model';
import { Servidor } from 'app/main/shared/models/servidor.model';
import { Signatario } from 'app/main/shared/models/signatario.model';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { EmissaoTituloSignatarioService } from 'app/main/pages/tarefas/shared/service/emissao-titulo-signatario.service';
import { ServidorService } from 'app/main/shared/services/servidor.service';

@Component({
    selector: 'app-lic-amb-ato-administrativo-signatario',
    templateUrl: './ato-administrativo-signatario.component.html',
    styleUrls: ['./ato-administrativo-signatario.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})

export class LicAmbAtoAdministrativoSignatarioComponent implements OnInit {

    @Input() emissaoTitulo: EmissaoTitulo;

    form: FormGroup;
    emissaoTituloSignatarioList: EmissaoTituloSignatario[] = [];
    mask: any;

    servidoresEncontrados: Servidor[];
    nomePesquisaControl: FormControl;
    verificaSigntario = true;
    nomeTarefa: any;
    emissaoTituloSignatario: EmissaoTituloSignatario = new EmissaoTituloSignatario();
    signatarioAdicionado = false;
    displayedColumns: string[] = ['nome', 'setor', 'cargo', 'acao'];
    novoSignatario = new Signatario();

    constructor(
        private snackBarService: SnackBarService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private service: EmissaoTituloSignatarioService,
        private servidorService: ServidorService,
    ) {
    }

    ngOnInit(): void {
        this.createForm();
        this.carregarSignatariosDoAto();
    }

    private createForm(): void {
        this.form = this.formBuilder.group({
            emissaoTitulo: this.formBuilder.group({
                id: [null, [Validators.required]],
                requerimentoTitulacao: [null, [Validators.required]],
                tipoEmissao: [null, [Validators.required]],
                descricao: [null, [Validators.required, Validators.maxLength(4000)]],
            }),
            signatario: this.formBuilder.group({
                nome: [null, [Validators.required]],
                setor: [null, [Validators.required]],
                formacao: [null, [Validators.required]],
                cpf: [null, [Validators.required]]
            }),
            situacao: 'AGUARDANDO',
            comentarioDecisao: [null, [Validators.maxLength(4000)]]
        });

        this.form.patchValue({emissaoTitulo: this.emissaoTitulo});

        this.nomePesquisaControl = new FormControl('');
        this.nomePesquisaControl.valueChanges.pipe(debounceTime(500)).subscribe(value => this.pesquisaSignatario(value));
    }

    async pesquisaSignatario(nomeSignatario: string) {
        if (nomeSignatario.trim().length === 0) {
            this.servidoresEncontrados = [];
            return;
        }

        this.servidoresEncontrados = await this.servidorService.getByServidoresScorp(nomeSignatario).toPromise();
    }

    async adicionarSignatarioEscolhido(servidor?: Servidor) {
        this.novoSignatario = new Signatario();
        this.novoSignatario.cpf = servidor.cpf;

        this.form.patchValue({
            signatario: this.novoSignatario
        });
    }

    adicionarSignatario(): void {
        if (this.emissaoTituloSignatario.emissaoTitulo) {
            this.form.patchValue({
                emissaoTitulo: this.emissaoTituloSignatario.emissaoTitulo
            });
            this.criarEmissaoTituloSignatario();
        } else {
            this.criarEmissaoTituloSignatario();
        }
    }

    criarEmissaoTituloSignatario(): void {
        this.signatarioAdicionado = false;
        this.emissaoTituloSignatarioList.forEach(signatario => {
            if (signatario.signatario.cpf === this.form.value.signatario.cpf) {
                this.snackBarService.showAlert('Signatário já foi adicionado');
                this.signatarioAdicionado = true;
            }
        });
        if (!this.signatarioAdicionado && this.novoSignatario.cpf) {
            this.service.create(this.form.value).subscribe(emissaoTituloSignatario => {
                this.emissaoTituloSignatario = emissaoTituloSignatario;
                this.nomePesquisaControl.setValue('');
                this.snackBarService.showSuccess('Signatário adicionado!');
                this.novoSignatario = new Signatario();
                this.carregarSignatariosDoAto();
            }, (e) => {
                this.snackBarService.showError('Erro ao adicionar Signatário!', e);
            });
        } else {
            if (!this.novoSignatario.cpf) {
                this.snackBarService.showAlert('Selecione um signatário');
            }
        }
    }

    carregarSignatariosDoAto(): void {
        this.service.getBySignatariosPorIdEmissao(this.emissaoTitulo.id).subscribe(emissaoTituloSignatario => {
            this.emissaoTituloSignatarioList = emissaoTituloSignatario;
        });
    }

    removerSignatario(signatario: Signatario, index): void {
        this.service.delete(signatario.id).subscribe(() => {
            this.carregarSignatariosDoAto();
            this.snackBarService.showSuccess('Signatário removido!');
        }, () => this.snackBarService.showError('Erro ao remover o Signatário!'));

    }

    cpfCnpjMask(cpfOrCnpj: any): void {
        const cnpjCpf = cpfOrCnpj.replace(/\D/g, '');

        if (cnpjCpf.length === 11) {
            this.mask = cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3-\$4');
        } else {
            this.mask = cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3/\$4-\$5');
        }

    }
}

