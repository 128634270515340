import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ServicoCombustivelService} from "../../../../../../../shared/services/servico-combustivel.service";
import {ServicoCombustivel} from "../../../../../../../shared/models/servico-combustivel.model";
import {Tanque} from "../../../../../../../shared/models/tanque.model";
import {TanqueService} from "../../../../../../../shared/services/tanque.service";
import {ParqueBombaCombustivelService} from "../../../../../../../shared/services/parque-bomba-combustivel.service";
import {ParqueBombaCombustivel} from "../../../../../../../shared/models/parque-bomba-combustivel.model";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {
    checkboxBooleanRequiredDirective
} from "../../../../../../../shared/directives/checkbox-boolean-required.directive";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";

@Component({
    selector: 'app-aba-servicos-combustivel',
    templateUrl: './servico-combustivel.component.html',
    styleUrls: ['./servico-combustivel.component.scss']
})
export class ServicoCombustivelComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    form: FormGroup = new FormGroup({});

    tanquesExcluidos: number[] = [];
    combustiveisExcluidos: number[] = [];
    isNaoPodeEditar: boolean = false;

    constructor(private formBuilder: FormBuilder,
                private servicoCombustivelService: ServicoCombustivelService,
                private tanqueService: TanqueService,
                private parqueBombaCombustivelService: ParqueBombaCombustivelService,
                private snackBarService: SnackBarService,
                private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private requerimentoService: RequerimentoService,
    ) {
        super();
        this.initFormControl();
    }

    async ngOnInit() {
        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarServicoCombustivel(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarServicoCombustivel(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
                || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
            this.isNaoPodeEditar = true;
            this.form.disable()
        }
    }

    async carregarServicoCombustivel(idRequerimento){
        try {
            let servico = await this.servicoCombustivelService.getByRequerimento(idRequerimento).toPromise();
            this.form.patchValue(servico);
            await this.consulta(servico);
        } catch (ex) {
        }
    }

    async salva() {
        this.form.markAllAsTouched();

        this.valida()

        if (this.form.valid && this.form.get('tanques').value.length && this.form.get('combustiveis').value.length) {

            let req = ServicoCombustivel.fromJson(this.form.value);
            req.requerimento = this.requerimento.id;

            if (req.id) {
                this.atualizar(req)
            } else {
                this.criar(req);
            }
        }
    }

    private criar(req: any) {
        this.servicoCombustivelService.create(req).subscribe(async servico => {
            await this.salvaTanques(servico);
            await this.salvaCombustiveis(servico);
            this.initFormControl();
            this.snackBarService.showSuccess("Formulário salvo com sucesso.")
            this.enviarFormConcluido.emit();
        })

    }


    private atualizar(req: ServicoCombustivel) {
        this.servicoCombustivelService.update(req).subscribe(async servico => {
            await this.salvaTanques(servico);
            await this.salvaCombustiveis(servico);
            this.initFormControl();
            await this.consultaTanques(servico);
            await this.consultaCombustiveis(servico);
            this.snackBarService.showSuccess("Formulário salvo com sucesso.")
            this.enviarFormConcluido.emit();
        });
    }


    private initFormControl() {

        this.form.addControl('id', this.formBuilder.control(null));
        this.form.addControl('tanques', this.formBuilder.control([]));
        this.form.addControl('combustiveis', this.formBuilder.control([]));
        this.form.addControl('comercioVarejista', this.formBuilder.control({
            value: false,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'comercioAtacadista', 'pontoAbastecimento', 'transpRevendedorRetalhista', 'pontoAbastecimentoAereo')]));
        this.form.addControl('comercioAtacadista', this.formBuilder.control({
            value: false,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'comercioVarejista', 'pontoAbastecimento', 'transpRevendedorRetalhista', 'pontoAbastecimentoAereo')]));
        this.form.addControl('pontoAbastecimento', this.formBuilder.control({
            value: false,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'comercioAtacadista', 'comercioVarejista', 'transpRevendedorRetalhista', 'pontoAbastecimentoAereo')]));
        this.form.addControl('transpRevendedorRetalhista', this.formBuilder.control({
            value: false,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'comercioAtacadista', 'pontoAbastecimento', 'comercioVarejista', 'pontoAbastecimentoAereo')]));
        this.form.addControl('pontoAbastecimentoAereo', this.formBuilder.control({
            value: false,
            disabled: this.isModoVisualizacao
        }, [checkboxBooleanRequiredDirective(this.form, '', 'comercioAtacadista', 'pontoAbastecimento', 'transpRevendedorRetalhista', 'comercioVarejista')]));

        this.form.addControl('area', this.formBuilder.group({
            id: [null],
            coberto: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            pisoImpermeavel: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            canaletas: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            plataformaCarga: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            parqueBomba: this.formBuilder.group({
                id: [null],
                quantidadeIlhas: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
                quantidadeUnidadeAbastecimento: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required]],
                quantidadeBicos: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
            })
        }));
    }


    private valida() {
        if (!this.form.valid) {
            this.snackBarService.showError("O formulário Combústivel possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).")
            return;
        }
    }

    private async consulta(servico: ServicoCombustivel) {
        await Promise.all([
            this.consultaCombustiveis(servico),
            this.consultaTanques(servico)
        ]);
    }

    private async consultaTanques(servico: ServicoCombustivel) {
        const tanques = await this.tanqueService.getByServicoCombustivel(servico.id).toPromise();

        this.form.get('tanques').setValue(tanques || []);
    }

    private async consultaCombustiveis(servico: ServicoCombustivel) {
        const combustiveis = await this.parqueBombaCombustivelService.getByParqueBomba(servico.area.parqueBomba.id).toPromise();

        this.form.get('combustiveis').setValue(combustiveis || []);
    }

    private async salvaTanques(servico: ServicoCombustivel) {
        await this.excluiTanques();
        for (let tanque of this.form.get('tanques').value) {
            tanque = Tanque.fromJson(tanque)
            tanque.servico = servico;
            if (tanque.id) {
                await this.tanqueService.update(tanque).toPromise();
            } else {
                await this.tanqueService.create(tanque).toPromise();
            }
        }
    }

    private async salvaCombustiveis(servico: ServicoCombustivel) {
        await this.excluiCombustiveis();
        for (let combustivel of this.form.get('combustiveis').value) {
            combustivel = ParqueBombaCombustivel.fromJson(combustivel);
            combustivel.parqueBomba = servico.area.parqueBomba;
            if (combustivel.id) {
                await this.parqueBombaCombustivelService.update(combustivel).toPromise();
            } else {
                await this.parqueBombaCombustivelService.create(combustivel).toPromise();
            }
        }

    }

    private async excluiTanques() {
        for (let i = 0, len = this.tanquesExcluidos.length; i < len; i++) {
            let id = this.tanquesExcluidos[i];
            if (id) {
                await this.tanqueService.delete(id).toPromise();
            }
        }
        this.tanquesExcluidos = [];
    }

    private async excluiCombustiveis() {
        for (let i = 0, len = this.combustiveisExcluidos.length; i < len; i++) {
            let id = this.combustiveisExcluidos[i];
            if (id) {
                await this.parqueBombaCombustivelService.delete(id).toPromise();
            }
        }
        this.combustiveisExcluidos = [];
    }
}
