import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {
    HistoricoConferenciaChecklist,
    ItemTermoReferenciaRequerimentoChecklist
} from '../../../shared/models/item-termo-referencia-requerimento-checklist.model';

@Component({
    selector: 'item-termo-referencia-historico-checklist-dialog',
    templateUrl: './item-termo-referencia-historico-checklist-dialog.component.html'
})
export class ItemTermoReferenciaHistoricoChecklistDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<ItemTermoReferenciaHistoricoChecklistDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: HistoricoConferenciaChecklist
    ) {
    }

    situacao(status: string): string {
        return ItemTermoReferenciaRequerimentoChecklist.statusAnalises.find(e => e.valor === status).descricao;
    }

    onClose(): void {
        this.dialogRef.close(false);
    }
}
