import {BaseModel} from "../base.model";

export class AtividadeConfiguracaoLicenciamento extends BaseModel {
    constructor(
        public id?: number,
        public atividade?: number,
        public configuracaoLicenciamento?: number,
        public cadastro?: Date
    ) {
        super();
    }

    static fromJson(json: any): AtividadeConfiguracaoLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new AtividadeConfiguracaoLicenciamento(
            json.id,
            json.atividade,
            json.configuracaoLicenciamento,
            json.cadastro
        );
    }
}
