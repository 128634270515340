import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {BaseService} from "../base.service";
import {environment} from "../../../../../environments/environment";
import {TituloParametrizacaoObjetivo} from "../../models/licenciamento-config-models/titulo-parametrizacao-objetivo.model";


@Injectable({
    providedIn: 'root'
})
export class TituloParametrizacaoObjetivoService extends BaseService<TituloParametrizacaoObjetivo> {

    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            TituloParametrizacaoObjetivo.prototype,
            TituloParametrizacaoObjetivo.fromJson
        );
        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/titulo-parametro-objetivo'
    }

    getByParametrizacaoObjetivo(idParametrizacaoObjetivo: number, httpParams?: HttpParams): Observable<TituloParametrizacaoObjetivo[]> {
        const url = `${this.urlResource}/parametro-objetivo/${idParametrizacaoObjetivo}`;

        return this.http.get(url, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

}