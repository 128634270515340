import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectItemComponent} from './select-item.component';


@NgModule({
  declarations: [ SelectItemComponent],
  exports: [SelectItemComponent],
  imports: [
    CommonModule,
  ],
})
export class SelectItemModule {
}
