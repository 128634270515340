import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from '../../../../shared/enums/base.enum';
import {ServidorLicenciamento} from './servidor-licenciamento.model';
import {AnaliseRequerimentoEquipe} from './analise-requerimento-equipe.model';

export class AnaliseRequerimentoAnalista extends BaseModel {
    constructor(
        public id?: number,
        public dataInclusao?: Date,
        public dataAutorizacao?: Date,
        public dataConclusao?: Date,
        public dataSituacao?: Date,
        public dataDesligamento?: Date,
        public situacao?: string,
        public percentualConcluido?: number,
        public visualizacaoCompartilhada?: boolean,
        public chefe?: boolean,
        public servidor?: ServidorLicenciamento,
        public setor?: string,
        public motivoRecusa?: string,
        public comentarioRecusa?: string,
        public chaveAnaliseTecnica?: string,
        public comentario?: string,
        public avaliacao?: string,
        public tarefaFinalizada?: boolean,
        public situacaoProcessoRequerimento?: string,
        public equipe?: AnaliseRequerimentoEquipe
    ) {
        super();
    }

    get situacaoAssinatura(): string  {
        if (this.tarefaFinalizada) {
            if (this.avaliacao === 'SIM') {
                return 'Assinado';
            } else {
                return 'Reprovado';
            }
        } else {
            return 'Aguardando';
        }
    }

    static situacoes: BaseEnum[] = [
        {valor: 'APROVADO', descricao: 'Aprovado'},
        {valor: 'PENDENTE', descricao: 'Pendente'},
        {valor: 'REPROVADO', descricao: 'Reprovado'}
    ];

    static motivosRecusa: BaseEnum[] = [
        {valor: 'IMPEDIMENTO_LEGAL', descricao: 'Impedimento Legal'},
        {valor: 'AUSENCIA', descricao: 'Ausência'},
        {valor: 'OUTROS', descricao: 'Outros Motivos'}
    ];

    static situacaoProcessoRequerimento: BaseEnum[] = [
        {valor: 'CONCLUIDO', descricao: 'Concluído'},
        {valor: 'ESPERA', descricao: 'Espera'},
        {valor: 'EM_ANALISE', descricao: 'Em análise'}
    ];

    static fromJson(json: any): AnaliseRequerimentoAnalista {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnaliseRequerimentoAnalista(
            json.id,
            json.dataInclusao,
            json.dataAutorizacao,
            json.dataConclusao,
            json.dataSituacao,
            json.dataDesligamento,
            json.situacao,
            json.percentualConcluido,
            json.visualizacaoCompartilhada,
            json.chefe,
            ServidorLicenciamento.fromJson(json.servidor),
            json.setor,
            json.motivoRecusa,
            json.comentarioRecusa,
            json.chaveAnaliseTecnica,
            json.comentario,
            json.avaliacao,
            json.tarefaFinalizada,
            json.situacaoProcessoRequerimento,
            AnaliseRequerimentoEquipe.fromJson(json.equipe),
        );
    }


    static fromJsons(json: any): AnaliseRequerimentoAnalista[] {
        const resources: AnaliseRequerimentoAnalista[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: AnaliseRequerimentoAnalista) => resources.push(AnaliseRequerimentoAnalista.fromJson(e)));
        }
        return resources;
    }
}
