import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {ResponsavelTecnico} from '../../../../../shared/models/responsavel-tecnico.model';

@Component({
    selector: 'app-dialog-alterar-rt',
    templateUrl: './dialog-alterar-rt.component.html'
})
export class DialogAlterarRtComponent implements OnInit {

    form: FormGroup;
    dataSource: MatTableDataSource<ResponsavelTecnico>;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder
    ) {
        this.dataSource = new MatTableDataSource<ResponsavelTecnico>();
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            responsavelTecnico: [null, [Validators.required]],
            justificativa: [null, [Validators.required, Validators.minLength(1)]],
            habilitado: [null, [Validators.required]]
        });
        this.form.patchValue({
            responsavelTecnico: this.data.responsavelTecnico,
            habilitado: !this.data.responsavelTecnico.habilitado
        });
        this.dataSource.data = [this.data.responsavelTecnico];
    }

}
