import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
    selector: 'app-agua-passivo-supressao-geral',
    templateUrl: './agua-passivo-supressao-geral.component.html',
    styleUrls: ['./agua-passivo-supressao-geral.component.scss']
})
export class AguaPassivoSupressaoGeralComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;
    @Input() requerimento: Requerimento;

    constructor() {
    }

    ngOnInit() {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

}
