import {Component, Input} from '@angular/core';
import {FormControlName, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-residenciais-comerciais',
    templateUrl: './residenciais-comerciais.component.html',
    styleUrls: ['./residenciais-comerciais.component.scss']
})
export class ResidenciaisComerciaisComponent {
    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;

    limitInput(input: HTMLInputElement, limite: number) {
        if (String(input.value).length > limite) {
            input.value = String(input.value).substring(0, limite);
        }
    }

    isResidencial() {
        this.form.get('residenciaisComerciais').get('residencial').value === true ?
            this.form.get('residenciaisComerciais').get('numeroUnidadesResidenciais').enable()
            : this.form.get('residenciaisComerciais').get('numeroUnidadesResidenciais').disable();
        return this.form.get('residenciaisComerciais').get('residencial').value;
    }

    isComercial() {
        this.form.get('residenciaisComerciais').get('comercial').value === true ?
            this.form.get('residenciaisComerciais').get('numeroUnidadesComerciais').enable()
            : this.form.get('residenciaisComerciais').get('numeroUnidadesComerciais').disable();
        return this.form.get('residenciaisComerciais').get('comercial').value;
    }

    isIndustrial() {
        this.form.get('residenciaisComerciais').get('industrial').value === true ?
            this.form.get('residenciaisComerciais').get('numeroUnidadesIndustriais').enable()
            : this.form.get('residenciaisComerciais').get('numeroUnidadesIndustriais').disable();
        return this.form.get('residenciaisComerciais').get('industrial').value;
    }
}
