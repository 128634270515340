import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ArquivoService} from '../../../shared/services/arquivo.service';
import {PdfViewerDialog} from '../../../shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import {SnackBarService} from '../../../shared/snack-bar/snack-bar.service';
import {ParecerTecnicoService} from '../parecer-tecnico.service';
import {ApreciarParecerTecnicoService} from '../../../shared/services/apreciar-parecer-tecnico.service';
import {VisualizarComentarioDialogComponent} from './visualizar-comentario-dialog/visualizar-comentario-dialog.component';
import {Despacho} from '../../../shared/components/despacho-cabecalho/despacho.model';
import {ApreciarParecerTecnico} from '../../../shared/models/apreciar-parecer-tecnico.model';
import {RequerimentoService} from '../../requerimentos/requerimento.service';
import {ConfirmarCancelarApreciarParecerDialogComponent} from './confirmar-cancelar-apreciar-parecer-dialog/confirmar-cancelar-apreciar-parecer-dialog.component';
import {RedirectUtil} from '../../../shared/util/RedirectUtil';


@Component({
    selector: 'app-apreciar-parecer-tecnico',
    templateUrl: './apreciar-parecer-tecnico.component.html',
    styleUrls: ['./apreciar-parecer-tecnico.component.scss']
})
export class ApreciarParecerTecnicoComponent implements OnInit {

    // @Input() idTarefa: string;
    // @Input() requerimento = new Requerimento();

    // : TODO Remover depois dos testes
    idTarefa = 'dsadsaewqeqw';
    requerimento: any;
    //
    statusComentario = false;
    formDespacho: FormGroup;
    formDecisaoParecerTecnico: FormGroup;
    dataSourceAnalistasManifestantes: any;
    parecerTecnico: any;

    constructor(private dialog: MatDialog,
                private formBuilder: FormBuilder,
                private arquivoService: ArquivoService,
                private pdfViewerDialog: PdfViewerDialog,
                private snackBarService: SnackBarService,
                private parecerTecnicoService: ParecerTecnicoService,
                private apreciarParecerTecnicoService: ApreciarParecerTecnicoService,
                private requerimentoService: RequerimentoService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.buildForm();
        this.requerimento = await this.requerimentoService.getById(11945).toPromise();
        if (this.requerimento) {
            console.log('this.requerimento', this.requerimento);
            this.buscaAnalistasManifestantes();
            this.buscaParecerTecnico();
        }
    }

    buildForm(): void {
        this.formDecisaoParecerTecnico = this.formBuilder.group({
            id: [null],
            decisao: ['', [Validators.required]],
            comentario: ['', [Validators.required]],
        });
        this.formDecisaoParecerTecnico.controls.comentario.disable();

        this.formDespacho = this.formBuilder.group({
            textoDespacho: ['', [Validators.required]],
        });
    }

    exibirComentario(event: any): void {
        if (event.value === 'NAO_ENCAMINHAR_AJUSTE_PARECER_TECNICO' || event.value === 'NAO_ENCAMINHAR_REANALISE') {
            this.statusComentario = true;
            this.formDecisaoParecerTecnico.controls.comentario.enable();
        } else {
            this.statusComentario = false;
            this.formDecisaoParecerTecnico.controls.comentario.disable();
        }
    }

    visualizarDocumento(): void {
        this.arquivoService.download(this.parecerTecnico.documentoProcesso.chave).subscribe(blob => this.pdfViewerDialog.show(blob),
            e => this.snackBarService.showError('Erro ao visualizar o documento', e));
    }

    visualizarComentarioSignatario(data: any): void {
        this.dialog.open(VisualizarComentarioDialogComponent, {
                width: '700px',
                data: data
            }
        );
    }

    verificarEncaminhamento(data: any): string {
        if (data.encaminhamentoSugerido === 'Sim') {
            return 'Assinado';
        } else {
            return 'Desacordo';
        }
    }

    tratarStringEncaminhamento(encaminhamento: string): string {
        switch (encaminhamento) {
            case 'Sim':
                return '-';
            case 'Não, sugiro que sejam realizados ajustes no parecer técnico':
                return 'Revisão';
            case 'Não, sugiro reanálise':
                return 'Reanalise';
        }
    }

    buscaAnalistasManifestantes(): void {
        this.apreciarParecerTecnicoService.buscaAnalistaReqAnalista(this.requerimento.id).subscribe(result => {
            this.dataSourceAnalistasManifestantes = result;
        });
    }

    retornaDespacho(): any {
        const despacho = new Despacho();
        despacho.descricao = this.formDespacho.get('textoDespacho').value;
        return despacho;
    }

    async retornaApreciarParecerTecnico(formDecisao: any): Promise<any> {
        const apreciarParecerTecnico = new ApreciarParecerTecnico();
        apreciarParecerTecnico.requerimento = this.requerimento;
        apreciarParecerTecnico.despacho = this.retornaDespacho();
        apreciarParecerTecnico.servidor = null;
        apreciarParecerTecnico.decisao = formDecisao.decisao;
        apreciarParecerTecnico.comentario = formDecisao.comentario ? formDecisao.comentario : null;
        apreciarParecerTecnico.dataConclusao = null;
        return apreciarParecerTecnico;
    }

    async concluirTarefa(): Promise<void> {
        if (this.formDecisaoParecerTecnico.valid && this.formDespacho.valid) {
            await this.apreciarParecerTecnicoService.salvarApreciarParecerTecnico(await this.retornaApreciarParecerTecnico(this.formDecisaoParecerTecnico.value),
                this.idTarefa).toPromise()
                .then(() => {
                    this.snackBarService.showSuccess('Apreciar Parecer Tecnico registrado com sucesso !!');
                });
        } else {
            this.formDecisaoParecerTecnico.markAllAsTouched();
            this.formDespacho.markAllAsTouched();
            this.snackBarService.showError('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)');
        }
    }

    cancelar(): void {
        const dialogRef = this.dialog.open(ConfirmarCancelarApreciarParecerDialogComponent, {
            width: '300px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                RedirectUtil.redirecionaDashboardCamunda();
            }
        });

    }

    buscaParecerTecnico(): void {
        this.requerimentoService.getParecerTecnico(this.requerimento).subscribe(result => {
            this.parecerTecnico = result;
        });
    }
}
