import { Injectable, Injector } from '@angular/core';
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/internal/operators";
import { ObjetivoLicenciamento } from "../models/objetivo-licenciamento.model";
import { BaseService } from "./base.service";
import { ObjetivoLicenciamentoTitulacaoDTO } from '../models/objetivo-licenciamento-titulacao.dto';

@Injectable({
    providedIn: 'root'
})
export class ObjetivoLicenciamentoService extends BaseService<ObjetivoLicenciamento> {

    constructor(protected injector: Injector) {
        super(
            '/api/objetivos-licenciamento',
            injector,
            ObjetivoLicenciamento.prototype,
            ObjetivoLicenciamento.fromJson
        );
    }

    buscaListaObjetivosPorIdReqLicen(requerimentoLicenciamentoId: number): Observable<ObjetivoLicenciamento[]> {
        return this.http.get(`${this.urlResource}/requerimento-licenciamento/${requerimentoLicenciamentoId}`).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    buscaListaObjetivosExtraPorIdReqLicen(requerimentoLicenciamentoId: number): Observable<ObjetivoLicenciamento[]> {
        return this.http.get(`${this.urlResource}/requerimento-licenciamento/${requerimentoLicenciamentoId}/extra`).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    buscaListaObjetivos(): Observable<ObjetivoLicenciamento[]> {
        return this.http.get(`${this.urlResource}`).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    deletePorIds(requerimentoLicenciamentoId: number, idObjetivoAmbiental: number): Observable<any> {
        return this.http.delete(`${this.urlResource}/requerimento-licenciamento/${requerimentoLicenciamentoId}/objetivo-lic-ambiental/${idObjetivoAmbiental}`).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    findObjetivosLicenciamentoByRequeriementoId(requerimentoId: number): Observable<ObjetivoLicenciamentoTitulacaoDTO[]> {
        return this.http.get(`${this.urlResource}/by-id/${requerimentoId}/requerimento`)
        .pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}
