import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {IndustriaBiocombustivel} from "../../../../../../../shared/models/industria-biocombustivel.model";
import {DominioDto} from "../../../../../../../shared/models/dominio-dto.model";
import {IndustriaBiocombustivelService} from "../../../../../../../shared/services/industria-biocombustivel.service";
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {
    MateriaPrimaIndBiocombustivelService
} from "../../../../../../../shared/services/materia-prima-ind-biocombustivel.service";
import {ProdutoBiodieselService} from "../../../../../../../shared/services/produto-biodiesel.service";
import {ProdutoEtanolService} from "../../../../../../../shared/services/produto-etanol.service";
import {BaseRelacionamentoService} from "../../../../../../../shared/services/base-relacionamento.service";
import {
    MateriaPrimaIndBiocombustivelComponent
} from "./materia-prima-ind-biocombustivel/materia-prima-ind-biocombustivel.component";
import {ProdutoEtanolComponent} from "./produto-etanol/produto-etanol.component";
import {ProdutoBiodieselComponent} from "./produto-biodiesel/produto-biodiesel.component";
import {MateriaPrimaIndBiocombustivel} from "../../../../../../../shared/models/materia-prima-ind-biocombustivel.model";
import {SnackBarService, SnackBarType} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";
import {ObjetivoLicenciamentoSubatividadeService} from "../../../../../../../shared/services/objetivo-licenciamento-subatividade.service";
import {AtividadeArrecadacaoService} from "../../../../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service";
import {AtividadeArrecadacao} from "../../../../../../../shared/models/arrecadacao-models/atividade-arrecadacao.model";
import {RequerimentoLicenciamentoService} from "../../../../requerimento-licenciamento.service";
import {RequerimentoLicenciamento} from "../../../../requerimento-licenciamento.model";

@Component({
    selector: 'app-industria-biocombustivel',
    templateUrl: './industria-biocombustivel.component.html',
    styleUrls: ['./industria-biocombustivel.component.scss']
})
export class IndustriaBiocombustivelComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {
    @ViewChild('MP', {static: false}) materiaPrimaComponent: MateriaPrimaIndBiocombustivelComponent;
    @ViewChild('PE', {static: false}) produtoEtanolComponent: ProdutoEtanolComponent;
    @ViewChild('PB', {static: false}) produtoBiodieselComponent: ProdutoBiodieselComponent;

    private readonly ID_ETANOL: number = 1;
    private readonly ID_BIODIESEL: number = 2;

    entidade: IndustriaBiocombustivel = {} as any;
    form: FormGroup;
    consultasIniciaisFinalizadas: boolean = false;

    isNaoPodeEditar: boolean = false;

    materiasPrima: Array<DominioDto> = []

    objetivoSubAtividade: any;
    atividadeComplementar: AtividadeArrecadacao = new AtividadeArrecadacao();
    requerimentoLicenciamento: RequerimentoLicenciamento;

    constructor(
        protected formBuilder: FormBuilder,
        protected dominiosService: DominiosService,
        protected service: IndustriaBiocombustivelService,
        public materiaPrimaIndBiocombustivelService: MateriaPrimaIndBiocombustivelService,
        public produtoBiodieselService: ProdutoBiodieselService,
        public produtoEtanolService: ProdutoEtanolService,
        private snackBarService: SnackBarService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
        private objetivoLicenciamentoSubatividadeService: ObjetivoLicenciamentoSubatividadeService,
        private arrecadacaoService: AtividadeArrecadacaoService,
        private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
    ) {
        super();
    }

    protected addControlsForm() {
        this.form.addControl('numeroFuncionarios', this.formBuilder.control({
            value: null,
            disabled: this.isModoVisualizacao
        }, [Validators.required]));
    }

    async ngOnInit(): Promise<void> {

        this.carregarAtividadeComplementar()
        this.consultasIniciaisFinalizadas = false;
        this.form = this.formBuilder.group({});
        this.addControlsForm();
        await this.getDominios();
        await this.getEntidadeByRequerimento();

        this.materiasPrima = await this.dominiosService.getDominioByUrl('materia-prima-biocombustivel').toPromise();

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
            this.form.disable();
        }

    }

    async carregarAtividadeComplementar(){
        this.requerimentoLicenciamento =
            await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();

        await this.objetivoLicenciamentoSubatividadeService.getRequerimentoLicenciamentoPorId(this.requerimentoLicenciamento.id).toPromise()
            .then(objetivo => {
                this.objetivoSubAtividade = objetivo;
            })

        if (this.objetivoSubAtividade) {
            await this.arrecadacaoService.getAtividadeId(this.objetivoSubAtividade.idAtividadeComplementar).toPromise()
                .then(atividadeComplementar => {
                    this.atividadeComplementar = atividadeComplementar;
                })
        }
    }

    async carregarIndustriaBiocombustivel(idRequerimento){
        let res = await this.service.getByRequerimento(idRequerimento).toPromise();
        this.entidade = res ? res : {} as any;

        if (res) {
            await this.atualizaFormEntidade();
            await this.getSelecionadosSalvos();
        }else{
            this.consultasIniciaisFinalizadas = true;
        }
    }

    get listaMateriaPrimaEtanol() {
        return this.getListaMateriaPrima(this.ID_ETANOL);
    }

    get listaMateriaPrimaBiodiesel() {
        return this.getListaMateriaPrima(this.ID_BIODIESEL);
    }

    getListaMateriaPrima(idCombustivel) {
        return this.materiaPrimaIndBiocombustivelService.info.selecionados.filter(s => s.vinculo.idDominio === idCombustivel);
    }

    protected get todasServicesRelacionamentos(): Array<BaseRelacionamentoService<any>> {
        return [
            this.materiaPrimaIndBiocombustivelService,
            this.produtoBiodieselService,
            this.produtoEtanolService
        ];
    }

    protected get servicesRelacionamentosAtivos(): Array<BaseRelacionamentoService<any>> {
        let lista: Array<BaseRelacionamentoService<any>> = [
            this.materiaPrimaIndBiocombustivelService,
        ];

        if (this.listaMateriaPrimaEtanol.length > 0) {
            lista.push(this.produtoEtanolService);
        }

        if (this.listaMateriaPrimaBiodiesel.length > 0) {
            lista.push(this.produtoBiodieselService);
        }

        return lista;
    }

    protected async salvaRelacionamentos(): Promise<void> {
        await this.materiaPrimaIndBiocombustivelService.createSelecionados(this.entidade.id);
        await this.createSelecionadosProduto(this.produtoBiodieselService);
        await this.createSelecionadosProduto(this.produtoEtanolService);
    };

    protected preSalva() {
        if (this.materiaPrimaIndBiocombustivelService.info.selecionados.length < 1) {
            this.materiaPrimaComponent.insideForm.form.markAllAsTouched();
            this.snackBarService.show('O formulário de Atividades: possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).', SnackBarType.ERROR);
            return false;
        }
        if (this.listaMateriaPrimaEtanol.length > 0 && this.produtoEtanolService.info.selecionados.length < 1) {
            this.produtoEtanolComponent.insideForm.form.markAllAsTouched();
            this.snackBarService.show('Deve ser informado ao menos um produto para cada matéria prima informada.', SnackBarType.ERROR);
            return false;
        }
        if (this.listaMateriaPrimaBiodiesel.length > 0 && this.produtoBiodieselService.info.selecionados.length < 1) {
            this.produtoBiodieselComponent.insideForm.form.markAllAsTouched();
            this.snackBarService.show('Deve ser informado ao menos um produto para cada matéria prima informada.', SnackBarType.ERROR);
            return false;
        }
        
        if(this.materiaPrimaComponent.novoDado.vinculo.cogeracaoEnergia && this.atividadeComplementar.id != 4097){
            this.snackBarService.show('Deve ser informado na primeira Aba, como atividade complementar a atividade referente a unidade de co-geração de energia elétrica', SnackBarType.ERROR);
            return false;
        }
        return true;
    }

    async createSelecionadosProduto(service: ProdutoBiodieselService | ProdutoEtanolService) {
        await service.deleteExcluidos();
        for (let tipo of service.info.selecionados) {
            let t = tipo.vinculo;
            if (tipo.vinculo.vinculoMateriaPrima) {
                t.idEntidade = tipo.vinculo.vinculoMateriaPrima.vinculo.id;
            }
            if (t.id === undefined) {
                t = await service.create(t).toPromise();
            } else {
                t = await service.update(t).toPromise();
            }
        }
    }

    getDescricaoMateriaPrima(vinculo: MateriaPrimaIndBiocombustivel) {
        let mp = this.materiasPrima.find(mp => mp.id === vinculo.idMateriaPrima);
        return mp ? mp.descricao : '';
    }

    async getSelecionadosSalvos(): Promise<void> {
        this.produtoBiodieselService.info.selecionados = [];
        this.produtoEtanolService.info.selecionados = [];
        await this.materiaPrimaIndBiocombustivelService.getSelecionadosSalvos(this.entidade.id);
        for (let mp of this.materiaPrimaIndBiocombustivelService.info.selecionados) {
            if (mp.vinculo.idDominio === this.ID_ETANOL) {
                await this.produtoEtanolService.getSelecionadosSalvos(mp.vinculo.id, true);
            } else if (mp.vinculo.idDominio === this.ID_BIODIESEL) {
                await this.produtoBiodieselService.getSelecionadosSalvos(mp.vinculo.id, true);
            }
        }
        this.consultasIniciaisFinalizadas = true;
    }

    async getEntidadeByRequerimento() {

        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarIndustriaBiocombustivel(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarIndustriaBiocombustivel(this.requerimento.id)
            }
        }
        if(this.isModoVisualizacao){
            this.form.disable()
        }
    }

    private async atualizaFormEntidade() {
        this.form.patchValue(this.entidade);
    }

    public isFormValido() {
        return this.form.valid;
    }

    public async salva() {
        if(this.preSalva()){
            this.form.markAllAsTouched();

            if (this.isFormValido()) {
                let res = new IndustriaBiocombustivel(this.entidade.id, this.form.get('numeroFuncionarios').value, this.requerimento.id);

                if (this.entidade.id === undefined) {
                    this.service.create(res).subscribe(result => {
                        this.entidade = result;
                        this.enviarFormConcluido.emit();
                        this.posSalva();
                    });
                } else {
                    this.service.update(res).subscribe(result => {
                        this.entidade = result;
                        this.enviarFormConcluido.emit();
                        this.posSalva();
                    });
                }
            }
        }
    }

    protected getValueFromEntidade(parametro: string) {
        let tipo;
        if (this.form) {
            tipo = this.form.get(parametro).value;
        }

        if (!tipo) {
            if (this.entidade) {
                tipo = this.entidade[parametro]
            }
        }

        return tipo;
    }

    protected async getDominios(): Promise<void> {
        for (let serv of this.todasServicesRelacionamentos) {
            await serv.getDominio();
        }
    }

    async posPatchValueForm(): Promise<void> {
        await this.getSelecionadosSalvos();
    }

    async posSalva() {
        await this.salvaRelacionamentos();
    }
}
