export class VazaoContinuaDto {
    constructor(
        public possuiDRDH?: boolean,
        public vazaoCadastradasUsoIns?: number,
        public vazaoOutorgadaSuperficial?: number,
        public vazaoRequerida?: number,
        public vazaoTotal?: number,
        public coordenadasDRDH?: CoordenadaDRDH[],
        public dataAtualizacao?: Date
    ) {
    }

    get totalVazaoContinua(): number {
        let total = 0;
        this.coordenadasDRDH.forEach(item => {
            if (item.vazaoDisponivel) {
                total++;
            }
        });
        return total;
    }

    public gerarStringVazaoContinuaUltrapassadaMeses(): string {
        return this.coordenadasDRDH.filter(item => !item.vazaoDisponivel).map(item => item.emprendimento).join(', ');
    }

    static fromJson(json: any): VazaoContinuaDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new VazaoContinuaDto(
            json.possuiDRDH,
            json.vazaoCadastradasUsoIns,
            json.vazaoOutorgadaSuperficial,
            json.vazaoRequerida,
            json.vazaoTotal,
            CoordenadaDRDH.fromJsons(json.coordenadasDRDH),
            json.dataAtualizacao
        );
    }

    static fromJsons(json: any): VazaoContinuaDto[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(VazaoContinuaDto.fromJson(j)));
            return resources;
        }
    }
}

export class CoordenadaDRDH {
    constructor(
        public idCoordenada?: number,
        public idMicrobacia?: number,
        public emprendimento?: string,
        public ano?: string,
        public vazaoReservada?: number,
        public vazaoDisponivel?: boolean,
        public vazaoCadastradasUsoIns?: number,
        public vazaoOutorgadaSuperficial?: number,
        public vazaoRequerida?: number,
        public vazaoTotal?: number
    ) {
    }

    static fromJson(json: any): CoordenadaDRDH {
        if (json === undefined || json === null) {
            return null;
        }
        return new CoordenadaDRDH(
            json.idCoordenada,
            json.idMicrobacia,
            json.emprendimento,
            json.ano,
            json.vazaoReservada,
            json.vazaoDisponivel,
            json.vazaoCadastradasUsoIns,
            json.vazaoOutorgadaSuperficial,
            json.vazaoRequerida,
            json.vazaoTotal
        );
    }

    static fromJsons(json: any): CoordenadaDRDH[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(CoordenadaDRDH.fromJson(j)));
            return resources;
        }
    }
}