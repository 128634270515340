import {BaseModel} from 'app/main/shared/models/base.model';
import {ItemTermoReferencia} from "../itens-termos-referencia/item-termo-referencia.model";
import {ItensTermosReferenciaModule} from "../itens-termos-referencia/itens-termos-referencia.module";

export class TermoReferencia extends BaseModel {
    constructor(
        public id?: number,
        public numero?: string,
        public titulo?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public eiaRima?: number,
        public idTipoTermoReferencia?: number,
        public prazo?: number,
        public idTipoPeriodo?: number,
        public objetivo?: string,
        public prazoData?: Date,
    ) {
        super();
    }

    static fromJson(json: any): TermoReferencia {
        if (json === undefined || json === null) {
            return null;
        }
        return new TermoReferencia(
            json.id,
            json.numero,
            json.titulo,
            json.dataInicio,
            json.dataFim,
            json.eiaRima,
            json.idTipoTermoReferencia,
            json.prazo,
            json.idTipoPeriodo,
            json.objetivo,
            json.prazoData
        );
    }

    static fromJsons(json: any): TermoReferencia[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((resource: any) =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }
}