import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import { TituloLegadoAnexo } from 'app/main/shared/models/titulo-legado-anexo.model';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TituloLegadoAnexoService extends BaseService<TituloLegadoAnexo> {

  constructor(protected injector: Injector) {
    super('/api/titulos-legado', injector, TituloLegadoAnexo.prototype, TituloLegadoAnexo.fromJson);
  }

  readPdf(tituloLegadoAnexo: TituloLegadoAnexo, forceRefresh: boolean = false): Observable<any> {
    return this.http.get(`${this.urlResource}/${tituloLegadoAnexo.id}/pdf`, {
        responseType: 'blob',
        params: new HttpParams().set('forceRefresh', String(forceRefresh))
    }).pipe(
        map((res) => {
                return new Blob([res], {type: 'application/pdf'});
            }
        ));
  }


  anexarTituloRequerimentoLegado(form: any, requerimento: Requerimento): Observable<TituloLegadoAnexo> {
      const url = `${this.urlResource}/${requerimento.id}`;
      return this.http.post(url, form).pipe(
          map(response => response),
          catchError(this.handleError)
      );
  }

  excluirTitulacaoRequerimentoLegado(idTituloLegadoAnexo: number): Observable<any> {
      const url = `${this.urlResource}/${idTituloLegadoAnexo}`;
      return this.http.delete(url).pipe(
          map(() => null),
          catchError(this.handleError.bind(this))
      );
  }

  readByNumeroAndRequerimentoId(numero: any, requerimentoId: number): Observable<TituloLegadoAnexo[]> {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('numero', numero)
      httpParams = httpParams.append('requerimentoId', requerimentoId.toString())
      const url = `${this.urlResource}/listar-titulos-por-numero-titulo`;
      return this.http.get(url, {params: httpParams}).pipe(
          map(TituloLegadoAnexo.fromJsons.bind(this)),
          catchError(this.handleError)
      );
  }

}
