import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'error-401',
    templateUrl: './401/error-401.component.html',
    styleUrls: ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error401Component {
    constructor() {

    }
}
