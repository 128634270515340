import {BaseModel} from 'app/main/shared/models/base.model';

export class AguaPassivoSupressao extends BaseModel {
    constructor(
        public id?: number,
        public requerimento?: number,
        public superficial?: boolean,
        public subterranea?: boolean,
        public concessionaria?: boolean,
        public outorgaCaptacaoSuperficial?: boolean,
        public usoInsignificanteCaptacaoSuperficial?: boolean,
        public aguaChuva?: boolean,
        public outorgaCaptacaoSubterranea?: boolean,
        public pocosRasos?: boolean,
        public cessaoUsoCaptacaoSubterranea?: boolean,
        public supressaoVegetal?: boolean,
        public passivoAmbiental?: boolean,
        public usoInsignificanteCaptacaoSubterranea?: boolean,
        public cessaoUsoCaptacaoSuperficial?: boolean,
        public amortecimentoEspecial?: boolean
    ) {
        super();
    }

    static fromJson(json: any): AguaPassivoSupressao {
        if (json === undefined || json === null) {
            return null;
        }
        return new AguaPassivoSupressao(
            json.id,
            json.requerimento,
            json.superficial,
            json.subterranea,
            json.concessionaria,
            json.outorgaCaptacaoSuperficial,
            json.usoInsignificanteCaptacaoSuperficial,
            json.aguaChuva,
            json.outorgaCaptacaoSubterranea,
            json.pocosRasos,
            json.cessaoUsoCaptacaoSubterranea,
            json.supressaoVegetal,
            json.passivoAmbiental,
            json.usoInsignificanteCaptacaoSubterranea,
            json.cessaoUsoCaptacaoSuperficial,
            json.amortecimentoEspecial
        );
    }

}
