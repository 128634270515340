import {Injectable, Injector} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BaseCorporativoService} from './base-corporativo.service';
import {ResponsavelTecnico} from '../models/responsavel-tecnico.model';
import {Pessoa} from '../models/pessoa.model';
import { ResponsavelTecnicoCompletoDto } from '../models/responsavel-tecnico-completo-dto.model';
import { PessoaCompletoDto } from '../models/pessoa-completo-dto.model';

@Injectable({
    providedIn: 'root',
})
export class ResponsaveisCorporativoService extends BaseCorporativoService<ResponsavelTecnico> {
    constructor(protected injector: Injector) {
        super(
            '/api',
            injector,
            ResponsavelTecnico.prototype,
            ResponsavelTecnico.fromJson
        );
    }

    /**
     * Retorna o Responsavel técnico com o ID informado.
     * @param {string} id - ID do RT interessado.
     */
    getRtDtobyId(id: number): Observable<ResponsavelTecnico> {
        const url = `${this.urlResourceCorporativo}/responsaveis-tecnicos/${id}/dto-licenciamento`;
        return this.http
            .get(url).pipe(
                map((json: any) => ResponsavelTecnico.fromJson(json)),
                catchError(this.handleError.bind(this))
            );
    }


    /**
     * Retorna o Responsavel técnico com o CPF/CNPJ informado.
     * @param {string} cpfCnpj - CPF/CNPJ do RT interessado.
     */
    getRTbyCpfCnpj(cpfCnpj: string): Observable<ResponsavelTecnico> {
        const url = `${this.urlResourceCorporativo}/responsaveis-tecnicos/cpf-cnpj/${cpfCnpj}`;
        return this.http
            .get(url)
            .pipe(
                map((json: any) => ResponsavelTecnico.fromJson(json)),
                catchError(this.handleError.bind(this))
            );
    }

    /**
     * Retorna o Responsavel técnico completo pelo CPF/CNPJ informado.
     * @param {string} cpfCnpj - CPF/CNPJ do RT interessado.
     */
    getRTCompletobyCpfCnpj(cpfCnpj: string): Observable<ResponsavelTecnicoCompletoDto> {
        const url = `${this.urlResourceCorporativo}/responsaveis-tecnicos/cpf-cnpj/${cpfCnpj}/completo`;
        return this.http
            .get(url)
            .pipe(
                map((json: any) => ResponsavelTecnicoCompletoDto.fromJson(json)),
                catchError(this.handleError.bind(this))
            );
    }

    /**
     * Retorna uma PessoaCompletoDTO pelo CPF/CNPJ informado.
     * @param {string} cpfCnpj - CPF/CNPJ da pessoa.
     */
    getPessoaCompletobyCpfCnpj(cpfCnpj: string): Observable<PessoaCompletoDto> {
        const url = `${this.urlResourceCorporativo}/pessoas/cpf-cnpj/${cpfCnpj}/completo`;
        return this.http
            .get(url)
            .pipe(
                map((json: any) => PessoaCompletoDto.fromJson(json)),
                catchError(this.handleError.bind(this))
            );
    }


    /**
     * Retorna o Responsavel técnico com o CPF/CNPJ informado.
     * @param {string} cpfCnpj - CPF/CNPJ do RT interessado.
     */
    buscarCnpj(cpfCnpj: string): Observable<ResponsavelTecnico[]> {
        const url = `${this.urlResourceCorporativo}/responsaveis-tecnicos/busca-cnpj/${cpfCnpj}`;
        return this.http
            .get(url)
            .pipe(
                map((json: any) => ResponsavelTecnico.fromJsons(json)),
                catchError(this.handleError.bind(this))
            );
    }

    /**
     * Retorna o Representante legal com o CPF informado.
     * @param {string} cnfCnpj - CPF do RT interessado.
     */
    getRLbyCpfCnpj(cpf: string): Observable<Pessoa> {
        const url = `${this.urlResourceCorporativo}/representantes/cpf/${cpf}`;
        return this.http
            .get(url)
            .pipe(
                map((json: any) => Pessoa.fromJson(json)),
                catchError(this.handleError.bind(this))
            );
    }
}
