import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AutorizacaoSupressaoVegetacao } from '../../../../../../../shared/models/autorizacao-supressao-vegetacao.model';
import {
    BaseAutorizacaoSupressaoDeVegetacaoService
} from '../../../../../../../shared/services/base-autorizacao-supressao-de-vegetacao.service';

@Injectable({
    providedIn: 'root'
})

export class SupressaoVegetalService extends BaseAutorizacaoSupressaoDeVegetacaoService<AutorizacaoSupressaoVegetacao>{
    constructor(protected injector: Injector) {
        super(
            '/api/formulario-supressao-de-vegetacao',
            injector,
            AutorizacaoSupressaoVegetacao.prototype,
            AutorizacaoSupressaoVegetacao.fromJson
        );
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResourceLicAutorizacaoSupressaoDeVegetacao}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url, { observe: 'response'}).pipe(
            catchError(this.handleError.bind(this)),
            map(response => response.body)
        );
    }
}