import {BaseModel} from 'app/main/shared/models/base.model';
import {EmpreendimentoSubEstacao} from './empreendimento-subestacao.model';

export class EmpreendimentoMeioIsolamento extends BaseModel {
    constructor(
        public id?: number,
        public meioIsolamento?: number,
        public empreendimentoSubEstacao?: EmpreendimentoSubEstacao,
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoMeioIsolamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoMeioIsolamento(
            json.id,
            json.meioIsolamento,
            json.empreendimentoSubEstacao,
        );
    }

    static fromJsons(json: any): EmpreendimentoMeioIsolamento[] {
        const empreendimentos: EmpreendimentoMeioIsolamento[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(empreendimento => empreendimentos.push(this.fromJson(empreendimento)));
            return empreendimentos;
        }
    }

}
