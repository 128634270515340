import {GeoJSON} from 'ol/format';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {
    Camada,
    ExtraOptions,
    GeometriaMapa,
    geometriaUuid,
    getStyle,
    MapaPadraoComponent,
    PermissaoCamada
} from '@sema-geo/sema-geoportal';
import {
    RequerimentoLicenciamento
} from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model';
import {
    RequerimentoLicenciamentoService
} from 'app/main/pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.service';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {ImovelService} from 'app/main/pages/requerimentos/shared/requerimento-localizacao/shared/imovel.service';
import {FeicaoRequerimentoLicenciamentoDto} from "app/main/shared/models/feicao-requerimento-licenciamento-dto.model";
import {Feicao} from "app/main/shared/models/geoadmin/feicao.model";
import {
    RegrasGeoRequerimentoFeicao
} from "app/main/shared/models/regras-geo-requerimento/regra-geo-requerimento-feicao.model";
import {RegrasGeoRequerimento} from "app/main/shared/models/regras-geo-requerimento/regras-geo-requerimento.model";
import {FeicaoService} from "app/main/shared/services/geoadmin/feicao.service";
import {
    RegrasGeoRequerimentoService
} from "app/main/shared/services/licenciamento-config-service/regras-geo-requerimento.service";
import {ObjetivoLicenciamentoService} from "app/main/shared/services/objetivo-licenciamento.service";
import {SnackBarService, SnackBarType} from "app/main/shared/snack-bar/snack-bar.service";
import {Imovel} from "../../../../requerimentos/shared/requerimento-localizacao/shared/imovel.model";
import {
    RegrasGeoRequerimentoRegra
} from "../../../../../shared/models/regras-geo-requerimento/regra-geo-requerimento-regra.model";
import {CenarioRegrasGeo} from "../../../../../shared/models/geoadmin/cenario-regras-geo";
import {
    AnaliseTopologica,
    FeicaoComparacao,
    RequisicaoAnalisesTopologicas
} from "../../../../../shared/models/geoadmin/analise-topologica";
import {RegraGeo} from "../../../../../shared/models/geoadmin/regra-geo.model";
import {CamadaRegraGeo} from "../../../../../shared/models/geoadmin/camada-regra-geo.model";
import {map} from "rxjs/operators";
import {RegraGeoService} from "../../../../../shared/services/geoadmin/regra-geo.service";
import {ObjetivoLicenciamento} from "../../../../../shared/models/objetivo-licenciamento.model";
import {Estilo} from "@sema-geo/sema-geoportal/lib/mapa-padrao/models/estilo";
import {InformacaoAnm} from "../../../../../shared/models/informacao-anm.model";
import {RequerimentoService} from "../../../../requerimentos/requerimento.service";
import {
    RequerimentoFormularioLicenciamentoService
} from "../../../../requerimentos/requerimento-licenciamento/requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {AtividadeMineracaoService} from "../../../../../shared/services/atividade-mineracao.service";
import {InformacaoAnmService} from "../../../../../shared/services/informacao-anm.service";
import {Feature} from "openlayers";
import {MatDialog} from "@angular/material/dialog";
import {
    DialogReprovarGeometriaComponent
} from "../../requerimento-analise/dialog-reprovar-geometria/dialog-reprovar-geometria.component";
import {
    DialogReanalisarGeometriaComponent
} from "../../requerimento-analise/dialog-reanalisar-geometria/dialog-reanalisar-geometria.component";
import {
    DialogAprovarGeometriaComponent
} from "../../requerimento-analise/dialog-aprovar-geometria/dialog-aprovar-geometria.component";
import {CenarioRegraGeo} from 'app/main/shared/enums/cenario-regra-geo.enum';
import {StatusGeometria} from 'app/main/shared/enums/status-geometria.enum';
import {CamadaFeicao, CamadaObjetivo} from 'app/main/shared/models/geoadmin/feicao-vinculada.interface';
import {
    agruparFeicoesAnalisadasPorObjetivos,
    cleanCamadas,
    getCamada,
    getCamadaByFeicaoEntrada,
    getCamadasByFeicaoEntrada,
    getEstiloFromFeicao,
    getStatusGeometriaClass, getStyleFromFeicaoDTO,
    ordenarFeicoes,
    removerCamada
} from 'app/main/shared/util/geo';
import {
    DialogVisualizarIncoformidadesGeometriaComponent
} from "../../requerimento-analise/dialog-visualizar-incoformidades-geometria/dialog-visualizar-incoformidades-geometria.component";
import {
    DialogAprovarGeometriasComponent
} from "../../requerimento-analise/dialog-aprovar-geometrias/dialog-aprovar-geometrias.component";
import {
    DialogReprovarGeometriasComponent
} from "../../requerimento-analise/dialog-reprovar-geometrias/dialog-reprovar-geometrias.component";
import {
    DialogReanalisarGeometriasComponent
} from "../../requerimento-analise/dialog-reanalisar-geometrias/dialog-reanalisar-geometrias.component";
import {FeicaoTabelaAtributosService} from "../../../../../shared/services/geo/feicao-tabela-atributos.service";
import {HistoricoFeicao} from "../../../../../shared/models/geoadmin/historico-feicao.interface";

@Component({
    selector: 'app-lic-amb-aba-dados-geograficos',
    templateUrl: 'aba-dados-geograficos.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class LicAmbAbaDadosGeograficosComponent {

    @Input()
    podeRemoverGeometrias: boolean = true;

    @Input()
    isSomenteVisualizacao: boolean = false;

    @Input()
    requerimento = new Requerimento();

    @Output()
    arquivoGerado = new EventEmitter<{ blob: Blob, nome: string }>();

    selectedIndex: number = 0;
    selectedIndexRelatorio: number = 0;

    extraOptions: ExtraOptions[];

    referenciasTemporarias: Camada = {
        title: 'Referências temporárias',
        name: 'temporario',
        expandGroup: true,
        permissao: {
            remover: true,
            ponto: true,
            poligono: true,
            linha: true,
            buffer: true,
            upload: true,
            exportar: true
        },
        camadas: []
    };

    localizacao: Camada = {
        title: 'Localização',
        name: 'localizacao',
        geometryGroup: true,
        expandGroup: true,
        camadas: []
    };

    feicoesDadosAnm: Camada = {
        title: 'Dados ANM',
        name: 'anm',
        geometryGroup: true,
        expandGroup: false,
        camadas: []
    };

    historioFeicoes: Camada = {
        title: 'Histórico de Feições',
        name: 'historico-feicoes',
        geometryGroup: true,
        expandGroup: true,
        camadas: [],
    };

    feicoesRequerimento: Camada = {
        title: 'Feições do Requerimento',
        name: 'requerimento',
        geometryGroup: true,
        expandGroup: true,
        camadas: []
    };

    feicoesAnalisadas: Camada = {
        title: 'Feições Analisadas',
        name: 'feicoes-analisadas',
        geometryGroup: true,
        expandGroup: true,
        camadas: [],
    };

    camadaTrabalho: Camada = {
        title: 'Camada de trabalho',
        name: 'trabalho',
        geometryGroup: true,
        expandGroup: true,
        camadas: [this.referenciasTemporarias]
    };

    camadas: Camada[] = [this.camadaTrabalho];
    camadaSelecionada: Camada;
    imoveisDoRequerimento: Imovel[] = [];
    geometrias: FeicaoRequerimentoLicenciamentoDto[] = [];
    analisesTopologicasDasGeometriasRequerimento: {
        geometria: FeicaoRequerimentoLicenciamentoDto,
        aplicacoes: CenarioRegrasGeo[]
    }[] = [];
    regrasFeicoes = new Map<number, RegrasGeoRequerimentoFeicao[]>();

    feicaoesPrincipais: RegrasGeoRequerimentoFeicao[] = [];

    objetivos: ObjetivoLicenciamento[];

    @ViewChild('mapaPadraoComponent', {static: false})
    componenteMapa: MapaPadraoComponent;

    requerimentoLicenciamento: RequerimentoLicenciamento;
    tipo: string;
    showTemplateParecer: boolean;

    constructor(
        private readonly atividadeMineracaoService: AtividadeMineracaoService,
        private readonly informacaoAnmService: InformacaoAnmService,
        private readonly snackBarService: SnackBarService,
        private readonly imovelService: ImovelService,
        private readonly dialog: MatDialog,
        private readonly requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private readonly feicaoService: FeicaoService,
        private readonly requerimentoService: RequerimentoService,
        private readonly requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private readonly regrasGeoRequerimentoService: RegrasGeoRequerimentoService,
        private readonly objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private readonly feicaoTabelaAtributosService: FeicaoTabelaAtributosService,
        private readonly regraGeoService: RegraGeoService
    ) {
        this.extraOptions = [];
        this.showTemplateParecer = false;
    }

    carregamentoCompleto() {
        this.buscarRequerimentoLicenciamento().then(async () => {
            await this.listarGeometrias();

            // @ts-ignore
            this.componenteMapa.urlWMS.value = 'https://geo.anm.gov.br/arcgis/services/SIGMINE/dados_anm/MapServer/WMSServer?request=GetCapabilities&service=WMS';
            this.componenteMapa.onWMS(document.createElement('div'));

            await this.carregaFeicoesAnm();
            if (this.feicoesDadosAnm.camadas.length > 0) {
                this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'anm');
                this.camadaTrabalho.camadas.push(this.feicoesDadosAnm);
                this.camadas = [...this.camadas];
                this.componenteMapa.fit();
            }

            this.getFeicoesAnalisadas();
        });
    }

    private async carregaFeicoesAnm(): Promise<void> {
        if (this.requerimento && this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarMineracao(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarMineracao(this.requerimento.id)
            }
        }
    }

    async carregarMineracao(idRequerimento): Promise<void> {
        let mineracao = await this.atividadeMineracaoService.getByRequerimento(idRequerimento).toPromise().catch(() => null);
        if (mineracao) {
            let informacoes: InformacaoAnm[] = await this.informacaoAnmService.getByMineracao(mineracao.id).toPromise().catch(() => []);
            if (informacoes) {
                for (let informacao of informacoes) {
                    const numero = Number(informacao.numeroProcesso.substring(0, informacao.numeroProcesso.length - 4));
                    const ano = Number(informacao.numeroProcesso.substring(informacao.numeroProcesso.length - 4));

                    let dados = await this.informacaoAnmService.getDadosAnm(numero, ano).toPromise().catch(() => null);
                    if (dados) {
                        let camada = new GeometriaMapa(this.componenteMapa.projection);
                        camada.title = `Processo: ${informacao.numeroProcesso}`;
                        camada.name = `processo-${numero}`;
                        camada.camadas = [];
                        camada.permissao = {
                            ponto: false,
                            linha: false,
                            poligono: false,
                            editar: false,
                            remover: false,
                            upload: false
                        };

                        camada.visualizacao = {
                            showCheckbox: true,
                            showFeature: true
                        };

                        const jsonReader = new GeoJSON();
                        const dadosImportacao: Feature[] = jsonReader.readFeatures(dados, {
                            featureProjection: this.componenteMapa.getMap().getView().getProjection(),
                            dataProjection: 'EPSG:4674'
                        });

                        this.componenteMapa.getSource().addFeatures(dadosImportacao);

                        for (let feature of dadosImportacao) {
                            // @ts-ignore
                            let geometria = this.componenteMapa.criarGeometriaMapa(feature);
                            geometria.title = geometria.propriedades["NOME"] || `Processo: ${informacao.numeroProcesso}`;
                            geometria.visualizacao = {
                                showCheckbox: true,
                                showFeature: true
                            };
                            this.feicoesDadosAnm.camadas.push(geometria);
                        }
                    }
                }
            }
        }

        this.feicoesDadosAnm.camadas = [...this.feicoesDadosAnm.camadas];
    }

    buscarRequerimentoLicenciamento(): Promise<any> {
        return this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id)
            .toPromise()
            .then(async (requerimentoLicenciamento) => {
                return this.requerimentoLicenciamento = requerimentoLicenciamento;
            });
    }

    setRegrasToFeicaoEntrada(regraGeoConfig: RegrasGeoRequerimentoFeicao, camada: Camada) {
        const camadasFeicaoEntrada = getCamadaByFeicaoEntrada(camada, regraGeoConfig.idFeicaoEntrada);
        camadasFeicaoEntrada.forEach((cam: CamadaRegraGeo) => {
            regraGeoConfig.regras.forEach((regra) => {
                if (!cam.regraGeoRequerimentoFeicao.regras.some(regraExistente => regraExistente.id === regra.id)) {
                    cam.regraGeoRequerimentoFeicao.regras.push(regra);
                }
            });
        })
    }

    async listarGeometrias(): Promise<void> {
        const feicoesDuplicadas: RegrasGeoRequerimentoFeicao[] = [];
        let idsFeicoes = [];

        this.feicoesRequerimento.camadas = [];

        this.objetivos = await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();

        for (let objetivo of this.objetivos) {

            let regrasGeoRequerimento: RegrasGeoRequerimento[] = await this.regrasGeoRequerimentoService.getByTipoProcessoAtividadeObjetivo(
                this.requerimentoLicenciamento.requerimento.tipoProcesso.id,
                this.requerimentoLicenciamento.idAtividade,
                objetivo.idObjetivoLicenciamentoAmbiental
            ).toPromise();

            let feicoesConfiguracao: RegrasGeoRequerimentoFeicao[] = [];

            for (let f of regrasGeoRequerimento.map(r => r.feicoes)) {
                feicoesConfiguracao = feicoesConfiguracao.concat(f);
            }

            this.regrasFeicoes.set(objetivo.idObjetivoLicenciamentoAmbiental, feicoesConfiguracao);

            this.feicaoesPrincipais = feicoesConfiguracao.filter(feicao => feicao.principal)

            const camadaObjetivos: Camada = {
                title: objetivo.descricaoObjetivo,
                name: `objetivo-${objetivo.idObjetivoLicenciamentoAmbiental}`,
                geometryGroup: true,
                expandGroup: true,
                camadas: [],
            };

            for (let feicaoConfiguracao of feicoesConfiguracao) {
                // Evita a inclusão duplicada de feições em uma única camada.
                if (idsFeicoes.includes(feicaoConfiguracao.idFeicaoEntrada)) {
                    feicoesDuplicadas.push(feicaoConfiguracao)
                    continue;
                }
                idsFeicoes.push(feicaoConfiguracao.idFeicaoEntrada);
                let feicaoEntrada: Feicao = await this.feicaoService.getById(feicaoConfiguracao.idFeicaoEntrada).toPromise();

                feicaoEntrada.objetivoLicenciamento = objetivo.idObjetivoLicenciamentoAmbiental;

                const estilo: Estilo = getEstiloFromFeicao(feicaoEntrada);
                const feicaoPrincipal = feicaoConfiguracao.principal ? ' * ' : '';

                let camada: CamadaRegraGeo = {
                    title: `${feicaoPrincipal} ${feicaoEntrada.descricao}`,
                    name: `${feicaoConfiguracao.idFeicaoEntrada}-${objetivo.idObjetivoLicenciamentoAmbiental}`,
                    camadas: [],
                    permissao: {
                        ponto: !!feicaoEntrada.geometriaPonto,
                        linha: !!feicaoEntrada.geometriaLinha,
                        poligono: !!feicaoEntrada.geometriaPoligono,
                        editar: false,
                        remover: !this.podeRemoverGeometrias,
                        upload: true
                    },
                    style: estilo ? getStyle(estilo) : this.componenteMapa.defaultStyle
                };
                camada.regraGeoRequerimentoFeicao = feicaoConfiguracao;
                camada.feicao = feicaoEntrada;

                if (!this.isSomenteVisualizacao) {
                    camada.extraOptions = [
                        {
                            icon: 'check_circle_outline',
                            text: 'Aprovar todas Geometria',
                            hint: 'Aprovar todas Geometria',
                            iconeColor: 'green',
                            callback: (camada: Camada) => this.aprovarGeometrias(camada, feicaoConfiguracao.idFeicaoEntrada)
                        },
                        {
                            icon: 'close',
                            text: 'Reprovar todas Geometria',
                            hint: 'Reprovar todas Geometria',
                            iconeColor: 'red',
                            callback: (camada: Camada) => this.raprovarGeometrias(camada, feicaoConfiguracao.idFeicaoEntrada)
                        },
                        {
                            icon: 'refresh',
                            text: 'Reanalisar todas Geometria',
                            hint: 'Reanalisar todas Geometria',
                            iconeColor: 'grey',
                            callback: (camada: Camada) => this.reanalisarGeometrias(camada, feicaoConfiguracao.idFeicaoEntrada)
                        },
                        {
                            icon: 'arrow_downward',
                            text: 'Download do SHP',
                            hint: 'Download do SHP da Feição',
                            callback: () => this.downloadSHP(feicaoEntrada)
                        },
                        {
                            icon: 'arrow_downward',
                            text: 'Download do KML',
                            hint: 'Download do KML da Feição',
                            callback: () => this.downloadKML(feicaoEntrada)
                        }
                    ];
                }
                camadaObjetivos.camadas.push(camada)
            }
            if (feicoesConfiguracao.length > 0) {
                feicoesDuplicadas.forEach(feicao => {
                    this.setRegrasToFeicaoEntrada(feicao, camadaObjetivos);
                })
                setTimeout(() => this.feicoesRequerimento.camadas.push(camadaObjetivos), 100);
            } else {
                this.snackBarService.showAlert(`Não foram encontradas Regras GEO configuradas para o objetivo: ${objetivo.descricaoObjetivo}.`);
            }
        }

        this.geometrias = await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();
        this.imoveisDoRequerimento = await this.imovelService.buscarTodosPorRequerimentoComGeometrias(this.requerimento.id).toPromise();
        this.atualizarMapa();
    }

    downloadSHP(feicao: Feicao) {
        this.feicaoTabelaAtributosService.downloadSHP(this.requerimento.id, feicao.id).subscribe(blob => {
            this.feicaoTabelaAtributosService.showFile(blob, `${feicao.descricao.toUpperCase()}_SHP.zip`);
        }, error => {
            this.snackBarService.showError(`Erro ao baixar o SHP: ${error}.`);

        });
    }

    downloadKML(feicao: Feicao) {
        this.feicaoTabelaAtributosService.downloadKML(this.requerimento.id, feicao.id).subscribe(blob => {
            this.feicaoTabelaAtributosService.showFile(blob, `${feicao.descricao.toUpperCase()}_KML.zip`);
        }, error => {
            this.snackBarService.showError(`Erro ao baixar o KML: ${error}.`);
        });
    }

    inserirFeicoesImoveisDoRequerimento(): void {
        cleanCamadas(this.localizacao, this.componenteMapa);
        if (this.imoveisDoRequerimento.length > 0) {
            this.localizacao.camadas.forEach((camada: Camada) => cleanCamadas(camada, this.componenteMapa));

            this.imoveisDoRequerimento.forEach((imovel) => {
                imovel.geometrias.forEach((geometria) => {
                    const geometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt);
                    geometriaMapa.id = geometria.id;
                    geometriaMapa.name = geometriaUuid(geometria.id);

                    geometriaMapa.permissao = {
                        remover: false
                    };
                    geometriaMapa.extra = geometria;
                    if (imovel.tipo === 'RURAL') {
                        const imovelPrincipal = imovel.imovelPrincipal ? '*' : '';
                        geometriaMapa.title = `${imovel.numeroCAR} ${imovelPrincipal}`;
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            Nome: geometriaMapa.title
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                    } else if (imovel.tipo === 'URBANO') {
                        geometriaMapa.title = 'Área do imóvel urbano';
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            Nome: geometriaMapa.title,
                            imovel: imovel.denominacao
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                        // Só permitir um único polígono para o imóvel urbano.
                        this.localizacao.permissao.poligono = false;
                    } else if (imovel.tipo === 'OBRA') {
                        geometriaMapa.title = geometriaMapa.tipoGeometria;
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            imovel: imovel.denominacao
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                    }
                    geometriaMapa.visualizacao = {
                        showCheckbox: true,
                        showFeature: true
                    }
                    geometriaMapa['expandGroup'] = true
                    geometriaMapa.style = this.componenteMapa.defaultStyle
                    geometriaMapa.feature.setStyle(this.componenteMapa.defaultStyle)
                    this.localizacao.camadas.push(geometriaMapa);
                });
            });

            if (this.imoveisDoRequerimento.some(imovel => imovel.geometrias.some(geom => geom.wkt !== ''))) {
                this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'localizacao');
                this.camadaTrabalho.camadas.push(this.localizacao);
            }

        }
    }

    inserirFeicoesDoRequerimento(): void {
        // Feições do requerimento.
        this.feicoesRequerimento.camadas.forEach((camada: Camada) => {
            camada.camadas.forEach((cam: Camada) => cleanCamadas(cam, this.componenteMapa))
        });
        if (this.geometrias) {
            for (let geometria of this.geometrias) {
                if (!geometria.objetivoLicenciamento) {
                    console.error("ABA 4 - Dados Geográficos: feição sem objetivo definido.")
                    continue;
                }

                if (!geometria.wkt) {
                    console.error(`Não foi encontrado o WKT da geometria ${geometria.denominacao}.`)
                    continue;
                }

                const camada: CamadaRegraGeo = this.findCamada(geometria.idFeicaoEntrada, geometria.objetivoLicenciamento);
                if (camada) {
                    const geometriaMapa: GeometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt, camada.style);
                    let permissao: PermissaoCamada = {
                        remover: false,
                        editar: false
                    }
                    geometriaMapa.extraOptions = [];

                    if (geometria.statusGeometria === StatusGeometria.ENVIADA || geometria.statusGeometria === StatusGeometria.AGUARDANDO_ANALISE) {
                        permissao = {
                            remover: false,
                            editar: false
                        }
                    }

                    geometriaMapa.permissao = permissao;
                    geometriaMapa.extra = {
                        ...geometria,
                    };
                    if (!this.isSomenteVisualizacao) {
                        geometriaMapa.title = `<strong class="req-geometria ${getStatusGeometriaClass(geometria.statusGeometria)}" >[${geometria.id}] ${geometria.denominacao || 'Sem denominação'}</strong>`;
                    } else {
                        geometriaMapa.title = `[${geometria.id}] ${geometria.denominacao || 'Sem denominação'}`;

                    }
                    geometriaMapa.name = geometriaUuid(geometria.id);
                    geometriaMapa.propriedades = {
                        ID: geometria.id,
                        'Denominação': geometria.denominacao
                    };

                    if (!geometria.denominacao) {
                        geometriaMapa.warningMessage = 'Cadastro incompleto';
                    }

                    const possuiInconformidades = geometria.inconformidades && geometria.inconformidades.length > 0;
                    let naoAprovadaOuEmAnalise = false;

                    if (geometria.statusGeometria !== StatusGeometria.APROVADA && geometria.statusGeometria !== StatusGeometria.EM_ANALISE) {
                        naoAprovadaOuEmAnalise = true;
                    }


                    geometriaMapa.visualizacao = {
                        showCheckbox: true,
                        showFeature: true
                    }

                    camada['expandGroup'] = true
                    geometriaMapa.style = camada.style;


                    if (!this.isSomenteVisualizacao) {

                        if (possuiInconformidades && naoAprovadaOuEmAnalise) {
                            geometriaMapa.warningMessage = 'Geometria com Inconformidades';
                        }

                        if (possuiInconformidades) {
                            geometriaMapa.extraOptions.push({
                                icon: 'pageview',
                                text: 'Visualizar inconformidades',
                                hint: 'Visualizar inconformidades',
                                iconeColor: 'orange',
                                callback: () => this.visualizarInconformidades(geometriaMapa)
                            })
                        }

                        if (geometria.statusGeometria === StatusGeometria.EM_ANALISE) {
                            geometriaMapa.extraOptions.push({
                                    icon: 'check_circle_outline',
                                    text: 'Aprovar Geometria',
                                    hint: 'Aprovar Geometria',
                                    iconeColor: 'green',
                                    callback: () => this.aprovarGeometria(geometriaMapa)
                                },
                                {
                                    icon: 'close',
                                    text: 'Reprovar Geometria',
                                    hint: 'Reprovar Geometria',
                                    iconeColor: 'red',
                                    callback: () => this.reprovarGeometria(geometriaMapa)
                                })
                        }

                        if (geometria.statusGeometria === StatusGeometria.APROVADA || geometria.statusGeometria === StatusGeometria.REPROVADA) {
                            geometriaMapa.extraOptions.push({
                                icon: 'refresh',
                                text: 'Reanalisar Geometria',
                                hint: 'Reanalisar Geometria',
                                iconeColor: 'grey',
                                callback: () => this.reanalisarGeometria(geometriaMapa)
                            })
                        }
                    }
                    camada.camadas.push(geometriaMapa);
                }
            }
        }
        this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'requerimento');
        this.camadaTrabalho.camadas.push(this.feicoesRequerimento);
        ordenarFeicoes(this.feicoesRequerimento, this.componenteMapa);
    }

    inserirHistoricoFeicoes(): void {
        // Histórico de Feições do requerimento.
        this.historioFeicoes.camadas.forEach((camada: Camada) => {
            camada.camadas.forEach((cam: Camada) => cleanCamadas(cam, this.componenteMapa))
        });
        const historicoFeicoes = this.getHistoricoFeicoes();
        const geometriaMapas: GeometriaMapa[] = [];
        historicoFeicoes.forEach(objetivo => {
            const camadaGrupo: Camada = {
                title: objetivo.descricaoObjetivoLicenciamento,
                name: `objetivo-${objetivo.objetivoLicenciamento}`,
                geometryGroup: true,
                expandGroup: true,
                camadas: []
            };

            objetivo.feicoes.forEach(feicao => {
                const camadaFeicao: Camada = {
                    title: feicao.descricao,
                    name: `feicao-${feicao.id}`,
                    geometryGroup: true,
                    expandGroup: true,
                    camadas: []
                };

                feicao.geomFeicoes.forEach(geometria => {
                    if (geometria.wkt) {
                        const style = getStyleFromFeicaoDTO(geometria.feicaoDTO)
                        const geometriaMapa: GeometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt, style);
                        geometriaMapa.permissao = {
                            remover: false,
                            editar: false
                        };

                        geometriaMapa.extraOptions = [];
                        geometriaMapa.extra = { ...geometria };
                        geometriaMapa.title = `<strong class="req-geometria ${getStatusGeometriaClass(geometria.statusGeometria)}" >[${geometria.id}] ${geometria.denominacao || 'Sem denominação'}</strong>`;
                        geometriaMapa.name = geometriaUuid(geometria.id);
                        geometriaMapa.propriedades = {
                            ID: geometria.id,
                            'Denominação': geometria.denominacao,
                            'Centroide': geometria.latitudeLongitudeGMS,
                            'Status': geometria.statusGeometria
                        };

                        geometriaMapa.visualizacao = {
                            showCheckbox: true,
                            showFeature: true
                        };
                        if(geometria.inconformidades && geometria.inconformidades.length > 0){
                            geometriaMapa.warningMessage = 'Geometria com Inconformidades';
                            geometriaMapa.extraOptions.push({
                                icon: 'pageview',
                                text: 'Visualizar inconformidades',
                                hint: 'Visualizar inconformidades',
                                iconeColor: 'orange',
                                callback: () => this.visualizarInconformidades(geometriaMapa)
                            });
                        }
                        geometriaMapa.style = style;
                        camadaFeicao.camadas.push(geometriaMapa);
                        geometriaMapas.push(geometriaMapa);
                        this.componenteMapa.onChangeVisulizacao(geometriaMapa.feature, geometriaMapa)
                    }
                });
                camadaGrupo.camadas.push(camadaFeicao);
            });

            this.historioFeicoes.camadas.push(camadaGrupo);
        });

        if(Array.isArray(historicoFeicoes) && historicoFeicoes.length > 0){
            this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'historico-feicoes');
            this.camadaTrabalho.camadas.push(this.historioFeicoes);
            ordenarFeicoes(this.historioFeicoes, this.componenteMapa);
        }
    }

    aprovarGeometria(geom: GeometriaMapa) {
        const dialogRef = this.dialog.open(DialogAprovarGeometriaComponent, {
            width: '50%',
            data: {
                idRequerimentoLicenciamento: this.requerimentoLicenciamento.id,
                geometria: geom
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.success) {
                this.listarGeometrias();
            }
        });
    }

    aprovarGeometrias(camada: Camada, idFeicaoEntrada: number) {
        const dialogRef = this.dialog.open(DialogAprovarGeometriasComponent, {
            width: '50%',
            data: {
                idRequerimentoLicenciamento: this.requerimentoLicenciamento.id,
                camada: camada,
                idFeicaoEntrada: idFeicaoEntrada
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.success) {
                this.listarGeometrias();
            }
        });
    }

    raprovarGeometrias(camada: Camada, idFeicaoEntrada: number) {
        const dialogRef = this.dialog.open(DialogReprovarGeometriasComponent, {
            width: '80%',
            data: {
                idRequerimentoLicenciamento: this.requerimentoLicenciamento.id,
                camada: camada,
                idFeicaoEntrada: idFeicaoEntrada
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.success) {
                this.listarGeometrias();
            }
        });
    }

    reanalisarGeometria(geom: GeometriaMapa) {
        const dialogRef = this.dialog.open(DialogReanalisarGeometriaComponent, {
            width: '60%',
            data: {
                idRequerimentoLicenciamento: this.requerimentoLicenciamento.id,
                geometria: geom
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.success) {
                this.listarGeometrias();
            }
        });
    }

    reanalisarGeometrias(camada: Camada, idFeicaoEntrada: number) {
        const dialogRef = this.dialog.open(DialogReanalisarGeometriasComponent, {
            width: '50%',
            data: {
                idRequerimentoLicenciamento: this.requerimentoLicenciamento.id,
                camada: camada,
                idFeicaoEntrada: idFeicaoEntrada
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.success) {
                this.listarGeometrias();
            }
        });
    }

    visualizarInconformidades(geom: GeometriaMapa) {
        this.dialog.open(DialogVisualizarIncoformidadesGeometriaComponent, {
            width: '60%',
            data: {
                geometria: geom
            }
        });
    }

    reprovarGeometria(geom: GeometriaMapa) {
        const dialogRef = this.dialog.open(DialogReprovarGeometriaComponent, {
            width: '60%',
            data: {
                idRequerimentoLicenciamento: this.requerimentoLicenciamento.id,
                geometria: geom
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result.success) {
                this.listarGeometrias();
            }
        });
    }

    async atualizarMapa(): Promise<void> {
        this.componenteMapa.getSource().clear();

        this.inserirFeicoesImoveisDoRequerimento();
        this.inserirFeicoesDoRequerimento();
        this.inserirHistoricoFeicoes();

        if (this.feicoesDadosAnm.camadas.length > 0) {
            this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'anm');
            this.camadaTrabalho.camadas.push(this.feicoesDadosAnm);
            this.camadas = [...this.camadas];
            this.componenteMapa.fit();
        }

        this.camadas = [...this.camadas];
        this.getFeicoesAnalisadas();
        this.componenteMapa.fit();
    }

    onSelectCamada(camadaSelecionada: Camada): void {
        this.camadaSelecionada = camadaSelecionada;
    }
    getHistoricoFeicoes(): HistoricoFeicao[] {
        const gruposObjetivos: { [key: number]: HistoricoFeicao } = {};

        this.geometrias.forEach(geometria => {
            const camada: CamadaRegraGeo = this.findCamada(geometria.idFeicaoEntrada, geometria.objetivoLicenciamento);

            if (!camada) {
                if (!gruposObjetivos[geometria.objetivoLicenciamento]) {
                    gruposObjetivos[geometria.objetivoLicenciamento] = {
                        objetivoLicenciamento: geometria.objetivoLicenciamento,
                        descricaoObjetivoLicenciamento: geometria.descricaoObjetivoLicenciamento,
                        feicoes: []
                    };
                }

                const grupoObjetivo = gruposObjetivos[geometria.objetivoLicenciamento];
                let feicao = grupoObjetivo.feicoes.find(f => f.id === geometria.feicaoDTO.id);

                if (!feicao) {
                    feicao = {
                        id: geometria.feicaoDTO.id,
                        descricao: geometria.feicaoDTO.descricao,
                        geomFeicoes: []
                    };
                    grupoObjetivo.feicoes.push(feicao);
                }

                feicao.geomFeicoes.push(geometria);
            }
        });

        return Object.values(gruposObjetivos);
    }

    private findCamada(idFeicao: number, idObjetivo: number): CamadaRegraGeo {
        const camadasObjetivo: Camada = this.feicoesRequerimento.camadas.find(c => c.name === `objetivo-${idObjetivo}`);
        if(camadasObjetivo){
            return camadasObjetivo.camadas.find((c: CamadaRegraGeo) => c.name === `${idFeicao}-${idObjetivo}`);
        } else {
            return null
        }
    }

    private criaAnalisesTopologicasTerceiroCenario(regraGeoAdmin: RegraGeo, regrasSemGeometrias: string[]): AnaliseTopologica[] {
        const analisesTopologicas: AnaliseTopologica[] = [];

        regraGeoAdmin.regrasFeicao.forEach(regra => {

            const camadasRequerimento: CamadaRegraGeo[] = getCamadasByFeicaoEntrada(this.feicoesRequerimento, regra.idFeicaoEntradaComoComp);

            if (camadasRequerimento.length > 0) {
                const feicoesComparacao: FeicaoComparacao[] = [];
                camadasRequerimento.forEach((camada) => {
                    if (camada.camadas.length > 0) {
                        camada.camadas.forEach((geometria: GeometriaMapa) => {
                            feicoesComparacao.push({
                                id: regra.idFeicaoEntradaComoComp,
                                wkt: geometria.wkt,
                                idGeometria: geometria.extra.idGeometria
                            })
                        })
                    } else {
                        regrasSemGeometrias.push(regraGeoAdmin.nome)
                    }
                })
                if (feicoesComparacao.length > 0) {
                    analisesTopologicas.push({
                        id: regra.id,
                        feicoesComparacao: feicoesComparacao
                    })
                }
            } else {
                regrasSemGeometrias.push(regraGeoAdmin.nome)
            }
        })
        return analisesTopologicas;
    }

    /**
     *
     * @param regrasGeoConfig
     * @param feicao
     */
    public async processaRequisicoesDeAnalisesTopologicas(regrasGeoConfig: RegrasGeoRequerimentoRegra[], feicao: FeicaoRequerimentoLicenciamentoDto): Promise<CenarioRegrasGeo[]> {
        const aplicacoesRegrasObs = [];
        const regrasSemGeometrias: string[] = [];
        const ocorreuErros: boolean[] = [];

        for (const regraGeoConfig of regrasGeoConfig) {
            let requisicao: RequisicaoAnalisesTopologicas = {
                idFeicaoEntrada: feicao.idFeicaoEntrada,
                wktFeicaoEntrada: feicao.wkt
            }
            if (regraGeoConfig.cenario === CenarioRegraGeo.GARANTIA_FEICOES_REQUERIMENTO) {

                const regraGeoAdmin: RegraGeo = await this.regraGeoService.getRegraGeoById(regraGeoConfig.idRegraGeo).toPromise()

                requisicao.analisesTopologicas = this.criaAnalisesTopologicasTerceiroCenario(regraGeoAdmin, regrasSemGeometrias);

                if (requisicao.analisesTopologicas.length > 0) {
                    aplicacoesRegrasObs.push(this.regraGeoService.applyTerceiroCenario(regraGeoConfig.idRegraGeo, requisicao).pipe(map(response => ({
                        cenarioId: regraGeoConfig.cenario,
                        regra: response
                    }))).toPromise());
                    ocorreuErros.push(false)
                } else {
                    this.snackBarService.show('Não há feições cadastradas para validar o cenário de aplicação das regras GEO para garantia com feições do requerimento.<br>Regras: <br>' + regrasSemGeometrias.join('<br>'), SnackBarType.ERROR, 50000);
                    ocorreuErros.push(true)
                }

            } else {
                aplicacoesRegrasObs.push(this.regraGeoService.apply(regraGeoConfig.idRegraGeo, requisicao).pipe(map(response => ({
                    cenarioId: regraGeoConfig.cenario,
                    regra: response
                }))).toPromise());
                ocorreuErros.push(false)
            }
        }

        if (ocorreuErros.some(i => i)) {
            return []
        } else {
            return await Promise.all(aplicacoesRegrasObs)
        }
    }

    async getAnalisesTopologicasDaGeometria(geometria: FeicaoRequerimentoLicenciamentoDto): Promise<CenarioRegrasGeo[]> {
        if (this.selectedIndex === 0) {
            return []
        }
        const camada: CamadaRegraGeo = this.findCamada(geometria.idFeicaoEntrada, geometria.objetivoLicenciamento);
        try {
            if (camada) {
                const objetivos = this.objetivos.map(ob => ob.idObjetivoLicenciamentoAmbiental);
                const regrasGeoConfig = camada ? this.getRegrasGeo(geometria.idFeicaoEntrada, objetivos) : [];
                return regrasGeoConfig.length > 0 ? await this.processaRequisicoesDeAnalisesTopologicas(regrasGeoConfig, geometria) : [];
            } else {
                return []
            }
        } catch (e) {
            return []
        }
    }

    async onChangeTab(evt) {
        if (evt.index == 0) {
            await this.listarGeometrias();
        } else if (evt.index == 1) {
            this.analisesTopologicasDasGeometriasRequerimento = [];
            let temp = [];
            for (const geometria of this.geometrias) {
                temp.push({
                    geometria: geometria,
                    aplicacoes: await this.getAnalisesTopologicasDaGeometria(geometria)
                })
            }

            this.analisesTopologicasDasGeometriasRequerimento = temp;
        }
        if (evt.index === 2 && !this.isSomenteVisualizacao) {
            this.showTemplateParecer = false;
            setTimeout(() => {
                this.showTemplateParecer = true;
            }, 20);
        }
    }

    getRegrasGeo(idFeicao: number, idObjetivos: number[] = null): RegrasGeoRequerimentoRegra[] {
        let regras: RegrasGeoRequerimentoRegra[] = [];
        idObjetivos.forEach(idObjetivo => {
            const regrasFeicoes = this.regrasFeicoes.get(idObjetivo).filter(regra => regra.idFeicaoEntrada === idFeicao);
            for (let feicao of regrasFeicoes) {
                feicao.regras.forEach(r => {
                    if (!regras.some(x => r.id == x.id)) {
                        regras.push(r)
                    }
                });
            }
        });

        return regras;
    }

    private async showFeicoesAnalisadas(camadas: CamadaObjetivo[]) {
        const camadasDeFeicoesAnalisadas: Camada[] = [];
        for (const camadaObjetivo of camadas) {
            camadaObjetivo.camada.camadas = [];
            camadaObjetivo.camada.expandGroup = true
            const feicoes: CamadaFeicao[] = await Promise.all(camadaObjetivo.feicoesPromises);
            for (const feicaoAnalisada of feicoes) {
                if(feicaoAnalisada){
                    feicaoAnalisada.camada.expandGroup = true
                    feicaoAnalisada.camada.geometryGroup = true;
                    feicaoAnalisada.camada.camadas = [];
                    for (const feicao of feicaoAnalisada.geometrias) {
                        if(feicao.wkt){
                            const geometriaMapa: GeometriaMapa = this.componenteMapa.criarGeometria(feicao.wkt, feicaoAnalisada.camada.style);
                            geometriaMapa.id = feicao.id;
                            geometriaMapa.title = `[${feicao.id}] ${feicao.denominacao || 'Sem denominação'}`;
                            geometriaMapa.name = geometriaUuid(feicao.id);

                            geometriaMapa.propriedades = {
                                ID: feicao.id,
                                'Denominação': feicao.denominacao,
                            };
                            geometriaMapa.permissao = {
                                remover: false,
                                ponto: false,
                                poligono: false,
                                linha: false,
                                buffer: false,
                                upload: false,
                                exportar: true
                            };
                            geometriaMapa.extra = feicao;
                            geometriaMapa.visualizacao = {
                                showCheckbox: true,
                                showFeature: true
                            }
                            geometriaMapa.expandGroup = true;
                            geometriaMapa.style = feicaoAnalisada.camada.style
                            geometriaMapa.feature.setStyle(feicaoAnalisada.camada.style)
                            feicaoAnalisada.camada.camadas.push(geometriaMapa)
                        } else {
                            console.error(`A feição ${feicao.denominacao}, não possui WKT (Geometria) setada.`)
                        }

                    }
                    camadaObjetivo.camada.camadas.push(feicaoAnalisada.camada)
                }
            }
            camadasDeFeicoesAnalisadas.push(camadaObjetivo.camada)
        }
        cleanCamadas(this.feicoesAnalisadas, this.componenteMapa)
        this.feicoesAnalisadas.camadas = [...camadasDeFeicoesAnalisadas];

        ordenarFeicoes(this.feicoesAnalisadas, this.componenteMapa);
        this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'feicoes-analisadas');
        this.camadaTrabalho.camadas.push(this.feicoesAnalisadas);
        this.camadas = [...this.camadas];
        this.componenteMapa.fit();
    }

    private async handleFeicoesAnalisadas(feicoesAnalisadas: FeicaoRequerimentoLicenciamentoDto[]) {
        const feicoesAgrupadas: CamadaObjetivo[] = agruparFeicoesAnalisadasPorObjetivos(feicoesAnalisadas);
        feicoesAgrupadas.forEach((objetivo: CamadaObjetivo) => {
            objetivo.camada = {
                title: objetivo.descricaoObjetivoLicenciamento,
                name: `objetivo-${objetivo.objetivoLicenciamento}`,
                geometryGroup: true,
                expandGroup: true,
                camadas: [],
            };
            objetivo.feicoesPromises = [];
            objetivo.feicoes.forEach((feicaoEntrada: CamadaFeicao) => {
                objetivo.feicoesPromises.push(new Promise<CamadaFeicao>((resolve, reject) => {
                    if(feicaoEntrada.idFeicaoEntrada){
                        this.feicaoService.getById(Number(feicaoEntrada.idFeicaoEntrada)).subscribe({
                            next: (feicao: Feicao) => {
                                resolve({
                                    camada: getCamada(feicao),
                                    geometrias: feicaoEntrada.geometrias
                                })
                            },
                            error: e => {
                                console.error('handleFeicoesAnalisadas', e)
                                resolve(null)
                            }
                        })
                    } else {
                        resolve(null)
                    }
                }))
            })
        })
        await this.showFeicoesAnalisadas(feicoesAgrupadas);
    }

    private getFeicoesAnalisadas(): void {
        if (this.requerimentoLicenciamento.requerimento.numeroProtocoloVinculado) {
            this.requerimentoLicenciamentoService.getFeicoesAnalisadas(this.requerimentoLicenciamento.requerimento.numeroProtocoloVinculado)
                .subscribe({
                    next: (feicoesAnalisadas: FeicaoRequerimentoLicenciamentoDto[]) => this.handleFeicoesAnalisadas(feicoesAnalisadas).then(),
                    error: e => this.snackBarService.showError('Erro ao buscar as feições analisadas.', e)
                })
        }
    }

    gerarArquivo(arquivo: { blob: Blob, nome: string }): void {
        this.arquivoGerado.emit(arquivo);
    }
}