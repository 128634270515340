import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {PontoParametroDiluicao} from "../models/hidrico-models/ponto-parametro-diluicao.model";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Ponto} from "../models/hidrico-models/ponto.model";

@Injectable({
    providedIn: 'root'
})
export class PontoParametroDiluicaoService extends BaseService<PontoParametroDiluicao> {

    constructor(protected injector: Injector) {
        super('/api/pontos-parametros-diluicao', injector, PontoParametroDiluicao.prototype, PontoParametroDiluicao.fromJson);
    }

    findAllByPonto(ponto: Ponto): Observable<PontoParametroDiluicao[]> {
        return this.http.get(`${this.urlResource}`, {params: {idPonto: ponto.id.toString()}})
            .pipe(
                map(json => PontoParametroDiluicao.fromJsons(json)),
                catchError(e => throwError(e))
            );
    }
}
