import {Injectable, Injector} from '@angular/core';
import {SistemaTratamentoPrimario} from "../models/sistema-tratamento-primario.model";
import {BaseRelacionamentoService} from "./base-relacionamento.service";
import {DominiosService} from "./dominios.service";
import {MateriaPrimaIndFrigorifico} from '../models/materia-prima-ind-frigorifico.model';
import {SubProdutoProcessadoProprioIndFrigorifico} from "../models/subproduto-processado-proprio-ind-frigorifico.model";
import {environment} from "../../../../environments/environment";


@Injectable({
    providedIn: "root"
})
export class SubProdutoProcessadoProprioIndFrigorificoService extends BaseRelacionamentoService<SubProdutoProcessadoProprioIndFrigorifico> {

    constructor(protected injector: Injector, protected dominiosService: DominiosService) {
        super(
            "/api/subprod-proc-proprio-ind-frig",
            injector,
            SubProdutoProcessadoProprioIndFrigorifico.prototype,
            SubProdutoProcessadoProprioIndFrigorifico.fromJson,
            dominiosService,
            false,
            environment.URL_LIC_IND_FRIGORIFICO
        );
    }

    protected get pathGetByEntidadePai(): string {
        return "industria-frigorifico";
    }

    protected get pathGetDominio(): string {
        return "subproduto-processado-proprio";
    }


}
