import {BaseModel} from 'app/main/shared/models/base.model';
import {EmpreendimentoEnergTermica} from './empreendimento-energ-termica';

export class UsinaTipoBiomassa extends BaseModel {
        
    constructor(

        public id?: number,
        public empreendimentoEnergTermica?: EmpreendimentoEnergTermica,
        public idTipoBiomassa?: number,
        public idSubTipoBiomassa?: number,
        public descricao?: string,
    ) {
        super();
    }
    static fromJson(json: any): UsinaTipoBiomassa {
        if (json === undefined || json === null) {
            return null;
        }
        return new UsinaTipoBiomassa(
            json.id,
            EmpreendimentoEnergTermica.fromJson(json.empreendimentoEnergTermica),
            json.idTipoBiomassa,
            json.idSubTipoBiomassa,
            json.descricao,
        );
    }

    static fromJsons(json: any): UsinaTipoBiomassa[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(UsinaTipoBiomassa.fromJson(j)));
            return resources;
        }
    }
}
