import {BaseModel} from "./base.model";
import {EmpreendimentoTransporteResiduosPerigosos} from "./empreendimento-transporte-residuos-perigosos.model";


export class RotasTransporteResiduosPerigosos extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoTransporteResiduosPerigosos?: EmpreendimentoTransporteResiduosPerigosos,
        public procedenciaCnpj?: string,
        public procedenciaRazaoSocial?: string,
        public procedenciaNumeroLicenca?: number,
        public destinoCnpj?: string,
        public destinoRazaoSocial?: string,
        public destinoNumeroLicenca?: number,
    ) {
        super();
    }

    static fromJson(json: any): RotasTransporteResiduosPerigosos {
        if (json === undefined || json === null) {
            return null;
        }
        return new RotasTransporteResiduosPerigosos(
            json.id,
            EmpreendimentoTransporteResiduosPerigosos.fromJson(json.empreendimentoTransporteResiduosPerigosos),
            json.procedenciaCnpj,
            json.procedenciaRazaoSocial,
            json.procedenciaNumeroLicenca,
            json.destinoCnpj,
            json.destinoRazaoSocial,
            json.destinoNumeroLicenca,
        );
    }

    static fromJsons(json: any): RotasTransporteResiduosPerigosos[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(RotasTransporteResiduosPerigosos.fromJson(j)));
            return resources;
        }
    }
}