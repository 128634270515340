import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {CondicionanteTemplate} from '../condicionante-template.model';
import {TipoCondicionante} from '../../tipos-condicionante/tipo-condicionante.model';
import {Validators} from '@angular/forms';
import {CondicionanteTemplateService} from '../condicionante-template.service';
import {TipoCondicionanteService} from '../../tipos-condicionante/tipo-condicionante.service';
import {fuseAnimations} from '@fuse/animations';

@Component({
    selector: 'app-condicionante-template-detail',
    templateUrl: './condicionante-template-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class CondicionanteTemplateDetailComponent extends BaseDetailComponent<CondicionanteTemplate> implements OnInit {
    tiposCondicionante: TipoCondicionante[];

    constructor(
        protected service: CondicionanteTemplateService,
        protected tipoCondicionanteService: TipoCondicionanteService,
        protected injector: Injector
    ) {
        super(injector, new CondicionanteTemplate(), service, CondicionanteTemplate.fromJson);
    }

    ngOnInit(): void {
        this.listarTiposCondicionante();
        super.ngOnInit();
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            descricao: [null, [Validators.required, Validators.maxLength(2048)]],
            tipoCondicionante: [null, [Validators.required]],
            situacao: [null]
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova condicionante';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando a condicionante: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando a condicionante: ' + resourceName;
    }

    private listarTiposCondicionante(): void {
        this.tipoCondicionanteService
            .getAll()
            .subscribe((tiposCondicionante) => {
                if (tiposCondicionante) {
                    this.tiposCondicionante = tiposCondicionante;
                } else {
                    this.tiposCondicionante = [];
                }
            });
    }
}
