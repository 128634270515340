import { BaseModel } from "app/main/shared/models/base.model";

export class TemplateDTO extends BaseModel {
    constructor(
        public id?: number,
        public nomeTemplate?: string,
        public descricao?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public tipoTemplate?: string,
        public tipoTitulo?: string,
        public fluxoProcesso?: string
    ) {
        super();
    }

    static fromJson(json: any): TemplateDTO {
        if (json === undefined || json === null) return null;
        return new TemplateDTO(
            json.id,
            json.nomeTemplate,
            json.descricao,
            json.dataInicio,
            json.dataFim,
            json.tipoTemplate,
            json.tipoTitulo,
            json.fluxoProcesso
        );
    }
}