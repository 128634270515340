import {Component, ComponentFactoryResolver, Injector, OnInit} from '@angular/core';
import {BaseTabRequerimentoComponent} from '../../shared/base-requerimento.component';
import {RequerimentoSteps} from '../../shared/requerimento.steps';
import {ErrosAbaRequerimento} from '../../shared/requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import {RequerimentoFormularioLicenciamentoService} from '../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service';
import {FormularioReqLicenciamentoDto} from '../../../../shared/models/formulario-req-licenciamento.dto';
import {AtividadeArrecadacaoService} from '../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {RequerimentoLicenciamento} from '../requerimento-licenciamento.model';

@Component({
    selector: 'app-requerimento-atividade-licenciamento',
    templateUrl: './requerimento-atividade-licenciamento.component.html',
})

export class RequerimentoAtividadeLicenciamentoComponent extends BaseTabRequerimentoComponent implements OnInit {

    requerimentoLicenciamentoLac: RequerimentoLicenciamento;
    formularioReqLicenciamentoDto: FormularioReqLicenciamentoDto;
    consultaAtividadeServico: any = [];
    parametroEntrada: any = [];

    constructor(
        injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver,
        protected requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private atividadeArrecadacaoService: AtividadeArrecadacaoService,
        private snackBarService: SnackBarService,
    ) {
        super(injector);
        RequerimentoSteps.ATIVIDADE.component = this;
    }

    ngOnInit(): void {
        this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId(this.requerimento.id)
            .subscribe(formularioReqLicenciamentoDto => {
                if (formularioReqLicenciamentoDto) {
                    this.formularioReqLicenciamentoDto = formularioReqLicenciamentoDto;
                    this.requerimentoLicenciamentoLac = formularioReqLicenciamentoDto.requerimentoLicenciamento;
                    if (this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList.length > 0) {
                        this.parametroEntrada = this.formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList;
                    }
                }
                this.carregarAtividade();
            });
    }

    carregarAtividade(): void {
        this.atividadeArrecadacaoService.getAtividadeDto(
            this.requerimentoLicenciamento.idAtividade,
            this.requerimentoLicenciamento.idGrupoAtividade
        )
            .toPromise().then(async atividade => {
            atividade['ppd'] = this.requerimentoLicenciamento.ppd;
            this.consultaAtividadeServico = [];
            this.consultaAtividadeServico.push(atividade);
        }, error => this.snackBarService.showError('Ocorreu um erro', error));
    }

    validarAba(erros: ErrosAbaRequerimento): void {
    }
}
