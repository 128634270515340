import { LagoaBaciaContencao } from "./../models/lagoa-bacia-contencao";
import { environment } from "./../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class LagoaBaciaContencaoService {
    constructor(private http: HttpClient) {}

    urlResource: string = `${environment.URL_LIC_FEICAO_PECUARIA_INTENSIVA}/api/lagoa-bacia-contencao`;

    createLagoaBaciaContencao(lagoaBaciaContencao: LagoaBaciaContencao): Observable<LagoaBaciaContencao> {
        console.log('Dados da lagoa-bacia-contenção: ', JSON.stringify(lagoaBaciaContencao))
        const url = `${this.urlResource}`;
        return this.http.post(url, lagoaBaciaContencao).pipe(
            map((response) => response),
            catchError((e) => throwError(e))
        );
    }

    deleteLagoaBaciaContencao(id: number): Observable<void> {
        return this.http.delete<void>(`${this.urlResource}/${id}`);
    }

    read(): Observable<LagoaBaciaContencao[]> {
        return this.http.get<LagoaBaciaContencao[]>(`${this.urlResource}/lista`);
    }
    
    readByIdGeometria(id: number): Observable<LagoaBaciaContencao[]> {
        return this.http.get<LagoaBaciaContencao[]>(`${this.urlResource}/buscarPorIdGeometria/${id}`);
    }

    update(lagoaBaciaContencao: LagoaBaciaContencao): Observable<LagoaBaciaContencao> {
        const url = `${this.urlResource}`;
        return this.http.put<any>(url, lagoaBaciaContencao);
    }
}
