import {Component, OnInit} from '@angular/core';
import {DominiosService} from '../../../../shared/services/dominios.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {FertirrigacaoService} from '../../../../shared/services/fertirrigacao.service';
import {Fertirrigacao} from '../../../../shared/models/fertirrigacao.model';
import {BaseEnum} from '../../../../shared/enums/base.enum';

@Component({
    selector: 'app-fertirrigacao',
    templateUrl: './fertirrigacao.component.html',
    styleUrls: ['./fertirrigacao.component.scss']
})
export class FertirrigacaoComponent implements OnInit {

    culturasDesenvolvidas: any;
    tipoSistema: any;
    formFertirrigacao: FormGroup;

    // : TODO Esses Parametros devem chegar atraves do component Pai
    idGeometria = 10;

    mesesEnum: BaseEnum[] = [
        {valor: 'JANEIRO', descricao: 'Janeiro'},
        {valor: 'FEVEREIRO', descricao: 'Fevereiro'},
        {valor: 'MARCO', descricao: 'Março'},
        {valor: 'ABRIL', descricao: 'Abril'},
        {valor: 'MAIO', descricao: 'Maio'},
        {valor: 'JUNHO', descricao: 'Junho'},
        {valor: 'JULHO', descricao: 'Julho'},
        {valor: 'AGOSTO', descricao: 'Agosto'},
        {valor: 'SETEMBRO', descricao: 'Setembro'},
        {valor: 'OUTUBRO', descricao: 'Outubro'},
        {valor: 'NOVEMBRO', descricao: 'Novembro'},
        {valor: 'DEZEMBRO', descricao: 'Dezembro'},
    ];

    constructor(private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private dominiosService: DominiosService,
                private fertirrigacaoService: FertirrigacaoService) {
    }

    ngOnInit() {
        this.buscaTipoSistema();
        this.buscaCulturasdesenvolvidas();
        this.fertirrigacaoBuilder();
    }

    private fertirrigacaoBuilder(): void {
        this.formFertirrigacao = this.formBuilder.group({
            id: [null],
            idTipoSistema: [null, Validators.required],
            idTipoCulturasDesenv: [null, Validators.required],
            mesInicial: [null, Validators.required],
            mesFinal: [null, Validators.required],
            volumeEfluente: [null, Validators.required],
        });
    }

    buscaTipoSistema(): void {
        this.dominiosService.getDominio('TIPO_SISTEMA_FERTIRRIGACAO').subscribe(result => {
            this.tipoSistema = result;
        });
    }

    buscaCulturasdesenvolvidas(): void {
        this.dominiosService.getDominio('TIPO_CULTURAS_DESENVOLVIDA').subscribe(result => {
            this.culturasDesenvolvidas = result.filter(tipo => tipo.descricao !== 'Outras');
        });
    }

    salvar(): void {
        const fertirrigacao = new Fertirrigacao();
        if (this.formFertirrigacao.valid) {
            fertirrigacao.idGeometria = this.idGeometria;
            fertirrigacao.idTipoSistema = this.formFertirrigacao.value.idTipoSistema;
            fertirrigacao.idTipoCulturasDesenv = this.formFertirrigacao.value.idTipoCulturasDesenv;
            fertirrigacao.mesInicial = this.formFertirrigacao.value.mesInicial;
            fertirrigacao.mesFinal = this.formFertirrigacao.value.mesFinal;
            fertirrigacao.volumeEfluente = this.formFertirrigacao.value.volumeEfluente;

            this.fertirrigacaoService.createFertirrigacao(fertirrigacao).subscribe(() => {
                this.snackBar.showSuccess('Fertirrigação salvo com sucesso.');
                this.formFertirrigacao.reset();
            });
        } else {
            this.formFertirrigacao.markAllAsTouched();
            this.snackBar.showError('Campo(s) obrigatório(s) não' +
                ' preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }
}



