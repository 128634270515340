import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material";
import {ConfirmarExclusaoDialogComponent} from "../../../../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component";
import {IrrigacaoService} from "../../../../../../shared/services/irrigacao.service";
import {SnackBarService} from "../../../../../../shared/snack-bar/snack-bar.service";
import {BaseFinalidadeComponent} from "../../base-finalidade.component";
import {AdicionarIrrigacaoComponent, AdicionarIrrigacaoData} from "../adicionar-irrigacao.component";
import {PontoService} from "../../../../../../shared/services/ponto.service";
import {RequerimentoOutorgaFinalidadeService} from "../../../../../../shared/services/requerimento-outorga-finalidade.service";
import {Ponto} from "../../../../../../shared/models/hidrico-models/ponto.model";
import {IrrigacaoDTO} from "../irrigacao-dto.model";
import {isNullOrUndefined} from "util";

@Component({
    selector: 'app-lista-irrigacao',
    templateUrl: './lista-irrigacao.component.html',
    styleUrls: ['./lista-irrigacao.component.scss']
})
export class ListaIrrigacaoComponent extends BaseFinalidadeComponent implements OnInit {
    irrigacaoList: IrrigacaoDTO[];

    condicaoFinalidadeValida: () => boolean = () => this.pontosQueAindaNaoForamAssociados().length == 0 && this.irrigacaoList.every(i => i.formularioValido);

    constructor(
        private dialog: MatDialog,
        private irrigacaoService: IrrigacaoService,
        private snackBarService: SnackBarService,
        private pontoService: PontoService,
        private requerimentoOutorgaFinalidadeService: RequerimentoOutorgaFinalidadeService,
    ) {
        super();
    }

    ngOnInit() {
        this.listarIrrigacoes();
    }

    excluirIrrigacao(irrigacao: IrrigacaoDTO) {
        this.dialog.open(ConfirmarExclusaoDialogComponent, {data: irrigacao.id}).afterClosed().subscribe(result => {
            if (result) {
                this.irrigacaoService.excluirIrrigacao(irrigacao).subscribe(() => {
                        this.snackBarService.showSuccess('Irrigação excluida com sucesso.');
                        this.listarIrrigacoes();
                    },
                    e => this.snackBarService.showError('Erro ao excluir irrigação', e))
            }
        });
    }

    adicionarEditarIrrigacao(irrigacao: IrrigacaoDTO = null) {
        const pontosNaoAdicionados = this.pontosQueAindaNaoForamAssociados();
        if (!isNullOrUndefined(irrigacao) || pontosNaoAdicionados.length > 0) {
            this.dialog.open<AdicionarIrrigacaoComponent, AdicionarIrrigacaoData>(AdicionarIrrigacaoComponent, {
                maxHeight: '90vh', maxWidth: '90vw', minWidth: '70vw',
                data: {
                    requerimentoOutorgaFinalidade: this.requerimentoOutorgaFinalidade,
                    pontos: pontosNaoAdicionados,
                    irrigacao: irrigacao
                }}).afterClosed().subscribe(result => {
                if (result) {
                    this.listarIrrigacoes();
                }
            });
        } else {
            this.snackBarService.showAlert('Todos os pontos já foram vinculados, não é possivel adicionar mais formulários.')
        }
    }

    private listarIrrigacoes() {
        this.irrigacaoService.listarIrrigacoes(this.requerimentoOutorgaFinalidade).subscribe(result => {
                this.irrigacaoList = result;
                const bool: boolean = this.condicaoFinalidadeValida();
                if (this.requerimentoOutorgaFinalidade.cadastroConcluido != bool) {
                    this.requerimentoOutorgaFinalidade.cadastroConcluido = bool;
                    this.requerimentoOutorgaFinalidadeService.update(this.requerimentoOutorgaFinalidade).subscribe(() => null,
                            e => this.snackBarService.showError('Erro ao atualizar requerimento finalidade', e))
                }
            },
            e => this.snackBarService.showError('Erro ao listar irrigações', e));
    }

    pontosQueAindaNaoForamAssociados() : Ponto[] {
        return this.pontos.filter(ponto => ponto.finalidade.id == this.requerimentoOutorgaFinalidade.finalidade.id &&
            !this.irrigacaoList.some(irrigacao => irrigacao.pontos.some(p => ponto.id == p.id)))
    }
}
