import {BaseModel} from '../base.model';
import {CentroCustoArrecadacao} from './centro-custo-arrecadacao.model';
import {SearchableModel} from '../../components/select-search/searchable-model';

export class AtividadeArrecadacao extends BaseModel implements SearchableModel {

    constructor(
        public id?: number,
        public descricao?: string,
        public atividadePai?: AtividadeArrecadacao,
        public centroCusto?: CentroCustoArrecadacao,
        public cnae?: string,
        public ativo?: string,
        public regraApp?: boolean
    ) {
        super();
    }

    static fromJson(json: any): AtividadeArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new AtividadeArrecadacao(
            json.id,
            json.descricao,
            AtividadeArrecadacao.fromJson(json.atividadePai),
            CentroCustoArrecadacao.fromJson(json.centroCusto),
            json.cnae,
            json.ativo,
            json.regraApp
        );
    }

    static compareWith = (a: AtividadeArrecadacao, b: AtividadeArrecadacao): boolean => a && b ? a.id === b.id : a === b;

    searchValue(): string {
        return `${this.descricao}`;
    }

    itemLabel(): string {
        return this.descricao;
    }

}
