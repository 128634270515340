import {Camada, CamadaFlatNode, GeometriaMapa, MapaPadraoComponent, PermissaoCamada} from "@sema-geo/sema-geoportal";
import {GeoJSON, KML, WKT} from 'ol/format';
import {Circle, Fill, Icon, Stroke, Style} from 'ol/style';

import * as _shp from 'shpjs/dist/shp';

import {StatusGeometria} from "../enums/status-geometria.enum";
import {FeicaoRequerimentoLicenciamentoDto} from "../models/feicao-requerimento-licenciamento-dto.model";
import {CamadaRegraGeo} from "../models/geoadmin/camada-regra-geo.model";
import {CamadaObjetivo} from "../models/geoadmin/feicao-vinculada.interface";
import {Feicao} from "../models/geoadmin/feicao.model";
import {RegrasGeoRequerimentoFeicao} from "../models/regras-geo-requerimento/regra-geo-requerimento-feicao.model";
import {CommonsUtil} from "./commons-util";
import {FormUtil} from "./form-util";
import {FeicaoDTO} from "../models/feicao-dto.model";

const shp = _shp;

export const getEstiloFromFeicao = (feicao: Feicao): any => {
    let estilo: any = null;

    if (feicao.geometriaPonto) {
        estilo = {
            corPreenchimento: feicao.geometriaPonto.corPreenchimento,
            espessuraBorda: feicao.geometriaPonto.espessuraBorda,
            cor: feicao.geometriaPonto.corBorda,
            tipoTracejado: feicao.geometriaPonto.tipoTracejado,
            icone: feicao.geometriaPonto.icone
        };
    } else if (feicao.geometriaPoligono) {
        estilo = {
            corPreenchimento: feicao.geometriaPoligono.corPreenchimento,
            espessuraBorda: feicao.geometriaPoligono.espessuraBorda,
            cor: feicao.geometriaPoligono.corBorda,
            tipoTracejado: feicao.geometriaPoligono.tipoTracejado
        };
    } else if (feicao.geometriaLinha) {
        estilo = {
            corPreenchimento: 'white',
            espessuraBorda: feicao.geometriaLinha.espessuraBorda,
            cor: feicao.geometriaLinha.corLinha,
            tipoTracejado: feicao.geometriaLinha.tipoTracejado
        };
    }
    return estilo;
}
export const getEstiloFromFeicaoDTO = (feicao: any): any => {
    let estilo: any = null;
    if (feicao.geometriaPonto) {
        estilo = {
            corPreenchimento: feicao.geometriaPonto.corPreenchimento,
            espessuraBorda: feicao.geometriaPonto.espessuraBorda,
            cor: feicao.geometriaPonto.corBorda,
            tipoTracejado: feicao.geometriaPonto.tipoTracejado,
            icone: feicao.geometriaPonto.icone
        };
    } else if (feicao.geometriaPoligono) {
        estilo = {
            corPreenchimento: feicao.geometriaPoligono.corPreenchimento,
            espessuraBorda: feicao.geometriaPoligono.espessuraBorda,
            cor: feicao.geometriaPoligono.corBorda,
            tipoTracejado: feicao.geometriaPoligono.tipoTracejado
        };
    } else if (feicao.geometriaLinha) {
        estilo = {
            corPreenchimento: 'white',
            espessuraBorda: feicao.geometriaLinha.espessuraBorda,
            cor: feicao.geometriaLinha.corLinha,
            tipoTracejado: feicao.geometriaLinha.tipoTracejado
        };
    }
    return estilo;
}
export const getStyle = (estilo: any): Style => {
    let style: Style = null;
    let icone: string = null;
    if (estilo) {
        let lineDash: number[] = null;

        if (estilo.tipoTracejado == 1) {
            lineDash = [10, 20]
        } else if (estilo.tipoTracejado == 2) {
            lineDash = [5, 10];
        }

        let stroke: string = new Stroke({
            color: estilo.cor || 'transparent',
            width: estilo.espessuraBorda || 1,
            lineCap: 'round',
            lineDash: lineDash
        });
        if (estilo.icone) {
            icone = new Icon({
                crossOrigin: 'anonymous',
                src: `data:image/svg+xml;utf8,${encodeURIComponent(estilo.icone)}`,
            });
        } else {
            icone = new Circle({
                radius: 6,
                fill: new Fill({
                    color: estilo.corPreenchimento,
                }),
                stroke: stroke,
            })
        }
        style = new Style({
            fill: new Fill({
                color: estilo.corPreenchimento || 'transparent'
            }),
            stroke: stroke,
            image: icone
        });
    }
    return style
}
export const getStyleFromFeicao = (feicao: Feicao): Style => {
    return getStyle(getEstiloFromFeicao(feicao))
}
export const getStyleFromFeicaoDTO = (feicao: FeicaoDTO): Style => {
    return getStyle(getEstiloFromFeicaoDTO(feicao))
}
export const getCamada = (feicao: Feicao, permissao?: PermissaoCamada): CamadaRegraGeo => {
    let style: Style = null;
    let icone: string = null;
    const estilo: any = getEstiloFromFeicao(feicao);

    if (estilo) {
        let lineDash: number[] = null;

        if (estilo.tipoTracejado == 1) {
            lineDash = [10, 20]
        } else if (estilo.tipoTracejado == 2) {
            lineDash = [5, 10];
        }

        let stroke: string = new Stroke({
            color: estilo.cor,
            width: estilo.espessuraBorda || 1,
            lineCap: 'round',
            lineDash: lineDash
        });
        if (estilo.icone) {
            icone = new Icon({
                crossOrigin: 'anonymous',
                src: `data:image/svg+xml;utf8,${encodeURIComponent(estilo.icone)}`,
            });
        } else {
            icone = new Circle({
                radius: 6,
                fill: new Fill({
                    color: estilo.corPreenchimento,
                }),
                stroke: stroke,
            })
        }
        style = new Style({
            fill: new Fill({
                color: estilo.corPreenchimento
            }),
            stroke: stroke,
            image: icone
        });
    }

    let camada: CamadaRegraGeo = {
        title: feicao.descricao,
        name: feicao.descricao,
        camadas: [],
        permissao: permissao,
        style
    };
    camada.feicao = feicao;
    return camada
}
export const cleanCamadas = (camada: Camada, mapa?: MapaPadraoComponent): void => {
    if (camada.camadas && camada.camadas.length > 0) {
        for (let subCamada of camada.camadas) {
            cleanCamadas(subCamada, mapa);
        }
    }
    if (mapa) {
        if (camada instanceof GeometriaMapa) {
            mapa.deleteFeature(camada)
        } else {
            camada.camadas = [];
        }
    }
}
export const getCamadaByFeicaoEntrada = (camada: Camada, idFeicaoEntrada: number): Camada[] => {
    let result: Camada[] = [];

    for (const cam of camada.camadas) {
        if (cam.hasOwnProperty('regraGeoRequerimentoFeicao')) {
            const camadaRegraGeo: CamadaRegraGeo = cam;

            if (camadaRegraGeo.regraGeoRequerimentoFeicao.idFeicaoEntrada === idFeicaoEntrada) {
                result.push(cam);
            }
        }
        result = result.concat(getCamadaByFeicaoEntrada(cam, idFeicaoEntrada));
    }

    return result;
}
export const getCamadasByFeicaoEntrada = (camada: Camada, idFeicaoEntrada: number): Camada[] => {
    let result: Camada[] = [];
    for (const cam of camada.camadas) {
        if (cam.hasOwnProperty('regraGeoRequerimentoFeicao')) {
            const camadaRegraGeo: CamadaRegraGeo = cam;
            if (camadaRegraGeo.regraGeoRequerimentoFeicao.idFeicaoEntrada === idFeicaoEntrada) {
                result = result.concat(cam.camadas);
            } else {
                result = result.concat(getCamadasByFeicaoEntrada(camadaRegraGeo, idFeicaoEntrada));
            }
        } else {
            result = result.concat(getCamadasByFeicaoEntrada(cam, idFeicaoEntrada));
        }
    }
    return result;
}

export const getFeicoesEntrada = (camada: Camada): CamadaRegraGeo[] => {
    let instances: CamadaRegraGeo[] = [];
    if (camada.hasOwnProperty('regraGeoRequerimentoFeicao')) {
        instances.push(camada);
    }

    if (camada.camadas) {
        for (const childCamada of camada.camadas) {
            instances = instances.concat(getFeicoesEntrada(childCamada));
        }
    }
    return instances;
}
export const getFeicaoEntradaByGeometria = (camada: Camada, idGeometria: number): Camada | null => {
    for (const cam of camada.camadas) {
        if (cam.hasOwnProperty('regraGeoRequerimentoFeicao')) {
            if (cam.camadas.some((c: GeometriaMapa) => c.extra.idGeometria === idGeometria)) {
                return cam;
            } else {
                const nestedResult = getFeicaoEntradaByGeometria(cam, idGeometria);
                if (nestedResult !== null) {
                    return nestedResult;
                }
            }
        }
    }
    return null;
}

export const getCamadaByGeometria = (camada: Camada, idGeometria: number): Camada | null => {
    for (const cam of camada.camadas) {
        let extra = (cam as any).extra || (cam as any).extraOptions;
        if (extra) {
            if (extra.idGeometria === idGeometria) {
                return cam;
            }
        }
        if (cam.hasOwnProperty('camadas')) {
            return getCamadaByGeometria(cam, idGeometria);
        } 
    }
    return null;
}
export const getGeometriaMapa = (camada: Camada, idGeometria: number): GeometriaMapa => {
    for (const cam of camada.camadas) {
        if (cam instanceof GeometriaMapa) {
            if (cam.extra.idGeometria == idGeometria) {
                return cam;
            } else {
                return getGeometriaMapa(camada, idGeometria);
            }
        } else {
            return getGeometriaMapa(camada, idGeometria);
        }
    }
    return null;
}

export const getAllGeometriasMapa = (camada: Camada, key?: string): GeometriaMapa[] => {
    let geometrias: GeometriaMapa[] = [];

    const buscarGeometrias = (camada: Camada): void => {
        for (const cam of camada.camadas) {
            // Se a key não for fornecida, não filtrar
            if (!key || cam.name !== key) {
                if (cam instanceof GeometriaMapa) {
                    geometrias.push(cam);
                } else {
                    buscarGeometrias(cam);
                }
            }
        }
    };

    buscarGeometrias(camada);

    return geometrias;
}

export const removerCamada = (camada: Camada, key: string): Camada[] => {
    return camada.camadas.filter(camada => camada.name !== key);
}
export const geometriaUuid = (id: string | number): string => {
    const caracteres: string = `abcdefghijklmnopqrstuvwxyz${Date.now().toString()}`;
    let uuidCurto: string = '';

    for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * caracteres.length);
        uuidCurto += caracteres[randomIndex];
    }
    return `${id ? id + '-' : ''}${uuidCurto}`;
}
export const ordenarFeicoes = (camada: Camada, mapa?: MapaPadraoComponent): void => {
    camada.camadas.sort((a: Camada, b: Camada) => CommonsUtil.comparaStrings(a['title'], b['title']));
    camada.camadas.forEach((camadasObjetivos: Camada): void => {
        camadasObjetivos.camadas.forEach((cam, index) => {
            if (cam.title.includes('*')) {
                camadasObjetivos.camadas.splice(index, 1);
                camadasObjetivos.camadas.unshift(cam);
            }
        })
        camadasObjetivos.camadas.forEach((camadaFeicaoEntrada: Camada): void => {
            //Adiciona Legenda das Feições de Entrada
            let node: CamadaFlatNode = new CamadaFlatNode();
            const title = `${camadaFeicaoEntrada.title.toUpperCase()}`
            node.item = title
            node.camada = camadaFeicaoEntrada;
            node.camada.title = title;
            const legendaPresenteNoMapa = mapa.camadasSelecionadas.find((no) => CommonsUtil.normaliza(node.item) === CommonsUtil.normaliza(no.item));
            if (legendaPresenteNoMapa) {
                // A camada já possui legenda no mapa
            } else {
                mapa.camadasSelecionadas.unshift(node);
            }
            //ordena feições
            camadaFeicaoEntrada.camadas.sort((a, b) => CommonsUtil.comparaStrings(a['title'], b['title']))
        })
    })
}
export const agruparFeicoesAnalisadasPorObjetivos = (feicoesAnalisadas: FeicaoRequerimentoLicenciamentoDto[]): CamadaObjetivo[] => {
    if (feicoesAnalisadas) {
        return feicoesAnalisadas.reduce((acumulardor, value) => {
            let objetivos = acumulardor.find(o => o.objetivoLicenciamento === value.objetivoLicenciamento);

            if (!objetivos) {
                objetivos = {
                    objetivoLicenciamento: value.objetivoLicenciamento,
                    descricaoObjetivoLicenciamento: value.descricaoObjetivoLicenciamento,
                    feicoes: []
                };
                acumulardor.push(objetivos);
            }

            let feicoes = objetivos.feicoes.find(f => f.idFeicaoEntrada === value.idFeicaoEntrada);

            if (!feicoes) {
                feicoes = {
                    idFeicaoEntrada: value.idFeicaoEntrada,
                    geometrias: []
                };
                objetivos.feicoes.push(feicoes);
            }

            feicoes.geometrias.push({
                ...value
            });
            return acumulardor;
        }, []);
    } else {
        return [];
    }
}

export function getStatusGeometriaClass(status: StatusGeometria): string {
    return 'req-geometria-' + status.toLowerCase().replace('_', '-');
}

export function temFeicaoDeEnvioObrigatorio(idFeicaoEntrada: number, feicoesConfiguracao: RegrasGeoRequerimentoFeicao[]): boolean {
    for (let feicao of feicoesConfiguracao) {
        if (feicao.idFeicaoEntrada === idFeicaoEntrada && feicao.envioObrigatorio) {
            return true; // Retorna true se uma feição correspondente for encontrada
        }
    }
    return false; // Retorna false se nenhuma feição correspondente for encontrada
}
export async function getFeaturesFromFile(file, mapaComponent: MapaPadraoComponent) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        // Auxiliar para processar as características e resolver a promessa
        const processFeatures = (input, format) => {
            try {
                const features = format.readFeatures(input, {
                    featureProjection: mapaComponent.getMap().getView().getProjection(),
                    dataProjection: format.readProjection(input)
                });
                resolve(features);
            } catch (error) {
                console.error("Erro ao processar as geometrias.", error);
                reject(new Error(`Erro ao processar características. ${error.message}`));
            }
        };

        // Ler arquivo ZIP
        if (file.type.includes('zip')) {
            reader.onload = async () => {
                try {
                    const jsonReader = new GeoJSON();
                    const json = await shp(reader.result);
                    processFeatures(json, jsonReader);
                } catch (error) {
                    console.error("Erro ao ler arquivo ZIP. ", error);
                    reject(new Error(`Erro ao ler arquivo ZIP. ${error.message}`));
                }
            };
            reader.onerror = (error) => {
                console.error("Erro do FileReader para arquivo ZIP.", error);
                reject(new Error(`Erro do FileReader para arquivo ZIP. ${error}`));
            };
            reader.readAsArrayBuffer(file);
        }
        // Ler arquivo KML
        else if (file.name.toLowerCase().endsWith('.kml')) {
            reader.onload = () => {
                try {
                    const kml = new KML();
                    processFeatures(reader.result, kml);
                } catch (error) {
                    console.error("Erro ao ler arquivo KML:", error);
                    reject(new Error(`Erro ao ler arquivo KML: ${error.message}`));
                }
            };
            reader.onerror = (error) => {
                console.error("Erro do FileReader para arquivo KML:", error);
                reject(new Error(`Erro do FileReader para arquivo KML: ${error}`));
            };
            reader.readAsText(file);
        }
        else {
            console.error("Tipo de arquivo não suportado:", file.type);
            reject(new Error("Tipo de arquivo não suportado"));
        }
    });
}

// Função para converter uma coordenada de graus decimais para GMS
export function coordParaGMS(coord: [number, number]) {
    const { latDMS, lonDMS } = convertLatLonToDMS(coord[1], coord[0]);
    return { lonDMS, latDMS };
}

// Função para converter WKT para lista de vértices em GMS
export function wktParaVerticesGMS(wktString: string) {
    const wktFormat = new WKT();
    const geometria = wktFormat.readGeometry(wktString, {
        dataProjection: 'EPSG:4674',
        featureProjection: 'EPSG:4674'
    });
    const coordenadas = geometria.getCoordinates();

    // Verifica o tipo de geometria para extrair os vértices corretamente
    let vertices: [number, number][] = [];
    if (geometria.getType() === 'Polygon') {
        vertices = coordenadas[0]; // Para polígonos, pegamos apenas o anel externo
    } else if (geometria.getType() === 'LineString' || geometria.getType() === 'MultiPoint') {
        vertices = coordenadas;
    } else if (geometria.getType() === 'Point') {
        vertices = [coordenadas];
    } else {
        throw new Error('Tipo de geometria não suportado.');
    }

    // Converte os vértices para GMS
    return vertices.map(coord => coordParaGMS(coord));
}

export function toDMS(coordinate, type) {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    const direction = type === 'lat'
        ? coordinate >= 0 ? 'N' : 'S'
        : coordinate >= 0 ? 'E' : 'W';

    return `${degrees}°${minutes}'${seconds}" ${direction}`;
}

export function convertLatLonToDMS(lat, lon) {
    const latDMS = toDMS(lat, 'lat');
    const lonDMS = toDMS(lon, 'lon');
    return { latDMS, lonDMS };
}
