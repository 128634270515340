import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-projeto-subestacao',
    templateUrl: './projeto-subestacao.component.html',
    styleUrls: ['./projeto-subestacao.component.scss']
})
export class ProjetoSubestacaoComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;

    derivaEnergiaLocal: FormControl;

    constructor() {
    }

    ngOnInit(): void {
        this.derivaEnergiaLocal = this.form.get('derivaEnergiaLocal') as FormControl;
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    mudouSelecao(event) {
        if (event) {
            this.form.get('nomeRazaoSocial').setValidators([Validators.required, Validators.maxLength(50)]);
            this.form.get('cnpjCpf').setValidators([Validators.required]);
        } else {
            this.form.get('nomeRazaoSocial').setValidators([]);
            this.form.get('cnpjCpf').setValidators([]);
            this.form.get('licencaAmbiental').setValidators([]);
            this.form.get('nomeRazaoSocial').setValue(undefined);
            this.form.get('cnpjCpf').setValue(undefined);
            this.form.get('licencaAmbiental').setValue(undefined);
        }
        this.form.get('nomeRazaoSocial').updateValueAndValidity();
        this.form.get('cnpjCpf').updateValueAndValidity();
        this.form.get('licencaAmbiental').updateValueAndValidity();

    }
}
