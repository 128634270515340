import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CurrentUser} from '../models/current-user.model';
import {AuthenticatedUserService} from './authenticated-user.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<CurrentUser>;

    public currentUser: Observable<CurrentUser>;
    private currentUserTokenSubject: BehaviorSubject<any>;

    constructor(
        private authenticatedUserService: AuthenticatedUserService
    ) {
        this.refreshUserInfo();
    }

    public get currentUserDecode(): CurrentUser {
        this.refreshUserInfo();
        return this.currentUserSubject.value;
    }

    private refreshUserInfo(): void {
        let currentUser: CurrentUser = null;

        if (this.authenticatedUserService.isAuthenticated) {
            const identityToken = this.authenticatedUserService.identityToken;

            currentUser = new CurrentUser(
                identityToken.sub,
                identityToken.details.nome,
                identityToken.details.servidor
            );
        }

        this.currentUserSubject = new BehaviorSubject<CurrentUser>(currentUser);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        this.refreshUserInfo();

        return this.currentUserTokenSubject.value;
    }
}
