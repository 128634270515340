import {Component, Input} from '@angular/core';
import {Estilo} from "@sema-geo/sema-geoportal/lib/mapa-padrao/models/estilo";
import {TemplateParecerComponent} from "../../template-parecer/template-parecer.component";

@Component({
    selector: 'app-icone-legenda',
    templateUrl: './icone-legenda.component.html',
    styleUrls: ['./icone-legenda.component.scss']
})
export class IconeLegendaComponent {

    @Input()
    titulo: string;

    @Input()
    subtitulo: string;

    @Input() set estilo (value: Estilo){
        if(value){
           this.estiloLegenda = value
        }
    };

    estiloLegenda: Estilo

    public desenhaRetanguloObjeto(estilo: {
        corPreenchimento: string,
        espessuraBorda: number,
        cor: string,
        tipoTracejado: number
    }): string {
        return TemplateParecerComponent.desenhaRetanguloObjeto(estilo, 20, 20);
    }
}