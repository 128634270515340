import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class InformacaoAnm extends BaseModel {
    constructor(
        public id: number,
        public numeroProcesso: string,
        public numeroTituloMinerario: number,
        public dataValidade: Date,
        public areaRequerida: number,
        public areaUtil: number,
        public mineracao: AtividadeMineracao,
    ) {
        super();
    }

    static fromJson(json: any): InformacaoAnm {
        if (json === undefined || json === null) {
            return null;
        }
        return new InformacaoAnm(
            json.id,
            json.numeroProcesso,
            json.numeroTituloMinerario,
            json.dataValidade,
            json.areaRequerida,
            json.areaUtil,
            json.mineracao,
        );
    }

}
