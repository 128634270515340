import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../../../../../environments/environment";
import {EmissaoTitulo} from "../../../../../shared/models/emissao-titulo.model";
import {catchError, map} from "rxjs/operators";
import {RegistrarDecisaoDocumentoDto} from "./registrar-decisao-documento-dto.model";

@Injectable({
    providedIn: 'root'
})
export class OutrosAtosInternosService {
    protected http: HttpClient;
    urlResource = `${environment.URL_GATEWAY}/api/solicitacao-atos-internos`;

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    salvarAtosInternos(atosInternos: any, multipartFile: Blob): Observable<EmissaoTitulo> {
        return this.http.post(
            this.urlResource + '/salvar-solicitacao-ato-interno',
            OutrosAtosInternosService.montarFormData(atosInternos, multipartFile)).pipe(
            map(EmissaoTitulo.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    private static montarFormData(atosInternosDTO: any, multipartFile: Blob): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const atosInternosParam = new Blob(
            [JSON.stringify(atosInternosDTO)],
            {type: 'application/json'}
        );
        formData.append('solicitacaoAtosInternosDTO', atosInternosParam);
        return formData;
    }

    registrarDecisaoNoDocumento(registrarDecisaoDocumentoDto: RegistrarDecisaoDocumentoDto): Observable<String> {
        return this.http.post(this.urlResource + '/registrar-decisao-no-documento', registrarDecisaoDocumentoDto).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
