import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RequerimentoService} from '../../requerimento.service';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {Requerimento} from "../../requerimento.model";
import {ImovelService} from "../../shared/requerimento-localizacao/shared/imovel.service";
import {Imovel} from "../../shared/requerimento-localizacao/shared/imovel.model";
import {RequerimentoNotasComponent} from "../../requerimento-notas/requerimento-notas.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogNotasComponent} from "../dialog-notas/dialog-notas.component";
import {AnaliseRequerimentoService} from "../../../tarefas/shared/service/analise-requerimento.service";
import {take} from "rxjs/operators";
import {AnaliseRequerimentoAnalista} from "../../../tarefas/shared/model/analise-requerimento-analista.model";
import {
    HistoricoAtribuicoesDialogComponent
} from "../../../tarefas-bpms/lic-ambiental/atribuir-requerimento-analista/historico-atribuicoes-dialog/historico-atribuicoes-dialog.component";
import {
    ModalImpedimentosComponent
} from "../../../tarefas-bpms/lic-ambiental/requerimento-analise/modal-impedimentos/modal-impedimentos.component";
import {TarefaService} from "../../../../shared/services/tarefa.service";
import {Tarefa} from "../../../../shared/models/tarefa.model";
import {
    DialogTrocaAssigneeUserTaskComponent
} from "../../gerenciar-requerimentos-processos/dialog-troca-assignee-user-task/dialog-troca-assignee-user-task.component";
import {RedistribuicaoProcessoDTO} from "../../gerenciar-requerimentos-processos/model/redistribuicao-processo-dto";
import {
    DialogHistoricoPriorizacaoComponent
} from "../dialog-historico-priorizacao/dialog-historico-priorizacao.component";
import {DominioDto} from "../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../shared/services/dominios.service";
import {PrioridadeProcesso} from "../../../../shared/models/prioridade-processo.model";
import {PrioridadeProcessoService} from "../../../../shared/services/prioridade-processo.service";

export class DataDetalheRequerimento {

    constructor(
        public id: number
    ) {
    }
}

@Component({
    templateUrl: 'dialog-detalhe-requerimento.component.html',
    styleUrls: ['dialog-detalhe-requerimento.component.scss'],
})
export class DialogDetalheRequerimentoComponent implements OnInit {

    imovel: Imovel | void;
    requerimento: Requerimento;
    analistas: AnaliseRequerimentoAnalista[] = [];
    tarefas: Tarefa[] = [];
    prioridade: PrioridadeProcesso;
    listaCriterios: DominioDto[] = [];
    displayedColumnsAtribuicao: string[] = ['analista', 'data', 'andamento', 'atribuido'];
    displayedColumnsTarefas: string[] = ['bk', 'fluxo', 'tarefa', 'envio', 'usuario', 'acoes'];

    constructor(
        private readonly dominiosService: DominiosService,
        private dialogRef: MatDialogRef<DialogDetalheRequerimentoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DataDetalheRequerimento,
        private snackBarService: SnackBarService,
        private prioridadeProcessoService: PrioridadeProcessoService,
        private analiseRequerimentoService: AnaliseRequerimentoService,
        private requerimentoService: RequerimentoService,
        private imovelService: ImovelService,
        private tarefaService: TarefaService,
        private dialog: MatDialog
    ) {
    }

    async ngOnInit(): Promise<void> {
        if (this.data) {
            await this.consultar();
        }

        this.listaCriterios = await this.dominiosService.getDominio('TIPO_PRIORIDADE_PROCESSO').toPromise();
        this.prioridadeProcessoService.getByRequerimentoId(this.requerimento.id).pipe(
            take(1)
        ).subscribe(prioridade => this.prioridade = prioridade);
    }

    private async consultar(): Promise<void> {
        this.requerimento = await this.requerimentoService.getById(this.data.id).toPromise().catch(error => {
            this.snackBarService.showError('Erro ao recuperar o requerimento: ' + error.error.errors[0]);
            this.closeDialog();
        }) as Requerimento;

        if (this.requerimento) {
            this.imovelService.getByRequerimento(this.requerimento).pipe(
                take(1)
            ).subscribe(imovel => this.imovel = imovel);
            this.analiseRequerimentoService.readEquipeByRequerimentoId(this.data.id).pipe(
                take(1)
            ).subscribe(
                resp => this.analistas = resp.analistas || [],
                () => this.analistas = []
            );

            this.tarefaService.getByRequerimentoId(this.requerimento.id).pipe(
                take(1)
            ).subscribe(tarefas => this.tarefas = tarefas);
        }
    }

    get nomeImovel(): string {
        if (this.imovel) {
            if (this.imovel.tipo == 'URBANO') {
                return this.imovel.denominacao;
            } else if (this.imovel.tipo == 'OBRA') {
                return this.imovel.denominacao || this.imovel.atividade;
            } else if (this.imovel.tipo == 'RURAL') {
                return this.imovel.numeroCAR;
            }
        }

        return '';
    }

    openNotas(): void {
        this.dialog.open(DialogNotasComponent, {
            data: {
                component: RequerimentoNotasComponent,
                inputs: {
                    requerimentoId: this.data.id
                }
            },
            width: '70%'
        });
    }

    openDialogHistorico(): void {
        this.dialog.open(DialogHistoricoPriorizacaoComponent, {
            data: {
                requerimento: this.requerimento
            }
        }).afterClosed().subscribe(result => {
            if (result) {
                this.closeDialog();
            }
        });
    }

    openDialogImpedimentos(analista: AnaliseRequerimentoAnalista): void {
        this.dialog.open(ModalImpedimentosComponent, {
            data: {
                analista: analista,
                requerimento: this.requerimento
            }
        });
    }

    openHistoricoAtribuicoes(): void {
        this.dialog.open(HistoricoAtribuicoesDialogComponent, {
            width: '80%',
            maxHeight: '500px',
            data: this.data.id
        });
    }

    public closeDialog(): void {
        this.dialogRef.close(false);
    }

    trocarAssigneeTarefa(): void {
        let redistribuicaoProcessoDTO = new RedistribuicaoProcessoDTO();
        redistribuicaoProcessoDTO.idsRequerimento = [this.requerimento.id];

        const dialogRef = this.dialog.open(DialogTrocaAssigneeUserTaskComponent, {
            width: '800px',
            data: {
                redistribuicaoProcessoDTO: redistribuicaoProcessoDTO
            }
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (result === 'update') {
                await this.consultar();
            }
        });
    }

    getPrioridades(): string {
        if (this.prioridade) {
            return this.prioridade.criterios.map(x => this.listaCriterios.find(d => d.id == x.tipoPrioridade))
            .filter(x => !!x).map(d => d.descricao).join(', ') || 'Atendimento normal';
        }

        return "Atendimento normal";
    }
}