import { BaseModel } from "./base.model";

export class BitolaTipoCondutor extends BaseModel {
    constructor(
        public id: number,
        public idDominio: number,
        public idEntidade: number,
        public tipo: string,
        public bitola: number,
        public secao: number,
    ) {
        super();
    }

    static fromJson(json: any): BitolaTipoCondutor {
        if (json === undefined || json === null) {
            return null;
        }
        return new BitolaTipoCondutor(
            json.id,
            json.idDominio,
            json.idEntidade,
            json.tipo,
            json.bitola,
            json.secao
        );
    }

}
