import {Injectable, Injector} from '@angular/core';
import {UnidadeContingencia} from '../models/unidade-contingencia.model';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {EmpreendimentoEnergiaEolicaTecnologia} from "../models/empreendimento-energia-eolica-tecnologia.model";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class UnidadeContingenciaService extends BaseService<UnidadeContingencia> {

    constructor(protected injector: Injector) {
        super(
            "/api/unidade-contigencia",
            injector,
            UnidadeContingencia.prototype,
            UnidadeContingencia.fromJson
        );
    }

    getByEmpreendimento(idEmpreendimento: number): Observable<Array<UnidadeContingencia>> {
        const url = `${this.urlEeEolica}/empreendimento/${idEmpreendimento}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}

