import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {RequerimentoReaproveitamento} from '../models/requerimento-reaproveitamento.model';

@Injectable({
    providedIn: 'root'
})
export class RequerimentoReaproveitamentoService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_GATEWAY;

    salvaReaproveitamento(reaproveitamento: RequerimentoReaproveitamento): Observable<RequerimentoReaproveitamento> {
        const url = `${this.urlResource}/api/requerimento-reaproveitamento`;
        return this.http.post(url, reaproveitamento)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    retornaReaproveitamentoExistente(idRequerimento: number): Observable<RequerimentoReaproveitamento> {
        const url = `${this.urlResource}/api/requerimento-reaproveitamento/${idRequerimento}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    atualizarReaproveitamento(reaproveitamento: RequerimentoReaproveitamento): Observable<RequerimentoReaproveitamento> {
        const url = `${this.urlResource}/api/requerimento-reaproveitamento/atualiza-reaproveitamento`;
        return this.http.put(url, reaproveitamento)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarReaproveitamentoExistente(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/api/requerimento-reaproveitamento/deleta-reaproveitamento/${idRequerimento}`;
        return this.http.delete(url)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

}
