import {BaseModel} from 'app/main/shared/models/base.model';
import {TipoSistemaCultivo} from '../../../../../../shared/models/tipo-sistema-cultivo.model';
import {TipoCorpoHidrico} from 'app/main/shared/models/tipo-corpo-hidrico.model';
import {AtividadeAquicultura} from '../../../../../../shared/models/atividade-aquicultura.model';

export class AquiculturaTanqueRede extends BaseModel {
    constructor(
        public id?: number,
        public tipoCorpoHidrico?: TipoCorpoHidrico,
        public outroCorpoHidrico?: string,
        public areaPoligonal?: number,
        public profundidadeMedia?: number,
        public tipoSistemaCultivo?: TipoSistemaCultivo,
        public outroSistema?: string,
        public atividadeAquicultura?: AtividadeAquicultura,
        public outraAtividadeAquicultura?: string,
        public especies?: string,
        public quantidadeTanque?: number,
        public quantidadeFosforo?: number,
    ) {
        super();
    }

    static fromJson(json: any): AquiculturaTanqueRede {
        if (json === undefined || json === null) {
            return null;
        }
        return new AquiculturaTanqueRede(
            json.id,
            TipoCorpoHidrico.fromJson(json.tipoCorpoHidrico),
            json.outroCorpoHidrico,
            json.areaPoligonal,
            json.profundidadeMedia,
            TipoSistemaCultivo.fromJson(json.tipoSistemaCultivo),
            json.outroSistema,
            AtividadeAquicultura.fromJson(json.atividadeAquicultura),
            json.outraAtividadeAquicultura,
            json.especies,
            json.quantidadeTanque,
            json.quantidadeFosforo,
        );
    }

}
