import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {RequerimentoLicenciamento} from '../requerimento-licenciamento.model';
import {FormularioReqLicenciamentoDto} from '../../../../shared/models/formulario-req-licenciamento.dto';
import {FormularioReqLicenciamentoEmp} from "../../../../shared/models/formulario-req-licenciamento-emp.dto";

@Injectable({
    providedIn: 'root'
})
export class RequerimentoFormularioLicenciamentoService extends BaseService<RequerimentoLicenciamento> {
    constructor(protected injector: Injector) {
        super(
            '/api/requerimento-licenciamento',
            injector,
            FormularioReqLicenciamentoDto.prototype,
            FormularioReqLicenciamentoDto.fromJson
        );
    }

    getByRequerimentoLicenciamentoRequerimentoId(idRequerimento: number): Observable<FormularioReqLicenciamentoDto> {
        const url = `${this.urlResource}/requerimento-licenciamento-formulario/${idRequerimento}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getByRequerimentoLicenciamentoRequerimentoEmpId(idRequerimento: number): Observable<FormularioReqLicenciamentoEmp> {
        const url = `${this.urlResource}/requerimento-licenciamento-emp/${idRequerimento}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    salvarFormularioCalculoDeLicenca(formulario: FormularioReqLicenciamentoDto): Observable<FormularioReqLicenciamentoDto> {
        const url = `${this.urlResource}/salvar-formulario-req-licenciamento`;
        return this.http
            .post(url, formulario)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    salvarFormularioCalculoDeLicencaEmp(formulario: FormularioReqLicenciamentoEmp): Observable<FormularioReqLicenciamentoEmp> {
        const url = `${this.urlResource}/salvar-formulario-req-licenciamento-emp`;
        return this.http
            .post(url, formulario)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    deleteEmp(id: number): Observable<any> {
        const url = `${this.urlResource}/req-emp/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
}
