import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MessageDialogButtons, MessageDialogResult} from './message-dialog.model';

function canShowButton(data, button): boolean {
    return data && ((data.buttons & button) === button);
}

@Component({
    selector: 'app-message-dialog.component',
    styleUrls: ['./message-dialog.component.scss'],
    templateUrl: './message-dialog.component.html'
})
export class MessageDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }

    get canShowCancel(): boolean {
        return canShowButton(this.data, MessageDialogButtons.Cancel);
    }

    get canShowOK(): boolean {
        return canShowButton(this.data, MessageDialogButtons.OK);
    }

    get canShowNo(): boolean {
        return canShowButton(this.data, MessageDialogButtons.No);
    }

    get canShowYes(): boolean {
        return canShowButton(this.data, MessageDialogButtons.Yes);
    }

    cancel(): void {
        this.dialogRef.close(MessageDialogResult.Cancel);
    }

    ok(): void {
        this.dialogRef.close(MessageDialogResult.OK);
    }

    no(): void {
        this.dialogRef.close(MessageDialogResult.No);
    }

    yes(): void {
        this.dialogRef.close(MessageDialogResult.Yes);
    }
}