import {BaseModel} from "../../../../../shared/models/base.model";
import {ModelUtil} from "../../../../../shared/models/model-util";
import {IrrigacaoCultura} from "./irrigacao-cultura.model";

export class IrrigacaoCulturaMensal extends BaseModel {
    constructor(
        public irrigacaoCultura?: IrrigacaoCultura,
        public mes?: string,
        public precipitacao?: number,
        public evapotranspiracao?: number,
        public coeficienteCultura?: number,
        public coeficienteAjuste?: number,
    ) {
        super();
    }

    /**
     * Evapotranspiração da cultura
     */
    get etc() {
        return this.evapotranspiracao * this.coeficienteCultura * this.coeficienteAjuste;
    }

    /**
     * Lâmina bruta de irrigação (mm)
     */
    public laminaBruta(eficienciaIrrigacao: number) {
        return (this.etc - this.precipitacao) * (100 / eficienciaIrrigacao);
    }

    /**
     * Volume de irrigação
     */
    public volume(eficienciaIrrigacao: number, areaIrrigada) {
        return this.laminaBruta(eficienciaIrrigacao) * 10 * areaIrrigada;
    }

    static fromJson(json: any): IrrigacaoCulturaMensal {
        if (json === undefined || json === null) {
            return null;
        }
        return new IrrigacaoCulturaMensal(
            ModelUtil.getOrElseNull(IrrigacaoCultura.fromJson(json.irrigacaoCultura)),
            ModelUtil.getOrElseNull(json.mes),
            ModelUtil.getOrElseNull(json.precipitacao),
            ModelUtil.getOrElseNull(json.evapotranspiracao),
            ModelUtil.getOrElseNull(json.coeficienteCultura),
            ModelUtil.getOrElseNull(json.coeficienteAjuste),
        );
    }

    static fromJsons(json: any): IrrigacaoCulturaMensal[] {
        const array: IrrigacaoCulturaMensal[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(IrrigacaoCulturaMensal.fromJson(item)));
            return array;
        }
    }
}
