import {Injectable, Injector} from '@angular/core';
import {PostoFluviometrico} from '../models/posto-fluviometrico.model';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {ObraPostoFluviometrico} from '../models/obra-posto-fluviometrico.model';
import {catchError, map} from 'rxjs/operators';
import {ObraBarragem} from '../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/objetivo-obra-barragem/obra-barragem.model';

@Injectable({
    providedIn: 'root'
})
export class PostoFluviometricoService extends BaseService<PostoFluviometrico> {
    constructor(protected injector: Injector) {
        super('/api/postos-fluviometricos', injector, PostoFluviometrico.prototype, PostoFluviometrico.fromJson);
    }

    createObraPostoFluviometrico(obraPostoFluviometrico: ObraPostoFluviometrico): Observable<ObraPostoFluviometrico> {
        return this.http.post(this.urlResource + '/obra-barragem', obraPostoFluviometrico)
            .pipe(
                map(value => ObraPostoFluviometrico.fromJson(value)),
                catchError(this.handleError.bind(this))
            );
    }

    updateObraPostoFluviometrico(obraPostoFluviometrico: ObraPostoFluviometrico): Observable<ObraPostoFluviometrico> {
        const url = `${this.urlResource}/obra-barragem`;
        return this.http.put(url, obraPostoFluviometrico)
            .pipe(
                map(value => ObraPostoFluviometrico.fromJson(value)),
                catchError(this.handleError.bind(this))
            );
    }

    getAllByObraBarragem(obraBarragem: ObraBarragem): Observable<ObraPostoFluviometrico[]> {
        const url = `${this.urlResource}/obra-barragem/${obraBarragem.id}`;
        return this.http.get(url).pipe(
            map(value => ObraPostoFluviometrico.fromJsons(value)),
            catchError(this.handleError.bind(this))
        );
    }

    deleteObraPostoFluviometrico(obraBarragem: ObraBarragem, postoFluviometrico: PostoFluviometrico): Observable<PostoFluviometrico> {
        const url = `${this.urlResource}/obra-barragem/${obraBarragem.id}/${postoFluviometrico.id}`;
        return this.http.delete(url).pipe(
            map(value => ObraPostoFluviometrico.fromJson(value)),
            catchError(this.handleError.bind(this))
        );
    }

}
