import {BaseModel} from 'app/main/shared/models/base.model';
import {TipoLancamento} from './../../../../../tipos-lancamento/tipo-lancamento.model';

export class LancamentoEfluente extends BaseModel {
    constructor(
        public id?: number,
        public tipoTratamento?: string,
        public eficienciaProjeto?: number,
        public eficienciaReal?: number,
        public tipoLancamento?: TipoLancamento
    ) {
        super();
    }

    static fromJson(json: any): LancamentoEfluente {
        if (json === undefined || json === null) {
            return null;
        }
        return new LancamentoEfluente(
            json.id ? json.id : null,
            json.tipoTratamento ? json.tipoTratamento : null,
            json.eficienciaProjeto ? json.eficienciaProjeto : null,
            json.eficienciaReal ? json.eficienciaReal : null,
            json.tipoLancamento ? TipoLancamento.fromJson(json.tipoLancamento) : null
        );
    }
}
