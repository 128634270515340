import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-confirmar-alteracao-interessado-dialog',
    templateUrl: './confirmar-alteracao-interessado-dialog.component.html'
})
export class ConfirmarAlteracaoInteressadoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarAlteracaoInteressadoDialogComponent>
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
