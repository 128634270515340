import {EnumDecisaoSituacao} from "../enums/enum-decisao-situacao.enum";
import {Despacho} from "../components/despacho-cabecalho/despacho.model";
import {Requerimento} from "../../pages/requerimentos/requerimento.model";
import {BaseModel} from "./base.model";
import {BaseEnum} from "../enums/base.enum";
import {EnumAnaliseConsema} from "../enums/EnumSituacaoAnaliseConsema";


export class AnaliseConsema extends BaseModel{
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public despacho?: Despacho,
        public dataConclusao?: Date,
        public decisao?: String
    ) {
        super();
    }

    static decisao: BaseEnum[] = [
        {valor: 'PARECER_TECNICO_REFERENDADO', descricao: 'Parecer técnico aprovado'},
        {valor: 'PARECER_TECNICO_NAO_REFERENDADO_REVISAO', descricao: 'Parecer técnico reprovado, solicito revisão'},
        {valor: 'PARECER_TECNICO_NAO_REFERENDADO_ARQUIVAR', descricao: 'Parecer técnico reprovado, solicito arquivamento definitivo'},
    ]

    static fromJson(json: any): AnaliseConsema {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnaliseConsema(
            json.id,
            Requerimento.fromJson(json.requerimento),
            Despacho.fromJson(json.despacho),
            json.dataConclusao,
            json.decisao
        )
    }

    static fromJsons(json: any): AnaliseConsema[] {
        const resources: AnaliseConsema[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: AnaliseConsema) => resources.push(AnaliseConsema.fromJson(e)));
        }
        return resources;
    }
}