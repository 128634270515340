import {BaseModel} from "../base.model";
import {Validacao} from "./validacao.model";

export class ValidacaoGeometria extends BaseModel {

    constructor(
        public srid: number,
        public wkt: string,
        public geoJson: string,
        public gerais: Validacao[],
        public especificas: Validacao[],
        public validacoes: Validacao[],
        public tipo: string,
        public valido: boolean
    ) {
        super();
    }

    static fromJson(json: any): ValidacaoGeometria {
        if (json === undefined || json === null) {
            return null;
        }
        return new ValidacaoGeometria(
            json.srid,
            json.wkt,
            json.geoJson,
            json.gerais,
            json.especificas,
            json.validacoes,
            json.tipo,
            json.valido
        );
    }
}