import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {FeicaoAnaliseRequerimento} from './feicao-analise-requerimento.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Requerimento} from '../requerimentos/requerimento.model';
import {FeicaoGeometriaDto} from '../../shared/models/feicao-geometria-dto.model';
import {FeicaoAnaliseGeometriaDto} from '../../shared/models/feicao-analise-geometria-dto.model';

@Injectable({
    providedIn: 'root'
})
export class FeicaoAnaliseRequerimentoService extends BaseService<FeicaoAnaliseRequerimento> {

    private static montarFormData(
        feicaoRequerimentoDTO: any,
        metodoIncluirGeometria: string,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        formData.append('metodo', metodoIncluirGeometria);
        const feicaoRequerimentoParam = new Blob(
            [JSON.stringify(feicaoRequerimentoDTO)],
            {type: 'application/json'}
        );
        formData.append('feicaoRequerimentoDTO', feicaoRequerimentoParam);
        return formData;
    }

    constructor(protected injector: Injector) {
        super(
            '/api/feicoes-analise-requerimentos',
            injector,
            FeicaoAnaliseRequerimento.prototype,
            FeicaoAnaliseRequerimento.fromJson
        );
    }

    getFeatureCollectionFromRequerimento(idRequerimento: number): Observable<any> {
        return this.http.get(`${this.urlResource}/feicoes/${idRequerimento}`);
    }

    getByRequerimento(requerimento: Requerimento): Observable<FeicaoAnaliseGeometriaDto[]> {
        const url = `${this.urlResource}/requerimento/${requerimento.id}`;
        return this.http.get(url).pipe(
            map(FeicaoAnaliseGeometriaDto.fromJsons.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    createMultipart(
        feicaoRequerimento: FeicaoAnaliseRequerimento,
        metodoIncluirGeometria: string,
        multipartFile: Blob
    ): Observable<FeicaoAnaliseRequerimento> {
        return this.http.post(this.urlResource, FeicaoAnaliseRequerimentoService.montarFormData({
                requerimentoId: feicaoRequerimento.requerimento.id
            },
            metodoIncluirGeometria,
            multipartFile
        )).pipe(
            map(FeicaoAnaliseRequerimento.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    updateMultipart(
        feicaoRequerimento: FeicaoAnaliseRequerimento,
        metodoIncluirGeometria: string,
        multipartFile: Blob
    ): Observable<FeicaoAnaliseRequerimento> {
        const url = `${this.urlResource}/${feicaoRequerimento.id}`;
        return this.http.put(url, FeicaoAnaliseRequerimentoService.montarFormData({
                id: feicaoRequerimento.id,
                requerimentoId: feicaoRequerimento.requerimento.id
            },
            metodoIncluirGeometria,
            multipartFile
            )
        ).pipe(
            map(() => feicaoRequerimento),
            catchError(this.handleError.bind(this))
        );
    }

    adicionarGeometria(feicaoAnaliseGeometriaDto: FeicaoAnaliseGeometriaDto): any {
        const url = `${this.urlResource}/geometria`;
        return this.http.post(url, feicaoAnaliseGeometriaDto).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    atualizarGeometria(id: number, feicaoAnaliseGeometriaDto: FeicaoAnaliseGeometriaDto): any {
        const url = `${this.urlResource}/geometria/${id}`;
        return this.http.put(url, feicaoAnaliseGeometriaDto).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    atualizarDadosFeicao(feicaoRequerimento: FeicaoAnaliseRequerimento): any {
        const url = `${this.urlResource}/${feicaoRequerimento.id}`;
        return this.http.put(url, feicaoRequerimento).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    excluirGeometria(idFeicao: number): any {
        const url = `${this.urlResource}/deletar-geometria-correcao/${idFeicao}`;
        return this.http.delete(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getByRequerimentoLac(requerimento: Requerimento): Observable<FeicaoGeometriaDto> {
        const url = `${this.urlResource}/requerimento-licenciamento/${requerimento.id}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    limparPontos(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/limpar-pontos/${idRequerimento}`;
        return this.http.delete(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}
