import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FinalidadeService} from '../finalidade.service';
import {HttpParams} from '@angular/common/http';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';
import {Finalidade} from '../finalidade.model';

@Component({
    selector: 'app-finalidade-list',
    templateUrl: './finalidade-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class FinalidadeListComponent extends BaseListComponent<Finalidade> {

    descricao: string;

    constructor(
        injector: Injector,
        service: FinalidadeService,
    ) {
        super(injector, ['id', 'descricao', 'exigeVistoriaTecnica', 'buttons'], service);
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        if (typeof this.descricao !== 'undefined' && this.descricao.length > 0) {
            httpParams = httpParams.set('descricao', this.descricao);
        }
        return httpParams;
    }

}