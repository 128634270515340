import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirmacao-dialog',
    templateUrl: './confirmacao-dialog.component.html',
    styleUrls: ['./confirmacao-dialog.component.scss']
})
export class ConfirmacaoDialogComponent {

    constructor(public dialogRef: MatDialogRef<ConfirmacaoDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    cancelar(): void {
        this.dialogRef.close(false);
    }
}
