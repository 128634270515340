import {BaseModel} from 'app/main/shared/models/base.model';
import {RequerimentoLicenciamento} from '../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model';
import {LancamentoTaxaParamCalculoDto} from './lancamento-taxa-param-calculo.dto';
import {LancamentoTaxaDto} from './lancamento-taxa-dto.model';


export class FormularioReqLicenciamentoDto extends BaseModel {

    constructor(
        public requerimentoLicenciamento: RequerimentoLicenciamento,
        public lancamentoTaxa?: LancamentoTaxaDto,
        public lancamentoTaxaParamCalculoList?: LancamentoTaxaParamCalculoDto[],
        public lancamentoTaxaSemParam?: boolean
    ) {
        super();
    }

    static fromJson(json: any): FormularioReqLicenciamentoDto {
        if (json === undefined || json === null) {
            return null;
        }
        return new FormularioReqLicenciamentoDto(
            RequerimentoLicenciamento.fromJson(json.requerimentoLicenciamento),
            LancamentoTaxaDto.fromJson(json.lancamentoTaxa),
            LancamentoTaxaParamCalculoDto.fromJsons(json.lancamentoTaxaParamCalculoList),
        );
    }


}
