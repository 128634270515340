import {BaseModel} from 'app/main/shared/models/base.model';
import {RegrasGeoRequerimentoAtividade} from "./regras-geo-requerimento-atividade.model";
import {RegrasGeoRequerimentoFeicao} from "./regra-geo-requerimento-feicao.model";
import {RegrasGeoRequerimentoObjetivo} from "./regra-geo-requerimento-objetivo.model";

export class RegrasGeoRequerimento extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public idTipoProcesso?: number,
        public idSetorResponsavel?: string,
        public excluido?: boolean,
        public atividades?: RegrasGeoRequerimentoAtividade[],
        public feicoes?: RegrasGeoRequerimentoFeicao[],
        public objetivos?: RegrasGeoRequerimentoObjetivo[]
    ) {
        super();
    }

    static fromJson(json: any): RegrasGeoRequerimento {
        if (json === undefined || json === null) {
            return null;
        }

        return new RegrasGeoRequerimento(
            json.id,
            json.descricao,
            json.idTipoProcesso,
            json.idSetorResponsavel,
            json.excluido,
            json.atividades,
            json.feicoes,
            json.objetivos
        );
    }
}
