import {Component, Input, OnInit} from '@angular/core';
import {RequerimentoOutorgaFinalidade} from '../../../models/requerimento-outorga-finalidade.model';
import {CaracterizacaoConsumoAgua} from '../../../models/caracterizacao-consumo-agua.model';
import {CaracterizacaoConsumoAguaService} from '../../../services/caracterizacao-consumo-agua.service';

@Component({
    selector: 'app-aba-finalidade-industria',
    templateUrl: './aba-finalidade-industria.component.html'
})

export class AbaFinalidadeIndustriaComponent implements OnInit {
    @Input() requerimentoOutorgaFinalidade: RequerimentoOutorgaFinalidade;

    caracterizacoesConsumoAgua: CaracterizacaoConsumoAgua[];

    constructor(private caracterizacaoConsumoAguaService: CaracterizacaoConsumoAguaService) {
    }

    ngOnInit(): void {
        this.listarCaracterizacoesConsumoAgua();
    }

    private listarCaracterizacoesConsumoAgua(): void {
        if (this.requerimentoOutorgaFinalidade.industria && this.requerimentoOutorgaFinalidade.industria.id) {
            this.caracterizacaoConsumoAguaService.getAllByIndustria(this.requerimentoOutorgaFinalidade.industria)
                .subscribe(caracterizacoesConsumoAgua => this.caracterizacoesConsumoAgua = caracterizacoesConsumoAgua);
        }
    }

}


