import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../snack-bar/snack-bar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GeometriaMapa} from '@sema-geo/sema-geoportal';
import {
    FeicaoAnaliseRequerimentoService
} from "../../../../pages/feicoes-analise-requerimentos/feicao-analise-requerimento.service";
import {ModeloFeicaoDTO} from "../../../models/modelo-feicao-dto.model";
import {DominiosService} from '../../../services/dominios.service';

export interface DialogEditarFeicaoUPAData {
    denomicacao: string;
    idModeloFeicao: number;
    modeloFeicaoDTO: ModeloFeicaoDTO;
    tamanho: number;
    geometria: GeometriaMapa;
    visualizacao: boolean;
    onRemoveFeature: (geometria: GeometriaMapa) => void;
}

@Component({
    selector: 'app-dialog-editar-feicao',
    templateUrl: './dialog-edicao-feicao-upa.component.html',
    styleUrls: ['./dialog-edicao-feicao-upa.component.scss'],
})
export class DialogEdicaoFeicaoUpaComponent {

    form: FormGroup;

    geometria: GeometriaMapa;

    tamanho: number;

    onRemoveFeature: (geometria: GeometriaMapa) => void;

    tipoDuracaoCiclo: any = [];

    tipoCicloAtual: any = [];

    constructor(
        private formBuilder: FormBuilder,
        private feicaoAnaliseRequerimentoService: FeicaoAnaliseRequerimentoService,
        private snackBarService: SnackBarService,
        private dialogRef: MatDialogRef<DialogEditarFeicaoUPAData>,
        private dominiosService: DominiosService,
        @Inject(MAT_DIALOG_DATA) protected data: DialogEditarFeicaoUPAData
    ) {
        this.data.visualizacao = this.data.visualizacao ? this.data.visualizacao : false;
        this.onRemoveFeature = data.onRemoveFeature;
        this.tamanho = data.tamanho;
        this.geometria = data.geometria;

        this.form = this.formBuilder.group({
            denomicacao: [null, [Validators.required, Validators.maxLength(this.tamanho)]],
            ano: [null, [Validators.required, Validators.maxLength(4)]],
            duracaoCiclo: [null, [Validators.required]],
            cicloAtual: [null, [Validators.required]],
        });

        this.form.get('denomicacao').setValue(data.denomicacao);
        if (this.data.hasOwnProperty('modeloFeicaoDTO') && this.data.modeloFeicaoDTO && this.data.modeloFeicaoDTO.modeloFeicaoUpa){
            this.form.get('ano').setValue(data.modeloFeicaoDTO.modeloFeicaoUpa.ano)
            this.form.get('duracaoCiclo').setValue(data.modeloFeicaoDTO.modeloFeicaoUpa.duracaoCiclo)
            this.form.get('cicloAtual').setValue(data.modeloFeicaoDTO.modeloFeicaoUpa.cicloAtual)
        }
        if(this.data.visualizacao){
            this.form.disable()
        }

        this.dominiosService.getAllTipoDuracaoCiclo().subscribe( retorno => {
            this.tipoDuracaoCiclo = retorno;
        }, error => {
            this.snackBarService.showError("Ocorreu um erro ao buscar a lista de tipo duração do ciclo. Cod. Erro: " + error.status);
        })

        this.dominiosService.getAllTipoCicloAtual().subscribe( retorno => {
            this.tipoCicloAtual = retorno;
        }, error => {
            this.snackBarService.showError("Ocorreu um erro ao buscar a lista de tipo ciclo atual. Cod. Erro: " + error.status);
        })
    }

    onCancel(): void {
        this.dialogRef.close({
            success: false
        });
    }

    onRemover(): void {
        this.onRemoveFeature(this.geometria);
        this.dialogRef.close({
            success: false
        });
    }

    salvar(): void {
        this.dialogRef.close({
            success: true,
            geometria: this.geometria,
            feicao: {
                denominacao: this.form.get('denomicacao').value,
                modeloFeicaoDTO: {
                    idModeloFeicao: this.data.idModeloFeicao,
                    modeloFeicaoUpa: {
                        ano: Number(this.form.get('ano').value),
                        duracaoCiclo: this.form.get('duracaoCiclo').value,
                        cicloAtual: this.form.get('cicloAtual').value,
                    }
                }
            }
        });
    }
}
