import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {DominiosService} from '../../../../shared/services/dominios.service';
import {TratamentoEfluentesService} from '../../../../shared/services/tratamento-efluentes.service';
import {TratamentoEfluentes} from '../../../../shared/models/tratamento-efluentes.model';

@Component({
    selector: 'app-tratamento-efluentes',
    templateUrl: './tratamento-efluentes.component.html',
    styleUrls: ['./tratamento-efluentes.component.scss']
})
export class TratamentoEfluentesComponent implements OnInit {

    tratamentoEfluentesForm: FormGroup;
    tipoTratamentoFeicao: any;
    tipoImpermeabilizacao: any;

    // : TODO Esses Parametros devem chegar atraves do component Pai
    idGeometria = 10;

    constructor(private dominiosService: DominiosService,
                private formBuilder: FormBuilder,
                private snackBar: SnackBarService,
                private tratamentoEfluentesService: TratamentoEfluentesService) {
    }

    ngOnInit() {
        this.tratamentoEfluentesBuilder();
        this.carregarTipos().then();
    }

    private tratamentoEfluentesBuilder(): void {
        this.tratamentoEfluentesForm = this.formBuilder.group({
            id: [null],
            idTipoTratamentoFeicao: [null, Validators.required],
            volume: [null, Validators.required],
            idTipoImpermeabilizacao: [null, Validators.required],
        });
    }

    async carregarTipos(): Promise<void> {
        this.tipoTratamentoFeicao = await this.dominiosService.getDominio('TIPO_TRATAMENTO_FEICAO').toPromise();

        this.dominiosService.getDominio('TIPO_IMPERMEABILIZACAO').subscribe(tipoImpermeabilizacao => {
            this.tipoImpermeabilizacao = tipoImpermeabilizacao.filter(tipo => tipo.id !== 4);
        });
    }

    salvar(): void {
        const validate = this.validaForm();
        const tratamentoEfluentes = new TratamentoEfluentes();
        if (validate) {
            tratamentoEfluentes.idGeometria = this.idGeometria;
            tratamentoEfluentes.idTipoTratamento = this.tratamentoEfluentesForm.value.idTipoTratamentoFeicao;
            tratamentoEfluentes.volume = this.tratamentoEfluentesForm.value.volume;
            tratamentoEfluentes.idTipoImpermeabilizacao = this.tratamentoEfluentesForm.value.idTipoImpermeabilizacao;

            this.tratamentoEfluentesService.createTratamentoEfluentes(tratamentoEfluentes).subscribe(() => {
                this.snackBar.showSuccess('Tratamento Efluentes salvo com sucesso.');
            });
            this.tratamentoEfluentesForm.reset();
        } else {
            this.tratamentoEfluentesForm.markAllAsTouched();
            this.snackBar.showError('Campo(s) obrigatório(s) não' +
                ' preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    validaForm(): boolean {
        return this.tratamentoEfluentesForm.valid;
    }
}
