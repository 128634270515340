import {BaseModel} from '../base.model';
import {AtividadeCalculoArrecadacao} from './atividade-calculo-arrecadacao.model';
import {TipoCondicaoArrecadacao} from './tipo-condicao-arrecadacao.model';
import {ParametroEntradaArrecadacao} from './parametro-entrada-arrecadacao.model';

export class AtividadeCalculoParametroEntradaArrecadacao extends BaseModel {

    constructor(
        public id?: number,
        public atividadeCalculo?: AtividadeCalculoArrecadacao,
        public parametroEntrada?: ParametroEntradaArrecadacao,
        public tipoCondicao?: TipoCondicaoArrecadacao,
        public valorMinimo?: number,
        public valorMaximo?: number,
    ) {
        super();
    }

    static fromJson(json: any): AtividadeCalculoParametroEntradaArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new AtividadeCalculoParametroEntradaArrecadacao(
            json.id,
            AtividadeCalculoArrecadacao.fromJson(json.atividadeCalculo),
            ParametroEntradaArrecadacao.fromJson(json.parametroEntrada),
            TipoCondicaoArrecadacao.fromJson(json.tipoCondicao),
            json.valorMinimo,
            json.valorMaximo,
        );
    }
}
