import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AbaDocumentoProcessoDTO} from './aba-documento-processo-dto.model';
import {EnumGrupoDocumento} from "../../../../shared/enums/EnumGrupoDocumento";

@Injectable({
    providedIn: 'root'
})
export class AbaDocumentoProcessoRequerimentoService {
    protected http: HttpClient;
    urlResource = `${environment.URL_GATEWAY}/api/documento-processo`;

    constructor(protected injector: Injector) {
        this.http = injector.get(HttpClient);
    }

    getByRequerimentoId(requerimentoId: number): Observable<AbaDocumentoProcessoDTO[]> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}/list`;
        return this.http.get(url).pipe(
            map(response => AbaDocumentoProcessoDTO.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getByRequerimentoLegadoId(requerimentoId: number): Observable<AbaDocumentoProcessoDTO[]> {
        const url = `${this.urlResource}/requerimento-legado/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(response => AbaDocumentoProcessoDTO.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getVisualizarDocumentoPath(doc: AbaDocumentoProcessoDTO): string {
        return `${this.urlResource}/visualizar/${doc.id}`;
    }

    getBaixarDocumentoPath(doc: AbaDocumentoProcessoDTO): string {
        return `${this.urlResource}/${doc.chave}/pdf`;
    }

    buscarTitulosDocumentosEmitidos(requerimentoId: number): Observable<AbaDocumentoProcessoDTO[]> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}/titulos-documentos-emitidos`;
        return this.http.get(url).pipe(
            map(response => AbaDocumentoProcessoDTO.fromJsons(response)),
            catchError(this.handleError.bind(this))
        );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
