import {BaseModel} from 'app/main/shared/models/base.model';

export class ResidenciaisComerciais extends BaseModel {
    constructor(
        public id?: number,
        public residencial?: boolean,
        public comercial?: boolean,
        public industrial?: boolean,
        public educacional?: boolean,
        public vertical?: boolean,
        public horizontal?: boolean,
        public numeroUnidadesResidenciais?: number,
        public numeroUnidadesComerciais?: number,
        public numeroUnidadesIndustriais?: number,
        public numeroLotesEquipamentoPublico?: number,
        public numeroHabitantesOcupantes?: number
    ) {
        super();
    }

    static fromJson(json: any): ResidenciaisComerciais {
        if (json === undefined || json === null) {
            return null;
        }
        return new ResidenciaisComerciais(
            json.id,
            json.residencial,
            json.comercial,
            json.industrial,
            json.educacional,
            json.vertical,
            json.horizontal,
            json.numeroUnidadesResidenciais,
            json.numeroUnidadesComerciais,
            json.numeroUnidadesIndustriais,
            json.numeroLotesEquipamentoPublico,
            json.numeroHabitantesOcupantes
        );
    }

    static fromJsons(json: any): ResidenciaisComerciais[] {
        const lista: ResidenciaisComerciais[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => lista.push(this.fromJson(item)));
            return lista;
        }
    }

}
