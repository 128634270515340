import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DocumentoImovel} from '../models/documento-imovel.model';
import {Imovel} from '../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model';
import { String } from 'lodash';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DocumentoImovelService extends BaseService<DocumentoImovel> {
    constructor(protected injector: Injector) {
        super(
            '/api/documentos-imoveis',
            injector,
            DocumentoImovel.prototype,
            DocumentoImovel.fromJson
        );
    }

    getByImovelUrbano(
        imovelUrbano: Imovel
    ): Observable<DocumentoImovel[]> {
        const url = `${this.urlResource}/imovel/${imovelUrbano.id}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createMultipart(
        documentoImovel: DocumentoImovel,
        multipartFile: Blob
    ): Observable<DocumentoImovel> {
        return this.http
            .post(
                this.urlResource,
                this.montarFormData(documentoImovel, multipartFile)
            )
            .pipe(
                map(DocumentoImovel.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateMultipart(
        documentoImovel: DocumentoImovel,
        multipartFile: Blob
    ): Observable<DocumentoImovel> {
        const url = `${this.urlResource}/${documentoImovel.id}`;
        return this.http
            .put(url, this.montarFormData(documentoImovel, multipartFile))
            .pipe(
                map(() => documentoImovel),
                catchError(this.handleError.bind(this))
            );
    }

    private montarFormData(
        documentoImovelUrbano: DocumentoImovel,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const documentoImovelUrbanoParam = new Blob(
            [JSON.stringify(documentoImovelUrbano)],
            {type: 'application/json'}
        );
        formData.append('documentoImovel', documentoImovelUrbanoParam);
        return formData;
    }

    getByRequerimentoVinculadoNumeroERequerimentoId(
        numeroProtocoloVinculado: string, idRequerimento: number 
    ): Observable<DocumentoImovel[]> {
        const url = `${this.urlResource}/requerimento-vinculado`;
        return this.http
            .get(url, { 
                params: new HttpParams()
                    .append('numeroProtocoloVinculado',numeroProtocoloVinculado)
                    .append('idRequerimento',idRequerimento.toString())
            })
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }


}
