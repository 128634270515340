import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    Optional,
    Output,
    QueryList,
    Self,
    ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {ErrorStateMatcher} from '@angular/material/core';
import {ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatFormFieldControl} from '@angular/material/form-field';
import {SelectItemComponent} from '../select-item/select-item.component';

@Component({
    selector: 'app-select-many-checkbox',
    templateUrl: './select-many-checkbox.component.html',
    styleUrls: ['./select-many-checkbox.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: SelectManyCheckboxComponent
        }
    ]
})
export class SelectManyCheckboxComponent implements ControlValueAccessor, MatFormFieldControl<Array<any>>, AfterViewInit, OnDestroy {

    static nextId = 0;

    private onChange = (val) => {
    };
    private onTouched = () => {
    };

    private $value: Array<any>;
    private $placeholder: string;
    private $disabled = false;
    private $required = false;

    @Input() layout: 'vertical' | 'grid' | 'horizontal' = 'grid';
    @Input() layoutColumns = 3;

    @ViewChild('input', {static: true}) input: ElementRef;

    stateChanges = new Subject<void>();

    @HostBinding() id = `select-radio-${SelectManyCheckboxComponent.nextId++}`;

    @HostBinding('attr.aria-describedby') describedBy = '';

    // @ContentChildren(SelectItemComponent) opcoes: QueryList<SelectItemComponent>;

    @Output() valueChanged: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    @Output() optionToggled: EventEmitter<any> = new EventEmitter<any>();

    opcoes: QueryList<SelectItemComponent>;

    @ContentChildren(SelectItemComponent) set content(content: QueryList<SelectItemComponent>) {
        if (content) {
            this.opcoes = content;
            this.updateOpcoes();
        }
    }

    constructor(
        private errorStateMatcher: ErrorStateMatcher,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() private parentForm: NgForm,
        @Optional() private parentFormGroup: FormGroupDirective) {

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngAfterViewInit() {
        // this.opcoes.changes.subscribe(() => {
        //     this.updateOpcoes();
        // });
    }

    get value() {
        return this.$value;
    }

    set value(value: Array<string>) {
        this.$value = value;
        this.onChange(value);
        this.stateChanges.next();
    }

    get focused() {
        return false;
    }

    get empty() {
        return !this.value;
    }

    onContainerClick(event: MouseEvent) {
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return true;
    }

    @Input()
    get placeholder() {
        return this.$placeholder;
    }

    set placeholder(plh) {
        this.$placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get required() {
        return this.$required;
    }

    set required(value) {
        this.$required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this.$disabled;
    }

    set disabled(value: boolean) {
        this.$disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    get errorState() {
        return this.errorStateMatcher.isErrorState(this.ngControl.control as FormControl, this.parentFormGroup || this.parentForm);
    }

    get controlType() {
        return 'select-chips';
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: Array<any>): void {
        this.updateOpcoes(value);

        this.$value = value;
    }

    change(opcao: SelectItemComponent, selected: boolean) {
        opcao._selected = selected;
        this.value = this.opcoesToValue();
        this.valueChanged.emit(this.value);
        this.optionToggled.emit({value: opcao.value, selected: opcao._selected});
    }

    private updateOpcoes(value = this.value) {
        if (this.opcoes) {
            for (const opcao of this.opcoes.toArray()) {
                opcao._selected = value && value.indexOf(opcao.value) >= 0;
            }
        }
    }

    private opcoesToValue(): Array<any> {
        const val = [];

        if (this.opcoes) {
            for (const opcao of this.opcoes.toArray()) {
                if (opcao._selected) {
                    val.push(opcao.value);
                }
            }
        }

        return val.length ? val : undefined;
    }
}

