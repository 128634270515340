import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {ConsultaRecolhimentoTaxaComponent} from './consulta-recolhimento-taxa.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DetalhesDaGuiaDialogComponent} from './dialog/detalhe-da-guia-dialog.component';
import {MatListModule} from '@angular/material/list';
import {GuiasRecolhimentoComponent} from './guias-recolhimento/guias-recolhimento.component';

export const routes: Routes = [
    {
        path: 'consulta-recolhimento-taxa',
        component: ConsultaRecolhimentoTaxaComponent
    }
];

@NgModule({
    declarations: [
        ConsultaRecolhimentoTaxaComponent,
        DetalhesDaGuiaDialogComponent,
        GuiasRecolhimentoComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        RouterModule.forChild(routes),
        MatTooltipModule,
        MatListModule
    ],
    entryComponents: [
        ConsultaRecolhimentoTaxaComponent,
        DetalhesDaGuiaDialogComponent,
        GuiasRecolhimentoComponent
    ],
    exports: [
        GuiasRecolhimentoComponent
    ]
})
export class ConsultaRecolhimentoTaxaModule {
}
