import {Component, OnInit, ViewChild} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {TipoProcesso} from '../../tipos-processo/tipo-processo.model';
import {TipoProcessoService} from '../../tipos-processo/tipo-processo.service';
import {Titulacao} from '../../titulacoes/titulacao.model';
import {TitulacaoService} from '../../titulacoes/titulacao.service';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {environment} from '../../../../../environments/environment';
import {SnackBarService, SnackBarType} from '../../../shared/snack-bar/snack-bar.service';
import {FormUtil} from "../../../shared/util/form-util";
import {Municipio} from 'app/main/shared/models/geoportal-base-referencia/municipio.model';
import {MunicipioService} from 'app/main/shared/services/geoportal-base-referencia/municipio.service';
import {Finalidade} from '../../finalidades/finalidade.model';
import {FinalidadeService} from '../../finalidades/finalidade.service';
import {MatDialog} from '@angular/material';
import {
    DialogDistribuirRequerimentoComponent
} from './dialog-distribuir-requerimento/dialog-distribuir-requerimento-component';
import {GerenciarRequerimentoProcessoService} from '../gerenciar-requerimento-processo.service';
import {Router} from '@angular/router';
import {ServidorService} from 'app/main/shared/services/servidor.service';
import {Servidor} from 'app/main/shared/models/servidor.model';
import {AtividadeArrecadacao} from 'app/main/shared/models/arrecadacao-models/atividade-arrecadacao.model';
import {AtividadeArrecadacaoService} from 'app/main/shared/services/arrecadacao-service/atividade-arrecadacao.service';
import {ENUM_TIPO_CONSULTA_KEYS} from 'app/main/shared/enums/EnumTipoConsulta';
import {SelectionModel} from '@angular/cdk/collections';
import {EnumRegraApresentacao} from 'app/main/shared/enums/EnumRegraApresentacao';
import {
    ItemTermoReferenciaRequerimentoChecklistService
} from 'app/main/shared/services/item-termo-referencia-requerimento-checklist.service';
import {Requerimento} from '../requerimento.model';
import {
    DialogObservacaoRequerimentoDistribuidoComponent
} from './dialog-observacao-requerimento-distribuido/dialog-observacao-requerimento-distribuido-component';
import {ObjetivoOutorga} from 'app/main/shared/models/objetivo-outorga.model';
import {TipoRequerimentoService} from "../../tipos-requerimento/tipo-requerimento.service";
import {TipoRequerimento} from "../../tipos-requerimento/tipo-requerimento.model";
import {
    ObjetivoLicenciamentoAmbientalService
} from "../../../shared/services/licenciamento-config-service/objetivo-licenciamento-ambiental.service";
import {ObjetivoOutorgaService} from "../../../shared/services/objetivo-outorga.service";
import {TarefaService} from "../../../shared/services/tarefa.service";
import {Tarefa} from "../../../shared/models/tarefa.model";
import {ProcessoService} from "../../../shared/services/processo.service";
import {ProcessDefinition} from "../../../shared/models/bpms/process-definition";
import {
    DialogRedistribuirRequerimentoComponent
} from "./dialog-redistribuir-requerimento/dialog-redistribuir-requerimento.component";
import {take} from "rxjs/operators";
import {RedistribuicaoProcessoDTO} from './model/redistribuicao-processo-dto';
import {GerenciarRequerimentosDTO} from "./model/gerenciar-requerimentos.dto";
import {
    ObjetivoLicenciamentoAmbiental
} from "../../../shared/models/licenciamento-config-models/objetivo-licenciamento-ambiental.model";
import { DialogTrocaAssigneeUserTaskComponent } from './dialog-troca-assignee-user-task/dialog-troca-assignee-user-task.component';
import { RequerimentoTaskDTO } from './model/requerimento-task.dto';
import {MessageDialog, MessageDialogResult} from "../../../shared/components/message-dialog/message-dialog.model";

@Component({
    selector: 'app-gerenciar-requerimentos-processos',
    templateUrl: './gerenciar-requerimentos-processos.component.html',
    styleUrls: ['./gerenciar-requerimentos-processos.component.scss'],
    animations: fuseAnimations
})
export class GerenciarRequerimentosProcessosComponent implements OnInit {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    formatoCoordenadas = new FormControl('latLng', Validators.required);
    maskInputLatLng = '';
    form: FormGroup;
    tiposProcesso: Array<TipoProcesso>;
    titulacoes: Array<Titulacao>;
    dataSourceRequerimentos: MatTableDataSource<GerenciarRequerimentosDTO>;
    municipios: Municipio[];
    servidores: Servidor[];
    conferentes: Servidor[];
    finalidadesOutorga: Array<Finalidade>;
    tiposRequerimento: Array<TipoRequerimento>;
    tarefas: Tarefa[] = [];
    definicoesProcesso: ProcessDefinition[] = [];
    objLicenciamento: ObjetivoLicenciamentoAmbiental[];
    pesquisado = false;

    atividadeGrupoDescricao: AtividadeArrecadacao[] = [];
    subGruposEncontrados: AtividadeArrecadacao[] = [];
    atividadePressionada: AtividadeArrecadacao;
    atividadeDescricaoSelect: AtividadeArrecadacao;
    tiposConsulta = ENUM_TIPO_CONSULTA_KEYS;
    totalElements: number;

    redistribuicaoProcessoDTO: RedistribuicaoProcessoDTO = new RedistribuicaoProcessoDTO;

    isServidorLogadoPerfilCoordenador: boolean = false;
    isServidorLogadoPerfilGestor: boolean = false;

    selection = new SelectionModel<GerenciarRequerimentosDTO>(true, []);

    enumRegraApresentacao = EnumRegraApresentacao;
    regrasApresentacao = new SelectionModel<EnumRegraApresentacao>(true, []);
    isProfileDesenvolvimentoOuHomologacao: boolean = false;

    ocultarBotao: boolean = false; 

    requerimentosTaskDTO: RequerimentoTaskDTO[] = [];

    displayedColumns: string[] = [
        'numRequerimento', 'numProcesso', 'interessado',
        'processoTarefa', 'nomeTarefa', 'analistaTarefa',
        'dataProtocolo', 'prioridade', 'ultimoStatus', 'acoes'
    ];

    tipoAplicacaoConsultaCoordenada: BaseEnum[] = [
        {valor: 'LOCALIZACAO_EXATA', descricao: 'Localização exata'},
        {valor: 'BUFFER', descricao: 'Buffer'}
    ]

    objetivosOutorga: ObjetivoOutorga[] = [];

    comparador = (a: GerenciarRequerimentosDTO, b: GerenciarRequerimentosDTO) =>
        a && b ? a.id === b.id : a === b

    constructor(
        private readonly tipoProcessoService: TipoProcessoService,
        private readonly titulacaoService: TitulacaoService,
        private readonly municipioService: MunicipioService,
        private readonly tipoRequerimentoService: TipoRequerimentoService,
        private readonly servidorService: ServidorService,
        private readonly finalidadeService: FinalidadeService,
        private readonly dialog: MatDialog,
        private readonly gerenciarRequerimentoProcessoService: GerenciarRequerimentoProcessoService,
        private readonly itemTermoReferenciaRequerimentoChecklistService: ItemTermoReferenciaRequerimentoChecklistService,
        private readonly objetivoLicenciamentoAmbientalService: ObjetivoLicenciamentoAmbientalService,
        private readonly atividadeArrecadacaoService: AtividadeArrecadacaoService,
        private readonly fb: FormBuilder,
        private readonly router: Router,
        private readonly snackBarService: SnackBarService,
        private readonly objetivoOutorgaService: ObjetivoOutorgaService,
        private readonly tarefaService: TarefaService,
        private readonly processoService: ProcessoService,
        private messageDialog: MessageDialog,
    ) {
    }

    ngOnInit(): void {
        this.dataSourceRequerimentos = new MatTableDataSource<GerenciarRequerimentosDTO>();
        this.dataSourceRequerimentos.sort = this.sort;
        this.dataSourceRequerimentos.sortingDataAccessor = this.dataSourceSorter;
        this.getTipoProcesso();
        this.getTitulacoes();
        this.listarMunicipios();
        this.carregarFinalidades();
        this.listarServidores();
        this.getConferentes();
        this.form = this.fb.group(this.returnObjectForm());
        this.getTipoPerfilServidorLogado();
        this.getListaTiposRequerimento();
        this.getListaObjetivos();
        this.listarObjetivos();
        this.carregaListaProcessos();
        this.isServidorLogadoGestor();
        this.validarProfileEmExecucao();
    }

    private dataSourceSorter(item, property) {
        switch (property) {
            case 'numRequerimento':
                const numeroSplit = item.numero.split('/');
                return +numeroSplit[1] + (+numeroSplit[0] * 10000);
            case 'interessado':
                return item.nomePessoa;
            default:
                return item[property];
        }
    }

    private returnObjectForm(): any {
        return {
            tipoConsulta: ['REQUERIMENTO'],
            numeroRequerimento: [],
            numeroProcesso: [],
            numeroProtocolo: [],
            nomeInteressado: [],
            cpfCnpjInteressado: [],
            conferenteResponsavelCpf: [],
            analistaResponsavelCpf: [],
            atividadeId: [],

            situacaoRequerimentoProcessoPeriodo: [],
            dataInicio: [],
            dataFim: [],

            situacaoRequerimentoProcesso: [],
            municipioEmpreendimento: [],
            nomeEmpreendimento: [],
            tipoProcesso: [],
            tiposOutorga: [],
            titulacaoId: [],
            pesquisaAtividade: [],

            responsavelImovel: [],
            responsavelTecnico: [],
            representanteLegal: [],
            latitude: [],
            longitude: [],
            prioridade: ['T'],
            tipoAplicacao: ['LOCALIZACAO_EXATA'],
            valorBuffer: [],
            finalidadeIds: [],
            tipoRequerimentoIds: [],
            objLicenciamentoIds: [],

            processoTarefa: [],
            responsavelTarefa: [],
            nomeTarefa: [],

            //Regra de apresentação
            regraApresentacao: [this.regrasApresentacao.selected]
        };
    }

    private getListaObjetivos(): void {
        this.objetivoLicenciamentoAmbientalService.getAll().pipe(
            take(1)
        ).subscribe(objs => {
            this.objLicenciamento = objs.filter(p => p.situacao)
            this.objLicenciamento = this.objLicenciamento.filter(obj => obj.idTipoProcesso !== 2 && obj.idTipoProcesso !== 42)
        }, () => this.snackBarService.showError('Ocorreu um erro ao buscar a lista de objetivos'));
    }

    pesquisaSubGrupoDesc(event): void {
        this.atividadeGrupoDescricao = [];
        this.atividadeDescricaoSelect = null;
        const subGrupoDesc = event.target.value;
        if (subGrupoDesc.trim().length === 0) {
            this.atividadeGrupoDescricao = [];
        } else if (subGrupoDesc.trim().length > 2) {
            this.atividadeGrupoDescricao = [];
            this.atividadeArrecadacaoService.getBySubGrupoDesc(subGrupoDesc).pipe(
                take(1)
            ).subscribe(x => this.subGruposEncontrados = x);
        }
    }

    carregaListaProcessos(): void {
        this.processoService.getDefinitions().pipe(
            take(1)
        ).subscribe(
            definicoes => this.definicoesProcesso = definicoes,
            error => this.snackBarService.showError('Ocorreu um erro ao consultar a atividade.', error)
        );
    }

    carregaTarefas(): void {
        this.tarefas = [];
        if (this.form.get('processoTarefa').value) {
            this.tarefaService.getByProcessId(this.form.get('processoTarefa').value).pipe(
                take(1)
            ).subscribe(
                tarefas => this.tarefas = tarefas,
                error => this.snackBarService.showError('Ocorreu um erro ao consultar a atividade.', error)
            );
        }
    }

    buscarAtividadePorSubGrupo(atividade: AtividadeArrecadacao) {
        if (atividade != null) {
            this.atividadePressionada = atividade;
        }

        this.atividadesPorId(this.atividadePressionada.id);
    }

    atividadesPorId(idAtividade) {
        this.atividadeArrecadacaoService.getFilhasArrecadacaoParaId(idAtividade).pipe(
            take(1)
        ).subscribe(
            atividadesParaDecricao => this.atividadeGrupoDescricao = atividadesParaDecricao,
            error => this.snackBarService.showError('Ocorreu um erro ao consultar a atividade.', error)
        );
    }

    private getTipoProcesso(): void {
        this.tipoProcessoService.getAll().pipe(
            take(1)
        ).subscribe(
            tiposProcesso => this.tiposProcesso = tiposProcesso,
            () => this.snackBarService.showError('Erro ao carregar as tipos de processo.')
        );
    }

    get situacoesRequerimentoProcesso(): BaseEnum[] {
        return Requerimento.situacoesProcesso;
    }

    get tiposOutorga(): BaseEnum[] {
        return ObjetivoOutorga.tiposOutorga;
    }

    private listarObjetivos() {
        this.objetivoOutorgaService.getAll().pipe(
            take(1)
        ).subscribe(
            objetivosOutorga => this.objetivosOutorga = objetivosOutorga,
            () => this.snackBarService.showError('Erro ao listar objetivos da outorga.')
        );
    }

    private getListaTiposRequerimento() {
        this.tipoRequerimentoService.getAll().pipe(
            take(1)
        ).subscribe(x => this.tiposRequerimento = x);
    }

    private getTitulacoes(): void {
        this.titulacaoService.getAll().pipe(
            take(1)
        ).subscribe(
            titulacoes => this.titulacoes = titulacoes,
            () => this.snackBarService.showError('Erro ao carregar as titulações.')
        );
    }

    private listarMunicipios(): void {
        this.municipioService.getAllByUf(environment.UF_PADRAO).pipe(
            take(1)
        ).subscribe(
            municipios => this.municipios = municipios,
            () => this.snackBarService.showError('Erro ao carregar os municípios.')
        );
    }

    private listarServidores(): void {
        this.servidorService.getListaServidoresAtivos().pipe(
            take(1)
        ).subscribe(
            servidores => this.servidores = servidores,
            () => this.snackBarService.showError('Erro ao carregar a lista de servidores.')
        );
    }

    private carregarFinalidades(): void {
        this.finalidadeService.getAll().pipe(
            take(1)
        ).subscribe(
            finalidadesOutorga => this.finalidadesOutorga = finalidadesOutorga,
            () => this.snackBarService.showError('Erro ao carregar as finalidades.')
        );
    }

    private getTipoPerfilServidorLogado(): void {
        this.gerenciarRequerimentoProcessoService.getIsPerfilCoordenador().pipe(
            take(1)
        ).subscribe(
            response => this.isServidorLogadoPerfilCoordenador = response,
            () => this.snackBarService.showError('Erro ao verificar se o perfil servidor logado é do tipo coordenador.')
        );
    }

    private getConferentes(): void {
        this.gerenciarRequerimentoProcessoService.getServidoresCoordenadores().pipe(
            take(1)
        ).subscribe(
            response => this.conferentes = response,
            () => this.snackBarService.showError('Erro ao verificar se o perfil servidor logado é do tipo coordenador.')
        );
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.form.get('cpfCnpjInteressado').value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    numeroProtocoloMask(text): void {
        if (text && text.length > 4) {
            if (text.indexOf('/') > -1) {
                text = text.replace('/', '');
            }

            const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
            const year = text.slice(-4);
            this.form.get('numeroProcesso').patchValue(`${initial}/${year}`);
        }
    }

    numeroRequerimentoProcessoMask(text): void {
        if (text && text.length > 4) {
            if (text.indexOf('/') > -1) {
                text = text.replace('/', '');
            }

            const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
            const year = text.slice(-4);
            this.form.get('numeroRequerimento').patchValue(`${initial}/${year}`);
        }
    }

    consultar(): void {
        FormUtil.resetPaginator(this.paginator);
        this.form.get("regraApresentacao").setValue(this.regrasApresentacao.selected);
        this.listarRequerimentos();
    }

    limpar(): void {
        this.form.reset();
        this.regrasApresentacao.clear();
        this.form = this.fb.group(this.returnObjectForm());
        this.dataSourceRequerimentos.data = [];
        this.atividadeGrupoDescricao = [];
        this.subGruposEncontrados = [];
        FormUtil.resetPaginator(this.paginator);
    }

    listarRequerimentos(): void {
        this.selection.clear();
        this.dataSourceRequerimentos.data = [];
        this.totalElements = 0;
        this.gerenciarRequerimentoProcessoService.listarRequerimentosProcessos(this.form, this.formatoCoordenadas.value, this.paginator).pipe(
            take(1)
        ).subscribe(response => {
            this.pesquisado = true;
            this.dataSourceRequerimentos.data = response.content;
            this.totalElements = response.totalElements;
        }, () => this.pesquisado = true);
    }

    get isTipoOutorga(): boolean {
        let tipos = this.form.get("tipoProcesso").value as TipoProcesso[];
        return tipos && tipos.some(x => x.tipo === 'OUTORGA');
    }

    get isTipoLicenciamento() {
        if (!this.objLicenciamento) {
            return [];
        }

        let tipos = this.form.get("tipoProcesso").value as TipoProcesso[];
        return this.objLicenciamento.filter(obj => tipos && tipos.some(x => x.id == obj.idTipoProcesso));
    }

    openDialogDistribuirRequerimento(): void {
        let requerimentosId: number[] = [];
        let numerosRequerimentos: string[] = [];
        this.selection.select(...this.selection.selected);
        if (this.selection.selected.length < 1) {
            this.snackBarService.show('Não há requerimento selecionado.', SnackBarType.ALERT, 1500);
            return;
        }

        const requerimentosInvalidos = this.selection.selected.find(requerimento => requerimento.situacaoProcesso === 'EM_ANALISE');
        if (requerimentosInvalidos) {
            this.snackBarService.show('Foram selecionados processos em que situação são Realizar análise do processo, Revisar Parecer Técnico ou Validar Parecer Técnico.', SnackBarType.ALERT, 1500);
            return;
        }

        requerimentosId = this.selection.selected.map(requerimento => {
            return requerimento.id;
        });
        numerosRequerimentos = this.selection.selected.map(requerimento => {
            return requerimento.numero;
        });
        this.dialog.open(DialogDistribuirRequerimentoComponent, {
                width: '1800px',
                maxHeight: '535px',
                data: {
                    listaRequerimentosId: requerimentosId,
                    listaNumeroRequerimentos: numerosRequerimentos,
                    listaServidores: this.servidores
                }
            }
        ).afterClosed().subscribe(result => {
            this.selection.clear();
            if (result) {
                this.listarRequerimentos();
            }
        });
    }

    openDialogObservacao(requerimentoDTO: GerenciarRequerimentosDTO): void {
        this.dialog.open(DialogObservacaoRequerimentoDistribuidoComponent, {
                width: '1800px',
                maxHeight: '550px',
                data: {
                    requerimentoId: requerimentoDTO.id,
                    requerimentoNumero: requerimentoDTO.numero
                }
            }
        );
    }

    exportarToExcel(): void {
        this.gerenciarRequerimentoProcessoService.exportToExcel(this.form, this.formatoCoordenadas.value).pipe(
            take(1)
        ).subscribe(response => this.downloadFile(response, 'requerimentos_consulta.xlsx'));
    }

    downloadFile(blob: any, filename: string) {
        let binaryData = [];
        binaryData.push(blob);

        const url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));

        // create hidden dom element (so it works in all browsers)
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);

        // create file, attach to hidden element and open hidden element
        a.href = url;
        a.download = filename;

        a.dispatchEvent(new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        }))

        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            a.remove();
        }, 100);
    }

    public visualizarPaginaProcesso(itemId): void {
        this.router.navigateByUrl(`/gerenciar-requerimentos-processos/${itemId}/pagina-processos`).then();
    }

    visualizarHistoricoConferencia(requerimentoId: number) {
        this.itemTermoReferenciaRequerimentoChecklistService.getAllByRequerimento(requerimentoId).pipe(
            take(1)
        ).subscribe(historico => {
            if (historico.length > 0) {
                this.router.navigateByUrl(`/gerenciar-requerimentos-processos/${requerimentoId}/historico-conferencia`);
            } else {
                this.snackBarService.show('Não há histórico de conferência para este requerimento.', SnackBarType.ALERT, 1500);
            }
        });
    }

    isRequerimentoProtocolado(requerimento: GerenciarRequerimentosDTO): boolean {
        return requerimento && !!requerimento.numeroProcesso;
    }

    isDataSourceEmpty(): boolean {
        return !this.dataSourceRequerimentos || this.dataSourceRequerimentos.data.length === 0;
    }

    isBuffer(): boolean {
        return this.form.get('tipoAplicacao').value === 'BUFFER';
    }

    prioridadeToString(item: number): string {
        switch (item) {
            case 20:
                return 'Idade superior a 60 anos';
            case 30:
                return 'Possui doença grave';
            case 40:
                return 'Possui deficiencia física ou mental';
            case 90:
                return 'Sem prioridade';
            default:
                return '';
        }
    }

    onChangeFormato(): void {
        this.maskInputLatLng = this.formatoCoordenadas.value === 'grau' ? '00° 00\' 00.0"' : 'separator.6';
        this.formatoCoordenadas.markAsUntouched();
        this.form.controls.latitude.setValue(null);
        this.form.controls.longitude.setValue(null);
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
    }

    alterarAnalistaDaEquipe() {
        this.selection.select(...this.selection.selected);

        if (this.selection.selected.length == 0 || this.selection.selected.length > 1) {
            this.snackBarService.show(this.selection.selected.length == 0 ? 'Selecione um processo para alterar analista da equipe.' :
                "Selecione somente um processo por vez para alterar analista da equipe.", SnackBarType.ALERT, 1500);
            this.selection.clear();
        } else {
            if (this.selection.selected[0].situacaoProcesso !== 'EM_ANALISE' && this.selection.selected[0].situacaoProcesso !== 'AGUARDANDO_ANALISE') {
                this.snackBarService.show('Somente é possível Alterar Analista da Equipe, para processos que estão na etapa de análise', SnackBarType.ALERT, 1500);
                return;
            } else {
                this.dialog.open(DialogRedistribuirRequerimentoComponent, {
                    width: '800px',
                    data: {
                        requerimentoId: this.selection.selected[0].id
                    }
                }).afterClosed().subscribe(() => {
                    this.selection.clear();
                });
            }
        }
    }

    trocarAssigneeTarefa() {
        let idsRequerimento: Array<number> = [];
        this.selection.select(...this.selection.selected);

        this.selection.selected.forEach(r => {
            idsRequerimento.push(r.id);
        });

        if (idsRequerimento === null || idsRequerimento.length < 1) {
            this.snackBarService.show('Nenhum registro foi selecionado para redistribuição.', SnackBarType.ALERT, 1500);
            return;
        }

        this.redistribuicaoProcessoDTO.idsRequerimento = idsRequerimento;

        const dialogRef = this.dialog.open(DialogTrocaAssigneeUserTaskComponent, {
            width: '800px',
            data: {
                redistribuicaoProcessoDTO: this.redistribuicaoProcessoDTO
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'update') {
                this.consultar();
            }
        });

        // this.dialog.open(DialogTrocaAssigneeUserTaskComponent, {
        //     width: '800px',
        //     data: {
        //         redistribuicaoProcessoDTO: this.redistribuicaoProcessoDTO
        //     }
        // }).afterClosed().subscribe(() => {
        //     this.selection.clear();
        // });

    }

    enviarSelecionadosParaRedistribuicao() {
        let idsRequerimento: Array<number> = [];
        if (this.selection.selected.some(r => r.situacaoProcesso !== 'EM_ANALISE' 
            && r.situacaoProcesso !== 'AGUARDANDO_ANALISE'
            && r.situacaoProcesso !== 'AGUARDANDO_ACEITE_ATRIBUICAO')) {
            this.snackBarService.show('Somente é possível Enviar para Redistribuição, processos que estão na etapa de análise', SnackBarType.ALERT, 1500);
            return;
        }

        this.messageDialog.showQuestion("VOCÊ SOLICITOU A REDISTRIBUIÇÃO DO PROCESSO, ESSA É UMA AÇÃO IRREVERSÍVEL. DESEJA CONTINUAR?")
            .subscribe( result => {
                if(result === MessageDialogResult.Yes){
                    this.selection.selected.forEach(r => {
                        idsRequerimento.push(r.id);
                    });

                    this.redistribuicaoProcessoDTO.idsRequerimento = idsRequerimento;
                    this.gerenciarRequerimentoProcessoService.enviarParaRedistribuicao(this.redistribuicaoProcessoDTO).pipe(
                        take(1)
                    ).subscribe(() => {
                        this.consultar();
                        this.snackBarService.showSuccess('Processo enviado para redistribuição com sucesso!');
                    }, e => {
                        this.snackBarService.showError(e.errors[0]);
                    });
                }
            })
    }

    //Verifica se o servidor logado é um gestor: superintendente/coordenador/gerente e seus substitutos
    private isServidorLogadoGestor(): void {
        this.gerenciarRequerimentoProcessoService.getIsPerfilGestor().pipe(
            take(1)
        ).subscribe(
            response => this.isServidorLogadoPerfilGestor = response,
            () => this.snackBarService.showError('Erro ao verificar se o perfil servidor logado é um gestor: superintendente/coordenador/gerente e seus substitutos.'));
    }

    validarProfileEmExecucao() {
        if (environment.name === 'dev' || environment.name === 'homolog' || environment.name === 'desenv' || environment.name === 'local') {
            this.isProfileDesenvolvimentoOuHomologacao = true;
        }

        return this.isProfileDesenvolvimentoOuHomologacao;
    }

    enviarParaNovaAtribuicao(requerimentoProcessoConsultaDTO: GerenciarRequerimentosDTO) {
        if (requerimentoProcessoConsultaDTO.situacaoProcesso !== 'EM_ANALISE' && requerimentoProcessoConsultaDTO.situacaoProcesso !== 'AGUARDANDO_ANALISE') {
            this.snackBarService.show('Somente é passível de redistribuição processos que com a situação do processo: EM ANÁLISE.', SnackBarType.ALERT, 1500);
            return;
        }
        let idsRequerimento: Array<number> = [];
        idsRequerimento.push(requerimentoProcessoConsultaDTO.id);
        this.redistribuicaoProcessoDTO.idsRequerimento = idsRequerimento;
        this.gerenciarRequerimentoProcessoService.enviarParaRedistribuicao(this.redistribuicaoProcessoDTO).pipe(
            take(1)
        ).subscribe(() => {
            this.consultar();
            this.snackBarService.showSuccess('Processo enviado para redistribuição com sucesso!');
        }, e => this.snackBarService.showError('Erro ao enviar o processo para redistribuição.', e));
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSourceRequerimentos.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSourceRequerimentos.data.forEach(row => {
            this.selection.select(row)
        });
    }

}