import {DocumentoRL} from './documento-rl.model';

export enum EnumTipoDocumentoRL {
    PROCURACAO = ('Procuração com firma reconhecida'),
    PROCURACAO_SIMPLES = ('Procuração simples'), //Item depreciado.
    OAB = ('Cópia da OAB')
}

export namespace EnumTipoDocumentoRL {

    /**
     * Lista os documentos passados como parametro, adicionando a estrutura para documentos á serem criados.
     * @param {DocumentoRL[]} documentos - Documentos já criados.
     */
    export function listarDocumentosRestantes(documentos: DocumentoRL[] = []): DocumentoRL[] {
        if (documentos == null) {
            documentos = [];
        }

        if (documentos.find(documento => documento.tipo === EnumTipoDocumentoRL.PROCURACAO) == null) {
            documentos.push(new DocumentoRL(null, null, EnumTipoDocumentoRL.PROCURACAO,
                null, false, null));
        }

        if (documentos.find(documento => documento.tipo === EnumTipoDocumentoRL.OAB) == null) {
            documentos.push(new DocumentoRL(null, null, EnumTipoDocumentoRL.OAB,
                null, false, null));
        }
        documentos = documentos.sort((a, b) => a.tipo.toString() < b.tipo.toString() ? -1 : a.tipo.toString() > b.tipo.toString() ? 1 : 0);
        return documentos;
    }

    export function fromJson(json: any): EnumTipoDocumentoRL {
        if (json === 'PROCURACAO') {
            return EnumTipoDocumentoRL.PROCURACAO;
        }
        if (json === 'PROCURACAO_SIMPLES') {
            return EnumTipoDocumentoRL.PROCURACAO_SIMPLES;
        }
        if (json === 'OAB') {
            return EnumTipoDocumentoRL.OAB;
        }

        return null;
    }

    export function toJson(tipo: EnumTipoDocumentoRL): string {
        if (tipo === EnumTipoDocumentoRL.PROCURACAO) {
            return 'PROCURACAO';
        }
        if (tipo === EnumTipoDocumentoRL.PROCURACAO_SIMPLES) {
            return 'PROCURACAO_SIMPLES';
        }
        if (tipo === EnumTipoDocumentoRL.OAB) {
            return 'OAB';
        }
        return null;
    }
}
