import { AfterContentChecked, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RedirectUtil } from '../../../shared/util/RedirectUtil';
import { Requerimento } from '../requerimento.model';
import {
    ErrosAbaRequerimento,
    RequerimentoValidacaoAbaDialogComponent
} from './requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { RequerimentoValidacaoDialogComponent } from './requerimento-validacao-dialog/requerimento-validacao-dialog.component';
import { RequerimentoHelperAcessors } from './requerimento.helper';
import { RequerimentoStep, RequerimentoSteps } from './requerimento.steps';

export abstract class BaseRequerimentoComponent extends RequerimentoHelperAcessors
    implements OnInit, AfterContentChecked, OnDestroy {

    pageTitle: string;

    @ViewChild('stepper', {static: true})
    protected stepper: MatStepper;

    protected activatedRoute: ActivatedRoute;
    protected router: Router;
    protected dialog: MatDialog;

    protected _unsubscribeAll: Subject<any>;

    constructor(injector: Injector) {
        super(injector);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.dialog = injector.get(MatDialog);
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.setCurrentAction();
        this.loadRequerimento();

        this.configurarStepper();

        this.stepper.selectionChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(e => {
            this.helper.currentStep = {newTabIndex: e.selectedIndex, oldTabIndex: e.previouslySelectedIndex};
        });
    }

    ngAfterContentChecked(): void {
        this.setPageTitle();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private setCurrentAction(): void {
        if (this.activatedRoute.snapshot.url[1].path === 'new') {
            this.helper.currentAction = 'new';
        } else if (this.activatedRoute.snapshot.url[2].path === 'edit') {
            this.helper.currentAction = 'edit';
        } else {
            this.helper.currentAction = 'detail';
        }
    }

    private setPageTitle(): void {
        if (this.currentAction === 'new') {
            this.pageTitle = this.creationPageTitle();
        } else if (this.currentAction === 'edit') {
            this.pageTitle = this.editionPageTitle();
        } else {
            this.pageTitle = this.visualizationPageTitle();
        }
    }

    creationPageTitle(): string {
        return 'Cadastro de novo requerimento';
    }

    editionPageTitle(): string {
        try {
            const resourceName = (this.requerimento && this.requerimento.numero !== null) || this.requerimento.numero !== '' ? this.requerimento.numero : '';
            return 'Editando o requerimento: ' + resourceName;
        } catch (e) {
            return 'Editando requerimento';
        }
        
    }

    visualizationPageTitle(): string {
        const resourceName = (this.requerimento.numero && this.requerimento.numero !== null) || this.requerimento.numero !== '' ? this.requerimento.numero : '';
        return 'Visualizando o requerimento: ' + resourceName;
    }

    loadRequerimento(): void {
        if (this.currentAction !== 'new') {
            this.activatedRoute.data.subscribe(response => {
                if (response.resource && response.resource.requerimento) {
                    if (this.helper.requerimento && this.helper.requerimento.id != response.resource.requerimento.id) {
                        const data = response.resource;
                        this.helper.requerimento = data.requerimento;
                        if (data.requerimentoOutorga) {
                            this.helper.requerimentoOutorga = data.requerimentoOutorga;
                        }
                        this.helper.currentStep = {newTabIndex: 0};
                    } 
                }
                
            });
        } else {
            this.helper.requerimento = new Requerimento();
        }
    }

    cancelar(): void {
        this.router.navigateByUrl('/requerimentos');
    }

    fechar(): void {
        RedirectUtil.redirecionaDashboardCamunda();
    }

    private configurarStepper(): void {
        this.stepper['_updateSelectedItemIndex'] = async (newIndex) => {
            const oldIndex = this.stepper.selectedIndex;
            if (oldIndex < newIndex && !this.isSomenteVisualizacao) {
                let erros: any;
                const avancouUmaAba = oldIndex + 1 === newIndex;
                if (avancouUmaAba) {
                    const step = RequerimentoSteps.getByIndex(oldIndex);
                    await this.validarAba(step).then(errosAbaRequerimento => erros = errosAbaRequerimento);
                } else {
                    const steps = RequerimentoSteps.values.filter(step => step.index >= oldIndex && step.index < newIndex);
                    await this.validarAbas(steps).then(errosAbasRequerimento => erros = errosAbasRequerimento);
                }
                if (erros && erros.length) {
                    let continuar = false;
                    let dialog;
                    if (erros._erros.filter(e => e.mensagem === 'PopUp').length > 0) {
                        return;
                    }
                    if (avancouUmaAba) {
                        dialog = this.dialog.open(RequerimentoValidacaoAbaDialogComponent, {
                            width: '500px',
                            data: {errosAbaRequerimento: erros, final: false}
                        });
                    } else {
                        dialog = this.dialog.open(RequerimentoValidacaoDialogComponent, {
                            width: '500px',
                            data: {errosAbasRequerimento: erros, final: false}
                        });
                    }
                    await dialog.afterClosed().toPromise().then(result => {
                        continuar = result;
                    });

                    if (!continuar) {
                        return;
                    }
                }
            }
            const stepsArray = this.stepper.steps.toArray();
            this.stepper.selectionChange.emit({
                selectedIndex: newIndex,
                previouslySelectedIndex: oldIndex,
                selectedStep: stepsArray[newIndex],
                previouslySelectedStep: stepsArray[oldIndex],
            });
            this.stepper['_containsFocus']() ? this.stepper['_keyManager'].setActiveItem(newIndex) :
                this.stepper['_keyManager'].updateActiveItemIndex(newIndex);
            this.stepper['_selectedIndex'] = newIndex;
            this.stepper._stateChanged();
        };
    }

    public async validarAba(step: RequerimentoStep, restrict: boolean = false): Promise<ErrosAbaRequerimento> {
        const errosAbaRequerimento = new ErrosAbaRequerimento(step);
        if (!step.component) {
            console.error('A propriedade \'component\' deve ser definida para todas as steps do requerimento.');
            return;
        }
        const validarAbaPromise = step.component.validarAba(errosAbaRequerimento,restrict);
        if (validarAbaPromise) {
            return await validarAbaPromise.then(() => errosAbaRequerimento);
        }
        return Promise.resolve(errosAbaRequerimento);
    }

    public validarAbas(steps: RequerimentoStep[] = RequerimentoSteps.values, restrict: boolean = false): Promise<ErrosAbaRequerimento[]> {
        return Promise.all(steps.map(step => this.validarAba(step, restrict)))
            .then(errosAbasRequerimento => errosAbasRequerimento
                .filter(errosAbaRequerimento => errosAbaRequerimento.length > 0));
    }

    async validacaoFinal(): Promise<boolean> {
        let errosAbasRequerimento: ErrosAbaRequerimento[];
        await this.validarAbas( RequerimentoSteps.values, true).then(errosAbas => errosAbasRequerimento = errosAbas);
        if (errosAbasRequerimento.length > 0) {
            if (errosAbasRequerimento.length === 1 && errosAbasRequerimento[0].requerimentoStep.last) {
                this.dialog.open(RequerimentoValidacaoAbaDialogComponent, {
                    width: '500px',
                    data: {errosAbaRequerimento: errosAbasRequerimento[0], final: true}
                });
            } else {
                this.dialog.open(RequerimentoValidacaoDialogComponent, {
                    width: '500px',
                    data: {errosAbasRequerimento: errosAbasRequerimento, final: true}
                });
            }
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    get requerimentoSteps(): any {
        return RequerimentoSteps;
    }

}

export abstract class BaseTabRequerimentoComponent extends RequerimentoHelperAcessors implements OnDestroy {
    protected _unsubscribeAll: Subject<any>;

    constructor(injector: Injector) {
        super(injector);
        this._unsubscribeAll = new Subject();
    }

    abstract validarAba(erros: ErrosAbaRequerimento, restrict?: boolean): void | Promise<void>;

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}

export interface ChangeStepEvent {
    oldTabIndex?: number;
    newTabIndex: number;
}