import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {MatDialog} from '@angular/material';
import {ParecerRequerimentoConclusaoService} from 'app/main/pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.service';
import {ParecerRequerimentoConclusao} from 'app/main/pages/parecer-tecnico/parecer-requerimento-conclusao/parecer-requerimento-conclusao.model';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {ParecerTecnico} from '../../parecer-tecnico.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-parecer-requerimento-conclusao-list',
    templateUrl: 'parecer-requerimento-conclusao-list.component.html',
    styleUrls: ['parecer-requerimento-conclusao-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class ParecerRequerimentoConclusaoListComponent implements OnInit {

    @Input() parecerTecnico: ParecerTecnico;
    @Input() acaoUpdateEmitter: EventEmitter<any>;
    @Output() deletedEmitter = new EventEmitter();

    conclusoes: ParecerRequerimentoConclusao[] = [];
    protected snackBarService: SnackBarService;

    constructor(
        injector: Injector,
        private dialog: MatDialog,
        private parecerRequerimentoConclusaoService: ParecerRequerimentoConclusaoService
    ) {
        this.snackBarService = injector.get(SnackBarService);
        this.dialog = injector.get(MatDialog);
    }

    ngOnInit(): void {
        this.carregarConclusoes();

        this.acaoUpdateEmitter.subscribe(conclusao => {
            this.carregarConclusoes();
        });
    }

    excluirDialog(conclusao: ParecerRequerimentoConclusao): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: conclusao.conclusao}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.excluir(conclusao);
            }
        });
    }

    excluir(conclusao: ParecerRequerimentoConclusao): void {
        this.parecerRequerimentoConclusaoService.delete(conclusao.id).subscribe(() => {
            this.carregarConclusoes();
            this.deletedEmitter.emit(conclusao);
            this.snackBarService.showSuccess('Registro excluido com sucesso.');
        }, (e) => this.snackBarService.showError('Não foi possível excluir o registro.', e));
    }

    getPrazoTipoDescricao(prazoTipo: string): string {
        return ParecerRequerimentoConclusao.prazosTipo.find(pt => pt.valor === prazoTipo).descricao;
    }

    private carregarConclusoes(): void {
        this.parecerRequerimentoConclusaoService.getByParecerTecnico(this.parecerTecnico.id).subscribe(paracerRequerimento => {
            this.conclusoes = paracerRequerimento;
        });
    }

}
