import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RequerimentoService} from '../../requerimento.service';
import {SnackBarService, SnackBarType} from 'app/main/shared/snack-bar/snack-bar.service';
import {Servidor} from 'app/main/shared/models/servidor.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GerenciarRequerimentoProcessoService} from '../../gerenciar-requerimento-processo.service';


@Component({
    templateUrl: 'dialog-distribuir-requerimento.component.html',
    styleUrls: ['dialog-distribuir-requerimento.component.scss'],
})

export class DialogDistribuirRequerimentoComponent implements OnInit {

    conferentes: Array<Servidor>;
    form: FormGroup;
    numerosRequerimentos: string;

    constructor(
        private dialogRef: MatDialogRef<DialogDistribuirRequerimentoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBarService: SnackBarService,
        private fb: FormBuilder,
        public gerenciarRequerimentoProcessoService: GerenciarRequerimentoProcessoService,
        protected requerimentoService: RequerimentoService,
    ) {

    }

    ngOnInit(): void {
        if (this.data) {
            this.conferentes = this.data.listaServidores;
            this.numerosRequerimentos = this.numerosRequerimentosToString(this.data.listaNumeroRequerimentos);
        }
        this.form = this.fb.group(this.returnObjectForm());
    }

    private returnObjectForm(): any {
        return {
            cpfServidor: [],
            requerimentosAtribuidos: [this.data.listaRequerimentosId],
            observacao: [],

            numerosRequerimentos: [this.numerosRequerimentos]
        };
    }

    distribuirRequerimento(): void {
        this.gerenciarRequerimentoProcessoService.delegarListaTarefas(this.form).subscribe(response => {
            if (response === null) {
                this.snackBarService.show('Requerimentos atribuído com sucesso.', SnackBarType.SUCCESS, 3000);
                this.dialogRef.close(true);
            }
        });
    }

    private numerosRequerimentosToString(arr: string[]): string {
        return arr.toString().replace(/,/g, ', ');
    }

    public closeDialog(): void {
        this.dialogRef.close(false);
    }
}