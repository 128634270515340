import {BaseModel} from '../../../../../shared/models/base.model';
import {BaseEnum} from '../../../../../shared/enums/base.enum';
import {Municipio} from '../../../../../shared/models/geoportal-base-referencia/municipio.model';

export class ObraBarragem extends BaseModel {

    constructor(
        public id?: number,
        public tipoObra?: string,
        public especifiqueTipoObra?: string,
        public nomeCorpoHidrico?: string,
        public baciaHidrografica?: string,
        public subBaciaHidrografica?: string,
        public municipioDireita?: Municipio,
        public municipioEsquerda?: Municipio,
        public distanciaFoz?: number,
        public montanteMinimoNormal?: number,
        public montanteMaximoNormal?: number,
        public montanteMaximorum?: number,
        public jusanteNormal?: number,
        public jusanteMinima?: number,
        public jusanteMaximoNormal?: number,
        public nivelAguaMaximorum?: number,
        public nivelAguaMaximoNormal?: number,
        public nivelAguaMinimoNormal?: number,
        public dispVazaoRemanescente?: boolean,
        public descargaDeFundo?: number,
        public volumeNivelAguaNormal?: number,
        public volumeUtil?: number,
        public abaixoSoleiraVertedouro?: number,
        public vidaUtilAnos?: number,
        public vazaoRegularizada?: number,
        public periodoCriticoInicio?: number,
        public periodoCriticoFim?: number,
        public perimetroReservatorio?: number,
        public profundidadeMedia?: number,
        public profundidadeMaxima?: number,
        public reservTempoFormacao?: number,
        public reservTempoResidencia?: number,
        public reservDeplecaoMaxima?: number,
        public reservVazaoDescarregada?: number,
        public tipoMacicoBarragem?: string,
        public barragemCotaCrista?: number,
        public barragemComprimentoCrista?: number,
        public barragemAlturaMaxima?: number,
        public tipoVertedor?: string,
        public comprimentoSoleira?: number,
        public vertedouroCotaCrista?: number,
        public numeroVaosVertedouro?: number,
        public vazaoVertedouro?: number,
        public periodoRetorno?: number,
        public laminaDaguaMaximaVertedouro?: number,
        public tomadaDaguaLatitudeSul?: string,
        public tomadaDaguaLongitudeOeste?: string,
        public vazaoReduzidaLatitudeSul?: string,
        public vazaoReduzidaLongitudeOeste?: string,
        public extensaoTVR?: number,
        public tipoTurbina?: string,
        public turbinaVazaoUnitaria?: number,
        public turbinaPotenciaUnitaria?: number,
        public turbinaRendimentoMaximo?: number,
        public turbinaRendimentoMinimo?: number,
        public turbinaUnidades?: number,
        public turbinaQuedaBrutaMaxima?: number,
        public turbinaNivelAguaMaximorum?: number,
        public cghQuedaMax?: number,
        public cghQuedaReferencia?: number,
        public cghPotenciaUsina?: number,
        public cghEnergiaFirme?: number,
        public cghEnergiaAssegurada?: number,
        public cghPotenciaMedia?: number,
        public populacaoAtingidaUrbana?: number,
        public populacaoAtingidaRural?: number,
        public familiasAtingidasUrbana?: number,
        public familiasAtingidasRural?: number,
        public nucleosUrbanosAtingidos?: number,
        public interferenciaAreaProtegida?: boolean,
        public interferenciaAreaIndigena?: boolean,
        public relocacaoEstrada?: boolean,
        public extensaoRelocacaoEstrada?: number,
        public relocacaoPontes?: boolean,
        public extensaoRelocacaoPontes?: number,
        public aspectosAmbientais?: string,
        public outrosRecursosHidricos?: string,
        public observacoes?: string,
    ) {
        super();
    }

    static tiposObras: BaseEnum[] = [
        {
            valor: 'BARRAGENS',
            descricao: 'Barragens'
        },
        {
            valor: 'ACUDES',
            descricao: 'Açudes'
        },
        {
            valor: 'SOLEIRAS',
            descricao: 'Soleiras de nível'
        },
        {
            valor: 'OUTROS',
            descricao: 'Outros'
        },
    ];
    static tiposVertedouros: BaseEnum[] = [
        {
            valor: 'COM_COMPORTA',
            descricao: 'Com comporta'
        },
        {
            valor: 'SEM_COMPORTA',
            descricao: 'Sem comporta'
        }
    ];

    static fromJson(json: any): ObraBarragem {
        if (json === undefined || json === null) {
            return null;
        }
        return new ObraBarragem(
            json.id ? json.id : null,
            json.tipoObra ? json.tipoObra : null,
            json.especifiqueTipoObra ? json.especifiqueTipoObra : null,
            json.nomeCorpoHidrico ? json.nomeCorpoHidrico : null,
            json.baciaHidrografica ? json.baciaHidrografica : null,
            json.subBaciaHidrografica ? json.subBaciaHidrografica : null,
            json.municipioDireita ? Municipio.fromJson(json.municipioDireita) : null,
            json.municipioEsquerda ? Municipio.fromJson(json.municipioEsquerda) : null,
            json.distanciaFoz ? json.distanciaFoz : null,
            json.montanteMinimoNormal ? json.montanteMinimoNormal : null,
            json.montanteMaximoNormal ? json.montanteMaximoNormal : null,
            json.montanteMaximorum ? json.montanteMaximorum : null,
            json.jusanteNormal ? json.jusanteNormal : null,
            json.jusanteMinima ? json.jusanteMinima : null,
            json.jusanteMaximoNormal ? json.jusanteMaximoNormal : null,
            json.nivelAguaMaximorum ? json.nivelAguaMaximorum : null,
            json.nivelAguaMaximoNormal ? json.nivelAguaMaximoNormal : null,
            json.nivelAguaMinimoNormal ? json.nivelAguaMinimoNormal : null,
            json.dispVazaoRemanescente ? json.dispVazaoRemanescente : null,
            json.descargaDeFundo ? json.descargaDeFundo : null,
            json.volumeNivelAguaNormal ? json.volumeNivelAguaNormal : null,
            json.volumeUtil ? json.volumeUtil : null,
            json.abaixoSoleiraVertedouro ? json.abaixoSoleiraVertedouro : null,
            json.vidaUtilAnos ? json.vidaUtilAnos : null,
            json.vazaoRegularizada ? json.vazaoRegularizada : null,
            json.periodoCriticoInicio ? json.periodoCriticoInicio : null,
            json.periodoCriticoFim ? json.periodoCriticoFim : null,
            json.perimetroReservatorio ? json.perimetroReservatorio : null,
            json.profundidadeMedia ? json.profundidadeMedia : null,
            json.profundidadeMaxima ? json.profundidadeMaxima : null,
            json.reservTempoFormacao ? json.reservTempoFormacao : null,
            json.reservTempoResidencia ? json.reservTempoResidencia : null,
            json.reservDeplecaoMaxima ? json.reservDeplecaoMaxima : null,
            json.reservVazaoDescarregada ? json.reservVazaoDescarregada : null,
            json.tipoMacicoBarragem ? json.tipoMacicoBarragem : null,
            json.barragemCotaCrista ? json.barragemCotaCrista : null,
            json.barragemComprimentoCrista ? json.barragemComprimentoCrista : null,
            json.barragemAlturaMaxima ? json.barragemAlturaMaxima : null,
            json.tipoVertedor ? json.tipoVertedor : null,
            json.comprimentoSoleira ? json.comprimentoSoleira : null,
            json.vertedouroCotaCrista ? json.vertedouroCotaCrista : null,
            json.numeroVaosVertedouro ? json.numeroVaosVertedouro : null,
            json.vazaoVertedouro ? json.vazaoVertedouro : null,
            json.periodoRetorno ? json.periodoRetorno : null,
            json.laminaDaguaMaximaVertedouro ? json.laminaDaguaMaximaVertedouro : null,
            json.tomadaDaguaLatitudeSul ? json.tomadaDaguaLatitudeSul : null,
            json.tomadaDaguaLongitudeOeste ? json.tomadaDaguaLongitudeOeste : null,
            json.vazaoReduzidaLatitudeSul ? json.vazaoReduzidaLatitudeSul : null,
            json.vazaoReduzidaLongitudeOeste ? json.vazaoReduzidaLongitudeOeste : null,
            json.extensaoTVR ? json.extensaoTVR : null,
            json.tipoTurbina ? json.tipoTurbina : null,
            json.turbinaVazaoUnitaria ? json.turbinaVazaoUnitaria : null,
            json.turbinaPotenciaUnitaria ? json.turbinaPotenciaUnitaria : null,
            json.turbinaRendimentoMaximo ? json.turbinaRendimentoMaximo : null,
            json.turbinaRendimentoMinimo ? json.turbinaRendimentoMinimo : null,
            json.turbinaUnidades ? json.turbinaUnidades : null,
            json.turbinaQuedaBrutaMaxima ? json.turbinaQuedaBrutaMaxima : null,
            json.turbinaNivelAguaMaximorum ? json.turbinaNivelAguaMaximorum : null,
            json.cghQuedaMax ? json.cghQuedaMax : null,
            json.cghQuedaReferencia ? json.cghQuedaReferencia : null,
            json.cghPotenciaUsina ? json.cghPotenciaUsina : null,
            json.cghEnergiaFirme ? json.cghEnergiaFirme : null,
            json.cghEnergiaAssegurada ? json.cghEnergiaAssegurada : null,
            json.cghPotenciaMedia ? json.cghPotenciaMedia : null,
            json.populacaoAtingidaUrbana ? json.populacaoAtingidaUrbana : null,
            json.populacaoAtingidaRural ? json.populacaoAtingidaRural : null,
            json.familiasAtingidasUrbana ? json.familiasAtingidasUrbana : null,
            json.familiasAtingidasRural ? json.familiasAtingidasRural : null,
            json.nucleosUrbanosAtingidos ? json.nucleosUrbanosAtingidos : null,
            json.interferenciaAreaProtegida ? json.interferenciaAreaProtegida : null,
            json.interferenciaAreaIndigena ? json.interferenciaAreaIndigena : null,
            json.relocacaoEstrada ? json.relocacaoEstrada : null,
            json.extensaoRelocacaoEstrada ? json.extensaoRelocacaoEstrada : null,
            json.relocacaoPontes ? json.relocacaoPontes : null,
            json.extensaoRelocacaoPontes ? json.extensaoRelocacaoPontes : null,
            json.aspectosAmbientais ? json.aspectosAmbientais : null,
            json.outrosRecursosHidricos ? json.outrosRecursosHidricos : null,
            json.observacoes ? json.observacoes : null,
        );
    }
}
