import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractComponenteRelacionamento} from "../../../../../../../../shared/components/aba-atividades/abstract-componente-relacionamento";
import {ProdutoBiocombustivel} from "../../../../../../../../shared/models/produto-biocombustivel.model";
import {ProdutoBiodieselService} from "../../../../../../../../shared/services/produto-biodiesel.service";
import {FormGroup, NgForm} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";

@Component({
  selector: 'app-produto-biodiesel',
  templateUrl: './produto-biodiesel.component.html',
  styleUrls: ['./produto-biodiesel.component.scss']
})
export class ProdutoBiodieselComponent extends AbstractComponenteRelacionamento<ProdutoBiocombustivel, ProdutoBiodieselService> implements OnInit {

    protected TIPO_OUTRO = 'Outros';

    @Input() form: FormGroup;
    @Input() materiaPrima: any;
    @Input() descricaoMateriaPrima: string;
    @Input() isNaoPodeEditar: boolean;
    @ViewChild('insideForm', {static: false}) insideForm: NgForm;

    unidadesMedida: Array<DominioDto> = [];

    constructor(
        private dominiosService: DominiosService
    ) {
        super();
    }

    async ngOnInit() {
        super.ngOnInit();

        this.unidadesMedida = await this.dominiosService.getDominioByUrl('unidade-medida-biocombustivel').toPromise();

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    getDisplayedColumns(): string[] {
        return ['produto', 'descricao', 'volume', 'acoes'];
    }

    isBotaoAdicionarDisabled() {
        return super.isBotaoAdicionarDisabled() || !this.novoDado.vinculo.volume || !this.novoDado.vinculo.idUnidadeMedida;
    }

    protected atualizaVinculo() {
        super.atualizaVinculo();
        this.novoDado.vinculo.vinculoMateriaPrima = this.materiaPrima;
    }

    protected getSelecionadosApresentacaoDataSource() {
        return this.info.selecionados.filter(s => {
            if (s.vinculo.idEntidade) {
                return s.vinculo.idEntidade === this.materiaPrima.vinculo.id;
            } else {
                return s.vinculo.vinculoMateriaPrima.idDominio === this.materiaPrima.idDominio
                    && s.vinculo.vinculoMateriaPrima.idMateriaPrima === this.materiaPrima.idMateriaPrima;
            }
        });
    }

    getUnidadeMedida(idUnidadeMedida: any) {
        let um = this.unidadesMedida.find(um => um.id === idUnidadeMedida);
        return um ? um.descricao : "";
    }
}
