import { EventEmitter, Input, Output } from '@angular/core';
import { IMESSAGE } from 'app/main/core/services/core-message.service';
import { BaseModel } from 'app/main/shared/models/base.model';
import { Requerimento } from "../../../../requerimento.model";

export abstract class BaseAtividadeEmpreendimentoComponent {
    @Input() requerimento: Requerimento;
    @Input() isModoVisualizacao = false;
    @Output() enviarFormConcluido = new EventEmitter<any>();
    @Input() validarRegras: boolean = true;
    comparador = (a: BaseModel, b: BaseModel) => a && b ? a.id === b.id : a === b;

    async validar(restrict: boolean = false): Promise<IMESSAGE[]> { return null; };
}