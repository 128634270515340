import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ResiduosTratamentoTermicoDto} from "../models/empreendimentos-models/residuos-tratamento-termico-dto.model";

@Injectable({
    providedIn: 'root'
})
export class ResiduoTratamentoTermicoService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_LIC_RES_TRATAM_TERM;

    createResiduoTratamentoTermico(residuosTratamentoTermicoDto: ResiduosTratamentoTermicoDto): Observable<ResiduosTratamentoTermicoDto> {
        const url = `${this.urlResource}/api/residuo-tratamento-termico`;
        return this.http.post(url, residuosTratamentoTermicoDto)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscaTratamentoTermicoPorId(idTratamentoTermico: number): Observable<any> {
        const url = `${this.urlResource}/api/residuo-tratamento-termico/id/${idTratamentoTermico}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscarDominiosFiltrados(): Observable<any> {
        const url = `${this.urlResource}/api/residuo-tratamento-termico/dominios-filtrados`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    buscaListaAutoClavePorIdTratamento(idTratamentoTermico: number): Observable<any>{
        const url = `${this.urlResource}/api/auto-clave/id/${idTratamentoTermico}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarAutoClavePorId(idAutoClave: number): Observable<any> {
        const url = `${this.urlResource}/api/auto-clave/delete/${idAutoClave}`;
        return this.http.delete(url);
    }

    buscaListaSituacaoIntertravamentoPorIdTratamento(idTratamentoTermico: number): Observable<any>{
        const url = `${this.urlResource}/api/situacao-intertravamento/id/${idTratamentoTermico}`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(e => throwError(e))
            );
    }

    deletarIntertravamentoPorId(idIntertravamento: number): Observable<any> {
        const url = `${this.urlResource}/api/situacao-intertravamento/delete/${idIntertravamento}`;
        return this.http.delete(url);
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/api/residuo-tratamento-termico/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(e => throwError(e))
        );;
    }
}
