import {AbstractControl, ValidatorFn} from '@angular/forms';
import * as moment from "moment";

export class CommonsUtil {

    /**
     * @Deprecated Use baseOnChange do FormUtil.
     * Base on change
     * @param condicaoParaAtivar - Condições para ativar o 'field'
     * @param select - Controller do select á ser testado
     * @param field - Field á set manipulado em função do valor do Select
     * @param validations - Validações á serem ativadas quando o 'field' for ativado
     */
    static baseOnChange(condicaoParaAtivar: boolean, select: AbstractControl, field: AbstractControl, validations: ValidatorFn | ValidatorFn[]): void {

        if (condicaoParaAtivar) {
            field.enable();
            field.setValidators(validations);
            field.updateValueAndValidity();
        } else {
            field.disable();
            field.setValidators(null);
            field.setValue('');
            field.markAsUntouched();
            field.updateValueAndValidity();
        }
    }

    static coordsDecimalToDMS(coordinate: number): string {
        const absolute = Math.abs(coordinate);
        const degrees = Math.floor(absolute);
        const minutesNotTruncated = (absolute - degrees) * 60;
        const minutes = Math.floor(minutesNotTruncated);
        const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

        let result = (coordinate < 0) ? '-' : '';
        result += degrees + ' ' + minutes + ' ' + seconds;
        return result;
    }

    static stringToDate(string: string): Date {
        return moment(string, 'DD/MM/YYYY HH:mm:ss').toDate();
    }

    static isEmpty(dado: any) {
        return (dado === undefined) || (dado === '') || (dado === null) || (dado.length == 0); 
   } 

   static cpfCnpjMask(cpfCnpj): string {
        cpfCnpj=cpfCnpj.replace(/\D/g,"")
        if (cpfCnpj.length <= 14) { 
            cpfCnpj=cpfCnpj.replace(/(\d{3})(\d)/,"$1.$2")
            cpfCnpj=cpfCnpj.replace(/(\d{3})(\d)/,"$1.$2")
            cpfCnpj=cpfCnpj.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        } else {
            cpfCnpj=cpfCnpj.replace(/^(\d{2})(\d)/,"$1.$2")
            cpfCnpj=cpfCnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
            cpfCnpj=cpfCnpj.replace(/\.(\d{3})(\d)/,".$1/$2")
            cpfCnpj=cpfCnpj.replace(/(\d{4})(\d)/,"$1-$2")
        }
        return cpfCnpj
    }

    static comparaStrings(a, b): number  {
        // Normaliza as ‘strings’ removendo os acentos e transformando em minúsculas
        const normalizadaA = a.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        const normalizadaB = b.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

        if (normalizadaA < normalizadaB) {
            return -1;
        }
        if (normalizadaA > normalizadaB) {
            return 1;
        }
        return 0;
    }

    static normaliza(text: string): string{
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

}
