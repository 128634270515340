import {BaseModel} from "./base.model";
import {EmpreendimentoResiduosGerais} from "./empreendimento-residuos-gerais.model";

export class ResiduosGeraisResiduosSolidos extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoResiduosGerais?: EmpreendimentoResiduosGerais,
        public nomeResiduo?: string,
        public estadoFisico?: string,
        public classificacao?: string,
        public possuiProcessamentoResiduos?: boolean,
        public possuiReaproveitamento?: boolean,
        public recebimentoQuantidade?: number,
        public recebimentoUnidade?: string,
        public recebimentoDensidade?: number,
        public armazenamentoQuantidade?: number,
        public armazenamentoUnidade?: string,
        public armazenamentoDensidade?: number,
        public processamentoQuantidade?: number,
        public processamentoUnidade?: string,
        public processamentoDensidade?: number,
        public armazenamentoResiduoProcessadoQuantidade?: number,
        public armazenamentoResiduoProcessadoUnidade?: string,
        public armazenamentoResiduoProcessadoDensidade?: number,
        public recebimentoPossuiAreaTancagem?: boolean,
        public recebimentoNumeroTanques?: number,
        public recebimentoUnidadeAreaTancagem?: number,

    ) {
        super();
    }
    static fromJson(json: any): ResiduosGeraisResiduosSolidos {
        if (json === undefined || json === null) {
            return null;
        }
        return new ResiduosGeraisResiduosSolidos(
            json.id,
            EmpreendimentoResiduosGerais.fromJson(json.empreendimentoResiduosGerais),
            json.nomeResiduo,
            json.estadoFisico,
            json.classificacao,
            json.possuiProcessamentoResiduos,
            json.possuiReaproveitamento,
            json.recebimentoQuantidade,
            json.recebimentoUnidade,
            json.recebimentoDensidade,
            json.armazenamentoQuantidade,
            json.armazenamentoUnidade,
            json.armazenamentoDensidade,
            json.processamentoQuantidade,
            json.processamentoUnidade,
            json.processamentoDensidade,
            json.armazenamentoResiduoProcessadoQuantidade,
            json.armazenamentoResiduoProcessadoUnidade,
            json.armazenamentoResiduoProcessadoDensidade,
            json.recebimentoPossuiAreaTancagem,
            json.recebimentoNumeroTanques,
            json.recebimentoUnidadeAreaTancagem,
        );
    }

    static fromJsons(json: any): ResiduosGeraisResiduosSolidos[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(ResiduosGeraisResiduosSolidos.fromJson(j)));
            return resources;
        }
    }
}