import {Injectable, Injector} from '@angular/core';
import {TipoCondicionante} from './tipo-condicionante.model';
import {CachedBaseService} from '../../shared/services/cached-base.service';

@Injectable({
    providedIn: 'root'
})
export class TipoCondicionanteService extends CachedBaseService<TipoCondicionante> {

    constructor(protected injector: Injector) {
        super('/api/tipos-condicionante', injector, TipoCondicionante.fromJson);
    }

}
