import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogTermoCompromissoComponent} from './dialog-termo-compromisso/dialog-termo-compromisso.component';
import {ImovelService} from "../../../../../shared/requerimento-localizacao/shared/imovel.service";
import {RequerimentoLicenciamentoService} from "../../../../requerimento-licenciamento.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackBarService} from "../../../../../../../shared/snack-bar/snack-bar.service";
import {PessoaCorporativoService} from "../../../../../../../shared/services/pessoa-corporativo.service";
import {ModeloFeicao} from "../../../../../../../shared/enums/modelo-feicao.enum";
import {AutorizacaoManejoFlorestal} from "../../../../../../../shared/models/autorizacao-manejo-florestal.model";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {ManejoFlorestalService} from "./manejo-florestal.service";
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';
import {FormUtil} from '../../../../../../../shared/util/form-util';

@Component({
    selector: 'app-manejo-florestal',
    templateUrl: './manejo-florestal.component.html',
    styleUrls: ['manejo-florestal.component.scss']
})

export class ManejoFlorestalComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    @Input() requerimento: Requerimento;
    requerimentoLicenciamento: any;


    dataSourceAreasFeicao = new MatTableDataSource<any>([]);
    dataSourceAreasComIntersecao = new MatTableDataSource<any>([]);

    //conforme conversado com Ana, seguimos aguardando as camadas
    dataSourceAreasAIR = [{feicao: 'Unidade de Producao Anual', area: '500.50'}];
    dataSourceTermos = [{
        denominialidade: 'M - 578151BC', areaImovel: '465.1050',
        solicitacao: 'Retificação', tempoExistente: 'TRMFM 3510/2021',
        registro: 'AV-05', areaRegistroAIR: '62.1039', situacao: 'Registrado'
    }];

    formDetentor: FormGroup;
    exibirQuadroDeAreasAIR: boolean;
    inventario: boolean;
    pessoaDetentor: any;
    manejoFlorestal: any;

    isNaoPodeEditar: boolean = false;

    constructor(private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
                private imovelService: ImovelService,
                private dialog: MatDialog,
                private pessoaService: PessoaCorporativoService,
                private snackBarService: SnackBarService,
                private formBuilder: FormBuilder,
                private manejoFlorestalService: ManejoFlorestalService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildForm();
        this.carregarQuadroDeAreas();
        this.carregarAreasImovel();
        this.carregaManejo();

        if (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar) {
            this.isNaoPodeEditar = true;
            this.formDetentor.disable();
        }
    }

    async carregaManejo() {
        if (this.requerimento.id) {
            this.manejoFlorestalService.buscarPorIdRequerimento(this.requerimento.id).subscribe(manejo => {
                if (manejo) {
                    this.manejoFlorestal = manejo;
                    this.pessoaService.getById(this.manejoFlorestal.idPessoaDetentor).subscribe(result => {
                        if (result != null) {
                            this.pessoaDetentor = result;
                            this.formDetentor.patchValue({
                                intervencaoNaArea: this.manejoFlorestal.intervencaoArea  === true ? 'sim' : 'nao',
                                nomeDetentor: result !== null ? result.nome : ''
                            });
                        }
                    });
                }
            });
        }
    }

    buildForm() {
        this.formDetentor = this.formBuilder.group({
            id: [null],
            cpfCnpjDetentor: [null],
            nomeDetentor: [null],
            intervencaoNaArea: [null],
        });
    }


    async carregarQuadroDeAreas(): Promise<void> {
        this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
        let result = await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();
        if (result.length > 0) {
            this.dataSourceAreasFeicao = new MatTableDataSource(result);
        }
    }

    carregarAreasImovel(): void {
        this.imovelService.buscarTodosPorRequerimento(this.requerimento.id).subscribe(result => {
            if (result.length > 0) {
                const imovelPrincipal = result.filter(imovel => imovel.imovelPrincipal === true);
                if (imovelPrincipal[0].tipo === 'RURAL') {
                    this.exibirQuadroDeAreasAIR = true;
                    this.dataSourceAreasComIntersecao = new MatTableDataSource(result);
                } else {
                    this.exibirQuadroDeAreasAIR = false;
                }
            }
        });
    }

    abrirDialogTermoResponsabilidade(tipoExibicao: string): void {
        const dialogRef = this.dialog.open(DialogTermoCompromissoComponent, {
            data: {acao: tipoExibicao},
            width: '50%',
            height: '70%',
        });
        dialogRef.afterClosed().subscribe(() => {
        });
    }

    // Função para extrair longitude e latitude de uma string WKT de um ponto
    extractCoordinatesFromWKT(wktString: string): any {
        const regex = /POINT \(([^)]+)\)/;
        const matches = wktString.match(regex);

        if (matches) {
            const coordinates = matches[1].split(' ');
            const longitude = parseFloat(coordinates[0]);
            const latitude = parseFloat(coordinates[1]);
            return { longitude: FormUtil.convertDEGToDMS(longitude.toString(), false).toString(),
                latitude: FormUtil.convertDEGToDMS(latitude.toString(), true).toString()};
        } else {
            return null;
        }
    }

    splitStringCoordenadas(wktPoint: string): string {
        // Uso da função para extrair as coordenadas
        const extractedCoordinates = this.extractCoordinatesFromWKT(wktPoint);
        if (extractedCoordinates) {
            return `${extractedCoordinates.longitude} ${extractedCoordinates.latitude}`;
        } else {
            return '';
        }
    }

    cpfCnpjMask(): string {
        return this.isCPF() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    isCPF(): boolean {
        const cpfOrCnpj = this.formDetentor.get('cpfCnpjDetentor').value;
        return cpfOrCnpj == null ? true : cpfOrCnpj.length < 12;
    }

    buscarPessoaScorp(): void {
        if (this.formDetentor.valid) {
            this.pessoaService.getByCpfCnpj(this.formDetentor.get('cpfCnpjDetentor').value).subscribe(result => {
                if (result != null) {
                    this.pessoaDetentor = result;
                    this.formDetentor.get('nomeDetentor').setValue(result.nome);
                    this.formDetentor.get('cpfCnpjDetentor').setValue('');
                }
            }, () => {
                this.snackBarService.showError('Pessoa não localizada no cadastro de pessoas do SIGA.' +
                    ' Verifique o CPF/CNPJ informado ou faça o seu cadastro no SIGA.”');
            });
        } else {
            this.formDetentor.markAllAsTouched();
            this.snackBarService.showError('Preencher Campo CPF/CNPJ Detentor')
        }

    }

    verificarForm(): boolean {
        return this.pessoaDetentor && this.formDetentor.get('intervencaoNaArea').value !== null;
    }

    salvarFormulario(): void {
        const validate = this.verificarForm();
        const manejo = new AutorizacaoManejoFlorestal();

        if (validate) {

            manejo.id = this.manejoFlorestal ? this.manejoFlorestal.id : null;
            manejo.requerimento = this.requerimentoLicenciamento.requerimento;
            manejo.idPessoaDetentor = this.pessoaDetentor.id;
            manejo.intervencaoArea = this.formDetentor.get('intervencaoNaArea').value === 'sim';

            this.manejoFlorestalService.salvarAutorizacaoManejo(manejo).subscribe(() => {
                this.carregaManejo();
                this.enviarFormConcluido.emit();
                this.snackBarService.showSuccess('Formulário salvo com sucesso.');
            }, () => this.snackBarService.showError("Ocorreu um erro no servidor."));
            
        } else {
            this.snackBarService.showError('Formulario Invalido. Verifique se todos os campos requeridos foram preenchidos.')
        }
    }

    montaStringConformeModeloFeicao(feicaoRequerimentoLic: any): string {
        if (feicaoRequerimentoLic.modeloFeicaoDTO && feicaoRequerimentoLic.modeloFeicaoDTO.idModeloFeicao === ModeloFeicao.UPA) {
            return feicaoRequerimentoLic.feicaoDTO.descricao + ' ' + feicaoRequerimentoLic.modeloFeicaoDTO.modeloFeicaoUpa.ano
        } else {
            return feicaoRequerimentoLic.feicaoDTO.descricao
        }
    }

    buscarInventario(): void {
        this.snackBarService.showAlert('Funcionalidade em Desenvolvimento')
    }

}

