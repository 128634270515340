import {Component, Injector, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HidricoService} from "../../shared/services/hidrico-service/hidrico.service";
import {FormUtil} from "../../shared/util/form-util";
import {SnackBarService} from "../../shared/snack-bar/snack-bar.service";
import {RedefinirDisponibilidadeResponse} from "../../shared/models/hidrico-models/redefinir-disponibilidade-response.model";
import {MicrobaciaDisponibilidade} from "../../shared/models/hidrico-models/microbacia-disponibilidade.model";
import {MatCheckbox} from "@angular/material/checkbox";
import {take} from "rxjs/operators";

@Component({
    selector: 'redefinir-disponobilidade-hidrica',
    templateUrl: './redefinir-disponibilidade-hidrica.component.html',
    styleUrls: ['redefinir-disponibilidade-hidrica.component.scss'],
    animations: fuseAnimations
})
export class RedefinirDisponibilidadeHidricaComponent implements OnInit {
    formatoCoordenadas = new FormControl('latLng', Validators.required);
    maskInputLatLng = '';
    pontoForm: FormGroup;

    microbaciaId: number;
    redefinirDisponibilidadeResponse: RedefinirDisponibilidadeResponse;
    microbaciaDisponibilidadesDTO: MicrobaciaDisponibilidade[] = [];
    disponibilidadeForm: FormGroup[] = [];
    formJustificativa: FormControl = new FormControl(null, Validators.required);

    constructor(
        private snackBarService: SnackBarService,
        protected injector: Injector,
        protected formBuilder: FormBuilder,
        private hidricoService: HidricoService
    ) {
    }

    ngOnInit(): void {
        this.pontoForm = this.formBuilder.group({
            latitude: [null, [Validators.required]],
            longitude: [null, [Validators.required]],
        });
    }

    pesquisar(checkEditar: MatCheckbox) {
        this.pontoForm.markAllAsTouched();
        checkEditar.checked = false;
        if (this.pontoForm.valid) {
            let lat;
            let long;
            if (this.formatoCoordenadas.value === 'grau') {
                lat = FormUtil.parseDMSToDecimal(this.pontoForm.controls.latitude.value);
                long = FormUtil.parseDMSToDecimal(this.pontoForm.controls.longitude.value);
            } else {
                lat = this.pontoForm.controls.latitude.value;
                long = this.pontoForm.controls.longitude.value;
            }

            this.hidricoService.consultarInfoRedefinirDisponibilidade(lat || 0, long || 0).pipe(
                take(1)
            ).subscribe(result => {
                this.redefinirDisponibilidadeResponse = result;
                this.microbaciaId = this.redefinirDisponibilidadeResponse.microbaciaId;
                this.iniciarDisponibilidadeForm();
            }, error => {
                this.snackBarService.showAlert(error.errors[0] || error.message || 'Falha ao pesquisar pelo ponto')
            })
        } else {
            this.snackBarService.showAlert('Preencha latitude e longitude corretamente!')
        }
    }

    onChangeFormato(): void {
        this.maskInputLatLng = this.formatoCoordenadas.value === 'grau' ? '00° 00\' 00.0"' : 'separator.6';
        this.formatoCoordenadas.markAsUntouched();
        this.pontoForm.controls.latitude.setValue(null);
        this.pontoForm.controls.longitude.setValue(null);
        this.pontoForm.markAsUntouched();
        this.pontoForm.updateValueAndValidity();
    }

    salvar(checkEditar: MatCheckbox) {
        if (this.formJustificativa.invalid || this.disponibilidadeForm.some(e => e.invalid)) {
            this.formJustificativa.markAllAsTouched();
            this.snackBarService.showAlert('Preencha corretamente os campos.')
            return;
        }
        this.disponibilidadeForm.forEach(element =>{
            this.microbaciaDisponibilidadesDTO.push(element.value);
        });
        let dto = {
            microbaciaId: this.microbaciaId,
            justificativa: this.formJustificativa.value,
            microbaciaDisponibilidadesDTO: this.microbaciaDisponibilidadesDTO,
        }
        this.hidricoService.redefinirDisponibilidade(dto).subscribe(() => {
            this.onChangeFormato();
            this.redefinirDisponibilidadeResponse = null;
            this.disponibilidadeForm = [];
            checkEditar.checked = false;
            this.snackBarService.showSuccess('Vazão da microbacia redefinida com sucesso!');
        }, e => this.snackBarService.showError('Erro ao salvar', e));
    }

    iniciarDisponibilidadeForm(): void {
        if(this.redefinirDisponibilidadeResponse){
            this.disponibilidadeForm = this.redefinirDisponibilidadeResponse.microbaciaDisponibilidadesDTO.map(e =>
                new FormGroup({
                    mes: new FormControl(e.mes ? e.mes : 0, [Validators.required]),
                    mesOrdem: new FormControl(e.mesOrdem ? e.mesOrdem : 0, [Validators.required]),
                    vazaoQ95: new FormControl(e.vazaoQ95 ? e.vazaoQ95 : 0, [Validators.required]),
                    vazaoQInc: new FormControl(e.vazaoQInc ? e.vazaoQInc : 0, [Validators.required]),
                    vazaoUtilizada: new FormControl(e.vazaoUtilizada ? e.vazaoUtilizada : 0, [Validators.required]),
                    vazaoDisponivel: new FormControl(e.vazaoQ95 ? this.vazaoDisponivel(e) : 0)
                })
            );
            this.disponibilidadeForm.forEach(formGroup => {
                formGroup.get('vazaoQ95').valueChanges.subscribe((value: number) => {
                    formGroup.get('vazaoDisponivel').setValue(value*0.7-formGroup.get('vazaoUtilizada').value);
                    formGroup.get('vazaoDisponivel').updateValueAndValidity({ onlySelf: true, emitEvent: false });
                });
            });

            this.formJustificativa = new FormControl(null, Validators.required);
        }
    }

    vazaoDisponivel(microbaciaDisponibilidade: MicrobaciaDisponibilidade): number {
        return microbaciaDisponibilidade.vazaoQ95 * 0.7 - microbaciaDisponibilidade.vazaoUtilizada;
    }
}
