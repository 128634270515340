import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../../environments/environment";
import {BaseService} from "../base.service";
import {LancamentosCreditosDisponiveisRetornoDtoArrecadacao} from "../../models/arrecadacao-models/lancamentos-creditos-disponiveis-retornoDto-arrecadacao.model";

@Injectable({
    providedIn: 'root'
})
export class ContaCorrenteService extends BaseService<LancamentosCreditosDisponiveisRetornoDtoArrecadacao>{

    private urlResourceArrecadacao = environment.URL_ARRECADACAO_API;

    constructor(protected injector: Injector) {
        super('/api/conta-corrente', injector, LancamentosCreditosDisponiveisRetornoDtoArrecadacao.prototype, LancamentosCreditosDisponiveisRetornoDtoArrecadacao.fromJson);
    }

    buscarSaldoUpfPorIdPessoa(idPessoa: number): Observable<any> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/api/conta-corrente/saldo-upf-pessoa/${idPessoa}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }
}
