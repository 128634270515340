import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Processo} from '../../../../shared/models/processo.model';
import {ProcessoService} from '../../../../shared/services/processo.service';
import {TipoRequerimento} from '../../../tipos-requerimento/tipo-requerimento.model';

@Component({
    selector: 'app-dialog-protocolo-existente',
    templateUrl: './dialog-protocolo-existente.component.html',
})
export class DialogProtocoloExistenteComponent {

    possuiProcotocolo = false;
    formProtocolo: FormControl;
    tiposRequerimento: TipoRequerimento[];
    processo: Processo;

    constructor(
        private dialogRef: MatDialogRef<DialogProtocoloExistenteComponent, DialogProtocoloExistenteResult>,
        private snackBarService: SnackBarService,
        private processoService: ProcessoService
    ) {
        this.formProtocolo = new FormControl(null, Validators.required);
    }

    concluir(): void {
        if (this.possuiProcotocolo) {
            if (!this.processo) {
                this.snackBarService.showAlert('Busque o processo pelo número de protocolo');
            } else {
                this.dialogRef.close({
                    possuiProtocoloExistente: true,
                    numeroProtocolo: this.formProtocolo.value
                });
            }
        } else {
            this.dialogRef.close({possuiProtocoloExistente: false});
        }
    }

    pesquisarProcesso(): void {
        this.formProtocolo.markAllAsTouched();
        if (this.formProtocolo.valid) {
            this.processoService.findByNumero(this.formProtocolo.value.replace('/', '')).subscribe(result => {
                this.processo = result;
            }, e => this.snackBarService.showError('Erro ao pesquisar o processo', e));
        }
    }

    numeroProcessoMask(text: string): void {
        text = text.replace(/\D/g, "");
        if (text && text.length > 4) {
            const initial = text.substring(0, text.length - (text.indexOf('/') > -1 ? 5 : 4));
            const year = text.slice(-4);
            this.formProtocolo.patchValue(`${initial}/${year}`);
        } else {
            this.formProtocolo.patchValue(`${text}`);
        }
    }
}

export interface DialogProtocoloExistenteResult {
    possuiProtocoloExistente: boolean;
    numeroProtocolo?: number | null;
}
