import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ApreciarParecerTecnico} from '../models/apreciar-parecer-tecnico.model';
import {FeicaoAnaliseGeometriaDto} from '../models/feicao-analise-geometria-dto.model';

@Injectable({
    providedIn: 'root'
})
export class ApreciarParecerTecnicoService extends BaseService<ApreciarParecerTecnico> {

    constructor(protected injector: Injector) {
        super(
            '/api/apreciar-parecer-tecnico',
            injector,
            ApreciarParecerTecnico.prototype,
            ApreciarParecerTecnico.fromJson
        );
    }

    salvarApreciarParecerTecnico(apreciarParecerTecnico: ApreciarParecerTecnico, idTarefa: any): Observable<any> {
        const url = `${this.urlResource}/${idTarefa}`;
        return this.http.post(url, apreciarParecerTecnico).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    buscaAnalistaReqAnalista(idRequerimento: number): Observable<any> {
        const url = `${this.urlResource}/busca-analista/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

}
