import {BaseModel} from "./base.model";
import {AtividadeMineracao} from "./atividade-mineracao.model";

export class CombustivelUtilizado extends BaseModel {
    constructor(
        public id: number,
        public idTipoCombustivel: number,
        public descricao: string,
        public quantidadeMensal: number,
        public idUnidadeMedida: number,
        public mineracao: AtividadeMineracao,
    ) {
        super();
    }

    static fromJson(json: any): CombustivelUtilizado {
        if (json === undefined || json === null) {
            return null;
        }
        return new CombustivelUtilizado(
            json.id,
            json.idTipoCombustivel,
            json.descricao,
            json.quantidadeMensal,
            json.idUnidadeMedida,
            json.mineracao,
        );
    }

}
