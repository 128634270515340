import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {CaracterizacaoConsumoAgua} from '../models/caracterizacao-consumo-agua.model';
import {Mineracao} from '../models/mineracao.model';
import {Industria} from '../models/industria.model';

@Injectable({
    providedIn: 'root'
})
export class CaracterizacaoConsumoAguaService {

    private urlResource = `${environment.URL_GATEWAY}/api/caracterizacoes-consumo-agua`;

    constructor(private http: HttpClient) {
    }

    getAllByMineracao(mineracao: Mineracao): Observable<CaracterizacaoConsumoAgua[]> {
        const url = `${this.urlResource}/mineracao/${mineracao.id}`;
        return this.http.get(url).pipe(
            map(this.jsonToResources),
            catchError(this.handleError)
        );
    }

    getAllByIndustria(industria: Industria): Observable<CaracterizacaoConsumoAgua[]> {
        const url = `${this.urlResource}/industria/${industria.id}`;
        return this.http.get(url).pipe(
            map(this.jsonToResources),
            catchError(this.handleError)
        );
    }

    create(caracterizacaoConsumoAgua: CaracterizacaoConsumoAgua): Observable<CaracterizacaoConsumoAgua> {
        return this.http
            .post(this.urlResource, caracterizacaoConsumoAgua)
            .pipe(
                map(CaracterizacaoConsumoAgua.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    delete(caracterizacaoConsumoAgua: CaracterizacaoConsumoAgua): Observable<any> {
        const url = `${this.urlResource}/${caracterizacaoConsumoAgua.id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    private jsonToResources(json: any): CaracterizacaoConsumoAgua[] {
        const resources = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: any) => resources.push(CaracterizacaoConsumoAgua.fromJson(e)));
        }
        return resources;
    }

    private handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
