import {BaseModel} from 'app/main/shared/models/base.model';
import { Requerimento } from '../../requerimento.model';

export class DelegarListaTarefaDTO extends BaseModel {

    constructor(
        public cpfAtribuidor?: string,
        public cpfServidor?: string,
        public listaRequerimentos?: number[],
        public observacao?: string
    ) {
        super();
    }

    static fromJson(json: any): DelegarListaTarefaDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new DelegarListaTarefaDTO(
            json.cpfAtribuidor,
            json.cpfServidor,
            json.listaRequerimentos,
            json.observacao
        );
    }

    static fromJsons(json: any): DelegarListaTarefaDTO[] {
        const lista: DelegarListaTarefaDTO[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}
