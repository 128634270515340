import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {DoencaGrave} from './doenca-grave.model';
import {Cemiterio} from "./cemiterio.model";
import {Crematorio} from "./crematorio.model";
import {ParcelamentoSolo} from "./parcelamento-solo.model";
import {ResidenciaisComerciais} from "./residenciais-comerciais.model";

export class EmpreendimentoInfraParcelSoloResCom extends BaseModel {
    constructor(
        public id?: number,
        public tipoEmpreendimento?: number,
        public parcelamentoSolo?: ParcelamentoSolo,
        public residenciaisComerciais?: ResidenciaisComerciais,
        public requerimento?: number
    ) {
        super();
    }

    static fromJson(json: any): EmpreendimentoInfraParcelSoloResCom {
        if (json === undefined || json === null) {
            return null;
        }
        return new EmpreendimentoInfraParcelSoloResCom(
            json.id,
            json.tipoEmpreendimento,
            ParcelamentoSolo.fromJson(json.parcelamentoSolo),
            ResidenciaisComerciais.fromJson(json.residenciaisComerciais),
            json.requerimento
        );
    }

    static fromJsons(json: any): EmpreendimentoInfraParcelSoloResCom[] {
        const empreendimentos: EmpreendimentoInfraParcelSoloResCom[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(empreendimento => empreendimentos.push(this.fromJson(empreendimento)));
            return empreendimentos;
        }
    }

}
