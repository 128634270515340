import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';

import {FinalidadeAtividade} from './finalidade-atividade.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {catchError, map} from 'rxjs/operators';
import {DetailResolver} from '../../shared/resolvers/detail-resolver';
import {RoutingStateService} from '../../core/services/routing-state.service';


@Injectable()
export class FinalidadeAtividadeResolver extends DetailResolver<FinalidadeAtividade> {
    constructor(
        service: FinalidadeAtividadeService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable()
export class FinalidadeAtividadeListResolver extends ListResolver<FinalidadeAtividade> {
    constructor(service: FinalidadeAtividadeService) {
        super(service);
    }
}

@Injectable({
    providedIn: 'root'
})
export class FinalidadeAtividadeService extends BaseService<FinalidadeAtividade> {

    constructor(protected injector: Injector) {
        super(
            '/api/finalidades-atividade',
            injector,
            FinalidadeAtividade.prototype,
            FinalidadeAtividade.fromJson
        );
    }

    getAllByFinalidade(finalidadeId: number): Observable<FinalidadeAtividade[]> {
        const url = `${this.urlResource}/finalidades/${finalidadeId}`;
        return this.http.get(url).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

    getById(id: number): Observable<FinalidadeAtividade> {
        const url = `${this.urlResource}/${id}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    create(resource: FinalidadeAtividade): Observable<FinalidadeAtividade> {
        return this.http
            .post(this.urlResource, resource)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}
