import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from '../../../requerimento.model';
import {Titulacao} from 'app/main/pages/titulacoes/titulacao.model';
import {EnumOrigemTitulacao} from '../../../../../shared/enums/EnumOrigemTitulacao';
import {BaseEnum} from '../../../../../shared/enums/base.enum';


export class RequerimentoTitulacao extends BaseModel {
    constructor(
        public id?: number,
        public titulacao?: Titulacao,
        public requerimento?: Requerimento,
        public enumOrigemTitulacao?: EnumOrigemTitulacao,
    ) {
        super();
    }

    static enumOrigemTitulacao: BaseEnum[] = [
        {valor: 'REQUERIMENTO', descricao: 'Requerimento'},
        {valor: 'EMISSAO', descricao: 'Emissao'}
    ];

    static fromJson(json: any): RequerimentoTitulacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoTitulacao(
            json.id,
            json.titulacao,
            json.requerimento,
            json.enumOrigemTitulacao
        );
    }

    static jsonToResources(json: any): RequerimentoTitulacao[] {
        const resources: RequerimentoTitulacao[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: RequerimentoTitulacao) => resources.push(RequerimentoTitulacao.fromJson(e)));
        }
        return resources;
    }
}
