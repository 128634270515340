import {ChangeDetectionStrategy, Component, Injector, Input, OnChanges, SimpleChanges} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {RequerimentoService} from 'app/main/pages/requerimentos/requerimento.service';
import {FormControl} from '@angular/forms';
import {ResponsavelTecnico} from '../../../../shared/models/responsavel-tecnico.model';
import {MatDialog} from '@angular/material';
import {DocumentoRL} from '../requerimento-responsaveis/representante-legal/documento-rl.model';
import {TipoProcesso} from 'app/main/pages/tipos-processo/tipo-processo.model';
import {EnumTipoDocumentoRL} from '../requerimento-responsaveis/representante-legal/enum-tipo-documento-rl.model';
import {RepresentateDialogComponent} from 'app/main/shared/components/aba-responsaveis/dialog-representate/representate-dialog-component';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-representante-associado',
    templateUrl: './representante-associado.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class RepresentanteAssociadoComponent implements OnChanges {

    @Input() requerimento: Requerimento;
    showForm: FormControl = new FormControl(false);
    anexos: DocumentoRL[] = EnumTipoDocumentoRL.listarDocumentosRestantes();
    fluxoAnexo: TipoProcesso | null = null;
    representantesLegalAnexado: ResponsavelTecnico[] = [];

    constructor(
        protected injector: Injector,
        protected requerimentoService: RequerimentoService,
        private snackBarService: SnackBarService,
        private dialog: MatDialog
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            this.listarRepresentanteLegal();
        }
    }

    private listarRepresentanteLegal(): void {
        this.requerimentoService
            .listarRepresentantesLegais(this.requerimento.id).subscribe((content) => {
            this.representantesLegalAnexado = content;
            if (this.representantesLegalAnexado.length > 0) {
                this.showForm.disable();
                this.showForm.patchValue(true);
            } else {
                this.showForm.enable();
            }
        });
    }

    public visualizarDocumento(documento: DocumentoRL): void {
        this.requerimentoService.downloadDocumentoRepresentanteLegal(this.requerimento.id, documento).subscribe((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    public downloadDocumento(documento: DocumentoRL): void {
        window.open(
            `${this.requerimentoService.urlResource}/${this.requerimento.id}
            /representantes-legais/${documento.representanteLegal.pessoa.cpfCnpj}/documentos/${EnumTipoDocumentoRL.toJson(documento.tipo)}`
        );
    }

    openDialogRepresentante(representanteLegal: any): void {
        this.dialog.open(RepresentateDialogComponent, {
                width: '1800px',
                maxHeight: '450px',
                data: representanteLegal
            }
        );
    }

}

