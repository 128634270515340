import {Injectable, Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {BaseService} from './base.service';
import {AssuntoNota} from '../models/assunto-nota.model';
import {DetailResolver} from '../resolvers/detail-resolver';
import {ListResolver} from '../resolvers/list-resolver';
import {RoutingStateService} from '../../core/services/routing-state.service';

@Injectable()
export class AssuntoNotaListResolver extends ListResolver<AssuntoNota> {
    constructor(service: AssuntoNotaService) {
        super(service);
    }
}

@Injectable()
export class AssuntoNotaResolver extends DetailResolver<AssuntoNota> {
    constructor(service: AssuntoNotaService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({providedIn: 'root'})
export class AssuntoNotaService extends BaseService<AssuntoNota> {
    constructor(injector: Injector) {
        super('/api/assuntos-nota', injector, AssuntoNota.prototype, AssuntoNota.fromJson);
    }

    protected handleError(error: any): Observable<any> {
        if (error) {
            if (error.status === 404) {
                return of(null);
            }

            if (error.error && error.message) {
                error.toString = () => error.message;
            }
        }

        return super.handleError(error);
    }
}