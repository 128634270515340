import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RequerimentoStep} from '../requerimento.steps';

export class ErrosAbaRequerimento {
    private _erros: ErroAbaRequerimento[] = [];

    constructor(public requerimentoStep: RequerimentoStep) {
    }

    public push(mensagem: string, restritivo: boolean = true): void {
        this._erros.push(new ErroAbaRequerimento(mensagem, restritivo));
    }

    get erros(): ErroAbaRequerimento[] {
        return [...this._erros].sort((a, b) => a.restritivo === b.restritivo ? 0 : 1);
    }

    get length(): number {
        return this._erros.length;
    }

    get temRestritivo(): boolean {
        return this._erros.some(erro => erro.restritivo);
    }
}

export class ErroAbaRequerimento {
    constructor(public mensagem: string, public restritivo: boolean) {
    }
}

@Component({
    selector: 'app-requerimento-validacao-aba-dialog',
    templateUrl: './requerimento-validacao-aba-dialog.component.html'
})
export class RequerimentoValidacaoAbaDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<RequerimentoValidacaoAbaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { errosAbaRequerimento: ErrosAbaRequerimento, final: boolean }
    ) {
    }

    cancelar(): void {
        this.dialogRef.close(false);
    }

    continuar(): void {
        this.dialogRef.close(true);
    }

    get errosAbaRequerimento(): ErrosAbaRequerimento {
        return this.data.errosAbaRequerimento;
    }

    get final(): boolean {
        return this.data.final;
    }

}
