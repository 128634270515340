import {BaseModel} from "./base.model";

export class AutorizacaoQueimaControlada extends BaseModel {
    constructor(
        public id?: number,
        public idRequerimento?: number,
    ) {
        super();
    }

    static fromJson(json: any): AutorizacaoQueimaControlada {
        if (json === undefined || json === null) {
            return null;
        }
        return new AutorizacaoQueimaControlada(
            json.id,
            json.idRequerimento,
        );
    }

    static fromJsons(json: any): AutorizacaoQueimaControlada[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(AutorizacaoQueimaControlada.fromJson(j)));
            return resources;
        }
    }
}