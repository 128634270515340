import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DominioService} from '../../../../../../../../shared/services/dominio.service';
import {MatTableDataSource} from '@angular/material/table';
import {SnackBarService} from '../../../../../../../../shared/snack-bar/snack-bar.service';
import {BaseModel} from '../../../../../../../../shared/models/base.model';
import {UsinaTipoFossil} from '../../../../../../../../shared/models/energia-termica-tipo-fossil.model';
import {EmpreendimentoEnergeticoTermicaService} from '../empreendimento-energetico-termica.service';

@Component({
    selector: 'app-usina-fossil',
    templateUrl: './usina-fossil.component.html',
    styleUrls: ['./usina-fossil.component.scss']
})
export class UsinaFossilComponent implements OnInit {

    @Output() enviarUsinasFossil = new EventEmitter<UsinaTipoFossil[]>();
    @Input() idEEtermica: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;

    formUsinaFossil: FormGroup;
    tipoCombustivel: any;
    subTipoCombustivel: any;
    descricao: any;
    mostrarCampoOutroTipo = false;
    mostrarCampoOutroSubTipo = false;
    usinaFossilAdicionado: any[] = [];
    idUsinaFossilTemp = 0;
    modoEdicao = false;
    dataSourceUsinaFossil = new MatTableDataSource<any>([]);
    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    constructor(private formBuilder: FormBuilder,
                private dominioService: DominioService,
                private snackBar: SnackBarService,
                private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService,
    ) {
    }

    ngOnInit(): void {
        this.buildFormUsinaFossil();
        this.getTipoCombustivel();
        if (this.idEEtermica) {
            this.carregaUsinaFossilCasoExistaIdEEtermica(this.idEEtermica);
        }

        if (this.isNaoPodeEditar) {
            this.formUsinaFossil.disable();
        }
    }

    carregaUsinaFossilCasoExistaIdEEtermica(idEEtermica: number): void {
        this.empreendimentoEnergService.buscaListUsinaFossil(idEEtermica).subscribe(async result => {
            for (const usinaFossil of result) {
                const tipoFossil = await this.tipoCombustivel.filter(tipo => tipo.id === usinaFossil.idTipoCombustivel);
                const listSubTipo = await this.dominioService.buscarSubTipoCombustivel().toPromise();
                const SubTipo = listSubTipo.filter(subTipo => subTipo.id === usinaFossil.idSubTipoCombustivel);

                this.usinaFossilAdicionado.push({
                    id: usinaFossil.id,
                    idTemp: this.idUsinaFossilTemp,
                    tipoUsinaFossil: tipoFossil[0],
                    subTipoUsinaFossil: SubTipo[0],
                    descricao: usinaFossil.descricao,
                });
                this.dataSourceUsinaFossil = new MatTableDataSource(this.usinaFossilAdicionado);
                this.idUsinaFossilTemp++;
            }
        });
    }

    buildFormUsinaFossil(): void {
        this.formUsinaFossil = this.formBuilder.group({
            id: [''],
            idTemp: [null],
            tipoFossil: ['', [Validators.required]],
            subTipoFossil: ['', [Validators.required]],
            descricaoTipo: [''],
            descricaoSubTipo: [''],
            descricao: ['']
        });
    }

    getTipoCombustivel(): void {
        this.dominioService.buscarTipoCombustivel().subscribe(tipo => {
            this.tipoCombustivel = tipo;
        });
    }

    carregaSubTipo(tipo: any): void {
        this.mostrarCampoOutroTipo = tipo.descricao === 'Outros';
        this.dominioService.buscarSubTipoCombustivel().subscribe(subTipoCombustivel => {
            this.subTipoCombustivel = subTipoCombustivel.filter(subTipo => subTipo.idTipoCombustivel === tipo.id);
        });
    }

    adicionarUsinaFossil(): void {
        const validate = this.validarFormulario();
        if (validate) {
            let descricao;
            if (this.formUsinaFossil.controls.descricaoTipo.value) {
                descricao = this.formUsinaFossil.controls.descricaoTipo.value;
            } else if (this.formUsinaFossil.controls.descricaoSubTipo.value) {
                descricao = this.formUsinaFossil.controls.descricaoSubTipo.value;
            }

            this.usinaFossilAdicionado.push({
                id: null,
                idTemp: this.idUsinaFossilTemp,
                tipoUsinaFossil: this.formUsinaFossil.controls.tipoFossil.value,
                subTipoUsinaFossil: this.formUsinaFossil.controls.subTipoFossil.value,
                descricao: descricao,
            });

            this.mostrarCampoOutroSubTipo = false;
            this.mostrarCampoOutroTipo = false;
            this.dataSourceUsinaFossil = new MatTableDataSource(this.usinaFossilAdicionado);
            this.enviarUsinas(this.mapearArray());
            this.idUsinaFossilTemp++;
            this.formUsinaFossil.reset();
        }

    }

    enviarUsinas(usinas: UsinaTipoFossil[]): void {
        this.enviarUsinasFossil.emit(usinas);
    }

    editarUsina(): void {
        this.deletarUsinaFossilItem(this.formUsinaFossil.controls.idTemp.value);
        this.adicionarUsinaFossil();
        this.modoEdicao = false;
    }


    async ativarModoEdicaoUsinaFossil(usinaFossil: any): Promise<void> {
        await this.dominioService.buscarSubTipoCombustivel().toPromise().then(subTipoFossil => {
            this.subTipoCombustivel = subTipoFossil.filter(subTipo => subTipo.idTipoCombustivel === usinaFossil.subTipoUsinaFossil.idTipoCombustivel);
        });

        this.formUsinaFossil.controls.id.patchValue(usinaFossil.id);
        this.formUsinaFossil.controls.idTemp.patchValue(usinaFossil.idTemp);
        this.formUsinaFossil.controls.tipoFossil.patchValue(usinaFossil.tipoUsinaFossil);
        this.formUsinaFossil.controls.subTipoFossil.patchValue(usinaFossil.subTipoUsinaFossil);
        if (usinaFossil.tipoUsinaFossil.descricao === 'Outros fósseis') {
            this.mostrarCampoOutroTipo = true;
            this.formUsinaFossil.controls.descricaoTipo.patchValue(usinaFossil.descricao);
        } else if (usinaFossil.subTipoUsinaFossil) {
            if (usinaFossil.subTipoUsinaFossil.descricao === 'Outros Energéticos de Petróleo') {
                this.mostrarCampoOutroSubTipo = true;
                this.formUsinaFossil.controls.descricaoSubTipo.patchValue(usinaFossil.descricao);
            }
        }
        this.modoEdicao = true;
    }

    fecharEdicao(): void {
        this.modoEdicao = false;
        this.mostrarCampoOutroTipo = false;
        this.mostrarCampoOutroSubTipo = false;
        this.formUsinaFossil.reset();
        this.formUsinaFossil.markAsUntouched();
        this.formUsinaFossil.updateValueAndValidity();
    }

    deletarUsinaFossilItem(item, acao?: string, itemId?: number): void {
        if (acao === 'DELETAR' && itemId != null) {
            this.empreendimentoEnergService.deletarUsinaFosilPorId(itemId).subscribe(() => {
                this.snackBar.showSuccess('Usina Fossil excluido com sucesso.');
            });
        }
        let data;
        data = this.usinaFossilAdicionado.filter(value => {
            return value.idTemp !== item;
        });
        this.usinaFossilAdicionado = data;
        this.dataSourceUsinaFossil = new MatTableDataSource(this.usinaFossilAdicionado);
        this.enviarUsinas(this.mapearArray());
    }

    validarFormulario(): boolean {
        if (this.formUsinaFossil.valid) {
            return true;
        } else {
            this.snackBar.showAlert(
                'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            this.formUsinaFossil.markAllAsTouched();
            return false;
        }
    }

    mapearArray(): any {
        if (this.usinaFossilAdicionado.length == 0) {
            return this.usinaFossilAdicionado;
        } else {
            return this.usinaFossilAdicionado.map(function (item) {
                return new UsinaTipoFossil(
                    null,
                    null,
                    item.tipoUsinaFossil.id,
                    item.subTipoUsinaFossil ? item.subTipoUsinaFossil.id : null,
                    item.descricao,
                );
            });
        }
    }

    verificarVazio(elemento): boolean {
        return elemento === '' || elemento == null;
    }
}
