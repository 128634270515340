import {Component, Injector, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {PontoVazaoMensal} from '../../ponto-vazao-mensal/models/ponto-vazao-mensal.model';
import {PontoVazoesMensaisService} from '../../ponto-vazao-mensal/services/ponto-vazoes-mensais.service';
import {BaseModel} from '../../../models/base.model';
import {EnumMes} from '../../../enums/mes.enum';

@Component({
    selector: 'app-aba-titulacao-captacao-insignificante',
    templateUrl: 'aba-titulacao-captacao-insignificante.component.html'
})
export class AbaTitulacaoCaptacaoInsignificanteComponent implements OnInit {
    @Input() ponto: Ponto;
    pontoIsDRDH = false;
    pontoIsSubterraneo = false;
    isDiluicao = false;

    lancamentos: Array<PontoVazaoMensal>;
    displayedColumns: string[] = ['mes', 'vazao', 'tempo', 'periodo'];

    constructor(injector: Injector,
                private dialog: MatDialog,
                private lancamentoService: PontoVazoesMensaisService
    ) {
    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b;

    ngOnInit(): void {
        this.carregarLancamentos();
        this.atualizaInformacoes();
    }

    private carregarLancamentos(): void {
        this.lancamentoService
            .getAllByPonto(this.ponto)
            .subscribe(lancamentos => {
                this.lancamentos = lancamentos;
            });
    }

    getMesTexto(tipo: string): string {
        return EnumMes[tipo];
    }

    atualizaInformacoes() {
        this.pontoIsDRDH = this.ponto.objetivoRequerimento.objetivo.isDRDH;
        this.pontoIsSubterraneo = this.ponto.objetivoRequerimento.objetivo.isSubterraneo;
        this.isDiluicao = this.ponto.objetivoRequerimento.objetivo.diluicao;
    }

}
