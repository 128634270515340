export class FeicaoDTO {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipoGeometria?: string,
        public formulario?: number,
        public modeloFeicao?: number,
        public excluido?: boolean,
        public area?: number,
        public idModeloFeicao?: number
) {
    }
    static fromJson(json: any): FeicaoDTO {
        if (!json) {
            return null;
        }
        return new FeicaoDTO(
            json.id,
            json.descricao,
            json.tipoGeometria,
            json.formulario,
            json.modeloFeicao,
            json.excluido,
            json.area,
            json.idModeloFeicao,
        );
    }

    static fromJsons(jsonArray: any): FeicaoDTO[] {
        const resources = [];
        if (!jsonArray) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(FeicaoDTO.fromJson(resource)));
            return resources;
        }
    }
}

