import {BaseEnum} from '../enums/base.enum';

export class ModeloFeicaoDTO {
    constructor(
        public idModeloFeicao: number,
        public modeloFeicaoAQC?: any,
        public modeloFeicaoUpa?: any,
        public modeloFeicaoDesmate?: any,
        public modeloFeicaoEventual?: any,
        public modeloFeicaoSeletiva?: any,
        public modeloFeicaoTalhao?: any
    ) {
    }

    static motivosAqc: BaseEnum[] = [
        {descricao: "Resto de exploração Florestal", valor: "RESTO_EXPLORACAO_FLORESTAL"},
        {descricao: "Resto de Cultura", valor: "RESTO_CULTURA"},
        {descricao: "Limpeza de Pastagem", valor: "LIMPEZA_PASTAGEM"},
        {descricao: "Cana de Açúcar", valor: "CANA_ACUCAR"},
        {descricao: "Controle Fitossanitário", valor: "CONTROLE_FITOSSANITARIO"},
        {descricao: "Pesquisa Cientifica Experimental", valor: "PESQUISA_CIENTIFICA_EXPERIMENTAL"},
        {descricao: "Pesquisa Tecnológica", valor: "PESQUISA_TECNOLOGICA"},
        {descricao: "Outros", valor: "OUTROS"}
    ];

    static fromJson(json: any): ModeloFeicaoDTO {
        if (json === undefined || json === null) {
            return null;
        }
        return new ModeloFeicaoDTO(
            json.idModeloFeicao,
            json.modeloFeicaoAQC,
            json.modeloFeicaoUpa,
            json.modeloFeicaoDesmate,
            json.modeloFeicaoEventual,
            json.modeloFeicaoSeletiva,
            json.modeloFeicaoTalhao,
        );
    }

    static fromJsons(jsonArray: any): ModeloFeicaoDTO[] {
        const resources = [];
        if (jsonArray === undefined || jsonArray === null) {
            return [];
        } else {
            jsonArray.forEach(resource => resources.push(this.fromJson(resource)));
            return resources;
        }
    }
}
