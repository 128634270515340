import {DocumentoDoencaPessoa} from './documento-doenca-pessoa.model';

export enum EnumTipoDocumentoDoencaPessoa {
    CID = 'CID',
    LAUDO = 'Laudo médico'
}

export namespace EnumTipoDocumentoDoencaPessoa {

    /**
     * Lista os documentos passados como parametro, adicionando a estrutura para documentos á serem criados.
     * @param {DocumentoDoencaPessoa[]} documentos - Documentos já criados.
     */
    export function listarDocumentosRestantes(documentos: DocumentoDoencaPessoa[] = []): DocumentoDoencaPessoa[] {
        if (documentos == null) {
            documentos = [];
        }

        if (documentos.find(documento => documento.tipo === EnumTipoDocumentoDoencaPessoa.CID) == null) {
            documentos.push(new DocumentoDoencaPessoa(null, null, EnumTipoDocumentoDoencaPessoa.CID, null, false));
        }

        if (documentos.find(documento => documento.tipo === EnumTipoDocumentoDoencaPessoa.LAUDO) == null) {
            documentos.push(new DocumentoDoencaPessoa(null, null, EnumTipoDocumentoDoencaPessoa.LAUDO, null, false));
        }

        return documentos;
    }


    export function fromJson(json: any): EnumTipoDocumentoDoencaPessoa {
        if (json === 'CID') {
            return EnumTipoDocumentoDoencaPessoa.CID;
        }
        if (json === 'LAUDO_MEDICO') {
            return EnumTipoDocumentoDoencaPessoa.LAUDO;
        }

        return null;
    }

    export function toJson(tipo: EnumTipoDocumentoDoencaPessoa): string {
        if (tipo === EnumTipoDocumentoDoencaPessoa.CID) {
            return 'CID';
        }
        if (tipo === EnumTipoDocumentoDoencaPessoa.LAUDO) {
            return 'LAUDO_MEDICO';

            return null;
        }
    }
}
