import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { EnumSituacaoTitulo } from '../../../../shared/enums/EnumSituacaoTitulo';
import { EnumSituacaoTituloDecisao } from '../../../../shared/enums/EnumSituacaoTituloDecisao';
import { DocumentoProcesso } from '../../../../shared/models/documento-processo.model';
import { EmissaoTitulo } from '../../../../shared/models/emissao-titulo.model';
import { DocumentoProcessoService } from '../../../gerar-documento-publicacao/documento-processo.service';
import { EmissaoTituloService } from '../../../tarefas/shared/service/emissao-titulo.service';
import { ParecerRequerimentoConclusaoService } from '../../parecer-requerimento-conclusao/parecer-requerimento-conclusao.service';
import { ParecerTecnico } from '../../parecer-tecnico.model';
import { ConfirmacaoDialogService } from './confirmacao-dialog/confirmacao-dialog-service';


@Component({
    selector: 'app-parecer-tecnico-conclusoes-dialog',
    templateUrl: './parecer-tecnico-conclusoes-dialog.component.html',
    styleUrls: ['./parecer-tecnico-conclusoes-dialog.component.scss']
})
export class ParecerTecnicoConclusoesDialogComponent {

    displayedColumns: string[] = ['titulo', 'numero', 'situacao', 'situacaoDecisao', 'emissao', 'validade', 'acao'];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    emissoesTitulos: EmissaoTitulo[] = [];
    tableEmissoes: MatTableDataSource<EmissaoTitulo>;

    constructor(public dialogRef: MatDialogRef<ParecerTecnicoConclusoesDialogComponent>,
                private emissaoTituloService: EmissaoTituloService,
                private confirmacaoDialogService: ConfirmacaoDialogService,
                private snackBarService: SnackBarService,
                private documentoProcessoService: DocumentoProcessoService,
                private parecerRequerimentoConlusaoService: ParecerRequerimentoConclusaoService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.carregarRequerimentoTitulacao();
    }

    enumSituacaoTitulo = EnumSituacaoTitulo;
    enumSituacaoTituloDecisao = EnumSituacaoTituloDecisao;
    enumSituacaoTituloDecisao2 = EnumSituacaoTituloDecisao.SEM_DECISOES_PENDENTES;

    // Variáveis usadas para trocar as cores dos botões de ação dinamicamente.
    botaoAtivoColor = 'primary';
    botaoSuspensoColor = 'darkgoldenrod';
    botaoCanceladoColor = 'warn';
    botaoDisable = 'gray';

    fecharDialog(): void {
        this.dialogRef.close();
    }

    carregarRequerimentoTitulacao(): void {
        this.emissaoTituloService
            .getEmissoesPorImovel(this.data.requerimento.id)
            .subscribe(emissoesTitulos => {
                this.emissoesTitulos = emissoesTitulos;
                this.carregarTabela();
            });
    }

    carregarTabela(): void {
        this.tableEmissoes = new MatTableDataSource(this.emissoesTitulos);
        this.tableEmissoes.paginator = this.paginator;
        this.tableEmissoes.sort = this.sort;
    }

    alterarSituacaoAtivo(emissaoTitulo: EmissaoTitulo): void {
        if (emissaoTitulo.situacaoTituloDecisao === this.enumSituacaoTituloDecisao.SEM_DECISOES_PENDENTES) {
            this.confirmacaoDialogService.openConfimDialog('Solicitar reabilitação', 'Título', emissaoTitulo.parecerRequerimentoConclusao.requerimentoTitulacao.titulacao.descricao)
                .afterClosed().subscribe(res => {
                if (res) {
                    this.salvarConclusao(emissaoTitulo, this.enumSituacaoTitulo.ATIVO, this.enumSituacaoTituloDecisao.AGUARDANDO_REABILITACAO, this.data.parecerTecnico);
                }
            });
        } else {
            this.snackBarService.showAlert('Não é possível solicitar uma mudança neste título, outra solicitação já está sendo analisada.');
        }
    }

    alterarSituacaoSuspenso(emissaoTitulo: EmissaoTitulo, parecerTecnico?: ParecerTecnico): void {
        if (emissaoTitulo.situacaoTituloDecisao === this.enumSituacaoTituloDecisao.SEM_DECISOES_PENDENTES) {
            this.confirmacaoDialogService.openConfimDialog('Solicitar suspenção', 'Título', emissaoTitulo.parecerRequerimentoConclusao.requerimentoTitulacao.titulacao.descricao)
                .afterClosed().subscribe(res => {
                if (res) {
                    this.salvarConclusao(emissaoTitulo, this.enumSituacaoTitulo.SUSPENSO, this.enumSituacaoTituloDecisao.AGUARDANDO_SUSPENSAO, this.data.parecerTecnico);
                }
            });
        } else {
            this.snackBarService.showAlert('Não é possível solicitar uma mudança neste título, outra solicitação já está sendo analisada.');
        }
    }

    alterarSituacaoCancelado(emissaoTitulo: EmissaoTitulo): void {
        if (emissaoTitulo.situacaoTituloDecisao === this.enumSituacaoTituloDecisao.SEM_DECISOES_PENDENTES) {
            this.confirmacaoDialogService.openConfimDialog('Solicitar cancelamento', 'Título', emissaoTitulo.parecerRequerimentoConclusao.requerimentoTitulacao.titulacao.descricao)
                .afterClosed().subscribe(res => {
                if (res) {
                    this.salvarConclusao(emissaoTitulo, this.enumSituacaoTitulo.CANCELADO, this.enumSituacaoTituloDecisao.AGUARDANDO_CANCELAMENTO, this.data.parecerTecnico);
                }
            });
        } else {
            this.snackBarService.showAlert('Não é possível solicitar uma mudança neste título, outra solicitação já está sendo analisada.');
        }
    }

    download(documentoProcesso: DocumentoProcesso): void {
        window.open(
            `${this.documentoProcessoService.urlResource}/${documentoProcesso.id}/download`
        );
    }

    salvarConclusao(emissao: EmissaoTitulo, enumSituacaoTitulo: EnumSituacaoTitulo, enumSituacaoTituloDecisao: EnumSituacaoTituloDecisao, parecerTecnico: ParecerTecnico): void {
        this.parecerRequerimentoConlusaoService
            .createParecerEmissaoTitulo(emissao, enumSituacaoTitulo, parecerTecnico)
            .subscribe(() => {
                emissao.situacaoTituloDecisao = enumSituacaoTituloDecisao;
                this.emissaoTituloService.alterarSituacaoTituloDecisao(emissao)
                    .subscribe(() => {
                        this.snackBarService.showSuccess('Mudança na títulação enviada com sucesso.');
                        this.carregarRequerimentoTitulacao();
                    });
            });

    }

    getDescription(emissao: any): any {
        return EmissaoTitulo.situacaoTituloDecisao.filter(e => e.valor === emissao)[0].descricao;
    }
}
