import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {GeometriaDTO} from '../../../../../shared/models/geometria-dto.model';

export interface ConfirmarExclusaoPontoDialogData {
    denominacao: string;
    pontoGeoDto: GeometriaDTO;
}

@Component({
    templateUrl: './confirmar-exclusao-ponto-licenciamento-dialog.component.html'
})
export class ConfirmarExclusaoPontoLicenciamentoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmarExclusaoPontoLicenciamentoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmarExclusaoPontoDialogData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        this.dialogRef.close(true);
    }

}
