import {BaseModel} from "../base.model";

export class ParametroObjetivo extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public idTipoAtuacao?: number,
        public situacao?: boolean,
        public idTipoProcesso?: number
    ) {
        super();
    }

    static fromJson(json: any): ParametroObjetivo {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParametroObjetivo(
            json.id,
            json.descricao,
            json.idTipoAtuacao,
            json.situacao,
            json.idTipoProcesso
        );
    }

    static fromJsons(json: any): ParametroObjetivo[] {
        const parametros: ParametroObjetivo[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(parametro => parametros.push(this.fromJson(parametro)));
            return parametros;
        }
    }

}
