import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from '../../shared/enums/base.enum';
import { TipoProcesso } from '../requerimentos/menu-novo-requerimento/tipo-processo-novo.model';

export class TipoRequerimento extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public tipoAtuacao?: string,
        public tipoFluxo?: string,
        public tipoProcesso?: TipoProcesso,
        public ativo?: boolean
    ) {
        super();
    }

    static tiposFluxo: BaseEnum[] = [
        {valor: 'ALTERAR', descricao: 'Alterar'},
        {valor: 'CANCELAR', descricao: 'Cancelar'},
        {valor: 'EMITIR', descricao: 'Emitir'},
        {valor: 'REABILITAR', descricao: 'Reabilitar'},
        {valor: 'RENOVAR', descricao: 'Renovar'},
        {valor: 'TRANSFERIR', descricao: 'Transferir'}

    ];

    get isFluxoAlterar(): boolean {
        return this.tipoFluxo === 'ALTERAR';
    }

    get isFluxoCancelar(): boolean {
        return this.tipoFluxo === 'CANCELAR';
    }

    get isFluxoEmitir(): boolean {
        return this.tipoFluxo === 'EMITIR';
    }

    get isFluxoReabilitar(): boolean {
        return this.tipoFluxo === 'REABILITAR';
    }

    get isFluxoRenovar(): boolean {
        return this.tipoFluxo === 'RENOVAR';
    }

    get isFluxoTransferir(): boolean {
        return this.tipoFluxo === 'TRANSFERIR';
    }

    get isFluxoAmpliacao(): boolean {
        return this.descricao.toLowerCase().includes('ampliação');
    }

    static fromJson(json: any): TipoRequerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoRequerimento(
            json.id,
            json.descricao,
            json.dataInicio,
            json.dataFim,
            json.tipoAtuacao,
            json.tipoFluxo,
            TipoProcesso.fromJson(json.tipoProcesso),
            json.ativo
        );
    }
}
