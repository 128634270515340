import {Component, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {RequerimentoService} from "../requerimento.service";
import {Requerimento} from "../requerimento.model";
import {take} from "rxjs/operators";
import {DominioDto} from "../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../shared/services/dominios.service";
import {PrioridadeProcessoService} from "../../../shared/services/prioridade-processo.service";
import {PrioridadeProcesso} from "../../../shared/models/prioridade-processo.model";
import {SnackBarService} from "../../../shared/snack-bar/snack-bar.service";
import {CriterioDTO} from "./model/criterio.dto";
import {PrioridadeProcessoCriterio} from "../../../shared/models/prioridade-processo-criterio.model";

@Component({
    selector: 'app-definir-prioridade-processo',
    templateUrl: './definir-prioridade-processo.component.html',
    styleUrls: ['./definir-prioridade-processo.component.scss'],
    animations: fuseAnimations
})
export class DefinirPrioridadeProcessoComponent implements OnInit {

    displayedColumnsCriterios: string[] = ['criterio', 'arquivo', 'sigiloso', 'acoes'];
    criterios: CriterioDTO[] = [];
    requerimento: Requerimento;
    form: FormGroup;
    arquivo: File;

    justificativa = new FormControl('');
    criterio = new FormControl('');
    sigiloso = new FormControl('');

    listaCriterios: DominioDto[] = [];
    somenteLeitura = false;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly requerimentoService: RequerimentoService,
        private readonly fb: FormBuilder,
        private readonly dominiosService: DominiosService,
        private readonly prioridadeProcessoService: PrioridadeProcessoService,
        private readonly snackBarService: SnackBarService
    ) {
        let id = activatedRoute.snapshot.params['id'];
        if (id) {
            this.requerimentoService.getById(id).pipe(
                take(1)
            ).subscribe(requerimento => this.requerimento = requerimento);
        }
    }

    async ngOnInit(): Promise<void> {
        this.form = this.fb.group(this.returnObjectForm());
        await this.recuperaCriterios();

        let idPrioridade = this.activatedRoute.snapshot.params['idPrioridade'];
        if (idPrioridade) {
            this.somenteLeitura = true;

            this.prioridadeProcessoService.getById(idPrioridade).pipe(
                take(1)
            ).subscribe(prioridade => {
                this.justificativa.patchValue(prioridade.justificativa);
                this.criterios = prioridade.criterios.map(x => ({
                    nomeArquivo: x.nomeArquivo,
                    sigiloso: x.sigiloso,
                    criterio: x.tipoPrioridade
                }) as CriterioDTO);
            });
        }
    }

    private async recuperaCriterios(): Promise<void> {
        this.listaCriterios = await this.dominiosService.getDominio('TIPO_PRIORIDADE_PROCESSO').toPromise();
    }

    private returnObjectForm(): any {
        return {
            justificativa: this.justificativa,
            criterio: this.criterio,
            sigiloso: this.sigiloso
        };
    }

    removerCriterio(item): void {
        const index = this.criterios.indexOf(item, 0);
        if (index > -1) {
            this.criterios.splice(index, 1);
            this.criterios = [...this.criterios]
        }
    }

    adicionarCriterio(): void {
        this.criterios = [...this.criterios, new CriterioDTO(this.sigiloso.value, this.criterio.value, this.arquivo)];
        this.sigiloso.reset();
        this.criterio.reset();
        this.arquivo = null;
    }

    getNomeCriterio(id: number): string {
        return this.listaCriterios.find(x => x.id == id).descricao;
    }

    onFileSelect(event: any): void {
        if (event.target.files.length === 1) {
            this.arquivo = event.target.files[0];
        } else {
            this.arquivo = null;
        }
    }

    salvar(): void {
        const res = PrioridadeProcesso.fromJson(this.form.value);
        res.requerimento = this.requerimento;
        this.prioridadeProcessoService.create(res).pipe(
            take(1)
        ).subscribe(async prioridade => {
            for (let criterio of this.criterios) {
                let item = new PrioridadeProcessoCriterio();
                item.nomeArquivo = criterio.arquivo.name;
                item.tipoPrioridade = criterio.criterio;
                item.prioridadeProcesso = prioridade;
                await this.prioridadeProcessoService.createFile(item, criterio.arquivo).toPromise();
            }

            this.snackBarService.showSuccess('Solicitação processada com sucesso!');
            this.voltar();
        }, error => {
            const errorMensagem = error !== '' ? error : 'Erro ao processar sua solicitação';
            this.snackBarService.showError(errorMensagem);
        })
    }

    voltar(): void {
        this.router.navigateByUrl('/consulta-requerimentos-processos').then();
    }

}