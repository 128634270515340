export enum StatusGeometria{
    ENVIADA = 'ENVIADA',
    AGUARDANDO_ANALISE = 'AGUARDANDO_ANALISE',
    EM_ANALISE = 'EM_ANALISE',
    APROVADA = 'APROVADA',
    REPROVADA = 'REPROVADA',
}

export const statusGeometriaLabel: any = {
    [StatusGeometria.ENVIADA]: 'Enviada',
    [StatusGeometria.AGUARDANDO_ANALISE]: 'Aguardando análise',
    [StatusGeometria.EM_ANALISE]: 'Em análise',
    [StatusGeometria.REPROVADA]: 'Reprovada',
    [StatusGeometria.APROVADA]: 'Aprovada',
};