import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Imovel} from '../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model';
import {SnackBarService} from '../../snack-bar/snack-bar.service';
import {ImovelService} from '../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.service';
import {RequerimentoArquivoSimcarDTO} from '../../models/requerimento-arquivo-simcar-dto.model';
import {BaseEnum} from '../../enums/base.enum';
import { DocumentoImovel } from '../../models/documento-imovel.model';
import { Requerimento } from 'app/main/pages/requerimentos/requerimento.model';

@Component({
    selector: 'app-documento-imovel-rural',
    templateUrl: './documento-imovel-rural.component.html',
    styleUrls: ['./documento-imovel-rural.component.scss'],
})
export class DocumentoImovelRuralComponent {

    @Input() 
    set imovel(i: Imovel){
        this.imovel$ = i;
        this.listarArquivos();
    }
    imovel$: Imovel;
    arquivos: RequerimentoArquivoSimcarDTO[];
    @Output() public updateArquivosEmitter: EventEmitter<RequerimentoArquivoSimcarDTO[]> = new EventEmitter();

    constructor(
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService,
        private imovelService: ImovelService
    ) {
    }

    public listarArquivos(): void {
        this.arquivos = [];
        if (this.imovel$) {
            if(this.imovel$.id){
                this.imovelService
                    .getArquivosSimcarByImovel(this.imovel$)
                    .subscribe(arquivos => {
                        this.arquivos = arquivos;
                        this.updateArquivosEmitter.emit(this.arquivos);
                    });
            }else{
                this.imovelService
                    .getArquivosSimcarByIdSimcar(this.imovel$.idSimcar)
                    .subscribe(arquivos => {
                        this.arquivos = arquivos;
                        this.updateArquivosEmitter.emit(this.arquivos);
                    });
            }
        }
    }

    public visualizarDocumento(arquivo: RequerimentoArquivoSimcarDTO): void {
        this.snackBarService.showAlert('Esta funcionalidade está indisponível.');
    }

    public downloadDocumento(arquivo: RequerimentoArquivoSimcarDTO): void {
        this.snackBarService.showAlert('Esta funcionalidade está indisponível.');
    }

    get categorias(): BaseEnum[] {
        return RequerimentoArquivoSimcarDTO.categorias;
    }

    get descricoes(): BaseEnum[] {
        return RequerimentoArquivoSimcarDTO.descricoes;
    }

}