import {Requerimento} from "../../pages/requerimentos/requerimento.model";
import {Despacho} from "../components/despacho-cabecalho/despacho.model";
import {BaseModel} from "./base.model";
import {BaseEnum} from "../enums/base.enum";

export class AnaliseLicencaPreviaConsema extends BaseModel{
    constructor(
        public id?: number,
        public requerimento?: Requerimento,
        public despacho?: Despacho,
        public dataConclusao?: Date,
        public decisao?: String
    ) {
        super();
    }

    static decisao: BaseEnum[] = [
        {valor: 'PARECER_TECNICO_LICENCA_PREVIA_REFERENDADO', descricao: 'Parecer técnico licença prévia referendado'},
        {valor: 'PARECER_TECNICO_LICENCA_NAO_REFERENDADO_REVISAO', descricao: 'Parecer tècnico licença prévia não refendado, solicito revisão'},
        {valor: 'PARECER_TECNICO_LICENCA_NAO_REFERENDADO_ARQUIVAR', descricao: 'Parecer tècnico licença prévia não refendado, solicito arquivamento definitivo'},
    ]

    static fromJson(json: any): AnaliseLicencaPreviaConsema {
        if (json === undefined || json === null) {
            return null;
        }
        return new AnaliseLicencaPreviaConsema(
            json.id,
            Requerimento.fromJson(json.requerimento),
            Despacho.fromJson(json.despacho),
            json.dataConclusao,
            json.decisao
        )
    }

    static fromJsons(json: any): AnaliseLicencaPreviaConsema[] {
        const resources: AnaliseLicencaPreviaConsema[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: AnaliseLicencaPreviaConsema) => resources.push(AnaliseLicencaPreviaConsema.fromJson(e)));
        }
        return resources;
    }

}