import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import {RequerimentoTermoReferenciaEspecificoModel} from "./requerimento-termo-referencia-especifico.model";
import {Observable} from "rxjs";
import {Requerimento} from "../requerimento.model";
import {RequerimentoLicenciamento} from "../requerimento-licenciamento/requerimento-licenciamento.model";
import {FormularioReqLicenciamentoEmp} from "../../../shared/models/formulario-req-licenciamento-emp.dto";

@Injectable({
    providedIn: 'root',
})
export class RequerimentoTermoReferenciaEspecificoService extends BaseService<RequerimentoTermoReferenciaEspecificoModel> {
    constructor(protected injector: Injector) {
        super(
            '/api/requerimentos-termo-referencia-especifico',
            injector,
            RequerimentoTermoReferenciaEspecificoModel.prototype,
            RequerimentoTermoReferenciaEspecificoModel.fromJson
        );
    }

    getByRequerimentoId(requerimentoId: number): Observable<RequerimentoLicenciamento> {
        const url = `${this.urlResource}/requerimento/${requerimentoId}`;
        return this.http.get<RequerimentoLicenciamento>(url);
    }

    salvarFormularioCalculoDeLicencaEmp(formulario: FormularioReqLicenciamentoEmp): Observable<FormularioReqLicenciamentoEmp> {
        const url = `${this.urlResource}/salvar-formulario-req-termo-referencia`;
        return this.http
            .post(url, formulario)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    deleteEmp(id: number): Observable<any> {
        const url = `${this.urlResource}/req-emp/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
}
