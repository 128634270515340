import {BaseModel} from 'app/main/shared/models/base.model';
import {AssuntoNota} from 'app/main/shared/models/assunto-nota.model';
import {Requerimento} from '../requerimento.model';
import * as moment from 'moment';

export class RequerimentoNota extends BaseModel {
    constructor(
        public id?: number,
        public analista?: string,
        public comentario?: string,
        public dataInicio?: string,
        public requerimento?: Requerimento,
        public assunto?: AssuntoNota
    ) {
        super();
    }

    get descricao(): string {
        return `${this.assunto} - ${this.analista} - ${this.dataInicio}`;
    }

    static fromJson(json: any): RequerimentoNota {
        json = json || {};

        if (typeof json.dataInicio === 'string') {
            json.dataInicio = moment.utc(json.dataInicio).local().format('DD/MM/YYYY');
        }

        if (typeof json.assunto === 'object') {
            json.assunto = AssuntoNota.fromJson(json.assunto);
        }

        if (typeof json.requerimento === 'object') {
            json.requerimento = Requerimento.fromJson(json.requerimento);
        }

        return new RequerimentoNota(
            json.id,
            json.analista,
            json.comentario,
            json.dataInicio,
            json.requerimento,
            json.assunto
        );
    }

    static fromIterable(json: any): Array<RequerimentoNota> {
        const notas = new Array<RequerimentoNota>();

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    notas.push(RequerimentoNota.fromJson(value));
                }
            }
        }

        return notas;
    }
}