import {BaseModel} from '../models/base.model';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {BaseService} from '../services/base.service';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';

export class DetailResolver<T extends BaseModel> implements Resolve<T> {
    constructor(
        private service: BaseService<T>,
        private routingStateService: RoutingStateService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<T> {
        this.routingStateService.addQueryParamsHistory(route.queryParams);
        if (route.params['id'] != null) {
            return this.service.getById(route.params['id']);
        }
    }
}