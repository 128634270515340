import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {MatDialog} from '@angular/material/dialog';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {TipoSistemaCaptacao} from '../../../../../shared/models/tipo-sistema-captacao.model';
import {TipoSistemaCaptacaoService} from '../finalidade-tanque-rede/services/tipo-sistema-captacao.service';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {BaseModel} from '../../../../../shared/models/base.model';
import {TipoCorpoHidricoService} from 'app/main/shared/services/tipo-corpo-hidrico.service';
import {TipoCorpoHidrico} from 'app/main/shared/models/tipo-corpo-hidrico.model';
import {Captacao} from '../../../../../shared/models/captacao.model';
import {BasePontoComponent} from '../base-ponto.component';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {isNullOrUndefined} from "util";

@Component({
    selector: 'app-objetivo-captacao-superficial',
    templateUrl: './objetivo-captacao-superficial.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})

export class ObjetivoCaptacaoSuperficialComponent extends BasePontoComponent implements OnInit {
    formCaptacaoSuperficialPonto: FormGroup;
    tipoCorposHidricos: Array<TipoCorpoHidrico>;
    tipoSistemaCaptacao: Array<TipoSistemaCaptacao>;

    lancamentosLength: number;
    condicaoFormularioConcluido = (ponto?: Ponto): boolean => this.lancamentosLength > 0 &&
        !isNullOrUndefined((ponto ? ponto : this.ponto).captacao)

    constructor(
        private pontoService: PontoService,
        private tipoCorpoHidricoService: TipoCorpoHidricoService,
        private tipoSistemaCaptacaoService: TipoSistemaCaptacaoService,
        private formBuilder: FormBuilder,
        protected injector: Injector,
        private snackBarService: SnackBarService,
        public dialog: MatDialog
    ) {
        super();
    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    ngOnInit(): void {
        this.buildResourceForm();
        this.patchPonto();
        this.carregarTipoCorpoHidrico();
        this.carregarTipoSistemaCaptacao();
        if (this.isModoVisualizacao) {
            this.formCaptacaoSuperficialPonto.disable();
        }
    }

    private patchPonto(): void {
        this.formCaptacaoSuperficialPonto.patchValue({
            id: this.ponto.id,
            tipoCorpoHidrico: this.ponto.tipoCorpoHidrico,
            outroTipoCorpoHidrico: this.ponto.outroTipoCorpoHidrico,
            corpoHidrico: this.ponto.corpoHidrico
        });
        if (this.ponto.captacao) {
            this.formCaptacaoSuperficialPonto.controls.captacao.setValue(this.ponto.captacao);
        }

    }

    buildResourceForm(): void {
        this.formCaptacaoSuperficialPonto = this.formBuilder.group({
            id: [null],
            captacao: this.formBuilder.group({
                id: [null],
                tipoSistema: [null, [Validators.required]],
                especificacao: [{value: null, disabled: true}],
            }),
            tipoCorpoHidrico: [null, [Validators.required]],
            outroTipoCorpoHidrico: [{value: null, disabled: true}, Validators.maxLength(255)],
            corpoHidrico: [null]
        });
    }

    onTipoCorpoHidricoChanged(event): void {
        if (event) {
            const field = this.formCaptacaoSuperficialPonto.controls.outroTipoCorpoHidrico;
            if (TipoCorpoHidrico.fromJson(this.formCaptacaoSuperficialPonto.controls.tipoCorpoHidrico.value).outro) {
                field.enable();
                field.setValidators([Validators.required,
                    Validators.maxLength(255)]);
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValidators([]);
                field.setValue('');
                field.updateValueAndValidity();
            }
        }
    }

    onChangeTipoSitemaCaptacao(event): void {
        if (event) {
            const field = this.formCaptacaoSuperficialPonto.controls.captacao.get('especificacao');
            if (TipoSistemaCaptacao.fromJson(this.formCaptacaoSuperficialPonto.controls.captacao.get('tipoSistema').value).outro) {
                field.enable();
                field.setValidators([
                    Validators.required,
                    Validators.maxLength(255)
                ]);
                field.updateValueAndValidity();
            } else {
                field.disable();
                field.setValue('');
                field.setValidators([]);
                field.updateValueAndValidity();
            }
        }

    }

    private carregarTipoSistemaCaptacao(): void {
        this.tipoSistemaCaptacaoService
            .getAll()
            .subscribe(tipoSistemaCaptacao => (this.tipoSistemaCaptacao = tipoSistemaCaptacao));
    }

    private carregarTipoCorpoHidrico(): void {
        this.tipoCorpoHidricoService
            .getAll()
            .subscribe(tipoCorpoHidricoService => (this.tipoCorposHidricos = tipoCorpoHidricoService));
    }

    salvarPonto(): void {
        this.formCaptacaoSuperficialPonto.markAllAsTouched();
        if (!this.formCaptacaoSuperficialPonto.invalid) {
            const dtoPonto = Ponto.fromJson(this.ponto);
            dtoPonto.captacao = Captacao.fromJson(this.formCaptacaoSuperficialPonto.controls.captacao.value);
            dtoPonto.tipoCorpoHidrico = this.formCaptacaoSuperficialPonto.controls.tipoCorpoHidrico.value;
            dtoPonto.outroTipoCorpoHidrico = this.formCaptacaoSuperficialPonto.controls.outroTipoCorpoHidrico.value;
            dtoPonto.corpoHidrico = this.formCaptacaoSuperficialPonto.controls.corpoHidrico.value;
            dtoPonto.formularioConcluido = this.condicaoFormularioConcluido(dtoPonto);
            this.pontoService.update(dtoPonto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.formCaptacaoSuperficialPonto.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    onChangeLancamentos(length: number) {
        this.lancamentosLength = length

        if (this.ponto.formularioConcluido != this.condicaoFormularioConcluido()) {
            this.ponto.formularioConcluido = this.condicaoFormularioConcluido()
            this.pontoService.update(this.ponto).subscribe(result => Object.assign(this.ponto, result))
        }
    }
}


