import {Injectable, Injector} from "@angular/core";
import {BaseService} from "../base.service";
import {AtividadeConfiguracaoLicenciamento} from "../../models/licenciamento-config-models/atividade-configuracao-licenciamento.model";
import {environment} from "../../../../../environments/environment";
import {AtividadeParametroLicenciamento} from "../../models/licenciamento-config-models/atividade-parametro-licenciamento.model";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AbaAtividadesDto} from "../../models/licenciamento-config-models/aba-atividades-dto.model";

@Injectable({
    providedIn: 'root'
})
export class AtividadeParametroLicenciamentoService extends BaseService<AtividadeParametroLicenciamento> {

    constructor(protected injector: Injector) {
        super(
            '',
            injector,
            AtividadeParametroLicenciamento.prototype,
            AtividadeParametroLicenciamento.fromJson
        );
        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/atividade-parametro-licenciamento'
    }

    buscarFormulariosPorIdAtividade(idAtividade: number, idRequerimentoLicenciamento: number): Observable<AbaAtividadesDto[]> {
        const url = `${this.urlResource}/buscar-form-por-id-atividade/${idAtividade}/${idRequerimentoLicenciamento}`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    buscarTodosFormularios(): Observable<AbaAtividadesDto[]> {
        this.urlResource = environment.URL_LICENCIAMENTO_CONFIG_API + '/api/formulariolicenciamentoambiental'
        const url = `${this.urlResource}/`;

        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}