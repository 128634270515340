import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Injectable, Injector} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseCorporativoService} from "./base-corporativo.service";
import { OrganizacaoAdministrativa } from "../models/organizacao-administrativa.model";
import { OrganizacaoAdministrativaDTO } from "app/main/pages/variavel-grupo-camunda/organizacao-admistrativa-dto.model";

@Injectable({
    providedIn: 'root'
})
export class OrganizacaoAdministrativaCorporativoService extends BaseCorporativoService<any>{

    constructor(protected injector: Injector) {
        super('/api/organizacoes-administrativas', injector, null, null);
    }

    buscarSetoresQuePossuemFormacaoEspecifica(idProfissao?: number): Observable<any> {
        let httpParams = new HttpParams();
        if(idProfissao){
            httpParams = httpParams.set('idProfissao', idProfissao.toString());
        }
        const url = `${this.urlResourceCorporativo}/setores-por-formacao-especifica`;
        return this.http.get(url, {params: httpParams})
            .pipe(map(response => response),
                catchError(e => throwError(e)))
    }

    buscarFormacoesDisponivelPorIdSetor(idSetor: number): Observable<any> {
        const url = `${this.urlResourceCorporativo}/formacoes-por-setor-id/${idSetor}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e)))
    }

    isPerfilSuperintendenciaOrGabinete(cpf: string): Observable<any> {
        const url = `${this.urlResourceCorporativo}/is-superintendencia-gabinete/${cpf}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e)))
    }

    buscarTodasAtivas(): Observable<any> {
        const url = `${this.urlResourceCorporativo}`;
        return this.http.get(url)
            .pipe(map(response => response),
                catchError(e => throwError(e)))
    }

    getAllByDataFimIsNull(): Observable<OrganizacaoAdministrativaDTO[]> {
        const url = `${this.urlResourceCorporativo}/lista-all/ativas`;
        return this.http
            .get(url)
            .pipe(map(array => OrganizacaoAdministrativaDTO.fromJsons(array)), catchError(this.handleError.bind(this)));
    }
}