import {BaseModel} from 'app/main/shared/models/base.model';
import {Finalidade} from 'app/main/pages/finalidades/finalidade.model';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {AtividadeArrecadacao} from './arrecadacao-models/atividade-arrecadacao.model';
import {FinalidadeAtividade} from "../../pages/finalidades-atividade/finalidade-atividade.model";

export class RequerimentoFinalidadeAtividade extends BaseModel {
    constructor(
        public id?: number,
        public atividade?: AtividadeArrecadacao,
        public finalidade?: Finalidade,
        public requerimento?: Requerimento,
        public finalidadeAtividade?: FinalidadeAtividade,
        public ppd?: string
    ) {
        super();
    }

    static fromJson(json: any): RequerimentoFinalidadeAtividade {
        if (json === undefined || json === null) {
            return null;
        }
        return new RequerimentoFinalidadeAtividade(
            json.id,
            AtividadeArrecadacao.fromJson(json.atividade),
            Finalidade.fromJson(json.finalidade),
            Requerimento.fromJson(json.requerimento),
            FinalidadeAtividade.fromJson(json.finalidadeAtividade),
            json.ppd
        );
    }

    static fromJsons(json: any): RequerimentoFinalidadeAtividade[] {
        const requerimentoFinalidadesAtividades: RequerimentoFinalidadeAtividade[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(requerimentoFinalidadeAtividade => {
                requerimentoFinalidadesAtividades.push(this.fromJson(requerimentoFinalidadeAtividade));
            });
            return requerimentoFinalidadesAtividades;
        }
    }
}
