import { Pipe, PipeTransform } from '@angular/core';
import {RegraGeoFeicao} from "../models/geoadmin/regra-geo-feicao.model";
import {verificaAtivacaoRegra} from "./regra-geo-icone.pipe";
import {TipoRegraGeo} from "../enums/tipo-regra-geo.enum";
@Pipe({
    name: 'regraGeoClasses'
})
export class RegraGeoClassesPipe implements PipeTransform {
    transform(regra: RegraGeoFeicao, cenarioId: number):string {
        const encontrouErros = verificaAtivacaoRegra(regra, cenarioId);
        if (encontrouErros && regra.tipoRegra === TipoRegraGeo.ALERTA) {
            return 'analises bg-atencao';
        } else if (!encontrouErros) {
            return 'analises bg-aprovado';
        } else if (encontrouErros && regra.tipoRegra === TipoRegraGeo.IMPEDITIVA) {
            return 'analises bg-reprovado';
        }
        return 'analises';
    }
}