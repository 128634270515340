import {Injectable, Injector} from "@angular/core";
import {catchError, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {PrioridadeProcesso} from "../models/prioridade-processo.model";
import {BaseService} from "./base.service";
import {HttpParams} from "@angular/common/http";
import {Page} from "../models/page.model";
import {
    HistoricoPrioridadeProcessoDTO
} from "../../pages/requerimentos/consulta-requerimentos-processos/model/historico-prioridade-processo.dto";
import {PrioridadeProcessoCriterio} from "../models/prioridade-processo-criterio.model";

@Injectable({
    providedIn: 'root'
})
export class PrioridadeProcessoService extends BaseService<PrioridadeProcesso> {

    constructor(protected injector: Injector) {
        super('/api/prioridade-processo', injector, PrioridadeProcesso.prototype, PrioridadeProcesso.fromJson);
    }

    getByRequerimentoId(idRequerimento: number): Observable<PrioridadeProcesso> {
        return this.http.get(`${this.urlResource}/requerimento/${idRequerimento}`)
        .pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getByHistoricoRequerimentoId(idRequerimento: number, httpParams: HttpParams): Observable<Page<HistoricoPrioridadeProcessoDTO>> {
        return this.http.get(`${this.urlResource}/requerimento/${idRequerimento}/historico`, {params: httpParams}).pipe(
            map(this.jsonToPageHistorico.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    protected jsonToPageHistorico(json: any): Page<HistoricoPrioridadeProcessoDTO> {
        return Page.fromJson(
            this.jsonToResourcesHistorico(json.content),
            json,
            this.type
        );
    }

    protected jsonToResourcesHistorico(json: any): HistoricoPrioridadeProcessoDTO[] {
        const resources: HistoricoPrioridadeProcessoDTO[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: HistoricoPrioridadeProcessoDTO) => resources.push(HistoricoPrioridadeProcessoDTO.fromJson(e)));
        }
        return resources;
    }

    createFile(prioridade: PrioridadeProcessoCriterio, file: File): Observable<PrioridadeProcessoCriterio> {
        return this.http.post(`${this.urlResource}/file`, this.createFormData(prioridade, file)).pipe(
            map(PrioridadeProcessoCriterio.fromJson.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    private createFormData(prioridade: PrioridadeProcesso, file: File): FormData {
        const formData = new FormData();
        formData.append('file', file);
        const prioridadeBlob = new Blob([JSON.stringify(prioridade)], {type: 'application/json'});
        formData.append('prioridadeProcessoCriterio', prioridadeBlob);
        return formData;
    }
}