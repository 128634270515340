import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../../../../../../shared/snack-bar/snack-bar.service";
import {RodoviasEmpObrasViarias} from "../../../../../../../../shared/models/rodovias-emp-obras-viarias.model";
import {InfraestruturaObrasViariasService} from "../infraestrutura-obras-viarias.service";

@Component({
    selector: 'app-rodovias',
    templateUrl: './rodovias.component.html',
    styleUrls: ['./rodovias.component.scss']
})
export class RodoviasComponent implements OnInit {

    @Input() idForm: any;
    @Input() readonly: boolean = false;
    @Input() isNaoPodeEditar: boolean;
    @Output() emitirRodovias = new EventEmitter<any[]>();
    formRodovias: FormGroup;
    rodoviasSalvas: any = [];
    idRodovias: number = 0;
    dataSourceRodovias = new MatTableDataSource<any>([]);

    constructor(private formBuilder: FormBuilder,
                private snackBarService: SnackBarService,
                private infraObrasViariasService: InfraestruturaObrasViariasService) {
    }

    ngOnInit() {
        this.buildFormRodovias();

        if (this.idForm) {
            this.infraObrasViariasService.buscarRodoviasPorId(this.idForm).subscribe(response => {
                response.forEach(rodovias => {
                    this.rodoviasSalvas.push({
                        id: rodovias.id,
                        idTemp: this.idRodovias,
                        rodoviaBr: rodovias.rodoviaBr,
                        rodoviaMt: rodovias.rodoviaMt,
                    });
                    this.idRodovias++;
                })
                this.dataSourceRodovias = new MatTableDataSource(this.rodoviasSalvas);
                this.enviarRodovias(this.mapearArray());
            })
        }
    }

    ngOnChanges(): void {
        if (this.isNaoPodeEditar) {
            this.formRodovias.disable();
        }
    }

    private buildFormRodovias(): void {
        this.formRodovias = this.formBuilder.group({
            id: [null],
            idTemp: [null],
            rodoviaBr: ["", [Validators.pattern("^[0-9]*$")]],
            rodoviaMt: ["", [Validators.pattern("^[0-9]*$")]],
        })
    }

    mapearArray(): any {
        if (this.rodoviasSalvas.length == 0) {
            return this.rodoviasSalvas;
        } else {
            return this.rodoviasSalvas.map(function (item) {
                return new RodoviasEmpObrasViarias(
                    item.id,
                    null,
                    Number(item.rodoviaBr),
                    Number(item.rodoviaMt),
                );
            });
        }
    }

    adicionarRodovia() {
        if (this.formRodovias.valid) {
            this.rodoviasSalvas.push({
                id: null,
                idTemp: this.idRodovias,
                rodoviaBr: this.formRodovias.controls.rodoviaBr.value,
                rodoviaMt: this.formRodovias.controls.rodoviaMt.value
            })
            this.idRodovias++;
            this.dataSourceRodovias = new MatTableDataSource(this.rodoviasSalvas);
            this.enviarRodovias(this.mapearArray());
            this.formRodovias.reset();
        } else {
            this.formRodovias.markAllAsTouched();
            this.snackBarService.showAlert("O formulário Rodovias: possui campo(s) obrigatório(s) não preenchido(s)." +
                " Favor realize o preenchimento do(s) mesmo(s).")
        }
    }

    deletarRodovia(item, acao?: string, itemId?: number) {
        if (acao == 'DELETAR' && itemId != null) {
            this.infraObrasViariasService.deletarRodoviaPorId(itemId).subscribe(response => {
                this.snackBarService.showSuccess("Rodovia excluido com sucesso.")
            })
        }
        let data;
        data = this.rodoviasSalvas.filter((value, key) => {
            return value.idTemp != item;
        });
        this.rodoviasSalvas = data;
        this.dataSourceRodovias = new MatTableDataSource(data);
        this.enviarRodovias(this.mapearArray());
    }

    enviarRodovias(rodovias: any[]) {
        this.emitirRodovias.emit(rodovias);
    }
}