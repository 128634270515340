import {Injector, OnInit} from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {DominioDto} from "../models/dominio-dto.model";
import {DominiosService} from "./dominios.service";
import {BaseRelacionamentoModel} from "../models/base-relacionamento.model";
import {SubDominioDto} from "../models/sub-dominio-dto.model";


export abstract class BaseRelacionamentoService<T extends BaseRelacionamentoModel> extends BaseService<T> implements OnInit {

    protected _info: {
        selecionados: Array<{ dominio: DominioDto, subdominio?: SubDominioDto, vinculo: T }>;
        dominio: Array<DominioDto>;
        subdominio: Array<DominioDto>;
        excluidos: Array<number>;
    } = {
        selecionados: [],
        dominio: [],
        subdominio: [],
        excluidos: []
    };

    get info() {
        return this._info;
    }

    set info(i) {
        this._info = i;
    }

    constructor(protected apiPath: string,
                protected injector: Injector,
                protected type: T,
                protected jsonToResourceFn: (json: any) => T,
                protected dominiosService: DominiosService,
                public hasSubDominio: boolean = false,
                protected urlRes?: string
    ) {
        super(
            apiPath,
            injector,
            type,
            jsonToResourceFn,
            urlRes
        );
    }

    ngOnInit() {
        this.info = {
            selecionados: [],
            dominio: [],
            subdominio: [],
            excluidos: []
        };
    }

    async getDominio() {
        this._info.dominio = await this.dominiosService.getDominioByUrl(this.pathGetDominio).toPromise();
    }

    async getSubDominio(idDominio: number) {
        this._info.subdominio = await this.dominiosService.getSubDominioByUrl(this.pathGetSubDominio, idDominio).toPromise();
    }

    protected abstract get pathGetByEntidadePai(): string;

    protected abstract get pathGetDominio(): string;

    protected get pathGetSubDominio(): string {
        return this.pathGetDominio;
    };

    getByIdEntidadePai(idEntidadePai: number): Observable<Array<T>> {
        const url = `${this.urlResource}/${this.pathGetByEntidadePai}/${idEntidadePai}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    async getSelecionadosSalvos(idEntidadePai: number, incremental?: boolean) {
        let tipos: Array<T> = await this.getByIdEntidadePai(idEntidadePai).toPromise();
        if (!incremental || !this._info.selecionados) {
            this._info.selecionados = [];
        }

        this._info.selecionados = this._info.selecionados.concat(tipos.map((tipo: T) => {
            return {
                dominio: this._info.dominio.find(o => o.id === tipo.idDominio),
                vinculo: tipo
            }
        }));

        if (!tipos || tipos.length < 1) {
            this._info.selecionados = [];
        }
    }

    async deleteExcluidos() {
        for (let i = 0, len = this._info.excluidos.length; i < len; i++) {
            let id = this._info.excluidos[i];
            await this.delete(id).toPromise();
            this._info.excluidos.splice(i, 1);
        }
    }

    async createSelecionados(idEntidade: number) {
        await this.deleteExcluidos();
        let index = 0;
        for (let tipo of this._info.selecionados) {
            let t = tipo.vinculo;
            t.idEntidade = idEntidade;
            if (t.id === undefined) {
                this._info.selecionados[index].vinculo = await this.create(t).toPromise();
            } else {
                this._info.selecionados[index].vinculo = await this.update(t).toPromise();
            }
            index++;
        }

    }

}
