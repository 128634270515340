import {BaseModel} from '../base.model';

export class TipoCondicaoArrecadacao extends BaseModel {

    constructor(
        public id?: number,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(json: any): TipoCondicaoArrecadacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoCondicaoArrecadacao(
            json.id,
            json.descricao,
        );
    }
}
