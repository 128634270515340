import {BaseModel} from 'app/main/shared/models/base.model';

export class Benfeitoria extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public idTipoBenfeitoria?: number,
        public capAlojamento?: number,
        public capProducao?: number,
        public descricao?: string,
    ) {
        super();
    }

    static fromJson(json: any): Benfeitoria {
        json = json || {};

        return new Benfeitoria(
            json.id,
            json.idGeometria,
            json.idTipoBenfeitoria,
            json.capAlojamento,
            json.capProducao,
            json.descricao,
        );
    }
}