import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {BaseModel} from 'app/main/shared/models/base.model';
import {ParecerTecnico} from '../parecer-tecnico.model';
import {RequerimentoTitulacao} from '../../requerimentos/shared/requerimento-titulacao/shared/requerimento-titulacao.model';
import {Servidor} from '../../../shared/models/servidor.model';
import { Template } from '../../templates/template.model';

export class ParecerRequerimentoConclusao extends BaseModel {
    constructor(
        public id?: number,
        public parecerTecnico?: ParecerTecnico,
        public requerimentoTitulacao?: RequerimentoTitulacao,
        public servidor?: Servidor,
        public conclusao?: string,
        public tipoData?: string,
        public prazoTipo?: string,
        public prazoNumero?: number,
        public prazoVencimento?: Date,
        public motivo?: string,
        public template?: Template,
        public templatePublicacao?: Template,
        public exigeEIARIMA?: string,
    ) {
        super();
        exigeEIARIMA = exigeEIARIMA != undefined ? exigeEIARIMA : 'NAO';
    }

    static conclusoes: BaseEnum[] = [
        {valor: 'DEFERIDO', descricao: 'Deferido'},
        {valor: 'INDEFERIDO', descricao: 'Indeferido'}
    ];

    static tiposData: BaseEnum[] = [
        {valor: 'PRAZO', descricao: 'Prazo'},
        {valor: 'SEM_PRAZO', descricao: 'Sem prazo'},
        {valor: 'VENCIMENTO', descricao: 'Vencimento'},
        {valor: 'DEFINIR_PRAZO_NA_EMISSAO', descricao: 'Definir prazo na emissão'}

    ];

    static prazosTipo: BaseEnum[] = [
        {valor: 'DIAS', descricao: 'Dia(s)'},
        {valor: 'SEMANAS', descricao: 'Semana(s)'},
        {valor: 'MESES', descricao: 'Mese(s)'},
        {valor: 'ANOS', descricao: 'Ano(s)'},
    ];

    static motivosIndeferimento: BaseEnum[] = [
        {valor: 'DECISAO_CONSEMA', descricao: 'DECISÃO DO CONSEMA'},
        {valor: 'INVIABILIDADE_TECNICA', descricao: 'INVIABILIDADE TÉCNICA'},
        {valor: 'INERCIA_INTERESSADO', descricao: 'INÉRCIA DO INTERESSADO'},
        {valor: 'OUTROS', descricao: 'OUTROS'},
    ];

    static exigeEIARIMA: BaseEnum[] = [
        {valor: 'NAO', descricao: 'Não'},
        {valor: 'DEFERIDO', descricao: 'Sim, deferido com EIA/RIMA'},
        {valor: 'DISPENSA', descricao: 'Sim, deferido com dispensa de EIA/RIMA'},
        
    ];

    static fromJson(json: any): ParecerRequerimentoConclusao {
        if (json === undefined || json === null) {
            return null;
        }
        return new ParecerRequerimentoConclusao(
            json.id,
            ParecerTecnico.fromJson(json.parecerTecnico),
            RequerimentoTitulacao.fromJson(json.requerimentoTitulacao),
            json.servidor,
            json.conclusao,
            json.tipoData,
            json.prazoTipo,
            json.prazoNumero,
            json.prazoVencimento,
            json.motivo,
            json.template,
            json.templatePublicacao
        );
    }
}
