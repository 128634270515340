import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Requerimento} from '../../../pages/requerimentos/requerimento.model';
import {MatPaginator} from '@angular/material';
import {HttpParams} from '@angular/common/http';
import {createRequestOptions} from '../../../core/util/request-util';
import {Despacho} from './despacho.model';
import {EnumTarefaTitle} from '../../enums/EnumTarefaTitle';
import {DespachoService} from '../../../pages/tarefas/shared/service/despacho.service';

@Component({
    selector: 'app-despacho',
    templateUrl: './despacho.component.html'
})
export class DespachoComponent implements OnInit {
    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;
    length = 1000;
    pageSize = 10;
    pageIndex = 0;

    @Input() requerimento = new Requerimento();

    despachos: Array<Despacho> = [];
    tiposDespacho = Despacho.tipoDespacho;
    enumPage = EnumTarefaTitle;

    constructor(private despachoService: DespachoService) {
    }

    ngOnInit(): void {
        this.listarDespachos();
    }

    listarDespachos(): void {
        const httpParams: HttpParams = createRequestOptions({
            page: String(this.pageIndex),
            size: String(this.pageSize)
        });

        this.despachoService.getDespachoByIdRequerimento(this.requerimento.id, httpParams).subscribe(despacho => {
            this.despachos = despacho.content;
            this.pageSize = despacho.totalPages;
            this.length = despacho.totalElements;
        });
    }

    tipoDespacho(valor: any) {
        return this.tiposDespacho.find(d => d.valor === valor).descricao;
    }

    descisaoNomeTarefa(valor: any) {
        return this.enumPage[valor];
    }

    pageChangeEvent(event): void {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.listarDespachos();
    }
}
