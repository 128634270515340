import {EspelhoOutorgaDto} from './espelho-outorga-dto.model';
import {RequerimentoOutorga} from 'app/main/pages/requerimentos/requerimento-outorga/requerimento-outorga.model';
import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RequerimentoOutorgaService extends BaseService<RequerimentoOutorga> {
    constructor(protected injector: Injector) {
        super(
            '/api/requerimentos-outorga',
            injector,
            RequerimentoOutorga.prototype,
            RequerimentoOutorga.fromJson
        );
    }

    getByRequerimentoId(requerimentoId: number): any {
        const url = `${this.urlResource}/requerimento/${requerimentoId}`;
        return this.http.get(url).pipe(
            map(this.jsonToResourceFn.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getEspelhoOutorga(requerimentoOutorga: RequerimentoOutorga): Observable<EspelhoOutorgaDto> {
        const url = `${this.urlResource}/${requerimentoOutorga.id}/espelho-outorga`;
        return this.http.get(url).pipe(
            map(jsonData => EspelhoOutorgaDto.fromJson(jsonData)),
            catchError(this.handleError.bind(this))
        );
    }
}
