import {Component, Input} from '@angular/core';
import {LancamentoTaxa} from '../../../shared/models/lancamento-taxa.model';
import {DetalhesDaGuiaDialogComponent} from '../dialog/detalhe-da-guia-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BaseEnum} from '../../../shared/enums/base.enum';
import {LancamentoTaxaService} from '../../../shared/services/lancamento-taxa.service';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-guias-recolhimento',
    templateUrl: './guias-recolhimento.component.html',
    styleUrls: ['./guias-recolhimento.component.scss'],
})

export class GuiasRecolhimentoComponent {

    @Input() lancamentosTaxas: LancamentoTaxa[];

    displayedColumns: string[] = [
        'numeroDar',
        'valorDaTaxa',
        'dataDoPagamento',
        'dataDoVencimento',
        'statusDaGuia',
        'statusAcoes',
    ];

    constructor(
        public dialog: MatDialog,
        private snackBarService: SnackBarService,
        protected service: LancamentoTaxaService
    ) {
    }

    openDialog(lancamentoTaxa: LancamentoTaxa): void {
        const dialogRef = this.dialog.open(DetalhesDaGuiaDialogComponent, {
                width: '700px',
                data: lancamentoTaxa
            }
        );
    }

    downloadDocumentoPdf(blob: Blob): void {
        const blobGenerate = new Blob([blob], {type: 'application/pdf'});
        const downloadURL = window.URL.createObjectURL(blobGenerate);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${downloadURL.substring(downloadURL.indexOf('/') + 1, downloadURL.length)}.${blob.type.substring(blob.type.indexOf('/') + 1)}`;
        link.click();
    }

    public downloadDocumento(lancamentoTaxa: LancamentoTaxa): void {
        this.service.downloadDocumentoPdfDaTaxa(lancamentoTaxa).subscribe(blob => {
            this.downloadDocumentoPdf(blob);
            this.snackBarService.showAlert('Baixando arquivo...');
        }, response => {
            if (response.status === 500) {
                this.snackBarService.showError('Documento não encontrado');
            } else {
                this.snackBarService.showError('Ocorreu um erro');
            }
        });
    }

    get statusLancamento(): BaseEnum[] {
        return LancamentoTaxa.enumStatus;
    }
}
