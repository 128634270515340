import {BaseModel} from 'app/main/shared/models/base.model';

export class DocumentoEntidade extends BaseModel {

    constructor(
        public id?: number,
        public titulo?: string,
        public nomeArquivo?: string,
        public entidade?: BaseModel,
        public act?: string,
    ) {
        super();
    }

    static fromJson(json: any): DocumentoEntidade {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoEntidade(
            json.id,
            json.titulo,
            json.nomeArquivo,
            BaseModel.fromJson(json.entidade)
        );
    }
}
