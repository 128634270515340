import {Injectable, Injector} from '@angular/core';
import {CachedBaseService} from './cached-base.service';
import {TipoObra} from '../models/tipo-obra.model';

@Injectable({
    providedIn: 'root'
})
export class TipoRebanhoService extends CachedBaseService<TipoObra> {

    constructor(injector: Injector) {
        super('/api/tipos-rebanho', injector, TipoObra.fromJson);
    }

}
