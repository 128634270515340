import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {BaseModel} from '../models/base.model';
import {environment} from '../../../../environments/environment';
import {Injector} from '@angular/core';

export abstract class CachedBaseService<T extends BaseModel> {

    constructor(
        protected apiPath: string,
        protected injector: Injector,
        protected jsonToResourceFn: (json: any) => T
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += apiPath;
    }

    resourcesCache: Observable<T[]>;

    protected http: HttpClient;
    urlResource: string = environment.URL_GATEWAY;

    getAll(httpParams ?: HttpParams): Observable<T[]> {
        if (!this.resourcesCache) {
            this.resourcesCache = this.http.get(this.urlResource, {params: httpParams}).pipe(
                shareReplay(1),
                map(response => this.jsonToResources(response)),
                catchError(e => {
                    delete this.resourcesCache;
                    return throwError(e);
                })
            );
        }
        return this.resourcesCache;
    }

    protected jsonToResources(json: any): T[] {
        const resources: T[] = [];
        if (json !== undefined && json !== null) {
            json.forEach((e: T) => resources.push(this.jsonToResourceFn(e)));
        }
        return resources;
    }

}
