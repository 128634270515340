import {BaseModel} from 'app/main/shared/models/base.model';
import {ParametroCondicionante} from './parametro-condicionante.model';

export class UnidadeAgua extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public valorIntervencao?: string,
        public observacao?: string,
        public parametroCondicionante?: ParametroCondicionante,
    ) {
        super();
    }

    static fromJson(json: any): UnidadeAgua {
        if (json === undefined || json === null) {
            return null;
        }
        return new UnidadeAgua
        (json.id,
            json.descricao,
            json.valorIntervencao,
            json.observacao,
            ParametroCondicionante.fromJson(json.parametroCondicionante));
    }
}
