import {Component, Input, OnInit} from '@angular/core';
import {
    EmpreendimentoSubestacaoInterna
} from "../../../../../../../../../shared/models/empreendimento-subestacao-interna.model";
import {FormControl, FormGroup} from "@angular/forms";
import {DominiosService} from "../../../../../../../../../shared/services/dominios.service";
import {DominioDto} from "../../../../../../../../../shared/models/dominio-dto.model";
import {
    EmpreendimentoSubestacaoInternaService
} from "../../../../../../../../../shared/services/empreendimento-subestacao-interna.service";

@Component({
    selector: 'app-subestacao-interna',
    templateUrl: './subestacao-interna.component.html',
    styleUrls: ['./subestacao-interna.component.scss']
})
export class SubestacaoInternaComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isNaoPodeEditar: boolean;
    private $idEmpreendimento: number;

    tiposSubstacaoInterna: FormControl;

    tipos: DominioDto[] = [];
    selectsubEstacaoInterna: DominioDto;
    descricaosubEstacaoInterna: string;

    displayedColumnsSubestacaoInterna: string[] = ['tipo', 'descricao', 'acoes'];

    constructor(private dominiosService: DominiosService,
                private subestacaoInternaService: EmpreendimentoSubestacaoInternaService) {
    }

    @Input() set idEmpreendimento(idEmpreendimento: number) {
        if (idEmpreendimento !== this.$idEmpreendimento) {
            this.$idEmpreendimento = idEmpreendimento;

            if (this.$idEmpreendimento) {
                this.subestacaoInternaService.getByEmpreendimento(this.$idEmpreendimento)
                    .subscribe(itens => this.tiposSubstacaoInterna.setValue(itens));
            }
        }
    }

    ngOnInit() {
        this.tiposSubstacaoInterna = this.form.get('subestacoesInternas') as FormControl;

        this.dominiosService.getDominio("TIPO_SUBESTACAO_INTERNA").subscribe(tipos => this.tipos = tipos);

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    ngOnChanges(): void {
    }

    excluirsubEstacaoInterna(index: number) {
        if (index > -1) {
            this.tiposSubstacaoInterna.value.splice(index, 1);
            this.tiposSubstacaoInterna.setValue([...this.tiposSubstacaoInterna.value])
        }
    }

    addsubEstacaoInterna(subEstacaoInterna: DominioDto, descricao) {

        if (subEstacaoInterna && !this.tiposSubstacaoInterna.value.some(f => f.subEstacaoInterna === subEstacaoInterna.id && f.descricao === descricao)) {
            this.tiposSubstacaoInterna.value.push(EmpreendimentoSubestacaoInterna.fromJson({
                subEstacaoInterna: subEstacaoInterna.id,
                descricao: subEstacaoInterna.descricao === 'Outro' ? descricao : undefined,
            }));
            this.tiposSubstacaoInterna.setValue([...this.tiposSubstacaoInterna.value])

            this.selectsubEstacaoInterna = undefined;
            this.descricaosubEstacaoInterna = undefined;
        }
    }

    verificaBotaoAdicionarDesabilitado() {
        return !this.selectsubEstacaoInterna ||
            (this.selectsubEstacaoInterna && this.selectsubEstacaoInterna.descricao === 'Outro'
                && !this.descricaosubEstacaoInterna);
    }

    getDescricao(id) {
        return this.tipos.find(tp => tp.id === id).descricao;
    }

}
