import {Component, OnInit} from '@angular/core';
import {BasePontoComponent} from '../base-ponto.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PontoService} from '../../../../../shared/services/ponto.service';
import {Ponto} from '../../../../../shared/models/hidrico-models/ponto.model';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {isNullOrUndefined} from 'util';
import {PocoTubularIrrigacaoModel} from './poco-tubular-irrigacao.model';
import {ResponsavelTecnico} from '../../../../../shared/models/responsavel-tecnico.model';
import {ResponsaveisCorporativoService} from '../../../../../shared/services/responsaveis-corporativo.service';
import {DialogSelecionarRtPjComponent} from '../../../../../shared/components/dialog-selecionar-rt-pj/dialog-selecionar-rt-pj.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-objetivo-poco-irrigacao',
    templateUrl: './objetivo-poco-irrigacao.component.html'
})
export class ObjetivoPocoIrrigacaoComponent extends BasePontoComponent implements OnInit {

    form: FormGroup;
    lancamentosLength: number;
    pocosLength: number;
    empresaSelecionada: ResponsavelTecnico;
    condicaoFormularioConcluido = (ponto?: Ponto): boolean => this.lancamentosLength > 0 && this.pocosLength > 0 &&
        !isNullOrUndefined((ponto ? ponto : this.ponto).pocoTubularIrrigacao)

    constructor(
        private formBuilder: FormBuilder,
        private pontoService: PontoService,
        private responsaveisCorporativoService: ResponsaveisCorporativoService,
        private snackBarService: SnackBarService,
        private matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.construirForm();
        this.patchForms();
        if (this.isModoVisualizacao) {
            this.form.disable();
        }
    }

    consultarRtCNPJ(): void {
        this.matDialog.open(DialogSelecionarRtPjComponent).afterClosed().subscribe(result => {
            if (result) {
                this.empresaSelecionada = result;
                this.form.get('idResponsavelTecnico').setValue(this.empresaSelecionada.id);
            }
        });
    }

    private patchForms(): void {
        if (this.ponto.pocoTubularIrrigacao) {
            this.form.patchValue(this.ponto.pocoTubularIrrigacao);
            if (this.ponto.pocoTubularIrrigacao.idResponsavelTecnico) {
                this.empresaSelecionada = this.ponto.pocoTubularIrrigacao.empresaResponsavel;
            }
        }
    }

    private construirForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            diametroPerfuracao: [null, [Validators.required, Validators.min(0.00001)]],
            diametroRevestimento: [null, [Validators.required, Validators.min(0.00001)]],
            vazao: [null, [Validators.required, Validators.min(0.000001)]],
            profundidade: [null, [Validators.required, Validators.min(0.00001)]],
            idResponsavelTecnico: this.formBuilder.control(null, [Validators.required]),
            dataConclusao: [null]
        });
    }

    salvarPonto(): void {
        this.form.markAllAsTouched();
        if (!this.form.invalid) {
            const ponto = Ponto.fromJson(this.ponto);
            ponto.pocoTubularIrrigacao = PocoTubularIrrigacaoModel.fromJson(this.form.value);
            ponto.formularioConcluido = this.condicaoFormularioConcluido(ponto);
            this.pontoService.update(ponto).subscribe(
                (result) => {
                    Object.assign(this.ponto, result);
                    this.patchForms();
                    this.form.markAsPristine();
                    this.snackBarService.showSuccess('Ponto salvo com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao salvar o ponto.', e)
            );
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). ' +
                'Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    onChangePocos(length: number): void {
        this.pocosLength = length;
        this.atualizarPonto(this.pocosLength);
    }

    onChangeLancamentos(length: number): void {
        this.lancamentosLength = length;
        this.atualizarPonto(this.lancamentosLength);
    }

    private atualizarPonto(length: number): void {
        if (!isNullOrUndefined(length) && this.ponto.formularioConcluido !== this.condicaoFormularioConcluido()) {
            this.ponto.formularioConcluido = this.condicaoFormularioConcluido();
            this.pontoService.update(this.ponto).subscribe(result => Object.assign(this.ponto, result));
        }
    }
}
