import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DominioDto} from "../../../../../../../../shared/models/dominio-dto.model";
import {DominiosService} from "../../../../../../../../shared/services/dominios.service";
import {CombustivelUtilizado} from "../../../../../../../../shared/models/combustivel-utilizado.model";

@Component({
    selector: 'app-tipos-combustiveis-utilizados',
    templateUrl: './tipos-combustiveis-utilizados.component.html',
    styleUrls: ['./tipos-combustiveis-utilizados.component.scss']
})
export class TiposCombustiveisUtilizadosComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() isSomenteVisualizacao = false;
    @Input() isNaoPodeEditar: boolean;

    displayedColumns = ['tipo', 'quantidade', 'acoes'];

    tipos: DominioDto[];
    unidades: DominioDto[];

    formCombustivelUtilizado = this.fb.group({
        id: [null],
        mineracao: [null],
        idTipoCombustivel: [null, Validators.required,],
        idUnidadeMedida: [null, Validators.required,],
        quantidadeMensal: [null, Validators.required,]
    });

    constructor(
        private dominiosService: DominiosService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        this.dominiosService.getDominioByUrl('tipo-combustivel-mineracao')
            .subscribe(doms => this.tipos = doms);
        this.dominiosService.getDominioByUrl('tipo-unidade-medida-combustivel-mineral')
            .subscribe(doms => this.unidades = doms);

        this.formCombustivelUtilizado.get('idTipoCombustivel').valueChanges
            .subscribe(value => {
                const isOutro = value &&
                    this.tipos && !!this.tipos.find(d => d.id === value && d.descricao === 'Outro');

                if (isOutro) {
                    if (!this.formCombustivelUtilizado.get('descricao')) {
                        this.formCombustivelUtilizado.addControl('descricao',
                            this.fb.control(null, [Validators.required, Validators.maxLength(100)]))
                    }
                } else if (!!this.formCombustivelUtilizado.get('descricao')) {
                    this.formCombustivelUtilizado.removeControl('descricao');
                }

            });

        if (this.isNaoPodeEditar) {
            this.form.disable();
            this.formCombustivelUtilizado.disable();
        }
    }
    
    getDescricaoTipo(id: number) {
        const dom = this.tipos && this.tipos.find(d => d.id === id);
        return dom && dom.descricao;
    }

    getDescricaoUnidadeMedida(id: number) {
        const dom = this.unidades && this.unidades.find(d => d.id === id);
        return dom && dom.descricao;
    }

    adicionar() {
        if (!this.formCombustivelUtilizado.valid) return;

        const combustiveisUtilizados = this.form.get('combustiveisUtilizados');

        combustiveisUtilizados.setValue([
            ...(combustiveisUtilizados.value || []),
            CombustivelUtilizado.fromJson(this.formCombustivelUtilizado.value)
        ]);

        this.formCombustivelUtilizado.reset();
    }

    excluir(item: CombustivelUtilizado) {
        const estruturas: AbstractControl = this.form.get('combustiveisUtilizados');

        if (estruturas.value.length > 1) {
            estruturas.setValue([
                ...estruturas.value.filter(e => e !== item),
            ]);
        } else {
            estruturas.setValue(null);
        }
    }

    editar(item: CombustivelUtilizado) {
        this.formCombustivelUtilizado.patchValue(item);

        this.excluir(item);
    }

}
