import {NgModule} from '@angular/core';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {ItemTermoReferenciaInformacoesAdicionaisDialogComponent} from './item-termo-referencia-informacoes-adicionais-dialog/item-termo-referencia-informacoes-adicionais-dialog.component';
import {ItemTermoReferenciaHistoricoDialogComponent} from './item-termo-referencia-historico-dialog/item-termo-referencia-historico-dialog.component';
import {ItemTermoReferenciaHistoricoChecklistDialogComponent} from './item-termo-referencia-historico-checklist-dialog/item-termo-referencia-historico-checklist-dialog.component';

@NgModule({
    declarations: [
        ItemTermoReferenciaInformacoesAdicionaisDialogComponent,
        ItemTermoReferenciaHistoricoDialogComponent,
        ItemTermoReferenciaHistoricoChecklistDialogComponent
    ],
    imports: [
        CoreModule,
        SharedModule
    ],
    entryComponents: [
        ItemTermoReferenciaInformacoesAdicionaisDialogComponent,
        ItemTermoReferenciaHistoricoDialogComponent,
        ItemTermoReferenciaHistoricoChecklistDialogComponent
    ]
})
export class ItensTermosReferenciaModule {
}
