import {BaseModel} from './base.model';
import {BaseEnum} from '../enums/base.enum';
import {ResponsavelTecnico} from './responsavel-tecnico.model';


export class PerfuracaoPoco extends BaseModel {

    constructor(
        public id?: number,
        public idResponsavelTecnico?: number,
        public dataConclusao?: Date,
        public tipoAquiferoPorosidade?: string,
        public caracteristicaHidraulica?: string,
        public fonteAbastecimento?: string,
        public especificarFonteAbastecimento?: string,
        public sistemaIndependente?: boolean,
        public profundidadePoco?: number,
        public diametroPerfuracao?: number,
        public diametroRevestimento?: number,
        public unidadeGeologica?: string,
        public aquiferoExplorado?: string,
        public topoAquifero?: string,
        public baseAquifero?: string,
        public tipoPoco?: string,
        public empresaResponsavel?: ResponsavelTecnico
    ) {
        super();
    }

    static tiposPorosidade: BaseEnum[] = [
        {valor: 'FISSURAL', descricao: ('Fissural')},
        {valor: 'CARSTIGO', descricao: ('Carstigo')},
        {valor: 'GRANULAR', descricao: ('Granular')}
    ];

    static tiposCaracteristicasHidraulicas: BaseEnum[] = [
        {valor: 'CONFINADO', descricao: ('Confinado')},
        {valor: 'LIVRE', descricao: ('Livre')},
        {valor: 'SEMI_CONFINADO', descricao: ('Semi Confinado')}
    ];

    static tiposFontesAbastecimento: BaseEnum[] = [
        {valor: 'CURSO_DAGUA', descricao: ('Curso D’Água')},
        {valor: 'ABASTECIMENTO_PUBLICO', descricao: ('Abastecimento Público')},
        {valor: 'BARRAMENTO', descricao: ('Barramento')},
        {valor: 'OUTROS', descricao: ('Outros')}
    ];

    static tiposBombas: BaseEnum[] = [
        {valor: 'SUBMERSA', descricao: ('Submersa')},
        {valor: 'SUPERFICIE', descricao: ('Superfície')}
    ];

    static tiposPoco: BaseEnum[] = [
        {valor: 'TUBULAR', descricao: ('Poço Tubular')},
        {valor: 'POCO_OBSERVACAO', descricao: ('Poço de Observação')}
    ];

    static fromJson(json: any): PerfuracaoPoco {
        if (json === undefined || json === null) {
            return null;
        }
        return new PerfuracaoPoco(
            json.id,
            json.idResponsavelTecnico,
            json.dataConclusao,
            json.tipoAquiferoPorosidade,
            json.caracteristicaHidraulica,
            json.fonteAbastecimento,
            json.especificarFonteAbastecimento,
            json.sistemaIndependente,
            json.profundidadePoco,
            json.diametroPerfuracao,
            json.diametroRevestimento,
            json.unidadeGeologica,
            json.aquiferoExplorado,
            json.topoAquifero,
            json.baseAquifero,
            json.tipoPoco,
            ResponsavelTecnico.fromJson(json.empresaResponsavel)
        );
    }

    static fromJsons(json: any): PerfuracaoPoco[] {
        const lista: PerfuracaoPoco[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}
