import {Injectable, Injector} from '@angular/core';
import {Ponto} from '../models/hidrico-models/ponto.model';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PontoVazaoMensalDRDH} from "../models/hidrico-models/ponto-vazao-mensal-drdh.model";

@Injectable({
    providedIn: 'root'
})
export class PontoVazoesMensaisDrdhService extends BaseService<PontoVazaoMensalDRDH> {

    constructor(injector: Injector) {
        super('/api/ponto-vazoes-mensais-drdh', injector, PontoVazaoMensalDRDH.prototype, PontoVazaoMensalDRDH.fromJson);
    }

    listarVazaoMensal(ponto: Ponto): Observable<PontoVazaoMensalDRDH[]> {
        return this.http.get(`${this.urlResource}`, {params: {idPonto: ponto.id.toString()}}).pipe(
            map(value => PontoVazaoMensalDRDH.fromJsons(value)),
            catchError(this.handleError.bind(this))
        );
    }

    adicionarVazaoMensal(vazaoMensal: PontoVazaoMensalDRDH): Observable<PontoVazaoMensalDRDH> {
        return this.http.post(`${this.urlResource}/${vazaoMensal.ponto.id}`, vazaoMensal).pipe(
            map(value => PontoVazaoMensalDRDH.fromJson(value)),
            catchError(this.handleError.bind(this))
        );
    }

    excluirVazaoMensal(dado: PontoVazaoMensalDRDH): Observable<void> {
        return this.http.delete(`${this.urlResource}/${dado.id}`).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }
}
