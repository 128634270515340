import {Component, Input, OnInit} from '@angular/core';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {DadoMeteorologico} from '../../../models/hidrico-models/dado-meteorologico.model';
import {PontoVazaoMensalDRDH} from '../../../models/hidrico-models/ponto-vazao-mensal-drdh.model';
import {PontoService} from '../../../services/ponto.service';
import {SnackBarService} from '../../../snack-bar/snack-bar.service';
import {DadosHidrometeorologicosService} from "../../../services/dados-hidrometeorologicos.service";
import {PontoVazoesMensaisDrdhService} from "../../../services/ponto-vazoes-mensais-drdh.service";

@Component({
    selector: 'app-aba-objetivo-drdh',
    templateUrl: './aba-objetivo-drdh.component.html'
})
export class AbaObjetivoDrdhComponent implements OnInit {

    @Input() ponto: Ponto;
    dadosMeteorologicos: DadoMeteorologico[] = null;
    vazoesMensais: PontoVazaoMensalDRDH[] = null;
    mediaMLT = 0;

    constructor(
        private pontoService: PontoService,
        private dadosHidrometeorologicosService: DadosHidrometeorologicosService,
        private vazoesMensaisService: PontoVazoesMensaisDrdhService,
        private snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
        this.listarDadosMeteorologicos();
        this.listarVazaoMensal();
    }

    private listarDadosMeteorologicos(): void {
        this.dadosHidrometeorologicosService.listarDadosHidrometeorologicos(this.ponto).subscribe(result => {
            this.dadosMeteorologicos = result;
        }, e => this.snackBarService.showError('Erro ao carregar dados meteorológicos', e));
    }

    private listarVazaoMensal(): void {
        this.vazoesMensaisService.listarVazaoMensal(this.ponto).subscribe(result => {
            this.vazoesMensais = result;
            if (this.vazoesMensais.length > 0) {
                let soma = 0;
                this.vazoesMensais.forEach(vazao => {
                    soma += vazao.vazao;
                });
                this.mediaMLT = soma / this.vazoesMensais.length;
            } else {
                this.mediaMLT = 0;
            }
        }, e => this.snackBarService.showError('Erro ao carregar vazões mensais', e));
    }

}
