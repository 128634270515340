import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AtividadeArrecadacao} from '../../models/arrecadacao-models/atividade-arrecadacao.model';
import {BaseArrecadacaoService} from '../base-arrecadacao.service';
import {AtividadeLicenciamentoDto} from '../../../shared/models/atividade-licenciamento-dto.model';
import {HttpParams} from "@angular/common/http";
import {AtividadeProjectionArrecadacao} from "../../models/arrecadacao-models/atividade-projection-arrecadacao.model";

@Injectable({
    providedIn: 'root'
})
export class AtividadeArrecadacaoService extends BaseArrecadacaoService<AtividadeArrecadacao> {

    constructor(protected injector: Injector) {
        super('/api/atividade', injector, AtividadeArrecadacao.prototype, AtividadeArrecadacao.fromJson);
    }

    getAtividadeId(id: number): Observable<AtividadeArrecadacao> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/id/${id}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getBySubGrupoDesc(descricao: string): Observable<AtividadeArrecadacao[]> {
        return this.http
            .get(`${this.urlResourceArrecadacao}/subgrupo/descricao/${descricao}`)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getFilhasArrecadacaoParaId(id?: number): Observable<AtividadeArrecadacao[]> {
        const url = `${this.urlResourceArrecadacao}/${id}/arrecadacao/filhas`;
        return this.http.get(url).pipe(
            map(this.jsonToResources.bind(this)),
            catchError(this.handleError.bind(this))
        );
    }

    getAllAtividades(): Observable<AtividadeArrecadacao[]> {
        const url = `${this.urlResourceArrecadacao}/buscar-atividades`;
        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    getAtividadeDto(id: number, idGrupo: number): Observable<AtividadeLicenciamentoDto> {
        const url = `${this.urlResourceArrecadacao}/atividade-dto/${id}/atividade-grupo/${idGrupo}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getByAtividadeNivelPorDesc(descricao = null, idAtividade = null): Observable<AtividadeProjectionArrecadacao[]> {
        let httpParams = new HttpParams();
        if (descricao) {
            httpParams = httpParams.set('descricao', descricao);
        }
        if (idAtividade) {
            httpParams = httpParams.set('idAtividade', idAtividade.toString());
        }
        return this.http
            .get(`${this.urlResourceArrecadacao}/nivel/descricao`, {params: httpParams})
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }
}
