import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {TemplateParecerComponent} from "../components/geoadmin/template-parecer/template-parecer.component";

@Pipe({
    name: 'safeSVG',
})
export class SafeSvgPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer
    ) {
    }

    transform(svg: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg+xml;utf8,' + encodeURIComponent(svg));
    }
}