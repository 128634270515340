import {BaseModel} from 'app/main/shared/models/base.model';
import {CompostoQuimico} from '../compostos-quimicos/composto-quimico.model';
import {UnidadeQuimica} from '../unidades-quimicas/unidade-quimica.model';

export class SubstanciaQuimica extends BaseModel {
    constructor(
        public id?: number,
        public casRegistryNumber?: string,
        public nome?: string,
        public compostoQuimico?: CompostoQuimico,
        public unidadeQuimica?: UnidadeQuimica
    ) {
        super();
    }

    static fromJson(json: any): SubstanciaQuimica {
        if (json === undefined || json === null) return null;
        return new SubstanciaQuimica(
            json.id,
            json.casRegistryNumber,
            json.nome,
            CompostoQuimico.fromJson(json.compostoQuimico),
            UnidadeQuimica.fromJson(json.unidadeQuimica)
        );
    }
}
