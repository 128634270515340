import {Injectable, Injector} from '@angular/core';
import {EmpreendimentoTransporteResiduosPerigosos} from "../../../../../../../shared/models/empreendimento-transporte-residuos-perigosos.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {TransporteResiduosPerigososDto} from "../../../../../../../shared/models/transporte-residuos-perigosos-dto.model";
import {BaseEmpreendimentoTransportePerigosoService} from "../../../../../../../shared/services/base-empreendimento-transporte-perigoso.service";
import {environment} from "../../../../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ResiduosTransporteProdutosPerigososService extends BaseEmpreendimentoTransportePerigosoService<EmpreendimentoTransporteResiduosPerigosos>{
    constructor(protected injector: Injector) {
        super(
            '/api/transporte-residuos-perigosos',
            injector,
            EmpreendimentoTransporteResiduosPerigosos.prototype,
            EmpreendimentoTransporteResiduosPerigosos.fromJson
        );
    }

    salvarFormulario(transporteResiduosPerigosos: TransporteResiduosPerigososDto): Observable<TransporteResiduosPerigososDto> {
        const url = this.urlResourceEmpreendimentoTransportePerigoso;
        return this.http.post(url, transporteResiduosPerigosos)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    buscarDominiosFiltrados(): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoTransportePerigoso}/dominios-filtrados`;
        return this.http.get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    buscarResiduosGeraisPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/residuos-dados-gerais/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarTranposrteResiduoPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/residuos-dados-gerais/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarResiduosProdutosPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/residuos-produtos/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarResiduosProdutosPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/residuos-produtos/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarRodoviasPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/rotas-rodovias/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarProcedenciaPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/rotas-rodovias/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    deletarRodoviaPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/rotas-rodovias/rodovias/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarVeiculosPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/veiculos/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarVeiculoPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_TRANSPORTE_PERIGOSO}/api/veiculos/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoTransportePerigoso}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );;
    }
}
