import {Injectable, Injector} from '@angular/core';
import {ListResolver} from '../resolvers/list-resolver';
import {DetailResolver} from "../resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "./base.service";
import {EmpreendimentoCemiterioCrematorio} from "../models/empreendimento-cemiterio-crematorio.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import { BaseLicenciamentoResiduosCemitCrematorio } from './base-licenciamento-residuos-cemit-crematorio.service';


@Injectable()
export class EmpreendimentoCemiterioCrematorioListResolver extends ListResolver<EmpreendimentoCemiterioCrematorio> {
    constructor(service: EmpreendimentoCemiterioCrematorioService) {
        super(service);
    }
}

@Injectable()
export class EmpreendimentoCemiterioCrematorioResolver extends DetailResolver<EmpreendimentoCemiterioCrematorio> {
    constructor(
        service: EmpreendimentoCemiterioCrematorioService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: "root"
})
export class EmpreendimentoCemiterioCrematorioService extends BaseLicenciamentoResiduosCemitCrematorio<EmpreendimentoCemiterioCrematorio> {

    constructor(protected injector: Injector) {
        super(
            "/api/empreendimentos-cemiterio-crematorio",
            injector,
            EmpreendimentoCemiterioCrematorio.prototype,
            EmpreendimentoCemiterioCrematorio.fromJson
        );
    }

    getByRequerimento(idRequerimento: number): Observable<EmpreendimentoCemiterioCrematorio> {
        const url = `${this.urlResource}/requerimento/${idRequerimento}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
