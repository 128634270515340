import {Injectable, Injector} from "@angular/core";
import {EmpreendimentoEeHidrica} from "../../../../../../../shared/models/empreendimento-ee-hidrica.model";
import {BaseEmpreendimentoEeHidricaService} from "../../../../../../../shared/services/base-empreendimento-ee-hidrica.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {EmpreendimentoEeHidricaDTO} from "../../../../../../../shared/models/empreendimento-ee-hidrica-dto.model";
import {Derivacao} from "../../../../../../../shared/models/derivacao.model";
import {environment} from "../../../../../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EeHidricaService extends BaseEmpreendimentoEeHidricaService<EmpreendimentoEeHidrica> {
    constructor(protected injector: Injector) {
        super(
            '/api/ee-hidrica',
            injector,
            EmpreendimentoEeHidrica.prototype,
            EmpreendimentoEeHidrica.fromJson
        );
    }

    salvarEeHidrica(eeHidricaDTO: EmpreendimentoEeHidricaDTO): Observable<EmpreendimentoEeHidrica> {
        const url = this.urlResourceEmpreendimentoEeHidrica;
        return this.http.post(url, eeHidricaDTO)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    buscarPorId(idEmpreendimento: number): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoEeHidrica}/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    buscarDerivacaoPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/derivacao-ee-hidrica/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarDerivacaoPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/derivacao-ee-hidrica/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarCasaForcaPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/casa-forca-ee-hidrica/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarCasaForcaPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/casa-forca-ee-hidrica/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarBarragemPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/barragem-ee-hidrica/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarBarragemPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/barragem-ee-hidrica/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarUnidadesGeradorasPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/unidade-geradora-ee-hidrica/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarUnidadesGeradorasPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/unidade-geradora-ee-hidrica/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarReservatoriosPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/reservatorios-ee-hidrica/buscar-por-id/${idEmpreendimento}`;
        return this.http.get(url);
    }

    deletarReservatoriosPorId(idEmpreendimento: number): Observable<any> {
        const url = `${environment.URL_EMPREENDIMENTO_EE_HIDRICA}/api/reservatorios-ee-hidrica/${idEmpreendimento}`;
        return this.http.delete(url);
    }

    buscarPorIdRequerimento(idRequerimento: number): Observable<any> {
        const url = `${this.urlResourceEmpreendimentoEeHidrica}/buscar-por-id-requerimento/${idRequerimento}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }
}