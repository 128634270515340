import {BaseModel} from 'app/main/shared/models/base.model';

export class TipoCondicionante extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string
    ) {
        super();
    }

    static fromJson(json: any): TipoCondicionante {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoCondicionante(json.id, json.descricao);
    }
}
