import {BaseModel} from 'app/main/shared/models/base.model';

export class Telefone extends BaseModel {

    constructor(
        public id?: number,
        public ddd?: string,
        public ddi?: string,
        public numero?: string,
        public tipo?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public contato?: any
    ) {
        super();
    }

    static fromJson(json: any): Telefone {
        if (json === undefined || json === null) {
            return null;
        }
        return new Telefone(
            json.id,
            json.ddd,
            json.ddi,
            json.numero,
            json.tipo,
            json.dataInicio,
            json.dataFim,
            json.contato
        );
    }

    static fromJsons(json: any): Telefone[] {
        const lista: Telefone[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((rt) => {
                lista.push(this.fromJson(rt));
            });
            return lista;
        }
    }
}
