import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CertificadoDigitalDTO } from '../models/certificado-digital-dto.model';


@Injectable({
    providedIn: 'root'
})
export class AssinadorDigitalService {

    //  private urlResource: string = environment.URL_GATEWAY;
    private urlResource: string = 'http://signer.sema.memora.com.br:8443/security/api';

    constructor(private http: HttpClient) {
    }

    ping(): boolean {
        let isConnected: boolean;
        this.http.get('http://signer.sema.memora.com.br/`', { observe: 'response' })
            .pipe(first())
            .subscribe(resp => {
                if (resp.status === 200) {
                    isConnected = true;
                } else {
                    isConnected = false;
                }
            }, err => {
                console.log(err);
                isConnected = false;
            });
        return isConnected;
    }

    getAllCertificates(tipo: string): Observable<CertificadoDigitalDTO[]> {
        const url = `${this.urlResource}/v1/certificados`;
        return this.http.get(url, { params: {tipo: tipo}}).pipe(
            map(resources => CertificadoDigitalDTO.fromJsons(resources)),
            catchError(this.handleError)
        );
    }

    signDocument(certificadoDigital: CertificadoDigitalDTO, multipartFile: Blob): Observable<Blob> {
        return this.createMultipart(certificadoDigital, multipartFile);
    }

    private handleError(error: any): Observable<any> {
        return throwError(error);
    }

    private createMultipart(certificadoDigital: CertificadoDigitalDTO, multipartFile: Blob
    ): Observable<Blob> {
        const formData = this.montarFormData(certificadoDigital, multipartFile);
        const url = `${this.urlResource}/v2/assinar`;
        return this.http.post(url, formData, { responseType: 'blob' }).pipe(
            map((res) => {
                return new Blob([res], { type: 'application/pdf' });
            }), catchError(this.handleError.bind(this)));
    }

    private montarFormData(certificadoDigital: CertificadoDigitalDTO, multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const certificadoDigitalParam = new Blob(
            [JSON.stringify(certificadoDigital)],
            { type: 'application/json' }
        );
        formData.append('certificadoDigital', certificadoDigitalParam);
        return formData;
    }
}
