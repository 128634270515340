import {BaseModel} from '../../../../shared/models/base.model';


export class AtividadeSubAtividade extends BaseModel {
    constructor(
        public descricaoAtividade?: string,
        public descricaoSub?: string,
    ) {
        super();
    }

    static fromJson(json: any): AtividadeSubAtividade {
        if (json === undefined || json === null) {
            return null;
        }
        return new AtividadeSubAtividade(
            json.descricaoAtividade,
            json.descricaoSub,
        );
    }


    static fromJsons(json: any): AtividadeSubAtividade[] {
        const atividadeSubAtividade: AtividadeSubAtividade[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(lancamento => atividadeSubAtividade.push(this.fromJson(lancamento)));
            return atividadeSubAtividade;
        }
    }
}



