import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import Graticule from 'ol-ext/control/Graticule';
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine';
import PrintDialog from 'ol-ext/control/PrintDialog';
import ScaleLine from 'ol/control/ScaleLine';
import Map from 'ol/Map';
import { PrintParams } from "../../models/geoadmin/print-params.interface";
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from "ol/style/Text";
import Fill from 'ol/style/Fill';
import { COMPASS } from "../../util/geo-report-icones.util";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import jsPDF from 'jspdf';
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import TileArcGISRest from 'ol/source/TileArcGISRest';
import TileWMS from 'ol/source/TileWMS';
import {LoadingService} from "../../components/loading/loading.service";

@Injectable({
    providedIn: 'root'
})
export class MapPrintService {
    private salvarPDFResultSubject: Subject<string> = new Subject<string>();
    private gerarPDFResultSubject: Subject<string> = new Subject<string>();

    private graticule: Graticule;
    private canvasScaleLine: CanvasScaleLine;
    private scaleLine: ScaleLine;
    private map: Map;
    public printControl: PrintDialog;
    public type: 'salvar' | 'gerar';

    public graticulesIntervalsBasedOnScale: any = {
        100: 6,
        1000: 60,
        5000: 302,
        10000: 605,
        25000: 1512,
        50000: 3025,
        100000: 6050,
        250000: 15124,
        1000000: 60498
    }

    constructor() {
        proj4.defs('EPSG:4674', '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
        proj4.defs('EPSG:100005', 'PROJCS["SIRGAS_2000_Lambert_Conformal_Conic_MT",GEOGCS["GCS_SIRGAS_2000",DATUM["D_SIRGAS_2000",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Standard_Parallel_1",-10.5],PARAMETER["Standard_Parallel_2",-15.5],PARAMETER["Latitude_Of_Origin",-13.0],UNIT["Meter",1.0]], AUTHORITY["EPSG","4674"]]');
        proj4.defs('EPSG:100006', 'PROJCS["South_America_Lambert_Conformal_Conic_MT",GEOGCS["GCS_South_American_1969",DATUM["D_South_American_1969",SPHEROID["GRS_1967_Truncated",6378160.0,298.25]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-56.0],PARAMETER["Standard_Parallel_1",-10.5],PARAMETER["Standard_Parallel_2",-15.5],PARAMETER["Latitude_Of_Origin",-13.0],UNIT["Meter",1.0]]');
        register(proj4);
    }

    get salvarPDFResult$(): Observable<string> {
        return this.salvarPDFResultSubject.asObservable();
    }

    get gerarPDFResult$(): Observable<string> {
        return this.gerarPDFResultSubject.asObservable();
    }

    print(params: PrintParams, type: 'salvar' | 'gerar'): void {
        // Configura a impressão e realiza a impressão com base no tipo
        if(this.printControl){
            this.setCrossOriginForAllTileLayers(this.map);
            this.addAddons(params.scale);
            this.printControl.print(params);
            this.type = type;
        } else {
            console.error("Janela de impressão não configurada")
        }
    }

    setPrintResult(img: string, type: 'salvar' | 'gerar'): void {
        if (type === 'salvar') {
            this.salvarPDFResultSubject.next(img);
        } else {
            this.gerarPDFResultSubject.next(img);
        }
    }

    createSourceWithCrossOrigin(source) {
        if (source instanceof XYZ) {
            return new XYZ({
                url: source.getUrls()[0],
                crossOrigin: 'Anonymous'
            });
        } else if (source instanceof TileArcGISRest) {
            return new TileArcGISRest({
                url: source.getUrl(),
                crossOrigin: 'Anonymous'
            });
        } else if (source instanceof TileWMS) {
            return new TileWMS({
                url: source.getUrls()[0],
                params: source.getParams(),
                crossOrigin: 'Anonymous'
            });
        }
        return source;
    }

    setCrossOriginForAllTileLayers(map: Map) {
        const layers = map.getLayers().getArray();
        layers.forEach(layer => {
            if (layer instanceof TileLayer) {
                const source = layer.getSource();
                const newSource = this.createSourceWithCrossOrigin(source);
                if (newSource) {
                    layer.setSource(newSource);
                }
            }
        });
    }

    configPrint(map: Map) {
        const self = this;
        this.map = map;

        PrintDialog.prototype.formats = [
            {
                title: 'jpegFormat',
                imageType: 'image/jpeg',
                quality: 1
            },
            // {
            //     title: 'pdfFormat',
            //     imageType: 'image/jpeg',
            //     pdf: true
            // }
        ];
        PrintDialog.prototype.marginSize = {
            none: 0,
            tiny: 1,
            small: 5,
            medium: 10,
            large: 15,
            extaLarge: 20
        };
        PrintDialog.prototype.scales = {
            ' 100': '1/100',
            ' 1000': '1/1.000',
            ' 5000': '1/5.000',
            ' 10000': '1/10.000',
            ' 25000': '1/25.000',
            ' 50000': '1/50.000',
            ' 100000': '1/100.000',
            ' 250000': '1/250.000',
            ' 1000000': '1/1.000.000'
        };
        PrintDialog.prototype._labels.pt = {
            title: 'Configuração de Exportação do Mapa',
            orientation: 'Orientação',
            portrait: 'Retrato',
            landscape: 'Paisagem',
            size: 'Tamanho da página',
            custom: 'tamanho da tela',
            margin: 'Margem',
            scale: 'Escala',
            legend: 'Legenda',
            north: 'Exibir Rosa dos Ventos',
            mapTitle: 'Título do mapa',
            saveas: 'Operações',
            saveLegend: 'Salvar legenda...',
            copied: '✔ Copiado para a área de transferência',
            errorMsg: 'Não é possível salvar o canvas do mapa',
            printBt: 'Imprimir',
            clipboardFormat: 'Copiar para a área de transferência',
            jpegFormat: 'Gerar Anexo do Parecer',
            pngFormat: 'Exportar como png',
            pdfFormat: 'Visualizar como PDF',
            none: 'nenhum',
            tiny: 'minúscula',
            small: 'pequena',
            medium: 'média',
            large: 'grande',
            extaLarge: 'muito grande',
            cancel: 'Cancelar'
        };
        PrintDialog.prototype.paperSize = {
            'A4': [210, 297],
            'B4': [257, 364],
            'B5': [182, 257]
        };

        const originalSetScale = PrintDialog.prototype.setScale;
        PrintDialog.prototype.setScale = function (value) {
            self.removeAddons();
            self.addAddons(value);
            originalSetScale.call(this, value);
        };
        this.printControl = new PrintDialog({
            title: 'Impressão do Mapa',
            targetDialog: map.getTargetElement(),
            save: true,
            print: false,
            copy: true,
            pdf: true,
            lang: 'pt',
            legend: true,
            northImage: COMPASS
        });
        const compassElement = this.printControl._compass.element;
        if (compassElement) {
            compassElement.style.width = '80px';
            compassElement.style.height = '80px';
            compassElement.style.marginRight = '70px';
        }
        this.printControl.element.style.visibility = 'hidden';
        this.printControl.hide = function () {
            self.removeAddons();
            this._printDialog.hide();
        };

        this.printControl.setSize('A4');

        this.map.addControl(this.printControl);

        this.printControl.on(['print', 'error'], (e) => {
            try {
                if (e.image) {
                    if (e.pdf) {
                        const pdf = new jsPDF({
                            orientation: e.print.orientation,
                            unit: e.print.unit,
                            format: e.print.size
                        });
                        pdf.addImage(e.image, 'JPEG', e.print.position[0], e.print.position[1], e.print.imageWidth, e.print.imageHeight);
                        pdf.save(`Template do Parecer.pdf`);
                    } else {
                        this.setPrintResult(e.image, this.type);
                        this.printControl.hide();
                    }
                } else {
                    this.printControl.hide();
                    console.log(e.canvas);
                    console.error('Não há canvas para exportar. Verifique se a requisição da camada foi finalizada na aba de redes.');
                }
            } catch (error) {
                console.error('Erro ao processar a impressão:', error);
                this.printControl.hide();
            }
        });

        const controls = this.map.getControls().getArray();
        this.scaleLine = null;
        for (const control of controls) {
            if (control instanceof ScaleLine) {
                this.scaleLine = control;
                break;
            }
        }
    }
    resetConfiguration() {
        if (this.printControl) {
            this.map.removeControl(this.printControl);
            this.printControl = null;
        }
        for (const prop in this) {
            if (this.hasOwnProperty(prop)) {
                this[prop] = null;
            }
        }
        this.salvarPDFResultSubject = new Subject<string>();
        this.gerarPDFResultSubject = new Subject<string>();
        this.graticulesIntervalsBasedOnScale = {
            100: 6,
            1000: 60,
            5000: 302,
            10000: 605,
            25000: 1512,
            50000: 3025,
            100000: 6050,
            250000: 15124,
            1000000: 60498
        }
        this.map = null;
    }

    private addAddons(scale: string): void {
        if (this.graticule) {
            this.map.removeControl(this.graticule);
            this.graticule = null;
        }
        if (this.canvasScaleLine) {
            this.map.removeControl(this.canvasScaleLine);
            this.canvasScaleLine = null;
        }

        function generateIntervals(baseStep, maxStep) {
            let intervals = [];
            for (let step = maxStep; step >= baseStep; step /= 2) {
                intervals.push(step);
            }
            return intervals;
        }

        const baseStep = this.graticulesIntervalsBasedOnScale[scale] || 100;
        const maxStep = 60498;

        const intervals = generateIntervals(baseStep, maxStep);
        this.graticule = new Graticule({
            intervals: intervals,
            margin: 2,
            projection: 'EPSG:3857',
            style: new Style({
                stroke: new Stroke({
                    color: 'rgba(0,0,0,0.19)',
                    width: 0.8,
                    lineDash: [0.5, 4],
                }),
                text: new Text({
                    font: 'normal 11px Helvetica, sans-serif',
                    fill: new Stroke({
                        color: 'rgba(0, 0, 0, 1)'
                    }),
                    stroke: new Stroke({
                        color: 'rgba(255, 255, 255, 1)',
                        width: 1.5,
                    }),
                    textAlign: 'center',
                    textBaseline: 'middle'
                }),
            }),
            formatCoord: (coord) => {
                return this.toHDMS(coord);
            }
        });

        this.map.addControl(this.graticule);
        this.map.removeControl(this.scaleLine);

        const scaleLineStyle = new Style({
            stroke: new Stroke({
                color: 'rgba(0,0,0,1)',
                width: 2
            }),
            fill: new Fill({
                color: 'rgba(255,255,255,1)'
            }),
            text: new Text({
                font: 'bold 12px Arial, sans-serif',
                fill: new Fill({
                    color: 'rgba(255,255,255,1)'
                }),
                stroke: new Stroke({
                    color: 'rgba(0,0,0,1)',
                    width: 2
                })
            })
        });
        this.canvasScaleLine = new CanvasScaleLine({
            style: scaleLineStyle
        });
        const scaleLineElement = this.canvasScaleLine.element;
        if (scaleLineElement) {
            scaleLineElement.style.marginLeft = '55px';
            scaleLineElement.style.marginBottom = '13px';
        }

        this.map.addControl(this.canvasScaleLine);
    }

    private removeAddons(): void {
        if (this.graticule) {
            this.map.removeControl(this.graticule);
        }
        if (this.canvasScaleLine) {
            this.map.removeControl(this.canvasScaleLine);
        }
        this.map.addControl(this.scaleLine);
    }

    private toDMS(degree: number): string {
        const degrees = Math.floor(degree);
        const minutes = Math.floor((degree - degrees) * 60);
        const seconds = Math.floor(((degree - degrees) * 60 - minutes) * 60);
        return `${degrees}° ${minutes}' ${seconds}"`;
    }

    private toHDMS(coord: number): string {
        const proj3857 = proj4('EPSG:3857');
        const proj4674 = proj4('EPSG:4674');
        const transformedCoord = proj4(proj3857, proj4674, [coord, 0]);
        return this.toDMS(transformedCoord[0]);
    }
}
