import {Injectable, Injector} from '@angular/core';
import {BaseService} from "./base.service";
import {ParqueBombaCombustivel} from "../models/parque-bomba-combustivel.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";


@Injectable({
    providedIn: "root"
})
export class ParqueBombaCombustivelService extends BaseService<ParqueBombaCombustivel> {

    constructor(protected injector: Injector) {
        super(
            "/api/parque-bomba/combustivel",
            injector,
            ParqueBombaCombustivel.prototype,
            ParqueBombaCombustivel.fromJson,
            environment.URL_LIC_SERV_COMBUSTIVEL
        );
    }


    getByParqueBomba(idParqueBomba: number): Observable<ParqueBombaCombustivel[]> {
        const url = `${this.urlResource}/parque/${idParqueBomba}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
