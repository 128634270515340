import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../../../../../../shared/snack-bar/snack-bar.service';
import {EmpreendimentoEnergiaFotovoltaicaService} from '../../../../../../../../shared/services/empreendimento-energia-fotovoltaica.service';
import {EnergiaFotovoltaicaUnidGeradoras} from '../../../../../../../../shared/models/energia-fotovoltaica-unid-geradoras.model';

@Component({
    selector: 'app-painel-unidades-geradoras',
    templateUrl: './painel-unidades-geradoras.component.html',
    styleUrls: ['./painel-unidades-geradoras.component.scss']
})
export class PainelUnidadesGeradorasComponent implements OnInit, OnChanges {

    @Output() save: EventEmitter<any> = new EventEmitter<any>();
    @Input() id: number;
    @Input() readonly = false;
    @Input() mostrarObrigatorioUnidadesGeradoras;
    @Input() isNaoPodeEditar: boolean;

    form: FormGroup;
    dataSource: any = [];
    displayedColumns: string[] = [
        'unidadeGestora',
        'numeroPlacasPorArranjo',
        'areaArranjo',
        'potenciaTotalArranjo',
        'potenciaAtivaNominalInversorAssociado',
        'tensaoSaidaInversos',
        'dataEntradaOperacao',
    ];

    constructor(private fb: FormBuilder,
                private snackBarService: SnackBarService,
                private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService) {
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            id: [''],
            unidadeGeradora: ['', [Validators.required]],
            numeroPlacaPorArranjo: ['', [Validators.required]],
            areaArranjo: ['', [Validators.required]],
            potenciaTotalArranjo: ['', [Validators.required]],
            potenciaAtivaNominalInversorAssociado: ['', [Validators.required]],
            tensaoSaidaInversos: ['', [Validators.required]],
            dataEntradaOperacao: ['']
        });

        if(this.mostrarObrigatorioUnidadesGeradoras){
            this.form.markAsDirty();
            this.form.markAllAsTouched();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if ( changes.id ) {
            this.carregar( changes.id.currentValue );
        }

        if ( changes.readonly && !changes.readonly.currentValue ) {
            this.displayedColumns.push( 'acao' );
        }

        if (this.isNaoPodeEditar) {
            this.form.disable();
        }
    }

    filtroRegex(formName){
        var input = this.form.get(formName).value;
        input = input.replace(/[^0-9]/g, "");
        this.form.get(formName).patchValue(input);
    }

    excluir( objeto: EnergiaFotovoltaicaUnidGeradoras ): void {

        this.empreendimentoEnergiaFotovoltaicaService.deletarUnidGeradoras( objeto.id )
            .subscribe( () => {

                this.snackBarService.showSuccess( 'Unidade Geradora excluída com sucesso!' );
                this.carregar( +this.id );
            }, error => {
                this.snackBarService.showError( 'Falha ao excluir Unidade Geradora!' );
                console.error( error );
            } );
    }

    editar( objeto: EnergiaFotovoltaicaUnidGeradoras ): void {

        this.form.patchValue( {
            id: objeto.id,
            areaArranjo: objeto.arranjoAreaM2,
            potenciaTotalArranjo: objeto.arranjoPotenciaTotalKwp,
            numeroPlacaPorArranjo: objeto.arranjoQtdePlacas,
            unidadeGeradora: objeto.unidadeGeradora,
            potenciaAtivaNominalInversorAssociado: objeto.potAtivNomCaInverAssociadoKw,
            tensaoSaidaInversos: objeto.tensaoSaidaInversosV,
            dataEntradaOperacao: objeto.dataEntradaOperacao
        } );
    }

    saveForm(): void {

        if (!this.form.invalid) {

            const form = this.form.getRawValue();
            const entidade: EnergiaFotovoltaicaUnidGeradoras = {
                id: form.id,
                arranjoAreaM2: form.areaArranjo,
                arranjoPotenciaTotalKwp: form.potenciaTotalArranjo,
                arranjoQtdePlacas: form.numeroPlacaPorArranjo,
                empreendimentoEnergFotovoltaica: { id: +this.id },
                unidadeGeradora: form.unidadeGeradora,
                potAtivNomCaInverAssociadoKw: form.potenciaAtivaNominalInversorAssociado,
                tensaoSaidaInversosV: form.tensaoSaidaInversos,
                dataEntradaOperacao: form.dataEntradaOperacao
            };

            this.empreendimentoEnergiaFotovoltaicaService.salvarUnidGeradoras( entidade )
                .subscribe( retorno => {

                    this.save.emit(retorno);
                    this.snackBarService.showSuccess( 'Informações salvas com sucesso!' );
                    this.carregar( +this.id );
                    this.form.reset();
                    this.form.markAsPristine();
                    this.form.markAsUntouched();
                    this.form.updateValueAndValidity();
                    // this.limparCamposUnidadesGeradoras();
                }, error => {

                    this.snackBarService.showError( 'Falha ao salvar informações!' );
                    console.error( error );
                } );
        } else {
            this.form.markAllAsTouched();
            this.snackBarService.showError( 'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s)' );
        }

    }

    private carregar( id: number ): void {

        this.empreendimentoEnergiaFotovoltaicaService.listarUnidGeradoras( id )
            .subscribe( lista => {
                this.dataSource = lista;
            }, error => {
                this.snackBarService.showError( 'Falha ao carregar unidade geradora!' );
                console.error( error );
            } );
    }

    limparCamposUnidadesGeradoras(): void {
            this.form.get('unidadeGeradora').setValue('Unidade geradora');
            this.form.get('numeroPlacaPorArranjo').setValue('Nº de Placas por Ar...');
            this.form.get('areaArranjo').setValue('Área de Arranjo (m...');
            this.form.get('potenciaTotalArranjo').setValue('Potência Total do A...');
            this.form.get('potenciaAtivaNominalInversorAssociado').setValue('Potência ativa nominal CA...');
            this.form.get('tensaoSaidaInversos').setValue('Tensão de Saída do...');
    }
}
