import {Component, Injector, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Requerimento} from '../../requerimento.model';
import {DocumentTemplate} from '../../../../shared/document-templates/document-template';
import {PdfViewerDialog} from '../../../../shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import {EmissaoTituloService} from '../../../tarefas/shared/service/emissao-titulo.service';
import {DocumentoProcessoSignatarioService} from '../../../tarefas/shared/service/documento-processo-signatario.service';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {RedirectUtil} from '../../../../shared/util/RedirectUtil';
import {RequerimentoService} from '../../requerimento.service';
import {DocumentoProcessoService} from '../../../gerar-documento-publicacao/documento-processo.service';
import {EnumGrupoDocumento} from '../../../../shared/enums/EnumGrupoDocumento';
import {DocumentoProcesso} from '../../../../shared/models/documento-processo.model';

@Component({
    selector: 'app-assinar-termo-adessao-compromisso',
    templateUrl: 'assinar-termo-adessao-compromisso.html',
})
export class AssinarTermoAdessaoCompromissoComponent implements OnInit {

    @Input() requerimento = new Requerimento();
    @Input() idTarefa: string;
    template: DocumentTemplate;
    htmlDocumento = '';
    documentoProcesso: DocumentoProcesso;

    constructor(
        protected dialog: MatDialog,
        protected injector: Injector,
        private emissaoTituloService: EmissaoTituloService,
        private documentoProcessoSignatarioService: DocumentoProcessoSignatarioService,
        private pdfViewerDialog: PdfViewerDialog,
        private snackBarService: SnackBarService,
        private requerimentoService: RequerimentoService,
        private documentoProcessoService: DocumentoProcessoService
    ) {
    }

    ngOnInit(): void {
        this.documentoProcessoService
            .recuperarDocumentoProcessoPorIdRequerimentoESGrupoDocumento(this.requerimento.id, EnumGrupoDocumento.TERMO_DE_ADESAO_E_COMPROMISSO)
            .subscribe(documento => {
                this.documentoProcesso = documento;
            });
    }


    public visualizarDocumento(): void {
        this.emissaoTituloService.downloadDocumentoTermoAdessao(this.requerimento.id).subscribe((blob) => {
            this.pdfViewerDialog.show(blob, {height: '80%', width: '60%', showDownloadButton: true});
        });
    }

    async assinarDocumento() {
        const emissaoTitulo = await this.documentoProcessoSignatarioService.criarEmissaoLicenca(this.requerimento.id).toPromise();
        await this.documentoProcessoSignatarioService.finalizarTarefaCamunda(this.requerimento.id, this.idTarefa, emissaoTitulo).toPromise().then(() => {
            this.snackBarService.showSuccess('Assinatura efetuada com sucesso!');

            setTimeout(() => {
                this.requerimentoService.downloadDocumentosRequerimentoLicenciamento(this.requerimento.id).toPromise().then(response => {
                    setTimeout(() => {
                        this.downloadDocumentoPdf(response);
                    }, 2000);
                    this.snackBarService.showSuccess('Baixando documentos...');
                    RedirectUtil.redirecionaDashboardCamundaComDelay();
                }).catch(error => this.snackBarService.showError('Ocorreu um erro ao fazendo download dos documentos', error));

            }, 2000);
        }, error => this.snackBarService.showError('Ocorreu um erro', error));
    }

    downloadDocumentoPdf(blob: Blob): void {
        const blobGenerate = new Blob([blob], {type: blob.type});
        const downloadURL = window.URL.createObjectURL(blobGenerate);
        const link = document.createElement('a');
        link.href = downloadURL;
        if (blob.type === 'application/pdf') {
            link.download = 'Documentos-requerimento-licenciamento.pdf';
        } else {
            link.download = 'Documentos-requerimento-licenciamento.zip';
        }
        link.click();
    }
}


