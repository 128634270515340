import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import Map from 'ol/Map';
import {Feature} from "ol";
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import OverviewMap from 'ol/control/OverviewMap';
import {SnackBarService} from "../../../snack-bar/snack-bar.service";
import {Feicao} from "../../../models/geoadmin/feicao.model";
import {GeoJSON} from 'ol/format';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {CenarioRegrasGeo} from "../../../models/geoadmin/cenario-regras-geo";
import {
    agruparFeicoesAnalisadasPorObjetivos, cleanCamadas, convertLatLonToDMS, getAllGeometriasMapa,
    getCamada, getCamadaByFeicaoEntrada,
    getEstiloFromFeicao, getFeicoesEntrada,
    getStyle, ordenarFeicoes,
    removerCamada
} from "../../../util/geo";
import {FeicaoRequerimentoLicenciamentoDto} from "../../../models/feicao-requerimento-licenciamento-dto.model";
import {RegrasGeoRequerimentoFeicao} from "../../../models/regras-geo-requerimento/regra-geo-requerimento-feicao.model";
import {LoadingService} from "../../loading/loading.service";
import {
    Camada,
    ExtraOptions,
    GeometriaMapa,
    geometriaUuid,
    MapaPadraoComponent,
    PermissaoCamada
} from "@sema-geo/sema-geoportal";
import {
    RequerimentoLicenciamentoService
} from "../../../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.service";
import {Requerimento} from "../../../../pages/requerimentos/requerimento.model";
import {RegrasGeoRequerimento} from "../../../models/regras-geo-requerimento/regras-geo-requerimento.model";
import {Estilo} from "@sema-geo/sema-geoportal/lib/mapa-padrao/models/estilo";
import {CamadaRegraGeo} from "../../../models/geoadmin/camada-regra-geo.model";
import {InformacaoAnm} from "../../../models/informacao-anm.model";
import {AtividadeMineracaoService} from "../../../services/atividade-mineracao.service";
import {InformacaoAnmService} from "../../../services/informacao-anm.service";
import {ImovelService} from "../../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.service";
import {
    RequerimentoFormularioLicenciamentoService
} from "../../../../pages/requerimentos/requerimento-licenciamento/requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {FeicaoService} from "../../../services/geoadmin/feicao.service";
import {RequerimentoService} from "../../../../pages/requerimentos/requerimento.service";
import {
    RegrasGeoRequerimentoService
} from "../../../services/licenciamento-config-service/regras-geo-requerimento.service";
import {ObjetivoLicenciamentoService} from "../../../services/objetivo-licenciamento.service";
import {
    RequerimentoLicenciamento
} from "../../../../pages/requerimentos/requerimento-licenciamento/requerimento-licenciamento.model";
import {CamadaFeicao, CamadaObjetivo} from "../../../models/geoadmin/feicao-vinculada.interface";
import {Imovel} from "../../../../pages/requerimentos/shared/requerimento-localizacao/shared/imovel.model";
import {ObjetivoLicenciamento} from "../../../models/objetivo-licenciamento.model";
import {StatusGeometria} from "../../../enums/status-geometria.enum";
import {forkJoin, Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MapPrintService} from "../../../services/geo/map-print.service";
import {logoSemaTemplateV2} from "./template-parecer-sema-imagens";
import {DatePipe, DecimalPipe} from "@angular/common";
import {
    TemplateImovelMapa
} from "../../../../pages/requerimentos/shared/requerimento-localizacao/shared/template-imovel-mapa.model";
import {getFeicaoCarStyle} from "../../../util/car-style.utl";
import {ModeloFeicao} from "../../../enums/modelo-feicao.enum";
import {AAir} from "../../../../pages/requerimentos/shared/requerimento-localizacao/shared/car-air.model";
import {FeicaoTabelaAtributosService} from "../../../services/geo/feicao-tabela-atributos.service";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-template-parecer-sema-geoportal',
    templateUrl: './template-parecer-sema-geoportal.component.html',
    styleUrls: ['./template-parecer-sema-geoportal.component.scss']
})
export class TemplateParecerSemaGeoportalComponent implements AfterViewInit, OnDestroy {

    @Input()
    requerimento = new Requerimento();

    @Output()
    arquivoGerado = new EventEmitter<{ blob: Blob, nome: string }>();

    idMapa: string;

    extraOptions: ExtraOptions[];

    referenciasTemporarias: Camada = {
        title: 'Referências temporárias',
        name: 'temporario',
        expandGroup: true,
        permissao: {
            remover: true,
            ponto: true,
            poligono: true,
            linha: true,
            buffer: true,
            upload: true,
            exportar: true
        },
        camadas: []
    };

    localizacao: Camada = {
        title: 'Localização',
        name: 'localizacao',
        geometryGroup: true,
        expandGroup: true,
        camadas: []
    };

    feicoesDadosAnm: Camada = {
        title: 'Dados ANM',
        name: 'anm',
        geometryGroup: true,
        expandGroup: false,
        camadas: []
    };

    feicoesRequerimento: Camada = {
        title: 'Feições do Requerimento',
        name: 'requerimento',
        geometryGroup: true,
        expandGroup: true,
        camadas: []
    };

    feicoesAnalisadas: Camada = {
        title: 'Feições Analisadas',
        name: 'feicoes-analisadas',
        geometryGroup: true,
        expandGroup: true,
        camadas: [],
    };

    camadaTrabalho: Camada = {
        title: 'Camada de trabalho',
        name: 'trabalho',
        geometryGroup: true,
        expandGroup: true,
        camadas: [this.referenciasTemporarias]
    };

    camadas: Camada[] = [this.camadaTrabalho];
    camadaSelecionada: Camada;

    @ViewChild('mapaPadraoComponent', {static: false})
    componenteMapa: MapaPadraoComponent;

    imoveisDoRequerimento: Imovel[] = [];
    templatesImovelMapa: TemplateImovelMapa[] = [];
    subgrupos: any[] = [];
    geometrias: FeicaoRequerimentoLicenciamentoDto[] = [];
    regrasFeicoes = new Map<number, RegrasGeoRequerimentoFeicao[]>();

    feicaoesPrincipais: RegrasGeoRequerimentoFeicao[] = [];

    objetivos: ObjetivoLicenciamento[] = [];

    requerimentoLicenciamento: RequerimentoLicenciamento;

    private unsubscribeAll: Subject<any> = new Subject<any>();
    private isPrintServiceConfigured = false;

    constructor(
        private readonly snackBarService: SnackBarService,
        private readonly loadingService: LoadingService,
        private readonly requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private readonly atividadeMineracaoService: AtividadeMineracaoService,
        private readonly informacaoAnmService: InformacaoAnmService,
        private readonly imovelService: ImovelService,
        private readonly requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private readonly feicaoService: FeicaoService,
        private readonly requerimentoService: RequerimentoService,
        private readonly regrasGeoRequerimentoService: RegrasGeoRequerimentoService,
        private readonly objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private readonly mapPrintService: MapPrintService,
        private readonly datePipe: DatePipe,
        private readonly decimalPipe: DecimalPipe,
        private readonly feicaoTabelaAtributosService: FeicaoTabelaAtributosService,
    ) {
        this.idMapa = Date.now().toString(36);
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
        this.resetPrintConfiguration();
        for (const prop in this) {
            if (this.hasOwnProperty(prop)) {
                this[prop] = null;
            }
        }
    }

    private configurePrint() {
        if (!this.isPrintServiceConfigured) {
            // Remover o controle OverviewMap
            const map = this.componenteMapa.getMap()
            map.getControls().forEach(control => {
                if (control instanceof OverviewMap) {
                    map.removeControl(control);
                }
            });
            this.mapPrintService.configPrint(map);
            this.isPrintServiceConfigured = true;
        }
    }

    private resetPrintConfiguration() {
        this.mapPrintService.resetConfiguration();
        this.isPrintServiceConfigured = false;
    }

    buscarRequerimentoLicenciamento(): Promise<any> {
        return this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id)
            .toPromise()
            .then(async (requerimentoLicenciamento) => {
                return this.requerimentoLicenciamento = requerimentoLicenciamento;
            });
    }

    setRegrasToFeicaoEntrada(regraGeoConfig: RegrasGeoRequerimentoFeicao, camada: Camada) {
        const camadasFeicaoEntrada = getCamadaByFeicaoEntrada(camada, regraGeoConfig.idFeicaoEntrada);
        camadasFeicaoEntrada.forEach((cam: CamadaRegraGeo) => {
            regraGeoConfig.regras.forEach((regra) => {
                if (!cam.regraGeoRequerimentoFeicao.regras.some(regraExistente => regraExistente.id === regra.id)) {
                    cam.regraGeoRequerimentoFeicao.regras.push(regra);
                }
            });
        })
    }

    inserirFeicoesImoveisDoRequerimento(): void {
        cleanCamadas(this.localizacao, this.componenteMapa);
        if (this.imoveisDoRequerimento.length > 0) {
            this.localizacao.camadas.forEach((camada: Camada) => cleanCamadas(camada, this.componenteMapa));

            this.imoveisDoRequerimento.forEach((imovel) => {
                imovel.geometrias.forEach((geometria) => {
                    const geometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt);
                    geometriaMapa.id = geometria.id;
                    geometriaMapa.name = geometriaUuid(geometria.id);

                    geometriaMapa.permissao = {
                        remover: false
                    };
                    geometriaMapa.extra = geometria;
                    if (imovel.tipo === 'RURAL') {
                        const imovelPrincipal = imovel.imovelPrincipal ? '*' : '';
                        geometriaMapa.title = `${imovel.numeroCAR} ${imovelPrincipal}`;
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            Nome: geometriaMapa.title
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                    } else if (imovel.tipo === 'URBANO') {
                        geometriaMapa.title = 'Área do imóvel urbano';
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            Nome: geometriaMapa.title,
                            imovel: imovel.denominacao
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                        // Só permitir um único polígono para o imóvel urbano.
                        this.localizacao.permissao.poligono = false;
                    } else if (imovel.tipo === 'OBRA') {
                        geometriaMapa.title = geometriaMapa.tipoGeometria;
                        geometriaMapa.propriedades = {
                            id: geometria.id,
                            imovel: imovel.denominacao
                        };
                        geometriaMapa.extra['imovel'] = imovel;
                    }
                    geometriaMapa.visualizacao = {
                        showCheckbox: true,
                        showFeature: true
                    }
                    geometriaMapa['expandGroup'] = true
                    geometriaMapa.style = this.componenteMapa.defaultStyle
                    geometriaMapa.feature.setStyle(this.componenteMapa.defaultStyle)
                    this.localizacao.camadas.push(geometriaMapa);
                });
            });

            if (this.imoveisDoRequerimento.some(imovel => imovel.geometrias.some(geom => geom.wkt !== ''))) {
                this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'localizacao');
                this.camadaTrabalho.camadas.push(this.localizacao);
            }

        }
    }

    private findCamada(idFeicao: number, idObjetivo: number): CamadaRegraGeo {
        const camadasObjetivo: Camada = this.feicoesRequerimento.camadas.find(c => c.name === `objetivo-${idObjetivo}`);
        if(camadasObjetivo){
            return camadasObjetivo.camadas.find((c: CamadaRegraGeo) => c.name === `${idFeicao}-${idObjetivo}`);
        } else {
            return null
        }
    }

    // Método para adicionar feições de um template específico ao grupo
    adicionarFeicoesDoTemplate(template: TemplateImovelMapa, grupo: any): void {
        this.subgrupos = [
            {nome: 'CAR_AIR', titulo: 'CAR AIR', lista: template.listAAir},
            {nome: 'CAR_APP', titulo: 'CAR APP', lista: template.listCarApp},
            {nome: 'CAR_ARL', titulo: 'CAR ARL', lista: template.listCarArl},
            {nome: 'CAR_AVN', titulo: 'CAR AVN', lista: template.listCarAvn},
            {nome: 'CAR_AUAS', titulo: 'CAR AUAS', lista: template.listCarAuas},
            {nome: 'CAR_AREA_CONSOLIDADA', titulo: 'CAR ÁREA CONSOLIDADA', lista: template.listCarAreaConsolidada}
        ];

        this.subgrupos.forEach(subgrupo => {
            if (subgrupo.lista && subgrupo.lista.length > 0) {
                const subgrupoCamada: Camada = {
                    title: subgrupo.titulo || '',
                    name: '',
                    visualizacao: {
                        showCheckbox: true,
                        showFeature: true
                    },
                    permissao: {
                        remover: false,
                        editar: false
                    },
                    expandGroup: true,
                    camadas: []
                };

                subgrupo.lista.forEach((feicao: any) => {
                    const geometriaMapa = this.componenteMapa.criarGeometria(feicao.wkt);
                    geometriaMapa.id = feicao.id;
                    geometriaMapa.name = geometriaUuid(subgrupo.nome === 'CAR_AIR' ? `${feicao.idAAir}` : `${feicao.id}`);

                    geometriaMapa.permissao = {remover: false};
                    geometriaMapa.extra = feicao;
                    geometriaMapa.title = subgrupo.nome === 'CAR_AIR' ? `${feicao.identificacao}` : `${feicao.id}`;
                    geometriaMapa.propriedades = {
                        id: feicao.id,
                        Nome: geometriaMapa.title
                    };
                    geometriaMapa.visualizacao = {
                        showCheckbox: true,
                        showFeature: true
                    };
                    const style = getFeicaoCarStyle(subgrupo.nome);

                    geometriaMapa.expandGroup = true;
                    geometriaMapa.style = style;
                    geometriaMapa.feature.setStyle(style);
                    subgrupoCamada.camadas.push(geometriaMapa);
                });

                grupo.camadas.push(subgrupoCamada);
            }
        });
    }

    /*
     RN07: Deve apresentar os dados de todos os polígonos vinculados ao requerimento conforme configuração dos dados geográficos no SIGA.
     As geometrias do requerimento devem estar na situação análise APROVADA para ser considerada no template.
     É necessário ter pelo menos uma geometria aprovada para emissão do mapa. Não deve exibir no mapa as feições dos modelos DESMATE, EVENTUAL e SELETIVA, mesmo que estejam aprovadas.
    */
    showGeometria(geometria: FeicaoRequerimentoLicenciamentoDto, camada: CamadaRegraGeo): boolean {
        if (geometria.statusGeometria !== StatusGeometria.APROVADA) {
            return false;
        }

        if (camada.feicao.idModeloFeicao === ModeloFeicao.EVENTUAL || camada.feicao.idModeloFeicao === ModeloFeicao.SELETIVA || camada.feicao.idModeloFeicao === ModeloFeicao.DESMATE) {
            return false;
        }

        return true;
    }

    inserirFeicoesDoRequerimento(): void {
        // Feições do requerimento.
        this.feicoesRequerimento.camadas.forEach((camada: Camada) => {
            camada.camadas.forEach((cam: Camada) => cleanCamadas(cam, this.componenteMapa))
        });
        if (this.geometrias) {
            for (let geometria of this.geometrias) {
                if (!geometria.objetivoLicenciamento) {
                    console.error("ABA 4 - Dados Geográficos: feição sem objetivo definido.")
                    continue;
                }

                if (!geometria.wkt) {
                    console.error(`Não foi encontrado o WKT da geometria ${geometria.denominacao}.`)
                    continue;
                }

                const camada: CamadaRegraGeo = this.findCamada(geometria.idFeicaoEntrada, geometria.objetivoLicenciamento);
                if (camada && this.showGeometria(geometria, camada)) {

                    const geometriaMapa: GeometriaMapa = this.componenteMapa.criarGeometria(geometria.wkt, camada.style);
                    let permissao: PermissaoCamada = {
                        remover: false,
                        editar: false
                    }
                    geometriaMapa.extraOptions = [];


                    if (geometria.statusGeometria === StatusGeometria.ENVIADA || geometria.statusGeometria === StatusGeometria.AGUARDANDO_ANALISE) {
                        permissao = {
                            remover: false,
                            editar: false
                        }
                    }

                    geometriaMapa.permissao = permissao;
                    geometriaMapa.extra = {
                        ...geometria,
                        idModeloFeicao: camada.feicao.idModeloFeicao
                    };
                    geometriaMapa.title = `[${geometria.id}] ${geometria.denominacao || 'Sem denominação'}`;

                    geometriaMapa.name = geometriaUuid(geometria.id);
                    geometriaMapa.propriedades = {
                        ID: geometria.id,
                        'Denominação': geometria.denominacao,
                    };

                    if (!geometria.denominacao) {
                        geometriaMapa.warningMessage = 'Cadastro incompleto';
                    }
                    geometriaMapa.visualizacao = {
                        showCheckbox: true,
                        showFeature: true
                    }
                    camada['expandGroup'] = true
                    geometriaMapa.style = camada.style;

                    camada.camadas.push(geometriaMapa);
                }
            }
        }
        if (this.templatesImovelMapa.length > 0) {
            const grupoFeicoesCAR: Camada = {
                title: "Feições do CAR",
                name: "feicoes-car",
                camadas: [],
                visualizacao: {
                    showCheckbox: true,
                    showFeature: true
                },
                permissao: {
                    remover: false,
                    editar: false
                },
                expandGroup: true
            };

            this.templatesImovelMapa.forEach(template => {
                const grupoFeicoesImovel: Camada = {
                    title: template.imovel.numeroCAR,
                    name: `imovel-feicoes-car-${template.imovel.numeroCAR}`,
                    camadas: [],
                    visualizacao: {
                        showCheckbox: true,
                        showFeature: true
                    },
                    permissao: {
                        remover: false,
                        editar: false
                    },
                    expandGroup: true
                };
                this.adicionarFeicoesDoTemplate(template, grupoFeicoesImovel);

                // Adicionar grupo do imóvel ao grupo principal
                grupoFeicoesCAR.camadas.push(grupoFeicoesImovel);
            });

            // Adicionar grupo principal nas feicoesRequerimento
            this.feicoesRequerimento.camadas.push(grupoFeicoesCAR);
        }
        this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'requerimento');
        this.camadaTrabalho.camadas.push(this.feicoesRequerimento);

        ordenarFeicoes(this.feicoesRequerimento, this.componenteMapa);

    }

    async atualizarMapa(): Promise<void> {
        this.componenteMapa.getSource().clear();

        this.inserirFeicoesImoveisDoRequerimento();
        this.inserirFeicoesDoRequerimento();

        if (this.feicoesDadosAnm.camadas.length > 0) {
            this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'anm');
            this.camadaTrabalho.camadas.push(this.feicoesDadosAnm);
            this.camadas = [...this.camadas];
            this.componenteMapa.fit();
        }

        this.camadas = [...this.camadas];
        this.getFeicoesAnalisadas();
        this.componenteMapa.fit();
    }

    async atualizarDadosMapa() {
        this.geometrias = await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();
        this.imoveisDoRequerimento = await this.imovelService.buscarTodosPorRequerimentoComGeometrias(this.requerimento.id).toPromise();

        const hasRuralImovel = this.imoveisDoRequerimento.some(imovel => imovel.tipo === 'RURAL');
        if (hasRuralImovel) {
            this.imovelService.getTemplateMapa(this.requerimento.id).pipe(
                takeUntil(this.unsubscribeAll)
            ).subscribe((templates: TemplateImovelMapa[]) => {
                this.templatesImovelMapa = templates;
                this.atualizarMapa();
            }, error => {
                // Lidar com qualquer erro que ocorra durante a execução dos observáveis
                console.error('Erro ao buscar templates de mapa:', error);
            });
        } else {
            await this.atualizarMapa();
        }
    }

    async listarGeometrias(): Promise<void> {
        const feicoesDuplicadas: RegrasGeoRequerimentoFeicao[] = [];
        let idsFeicoes = [];

        this.feicoesRequerimento.camadas = [];

        this.objetivos = await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();

        for (let objetivo of this.objetivos) {

            let regrasGeoRequerimento: RegrasGeoRequerimento[] = await this.regrasGeoRequerimentoService.getByTipoProcessoAtividadeObjetivo(
                this.requerimentoLicenciamento.requerimento.tipoProcesso.id,
                this.requerimentoLicenciamento.idAtividade,
                objetivo.idObjetivoLicenciamentoAmbiental
            ).toPromise();

            let feicoesConfiguracao: RegrasGeoRequerimentoFeicao[] = [];

            for (let f of regrasGeoRequerimento.map(r => r.feicoes)) {
                feicoesConfiguracao = feicoesConfiguracao.concat(f);
            }

            this.regrasFeicoes.set(objetivo.idObjetivoLicenciamentoAmbiental, feicoesConfiguracao);

            this.feicaoesPrincipais = feicoesConfiguracao.filter(feicao => feicao.principal)

            const camadaObjetivos: Camada = {
                title: objetivo.descricaoObjetivo,
                name: `objetivo-${objetivo.idObjetivoLicenciamentoAmbiental}`,
                geometryGroup: true,
                expandGroup: true,
                camadas: [],
            };

            for (let feicaoConfiguracao of feicoesConfiguracao) {
                // Evita a inclusão duplicada de feições em uma única camada.
                if (idsFeicoes.includes(feicaoConfiguracao.idFeicaoEntrada)) {
                    feicoesDuplicadas.push(feicaoConfiguracao)
                    continue;
                }
                idsFeicoes.push(feicaoConfiguracao.idFeicaoEntrada);

                let feicaoEntrada: Feicao = await this.feicaoService.getById(feicaoConfiguracao.idFeicaoEntrada).toPromise();

                feicaoEntrada.objetivoLicenciamento = objetivo.idObjetivoLicenciamentoAmbiental;

                const estilo: Estilo = getEstiloFromFeicao(feicaoEntrada);
                const feicaoPrincipal = feicaoConfiguracao.principal ? ' * ' : '';

                let camada: CamadaRegraGeo = {
                    title: `${feicaoPrincipal} ${feicaoEntrada.descricao}`,
                    name: `${feicaoConfiguracao.idFeicaoEntrada}-${objetivo.idObjetivoLicenciamentoAmbiental}`,
                    camadas: [],
                    permissao: {
                        ponto: false,
                        linha: false,
                        poligono: false,
                        editar: false,
                        remover: false,
                        upload: true
                    },
                    style: estilo ? getStyle(estilo) : this.componenteMapa.defaultStyle
                };
                camada.regraGeoRequerimentoFeicao = feicaoConfiguracao;
                camada.feicao = feicaoEntrada;

                camada.extraOptions = [
                    {
                        icon: 'arrow_downward',
                        text: 'Download do SHP',
                        hint: 'Download do SHP da Feição',
                        callback: () => this.downloadSHP(feicaoEntrada)
                    },
                    {
                        icon: 'arrow_downward',
                        text: 'Download do KML',
                        hint: 'Download do KML da Feição',
                        callback: () => this.downloadKML(feicaoEntrada)
                    }
                ]

                camadaObjetivos.camadas.push(camada)
            }

            if (feicoesConfiguracao.length > 0) {
                feicoesDuplicadas.forEach(feicao => {
                    this.setRegrasToFeicaoEntrada(feicao, camadaObjetivos);
                })
                setTimeout(() => this.feicoesRequerimento.camadas.push(camadaObjetivos), 100);
            } else {
                this.snackBarService.showAlert(`Não foram encontradas Regras GEO configuradas para o objetivo: ${objetivo.descricaoObjetivo}.`);
            }
        }

        await this.atualizarDadosMapa();
    }

    downloadSHP(feicao: Feicao) {
        this.feicaoTabelaAtributosService.downloadSHP(this.requerimento.id, feicao.id).pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(blob => {
            this.feicaoTabelaAtributosService.showFile(blob, `${feicao.descricao.toUpperCase()}_SHP.zip`);
        }, error => {
            this.snackBarService.showError(`Erro ao baixar o SHP: ${error}.`);

        });
    }

    downloadKML(feicao: Feicao) {
        this.feicaoTabelaAtributosService.downloadKML(this.requerimento.id, feicao.id).pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe(blob => {
            this.feicaoTabelaAtributosService.showFile(blob, `${feicao.descricao.toUpperCase()}_KML.zip`);
        }, error => {
            this.snackBarService.showError(`Erro ao baixar o KML: ${error}.`);
        });
    }

    async carregarMineracao(idRequerimento): Promise<void> {
        let mineracao = await this.atividadeMineracaoService.getByRequerimento(idRequerimento).toPromise().catch(() => null);
        if (mineracao) {
            let informacoes: InformacaoAnm[] = await this.informacaoAnmService.getByMineracao(mineracao.id).toPromise().catch(() => []);
            if (informacoes) {
                for (let informacao of informacoes) {
                    const numero = Number(informacao.numeroProcesso.substring(0, informacao.numeroProcesso.length - 4));
                    const ano = Number(informacao.numeroProcesso.substring(informacao.numeroProcesso.length - 4));

                    let dados = await this.informacaoAnmService.getDadosAnm(numero, ano).toPromise().catch(() => null);
                    if (dados) {
                        let camada = new GeometriaMapa(this.componenteMapa.projection);
                        camada.title = `Processo: ${informacao.numeroProcesso}`;
                        camada.name = `processo-${numero}`;
                        camada.camadas = [];
                        camada.permissao = {
                            ponto: false,
                            linha: false,
                            poligono: false,
                            editar: false,
                            remover: false,
                            upload: false
                        };

                        camada.visualizacao = {
                            showCheckbox: true,
                            showFeature: true
                        };

                        const jsonReader = new GeoJSON();
                        const dadosImportacao: Feature[] = jsonReader.readFeatures(dados, {
                            featureProjection: this.componenteMapa.getMap().getView().getProjection(),
                            dataProjection: 'EPSG:4674'
                        });

                        this.componenteMapa.getSource().addFeatures(dadosImportacao);

                        for (let feature of dadosImportacao) {
                            // @ts-ignore
                            let geometria = this.componenteMapa.criarGeometriaMapa(feature);
                            geometria.title = geometria.propriedades["NOME"] || `Processo: ${informacao.numeroProcesso}`;
                            geometria.visualizacao = {
                                showCheckbox: true,
                                showFeature: true
                            };
                            this.feicoesDadosAnm.camadas.push(geometria);
                        }
                    }
                }
            }
        }

        this.feicoesDadosAnm.camadas = [...this.feicoesDadosAnm.camadas];
    }

    private async carregaFeicoesAnm(): Promise<void> {
        if (this.requerimento && this.requerimento.id) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarMineracao(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarMineracao(this.requerimento.id)
            }
        }
    }

    private async showFeicoesAnalisadas(camadas: CamadaObjetivo[]) {
        const camadasDeFeicoesAnalisadas: Camada[] = [];
        for (const camadaObjetivo of camadas) {
            camadaObjetivo.camada.camadas = [];
            camadaObjetivo.camada.expandGroup = true
            const feicoes: CamadaFeicao[] = await Promise.all(camadaObjetivo.feicoesPromises);
            for (const feicaoAnalisada of feicoes) {
                if(feicaoAnalisada) {
                    feicaoAnalisada.camada.expandGroup = true
                    feicaoAnalisada.camada.geometryGroup = true;
                    feicaoAnalisada.camada.camadas = [];

                    for (const feicao of feicaoAnalisada.geometrias) {
                        if(feicao.wkt){


                        const geometriaMapa: GeometriaMapa = this.componenteMapa.criarGeometria(feicao.wkt, feicaoAnalisada.camada.style);
                        geometriaMapa.id = feicao.id;
                        geometriaMapa.title = `[${feicao.id}] ${feicao.denominacao || 'Sem denominação'}`;
                        geometriaMapa.name = geometriaUuid(feicao.id);

                        geometriaMapa.propriedades = {
                            ID: feicao.id,
                            'Denominação': feicao.denominacao,
                        };
                        geometriaMapa.permissao = {
                            remover: false,
                            ponto: false,
                            poligono: false,
                            linha: false,
                            buffer: false,
                            upload: false,
                            exportar: true
                        };
                        geometriaMapa.extra = feicao;
                        geometriaMapa.visualizacao = {
                            showCheckbox: true,
                            showFeature: true
                        }
                        geometriaMapa.expandGroup = true;
                        geometriaMapa.style = feicaoAnalisada.camada.style
                        geometriaMapa.feature.setStyle(feicaoAnalisada.camada.style)
                        feicaoAnalisada.camada.camadas.push(geometriaMapa)
                        } else {
                            console.error(`A feição ${feicao.denominacao}, não possui WKT (Geometria) setada.`)
                        }
                    }
                    camadaObjetivo.camada.camadas.push(feicaoAnalisada.camada)
                }
            }
            camadasDeFeicoesAnalisadas.push(camadaObjetivo.camada)
        }
        cleanCamadas(this.feicoesAnalisadas, this.componenteMapa)
        this.feicoesAnalisadas.camadas = [...camadasDeFeicoesAnalisadas];

        ordenarFeicoes(this.feicoesAnalisadas, this.componenteMapa);
        this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'feicoes-analisadas');
        this.camadaTrabalho.camadas.push(this.feicoesAnalisadas);
        this.camadas = [...this.camadas];
        this.componenteMapa.fit();
    }

    private async handleFeicoesAnalisadas(feicoesAnalisadas: FeicaoRequerimentoLicenciamentoDto[]) {
        const feicoesAgrupadas: CamadaObjetivo[] = agruparFeicoesAnalisadasPorObjetivos(feicoesAnalisadas);
        feicoesAgrupadas.forEach((objetivo: CamadaObjetivo) => {
            objetivo.camada = {
                title: objetivo.descricaoObjetivoLicenciamento,
                name: `objetivo-${objetivo.objetivoLicenciamento}`,
                geometryGroup: true,
                expandGroup: true,
                camadas: [],
            };
            objetivo.feicoesPromises = [];
            objetivo.feicoes.forEach((feicaoEntrada: CamadaFeicao) => {
                objetivo.feicoesPromises.push(new Promise<CamadaFeicao>((resolve, reject) => {
                    if(feicaoEntrada.idFeicaoEntrada){
                        this.feicaoService.getById(Number(feicaoEntrada.idFeicaoEntrada)).pipe(
                            takeUntil(this.unsubscribeAll)
                        ).subscribe({
                            next: (feicao: Feicao) => {
                                resolve({
                                    camada: getCamada(feicao),
                                    geometrias: feicaoEntrada.geometrias
                                })
                            },
                            error: e => {
                                console.error('handleFeicoesAnalisadas', e)
                                resolve(null)
                            }
                        })
                    } else {
                        resolve(null)
                    }
                }))
            })
        })
        await this.showFeicoesAnalisadas(feicoesAgrupadas);
    }

    private getFeicoesAnalisadas(): void {
        if (this.requerimentoLicenciamento.requerimento.numeroProtocoloVinculado) {
            this.requerimentoLicenciamentoService.getFeicoesAnalisadas(this.requerimentoLicenciamento.requerimento.numeroProtocoloVinculado)
                .pipe(
                    takeUntil(this.unsubscribeAll)
                )
                .subscribe({
                    next: (feicoesAnalisadas: FeicaoRequerimentoLicenciamentoDto[]) => this.handleFeicoesAnalisadas(feicoesAnalisadas).then(),
                    error: e => this.snackBarService.showError('Erro ao buscar as feições analisadas.', e)
                })
        }
    }

    carregamentoCompleto() {
        this.buscarRequerimentoLicenciamento().then(async () => {
            await this.listarGeometrias();

            // @ts-ignore
            this.componenteMapa.urlWMS.value = 'https://geo.anm.gov.br/arcgis/services/SIGMINE/dados_anm/MapServer/WMSServer?request=GetCapabilities&service=WMS';
            this.componenteMapa.onWMS(document.createElement('div'));

            await this.carregaFeicoesAnm();
            if (this.feicoesDadosAnm.camadas.length > 0) {
                this.camadaTrabalho.camadas = removerCamada(this.camadaTrabalho, 'anm');
                this.camadaTrabalho.camadas.push(this.feicoesDadosAnm);
                this.camadas = [...this.camadas];
                this.componenteMapa.fit();
            }
            this.configurePrint();
        });
    }

    onSelectCamada(camadaSelecionada: Camada): void {
        this.camadaSelecionada = camadaSelecionada;
    }

    convertLegendaTreeToTable(legendaTree: any[]): any[] {
        const tableBody = [];

        legendaTree.forEach((node) => {
            if (node !== null) {
                if (node.icon) {
                    tableBody.push([
                        {svg: node.icon, width: 10, height: 10, style: 'info'},
                        {text: node.title, colSpan: 2, alignment: 'left', style: 'info'},
                        {text: ''}  // Empty cell due to colSpan
                    ]);
                } else {
                    tableBody.push([
                        {text: '', width: 10},
                        {text: node.title, colSpan: 2, alignment: 'left', style: 'info'},
                        {text: ''}  // Empty cell due to colSpan
                    ]);
                }

                if (node.camadas && node.camadas.length > 0) {
                    const childRows = this.convertLegendaTreeToTable(node.camadas);
                    tableBody.push(...childRows);
                }
            }
        });

        return tableBody;
    }

    geraPDF(imagem): Promise<any> {
        this.loadingService.setValue(true);
        return new Promise((resolve, reject) => {
            const content: any[] = [];
            let document = {
                pageSize: 'A4',
                pageOrientation: 'portrait',
                // [left, top, right, bottom]
                pageMargins: [10, 100, 10, 20],

                header: {
                    margin: [23, 15, 24, 20],
                    columns: [{
                        stack: [
                            {
                                image: logoSemaTemplateV2,
                                width: 553,
                                margin: [0, 0, 0, 8]
                            },
                            {
                                canvas: [
                                    {
                                        type: 'line',
                                        x1: 0,
                                        y1: 0,
                                        x2: 552,
                                        y2: 0,
                                        lineWidth: 1,
                                        lineColor: '#949597'
                                    }
                                ]
                            },
                            {
                                text: [
                                    {text: 'WWW.', fontSize: 6, color: '#949597', characterSpacing: 2},
                                    {text: 'MT', bold: true, fontSize: 6, color: '#949597', characterSpacing: 2},
                                    {text: '.GOV.BR', fontSize: 6, color: '#949597', characterSpacing: 2}
                                ],
                                margin: [0, 2, 0, 2],
                                alignment: 'right'
                            },
                        ]
                    }]
                },
                content: [{
                    columns: [
                        {
                            text: [
                                {text: 'Processo: ', bold: true, style: 'titulo'},
                                {text: this.requerimento.numeroProcesso, bold: true, style: 'titulo'}
                            ],
                            alignment: 'left'
                        },
                        {
                            text: [
                                {text: 'Data de Protocolo: ', bold: true, style: 'titulo'},
                                {
                                    text: this.datePipe.transform(this.requerimento.dataProtocoloProcesso, 'dd/MM/yyyy HH:mm:ss'),
                                    bold: true,
                                    style: 'titulo'
                                }
                            ],
                            alignment: 'right'
                        }
                    ],
                    margin: [10, 0, 10, 5],
                }],
                styles: {
                    titleReport: {
                        fontSize: 8,
                        bold: true,
                        color: 'gray'
                    },
                    textFooter: {
                        fontSize: 9
                    },
                    msg: {
                        fontSize: 10,
                        color: 'red'
                    },
                    title: {
                        fontSize: 13,
                        bold: true
                    },
                    table: {
                        fontSize: 10,
                    },
                    value: {
                        fontSize: 10,
                    },
                    info: {
                        fontSize: 8,
                    },
                    titulo: {
                        fontSize: 10,
                    },
                    valueMsg: {
                        color: 'red',
                        fontSize: 9,
                    },
                    legendaCentroid: {
                        fontSize: 10,
                    },
                    headerLine: {
                        margin: [0, 0, 0, 10] // top, right, bottom, left
                    }
                }
            }
            const mapIamgem = {
                image: imagem,
                alignment: 'center',
                width: 552,
            }
            if (imagem.height > 500) {
                mapIamgem['height'] = 400
            }
            content.push(mapIamgem);

            content.push({
                text: 'SRC: SIRGAS 2000',
                style: 'info',
                alignment: 'right',
                margin: [0, 5, 10, 10]
            });
            content.push({
                text: 'LEGENDA',
                alignment: 'left',
                style: 'info',
                margin: [10, 2, 10, 2]
            });

            let tableLagendaFeicoesRequerimento = {
                style: 'table',
                // [left, top, right, bottom]
                margin: [10, 2, 1, 2],
                table: {
                    alignment: 'center',
                    headerRows: 1,
                    widths: [15, 'auto', 'auto'],
                    body: [],
                },
                layout: {
                    hLineColor: (i, node) => {
                        return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
                    },
                    vLineColor: (i, node) => {
                        return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                    }
                }
            };
            let tableLegendaFeicoesImoveis = {
                style: 'table',
                margin: [1, 2, 10, 2],
                table: {
                    alignment: 'center',
                    headerRows: 1,
                    widths: [10, 'auto', 'auto'],
                    body: [],
                },
                layout: {
                    hLineColor: (i, node) => {
                        return (i === 0 || i === node.table.body.length) ? 'white' : 'white';
                    },
                    vLineColor: (i, node) => {
                        return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                    }
                }
            };
            const feicoesImoveis = this.subgrupos.map(grupo => {
                return {
                    icon: this.styleToSvg(getFeicaoCarStyle(grupo.nome)),
                    title: grupo.titulo,
                    lista: grupo.lista
                }
            });

            feicoesImoveis.forEach(feicao => {
                if (feicao.lista && feicao.lista.length > 0) {
                    tableLegendaFeicoesImoveis.table.body.push([
                        {svg: feicao.icon, width: 10, height: 10},
                        {text: feicao.title, colSpan: 2, alignment: 'left', style: 'info'},
                        {text: '', style: 'info'}
                    ]);
                }
            });
            const infoLegendaColumns: any = {
                columns: []
            }

            const feicoesEntrade = getFeicoesEntrada(this.feicoesRequerimento)
            const legendaFeicoesRequerimento = this.convertLegendaTreeToTable(this.exportCamadasToLegendaTree(feicoesEntrade));
            tableLagendaFeicoesRequerimento.table.body.push(...legendaFeicoesRequerimento);


            if (Array.isArray(feicoesEntrade) && feicoesEntrade.length > 0) {
                infoLegendaColumns.columns.push(tableLagendaFeicoesRequerimento)
            }

            if (Array.isArray(feicoesImoveis) && feicoesImoveis.length > 0) {
                infoLegendaColumns.columns.push(tableLegendaFeicoesImoveis)
            }

            content.push(infoLegendaColumns)

            content.push({
                text: 'QUADRO DE ÁREAS',
                alignment: 'left',
                style: 'info',
                margin: [10, 2, 10, 2]
            });

            let tableFeicoesRequerimento = {
                style: 'table',
                margin: [10, 2, 10, 2],
                table: {
                    alignment: 'center',
                    headerRows: 1,
                    widths: [],
                    body: []
                },
            };
            const geometriasRequerimento = getAllGeometriasMapa(this.feicoesRequerimento, 'feicoes-car');

            const addRowToTable = (table, row) => {
                table.table.body.push(row);
            };

            const transformArea = (area) => {
                return this.decimalPipe.transform(area, '1.4-4');
            };
            if (Array.isArray(geometriasRequerimento) && geometriasRequerimento.length > 0) {
                let addAnoDataEvento = false;

                geometriasRequerimento.forEach((geom: GeometriaMapa, index) => {
                    if (geom.feature) {
                        const transformedArea = transformArea(geom.extra.area);
                        let row: any = [
                            {text: `${geom.extra.feicaoDTO.descricao.toUpperCase()}`, style: 'info', alignment: 'left'},
                            {text: `${geom.extra.denominacao.toUpperCase()}`, style: 'info', alignment: 'left'},
                            {
                                text: geom.extra.area > 0 ? `${transformedArea} ha` : '-',
                                style: 'info',
                                alignment: 'center'
                            },
                            {text: this.getLabelCentroid(geom.extra), style: 'info', alignment: 'center'},
                        ];
                        if (geom.extra.idModeloFeicao === ModeloFeicao.UPA && geom.extra.modeloFeicaoDTO.UPA.ano) {
                            row.splice(1, 0, {
                                text: geom.extra.modeloFeicaoDTO.UPA.ano,
                                style: 'info',
                                alignment: 'center'
                            });
                            addAnoDataEvento = true;
                        } else if (geom.extra.idModeloFeicao === ModeloFeicao.TALHAO && geom.extra.modeloFeicaoDTO.modeloFeicaoTalhao.dataEvento) {
                            row.splice(1, 0, {
                                text: this.datePipe.transform(geom.extra.modeloFeicaoDTO.modeloFeicaoTalhao.dataEvento, 'dd/MM/yyyy HH:mm:ss'),
                                style: 'info',
                                alignment: 'center'
                            });
                            addAnoDataEvento = true;
                        } else {
                            row.push({text: '', style: 'info', alignment: 'center'});
                            row[3]['colSpan'] = 2
                        }

                        addRowToTable(tableFeicoesRequerimento, row);
                    }
                });

                if (tableFeicoesRequerimento.table.body.length > 0) {
                    const headerRow = [
                        {text: `Feição`, style: 'info', bold: true, alignment: 'left'},
                        {text: `Denominação`, style: 'info', bold: true, alignment: 'left'},
                        {text: `Área`, style: 'info', bold: true, alignment: 'center'},
                        {text: `Centroide`, style: 'info', bold: true, alignment: 'center'}
                    ];

                    if (addAnoDataEvento) {
                        headerRow.push({text: `Ano/Data do Evento`, style: 'info', bold: true, alignment: 'center'});
                        tableFeicoesRequerimento.table.widths = ['auto', 'auto', 'auto', 'auto', 'auto'];
                    } else {
                        headerRow[3]['colSpan'] = 2
                        headerRow.push({text: ``, style: 'info', bold: true, alignment: 'center'});
                        tableFeicoesRequerimento.table.widths = ['auto', 'auto', 'auto', 'auto', 'auto'];
                    }

                    tableFeicoesRequerimento.table.body.unshift(headerRow);
                }
            }

            let tableFeicoesImoveis = {
                style: 'table',
                margin: [10, 2, 10, 2],
                alignment: 'center',
                table: {
                    alignment: 'center',
                    headerRows: 1,
                    widths: ['auto', 'auto', 'auto', 'auto'],
                    body: [
                        [
                            {text: `Feição CAR`, style: 'info', bold: true, alignment: 'center'},
                            {text: `Área`, style: 'info', bold: true, alignment: 'center'},
                            {text: `Tipo`, style: 'info', bold: true, alignment: 'center'},
                            {text: `Número`, style: 'info', bold: true, alignment: 'center'}
                        ]
                    ],
                }
            };
            const tables: any[] = [];
            const infoTemplateColumns: any = {
                columns: [
                    tableFeicoesRequerimento
                ]
            }
            if (Array.isArray(this.templatesImovelMapa) && this.templatesImovelMapa.length > 0) {
                this.templatesImovelMapa.forEach((temp: any) => {
                    addRowToTable(tableFeicoesImoveis, [
                        {text: `Número CAR: ${temp.imovel.numeroCAR}`, colSpan: 4, alignment: 'center', style: 'info'},
                        {text: ''},
                        {text: ''},
                        {text: ''},
                    ]);

                    const areas = [
                        {label: 'CAR APP', value: temp.areaAPP},
                        {label: 'CAR ARL', value: temp.areaARL},
                        {label: 'CAR AVN', value: temp.areaAVN},
                        {label: 'CAR AUAS', value: temp.areaAUAS},
                        {label: 'ÁREA CONSOLIDADA', value: temp.areaAreaConsolidada},
                    ];
                    addRowToTable(tableFeicoesImoveis, [
                        {text: 'CAR ATP', style: 'info', alignment: 'left'},
                        {text: `${transformArea(Number(temp.imovel.area))} ha`, alignment: 'center', style: 'info'},
                        {text: '-', alignment: 'center', style: 'info'},
                        {text: '-', alignment: 'center', style: 'info'},
                    ]);

                    temp.listAAir.forEach((air: AAir) => {
                        addRowToTable(tableFeicoesImoveis, [
                            {text: `CAR AIR`, alignment: 'left', style: 'info'},
                            {text: `${transformArea(air.areaHa)} ha`, alignment: 'center', style: 'info'},
                            {text: air.tipo, alignment: 'center', style: 'info'},
                            {text: air.codigo, alignment: 'center', style: 'info'},
                        ]);
                    });

                    areas.forEach(area => {
                        if (area.value) {
                            addRowToTable(tableFeicoesImoveis, [
                                {text: area.label, style: 'info', alignment: 'left'},
                                {text: `${transformArea(area.value)} ha`, alignment: 'center', style: 'info'},
                                {text: '-', alignment: 'center', style: 'info'},
                                {text: '-', alignment: 'center', style: 'info'},
                            ]);
                        }
                    });

                });
                this.templatesImovelMapa.forEach((temp: any) => {
                    // Primeira tabela: CAR ATP e áreas
                    let tableFeicoesImoveis = {
                        style: 'table',
                        margin: [10, 2, 10, 2],
                        alignment: 'center',
                        table: {
                            alignment: 'center',
                            headerRows: 1,
                            widths: ['auto', 'auto'],
                            body: [
                                [
                                    {text: `Feição CAR`, style: 'info', bold: true, alignment: 'center'},
                                    {text: `Área`, style: 'info', bold: true, alignment: 'center'},
                                ]
                            ],
                        }
                    };

                    // Adicionar a linha CAR ATP
                    addRowToTable(tableFeicoesImoveis, [
                        {text: 'CAR ATP', style: 'info', alignment: 'left'},
                        {text: `${transformArea(Number(temp.imovel.area))} ha`, alignment: 'center', style: 'info'},
                    ]);

                    // Adicionar linhas das áreas
                    const areas = [
                        {label: 'CAR APP', value: temp.areaAPP},
                        {label: 'CAR ARL', value: temp.areaARL},
                        {label: 'CAR AVN', value: temp.areaAVN},
                        {label: 'CAR AUAS', value: temp.areaAUAS},
                        {label: 'ÁREA CONSOLIDADA', value: temp.areaAreaConsolidada},
                    ];

                    areas.forEach(area => {
                        if (area.value) {
                            addRowToTable(tableFeicoesImoveis, [
                                {text: area.label, style: 'info', alignment: 'left'},
                                {text: `${transformArea(area.value)} ha`, alignment: 'center', style: 'info'},
                            ]);
                        }
                    });

                    // Segunda tabela: temp.listAAir
                    let tableFeicoesCarAir = {
                        style: 'table',
                        margin: [10, 2, 10, 2],
                        alignment: 'center',
                        table: {
                            alignment: 'center',
                            headerRows: 1,
                            widths: ['auto', 'auto', 'auto', 'auto'],
                            body: [
                                [
                                    {text: `Feição CAR AIR`, style: 'info', bold: true, alignment: 'center'},
                                    {text: `Área`, style: 'info', bold: true, alignment: 'center'},
                                    {text: `Tipo`, style: 'info', bold: true, alignment: 'center'},
                                    {text: `Número`, style: 'info', bold: true, alignment: 'center'}
                                ]
                            ],
                        }
                    };

                    temp.listAAir.forEach((air: AAir) => {
                        addRowToTable(tableFeicoesCarAir, [
                            {text: air.identificacao, alignment: 'left', style: 'info'},
                            {text: `${transformArea(air.areaHa)} ha`, alignment: 'center', style: 'info'},
                            {text: air.tipo, alignment: 'center', style: 'info'},
                            {text: air.codigo, alignment: 'center', style: 'info'},
                        ]);
                    });
                    tables.push({
                        margin: [10, 2, 10, 2],
                        text: [
                            {text: 'Número CAR: ', fontSize: 10},
                            {text: temp.imovel.numeroCAR, bold: true, fontSize: 10},
                        ],
                    })
                    tables.push(tableFeicoesImoveis)
                    tables.push(tableFeicoesCarAir)
                });
                if (tables.length > 0) {
                    infoTemplateColumns.columns.push(tables)
                }
            }

            content.push(infoTemplateColumns)

            document.content.push(...content);

            this.loadingService.setValue(false);
            try {
                resolve(pdfMake.createPdf(document));
            } catch (e) {
                reject(e)
            }
        });
    }

    salvarPDFRelatorio(): void {
        this.processaPDFTemplate('salvar', 'Parecer SEMA', (pdf) => {
            pdf.getBlob(blob => this.arquivoGerado.emit({blob, nome: "Parecer Técnico - MAPA.pdf"}));
        });
    }

    geraPDFRelatorio(): void {
        this.processaPDFTemplate('gerar', 'B5', (pdf) => {
            pdf.download("Parecer Técnico - MAPA.pdf");
        });
    }

    private processaPDFTemplate(action: 'salvar' | 'gerar', size: string, onPDFGenerated: (pdf: any) => void): void {
        const subscription: Subscription = this.mapPrintService[`${action}PDFResult$`].pipe(
            takeUntil(this.unsubscribeAll)
        ).subscribe({
            next: (mapaBase64) => {
                this.geraPDF(mapaBase64)
                    .then(pdf => {
                        onPDFGenerated(pdf);
                        subscription.unsubscribe();
                    })
                    .catch(error => {
                        console.error("Erro ao gerar o PDF", error);
                        subscription.unsubscribe();
                    });
                this.loadingService.setValue(false);
            },
            error: err => {
                this.loadingService.setValue(false);
                subscription.unsubscribe();
            }
        });

        this.mapPrintService.print({
            id: 'principal',
            format: null,
            imageType: 'image/jpeg',
            margin: 0,
            pdf: false,
            orientation: 'landscape',
            quality: 1,
            size,
            title: 'report',
            scale: '100000'
        }, action);
    }


    desenhaRetanguloObjeto(estilo: Estilo, width: number = 15, height: number = 15): string {
        try {
            if (estilo.icone) {
                return estilo.icone;
            }

            const corPreenchimento = estilo.corPreenchimento || 'transparent';
            const espessuraBorda = estilo.espessuraBorda || 0;
            const cor = estilo.cor || 'transparent';
            const dasharray = estilo.tipoTracejado === 1 ? "1" : estilo.tipoTracejado === 2 ? "2" : '';

            const style = [
                `fill:${corPreenchimento}`,
                espessuraBorda !== 0 ? `stroke:${cor}` : '',
                espessuraBorda !== 0 ? `stroke-width:${espessuraBorda}` : '',
                dasharray ? `stroke-dasharray:${dasharray}` : ''
            ].filter(Boolean).join(';');

            return `<svg xmlns="http://www.w3.org/2000/svg"><rect width="${width}" height="${height}" x="0" y="0" style="${style}"/></svg>`;
        } catch (error) {
            console.error('Erro ao desenhar o retângulo:', error);
            return `<svg xmlns="http://www.w3.org/2000/svg"><rect width="${width}" height="${height}" x="0" y="0" fill="transparent"/></svg>`;
        }
    }

    /**
     * Valida se uma string SVG é válida.
     * @param {string} svg - A string SVG a ser validada.
     * @returns {boolean} - Retorna true se o SVG for válido, caso contrário, false.
     */
    validarSvg(svg: string): boolean {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
        const isValidSvg = !svgDoc.querySelector('parsererror');

        if (!isValidSvg) {
            console.error('SVG inválido gerado:', svg);
        }

        return isValidSvg;
    }

    /**
     * Converte um objeto de estilo do OpenLayers em um SVG.
     * @param {Style} style - O objeto de estilo a ser convertido.
     * @returns {string} - O SVG gerado.
     */
    styleToSvg(style: Style): string {
        const fill = style.getFill();
        const stroke = style.getStroke();
        const image = style.getImage();

        const width = 20;
        const height = 20;

        let corPreenchimento = 'transparent';
        let espessuraBorda = 0;
        let cor = 'transparent';
        let dasharray = '';

        if (fill) {
            corPreenchimento = fill.getColor() || 'transparent';
        }

        if (stroke) {
            cor = stroke.getColor() || 'transparent';
            espessuraBorda = stroke.getWidth() || 0;
            dasharray = stroke.getLineDash() ? stroke.getLineDash().join(' ') : '';
        }

        const styleString = [
            `fill:${corPreenchimento}`,
            espessuraBorda !== 0 ? `stroke:${cor}` : '',
            espessuraBorda !== 0 ? `stroke-width:${espessuraBorda}` : '',
            dasharray ? `stroke-dasharray:${dasharray}` : ''
        ].filter(Boolean).join(';');


        if (image instanceof Icon) {
            const iconImage = image.getImage();
            if (iconImage instanceof HTMLImageElement) {
                const src = iconImage.src;
                return decodeURIComponent(src.split(',')[1] || image.getSrc());
            }
        }
        let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${width}">`;
        svg += `<rect width="${width}" height="${height}" x="0" y="0" style="${styleString}"/>`;
        svg += `</svg>`;

        // Valida o SVG antes de retornar
        if (!this.validarSvg(svg)) {
            return null;
        }

        return svg;
    }

    /**
     * Remove a parte `data:image/svg+xml;utf8,` de uma string SVG, se presente.
     * @param {string} svgDataUrl - A string SVG que pode conter `data:image/svg+xml;utf8,`.
     * @returns {string} - A string SVG limpa, sem o prefixo `data:image/svg+xml;utf8,`.
     */
    limparSvgDataUrl(svgDataUrl: string): string {
        const prefixo = 'data:image/svg+xml;utf8,';
        if (svgDataUrl.startsWith(prefixo)) {
            return svgDataUrl.slice(prefixo.length);
        }
        return svgDataUrl;
    }

    convertCamadaToLegenda(camada: Camada): any {
        // Verifique se a camada é uma instância de GeometriaMapa e, se for, retorne null
        if (camada instanceof GeometriaMapa) {
            return null;
        }
        const svg = camada.style instanceof Style
            ? this.styleToSvg(camada.style)
            : (typeof camada.style === 'object' && camada.style)
                ? this.desenhaRetanguloObjeto(camada.style as Estilo)
                : null;
        console.log('convertCamadaToLegenda', svg)
        return {
            icon: this.limparSvgDataUrl(svg),
            title: camada.title.toUpperCase(),
            camadas: camada.camadas ? camada.camadas.map(this.convertCamadaToLegenda.bind(this)).filter(camada => camada !== null) : []
        };
    }

    exportCamadasToLegendaTree(camadas: Camada[]): any[] {
        return camadas.map(this.convertCamadaToLegenda.bind(this));
    }

    public getLabelCentroid(geometria: FeicaoRequerimentoLicenciamentoDto): string {
        let label = '';

        const isValidCentroid = (latitude: number | null | undefined, longitude: number | null | undefined): boolean => {
            return latitude !== null && latitude !== undefined && longitude !== null && longitude !== undefined;
        };

        if (geometria.tipoGeometria === 'Ponto') {
            const {latDMS, lonDMS} = convertLatLonToDMS(geometria.latitude, geometria.longitude);

            label = `${latDMS}, ${lonDMS}`;
        } else if (isValidCentroid(geometria.centroidLatidude, geometria.centroidLongitude) &&
            (geometria.tipoGeometria === 'Polígono' || geometria.tipoGeometria === 'Não identificada')) {
            const {latDMS, lonDMS} = convertLatLonToDMS(geometria.centroidLatidude, geometria.centroidLongitude);
            label = `${latDMS}, ${lonDMS}`;
        }

        return label;
    }

}