import {BaseModel} from 'app/main/shared/models/base.model';
import {Requerimento} from '../requerimentos/requerimento.model';
import {ItemTermoReferenciaRequerimento} from 'app/main/shared/models/item-termo-referencia-requerimento.model';
import {BaseEnum} from '../../shared/enums/base.enum';

export enum TipoDocumentoRequerimento {
    ITEM_TERMO_REFERENCIA = 'ITEM_TERMO_REFERENCIA',
    JUNTADA_PROCESSO = 'JUNTADA_PROCESSO',
    PROCESSO_LEGADO = 'PROCESSO_LEGADO',
    ANEXO_OBJETIVO = 'ANEXO_OBJETIVO',
}

export class DocumentoRequerimento extends BaseModel {
    constructor(
        public id?: number,
        public titulo?: string,
        public nomeArquivo?: string,
        public requerimento?: Requerimento,
        public itemTermoReferenciaRequerimento?: ItemTermoReferenciaRequerimento,
        public sigiloso?: boolean,
        public tipo?: string,
        public chave?: string,
        public dataUpload?: Date
    ) {
        super();
    }

    static tipos: BaseEnum[] = [
        {valor: TipoDocumentoRequerimento.ITEM_TERMO_REFERENCIA, descricao: 'Item termo referência'},
        {valor: TipoDocumentoRequerimento.JUNTADA_PROCESSO, descricao: 'Juntada processo'},
        {valor: TipoDocumentoRequerimento.PROCESSO_LEGADO, descricao: 'Processo legado'},
        {valor: TipoDocumentoRequerimento.ANEXO_OBJETIVO, descricao: 'Anexo objetivo'},
    ];

    static fromJson(json: any): DocumentoRequerimento {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoRequerimento(
            json.id,
            json.titulo,
            json.nomeArquivo,
            Requerimento.fromJson(json.requerimento),
            ItemTermoReferenciaRequerimento.fromJson(json.itemTermoReferenciaRequerimento),
            json.sigiloso,
            json.tipo,
            json.chave,
            json.dataUpload ? new Date(json.dataUpload) : null
        );
    }
}
