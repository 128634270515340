import {ModelUtil} from "../model-util";

export class MicrobaciaDisponibilidade {
    constructor(
        public mes?: string,
        public mesOrdem?: number,
        public vazaoQ95?: number,
        public vazaoQInc?: number,
        public vazaoUtilizada?: number
    ) {
    }

    static fromJson(json: any): MicrobaciaDisponibilidade {
        if (json === undefined || json === null) {
            return null;
        }

        return new MicrobaciaDisponibilidade(
            ModelUtil.getOrElseNull(json.mes),
            ModelUtil.getOrElseNull(json.mesOrdem),
            ModelUtil.getOrElseNull(json.vazaoQ95),
            ModelUtil.getOrElseNull(json.vazaoQInc),
            ModelUtil.getOrElseNull(json.vazaoUtilizada)
        );
    }

    static fromJsons(json: any): MicrobaciaDisponibilidade[] {
        const array: MicrobaciaDisponibilidade[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(item => array.push(MicrobaciaDisponibilidade.fromJson(item)));
            return array;
        }
    }
}