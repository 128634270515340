import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemplateDTO } from '../shared/template-dto.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ParecerRequerimentoConclusaoService } from '../shared/parecer-requerimento-conclusao.service';
import { SnackBarService } from 'app/main/shared/snack-bar/snack-bar.service';
import { TemplateService } from '../shared/template.service';
import { ParecerRequerimentoConclusaoUpdateTemplateDTO } from '../shared/parecer-req-conclusao-update-template-dto.mode';

@Component({
  selector: 'app-templates-emissao-titulos-dialog',
  templateUrl: './templates-emissao-titulos-dialog.component.html',
  styleUrls: ['./templates-emissao-titulos-dialog.component.scss']
})
export class TemplatesEmissaoTitulosDialogComponent implements OnInit {

    form: FormGroup;
    parecerTecnicoId: number;
    templates: Array<TemplateDTO>;
    templatesTitulo: Array<TemplateDTO>;
    templatesExtratoTitulo: Array<TemplateDTO>;
    templatesAtoAdministrativo: Array<TemplateDTO>;
    templatesExtratoAtosAdministrativos: Array<TemplateDTO>;
    template: TemplateDTO;
    requerimentoTitulacao: any;
    parecerRequerimentoConclusao: any;

    constructor(
        public dialogRef: MatDialogRef<TemplatesEmissaoTitulosDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,

        private parecerRequerimentoConlusaoService: ParecerRequerimentoConclusaoService,
        private formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        private templateService: TemplateService
    ) {
    }

    ngOnInit(): void {
        console.log("dados: ", this.data);
        this.template = this.data.template !== null ? this.data.template : null;
        this.requerimentoTitulacao = this.data !== null ? this.data.requerimentoTitulacao : null;
        this.parecerRequerimentoConclusao = this.data !== null ? this.data.emissaoTitulo.parecerRequerimentoConclusao : null; 

        this.form = this.formBuilder.group({
            templateId: [null],
            templatePublicacaoId: [null]
        });
        this.listarTemplates();
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    listarTemplates() {
        this.templateService.listAllTemplates().subscribe(result => {
            this.templates = result;
            this.carregaComboTemplates();
        })

    }

    salvarConclusao(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const parecerConclusao = new ParecerRequerimentoConclusaoUpdateTemplateDTO();
            parecerConclusao.idParecerRequeriemntoConclusao = this.parecerRequerimentoConclusao.id;
            parecerConclusao.idTemplate = this.form.controls.templateId.value;
            parecerConclusao.idTemplatePublicacao = this.form.controls.templatePublicacaoId.value;
            parecerConclusao.tipoTemplate = this.template.tipoTemplate;
            this.parecerRequerimentoConlusaoService.updateTemplateParecerRequerimentoConclusao(parecerConclusao)
                .subscribe(persisted => {
                    this.dialogRef.close(true);
                });
        } else {
            this.snackBarService.showAlert('Revise os campos do formulário da conclusão.');
        }

    }

    carregaComboTemplates() {
        this.inicializarListasTemplates();

        
        
        this.templates.forEach(template => {
            if(this.template.fluxoProcesso === 'OUTORGA') {
                if (this.requerimentoTitulacao.requerimento.tipoProcesso.tipo === 'OUTORGA'
                    && template.tipoTemplate === 'TITULO' && template.tipoTitulo !== 'OUTROS_ATOS'
                    && template.fluxoProcesso === 'OUTORGA') {
                    this.templatesTitulo.push(template);
                }
                if (template.tipoTemplate === 'EXTRATO' && template.tipoTitulo !== 'OUTROS_ATOS'
                    && template.fluxoProcesso === 'OUTORGA') {
                    this.templatesExtratoTitulo.push(template);
                }

                if (template.tipoTemplate === 'TITULO' && template.tipoTitulo === 'OUTROS_ATOS'
                    && template.fluxoProcesso === 'OUTORGA') {
                    this.templatesAtoAdministrativo.push(template);
                }
                if (template.tipoTemplate === 'EXTRATO' && template.tipoTitulo === 'OUTROS_ATOS'
                    && template.fluxoProcesso === 'OUTORGA') {
                    this.templatesExtratoAtosAdministrativos.push(template);
                }
            } else {
                if (this.requerimentoTitulacao.requerimento.tipoProcesso.tipo === 'OUTORGA'
                    && template.tipoTemplate === 'TITULO' && template.tipoTitulo !== 'OUTROS_ATOS'
                    && template.fluxoProcesso === 'LICENCIAMENTO') {
                    this.templatesTitulo.push(template);
                }
                if (template.tipoTemplate === 'EXTRATO' && template.tipoTitulo !== 'OUTROS_ATOS'
                    && template.fluxoProcesso === 'LICENCIAMENTO') {
                    this.templatesExtratoTitulo.push(template);
                }

                if (template.tipoTemplate === 'TITULO' && template.tipoTitulo === 'OUTROS_ATOS'
                    && template.fluxoProcesso === 'LICENCIAMENTO') {
                    this.templatesAtoAdministrativo.push(template);
                }
                if (template.tipoTemplate === 'EXTRATO' && template.tipoTitulo === 'OUTROS_ATOS'
                    && template.fluxoProcesso === 'LICENCIAMENTO') {
                    this.templatesExtratoAtosAdministrativos.push(template);
                }
            }
                
        });
    }

    inicializarListasTemplates() {
        this.templatesTitulo = new Array<TemplateDTO>();
        this.templatesExtratoTitulo = new Array<TemplateDTO>();
        this.templatesAtoAdministrativo = new Array<TemplateDTO>();
        this.templatesExtratoAtosAdministrativos = new Array<TemplateDTO>();
    }

}
