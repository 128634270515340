import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {RequerimentoService} from 'app/main/pages/requerimentos/requerimento.service';
import {ResponsaveisCorporativoService} from 'app/main/shared/services/responsaveis-corporativo.service';
import {MatDialog} from '@angular/material';
import {MatTableDataSource} from '@angular/material/table';
import {DialogHistoricoRtComponent} from './historico-rt/dialog-historico-rt.component';
import {DialogAlterarRtComponent} from './dialog-alterar-rt/dialog-alterar-rt.component';
import {ResponsavelTecnico} from '../../../../shared/models/responsavel-tecnico.model';
import {DocumentoRT} from '../../../../shared/models/documento-rt.model';
import {EnumTipoDocumentoRT} from '../../../../shared/models/enum-tipo-documento-rt.model';
import {FileValidationUtil} from '../../../../shared/util/file-validation-util';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-alteracao-rt',
    templateUrl: './aba-alteracao-rt.component.html',
    styleUrls: ['./aba-alteracao-rt.component.scss']
})
export class AbaAlteracaoRtComponent implements OnInit, OnChanges {
    @Input() requerimento: Requerimento;
    responsaveisTecnicosAnexados: ResponsavelTecnico[];
    dataSource = new MatTableDataSource<ResponsavelTecnico>([]);

    // bloco consulta
    columnsResponsaveis: string[] = ['nome', 'cpfcnpj', 'email', 'habilitado', 'acoes'];
    columnsConsulta: string[] = ['select', 'nome', 'cpfcnpj', 'email', 'formacao'];
    resultadoPesquisa: ResponsavelTecnico[] = [];
    ultimoValorConsultado: string; // Validar multiplas pesquisas com o mesmo valor
    maskInputConsulta = '000.000.000-009';
    inputConsulta = new FormControl('');

    // bloco anexar
    anexos: DocumentoRT[] = EnumTipoDocumentoRT.listarDocumentos();
    numeroART = new FormControl(null, Validators.required);
    isResponsavelLegal = new FormControl(false, Validators.required);
    fluxoAnexo: TipoProcesso | null = null;

    constructor(
        protected injector: Injector,
        protected requerimentoService: RequerimentoService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        protected responsavelTecnicoCorporativoService: ResponsaveisCorporativoService,
    ) {
    }

    ngOnInit(): void {
        this.inputConsulta.valueChanges.subscribe(() =>
            this.maskInputConsulta = (this.inputConsulta.value.length > 11) ? '00.000.000/0000-00' : '000.000.000-009');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['requerimento'] && changes['requerimento'].currentValue) {
            this.listarResponsaveisTecnicos();
        }
    }

    /**
     * Consulta listagem de pessoas no SCORP e configura a tabela resultado com os dados.
     * @param {string} parametroConsulta - Parametro para consulta, podendo ser NOME, CPF, CNPJ.
     */
    consultarPessoas(parametroConsulta: string): void {
        const inputConsultaValue = parametroConsulta.trim();
        // Valida se o valor consultado é diferente do ultimo consultado
        if (inputConsultaValue !== this.ultimoValorConsultado) {
            this.responsavelTecnicoCorporativoService.getRTbyCpfCnpj(inputConsultaValue).subscribe((result) => {
                this.ultimoValorConsultado = inputConsultaValue;
                let jaAdicionado = false;
                this.responsaveisTecnicosAnexados.forEach(rt => {
                        if (rt.pessoa.cpfCnpj === result.pessoa.cpfCnpj) {
                            this.snackBarService.showAlert('Responsável técnico já adicionado ao requerimento.');
                            jaAdicionado = true;
                        }
                    }
                );

                if (!jaAdicionado) {
                    this.resultadoPesquisa = [result];
                    this.anexos = EnumTipoDocumentoRT.listarDocumentos();
                    this.fluxoAnexo = TipoProcesso.CRIAR;
                }
            }, (e) => {
                this.snackBarService.showError('Erro ao consultar RT', e);
            });
        }
    }

    private listarResponsaveisTecnicos(): void {
        this.requerimentoService
            .listarResponsaveisTecnicos(this.requerimento.id).subscribe((content) => {
            this.responsaveisTecnicosAnexados = content;
        });
    }

    postResponsavelTecnico(): void {
        if (this.formRtIsValid()) {

            const formData = new FormData();
            formData.append('numeroART', this.numeroART.value.toString());
            formData.append('representanteLegal', this.isResponsavelLegal.value ? 'true' : 'false');
            formData.append('cpfCnpj', this.resultadoPesquisa[0].pessoa.cpfCnpj);
            this.anexos.forEach(anexo => {
                if (anexo.tipo === EnumTipoDocumentoRT.ART) {
                    formData.append('fileART', anexo.arquivo);
                    formData.append('sigiloART', anexo.sigilo ? 'true' : 'false');

                }
            });

            this.requerimentoService.postResponsavelTecnico(this.requerimento.id, formData).subscribe((result) => {
                this.limparFluxoAnexos();

                this.snackBarService.showSuccess('Responsável técnico adicionado ao requerimento');
            }, (error) => {
                this.snackBarService.showError('Ocorreu um erro ao adicionar o RT ao requerimento.', error);
            });
        }
    }

    putResponsavelTecnico(): void {

        if (this.formRtIsValid()) {
            const formData = new FormData();
            if (this.numeroART.dirty) {
                formData.append('numeroART', this.numeroART.value.toString());
            }

            this.anexos.forEach(anexo => {
                if (anexo.tipo === EnumTipoDocumentoRT.ART) {
                    if (anexo.arquivo) {
                        formData.append('fileART', anexo.arquivo);
                    }
                    formData.append('sigiloART', anexo.sigilo ? 'true' : 'false');
                }
            });

            this.requerimentoService.putResponsavelTecnico(this.requerimento.id, this.resultadoPesquisa[0].pessoa.cpfCnpj, formData).subscribe((result) => {
                this.limparFluxoAnexos();

                this.snackBarService.showSuccess('Responsável técnico atualizado');
            }, (error) => {
                this.snackBarService.showError('Ocorreu um erro ao atualizar o Responsável técnico.', error
                );
            });
        }

    }

    removerAnexo(index: number): void {
        const anexo = this.anexos[index];
        anexo.arquivo = null;
    }

    selecionarAnexo(file: File, index: number): void {
        if (FileValidationUtil.invalidSize(file, FileValidationUtil.size('5MB'))) {
            this.snackBarService.showAlert('Arquivo com tamanho inválido, o tamanho limite é 5MB.');
            document.getElementById(`input-arquivo-${index}`)['value'] = null;
            return;
        }
        const anexo = this.anexos[index];
        anexo.arquivo = file;
        anexo.nomeArquivo = file.name;
    }

    formRtIsValid(): boolean {
        let isValid = true;
        if (this.isTipoProcessoCriar()) {
            this.anexos.forEach(anexo => {
                if (!(anexo.arquivo instanceof File)) {
                    isValid = false;
                }
            });
        }
        if (!(this.resultadoPesquisa[0])) {
            isValid = false;
        }
        if (!this.numeroART.value) {
            isValid = false;
        }
        return isValid;
    }

    /**
     * Limpa todas as variáveis utilizadas no fluxo de adicionar o RT
     */
    limparFluxoAnexos(): void {
        this.inputConsulta.setValue('');
        this.resultadoPesquisa = [];
        this.ultimoValorConsultado = null;
        this.fluxoAnexo = null;
        this.anexos = [];
        this.isResponsavelLegal.setValue(false);
        this.listarResponsaveisTecnicos();
    }

    openHistoricoDialog(): void {

        this.requerimentoService.listHistoricoAlteracaoRt(this.requerimento.id).subscribe(result => {
                this.dialog.open(DialogHistoricoRtComponent, {
                    width: '1800px',
                    maxHeight: '600px',
                    data: {historico: result}
                });
            }, (e) => this.snackBarService.showError('Erro ao carregar histórico', e)
        );

    }

    alterarAssociacaoRt(rt: ResponsavelTecnico): void {
        const dialogRef = this.dialog.open(DialogAlterarRtComponent, {
            width: '1800px',
            maxHeight: '600px',
            data: {responsavelTecnico: rt}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.requerimentoService.postHistoricoAlteracaoRt(this.requerimento.id, result).subscribe(r => {
                    this.listarResponsaveisTecnicos();
                }, (e) => this.snackBarService.showError('Erro ao salvar historico RT', e));
            }
        });
    }

    public visualizarDocumento(documento: DocumentoRT): void {
        this.requerimentoService.downloadDocumentoResponsavelTecnico(this.requerimento.id, documento).subscribe((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    public downloadDocumento(documento: DocumentoRT): void {
        window.open(
            `${this.requerimentoService.urlResource}/${this.requerimento.id}/responsaveis-tecnicos/
            ${documento.responsavelTecnico.pessoa.cpfCnpj}/documentos/${EnumTipoDocumentoRT.toJson(documento.tipo)}`
        );
    }

    // Verificadores de ENUM para utilizar no HTML.
    isTipoProcessoCriar(): boolean {
        return this.fluxoAnexo === TipoProcesso.CRIAR;
    }

    isTipoProcessoEditar(): boolean {
        return this.fluxoAnexo === TipoProcesso.EDITAR;
    }

}

enum TipoProcesso {
    EDITAR,
    CRIAR
}
