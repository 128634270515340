import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {ParametroCondicionante} from '../../../../../shared/models/parametro-condicionante.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ParametroCondicionanteService extends BaseService<ParametroCondicionante> {
    constructor(protected injector: Injector) {
        super(
            '/api/parametro-condicionante',
            injector,
            ParametroCondicionante.prototype,
            ParametroCondicionante.fromJson
        );
    }

    getAllParamCond(): Observable<ParametroCondicionante[]> {
        const url = `${this.urlResource}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );

    }
}
