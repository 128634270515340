import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    EmpreendimentoInfraParcelSoloResCom
} from "../../../../../../../shared/models/empreendimento-infra-parcel-solo-res-com.model";
import {DominiosService} from "../../../../../../../shared/services/dominios.service";
import {
    EmpreendimentoInfraParcelSoloResComService
} from "../../../../../../../shared/services/empreendimento-infra-parcel-solo-res-com.service";
import {
    checkboxBooleanRequiredDirective
} from "../../../../../../../shared/directives/checkbox-boolean-required.directive";
import {DominioDto} from "../../../../../../../shared/models/dominio-dto.model";
import {BaseAtividadeEmpreendimentoComponent} from "../base-atividade-empreendimento.component";
import {numeroIgualSuperiorDirective} from "../../../../../../../shared/directives/numero-igual-superior.directive";
import {RequerimentoFormularioLicenciamentoService} from "../../../../requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service";
import {RequerimentoService} from "../../../../../requerimento.service";
import {SnackBarService, SnackBarType} from '../../../../../../../shared/snack-bar/snack-bar.service';

@Component({
    selector: 'app-aba-atividade-infraestrutura-parcel-solo-res-com',
    templateUrl: './infra-parcel-solo-res-com.component.html',
    styleUrls: ['./infra-parcel-solo-res-com.component.scss']
})
export class InfraParcelSoloResComComponent extends BaseAtividadeEmpreendimentoComponent implements OnInit {

    private readonly TIPO_PARCELAMENTO_SOLO: number = 2;
    private readonly TIPO_RESIDENCIAIS_COMERCIAIS: number = 1;

    tiposEmpreendimento: DominioDto[] = [];

    form: FormGroup;
    private empreendimento: EmpreendimentoInfraParcelSoloResCom;

    isNaoPodeEditar: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private empreendimentoInfraParcelSoloResComService: EmpreendimentoInfraParcelSoloResComService,
        private dominiosService: DominiosService,
        private requerimentoFormularioLicenciamentoService: RequerimentoFormularioLicenciamentoService,
        private requerimentoService: RequerimentoService,
        private snackBarService: SnackBarService,
    ) {
        super();
        this.form = this.formBuilder.group({
            tipoEmpreendimento: [{value: null, disabled: this.isModoVisualizacao}, [Validators.required]],
        });
    }

    async ngOnInit() {
        this.tiposEmpreendimento = await this.dominiosService.getDominioByUrl('tipo-empreendimento-parcel-solo-res-com').toPromise();

        if (this.requerimento.id !== undefined) {
            let requerimentoLicenciamentoVinculado;
            if (this.requerimento.numeroProtocoloVinculado && this.requerimento.desabilitarAtividades) {
                await this.requerimentoService.getRequerimento(this.requerimento.numeroProtocoloVinculado).toPromise().then(async resposta => {
                    let formularioReqLicenciamentoVinculadoDto = await this.requerimentoFormularioLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(resposta[0].id).toPromise()
                    requerimentoLicenciamentoVinculado = formularioReqLicenciamentoVinculadoDto.requerimentoLicenciamento
                })
                await this.carregarInfraParcelSoloResCom(requerimentoLicenciamentoVinculado.requerimento.id)
            } else {
                await this.carregarInfraParcelSoloResCom(this.requerimento.id)
            }
        }
        if (this.isModoVisualizacao || (this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoRenovar
            || this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoReabilitar)) {
                this.isNaoPodeEditar = true;
                this.form.disable();
        }
    }

    async carregarInfraParcelSoloResCom(idRequerimento){
        let res = await this.empreendimentoInfraParcelSoloResComService.getByRequerimento(idRequerimento).toPromise()
        .then((res) => {
            this.empreendimento = res ? res : {} as any;
            if (this.empreendimento ) {
                this.atualizaTipoEmpreendimento();
                this.form.patchValue(this.empreendimento);
            }
        })
        .catch((e)=> {
            this.empreendimento  = {} as any;
        });
        
    }

    async salva() {
        this.form.markAllAsTouched();

        if (this.form.valid) {
            let res = EmpreendimentoInfraParcelSoloResCom.fromJson(this.form.value);
            res.requerimento = this.requerimento.id;
            res.id = this.requerimento.id;
            if (this.isTipoParcelamentoSolo()) {
                res.parcelamentoSolo.id = this.empreendimento.parcelamentoSolo ? this.empreendimento.parcelamentoSolo.id : null;
            } else {
                res.residenciaisComerciais.id = this.empreendimento.residenciaisComerciais ? this.empreendimento.residenciaisComerciais.id : null;
            }

            if (this.empreendimento.id === undefined) {
                this.empreendimentoInfraParcelSoloResComService.create(res).subscribe(response => {
                    this.enviarFormConcluido.emit();
                    this.snackBarService.show('formulário de Atividades salvo com sucesso.', SnackBarType.SUCCESS);
                });
            } else {
                this.empreendimentoInfraParcelSoloResComService.update(res).subscribe(response => {
                    this.enviarFormConcluido.emit();
                    this.snackBarService.show('formulário de Atividades salvo com sucesso.', SnackBarType.SUCCESS);
                });
            }
        } else {
            this.snackBarService.show('O formulário de Atividades: possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).', SnackBarType.ERROR);
        }

    }

    isTipoParcelamentoSolo() {
        return this.getTipoEmpreendimento() === this.TIPO_PARCELAMENTO_SOLO;
    }

    isTipoResidenciaisComerciais() {

        return this.getTipoEmpreendimento() === this.TIPO_RESIDENCIAIS_COMERCIAIS;
    }

    getTipoEmpreendimento() {
        let tipo;
        if (this.form) {
            tipo = this.form.get('tipoEmpreendimento').value;
        }

        if (!tipo) {
            if (this.empreendimento) {
                tipo = this.empreendimento.tipoEmpreendimento
            }
        }

        return tipo;
    }

    atualizaTipoEmpreendimento() {
        this.atualizaParcelamentoSolo();
        this.atualizaResidenciaisComerciais();
    }

    atualizaParcelamentoSolo() {
        if (this.isTipoParcelamentoSolo()) {
            this.form.addControl('parcelamentoSolo', this.formBuilder.group({
                residencial: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'parcelamentoSolo', 'comercial', 'industrial', 'educacional')]],
                comercial: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'parcelamentoSolo', 'residencial', 'industrial', 'educacional')]],
                industrial: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'parcelamentoSolo', 'comercial', 'residencial',  'educacional')]],
                educacional: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'parcelamentoSolo', 'comercial', 'residencial', 'industrial')]],
                numeroLotes: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required, Validators.maxLength(20)]],
                numeroLotesEquipamentoPublico: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required, Validators.maxLength(20), numeroIgualSuperiorDirective(this.form, 'parcelamentoSolo', 'numeroLotes')]],
                numeroHabitantesOcupantes: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required, Validators.maxLength(20)]],
            }));
        } else {
            this.form.removeControl('parcelamentoSolo');
        }
    }

    atualizaResidenciaisComerciais() {
        if (this.isTipoResidenciaisComerciais()) {
            this.form.addControl('residenciaisComerciais', this.formBuilder.group({
                residencial: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'residenciaisComerciais', 'comercial', 'industrial')]],
                comercial: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'residenciaisComerciais', 'residencial', 'industrial')]],
                industrial: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'residenciaisComerciais', 'comercial', 'residencial')]],
                vertical: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'residenciaisComerciais', 'horizontal')]],
                horizontal: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [checkboxBooleanRequiredDirective(this.form, 'residenciaisComerciais', 'vertical')]],
                numeroUnidadesResidenciais: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required, Validators.maxLength(20)]],
                numeroUnidadesComerciais: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required, Validators.maxLength(20)]],
                numeroUnidadesIndustriais: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required, Validators.maxLength(20)]],
                numeroLotesEquipamentoPublico: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required, Validators.maxLength(20)]],
                numeroHabitantesOcupantes: [{
                    value: null,
                    disabled: this.isModoVisualizacao
                }, [Validators.required, Validators.maxLength(20)]],
            }));
        } else {
            this.form.removeControl('residenciaisComerciais');
        }
    }
}
