import {BaseModel} from "./base.model";
import {EmpreendimentoEeHidrica} from "./empreendimento-ee-hidrica.model";

export class Reservatorio extends BaseModel {
    constructor(
        public id?: number,
        public empreendimentoEeHidrica?: EmpreendimentoEeHidrica,
        public identificacao?: string,
        public volumeMax?: number,
        public volumeMin?: number,
        public volumeUtil?: number,
        public areaMax?: number,
        public areaMaximorum?: number,
        public areaMin?: number,
        public reservatorioVidaMaxOperativo?: number,
        public reservatorioVidaUtil?: number,
    ) {
        super();
    }

    static fromJson(json: any): Reservatorio {
        if (json === undefined || json === null) {
            return null;
        }
        return new Reservatorio(
            json.id,
            EmpreendimentoEeHidrica.fromJson(json.empreendimentoEeHidrica),
            json.identificacao,
            json.volumeMax,
            json.volumeMin,
            json.volumeUtil,
            json.areaMax,
            json.areaMaximorum,
            json.areaMin,
            json.reservatorioVidaMaxOperativo,
            json.reservatorioVidaUtil,
        );
    }

    static fromJsons(json: any): Reservatorio[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(j => resources.push(Reservatorio.fromJson(j)));
            return resources;
        }
    }
}