import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AssinadorDigitalService} from '../../services/assinador-digital.service';
import {CertificadoDigitalDTO} from '../../models/certificado-digital-dto.model';
import {SnackBarService} from '../../snack-bar/snack-bar.service';
import {ParametrosControleAssinaturaDTO} from '../../models/parametros-controle-assinatura-dto.model';
import {SignatarioDTO} from '../../models/signatario-dto.model';
import {EmissaoTituloSignatarioService} from '../../../pages/tarefas/shared/service/emissao-titulo-signatario.service';
import {EmissaoTitulo} from '../../models/emissao-titulo.model';
import {BaseModel} from '../../models/base.model';
import {BaseEnum} from '../../enums/base.enum';
import {FormControl} from '@angular/forms';

export interface AssinarDocumentoData {
    documento: Blob;
    emissao: EmissaoTitulo;
}

@Component({
    selector: 'app-dialog-assinar-titulo.component',
    templateUrl: './assinar-documento-dialog.component.html'
})
export class AssinarDocumentoDialogComponent implements OnInit {

    certificados: CertificadoDigitalDTO[];
    certificado: CertificadoDigitalDTO;
    parametrosControleAssinatura: ParametrosControleAssinaturaDTO;
    formTipoCert: FormControl;

    constructor(
        public dialogRef: MatDialogRef<AssinarDocumentoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AssinarDocumentoData,
        protected snackBarService: SnackBarService,
        private emissaoTituloSignatarioService: EmissaoTituloSignatarioService,
        private assinadorDigitalService: AssinadorDigitalService) {
    }

    comparador = (a: BaseModel, b: BaseModel) =>
        a && b ? a.id === b.id : a === b

    async ngOnInit(): Promise<void> {
        this.formTipoCert = new FormControl();
        this.formTipoCert.valueChanges.subscribe(value => this.carregarListaCertificadosDigitais(value));
        this.formTipoCert.setValue('TODOS');
        this.parametrosControleAssinatura = await this.emissaoTituloSignatarioService
            .getByParametrosControleAssinatura(this.data.emissao.id).toPromise();
    }

    cancelar(): void {
        this.dialogRef.close(false);
    }

    private continuar(data: Blob): void {
        this.dialogRef.close(data);
    }

    async assinar(): Promise<void> {
        if (!this.certificado || !this.certificado.aliasKey) {
            this.snackBarService.showAlert('O certificado digital é obrigatório.');
            return;
        }
        if (!this.data || !this.data.documento) {
            this.snackBarService.showAlert('O documento é obrigatório.');
            return;
        }

        this.certificado.signaturePosition = this.parametrosControleAssinatura.qtdAssinaturasRealizadas;
        this.certificado.signatario = new SignatarioDTO(this.parametrosControleAssinatura.signatario.signatario.cpf,
            this.parametrosControleAssinatura.signatario.signatario.nome, this.parametrosControleAssinatura.signatario.signatario.cargoESetor);

        await this.assinadorDigitalService.signDocument(this.certificado, this.data.documento).subscribe((data) => {
            this.continuar(data);
        }, (e) => {
            this.snackBarService.showError('Não foi possível assinar o documento.', e);
        });
    }

    get tiposCertificadoDigital(): BaseEnum[] {
        return CertificadoDigitalDTO.tiposCertificadoDigital;
    }

    carregarListaCertificadosDigitais(tipoCert: string): void {
        this.assinadorDigitalService.getAllCertificates(tipoCert).subscribe(certificados => {
            this.certificados = certificados;
        }, e => {
            this.snackBarService.showError('Não foi possivel verificar os certificados do seu assinador.');
            this.dialogRef.close(false);
        });
    }

}
