export class CriterioDTO {

    constructor(
        public sigiloso?: boolean,
        public criterio?: number,
        public arquivo?: File,
        public nomeArquivo?: string
    ) {
    }

}