import {
    Component,
    ComponentFactoryResolver,
    Injector,
    Input,
    OnInit,
    QueryList,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { IMESSAGE } from 'app/main/core/services/core-message.service';
import { FormularioReqLicenciamentoEmp } from 'app/main/shared/models/formulario-req-licenciamento-emp.dto';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentoProcesso } from '../../../../shared/models/documento-processo.model';
import { LancamentoTaxaDto } from '../../../../shared/models/lancamento-taxa-dto.model';
import { AguaPassivoSupressaoService } from '../../../../shared/services/agua-passivo-supressao.service';
import { AtividadeArrecadacaoService } from '../../../../shared/services/arrecadacao-service/atividade-arrecadacao.service';
import { AtividadeMineracaoService } from '../../../../shared/services/atividade-mineracao.service';
import { DominiosService } from '../../../../shared/services/dominios.service';
import { EeLinhaTransmissaoService } from '../../../../shared/services/ee-linha-transmissao.service';
import { EmpreendimentoCemiterioCrematorioService } from '../../../../shared/services/empreendimento-cemiterio-crematorio.service';
import { EmpreendimentoEnergeticoService } from '../../../../shared/services/empreendimento-energetico.service';
import { EmpreendimentoEnergiaFotovoltaicaService } from '../../../../shared/services/empreendimento-energia-fotovoltaica.service';
import { EmpreendimentoGerenciamentoResiduosService } from '../../../../shared/services/empreendimento-gerenciamento-residuos.service';
import { EmpreendimentoInfraParcelSoloResComService } from '../../../../shared/services/empreendimento-infra-parcel-solo-res-com.service';
import { EmpreendimentoService } from '../../../../shared/services/empreendimento.service';
import { IndustriaBiocombustivelService } from '../../../../shared/services/industria-biocombustivel.service';
import { IndustriaFrigorificoService } from '../../../../shared/services/industria-frigorifico.service';
import { InfraObraFluvSaneamService } from '../../../../shared/services/infra-obra-fluv-saneam.service';
import { AtividadeParametroLicenciamentoService } from '../../../../shared/services/licenciamento-config-service/atividade-parametro-licenciamento.service';
import { MadeiraService } from '../../../../shared/services/madeira.service';
import { ObjetivoLicenciamentoSubatividadeService } from '../../../../shared/services/objetivo-licenciamento-subatividade.service';
import { ObjetivoLicenciamentoService } from '../../../../shared/services/objetivo-licenciamento.service';
import { ResiduoAterroIndUrbService } from '../../../../shared/services/residuo-aterro-ind-urb.service';
import { ResiduoRccService } from '../../../../shared/services/residuo-rcc.service';
import { ResiduoTratamentoTermicoService } from '../../../../shared/services/residuo-tratamento-termico.service';
import { ServicoCombustivelService } from '../../../../shared/services/servico-combustivel.service';
import { ServicoGeralService } from '../../../../shared/services/servico-geral.service';
import { SistemaTratamentoService } from '../../../../shared/services/sistema-tratamento.service';
import { SnackBarService } from '../../../../shared/snack-bar/snack-bar.service';
import { DocumentoProcessoService } from '../../../gerar-documento-publicacao/documento-processo.service';
import { EmissaoTituloService } from '../../../tarefas/shared/service/emissao-titulo.service';
import { ReqTituloVinculadoService } from '../../../tarefas/shared/service/req-titulo-vinculado.service';
import { TipoProcessoEnum } from '../../../tipos-processo/tipo-processo.enum';
import { EnumInstanciaRequerimento } from "../../menu-novo-requerimento/instancia-requerimento.enum";
import { RequerimentoFormularioLicenciamentoService } from '../../requerimento-licenciamento/requerimento-formulario-licenciamento/requerimento-formulario-licenciamento.service';
import { RequerimentoLicenciamentoService } from '../../requerimento-licenciamento/requerimento-licenciamento.service';
import { AcaiService } from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/acai/acai.service";
import { EeHidricaService } from '../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-hidrica/ee-hidrica.service';
import { EmpreendimentoEnergeticoTermicaService } from '../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/ee-termica/empreendimento-energetico-termica.service';
import { InfraestruturaObrasViariasService } from '../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/infraestrutura-obras-viarias/infraestrutura-obras-viarias.service';
import { ManejoFlorestalService } from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/manejo-florestal/manejo-florestal.service";
import { QueimaControladaService } from "../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/queima-controlada/queima-controlada.service";
import { ReflorestamentoSeminventarioService } from '../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/reflorestamento-seminventario/reflorestamento-seminventario.service';
import { ResiduoGeralService } from '../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-geral/residuo-geral.service';
import { ResiduosTransporteProdutosPerigososService } from '../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/residuos-transporte-produtos-perigosos/residuos-transporte-produtos-perigosos.service';
import { SupressaoVegetalService } from '../../requerimento-licenciamento/tipos-licenciamento/licenciamento-empreendimentos/atividades/supressao-vegetal/supressao-vegetal.service';
import { RequerimentoService } from '../../requerimento.service';
import { BaseTabRequerimentoComponent } from '../base-requerimento.component';
import { ErrosAbaRequerimento } from '../requerimento-validacao-aba-dialog/requerimento-validacao-aba-dialog.component';
import { RequerimentoSteps } from '../requerimento.steps';
import { RequerimentoLicenciamentoAtividadeFormulario } from './requerimento-licenciamento-atividade-formulario';

@Component({
    selector: 'app-aba-atividades-lic',
    templateUrl: './aba-atividades-lic.component.html',
    styleUrls: ['./aba-atividades-lic.component.scss']
})
export class AbaAtividadesLicComponent extends BaseTabRequerimentoComponent implements OnInit {

    formsConcluidos: any[] = [];
    requerimentoEmpreendimentoAtividade = [];
    formularioReqLicenciamentoVinculadoDto: FormularioReqLicenciamentoEmp;

    objetivoSubAtividade: any;
    atividadeComplementar: any;
    subAtividade: any;
    lancamentoTaxa: LancamentoTaxaDto;
    lancamentoTaxaParam: any[] = [];
    feicaoReqLicenciamento: any[] = [];
    geometrias: any = [];
    upas: any
    objLicenciamento: any;
    dinamicaDesmate: any
    formulariosConcluido = false
    quantidadeFormulariosConcluidos = []
    formularios = 0
    motivos: any
    motivoSelecionado: any;
    motivoSelect: any
    errosDeRegras = []
    statusExibicaoAtv: boolean;

    inventarioImportado: DocumentoProcesso;

    @ViewChildren('formularioContainer', {read: ViewContainerRef}) formularioContainer: QueryList<ViewContainerRef>;

    @Input()
    public isSomenteLeitura: boolean = false;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                injector: Injector,
                private atividadeParamLicenciamentoService: AtividadeParametroLicenciamentoService,
                private requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
                private objetivoLicenciamentoSubatividadeService: ObjetivoLicenciamentoSubatividadeService,
                private arrecadacaoService: AtividadeArrecadacaoService,
                private mineracaoService: AtividadeMineracaoService,
                private residuoAterroIndUrbService: ResiduoAterroIndUrbService,
                private industriaFrigorificoService: IndustriaFrigorificoService,
                private empreendimentoCemiterioCrematorioService: EmpreendimentoCemiterioCrematorioService,
                private servicoCombustivelService: ServicoCombustivelService,
                private industriaBiocombustivelService: IndustriaBiocombustivelService,
                private residuosRccService: ResiduoRccService,
                private empreendimentoGerenciamentoResiduosService: EmpreendimentoGerenciamentoResiduosService,
                private residuoTratamentoTermicoService: ResiduoTratamentoTermicoService,
                private transporteProdutosPerigososService: ResiduosTransporteProdutosPerigososService,
                private residuoGeralService: ResiduoGeralService,
                private aguaPassivoSupressaoService: AguaPassivoSupressaoService,
                private sistemaTratamentoService: SistemaTratamentoService,
                private madeiraService: MadeiraService,
                private empreendimentoInfraParcelSoloResComService: EmpreendimentoInfraParcelSoloResComService,
                private infraObrasViariasService: InfraestruturaObrasViariasService,
                private eeHidricaService: EeHidricaService,
                private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService,
                private empreendimentoEnergiaFotovoltaicaService: EmpreendimentoEnergiaFotovoltaicaService,
                private eeLinhaTransmissaoService: EeLinhaTransmissaoService,
                private empreendimentoSubestacaoService: EmpreendimentoService,
                private empreendimentoEnergiaEolicaService: EmpreendimentoEnergeticoService,
                private infraObraFluvSaneamService: InfraObraFluvSaneamService,
                private requerimentoFormLicenciamentoService: RequerimentoFormularioLicenciamentoService,
                private servicoGeralService: ServicoGeralService,
                private supressaoVegetalService: SupressaoVegetalService,
                private acaiService: AcaiService,
                private manejoFlorestalService: ManejoFlorestalService,
                private dominioService: DominiosService,
                private snackBarService: SnackBarService,
                private reqTituloVinculadoService: ReqTituloVinculadoService,
                private documentoProcessoService: DocumentoProcessoService,
                private emissaoTituloService: EmissaoTituloService,
                private queimaControladaService: QueimaControladaService,
                private requerimentoService: RequerimentoService,
                private objetivoLicenciamentoService: ObjetivoLicenciamentoService,
                private reflorestamentoSeminventarioService: ReflorestamentoSeminventarioService,
    ) {
        super(injector);
        RequerimentoSteps.ATIVIDADE.component = this;
    }

    async ngOnInit() {
        if (this.requerimento.id) {
            // await this.buscaReqLicenciamentoEmAndamento();
            this.requerimentoLicenciamento = await this.requerimentoLicenciamentoService.getRequerimentoLicenciamentoByRequerimentoId(this.requerimento.id).toPromise();
            await this.carregarFormulariosAtividades();
            //TODO aguardando desenvolvimento da API de inventario
            // this.carregarInventarioSalvo();

            if (this.requerimento.tipoProcesso.id === 3) {
                await this.buscarFeicaoReqLicenciamento();
            }

        }
        this.helper
            .onCurrentStepChange()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(e => {
                if (e.newTabIndex === RequerimentoSteps.ATIVIDADE.index) {
                    forkJoin([this.carregarFormulariosAtividades(),
                        this.carregarAtividades(), this.buscarFormularioRequerimentoLicenciamentoEmp()]).toPromise();
                }
            });
    }


    isSomenteVisualizacaoEspecifico(): boolean {
        return this.requerimento && this.requerimento.tipoProcesso.id == TipoProcessoEnum.LICENCIAMENTO_TRIFASICO_LAS
            && (this.requerimento.tipoRequerimento.isFluxoTransferir || this.requerimento.tipoRequerimento.isFluxoRenovar
                || this.requerimento.tipoRequerimento.isFluxoCancelar || this.requerimento.tipoRequerimento.isFluxoReabilitar);
    }

    // carregarMotivos() {
    //     this.dominioService.getDominio('MOTIVO_AUTORIZACAO_QUEIMADA').subscribe(async mot => {
    //         this.motivos = mot
    //         await this.carregarMotivoSelecionado()
    //     })
    // }

    private async buscarFormularioRequerimentoLicenciamentoEmp() {
        this.requerimento.numeroProtocoloVinculado && this.requerimentoLicenciamentoVinculado.requerimento &&
        this.requerimento.tipoProcesso.instanciaRequerimento == EnumInstanciaRequerimento.TERMO_REFERENCIA ?
        await this.requerimentoFormLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoId(this.requerimentoLicenciamentoVinculado.requerimento.id).toPromise()
            .then(async formularioReqLicenciamentoDto => {
                if (formularioReqLicenciamentoDto) {
                    if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                        this.lancamentoTaxaParam = formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList;
                        const key = 'labelFormulario';
                        this.lancamentoTaxaParam = [...new Map(this.lancamentoTaxaParam.map(item =>
                            [item[key], item])).values()]
                    }
                }
            })
        : await this.requerimentoFormLicenciamentoService.getByRequerimentoLicenciamentoRequerimentoEmpId(this.requerimento.id).toPromise()
            .then(async formularioReqLicenciamentoDto => {
                if (formularioReqLicenciamentoDto) {
                    if (formularioReqLicenciamentoDto.requerimentoLicenciamento) {
                        this.lancamentoTaxaParam = formularioReqLicenciamentoDto.lancamentoTaxaParamCalculoList;
                        const key = 'labelFormulario';
                        this.lancamentoTaxaParam = [...new Map(this.lancamentoTaxaParam.map(item =>
                            [item[key], item])).values()]
                    }
                }
            });
    }

    stringParametroCalculo(parametros: any) {

        let novoArray = []
        for (let param of parametros) {
            let newObject = `${param.labelFormulario} : ${param.valor}`
            novoArray.push(newObject)
        }
        return novoArray.join(', ')
    }

    async carregarAtividades() {
        await this.objetivoLicenciamentoSubatividadeService.getRequerimentoLicenciamentoPorId(this.requerimento.id).toPromise()
            .then(objetivo => {
                this.objetivoSubAtividade = objetivo;
            })

        if (this.objetivoSubAtividade) {
            await this.arrecadacaoService.getAtividadeId(this.objetivoSubAtividade.idAtividadeComplementar).toPromise()
                .then(atividadeComplementar => {
                    this.atividadeComplementar = atividadeComplementar;
                })
        }

        await this.arrecadacaoService.getAtividadeId(this.requerimentoLicenciamento.idAtividade).toPromise()
            .then(subAtividade => {
                this.subAtividade = subAtividade;
            })
    }

    loadFinalidadeContainer(requerimentoEmpreendimentoAtividade: any, index: number, skipReload: boolean = false): void {
        const formularioContainer = this.formularioContainer.find((e, i, a) => i === index);
        formularioContainer.clear();
        const type = RequerimentoLicenciamentoAtividadeFormulario.get(requerimentoEmpreendimentoAtividade).resolved;
        const factory = this.componentFactoryResolver.resolveComponentFactory(type);
        const ref = formularioContainer.createComponent(factory);
        ref.instance.requerimento = this.requerimentoLicenciamento && this.requerimentoLicenciamento.requerimento ? this.requerimentoLicenciamento.requerimento : this.requerimento;
        ref.instance.isModoVisualizacao = this.isSomenteVisualizacao || this.isSomenteLeitura;
        if (this.desabilitaEdicaoAba5 || this.regrasSuimis == "suimisFluxoAlteracaoComProtocoloLp" ||
            this.regrasSuimis == "suimisFluxoAlteracaoComProtocoloLopm") {
            ref.instance.isModoVisualizacao = true
            ref.instance.requerimento.desabilitarAtividades = true
        }

        //Os formulários de atividade devem possuir essa instancia para que quando o formulário seja salvo,
        // o componente marque o mesmo como concluido.
        requerimentoEmpreendimentoAtividade.instance = ref.instance;
        ref.instance.enviarFormConcluido.subscribe((value) => {
            this.recarregarForms();
        })

        if (!skipReload)
        this.recarregarForms();
    }

    private async carregarFormulariosAtividades() {
        this.requerimentoEmpreendimentoAtividade = [];
        let obs: Promise<any>[] = [];

        if (this.requerimento.numeroProtocoloVinculado && this.requerimentoLicenciamentoVinculado.requerimento){
            obs.push(this.atividadeParamLicenciamentoService.buscarFormulariosPorIdAtividade(this.requerimentoLicenciamentoVinculado.idAtividade, this.requerimentoLicenciamento.id).toPromise())
        }else{
            obs.push(this.atividadeParamLicenciamentoService.buscarFormulariosPorIdAtividade(this.requerimentoLicenciamento.idAtividade, this.requerimentoLicenciamento.id).toPromise());
        }

        await Promise.all(obs).then((responses)=> {
            console.debug('Atividade formularios carregados', responses );
            responses.forEach((response) => { 
                let filtered: any[] = response.filter(requerimentoFormularioAtividade => RequerimentoLicenciamentoAtividadeFormulario.get(requerimentoFormularioAtividade));
                if (filtered && filtered.length > 0) {
                    filtered.forEach((at,index) => { at.formIndex = index; this.requerimentoEmpreendimentoAtividade.push(at)} );
                }
            });  
        });

        // remove dup
        this.requerimentoEmpreendimentoAtividade = this.requerimentoEmpreendimentoAtividade.filter((item, index, self) => self.indexOf(item) === index);
        this.formsConcluidos = [];
        this.requerimentoEmpreendimentoAtividade.forEach(async form => { 
            this.formsConcluidos.push({
                idForm: form.idFormulario,
                formConcluido: false,
                formularioDesc: form.constante,
                nomeForm: form.descricao
            }) 
        })

        this.requerimentoEmpreendimentoAtividade.forEach(async form => {
            await this.verificarPreenchimentoFormularios(form.constante);
        })
    }



    receberFormConcluido(detalheForm: any) {
        const objIndex = this.formsConcluidos.findIndex((obj => obj.formularioDesc == detalheForm.formularioDesc));
        if (detalheForm) {
            detalheForm.id = `${detalheForm.formularioDesc}-${detalheForm.idForm}`;
            if (this.requerimentoEmpreendimentoAtividade)
                this.requerimentoEmpreendimentoAtividade.forEach((form) => 
                        this.loadFinalidadeContainer(form,  form.formIndex, true)
                );
        }
        

        if (objIndex >= 0) {
            this.formsConcluidos[objIndex] = detalheForm;
        } else {
            this.formsConcluidos.push(detalheForm);
        }
    }

    // checkFormSituacao(id: any, formDesc: string) {
    //     console.debug('checkFormSituacao0.formConcluidos', this.formsConcluidos);
    //     console.debug('checkFormSituacao1', id, formDesc);
    //     let form =  this.formsConcluidos.find((f) => f.idForm == id && f.formularioDesc == formDesc);
    //     if (form)
    //         console.debug('checkFormSituacao2', id, formDesc, form.formConcluido);

    //     return form && form.formConcluido;
    // }

    async validarAba(erros: ErrosAbaRequerimento, restrict: boolean = false): Promise<void | any> {
        this.formsConcluidos.forEach(form => {
            if (form.formConcluido == false) {
                erros.push('O formulário ' + form.nomeForm + ': possui campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            }
        });
        if (this.requerimento.tipoProcesso.id === 3 && this.requerimentoLicenciamento.idAtividade === 4166) {
            for (let erro of this.errosDeRegras) {
                erros.push(erro, false)
            }
        }

        if (this.requerimento.tipoProcesso.id === 3 && !this.formulariosConcluido && this.statusExibicaoAtv) {
            erros.push('Preencha todos os formulários')
        }

        await this.validarRegrasAbaFormulario(erros, restrict);

        if (erros.length === 0) {
            return Promise.resolve();
        } else {
            return Promise.resolve(erros);
        }
    }


    async validarRegrasAbaFormulario(erros: ErrosAbaRequerimento, restrict: boolean = false) {
        let errors:IMESSAGE[] = []; // nao restritivas | alerta
        for (let index = 0; index < this.requerimentoEmpreendimentoAtividade.length; index++) {
            const form = this.requerimentoEmpreendimentoAtividade[index];
            console.log('form: ', form);
            if (form.instance['validar']) {
                let errosForm = await form.instance.validar(restrict);
                if (errosForm && errosForm.filter((m)=>m.restritiva).length > 0)
                    errosForm.filter((m)=>m.restritiva).forEach((e)=>erros.push(e.message));

                if (errosForm && errosForm.filter((m)=>!m.restritiva).length > 0)
                    errosForm.filter((m)=>!m.restritiva).forEach((e)=>errors.push(e.message));
            }
            
        } 

        if (errors.length > 0) {
            this.snackBarService.showAlert(errors.join('\n'));
        }


    }

    //: TODO  Exibicao de formulario feicao aqui sera implementando a Evolutiva #9920 / codigo fixo para apsentacao
    async buscarFeicaoReqLicenciamento(): Promise<void> {
        this.feicaoReqLicenciamento = await this.requerimentoLicenciamentoService.getFeicoes(this.requerimentoLicenciamento.id).toPromise();
        const objLicenciamento = await this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).toPromise();
        this.objLicenciamento = objLicenciamento[0];
    }

    get possuiAtividades(): boolean {
        return this.requerimentoEmpreendimentoAtividade && this.requerimentoEmpreendimentoAtividade.length > 0;
    }

    recarregarForms() {
        this.formsConcluidos.forEach(async form => {
            await this.verificarPreenchimentoFormularios(form.formularioDesc);
        });
        
    }

    verificarPreenchimentoFormularios(formDesc) {
        switch (formDesc) {
            case 'mineracao':
                this.mineracaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(mineracao => {
                    if (mineracao) {
                        this.receberFormConcluido({
                            idForm: mineracao.id,
                            formConcluido: true,
                            formularioDesc: 'mineracao',
                            nomeForm: 'Mineração'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'mineracao',
                            nomeForm: 'Mineração'
                        })
                    }
                });
                break;
            case 'residuos-aterro-industrial-urbano':
                this.residuoAterroIndUrbService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuoAterro => {
                    if (residuoAterro) {
                        this.receberFormConcluido({
                            idForm: residuoAterro.id,
                            formConcluido: true,
                            formularioDesc: 'residuos-aterro-industrial-urbano',
                            nomeForm: 'Resíduos - Aterro Industrial/Urbano'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false, formularioDesc: 'residuos-aterro-industrial-urbano',
                            nomeForm: 'Resíduos - Aterro Industrial/Urbano'
                        })
                    }
                });
                break;
            case 'industria-frigorifico':
                this.industriaFrigorificoService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(industriaFrigorifico => {
                    if (industriaFrigorifico) {
                        this.receberFormConcluido({
                            idForm: industriaFrigorifico.id,
                            formConcluido: true,
                            formularioDesc: 'industria-frigorifico',
                            nomeForm: 'Indústria - Abate/Frigorífico de animais'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false, formularioDesc: 'industria-frigorifico',
                            nomeForm: 'Indústria - Abate/Frigorífico de animais'
                        })
                    }
                });
                break;
            case 'residuos-cemiterio-crematorio':
                this.empreendimentoCemiterioCrematorioService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(cemiterioCrematorio => {
                    if (cemiterioCrematorio) {
                        this.receberFormConcluido({
                            idForm: cemiterioCrematorio.id, formConcluido: true,
                            formularioDesc: 'residuos-cemiterio-crematorio', nomeForm: 'Resíduos - Cemitério/Crematório'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-cemiterio-crematorio', nomeForm: 'Resíduos - Cemitério/Crematório'
                        })
                    }
                });
                break;
            case 'servico-combustivel':
                this.servicoCombustivelService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(servicoCombustivel => {
                    if (servicoCombustivel) {
                        this.receberFormConcluido({
                            idForm: servicoCombustivel.id, formConcluido: true,
                            formularioDesc: 'servico-combustivel', nomeForm: 'Serviços - Combustível'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'servico-combustivel', nomeForm: 'Serviços - Combustível'
                        })
                    }
                });
                break;
            case 'industria-biocombustivel':
                this.industriaBiocombustivelService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(industriaBiocombustivel => {
                    if (industriaBiocombustivel) {
                        this.receberFormConcluido({
                            idForm: industriaBiocombustivel.id, formConcluido: true,
                            formularioDesc: 'industria-biocombustivel', nomeForm: 'Indústria - Biocombustível'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'industria-biocombustivel', nomeForm: 'Indústria - Biocombustível'
                        })
                    }
                });
                break;
            case 'residuos-rcc':
                this.residuosRccService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuosRcc => {
                    if (residuosRcc) {
                        this.receberFormConcluido({
                            idForm: residuosRcc.id, formConcluido: true,
                            formularioDesc: 'residuos-rcc', nomeForm: 'Resíduos - Construção Civil'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-rcc', nomeForm: 'Resíduos - Construção Civil'
                        })
                    }
                });
                break;
            case 'gerenciamento-residuos':
                this.empreendimentoGerenciamentoResiduosService.listarResiduosGeradosPorRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(gerenciamentoResiduos => {
                    if (gerenciamentoResiduos.length > 0) {
                        this.receberFormConcluido({
                            idForm: gerenciamentoResiduos[0].id,
                            formConcluido: true,
                            formularioDesc: 'gerenciamento-residuos',
                            nomeForm: 'Resíduos - Plano de Gerenciamento de Resíduos Sólidos'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'gerenciamento-residuos',
                            nomeForm: 'Resíduos - Plano de Gerenciamento de Resíduos Sólidos'
                        })
                    }
                });
                break;
            case 'residuos-tratamento-termico':
                this.residuoTratamentoTermicoService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(tratamentoTermico => {
                    if (tratamentoTermico) {
                        this.receberFormConcluido({
                            idForm: tratamentoTermico.id, formConcluido: true,
                            formularioDesc: 'residuos-tratamento-termico', nomeForm: 'Resíduos - Tratamento Térmico'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-tratamento-termico', nomeForm: 'Resíduos - Tratamento Térmico'
                        })
                    }
                });
                break;
            case 'residuos-transporte-produtos-perigosos':
                this.transporteProdutosPerigososService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(produtosPerigosos => {
                    if (produtosPerigosos) {
                        this.receberFormConcluido({
                            idForm: produtosPerigosos.id,
                            formConcluido: true,
                            formularioDesc: 'residuos-transporte-produtos-perigosos',
                            nomeForm: 'Resíduos - Transporte de resíduos e produtos perigosos'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'residuos-transporte-produtos-perigosos',
                            nomeForm: 'Resíduos - Transporte de resíduos e produtos perigosos'
                        })
                    }
                });
                break;
            case 'residuos-geral':
                this.residuoGeralService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(residuoGeral => {
                    if (residuoGeral) {
                        this.receberFormConcluido({
                            idForm: residuoGeral.id, formConcluido: true,
                            formularioDesc: 'residuos-geral', nomeForm: 'Resíduos - Geral'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'residuos-geral', nomeForm: 'Resíduos - Geral'
                        })
                    }
                });
                break;
            case 'agua-passivo-supressao':
                this.aguaPassivoSupressaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(aguaPassivoSupressao => {
                    if (aguaPassivoSupressao) {
                        this.receberFormConcluido({
                            idForm: aguaPassivoSupressao.id, formConcluido: true,
                            formularioDesc: 'agua-passivo-supressao', nomeForm: 'Água Passivo Supressão'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'agua-passivo-supressao', nomeForm: 'Água Passivo Supressão'
                        })
                    }
                });
                break;
            case 'sistema-tratamento':
                this.sistemaTratamentoService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(sistemaTratamento => {
                    if (sistemaTratamento) {
                        this.receberFormConcluido({
                            idForm: sistemaTratamento.id,
                            formConcluido: true,
                            formularioDesc: 'sistema-tratamento',
                            nomeForm: 'Sistema de tratamento de efluentes doméstico e industrial'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'sistema-tratamento',
                            nomeForm: 'Sistema de tratamento de efluentes doméstico e industrial'
                        })
                    }
                });
                break;
            case 'madeira':
                this.madeiraService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(madeira => {
                    if (madeira) {
                        this.receberFormConcluido({
                            idForm: madeira.id, formConcluido: true,
                            formularioDesc: 'madeira', nomeForm: 'Madeira'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'madeira', nomeForm: 'Madeira'
                        })
                    }
                });
                break;
            case 'infra-parcel-solo-res-com':
                this.empreendimentoInfraParcelSoloResComService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(infraParcelSolo => {
                    if (infraParcelSolo) {
                        this.receberFormConcluido({
                            idForm: infraParcelSolo.id,
                            formConcluido: true,
                            formularioDesc: 'infra-parcel-solo-res-com',
                            nomeForm: 'Infraestrutura - Obras Residenciais/Comerciais / Parcelamento do solo'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'infra-parcel-solo-res-com',
                            nomeForm: 'Infraestrutura - Obras Residenciais/Comerciais / Parcelamento do solo'
                        })
                    }
                });
                break;
            case 'infraestrutura-obras-viarias':
                this.infraObrasViariasService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(infraObraViaria => {
                    if (infraObraViaria) {
                        this.receberFormConcluido({
                            idForm: infraObraViaria.id, formConcluido: true,
                            formularioDesc: 'infraestrutura-obras-viarias', nomeForm: 'Infraestrutura - Obras Viárias'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'infraestrutura-obras-viarias', nomeForm: 'Infraestrutura - Obras Viárias'
                        })
                    }
                });
                break;
            case 'ee-hidrica':
                this.eeHidricaService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeHidrica => {
                    if (eeHidrica) {
                        this.receberFormConcluido({
                            idForm: eeHidrica.id, formConcluido: true,
                            formularioDesc: 'ee-hidrica', nomeForm: 'Empreendimentos Energéticos - Hídrica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-hidrica', nomeForm: 'Empreendimentos Energéticos - Hídrica'
                        })
                    }
                });
                break;
            case 'ee-termica':
                this.empreendimentoEnergService.buscaEEtermicaPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeTermica => {
                    if (eeTermica) {
                        this.receberFormConcluido({
                            idForm: eeTermica.id, formConcluido: true,
                            formularioDesc: 'ee-termica', nomeForm: 'Empreendimentos Energéticos - Térmica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-termica', nomeForm: 'Empreendimentos Energéticos - Térmica'
                        })
                    }
                });
                break;
            case 'ee-fotovoltaica':
                this.empreendimentoEnergiaFotovoltaicaService.consultarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeFotovoltaica => {
                    if (eeFotovoltaica) {
                        if (eeFotovoltaica.formConcluido) {
                            this.receberFormConcluido({
                                idForm: eeFotovoltaica.id,
                                formConcluido: true,
                                formularioDesc: 'ee-fotovoltaica',
                                nomeForm: 'Empreendimentos Energéticos - Fotovoltaica'
                            })
                        } else {
                            this.receberFormConcluido({
                                idForm: 0,
                                formConcluido: false,
                                formularioDesc: 'ee-fotovoltaica',
                                nomeForm: 'Empreendimentos Energéticos - Fotovoltaica'
                            })
                        }
                    }
                });
                break;
            case 'ee-linha-transmissao':
                this.eeLinhaTransmissaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeLinha => {
                    if (eeLinha) {
                        this.receberFormConcluido({
                            idForm: eeLinha.id,
                            formConcluido: true,
                            formularioDesc: 'ee-linha-transmissao',
                            nomeForm: 'Empreendimentos Energéticos - Linha de Transmissão'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'ee-linha-transmissao',
                            nomeForm: 'Empreendimentos Energéticos - Linha de Transmissão'
                        })
                    }
                });
                break;
            case 'ee-subestacao':
                this.empreendimentoSubestacaoService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeSubestacao => {
                    if (eeSubestacao) {
                        this.receberFormConcluido({
                            idForm: eeSubestacao.id, formConcluido: true,
                            formularioDesc: 'ee-subestacao', nomeForm: 'Empreendimentos Energéticos - Subestação'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-subestacao', nomeForm: 'Empreendimentos Energéticos - Subestação'
                        })
                    }
                });
                break;
            case 'ee-eolica':
                this.empreendimentoEnergiaEolicaService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(eeEolica => {
                    if (eeEolica) {
                        this.receberFormConcluido({
                            idForm: eeEolica.id, formConcluido: true,
                            formularioDesc: 'ee-eolica', nomeForm: 'Empreendimentos Energéticos - Eólica'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'ee-eolica', nomeForm: 'Empreendimentos Energéticos - Eólica'
                        })
                    }
                });
                break;
            case 'infra-obra-fluviais-saneamento':
                this.infraObraFluvSaneamService.getByRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(obraFluv => {
                    if (obraFluv) {
                        this.receberFormConcluido({
                            idForm: obraFluv.id,
                            formConcluido: true,
                            formularioDesc: 'infra-obra-fluviais-saneamento',
                            nomeForm: 'Infraestrutura - Obras Fluviais Saneamento'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0,
                            formConcluido: false,
                            formularioDesc: 'infra-obra-fluviais-saneamento',
                            nomeForm: 'Infraestrutura - Obras Fluviais Saneamento'
                        })
                    }
                });
                break;
            case 'servico-geral':
                this.servicoGeralService.getByRequerimento(this.requerimentoLicenciamento && this.requerimentoLicenciamento.requerimento ? this.requerimentoLicenciamento.requerimento.id : this.requerimento.id).subscribe(servGeral => {
                    if (servGeral) {
                        this.receberFormConcluido({
                            idForm: servGeral.id, formConcluido: true,
                            formularioDesc: 'servico-geral', nomeForm: 'Serviços Gerais'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'servico-geral', nomeForm: 'Serviços Gerais'
                        })
                    }
                });
                break;
            case 'queima-controlada':
                this.queimaControladaService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(queimaControlada => {
                    if (queimaControlada) {
                        this.requerimentoLicenciamentoService.getFeicoes(
                            this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ?
                                this.requerimentoLicenciamentoVinculado.id : this.requerimentoLicenciamento.id).subscribe(feicoesRequerimento => {
                            if(queimaControlada.quadroDeFeicoes.length != feicoesRequerimento.length){
                                this.snackBarService.showError("O quadro de áreas possui geometrias que não correspondem aos dados geográficos. " +
                                    "Atualize o quadro de áreas e salve o formulário.")
                                this.receberFormConcluido({
                                    idForm: 0, formConcluido: false,
                                    formularioDesc: 'queima-controlada', nomeForm: 'AQC - Queima Controlada (Atividade) #SUGF'
                                })
                            }else{
                                //Valida se houve alteração nas feições desde o ultima save do formulario
                                let validacaoFeicoes = true;
                                queimaControlada.quadroDeFeicoes.forEach(feicaoSalva => {
                                    if(!feicoesRequerimento.find(feicaoRequerimento =>
                                        feicaoRequerimento.id == feicaoSalva.idFeicaoRequerimentoLicenciamento)){
                                        validacaoFeicoes = false;
                                    }
                                })
                                if(validacaoFeicoes){
                                    this.receberFormConcluido({
                                        idForm: queimaControlada.id, formConcluido: true,
                                        formularioDesc: 'queima-controlada', nomeForm: 'AQC - Queima Controlada (Atividade) #SUGF'
                                    })
                                }else{
                                    this.snackBarService.showError("O quadro de áreas possui geometrias que não correspondem aos dados geográficos. " +
                                        "Atualize o quadro de áreas e salve o formulário.")
                                    this.receberFormConcluido({
                                        idForm: 0, formConcluido: false,
                                        formularioDesc: 'queima-controlada', nomeForm: 'AQC - Queima Controlada (Atividade) #SUGF'
                                    })
                                }
                            }
                        });
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'queima-controlada', nomeForm: 'AQC - Queima Controlada (Atividade) #SUGF'
                        })
                    }
                });
                break;
            case 'supressao-de-vegetacao':
                this.supressaoVegetalService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(supressaoVegetal => {
                    if (supressaoVegetal) {
                        this.receberFormConcluido({
                            idForm: supressaoVegetal.id, formConcluido: true,
                            formularioDesc: 'supressao-de-vegetacao', nomeForm: 'Supressão de Vegetação'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'supressao-de-vegetacao', nomeForm: 'Supressão de Vegetação'
                        })
                    }
                });
                break;
            case 'acai':
                this.acaiService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(acai => {
                    if (acai) {
                        this.receberFormConcluido({
                            idForm: acai.id, formConcluido: true,
                            formularioDesc: 'acai', nomeForm: 'Autorizacao de Corte'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'acai', nomeForm: 'Autorizacao de Corte'
                        })
                    }
                });
                break;
            case 'manejo-florestal':
                this.manejoFlorestalService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(manejoFlorestal => {
                    if (manejoFlorestal) {
                        this.receberFormConcluido({
                            idForm: manejoFlorestal.id, formConcluido: true,
                            formularioDesc: 'manejo-florestal', nomeForm: 'Manejo Florestal'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'manejo-florestal', nomeForm: 'Manejo Florestal'
                        })
                    }
                });
                break;
            case 'reflorestamento-seminventario':
                this.reflorestamentoSeminventarioService.buscarPorIdRequerimento(this.requerimentoLicenciamentoVinculado && this.requerimentoLicenciamentoVinculado.requerimento ? this.requerimentoLicenciamentoVinculado.requerimento.id : this.requerimento.id).subscribe(reflorestamento => {
                    if (reflorestamento) {
                        this.receberFormConcluido({
                            idForm: reflorestamento.id, formConcluido: true,
                            formularioDesc: 'reflorestamento-seminventario', nomeForm: 'Reflorestamento sem inventário'
                        })
                    } else {
                        this.receberFormConcluido({
                            idForm: 0, formConcluido: false,
                            formularioDesc: 'reflorestamento-seminventario', nomeForm: 'Reflorestamento sem inventário'
                        })
                    }
                });
                break;
        }
    }


    atualizaFormulario(event: any) {
        if (event.tipo === 'SAVE') {
            let filter = this.quantidadeFormulariosConcluidos.filter(form => form.idFeicao === event.idFeicao && form.formulario === event.formulario)
            if (filter.length === 0) {
                this.quantidadeFormulariosConcluidos.push(event)
            }
        } else {
            let filter = this.quantidadeFormulariosConcluidos.filter(form => form.idFeicao === event.idFeicao && form.formulario === event.formulario)
            if (filter.length === 1) {
                this.quantidadeFormulariosConcluidos.splice(this.quantidadeFormulariosConcluidos.indexOf(filter[0]), 1);
            }
        }

        if (this.quantidadeFormulariosConcluidos.length === this.formularios) {
            this.formulariosConcluido = true
        } else {
            this.formulariosConcluido = false
        }
    }

    // TODO: função apenas para teste
    criarDesmate(id) {
        return {
            area: 0.5192,
            denominacao: 'nova deno desmate',
            descricaoObjetivo: 'Dinâmica de Desmate',
            id: id,
            objetivo: 0
        }
    }

    // selecionarMotivo(motivo: any) {
    //     this.motivoSelect = motivo
    // }

    // private async carregarMotivoSelecionado() {
    //     if (this.requerimentoLicenciamento.idMotivoAutorizacaoQueima) {
    //         let motivo = this.motivos.find(m => m.id === this.requerimentoLicenciamento.idMotivoAutorizacaoQueima)
    //         this.motivoSelecionado = []
    //         this.motivoSelecionado.push(motivo)
    //         let motivos = this.motivoSelecionado.slice()
    //         this.motivoSelecionado = motivos
    //         await this.verificarRegrasDeMotivo()
    //     }
    // }

    // async selecionarMotivoNaTabela() {
    //     if (this.motivoSelecionado && this.motivoSelecionado.length > 0) {
    //         this.snackBarService.showAlert('Já existe um motivo selecionado.')
    //     } else {
    //         this.motivoSelecionado = []
    //         this.requerimentoLicenciamento.idMotivoAutorizacaoQueima = this.motivoSelect.id
    //         await this.requerimentoLicenciamentoService.update(this.requerimentoLicenciamento).toPromise()
    //         this.motivoSelecionado.push(this.motivoSelect)
    //         let motivos = this.motivoSelecionado.slice()
    //         this.motivoSelecionado = motivos
    //         this.snackBarService.showSuccess('Motivo selecionado com sucesso.')
    //         await this.verificarRegrasDeMotivo()
    //     }
    // }

    // async deletarMotivo() {
    //     this.requerimentoLicenciamento.idMotivoAutorizacaoQueima = null
    //     await this.requerimentoLicenciamentoService.update(this.requerimentoLicenciamento).toPromise()
    //     this.motivoSelecionado = []
    //     this.snackBarService.showSuccess('Motivo removido com sucesso.')
    //     localStorage.setItem('autValidada', 'false')
    // }

    // private async verificarRegrasDeMotivo() {
    //     let titulosInformado = await this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, null).toPromise()
    //     let tituloInformado = titulosInformado[0]
    //     if (this.motivoSelecionado[0].id === 1) {
    //         if (!tituloInformado) {
    //             this.snackBarService.showAlert('O número da Autorização de Desmate é obrigatório. Informe o número da autorização na aba 1 do requerimento.')
    //             this.errosDeRegras.push('O número da Autorização de Desmate é obrigatório. Informe o número da autorização na aba 1 do requerimento.')
    //             localStorage.setItem('autValidada', 'false')
    //         } else if (tituloInformado) {
    //             let emissao = await this.emissaoTituloService
    //                 .readByEmissaoTituloNumeroEmissaoAndReqTitulacaoId(tituloInformado.numeroEmissao, 16)
    //                 .toPromise()
    //             localStorage.setItem('autValidada', 'true')
    //             if (!emissao) {
    //                 this.snackBarService.showAlert('O número da Autorização de Desmate é obrigatório. Informe o número da autorização na aba 1 do requerimento.')
    //                 this.errosDeRegras.push('O número da Autorização de Desmate é obrigatório. Informe o número da autorização na aba 1 do requerimento.')
    //                 localStorage.setItem('autValidada', 'false')
    //             }
    //         }
    //     } else {
    //         if (!tituloInformado) {
    //             this.snackBarService.showAlert('O número da Autorização Provisória de Funcionamento é obrigatório. Informe o número da APF na aba 1 do requerimento.')
    //             this.errosDeRegras.push('O número da Autorização Provisória de Funcionamento é obrigatório. Informe o número da APF na aba 1 do requerimento.')
    //             localStorage.setItem('autValidada', 'false')
    //         } else if (tituloInformado) {
    //             let emissao = await this.emissaoTituloService
    //                 .readByEmissaoTituloNumeroEmissaoAndReqTitulacaoId(tituloInformado.numeroEmissao, 19)
    //                 .toPromise()
    //             localStorage.setItem('autValidada', 'true')
    //             if (!emissao) {
    //                 this.snackBarService.showAlert('O número da Autorização Provisória de Funcionamento é obrigatório. Informe o número da APF na aba 1 do requerimento.')
    //                 this.errosDeRegras.push('O número da Autorização Provisória de Funcionamento é obrigatório. Informe o número da APF na aba 1 do requerimento.')
    //                 localStorage.setItem('autValidada', 'false')
    //             }
    //         }
    //     }
    // }

    //TODO aguardando desenvolvimento da API de inventario

    // async carregarInventarioSalvo() {
    //     this.inventarioImportado = await this.documentoProcessoService.buscaDocumentoProcessoPorIdRequerimentoEtipo(14040,
    //         EnumGrupoDocumento.DOCUMENTO_INVENTARIO_IMPORTADO).toPromise();
    // }
    //
    // baixarInventario(){
    //     window.open(`${this.documentoProcessoService.urlResource}/${this.inventarioImportado.id}/download`);
    // }
}
