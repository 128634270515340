import {CommonsUtil} from './../../../../../../../../shared/util/commons-util';
import {MatTableDataSource} from '@angular/material';
import {GeradorVaporUsinaTerm} from './../../../../../../../../shared/models/energia-termica-gerador-vapor.model';
import {SnackBarService} from 'app/main/shared/snack-bar/snack-bar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmpreendimentoEnergeticoTermicaService} from '../empreendimento-energetico-termica.service';

@Component({
    selector: 'app-gerador-vapor',
    templateUrl: './gerador-vapor.component.html',
    styleUrls: ['./gerador-vapor.component.scss']
})
export class GeradorVaporComponent implements OnInit {

    @Input() idEEtermica: any;
    @Input() readonly: boolean;
    @Input() isNaoPodeEditar: boolean;


    modoEdicao: boolean = false;

    @Output() enviarGeradorVapor = new EventEmitter<GeradorVaporUsinaTerm[]>();
    formGeradorVapor: FormGroup;

    geradoresVapor: any[] = [];
    dataSourceGeradoresVapor = new MatTableDataSource<any>([]);

    @Output() save: EventEmitter<any> = new EventEmitter<any>();

    idTabela = 0;

    displayedColumns: string[] = [
        'identificacaoGeradorVapor',
        'descricaoTipo',
        'capacidade',
        'pressaoInstrumento',
        'temperatura',
        'eficiencia',
        'nomeFabricante',
        'acao'
    ];

    constructor(private fb: FormBuilder,
                private snackBarService: SnackBarService,
                private empreendimentoEnergService: EmpreendimentoEnergeticoTermicaService) {
    }

    ngOnInit(): void {
        this.buildFormGeradorVapor();

        if (this.idEEtermica) {
            this.carregaGeradorVaporCasoExistaIdEEtermica(this.idEEtermica);
        }

        if (this.isNaoPodeEditar) {
            this.formGeradorVapor.disable();
        }
    }

    buildFormGeradorVapor(): void {
        this.formGeradorVapor = this.fb.group({
            id: [''],
            idTemp: [null],
            identificacaoGeradorVapor: ['', [Validators.required]],
            descricaoTipo: ['', [Validators.required]],
            capacidade: ['', [Validators.required]],
            pressaoInstrumento: ['', [Validators.required]],
            temperatura: ['', [Validators.required]],
            eficiencia: ['', [Validators.required]],
            nomeFabricante: ['', [Validators.required]],
        });
    }


    adicionaGeradorVapor(): void {
        const validacao = this.validarFormulario();
        if (validacao) {

            this.geradoresVapor.push({
                id: null,
                idTemp: this.idTabela,
                identificacaoGeradorVapor: this.formGeradorVapor.controls.identificacaoGeradorVapor.value,
                descricaoTipo: this.formGeradorVapor.controls.descricaoTipo.value,
                capacidade: Number(this.formGeradorVapor.controls.capacidade.value),
                pressaoInstrumento: Number(this.formGeradorVapor.controls.pressaoInstrumento.value),
                temperatura: Number(this.formGeradorVapor.controls.temperatura.value),
                eficiencia: Number(this.formGeradorVapor.controls.eficiencia.value),
                nomeFabricante: this.formGeradorVapor.controls.nomeFabricante.value,
            });
            this.dataSourceGeradoresVapor = new MatTableDataSource(this.geradoresVapor);
            this.enviarGeradoresVapor(this.mapearArray());
            this.idTabela++;
            this.formGeradorVapor.reset();
        }
    }

    enviarGeradoresVapor(geradores: GeradorVaporUsinaTerm[]): void {
        this.enviarGeradorVapor.emit(geradores);
    }

    deletarItem(item, acao?: string, itemId?: number): void {
        if (acao === 'DELETAR' && itemId != null) {
            this.empreendimentoEnergService.deletarGeradorVaporPorId(itemId).subscribe(() => {
                this.snackBarService.showSuccess('Gerador Vapor excluido com sucesso.');
            });
        }
        let data;
        data = this.geradoresVapor.filter((value, key) => {
            return value.idTemp !== item;
        });
        this.geradoresVapor = data;
        this.dataSourceGeradoresVapor = data;
        this.enviarGeradoresVapor(this.mapearArray());
    }


    mapearArray(): any {
        if (this.geradoresVapor.length == 0) {
            return this.geradoresVapor;
        } else {
            return this.geradoresVapor.map(function (item) {
                return new GeradorVaporUsinaTerm(
                    null,
                    null,
                    item.identificacaoGeradorVapor,
                    item.descricaoTipo,
                    item.capacidade,
                    item.pressaoInstrumento,
                    item.temperatura,
                    item.eficiencia,
                    item.nomeFabricante,
                );
            });
        }
    }

    ativarModoEdicaoGerador(geradores): void {
        this.formGeradorVapor.controls.id.patchValue(geradores.id);
        this.formGeradorVapor.controls.idTemp.patchValue(geradores.idTemp);
        this.formGeradorVapor.controls.identificacaoGeradorVapor.patchValue(geradores.identificacaoGeradorVapor);
        this.formGeradorVapor.controls.descricaoTipo.patchValue(geradores.descricaoTipo);
        this.formGeradorVapor.controls.capacidade.patchValue(geradores.capacidade);
        this.formGeradorVapor.controls.pressaoInstrumento.patchValue(geradores.pressaoInstrumento);
        this.formGeradorVapor.controls.temperatura.patchValue(geradores.temperatura);
        this.formGeradorVapor.controls.eficiencia.patchValue(geradores.eficiencia);
        this.formGeradorVapor.controls.nomeFabricante.patchValue(geradores.nomeFabricante);
        this.modoEdicao = true;

    }

    editarGerador(): void {
        const validacao = this.validarFormulario();
        if (validacao) {
            this.deletarItem(this.formGeradorVapor.controls.idTemp.value);
            this.adicionaGeradorVapor();
            this.modoEdicao = false;
        }

    }

    fecharEdicao(): void {
        this.modoEdicao = false;
        this.formGeradorVapor.reset();
        this.formGeradorVapor.markAsUntouched();
        this.formGeradorVapor.updateValueAndValidity();
    }


    validarFormulario(): boolean {
        if (this.formGeradorVapor.valid) {
            if (CommonsUtil.isEmpty(this.formGeradorVapor.controls.identificacaoGeradorVapor.value)) {
                this.snackBarService.showAlert(
                    'O campo Identificação ' +
                    ' é obrigatório' +
                    'Favor realize o preenchimento do mesmo.');
                this.formGeradorVapor.markAllAsTouched();
                return false;
            }

            return true;
        } else {
            this.snackBarService.showAlert(
                'Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
            this.formGeradorVapor.markAllAsTouched();
            return false;
        }
    }


    carregaGeradorVaporCasoExistaIdEEtermica(idEEtermica: any): void {
        this.empreendimentoEnergService.buscaListGeradorVapor(idEEtermica).subscribe(async result => {
            for (const geradorVapor of result) {

                this.geradoresVapor.push({
                    id: geradorVapor.id,
                    idTemp: this.idTabela,
                    identificacaoGeradorVapor: geradorVapor.identificacaoGeradorVapor,
                    descricaoTipo: geradorVapor.descricaoTipo,
                    capacidade: geradorVapor.capacidade,
                    pressaoInstrumento: geradorVapor.pressaoInstrumento,
                    temperatura: geradorVapor.temperatura,
                    eficiencia: geradorVapor.eficiencia,
                    nomeFabricante: geradorVapor.nomeFabricante,
                });
                this.dataSourceGeradoresVapor = new MatTableDataSource(this.geradoresVapor);
                this.idTabela++;
            }
        });
    }
}


