import {BaseModel} from "../base.model";
import {ParametroLicenciamento} from "./parametro-licenciamento.model";

export class AtividadeParametroLicenciamento extends BaseModel {
    constructor(
        public id?: number,
        public atividade?: number,
        public parametroLicenciamento?: ParametroLicenciamento,
    ) {
        super();
    }

    static fromJson(json: any): AtividadeParametroLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new AtividadeParametroLicenciamento(
            json.id,
            json.atividade,
            ParametroLicenciamento.fromJson(json.parametroLicenciamento),
        );
    }
}