import {BaseModel} from 'app/main/shared/models/base.model';

export class TratamentoEfluentes extends BaseModel {
    constructor(
        public id?: number,
        public idGeometria?: number,
        public idTipoTratamento?: number,
        public volume?: number,
        public idTipoImpermeabilizacao?: number,
    ) {
        super();
    }

    static fromJson(json: any): TratamentoEfluentes {
        json = json || {};

        return new TratamentoEfluentes(
            json.id,
            json.idGeometria,
            json.idTipoTratamento,
            json.volume,
            json.idTipoImpermeabilizacao,
        );
    }
}