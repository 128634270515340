import {BaseModel} from 'app/main/shared/models/base.model';

export class AssuntoNota extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string
    ) {
        super();
    }

    static fromJson(json: any): AssuntoNota {
        json = json || {};

        return new AssuntoNota(
            json.id,
            json.descricao
        );
    }

    static fromIterable(json: any): Array<AssuntoNota> {
        const assuntos = new Array<AssuntoNota>();

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    assuntos.push(AssuntoNota.fromJson(value));
                }
            }
        }

        return assuntos;
    }

    toString(): string {
        return this.descricao;
    }
}