import {Component, Injector, OnInit} from '@angular/core';
import {BaseRequerimentoLicenciamentoFormulario} from "../base-requerimento-licenciamento.component";
import {RequerimentoService} from "../../../requerimento.service";
import {SnackBarService} from "../../../../../shared/snack-bar/snack-bar.service";
import {
    ConfirmarCancelamentoDialogComponent
} from "../../../shared/confirmar-cancelamento-dialog/confirmar-cancelamento-dialog.component";
import {RedirectUtil} from "../../../../../shared/util/RedirectUtil";
import {RequerimentoLicenciamentoService} from "../../requerimento-licenciamento.service";
import {PdfViewerDialog} from 'app/main/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.model';
import {takeUntil} from 'rxjs/operators';
import {RequerimentoSteps} from '../../../shared/requerimento.steps';
import {
    RequerimentoTitulacaoService
} from '../../../shared/requerimento-titulacao/shared/requerimento-titulacao.service';
import {ObjetivoLicenciamentoService} from 'app/main/shared/services/objetivo-licenciamento.service';
import {
    DialogConfirmacaoComponent
} from "../../../../../shared/components/dialog-confirmacao/dialog-confirmacao.component";
import {ReqTituloVinculadoService} from "../../../../tarefas/shared/service/req-titulo-vinculado.service";
import {LocalStorageService} from 'app/main/shared/services/local-storage.service';
import {EnumObjetoAssinatura} from '../../../../../shared/enums/EnumObjetoAssinatura';

@Component({
    selector: 'app-licenciamento-empreendimentos',
    templateUrl: './licenciamento-empreendimentos.component.html',
    styleUrls: ['./licenciamento-empreendimentos.component.scss']
})
export class LicenciamentoEmpreendimentosComponent extends BaseRequerimentoLicenciamentoFormulario implements OnInit {

    constructor(
        readonly injector: Injector,
        private readonly requerimentoService: RequerimentoService,
        private readonly requerimentoLicenciamentoService: RequerimentoLicenciamentoService,
        private readonly objetivoLicenciamentoService: ObjetivoLicenciamentoService,
        private readonly requerimentoTitulacao: RequerimentoTitulacaoService,
        private readonly snackBarService: SnackBarService,
        private readonly pdfViewerDialog: PdfViewerDialog,
        private readonly reqTituloVinculadoService: ReqTituloVinculadoService,
        private readonly localStorageService: LocalStorageService) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.activatedRoute.snapshot.url[2].path === 'edit') {
            this.requerimentoService.getById(this.requerimento.id).subscribe(req => {
                this.localStorageService.set('requerimento', req);
                if (req.situacaoProcesso === 'CHECKLIST_REPROVADO') {
                    this.requerimentoService.atualizaStatusRequerimento(req.id, 'EM_CORRECAO_CADASTRO').subscribe(req => req);
                }
            });
        }

        this.helper.onCurrentStepChange().pipe(takeUntil(this._unsubscribeAll)).subscribe(e => {
            if (e.newTabIndex === RequerimentoSteps.LOCALIZACAO.index) {
                console.debug('VALIDAR REQUERIMENTO LICENCIAMENTO', this.requerimentoLicenciamento, this.requerimento);
                // Criar REQUERIMENTO_TITULACAO para as atividades.
                // this.requerimentoTitulacao
                this.objetivoLicenciamentoService.buscaListaObjetivosPorIdReqLicen(this.requerimentoLicenciamento.id).subscribe((objetivos) => {
                    console.debug('VALIDAR REQUERIMENTO LICENCIAMENTO.objetivos', objetivos);
                    this.localStorageService.set('requerimento_ojetivos', objetivos);
                });


                // let objetivoLicenciamento = new ObjetivoLicenciamento()
                // objetivoLicenciamento.requerimentoLicenciamento = this.requerimentoLicenciamento
                // objetivoLicenciamento.descricaoObjetivo = objetivo.objetivo.objetivo.descricao
                // objetivoLicenciamento.idObjetivoLicenciamentoAmbiental = objetivo.objetivo.objetivo.id
                // objetivoLicenciamento.idTipoObjetivo = objetivo.objetivo.tipoObjetivo.id
                // await this.objetivoLicenciamentoService.create(objetivoLicenciamento).toPromise()

            }

        });
    }

    cancelar(): any {
        const idTarefa = this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path;

        const dialogRef = this.dialog.open(ConfirmarCancelamentoDialogComponent, {
            width: '300px',
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.requerimentoService.cancelarRequerimento(idTarefa, this.requerimento.id).subscribe(resp => {
                    RedirectUtil.redirecionaDashboardCamunda();
                }, () => {
                });
            }
        });
    }

    async finalizar(): Promise<void> {
        this.validacaoFinal().then(async passou => {
            if (passou) {
                if (this.requerimento.tipoProcesso.instanciaRequerimento == "AUTORIZACAO_OUTROS_DOCUMENTOS" && !this.requerimento.numeroProtocoloVinculado) {
                    let lpTramitacao = await this.reqTituloVinculadoService.readByReqTituloVinculado(this.requerimento.id, 'LP_TRAMITACAO').toPromise()
                    if (lpTramitacao && lpTramitacao.length > 0) {
                        const dialog = await this.dialog.open(DialogConfirmacaoComponent,
                            {
                                width: '500px',
                                data: {
                                    message: `Após o envio da solicitação, a análise do processo de requerimento de ${localStorage.getItem('requerimentoVinculadoLpTramitacao')} será suspensa, por ser atividade passível (EIA/RIMA). Deseja continuar?`
                                }
                            });

                        await dialog.afterClosed().toPromise().then(async result => {
                            if (result) {
                                const dialog2 = await this.dialog.open(DialogConfirmacaoComponent,
                                    {
                                        width: '500px',
                                        data: {
                                            message: `Aviso: Foi requerido Estudo Impacto Ambiental (EIA/RIMA) para o processo, verificar possibilidade de arquivamento definitivo.`
                                        }
                                    });

                                await dialog2.afterClosed().toPromise().then(async result => {
                                    if (result) {
                                        await this.abrirAssinaturaDocumentoPdf()
                                        localStorage.removeItem('requerimentoVinculadoLpTramitacao')
                                    }
                                })
                            }
                        })
                    } else {
                        await this.abrirAssinaturaDocumentoPdf()
                    }
                } else {
                    await this.abrirAssinaturaDocumentoPdf()
                }
            }
        });
    }

    async abrirAssinaturaDocumentoPdf() {
        let blob = await this.requerimentoLicenciamentoService.readPdf(this.requerimento).toPromise();
        this.pdfViewerDialog.show(blob, {
            height: '80%', width: '60%', showDownloadButton: true,
            assinatura: {
                service: this.requerimentoService,
                alvoAssinatura: this.requerimento,
                idTarefa: this.requerimentoTaskId ? this.requerimentoTaskId : this.activatedRoute.snapshot.url[3].path,
                aviso: 'Essa ação não poderá ser desfeita.\n Confira atentamente todas as informações ' +
                    'do requerimento antes de Assinar e Assinar e enviar o Requerimento para o checklist.',
                textoBotaoEnviar: 'Assinar e Enviar Requerimento',
                idDepartamentoResponsavel: this.idDepartamentoResponsavel ? this.idDepartamentoResponsavel : null,
                objetoAssinatura: EnumObjetoAssinatura.COPIA_REQUERIMENTO_SUIMIS,
            }
        }).subscribe(assinou => {
            if (assinou) {
                RedirectUtil.redirecionaDashboardCamundaComDelay();
            }
        });
    }

}