import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {UnidadeAgua} from "../../../../../shared/models/unidade-agua.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class UnidadeAguaService extends BaseService<UnidadeAgua> {
    constructor(protected injector: Injector) {
        super(
            '/api/unidade-agua',
            injector,
            UnidadeAgua.prototype,
            UnidadeAgua.fromJson
        );
    }

    getByParametroId(idParametroCond: number): Observable<UnidadeAgua> {
        const url = `${this.urlResource}/${idParametroCond}/parametro-condicionante`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );

    }
}
