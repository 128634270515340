import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from '../enums/base.enum';
import {Requerimento} from '../../pages/requerimentos/requerimento.model';
import {HistoricoRequerimentoEnum} from '../../pages/requerimentos/shared/historico-requerimento/model/historico-requerimento.model';

export class HistoricoPrazoProcesso extends BaseModel {
    constructor(
        public id?: number,
        public dataInicio?: Date,
        public dataFim?: Date,
        public dias?: number,
        public evento?: HistoricoRequerimentoEnum,
        public tipoEvento?: string,
        public requerimento?: Requerimento,
    ) {
        super();
    }


    static fromJson(json: any): HistoricoPrazoProcesso {
        if (json === undefined || json === null) {
            return null;
        }
        return new HistoricoPrazoProcesso(
            json.id,
            json.dataInicio,
            json.dataFim,
            json.dias,
            json.evento,
            json.tipoEvento,
            Requerimento.fromJson(json.requerimento),
        );
    }

    static fromJsons(json: any[]): HistoricoPrazoProcesso[] {
        const resources = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(resource =>
                resources.push(this.fromJson(resource))
            );
            return resources;
        }
    }

}
