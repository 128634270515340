import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {PontoVazaoMensal} from '../../ponto-vazao-mensal/models/ponto-vazao-mensal.model';
import {PontoVazoesMensaisService} from '../../ponto-vazao-mensal/services/ponto-vazoes-mensais.service';
import {TipoCorpoHidricoService} from '../../../services/tipo-corpo-hidrico.service';
import {EnumMes} from '../../../enums/mes.enum';
import {Ponto} from '../../../models/hidrico-models/ponto.model';
import {TipoSistemaCaptacao} from '../../../models/tipo-sistema-captacao.model';
import {TipoSistemaCaptacaoService} from '../../../../pages/requerimentos/requerimento-outorga/requerimento-atividade-outorga/finalidade-tanque-rede/services/tipo-sistema-captacao.service';

@Component({
    selector: 'app-aba-objetivo-captacao-superficial',
    templateUrl: './aba-objetivo-captacao-superficial.component.html'
})

export class AbaObjetivoCaptacaoSuperficialComponent implements OnInit {
    @Input() ponto: Ponto;
    lancamentosLenght: EventEmitter<number> = new EventEmitter<number>();
    lancamentos: Array<PontoVazaoMensal>;
    tipoSistemaCaptacao: Array<TipoSistemaCaptacao>;

    constructor(private lancamentoService: PontoVazoesMensaisService,
                private tipoCorpoHidricoService: TipoCorpoHidricoService,
                private tipoSistemaCaptacaoService: TipoSistemaCaptacaoService) {
    }

    ngOnInit(): void {
        this.carregarLancamentos();
        this.carregarTipoSistemaCaptacao();
    }

    private carregarLancamentos(): void {
        this.lancamentoService
            .getAllByPonto(this.ponto)
            .subscribe(lancamentos => {
                this.lancamentos = lancamentos;
                this.lancamentosLenght.emit(
                    this.lancamentos.length
                );
            });
    }

    private carregarTipoSistemaCaptacao(): void {
        this.tipoSistemaCaptacaoService
            .getAll()
            .subscribe(tipoSistemaCaptacao => (this.tipoSistemaCaptacao = tipoSistemaCaptacao));
    }

    getMesTexto(tipo: string): string {
        return EnumMes[tipo];
    }

}


