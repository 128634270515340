import {Injectable, Injector} from '@angular/core';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {CondicionanteTemplate} from './condicionante-template.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';


@Injectable()
export class CondicionanteTemplateListResolver extends ListResolver<CondicionanteTemplate> {
    constructor(service: CondicionanteTemplateService) {
        super(service);
    }
}

@Injectable()
export class CondicionanteTemplateResolver extends DetailResolver<CondicionanteTemplate> {
    constructor(service: CondicionanteTemplateService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CondicionanteTemplateService extends BaseService<CondicionanteTemplate> {

    constructor(protected injector: Injector) {
        super('/api/condicionante-templates', injector, CondicionanteTemplate.prototype, CondicionanteTemplate.fromJson);
    }

    pesquisaCondPorComentario(comentario: string): Observable<CondicionanteTemplate[]> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('descricao', comentario);
        const url = `${this.urlResource}/condicionantes/pesquisar-descricao`;
        return this.http.get(url, {params: httpParams}).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );
    }

}
