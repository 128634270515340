import {BaseModel} from 'app/main/shared/models/base.model';
import {ParqueBomba} from "./parque-bomba.model";


export class ParqueBombaCombustivel extends BaseModel {
    constructor(
        public id?: number,
        public tipoCombustivel?: number,
        public parqueBomba?: ParqueBomba
    ) {
        super();
    }

    static fromJson(json: any): ParqueBombaCombustivel {
        if (json === undefined || json === null) {
            return null;
        }

        return new ParqueBombaCombustivel(
            json.id,
            json.tipoCombustivel,
            json.parqueBomba
        );
    }

    static fromJsons(json: any): ParqueBombaCombustivel[] {
        const lista: ParqueBombaCombustivel[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach((item) => {
                lista.push(this.fromJson(item));
            });
            return lista;
        }
    }
}
