import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {CondicionanteTemplate} from '../condicionante-template.model';
import {CondicionanteTemplateService} from '../condicionante-template.service';
import {HttpParams} from '@angular/common/http';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';

@Component({
    selector: 'app-condicionante-template-list',
    templateUrl: './condicionante-template-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CondicionanteTemplateListComponent extends BaseListComponent<CondicionanteTemplate> {

    descricao: string;

    constructor(
        injector: Injector,
        service: CondicionanteTemplateService,
    ) {
        super(injector, ['id', 'descricao', 'tipoCondicionante', 'buttons'], service);
    }

    fillFilters(httpParams: HttpParams): HttpParams {
        if (typeof this.descricao !== 'undefined' && this.descricao.length > 0) {
            httpParams = httpParams.set('descricao', this.descricao);
        }
        return httpParams;
    }
}
