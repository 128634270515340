import {Observable, throwError} from 'rxjs';
import {Fertirrigacao} from '../models/fertirrigacao.model';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Fertilizacao} from '../models/fertilizacao.model';

@Injectable({
    providedIn: 'root'
})
export class FertilizacaoService {
    constructor(private http: HttpClient) {
    }

    urlResource: string = environment.URL_LIC_FEICAO_PECUARIA_INTENSIVA;

    createFertilizacao(fertilizacao: Fertilizacao): Observable<Fertilizacao> {
        const url = `${this.urlResource}/api/fertilizacao`;
        return this.http.post(url, fertilizacao)
            .pipe(map(response => response),
                catchError(e => throwError(e))
            );
    }

}
