import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";
import {ParqueBombaCombustivel} from "../../../../../../../../shared/models/parque-bomba-combustivel.model";

@Component({
    selector: 'app-area-abastecimento',
    templateUrl: './area-abastecimento.component.html',
    styleUrls: ['./area-abastecimento.component.scss']
})
export class AreaAbastecimentoComponent implements OnInit {

    @Input() form: FormGroup;

    @Input() combustiveisControl: AbstractControl;

    @Input()
    combustiveisExcluidos: number[];

    @Input() isSomenteVisualizacao: boolean;

    @Input() isNaoPodeEditar: boolean;

    @Output()
    onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }


}
