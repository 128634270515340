import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmacaoComponent } from './dialog-confirmacao.component';

@Injectable({
    providedIn: 'root'
  })
  export class DialogConfirmacaoService {
  
    constructor( private dialog: MatDialog ) { }
  
      openConfimDialog(acao: string, tipo: string, nome: string ){
          return this.dialog.open(DialogConfirmacaoComponent, {
              width: '390px',
              panelClass: 'confirm-dialog-container',
              disableClose: true,
              data: {
                  message: 'Tem certeza que deseja ' + acao + ' o(a) ' + tipo + ' ' + '"' + nome + '"'
              }
          })
      }
  }
